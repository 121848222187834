import React from "react"

import { withStyles } from "@material-ui/core/styles"
import get from "lodash/get"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { bindActionCreators } from "redux"

import {
	addMilestoneLine,
	fetchMilestone,
	removeMilestoneLine,
	updateMilestone,
} from "@ncs/mortar/redux/services/machineMilestones"
import { APPLICATIONS } from "@ncs/mortar/util/constants"
import { meetsAppRestriction } from "@ncs/bricks/redux/selectors/auth"
import callApi from "@ncs/bricks/redux/services/callApi"
import { formatDate, formatNumber, formatPercentage } from "@ncs/bricks/util/formatters"

import {
	AddLineItemDialog,
	Button,
	ButtonWithIcon,
	DynamicTable,
	GridContainer,
	GridItem,
	Input,
	InputAsDisplay,
	LoadingWrapper,
	NumericInput,
	QuickCard,
	Select,
} from "~/components"

import Add from "@material-ui/icons/Add"
import Cancel from "@material-ui/icons/Cancel"
import ChevronLeft from "@material-ui/icons/ChevronLeft"
import Edit from "@material-ui/icons/Edit"
import LocalShipping from "@material-ui/icons/LocalShipping"
import Remove from "@material-ui/icons/Remove"

const styles = {
	rightAlign: {
		textAlign: "right",
	},
}

const columns = [
	{
		Header: "Description",
		accessor: "description",
		id: "description",
		sortable: true,
		toggleable: false,
	},
	{
		Header: "Line Type",
		accessor: (x) => x.line_type.description,
		id: "wash_count",
		sortable: true,
		toggleable: false,
	},
	{
		Header: "Quantity",
		accessor: "quantity",
		sortable: true,
		toggleable: false,
	},
]

class MilestoneDetail extends React.PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			isLoading: true,
			milestone: null,
			newDescription: "",
			newIsActive: "true",
			newDiscountPercent: 0,
			ageInMonths: 0,
			washCount: 0,
			isEditing: false,
			potentialDaysDown: 0,
			newWashCountPercentIncrease: 0,
			newWashCountPriceIncrease: 0,
			newMaxUserDiscount: 0,
			newMaxAdminDiscount: 0,
		}
	}

	getMilestoneIdFromUrlParams = (props = this.props) => props.match.params.id

	componentDidUpdate(prevProps, prevState, snapshot) {
		let id = this.getMilestoneIdFromUrlParams()
		if (this.getMilestoneIdFromUrlParams(prevProps) !== id) {
			this.fetchMilestoneInfo()
		}
	}

	componentDidMount = () => {
		this.fetchMilestoneInfo()
	}

	fetchMilestoneInfo = () => {
		this.setState({ isLoading: true })

		let milestoneId = this.getMilestoneIdFromUrlParams()
		this.props.callApi(fetchMilestone(milestoneId)).then(({ payload: milestone }) => {
			this.setState({ milestone, isLoading: false })
		})
	}

	handlePercentIncrease = (newWashCountPercentIncrease) => {
		this.setState({ newWashCountPercentIncrease })
	}

	handlePriceIncrease = (newWashCountPriceIncrease) => {
		this.setState({ newWashCountPriceIncrease })
	}

	handleBackToList = () => {
		this.props.history.push(
			`/machine-milestone/groups/${this.state.milestone.milestone_group_id}`
		)
	}

	viewDetailsButton = (item) => (
		<Button
			justIcon
			round
			simple
			className="edit"
			color="danger"
			disabled={!this.props.isMilestoneAdmin()}
			onClick={(e) => {
				e.stopPropagation()
				this.handleRemoveLine(item)
			}}
		>
			<Cancel />
		</Button>
	)

	handleDescriptionUpdate = (e) => {
		this.setState({ newDescription: e.target.value })
	}

	handleNewIsActiveUpdate = (newIsActive) => {
		this.setState({ newIsActive })
	}

	handleDiscountPercentUpdate = (newDiscountPercent) => {
		this.setState({ newDiscountPercent })
	}

	handleNewMaxAdminDiscount = (newMaxAdminDiscount) => {
		this.setState({ newMaxAdminDiscount })
	}

	handleNewMaxUserDiscount = (newMaxUserDiscount) => {
		this.setState({ newMaxUserDiscount })
	}

	handleAgeInMonthUpdate = (ageInMonths) => {
		this.setState({ ageInMonths })
	}

	handleWashCountUpdate = (washCount) => {
		this.setState({ washCount })
	}

	handlePotentialDaysDown = (potentialDaysDown) => {
		this.setState({ potentialDaysDown })
	}

	handleCancelEditing = () => {
		this.setState({
			isEditing: false,
			newDescription: "",
			newIsActive: "true",
			newDiscountPercent: 0,
			ageInMonths: 0,
			washCount: 0,
			potentialDaysDown: 0,
			newWashCountPercentIncrease: 0,
			newWashCountPriceIncrease: 0,
			newMaxUserDiscount: 0,
			newMaxAdminDiscount: 0,
		})
	}

	handleEditMilestone = () => {
		let { milestone } = this.state
		this.setState({
			newDescription: milestone.description,
			newIsActive: milestone.is_active ? "true" : "false",
			newDiscountPercent: milestone.discount_percent ? milestone.discount_percent * 100 : 0,
			newWashCountPriceIncrease: milestone.wash_count_price_increase,
			newWashCountPercentIncrease:
				milestone.wash_count_percent_increase ?
					milestone.wash_count_percent_increase * 100
				:	0,
			ageInMonths: milestone.age_in_months ? milestone.age_in_months : 0,
			potentialDaysDown: milestone.potential_down_days ? milestone.potential_down_days : 0,
			washCount: milestone.wash_count ? milestone.wash_count : 0,
			newMaxUserDiscount:
				milestone.user_max_discount_percent ?
					milestone.user_max_discount_percent * 100
				:	0,
			newMaxAdminDiscount:
				milestone.admin_max_discount_percent ?
					milestone.admin_max_discount_percent * 100
				:	0,
			isEditing: true,
		})
	}

	handleSaveEditMilestone = () => {
		let {
			newDescription,
			newIsActive,
			newDiscountPercent,
			ageInMonths,
			washCount,
			milestone,
			potentialDaysDown,
			newWashCountPercentIncrease,
			newWashCountPriceIncrease,
			newMaxAdminDiscount,
			newMaxUserDiscount,
		} = this.state
		let data = {
			age_in_months: ageInMonths === 0 ? null : ageInMonths,
			wash_count: washCount === 0 ? null : washCount,
			description: newDescription,
			is_active: newIsActive,
			discount_percent: newDiscountPercent ? (newDiscountPercent / 100).toFixed(4) : 0,
			user_max_discount_percent:
				newMaxUserDiscount ? (newMaxUserDiscount / 100).toFixed(4) : 0,
			admin_max_discount_percent:
				newMaxAdminDiscount ? (newMaxAdminDiscount / 100).toFixed(4) : 0,
			milestone_group_id: milestone.machine_group_id,
			potential_down_days: potentialDaysDown,
			wash_count_price_increase: newWashCountPriceIncrease,
			wash_count_percent_increase:
				newWashCountPercentIncrease ? (newWashCountPercentIncrease / 100).toFixed(2) : 0,
		}
		this.setState({ isSaving: true })
		this.props.callApi(updateMilestone(milestone.id, data)).then(({ payload }) => {
			if (payload.error) {
				this.setState({ isSaving: false, isEditing: false })
				console.error(payload.error)
			} else {
				this.setState({ isSaving: false, isEditing: false })
				this.fetchMilestoneInfo()
			}
		})
	}

	handleAddLine = (line) => {
		let data = {
			part_id: line.partId,
			line_type_id: line.lineItemTypeId,
			description: line.description,
			quantity: line.quantity,
			milestone_id: this.state.milestone.id,
		}
		this.setState({ isSaving: false })
		this.props.callApi(addMilestoneLine(data)).then(({ payload }) => {
			if (payload.error) {
				this.setState({ isSaving: false })
				console.error(payload.error)
			} else {
				this.setState({ isSaving: false })
				this.fetchMilestoneInfo()
			}
		})
	}

	handleRemoveLine = (item) => {
		this.setState({ isLoading: true })
		this.props.callApi(removeMilestoneLine(item.id)).then(() => {
			this.fetchMilestoneInfo()
		})
	}

	render() {
		const {
			milestone,
			isLoading,
			isEditing,
			newDescription,
			washCount,
			ageInMonths,
			newIsActive,
			newDiscountPercent,
			potentialDaysDown,
			isSaving,
			newWashCountPercentIncrease,
			newWashCountPriceIncrease,
			newMaxUserDiscount,
			newMaxAdminDiscount,
		} = this.state
		const { classes, isMilestoneAdmin } = this.props
		return (
			<React.Fragment>
				<LoadingWrapper isLoading={isLoading} />

				<GridItem xs={12}>
					<ButtonWithIcon
						content="Back to Milestones"
						onClick={this.handleBackToList}
						color="white"
						size="sm"
						round
						icon={<ChevronLeft />}
					/>
				</GridItem>
				<QuickCard title={"Machine Milestone Detail"} icon={<LocalShipping />}>
					<GridContainer>
						<GridItem xs={12} sm={6} md={4}>
							{isEditing === false ?
								<InputAsDisplay
									labelText="Description"
									value={get(milestone, "description")}
								/>
							:	<Input
									labelText="Description"
									value={newDescription}
									onChange={this.handleDescriptionUpdate}
								/>
							}
						</GridItem>
						<GridItem xs={12} sm={6} md={4}>
							{isEditing === false || (ageInMonths && ageInMonths > 0) ?
								<InputAsDisplay
									labelText="Wash Counts"
									value={formatNumber(get(milestone, "wash_count", 0))}
								/>
							:	<NumericInput
									labelText="Wash Count"
									value={washCount}
									onChange={this.handleWashCountUpdate}
								/>
							}
						</GridItem>
						<GridItem xs={12} sm={6} md={4}>
							{isEditing === false || (washCount && washCount > 0) ?
								<InputAsDisplay
									labelText="Age of Machine (Months)"
									value={formatNumber(get(milestone, "age_in_months", 0))}
								/>
							:	<NumericInput
									labelText="Age (In Months)"
									value={ageInMonths}
									onChange={this.handleAgeInMonthUpdate}
								/>
							}
						</GridItem>

						<GridItem xs={12} sm={6} md={4}>
							{isEditing === false ?
								<InputAsDisplay
									labelText="Is Active"
									value={get(milestone, "is_active") ? "True" : "False"}
								/>
							:	<Select
									value={newIsActive}
									onChange={this.handleNewIsActiveUpdate}
									labelText="Is Active"
									valueAccessor="id"
									textAccessor="description"
									options={[
										{ id: "true", description: "True" },
										{ id: "false", description: "False" },
									]}
								/>
							}
						</GridItem>
						<GridItem xs={12} sm={6} md={4}>
							{isEditing === false ?
								<InputAsDisplay
									labelText="Discount Percent"
									value={formatPercentage(
										get(milestone, "discount_percent", 0),
										2
									)}
								/>
							:	<NumericInput
									labelText="Discount Percent"
									value={newDiscountPercent}
									decimal_places={2}
									onChange={this.handleDiscountPercentUpdate}
								/>
							}
						</GridItem>
						<GridItem xs={12} sm={6} md={4}>
							{isEditing === false ?
								<InputAsDisplay
									labelText="Max Admin Discount"
									value={formatPercentage(
										get(milestone, "admin_max_discount_percent", 0),
										2
									)}
								/>
							:	<NumericInput
									labelText="Max Admin Discount"
									value={newMaxAdminDiscount}
									decimal_places={2}
									onChange={this.handleNewMaxAdminDiscount}
								/>
							}
						</GridItem>
						<GridItem xs={12} sm={6} md={4}>
							{isEditing === false ?
								<InputAsDisplay
									labelText="Max User Discount"
									value={formatPercentage(
										get(milestone, "user_max_discount_percent", 0),
										2
									)}
								/>
							:	<NumericInput
									labelText="Max User Discount"
									value={newMaxUserDiscount}
									decimal_places={2}
									onChange={this.handleNewMaxUserDiscount}
								/>
							}
						</GridItem>
						<GridItem xs={12} sm={6} md={4}>
							{isEditing === false ?
								<InputAsDisplay
									labelText="Wash Count Percent Increase"
									value={formatPercentage(
										get(milestone, "wash_count_percent_increase", 0),
										2
									)}
								/>
							:	<NumericInput
									labelText="Wash Count Percent Increase"
									value={newWashCountPercentIncrease}
									decimal_places={2}
									onChange={this.handlePercentIncrease}
								/>
							}
						</GridItem>
						<GridItem xs={12} sm={6} md={4}>
							{isEditing === false ?
								<InputAsDisplay
									labelText="Wash Count Price Increase"
									value={formatNumber(
										get(milestone, "wash_count_price_increase", 0),
										2
									)}
								/>
							:	<NumericInput
									labelText="Wash Count Price Increase"
									value={newWashCountPriceIncrease}
									decimal_places={2}
									onChange={this.handlePriceIncrease}
								/>
							}
						</GridItem>
						<GridItem xs={12} sm={6} md={4}>
							{isEditing === false ?
								<InputAsDisplay
									labelText="Potential Days Down"
									value={formatNumber(get(milestone, "potential_down_days", 0))}
								/>
							:	<NumericInput
									labelText="Potential Days Down"
									value={potentialDaysDown}
									onChange={this.handlePotentialDaysDown}
								/>
							}
						</GridItem>
						<GridItem xs={12} sm={6} md={4}>
							<InputAsDisplay
								labelText="Created Date"
								value={formatDate(get(milestone, "created_date"))}
							/>
						</GridItem>
						<GridItem xs={12} className={classes.rightAlign} show={isMilestoneAdmin()}>
							{isEditing === false ?
								<GridItem xs={12} className={classes.rightAlign}>
									<ButtonWithIcon
										content="Edit Milestone"
										onClick={this.handleEditMilestone}
										icon={<Edit />}
										size="sm"
										color="warning"
										disabled={isSaving}
										loading={isSaving}
										round
									/>
									<AddLineItemDialog
										onSubmit={(line) => this.handleAddLine(line)}
									/>
								</GridItem>
							:	<GridItem xs={12} className={classes.rightAlign}>
									<ButtonWithIcon
										content="Cancel Editing"
										onClick={this.handleCancelEditing}
										icon={<Remove />}
										size="sm"
										color="warning"
										disabled={isSaving}
										loading={isSaving}
										round
									/>
									<ButtonWithIcon
										content="Save Milestone"
										onClick={this.handleSaveEditMilestone}
										icon={<Add />}
										size="sm"
										color="success"
										disabled={isSaving}
										loading={isSaving}
										round
									/>
								</GridItem>
							}
						</GridItem>
					</GridContainer>

					<DynamicTable // TODO: make props static
						loading={isLoading}
						data={get(milestone, "milestone_items", [])}
						useClientSideDataProcessing={false}
						useClientSidePaging={false}
						style={styles.center}
						columns={[
							...columns,
							{
								Header: "",
								accessor: this.viewDetailsButton,
								id: "actions",
								sortable: false,
								toggleable: false,
								maxWidth: 50,
							},
						]}
					/>
				</QuickCard>
			</React.Fragment>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		shipMethods: state.lookups.shipMethods,
		isMilestoneAdmin: () => {
			return meetsAppRestriction(state.auth, APPLICATIONS.MachineMilestoneAdmin)
		},
	}
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ callApi }, dispatch)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(withStyles(styles)(MilestoneDetail)))

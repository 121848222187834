import React from "react"
import moment from "moment"
import { string, instanceOf, oneOfType } from "prop-types"
import TimeAgo from "react-timeago"
import { formatLongDateTime } from "../../util/formatters"

class CustomTimeAgo extends React.PureComponent {
	render() {
		const { date, ...rest } = this.props

		return <TimeAgo date={date} title={formatLongDateTime(date)} {...rest} />
	}
}

CustomTimeAgo.propTypes = {
	date: oneOfType([string, instanceOf(Date), instanceOf(moment), instanceOf(null)]),
}

export default CustomTimeAgo

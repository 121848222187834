import { FC, useMemo, useState } from "react"

import { Controller, useFormContext, useWatch } from "react-hook-form"
import { useHistory } from "react-router-dom"

import { Customer, makeApiErrorMessage, useCreateDispatch, useCustomer } from "@ncs/ncs-api"
import { tryToFormatPhone } from "@ncs/ts-utils"
import {
	Box,
	Button,
	CheckboxFormField,
	CustomerSelector,
	DispatchCallTypeSelector,
	GridContainer,
	GridItem,
	GridItemProps,
	HeadingDivider,
	NumericInputFormField,
	PhoneInputFormField,
	TextareaFormField,
	TextInputFormField,
	useChangeCallback,
	useIsSaving,
	UserSelectorFormField,
	useToast,
} from "@ncs/web-legos"

import { AdvancedCustomerSearchModal, DispatchPrioritySelector } from "~/components"

import { assembleDispatchPost, CreateDispatchForm as CreateDispatchFormType } from "../../util"

export const CreateDispatchForm: FC = () => {
	const history = useHistory()
	const { makeSuccessToast, makeErrorToast } = useToast()
	const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null)
	const [showAdvSearch, setShowAdvSearch] = useState(false)
	const { isSaving, setSaving, endSaving } = useIsSaving()

	const { control, setValue, handleSubmit, formState, reset, getValues } =
		useFormContext<CreateDispatchFormType>()
	const { errors } = formState
	const [customerId] = useWatch({
		control,
		name: ["customerId"],
	})

	const [customerDetails, customerDetailsLoading] = useCustomer(customerId)
	const createDispatch = useCreateDispatch()

	// Keep the form up-to-date with what's selected for customer.
	useChangeCallback(selectedCustomer?.id, (newId) => {
		setValue("customerId", newId ?? "", {
			shouldDirty: true,
			shouldValidate: true,
		})
	})

	// Whenever we get details back for a customer selection, set a few values
	// in the form from it.
	useChangeCallback(customerDetails, (newDetails) => {
		reset({
			...getValues(),
			callBackNumber: tryToFormatPhone(newDetails?.phone) || null,
		})
	})

	const handleSave = async (form: CreateDispatchFormType) => {
		if (isNmsCustomer) {
			makeErrorToast("Selected customer is NMS")
			return
		}
		if (isBtoCustomer) {
			makeErrorToast("Selected customer is BTO")
			return
		}

		try {
			setSaving()
			const { data } = await createDispatch(assembleDispatchPost(form))
			makeSuccessToast(`Dispatch #${data.dispatch?.dispatch_number} successfully created`)
			history.push(`/service/dispatches/${data.dispatch?.id}`)
		} catch (e) {
			endSaving()
			makeErrorToast(makeApiErrorMessage(e))
		}
	}

	const isNmsCustomer = useMemo(() => {
		return (
			!!customerId &&
			!customerDetailsLoading &&
			(!customerDetails?.isFstActive || !customerDetails?.servicable)
		)
	}, [
		customerId,
		customerDetailsLoading,
		customerDetails?.isFstActive,
		customerDetails?.servicable,
	])

	const isBtoCustomer = useMemo(() => {
		return !!customerId && !customerDetailsLoading && customerDetails?.territory?.id === "1"
	}, [customerId, customerDetailsLoading, customerDetails?.territory?.id])

	const disableCreateButton = useMemo(() => {
		return isNmsCustomer || isBtoCustomer
	}, [isNmsCustomer, isBtoCustomer])

	const gridItemProps: GridItemProps = {
		xs: 12,
		sm: 6,
	}

	return (
		<div>
			<HeadingDivider mb={1.5} variant="h5">
				Create New Dispatch
			</HeadingDivider>

			<CustomerSelector
				value={selectedCustomer}
				onChange={setSelectedCustomer}
				error={
					isNmsCustomer ? "Customer marked as No More Service"
					: isBtoCustomer ?
						"Customer marked as BTO"
					:	errors.customerId?.message
				}
			/>
			<Button
				icon="search"
				onClick={() => setShowAdvSearch(true)}
				containerProps={{ mb: 1.5 }}
			>
				Advanced customer search
			</Button>
			<TextInputFormField
				control={control}
				name="caller"
				label="Caller"
				mb={1}
				emptyValueFallback={""}
			/>
			<TextareaFormField
				control={control}
				name="symptoms"
				label="Description"
				mb={1}
				emptyValueFallback={""}
			/>
			<GridContainer>
				<GridItem {...gridItemProps}>
					<TextInputFormField control={control} name="bay" label="Bay" />
				</GridItem>
				<GridItem {...gridItemProps}>
					<Controller
						control={control}
						name="callType"
						render={({ field: { value, onChange }, fieldState: { error } }) => {
							return (
								<DispatchCallTypeSelector
									value={value}
									onChange={(newValue) => {
										if (newValue) onChange(newValue)
									}}
									fillContainer
									disableNoSelectionOption
									error={error?.message}
								/>
							)
						}}
					/>
				</GridItem>
				<GridItem {...gridItemProps}>
					<PhoneInputFormField
						control={control}
						name="callBackNumber"
						label="Callback #"
					/>
				</GridItem>
				<GridItem {...gridItemProps}>
					<PhoneInputFormField
						control={control}
						name="altCallBackNumber"
						label="Alt callback #"
					/>
				</GridItem>{" "}
				<GridItem {...gridItemProps}>
					<Controller
						control={control}
						name="priority"
						render={({ field: { value, onChange }, fieldState: { error } }) => {
							return (
								<DispatchPrioritySelector
									value={value?.toString() ?? null}
									onChange={(newValue) => {
										if (newValue) onChange(newValue)
									}}
									fillContainer
									disableNoSelectionOption
									error={error?.message}
								/>
							)
						}}
					/>
				</GridItem>
				<GridItem {...gridItemProps}>
					<UserSelectorFormField
						control={control}
						name="assignedEmployeeId"
						label="Tech"
						employeesOnly
						emptyValueFallback={""}
					/>
				</GridItem>
				<GridItem {...gridItemProps}>
					<TextInputFormField
						control={control}
						name="poTicket"
						label="PO / ticket number"
					/>
				</GridItem>
				<GridItem {...gridItemProps}>
					<NumericInputFormField
						control={control}
						name="billNotToExceed"
						label="Bill not to exceed $"
						decimalScale={2}
						fixedDecimalScale
					/>
				</GridItem>
			</GridContainer>

			<Box d="flex" gap={2} flexWrap="wrap">
				<CheckboxFormField
					control={control}
					name="isOutOfChemicals"
					label="Out of chemicals?"
					fillContainer={false}
				/>
				<CheckboxFormField
					control={control}
					name="isReturnCall"
					label="Return call?"
					fillContainer={false}
				/>
			</Box>

			<Button
				variant="primary-cta"
				fillContainer
				containerProps={{ mt: 2 }}
				onClick={handleSubmit(handleSave)}
				isLoading={isSaving()}
				disabled={disableCreateButton}
			>
				Create Dispatch
			</Button>

			{showAdvSearch && (
				<AdvancedCustomerSearchModal
					onSave={setSelectedCustomer}
					onClose={() => setShowAdvSearch(false)}
				/>
			)}
		</div>
	)
}

import { FC } from "react"

import { Card, Icon, Tabs, useUrlState } from "@ncs/web-legos"

import { PurchaseOrdersCreateTab, PurchaseOrdersViewTab } from "./components"

enum PurchaseOrdersTabs {
	View = "View Purchase Orders",
	Create = "New Purchase Order",
}

export const PurchaseOrders: FC = () => {
	const [{ tab }, { updateUrlValue }] = useUrlState({
		tab: PurchaseOrdersTabs.View,
	})

	return (
		<Card heading="Purchase Orders" headingIcon="warehouse-alt">
			<Tabs
				currentTab={tab}
				onChange={(newTab) => updateUrlValue("tab", newTab)}
				panels={[
					{
						navLabel: PurchaseOrdersTabs.View,
						component: <PurchaseOrdersViewTab />,
						navIcon: <Icon icon="list" />,
					},
					{
						navLabel: PurchaseOrdersTabs.Create,
						component: <PurchaseOrdersCreateTab />,
						navIcon: <Icon icon="plus" />,
					},
				]}
			/>
		</Card>
	)
}

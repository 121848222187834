import { FC, memo, useState } from "react"

import { useFormContext } from "react-hook-form"

import { useEquipmentVintagesLookup, usePartCategories } from "@ncs/ncs-api"
import { addOrPromote } from "@ncs/ts-utils"
import { Box, Button, Chip, Label, Paragraph } from "@ncs/web-legos"

import { AddCategoryToPartModal } from "./AddCategoryToPartModal"
import { AddVintageToPartModal } from "./AddVintageToPartModal"
import { ParentAttributeCallout } from "./ParentAttributeCallout"
import { getVintageLabel, PosPartForm } from "./pos-products-utils"

export interface PosPartTaxonomyProps {}

export const PosPartTaxonomy: FC<PosPartTaxonomyProps> = memo(() => {
	const [categories] = usePartCategories()
	const [vintages] = useEquipmentVintagesLookup()

	const { setValue, getValues, watch } = useFormContext<PosPartForm>()
	const [categoryIds, vintageIds, relationship] = watch([
		"categoryIds",
		"vintageIds",
		"relationship",
	])

	const [showAddCategoryModal, setShowAddCategoryModal] = useState(false)
	const [showAddVintageModal, setShowAddVintageModal] = useState(false)

	const isChild = relationship === "child"

	return (
		<>
			<Paragraph mb={1}>
				Assigning categories and equipment types to products makes it easier for customers
				to find them in the shop.
			</Paragraph>
			<ParentAttributeCallout />

			<Label>Categories{isChild && " (inherited from parent)"}</Label>
			<Box
				display="flex"
				mt={0.25}
				mb={1}
				columnGap={0.5}
				rowGap={0.5}
				alignItems="center"
				flexWrap="wrap"
			>
				{categoryIds.map((categoryId) => {
					const category = (categories ?? []).find((c) => c.id.toString() === categoryId)
					return category ?
							<Chip
								key={categoryId}
								label={category.name || ""}
								onDelete={
									isChild ? undefined : (
										() => {
											setValue(
												"categoryIds",
												getValues("categoryIds").filter(
													(cId) => cId !== categoryId
												)
											)
										}
									)
								}
							/>
						:	null
				})}
				{categoryIds.length === 0 && isChild && <Paragraph>None</Paragraph>}
				{!isChild && (
					<Button icon="plus-circle" onClick={() => setShowAddCategoryModal(true)}>
						Add category
					</Button>
				)}
			</Box>

			<Label>Equipment types{isChild && " (inherited from parent)"}</Label>
			<Box
				display="flex"
				mt={0.25}
				columnGap={0.5}
				rowGap={0.5}
				alignItems="center"
				flexWrap="wrap"
			>
				{vintageIds.map((vintageId) => {
					const vintage = (vintages ?? []).find((c) => c.id === vintageId)
					return vintage ?
							<Chip
								key={vintageId}
								label={getVintageLabel(vintage)}
								onDelete={
									isChild ? undefined : (
										() => {
											setValue(
												"vintageIds",
												getValues("vintageIds").filter(
													(vId) => vId !== vintageId
												)
											)
										}
									)
								}
							/>
						:	null
				})}
				{vintageIds.length === 0 && isChild && <Paragraph>None</Paragraph>}
				{!isChild && (
					<Button icon="plus-circle" onClick={() => setShowAddVintageModal(true)}>
						Add equipment type
					</Button>
				)}
			</Box>

			<AddCategoryToPartModal
				isOpen={showAddCategoryModal}
				onClose={() => setShowAddCategoryModal(false)}
				onSave={(newCatId) => {
					setValue("categoryIds", addOrPromote(newCatId, getValues("categoryIds")))
				}}
			/>
			<AddVintageToPartModal
				isOpen={showAddVintageModal}
				onClose={() => setShowAddVintageModal(false)}
				onSave={(newVintageId) => {
					setValue("vintageIds", addOrPromote(newVintageId, getValues("vintageIds")))
				}}
			/>
		</>
	)
})

PosPartTaxonomy.displayName = "PosPartTaxonomy"

import React from "react"

import {
	createStyles,
	Grid,
	GridProps as MaterialUIGridProps,
	makeStyles,
} from "@material-ui/core"
import PropTypes from "prop-types"

const styles = createStyles({
	grid: {
		margin: "0 -15px",
		width: "calc(100% + 30px)",
	},
})
const useStyles = makeStyles(styles)

export interface GridContainerProps extends Omit<MaterialUIGridProps, "classes"> {
	/** flex-direction: default is row (left to right, top to bottom) */
	direction?: "row" | "row-reverse" | "column" | "column-reverse"

	/** flex-wrap: wrap is default */
	wrap?: "nowrap" | "wrap" | "wrap-reverse"

	/** justify-content: controls alignment along the main axis (which is row by default). flex-start is default */
	justify?:
		| "flex-start"
		| "center"
		| "flex-end"
		| "space-between"
		| "space-around"
		| "space-evenly"

	/** align-items: controls alignment along the cross axis (perpendicular to justify-content's setting)
	 * stretch is default
	 */
	alignItems?: "flex-start" | "center" | "flex-end" | "stretch" | "baseline"

	/** align-content: controls alignment of lines when items wrap, in much the same was as justify-content controls
	 * alignment for individual items
	 * stretch is default
	 */
	alignContent?:
		| "stretch"
		| "center"
		| "flex-start"
		| "flex-end"
		| "space-between"
		| "space-around"

	show?: boolean
	hide?: boolean
}

const GridContainer: React.ForwardRefExoticComponent<GridContainerProps> = React.forwardRef(
	({ children, className, show, hide, justify, ...rest }, ref) => {
		const classes = useStyles()

		if (typeof show === typeof hide && typeof show !== "undefined") {
			throw new Error(
				"The `show` and `hide` props are mutually exclusive. Please provide just one, " +
					"whichever makes the most sense for your conditional statement"
			)
		}

		const shouldShow =
			typeof show === "boolean" ? show
			: typeof hide === "boolean" ? !hide
			: true

		if (!shouldShow) {
			return null
		}

		return (
			<Grid
				container
				ref={ref}
				// The `justify` prop is deprecated, so we'll rename it here to its update.
				justifyContent={justify}
				{...rest}
				className={classes.grid + " " + className}
			>
				{shouldShow && (typeof children === "function" ? children() : children)}
			</Grid>
		)
	}
)

GridContainer.propTypes = {
	/** flex-direction: default is row (left to right, top to bottom) **/
	direction: PropTypes.oneOf(["row", "row-reverse", "column", "column-reverse"]),

	/** flex-wrap: wrap is default **/
	wrap: PropTypes.oneOf(["nowrap", "wrap", "wrap-reverse"]),

	/** justify-content: controls alignment along the main axis (which is row by default). flex-start is default **/
	justify: PropTypes.oneOf([
		"flex-start",
		"center",
		"flex-end",
		"space-between",
		"space-around",
		"space-evenly",
	]),

	/** align-items: controls alignment along the cross axis (perpendicular to justify-content's setting)
	 * stretch is default
	 **/
	alignItems: PropTypes.oneOf(["flex-start", "center", "flex-end", "stretch", "baseline"]),

	/** align-content: controls alignment of lines when items wrap, in much the same was as justify-content controls
	 * alignment for individual items
	 * stretch is default
	 */
	alignContent: PropTypes.oneOf([
		"stretch",
		"center",
		"flex-start",
		"flex-end",
		"space-between",
		"space-around",
	]),
	show: PropTypes.bool,
	hide: PropTypes.bool,
}

export default GridContainer

import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import callApi from "@ncs/bricks/redux/services/callApi"
import { placeOrder } from "@ncs/mortar/redux/services/marketing"
import { handleEmptyCart } from "~/redux/modules/marketingMaterialsModule"
import Checkout from "./Checkout"
import FormValidator from "@ncs/bricks/util/formValidator"

class CheckoutContainer extends React.Component {
	constructor(props) {
		super(props)

		this.validations = {
			shipping_address1: { stateName: "name", isRequired: true, maxLength: 255 },
			shipping_address2: { stateName: "address1", maxLength: 255 },
			shipping_address3: { stateName: "address2", maxLength: 255 },
			shipping_city: { stateName: "city", maxLength: 255 },
			shipping_state: { stateName: "addressState", maxLength: 255 },
			shipping_zip: { stateName: "postalCode", maxLength: 11 },
		}

		this.formValidator = new FormValidator(this, this.validations)

		this.state = {
			isPlacingOrder: false,
			showSuccessModal: false,
			showFailureModal: false,
			...this.formValidator.getInitialFormState(),
		}
	}

	componentDidMount = () => {
		// TODO: load shipping details from latest order for this user
		this.loadStateFromProps()
		this.formValidator.dataIsValid()
	}

	loadStateFromProps = () => {
		let changedState = {}

		for (let field of Object.keys(this.validations)) {
			if (
				this.props[this.validations[field].stateName] !==
				this.state[this.validations[field].stateName]
			)
				changedState[this.validations[field].stateName] = this.props[field]
		}

		this.setState(changedState)
	}

	handlePlaceOrder = () => {
		// TODO: fix validations, if we care about them enough
		// if (!this.formValidator.dataIsValid()) {
		// 	this.setState({showValidationErrors: true})
		// 	return
		// }

		let postData = Object.keys(this.validations).reduce(
			(obj, field) => ({
				...obj,
				[field]: this.state[this.validations[field].stateName],
			}),
			{
				items: Object.keys(this.props.cart).map((item_id) => ({
					item_id: Number(item_id),
					quantity: this.props.cart[item_id].quantity,
				})),
			}
		)

		this.setState({ isPlacingOrder: true })
		this.props.callApi(placeOrder(postData)).then((result) => {
			this.setState({
				isPlacingOrder: false,
				showSuccessModal: !result.error,
				showFailureModal: !!result.error,
			})
		})
	}

	handleCloseSuccessModal = () => {
		this.setState({ showSuccessModal: false })
		this.props.handleEmptyCart()
		this.props.history.push("/marketing/materials")
	}

	handleCloseFailureModal = () => {
		this.setState({ showFailureModal: false })
	}

	handleBackToCart = () => {
		this.props.history.push("/marketing/cart")
	}

	render() {
		return (
			<Checkout
				items={Object.values(this.props.cart)}
				onPlaceOrder={this.handlePlaceOrder}
				isPlacingOrder={this.state.isPlacingOrder}
				showSuccessModal={this.state.showSuccessModal}
				onCloseSuccessModal={this.handleCloseSuccessModal}
				showFailureModal={this.state.showFailureModal}
				onCloseFailureModal={this.handleCloseFailureModal}
				onViewCart={this.handleBackToCart}
				{...this.formValidator.generateFormProps()}
			/>
		)
	}
}

CheckoutContainer.propTypes = {
	cart: PropTypes.object.isRequired,
}

const mapStateToProps = ({ marketingMaterialsModule }) => {
	return {
		...marketingMaterialsModule,
	}
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ callApi, handleEmptyCart }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutContainer)

import React from "react"

import { connect } from "react-redux"
import { Redirect } from "react-router-dom"

import { userId } from "@ncs/bricks/redux/selectors"

class UserAdminContainer extends React.PureComponent {
	render = () => <Redirect to={`/user-management/${this.props.userId()}`} />
}

const mapStateToProps = (state) => ({
	userId: () => userId(state),
})

export default connect(mapStateToProps, null)(UserAdminContainer)

export const submissionControlsStyles = {
	submissionControls: {
		marginTop: "1rem",
		"& .MuiButton-label": {
			fontWeight: "bold",
		},
	},
	message: {
		textAlign: "right",
		height: "1.5rem",
	},
	buttonRow: {
		display: "flex",
		alignItems: "center",
	},
}

import { FC, useMemo } from "react"

import { ChemicalCustomerDetail, useChemicalCustomerDetail } from "@ncs/ncs-api"

import { ExtendableSelectProps, Select } from "../inputs"

export interface CustomerChemicalContainerSelectorProps
	extends ExtendableSelectProps<ChemicalContainer> {
	customerId: string | null | undefined
}

type ChemicalContainer = ChemicalCustomerDetail["containers"][number]

export const CustomerChemicalContainerSelector: FC<CustomerChemicalContainerSelectorProps> = ({
	customerId,
	...rest
}) => {
	const [details, detailsLoading] = useChemicalCustomerDetail(customerId)

	const options = useMemo(() => {
		return (details?.containers ?? [])
			.filter((container) => container.isActive)
			.sort((a, b) => {
				if (a.name === b.name) return 0
				if (a.name == null) return 1
				if (b.name == null) return -1

				return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
			})
	}, [details?.containers])

	return (
		<Select
			disableNoSelectionOption={false}
			disabled={detailsLoading}
			label="Container"
			{...rest}
			valueAccessor={(option) => option.id}
			textAccessor={(option) => option.name || "(unnamed)"}
			options={options}
		/>
	)
}

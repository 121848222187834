import { FC, Fragment, useEffect, useState } from "react"

import {
	BillOfMaterial,
	BinLocation,
	InventoryLocation,
	makeApiErrorMessage,
	useCompleteBillOfMaterial,
	useInventoryPart,
} from "@ncs/ncs-api"
import { extractNumber } from "@ncs/ts-utils"
import {
	Box,
	Button,
	ConfirmationModal,
	ConfirmationModalConfig,
	CssGridTable,
	Divider,
	EmptyValueDash,
	ErrorText,
	GridContainer,
	GridItem,
	Heading,
	Label,
	LabeledData,
	LocationBinSelector,
	LocationSelector,
	NumericInput,
	Paragraph,
	PartImage,
	useToast,
} from "@ncs/web-legos"

import { confirmMixBomMessage } from "../chemical-pour-down-util"

export interface MixRecipeModalMixRecipeTabProps {
	bom: BillOfMaterial
	closeModal: () => void
}

export const MixRecipeModalMixRecipeTab: FC<MixRecipeModalMixRecipeTabProps> = ({
	bom,
	closeModal,
}) => {
	const { makeSuccessToast } = useToast()
	const [quantity, setQuantity] = useState<number | null>(1)
	const [location, setLocation] = useState<InventoryLocation | null>(null)
	const [fromBin, setFromBin] = useState<BinLocation | null>(null)
	const [toBin, setToBin] = useState<BinLocation | null>(null)
	const [errorText, setErrorText] = useState<string | null>(null)
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)

	const [partDetails, partDetailsLoading] = useInventoryPart(bom.partId)
	const completeBom = useCompleteBillOfMaterial()

	const onSubmit = async () => {
		try {
			if (!quantity) throw new Error("Choose a quantity above 0")
			if (!location) throw new Error("Select a location")
			if (!fromBin || !toBin) throw new Error("Select a FROM bin and a TO bin")

			await completeBom({
				bomId: bom.id,
				fromBinId: fromBin.id,
				toBinId: toBin.id,
				quantity,
			})
			makeSuccessToast("Usage recorded")
			closeModal()
		} catch (e) {
			setErrorText(makeApiErrorMessage(e))
		}
	}

	useEffect(() => {
		setErrorText(null)
	}, [quantity, location?.id, fromBin?.id, toBin?.id])

	return (
		<>
			<GridContainer rowGap={0}>
				<GridItem xs={12} sm={6}>
					<Label>Recipe</Label>
					<Heading variant="h2" mb={1}>
						{bom.name}
					</Heading>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<PartImage
						src={partDetails?.imageUrl}
						isLoading={partDetailsLoading}
						maxWidth={10}
						ml="auto"
						mb={0.5}
						xsProps={{ mr: "auto" }}
					/>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<NumericInput
						label="Quantity to mix"
						value={quantity}
						onChange={(newValue) => setQuantity(newValue ?? null)}
						decimalScale={0}
					/>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<LabeledData label="Resulting chemical">
						{bom.partId ?
							`(${bom.partNumber}) ${bom.partDescription}`
						:	<EmptyValueDash />}
					</LabeledData>
				</GridItem>
				<GridItem xs={12}>
					<LocationSelector
						value={location}
						onChange={(newLocation) => {
							setLocation(newLocation)
							if (!newLocation) {
								setFromBin(null)
								setToBin(null)
							}
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<LocationBinSelector
						locationId={location?.id ?? null}
						value={fromBin}
						onChange={setFromBin}
						label="Reduce ingredients stock from bin"
					/>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<LocationBinSelector
						locationId={location?.id ?? null}
						value={toBin}
						onChange={setToBin}
						label="Add result stock to bin"
					/>
				</GridItem>
			</GridContainer>

			<Divider />

			<CssGridTable
				gap={1}
				gridTemplateColumns="1fr auto auto"
				headers={["Ingredient", "Usage", "Total usage"]}
				cells={(bom.items ?? []).map((item) => {
					return (
						<Fragment key={item.id}>
							<Paragraph small>
								{item.partId ?
									`(${item.partNumber}) ${item.partDescription}`
								:	item.description}
							</Paragraph>
							<Paragraph small>{item.quantity ?? 0}</Paragraph>
							<Paragraph small>
								{quantity ?
									extractNumber(((item.quantity ?? 0) * quantity).toFixed(3))
								:	"-"}
							</Paragraph>
						</Fragment>
					)
				})}
			/>

			<Box mt={3}>
				<Button
					variant="primary-cta"
					fillContainer
					onClick={() => {
						setConfirmationConfig({
							title: "Complete Recipe",
							message: confirmMixBomMessage,
							onConfirm: async () => {
								await onSubmit()
							},
						})
					}}
					disabled={bom.isActive === false}
				>
					Complete Recipe
				</Button>
			</Box>

			{!!errorText && <ErrorText mt={2}>{errorText}</ErrorText>}

			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</>
	)
}

import { ChangeEvent, FC, useMemo, useState } from "react"

import { css, useTheme } from "@emotion/react"
import { transparentize } from "polished"

import { LoadingSpinner } from "../transitions"
import { Icon, IconFamily, IconName } from "../typography"
import { FieldContainer, FieldContainerProps } from "./FieldContainer"

export enum FileType {
	Images = "image/*",
	Pdf = "application/pdf",
	Word = "application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document", // cspell:disable-line
	Text = "text/plain, application/rtf",
	Csv = "text/csv",
	Excel = "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // cspell:disable-line
}

export interface FileInputProps extends FieldContainerProps {
	onChange: (newValue: File) => void
	/** You'll need to specify this if you have more than on FileInput on a page. */
	id?: string
	label?: string
	icon?: IconName
	iconFamily?: IconFamily
	fileTypes: FileType[]
	multiple?: boolean
	/** Max file size, in MB */
	maxSize?: number
	isLoading?: boolean
	buttonVariant?: "text" | "secondary-cta"
}

export const FileInput: FC<FileInputProps> = ({
	id = "file-input",
	onChange,
	label = "Choose file",
	icon = "upload",
	iconFamily = "regular",
	fileTypes,
	multiple = false,
	maxSize = 5, // 5 MB
	isLoading,
	buttonVariant = "secondary-cta",
	fillContainer,
	...rest
}) => {
	const { palette } = useTheme()
	const [errorText, setErrorText] = useState<string | null>(null)

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files
		const file = files ? files[0] : null

		if (file) {
			if (file.size / 1024 / 1024 > maxSize) {
				setErrorText(`File is too big. Max size is ${maxSize} MB`)
			} else {
				onChange(file)
			}
		}
	}

	const containerCss = useMemo(() => {
		return css`
			opacity: ${isLoading ? 0.7 : undefined};
			pointer-events: ${isLoading ? "none" : undefined};
		`
	}, [isLoading])

	const labelCss = useMemo(() => {
		return css`
			position: relative;
			display: ${fillContainer ? "block" : "inline-block"};
			text-align: center;
			cursor: pointer;
			color: ${palette.primary.main};
			font-weight: bold;
			font-size: 1rem;
			line-height: 1rem;
			svg {
				margin-right: 0.45rem;
			}
		`
	}, [palette.primary.main, fillContainer])

	const buttonVariantCss = useMemo(() => {
		switch (buttonVariant) {
			case "secondary-cta": {
				return css`
					padding: 0.9rem 1.95rem;
					border: 2px solid ${palette.primary.main};
					border-radius: 4px;
					text-transform: uppercase;
					font-size: 15px;
					&:hover {
						background: ${transparentize(0.95, palette.cta.light)};
						color: ${palette.cta.light};
						border-color: ${palette.cta.light};
					}
				`
			}
			case "text": {
				return css`
					text-decoration: underline;
					font-size: 15px;
				`
			}
		}
	}, [buttonVariant, palette.cta.light, palette.primary.main])

	return (
		<FieldContainer error={errorText} {...rest}>
			<div css={containerCss}>
				<label htmlFor={id} css={[labelCss, buttonVariantCss]}>
					{isLoading && (
						<div css={loadingSpinnerCss}>
							<LoadingSpinner py={0} />
						</div>
					)}
					<Icon icon={icon} family={iconFamily} />
					{label}
					<input
						value=""
						id={id}
						type="file"
						css={inputStyle}
						onChange={handleChange}
						accept={fileTypes.join(", ")}
						multiple={multiple}
					/>
				</label>
			</div>
		</FieldContainer>
	)
}

const inputStyle = css`
	display: none;
`
const loadingSpinnerCss = css`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
`

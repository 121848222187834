import { FC, useState } from "react"

import { PartOrderList, usePartOrderList } from "@ncs/ncs-api"
import { DateFormat, formatDate } from "@ncs/ts-utils"

import { ExtendableSearchableSelectProps, SearchableSelect } from "../inputs"

export interface PartOrderSelectorProps
	extends ExtendableSearchableSelectProps<PartOrderList[number]> {}

export const PartOrderSelector: FC<PartOrderSelectorProps> = ({ onChange, ...rest }) => {
	const [searchInput, setSearchInput] = useState<string | null>(null)

	const ordersQuery = usePartOrderList({
		params: {
			search: searchInput,
		},
		pageSize: 10,
		queryConfig: {
			enabled: !!searchInput,
		},
	})

	return (
		<SearchableSelect
			isLoading={ordersQuery.isLoading}
			label="Part order"
			placeholder="Search for a part order..."
			{...rest}
			onItemSelect={onChange}
			getOptionLabel={(option) =>
				`#${option.orderId.toString()} - ${formatDate(
					option.orderDate,
					DateFormat.DateShort
				)} - ${option.customerName}`
			}
			options={ordersQuery.data}
			onInputChange={setSearchInput}
		/>
	)
}

import { FC, useCallback, useMemo, useState } from "react"

import dayjs, { Dayjs } from "dayjs"
import { Cell, Column } from "react-table"

import {
	APPLICATION,
	BinLocation,
	CompletePickListPost,
	createDocumentToken,
	getDocumentUrlForToken,
	InventoryLocation,
	makeApiErrorMessage,
	PartOrderPickList,
	PickListStatus,
	UpdatePickListPatch,
	useCompletePickList,
	useLocationBins,
	usePickListDocument,
	useRepairPickListStatus,
	useUpdatePartOrderPickList,
	useUserCanUse,
	WorkOrderDocument,
} from "@ncs/ncs-api"
import {
	extractNumber,
	formatDateTime,
	formatNumber,
	getTimezoneAbbreviation,
} from "@ncs/ts-utils"
import {
	Box,
	Button,
	ButtonProps,
	Callout,
	ConfirmationModal,
	ConfirmationModalConfig,
	DateTimeInput,
	EditableCell,
	EmptyValueDash,
	ExtendableModalProps,
	GridContainer,
	GridItem,
	Label,
	LabeledData,
	LetterIcon,
	LocationSelector,
	Modal,
	Paragraph,
	SimpleBinSelector,
	Table,
	ThrottledTextarea,
	Tooltip,
	useChangeCallback,
	useIsSaving,
	useToast,
} from "@ncs/web-legos"

import { PickListReassignModal } from "./PickListReassignModal"

export interface PartOrderPickListModalProps extends ExtendableModalProps {
	partOrderId: string
	partOrderOrderId: number
	pickList: PartOrderPickList
}

export const PartOrderPickListModal: FC<PartOrderPickListModalProps> = ({
	onClose,
	partOrderId,
	pickList,
	partOrderOrderId,
	...rest
}) => {
	const { makeSuccessToast, makeErrorToast } = useToast()
	const hasPickListPermission = useUserCanUse(APPLICATION.PickList)
	const [errorText, setErrorText] = useState<string | null>(null)
	const [selectedLocation, setSelectedLocation] = useState<InventoryLocation | null>(null)
	const [selectedBin, setSelectedBin] = useState<BinLocation | null>(null)
	const [submitAttempted, setSubmitAttempted] = useState(false)
	const [formState, setFormState] = useState<Record<string, number>>({})
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)
	const [showReassignModal, setShowReassignModal] = useState(false)

	const [pickListDocument] = usePickListDocument(pickList.id)
	const [bins, binsLoading] = useLocationBins(selectedLocation?.id)
	const suggestedShipHoldBin = useMemo(() => {
		return (bins ?? []).find((bin) => bin.code === "Ship / Hold")
	}, [bins])
	const [newComment, setNewComment] = useState(pickList.comment)
	const [newShipDate, setNewShipDate] = useState<Dayjs | null>(
		pickList.shipDate ? dayjs(pickList.shipDate) : null
	)
	const { isSaving, setSaving, endSaving } = useIsSaving<
		keyof UpdatePickListPatch | "loading-doc"
	>()

	const completePickList = useCompletePickList()
	const updatePickList = useUpdatePartOrderPickList(partOrderId)
	const repairStatus = useRepairPickListStatus(partOrderId)

	const checkIfValid = useCallback(
		(currentState: {
			selectedBin: BinLocation | null
		}): currentState is {
			selectedBin: BinLocation
		} => {
			const { selectedBin: currentSelectedBin } = currentState

			if (binsLoading) {
				return false
			}

			if (
				Object.entries(formState).some(([id, formQuantity]) => {
					const originalLine = pickList.items.find((item) => item.id === id)
					if (!originalLine) {
						console.error("Line found in form state not in Pick List items array")
						return true
					}
					return formQuantity > originalLine.quantity
				})
			) {
				setErrorText(
					"Quantity Picked cannot be greater than the line's Pick List Quantity"
				)
				return false
			}

			if (!selectedLocation) {
				setErrorText("Please select a location")
				return false
			}

			if (!currentSelectedBin) {
				setErrorText("Please select a bin")
				return false
			}

			if ((bins ?? []).some((bin) => bin.id === currentSelectedBin.id) === false) {
				setErrorText(
					"Bin selected is not at location selected, please choose your bin again."
				)
				setSelectedBin(null)
				return false
			}

			setErrorText(null)
			return true
		},
		[bins, binsLoading, formState, pickList.items, selectedLocation]
	)

	const isValid = useMemo(() => {
		return checkIfValid({ selectedBin })
	}, [checkIfValid, selectedBin])

	const handleComplete = useCallback(async () => {
		setSubmitAttempted(true)
		const validatedState = { selectedBin }
		if (!checkIfValid(validatedState)) {
			return
		}
		const { selectedBin: currentSelectedBin } = validatedState

		try {
			const postData: CompletePickListPost = {
				pickListId: pickList.id,
				binId: currentSelectedBin.id,
				lines: Object.entries(formState).map(([id, quantity]) => ({ id, quantity })),
			}
			await completePickList(postData)
			makeSuccessToast("Pick list completed")
			onClose()
		} catch (e) {
			setErrorText(makeApiErrorMessage(e))
		}
	}, [
		checkIfValid,
		completePickList,
		formState,
		makeSuccessToast,
		onClose,
		pickList.id,
		selectedBin,
	])

	const handleDownload = async (document: WorkOrderDocument) => {
		try {
			setSaving("loading-doc")
			const { token } = await createDocumentToken(document.id, document.documentType)
			window.open(getDocumentUrlForToken(token), "_blank")
		} catch (e) {
			setErrorText(makeApiErrorMessage(e))
		} finally {
			endSaving("loading-doc")
		}
	}

	const handleUpdate = async <F extends keyof UpdatePickListPatch>(
		field: F,
		value: UpdatePickListPatch[F]
	) => {
		try {
			setSaving(field)
			await updatePickList({
				updates: {
					// Pass through...
					cancel: false,
					comment: pickList.comment,
					pickListId: pickList.id,
					shipDate: pickList.shipDate,
					// The update
					[field]: value,
				},
			})
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		} finally {
			endSaving(field)
		}
	}

	const handleCancel = useCallback(async () => {
		try {
			await updatePickList({
				updates: {
					cancel: true,

					// Pass through...
					pickListId: pickList.id,
					shipDate: pickList.shipDate,
					comment: pickList.comment,
				},
			})
			onClose()
			makeSuccessToast("Pick list canceled")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		}
	}, [
		makeErrorToast,
		makeSuccessToast,
		onClose,
		pickList.comment,
		pickList.id,
		pickList.shipDate,
		updatePickList,
	])

	const handleRepairStatus = useCallback(async () => {
		try {
			await repairStatus({
				updates: {
					pickListId: pickList.id,
					check: true,
					cancel: false,
				},
			})
			makeSuccessToast("Status recalculated")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		}
	}, [makeErrorToast, makeSuccessToast, pickList.id, repairStatus])

	// Reset some local state if the pick list changes.
	useChangeCallback(
		pickList,
		(newPickList) => {
			setFormState(
				Object.fromEntries(newPickList.items.map(({ id, quantity }) => [id, quantity]))
			)
			setNewShipDate(newPickList.shipDate ? dayjs(newPickList.shipDate) : null)
			setNewComment(newPickList.comment)
		},
		{ callOnSetup: true }
	)

	// Whenever the location changes, that'll change the bins, which will change the shipHoldBin.
	// Keep it up-to-date.
	useChangeCallback(suggestedShipHoldBin, (newShipHoldBin) => {
		setSelectedBin(newShipHoldBin ?? null)
	})

	const pickListItems = useMemo((): PartOrderPickList["items"] => {
		return pickList.items.sort((a, b) => {
			if (a.priority != null && b.priority != null) {
				return a.priority < b.priority ? -1 : 1
			}
			return 0
		})
	}, [pickList.items])

	const columns = useMemo(() => {
		const c: Column<PickListItem>[] = [
			{
				Header: "Part #",
				accessor: ({ partNumber }) => partNumber,
			},
			{
				Header: "Description",
				accessor: "description",
				Cell: ({ row: { original } }: Cell<PickListItem>) => {
					return (
						<Box d="flex" alignItems="center" gap={0.5}>
							<span>{original.description}</span>
							{original.hazmat === true && (
								<Tooltip title="Hazmat part">
									<LetterIcon letter="H" />
								</Tooltip>
							)}
						</Box>
					)
				},
			},
			{
				Header: "Bin",
				accessor: ({ binCode }) => binCode,
			},
			{
				Header: "Weight Per Unit",
				accessor: "weight",
				Cell: ({ row: { original } }: Cell<PickListItem>) =>
					original.weight ? formatNumber(original.weight) : <EmptyValueDash />,
			},
			{
				Header: `${
					pickList.status.description === PickListStatus.Picked ?
						"Quantity Picked"
					:	"Pick List Quantity"
				}`,
				accessor: ({ quantity }) => formatNumber(quantity),
			},
		]

		if (
			[PickListStatus.Open, PickListStatus.InProgress, PickListStatus.ReadyToPick].includes(
				pickList.status.description
			)
		) {
			c.push({
				Header: "Quantity Picked",
				id: "quantity-picked",
				Cell: ({ row: { original } }: Cell<PickListItem>) => {
					const value = formState[original.id]
					if (value == null) {
						console.error(
							"trying to render Quantity Picked but the line was not found in form state"
						)
						return null
					}

					return (
						<EditableCell
							placeholder="Qty"
							maxWidth={4}
							value={formState[original.id].toString()}
							onChange={(newValue) =>
								setFormState((prev) => ({
									...prev,
									[original.id]: extractNumber(newValue),
								}))
							}
						/>
					)
				},
			})
		}

		return c
	}, [pickList.status.description, formState])

	const canComplete =
		[PickListStatus.Open, PickListStatus.ReadyToPick].includes(pickList.status.description) &&
		hasPickListPermission

	const canEdit =
		[PickListStatus.Open, PickListStatus.ReadyToPick].includes(pickList.status.description) &&
		hasPickListPermission

	const canRecalculate =
		[PickListStatus.InProgress, PickListStatus.Open, PickListStatus.ReadyToPick].includes(
			pickList.status.description
		) && hasPickListPermission

	const totalListWeight = pickList.items.reduce((total, item) => {
		const userQuantity = formState[item.id] ?? 0
		return (total as number) + item.weight * userQuantity
	}, 0)

	const cancelButton: ButtonProps = useMemo(
		() => ({
			buttonText: "Cancel pick list",
			variant: "text",
			icon: "trash-alt",
			onClick: () =>
				setConfirmationConfig({
					title: "Cancel Pick List",
					message:
						"This will set the pick list's status to Removed and release allocations for its line items.",
					onConfirm: handleCancel,
				}),
		}),
		[handleCancel]
	)

	const recalculateButton: ButtonProps = useMemo(
		() => ({
			buttonText: "Recalculate status",
			variant: "text",
			icon: "sync",
			onClick: () => {
				setConfirmationConfig({
					title: "Recalculate Pick List Status",
					message:
						"This will examine this pick list's line items. If all are found to have been picked already, the pick list's status will update.",
					onConfirm: handleRepairStatus,
					confirmButtonText: "Recalculate status",
				})
			},
		}),
		[handleRepairStatus]
	)

	const completeButton: ButtonProps = useMemo(
		() => ({
			buttonText: "Complete Pick List",
			disabled: (submitAttempted && !isValid) || !bins?.length,
			onClick: () =>
				setConfirmationConfig({
					title: "Complete Pick List",
					message: "Confirm: Pick this pick list?",
					onConfirm: handleComplete,
				}),
		}),
		[bins?.length, handleComplete, isValid, submitAttempted]
	)

	return (
		<>
			<Modal
				title={`Pick List # ${pickList.id}`}
				titleDetail={`for Part Order # ${partOrderOrderId}`}
				{...rest}
				onClose={onClose}
				errorText={submitAttempted ? errorText : undefined}
				maxWidth="md"
				leftButtons={[
					...(canEdit ? [cancelButton] : []),
					...(canRecalculate ? [recalculateButton] : []),
				]}
				rightButtons={canComplete ? completeButton : undefined}
			>
				<Box display="flex" alignItems="center" gap={2} mb={1}>
					<Callout mb={2} noBorder>
						<Paragraph>
							<strong>Status:</strong> {pickList.status.description}
						</Paragraph>
					</Callout>

					<Button
						icon="print"
						containerProps={{ mb: 2 }}
						disabled={!pickListDocument}
						onClick={() => {
							if (pickListDocument) {
								void handleDownload(pickListDocument)
							}
						}}
						isLoading={isSaving("loading-doc")}
					>
						Download
					</Button>
				</Box>

				<GridContainer mb={2}>
					<GridItem xs={12} sm={6} md={3}>
						<LabeledData label="Location">
							({pickList.location.locationCode}) {pickList.location.description}
						</LabeledData>
					</GridItem>
					<GridItem xs={12} sm={6} md={3}>
						<LabeledData label={`Created (${getTimezoneAbbreviation()})`}>
							{formatDateTime(pickList.createdDate)}
						</LabeledData>
					</GridItem>
					<GridItem xs={12} sm={6} md={3}>
						<LabeledData label="Total list weight (lbs)">
							{formatNumber(Math.round(totalListWeight))}
						</LabeledData>
					</GridItem>
					<GridItem xs={12} sm={6} md={3}>
						<Label>Assigned to</Label>
						<Box display="flex" alignItems="center" gap={0.5}>
							<Paragraph>
								{pickList.assignedTo.firstName} {pickList.assignedTo.lastName}
							</Paragraph>
							{canEdit && (
								<Button icon="pencil" onClick={() => setShowReassignModal(true)}>
									Reassign
								</Button>
							)}
						</Box>
					</GridItem>
					<GridItem xs={12} sm={6} md={3}>
						<LabeledData
							label="Expected ship date"
							editingRender={() => (
								<DateTimeInput
									value={newShipDate}
									onChange={setNewShipDate}
									label={null}
									clearable
								/>
							)}
							onSaveEdit={
								canEdit ?
									() =>
										handleUpdate(
											"shipDate",
											newShipDate?.toISOString() ?? null
										)
								:	undefined
							}
							isSavingEdit={isSaving("shipDate")}
						>
							{pickList.shipDate ?
								formatDateTime(pickList.shipDate)
							:	<EmptyValueDash />}
						</LabeledData>
					</GridItem>
					<GridItem xs={12} sm={6} md={3}>
						<LabeledData
							label="Comments"
							editingRender={() => (
								<ThrottledTextarea
									value={newComment}
									onChange={setNewComment}
									label={null}
									placeholder="Comment..."
									rows={2}
								/>
							)}
							onSaveEdit={
								canEdit ? () => handleUpdate("comment", newComment) : undefined
							}
							isSavingEdit={isSaving("comment")}
						>
							{pickList.comment || <EmptyValueDash />}
						</LabeledData>
					</GridItem>
				</GridContainer>

				{pickList.status.description !== PickListStatus.Removed && (
					<Table
						columns={columns}
						data={pickListItems}
						disableAllSorting
						rowVerticalAlign="middle"
					/>
				)}

				{canComplete && (
					<>
						<GridContainer fillRightToLeft>
							<GridItem sm={12} md={6}>
								<LocationSelector
									value={selectedLocation}
									onChange={setSelectedLocation}
									initialId={pickList.location.id}
								/>
							</GridItem>
						</GridContainer>
						<GridContainer fillRightToLeft>
							<GridItem sm={12} md={6}>
								<SimpleBinSelector
									value={selectedBin}
									onChange={setSelectedBin}
									locationId={selectedLocation?.id ?? null}
									disabled={!selectedLocation}
									initialId={suggestedShipHoldBin?.id}
								/>
							</GridItem>
						</GridContainer>
					</>
				)}
			</Modal>

			{showReassignModal && (
				<PickListReassignModal
					pickListId={pickList.id}
					onClose={() => setShowReassignModal(false)}
				/>
			)}
			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</>
	)
}

type PickListItem = PartOrderPickList["items"][number]

import React, { Component } from "react"
import { withCallApi } from "~/components"
import { createTicketSubtype } from "@ncs/mortar/redux/services/ticketing"
import FormValidator from "@ncs/bricks/util/formValidator"
import { compose } from "redux"
import { withDynamicTableFilter } from "~/components"

import TicketSubtypeAdd from "./TicketSubtypeAdd"

const reduxKey = "ticketSubtypeManagementReduxKey"

class TicketSubtypeAddContainer extends Component {
	constructor(props) {
		super(props)

		this.validations = {
			description: {
				stateName: "description",
				isRequired: true,
				maxLength: 255,
			},
		}

		this.formValidator = new FormValidator(this, this.validations)

		this.state = {
			isAdding: false,
			user: null,
			isSaving: false,
			...this.formValidator.getInitialFormState(),
		}
	}

	handleSetInputRef = (input) => {
		this.input = input
	}

	handleSubtypeSave = () => {
		let subtype = {
			type: this.props.selectedTicketType,
		}
		for (const [prop, config] of Object.entries(this.validations)) {
			subtype[prop] = this.state[config.stateName]
		}

		this.props
			.callApi(createTicketSubtype(this.props.selectedTicketType, subtype))
			.then((result) => {
				if (result.error) {
					return this.setState({ error: result.error })
				} else {
					this.setState({
						success: true,
						newSubtypeMessage: "Subtype Added.",
					})
					this.setState({
						description: null,
						isAdding: false,
						isSaving: false,
					})
					this.props.onSubtypeSave()
				}
			})
	}
	handleSubtypeAddCancel = () => {
		this.setState({
			user: null,
			isAdding: false,
		})
	}
	handleSubtypeAdd = () => {
		this.setState({
			isAdding: true,
		})
	}

	render() {
		return (
			<TicketSubtypeAdd
				setInputRef={this.handleSetInputRef}
				isAdding={this.state.isAdding}
				isSaving={this.state.isSaving}
				onSaveSubtype={this.handleSubtypeSave}
				onCancelSubtypeAdd={this.handleSubtypeAddCancel}
				onSubtypeAdd={this.handleSubtypeAdd}
				success={this.state.success}
				error={this.state.error}
				{...this.formValidator.generateFormProps()}
			/>
		)
	}
}

export default compose(
	withDynamicTableFilter(
		"ticket_type",
		undefined,
		reduxKey,
		"setTicketType",
		"selectedTicketType"
	),
	withCallApi
)(TicketSubtypeAddContainer)

import { FC, useMemo } from "react"

import dayjs from "dayjs"

import {
	Customer,
	LaborRateTypeId,
	useCustomerLaborPricingHistory,
	useLaborPricings,
} from "@ncs/ncs-api"
import { displayDate, formatCurrency, formatDate } from "@ncs/ts-utils"
import {
	Box,
	cssMixins,
	ExtendableModalProps,
	Heading,
	Icon,
	Link,
	LoadingSpinner,
	Modal,
	Paragraph,
} from "@ncs/web-legos"

import { laborRateTypeDescriptions } from "~/components"

export interface PricingHistoryModalProps extends ExtendableModalProps {
	customer: Customer
	type: LaborRateTypeId
}

export const PricingHistoryModal: FC<PricingHistoryModalProps> = ({ customer, type, ...rest }) => {
	const [history, historyLoading] = useCustomerLaborPricingHistory(customer.id, {
		params: { type },
	})

	const territoryDefaultQuery = useLaborPricings({
		params: {
			territory: customer.territory?.id,
			laborRateType: type,
		},
		queryConfig: {
			enabled: !!customer.territory?.id,
		},
	})

	const defaultPricing = useMemo(() => {
		return territoryDefaultQuery.data.find(
			(d) => d.territory?.id === customer.territory?.id && d.zone === 1
		)
	}, [customer.territory?.id, territoryDefaultQuery.data])

	return (
		<Modal {...rest} title={`${laborRateTypeDescriptions[type]} Rate History`} maxWidth="md">
			<Box d="flex" gap={1}>
				<Heading variant="h6" flex={1} bold mb={0.35}>
					Pricing Group
				</Heading>
				<Heading variant="h6" flex={1} bold mb={0.35}>
					Rate
				</Heading>
			</Box>

			{historyLoading && <LoadingSpinner />}

			{(history ?? [])
				.sort((a, b) => (dayjs(a.startsOn).isAfter(b.startsOn) ? -1 : 1))
				.map((h, i) => {
					return (
						<Box key={h.membershipId} d="flex" bb={1} pb={1} mb={1} gap={1}>
							<Box flex={1}>
								<Box d="flex" gap={0.5} alignItems="baseline">
									<Heading variant="h4">
										{h.territory ?
											`(${h.territory.code}) ${h.territory.description}`
										:	h.description}
									</Heading>
									<Link
										newTab
										to={`/finance/labor-pricing/${h.laborPricingId}/`}
										icon="external-link"
										css={cssMixins.noWrap}
									>
										View
									</Link>
								</Box>
								{!!h.zone && (
									<Heading variant="h5" bold={false}>
										Zone {h.zone}
										{h.zone === 1 && " (Default)"}
									</Heading>
								)}
								<Paragraph small secondary>
									Membership {formatDate(h.startsOn, { formatInUtc: true })} -{" "}
									{displayDate(h.endsAfter, "(no end date)", {
										formatInUtc: true,
									})}
								</Paragraph>
							</Box>
							<Box flex={1}>
								{h.prices
									.sort((a, b) =>
										dayjs(a.startsOn).isAfter(b.startsOn) ? -1 : 1
									)
									.map((price) => {
										return (
											<Box key={price.id} mb={0.5}>
												<Heading variant="h4">
													{formatCurrency(price.price)}
												</Heading>
												<Paragraph small secondary>
													Group rate{" "}
													{formatDate(price.startsOn, {
														formatInUtc: true,
													})}{" "}
													-{" "}
													{displayDate(
														price.endsAfter,
														"(no end date)",
														{
															formatInUtc: true,
														}
													)}
												</Paragraph>
											</Box>
										)
									})}
							</Box>
						</Box>
					)
				})}

			{!historyLoading && !territoryDefaultQuery.isLoading && !!defaultPricing && (
				<Box d="flex" gap={0.5} mt={2} mb={4}>
					<Icon icon="info-circle" color="gray" fontSize={1.25} />
					<Paragraph mt={-0.1}>
						Based on their territory, for{" "}
						<strong>{laborRateTypeDescriptions[type].toLowerCase()}</strong> rate this
						customer will fallback to{" "}
						<Link
							to={`/finance/labor-pricing/${defaultPricing.id}`}
							icon="external-link"
							newTab
						>
							({defaultPricing.territory?.code}){" "}
							{defaultPricing.territory?.description} - Zone {defaultPricing.zone}
						</Link>{" "}
						for any dates not covered above.
					</Paragraph>
				</Box>
			)}
		</Modal>
	)
}

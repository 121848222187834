import { FC, useEffect, useRef } from "react"

import { ProductCatalog, useProductCatalogs } from "@ncs/ncs-api"

import { ExtendableSearchableSelectProps, SearchableSelect } from "../inputs"

export interface ProductCatalogSelectorProps
	extends ExtendableSearchableSelectProps<ProductCatalog> {
	initialId?: string | null
}

export const ProductCatalogSelector: FC<ProductCatalogSelectorProps> = ({
	onChange,
	initialId,
	...rest
}) => {
	const [catalogs, isLoading] = useProductCatalogs()
	const hasSetInitial = useRef(false)

	useEffect(() => {
		if (hasSetInitial.current === false && !rest.value && !!initialId) {
			const initialOption = (catalogs ?? []).find((o) => o.id.toString() === initialId)

			if (initialOption) {
				hasSetInitial.current = true
				onChange(initialOption)
			}
		}
	}, [catalogs, initialId, onChange, rest.value])

	return (
		<SearchableSelect
			label="Product catalog"
			{...rest}
			options={catalogs ?? []}
			onItemSelect={onChange}
			getOptionLabel={(option) => option.description}
			isLoading={isLoading}
		/>
	)
}

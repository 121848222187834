import React from "react"

import PropTypes from "prop-types"

import { SearchSelector, withCallApi } from "@ncs/bricks/components"

import { listParts } from "../../redux/services/inventory"

class PartSelector extends React.Component {
	handleGetSuggestions = (search) => {
		// note: SearchSelector handles debouncing for us
		return this.props.callApi(
			listParts({
				search,
				pageSize: 15,
				service: this.props.includeAllParts ? undefined : this.props.service,
				all_parts: this.props.includeAllParts,
				chemicals_only: this.props.chemicalsOnly,
				restricted: this.props.restricted,
				restrictions_customer: this.props.restrictionsCustomer,
				skip_restricted_check: this.props.skipRestrictedCheck,
			})
		)
	}

	handleGetSuggestionValue = (part) => {
		let replaced_by_part = part.replaced_by ? ` (replaced by ${part.replaced_by})` : ""
		return `${part.part_number}: ${part.description}${replaced_by_part}`
	}

	handleSuggestionSelected = (event, { suggestion }) => {
		this.props.onPartSelected(suggestion)
	}

	render = () => {
		return (
			<SearchSelector
				label="Part"
				placeholder="Search for a part"
				getSuggestions={this.handleGetSuggestions}
				getSuggestionValue={this.handleGetSuggestionValue}
				onSuggestionSelected={this.handleSuggestionSelected}
				{...this.props}
			/>
		)
	}
}

PartSelector.defaultProps = {
	service: true,
	chemicalsOnly: false,
}

PartSelector.propTypes = {
	callApi: PropTypes.func.isRequired,
	onPartSelected: PropTypes.func.isRequired,
	service: PropTypes.bool,
	includeAllParts: PropTypes.bool,
	chemicalsOnly: PropTypes.bool,
	// Only show parts that are restricted, or are not restricted.
	restricted: PropTypes.bool,
	// Pass in a customer ID to check for restricted parts permissions.
	restrictionsCustomer: PropTypes.string,
	// Don't do any checking on restricted status and just return them all regardless.
	skipRestrictedCheck: PropTypes.bool,
}

export default withCallApi(PartSelector)

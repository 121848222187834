import { FC, useState } from "react"

import { Column } from "react-table"

import {
	makeApiErrorMessage,
	ProductCatalog,
	ProductCatalogDetails,
	useProductCatalogDetails,
	useRemovePartFromCatalog,
} from "@ncs/ncs-api"
import { extractNumber } from "@ncs/ts-utils"
import {
	Button,
	ConfirmationModal,
	ConfirmationModalConfig,
	Table,
	useToast,
} from "@ncs/web-legos"

import { AddPartToCatalogModal } from "./AddPartToCatalogModal"

export interface CatalogPartsTabProps {
	catalog: ProductCatalog
}

const columns: Column<ProductCatalogDetails["parts"][number]>[] = [
	{
		Header: "Part #",
		accessor: "partNumber",
	},
	{
		Header: "Part Name",
		accessor: "description",
	},
]

export const CatalogPartsTab: FC<CatalogPartsTabProps> = ({ catalog }) => {
	const { makeSuccessToast, makeErrorToast } = useToast()
	const [showAddModal, setShowAddModal] = useState(false)
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)

	const [details, detailsLoading] = useProductCatalogDetails(catalog.id.toString())

	const removePart = useRemovePartFromCatalog()

	const onRemove = async (id: string) => {
		try {
			await removePart({
				body: {
					catalogId: catalog.id,
					onlinePartId: extractNumber(id),
				},
			})
			makeSuccessToast("Product removed from catalog")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		}
	}

	return (
		<>
			<Button
				variant="secondary-cta"
				icon="plus-circle"
				onClick={() => setShowAddModal(true)}
				containerProps={{ mb: 3 }}
			>
				Add product to catalog
			</Button>

			<Table
				columns={columns}
				data={details?.parts ?? []}
				isLoading={detailsLoading}
				noDataText="No products in catalog yet"
				rowMenu={[
					{
						label: "Remove",
						iconName: "trash",
						onClick: ({ original }) => {
							setConfirmationConfig({
								title: "Remove Product?",
								message: `Remove ${original.description} from ${catalog.description}?`,
								onConfirm: () => onRemove(original.onlinePartId),
							})
						},
					},
				]}
				infiniteRowsIncrement={100}
			/>

			<AddPartToCatalogModal
				isOpen={showAddModal}
				onClose={() => setShowAddModal(false)}
				catalog={catalog}
			/>
			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</>
	)
}

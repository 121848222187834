import { FC, useState } from "react"

import dayjs, { Dayjs } from "dayjs"

import {
	LaborPricingDetails,
	makeApiErrorMessage,
	useUpdateLaborPricingCustomers,
} from "@ncs/ncs-api"
import { DateFormat } from "@ncs/ts-utils"
import {
	Box,
	DateInput,
	ExtendableModalProps,
	Modal,
	Paragraph,
	useIsSaving,
	useToast,
} from "@ncs/web-legos"

export interface EditLaborMembershipsModalProps extends ExtendableModalProps {
	pricing: LaborPricingDetails
	memberships: string[]
}

export const EditLaborMembershipsModal: FC<EditLaborMembershipsModalProps> = ({
	pricing,
	memberships,
	...rest
}) => {
	const { makeSuccessToast } = useToast()
	const [newEndDate, setNewEndDate] = useState<Dayjs | null>(() => {
		if (memberships.length === 1) {
			const membership = pricing.customers.find((p) => p.membershipId === memberships[0])
			return membership?.membershipEndsAfter ? dayjs(membership.membershipEndsAfter) : null
		}
		return null
	})
	const [errorText, setErrorText] = useState<string | null>(null)
	const { isSaving, setSaving, endSaving } = useIsSaving()

	const updateCustomers = useUpdateLaborPricingCustomers(pricing.id)

	const handleSave = async () => {
		try {
			setSaving()
			await updateCustomers({
				updates: {
					memberships,
					newEndDate: newEndDate?.format(DateFormat.DateQueryParam) ?? null,
				},
			})
			makeSuccessToast("Membership end date updated")
			rest.onClose()
		} catch (e) {
			endSaving()
			setErrorText(makeApiErrorMessage(e))
		}
	}

	return (
		<Modal
			{...rest}
			title="Change Membership End Date"
			errorText={errorText}
			rightButtons={{
				buttonText: "Update memberships",
				onClick: handleSave,
				isLoading: isSaving(),
			}}
		>
			<Paragraph mb={1}>
				Set a new date for customer membership with this labor pricing to end. Leave blank
				to indicate no set end date.
			</Paragraph>
			<Box maxWidth="50%" smProps={{ maxWidth: "none" }}>
				<DateInput
					value={newEndDate}
					onChange={setNewEndDate}
					label="New end date (optional)"
					disablePast
					clearable
				/>
			</Box>
		</Modal>
	)
}

import React from "react"
import ForgottenPasswordForm from "./ForgotPasswordForm"
import { makeStyles } from "@material-ui/core/styles"

import { container } from "@ncs/bricks/assets/jss/material-dashboard-pro-react"
import { GridContainer, GridItem } from "~/components"

const pageStyles = {
	container,
	content: {
		paddingTop: "4vh",
		position: "relative",
		zIndex: "4",
	},
}

const useStyles = makeStyles(pageStyles)

const ForgotPasswordPage = () => {
	const classes = useStyles()

	return (
		<div className={classes.content}>
			<div className={classes.container}>
				<GridContainer justify="center">
					<GridItem xs={12} sm={6} md={4}>
						<ForgottenPasswordForm />
					</GridItem>
				</GridContainer>
			</div>
		</div>
	)
}

export default ForgotPasswordPage

import { FC, useState } from "react"

import { LineItemStockInfo, makeApiErrorMessage, useUpdatePartOrderLineItem } from "@ncs/ncs-api"
import {
	ConfirmationModal,
	ConfirmationModalConfig,
	ExtendableModalProps,
	Modal,
	Paragraph,
	Select,
	useToast,
} from "@ncs/web-legos"

export interface PickNewDcModalProps extends ExtendableModalProps {
	lineItemId: string
	locationId: string
	quantity: number
	options: LineItemStockInfo["locations"]
	onRerouteSuccess: () => void
}

export const PickNewDcModal: FC<PickNewDcModalProps> = ({
	lineItemId,
	locationId,
	quantity,
	options,
	onRerouteSuccess,
	...rest
}) => {
	const { makeSuccessToast } = useToast()
	const [newDcId, setNewDcId] = useState<string | null>(null)
	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)

	const updateLineItem = useUpdatePartOrderLineItem()

	const onSave = async () => {
		try {
			if (!newDcId) {
				throw new Error("Please select a DC")
			}

			setIsSaving(true)
			await updateLineItem({
				updates: {
					reroute: true,
					oldWarehouseId: locationId,
					newWarehouseId: newDcId,
					quantityMoving: Math.abs(quantity),
				},
				id: lineItemId,
			})
			makeSuccessToast("Line item rerouted")
			// This modal will be closed when the parent modal is closed through `onRerouteSuccess`.
			onRerouteSuccess()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	return (
		<Modal
			{...rest}
			errorText={errorText}
			title="Reroute Line Item"
			rightButtons={{
				buttonText: `Reroute (${Math.abs(quantity)})`,
				isLoading: isSaving,
				onClick: () => {
					void onSave()
				},
			}}
		>
			<Paragraph mb={0.5}>Choose a DC to move this request to:</Paragraph>
			<Select
				value={newDcId}
				onChange={setNewDcId}
				options={options.sort((a, b) => (a.locationName > b.locationName ? 1 : -1))}
				valueAccessor="locationId"
				textAccessor={(option) => `(${option.locationNumber}) ${option.locationName}`}
				fillContainer
			/>

			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</Modal>
	)
}

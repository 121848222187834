import { FC, Fragment, useState } from "react"

import { makeApiErrorMessage, useCreateLabel } from "@ncs/ncs-api"
import { formatNumber } from "@ncs/ts-utils"
import {
	blobSaver,
	Box,
	Button,
	CssGridTable,
	Heading,
	Label,
	Paragraph,
	useToast,
} from "@ncs/web-legos"

import { useShipShipmentContext } from "~/util/ship-shipment"

import { DivideShipmentModal } from "./components"

export const ShipmentVerifyContents: FC = () => {
	const { shipment, verifyStepComplete, setVerifyStepComplete } = useShipShipmentContext()
	const { makeSuccessToast, makeErrorToast } = useToast()
	const [showDivideModal, setShowDivideModal] = useState(false)
	const [isSaving, setIsSaving] = useState(false)

	const createLabels = useCreateLabel()

	const handleLabels = async () => {
		try {
			setIsSaving(true)
			const { data } = await createLabels({
				labelSize: "3x2",
				labels: shipment.lines.map((line) => ({
					label: line.partNumber,
					quantity: line.quantity,
				})),
			})
			blobSaver(data as Blob, `shipment_${shipment.shipmentId}_items.pdf`)
			makeSuccessToast("Labels created")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		} finally {
			setIsSaving(false)
		}
	}

	return (
		<>
			<Box display="flex" mb={2}>
				<Box flex={1}>
					<Label>Shipping from</Label>
					<Heading variant="h4" mt={0} mb={0}>
						{shipment.locationDescription}
					</Heading>
				</Box>
				{shipment.pickedToBin && (
					<Box flex={1}>
						<Label>Picked to bin</Label>
						<Heading variant="h4" mt={0} mb={0}>
							{shipment.pickedToBin}
						</Heading>
					</Box>
				)}
			</Box>

			<Paragraph mb={1}>
				This shipment will include the following items. Verify that all items are present
				in the quantities listed below.
			</Paragraph>

			<CssGridTable
				gridTemplateColumns="auto auto 1fr"
				headers={["Qty", "Part #", "Name"]}
				columnGap={1}
				rowGap={0.5}
				cells={shipment.lines.map((line) => (
					<Fragment key={line.id}>
						<span>{formatNumber(line.quantity)}</span>
						<span>{line.partNumber}</span>
						<span>{line.description}</span>
					</Fragment>
				))}
				mb={3}
			/>

			<Box d="flex" gap={2} flexWrap="wrap">
				{!verifyStepComplete && (
					<Button
						icon="check"
						variant="secondary-cta"
						onClick={() => setVerifyStepComplete(true)}
					>
						All Items Accounted For
					</Button>
				)}
				<Button icon="print" onClick={handleLabels} isLoading={isSaving}>
					Line item labels
				</Button>
				{!verifyStepComplete && (
					<Button icon="split" onClick={() => setShowDivideModal(true)}>
						Need to split up this shipment?
					</Button>
				)}
			</Box>

			{showDivideModal && <DivideShipmentModal onClose={() => setShowDivideModal(false)} />}
		</>
	)
}

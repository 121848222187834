import { FC, HTMLProps, useMemo } from "react"

import { css } from "@emotion/react"

export interface LetterIconProps extends HTMLProps<HTMLSpanElement> {
	letter: string
}

export const LetterIcon: FC<LetterIconProps> = ({ letter, ...rest }) => {
	const letterIconContainerCss = useMemo(() => {
		return css`
			display: inline-flex;
			align-items: center;
			justify-content: center;
			color: #0a3d5391;
			background: rgb(239, 245, 247);
			border: 1px solid rgba(10, 61, 83, 0.15);
			border-radius: 50%;
			width: 1.35rem;
			height: 1.35rem;
			font-size: 0.7rem;
			font-weight: bold;
			flex-shrink: 0;
		`
	}, [])

	return (
		<span css={letterIconContainerCss} {...rest}>
			{letter}
		</span>
	)
}

import { FC, useMemo, useState } from "react"

import { captureException } from "@sentry/react"

import {
	ChemicalCustomerDetail,
	makeApiErrorMessage,
	useUpdateContainerChemical,
} from "@ncs/ncs-api"
import {
	Box,
	Button,
	Callout,
	ConfirmationModal,
	ConfirmationModalConfig,
	ExtendableModalProps,
	Modal,
	Tabs,
	useToast,
} from "@ncs/web-legos"

import { ChemicalSettingsTab, ContainerTab, HistoryTab } from "./components"

export interface ContainerDetailModalProps extends ExtendableModalProps {
	customerContainers: ChemicalCustomerDetail["containers"]
	containerChemicalId: string
}

export const ContainerDetailModal: FC<ContainerDetailModalProps> = ({
	customerContainers,
	containerChemicalId,
	...rest
}) => {
	const { makeSuccessToast, makeErrorToast } = useToast()
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)

	const updateContainer = useUpdateContainerChemical()

	const container = useMemo(() => {
		return customerContainers.find((c) => c.id === containerChemicalId)
	}, [customerContainers, containerChemicalId])

	if (!container) {
		const message = "ContainerDetailModal was given a container ID it cannot find"
		captureException(message)
		console.error(message)
		return null
	}

	const changeActiveStatus = async (newState: boolean) => {
		try {
			await updateContainer({
				id: containerChemicalId,
				updates: { isActive: newState },
			})
			makeSuccessToast("Status updated")
			rest.onClose()
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		}
	}

	return (
		<>
			<Modal
				{...rest}
				title="Chemical Container Details"
				titleDetail={container.name || undefined}
				maxWidth="md"
				leftButtons={[
					{
						buttonText: container.isActive ? "Make inactive" : "Reactivate",
						variant: "text",
						icon: container.isActive ? "trash-alt" : "check",
						onClick: () =>
							setConfirmationConfig({
								title: "Update Status",
								message:
									container.isActive ?
										"Mark this container as inactive?"
									:	"Mark this container as active?",
								onConfirm: async () => {
									await changeActiveStatus(!container.isActive)
								},
							}),
					},
				]}
			>
				{!container.isActive && (
					<Callout variant="info">
						<Box display="flex" alignItems="center" gap={0.5}>
							<span>This chemical container is currently set to inactive.</span>
							<Button
								onClick={() =>
									setConfirmationConfig({
										title: "Update Status",
										message:
											container.isActive ?
												"Mark this container as inactive?"
											:	"Mark this container as active?",
										onConfirm: async () => {
											await changeActiveStatus(!container.isActive)
										},
									})
								}
							>
								Reactivate?
							</Button>
						</Box>
					</Callout>
				)}
				<Tabs
					panels={[
						{
							navLabel: "Chemical Settings",
							component: <ChemicalSettingsTab container={container} />,
						},
						{
							navLabel: "Container",
							component: <ContainerTab container={container} />,
						},
						{
							navLabel: "History",
							component: <HistoryTab container={container} />,
						},
					]}
				/>
			</Modal>

			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</>
	)
}

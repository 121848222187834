import { FC, memo, useMemo, useState } from "react"

import { APPLICATION, PartGroup, usePartGroups, useUserCanUse } from "@ncs/ncs-api"
import {
	AnimatedEntrance,
	Box,
	Button,
	Callout,
	Card,
	Divider,
	Heading,
	Icon,
	Label,
	PartGroupSelector,
	RecentItems,
	Tabs,
	useChangeCallback,
	useUrlState,
} from "@ncs/web-legos"

import { EditPartGroupModal } from "./EditPartGroupModal"
import { PartGroupsCustomersTab } from "./PartGroupsCustomersTab"
import { PartGroupsPartsTab } from "./PartGroupsPartsTab"

enum PartGroupsTab {
	GroupParts = "Group Parts",
	CustomerDiscounts = "Customer Discounts",
}

export type PartGroupsUrlState = {
	tab: PartGroupsTab
	id: string | null
}

const PartGroups: FC = () => {
	const isDiscountAdmin = useUserCanUse(APPLICATION.PartDiscountAdmin)
	const [{ tab, id }, { updateUrlValue }] = useUrlState<PartGroupsUrlState>({
		tab: PartGroupsTab.GroupParts,
		id: null,
	})
	const [selectedPartGroupId, setSelectedPartGroupId] = useState<string | null>(null)
	const { data } = usePartGroups()

	const selectedPartGroup = useMemo(() => {
		return data.find((d) => d.id === selectedPartGroupId) ?? null
	}, [data, selectedPartGroupId])

	const [groupToEdit, setGroupToEdit] = useState<PartGroup | null>(null)
	const [showGroupModal, setShowGroupModal] = useState(false)

	useChangeCallback(selectedPartGroupId, (newId) => updateUrlValue("id", newId))

	return (
		<Card
			heading="Part Groups"
			headingIcon="folders"
			renderRight={() =>
				isDiscountAdmin ?
					<Button icon="plus" onClick={() => setShowGroupModal(true)}>
						Create New Part Group
					</Button>
				:	undefined
			}
		>
			<PartGroupSelector
				value={selectedPartGroup}
				onChange={(newGroup) => setSelectedPartGroupId(newGroup?.id ?? null)}
				maxWidth={35}
				placeholder="Select a part group..."
				initialId={id}
			/>
			<Box mb={3}>
				<RecentItems
					item={selectedPartGroup}
					itemLabelAccessor="description"
					onSelect={(group) => setSelectedPartGroupId(group.id)}
					compareFn={(a, b) => a.id === b.id}
				/>
			</Box>

			<Divider fullCardBleed my={3} />

			{!!selectedPartGroup && (
				<AnimatedEntrance show>
					<Callout mb={2} variant="info" pr={4} smProps={{ pr: undefined }}>
						<Box display="flex" columnGap={4}>
							<div>
								<Label>Group name</Label>
								<Heading variant="h2">{selectedPartGroup.description}</Heading>
							</div>
							<div>
								<Label>Assignable to new customers?</Label>
								<Heading variant="h3" mt={0.25}>
									{selectedPartGroup.isReusableGroup ? "Yes" : "No"}
								</Heading>
							</div>
						</Box>
						{isDiscountAdmin && (
							<Button
								buttonText="Edit"
								icon="pencil"
								containerProps={{ mt: 1.5 }}
								onClick={() => {
									setShowGroupModal(true)
									setGroupToEdit(selectedPartGroup)
								}}
							/>
						)}
					</Callout>

					<Tabs
						disabled={!selectedPartGroup}
						currentTab={tab}
						onChange={(newTab) => updateUrlValue("tab", newTab)}
						panels={[
							{
								navLabel: PartGroupsTab.GroupParts,
								navIcon: <Icon icon="tools" />,
								component: <PartGroupsPartsTab group={selectedPartGroup} />,
							},
							{
								navLabel: PartGroupsTab.CustomerDiscounts,
								navIcon: <Icon icon="percent" />,
								component: <PartGroupsCustomersTab group={selectedPartGroup} />,
							},
						]}
					/>
				</AnimatedEntrance>
			)}

			{!selectedPartGroup && (
				<Heading textAlign="center" icon="search" pt={3} pb={4} opacity={0.25}>
					Select a part group above to begin
				</Heading>
			)}

			<EditPartGroupModal
				isOpen={showGroupModal}
				onClose={() => {
					setShowGroupModal(false)
					setGroupToEdit(null)
				}}
				groupToEdit={groupToEdit}
				createCallback={setSelectedPartGroupId}
			/>
		</Card>
	)
}

export default memo(PartGroups)

import { FC, useMemo } from "react"

import { css } from "@emotion/react"
import { useFormContext, useWatch } from "react-hook-form"

import { Button, Paragraph } from "@ncs/web-legos"

import { BrandCardForm } from "./brand-card-utils"

export interface BrandCardPreviewProps {}

export const BrandCardPreview: FC<BrandCardPreviewProps> = () => {
	// Use `useWatch` for performance reasons.
	const [buttonLabel, taglineText] = useWatch<BrandCardForm>({
		name: ["buttonLabel", "taglineText"],
	})
	// Use `watch` for typing reasons.
	const { watch } = useFormContext<BrandCardForm>()
	const [bannerImageUrl, logoImageUrl] = watch(["bannerImageUrl", "logoImageUrl"])

	const bannerCss = useMemo(() => {
		return css`
			margin: 0 -1.5rem;
			overflow: hidden;
			background: black;
			> div {
				height: 10rem;
				background-image: url(${bannerImageUrl});
				background-size: cover;
				background-position: center center;
				transition-duration: 200ms;
				transition-timing-function: ease-out;
				transition-property: transform opacity;
				&:hover {
					opacity: 0.9;
					transform: scale(1.05);
				}
			}
		`
	}, [bannerImageUrl])

	return (
		<div css={containerCss}>
			{logoImageUrl ?
				<img src={logoImageUrl} alt="Logo" css={logoCss} />
			:	<Paragraph small color="secondary" textAlign="center" mb={1.5}>
					(Logo will show here)
				</Paragraph>
			}
			<div css={bannerCss}>
				{bannerImageUrl ?
					<div />
				:	<Paragraph color="white" small textAlign="center" py={4}>
						(Banner will show here)
					</Paragraph>
				}
			</div>
			<Paragraph textAlign="center" my={1.25} color={!taglineText ? "secondary" : undefined}>
				{taglineText || "(Tagline will show here)"}
			</Paragraph>
			<Button
				variant="primary-cta"
				icon={buttonLabel ? "long-arrow-right" : undefined}
				fillContainer
				css={buttonCss}
			>
				{buttonLabel || "(Button text will show here)"}
			</Button>
		</div>
	)
}

const containerCss = css`
	max-width: 25rem;
	padding: 1.5rem;
	border-radius: 0 25px 0 25px;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
`
const logoCss = css`
	display: block;
	height: 3rem;
	margin: 0 auto 1.5rem auto;
`
const buttonCss = css`
	background: black;
	border-color: black;
`

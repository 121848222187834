import React from "react"
import PropTypes from "prop-types"
import {
	BaseSelectFilter,
	CollectionSearchSelector,
	withCallApi,
	withDynamicTableFilter,
} from "@ncs/bricks/components"
import { listBins } from "../../redux/services/inventory"

class BinFilter extends React.PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			bins: [],
			selectedBin: null,
		}
	}

	componentDidMount() {
		this.loadBins()
	}

	// load bins whenever the location changes
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.location !== this.props.location) {
			this.loadBins()
		}
	}

	loadBins = () => {
		// if location is empty
		if (!this.props.location || !this.props.location._id) {
			this.setState({ bins: [] })
			this.props.onChange(null)
			return
		}

		this.props.callApi(listBins(this.props.location._id)).then(({ payload: bins }) => {
			this.setState({ bins })

			// if the currently selected bin is not in the results, erase the selected bin
			if (this.props.value && !bins.some((t) => t._id.toString() === this.props.value)) {
				this.props.onChange(null)
			}
		})
	}

	handleBinChange = (selectedBin) => {
		this.setState({ selectedBin })
		if (!selectedBin) {
			this.props.onChange("")
			return
		}

		this.props.onChange(selectedBin._id, selectedBin.code)
	}

	render() {
		return this.state.bins.length <= 100 ?
				<BaseSelectFilter
					id="bin"
					labelText="Bin"
					options={[{ _id: "", code: "All Bins" }, ...this.state.bins]}
					valueAccessor="_id"
					textAccessor="code"
					{...this.props}
				/>
			:	<CollectionSearchSelector
					labelText="Bin"
					placeholder="Search for a bin"
					collection={this.state.bins}
					selectedSuggestion={this.state.selectedBin}
					valueAccessor={(x) => x.code}
					onSelected={this.handleBinChange}
				/>
	}
}

BinFilter.propTypes = {
	location: PropTypes.object.isRequired,
}

export default withCallApi(withDynamicTableFilter("bin")(BinFilter))

import { z } from "zod"

import {
	ContractBusinessUnit,
	ContractIncentives,
	ContractUnitCategory,
	CreateContractIncentivesPost,
	CreateContractPost,
	NewOtherContractUnitCategory,
	UpdateContractIncentivesPatch,
} from "@ncs/ncs-api"

export const NewContractFormSchema = z.object({
	organization: z.string().min(1, "Required").max(100),
	docusignId: z.string().max(100).nullable(),
	effectiveDate: z.string().min(1, "Required"),
	expirationDate: z.string().min(1, "Required"),
	typeIds: z.array(z.string()).refine((v) => v.length > 0, {
		message: "Choose at least 1 contract type",
	}),
	partsCovered: z.boolean(),
	chemicalsCovered: z.boolean(),
	equipmentInstallCovered: z.boolean(),
	maintenanceCovered: z.boolean(),
	hasChemicalSchedule: z.boolean(),
	hasSelfServiceChemicalSchedule: z.boolean(),
	hasPartsSchedule: z.boolean(),
	hasServiceSchedule: z.boolean(),
	escalatorPercent: z.number().nullable(),
	escalatorMonths: z.number().nullable(),
	maxPriceVariancePercent: z.number().nullable(),
	maxPriceVarianceMonths: z.number().nullable(),
	priceIncreases: z.array(
		z.object({
			executedOn: z.string().min(1, "Required"),
			scheduledOn: z.string().min(1, "Required"),
			percentIncreased: z.number(),
		})
	),
	documents: z.array(
		z.object({
			description: z.string().min(1, "Required"),
			file: z.instanceof(File),
		})
	),
	recipients: z.array(
		z.object({
			description: z.string().max(255).nullable(),
			name: z.string().min(1).max(255).optional(),
			email: z.string().email().max(255).optional(),
			userId: z.string().optional(),
		})
	),
	incentives: z.array(
		z.object({
			categories: z.array(
				z
					.object({
						id: z.string(),
						name: z.string().min(1),
						type: z.literal("business_unit").or(z.literal("other")),
					})
					.or(
						z.object({
							name: z.string().min(1),
							type: z.literal("other"),
						})
					)
			),
			rebate: z
				.object({
					schedule: z.array(
						z.object({
							start: z.number(),
							end: z.number().nullable(),
							percent: z.number(),
						})
					),
				})
				.nullable(),
			credit: z
				.object({
					amount: z.number(),
					isPerSite: z.boolean(),
				})
				.nullable(),
			discount: z
				.object({
					percent: z.number(),
				})
				.nullable(),
		})
	),
	coverageType: z.string().nullable(),
	specialException: z.string().max(5000).nullable(),
})

export type NewContractForm = z.infer<typeof NewContractFormSchema>
export type ContractFormRebate = NewContractForm["incentives"][number]["rebate"]
export type ContractFormDiscount = NewContractForm["incentives"][number]["discount"]
export type ContractFormCredit = NewContractForm["incentives"][number]["credit"]
export type ContractFormDocument = NewContractForm["documents"][number]
export type ContractFormRecipient = NewContractForm["recipients"][number]

export const newContractFormDefaultValues: NewContractForm = {
	organization: "",
	docusignId: null,
	effectiveDate: "",
	expirationDate: "",
	typeIds: [],
	partsCovered: false,
	chemicalsCovered: false,
	equipmentInstallCovered: false,
	maintenanceCovered: false,
	hasChemicalSchedule: false,
	hasSelfServiceChemicalSchedule: false,
	hasPartsSchedule: false,
	hasServiceSchedule: false,
	escalatorPercent: null,
	escalatorMonths: null,
	maxPriceVariancePercent: null,
	maxPriceVarianceMonths: null,
	documents: [],
	recipients: [],
	incentives: [],
	priceIncreases: [],
	coverageType: null,
	specialException: null,
}

/** These are the fields that we can edit in EditContractNumberModal. */
export type EditableContractNumberFields = Extract<
	keyof NewContractForm,
	"escalatorPercent" | "escalatorMonths" | "maxPriceVariancePercent" | "maxPriceVarianceMonths"
>

/**
 * A group of incentive parts that all target the same categories. Can have IDs if
 * it's holding incentives from an existing contract. Needs to be based on our `NewContractForm`
 * type, because it needs to be usable by a not-yet-created contract that exists only in our form.
 */
export type ContractIncentiveProgram = {
	categories: NewContractForm["incentives"][number]["categories"]
	rebate: (ContractFormRebate & { id?: string }) | null
	discount: (ContractFormDiscount & { id?: string }) | null
	credit: (ContractFormCredit & { id?: string }) | null
	remaining?: number
}

export const hasOnlyBusinessUnits = (
	categories: ContractIncentiveProgram["categories"]
): categories is (Omit<ContractUnitCategory, "type"> & {
	type: "business_unit"
})[] => {
	return categories.every((c) => c.type === "business_unit")
}

/**
 * Map the whole big contract form into the POST data to create a new one.
 * Also creates all the individual incentive parts.
 */
export const contractFormToContractPost = (form: NewContractForm): CreateContractPost => {
	const rebates: CreateContractPost["rebates"] = []
	const credits: CreateContractPost["credits"] = []
	const discounts: CreateContractPost["discounts"] = []

	form.incentives.forEach((i) => {
		const { categories } = i

		if (hasOnlyBusinessUnits(categories)) {
			if (i.rebate) {
				rebates.push({
					schedule: i.rebate.schedule,
					categories,
				})
			}
			if (i.credit) {
				credits.push({
					amount: i.credit.amount,
					isPerSite: i.credit.isPerSite,
					categories,
				})
			}
		}
		if (i.discount) {
			discounts.push({
				percent: i.discount.percent,
				categories,
			})
		}
	})

	return {
		organization: form.organization,
		docusignId: form.docusignId,
		effectiveDate: form.effectiveDate,
		expirationDate: form.expirationDate,
		types: form.typeIds,
		partsCovered: form.partsCovered,
		chemicalsCovered: form.chemicalsCovered,
		equipmentInstallCovered: form.equipmentInstallCovered,
		maintenanceCovered: form.maintenanceCovered,
		hasChemicalSchedule: form.hasChemicalSchedule,
		hasSelfServiceChemicalSchedule: form.hasSelfServiceChemicalSchedule,
		hasPartsSchedule: form.hasPartsSchedule,
		hasServiceSchedule: form.hasServiceSchedule,
		escalatorPercent: form.escalatorPercent,
		escalatorMonths: form.escalatorMonths || null,
		maxPriceVariancePercent: form.maxPriceVariancePercent,
		maxPriceVarianceMonths: form.maxPriceVarianceMonths || null,
		documents: form.documents,
		recipients: form.recipients.map((r) => {
			if (r.userId) {
				return {
					description: r.description,
					user: r.userId,
				}
			} else {
				return {
					description: r.description,
					name: r.name,
					email: r.email,
				}
			}
		}),
		rebates,
		credits,
		discounts,
		priceIncreases: form.priceIncreases,
		coverageTypeId: form.coverageType,
		specialException: form.specialException,
	}
}

/**
 * Take all the incentives returned for a contract and group them into incentive programs according
 * to the units attached to each of them.
 */
export const groupIncentivesByUnits = (
	incentives: ContractIncentives
): ContractIncentiveProgram[] => {
	const { rebates, credits, discounts } = incentives

	const incentivePrograms: ContractIncentiveProgram[] = []

	const sortCategories = (categories: ContractIncentiveProgram["categories"]) => {
		return categories.sort((a, b) => (a.name < b.name ? -1 : 1))
	}

	const sameCategories = (
		a: ContractIncentiveProgram["categories"],
		b: ContractIncentiveProgram["categories"]
	) => {
		return (
			sortCategories(a)
				.map((c) => (c as ContractUnitCategory).id ?? c.name)
				.join(" ") ===
			sortCategories(b)
				.map((c) => (c as ContractUnitCategory).id ?? c.name)
				.join(" ")
		)
	}

	rebates.forEach((rebate) => {
		const prevIndex = incentivePrograms.findIndex((i) =>
			sameCategories(i.categories, rebate.categories)
		)

		if (prevIndex !== -1) {
			incentivePrograms[prevIndex].rebate = rebate
		} else {
			incentivePrograms.push({
				categories: rebate.categories,
				rebate,
				credit: null,
				discount: null,
			})
		}
	})

	discounts.forEach((discount) => {
		const prevIndex = incentivePrograms.findIndex((i) =>
			sameCategories(i.categories, discount.categories)
		)

		if (prevIndex !== -1) {
			incentivePrograms[prevIndex].discount = discount
		} else {
			incentivePrograms.push({
				categories: discount.categories,
				rebate: null,
				credit: null,
				discount,
			})
		}
	})

	credits.forEach((credit) => {
		const prevIndex = incentivePrograms.findIndex((i) =>
			sameCategories(i.categories, credit.categories)
		)

		// If this combo of units exists already, then set its credit and credit remaining
		// fields to the credit.
		if (prevIndex !== -1) {
			incentivePrograms[prevIndex].credit = {
				id: credit.id,
				amount: credit.amount,
				isPerSite: credit.isPerSite,
			}
			incentivePrograms[prevIndex].remaining = credit.remaining ?? undefined
		} else {
			incentivePrograms.push({
				categories: credit.categories,
				rebate: null,
				credit: {
					id: credit.id,
					amount: credit.amount,
					isPerSite: credit.isPerSite,
				},
				discount: null,
				remaining: credit.remaining ?? undefined,
			})
		}
	})

	return incentivePrograms
}

/**
 * Map a form incentive grouping to the POST data to create new individual incentive parts.
 */
export const formIncentiveToIncentivePost = (
	incentive: ContractIncentiveProgram,
	contractId: string
): CreateContractIncentivesPost => {
	// Make use of the util that takes the whole big contract form and shapes it.
	// The endpoint that can create just the incentives takes rebates, etc, shaped
	// in the same way.
	const { rebates, credits, discounts } = contractFormToContractPost({
		...newContractFormDefaultValues,
		incentives: [incentive],
	})

	return {
		contract: contractId,
		rebates,
		credits,
		discounts,
	}
}

/**
 * Map a form incentive grouping to the PATCH data to update its parts.
 */
export const formIncentiveToIncentivePatch = (
	incentive: ContractIncentiveProgram,
	contractId: string
): UpdateContractIncentivesPatch => {
	const rebates: UpdateContractIncentivesPatch["rebates"] = []
	const credits: UpdateContractIncentivesPatch["credits"] = []
	const discounts: UpdateContractIncentivesPatch["discounts"] = []

	// We can only update incentives that have IDs on them.

	if (incentive.rebate?.id && hasOnlyBusinessUnits(incentive.categories)) {
		rebates.push({
			id: incentive.rebate.id,
			schedule: incentive.rebate.schedule,
			categories: incentive.categories,
		})
	}
	if (incentive.credit?.id && hasOnlyBusinessUnits(incentive.categories)) {
		credits.push({
			id: incentive.credit.id,
			amount: incentive.credit.amount,
			isPerSite: incentive.credit.isPerSite,
			categories: incentive.categories,
		})
	}
	if (incentive.discount?.id) {
		discounts.push({
			id: incentive.discount.id,
			percent: incentive.discount.percent,
			categories: incentive.categories,
		})
	}

	return {
		contract: contractId,
		rebates: rebates.length ? rebates : undefined,
		credits: credits.length ? credits : undefined,
		discounts: discounts.length ? discounts : undefined,
	}
}

export const stringifyContractUnits = (
	categories: (ContractUnitCategory | NewOtherContractUnitCategory)[],
	labelOtherAsOther?: boolean
): string => {
	return categories
		.map(
			(c) => `${c.name}${c.type === "other" && labelOtherAsOther === true ? " (other)" : ""}`
		)
		.join(", ")
}

/**
 * Map a `ContractBusinessUnit`, which is what comes back from the lookup, into a `ContractUnitCategory`,
 * which can be both business units and 'other'.
 */
export const businessUnitToUnitCategory = (unit: ContractBusinessUnit): ContractUnitCategory => {
	return {
		id: unit.id,
		name: unit.name,
		type: "business_unit",
	}
}

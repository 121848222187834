import { PartOrderList, PartOrderStatusId, StockStatus } from "@ncs/ncs-api"
import { FileType } from "@ncs/web-legos"

export const getLineLocationPickListCreationStatus = (
	location: PartOrderList[number]["locations"][number]
): [StockStatus, string] | null => {
	if (location.fullyInStock) {
		return [StockStatus.All, "Complete pick list can be created"]
	}
	if (location.partialInStock) {
		return [StockStatus.Partial, "Partial pick list can be created"]
	}
	if (location.noneInStock) {
		return [StockStatus.None, "All needed items out of stock"]
	}

	return null
}

export const partOrderAttachmentFileTypes = [
	FileType.Excel,
	FileType.Images,
	FileType.Pdf,
	FileType.Text,
	FileType.Word,
]

export const onHoldPartOrderStatus = PartOrderStatusId.Hold
export const paymentIncompleteStatuses: (PartOrderStatusId | undefined)[] = [
	PartOrderStatusId.PaymentPending,
	PartOrderStatusId.PaymentFailed,
]
export const openPartOrderStatuses = [
	PartOrderStatusId.InProgress,
	PartOrderStatusId.Submitted,
	PartOrderStatusId.Ordered,
	PartOrderStatusId.Shipped,
	PartOrderStatusId.PickListGenerated,
	PartOrderStatusId.PendingShipment,
	PartOrderStatusId.ReadyForPickup,
	PartOrderStatusId.PaymentPending,
]
export const closedPartOrderStatuses = [
	PartOrderStatusId.Received,
	PartOrderStatusId.Removed,
	PartOrderStatusId.Invoiced,
	PartOrderStatusId.Closed,
	PartOrderStatusId.ShippedToCustomer,
	PartOrderStatusId.OneTimeYearEnd,
	PartOrderStatusId.ReceivedByCustomer,
	PartOrderStatusId.DispatchCreated,
	PartOrderStatusId.ShipmentComplete,
]

import React from "react"
import { compose } from "redux"

//custom components
import { ConditionalContent, withDynamicTableFilter } from "~/components"
//local components
import TypeSubtypeFilters from "./TypeSubtypeFilters"
import TicketSubtypeManagementContainer from "./TicketSubtypeManagementContainer"
import TicketTypeManagementContainer from "./TicketTypeManagementContainer"

const reduxKey = "typeSubtypeFiltersReduxKey"

class TypeSubtypeOverview extends React.Component {
	render = () => {
		return (
			<React.Fragment>
				<TypeSubtypeFilters />
				<ConditionalContent
					hide={
						typeof this.props.selectedGroup !== "number" ||
						typeof this.props.selectedType === "number"
					}
				>
					<TicketTypeManagementContainer
						group={this.props.selectedGroup}
						type={this.props.selectedType}
					/>
				</ConditionalContent>
				<ConditionalContent show={typeof this.props.selectedType === "number"}>
					<TicketSubtypeManagementContainer type={this.props.selectedType} />
				</ConditionalContent>
			</React.Fragment>
		)
	}
}

export default compose(
	withDynamicTableFilter("ticket_type", undefined, reduxKey, "setType", "selectedType"),
	withDynamicTableFilter("group", undefined, reduxKey, "setGroup", "selectedGroup")
)(TypeSubtypeOverview)

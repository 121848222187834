import React from "react"

import { BigCalendar } from "@ncs/web-legos"

//material ui icons
import CalendarTodayIcon from "@material-ui/icons/CalendarToday"
//local components
import CreateOffHoursEventContainer from "./CreateOffHoursEventContainer"
import EditOffHoursEventContainer from "./EditOffHoursEventContainer"
import {
	GridContainer,
	GridItem,
	QuickCard,
	TerritorySelector,
	LoadingWrapper,
	ConditionalContent,
} from "~/components"

function OffHours(props) {
	let {
		onEditSlot,
		onSlotSelect,
		selectedEvent,
		techAssignments,
		onEventSave,
		creating,
		createEvent,
		onCancelEvent,
		onTerritoryChange,
		selectedTerritory,
		isLoading,
		...attributes
	} = props
	return (
		<GridContainer>
			<LoadingWrapper isLoading={isLoading} />
			<GridItem xs={12}>
				<TerritorySelector onChange={onTerritoryChange} territory={selectedTerritory} />
			</GridItem>

			<ConditionalContent show={selectedTerritory !== null}>
				<GridItem xs={12} show={creating}>
					<CreateOffHoursEventContainer
						techAssignments={techAssignments}
						onEventSave={onEventSave}
						event={createEvent}
						onCancelEvent={onCancelEvent}
						territory={selectedTerritory}
					/>
				</GridItem>
				<GridItem xs={12} show={selectedEvent !== null}>
					<EditOffHoursEventContainer
						event={selectedEvent}
						techAssignments={techAssignments}
						onEventSave={onEventSave}
						onCancelEvent={onCancelEvent}
						territory={selectedTerritory}
					/>
				</GridItem>
				<GridItem xs={12} show={!isLoading && selectedTerritory !== null}>
					<QuickCard title={"Off Hours"} icon={<CalendarTodayIcon />}>
						<BigCalendar
							popup
							selectable
							views={["month"]}
							{...attributes}
							startAccessor="startDate"
							endAccessor="endDate"
							onSelectEvent={(event) => onEditSlot(event)}
							onSelectSlot={(event) => onSlotSelect(event)}
						/>
					</QuickCard>
				</GridItem>
			</ConditionalContent>
		</GridContainer>
	)
}

export default OffHours

import { FC } from "react"

import { Lookup, useDispatchPriorities } from "@ncs/ncs-api"
import { ExtendableSelectProps, Select } from "@ncs/web-legos"

export const DispatchPrioritySelector: FC<ExtendableSelectProps<Lookup<number>>> = ({
	...rest
}) => {
	const [options, optionsLoading] = useDispatchPriorities()

	return (
		<Select
			label="Dispatch priority"
			{...rest}
			options={options ?? []}
			valueAccessor={(option) => option.id.toString()}
			textAccessor="description"
			isLoading={optionsLoading}
		/>
	)
}

import { FC, useMemo, useState } from "react"

import { ContractBusinessUnit, useContractBusinessUnits } from "@ncs/ncs-api"

import { useChangeCallback } from "../../util"
import { Select, ThrottledTextInput } from "../inputs"
import { Box, BoxProps } from "../layout"
import { AnimatedEntrance } from "../transitions"

export interface BusinessUnitSelectorProps extends BoxProps {
	value: string | null
	onChange: (businessUnitId: string | null, newName?: string | null) => void
	label?: string
	fillContainer?: boolean
	disabled?: boolean
}

export const BusinessUnitSelector: FC<BusinessUnitSelectorProps> = ({
	value,
	onChange,
	label = "Business unit",
	fillContainer = true,
	disabled,
	...rest
}) => {
	const [selectedOptionValue, setSelectedOptionValue] = useState<string | null>(value)
	const [newName, setNewName] = useState<string | null>(null)

	const [units, unitsLoading] = useContractBusinessUnits()

	const handleSelectChange = (newUnitId: string | null) => {
		// Update local state for the select menu.
		setSelectedOptionValue(newUnitId)
		// Update parent state, but if it's other, just send out null.
		onChange(newUnitId === otherBusinessUnitOptionValue ? null : newUnitId, newName)
	}

	// Update parent state with the new business unit name as it changes.
	useChangeCallback(newName, (newNameValue) => {
		if (selectedOptionValue === otherBusinessUnitOptionValue) {
			onChange(null, newNameValue)
		}
	})

	// Empty the new name field when you switch away from 'other'.
	useChangeCallback(selectedOptionValue, (newValue) => {
		if (newValue !== otherBusinessUnitOptionValue) {
			setNewName(null)
		}
	})

	const options = useMemo((): ContractBusinessUnit[] => {
		return [
			...(units ?? []).sort((a, b) => (a.name > b.name ? 1 : -1)),
			{
				id: otherBusinessUnitOptionValue,
				name: "Other",
			},
		]
	}, [units])

	return (
		<Box {...rest}>
			<Select
				options={options}
				disabled={unitsLoading || disabled}
				value={selectedOptionValue}
				onChange={handleSelectChange}
				label={label}
				fillContainer={fillContainer}
				valueAccessor="id"
				textAccessor="name"
			/>

			<AnimatedEntrance show={selectedOptionValue === otherBusinessUnitOptionValue}>
				<ThrottledTextInput
					value={newName}
					onChange={setNewName}
					label="Other business unit name"
				/>
			</AnimatedEntrance>
		</Box>
	)
}

const otherBusinessUnitOptionValue = "other"

import React from "react"

import {
	ButtonWithIcon,
	GridContainer,
	GridItem,
	Input,
	UserSelector,
	Dialog,
	TerritorySelector,
	// onClickCustomer,
} from "~/components"

//material ui icons
import Add from "@material-ui/icons/Add"
import RemoveIcon from "@material-ui/icons/Remove"

function TechAssignmentAdd(props) {
	let {
		onStartAssignmentAdd,
		onCancelAssignmentAdd,
		show,
		onDefaultTechSelected,
		onAlternateTechSelected,
		selectedTerritory,
		description,
		onDescriptionChange,
		showValidationErrors,
		descriptionErrorMessage,
		descriptionIsValid,
		setInputRef,
		onTerritorySelected,
		onConfirmAssignmentAdd,
		isSaving,
		title,
	} = props
	return (
		<GridContainer>
			<GridItem xs={12}>
				<ButtonWithIcon
					content="New Assignment"
					onClick={onStartAssignmentAdd}
					icon={<Add />}
					size="sm"
					color="success"
					round
				/>
			</GridItem>
			<Dialog
				warning="true"
				show={show}
				title={title}
				confirmBtnIcon={<Add />}
				confirmBtnText="Confirm"
				onConfirm={onConfirmAssignmentAdd}
				isConfirmationAsync={true}
				cancelBtnIcon={<RemoveIcon />}
				cancelBtnText={"Cancel"}
				cancelBtnColor={"danger"}
				onCancel={onCancelAssignmentAdd}
				isSaving={isSaving}
			>
				{show && (
					<GridContainer>
						<GridItem xs={12} md={12}>
							<Input
								labelText={"Description"}
								value={description}
								onChange={onDescriptionChange}
								inputProps={{
									inputProps: { ref: setInputRef },
								}}
								formControlProps={{
									error: showValidationErrors && !descriptionIsValid,
								}}
								helperText={showValidationErrors ? descriptionErrorMessage : ""}
							/>
						</GridItem>
						<GridItem xs={12} md={6}>
							<TerritorySelector
								territory={selectedTerritory}
								onChange={onTerritorySelected}
							/>
						</GridItem>
						<GridItem xs={12} md={6}></GridItem>
						<GridItem xs={12} md={6}>
							<UserSelector
								labelText={"Default Tech"}
								onUserSelected={onDefaultTechSelected}
							/>
						</GridItem>
						<GridItem xs={12} md={6}>
							<UserSelector
								labelText={"Alternate Tech"}
								onUserSelected={onAlternateTechSelected}
							/>
						</GridItem>
					</GridContainer>
				)}
			</Dialog>
		</GridContainer>
	)
}

export default TechAssignmentAdd

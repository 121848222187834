import { FC, Fragment, useEffect, useMemo, useState } from "react"

import { nanoid } from "nanoid"

import {
	BillOfMaterial,
	CreateBillOfMaterialPost,
	InventoryPart,
	makeApiErrorMessage,
	UpdateBillOfMaterialPatch,
} from "@ncs/ncs-api"
import {
	Box,
	Button,
	CssGridTable,
	Divider,
	ErrorText,
	Heading,
	IconButton,
	Modal,
	NumericInput,
	Paragraph,
	PartSelector,
	TextInput,
} from "@ncs/web-legos"

export interface RecipeFormProps {
	bom: BillOfMaterial | null
	handleCreate?: (data: CreateBillOfMaterialPost) => Promise<void>
	handleUpdate?: (data: UpdateBillOfMaterialPatch) => Promise<void>
	handleCancel?: () => void
}

export const RecipeForm: FC<RecipeFormProps> = ({
	bom,
	handleCreate,
	handleUpdate,
	handleCancel,
}) => {
	const [name, setName] = useState<string | null>(bom?.name ?? null)
	const [ingredients, setIngredients] = useState(() => makeIngredientsState(bom))
	const [selectedNewIngredientPart, setSelectedNewIngredientPart] =
		useState<InventoryPart | null>(null)
	const [resultPart, setResultPart] = useState<InventoryPart | null>(null)
	const [showAddPartModal, setShowAddPartModal] = useState(false)
	const [addPartError, setAddPartError] = useState<string | null>(null)
	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const setIngredientQuantity = (tempId: string, quantity: number | null) => {
		setIngredients((prev) => {
			return {
				...prev,
				[tempId]: {
					...prev[tempId],
					quantity,
				},
			}
		})
	}

	const deleteIngredient = (id: string) => {
		setIngredients((prev) => {
			const newState = { ...prev }
			delete newState[id]

			return newState
		})
	}

	const closeAddPartModal = () => {
		setSelectedNewIngredientPart(null)
		setShowAddPartModal(false)
	}

	const onSave = async () => {
		try {
			if (!name) {
				throw new Error("Name is required")
			}
			if (Object.values(ingredients).length === 0) {
				throw new Error("Must have at least one ingredient")
			}
			Object.values(ingredients).forEach((item) => {
				if (!item.quantity) {
					throw new Error("All ingredients must have a usage")
				}
				if (!item.part) {
					throw new Error("All ingredients must have a part")
				}
			})

			if (bom && handleUpdate) {
				setIsSaving(true)
				const data = makePatchData({ name, ingredients })
				await handleUpdate(data)
			} else if (handleCreate) {
				if (!resultPart) {
					throw new Error("A resulting chemical is required")
				}
				setIsSaving(true)
				const data = makePostData({ name, resultPart, ingredients })
				await handleCreate(data)
			}
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const isDirty = useMemo(() => {
		if (!bom) {
			return true
		}
		if (name !== bom.name) {
			return true
		}
		if (
			Object.values(ingredients).some((newItem) => {
				if (!newItem.bomItemId) return true

				const matchingOriginalItem = bom.items.find(
					(item) => item.id === newItem.bomItemId
				)

				if (!matchingOriginalItem) return true

				return (
					newItem.quantity !== matchingOriginalItem.quantity ||
					newItem.part?.id !== matchingOriginalItem.partId?.toString()
				)
			})
		) {
			return true
		}

		return false
	}, [bom, ingredients, name])

	useEffect(() => {
		setErrorText(null)
	}, [ingredients, name, resultPart])

	return (
		<div>
			<TextInput value={name} onChange={setName} label="Recipe name" />

			{!bom && (
				<PartSelector
					label="Resulting chemical"
					value={resultPart}
					onChange={setResultPart}
				/>
			)}

			<Divider />

			<CssGridTable
				gridTemplateColumns="auto 4rem 1fr"
				alignItems="center"
				gap={0.5}
				headers={["", "Usage", "Ingredient"]}
				mb={1}
				cells={Object.values(ingredients).map((item) => (
					<Fragment key={item.tempId}>
						<IconButton
							icon="trash-alt"
							size="sm"
							onClick={() => deleteIngredient(item.tempId)}
						/>
						<NumericInput
							value={item.quantity}
							onChange={(newValue) =>
								setIngredientQuantity(item.tempId, newValue ?? null)
							}
							placeholder="Usage..."
							mb={0}
							decimalScale={2}
						/>
						<Paragraph>
							{(item.part ?
								`(${item.part.number}) ${item.part.description}`
							:	item.description) || "(no description)"}
						</Paragraph>
					</Fragment>
				))}
			/>

			<div>
				<Button icon="plus" onClick={() => setShowAddPartModal(true)}>
					Add part
				</Button>
			</div>

			{bom ?
				<Box display="flex" alignItems="center" gap={1} mt={2}>
					<Button
						variant="secondary-cta"
						disabled={!isDirty}
						isLoading={isSaving}
						onClick={onSave}
					>
						Save Changes
					</Button>
					{!!handleCancel && (
						<Button
							icon="times"
							disabled={!isDirty || isSaving}
							onClick={handleCancel}
						>
							Cancel
						</Button>
					)}
				</Box>
			:	<Box mt={4}>
					<Button
						variant="primary-cta"
						fillContainer
						isLoading={isSaving}
						onClick={onSave}
					>
						Create
					</Button>
				</Box>
			}

			{!!errorText && <ErrorText mt={2}>{errorText}</ErrorText>}

			{showAddPartModal && (
				<Modal
					errorText={addPartError}
					onClose={closeAddPartModal}
					rightButtons={{
						buttonText: "Add to recipe",
						disabled: !selectedNewIngredientPart,
						onClick: () => {
							if (selectedNewIngredientPart) {
								if (
									Object.values(ingredients).some((item) => {
										return item.part?.id === selectedNewIngredientPart.id
									})
								) {
									setAddPartError("Part is already included in recipe")
									return
								}

								const tempId = nanoid()

								setIngredients((prev) => ({
									...prev,
									[tempId]: {
										tempId,
										bomItemId: null,
										quantity: null,
										description: selectedNewIngredientPart.description,
										part: {
											id: selectedNewIngredientPart.id,
											number: selectedNewIngredientPart.partNumber,
											description: selectedNewIngredientPart.description,
										},
									},
								}))
								closeAddPartModal()
							}
						},
					}}
				>
					<Heading bold mb={2}>
						Search For A Part
					</Heading>
					<PartSelector
						value={selectedNewIngredientPart}
						onChange={setSelectedNewIngredientPart}
						autoFocus
						includeNonService
						skipRestrictedCheck
					/>
				</Modal>
			)}
		</div>
	)
}

interface IngredientsState {
	[tempId: string]: {
		tempId: string
		bomItemId: string | null
		quantity: number | null
		description: string | null
		part: {
			id: string
			number: string
			description: string
		} | null
	}
}

const makeIngredientsState = (bom: BillOfMaterial | null): IngredientsState => {
	if (!bom) {
		return {}
	}

	return Object.fromEntries(
		bom.items.map((item) => {
			const tempId = nanoid()
			const state: IngredientsState[string] = {
				tempId,
				bomItemId: item.id,
				quantity: item.quantity,
				description: item.description,
				part: null,
			}

			if (item.partId && item.partNumber && item.partDescription) {
				state.part = {
					id: item.partId,
					number: item.partNumber,
					description: item.partDescription,
				}
			}

			return [tempId, state]
		})
	)
}

const makePostData = ({
	name,
	resultPart,
	ingredients,
}: {
	name: string
	resultPart: InventoryPart
	ingredients: IngredientsState
}): CreateBillOfMaterialPost => {
	const items: CreateBillOfMaterialPost["items"] = []

	Object.values(ingredients).forEach((item) => {
		if (item.part && item.quantity) {
			items.push({
				partId: item.part.id,
				description: item.part.description,
				quantity: item.quantity,
			})
		}
	})

	return {
		name,
		partId: resultPart.id,
		items,
	}
}

const makePatchData = ({
	name,
	ingredients,
}: {
	name: string
	ingredients: IngredientsState
}): UpdateBillOfMaterialPatch => {
	const items: UpdateBillOfMaterialPatch["items"] = []

	Object.values(ingredients).forEach((item) => {
		if (item.part && item.quantity) {
			items.push({
				partId: item.part.id,
				description: item.part.description,
				quantity: item.quantity,
			})
		}
	})

	return {
		name,
		items,
	}
}

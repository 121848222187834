import React from "react"
import PropTypes from "prop-types"

// containers
import TicketDetails from "./TicketDetails"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// @material-ui/icons
import History from "@material-ui/icons/History"

// custom components
import { GridContainer, GridItem, NavPills } from "~/components"

import LocationOn from "@material-ui/icons/LocationOn"

// styles
import { cardTitle, grayColor } from "@ncs/bricks/assets/jss/material-dashboard-pro-react"
import buttonsStyle from "@ncs/bricks/assets/jss/material-dashboard-pro-react/views/buttonsStyle"
import modalStyle from "@ncs/bricks/assets/jss/material-dashboard-pro-react/modalStyle"
import TicketEventContainer from "./TicketEventContainer"

const styles = (theme) => ({
	cardTitle,
	buttonsStyle,
	...modalStyle(theme),
	pageTitle: {
		color: grayColor[2],
		textDecoration: "none",
		textAlign: "center",
	},
})

const TicketingOverview = (props) => {
	const { activeTabIndex, onTabChange } = props

	return (
		<GridContainer>
			<GridItem xs={12}>
				{() => (
					<NavPills
						color="warning"
						alignCenter
						active={activeTabIndex}
						onTabChange={onTabChange}
						tabs={[
							{
								tabButton: "Ticket Details",
								tabIcon: LocationOn,
								tabContent: <TicketDetails />,
							},
							{
								tabButton: "History",
								tabIcon: History,
								tabContent: <TicketEventContainer />,
							},
						]}
					/>
				)}
			</GridItem>
			{/* </ConditionalContent> */}
		</GridContainer>
	)
}

TicketingOverview.propTypes = {
	activeTabIndex: PropTypes.number.isRequired,
	onTabChange: PropTypes.func.isRequired,
}

export default withStyles(styles)(TicketingOverview)

import { nanoid } from "nanoid"

import {
	PartOrderLineItem,
	PartOrderMin,
	PartOrderPart,
	PurchaseOrderLineItemMin,
} from "@ncs/ncs-api"

export const isPartOrderLineItem = (
	line: PartOrderLineItem | PurchaseOrderLineItemMin
): line is PartOrderLineItem => {
	return typeof (line as PartOrderLineItem).locationLines !== "undefined"
}

export const isPurchaseOrderLineItem = (
	line: PartOrderLineItem | PurchaseOrderLineItemMin
): line is PurchaseOrderLineItemMin => {
	return typeof (line as PurchaseOrderLineItemMin).partPurchaseLine !== "undefined"
}

/** These are what you actually see rows of in the modal. */
export interface ReceivingLine {
	lineItemId: string // ID of the part or purchase order line item it came from.
	part: PartOrderPart
	orderedQuantity: number // The amount originally asked for of the whole line item.
	alreadyReceived: number // The amount already received towards this line.
	receivableQuantity: number // The amount we have to play with.
	destinations: {
		quantityNeeded: number | null // The total amount this bin destination wants. (stock doesn't have this, orders  might)
		partOrder: PartOrderMin | null // Null if is stock / unspecified.
		suggestedReceiveQuantity: number // Not using anymore.
	}[]
}

/** The local state that drives the quantity/bin form of rows of ReceivingLines. */
export interface LineFormState {
	[lineItemId: string]: {
		lineItemId: string
		destinations: {
			[destinationId: string]: {
				destinationId: string
				partOrder: PartOrderMin | null
				quantityNeeded: number | null
				binQuantities: {
					[binQuantityId: string]: {
						binQuantityId: string
						binId: string | null
						quantity: number
					}
				}
			}
		}
	}
}

export const makeLineFormState = (receivingLinesToMap: ReceivingLine[]): LineFormState => {
	return Object.fromEntries(
		receivingLinesToMap.map((line) => {
			const destinations: LineFormState[string]["destinations"] = {}

			line.destinations.forEach(({ partOrder, quantityNeeded }) => {
				// We only need to add order destinations into our state if we're separating out
				// demands for a purchase order.
				const destinationId = nanoid()
				const binQuantityId = nanoid()

				destinations[destinationId] = {
					destinationId,
					partOrder,
					quantityNeeded,
					binQuantities: {
						[binQuantityId]: {
							binQuantityId,
							binId: null,
							quantity: 0,
						},
					},
				}
			})

			return [
				line.lineItemId,
				{
					lineItemId: line.lineItemId,
					destinations,
				},
			]
		})
	)
}

export const binQtyRowHeight = 2.5

export interface LinePrintingState {
	[lineItemId: string]: {
		lineItemId: string
		partNumber: string
		quantity: number | null
	}
}

/**
 * For every line in the receive modal, we need to get the quantity user has entered
 * and the part number associated with that line.
 */
export const makeLinePrintingState = (
	receivingLines: ReceivingLine[],
	lineFormState: LineFormState
): LinePrintingState => {
	const printingLines = receivingLines.flatMap((rLine) => {
		const formLine = Object.values(lineFormState).find(
			(fLine) => fLine.lineItemId === rLine.lineItemId
		)

		if (formLine) {
			const quantity = Object.values(formLine.destinations).reduce(
				(lineTotal, destination) => {
					return (
						lineTotal +
						Object.values(destination.binQuantities).reduce((binTotal, bin) => {
							return binTotal + bin.quantity
						}, 0)
					)
				},
				0
			)

			return [
				{
					lineItemId: rLine.lineItemId,
					partNumber: rLine.part.partNumber,
					quantity,
				},
			]
		} else {
			return []
		}
	})

	return Object.fromEntries(printingLines.map((line) => [line.lineItemId, line]))
}

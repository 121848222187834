import moment from "moment/moment"
import { formatNumber as formatPhoneNumber } from "libphonenumber-js"

export const formatNumberAsCurrency = (amount, digitsAfterDecimal = 2) => {
	return new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
		minimumFractionDigits: digitsAfterDecimal,
		maximumFractionDigits: digitsAfterDecimal,
	}).format(amount)
}

export const formatNumber = (amount, digitsAfterDecimal = 0) => {
	return new Intl.NumberFormat("en-US", {
		minimumFractionDigits: digitsAfterDecimal,
		maximumFractionDigits: digitsAfterDecimal,
	}).format(amount)
}

export const formatPercentage = (amount, digitsAfterDecimal = 0) => {
	return new Intl.NumberFormat("en-US", {
		style: "percent",
		minimumFractionDigits: digitsAfterDecimal,
		maximumFractionDigits: digitsAfterDecimal,
	}).format(amount)
}

const formatDateTimeInternal = (utcDate, format, invalidDateText, formatInUtc = false) => {
	let date = moment.utc(utcDate)
	if (!formatInUtc) date = date.local()

	let formattedDate = date.format(format)
	return (
			typeof invalidDateText !== "undefined" &&
				(typeof utcDate === "undefined" || formattedDate === "Invalid date")
		) ?
			invalidDateText
		:	formattedDate
}

export const formatDate = (
	utcDate,
	format = "MMM DD YYYY",
	invalidDateText = "N/A",
	formatInUtc = false
) => {
	return formatDateTimeInternal(utcDate, format, invalidDateText, formatInUtc)
}

export const formatDateTime = (
	utcDate,
	format = "MMM DD YYYY h:mm a",
	invalidDateText = "N/A",
	formatInUtc = false
) => {
	return formatDateTimeInternal(utcDate, format, invalidDateText, formatInUtc)
}

export const formatDateTimeForFilename = (
	utcDate,
	format = "YYYY-MM-DD HH:mm",
	invalidDateText = "N/A",
	formatInUtc = false
) => {
	return formatDateTimeInternal(utcDate, format, invalidDateText, formatInUtc)
}

export const formatLongDateTime = (
	utcDate,
	format = "dddd, MMM DD, YYYY, h:mm:ss a",
	invalidDateText = "N/A",
	formatInUtc = false
) => {
	return formatDateTimeInternal(utcDate, format, invalidDateText, formatInUtc)
}

export const formatCustomerContracts = (customer) => {
	if (!customer || !customer.contracts || customer.contracts.length === 0) return "None"

	return customer.contracts.map((contract) => contract.type).join(", ")
}

export const formatPhone = (phone) => {
	return formatPhoneNumber(phone, "National")
}

export const formatCustomerAddress = (customer) => {
	return [
		customer.customer_number,
		customer.name,
		customer.address_1,
		customer.address_2,
		customer.address_3,
		`${customer.city}, ${customer.state} ${customer.postalcode}`,
		customer.phone ? formatPhone(customer.phone) : null,
	]
		.filter((item) => item)
		.join("\n")
}

export const titleCase = (str) => {
	if (!str) return str

	return str
		.split(" ")
		.map((w) => (w[0] ?? "").toUpperCase() + w.substr(1).toLowerCase())
		.join(" ")
}

export const extractInteger = (str) => {
	const result = parseInt((str ?? "").replace(/[^0-9.]/g, ""))

	if (Number.isNaN(result)) return 0

	return result
}

import React from "react"

// core components
import { AppBar, Hidden, Toolbar, withStyles } from "@material-ui/core"
import cx from "classnames"
import PropTypes from "prop-types"

import headerStyle from "@ncs/bricks/assets/jss/material-dashboard-pro-react/components/headerStyle"
import { Button } from "@ncs/bricks/components"

import HeaderLinks from "./HeaderLinks"

import { Menu, MoreVert, ViewList } from "@material-ui/icons"

export const getBreadcrumbName = (routes, pathname) => {
	for (let route of routes) {
		// recurse over submenus
		if (route.collapse) {
			let name = getBreadcrumbName(route.views, pathname)
			if (name) {
				return name
			}
		}
		if (route.path === pathname) {
			return route.name
		}
		if (route.breadcrumbRegex && route.breadcrumbRegex.test(pathname)) return route.name
	}

	return null
}

function Header({ ...props }) {
	function makeBrand() {
		return getBreadcrumbName(props.routes, props.location.pathname) || " "
	}

	const { classes, color } = props
	const appBarClasses = cx({ [classes[color]]: color })
	const sidebarMinimize = classes.sidebarMinimize

	return (
		<AppBar className={classes.appBar + appBarClasses}>
			<Toolbar className={classes.container}>
				<Hidden smDown>
					<div className={sidebarMinimize}>
						{props.miniActive ?
							<Button justIcon round color="white" onClick={props.sidebarMinimize}>
								<ViewList className={classes.sidebarMiniIcon} />
							</Button>
						:	<Button justIcon round color="white" onClick={props.sidebarMinimize}>
								<MoreVert className={classes.sidebarMiniIcon} />
							</Button>
						}
					</div>
				</Hidden>
				<div className={classes.flex}>
					{/* Here we create navbar brand, based on route name */}
					<Button href="#" className={classes.title} color="transparent">
						{makeBrand()}
					</Button>
				</div>
				<Hidden smDown implementation="css">
					<HeaderLinks newAuth={props.newAuth} />
				</Hidden>
				<Hidden mdUp>
					<Button
						className={classes.appResponsive}
						color="transparent"
						justIcon
						aria-label="open drawer"
						onClick={props.handleDrawerToggle}
					>
						<Menu />
					</Button>
				</Hidden>
			</Toolbar>
		</AppBar>
	)
}

Header.propTypes = {
	classes: PropTypes.object.isRequired,
	color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
}

export default withStyles(headerStyle)(Header)

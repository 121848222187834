import { FC, useMemo, useState } from "react"

import { useHistory } from "react-router-dom"
import { Cell, Column } from "react-table"

import {
	defaultUserProfilesQueryParams,
	UserProfile,
	UserProfileQueryParams,
	useUserProfiles,
} from "@ncs/ncs-api"
import { formatDateTime, formatNumber, getTimezoneAbbreviation, Typify } from "@ncs/ts-utils"
import {
	Button,
	Card,
	Icon,
	SearchQueryFilter,
	Table,
	Tooltip,
	UserGuestQueryFilter,
	useUrlState,
} from "@ncs/web-legos"

import { CreateCustomerUserModal } from "./CreateCustomerUserModal"

export type CustomerUsersUrlState = Typify<UserProfileQueryParams>

const CustomerUsers: FC = () => {
	const history = useHistory()
	const [params, { setUrlState }] = useUrlState<CustomerUsersUrlState>(defaultParams)

	const usersQuery = useUserProfiles({ params })
	const [showCreateModal, setShowCreateModal] = useState(false)

	const startToggledFiltersOpen = useMemo(() => {
		return params.isGuest !== null || params.noSites !== null
	}, [params.isGuest, params.noSites])

	return (
		<Card heading="Customer User Management" headingIcon="user-cog">
			<Button
				variant="secondary-cta"
				icon="plus-circle"
				containerProps={{ mb: 2 }}
				onClick={() => setShowCreateModal(true)}
			>
				Create customer user
			</Button>

			<Table
				query={usersQuery}
				queryParamState={params}
				setQueryParamState={setUrlState}
				columns={columns}
				pinnedQueryFilters={[SearchQueryFilter]}
				toggledQueryFilters={[UserGuestQueryFilter]}
				showToggledFiltersByDefault={startToggledFiltersOpen}
				onRowClick={({ original }) =>
					history.push({
						pathname: `/pos-users/${original.id}`,
						state: { params },
					})
				}
				filterResetValues={defaultParams}
				storeColumnVisibility
			/>

			<CreateCustomerUserModal
				isOpen={showCreateModal}
				onClose={() => setShowCreateModal(false)}
			/>
		</Card>
	)
}

const defaultParams = {
	...defaultUserProfilesQueryParams,
	isCustomer: true,
}

const columns: Column<UserProfile>[] = [
	{
		Header: "First name",
		accessor: "firstName",
	},
	{
		Header: "Last name",
		accessor: "lastName",
	},
	{
		Header: "Email",
		accessor: "email",
	},
	{
		Header: "Site count",
		hiddenByDefault: true,
		disableSortBy: true,
		accessor: ({ siteCount }) => formatNumber(siteCount),
	},
	{
		Header: "Is guest?",
		headingTooltip: "Guest users cannot have any sites set up with NCS",
		hiddenByDefault: true,
		disableSortBy: true,
		accessor: ({ isGuest }) => (isGuest === true ? "Guest" : "-"),
	},
	{
		Header: `Created date (${getTimezoneAbbreviation()})`,
		id: "effDate",
		hiddenByDefault: true,
		accessor: ({ effectiveDate }) => formatDateTime(effectiveDate),
	},
	{
		Header: `Last login (${getTimezoneAbbreviation()})`,
		id: "lastLogin",
		accessor: ({ lastLogin }) => (lastLogin ? formatDateTime(lastLogin) : "-"),
	},
	{
		Header: "Non-guest with no sites?",
		hiddenByDefault: true,
		disableSortBy: true,
		accessor: ({ siteCount, isGuest }) => !siteCount && !isGuest,
		Cell: ({ row: { original } }: Cell<UserProfile>) => {
			return !original.siteCount && !original.isGuest ?
					<Tooltip title="This user is not configured to be a guest but does not have any sites set up">
						<Icon icon="exclamation-triangle" color="warning" />
					</Tooltip>
				:	"-"
		},
	},
]

export default CustomerUsers

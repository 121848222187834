import { FC, useMemo, useState } from "react"

import { ShipmentPackageSize, useShipmentPackageSizes } from "@ncs/ncs-api"

import { ExtendableSelectProps, Select } from "../inputs"
import { NewPackageSizeModal } from "../modals"

export interface PackageSizeSelectorProps extends ExtendableSelectProps<ShipmentPackageSize> {
	/**
	 * Don't add in the option to allow user to create another package size.
	 */
	hideCreateNewOption?: boolean
}

export const PackageSizeSelector: FC<PackageSizeSelectorProps> = ({
	onChange,
	hideCreateNewOption = false,
	...rest
}) => {
	const [showNewModal, setShowNewModal] = useState(false)

	const [sizes, sizesLoading] = useShipmentPackageSizes()

	const onNewSizeCreated = (size: ShipmentPackageSize) => {
		onChange(size.id)
		setShowNewModal(false)
	}

	const handleChange = (option: ShipmentPackageSize | null) => {
		if (option?.id === newBoxOption.id) {
			setShowNewModal(true)
		} else {
			onChange(option?.id ?? null, option ?? undefined)
		}
	}

	const preparedOptions = useMemo(() => {
		const options: ShipmentPackageSize[] = [...(sizes ?? [])].sort((a, b) =>
			a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
		)

		if (!hideCreateNewOption) {
			options.push(newBoxOption)
		}

		return options
	}, [sizes, hideCreateNewOption])

	return (
		<>
			<Select
				label="Type"
				disabled={sizesLoading}
				{...rest}
				onChange={(value, option) => handleChange(option ?? null)}
				options={preparedOptions}
				valueAccessor="id"
				textAccessor={(option) =>
					option.id === newBoxOptionId ?
						option.name
					:	`${option.name} (${option.height}x${option.width}x${option.length})`
				}
			/>

			{showNewModal && (
				<NewPackageSizeModal
					onClose={() => setShowNewModal(false)}
					onCreation={onNewSizeCreated}
				/>
			)}
		</>
	)
}

const newBoxOptionId = "new"

const newBoxOption: ShipmentPackageSize = {
	id: newBoxOptionId,
	name: "Create new size option",
	height: 0,
	width: 0,
	length: 0,
}

import React, { useEffect, useState } from "react"
import { Checkbox, makeStyles } from "@material-ui/core"
import { CheckCircleOutline } from "@material-ui/icons"
import { usePrevious } from "@ncs/bricks/util/hooks"

const useStyles = makeStyles(({ palette }) => ({
	checkbox: {
		textAlign: "center",
		maxWidth: "3rem",
		height: "3rem",
		"& .check-circle": {
			fontSize: "1rem",
			color: palette.success.main,
		},
	},
}))

const InvoiceRowCheckbox = ({
	index,
	submitted,
	disabled,
	checked: parentChecked,
	onCheckboxClick,
}) => {
	const classes = useStyles()
	const [localChecked, setLocalChecked] = useState(parentChecked)

	// Whenever the invoice prop changes, see if we should update our local state.
	// This happens in the case of user shift-clicking or using bulk check button.
	const prevParentChecked = usePrevious(parentChecked)
	useEffect(() => {
		if (parentChecked !== prevParentChecked && parentChecked !== localChecked) {
			setLocalChecked(parentChecked)
		}
	}, [localChecked, parentChecked, prevParentChecked])

	const handleCheckboxClick = (e) => {
		const wasShiftClicked = e.shiftKey
		const newState = e.target.checked

		setLocalChecked(newState)
		setTimeout(() => onCheckboxClick(index, wasShiftClicked, newState), 1)
	}

	return (
		<td className={classes.checkbox}>
			{!submitted ?
				<Checkbox
					checked={localChecked}
					onClick={handleCheckboxClick}
					color="primary"
					disabled={disabled}
				/>
			:	<CheckCircleOutline className="check-circle" />}
		</td>
	)
}

export default React.memo(InvoiceRowCheckbox)

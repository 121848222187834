import { FC, useState } from "react"

import { APPLICATION, CustomerDetail, UpdateCustomerPatch, useUserCanUse } from "@ncs/ncs-api"
import { extractNumber, formatPercentage } from "@ncs/ts-utils"
import {
	EmptyValueDash,
	encodeUrlState,
	GridContainer,
	GridItem,
	Icon,
	LabeledData,
	Link,
	NumericInput,
	RadioBoolean,
} from "@ncs/web-legos"

import { PartGroupsUrlState } from "~/views/Finance/PartGroups/PartGroups"
import { PricingMatricesUrlState } from "~/views/Finance/PricingMatrix/PricingMatrices"

export interface DetailsTabFinanceProps {
	customer: CustomerDetail
	update: (updates: Partial<UpdateCustomerPatch>) => Promise<void>
}

export const DetailsTabFinance: FC<DetailsTabFinanceProps> = ({ customer, update }) => {
	const isReportsAdmin = useUserCanUse(APPLICATION.CustomerManagementReportsAdmin)
	const isAbleToEditChemSurcharge = useUserCanUse(APPLICATION.FuelSurchargeEditor)
	const [isSaving, setIsSaving] = useState<keyof UpdateCustomerPatch | null>(null)

	const [newPartDiscount, setNewPartDiscount] = useState<number | null>(
		extractNumber(customer.partDiscountPercent)
	)
	const [newChemDiscount, setNewChemDiscount] = useState<number | null>(
		extractNumber(customer.chemicalDiscountPercent)
	)
	const [newDetailDiscount, setNewDetailDiscount] = useState<number | null>(
		extractNumber(customer.detailDiscountPercent)
	)
	const [newServiceBillable, setNewServiceBillable] = useState(customer.fuelSurchargeBillable)
	const [newChemicalBillable, setNewChemicalBillable] = useState(
		customer.chemicalSurchargeBillable ?? false
	)

	return (
		<>
			<GridContainer alignItems="baseline" rowGap={0}>
				<GridItem sm={6} md={4} lg={3}>
					<LabeledData label="Credit status">
						{customer.creditCheckStatus || <EmptyValueDash />}
					</LabeledData>
				</GridItem>
				<GridItem sm={6} md={4} lg={3}>
					<LabeledData label="AR info">
						{customer.arTerm || <EmptyValueDash />}
					</LabeledData>
				</GridItem>
				<GridItem sm={6} md={4} lg={3}>
					<LabeledData label="Pricing matrixes">
						{customer.priceMatrixList.map((matrix) => {
							return (
								<Link
									key={matrix.id}
									inline={false}
									newTab
									to={`/finance/matrix-pricing/${encodeUrlState<
										Partial<PricingMatricesUrlState>
									>({
										urlMatrixId: matrix.id.toString(),
									})}`}
									icon="external-link"
								>
									{matrix.name}
								</Link>
							)
						})}
						{customer.priceMatrixList.length === 0 && <EmptyValueDash />}
					</LabeledData>
				</GridItem>
				<GridItem sm={6} md={4} lg={3}>
					<LabeledData label="Part groups">
						{customer.partGroupList.map((group) => {
							return (
								<Link
									inline={false}
									key={group.id}
									newTab
									to={`/finance/part-group-pricing${encodeUrlState<
										Partial<PartGroupsUrlState>
									>({ id: group.id })}`}
									icon="external-link"
								>
									{group.name} {formatPercentage(group.discount)}
								</Link>
							)
						})}
						{customer.partGroupList.length === 0 && <EmptyValueDash />}
					</LabeledData>
				</GridItem>
			</GridContainer>
			<GridContainer>
				<GridItem sm={6} md={4} lg={3}>
					<LabeledData
						label="Part discount %"
						editingRender={
							isReportsAdmin ?
								() => (
									<NumericInput
										value={newPartDiscount}
										onChange={(value) => setNewPartDiscount(value ?? null)}
										max={10}
										doPercentageMath
										decimalScale={3}
										placeholder="Eg, 5%"
									/>
								)
							:	undefined
						}
						onSaveEdit={async () => {
							setIsSaving("partDiscountPercent")
							await update({ partDiscountPercent: newPartDiscount ?? 0 })
							setIsSaving(null)
						}}
						isSavingEdit={isSaving === "partDiscountPercent"}
						onCancelEdit={() =>
							setNewPartDiscount(extractNumber(customer.partDiscountPercent))
						}
					>
						{formatPercentage(extractNumber(customer.partDiscountPercent))}
					</LabeledData>
				</GridItem>
				<GridItem sm={6} md={4} lg={3}>
					<LabeledData
						label="Chemical discount %"
						editingRender={
							isReportsAdmin ?
								() => (
									<NumericInput
										value={newChemDiscount}
										onChange={(value) => setNewChemDiscount(value ?? null)}
										max={40}
										doPercentageMath
										decimalScale={3}
										placeholder="Eg, 5%"
									/>
								)
							:	undefined
						}
						onSaveEdit={async () => {
							setIsSaving("chemicalDiscountPercent")
							await update({ chemicalDiscountPercent: newChemDiscount ?? 0 })
							setIsSaving(null)
						}}
						isSavingEdit={isSaving === "chemicalDiscountPercent"}
						onCancelEdit={() =>
							setNewChemDiscount(extractNumber(customer.chemicalDiscountPercent))
						}
					>
						{formatPercentage(extractNumber(customer.chemicalDiscountPercent))}
					</LabeledData>
				</GridItem>
				<GridItem sm={6} md={4} lg={3}>
					<LabeledData
						label="Detail discount %"
						editingRender={
							isReportsAdmin ?
								() => (
									<NumericInput
										value={newDetailDiscount}
										onChange={(value) => setNewDetailDiscount(value ?? null)}
										max={40}
										decimalScale={3}
										doPercentageMath
										placeholder="Eg, 5%"
									/>
								)
							:	undefined
						}
						onSaveEdit={async () => {
							setIsSaving("detailDiscountPercent")
							await update({ detailDiscountPercent: newDetailDiscount ?? 0 })
							setIsSaving(null)
						}}
						isSavingEdit={isSaving === "detailDiscountPercent"}
						onCancelEdit={() =>
							setNewDetailDiscount(extractNumber(customer.detailDiscountPercent))
						}
					>
						{formatPercentage(extractNumber(customer.detailDiscountPercent))}
					</LabeledData>
				</GridItem>
				<GridItem sm={6} md={4} lg={3}>
					<LabeledData
						label="Service surcharge billable?"
						editingRender={
							isReportsAdmin ?
								() => (
									<RadioBoolean
										htmlName="service-surcharge-billable"
										value={newServiceBillable}
										onChange={setNewServiceBillable}
										yesText="Service surcharge billable"
										noText="Service surcharge not billable (default)"
										noFirst
									/>
								)
							:	undefined
						}
						onSaveEdit={async () => {
							setIsSaving("fuelSurchargeBillable")
							await update({ fuelSurchargeBillable: newServiceBillable })
							setIsSaving(null)
						}}
						isSavingEdit={isSaving === "fuelSurchargeBillable"}
						onCancelEdit={() => setNewServiceBillable(customer.fuelSurchargeBillable)}
					>
						{customer.fuelSurchargeBillable ?
							<>
								<Icon icon="check" color="gray" /> Service surcharge billable
							</>
						:	"Service surcharge not billable"}
					</LabeledData>
				</GridItem>
				{isAbleToEditChemSurcharge ?
					<GridItem sm={6} md={4} lg={3}>
						<LabeledData
							label="Chemical surcharge billable?"
							editingRender={
								isAbleToEditChemSurcharge ?
									() => (
										<RadioBoolean
											htmlName="chemical-surcharge-billable"
											value={newChemicalBillable}
											onChange={setNewChemicalBillable}
											yesText="Chemical surcharge billable"
											noText="Chemical surcharge not billable (default)"
											noFirst
										/>
									)
								:	undefined
							}
							onSaveEdit={async () => {
								setIsSaving("chemicalSurchargeBillable")
								await update({ chemicalSurchargeBillable: newChemicalBillable })
								setIsSaving(null)
							}}
							isSavingEdit={isSaving === "chemicalSurchargeBillable"}
							onCancelEdit={() =>
								setNewChemicalBillable(customer.chemicalSurchargeBillable)
							}
						>
							{customer.chemicalSurchargeBillable ?
								<>
									<Icon icon="check" color="gray" /> Chemical surcharge billable
								</>
							:	"Chemical surcharge not billable"}
						</LabeledData>
					</GridItem>
				:	null}
			</GridContainer>
		</>
	)
}

import { useMemo } from "react"

import uniqBy from "lodash/uniqBy"

import {
	ApplicationAssignment,
	ReportAssignment,
	useApplicationAssignments,
	useReportAssignments,
} from "@ncs/ncs-api"

/**
 * Pass in an array of `Application` IDs and look for `ApplicationAssignment`s
 * that have an applications list that matches what you passed in.
 */
export const useApplicationAssignmentMatches = (
	applicationIds: string[]
): ApplicationAssignment[] => {
	const [rawAssignments] = useApplicationAssignments()

	const assignments = useMemo(() => {
		// Some assignments have the same applications associated with them multiple times, ie there
		// are extra rows on their join table with the same thing. De-dupe the application lists
		// before proceeding, otherwise our length comparison gets screwed up.
		return (rawAssignments ?? []).map((assignment) => ({
			...assignment,
			applications: uniqBy(assignment.applications, (item) => item.id),
		}))
	}, [rawAssignments])

	const matches = useMemo(() => {
		const result: ApplicationAssignment[] = []

		if (assignments) {
			assignments.forEach((assignment) => {
				if (
					assignment.applications.every((app) => applicationIds.includes(app.id)) &&
					assignment.applications.length === applicationIds.length
				) {
					result.push(assignment)
				}
			})
		}

		return result
	}, [applicationIds, assignments])

	if (applicationIds.length === 0) {
		// Even though there may be application assignments that have zero applications,
		// we're going to say that nothing was matched if nothing was passed in.
		return []
	}

	return matches
}

/**
 * Pass in an array of `Report` IDs and look for `ReportAssignments`s
 * that have a report list that matches what you passed in.
 */
export const useReportAssignmentMatches = (reportIds: string[]): ReportAssignment[] => {
	const [rawAssignments] = useReportAssignments()

	const assignments = useMemo(() => {
		// De-dupe the reports attached to the assignment, just in case.
		return (rawAssignments ?? []).map((assignment) => ({
			...assignment,
			reports: uniqBy(assignment.reports, (report) => report.id),
		}))
	}, [rawAssignments])

	const matches = useMemo(() => {
		const result: ReportAssignment[] = []

		if (assignments) {
			assignments.forEach((assignment) => {
				if (
					assignment.reports.every((report) => reportIds.includes(report.id)) &&
					assignment.reports.length === reportIds.length
				) {
					result.push(assignment)
				}
			})
		}

		return result
	}, [reportIds, assignments])

	if (reportIds.length === 0) {
		// Even though there may be application assignments that have zero applications,
		// we're going to say that nothing was matched if nothing was passed in.
		return []
	}

	return matches
}

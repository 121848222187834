import React from "react"
import PropTypes from "prop-types"
import { MaterialUISizeKeys, MaterialUISizeValues } from "../../util/types"
import { GridItem, DashboardWidgetWrapper } from "../../components"
import { WidgetMap } from "./ConfigurableDashboard"

export type GridSizeMap = Partial<Record<MaterialUISizeKeys, MaterialUISizeValues>>

const defaultGridSizes: GridSizeMap = {
	xs: 12,
	sm: 6,
	md: 6,
	lg: 3,
}

const EmptyWidget = () => <div />

const getWidgetComponentWidths = (
	WidgetComponent: (props?: Record<string, unknown>) => JSX.Element,
	width?: MaterialUISizeValues | GridSizeMap | null
) => {
	// @ts-expect-error. TODO: Can anyone clarify why/how "defaultGridSizes" would ever be
	// a property on the component?
	let gridWidths = (WidgetComponent?.defaultGridSizes ?? defaultGridSizes) as GridSizeMap

	if (!!width && typeof width === "object") {
		gridWidths = width
	}

	if (typeof width === "number") {
		gridWidths = {
			xs: 12,
			sm: width,
			md: width,
			lg: width,
			xl: width,
		}
	}

	return gridWidths
}

export interface WidgetConfig extends Record<string, unknown> {
	widget: string
	width?: MaterialUISizeValues | Partial<Record<MaterialUISizeKeys, MaterialUISizeValues>>
}

export interface DashboardWidgetProps {
	widgetConfig: WidgetConfig
	index: number
	widgetMap: WidgetMap
	isEditing: boolean
	onRemoveWidget?: (index: number) => void
	onStartEditing?: () => void
	onUpdateWidgetConfig?: (config: WidgetConfig) => void
}

const DashboardWidget: React.FC<DashboardWidgetProps> = ({
	index,
	isEditing,
	onRemoveWidget,
	onStartEditing,
	onUpdateWidgetConfig,
	widgetConfig: { widget, width, ...rest },
	widgetMap,
}) => {
	const WidgetComponent = widgetMap[widget] ?? EmptyWidget

	const gridWidths = getWidgetComponentWidths(WidgetComponent, width)

	if (isEditing) {
		return (
			<DashboardWidgetWrapper
				index={index}
				onRemoveWidget={onRemoveWidget}
				gridWidths={gridWidths}
				{...rest}
			>
				<WidgetComponent
					{...rest}
					name={widget}
					key={index}
					noWrapper={true}
					onClick={undefined}
					isEditing={isEditing}
					onUpdateWidgetConfig={onUpdateWidgetConfig}
				/>
			</DashboardWidgetWrapper>
		)
	}

	return (
		<GridItem {...gridWidths}>
			<WidgetComponent
				{...rest}
				name={widget}
				key={index}
				noWrapper={true}
				onLongPress={onStartEditing}
			/>
		</GridItem>
	)
}

export const widgetConfigPropType = PropTypes.shape({
	widget: PropTypes.string.isRequired,
	width: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.object.isRequired]),
})

DashboardWidget.propTypes = {
	// @ts-expect-error. Permit TS to describe widgetConfig more specifically than PropTypes can.
	widgetConfig: widgetConfigPropType.isRequired,

	index: PropTypes.number.isRequired,
	widgetMap: PropTypes.object.isRequired,
	isEditing: PropTypes.bool.isRequired,
	onRemoveWidget: PropTypes.func,
	onStartEditing: PropTypes.func,
	onUpdateWidgetConfig: PropTypes.func,
}

export default React.memo(DashboardWidget)

import { FC } from "react"

import { PartChemical, usePartChemicals } from "@ncs/ncs-api"

import { ExtendableSelectProps, Select } from "../inputs"

export interface PartChemicalSelectorProps
	extends ExtendableSelectProps<PartChemical, PartChemical> {}

export const PartChemicalSelector: FC<PartChemicalSelectorProps> = ({
	value,
	onChange,
	...rest
}) => {
	const [chemicals, isLoading] = usePartChemicals()

	return (
		<Select
			disabled={isLoading}
			label="Part chemical"
			fillContainer
			{...rest}
			value={value?.id.toString() ?? null}
			onChange={(newValue, newOption) => onChange(newOption ?? null)}
			options={chemicals ?? []}
			valueAccessor={(option) => option.id.toString()}
			textAccessor="name"
		/>
	)
}

import React from "react"
import PropTypes from "prop-types"

import { default as ReactImagesUploader } from "react-images-upload"

import { successColor } from "../../assets/jss/material-dashboard-pro-react"

export default function ImageUploader(props) {
	return (
		<ReactImagesUploader
			withIcon={true}
			buttonText={props.buttonText}
			onChange={props.onImagesChange}
			label={props.label}
			imgExtension={props.imageExtensions}
			buttonClassName={props.buttonClassName}
			buttonStyles={props.buttonStyles}
			maxFileSize={props.maxFileSize}
			withPreview={props.preview}
		/>
	)
}

ImageUploader.defaultProps = {
	preview: true,
	label: "Accepts .jpg, .png, .jpeg -- Max File Size: 5mb",
	imageExtensions: [".jpg", ".png", ".jpeg"],
	buttonClassName:
		"MuiButtonBase-root-171 MuiButton-root-145 MuiButton-text-147 MuiButton-flat-150 RegularButton-button-116 RegularButton-sm-140 RegularButton-success-120 RegularButton-round-141",
	buttonStyles: { background: successColor[0] },
	maxFileSize: 5242880,
	buttonText: "Choose Image",
}

ImageUploader.propTypes = {
	onImagesChange: PropTypes.func.isRequired,
	preview: PropTypes.bool,
	label: PropTypes.string,
	imageExtensions: PropTypes.array,
	buttonClassName: PropTypes.string,
	buttonStyles: PropTypes.object,
	maxFileSize: PropTypes.number,
	buttonText: PropTypes.string,
}

import React, { HTMLAttributes, useMemo } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { makeStyles } from "@material-ui/core"

import styles from "../../assets/jss/material-dashboard-pro-react/components/cardIconStyle"
import { MaterialDashboardProColors } from "../../util/types"

const useStyles = makeStyles(styles)

export interface CardIconProps extends HTMLAttributes<HTMLDivElement> {
	className?: string
	color?: Exclude<MaterialDashboardProColors, "gray">
}

const CardIcon: React.FC<CardIconProps> = ({ className, color, children, ...rest }) => {
	const classes = useStyles()

	const cardIconClasses = useMemo(
		() =>
			classNames(className, {
				[classes.cardIcon]: true,
				[classes[(color + "CardHeader") as keyof typeof classes]]: color,
			}),
		[classes, className, color]
	)

	return (
		<div className={cardIconClasses} {...rest}>
			{children}
		</div>
	)
}

CardIcon.propTypes = {
	className: PropTypes.string,
	color: PropTypes.oneOf(["warning", "success", "danger", "info", "primary", "rose"]),
	children: PropTypes.node,
}

export default CardIcon

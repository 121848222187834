import React, { useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { useApi } from "@ncs/bricks/util/hooks"
import { getDashboardConfig, updateDashboardConfig } from "../../redux/services/dashboards"

import { ConfigurableDashboard } from "@ncs/bricks/components"

const useDashboardConfig = (dashboardKey, defaultConfig) => {
	const [fetchConfig, { loading, result: dashboardConfig }] = useApi(
		getDashboardConfig,
		undefined,
		true
	)

	const fetchDashboard = () => {
		fetchConfig(dashboardKey)
	}

	const [onSaveWidgets] = useApi(
		(config) => updateDashboardConfig(dashboardKey, config),
		fetchDashboard
	)

	useEffect(fetchDashboard, [dashboardKey, fetchConfig])

	const widgets = useMemo(
		() => dashboardConfig?.config ?? defaultConfig,
		[dashboardConfig, defaultConfig]
	)

	return [loading, widgets, onSaveWidgets]
}

function PersistedDashboard({ dashboardKey, defaultConfig, widgetMap }) {
	const [loading, widgets, saveWidgets] = useDashboardConfig(dashboardKey, defaultConfig)

	return (
		<ConfigurableDashboard
			loading={loading}
			widgets={widgets}
			widgetMap={widgetMap}
			onSaveWidgets={saveWidgets}
		/>
	)
}

PersistedDashboard.propTypes = {
	/** which configurable dashboard is this? (controls which API endpoint to hit) **/
	dashboardKey: PropTypes.string,

	/** what should the dashboard config be if the user hasn't configured theirs **/
	defaultConfig: PropTypes.array,

	/** a map of the possible widget names to their component **/
	widgetMap: PropTypes.object,
}

export default PersistedDashboard

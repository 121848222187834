import { FC } from "react"

import { SalesmanType, useSalesmanTypes } from "@ncs/ncs-api"

import { ExtendableSelectProps, Select } from "../inputs"

export interface SalesTypeSelectorProps extends ExtendableSelectProps<SalesmanType> {}

export const SalesTypeSelector: FC<SalesTypeSelectorProps> = ({ ...rest }) => {
	const [types, isLoading] = useSalesmanTypes()

	return (
		<Select
			disabled={isLoading}
			label="Product type"
			{...rest}
			options={types ?? []}
			valueAccessor="id"
			textAccessor="description"
		/>
	)
}

import { Dispatch, ReactElement, SetStateAction, useState } from "react"

import { UserMinimal } from "@ncs/ncs-api"

import { useChangeCallback } from "../../util"
import { UserSelector } from "../selectors"

export interface UserQueryParams {
	user?: string | null
	picker?: string | null
	assignedTo?: string | null
}

export interface UserQueryFilterProps<QueryParamState extends UserQueryParams> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
	label?: string
	employeesOnly?: boolean
	paramKey?: keyof UserQueryParams
}

export const UserQueryFilter = <QueryParamState extends UserQueryParams>({
	queryParamState,
	setQueryParamState,
	label = "User",
	employeesOnly,
	paramKey = "user",
}: UserQueryFilterProps<QueryParamState>): ReactElement => {
	const [selectedUser, setSelectedUser] = useState<UserMinimal | null>(null)

	const handleChange = (newUser: UserMinimal | null) => {
		// Set local state with full UserMinimal object.
		setSelectedUser(newUser)
		// Set query param state with just the UserMinimal ID.
		setQueryParamState((prev) => ({
			...prev,
			[paramKey]: newUser?.id ?? null,
		}))
	}

	// Handle the user clicking Reset button.
	useChangeCallback(queryParamState[paramKey], (newUserId, prevUserId) => {
		if (!newUserId && !!prevUserId && !!selectedUser) {
			setSelectedUser(null)
		}
	})

	return (
		<UserSelector
			value={selectedUser}
			onChange={handleChange}
			initialId={queryParamState[paramKey]}
			fillContainer
			label={label}
			employeesOnly={employeesOnly}
		/>
	)
}

import { Dispatch, FC, SetStateAction, useCallback, useMemo, useState } from "react"

import uniqBy from "lodash/uniqBy"
import { Cell, Column } from "react-table"

import {
	makeApiErrorMessage,
	Organization,
	OrganizationCustomer,
	useOrganizationCustomers,
	useUpdateOrganization,
	useUpdateOrganizationCustomers,
} from "@ncs/ncs-api"
import { displayDate, formatNumber, getTimezoneAbbreviation, noFalsy } from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	Box,
	Button,
	Callout,
	ConfirmationModal,
	ConfirmationModalConfig,
	EditBooleanModal,
	EditBooleanModalProps,
	EditStringModal,
	EditStringModalProps,
	encodeUrlState,
	getAddressFields,
	Heading,
	HeadingDivider,
	Icon,
	IconButton,
	Label,
	Link,
	Paragraph,
	SelectCustomerGroupModal,
	SelectCustomerGroupModalPayload,
	Table,
	TableProps,
	ThrottledTextInput,
	useToast,
} from "@ncs/web-legos"

import {
	EnterprisesAndOrganizationsTab,
	EnterprisesAndOrganizationsUrlState,
} from "../../../EnterprisesAndOrganizations"
import { EnterprisesTabUrlState } from "../../enterprises-tab"
import { ReassignEnterpriseModal } from "../../reassign-enterprise-modal"

export interface SelectedOrganizationProps {
	organization: Organization
	setSelectedOrganizationId: (id: string | null) => void
	setShowCreateModal: Dispatch<SetStateAction<boolean>>
}

export const SelectedOrganization: FC<SelectedOrganizationProps> = ({
	organization,
	setSelectedOrganizationId,
	setShowCreateModal,
}) => {
	const { makeSuccessToast, makeErrorToast } = useToast()
	const [editStringModalConfig, setEditStringModalConfig] =
		useState<EditStringModalProps | null>(null)
	const [editBooleanModalConfig, setEditBooleanModalConfig] =
		useState<EditBooleanModalProps | null>(null)
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)
	const [search, setSearch] = useState<string | null>(null)
	const [showReassignModal, setShowReassignModal] = useState(false)
	const [showAddCustomerModal, setShowAddCustomerModal] = useState(false)

	const [customers, customersLoading] = useOrganizationCustomers(organization.id)
	const updateOrganization = useUpdateOrganization()
	const updateCustomers = useUpdateOrganizationCustomers(organization.id)

	const editName = () => {
		setEditStringModalConfig({
			initialValue: organization.name,
			allowEmpty: false,
			title: "Edit Organization Name",
			label: "Name",
			onClose: () => setEditStringModalConfig(null),
			onSave: async (newValue) => {
				if (newValue) {
					await updateOrganization({
						id: organization.id,
						updates: {
							name: newValue,
						},
					})
				}
			},
		})
	}

	const editStatus = () => {
		setEditBooleanModalConfig({
			initialSelection: organization.isActive,
			description:
				"Set this organization's status. An inactive organization will be hidden in other lists of organizations throughout this site.",
			noText: "Inactive",
			yesText: "Active (default)",
			title: "Organization Status",
			onClose: () => setEditBooleanModalConfig(null),
			onSave: async (newValue) => {
				await updateOrganization({
					id: organization.id,
					updates: { isActive: newValue },
				})
				makeSuccessToast("Enterprise updated")
			},
		})
	}

	const saveNewCustomers = async (
		{ shipToId, billToId }: SelectCustomerGroupModalPayload,
		includeInactive = false
	) => {
		if (!shipToId && !billToId) {
			throw new Error("No customers selected")
		}
		await updateCustomers({
			addBillTo: billToId ?? undefined,
			addShipTo: shipToId ?? undefined,
			addIncludesInactive: includeInactive,
		})
		makeSuccessToast("Customer site(s) added to organization")
	}

	const removeCustomer = useCallback(
		(customer: OrganizationCustomer) => {
			setConfirmationConfig({
				title: "Remove Customer From Organization",
				message: (
					<>
						Confirm: Remove{" "}
						<strong>
							({customer.customerNumber}) {customer.name}
						</strong>{" "}
						from {organization.name}?
					</>
				),
				onConfirm: async () => {
					try {
						await updateCustomers({
							removeShipTo: customer.id,
						})
					} catch (e) {
						makeErrorToast(makeApiErrorMessage(e))
					}
				},
			})
		},
		[organization.name, updateCustomers, makeErrorToast]
	)

	const filteredCustomers = useMemo(() => {
		const searchChunks = search?.trim().toUpperCase().split(" ") ?? []

		return (customers ?? []).filter((customer) => {
			if (!search) return true

			const searchable = noFalsy([
				customer.id,
				customer.address1,
				customer.address2,
				customer.city,
				customer.customerNumber,
				customer.name,
				customer.postalcode,
				customer.state,
				customer.billTos.reduce((concat, billTo) => {
					return concat + [billTo.id, billTo.customerNumber, billTo.name].join("")
				}, ""),
			])
				.join("")
				.toUpperCase()

			return searchChunks.every((chunk) => searchable.includes(chunk))
		})
	}, [customers, search])

	const rowMenu = useMemo((): TableProps<OrganizationCustomer>["rowMenu"] => {
		return [
			{
				label: "View customer management page",
				iconName: "external-link",
				onClick: ({ original }) => {
					window.open(`/customer-management/${original.id}`)
				},
			},
			{
				label: "Remove from organization",
				iconName: "trash-alt",
				onClick: ({ original }) => removeCustomer(original),
			},
		]
	}, [removeCustomer])

	return (
		<>
			<AnimatedEntrance show>
				<Box display="flex" justifyContent="space-between" mb={3}>
					<Button icon="long-arrow-left" onClick={() => setSelectedOrganizationId(null)}>
						All organizations
					</Button>
					<Button icon="plus" onClick={() => setShowCreateModal(true)}>
						Create new organization
					</Button>
				</Box>

				<Callout display="block" variant="info">
					<Label>Organization name</Label>
					<Heading variant="h1">
						{organization.name}
						<IconButton
							icon="pencil"
							color="primary"
							onClick={editName}
							title="Edit organization name"
						/>
					</Heading>

					<Box display="flex" mt={1} columnGap={3}>
						<div>
							<Label>Parent enterprise</Label>
							<Box display="flex" alignItems="center" mt={-0.35}>
								<Link
									newTab
									icon="external-link"
									to={`/customers/enterprises-and-organizations${encodeUrlState<
										EnterprisesAndOrganizationsUrlState &
											EnterprisesTabUrlState
									>({
										tab: EnterprisesAndOrganizationsTab.Enterprises,
										enterpriseId: organization.enterpriseId.toString(),
									})}`}
								>
									{organization.enterpriseName}
								</Link>
								<IconButton
									icon="pencil"
									color="primary"
									onClick={() => setShowReassignModal(true)}
									title="Change parent enterprise"
								/>
							</Box>
						</div>
						<div>
							<Label>Status</Label>
							<Box display="flex" alignItems="center" gap={0.5} mt={-0.35}>
								{organization.isActive ?
									<>
										<Icon icon="check" color="gray" />{" "}
										<Paragraph>Active</Paragraph>
									</>
								:	<Paragraph>Not active</Paragraph>}
								<IconButton icon="pencil" color="primary" onClick={editStatus} />
							</Box>
						</div>
						<div>
							<Label>Modified date ({getTimezoneAbbreviation()})</Label>
							<Paragraph>{displayDate(organization.modifiedOn)}</Paragraph>
						</div>
						<div>
							<Label>Created date ({getTimezoneAbbreviation()})</Label>
							<Paragraph>{displayDate(organization.createdOn)}</Paragraph>
						</div>
					</Box>
				</Callout>

				<HeadingDivider headingVariant="h4" bold mt={4} mb={2}>
					Associated Customer Sites ({formatNumber(organization.customersCount)})
				</HeadingDivider>
				<Box display="flex" justifyContent="space-between" alignItems="flex-end" mb={2}>
					<Button
						icon="plus-circle"
						variant="secondary-cta"
						onClick={() => setShowAddCustomerModal(true)}
					>
						Add customer site
					</Button>
					<ThrottledTextInput
						value={search}
						onChange={setSearch}
						placeholder="Search..."
						icon="search"
						clearable
						fillContainer={false}
						mb={0}
					/>
				</Box>

				<Table
					data={filteredCustomers}
					columns={columns}
					isLoading={customersLoading}
					rowMenu={rowMenu}
					noDataText={
						search ?
							`No sites match "${search}"`
						:	"No sites associated with this organization"
					}
				/>
			</AnimatedEntrance>

			{showReassignModal && (
				<ReassignEnterpriseModal
					organization={organization}
					onClose={() => setShowReassignModal(false)}
				/>
			)}
			{showAddCustomerModal && (
				<SelectCustomerGroupModal
					onSave={saveNewCustomers}
					onClose={() => setShowAddCustomerModal(false)}
					title="Add Customer(s) To Organization"
					saveButtonText="Add Customer(s)"
					includeInactiveInSearch
					showIncludeInactiveCheckbox
				/>
			)}
			{!!editStringModalConfig && <EditStringModal {...editStringModalConfig} />}
			{!!editBooleanModalConfig && <EditBooleanModal {...editBooleanModalConfig} />}
			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</>
	)
}

const columns: Column<OrganizationCustomer>[] = [
	{
		Header: "Customer #",
		accessor: ({ customerNumber }) => customerNumber,
	},
	{
		Header: "Name",
		accessor: ({ name }) => name,
	},
	{
		Header: "Address",
		disableSortBy: true,
		accessor: (original) =>
			getAddressFields(original, {
				exclude: ["name", "zip"],
			}).join(", "),
	},
	{
		Header: "Bill-tos",
		id: "Bill-tos",
		Cell: ({ row: { original } }: Cell<OrganizationCustomer>) => {
			return uniqBy(original.billTos, "id").map((billTo) => (
				<Box key={`${billTo.id}`}>
					({billTo.customerNumber}) {billTo.name}
				</Box>
			))
		},
	},
]

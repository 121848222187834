import { get, rsaaTypes, RSAA } from "@ncs/bricks/middleware/portalApiMiddleware"

export const LIST_PARTS = rsaaTypes("catalog/parts/list")
export const SEARCH_PARTS = rsaaTypes("catalog/parts/search")
export const LIST_CATEGORIES = rsaaTypes("catalog/categories/list")
export const LIST_SYSTEMS = rsaaTypes("catalog/systems/list")

export const listParts = (querystring_params) => ({
	[RSAA]: {
		api: get("/catalog/parts/", querystring_params),
		types: LIST_PARTS,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const listCategories = () => ({
	[RSAA]: {
		api: get("/catalog/categories/"),
		types: LIST_CATEGORIES,
	},
})

export const listSystems = () => ({
	[RSAA]: {
		api: get("/catalog/systems/"),
		types: LIST_SYSTEMS,
	},
})

const searchPartsAPI = (searchText) => ({
	[RSAA]: {
		api: get("/catalog/parts/", {
			search: searchText,
			fields: "id,part_number,description,info,list_price,net_price,web_price,unit_of_measure,image_url",
		}),
		types: SEARCH_PARTS,
	},
})

export const searchCustomer = (searchText) => {
	const debounced = (dispatch) => {
		dispatch(searchPartsAPI(searchText))
	}

	debounced.meta = {
		debounce: {
			time: 325,
			key: SEARCH_PARTS,
		},
	}

	return debounced
}

const initialState = {
	isLoadingParts: false,
	isLoadingCategories: false,
	isLoadingSystems: false,
	isSearchingParts: false,
	parts: [],
	partsPages: 1,
	partsRequestTimestamp: null,
	categories: [],
	systems: [],
	manufacturers: [],
	error: null,
}

export const parseSystemHierarchy = (systems) => {
	let manufacturers = []

	for (let item of systems) {
		let { manufacturer, ...system } = item

		let maker = manufacturers.find((m) => m.id === manufacturer.id)
		if (!maker) {
			maker = { ...manufacturer, systems: [] }
			manufacturers.push(maker)
		}

		maker.systems.push(system)
	}

	return manufacturers
}

const catalog = (state = initialState, action) => {
	switch (action.type) {
		case LIST_PARTS.request:
			return {
				...state,
				isLoadingParts: true,
			}
		case LIST_PARTS.success:
			// bail if we would be overwriting newer data
			if (state.partsRequestTimestamp > action.meta.timestamp) return state

			return {
				...state,
				isLoadingParts: false,
				parts: action.payload,
				partsPages: action.meta.pages,
				partsRequestTimestamp: action.meta.timestamp,
				error: null,
			}
		case LIST_PARTS.failure:
			return {
				...state,
				isLoadingParts: false,
				error: action.error,
			}

		case LIST_CATEGORIES.request:
			return {
				...state,
				isLoadingCategories: true,
			}
		case LIST_CATEGORIES.success:
			return {
				...state,
				isLoadingCategories: false,
				categories: action.payload,
				error: null,
			}
		case LIST_CATEGORIES.failure:
			return {
				...state,
				isLoadingCategories: false,
				error: action.error,
			}

		case LIST_SYSTEMS.request:
			return {
				...state,
				isLoadingSystems: true,
			}
		case LIST_SYSTEMS.success:
			return {
				...state,
				isLoadingSystems: false,
				systems: action.payload,
				manufacturers: parseSystemHierarchy(action.payload),
				error: null,
			}
		case LIST_SYSTEMS.failure:
			return {
				...state,
				isLoadingSystems: false,
				error: action.error,
			}

		case SEARCH_PARTS.request:
			return {
				...state,
				isSearchingParts: true,
			}
		case SEARCH_PARTS.success:
			return {
				...state,
				isSearchingParts: false,
				partSearchResults: action.payload,
				error: null,
			}
		case SEARCH_PARTS.failure:
			return {
				...state,
				isSearchingParts: false,
				error: action.error,
			}

		default:
			return state
	}
}
export default catalog

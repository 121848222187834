import React from "react"
import PropTypes from "prop-types"
import match from "autosuggest-highlight/match"
import parse from "autosuggest-highlight/parse"
import { MenuItem, withStyles } from "@material-ui/core"
import { formatNumberAsCurrency } from "@ncs/bricks/util/formatters"
import customSelectStyle from "@ncs/bricks/assets/jss/material-dashboard-pro-react/components/customSelectStyle"
import { SearchSelector, withCallApi } from "@ncs/bricks/components"

import { listCustomerParts } from "../../redux/services/pointOfSale"
import missingImage from "../../assets/img/ryko_no_image.png"

const styles = {
	...customSelectStyle,
	selectMenuItem: {
		...customSelectStyle.selectMenuItem,
		height: "60px",
		padding: 0,
	},
	wrapper: {
		width: "100%",
	},
	imgContainer: {
		width: "60px",
		height: "60px",
		overflow: "hidden",
		display: "inline-block",
		verticalAlign: "middle",
	},
	img: {
		width: "100%",
		// height: "auto",
		verticalAlign: "middle",
		border: "0",
	},
	partPrice: {
		margin: "0 10px",
		width: "100px",
		textAlign: "right",
		display: "inline-block",
	},
	partNumber: {
		width: "180px",
		textAlign: "center",
		display: "inline-block",
	},
	partTitle: {
		display: "inline-block",
	},
}

class PartSelector extends React.Component {
	handleGetSuggestions = (search) => {
		// note: SearchSelector handles debouncing for us
		return this.props.callApi(
			listCustomerParts({
				search,
				fields: "_id,online_part_number,title,image_url,price,net_price",
				pageSize: 10,
			})
		)
	}

	handleGetSuggestionValue = (part) => {
		return `${part.online_part_number}: ${part.title}`
	}

	handleSuggestionSelected = (event, { suggestion }) => {
		this.props.onPartSelected(suggestion)
	}

	handleRenderHighlightedText = (text, query) => {
		const matches = match(text, query)
		const parts = parse(text, matches)

		return (
			<span>
				{parts.map((part, index) =>
					part.highlight ?
						<span key={String(index)} style={{ fontWeight: 500 }}>
							{part.text}
						</span>
					:	<strong key={String(index)} style={{ fontWeight: 300 }}>
							{part.text}
						</strong>
				)}
			</span>
		)
	}

	handleRenderSuggestion = (part, { query, isHighlighted }) => {
		const { classes } = this.props
		const price = Math.min(part.price || Infinity, part.net_price || Infinity)

		return (
			<MenuItem
				selected={isHighlighted}
				component="div"
				classes={{
					root: classes.selectMenuItem,
					selected: classes.selectMenuItemSelected,
				}}
			>
				<div className={classes.wrapper}>
					<div className={classes.imgContainer}>
						<img
							className={classes.img}
							src={part.image_url || missingImage}
							alt={part.title}
						/>
					</div>
					<div className={classes.partPrice}>{formatNumberAsCurrency(price)}</div>
					<div className={classes.partNumber}>
						{this.handleRenderHighlightedText(part.online_part_number, query)}
					</div>
					<div className={classes.partTitle}>
						{this.handleRenderHighlightedText(part.title, query)}
					</div>
				</div>
			</MenuItem>
		)
	}

	render = () => {
		const { classes, label, placeholder, ...rest } = this.props
		return (
			<SearchSelector
				label={label}
				placeholder={placeholder}
				getSuggestions={this.handleGetSuggestions}
				getSuggestionValue={this.handleGetSuggestionValue}
				onSuggestionSelected={this.handleSuggestionSelected}
				renderSuggestion={this.handleRenderSuggestion}
				{...rest}
			/>
		)
	}
}

PartSelector.defaultProps = {
	label: "Part",
	placeholder: "Search for a part",
}

PartSelector.propTypes = {
	callApi: PropTypes.func.isRequired,
	onPartSelected: PropTypes.func.isRequired,
}

export default withStyles(styles)(withCallApi(PartSelector))

import { FC, useState } from "react"

import {
	EnterpriseMinimal,
	makeApiErrorMessage,
	useCreateEnterprise,
	useCreateOrganization,
} from "@ncs/ncs-api"
import {
	AnimatedEntrance,
	EnterpriseSelector,
	ExtendableModalProps,
	Modal,
	RadioBoolean,
	TextInput,
	useChangeCallback,
	useSetUrlState,
	useToast,
} from "@ncs/web-legos"

import { OrganizationsTabUrlState } from "../OrganizationsTab"

export interface CreateOrganizationModalProps extends ExtendableModalProps {}

export const CreateOrganizationModal: FC<CreateOrganizationModalProps> = ({ ...rest }) => {
	const { makeSuccessToast } = useToast()
	const [newOrgName, setNewOrgName] = useState<string | null>(null)
	const [isNewEnterprise, setIsNewEnterprise] = useState(false)
	const [newEnterpriseName, setNewEnterpriseName] = useState<string | null>(null)
	const [selectedEnterprise, setSelectedEnterprise] = useState<EnterpriseMinimal | null>(null)
	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)
	const setUrlState = useSetUrlState<OrganizationsTabUrlState>()

	const createEnterprise = useCreateEnterprise()
	const createOrganization = useCreateOrganization()

	const onSave = async () => {
		try {
			if (!newOrgName) throw new Error("Enter a name for the organization")
			setIsSaving(true)
			let enterpriseId: string

			// If they're creating a new enterprise, we need to do that first.
			if (isNewEnterprise) {
				if (!newEnterpriseName) throw new Error("Enter a name for the new enterprise")
				const { data } = await createEnterprise({ name: newEnterpriseName })
				enterpriseId = data
			} else {
				if (!selectedEnterprise) throw new Error("Select an enterprise")
				enterpriseId = selectedEnterprise.id
			}

			const { data } = await createOrganization({
				name: newOrgName,
				enterprise: enterpriseId,
			})
			makeSuccessToast("Organization created")
			setUrlState({ organizationId: data })
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	useChangeCallback(isNewEnterprise, (newIsNewEnterprise) => {
		if (newIsNewEnterprise) {
			setNewEnterpriseName(null)
		} else {
			setSelectedEnterprise(null)
		}
	})

	return (
		<Modal
			{...rest}
			title="Create New Organization"
			errorText={errorText}
			rightButtons={{
				buttonText: "Create Organization",
				isLoading: isSaving,
				onClick: onSave,
			}}
		>
			<TextInput
				value={newOrgName}
				onChange={setNewOrgName}
				label="Organization name"
				autoFocus
			/>
			<RadioBoolean
				mt={2}
				htmlName="is-new-enterprise"
				value={isNewEnterprise}
				onChange={setIsNewEnterprise}
				description="You can assign this new organization to an existing enterprise, or create a new one"
				noFirst
				noText="Assign to existing enterprise"
				yesText="Create a new enterprise"
			/>
			<AnimatedEntrance show={isNewEnterprise}>
				<TextInput
					value={newEnterpriseName}
					onChange={setNewEnterpriseName}
					label="New enterprise name"
				/>
			</AnimatedEntrance>
			<AnimatedEntrance show={!isNewEnterprise}>
				<EnterpriseSelector value={selectedEnterprise} onChange={setSelectedEnterprise} />
			</AnimatedEntrance>
		</Modal>
	)
}

import React from "react"

import AddCommentIcon from "@material-ui/icons/AddComment"

//custom components
import {
	GridContainer,
	QuickCard,
	GridItem,
	ButtonWithIcon,
	Input,
	Select,
	UserSelector,
	// Checkbox,
	TicketRequestorSelector,
} from "~/components"

// icons
import RemoveIcon from "@material-ui/icons/Remove"
import AddIcon from "@material-ui/icons/Add"

// styles
import { withStyles } from "@material-ui/core/styles"
const styles = {
	right: {
		textAlign: "right",
	},
}

function TicketCommentAdd(props) {
	let {
		description,
		onDescriptionChange,
		dsecriptionIsValid,
		descriptionErrorMessage,
		setInputRef,
		showValidationErrors,
		onSaveComment,
		onCancelCommentAdd,
		onRequestorTypeChange,
		selectedRequestor,
		requestorText,
		onRequestorTextChange,
		requestorTextIsValid,
		requestorTextErrorMessage,
		onTechSelected,
		// sendNotification,
		// sendNotificationIsValid,
		// onSendNotificationChange,
		isService,
		ticketCustomer,
		onSelectedTicketRequestorChange,
		selectedTicketRequestor,
	} = props

	return (
		<GridContainer>
			<GridItem xs={12}>
				<QuickCard title={"New Comment"} icon={<AddCommentIcon />}>
					<GridContainer>
						<GridItem xs={12} md={4} show={isService}>
							<Select
								id="ticketCommentRequestor"
								labelText="Requestor Type"
								value={selectedRequestor}
								onChange={onRequestorTypeChange}
								options={[
									{
										id: null,
										description: "Select a request type...",
										disabled: false,
									},
									{
										id: 1,
										description: "Technician",
									},
									{
										id: 2,
										description: "Other",
									},
								]}
								labelProps={{ shrink: true }}
								valueAccessor="id"
								textAccessor={(x) => x.description}
							/>
						</GridItem>
						<GridItem xs={12} md={4} show={selectedRequestor === 1}>
							<UserSelector onUserSelected={onTechSelected} label={"Tech"} />
						</GridItem>
						<GridItem xs={12} md={4} show={selectedRequestor === 2}>
							<TicketRequestorSelector
								customer={ticketCustomer}
								onChange={onSelectedTicketRequestorChange}
								requestor={selectedTicketRequestor}
							/>
						</GridItem>
						<GridItem
							xs={12}
							md={4}
							show={selectedRequestor === 2 && selectedTicketRequestor === null}
						>
							<Input
								labelText={"Requestor"}
								value={requestorText}
								onChange={onRequestorTextChange}
								inputProps={{
									ref: setInputRef,
									autoFocus: true,
								}}
								formControlProps={{
									error: showValidationErrors && !requestorTextIsValid,
								}}
								helperText={showValidationErrors ? requestorTextErrorMessage : ""}
							/>
						</GridItem>
						{/* <GridItem xs={12} show={selectedRequestor === 1}>
							<Checkbox
								labelText="Send Notification"
								checked={sendNotification}
								onClick={onSendNotificationChange}
								isError={!sendNotificationIsValid}
							/>
						</GridItem> */}
						<GridItem xs={12}>
							<Input
								labelText={"Comment"}
								value={description}
								onChange={onDescriptionChange}
								inputProps={{
									ref: setInputRef,
									multiline: true,
									autoFocus: true,
								}}
								formControlProps={{
									error: showValidationErrors && !dsecriptionIsValid,
								}}
								helperText={showValidationErrors ? descriptionErrorMessage : ""}
							/>
						</GridItem>
						<GridItem>
							<ButtonWithIcon
								icon={<RemoveIcon />}
								content="Cancel"
								size="sm"
								round
								color="warning"
								onClick={onCancelCommentAdd}
							/>
						</GridItem>
						<GridItem>
							<ButtonWithIcon
								icon={<AddIcon />}
								content="Add Comment"
								size="sm"
								round
								color="success"
								onClick={onSaveComment}
							/>
						</GridItem>
					</GridContainer>
				</QuickCard>
			</GridItem>
		</GridContainer>
	)
}

export default withStyles(styles)(TicketCommentAdd)

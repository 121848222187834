import React from "react"

import { withStyles } from "@material-ui/core/styles"
import get from "lodash/get"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { bindActionCreators } from "redux"

import {
	addMilestone,
	fetchMilestoneGroup,
	fetchMilestones,
	updateMilestoneGroup,
} from "@ncs/mortar/redux/services/machineMilestones"
import { APPLICATIONS } from "@ncs/mortar/util/constants"
import { meetsAppRestriction } from "@ncs/bricks/redux/selectors/auth"
import callApi from "@ncs/bricks/redux/services/callApi"
import { formatDate, formatPercentage } from "@ncs/bricks/util/formatters"

import {
	Button,
	ButtonWithIcon,
	Dialog,
	DynamicTable,
	GridContainer,
	GridItem,
	Input,
	InputAsDisplay,
	LoadingWrapper,
	NumericInput,
	QuickCard,
	Select,
} from "~/components"

import Add from "@material-ui/icons/Add"
import ChevronLeft from "@material-ui/icons/ChevronLeft"
import Edit from "@material-ui/icons/Edit"
import Info from "@material-ui/icons/Info"
import LocalShipping from "@material-ui/icons/LocalShipping"
import Remove from "@material-ui/icons/Remove"

const styles = {
	rightAlign: {
		textAlign: "right",
	},
}

const columns = [
	{
		Header: "Description",
		accessor: "description",
		id: "description",
		sortable: true,
		toggleable: false,
	},
	{
		Header: "Wash Count",
		accessor: (x) => (x.wash_count ? x.wash_count : "N/A"),
		id: "wash_count",
		sortable: true,
		toggleable: false,
	},
	{
		Header: "Age (Months)",
		accessor: (x) => (x.age_in_months ? x.age_in_months : "N/A"),
		id: "age_in_months",
		sortable: true,
		toggleable: false,
	},
	{
		Header: "Is Active",
		accessor: (x) => (x.is_active ? "True" : "False"),
		id: "is_active",
		sortable: true,
		toggleable: false,
	},
	{
		Header: "Created By",
		accessor: "created_by.name",
		sortable: true,
		hiddenByDefault: true,
	},
	{
		Header: "Created Date",
		accessor: "created_date",
		sortable: true,
		hiddenByDefault: true,
	},
	{
		Header: "Modified By",
		accessor: "modified_by.name",
		sortable: true,
		hiddenByDefault: true,
	},
	{
		Header: "Modified By",
		accessor: "modified_date",
		sortable: true,
		hiddenByDefault: true,
	},
]

class MachineMilestone extends React.PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			isLoading: true,
			isEditing: false,
			isSaving: false,
			milestoneGroup: null,
			milestones: [],
			newDescription: "",
			newDiscountPercent: 0,
			newIsActive: "true",
			ageInMonths: 0,
			washCount: 0,
			creatingMilestone: false,
			newWashCountPercentIncrease: 0,
			newWashCountPriceIncrease: 0,
			potentialDaysDown: 0,
		}
	}

	getMilestoneIdFromUrlParams = (props = this.props) => props.match.params.id

	componentDidUpdate(prevProps, prevState, snapshot) {
		let id = this.getMilestoneIdFromUrlParams()
		if (this.getMilestoneIdFromUrlParams(prevProps) !== id) {
			this.fetchMilestoneInfo()
		}
	}

	componentDidMount = () => {
		this.fetchMilestoneInfo()
	}

	fetchMilestoneInfo = () => {
		this.setState({ isLoading: true })

		let groupId = this.getMilestoneIdFromUrlParams()
		this.props.callApi(fetchMilestones(groupId)).then(({ payload: milestones }) => {
			this.setState({ milestones, isLoading: false })
		})
		this.props.callApi(fetchMilestoneGroup(groupId)).then(({ payload: milestoneGroup }) => {
			this.setState({ milestoneGroup, isLoading: false })
		})
	}

	handleBackToGroups = () => {
		this.props.history.push("/machine-milestone/groups")
	}

	viewDetailsButton = (milestone) => (
		<Button
			justIcon
			round
			simple
			className="edit"
			color="info"
			onClick={(e) => {
				e.stopPropagation()
				this.handleMilestoneSelect(milestone)
			}}
		>
			<Info />
		</Button>
	)

	handleDescriptionUpdate = (e) => {
		this.setState({ newDescription: e.target.value })
	}

	handleNewIsActiveUpdate = (newIsActive) => {
		this.setState({ newIsActive })
	}

	handleMilestoneSelect = (milestone) => {
		this.props.history.push(`/machine-milestone/milestone/${milestone.id}`)
	}

	handleDiscountPercentUpdate = (newDiscountPercent) => {
		this.setState({ newDiscountPercent })
	}

	handleAgeInMonthUpdate = (ageInMonths) => {
		this.setState({ ageInMonths })
	}

	handleWashCountUpdate = (washCount) => {
		this.setState({ washCount })
	}

	handlePercentIncrease = (newWashCountPercentIncrease) => {
		this.setState({ newWashCountPercentIncrease })
	}

	handlePriceIncrease = (newWashCountPriceIncrease) => {
		this.setState({ newWashCountPriceIncrease })
	}

	handleDaysDownUpdate = (potentialDaysDown) => {
		this.setState({ potentialDaysDown })
	}

	handleCreateMilestone = () => {
		this.setState({
			creatingMilestone: true,
			newDescription: "",
			newDiscountPercent: 0,
			newIsActive: "true",
			ageInMonths: 0,
			washCount: 0,
			newWashCountPercentIncrease: 0,
			newWashCountPriceIncrease: 0,
			potentialDaysDown: 0,
		})
	}

	handleCancelCreateMilestone = () => {
		this.setState({
			creatingMilestone: false,
			newDescription: "",
			newDiscountPercent: 0,
			newIsActive: "true",
			ageInMonths: 0,
			washCount: 0,
			newWashCountPercentIncrease: 0,
			newWashCountPriceIncrease: 0,
			potentialDaysDown: 0,
		})
	}

	handleConfirmCreateMilestone = () => {
		let {
			newDescription,
			newIsActive,
			newDiscountPercent,
			ageInMonths,
			washCount,
			milestoneGroup,
			newWashCountPercentIncrease,
			newWashCountPriceIncrease,
			potentialDaysDown,
		} = this.state
		let data = {
			age_in_months: ageInMonths === 0 ? null : ageInMonths,
			wash_count: washCount === 0 ? null : washCount,
			description: newDescription,
			is_active: newIsActive,
			discount_percent: newDiscountPercent ? (newDiscountPercent / 100).toFixed(4) : 0,
			milestone_group_id: milestoneGroup.id,
			wash_count_price_increase: newWashCountPriceIncrease,
			wash_count_percent_increase:
				newWashCountPercentIncrease ? (newWashCountPercentIncrease / 100).toFixed(2) : 0,
			potential_down_days: potentialDaysDown,
		}
		this.setState({ isSaving: true })
		this.props.callApi(addMilestone(data)).then(({ payload }) => {
			if (payload.error) {
				this.setState({ isSaving: false })
				console.error(payload.error)
			} else {
				this.setState({ isSaving: false })
				this.handleMilestoneSelect(payload)
			}
		})
	}

	handleEditMilestone = () => {
		this.setState({
			isEditing: true,
			newDescription: this.state.milestoneGroup.description,
			newDiscountPercent:
				this.state.milestoneGroup.discount_percent ?
					this.state.milestoneGroup.discount_percent * 100
				:	0,
			newIsActive: this.state.milestoneGroup.is_active ? "true" : "false",
		})
	}

	handleCancelEditing = () => {
		this.setState({
			isEditing: false,
			newDescription: "",
			newDiscountPercent: 0,
			newIsActive: "true",
		})
	}

	handleSaveMilestone = () => {
		let { milestoneGroup, newDescription, newIsActive, newDiscountPercent } = this.state
		let data = {
			vintage_id: get(milestoneGroup.vintage, "_id"),
			model_id: get(milestoneGroup.model, "_id"),
			description: newDescription,
			is_active: newIsActive,
			discount_percent: newDiscountPercent ? (newDiscountPercent / 100).toFixed(4) : 0,
		}
		this.setState({ isSaving: true })
		this.props.callApi(updateMilestoneGroup(milestoneGroup.id, data)).then((payload) => {
			if (payload.error) {
				this.setState({ isSaving: false })
				console.error(payload.error)
			} else {
				this.setState({ isSaving: false })
				this.handleCancelEditing()
				this.fetchMilestoneInfo()
			}
		})
	}

	render() {
		const {
			milestoneGroup,
			milestones,
			isLoading,
			isEditing,
			newDescription,
			newDiscountPercent,
			newWashCountPercentIncrease,
			newWashCountPriceIncrease,
			potentialDaysDown,
			newIsActive,
			washCount,
			ageInMonths,
			creatingMilestone,
			isSaving,
		} = this.state
		const { classes, isMilestoneAdmin } = this.props

		return (
			<React.Fragment>
				<LoadingWrapper isLoading={isLoading} />

				<GridItem xs={12}>
					<ButtonWithIcon
						content="Back to Groups"
						onClick={this.handleBackToGroups}
						color="white"
						size="sm"
						round
						icon={<ChevronLeft />}
					/>
				</GridItem>
				<QuickCard title={"Machine Milestone Groups"} icon={<LocalShipping />}>
					<GridContainer>
						<GridItem xs={12} sm={6} md={4}>
							{isEditing === false ?
								<InputAsDisplay
									labelText="Description"
									value={get(milestoneGroup, "description")}
								/>
							:	<Input
									labelText="Description"
									value={newDescription}
									onChange={this.handleDescriptionUpdate}
								/>
							}
						</GridItem>

						<GridItem xs={12} sm={6} md={4}>
							<InputAsDisplay
								labelText="Model"
								value={get(milestoneGroup, "model.model_name")}
							/>
						</GridItem>

						<GridItem xs={12} sm={6} md={4}>
							<InputAsDisplay
								labelText="Vintage"
								value={get(milestoneGroup, "vintage.name")}
							/>
						</GridItem>
						<GridItem xs={12} sm={6} md={4}>
							{isEditing === false ?
								<InputAsDisplay
									labelText="Is Active"
									value={get(milestoneGroup, "is_active") ? "True" : "False"}
								/>
							:	<Select
									value={newIsActive}
									onChange={this.handleNewIsActiveUpdate}
									labelText="Is Active"
									valueAccessor="id"
									textAccessor="description"
									options={[
										{ id: "true", description: "True" },
										{ id: "false", description: "False" },
									]}
								/>
							}
						</GridItem>
						<GridItem xs={12} sm={6} md={4}>
							{isEditing === false ?
								<InputAsDisplay
									labelText="Discount Percent"
									value={formatPercentage(
										get(milestoneGroup, "discount_percent"),
										2
									)}
								/>
							:	<NumericInput
									labelText="Discount Percent"
									value={newDiscountPercent}
									decimal_places={2}
									onChange={this.handleDiscountPercentUpdate}
								/>
							}
						</GridItem>
						<GridItem xs={12} sm={6} md={4}>
							<InputAsDisplay
								labelText="Created Date"
								value={formatDate(get(milestoneGroup, "created_date"))}
							/>
						</GridItem>

						{isEditing === false ?
							<GridItem
								xs={12}
								className={classes.rightAlign}
								show={isMilestoneAdmin()}
							>
								<ButtonWithIcon
									content="Edit Milestone Group"
									onClick={this.handleEditMilestone}
									icon={<Edit />}
									size="sm"
									color="warning"
									round
								/>
								<ButtonWithIcon
									content="Create Milestone"
									onClick={this.handleCreateMilestone}
									icon={<Add />}
									size="sm"
									color="success"
									round
								/>
							</GridItem>
						:	<GridItem
								xs={12}
								className={classes.rightAlign}
								show={isMilestoneAdmin()}
							>
								<ButtonWithIcon
									content="Cancel Editing"
									onClick={this.handleCancelEditing}
									icon={<Remove />}
									size="sm"
									color="warning"
									round
								/>
								<ButtonWithIcon
									content="Save Milestone"
									onClick={this.handleSaveMilestone}
									icon={<Add />}
									size="sm"
									color="success"
									round
								/>
							</GridItem>
						}
					</GridContainer>

					<DynamicTable // TODO: make props static
						loading={isLoading}
						data={milestones}
						useClientSideDataProcessing={false}
						useClientSidePaging={false}
						style={styles.center}
						columns={[
							...columns,
							{
								Header: "",
								accessor: this.viewDetailsButton,
								id: "actions",
								sortable: false,
								toggleable: false,
								maxWidth: 50,
							},
						]}
						onRowClick={this.handleMilestoneSelect}
					/>
				</QuickCard>

				<Dialog
					show={creatingMilestone}
					title={"Create Milestone Group"}
					onCancel={this.handleCancelCreateMilestone}
					onConfirm={this.handleConfirmCreateMilestone}
					isSaving={isSaving}
				>
					{ageInMonths && ageInMonths > 0 ?
						<InputAsDisplay value={"0"} labelText={"Wash Count"} />
					:	<NumericInput
							labelText="Wash Count"
							value={washCount}
							onChange={this.handleWashCountUpdate}
						/>
					}
					{washCount && washCount > 0 ?
						<InputAsDisplay value={"0"} labelText={"Age (In Months)"} />
					:	<NumericInput
							labelText="Age (In Months)"
							value={ageInMonths}
							onChange={this.handleAgeInMonthUpdate}
						/>
					}
					<Input
						labelText="Description"
						value={newDescription}
						onChange={this.handleDescriptionUpdate}
					/>
					<Select
						value={newIsActive}
						onChange={this.handleNewIsActiveUpdate}
						labelText="Is Active"
						valueAccessor="id"
						textAccessor="description"
						options={[
							{ id: "true", description: "True" },
							{ id: "false", description: "False" },
						]}
					/>
					<NumericInput
						labelText="Discount Percent"
						value={newDiscountPercent}
						decimal_places={2}
						onChange={this.handleDiscountPercentUpdate}
					/>
					<NumericInput
						labelText="Wash Count Percent Increase"
						value={newWashCountPercentIncrease}
						decimal_places={0}
						onChange={this.handlePercentIncrease}
					/>
					<NumericInput
						labelText="Wash Count Price Increase"
						value={newWashCountPriceIncrease}
						decimal_places={2}
						onChange={this.handlePriceIncrease}
					/>
					<NumericInput
						labelText="Potential Days Down"
						value={potentialDaysDown}
						decimal_places={0}
						onChange={this.handleDaysDownUpdate}
					/>
				</Dialog>
			</React.Fragment>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		isMilestoneAdmin: () => {
			return meetsAppRestriction(state.auth, APPLICATIONS.MachineMilestoneAdmin)
		},
	}
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ callApi }, dispatch)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(withStyles(styles)(MachineMilestone)))

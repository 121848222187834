import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import callApi from "@ncs/bricks/redux/services/callApi"
import { loadLookups, LOOKUPS } from "../../redux/services/lookups"
import { TICKET_GROUPS } from "../../util/constants"

// custom components
import { Select } from "@ncs/bricks/components"

class TicketGroupSelector extends React.PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			groups: [],
			group: null,
		}
	}

	componentDidMount() {
		this.props.loadLookups([LOOKUPS.TicketGroups], this.filterTicketGroup)
	}
	filterTicketGroup = () => {
		if (!this.props.isServiceUser) {
			let groups = this.props.groups.filter((x) => x.id !== TICKET_GROUPS.TECH_SUPPORT)
			this.setState({
				groups,
			})
		} else {
			let groups = this.props.groups
			this.setState({
				groups,
			})
		}
	}

	render() {
		const { displayEmpty, ...rest } = this.props
		return (
			<Select
				id="ticketGroups"
				labelText="Department"
				value={this.props.group || -1}
				onChange={this.props.onChange}
				options={[
					{
						id: "-1",
						description: "Select a department...",
						disabled: true,
					},
					...this.state.groups,
				]}
				valueAccessor="id"
				textAccessor={(x) => x.description}
				{...rest}
			/>
		)
	}
}

TicketGroupSelector.defaultProps = {
	displayEmpty: true,
}

TicketGroupSelector.propTypes = {
	group: PropTypes.object,
	displayEmpty: PropTypes.bool,
}

const mapStateToProps = (state) => {
	return {
		groups: state.lookups.ticketGroups,
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			callApi,
			loadLookups,
		},
		dispatch
	)

export default connect(mapStateToProps, mapDispatchToProps)(TicketGroupSelector)

import { FC } from "react"

import { VacuumEventHistory } from "@ncs/ncs-api"
import { DateFormat, formatDateTime } from "@ncs/ts-utils"
import { DescriptionList, Divider, Modal, Paragraph } from "@ncs/web-legos"

export interface EventDetailModalProps {
	isOpen: boolean
	onClose: () => void
	event: VacuumEventHistory | null
}

export const EventDetailModal: FC<EventDetailModalProps> = ({ isOpen, onClose, event }) => {
	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<DescriptionList
				variant="code"
				items={[
					[
						"Start",
						event?.startedOn ?
							formatDateTime(event.startedOn, DateFormat.DateTimeSeconds)
						:	null,
					],
					[
						"End",
						event?.endedOn ?
							formatDateTime(event.endedOn, DateFormat.DateTimeSeconds)
						:	null,
					],
					["Code", event?.eventCode],
					["Type", event?.eventType],
					["Message", event?.message],
				]}
			/>

			<Divider />
			<Paragraph>Params:</Paragraph>

			<DescriptionList variant="code" items={Object.entries(event?.params ?? {})} />
		</Modal>
	)
}

import { FC, useMemo, useState } from "react"

import { Cell, Column } from "react-table"

import {
	defaultPartLevelQueryParams,
	InventoryPart,
	PartLevel,
	PartLevelQueryParams,
	useInventoryPartLevels,
} from "@ncs/ncs-api"
import { extractNumber, formatCurrency, formatNumber } from "@ncs/ts-utils"
import { EmptyValueDash, encodeUrlState, Link, SearchQueryFilter, Table } from "@ncs/web-legos"

import { LocationPartsTabUrlState } from "~/views/Inventory/LocationManagement/components/location-parts-tab"
import { LocationsUrlState } from "~/views/Inventory/LocationManagement/Locations"

export interface PartLevelsTabProps {
	part: InventoryPart
}

const PartLevelsTab: FC<PartLevelsTabProps> = ({ part }) => {
	const [params, setParams] = useState<PartLevelQueryParams>({
		...defaultPartLevelQueryParams,
	})

	const partLevelsQuery = useInventoryPartLevels(part.id, { params })

	const columns = useMemo(
		(): Column<PartLevel>[] => [
			{
				Header: "Location",
				id: "bin_Location_Description",
				accessor: ({ bin }) => bin.location.id,
				Cell: ({ row: { original } }: Cell<PartLevel>) => {
					const text = `(${original.bin.location.locationCode}) ${original.bin.location.description}`

					return (
						<Link
							to={`/inventory/locations${encodeUrlState<
								LocationsUrlState & LocationPartsTabUrlState
							>({
								urlLocationId: original.bin.location.id,
								partsTabPart: part.id,
							})}`}
							newTab
							icon="external-link"
						>
							{text}
						</Link>
					)
				},
			},
			{
				Header: "Bin code",
				id: "bin_Code",
				accessor: ({ bin }) => bin.code,
			},
			{
				Header: "Bin qty",
				id: "qty",
				accessor: "quantity",
				Cell: (cell: Cell<PartLevel>) => {
					return formatNumber(extractNumber(cell.row.original.quantity))
				},
			},
			{
				Header: "Location qty",
				id: "locationQuantity",
				accessor: ({ locationQuantity }) => locationQuantity,
				Cell: ({ row: { original } }: Cell<PartLevel>) =>
					original.locationQuantity != null ?
						formatNumber(original.locationQuantity)
					:	<EmptyValueDash />,
			},
			{
				Header: "Total value",
				accessor: "totalCost",
				hiddenByDefault: true,
				Cell: ({ row: { original } }: Cell<PartLevel>) =>
					original.totalCost != null ?
						formatCurrency(original.totalCost)
					:	<EmptyValueDash />,
			},
		],
		[part.id]
	)

	return (
		<Table
			query={partLevelsQuery}
			columns={columns}
			queryParamState={params}
			setQueryParamState={setParams}
			pinnedQueryFilters={[SearchQueryFilter]}
		/>
	)
}

export default PartLevelsTab

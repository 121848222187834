import { FC, useState } from "react"

import { makeApiErrorMessage, useApproveCreditMemo, useRejectCreditMemo } from "@ncs/ncs-api"
import {
	ExtendableModalProps,
	Modal,
	Paragraph,
	Textarea,
	useIsSaving,
	useToast,
} from "@ncs/web-legos"

export interface CreditMemoDecisionProps extends ExtendableModalProps {
	invoiceId: string
	creditMemoId: string
	isApproved: boolean
}

export const CreditMemoDecisionModal: FC<CreditMemoDecisionProps> = ({
	invoiceId,
	creditMemoId,
	isApproved,
	...rest
}) => {
	const { makeSuccessToast } = useToast()
	const [comment, setComment] = useState<string | null>(null)
	const { isSaving, setSaving, endSaving } = useIsSaving()
	const [errorText, setErrorText] = useState<string | null>(null)

	const approveMemo = useApproveCreditMemo(invoiceId)
	const rejectMemo = useRejectCreditMemo(invoiceId)

	const handleSubmit = async () => {
		try {
			setSaving()
			if (isApproved) {
				await approveMemo({
					id: creditMemoId,
				})
				makeSuccessToast("Credit memo approved")
				rest.onClose()
			} else {
				await rejectMemo({
					id: creditMemoId,
					comment,
				})
				makeSuccessToast("Credit memo rejected")
				rest.onClose()
			}
		} catch (e) {
			endSaving()
			setErrorText(makeApiErrorMessage(e))
		}
	}

	return (
		<Modal
			{...rest}
			title="Submit Credit Memo Decision"
			errorText={errorText}
			rightButtons={{
				buttonText: "Submit",
				isLoading: isSaving(),
				onClick: handleSubmit,
			}}
		>
			{isApproved ?
				<Paragraph>Confirm: Approve this credit memo?</Paragraph>
			:	<>
					<Paragraph mb={1}>
						Confirm: Reject this credit memo? Enter any additional comments below.
					</Paragraph>
					<Textarea value={comment} onChange={setComment} label="Comments (optional)" />
				</>
			}
		</Modal>
	)
}

import { FC } from "react"

import { TwoDigitMachineCode } from "@ncs/ncs-api"
import { formatDateTime } from "@ncs/ts-utils"
import { DescriptionList, ExtendableModalProps, Modal } from "@ncs/web-legos"

export interface MachineStateDetailModalProps extends ExtendableModalProps {
	code: TwoDigitMachineCode | null
}

export const MachineStateDetailModal: FC<MachineStateDetailModalProps> = ({
	isOpen,
	onClose,
	code,
}) => {
	return (
		<Modal isOpen={isOpen} onClose={onClose} title="State Code Details" maxWidth="md">
			{code && (
				<DescriptionList
					variant="code"
					items={[
						["Code", code.twoDigitCode?.machineCode],
						["Start", code.eventStartOn ? formatDateTime(code.eventStartOn) : null],
						["End", code.eventEndOn ? formatDateTime(code.eventEndOn) : null],
						["Description", code.twoDigitCode?.description],
						["Definition", code.twoDigitCode?.definition],
						["Potential Cause", code.twoDigitCode?.potentialCause],
					]}
				/>
			)}
		</Modal>
	)
}

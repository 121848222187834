import { ReactElement } from "react"

import { Control, Controller, FieldValues, Path } from "react-hook-form"

import { AnimatedEntrance, AnimatedEntranceProps } from "../transitions"
import { ErrorText } from "../typography"

export interface HookFormErrorTextProps<TFieldValues extends FieldValues>
	extends Omit<AnimatedEntranceProps, "show"> {
	name: Path<TFieldValues>
	control: Control<TFieldValues>
}

/**
 * Takes a field name and form control and renders the error text when necessary.
 * Mostly making this to handle Hook Form's type weirdness when a field is an array
 * of something.
 */
export const HookFormErrorText = <TFieldValues extends FieldValues>({
	name,
	control,
	...rest
}: HookFormErrorTextProps<TFieldValues>): ReactElement => {
	return (
		<Controller
			name={name}
			control={control}
			render={({ formState, fieldState: { error } }) => {
				const { submitCount } = formState

				return (
					<AnimatedEntrance
						direction="down"
						{...rest}
						show={submitCount > 0 && !!error?.message}
					>
						<ErrorText>{error?.message}</ErrorText>
					</AnimatedEntrance>
				)
			}}
		/>
	)
}

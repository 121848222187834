import { ReactElement, useMemo } from "react"

import { InventoryLocation, useInventoryLocations } from "@ncs/ncs-api"

import { ReactStateDispatch } from "../../util"
import { WarehouseMultiSelect } from "../multi-selects"

interface MultiWarehouseParamState {
	warehouses: (string | number)[] | null
}

export interface MultiWarehouseQueryFilterProps<QueryParamState extends MultiWarehouseParamState> {
	queryParamState: QueryParamState
	setQueryParamState: ReactStateDispatch<QueryParamState>
}

export const MultiWarehouseQueryFilter = <QueryParamState extends MultiWarehouseParamState>({
	queryParamState,
	setQueryParamState,
}: MultiWarehouseQueryFilterProps<QueryParamState>): ReactElement => {
	const [locations] = useInventoryLocations()

	const handleChange = (newList: InventoryLocation[]) => {
		setQueryParamState((prev) => {
			const newState: QueryParamState = {
				...prev,
				warehouses: newList.length ? newList.map((l) => l.id) : null,
			}

			return newState
		})
	}

	const values = useMemo(() => {
		const result: InventoryLocation[] = []

		queryParamState.warehouses?.forEach((paramLocationId) => {
			const match = locations?.find((location) => location.id === paramLocationId)

			if (match) {
				result.push(match)
			}
		})

		return result
	}, [queryParamState.warehouses, locations])

	return <WarehouseMultiSelect values={values} onChange={handleChange} />
}

import React from "react"
import PropTypes from "prop-types"
import { withCallApi } from "@ncs/bricks/components"
import { Search, Remove } from "@material-ui/icons"
import {
	Dialog,
	GridContainer,
	DynamicTable,
	SearchFilter,
	GridItem,
	ButtonWithIcon,
} from "@ncs/bricks/components"

import {
	RegionFilter,
	TerritoryFilter,
	EnterpriseFilter,
	OrganizationFilter,
} from "../../components"
import { listCustomers } from "../../redux/services/customers"

const columns = [
	{
		Header: "Customer #",
		accessor: "customer_number",
		sortable: true,
		toggleable: false,
	},
	{
		Header: "Customer Name",
		accessor: "name",
		sortable: true,
		toggleable: false,
	},
	{
		Header: "Address",
		accessor: "address_2",
		sortable: true,
		toggleable: true,
	},
	{
		Header: "City",
		accessor: "city",
		sortable: true,
		toggleable: true,
	},
	{
		Header: "State",
		accessor: "state",
		sortable: true,
		toggleable: true,
	},
	{
		Header: "Postal Code",
		accessor: "postalcode",
		sortable: true,
		toggleable: true,
	},
	{
		Header: "Is Active",
		accessor: (x) => (x.is_fst_active === true ? "True" : "False"),
		id: "is_fst_active",
		sortable: true,
		hiddenByDefault: true,
	},
]

const reduxKey = "detailedCustomerSelectorReduxKey"

class DetailedCustomerSelector extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			show: false,
		}
	}
	listCustomers = (query) =>
		listCustomers({
			...query,
			service: this.props.limitToServiceableCustomers ? true : undefined,
			account_active: this.props.limitToActiveCustomers ? true : undefined,
			is_service_customer: this.props.limitToNonServiceCustomer ? false : undefined,
		})
	handleRowClick = (customer) => {
		this.setState({ show: false })
		this.props.onCustomerSelected(customer)
		return
	}
	handleClick = () => {
		this.setState({ show: true })
	}
	handleCancel = () => {
		this.setState({
			show: false,
		})
	}
	render = () => (
		<React.Fragment>
			<GridContainer>
				<GridItem show={!this.state.show}>
					<ButtonWithIcon
						icon={<Search />}
						onClick={this.handleClick}
						round
						justIcon
						color="primary"
						size="sm"
					/>
				</GridItem>
			</GridContainer>
			<Dialog
				warning="true"
				show={this.state.show}
				fullWidth={true}
				maxWidth={"lg"}
				title="Customer Search"
				confirmBtnIcon={<Remove />}
				confirmBtnText="Cancel"
				confirmBtnColor={"danger"}
				onConfirm={this.handleCancel}
			>
				{true && (
					<GridContainer>
						<GridItem xs={12}>
							<GridContainer>
								<GridItem xs={12} sm={4}>
									<RegionFilter reduxKey={reduxKey} />
								</GridItem>
								<GridItem xs={12} sm={4}>
									<TerritoryFilter reduxKey={reduxKey} />
								</GridItem>
								<GridItem xs={12} sm={4}>
									<EnterpriseFilter reduxKey={reduxKey} />
								</GridItem>
								<GridItem xs={12} sm={4}>
									<OrganizationFilter reduxKey={reduxKey} />
								</GridItem>
								<GridItem xs={12} sm={4}>
									<SearchFilter reduxKey={reduxKey} />
								</GridItem>
							</GridContainer>
							<DynamicTable
								reduxKey={reduxKey}
								fetchDataAction={this.listCustomers}
								columns={columns}
								showResetFiltersButton={true}
								defaultPageSize={25}
								onRowClick={this.handleRowClick}
							/>
						</GridItem>
					</GridContainer>
				)}
			</Dialog>
		</React.Fragment>
	)
}

DetailedCustomerSelector.defaultProps = {
	limitToServiceableCustomers: true,
	limitToActiveCustomers: true,
	limitToNonServiceCustomer: false,
	getQueryParameters: () => ({}),
}

DetailedCustomerSelector.propTypes = {
	callApi: PropTypes.func.isRequired,
	onCustomerSelected: PropTypes.func.isRequired,
	title: PropTypes.string,
	limitToServiceableCustomers: PropTypes.bool,
	limitToActiveCustomers: PropTypes.bool,
	getQueryParameters: PropTypes.func,
}

export default withCallApi(DetailedCustomerSelector)

import { FC, useState } from "react"

import { useProductCatalog } from "@ncs/ncs-api"
import {
	AnimatedEntrance,
	Button,
	Callout,
	Card,
	Divider,
	Heading,
	Icon,
	Label,
	Paragraph,
	ProductCatalogSelector,
	RecentItems,
	Tabs,
	useUrlState,
} from "@ncs/web-legos"

import { CatalogPartsTab } from "./CatalogPartsTab"
import { CatalogUsersTab } from "./CatalogUsersTab"
import { EditCatalogModal } from "./EditCatalogModal"

export enum CatalogTabs {
	CatalogProducts = "Catalog Products",
	AssignedUsers = "Assigned Users",
}

export type CatalogsUrlState = {
	catalogId: string | null
	tab: CatalogTabs
}

const Catalogs: FC = () => {
	const [{ catalogId, tab }, { updateUrlValue, setUrlState }] = useUrlState<CatalogsUrlState>({
		catalogId: null,
		tab: CatalogTabs.CatalogProducts,
	})
	const [showEditModal, setShowEditModal] = useState(false)
	const [showNewModal, setShowNewModal] = useState(false)
	const [catalog] = useProductCatalog(catalogId)

	return (
		<Card
			heading="Customer Catalogs"
			headingIcon="book"
			renderRight={() => {
				return (
					<Button icon="plus-circle" onClick={() => setShowNewModal(true)}>
						Create new customer catalog
					</Button>
				)
			}}
		>
			<Paragraph mb={2}>
				Create a custom list of parts and chemicals that a customer will see in their
				search results in the Customer Portal. Other products that are not in their catalog
				will be hidden.
			</Paragraph>

			<ProductCatalogSelector
				value={catalog ?? null}
				onChange={(selection) =>
					updateUrlValue("catalogId", selection?.id.toString() ?? null)
				}
				label="Select catalog"
				maxWidth={30}
				initialId={catalogId}
			/>
			<RecentItems
				item={catalog}
				itemLabelAccessor="description"
				onSelect={(selection) => updateUrlValue("catalogId", selection.id.toString())}
				compareFn={(a, b) => a.id === b.id}
			/>

			<Divider fullCardBleed my={3} />

			{catalog ?
				<AnimatedEntrance show>
					<Callout variant="info" pr={4}>
						<Label>Catalog name</Label>
						<Heading variant="h2" mb={2}>
							{catalog.description}
						</Heading>
						<Button icon="pencil" onClick={() => setShowEditModal(true)}>
							Edit
						</Button>
					</Callout>

					<Tabs
						currentTab={tab}
						onChange={(newTab) => updateUrlValue("tab", newTab)}
						panels={[
							{
								navLabel: CatalogTabs.CatalogProducts,
								component: <CatalogPartsTab catalog={catalog} />,
								navIcon: <Icon icon="tools" />,
							},
							{
								navLabel: CatalogTabs.AssignedUsers,
								component: <CatalogUsersTab catalog={catalog} />,
								navIcon: <Icon icon="users" />,
							},
						]}
					/>
				</AnimatedEntrance>
			:	<Heading textAlign="center" icon="search" py={5} opacity={0.25}>
					Select a catalog above to begin...
				</Heading>
			}

			<EditCatalogModal
				isOpen={showEditModal || showNewModal}
				onClose={() => {
					setShowEditModal(false)
					setShowNewModal(false)
				}}
				catalog={catalog ?? null}
				isNew={showNewModal}
				setUrlState={setUrlState}
			/>
		</Card>
	)
}

export default Catalogs

import React from "react"

import NumberFormField from "./NumberFormField"

class IntegerFormField extends React.Component {
	render = () => <NumberFormField {...this.props} />
}

IntegerFormField.defaultProps = {
	thousandSeparator: true,
	decimalScale: 0,
	displayType: "input",
}

IntegerFormField.propTypes = {
	...NumberFormField.propTypes,
}

export default IntegerFormField

import React from "react"
import { connect } from "react-redux"
import { bindActionCreators, compose } from "redux"
import { withRouter } from "react-router-dom"
import callApi from "@ncs/bricks/redux/services/callApi"
import { getTickets } from "@ncs/mortar/redux/services/ticketing"
import { loadLookups } from "@ncs/mortar/redux/services/lookups"
import { fetchData, resetFilters, updateFilters } from "@ncs/bricks/redux/services/dynamicTables"
import {
	APPLICATIONS,
	TICKET_GROUPS,
	TICKET_STATUSES,
	TICKETING_FILTERS,
} from "@ncs/mortar/util/constants"

import { getName, meetsAppRestriction, userId } from "@ncs/bricks/redux/selectors/auth"
import qs from "query-string"
import moment from "moment"

import {
	ButtonWithIcon,
	CustomerFilter,
	DynamicTable,
	EndDateFilter,
	EstimatedCompletionDateFilter,
	GridContainer,
	GridItem,
	QuickCard,
	SearchFilter,
	StartDateFilter,
	TicketClosedEndFilter,
	TicketCloseStartFilter,
	TicketGroupFilter,
	TicketPriorityFilter,
	TicketStatusFilter,
	TicketSubtypeFilter,
	TicketTypeFilter,
	UserSelector,
	withDynamicTableFilter,
} from "~/components"

import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber"
import AddIcon from "@material-ui/icons/Add"
import ListIcon from "@material-ui/icons/List"
import { formatDate } from "@ncs/bricks/util/formatters"

const styles = {
	center: {
		textAlign: "center",
	},
}

const FILTER_FIELDS = [
	...TICKETING_FILTERS,
	"status",
	"priority",
	"start_date",
	"group",
	"created",
	"assigned",
	"ticket_close_start",
	"est_comp_date",
]
export const reduxKey = "ticketManagment"

class TicketManagement extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			pastDue: false,
			quickFiltersSet: false,
		}
	}

	setQuickFilters = () => {
		this.props.resetFilters(false)
		let filters = {}
		const params = qs.parse(this.props.location.search)
		let time = moment()
		switch (params["quick-filter"]) {
			case "new-today":
				filters["start_date"] = time.startOf("day")
				break
			case "open":
				filters["status"] = TICKET_STATUSES.OPEN
				break
			case "closed-today":
				filters["ticket_close_start"] = moment().startOf("day")
				filters["status"] = TICKET_STATUSES.CLOSED
				break
			case "past-due":
				filters["est_comp_date"] = moment().startOf("day")
				filters["status"] = null
				break
			case "assigned-to-me":
				filters["assigned"] = {
					_id: this.props.userId(),
					name: this.props.getName(),
				}
				filters["status"] = TICKET_STATUSES.OPEN
				break
			case "my-open-tickets":
				// this.setState({
				// 	selectedCreatedBy: {
				// 		_id: this.props.userId(),
				// 		name: this.props.getName(),
				// 	},
				// })
				filters["created"] = {
					_id: this.props.userId(),
					name: this.props.getName(),
				}
				filters["status"] = TICKET_STATUSES.OPEN
				break
			default:
				filters["status"] = TICKET_STATUSES.OPEN
				break
		}
		switch (params["department"]) {
			case "finance":
				filters["group"] = TICKET_GROUPS.FINANCE
				break
			case "it":
				filters["group"] = TICKET_GROUPS.IT
				break
			case "tech-support":
				filters["group"] = TICKET_GROUPS.TECH_SUPPORT
				break
			default:
		}

		let isAdmin = this.props.isTicketingAdmin()
		let isServiceUser = this.props.isServiceUser()
		if (!isAdmin && isServiceUser) {
			filters["group"] = TICKET_GROUPS.TECH_SUPPORT
			filters["status"] = null
		}

		this.props.updateFilters(filters)
		this.setState({ quickFiltersSet: true })

		// cancel DynamicTable's initial fetch, updateFilters handles it
		return { cancel: true }
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevState.params !== this.state.params) {
			this.props.fetchData()
		}
		if (prevState.selectedTicketType !== this.state.selectedTicketType) {
			this.props.fetchData()
		}
	}
	handleItemSelected = (item) => {
		this.props.history.push(`/ticketing/detail/${item.id}`)
	}
	handleCreateTicket = () => {
		this.props.history.push(`/ticketing/create/`)
	}

	handleAssignedToSelected = (user) => {
		this.props.setAssignedTo(user)
		this.props.fetchData()
	}

	handleCreatedBySelected = (user) => {
		this.props.setCreatedBy(user)
		this.props.fetchData()
	}

	getTickets = (params) =>
		getTickets({
			...params,
			past_due: this.state.pastDue,
			is_service: this.props.isTicketingAdmin() ? null : !!this.props.isServiceUser(),
		})

	adminColumns = [
		{
			Header: "Ticket #",
			accessor: "id",
			id: "id",
			sortable: true,
			toggleable: false,
			headerStyle: styles.center,
		},
		{
			Header: "Title",
			accessor: "description",
			id: "description",
			sortable: true,
			toggleable: true,
			headerStyle: styles.center,
		},
		{
			Header: "Created Date",
			accessor: (x) => formatDate(x.created_date),
			id: "created_date",
			sortable: true,
			toggleable: true,
			headerStyle: styles.center,
		},
		{
			Header: "Closed Date",
			accessor: (x) => formatDate(x.closed_date),
			id: "closed_date",
			sortable: true,
			toggleable: true,
			hiddenByDefault: true,
			headerStyle: styles.center,
		},
		{
			Header: "Estimated Completion Date",
			accessor: (x) => formatDate(x.estimated_completion_date),
			id: "estimated_completion_date",
			sortable: true,
			toggleable: true,
			hiddenByDefault: false,
			headerStyle: styles.center,
		},
		{
			Header: "Type",
			accessor: (x) => x.ticket_type.description,
			id: "ticket_type",
			sortable: true,
			toggleable: true,
			headerStyle: styles.center,
		},
		{
			Header: "Priority",
			accessor: (x) => (x.priority === null ? "" : x.priority.description),
			id: "priority",
			sortable: true,
			toggleable: true,
			headerStyle: styles.center,
		},
		{
			Header: "Group",
			accessor: "ticket_type.group.description",
			id: "ticket_type__group__description",
			sortable: true,
			toggleable: true,
			hiddenByDefault: true,
			headerStyle: styles.center,
		},
		{
			Header: "Created By",
			accessor: (x) => x.created_by.name,
			id: "createdBy",
			sortable: true,
			toggleable: true,
			headerStyle: styles.center,
		},
		{
			Header: "Closed By",
			accessor: (x) => (x.closed_by ? x.closed_by.name : ""),
			id: "closed_by",
			sortable: true,
			toggleable: true,
			hiddenByDefault: true,
			headerStyle: styles.center,
		},
		{
			Header: "Assigned To",
			accessor: (x) => (x.assigned_to ? x.assigned_to.name : ""),
			id: "assignedTo",
			sortable: true,
			toggleable: true,
			headerStyle: styles.center,
		},
		{
			Header: "Status",
			accessor: (x) => x.status.description,
			id: "status",
			sortable: true,
			toggleable: true,
			headerStyle: styles.center,
		},
		{
			Header: "Urgent",
			accessor: (x) => (x.is_urgent ? "Yes" : "No"),
			id: "is_urgent",
			sortable: true,
			toggleable: true,
			headerStyle: styles.center,
		},
		{
			Header: "Nuisance",
			accessor: (x) => (x.is_nuisance ? "Yes" : "No"),
			id: "is_nuisance",
			sortable: true,
			toggleable: true,
			headerStyle: styles.center,
		},
		{
			Header: "Tech",
			accessor: (x) => (x.tech_id ? x.tech.name : "None"),
			id: "tech",
			sortable: true,
			toggleable: true,
			headerStyle: styles.center,
		},
		{
			Header: "Customer",
			accessor: (x) =>
				x.customer_id ? `${x.customer.name} (${x.customer.customer_number})` : "None",
			id: "customer",
			sortable: true,
			toggleable: true,
			headerStyle: styles.center,
		},
		{
			Header: "Customer Equipment",
			accessor: (x) =>
				x.customer_equipment_id ?
					`${x.customer_equipment.model} (${x.customer_equipment.serial_number})`
				:	"None",
			id: "customer_equipment",
			sortable: true,
			toggleable: true,
			headerStyle: styles.center,
		},
	]

	userColumns = [
		{
			Header: "Ticket #",
			accessor: "id",
			id: "id",
			sortable: true,
			toggleable: false,
			headerStyle: styles.center,
		},
		{
			Header: "Title",
			accessor: "description",
			id: "description",
			sortable: true,
			toggleable: true,
			headerStyle: styles.center,
		},
		{
			Header: "Created Date",
			accessor: (x) => formatDate(x.created_date),
			id: "created_date",
			sortable: true,
			toggleable: true,
			headerStyle: styles.center,
		},
		{
			Header: "Closed Date",
			accessor: (x) => formatDate(x.closed_date),
			id: "closed_date",
			sortable: true,
			toggleable: true,
			hiddenByDefault: true,
			headerStyle: styles.center,
		},
		{
			Header: "Estimated Completion Date",
			accessor: (x) => formatDate(x.estimated_completion_date),
			id: "estimated_completion_date",
			sortable: true,
			toggleable: true,
			hiddenByDefault: false,
			headerStyle: styles.center,
		},
		{
			Header: "Type",
			accessor: (x) => x.ticket_type.description,
			id: "ticket_type",
			sortable: true,
			toggleable: true,
			headerStyle: styles.center,
		},
		{
			Header: "Priority",
			accessor: (x) => (x.priority === null ? "" : x.priority.description),
			id: "priority",
			sortable: true,
			toggleable: true,
			headerStyle: styles.center,
		},
		{
			Header: "Group",
			accessor: "ticket_type.group.description",
			id: "ticket_type__group__description",
			sortable: true,
			toggleable: true,
			hiddenByDefault: true,
			headerStyle: styles.center,
		},
		{
			Header: "Created By",
			accessor: (x) => x.created_by.name,
			id: "createdBy",
			sortable: true,
			toggleable: true,
			headerStyle: styles.center,
		},
		{
			Header: "Closed By",
			accessor: (x) => (x.closed_by ? x.closed_by.name : ""),
			id: "closed_by",
			sortable: true,
			toggleable: true,
			hiddenByDefault: true,
			headerStyle: styles.center,
		},
		{
			Header: "Assigned To",
			accessor: (x) => (x.assigned_to ? x.assigned_to.name : ""),
			id: "assignedTo",
			sortable: true,
			toggleable: true,
			headerStyle: styles.center,
		},
		{
			Header: "Status",
			accessor: (x) => x.status.description,
			id: "status",
			sortable: true,
			toggleable: true,
			headerStyle: styles.center,
		},
		{
			Header: "Urgent",
			accessor: (x) => (x.is_urgent ? "Yes" : "No"),
			id: "is_urgent",
			sortable: true,
			toggleable: true,
			headerStyle: styles.center,
		},
		{
			Header: "Tech",
			accessor: (x) => (x.tech_id ? x.tech.name : "None"),
			id: "tech",
			sortable: true,
			toggleable: true,
			headerStyle: styles.center,
		},
		{
			Header: "Customer",
			accessor: (x) =>
				x.customer_id ? `${x.customer.name} (${x.customer.customer_number})` : "None",
			id: "customer",
			sortable: true,
			toggleable: true,
			headerStyle: styles.center,
		},
		{
			Header: "Customer Equipment",
			accessor: (x) =>
				x.customer_equipment_id ?
					`${x.customer_equipment.model} (${x.customer_equipment.serial_number})`
				:	"None",
			id: "customer_equipment",
			sortable: true,
			toggleable: true,
			headerStyle: styles.center,
		},
	]

	render() {
		const isAdmin = this.props.isTicketingAdmin()
		const isServiceUser = this.props.isServiceUser()
		return (
			<React.Fragment>
				<QuickCard title={"Ticket Management"} icon={<ConfirmationNumberIcon />}>
					<GridContainer>
						<GridItem xs={12} md={6}>
							<TicketGroupFilter reduxKey={reduxKey} isServiceUser={isServiceUser} />
						</GridItem>
						<GridItem xs={12} md={6}>
							<TicketPriorityFilter reduxKey={reduxKey} />
						</GridItem>
						<GridItem xs={12} md={6}>
							<TicketTypeFilter reduxKey={reduxKey} />
						</GridItem>
						<GridItem xs={12} md={6}>
							<UserSelector
								labelText="Created By"
								selectedSuggestion={this.props.selectedCreatedBy}
								onUserSelected={this.handleCreatedBySelected}
							/>
						</GridItem>
						<GridItem xs={12} md={6}>
							<TicketSubtypeFilter reduxKey={reduxKey} />
						</GridItem>
						<GridItem xs={12} md={6}>
							<UserSelector
								labelText="Assigned To"
								selectedSuggestion={this.props.selectedAssignedTo}
								onUserSelected={this.handleAssignedToSelected}
							/>
						</GridItem>
						<GridItem xs={12} md={6}>
							<TicketStatusFilter reduxKey={reduxKey} />
						</GridItem>
						<GridItem xs={12} md={6}>
							<SearchFilter reduxKeys={[reduxKey]} />
						</GridItem>
						<GridItem xs={12} md={6}>
							<CustomerFilter reduxKey={reduxKey} />
						</GridItem>
						<GridItem xs={12} md={3}>
							<StartDateFilter
								reduxKey={reduxKey}
								labelText={"Created Date Start"}
							/>
						</GridItem>
						<GridItem xs={12} md={3}>
							<EndDateFilter reduxKey={reduxKey} labelText={"Created Date End"} />
						</GridItem>
						<GridItem xs={12} md={3}>
							<TicketCloseStartFilter reduxKey={reduxKey} />
						</GridItem>
						<GridItem xs={12} md={3}>
							<TicketClosedEndFilter reduxKey={reduxKey} />
						</GridItem>
						<GridItem xs={12} md={3}>
							<EstimatedCompletionDateFilter reduxKey={reduxKey} />
						</GridItem>
					</GridContainer>
				</QuickCard>
				<QuickCard title="Tickets" icon={<ListIcon />}>
					<ButtonWithIcon
						icon={<AddIcon />}
						content="Create Ticket"
						size="sm"
						round
						color="success"
						onClick={this.handleCreateTicket}
					/>
					<DynamicTable
						retain={true}
						reduxKey={reduxKey}
						fetchDataAction={this.getTickets}
						filterFields={FILTER_FIELDS}
						showResetFiltersButton={true}
						onRowClick={this.handleItemSelected}
						style={styles.center}
						columns={isAdmin ? this.adminColumns : this.userColumns}
						onPostInit={this.setQuickFilters}
					/>
				</QuickCard>
			</React.Fragment>
		)
	}
}
const mapStateToProps = (state) => {
	return {
		isTicketingAdmin: () => {
			return meetsAppRestriction(state.auth, APPLICATIONS.TicketingAdmin)
		},
		isServiceUser: () => {
			return meetsAppRestriction(state.auth, APPLICATIONS.TicketingTechSupportUser)
		},
		userId: () => {
			return userId(state.auth)
		},
		getName: () => {
			return getName(state.auth)
		},
		...state.lookups,
	}
}

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			callApi,
			loadLookups,
			fetchData: fetchData(reduxKey),
			updateFilters: updateFilters(reduxKey),
			resetFilters: resetFilters(reduxKey),
		},
		dispatch
	),
})

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withRouter,
	withDynamicTableFilter(
		"status",
		undefined,
		reduxKey,
		"setTicketStatus",
		"selectedTicketStatus"
	),
	withDynamicTableFilter(
		"priority",
		undefined,
		reduxKey,
		"setTicketPriority",
		"selectedTicketPriority"
	),
	withDynamicTableFilter("start_date", undefined, reduxKey, "setStartDate", "startDate"),
	withDynamicTableFilter("group", undefined, reduxKey, "setTicketGroup", "selectedTicketGroup"),
	withDynamicTableFilter("created", undefined, reduxKey, "setCreatedBy", "selectedCreatedBy"),
	withDynamicTableFilter("assigned", undefined, reduxKey, "setAssignedTo", "selectedAssignedTo"),
	withDynamicTableFilter(
		"ticket_close_start",
		undefined,
		reduxKey,
		"setTicketCloseStart",
		"selectedTicketCloseStart"
	),
	withDynamicTableFilter(
		"est_comp_date",
		undefined,
		reduxKey,
		"setEstCompDate",
		"selectedEstCompDate"
	)
)(TicketManagement)

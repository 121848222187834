import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { bindActionCreators } from "redux"
import debounce from "lodash/debounce"
import { callApi } from "../redux/services/callApi"
import { setFilter } from "../redux/services/dynamicTables"
import { meetsAppRestriction } from "../redux/selectors/auth"

export const useCallApi = () => {
	const dispatch = useDispatch()

	const actions = useMemo(() => {
		return bindActionCreators({ callApi }, dispatch)
	}, [dispatch])

	return actions.callApi
}

export const useApi = (requestBuilder, transformResult, initialLoadingState = false) => {
	const callApi = useCallApi()
	const [loading, setLoading] = useState(initialLoadingState)
	const [error, setError] = useState(null)
	const [result, setResult] = useState(null)

	const makeRequest = useCallback(
		(...args) => {
			setLoading(true)
			setError(null)

			try {
				return callApi(requestBuilder(...args))
					.then((response) => {
						const { error, payload } = response
						if (error === true) {
							setError(payload)

							return
						}

						const result = transformResult ? transformResult(payload) : payload

						setResult(result)

						return result
					})
					.catch((err) => {
						setError(err)
						setResult(null)
					})
					.finally(() => {
						setLoading(false)
					})
			} catch (err) {
				setLoading(false)
				setError(err)
			}
		},
		[callApi, requestBuilder, transformResult]
	)

	return [makeRequest, { loading, error, result }]
}

export const useLongPress = (
	onLongPress,
	onClick,
	{ shouldPreventDefault = true, delay = 500 } = {}
) => {
	const [longPressTriggered, setLongPressTriggered] = useState(false)
	const timeout = useRef()
	const target = useRef()

	const start = useCallback(
		(event) => {
			if (shouldPreventDefault && event.target) {
				event.target.addEventListener("touchend", preventDefault, {
					passive: false,
				})
				target.current = event.target
			}
			timeout.current = setTimeout(() => {
				onLongPress?.(event)
				setLongPressTriggered(true)
			}, delay)
		},
		[onLongPress, delay, shouldPreventDefault]
	)

	const clear = useCallback(
		(event, shouldTriggerClick = true) => {
			timeout.current && clearTimeout(timeout.current)
			shouldTriggerClick && !longPressTriggered && onClick?.()
			setLongPressTriggered(false)
			if (shouldPreventDefault && target.current) {
				target.current.removeEventListener("touchend", preventDefault)
			}
		},
		[shouldPreventDefault, onClick, longPressTriggered]
	)

	return {
		onMouseDown: (e) => start(e),
		onTouchStart: (e) => start(e),
		onMouseUp: (e) => clear(e),
		onMouseLeave: (e) => clear(e, false),
		onTouchEnd: (e) => clear(e),
	}
}

const isTouchEvent = (event) => {
	return "touches" in event
}

const preventDefault = (event) => {
	if (!isTouchEvent(event)) {
		return
	}

	if (event.touches.length < 2 && event.preventDefault) {
		event.preventDefault()
	}
}

export const useDynamicTableFilter = (fieldName, reduxKey) => {
	const dispatch = useDispatch()
	const dynamicTables = useSelector((x) => x.dynamicTables)

	const isArray = Array.isArray(reduxKey)
	if ((typeof reduxKey !== "string" && !isArray) || (isArray && reduxKey.length === 0)) {
		throw Error(
			"A reduxKey prop of a string or an array of strings is required for useDynamicTableFilter to function."
		)
	}

	let keys = isArray ? reduxKey : [reduxKey]

	// only map the first one, since all should be set the same
	let reportState = dynamicTables && keys[0] ? dynamicTables[keys[0]] : undefined

	let value = undefined
	// special case for search filter
	if (reportState && fieldName === "search") {
		value = reportState[fieldName]
	} else if (reportState && reportState.filtered) {
		value = (reportState.filtered.find((x) => x.id === fieldName) || {}).value
	}

	let setFilters = (value, text) => (dispatch) =>
		keys.forEach((k) => setFilter(k, fieldName)(value, text)(dispatch))

	let onChange = bindActionCreators(setFilters, dispatch)

	return [value, onChange, reportState]
}

export const useDispatchableAction = (action) => {
	const dispatch = useDispatch()
	return useCallback((...args) => dispatch(action(...args)), [action, dispatch])
}

export const useDebounce = (
	value,
	wait = 350,
	{ leading = false, maxWait = undefined, trailing = true } = {}
) => {
	const [debouncedValue, setDebouncedValue] = useState(value)

	const debounced = useCallback(
		debounce(setDebouncedValue, wait, { leading, maxWait, trailing }),
		[setDebouncedValue, wait, leading, maxWait, trailing]
	)

	useEffect(() => {
		debounced(value)
	}, [debounced, value])

	return debouncedValue
}

/**
 * Hooks version of prevProps in componentDidUpdate
 * https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 */
export const usePrevious = (value) => {
	const ref = useRef()

	useEffect(() => {
		ref.current = value
	})

	return ref.current
}

export const useAppRestrictions = (restriction) => {
	const userAuth = useSelector((store) => store.auth)

	return meetsAppRestriction(userAuth, restriction)
}

/**
 * Like useState, except automatically syncs with localStorage.
 * Mostly based on https://usehooks.com/useLocalStorage.
 */
export const useLocalStorageSyncedState = (initialValue, keyOverride) => {
	// To avoid naming collisions, we'll use the URL pathname as the default key.
	// You can override that if you need to though, for example if you need
	// multiple things stored at the same URL, or if the URL is dynamically
	// generated.
	const { pathname } = useLocation()
	const key = keyOverride ? keyOverride : pathname

	const [storedValue, setStoredValue] = useState(() => {
		try {
			// Try to get the item from storage. If it's there, that'll be our initial
			// value, otherwise use the passed parameter.
			const item = window.localStorage.getItem(key)

			return item ? JSON.parse(item) : initialValue
		} catch (error) {
			console.error(error)

			return initialValue
		}
	})

	// Return a wrapped version of useState's setter function that persists the new value to localStorage.
	const setValue = (value) => {
		try {
			// Allow value to be a function so we have same API as useState
			const valueToStore = value instanceof Function ? value(storedValue) : value

			// Save in state and in local storage.
			setStoredValue(valueToStore)
			window.localStorage.setItem(key, JSON.stringify(valueToStore))
		} catch (error) {
			console.log(error)
		}
	}

	return [storedValue, setValue]
}

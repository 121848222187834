import { get, patch, post, del, rsaaTypes, RSAA } from "@ncs/bricks/middleware/portalApiMiddleware"
import { unpythonify } from "@ncs/bricks/util/variableCasing"

export const INVENTORY_DASHBOARD = rsaaTypes("inventory/dashboard")
export const GET_PART_PRICE = rsaaTypes("inventory/get_part_price/get")
export const GET_BIN_PARTS = rsaaTypes("inventory/bin_part/get")
export const LIST_PARTS = rsaaTypes("inventory/parts/list")
export const PATCH_PART = rsaaTypes("inventory/part/patch")
export const LIST_BINS = rsaaTypes("inventory/location/bins/list")
export const GET_LOCATION_LEVELS = rsaaTypes("inventory/locations/list")
export const EXPORT_LOCATION_LEVELS = rsaaTypes("inventory/locations/export")
export const GET_LOCATION_DETAILS = rsaaTypes("inventory/locations/details/list")
export const PATCH_LOCATION_DETAILS = rsaaTypes("inventory/locations/details/patch")
export const GET_LOCATION_INVENTORY_TRANSACTIONS = rsaaTypes(
	"inventory/locations/transactions/list"
)
export const EXPORT_LOCATION_INVENTORY_TRANSACTIONS = rsaaTypes(
	"inventory/locations/transactions/export"
)
export const GET_PART_INVENTORY_TRANSACTIONS = rsaaTypes("inventory/part/transactions/list")
export const EXPORT_PART_INVENTORY_TRANSACTIONS = rsaaTypes("inventory/part/transactions/export")
export const PATCH_INVENTORY = rsaaTypes("inventory/locations/inventory/patch")
export const GET_TECH_LOCATIONS = rsaaTypes("inventory/tech_locations/list")
export const GET_TECHS_AND_WAREHOUSES = rsaaTypes("inventory/get_techs_and_warehouses/list")
export const GET_PICK_LIST = rsaaTypes("inventory/pick_list")
export const COMPLETE_PICK_LIST = rsaaTypes("inventory/complete_pick_list")
export const GET_PART_LEVELS = rsaaTypes("inventory/part/levels/list")
export const SET_LOCATION = "inventory/set_location"
export const SET_PART = "inventory/set_part"
export const CREATE_TECH_LOCATION = rsaaTypes("inventory/tech_locations/create")
export const GET_TECH_LOCATION = rsaaTypes("inventory/tech_locations/get")
export const PATCH_TECH_LOCATION = rsaaTypes("inventory/tech_locations/patch")
export const DELETE_TECH_LOCATION = rsaaTypes("inventory/tech_locations/delete")
export const GET_PICK_DOCUMENTS = rsaaTypes("inventory/pick_lists/documents")
export const GET_LOCAL_PARTS = rsaaTypes("inventory/local_parts/list")
export const UPDATE_LOCAL_PART = rsaaTypes("inventory/local_parts/update")
export const CREATE_LOCAL_PART = rsaaTypes("inventory/local_parts/create")
export const DELETE_LOCAL_PART = rsaaTypes("inventory/local_parts/delete")
export const GET_CROSS_REF_PARTS = rsaaTypes("inventory/cross_ref/list")
export const UPDATE_CROSS_REF_PART = rsaaTypes("inventory/cross_ref/update")
export const CREATE_CROSS_REF_PART = rsaaTypes("inventory/cross_ref/create")
export const DELETE_CROSS_REF_PART = rsaaTypes("inventory/cross_ref/delete")
export const CHECK_LOCATION_REPLENISHMENT = rsaaTypes("inventory/replenishment")
export const CHECK_LOCATION_OVERSTOCK = rsaaTypes("inventory/overstocked")
export const REFRESH_LOCATION = rsaaTypes("inventory/refresh/location")
export const FETCH_PICK_LIST = rsaaTypes("inventory/fetch/pick_list")
export const CREATE_INVENTORY_SHEET = rsaaTypes("inventory/inventory_cheet_sheet")
export const GET_INVENTORY_REQUESTS = rsaaTypes("inventory/fetch/inventory_requests")
export const GET_INVENTORY_REQUEST = rsaaTypes("inventory/fetch/inventory_request")
export const CREATE_INVENTORY_REQUEST = rsaaTypes("inventory/create/inventory_request")
export const UPDATE_INVENTORY_REQUEST_LINE = rsaaTypes("inventory/update/inventory_request_line")
export const SUBMIT_INVENTORY_REQUEST = rsaaTypes("inventory/submit/inventory_request")
export const ADD_INVENTORY_REQUEST_LINE = rsaaTypes("inventory/add/inventory_request")
export const GET_ALLOCATION_ITEMS = rsaaTypes("inventory/allocation_levels")
export const GET_INVENTORY_TRANSACTION_TYPES = rsaaTypes("inventory/transaction_types")
export const GET_INVENTORY_BIN_INFO = rsaaTypes("inventory/bin_info")
export const SUBMIT_INVENTORY_TRANSACTION = rsaaTypes("inventory/transaction/submit")
export const RUN_LOCATION_REPLEN = rsaaTypes("inventory/location/replen")
export const GET_RECIPES = rsaaTypes("inventory/recipes/list")
export const GET_RECIPE = rsaaTypes("inventory/recipe/get")
export const CREATE_RECIPE = rsaaTypes("inventory/recipe/post")
export const PATCH_RECIPE = rsaaTypes("inventory/recipe/patch")
export const CREATE_RECIPE_INGREDIENT = rsaaTypes("inventory/recipe/ingredient/post")
export const PATCH_RECIPE_INGREDIENT = rsaaTypes("inventory/recipe/ingredient/patch")
export const DELETE_RECIPE_INGREDIENT = rsaaTypes("inventory/recipe/ingredient/delete")
export const EXPORT_INVENTORY_REQUEST = rsaaTypes("inventory/request/export")
export const GET_CHEMICAL_MACHINE_TYPES = rsaaTypes("inventory/chemical_machine_types/list")
export const CREATE_CHEMICAL_MACHINE_TYPE = rsaaTypes("inventory/chemical_machine_types/post")
export const PATCH_CHEMICAL_MACHINE_TYPE = rsaaTypes("inventory/chemical_machine_types/patch")
export const GET_CHEMICAL_MACHINE_PHASES = rsaaTypes("inventory/chemical_machine_phases/list")
export const CREATE_CHEMICAL_MACHINE_PHASE = rsaaTypes("inventory/chemical_machine_phases/post")
export const PATCH_CHEMICAL_MACHINE_PHASE = rsaaTypes("inventory/chemical_machine_phases/patch")
export const GET_CHEMICAL_PHASE_PARTS = rsaaTypes("inventory/chemical_phase_parts/list")
export const CREATE_CHEMICAL_PHASE_PART = rsaaTypes("inventory/chemical_phase_parts/post")
export const PATCH_CHEMICAL_PHASE_PART = rsaaTypes("inventory/chemical_phase_parts/patch")
export const DELETE_CHEMICAL_PHASE_PART = rsaaTypes("inventory/chemical_phase_parts/delete")
export const GET_REPLENISHMENT_IGNORE_PARTS = rsaaTypes("inventory/replenishment_ignore/list")
export const CREATE_REPLENISHMENT_IGNORE_PART = rsaaTypes("inventory/replenishment_ignore/create")
export const DELETE_REPLENISHMENT_IGNORE_PART = rsaaTypes("inventory/replenishment_ignore/delete")
export const CREATE_REPLENISHMENT_ORDER = rsaaTypes("inventory/replenishment_order/create")
export const CREATE_PART_GROUP = rsaaTypes("inventory/part_group/create")
export const PATCH_PART_GROUP = rsaaTypes("inventory/part_group/patch")
export const LIST_PART_GROUPS = rsaaTypes("inventory/part_group/list")
export const LIST_PART_GROUP = rsaaTypes("inventory/part_group/get")
export const CREATE_PART_GROUP_DISCOUNT = rsaaTypes("inventory/part_group_discount/create")
export const PATCH_PART_GROUP_DISCOUNT = rsaaTypes("inventory/part_group_discount/patch")
export const LIST_PART_GROUPS_DISCOUNT = rsaaTypes("inventory/part_group_discount/list")
export const LIST_PART_GROUP_DISCOUNT = rsaaTypes("inventory/part_group_discount/get")
export const DELETE_PART_GROUP_DISCOUNT = rsaaTypes("inventory/part_group_discount/delete")
export const GET_LOCATION_BINS = rsaaTypes("inventory/locations/bins")
export const UPDATE_LOCATION_BIN = rsaaTypes("inventory/location/bin/update")
export const CREATE_LOCATION_BIN = rsaaTypes("inventory/location/bin/create")
export const CREATE_PART_GROUP_MEMBER = rsaaTypes("inventory/part_group_member/create")
export const DELETE_PART_GROUP_MEMBER = rsaaTypes("inventory/part_group_member/delete")
export const LIST_PART_GROUP_MEMBER = rsaaTypes("inventory/part_group_member/list")

export const GET_REPLENISHMENT_OVERSTOCK_PARTS = rsaaTypes(
	"inventory/replenishment_overstock/list"
)
export const CREATE_REPLENISHMENT_OVERSTOCK_PART = rsaaTypes(
	"inventory/replenishment_overstock/create"
)
export const DELETE_REPLENISHMENT_OVERSTOCK_PART = rsaaTypes(
	"inventory/replenishment_overstock/delete"
)

export const getInventoryDashboard = () => ({
	[RSAA]: {
		api: get("/inventory/dashboard/"),
		types: INVENTORY_DASHBOARD,
	},
})

export const getPartPrice = (data) => ({
	[RSAA]: {
		api: post(`/inventory/get_part_price/`, data),
		types: GET_PART_PRICE,
	},
})

export const getBinParts = (partId) => ({
	[RSAA]: {
		api: get(`/inventory/bin_parts/${partId}/`),
		types: GET_BIN_PARTS,
	},
})

export const listParts = (querystring_params) => ({
	[RSAA]: {
		api: get(`/inventory/parts/`, querystring_params),
		types: LIST_PARTS,
		parseLinkHeader: true,
	},
})

export const patchPart = (partId, data) => ({
	[RSAA]: {
		api: patch(`/inventory/parts/${partId}/`, data),
		types: PATCH_PART,
	},
})

export const listBins = (locationId) => ({
	[RSAA]: {
		api: get(`/inventory/locations/${locationId}/bins/`),
		types: LIST_BINS,
	},
})

export const listBinsWithoutParts = (locationId) => ({
	[RSAA]: {
		api: get(`/inventory/locations/${locationId}/bins/`, {
			fields: "_id,code,location_id,is_default",
		}),
		types: LIST_BINS,
	},
})

export const getPickLists = (querystring_params) => ({
	[RSAA]: {
		api: get(`/inventory/pick_list/`, querystring_params),
		types: GET_PICK_LIST,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const fetchPickList = (pickListId) => ({
	[RSAA]: {
		api: get(`/inventory/pick_list/${pickListId}/`),
		types: FETCH_PICK_LIST,
	},
})

export const submitPickList = (pickListId, params) => ({
	[RSAA]: {
		api: post(`/inventory/pick_list/${pickListId}/complete/`, params),
		types: COMPLETE_PICK_LIST,
	},
})

export const getPickListDocuments = (pickListId) => ({
	[RSAA]: {
		api: get(`/inventory/pick_list/${pickListId}/documents/`),
		types: GET_PICK_DOCUMENTS,
	},
})

export const getLocationLevels = (locationId, querystring_params) => ({
	[RSAA]: {
		api: get(`/inventory/locations/${locationId}/levels/`, querystring_params),
		types: GET_LOCATION_LEVELS,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const exportLocationLevels = (locationId, querystringParams) => ({
	[RSAA]: {
		api: post(
			`/inventory/locations/${locationId}/levels/export/`,
			undefined,
			querystringParams
		),
		types: EXPORT_LOCATION_LEVELS,
	},
})

export const runReplenishmentLocation = (locationId) => ({
	[RSAA]: {
		api: post(`/inventory/locations/${locationId}/car_craft_replenishment/`),
		types: RUN_LOCATION_REPLEN,
	},
})

export const getLocationDetails = (locationId, querystring_params) => ({
	[RSAA]: {
		api: get(`/inventory/location_edit/${locationId}/`, querystring_params),
		types: GET_LOCATION_DETAILS,
	},
})

export const patchLocationDetails = (locationId, data) => ({
	[RSAA]: {
		api: patch(`/inventory/location_edit/${locationId}/`, data),
		types: PATCH_LOCATION_DETAILS,
	},
})

export const listLocationInventoryTransactions = (locationId, querystring_params) => {
	querystring_params["location"] = locationId
	return {
		[RSAA]: {
			api: get(`/inventory/transactions/`, querystring_params),
			types: GET_LOCATION_INVENTORY_TRANSACTIONS,
			parseLinkHeader: true,
			includeTimestamp: true,
		},
	}
}

export const exportLocationInventoryTransactions = (locationId, querystring_params) => {
	querystring_params["location"] = locationId
	return {
		[RSAA]: {
			api: post(`/inventory/transactions/export/`, undefined, querystring_params),
			types: EXPORT_LOCATION_INVENTORY_TRANSACTIONS,
		},
	}
}

export const listPartInventoryTransactions = (partId) => {
	return {
		[RSAA]: {
			api: get(`/inventory/transactions/`, { part: partId }),
			types: GET_PART_INVENTORY_TRANSACTIONS,
			parseLinkHeader: true,
			includeTimestamp: true,
		},
	}
}

export const exportPartInventoryTransactions = (partId) => {
	return {
		[RSAA]: {
			api: post(`/inventory/transactions/export/`, undefined, { part: partId }),
			types: EXPORT_PART_INVENTORY_TRANSACTIONS,
		},
	}
}

export const getPartLevels = (partId, querystring_params) => ({
	[RSAA]: {
		api: get(`/inventory/parts/${partId}/levels/`, querystring_params),
		types: GET_PART_LEVELS,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const patchInventory = (locationId, inventoryId, data) => ({
	[RSAA]: {
		api: patch(`/inventory/locations/${locationId}/inventory/${inventoryId}/`, data),
		types: PATCH_INVENTORY,
	},
})

export const getTechLocations = (querystring_params) => ({
	[RSAA]: {
		api: get(`/inventory/tech_locations/`, querystring_params),
		types: GET_TECH_LOCATIONS,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const getTechsAndWarehouses = (querystring_params) => ({
	[RSAA]: {
		api: get(`/inventory/location_edit/`, querystring_params),
		types: GET_TECHS_AND_WAREHOUSES,
		parseLinkHeader: true,
	},
})

export const createTechLocationApi = (data) => ({
	[RSAA]: {
		api: post(`/inventory/tech_locations/`, data),
		types: CREATE_TECH_LOCATION,
	},
})
export const patchTechLocation = (techLocationId, data) => ({
	[RSAA]: {
		api: patch(`/inventory/tech_locations/${techLocationId}/`, data),
		types: PATCH_TECH_LOCATION,
	},
})
export const getTechLocation = (querystring_params) => ({
	[RSAA]: {
		api: get(`/inventory/tech_locations/`, querystring_params),
		types: GET_TECH_LOCATION,
	},
})
export const deleteTechLocation = (id) => ({
	[RSAA]: {
		api: del(`/inventory/tech_locations/${id}/`),
		types: DELETE_TECH_LOCATION,
	},
})

export const setLocation = (location) => ({
	type: SET_LOCATION,
	payload: location,
})

export const setPart = (part) => ({
	type: SET_PART,
	payload: part,
})

export const fetchLocalParts = (querystringParams) => ({
	[RSAA]: {
		api: get(`/inventory/local_part/`, querystringParams),
		types: GET_LOCAL_PARTS,
	},
})

export const createLocalPart = (newPart) => ({
	[RSAA]: {
		api: post(`/inventory/local_part/`, newPart),
		types: CREATE_LOCAL_PART,
	},
})

export const updateLocalPart = (newPart) => ({
	[RSAA]: {
		api: patch(`/inventory/local_part/${newPart.id}/`, newPart),
		types: UPDATE_LOCAL_PART,
	},
})

export const deleteLocalPart = (partId) => ({
	[RSAA]: {
		api: del(`/inventory/local_part/${partId}/`),
		types: DELETE_LOCAL_PART,
	},
})

export const fetchCrossRef = (querystringParams) => ({
	[RSAA]: {
		api: get(`/part_cross_reference/`, querystringParams),
		types: GET_CROSS_REF_PARTS,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const createCrossRef = (newPart) => ({
	[RSAA]: {
		api: post(`/part_cross_reference/`, newPart),
		types: CREATE_CROSS_REF_PART,
	},
})

export const updateCrossRef = (newPart) => ({
	[RSAA]: {
		api: patch(`/part_cross_reference/${newPart._id}/`, newPart),
		types: UPDATE_CROSS_REF_PART,
	},
})

export const deleteCrossRef = (refId) => ({
	[RSAA]: {
		api: del(`/part_cross_reference/${refId}/`),
		types: DELETE_CROSS_REF_PART,
	},
})

export const checkReplenishment = (params) => ({
	[RSAA]: {
		api: post(`/inventory/replenishment/`, params),
		types: CHECK_LOCATION_REPLENISHMENT,
	},
})

export const checkOverStocked = (params) => ({
	[RSAA]: {
		api: post(`/inventory/over_stocked/`, params),
		types: CHECK_LOCATION_OVERSTOCK,
	},
})

export const refreshLocation = (params) => ({
	[RSAA]: {
		api: post(`/inventory/refresh_location/`, params),
		types: REFRESH_LOCATION,
	},
})

export const createInventorySheet = (locId) => ({
	[RSAA]: {
		api: post(`/inventory/location_inventory_cheat_sheet/`, { location_id: locId }),
		types: CREATE_INVENTORY_SHEET,
	},
})

export const fetchInventoryRequests = (querystringParams) => ({
	[RSAA]: {
		api: get(`/inventory/inventory_requests/`, querystringParams),
		types: GET_INVENTORY_REQUESTS,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const fetchInventoryRequest = (requestId) => ({
	[RSAA]: {
		api: get(`/inventory/inventory_request_detail/${requestId}/`),
		types: GET_INVENTORY_REQUEST,
	},
})

export const exportInventoryRequest = (requestId) => ({
	[RSAA]: {
		api: post(`/inventory/inventory_requests/${requestId}/lines/export/`),
		types: EXPORT_INVENTORY_REQUEST,
	},
})

export const createInventoryRequest = (params) => ({
	[RSAA]: {
		api: post(`/inventory/inventory_requests/`, params),
		types: CREATE_INVENTORY_REQUEST,
	},
})

export const updateInventoryRequestLine = (requestId, lineId, params) => ({
	[RSAA]: {
		api: patch(`/inventory/inventory_requests/${requestId}/lines/${lineId}/`, params),
		types: UPDATE_INVENTORY_REQUEST_LINE,
	},
})

export const submitInventoryRequest = (requestId) => ({
	[RSAA]: {
		api: post(`/inventory/inventory_requests/${requestId}/submit/`),
		types: SUBMIT_INVENTORY_REQUEST,
	},
})

export const addInventoryRequestLine = (requestId, lineInfo) => ({
	[RSAA]: {
		api: post(`/inventory/inventory_requests/${requestId}/lines/`, lineInfo),
		types: ADD_INVENTORY_REQUEST_LINE,
	},
})

export const getAllocationLevels = (locationId, partId) => ({
	[RSAA]: {
		api: get(`/inventory/allocation/`, { location: locationId, part: partId }),
		types: GET_ALLOCATION_ITEMS,
	},
})

export const getInventoryTransactionTypes = () => ({
	[RSAA]: {
		api: get("/inventory/inventory_transaction_initial/"),
		types: GET_INVENTORY_TRANSACTION_TYPES,
	},
})

export const getInventoryBinInfo = (locationId) => ({
	[RSAA]: {
		api: post("/inventory/get_bin_information/", { location: locationId }),
		types: GET_INVENTORY_BIN_INFO,
	},
})

export const submitInventoryTransaction = (
	transactionTypeId,
	sourceLocationId,
	destLocationId,
	parts
) => ({
	[RSAA]: {
		api: post("/inventory/transaction/", {
			transaction_type_id: transactionTypeId,
			source_location_id: sourceLocationId,
			dest_location_id: destLocationId,
			parts: parts.map((part) => {
				const ret = {
					part_id: part.partId,
					quantity: part.quantity,
				}

				if (part.destBinId != null) {
					ret.dest_bin_id = part.destBinId
				}

				if (part.sourceBinId != null) {
					ret.source_bin_id = part.sourceBinId
				}

				return ret
			}),
		}),
		types: SUBMIT_INVENTORY_TRANSACTION,
	},
})

export const fetchRecipes = (querystringParams) => ({
	[RSAA]: {
		api: get(`/inventory/recipes/`, querystringParams),
		types: GET_RECIPES,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const getRecipe = (recipeId) => ({
	[RSAA]: {
		api: get(`/inventory/recipes/${recipeId}/`),
		types: GET_RECIPE,
	},
})

export const createRecipe = (data) => ({
	[RSAA]: {
		api: post(`/inventory/recipes/`, data),
		types: CREATE_RECIPE,
	},
})

export const patchRecipe = (recipeId, data) => ({
	[RSAA]: {
		api: patch(`/inventory/recipes/${recipeId}/`, data),
		types: PATCH_RECIPE,
	},
})

export const createRecipeIngredient = (recipeId, data) => ({
	[RSAA]: {
		api: post(`/inventory/recipes/${recipeId}/ingredients/`, data),
		types: CREATE_RECIPE_INGREDIENT,
	},
})

export const patchRecipeIngredient = (recipeId, ingredientId, data) => ({
	[RSAA]: {
		api: patch(`/inventory/recipes/${recipeId}/ingredients/${ingredientId}/`, data),
		types: PATCH_RECIPE_INGREDIENT,
	},
})

export const deleteRecipeIngredient = (recipeId, ingredientId) => ({
	[RSAA]: {
		api: del(`/inventory/recipes/${recipeId}/ingredients/${ingredientId}/`),
		types: DELETE_RECIPE_INGREDIENT,
	},
})

export const getChemicalMachineTypes = (isActive) => ({
	[RSAA]: {
		api: get("/inventory/chemical_machine_types/", {
			is_active: isActive,
		}),
		types: GET_CHEMICAL_MACHINE_TYPES,
	},
})

export const createChemicalMachineType = ({ description, isActive }) => ({
	[RSAA]: {
		api: post("/inventory/chemical_machine_types/", {
			description,
			is_active: isActive,
		}),
		types: CREATE_CHEMICAL_MACHINE_TYPE,
	},
})

export const patchChemicalMachineType = ({ machineTypeId, description, isActive }) => ({
	[RSAA]: {
		api: patch(`/inventory/chemical_machine_types/${machineTypeId}/`, {
			is_active: isActive,
			description,
		}),
		types: PATCH_CHEMICAL_MACHINE_TYPE,
	},
})

export const getChemicalMachinePhases = ({ machineTypeId, isActive }) => ({
	[RSAA]: {
		api: get("/inventory/chemical_machine_phases/", {
			machine_type: machineTypeId,
			is_active: isActive,
		}),
		types: GET_CHEMICAL_MACHINE_PHASES,
	},
})

export const createChemicalMachinePhase = ({ description, machineTypeId, isActive }) => ({
	[RSAA]: {
		api: post("/inventory/chemical_machine_phases/", {
			description,
			machine_type_id: machineTypeId,
			is_active: isActive,
		}),
		types: CREATE_CHEMICAL_MACHINE_PHASE,
	},
})

export const patchChemicalMachinePhase = ({ machinePhaseId, description, isActive }) => ({
	[RSAA]: {
		api: patch(`/inventory/chemical_machine_phases/${machinePhaseId}/`, {
			description,
			is_active: isActive,
		}),
		types: PATCH_CHEMICAL_MACHINE_PHASE,
	},
})

export const getChemicalPhaseParts = ({ machinePhaseId }) => ({
	[RSAA]: {
		api: get("/inventory/chemical_phase_parts/", {
			machine_phase: machinePhaseId,
		}),
		types: GET_CHEMICAL_PHASE_PARTS,
	},
})

export const createChemicalPhasePart = ({ machinePhaseId, partId, amount }) => ({
	[RSAA]: {
		api: post("/inventory/chemical_phase_parts/", {
			machine_phase_id: machinePhaseId,
			part_id: partId,
			default_usage_rate_in_mL: amount,
		}),
		types: CREATE_CHEMICAL_PHASE_PART,
	},
})

export const patchChemicalPhasePart = ({ phasePartId, amount }) => ({
	[RSAA]: {
		api: patch(`/inventory/chemical_phase_parts/${phasePartId}/`, {
			default_usage_rate_in_mL: amount,
		}),
		types: PATCH_CHEMICAL_PHASE_PART,
	},
})

export const deleteChemicalPhasePart = ({ phasePartId }) => ({
	[RSAA]: {
		api: del(`/inventory/chemical_phase_parts/${phasePartId}/`),
		types: DELETE_CHEMICAL_PHASE_PART,
	},
})

export const getReplenishmentIgnore = (locationId) => ({
	[RSAA]: {
		api: get("/inventory/replenishment_ignore/", {
			location: locationId,
		}),
		types: GET_REPLENISHMENT_IGNORE_PARTS,
	},
})

export const createReplenishmentIgnore = (locationId, partId) => ({
	[RSAA]: {
		api: post("/inventory/replenishment_ignore/", {
			part_id: partId,
			location_id: locationId,
		}),
		types: CREATE_REPLENISHMENT_IGNORE_PART,
	},
})

export const deleteReplenishmentIgnore = (ignoreId) => ({
	[RSAA]: {
		api: del(`/inventory/replenishment_ignore/${ignoreId}/`),
		types: DELETE_REPLENISHMENT_IGNORE_PART,
	},
})

export const getReplenishmentOverstock = (locationId) => ({
	[RSAA]: {
		api: get("/inventory/replenishment_overstock/", {
			location: locationId,
		}),
		types: GET_REPLENISHMENT_OVERSTOCK_PARTS,
	},
})

export const createReplenishmentOverstock = (locationId, partId) => ({
	[RSAA]: {
		api: post("/inventory/replenishment_overstock/", {
			part_id: partId,
			location_id: locationId,
		}),
		types: CREATE_REPLENISHMENT_OVERSTOCK_PART,
	},
})

export const deleteReplenishmentOverstock = (overstockId) => ({
	[RSAA]: {
		api: del(`/inventory/replenishment_overstock/${overstockId}/`),
		types: DELETE_REPLENISHMENT_OVERSTOCK_PART,
	},
})

export const placeReplenishmentOrder = (params) => ({
	[RSAA]: {
		api: post("/inventory/replenishment_order/", params),
		types: CREATE_REPLENISHMENT_ORDER,
	},
})
export const listPartGroups = (querystringParams) => ({
	[RSAA]: {
		api: get(`/inventory/part_group/`, querystringParams),
		types: LIST_PART_GROUP,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const getPartGroup = (id) => ({
	[RSAA]: {
		api: get(`/inventory/part_group/${id}/`),
		types: LIST_PART_GROUP,
	},
})

export const createPartGroup = (data) => ({
	[RSAA]: {
		api: post(`/inventory/part_group/`, data),
		types: CREATE_PART_GROUP,
	},
})

export const patchPartGroup = (id, data) => ({
	[RSAA]: {
		api: patch(`/inventory/part_group/${id}/`, data),
		types: PATCH_PART_GROUP,
	},
})
export const listPartGroupDiscounts = (querystringParams) => ({
	[RSAA]: {
		api: get(`/inventory/part_group_discount/`, querystringParams),
		types: LIST_PART_GROUP_DISCOUNT,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const getPartGroupDiscount = (id) => ({
	[RSAA]: {
		api: get(`/inventory/part_group_discount/${id}/`),
		types: LIST_PART_GROUP_DISCOUNT,
	},
})

export const createPartGroupDiscount = (data) => ({
	[RSAA]: {
		api: post(`/inventory/part_group_discount/`, {
			...data,
			group_discount_percent: data.group_discount_percent / 100.0,
		}),
		types: CREATE_PART_GROUP_DISCOUNT,
	},
})
export const deletePartGroupDiscount = (id) => ({
	[RSAA]: {
		api: del(`/inventory/part_group_discount/${id}/`),
		types: DELETE_PART_GROUP_DISCOUNT,
	},
})

export const patchPartGroupDiscount = (id, data) => ({
	[RSAA]: {
		api: patch(`/inventory/part_group_discount/${id}/`, data),
		types: PATCH_PART_GROUP_DISCOUNT,
	},
})

export const listPartGroupMembers = (querystringParams) => ({
	[RSAA]: {
		api: get(`/inventory/part_group_member/`, querystringParams),
		types: LIST_PART_GROUP_MEMBER,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const createPartGroupMember = (data) => ({
	[RSAA]: {
		api: post(`/inventory/part_group_member/`, data),
		types: CREATE_PART_GROUP_MEMBER,
	},
})
export const deletePartGroupMember = (id) => ({
	[RSAA]: {
		api: del(`/inventory/part_group_member/${id}/`),
		types: DELETE_PART_GROUP_MEMBER,
	},
})

export const getBinLocations = (querystring_params) => ({
	[RSAA]: {
		api: get(`/inventory/location_bins/`, querystring_params),
		types: GET_LOCATION_BINS,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const patchBinLocation = (id, data) => ({
	[RSAA]: {
		api: patch(`/inventory/location_bins/${id}/`, data),
		types: UPDATE_LOCATION_BIN,
	},
})

export const createBinLocation = (data) => ({
	[RSAA]: {
		api: post(`/inventory/location_bins/`, data),
		types: CREATE_LOCATION_BIN,
	},
})

const randomInts = (min = 1, max = 40, length = 30, outerLength = null) => {
	const rnd = () => Math.floor(Math.random() * Math.floor(max)) + min

	if (!outerLength) return [...Array(length)].map(rnd)

	return [...Array(outerLength)].map(() => [...Array(length)].map(rnd))
}

const oneToThirtyStrings = [
	...Array(30)
		.fill()
		.map((_, i) => (i + 1).toString()),
]

const initialState = {
	dashboard: {
		isLoading: true,
		isInitialState: true,
		lastRefreshed: null,
		openPartsOrders: null,
		recPartsOrders: null,
		todayPartsOrders: null,
		shippedOnTime: null,
		inventoryTurn: null,
		todayTransactions: null,
		mtdCreditStocks: null,
		mtdInventoryLoss: null,
		openPOHistory: {
			labels: oneToThirtyStrings,
			series: randomInts(200, 1000, 30, 1),
		},
		transHistory: {
			labels: oneToThirtyStrings,
			series: randomInts(1, 300, 30, 1),
		},
	},

	isLoadingLocationLevels: false,
	locationLevels: null,

	isLoadingLocationDetails: false,
	locationDetails: null,

	isLoadingTechLocation: false,
	techLocation: null,

	isLoadingLocationTransactions: false,
	locationTransactions: null,

	isLoadingPartLevels: false,
	partLevels: null,

	location: null,
	part: null,

	error: null,

	transaction: {
		types: {
			data: [],
			loading: false,
			error: null,
		},
	},

	isLoadingChemicalMachineTypes: false,
	chemicalMachineTypes: {},

	isLoadingChemicalMachinePhases: false,
	chemicalMachinePhases: {},

	isLoadingChemicalPhaseParts: false,
	chemicalPhaseParts: {},
}

const inventory = (state = initialState, action) => {
	switch (action.type) {
		case INVENTORY_DASHBOARD.request:
			return {
				...state,
				service: {
					...state.dashboard,
					isLoading: true,
				},
			}
		case INVENTORY_DASHBOARD.success:
			// TODO: make this do something useful instead of dump dummy data
			return {
				...state,
				dashboard: {
					isLoading: false,
					isInitialState: false,
					lastRefreshed: new Date(Date.now()),
					openPartsOrders: 19,
					recPartsOrders: 12,
					todayPartsOrders: 3,
					shippedOnTime: 87.2,
					inventoryTurn: 92.7,
					todayTransactions: 5,
					mtdCreditStocks: 21,
					mtdInventoryLoss: 23,
					openPOHistory: {
						labels: oneToThirtyStrings,
						series: randomInts(200, 1000, 30, 1),
					},
					transHistory: {
						labels: oneToThirtyStrings,
						series: randomInts(1, 300, 30, 1),
					},
				},
			}
		case INVENTORY_DASHBOARD.failure:
			return {
				...state,
				service: {
					...action.payload,
					isLoading: false,
					isInitialState: false,
					lastRefreshed: Date.now(),
				},
			}

		case LIST_PARTS.request:
			return {
				...state,
				isLoadingParts: true,
			}
		case LIST_PARTS.success:
			return {
				...state,
				isLoadingParts: false,
				parts: action.payload,
				partsPages: action.meta.pages,
			}
		case LIST_PARTS.failure:
			return {
				...state,
				isLoadingParts: false,
				error: action,
			}

		case LIST_BINS.request:
			return {
				...state,
				isLoadingBins: true,
			}
		case LIST_BINS.success:
			return {
				...state,
				isLoadingBins: false,
				bins: action.payload,
			}
		case LIST_BINS.failure:
			return {
				...state,
				isLoadingBins: false,
				error: action,
			}

		case GET_LOCATION_LEVELS.request:
		case GET_LOCATION_LEVELS.success:
		case GET_LOCATION_LEVELS.failure:
			return {
				...state,
				isLoadingLocationLevels: action.type === GET_LOCATION_LEVELS.request,
				locationLevels: action.payload,
				error: action.error,
			}

		case GET_LOCATION_DETAILS.request:
		case GET_LOCATION_DETAILS.success:
		case GET_LOCATION_DETAILS.failure:
			return {
				...state,
				isLoadingLocationDetails: action.type === GET_LOCATION_DETAILS.request,
				locationDetails: action.payload,
				error: action.error,
			}

		case GET_TECH_LOCATION.requst:
		case GET_TECH_LOCATION.success:
		case GET_TECH_LOCATION.failure:
			return {
				...state,
				isLoadingTechLocation: action.type === GET_TECH_LOCATION.request,
				techLocation: action.payload,
				error: action.error,
			}
		case CREATE_TECH_LOCATION.request:
			return {
				...state,
				isSaving: true,
			}
		case CREATE_TECH_LOCATION.success:
			return {
				...state,
				isSaving: false,
				techLocation: action.payload,
				error: null,
			}
		case CREATE_TECH_LOCATION.failure:
			return {
				...state,
				isSaving: false,
				error: action.error,
			}
		case GET_LOCATION_INVENTORY_TRANSACTIONS.request:
		case GET_LOCATION_INVENTORY_TRANSACTIONS.success:
		case GET_LOCATION_INVENTORY_TRANSACTIONS.failure:
			return {
				...state,
				isLoadingLocationTransactions:
					action.type === GET_LOCATION_INVENTORY_TRANSACTIONS.request,
				locationTransactions: action.payload,
				error: action.error,
			}

		case GET_PART_LEVELS.request:
		case GET_PART_LEVELS.success:
		case GET_PART_LEVELS.failure:
			return {
				...state,
				isLoadingPartLevels: action.type === GET_PART_LEVELS.request,
				partLevels: action.payload,
				error: action.error,
			}

		case SET_LOCATION:
			return {
				...state,
				location: action.payload,
			}

		case SET_PART:
			return {
				...state,
				part: action.payload,
			}

		case GET_INVENTORY_TRANSACTION_TYPES.request:
			return {
				...state,
				transaction: {
					...state.transaction,
					types: {
						...state.transaction.types,
						loading: true,
						error: null,
					},
				},
			}
		case GET_INVENTORY_TRANSACTION_TYPES.success:
			const { transaction_types } = action.payload

			return {
				...state,
				transaction: {
					...state.transaction,
					types: {
						...state.transaction.types,
						data: transaction_types,
						loading: false,
						error: null,
					},
				},
			}
		case GET_INVENTORY_TRANSACTION_TYPES.failure:
			return {
				...state,
				transaction: {
					...state.transaction,
					types: {
						...state.transaction.types,
						loading: false,
						error: action.payload,
						data: [],
					},
				},
			}

		case GET_CHEMICAL_MACHINE_TYPES.request:
			return {
				...state,
				isLoadingChemicalMachineTypes: true,
			}
		case GET_CHEMICAL_MACHINE_TYPES.failure:
			return {
				...state,
				isLoadingChemicalMachineTypes: false,
				error: action.error,
			}
		case GET_CHEMICAL_MACHINE_TYPES.success:
			const newMachineTypes = (unpythonify(action.payload) ?? []).map((machineType) => [
				machineType.id,
				machineType,
			])

			return {
				...state,
				isLoadingChemicalMachineTypes: false,
				chemicalMachineTypes: {
					...state.chemicalMachineTypes,
					...Object.fromEntries(newMachineTypes),
				},
			}

		case CREATE_CHEMICAL_MACHINE_TYPE.request:
			return {
				...state,
				isLoadingChemicalMachineTypes: true,
			}
		case CREATE_CHEMICAL_MACHINE_TYPE.failure:
			return {
				...state,
				isLoadingChemicalMachineTypes: false,
				error: action.error,
			}
		case CREATE_CHEMICAL_MACHINE_TYPE.success:
			return {
				...state,
				isLoadingChemicalMachineTypes: false,
				chemicalMachineTypes: {
					...state.chemicalMachineTypes,
					[action.payload.id]: unpythonify(action.payload),
				},
			}

		case PATCH_CHEMICAL_MACHINE_TYPE.request:
			return {
				...state,
				isLoadingChemicalMachineTypes: true,
			}
		case PATCH_CHEMICAL_MACHINE_TYPE.failure:
			return {
				...state,
				isLoadingChemicalMachineTypes: false,
				error: action.error,
			}
		case PATCH_CHEMICAL_MACHINE_TYPE.success:
			return {
				...state,
				isLoadingChemicalMachineTypes: false,
				chemicalMachineTypes: {
					...state.chemicalMachineTypes,
					[action.payload.id]: unpythonify(action.payload),
				},
			}

		case GET_CHEMICAL_MACHINE_PHASES.request:
			return {
				...state,
				isLoadingChemicalMachinePhases: true,
			}
		case GET_CHEMICAL_MACHINE_PHASES.failure:
			return {
				...state,
				isLoadingChemicalMachinePhases: false,
				error: action.error,
			}
		case GET_CHEMICAL_MACHINE_PHASES.success:
			const newMachinePhases = (unpythonify(action.payload) ?? []).map((machinePhase) => [
				machinePhase.id,
				machinePhase,
			])

			return {
				...state,
				isLoadingChemicalMachinePhases: false,
				chemicalMachinePhases: {
					...state.chemicalMachinePhases,
					...Object.fromEntries(newMachinePhases),
				},
			}

		case CREATE_CHEMICAL_MACHINE_PHASE.request:
			return {
				...state,
				isLoadingChemicalMachinePhases: true,
			}
		case CREATE_CHEMICAL_MACHINE_PHASE.failure:
			return {
				...state,
				isLoadingChemicalMachinePhases: false,
				error: action.error,
			}
		case CREATE_CHEMICAL_MACHINE_PHASE.success:
			return {
				...state,
				isLoadingChemicalMachinePhases: false,
				chemicalMachinePhases: {
					...state.chemicalMachinePhases,
					[action.payload.id]: unpythonify(action.payload),
				},
			}

		case PATCH_CHEMICAL_MACHINE_PHASE.request:
			return {
				...state,
				isLoadingChemicalMachinePhases: true,
			}
		case PATCH_CHEMICAL_MACHINE_PHASE.failure:
			return {
				...state,
				isLoadingChemicalMachinePhases: false,
				error: action.error,
			}
		case PATCH_CHEMICAL_MACHINE_PHASE.success:
			return {
				...state,
				isLoadingChemicalMachinePhases: false,
				chemicalMachinePhases: {
					...state.chemicalMachinePhases,
					[action.payload.id]: unpythonify(action.payload),
				},
			}

		case GET_CHEMICAL_PHASE_PARTS.request:
			return {
				...state,
				isLoadingChemicalPhaseParts: true,
			}
		case GET_CHEMICAL_PHASE_PARTS.failure:
			return {
				...state,
				isLoadingChemicalPhaseParts: false,
				error: action.error,
			}
		case GET_CHEMICAL_PHASE_PARTS.success:
			const newPhaseParts = (unpythonify(action.payload) ?? []).map((phasePart) => [
				phasePart.id,
				phasePart,
			])

			return {
				...state,
				isLoadingChemicalPhaseParts: false,
				chemicalPhaseParts: {
					...state.chemicalPhaseParts,
					...Object.fromEntries(newPhaseParts),
				},
			}

		case CREATE_CHEMICAL_PHASE_PART.request:
			return {
				...state,
				isLoadingChemicalPhaseParts: true,
			}
		case CREATE_CHEMICAL_PHASE_PART.failure:
			return {
				...state,
				isLoadingChemicalPhaseParts: false,
				error: action.error,
			}
		case CREATE_CHEMICAL_PHASE_PART.success:
			return {
				...state,
				isLoadingChemicalPhaseParts: false,
				chemicalPhaseParts: {
					...state.chemicalPhaseParts,
					[action.payload.id]: unpythonify(action.payload),
				},
			}

		case PATCH_CHEMICAL_PHASE_PART.request:
			return {
				...state,
				isLoadingChemicalPhaseParts: true,
			}
		case PATCH_CHEMICAL_PHASE_PART.failure:
			return {
				...state,
				isLoadingChemicalPhaseParts: false,
				error: action.error,
			}
		case PATCH_CHEMICAL_PHASE_PART.success:
			return {
				...state,
				isLoadingChemicalPhaseParts: false,
				chemicalPhaseParts: {
					...state.chemicalPhaseParts,
					[action.payload.id]: unpythonify(action.payload),
				},
			}

		case DELETE_CHEMICAL_PHASE_PART.request:
			return {
				...state,
				isLoadingChemicalPhaseParts: true,
			}
		case DELETE_CHEMICAL_PHASE_PART.failure:
			return {
				...state,
				isLoadingChemicalPhaseParts: false,
				error: action.error,
			}
		case DELETE_CHEMICAL_PHASE_PART.success:
			return {
				...state,
				isLoadingChemicalPhaseParts: false,
			}

		default:
			return state
	}
}
export default inventory

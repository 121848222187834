import { FC } from "react"

import { WorkOrderInvoiceLineItem } from "@ncs/ncs-api"
import { displayDateTime, getTimezoneAbbreviation } from "@ncs/ts-utils"
import {
	cssMixins,
	EmptyValueDash,
	ExtendableModalProps,
	GridContainer,
	GridItem,
	Label,
	LabeledData,
	Modal,
	Paragraph,
} from "@ncs/web-legos"

export interface WorkOrderSummaryModalProps extends ExtendableModalProps {
	details: WorkOrderInvoiceLineItem["workorder"]
}

export const WorkOrderSummaryModal: FC<WorkOrderSummaryModalProps> = ({ details, ...rest }) => {
	return (
		<Modal {...rest} title="Work Order Summary">
			<GridContainer>
				<GridItem xs={12} sm={6}>
					<LabeledData label="Work Order">#{details.workorderNumber}</LabeledData>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<LabeledData label="Technician">
						{details.technician || <EmptyValueDash />}
					</LabeledData>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<LabeledData label={`Opened (${getTimezoneAbbreviation()})`}>
						{displayDateTime(details.openDate, "") || <EmptyValueDash />}
					</LabeledData>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<LabeledData label={`Closed (${getTimezoneAbbreviation()})`}>
						{displayDateTime(details.closedDate, "") || <EmptyValueDash />}
					</LabeledData>
				</GridItem>
				<GridItem xs={12}>
					<Label>Description</Label>
					<Paragraph css={cssMixins.preserveLineBreaks} mb={1}>
						{details.comment}
					</Paragraph>
				</GridItem>
			</GridContainer>
		</Modal>
	)
}

import React from "react"

import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { withStyles } from "@material-ui/core/styles"
import cx from "classnames"
import get from "lodash/get"
import moment from "moment"
import PropTypes from "prop-types"

import { dangerColor, successColor } from "@ncs/bricks/assets/jss/material-dashboard-pro-react"
import buttonsStyle from "@ncs/bricks/assets/jss/material-dashboard-pro-react/components/buttonStyle"
import { formatDate, formatLongDateTime } from "@ncs/bricks/util/formatters"

import {
	Alert,
	ButtonWithIcon,
	ConditionalContent,
	CustomerSelector,
	Datetime,
	Dialog,
	DynamicTable,
	GridContainer,
	GridItem,
	Input,
	InputAsDisplay,
	LoadingWrapper,
	ManyToOneEditor,
	NavPills,
	QuickCard,
	Select,
	TerritorySelector,
} from "~/components"

import AddIcon from "@material-ui/icons/Add"
import Check from "@material-ui/icons/Check"
import ChevronLeft from "@material-ui/icons/ChevronLeft"
import Edit from "@material-ui/icons/Edit"
import LockOutline from "@material-ui/icons/Lock"
import Person from "@material-ui/icons/Person"
import Remove from "@material-ui/icons/Remove"

const styles = {
	...buttonsStyle,
	columnChooser: {
		margin: "15px",
	},
	right: {
		textAlign: "right",
	},
	center: {
		textAlign: "center",
	},
	notificationCheckIcon: {
		color: `${successColor[0]} !important`,
	},
	greenInput: {
		color: successColor[0],
	},
	redInput: {
		color: dangerColor[0],
	},
}

const locationColumns = [
	{
		Header: "Number",
		accessor: "location_name",
		sortable: true,
		toggleable: false,
		headerStyle: styles.center,
	},
	{
		Header: "Name",
		accessor: "location_number",
		sortable: true,
		toggleable: false,
		headerStyle: styles.center,
	},
]

const customerColumns = [
	{
		Header: "Number",
		accessor: "customer_number",
		sortable: true,
		toggleable: false,
		headerStyle: styles.center,
	},
	{
		Header: "Name",
		accessor: "name",
		sortable: true,
		toggleable: false,
		headerStyle: styles.center,
	},
]

const UserContactTab = (props) => {
	const {
		classes,
		isLoading,
		isEditing,
		isSaving,
		onStartEditingUser,
		onCancelEditingUser,
		onSaveUser,
		onBackToList,
		displayActionSuccess,
		onSuccessModalClose,
		displayActionFailure,
		onFailureModalClose,
		user,
		isMyProfileView,
		isUserAdmin,
		isUserCustomer,
		showValidationErrors,
		formIsValid,
		employeeNumber,
		employeeNumberIsValid,
		employeeNumberErrorMessage,
		onEmployeeNumberChange,
		email,
		onEmailChange,
		emailErrorMessage,
		emailIsValid,
		firstName,
		onFirstNameChange,
		firstNameErrorMessage,
		firstNameIsValid,
		lastName,
		onLastNameChange,
		lastNameErrorMessage,
		lastNameIsValid,
		mobile,
		onMobileChange,
		mobileErrorMessage,
		mobileIsValid,
		effectiveDate,
		onEffectiveDateChange,
		effectiveDateErrorMessage,
		effectiveDateIsValid,
		endDate,
		onEndDateChange,
		endDateErrorMessage,
		endDateIsValid,
		lastLogin,
		verisaeUsername,
		onVerisaeUsernameChange,
		verisaeUsernameErrorMessage,
		verisaeUsernameIsValid,
		applications,
		applicationSelected,
		handleApplicationGroupUpdate,
		reports,
		reportSelected,
		handleReportGroupUpdate,
		resetPassword,
		isResetting,
		disablePasswordReset,
		locations,
		isLoadingLocations,
		availableTerritories,
		showAddLocation,
		handleCancelAddLocation,
		handleAddLocation,
		handleReloadUser,
		handleShowAddLocation,
		newUserTerritories,
		onRemoveTerritory,
		onAddTerritory,
		billToUsed,
		handleBillToUpdate,
		handleCustomerSelected,
		handleRemoveCustomer,
		newUserCustomers,
		onAddAll,
		onRemoveAll,
		onAddDomestic,
		onAddInternational,
		selectedDefaultTerritory,
		onDefaultTerritoryChange,
		activeTabIndex,
		handleTabChange,
		salesCustomers,
		addSalesCustomer,
		handleCancelSalesCustomer,
		handleAddSalesCustomer,
		handleReloadCustomers,
		handleSaleCustomerSelected,
		handleShowAddCustomer,
		viewDetailsButton,
		handleRemoveSalesCustomer,
		showRemoveCustomer,
		updateShowRemoveCustomer,
		handleShowRemoveCustomer,
	} = props

	const validBetween = (fromDate, toDate) => ({
		inputProps: {
			className: cx({
				[classes.greenInput]: fromDate <= moment() && moment() <= toDate,
				[classes.redInput]: moment() < fromDate || toDate < moment(),
			}),
		},
	})

	const canResetPassword = !isLoading && !isUserCustomer
	const cardTitle = isMyProfileView ? "My Profile" : `User: ${user.first_name} ${user.last_name}`

	return (
		<React.Fragment>
			<LoadingWrapper isLoading={isLoading}>
				{() => (
					<GridContainer>
						<GridItem xs={12}>
							<ButtonWithIcon
								content="Back to List"
								onClick={onBackToList}
								color="white"
								size="sm"
								round
								icon={<ChevronLeft />}
							/>
						</GridItem>
						<GridItem xs={12}>
							<QuickCard title={cardTitle} icon={<Person />}>
								<GridContainer>
									<GridItem xs={12}>
										<GridContainer>
											<GridItem xs={12} sm={6} lg={3}>
												<Input
													labelText={"Employee Number"}
													value={employeeNumber}
													onChange={onEmployeeNumberChange}
													inputProps={{
														disabled: !isEditing || !isUserAdmin,
													}}
													formControlProps={{
														error:
															showValidationErrors &&
															!employeeNumberIsValid,
													}}
													helperText={
														showValidationErrors ?
															employeeNumberErrorMessage
														:	""
													}
												/>
											</GridItem>

											<GridItem xs={12} sm={6} lg={3}>
												<Input
													labelText={"First Name"}
													value={firstName}
													onChange={onFirstNameChange}
													inputProps={{
														disabled: !isEditing,
													}}
													formControlProps={{
														error:
															showValidationErrors &&
															!firstNameIsValid,
													}}
													helperText={
														showValidationErrors ?
															firstNameErrorMessage
														:	""
													}
												/>
											</GridItem>

											<GridItem xs={12} sm={6} lg={3}>
												<Input
													labelText={"Last Name"}
													value={lastName}
													onChange={onLastNameChange}
													inputProps={{
														disabled: !isEditing,
													}}
													formControlProps={{
														error:
															showValidationErrors &&
															!lastNameIsValid,
													}}
													helperText={
														showValidationErrors ? lastNameErrorMessage
														:	""
													}
												/>
											</GridItem>

											<GridItem xs={12} sm={6} lg={3}>
												<Input
													labelText={"Email"}
													value={email}
													onChange={onEmailChange}
													inputProps={{
														disabled: !isEditing,
													}}
													formControlProps={{
														error:
															showValidationErrors && !emailIsValid,
													}}
													helperText={
														showValidationErrors ? emailErrorMessage
														:	""
													}
												/>
											</GridItem>

											<GridItem xs={12} sm={6} lg={3}>
												<Input
													labelText={"Cell Phone #"}
													value={mobile}
													onChange={onMobileChange}
													inputProps={{
														disabled: !isEditing,
													}}
													formControlProps={{
														error:
															showValidationErrors && !mobileIsValid,
													}}
													helperText={
														showValidationErrors ? mobileErrorMessage
														:	""
													}
												/>
											</GridItem>

											<GridItem xs={12} sm={6} lg={3} show={isUserAdmin}>
												<ConditionalContent hide={isEditing}>
													<InputAsDisplay
														labelText="Effective Date"
														value={formatDate(effectiveDate)}
														inputProps={validBetween(
															moment(effectiveDate),
															moment(endDate)
														)}
													/>
												</ConditionalContent>
												<ConditionalContent show={isEditing}>
													<Datetime
														labelText="Effective Date"
														value={moment(effectiveDate)}
														onChange={onEffectiveDateChange}
														isError={
															showValidationErrors &&
															!effectiveDateIsValid
														}
														helperText={
															showValidationErrors ?
																effectiveDateErrorMessage
															:	""
														}
														dateFormat="MMM DD YYYY"
														timeFormat={false}
													/>
												</ConditionalContent>
											</GridItem>

											<GridItem xs={12} sm={6} lg={3} show={isUserAdmin}>
												<ConditionalContent hide={isEditing}>
													<InputAsDisplay
														labelText="End Date"
														value={formatDate(endDate)}
														inputProps={validBetween(
															moment(effectiveDate),
															moment(endDate)
														)}
													/>
												</ConditionalContent>
												<ConditionalContent show={isEditing}>
													<Datetime
														labelText="End Date"
														value={moment(endDate)}
														onChange={onEndDateChange}
														isError={
															showValidationErrors && !endDateIsValid
														}
														helperText={
															showValidationErrors ?
																endDateErrorMessage
															:	""
														}
														dateFormat="MMM DD YYYY"
														timeFormat={false}
													/>
												</ConditionalContent>
											</GridItem>

											<GridItem xs={12} sm={6} lg={3}>
												<ConditionalContent hide={isEditing}>
													<InputAsDisplay
														labelText="Schedule Territory"
														value={
															user.default_territory ?
																`${user.default_territory.description} (${user.default_territory.code})`
															:	"None"
														}
													/>
												</ConditionalContent>
												<ConditionalContent show={isEditing}>
													<TerritorySelector
														labelText="Schedule Territory"
														value={selectedDefaultTerritory}
														onChange={onDefaultTerritoryChange}
													/>
												</ConditionalContent>
											</GridItem>

											<GridItem xs={12} sm={6} lg={3}>
												<Input
													labelText={"Verisae Username"}
													value={verisaeUsername}
													onChange={onVerisaeUsernameChange}
													inputProps={{
														disabled: !isEditing,
													}}
													formControlProps={{
														error:
															showValidationErrors &&
															!verisaeUsernameIsValid,
													}}
													helperText={
														showValidationErrors ?
															verisaeUsernameErrorMessage
														:	""
													}
												/>
											</GridItem>

											{isEditing && isUserAdmin ?
												<GridItem xs={12} sm={6} lg={3}>
													<Select
														id="applicationsGroup"
														labelText="Application Group"
														value={applicationSelected}
														onChange={handleApplicationGroupUpdate}
														options={[
															{ _id: 0, description: "None" },
															...applications,
														]}
														valueAccessor={(item) => item._id}
														textAccessor={(item) => item.description}
													/>
												</GridItem>
											:	<GridItem xs={12} sm={6} lg={3}>
													<InputAsDisplay
														labelText={"Application Group"}
														value={get(
															user,
															"applications_group.description",
															"None"
														)}
													/>
												</GridItem>
											}

											{isEditing && isUserAdmin ?
												<GridItem xs={12} sm={6} lg={3}>
													<Select
														id="reportsGroup"
														labelText="Report Group"
														value={reportSelected}
														onChange={handleReportGroupUpdate}
														options={[
															{ _id: 0, description: "None" },
															...reports,
														]}
														valueAccessor={(item) => item._id}
														textAccessor={(item) => item.description}
													/>
												</GridItem>
											:	<GridItem xs={12} sm={6} lg={3}>
													<InputAsDisplay
														labelText={"Report Group"}
														value={get(
															user,
															"reports_group.description",
															"None"
														)}
													/>
												</GridItem>
											}

											<GridItem xs={12} sm={6} lg={3}>
												<InputAsDisplay
													labelText="Last Login"
													value={formatLongDateTime(
														lastLogin,
														undefined,
														"Never"
													)}
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
									<GridItem xs={12} align="right">
										<ConditionalContent hide={isEditing || isSaving}>
											<ConditionalContent
												hide={isMyProfileView || !canResetPassword}
											>
												<ButtonWithIcon
													icon={<LockOutline />}
													content="Send Reset Password"
													size="sm"
													color="primary"
													round
													disabled={disablePasswordReset}
													loading={isResetting}
													onClick={resetPassword}
												/>
											</ConditionalContent>
											<ButtonWithIcon
												icon={<Edit />}
												content={isMyProfileView ? "Edit" : "Edit User"}
												size="sm"
												round
												color="success"
												onClick={onStartEditingUser}
											/>
										</ConditionalContent>
										<ConditionalContent show={isEditing || isSaving}>
											<ButtonWithIcon
												icon={<Remove />}
												content="Cancel"
												size="sm"
												round
												onClick={onCancelEditingUser}
												disabled={isSaving}
											/>
											<ButtonWithIcon
												icon={<Check />}
												content={isMyProfileView ? "Save" : "Save User"}
												size="sm"
												round
												color="success"
												onClick={onSaveUser}
												disabled={
													isSaving ||
													(showValidationErrors && !formIsValid)
												}
												loading={isSaving}
											/>
										</ConditionalContent>
									</GridItem>
								</GridContainer>
							</QuickCard>
						</GridItem>
						<GridItem xs={12}>
							<QuickCard title={"User Locations"} icon={<Person />}>
								<NavPills
									color="warning"
									alignCenter
									active={activeTabIndex}
									onTabChanges={handleTabChange}
									tabs={[
										{
											tabButton: "Territory Permissions",
											tabIcon: Person,
											tabContent: (
												<React.Fragment>
													<GridItem xs={12} align="right">
														<ButtonWithIcon
															icon={<Edit />}
															content="Edit"
															size="sm"
															color="warning"
															round
															onClick={handleShowAddLocation}
														/>
													</GridItem>
													<DynamicTable
														loading={isLoadingLocations}
														data={locations}
														columns={locationColumns}
														useClientSideDataProcessing={true}
														useClientSidePaging={false}
														style={styles.center}
													/>
												</React.Fragment>
											),
										},
										{
											tabButton: "Customer Sales Assignments",
											tabIcon: Person,
											tabContent: (
												<React.Fragment>
													<GridItem xs={12} align="right">
														<ButtonWithIcon
															icon={<Remove />}
															content="Move All Customers"
															size="sm"
															color="primary"
															round
															onClick={handleShowAddCustomer}
														/>
														<ButtonWithIcon
															icon={<AddIcon />}
															content="Add"
															size="sm"
															color="success"
															round
															onClick={handleShowAddCustomer}
														/>
													</GridItem>
													<DynamicTable
														loading={isLoadingLocations}
														data={salesCustomers}
														columns={[
															...customerColumns,
															{
																Header: "",
																accessor: viewDetailsButton,
																id: "actions",
																sortable: false,
																toggleable: false,
																maxWidth: 50,
															},
														]}
														useClientSideDataProcessing={true}
														useClientSidePaging={false}
														style={styles.center}
														onRowClick={handleShowRemoveCustomer}
													/>
												</React.Fragment>
											),
										},
									]}
								/>
							</QuickCard>
						</GridItem>
					</GridContainer>
				)}
			</LoadingWrapper>

			<Dialog
				show={addSalesCustomer}
				title={"Add Customer"}
				onCancel={handleCancelSalesCustomer}
				onConfirm={handleAddSalesCustomer}
				isConfirmationAsync={true}
				confirmBtnText="Update User"
				successTitle="Success"
				successContent="Customer Successfully Added"
				onSuccessConfirm={handleReloadCustomers}
				isSaving={isSaving}
				fullWidth={true}
			>
				<GridContainer>
					<GridItem xs={12}>
						<CustomerSelector
							onCustomerSelected={handleSaleCustomerSelected}
							title={"Ship To Site"}
						/>
					</GridItem>
				</GridContainer>
			</Dialog>

			<Dialog
				show={showAddLocation}
				title={"Add Locations"}
				onCancel={handleCancelAddLocation}
				onConfirm={handleAddLocation}
				isConfirmationAsync={true}
				confirmBtnText="Update User"
				successTitle="Success"
				successContent="Locations Successfully Added"
				onSuccessConfirm={handleReloadUser}
				isSaving={isSaving}
				fullWidth={true}
			>
				<GridContainer>
					<ConditionalContent show={user.is_customer === false}>
						<GridItem xs={12}>
							<ManyToOneEditor
								label="Territories"
								items={newUserTerritories}
								availableItems={availableTerritories}
								onDelete={onRemoveTerritory}
								onAdd={onAddTerritory}
								valueAccessor="_id"
								isEditing={true}
								textAccessor={(territory) =>
									`(${territory.code}) ${territory.description}`
								}
							/>
						</GridItem>
						<GridItem xs={3}>
							<ButtonWithIcon
								content="All"
								onClick={onRemoveAll}
								color="danger"
								size="sm"
								round
								icon={<Remove />}
							/>
						</GridItem>
						<GridItem xs={3}>
							<ButtonWithIcon
								content="All"
								onClick={onAddAll}
								color="success"
								size="sm"
								round
								icon={<AddIcon />}
							/>
						</GridItem>
						<GridItem xs={3}>
							<ButtonWithIcon
								content="Domestic"
								onClick={onAddDomestic}
								color="success"
								size="sm"
								round
								icon={<AddIcon />}
							/>
						</GridItem>
						<GridItem xs={3}>
							<ButtonWithIcon
								content="International"
								onClick={onAddInternational}
								color="success"
								size="sm"
								round
								icon={<AddIcon />}
							/>
						</GridItem>
					</ConditionalContent>
					<ConditionalContent show={user.is_customer === true}>
						<GridItem xs={12}>
							<ManyToOneEditor
								label="Customers"
								items={newUserCustomers}
								onDelete={handleRemoveCustomer}
								valueAccessor="location_id"
								isEditing={true}
								textAccessor={(customer) =>
									`(${customer.location_name}) ${customer.location_number}`
								}
							/>
						</GridItem>
						<GridItem xs={12}>
							<CustomerSelector
								onCustomerSelected={handleCustomerSelected}
								title={"Customer"}
								limitToNonServiceCustomer={true}
								autoFocus
							/>
						</GridItem>
						<GridItem xs={12} align="center">
							<FormControlLabel
								control={
									<Checkbox
										onClick={handleBillToUpdate}
										checked={billToUsed}
										classes={{
											checked: classes.checked,
										}}
									/>
								}
								classes={{
									label: classes.label,
								}}
								label="All Bill-To Locations"
							/>
						</GridItem>
					</ConditionalContent>
				</GridContainer>
			</Dialog>

			<Alert
				success
				show={displayActionSuccess}
				style={{ display: "block" }}
				title="Successfully Saved User"
				onCancel={onBackToList}
				cancelBtnText="Back to List"
				cancelBtnColor="info"
				onConfirm={onSuccessModalClose}
				confirmBtnText="Stay Here"
			>
				{"Where would you like to go now?"}
			</Alert>

			<Alert
				danger
				show={showRemoveCustomer}
				style={{ display: "block" }}
				title={"Remove Customer"}
				onConfirm={handleRemoveSalesCustomer}
				confirmBtnText="Remove"
				confirmBtnColor="success"
				onCancel={updateShowRemoveCustomer}
				cancelBtnText="Cancel"
				cancelBtnColor="warning"
			>
				{"Are you sure you want to remove customer?"}
			</Alert>

			<Alert
				danger
				show={displayActionFailure}
				style={{ display: "block" }}
				title="Failed to save User"
				onConfirm={onFailureModalClose}
				confirmBtnText="Close"
				confirmBtnColor="info"
			>
				{"Please try again. If the issue persists, contact support."}
			</Alert>
		</React.Fragment>
	)
}

UserContactTab.propTypes = {
	classes: PropTypes.object.isRequired,

	// input
	user: PropTypes.object.isRequired,

	isMyProfileView: PropTypes.bool.isRequired,
	isUserAdmin: PropTypes.bool.isRequired,
	isUserCustomer: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	onBackToList: PropTypes.func.isRequired,

	// editor stuff
	isEditing: PropTypes.bool.isRequired,
	isSaving: PropTypes.bool.isRequired,
	onStartEditingUser: PropTypes.func.isRequired,
	onCancelEditingUser: PropTypes.func.isRequired,
	onSaveUser: PropTypes.func.isRequired,
	showValidationErrors: PropTypes.bool.isRequired,
	formIsValid: PropTypes.bool.isRequired,

	displayActionSuccess: PropTypes.bool.isRequired,
	onSuccessModalClose: PropTypes.func.isRequired,
	displayActionFailure: PropTypes.bool.isRequired,
	onFailureModalClose: PropTypes.func.isRequired,

	// fields
	employeeNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	employeeNumberIsValid: PropTypes.bool,
	employeeNumberErrorMessage: PropTypes.string,
	onEmployeeNumberChange: PropTypes.func.isRequired,

	firstName: PropTypes.string,
	firstNameIsValid: PropTypes.bool,
	firstNameErrorMessage: PropTypes.string,
	onFirstNameChange: PropTypes.func.isRequired,

	lastName: PropTypes.string,
	lastNameIsValid: PropTypes.bool,
	lastNameErrorMessage: PropTypes.string,
	onLastNameChange: PropTypes.func.isRequired,

	email: PropTypes.string,
	emailIsValid: PropTypes.bool,
	emailErrorMessage: PropTypes.string,
	onEmailChange: PropTypes.func.isRequired,

	mobile: PropTypes.string,
	mobileIsValid: PropTypes.bool,
	mobileErrorMessage: PropTypes.string,
	onMobileChange: PropTypes.func.isRequired,

	lastLogin: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.instanceOf(Date),
		PropTypes.instanceOf(null),
	]),

	verisaeUsername: PropTypes.string,
	verisaeUsernameIsValid: PropTypes.bool,
	verisaeUsernameErrorMessage: PropTypes.string,
	onVerisaeUsernameChange: PropTypes.func.isRequired,

	effectiveDate: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.instanceOf(Date),
		PropTypes.instanceOf(null),
	]),
	effectiveDateIsValid: PropTypes.bool,
	effectiveDateErrorMessage: PropTypes.string,
	onEffectiveDateChange: PropTypes.func.isRequired,

	endDate: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.instanceOf(Date),
		PropTypes.instanceOf(null),
	]),
	endDateIsValid: PropTypes.bool,
	endDateErrorMessage: PropTypes.string,
	onEndDateChange: PropTypes.func.isRequired,
}

export default withStyles(styles)(UserContactTab)

import { ChangeEvent, FC, useState } from "react"

import { makeApiErrorMessage, useCustomerAssignmentBulkPost } from "@ncs/ncs-api"
import { Box, ExtendableModalProps, Modal, Paragraph, useToast } from "@ncs/web-legos"

/**
 * @returns A modal for bulk importing of a customer's user's roles.
 */
export const BulkImportPeopleAlignmentsModal: FC<ExtendableModalProps> = ({
	onClose,
	...rest
}) => {
	const { makeSuccessToast } = useToast()
	const [uploadedFile, setUploadedFile] = useState<File | null>(null)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const postBulkRdc = useCustomerAssignmentBulkPost()

	const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files

		if (files && files.length > 0) {
			setUploadedFile(files[0])

			// Clear out the error message if there was one there before.
			setErrorText(null)
		}
	}

	const importColumns = ["Customer Number (Required)", "User ID", "Role ID (Required)"]

	const submit = async () => {
		try {
			setIsSubmitting(true)
			setErrorText(null)
			if (!uploadedFile) throw new Error("No file selected")

			const formData = new FormData()
			formData.append("customer_data", uploadedFile)
			await postBulkRdc(formData)
			makeSuccessToast(
				"Import successful. Changes may take a few minutes to be reflected here."
			)
			onClose()
		} catch (e) {
			setErrorText(makeApiErrorMessage(e))
			setIsSubmitting(false)
		}
	}

	const reset = () => {
		setUploadedFile(null)
		setIsSubmitting(false)
		setErrorText(null)
	}

	return (
		<Modal
			{...rest}
			onOpen={reset}
			onClose={onClose}
			errorText={errorText}
			rightButtons={{
				buttonText: "Import",
				onClick: submit,
				disabled: !uploadedFile,
				isLoading: isSubmitting,
			}}
		>
			<Paragraph mb={1}>
				Upload an Excel spreadsheet (.xls, .xlsx) with rows of data in the following column
				order (header row is optional). Your columns should be:
			</Paragraph>

			<ol>
				{importColumns.map((column, index) => {
					return <li key={index}>{column}</li>
				})}
			</ol>

			<Box>
				<Paragraph>
					<strong>
						These changes will affect the entire customer row in the data, be certain
						of every field.
					</strong>
					<br />
					For example:
					<br />
					<br />
				</Paragraph>
				<Box
					alignItems="center"
					display="flex"
					flexDirection="row"
					justifyContent="flex-start"
					alignSelf="center"
					textAlign="center"
				>
					<Box b={2} height={1.5} width={4} textAlign="center">
						Customer
					</Box>
					<Box b={2} height={1.5} width={4} textAlign="center">
						User ID
					</Box>
					<Box b={2} height={1.5} width={4} textAlign="center">
						Role ID
					</Box>
				</Box>
				<Box
					alignItems="center"
					display="flex"
					flexDirection="row"
					justifyContent="flex-start"
					alignSelf="center"
					textAlign="center"
				>
					<Box b={2} height={1.5} width={4} textAlign="center">
						123456P
					</Box>
					<Box b={2} height={1.5} width={4} textAlign="center"></Box>
					<Box b={2} height={1.5} width={4} textAlign="center">
						1
					</Box>
				</Box>
				<br />
				<Paragraph>
					If a customer 123456P existed and had people assignments with the role ID 1,
					this row would remove all of the people assigned with that role.
				</Paragraph>
				<br />
				<Paragraph>
					Otherwise, if all values are provided, the user will be assigned to the
					customer with the specified role.
				</Paragraph>
			</Box>

			<Box my={2}>
				<input
					type="file"
					onChange={handleUpload}
					multiple={false}
					accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" // cspell:disable-line
				/>
			</Box>
		</Modal>
	)
}

import { FC, useEffect, useState } from "react"

import { Column } from "react-table"

import {
	LtlCarrierId,
	makeApiErrorMessage,
	PartOrderShipment,
	useAddPartOrderTracking,
} from "@ncs/ncs-api"
import { displayNumber, formatDate } from "@ncs/ts-utils"
import {
	Button,
	Divider,
	EmptyValueDash,
	ExtendableModalProps,
	FormattedNumber,
	GridContainer,
	GridItem,
	LabeledData,
	LtlCarrierSelector,
	Modal,
	Table,
	TextInput,
	useToast,
} from "@ncs/web-legos"

export interface ShipmentDetailModalProps extends ExtendableModalProps {
	shipment: PartOrderShipment
	partOrderId: string
}

export const ShipmentDetailModal: FC<ShipmentDetailModalProps> = ({
	shipment,
	partOrderId,
	...rest
}) => {
	const { makeSuccessToast } = useToast()
	const [showLines, setShowLines] = useState(false)
	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const [carrierId, setCarrierId] = useState<string | null>(
		shipment.inTransit?.carrier?.id ?? null
	)
	const [newCarrierName, setNewCarrierName] = useState<string | null>(null)
	const [waybill, setWaybill] = useState(shipment.inTransit?.waybill ?? null)
	const [newProNumber, setNewProNumber] = useState(shipment.proNumber ?? null)

	const updateTracking = useAddPartOrderTracking(partOrderId)

	const handleSave = async () => {
		try {
			if (!carrierId && !!waybill) {
				throw new Error("Carrier must be selected if providing tracking info")
			}
			if (carrierId === LtlCarrierId.Other && !newCarrierName) {
				throw new Error('Carrier name required when "Other" is selected')
			}

			setIsSaving(true)
			await updateTracking({
				shipment: shipment.shipmentId.toString(),
				shippingInfo: waybill,
				carrier: carrierId,
				newCarrier: newCarrierName,
				proNumber: newProNumber,
			})
			rest.onClose()
			makeSuccessToast("Part order updated")
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	useEffect(() => {
		setErrorText(null)
	}, [newCarrierName, carrierId])

	return (
		<Modal
			{...rest}
			title={`Shipment #${shipment.shipmentId}`}
			maxWidth="md"
			rightButtons={{
				buttonText: "Update",
				isLoading: isSaving,
				onClick: handleSave,
			}}
			errorText={errorText}
		>
			<GridContainer>
				<GridItem xs={12} sm={6}>
					<LtlCarrierSelector
						value={carrierId}
						onChange={setCarrierId}
						newCarrierValue={newCarrierName}
						newCarrierOnChange={setNewCarrierName}
						fillContainer
						mb={0}
					/>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<TextInput
						value={waybill}
						onChange={setWaybill}
						label="Tracking info"
						maxLength={50}
						mb={0}
					/>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<TextInput
						value={newProNumber}
						onChange={setNewProNumber}
						label="PRO number"
						maxLength={255}
						mb={0}
					/>
				</GridItem>
			</GridContainer>

			<Divider />

			<GridContainer mb={1} rowGap={0}>
				<GridItem xs={12} sm={6}>
					<LabeledData label="Shipped date">
						{shipment.inTransit?.shipDate ?
							formatDate(shipment.inTransit.shipDate, { formatInUtc: true })
						:	<EmptyValueDash />}
					</LabeledData>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<LabeledData label="Promised date">
						{shipment.inTransit?.promisedDate ?
							formatDate(shipment.inTransit.promisedDate, {
								formatInUtc: true,
							})
						:	<EmptyValueDash />}
					</LabeledData>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<LabeledData label="Estimated delivery date">
						{shipment.inTransit?.estimatedDeliveryDate ?
							formatDate(shipment.inTransit.estimatedDeliveryDate, {
								formatInUtc: true,
							})
						:	<EmptyValueDash />}
					</LabeledData>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<LabeledData label="Delivery date">
						{shipment.inTransit?.deliveryDate ?
							formatDate(shipment.inTransit.deliveryDate, {
								formatInUtc: true,
							})
						:	<EmptyValueDash />}
					</LabeledData>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<LabeledData label="Number of pallets">
						<FormattedNumber value={shipment.numberOfPallets} />
					</LabeledData>
				</GridItem>
			</GridContainer>

			{showLines ?
				<Table
					data={shipment.lines.sort((a, b) => {
						if (a.package != null && b.package != null) {
							return a.package.name.toUpperCase() > b.package.name.toUpperCase() ?
									1
								:	-1
						}
						return 0
					})}
					columns={columns}
					infiniteRowsIncrement={10}
				/>
			:	<Button icon="angle-down" onClick={() => setShowLines(true)}>
					Show shipment line items ({shipment.lines.length})
				</Button>
			}
		</Modal>
	)
}

type ShipmentLine = PartOrderShipment["lines"][number]

const columns: Column<ShipmentLine>[] = [
	{
		Header: "Part #",
		accessor: "partNumber",
	},
	{
		Header: "Description",
		accessor: "description",
	},
	{
		Header: "Qty",
		accessor: ({ quantity }) => displayNumber(quantity),
	},
	{
		Header: "Package name",
		accessor: ({ package: linePackage }) => linePackage?.name || "-",
	},
	{
		Header: "Package weight",
		accessor: ({ package: linePackage }) => displayNumber(linePackage?.weight),
	},
	{
		Header: "Package size",
		accessor: ({ package: linePackage }) => {
			if (linePackage?.packageItem) {
				const { name, height, width, length } = linePackage.packageItem

				return `${name} (${height}x${width}x${length})`
			} else {
				return "-"
			}
		},
	},
]

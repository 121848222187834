import React from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import callApi from "@ncs/bricks/redux/services/callApi"
import { BaseSelectFilter, withDynamicTableFilter } from "@ncs/bricks/components"
import { loadLookups, LOOKUPS } from "../../redux/services/lookups"

class TicketTypeFilter extends React.PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			types: [],
		}
	}

	componentDidMount() {
		this.props.loadLookups([LOOKUPS.TicketTypes], this.filterTicketType)
	}

	// filter types whenever the group changes
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.getGroupFromReportState(prevProps) !== this.getGroupFromReportState(this.props)) {
			this.filterTicketType()
		}
	}

	getGroupFromReportState = (props) => {
		return (((props.reportState || {}).filtered || []).find((x) => x.id === "group") || {})
			.value
	}

	filterTicketType = () => {
		let group = this.getGroupFromReportState(this.props)

		let types = this.props.types.filter((x) => x.group_id === Number(group))
		this.setState({
			types,
		})

		// if the currently selected location is not in the results, erase the selected location
		if (this.props.value && !types.some((t) => t.id.toString() === this.props.value)) {
			this.props.onChange("")
		}
	}

	render() {
		return (
			<BaseSelectFilter
				id="ticketType"
				labelText="Ticket Type"
				options={[
					{
						id: "",
						description: "All Ticket Types",
					},
					...this.state.types,
				]}
				valueAccessor="id"
				textAccessor={(x) => x.description}
				{...this.props}
			/>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		types: state.lookups.ticketTypes,
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			callApi,
			loadLookups,
		},
		dispatch
	)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withDynamicTableFilter("ticket_type")(TicketTypeFilter))

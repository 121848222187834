import { FC, useState } from "react"

import {
	Dispatch,
	makeApiErrorMessage,
	UpdateDispatchPatch,
	useDispatchWorkorders,
	useUpdateDispatch,
	useWorkOrderInvoice,
} from "@ncs/ncs-api"
import { displayDate, yesOrNo } from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	Box,
	Button,
	Callout,
	DownloadDocumentButton,
	EditBooleanModal,
	EditBooleanModalProps,
	EmptyValueDash,
	GridContainer,
	GridItem,
	GridItemProps,
	HeadingDivider,
	Icon,
	infoCalloutBackgroundColor,
	LabeledData,
	LabeledDataWithButton,
	LoadingSpinner,
	Paragraph,
	Tooltip,
	useToast,
} from "@ncs/web-legos"

import { UserSelectorModal } from "~/components"

import { EditCallTypeModal, EditPriorityModal, EditStatusModal } from "./components"

export interface HeaderDetailsProps {
	dispatch: Dispatch
}

export const HeaderDetails: FC<HeaderDetailsProps> = ({ dispatch }) => {
	const { makeSuccessToast, makeErrorToast } = useToast()
	const [expanded, setExpanded] = useState(false)
	const [editingCallType, setEditingCallType] = useState(false)
	const [editingPriority, setEditingPriority] = useState(false)
	const [editingTech, setEditingTech] = useState(false)
	const [editingStatus, setEditingStatus] = useState(false)
	const [editBoolConfig, setEditBoolConfig] = useState<EditBooleanModalProps | null>(null)

	const [workOrders, workOrdersLoading] = useDispatchWorkorders(dispatch.id)
	const [invoice, invoiceLoading] = useWorkOrderInvoice(dispatch.invoice?.id)
	const updateDispatch = useUpdateDispatch()

	const handleSave = async <K extends ModalEditableKeys>(
		key: K,
		value: UpdateDispatchPatch[K]
	) => {
		try {
			await updateDispatch({
				id: dispatch.id,
				updates: { [key]: value },
			})
			makeSuccessToast("Dispatch updated")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		}
	}

	const gridItemProps: GridItemProps = {
		xs: 12,
		sm: 6,
		md: 3,
	}

	return (
		<Callout variant="info" fillContainer>
			<GridContainer>
				<GridItem {...gridItemProps}>
					<LabeledData label="Customer">
						({dispatch.customer.customerNumber}) {dispatch.customer.name}
					</LabeledData>
				</GridItem>
				<GridItem {...gridItemProps}>
					<LabeledDataWithButton
						label="Dispatch status"
						onClick={() => setEditingStatus(true)}
					>
						Dispatch: <strong>{dispatch.status || "No status found"}</strong>
					</LabeledDataWithButton>
				</GridItem>
				<GridItem {...gridItemProps}>
					<LabeledData label="Invoice status">
						Invoice: <strong>{dispatch.invoice?.status || <EmptyValueDash />}</strong>
					</LabeledData>
				</GridItem>
				<GridItem {...gridItemProps}>
					<LabeledDataWithButton
						label="Tech assigned"
						onClick={() => setEditingTech(true)}
					>
						{dispatch.assignedEmployee?.name || <EmptyValueDash />}
					</LabeledDataWithButton>
				</GridItem>
			</GridContainer>

			<AnimatedEntrance show={expanded} direction="down" mt={1}>
				<GridContainer rowGap={1.5}>
					<GridItem {...gridItemProps}>
						<LabeledDataWithButton
							label="Priority"
							onClick={() => setEditingPriority(true)}
						>
							{dispatch.priority || <EmptyValueDash />}
						</LabeledDataWithButton>
					</GridItem>
					<GridItem {...gridItemProps}>
						<LabeledDataWithButton
							label="Call type"
							onClick={() => setEditingCallType(true)}
						>
							{dispatch.callType || <EmptyValueDash />}
						</LabeledDataWithButton>
					</GridItem>
					<GridItem {...gridItemProps}>
						<LabeledDataWithButton
							label="Return call?"
							onClick={() =>
								setEditBoolConfig({
									initialSelection: dispatch.isReturnCall,
									title: "Edit Return Call",
									description: "Is this dispatch a return call?",
									onSave: (newValue) => handleSave("isReturnCall", newValue),
									onClose: () => setEditBoolConfig(null),
								})
							}
						>
							{yesOrNo(dispatch.isReturnCall)}
						</LabeledDataWithButton>
					</GridItem>
					<GridItem {...gridItemProps}>
						<LabeledDataWithButton
							label="Out of chemicals?"
							onClick={() =>
								setEditBoolConfig({
									initialSelection: dispatch.outOfChemicals,
									title: "Edit Out Of Chemicals",
									description: "Is the dispatch customer out of chemicals?",
									onSave: (newValue) => handleSave("outOfChemicals", newValue),
									onClose: () => setEditBoolConfig(null),
								})
							}
						>
							{yesOrNo(dispatch.outOfChemicals)}
						</LabeledDataWithButton>
					</GridItem>
					<GridItem {...gridItemProps}>
						<LabeledData label="Closed date">
							{displayDate(dispatch.closedDate, null) || <EmptyValueDash />}
						</LabeledData>
					</GridItem>
					<GridItem {...gridItemProps}>
						<LabeledData label="Closed reason">
							{dispatch.closedReason || <EmptyValueDash />}
						</LabeledData>
					</GridItem>
				</GridContainer>

				<GridContainer>
					<GridItem xs={12} md={6} lg={4}>
						<HeadingDivider
							variant="h5"
							my={1}
							backgroundColor={infoCalloutBackgroundColor}
						>
							Work Order Documents
						</HeadingDivider>
						{workOrdersLoading && <LoadingSpinner />}
						{workOrdersLoading === false &&
							(workOrders ?? []).every((w) => !w.documents.length) && (
								<Paragraph small opacity={0.6}>
									No work order documents yet
								</Paragraph>
							)}
						{(workOrders ?? [])
							.sort((a, b) => (a.openDate > b.openDate ? -1 : 1))
							.map((workOrder) => {
								return (
									<Box key={workOrder.id}>
										{workOrder.documents.map((doc, i) => {
											return (
												<Box
													key={doc.id}
													d="flex"
													alignItems="flex-start"
													flexWrap="wrap"
													columnGap={0.5}
													mb={0.5}
												>
													<DownloadDocumentButton
														documentId={doc.id}
														documentType={doc.type}
														buttonText={`Work order #${
															workOrder.workorderNumber
														} document - ${i + 1}`}
													/>
													{workOrder.waaSignatureObtained && (
														<Tooltip
															title={`Signed by ${workOrder.waaSignatureName}`}
															icon="check"
														>
															<Box
																d="flex"
																gap={0.25}
																alignItems="center"
																flexWrap="nowrap"
															>
																<Icon icon="check" color="gray" />
																<Paragraph small secondary>
																	Signed
																</Paragraph>
															</Box>
														</Tooltip>
													)}
													{!!workOrder.waaNoSignatureReason && (
														<Paragraph small secondary>
															No signature reason:{" "}
															{workOrder.waaNoSignatureReason}
														</Paragraph>
													)}
												</Box>
											)
										})}
									</Box>
								)
							})}
					</GridItem>
					<GridItem xs={12} md={6} lg={4}>
						<HeadingDivider
							variant="h5"
							my={1}
							backgroundColor={infoCalloutBackgroundColor}
						>
							Invoice Documents
						</HeadingDivider>
						{invoiceLoading && <LoadingSpinner />}
						{invoiceLoading === false && !invoice?.invoiceDoc.length && (
							<Paragraph small opacity={0.6}>
								No invoice documents yet
							</Paragraph>
						)}
						{invoice?.invoiceDoc.map((doc, i) => {
							return (
								<Box key={doc.id} mb={0.5}>
									<DownloadDocumentButton
										documentId={doc.id}
										documentType={doc.type}
										buttonText={`Invoice document - ${i + 1}`}
									/>
								</Box>
							)
						})}
					</GridItem>
					<GridItem xs={12} md={6} lg={4}>
						<HeadingDivider
							variant="h5"
							my={1}
							backgroundColor={infoCalloutBackgroundColor}
						>
							Dispatch Documents
						</HeadingDivider>
						{!dispatch.dispatchDocs.length && (
							<Paragraph small opacity={0.6}>
								No dispatch documents yet
							</Paragraph>
						)}
						{dispatch.dispatchDocs.map((doc, i) => {
							return (
								<Box key={doc.id} mb={0.5}>
									<DownloadDocumentButton
										documentId={doc.id}
										documentType={doc.type}
										buttonText={`Dispatch document - ${i + 1}`}
									/>
								</Box>
							)
						})}
					</GridItem>
				</GridContainer>

				<Box textAlign="center" mt={2}>
					<Button icon="angle-up" onClick={() => setExpanded(false)}>
						Show less
					</Button>
				</Box>
			</AnimatedEntrance>

			{expanded === false && (
				<Box textAlign="center">
					<Button
						icon="angle-right"
						onClick={() => setExpanded(true)}
						containerProps={{ mt: 1 }}
					>
						Show more details &amp; documents
					</Button>
				</Box>
			)}

			{editingCallType && (
				<EditCallTypeModal dispatch={dispatch} onClose={() => setEditingCallType(false)} />
			)}
			{editingPriority && (
				<EditPriorityModal dispatch={dispatch} onClose={() => setEditingPriority(false)} />
			)}
			{editingStatus && (
				<EditStatusModal dispatch={dispatch} onClose={() => setEditingStatus(false)} />
			)}
			{editingTech && (
				<UserSelectorModal
					title="Edit Tech Assigned"
					initialId={dispatch.assignedEmployeeId?.toString()}
					employeesOnly
					onSave={async (user) => {
						if (user) {
							await handleSave("assignedEmployeeId", user.id)
						}
					}}
					onClose={() => setEditingTech(false)}
				/>
			)}
			{!!editBoolConfig && <EditBooleanModal {...editBoolConfig} />}
		</Callout>
	)
}

type ModalEditableKeys = keyof Pick<
	UpdateDispatchPatch,
	"callTypeId" | "isReturnCall" | "outOfChemicals" | "assignedEmployeeId"
>

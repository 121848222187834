import { get, patch, rsaaTypes, RSAA } from "@ncs/bricks/middleware/portalApiMiddleware"

export const GET_TERRITORY_BUDGETS = rsaaTypes("finance/territory/budgets/list")
export const GET_TECHNICIAN_COST = rsaaTypes("finance/technician/cost/list")
export const PATCH_TERRITORY_BUDGET = rsaaTypes("finance/territory/budgets/patch")
export const PATCH_TECHNICIAN_COST = rsaaTypes("finance/technician/cost/patch")

export const getTerritoryBudgets = (querystring_params) => ({
	[RSAA]: {
		api: get("/reports/budgets/territory/", querystring_params),
		types: GET_TERRITORY_BUDGETS,
		parseLinkHeader: true,
	},
})

export const getTechnicianCosts = (querystring_params) => ({
	[RSAA]: {
		api: get("/reports/budgets/technician/", querystring_params),
		types: GET_TECHNICIAN_COST,
		parseLinkHeader: true,
	},
})

export const patchTerritoryBudget = (territory_budget_id, data) => {
	return {
		[RSAA]: {
			api: patch(`/reports/budgets/territory/${territory_budget_id}/`, data),
			types: PATCH_TERRITORY_BUDGET,
		},
	}
}

export const patchTechnicianCost = (technician_cost_id, data) => {
	return {
		[RSAA]: {
			api: patch(`/reports/budgets/technician/${technician_cost_id}/`, data),
			types: PATCH_TECHNICIAN_COST,
		},
	}
}

const initialState = {
	isLoadingTerritoryBudgets: false,
	territoryBudgets: [],
	isLoadingTechnicianCosts: false,
	technicianCosts: [],
}

const budget = (state = initialState, action) => {
	switch (action.type) {
		case GET_TERRITORY_BUDGETS.request:
			return {
				...state,
				isLoadingTerritoryBudgets: true,
			}
		case GET_TERRITORY_BUDGETS.success:
			return {
				...state,
				isLoadingTerritoryBudgets: false,
				territoryBudgets: action.payload,
			}
		case GET_TERRITORY_BUDGETS.failure:
			return {
				...state,
				isLoadingTerritoryBudgets: false,
			}

		case GET_TECHNICIAN_COST.request:
			return {
				...state,
				isLoadingTechnicianCosts: true,
			}
		case GET_TECHNICIAN_COST.success:
			return {
				...state,
				isLoadingTechnicianCosts: false,
				technicianCosts: action.payload,
			}
		case GET_TECHNICIAN_COST.failure:
			return {
				...state,
				isLoadingTechnicianCosts: false,
			}
		case PATCH_TERRITORY_BUDGET.request:
		case PATCH_TERRITORY_BUDGET.success:
		case PATCH_TERRITORY_BUDGET.failure:
		case PATCH_TECHNICIAN_COST.request:
		case PATCH_TECHNICIAN_COST.success:
		case PATCH_TECHNICIAN_COST.failure:
		default:
			return state
	}
}

export default budget

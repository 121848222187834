import { FC } from "react"

import { Column } from "react-table"

import {
	ChemicalContainerDetail,
	ChemicalCustomerDetail,
	useChemicalContainerDetail,
} from "@ncs/ncs-api"
import { displayNumber, formatDateTime, getTimezoneAbbreviation } from "@ncs/ts-utils"
import { Table } from "@ncs/web-legos"

export interface HistoryTabProps {
	container: ChemicalCustomerDetail["containers"][number]
}

export const HistoryTab: FC<HistoryTabProps> = ({ container }) => {
	const [containerDetail, containerDetailLoading] = useChemicalContainerDetail(container.id)

	return (
		<>
			<Table
				data={containerDetail?.history ?? []}
				columns={columns}
				isLoading={containerDetailLoading}
			/>
		</>
	)
}

const columns: Column<ChemicalContainerDetail["history"][number]>[] = [
	{
		Header: `Submitted (${getTimezoneAbbreviation()})`,
		accessor: ({ createdOn }) => formatDateTime(createdOn),
	},
	{
		Header: "Name",
		accessor: ({ creator }) => creator,
	},
	{
		Header: "Gallons",
		accessor: ({ gallonsInContainer }) => displayNumber(gallonsInContainer),
	},
	{
		Header: "Pump",
		accessor: ({ pumpSetting }) => pumpSetting,
	},
	{
		Header: "Amount backstocked",
		accessor: ({ amountBackstocked }) => displayNumber(amountBackstocked),
	},
	{
		Header: "Tip",
		accessor: ({ tip }) => tip,
	},
	{
		Header: "Titration drops",
		accessor: ({ titrationDrops }) => displayNumber(titrationDrops),
	},
]

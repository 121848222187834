import { FC, useMemo, useState } from "react"

import { useQuickOrders } from "@ncs/ncs-api"
import {
	AnimatedEntrance,
	Button,
	Callout,
	Card,
	Divider,
	Heading,
	Icon,
	Label,
	Paragraph,
	QuickOrderSelector,
	RecentItems,
	Tabs,
	useUrlState,
} from "@ncs/web-legos"

import { EditQuickOrderModal } from "./EditQuickOrderModal"
import { QuickOrdersPartsTab } from "./QuickOrdersPartsTab"
import { QuickOrdersSitesTab } from "./QuickOrdersSitesTab"

export enum QuickOrdersTabs {
	Products = "Products",
	AssignedSites = "Assigned Sites",
}

export type QuickOrdersUrlState = {
	tab: QuickOrdersTabs
	orderId: string | null
}

const QuickOrders: FC = () => {
	const [{ orderId, tab }, { updateUrlValue, setUrlState }] = useUrlState<QuickOrdersUrlState>({
		orderId: null,
		tab: QuickOrdersTabs.Products,
	})
	const [showEditModal, setShowEditModal] = useState(false)
	const [showNewModal, setShowNewModal] = useState(false)

	const [orders] = useQuickOrders()
	const order = useMemo(() => {
		return (orders ?? []).find((o) => o.id.toString() === orderId) ?? null
	}, [orderId, orders])

	return (
		<Card
			heading="Site Quick Orders"
			headingIcon="cart-shopping-fast"
			renderRight={() => {
				return (
					<Button icon="plus-circle" onClick={() => setShowNewModal(true)}>
						Create new Quick Order
					</Button>
				)
			}}
		>
			<Paragraph mb={2}>
				Set up Quick Orders for users to simplify getting their frequently ordered products
				to their sites that use them. Shown on the Quick Orders page of the Customer
				Portal.
			</Paragraph>

			<QuickOrderSelector
				value={order}
				onChange={(selection) =>
					updateUrlValue("orderId", selection?.id.toString() ?? null)
				}
				label="Select Quick Order"
				maxWidth={30}
				initialId={orderId}
			/>
			<RecentItems
				item={order}
				itemLabelAccessor={(item) => item.description}
				onSelect={(item) => updateUrlValue("orderId", item.id.toString())}
				compareFn={(a, b) => a.id === b.id}
			/>

			<Divider fullCardBleed my={3} />

			{order ?
				<AnimatedEntrance show>
					<Callout variant="info" pr={4}>
						<Label>Quick Order name</Label>
						<Heading variant="h2" mb={2}>
							{order.description}
						</Heading>
						<Button icon="pencil" onClick={() => setShowEditModal(true)}>
							Edit
						</Button>
					</Callout>
					<Tabs
						currentTab={tab}
						onChange={(newTab) => updateUrlValue("tab", newTab)}
						panels={[
							{
								navLabel: QuickOrdersTabs.Products,
								component: <QuickOrdersPartsTab order={order} />,
								navIcon: <Icon icon="tools" />,
							},
							{
								navLabel: QuickOrdersTabs.AssignedSites,
								component: <QuickOrdersSitesTab order={order} />,
								navIcon: <Icon icon="garage-car" family="solid" />,
							},
						]}
					/>
				</AnimatedEntrance>
			:	<Heading textAlign="center" icon="search" py={5} opacity={0.25}>
					Select a Quick Order above to begin...
				</Heading>
			}

			<EditQuickOrderModal
				isOpen={showEditModal || showNewModal}
				onClose={() => {
					setShowEditModal(false)
					setShowNewModal(false)
				}}
				order={order}
				isNew={showNewModal}
				setUrlState={setUrlState}
			/>
		</Card>
	)
}

export default QuickOrders

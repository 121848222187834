import React from "react"

import * as Sentry from "@sentry/react"
import { connect } from "react-redux"
import { Redirect, Route, Switch, useLocation } from "react-router-dom"

import { useAuth } from "@ncs/ncs-api"
import { isAuthenticated, userId } from "@ncs/bricks/redux/selectors"
import { ClientUpdater, LoadingSpinner, useChangeCallback } from "@ncs/web-legos"

import { ProtectedRoute } from "~/components"
import indexRoutes from "~/routes"
import ForgotPasswordPage from "~/views/Auth/ForgotPasswordPage"
import ResetPasswordPage from "~/views/Auth/ResetPasswordPage"

import LoginPageContainer from "./LoginPageContainer"

const LoginRedirectionRouter = ({ isAuthenticated: isAuthenticatedLegacy, userId }) => {
	const location = useLocation()

	// New authentication system for ncs-api.
	const auth = useAuth()

	const isAuthenticated = !!auth.user && isAuthenticatedLegacy

	// When the user ID changes, update Sentry accordingly.
	useChangeCallback(userId, (newUserId) => {
		Sentry.configureScope((scope) => {
			scope.setUser(newUserId ? { id: newUserId } : null)
		})
	})

	const redirectWhenAuthenticated = (Component) => {
		return isAuthenticated ?
				<Redirect to={location.state?.from?.pathname || "/dashboard"} />
			:	<Component />
	}

	// The auth system's not initialized until we know if you have a valid session or not.
	if (!auth.initialized) {
		return <LoadingSpinner />
	}

	return (
		<>
			<ClientUpdater />

			<Switch>
				<Route
					exact
					path="/login"
					render={() => redirectWhenAuthenticated(LoginPageContainer)}
				/>
				<Route
					exact
					path="/login/forgot-password"
					render={() => redirectWhenAuthenticated(ForgotPasswordPage)}
				/>
				<Route
					exact
					path="/login/forgot-password/:token"
					render={() => redirectWhenAuthenticated(ResetPasswordPage)}
				/>

				{indexRoutes.map((prop, key) => {
					return (
						<ProtectedRoute
							isAuthenticated={isAuthenticated}
							path={prop.path}
							exact={prop.exact}
							component={prop.component}
							key={key}
						/>
					)
				})}
			</Switch>
		</>
	)
}

const mapStateToProps = (state) => ({
	isAuthenticated: isAuthenticated(state),
	userId: userId(state),
})

export default connect(mapStateToProps, null)(LoginRedirectionRouter)

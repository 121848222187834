import { FC, useMemo, useState } from "react"

import dayjs from "dayjs"
import { Cell, Column } from "react-table"

import {
	AccessorialPrice,
	AccessorialPricesQueryParams,
	APPLICATION,
	useAccessorialPrices,
	useUserCanUse,
} from "@ncs/ncs-api"
import {
	dateRangeIsActive,
	displayDate,
	formatCurrency,
	getTimezoneAbbreviation,
	yesOrNo,
} from "@ncs/ts-utils"
import {
	Box,
	Button,
	EmptyValueDash,
	Icon,
	ReactTableSortType,
	Table,
	Tooltip,
	useUrlState,
} from "@ncs/web-legos"

import { ActiveOnlyQueryFilter } from "~/components"

import { EditAccessorialModal } from "./components"

export type AccessorialsTabUrlState = AccessorialPricesQueryParams

export const AccessorialsTab: FC = () => {
	const canEdit = useUserCanUse(APPLICATION.FreightRatesAdmin)
	const [showCreate, setShowCreate] = useState(false)
	const [toEdit, setToEdit] = useState<AccessorialPrice | null>(null)

	const [params, { setUrlState: setParams }] =
		useUrlState<AccessorialsTabUrlState>(defaultUrlState)

	const [prices, priceLoading] = useAccessorialPrices({ params })

	const sortedPrices = useMemo(() => {
		return (prices ?? []).sort((a, b) => {
			if (a.description === b.description) {
				return a.startDate > b.startDate ? -1 : 1
			}

			return a.description > b.description ? 1 : -1
		})
	}, [prices])

	return (
		<>
			{canEdit && (
				<Box d="flex" justifyContent="flex-end" mb={4}>
					<Button
						variant="secondary-cta"
						icon="plus-circle"
						onClick={() => setShowCreate(true)}
					>
						Create Accessorial Charge
					</Button>
				</Box>
			)}

			<Table
				data={sortedPrices}
				columns={columns}
				isLoading={priceLoading}
				queryParamState={params}
				setQueryParamState={setParams}
				toggledQueryFilters={[ActiveOnlyQueryFilter]}
				onRowClick={canEdit ? ({ original }) => setToEdit(original) : undefined}
			/>

			{(showCreate || !!toEdit) && (
				<EditAccessorialModal
					toEdit={toEdit}
					onClose={() => {
						setShowCreate(false)
						setToEdit(null)
					}}
				/>
			)}
		</>
	)
}

const columns: Column<AccessorialPrice>[] = [
	{
		Header: "Line item",
		accessor: "description",
	},
	{
		Header: "Rate",
		accessor: "rate",
		Cell: ({ row: { original } }: Cell<AccessorialPrice>) => formatCurrency(original.rate),
		sortType: ReactTableSortType.Number,
	},
	{
		Header: "Excluded from free freight",
		hiddenByDefault: true,
		accessor: ({ freeFreightExcluded }) => yesOrNo(freeFreightExcluded),
	},
	{
		Header: `Start (${getTimezoneAbbreviation()})`,
		accessor: ({ startDate }) => new Date(startDate),
		Cell: ({ row: { original } }: Cell<AccessorialPrice>) => displayDate(original.startDate),
		sortType: ReactTableSortType.Datetime,
	},
	{
		Header: `End (${getTimezoneAbbreviation()})`,
		accessor: ({ endDate }) => new Date(endDate),
		Cell: ({ row: { original } }: Cell<AccessorialPrice>) => displayDate(original.endDate),
		sortType: ReactTableSortType.Datetime,
	},
	{
		Header: "Status",
		Cell: ({ row: { original } }: Cell<AccessorialPrice>) => {
			return (
				dateRangeIsActive(original.startDate, original.endDate) ?
					<>
						<Icon icon="check" /> Active
					</>
				: dayjs(original.endDate).isBefore(original.startDate) ?
					<Tooltip title="Date range can never be active because end date comes before start date">
						<Icon icon="exclamation-triangle" />
					</Tooltip>
				:	<EmptyValueDash />
			)
		},
	},
	{
		Header: "Created by",
		accessor: "userCreated",
		hiddenByDefault: true,
	},
	{
		Header: `Created (${getTimezoneAbbreviation()})`,
		accessor: ({ createdOn }) => new Date(createdOn),
		Cell: ({ row: { original } }: Cell<AccessorialPrice>) => displayDate(original.createdOn),
		sortType: ReactTableSortType.Datetime,
		hiddenByDefault: true,
	},
	{
		Header: "Last modified by",
		accessor: "userLastModified",
		hiddenByDefault: true,
	},
	{
		Header: `Modified (${getTimezoneAbbreviation()})`,
		accessor: ({ modifiedOn }) => new Date(modifiedOn),
		Cell: ({ row: { original } }: Cell<AccessorialPrice>) => displayDate(original.modifiedOn),
		sortType: ReactTableSortType.Datetime,
		hiddenByDefault: true,
	},
]

const defaultUrlState: AccessorialsTabUrlState = {
	active: null,
}

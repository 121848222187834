import { FC, useEffect, useState } from "react"

export interface ImageFileReaderProps {
	file: File | null
	alt?: string
}

export const ImageFileReader: FC<ImageFileReaderProps> = ({
	file,
	alt = "Uploaded image preview",
}) => {
	const [src, setSrc] = useState<string | null>(null)

	useEffect(() => {
		if (file) {
			const reader = new FileReader()
			reader.onload = (e) => {
				const fileSrc = e.target?.result
				if (fileSrc && typeof fileSrc === "string") {
					setSrc(fileSrc)
				}
			}
			reader.readAsDataURL(file)
		} else {
			setSrc(null)
		}
	}, [file])

	if (!src) {
		return null
	}

	// TODO: Maybe better to make this file just a util that returns the src, so
	// that we can funnel that directly into part image (or wherever)?
	return <img src={src} alt={alt} />
}

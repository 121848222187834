import { ChangeEvent, FC, useEffect, useState } from "react"

import { css } from "@emotion/react"

import { Box, BoxProps } from "../layout"

export interface EditableCellProps extends BoxProps {
	value: string
	onChange: (newValue: string | null) => void
	placeholder?: string
	className?: string
}

export const EditableCell: FC<EditableCellProps> = ({
	value: initialValue,
	onChange: updateFn,
	placeholder,
	className,
	...rest
}) => {
	// Internal text value state.
	const [value, setValue] = useState(initialValue)

	const onChange = (e: ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value)
	}

	// We'll only update the external data when the input is blurred.
	const onBlur = () => {
		updateFn(value || null)
	}

	// If the initialValue is changed external, sync it up with our state.
	useEffect(() => {
		setValue(initialValue)
	}, [initialValue])

	return (
		<Box {...rest}>
			<input
				value={value}
				onChange={onChange}
				onBlur={onBlur}
				css={inputCss}
				className={className}
				placeholder={placeholder}
			/>
		</Box>
	)
}

const inputCss = css`
	width: 100%;
`

import React from "react"
import PropTypes from "prop-types"
import startCase from "lodash/startCase"

import { GridItem } from "../../components"

import FormField from "./FormField"

class FormValidatorField extends React.Component {
	render = () => {
		const { isEditingStateKey, field, formValidator, xs, sm, md, lg, xl, ...rest } = this.props
		const container = formValidator.container
		const config =
			formValidator.validations[field] ??
			formValidator.validations.find((x) => x.stateName === field)
		if (!config) {
			throw new Error(`FormValidator does not contain a configuration for ${field}`)
		}
		const { labelText, type, stateName, editorProps, ...configProps } = config

		const inner = (
			<FormField
				type={type ?? "string"}
				isEditing={container.state[isEditingStateKey]}
				labelText={labelText ?? startCase(stateName)}
				value={container.state[stateName]}
				isReadOnly={formValidator.isFieldReadOnly(field)}
				onChange={formValidator.handleInputChanged(stateName, type)}
				showValidationErrors={container.state["showValidationErrors"]}
				isValid={container.state[stateName + "IsValid"]}
				errorMessage={container.state[stateName + "ErrorMessage"]}
				{...configProps}
				{...editorProps}
				{...rest}
			/>
		)

		const useEditorPropsWidths = [
			editorProps?.xs,
			editorProps?.sm,
			editorProps?.md,
			editorProps?.lg,
			editorProps?.xl,
		].some((x) => !!x)
		const widths = {
			xs: useEditorPropsWidths ? editorProps?.xs : xs,
			sm: useEditorPropsWidths ? editorProps?.sm : sm,
			md: useEditorPropsWidths ? editorProps?.md : md,
			lg: useEditorPropsWidths ? editorProps?.lg : lg,
			xl: useEditorPropsWidths ? editorProps?.xl : xl,
		}
		const widthIsDefined = Object.values(widths).some((x) => !!x)
		return !widthIsDefined ? inner : <GridItem {...widths}>{inner}</GridItem>
	}
}

FormValidatorField.defaultProps = {
	isEditingStateKey: "isEditing",
}

const gridItemWidthPropType = PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12])
FormValidatorField.propTypes = {
	/** formValidator instance controlling this form field */
	formValidator: PropTypes.object.isRequired,

	/** field name as defined in the validations collection of formValidator */
	field: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,

	/** key to the prop in container's state that holds editing status, defaults to isEditing */
	isEditingStateKey: PropTypes.string,

	xs: gridItemWidthPropType,
	sm: gridItemWidthPropType,
	md: gridItemWidthPropType,
	lg: gridItemWidthPropType,
	xl: gridItemWidthPropType,
}

export default FormValidatorField

import {
	del,
	get,
	patch,
	post,
	put,
	RSAA,
	rsaaTypes,
} from "@ncs/bricks/middleware/portalApiMiddleware"

export const GET_INVOICE = rsaaTypes("invoice/get")
export const LIST_INVOICES = rsaaTypes("invoices/list")
export const LIST_INVOICE = rsaaTypes("invoice/list")
export const PATCH_INVOICE = rsaaTypes("invoice/patch")
export const LIST_INVOICE_LINE_ITEMS = rsaaTypes("invoice/line-items/list")
export const POST_INVOICE_LINE_ITEM = rsaaTypes("invoice/line-item/post")
export const PUT_INVOICE_LINE_ITEM = rsaaTypes("invoice/line-item/put")
export const BULK_INVOICE_LINE_ITEM_NON_BILLABLE = rsaaTypes(
	"invoice/bulk-line-item-non-billable/patch"
)
export const DELETE_INVOICE_LINE_ITEM = rsaaTypes("invoice/line-item/delete")
export const POST_INVOICE_HISTORY = rsaaTypes("invoice/history/post")
export const APPROVE_INVOICE = rsaaTypes("invoice/approve/post")
export const REJECT_INVOICE = rsaaTypes("invoice/reject/post")
export const REBILL_INVOICE = rsaaTypes("invoice/rebill/post")
export const CREATE_CREDIT_MEMO = rsaaTypes("invoice/credit_memo/post")
export const FINALIZE_INVOICE = rsaaTypes("invoice/finalize/post")
export const PRINT_INVOICES = rsaaTypes("invoice/print/post")
export const REQUEST_KBM_INVOICE = rsaaTypes("invoice/request_kbm_invoice")

// New Invoice API that will eventually replace /workorders/invoices
export const listInvoice = (querystring_params) => ({
	[RSAA]: {
		api: get(`/invoice/`, querystring_params),
		types: LIST_INVOICE,
		parseLinkHeader: true,
	},
})

export const listInvoices = (querystring_params) => ({
	[RSAA]: {
		api: get(`/workorders/invoices/`, querystring_params),
		types: LIST_INVOICES,
	},
})

export const getInvoice = (invoiceId) => ({
	[RSAA]: {
		api: get(`/workorders/invoices/${invoiceId}/`),
		types: GET_INVOICE,
	},
})

export const patchInvoice = (invoiceId, data) => ({
	[RSAA]: {
		api: patch(`/workorders/invoices/${invoiceId}/`, data),
		types: PATCH_INVOICE,
	},
})

export const listInvoiceLineItems = (invoiceId) => ({
	[RSAA]: {
		api: get(`/workorders/invoices/${invoiceId}/line_items/`),
		types: LIST_INVOICE_LINE_ITEMS,
	},
})

export const createInvoiceLineItem = (invoiceId, data) => ({
	[RSAA]: {
		api: post(`/workorders/invoices/${invoiceId}/line_items/`, data),
		types: POST_INVOICE_LINE_ITEM,
	},
})

export const updateInvoiceLineItem = (invoiceId, line_item_id, data) => ({
	[RSAA]: {
		api: put(`/workorders/invoices/${invoiceId}/line_items/${line_item_id}/`, data),
		types: PUT_INVOICE_LINE_ITEM,
	},
})

export const makeInvoiceLineItemsNonBillable = (invoiceId) => ({
	[RSAA]: {
		api: patch(`/workorders/invoices/${invoiceId}/bulk_non_billable/`),
		types: BULK_INVOICE_LINE_ITEM_NON_BILLABLE,
	},
})

export const deleteInvoiceLineItem = (invoiceId, lineItemId) => ({
	[RSAA]: {
		api: del(`/workorders/invoices/${invoiceId}/line_items/${lineItemId}/`),
		types: DELETE_INVOICE_LINE_ITEM,
	},
})

export const createInvoiceHistory = (invoiceId, data) => ({
	[RSAA]: {
		api: post(`/workorders/invoices/${invoiceId}/history/`, data),
		types: POST_INVOICE_HISTORY,
	},
})

export const createInvoiceNote = (invoice, note) => {
	let data = {
		invoice_id: invoice._id,
		rejection_reason: note,
	}

	return createInvoiceHistory(invoice._id, data)
}

export const approveInvoice = (invoiceId) => ({
	[RSAA]: {
		api: post(`/workorders/invoices/${invoiceId}/approve/`, null),
		types: APPROVE_INVOICE,
	},
})

export const rejectInvoice = (invoiceId, data) => ({
	[RSAA]: {
		api: post(`/workorders/invoices/${invoiceId}/reject/`, data),
		types: REJECT_INVOICE,
	},
})

export const rebillInvoice = (invoiceId, creditMemoData) => ({
	[RSAA]: {
		api: post(`/workorders/invoices/${invoiceId}/rebill/`, creditMemoData),
		types: REBILL_INVOICE,
	},
})

export const createCreditMemo = (invoiceId, creditMemoData) => ({
	[RSAA]: {
		api: post(`/workorders/invoices/${invoiceId}/credit_memo/`, creditMemoData),
		types: CREATE_CREDIT_MEMO,
	},
})

export const finalizeInvoice = (invoiceId) => ({
	[RSAA]: {
		api: post(`/workorders/invoices/${invoiceId}/finalize/`, null),
		types: FINALIZE_INVOICE,
	},
})
export const printInvoices = (invoices) => ({
	[RSAA]: {
		api: post(`/invoice/invoice_print/`, invoices),
		types: PRINT_INVOICES,
	},
})

export const INVOICE_LIST_TYPES = {
	BY_TERRITORY: "invoices/by_territory",
	BY_CUSTOMER: "invoices/by_customer",
	SINGLE_CUSTOMER: "invoices/single_customer",
}

export const requestKbmInvoice = () => ({
	[RSAA]: {
		api: post(`/invoice/queue_kbm_invoice/`, null),
		types: REQUEST_KBM_INVOICE,
	},
})

const initialState = {
	isLoading: true,
	isLoadingInvoices: true,
	isLoadingCustomerInvoices: true,
	invoicesByTerritory: [],
	invoicesByCustomer: [],
	customerInvoices: [],
	invoice: {},
	invoiceListType: INVOICE_LIST_TYPES.BY_CUSTOMER,
}

const invoices = (state = initialState, action) => {
	switch (action.type) {
		case LIST_INVOICES.request:
			return {
				...state,
				isLoadingInvoices: true,
			}
		case LIST_INVOICES.success:
			return {
				...state,
				isLoadingInvoices: false,
				invoices: action.payload,
			}

		case GET_INVOICE.request:
			return {
				...state,
				isLoadingInvoice: true,
			}
		case GET_INVOICE.success:
			return {
				...state,
				isLoadingInvoice: false,
				invoice: action.payload,
			}

		case LIST_INVOICE_LINE_ITEMS.request:
			return {
				...state,
				isLoadingInvoiceLineItems: true,
			}
		case LIST_INVOICE_LINE_ITEMS.success:
			return {
				...state,
				isLoadingInvoiceLineItems: false,
				lineItems: action.payload,
			}

		case PATCH_INVOICE.request:
		case POST_INVOICE_LINE_ITEM.request:
		case PUT_INVOICE_LINE_ITEM.request:
		case DELETE_INVOICE_LINE_ITEM.request:
		case POST_INVOICE_HISTORY.request:
		case APPROVE_INVOICE.request:
		case REJECT_INVOICE.request:
		case REBILL_INVOICE.request:
		case CREATE_CREDIT_MEMO.request:
		case FINALIZE_INVOICE.request:
		case PRINT_INVOICES.request:
		case REQUEST_KBM_INVOICE.request:
			console.log("print invoices here")
			return {
				...state,
				isSaving: true,
			}

		case PATCH_INVOICE.success:
		case POST_INVOICE_LINE_ITEM.success:
		case PUT_INVOICE_LINE_ITEM.success:
		case DELETE_INVOICE_LINE_ITEM.success:
		case POST_INVOICE_HISTORY.success:
		case APPROVE_INVOICE.success:
		case REJECT_INVOICE.success:
		case REBILL_INVOICE.success:
		case CREATE_CREDIT_MEMO.success:
		case FINALIZE_INVOICE.success:
		case PRINT_INVOICES.success:
		case REQUEST_KBM_INVOICE.success:
			return {
				...state,
				isSaving: false,
			}

		default:
			return state
	}
}
export default invoices

import { Dispatch, ReactElement, SetStateAction } from "react"

import { SelectBoolean } from "@ncs/web-legos"

export interface VehicleCareParamState {
	vehicleCare: true | null
}

export interface VehicleCareQueryFilterProps<QueryParamState extends VehicleCareParamState> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

export const VehicleCareQueryFilter = <QueryParamState extends VehicleCareParamState>({
	queryParamState,
	setQueryParamState,
}: VehicleCareQueryFilterProps<QueryParamState>): ReactElement => {
	return (
		<SelectBoolean
			value={queryParamState.vehicleCare === true}
			yesText="Vehicle Care customers only"
			noText="—"
			showNoSelectionOption={false}
			noFirst
			fillContainer
			label="Vehicle care"
			onChange={(newValue) =>
				setQueryParamState((prev) => {
					// This filter should only let us send TRUE to get just vehicle care customers.
					const newState: VehicleCareParamState = {
						vehicleCare: newValue || null,
					}

					return {
						...prev,
						...newState,
					}
				})
			}
		/>
	)
}

import { Dispatch, ReactElement, SetStateAction } from "react"

import { Select } from "../inputs"

interface IncludeFutureOrdersQueryParams {
	futureOrders: boolean | null
}

export interface IncludeFutureOrdersQueryFilterProps<
	QueryParamState extends IncludeFutureOrdersQueryParams,
> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

export const IncludeFutureOrdersQueryFilter = <
	QueryParamState extends IncludeFutureOrdersQueryParams,
>({
	queryParamState,
	setQueryParamState,
}: IncludeFutureOrdersQueryFilterProps<QueryParamState>): ReactElement => {
	return (
		<Select
			value={queryParamState.futureOrders === false ? "false" : null}
			options={options}
			noSelectionOptionText="Include all future orders"
			showNoSelectionOption
			disableNoSelectionOption={false}
			label="Future-dated orders"
			fillContainer
			onChange={(value) =>
				setQueryParamState((prev) => {
					const newState: IncludeFutureOrdersQueryParams = {
						futureOrders: value === "false" ? false : null,
					}

					return {
						...prev,
						...newState,
					}
				})
			}
		/>
	)
}

const options = [
	{
		text: "Exclude unless within 7 days",
		value: "false",
	},
]

import { FC } from "react"

import { ExtendableSelectProps, Select } from "@ncs/web-legos"

export type IncludeUnusedSelectorProps = ExtendableSelectProps<(typeof options)[number]>

export const IncludeUnusedSelector: FC<IncludeUnusedSelectorProps> = ({ ...rest }) => {
	return (
		<Select
			label="Include unused?"
			showNoSelectionOption={false}
			{...rest}
			options={options}
		/>
	)
}

const options = [
	{
		value: "true",
		booleanValue: true,
		text: "Include unused",
	},
	{
		value: "false",
		booleanValue: false,
		text: "Exclude unused",
	},
]

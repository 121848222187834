import { FC, useState } from "react"

import { useHistory } from "react-router-dom"
import { Column } from "react-table"

import { ChemicalCustomer, ChemicalCustomerQueryParams, useChemicalCustomers } from "@ncs/ncs-api"
import { Typify } from "@ncs/ts-utils"
import {
	Button,
	Card,
	CustomerQueryFilter,
	InventoryPartQueryFilter,
	ReactTableSortType,
	Table,
	TerritoryQueryFilter,
	useUrlState,
} from "@ncs/web-legos"

import { AddChemicalToCustomerModal } from "~/components"

export type CustomerChemicalsUrlState = Typify<ChemicalCustomerQueryParams>

export const CustomerChemicals: FC = () => {
	const history = useHistory<{ params: CustomerChemicalsUrlState }>()
	const [showAddModal, setShowAddModal] = useState(false)
	const [params, { setUrlState }] = useUrlState<CustomerChemicalsUrlState>({
		...defaultQueryParams,
	})

	const [customers, customersLoading] = useChemicalCustomers({ params })

	return (
		<Card heading="Customer Chemicals" headingIcon="car-wash">
			<Button
				icon="plus-circle"
				buttonText="Create chemical container"
				variant="secondary-cta"
				onClick={() => setShowAddModal(true)}
			/>

			<Table
				data={customers ?? []}
				columns={columns}
				isLoading={customersLoading}
				queryParamState={params}
				setQueryParamState={setUrlState}
				toggledQueryFilters={[
					TerritoryQueryFilter,
					CustomerQueryFilter,
					InventoryPartQueryFilter,
				]}
				showToggledFiltersByDefault={Object.values(params).some((p) => !!p)}
				onRowClick={({ original }) => {
					history.push({
						pathname: `/customers/customer-chemicals/${original.customerId}`,
						state: { params },
					})
				}}
			/>

			{showAddModal && <AddChemicalToCustomerModal onClose={() => setShowAddModal(false)} />}
		</Card>
	)
}

const columns: Column<ChemicalCustomer>[] = [
	{
		Header: "Customer #",
		accessor: ({ customerNumber }) => customerNumber,
	},
	{
		Header: "Name",
		accessor: ({ customerName }) => customerName,
	},
	{
		Header: "Chemical containers count",
		sortType: ReactTableSortType.Number,
		accessor: ({ chemicalCount }) => chemicalCount,
	},
]

const defaultQueryParams: ChemicalCustomerQueryParams = {
	customer: null,
	part: null,
	territory: null,
}

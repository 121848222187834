import React from "react"
import { compose } from "redux"
import { InputAdornment, withStyles } from "@material-ui/core"
import { Search } from "@material-ui/icons"

import { Input } from "../CustomInput"
import { withCallApi } from "../Util"

import withFilter from "./withDynamicTableFilter"

const styles = {
	inputAdornmentIcon: {
		color: "#555",
	},
}

class SearchFilter extends React.PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			value: props.value || "",
		}
	}

	componentDidMount() {
		const { initialValue } = this.props

		if (initialValue) {
			this.setState({ value: initialValue }, () =>
				this.props.onChange(initialValue, `Search=${initialValue}`)
			)
		}
	}

	componentDidUpdate(_prevProps, _prevState, _snapshot) {
		if (this.state.value !== this.props.value) {
			this.setState({ value: this.props.value })
		}
	}

	handleChange = (value) => {
		this.setState({ value }, () => this.props.onChange(value, `Search=${value}`))
	}

	render = () => (
		<Input
			id="search"
			labelText="Search"
			value={this.state.value}
			inputProps={{
				autoFocus: true,
				placeholder: this.props.placeholder || "Search all fields...",
				onChange: (e) => this.handleChange(e.target.value),
				startAdornment: (
					<InputAdornment position="start">
						<Search className={this.props.classes.inputAdornmentIcon} />
					</InputAdornment>
				),
			}}
		/>
	)
}

export default compose(withCallApi, withStyles(styles), withFilter("search"))(SearchFilter)

import React, { useCallback } from "react"
import PropTypes from "prop-types"
import {
	Accordion as CoreAccordion,
	AccordionSummary,
	AccordionDetails,
	makeStyles,
	AccordionProps as CoreAccordionProps,
} from "@material-ui/core"
import { ExpandMore } from "@material-ui/icons"

import styles from "../../assets/jss/material-dashboard-pro-react/components/accordionStyle"

const useStyles = makeStyles(styles)

export interface AccordionProps {
	/** Index of the default active collapse */
	active?: number
	collapses: {
		title: string
		content: React.ReactNode
	}[]
	onActiveIndexChange?: ((index: number) => void) | null
}

const Accordion: React.FC<AccordionProps> = ({
	active = 1,
	collapses,
	onActiveIndexChange = null,
}) => {
	const [activeIndex, setActiveIndex] = React.useState(active)
	const classes = useStyles()

	const handleChange = useCallback(
		(panel: number): CoreAccordionProps["onChange"] =>
			(event, expanded) => {
				const newIndex = expanded ? panel : -1
				setActiveIndex(newIndex)
				if (typeof onActiveIndexChange === "function") {
					onActiveIndexChange(newIndex)
				}
			},
		[onActiveIndexChange]
	)

	return (
		<div className={classes.root}>
			{collapses.map((prop, key) => {
				return (
					<CoreAccordion
						expanded={activeIndex === key}
						onChange={handleChange(key)}
						key={key}
						classes={{
							root: classes.expansionPanel,
							expanded: classes.expansionPanelExpanded,
						}}
					>
						<AccordionSummary
							expandIcon={<ExpandMore />}
							classes={{
								root: classes.expansionPanelSummary,
								expanded: classes.expansionPanelSummaryExpanded,
								content: classes.expansionPanelSummaryContent,
								expandIcon: classes.expansionPanelSummaryExpandIcon,
							}}
						>
							<h4 className={classes.title}>{prop.title}</h4>
						</AccordionSummary>
						<AccordionDetails className={classes.expansionPanelDetails}>
							{prop.content}
						</AccordionDetails>
					</CoreAccordion>
				)
			})}
		</div>
	)
}

Accordion.propTypes = {
	// index of the default active collapse
	active: PropTypes.number,
	collapses: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			content: PropTypes.node.isRequired,
		}).isRequired
	).isRequired,
	onActiveIndexChange: PropTypes.func,
}

export default Accordion

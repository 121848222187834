import { ChangeEvent, FC, useState } from "react"

import { makeApiErrorMessage, useRdcPartBulkPost } from "@ncs/ncs-api"
import { Box, ExtendableModalProps, Modal, Paragraph, useToast } from "@ncs/web-legos"

export interface BulkImportPartsModalProps extends ExtendableModalProps {
	formDataId: string
}

/**
 * @param formDataId An ID for the form data that can be null.
 * @returns A modal for bulk importing of parts.
 */
export const BulkImportPartsModal: FC<BulkImportPartsModalProps> = ({
	formDataId,
	onClose,
	...rest
}) => {
	const { makeSuccessToast } = useToast()
	const [uploadedFile, setUploadedFile] = useState<File | null>(null)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const postBulkRdc = useRdcPartBulkPost()

	const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files

		if (files && files.length > 0) {
			setUploadedFile(files[0])

			// Clear out the error message if there was one there before.
			setErrorText(null)
		}
	}

	const importColumns = [
		"Part number (required)",
		"Part name (required)",
		"Min location level",
		"Econ order quantity",
		"Min order quantity",
		"Max location level",
		"Lead time in days",
		"Supplier code",
		"Supplier part number",
		"Supplier part description",
		"Supplier cost",
	]

	const submit = async () => {
		try {
			setIsSubmitting(true)
			setErrorText(null)
			if (!uploadedFile) throw new Error("No file selected")

			const formData = new FormData()
			formData.append("location_id", formDataId)
			formData.append("part_data", uploadedFile)
			await postBulkRdc(formData)
			makeSuccessToast(
				"Import successful. Changes may take a few minutes to be reflected here."
			)
			onClose()
		} catch (e) {
			setErrorText(makeApiErrorMessage(e))
			setIsSubmitting(false)
		}
	}

	const reset = () => {
		setUploadedFile(null)
		setIsSubmitting(false)
		setErrorText(null)
	}

	return (
		<Modal
			{...rest}
			onOpen={reset}
			onClose={onClose}
			errorText={errorText}
			rightButtons={{
				buttonText: "Import",
				onClick: submit,
				disabled: !uploadedFile,
				isLoading: isSubmitting,
			}}
		>
			<Paragraph mb={1}>
				Upload an Excel spreadsheet (.xls, .xlsx) with rows of data in the following column
				order (header row is optional). Your columns should be:
			</Paragraph>

			<ol>
				{importColumns.map((column, index) => {
					return <li key={index}>{column}</li>
				})}
			</ol>

			<Box>
				<Paragraph mb={1}>
					Part relationships that already exist at the location will be updated,
					otherwise they will be created.
				</Paragraph>
				<Paragraph mb={1}>
					If a supplier code that is not Grimes (1) or CSI (87) is given, then{" "}
					<strong>Supplier part number</strong>,{" "}
					<strong>Supplier part description</strong>, and <strong>Supplier cost</strong>{" "}
					are all required.
				</Paragraph>
			</Box>

			<Box my={2}>
				<input
					type="file"
					onChange={handleUpload}
					multiple={false}
					accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" // cspell:disable-line
				/>
			</Box>
		</Modal>
	)
}

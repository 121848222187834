import { FC } from "react"

import { Cell, Column } from "react-table"

import {
	EquipmentVintage,
	EquipmentVintageWorkOrder,
	useEquipmentVintageWorkOrders,
} from "@ncs/ncs-api"
import { displayDateTime, getTimezoneAbbreviation } from "@ncs/ts-utils"
import { EmptyValueDash, ExtendableModalProps, Heading, Link, Modal, Table } from "@ncs/web-legos"

export interface VintageWorkOrdersModalProps extends ExtendableModalProps {
	vintage: EquipmentVintage
}

export const VintageWorkOrdersModal: FC<VintageWorkOrdersModalProps> = ({ vintage, ...rest }) => {
	const query = useEquipmentVintageWorkOrders(vintage.id, {
		pageSize: 25,
	})

	return (
		<Modal {...rest} title="Vintage Work Orders" maxWidth="md">
			<Heading mt={1} mb={2}>
				Vintage: <strong>{vintage.name}</strong>
			</Heading>

			<Table query={query} columns={columns} disableAllSorting />
		</Modal>
	)
}

const columns: Column<EquipmentVintageWorkOrder>[] = [
	{
		Header: "Worker order #",
		accessor: ({ workorder }) => workorder?.workorderNumber || <EmptyValueDash />,
	},
	{
		Header: "Dispatch",
		Cell: ({ row: { original } }: Cell<EquipmentVintageWorkOrder>) => (
			<Link to={`/service/dispatches/${original.dispatch.id}`} newTab>
				#{original.dispatch.dispatchNumber}
			</Link>
		),
	},
	{
		Header: `Call received (${getTimezoneAbbreviation()})`,
		accessor: ({ dispatch }) =>
			displayDateTime(dispatch.callReceived, "") || <EmptyValueDash />,
	},
	{
		Header: "Customer",
		Cell: ({ row: { original } }: Cell<EquipmentVintageWorkOrder>) => (
			<Link to={`/customer-management/${original.dispatch.customer.id}`} newTab>
				({original.dispatch.customer.customerNumber}) {original.dispatch.customer.name}
			</Link>
		),
	},
]

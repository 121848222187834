import { ReactElement, useEffect, useState } from "react"

import {
	Control,
	Controller,
	FieldError,
	FieldValues,
	Path,
	PathValue,
	UnpackNestedValue,
} from "react-hook-form"

import { UserMinimal, useUser } from "@ncs/ncs-api"

import { usePrevious } from "../../util"
import { UserSelector, UserSelectorProps } from "../selectors"

export interface UserSelectorFormFieldProps<TFieldValues extends FieldValues>
	extends Omit<UserSelectorProps, "value" | "onChange"> {
	control: Control<TFieldValues>
	name: Path<TFieldValues>
	emptyValueFallback?: string | null
}

export const UserSelectorFormField = <TFieldValues extends FieldValues>({
	name,
	control,
	...rest
}: UserSelectorFormFieldProps<TFieldValues>): ReactElement => {
	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { onBlur, value, onChange }, fieldState: { error } }) => {
				return (
					<UserSelectorFormFieldRender
						{...rest}
						formValue={value}
						formOnChange={onChange}
						formOnBlur={onBlur}
						formError={error}
					/>
				)
			}}
		/>
	)
}

interface UserSelectorFormFieldRenderProps<TFieldValues extends FieldValues>
	extends Omit<UserSelectorProps, "value" | "onChange"> {
	formValue: UnpackNestedValue<PathValue<TFieldValues, Path<TFieldValues>>>
	formOnChange: (newValue: string | null) => void
	formOnBlur?: () => void
	formError?: FieldError
	emptyValueFallback?: string | null
}

const UserSelectorFormFieldRender = <TFieldValues extends FieldValues>({
	formValue,
	formOnChange,
	formOnBlur,
	formError,
	emptyValueFallback = null,
	...rest
}: UserSelectorFormFieldRenderProps<TFieldValues>): ReactElement => {
	const [selectedUser, setSelectedUser] = useState<UserMinimal | null>(null)

	// Change the form as selectedUser changes. Watching selectedUser for changes
	// guarantees that the form will never be changed "ahead" of our local state.
	const prevUserId = usePrevious(selectedUser?.id)
	useEffect(() => {
		if (selectedUser?.id !== prevUserId) {
			formOnChange(selectedUser?.id ?? emptyValueFallback)
		}
	}, [selectedUser?.id, prevUserId, formOnChange, emptyValueFallback])

	// If we ever get ourselves in the situation where the form has an ID but its
	// not the ID of the user we have in our local state, that means something
	// changed the form's state upstream (rather than via this component).
	// In that case, do a search for that ID so we can set our local state
	// manually in order to match.
	const [shouldSetManually, setShouldSetManually] = useState(false)
	const [userToSet] = useUser(formValue, {
		queryConfig: {
			enabled: !!formValue && shouldSetManually,
		},
	})

	// Listen for when we should change our local state based on what we're getting
	// from the form.
	const prevFormValue = usePrevious(formValue)
	useEffect(() => {
		if (!formValue && prevFormValue && selectedUser?.id) {
			setSelectedUser(null)
		} else if (formValue && formValue !== prevFormValue && formValue !== selectedUser?.id) {
			setShouldSetManually(true)
		}
	}, [formValue, prevFormValue, selectedUser?.id])

	useEffect(() => {
		if (shouldSetManually && userToSet) {
			setSelectedUser(userToSet)
			setShouldSetManually(false)
		}
	}, [userToSet, setShouldSetManually, shouldSetManually])

	return (
		<UserSelector
			{...rest}
			onBlur={formOnBlur}
			value={selectedUser}
			onChange={setSelectedUser}
			error={formError?.message}
		/>
	)
}

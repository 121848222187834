import React from "react"
import moment from "moment"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { withStyles } from "@material-ui/core"
import { NewReleases } from "@material-ui/icons"

import { warningColor } from "../../assets/jss/material-dashboard-pro-react"
import { ButtonWithIcon, ConditionalContent, TimeAgo } from "../../components"
import { forceReload } from "../../redux/services/clientVersion"

const styles = {
	updateTimer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		fontSize: "1em",
		color: warningColor[0],
	},
	updateButton: {
		margin: 0,
	},
}

class UpdateCountdown extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			showForcedReloadTimer: null,
			forcedReloadTime: null,
		}
	}

	componentDidMount = () => {
		if (this.reloadTimer) {
			clearInterval(this.reloadTimer)
		}

		this.reloadTimer = setInterval(this.checkForReloadTimer, 1000)
	}

	componentWillUnmount = () => {
		if (this.reloadTimer) {
			clearInterval(this.reloadTimer)
		}
	}

	checkForReloadTimer = () => {
		if (!this.props.forcedReloadCountdown) {
			return
		}

		let forcedReloadTime = moment(this.props.forcedReloadCountdown).add(
			this.props.usingExtraTimeBeforeReload ? 5 : 15,
			"minutes"
		)

		if (moment() > forcedReloadTime) {
			this.props.forceReload()
		}

		if (
			!this.state.showForcedReloadTimer ||
			forcedReloadTime !== this.state.forcedReloadTime
		) {
			this.setState({
				forcedReloadTime,
				showForcedReloadTimer:
					this.props.usingExtraTimeBeforeReload ||
					moment().diff(this.props.forcedReloadCountdown, "minutes") >= 5,
			})
		}
	}

	render() {
		const { classes } = this.props

		return (
			<ConditionalContent show={this.state.showForcedReloadTimer}>
				{() => (
					<div className={classes.updateTimer}>
						<span>
							Updating <TimeAgo date={this.state.forcedReloadTime} />
						</span>
						<ButtonWithIcon
							content="Update Now"
							icon={<NewReleases />}
							onClick={this.props.forceReload}
							round
							size="sm"
							color="warning"
							className={classes.updateButton}
						/>
					</div>
				)}
			</ConditionalContent>
		)
	}
}

const mapStateToProps = ({ clientVersion }) => ({
	...clientVersion,
})

const mapDispatchToProps = (dispatch) => bindActionCreators({ forceReload }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UpdateCountdown))

import { Dispatch, ReactElement, SetStateAction, useState } from "react"

import { EquipmentModel } from "@ncs/ncs-api"

import { useChangeCallback } from "../../util"
import { EquipmentModelSelector } from "../selectors"

interface EquipmentModelQueryParams {
	model: string | null
}

export interface EquipmentModelQueryFilterProps<
	QueryParamState extends EquipmentModelQueryParams,
> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
	label?: string
	manufacturer?: string | null | undefined
	onSelect?: (selection: EquipmentModel | null) => void
}

export const EquipmentModelQueryFilter = <QueryParamState extends EquipmentModelQueryParams>({
	queryParamState,
	setQueryParamState,
	label,
	manufacturer,
	onSelect,
}: EquipmentModelQueryFilterProps<QueryParamState>): ReactElement => {
	const [selectedModel, setSelectedModel] = useState<EquipmentModel | null>(null)

	useChangeCallback(selectedModel, (newSelectedModel) => {
		// Set query param state with just the EquipmentModel ID.
		setQueryParamState((prev) => ({
			...prev,
			model: newSelectedModel?.id ?? null,
		}))
		// If an onSelect side effect was passed in, fire it here.
		if (onSelect) {
			onSelect(newSelectedModel)
		}
	})

	// Handle the user clicking Reset button.
	useChangeCallback(queryParamState.model, (newId, prevId) => {
		if (!newId && !!prevId && !!selectedModel) {
			setSelectedModel(null)
		}
	})

	return (
		<EquipmentModelSelector
			value={selectedModel}
			onChange={setSelectedModel}
			fillContainer
			label={label}
			manufacturer={manufacturer}
			initialId={queryParamState.model}
		/>
	)
}

import {
	ApiDeleteMutation,
	ApiGetQuery,
	ApiGetQueryOptions,
	ApiPatchMutation,
	ApiPostMutation,
	useDeleteRequest,
	useGetRequest,
	usePatchRequest,
	usePostRequest,
} from "../../request-hooks"
import {
	Contract,
	ContractDocument,
	ContractIncentives,
	ContractIncentivesQueryParams,
	ContractQueryParams,
	ContractRecipient,
	ContractRecipientsQueryParams,
	ContractSearchResult,
	ContractUrlPath,
	CreateContractDocumentPost,
	CreateContractIncentivesPost,
	CreateContractPost,
	CreateContractRecipientsPost,
	CreateCustomerCreditPost,
	CreateSpentCreditPost,
	CustomerCredit,
	DeleteContractIncentives,
	DeleteContractRecipient,
	DeleteSpentCredit,
	ExpireCustomerCreditPatch,
	SentContractNotification,
	SentContractNotificationQueryParams,
	SpentContractCredit,
	SpentContractCreditQueryParams,
	UpdateContractIncentivesPatch,
	UpdateContractPatch,
} from "./types"

export const useOrganizationContract = (
	contractId: string | null | undefined
): ApiGetQuery<Contract> => {
	return useGetRequest<Contract, ContractQueryParams>(
		[ContractUrlPath.Contracts, ContractUrlPath.Entity],
		{
			params: {
				contract: contractId,
			},
			queryConfig: {
				enabled: !!contractId,
			},
		}
	)
}

export const useOrganizationContracts = (
	options?: ApiGetQueryOptions<ContractSearchResult[], ContractQueryParams>
): ApiGetQuery<ContractSearchResult[]> => {
	return useGetRequest([ContractUrlPath.Contracts, ContractUrlPath.List], options)
}

export const defaultContractsQueryParams: ContractQueryParams = {
	contract: null,
	search: null,
	type: null,
	coverageType: null,
}

// Currently the backend doesn't actually create these on a contract entity POST.
// Instead, create without them, get the ID back, and then call their respective
// POST calls individually. Remove this if that changes.
type CreateContractPostWithoutRelations = Omit<
	CreateContractPost,
	"rebates" | "credits" | "discounts" | "documents" | "recipients"
>

export const useCreateOrganizationContract = (): ApiPostMutation<
	CreateContractPostWithoutRelations,
	number
> => {
	return usePostRequest([ContractUrlPath.Contracts, ContractUrlPath.Entity], {
		keyToInvalidate: ContractUrlPath.Contracts,
	})
}

export const useUpdateOrganizationContract = (): ApiPatchMutation<UpdateContractPatch> => {
	return usePatchRequest([ContractUrlPath.Contracts, ContractUrlPath.Entity], {
		keyToInvalidate: ContractUrlPath.Contracts,
	})
}

export const useContractIncentives = (
	contractId: string | null | undefined
): ApiGetQuery<ContractIncentives> => {
	return useGetRequest<ContractIncentives, ContractIncentivesQueryParams>(
		[ContractUrlPath.Contracts, ContractUrlPath.Incentives],
		{
			params: {
				contract: contractId ?? undefined,
			},
			queryConfig: {
				enabled: !!contractId,
			},
		}
	)
}

export const useCreateContractIncentives = (): ApiPostMutation<CreateContractIncentivesPost> => {
	return usePostRequest([ContractUrlPath.Contracts, ContractUrlPath.Incentives], {
		keyToInvalidate: ContractUrlPath.Contracts,
	})
}

export const useUpdateContractIncentives = (): ApiPatchMutation<UpdateContractIncentivesPatch> => {
	return usePatchRequest([ContractUrlPath.Contracts, ContractUrlPath.Incentives], {
		keyToInvalidate: ContractUrlPath.Contracts,
	})
}

export const useDeleteContractIncentives = (): ApiDeleteMutation<DeleteContractIncentives> => {
	return useDeleteRequest([ContractUrlPath.Contracts, ContractUrlPath.Incentives], {
		keyToInvalidate: ContractUrlPath.Contracts,
	})
}

export const useContractDocuments = (
	contractId: string | null | undefined
): ApiGetQuery<ContractDocument[]> => {
	return useGetRequest([ContractUrlPath.Contracts, ContractUrlPath.Document], {
		params: {
			contract: contractId,
		},
		queryConfig: {
			enabled: !!contractId,
		},
	})
}

export const useCreateContractDocument = (): ((
	payload: CreateContractDocumentPost
) => Promise<void>) => {
	const create = usePostRequest<FormData>(
		[ContractUrlPath.Contracts, ContractUrlPath.Document],
		{
			keyToInvalidate: ContractUrlPath.Contracts,
		}
	)

	const handleCreate = async (payload: CreateContractDocumentPost): Promise<void> => {
		const data = new FormData()
		data.append("file", payload.file)
		data.append("description", payload.description)
		data.append("contract", payload.contract)

		await create(data)
	}

	return handleCreate
}

export const useContractRecipients = (contractId: string): ApiGetQuery<ContractRecipient[]> => {
	return useGetRequest<ContractRecipient[], ContractRecipientsQueryParams>(
		[ContractUrlPath.Contracts, ContractUrlPath.Recipients],
		{
			params: {
				contract: contractId,
			},
		}
	)
}

export const useCreateContractRecipients = (): ApiPostMutation<CreateContractRecipientsPost> => {
	return usePostRequest([ContractUrlPath.Contracts, ContractUrlPath.Recipients], {
		keyToInvalidate: ContractUrlPath.Contracts,
	})
}

export const useDeleteContractRecipient = (): ApiDeleteMutation<DeleteContractRecipient> => {
	return useDeleteRequest([ContractUrlPath.Contracts, ContractUrlPath.Recipients], {
		keyToInvalidate: ContractUrlPath.Contracts,
	})
}

export const useSentContractNotifications = (
	contractId: string
): ApiGetQuery<SentContractNotification[]> => {
	return useGetRequest<SentContractNotification[], SentContractNotificationQueryParams>(
		[ContractUrlPath.Contracts, ContractUrlPath.ContactHistory],
		{
			params: {
				contract: contractId,
			},
		}
	)
}

export const useSpentContractCredits = (creditId: string): ApiGetQuery<SpentContractCredit[]> => {
	return useGetRequest<SpentContractCredit[], SpentContractCreditQueryParams>(
		[ContractUrlPath.Contracts, ContractUrlPath.CreditHistory],
		{
			params: {
				credit: creditId,
			},
		}
	)
}

export const useCreateSpentCredit = (): ApiPostMutation<CreateSpentCreditPost> => {
	return usePostRequest([ContractUrlPath.Contracts, ContractUrlPath.CreditHistory], {
		keyToInvalidate: ContractUrlPath.Contracts,
	})
}

export const useDeleteSpentCredit = (): ApiDeleteMutation<DeleteSpentCredit> => {
	return useDeleteRequest([ContractUrlPath.Contracts, ContractUrlPath.CreditHistory], {
		keyToInvalidate: ContractUrlPath.Contracts,
	})
}

export const useCustomerCredits = (
	customerId: string | null | undefined
): ApiGetQuery<CustomerCredit[]> => {
	return useGetRequest(
		[ContractUrlPath.Contracts, ContractUrlPath.Customer, ContractUrlPath.Credits],
		{
			params: {
				customer: customerId,
			},
			queryConfig: {
				enabled: !!customerId,
			},
		}
	)
}

export const useCreateCustomerCredit = (): ApiPostMutation<CreateCustomerCreditPost> => {
	return usePostRequest(
		[ContractUrlPath.Contracts, ContractUrlPath.Customer, ContractUrlPath.Credits],
		{
			keyToInvalidate: ContractUrlPath.Contracts,
		}
	)
}

export const useExpireCustomerCredit = (): ApiPatchMutation<ExpireCustomerCreditPatch> => {
	return usePatchRequest(
		[ContractUrlPath.Contracts, ContractUrlPath.Customer, ContractUrlPath.Credits],
		{
			keyToInvalidate: ContractUrlPath.Contracts,
		}
	)
}

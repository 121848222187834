import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import noop from "lodash/noop"
import sortBy from "lodash/sortBy"
import startCase from "lodash/startCase"
import memoizeOne from "memoize-one"
import { ReactSortable, Sortable } from "react-sortablejs"
import { Add, Check, Delete, Edit, Remove } from "@material-ui/icons"
import { createStyles, WithStyles, withStyles } from "@material-ui/core"
import {
	CircularProgressIndeterminate,
	GridItem,
	GridContainer,
	ButtonWithIcon,
	Dialog,
	Select,
	DashboardWidgetList,
	DashboardWidget,
} from "../../components"
import { WidgetConfig, widgetConfigPropType } from "./DashboardWidget"

const styles = createStyles({
	"@global": {
		"@keyframes fadeIn": {
			"0%": {
				opacity: 0,
				transform: "translateY(5rem)",
			},
			"100%": {
				opacity: 1,
				transform: "translateY(0)",
			},
		},
		"@keyframes jiggle-left": {
			"0%": {
				transform: "rotate(-0.2deg)",
				animationTimingFunction: "ease-in",
			},
			"50%": {
				transform: "rotate(0.3deg)",
				animationTimingFunction: "ease-out",
			},
		},
		"@keyframes jiggle-right": {
			"0%": {
				transform: "rotate(0.2deg)",
				animationTimingFunction: "ease-in",
			},
			"50%": {
				transform: "rotate(-0.3deg)",
				animationTimingFunction: "ease-out",
			},
		},
	},
	jigglyMode: {
		overflow: "hidden",
		backgroundColor: "#dde",
		"& > .MuiGrid-item > *": {
			cursor: "move",
		},
		"&>:nth-child(2n)>:first-child": {
			animation: "jiggle-left 0.2s ease-in-out infinite",
			transformOrigin: "50% 10%",
		},
		"&>:nth-child(2n-1)>:first-child": {
			animation: "jiggle-right 0.2s ease-in-out infinite",
			transformOrigin: "50% 10%",
		},
	},
	ghostClass: {
		// card and the background behind it
		"& > * > *": {
			// card and the delete button
		},
		"& > * > :last-child": {
			// just the card
			opacity: 0.6,
		},
	},
	dragClass: {
		// card and the background behind it
		"& > * > *": {
			// card and the delete button
		},
		"& > * > :last-child": {
			// just the card
		},
	},
})

export type WidgetMap = Partial<Record<string, (props?: Record<string, unknown>) => JSX.Element>>

export interface ConfigurableDashboardProps extends WithStyles<typeof styles> {
	loading?: boolean

	/** widgets to render */
	widgets: WidgetConfig[]

	/** a map of the possible widget names to their component */
	widgetMap: WidgetMap

	/** receives an array of widgets (see widgets prop) when the user saves changes to their dashboard */
	onSaveWidgets: (widgets: WidgetConfig[] | null) => void
}
export interface ConfigurableDashboardState {
	isAdding: boolean
	isEditing: boolean
	isResettingToDefault: boolean
	selectedWidget: keyof ConfigurableDashboardProps["widgetMap"] | "Empty"
	widgetsBeforeEditing: null
	editingWidgets: WidgetConfig[] | null
}

class ConfigurableDashboard extends PureComponent<
	ConfigurableDashboardProps,
	ConfigurableDashboardState
> {
	public static propTypes = {
		loading: PropTypes.bool,

		/** widgets to render */
		widgets: PropTypes.arrayOf(widgetConfigPropType.isRequired).isRequired,

		/** a map of the possible widget names to their component */
		widgetMap: PropTypes.object.isRequired,

		/** receives an array of widgets (see widgets prop) when the user saves changes to their dashboard */
		onSaveWidgets: PropTypes.func.isRequired,
	}

	state = {
		isAdding: false,
		isEditing: false,
		isResettingToDefault: false,
		selectedWidget: "Empty",
		widgetsBeforeEditing: null,
		editingWidgets: null,
	}

	handleStartEditing = () =>
		this.setState({
			isEditing: true,
			editingWidgets: this.props.widgets,
		})

	handleCancelEditing = () =>
		this.setState({
			isEditing: false,
			editingWidgets: null,
		})

	handleStartAdding = () => this.setState({ isAdding: true })

	handleCancelAdding = () => this.setState({ isAdding: false })

	generateWidgetOptions = memoizeOne((widgetMap) =>
		sortBy(
			[
				{ value: "Empty", text: "Empty Space" },
				...Object.entries(widgetMap).map(([widget]) => ({
					value: widget,
					text: startCase(widget),
				})),
			],
			["text"]
		)
	)

	handleWidgetSelected = (selectedWidget: keyof WidgetMap) => {
		this.setState({ selectedWidget })
	}

	handleAddWidget = () => {
		this.setState({
			isAdding: false,
			selectedWidget: "Empty",
			editingWidgets: [
				...(this.state.editingWidgets ?? []),
				{ widget: this.state.selectedWidget },
			],
		})
	}

	handleRemoveWidget = (index: number) => {
		this.setState({
			editingWidgets: (this.state.editingWidgets ?? []).filter((item, i) => i !== index),
		})
	}

	handleUpdateWidgetConfig = (index: number) => (config: WidgetConfig) => {
		this.setState({
			editingWidgets:
				this.state.editingWidgets ??
				[].map((item: WidgetConfig, i) =>
					i !== index ? item : (
						{
							...item,
							...config,
							// don't allow changing the widget itself
							widget: item.widget,
						}
					)
				),
		})
	}

	handlePromptForResetToDefault = () => this.setState({ isResettingToDefault: true })

	handleCancelResetToDefault = () => this.setState({ isResettingToDefault: false })

	handleResetToDefault = () => {
		this.setState(
			{
				isResettingToDefault: false,
				editingWidgets: null,
			},
			this.handleSave
		)
	}

	handleSave = () => {
		const { editingWidgets } = this.state

		this.setState({
			isAdding: false,
			isEditing: false,
			selectedWidget: "Empty",
		})

		this.props.onSaveWidgets(editingWidgets)
	}

	handleReorderWidgets = (e: Sortable.SortableEvent) => {
		const editingWidgets = this.state.editingWidgets

		if (
			typeof e?.oldIndex !== "number" ||
			typeof e?.newIndex !== "number" ||
			editingWidgets === null
		) {
			// something weird happened, don't move anything
			return
		}

		const updatedWidgetList = Array.from(editingWidgets as WidgetConfig[])
		const [removed] = updatedWidgetList.splice(e.oldIndex, 1)
		updatedWidgetList.splice(e.newIndex, 0, removed)

		this.setState({ editingWidgets: updatedWidgetList })
	}

	render() {
		const { classes, loading, widgets: currentWidgets, widgetMap } = this.props
		const { isAdding, isEditing, isResettingToDefault, selectedWidget, editingWidgets } =
			this.state

		const widgets = editingWidgets || currentWidgets

		if (!widgets) {
			return null
		}

		if (loading) {
			return (
				<GridContainer justify="center">
					<GridItem>
						<CircularProgressIndeterminate />
					</GridItem>
				</GridContainer>
			)
		}

		return (
			<>
				{isEditing && (
					// ReactSortable doesn't update options on componentDidUpdate, so must force a
					// remount instead of using disabled={true}
					<ReactSortable
						list={widgets.map((w) => ({ ...w, id: w.widget }))} // Add ID prop for ReactSortable
						setList={noop}
						onEnd={this.handleReorderWidgets}
						className={cx({ [classes.jigglyMode]: isEditing })}
						animation={350}
						easing="cubic-bezier(0.33, 1, 0.68, 1)"
						ghostClass={classes.ghostClass}
						dragClass={classes.dragClass}
						tag={GridContainer}
					>
						<DashboardWidgetList
							widgets={widgets}
							widgetMap={widgetMap}
							isEditing={isEditing}
							onRemoveWidget={this.handleRemoveWidget}
							onStartEditing={this.handleStartEditing}
							onUpdateWidgetConfig={this.handleUpdateWidgetConfig}
						/>
					</ReactSortable>
				)}
				{!isEditing && (
					<GridContainer>
						<DashboardWidgetList
							widgets={widgets}
							widgetMap={widgetMap}
							isEditing={isEditing}
							onRemoveWidget={this.handleRemoveWidget}
							onStartEditing={this.handleStartEditing}
							onUpdateWidgetConfig={this.handleUpdateWidgetConfig}
						/>
					</GridContainer>
				)}
				<GridContainer>
					<GridItem xs={12} justifyContentRight>
						{isEditing && (
							<ButtonWithIcon
								content="Add Widget"
								round
								size="sm"
								color="success"
								icon={<Add />}
								onClick={this.handleStartAdding}
							/>
						)}
					</GridItem>
					<GridItem xs={12} justifyContentRight>
						{!isEditing && (
							<ButtonWithIcon
								content="Edit Dashboard"
								round
								size="sm"
								color="warning"
								icon={<Edit />}
								onClick={this.handleStartEditing}
							/>
						)}
						{isEditing && (
							<>
								<ButtonWithIcon
									content="Cancel"
									round
									size="sm"
									color="warning"
									icon={<Remove />}
									onClick={this.handleCancelEditing}
								/>
								<ButtonWithIcon
									content="Reset to Default"
									round
									size="sm"
									color="danger"
									icon={<Delete />}
									onClick={this.handlePromptForResetToDefault}
								/>
								<ButtonWithIcon
									content="Save Dashboard"
									round
									size="sm"
									color="success"
									icon={<Check />}
									onClick={this.handleSave}
								/>
							</>
						)}
					</GridItem>
				</GridContainer>
				<Dialog
					show={isAdding}
					title="Add a New Widget"
					confirmBtnText="Add Widget"
					onConfirm={this.handleAddWidget}
					onCancel={this.handleCancelAdding}
					// @ts-expect-error: TODO - remove when Dialog is converted.
					fullWidth={true}
				>
					<div>
						<Select
							id="widgetToAdd"
							labelText="Widget"
							value={selectedWidget || "Empty"}
							onChange={this.handleWidgetSelected}
							options={this.generateWidgetOptions(widgetMap)}
						/>
						<GridContainer>
							<DashboardWidget
								widgetConfig={{ widget: selectedWidget, width: 12 }}
								index={0}
								widgetMap={widgetMap}
								isEditing={false}
							/>
						</GridContainer>
					</div>
				</Dialog>
				<Dialog
					show={isResettingToDefault}
					title="Reset Entire Dashboard?"
					confirmBtnText="Confirm Reset"
					confirmBtnColor="danger"
					cancelBtnText="Keep My Dashboard"
					cancelBtnColor="success"
					onConfirm={this.handleResetToDefault}
					onCancel={this.handleCancelResetToDefault}
				>
					{
						"This will remove all customizations and reset this dashboard to its default state."
					}
				</Dialog>
			</>
		)
	}
}

// @ts-expect-error. Permit TS to describe widgetConfig more specifically than PropTypes can.
export default withStyles(styles)(ConfigurableDashboard)

import React, { useCallback, useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { useAppRestrictions, useCallApi } from "@ncs/bricks/util/hooks"
import { unpythonify } from "@ncs/bricks/util/variableCasing"
import {
	getChemicalMachinePhases,
	getChemicalMachineTypes,
	patchChemicalMachineType,
} from "@ncs/mortar/redux/services/inventory"
import { APPLICATIONS } from "@ncs/mortar/util/constants"
import {
	AnimatedEntrance,
	ButtonWithIcon,
	DynamicTable,
	GridContainer,
	GridItem,
	Input,
	InputAsDisplay,
	QuickCard,
	Select,
} from "~/components"
import NewMachinePhaseModal from "./NewMachinePhaseModal"

import {
	Add,
	Check,
	ChevronLeft,
	Close,
	Edit,
	SettingsInputComponentOutlined,
} from "@material-ui/icons"

const MachineTypeDetail = () => {
	const isAdmin = useAppRestrictions(APPLICATIONS.ChemMachineTypeAdmin)
	const history = useHistory()
	const callApi = useCallApi()
	const { machine_type_id: machineTypeId } = useParams()
	const [machineType, setMachineType] = useState(null)
	const [machinePhases, setMachinePhases] = useState([])
	const [isLoadingMachineTypes, setIsLoadingMachineTypes] = useState(false)
	const [isLoadingMachinePhases, setIsLoadingMachinePhases] = useState(false)
	const [isEditing, setIsEditing] = useState(false)
	const [tempDescription, setTempDescription] = useState("")
	const [tempIsActive, setTempIsActive] = useState(null)
	const [showNewPhaseModal, setShowNewPhaseModal] = useState(false)

	const fetchMachineTypes = useCallback(async () => {
		setIsLoadingMachineTypes(true)
		const { payload } = await callApi(getChemicalMachineTypes())
		setMachineType(unpythonify(payload.find((p) => p.id === machineTypeId)))
		setIsLoadingMachineTypes(false)
	}, [callApi, machineTypeId])

	const fetchMachinePhases = useCallback(async () => {
		setIsLoadingMachinePhases(true)
		const { payload } = await callApi(getChemicalMachinePhases({ machineTypeId }))
		setMachinePhases(unpythonify(payload))
		setIsLoadingMachinePhases(false)
	}, [callApi, machineTypeId])

	useEffect(() => {
		fetchMachineTypes()
	}, [fetchMachineTypes])

	useEffect(() => {
		fetchMachinePhases()
	}, [fetchMachinePhases])

	function reset() {
		setIsEditing(false)
		setTempDescription("")
		setTempIsActive(null)
	}

	function startEditing() {
		setIsEditing(true)
		setTempDescription(machineType.description)
		setTempIsActive(machineType.isActive)
	}

	async function handleSave() {
		setIsLoadingMachineTypes(true)
		const { payload } = await callApi(
			patchChemicalMachineType({
				machineTypeId,
				description: tempDescription,
				isActive: tempIsActive,
			})
		)
		setMachineType(unpythonify(payload))
		setIsLoadingMachineTypes(false)
		reset()
	}

	function handleBackToList() {
		history.push("/machine-types")
	}

	function handleRowClick(row) {
		history.push(`/machine-types/${machineTypeId}/phases/${row.id}`)
	}

	if (!machineType) {
		return <div />
	}

	return (
		<>
			<ButtonWithIcon
				content="Back To All Machine Types"
				onClick={handleBackToList}
				color="white"
				size="sm"
				icon={<ChevronLeft />}
				round
			/>

			<QuickCard
				icon={<SettingsInputComponentOutlined />}
				title={`${machineType.description} Machine Type`}
			>
				<GridContainer>
					<GridItem lg={4} md={6} xs={12}>
						{isEditing ?
							<Input
								labelText="Machine type name"
								value={tempDescription}
								onChange={(e) => setTempDescription(e.target.value)}
								inputProps={{
									autoFocus: true,
								}}
							/>
						:	<InputAsDisplay
								labelText="Machine type name"
								value={machineType.description}
							/>
						}
					</GridItem>
					<GridItem lg={4} md={6} xs={12}>
						{isEditing ?
							<Select
								labelText="Machine type status"
								value={String(tempIsActive)}
								onChange={(value) => setTempIsActive(value === "true")}
								options={[
									{
										value: "true",
										text: "Active",
									},
									{
										value: "false",
										text: "Inactive",
									},
								]}
							/>
						:	<InputAsDisplay
								value={machineType.isActive ? "Active" : "Inactive"}
								labelText="Machine type status"
							/>
						}
					</GridItem>

					{isAdmin && (
						<GridItem lg={4} xs={12} justifyContentRight alignItemsCenter>
							{!isEditing && (
								<ButtonWithIcon
									content="Edit Name and Status"
									onClick={startEditing}
									icon={<Edit />}
									size="sm"
									color="primary"
									disabled={isLoadingMachineTypes}
									round
									noMargin
								/>
							)}
							<AnimatedEntrance show={isEditing}>
								<ButtonWithIcon
									content="Cancel"
									onClick={reset}
									icon={<Close />}
									size="sm"
									color="primary"
									round
									simple
								/>
								<ButtonWithIcon
									content="Save"
									onClick={handleSave}
									icon={<Check />}
									size="sm"
									color="primary"
									loading={isLoadingMachineTypes}
									disabled={!tempDescription}
									round
								/>
							</AnimatedEntrance>
						</GridItem>
					)}
				</GridContainer>

				<GridContainer style={{ marginTop: "3rem" }}>
					<GridItem xs={12} alignItemsCenter justifyContentSpaceBetween>
						<h4>Phases in {machineType.description}</h4>
						{isAdmin && (
							<ButtonWithIcon
								content="Add New Phase"
								onClick={() => setShowNewPhaseModal(true)}
								icon={<Add />}
								size="sm"
								color="success"
								disabled={isEditing || isLoadingMachinePhases}
								noMargin
								round
							/>
						)}
					</GridItem>
					<GridItem xs={12}>
						<DynamicTable
							data={machinePhases}
							useClientSidePaging={false}
							onRowClick={handleRowClick}
							noDataText={
								isLoadingMachinePhases ?
									"Loading machine phases..."
								:	"No phases found for this machine type"
							}
							columns={[
								{
									Header: "Name",
									accessor: "description",
								},
								{
									id: "isActive",
									Header: "Status",
									accessor: (row) => (row.isActive ? "Active" : "Inactive"),
								},
							]}
						/>
					</GridItem>
				</GridContainer>
			</QuickCard>

			<NewMachinePhaseModal
				show={showNewPhaseModal}
				onClose={(newPhase) => {
					setShowNewPhaseModal(false)
					if (newPhase) {
						setMachinePhases((prev) => [...prev, unpythonify(newPhase)])
					}
				}}
			/>
		</>
	)
}

export default MachineTypeDetail

import Dashboard from "~/layouts/Dashboard"
import cloneDeep from "lodash/cloneDeep"

const indexRoutes = [{ path: "/", name: "Home", component: Dashboard }]

export const filterRoutesByAppPermissions = (routes, meetsAppRestriction) => {
	let filteredRoutes = []

	// copy the routes so permissions don't go stale
	let clonedRoutes = cloneDeep(routes)

	for (let route of clonedRoutes) {
		// check parent item security
		if (route.restrictTo && !meetsAppRestriction(route.restrictTo)) continue

		if (route.collapse) {
			// check security for children
			route.views = route.views.filter((item) => {
				return !item.restrictTo || meetsAppRestriction(item.restrictTo)
			})

			// don't show parent items with no children
			if (route.views.length < 1) continue
		}

		filteredRoutes.push(route)
	}

	return filteredRoutes
}

export default indexRoutes

import React from "react"

import {
	ButtonWithIcon,
	GridContainer,
	GridItem,
	Dialog,
	TechAssignmentSelector,
	ChemicalTechAssignmentSelector,
	// onClickCustomer,
} from "~/components"

//material ui icons
import SwapHorizIcon from "@material-ui/icons/SwapHoriz"

import RemoveIcon from "@material-ui/icons/Remove"
//styles
import withStyles from "@material-ui/core/styles/withStyles"
const styles = {
	right: {
		textAlign: "right",
	},
}

function TechAssignmentChange(props) {
	let {
		onTechAssSelected,
		onStartAssignmentAdd,
		onCancelAssignmentAdd,
		show,
		selectedTechAssignment,
		onConfirmAssignmentAdd,
		isSaving,
		territory,
		chemical,
	} = props
	return (
		<GridContainer>
			<GridItem xs={12}>
				<ButtonWithIcon
					// content="Change Assignment"
					justIcon
					onClick={onStartAssignmentAdd}
					icon={<SwapHorizIcon />}
					size="sm"
					color="success"
					round
				/>
			</GridItem>
			<Dialog
				warning="true"
				show={show}
				title="Change Assignment"
				confirmBtnIcon={<SwapHorizIcon />}
				confirmBtnText="Confirm"
				onConfirm={onConfirmAssignmentAdd}
				isConfirmationAsync={true}
				cancelBtnIcon={<RemoveIcon />}
				cancelBtnText={"Cancel"}
				cancelBtnColor={"danger"}
				onCancel={onCancelAssignmentAdd}
				isSaving={isSaving}
			>
				{show && (
					<GridContainer>
						<GridItem xs={12}>
							{chemical ?
								<ChemicalTechAssignmentSelector
									onChange={onTechAssSelected}
									territory={territory}
									techAssignment={selectedTechAssignment}
								/>
							:	<TechAssignmentSelector
									onChange={onTechAssSelected}
									territory={territory}
									techAssignment={selectedTechAssignment}
								/>
							}
						</GridItem>
					</GridContainer>
				)}
			</Dialog>
		</GridContainer>
	)
}

export default withStyles(styles)(TechAssignmentChange)

import React from "react"
import PropTypes from "prop-types"
import { formatNumberAsCurrency } from "@ncs/bricks/util/formatters"
import { SearchSelector, withCallApi } from "@ncs/bricks/components"
import { fetchLaborRatePrices } from "../../redux/services/pricing"

class LaborRatePriceSelector extends React.Component {
	handleGetSuggestions = (search) => {
		// note: SearchSelector handles debouncing for us
		return this.props.callApi(
			fetchLaborRatePrices({
				search,
				fields: "id,description,locked_rate,territory",
				pageSize: 15,
				include_base_rates: this.props.includeBaseRates,
				type_id: this.props.laborRateType,
			})
		)
	}

	handleGetSuggestionValue = (item) => {
		return `${item.description} (${formatNumberAsCurrency(item.locked_rate)})`
	}

	handleSuggestionSelected = (event, { suggestion }) => {
		this.props.onLaborRatePriceSelected(suggestion)
	}

	render = () => (
		<SearchSelector
			label="Labor Rate Price Group"
			placeholder="Search for a group"
			getSuggestions={this.handleGetSuggestions}
			getSuggestionValue={this.handleGetSuggestionValue}
			onSuggestionSelected={this.handleSuggestionSelected}
			{...this.props}
		/>
	)
}

LaborRatePriceSelector.defaultProps = {
	includeBaseRates: true,
}

LaborRatePriceSelector.propTypes = {
	callApi: PropTypes.func.isRequired,
	onLaborRatePriceSelected: PropTypes.func.isRequired,
	includeBaseRates: PropTypes.bool,
	laborRateType: PropTypes.number,
}

export default withCallApi(LaborRatePriceSelector)

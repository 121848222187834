import { FC, ReactElement } from "react"

import { Column } from "react-table"

import {
	DispatchHistoryReportLine,
	DispatchHistoryReportQueryParams,
	useDispatchHistoryReport,
	useExportDispatchHistoryReport,
} from "@ncs/ncs-api"
import {
	extractNumber,
	formatDateTime,
	formatDuration,
	formatNumber,
	getStartAndEndDateQueryParams,
	getTimezoneAbbreviation,
	TimeAgo,
	Typify,
} from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	CustomerQueryFilter as DefaultCustomerQueryFilter,
	CustomerQueryFilterProps,
	DispatchCallTypeQueryFilter,
	DispatchStatusQueryFilter,
	EnterpriseQueryFilter,
	OrganizationQueryFilter,
	RegionQueryFilter,
	Table,
	TerritoryQueryFilter,
	TimeAgoQueryFilter,
	usePaginationUrlState,
	useUrlState,
	VintageQueryFilter,
} from "@ncs/web-legos"

export type DispatchHistoryReportUrlState = Typify<DispatchHistoryReportQueryParams>

export const DispatchHistoryReport: FC = () => {
	const [queryParams, { setUrlState: setQueryParams }] =
		useUrlState<DispatchHistoryReportUrlState>(initialDispatchHistoryReportUrlState)
	const [pagination, setPagination] = usePaginationUrlState({
		defaultPageSize: 25,
	})

	const query = useDispatchHistoryReport({
		params: { ...queryParams, ...pagination },
		manualPagination: true,
	})
	const dataExport = useExportDispatchHistoryReport(queryParams)

	return (
		<AnimatedEntrance show>
			<Table
				query={query}
				columns={columns}
				queryParamState={queryParams}
				setQueryParamState={setQueryParams}
				pagination={pagination}
				setPagination={setPagination}
				dataExport={dataExport}
				pinnedQueryFilters={[
					TimeAgoQueryFilter,
					CustomerQueryFilter,
					OrganizationQueryFilter,
					TerritoryQueryFilter,
					DispatchCallTypeQueryFilter,
					DispatchStatusQueryFilter,
				]}
				filterResetValues={initialDispatchHistoryReportUrlState}
				toggledQueryFilters={[
					RegionQueryFilter,
					EnterpriseQueryFilter,
					VintageQueryFilter,
				]}
				storeColumnVisibility="dispatch-history-report-columns"
			/>
		</AnimatedEntrance>
	)
}

const columns: Column<DispatchHistoryReportLine>[] = [
	{
		Header: "Dispatch #",
		accessor: "dispatchNumber",
		disableSortBy: true,
	},
	{
		Header: "Customer",
		id: "customer_CustomerNumber",
		accessor: ({ customer }) => `(${customer.customerNumber}) ${customer.name}`,
	},
	{
		Header: "Address 1",
		disableSortBy: true,
		hiddenByDefault: true,
		accessor: ({ customer }) => customer.address1,
	},
	{
		Header: "Address 2",
		disableSortBy: true,
		hiddenByDefault: true,
		accessor: ({ customer }) => customer.address2,
	},
	{
		Header: "City",
		id: "customer_City",
		hiddenByDefault: true,
		accessor: ({ customer }) => customer.city,
	},
	{
		Header: "State",
		id: "customer_State",
		hiddenByDefault: true,
		accessor: ({ customer }) => customer.state,
	},
	{
		Header: "Postal code",
		id: "customer_Postalcode",
		hiddenByDefault: true,
		accessor: ({ customer }) => customer.postalcode,
	},
	{
		Header: "Region",
		id: "customer_Territory_Region_Code",
		hiddenByDefault: true,
		accessor: ({ customer: { territory } }) =>
			territory ? `(${territory.region.code}) ${territory.region.description}` : "-",
	},
	{
		Header: "Territory",
		id: "customer_Territory_Code",
		hiddenByDefault: true,
		accessor: ({ customer: { territory } }) =>
			territory ? `(${territory.code}) ${territory.description}` : "-",
	},
	{
		Header: "Status",
		id: "status_Description",
		accessor: ({ status }) => status || "-",
	},
	{
		Header: "Priority",
		id: "priority_Description",
		accessor: ({ priority }) => priority || "-",
	},
	{
		Header: "Call type",
		id: "callType_Description",
		accessor: ({ callType }) => callType || "-",
	},
	{
		Header: "Caller",
		id: "callerName",
		hiddenByDefault: true,
		accessor: ({ callerName }) => callerName || "-",
	},
	{
		Header: "Tech assigned",
		id: "assignedEmployee_LastName",
		hiddenByDefault: true,
		accessor: ({ assignedEmployee }) => assignedEmployee?.name || "-",
	},
	{
		Header: `Created (${getTimezoneAbbreviation()})`,
		id: "createdDate",
		hiddenByDefault: true,
		accessor: ({ createdDate }) => (createdDate ? formatDateTime(createdDate) : "-"),
	},
	{
		Header: `Call received (${getTimezoneAbbreviation()})`,
		id: "callReceived",
		accessor: ({ callReceivedDate }) =>
			callReceivedDate ? formatDateTime(callReceivedDate) : "-",
	},
	{
		Header: `Arrival (${getTimezoneAbbreviation()})`,
		hiddenByDefault: true,
		disableSortBy: true,
		accessor: ({ arrival }) => (arrival ? formatDateTime(arrival) : "-"),
	},
	{
		Header: `Closed (${getTimezoneAbbreviation()})`,
		id: "closedDate",
		accessor: ({ closedDate }) => (closedDate ? formatDateTime(closedDate) : "-"),
	},
	{
		Header: "Response time",
		hiddenByDefault: true,
		disableSortBy: true,
		accessor: ({ responseTime }) =>
			formatDuration(Math.round(extractNumber(responseTime) / 60)) || "-",
	},
	{
		Header: "Down time",
		hiddenByDefault: true,
		disableSortBy: true,
		accessor: ({ downTime }) =>
			formatDuration(Math.round(extractNumber(downTime) / 60)) || "-",
	},
	{
		Header: "Description",
		hiddenByDefault: true,
		disableSortBy: true,
		accessor: ({ symptoms }) => symptoms || "-",
	},
	{
		Header: "Bill-to customer",
		id: "billToCustomer_CustomerNumber",
		hiddenByDefault: true,
		accessor: ({ billToCustomer }) =>
			billToCustomer ? `(${billToCustomer.customerNumber}) ${billToCustomer.name}` : "-",
	},
	{
		Header: "PO #",
		id: "poTicket",
		hiddenByDefault: true,
		accessor: ({ poTicket }) => poTicket || "-",
	},
	{
		Header: "Wash counter",
		hiddenByDefault: true,
		disableSortBy: true,
		accessor: ({ washCounter }) => formatNumber(extractNumber(washCounter)),
	},
	{
		Header: "WO comments",
		hiddenByDefault: true,
		disableSortBy: true,
		accessor: ({ woComments }) => woComments || "-",
	},
]

const initialDispatchHistoryReportUrlState: DispatchHistoryReportUrlState = {
	ordering: null,
	organization: null,
	region: null,
	enterprise: null,
	territory: null,
	customer: null,
	startDate: getStartAndEndDateQueryParams(TimeAgo.DaysAgo30)[0],
	endDate: null,
	vintage: null,
	callTypeId: null,
	statusId: null,
}

const CustomerQueryFilter = (
	props: CustomerQueryFilterProps<DispatchHistoryReportUrlState>
): ReactElement => {
	return (
		<DefaultCustomerQueryFilter
			{...props}
			limitToServiceCustomers={null}
			accountActive={null}
			service={null}
		/>
	)
}

import { FC, useState } from "react"

import { Column } from "react-table"

import {
	makeApiErrorMessage,
	QuickOrder,
	QuickOrderDetails,
	useQuickOrderDetails,
	useRemoveQuickOrderPart,
} from "@ncs/ncs-api"
import { extractNumber } from "@ncs/ts-utils"
import {
	Button,
	ConfirmationModal,
	ConfirmationModalConfig,
	Table,
	useToast,
} from "@ncs/web-legos"

import { AddPartToQuickOrderModal } from "./AddPartToQuickOrderModal"

export interface QuickOrdersPartsTabProps {
	order: QuickOrder
}

const columns: Column<QuickOrderDetails["parts"][number]>[] = [
	{
		Header: "Part #",
		accessor: "partNumber",
	},
	{
		Header: "Part Name",
		accessor: "description",
	},
]

export const QuickOrdersPartsTab: FC<QuickOrdersPartsTabProps> = ({ order }) => {
	const { makeSuccessToast, makeErrorToast } = useToast()
	const [showAddModal, setShowAddModal] = useState(false)
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)

	const [details, detailsLoading] = useQuickOrderDetails(order.id)

	const removePart = useRemoveQuickOrderPart()

	const onRemove = async (id: number) => {
		try {
			await removePart({
				body: {
					quickOrderId: order.id,
					onlinePartId: id,
				},
			})
			makeSuccessToast("Product removed from Quick Order")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		}
	}

	return (
		<>
			<Button
				variant="secondary-cta"
				icon="plus-circle"
				onClick={() => setShowAddModal(true)}
				containerProps={{ mb: 3 }}
			>
				Add product to Quick Order
			</Button>

			<Table
				columns={columns}
				data={details?.parts ?? []}
				isLoading={detailsLoading}
				noDataText="No products in this Quick Order yet"
				rowMenu={[
					{
						label: "Remove",
						iconName: "trash",
						onClick: ({ original }) => {
							setConfirmationConfig({
								title: "Remove Product?",
								message: `Remove ${original.description} from ${order.description}?`,
								onConfirm: () => onRemove(extractNumber(original.onlinePartId)),
							})
						},
					},
				]}
				infiniteRowsIncrement={100}
			/>

			<AddPartToQuickOrderModal
				isOpen={showAddModal}
				onClose={() => setShowAddModal(false)}
				order={order}
			/>
			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</>
	)
}

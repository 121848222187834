import { FC, useMemo } from "react"

import { css } from "@emotion/react"

import { withCssUnit } from "@ncs/ts-utils"

export interface PipeProps {
	m?: string | number
}

export const Pipe: FC<PipeProps> = ({ m = 0.75 }) => {
	const pipeStyle = useMemo(() => {
		return css`
			color: inherit;
			font-size: inherit;
			margin-left: ${withCssUnit(m)};
			margin-right: ${withCssUnit(m)};
		`
	}, [m])

	return <span css={pipeStyle}>|</span>
}

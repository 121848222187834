import React from "react"

import { withStyles } from "@material-ui/core/styles"

import { successColor } from "@ncs/bricks/assets/jss/material-dashboard-pro-react"
import buttonsStyle from "@ncs/bricks/assets/jss/material-dashboard-pro-react/components/buttonStyle"

import {
	Button,
	ButtonWithIcon,
	Checkbox,
	ConditionalContent,
	CustomerSelector,
	Dialog,
	GridContainer,
	GridItem,
	Input,
	InputAsDisplay,
	Select,
	SnackbarContent,
	TicketGroupSelector,
	TicketPrioritySelector,
	TicketRequestorSelector,
	TicketSubtypeSelector,
	TicketTypeSelector,
	UserSelector,
} from "~/components"

import TicketDocumentUploadContainer from "./TicketDocumentUploadContainer"

import AddIcon from "@material-ui/icons/Add"
import AddAlert from "@material-ui/icons/AddAlert"
import Delete from "@material-ui/icons/Delete"
import Remove from "@material-ui/icons/Remove"

const styles = {
	...buttonsStyle,
	right: {
		textAlign: "right",
	},
	notificationCheckIcon: {
		color: `${successColor[0]} !important`,
	},
}

function CreateTicket(props) {
	const {
		selectedTicketType,
		selectedTicketPriority,
		selectedTicketSubtype,
		selectedTicketGroup,
		onTicketGroupChange,
		onTicketPriorityChange,
		onTicketSubtypeChange,
		onTicketTypeChange,
		description,
		descriptionIsValid,
		commentIsValid,
		descriptionErrorMessage,
		comment,
		onCommentChange,
		commentErrorMessage,
		isUrgent,
		isUrgentIsValid,
		onIsUrgentChange,
		isUrgentErrorMessage,
		setInputRef,
		showValidationErrors,
		onDescriptionChange,
		onSaveTicket,
		valid,
		error,
		newTicketMessage,
		success,
		onTicketAssignedToChange,
		selectedAssignedTo,
		classes,
		isAdmin,
		onDocumentUploaded,
		documents,
		onDeleteDocument,
		isConfirmingDelete,
		onCancelDelete,
		isSaving,
		deletingDocument,
		generatePreview,
		onConfirmDelete,
		onCustomerSelected,
		selectedCustomer,
		customerEquipment,
		selectedCustomerEquipment,
		onCustomerEquipmentChange,
		onTicketTechChange,
		isServiceUser,
		selectedTicketRequestor,
		onSelectedTicketRequestorChange,
		selectedRequestor,
		onRequestorTypeChange,
		requestorText,
		onRequestorTextChange,
		requestorTextIsValid,
		requestorTextErrorMessage,
	} = props
	return (
		<React.Fragment>
			<GridContainer justify="center">
				<GridItem xs={12}>
					<Input
						labelText={"Title"}
						value={description}
						onChange={onDescriptionChange}
						inputProps={{
							inputProps: { ref: setInputRef },
						}}
						formControlProps={{
							error: showValidationErrors && !descriptionIsValid,
						}}
						helperText={showValidationErrors ? descriptionErrorMessage : ""}
					/>
				</GridItem>
				<GridItem xs={12} md={6} lg={6}>
					<TicketGroupSelector
						group={selectedTicketGroup}
						onChange={onTicketGroupChange}
						isServiceUser={isServiceUser}
					/>
				</GridItem>
				<GridItem xs={12} md={6} lg={6}>
					<TicketTypeSelector
						type={selectedTicketType}
						group={selectedTicketGroup}
						value={selectedTicketType || ""}
						onChange={onTicketTypeChange}
					/>
				</GridItem>
				<GridItem xs={12} md={6} lg={6}>
					<TicketSubtypeSelector
						type={selectedTicketType}
						onChange={onTicketSubtypeChange}
						subtype={selectedTicketSubtype}
					/>
				</GridItem>
				<GridItem xs={12} md={6} lg={6}>
					<Checkbox
						labelText="Is Urgent"
						checked={isUrgent}
						onClick={onIsUrgentChange}
						isError={!isUrgentIsValid}
						helperText={isUrgentErrorMessage}
					/>
				</GridItem>

				<GridItem xs={12} md={6} lg={6}>
					<CustomerSelector onCustomerSelected={onCustomerSelected} />
				</GridItem>
				<GridItem xs={12} md={6} show={customerEquipment.length > 0}>
					<Select
						id="customerEquipment"
						labelText="Customer Equipment"
						value={selectedCustomerEquipment}
						onChange={onCustomerEquipmentChange}
						options={[
							{
								_id: null,
								model: "No Equipment.",
								disabled: false,
								serial_number: "",
							},
							...customerEquipment,
						]}
						labelProps={{ shrink: true }}
						valueAccessor="_id"
						textAccessor={(x) =>
							`${x.model} ${x.serial_number ? `(${x.serial_number})` : ""} ${
								x._id !== null ? `(Bay: ${x.bay})` : ""
							}`
						}
					/>
				</GridItem>
				<GridItem sm={12} md={6} hide={customerEquipment.length > 0}></GridItem>
				<ConditionalContent show={selectedCustomer !== null}>
					<GridItem xs={12} md={12} lg={12}>
						<InputAsDisplay
							labelText={"Customer Address"}
							value={
								selectedCustomer ?
									`${selectedCustomer.address_2} - ${selectedCustomer.city}, ${selectedCustomer.state}`
								:	""
							}
						/>
					</GridItem>
				</ConditionalContent>
				<GridItem xs={12} md={6} show={selectedTicketGroup === 3}>
					<Select
						id="ticketCommentRequestor"
						labelText="Requestor Type"
						value={selectedRequestor}
						onChange={onRequestorTypeChange}
						options={
							selectedCustomer ?
								[
									{
										id: null,
										description: "Select a request type...",
										disabled: false,
									},
									{
										id: 1,
										description: "Technician",
									},
									{
										id: 2,
										description: "Other",
									},
								]
							:	[
									{
										id: null,
										description: "Select a request type...",
										disabled: false,
									},
									{
										id: 1,
										description: "Technician",
									},
								]
						}
						labelProps={{ shrink: true }}
						valueAccessor="id"
						textAccessor={(x) => x.description}
					/>
				</GridItem>
				<GridItem xs={12} md={6} lg={6} show={selectedRequestor === 1}>
					<UserSelector labelText="Tech" onUserSelected={onTicketTechChange} />
				</GridItem>
				<GridItem xs={12} md={6} show={selectedRequestor === 1}></GridItem>
				<GridItem
					xs={12}
					md={6}
					show={selectedRequestor === 2 && selectedTicketGroup === 3}
				>
					<TicketRequestorSelector
						customer={selectedCustomer ? selectedCustomer._id : null}
						onChange={onSelectedTicketRequestorChange}
						requestor={selectedTicketRequestor}
					/>
				</GridItem>
				<GridItem
					xs={12}
					md={6}
					show={selectedRequestor === 2 && selectedTicketRequestor === null}
				>
					<Input
						labelText={"Requestor"}
						value={requestorText}
						onChange={onRequestorTextChange}
						inputProps={{
							ref: setInputRef,
							autoFocus: true,
						}}
						formControlProps={{
							error: showValidationErrors && !requestorTextIsValid,
						}}
						helperText={showValidationErrors ? requestorTextErrorMessage : ""}
					/>
				</GridItem>
				<GridItem
					show={selectedRequestor === 2 && selectedTicketRequestor !== null}
					xs={12}
					sm={6}
				></GridItem>
				<GridItem xs={12} md={6} lg={6} show={selectedTicketGroup !== 3}></GridItem>

				<GridItem xs={12} md={6}></GridItem>

				<ConditionalContent show={isAdmin}>
					<GridItem xs={12} md={6}>
						<TicketPrioritySelector
							priority={selectedTicketPriority}
							onChange={onTicketPriorityChange}
						/>
					</GridItem>
					<GridItem xs={12} md={6}>
						<UserSelector
							labelText="Assign To"
							onUserSelected={onTicketAssignedToChange}
							defaultValue={selectedAssignedTo || ""}
						/>
					</GridItem>
				</ConditionalContent>

				<GridItem lg={12}>
					<Input
						labelText={"Comment"}
						value={comment}
						onChange={onCommentChange}
						inputProps={{
							ref: setInputRef,
							multiline: true,
						}}
						formControlProps={{
							error: showValidationErrors && !commentIsValid,
						}}
						helperText={showValidationErrors ? commentErrorMessage : ""}
					/>
				</GridItem>
				<GridItem xs={6}>
					<GridContainer show={documents.length > 0}>
						<GridItem xs={12}>
							<h2>Documents</h2>
						</GridItem>
						{documents.map((document) => (
							<React.Fragment key={document.filename}>
								<GridItem xs={8}>{document.filename}</GridItem>
								<GridItem xs={4}>
									<Button
										justIcon
										round
										color="danger"
										size="sm"
										title="Delete"
										onClick={(e) => {
											e.stopPropagation()
											onDeleteDocument(document)
										}}
									>
										<Remove />
									</Button>
								</GridItem>
							</React.Fragment>
						))}
					</GridContainer>
					<TicketDocumentUploadContainer
						onDocumentUploaded={onDocumentUploaded}
						isTicket={true}
						isComment={false}
						relatedId={null}
					/>
				</GridItem>
				<GridItem xs={6} className={classes.right}>
					<ButtonWithIcon
						icon={<AddIcon />}
						content="Create Ticket"
						size="sm"
						round
						color="success"
						onClick={onSaveTicket}
						disabled={!valid}
					/>
				</GridItem>
				<ConditionalContent show={error !== null}>
					<GridItem xs={12} />
					<GridItem xs={4}>
						<SnackbarContent
							message={"Error Creating Ticket. If problem persists contact IT."}
							color={"info"}
							icon={AddAlert}
						/>
					</GridItem>
					<GridItem xs={12} />
				</ConditionalContent>
				<ConditionalContent show={success}>
					<GridItem xs={12}></GridItem>
					<GridItem xs={4}>
						<SnackbarContent
							message={newTicketMessage}
							color={"success"}
							icon={AddAlert}
						/>
					</GridItem>
				</ConditionalContent>
			</GridContainer>
			<Dialog
				warning="true"
				show={isConfirmingDelete}
				title={"Are you sure you want to permanently remove this item?"}
				confirmBtnIcon={<Delete />}
				confirmBtnText="Confirm Deletion"
				confirmBtnColor="danger"
				onConfirm={onConfirmDelete}
				isConfirmationAsync={true}
				cancelBtnText={"Cancel"}
				onCancel={onCancelDelete}
				successTitle="Success"
				successContent="Document was removed."
				successConformColor="success"
				onSuccessConfirm={onCancelDelete}
				isSaving={isSaving}
			>
				{isConfirmingDelete && (
					<GridContainer>
						<GridItem xs={12} sm={4}>
							{generatePreview(deletingDocument)}
						</GridItem>
						<GridItem xs={12} sm={8}>
							<InputAsDisplay
								labelText="Filename"
								value={`${deletingDocument.filename}`}
							/>
						</GridItem>
					</GridContainer>
				)}
			</Dialog>
		</React.Fragment>
	)
}

export default withStyles(styles)(CreateTicket)

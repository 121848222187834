import { Dispatch, ReactElement, SetStateAction } from "react"

import { Select } from "../inputs"

interface IsFstActiveQueryParam {
	service: boolean | null
}

export interface IsFstActiveQueryFilterProps<QueryParamState extends IsFstActiveQueryParam> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

export const IsFstActiveQueryFilter = <QueryParamState extends IsFstActiveQueryParam>({
	queryParamState,
	setQueryParamState,
}: IsFstActiveQueryFilterProps<QueryParamState>): ReactElement => {
	const handleChange = (value: string | null) => {
		setQueryParamState((prev) => ({
			...prev,
			service: value === null ? null : value === "true",
		}))
	}

	return (
		<Select
			value={
				queryParamState.service == null ? null
				: queryParamState.service ?
					"true"
				:	"false"
			}
			onChange={handleChange}
			label="FST active status"
			options={[
				{
					text: "Active",
					value: "true",
				},
				{
					text: "Inactive",
					value: "false",
				},
			]}
			fillContainer
			disableNoSelectionOption={false}
			noSelectionOptionText="Any status"
			nullNoSelectionValue
		/>
	)
}

import { FC } from "react"

import { ExtendableSelectProps, Select } from "../inputs"

export interface PosPartRelationshipSelectorProps
	extends ExtendableSelectProps<(typeof options)[number]> {}

const options = [
	{
		text: "Parents",
		value: "is_parent",
	},
	{
		text: "Children",
		value: "is_child",
	},
]

export const PosPartRelationshipSelector: FC<PosPartRelationshipSelectorProps> = ({ ...rest }) => {
	return <Select label="Product relationship type" fillContainer {...rest} options={options} />
}

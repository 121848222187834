import { FC } from "react"

import { BinPart, usePartAvailability } from "@ncs/ncs-api"
import { formatNumber } from "@ncs/ts-utils"
import {
	ErrorText,
	ExtendableSearchableSelectProps,
	Paragraph,
	SearchableSelect,
} from "@ncs/web-legos"

export interface PartLocationBinSelectorProps extends ExtendableSearchableSelectProps<BinPart> {
	partId: string | null
	locationId: string | null
	showNoStockError?: boolean
}

export const PartLocationBinSelector: FC<PartLocationBinSelectorProps> = ({
	value,
	onChange,
	partId,
	locationId,
	showNoStockError,
	...rest
}) => {
	const [partAvailability, partAvailabilityLoading] = usePartAvailability({
		params: {
			locationId: locationId ?? undefined,
			partId: partId ?? undefined,
		},
	})

	return (
		<>
			<SearchableSelect
				value={value}
				onItemSelect={onChange}
				isLoading={partAvailabilityLoading}
				options={partAvailability ?? []}
				label="Bins at location"
				getOptionLabel={(option) => option.binCode}
				uniqueIdParam="binId"
				renderOption={(option) => (
					<div>
						<Paragraph>{option.binCode}</Paragraph>
						<Paragraph small secondary>
							Qty: {formatNumber(option.quantity)}
						</Paragraph>
					</div>
				)}
				disableOptionsAggregation
				{...rest}
			/>

			{!!showNoStockError && !partAvailabilityLoading && partAvailability?.length === 0 && (
				<ErrorText>Part is not in stock at location</ErrorText>
			)}
		</>
	)
}

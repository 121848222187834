import { FC, useEffect, useState } from "react"

import {
	makeApiErrorMessage,
	useCreateCustomerContact,
	useEditCustomerContact,
	CustomerContact,
} from "@ncs/ncs-api"
import {
	DispatchServiceNotificationType,
	DispatchServiceNotificationReason,
	DispatchServiceNotificationTypeEnum,
	DispatchServiceNotificationReasonEnum,
	ExtendableModalProps,
	Modal,
	TextInput,
	useToast,
} from "@ncs/web-legos"

import { validatePhone } from "@ncs/ts-utils"

export interface AddServiceNotificationsModalProps extends ExtendableModalProps {
	customerId: string
	customerContact: CustomerContact | null
}

export const AddServiceNotificationsModal: FC<AddServiceNotificationsModalProps> = ({
	customerId,
	customerContact,
	...rest
}) => {
	const { makeSuccessToast } = useToast()
	const [reasonId, setReasonId] = useState<DispatchServiceNotificationReasonEnum | null>(null)
	const [typeId, setTypeId] = useState<DispatchServiceNotificationTypeEnum | null>(null)
	// const [manualValue, setManualValue] = useState<boolean>(false)
	// const [additionalEntryValue, setAdditionalEntryValue] = useState<boolean>(false)
	const [contactValue, setContactValue] = useState<string | null>(null)

	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const [validContactValue, setValidContactValue] = useState<boolean>(false)

	const createCustomerContacts = useCreateCustomerContact(customerId)

	const customerContactId = customerContact ? customerContact.id.toString() : ""
	const editCustomerContacts = useEditCustomerContact(customerId, customerContactId)

	const getCommunicationReasonText = (reason: string) => {
		switch (reason) {
			case DispatchServiceNotificationReasonEnum.Invoice:
				return "Invoice"

			case DispatchServiceNotificationReasonEnum.WorkOrders:
				return "Work Orders"

			case DispatchServiceNotificationReasonEnum.Dispatch:
				return "Dispatch"

			case DispatchServiceNotificationReasonEnum.PartOrder:
				return "Part Order"

			default:
				return ""
		}
	}

	useEffect(() => {
		if (customerContact !== null) {
			setTypeId(
				DispatchServiceNotificationTypeEnum[
					customerContact.communicationChannel as keyof typeof DispatchServiceNotificationTypeEnum
				]
			)
			setReasonId(
				DispatchServiceNotificationReasonEnum[
					customerContact.communicationReason as keyof typeof DispatchServiceNotificationReasonEnum
				]
			)
			setContactValue(customerContact.destination)
		}
	}, [customerContact])

	const handleSave = async () => {
		try {
			if (!contactValue) throw new Error("No contact selected")
			if (!reasonId) throw new Error("No reason selected")
			if (!typeId) throw new Error("No type selected")

			setIsSaving(true)
			if (customerContact !== null) {
				await editCustomerContacts({
					updates: {
						destination: contactValue,
						communication_channel: typeId,
						communication_reason: getCommunicationReasonText(reasonId),
						notification_type: Number(reasonId),
					},
				})
			} else {
				await createCustomerContacts({
					destination: contactValue,
					communication_channel: typeId,
					communication_reason: getCommunicationReasonText(reasonId),
					notification_type: Number(reasonId),
				})
			}
			makeSuccessToast("Contact method created for customer")
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const validateEmail = (email: string | null) => {
		if (email) {
			return email.match(
				/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			)
		}
		return false
	}

	useEffect(() => {
		switch (typeId) {
			case DispatchServiceNotificationTypeEnum.Phone:
				if (validatePhone(contactValue)) {
					setValidContactValue(true)
				} else {
					setValidContactValue(false)
				}
				break

			case DispatchServiceNotificationTypeEnum.Email:
				if (validateEmail(contactValue)) {
					setValidContactValue(true)
				} else {
					setValidContactValue(false)
				}
				break

			case DispatchServiceNotificationTypeEnum.Fax:
				setValidContactValue(true)
				break

			case DispatchServiceNotificationTypeEnum.Mail:
				setValidContactValue(true)
				break

			default:
				break
		}
	}, [typeId, contactValue])

	const reset = () => {
		if (customerContact == null) {
			setTypeId(null)
			setIsSaving(false)
			setErrorText(null)
			setValidContactValue(false)
		}
	}

	return (
		<Modal
			{...rest}
			onOpen={reset}
			title="Add Service Notification"
			errorText={errorText}
			rightButtons={{
				buttonText: customerContact !== null ? "Edit Notification" : "Add Notification",
				onClick: handleSave,
				isLoading: isSaving,
				disabled: !reasonId || !typeId || !contactValue || !validContactValue,
			}}
		>
			<DispatchServiceNotificationType value={typeId} onChange={setTypeId} fillContainer />

			<DispatchServiceNotificationReason
				value={reasonId}
				onChange={setReasonId}
				fillContainer
			/>

			<TextInput
				label={"Contact Value"}
				value={contactValue}
				disabled={!typeId}
				onChange={setContactValue}
				fillContainer
			/>

			{/* <Checkbox
				label={'Manual'}
				value={manualValue}
				onChange={() => setManualValue(!manualValue)}
				mb={0}
			/>

			<Checkbox
				label={'Additional Entry'}
				value={additionalEntryValue}
				onChange={() => setAdditionalEntryValue(!additionalEntryValue)}
				mb={0}
			/> */}
		</Modal>
	)
}

import { PermanentPriceStatus, PricingAdjustmentRequest } from "@ncs/ncs-api"

export const priceAdjustmentPermissions = (
	request: PricingAdjustmentRequest
): {
	canSubmit: boolean
	canApprove: boolean
	canResubmit: boolean
	isRejected: boolean
	isApproved: boolean
	isCanceled: boolean
	noMoreEdits: boolean
} => {
	const canSubmit = request.status === PermanentPriceStatus.Open
	const canApprove = request.status === PermanentPriceStatus.Submitted
	const canResubmit = request.status === PermanentPriceStatus.FollowUpNeeded

	const isRejected = request.status === PermanentPriceStatus.Denied
	const isApproved = request.status === PermanentPriceStatus.Approved
	const isCanceled = request.status === PermanentPriceStatus.Canceled

	const noMoreEdits = [
		PermanentPriceStatus.Denied,
		PermanentPriceStatus.Canceled,
		PermanentPriceStatus.Closed,
		PermanentPriceStatus.Approved,
	].includes(request.status)

	return {
		canSubmit,
		canApprove,
		canResubmit,
		isRejected,
		isApproved,
		isCanceled,
		noMoreEdits,
	}
}

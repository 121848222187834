import { FC, useEffect, useRef } from "react"

import { EnterpriseMinimal, useActiveEnterprises } from "@ncs/ncs-api"

import { ExtendableSearchableSelectProps, SearchableSelect } from "../inputs"

export interface EnterpriseSelectorProps
	extends ExtendableSearchableSelectProps<EnterpriseMinimal> {
	initialId?: string | null
}

export const EnterpriseSelector: FC<EnterpriseSelectorProps> = ({
	value,
	onChange,
	initialId,
	...rest
}) => {
	const [enterprises, loading] = useActiveEnterprises()
	const hasSetInitial = useRef(false)

	useEffect(() => {
		if (!!initialId && !hasSetInitial.current && !!enterprises) {
			const option = enterprises.find((t) => t.id === initialId)

			if (option) {
				onChange(option)
				hasSetInitial.current = true
			}
		}
	}, [initialId, enterprises, onChange])

	return (
		<SearchableSelect
			label="Enterprise"
			placeholder="Search for an enterprise..."
			{...rest}
			value={value}
			onItemSelect={onChange}
			getOptionLabel={(option) => option.name ?? option.id}
			options={enterprises ?? []}
			disabled={loading}
			useSearchIcon
		/>
	)
}

import React from "react"
import PropTypes from "prop-types"
import { default as NumericInput, CustomNumericInputProps } from "./CustomNumericInput"

const CustomCurrencyInput: React.FC<CustomNumericInputProps> = ({
	thousandSeparator = true,
	decimalScale = 2,
	fixedDecimalScale = true,
	prefix = "$",
	displayType = "input",
	...rest
}) => {
	return (
		<NumericInput
			thousandSeparator={thousandSeparator}
			decimalScale={decimalScale}
			fixedDecimalScale={fixedDecimalScale}
			prefix={prefix}
			displayType={displayType}
			{...rest}
		/>
	)
}

CustomCurrencyInput.propTypes = {
	...NumericInput.propTypes,
	/** receives a numeric parameter representing the input amount and the event object */
	onChange: PropTypes.func,
	/** receives an object with formattedValue, value, and floatValue properties, along with the event object.
	 * The floatValue property is what is returned in onChange, which should be used most of the time */
	onValueChange: PropTypes.func,
	value: PropTypes.number,
}

export default React.memo(CustomCurrencyInput)

import { Dispatch, FC, memo, SetStateAction } from "react"

import { css } from "@emotion/react"
import produce from "immer"

import { extractNumber } from "@ncs/ts-utils"
import { Box } from "@ncs/web-legos"

import { binQtyRowHeight, LineFormState } from "./receive-parts-modal-util"

export interface ReceivePartsQtyListProps {
	lineItemId: string
	destinationId: string
	binQuantities: LineFormState[string]["destinations"][string]["binQuantities"]
	setLineFormState: Dispatch<SetStateAction<LineFormState>>
	showWarning: boolean
}

export const ReceivePartsQtyList: FC<ReceivePartsQtyListProps> = memo(
	({ lineItemId, destinationId, binQuantities, setLineFormState, showWarning }) => {
		const updateQuantity = (update: { binQuantityId: string; newQuantity?: number }) => {
			const { binQuantityId, newQuantity } = update

			setLineFormState((prev) => {
				return produce(prev, (draft) => {
					const binQuantity =
						draft[lineItemId].destinations[destinationId].binQuantities[binQuantityId]

					if (newQuantity !== undefined) {
						binQuantity.quantity = newQuantity
					}
				})
			})
		}

		return (
			<Box display="flex" flexDirection="column">
				{Object.values(binQuantities).map(({ binQuantityId, quantity }) => {
					return (
						<Box key={binQuantityId} height={binQtyRowHeight}>
							<input
								value={quantity}
								css={showWarning ? warnedInputCss : undefined}
								onChange={(e) =>
									updateQuantity({
										binQuantityId: binQuantityId,
										newQuantity: extractNumber(e.target.value),
									})
								}
							/>
						</Box>
					)
				})}
			</Box>
		)
	}
)

const warnedInputCss = css`
	background: #fff8d0;
`

ReceivePartsQtyList.displayName = "ReceivePartsQtyList"

import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core"

import { Button, ConditionalContent } from "../../components"

import ColumnChooser from "./ColumnChooser"
import ReportExporter from "./ReportExporter"
import ResetFiltersButton from "./ResetFiltersButton"

const styles = {
	tableActionsWrapper: {
		display: "flex",
		justifyContent: "space-between",
	},
	resetFilters: {
		display: "flex",
		justifyContent: "fex-start",
		alignItems: "bottom",
	},
	columnChooser: {
		display: "flex",
		justifyContent: "flex-end",
		alignItems: "bottom",
	},
}

class DynamicTableActions extends React.PureComponent {
	render() {
		const {
			classes,
			showResetFiltersButton,
			extraButtons,
			onResetFilters,
			onToggleColumn,
			selectedColumns,
			toggleableColumns,
			exportDataAction,
			generateExportFilenameFn,
			generateQueryFn,
			showDownload,
			downloadFn,
			actionProps,
		} = this.props

		return (
			<div className={classes.tableActionsWrapper}>
				<div className={classes.resetFilters}>
					{showResetFiltersButton && (
						<ResetFiltersButton onResetFilters={onResetFilters} />
					)}
					{extraButtons}
				</div>
				<div className={classes.columnChooser}>
					<ConditionalContent show={showDownload}>
						<Button
							onClick={() => {
								let params = generateQueryFn()
								downloadFn(params)
							}}
						>
							Download
						</Button>
					</ConditionalContent>
					<ReportExporter
						exportDataAction={exportDataAction}
						generateQueryFn={generateQueryFn}
						generateExportFilenameFn={generateExportFilenameFn}
						{...actionProps}
					/>
					<ColumnChooser
						toggleColumn={onToggleColumn}
						selectedColumns={selectedColumns || []}
						toggleableColumns={toggleableColumns || []}
					/>
				</div>
			</div>
		)
	}
}

DynamicTableActions.propTypes = {
	/** shows the reset filters button. use this to handle resetting filters elsewhere */
	showResetFiltersButton: PropTypes.bool,

	/** more buttons (or any node) to show next to the reset filters button (or where it would be if hidden). */
	extraButtons: PropTypes.node,

	/** handler for the reset filters button */
	onResetFilters: PropTypes.func,

	/** handler called when a toggleable column is toggled */
	onToggleColumn: PropTypes.func,

	/** array of Headers of columns currently shown */
	selectedColumns: PropTypes.array,

	/** array of Headers of columns that can be toggled */
	toggleableColumns: PropTypes.array,

	/** function that initiates report export and returns a report export object */
	exportDataAction: PropTypes.func,

	/** function that returns an object with querystring parameters, (same one used for fetchFn) */
	generateQueryFn: PropTypes.func,

	/** function that returns a filename for the report as exported */
	generateExportFilenameFn: PropTypes.func,

	/** extra props containing redux actions specific to a client's endpoints **/
	actionProps: PropTypes.object,
}

export default withStyles(styles)(DynamicTableActions)

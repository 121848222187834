import React from "react"
import { CheckBox, Close, Send } from "@material-ui/icons"
import { ButtonWithIcon } from "~/components"
import { makeStyles } from "@material-ui/core"
import { submissionControlsStyles } from "./submissionControlsStyles"

const useStyles = makeStyles(submissionControlsStyles)

const SubmissionControls = ({
	bulkCheckChange,
	checkedTally,
	readyTally,
	washCounts,
	isSubmitting,
	isLoading,
	onSubmit,
}) => {
	const classes = useStyles()

	return (
		<div className={classes.submissionControls}>
			<div className={classes.message}>
				{checkedTally > 0 &&
					`(${checkedTally} invoices out of ${washCounts.length} selected for submission)`}
			</div>
			<div className={classes.buttonRow}>
				<ButtonWithIcon
					onClick={() => bulkCheckChange(true)}
					color="primary"
					icon={<CheckBox />}
					boxShadowHover={false}
					size="sm"
					disabled={washCounts.length === 0 || isLoading}
				>
					Select All Ready ({readyTally})
				</ButtonWithIcon>

				{checkedTally > 0 && (
					<ButtonWithIcon
						icon={<Close />}
						onClick={() => bulkCheckChange(false)}
						color="transparent"
						size="sm"
						noMargin
						style={{ marginLeft: "0.5rem" }}
					>
						Uncheck all
					</ButtonWithIcon>
				)}
				<div style={{ marginLeft: "auto" }}>
					<ButtonWithIcon
						icon={<Send />}
						color="success"
						boxShadowHover={false}
						size="sm"
						disabled={checkedTally === 0}
						noMargin
						loading={isSubmitting}
						onClick={onSubmit}
					>
						Submit Selected Invoices ({checkedTally})
					</ButtonWithIcon>
				</div>
			</div>
		</div>
	)
}

export default React.memo(SubmissionControls)

import { FC, useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"

import { InventoryPart, makeApiErrorMessage, useUpdateInventoryPart } from "@ncs/ncs-api"
import {
	Disabled,
	ExtendableModalProps,
	Modal,
	RadioBoolean,
	TextInputFormField,
	useChangeCallback,
	useToast,
} from "@ncs/web-legos"

export interface EditPartHazmatModalProps extends ExtendableModalProps {
	part: InventoryPart
}

export const EditPartHazmatModal: FC<EditPartHazmatModalProps> = ({ part, ...rest }) => {
	const { makeSuccessToast } = useToast()
	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)
	const [isHazmat, setIsHazmat] = useState(!!part.hazmat)

	const {
		control,
		handleSubmit,
		reset,
		formState: { isValid, submitCount },
	} = useForm<HazmatForm>({
		resolver: zodResolver(HazmatFormSchema),
		defaultValues:
			part.hazmat ? getHazmatFormDefaultValuesFromPart(part) : hazmatFormDefaultValues,
	})

	const updatePart = useUpdateInventoryPart()

	const handleSaveNonHazmat = async () => {
		try {
			setIsSaving(true)
			await updatePart({
				updates: { hazmat: null },
				id: part.id,
			})
			makeSuccessToast("Part updated")
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const handleSaveHazmat = async (formData: HazmatForm) => {
		try {
			setIsSaving(true)
			await updatePart({
				updates: { hazmat: formData },
				id: part.id,
			})
			makeSuccessToast("Part updated")
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	useChangeCallback(isHazmat, (newHazmatState) => {
		if (newHazmatState) {
			reset(getHazmatFormDefaultValuesFromPart(part))
		} else {
			reset({ ...hazmatFormDefaultValues })
		}
	})

	return (
		<Modal
			{...rest}
			title="Edit Part Hazmat"
			rightButtons={{
				buttonText: "Save Changes",
				isLoading: isSaving,
				onClick: isHazmat ? handleSubmit(handleSaveHazmat) : handleSaveNonHazmat,
				disabled: isHazmat && !isValid && submitCount > 0,
			}}
			errorText={errorText}
		>
			<RadioBoolean
				value={isHazmat}
				onChange={setIsHazmat}
				htmlName="is-hazmat"
				description="Is this a hazmat part?"
				noFirst
			/>

			<Disabled disabled={!isHazmat}>
				<TextInputFormField
					control={control}
					name="idNumber"
					label="ID number"
					returnEmptyString
				/>
				<TextInputFormField
					control={control}
					name="shippingName"
					label="Shipping name"
					returnEmptyString
				/>
				<TextInputFormField
					control={control}
					name="description"
					label="Description"
					returnEmptyString
				/>
				<TextInputFormField
					control={control}
					name="hazardClass"
					label="Hazard class"
					returnEmptyString
				/>
				<TextInputFormField
					control={control}
					name="hazmatType"
					label="Hazmat type"
					returnEmptyString
				/>
				<TextInputFormField
					control={control}
					name="packingGroup"
					label="Packing group"
					returnEmptyString
				/>
				<TextInputFormField
					control={control}
					name="additionalInformation"
					label="Additional information (optional)"
				/>
			</Disabled>
		</Modal>
	)
}

const HazmatFormSchema = z.object({
	additionalInformation: z.string().max(255).nullable(),
	description: z.string().min(1, "Required").max(255),
	hazardClass: z.string().min(1, "Required").max(255),
	hazmatType: z.string().min(1, "Required").max(255),
	idNumber: z.string().min(1, "Required").max(255),
	packingGroup: z.string().min(1, "Required").max(255),
	shippingName: z.string().min(1, "Required").max(255),
})

type HazmatForm = z.infer<typeof HazmatFormSchema>

const hazmatFormDefaultValues: HazmatForm = {
	additionalInformation: null,
	description: "",
	hazardClass: "",
	hazmatType: "",
	idNumber: "",
	packingGroup: "",
	shippingName: "",
}

const getHazmatFormDefaultValuesFromPart = ({ hazmat }: InventoryPart): HazmatForm => {
	return {
		additionalInformation:
			hazmat?.additionalInformation || hazmatFormDefaultValues.additionalInformation,
		description: hazmat?.description || hazmatFormDefaultValues.description,
		hazardClass: hazmat?.hazardClass || hazmatFormDefaultValues.hazardClass,
		hazmatType: hazmat?.hazmatType || hazmatFormDefaultValues.hazmatType,
		idNumber: hazmat?.idNumber || hazmatFormDefaultValues.idNumber,
		packingGroup: hazmat?.packingGroup || hazmatFormDefaultValues.packingGroup,
		shippingName: hazmat?.shippingName || hazmatFormDefaultValues.shippingName,
	}
}

import { FC, useState } from "react"

import { useFormContext } from "react-hook-form"

import { addOrReplace } from "@ncs/ts-utils"
import { Box, Button } from "@ncs/web-legos"

import {
	ContractIncentiveProgram as IContractIncentiveProgram,
	NewContractForm,
} from "../contract-utils"
import { ContractIncentiveProgram } from "../ContractIncentiveProgram"
import { ContractIncentiveProgramModal } from "../ContractIncentiveProgramModal"

export const NewContractIncentives: FC = () => {
	const [showNew, setShowNew] = useState(false)
	const [editIncentiveIndex, setEditIncentiveIndex] = useState<number | null>(null)

	const { watch, setValue } = useFormContext<NewContractForm>()
	const [formIncentives] = watch(["incentives"])

	const saveFormIncentive = (incentive: IContractIncentiveProgram) => {
		setValue(
			"incentives",
			addOrReplace(incentive, formIncentives, (_, i) => i === editIncentiveIndex),
			{
				shouldValidate: true,
			}
		)
	}

	const removeFormIncentive = (indexToDelete: number) => {
		setValue(
			"incentives",
			formIncentives.filter((_, i) => i !== indexToDelete),
			{
				shouldValidate: true,
			}
		)
	}

	return (
		<>
			{formIncentives.map((incentive, i) => (
				<Box key={incentive.categories.map((c) => c.name).join("-")} mb={1.5}>
					<ContractIncentiveProgram
						onEdit={() => setEditIncentiveIndex(i)}
						onRemove={() => removeFormIncentive(i)}
						incentive={incentive}
					/>
				</Box>
			))}
			<Button icon="plus" onClick={() => setShowNew(true)}>
				Add incentive program
			</Button>

			<ContractIncentiveProgramModal
				isOpen={showNew || editIncentiveIndex != null}
				onClose={() => {
					setShowNew(false)
					setEditIncentiveIndex(null)
				}}
				incentive={
					editIncentiveIndex != null ? formIncentives[editIncentiveIndex] ?? null : null
				}
				onSave={(incentive) => {
					saveFormIncentive(incentive)
				}}
			/>
		</>
	)
}

import React from "react"
import PropTypes from "prop-types"

// custom components
import {
	GridContainer,
	GridItem,
	Button,
	Card,
	CardBody,
	CardHeader,
	CardText,
	CardFooter,
	ConditionalContent,
} from "~/components"
import CartTable from "./CartTable"

// icons
import ArrowBack from "@material-ui/icons/ArrowBack"
import ArrowForward from "@material-ui/icons/ArrowForward"

// styles
import withStyles from "@material-ui/core/styles/withStyles"
import extendedTablesStyle from "@ncs/bricks/assets/jss/material-dashboard-pro-react/views/extendedTablesStyle"

const ViewCart = (props) => {
	const { classes, onUpdateQuantity, onRemoveFromCart, items, onBackToList, onViewCheckout } =
		props

	return (
		<GridContainer>
			<GridItem xs={12} s={12} md={12}>
				<Card>
					<CardHeader color="rose" text>
						<CardText color="rose">
							<h4 className={classes.cardTitle}>{"My Cart"}</h4>
						</CardText>
					</CardHeader>
					{items.length > 0 ?
						<CardBody>
							<CartTable
								items={items}
								canUpdateQuantity={true}
								onUpdateQuantity={onUpdateQuantity}
								onRemoveFromCart={onRemoveFromCart}
							/>
						</CardBody>
					:	<CardBody>
							<GridContainer>{"Cart is Empty"}</GridContainer>
						</CardBody>
					}
					<CardFooter>
						<GridContainer>
							<GridItem xs={12}>
								<Button round onClick={onBackToList}>
									<ArrowBack />
									{"Back to List"}
								</Button>
								<ConditionalContent show={items.length > 0}>
									<Button round color="success" onClick={onViewCheckout}>
										{"Proceed to Checkout"}
										<ArrowForward />
									</Button>
								</ConditionalContent>
							</GridItem>
						</GridContainer>
					</CardFooter>
				</Card>
			</GridItem>
		</GridContainer>
	)
}

ViewCart.propTypes = {
	classes: PropTypes.object.isRequired,
	onUpdateQuantity: PropTypes.func.isRequired,
	onRemoveFromCart: PropTypes.func.isRequired,
	items: PropTypes.array.isRequired,
	onBackToList: PropTypes.func.isRequired,
	onViewCheckout: PropTypes.func.isRequired,
}

export default withStyles(extendedTablesStyle)(ViewCart)

import { FC } from "react"

import { useHistory, useParams } from "react-router-dom"

import { useChemicalCustomerDetail } from "@ncs/ncs-api"
import {
	Button,
	Card,
	encodeUrlState,
	Icon,
	LoadingSpinner,
	Tabs,
	useUrlState,
} from "@ncs/web-legos"

import { CustomerChemicalsUrlState } from "../customer-chemicals"
import { ChemicalContainersTab, PackagesTab } from "./components"

export type CustomerChemicalsDetailUrlState = {
	currentTab: CustomerChemicalDetailsTab
}

export enum CustomerChemicalDetailsTab {
	ChemicalContainers = "Chemical Containers",
	Packages = "Packages",
}

export const CustomerChemicalsDetail: FC = () => {
	const { customerId } = useParams<{ customerId: string }>()
	const history = useHistory<{ params: CustomerChemicalsUrlState }>()
	const [{ currentTab }, { updateUrlValue }] = useUrlState<CustomerChemicalsDetailUrlState>({
		currentTab: CustomerChemicalDetailsTab.ChemicalContainers,
	})

	const [customer, customerLoading] = useChemicalCustomerDetail(customerId)

	if (customerLoading) {
		return (
			<Card>
				<LoadingSpinner />
			</Card>
		)
	}
	if (!customer) {
		return <Card>Customer not found</Card>
	}

	return (
		<>
			<Button
				icon="long-arrow-left"
				containerProps={{ mb: 3 }}
				onClick={() =>
					history.push({
						pathname: "/customers/customer-chemicals",
						search: encodeUrlState(history.location.state?.params ?? {}),
					})
				}
			>
				All customer chemicals
			</Button>

			<Card
				heading={`(${customer.customerNumber}) ${customer.customerName}`}
				headingIcon="car-wash"
				headingDetail="Customer Chemicals"
			>
				<Tabs
					currentTab={currentTab}
					onChange={(newTab) => updateUrlValue("currentTab", newTab)}
					panels={[
						{
							navLabel: CustomerChemicalDetailsTab.ChemicalContainers,
							navIcon: <Icon icon="prescription-bottle" />,
							component: <ChemicalContainersTab chemicalCustomerDetail={customer} />,
						},
						{
							navLabel: CustomerChemicalDetailsTab.Packages,
							navIcon: <Icon icon="raindrops" />,
							component: <PackagesTab chemicalCustomerDetail={customer} />,
						},
					]}
				/>
			</Card>
		</>
	)
}

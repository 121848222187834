import { FC, useEffect, useMemo, useRef, useState } from "react"

import dayjs, { Dayjs } from "dayjs"
import produce from "immer"
import { Cell, Column } from "react-table"

import {
	makeApiErrorMessage,
	PartOrderLineItem,
	PartOrderPickList,
	useCreatePartOrderPickList,
	useLocationPickListAssignments,
	UserMinimal,
} from "@ncs/ncs-api"
import { formatNumber } from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	Box,
	Button,
	Callout,
	Checkbox,
	Chip,
	DateTimeInput,
	ExtendableModalProps,
	LetterIcon,
	Modal,
	NumericInput,
	Paragraph,
	RadioBoolean,
	SkeletonRows,
	Table,
	ThrottledNumericInput,
	ThrottledTextarea,
	Tooltip,
	UserSelector,
	useToast,
} from "@ncs/web-legos"

export interface GeneratePickListModalProps extends ExtendableModalProps {
	partOrderId: string
	showShipCompleteWarning: boolean
	location: PartOrderPickList["location"]
	lineItems: PartOrderLineItem[] // Every line item on the order.
}

interface LineItemsToInclude {
	[lineItemId: string]: {
		lineItemId: string
		description: string
		partId: string | undefined
		partNumber: string | undefined
		partName: string | undefined
		partWeight: number | undefined
		qtyAvailable: number // From the location, not the whole line.
		qtyToPick: number | null // Form value for the amount to put on the pick list.
		hazmat: boolean
	}
}

export const GeneratePickListModal: FC<GeneratePickListModalProps> = ({
	partOrderId,
	showShipCompleteWarning,
	location,
	lineItems,
	...rest
}) => {
	const { makeSuccessToast } = useToast()
	const [includeAll, setIncludeAll] = useState(true)
	const [showLineItems, setShowLineItems] = useState(false)
	const [lineItemsToInclude, setLineItemsToInclude] = useState<LineItemsToInclude>(() =>
		buildStateFromLineItems(location.id, lineItems)
	)
	const originalLineItemsToInclude = useRef(buildStateFromLineItems(location.id, lineItems))
	const [user, setUser] = useState<UserMinimal | null>(null)
	const [comment, setComment] = useState<string | null>(null)
	const [palletCount, setPalletCount] = useState<number | null>(null)
	const [isGeodis, setIsGeodis] = useState(false)
	const [shipDate, setShipDate] = useState<Dayjs | null>(() => {
		// If after 11am, set to the next day, otherwise, today.
		return dayjs().local().isAfter(dayjs().local().set("h", 11)) ?
				dayjs().add(1, "d")
			:	dayjs()
	})
	const [errorText, setErrorText] = useState<string | null>(null)
	const [isSaving, setIsSaving] = useState(false)

	const [recentAssignments, recentAssignmentsLoading] = useLocationPickListAssignments(
		location.id
	)
	const createPickList = useCreatePartOrderPickList(partOrderId)

	const handleSetToZero = () => {
		setLineItemsToInclude((prev) => {
			const update = produce(prev, (draft) => {
				Object.keys(draft).forEach((lineItemId) => {
					draft[lineItemId].qtyToPick = 0
				})
			})
			return update
		})
	}

	const handleReset = () => {
		setLineItemsToInclude(() => buildStateFromLineItems(location.id, lineItems))
	}

	const handleSave = async () => {
		if (!user) {
			setErrorText("Must choose someone to fulfill the pick list")
			return
		}
		if (isGeodis && !shipDate) {
			setErrorText("Ship date and time required for Geodis (LTL)")
			return
		}

		try {
			Object.values(lineItemsToInclude).forEach((lineItem) => {
				if (lineItem.qtyToPick !== null && lineItem.qtyToPick > lineItem.qtyAvailable) {
					throw new Error(
						`Item ${lineItem.description} has a pick quantity greater than the quantity on the line`
					)
				}
			})
			if (Object.values(lineItemsToInclude).every((lineItem) => !lineItem.qtyToPick)) {
				throw new Error(
					"At least one line item must have a pick list quantity greater than zero"
				)
			}
			setIsSaving(true)
			await createPickList({
				assignedTo: user.id,
				locationId: location.id,
				lines: Object.values(lineItemsToInclude).map((line) => ({
					partOrderLineId: line.lineItemId,
					quantity: line.qtyToPick || 0,
				})),
				comment,
				shipDate: shipDate?.toISOString() ?? null,
				geodis: isGeodis,
				numberOfPallets: palletCount,
			})
			makeSuccessToast("Pick list created")
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const columns = useMemo((): Column<LineItemsToInclude[string]>[] => {
		return [
			{
				Header: "Part #",
				id: "part-number",
				Cell: ({ row: { original } }: Cell<LineItemsToInclude[string]>) => {
					return (
						<Box d="flex" alignItems="flex-start" gap={0.5}>
							<span>{original.partNumber}</span>
							{!!original.hazmat && (
								<Tooltip title="Part is hazmat">
									<LetterIcon letter="H" />
								</Tooltip>
							)}
						</Box>
					)
				},
			},
			{
				Header: "Description",
				accessor: ({ description, partName }) => partName || description,
			},
			{
				Header: "Qty available",
				accessor: "qtyAvailable",
			},
			{
				Header: "Qty for pick list",
				id: "qty-to-pick",
				Cell: ({ row: { original } }: Cell<LineItemsToInclude[string]>) => {
					return (
						<ThrottledNumericInput
							mb={0}
							value={original.qtyToPick}
							disabled={includeAll}
							placeholder="Qty..."
							maxWidth={5}
							min={0}
							max={original.qtyAvailable}
							onChange={(newValue) => {
								setLineItemsToInclude((prev) => {
									return produce(prev, (draft) => {
										draft[original.lineItemId].qtyToPick = newValue ?? null
									})
								})
							}}
						/>
					)
				},
			},
		]
	}, [includeAll])

	const totalWeight = useMemo(() => getTotalWeight(lineItemsToInclude), [lineItemsToInclude])

	useEffect(() => {
		setErrorText(null)
	}, [user, lineItemsToInclude, isGeodis, shipDate])

	return (
		<Modal
			{...rest}
			maxWidth="md"
			title={`Generate Pick List: ${location.description}`}
			rightButtons={{
				buttonText: "Generate and assign",
				onClick: handleSave,
				isLoading: isSaving,
			}}
			errorText={errorText}
		>
			{showShipCompleteWarning && (
				<Callout variant="warning" icon="exclamation-triangle" mb={2}>
					<Paragraph>
						This order is <strong>Ship Complete</strong>. It should ship from only one
						DC and have only one pick list generated for all ordered items.
					</Paragraph>
				</Callout>
			)}

			<Paragraph mb={0.75}>Who should fulfill the pick list?</Paragraph>
			<Box mb={1}>
				<UserSelector
					value={user}
					onChange={setUser}
					employeesOnly
					label={null}
					placeholder="Search employees..."
					maxWidth="50%"
					smProps={{ maxWidth: "none " }}
				/>

				{recentAssignmentsLoading && <SkeletonRows rows={1} width={10} />}

				{!!recentAssignments?.length && (
					<Box mt={1}>
						<Paragraph small secondary mb={0.5}>
							Recent assignments:
						</Paragraph>
						<Box display="flex" gap={0.5} flexWrap="wrap">
							{recentAssignments.map((u) => {
								return (
									<Chip
										key={u.id}
										label={u.name}
										onClick={() => setUser(u)}
										icon="plus"
									/>
								)
							})}
						</Box>
					</Box>
				)}
			</Box>

			<Checkbox value={isGeodis} onChange={setIsGeodis} label="Geodis (LTL)" />

			<NumericInput
				value={palletCount}
				onChange={(value) => setPalletCount(value ?? null)}
				label="Number of pallets"
				fillContainer
				maxWidth={10}
				smProps={{ maxWidth: "none" }}
				mb={1.5}
			/>

			<DateTimeInput
				value={shipDate}
				onChange={setShipDate}
				label="Expected ship date and time"
				placeholder="Ship date / time..."
				maxWidth="50%"
				smProps={{ maxWidth: "none" }}
				muiProps={{ disablePast: true }}
				clearable={isGeodis === false}
				tooltip="Required if Geodis (LTL)"
			/>

			<ThrottledTextarea
				value={comment}
				onChange={setComment}
				label="Comments (optional)"
				placeholder="Any additional comments..."
				rows={2}
				maxWidth="50%"
				mb={2}
				smProps={{ maxWidth: "none " }}
			/>

			<RadioBoolean
				description="Which line items should be on the pick list?"
				htmlName="include-all"
				value={includeAll}
				onChange={(newState) => {
					if (newState) {
						// If you're setting to include all, then reset the values to original.
						handleReset()
					} else {
						setShowLineItems(true)
					}
					setIncludeAll(newState)
				}}
				yesText={`Include all available (${
					Object.keys(originalLineItemsToInclude.current).length
				} line item${
					Object.keys(originalLineItemsToInclude.current).length !== 1 ? "s" : ""
				}, ${formatNumber(getTotalWeight(originalLineItemsToInclude.current))} lbs)`}
				noText="Choose which items and quantities to include"
			/>

			<AnimatedEntrance show={showLineItems} direction="down" mb={3}>
				{!includeAll && (
					<Box display="flex" justifyContent="flex-end" gap={1} mb={0.5}>
						<Button icon="empty-set" onClick={handleSetToZero}>
							Set all to 0
						</Button>
						<Button icon="undo" onClick={handleReset}>
							Reset all
						</Button>
					</Box>
				)}
				<Table
					data={Object.values(lineItemsToInclude)}
					columns={columns}
					rowVerticalAlign="middle"
				/>
				<Paragraph textAlign="right">
					List total weight: <strong>{formatNumber(totalWeight)} lbs</strong>
				</Paragraph>
			</AnimatedEntrance>

			<Button
				icon={showLineItems ? "angle-up" : "angle-right"}
				onClick={() => setShowLineItems(!showLineItems)}
				containerProps={{ mb: 2 }}
			>
				{showLineItems ? "Hide line items" : "Show line items"}
			</Button>
		</Modal>
	)
}

/**
 * Take every line item on the order and narrow it down to just pickable at this particular location.
 */
const buildStateFromLineItems = (
	locationId: string,
	lineItems: PartOrderLineItem[]
): LineItemsToInclude => {
	const state: LineItemsToInclude = {}

	const locationLines = lineItems.flatMap((line) =>
		[
			...line.locationLines.filter((locationLine) => {
				const rightLocation = locationLine.locationId.toString() === locationId
				const needsMore = locationLine.quantityRequested > 0
				const hasSomeAvailable = locationLine.quantityAvailable > 0

				return rightLocation && needsMore && hasSomeAvailable
			}),
		].map((locationLine) => ({
			...locationLine,
			lineItemId: line.id,
			lineItemDescription: line.description,
			partId: line.part?.id,
			partNumber: line.part?.partNumber,
			partDescription: line.part?.description,
			partHazmat: line.part?.hazmat,
			weight: line.part?.weight || 0,
		}))
	)

	locationLines.forEach((line) => {
		state[line.lineItemId] = {
			lineItemId: line.lineItemId,
			description: line.lineItemDescription,
			partId: line.partId,
			partNumber: line.partNumber,
			partName: line.partDescription,
			partWeight: line.weight,
			qtyAvailable: Math.min(line.quantityAvailable, line.quantityRequested),
			qtyToPick: Math.min(line.quantityAvailable, line.quantityRequested),
			hazmat: !!line.partHazmat,
		}
	})

	return state
}

const getTotalWeight = (lineItems: LineItemsToInclude): number => {
	return Math.round(
		Object.values(lineItems).reduce((total, lineItem) => {
			return total + (lineItem.partWeight || 0) * (lineItem.qtyToPick || 0)
		}, 0)
	)
}

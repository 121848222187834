/**
 * Notice: Adapted from https://github.com/TeamWertarbyte/material-ui-chip-input/blob/master/src/ChipInput.js
 *         Feature set trimmed significantly, so if you want this to do more, check for an existing implementation
 */
import React, { useState } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import {
	InputLabel,
	Chip,
	FormControl,
	FormHelperText,
	FormHelperTextProps as MaterialUIFormHelperTextProps,
	makeStyles,
	InputLabelProps,
	FormControlProps,
	ChipProps,
} from "@material-ui/core"

import styles from "../../assets/jss/material-dashboard-pro-react/components/searchResultChipStyle"
import { twitterColor } from "../../assets/jss/material-dashboard-pro-react"

const useStyles = makeStyles(styles)

export interface ChipRendererProps {
	value: { [key: string]: unknown }
	text?: string
	chip?: unknown
	isDisabled?: boolean
	isFocused?: boolean
	handleClick?: ChipProps["onClick"]
	handleDelete: ChipProps["onDelete"]
	className?: string
}

export const defaultChipRenderer = (
	{ text, isDisabled, handleClick, handleDelete, className }: ChipRendererProps,
	key: string
) => {
	return (
		<Chip
			key={key}
			className={className}
			style={{
				pointerEvents: isDisabled ? "none" : undefined,
				backgroundColor: twitterColor,
			}}
			onClick={handleClick}
			onDelete={handleDelete}
			label={text}
		/>
	)
}

export interface SearchResultChipProps extends Omit<FormControlProps, "onChange"> {
	/** A function of the type `({ value, text, chip, isFocused, isDisabled, handleClick, handleDelete, className }, key) => node` that returns a chip based on the given properties. This can be used to customize chip styles. */
	chipRenderer?: (props: ChipRendererProps, key: string) => React.ReactNode

	/** Props to pass through to the `FormHelperText` component. */
	FormHelperTextProps?: MaterialUIFormHelperTextProps

	/** If true, the chip input will fill the available width. */
	fullWidth?: boolean

	/** Helper text that is displayed below the input. */
	helperText?: React.ReactNode

	/** Props to pass through to the `InputLabel`. */
	InputLabelProps?: InputLabelProps

	/* The content of the floating label. */
	label?: React.ReactNode

	/** Callback function that is called when a new chip was removed (in controlled mode). */
	onDelete?: (chip: unknown, i: number) => void

	/** The chips to display (enables controlled mode if set). */
	value?: unknown[]

	rootRef: FormControlProps["ref"]
	onChange: (chips: unknown[]) => void
}

const SearchResultChip: React.FC<SearchResultChipProps> = ({
	chipRenderer = defaultChipRenderer,
	className,
	disabled,
	error,
	FormHelperTextProps,
	fullWidth = true,
	helperText,
	InputLabelProps = {},
	label,
	required,
	rootRef,
	onDelete,
	onChange,
	value,
	...other
}) => {
	const [state, setState] = useState({
		isFocused: false,
		errorText: undefined,
		isClean: true,
		chips: [],
	})

	const classes = useStyles()

	function handleDeleteChip(chip: string, i: number) {
		if (value) {
			if (typeof onDelete === "function") {
				onDelete(chip, i)
			}
			return
		}

		const chips = state.chips.slice()
		const changed = chips.splice(i, 1) // remove the chip at index i
		if (changed) {
			setState({ ...state, chips })
			if (typeof onChange === "function") {
				onChange(chips)
			}
		}
	}

	const chips = value || state.chips
	const hasInput = chips.length > 0
	const shrinkFloatingLabel =
		InputLabelProps.shrink != null ?
			InputLabelProps.shrink
		:	label != null && (hasInput || state.isFocused)

	return (
		<FormControl
			ref={rootRef}
			fullWidth={fullWidth}
			className={cx(className, classes.root)}
			error={error}
			required={required}
			onClick={focus}
			disabled={disabled}
			{...other}
		>
			{label && (
				<InputLabel
					classes={{ root: classes.label, shrink: classes.labelShrink }}
					shrink={shrinkFloatingLabel}
					focused={state.isFocused}
					{...InputLabelProps}
				>
					{label}
				</InputLabel>
			)}
			<div
				className={cx(classes.chipContainer, {
					[classes.inkbar]: true,
					[classes.focused]: state.isFocused,
					[classes.underline]: true,
					[classes.disabled]: disabled,
					[classes.labeled]: label != null,
					[classes.error]: error,
				})}
			>
				{chips.map((tag, i) => {
					const value = tag
					return chipRenderer(
						{
							value: value as { [key: string]: unknown },
							text: tag as string,
							isDisabled: !!disabled,
							handleDelete: () => handleDeleteChip(value as string, i),
							className: classes.chip,
						},
						String(i)
					)
				})}
			</div>
			{helperText && (
				<FormHelperText
					{...FormHelperTextProps}
					className={
						FormHelperTextProps ?
							cx(FormHelperTextProps.className, classes.helperText)
						:	classes.helperText
					}
				>
					{helperText}
				</FormHelperText>
			)}
		</FormControl>
	)
}

SearchResultChip.propTypes = {
	/** A function of the type `({ value, text, chip, isFocused, isDisabled, handleClick, handleDelete, className }, key) => node` that returns a chip based on the given properties. This can be used to customize chip styles. */
	chipRenderer: PropTypes.func,
	/** Props to pass through to the `FormHelperText` component. */
	FormHelperTextProps: PropTypes.object,
	/** If true, the chip input will fill the available width. */
	fullWidth: PropTypes.bool,
	/** Helper text that is displayed below the input. */
	helperText: PropTypes.node,
	/** Props to pass through to the `InputLabel`. */
	InputLabelProps: PropTypes.object,
	/* The content of the floating label. */
	label: PropTypes.node,
	/** Callback function that is called when a new chip was removed (in controlled mode). */
	onDelete: PropTypes.func,
	/** The chips to display (enables controlled mode if set). */
	value: PropTypes.array,
}

export default React.memo(SearchResultChip)

import { FC, Fragment, useMemo, useState } from "react"

import uniqBy from "lodash/uniqBy"
import { Cell, Column } from "react-table"

import {
	BulkReceivePurchaseOrderPost,
	GenericLineItem,
	lineItemsAreEqual,
	makeApiErrorMessage,
	PurchaseOrder,
	PurchaseOrderLineItemMin,
	useAddPurchaseOrderLineItem,
	useBulkReceivePurchaseOrder,
	useDeletePurchaseOrderLineItem,
	useUpdatePurchaseOrderLineItem,
} from "@ncs/ncs-api"
import { extractNumber, formatCurrency, formatNumber } from "@ncs/ts-utils"
import {
	Box,
	Button,
	EmptyValueDash,
	Heading,
	Link,
	Table,
	TableProps,
	useScreenSizeMatch,
	useToast,
} from "@ncs/web-legos"

import {
	LineItemEditorModal,
	ReceivePartsModal,
	ReceivePartsModalProps,
	SaveLineItemExtras,
} from "~/components"

import {
	lineItemToPurchaseOrderLineItemPatch,
	lineItemToPurchaseOrderLineItemPost,
	purchaseOrderLineItemToLineItem,
} from "../purchase-order-detail-util"
import { PurchaseOrderLineTransitDetailsModal } from "./PurchaseOrderLineTransitDetailsModal"

export interface PurchaseOrderDetailLineItemsProps {
	order: PurchaseOrder
	canEdit: boolean
	canReceive: boolean
}

const columns: Column<PurchaseOrderLineItemMin>[] = [
	{
		Header: "Part #",
		accessor: ({ part }) => part?.partNumber ?? <EmptyValueDash />,
	},
	{
		Header: "Description",
		accessor: ({ part }) => part?.description ?? "Non-part line item",
	},
	{
		Header: "Qty ordered",
		accessor: ({ quantity }) => formatNumber(extractNumber(quantity)),
	},
	{
		Header: "Qty in-transit",
		accessor: ({ quantityInTransit }) => formatNumber(extractNumber(quantityInTransit)),
	},
	{
		Header: "Qty receivable",
		accessor: ({ quantityReceivable }) => formatNumber(extractNumber(quantityReceivable)),
		hiddenByDefault: true,
	},
	{
		Header: "Qty received",
		accessor: ({ receivedQuantity }) => formatNumber(receivedQuantity),
		hiddenByDefault: true,
	},
	{
		Header: "Unit price",
		accessor: ({ unitPrice }) => (unitPrice ? formatCurrency(unitPrice) : <EmptyValueDash />),
	},
	{
		Header: "Part orders",
		id: "partOrders",
		disableSortBy: true,
		Cell: ({
			row: {
				original: { partPurchaseLine },
			},
		}: Cell<PurchaseOrderLineItemMin>) => {
			return partPurchaseLine.length > 0 ?
					uniqBy(partPurchaseLine, "id").map((line, i) => (
						<Fragment key={line.id}>
							{i !== 0 && ", "}
							<Link
								newTab
								icon="external-link"
								to={`/part-orders/${line.partOrder.id}`}
								iconPosition="trailing"
							>
								{line.partOrder.orderId}
							</Link>
						</Fragment>
					))
				:	<EmptyValueDash />
		},
	},
]

export const PurchaseOrderDetailLineItems: FC<PurchaseOrderDetailLineItemsProps> = ({
	order,
	canEdit,
	canReceive,
}) => {
	const screenIsXs = useScreenSizeMatch("xs")
	const { makeSuccessToast, makeErrorToast } = useToast()
	const addLineItem = useAddPurchaseOrderLineItem(String(order.id))
	const updateLineItem = useUpdatePurchaseOrderLineItem(String(order.id))
	const deleteLineItem = useDeletePurchaseOrderLineItem(String(order.id))
	const receiveParts = useBulkReceivePurchaseOrder()

	const [lineItemToEdit, setLineItemToEdit] = useState<PurchaseOrderLineItemMin | null>(null)
	const [showNewLineItemModal, setShowNewLineItemModal] = useState(false)
	const [lineItemForTransit, setLineItemForTransit] = useState<PurchaseOrderLineItemMin | null>(
		null
	)
	const [showReceiveModal, setShowReceiveModal] = useState(false)

	const onLineItemSave = async (newLine: GenericLineItem, { isEdit }: SaveLineItemExtras) => {
		try {
			if (isEdit && !!lineItemToEdit) {
				await updateLineItem({
					updates: lineItemToPurchaseOrderLineItemPatch(newLine),
					id: String(lineItemToEdit.id),
				})
				makeSuccessToast("Line item updated")
			} else {
				// User did not edit an existing line item, but the new one they added might already be on there.
				const lineAlreadyOnOrder = order.lineItems.some((line) =>
					lineItemsAreEqual(purchaseOrderLineItemToLineItem(line), newLine)
				)
				if (lineAlreadyOnOrder) {
					makeErrorToast("Part already exists on order. Edit existing line instead.")
				} else {
					await addLineItem(lineItemToPurchaseOrderLineItemPost(newLine, order.id))
					makeSuccessToast("Line item added")
				}
			}
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		}
	}

	const onLineItemRemove = async (lineItemId: string) => {
		try {
			await deleteLineItem(lineItemId)
			makeSuccessToast("Line item removed")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		}
	}

	const onReceiveParts: ReceivePartsModalProps["partReceiveHandler"] = async (
		locationId,
		lines
	) => {
		const submission: BulkReceivePurchaseOrderPost = {
			purchaseOrderId: order.id.toString(),
			locationId,
			lines,
		}
		await receiveParts(submission)
	}

	const rowMenu: TableProps<PurchaseOrderLineItemMin, never>["rowMenu"] = useMemo(() => {
		const menuItems: Exclude<
			TableProps<PurchaseOrderLineItemMin, never>["rowMenu"],
			undefined
		>[number][] = []

		if (canEdit) {
			menuItems.push({
				label: "Edit",
				iconName: "pencil",
				onClick: ({ original }) => {
					setLineItemToEdit(original)
				},
			})
		}
		if (order.purchaseInTransit.length > 0) {
			menuItems.push({
				label: "Transit details",
				iconName: "shipping-fast",
				disabledAccessor: ({ original }) => {
					return !order.purchaseInTransit.some((t) => t.part.id === original.part?.id)
				},
				onClick: ({ original }) => {
					setLineItemForTransit(original)
				},
			})
		}

		return menuItems.length > 0 ? menuItems : undefined
	}, [canEdit, order.purchaseInTransit])

	const currentWeightTotal = useMemo(() => {
		return order.lineItems.reduce((total, line) => {
			return total + extractNumber(line.part?.weight ?? 0) * extractNumber(line.quantity)
		}, 0)
	}, [order.lineItems])

	return (
		<>
			<Heading icon="list" variant="h2">
				Line Items
			</Heading>

			<Box pl={1.5}>
				{canReceive && (
					<Button
						buttonText="Receive Items"
						variant="primary-cta"
						icon="scanner-gun"
						onClick={() => setShowReceiveModal(true)}
						containerProps={{ mt: 2 }}
						fillContainer={screenIsXs}
					/>
				)}

				{canEdit && (
					<Button
						buttonText="Add line item"
						variant="secondary-cta"
						icon="plus-circle"
						onClick={() => setShowNewLineItemModal(true)}
						containerProps={{ mt: 2 }}
						fillContainer={screenIsXs}
					/>
				)}

				<Table data={order.lineItems} columns={columns} rowMenu={rowMenu} />
			</Box>

			{(showNewLineItemModal || !!lineItemToEdit) && (
				<LineItemEditorModal
					lineItemToEdit={
						lineItemToEdit ? purchaseOrderLineItemToLineItem(lineItemToEdit) : null
					}
					shipToId={order.shipToCustomer?.id ?? null}
					billToId={order.billToCustomer?.id ?? null}
					onSave={onLineItemSave}
					onRemove={(line) => {
						if (line.id) {
							void onLineItemRemove(line.id)
						}
					}}
					onClose={() => {
						setShowNewLineItemModal(false)
						setLineItemToEdit(null)
					}}
					currentWeightTotal={currentWeightTotal}
					isPurchaseOrder
					skipRestrictedCheck
				/>
			)}
			<PurchaseOrderLineTransitDetailsModal
				isOpen={!!lineItemForTransit}
				onClose={() => setLineItemForTransit(null)}
				lineItem={lineItemForTransit}
				inTransitItems={order.purchaseInTransit}
			/>
			{canReceive && showReceiveModal && (
				<ReceivePartsModal
					onClose={() => setShowReceiveModal(false)}
					titleDetail={`Purchase Order #${order.id}`}
					purchaseOrderId={order.id.toString()}
					lineItems={order.lineItems}
					partReceiveHandler={onReceiveParts}
				/>
			)}
		</>
	)
}

import { FC, useState } from "react"

import dayjs, { Dayjs } from "dayjs"
import utc from "dayjs/plugin/utc"

import { ReportParamKeys, useVacuumTrendReport } from "@ncs/ncs-api"
import { DateFormat, formatDateTime, formatNumber } from "@ncs/ts-utils"
import { Box, ChartCard, ChartType, GenericReportChart, LabeledData, Select } from "@ncs/web-legos"

dayjs.extend(utc)

export interface ChartsTabProps {
	deviceId: string | null
	startDate: Dayjs
	endDate: Dayjs
	isRefreshing?: boolean
}

export const ChartsTab: FC<ChartsTabProps> = ({ deviceId, startDate, endDate }) => {
	const [dataField, setDataField] = useState<string | null>("vib_1")

	const [data, isLoading] = useVacuumTrendReport({
		params: {
			[ReportParamKeys.Field]: dataField,
			[ReportParamKeys.Device]: deviceId,
			[ReportParamKeys.StartDate]: startDate.format(DateFormat.DateTimeQueryParam),
			[ReportParamKeys.EndDate]: endDate.format(DateFormat.DateTimeQueryParam),
		},
	})

	const report = data?.reportData
	const miscData = data?.miscData

	return (
		<ChartCard
			heading="Vacuum Telemetry Data"
			headingDetail={`${formatDateTime(startDate.toISOString())} - ${formatDateTime(
				endDate.toISOString()
			)}`}
		>
			<GenericReportChart
				report={report}
				isLoading={isLoading}
				yAxisTickFormatter={(value) => formatNumber(value, 3)}
				yAxisDomain={["dataMin", "dataMax"]}
				hideXAxis
				defaultChartType={ChartType.Line}
				dot={false}
				showLegend={false}
				dataRecordCountCap={10000}
				tooltipLabelFormatter={(value) => ""}
				filters={[
					<Select
						label="Data type"
						key="field-type"
						value={dataField ?? "vib_1"}
						onChange={setDataField}
						showNoSelectionOption={false}
						options={vacuumDataFields}
					/>,
				]}
				contentAboveChart={
					miscData && report && report.data.length > 0 ?
						<Box display="flex" columnGap={2}>
							<LabeledData label="Average">{miscData.average}</LabeledData>
							<LabeledData label="Calculated Min">
								{miscData.calculatedMin}
							</LabeledData>
							<LabeledData label="Calculated Max">
								{miscData.calculatedMax}
							</LabeledData>
							<LabeledData label="Min">{miscData.min}</LabeledData>
							<LabeledData label="Max">{miscData.max}</LabeledData>
						</Box>
					:	undefined
				}
			/>
		</ChartCard>
	)
}

export const vacuumDataFields = [
	{
		value: "ref_frequency_1",
		text: "Ref Frequency 1",
	},
	{
		value: "ref_frequency_2",
		text: "Ref Frequency 2",
	},
	{
		value: "output_frequency_1",
		text: "Output Frequency 1",
	},
	{
		value: "output_frequency_2",
		text: "Output Frequency 2",
	},
	{
		value: "output_power_1",
		text: "Output Power 1",
	},
	{
		value: "output_power_2",
		text: "Output Power 2",
	},
	{
		value: "output_current_1",
		text: "Output Current 1",
	},
	{
		value: "output_current_2",
		text: "Output Current 2",
	},
	{
		value: "p_1",
		text: "System Pressure",
	},
	{
		value: "p_2",
		text: "Filter Pressure",
	},
	{
		value: "vib_1",
		text: "Vib 1",
	},
	{
		value: "vib_2",
		text: "Vib 2",
	},
]

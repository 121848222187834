import React from "react"

import { connect } from "react-redux"

import {
	getServiceDashboard,
	updateTerritorySelected,
} from "@ncs/mortar/redux/services/dashboards"
import { updateHeaderIcons } from "@ncs/mortar/redux/services/header"
import { LoadingSpinner } from "@ncs/web-legos"

import ServiceDashboard from "./ServiceDashboard"

class ServiceDashboardContainer extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			dataRefreshTimer: null,
			activeTerritory: null,
		}
	}

	updateTerritory = (e) => {
		let dashboard = {}
		this.props.service.map((s) => {
			if (s.territory_id === e.target.value) {
				dashboard = s
			}
			return dashboard
		})
		this.props.updateTerritorySelected(e.target.value, dashboard)
	}

	componentDidMount = () => {
		this.refreshData()
		let timer = setInterval(this.refreshData, 1000 * 60 * 5)
		this.setState({ timer })
	}

	componentWillUnmount() {
		clearInterval(this.state.timer)
	}

	refreshData = () => {
		this.props.getData()
	}

	render() {
		if (this.props.isInitialState) return <LoadingSpinner />
		return (
			<ServiceDashboard
				service={this.props.service}
				userTerritories={this.props.userTerritories}
				territorySelected={this.props.territorySelected}
				onTerritoryUpdate={this.updateTerritory}
				activeDashboard={this.props.activeDashboard}
			/>
		)
	}
}

const mapStateToProps = ({ dashboards }) => {
	return {
		...dashboards,
	}
}

const mapDispatchToProps = (dispatch) => ({
	getData: () => {
		dispatch(getServiceDashboard())
	},
	updateHeaderIcons: (e) => {
		dispatch(updateHeaderIcons(e))
	},
	updateTerritorySelected: (e, d) => {
		dispatch(updateTerritorySelected(e, d))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(ServiceDashboardContainer)

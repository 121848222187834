/* ts-import-sorter: disable */

import { combineReducers } from "redux"
import { routerReducer } from "react-router-redux"
import auth from "@ncs/bricks/redux/services/auth"
import budget from "@ncs/mortar/redux/services/budget"
import clientVersion from "@ncs/bricks/redux/services/clientVersion"
import creditMemos from "@ncs/mortar/redux/services/credit_memos"
import customers from "@ncs/mortar/redux/services/customers"
import dashboards from "@ncs/mortar/redux/services/dashboards"
import debug from "@ncs/mortar/redux/services/debug"
import deliveryOrders from "@ncs/mortar/redux/services/deliveryOrders"
import dispatches from "@ncs/mortar/redux/services/dispatches"
import documents from "@ncs/mortar/redux/services/documents"
import dynamicTables from "@ncs/bricks/redux/services/dynamicTables"
import equipmentRepairProposals from "@ncs/mortar/redux/services/equipmentRepairProposals"
import equipment from "@ncs/mortar/redux/services/equipment"
import header from "@ncs/mortar/redux/services/header"
import infoRef from "@ncs/mortar/redux/services/infoRef"
import inventory from "@ncs/mortar/redux/services/inventory"
import invoices from "@ncs/mortar/redux/services/invoices"
import lookups from "@ncs/mortar/redux/services/lookups"
import marketing from "@ncs/mortar/redux/services/marketing"
import partOrder from "@ncs/mortar/redux/services/partOrder"
import purchaseOrder from "@ncs/mortar/redux/services/purchaseOrder"
import pointOfSale from "@ncs/mortar/redux/services/pointOfSale"
import pricing from "@ncs/mortar/redux/services/pricing"
import siteProfit from "@ncs/mortar/redux/services/siteProfit"
import reports from "@ncs/mortar/redux/services/reports"
import retailSales from "@ncs/mortar/redux/services/retailSales"
import serviceRequests from "@ncs/mortar/redux/services/serviceRequests"
import shipping from "@ncs/mortar/redux/services/shipping"
import users from "@ncs/mortar/redux/services/users"
import washCounts from "@ncs/mortar/redux/services/washCounts"
import workOrders from "@ncs/mortar/redux/services/workOrders"
import tickets from "@ncs/mortar/redux/services/ticketingDashboard"
import transferRequest from "@ncs/mortar/redux/services/transferRequests"
import marketingMaterialsModule from "./modules/marketingMaterialsModule"
import userAdminModule from "./modules/userAdminModule"

export const reducers = {
	auth,
	budget,
	equipment,
	clientVersion,
	creditMemos,
	customers,
	dashboards,
	debug,
	deliveryOrders,
	dispatches,
	documents,
	dynamicTables,
	equipmentRepairProposals,
	infoRef,
	inventory,
	invoices,
	lookups,
	marketing,
	marketingMaterialsModule,
	partOrder,
	pointOfSale,
	purchaseOrder,
	pricing,
	reports,
	retailSales,
	serviceRequests,
	shipping,
	tickets,
	siteProfit,
	userAdminModule,
	users,
	washCounts,
	workOrders,
	transferRequest,
	header,
	router: routerReducer,
}

export default combineReducers(reducers)

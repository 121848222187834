import { FC, Fragment, useMemo, useState } from "react"

import {
	makeApiErrorMessage,
	PartOrderShipment,
	UpdateShipmentQuantitiesPatch,
	useUpdateShipmentQuantities,
} from "@ncs/ncs-api"
import { formatNumber, ObjectEntry } from "@ncs/ts-utils"
import {
	Box,
	Button,
	ConfirmationModal,
	ConfirmationModalConfig,
	CssGridTable,
	ExtendableModalProps,
	Heading,
	Modal,
	NumericInput,
	Paragraph,
	useToast,
} from "@ncs/web-legos"

import { useShipShipmentContext } from "~/util/ship-shipment"

export type DivideShipmentModalProps = ExtendableModalProps

export const DivideShipmentModal: FC<DivideShipmentModalProps> = ({ ...rest }) => {
	const { shipment } = useShipShipmentContext()
	const { makeSuccessToast } = useToast()
	const [lineState, setLineState] = useState(() => makeLineState(shipment.lines))
	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)

	const updateShipment = useUpdateShipmentQuantities()

	const handleSave = async () => {
		try {
			if (Object.values(lineState).every((l) => !l.newQuantity)) {
				throw new Error("At least one line item must have a quantity above zero")
			}

			setIsSaving(true)
			await updateShipment({
				id: shipment.shipmentId,
				updates: makeUpdatePayload(lineState),
			})
			makeSuccessToast("Shipment lines updated")
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const handleSetZero = () => {
		setLineState((prev) => {
			return Object.fromEntries(
				Object.entries(prev).map(([id, line]) => [
					id,
					{
						...line,
						newQuantity: 0,
					},
				])
			)
		})
	}

	const handleReset = () => {
		setLineState(makeLineState(shipment.lines))
	}

	const isDirty = useMemo(() => {
		return Object.values(lineState).some((line) => {
			return line.newQuantity !== line.originalLine.quantity
		})
	}, [lineState])

	return (
		<Modal
			{...rest}
			errorText={errorText}
			rightButtons={{
				buttonText: "Save Changes",
				disabled: !isDirty,
				isLoading: isSaving,
				onClick: handleSave,
			}}
		>
			<Heading mt={0} mb={2} variant="h3" bold>
				Divide Shipment
			</Heading>
			<Paragraph mb={2}>
				Enter the quantities for just <strong>the parts you want in THIS shipment</strong>.
				A separate, new shipment will be created for the remaining part quantities.
			</Paragraph>
			<Paragraph bold mb={0.5}>
				Update this shipment to include:
			</Paragraph>
			<Box d="flex" mb={1} justifyContent="flex-end" gap={1}>
				<Button onClick={handleSetZero}>Set all to zero</Button>
				<Button onClick={handleReset}>Reset</Button>
			</Box>

			<CssGridTable
				gridTemplateColumns="auto 1fr auto 6rem"
				rowGap={0.5}
				columnGap={1}
				headers={["Part #", "Name", "Current Qty", "New Qty"]}
				alignItems="baseline"
				cells={Object.values(lineState).map(({ newQuantity, originalLine }) => (
					<Fragment key={originalLine.id}>
						<span>{originalLine.partNumber}</span>
						<span>{originalLine.description}</span>
						<span>{formatNumber(originalLine.quantity)}</span>
						<NumericInput
							value={newQuantity}
							onChange={(newValue) =>
								setLineState((prev) => ({
									...prev,
									[originalLine.id]: {
										...prev[originalLine.id],
										newQuantity: newValue ?? null,
									},
								}))
							}
							max={originalLine.quantity}
							placeholder="New qty..."
							mb={0}
						/>
					</Fragment>
				))}
			/>

			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</Modal>
	)
}

interface LineState {
	[lineItemId: string]: {
		newQuantity: number | null
		originalLine: PartOrderShipment["lines"][number]
	}
}

const makeLineState = (shipmentLines: PartOrderShipment["lines"]): LineState => {
	return Object.fromEntries(
		shipmentLines.map((line): ObjectEntry<LineState> => {
			return [
				line.id,
				{
					originalLine: line,
					newQuantity: line.quantity,
				},
			]
		})
	)
}

const makeUpdatePayload = (lines: LineState): UpdateShipmentQuantitiesPatch => {
	return {
		updatedLines: Object.values(lines).flatMap(
			({ originalLine, newQuantity }): UpdateShipmentQuantitiesPatch["updatedLines"] => {
				return newQuantity ?
						[
							{
								id: originalLine.id,
								partOrderLineId: originalLine.partOrderLineId,
								quantity: newQuantity,
								packageInfo:
									originalLine.package ?
										{
											packageId: originalLine.package.id,
											weight: originalLine.package.weight,
											packageItemId:
												originalLine.package.packageItem?.id ?? null,
										}
									:	null,
							},
						]
					:	[]
			}
		),
	}
}

import { FC, useMemo, useState } from "react"

import { css } from "@emotion/react"
import dayjs, { Dayjs } from "dayjs"
import { useParams } from "react-router-dom"

import { makeApiErrorMessage, PurchaseOrder, useFinalizePurchaseOrder } from "@ncs/ncs-api"
import { formatDate } from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	Button,
	DateInput,
	DateInputProps,
	EmptyValueDash,
	ExtendableModalProps,
	HeadingDivider,
	IconButton,
	Modal,
	Paragraph,
	Textarea,
	TextInput,
	useChangeCallback,
} from "@ncs/web-legos"

export interface PurchaseOrderOrderInfoModalProps extends ExtendableModalProps {
	order: PurchaseOrder | null
}

export const PurchaseOrderOrderInfoModal: FC<PurchaseOrderOrderInfoModalProps> = ({
	order,
	onClose,
	...rest
}) => {
	const { purchaseOrderId: id } = useParams<{ purchaseOrderId: string }>()
	if (!id) throw new Error("purchase order ID not found in URL")

	const finalizeOrder = useFinalizePurchaseOrder(id)

	const [confirmationNumber, setConfirmationNumber] = useState<string | null>(null)
	const [comments, setComments] = useState<string | null>(null)
	const [lineItemDates, setLineItemDates] = useState<Record<string, string | null>>()
	const [showDatesForLines, setShowDatesForLines] = useState(false)
	const [lineIdForDateEdit, setLineIdForDateEdit] = useState<string | null>(null)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const handleSetForAll = (date: Dayjs | null) => {
		if (!order) throw new Error("No purchase order")

		if (date) {
			setLineItemDates(
				Object.fromEntries(
					Object.entries(order.lineItems).map(([, value]) => [
						value.id,
						date.toISOString(),
					])
				)
			)
		}
	}

	const onSubmit = () => {
		if (!lineItemDates || Object.values(lineItemDates).some((date) => !date)) {
			setErrorText("All line items must have a date selected")
		} else {
			void handlePlaceOrder()
		}
	}

	const handlePlaceOrder = async () => {
		if (!order) throw new Error("No purchase order")

		try {
			const postLineItems = Object.entries(lineItemDates ?? {}).flatMap(([key, value]) =>
				value ?
					[
						{
							lineItemId: key,
							shipDate: value,
						},
					]
				:	[]
			)

			setIsSubmitting(true)
			await finalizeOrder({
				confirmationNumber,
				comments,
				lineItemDates: postLineItems,
			})
			onClose()
		} catch (e) {
			setErrorText(makeApiErrorMessage(e))
			setIsSubmitting(false)
		}
	}

	const onOpen = () => {
		if (!order) throw new Error("No purchase order")

		setConfirmationNumber(null)
		setComments(null)
		setLineItemDates(
			Object.fromEntries(
				Object.entries(order.lineItems).map(([, value]) => [value.id, null])
			)
		)
		setShowDatesForLines(false)
		setLineIdForDateEdit(null)
		setIsSubmitting(false)
		setErrorText(null)
	}

	/** The date for the "All Lines" date box should show a date if all lines are set to it. */
	const dateForAllLines = useMemo(() => {
		if (!lineItemDates) {
			return null
		}

		return (
			Object.values(lineItemDates).find((date) =>
				Object.values(lineItemDates).every((otherDate) => date === otherDate)
			) ?? null
		)
	}, [lineItemDates])

	useChangeCallback(lineItemDates, () => {
		setErrorText(null)
	})

	const sharedDateInputProps: Partial<DateInputProps> = {
		label: "",
		mb: 0,
		mr: 0.5,
		maxWidth: 9,
		disablePast: true,
	}

	const onlyOneLine = !!order && order.lineItems.length === 1

	return (
		<Modal
			{...rest}
			onClose={onClose}
			onOpen={onOpen}
			title="Add Supplier Order Information"
			errorText={errorText}
			rightButtons={{
				buttonText: "Finalize Order",
				onClick: onSubmit,
				isLoading: isSubmitting,
			}}
		>
			<TextInput
				value={confirmationNumber}
				onChange={setConfirmationNumber}
				label="Confirmation #"
			/>
			<Textarea value={comments} onChange={setComments} label="Comments" />

			<HeadingDivider headingVariant="h5" icon="truck" mb={1}>
				Estimated Shipping Date
			</HeadingDivider>

			{!onlyOneLine && (
				<>
					<div css={lineContainerStyle}>
						<DateInput
							{...sharedDateInputProps}
							value={dateForAllLines ? dayjs(dateForAllLines) : null}
							onChange={handleSetForAll}
						/>
						<Paragraph ml={0.5}>Date for all line items</Paragraph>
					</div>

					<Button
						icon={showDatesForLines ? "caret-down" : "caret-right"}
						iconFamily="solid"
						onClick={() => setShowDatesForLines((prev) => !prev)}
						containerProps={{ mt: 1, mb: 2 }}
					>
						Set dates for individual line items
					</Button>
				</>
			)}

			<AnimatedEntrance show={showDatesForLines || onlyOneLine} direction="down">
				{order?.lineItems.map((line) => {
					const dateMapValue = lineItemDates?.[line.id]

					return (
						<div key={line.id} css={lineContainerStyle}>
							{lineIdForDateEdit === line.id.toString() ?
								<>
									<DateInput
										{...sharedDateInputProps}
										value={dateMapValue ? dayjs(dateMapValue) : null}
										onChange={(date) => {
											setLineItemDates((prev) => ({
												...prev,
												[line.id]: date?.toISOString() ?? null,
											}))
											setLineIdForDateEdit(null)
										}}
									/>
									<IconButton
										icon="times"
										size="sm"
										onClick={() => setLineIdForDateEdit(null)}
									/>
								</>
							:	<>
									{dateMapValue ?
										<Paragraph>{formatDate(dateMapValue)}</Paragraph>
									:	<EmptyValueDash />}
									<IconButton
										icon="pencil"
										size="sm"
										color="primary"
										onClick={() => setLineIdForDateEdit(line.id.toString())}
									/>
								</>
							}
							<Paragraph ml={0.5}>{line.part?.description}</Paragraph>
						</div>
					)
				})}
			</AnimatedEntrance>
		</Modal>
	)
}

const lineContainerStyle = css`
	display: flex;
	align-items: center;
	margin-bottom: 0.75rem;
`

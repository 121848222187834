import React from "react"

import {
	ButtonWithIcon,
	GridContainer,
	GridItem,
	Dialog,
	CustomerSelector,
	// onClickCustomer,
} from "~/components"

//material ui icons
import Add from "@material-ui/icons/Add"
import RemoveIcon from "@material-ui/icons/Remove"
//styles
import withStyles from "@material-ui/core/styles/withStyles"
const styles = {
	right: {
		textAlign: "right",
	},
}

function TechAssignmentCustomerAdd(props) {
	let {
		onStartAssignmentAdd,
		onCancelAssignmentAdd,
		show,
		onCustomerSelected,
		onConfirmAssignmentAdd,
		isSaving,
		territory,
	} = props
	return (
		<GridContainer>
			<GridItem xs={12}>
				<ButtonWithIcon
					content="Add Customer"
					onClick={onStartAssignmentAdd}
					icon={<Add />}
					size="sm"
					color="success"
					round
				/>
			</GridItem>
			<Dialog
				warning="true"
				show={show}
				title="Add Customer"
				confirmBtnIcon={<Add />}
				confirmBtnText="Confirm"
				onConfirm={onConfirmAssignmentAdd}
				isConfirmationAsync={true}
				cancelBtnIcon={<RemoveIcon />}
				cancelBtnText={"Cancel"}
				cancelBtnColor={"danger"}
				onCancel={onCancelAssignmentAdd}
				isSaving={isSaving}
			>
				{show && (
					<GridContainer>
						<GridItem xs={12}>
							<CustomerSelector
								onCustomerSelected={onCustomerSelected}
								getQueryParameters={() => {
									return { territory: territory }
								}}
							/>
						</GridItem>
					</GridContainer>
				)}
			</Dialog>
		</GridContainer>
	)
}

export default withStyles(styles)(TechAssignmentCustomerAdd)

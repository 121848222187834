import { FC, Fragment, useMemo, useState } from "react"

import dayjs from "dayjs"

import { CustomerDetail, useCustomerCredits } from "@ncs/ncs-api"
import { Box, Button, Divider, LoadingSpinner } from "@ncs/web-legos"

import { AddBusinessCreditModal, BusinessCredit } from "./components"

export interface CustomerBusinessCreditsProps {
	customer: CustomerDetail
}

export const CustomerBusinessCredits: FC<CustomerBusinessCreditsProps> = ({ customer }) => {
	const [showAddCreditModal, setShowAddCreditModal] = useState(false)

	const [credits, creditsLoading] = useCustomerCredits(customer.id)

	const sortedCredits = useMemo(() => {
		return (credits ?? []).sort((a, b) => {
			const aIsExpired = dayjs().isAfter(a.expiresOn)
			const bIsExpired = dayjs().isAfter(b.expiresOn)

			if (aIsExpired === bIsExpired) {
				const aName = (a.businessUnit.name || a.otherUnit.name) ?? ""
				const bName = (b.businessUnit.name || b.otherUnit.name) ?? ""

				return aName > bName ? 1 : -1
			} else {
				return aIsExpired && !bIsExpired ? 1 : -1
			}
		})
	}, [credits])

	return (
		<>
			{creditsLoading && <LoadingSpinner />}

			<Button icon="plus" onClick={() => setShowAddCreditModal(true)}>
				Add new business credit
			</Button>

			{!!sortedCredits.length && (
				<Box mt={1.5}>
					{sortedCredits.map((credit, i) => (
						<Fragment key={credit.id}>
							{i > 0 && <Divider mt={3.5} mb={1.5} />}
							<BusinessCredit customer={customer} credit={credit} />
						</Fragment>
					))}
				</Box>
			)}

			{showAddCreditModal && (
				<AddBusinessCreditModal
					isOpen
					onClose={() => setShowAddCreditModal(false)}
					customer={customer}
				/>
			)}
		</>
	)
}

import { FC, Fragment, useMemo, useState } from "react"

import {
	makeApiErrorMessage,
	PermanentPriceAction,
	PricingAdjustmentRequest,
	UpdatePricingAdjustmentRequestPatch,
	useUpdatePricingAdjustmentForm,
} from "@ncs/ncs-api"
import { displayDateTime, formatDateTime, getTimezoneAbbreviation } from "@ncs/ts-utils"
import {
	Box,
	Button,
	Callout,
	CssGridTable,
	cssMixins,
	Divider,
	EditStringModal,
	EditStringModalProps,
	EmptyValueDash,
	Heading,
	IconButton,
	LabeledData,
	Paragraph,
	useToast,
} from "@ncs/web-legos"

export interface RequestDetailHeaderProps {
	request: PricingAdjustmentRequest
}

export const RequestDetailHeader: FC<RequestDetailHeaderProps> = ({ request }) => {
	const { makeSuccessToast, makeErrorToast } = useToast()
	const [editStringModalConfig, setEditStringModalConfig] =
		useState<EditStringModalProps | null>(null)
	const [showAll, setShowAll] = useState(false)

	const updateRequest = useUpdatePricingAdjustmentForm()

	const handleSave = async <Key extends keyof UpdatePricingAdjustmentRequestPatch>(
		key: Key,
		value: UpdatePricingAdjustmentRequestPatch[Key],
		shouldThrow = false
	) => {
		try {
			await updateRequest({
				id: request.id,
				updates: {
					[key]: value,
				},
			})
			makeSuccessToast("Request form updated")
		} catch (e) {
			if (shouldThrow) {
				throw e
			} else {
				makeErrorToast(makeApiErrorMessage(e))
			}
		}
	}

	const editNotes = () => {
		setEditStringModalConfig({
			title: "Edit Request Notes",
			initialValue: request.notes,
			label: "Notes",
			allowEmpty: true,
			textarea: true,
			textareaProps: { rows: 5 },
			onSave: async (value) => {
				await handleSave("notes", value, true)
			},
			onClose: () => setEditStringModalConfig(null),
		})
	}

	const editTitle = () => {
		setEditStringModalConfig({
			title: "Edit Request Title",
			initialValue: request.description,
			label: "Title",
			allowEmpty: false,
			onSave: async (value) => {
				if (value) {
					await handleSave("description", value, true)
				}
			},
			onClose: () => setEditStringModalConfig(null),
		})
	}

	const metaDetails = useMemo(() => {
		const createdAction = request.actions.find((a) => a.action === PermanentPriceAction.Create)

		return createdAction ?
				`Request created ${formatDateTime(createdAction.actionDate)} by ${
					createdAction.createdBy
				}`
			:	null
	}, [request.actions])

	return (
		<Callout variant="info" fillContainer pb={2}>
			<Box d="flex">
				<Heading variant="h2" mb={0.5}>
					{request.description}
				</Heading>

				<Box>
					<IconButton onClick={editTitle} />
				</Box>
			</Box>
			{!!metaDetails && (
				<Paragraph small secondary opacity={0.6}>
					{metaDetails}
				</Paragraph>
			)}

			<Box d="flex" columnGap={4} mt={2}>
				<LabeledData label="Status">{request.status}</LabeledData>
				<LabeledData label="Assigned to">
					{request.assignedTo || <EmptyValueDash />}
				</LabeledData>
				<LabeledData label="Last submission">
					{displayDateTime(request.lastSubmissionDate, "") || <EmptyValueDash />}
				</LabeledData>
			</Box>

			<Divider />

			<Heading variant="h5" mb={1}>
				Notes
			</Heading>
			<Box d="flex" mb={3}>
				{request.notes ?
					<Paragraph css={cssMixins.preserveLineBreaks}>{request.notes}</Paragraph>
				:	<Paragraph opacity={0.5}>Add notes here...</Paragraph>}
				<Box mt={-0.35}>
					<IconButton onClick={editNotes} />
				</Box>
			</Box>

			<Heading variant="h5" mb={1}>
				History
			</Heading>
			<CssGridTable
				gridTemplateColumns="auto auto auto 1fr"
				columnGap={2}
				headers={[`Date (${getTimezoneAbbreviation()})`, "Action", "User", "Comment"]}
				cells={request.actions
					.sort((a, b) => (a.actionDate > b.actionDate ? -1 : 1))
					.filter((a, i) => showAll === true || i < historyRowCap)
					.map((action) => (
						<Fragment key={Object.values(action).join("")}>
							<Paragraph>{displayDateTime(action.actionDate)}</Paragraph>
							<Paragraph>{action.action}</Paragraph>
							<Paragraph>{action.createdBy}</Paragraph>
							<Paragraph>{action.comment || <EmptyValueDash />}</Paragraph>
						</Fragment>
					))}
			/>

			{request.actions.length > historyRowCap && showAll === false && (
				<Button
					icon="angle-right"
					onClick={() => setShowAll(true)}
					containerProps={{ mt: 0.5 }}
				>
					Show all
				</Button>
			)}

			{!!editStringModalConfig && <EditStringModal {...editStringModalConfig} />}
		</Callout>
	)
}

const historyRowCap = 10

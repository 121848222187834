export const rowContentsStyles = ({ palette }) => ({
	dispatchLink: {
		"& a": {
			fontWeight: "bold",
			color: palette.primary.main,
			opacity: "0.85",
		},
	},
	flexCell: {
		display: "flex",
		alignItems: "center",
		"& svg": {
			fontSize: "0.75rem",
			opacity: 0.35,
			marginLeft: "0.25rem",
		},
	},
	serialNumber: {
		maxWidth: "4rem",
		overflow: "hidden",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
	},
	status: {
		display: "flex",
		alignItems: "center",
		fontWeight: "bold",
		fontSize: "0.75rem",
		"& svg": {
			marginRight: "0.25rem",
			fontSize: "1rem",
		},
	},
	statusInvalid: {
		color: palette.warning.main,
	},
	statusValid: {
		color: palette.primary.main,
	},
	statusSubmitted: {
		color: palette.success.main,
	},
})

import { Dispatch, ReactElement, SetStateAction, useState } from "react"

import { BinLocation } from "@ncs/ncs-api"

import { useChangeCallback } from "../../util"
import { LocationBinSelector } from "../selectors"

interface LocationBinQueryParam {
	bin: string | null
}

export interface LocationBinQueryFilterProps<QueryParamState extends LocationBinQueryParam> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
	locationId: string
}

export const LocationBinQueryFilter = <QueryParamState extends LocationBinQueryParam>({
	queryParamState,
	setQueryParamState,
	locationId,
}: LocationBinQueryFilterProps<QueryParamState>): ReactElement => {
	const [bin, setBin] = useState<BinLocation | null>(null)

	const handleChange = (newBin: BinLocation | null) => {
		setBin(newBin)
		setQueryParamState((prev) => ({
			...prev,
			bin: newBin?.id ?? null,
		}))
	}

	useChangeCallback(queryParamState.bin, (newBinId) => {
		if (newBinId == null && bin != null) {
			setBin(null)
		}
	})

	return (
		<LocationBinSelector
			value={bin}
			onChange={handleChange}
			initialId={queryParamState.bin}
			locationId={locationId}
			fillContainer
		/>
	)
}

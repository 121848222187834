import { FC, memo, ReactElement, useCallback, useMemo, useState } from "react"

import { useHistory } from "react-router-dom"
import { Cell, Column } from "react-table"

import {
	APPLICATION,
	ConnectedDevicesAtSite,
	ConnectedMachinesAtSiteQueryParams,
	ConnectedVacuumsAtSiteQueryParams,
	ConnectedWashCountersAtSiteQueryParams,
	ConnectivityDeviceTypeId,
	DeviceStatusCategory,
	useConnectedMachinesAtSites,
	useUserCanUse,
	useVacuumsAtSite,
	useWashCountersAtSite,
} from "@ncs/ncs-api"
import {
	formatNumber,
	isEnumMember,
	noNullish,
	ObjectEntry,
	titleCase,
	Typify,
} from "@ncs/ts-utils"
import {
	Box,
	ConnectivityDeviceTypeQueryFilter,
	ConnectivityDeviceTypeQueryFilterProps,
	CustomerQueryFilter,
	DeviceStatusQueryFilter,
	IsActiveQueryFilter,
	EquipmentVintageQueryFilter,
	OrganizationQueryFilter,
	SearchQueryFilter,
	SearchQueryFilterProps,
	StatusBlip,
	Table,
	TerritoryQueryFilter,
	useUrlState,
} from "@ncs/web-legos"

import { c12yAutoRefreshConfig, getConnectivityDeviceDetailUrl } from "~/util"

import { ChooseDeviceModal } from "../choose-device-modal"

export type DevicesTabUrlState = Typify<
	ConnectedMachinesAtSiteQueryParams &
		ConnectedWashCountersAtSiteQueryParams &
		ConnectedVacuumsAtSiteQueryParams & {
			deviceType: ConnectivityDeviceTypeId | null
		}
>

export const DevicesTab: FC = () => {
	const history = useHistory()
	const isMachineAdmin = useUserCanUse(APPLICATION.MachineAdmin)
	const isWashCounterAdmin = useUserCanUse(APPLICATION.WashCounterAdmin)
	const isVacuumAdmin = useUserCanUse(APPLICATION.VacuumAdmin)

	const defaultDeviceType = useMemo(() => {
		return (
			isMachineAdmin ? ConnectivityDeviceTypeId.Machine
			: isWashCounterAdmin ? ConnectivityDeviceTypeId.WashCount
			: isVacuumAdmin ? ConnectivityDeviceTypeId.Vacuum
			: null
		)
	}, [isMachineAdmin, isWashCounterAdmin, isVacuumAdmin])

	const [urlState, { setUrlState }] = useUrlState<DevicesTabUrlState>({
		...defaultUrlState,
		deviceType: defaultDeviceType,
	})
	const { deviceType, ...queryParams } = urlState
	const [selectedCustomer, setSelectedCustomer] = useState<string | null>(null)

	const [machinesAtSites, machinesLoading] = useConnectedMachinesAtSites({
		params: queryParams,
		queryConfig: {
			...c12yAutoRefreshConfig,
			enabled: deviceType === ConnectivityDeviceTypeId.Machine,
		},
	})

	const washCountsQuery = useWashCountersAtSite({
		params: queryParams,
		queryConfig: {
			...c12yAutoRefreshConfig,
			enabled: deviceType === ConnectivityDeviceTypeId.WashCount,
		},
	})

	const vacuumsQuery = useVacuumsAtSite({
		params: queryParams,
		queryConfig: {
			...c12yAutoRefreshConfig,
			enabled: deviceType === ConnectivityDeviceTypeId.Vacuum,
		},
	})

	const tableDataProps = useMemo(() => {
		if (deviceType === ConnectivityDeviceTypeId.WashCount) {
			return { query: washCountsQuery }
		}
		if (deviceType === ConnectivityDeviceTypeId.Vacuum) {
			return { query: vacuumsQuery }
		}
		if (deviceType === ConnectivityDeviceTypeId.Machine) {
			return {
				data: (machinesAtSites ?? []).sort((a, b) =>
					a.sortingPriority > b.sortingPriority ? -1 : 1
				),
			}
		}

		return null
	}, [deviceType, machinesAtSites, washCountsQuery, vacuumsQuery])

	const FilteredDeviceTypeFilter = useMemo(
		() => (props: ConnectivityDeviceTypeQueryFilterProps<DevicesTabUrlState>) => {
			const excluded = noNullish([
				!isMachineAdmin ? ConnectivityDeviceTypeId.Machine : undefined,
				!isWashCounterAdmin ? ConnectivityDeviceTypeId.WashCount : undefined,
				!isVacuumAdmin ? ConnectivityDeviceTypeId.Vacuum : undefined,
			])

			return <ConnectivityDeviceTypeQueryFilter {...props} exclude={excluded} />
		},
		[isMachineAdmin, isWashCounterAdmin, isVacuumAdmin]
	)

	const selectionForModal = useMemo(() => {
		if (!selectedCustomer) return null

		const results = [
			...(machinesAtSites ?? []),
			...washCountsQuery.data,
			...vacuumsQuery.data,
		].find((result) => result.customer.id === selectedCustomer)

		return results ?? null
	}, [machinesAtSites, washCountsQuery.data, vacuumsQuery.data, selectedCustomer])

	const handleDeviceClick = useCallback(
		(row: ConnectedDevicesAtSite) => {
			if (row.devices.length > 1) {
				setSelectedCustomer(row.customer.id)
			} else if (row.devices.length === 1) {
				const url = getConnectivityDeviceDetailUrl(row.devices[0])

				if (url) {
					history.push({
						pathname: url,
						state: { devicesUrlState: urlState },
					})
				}
			}
		},
		[history, urlState]
	)

	const toggledFiltersStartOpen = Object.entries(queryParams).some(
		([key, value]) => !!value && key !== "deviceType"
	)

	return (
		<>
			{!!tableDataProps && (
				<Table
					{...tableDataProps}
					columns={columns}
					isLoading={machinesLoading}
					queryParamState={urlState}
					setQueryParamState={setUrlState}
					pinnedQueryFilters={[FilteredDeviceTypeFilter]}
					toggledQueryFilters={[
						TerritoryQueryFilter,
						OrganizationQueryFilter,
						CustomerQueryFilter,
						DeviceIdSearch,
						DeviceStatusQueryFilter,
						IsActiveQueryFilter,
						EquipmentVintageQueryFilter,
					]}
					onRowClick={({ original }) => handleDeviceClick(original)}
					disableAllSorting
					showToggledFiltersByDefault={toggledFiltersStartOpen}
					noDataText="No devices found"
					onReset={() => {
						setUrlState((prev) => ({
							...defaultUrlState,
							deviceType: prev.deviceType || ConnectivityDeviceTypeId.Machine,
						}))
					}}
				/>
			)}

			{!!selectionForModal && (
				<ChooseDeviceModal
					devicesAtSite={selectionForModal}
					onClose={() => setSelectedCustomer(null)}
					devicesUrlState={urlState}
				/>
			)}
		</>
	)
}

const columns: Column<ConnectedDevicesAtSite>[] = [
	{
		Header: "Customer #",
		accessor: (original) => original.customer.number,
	},
	{
		Header: "Customer",
		accessor: (original) => original.customer.name,
	},
	{
		Header: "City",
		accessor: (original) => original.customer.city || "-",
	},
	{
		Header: "State",
		accessor: (original) => original.customer.state || "-",
	},
	{
		Header: "Device States",
		id: "status",
		Cell: ({ row }: Cell<ConnectedDevicesAtSite>) => {
			const statusCounts: Record<DeviceStatusCategory, number> = {
				[DeviceStatusCategory.Error]: 0,
				[DeviceStatusCategory.Unknown]: 0,
				[DeviceStatusCategory.Offline]: 0,
				[DeviceStatusCategory.Warning]: 0,
				[DeviceStatusCategory.Normal]: 0,
			}

			row.original.devices.forEach((d) => {
				if (isEnumMember(d.state, DeviceStatusCategory)) {
					statusCounts[d.state] = statusCounts[d.state] + 1
				}
			})

			return (
				<Box display="flex" flexDirection="column" gap={0.5}>
					{(Object.entries(statusCounts) as ObjectEntry<typeof statusCounts>[]).map(
						([key, value]) => {
							if (!value) return null

							return (
								<Box d="flex" gap={0.5} alignItems="center" key={key}>
									<StatusBlip status={key} />
									<span>
										{titleCase(key)} ({formatNumber(value)})
									</span>
								</Box>
							)
						}
					)}
				</Box>
			)
		},
	},
]

const defaultUrlState: DevicesTabUrlState = {
	customer: null,
	territory: null,
	organization: null,
	equipment: null,
	status: null,
	device: null,
	search: null,
	user: null,
	deviceType: null,
	isActive: true,
}

const DeviceIdSearch = (props: SearchQueryFilterProps<DevicesTabUrlState>): ReactElement => {
	return <SearchQueryFilter {...props} label="Device ID search" />
}

export default memo(DevicesTab)

import { FC, useState } from "react"

import { Column } from "react-table"

import { Customer, CustomerMilestone, useCustomerMilestones } from "@ncs/ncs-api"
import { LoadingSpinner, Paragraph, Table } from "@ncs/web-legos"

import { MilestoneModal } from "./components"

export interface WearPartsProps {
	customer: Customer
}

export const WearParts: FC<WearPartsProps> = ({ customer }) => {
	const [modalMilestone, setModalMilestone] = useState<CustomerMilestone | null>(null)

	const milestoneQuery = useCustomerMilestones({
		params: {
			customer: customer.id,
		},
	})

	if (milestoneQuery.isLoading) {
		return <LoadingSpinner />
	}
	return (
		<>
			{milestoneQuery.data.length ?
				<Table
					query={milestoneQuery}
					columns={columns}
					onRowClick={(row) => setModalMilestone(row.original)}
					disableAllSorting
				/>
			:	<Paragraph small color="secondary">
					No machine milestones found at this site.
				</Paragraph>
			}

			<MilestoneModal
				isOpen={!!modalMilestone}
				onClose={() => setModalMilestone(null)}
				milestone={modalMilestone}
			/>
		</>
	)
}

const columns: Column<CustomerMilestone>[] = [
	{
		Header: "Milestone",
		accessor: ({ milestone }) => milestone.description,
	},
	{
		Header: "Equipment",
		accessor: ({ customerEquipment }) => customerEquipment.model,
	},
	{
		Header: "Status",
		accessor: ({ status }) => status.description,
	},
]

import { Dispatch, ReactElement, SetStateAction, useMemo } from "react"

import { TerritoryMinimal, useTerritories } from "@ncs/ncs-api"

import { TerritorySelector } from "../selectors"

interface TerritoryQueryParams {
	territory: string | null
}

export interface TerritoryQueryFilterProps<QueryParamState extends TerritoryQueryParams> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

export const TerritoryQueryFilter = <QueryParamState extends TerritoryQueryParams>({
	queryParamState,
	setQueryParamState,
}: TerritoryQueryFilterProps<QueryParamState>): ReactElement => {
	const [territories] = useTerritories()

	const value = useMemo(() => {
		return (territories ?? []).find((t) => t.id === queryParamState.territory)
	}, [queryParamState.territory, territories])

	const handleChange = (newTerritory: TerritoryMinimal | null) => {
		// Set query param state with just the Territory ID.
		setQueryParamState((prev) => ({
			...prev,
			territory: newTerritory?.id ?? null,
		}))
	}

	return (
		<TerritorySelector
			value={value ?? null}
			onChange={handleChange}
			initialId={queryParamState.territory}
			fillContainer
		/>
	)
}

import React from "react"
import PropTypes from "prop-types"
import ChartistLineGraph from "./ChartistLineGraph"
import { Interpolation } from "chartist"
import cx from "classnames"

import {
	dangerColor,
	infoColor,
	primaryColor,
	roseColor,
	successColor,
	warningColor,
} from "../../assets/jss/material-dashboard-pro-react"

import { makeStyles } from "@material-ui/core"

export const chartOptions = {
	height: "1em",
	low: 0,
	fullWidth: true,
	chartPadding: { top: 1, right: 0, bottom: 1, left: 0 },
	axisX: { showGrid: false, showLabel: false, offset: 0 },
	axisY: { showGrid: false, showLabel: false, offset: 0 },
	lineSmooth: Interpolation.cardinal({ tension: 0.2 }),
}

const styles = {
	root: {
		"& svg": {
			margin: "1px",
			verticalAlign: "text-bottom",
		},
		"& .ct-series-a .ct-line": {
			strokeWidth: "2px",
		},
		"& .ct-series-a .ct-point": {
			strokeWidth: "0",
		},
	},
	primary: {
		"& .ct-series-a .ct-line": {
			stroke: primaryColor[0] + " !important",
		},
	},
	info: {
		"& .ct-series-a .ct-line": {
			stroke: infoColor[0] + " !important",
		},
	},
	success: {
		"& .ct-series-a .ct-line": {
			stroke: successColor[0] + " !important",
		},
	},
	warning: {
		"& .ct-series-a .ct-line": {
			stroke: warningColor[0] + " !important",
		},
	},
	danger: {
		"& .ct-series-a .ct-line": {
			stroke: dangerColor[0] + " !important",
		},
	},
	rose: {
		"& .ct-series-a .ct-line": {
			stroke: roseColor[0] + " !important",
		},
	},
}
const useStyles = makeStyles(styles)

export default function Sparkline(props) {
	const classes = useStyles()
	const { data, color, ...rest } = props

	return (
		<ChartistLineGraph
			className={cx(classes.root, { [classes[color]]: color })}
			type="Line"
			options={chartOptions}
			{...rest}
			data={{ series: [data] }}
		/>
	)
}

Sparkline.defaultProps = {
	color: "rose",
}

Sparkline.propTypes = {
	data: PropTypes.array.isRequired,
	color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger", "rose"]),
}

import React, { Component } from "react"
import FormValidator from "@ncs/bricks/util/formValidator"

import { sendTechNotification } from "@ncs/mortar/redux/services/ticketing"
import { withCallApi } from "~/components"

//local components
import CommentNotification from "./CommentNotification"

class CommentNotificationContainer extends Component {
	constructor(props) {
		super(props)
		this.validations = {
			notification_email: {
				stateName: "notificationEmail",
				type: "email",
				maxLength: 255,
				isRequired: false,
			},
			message: {
				stateName: "message",
				isRequired: true,
				maxLength: 1000,
				default: "",
			},
		}
		this.formValidator = new FormValidator(this, this.validations)

		this.state = {
			notificationEmail: props.documentationEmail,
			show: props.show,
			notificationSent: false,
			notificationSentText: "",
			selectedNotificationTech: {},
			selectedNotificationType: props.selectedRequestor,
		}
	}

	handleSetInputRef = (input) => {
		this.input = input
	}
	handleConfirmSendNotification = () => {
		if (!this.formValidator.dataIsValid()) {
			this.setState({ showValidationErrors: true })
			return
		}
		if (this.state.selectedNotificationType === 1) {
			if (Object.keys(this.state.selectedNotificationTech).length === 0) {
				return
			}
		} else if (this.state.selectedNotificationType === 2) {
			if (this.state.notificationEmail === "")
				return this.setState({
					showValidationErrors: true,
					notificationEmailErrorMessage: "required",
				})
		}
		let notification = {
			comment_id: this.props.commentId,
			tech_id:
				this.state.selectedNotificationTech ?
					this.state.selectedNotificationTech._id
				:	null,
			ticket_id: this.props.ticketId,
		}
		for (const [prop, config] of Object.entries(this.validations)) {
			notification[prop] = this.state[config.stateName]
		}

		this.props.callApi(sendTechNotification(notification)).then((result) => {
			if (result.error) {
				this.setState({
					notificationSent: true,
					notificationSentText: "Error Sending Notification",
					error: result.error,
				})
				return this.handleCancelSendTechNotification()
			} else {
				this.setState({
					notificationSent: true,
					notificationSentText: "Notification Sent.",
				})
				return this.handleCancelSendTechNotification()
			}
		})
	}
	handleConfirmNotificationSent = () => {
		this.setState({ notificationSent: false })
	}

	handleNotificationTypeChange = (selectedNotificationType) => {
		this.setState({
			selectedNotificationType,
			notificationEmail: this.props.documentationEmail,
			selectedNotificationTech: {},
		})
	}
	handleNotificationTechSelected = (selectedNotificationTech) => {
		this.setState({ selectedNotificationTech })
	}
	handleCancelSendTechNotification = () => {
		this.setState({
			show: false,
			selectedNotificationTech: {},
			selectedNotificationType: this.props.selectedRequestor,
			notificationEmail: this.props.documentationEmail,
			showValidationErrors: false,
			message: "",
		})
	}
	handleSendTechNotification = () => {
		this.setState({ show: true })
	}

	render() {
		return (
			<CommentNotification
				onNotificationTypeChange={this.handleNotificationTypeChange}
				onNotificationTechSelected={this.handleNotificationTechSelected}
				selectedNotificationTech={this.state.selectedNotificationTech}
				selectedNotificationType={this.state.selectedNotificationType}
				onConfirmSendNotification={this.handleConfirmSendNotification}
				onConfirmCancelSendNotification={this.handleCancelSendTechNotification}
				onCancelSendTechNotification={this.handleCancelSendTechNotification}
				onSendTechNotification={this.handleSendTechNotification}
				show={this.state.show}
				setInputRef={this.handleSetInputRef}
				hasDocumentationEmail={this.props.hasDocumentationEmail}
				documentationEmail={this.props.documentationEmail}
				onConfirmNotificationSent={this.handleConfirmNotificationSent}
				notificationSent={this.state.notificationSent}
				notificationSentText={this.state.notificationSentText}
				{...this.formValidator.generateFormProps()}
			/>
		)
	}
}

export default withCallApi(CommentNotificationContainer)

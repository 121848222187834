import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { withRouter } from "react-router-dom"
import callApi from "@ncs/bricks/redux/services/callApi"
import { getEvents } from "@ncs/mortar/redux/services/ticketing"
import { fetchData } from "@ncs/bricks/redux/services/dynamicTables"
import { APPLICATIONS } from "@ncs/mortar/util/constants"
import { meetsAppRestriction } from "@ncs/bricks/redux/selectors/auth"

import TicketEvent from "./TicketEvent"

export const reduxKey = "ticketEvents"

class TicketManagement extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			selectedTicketType: "all",
			selectedTicketGroup: "all",
			selectedTicketStatus: "all",
			selectedTicketSubtype: "all",
			selectedTicketPriority: "all",
			params: {
				type: null,
				priority: null,
				groups: null,
				status: null,
				subtype: null,
			},
		}
	}

	getEvents = () => getEvents(this.getIdFromUrlParams())
	getIdFromUrlParams = (props = this.props) => props.match.params.id

	render() {
		const isAdmin = this.props.isTicketingAdmin()

		return <TicketEvent reduxKey={reduxKey} getEvents={this.getEvents} isAdmin={isAdmin} />
	}
}
const mapStateToProps = (state) => {
	return {
		isTicketingAdmin: () => {
			return meetsAppRestriction(state.auth, APPLICATIONS.TicketingAdmin)
		},
		...state.lookups,
	}
}

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ callApi, fetchData: fetchData(reduxKey) }, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TicketManagement))

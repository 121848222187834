import React from "react"
import { BaseSelectFilter, withDynamicTableFilter } from "@ncs/bricks/components"
import { FILTER_OVERRIDE } from "../../util/constants"

const options = [
	{ value: "", text: "All Invoices" },
	{ value: "payment_due", text: "Payment Due" },
	{ value: "partially_paid", text: "Partially Paid" },
	{ value: "paid_in_full", text: "Paid in Full" },
]

function getFilterValue(value) {
	if (!value) {
		return { id: "" }
	}

	return { id: value, value: true }
}

function AmountDueFilter(props) {
	function handleChange(value) {
		props.onChange({ type: FILTER_OVERRIDE, value, filterValue: getFilterValue(value) })
	}

	return (
		<BaseSelectFilter
			id="amount_due"
			labelText="Amount Due"
			options={options}
			{...props}
			value={props.value?.value || options[0].value}
			onChange={handleChange}
		/>
	)
}

export default withDynamicTableFilter("amount_due")(AmountDueFilter)

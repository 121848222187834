const specialCharsRegex = /[.*+?^${}()|[\]\\]/g
const whitespacesRegex = /\s+/
const escapeRegexCharacters = (str) => str.replace(specialCharsRegex, "\\$&")

export const match = (text, query) =>
	query
		.trim()
		.split(whitespacesRegex)
		// If query is blank, we'll get empty string here, so let's filter it out.
		.filter(function (word) {
			return word.length > 0
		})
		.reduce(function (result, word) {
			const wordLen = word.length
			const regex = new RegExp(escapeRegexCharacters(word), "i")
			let index = text.match(regex)
			index = index?.index ?? -1

			if (index > -1) {
				result.push([index, index + wordLen])

				// Replace what we just found with spaces so we don't find it again.
				text =
					text.slice(0, index) +
					new Array(wordLen + 1).join(" ") +
					text.slice(index + wordLen)
			}

			return result
		}, [])
		.sort(function (match1, match2) {
			return match1[0] - match2[0]
		})

import { FC, useState } from "react"

import parsePhoneNumberFromString from "libphonenumber-js"
import { isValidPhoneNumber } from "react-phone-number-input"

import { makeApiErrorMessage } from "@ncs/ncs-api"

import { PhoneInput, PhoneInputProps } from "../inputs"
import { Paragraph } from "../typography"
import { ExtendableModalProps, Modal } from "./Modal"

export interface EditPhoneModalProps extends ExtendableModalProps {
	onSave: (value: string | null) => void | Promise<void>
	label?: string
	initialValue?: string
	allowEmpty?: boolean
	emptyValue?: string | null
	title?: string
	placeholder?: string
	phoneInputProps?: PhoneInputProps
	saveButtonLabel?: string
	message?: string
}

export const EditPhoneModal: FC<EditPhoneModalProps> = ({
	onSave,
	label = "Phone",
	initialValue,
	allowEmpty = false,
	emptyValue = null,
	title = "Edit Phone",
	placeholder,
	phoneInputProps,
	saveButtonLabel = "Save",
	message,
	...rest
}) => {
	const [value, setValue] = useState<string | null>(() => {
		// If initial value isn't a valid phone number, then don't set it here.
		if (initialValue) {
			const parsed = parsePhoneNumberFromString(initialValue ?? "", "US")?.number
			if (parsed && isValidPhoneNumber(parsed)) {
				return parsed
			}
		}

		return emptyValue ?? null
	})
	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const handleSave = async () => {
		try {
			if (!value && !allowEmpty) {
				throw new Error("Please enter a value")
			}
			if (!!value && !isValidPhoneNumber(value)) {
				throw new Error("Please enter a valid phone number")
			}

			setIsSaving(true)
			await onSave(value)
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const reset = () => {
		setValue(null)
		setIsSaving(false)
		setErrorText(null)
	}

	return (
		<Modal
			errorText={errorText}
			title={title}
			onOpen={reset}
			{...rest}
			rightButtons={{
				buttonText: saveButtonLabel,
				onClick: handleSave,
				isLoading: isSaving,
				disabled: !value && !allowEmpty,
			}}
		>
			{!!message && <Paragraph mb={1}>{message}</Paragraph>}

			<PhoneInput
				fillContainer
				{...phoneInputProps}
				label={label}
				placeholder={placeholder}
				value={value}
				onChange={(newValue) => {
					setValue((newValue || emptyValue) ?? null)
				}}
			/>
		</Modal>
	)
}

import React from "react"
import { BaseSelectFilter, withCallApi, withDynamicTableFilter } from "@ncs/bricks/components"
import { INVOICE_TYPES } from "../../util/constants"

class InvoiceTypeFilter extends React.PureComponent {
	types = [
		{ id: "", description: "All Invoice Types" },
		{ id: INVOICE_TYPES.DISPATCH, description: "Dispatches" },
		{ id: INVOICE_TYPES.PARTS_ORDER, description: "Parts Orders" },
		{ id: INVOICE_TYPES.DELIVERY_ORDER, description: "Delivery Orders" },
		{ id: INVOICE_TYPES.KBM_INVOICE, description: "KBM Invoices" },
	]

	render() {
		return (
			<BaseSelectFilter
				id="invoice_type"
				labelText="Invoice Type"
				options={this.types}
				valueAccessor="id"
				textAccessor="description"
				{...this.props}
			/>
		)
	}
}

export const AliasedInvoiceTypeFilter = (filterName) =>
	withCallApi(withDynamicTableFilter(filterName)(InvoiceTypeFilter))

export default AliasedInvoiceTypeFilter("invoice_type")

import { Dispatch, FC, SetStateAction, useState } from "react"

import {
	AppliedPromotions,
	GenericLineItem,
	makeApiErrorMessage,
	Promotion,
	useValidatePromotions,
} from "@ncs/ncs-api"
import { unpythonify } from "@ncs/ts-utils"
import { CheckboxGroup, ExtendableModalProps, Modal, Paragraph, useToast } from "@ncs/web-legos"

export interface PromotionsModalProps extends ExtendableModalProps {
	customerId: string
	lineItems: GenericLineItem[]
	promotions: Promotion[]
	appliedPromotions: AppliedPromotions[]
	setAppliedPromotions: Dispatch<SetStateAction<AppliedPromotions[]>>
}

export const PromotionsModal: FC<PromotionsModalProps> = ({
	customerId,
	lineItems,
	promotions,
	appliedPromotions,
	setAppliedPromotions,
	...rest
}) => {
	const { makeSuccessToast } = useToast()
	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)
	const [checked, setChecked] = useState<{
		[promoId: string]: boolean
	}>(() =>
		Object.fromEntries(
			promotions.map((promo) => [
				promo.id,
				appliedPromotions.some((appliedPromo) => appliedPromo.id === promo.id),
			])
		)
	)

	const validatePromotions = useValidatePromotions()

	const handleApply = async () => {
		try {
			setIsSaving(true)
			const { data } = await validatePromotions({
				customerId,
				promotionIds: Object.entries(checked)
					.filter(([, status]) => status)
					.map(([promoId]) => promoId),
				parts: lineItems.flatMap((line) =>
					line.part ?
						[
							{
								partId: line.part.id,
								quantity: line.quantity,
								netPrice: line.finalPrice,
							},
						]
					:	[]
				),
			})
			const validPromos = unpythonify(data).filter((promo) => promo.discount > 0)
			setAppliedPromotions(validPromos)
			makeSuccessToast(
				`${validPromos.length} promotion${validPromos.length !== 1 ? "s" : ""} applied`
			)
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	return (
		<Modal
			{...rest}
			title="Apply Part Order Promotions"
			errorText={errorText}
			rightButtons={{
				buttonText: "Validate and apply",
				onClick: handleApply,
				isLoading: isSaving,
			}}
		>
			<Paragraph mb={1}>
				Add all your line items to the order, and then select which promotions you intend
				to apply to the order. The system will validate that the order qualifies and apply
				the promotions if able.
			</Paragraph>
			<CheckboxGroup
				rows={promotions ?? []}
				valueAccessor="id"
				labelAccessor="description"
				checkedAccessor={(row) => !!checked[row.id]}
				onChange={(row, newState) => {
					setChecked((prev) => ({
						...prev,
						[row.id]: newState,
					}))
				}}
			/>
		</Modal>
	)
}

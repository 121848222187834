import React from "react"
import PropTypes from "prop-types"
import { createStyles, makeStyles } from "@material-ui/core"

const useStyles = makeStyles(
	createStyles({
		container: {
			position: "relative",
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
			margin: "2rem 0 1rem 0",
		},
		heading: {
			position: "relative",
			display: "inline-block",
			paddingRight: "1rem",
			margin: 0,
			background: "white",
			zIndex: 1,
		},
		rightButton: {
			position: "relative",
			paddingLeft: "1rem",
			background: "white",
			zIndex: 1,
		},
		line: {
			position: "absolute",
			left: 0,
			right: 0,
			bottom: "1rem",
			borderTop: "1px solid rgba(0, 0, 0, 0.05)",
			zIndex: 0,
		},
	})
)

export interface HeadingWithLineProps extends React.HTMLAttributes<HTMLDivElement> {
	heading: string
	rightButton?: React.ReactNode
}

const HeadingWithLine: React.FC<HeadingWithLineProps> = ({ heading, rightButton, ...rest }) => {
	const classes = useStyles()

	return (
		<div className={classes.container} {...rest}>
			<h3 className={classes.heading}>{heading}</h3>
			{rightButton && <div className={classes.rightButton}>{rightButton}</div>}
			<div className={classes.line} />
		</div>
	)
}

HeadingWithLine.propTypes = {
	heading: PropTypes.string.isRequired,
	rightButton: PropTypes.node,
}

export default HeadingWithLine

import {
	patch,
	multipartPost,
	multipartPatch,
	post,
	get,
	rsaaTypes,
	RSAA,
} from "@ncs/bricks/middleware/portalApiMiddleware"
import moment from "moment"

export const LIST_DOCUMENTS = rsaaTypes("info_ref/documents/list")
export const GET_DOCUMENT = rsaaTypes("info_ref/documents/get")
export const SEARCH_DOCUMENTS = rsaaTypes("info_ref/documents/search")
export const PATCH_DOCUMENT = rsaaTypes("info_ref/document/patch")
export const UPDATE_DOCUMENT_FILE = rsaaTypes("info_ref/document/patchFile")
export const DELETE_DOCUMENT = rsaaTypes("info_ref/document/delete")
export const GENERATE_DOCUMENT_LINK = rsaaTypes("info_ref/document/generate_link")
export const UPLOAD_FILE = rsaaTypes("info_ref/document/post")

export const LIST_INFO_REF_CATEGORIES = rsaaTypes("info_ref/categories/list")
export const GET_CATEGORY = rsaaTypes("info_ref/category/get")
export const ADD_CATEGORY = rsaaTypes("info_ref/category/post")
export const EDIT_CATEGORY = rsaaTypes("info_ref/category/put")
export const DELETE_CATEGORY = rsaaTypes("info_ref/category/delete")

export const SYNC_CATEGORIES = rsaaTypes("info_ref/categories/sync")

export const listDocuments = (querystring_params) => ({
	[RSAA]: {
		api: get("/info_ref/documents/", querystring_params),
		types: LIST_DOCUMENTS,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const getDocument = (id) => ({
	[RSAA]: {
		api: get(`/info_ref/documents/${id}/`),
		types: GET_DOCUMENT,
	},
})

const searchDocumentsAPI = (searchText) => ({
	[RSAA]: {
		api: get("/info_ref/documents/", {
			search: searchText,
			fields: "id,document_type,filename,extension,created_date,uploaded_by,is_deleted,category",
		}),
		types: SEARCH_DOCUMENTS,
	},
})

export const searchDocuments = (searchText) => {
	const debounced = (dispatch) => {
		dispatch(searchDocumentsAPI(searchText))
	}

	debounced.meta = {
		debounce: {
			time: 325,
			key: SEARCH_DOCUMENTS,
		},
	}

	return debounced
}

export const patchDocument = (id, data) => ({
	[RSAA]: {
		api: patch(`/info_ref/documents/${id}/`, data),
		types: PATCH_DOCUMENT,
	},
})

export const updateDocumentFile = (id) => {
	// FileInput handles attaching file to the body
	return {
		[RSAA]: {
			api: multipartPatch(`/info_ref/documents/${id}/`),
			types: UPDATE_DOCUMENT_FILE,
			multipart: true,
		},
	}
}

export const deleteDocument = (documentId) => ({
	[RSAA]: {
		api: patch(`/info_ref/documents/${documentId}/`, { is_deleted: true }),
		types: DELETE_DOCUMENT,
	},
})

export const generateDocumentink = (documentId) => ({
	[RSAA]: {
		api: post(`/info_ref/documents/${documentId}/generate_link/`),
		types: GENERATE_DOCUMENT_LINK,
	},
})

export const listInfoRefCategories = () => ({
	[RSAA]: {
		api: get("/info_ref/categories/"),
		types: LIST_INFO_REF_CATEGORIES,
	},
})

export const syncInfoRefCategories = () => ({
	[RSAA]: {
		api: post(`/info_ref/categories/sync/`),
		types: SYNC_CATEGORIES,
	},
})

export const createDocument = (data) => {
	// FileInput handles attaching file to the body
	let formatDate = (x) => (!x ? x : moment(x).toISOString().replace("T", " ").replace("Z", ""))
	let parsed = {
		description: data.description,
		document_number: data.document_number,
		revision: data.revision,
		category_ids: data.categories,
		effective_date: data.effectiveDate !== null ? formatDate(data.effective_date) : null,
		expiration_date: data.expirationDate !== null ? formatDate(data.expiration_date) : null,
	}

	return {
		[RSAA]: {
			api: multipartPost("/info_ref/documents/", parsed),
			types: UPLOAD_FILE,
			multipart: true,
		},
	}
}

export const getCategory = (id) => ({
	[RSAA]: {
		api: get(`/info_ref/categories/${id}/`),
		types: GET_CATEGORY,
	},
})

export const addCategory = (parent_id, name) => ({
	[RSAA]: {
		api: post("/info_ref/categories/", { parent_id, name, is_deleted: false }),
		types: ADD_CATEGORY,
	},
})

export const patchCategory = (id, data) => ({
	[RSAA]: {
		api: patch(`/info_ref/categories/${id}/`, data),
		types: EDIT_CATEGORY,
	},
})

export const deleteCategory = (id) => ({
	[RSAA]: {
		api: patch(`/info_ref/categories/${id}/`, { is_deleted: true }),
		types: DELETE_CATEGORY,
	},
})

const initialState = {
	isLoadingDocuments: false,
	isLoadingInfoRefCategories: false,
	isSearchingDocuments: false,
	isLoadingSingleDocument: false,
	documents: [],
	item: null,
	documentsPages: 1,
	documentsRequestTimestamp: null,
	documentCategories: [],
	reverseDocumentCategoriesMap: {},
	isGettingDocumentCategory: false,
	documentCategory: null,
	error: null,
}

const infoRef = (state = initialState, action) => {
	switch (action.type) {
		case LIST_DOCUMENTS.request:
			return {
				...state,
				isLoadingDocuments: true,
			}
		case LIST_DOCUMENTS.success:
			// bail if we would be overwriting newer data
			if (state.documentsRequestTimestamp > action.meta.timestamp) {
				return state
			}

			return {
				...state,
				isLoadingDocuments: false,
				documents: action.payload,
				documentsPages: action.meta.pages,
				documentsRequestTimestamp: action.meta.timestamp,
				error: null,
			}
		case LIST_DOCUMENTS.failure:
			return {
				...state,
				isLoadingDocuments: false,
				error: action.error,
			}

		case GET_DOCUMENT.request:
		case GET_DOCUMENT.success:
		case GET_DOCUMENT.failure:
			return {
				...state,
				isLoadingSingleDocument: action.type === GET_DOCUMENT.request,
				item: action.type === GET_DOCUMENT.success ? action.payload : state.item,
				error: action.error,
			}

		case SEARCH_DOCUMENTS.request:
		case SEARCH_DOCUMENTS.success:
		case SEARCH_DOCUMENTS.failure:
			return {
				...state,
				isSearchingDocuments: action.type === SEARCH_DOCUMENTS.request,
				documentSearchResults:
					action.type === SEARCH_DOCUMENTS.success ?
						action.payload
					:	state.documentSearchResults,
				error: action.error,
			}

		case GENERATE_DOCUMENT_LINK.request:
			return {
				...state,
				isGeneratingLink: true,
			}
		case GENERATE_DOCUMENT_LINK.success:
			return {
				...state,
				isGeneratingLink: false,
				documentLink: action.payload.presign_url,
			}

		case LIST_INFO_REF_CATEGORIES.request:
		case LIST_INFO_REF_CATEGORIES.failure:
			return {
				...state,
				isLoadingInfoRefCategories: action.type === LIST_INFO_REF_CATEGORIES.request,
				error: action.error,
			}

		case LIST_INFO_REF_CATEGORIES.success:
			let documentCategories = action.payload ?? []
			let reverseDocumentCategoriesMap = {}

			for (let top of documentCategories) {
				reverseDocumentCategoriesMap[top.id] = { ...top, parent: null }
				for (let second of top.sub_categories) {
					reverseDocumentCategoriesMap[second.id] = {
						...second,
						parent: reverseDocumentCategoriesMap[top.id],
					}
					for (let third of second.sub_categories) {
						reverseDocumentCategoriesMap[third.id] = {
							...third,
							parent: reverseDocumentCategoriesMap[second.id],
						}
						for (let fourth of third.sub_categories) {
							reverseDocumentCategoriesMap[fourth.id] = {
								...fourth,
								parent: reverseDocumentCategoriesMap[third.id],
							}
						}
					}
				}
			}

			return {
				...state,
				isLoadingInfoRefCategories: false,
				documentCategories,
				reverseDocumentCategoriesMap,
			}

		case UPLOAD_FILE.request:
		case UPLOAD_FILE.success:
		case UPLOAD_FILE.failure:
			return {
				...state,
				isLoadingPresignedUploadUrl: action.type === UPLOAD_FILE.request,
				error: action.error,
			}

		case GET_CATEGORY.request:
		case GET_CATEGORY.success:
		case GET_CATEGORY.failure:
			return {
				...state,
				isGettingDocumentCategory: action.type === GET_CATEGORY.request,
				documentCategory: action.type === GET_CATEGORY.success ? action.payload : null,
				error: action.error,
			}

		case PATCH_DOCUMENT.request:
		case UPDATE_DOCUMENT_FILE.request:
		case DELETE_DOCUMENT.request:
		case ADD_CATEGORY.request:
		case EDIT_CATEGORY.request:
		case DELETE_CATEGORY.request:
			return {
				...state,
				isSaving: true,
			}
		case PATCH_DOCUMENT.success:
		case UPDATE_DOCUMENT_FILE.success:
		case DELETE_DOCUMENT.success:
		case ADD_CATEGORY.success:
		case EDIT_CATEGORY.success:
		case DELETE_CATEGORY.success:
			return {
				...state,
				isSaving: false,
				error: null,
			}
		case PATCH_DOCUMENT.failure:
		case UPDATE_DOCUMENT_FILE.failure:
		case DELETE_DOCUMENT.failure:
		case ADD_CATEGORY.failure:
		case EDIT_CATEGORY.failure:
		case DELETE_CATEGORY.failure:
			return {
				...state,
				isSaving: false,
				error: action.error,
			}

		default:
			return state
	}
}
export default infoRef

import { FC, useMemo } from "react"

import { ErpStatus, useErpStatuses } from "@ncs/ncs-api"

import { ExtendableSelectProps, Select } from "../inputs"

export interface ErpStatusSelectorProps extends ExtendableSelectProps<ErpStatus> {}

export const ErpStatusSelector: FC<ErpStatusSelectorProps> = ({ ...rest }) => {
	const [statuses, loading] = useErpStatuses()

	const options = useMemo(() => {
		return (statuses ?? []).sort((a, b) => (a.description > b.description ? 1 : -1))
	}, [statuses])

	return (
		<Select
			{...rest}
			label="Status"
			options={options}
			disabled={loading}
			valueAccessor="id"
			textAccessor="description"
		/>
	)
}

import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import callApi from "@ncs/bricks/redux/services/callApi"
import { loadLookups, LOOKUPS } from "../../redux/services/lookups"

// custom components
import { Select } from "@ncs/bricks/components"

class TicketSubtypeSelector extends React.PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			subtypes: [],
			subtype: null,
		}
	}

	componentDidMount() {
		this.props.loadLookups([LOOKUPS.TicketSubtypes], this.filterTicketSubtype)
	}

	// filter subtypes whenever the type changes
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.type !== this.props.type) {
			this.filterTicketSubtype()
		}
		if (prevProps.subtype !== this.props.subtype) {
			this.setState({
				subtype: this.state.subtypes.filter((x) => x.id === this.props.subtype),
			})
		}
	}

	filterTicketSubtype = () => {
		// if type is empty
		if (!this.props.type) {
			this.setState({ subtypes: [] })
			return
		}
		let subtypes = this.props.subTypes.filter((x) => x.type_id === Number(this.props.type))
		this.setState({ subtypes })

		// if the currently selected subtype is not in the results, erase the selected subtype
		if (this.state.subtype && !subtypes.some((t) => t.id.toString() === this.state.subtype)) {
			this.props.onChange(null)
		}
	}

	render() {
		const { displayEmpty, ...rest } = this.props
		return (
			<Select
				id="subtypes"
				labelText="Subtypes"
				value={
					this.props.subtype ? this.props.subtype
					: this.state.subtype ?
						this.state.subtype
					:	null
				}
				options={[
					{
						id: null,
						description:
							!this.props.type ? "No subtypes for this type"
							: this.props.subtype ? "None"
							: this.state.subtypes.length > 0 ? "Select a subtype..."
							: "No subtypes for this type",
						disabled: false,
					},
					...this.state.subtypes,
				]}
				valueAccessor="id"
				textAccessor="description"
				onChange={this.props.onChange}
				labelProps={{
					shrink: displayEmpty ? true : undefined,
					...rest.labelProps,
				}}
				selectProps={{
					displayEmpty: displayEmpty,
					disabled: this.state.subtypes.length < 1,
					...rest.selectProps,
				}}
				{...rest}
			/>
		)
	}
}

TicketSubtypeSelector.defaultProps = {
	displayEmpty: true,
}

TicketSubtypeSelector.propTypes = {
	type: PropTypes.object,
	onBinSelected: PropTypes.func.isRequired,
	displayEmpty: PropTypes.bool,
}

const mapStateToProps = (state) => {
	return {
		subTypes: state.lookups.ticketSubtypes,
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			callApi,
			loadLookups,
		},
		dispatch
	)

export default connect(mapStateToProps, mapDispatchToProps)(TicketSubtypeSelector)

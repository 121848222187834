import React from "react"

import { ExtendableSelectProps, Select } from "../inputs"

export interface DispatchServiceNotificationReasonProps
	extends ExtendableSelectProps<
		(typeof options)[number],
		DispatchServiceNotificationReasonEnum
	> {}

export enum DispatchServiceNotificationReasonEnum {
	Dispatch = "2",
	Invoice = "3",
	PartOrder = "1",
	WorkOrders = "4",
}

const options: {
	value: DispatchServiceNotificationReasonEnum | null
	text: string
}[] = [
	{
		value: DispatchServiceNotificationReasonEnum.Invoice,
		text: "Invoice",
	},
	{
		value: DispatchServiceNotificationReasonEnum.WorkOrders,
		text: "Work Orders",
	},
	{
		value: DispatchServiceNotificationReasonEnum.Dispatch,
		text: "Dispatch",
	},
	{
		value: DispatchServiceNotificationReasonEnum.PartOrder,
		text: "Part Order",
	},
]

export const DispatchServiceNotificationReason: React.FC<
	DispatchServiceNotificationReasonProps
> = ({ onChange, label = "Notification Reason", ...rest }) => {
	return (
		<Select
			disableNoSelectionOption={false}
			label={label}
			options={options}
			onChange={(newValue) =>
				onChange(newValue as DispatchServiceNotificationReasonEnum | null)
			}
			{...rest}
		/>
	)
}

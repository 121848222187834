import { FC } from "react"

import { LaborRateTypeId } from "@ncs/ncs-api"
import { ExtendableSelectProps, Select } from "@ncs/web-legos"

export type LaborRateTypeSelectorProps = ExtendableSelectProps<(typeof options)[number]>

export const LaborRateTypeSelector: FC<LaborRateTypeSelectorProps> = ({ ...rest }) => {
	return <Select label="Labor rate type" {...rest} options={options} />
}

export const laborRateTypeDescriptions: Record<LaborRateTypeId, string> = {
	[LaborRateTypeId.Labor]: "Labor",
	[LaborRateTypeId.FlatRate]: "Flat",
}

const options = [
	{
		value: LaborRateTypeId.Labor,
		text: laborRateTypeDescriptions[LaborRateTypeId.Labor],
	},
	{
		value: LaborRateTypeId.FlatRate,
		text: laborRateTypeDescriptions[LaborRateTypeId.FlatRate],
	},
]

import { Dispatch, ReactElement, SetStateAction } from "react"

import { OrderShipMethodSelector, OrderShipMethodSelectorProps } from "../selectors"

// Trying this to make it more type safe...
enum OrderShipMethodParamKey {
	ShipMethod = "shipMethod",
}

interface OrderShipMethodParamState {
	[OrderShipMethodParamKey.ShipMethod]: string | null
}

export interface OrderShipMethodQueryFilterProps<QueryParamState extends OrderShipMethodParamState>
	extends Omit<OrderShipMethodSelectorProps, "value" | "onChange"> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

export const OrderShipMethodQueryFilter = <QueryParamState extends OrderShipMethodParamState>({
	queryParamState,
	setQueryParamState,
	...rest
}: OrderShipMethodQueryFilterProps<QueryParamState>): ReactElement => {
	return (
		<OrderShipMethodSelector
			fillContainer
			disableNoSelectionOption={false}
			{...rest}
			value={queryParamState[OrderShipMethodParamKey.ShipMethod]}
			onChange={(newValue) =>
				setQueryParamState((prev) => ({
					...prev,
					[OrderShipMethodParamKey.ShipMethod]: newValue,
				}))
			}
		/>
	)
}

import React from "react"

import { Control, Controller, FieldValues, Path } from "react-hook-form"

import { CustomerBillToSelector, CustomerBillToSelectorProps } from "../selectors"

export interface CustomerBillToSelectorFormFieldProps<TFieldValues extends FieldValues>
	extends Omit<CustomerBillToSelectorProps, "onChange" | "value"> {
	name: Path<TFieldValues>
	control: Control<TFieldValues>
}

export const CustomerBillToSelectorFormField = <TFieldValues extends FieldValues>({
	name,
	control,
	customerId,
	...rest
}: CustomerBillToSelectorFormFieldProps<TFieldValues>): React.ReactElement => {
	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { onBlur, value, onChange }, fieldState: { error } }) => {
				return (
					<CustomerBillToSelector
						{...rest}
						onBlur={onBlur}
						value={value ?? null}
						onChange={(newBillToId) => onChange(newBillToId)}
						customerId={customerId}
						error={error?.message}
						fillContainer
					/>
				)
			}}
		/>
	)
}

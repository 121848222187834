import { FC, useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"

import { makeApiErrorMessage } from "@ncs/ncs-api"
import { ExtendableModalProps, Modal, Paragraph, TextInputFormField } from "@ncs/web-legos"

import { ContractFormRecipient } from "./contract-utils"

export interface AddExternalRecipientModalProps extends ExtendableModalProps {
	onSave: (user: ContractFormRecipient) => Promise<void> | void
}

const ExternalRecipientFormSchema = z.object({
	name: z.string().min(1).max(255),
	email: z.string().max(255).email(),
	description: z.string().max(255).nullable(),
})

type ExternalRecipientForm = z.infer<typeof ExternalRecipientFormSchema>

const externalRecipientFormDefaultValue: ExternalRecipientForm = {
	name: "",
	email: "",
	description: null,
}

export const AddExternalRecipientModal: FC<AddExternalRecipientModalProps> = ({
	onSave,
	...rest
}) => {
	const {
		handleSubmit,
		control,
		reset,
		formState: { isValid, submitCount },
	} = useForm<ExternalRecipientForm>({
		resolver: zodResolver(ExternalRecipientFormSchema),
		defaultValues: externalRecipientFormDefaultValue,
	})

	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const onSubmit = async (formData: ExternalRecipientForm) => {
		try {
			setIsSaving(true)
			await onSave({
				name: formData.name,
				email: formData.email,
				description: formData.description,
			})
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const onOpen = () => {
		reset()
		setIsSaving(false)
		setErrorText(null)
	}

	return (
		<Modal
			{...rest}
			onOpen={onOpen}
			errorText={errorText}
			title="Add External Recipient"
			rightButtons={{
				buttonText: "Add Person",
				onClick: handleSubmit(onSubmit),
				isLoading: isSaving,
				disabled: submitCount > 0 && !isValid,
			}}
		>
			<Paragraph mb={1}>
				Add the contract information for anyone who should receive notification emails.
			</Paragraph>

			<TextInputFormField control={control} name="name" />
			<TextInputFormField control={control} name="email" />
			<TextInputFormField
				control={control}
				name="description"
				label="Description / Role (optional)"
			/>
		</Modal>
	)
}

import React from "react"

import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	makeStyles,
	Slide,
} from "@material-ui/core"
import PropTypes from "prop-types"

import styles from "../../assets/jss/material-dashboard-pro-react/components/loadingWrapperStyle"
import { Button, CircularProgressIndeterminate } from "../../components"

const useStyles = makeStyles(styles)

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />
})

function LoadingWrapper(props) {
	const classes = useStyles()
	const { children, isLoading, title, description } = props

	return (
		<React.Fragment>
			<Dialog
				classes={{
					root: classes.center + " " + classes.modalRoot,
					paper: classes.modal,
				}}
				open={props.isLoading}
				TransitionComponent={Transition}
				transitionDuration={{ enter: 500, exit: 300 }}
				aria-labelledby="notice-modal-slide-title"
				aria-describedby="notice-modal-slide-description"
			>
				<DialogTitle
					id="notice-modal-slide-title"
					disableTypography
					className={classes.modalHeader}
				>
					<h3 className={classes.modalTitle}>{title}</h3>
				</DialogTitle>
				<DialogContent id="notice-modal-slide-description" className={classes.modalBody}>
					<CircularProgressIndeterminate />
					{description}
				</DialogContent>
				<DialogActions className={classes.modalFooter + " " + classes.modalFooterCenter}>
					<Button onClick={() => false} color="info" round>
						Load Faster
					</Button>
				</DialogActions>
			</Dialog>
			{isLoading !== false ?
				undefined
			:	<React.Fragment>
					{typeof children === "function" ? children() : children}
				</React.Fragment>
			}
		</React.Fragment>
	)
}

LoadingWrapper.defaultProps = {
	title: "Loading...",
	description: "",
}

LoadingWrapper.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	title: PropTypes.string,
	description: PropTypes.string,
}

export default React.memo(LoadingWrapper)

import { FC, useState } from "react"

import { InventoryPart } from "@ncs/ncs-api"
import { ExtendableModalProps, Heading, Modal, PartSelector } from "@ncs/web-legos"

export interface AddPartModalProps extends ExtendableModalProps {
	onSave: (part: InventoryPart) => void
}

export const AddPartModal: FC<AddPartModalProps> = ({ onSave, ...rest }) => {
	const [part, setPart] = useState<InventoryPart | null>(null)

	return (
		<Modal
			{...rest}
			rightButtons={[
				{
					buttonText: "Add",
					onClick: () => {
						if (part) {
							onSave(part)
						}
						rest.onClose()
					},
					disabled: !part,
					nestedButtons: [
						{
							buttonText: "Add + Choose Another",
							disabled: !part,
							onClick: () => {
								if (part) {
									onSave(part)
									setPart(null)
								}
							},
						},
					],
				},
			]}
		>
			<Heading mb={2} bold>
				Add Part
			</Heading>
			<PartSelector
				value={part}
				onChange={setPart}
				autoFocus
				includeNonService
				skipRestrictedCheck
			/>
		</Modal>
	)
}

import { FC, useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"

import {
	CreateCustomerCreditPost,
	CustomerDetail,
	makeApiErrorMessage,
	useCreateCustomerCredit,
} from "@ncs/ncs-api"
import { DateFormat, formatCurrency, formatDate } from "@ncs/ts-utils"
import {
	BusinessUnitSelector,
	DateInputFormField,
	Divider,
	ExtendableModalProps,
	GridContainer,
	GridItem,
	HookFormErrorText,
	Modal,
	NumericInputFormField,
	Paragraph,
	RadioBooleanFormField,
	TextareaFormField,
	useToast,
} from "@ncs/web-legos"

export interface AddBusinessCreditModalProps extends ExtendableModalProps {
	customer: CustomerDetail
}

export const AddBusinessCreditModal: FC<AddBusinessCreditModalProps> = ({ customer, ...rest }) => {
	const { makeSuccessToast } = useToast()
	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const createCredit = useCreateCustomerCredit()

	const { control, setValue, handleSubmit, watch } = useForm<AddBusinessCreditForm>({
		resolver: zodResolver(AddBusinessCreditFormSchema),
		defaultValues: { ...defaultAddBusinessCreditFormValues },
	})

	const handleBusinessUnitChange = (
		businessUnitId: string | null,
		otherBusinessName?: string | null
	) => {
		setValue("businessUnitId", businessUnitId, { shouldValidate: true })
		setValue("otherBusinessUnitName", otherBusinessName ?? null, { shouldValidate: true })
	}

	const onSubmit = async (formData: AddBusinessCreditForm) => {
		try {
			setIsSaving(true)
			if (formData.amount == null) throw new Error("Amount is required")

			const postData: CreateCustomerCreditPost = {
				customerId: customer.id,
				amount: formData.amount,
				expiresOn: formatDate(formData.expirationDate, DateFormat.DateQueryParam),
				comments: formData.comments,
				isPerSite: formData.isPerSite,
				businessUnit: formData.businessUnitId,
				otherUnitName: formData.otherBusinessUnitName,
			}
			await createCredit(postData)
			makeSuccessToast("Credit added to customer(s)")
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const [amount] = watch(["amount"])

	return (
		<Modal
			{...rest}
			title="Add Business Unit Credit"
			rightButtons={{
				buttonText: "Add Credit",
				onClick: handleSubmit(onSubmit),
				isLoading: isSaving,
			}}
			errorText={errorText}
		>
			<BusinessUnitSelector value={null} onChange={handleBusinessUnitChange} />
			<HookFormErrorText control={control} name="businessUnitId" mb={1} />
			<GridContainer>
				<GridItem xs={12} sm={6}>
					<NumericInputFormField
						control={control}
						name="amount"
						label="Credit amount $"
						decimalScale={2}
						fixedDecimalScale
					/>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<DateInputFormField
						control={control}
						name="expirationDate"
						label="Expiration date"
					/>
				</GridItem>
			</GridContainer>
			<TextareaFormField control={control} name="comments" label="Comments (optional)" />

			<Divider my={2} />

			<Paragraph mb={0.75}>
				Is this credit amount just for this customer, or is it per site?
			</Paragraph>
			<RadioBooleanFormField
				control={control}
				name="isPerSite"
				noText={`${amount ? formatCurrency(amount) : ""} total for this customer`}
				yesText={`${
					amount ? formatCurrency(amount) : ""
				} per site that bills to this customer`}
				noFirst
			/>
		</Modal>
	)
}

const AddBusinessCreditFormSchema = z.object({
	businessUnitId: z.string().nullable(),
	otherBusinessUnitName: z.string().max(1000).nullable(),
	amount: z.number().min(1).nullable(),
	expirationDate: z.string().min(1, "Required"),
	comments: z.string().max(1000).nullable(),
	isPerSite: z.boolean(),
})

export type AddBusinessCreditForm = z.infer<typeof AddBusinessCreditFormSchema>

const defaultAddBusinessCreditFormValues: Partial<AddBusinessCreditForm> = {
	businessUnitId: null,
	otherBusinessUnitName: null,
	amount: undefined,
	expirationDate: "",
	comments: null,
	isPerSite: false,
}

import { Dispatch, ReactElement, SetStateAction, useCallback, useEffect, useState } from "react"

import { Warehouse } from "@ncs/ncs-api"

import { usePrevious } from "../../util"
import { WarehouseSelector } from "../selectors"

export interface WarehouseQueryParams {
	warehouse: string | null
}

export interface WarehouseQueryFilterProps<QueryParamState extends WarehouseQueryParams> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

export const WarehouseQueryFilter = <QueryParamState extends WarehouseQueryParams>({
	queryParamState,
	setQueryParamState,
}: WarehouseQueryFilterProps<QueryParamState>): ReactElement => {
	const [warehouse, setWarehouse] = useState<Warehouse | null>(null)

	const onChange = useCallback(
		(newWarehouse: Warehouse | null) => {
			setWarehouse(newWarehouse)
			setQueryParamState((prev) => ({
				...prev,
				warehouse: newWarehouse?.id ?? null,
			}))
		},
		[setQueryParamState]
	)

	// Listen for reset from above.
	const prevQueryParamWarehouse = usePrevious(queryParamState.warehouse)
	useEffect(() => {
		if (!queryParamState.warehouse && !!prevQueryParamWarehouse && !!warehouse) {
			setWarehouse(null)
		}
	}, [onChange, prevQueryParamWarehouse, warehouse, queryParamState.warehouse])

	return (
		<WarehouseSelector
			fillContainer
			value={warehouse}
			onChange={onChange}
			initialId={queryParamState.warehouse}
			addFakeGrimes={false}
		/>
	)
}

import { FC, useCallback, useMemo, useState } from "react"

import { Column } from "react-table"

import {
	APPLICATION,
	CustomerDetail,
	CustomerRestrictedPart,
	useCustomerRestrictedParts,
	useDeleteRestrictedParts,
	useUserCanUse,
} from "@ncs/ncs-api"
import {
	Button,
	ConfirmationModal,
	ConfirmationModalConfig,
	encodeUrlState,
	LoadingSpinner,
	Table,
	TableProps,
	Tooltip,
	useToast,
} from "@ncs/web-legos"

import { PartManagementUrlState } from "~/views/Inventory/PartManagement/PartManagement"

import { AddRestrictedPartModal } from "./components"

export interface RestrictedPartPermissionsProps {
	customer: CustomerDetail
}

export const RestrictedPartPermissions: FC<RestrictedPartPermissionsProps> = ({ customer }) => {
	const { makeSuccessToast } = useToast()
	const isPartAdmin = useUserCanUse(APPLICATION.PartManagementAdmin)
	const isInventoryAdmin = useUserCanUse([APPLICATION.InventoryManagement])
	const [showAddRestrictedPartModal, setShowAddRestrictedPartModal] = useState(false)
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)

	const [restrictedParts, restrictedPartsLoading] = useCustomerRestrictedParts(customer.id)

	const removePart = useDeleteRestrictedParts()

	const onRemove = useCallback(
		async (partId: string) => {
			await removePart({ body: { partId } })
			makeSuccessToast("Part permission removed")
		},
		[makeSuccessToast, removePart]
	)

	const rowMenu = useMemo((): TableProps<CustomerRestrictedPart>["rowMenu"] => {
		return [
			{
				label: "Remove permission",
				iconName: "trash-alt",
				disabledAccessor: () => !isPartAdmin,
				onClick: ({ original }) =>
					setConfirmationConfig(() => ({
						title: "Restrict Part",
						message: (
							<>
								Remove the ability of this customer to purchase{" "}
								<strong>
									({original.part.partNumber}) {original.part.description}
								</strong>
								?
							</>
						),
						onConfirm: () => onRemove(original.part.id),
					})),
			},
			{
				label: "View Part Management page",
				iconName: "external-link",
				disabledAccessor: () => !isInventoryAdmin,
				onClick: ({ original }) => {
					window.open(
						`/inventory/part-management/${encodeUrlState<PartManagementUrlState>({
							partId: original.part.id,
						})}`
					)
				},
			},
		]
	}, [onRemove, isPartAdmin, isInventoryAdmin])

	if (restrictedPartsLoading) {
		return <LoadingSpinner />
	}

	return (
		<>
			<Tooltip title="Requires Part Management Admin permission" disabled={isPartAdmin}>
				<Button
					icon="plus"
					onClick={() => setShowAddRestrictedPartModal(true)}
					disabled={!isPartAdmin}
				>
					Add allowed restricted part
				</Button>
			</Tooltip>

			{!!restrictedParts?.length && (
				<Table
					data={restrictedParts ?? []}
					isLoading={restrictedPartsLoading}
					columns={restrictedPartsColumns}
					rowMenu={rowMenu}
				/>
			)}

			{showAddRestrictedPartModal && (
				<AddRestrictedPartModal
					isOpen
					onClose={() => setShowAddRestrictedPartModal(false)}
					customer={customer}
				/>
			)}
			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</>
	)
}
const restrictedPartsColumns: Column<CustomerRestrictedPart>[] = [
	{
		Header: "Part #",
		accessor: ({ part }) => part.partNumber,
	},
	{
		Header: "Part description",
		accessor: ({ part }) => `(RESTRICTED) ${part.description}`,
	},
]

import { FC, useState } from "react"

import { ExtendableModalProps, Modal, TextInput } from "@ncs/web-legos"

export interface AddCustomCategoryModalProps extends ExtendableModalProps {
	onSave: (newCategory: string) => void
}

export const AddCustomCategoryModal: FC<AddCustomCategoryModalProps> = ({ onSave, ...rest }) => {
	const [name, setName] = useState<string | null>(null)

	const handleSave = () => {
		if (name) {
			onSave(name)
		}
		rest.onClose()
	}

	return (
		<Modal
			{...rest}
			title="Add Custom Category"
			onOpen={() => setName(null)}
			rightButtons={{
				buttonText: "Save",
				disabled: !name,
				onClick: handleSave,
			}}
		>
			<TextInput
				value={name}
				onChange={setName}
				label="Category name"
				autoFocus
				onReturn={handleSave}
			/>
		</Modal>
	)
}

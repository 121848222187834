import React from "react"
import PropTypes from "prop-types"

import SearchSelector from "./SearchSelector"

class CollectionSearchSelector extends React.Component {
	handleGetSuggestions = (search) => {
		let matches = []
		let searchParts = this.getSearchParts(search)
		for (let item of this.props.collection) {
			if (this.matchesSearchParts(item, searchParts)) {
				matches.push(item)
				if (matches.length === 15) break
			}
		}

		// this must return a promise with a payload property
		return Promise.resolve({ payload: matches })
	}

	handleSuggestionSelected = (event, { suggestion }) => {
		this.props.onSelected(suggestion)
	}

	getSearchParts = (search) => {
		return (search || "").toLowerCase().split(" ")
	}

	matchesSearchParts = (item, searchParts) => {
		let value = this.props.valueAccessor(item).toLowerCase()

		for (let part of searchParts) {
			if (value.indexOf(part) < 0) return false
		}

		return true
	}

	render = () => (
		<SearchSelector
			label={this.props.labelText}
			placeholder={this.props.placeholder}
			getSuggestions={this.handleGetSuggestions}
			getSuggestionValue={this.props.valueAccessor}
			onSuggestionSelected={this.handleSuggestionSelected}
			defaultValue={this.props.defaultValue}
			{...this.props}
		/>
	)
}

CollectionSearchSelector.defaultProps = {
	valueAccessor: (item) => item.toString(),
}

CollectionSearchSelector.propTypes = {
	labelText: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
	collection: PropTypes.array.isRequired,
	valueAccessor: PropTypes.func,
	defaultValue: PropTypes.any,
	onSelected: PropTypes.func.isRequired,
}

export default CollectionSearchSelector

import { FC, useState } from "react"

import { useHistory } from "react-router-dom"
import { Cell, Column } from "react-table"

import {
	LaborPricingListItem,
	LaborPricingListQueryParams,
	LaborRateTypeId,
	useLaborPricings,
} from "@ncs/ncs-api"
import { displayDate, displayNumber, formatCurrency, isEnumMember } from "@ncs/ts-utils"
import {
	Button,
	Card,
	CustomerQueryFilter,
	SearchQueryFilter,
	Table,
	TerritoryQueryFilter,
	Tooltip,
	useUrlState,
} from "@ncs/web-legos"

import {
	IncludeExpiredQueryFilter,
	IncludeUnusedQueryFilter,
	laborRateTypeDescriptions,
	LaborRateTypeQueryFilter,
} from "~/components"

import { NewLaborPricingModal } from "./components"

export type LaborPricingUrlState = LaborPricingListQueryParams

export const LaborPricing: FC = () => {
	const history = useHistory()
	const [showNewModal, setShowNewModal] = useState(false)
	const [queryParams, { setUrlState }] = useUrlState<LaborPricingUrlState>(defaultQueryParams)

	const query = useLaborPricings({ params: queryParams })

	return (
		<Card
			heading="Labor Pricing Groups"
			headingIcon="tools"
			renderRight={() => (
				<Button
					icon="plus-circle"
					onClick={() => setShowNewModal(true)}
					variant="secondary-cta"
				>
					Create pricing group
				</Button>
			)}
		>
			<Table
				query={query}
				columns={columns}
				queryParamState={queryParams}
				setQueryParamState={setUrlState}
				pinnedQueryFilters={[
					SearchQueryFilter,
					CustomerQueryFilter,
					TerritoryQueryFilter,
					LaborRateTypeQueryFilter,
				]}
				toggledQueryFilters={[IncludeExpiredQueryFilter, IncludeUnusedQueryFilter]}
				onRowClick={({ original }) =>
					history.push({
						pathname: `/finance/labor-pricing/${original.id}`,
						state: { laborPricingQueryParams: queryParams },
					})
				}
				filterResetValues={defaultQueryParams}
				noDataText="No data found matching current filters"
				storeColumnVisibility
			/>

			{showNewModal && <NewLaborPricingModal onClose={() => setShowNewModal(false)} />}
		</Card>
	)
}

const columns: Column<LaborPricingListItem>[] = [
	{
		Header: "Group Name",
		id: "description",
		accessor: ({ territory: t, description, zone }) =>
			t ?
				`(${t.code}) ${t.description} - Zone ${zone}${zone === 1 ? " (Default)" : ""}`
			:	description,
	},
	{
		Header: "Territory",
		hiddenByDefault: true,
		accessor: ({ territory }) =>
			territory ? `(${territory.code}) ${territory.description}` : "-",
	},
	{
		Header: "Zone",
		hiddenByDefault: true,
		accessor: ({ zone, territory }) => {
			if (zone) {
				if (zone === 1 && territory) {
					return `${zone} (Default)`
				}

				return zone
			}

			return "-"
		},
	},
	{
		Header: "Type",
		id: "type",
		accessor: ({ type }) => {
			if (isEnumMember(type, LaborRateTypeId)) {
				return laborRateTypeDescriptions[type]
			}
			return "-"
		},
	},
	{
		Header: "Current customer count",
		disableSortBy: true,
		Cell: ({ row: { original } }: Cell<LaborPricingListItem>) => {
			if (original.zone === 1 && original.territory) {
				return (
					<Tooltip title="No customer count for default territory zones">
						<span>(Default)</span>
					</Tooltip>
				)
			}

			return displayNumber(original.customerCount)
		},
	},
	{
		Header: "Current rate $",
		id: "currentRate",
		accessor: ({ currentRate }) => (currentRate != null ? formatCurrency(currentRate) : "-"),
	},
	{
		Header: "Ends after",
		id: "endsAfter",
		accessor: ({ expiresAfter }) =>
			displayDate(expiresAfter, undefined, { formatInUtc: true }),
	},
]

const defaultQueryParams: LaborPricingListQueryParams = {
	search: null,
	includeExpired: false,
	includeUnused: false,
	laborRateType: null,
	customer: null,
	territory: null,
	ordering: "description",
}

import { ReactElement, useMemo } from "react"

import dayjs, { Dayjs } from "dayjs"

import { DateFormat } from "@ncs/ts-utils"

import { DateInput } from "../inputs"

interface StartDateParamState {
	startDate: string | null
}

export interface StartDateQueryFilterProps<QueryParamState extends StartDateParamState> {
	queryParamState: QueryParamState
	setQueryParamState: React.Dispatch<React.SetStateAction<QueryParamState>>
	dateFormat?: DateFormat
	label?: string
}

export const StartDateQueryFilter = <QueryParamState extends StartDateParamState>({
	queryParamState,
	setQueryParamState,
	dateFormat = DateFormat.DateQueryParam,
	label = "Start date",
}: StartDateQueryFilterProps<QueryParamState>): ReactElement => {
	// Take the raw string value that's from query param state and turn it
	// into a DayJs for the date picker.
	const value = useMemo(
		() => (queryParamState.startDate ? dayjs(queryParamState.startDate) : null),
		[queryParamState.startDate]
	)

	const handleChange = (newDate: Dayjs | null) => {
		setQueryParamState((prev) => ({
			...prev,
			// Convert the DayJs object into a plain string for use in URL to backend.
			startDate: newDate ? dayjs(newDate).startOf("day").format(dateFormat) : null,
		}))
	}

	return (
		<DateInput
			value={value}
			label={label}
			onChange={handleChange}
			datePickerProps={{ disableFuture: true }}
			fillContainer
		/>
	)
}

export type MaterialDashboardProColors =
	| "primary"
	| "info"
	| "success"
	| "warning"
	| "danger"
	| "rose"
	| "gray"

export const isMaterialDashboardProColor = (
	color: string
): color is MaterialDashboardProColors => {
	const colors: MaterialDashboardProColors[] = [
		"primary",
		"info",
		"success",
		"warning",
		"danger",
		"rose",
		"gray",
	]

	return colors.includes(color as MaterialDashboardProColors)
}

export type MaterialUISizeKeys = "xs" | "sm" | "md" | "lg" | "xl"

export type MaterialUISizeValues =
	| false
	| true
	| "auto"
	| 1
	| 2
	| 3
	| 4
	| 5
	| 6
	| 7
	| 8
	| 9
	| 10
	| 11
	| 12

export const materialUISizeValues: MaterialUISizeValues[] = [
	false,
	"auto",
	true,
	1,
	2,
	3,
	4,
	5,
	6,
	7,
	8,
	9,
	10,
	11,
	12,
]

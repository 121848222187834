import { RSAA as originalRSAA, isRSAA, apiMiddleware } from "redux-api-middleware"
import qs from "query-string"
import { parseLinkHeader } from "@ncs/ts-utils"
import { getMiddlewareBaseUrl } from "../config"
import {
	isAccessTokenExpired,
	accessToken,
	refreshToken,
	isAuthenticated,
} from "../redux/selectors/index"
import { TOKEN, refreshAccessToken } from "../redux/services/auth"
import { checkClientVersion } from "../redux/services/clientVersion"

// make it easy to access this throughout the application in order to create APIs and hide the deprecation warnings
const RSAA = originalRSAA
export { RSAA }

const rsaaWrapper = (url, method, bodyObject, query) => {
	return {
		endpoint: url,
		method: method,
		body:
			typeof bodyObject === "undefined" ? undefined
			: typeof bodyObject !== "string" ? JSON.stringify(bodyObject)
			: bodyObject,
		query,
	}
}

const multipartRsaaWrapper = (url, method, bodyObject) => {
	return {
		endpoint: url,
		method: method,
		body: bodyObject,
	}
}

// DRY helpers for common HTTP verbs
export const get = (url, query) => {
	return rsaaWrapper(url, "GET", undefined, query)
}

export const multipartPost = (url, data) => {
	return multipartRsaaWrapper(url, "POST", data)
}

export const multipartPatch = (url, data) => {
	return multipartRsaaWrapper(url, "PATCH", data)
}

export const post = (url, data, query) => {
	return rsaaWrapper(url, "POST", data, query)
}

export const put = (url, data) => {
	return rsaaWrapper(url, "PUT", data)
}

export const patch = (url, data) => {
	return rsaaWrapper(url, "PATCH", data)
}

export const del = (url, data) => {
	return rsaaWrapper(url, "DELETE", data)
}

export const rsaaTypes = (typePrefix) => {
	return {
		request: typePrefix + "_request",
		success: typePrefix + "_success",
		failure: typePrefix + "_failure",
	}
}

const generateRequestType = (type, { includeTimestamp, meta = {} }) => {
	if (!includeTimestamp) return type

	return {
		type,
		meta: {
			...meta,
			timestamp: new Date(),
		},
	}
}

const generateSuccessType = (
	type,
	{ includeTimestamp, parseLinkHeader: shouldParseLinkHeader, ...restOfMeta },
	dispatch
) => {
	return {
		type,
		meta: (action, state, res) => {
			let meta = restOfMeta

			if (includeTimestamp) {
				meta = {
					...meta,
					timestamp: action[RSAA].types[0].meta.timestamp,
				}
			}

			if (shouldParseLinkHeader) {
				const parsed = parseLinkHeader(res.headers.get("Link"))

				if (!parsed || !parsed.last) {
					meta = {
						...meta,
						pages: null,
					}
				} else {
					const m = /page=(\d+)(&|$)/.exec(parsed.last.url)
					meta = {
						...meta,
						pages: m.length > 1 ? parseInt(m[1], 10) : null,
					}
				}
			}

			let version = res.headers.get("X-Client-Version")
			if (version) {
				dispatch(checkClientVersion(version))
			}

			return Object.keys(meta).length > 0 ? meta : undefined
		},
	}
}

const generateFailureType = (type) => ({ type })

// when it comes time to issue the api call, add auth headers, content-type, querystring,
// and consume the rsaaWrapper used in the shortcut methods
export const prepareApiCall = (action, state, dispatch) => {
	let api = action[RSAA]

	if (!api) return

	// copy properties from the shortcut helpers onto the RSAA
	if (api.hasOwnProperty("api")) {
		Object.assign(api, api.api)
		delete api.api
	}

	if (typeof api.headers !== "object") {
		api.headers = {}
	}

	// Prepend API base URL to endpoint if it does not already contain a valid base URL.
	if (!/^((http|https|ftp):\/\/)/i.test(api.endpoint)) {
		api.endpoint = getMiddlewareBaseUrl() + api.endpoint

		// if we're on our own domain, let's throw on an auth header
		if (!("Authorization" in api.headers) && isAuthenticated(state)) {
			api.headers["Authorization"] = `Bearer ${accessToken(state)}`
		}
	}

	// Set Content-Type to application/json if Content-Type does not already have a value.
	if (!("Content-Type" in api.headers) && !api.hasOwnProperty("multipart")) {
		api.headers["Content-Type"] = "application/json"
	}

	if (api.hasOwnProperty("multipart")) {
		delete api.multipart
	}

	// turn the query object into a querystring, used on GET requests that add additional parameters to the URL
	if (api.hasOwnProperty("query")) {
		let querystring = qs.stringify(api.query)
		if (querystring) api.endpoint = `${api.endpoint}?${querystring}`
		delete api.query
	}

	// if the types property is an object, turn its properties into an array of RSAA types.
	// this allows DRYING up setting types to API_CALL instead of [API_CALL.request, API_CALL.success, API_CALL.failure]
	if (
		api.hasOwnProperty("types") &&
		typeof api.types === "object" &&
		!Array.isArray(api.types)
	) {
		api.types = [
			generateRequestType(api.types.request, api),
			generateSuccessType(api.types.success, api, dispatch),
			generateFailureType(api.types.failure),
		]

		let propsToDelete = ["parseLinkHeader", "includeTimestamp", "allowAnonymous", "meta"]
		for (let propToDelete of propsToDelete) {
			if (api.hasOwnProperty(propToDelete)) {
				delete api[propToDelete]
			}
		}
	}

	return action
}

const isAuthRequest = (action) => {
	if (!isRSAA(action)) return false

	let api = action[RSAA]
	let endpoint = api.endpoint || api.api.endpoint
	return endpoint.indexOf("/auth/") >= 0
}

const allowsAnonymous = (action) => {
	if (!isRSAA(action)) return false

	let api = action[RSAA]
	return api.allowAnonymous || api.api.allowAnonymous
}

const portalApiMiddleware = () => {
	let postponedApiCalls = []

	return ({ dispatch, getState }) => {
		const rsaaMiddleware = apiMiddleware({ dispatch, getState })

		return (next) => (action) => {
			const interceptTokenReceivedAndFlushPostponedApiCalls = (nextAction) => {
				next(nextAction)

				// Run postponed api calls after token refresh
				if (nextAction.type === TOKEN.success) {
					postponedApiCalls.forEach((postponed) => {
						prepareApiCall(postponed, getState(), dispatch)
						rsaaMiddleware(next)(postponed)
					})
					postponedApiCalls = []
				}
			}

			if (isRSAA(action)) {
				const state = getState()
				if (
					!isAccessTokenExpired(state) ||
					isAuthRequest(action) ||
					allowsAnonymous(action)
				) {
					prepareApiCall(action, state, dispatch)
					return rsaaMiddleware(next)(action)
				} else {
					const token = refreshToken(state)

					if (token) {
						postponedApiCalls.push(action)

						// only refresh token on the first postponed api call
						if (postponedApiCalls.length !== 1) {
							return
						}

						const refreshAction = refreshAccessToken(token)
						prepareApiCall(refreshAction, state, dispatch)
						return rsaaMiddleware(interceptTokenReceivedAndFlushPostponedApiCalls)(
							refreshAction
						)
					}
				}
			}
			return next(action)
		}
	}
}

export default portalApiMiddleware()

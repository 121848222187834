import { FC, useCallback, useEffect, useMemo, useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"

import {
	InventoryLocation,
	InventoryPart,
	LocationPartSummary,
	makeApiErrorMessage,
	useCreateLocationPart,
	useUpdateLocationPart,
} from "@ncs/ncs-api"
import {
	CheckboxFormField,
	ConfirmationModal,
	ConfirmationModalConfig,
	ExtendableModalProps,
	GridContainer,
	GridItem,
	GridItemProps,
	HeadingDivider,
	HeadingDividerProps,
	HookFormErrorText,
	LabeledData,
	Modal,
	NumericInputFormField,
	PartSelector,
	TextInputFormField,
	useChangeCallback,
	useToast,
	VendorSelectorFormField,
} from "@ncs/web-legos"

import {
	defaultLocationPartFormValues,
	getPartSummaryFormValues,
	LocationPartForm,
	LocationPartFormSchema,
	makeLocationPartDeletePatch,
	makeLocationPartPatch,
	makeLocationPartPost,
} from "../../../locations-util"

export interface LocationPartModalProps extends ExtendableModalProps {
	partSummary: LocationPartSummary | null
	location: InventoryLocation
}

export const LocationPartModal: FC<LocationPartModalProps> = ({
	partSummary,
	location,
	...rest
}) => {
	const { makeSuccessToast, makeErrorToast } = useToast()
	const [selectedPart, setSelectedPart] = useState<InventoryPart | null>(null)
	const [errorText, setErrorText] = useState<string | null>(null)
	const [isSaving, setIsSaving] = useState(false)
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)

	const createPart = useCreateLocationPart()
	const updatePart = useUpdateLocationPart()

	const {
		control,
		reset: resetForm,
		handleSubmit: submitForm,
		setValue,
		trigger,
		watch,
		formState,
	} = useForm<LocationPartForm>({
		resolver: zodResolver(LocationPartFormSchema),
		defaultValues: {
			...defaultLocationPartFormValues,
			...getPartSummaryFormValues(partSummary),
		},
	})

	const { isValid, submitCount } = formState

	const handleSubmit = async (formData: LocationPartForm) => {
		try {
			setIsSaving(true)
			if (isNew) {
				const postData = makeLocationPartPost(formData, location.id)
				await createPart(postData)
				makeSuccessToast("Part added to location")
			} else {
				const patchData = makeLocationPartPatch(formData, location.id)
				await updatePart({ updates: patchData })
				makeSuccessToast("Part updated")
			}
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const handleRemove = useCallback(async () => {
		try {
			if (!partSummary) throw new Error("No part selected")

			await updatePart({
				updates: makeLocationPartDeletePatch(partSummary, location.id),
			})
			makeSuccessToast("Part relationship removed from location")
			rest.onClose()
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		}
	}, [makeSuccessToast, makeErrorToast, updatePart, location.id, partSummary, rest])

	const reset = () => {
		setSelectedPart(null)
		setErrorText(null)
		setIsSaving(false)
		resetForm({
			...defaultLocationPartFormValues,
			...getPartSummaryFormValues(partSummary),
		})
	}

	// Keep the form up to date part selection changes.
	useChangeCallback(selectedPart, (part) => {
		setValue("partId", part?.id ?? "", {
			shouldValidate: true,
		})
	})

	const headingDividerProps: HeadingDividerProps = {
		headingVariant: "h4",
		bold: true,
		mb: 1,
	}

	const gridItemProps: GridItemProps = {
		xs: 12,
		sm: 6,
	}

	const showErrors = useMemo(() => submitCount > 0, [submitCount])

	const [isStock, isLocal] = watch(["isStock", "isLocal"])

	// Whenever isStock or isLocal changes, validate entire form because these two reference each other in validation.
	useEffect(() => {
		if (showErrors) {
			void trigger()
		}
	}, [trigger, isStock, isLocal, showErrors])

	const isNew = !partSummary || (!partSummary.isStock && !partSummary.isLocallySourced)

	return (
		<>
			<Modal
				title={`${isNew ? "Add" : "Edit"} Part at ${location.description}`}
				onOpen={reset}
				errorText={showErrors ? errorText : undefined}
				{...rest}
				leftButtons={
					isNew ? undefined : (
						{
							buttonText: "Remove",
							icon: "trash",
							variant: "text",
							onClick: () =>
								setConfirmationConfig({
									title: "Remove From Location?",
									message: "Remove this part's relationship with this location?",
									onConfirm: handleRemove,
								}),
						}
					)
				}
				rightButtons={{
					buttonText: isNew ? "Add Part To Location" : "Update",
					onClick: submitForm(handleSubmit),
					disabled: showErrors && !isValid,
					isLoading: isSaving,
				}}
			>
				<HeadingDivider {...headingDividerProps}>Part</HeadingDivider>
				{!partSummary ?
					<PartSelector
						value={selectedPart}
						onChange={setSelectedPart}
						mt={1}
						label={null}
						skipRestrictedCheck
					/>
				:	<LabeledData label="Part" mb={1}>
						({partSummary.partNumber}) {partSummary.partName}
					</LabeledData>
				}
				<HookFormErrorText control={control} name="partId" mt={-1} mb={0.5} />

				<VendorSelectorFormField
					control={control}
					name="vendorId"
					initialId={isNew ? "1" : partSummary?.vendor?.id} // Default to Grimes for new
					mb={3}
				/>

				<HeadingDivider {...headingDividerProps}>Stock</HeadingDivider>
				<CheckboxFormField
					control={control}
					name="isStock"
					label="Part is stock at this location"
				/>
				<GridContainer rowGap={0}>
					<GridItem {...gridItemProps}>
						<NumericInputFormField
							control={control}
							name="minLocationLevel"
							label="Min location level"
							disabled={!isStock}
						/>
					</GridItem>
					<GridItem {...gridItemProps}>
						<NumericInputFormField
							control={control}
							name="maxLocationLevel"
							label="Max location level"
							disabled={!isStock}
						/>
					</GridItem>
					<GridItem {...gridItemProps}>
						<NumericInputFormField
							control={control}
							name="econOrderAmount"
							label="Econ order amount"
							disabled={!isStock}
						/>
					</GridItem>
					<GridItem {...gridItemProps}>
						<NumericInputFormField
							control={control}
							name="minOrderQuantity"
							label="Min order quantity"
							disabled={!isStock}
						/>
					</GridItem>
					<GridItem {...gridItemProps}>
						<NumericInputFormField
							control={control}
							name="leadTime"
							label="Lead time (days)"
							disabled={!isStock}
						/>
					</GridItem>
				</GridContainer>

				<HeadingDivider {...headingDividerProps}>Local Source</HeadingDivider>
				<CheckboxFormField
					control={control}
					name="isLocal"
					label="Part is locally sourced from supplier at this location"
				/>
				<GridContainer rowGap={0}>
					<GridItem {...gridItemProps}>
						<TextInputFormField
							control={control}
							name="vendorPartNumber"
							label="Supplier part #"
							disabled={!isLocal}
							maxLength={255}
						/>
					</GridItem>
					<GridItem {...gridItemProps}>
						<TextInputFormField
							control={control}
							name="vendorPartName"
							label="Supplier part name"
							disabled={!isLocal}
							maxLength={255}
						/>
					</GridItem>
					<GridItem {...gridItemProps}>
						<NumericInputFormField
							control={control}
							name="vendorPartCost"
							label="Supplier part cost $"
							disabled={!isLocal}
							fixedDecimalScale
							decimalScale={2}
						/>
					</GridItem>
				</GridContainer>
			</Modal>

			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</>
	)
}

// ##############################
// // // Heading component styles
// #############################

import {grayColor} from "../../material-dashboard-pro-react"

const headingStyle = {
	heading: {
		marginBottom: "30px",
	},
	rightTextAlign: {
		textAlign: "right",
	},
	leftTextAlign: {
		textAlign: "left",
	},
	centerTextAlign: {
		textAlign: "center",
	},
	title: {
		marginTop: "10px",
		color: grayColor[2],
		textDecoration: "none",
	},
	category: {
		margin: "0 0 10px",
	},
}

export default headingStyle

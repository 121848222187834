import { FC, useState } from "react"

import { CustomerDetail } from "@ncs/ncs-api"
import { Box, ConfirmationModal, ConfirmationModalConfig, HeadingDivider } from "@ncs/web-legos"

import { sectionContentsContainer, sectionHeadingProps } from "../../customer-detail-util"
import {
	Accessorials,
	CustomerBusinessCredits,
	RestrictedPartPermissions,
	SalesAssignments,
	ServiceDetails,
	ServiceNotifications,
} from "./components"

export interface CustomerSalesAndServiceTabProps {
	customer: CustomerDetail
}

export const CustomerSalesAndServiceTab: FC<CustomerSalesAndServiceTabProps> = ({ customer }) => {
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)

	return (
		<>
			<HeadingDivider {...sectionHeadingProps} mt={0}>
				Service Details
			</HeadingDivider>
			<Box {...sectionContentsContainer}>
				<ServiceDetails customer={customer} />
			</Box>

			<HeadingDivider {...sectionHeadingProps}>Service Notifications</HeadingDivider>
			<Box {...sectionContentsContainer}>
				<ServiceNotifications customer={customer} />
			</Box>

			<HeadingDivider {...sectionHeadingProps}>Sales Assignments</HeadingDivider>
			<Box {...sectionContentsContainer}>
				<SalesAssignments customer={customer} />
			</Box>

			<HeadingDivider {...sectionHeadingProps}>Purchasing Restricted Parts</HeadingDivider>
			<Box {...sectionContentsContainer}>
				<RestrictedPartPermissions customer={customer} />
			</Box>

			<HeadingDivider {...sectionHeadingProps}>
				Default Accessorial Shipping Services
			</HeadingDivider>
			<Box {...sectionContentsContainer}>
				<Accessorials customer={customer} />
			</Box>

			<HeadingDivider {...sectionHeadingProps}>Business Credits</HeadingDivider>
			<Box {...sectionContentsContainer}>
				<CustomerBusinessCredits customer={customer} />
			</Box>

			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</>
	)
}

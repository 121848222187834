import { FC } from "react"

import { Helmet } from "react-helmet-async"

export interface PageTitleProps {
	title?: string | null
}

export const PageTitle: FC<PageTitleProps> = ({ title }) => {
	return (
		<Helmet titleTemplate="%s | NCS Internal" defaultTitle="NCS Internal">
			<title>{title ?? undefined}</title>
		</Helmet>
	)
}

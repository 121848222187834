import { FC } from "react"

import { ConnectedMachineWashCounts } from "@ncs/ncs-api"
import { formatDateTime, formatNumber } from "@ncs/ts-utils"
import { DescriptionList, Divider, Modal, Paragraph } from "@ncs/web-legos"

export interface MachineWashCountDetailModalProps {
	isOpen: boolean
	onClose: () => void
	washCounts: ConnectedMachineWashCounts | null
}

export const MachineWashCountDetailModal: FC<MachineWashCountDetailModalProps> = ({
	isOpen,
	onClose,
	washCounts,
}) => {
	return (
		<Modal isOpen={isOpen} onClose={onClose} title="Wash Count Details">
			{washCounts && (
				<>
					<DescriptionList
						labelWidth={6}
						variant="code"
						items={[
							["Date", formatDateTime(washCounts.eventDate)],
							["Total", formatNumber(washCounts.total)],
							[
								"Double Pass",
								washCounts.doublePass != null ?
									formatNumber(washCounts.doublePass)
								:	null,
							],
							[
								"Single Pass",
								washCounts.singlePass != null ?
									formatNumber(washCounts.singlePass)
								:	null,
							],
						]}
					/>
					<Divider />
					<Paragraph>All packages:</Paragraph>
					<DescriptionList
						labelWidth={6}
						variant="code"
						items={Object.entries(washCounts.counts).map(([name, count]) => [
							name,
							formatNumber(count || 0),
						])}
					/>
				</>
			)}
		</Modal>
	)
}

import { get, rsaaTypes, RSAA } from "@ncs/bricks/middleware/portalApiMiddleware"

const FETCH_RETAIL_SALES = rsaaTypes("retail_sales/list")
const GET_RETAIL_SALE = rsaaTypes("retail_sales/detail")
const GET_DOCUMENTS = rsaaTypes("retail_sales/documents/get")

export const fetchRetailSales = (querystringParams) => ({
	[RSAA]: {
		api: get(`/sales_orders/`, querystringParams),
		types: FETCH_RETAIL_SALES,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const getRetailSale = (orderNumber) => ({
	[RSAA]: {
		api: get(`/sales_orders/${orderNumber}/`),
		types: GET_RETAIL_SALE,
	},
})

export const getDocuments = (orderNumber) => ({
	[RSAA]: {
		api: get(`/sales_orders/${orderNumber}/documents`),
		types: GET_DOCUMENTS,
	},
})

const initialState = {
	isLoadingOrders: false,
	error: false,
	locations: [],
	retailSales: [],
}

const retailOrder = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_RETAIL_SALES.request:
		case FETCH_RETAIL_SALES.success:
		case FETCH_RETAIL_SALES.failure:
			return {
				...state,
				isLoadingOrders: action.type === FETCH_RETAIL_SALES.request,
				retailSales:
					action.type === FETCH_RETAIL_SALES.success ?
						action.payload
					:	state.deliverOrders,
				error: action.error,
			}

		default:
			return state
	}
}
export default retailOrder

import { FC } from "react"

import { Column } from "react-table"

import {
	SiteSurveyGroupedReportLine,
	SiteSurveyGroupedReportQueryParams,
	useSiteSurveyGroupedReport,
} from "@ncs/ncs-api"
import { Typify } from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	CustomerQueryFilter,
	EnterpriseQueryFilter,
	OrganizationQueryFilter,
	ReactTableSortType,
	Table,
	TerritoryQueryFilter,
	useUrlState,
} from "@ncs/web-legos"

export type SiteSurveyGroupedReportUrlState = Typify<SiteSurveyGroupedReportQueryParams>

export const SiteSurveyGroupedReport: FC = () => {
	const [queryParams, { setUrlState: setQueryParams }] =
		useUrlState<SiteSurveyGroupedReportUrlState>(initialSiteSurveyGroupedReportUrlState)

	const [data, dataLoading] = useSiteSurveyGroupedReport({ params: { ...queryParams } })

	return (
		<AnimatedEntrance show>
			<Table
				data={data ?? []}
				columns={columns}
				isLoading={dataLoading}
				queryParamState={queryParams}
				setQueryParamState={setQueryParams}
				pinnedQueryFilters={[
					CustomerQueryFilter,
					TerritoryQueryFilter,
					OrganizationQueryFilter,
					EnterpriseQueryFilter,
				]}
				storeColumnVisibility="site-survey-grouped-report-columns"
			/>
		</AnimatedEntrance>
	)
}

const columns: Column<SiteSurveyGroupedReportLine>[] = [
	{
		Header: "Location",
		accessor: ({ location }) => location || "-",
	},
	{
		Header: "Store #",
		accessor: ({ store }) => store || "-",
	},
	{
		Header: "City",
		accessor: ({ city }) => city || "-",
	},
	{
		Header: "State, zip",
		accessor: ({ stateZip }) => stateZip || "-",
	},
	{
		Header: "Machine age",
		accessor: ({ machineAge }) => machineAge || "-",
		hiddenByDefault: true,
	},
	{
		Header: "Wash type",
		accessor: ({ washType }) => washType || "-",
		hiddenByDefault: true,
	},
	{
		Header: "Chemical lineup",
		accessor: ({ chemicalLineup }) => chemicalLineup || "-",
		hiddenByDefault: true,
	},
	{
		Header: "Wash packages",
		sortType: ReactTableSortType.Number,
		accessor: ({ washPackages }) => washPackages || "-",
		hiddenByDefault: true,
	},
	{
		Header: "Wash count",
		sortType: ReactTableSortType.Number,
		accessor: ({ washCount }) => washCount || "-",
		hiddenByDefault: true,
	},
	{
		Header: "Bay condition",
		accessor: ({ bayCondition }) => bayCondition || "-",
		hiddenByDefault: true,
	},
	{
		Header: "Machine replacement",
		accessor: ({ machineReplacement }) => (machineReplacement ? "Yes" : "No"),
		hiddenByDefault: true,
	},
	{
		Header: "Replace RO system",
		accessor: ({ roSystem }) => (roSystem ? "Yes" : "No"),
		hiddenByDefault: true,
	},
	{
		Header: "Replace pay teller",
		accessor: ({ payTeller }) => (payTeller ? "Yes" : "No"),
		hiddenByDefault: true,
	},
	{
		Header: "LED lighting recommended",
		accessor: ({ ledLighting }) => (ledLighting ? "Yes" : "No"),
		hiddenByDefault: true,
	},
	{
		Header: "Marketing overhaul recommended",
		accessor: ({ marketingOverhaul }) => (marketingOverhaul ? "Yes" : "No"),
		hiddenByDefault: true,
	},
]

const initialSiteSurveyGroupedReportUrlState: SiteSurveyGroupedReportUrlState = {
	ordering: null,
	organization: null,
	enterprise: null,
	territory: null,
	customer: null,
}

import { FC, useState } from "react"

import { Cell, Column } from "react-table"

import { EquipmentModel, EquipmentModelsQueryParams, useEquipmentModels } from "@ncs/ncs-api"
import {
	Box,
	Button,
	EmptyValueDash,
	EquipmentManufacturerQueryFilter as DefaultEquipmentManufacturerQueryFilter,
	EquipmentManufacturerQueryFilterProps,
	Icon,
	IsActiveQueryFilter,
	SearchQueryFilter,
	Table,
	useUrlState,
} from "@ncs/web-legos"

import { EditModelModal } from "./components"

export type ModelsTabUrlState = EquipmentModelsQueryParams

export const ModelsTab: FC = () => {
	const [modal, setModal] = useState<EquipmentModel | true | null>(null)

	const [params, { setUrlState }] = useUrlState(defaultUrlState)

	const modelsQuery = useEquipmentModels({ params })

	return (
		<>
			<Box textAlign="right">
				<Button icon="plus" onClick={() => setModal(true)}>
					Create model
				</Button>
			</Box>

			<Table
				query={modelsQuery}
				columns={columns}
				pinnedQueryFilters={[
					SearchQueryFilter,
					EquipmentManufacturerQueryFilter,
					IsActiveQueryFilter,
				]}
				queryParamState={params}
				setQueryParamState={setUrlState}
				rowMenu={[
					{
						label: "Edit",
						iconName: "pencil",
						onClick: ({ original }) => setModal(original),
					},
				]}
			/>

			{!!modal && (
				<EditModelModal
					model={modal === true ? null : modal}
					onClose={() => setModal(null)}
				/>
			)}
		</>
	)
}

const columns: Column<EquipmentModel>[] = [
	{
		Header: "Name",
		id: "modelName",
		accessor: ({ modelName }) => modelName || <EmptyValueDash />,
	},
	{
		Header: "Equipment type",
		disableSortBy: true,
		accessor: ({ eqpFamily }) => eqpFamily?.description || <EmptyValueDash />,
	},
	{
		Header: "Manufacturer",
		disableSortBy: true,
		accessor: ({ manufacturer }) => manufacturer?.name || <EmptyValueDash />,
	},
	{
		Header: "Is primary?",
		accessor: "isPrimary",
		Cell: ({ row: { original } }: Cell<EquipmentModel>) => {
			return original.isPrimary ?
					<>
						<Icon icon="check" color="gray" /> Primary
					</>
				:	<EmptyValueDash />
		},
	},
	{
		Header: "Is active?",
		accessor: "isActive",
		Cell: ({ row: { original } }: Cell<EquipmentModel>) => {
			return original.isActive ?
					<>
						<Icon icon="check" color="gray" /> Active
					</>
				:	<EmptyValueDash />
		},
	},
]

const defaultUrlState: ModelsTabUrlState = {
	ordering: null,
	search: null,
	modelName: null,
	manufacturerId: null,
	isActive: null,
}

const EquipmentManufacturerQueryFilter = (
	props: EquipmentManufacturerQueryFilterProps<ModelsTabUrlState>
) => {
	return <DefaultEquipmentManufacturerQueryFilter {...props} paramKey="manufacturerId" />
}

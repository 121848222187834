import { FC, useEffect, useMemo, useRef } from "react"

import {
	StockStatus,
	useCheckPartOrderWarehouseStock,
	useWarehouses,
	Warehouse,
} from "@ncs/ncs-api"

import { StatusBlip } from "../connectivity"
import { ExtendableSearchableSelectProps, SearchableSelect } from "../inputs"
import { Box } from "../layout"
import { fakeGrimes } from "./LocationSelector"

export interface WarehouseSelectorProps extends ExtendableSearchableSelectProps<Warehouse> {
	value: Warehouse | null
	onChange: (newValue: Warehouse | null) => void
	initialId?: string | null
	stockCheckPartOrderId?: string
	addFakeGrimes?: boolean
}

export const WarehouseSelector: FC<WarehouseSelectorProps> = ({
	value,
	onChange,
	initialId,
	stockCheckPartOrderId,
	addFakeGrimes,
	...rest
}) => {
	const [warehouses, isLoading] = useWarehouses()
	const [stockLevels] = useCheckPartOrderWarehouseStock(stockCheckPartOrderId ?? null)
	const hasSetInitialId = useRef(false)

	useEffect(() => {
		if (!!initialId && !value && !!warehouses && !hasSetInitialId.current) {
			const initialWarehouse = warehouses.find((v) => v.id === initialId)

			if (initialWarehouse) {
				onChange(initialWarehouse)
				hasSetInitialId.current = true
			}
		}
	}, [initialId, value, warehouses, onChange])

	const options = useMemo(() => {
		const result = warehouses ?? []

		if (addFakeGrimes) {
			result.push(fakeGrimes)
		}

		return result
	}, [warehouses, addFakeGrimes])

	const makeLabel = (option: Warehouse) => {
		return option.id === fakeGrimes.id ?
				"Grimes"
			:	`(${option.locationCode}) ${option.description}`
	}

	return (
		<SearchableSelect
			label="Warehouse"
			placeholder="Search for a warehouse..."
			isLoading={isLoading}
			{...rest}
			value={value}
			onItemSelect={onChange}
			options={options}
			getOptionLabel={makeLabel}
			renderOption={(option) => {
				if (!stockLevels) {
					return makeLabel(option)
				}

				const warehouseStockStatus = stockLevels.find(
					(s) => s.locationCode === option.locationCode
				)

				return (
					<>
						<Box mr={0.5}>
							<StatusBlip
								status={warehouseStockStatus?.stockStatus ?? StockStatus.Unknown}
							/>
						</Box>
						{makeLabel(option)}
					</>
				)
			}}
		/>
	)
}

import { FC } from "react"

import { Card, Icon, Tabs, useUrlState } from "@ncs/web-legos"

import { CreateDispatchTab, ViewDispatchesTab } from "./components"

export enum DispatchesTab {
	ViewDispatches = "View Dispatches",
	CreateDispatch = "Create Dispatch",
}

export type DispatchesUrlState = {
	tab: DispatchesTab | null
}

export const Dispatches: FC = () => {
	const [{ tab }, { updateUrlValue }] = useUrlState<DispatchesUrlState>(
		{ tab: DispatchesTab.ViewDispatches },
		{ fallbackToInitialState: true }
	)

	return (
		<Card heading="Dispatches" headingIcon="user-hard-hat">
			<Tabs
				currentTab={tab}
				onChange={(newTab) => updateUrlValue("tab", newTab)}
				panels={[
					{
						navLabel: DispatchesTab.ViewDispatches,
						navIcon: <Icon icon="list" />,
						component: <ViewDispatchesTab />,
					},
					{
						navLabel: DispatchesTab.CreateDispatch,
						navIcon: <Icon icon="plus" />,
						component: <CreateDispatchTab />,
					},
				]}
			/>
		</Card>
	)
}

import { FC } from "react"

import { Cell, Column } from "react-table"

import { EquipmentVintage, EquipmentVintagePart, useEquipmentVintageParts } from "@ncs/ncs-api"
import { formatNumber } from "@ncs/ts-utils"
import { encodeUrlState, ExtendableModalProps, Heading, Link, Modal, Table } from "@ncs/web-legos"

import { PartManagementUrlState } from "~/views/Inventory/PartManagement/PartManagement"

export interface VintagePartsUsedModalProps extends ExtendableModalProps {
	vintage: EquipmentVintage
}

export const VintagePartsUsedModal: FC<VintagePartsUsedModalProps> = ({ vintage, ...rest }) => {
	const query = useEquipmentVintageParts(vintage.id, {
		pageSize: 25,
	})

	return (
		<Modal {...rest} title="Parts Used" maxWidth="md">
			<Heading mt={1} mb={2}>
				Vintage: <strong>{vintage.name}</strong>
			</Heading>

			<Table
				query={query}
				columns={columns}
				noDataText="No parts found for this vintage"
				disableAllSorting
			/>
		</Modal>
	)
}

const columns: Column<EquipmentVintagePart>[] = [
	{
		Header: "Part",
		Cell: ({ row: { original } }: Cell<EquipmentVintagePart>) => (
			<Link
				to={`/inventory/part-management${encodeUrlState<PartManagementUrlState>({
					partId: original.partId.toString(),
				})}`}
				newTab
			>
				({original.part_PartNumber}) {original.part_Description}
			</Link>
		),
	},
	{
		Header: "Total quantity",
		accessor: ({ totalQuantity }) => formatNumber(totalQuantity),
	},
]

import { post, rsaaTypes, RSAA } from "@ncs/bricks/middleware/portalApiMiddleware"

export const ECHO = rsaaTypes("debug/echo")

export const echo = (bodyObject) => ({
	[RSAA]: {
		api: post("/api/echo/", bodyObject),
		types: ECHO,
	},
})

const initialState = {
	isLoading: false,
	echo: undefined,
	error: undefined,
}

const debug = (state = initialState, action) => {
	switch (action.type) {
		case ECHO.request:
			return {
				...state,
				isLoading: true,
			}
		case ECHO.success:
			return {
				...state,
				isLoading: false,
				echo: action.payload,
				error: action.error,
			}
		case ECHO.failure:
			return {
				...state,
				isLoading: false,
				echo: undefined,
				error: action.error,
			}
		default:
			return state
	}
}
export default debug

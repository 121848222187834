import InputAdornment from "@material-ui/core/InputAdornment"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

import { ErrorText } from "@ncs/web-legos"

import {
	Button,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	GridContainer,
	GridItem,
	Input,
} from "~/components"

import Email from "@material-ui/icons/Email"
import LockOutline from "@material-ui/icons/Lock"

function LoginPage({ classes, isLoading, errorMessage, ...props }) {
	return (
		<div className={classes.content}>
			<div className={classes.container}>
				<GridContainer justify="center">
					<GridItem xs={12} sm={6} md={4}>
						<form onSubmit={props.onSubmit}>
							<Card login>
								<CardHeader
									className={`${classes.cardHeader} ${classes.textCenter}`}
									color="rose"
								>
									<h4 className={classes.cardTitle}>Log in</h4>
									<div className={classes.socialLine}>
										{[
											// "fab fa-facebook-square",
											// "fab fa-twitter",
											// "fab fa-google-plus"
										].map((prop, key) => {
											return (
												<Button
													color="transparent"
													justIcon
													key={key}
													className={classes.customButtonClass}
													disabled={isLoading}
												>
													<i className={prop} />
												</Button>
											)
										})}
									</div>
								</CardHeader>
								<CardBody>
									<Input
										labelText="Email..."
										id="email"
										value={props.email}
										formControlProps={{
											fullWidth: true,
										}}
										inputProps={{
											onChange: props.onInputChange,
											endAdornment: (
												<InputAdornment position="end">
													<Email
														className={classes.inputAdornmentIcon}
													/>
												</InputAdornment>
											),
										}}
									/>
									<Input
										labelText="Password"
										id="password"
										value={props.password}
										formControlProps={{
											fullWidth: true,
										}}
										inputProps={{
											type: "password",
											onChange: props.onInputChange,
											endAdornment: (
												<InputAdornment position="end">
													<LockOutline
														className={classes.inputAdornmentIcon}
													/>
												</InputAdornment>
											),
										}}
									/>

									{!!errorMessage && <ErrorText>{errorMessage}</ErrorText>}
								</CardBody>
								<CardFooter className={classes.justifyContentCenter}>
									<Button
										color="primary"
										size="lg"
										block
										type="submit"
										onClick={props.onSubmit}
										disabled={isLoading}
									>
										Let's Go
									</Button>
									<Link to="/login/forgot-password">Forgot password?</Link>
								</CardFooter>
							</Card>
						</form>
					</GridItem>
				</GridContainer>
			</div>
		</div>
	)
}

LoginPage.propTypes = {
	classes: PropTypes.object.isRequired,
	onInputChange: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
}

export default LoginPage

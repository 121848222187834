import { get, post, del, patch, rsaaTypes, RSAA } from "@ncs/bricks/middleware/portalApiMiddleware"

export const GET_TRANSFER_REQUESTS = rsaaTypes("transferRequests/list")
export const GET_TRANSFER_REQUEST_DETAIL = rsaaTypes("transferRequests/detail")
export const SUBMIT_TRANSFER_REQUEST = rsaaTypes("transferRequests/submit")
export const ACCEPT_TRANSFER_REQUEST = rsaaTypes("transferRequests/accept")
export const SHIP_TRANSFER_REQUEST = rsaaTypes("transferRequests/ship")
export const RECEIVE_TRANSFER_REQUEST = rsaaTypes("transferRequests/receive")
export const CANCEL_TRANSFER_REQUEST = rsaaTypes("transferRequests/cancel")
export const ADD_TRANSFER_REQUEST_LINE = rsaaTypes("transferRequests/line/add")
export const EDIT_TRANSFER_REQUEST_LINE = rsaaTypes("transferRequests/line/edit")
export const REMOVE_TRANSFER_REQUEST_LINE = rsaaTypes("transferRequests/line/remove")

export const fetchTransferRequests = (querystring_params) => {
	return {
		[RSAA]: {
			api: get("/inventory/transfer_request/", querystring_params),
			types: GET_TRANSFER_REQUESTS,
		},
	}
}

export const fetchTransferRequestDetail = (requestId) => {
	return {
		[RSAA]: {
			api: get(`/inventory/transfer_request/${requestId}/`),
			types: GET_TRANSFER_REQUEST_DETAIL,
		},
	}
}

export const submitTransferRequest = (requestId) => {
	return {
		[RSAA]: {
			api: post(`/inventory/transfer_request/${requestId}/submit/`),
			types: SUBMIT_TRANSFER_REQUEST,
		},
	}
}

export const acceptTransferRequest = (requestId) => {
	return {
		[RSAA]: {
			api: post(`/inventory/transfer_request/${requestId}/accept/`),
			types: ACCEPT_TRANSFER_REQUEST,
		},
	}
}

export const shipTransferRequest = (requestId) => {
	return {
		[RSAA]: {
			api: post(`/inventory/transfer_request/${requestId}/ship/`),
			types: SHIP_TRANSFER_REQUEST,
		},
	}
}

export const receiveTransferRequest = (requestId) => {
	return {
		[RSAA]: {
			api: post(`/inventory/transfer_request/${requestId}/receive/`),
			types: RECEIVE_TRANSFER_REQUEST,
		},
	}
}

export const cancelTransferRequest = (requestId) => {
	return {
		[RSAA]: {
			api: post(`/inventory/transfer_request/${requestId}/cancel/`),
			types: CANCEL_TRANSFER_REQUEST,
		},
	}
}

export const addTransferLine = (requestId, line) => {
	return {
		[RSAA]: {
			api: post(`/inventory/transfer_request/${requestId}/lines/`, line),
			types: ADD_TRANSFER_REQUEST_LINE,
		},
	}
}

export const editTransferLine = (requestId, line) => {
	return {
		[RSAA]: {
			api: patch(`/inventory/transfer_request/${requestId}/lines/${line.id}/`, line),
			types: EDIT_TRANSFER_REQUEST_LINE,
		},
	}
}

export const removeTransferLine = (requestId, lineId) => {
	return {
		[RSAA]: {
			api: del(`/inventory/transfer_request/${requestId}/lines/${lineId}/`),
			types: REMOVE_TRANSFER_REQUEST_LINE,
		},
	}
}

const initialState = {
	isLoading: false,
}

const transferRequest = (state = initialState, action) => {
	switch (action.type) {
		default:
			return state
	}
}

export default transferRequest

import {
	get,
	post,
	put,
	del,
	patch,
	rsaaTypes,
	RSAA,
} from "@ncs/bricks/middleware/portalApiMiddleware"

export const GET_DISPATCH = rsaaTypes("dispatch/get")
export const LIST_DISPATCHES = rsaaTypes("dispatches/list")
export const PATCH_DISPATCH = rsaaTypes("dispatch/patch")
export const CREATE_DISPATCH = rsaaTypes("dispatch/create")
export const LIST_DISPATCH_WORK_ORDERS = rsaaTypes("dispatches/work-orders/list")
export const LIST_DISPATCH_CALL_LOGS = rsaaTypes("dispatches/call-logs/list")
export const LIST_DISPATCH_ARCHIVED_INVOICES = rsaaTypes("dispatches/archived_invoices/list")
export const LIST_DISPATCH_LINE_ITEMS = rsaaTypes("dispatch/line-items/list")
export const POST_DISPATCH_LINE_ITEM = rsaaTypes("dispatch/line-item/post")
export const PUT_DISPATCH_LINE_ITEM = rsaaTypes("dispatch/line-item/put")
export const DELETE_DISPATCH_LINE_ITEM = rsaaTypes("dispatch/line-item/delete")

export const DEFAULT_DISPATCH_LIST_FIELDS =
	"_id,dispatch_number,customer,status,closed_date,bill_to_customer,assigned_employee,invoice,created_date," +
	"created_by,po_ticket,call_type,caller_name,call_received_date,erp,priority,call_back_number,description," +
	"bill_not_to_exceed,symptoms"

export const listDispatches = (querystring_params) => ({
	[RSAA]: {
		api: get(`/dispatches/`, {
			fields: DEFAULT_DISPATCH_LIST_FIELDS,
			...querystring_params,
		}),
		types: LIST_DISPATCHES,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const getDispatch = (dispatchId) => ({
	[RSAA]: {
		api: get(`/dispatches/${dispatchId}/`),
		types: GET_DISPATCH,
	},
})

export const patchDispatch = (dispatchId, data) => ({
	[RSAA]: {
		api: patch(`/dispatches/${dispatchId}/`, data),
		types: PATCH_DISPATCH,
	},
})

export const createDispatch = (data) => ({
	[RSAA]: {
		api: post(`/api/create_dispatch/`, data),
		types: CREATE_DISPATCH,
	},
})

export const listWorkOrders = (dispatchId) => ({
	[RSAA]: {
		api: get(`/dispatches/${dispatchId}/work_orders/`),
		types: LIST_DISPATCH_WORK_ORDERS,
	},
})

export const listCallLogs = (dispatchId) => ({
	[RSAA]: {
		api: get(`/dispatches/${dispatchId}/call_logs/`),
		types: LIST_DISPATCH_CALL_LOGS,
	},
})

export const listArchivedInvoices = (dispatchId) => ({
	[RSAA]: {
		api: put(`/dispatches/${dispatchId}/archived_invoices/`),
		types: LIST_DISPATCH_ARCHIVED_INVOICES,
	},
})

export const getDispatchLineItems = (dispatchId) => ({
	[RSAA]: {
		api: get(`/dispatches/${dispatchId}/line_items/`),
		types: LIST_DISPATCH_LINE_ITEMS,
	},
})

export const createDispatchLineItem = (dispatchId, data) => ({
	[RSAA]: {
		api: post(`/dispatches/${dispatchId}/line_items/`, data),
		types: POST_DISPATCH_LINE_ITEM,
	},
})

export const updateDispatchLineItem = (dispatchId, line_item_id, data) => ({
	[RSAA]: {
		api: put(`/dispatches/${dispatchId}/line_items/${line_item_id}/`, data),
		types: PUT_DISPATCH_LINE_ITEM,
	},
})

export const deleteDispatchLineItem = (dispatchId, lineItemId) => ({
	[RSAA]: {
		api: del(`/dispatches/${dispatchId}/line_items/${lineItemId}/`),
		types: DELETE_DISPATCH_LINE_ITEM,
	},
})

const initialState = {
	isLoading: true,
	isLoadingDispatches: true,
	isLoadingDispatch: true,
	isLoadingDispatchWorkOrders: true,
	isLoadingDispatchCallLogs: true,
	dispatches: [],
	workOrders: [],
	callLogs: [],
	dispatch: {},
	dispatchesRequestTimestamp: null,
	isCreatingDispatch: true,
	createdDispatch: {},
}

const dispatches = (state = initialState, action) => {
	switch (action.type) {
		case LIST_DISPATCHES.request:
			return {
				...state,
				isLoadingDispatches: true,
			}
		case LIST_DISPATCHES.success:
			// bail if we would be overwriting newer data
			if (state.dispatchesRequestTimestamp > action.meta.timestamp) return state

			return {
				...state,
				isLoadingDispatches: false,
				dispatches: action.payload,
				dispatchesPages: action.meta.pages,
				dispatchesRequestTimestamp: action.meta.timestamp,
			}

		case GET_DISPATCH.request:
			return {
				...state,
				isLoadingDispatch: true,
			}
		case GET_DISPATCH.success:
			return {
				...state,
				isLoadingDispatch: false,
				dispatch: action.payload,
			}
		case CREATE_DISPATCH.request:
			return {
				...state,
				isCreatingDispatch: true,
			}
		case CREATE_DISPATCH.success:
			return {
				...state,
				isCreatingDispatch: false,
				createDispatch: action.payload,
			}
		case LIST_DISPATCH_WORK_ORDERS.request:
			return {
				...state,
				isLoadingWorkOrders: true,
			}
		case LIST_DISPATCH_WORK_ORDERS.success:
			return {
				...state,
				isLoadingWorkOrders: false,
				workOrders: action.payload,
			}

		case LIST_DISPATCH_CALL_LOGS.request:
			return {
				...state,
				isLoadingDispatchCallLogs: true,
			}
		case LIST_DISPATCH_CALL_LOGS.success:
			return {
				...state,
				isLoadingDispatchCallLogs: false,
				callLogs: action.payload,
			}

		case LIST_DISPATCH_ARCHIVED_INVOICES.request:
			return {
				...state,
				isLoadingArchivedInvoices: true,
			}
		case LIST_DISPATCH_ARCHIVED_INVOICES.success:
			return {
				...state,
				isLoadingArchivedInvoices: false,
				archivedInvoices: action.payload,
			}

		case LIST_DISPATCH_LINE_ITEMS.request:
			return {
				...state,
				isLoadingDispatchWorkOrders: true,
			}
		case LIST_DISPATCH_LINE_ITEMS.success:
			return {
				...state,
				isLoadingDispatchWorkOrders: false,
				lineItems: action.payload,
			}

		case PATCH_DISPATCH.request:
		case POST_DISPATCH_LINE_ITEM.request:
		case PUT_DISPATCH_LINE_ITEM.request:
		case DELETE_DISPATCH_LINE_ITEM.request:
			return {
				...state,
				isSaving: true,
			}

		case PATCH_DISPATCH.success:
		case POST_DISPATCH_LINE_ITEM.success:
		case PUT_DISPATCH_LINE_ITEM.success:
		case DELETE_DISPATCH_LINE_ITEM.success:
			return {
				...state,
				isSaving: false,
			}

		case LIST_DISPATCHES.failure:
		case GET_DISPATCH.failure:
		case CREATE_DISPATCH.failure:
		case LIST_DISPATCH_WORK_ORDERS.failure:
		case LIST_DISPATCH_CALL_LOGS.failure:
		case LIST_DISPATCH_ARCHIVED_INVOICES.failure:
		case LIST_DISPATCH_LINE_ITEMS.failure:
		case PATCH_DISPATCH.failure:
		case POST_DISPATCH_LINE_ITEM.failure:
		case PUT_DISPATCH_LINE_ITEM.failure:
		case DELETE_DISPATCH_LINE_ITEM.failure:
			return {
				...state,
				error: action,
			}

		default:
			return state
	}
}
export default dispatches

import React, { HTMLAttributes, useMemo } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { makeStyles } from "@material-ui/core"

import styles from "../../assets/jss/material-dashboard-pro-react/components/cardBodyStyle"
import { MaterialDashboardProColors } from "../../util/types"

const useStyles = makeStyles(styles)

export interface CardBodyProps extends HTMLAttributes<HTMLDivElement> {
	className?: string
	background?: boolean
	plain?: boolean
	formHorizontal?: boolean
	pricing?: boolean
	signup?: boolean
	color?: MaterialDashboardProColors
	profile?: boolean
	calendar?: boolean
}

const CardBody: React.FC<CardBodyProps> = ({
	className,
	background,
	plain,
	formHorizontal,
	pricing,
	signup,
	color,
	profile,
	calendar,
	children,
	...rest
}) => {
	const classes = useStyles()

	const cardBodyClasses = useMemo(
		() =>
			classNames(className, {
				[classes.cardBody]: true,
				[classes.cardBodyBackground]: background,
				[classes.cardBodyPlain]: plain,
				[classes.cardBodyFormHorizontal]: formHorizontal,
				[classes.cardPricing]: pricing,
				[classes.cardSignup]: signup,
				[classes.cardBodyColor]: color,
				[classes.cardBodyProfile]: profile,
				[classes.cardBodyCalendar]: calendar,
			}),
		[
			background,
			calendar,
			classes,
			className,
			color,
			formHorizontal,
			plain,
			pricing,
			profile,
			signup,
		]
	)

	return (
		<div className={cardBodyClasses} {...rest}>
			{children}
		</div>
	)
}

CardBody.propTypes = {
	className: PropTypes.string,
	background: PropTypes.bool,
	plain: PropTypes.bool,
	formHorizontal: PropTypes.bool,
	pricing: PropTypes.bool,
	signup: PropTypes.bool,
	color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger", "rose", "gray"]),
	profile: PropTypes.bool,
	calendar: PropTypes.bool,
	children: PropTypes.node,
}

export default CardBody

import React, { Component } from "react"
import { compose, bindActionCreators } from "redux"
import { withDynamicTableFilter } from "~/components"
import { listTicketSubtypes, patchTicketSubtype } from "@ncs/mortar/redux/services/ticketing"
import { connect } from "react-redux"
import { fetchData } from "@ncs/bricks/redux/services/dynamicTables"

//local components
import TicketSubtypeManagement from "./TicketSubtypeManagement"
import { withCallApi } from "~/components"

const reduxKey = "ticketSubtypeManagementReduxKey"

class TicketSubtypeManagementContainer extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isSaving: false,
			isRefreshing: false,
		}
	}

	componentDidUpdate = (prevProps, prevState) => {
		if (prevProps.selectedTicketType !== this.props.selectedTicketType) {
			this.props.refreshSubtypes()
		}
	}

	listTicketSubtypes = (query) => {
		return listTicketSubtypes(query)
	}

	handleSubtypeSave = () => {
		this.refreshData()
	}

	refreshData = () => {
		this.setState({ isRefreshing: true })
		this.props.refreshSubtypes(reduxKey, this.setState({ isRefreshing: false }))
	}

	handleSaveEdit = (subtype, description) => {
		let data = {
			description: description,
		}
		return this.props
			.callApi(patchTicketSubtype(subtype, data))
			.then((result) => this.refreshData())
	}

	render() {
		return (
			<TicketSubtypeManagement
				reduxKey={reduxKey}
				fetchDataAction={this.listTicketSubtypes}
				selectedGroup={this.props.selectedGroup}
				selectedTicketType={this.props.selectedTicketType}
				onSubtypeSave={this.handleSubtypeSave}
				onSaveEdit={this.handleSaveEdit}
				isRefreshing={this.state.isRefreshing}
			/>
		)
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			refreshSubtypes: fetchData(reduxKey),
		},
		dispatch
	)

export default compose(
	connect(null, mapDispatchToProps),
	withDynamicTableFilter("group", undefined, reduxKey, "setGroup", "selectedGroup"),
	withDynamicTableFilter(
		"ticket_type",
		undefined,
		reduxKey,
		"setTicketType",
		"selectedTicketType"
	),
	withCallApi
)(TicketSubtypeManagementContainer)

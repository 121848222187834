import { Dispatch, ReactElement, SetStateAction, useState } from "react"

import { InventoryPart } from "@ncs/ncs-api"

import { useChangeCallback } from "../../util"
import { PartSelector, PartSelectorProps } from "../selectors"

interface InventoryPartQueryParam {
	part: string | null
}

export interface InventoryPartQueryFilterProps<QueryParamState extends InventoryPartQueryParam>
	extends Omit<PartSelectorProps, "initialPartId" | "value" | "onChange"> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

/**
 * Query filter for Inventory Part. Note that this has `allParts` and `skipRestrictedCheck` true by default.
 */
export const InventoryPartQueryFilter = <QueryParamState extends InventoryPartQueryParam>({
	queryParamState,
	setQueryParamState,
	...rest
}: InventoryPartQueryFilterProps<QueryParamState>): ReactElement => {
	const [part, setPart] = useState<InventoryPart | null>(null)

	const handleChange = (newPart: InventoryPart | null) => {
		setPart(newPart)
		setQueryParamState((prev) => ({
			...prev,
			part: newPart?.id ?? null,
		}))
	}

	useChangeCallback(queryParamState.part, (newPartId) => {
		if (newPartId == null && part != null) {
			setPart(null)
		}
	})

	return (
		<PartSelector
			fillContainer
			skipRestrictedCheck
			includeNonService
			{...rest}
			initialPartId={queryParamState.part}
			value={part}
			onChange={handleChange}
		/>
	)
}

import React, { Component } from "react"

import TicketComment from "./TicketComment"
import connect from "react-redux/es/connect/connect"
import FormValidator from "@ncs/bricks/util/formValidator"
import { meetsAppRestriction, userId } from "@ncs/bricks/redux/selectors/auth"
import { APPLICATIONS } from "@ncs/mortar/util/constants"
import { patchComment } from "@ncs/mortar/redux/services/ticketing"
import { withCallApi } from "~/components"

class TicketCommentContainer extends Component {
	constructor(props) {
		super(props)

		this.validations = {
			description: {
				stateName: "description",
				isRequired: true,
				maxLength: 5000,
				default: this.props.comment.description,
			},
			ticket_requestor_name: {
				stateName: "requestorText",
				isRequired: false,
				maxLength: 255,
				minLength: 1,
				default:
					props.comment.ticket_requestor_id ? props.comment.ticket_requestor.name : "",
			},
		}

		this.formValidator = new FormValidator(this, this.validations)

		this.state = {
			isEditing: false,
			isSaving: false,
			selectedRequestor:
				props.comment.tech_id ? 1
				: props.comment.ticket_requestor_id ? 2
				: null,
			selectedTech: props.comment.tech_id ? props.comment.tech : null,
			requestorText:
				props.comment.ticket_requestor_id ? props.comment.ticket_requestor.name : "",
			notificationSentText: "",
			notificationSent: false,
			selectedTicketRequestor: props.comment.ticket_requestor_id,
			startNotification: false,
			selectedNotificationType: -1,
			selectedNotificationTech: {},
			...this.formValidator.getInitialFormState(),
		}
	}

	handleSetInputRef = (input) => {
		this.input = input
	}

	handleStartEditing = () => {
		this.setState({ isEditing: true })
		setTimeout(() => {
			this.input && typeof this.input.focus === "function" && this.input.focus()
		}, 50)
		this.formValidator.dataIsValid()
	}

	handleCancelEditing = () => {
		this.setState({
			isEditing: false,
			showValidationErrors: false,
			selectedRequestor:
				this.props.comment.tech_id ? 1
				: this.props.comment.ticket_requestor_id ? 2
				: null,
			selectedTech: this.props.comment.tech_id ? this.props.comment.tech : null,
			requestorText:
				this.props.comment.ticket_requestor_id ?
					this.props.comment.ticket_requestor.name
				:	null,
		})
	}

	handleSave = () => {
		if (!this.formValidator.dataIsValid()) {
			this.setState({ showValidationErrors: true })
			return
		}

		let comment = {
			tech_id: this.state.selectedTech ? this.state.selectedTech._id : null,
			customer_id: this.props.ticketCustomer,
			ticket_requestor_id: this.state.selectedTicketRequestor,
		}

		for (const [prop, config] of Object.entries(this.validations)) {
			if (prop === "comment") {
				comment["description"] = this.state[config.stateName]
			} else {
				comment[prop] = this.state[config.stateName]
			}
		}
		this.props.callApi(patchComment(this.props.comment.id, comment)).then((result) => {
			if (result.error) {
				return this.setState({ error: result.error })
			} else {
				this.setState({ success: true, isEditing: false })
				this.props.refreshTicket()
			}
		})
	}

	handleRequestorTypeChange = (selectedRequestor) => {
		if (selectedRequestor === 1) {
			this.setState({ selectedRequestor, ticket_requestor_name: null, requestorText: "" })
		} else {
			this.setState({ selectedRequestor, selectedTech: null })
		}
	}
	handleRequestorTextChange = (requestorText) => {
		this.setState({ requestorText })
	}
	handleTechSelected = (selectedTech) => {
		this.setState({ selectedTech, ticket_requestor_name: null, requestorText: "" })
	}

	handleCancelSendTechNotification = () => {
		this.setState({
			startNotification: false,
			selectedNotificationTech: {},
			selectedNotificationType: -1,
			notificationEmail: this.props.documentationEmail,
			showValidationErrors: false,
		})
	}
	handleSelectedTicketRequestorChange = (selectedTicketRequestor) => {
		this.setState({ selectedTicketRequestor, requestorText: "" })
	}

	render() {
		return (
			<TicketComment
				ticketComment={this.props.comment}
				userId={this.props.userId()}
				isTicketingAdmin={this.props.isTicketingAdmin()}
				apps={this.props.apps}
				onStartEditing={this.handleStartEditing}
				onSave={this.handleSave}
				onCancelEditing={this.handleCancelEditing}
				setInputRef={this.handleSetInputRef}
				isEditing={this.state.isEditing}
				isSaving={this.state.isSaving}
				isAdmin={this.props.isTicketingAdmin()}
				onRequestorTypeChange={this.handleRequestorTypeChange}
				onRequestorTextChange={this.handleRequestorTextChange}
				onTechSelected={this.handleTechSelected}
				selectedRequestor={this.state.selectedRequestor}
				selectedTech={this.state.selectedTech}
				notificationSentText={this.state.notificationSentText}
				notificationSent={this.state.notificationSent}
				onSendTechNotification={this.handleSendTechNotification}
				onConfirmSendNotification={this.handleConfirmSendNotification}
				commentTech={this.state.commentTech}
				isService={this.props.isService}
				ticketCustomer={this.props.ticketCustomer}
				startNotification={this.state.startNotification}
				hasDocumentationEmail={this.props.hasDocumentationEmail}
				selectedNotificationType={this.state.selectedNotificationType}
				documentationEmail={this.props.documentationEmail}
				notificationEmail={this.state.notificationEmail}
				selectedTicketRequestor={this.state.selectedTicketRequestor}
				onSelectedTicketRequestorChange={this.handleSelectedTicketRequestorChange}
				{...this.formValidator.generateFormProps()}
			/>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		isTicketingAdmin: () => {
			return meetsAppRestriction(state.auth, APPLICATIONS.TicketingAdmin)
		},
		userId: () => {
			return userId(state.auth)
		},
	}
}

export default connect(mapStateToProps, null)(withCallApi(TicketCommentContainer))

import { FC, useState } from "react"

import { saveAs } from "file-saver"

import { InventoryPart, LabelSize, makeApiErrorMessage, useCreateLabel } from "@ncs/ncs-api"
import {
	ExtendableModalProps,
	Label,
	LabelSizeSelector,
	Modal,
	NumericInput,
	Paragraph,
	useToast,
} from "@ncs/web-legos"

export interface PrintPartLabelModalProps extends ExtendableModalProps {
	part: InventoryPart
}

export const PrintPartLabelModal: FC<PrintPartLabelModalProps> = ({ part, ...rest }) => {
	const { makeSuccessToast } = useToast()
	const [size, setSize] = useState<LabelSize>("3x2")
	const [quantity, setQuantity] = useState<number | null>(1)
	const [errorText, setErrorText] = useState<string | null>(null)
	const [isSaving, setIsSaving] = useState(false)

	const makeLabel = useCreateLabel()

	const printLabel = async () => {
		try {
			if (!quantity) {
				throw new Error("Must enter a quantity of 1 or more")
			}

			setIsSaving(true)
			const { data } = await makeLabel({
				labelSize: size,
				labels: [
					{
						label: part.partNumber,
						quantity,
					},
				],
			})
			saveAs(new Blob([data as Blob]), `label_${part.partNumber}.pdf`)
			makeSuccessToast("Label created")
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}
	return (
		<Modal
			{...rest}
			title="Create Part Label"
			errorText={errorText}
			rightButtons={{
				buttonText: "Create Label",
				onClick: printLabel,
				isLoading: isSaving,
			}}
		>
			<Paragraph mb={2}>
				Generate a QR code containing: <strong>{part.partNumber}</strong>
			</Paragraph>
			<NumericInput
				label="Number of copies"
				placeholder="# of copies..."
				value={quantity}
				onChange={(value) => setQuantity(value ?? null)}
				mb={2}
				min={1}
				max={250}
				maxWidth={7}
			/>

			<Label>Label size</Label>
			<LabelSizeSelector value={size} onChange={setSize} description="" radio />
		</Modal>
	)
}

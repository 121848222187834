import { FC, useState } from "react"

import dayjs from "dayjs"
import { Cell, Column } from "react-table"

import {
	ConnectedDevice,
	ConnectedDeviceWorkOrder,
	ConnectedDeviceWorkOrdersQueryParams,
	useConnectedDeviceWorkOrders,
} from "@ncs/ncs-api"
import { DateFormat, formatDateTime, formatNumber, getTimezoneAbbreviation } from "@ncs/ts-utils"
import {
	EmptyValueDash,
	KeyboardEndDateQueryFilter,
	KeyboardStartDateQueryFilter,
	Link,
	NoWrap,
	Table,
} from "@ncs/web-legos"

import { c12yAutoRefreshConfig } from "~/util"

export interface WashCounterWorkOrdersTabProps {
	device: ConnectedDevice | null | undefined
}

export const WashCounterWorkOrdersTab: FC<WashCounterWorkOrdersTabProps> = ({ device }) => {
	const [tableParams, setTableParams] =
		useState<ConnectedDeviceWorkOrdersQueryParams>(defaultParams)

	const params: ConnectedDeviceWorkOrdersQueryParams = {
		...tableParams,
		device: device?.id ?? null,
	}

	const [workOrders, isLoading] = useConnectedDeviceWorkOrders({
		params,
		queryConfig: {
			...c12yAutoRefreshConfig,
			enabled: !!device,
		},
	})

	return (
		<Table
			columns={columns}
			data={workOrders ?? []}
			toggledQueryFilters={[KeyboardEndDateQueryFilter, KeyboardStartDateQueryFilter]}
			queryParamState={tableParams}
			setQueryParamState={setTableParams}
			isLoading={isLoading}
		/>
	)
}

const columns: Column<ConnectedDeviceWorkOrder>[] = [
	{
		Header: `Opened (${getTimezoneAbbreviation()})`,
		id: "openDate",
		accessor: (original) => <NoWrap>{formatDateTime(original.openDate)}</NoWrap>,
		disableSortBy: true,
	},
	{
		Header: `Closed (${getTimezoneAbbreviation()})`,
		id: "closedDate",
		accessor: (original) =>
			original.closedDate ?
				<NoWrap>{formatDateTime(original.closedDate)}</NoWrap>
			:	<EmptyValueDash />,
		disableSortBy: true,
	},
	{
		Header: "Wash Count",
		id: "washCount",
		accessor: (original) =>
			original.washCount ?
				<NoWrap>{formatNumber(original.washCount)}</NoWrap>
			:	<EmptyValueDash />,
		disableSortBy: true,
	},
	{
		Header: "Technician",
		accessor: "technician",
		disableSortBy: true,
	},
	{
		Header: "Comments",
		accessor: (original) => original.comments ?? <EmptyValueDash />,
		disableSortBy: true,
	},
	{
		Header: "",
		id: "dispatch-link-button",
		Cell: ({ row: { original } }: Cell<ConnectedDeviceWorkOrder>) => {
			return (
				<NoWrap>
					<Link
						to={`/service/dispatches/${original.dispatchId}`}
						newTab
						icon="external-link"
					>
						View dispatch
					</Link>
				</NoWrap>
			)
		},
	},
]

export const defaultParams: ConnectedDeviceWorkOrdersQueryParams = {
	device: null,
	startDate: dayjs().subtract(60, "day").startOf("minute").format(DateFormat.DateQueryParam),
	endDate: null,
}

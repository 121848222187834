import React from "react"
import PropTypes from "prop-types"
import { loadLookups, LOOKUPS } from "../../redux/services/lookups"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import callApi from "@ncs/bricks/redux/services/callApi"

// custom components
import { Select } from "@ncs/bricks/components"

class CustomerSourceSelector extends React.PureComponent {
	componentDidMount() {
		this.props.loadLookups([LOOKUPS.CustomerSources])
	}

	render() {
		const { displayEmpty, ...rest } = this.props
		return (
			<Select
				id="territories"
				labelText="Territory"
				value={this.props.customerSource || null}
				onChange={this.props.onChange}
				options={[
					{
						id: null,
						name: "Select a Source",
						disabled: false,
					},
					...this.props.customerSources,
				]}
				labelProps={{
					shrink: displayEmpty ? true : undefined,
					...rest.labelProps,
				}}
				valueAccessor="id"
				textAccessor={(x) => x.name}
				selectProps={{
					displayEmpty: displayEmpty,
					...rest.selectProps,
				}}
				{...rest}
			/>
		)
	}
}

CustomerSourceSelector.defaultProps = {
	displayEmpty: true,
}

CustomerSourceSelector.propTypes = {
	customerSource: PropTypes.string,
	onChange: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
	return {
		customerSources: state.lookups.customerSources,
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			callApi,
			loadLookups,
		},
		dispatch
	)

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSourceSelector)

import React from "react"
import PropTypes from "prop-types"

// material-ui icons
import { withStyles } from "@material-ui/core"
import { Info } from "@material-ui/icons"

import { Card, CardBody, CardHeader, CardIcon, CardIconTitle, Table } from "../../components"

// styles
import extendedTablesStyle from "../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle"

const TableCard = (props) => {
	let { classes, cardColor, cardIcon, title, beforeTable, afterTable, ...rest } = props

	return (
		<Card>
			<CardHeader color={cardColor} icon>
				<CardIcon color={cardColor}>{cardIcon}</CardIcon>
				<CardIconTitle>{title}</CardIconTitle>
			</CardHeader>
			<CardBody>
				{beforeTable}
				<Table {...rest} />
				{afterTable}
			</CardBody>
		</Card>
	)
}

TableCard.defaultProps = {
	cardColor: "rose",
	hasData: true,
	cardIcon: <Info />,
}

TableCard.propTypes = {
	classes: PropTypes.object.isRequired,
	cardColor: PropTypes.PropTypes.oneOf([
		"warning",
		"primary",
		"danger",
		"success",
		"info",
		"rose",
		"gray",
	]),
	cardIcon: PropTypes.element,
	title: PropTypes.string.isRequired,
	beforeTable: PropTypes.arrayOf(PropTypes.node),
	afterTable: PropTypes.arrayOf(PropTypes.node),

	tableHeaderColor: PropTypes.oneOf([
		"warning",
		"primary",
		"danger",
		"success",
		"info",
		"rose",
		"gray",
	]),
	tableHead: PropTypes.arrayOf(PropTypes.string),
	// Of(PropTypes.arrayOf(PropTypes.node)) || Of(PropTypes.object),
	tableData: PropTypes.array,
	hover: PropTypes.bool,
	coloredColls: PropTypes.arrayOf(PropTypes.number),
	// Of(["warning","primary","danger","success","info","rose","gray"]) - colorsColls
	colorsColls: PropTypes.array,
	customCellClasses: PropTypes.arrayOf(PropTypes.string),
	customClassesForCells: PropTypes.arrayOf(PropTypes.number),
	customHeadCellClasses: PropTypes.arrayOf(PropTypes.string),
	customHeadClassesForCells: PropTypes.arrayOf(PropTypes.number),
	striped: PropTypes.bool,
	// this will cause some changes in font
	tableShopping: PropTypes.bool,
	hasData: PropTypes.bool,
	zeroDataContent: PropTypes.node,
}

export default withStyles(extendedTablesStyle)(TableCard)

import React from "react"

// custom components
import { BaseDateFilter, withDynamicTableFilter } from "@ncs/bricks/components"

class TicketClosedStartFilter extends React.PureComponent {
	render() {
		return <BaseDateFilter labelText="Closed Start Date" {...this.props} />
	}
}

export default withDynamicTableFilter("ticket_close_start")(TicketClosedStartFilter)

import { Dispatch, ReactElement, SetStateAction, useMemo } from "react"

import { Select } from "@ncs/web-legos"

interface DefaultsCustomersQueryParam {
	defaultsOnly: true | null
	customersOnly: true | null
}

export interface DefaultsCustomersQueryFilterProps<
	QueryParamState extends DefaultsCustomersQueryParam,
> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

/**
 * Can only send true to activate the filter, or nothing.
 */
export const DefaultsCustomersQueryFilter = <QueryParamState extends DefaultsCustomersQueryParam>({
	queryParamState,
	setQueryParamState,
}: DefaultsCustomersQueryFilterProps<QueryParamState>): ReactElement => {
	const value = useMemo(() => {
		if (queryParamState.defaultsOnly === true) {
			return "defaults"
		} else if (queryParamState.customersOnly === true) {
			return "customers"
		}

		return null
	}, [queryParamState.defaultsOnly, queryParamState.customersOnly])

	const handleChange = (newValue: "defaults" | "customers" | null) => {
		setQueryParamState((prev) => ({
			...prev,
			defaultsOnly: newValue === "defaults" ? true : null,
			customersOnly: newValue === "customers" ? true : null,
		}))
	}

	return (
		<Select
			value={value}
			onChange={(v, option) => handleChange(option?.value ?? null)}
			label="Type"
			options={[
				{
					text: "Freight class only",
					value: "defaults" as const,
				},
				{
					text: "Customers only",
					value: "customers" as const,
				},
			]}
			fillContainer
			disableNoSelectionOption={false}
		/>
	)
}

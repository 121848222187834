import { FC, useState } from "react"

import { makeApiErrorMessage, PosPart, ProductCatalog, useAddPartToCatalog } from "@ncs/ncs-api"
import { ExtendableModalProps, Modal, Paragraph, PosPartSelector } from "@ncs/web-legos"

export interface AddPartToCatalogModalProps extends ExtendableModalProps {
	catalog: ProductCatalog
}

export const AddPartToCatalogModal: FC<AddPartToCatalogModalProps> = ({ catalog, ...rest }) => {
	const [selection, setSelection] = useState<PosPart | null>(null)
	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const addPart = useAddPartToCatalog()

	const onSave = async () => {
		if (!selection) return

		try {
			setIsSaving(true)
			await addPart({
				onlinePartId: selection.id,
				catalogId: catalog.id,
			})
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const reset = () => {
		setSelection(null)
		setIsSaving(false)
		setErrorText(null)
	}

	return (
		<Modal
			title="Add Product To Catalog"
			onOpen={reset}
			{...rest}
			errorText={errorText}
			rightButtons={{
				buttonText: "Add Product",
				onClick: onSave,
				disabled: !selection,
				isLoading: isSaving,
			}}
		>
			<Paragraph mb={1}>
				Select a product to add to the {catalog.description} catalog.
			</Paragraph>
			<PosPartSelector
				value={selection}
				onChange={setSelection}
				hideParents
				hideRelationshipLabels
				autoFocus
			/>
		</Modal>
	)
}

import React from "react"

import {
	GridContainer,
	GridItem,
	QuickCard,
	DynamicTable,
	SearchFilter,
	TicketGroupFilter,
	TicketTypeFilter,
} from "~/components"

//local components
import TicketUserAssignmentAddContainer from "./TicketUserAssignmentAddContainer"
import TicketingUserEditor from "./TicketingUserEditor"

//material ui icons
import PersonIcon from "@material-ui/icons/Person"
import ListIcon from "@material-ui/icons/List"

const filterReduxKey = "filterReduxKey"

function TicketingUserManagement(props) {
	const {
		fetchDataAction,
		type,
		onAssignmentSaved,
		onDeleteUserAssignment,
		isSaving,
		reduxKey,
	} = props
	return (
		<GridContainer>
			<GridItem xs={12}>
				<QuickCard title={"Ticket User Management"} icon={<PersonIcon />}>
					<GridContainer>
						<GridItem xs={12} md={6}>
							<TicketGroupFilter reduxKey={filterReduxKey} />
						</GridItem>
						<GridItem xs={12} md={6}>
							<TicketTypeFilter reduxKey={filterReduxKey} />
						</GridItem>
						<GridItem xs={12} md={6} show={typeof type === "number"}>
							<SearchFilter reduxKeys={[reduxKey]} />
						</GridItem>
					</GridContainer>
				</QuickCard>
				<GridContainer>
					<GridItem xs={12} show={typeof type === "number"}>
						<QuickCard title="Users" shortenHeader={true} icon={<ListIcon />}>
							<GridContainer>
								<GridItem xs={12}>
									<TicketUserAssignmentAddContainer
										type={type}
										onAssignmentSaved={onAssignmentSaved}
									/>
								</GridItem>
								<GridItem xs={12}>
									<DynamicTable // TODO: make props static
										reduxKey={reduxKey}
										fetchDataAction={fetchDataAction}
										style={{ textAlign: "center " }}
										columns={[
											{
												Header: "User",
												accessor: (x) => x.user.name,
												id: "user.name",
												sortable: true,
												toggleable: false,
												headerStyle: { textAlign: "center " },
											},
											{
												Header: "Actions",
												accessor: (x) => (
													<TicketingUserEditor
														onDeleteUserAssignment={
															onDeleteUserAssignment
														}
														isSaving={isSaving}
														user={x.user.name}
														type={type}
														assignment={x.id}
													/>
												),
												id: "id",
												sortable: false,
												toggleable: false,
												maxWidth: 85,
											},
										]}
									/>
								</GridItem>
							</GridContainer>
						</QuickCard>
					</GridItem>
				</GridContainer>
			</GridItem>
		</GridContainer>
	)
}

export default TicketingUserManagement

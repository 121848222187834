import { FC } from "react"

import { Column } from "react-table"

import {
	ServiceTrendReportLine,
	ServiceTrendReportQueryParams,
	useExportServiceTrendReport,
	useServiceTrendReport,
} from "@ncs/ncs-api"
import {
	formatDateTime,
	formatNumber,
	formatPercentage,
	getStartAndEndDateQueryParams,
	getTimezoneAbbreviation,
	TimeAgo,
	Typify,
} from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	Table,
	TerritoryQueryFilter,
	TimeAgoQueryFilter,
	usePaginationUrlState,
	useUrlState,
} from "@ncs/web-legos"

export type ServiceTrendReportUrlState = Typify<ServiceTrendReportQueryParams>

export const ServiceTrendReport: FC = () => {
	const [queryParams, { setUrlState: setQueryParams }] = useUrlState<ServiceTrendReportUrlState>(
		initialServiceTrendReportUrlState
	)
	const [pagination, setPagination] = usePaginationUrlState()

	const query = useServiceTrendReport({
		params: { ...queryParams, ...pagination },
		manualPagination: true,
	})
	const dataExport = useExportServiceTrendReport(queryParams)

	return (
		<AnimatedEntrance show>
			<Table
				query={query}
				columns={columns}
				queryParamState={queryParams}
				setQueryParamState={setQueryParams}
				pagination={pagination}
				setPagination={setPagination}
				dataExport={dataExport}
				pinnedQueryFilters={[TerritoryQueryFilter, TimeAgoQueryFilter]}
				storeColumnVisibility="service-trend-report-columns"
			/>
		</AnimatedEntrance>
	)
}

const columns: Column<ServiceTrendReportLine>[] = [
	{
		Header: `Start date (${getTimezoneAbbreviation()})`,
		id: "weekStart",
		accessor: ({ weekStart }) => formatDateTime(weekStart),
	},
	{
		Header: `End date (${getTimezoneAbbreviation()})`,
		id: "weekEnd",
		accessor: ({ weekEnd }) => formatDateTime(weekEnd),
	},
	{
		Header: "Territory",
		id: "territory_Code",
		accessor: ({ territory }) => `(${territory.code}) ${territory.description}`,
	},
	{
		Header: "Dispatches",
		id: "totalDispatches",
		accessor: ({ totalDispatches }) => formatNumber(totalDispatches),
	},
	{
		Header: "L.Y. dispatches",
		id: "lastYearDispatches",
		accessor: ({ lastYearDispatches }) => formatNumber(lastYearDispatches),
	},
	{
		Header: "Dispatch diff (%)",
		disableSortBy: true,
		accessor: ({ lastYearDispatches, totalDispatches }) =>
			lastYearDispatches ?
				formatPercentage((totalDispatches - lastYearDispatches) / lastYearDispatches, 0)
			:	"-",
	},
	{
		Header: "Work orders",
		id: "totalWorkOrders",
		hiddenByDefault: true,
		accessor: ({ totalWorkOrders }) => formatNumber(totalWorkOrders),
	},
	{
		Header: "L.Y. work orders",
		id: "lastYearWorkOrders",
		hiddenByDefault: true,
		accessor: ({ lastYearWorkOrders }) => formatNumber(lastYearWorkOrders),
	},
	{
		Header: "Work order diff (%)",
		disableSortBy: true,
		hiddenByDefault: true,
		accessor: ({ lastYearWorkOrders, totalWorkOrders }) =>
			lastYearWorkOrders ?
				formatPercentage((totalWorkOrders - lastYearWorkOrders) / lastYearWorkOrders, 0)
			:	"-",
	},
]

const initialServiceTrendReportUrlState: ServiceTrendReportUrlState = {
	ordering: null,
	territory: null,
	startDate: getStartAndEndDateQueryParams(TimeAgo.MonthsAgo3)[0],
	endDate: null,
}

import { FC } from "react"

import { ChemicalCustomerDetail } from "@ncs/ncs-api"
import { displayNumber } from "@ncs/ts-utils"
import { Box, LabeledData } from "@ncs/web-legos"

export interface ContainerTabProps {
	container: ChemicalCustomerDetail["containers"][number]
}

export const ContainerTab: FC<ContainerTabProps> = ({ container }) => {
	return (
		<Box display="flex" rowGap={0} flexDirection="column">
			<LabeledData label="Container name">{container.containerName}</LabeledData>
			<LabeledData label="Fillable height (gallons)">
				{displayNumber(container.containerFillableHeight)}
			</LabeledData>
			<LabeledData label="Total gallons">
				{displayNumber(container.containerGallons)}
			</LabeledData>
			<LabeledData label="mL per mm">
				{displayNumber(container.containerMlPerMm)}
			</LabeledData>
		</Box>
	)
}

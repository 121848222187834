import React from "react"
import PropTypes from "prop-types"
import { createStyles, makeStyles } from "@material-ui/core"
import { ClipboardButton } from "../../components"
import { ClipboardButtonProps } from "./ClipboardButton"

const styles = createStyles({
	clipboardBtnWrapper: {
		margin: 0,
		position: "relative",
		display: "inline-block",
	},
	clipboardBtn: {
		margin: 0,
		padding: 0,
		"& svg": {
			marginRight: "0px",
		},
	},
})

const useStyles = makeStyles(styles)

interface ClipboardButtonColumnProps extends ClipboardButtonProps {
	/** the potentially long text string */
	value?: string
}

/** wraps long text in a span and slaps a copy to clipboard button before it. This is useful in ReactTable columns that
 * will otherwise truncate the long text. */
const ClipboardButtonColumn: React.FC<ClipboardButtonColumnProps> = ({ value, ...rest }) => {
	const classes = useStyles()

	if (!value) {
		return null
	}

	return (
		<div>
			<ClipboardButton
				title="Copy to Clipboard"
				round
				justIcon
				simple
				size="sm"
				className={classes.clipboardBtn}
				wrapperClassName={classes.clipboardBtnWrapper}
				content={undefined}
				{...rest}
			/>
			<span title={value}> {value} </span>
		</div>
	)
}

ClipboardButtonColumn.propTypes = {
	/** the potentially long text string */
	value: PropTypes.string,
}

export default ClipboardButtonColumn

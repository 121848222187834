import { FC, memo } from "react"

import { Icon, Tooltip } from "@ncs/web-legos"

interface ReceivePartQuantityWarningProps {
	showWarning: boolean
}

export const ReceivePartQuantityWarning: FC<ReceivePartQuantityWarningProps> = memo(
	({ showWarning }) => {
		if (!showWarning) return null

		return (
			<Tooltip title="The total quantity to receive for this line item is greater than the quantity ordered.">
				<Icon icon="exclamation-circle" color="warning" />
			</Tooltip>
		)
	}
)

ReceivePartQuantityWarning.displayName = "QuantityWarning"

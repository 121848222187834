import { HTMLAttributes } from "react"
/**
 * Pass in a prop for the tooltip that can be either a string or an object with
 * a 'title' property. We'll turn this into a proper object for the Tooltip component.
 *
 * This allows components that are implementing Tooltip inside them to take a simpler
 * 'tooltip' prop where user can pass just a string if that's all they need, or
 * an object that holds more props if required.
 */

export type AmbiguousTooltipProp =
	| string
	| {
			containerProps?: HTMLAttributes<HTMLDivElement>
			title: string | React.ReactNode
			icon?: React.ReactNode
			noIcon?: boolean
	  }

export const makeTooltipProps = (ambiguousTooltipProp: AmbiguousTooltipProp) => {
	if (typeof ambiguousTooltipProp === "string") {
		return {
			title: ambiguousTooltipProp,
		}
	} else if (ambiguousTooltipProp.title) {
		return ambiguousTooltipProp
	}

	throw new Error("tooltip prop must be a string or an object with a 'title' property")
}

import { FC } from "react"

import { BinType, useBinTypes } from "@ncs/ncs-api"
import { ExtendableSelectProps, Select } from "@ncs/web-legos"

export type BinTypeSelectorProps = ExtendableSelectProps<BinType>

export const BinTypeSelector: FC<BinTypeSelectorProps> = ({ ...rest }) => {
	const [types, isLoading] = useBinTypes()

	return (
		<Select
			label="Bin type"
			{...rest}
			isLoading={isLoading}
			options={types ?? []}
			valueAccessor="id"
			textAccessor="description"
		/>
	)
}

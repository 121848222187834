import { FC, useState } from "react"

import { useWatch, useForm } from "react-hook-form"
import { makeApiErrorMessage, PartOrder, useUpdatePartOrder } from "@ncs/ncs-api"
import {
	CustomerBillToSelectorFormField,
	CustomerSelectorFormField,
	ExtendableModalProps,
	Heading,
	Modal,
	useToast,
} from "@ncs/web-legos"

import { CreatePartOrderForm } from "../../../PartOrders"

export interface EditShipToModalProps extends ExtendableModalProps {
	order: PartOrder
}

export const EditBillToShipToModal: FC<EditShipToModalProps> = ({ order, ...rest }) => {
	const { makeSuccessToast } = useToast()
	const { control } = useForm<CreatePartOrderForm>()

	const [selectedShipToId, billToId] = useWatch({
		name: ["siteId", "billToId"],
		control,
	})

	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const updateOrder = useUpdatePartOrder(order.id)

	const onSave = async () => {
		try {
			if (!selectedShipToId) {
				throw new Error("No Ship To selected")
			}
			if (!billToId) {
				throw new Error("No Bill To selected")
			}

			setIsSaving(true)
			await updateOrder({
				updates: {
					shipToId: selectedShipToId,
					billToId: billToId,
				},
				id: order.id,
			})
			makeSuccessToast("Order updated")
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	return (
		<Modal
			{...rest}
			title="Edit Part Order Ship To Address"
			rightButtons={{
				buttonText: "Save Order",
				isLoading: isSaving,
				onClick: onSave,
			}}
			closeButtonText="Cancel"
			errorText={errorText}
		>
			<Heading mb={0.6} bold variant="h5" icon="truck">
				Ship To
			</Heading>
			<CustomerSelectorFormField
				control={control}
				name="siteId"
				label=""
				placeholder="Ship To customer..."
				emptyValueFallback=""
				excludeBillTo={false}
			/>

			<Heading mb={0.6} bold variant="h5" icon="dollar-sign">
				Bill To
			</Heading>
			<CustomerBillToSelectorFormField
				control={control}
				name="billToId"
				customerId={selectedShipToId || null}
				noSelectionOptionText="Choose Ship To, then Bill To customer..."
				emptyValueFallback=""
				label=""
				addBillToInternal
			/>
		</Modal>
	)
}

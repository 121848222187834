import { FC, useMemo } from "react"

import { Cell, Column } from "react-table"

import {
	InventoryLocation,
	LocationPartOrders,
	PartOrderAtLocation,
	PurchaseOrderAtLocation,
	useLocationPartOrders,
} from "@ncs/ncs-api"
import { formatNumber } from "@ncs/ts-utils"
import {
	Box,
	ExtendableModalProps,
	Heading,
	LabeledData,
	Link,
	Modal,
	Table,
} from "@ncs/web-legos"

export interface LocationPartOrdersModalProps extends ExtendableModalProps {
	partId: string
	location: InventoryLocation
}

export const LocationPartOrdersModal: FC<LocationPartOrdersModalProps> = ({
	partId,
	location,
	...rest
}) => {
	const [orderSummary, summaryLoading] = useLocationPartOrders({
		partId,
		locationId: location.id,
	})

	const incomingOrders = useMemo(() => {
		return [
			...(orderSummary?.incomingPartOrders ?? []),
			...(orderSummary?.incomingPurchaseOrders ?? []),
		]
	}, [orderSummary?.incomingPartOrders, orderSummary?.incomingPurchaseOrders])

	const outgoingOrders = useMemo((): OutgoingOrder[] => {
		if (!orderSummary) return []

		const orders: { [id: string]: OutgoingOrder } = {}

		orderSummary.demands.forEach((order) => {
			orders[order.id] = {
				id: order.id,
				orderId: order.orderId?.toString() || "None",
				demands: (orders[order.id]?.demands || 0) + order.quantity,
				picking: orders[order.id]?.picking ?? 0,
				shipping: orders[order.id]?.shipping ?? 0,
			}
		})
		orderSummary.picking.forEach((order) => {
			orders[order.id] = {
				id: order.id,
				orderId: order.orderId?.toString() || "None",
				picking: (orders[order.id]?.picking || 0) + order.quantity,
				demands: orders[order.id]?.demands ?? 0,
				shipping: orders[order.id]?.shipping ?? 0,
			}
		})
		orderSummary.shipping.forEach((order) => {
			orders[order.id] = {
				id: order.id,
				orderId: order.orderId?.toString() || "None",
				shipping: (orders[order.id]?.shipping || 0) + order.quantity,
				picking: orders[order.id]?.picking ?? 0,
				demands: orders[order.id]?.demands ?? 0,
			}
		})

		return Object.values(orders)
	}, [orderSummary])

	return (
		<Modal title={`Orders at ${location.description}`} {...rest}>
			<Box display="flex" columnGap={2} mb={2}>
				<LabeledData label="Part #">{orderSummary?.partNumber}</LabeledData>
				<LabeledData label="Name">{orderSummary?.partName}</LabeledData>
			</Box>

			<Heading variant="h4" icon="inbox-in" bold>
				Incoming Orders
			</Heading>
			<Table
				columns={incomingColumns}
				data={incomingOrders}
				disableAllSorting
				noDataText="No incoming orders for part"
				isLoading={summaryLoading}
			/>

			<Heading variant="h4" icon="inbox-out" bold mt={3}>
				Outgoing Orders
			</Heading>
			<Table
				columns={outgoingColumns}
				data={outgoingOrders}
				disableAllSorting
				noDataText="No outgoing orders for part"
				isLoading={summaryLoading}
			/>
		</Modal>
	)
}

type IncomingOrder = PartOrderAtLocation | PurchaseOrderAtLocation

const isIncomingPartOrder = (
	order: IncomingOrder
): order is LocationPartOrders["incomingPartOrders"][number] => {
	return !!(order as LocationPartOrders["incomingPartOrders"][number]).orderId
}

const incomingColumns: Column<IncomingOrder>[] = [
	{
		Header: "Order",
		id: "order",
		Cell: ({ row: { original } }: Cell<IncomingOrder>) => {
			return (
				<Link
					to={
						isIncomingPartOrder(original) ?
							`/part-orders/${original.id}`
						:	`/purchase-orders/${original.id}`
					}
					newTab
					icon="external-link"
				>
					{isIncomingPartOrder(original) ?
						`Part Order # ${original.orderId}`
					:	`Purchase Order # ${original.id}`}
				</Link>
			)
		},
	},
	{
		Header: "Quantity",
		accessor: ({ quantity }) => formatNumber(quantity, { zeroString: "-" }),
	},
]

type OutgoingOrder = {
	id: string
	orderId: string
	picking: number
	shipping: number
	demands: number
}

const outgoingColumns: Column<OutgoingOrder>[] = [
	{
		Header: "Order",
		id: "order",
		Cell: ({ row: { original } }: Cell<OutgoingOrder>) => {
			return original.id !== "None" ?
					<Link newTab to={`/part-orders/${original.id}`} icon="external-link">
						Part Order #{original.orderId}
					</Link>
				:	"-"
		},
	},
	{
		Header: "Picking",
		accessor: ({ picking }) => formatNumber(picking, { zeroString: "-" }),
	},
	{
		Header: "Shipping",
		accessor: ({ shipping }) => formatNumber(shipping, { zeroString: "-" }),
	},
	{
		Header: "Demands",
		accessor: ({ demands }) => formatNumber(demands, { zeroString: "-" }),
	},
]

/* ts-import-sorter: disable */

import storage from "redux-persist/es/storage"
import { applyMiddleware, createStore, compose } from "redux"
import { createFilter } from "redux-persist-transform-filter"
import { persistReducer, persistStore } from "redux-persist"
import { routerMiddleware } from "react-router-redux"
import rootReducer from "~/redux/index"
import createDebounce from "redux-debounced"
import portalApiMiddleware from "@ncs/bricks/middleware/portalApiMiddleware"
import asyncDispatchMiddleware from "@ncs/bricks/middleware/asyncDispatchMiddleware"
import thunk from "redux-thunk"

export default (history) => {
	const authFilter = createFilter("auth", [
		"access",
		"refresh",
		"apps",
		"userId",
		"name",
		"email",
	])
	const marketingMaterialsModuleFilter = createFilter("marketingMaterialsModule", [
		"cart",
		"showCategorySidebar",
		"selectedColumns",
	])
	const userAdminModuleFilter = createFilter("userAdminModule", ["selectedColumns"])
	const pointOfSaleFilter = createFilter("pointOfSale", ["showVariations"])

	const reducer = persistReducer(
		{
			key: "root",
			storage: storage,
			whitelist: [
				"auth",
				"clientVersion",
				"dynamicTables",
				"marketingMaterialsModule",
				"pointOfSale",
			],
			transforms: [
				authFilter,
				marketingMaterialsModuleFilter,
				userAdminModuleFilter,
				pointOfSaleFilter,
			],
		},
		rootReducer
	)

	const store = createStore(
		reducer,
		{},
		compose(
			applyMiddleware(
				createDebounce(),
				portalApiMiddleware,
				thunk,
				asyncDispatchMiddleware,
				routerMiddleware(history)
			),
			window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f
		)
	)

	return store
}

export const persistStoreWithCallback = (store, callback) => {
	persistStore(store, null, callback)
}

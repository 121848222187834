import React, { HTMLAttributes, useMemo } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { createStyles, makeStyles } from "@material-ui/core"

import { cardIconTitle } from "../../assets/jss/material-dashboard-pro-react"

const styles = createStyles({ cardIconTitle })
const useStyles = makeStyles(styles)

export interface CardIconTitleProps extends HTMLAttributes<HTMLDivElement> {
	className?: string
}

const CardIconTitle: React.FC<CardIconTitleProps> = ({ className, children, ...rest }) => {
	const classes = useStyles()

	const cardIconTitleClasses = useMemo(
		() => classNames(classes.cardIconTitle, className),
		[className, classes]
	)

	return (
		<h4 className={cardIconTitleClasses} {...rest}>
			{children}
		</h4>
	)
}

CardIconTitle.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
}

export default CardIconTitle

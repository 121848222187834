import { FC, useState } from "react"

import { Cell, Column } from "react-table"

import {
	EquipmentManufacturer,
	EquipmentManufacturersQueryParams,
	EquipmentModel,
	useEquipmentManufacturers,
} from "@ncs/ncs-api"
import {
	Box,
	Button,
	EmptyValueDash,
	Icon,
	IsActiveQueryFilter,
	SearchQueryFilter,
	Table,
	useUrlState,
} from "@ncs/web-legos"

import { EditManufacturerModal } from "./components"

export type ManufacturersTabUrlState = EquipmentManufacturersQueryParams

export const ManufacturersTab: FC = () => {
	const [params, { setUrlState }] = useUrlState(defaultUrlState)

	const [modal, setModal] = useState<EquipmentManufacturer | true | null>(null)

	const query = useEquipmentManufacturers({ params })

	return (
		<>
			<Box textAlign="right">
				<Button icon="plus" onClick={() => setModal(true)}>
					Create manufacturer
				</Button>
			</Box>

			<Table
				query={query}
				columns={columns}
				queryParamState={params}
				setQueryParamState={setUrlState}
				pinnedQueryFilters={[SearchQueryFilter, IsActiveQueryFilter]}
				rowMenu={[
					{
						label: "Edit",
						iconName: "pencil",
						onClick: ({ original }) => setModal(original),
					},
				]}
			/>

			{!!modal && (
				<EditManufacturerModal
					manufacturer={typeof modal === "boolean" ? null : modal}
					onClose={() => setModal(null)}
				/>
			)}
		</>
	)
}

const columns: Column<EquipmentManufacturer>[] = [
	{
		Header: "Name",
		accessor: "name",
	},
	{
		Header: "Is active?",
		accessor: "isActive",
		Cell: ({ row: { original } }: Cell<EquipmentModel>) => {
			return original.isActive ?
					<>
						<Icon icon="check" color="gray" /> Active
					</>
				:	<EmptyValueDash />
		},
	},
]

const defaultUrlState: ManufacturersTabUrlState = {
	search: null,
	ordering: null,
	isActive: null,
}

import { Dispatch, ReactElement, SetStateAction } from "react"

import { ConnectivityDeviceTypeSelector, ConnectivityDeviceTypeSelectorProps } from "../selectors"

interface ConnectivityDeviceTypeQueryParams {
	deviceType: string | null
}

export interface ConnectivityDeviceTypeQueryFilterProps<
	QueryParamState extends ConnectivityDeviceTypeQueryParams,
> extends Omit<ConnectivityDeviceTypeSelectorProps, "value" | "onChange"> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

export const ConnectivityDeviceTypeQueryFilter = <
	QueryParamState extends ConnectivityDeviceTypeQueryParams,
>({
	queryParamState,
	setQueryParamState,
	...rest
}: ConnectivityDeviceTypeQueryFilterProps<QueryParamState>): ReactElement => {
	const handleChange = (deviceType: string | null) => {
		setQueryParamState((prev) => {
			const newState: ConnectivityDeviceTypeQueryParams = { deviceType }

			return {
				...prev,
				...newState,
			}
		})
	}

	return (
		<ConnectivityDeviceTypeSelector
			showNoSelectionOption={false}
			fillContainer
			{...rest}
			value={queryParamState.deviceType}
			onChange={handleChange}
		/>
	)
}

import { ChangeEvent, FC, useState } from "react"

import { makeApiErrorMessage, useCustomerMetaBulkPost } from "@ncs/ncs-api"
import { Box, ExtendableModalProps, Modal, Paragraph, useToast } from "@ncs/web-legos"

/**
 * @returns A modal for bulk importing of customer metadata.
 */
export const BulkImportMetaModal: FC<ExtendableModalProps> = ({ onClose, ...rest }) => {
	const { makeSuccessToast } = useToast()
	const [uploadedFile, setUploadedFile] = useState<File | null>(null)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const postBulkRdc = useCustomerMetaBulkPost()

	const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files

		if (files && files.length > 0) {
			setUploadedFile(files[0])

			// Clear out the error message if there was one there before.
			setErrorText(null)
		}
	}

	const importColumns = ["Customer Number", "Class", "Subclass", "Type", "Org"]

	const submit = async () => {
		try {
			setIsSubmitting(true)
			setErrorText(null)
			if (!uploadedFile) throw new Error("No file selected")

			const formData = new FormData()
			formData.append("customer_data", uploadedFile)
			await postBulkRdc(formData)
			makeSuccessToast(
				"Import successful. Changes may take a few minutes to be reflected here."
			)
			onClose()
		} catch (e) {
			setErrorText(makeApiErrorMessage(e))
			setIsSubmitting(false)
		}
	}

	const reset = () => {
		setUploadedFile(null)
		setIsSubmitting(false)
		setErrorText(null)
	}

	return (
		<Modal
			{...rest}
			onOpen={reset}
			onClose={onClose}
			errorText={errorText}
			rightButtons={{
				buttonText: "Import",
				onClick: submit,
				disabled: !uploadedFile,
				isLoading: isSubmitting,
			}}
		>
			<Paragraph mb={1}>
				Upload an Excel spreadsheet (.xls, .xlsx) with rows of data in the following column
				order (header row is optional). Your columns should be:
			</Paragraph>

			<ol>
				{importColumns.map((column, index) => {
					return <li key={index}>{column}</li>
				})}
			</ol>

			<Box>
				<Paragraph>
					<strong>
						These changes will set the information on the customer to the exact data
						provided.
					</strong>
				</Paragraph>
				<br />
				<Paragraph>
					If nothing is provided in a column, it will unset that field for the customer.
				</Paragraph>
			</Box>

			<Box my={2}>
				<input
					type="file"
					onChange={handleUpload}
					multiple={false}
					accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" // cspell:disable-line
				/>
			</Box>
		</Modal>
	)
}

import { FC, ReactElement, useMemo } from "react"

import { css } from "@emotion/react"

import { InTransitPartOrderItem, PurchaseOrderLineItemMin } from "@ncs/ncs-api"
import { extractNumber, formatCurrency, formatDate, formatNumber } from "@ncs/ts-utils"
import { ExtendableModalProps, Heading, Link, Modal, Paragraph } from "@ncs/web-legos"

export interface PurchaseOrderLineTransitDetailsModalProps extends ExtendableModalProps {
	lineItem: PurchaseOrderLineItemMin | null
	inTransitItems: InTransitPartOrderItem[]
}

export const PurchaseOrderLineTransitDetailsModal: FC<
	PurchaseOrderLineTransitDetailsModalProps
> = ({ lineItem, inTransitItems, ...rest }) => {
	const matchingItems = useMemo(() => {
		return inTransitItems.filter((t) => t.part.id === lineItem?.part?.id)
	}, [inTransitItems, lineItem?.part?.id])

	if (!lineItem) return null

	const makeDetails = (item: InTransitPartOrderItem) => {
		const details: ReactElement[] = []

		if (item.deliveryDate) {
			details.push(
				<Heading bold>
					{item.quantityInTransit}x delivered on {formatDate(item.deliveryDate)}
				</Heading>
			)
			if (item.shipDate) {
				details.push(<Paragraph>Shipped on {formatDate(item.shipDate)}</Paragraph>)
			}
		} else if (item.shipDate) {
			details.push(
				<Heading bold>
					{item.quantityInTransit}x shipped on {formatDate(item.shipDate)}
				</Heading>
			)
			if (item.estimatedDeliveryDate) {
				details.push(
					<Paragraph color="success" bold>
						Estimated delivery: {formatDate(item.estimatedDeliveryDate)}
					</Paragraph>
				)
			}
		}
		if (item.shipMethod) {
			details.push(<Paragraph>{item.shipMethod.description}</Paragraph>)
		}
		if (item.waybillNumberShippedOn) {
			details.push(<Paragraph>Waybill # {item.waybillNumberShippedOn}</Paragraph>)
		}
		if (item.freightCharge) {
			details.push(
				<Paragraph>Freight charge: {formatCurrency(item.freightCharge)}</Paragraph>
			)
		}
		if (item.trackingLink) {
			details.push(
				<Link
					newTab
					external
					underline
					to={item.trackingLink}
					icon="external-link"
					iconPosition="trailing"
				>
					Track package
				</Link>
			)
		}

		return details
	}

	return (
		<Modal {...rest} title={lineItem.part?.description || "Transit Details"}>
			{matchingItems.length === 0 && (
				<Paragraph small color="secondary">
					Transit information not available.
				</Paragraph>
			)}

			<Paragraph small color="secondary">
				Order quantity: {formatNumber(extractNumber(lineItem.quantity))}
			</Paragraph>

			{matchingItems.map((item) => (
				<ul key={item.id} css={transitDetailsListStyle}>
					{makeDetails(item).map((d, i) => (
						<li key={i}>{d}</li>
					))}
				</ul>
			))}
		</Modal>
	)
}

const transitDetailsListStyle = css`
	padding: 0;
	border-top: 1px solid #eee;
	padding-top: 1rem;
	li {
		list-style-type: none;
		margin-bottom: 0.5rem;
	}
`

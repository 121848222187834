import { useMemo } from "react"

import { useQueryClient } from "react-query"

import {
	ApiDeleteMutation,
	ApiGetQuery,
	ApiGetQueryOptions,
	ApiInfiniteGetQuery,
	ApiInfiniteGetQueryOptions,
	ApiPatchMutation,
	ApiPostMutation,
	useDeleteRequest,
	useGetRequest,
	useInfiniteGetRequest,
	usePatchRequest,
	usePostRequest,
} from "../../request-hooks"
import { apiClient, buildUrl, lineTypesAccessorials, useEnumPropertyValidation } from "../../util"
import { APPLICATION } from "../auth"
import { LineItemType } from "../common"
import { DispatchUrlPaths } from "../dispatches"
import {
	AddErpLineItemPost,
	ApproveCreditMemoPost,
	ApproveInvoicePost,
	ApproveMilestonePost,
	ConvertErpPost,
	CreateCreditMemoPost,
	CreateErpPost,
	CreateInvoiceNotePost,
	CreateWorkorderInvoiceLineItemPost,
	CreditMemo,
	CustomerMilestone,
	CustomerMilestoneQueryParams,
	EditErpLineItemPatch,
	EmailErpDocPost,
	Erp,
	ErpQueryParams,
	FinalizeInvoicePost,
	InvoiceStatusId,
	RebillInvoicePost,
	RecreateErpDocPost,
	RejectCreditMemoPost,
	RejectInvoicePost,
	RejectMilestonePost,
	UnexpireErpPost,
	UpdateErpPatch,
	UpdateWorkorderInvoiceLineItemPatch,
	UpdateWorkOrderInvoicePatch,
	WorkOrderInvoice,
	WorkOrderInvoiceLineItem,
	WorkOrderLineItemType,
	WorkOrderLineItemTypesQueryParams,
	WorkordersUrlPath,
} from "./types"

export const useCustomerMilestones = (
	options?: ApiInfiniteGetQueryOptions<CustomerMilestone, CustomerMilestoneQueryParams>
): ApiInfiniteGetQuery<CustomerMilestone> => {
	return useInfiniteGetRequest(
		[WorkordersUrlPath.Workorders, WorkordersUrlPath.CustomerMilestone],
		options
	)
}

export const useRejectMilestone = (): ApiPostMutation<RejectMilestonePost> => {
	return usePostRequest([WorkordersUrlPath.Workorders, WorkordersUrlPath.CustomerMilestone], {
		addRequestDataIdToUrl: true,
		urlPathsAfterId: WorkordersUrlPath.RejectMilestone,
		keyToInvalidate: WorkordersUrlPath.Workorders,
	})
}

export const useApproveMilestone = (): ApiPostMutation<ApproveMilestonePost> => {
	return usePostRequest([WorkordersUrlPath.Workorders, WorkordersUrlPath.CustomerMilestone], {
		addRequestDataIdToUrl: true,
		urlPathsAfterId: WorkordersUrlPath.ApproveMilestone,
		keyToInvalidate: WorkordersUrlPath.Workorders,
	})
}

export const defaultErpQueryParams: ErpQueryParams = {
	organization: null,
	enterprise: null,
	territory: null,
	customer: null,
	startDate: null,
	endDate: null,
	erpStatus: null,
	milestone: null,
	search: null,
	ordering: null,
}

export const useErps = (
	options?: ApiInfiniteGetQueryOptions<Erp, ErpQueryParams>
): ApiInfiniteGetQuery<Erp> => {
	return useInfiniteGetRequest([WorkordersUrlPath.Workorders, WorkordersUrlPath.Erp], options)
}

export const useErp = (erpId: string | null | undefined): ApiGetQuery<Erp> => {
	return useGetRequest([WorkordersUrlPath.Workorders, WorkordersUrlPath.Erp, erpId ?? ""], {
		queryConfig: {
			enabled: !!erpId,
		},
	})
}

export const useUpdateErp = (): ApiPatchMutation<UpdateErpPatch> => {
	return usePatchRequest([WorkordersUrlPath.Workorders, WorkordersUrlPath.Erp], {
		keyToInvalidate: WorkordersUrlPath.Workorders,
	})
}

export const useEmailErpDoc = (): ApiPostMutation<EmailErpDocPost> => {
	return usePostRequest([WorkordersUrlPath.Workorders, WorkordersUrlPath.Erp2], {
		addRequestDataIdToUrl: true,
		urlPathsAfterId: WorkordersUrlPath.RegenerateDocument,
		keyToInvalidate: WorkordersUrlPath.Workorders,
	})
}

export const useRecreateErpDoc = (): ApiPostMutation<RecreateErpDocPost> => {
	return usePostRequest([WorkordersUrlPath.Workorders, WorkordersUrlPath.Erp], {
		addRequestDataIdToUrl: true,
		urlPathsAfterId: WorkordersUrlPath.RecreateDocument,
		keyToInvalidate: WorkordersUrlPath.Workorders,
	})
}

export const useAddErpLineItem = (): ApiPostMutation<AddErpLineItemPost> => {
	return usePostRequest([WorkordersUrlPath.Workorders, WorkordersUrlPath.Erp], {
		addRequestDataIdToUrl: true,
		urlPathsAfterId: WorkordersUrlPath.LineItems,
		keyToInvalidate: WorkordersUrlPath.Workorders,
	})
}

export const useEditErpLineItem = (erpId: string): ApiPatchMutation<EditErpLineItemPatch> => {
	{
		return usePatchRequest(
			[
				WorkordersUrlPath.Workorders,
				WorkordersUrlPath.Erp,
				erpId,
				WorkordersUrlPath.LineItems,
			],
			{
				keyToInvalidate: WorkordersUrlPath.Workorders,
			}
		)
	}
}

/**
 * This one's a little too weird, just gonna do it manually.
 */
interface ErpLineItemDeleteParams {
	erpId: string
	erpLineItemId: string
}
export const useRemoveErpLineItem = (): (({
	erpId,
	erpLineItemId,
}: ErpLineItemDeleteParams) => Promise<void>) => {
	const queryClient = useQueryClient()

	return async ({ erpId, erpLineItemId }: ErpLineItemDeleteParams): Promise<void> => {
		await apiClient.delete(
			buildUrl(
				[
					WorkordersUrlPath.Workorders,
					WorkordersUrlPath.Erp,
					erpId,
					WorkordersUrlPath.LineItems,
					erpLineItemId,
				].join("/") + "/"
			)
		)

		await queryClient.invalidateQueries(WorkordersUrlPath.Workorders)
	}
}

export const useCreateErp = (): ApiPostMutation<CreateErpPost, string> => {
	return usePostRequest([WorkordersUrlPath.Workorders, WorkordersUrlPath.Erp], {
		keyToInvalidate: WorkordersUrlPath.Workorders,
	})
}

export const useConvertErp = (): ApiPostMutation<ConvertErpPost> => {
	return usePostRequest([WorkordersUrlPath.Workorders, WorkordersUrlPath.Erp2], {
		addRequestDataIdToUrl: true,
		urlPathsAfterId: WorkordersUrlPath.Convert,
		keyToInvalidate: WorkordersUrlPath.Workorders,
	})
}

export const useUnexpireErp = (): ApiPostMutation<UnexpireErpPost> => {
	return usePostRequest([WorkordersUrlPath.Workorders, WorkordersUrlPath.Erp2], {
		addRequestDataIdToUrl: true,
		urlPathsAfterId: WorkordersUrlPath.Unexpire,
		keyToInvalidate: WorkordersUrlPath.Workorders,
	})
}

export const useUploadErpAttachment = (): ((file: File, erpId: string) => Promise<void>) => {
	const request = usePostRequest<FormData, string>(
		[WorkordersUrlPath.Workorders, WorkordersUrlPath.UploadErpAttachment],
		{ keyToInvalidate: WorkordersUrlPath.Workorders }
	)

	const call = async (file: File, erpId: string): Promise<void> => {
		const data = new FormData()

		data.append("file", file)
		data.append("erp_id", erpId)

		await request(data)
	}

	return call
}

export const useWorkOrderLineItemTypes = (
	options?: ApiGetQueryOptions<WorkOrderLineItemType[], WorkOrderLineItemTypesQueryParams>
): ApiGetQuery<WorkOrderLineItemType[]> => {
	const [data, ...rest] = useGetRequest<WorkOrderLineItemType[]>(
		[WorkordersUrlPath.Workorders, WorkordersUrlPath.WorkOrderLineItemTypes],
		options
	)

	useEnumPropertyValidation(data, "id", LineItemType)

	return [data, ...rest]
}

export const useWorkOrderInvoice = (
	invoiceId: string | null | undefined
): ApiGetQuery<WorkOrderInvoice> => {
	const [data, ...rest] = useGetRequest<WorkOrderInvoice>(
		[WorkordersUrlPath.Workorders, WorkordersUrlPath.Invoices, invoiceId ?? ""],
		{
			queryConfig: {
				enabled: !!invoiceId,
			},
		}
	)

	useEnumPropertyValidation(data, "status", InvoiceStatusId)

	return [data, ...rest]
}

export const useUpdateWorkOrderInvoice = (): ApiPatchMutation<UpdateWorkOrderInvoicePatch> => {
	return usePatchRequest([WorkordersUrlPath.Workorders, WorkordersUrlPath.Invoices], {
		keyToInvalidate: [[WorkordersUrlPath.Workorders], [DispatchUrlPaths.Dispatches]],
	})
}

export const useWorkOrderInvoiceLineItems = (
	invoiceId: string | null | undefined
): ApiGetQuery<WorkOrderInvoiceLineItem[]> => {
	const [data, ...rest] = useGetRequest<WorkOrderInvoiceLineItem[]>(
		[
			WorkordersUrlPath.Workorders,
			WorkordersUrlPath.Invoices,
			invoiceId ?? "",
			WorkordersUrlPath.LineItems,
		],
		{
			queryConfig: {
				enabled: !!invoiceId,
			},
		}
	)

	useEnumPropertyValidation(data, "overridePermission", APPLICATION)

	return [data, ...rest]
}

export const useCreateWorkOrderInvoiceLineItem = (
	invoiceId: string
): ApiPostMutation<CreateWorkorderInvoiceLineItemPost> => {
	return usePostRequest(
		[
			WorkordersUrlPath.Workorders,
			WorkordersUrlPath.Invoices,
			invoiceId ?? "",
			WorkordersUrlPath.LineItems,
		],
		{
			keyToInvalidate: [[WorkordersUrlPath.Workorders], [DispatchUrlPaths.Dispatches]],
		}
	)
}

export const useUpdateWorkOrderInvoiceLineItem = (
	invoiceId: string
): ApiPatchMutation<UpdateWorkorderInvoiceLineItemPatch> => {
	return usePatchRequest(
		[
			WorkordersUrlPath.Workorders,
			WorkordersUrlPath.Invoices,
			invoiceId ?? "",
			WorkordersUrlPath.LineItems,
		],
		{
			keyToInvalidate: [[WorkordersUrlPath.Workorders], [DispatchUrlPaths.Dispatches]],
		}
	)
}

export const useDeleteWorkOrderLineInvoiceLineItem = (invoiceId: string): ApiDeleteMutation => {
	return useDeleteRequest(
		[
			WorkordersUrlPath.Workorders,
			WorkordersUrlPath.Invoices,
			invoiceId ?? "",
			WorkordersUrlPath.LineItems,
		],
		{
			keyToInvalidate: [[WorkordersUrlPath.Workorders], [DispatchUrlPaths.Dispatches]],
		}
	)
}

export const useSetInvoiceLineItemsNonBillable = (invoiceId: string): ApiPatchMutation<void> => {
	return usePatchRequest(
		[
			WorkordersUrlPath.Workorders,
			WorkordersUrlPath.Invoices,
			invoiceId,
			WorkordersUrlPath.BulkNonBillable,
		],
		{
			keyToInvalidate: [[WorkordersUrlPath.Workorders], [DispatchUrlPaths.Dispatches]],
		}
	)
}

export const useCreditMemos = (
	invoiceId: string | null | undefined
): ApiGetQuery<CreditMemo[]> => {
	const [data, ...rest] = useGetRequest<CreditMemo[]>(
		[
			WorkordersUrlPath.Workorders,
			WorkordersUrlPath.Invoices,
			invoiceId ?? "",
			WorkordersUrlPath.CreditMemos,
		],
		{
			queryConfig: {
				enabled: !!invoiceId,
			},
		}
	)

	useEnumPropertyValidation(data, "status", InvoiceStatusId)

	return [data, ...rest]
}

export const useCreateCreditMemo = (invoiceId: string): ApiPostMutation<CreateCreditMemoPost> => {
	return usePostRequest(
		[
			WorkordersUrlPath.Workorders,
			WorkordersUrlPath.Invoices,
			invoiceId,
			WorkordersUrlPath.CreditMemo,
		],
		{
			keyToInvalidate: [[WorkordersUrlPath.Workorders], [DispatchUrlPaths.Dispatches]],
		}
	)
}

export const useRebillInvoice = (invoiceId: string): ApiPostMutation<RebillInvoicePost> => {
	return usePostRequest(
		[
			WorkordersUrlPath.Workorders,
			WorkordersUrlPath.Invoices,
			invoiceId,
			WorkordersUrlPath.Rebill,
		],
		{
			keyToInvalidate: [[WorkordersUrlPath.Workorders], [DispatchUrlPaths.Dispatches]],
		}
	)
}

export const useApproveInvoice = (): ApiPostMutation<ApproveInvoicePost> => {
	return usePostRequest([WorkordersUrlPath.Workorders, WorkordersUrlPath.Invoices], {
		addRequestDataIdToUrl: true,
		urlPathsAfterId: WorkordersUrlPath.Approve,
		keyToInvalidate: [[WorkordersUrlPath.Workorders], [DispatchUrlPaths.Dispatches]],
	})
}

export const useRejectInvoice = (): ApiPostMutation<RejectInvoicePost> => {
	return usePostRequest([WorkordersUrlPath.Workorders, WorkordersUrlPath.Invoices], {
		addRequestDataIdToUrl: true,
		urlPathsAfterId: WorkordersUrlPath.Reject,
		keyToInvalidate: [[WorkordersUrlPath.Workorders], [DispatchUrlPaths.Dispatches]],
	})
}

export const useFinalizeInvoice = (): ApiPostMutation<FinalizeInvoicePost> => {
	return usePostRequest([WorkordersUrlPath.Workorders, WorkordersUrlPath.Invoices], {
		addRequestDataIdToUrl: true,
		urlPathsAfterId: WorkordersUrlPath.Finalize,
		keyToInvalidate: [[WorkordersUrlPath.Workorders], [DispatchUrlPaths.Dispatches]],
	})
}

/**
 * Heads up, the backend prevents any user other than Ryko Admin from doing this.
 */
export const useApproveCreditMemo = (
	invoiceId: string
): ApiPostMutation<ApproveCreditMemoPost> => {
	return usePostRequest(
		[
			WorkordersUrlPath.Workorders,
			WorkordersUrlPath.Invoices,
			invoiceId,
			WorkordersUrlPath.CreditMemos,
		],
		{
			addRequestDataIdToUrl: true,
			urlPathsAfterId: WorkordersUrlPath.Approve,
			keyToInvalidate: [[WorkordersUrlPath.Workorders], [DispatchUrlPaths.Dispatches]],
		}
	)
}

export const useRejectCreditMemo = (invoiceId: string): ApiPostMutation<RejectCreditMemoPost> => {
	return usePostRequest(
		[
			WorkordersUrlPath.Workorders,
			WorkordersUrlPath.Invoices,
			invoiceId,
			WorkordersUrlPath.CreditMemos,
		],
		{
			addRequestDataIdToUrl: true,
			urlPathsAfterId: WorkordersUrlPath.Reject,
			keyToInvalidate: [[WorkordersUrlPath.Workorders], [DispatchUrlPaths.Dispatches]],
		}
	)
}

export const useCreateInvoiceNote = (): ApiPostMutation<CreateInvoiceNotePost> => {
	return usePostRequest([WorkordersUrlPath.Workorders, WorkordersUrlPath.Invoices], {
		addRequestDataIdToUrl: true,
		urlPathsAfterId: WorkordersUrlPath.History,
		keyToInvalidate: [[WorkordersUrlPath.Workorders], [DispatchUrlPaths.Dispatches]],
	})
}

// Ideally this would be a simple lookup and the backend would be in charge of the master
// list of which line item types are accessorials and which are not.
export const useAccessorials = (all = false): [WorkOrderLineItemType[] | undefined, boolean] => {
	const [lineItemTypes, lineItemTypesLoading] = useWorkOrderLineItemTypes()

	const accessorials = useMemo(() => {
		return lineItemTypes?.filter((a) => {
			// Just in case, make sure that the DB says this is user selectable.
			if (all !== true && a.userSelectable === false) return false
			// Check if this is in our front-end source of truth on what is an accessorial
			// and what is not.
			return lineTypesAccessorials.includes(a.id)
		})
	}, [lineItemTypes, all])

	return [accessorials, lineItemTypesLoading]
}

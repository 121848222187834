import { FC, useState } from "react"

import dayjs from "dayjs"

import {
	APPLICATION,
	makeApiErrorMessage,
	TerritoryMinimal,
	UpdateUserProfilePatch,
	useRoleGroups,
	UserProfile,
	useSendPasswordReset,
	useUpdateUserProfile,
	useUserCanUse,
	useUserGroups,
} from "@ncs/ncs-api"
import { formatDate, formatPhone, titleCase } from "@ncs/ts-utils"
import {
	Box,
	Button,
	ConfirmationModal,
	ConfirmationModalConfig,
	DateInputIcon,
	EmptyValueDash,
	GridContainer,
	GridItem,
	HeadingDivider,
	HeadingDividerProps,
	IconButton,
	Label,
	LabeledData,
	Paragraph,
	PhoneInput,
	TerritorySelector,
	TextInput,
	useToast,
} from "@ncs/web-legos"

import { EditRoleGroupsModal } from "./EditRoleGroupsModal"

export interface UserProfileDetailsTabProps {
	user: UserProfile
}

export const UserProfileDetailsTab: FC<UserProfileDetailsTabProps> = ({ user }) => {
	const isUserAdmin = useUserCanUse(APPLICATION.UserManagementAdmin)
	const [saving, setSaving] = useState<(keyof UpdateUserProfilePatch)[]>([])
	const { makeErrorToast, makeSuccessToast } = useToast()
	const [showEditGroups, setShowEditGroups] = useState(false)

	// Inline edit states
	const [newFirstName, setNewFirstName] = useState<string | null>(user.firstName)
	const [newLastName, setNewLastName] = useState<string | null>(user.lastName)
	const [newEmail, setNewEmail] = useState<string | null>(user.email)
	const [newPhone, setNewPhone] = useState<string | null>(user.mobile)
	const [newEmployeeNumber, setNewEmployeeNumber] = useState<string | null>(user.employeeNumber)
	const [newVerisae, setNewVerisae] = useState<string | null>(user.verisaeUsername)
	const [newTerritory, setNewTerritory] = useState<TerritoryMinimal | null>(
		user.defaultTerritory
	)
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)

	const [userGroups] = useUserGroups(user.id.toString())
	const [allGroups] = useRoleGroups()
	const update = useUpdateUserProfile()
	const sendReset = useSendPasswordReset()

	const handleUpdate = async <Field extends keyof UpdateUserProfilePatch>(
		field: Field,
		value: UpdateUserProfilePatch[Field]
	) => {
		try {
			setSaving((prev) => [...prev, field])
			await update({
				updates: { [field]: value },
				id: user.id.toString(),
			})
			makeSuccessToast("User updated")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		} finally {
			setSaving((prev) => prev.filter((f) => f !== field))
		}
	}

	const handleSendPasswordReset = async () => {
		try {
			await sendReset({ email: user.email })
			makeSuccessToast("Password reset email sent")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		}
	}

	const headingDividerProps: HeadingDividerProps = {
		headingVariant: "h4",
		bold: true,
		mb: 1,
	}

	// I don't think we'll ever be here with a customer, but just in case...
	// const canResetPassword = !user.isCustomer

	return (
		<>
			<HeadingDivider {...headingDividerProps}>Contact</HeadingDivider>

			<GridContainer pl={1.5}>
				<GridItem xs={12} sm={6} md={4} lg={3}>
					<LabeledData
						label="First name"
						editingRender={(props) => (
							<TextInput
								{...props}
								value={newFirstName}
								onChange={setNewFirstName}
							/>
						)}
						onCancelEdit={() => setNewFirstName(user.firstName)}
						validateEdit={() => !!newFirstName}
						onSaveEdit={async () => {
							if (newFirstName) await handleUpdate("firstName", newFirstName)
						}}
						isSavingEdit={saving.includes("firstName")}
					>
						{user.firstName}
					</LabeledData>
				</GridItem>
				<GridItem xs={12} sm={6} md={4} lg={3}>
					<LabeledData
						label="Last name"
						editingRender={(props) => (
							<TextInput {...props} value={newLastName} onChange={setNewLastName} />
						)}
						onCancelEdit={() => setNewLastName(user.lastName)}
						validateEdit={() => !!newLastName}
						onSaveEdit={async () => {
							if (newLastName) await handleUpdate("lastName", newLastName)
						}}
						isSavingEdit={saving.includes("lastName")}
					>
						{user.lastName}
					</LabeledData>
				</GridItem>
				<GridItem xs={12} sm={6} md={4} lg={3}>
					<LabeledData
						label="Email"
						editingRender={(props) => (
							<TextInput {...props} value={newEmail} onChange={setNewEmail} />
						)}
						onCancelEdit={() => setNewEmail(user.email)}
						validateEdit={() => !!newEmail}
						onSaveEdit={async () => {
							if (newEmail) await handleUpdate("email", newEmail)
						}}
						isSavingEdit={saving.includes("email")}
					>
						{user.email}
					</LabeledData>
				</GridItem>
				<GridItem xs={12} sm={6} md={4} lg={3}>
					<LabeledData
						label="Phone"
						editingRender={(props) => (
							<PhoneInput {...props} value={newPhone} onChange={setNewPhone} />
						)}
						onCancelEdit={() => setNewPhone(user.mobile)}
						onSaveEdit={async () => {
							await handleUpdate("mobile", newPhone)
						}}
						isSavingEdit={saving.includes("mobile")}
					>
						{user.mobile ? formatPhone(user.mobile) : <EmptyValueDash />}
					</LabeledData>
				</GridItem>
			</GridContainer>

			<HeadingDivider {...headingDividerProps} mt={2}>
				Account
			</HeadingDivider>
			<GridContainer pl={1.5}>
				<GridItem xs={12} sm={6} md={4} lg={3}>
					<LabeledData
						label="Employee #"
						editingRender={(props) =>
							isUserAdmin ?
								<TextInput
									{...props}
									value={newEmployeeNumber}
									onChange={setNewEmployeeNumber}
								/>
							:	undefined
						}
						onCancelEdit={() => setNewEmployeeNumber(user.employeeNumber)}
						onSaveEdit={
							isUserAdmin ?
								async () => {
									await handleUpdate("employeeNumber", newEmployeeNumber)
								}
							:	undefined
						}
						isSavingEdit={saving.includes("employeeNumber")}
					>
						{user.employeeNumber || <EmptyValueDash />}
					</LabeledData>
				</GridItem>
				<GridItem xs={12} sm={6} md={4} lg={3}>
					<LabeledData
						label="Verisae username"
						editingRender={(props) => (
							<TextInput {...props} value={newVerisae} onChange={setNewVerisae} />
						)}
						onCancelEdit={() => setNewVerisae(user.verisaeUsername)}
						onSaveEdit={async () => {
							await handleUpdate("verisaeUsername", newVerisae)
						}}
						isSavingEdit={saving.includes("verisaeUsername")}
					>
						{user.verisaeUsername || <EmptyValueDash />}
					</LabeledData>
				</GridItem>
				{isUserAdmin && (
					<>
						<GridItem xs={12} sm={6} md={4} lg={3}>
							<Label>Effective date</Label>
							<Box display="flex" alignItems="center">
								<Paragraph>{formatDate(user.effectiveDate)}</Paragraph>
								{isUserAdmin && (
									<DateInputIcon
										value={dayjs(user.effectiveDate)}
										onChange={async (newValue) => {
											if (newValue)
												await handleUpdate(
													"effectiveDate",
													newValue.toISOString()
												)
										}}
									/>
								)}
							</Box>
						</GridItem>

						<GridItem xs={12} sm={6} md={4} lg={3}>
							<Label>End date</Label>
							<Box display="flex" alignItems="center">
								<Paragraph>{formatDate(user.endDate)}</Paragraph>
								{isUserAdmin && (
									<DateInputIcon
										value={dayjs(user.endDate)}
										onChange={async (newValue) => {
											if (newValue)
												await handleUpdate(
													"endDate",
													newValue.toISOString()
												)
										}}
										datePickerProps={{
											showTodayButton: true,
										}}
									/>
								)}
							</Box>
						</GridItem>
					</>
				)}
				<GridItem xs={12} sm={6} md={4} lg={3}>
					<LabeledData
						label="Schedule territory"
						editingRender={(props) => (
							<TerritorySelector
								{...props}
								value={newTerritory}
								onChange={setNewTerritory}
							/>
						)}
						onCancelEdit={() => setNewTerritory(user.defaultTerritory)}
						onSaveEdit={async () => {
							await handleUpdate("defaultTerritoryId", newTerritory?.id ?? null)
						}}
						isSavingEdit={saving.includes("defaultTerritoryId")}
					>
						{user.defaultTerritory ?
							`(${user.defaultTerritory.code}) ${user.defaultTerritory.description}`
						:	<EmptyValueDash />}
					</LabeledData>
				</GridItem>
				<GridItem xs={12} sm={6} md={4} lg={3}>
					<Label>User roles</Label>
					<Box display="flex" columnGap={0.5}>
						{userGroups?.length ?
							<Paragraph>
								{userGroups
									.map((g) => titleCase(g.code.split("_").join(" ")))
									.join(", ")}
							</Paragraph>
						:	<EmptyValueDash />}
						{isUserAdmin && (
							<Box mt={-0.5}>
								<IconButton
									icon="pencil"
									color="primary"
									onClick={() => setShowEditGroups(true)}
									disabled={!allGroups || !userGroups}
								/>
							</Box>
						)}
					</Box>
				</GridItem>
			</GridContainer>

			<Box pl={1.5} mt={2}>
				<Button
					icon="lock"
					onClick={() =>
						setConfirmationConfig({
							title: "Send Password Reset",
							message:
								"Send an email to this user with instructions to reset their password?",
							onConfirm: handleSendPasswordReset,
						})
					}
				>
					Send password reset email
				</Button>
			</Box>

			{!!allGroups && !!userGroups && (
				<EditRoleGroupsModal
					isOpen={showEditGroups}
					onClose={() => setShowEditGroups(false)}
					user={user}
					allGroups={allGroups}
					userGroups={userGroups}
				/>
			)}
			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</>
	)
}

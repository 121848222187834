import React from "react"
import PropTypes from "prop-types"
import TimeAgo from "react-timeago"
import { createStyles, makeStyles } from "@material-ui/core"
import { Card, CardBody, CardFooter, CardHeader, GridItem, LineGraph } from "../../components"
import { useLongPress } from "../../util/hooks"
import { formatLongDateTime } from "../../util/formatters"
import {
	MaterialDashboardProColors,
	MaterialUISizeKeys,
	MaterialUISizeValues,
} from "../../util/types"

import dashboardStyle from "../../assets/jss/material-dashboard-pro-react/views/dashboardStyle"

const styles = createStyles({
	...dashboardStyle,
	clickableCard: {
		cursor: "pointer",
	},
})
const useStyles = makeStyles(styles)

export interface DashboardLineGraphProps {
	color: Exclude<MaterialDashboardProColors, "gray">
	title: string
	data?: Record<string, unknown>
	summary?: React.ReactElement
	summaries?: React.ReactNode[]
	footerIcon: React.ReactNode
	footerText: React.ReactNode
	footerDate?: string | Date
	noWrapper?: boolean
	onClick?: () => void
	onLongPress?: () => void
	showLegend?: boolean
	size?: Partial<Record<MaterialUISizeKeys, MaterialUISizeValues>>
}

const DashboardLineGraph: React.FC<DashboardLineGraphProps> = ({
	color,
	data,
	footerDate,
	footerIcon,
	footerText,
	noWrapper,
	onClick,
	onLongPress,
	showLegend,
	size,
	summaries,
	summary,
	title,
	...rest
}) => {
	const classes = useStyles()
	const longPress = useLongPress(onLongPress, onClick)

	const inner = (
		<Card
			chart
			{...(onLongPress ? longPress : { onClick })}
			className={onClick ? classes.clickableCard : ""}
		>
			<CardHeader color={color} className={classes.cardHeaderHover}>
				<LineGraph data={data} color={color} showLegend={showLegend} {...rest} />
			</CardHeader>
			<CardBody>
				<h4 className={classes.cardTitle}>{title}</h4>
				{[...(summaries || []), summary].map((item, i) => (
					<div key={i}>{item}</div>
				))}
			</CardBody>
			<CardFooter chart>
				<div className={classes.stats}>
					{footerIcon}
					{footerText}&nbsp;
					{footerDate && (
						<TimeAgo date={footerDate} title={formatLongDateTime(footerDate)} />
					)}
				</div>
			</CardFooter>
		</Card>
	)

	return noWrapper ? inner : (
			<GridItem xs={12} sm={12} md={6} {...size}>
				{inner}
			</GridItem>
		)
}

DashboardLineGraph.propTypes = {
	// @ts-expect-error. TODO: Make this more specific when LineGraph is converted to TS.
	data: PropTypes.object,

	color: PropTypes.oneOf([
		"primary" as const,
		"info" as const,
		"success" as const,
		"warning" as const,
		"danger" as const,
		"rose" as const,
	]).isRequired,
	title: PropTypes.string.isRequired,
	summary: PropTypes.element,
	summaries: PropTypes.array,
	footerIcon: PropTypes.node.isRequired,
	footerText: PropTypes.node.isRequired,
	footerDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
	noWrapper: PropTypes.bool,
	onClick: PropTypes.func,
	onLongPress: PropTypes.func,
	showLegend: PropTypes.bool,
}

export default DashboardLineGraph

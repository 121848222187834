import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import callApi from "@ncs/bricks/redux/services/callApi"
import { loadLookups, LOOKUPS } from "../../redux/services/lookups"

// custom components
import { Select } from "@ncs/bricks/components"

class TicketPrioritySelector extends React.PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			priorities: [],
			priority: null,
		}
	}

	componentDidMount() {
		this.props.loadLookups([LOOKUPS.TicketPriorities])
	}

	render() {
		const { displayEmpty, ...rest } = this.props
		return (
			<Select
				id="ticketPriorities"
				labelText="Priority"
				value={this.props.priority || null}
				onChange={this.props.onChange}
				options={[
					{
						id: null,
						description: "Select a priority...",
						disabled: false,
					},
					...this.props.priorities,
				]}
				labelProps={{
					shrink: displayEmpty ? true : undefined,
					...rest.labelProps,
				}}
				valueAccessor="id"
				textAccessor={(x) => x.description}
				selectProps={{
					displayEmpty: displayEmpty,
					...rest.selectProps,
				}}
				{...rest}
			/>
		)
	}
}

TicketPrioritySelector.defaultProps = {
	displayEmpty: true,
}

TicketPrioritySelector.propTypes = {
	priority: PropTypes.object,
	onBinSelected: PropTypes.func.isRequired,
	displayEmpty: PropTypes.bool,
}

const mapStateToProps = (state) => {
	return {
		priorities: state.lookups.ticketPriorities,
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			callApi,
			loadLookups,
		},
		dispatch
	)

export default connect(mapStateToProps, mapDispatchToProps)(TicketPrioritySelector)

import { FC } from "react"

import {
	ChemicalRevSummaryReport,
	CustomerPortalPurchasersReport,
	CustomerPortalUsageSummaryReport,
	DispatchCountsReport,
	DispatchHistoryReport,
	ErpDetailReport,
	ErpSummaryReport,
	LaborRatesReport,
	MilestoneDetailReport,
	NegativeOnHandReport,
	PartOrderDetailReport,
	PickListDetailReport,
	PmpiDetailReport,
	PmpiSummaryReport,
	PricingModificationsReport,
	SalesRepAssignmentsReport,
	SalesRepDetailReport,
	SalesRepInvoicesReport,
	ServiceTrendReport,
	SiteSurveyGroupedReport,
	SiteSurveyReport,
	WarehouseOrderReport,
	WorkOrderDetailReport,
} from "./report-components"

export const reportConfigs: {
	/**
	 * What's shown in the UI to the user.
	 */
	uiName: string
	/**
	 * If this report is represented in the `reports` permission DB table, this is
	 * what it's called. Reports that don't need special permission don't have a row
	 * on the table and can be null here.
	 */
	permissionName: string | null
	/**
	 * The actual report component to render.
	 */
	component: FC
}[] = [
	{
		uiName: "Chemical Revenue Summary",
		permissionName: "Chemical Revenue Summary",
		component: ChemicalRevSummaryReport,
	},
	{
		uiName: "PM/PI Detail",
		permissionName: "PMPI Detail",
		component: PmpiDetailReport,
	},
	{
		uiName: "PM/PI Summary",
		permissionName: "PMPI Summary",
		component: PmpiSummaryReport,
	},
	{
		uiName: "Dispatch Counts",
		permissionName: "Dispatch History",
		component: DispatchCountsReport,
	},
	{
		uiName: "Dispatch History",
		permissionName: "Dispatch History",
		component: DispatchHistoryReport,
	},
	{
		uiName: "Quotes (ERP) Detail",
		permissionName: "ERP Detail",
		component: ErpDetailReport,
	},
	{
		uiName: "Quotes (ERP) Summary",
		permissionName: "ERP Summary",
		component: ErpSummaryReport,
	},
	{
		uiName: "Inventory Consolidation",
		permissionName: null,
		component: ErpSummaryReport,
	},
	{
		uiName: "Labor Rates",
		permissionName: "Labor Pricing Report",
		component: LaborRatesReport,
	},
	{
		uiName: "Milestone Detail",
		permissionName: "Milestone Detail",
		component: MilestoneDetailReport,
	},
	{
		uiName: "Negative On-Hand",
		permissionName: "Negative On-Hand",
		component: NegativeOnHandReport,
	},
	{
		uiName: "Part Order Detail",
		permissionName: "Part Order Detail",
		component: PartOrderDetailReport,
	},
	{
		uiName: "Pick List Detail",
		permissionName: "Pick List Detail",
		component: PickListDetailReport,
	},
	{
		uiName: "Pricing Modifications",
		permissionName: "Price Modifications",
		component: PricingModificationsReport,
	},
	{
		uiName: "Sales Rep Assignments",
		permissionName: null,
		component: SalesRepAssignmentsReport,
	},
	{
		uiName: "Sales Rep Customer Revenue",
		permissionName: null,
		component: SalesRepInvoicesReport,
	},
	{
		uiName: "Sales Rep Detail",
		permissionName: null,
		component: SalesRepDetailReport,
	},
	{
		uiName: "Service Trend",
		permissionName: "Service Trend",
		component: ServiceTrendReport,
	},
	{
		uiName: "Site Survey",
		permissionName: null,
		component: SiteSurveyReport,
	},
	{
		uiName: "Site Survey Grouped",
		permissionName: null,
		component: SiteSurveyGroupedReport,
	},
	{
		uiName: "Warehouse Order",
		permissionName: null,
		component: WarehouseOrderReport,
	},
	{
		uiName: "Work Order Detail",
		permissionName: "Work Order Detail",
		component: WorkOrderDetailReport,
	},
	{
		uiName: "Customer Portal Usage Summary",
		permissionName: null,
		component: CustomerPortalUsageSummaryReport,
	},
	{
		uiName: "Customer Portal Purchasers",
		permissionName: null,
		component: CustomerPortalPurchasersReport,
	},
]

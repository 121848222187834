import { FC, useState } from "react"

import { useHistory } from "react-router-dom"
import { Column } from "react-table"

import {
	PricingAdjustmentRequestListItem,
	PricingAdjustmentRequestsQueryParams,
	useCreatePricingAdjustmentForm,
	usePricingAdjustmentRequests,
} from "@ncs/ncs-api"
import { displayDate, getTimezoneAbbreviation } from "@ncs/ts-utils"
import {
	Button,
	Card,
	EmptyValueDash,
	Paragraph,
	SelectCustomerGroupModal,
	SelectCustomerGroupModalPayload,
	Table,
	TextInput,
	UserQueryFilter as DefaultUserQueryFilter,
	UserQueryFilterProps,
	useStateReset,
	useToast,
	useUrlState,
} from "@ncs/web-legos"

import { PricingAdjustmentFormStatusQueryFilter } from "~/components"

export type PricingAdjustmentRequestsUrlState = PricingAdjustmentRequestsQueryParams

export const PricingAdjustmentRequests: FC = () => {
	const history = useHistory()
	const { makeSuccessToast } = useToast()
	const [showCreateModal, setShowCreateModal] = useState(false)
	const [newFormTitle, setNewFormTitle] = useStateReset<string | null>(null)
	const [params, { setUrlState }] = useUrlState(defaultUrlState)

	const [adjustments, adjustmentsLoading] = usePricingAdjustmentRequests({ params })
	const createForm = useCreatePricingAdjustmentForm()

	const handleCreate = async (customers: SelectCustomerGroupModalPayload) => {
		if (!newFormTitle) throw new Error("Title is required")
		if (!customers.billToId && !customers.shipToId) {
			throw new Error("No customer selected")
		}

		const { data } = await createForm({
			description: newFormTitle,
			...customers,
		})

		makeSuccessToast("Request form created")
		history.push(`/service/price-adjustment-requests/${data}`)
	}

	const paramsSet = Object.values(params).some((v) => v)

	return (
		<Card
			heading="Pricing Adjustment Request Forms"
			headingIcon="dollar-sign"
			renderRight={() => (
				<Button
					variant="secondary-cta"
					icon="plus-circle"
					onClick={() => setShowCreateModal(true)}
				>
					Create Adjustment Request
				</Button>
			)}
		>
			<Table
				data={adjustments ?? []}
				columns={columns}
				isLoading={adjustmentsLoading}
				onRowClick={({ original }) =>
					history.push(`/service/price-adjustment-requests/${original.id}`)
				}
				queryParamState={params}
				setQueryParamState={setUrlState}
				toggledQueryFilters={[UserQueryFilter, PricingAdjustmentFormStatusQueryFilter]}
				showToggledFiltersByDefault={paramsSet}
				noDataText={
					paramsSet ? "No forms match the current filter settings" : "No forms found"
				}
			/>

			{showCreateModal && (
				<SelectCustomerGroupModal
					title="New Pricing Adjustment Request"
					instructions={
						<>
							<Paragraph mb={1}>
								Enter a title or short summary of the adjustment request. You can
								provide additional details on the next screen.
							</Paragraph>
							<TextInput
								autoFocus
								value={newFormTitle}
								onChange={setNewFormTitle}
								label="Title or short summary of request (required)"
								mb={2}
							/>
							<Paragraph mb={1.5}>
								Next, search all customers and make a selection for who should be
								affected by the price changes. Choose either just your selection,
								or your selection and all others that have the same bill-to, or all
								sites that bill to your selection.
							</Paragraph>
						</>
					}
					saveButtonText="Start Form"
					onSave={handleCreate}
					onClose={() => setShowCreateModal(false)}
					autoFocus={false}
					disableSaveButton={!newFormTitle}
				/>
			)}
		</Card>
	)
}

const columns: Column<PricingAdjustmentRequestListItem>[] = [
	{
		Header: "Title",
		accessor: "description",
	},
	{
		Header: "Status",
		accessor: "status",
	},
	{
		Header: "Assigned to",
		accessor: "assignedTo",
	},
	{
		Header: `Last submission date (${getTimezoneAbbreviation()})`,
		accessor: ({ lastSubmissionDate }) =>
			displayDate(lastSubmissionDate, "") || <EmptyValueDash />,
	},
]

const defaultUrlState: PricingAdjustmentRequestsUrlState = {
	status: null,
	assignedTo: null,
}

const UserQueryFilter = (props: UserQueryFilterProps<PricingAdjustmentRequestsUrlState>) => {
	return (
		<DefaultUserQueryFilter
			{...props}
			paramKey="assignedTo"
			label="Assigned to"
			employeesOnly
		/>
	)
}

import { FC, useEffect, useState } from "react"

import dayjs, { Dayjs } from "dayjs"

import { CreateContractPost, makeApiErrorMessage } from "@ncs/ncs-api"
import { extractNumber } from "@ncs/ts-utils"
import {
	Box,
	Button,
	DateInput,
	ExtendableModalProps,
	IconButton,
	Modal,
	NumericInput,
} from "@ncs/web-legos"

export interface PriceEscalationsModalProps extends ExtendableModalProps {
	escalations: CreateContractPost["priceIncreases"]
	onSave: (increases: CreateContractPost["priceIncreases"]) => Promise<void> | void
}

interface LocalPriceIncrease {
	executedOn: Dayjs | null
	scheduledOn: Dayjs | null
	percentIncreased: number | null
}

export const PriceEscalationsModal: FC<PriceEscalationsModalProps> = ({
	escalations,
	onSave,
	...rest
}) => {
	const [localIncreases, setLocalIncreases] = useState<LocalPriceIncrease[]>([])
	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const handleSave = async () => {
		try {
			// If there are any increases that are partially filled out, throw an error.
			if (
				localIncreases.some(
					(i) => !i.scheduledOn || !i.executedOn || i.percentIncreased == null
				)
			) {
				throw new Error("Please complete or remove any unfinished rows")
			}

			setIsSaving(true)
			await onSave(
				localIncreases.flatMap((i) => {
					if (i.scheduledOn && i.executedOn && i.percentIncreased != null) {
						return [
							{
								scheduledOn: i.scheduledOn.toISOString(),
								executedOn: i.executedOn.toISOString(),
								percentIncreased: i.percentIncreased,
							},
						]
					} else {
						return []
					}
				})
			)
			// Handle success outside of modal.
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const reset = () => {
		setIsSaving(false)
		setErrorText(null)
		setLocalIncreases(
			escalations.length ?
				escalations.map((increase) => ({
					scheduledOn: dayjs(increase.scheduledOn),
					executedOn: dayjs(increase.executedOn),
					percentIncreased: extractNumber(increase.percentIncreased),
				}))
			:	[
					{
						scheduledOn: null,
						executedOn: null,
						percentIncreased: null,
					},
				]
		)
	}

	useEffect(() => {
		setErrorText(null)
	}, [localIncreases])

	return (
		<Modal
			{...rest}
			onOpen={reset}
			errorText={errorText}
			title="Price Escalations"
			rightButtons={{
				buttonText: "Add to contract",
				isLoading: isSaving,
				onClick: handleSave,
			}}
		>
			{localIncreases.map((increase, i) => (
				<Box key={i} display="flex" columnGap={1}>
					<DateInput
						value={increase.scheduledOn}
						onChange={(value) => {
							setLocalIncreases((prev) => {
								return prev.map((item, j) =>
									j === i ? { ...item, scheduledOn: value } : item
								)
							})
						}}
						label="Scheduled date"
					/>
					<DateInput
						value={increase.executedOn}
						onChange={(value) =>
							setLocalIncreases((prev) => {
								return prev.map((item, j) =>
									j === i ? { ...item, executedOn: value } : item
								)
							})
						}
						label="Executed date"
					/>
					<NumericInput
						doPercentageMath
						label="Percent increased %"
						placeholder="Eg, 1.5"
						value={increase.percentIncreased}
						onChange={(value) => {
							setLocalIncreases((prev) => {
								return prev.map((item, j) =>
									j === i ? { ...item, percentIncreased: value ?? null } : item
								)
							})
						}}
						min={0}
						max={100}
						decimalScale={3}
					/>
					<Box width={4} mt={1.5}>
						<IconButton
							icon="trash-alt"
							onClick={() => {
								setLocalIncreases((prev) => prev.filter((item, j) => j !== i))
							}}
						/>
					</Box>
				</Box>
			))}

			<Button
				icon="plus"
				onClick={() => {
					setLocalIncreases((prev) => [
						...prev,
						{
							scheduledOn: null,
							executedOn: null,
							percentIncreased: null,
						},
					])
				}}
			>
				Add price escalation
			</Button>
		</Modal>
	)
}

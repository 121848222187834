import { FC } from "react"

import { Button, ExtendableModalProps, Modal, Paragraph, useSetUrlState } from "@ncs/web-legos"

import { PhysicalInventoryTab, PhysicalInventoryUrlState } from "../../../PhysicalInventory"

export type InventoryRequestCreatedModalProps = ExtendableModalProps

export const InventoryRequestCreatedModal: FC<InventoryRequestCreatedModalProps> = ({
	...rest
}) => {
	const setUrlState = useSetUrlState<PhysicalInventoryUrlState>()

	return (
		<Modal {...rest} title="Inventory Request Successful" showCloseButton={false}>
			<Paragraph mb={3}>
				Your inventory request is being created now. Depending on the number of parts
				involved, it may take or a minute or two for the request to finish populating.
			</Paragraph>

			<Button
				variant="secondary-cta"
				fillContainer
				icon="check"
				onClick={() => setUrlState({ tab: PhysicalInventoryTab.ViewInventories })}
			>
				Done
			</Button>
		</Modal>
	)
}

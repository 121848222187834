import { ReactElement } from "react"

import { css, Theme } from "@emotion/react"
import isEqual from "lodash/isEqual"

import { accessString, addOrPromote, StringAccessor } from "@ncs/ts-utils"

import { useChangeCallback, useLocalStorageState } from "../../util"
import { Box } from "../layout"
import { Icon, Label } from "../typography"

export interface RecentItemsProps<Item> {
	/** The item you want to store. `RecentItems` will keep an array of this as `item` changes.  */
	item: Item
	/** How should the item display in the recents list? */
	itemLabelAccessor: StringAccessor<NonNullable<Item>>
	/** What happens when you click one of the recent items? */
	onSelect: (item: NonNullable<Item>) => void
	/** How many should keep we around? */
	limit?: number
	/** How do we describe the list? Defaults to "Recent". */
	label?: string
	/** Override the key used for `useLocalStorageState`.  */
	localStorageName?: string
	/** Function to determine if a new item is a replacement of an existing one or a new one.
	 * Defaults to lodash `isEqual`. */
	compareFn?: (itemA: NonNullable<Item>, itemB: NonNullable<Item>) => boolean
}

export const RecentItems = <Item,>({
	item,
	itemLabelAccessor,
	localStorageName,
	label = "Recent",
	limit = 4,
	onSelect,
	compareFn,
}: RecentItemsProps<Item>): ReactElement => {
	const [storedItems, setStoredItems] = useLocalStorageState<NonNullable<Item>[]>([], {
		keyOverride: localStorageName,
	})

	// As item changes, update our local storage list.
	useChangeCallback(item, (newItem) => {
		if (newItem != null) {
			setStoredItems(
				addOrPromote(newItem as NonNullable<Item>, storedItems, (prevItem) => {
					if (compareFn) {
						return compareFn(newItem as NonNullable<Item>, prevItem)
					} else {
						return isEqual(newItem, prevItem)
					}
				}).slice(0, limit)
			)
		}
	})

	if (storedItems.length === 0) {
		return <div />
	}

	return (
		<Box display="flex" alignItems="center" flexWrap="wrap" gap={0.5}>
			<Box display="flex" alignItems="center" columnGap={0.5}>
				<Icon icon="search" color="gray" />
				<Label>{label}</Label>
			</Box>
			{storedItems.map((storedItem, i) => {
				const itemLabel = accessString(storedItem, itemLabelAccessor)

				return (
					<button
						key={`${itemLabel}${i}`}
						css={recentButtonStyles}
						onClick={() => onSelect(storedItem)}
					>
						{itemLabel}
						{i !== storedItems.length - 1 && <span>, </span>}
					</button>
				)
			})}
		</Box>
	)
}

const recentButtonStyles = (theme: Theme) => css`
	font-size: 0.9rem;
	font-weight: bold;
	color: ${theme.palette.primary.main};
	background: none;
	border: 0;
	padding: 0;
	&:hover {
		color: ${theme.palette.primary.light};
	}
	transition: color 250ms ease-out;
`

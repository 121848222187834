import React from "react"
import { BaseDateFilter, withDynamicTableFilter } from "@ncs/bricks/components"

class EstimatedCompletionDateFilter extends React.PureComponent {
	render() {
		return <BaseDateFilter labelText="Est. Completion Date" {...this.props} />
	}
}

export default withDynamicTableFilter("est_comp_date")(EstimatedCompletionDateFilter)

import { FC, useMemo, useState } from "react"

import { Column } from "react-table"

import { PricingAdjustmentRequest, useUpdatePricingAdjustmentForm } from "@ncs/ncs-api"
import {
	Box,
	Button,
	ConfirmationModal,
	ConfirmationModalConfig,
	HeadingDivider,
	Label,
	Paragraph,
	Price,
	SelectCustomerGroupModal,
	SelectCustomerGroupModalPayload,
	Table,
	ThrottledTextInput,
	useToast,
} from "@ncs/web-legos"

import { priceAdjustmentPermissions } from "~/util"

export interface RequestDetailCustomersProps {
	request: PricingAdjustmentRequest
}

export const RequestDetailCustomers: FC<RequestDetailCustomersProps> = ({ request }) => {
	const { makeSuccessToast } = useToast()
	const [showAddModal, setShowAddModal] = useState(false)
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)
	const [search, setSearch] = useState<string | null>(null)

	const updateForm = useUpdatePricingAdjustmentForm()

	const customers = useMemo(() => {
		if (!search) return request.customers

		const searchChunks = search.trim().toLowerCase().split(" ")

		return request.customers.filter((c) => {
			const searchable = Object.values(c).join("").toLowerCase()

			return searchChunks.every((chunk) => searchable.includes(chunk))
		})
	}, [request.customers, search])

	const handleAddCustomers = async (customerIds: SelectCustomerGroupModalPayload) => {
		if (!customerIds.billToId && !customerIds.shipToId) {
			throw new Error("No customer selected")
		}

		await updateForm({
			id: request.id,
			updates: {
				addBillToId: customerIds.billToId,
				addShipToId: customerIds.shipToId,
			},
		})
		makeSuccessToast("Request form updated")
	}

	const handleRemoveCustomer = (customer: RequestCustomer) => {
		setConfirmationConfig({
			title: "Remove Customer",
			message: (
				<>
					Confirm: Remove customer{" "}
					<strong>
						({customer.customerNumber}) {customer.customerName}
					</strong>{" "}
					from this form?
				</>
			),
			onConfirm: async () => {
				await updateForm({
					id: request.id,
					updates: { removeCustomerId: customer.customerId },
				})
				makeSuccessToast("Request form updated")
			},
		})
	}

	const handleRemoveAllCustomers = () => {
		setConfirmationConfig({
			title: "Remove All Customers",
			message:
				"Confirm: Are you sure you want to remove all customers currently associated with this form?",
			onConfirm: async () => {
				await updateForm({
					id: request.id,
					updates: { removeAllCustomers: true },
				})
				makeSuccessToast("Request form updated")
			},
		})
	}

	const totals = useMemo(() => {
		const { partVolume, laborVolume, chemicalVolume } = request

		if (partVolume == null || laborVolume == null || chemicalVolume == null) {
			return null
		}

		return {
			partVolume,
			laborVolume,
			chemicalVolume,
			totalVolume: partVolume + laborVolume + chemicalVolume,
		}
	}, [request])

	const { noMoreEdits } = priceAdjustmentPermissions(request)

	return (
		<div>
			<HeadingDivider
				variant="h5"
				icon="user"
				renderRight={() => (
					<Box d="flex" gap={1}>
						<Button
							icon="plus"
							onClick={() => setShowAddModal(true)}
							disabled={noMoreEdits}
						>
							Add customer(s)
						</Button>
						<Button
							icon="ban"
							onClick={handleRemoveAllCustomers}
							disabled={noMoreEdits}
						>
							Remove all
						</Button>
					</Box>
				)}
			>
				Customers Included
			</HeadingDivider>

			<Box pl={1.5}>
				<Box d="flex" justifyContent="space-between" gap={2} flexWrap="wrap-reverse">
					<ThrottledTextInput
						value={search}
						onChange={setSearch}
						clearable
						icon="search"
						placeholder="Search..."
						maxWidth={15}
					/>
					{!!totals && (
						<Box d="flex" gap={2}>
							<div>
								<Label>Parts volume</Label>
								<Price small round price={totals.partVolume} />
							</div>
							<div>
								<Label>Labor volume</Label>
								<Price small round price={totals.laborVolume} />
							</div>
							<div>
								<Label>Chemical volume</Label>
								<Price small round price={totals.chemicalVolume} />
							</div>
							<Box bl={1} pl={2}>
								<Label>Total</Label>
								<Price small round price={totals.totalVolume} />
							</Box>
						</Box>
					)}
				</Box>

				<Table
					data={customers}
					columns={columns}
					infiniteRowsIncrement={25}
					rowMenu={
						noMoreEdits ? undefined : (
							[
								{
									label: "Remove",
									iconName: "trash-alt",
									onClick: ({ original }) => handleRemoveCustomer(original),
								},
							]
						)
					}
					disableAllSorting
					noDataText={
						search ?
							`No customers match "${search}"`
						:	"No customers attached to this form yet."
					}
				/>
			</Box>

			{showAddModal && (
				<SelectCustomerGroupModal
					title="Add Customers To Form"
					instructions={
						<Paragraph mb={1.5}>
							Search all customers and make a selection for who should be added to
							this price adjustment request form. Choose either just your selection,
							or your selection and all others that have the same bill-to, or all
							sites that bill to your selection.
						</Paragraph>
					}
					saveButtonText="Add customers"
					onSave={handleAddCustomers}
					onClose={() => setShowAddModal(false)}
				/>
			)}
			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</div>
	)
}

type RequestCustomer = PricingAdjustmentRequest["customers"][number]

const columns: Column<RequestCustomer>[] = [
	{
		Header: "Customer #",
		accessor: "customerNumber",
	},
	{
		Header: "Name",
		accessor: "customerName",
	},
]

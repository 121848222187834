import { FC, useEffect, useState } from "react"

import { makeApiErrorMessage, useCustomer } from "@ncs/ncs-api"
import { ExtendableModalProps, Modal, Paragraph, RadioGroup, useIsSaving } from "@ncs/web-legos"

export interface BillToSelectorModalProps extends ExtendableModalProps {
	customerId: string | null | undefined
	onSave: (billToId: string | null) => void | Promise<void>
	currentId?: string | null
	allowEmpty?: boolean
}

export const BillToSelectorModal: FC<BillToSelectorModalProps> = ({
	customerId,
	onSave,
	currentId,
	allowEmpty,
	...rest
}) => {
	const [customerDetails, customerDetailsLoading] = useCustomer(customerId)

	const [selection, setSelection] = useState<string | null>(currentId ?? null)
	const { isSaving, setSaving, endSaving } = useIsSaving()
	const [errorText, setErrorText] = useState<string | null>(null)

	const handleSave = async () => {
		if (!allowEmpty && !selection) {
			setErrorText("Please make a selection")
			return
		}

		try {
			setSaving()
			await onSave(selection)
			rest.onClose()
		} catch (e) {
			endSaving()
			setErrorText(makeApiErrorMessage(e))
		}
	}

	useEffect(() => {
		setErrorText(null)
	}, [selection])

	return (
		<Modal
			{...rest}
			title="Edit Bill-To"
			errorText={errorText}
			rightButtons={{
				buttonText: "Save",
				onClick: handleSave,
				isLoading: isSaving(),
			}}
		>
			<RadioGroup
				htmlName="bill-to"
				value={selection}
				onChange={setSelection}
				valueAccessor="id"
				labelAccessor={(option) =>
					`(${option.customerNumber}) ${option.name} - ${
						option.description || "(no description)"
					}`
				}
				options={customerDetails?.billableTo ?? []}
				disabled={customerDetailsLoading || !customerDetails?.billableTo?.length}
			/>

			{!customerDetailsLoading && !customerDetails?.billableTo?.length && (
				<Paragraph>No bill-to options found for customer</Paragraph>
			)}
		</Modal>
	)
}

import { ReactElement } from "react"

import { Control, Controller, ControllerRenderProps, FieldValues, Path } from "react-hook-form"

import { Checkbox, ExtendableCheckboxProps } from "../inputs"

export interface CheckboxFormFieldProps<TFieldValues extends FieldValues>
	extends ExtendableCheckboxProps {
	name: Path<TFieldValues>
	control: Control<TFieldValues>
	/**
	 * Override the built-in onChange function with your own. Provides the form's onChange in case you need it.
	 */
	onChange?: (
		newValue: boolean,
		formOnChange: ControllerRenderProps<TFieldValues, Path<TFieldValues>>["onChange"]
	) => void
}

export const CheckboxFormField = <TFieldValues extends FieldValues>({
	name,
	control,
	onChange: onChangeProp,
	...rest
}: CheckboxFormFieldProps<TFieldValues>): ReactElement => {
	return (
		<Controller
			name={name}
			control={control}
			render={({
				field: { onBlur, value, onChange: formOnChange },
				fieldState: { error },
			}) => {
				return (
					<Checkbox
						{...rest}
						value={!!value}
						onBlur={onBlur}
						onChange={(newValue: boolean) => {
							if (onChangeProp) {
								onChangeProp(newValue, formOnChange)
							} else {
								formOnChange(newValue)
							}
						}}
						error={error?.message}
					/>
				)
			}}
		/>
	)
}

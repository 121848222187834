import React from "react"
import PropTypes from "prop-types"
import {
	makeStyles,
	LinearProgress,
	LinearProgressProps as MaterialUILinearProgressProps,
} from "@material-ui/core"
import { MaterialDashboardProColors } from "../../util/types"

import styles from "../../assets/jss/material-dashboard-pro-react/components/customLinearProgressStyle"

const useStyles = makeStyles(styles)

export interface CustomLinearProgressProps
	extends Omit<MaterialUILinearProgressProps, "color" | "classes"> {
	color?: MaterialDashboardProColors
}

const CustomLinearProgress: React.FC<CustomLinearProgressProps> = ({
	color = "gray",
	...rest
}) => {
	const classes = useStyles()

	return (
		<LinearProgress
			{...rest}
			classes={{
				root: `${classes.root} ${classes[(color + "Background") as keyof typeof classes]}`,
				bar: `${classes.bar} ${classes[color]}`,
			}}
		/>
	)
}

CustomLinearProgress.propTypes = {
	color: PropTypes.oneOf(["primary", "warning", "danger", "success", "info", "rose", "gray"]),
}

export default CustomLinearProgress

import { FC } from "react"

import { Equipment, useEquipment } from "@ncs/ncs-api"

import { useInitialId } from "../../util"
import { ExtendableSearchableSelectProps, SearchableSelect } from "../inputs"
import { Paragraph } from "../typography"

export interface CustomerEquipmentSelectorProps
	extends ExtendableSearchableSelectProps<Equipment> {
	customerId: string | null | undefined
	initialId?: string
}

export const CustomerEquipmentSelector: FC<CustomerEquipmentSelectorProps> = ({
	customerId,
	initialId,
	value,
	onChange,
	...rest
}) => {
	const [equipment, isLoading] = useEquipment(customerId)

	useInitialId({
		currentValue: value,
		onChange,
		initialId,
		findInitialOption: () => equipment?.find((e) => e.id === initialId),
	})

	return (
		<SearchableSelect
			options={equipment ?? []}
			label="Equipment"
			value={value}
			isLoading={isLoading}
			onItemSelect={onChange}
			getOptionLabel={(option) =>
				`${option.model ? `${option.model} - ` : ""}#${option.serialNumber}`
			}
			renderOption={(option) => {
				return (
					<div>
						<Paragraph>
							{option.model || "(No model)"} #{option.serialNumber}
						</Paragraph>
						{!!option.bay && (
							<Paragraph small color="secondary">
								Bay {option.bay}
							</Paragraph>
						)}
					</div>
				)
			}}
			{...rest}
		/>
	)
}

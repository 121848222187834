import React from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import callApi from "@ncs/bricks/redux/services/callApi"
import { BaseSelectFilter, withDynamicTableFilter } from "@ncs/bricks/components"
import { loadLookups, LOOKUPS } from "../../redux/services/lookups"

class DeliveryOrderStatusFilter extends React.PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			locations: [],
		}
	}

	componentDidMount() {
		this.props.loadLookups([LOOKUPS.DeliveryOrderStatuses])
	}

	render() {
		return (
			<BaseSelectFilter
				id="deliveryOrderStatuses"
				labelText="Status"
				options={[{ id: "", description: "All Statuses" }, ...this.props.statuses]}
				valueAccessor="id"
				textAccessor="description"
				{...this.props}
			/>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		statuses: state.lookups.deliveryOrderStatuses,
	}
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ callApi, loadLookups }, dispatch)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withDynamicTableFilter("status")(DeliveryOrderStatusFilter))

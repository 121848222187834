import { FC, useCallback, useMemo, useState } from "react"

import { useHistory, useParams } from "react-router-dom"
import { Cell, Column } from "react-table"

import {
	InventoryRequest,
	InventoryRequestStatusDescription,
	InventoryRequestTypeDescription,
	makeApiErrorMessage,
	useCompleteInventoryRequest,
	useExportInventoryRequestLines,
	useInventoryRequest,
	useCancelInventoryRequest,
	useUserCanUse,
	APPLICATION,
} from "@ncs/ncs-api"
import { displayDateTime, formatCurrency, formatNumber } from "@ncs/ts-utils"
import {
	Box,
	Button,
	Card,
	ConfirmationModal,
	ConfirmationModalConfig,
	EmptyValueDash,
	encodeUrlState,
	GridContainer,
	GridItem,
	Heading,
	HeadingDivider,
	Label,
	LoadingSpinner,
	Paragraph,
	ReactTableSortType,
	Table,
	TableProps,
	ThrottledTextInput,
	useScrollToTopOnMount,
	useToast,
} from "@ncs/web-legos"

import { PageTitle } from "~/layouts"
import { PhysicalInventoryUrlState } from "~/views/Inventory/PhysicalInventory/PhysicalInventory"

import { InventoryPartDetailsModal } from "./components"

export const InventoryDetail: FC = () => {
	useScrollToTopOnMount()
	const { makeSuccessToast, makeErrorToast } = useToast()
	const { id } = useParams<{ id: string }>()
	const history = useHistory<{ physicalInventoryUrlState?: PhysicalInventoryUrlState }>()
	const [details, detailsLoading] = useInventoryRequest(id)
	const [partSearch, setPartSearch] = useState<string | null>(null)
	const [modalPart, setModalPart] = useState<InventoryPart | null>(null)
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)

	const completeRequest = useCompleteInventoryRequest(id)
	const cancelRequest = useCancelInventoryRequest(id)
	const exportLines = useExportInventoryRequestLines(id)

	const handleApprove = () => {
		setConfirmationConfig({
			title: "Approve and Submit Counts",
			message: `Confirm: Approve and submit this ${details?.inventoryType}?`,
			onConfirm: async () => {
				try {
					await completeRequest()
					makeSuccessToast("Inventory count completed")
				} catch (e) {
					makeErrorToast(makeApiErrorMessage(e))
				}
			},
		})
	}

	const handleCancel = () => {
		setConfirmationConfig({
			title: "Cancel Inventory Counts",
			message: `Confirm: Cancel this ${details?.inventoryType}?`,
			onConfirm: async () => {
				try {
					await cancelRequest({
						updates: {
							cancel: true,
							id: id,
						},
					})
					makeSuccessToast("Inventory count canceled")
				} catch (e) {
					makeErrorToast(makeApiErrorMessage(e))
				}
			},
		})
	}

	const partsList = useMemo(() => {
		const searchChunks = partSearch?.trim().toLowerCase().split(" ")

		if (!partSearch) {
			return details?.parts ?? []
		}

		return details?.parts.filter((p) => {
			const searchable = `${p.partNumber}${p.partDescription}`.toLowerCase()

			return searchChunks?.every((c) => searchable.includes(c))
		})
	}, [details?.parts, partSearch])

	const onRowClick: TableProps<InventoryPart>["onRowClick"] = useCallback(({ original }) => {
		setModalPart(original)
	}, [])

	const canApprove = details?.status === InventoryRequestStatusDescription.CountsSubmitted
	const canCancel = useUserCanUse(APPLICATION.CancelCycleCounts)
	const canEdit = (
		[
			InventoryRequestStatusDescription.Open,
			InventoryRequestStatusDescription.CountsSubmitted,
		] as (string | undefined)[]
	).includes(details?.status)
	const showActions =
		details?.status === InventoryRequestStatusDescription.CountsSubmitted ||
		(details?.status === InventoryRequestStatusDescription.Open && canCancel === true)
	const canUploadCounts =
		canEdit && details?.inventoryType === InventoryRequestTypeDescription.Physical

	const difference =
		details?.countedInventory != null && details?.currentInventory != null ?
			details.countedInventory - details.currentInventory
		:	null

	const heading = useMemo(() => {
		if (!details) {
			return undefined
		}
		if (details.inventoryType === InventoryRequestTypeDescription.Cycle) {
			return `Cycle Count for ${details.requestLocation}`
		} else {
			return `Physical Inventory for ${details.requestLocation}`
		}
	}, [details])

	if (detailsLoading) {
		return (
			<Card>
				<LoadingSpinner />
			</Card>
		)
	}

	if (!details) {
		return <Card>Could not load inventory request details</Card>
	}

	return (
		<>
			<PageTitle title={`Inventory Request #${id}`} />

			<Button
				icon="long-arrow-left"
				containerProps={{ mb: 2 }}
				onClick={() => {
					history.push({
						pathname: "/inventory/physical-inventory",
						search: encodeUrlState<PhysicalInventoryUrlState>(
							history.location.state?.physicalInventoryUrlState
						),
					})
				}}
			>
				All Inventories
			</Button>

			<Card
				heading={heading}
				headingIcon="calculator"
				headingDetail={`Created ${displayDateTime(details.createdDate)} by ${
					details.createdBy
				}`}
			>
				{showActions && (
					<Box d="flex" justifyContent="space-evenly" alignItems="stretch" mb={4}>
						{canApprove && (
							<Box maxWidth={30} flexGrow={1}>
								<Button
									variant="primary-cta"
									icon="check"
									fillContainer
									onClick={handleApprove}
								>
									Approve Inventory Count
								</Button>
							</Box>
						)}
						{canCancel && (
							<Box maxWidth={30} flexGrow={1}>
								<Button
									variant="secondary-cta"
									icon="cancel"
									fillContainer
									onClick={handleCancel}
								>
									Cancel Inventory Count
								</Button>
							</Box>
						)}
					</Box>
				)}

				<GridContainer mb={4}>
					<GridItem xs={12} sm={4} md={3}>
						<Label>Status</Label>
						<Paragraph>{details.status}</Paragraph>
					</GridItem>
					<GridItem xs={12} sm={4} md={3}>
						<Label>Assigned to</Label>
						<Paragraph>{details.assignedTo || <EmptyValueDash />}</Paragraph>
					</GridItem>
					<GridItem xs={12} sm={4} md={3}>
						<Label>Description</Label>
						<Paragraph>{details.requestDescription || <EmptyValueDash />}</Paragraph>
					</GridItem>
					<GridItem xs={12} sm={4} md={3}>
						<Label>Counts last submitted by</Label>
						<Paragraph>
							{details.submittedDate ?
								<>
									{details.submittedBy}, {displayDateTime(details.submittedDate)}
								</>
							:	<EmptyValueDash />}
						</Paragraph>
					</GridItem>
				</GridContainer>

				<HeadingDivider icon="list">Parts</HeadingDivider>
				<Box
					d="flex"
					mt={2}
					gap={4}
					justifyContent="space-between"
					flexWrap="wrap"
					alignItems="flex-end"
					mb={canUploadCounts ? 2 : 0}
				>
					<Box d="flex" gap={2.5}>
						<div>
							<Label>Original inventory $</Label>
							{details.currentInventory != null ?
								<Heading variant="h2" bold>
									{formatCurrency(details.currentInventory)}
								</Heading>
							:	<EmptyValueDash />}
						</div>
						<div>
							<Label>New count $</Label>
							{details.countedInventory != null ?
								<Heading variant="h2" bold>
									{formatCurrency(details.countedInventory)}
								</Heading>
							:	<EmptyValueDash />}
						</div>
						<Box bl={1} pl={2.5}>
							<Label>Difference</Label>
							{difference != null ?
								<Heading
									variant="h2"
									bold
									color={difference < 0 ? "error" : undefined}
								>
									{formatCurrency(difference)}
								</Heading>
							:	<EmptyValueDash />}
						</Box>
					</Box>
					<ThrottledTextInput
						value={partSearch}
						onChange={setPartSearch}
						placeholder="Search"
						icon="search"
						clearable
						fillContainer={false}
					/>
				</Box>

				<Table
					data={partsList}
					columns={columns}
					isLoading={detailsLoading}
					defaultSort={defaultSort}
					onRowClick={onRowClick}
					dataExport={exportLines}
					noDataText={partSearch ? `No parts match "${partSearch}"` : "No parts found"}
				/>
			</Card>

			{!!modalPart && (
				<InventoryPartDetailsModal
					canEdit={canEdit}
					part={modalPart}
					locationId={details.requestLocationId}
					requestId={details.requestId}
					onClose={() => setModalPart(null)}
				/>
			)}
			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</>
	)
}

type InventoryPart = InventoryRequest["parts"][number]

const defaultSort: TableProps<InventoryPart>["defaultSort"] = [
	{
		id: "costDifference",
		desc: false, // Smallest at the top, aka the biggest negative.
	},
]

const columns: Column<InventoryPart>[] = [
	{
		Header: "Part #",
		accessor: ({ partNumber }) => partNumber,
	},
	{
		Header: "Description",
		accessor: ({ partDescription }) => partDescription,
	},
	{
		Header: "Cost difference",
		id: "costDifference",
		accessor: ({ costDifference }) => costDifference,
		Cell: ({ row: { original } }: Cell<InventoryPart>) =>
			formatCurrency(original.costDifference),
		sortType: ReactTableSortType.Basic,
	},
	{
		Header: "Current qty",
		hiddenByDefault: true,
		accessor: ({ currentQuantity }) => currentQuantity,
		Cell: ({
			row: {
				original: { currentQuantity },
			},
		}: Cell<InventoryPart>) => formatNumber(currentQuantity),
		sortType: ReactTableSortType.Basic,
	},
	{
		Header: "Counted qty",
		hiddenByDefault: true,
		accessor: ({ countedQuantity }) => countedQuantity,
		Cell: ({
			row: {
				original: { countedQuantity },
			},
		}: Cell<InventoryPart>) => formatNumber(countedQuantity),
		sortType: ReactTableSortType.Basic,
	},
	{
		Header: "Qty Difference",
		accessor: ({ difference }) => difference,
		Cell: ({
			row: {
				original: { difference },
			},
		}: Cell<InventoryPart>) => formatNumber(difference),
		sortType: ReactTableSortType.Basic,
	},
	{
		Header: "Std cost (each)",
		accessor: ({ partStandardCost }) => partStandardCost,
		Cell: ({
			row: {
				original: { partStandardCost },
			},
		}: Cell<InventoryPart>) => formatCurrency(partStandardCost),
		sortType: ReactTableSortType.Basic,
		hiddenByDefault: true,
	},
]

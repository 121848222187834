import { FC, useMemo, useState } from "react"

import {
	Application,
	ApplicationAssignment,
	makeApiErrorMessage,
	useAssignUserApplications,
	useAuth,
	useIsUser,
	UserId,
	UserProfile,
	useUserProfile,
} from "@ncs/ncs-api"
import {
	ApplicationGroupSelector,
	Box,
	Button,
	CheckboxGroup,
	ExtendableModalProps,
	Label,
	Modal,
	useChangeCallback,
	useToast,
} from "@ncs/web-legos"

import { useApplicationAssignmentMatches } from "./user-management-util"

export interface EditApplicationsModalProps extends ExtendableModalProps {
	user: UserProfile
	applications: Application[]
}

interface SelectionsState {
	[id: string]: {
		application: Application
		checked: boolean
	}
}

export const EditApplicationsModal: FC<EditApplicationsModalProps> = ({
	user,
	applications: allApplications,
	...rest
}) => {
	const isAdmin = useIsUser(UserId.Admin)
	const { user: authedUser } = useAuth()
	const [authedUserProfile] = useUserProfile(authedUser?.id)
	const { makeSuccessToast } = useToast()
	const [selectedPresetId, setSelectedPresetId] = useState<string | null>(null)
	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const makeSelectionsState = (appsToSelect?: Application[]): SelectionsState => {
		return Object.fromEntries(
			allApplications.map((application) => {
				const checked =
					(appsToSelect ?? []).findIndex(
						(selectedApp) => selectedApp.id === application.id
					) !== -1

				return [
					application.id,
					{
						application,
						checked,
					},
				]
			})
		)
	}

	const [selections, setSelections] = useState<SelectionsState>(() => makeSelectionsState())

	const assignApplications = useAssignUserApplications()

	const onSave = async () => {
		try {
			setIsSaving(true)

			const data = {
				userId: user.id.toString(),
			}
			// If we have a matched preset ID, send that. Otherwise, send the selected applications.
			if (matchedPreset) {
				await assignApplications({
					...data,
					applicationAssignmentId: matchedPreset.id,
					applications: null,
				})
			} else {
				await assignApplications({
					...data,
					applicationAssignmentId: null,
					applications: selectedAppIds,
				})
			}
			makeSuccessToast("User permissions updated")
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const selectedAppIds = useMemo(() => {
		return Object.entries(selections)
			.filter(([, value]) => value.checked)
			.map(([id]) => id)
	}, [selections])

	// We need to keep the preset group dropdown in sync with what the user checks as best as possible.
	const matchedPresets = useApplicationAssignmentMatches(selectedAppIds)

	// From the matched presets, do some logic to pick out just one that makes the most sense.
	const matchedPreset: ApplicationAssignment | null = useMemo(() => {
		// If nothing matched, return null
		if (!matchedPresets.length) return null

		// If the currently dropdown menu selection is amongst the presets, do grab that one.
		const dropdownMatch = matchedPresets.find((preset) => preset.id === selectedPresetId)
		if (dropdownMatch) return dropdownMatch

		// If the user's current group is one of the ones matched, return it. This way we're choosing
		// to not change the group if possible.
		const currentUserGroupId = user.applicationsGroup?.id.toString() ?? null
		if (currentUserGroupId) {
			const match = matchedPresets.find((preset) => preset.id === currentUserGroupId)
			if (match) return match
		}

		// Otherwise, put any defaults in front of non-defaults and then take the first in the list.
		return matchedPresets.sort((a, b) => (a.isDefault && !b.isDefault ? -1 : 1))[0]
	}, [matchedPresets, user.applicationsGroup?.id, selectedPresetId])

	useChangeCallback(matchedPreset, (newMatch) => setSelectedPresetId(newMatch?.id ?? null))

	const permissionsLookup = useMemo(() => {
		return Object.fromEntries(
			(authedUserProfile?.applicationsGroup?.applications ?? []).map((app) => [
				app.application.id,
				true,
			])
		)
	}, [authedUserProfile?.applicationsGroup?.applications])

	const handleSelectGroup = (assignment: ApplicationAssignment | null) => {
		setSelectedPresetId(assignment?.id ?? null)
		if (assignment) {
			setSelections(makeSelectionsState(assignment?.applications))
		}
	}

	const handleClear = () => {
		setSelections(makeSelectionsState())
	}

	const onOpen = () => {
		setErrorText(null)
		setIsSaving(false)

		// Set selections to what the user's current applications are, if any.
		// Note that this triggers state change flows all the way down to setting
		// the dropdown menu, if possible.
		setSelections(
			makeSelectionsState(
				user.applicationsGroup?.applications.map(
					(assignedApplication) => assignedApplication.application
				)
			)
		)
	}

	return (
		<Modal
			{...rest}
			maxWidth="md"
			onOpen={onOpen}
			title="Edit Application Permissions"
			errorText={errorText}
			stickyTopContent={
				<Box display="flex" justifyContent="space-between" columnGap={1}>
					<ApplicationGroupSelector
						value={selectedPresetId}
						onChange={(id, option) => handleSelectGroup(option ?? null)}
						label="Application group preset"
					/>
					<Button onClick={handleClear}>Clear selections</Button>
				</Box>
			}
			rightButtons={{
				buttonText: "Save Changes",
				isLoading: isSaving,
				onClick: onSave,
			}}
		>
			<Box my={1}>
				<Label>Application permissions you are allowed to edit for this user:</Label>
			</Box>
			<CheckboxGroup
				rows={Object.values(selections).sort((a, b) =>
					a.application.description > b.application.description ? 1 : -1
				)}
				valueAccessor={(row) => row.application.id}
				labelAccessor={(row) => row.application.description}
				checkedAccessor={(row) => row.checked}
				hiddenAccessor={
					isAdmin ? undefined : (row) => permissionsLookup[row.application.id] !== true
				}
				onChange={(row, newState) => {
					setSelections((prev) => ({
						...prev,
						[row.application.id]: {
							...prev[row.application.id],
							checked: newState,
						},
					}))
				}}
				fillContainer
				columnCounts={{ md: 3, sm: 2, xs: 1 }}
			/>
		</Modal>
	)
}

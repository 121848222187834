import React from "react"
import { generateLink, getReportExport, patchReportExport } from "../../redux/services/reports"
import { default as OGDynamicTable } from "@ncs/bricks/components/Table/DynamicTable"

class DynamicTable extends React.PureComponent {
	render = () => (
		<OGDynamicTable
			actionProps={{
				generateLink,
				getReportExport,
				patchReportExport,
			}}
			{...this.props}
		/>
	)
}

DynamicTable.propTypes = OGDynamicTable.propTypes

export default DynamicTable

import React from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import callApi from "@ncs/bricks/redux/services/callApi"
import { BaseSelectFilter, withDynamicTableFilter } from "@ncs/bricks/components"
import { loadLookups, LOOKUPS } from "../../redux/services/lookups"

class LocationFilter extends React.PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			locations: [],
		}
	}

	componentDidMount() {
		this.props.loadLookups([LOOKUPS.Locations], this.filterLocations)
	}

	// filter locations whenever the territory changes
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (
			this.getTerritoryFromReportState(prevProps) !==
			this.getTerritoryFromReportState(this.props)
		) {
			this.filterLocations()
		}
	}

	getTerritoryFromReportState = (props) => {
		return (((props.reportState || {}).filtered || []).find((x) => x.id === "territory") || {})
			.value
	}

	filterLocations = () => {
		let territory = this.getTerritoryFromReportState(this.props)

		let locations = []
		if (territory) {
			locations = this.props.locations.filter((x) => x.territory_id === Number(territory))
		} else {
			locations = this.props.locations
		}
		this.setState({ locations })

		// if the currently selected location is not in the results, erase the selected location
		if (this.props.value && !locations.some((t) => t._id.toString() === this.props.value)) {
			this.props.onChange("")
		}
	}

	render() {
		return (
			<BaseSelectFilter
				id="location"
				labelText="Location"
				options={[{ _id: "", description: "All Locations" }, ...this.state.locations]}
				valueAccessor="_id"
				textAccessor={(x) =>
					x._id ? `${x.description} (${x.location_code})` : x.description
				}
				{...this.props}
			/>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		locations: state.lookups.locations,
	}
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ callApi, loadLookups }, dispatch)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withDynamicTableFilter("location")(LocationFilter))

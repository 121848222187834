import { z } from "zod"

import {
	AppliedPromotions,
	CreatePartOrderPost,
	GenericLineItem,
	PartOrderLineItem,
} from "@ncs/ncs-api"
import { addBusinessDays, extractNumber } from "@ncs/ts-utils"

export const CreatePartOrderFormSchema = z
	.object({
		siteId: z.string().min(1, "Required"),
		billToId: z.string().min(1, "Required"),
		contactPhone: z.string().min(1, "Required"),
		shipmentMethodId: z.string().min(1, "Required"),
		purchaseOrder: z.string().optional(),
		onlinePurchase: z.boolean(),
		invoiceComment: z.string().nullable(), // This comment will go on the invoice to the customer.
		alternateAddressId: z.string().nullable(),
		isInternationalAddress: z.boolean(),
		isHold: z.boolean(),
		targetDeliveryDate: z.string().min(1, "Required"),
		holder: z.string().nullable(),
		internalComment: z.string().nullable(),

		// Shipping services that are NOT accessorials.
		machineDown: z.boolean(),
		shipComplete: z.boolean(),
	})
	.refine((data) => data.isHold === false || !!data.holder, {
		message: "Choose who can remove the hold on the order",
		path: ["holder"],
	})

export type CreatePartOrderForm = z.infer<typeof CreatePartOrderFormSchema>

export const defaultCreatePartOrderFormValues: CreatePartOrderForm = {
	alternateAddressId: null,
	isInternationalAddress: false,
	billToId: "",
	contactPhone: "",
	holder: null,
	isHold: false,
	machineDown: false,
	onlinePurchase: false,
	shipComplete: false,
	shipmentMethodId: "",
	siteId: "",
	targetDeliveryDate: addBusinessDays(3).toISOString(),
	internalComment: null,
	invoiceComment: null,
}

/** Map a `GenericLineItem` to a `PartOrderPostLineItem`. */
export const lineItemToPartOrderPostLineItem = (
	line: GenericLineItem
): CreatePartOrderPost["lineItems"][number] => {
	const unitTax = line.finalPrice * line.taxRate

	const partOrderPostLineItem: CreatePartOrderPost["lineItems"][number] = {
		lineTypeId: line.lineTypeId,
		partId: line.part?.id ?? null,
		description: line.description,
		unitPrice: line.finalPrice.toFixed(4),
		unitTax: unitTax.toFixed(7),
		taxRateUsed: line.taxRate.toFixed(7),
		quantity: line.quantity,
		overridePermission: null,
		reasonId: line.priceOverrideReason?.id ?? null,
		reasonComment: null,
		overrideAmount: null,
	}

	return partOrderPostLineItem
}

/** Map `PartOrderPostLineItem` a to a`GenericLineItem` . */
export const partOrderPostLineItemToLineItem = (line: PartOrderLineItem): GenericLineItem => {
	const quantity = extractNumber(line.quantity)
	const finalPrice = extractNumber(line.unitPrice)
	const unitTax = extractNumber(line.unitTax)
	const taxRate = finalPrice !== 0 ? unitTax / finalPrice : 0

	const lineDescription = (l: PartOrderLineItem): string => {
		if (l.part) {
			return l.part?.description
		} else if (l.overridePermission) {
			return l.overridePermission?.description
		} else {
			return ""
		}
	}

	const genericLineItem: GenericLineItem = {
		id: String(line.id),
		lineTypeId: line.lineTypeId,
		part:
			line.part ?
				{
					id: line.part.id,
					partNumber: line.part.partNumber,
					partFamily: line.part.partFamily,
					description: line.part.description,
					weight: line.part.weight != null ? extractNumber(line.part.weight) : null,
				}
			:	null,
		description: lineDescription(line),
		quantity: Number(line.quantity),
		finalPrice,
		basePrice: null,
		taxRate,
		subtotal: quantity * finalPrice,
		priceOverrideReason: null,
		reasonComment: null,
		overridePermission: null,
		requestedPrice: null,
		systemGeneratedLine: false,
		originalSystemGeneratedPrice: null,
	}

	return genericLineItem
}
/**
 * Take the data we've collected in its various forms from the create order form and
 * piece it together to be sent to the create part order endpoint.
 */
export const assemblePartOrderPost = (
	formData: CreatePartOrderForm,
	lineItems: GenericLineItem[],
	appliedPromotions: AppliedPromotions[]
): CreatePartOrderPost => {
	const postLineItems: CreatePartOrderPost["lineItems"] = lineItems.map((line) => {
		const unitTax = line.finalPrice * line.taxRate

		const partOrderPostLineItem: CreatePartOrderPost["lineItems"][number] = {
			lineTypeId: line.lineTypeId,
			partId: line.part?.id ?? null,
			description: line.description,
			unitPrice: line.finalPrice.toFixed(4),
			unitTax: unitTax.toFixed(7),
			taxRateUsed: line.taxRate.toFixed(7),
			quantity: line.quantity,
			overridePermission: line.overridePermission?.id ?? null,
			overrideAmount: line.requestedPrice, // Needed whether approval is required or not
			reasonId: line.priceOverrideReason?.id ?? null,
			reasonComment: line.reasonComment,
		}

		return partOrderPostLineItem
	})

	const partOrderPost: CreatePartOrderPost = {
		comment: formData.invoiceComment ?? null,
		shipToCustomerId: formData.siteId,
		billToCustomerId: formData.billToId,
		contactPhone: formData.contactPhone,
		purchaseOrderNumber: formData.purchaseOrder ?? null,
		isMachineDown: formData.machineDown,
		shipMethodId: formData.shipmentMethodId,
		lineItems: postLineItems,
		shipComplete: formData.shipComplete,
		sourceId: 9, // Hardcode to Kittycat
		alternateAddressId: formData.alternateAddressId,
		useAlternateAddress: !!formData.alternateAddressId,
		targetDeliveryDate: formData.targetDeliveryDate,
		onHold: formData.isHold,
		holders: formData.isHold && formData.holder ? [formData.holder] : null,
		onlinePurchase: formData.onlinePurchase,
		appliedPromotions: appliedPromotions.map((promo) => promo.id),
		internalComment: formData.internalComment,

		// Unused.
		paymentMethodId: null,
		squarePaymentId: null,
		inventoryLocationId: null,
	}

	return partOrderPost
}

/**
 * Look through the list of promotions and add up all the discounts for a part line item.
 */
export const getLineItemDiscounts = (
	line: GenericLineItem,
	promotions: AppliedPromotions[]
): number => {
	if (!line.part) {
		return 0
	}

	return promotions.reduce((total, promo) => {
		const matchingPromoLines = promo.lineItems.filter(
			(promoLine) => promoLine.partId === line.part?.id
		)
		const promoDiscounts = matchingPromoLines.reduce((promoTotal, promoLine) => {
			return promoTotal + promoLine.discount
		}, 0)

		return total + promoDiscounts
	}, 0)
}

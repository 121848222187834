import "@ncs/bricks/assets/scss/material-dashboard-pro-react.css?v=1.9.0"

import React from "react"

import DayJsUtils from "@date-io/dayjs"
import { Global, ThemeProvider as EmotionThemeProvider } from "@emotion/react"
import { StylesProvider, ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import PropTypes from "prop-types"
import { HelmetProvider } from "react-helmet-async"
import { Provider as ReduxProvider } from "react-redux"
import { Router } from "react-router-dom"

import { ClientVersionHeader, NcsApiProvider } from "@ncs/ncs-api"
import { ToastProvider, UrlStateProvider } from "@ncs/web-legos"

import { persistStoreWithCallback } from "~/store"
import { globalStyles, theme } from "~/styles"
import { config } from "~/util"

import LoginRedirectionRouter from "./LoginRedirectionRouter"

class AppProvider extends React.Component {
	constructor() {
		super()
		this.state = { rehydrated: false }
	}

	componentDidMount = () => {
		persistStoreWithCallback(this.props.store, () => this.setState({ rehydrated: true }))
	}

	render() {
		if (!this.state.rehydrated) {
			return <div>Loading...</div>
		}

		return (
			<HelmetProvider>
				<MuiThemeProvider theme={theme}>
					<EmotionThemeProvider theme={theme}>
						<MuiPickersUtilsProvider utils={DayJsUtils}>
							<Global styles={globalStyles} />
							<StylesProvider injectFirst>
								<Router history={this.props.history}>
									<NcsApiProvider
										clientVersionHeader={ClientVersionHeader.Internal}
										isDev={config.IS_DEV}
									>
										<UrlStateProvider>
											<ReduxProvider store={this.props.store}>
												<ToastProvider>
													<LoginRedirectionRouter />
												</ToastProvider>
											</ReduxProvider>
										</UrlStateProvider>
									</NcsApiProvider>
								</Router>
							</StylesProvider>
						</MuiPickersUtilsProvider>
					</EmotionThemeProvider>
				</MuiThemeProvider>
			</HelmetProvider>
		)
	}
}

AppProvider.propTypes = {
	store: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
}

export default AppProvider

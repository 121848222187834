import { FC } from "react"

import { Cell, Column } from "react-table"

import { InventoryAllocation, useInventoryAllocations, useInventoryPart } from "@ncs/ncs-api"
import {
	Box,
	EmptyValueDash,
	ExtendableModalProps,
	LabeledData,
	Link,
	Modal,
	Table,
} from "@ncs/web-legos"

export interface AllocationsModalProps extends ExtendableModalProps {
	locationId: string
	partId: string
}

const columns: Column<InventoryAllocation>[] = [
	{
		Header: "Bin code",
		accessor: ({ locationInventory }) => locationInventory.bin.code,
	},
	{
		Header: "Quantity",
		accessor: "quantity",
	},
	{
		Header: "Part order #",
		id: "part-order",
		Cell: ({ row: { original } }: Cell<InventoryAllocation>) => {
			return original.partOrderLine ?
					<Link
						newTab
						icon="external-link"
						to={`/part-orders/${original.partOrderLine.partOrder.id}`}
					>
						#{original.partOrderLine.partOrder.orderId}
					</Link>
				:	<EmptyValueDash />
		},
	},
	{
		Header: "Ship to",
		accessor: ({ partOrderLine }) =>
			partOrderLine ?
				`(${partOrderLine.partOrder.shipToCustomer.customerNumber}) ${partOrderLine.partOrder.shipToCustomer.name}`
			:	"",
	},
]

export const AllocationsModal: FC<AllocationsModalProps> = ({ locationId, partId, ...rest }) => {
	const [partDetails, partDetailsLoading] = useInventoryPart(partId)
	const [allocations, allocationsLoading] = useInventoryAllocations({
		params: {
			location: locationId,
			part: partId,
		},
	})

	return (
		<Modal title="Part Allocations" {...rest}>
			<Box display="flex" columnGap={2}>
				<LabeledData label="Part #" isLoading={partDetailsLoading}>
					{partDetails?.partNumber}
				</LabeledData>
				<LabeledData label="Name" isLoading={partDetailsLoading}>
					{partDetails?.description}
				</LabeledData>
			</Box>

			<Table
				data={allocations ?? []}
				isLoading={allocationsLoading}
				columns={columns}
				disableAllSorting
			/>
		</Modal>
	)
}

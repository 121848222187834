import { FC, useMemo, useState, useCallback } from "react"

import { css } from "@emotion/react"
import { useHistory, useLocation, useParams } from "react-router-dom"

import {
	APPLICATION,
	createDocumentToken,
	getDocumentUrlForToken,
	InvoiceStatusId,
	makeApiErrorMessage,
	PartOrderListQueryParams,
	PartOrderStatusId,
	usePartOrder,
	usePartOrderDocuments,
	usePartOrderList,
	usePrefetchWorkOrderLineItemTypes,
	useRecreatePartOrderDocuments,
	useUpdatePartOrder,
	useUpdatePartOrderInvoice,
	useUpdatePartOrderApproveAdditionalEntry,
	useUploadPartOrderAttachment,
	useUpdatePartOrderLineItem,
	useUserCanUse,
	PartOrderLineItem,
	useWorkOrderInvoice,
} from "@ncs/ncs-api"
import {
	DateFormat,
	formatCurrency,
	formatDateTime,
	getTimezoneAbbreviation,
	isEnumMember,
	tryToFormatPhone,
	yesOrNo,
} from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	Box,
	BoxProps,
	Button,
	Callout,
	Card,
	ConfirmationModal,
	ConfirmationModalConfig,
	Divider,
	EmptyValueDash,
	encodeUrlState,
	FileInput,
	getAddressFields,
	GridContainer,
	GridItem,
	GridItemProps,
	Heading,
	HeadingDivider,
	Icon,
	Label,
	LabeledData,
	LoadingSpinner,
	Paragraph,
	ParagraphList,
	Pipe,
	ShippingMethod,
	shippingMethods,
	SkeletonRows,
	StatusBlip,
	useIsSaving,
	useScreenSizeMatch,
	useScrollToTopOnMount,
	useToast,
} from "@ncs/web-legos"

import { PrintLinePartNumbersModal } from "~/components"
import { PageTitle } from "~/layouts/PageTitle"
import { allowInvoiceFinalize } from "~/util"
import {
	getLineLocationPickListCreationStatus,
	partOrderAttachmentFileTypes,
} from "~/util/part-orders"

import { PartOrdersViewOrdersTabUrlState } from "../PartOrders/components"
import {
	ChangeDcRoutingModal,
	EditBillToShipToModal,
	EditPartOrderModal,
	PartOrderDetailLineItems,
	PartOrderDetailPickLists,
	PartOrderDetailShipments,
	PlaceHoldModal,
	RemoveHoldModal,
} from "./components"
import {
	FulfillmentSources,
	getFulfillmentSources,
	PendingFieldApprovalInvoiceStatusCode,
	usePartOrderPermissions,
} from "./part-order-detail-util"

type CopyPartOrderUrlState = {
	tab: string
	partOrderId: string
}

export const PartOrderDetail: FC = () => {
	useScrollToTopOnMount()
	usePrefetchWorkOrderLineItemTypes()
	const screenIsXs = useScreenSizeMatch("xs")
	const { makeSuccessToast, makeErrorToast } = useToast()
	const history = useHistory()
	const updateLineItem = useUpdatePartOrderLineItem()
	const location = useLocation<{
		partOrderSearchQueryParams?: Partial<PartOrderListQueryParams>
	}>()
	const { partOrderId } = useParams<{ partOrderId?: string }>()
	if (!partOrderId) throw new Error("part order ID not found in URL")

	const [partOrder, partOrderLoading] = usePartOrder(partOrderId)

	const [workOrderInvoice] = useWorkOrderInvoice(partOrder?.invoice[0]?.id)

	const partOrderListQuery = usePartOrderList({
		params: { search: partOrder?.orderId.toString() || "" },
		queryConfig: { enabled: !!partOrder?.orderId },
	})
	const partOrderListResult = useMemo(() => {
		return partOrderListQuery.data.find((d) => d.orderId === partOrder?.orderId)
	}, [partOrder?.orderId, partOrderListQuery.data])
	const [documents, documentsLoading] = usePartOrderDocuments(partOrderId)
	const recreateDocuments = useRecreatePartOrderDocuments(partOrderId)
	const updateInvoice = useUpdatePartOrderInvoice(partOrderId)
	const approveAdditionalEntry = useUpdatePartOrderApproveAdditionalEntry(
		partOrderId,
		workOrderInvoice?.id
	)
	const uploadAttachment = useUploadPartOrderAttachment()
	const updateOrder = useUpdatePartOrder(partOrderId)

	const [showEditBillShipModal, setShowEditBillShipModal] = useState(false)
	const [isExpandedView, setIsExpandedView] = useState(true)
	const [showEditModal, setShowEditModal] = useState(false)
	const [showPlaceHoldModal, setShowPlaceHoldModal] = useState(false)
	const [confirmationModalConfig, setConfirmationModalConfig] =
		useState<ConfirmationModalConfig | null>(null)
	const [documentKeyBeingFetch, setDocumentKeyBeingFetched] = useState<string | null>(null)
	const { isSaving, setSaving, endSaving } = useIsSaving<
		"recreating-documents" | "adding-attachment"
	>()
	const [showChangeDcModal, setShowChangeDcModal] = useState(false)
	const [showPrintModal, setShowPrintModal] = useState(false)
	const [showRemoveHoldModal, setShowRemoveHoldModal] = useState(false)

	const partOrderListQueryLoading = partOrderLoading || partOrderListQuery.isLoading

	const canFinalize = useMemo(() => {
		if (!workOrderInvoice) return false
		return allowInvoiceFinalize(workOrderInvoice)
	}, [workOrderInvoice])

	const handleSelectAttachment = async (file: File) => {
		try {
			if (!partOrder) throw new Error("No part order found")

			setSaving("adding-attachment")
			await uploadAttachment(file, partOrder.id)
			makeSuccessToast("Document attached")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		} finally {
			endSaving("adding-attachment")
		}
	}

	const handleApproveAdditionalEntry = async (invoiceId: string) => {
		try {
			await approveAdditionalEntry({
				updates: {
					invoiceId,
				},
			})
			makeSuccessToast("Additional Entry approved")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		}
	}

	const recalculateLineItem = useCallback(
		async (lineItem: PartOrderLineItem) => {
			try {
				// You should only be recalculating if there is a location on the line item.
				const locationId = lineItem.locationLines[0]?.locationId ?? null

				await updateLineItem({
					id: lineItem.id,
					updates: {
						recalc: true,
						oldWarehouseId: locationId,
					},
				})
			} catch (e) {
				makeErrorToast(makeApiErrorMessage(e))
			}
		},
		[makeErrorToast, updateLineItem]
	)

	const handleRecalculateInvoice = async () => {
		partOrder?.lineItems.forEach(async (lineItem) => {
			await recalculateLineItem(lineItem)
		})
	}

	const handleApproveInvoice = async (invoiceId: string) => {
		try {
			await updateInvoice({
				updates: {
					invoiceId,
					approve: true,
					lineItem: null,
					addInvoiceLineItem: null,
				},
			})
			makeSuccessToast("Invoice approved")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		}
	}

	const handleSubmitOrder = async () => {
		try {
			await updateOrder({
				updates: { submit: true },
				id: partOrderId,
			})
			makeSuccessToast("Order placed")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		}
	}

	const handleCancelOrder = async () => {
		try {
			await updateOrder({
				updates: { cancel: true },
				id: partOrderId,
			})
			makeSuccessToast("Order canceled")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		}
	}

	const handleViewDocument = async (
		documentId: number,
		documentTypeId: number,
		documentIndex: number
	) => {
		try {
			setDocumentKeyBeingFetched(makeDocumentKey(documentId, documentIndex))
			const { token } = await createDocumentToken(documentId, documentTypeId)
			const url = getDocumentUrlForToken(token)
			window.open(url, "_blank")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		} finally {
			setDocumentKeyBeingFetched(null)
		}
	}

	const handleRecreateDocuments = async () => {
		try {
			setSaving("recreating-documents")
			await recreateDocuments()
			makeSuccessToast("Documents created")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		} finally {
			endSaving("recreating-documents")
		}
	}

	const fulfillmentSources = useMemo((): FulfillmentSources => {
		return partOrder?.lineItems ?
				getFulfillmentSources(partOrder.lineItems)
			:	{
					locations: {},
					orders: {},
				}
	}, [partOrder?.lineItems])

	const headingDetail = useMemo(() => {
		const details = []

		if (partOrder) {
			if (partOrder.createdDate) {
				details.push(
					`Created ${formatDateTime(
						partOrder.createdDate
					)} (${getTimezoneAbbreviation()})`
				)
			}
			details.push(`Order # ${partOrder.orderId}`)
			if (partOrder.kbmOrderNumber) {
				details.push(`KBM Order # ${partOrder.kbmOrderNumber}`)
			}
			if (partOrder.purchaseOrderNumber) {
				details.push(`Customer Purchase Order: ${partOrder.purchaseOrderNumber}`)
			}
		}

		return details
	}, [partOrder])

	const {
		canEdit,
		canEditLineItems,
		canApproveOrder,
		canCancel,
		needsPoForApproval,
		canApproveInvoice,
		canEditHold,
		isOpen,
		paymentNotComplete,
	} = usePartOrderPermissions(partOrder)
	const hasFinanceSuperUser = useUserCanUse(APPLICATION.FinanceSuperUser)

	return (
		<>
			<PageTitle title={partOrder ? `Part Order # ${partOrder.orderId}` : "Part Order"} />

			<Button
				icon="long-arrow-left"
				containerProps={{ mb: 3 }}
				onClick={() =>
					history.push({
						pathname: "/part-orders",
						search: encodeUrlState<PartOrdersViewOrdersTabUrlState>(
							location.state?.partOrderSearchQueryParams
						),
					})
				}
				buttonText="All part orders"
			/>

			<Card
				heading={partOrder ? `Part Order # ${partOrder.orderId}` : "Part Order"}
				headingIcon="truck"
				headingDetail={headingDetail}
				renderRight={() => {
					return (
						<>
							<Button
								variant="secondary-cta"
								disabled={partOrderLoading}
								onClick={() =>
									history.push({
										pathname: "/part-orders",
										search: encodeUrlState<CopyPartOrderUrlState>({
											tab: "New Part Order",
											partOrderId: partOrderId,
										}),
									})
								}
							>
								Copy Order
							</Button>
						</>
					)
				}}
			>
				{canApproveInvoice && (
					<>
						<Heading variant="h5" mb={1}>
							Invoices Awaiting Approval
						</Heading>
						{needsPoForApproval && (
							<Callout variant="info">
								Customer purchase order number is required before invoice can be
								approved
							</Callout>
						)}

						<Box mb={2}>
							{partOrder?.invoice
								.filter(
									(invoice) =>
										invoice.statusCode ===
										PendingFieldApprovalInvoiceStatusCode
								)
								.map((invoice, i) => (
									<Box
										key={invoice.id + i}
										display="flex"
										alignItems="center"
										gap={1}
										mb={1}
									>
										<Button
											variant="primary-cta"
											disabled={needsPoForApproval}
											icon="check"
											onClick={async () => {
												await handleRecalculateInvoice()
												setConfirmationModalConfig({
													title: `Approve Invoice # ${invoice.invoiceNumber}`,
													message: `Confirm: Approve this invoice?`,
													onConfirm: () =>
														handleApproveInvoice(invoice.id),
												})
											}}
										>
											Approve
										</Button>
										<Paragraph small secondary>
											Invoice # {invoice.invoiceNumber}
										</Paragraph>
										<Pipe m={0} />
										<Paragraph small secondary>
											Total: {formatCurrency(invoice.total)}
										</Paragraph>
									</Box>
								))}
						</Box>
					</>
				)}

				{canApproveOrder && (
					<Box {...primaryActionBoxProps}>
						<Button
							buttonText="Approve & Place Order"
							variant="primary-cta"
							icon="check"
							fillContainer
							onClick={() =>
								setConfirmationModalConfig({
									title: "Approve & Place Order",
									message:
										"Are you sure you want to approve and place this order?",
									onConfirm: handleSubmitOrder,
								})
							}
						/>
					</Box>
				)}

				{partOrder?.status.id === PartOrderStatusId.Hold && canEditHold && (
					<Box maxWidth={40} mb={4}>
						<Heading variant="h5" mb={2} bold={false}>
							This order is on hold. Most edits can be still be made but the order
							cannot be progressed until the hold is removed.
						</Heading>
						<Button variant="primary-cta" onClick={() => setShowRemoveHoldModal(true)}>
							Remove Hold On Order
						</Button>
					</Box>
				)}

				{canFinalize && workOrderInvoice?.status === InvoiceStatusId.AdditionalEntry && (
					<Box maxWidth={20} mb={4}>
						<Button
							variant="primary-cta"
							icon="check"
							onClick={() => handleApproveAdditionalEntry(workOrderInvoice.id)}
						>
							Send Invoice
						</Button>
					</Box>
				)}

				{/* Above-the-fold details */}
				<GridContainer>
					<GridItem {...gridItemProps}>
						<HeadingDivider headingVariant="h5" mt={0} mb={0.5}>
							Summary
						</HeadingDivider>
						{partOrder?.status.id !== PartOrderStatusId.Invoiced &&
							partOrder?.invoice && (
								<Callout
									mb={0.5}
									ml={-0.4}
									px={0.9}
									py={0.6}
									isLoading={partOrderLoading}
									loadingRowCount={1}
									noBorder
								>
									<Paragraph>
										<strong>Order Status: </strong>
										{partOrder?.status?.description}
									</Paragraph>

									{paymentNotComplete && (
										<Box d="flex" gap={0.5} mt={1}>
											<Icon icon="exclamation-triangle" color="primary" />
											<Paragraph mt={-0.25}>
												Order cannot progress until payment is completed
											</Paragraph>
										</Box>
									)}
								</Callout>
							)}

						{partOrder?.invoice.map((invoice) => (
							<Box key={invoice.invoiceNumber}>
								<Heading variant="h6" mt={0.5} mb={0.25}>
									Invoice # {invoice.invoiceNumber}
								</Heading>
								<Paragraph>Total: {formatCurrency(invoice.total)}</Paragraph>
								<Paragraph>
									Invoice status: {invoice.statusCode || <EmptyValueDash />}
								</Paragraph>
								<Divider my={1} />
							</Box>
						))}
						<ParagraphList
							isLoading={partOrderLoading}
							lines={[
								`Subtotal (est.):  ${formatCurrency(partOrder?.subTotal ?? 0)}`,
								`Tax (est.):  ${formatCurrency(partOrder?.taxTotal ?? 0)}`,
								`Total (est.):  ${formatCurrency(partOrder?.total ?? 0)}`,
							]}
						/>
					</GridItem>

					<GridItem {...gridItemProps}>
						<HeadingDivider headingVariant="h5" mt={0} mb={0.5}>
							Fulfillment Actions
						</HeadingDivider>
						{partOrderListQueryLoading && (
							<Box maxWidth="50%">
								<SkeletonRows rows={2} />
							</Box>
						)}
						{!partOrderListQueryLoading && (
							<>
								{partOrderListResult?.locations.map((l, i) => {
									const pickListCreationStatus =
										getLineLocationPickListCreationStatus(l)

									return (
										<Box key={l.locationId + i} mb={0.5}>
											<Paragraph>
												({l.locationNumber}) {l.locationName}
											</Paragraph>
											{pickListCreationStatus && (
												<Box display="flex" gap={0.5} alignItems="center">
													<StatusBlip
														status={pickListCreationStatus[0]}
													/>
													<Paragraph small secondary>
														{pickListCreationStatus[1]}
													</Paragraph>
												</Box>
											)}
											{l.picking && (
												<Box display="flex" gap={0.5} alignItems="center">
													<Icon icon="person-dolly" />
													<Paragraph small secondary>
														Waiting on pick list completion
													</Paragraph>
												</Box>
											)}
											{l.shipping && (
												<Box display="flex" gap={0.5} alignItems="center">
													<Icon icon="box-check" />
													<Paragraph small secondary>
														Waiting on shipment of items
													</Paragraph>
												</Box>
											)}
										</Box>
									)
								})}
								{!partOrderListQueryLoading &&
									!partOrderListResult?.locations.length && (
										<Paragraph small secondary mb={0.5}>
											No fulfillment actions needed
										</Paragraph>
									)}
								{!partOrderListQueryLoading &&
									!!partOrder?.lineItems.some((lineItem) => {
										return lineItem.locationLines.some(
											(locationLine) => locationLine.quantityRequested
										)
									}) && (
										<Button
											icon="pencil"
											onClick={() => setShowChangeDcModal(true)}
											containerProps={{ mt: 0.5 }}
										>
											Change DC routing
										</Button>
									)}
							</>
						)}
					</GridItem>

					<GridItem {...gridItemProps}>
						<HeadingDivider headingVariant="h5" mt={0} mb={0.5}>
							Customer Ship To
						</HeadingDivider>
						{partOrder?.targetDeliveryDate ?
							<Callout mb={0.5} ml={-0.25} px={0.9} py={0.6} noBorder iconSize={1}>
								<Paragraph>
									<strong>Target Shipping Date: </strong>
									{formatDateTime(partOrder.targetDeliveryDate, DateFormat.Date)}
								</Paragraph>
							</Callout>
						:	null}
						{partOrder?.alternateAddress ?
							<>
								{!!partOrder?.alternateAddress && (
									<Callout
										mb={0.5}
										ml={-0.25}
										noBorder
										icon="house-circle-exclamation"
										variant="warning"
										px={0.9}
										py={0.6}
									>
										<Paragraph>Customer provided alternate address</Paragraph>
									</Callout>
								)}
								<ParagraphList
									lines={[
										partOrder.alternateAddress.description,
										...getAddressFields(partOrder.alternateAddress),
									]}
									loadingRowCount={3}
								/>
							</>
						:	<ParagraphList
								isLoading={partOrderLoading}
								lines={[
									`(${partOrder?.shipToCustomer?.customerNumber}) ${partOrder?.shipToCustomer?.name}`,
									...getAddressFields(partOrder?.shipToCustomer, {
										exclude: "name",
									}),
								]}
								loadingRowCount={4}
							/>
						}
						{!!partOrder?.contactPhone && (
							<Paragraph small>
								<span css={detailLabelCss}>Delivery Tel:</span>{" "}
								{tryToFormatPhone(partOrder.contactPhone)}
							</Paragraph>
						)}
						{!!partOrder?.shipToCustomer.phone && (
							<Paragraph small>
								<span css={detailLabelCss}>Customer Tel:</span>{" "}
								{tryToFormatPhone(partOrder.shipToCustomer.phone)}
							</Paragraph>
						)}
						{!!partOrder?.shipToCustomer.territory && (
							<Paragraph small>
								<span css={detailLabelCss}>Territory:</span> (
								{partOrder.shipToCustomer.territory.code}){" "}
								{partOrder.shipToCustomer.territory.description}
							</Paragraph>
						)}
					</GridItem>
					<GridItem {...gridItemProps}>
						<HeadingDivider headingVariant="h5" mt={0} mb={0.5}>
							Customer Bill To
						</HeadingDivider>
						<ParagraphList
							isLoading={partOrderLoading}
							lines={[
								`(${partOrder?.billToCustomer.customerNumber}) ${partOrder?.billToCustomer.name}`,
								...getAddressFields(partOrder?.billToCustomer, {
									exclude: "name",
								}),
							]}
							loadingRowCount={3}
						/>
						{!!partOrder?.billToCustomer.phone && (
							<Paragraph small>
								<span css={detailLabelCss}>Customer Tel:</span>{" "}
								{tryToFormatPhone(partOrder.billToCustomer.phone)}
							</Paragraph>
						)}
						{!!partOrder?.billToCustomer.territory && (
							<Paragraph small>
								<span css={detailLabelCss}>Territory:</span> (
								{partOrder.billToCustomer.territory.code}){" "}
								{partOrder.billToCustomer.territory.description}
							</Paragraph>
						)}
					</GridItem>
				</GridContainer>

				{/* Additional details */}
				<AnimatedEntrance show={isExpandedView} direction="down">
					<Divider mt={3} />
					<GridContainer mt={2}>
						<GridItem xs={12} sm={6} md={6} lg={9}>
							<GridContainer>
								<GridItem md={6} lg={4}>
									<LabeledData label="Machine down">
										{yesOrNo(partOrder?.isMachineDown) || <EmptyValueDash />}
									</LabeledData>
								</GridItem>
								<GridItem md={6} lg={4}>
									<LabeledData label="Ship complete">
										{yesOrNo(partOrder?.shipComplete) || <EmptyValueDash />}
									</LabeledData>
								</GridItem>
								<GridItem md={6} lg={4}>
									<LabeledData label="Customer online order">
										{yesOrNo(partOrder?.onlinePurchase) || <EmptyValueDash />}
									</LabeledData>
								</GridItem>
								<GridItem md={6} lg={4}>
									<LabeledData label="Full truck" isLoading={partOrderLoading}>
										{yesOrNo(partOrder?.fullTruck) || <EmptyValueDash />}
									</LabeledData>
								</GridItem>
								<GridItem md={6} lg={4}>
									<LabeledData label="Ship method">
										{!!partOrder && (
											<>
												{partOrder.shipMethod.description}
												{isEnumMember(
													partOrder.shipMethod.id,
													ShippingMethod
												) &&
													` (${
														shippingMethods[partOrder.shipMethod.id]
													})`}
											</>
										)}
									</LabeledData>
								</GridItem>
								<GridItem md={6} lg={4}>
									<Label>Created by</Label>
									<ParagraphList
										isLoading={partOrderLoading}
										loadingRowCount={2}
										lines={[
											partOrder?.createdBy?.name,
											partOrder?.createdBy?.email,
											partOrder?.createdBy?.mobile ?
												tryToFormatPhone(partOrder.createdBy.mobile)
											:	undefined,
										]}
									/>
								</GridItem>
								<GridItem md={6} lg={4}>
									<LabeledData label="Delivery contact phone">
										{partOrder?.contactPhone ?
											tryToFormatPhone(partOrder.contactPhone)
										:	<EmptyValueDash />}
									</LabeledData>
								</GridItem>
								<GridItem md={12} lg={4}>
									<LabeledData label="Invoice comments">
										{partOrder?.comment || <EmptyValueDash />}
									</LabeledData>
								</GridItem>
								<GridItem md={12} lg={4}>
									<LabeledData label="Internal comments">
										{partOrder?.internalComment || <EmptyValueDash />}
									</LabeledData>
								</GridItem>
							</GridContainer>

							{/* Documents */}
							<HeadingDivider headingVariant="h5" icon="copy">
								Documents
							</HeadingDivider>
							<Box ml={1} pl={1}>
								{documentsLoading && <LoadingSpinner />}

								{!documentsLoading &&
									(documents?.length ?
										documents.map((d, i) => (
											<div key={makeDocumentKey(d.id, i)}>
												<Button
													buttonText={d.name}
													onClick={() =>
														handleViewDocument(d.id, d.documentType, i)
													}
													isLoading={
														documentKeyBeingFetch ===
														makeDocumentKey(d.id, i)
													}
													trailingIcon="external-link"
												/>
											</div>
										))
									:	<Paragraph small secondary>
											No documents found
										</Paragraph>)}
							</Box>
						</GridItem>

						{/* Secondary action buttons */}
						<GridItem
							xs={12}
							md={6}
							lg={3}
							display="flex"
							flexDirection="column"
							rowGap={1}
						>
							{screenIsXs && <Divider />}
							{canEdit && (
								<>
									<Button
										variant="secondary-cta"
										icon="pencil"
										fillContainer
										onClick={() => setShowEditModal(true)}
									>
										Edit details
									</Button>
									{hasFinanceSuperUser && canEditLineItems && (
										<Button
											variant="secondary-cta"
											icon="pencil"
											fillContainer
											onClick={() => setShowEditBillShipModal(true)}
										>
											Edit Bill To / Ship To
										</Button>
									)}
								</>
							)}
							<Button
								icon="file-signature"
								variant="secondary-cta"
								buttonText="Create Documents"
								fillContainer
								isLoading={isSaving("recreating-documents")}
								onClick={handleRecreateDocuments}
								disabled={partOrderLoading}
							/>
							<FileInput
								icon="paperclip-vertical"
								label="Attach Document"
								isLoading={isSaving("adding-attachment")}
								fileTypes={partOrderAttachmentFileTypes}
								onChange={(file) => handleSelectAttachment(file)}
								fillContainer
								mb={0}
							/>
							{!!partOrder?.lineItems.length && (
								<Button
									buttonText="Part number labels"
									variant="secondary-cta"
									icon="print"
									onClick={() => setShowPrintModal(true)}
									fillContainer
								/>
							)}
							{isOpen &&
								!!partOrder &&
								partOrder.status.id !== PartOrderStatusId.Hold && (
									<Button
										variant="secondary-cta"
										fillContainer
										icon="hourglass-half"
										onClick={() => setShowPlaceHoldModal(true)}
									>
										Place On Hold
									</Button>
								)}
							{canCancel && (
								<Button
									variant="secondary-cta"
									icon="trash"
									fillContainer
									onClick={() =>
										setConfirmationModalConfig({
											title: "Cancel Order",
											message:
												"Are you sure you want to cancel this order? This action cannot be undone.",
											onConfirm: handleCancelOrder,
										})
									}
								>
									Cancel order
								</Button>
							)}
						</GridItem>
					</GridContainer>
				</AnimatedEntrance>

				<Box textAlign="center">
					<Button
						icon={isExpandedView ? "angle-up" : "angle-down"}
						containerProps={{ mt: 3 }}
						onClick={() => setIsExpandedView(!isExpandedView)}
						disabled={partOrderLoading}
					>
						{isExpandedView ? "Show less" : "Show more details and actions"}
					</Button>
				</Box>

				<PartOrderDetailPickLists
					partOrderId={partOrderId}
					partOrderLoading={partOrderLoading}
					partOrderOrderId={partOrder?.orderId ?? null}
					partOrderOnHold={partOrder?.status.id === PartOrderStatusId.Hold}
					isShipComplete={partOrder?.shipComplete ?? false}
					fulfillmentLocations={fulfillmentSources.locations}
					orderLineItems={partOrder?.lineItems ?? []}
					paymentNotComplete={paymentNotComplete}
				/>

				<Divider my={2} />

				<PartOrderDetailShipments
					partOrderId={partOrderId}
					partOrder={partOrder ?? null}
					paymentNotComplete={paymentNotComplete}
				/>

				{partOrderLoading && <LoadingSpinner />}
				{!!partOrder && <PartOrderDetailLineItems order={partOrder} />}
			</Card>

			<ConfirmationModal
				config={confirmationModalConfig}
				setConfig={setConfirmationModalConfig}
			/>
			{showEditModal && !!partOrder && (
				<EditPartOrderModal order={partOrder} onClose={() => setShowEditModal(false)} />
			)}
			{showEditBillShipModal && !!partOrder && (
				<EditBillToShipToModal
					order={partOrder}
					onClose={() => setShowEditBillShipModal(false)}
				/>
			)}
			{showChangeDcModal && (
				<ChangeDcRoutingModal
					partOrderId={partOrderId}
					locationIds={Object.values(fulfillmentSources.locations).map((l) =>
						l.locationId.toString()
					)}
					onClose={() => setShowChangeDcModal(false)}
				/>
			)}
			{showPrintModal && !!partOrder?.lineItems.length && (
				<PrintLinePartNumbersModal
					lines={partOrder.lineItems}
					partOrderOrderNumber={partOrder.orderId.toString()}
					onClose={() => setShowPrintModal(false)}
				/>
			)}
			{showPlaceHoldModal && !!partOrder && !!partOrderListResult && (
				<PlaceHoldModal
					order={partOrder}
					orderListResult={partOrderListResult}
					onClose={() => setShowPlaceHoldModal(false)}
				/>
			)}
			{showRemoveHoldModal && !!partOrder && (
				<RemoveHoldModal
					partOrder={partOrder}
					onClose={() => setShowRemoveHoldModal(false)}
				/>
			)}
		</>
	)
}

const makeDocumentKey = (documentId: number, documentIndex: number): string => {
	return `${documentId}-${documentIndex}`
}

const gridItemProps: GridItemProps = {
	md: 3,
	sm: 6,
	xs: 12,
}
const primaryActionBoxProps: BoxProps = {
	textAlign: "center",
	maxWidth: 25,
	mx: "auto",
	mt: 4,
	mb: 5,
}

const detailLabelCss = css`
	opacity: 0.65;
	font-weight: 500;
`

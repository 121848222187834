import React from "react"
import PropTypes from "prop-types"
import Documents from "./Documents"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { bindActionCreators, compose } from "redux"
import memoizeOne from "memoize-one"
import { APPLICATIONS } from "@ncs/mortar/util/constants"
import { meetsAppRestriction } from "@ncs/bricks/redux/selectors/auth"
import {
	addCategory,
	deleteCategory,
	listInfoRefCategories,
	patchCategory,
} from "@ncs/mortar/redux/services/infoRef"
import { fetchData } from "@ncs/bricks/redux/services/dynamicTables"
import { deleteDocument, generateDocumentink } from "@ncs/mortar/redux/services/infoRef"
import callApi from "@ncs/bricks/redux/services/callApi"
import Clipboard from "clipboard"
import { withDynamicTableFilter } from "~/components"
import { setStateKeyFromEventTargetValue } from "@ncs/bricks/util/state"
import get from "lodash/get"

const reduxKey = "infoRefDocuments"

const setCategoryTree = memoizeOne((categoryMap, selectedCategory) => {
	if (typeof selectedCategory === "string") {
		selectedCategory = categoryMap[selectedCategory]
	} else if (typeof selectedCategory === "number") {
		selectedCategory = categoryMap[selectedCategory.toString()]
	}

	let top, second, third, fourth
	top = second = third = fourth = null

	if (selectedCategory) {
		top = categoryMap[selectedCategory.id]
		if (top.parent) {
			second = top
			top = second.parent

			if (top.parent) {
				third = second
				second = top
				top = second.parent

				if (top.parent) {
					fourth = third
					third = second
					second = top
					top = second.parent
				}
			}
		}
	}

	return {
		selectedTopLevelCategory: top,
		selectedSecondLevelCategory: second,
		selectedThirdLevelCategory: third,
		selectedFourthLevelCategory: fourth,
	}
})

class DocumentsContainer extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isConfirmingDelete: false,
			deletingItem: null,
			isSaving: false,
			isGeneratingLink: false,
			generatedLink: null,
			isCreatingCategory: false,
			isEditingCategory: false,
			isDeletingCategory: false,
			categoryName: "",
			categoryToEdit: null,
			categoryToDelete: null,
		}
	}

	componentDidMount() {
		this.clipboard = new Clipboard("#clipboardButton", {})

		this.props.listInfoRefCategories()
	}

	componentWillUnmount() {
		this.clipboard && this.clipboard.destroy()
	}

	handleViewPreview = (item) => {
		this.props.history.push(`/info-ref/documents/${item.id}`)
	}

	handleDownload = (item) => {
		window.open(item.presign_url, "_blank")
	}

	handleGenerateLink = (item) => {
		this.setState({ isGeneratingLink: true })
		this.props.callApi(generateDocumentink(item.id)).then(({ payload: result }) => {
			this.setState({ isGeneratingLink: false, generatedLink: result.presign_url })
		})
	}

	handleCloseGeneratedLink = () => {
		this.setState({ isGeneratingLink: false, generatedLink: null })
	}

	handleDelete = (deletingItem) => {
		this.setState({ isConfirmingDelete: true, deletingItem })
	}

	handleCancelDelete = () => {
		this.setState({ isSaving: false, isConfirmingDelete: false, deletingItem: null })
	}

	handleConfirmDelete = (confirmationCallback) => {
		if (!this.state.deletingItem || !this.state.deletingItem.id) {
			return
		}

		this.setState({ isSaving: true })
		this.props.callApi(deleteDocument(this.state.deletingItem.id)).then(() => {
			this.handleCancelDelete()

			if (typeof confirmationCallback === "function") {
				confirmationCallback()
			}

			// refresh data after deleting
			this.props.refreshDocuments(reduxKey)
		})
	}

	handleCategoryUpdated = (selectedCategory) => {
		if (typeof selectedCategory === "string") {
			selectedCategory = this.props.reverseDocumentCategoriesMap[selectedCategory]
		}

		this.props.setCategory((selectedCategory || {}).id)
	}

	handleStartCreatingCategory = () => {
		this.setState({ isCreatingCategory: true, categoryName: "" })
	}

	handleCancelCreatingCategory = () => {
		this.setState({ isSaving: false, isCreatingCategory: false, categoryName: "" })
	}

	handleCreateCategory = (confirmationCallback) => {
		if (!this.state.categoryName) return

		this.setState({ isSaving: true })

		let currentCategory = (
			this.props.selectedThirdLevelCategory ||
			this.props.selectedSecondLevelCategory ||
			this.props.selectedTopLevelCategory ||
			{}
		).id

		this.props.callApi(addCategory(currentCategory, this.state.categoryName)).then(() => {
			this.handleCancelCreatingCategory()

			if (typeof confirmationCallback === "function") {
				confirmationCallback()
			}

			this.props.listInfoRefCategories()
		})
	}

	handleStartEditingCategory = (categoryToEdit) => () => {
		this.setState({
			isEditingCategory: true,
			categoryToEdit,
			categoryName: categoryToEdit.name,
		})
	}

	handleCancelEditingCategory = () => {
		this.setState({
			isSaving: false,
			isEditingCategory: false,
			categoryToEdit: null,
			categoryName: "",
		})
	}

	handleSaveCategory = (confirmationCallback) => {
		if (!this.state.categoryName || !this.state.categoryToEdit) return

		this.setState({ isSaving: true })

		let data = {
			name: this.state.categoryName,
		}

		this.props.callApi(patchCategory(this.state.categoryToEdit.id, data)).then(() => {
			this.handleCancelEditingCategory()

			if (typeof confirmationCallback === "function") {
				confirmationCallback()
			}

			this.props.listInfoRefCategories()
		})
	}

	handleStartDeletingCategory = (categoryToDelete) => () => {
		this.setState({
			isDeletingCategory: true,
			categoryToDelete,
			categoryName: categoryToDelete.name,
		})
	}

	handleCancelDeletingCategory = () => {
		this.setState({
			isSaving: false,
			isDeletingCategory: false,
			categoryToDelete: null,
			categoryName: "",
		})
	}

	handleDeleteCategory = (confirmationCallback) => {
		if (!this.state.categoryToDelete || !this.state.categoryToDelete.id) return

		this.setState({ isSaving: true })

		this.props.callApi(deleteCategory(this.state.categoryToDelete.id)).then(() => {
			let parent =
				this.props.reverseDocumentCategoriesMap[this.state.categoryToDelete.id].parent
			if (parent) {
				this.handleCategoryUpdated(parent)
			}

			this.handleCancelDeletingCategory()

			this.props.listInfoRefCategories()

			if (typeof confirmationCallback === "function") {
				confirmationCallback()
			}
		})
	}

	handleCreateDocument = () => {
		let categoryParam =
			this.props.selectedCategory ? `?category=${this.props.selectedCategory}` : ""
		this.props.history.push(`/info-ref/create-document${categoryParam}`)
	}

	render = () => (
		<Documents
			reduxKey={reduxKey}
			isAdmin={this.props.isInfoRefAdmin()}
			isSaving={this.state.isSaving}
			onViewPreview={this.handleViewPreview}
			onDownload={this.handleDownload}
			onCreateDocument={this.handleCreateDocument}
			onGenerateLink={this.handleGenerateLink}
			onCloseGeneratedLink={this.handleCloseGeneratedLink}
			isGeneratingLink={this.state.isGeneratingLink}
			generatedLink={this.state.generatedLink}
			isConfirmingDelete={this.state.isConfirmingDelete}
			deletingItem={this.state.deletingItem}
			onDelete={this.handleDelete}
			onCancelDelete={this.handleCancelDelete}
			onConfirmDelete={this.handleConfirmDelete}
			categories={this.props.documentCategories}
			onCategoryChange={this.handleCategoryUpdated}
			selectedCategory={this.props.selectedCategory}
			selectedTopLevelCategory={this.props.selectedTopLevelCategory}
			selectedSecondLevelCategory={this.props.selectedSecondLevelCategory}
			selectedThirdLevelCategory={this.props.selectedThirdLevelCategory}
			selectedFourthLevelCategory={this.props.selectedFourthLevelCategory}
			categoryName={this.state.categoryName}
			onCategoryNameChange={setStateKeyFromEventTargetValue(this, "categoryName", "")}
			isCreatingCategory={this.state.isCreatingCategory}
			onStartCreatingCategory={this.handleStartCreatingCategory}
			onCancelCreatingCategory={this.handleCancelCreatingCategory}
			onCreateCategory={this.handleCreateCategory}
			isEditingCategory={this.state.isEditingCategory}
			onStartEditingCategory={this.handleStartEditingCategory}
			onCancelEditingCategory={this.handleCancelEditingCategory}
			onSaveCategory={this.handleSaveCategory}
			isDeletingCategory={this.state.isDeletingCategory}
			selectedCategoryHasDocuments={get(this.props.dynamicTableState, "data.length", 1) > 0}
			onStartDeletingCategory={this.handleStartDeletingCategory}
			onCancelDeletingCategory={this.handleCancelDeletingCategory}
			onDeleteCategory={this.handleDeleteCategory}
		/>
	)
}

DocumentsContainer.propTypes = {
	callApi: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	setCategory: PropTypes.func.isRequired,
	reverseDocumentCategoriesMap: PropTypes.object.isRequired,
}

const mapStateToProps = (state, ownProps) => {
	return {
		...state.lookups,
		...state.infoRef,
		...setCategoryTree(state.infoRef.reverseDocumentCategoriesMap, ownProps.selectedCategory),
		dynamicTableState: { ...state.dynamicTables[reduxKey] },
		isInfoRefAdmin: () => {
			return meetsAppRestriction(state.auth, APPLICATIONS.InfoRefAdmin)
		},
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			callApi,
			listInfoRefCategories,
			refreshDocuments: fetchData(reduxKey),
		},
		dispatch
	)

export default compose(
	withDynamicTableFilter("category", undefined, reduxKey, "setCategory", "selectedCategory"),
	connect(mapStateToProps, mapDispatchToProps),
	withRouter
)(DocumentsContainer)

import React from "react"
import { BaseDateFilter, withDynamicTableFilter } from "@ncs/bricks/components"

class EndDateFilter extends React.PureComponent {
	render() {
		return <BaseDateFilter labelText="End Date" {...this.props} />
	}
}

export default withDynamicTableFilter("end_date")(EndDateFilter)

import { z } from "zod"

let ConfigSchema = null

ConfigSchema = z.object({
	IS_DEV: z.boolean(),
})

const devPortalUrls: (string | undefined)[] = [
	"http://localhost:8000", // Local
	"https://portal.ncsstaging.com", // Staging
]

const config = {
	IS_DEV: devPortalUrls.includes(import.meta.env.VITE_PORTAL_SERVICE_URL),
}

export default ConfigSchema.parse(config)

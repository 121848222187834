import { Dispatch, ReactElement, SetStateAction } from "react"

import { PurchaseOrderStatusSelector } from "../selectors"

export interface PurchaseOrderStatusQueryParams {
	purchaseOrderStatus: string | null
}

export interface PurchaseOrderStatusQueryFilterProps<
	QueryParamState extends PurchaseOrderStatusQueryParams,
> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

export const PurchaseOrderStatusQueryFilter = <
	QueryParamState extends PurchaseOrderStatusQueryParams,
>({
	queryParamState,
	setQueryParamState,
}: PurchaseOrderStatusQueryFilterProps<QueryParamState>): ReactElement => {
	const handleChange = (newValue: string | null) => {
		setQueryParamState((prev) => ({
			...prev,
			purchaseOrderStatus: newValue || null,
		}))
	}

	return (
		<PurchaseOrderStatusSelector
			value={queryParamState.purchaseOrderStatus}
			onChange={handleChange}
			fillContainer
			disableNoSelectionOption={false}
			noSelectionOptionText="Any status"
		/>
	)
}

import { Dispatch, ReactElement, SetStateAction } from "react"

import { PortalSelector } from "../selectors"

interface PortalQueryParams {
	billToCustomer_PortalAssignment_PortalId: string | null
}

export interface PortalQueryFilterProps<QueryParamState extends PortalQueryParams> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

export const PortalQueryFilter = <QueryParamState extends PortalQueryParams>({
	queryParamState,
	setQueryParamState,
}: PortalQueryFilterProps<QueryParamState>): ReactElement => {
	const handleChange = (newValue: string | null) => {
		setQueryParamState((prev) => ({
			...prev,
			billToCustomer_PortalAssignment_PortalId: newValue,
		}))
	}

	return (
		<PortalSelector
			value={queryParamState.billToCustomer_PortalAssignment_PortalId}
			onChange={handleChange}
			disableNoSelectionOption={false}
			fillContainer
		/>
	)
}

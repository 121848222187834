export const SET_CLIENT_VERSION = "clientVersion/set_client_version"
export const REQUEST_EXTRA_TIME_BEFORE_RELOAD = "clientVersion/request_extra_time_before_reload"
export const BEGIN_FORCED_RELOAD = "clientVersion/begin_forced_reload"
export const FORCE_RELOAD = "clientVersion/force_reload"

export const checkClientVersion = (version) => {
	return (dispatch, getState) => {
		let state = getState().clientVersion
		if (!state.clientVersion) {
			// no version set, so this is the first load (or incognito), set the client version and don't refresh
			dispatch({ type: SET_CLIENT_VERSION, payload: version })
		} else if (state.clientVersion !== version && !state.forcedReloadCountdown) {
			dispatch({ type: BEGIN_FORCED_RELOAD, payload: version })
		}
	}
}

export const requestExtraTimeBeforeReload = () => (dispatch) => {
	dispatch({ type: REQUEST_EXTRA_TIME_BEFORE_RELOAD, payload: true })
}

export const forceReload = () => (dispatch, getStore) => {
	dispatch({ type: SET_CLIENT_VERSION, payload: getStore().clientVersion.pendingClientVersion })

	// avoid an infinite loop
	setTimeout(() => {
		dispatch({ type: FORCE_RELOAD })
	}, 250)
}

const initialState = {
	clientVersion: null,
	pendingClientVersion: null,
	forcedReloadCountdown: null,
	usingExtraTimeBeforeReload: null,
}

const clientVersion = (state = initialState, action) => {
	switch (action.type) {
		case BEGIN_FORCED_RELOAD:
			return {
				...state,
				forcedReloadCountdown: new Date(),
				pendingClientVersion: action.payload,
			}

		case SET_CLIENT_VERSION:
			return {
				...state,
				clientVersion: action.payload,
				pendingClientVersion: null,
				forcedReloadCountdown: null,
				usingExtraTimeBeforeReload: null,
			}
		case REQUEST_EXTRA_TIME_BEFORE_RELOAD:
			return {
				...state,
				usingExtraTimeBeforeReload: action.payload,
			}
		case FORCE_RELOAD:
			window.location.reload()
			return state

		default:
			return state
	}
}
export default clientVersion

import { FC, useMemo, useRef, useState } from "react"

import qs from "query-string"
import { useHistory } from "react-router-dom"

import { InventoryLocation, makeApiErrorMessage, useCreateInventorySheet } from "@ncs/ncs-api"
import {
	AnimatedEntrance,
	Box,
	Button,
	Card,
	cssMixins,
	GridContainer,
	GridItem,
	Heading,
	Icon,
	LocationSelector,
	RecentItems,
	Tabs,
	useChangeCallback,
	useToast,
	useUrlState,
} from "@ncs/web-legos"

import { PageTitle } from "~/layouts/PageTitle"

import {
	LocationBinsTab,
	LocationCycleCountsTab,
	LocationOverstockedTab,
	LocationPartsTab,
	LocationReplenishmentTab,
	LocationTransactionsTab,
} from "./components"

export enum LocationsTab {
	Parts = "Parts",
	Transactions = "Transactions",
	Bins = "Bins",
	SupplierDemand = "Supplier Demand",
	Replenishment = "Replenishment",
	Overstocked = "Overstocked",
	CycleCounts = "Cycle Counts",
}

export type LocationsUrlState = {
	tab: LocationsTab | null
	urlLocationId: string | null
}

const Locations: FC = () => {
	const history = useHistory()
	const { makeSuccessToast, makeErrorToast } = useToast()
	const [{ tab, urlLocationId }, { setUrlState, updateUrlValue }] =
		useUrlState<LocationsUrlState>({
			tab: null,
			urlLocationId: null,
		})
	const [selectedLocation, setSelectedLocation] = useState<InventoryLocation | null>(null)
	const [requestingSheet, setRequestingSheet] = useState(false)
	// const [requestingAllocations, setRequestingAllocations] = useState(false)

	// Track this so that LocationSelector initialId doesn't block setting location to null.
	const hasSetInitialLocation = useRef(false)

	const requestSheet = useCreateInventorySheet()
	// const requestAllocationReport = useEmailLocationAllocations()

	const handleSetSelectedLocation = (newLocation: InventoryLocation | null) => {
		setSelectedLocation(newLocation)
		setUrlState((prev) => ({
			urlLocationId: newLocation?.id ?? null,
			tab: prev.tab ?? LocationsTab.Parts,
		}))
		if (hasSetInitialLocation.current === false) {
			hasSetInitialLocation.current = true
		}
	}

	// const handleRequestAllocationsReport = async (locationId: string) => {
	// 	try {
	// 		setRequestingAllocations(true)
	// 		await requestAllocationReport(locationId)
	// 		makeSuccessToast("Report is being created. Check your email in a few minutes.")
	// 	} catch (e) {
	// 		makeErrorToast(makeApiErrorMessage(e))
	// 	} finally {
	// 		setRequestingAllocations(false)
	// 	}
	// }

	const handleRequestSheet = async (locationId: string) => {
		try {
			setRequestingSheet(true)
			await requestSheet({ locationId })
			makeSuccessToast("Report is being created. Check your email in a few minutes.")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		} finally {
			setRequestingSheet(false)
		}
	}

	// Handles the odd case of the user being here in Location Management, but then clicking
	// the Location Management link in the sidebar nav menu again, and expecting to reset the
	// state of the page, which normally wouldn't happen.
	useChangeCallback(history.location.search, (newSearch) => {
		const rawParams = qs.parse(newSearch)
		if (!rawParams.urlLocationId) {
			handleSetSelectedLocation(null)
		}
	})

	const panels = useMemo(() => {
		const p = [
			{
				navLabel: LocationsTab.Parts,
				navIcon: <Icon icon="chart-bar" />,
				component:
					selectedLocation ? <LocationPartsTab location={selectedLocation} /> : <div />,
			},
			{
				navLabel: LocationsTab.Transactions,
				navIcon: <Icon icon="history" />,
				component:
					selectedLocation ?
						<LocationTransactionsTab location={selectedLocation} />
					:	<div />,
			},
			{
				navLabel: LocationsTab.Replenishment,
				navIcon: <Icon icon="shopping-basket" />,
				component:
					selectedLocation ?
						<LocationReplenishmentTab location={selectedLocation} />
					:	<div />,
			},
			{
				navLabel: LocationsTab.Overstocked,
				navIcon: <Icon icon="balance-scale-left" />,
				component:
					selectedLocation ?
						<LocationOverstockedTab location={selectedLocation} />
					:	<div />,
			},
			{
				navLabel: LocationsTab.Bins,
				navIcon: <Icon icon="box-open" />,
				component:
					selectedLocation ? <LocationBinsTab location={selectedLocation} /> : <div />,
			},
			{
				navLabel: LocationsTab.CycleCounts,
				navIcon: <Icon icon="abacus" />,
				component:
					selectedLocation ?
						<LocationCycleCountsTab location={selectedLocation} />
					:	<div />,
			},
		]

		return p
	}, [selectedLocation])

	return (
		<>
			{!!selectedLocation && (
				<>
					<AnimatedEntrance show mt={2} mb={2} direction="fade">
						<Button
							icon="long-arrow-left"
							onClick={() => handleSetSelectedLocation(null)}
						>
							Select different location
						</Button>
					</AnimatedEntrance>

					<PageTitle
						title={`(${selectedLocation.locationCode}) ${selectedLocation.description} | Location Management`}
					/>
				</>
			)}

			<Card
				heading={
					selectedLocation ?
						`(${selectedLocation.locationCode}) ${selectedLocation.description}`
					:	"Select a location"
				}
				headingIcon={!selectedLocation ? "map-marker-alt" : "warehouse"}
				headingDetail={selectedLocation?.isWarehouse ? "Warehouse" : undefined}
				renderRight={() => {
					return selectedLocation ?
							<Box
								display="flex"
								flexDirection="column"
								rowGap={0.75}
								justifyContent="flex-end"
								alignItems="flex-end"
								smProps={{ alignItems: "flex-start" }}
							>
								{/* <Button
								icon="box-check"
								isLoading={requestingAllocations}
								onClick={() => handleRequestAllocationsReport(selectedLocation.id)}
							>
								Email order allocations report
							</Button> */}
								<Button
									icon="list"
									isLoading={requestingSheet}
									onClick={() => handleRequestSheet(selectedLocation.id)}
								>
									Email inventory cheat sheet report
								</Button>
							</Box>
						:	undefined
				}}
			>
				<AnimatedEntrance mt={-1} show={!selectedLocation} direction="fade">
					<GridContainer>
						<GridItem sm={12} md={6} lg={5} xl={4}>
							<LocationSelector
								value={selectedLocation}
								initialId={
									hasSetInitialLocation.current ? undefined : urlLocationId
								}
								onChange={handleSetSelectedLocation}
								autoFocus
							/>
						</GridItem>
					</GridContainer>
				</AnimatedEntrance>

				<Box mb={3} css={selectedLocation ? cssMixins.hidden : undefined}>
					<RecentItems
						item={selectedLocation}
						onSelect={handleSetSelectedLocation}
						itemLabelAccessor={(location) =>
							`(${location.locationCode}) ${location.description}`
						}
					/>
				</Box>

				<Tabs
					currentTab={tab}
					onChange={(newTab) => updateUrlValue("tab", newTab)}
					disabled={!selectedLocation}
					panels={panels}
				/>

				{!selectedLocation && (
					<Box p={3}>
						<Heading
							icon="map-marker-alt"
							opacity={0.5}
							variant="h4"
							textAlign="center"
						>
							Please select a location above to begin
						</Heading>
					</Box>
				)}
			</Card>
		</>
	)
}

export default Locations

import { FC } from "react"

import dayjs from "dayjs"
import { Column } from "react-table"

import {
	ConnectedDevice,
	useWashCounterWashCounts,
	WashCounterWashCounts,
	WashCounterWashCountsQueryParams,
} from "@ncs/ncs-api"
import {
	DateFormat,
	formatDateTime,
	formatNumber,
	getTimezoneAbbreviation,
	Typify,
} from "@ncs/ts-utils"
import {
	EndDateQueryFilter,
	EndDateQueryFilterProps,
	StartDateQueryFilter,
	StartDateQueryFilterProps,
	Table,
	useUrlState,
} from "@ncs/web-legos"

import { c12yAutoRefreshConfig } from "~/util"

export interface WashCounterWashCountsTabProps {
	device: ConnectedDevice | null
}

export type WashCounterWashCountsTabUrlState = Typify<WashCounterWashCountsQueryParams>

export const WashCounterWashCountsTab: FC<WashCounterWashCountsTabProps> = ({ device }) => {
	const [params, { setUrlState }] = useUrlState<WashCounterWashCountsTabUrlState>({
		startDate: dayjs().subtract(2, "weeks").format(DateFormat.DateTimeQueryParam),
		endDate: null,
	})

	const query = useWashCounterWashCounts(device?.id, {
		params,
		queryConfig: c12yAutoRefreshConfig,
	})

	return (
		<>
			<Table
				query={query}
				columns={columns}
				queryParamState={params}
				setQueryParamState={setUrlState}
				pinnedQueryFilters={[StartDateFilter, EndDateFilter]}
				noDataText="No wash count data found within the selected time"
				disableAllSorting
			/>
		</>
	)
}

const columns: Column<WashCounterWashCounts>[] = [
	{
		Header: "id",
		accessor: "id",
	},
	{
		Header: `Date (${getTimezoneAbbreviation()})`,
		accessor: ({ eventOn }) => formatDateTime(eventOn),
	},
	{
		Header: "Total",
		accessor: ({ total }) => formatNumber(total),
	},
	{
		Header: "Pkg One",
		accessor: ({ washPackageOne }) => formatNumber(washPackageOne),
	},
	{
		Header: "Pkg Two",
		accessor: ({ washPackageTwo }) => formatNumber(washPackageTwo),
	},
	{
		Header: "Pkg Three",
		accessor: ({ washPackageThree }) => formatNumber(washPackageThree),
	},
	{
		Header: "Pkg Four",
		accessor: ({ washPackageFour }) => formatNumber(washPackageFour),
	},
	{
		Header: "Pkg Five",
		accessor: ({ washPackageFive }) => formatNumber(washPackageFive),
	},
	{
		Header: "Pkg Six",
		accessor: ({ washPackageSix }) => formatNumber(washPackageSix),
	},
]

const StartDateFilter = (props: StartDateQueryFilterProps<WashCounterWashCountsQueryParams>) => {
	return (
		<StartDateQueryFilter {...props} dateFormat={DateFormat.DateTimeQueryParam} label="From" />
	)
}

const EndDateFilter = (props: EndDateQueryFilterProps<WashCounterWashCountsQueryParams>) => {
	return (
		<EndDateQueryFilter
			{...props}
			dateFormat={DateFormat.DateTimeQueryParam}
			label="Through"
		/>
	)
}

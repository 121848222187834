import { FC } from "react"

import { useHistory } from "react-router-dom"
import { Column } from "react-table"

import {
	InventoryRequestListItem,
	InventoryRequestQueryParams,
	useInventoryRequests,
} from "@ncs/ncs-api"
import { displayDateTime, getTimezoneAbbreviation } from "@ncs/ts-utils"
import {
	SearchQueryFilter,
	Table,
	TerritoryQueryFilter,
	usePaginationUrlState,
	useUrlState,
} from "@ncs/web-legos"

import { InventoryRequestStatusQueryFilter, InventoryRequestTypeQueryFilter } from "~/components"

export type ViewInventoriesTabUrlState = InventoryRequestQueryParams

export const ViewInventoriesTab: FC = () => {
	const history = useHistory()
	const [params, { setUrlState: setParams }] =
		useUrlState<ViewInventoriesTabUrlState>(initialUrlState)
	const [pagination, setPagination] = usePaginationUrlState()

	const query = useInventoryRequests({
		params: {
			...params,
			...pagination,
		},
		manualPagination: true,
	})

	return (
		<>
			<Table
				query={query}
				columns={columns}
				pagination={pagination}
				setPagination={setPagination}
				queryParamState={params}
				setQueryParamState={setParams}
				pinnedQueryFilters={[
					TerritoryQueryFilter,
					InventoryRequestStatusQueryFilter,
					InventoryRequestTypeQueryFilter,
					SearchQueryFilter,
				]}
				onRowClick={({ original }) =>
					history.push({
						pathname: `/inventory/physical-inventory/${original.id}`,
						state: { physicalInventoryUrlState: params },
					})
				}
			/>
		</>
	)
}

const columns: Column<InventoryRequestListItem>[] = [
	{
		Header: "Request #",
		accessor: ({ id }) => id,
	},
	{
		Header: "Type",
		accessor: ({ inventoryType }) => inventoryType.description,
	},
	{
		Header: "Location",
		accessor: ({ location }) => `(${location.locationCode}) ${location.description}`,
	},
	{
		Header: "Description",
		accessor: ({ description }) => description || "-",
	},
	{
		Header: "Status",
		accessor: ({ status }) => status.description,
	},
	{
		Header: `Created (${getTimezoneAbbreviation()})`,
		accessor: ({ createdDate }) => displayDateTime(createdDate),
	},
	{
		Header: "Created by",
		accessor: ({ createdBy }) => createdBy.name,
		hiddenByDefault: true,
	},
	{
		Header: `Submitted (${getTimezoneAbbreviation()})`,
		accessor: ({ submittedDate }) => displayDateTime(submittedDate),
		hiddenByDefault: true,
	},
	{
		Header: "Submitted by",
		accessor: ({ submittedBy }) => submittedBy?.name || "-",
		hiddenByDefault: true,
	},
	{
		Header: "Assigned to",
		accessor: ({ assignedTo }) => assignedTo?.name || "-",
		hiddenByDefault: true,
	},
]

const initialUrlState: ViewInventoriesTabUrlState = {
	territory: null,
	status: null,
	inventoryType: null,
	search: null,
}

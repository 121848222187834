import { FC } from "react"

import { Card, Tabs, useUrlState } from "@ncs/web-legos"

import { EnterprisesTab, OrganizationsTab } from "./components"

export enum EnterprisesAndOrganizationsTab {
	Enterprises = "Enterprises",
	Organizations = "Organizations",
}

export type EnterprisesAndOrganizationsUrlState = {
	tab: EnterprisesAndOrganizationsTab
}

export const EnterprisesAndOrganizations: FC = () => {
	const [{ tab }, { updateUrlValue }] = useUrlState<EnterprisesAndOrganizationsUrlState>({
		tab: EnterprisesAndOrganizationsTab.Enterprises,
	})

	return (
		<Card heading="Enterprises & Organizations" headingIcon="sitemap">
			<Tabs
				currentTab={tab}
				onChange={(newTab) => updateUrlValue("tab", newTab)}
				panels={[
					{
						navLabel: EnterprisesAndOrganizationsTab.Enterprises,
						component: <EnterprisesTab />,
					},
					{
						navLabel: EnterprisesAndOrganizationsTab.Organizations,
						component: <OrganizationsTab />,
					},
				]}
			/>
		</Card>
	)
}

import { FC, useMemo } from "react"

import { useHistory, useParams } from "react-router-dom"

import { DeviceStatusCategory, useWashCountersAtSite } from "@ncs/ncs-api"
import { displayDateTime, titleCase } from "@ncs/ts-utils"
import {
	Box,
	Button,
	Callout,
	Card,
	encodeUrlState,
	Icon,
	LoadingSpinner,
	Paragraph,
	StatusBlip,
	Tabs,
} from "@ncs/web-legos"

import { c12yAutoRefreshConfig } from "~/util"

import { DevicesTabUrlState } from "../ConnectedDevices/components"
import { WashCounterWashCountsTab, WashCounterWorkOrdersTab } from "./components"

export enum WashCounterDetailTab {
	WashCounts = "Wash Counts",
	WorkOrders = "Work Orders",
}

export const WashCounterDetail: FC = () => {
	const history = useHistory<{ devicesUrlState?: DevicesTabUrlState }>()
	const { id } = useParams<{ id: string }>()

	const query = useWashCountersAtSite({
		params: { device: id },
		queryConfig: c12yAutoRefreshConfig,
	})
	const [customer, device] = useMemo(() => {
		// Because we pass in the specific device as a param, there should only be one item
		// in the array, and the devices list should have the device in question in it.
		const site = query.data?.find((s) => s.devices.some((d) => d.id === id))

		return [site?.customer ?? null, site?.devices.find((d) => d.id === id) ?? null]
	}, [query.data, id])

	if (query.isLoading) {
		return (
			<Card>
				<LoadingSpinner />
			</Card>
		)
	}

	return (
		<>
			<Button
				icon="long-arrow-left"
				onClick={() =>
					history.push({
						pathname: "/connectivity/connected-devices",
						search: encodeUrlState<DevicesTabUrlState>(
							history.location.state?.devicesUrlState
						),
					})
				}
				containerProps={{ mb: 3 }}
			>
				Back
			</Button>

			<Card
				heading={
					device && customer ?
						`${device.name} at ${customer.name} (${customer.number})`
					:	undefined
				}
				headingDetail={
					device ?
						[
							device.equipment?.vintage,
							device.equipment ?
								`Serial # ${device.equipment.serialNumber || "(none)"}`
							:	null,
							`Device ID: ${device.deviceId || "(none)"}`,
						]
					:	undefined
				}
				headingIcon="router"
			>
				{!!device && (
					<Callout maxWidth={45}>
						<Box display="flex" alignItems="center" columnGap={0.62} mb={0.25}>
							<StatusBlip status={device.state} size={1} />
							<Paragraph>
								Status: <strong>{titleCase(device.state) || "--"}</strong>
							</Paragraph>
						</Box>
						<Box d="flex" alignItems="center" gap={0.5} mb={0.25}>
							<Icon
								icon={
									device.state !== DeviceStatusCategory.Offline ?
										"wifi"
									:	"wifi-slash"
								}
							/>
							<Paragraph>
								Last communication:{" "}
								<strong>{displayDateTime(device.lastCommunication)}</strong>
							</Paragraph>
						</Box>
					</Callout>
				)}

				<Tabs
					panels={[
						{
							navLabel: WashCounterDetailTab.WashCounts,
							navIcon: <Icon icon="chart-bar" />,
							component: <WashCounterWashCountsTab device={device} />,
						},
						{
							navLabel: WashCounterDetailTab.WorkOrders,
							navIcon: <Icon icon="tools" />,
							component: <WashCounterWorkOrdersTab device={device} />,
						},
					]}
				/>
			</Card>
		</>
	)
}

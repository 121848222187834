import React from "react"
import PropTypes from "prop-types"

import { Checkbox, InputAsDisplay } from "../../../components"

import TextFormField from "./TextFormField"

class BooleanFormField extends React.PureComponent {
	render = () => {
		const {
			errorMessage,
			isEditing,
			isReadOnly,
			isValid,
			labelText,
			onChange,
			showValidationErrors,
			value,
		} = this.props

		return (
			<React.Fragment>
				{(isReadOnly || !isEditing) && (
					<InputAsDisplay labelText={labelText} value={value === true ? "Yes" : "No"} />
				)}

				{!isReadOnly && !!isEditing && (
					<Checkbox
						labelText={labelText}
						checked={value}
						onToggle={onChange}
						inputProps={{ disabled: isReadOnly || !isEditing }}
						isError={showValidationErrors && !isValid}
						helperText={showValidationErrors ? errorMessage : ""}
					/>
				)}
			</React.Fragment>
		)
	}
}

BooleanFormField.propTypes = {
	...TextFormField.propTypes,

	/** current value of input */
	value: PropTypes.bool,
}

export default BooleanFormField

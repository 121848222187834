import { get, patch, post, put, rsaaTypes, RSAA } from "@ncs/bricks/middleware/portalApiMiddleware"
import loGet from "lodash/get"
import omit from "lodash/omit"

const FETCH_DELIVERY_ORDERS = rsaaTypes("delivery_orders/list")
const GET_DELIVERY_ORDER = rsaaTypes("delivery_orders/detail")
const CREATE_DELIVERY_ORDER = rsaaTypes("delivery_order/post")
const PATCH_DELIVERY_ORDER = rsaaTypes("delivery_order/patch")
const UPDATE_DELIVERY_ORDER = rsaaTypes("delivery_order/put")
const CANCEL_DELIVERY_ORDER = rsaaTypes("delivery_order/cancel/post")
const GET_DOCUMENTS = rsaaTypes("delivery_orders/documents/get")
const COMPLETE_ORDER = rsaaTypes("delivery_order/complete/order")
const INVENTORY_ORDER_CHECK = rsaaTypes("delivery_order/inventory/check")

export const fetchDeliveryOrders = (querystringParams) => ({
	[RSAA]: {
		api: get(`/delivery_orders/`, querystringParams),
		types: FETCH_DELIVERY_ORDERS,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const getDeliveryOrder = (orderNumber) => ({
	[RSAA]: {
		api: get(`/delivery_orders/${orderNumber}/`),
		types: GET_DELIVERY_ORDER,
	},
})

export const createDeliveryOrder = (order) => ({
	[RSAA]: {
		api: post("/delivery_orders/", order),
		types: CREATE_DELIVERY_ORDER,
	},
})

export const updateDeliveryOrder = (id, order) => {
	let data = {
		// copy everything over for a start
		...omit(order, [
			// get rid of data we don't want to send
			"id",
			"assigned_to",
			"bill_to_customer",
			"created_by",
			"customer",
			"location",
			"modified_by",
			"payment_method",
			"shipment_method",
			"status",
		]),

		line_items: loGet(order, "line_items", []).map((x) => ({
			// copy everything over for a start
			...x,

			// move nested data to writeable properties
			part_id: loGet(x, "part_id", loGet(x, "part._id", null)),
			line_type_id: loGet(x, "line_type_id", loGet(x, "line_type._id", null)),

			// get rid of data we don't want to send
			part: undefined,
			line_type: undefined,
		})),

		// move nested data to writeable properties
		assigned_to_id: loGet(order, "assigned_to._id", undefined),
		bill_to_customer_id: loGet(order, "bill_to_customer._id", undefined),
		customer_id: loGet(order, "customer._id", undefined),
		location_id: loGet(order, "location._id", undefined),
		shipment_method_id: loGet(order, "shipment_method.id", undefined),
	}

	return {
		[RSAA]: {
			api: put(`/delivery_orders/${id}/`, data),
			types: UPDATE_DELIVERY_ORDER,
		},
	}
}

export const patchDeliveryOrder = (id, order) => ({
	[RSAA]: {
		api: patch(`/delivery_orders/${id}/`, order),
		types: PATCH_DELIVERY_ORDER,
	},
})

export const cancelDeliveryOrder = (id) => ({
	[RSAA]: {
		api: post(`/delivery_orders/${id}/cancel/`),
		types: CANCEL_DELIVERY_ORDER,
	},
})

export const getDocuments = (orderNumber) => ({
	[RSAA]: {
		api: get(`/delivery_orders/${orderNumber}/documents/`),
		types: GET_DOCUMENTS,
	},
})

export const completeDeliveryOrder = (orderNumber) => ({
	[RSAA]: {
		api: post(`/delivery_orders/${orderNumber}/complete/`),
		types: COMPLETE_ORDER,
	},
})

export const checkOrderInventory = (orderNumber) => ({
	[RSAA]: {
		api: post(`/delivery_orders/${orderNumber}/inventory_check/`),
		types: INVENTORY_ORDER_CHECK,
	},
})

const initialState = {
	isLoadingOrders: false,
	error: false,
	locations: [],
	deliverOrders: [],
}

const deliveryOrder = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_DELIVERY_ORDERS.request:
		case FETCH_DELIVERY_ORDERS.success:
		case FETCH_DELIVERY_ORDERS.failure:
			return {
				...state,
				isLoadingOrders: action.type === FETCH_DELIVERY_ORDERS.request,
				deliverOrders:
					action.type === FETCH_DELIVERY_ORDERS.success ?
						action.payload
					:	state.deliverOrders,
				error: action.error,
			}

		default:
			return state
	}
}
export default deliveryOrder

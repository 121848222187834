import { FC, ReactElement } from "react"

import { Cell, Column } from "react-table"

import {
	CustomerPortalPurchasersReportLine,
	CustomerPortalPurchasersReportQueryParams,
	useCustomerPortalPurchasersReport,
} from "@ncs/ncs-api"
import {
	displayDate,
	displayNumber,
	formatCurrency,
	getStartAndEndDateQueryParams,
	TimeAgo,
	yesOrNo,
} from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	InventoryPartQueryFilter,
	InventoryPartQueryFilterProps,
	ReactTableSortType,
	Table,
	TimeAgoQueryFilter,
	TimeAgoQueryFilterProps,
	useUrlState,
} from "@ncs/web-legos"

export type CustomerPortalPurchasersReportUrlState = CustomerPortalPurchasersReportQueryParams

export const CustomerPortalPurchasersReport: FC = () => {
	const [queryParams, { setUrlState: setQueryParams }] =
		useUrlState<CustomerPortalPurchasersReportQueryParams>(
			initialCustomerPortalPurchasersReportUrlState
		)

	const [report, reportLoading] = useCustomerPortalPurchasersReport({
		params: queryParams,
		queryConfig: {
			enabled: !!queryParams.startDate,
		},
	})

	return (
		<AnimatedEntrance show>
			<Table
				data={report ?? []}
				columns={columns}
				queryParamState={queryParams}
				setQueryParamState={setQueryParams}
				pinnedQueryFilters={[ReportPartQueryFilter, ReportTimeAgoQueryFilter]}
				isLoading={reportLoading}
				storeColumnVisibility="customer-portal-purchasers-report-columns"
				noDataText="No customer users match criteria"
			/>
		</AnimatedEntrance>
	)
}

const columns: Column<CustomerPortalPurchasersReportLine>[] = [
	{
		Header: "Name",
		accessor: ({ name }) => name,
	},
	{
		Header: "Email",
		accessor: ({ email }) => email,
	},
	{
		Header: "Is guest?",
		hiddenByDefault: true,
		accessor: ({ isGuest }) => yesOrNo(isGuest),
	},
	{
		Header: "Last purchased",
		accessor: ({ lastPurchased }) => lastPurchased,
		sortType: ReactTableSortType.Number,
		Cell: ({
			row: {
				original: { lastPurchased },
			},
		}: Cell<CustomerPortalPurchasersReportLine>) => displayDate(lastPurchased),
	},
	{
		Header: "Total spent",
		accessor: ({ totalSpent }) => totalSpent,
		headingTooltip: "Total spent by this user through the Customer Portal in the time period",
		sortType: ReactTableSortType.Number,
		Cell: ({
			row: {
				original: { totalSpent },
			},
		}: Cell<CustomerPortalPurchasersReportLine>) => formatCurrency(totalSpent),
	},
	{
		Header: "Orders placed",
		accessor: ({ ordersCount }) => ordersCount,
		headingTooltip:
			"Count of the orders this user has placed through the Customer Portal in the selected time period",
		sortType: ReactTableSortType.Number,
		Cell: ({
			row: {
				original: { ordersCount },
			},
		}: Cell<CustomerPortalPurchasersReportLine>) => displayNumber(ordersCount),
	},
]

const initialCustomerPortalPurchasersReportUrlState: CustomerPortalPurchasersReportUrlState = {
	part: null,
	startDate: getStartAndEndDateQueryParams(TimeAgo.MonthsAgo6)[0],
	endDate: null,
}

const ReportPartQueryFilter = (
	props: InventoryPartQueryFilterProps<CustomerPortalPurchasersReportUrlState>
) => {
	return (
		<InventoryPartQueryFilter
			{...props}
			placeholder="Any part..."
			label="Has purchased part"
		/>
	)
}

const ReportTimeAgoQueryFilter = (
	props: TimeAgoQueryFilterProps<CustomerPortalPurchasersReportUrlState>
): ReactElement => {
	return (
		<TimeAgoQueryFilter
			{...props}
			disableNoSelectionOption
			showNoSelectionOption={false}
			label="Within time period"
		/>
	)
}

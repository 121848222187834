import { FC, useMemo, useState } from "react"

import { useThrottle } from "@react-hook/throttle"

import {
	EquipmentManufacturer,
	useEquipmentManufacturer,
	useEquipmentManufacturers,
} from "@ncs/ncs-api"

import { useChangeCallback, useInitialId } from "../../util"
import { ExtendableSearchableSelectProps, SearchableSelect } from "../inputs"

export type EquipmentManufacturerSelectorProps =
	ExtendableSearchableSelectProps<EquipmentManufacturer> & {
		initialId?: string | null | undefined
	}

export const EquipmentManufacturerSelector: FC<EquipmentManufacturerSelectorProps> = ({
	initialId,
	value,
	onChange,
	label = "Manufacturer",
}) => {
	const [searchInput, setSearchInput] = useState<string | null>(null)
	const [throttledSearch, setThrottledSearch] = useThrottle<string | null>(null)

	useChangeCallback(searchInput, (newSearchInput) => setThrottledSearch(newSearchInput))

	const query = useEquipmentManufacturers({
		params: {
			search: throttledSearch,
		},
	})

	const [initialManufacturer] = useEquipmentManufacturer(initialId, {
		queryConfig: {
			enabled: !value && !!initialId,
		},
	})

	useInitialId({
		initialId,
		onChange,
		currentValue: value,
		findInitialOption: () => initialManufacturer,
	})

	const options = useMemo(() => {
		return value ?
				// `value` may or may not be present in the data, especially at a first.
				// Inject it here, and then filter it out of the data to prevent duplicate.
				[value, ...query.data.filter((d) => d.id !== value.id)]
			:	query.data
	}, [query.data, value])

	return (
		<SearchableSelect
			options={options}
			value={value ?? null}
			onItemSelect={onChange}
			getOptionLabel={(option) => option.name ?? ""}
			isLoading={query.isLoading || query.isFetching}
			label={label}
			placeholder="Search for a manufacturer..."
			useSearchIcon
			onInputChange={setSearchInput}
			sortingParam="name"
		/>
	)
}

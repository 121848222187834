import { FC, useState } from "react"

import { useHistory } from "react-router-dom"
import { Cell, Column } from "react-table"

import {
	APPLICATION,
	defaultUserProfilesQueryParams,
	UserProfile,
	UserProfileQueryParams,
	useUserCanUse,
	useUserProfiles,
} from "@ncs/ncs-api"
import { formatDate, formatDateTime, getTimezoneAbbreviation, Typify } from "@ncs/ts-utils"
import {
	Button,
	Card,
	EmptyValueDash,
	SearchQueryFilter,
	Table,
	TableProps,
	useScrollToTopOnMount,
	useUrlState,
} from "@ncs/web-legos"

import { NewUserModal } from "./NewUserModal"

export type UserManagementUrlState = Typify<UserProfileQueryParams>

const UserManagement: FC = () => {
	useScrollToTopOnMount()
	const isUserAdmin = useUserCanUse(APPLICATION.UserManagementAdmin)
	const history = useHistory()
	const [showAddModal, setShowAddModal] = useState(false)

	const [params, { setUrlState }] = useUrlState<UserManagementUrlState>({
		...defaultUserProfilesQueryParams,
		isCustomer: false,
	})

	const usersQuery = useUserProfiles({ params, pageSize: 100 })

	return (
		<>
			<Card heading="User Management" headingIcon="users-cog">
				{isUserAdmin && (
					<Button
						variant="secondary-cta"
						icon="plus-circle"
						onClick={() => setShowAddModal(true)}
						containerProps={{ mb: 3 }}
					>
						Create User
					</Button>
				)}

				<Table
					query={usersQuery}
					columns={columns}
					queryParamState={params}
					setQueryParamState={setUrlState}
					pinnedQueryFilters={pinnedFilters}
					onRowClick={({ original }) =>
						history.push({
							pathname: `/user-management/${original.id}`,
							state: { params },
						})
					}
				/>
			</Card>

			<NewUserModal isOpen={showAddModal} onClose={() => setShowAddModal(false)} />
		</>
	)
}

const pinnedFilters: TableProps<UserProfile, UserProfileQueryParams>["pinnedQueryFilters"] = [
	SearchQueryFilter,
]

const columns: Column<UserProfile>[] = [
	{
		Header: "First name",
		accessor: "firstName",
		Cell: ({ row: { original } }: Cell<UserProfile>) => {
			return original.firstName || <EmptyValueDash />
		},
	},
	{
		Header: "Last name",
		accessor: "lastName",
		Cell: ({ row: { original } }: Cell<UserProfile>) => {
			return original.lastName || <EmptyValueDash />
		},
	},
	{
		Header: "Employee #",
		accessor: "employeeNumber",
		Cell: ({ row: { original } }: Cell<UserProfile>) => {
			return original.employeeNumber || <EmptyValueDash />
		},
	},
	{
		Header: "Email address",
		accessor: "email",
		Cell: ({ row: { original } }: Cell<UserProfile>) => {
			return original.email || <EmptyValueDash />
		},
	},
	{
		Header: "Cell phone",
		accessor: "mobile",
		hiddenByDefault: true,
		disableSortBy: true,
		Cell: ({ row: { original } }: Cell<UserProfile>) => {
			return original.mobile || <EmptyValueDash />
		},
	},
	{
		Header: "Text email",
		accessor: "textEmail",
		hiddenByDefault: true,
		disableSortBy: true,
		Cell: ({ row: { original } }: Cell<UserProfile>) => {
			return original.textEmail || <EmptyValueDash />
		},
	},
	{
		Header: "Verisae username",
		accessor: "verisaeUsername",
		hiddenByDefault: true,
		disableSortBy: true,
		Cell: ({ row: { original } }: Cell<UserProfile>) => {
			return original.verisaeUsername || <EmptyValueDash />
		},
	},
	{
		Header: "Effective date",
		id: "effDate",
		accessor: (original) => formatDate(original.effectiveDate),
		hiddenByDefault: true,
	},
	{
		Header: "End date",
		id: "endDate",
		accessor: (original) => formatDate(original.endDate),
		hiddenByDefault: true,
	},
	{
		Header: `Last login (${getTimezoneAbbreviation()})`,
		id: "lastLogin",
		accessor: "lastLogin",
		Cell: ({ row: { original } }: Cell<UserProfile>) => {
			return original.lastLogin ? formatDateTime(original.lastLogin) : <EmptyValueDash />
		},
	},
]

export default UserManagement

import { FC, useMemo } from "react"

import {
	InventoryRequestType,
	InventoryRequestTypeId,
	useInventoryRequestTypes,
} from "@ncs/ncs-api"
import { isEnumMember } from "@ncs/ts-utils"
import { ExtendableSelectProps, Select } from "@ncs/web-legos"

export type InventoryRequestTypeSelectorProps = ExtendableSelectProps<
	InventoryRequestType,
	InventoryRequestTypeId
>

export const InventoryRequestTypeSelector: FC<InventoryRequestTypeSelectorProps> = ({
	value: valueProp,
	onChange: onChangeProp,
	...rest
}) => {
	const [types, isLoading] = useInventoryRequestTypes()

	const value = useMemo(() => {
		if (isEnumMember(valueProp, InventoryRequestTypeId)) {
			return valueProp
		}
		return null
	}, [valueProp])

	const onChange = (selectionValue: string | null) => {
		if (isEnumMember(selectionValue, InventoryRequestTypeId)) {
			onChangeProp(selectionValue)
		} else {
			onChangeProp(null)
		}
	}

	return (
		<Select
			label="Type"
			{...rest}
			value={value}
			onChange={onChange}
			options={types ?? []}
			isLoading={isLoading}
			valueAccessor={(option) => option.id.toString()}
			textAccessor="description"
		/>
	)
}

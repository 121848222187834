import { FC } from "react"

import { TerritoryMinimal, useTerritories } from "@ncs/ncs-api"

import { useInitialId } from "../../util"
import { ExtendableSearchableSelectProps, SearchableSelect } from "../inputs"

export interface TerritorySelectorProps extends ExtendableSearchableSelectProps<TerritoryMinimal> {
	initialId?: string | null
}

export const TerritorySelector: FC<TerritorySelectorProps> = ({
	value,
	onChange,
	initialId,
	...rest
}) => {
	const [territories, loading] = useTerritories()

	useInitialId({
		initialId,
		onChange,
		currentValue: value,
		findInitialOption: () => territories?.find((t) => t.id === initialId),
	})

	return (
		<SearchableSelect
			options={territories ?? []}
			value={value ?? null}
			onItemSelect={onChange}
			getOptionLabel={(option) => `(${option.code}) ${option.description}`}
			disabled={loading}
			isLoading={loading}
			useSearchIcon
			label="Territory"
			placeholder="Search for a territory..."
			{...rest}
		/>
	)
}

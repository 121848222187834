import React from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import callApi from "@ncs/bricks/redux/services/callApi"
import { BaseSelectFilter, withDynamicTableFilter } from "@ncs/bricks/components"
import { loadLookups, LOOKUPS } from "../../redux/services/lookups"

class ProfitEndMonthFilter extends React.PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			locations: [],
		}
	}

	componentDidMount() {
		this.props.loadLookups([LOOKUPS.ProfitMonth])
	}

	render() {
		return (
			<BaseSelectFilter
				id="profitEndMonth"
				labelText="End Date"
				options={[{ value: "", description: "Select End Month" }, ...this.props.months]}
				valueAccessor={(x) => x.id}
				textAccessor="description"
				{...this.props}
			/>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		months: state.lookups.profitMonths,
	}
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ callApi, loadLookups }, dispatch)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withDynamicTableFilter("end_month")(ProfitEndMonthFilter))

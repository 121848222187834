import { FC, useMemo, useState } from "react"

import { UserProfile, UserProfileQueryParams, useUserProfile, useUserProfiles } from "@ncs/ncs-api"

import { useInitialId } from "../../util"
import { ExtendableSearchableSelectProps, SearchableSelect } from "../inputs"

export interface UserProfileSelectorProps extends ExtendableSearchableSelectProps<UserProfile> {
	params?: Partial<UserProfileQueryParams>
	initialId?: string | null
	employeesOnly?: boolean
	customersOnly?: boolean
}

export const UserProfileSelector: FC<UserProfileSelectorProps> = ({
	params,
	value,
	onChange,
	initialId,
	employeesOnly,
	customersOnly,
	...rest
}) => {
	const [search, setSearch] = useState<string | null>(null)

	const isCustomer =
		customersOnly != null ? customersOnly
		: employeesOnly != null ? !employeesOnly
		: undefined

	const { data, isLoading } = useUserProfiles({
		params: {
			isCustomer,
			...params,
			search,
		},
		pageSize: 15,
	})
	const [initialUser] = useUserProfile(initialId)

	useInitialId({
		initialId,
		onChange,
		currentValue: value,
		findInitialOption: () => initialUser,
	})

	const preparedData = useMemo(() => {
		let options = [...data]

		// `value` passed in from the parent may or may not be present in the search results data,
		// especially at first. Inject it here, and then filter it out of the data to prevent duplicate.
		options =
			value ?
				[
					value,
					...options.filter((d) => {
						return (
							// Remove the one already added
							d.id !== value.id &&
							// Weed out entries where name is an empty string.
							!!d.lastName
						)
					}),
				]
			:	options

		return options
	}, [data, value])

	const getOptionLabel = (option: UserProfile) => {
		return `${option.firstName} ${option.lastName}`
	}

	return (
		<SearchableSelect
			isLoading={isLoading}
			label="User"
			getOptionLabel={getOptionLabel}
			renderOption={getOptionLabel}
			{...rest}
			value={value}
			options={preparedData}
			onInputChange={setSearch}
			onItemSelect={onChange}
		/>
	)
}

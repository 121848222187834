import { FC } from "react"

import { ExtendableSelectProps, Select } from "@ncs/web-legos"

export type OrderLineStatusSelectorProps = ExtendableSelectProps<(typeof options)[number]>

export const OrderLineStatusSelector: FC<OrderLineStatusSelectorProps> = ({ ...rest }) => {
	return <Select label="Line status" {...rest} options={options} />
}

const options = [
	{
		value: "picking",
		text: "Picking",
	},
	{
		value: "shipping",
		text: "Shipping",
	},
	{
		value: "unassigned",
		text: "Unassigned",
	},
	{
		value: "drop_shipping",
		text: "Drop shipping",
	},
]

export const DISPATCH_STATUS = {
	Open: 1,
	Closed: 2,
	Completed: 3,
}

export const INVOICE_STATUS = {
	New: 2,
	Combined: 3,
	ReadyForInvoicing: 4,
	InvoiceGenerated: 5,
	Rejected: 6,
	PendingFieldApproval: 9,
	PendingSecondApproval: 10,
	Rebilled: 11,
	AdditionalEntry: 12,
	Invoiced: 13,
}

export const PART_ORDER_STATUS = {
	InProgress: 1,
	Submitted: 2,
	Ordered: 3,
	Shipped: 4,
	Received: 5,
	Removed: 6,
	InStock: 7,
	Pulled: 8,
	Invoiced: 9,
	Closed: 10,
}

export const DELIVERY_ORDER_STATUS = {
	ReadyToPick: "1",
	EnRoute: "2",
	PendingShipping: "3",
	Shipped: "4",
	Delivered: "5",
	BackOrdered: "6",
	Canceled: "7",
}

export const RETAIL_PAYMENT_TYPE = {
	ChargeAccount: 1,
	Cash: 2,
	CreditCard: 3,
}

export const APPLICATIONS = {
	BudgetAdmin: "Budget Admin",
	ChemBlend: "Chem Blend",
	ChemicalService: "Chemical Service",
	ChemMachineTypeAdmin: "Chemical Machine Type Administrator",
	ChemMachineTypeViewer: "Chemical Machine Type Viewer",
	ChemOrder: "Chemical Orders",
	ContractApprover: "Contract Approver",
	ContractCreator: "Contract Creator",
	ContractViewer: "Contract Viewer",
	ContractWashCounts: "Contract Wash Counts",
	CreateDispatch: "Create Dispatch",
	CreditMemoApproval: "Credit Memo Approval",
	CrossRefManagement: "Part Cross Reference",
	CsFreightLineApprover: "CS Freight Line Approver",
	CustomerFreightExceptions: "Customer Freight Exceptions",
	CustomerHierarchy: "Customer Hierarchy",
	CustomerManagement: "Customer Management",
	CustomerManagementReportsAdmin: "Customer Management Reports Admin",
	CustomerManagementBulkUploads: "Customer Management Bulk Uploads",
	CustomReports: "Custom Reports",
	DeliveryOrder: "Delivery Order",
	EquipmentManagement: "Equipment Management",
	ERP: "Equipment Repair Proposal",
	FinanceSuperUser: "Finance Super User",
	FreightPriceOverride: "Freight Price Override",
	FreightPricingViewer: "Freight Pricing Viewer",
	FreightRatesAdmin: "Freight Rates Admin",
	GeodisOverride: "Geodis Override",
	InfoRefAdmin: "Information Reference Administrator",
	InfoRefViewer: "Information Reference Viewer",
	InventoryManagement: "Inventory Management",
	InvoiceApproval: "Invoice Approval",
	InvoicePrint: "Invoice Print",
	LaborPricingAdmin: "Labor Pricing Admin",
	LaborRateAdmin: "Labor Rate Admin",
	LaborRateUser: "Labor Rate User",
	LocalPartManagement: "Locally Sourced Part",
	MachineAdmin: "Machine Admin",
	MachineCodes: "Machine Codes",
	MachineMilestone: "Machine Milestone",
	MachineMilestoneAdmin: "Machine Milestone Admin",
	ManufacturedParts: "Manufactured Parts",
	Mapping: "Mapping",
	MappingAdmin: "Mapping Admin",
	MarketingMaterialAdmin: "Marketing Material Admin",
	MarketingMaterialUser: "Marketing Material User",
	OpsFreightLineApprover: "Ops Freight Line Approver",
	PackageShipping: "Package Shipping",
	PartDiscountAdmin: "Part Discount Admin",
	PartManagementAdmin: "Part Management Admin",
	PartOrderHoldStatus: "Part Order Hold Status",
	PartReplacement: "Part Replacement",
	PartsOrder: "Parts Order",
	PartsOrderDelivery: "Parts Order Delivery",
	PhysicalInventory: "Physical Inventory",
	PickList: "Pick List",
	PointOfSaleAdmin: "Point of Sale Admin",
	PourDown: "Pour Down",
	ProductionBoardAdmin: "Production Board Admin",
	ProductionBoardUser: "Production Board User",
	RDCManager: "RDC Manager",
	ReassignOrderDc: "Reassign Order DC",
	RepairPartOrder: "Repair Part Order",
	Replenishment: "Replenishment",
	RetailSales: "Retail Sales",
	Shipping: "Shipping",
	SiteProfit: "Site Profitability",
	SiteProfitabilityTemp: "Site Profitability Temp",
	SiteSurveyAdmin: "Site Survey Admin",
	SiteSurveyUser: "Site Survey User",
	TicketingAdmin: "Ticketing Admin",
	TicketingFinanceAdmin: "Ticketing Finance Admin",
	TicketingSuperUser: "Ticketing Superuser",
	TicketingTechSupportUser: "Ticketing Tech Support User",
	TicketingUser: "Ticketing User",
	TSMUserManagement: "TSM User Management",
	UserManagement: "User Management",
	UserManagementAdmin: "User Management Admin",
	UserSchedule: "User Schedule",
	VacuumAdmin: "Vacuum Admin",
	VendorManagement: "Vendor Management",
	WashCounterAdmin: "Wash Counter Admin",
	WashCountSubmission: "Wash Count Submission",
}

export const REPORTS = {
	DispatchHistory: "Dispatch History",
	SiteSurveyGrouped: "Site Survey Grouped",
	SiteSurvey: "Site Survey",
	ERPSummary: "ERP Summary",
	ERPDetail: "ERP Detail",
	TicketLifecycle: "Ticket Lifecycle",
	NegativeOnHand: "Negative On-Hand",
	PickListDetail: "Pick List Detail",
	PartOrderDetail: "Part Order Detail",
	AllocatedParts: "Allocated Parts",
	PIPMDetail: "PMPI Detail",
	PIPMSummary: "PMPI Summary",
	PricingModifications: "Price Modifications",
}

export const HEADER_TYPES = {
	Default: 1,
	PartsOrder: 2,
	ExternalPartsOrder: 3,
	None: 4,
}

export const MACHINE_STATES = {
	DeviceInitialSetup: "Device Initial Setup",
	Inoperative: "Inoperative",
	Closed: "Closed",
	Idle: "Idle",
	CustomerEntry: "Customer Entry",
	Authorized: "Authorized",
	Washing: "Washing",
	WashIsSuspended: "Wash is suspended",
	DoneWashingResetting: "Done Washing (Resetting)",
	Maintenance: "Maintenance",
}

export const MONTHS = [
	"", // zero-based
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
]

export const MONTH_ABBREVIATIONS = [
	"", // zero-based
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"May",
	"Jun",
	"Jul",
	"Aug",
	"Sep",
	"Oct",
	"Nov",
	"Dec",
]

export const REPORT_FILTERS = {
	ReportPeriod: "report_period",
	StartDate: "start_date",
	EndDate: "end_date",
	Region: "region",
	Territory: "territory",
	Organization: "organization",
	Enterprise: "enterprise",
	Customer: "customer",
	Part: "part",
	Search: "search",
	Description: "description",
	Month: "month",
	PartOrderStatus: "part_order_status",
	DeliveryOrderStatus: "delivery_order_status",
	InvoiceStatus: "invoice_status",
	Vendors: "vendors",
	PickListStatus: "pick_list_status",
	CustomerRoutes: "customer_route",
	ERPStatus: "erp_status",
	StartMonth: "start_month",
	EndMonth: "end_month",
	IsActive: "is_active",
	IsFSTActive: "is_fst_active",
	IsAlternatePrice: "is_alternate_price",
	Vintages: "vintage",
	PurchaseOrderStatus: "purchase_order_status",
	DispatchCallType: "call_type",
	DispatchStatus: "dispatch_status",
	InventoryRequestStatus: "inventory_request_status",
	InventoryType: "inventory_type",
	ERPExpiredFilter: "is_expired",
	SiteSurveySubmitted: "is_submitted",
	Location: "location",
	TransferRequestStatus: "request_status",
	CustomerPortal: "customer_portal",
	CustomerPortalUser: "customer_portal_user",
	CustomerSite: "site",
	NCSModels: "NCS_models",
	NCSVintages: "NCS_vintages",
	User: "user",
	Warehouse: "warehouse",
}

export const CONTRACT_WASH_FILTERS = [
	REPORT_FILTERS.Search,
	REPORT_FILTERS.Customer,
	REPORT_FILTERS.Territory,
	REPORT_FILTERS.Enterprise,
	REPORT_FILTERS.Organization,
	REPORT_FILTERS.Month,
]

export const SITE_SURVEY_REPORT_FILTERS = [
	REPORT_FILTERS.ReportPeriod,
	REPORT_FILTERS.StartDate,
	REPORT_FILTERS.EndDate,
	REPORT_FILTERS.Territory,
	REPORT_FILTERS.Enterprise,
	REPORT_FILTERS.Organization,
	REPORT_FILTERS.Customer,
	REPORT_FILTERS.SiteSurveySubmitted,
]

export const SITE_PROFIT_FILTERS = [
	REPORT_FILTERS.Territory,
	REPORT_FILTERS.Enterprise,
	REPORT_FILTERS.Organization,
	REPORT_FILTERS.Customer,
	REPORT_FILTERS.StartMonth,
	REPORT_FILTERS.EndMonth,
]

export const DEFAULT_REPORT_FILTERS = [
	REPORT_FILTERS.ReportPeriod,
	REPORT_FILTERS.StartDate,
	REPORT_FILTERS.EndDate,
	REPORT_FILTERS.Region,
	REPORT_FILTERS.Territory,
	REPORT_FILTERS.Enterprise,
	REPORT_FILTERS.Organization,
	REPORT_FILTERS.Customer,
]

export const DISPATCH_HISTORY_FILTERS = [
	REPORT_FILTERS.ReportPeriod,
	REPORT_FILTERS.StartDate,
	REPORT_FILTERS.EndDate,
	REPORT_FILTERS.Region,
	REPORT_FILTERS.Territory,
	REPORT_FILTERS.Enterprise,
	REPORT_FILTERS.Organization,
	REPORT_FILTERS.Customer,
	REPORT_FILTERS.Vintages,
	REPORT_FILTERS.DispatchCallType,
	REPORT_FILTERS.DispatchStatus,
]

export const INVOICE_REPORT_FILTERS = {
	InvoiceType: "invoice_type",
}

export const INVOICE_FILTERS = [
	REPORT_FILTERS.ReportPeriod,
	REPORT_FILTERS.StartDate,
	REPORT_FILTERS.EndDate,
	REPORT_FILTERS.Region,
	REPORT_FILTERS.Territory,
	REPORT_FILTERS.Enterprise,
	REPORT_FILTERS.Organization,
	REPORT_FILTERS.Customer,
	INVOICE_REPORT_FILTERS.InvoiceType,
	REPORT_FILTERS.InvoiceStatus,
]

export const TICKETING_REPORT_FILTERS = {
	TicketGroup: "group",
	TicketType: "ticket_type",
	TicketSubType: "subtype",
	ReportPeriod: "report_period",
}

export const TICKETING_FILTERS = [
	TICKETING_REPORT_FILTERS.TicketGroup,
	TICKETING_REPORT_FILTERS.TicketType,
	TICKETING_REPORT_FILTERS.TicketSubType,
	TICKETING_REPORT_FILTERS.ReportPeriod,
	REPORT_FILTERS.Customer,
]

export const LABOR_PRICING_FILTERS = [
	REPORT_FILTERS.Search,
	REPORT_FILTERS.IsActive,
	REPORT_FILTERS.IsAlternatePrice,
]

export const CREATION_SOURCE = {
	PORTAL: 1,
	CTM: 2,
}

export const TICKET_GROUPS = {
	FINANCE: 1,
	IT: 2,
	TECH_SUPPORT: 3,
}

export const TICKET_STATUSES = {
	OPEN: 1,
	CLOSED: 4,
}

export const LINE_ITEM_TYPES = {
	FirstHour: { _id: 1, description: "FC - First Hour" },
	FlatRateTravelCharge: { _id: 2, description: "FC - Flat Rate / Travel Charge" },
	TravelLabor: { _id: 3, description: "FC - Travel Labor" },
	NonStandardFirstHour: { _id: 4, description: "FN - Non Standard First Hour" },
	NonStandardFlatRate: { _id: 5, description: "FN - Non Standard Flat Rate" },
	Freight: { _id: 6, description: "FR - Freight" },
	FuelSurcharge: { _id: 7, description: "FS - Fuel Surcharge" },
	AdditionalHours: { _id: 8, description: "LH - Additional Hours" },
	LaborHours: { _id: 9, description: "LH - Labor Hours" },
	LaborRate: { _id: 10, description: "LH - Labor Rate" },
	AdditionalHoursOvertime: { _id: 11, description: "LO - Additional Hours/Overtime" },
	LaborOvertime: { _id: 12, description: "LO - Labor/Overtime" },
	LaborOvertimeRate: { _id: 13, description: "LO - Labor Overtime Rate" },
	MiscellaneousSuppliesCharge: { _id: 14, description: "MC - Miscellaneous Supplies Charge" },
	MMInspection: { _id: 15, description: "MI - MM Inspection" },
	PMInspection: { _id: 16, description: "MI - PM Inspection" },
	NonContractFlatRateCharge: { _id: 17, description: "NC - Non-Contract Flat Rate Charge" },
	NonContractLaborRate: { _id: 18, description: "NC - Non-Contract Labor Rate" },
	OutsidePurchase: { _id: 19, description: "OP - Outside Purchase" },
	Parts: { _id: 21, description: "PT - Parts" },
	SubContractCharge: { _id: 22, description: "SB - Sub-Contract Charge" },
	InspectionOnly: { _id: 20, description: "PI - Inspection Only" },
	Salt: { _id: 23, description: "OP - Salt (40# Bag)" },
	OilChange: { _id: 24, description: "OP - Compressor Oil Change (Filter Inc.)" },
	AppointmentNotification: { _id: 25, description: "FR - Appointment Notification" },
	TankEndorsement: { _id: 26, description: "FR - Tanker Endorsement" },
	StopCharge: { _id: 27, description: "FR - Stop Charge" },
	Redelivery: { _id: 28, description: "FR - Redelivery" },
	ProtectiveService: { _id: 29, description: "FR - Protective Service" },
	PrivateResidenceFee: { _id: 30, description: "FR - Private Residence Fee" },
	PalletJack: { _id: 31, description: "FR - Pallet Jack Fee" },
	LimitedAccess: { _id: 32, description: "FR - Limited Access" },
	JobSiteDelivery: { _id: 33, description: "FR - Job Site Delivery" },
	InsideDeliveryPickup: { _id: 34, description: "FR - Inside Delivery/Pickup" },
	HighCostRegion: { _id: 35, description: "FR - High Cost Region" },
	HandlingFee: { _id: 36, description: "FR - Handling Fee" },
	HazmatFee: { _id: 37, description: "FR - Hazmat Fee" },
	GuaranteedDelivery: { _id: 38, description: "FR - Guaranteed Delivery" },
}

export const FILTER_OVERRIDE = "FILTER_OVERRIDE"
export const DATE_RANGE_FILTER_TYPE = "date_range_filter_type"

export const CALL_TYPES = {
	CourtesyCall: { _id: 1, code: "CC", description: "Courtesy Call" },
	Delivery: { _id: 2, code: "DLV", description: "Delivery" },
	Install: { _id: 3, code: "INS", description: "Install" },
	MaintenanceInspection: {
		_id: 4,
		code: "MI",
		description: "Maintenance Inspection",
		isContract: true,
	},
	PreventativeInspection: {
		_id: 5,
		code: "PI",
		description: "Preventative Inspection",
		isContract: true,
	},
	PremiumMaintenance: {
		_id: 6,
		code: "PM",
		description: "Premium Maintenance",
		isContract: true,
	},
	ServiceCall: { _id: 7, code: "SVC", description: "Service Call" },
	PrebidSurvey: { _id: 8, code: "PBS", description: "Prebid Survey" },
	ServiceUpgrade: { _id: 9, code: "SVC", description: "Service Upgrade" },
}

export const GENERIC_SHIPMENT_METHODS = {
	GROUND: {
		id: 1,
		description: "Ground",
		shippingServiceName: "FEDEX_GROUND",
	},
	TWO_DAY: {
		id: 2,
		description: "Two Day",
		shippingServiceName: "FEDEX_2_DAY",
	},
	OVERNIGHT: {
		id: 3,
		description: "Overnight",
		shippingServiceName: "STANDARD_OVERNIGHT",
	},
	LTL: {
		id: 8,
		description: "LTL",
		shippingServiceName: "WWL",
	},
}

export const INVOICE_TYPES = {
	DISPATCH: 1,
	PARTS_ORDER: 2,
	DELIVERY_ORDER: 4,
	KBM_INVOICE: 5,
}

export const CONTRACT_KBM_CODES = {
	CentsPerCar: "CPC",
	Bulk: "BLK",
	ChemicalMonthlyFixed: "CMF",
}

export const WASH_COUNT_SUBMISSION_STATUSES = {
	IN_PROGRESS: {
		id: 1,
		description: "In-Progress",
	},
	SUBMITTED: {
		id: 2,
		description: "Submitted",
	},
	INVOICE_GENERATED: {
		id: 3,
		description: "Invoice Generated",
	},
	INVOICED: {
		id: 4,
		description: "Invoiced",
	},
	REMOVED: {
		id: 5,
		description: "Removed",
	},
}

export const WASH_COUNT_ENTRY_TYPES = {
	CUSTOMER: {
		value: "customer",
		description: "Customer",
	},
	FIELD: {
		value: "field",
		description: "Field",
	},
	CUSTOMER_OR_FIELD: {
		value: "customerOrField",
		description: "Customer or field",
	},
}

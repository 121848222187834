import { FC, useMemo } from "react"

import dayjs from "dayjs"
import { useHistory } from "react-router-dom"
import { Cell, Column } from "react-table"

import { defaultErpQueryParams, Erp, ErpQueryParams, useErps } from "@ncs/ncs-api"
import {
	formatCurrency,
	formatDate,
	formatDateTime,
	getStartAndEndDateQueryParams,
	getTimeAgoFromStartDate,
	getTimezoneAbbreviation,
	Typify,
} from "@ncs/ts-utils"
import {
	CustomerQueryFilter,
	EmptyValueDash,
	EnterpriseQueryFilter,
	ErpStatusQueryFilter,
	OrganizationQueryFilter,
	SearchQueryFilter,
	Table,
	TerritoryQueryFilter,
	TimeAgoQueryFilter,
	usePaginationUrlState,
	useScrollToTopOnMount,
	useUrlState,
} from "@ncs/web-legos"

export type QuotesUrlState = Typify<ErpQueryParams>

export const QuotesViewTab: FC = () => {
	useScrollToTopOnMount()
	const history = useHistory()

	const [queryParams, { setUrlState: setQueryParams }] = useUrlState<QuotesUrlState>({
		...defaultErpQueryParams,
		startDate,
	})
	const [pagination, setPagination] = usePaginationUrlState()
	const params = useMemo(() => {
		return {
			...queryParams,
			...pagination,
		}
	}, [queryParams, pagination])

	const erpQuery = useErps({ params, manualPagination: true })

	const moreFiltersOpen = useMemo(() => {
		return !!(
			queryParams.organization ||
			queryParams.enterprise ||
			queryParams.territory ||
			queryParams.customer ||
			queryParams.erpStatus
		)
	}, [
		queryParams.organization,
		queryParams.enterprise,
		queryParams.territory,
		queryParams.customer,
		queryParams.erpStatus,
	])

	return (
		<>
			<Table
				query={erpQuery}
				columns={columns}
				pinnedQueryFilters={[SearchQueryFilter, TimeAgoQueryFilter]}
				toggledQueryFilters={[
					CustomerQueryFilter,
					TerritoryQueryFilter,
					EnterpriseQueryFilter,
					OrganizationQueryFilter,
					ErpStatusQueryFilter,
				]}
				queryParamState={queryParams}
				setQueryParamState={setQueryParams}
				pagination={pagination}
				setPagination={setPagination}
				onRowClick={(row) =>
					history.push({
						pathname: `/quotes/${row.original.id}`,
						state: params,
					})
				}
				showToggledFiltersByDefault={moreFiltersOpen}
			/>
		</>
	)
}

const columns: Column<Erp>[] = [
	{
		Header: "Customer #",
		id: "customer_CustomerNumber",
		accessor: ({ customer }) => customer.customerNumber,
	},
	{
		Header: "Name",
		id: "customer_Name",
		accessor: ({ customer }) => customer.name,
	},
	{
		Header: `Created (${getTimezoneAbbreviation()})`,
		id: "createdDate",
		accessor: ({ createdDate }) => formatDateTime(createdDate),
	},
	{
		Header: "Total",
		accessor: ({ total }) => formatCurrency(total),
		disableSortBy: true,
	},
	{
		Header: "Status",
		id: "erpStatus_Description",
		accessor: ({ erpStatus }) => erpStatus.code,
	},
	{
		Header: "Territory",
		id: "customer_Territory_Code",
		accessor: ({ customer }) =>
			customer.territory ?
				`(${customer.territory.code}) ${customer.territory.description}`
			:	"-",
		hiddenByDefault: true,
	},
	{
		Header: "Expiration date",
		accessor: "expirationDate",
		hiddenByDefault: true,
		Cell: ({ row: { original } }: Cell<Erp>) => {
			return original.expirationDate ?
					formatDate(original.expirationDate)
				:	<EmptyValueDash />
		},
		disableSortBy: true,
	},
]

const [startDate] = getStartAndEndDateQueryParams(
	getTimeAgoFromStartDate(dayjs().subtract(6, "months").toISOString())
)

import { FC, useState } from "react"

import { Contract, makeApiErrorMessage, useUpdateOrganizationContract } from "@ncs/ncs-api"
import { Checkbox, ExtendableModalProps, Modal, Paragraph, useToast } from "@ncs/web-legos"

export interface EditProductCoverageModalProps extends ExtendableModalProps {
	contract: Contract
}

export const EditProductCoverageModal: FC<EditProductCoverageModalProps> = ({
	contract,
	...rest
}) => {
	const { makeSuccessToast } = useToast()

	const [partsCovered, setPartsCovered] = useState(contract.partsCovered)
	const [chemicalsCovered, setChemicalsCovered] = useState(contract.chemicalsCovered)
	const [equipmentInstallCovered, setEquipmentInstallCovered] = useState(
		contract.equipmentInstallCovered
	)
	const [maintenanceCovered, setMaintenanceCovered] = useState(contract.maintenanceCovered)

	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const updateContract = useUpdateOrganizationContract()

	const handleSave = async () => {
		try {
			setIsSaving(true)
			await updateContract({
				updates: {
					contract: contract.id,
					partsCovered,
					chemicalsCovered,
					equipmentInstallCovered,
					maintenanceCovered,
				},
			})
			makeSuccessToast("Contract updated")
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const reset = () => {
		setPartsCovered(contract.partsCovered)
		setChemicalsCovered(contract.chemicalsCovered)
		setEquipmentInstallCovered(contract.equipmentInstallCovered)
		setMaintenanceCovered(contract.maintenanceCovered)
		setIsSaving(false)
		setErrorText(null)
	}

	return (
		<Modal
			{...rest}
			title="Product Coverage"
			errorText={errorText}
			onOpen={reset}
			rightButtons={{
				buttonText: "Save Changes",
				onClick: handleSave,
				isLoading: isSaving,
			}}
		>
			<Paragraph mb={1}>Which of these are covered in the contract?</Paragraph>
			<Checkbox value={partsCovered} onChange={setPartsCovered} label="Parts" mb={0} />
			<Checkbox
				value={chemicalsCovered}
				onChange={setChemicalsCovered}
				label="Chemicals"
				mb={0}
			/>
			<Checkbox
				value={equipmentInstallCovered}
				onChange={setEquipmentInstallCovered}
				label="Equipment / Install"
				mb={0}
			/>
			<Checkbox
				value={maintenanceCovered}
				onChange={setMaintenanceCovered}
				label="Maintenance"
				mb={0}
			/>
		</Modal>
	)
}

import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import Uppy from "@uppy/core"
import toArray from "@uppy/utils/lib/toArray"
import ThumbnailGenerator from "@uppy/thumbnail-generator"
import XHRUpload from "@uppy/xhr-upload"
import { RSAA, isRSAA } from "redux-api-middleware"

import { InputAdornment, FormHelperText } from "@material-ui/core"

import {
	ButtonWithIcon,
	ConditionalContent,
	GridContainer,
	GridItem,
	InputAsDisplay,
} from "../../components"
import { prepareApiCall } from "../../middleware/portalApiMiddleware"
import { ensureAccessTokenIsFresh } from "../../redux/services/callApi"

// icons
import { AttachFile, Close } from "@material-ui/icons"

// styles
import { dangerColor } from "../../assets/jss/material-dashboard-pro-react"

class FileInput extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			files: null,
			filename: "",
			fileThumbnail: null,
		}
		this.fileInputRef = React.createRef()
		this.uppy = new Uppy({
			restrictions: {
				maxNumberOfFiles:
					typeof this.props.maxNumberOfFiles !== "number" ?
						this.props.maxNumberOfFiles
					:	1,
				minNumberOfFiles:
					typeof this.props.minNumberOfFiles !== "number" ?
						this.props.minNumberOfFiles
					:	0,
				maxFileSize: this.props.maxFileSize,
				allowedFileTypes: this.props.allowedFileTypes,
			},
			autoProceed: false,
		})
			.use(ThumbnailGenerator, {
				thumbnailWidth: 200,
				waitForThumbnailsBeforeUpload: false,
			})
			.on("thumbnail:generated", this.handleThumbnailGenerated)
	}

	handleThumbnailGenerated = (file, preview) => {
		this.setState({ fileThumbnail: preview })
		if (typeof this.props.onThumbnailGenerated === "function") {
			this.props.onThumbnailGenerated(preview, file)
		}
	}

	handleFileChange = (event) => {
		this.uppy.log("[FileInput] Something selected through input...")

		let files = event.target.files
		this.setState({ files, filename: !files ? "" : files[0].name })
		if (typeof this.props.onFileSelected === "function") {
			this.props.onFileSelected(files)
		}

		const fileArray = toArray(event.target.files)
		fileArray.forEach((file) => {
			try {
				this.uppy.addFile({
					source: this.id,
					name: file.name,
					type: file.type,
					data: file,
				})
			} catch (err) {
				if (!err.isRestriction) {
					this.uppy.log(err)
				}
			}
		})

		// We clear the input after a file is selected, because otherwise
		// change event is not fired in Chrome and Safari when a file
		// with the same name is selected.
		event.target.value = null
	}

	handleClick = () => {
		this.fileInputRef.current.click()

		if (typeof this.props.onChooseFile === "function") {
			this.props.onChooseFile()
		}
	}

	handleRemove = (file) => {
		this.setState({
			files: null,
			filename: "",
			fileThumbnail: null,
		})
		this.fileInputRef.current.value = null

		this.uppy.getFiles().forEach((x) => this.uppy.removeFile(x.id))

		if (typeof this.props.onFileSelectionCanceled === "function") {
			this.props.onFileSelectionCanceled()
		}
	}

	startUpload = async (action) => {
		if (!isRSAA(action)) {
			console.error("An RSAA action is required for FileInput.startUpload to function")
			return
		}

		this.setState({ isUploading: true })

		await this.props.ensureAccessTokenIsFresh()

		let api = prepareApiCall(action, this.props.stateWithAuthOnly)

		const { endpoint, method, body: data, headers } = api[RSAA]

		this.uppy.use(XHRUpload, {
			endpoint,
			method,
			headers,
			formData: true,
			fieldName: this.props.filesFieldName,
			bundle: true,
		})

		this.uppy.setMeta(data)

		let uploadPromise = this.uppy.upload()
		if (typeof this.props.onUploadComplete === "function") {
			uploadPromise.then(this.props.onUploadComplete)
		}

		// create a manual promise to fake the payload/error properties so it acts like an RSAA response
		let rsaaPromise = new Promise((resolve, reject) => {
			uploadPromise
				.then((result, b, c, d) => {
					let failedFiles = result.failed

					if (failedFiles.length > 0) {
						return reject({
							payload: failedFiles[0].response.body,
							status: failedFiles[0].status,
							error: true,
						})
					}

					let successfulFiles = result.successful
					if (successfulFiles.length === 0) {
						return reject({ payload: "No uploads succeeded", error: true })
					}

					resolve({
						payload: successfulFiles[0].response.body,
						status: successfulFiles[0].status,
					})
				})
				.catch((result, b, c, d) => {
					reject({ payload: result, error: true })
				})
		})
		return rsaaPromise
	}

	render() {
		const { buttonProps, allowedFileTypes, isError, helperText } = this.props
		const { filename, fileThumbnail } = this.state
		const accept = allowedFileTypes ? allowedFileTypes.join(",") : null

		return (
			<GridContainer>
				<GridItem xs={12}>
					<input
						type="file"
						onChange={this.handleFileChange}
						multiple={false}
						accept={accept}
						ref={this.fileInputRef}
						style={{ display: "none" }}
					/>

					{/* Thumbnail Generator here */}
					<ConditionalContent hide={!fileThumbnail}>
						<img src={fileThumbnail} alt="File Preview" />
					</ConditionalContent>

					<ConditionalContent hide={!filename}>
						<InputAsDisplay
							labelText="Filename"
							value={filename}
							inputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<ButtonWithIcon
											justIcon
											round
											size="sm"
											color="danger"
											onClick={this.handleRemove}
											icon={<Close />}
										/>
									</InputAdornment>
								),
							}}
						/>
					</ConditionalContent>

					<ConditionalContent show={!filename}>
						<ButtonWithIcon
							icon={<AttachFile />}
							content="Choose File"
							round
							size="sm"
							{...buttonProps}
							onClick={this.handleClick}
						/>
					</ConditionalContent>

					<ConditionalContent show={isError === true && !!helperText}>
						<FormHelperText
							id="file-input-text"
							style={isError ? `color: ${dangerColor[0]} !important` : undefined}
						>
							{helperText}
						</FormHelperText>
					</ConditionalContent>
				</GridItem>
			</GridContainer>
		)
	}
}

FileInput.defaultProps = {
	showPreview: true,
	filesFieldName: "file",
	onUploadComplete: () => {},
}

FileInput.propTypes = {
	onChooseFile: PropTypes.func,
	onFileSelected: PropTypes.func.isRequired,
	onFileSelectionCanceled: PropTypes.func,
	onThumbnailGenerated: PropTypes.func,
	onUploadComplete: PropTypes.func,
	getPostData: PropTypes.func,
	showPreview: PropTypes.bool,
	buttonProps: PropTypes.object,
	allowedFileTypes: PropTypes.arrayOf(PropTypes.string),
	maxFileSize: PropTypes.number,
	maxNumberOfFiles: PropTypes.number,
	minNumberOfFiles: PropTypes.number,
	filesFieldName: PropTypes.string,
	isError: PropTypes.bool,
	helperText: PropTypes.node,
}

const mapStateToProps = ({ auth }) => {
	return {
		stateWithAuthOnly: { auth },
	}
}

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			ensureAccessTokenIsFresh: () => ensureAccessTokenIsFresh,
		},
		dispatch
	),
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(FileInput)

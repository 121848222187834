import React from "react"
import cx from "classnames"
import PropTypes from "prop-types"
import {
	withStyles,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TableFooter,
} from "@material-ui/core"

import tableStyle from "../../assets/jss/material-dashboard-pro-react/components/tableStyle"

function CustomTable({ ...props }) {
	const {
		classes,
		tableHead,
		tableData,
		tableFooter,
		tableHeaderColor,
		hover,
		colorsColls,
		coloredColls,
		customCellClasses,
		customClassesForCells,
		striped,
		tableShopping,
		customHeadCellClasses,
		customHeadClassesForCells,
		customFooterCellClasses,
		customFooterClassesForCells,
		customRowClass,
		hasData,
		zeroDataContent,
		getTrProps,
	} = props

	return (
		<div className={classes.tableResponsive}>
			{typeof hasData !== "undefined" && !hasData ?
				<span>{zeroDataContent}</span>
			:	<Table className={classes.table}>
					{tableHead !== undefined ?
						<TableHead className={classes[tableHeaderColor]}>
							<TableRow className={classes.tableRow}>
								{tableHead.map((prop, key) => {
									const tableCellClasses =
										classes.tableHeadCell +
										" " +
										classes.tableCell +
										" " +
										cx({
											[customHeadCellClasses[
												customHeadClassesForCells.indexOf(key)
											]]: customHeadClassesForCells.indexOf(key) !== -1,
											[classes.tableShoppingHead]: tableShopping,
											[classes.tableHeadFontSize]: !tableShopping,
										})
									return (
										<TableCell className={tableCellClasses} key={key}>
											{prop}
										</TableCell>
									)
								})}
							</TableRow>
						</TableHead>
					:	null}
					<TableBody>
						{tableData.map((prop, key) => {
							var rowColor = ""
							var rowColored = false
							if (prop.color !== undefined) {
								rowColor = prop.color
								rowColored = true
							}
							if (prop.data !== undefined) {
								prop = prop.data
							}
							const tableRowClasses =
								customRowClass +
								" " +
								cx({
									[classes.tableRowHover]: hover,
									[classes[rowColor + "Row"]]: rowColored,
									[classes.tableStripedRow]: striped && key % 2 === 0,
								})
							if (prop.total) {
								return (
									<TableRow
										key={key}
										hover={hover}
										className={tableRowClasses}
										{...getTrProps(prop, key)}
									>
										<TableCell
											className={classes.tableCell}
											colSpan={prop.colspan}
										/>
										<TableCell
											className={
												classes.tableCell + " " + classes.tableCellTotal
											}
										>
											Total
										</TableCell>
										<TableCell
											className={
												classes.tableCell + " " + classes.tableCellAmount
											}
										>
											{prop.amount}
										</TableCell>
										{tableHead.length - (prop.colspan - 0 + 2) > 0 ?
											<TableCell
												className={classes.tableCell}
												colSpan={tableHead.length - (prop.colspan - 0 + 2)}
											/>
										:	null}
									</TableRow>
								)
							}
							if (prop.purchase) {
								return (
									<TableRow
										key={key}
										hover={hover}
										className={tableRowClasses}
										{...getTrProps(prop, key)}
									>
										<TableCell
											className={classes.tableCell}
											colSpan={prop.colspan}
										/>
										<TableCell
											className={classes.tableCell + " " + classes.right}
											colSpan={prop.col.colspan}
										>
											{prop.col.text}
										</TableCell>
									</TableRow>
								)
							}
							return (
								<TableRow
									key={key}
									hover={hover}
									className={classes.tableRow + " " + tableRowClasses}
									{...getTrProps(prop, key)}
								>
									{prop.map((prop, key) => {
										const tableCellClasses =
											classes.tableCell +
											" " +
											cx({
												[classes[colorsColls[coloredColls.indexOf(key)]]]:
													coloredColls.indexOf(key) !== -1,
												[customCellClasses[
													customClassesForCells.indexOf(key)
												]]: customClassesForCells.indexOf(key) !== -1,
											})
										return (
											<TableCell className={tableCellClasses} key={key}>
												{prop}
											</TableCell>
										)
									})}
								</TableRow>
							)
						})}
					</TableBody>
					{tableFooter !== undefined ?
						<TableFooter>
							<TableRow className={classes.tableRow}>
								{tableFooter.map((prop, key) => {
									const footerCellClasses =
										classes.tableFooterCell +
										" " +
										classes.tableCell +
										" " +
										cx({
											[customFooterCellClasses[
												customFooterClassesForCells.indexOf(key)
											]]: customFooterClassesForCells.indexOf(key) !== -1,
										})
									return (
										<TableCell className={footerCellClasses} key={key}>
											{prop}
										</TableCell>
									)
								})}
							</TableRow>
						</TableFooter>
					:	null}
				</Table>
			}
		</div>
	)
}

CustomTable.defaultProps = {
	tableHeaderColor: "gray",
	hover: false,
	colorsColls: [],
	coloredColls: [],
	striped: false,
	customCellClasses: [],
	customClassesForCells: [],
	customHeadCellClasses: [],
	customHeadClassesForCells: [],
	customFooterCellClasses: [],
	customFooterClassesForCells: [],
	getTrProps: () => ({}),
}

CustomTable.propTypes = {
	classes: PropTypes.object.isRequired,
	tableHeaderColor: PropTypes.oneOf([
		"warning",
		"primary",
		"danger",
		"success",
		"info",
		"rose",
		"gray",
	]),
	tableHead: PropTypes.arrayOf(PropTypes.node),
	// Of(PropTypes.arrayOf(PropTypes.node)) || Of(PropTypes.object),
	tableData: PropTypes.array,
	tableFooter: PropTypes.array,
	hover: PropTypes.bool,
	coloredColls: PropTypes.arrayOf(PropTypes.number),
	// Of(["warning","primary","danger","success","info","rose","gray"]) - colorsColls
	colorsColls: PropTypes.array,
	customCellClasses: PropTypes.arrayOf(PropTypes.string),
	customClassesForCells: PropTypes.arrayOf(PropTypes.number),
	customHeadCellClasses: PropTypes.arrayOf(PropTypes.string),
	customHeadClassesForCells: PropTypes.arrayOf(PropTypes.number),
	customFooterCellClasses: PropTypes.arrayOf(PropTypes.string),
	customFooterClassesForCells: PropTypes.arrayOf(PropTypes.number),
	striped: PropTypes.bool,
	// this will cause some changes in font
	tableShopping: PropTypes.bool,

	// whether the table will not be empty
	hasData: PropTypes.bool,
	// displays this content instead of the table if hasData is false
	zeroDataContent: PropTypes.node,
	getTrProps: PropTypes.func,
}

export default withStyles(tableStyle)(CustomTable)

import { FC, useEffect, useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import isEqual from "lodash/isEqual"
import { useForm } from "react-hook-form"
import { z } from "zod"

import { makeApiErrorMessage, useVacuumConfigPatch, VacuumConfig } from "@ncs/ncs-api"
import { DeepPartial } from "@ncs/ts-utils"
import {
	Box,
	Button,
	Divider,
	GridContainer,
	GridContainerProps,
	GridItem,
	GridItemProps,
	HeadingDivider,
	HeadingDividerProps,
	NumericInputFormField,
	TextInputFormField,
	usePrevious,
	useToast,
} from "@ncs/web-legos"

export interface ConnectedVacuumConfigFormProps {
	config: VacuumConfig | null
}

const sharedHeadingDividerProps: Partial<HeadingDividerProps> = {
	headingVariant: "h5",
	uppercase: true,
	mt: 3,
	mb: 0.75,
}

const sharedGridContainerProps: Partial<GridContainerProps> = {
	rowGap: 0,
}

const sharedGridItemProps: Partial<GridItemProps> = {
	xs: 6,
	sm: 3,
}

export const ConnectedVacuumConfigForm: FC<ConnectedVacuumConfigFormProps> = ({ config }) => {
	const [isSubmitting, setIsSubmitting] = useState(false)
	const updateConfig = useVacuumConfigPatch()
	const { makeSuccessToast, makeErrorToast } = useToast()

	const {
		control,
		handleSubmit,
		reset,
		formState: { isValid, isDirty },
	} = useForm<VacuumConfigForm>({
		resolver: zodResolver(VacuumConfigFormSchema),
		mode: "onChange",
		reValidateMode: "onChange",
		defaultValues: makeDefaultValues(config),
	})

	const onSave = async (formData: VacuumConfigForm) => {
		try {
			setIsSubmitting(true)
			await updateConfig({ updates: formData, id: config?.id })
			makeSuccessToast("Config update saved")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		} finally {
			setIsSubmitting(false)
		}
	}

	// After you save, we invalidate the config query and refetch it, resulting
	// in the updated config being passed down at that point. Listen for that change,
	// and reset if it happens.
	const prevConfig = usePrevious(config)
	useEffect(() => {
		if (!isEqual(config, prevConfig)) {
			reset(makeDefaultValues(config))
		}
	}, [config, prevConfig, reset])

	const onCancel = () => {
		reset(makeDefaultValues(config))
	}

	return (
		<>
			<HeadingDivider headingText="Connectivity" {...sharedHeadingDividerProps} mt={0} />
			<GridContainer {...sharedGridContainerProps}>
				<GridItem {...sharedGridItemProps}>
					<NumericInputFormField
						control={control}
						name="filterPressureHigh"
						label="Filter pressure high"
						decimalScale={4}
					/>
				</GridItem>
				<GridItem {...sharedGridItemProps}>
					<NumericInputFormField
						control={control}
						name="pressureHighInterval"
						label="Pressure high interval"
					/>
				</GridItem>
				<GridItem {...sharedGridItemProps}>
					<NumericInputFormField control={control} name="vib1High" label="Vib 1 high" />
				</GridItem>
				<GridItem {...sharedGridItemProps}>
					<NumericInputFormField control={control} name="vib2High" label="Vib 2 high" />
				</GridItem>
				<GridItem {...sharedGridItemProps}>
					<NumericInputFormField
						control={control}
						name="vibHighInterval"
						label="Vib high interval"
					/>
				</GridItem>
				<GridItem {...sharedGridItemProps}>
					<NumericInputFormField
						control={control}
						name="dataInterval"
						label="Data interval"
					/>
				</GridItem>
			</GridContainer>

			<HeadingDivider headingText="Drive 1" {...sharedHeadingDividerProps} />
			<GridContainer {...sharedGridContainerProps}>
				<GridItem {...sharedGridItemProps}>
					<NumericInputFormField
						control={control}
						name="driveOne.address"
						label="Address"
					/>
				</GridItem>
				<GridItem {...sharedGridItemProps}>
					<TextInputFormField control={control} name="driveOne.type" label="Type" />
				</GridItem>
				<GridItem {...sharedGridItemProps}>
					<TextInputFormField control={control} name="driveOne.p1" label="P 1" />
				</GridItem>
				<GridItem {...sharedGridItemProps}>
					<TextInputFormField control={control} name="driveOne.p2" label="P 2" />
				</GridItem>
				<GridItem {...sharedGridItemProps}>
					<TextInputFormField control={control} name="driveOne.vib1" label="Vib 1" />
				</GridItem>
				<GridItem {...sharedGridItemProps}>
					<TextInputFormField control={control} name="driveOne.vib2" label="Vib 2" />
				</GridItem>
			</GridContainer>

			<HeadingDivider headingText="Drive 2" {...sharedHeadingDividerProps} />
			<GridContainer {...sharedGridContainerProps}>
				<GridItem {...sharedGridItemProps}>
					<NumericInputFormField
						control={control}
						name="driveTwo.address"
						label="Address"
					/>
				</GridItem>
				<GridItem {...sharedGridItemProps}>
					<TextInputFormField control={control} name="driveTwo.type" label="Type" />
				</GridItem>
				<GridItem {...sharedGridItemProps}>
					<TextInputFormField control={control} name="driveTwo.p1" label="P 1" />
				</GridItem>
				<GridItem {...sharedGridItemProps}>
					<TextInputFormField control={control} name="driveTwo.p2" label="P 2" />
				</GridItem>
				<GridItem {...sharedGridItemProps}>
					<TextInputFormField control={control} name="driveTwo.vib1" label="Vib 1" />
				</GridItem>
				<GridItem {...sharedGridItemProps}>
					<TextInputFormField control={control} name="driveTwo.vib2" label="Vib 2" />
				</GridItem>
			</GridContainer>

			<HeadingDivider headingText="Serial" {...sharedHeadingDividerProps} />
			<GridContainer {...sharedGridContainerProps}>
				<GridItem {...sharedGridItemProps}>
					<TextInputFormField
						control={control}
						name="serial.stopBits"
						label="Stop bits"
					/>
				</GridItem>
				<GridItem {...sharedGridItemProps}>
					<NumericInputFormField
						control={control}
						name="serial.baudRate"
						label="Baud rate"
					/>
				</GridItem>
				<GridItem {...sharedGridItemProps}>
					<TextInputFormField control={control} name="serial.parity" label="Parity" />
				</GridItem>
			</GridContainer>

			<Divider />
			<Box display="flex" alignItems="center" columnGap={1.5}>
				<Button
					variant="primary-cta"
					onClick={handleSubmit(onSave)}
					isLoading={isSubmitting}
					disabled={!isValid || !isDirty}
				>
					Save
				</Button>
				<Button onClick={onCancel} disabled={!isDirty}>
					Cancel
				</Button>
			</Box>
		</>
	)
}

const VacuumConfigFormSchema = z.object({
	driveOne: z.object({
		address: z.number().int(),
		type: z.string().nonempty(),
		p1: z.string().nullable(),
		p2: z.string().nullable(),
		vib1: z.string().nullable(),
		vib2: z.string().nullable(),
	}),
	driveTwo: z.object({
		address: z.number().int().nullable(),
		type: z.string().nullable(),
		p1: z.string().nullable(),
		p2: z.string().nullable(),
		vib1: z.string().nullable(),
		vib2: z.string().nullable(),
	}),
	serial: z.object({
		stopBits: z.string().nonempty(),
		baudRate: z.number().int(),
		parity: z.string().nonempty(),
	}),
	filterPressureHigh: z.number(),
	pressureHighInterval: z.number().int(),
	vib1High: z.number().min(0).max(10),
	vib2High: z.number().min(0).max(10).nullable(),
	dataInterval: z.number().int(),
	vibHighInterval: z.number().int().nullable(),
})

type VacuumConfigForm = z.infer<typeof VacuumConfigFormSchema>

/**
 * `VacuumConfig` contains everything that's in `VacuumConfigForm`, but also has
 * some additional stuff so we'll map it to the form here so we don't include
 * other unknown fields.
 */
const makeDefaultValues = (config: VacuumConfig | null): DeepPartial<VacuumConfigForm> => {
	return {
		driveOne: {
			address: config?.driveOne?.address,
			type: config?.driveOne?.type,
			p1: config?.driveOne?.p1 ?? null,
			p2: config?.driveOne?.p2 ?? null,
			vib1: config?.driveOne?.vib1 ?? null,
			vib2: config?.driveOne?.vib2 ?? null,
		},
		driveTwo: {
			address: config?.driveTwo?.address ?? null,
			type: config?.driveTwo?.type ?? null,
			p1: config?.driveTwo?.p1 ?? null,
			p2: config?.driveTwo?.p2 ?? null,
			vib1: config?.driveTwo?.vib1 ?? null,
			vib2: config?.driveTwo?.vib2 ?? null,
		},
		serial: {
			stopBits: config?.serial?.stopBits,
			baudRate: config?.serial?.baudRate,
			parity: config?.serial?.parity,
		},
		filterPressureHigh: config?.filterPressureHigh,
		pressureHighInterval: config?.pressureHighInterval,
		vib1High: config?.vib1High,
		vib2High: config?.vib2High ?? null,
		dataInterval: config?.dataInterval,
		vibHighInterval: config?.vibHighInternal ?? null,
	}
}

import { get, post, patch, del, rsaaTypes, RSAA } from "@ncs/bricks/middleware/portalApiMiddleware"

const FETCH_PURCHASE_ORDERS = rsaaTypes("purchase_orders/list")
const GET_PURCHASE_ORDER = rsaaTypes("purchase_order/get")
const PURCHASE_ORDER_CREATE = rsaaTypes("purchase_order/create")
const CANCEL_PURCHASE_ORDER = rsaaTypes("purchase_order/cancel")
const UPDATE_PURCHASE_ORDER_HEADER = rsaaTypes("purchase_order/update")
const UPDATE_PURCHASE_ORDER_LINE = rsaaTypes("purchase_order/lines/update")
const ADD_PURCHASE_ORDER_LINE = rsaaTypes("purchase_order/lines/add")
const REMOVE_PURCHASE_ORDER_LINE = rsaaTypes("purchase_order/lines/remove")
const PLACE_PURCHASE_ORDER = rsaaTypes("purchase_order/place_order")
const FORCE_SHIP_PURCHASE_ORDER = rsaaTypes("puchase_order/force_ship")
const RECEIVE_PURCHASE_ORDER_LINE = rsaaTypes("purchase_order/receive")
const GET_PURCHASE_ORDER_DOCUMENTS = rsaaTypes("purchase_order/documents")
const RECREATE_ORDER_DOCUMENTS = rsaaTypes("purchase_order/recreate/documents")

export const fetchPurchaseOrders = (querystringParams) => ({
	[RSAA]: {
		api: get(`/purchase_orders/`, querystringParams),
		types: FETCH_PURCHASE_ORDERS,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const fetchPurchaseOrder = (orderId) => ({
	[RSAA]: {
		api: get(`/purchase_orders/${orderId}/`),
		types: GET_PURCHASE_ORDER,
	},
})

export const addOrderDeliveryComment = (params) => ({
	[RSAA]: {
		api: post("/parts/add_delivery_comment/", params),
		types: PURCHASE_ORDER_CREATE,
	},
})

export const purchaseOrderCreate = (order) => ({
	[RSAA]: {
		api: post("/purchase_orders/", order),
		types: PURCHASE_ORDER_CREATE,
	},
})

export const purchaseOrderPlaceOrder = (orderId) => ({
	[RSAA]: {
		api: post(`/purchase_orders/${orderId}/place_order/`),
		types: PLACE_PURCHASE_ORDER,
	},
})

export const purchaseOrderForceShip = (orderId) => ({
	[RSAA]: {
		api: post(`/purchase_orders/${orderId}/force_ship/`),
		types: FORCE_SHIP_PURCHASE_ORDER,
	},
})

export const purchaseOrderReceive = (item) => ({
	[RSAA]: {
		api: post(`/parts/receive_purchase_order/`, item),
		types: RECEIVE_PURCHASE_ORDER_LINE,
	},
})

export const getPurchaseOrderDocuments = (orderId) => ({
	[RSAA]: {
		api: get(`/purchase_orders/${orderId}/documents/`),
		types: GET_PURCHASE_ORDER_DOCUMENTS,
	},
})

export const purchaseOrderRecreateDocument = (orderId) => ({
	[RSAA]: {
		api: post(`/purchase_orders/${orderId}/recreate_document/`),
		types: RECREATE_ORDER_DOCUMENTS,
	},
})

export const cancelPurchaseOrder = (orderId) => ({
	[RSAA]: {
		api: post(`/purchase_orders/${orderId}/cancel/`),
		types: CANCEL_PURCHASE_ORDER,
	},
})

export const updatePurchaseOrderHeader = (orderId, updatedItems) => ({
	[RSAA]: {
		api: patch(`/purchase_orders/${orderId}/`, updatedItems),
		types: UPDATE_PURCHASE_ORDER_HEADER,
	},
})

export const updatePurchaseOrderLine = (orderId, lineId, updatedItems) => ({
	[RSAA]: {
		api: patch(`/purchase_orders/${orderId}/line_items/${lineId}/`, updatedItems),
		types: UPDATE_PURCHASE_ORDER_LINE,
	},
})

export const addPurchaseOrderLine = (orderId, lineItems) => ({
	[RSAA]: {
		api: post(`/purchase_orders/${orderId}/line_items/`, lineItems),
		types: ADD_PURCHASE_ORDER_LINE,
	},
})

export const removePurchaseOrderLine = (orderId, lineId) => ({
	[RSAA]: {
		api: del(`/purchase_orders/${orderId}/line_items/${lineId}/`),
		types: REMOVE_PURCHASE_ORDER_LINE,
	},
})

const initialState = {
	isLoadingPurchaseOrders: false,
	purchaseOrders: [],
	error: null,
}

const purchaseOrder = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_PURCHASE_ORDERS.request:
		case FETCH_PURCHASE_ORDERS.success:
		case FETCH_PURCHASE_ORDERS.failure:
			return {
				...state,
				isLoadingPurchaseOrders: action.type === FETCH_PURCHASE_ORDERS.request,
				purchaseOrders:
					action.type === FETCH_PURCHASE_ORDERS.success ?
						action.payload
					:	state.purchaseOrders,
				error: action.error,
			}

		default:
			return state
	}
}
export default purchaseOrder

import { FC } from "react"

import { ExtendableSelectProps, Select } from "@ncs/web-legos"

export type IncludeExpiredSelectorProps = ExtendableSelectProps<(typeof options)[number]>

export const IncludeExpiredSelector: FC<IncludeExpiredSelectorProps> = ({ ...rest }) => {
	return (
		<Select
			label="Include expired?"
			showNoSelectionOption={false}
			{...rest}
			options={options}
		/>
	)
}

const options = [
	{
		value: "true",
		booleanValue: true,
		text: "Include expired",
	},
	{
		value: "false",
		booleanValue: false,
		text: "Exclude expired",
	},
]

import React, { Component } from "react"
import { withCallApi } from "~/components"
import { withRouter } from "react-router-dom"

import { createUserAssignment } from "@ncs/mortar/redux/services/ticketing"

import TicketUserAssignmentAdd from "./TicketUserAssignmentAdd"

class TicketUserAssignmentAddContainer extends Component {
	constructor(props) {
		super(props)

		this.state = {
			isAdding: false,
			user: null,
			isSaving: false,
		}
	}

	handleSetInputRef = (input) => {
		this.input = input
	}

	handleAssignmentSave = () => {
		let assignment = {
			ticket_type_id: this.props.type,
			user_id: this.state.user._id,
		}

		// this.props.callApi()

		this.props.callApi(createUserAssignment(this.props.type, assignment)).then((result) => {
			if (result.error) {
				return this.setState({ error: result.error })
			} else {
				this.setState({
					success: true,
					newUserAssignmentMessage: "User Assignment Added.",
				})
				this.setState({
					user: null,
					isAdding: false,
					isSaving: false,
				})
				this.props.onAssignmentSaved()
			}
		})
	}
	handleAssignmentAddCancel = () => {
		this.setState({
			user: null,
			isAdding: false,
		})
	}
	handleAssignmentAdd = () => {
		this.setState({
			isAdding: true,
		})
	}
	handleUserSelected = (user) => {
		this.setState({ user })
	}

	render() {
		return (
			<TicketUserAssignmentAdd
				setInputRef={this.handleSetInputRef}
				isAdding={this.state.isAdding}
				isSaving={this.state.isSaving}
				onSaveAssignment={this.handleAssignmentSave}
				onCancelAssignmentAdd={this.handleAssignmentAddCancel}
				onAssignmentAdd={this.handleAssignmentAdd}
				success={this.state.success}
				error={this.state.error}
				onUserSelected={this.handleUserSelected}
				user={this.state.user}
				type={this.props.type}
			/>
		)
	}
}

export default withRouter(withCallApi(TicketUserAssignmentAddContainer))

import { FC, memo as memoComponent, useState } from "react"

import { Cell, Column } from "react-table"

import {
	APPLICATION,
	CreditMemoLineItem,
	InvoiceStatusId,
	useCreditMemos,
	useUserCanUse,
} from "@ncs/ncs-api"
import {
	extractNumber,
	formatCurrency,
	formatDateTime,
	formatNumber,
	formatPercentage,
} from "@ncs/ts-utils"
import {
	Box,
	Button,
	EmptyValueDash,
	GridContainer,
	GridItem,
	GridItemProps,
	HeadingDivider,
	Icon,
	Label,
	LabeledData,
	Price,
	Table,
} from "@ncs/web-legos"

import { getDispatchLineDescription } from "~/util"

import { CreditMemoDecisionModal } from "./components"

export interface CreditMemosProps {
	invoiceId: string
}

export const CreditMemos: FC<CreditMemosProps> = memoComponent(({ invoiceId }) => {
	const userCanApprove = useUserCanUse(APPLICATION.CreditMemoApproval)
	const [decision, setDecision] = useState<[string, boolean] | null>(null)

	const [memos] = useCreditMemos(invoiceId)

	const showApprovalButtons = (memoStatus: InvoiceStatusId): boolean => {
		return (
			userCanApprove &&
			[InvoiceStatusId.Rejected, InvoiceStatusId.Invoiced].includes(memoStatus) === false
		)
	}

	if (!memos?.length) {
		return null
	}

	const gridItemProps: GridItemProps = {
		xs: 12,
		sm: 6,
		md: 4,
		lg: 3,
	}

	return (
		<>
			{memos.map((memo) => {
				return (
					<Box
						key={memo.id}
						opacity={memo.status === InvoiceStatusId.Rejected ? 0.65 : undefined}
					>
						<HeadingDivider
							variant="h5"
							mt={0}
							detailText={`Created ${formatDateTime(memo.createdDate)} by ${
								memo.createdBy.name
							}`}
							renderRight={
								showApprovalButtons(memo.status) ?
									() => (
										<Button
											icon="thumbs-down"
											onClick={() => setDecision([memo.id, false])}
										>
											Reject credit memo
										</Button>
									)
								:	undefined
							}
						>
							Credit Memo #{memo.invoiceNumber} ({memo.statusCode})
						</HeadingDivider>

						<Box d="flex" gap={2} flexWrap="wrap" mb={3}>
							<div>
								<Label>Parts total</Label>
								<Price price={memo.totalParts} />
							</div>
							<div>
								<Label>Labor total</Label>
								<Price price={memo.laborTotal} />
							</div>
							<div>
								<Label>Other total</Label>
								<Price price={memo.otherTotal} />
							</div>
							<Box bl={1} pl={2}>
								<Label>Subtotal</Label>
								<Price price={memo.subtotal} />
							</Box>
							<div>
								<Label>Tax total</Label>
								<Price price={memo.taxTotal} />
							</div>
							<Box bl={1} pl={2}>
								<Label>Total</Label>
								<Price price={memo.total} />
							</Box>
						</Box>

						<GridContainer mb={1}>
							<GridItem {...gridItemProps}>
								<LabeledData label="Reason" mb={0}>
									{memo.reason}
								</LabeledData>
							</GridItem>
							<GridItem {...gridItemProps}>
								<LabeledData label="Comments" mb={0}>
									{memo.comments}
								</LabeledData>
							</GridItem>
							<GridItem {...gridItemProps}>
								<LabeledData label="Status" mb={0}>
									{memo.statusCode}
								</LabeledData>
							</GridItem>
						</GridContainer>

						<Table
							data={memo.lineItems}
							columns={columns}
							disableAllSorting
							noDataText="No line items found on credit memo"
						/>
					</Box>
				)
			})}

			{!!decision && (
				<CreditMemoDecisionModal
					invoiceId={invoiceId}
					creditMemoId={decision[0]}
					isApproved={decision[1]}
					onClose={() => setDecision(null)}
				/>
			)}
		</>
	)
})

const columns: Column<CreditMemoLineItem>[] = [
	{
		Header: "Description",
		accessor: (original) => getDispatchLineDescription(original),
	},
	{
		Header: "Under warranty?",
		accessor: "underWarranty",
		Cell: ({ row: { original } }: Cell<CreditMemoLineItem>) => {
			return original.underWarranty ?
					<Box d="flex" alignItems="center" gap={0.5}>
						<Icon icon="check" color="gray" />
						<span>Under warranty</span>
					</Box>
				:	<EmptyValueDash />
		},
	},
	{
		Header: "Billable?",
		accessor: "billable",
		Cell: ({ row: { original } }: Cell<CreditMemoLineItem>) => {
			return original.billable ?
					<Box d="flex" alignItems="center" gap={0.5}>
						<Icon icon="check" color="gray" />
						<span>Billable</span>
					</Box>
				:	<EmptyValueDash />
		},
	},
	{
		Header: "Qty",
		accessor: ({ quantity }) => formatNumber(extractNumber(quantity)),
	},
	{
		Header: "Original price",
		accessor: ({ unitPrice }) => formatCurrency(unitPrice),
	},
	{
		Header: "Credit memo price (disc %)",
		accessor: "netPrice",
		Cell: ({ row: { original } }: Cell<CreditMemoLineItem>) => {
			const originalPrice = extractNumber(original.unitPrice)
			const memoPrice = extractNumber(original.netPrice)
			let discount: number | null = null

			if (memoPrice < originalPrice) {
				// Subtract 1 at the end because the memo price is negative.
				discount = memoPrice === 0 ? 1 : (originalPrice - memoPrice) / originalPrice - 1
			}

			return `${formatCurrency(memoPrice)}${
				discount != null ? ` (${formatPercentage(discount)})` : ""
			}`
		},
	},
	{
		Header: "Subtotal",
		accessor: ({ subTotal }) => formatCurrency(subTotal),
	},
	{
		Header: "Est tax",
		accessor: ({ tax }) => formatCurrency(tax),
	},
	{
		Header: "Total",
		accessor: ({ total }) => formatCurrency(total),
	},
]

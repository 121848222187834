import { FC, useCallback, useMemo, useState } from "react"

import { Column } from "react-table"

import {
	PartCrossReference,
	PartCrossReferenceQueryParams,
	useDeletePartCrossReference,
	usePartCrossReferences,
} from "@ncs/ncs-api"
import {
	Button,
	Card,
	ConfirmationModal,
	ConfirmationModalConfig,
	encodeUrlState,
	InventoryPartQueryFilter as DefaultInventoryPartQueryFilter,
	InventoryPartQueryFilterProps,
	SearchQueryFilter,
	Table,
	TableProps,
	useToast,
	useUrlState,
} from "@ncs/web-legos"

import { PartManagementUrlState } from "../PartManagement/PartManagement"
import { EditCrossReference } from "./components"

export type PartCrossReferencesUrlState = PartCrossReferenceQueryParams & {
	modal: string | boolean | null
}

export const PartCrossReferences: FC = () => {
	const { makeSuccessToast } = useToast()
	const [urlState, { setUrlState, updateUrlValue }] = useUrlState(defaultUrlState)
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)

	const query = usePartCrossReferences({
		params: {
			search: urlState.search,
			part: urlState.part,
		},
	})
	const deleteReference = useDeletePartCrossReference()

	const handleDelete = useCallback(
		(id: string) => {
			setConfirmationConfig({
				title: "Delete Cross Reference",
				message: "Confirm: Delete this part cross reference?",
				onConfirm: async () => {
					await deleteReference(id)
					makeSuccessToast("Reference deleted")
				},
			})
		},
		[deleteReference, makeSuccessToast]
	)

	const rowMenu = useMemo((): TableProps<PartCrossReference>["rowMenu"] => {
		return [
			{
				label: "Edit",
				iconName: "pencil",
				onClick: ({ original }) => updateUrlValue("modal", original.id),
			},
			{
				label: "Delete cross reference",
				iconName: "trash-alt",
				onClick: ({ original }) => handleDelete(original.id),
			},
			{
				label: "View part",
				iconName: "external-link",
				onClick: ({ original }) => {
					window.open(
						`/inventory/part-management${encodeUrlState<PartManagementUrlState>({
							partId: original.part.id,
						})}`
					)
				},
			},
		]
	}, [handleDelete, updateUrlValue])

	return (
		<Card
			heading="Part Cross References"
			headingIcon="link"
			renderRight={() => (
				<Button
					icon="plus-circle"
					variant="secondary-cta"
					onClick={() => updateUrlValue("modal", true)}
				>
					Create cross reference
				</Button>
			)}
		>
			<Table
				query={query}
				columns={columns}
				queryParamState={urlState}
				setQueryParamState={setUrlState}
				pinnedQueryFilters={[SearchQueryFilter, InventoryPartQueryFilter]}
				rowMenu={rowMenu}
				noDataText="No cross references match current filters"
			/>

			{!!urlState.modal && (
				<EditCrossReference
					crossReferenceId={typeof urlState.modal === "string" ? urlState.modal : null}
					onClose={() => updateUrlValue("modal", null)}
				/>
			)}

			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</Card>
	)
}

const columns: Column<PartCrossReference>[] = [
	{
		Header: "Alternate Part #",
		accessor: "partNumber",
	},
	{
		Header: "Alternate Description",
		accessor: "description",
	},
	{
		Header: "NCS Part #",
		accessor: ({ part }) => part.partNumber,
	},
	{
		Header: "NCS Description",
		accessor: ({ part }) => part.description,
	},
]

const defaultUrlState: PartCrossReferencesUrlState = {
	modal: null,
	search: null,
	part: null,
}

const InventoryPartQueryFilter = (
	props: InventoryPartQueryFilterProps<PartCrossReferencesUrlState>
) => {
	return (
		<DefaultInventoryPartQueryFilter
			{...props}
			skipRestrictedCheck
			includeNonService={false}
		/>
	)
}

import { createMiddleware } from "redux-api-middleware"
import { prepareApiCall } from "../../middleware/portalApiMiddleware"
import { isAccessTokenExpired, isRefreshTokenExpired, refreshToken } from "../selectors"
import { refreshAccessToken } from "./auth"

export const ensureAccessTokenIsFresh = async (dispatch, getState) => {
	const state = getState()
	if (!isAccessTokenExpired(state) || isRefreshTokenExpired(state)) return

	const token = refreshToken(state)
	if (token) {
		return dispatch(refreshAccessToken(token))
	}
}

/**
 * Allows calling APIs without results going to reducers.
 * If you *do* want the results going to reducers, just dispatch the action instead and middleware will handle it.
 *
 *	use the withCallApi HOC to bind callApi:
 *      export default withCallApi(MyContainer)
 *
 *	or bind it yourself with bindActionCreators and connect redux as normal:
 *		const mapDispatchToProps = (dispatch) => ({
 *			...bindActionCreators({callApi}, dispatch)
 *		})
 *		export default connect(mapStateToProps, mapDispatchToProps)(MyContainer)
 *
 *  then call it via props: this.props.callApi(myApiAction(apiParams)).then(...
 *
 * @param {object} apiAction - The RSAA standard API action to execute (from /redux/service/blah)
 */
export const callApi = (apiAction) => async (dispatch, getState) => {
	const next = (x) => x
	const middleware = createMiddleware()({ getState })(next)
	await ensureAccessTokenIsFresh(dispatch, getState)

	// if it's a thunk
	if (typeof apiAction === "function") {
		// fake the dispatch to grab the first real RSAA that the thunk tries to dispatch
		// if the thunk does more than one thing, uh... don't use callApi?
		apiAction((action) => {
			apiAction = action
		}, getState)
	}

	return middleware(prepareApiCall(apiAction, getState(), dispatch))
}

export default callApi

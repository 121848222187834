import { FC, useCallback, useEffect, useMemo, useState } from "react"

import { useThrottle } from "@react-hook/throttle"

import { EquipmentModel, useEquipmentModel, useEquipmentModels } from "@ncs/ncs-api"

import { useChangeCallback, useInitialId } from "../../util"
import { ExtendableSearchableSelectProps, SearchableSelect } from "../inputs"

export type EquipmentModelSelectorProps = ExtendableSearchableSelectProps<EquipmentModel> & {
	initialId?: string | null | undefined
	manufacturer?: string | null | undefined
}

export const EquipmentModelSelector: FC<EquipmentModelSelectorProps> = ({
	initialId,
	manufacturer,
	value,
	onChange,
	label = "Equipment model",
	...rest
}) => {
	const [searchInput, setSearchInput] = useState<string | null>(null)
	const [throttledSearch, setThrottledSearch] = useThrottle<string | null>(null)

	useChangeCallback(searchInput, (newSearchInput) => setThrottledSearch(newSearchInput))

	const query = useEquipmentModels({
		params: {
			search: throttledSearch,
			manufacturerId: manufacturer,
		},
	})

	const [initialModel] = useEquipmentModel(initialId)

	useInitialId({
		initialId,
		onChange,
		currentValue: value,
		findInitialOption: () => initialModel,
	})

	const doesNotMatchManufacturer = useCallback(
		(m: EquipmentModel) => {
			if (
				manufacturer &&
				m.manufacturer?.id &&
				m.manufacturer.id.toString() !== manufacturer
			) {
				return true
			}

			return false
		},
		[manufacturer]
	)

	const options = useMemo(() => {
		return value ?
				// `value` may or may not be present in the data, especially at a first.
				// Inject it here, and then filter it out of the data to prevent duplicate.
				[
					value,
					...query.data.filter((d) => {
						if (doesNotMatchManufacturer(d)) {
							return false
						}

						return d.id !== value.id
					}),
				]
			:	query.data.filter((m) => doesNotMatchManufacturer(m) === false)
	}, [query.data, value, doesNotMatchManufacturer])

	// If manufacturer changes, and we have a selection, and the selection has a
	// manufacturer other than what it just changed to, then we should clear out
	// the selection.
	useEffect(() => {
		if (manufacturer && !!value && value.manufacturerId?.toString() !== manufacturer) {
			onChange(null)
		}
	}, [manufacturer, onChange, value])

	const noModelsForManufacturer = useMemo(() => {
		if (
			manufacturer &&
			query.isLoading === false &&
			query.isFetching === false &&
			query.data.length === 0
		) {
			return "No models found for the selected manufacturer"
		}

		return undefined
	}, [manufacturer, query.isLoading, query.isFetching, query.data])

	return (
		<SearchableSelect
			sortingParam="modelName"
			useSearchIcon
			placeholder="Search for a model..."
			{...rest}
			options={options}
			value={value ?? null}
			onItemSelect={onChange}
			getOptionLabel={(option) => option.modelName ?? ""}
			isLoading={query.isLoading || query.isFetching}
			label={label}
			onInputChange={setSearchInput}
			error={noModelsForManufacturer}
		/>
	)
}

import { FC, useState } from "react"

import {
	makeApiErrorMessage,
	RoleGroup,
	UserGroup,
	UserProfile,
	useUpdateUserGroups,
} from "@ncs/ncs-api"
import { titleCase } from "@ncs/ts-utils"
import { CheckboxGroup, ExtendableModalProps, Modal, useToast } from "@ncs/web-legos"

export interface EditRoleGroupsModalProps extends ExtendableModalProps {
	user: UserProfile
	allGroups: RoleGroup[]
	userGroups: UserGroup[]
}

interface SelectionsState {
	[id: string]: {
		group: RoleGroup
		checked: boolean
	}
}

export const EditRoleGroupsModal: FC<EditRoleGroupsModalProps> = ({
	user,
	allGroups,
	userGroups,
	...rest
}) => {
	const { makeSuccessToast } = useToast()
	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const makeSelectionsState = (): SelectionsState => {
		return Object.fromEntries(
			allGroups.map((group) => {
				const checked =
					userGroups.findIndex((userGroup) => userGroup.id === group.id) !== -1

				return [
					group.id,
					{
						group,
						checked,
					},
				]
			})
		)
	}

	const [selections, setSelections] = useState<SelectionsState>(() => makeSelectionsState())

	const saveGroups = useUpdateUserGroups()

	const onSave = async () => {
		try {
			setIsSaving(true)
			await saveGroups({
				userId: user.id.toString(),
				groupIds: Object.values(selections)
					.filter((s) => s.checked)
					.map((s) => s.group.id),
			})
			makeSuccessToast("User updated")
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const onOpen = () => {
		setIsSaving(false)
		setErrorText(null)
		setSelections(makeSelectionsState())
	}

	return (
		<Modal
			{...rest}
			title="Edit Roles"
			onOpen={onOpen}
			errorText={errorText}
			rightButtons={{
				buttonText: "Save Changes",
				isLoading: isSaving,
				onClick: onSave,
			}}
		>
			<CheckboxGroup
				rows={Object.values(selections)}
				valueAccessor={(row) => row.group.id}
				labelAccessor={(row) => titleCase(row.group.code.split("_").join(" "))}
				onChange={(row, newState) => {
					setSelections((prev) => ({
						...prev,
						[row.group.id]: {
							...prev[row.group.id],
							checked: newState,
						},
					}))
				}}
				disabledAccessor={() => isSaving}
			/>
		</Modal>
	)
}

import { FC } from "react"

import { FreightGroupDescription, FreightGroupId } from "@ncs/ncs-api"
import { ExtendableSelectProps, Select } from "@ncs/web-legos"

export type FreightGroupSelectorProps = ExtendableSelectProps<
	(typeof options)[number],
	FreightGroupId
>

export const FreightGroupSelector: FC<FreightGroupSelectorProps> = ({ ...rest }) => {
	return (
		<Select
			label="Freight group"
			{...rest}
			onChange={(v, option) => rest.onChange(option?.value ?? null)}
			options={options}
		/>
	)
}

const options = [
	{
		value: FreightGroupId.Conventional,
		text: FreightGroupDescription.Conventional,
	},
	{
		value: FreightGroupId.Ultra,
		text: FreightGroupDescription.Ultra,
	},
	{
		value: FreightGroupId.Other,
		text: FreightGroupDescription.Other,
	},
]

import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Clipboard from "clipboard"
import classNames from "classnames"
import { createStyles, makeStyles } from "@material-ui/core"
import { AssignmentReturn } from "@material-ui/icons"
import { ButtonWithIcon } from "../../components"
import { ButtonWithIconProps } from "./ButtonWithIcon"

const styles = createStyles({
	// used for attaching to the button
	clipboardBtn: {},
})
const useStyles = makeStyles(styles)

export interface ClipboardButtonProps extends Omit<ButtonWithIconProps, "icon"> {
	/** the potentially long text string */
	value?: string
}

/** wraps long text in a span and slaps a copy to clipboard button before it. This is useful in ReactTable columns that
 * will otherwise truncate the long text. */
const ClipboardButton: React.FC<ClipboardButtonProps> = ({ value, ...rest }) => {
	const classes = useStyles()
	const [clipboard, setClipboard] = useState<undefined | Clipboard>()

	useEffect(() => {
		if (!clipboard) {
			setClipboard(new Clipboard(`.${classes.clipboardBtn}`, {}))
		}

		return function cleanupClipboard() {
			clipboard && clipboard.destroy()
		}
	}, [classes.clipboardBtn, clipboard, setClipboard])

	const buttonClasses = useMemo(
		() => classNames(classes.clipboardBtn, rest.className),
		[classes, rest.className]
	)

	return (
		<ButtonWithIcon
			content="Copy to Clipboard"
			data-clipboard-text={value}
			color="info"
			icon={<AssignmentReturn />}
			{...rest}
			className={buttonClasses}
			onClick={(e) => e?.stopPropagation()}
		/>
	)
}

ClipboardButton.propTypes = {
	/** the potentially long text string */
	value: PropTypes.string,
}

export default ClipboardButton

import { FC, useEffect, useState } from "react"

import {
	APPLICATION,
	makeApiErrorMessage,
	PurchaseOrder,
	PurchaseOrderPatch,
	useUpdatePurchaseOrder,
	useUserCanUse,
	Vendor,
} from "@ncs/ncs-api"
import {
	ExtendableModalProps,
	Modal,
	OrderShipMethodSelector,
	Textarea,
	TextInput,
	useToast,
	VendorSelector,
} from "@ncs/web-legos"

export interface EditPurchaseOrderModalProps extends ExtendableModalProps {
	order: PurchaseOrder | null
}

export const EditPurchaseOrderModal: FC<EditPurchaseOrderModalProps> = ({
	order,
	isOpen,
	onClose,
	...rest
}) => {
	const isRdcManager = useUserCanUse(APPLICATION.RDCManager)
	const updateOrder = useUpdatePurchaseOrder()
	const { makeSuccessToast } = useToast()

	const [vendor, setVendor] = useState<Vendor | null>(null)
	const [comments, setComments] = useState<string | null>(null)
	const [purchaseOrderNumber, setPurchaseOrderNumber] = useState<string | null>(null)
	const [shipMethodId, setShipMethodId] = useState<string | null>(null)
	const [errorText, setErrorText] = useState<string | null>(null)
	const [isSaving, setIsSaving] = useState(false)

	const handleClose = () => {
		// Set these to null here so that the initial ID prop functionality on them works properly.
		setVendor(null)
		onClose()
	}

	const onOpen = () => {
		setComments(order?.comments ?? null)
		setPurchaseOrderNumber(order?.purchaseOrderNumber ?? null)
		setShipMethodId(order?.shipMethodId ? String(order.shipMethodId) : null)
	}

	const onSave = async () => {
		if (!order) {
			throw new Error("Somehow we are trying to save without an order. Check yo self.")
		}

		if (!vendor?.id) {
			setErrorText("Supplier is required")
		} else if (!shipMethodId) {
			setErrorText("Ship method is required")
		} else {
			try {
				const updates: Partial<PurchaseOrderPatch> = {
					vendorId: String(vendor.id),
					comments,
					purchaseOrderNumber,
					shipMethodId,
				}

				setIsSaving(true)
				await updateOrder({ updates, id: String(order.id) })
				makeSuccessToast("Order updated")
				handleClose()
			} catch (e) {
				setErrorText(makeApiErrorMessage(e))
			} finally {
				setIsSaving(false)
			}
		}
	}

	// When any of these change, clear the error message.
	useEffect(() => {
		setErrorText(null)
	}, [vendor, shipMethodId])

	const canEditPurchaseOrderNumber = vendor?.id === 1 // Grimes

	return (
		<Modal
			onOpen={onOpen}
			isOpen={isOpen}
			onClose={handleClose}
			{...rest}
			title="Edit Purchase Order"
			titleDetail={`Purchase Order # ${order?.id}`}
			rightButtons={{
				buttonText: "Save",
				onClick: onSave,
				isLoading: isSaving,
			}}
			errorText={errorText}
		>
			{isRdcManager && (
				<VendorSelector
					value={vendor}
					onChange={setVendor}
					initialId={order?.vendor?.id}
				/>
			)}
			<OrderShipMethodSelector
				value={shipMethodId}
				onChange={setShipMethodId}
				fillContainer
			/>
			{canEditPurchaseOrderNumber && (
				<TextInput
					value={purchaseOrderNumber}
					onChange={setPurchaseOrderNumber}
					label="Customer purchase order #"
				/>
			)}
			<Textarea value={comments} onChange={setComments} label="Comments" />
		</Modal>
	)
}

import { FC, useState } from "react"

import dayjs from "dayjs"
import { Cell, Column } from "react-table"

import {
	ConnectedDevice,
	ConnectedMachineWashCounts,
	ConnectedMachineWashCountsQueryParams,
	useConnectedMachineWashCounts,
} from "@ncs/ncs-api"
import { DateFormat, formatDateTime, formatNumber, getTimezoneAbbreviation } from "@ncs/ts-utils"
import {
	EmptyValueDash,
	KeyboardEndDateQueryFilter,
	KeyboardStartDateQueryFilter,
	Table,
	Weather,
} from "@ncs/web-legos"

import { c12yAutoRefreshConfig } from "~/util"

import { MachineWashCountDetailModal } from "./MachineWashCountDetailModal"

export interface MachineWashCountsTabProps {
	machine: ConnectedDevice | undefined
}

export const MachineWashCountsTab: FC<MachineWashCountsTabProps> = ({ machine }) => {
	const [washCountsForDetailModal, setWashCountsForDetailModal] =
		useState<ConnectedMachineWashCounts | null>(null)

	const [tableParamState, setTableParamState] = useState<ConnectedMachineWashCountsQueryParams>({
		startDate: dayjs().subtract(15, "day").startOf("minute").format(DateFormat.DateQueryParam),
		endDate: null,
	})

	const [counts, isLoading] = useConnectedMachineWashCounts(machine?.id, {
		params: tableParamState,
		queryConfig: c12yAutoRefreshConfig,
	})

	return (
		<>
			<Table
				data={counts ?? []}
				columns={columns}
				toggledQueryFilters={[KeyboardEndDateQueryFilter, KeyboardStartDateQueryFilter]}
				queryParamState={tableParamState}
				setQueryParamState={setTableParamState}
				isLoading={isLoading}
				onRowClick={({ original }) => setWashCountsForDetailModal(original)}
			/>

			<MachineWashCountDetailModal
				isOpen={!!washCountsForDetailModal}
				onClose={() => setWashCountsForDetailModal(null)}
				washCounts={washCountsForDetailModal}
			/>
		</>
	)
}

const columns: Column<ConnectedMachineWashCounts>[] = [
	{
		Header: `Date (${getTimezoneAbbreviation()})`,
		accessor: (original) => formatDateTime(original.eventDate),
		disableSortBy: true,
	},
	{
		Header: "Total washes count",
		id: "total",
		accessor: (original) => formatNumber(original.total),
		disableSortBy: true,
	},
	{
		Header: "Double pass",
		disableSortBy: true,
		accessor: (original) =>
			original.doublePass ? formatNumber(original.doublePass) : <EmptyValueDash />,
	},
	{
		Header: "Single pass",
		disableSortBy: true,
		accessor: (original) =>
			original.singlePass ? formatNumber(original.singlePass) : <EmptyValueDash />,
	},
	{
		Header: "Weather",
		disableSortBy: true,
		Cell: ({ row: { original } }: Cell<ConnectedMachineWashCounts>) =>
			original.weather ?
				<Weather
					temperature={original.weather?.temperature}
					icon={original.weather?.icon}
				/>
			:	<EmptyValueDash />,
	},
]

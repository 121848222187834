import { FC, memo, useMemo, useState } from "react"

import {
	Customer,
	makeApiErrorMessage,
	PricingMatrix,
	useAddCustomerToPricingMatrix,
} from "@ncs/ncs-api"
import {
	Callout,
	CustomerSelector,
	ExtendableModalProps,
	Heading,
	Modal,
	Paragraph,
	useToast,
} from "@ncs/web-legos"

export interface EditPricingMatrixCustomerModalProps extends ExtendableModalProps {
	matrix: PricingMatrix
}

export const EditPricingMatrixCustomerModal: FC<EditPricingMatrixCustomerModalProps> = memo(
	({ matrix, ...rest }) => {
		const { makeSuccessToast } = useToast()
		const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null)

		const addCustomer = useAddCustomerToPricingMatrix()

		const [isLoading, setIsLoading] = useState(false)
		const [errorText, setErrorText] = useState<string | null>(null)

		const onSave = async () => {
			if (!!selectedCustomer && !!matrix)
				try {
					setIsLoading(true)
					await addCustomer({
						id: matrix.id,
						customerId: selectedCustomer.id,
					})
					makeSuccessToast("Customer added to matrix")
					rest.onClose()
				} catch (e) {
					setErrorText(makeApiErrorMessage(e))
					setIsLoading(false)
				}
		}

		const reset = () => {
			setSelectedCustomer(null)
			setIsLoading(false)
			setErrorText(null)
		}

		const alreadyPresentInMatrix = useMemo(() => {
			return selectedCustomer?.pricingMatrixId?.toString() === matrix.id
		}, [selectedCustomer?.pricingMatrixId, matrix.id])

		return (
			<Modal
				{...rest}
				title="Add Customer To Matrix"
				onOpen={reset}
				errorText={errorText}
				rightButtons={{
					buttonText: "Add Customer",
					isLoading,
					onClick: onSave,
					disabled: !selectedCustomer || alreadyPresentInMatrix,
				}}
			>
				<CustomerSelector
					value={selectedCustomer}
					onChange={setSelectedCustomer}
					autoFocus
				/>

				{alreadyPresentInMatrix && (
					<Callout variant="info" icon="info-circle" my={2}>
						<Paragraph>
							This customer is already present in this pricing matrix
						</Paragraph>
					</Callout>
				)}

				{!!selectedCustomer?.pricingMatrix && !alreadyPresentInMatrix && (
					<Callout variant="warning" icon="info-circle" my={2}>
						<Heading bold mb={1} variant="h4">
							This customer is already in a pricing matrix.
						</Heading>
						<Paragraph>
							This customer is currently in pricing matrix{" "}
							<strong>{selectedCustomer.pricingMatrix.description}</strong>. You may
							proceed, but a customer can only be a part of one pricing matrix at a
							time. It will be removed from{" "}
							{selectedCustomer.pricingMatrix.description} and added to{" "}
							{matrix.description}.
						</Paragraph>
					</Callout>
				)}
			</Modal>
		)
	}
)

import React from "react"
import { bool, func } from "prop-types"

// custom components
import { Select } from "../../components"

class BaseSelectFilter extends React.PureComponent {
	getOptionValue = (option) =>
		typeof this.props.valueAccessor === "function" ?
			this.props.valueAccessor(option)
		:	option[this.props.valueAccessor || "value"]

	handleChange = (value) => {
		let selectedOption = this.props.options.find((x) => value === this.getOptionValue(x))

		let text =
			typeof this.props.textAccessor === "function" ?
				this.props.textAccessor(selectedOption)
			:	selectedOption[this.props.textAccessor || "text"]

		this.props.onChange(value, text)

		if (typeof this.props.onItemSelected === "function") {
			this.props.onItemSelected(selectedOption, value, text)
		}
	}

	render() {
		const { classes, value, onChange, ...rest } = this.props

		return (
			<Select
				value={value || ""}
				{...rest}
				onChange={this.handleChange}
				labelProps={{
					shrink: this.props.displayEmpty ? true : undefined,
					...rest.labelProps,
				}}
				selectProps={{
					displayEmpty: this.props.displayEmpty,
					...rest.selectProps,
				}}
			/>
		)
	}
}

BaseSelectFilter.defaultProps = {
	displayEmpty: true,
}

BaseSelectFilter.propsTypes = {
	displayEmpty: bool,
	onItemSelected: func,
}

export default BaseSelectFilter

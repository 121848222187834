import { FC } from "react"

import { InvoiceStatus, useInvoiceStatuses } from "@ncs/ncs-api"

import { ExtendableSelectProps, Select } from "../inputs"

export interface InvoiceStatusSelectorProps extends ExtendableSelectProps<InvoiceStatus> {}

export const InvoiceStatusSelector: FC<InvoiceStatusSelectorProps> = (props) => {
	const [statuses, loading] = useInvoiceStatuses()

	return (
		<Select
			label="Invoice status"
			{...props}
			valueAccessor="id"
			textAccessor="code"
			options={statuses ?? []}
			disabled={loading}
		/>
	)
}

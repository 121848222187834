import { FC, useMemo, useState } from "react"

import {
	AddCustomerRestrictedPartPost,
	CustomerDetail,
	InventoryPart,
	makeApiErrorMessage,
	useAddRestrictedPartToCustomers,
} from "@ncs/ncs-api"
import {
	Divider,
	ExtendableModalProps,
	Heading,
	Modal,
	Paragraph,
	PartSelector,
	RadioGroup,
	useToast,
} from "@ncs/web-legos"

export interface AddRestrictedPartModalProps extends ExtendableModalProps {
	customer: CustomerDetail
}

enum SelectionType {
	Selection = "1",
	SameBillTos = "2",
	BillToSelection = "3",
}

export const AddRestrictedPartModal: FC<AddRestrictedPartModalProps> = ({ customer, ...rest }) => {
	const { makeSuccessToast } = useToast()
	const [selectionType, setSelectionType] = useState<SelectionType>(SelectionType.Selection)
	const [part, setPart] = useState<InventoryPart | null>(null)
	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const addPart = useAddRestrictedPartToCustomers()

	const defaultBillToId = useMemo(() => {
		return customer.billableTo?.find((billTo) => billTo.isDefault)?.id ?? null
	}, [customer.billableTo])

	const onSave = async () => {
		try {
			setIsSaving(true)
			if (!part) throw new Error("Please select a part")

			let postData: AddCustomerRestrictedPartPost

			switch (selectionType) {
				case SelectionType.Selection: {
					postData = {
						shipToId: customer.id,
						partId: part.id,
					}
					break
				}
				case SelectionType.SameBillTos: {
					if (!defaultBillToId) throw new Error("Customer has no default bill-to")
					postData = {
						billToId: defaultBillToId,
						partId: part.id,
					}
					break
				}
				case SelectionType.BillToSelection: {
					postData = {
						billToId: customer.id,
						partId: part.id,
					}
					break
				}
			}

			await addPart(postData)
			makeSuccessToast(`${part.description} added to customer`)
			rest.onClose()
		} catch (e) {
			setErrorText(makeApiErrorMessage(e))
			setIsSaving(false)
		}
	}

	const reset = () => {
		setSelectionType(SelectionType.Selection)
		setPart(null)
		setIsSaving(false)
		setErrorText(null)
	}

	return (
		<Modal
			{...rest}
			onOpen={reset}
			title="Allow Restricted Part"
			errorText={errorText}
			rightButtons={{
				buttonText: "Allow Part",
				onClick: onSave,
				isLoading: isSaving,
			}}
		>
			<Paragraph mb={1}>
				Search for the restricted part you want to allow this customer to purchase.
			</Paragraph>
			<PartSelector value={part} onChange={setPart} restricted />

			<Divider mt={2} mb={1.5} />

			<Heading variant="h4" mb={1}>
				({customer.customerNumber}) {customer.name}
			</Heading>

			<Paragraph mb={1}>
				You can apply this permission to just this one site, or this site plus all others
				that share the same default bill-to, or all sites that bill to this one.
			</Paragraph>

			<RadioGroup
				htmlName="selection-type"
				options={options}
				value={selectionType}
				onChange={(newValue, option) => setSelectionType(option.value)}
			/>
		</Modal>
	)
}

const options = [
	{
		label: "This site only",
		value: SelectionType.Selection,
	},
	{
		label: "This site plus all sites with same default bill-to",
		value: SelectionType.SameBillTos,
	},
	{
		label: "All sites that bill to this site",
		value: SelectionType.BillToSelection,
	},
]

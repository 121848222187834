import React from "react"

import { Slide } from "@material-ui/core"

const CustomSlide = React.forwardRef((props, ref) => {
	const { children, ...rest } = props
	let wrappedChildren = <div ref={ref}>{children}</div>
	return <Slide {...rest}>{wrappedChildren}</Slide>
})

CustomSlide.defaultProps = {
	unmountOnExit: true,
}

CustomSlide.propTypes = {
	...Slide.propTypes,
}

export default CustomSlide

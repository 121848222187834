import { FC, useEffect, useRef } from "react"

import { RegionMinimal, useRegions } from "@ncs/ncs-api"

import { ExtendableSearchableSelectProps, SearchableSelect } from "../inputs"

export interface RegionSelectorProps extends ExtendableSearchableSelectProps<RegionMinimal> {
	initialId?: string | null
}

export const RegionSelector: FC<RegionSelectorProps> = ({
	value,
	onChange,
	initialId,
	...rest
}) => {
	const [regions, loading] = useRegions()
	const hasSetInitial = useRef(false)

	useEffect(() => {
		if (!!initialId && !hasSetInitial.current && !!regions) {
			const option = regions.find((t) => t.id === initialId)

			if (option) {
				onChange(option)
				hasSetInitial.current = true
			}
		}
	}, [initialId, regions, onChange])

	return (
		<SearchableSelect
			getOptionLabel={(option) => option.description}
			disabled={loading}
			label="Region"
			placeholder="Search for a region..."
			{...rest}
			options={regions ?? []}
			value={value ?? null}
			onItemSelect={onChange}
			useSearchIcon
		/>
	)
}

import { FC, useCallback, useMemo, useState } from "react"

import { Cell, Column } from "react-table"

import {
	APPLICATION,
	defaultPartGroupDiscountQueryParams,
	makeApiErrorMessage,
	PartGroup,
	PartGroupDiscount,
	PartGroupDiscountQueryParams,
	useDeletePartGroupDiscount,
	usePartGroupDiscounts,
	useUserCanUse,
} from "@ncs/ncs-api"
import { extractNumber, formatPercentage } from "@ncs/ts-utils"
import {
	Button,
	ConfirmationModal,
	ConfirmationModalConfig,
	EmptyValueDash,
	Icon,
	SearchQueryFilter,
	Table,
	TableProps,
	useToast,
} from "@ncs/web-legos"

import { EditGroupDiscountModal } from "./EditGroupDiscountModal"

export interface PartGroupsCustomersTabProps {
	group: PartGroup
}

export const PartGroupsCustomersTab: FC<PartGroupsCustomersTabProps> = ({ group }) => {
	const isDiscountAdmin = useUserCanUse(APPLICATION.PartDiscountAdmin)
	const { makeSuccessToast, makeErrorToast } = useToast()
	const [params, setParams] = useState<PartGroupDiscountQueryParams>(
		defaultPartGroupDiscountQueryParams
	)

	const customerDiscountsQuery = usePartGroupDiscounts({
		params: {
			...params,
			group: group.id,
		},
	})
	const deleteDiscount = useDeletePartGroupDiscount()

	const [addingNewDiscount, setAddingNewDiscount] = useState(false)
	const [discountToEdit, setDiscountToEdit] = useState<PartGroupDiscount | null>(null)
	const [confirmModalConfig, setConfirmModalConfig] = useState<ConfirmationModalConfig | null>(
		null
	)

	const onDelete = useCallback(
		async (discountId: number) => {
			try {
				await deleteDiscount(discountId.toString())
				makeSuccessToast("Customer discount deleted")
			} catch (e) {
				makeErrorToast(makeApiErrorMessage(e))
			}
		},
		[deleteDiscount, makeErrorToast, makeSuccessToast]
	)

	const rowMenu = useMemo((): TableProps<PartGroupDiscount>["rowMenu"] => {
		return isDiscountAdmin ?
				[
					{
						label: "Edit discount",
						iconName: "pencil",
						onClick: ({ original }) => setDiscountToEdit(original),
					},
					{
						label: "Remove",
						iconName: "trash",
						onClick: ({ original }) =>
							setConfirmModalConfig({
								title: "Remove Discount",
								message: `Remove ${group.description} discount for ${original.customer.name}?`,
								onConfirm: () => onDelete(original.id),
							}),
					},
				]
			:	undefined
	}, [group.description, isDiscountAdmin, onDelete])

	return (
		<>
			{isDiscountAdmin && (
				<Button
					buttonText="Add Customer To Group"
					variant="secondary-cta"
					icon="plus-circle"
					containerProps={{ mb: 2 }}
					onClick={() => setAddingNewDiscount(true)}
				/>
			)}

			<Table
				query={customerDiscountsQuery}
				columns={columns}
				queryParamState={params}
				setQueryParamState={setParams}
				toggledQueryFilters={[SearchQueryFilter]}
				noDataText="No customers have discounts for this part group yet"
				rowMenu={rowMenu}
			/>

			<EditGroupDiscountModal
				discountToEdit={discountToEdit}
				isOpen={!!discountToEdit || addingNewDiscount}
				onClose={() => {
					setAddingNewDiscount(false)
					setDiscountToEdit(null)
				}}
				group={group}
			/>
			<ConfirmationModal config={confirmModalConfig} setConfig={setConfirmModalConfig} />
		</>
	)
}

const columns: Column<PartGroupDiscount>[] = [
	{
		Header: "Customer #",
		id: "customer_CustomerNumber",
		accessor: "customerNumber",
	},
	{
		Header: "Customer Name",
		id: "customer_CustomerName",
		accessor: ({ customer }) => customer.name,
	},
	{
		Header: "Group Discount",
		accessor: ({ groupDiscountPercent }) =>
			formatPercentage(extractNumber(groupDiscountPercent)),
	},
	{
		Header: "Discount Active?",
		accessor: "isActive",
		disableSortBy: true,
		Cell: (cell: Cell<PartGroupDiscount>) => {
			return cell.row.original.isActive ?
					<Icon icon="check" color="gray" />
				:	<EmptyValueDash />
		},
	},
]

import { FC, useMemo, useState } from "react"

import { Cell, Column } from "react-table"

import {
	BinManagementBin,
	BinManagementBinQueryParams,
	InventoryLocation,
	useBinManagementBins,
} from "@ncs/ncs-api"
import {
	Box,
	Button,
	EmptyValueDash,
	GridContainer,
	GridItem,
	GridItemProps,
	Icon,
	IsActiveQueryFilter,
	SearchQueryFilter,
	Table,
	TableProps,
	usePaginationState,
} from "@ncs/web-legos"

import { BinTypeQueryFilter } from "~/components"

import { EditBinModal, PrintBinLabelsModal, PrintSingleBinLabelModal } from "./components"

export interface LocationBinsTabProps {
	location: InventoryLocation
}

export const LocationBinsTab: FC<LocationBinsTabProps> = ({ location }) => {
	const [queryParams, setQueryParams] = useState(defaultQueryParams)
	const [pagination, setUrlState] = usePaginationState()
	const combinedParams = useMemo(
		() => ({ ...queryParams, location: location.id, ...pagination }),
		[location.id, queryParams, pagination]
	)
	const [modalBin, setModalBin] = useState<BinManagementBin | null>(null)
	const [showNewModal, setShowNewModal] = useState(false)
	const [showPrintModal, setShowPrintModal] = useState(false)
	const [singleLabelModal, setSingleLabelModal] = useState<BinManagementBin | null>(null)

	const binsQuery = useBinManagementBins({ params: combinedParams, manualPagination: true })

	const rowMenu = useMemo(
		(): TableProps<BinManagementBin>["rowMenu"] => [
			{
				iconName: "pencil",
				label: "Edit",
				onClick: ({ original }) => setModalBin(original),
			},
			{
				iconName: "barcode-read",
				label: "Print label",
				disabledAccessor: ({ original }) => !original.isActive,
				onClick: ({ original }) => setSingleLabelModal(original),
			},
		],
		[]
	)

	const queryFilterGridItemProps: GridItemProps = {
		xs: 12,
		sm: 6,
		md: 4,
		lg: 3,
	}
	const queryFilterProps = {
		queryParamState: queryParams,
		setQueryParamState: setQueryParams,
	}

	return (
		<>
			<Box display="flex" gap={1} justifyContent="flex-end" mdProps={{ mb: 2 }}>
				<Button icon="plus-circle" onClick={() => setShowNewModal(true)}>
					Create new bin
				</Button>
				<Button icon="barcode-read" onClick={() => setShowPrintModal(true)}>
					Print bin labels
				</Button>
			</Box>

			<GridContainer>
				<GridItem {...queryFilterGridItemProps}>
					<SearchQueryFilter {...queryFilterProps} />
				</GridItem>
				<GridItem {...queryFilterGridItemProps}>
					<IsActiveQueryFilter {...queryFilterProps} />
				</GridItem>
				<GridItem {...queryFilterGridItemProps}>
					<BinTypeQueryFilter {...queryFilterProps} />
				</GridItem>
			</GridContainer>

			<Table
				columns={columns}
				rowMenu={rowMenu}
				query={binsQuery}
				queryParamState={queryParams}
				setQueryParamState={setQueryParams}
				pagination={pagination}
				setPagination={setUrlState}
			/>

			<EditBinModal
				bin={modalBin}
				isOpen={!!modalBin || showNewModal}
				onClose={() => {
					setModalBin(null)
					setShowNewModal(false)
				}}
				location={location}
			/>
			{showPrintModal && (
				<PrintBinLabelsModal
					locationId={location.id}
					onClose={() => setShowPrintModal(false)}
				/>
			)}
			{!!singleLabelModal && (
				<PrintSingleBinLabelModal
					bin={singleLabelModal}
					locationId={location.id}
					onClose={() => setSingleLabelModal(null)}
				/>
			)}
		</>
	)
}

const columns: Column<BinManagementBin>[] = [
	{
		Header: "Bin Code",
		accessor: "code",
	},
	{
		Header: "Is active?",
		accessor: "isActive",
		disableSortBy: true,
		Cell: ({ row: { original } }: Cell<BinManagementBin>) => {
			return original.isActive ?
					<>
						<Icon icon="check" color="gray" /> Active
					</>
				:	<EmptyValueDash />
		},
	},
	{
		Header: "Has parts?",
		accessor: "hasParts",
		disableSortBy: true,
		Cell: ({ row: { original } }: Cell<BinManagementBin>) => {
			return original.hasParts ?
					<>
						<Icon icon="check" color="gray" /> Has parts
					</>
				:	<EmptyValueDash />
		},
	},
	{
		Header: "Bin type",
		accessor: "binType",
		disableSortBy: true,
	},
]

const defaultQueryParams: BinManagementBinQueryParams = {
	location: null,
	isActive: null,
	search: null,
	ordering: null,
	binType: null,
}

import { FC } from "react"

import { Cell, Column } from "react-table"

import {
	SalesRepDetailReportLine,
	SalesRepDetailReportQueryParams,
	useSalesRepDetailReport,
} from "@ncs/ncs-api"
import { formatCurrency, getStartAndEndDateQueryParams, TimeAgo, Typify } from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	Table,
	TimeAgoQueryFilter,
	UserQueryFilter,
	useUrlState,
} from "@ncs/web-legos"

export type SalesRepDetailReportUrlState = Typify<SalesRepDetailReportQueryParams>

export const SalesRepDetailReport: FC = () => {
	const [params, { setUrlState: setParams }] = useUrlState<SalesRepDetailReportUrlState>(
		initialSalesRepDetailReportUrlState
	)

	const [data, isLoading] = useSalesRepDetailReport({ params })

	return (
		<AnimatedEntrance show>
			<Table
				data={data ?? []}
				isLoading={isLoading}
				columns={columns}
				queryParamState={params}
				setQueryParamState={setParams}
				pinnedQueryFilters={[UserQueryFilter, TimeAgoQueryFilter]}
				noDataText={
					!params.user ? "This report requires setting the user filter" : undefined
				}
				storeColumnVisibility="site-rep-detail-columns"
			/>
		</AnimatedEntrance>
	)
}

const columns: Column<SalesRepDetailReportLine>[] = [
	{
		Header: "Customer #",
		disableSortBy: true,
		accessor: "customerNumber",
	},
	{
		Header: "Customer name",
		accessor: "customerName",
	},
	{
		Header: "Order #",
		accessor: "orderNumber",
	},
	{
		Header: "Chem total",
		accessor: "orderChemTotal",
		Cell: ({ row: { original } }: Cell<SalesRepDetailReportLine>) =>
			formatCurrency(original.orderChemTotal),
	},
	{
		Header: "Order status",
		hiddenByDefault: true,
		accessor: ({ orderStatus }) => orderStatus,
	},
	{
		Header: "Invoice #",
		hiddenByDefault: true,
		accessor: ({ invoiceNumber }) => invoiceNumber || "-",
	},
	{
		Header: "Invoice status",
		hiddenByDefault: true,
		accessor: ({ invoiceStatus }) => invoiceStatus || "-",
	},
]

const initialSalesRepDetailReportUrlState: SalesRepDetailReportUrlState = {
	user: null,
	startDate: getStartAndEndDateQueryParams(TimeAgo.DaysAgo30)[0],
	endDate: null,
}

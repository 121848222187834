import {
	get,
	post,
	put,
	del,
	patch,
	rsaaTypes,
	RSAA,
} from "@ncs/bricks/middleware/portalApiMiddleware"

export const GET_EQUIPMENT_REPAIR_PROPOSAL = rsaaTypes("equipment_repair_proposal/get")
export const LIST_EQUIPMENT_REPAIR_PROPOSALS = rsaaTypes("equipment_repair_proposals/list")
export const PATCH_EQUIPMENT_REPAIR_PROPOSAL = rsaaTypes("equipment_repair_proposal/patch")
export const LIST_EQUIPMENT_REPAIR_PROPOSAL_LINE_ITEMS = rsaaTypes(
	"equipment_repair_proposal/line-items/list"
)
export const POST_EQUIPMENT_REPAIR_PROPOSAL_LINE_ITEM = rsaaTypes(
	"equipment_repair_proposal/line-item/post"
)
export const PUT_EQUIPMENT_REPAIR_PROPOSAL_LINE_ITEM = rsaaTypes(
	"equipment_repair_proposal/line-item/put"
)
export const DELETE_EQUIPMENT_REPAIR_PROPOSAL_LINE_ITEM = rsaaTypes(
	"equipment_repair_proposal/line-item/delete"
)
export const APPROVE_EQUIPMENT_REPAIR_PROPOSAL = rsaaTypes(
	"equipment_repair_proposal/approve/post"
)
export const REJECT_EQUIPMENT_REPAIR_PROPOSAL = rsaaTypes("equipment_repair_proposal/reject/post")

export const listEquipmentRepairProposals = (querystring_params) => ({
	[RSAA]: {
		api: get(`/workorders/equipment_repair_proposals/`, querystring_params),
		types: LIST_EQUIPMENT_REPAIR_PROPOSALS,
	},
})

export const getEquipmentRepairProposal = (erpId) => ({
	[RSAA]: {
		api: get(`/workorders/equipment_repair_proposals/${erpId}/`),
		types: GET_EQUIPMENT_REPAIR_PROPOSAL,
	},
})

export const patchEquipmentRepairProposal = (erpId, data) => ({
	[RSAA]: {
		api: patch(`/workorders/equipment_repair_proposals/${erpId}/`),
		types: PATCH_EQUIPMENT_REPAIR_PROPOSAL,
	},
})

export const getEquipmentRepairProposalLineItems = (erpId) => ({
	[RSAA]: {
		api: get(`/workorders/equipment_repair_proposals/${erpId}/line_items/`),
		types: LIST_EQUIPMENT_REPAIR_PROPOSAL_LINE_ITEMS,
	},
})

export const createEquipmentRepairProposalLineItem = (erpId, data) => ({
	[RSAA]: {
		api: post(`/workorders/equipment_repair_proposals/${erpId}/line_items/`, data),
		types: POST_EQUIPMENT_REPAIR_PROPOSAL_LINE_ITEM,
	},
})

export const updateEquipmentRepairProposalLineItem = (erpId, line_item_id, data) => ({
	[RSAA]: {
		api: put(
			`/workorders/equipment_repair_proposals/${erpId}/line_items/${line_item_id}/`,
			data
		),
		types: PUT_EQUIPMENT_REPAIR_PROPOSAL_LINE_ITEM,
	},
})

export const deleteEquipmentRepairProposalLineItem = (erpId, lineItemId) => ({
	[RSAA]: {
		api: del(`/workorders/equipment_repair_proposals/${erpId}/line_items/${lineItemId}/`),
		types: DELETE_EQUIPMENT_REPAIR_PROPOSAL_LINE_ITEM,
	},
})

export const approveEquipmentRepairProposal = (erpId) => ({
	[RSAA]: {
		api: post(`/workorders/equipment_repair_proposals/${erpId}/approve/`, null),
		types: APPROVE_EQUIPMENT_REPAIR_PROPOSAL,
	},
})

export const rejectEquipmentRepairProposal = (erpId) => ({
	[RSAA]: {
		api: post(`/workorders/equipment_repair_proposals/${erpId}/reject/`, null),
		types: REJECT_EQUIPMENT_REPAIR_PROPOSAL,
	},
})

export const ERP_LIST_TYPES = {
	BY_TERRITORY: "ERPs/by_territory",
	BY_CUSTOMER: "ERPs/by_customer",
	SINGLE_CUSTOMER: "ERPs/single_customer",
}

const initialState = {
	isLoadingERPs: true,
	isLoadingCustomerERPs: true,
	erpsByTerritory: [],
	erpsByCustomer: [],
	customerERPs: [],
	erp: {},
	erpNavigation: { previousERP: null, currentERP: null, nextERP: null },
	erpListType: ERP_LIST_TYPES.BY_CUSTOMER,
}

const equipmentRepairProposals = (state = initialState, action) => {
	switch (action.type) {
		case LIST_EQUIPMENT_REPAIR_PROPOSALS.request:
			return {
				...state,
				isLoadingERPs: true,
			}
		case LIST_EQUIPMENT_REPAIR_PROPOSALS.success:
			return {
				...state,
				isLoadingERPs: false,
				erps: action.payload,
			}

		case GET_EQUIPMENT_REPAIR_PROPOSAL.request:
			return {
				...state,
				isLoadingERP: true,
			}
		case GET_EQUIPMENT_REPAIR_PROPOSAL.success:
			return {
				...state,
				isLoadingERP: false,
				erp: action.payload,
			}

		case LIST_EQUIPMENT_REPAIR_PROPOSAL_LINE_ITEMS.request:
			return {
				...state,
				isLoadingERPLineItems: true,
			}
		case LIST_EQUIPMENT_REPAIR_PROPOSAL_LINE_ITEMS.success:
			return {
				...state,
				isLoadingERPLineItems: false,
				lineItems: action.payload,
			}

		case PATCH_EQUIPMENT_REPAIR_PROPOSAL.request:
		case POST_EQUIPMENT_REPAIR_PROPOSAL_LINE_ITEM.request:
		case PUT_EQUIPMENT_REPAIR_PROPOSAL_LINE_ITEM.request:
		case DELETE_EQUIPMENT_REPAIR_PROPOSAL_LINE_ITEM.request:
		case APPROVE_EQUIPMENT_REPAIR_PROPOSAL.request:
		case REJECT_EQUIPMENT_REPAIR_PROPOSAL.request:
			return {
				...state,
				isSaving: true,
			}

		case PATCH_EQUIPMENT_REPAIR_PROPOSAL.success:
		case POST_EQUIPMENT_REPAIR_PROPOSAL_LINE_ITEM.success:
		case PUT_EQUIPMENT_REPAIR_PROPOSAL_LINE_ITEM.success:
		case DELETE_EQUIPMENT_REPAIR_PROPOSAL_LINE_ITEM.success:
		case APPROVE_EQUIPMENT_REPAIR_PROPOSAL.success:
		case REJECT_EQUIPMENT_REPAIR_PROPOSAL.success:
			return {
				...state,
				isSaving: false,
			}

		default:
			return state
	}
}
export default equipmentRepairProposals

import { FC, useMemo } from "react"

import { usePortalUsers, UserMinimal } from "@ncs/ncs-api"

import { ExtendableSelectProps, Select } from "../inputs"

export interface PortalUserSelectorProps extends ExtendableSelectProps<UserMinimal> {}

export const noPortalUserAssignedId = "no_portal_user"

export const PortalUserSelector: FC<PortalUserSelectorProps> = (props) => {
	const [users, loading] = usePortalUsers()

	const options = useMemo((): UserMinimal[] => {
		if (loading || !users) return []

		return [
			{
				id: noPortalUserAssignedId,
				name: "No user assigned",
				email: "",
				mobile: null,
				endDate: "",
			},
			...users.sort((a, b) => (a.name > b.name ? 1 : -1)),
		]
	}, [users, loading])

	return (
		<Select
			label="Portal user"
			disabled={loading}
			{...props}
			valueAccessor="id"
			textAccessor="name"
			options={options}
			noSelectionOptionText="All users"
		/>
	)
}

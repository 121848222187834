import { FC } from "react"

import { CustomerClass, useCustomerClasses } from "@ncs/ncs-api"
import { ExtendableSelectProps, Select } from "@ncs/web-legos"

export interface CustomerClassSelectorProps extends ExtendableSelectProps<CustomerClass> {}

export const CustomerClassSelector: FC<CustomerClassSelectorProps> = ({ ...rest }) => {
	const [classes, classesLoading] = useCustomerClasses()

	return (
		<Select
			label="Customer class"
			disableNoSelectionOption={false}
			disabled={classesLoading}
			{...rest}
			options={classes ?? []}
			valueAccessor="id"
			textAccessor="description"
		/>
	)
}

import { formatPercentage } from "@ncs/ts-utils"

/**
 * How different can the new month's count be from the prev month's count
 * without triggering an error?
 */
export const VARIANCE_THRESHOLD = 0.3

/**
 * Any initial transforming of the wash count data when it first arrives.
 */
export const prepareIncomingWashCounts = (washCountArray) => {
	return washCountArray
		.sort((a, b) => {
			const aName = a.package.portalContractSite.customer.name
			const bName = b.package.portalContractSite.customer.name
			const aDescription = a.package.packageDescription
			const bDescription = b.package.packageDescription

			if (aName === bName) return aDescription > bDescription ? 1 : -1
			return aName > bName ? 1 : -1
		})
		.map((row) => {
			const withNewFields = {
				...row,

				// Calculate the initial value for the current month's count based on
				// the new ending and last month's ending. User will then tweak this if need be.
				currentCount:
					row.newEnding - row.lastMonthEnding > 0 ?
						row.newEnding - row.lastMonthEnding
					:	0,

				// Original new ending, unchanged by user. Keep it here as a backup incase
				// we need to revert to it.
				originalNewEnding: row.newEnding,

				// Empty comment.
				commentId: row.commentId || null,
				comment: row.comment || null,
			}

			return {
				...withNewFields,
				// Do an initial round of validation.
				errorMessage: validateCounts(withNewFields),
			}
		})
}

/**
 * Assemble the final result for the backend.
 */
export const prepareOutgoingWashCounts = (washCountArray) => {
	return washCountArray.map((row) => {
		const result = {
			id: row.id,
			comment_id: row.commentId,
			comment: row.comment,
			last_month_ending: row.lastMonthEnding,
			new_ending: row.newEnding,
			count: row.currentCount,
		}

		return result
	})
}

/**
 * Are any of our error conditions triggered? If so, return an error message.
 */
export const validateCounts = (washCount) => {
	const lastMonthCount = washCount.lastMonthEnding - washCount.lastMonthStarting

	if (washCount.currentCount < washCount.package.minMonthlyCount)
		return "Count below contract minimum"
	if (washCount.currentCount === 0) return "Wash count of 0"
	if (washCount.currentCount > lastMonthCount * (1 + VARIANCE_THRESHOLD))
		return `Variance greater than ${formatPercentage(VARIANCE_THRESHOLD)}`
	if (washCount.currentCount < lastMonthCount * (1 - VARIANCE_THRESHOLD))
		return `Variance lower than ${formatPercentage(VARIANCE_THRESHOLD)}`

	return null
}

/**
 * Test if wash count row is ready to be submitted.
 */
export const washCountReady = (washCount) => {
	// Status ID 1 is "In Progress", meaning the wash count has not be submitted yet.
	// If it's not that, we shouldn't be submitting it.
	if (washCount.status.id !== "1") return false

	return !washCount.errorMessage || washCount.comment
}

/**
 * Take an educated guess on what invoice period user wants to see.
 */
export const guessWashCountPeriod = () => {
	const today = new Date()

	// If it's before the 25th, it's last month. After that, it's the current month.
	// Remember, JS months are zero-based, but Mortar months are not.
	const month =
		today.getDate() >= 25 ? today.getMonth() + 1
		: today.getMonth() === 0 ? 12
		: today.getMonth()

	// Unless the month is December, the year is going to be current year.
	const year = month === 12 ? today.getFullYear() - 1 : today.getFullYear()

	return [month, year]
}

/**
 * What dates should user be able to choose? This was implemented in early 2021,
 * so we'll do 2020 through whatever the current year is, plus 1.
 */
export const getYearOptions = () => {
	const years = []
	const currentYear = new Date().getFullYear()

	for (let i = 2020; i <= currentYear + 1; i += 1) {
		years.push(i)
	}

	return years
		.map((y) => ({
			value: y,
			text: y,
		}))
		.sort((y1, y2) => (y1.value > y2.value ? -1 : 1))
}

/**
 * Do any necessary transforming between what we hold here on the frontend as our
 * filter state and what the backend actually expects.
 */
export const prepareFilters = (filters) => {
	const result = { ...filters }

	// Customer should be just the ID, not the whole customer object. Note that
	// we're storing the whole customer object in filters state so that the
	// CustomerSelector has all the info it needs to show a selection based on
	// the preserved filter state from local state.
	result.customer = filters.customer?._id

	// Concat our year and month fields into one string, and then remove year.
	result.month = `${filters.year}-${filters.month}-01`
	delete result.year

	return result
}

import { FC, useState } from "react"

import { CustomerCredit, makeApiErrorMessage, useExpireCustomerCredit } from "@ncs/ncs-api"
import { formatDate } from "@ncs/ts-utils"
import { ExtendableModalProps, Modal, Paragraph, useToast } from "@ncs/web-legos"

export interface ExpireCreditModalProps extends ExtendableModalProps {
	credit: CustomerCredit
	customerId: string
}

export const ExpireCreditModal: FC<ExpireCreditModalProps> = ({ credit, customerId, ...rest }) => {
	const { makeSuccessToast } = useToast()
	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const expireCredit = useExpireCustomerCredit()

	const onSubmit = async () => {
		try {
			setIsSaving(true)
			await expireCredit({
				updates: {
					customerId,
					businessUnitId: credit.businessUnit.id,
					otherUnitId: credit.otherUnit.id,
					isPerSite: false,
				},
			})
			makeSuccessToast("Credit expired")
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const creditName = credit.businessUnit.name || credit.otherUnit.name

	return (
		<Modal
			{...rest}
			title="Credit Expiration"
			rightButtons={{
				buttonText: "Expire Now",
				isLoading: isSaving,
				onClick: onSubmit,
			}}
			errorText={errorText}
		>
			<Paragraph mb={1}>
				This {creditName} credit is currently set to expire on{" "}
				{formatDate(credit.expiresOn, { formatInUtc: true })}.
			</Paragraph>
			<Paragraph>Would you like to set it to be expired now instead?</Paragraph>
		</Modal>
	)
}

import React from "react"
import PropTypes from "prop-types"
import { Redo } from "@material-ui/icons"

import { Button } from "../../components"

class ResetFiltersButton extends React.PureComponent {
	render() {
		const { onResetFilters } = this.props

		return (
			<Button simple color="primary" onClick={onResetFilters}>
				<Redo /> {"Reset Filters"}
			</Button>
		)
	}
}

ResetFiltersButton.propTypes = {
	onResetFilters: PropTypes.func.isRequired,
}

export default ResetFiltersButton

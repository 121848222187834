import React from "react"
import PropTypes from "prop-types"
import moment from "moment"

// custom components
import {
	Alert,
	ButtonWithIcon,
	Checkbox,
	ConditionalContent,
	CurrencyInput,
	Datetime,
	GridContainer,
	GridItem,
	Input,
	LoadingWrapper,
	QuickCard,
	Select,
} from "~/components"

// @material-ui/icons
import CloudUpload from "@material-ui/icons/CloudUpload"

// styles
import withStyles from "@material-ui/core/styles/withStyles"

const styles = {
	clickableRow: {
		cursor: "pointer",
	},
	columnChooser: {
		margin: "15px",
	},
	inputAdornmentIcon: {
		color: "#555",
	},
	right: {
		textAlign: "right",
	},
	bottom: {
		marginTop: "19px",
	},
}

const generateCategoryOptions = (cats) => {
	if (!cats || !cats.length) return []

	return [
		{ value: "-1", text: "All Categories" },
		...cats.map((x) => ({
			value: x.id.toString(),
			text: x.name,
		})),
	]
}

const MarketingMaterialsUpload = (props) => {
	const {
		isLoading,
		isSaving,
		onCategoryChange,
		selectedCategory,
		categories,
		title,
		onFileSelected,
		onSubmit,
		onTitleChanged,
		showSuccessfulUpload,
		onCloseSuccessModal,
		onBackToList,
		showFailedUpload,
		onCloseFailedUploadModal,
		expirationDate,
		onExpirationDateSelected,
		effectiveDate,
		onEffectiveDateSelected,
		cost,
		onCostUpdated,
		isOrderable,
		onIsOrderableUpdated,
	} = props

	// initialize category variables
	let selectedTopLevelCategory, selectedSecondLevelCategory, selectedThirdLevelCategory
	selectedTopLevelCategory = selectedSecondLevelCategory = selectedThirdLevelCategory = "-1"

	const availableTopLevelCategories = generateCategoryOptions(categories)
	let availableSecondLevelCategories, availableThirdLevelCategories
	availableSecondLevelCategories = availableThirdLevelCategories = []

	for (let top of categories) {
		if (top.id === selectedCategory) {
			selectedTopLevelCategory = selectedCategory.toString()
			availableSecondLevelCategories = generateCategoryOptions(top.sub_categories)
		}

		for (let mid of top.sub_categories) {
			if (mid.id === selectedCategory) {
				selectedTopLevelCategory = top.id.toString()
				selectedSecondLevelCategory = selectedCategory.toString()
				availableSecondLevelCategories = generateCategoryOptions(top.sub_categories)
				availableThirdLevelCategories = generateCategoryOptions(mid.sub_categories)
			}

			for (let bottom of mid.sub_categories) {
				if (bottom.id === selectedCategory) {
					selectedTopLevelCategory = top.id.toString()
					selectedSecondLevelCategory = mid.id.toString()
					selectedThirdLevelCategory = selectedCategory.toString()
					availableSecondLevelCategories = generateCategoryOptions(top.sub_categories)
					availableThirdLevelCategories = generateCategoryOptions(mid.sub_categories)
				}
			}
		}
	}

	return (
		<React.Fragment>
			<LoadingWrapper isLoading={isLoading} title="Loading Categories" />

			<GridContainer>
				<GridItem xs={12} md={12} lg={12}>
					<QuickCard title={"Upload Document"} icon={<CloudUpload />}>
						<GridContainer justify="flex-start">
							<GridItem xs={12} md={4}>
								<Select
									id="first_category"
									labelText="Top-level Category"
									value={selectedTopLevelCategory || "-1"}
									onChange={onCategoryChange}
									options={availableTopLevelCategories}
								/>
							</GridItem>
							<GridItem xs={12} md={4}>
								<ConditionalContent
									show={availableSecondLevelCategories.length > 0}
								>
									<Select
										id="second_category"
										labelText="Second-level Category"
										value={selectedSecondLevelCategory || "-1"}
										onChange={onCategoryChange}
										options={availableSecondLevelCategories}
									/>
								</ConditionalContent>
							</GridItem>
							<GridItem xs={12} md={4}>
								<ConditionalContent
									show={availableThirdLevelCategories.length > 0}
								>
									<Select
										id="third_category"
										labelText="Third-level Category"
										value={selectedThirdLevelCategory || "-1"}
										onChange={onCategoryChange}
										options={availableThirdLevelCategories}
									/>
								</ConditionalContent>
							</GridItem>
							<GridItem xs={12} sm={10} md={6}>
								<Input
									id="title"
									labelText="Document Title"
									value={title}
									onChange={(e) => onTitleChanged(e.target.value)}
								/>
							</GridItem>
							<GridItem xs={2}>
								<Datetime
									labelText="Expiration Date"
									timeFormat={false}
									dateFormat="MM-DD-YYYY"
									onChange={(e) => onExpirationDateSelected(moment(e))}
									value={moment(expirationDate)}
								/>
							</GridItem>
							<GridItem xs={2}>
								<Datetime
									labelText="Effective Date"
									timeFormat={false}
									dateFormat="MM-DD-YYYY"
									onChange={(e) => onEffectiveDateSelected(moment(e))}
									value={moment(effectiveDate)}
								/>
							</GridItem>
							<GridItem xs={2}>
								<CurrencyInput
									labelText="Cost"
									onChange={onCostUpdated}
									value={cost}
								/>
							</GridItem>
							<GridItem xs={12}>
								<Checkbox
									labelText="Is Orderable"
									checked={isOrderable}
									onToggle={onIsOrderableUpdated}
								/>
							</GridItem>
							<GridItem xs={12}>
								<input
									type="file"
									onChange={(e) => onFileSelected(e.target.files)}
								/>
							</GridItem>
							<GridItem xs={12}>
								<ButtonWithIcon
									content="Upload"
									onClick={onSubmit}
									icon={<CloudUpload />}
									loading={isSaving}
									disabled={isSaving}
									color="success"
									round
								/>
							</GridItem>
						</GridContainer>
					</QuickCard>
				</GridItem>
			</GridContainer>

			<Alert
				success
				show={showSuccessfulUpload}
				title="Document successfully uploaded!"
				onCancel={onBackToList}
				cancelBtnText="Back to List"
				onConfirm={onCloseSuccessModal}
				confirmBtnText="Upload Another"
			/>

			<Alert
				danger
				show={showFailedUpload}
				title="Document failed to upload"
				onConfirm={onCloseFailedUploadModal}
				confirmBtnText="Try Again"
			>
				{"Please try your upload again."}
			</Alert>
		</React.Fragment>
	)
}

MarketingMaterialsUpload.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	isSaving: PropTypes.bool.isRequired,
	categories: PropTypes.array.isRequired,
	selectedCategory: PropTypes.number,
	onCategoryChange: PropTypes.func.isRequired,
	title: PropTypes.string,
	onFileSelected: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	onTitleChanged: PropTypes.func.isRequired,
	showSuccessfulUpload: PropTypes.bool.isRequired,
	onCloseSuccessModal: PropTypes.func.isRequired,
	onBackToList: PropTypes.func.isRequired,
	showFailedUpload: PropTypes.bool.isRequired,
	onCloseFailedUploadModal: PropTypes.func.isRequired,
}

export default withStyles(styles)(MarketingMaterialsUpload)

import { FC, useEffect, useMemo, useRef, useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { FormProvider, useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"

import {
	CustomerAlternateAddress,
	GenericLineItemWithBasePrice,
	makeApiErrorMessage,
	useCreateErp,
	useCustomer,
	useCustomerAccessorials,
	useUploadErpAttachment,
} from "@ncs/ncs-api"
import {
	Box,
	Button,
	Divider,
	ErrorText,
	FileInput,
	GridContainer,
	GridItem,
	Heading,
	IconButton,
	Label,
	Paragraph,
	Price,
	TextareaFormField,
	UpdateUrlValue,
	useScrollToTop,
	useToast,
} from "@ncs/web-legos"

import { QuotesTab, QuotesUrlState } from "../Quotes"
import {
	CreateQuoteOrderForm,
	CreateQuoteOrderFormSchema,
	defaultCreateQuoteOrderFormValues,
	erpAttachmentFileTypes,
	quoteFormToErpPost,
} from "../quotes-util"
import { CreateQuoteCustomer } from "./CreateQuoteCustomer"
import { CreateQuoteLineItems } from "./CreateQuoteLineItems"

export interface QuotesCreateTabProps {
	updateUrlValue: UpdateUrlValue<QuotesUrlState>
}

export const QuotesCreateTab: FC<QuotesCreateTabProps> = ({ updateUrlValue }) => {
	const { makeSuccessToast } = useToast()
	const history = useHistory()
	const scrollToTop = useScrollToTop()
	const [lineItemsToSubmit, setLineItemsToSubmit] = useState<GenericLineItemWithBasePrice[]>([])
	const [attachments, setAttachments] = useState<File[]>([])

	const form = useForm<CreateQuoteOrderForm>({
		resolver: zodResolver(CreateQuoteOrderFormSchema),
		defaultValues: { ...defaultCreateQuoteOrderFormValues },
	})
	const {
		control,
		watch,
		handleSubmit,
		formState: { submitCount, isValid: formIsValid },
		trigger,
	} = form
	const values = watch()
	const { shipToId, billToId } = values

	const [shipToCustomer] = useCustomer(shipToId)
	const [billToCustomer, billToLoading] = useCustomer(billToId, {
		queryConfig: { retry: false },
	})
	const [selectedAlternateAddress, setSelectedAlternateAddress] =
		useState<CustomerAlternateAddress | null>(null)
	const [accessorials] = useCustomerAccessorials(shipToCustomer?.id)

	const [isSubmitting, setIsSubmitting] = useState(false)
	const [submissionErrorText, setSubmissionErrorText] = useState<string | null>(null)
	const [customersErrorText, setCustomersErrorText] = useState<string | null>(null)
	const [lineItemErrorText, setLineItemErrorText] = useState<string | null>(null)

	const createErp = useCreateErp()
	const uploadAttachment = useUploadErpAttachment()

	// Keep track of ship to customer's that we have set default accessorials for already.
	const defaultAccessorialsSetFor = useRef<string[]>([])

	const orderHasChmOrDtl = useMemo(() => {
		return lineItemsToSubmit.some((line) =>
			(["CHM", "DTL"] as (string | null)[]).includes(line.part?.partFamily ?? null)
		)
	}, [lineItemsToSubmit])

	const onSubmit = async (quoteForm: CreateQuoteOrderForm) => {
		if (lineItemsToSubmit.length < 1) {
			setLineItemErrorText("At least 1 line item is required")
			return
		}
		if (!shipToCustomer?.servicable || !billToCustomer?.servicable) {
			// The error text should be there already, we just need to prevent submission.
			scrollToTop()
			return
		}

		try {
			setIsSubmitting(true)
			// Need to get additional pricing if any accessorials are checked.
			const postData = await quoteFormToErpPost(quoteForm, lineItemsToSubmit)
			// Now create the ERP.
			const { data: newId } = await createErp(postData)
			// Create any attachments that were added.
			if (attachments.length) {
				await Promise.all(attachments.map((a) => uploadAttachment(a, newId)))
			}

			updateUrlValue("tab", QuotesTab.ViewQuotes)
			makeSuccessToast("Quote created", {
				actionText: "View",
				actionIcon: "long-arrow-right",
				actionOnClick: () => {
					history.push(`/quotes/${newId}`)
					scrollToTop()
				},
			})
		} catch (e) {
			setSubmissionErrorText(makeApiErrorMessage(e))
			setIsSubmitting(false)
		}
	}

	const handleAttachmentSelect = (file: File) => {
		setAttachments((prev) => [...prev, file])
	}

	const handleRemoveAttachment = (indexToRemove: number) => {
		setAttachments((prev) => prev.filter((attachment, i) => i !== indexToRemove))
	}

	// Create the default accessorials.
	useEffect(() => {
		if (
			shipToCustomer?.id &&
			!defaultAccessorialsSetFor.current.includes(shipToCustomer.id) &&
			orderHasChmOrDtl &&
			accessorials
		) {
			defaultAccessorialsSetFor.current = [
				...defaultAccessorialsSetFor.current,
				shipToCustomer.id,
			]
			setLineItemsToSubmit((prev) => [
				...prev,
				...accessorials.flatMap((accessorial): GenericLineItemWithBasePrice[] => {
					if (accessorial.default) {
						return [
							{
								lineTypeId: accessorial.lineItemTypeId,
								basePrice: accessorial.rate,
								finalPrice: accessorial.rate,
								part: null,
								description: accessorial.lineItemDescription,
								quantity: 1,
								requestedPrice: null,
								taxRate: 0, // Accessorials aren't taxed
								subtotal: accessorial.rate,
								priceOverrideReason: null,
								overridePermission: null,
								reasonComment: null,
								systemGeneratedLine: false,
								originalSystemGeneratedPrice: null,
							},
						]
					}

					return []
				}),
			])
		}
	}, [accessorials, orderHasChmOrDtl, shipToCustomer?.id])

	useEffect(() => {
		if (!!shipToCustomer && shipToCustomer.servicable === false) {
			setCustomersErrorText(
				"Your Ship To selection is invalid because the customer is not serviceable."
			)
		} else if (!!billToCustomer && billToCustomer.servicable === false) {
			setCustomersErrorText(
				"Your Bill To selection is invalid because the customer is not serviceable."
			)
		} else if (!!shipToCustomer && !!billToId && !billToCustomer && !billToLoading) {
			setCustomersErrorText("Unable to validate if the selected Bill To is serviceable.")
		} else {
			setCustomersErrorText(null)
		}
	}, [billToCustomer, shipToCustomer, billToLoading, billToId])

	useEffect(() => {
		if (lineItemsToSubmit.length > 0) {
			setLineItemErrorText(null)
			void trigger()
		}
	}, [lineItemsToSubmit, trigger])

	const { orderSubtotal, orderTaxes, orderTotal } = useMemo(() => {
		const subtotal = lineItemsToSubmit.reduce(
			(prev, lineItem) => prev + lineItem.finalPrice * lineItem.quantity,
			0
		)
		const taxes = lineItemsToSubmit.reduce(
			(prev, lineItem) => prev + lineItem.finalPrice * lineItem.quantity * lineItem.taxRate,
			0
		)
		const total = subtotal + taxes

		return {
			orderSubtotal: subtotal,
			orderTaxes: taxes,
			orderTotal: total,
		}
	}, [lineItemsToSubmit])

	const canSubmit =
		formIsValid &&
		lineItemsToSubmit.length > 0 &&
		billToCustomer?.servicable &&
		shipToCustomer?.servicable

	return (
		<>
			<FormProvider {...form}>
				<CreateQuoteCustomer
					shipToCustomer={shipToCustomer}
					selectedAlternateAddress={selectedAlternateAddress}
					setSelectedAlternateAddress={setSelectedAlternateAddress}
					customersErrorText={customersErrorText}
				/>

				<CreateQuoteLineItems
					lineItemsToSubmit={lineItemsToSubmit}
					setLineItemsToSubmit={setLineItemsToSubmit}
					lineItemErrorText={lineItemErrorText}
					setLineItemErrorText={setLineItemErrorText}
					orderSubtotal={orderSubtotal}
					orderTaxes={orderTaxes}
					orderTotal={orderTotal}
				/>

				<Divider mt={3} mb={1} />

				<GridContainer>
					<GridItem xs={12} sm={6}>
						<TextareaFormField
							control={control}
							name="comment"
							label="Additional comments"
						/>
					</GridItem>
				</GridContainer>

				{attachments.length > 0 && (
					<Box>
						<Heading bold variant="h5" mt={1} mb={0.35}>
							Attachments
						</Heading>
						{attachments.map(({ name, size }, i) => (
							<Box
								key={`${name}-${size}-${i}`}
								display="flex"
								alignItems="center"
								gap={1}
							>
								<Paragraph small>{name}</Paragraph>
								<IconButton
									icon="trash-alt"
									onClick={() => handleRemoveAttachment(i)}
									color="primary"
									size="sm"
									title="Remove attachment"
								/>
							</Box>
						))}
					</Box>
				)}

				<Box display="flex" justifyContent="flex-start" alignItems="center" gap={2} my={1}>
					<FileInput
						onChange={handleAttachmentSelect}
						fileTypes={erpAttachmentFileTypes}
						buttonVariant="text"
						icon="paperclip-vertical"
						label="Attach file to order"
						width="auto"
					/>
				</Box>

				<GridContainer mb={3}>
					<GridItem
						display="flex"
						justifyContent="space-between"
						columnGap={2}
						sm={12}
						md={6}
						lg={4}
					>
						<div>
							<Label>Subtotal</Label>
							<Price price={orderSubtotal} />
						</div>
						<div>
							<Label>Taxes</Label>
							<Price price={orderTaxes} />
						</div>
						<div>
							<Label>Total</Label>
							<Price price={orderTotal} />
						</div>
					</GridItem>
				</GridContainer>

				<GridContainer mt={2} mb={5}>
					<GridItem sm={12} md={6} lg={4}>
						<Button
							variant="primary-cta"
							onClick={handleSubmit(onSubmit)}
							fillContainer
							isLoading={isSubmitting}
							disabled={submitCount > 0 && !canSubmit}
						>
							Create
						</Button>
					</GridItem>
				</GridContainer>

				{!!submissionErrorText && (
					<ErrorText mt={-4} mb={4}>
						{submissionErrorText}
					</ErrorText>
				)}
			</FormProvider>
		</>
	)
}

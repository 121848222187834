import omit from "lodash/omit"

import customInputStyle from "../../material-dashboard-pro-react/components/customInputStyle"
import customSelectStyle from "../../material-dashboard-pro-react/components/customSelectStyle"
import { grayColor } from "../../material-dashboard-pro-react"
import { createStyles, Theme } from "@material-ui/core"

const searchResultChipStyle = (theme: Theme) => {
	const light = theme.palette.type === "light"

	return createStyles({
		root: {
			...customSelectStyle.selectFormControl,
		},
		chipContainer: {
			cursor: "text",
			marginBottom: -2,
			minHeight: 40,
			"&$labeled": {
				marginTop: 18,
			},
		},
		labeled: {},
		label: {
			top: 4,
			...omit(customInputStyle.labelRoot, ["top", "& + $underline"]),
		},
		labelShrink: {
			top: 0,
		},
		helperText: {
			marginBottom: -20,
		},
		inkbar: {
			"&:after": {
				backgroundColor: theme.palette.primary[light ? "dark" : "light"],
				left: 0,
				bottom: 0,
				// Doing the other way around crash on IE11 "''" https://github.com/cssinjs/jss/issues/242
				content: '""',
				height: 2,
				position: "absolute",
				right: 0,
				transform: "scaleX(0)",
				transition: theme.transitions.create("transform", {
					duration: theme.transitions.duration.shorter,
					easing: theme.transitions.easing.easeOut,
				}),
				pointerEvents: "none", // Transparent to the hover style.
			},
			"&$focused:after": {
				transform: "scaleX(1)",
			},
		},
		focused: {},
		disabled: {
			...customInputStyle.disabled,
		},
		underline: {
			"&:before": {
				backgroundColor: grayColor[4] + " !important",
				left: 0,
				bottom: 0,
				// Doing the other way around crash on IE11 "''" https://github.com/cssinjs/jss/issues/242
				content: '""',
				height: 1,
				position: "absolute",
				right: 0,
				transition: theme.transitions.create("background-color", {
					duration: theme.transitions.duration.shorter,
					easing: theme.transitions.easing.easeOut,
				}),
				pointerEvents: "none", // Transparent to the hover style.
			},
		},
		error: {
			"&:after": {
				backgroundColor: theme.palette.error.main,
				transform: "scaleX(1)", // error is always underlined in red
			},
		},
		chip: {
			margin: "0 8px 8px 0",
			float: "left",
		},
	})
}

export default searchResultChipStyle

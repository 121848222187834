import { FC, useState } from "react"

import { Erp, makeApiErrorMessage, useConvertErp } from "@ncs/ncs-api"
import { ExtendableModalProps, Modal, Paragraph, useToast } from "@ncs/web-legos"

export interface ConvertQuoteModalProps extends ExtendableModalProps {
	erp: Erp
}

export const ConvertQuoteModal: FC<ConvertQuoteModalProps> = ({ erp, ...rest }) => {
	const { makeSuccessToast } = useToast()
	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const convertErp = useConvertErp()

	const handleSubmit = async () => {
		try {
			setIsSaving(true)
			await convertErp({
				id: erp.id,
				shipTo: erp.customer.id,
			})
			makeSuccessToast("Quote converted")
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	return (
		<Modal
			{...rest}
			title="Convert Quote To Dispatch"
			errorText={errorText}
			rightButtons={{
				buttonText: "Convert Quote",
				onClick: handleSubmit,
				isLoading: isSaving,
			}}
		>
			<Paragraph>Create a dispatch out of this quote?</Paragraph>
		</Modal>
	)
}

import { FC } from "react"

import { Portal, usePortals } from "@ncs/ncs-api"

import { ExtendableSelectProps, Select } from "../inputs"

export interface PortalSelectorProps extends ExtendableSelectProps<Portal> {}

export const PortalSelector: FC<PortalSelectorProps> = (props) => {
	const [portals, loading] = usePortals()

	return (
		<Select
			label="Customer portal"
			disabled={loading}
			{...props}
			valueAccessor="id"
			textAccessor="description"
			options={portals ?? []}
			noSelectionOptionText="All portals"
		/>
	)
}

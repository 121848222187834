import { FC, useState } from "react"

import { makeApiErrorMessage } from "@ncs/ncs-api"

import { NumericInput, NumericInputProps } from "../inputs"
import { Paragraph } from "../typography"
import { ExtendableModalProps, Modal } from "./Modal"

export interface EditNumberModalProps extends ExtendableModalProps {
	onSave: (value: number | null) => void | Promise<void>
	label: string
	initialValue?: number | null
	emptyValue?: null | 0
	title?: string
	placeholder?: string
	numericInputProps?: NumericInputProps
	saveButtonLabel?: string
	message?: string
	min?: number
	max?: number
}

export const EditNumberModal: FC<EditNumberModalProps> = ({
	onSave,
	label,
	initialValue,
	emptyValue = null,
	title = "Edit",
	placeholder,
	numericInputProps,
	saveButtonLabel = "Save",
	message,
	min,
	max,
	...rest
}) => {
	const [value, setValue] = useState<number | null>(initialValue ?? null)

	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const handleSave = async () => {
		try {
			setIsSaving(true)
			await onSave(value != null ? value : emptyValue)
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const reset = () => {
		setValue(null)
		setIsSaving(false)
		setErrorText(null)
	}

	return (
		<Modal
			errorText={errorText}
			title={title}
			onOpen={reset}
			{...rest}
			rightButtons={{
				buttonText: saveButtonLabel,
				onClick: handleSave,
				isLoading: isSaving,
			}}
		>
			{!!message && <Paragraph mb={1}>{message}</Paragraph>}

			<NumericInput
				{...numericInputProps}
				fillContainer
				autoFocus
				label={label}
				placeholder={placeholder}
				value={value}
				onChange={(newValue) => setValue(newValue ?? null)}
				min={min}
				max={max}
			/>
		</Modal>
	)
}

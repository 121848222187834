import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { ArrowUpward, ArrowDownward } from "@material-ui/icons"
import { makeStyles } from "@material-ui/core"

import styles from "../../assets/jss/material-dashboard-pro-react/views/dashboardStyle"

const useStyles = makeStyles(styles)

const round = (num: number, places: number) =>
	Number(Math.round(Number(num + "e+" + places)) + "e-" + places)

const sum = (a: number, b: number) => a + b

const percentageChangeInArraySums = (
	first: number[],
	second: number[],
	decimalPlaces = 0,
	inverseResult = false
) => {
	const { a, b } = inverseResult ? { a: second, b: first } : { a: first, b: second }

	const firstSum = a.reduce(sum)

	if (firstSum === 0) return NaN

	return round((b.reduce(sum) / firstSum) * 100, decimalPlaces) - 100
}

const getArrayGroupsFromEndOfArray = (arr: number[], groups: number, groupSize: number) => {
	if (groups * groupSize > arr.length) return null

	const x = []

	for (let i = 0; i < groups; i++) {
		const start = arr.length - (groups - i) * groupSize
		x.push(arr.slice(start, start + groupSize))
	}

	return x
}

const calculatePercentage = ({
	data,
	sizeOfComparison,
	inverseResult,
}: {
	data: number[]
	sizeOfComparison: number
	inverseResult: boolean
}) => {
	// getArrayGroupsFromEndOfArray supports more than 2 arrays, but
	// percentageChangeInArraySums does not. So, we'll grab the first two from
	// the result and use them in percentageChange. Revisit this if percentageChange
	// is refactored to allow more than two arrays.
	const [array1, array2] = [
		...(getArrayGroupsFromEndOfArray(data, 2, sizeOfComparison) ?? [[], []]),
	]

	return percentageChangeInArraySums(array1, array2, 0, inverseResult)
}

export interface PercentageSummaryProps {
	betterText: string
	worseText: string
	value?: number
	data?: number[]
	sizeOfComparison?: number
	inverseResult?: boolean
}

const PercentageSummary: React.FC<PercentageSummaryProps> = ({
	betterText,
	worseText,
	value: valueProp,
	data,
	sizeOfComparison,
	inverseResult,
}) => {
	const classes = useStyles()

	const value = useMemo(() => {
		if (valueProp) {
			return valueProp
		}

		if (data !== undefined && sizeOfComparison !== undefined && inverseResult !== undefined) {
			return calculatePercentage({ data, sizeOfComparison, inverseResult })
		}

		return null
	}, [data, inverseResult, sizeOfComparison, valueProp])

	if (value === null) {
		return <div />
	}

	return (
		<div>
			{value >= 0 && (
				<p className={classes.cardCategory}>
					<span className={classes.successText}>
						<ArrowUpward className={classes.upArrowCardCategory} />
						{value}%
					</span>
					{` ${betterText}`}
				</p>
			)}

			{value < 0 && (
				<p className={classes.cardCategory}>
					<span className={classes.dangerText}>
						<ArrowDownward className={classes.downArrowCardCategory} />
						{Math.abs(value)}%
					</span>
					{` ${worseText}`}
				</p>
			)}
		</div>
	)
}

PercentageSummary.propTypes = {
	value: PropTypes.number,
	data: PropTypes.arrayOf(PropTypes.number.isRequired),
	sizeOfComparison: PropTypes.number,
	inverseResult: PropTypes.bool,
	betterText: PropTypes.string.isRequired,
	worseText: PropTypes.string.isRequired,
}

export default PercentageSummary

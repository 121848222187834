import { FC, useMemo } from "react"

import { Card, Icon, Tabs, TabsProps, useUrlState } from "@ncs/web-legos"

import { DevicesTab, RegisterNewDeviceTab } from "./components"

export type ConnectedDevicesUrlState = {
	tab: ConnectedDevicesTabName
}

export enum ConnectedDevicesTabName {
	Devices = "Devices",
	RegisterNewDevice = "Register New Device",
}

export const ConnectedDevices: FC = () => {
	const [{ tab }, { updateUrlValue }] = useUrlState<ConnectedDevicesUrlState>({
		tab: ConnectedDevicesTabName.Devices,
	})

	const panels = useMemo((): TabsProps<ConnectedDevicesTabName>["panels"] => {
		return [
			{
				navLabel: ConnectedDevicesTabName.Devices,
				navIcon: <Icon icon="list" />,
				component: <DevicesTab />,
			},
			{
				navLabel: ConnectedDevicesTabName.RegisterNewDevice,
				navIcon: <Icon icon="plus" />,
				component: <RegisterNewDeviceTab />,
			},
		]
	}, [])

	return (
		<Card heading="Connected Devices" headingIcon="router">
			<Tabs
				currentTab={tab}
				onChange={(newTab) => updateUrlValue("tab", newTab)}
				panels={panels}
			/>
		</Card>
	)
}

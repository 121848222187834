import React from "react"
import get from "lodash/get"

//local components
import TechAssignmentCustomerAddContainer from "./TechAssigmentCustomerAddContainer"
import TechAssignmentChangeContainer from "./TechAssignmentChangeContainer"

//custom components
import {
	ButtonWithIcon,
	ConditionalContent,
	DynamicTable,
	GridContainer,
	GridItem,
	Input,
	InputAsDisplay,
	LoadingWrapper,
	Dialog,
	QuickCard,
	UserSelector,
} from "~/components"

import LocalShipping from "@material-ui/icons/LocalShipping"
import ChevronLeft from "@material-ui/icons/ChevronLeft"
import SwapHorizIcon from "@material-ui/icons/SwapHoriz"
import RemoveIcon from "@material-ui/icons/Remove"
import EditIcon from "@material-ui/icons/Edit"
import CheckIcon from "@material-ui/icons/Check"

//styles
import withStyles from "@material-ui/core/styles/withStyles"
const styles = {
	right: {
		textAlign: "right",
	},
}

const columns = [
	{
		Header: "Customer Number",
		accessor: "customer_number",
		sortable: true,
		toggleable: false,
	},
	{
		Header: "Name",
		accessor: "name",
		sortable: true,
		toggleable: true,
	},
	{
		Header: "Address 2",
		accessor: "address_2",
		sortable: true,
		hiddenByDefault: true,
	},
	{
		Header: "City",
		accessor: "city",
		sortable: true,
		hiddenByDefault: false,
	},
	{
		Header: "State",
		accessor: "state",
		sortable: true,
		hiddenByDefault: false,
	},
	{
		Header: "Postal Code",
		accessor: "postalcode",
		sortable: true,
		hiddenByDefault: true,
	},
]

function TechAssignmentDetail(props) {
	let {
		onBackToList,
		techAssignment,
		onDefaultTechChange,
		onAlternateTechChange,
		onStartEditing,
		onCancelEditing,
		onStartSwapDefault,
		onCancelSwapDefault,
		onConfirmSwapDefault,
		startSwapDefault,
		isLoading,
		customers,
		isEditing,
		description,
		onDescriptionChange,
		descriptionIsValid,
		descriptionErrorMessage,
		setInputRef,
		showValidationErrors,
		onConfirmEdit,
		classes,
		onSave,
		onDeleteAssignment,
	} = props
	return (
		<React.Fragment>
			<LoadingWrapper isLoading={isLoading} />
			<GridItem xs={12}>
				<ButtonWithIcon
					content="Back to List"
					onClick={onBackToList}
					color="white"
					size="sm"
					round
					icon={<ChevronLeft />}
				/>
			</GridItem>
			<QuickCard title="Technician Group Details" icon={<LocalShipping />}>
				<GridContainer>
					<GridItem xs={12} sm={6} show={!isEditing}>
						<InputAsDisplay
							labelText="Assignment Description"
							value={get(techAssignment, "description", "")}
						/>
					</GridItem>
					<GridItem xs={12} sm={6} show={isEditing}>
						<Input
							labelText={"Title"}
							value={description}
							onChange={onDescriptionChange}
							inputProps={{
								inputProps: { ref: setInputRef },
								disabled: !isEditing,
								autoFocus: isEditing,
							}}
							formControlProps={{
								error: showValidationErrors && !descriptionIsValid,
							}}
							helperText={showValidationErrors ? descriptionErrorMessage : ""}
						/>
					</GridItem>
					<GridItem xs={12} sm={4}>
						<InputAsDisplay
							labelText="Territory"
							value={`(${get(techAssignment, "territory.code")}) ${get(
								techAssignment,
								"territory.description"
							)}`}
						/>
					</GridItem>
					<GridItem xs={12} sm={2}>
						<InputAsDisplay
							labelText="Is Active"
							value={
								techAssignment ?
									techAssignment.is_active === true ?
										"True"
									:	"False"
								:	"N/A"
							}
						/>
					</GridItem>
					<ConditionalContent
						show={techAssignment ? techAssignment.default_not_available : false}
					>
						<GridItem
							xs={12}
							sm={3}
							show={
								!isEditing && techAssignment ?
									techAssignment.alternate !== null
								:	false
							}
						>
							<ButtonWithIcon
								icon={<SwapHorizIcon />}
								content={`Activate ${
									techAssignment ?
										techAssignment.default_not_available ?
											"Default"
										:	"Alternate"
									:	""
								}`}
								size="sm"
								round
								color="primary"
								onClick={onStartSwapDefault}
							/>
						</GridItem>
					</ConditionalContent>
					<ConditionalContent
						show={techAssignment ? !techAssignment.default_not_available : true}
					>
						<GridItem xs={12} sm={3}></GridItem>
					</ConditionalContent>

					<GridItem xs={12} sm={3} show={!isEditing}>
						<InputAsDisplay
							labelText="Default Tech"
							value={get(techAssignment, "default_tech.name", "")}
							error={
								techAssignment ?
									techAssignment.default_not_available ?
										true
									:	false
								:	false
							}
							success={
								techAssignment ?
									techAssignment.default_not_available ?
										false
									:	true
								:	false
							}
						/>
					</GridItem>

					<GridItem xs={12} sm={3} show={isEditing}>
						<UserSelector
							labelText={"Default Tech"}
							onUserSelected={onDefaultTechChange}
							defaultValue={techAssignment ? techAssignment.default_tech : null}
						/>
					</GridItem>

					<GridItem xs={12} sm={3} show={!isEditing}>
						<InputAsDisplay
							labelText="Alternate Tech"
							value={get(techAssignment, "alternate.name", "None")}
							error={
								techAssignment ?
									techAssignment.default_not_available ?
										false
									:	true
								:	false
							}
							success={
								techAssignment ?
									techAssignment.default_not_available ?
										true
									:	false
								:	false
							}
						/>
					</GridItem>
					<GridItem xs={12} sm={3} show={isEditing}>
						<UserSelector
							labelText={"Alternate Tech"}
							onUserSelected={onAlternateTechChange}
							defaultValue={techAssignment ? techAssignment.alternate : {}}
						/>
					</GridItem>
					<ConditionalContent
						hide={techAssignment ? techAssignment.default_not_available : true}
					>
						<GridItem
							xs={12}
							sm={3}
							show={
								!isEditing && techAssignment ?
									techAssignment.alternate !== null
								:	false
							}
						>
							<ButtonWithIcon
								icon={<SwapHorizIcon />}
								content={`Activate ${
									techAssignment ?
										techAssignment.default_not_available ?
											"Default"
										:	"Alternate"
									:	""
								}`}
								size="sm"
								round
								color="primary"
								onClick={onStartSwapDefault}
							/>
						</GridItem>
					</ConditionalContent>
					<ConditionalContent
						show={techAssignment ? techAssignment.default_not_available : true}
					>
						<GridItem xs={12} sm={3}></GridItem>
					</ConditionalContent>
					<GridItem xs={12} sm={3} show={isEditing}></GridItem>

					<GridItem xs={12} sm={2} show={isEditing}>
						<ButtonWithIcon
							icon={<RemoveIcon />}
							content={"Cancel"}
							size="sm"
							round
							color="warning"
							onClick={onCancelEditing}
						/>
					</GridItem>
					<GridItem xs={12} sm={2} show={isEditing}>
						<ButtonWithIcon
							icon={<CheckIcon />}
							content="Confirm"
							size="sm"
							round
							color="success"
							onClick={onConfirmEdit}
						/>
					</GridItem>
					<GridItem xs={12} sm={3} show={!isEditing}>
						<ButtonWithIcon
							icon={<EditIcon />}
							content="Edit Details"
							size="sm"
							round
							color="warning"
							onClick={onStartEditing}
						/>
					</GridItem>
					<GridItem
						xs={12}
						sm={3}
						show={isEditing && customers ? customers.length === 0 : false}
					>
						<ButtonWithIcon
							icon={<RemoveIcon />}
							content="Delete"
							size="sm"
							round
							color="danger"
							onClick={onDeleteAssignment}
						/>
					</GridItem>
					<GridItem xs={12} className={classes.right} show={!isEditing}>
						<TechAssignmentCustomerAddContainer
							territory={techAssignment ? techAssignment.territory._id : null}
							techAssignment={techAssignment}
							onSave={onSave}
						/>
					</GridItem>
				</GridContainer>
				<DynamicTable // TODO: make props static
					loading={isLoading}
					data={customers}
					columns={[
						...columns,
						{
							Header: "Actions",
							accessor: (x) => (
								<TechAssignmentChangeContainer
									territory={
										techAssignment ? techAssignment.territory._id : null
									}
									customer={x._id}
									onSave={onSave}
								/>
							),
							id: "actions",
							sortable: false,
							toggleable: false,
							maxWidth: 85,
						},
					]}
					useClientSideDataProcessing={true}
					showPaginationTop={true}
					showPaginationBottom={true}
				/>
			</QuickCard>
			<Dialog
				warning="true"
				show={startSwapDefault}
				title={`Are you sure you want to activate ${
					techAssignment ?
						techAssignment.default_not_available ?
							"Default"
						:	"Alternate"
					:	""
				} tech?`}
				confirmBtnIcon={<SwapHorizIcon />}
				confirmBtnText="Confirm"
				confirmBtnColor="success"
				onConfirm={onConfirmSwapDefault}
				isConfirmationAsync={true}
				onSuccessConfirm={onCancelSwapDefault}
				cancelBtnIcon={<RemoveIcon />}
				cancelBtnText={"Cancel"}
				cancelBtnColor={"danger"}
				onCancel={onCancelSwapDefault}
			/>
		</React.Fragment>
	)
}

export default withStyles(styles)(TechAssignmentDetail)

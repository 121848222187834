import { get, post, patch, del, rsaaTypes, RSAA } from "@ncs/bricks/middleware/portalApiMiddleware"

const FETCH_PART_ORDERS = rsaaTypes("part_orders/list")
const GET_PART_ORDER = rsaaTypes("part_order/get")
const REPAIR_PART_ORDER = rsaaTypes("part_order/repair")

const SET_ORDER = "SET_ORDER"
const RESET_PART_ORDER = "part_order/RESET_PART_ORDER"
const PART_ORDER_INITIAL = rsaaTypes("part_order/PART_ORDER_INITIAL")
const PART_ORDER_DETAIL = rsaaTypes("part_order/PART_ORDER_DETAIL")
const UPDATE_DETAIL_VIEW = "part_order/UPDATE_DETAIL_VIEW"
const CREATE_PO_INITIAL = rsaaTypes("part_order/CREATE_PO_INITIAL")
const CREATE_PART_ORDER = rsaaTypes("part_order/CREATE_PART_ORDER")
const UPDATE_PART_ORDER = rsaaTypes("part_order/UPDATE_PART_ORDER")
const CREATE_CUSTOMER_PART_ORDER = rsaaTypes("part_order/CREATE_CUSTOMER_PART_ORDER")
const GET_CUSTOMER_PART_ORDERS = rsaaTypes("part_order/GET_CUSTOMER_PART_ORDERS")
const EXPORT_CUSTOMER_ORDER_HISTORY = rsaaTypes("customer_orders/export")
const SELECT_CUSTOMER_PART_ORDER = "part_order/SELECT_CUSTOMER_PART_ORDER"
const GET_LOCATION_BINS = rsaaTypes("part_order/GET_LOCATION_BINS")
const RECEIVE_LINE_ITEM = rsaaTypes("part_order/RECEIVE_LINE_ITEM")
const GET_PART_ORDER_DOCUMENTS = rsaaTypes("part_order/GET_PART_ORDER_DOCUMENTS")
const PART_ORDER_CREATE = rsaaTypes("part_order/PART_ORDER_CREATE")
const RECREATE_ORDER_DOCUMENT = rsaaTypes("part_order/RECREATE_ORDER_DOCUMENT")
const EXPORT_PART_ORDER_HISTORY = rsaaTypes("part_order/EXPORT_PART_ORDER_HISTORY")
const CANCEL_PART_ORDER = rsaaTypes("part_order/CANCEL_PART_ORDER")
const UPDATE_PART_ORDER_HEADER = rsaaTypes("part_order/UPDATE_PART_ORDER_HEADER")
const UPDATE_PART_ORDER_LINE = rsaaTypes("part_order/UPDATE_PART_ORDER_LINE")
const ADD_PART_ORDER_LINE = rsaaTypes("part_order/ADD_PART_ORDER_LINE")
const REMOVE_PART_ORDER_LINE = rsaaTypes("part_order/REMOVE_PART_ORDER_LINE")
const UPDATE_PART_ORDER_INVOICE_LINE = rsaaTypes("part_order/UPDATE_PART_ORDER_INVOICE_LINE")
const REMOVE_PART_ORDER_INVOICE_LINE = rsaaTypes("part_order/REMOVE_PART_ORDER_INVOICE_LINE")
const ADD_PART_ORDER_INVOICE_LINE = rsaaTypes("part_order/ADD_PART_ORDER_INVOICE_LINE")
const APPROVE_PART_ORDER_INVOICE = rsaaTypes("part_order/APPROVE_PART_ORDER_INVOICE")
const COMPLETE_PART_ORDER = rsaaTypes("part_order/COMPLETE_PART_ORDER")
const GET_CUSTOMER_PART_ORDER_DETAIL = rsaaTypes("part_order/GET_CUSTOMER_PART_ORDER_DETAIL")
const SUBMIT_PART_ORDER_INVOICE = rsaaTypes("part_orders/SUBMIT_PART_ORDER_INVOICE")
const CHECK_PART_ORDER_LEVELS = rsaaTypes("part_orders/CHECK_PART_ORDER_LEVELS")
const CHECK_FULL_PART_ORDER = rsaaTypes("part_orders/CHECK_FULL_PART_ORDER")
const RESET_PART_ORDER_LINES = rsaaTypes("part_orders/RESET_PART_ORDER_LINES")
const CHECK_ITEM_LEVELS = rsaaTypes("part_orders/CHECK_ITEM_LEVELS")

export const fetchPartOrders = (querystringParams) => ({
	[RSAA]: {
		api: get(`/part_orders/`, querystringParams),
		types: FETCH_PART_ORDERS,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const getPartOrderByOrderNumber = (orderNumber) => ({
	[RSAA]: {
		api: get(`/part_orders/${orderNumber}/`),
		types: GET_PART_ORDER,
	},
})

export const repairPartOrder = (orderNumber) => ({
	[RSAA]: {
		api: post(`/part_orders/${orderNumber}/repair/`),
		types: REPAIR_PART_ORDER,
	},
})

export const getPartOrderInitial = () => ({
	[RSAA]: {
		api: get("/parts/initial"),
		types: PART_ORDER_INITIAL,
	},
})

export const getPartOrderDetail = (orderId, cb) => ({
	[RSAA]: {
		api: post("/parts/part_order_detail", orderId, cb),
		types: PART_ORDER_DETAIL,
	},
})

export const getPOCreateInitial = () => ({
	[RSAA]: {
		api: get("/parts/create_parts_order_initial"),
		types: CREATE_PO_INITIAL,
	},
})

export const createPartOrder = (order) => ({
	[RSAA]: {
		api: post("/inventory/create_parts_order", order),
		types: CREATE_PART_ORDER,
	},
})

export const updatePartOrder = (update) => ({
	[RSAA]: {
		api: post("/inventory/part_order_update", update),
		types: UPDATE_PART_ORDER,
	},
})

export const createCustomerPartOrder = (order) => ({
	[RSAA]: {
		api: post("/customer_orders/", order),
		types: CREATE_CUSTOMER_PART_ORDER,
	},
})

export const getCustomerPartOrders = (querystringParams) => ({
	[RSAA]: {
		api: get("/customer_orders/", querystringParams),
		types: GET_CUSTOMER_PART_ORDERS,
	},
})

export const exportCustomerOrderHistory = (querystringParams) => ({
	[RSAA]: {
		api: post(`/customer_orders/export/`, undefined, querystringParams),
		types: EXPORT_CUSTOMER_ORDER_HISTORY,
	},
})

export const getCustomerPartOrderDetail = (orderId) => ({
	[RSAA]: {
		api: get(`/customer_orders/${orderId}/`),
		types: GET_CUSTOMER_PART_ORDER_DETAIL,
	},
})

export const selectCustomerPartOrder = (orderId) => ({
	type: SELECT_CUSTOMER_PART_ORDER,
	payload: orderId,
})

export const getLocationBins = (location) => ({
	[RSAA]: {
		api: get(`/inventory/location_bins/${location}/`),
		types: GET_LOCATION_BINS,
	},
})

export const receiveLineItem = (partOrderId, binId, partId, quantity) => ({
	[RSAA]: {
		api: post("/parts/receive_part_order", {
			part_order_id: partOrderId,
			bin_id: binId,
			part_id: partId,
			quantity,
		}),
		types: RECEIVE_LINE_ITEM,
	},
})

export function resetPartOrder() {
	return {
		type: RESET_PART_ORDER,
	}
}

export function updateDetailView() {
	return {
		type: UPDATE_DETAIL_VIEW,
	}
}

export function setOrder(order) {
	return {
		type: SET_ORDER,
		order: order,
	}
}

export const getPartOrderDocuments = (orderId) => ({
	[RSAA]: {
		api: get(`/part_orders/${orderId}/documents/`),
		types: GET_PART_ORDER_DOCUMENTS,
	},
})

export const partOrderCreate = (order) => ({
	[RSAA]: {
		api: post("/part_orders/", order),
		types: PART_ORDER_CREATE,
	},
})

export const recreateOrderDoc = (orderId) => ({
	[RSAA]: {
		api: post(`/parts/recreate_order_doc/${orderId}/`),
		types: RECREATE_ORDER_DOCUMENT,
	},
})

export const exportPartOrderHistory = (querystringParams) => ({
	[RSAA]: {
		api: post(`/part_orders/export/`, undefined, querystringParams),
		types: EXPORT_PART_ORDER_HISTORY,
	},
})

export const cancelPartOrder = (orderId, override) => ({
	[RSAA]: {
		api: post(`/part_orders/${orderId}/cancel/`, { override: override }),
		types: CANCEL_PART_ORDER,
	},
})

export const updatePartOrderHeader = (orderId, updatedItems) => ({
	[RSAA]: {
		api: patch(`/part_orders/${orderId}/`, updatedItems),
		types: UPDATE_PART_ORDER_HEADER,
	},
})

export const updatePartOrderLine = (orderId, lineId, updatedItems) => ({
	[RSAA]: {
		api: patch(`/part_orders/${orderId}/line_items/${lineId}/`, updatedItems),
		types: UPDATE_PART_ORDER_LINE,
	},
})

export const addPartOrderLine = (orderId, lineItems) => ({
	[RSAA]: {
		api: post(`/part_orders/${orderId}/line_items/`, lineItems),
		types: ADD_PART_ORDER_LINE,
	},
})

export const removePartOrderLine = (orderId, lineId, override) => ({
	[RSAA]: {
		api: del(`/part_orders/${orderId}/line_items/${lineId}/`, { override: override }),
		types: REMOVE_PART_ORDER_LINE,
	},
})

export const updatePartOrderInvoiceLine = (orderId, lineId, updatedItems) => ({
	[RSAA]: {
		api: post(
			`/part_orders/${orderId}/line_items/${lineId}/update_order_invoice_line/`,
			updatedItems
		),
		types: UPDATE_PART_ORDER_INVOICE_LINE,
	},
})

export const completePartOrder = (orderId, params) => ({
	[RSAA]: {
		api: post(`/part_orders/${orderId}/complete/`, params),
		types: COMPLETE_PART_ORDER,
	},
})
export const addPartOrderInvoiceLine = (orderId, lineItems) => ({
	[RSAA]: {
		api: post(`/part_orders/${orderId}/add_order_invoice_line/`, lineItems),
		types: ADD_PART_ORDER_INVOICE_LINE,
	},
})

export const removePartOrderInvoiceLine = (orderId, lineId, override) => ({
	[RSAA]: {
		api: post(`/part_orders/${orderId}/line_items/${lineId}/delete_order_invoice_line/`),
		types: REMOVE_PART_ORDER_INVOICE_LINE,
	},
})

export const approvePartOrderInvoice = (orderId) => ({
	[RSAA]: {
		api: post(`/part_orders/${orderId}/approve_invoice/`),
		types: APPROVE_PART_ORDER_INVOICE,
	},
})

export const submitPartOrder = (orderId) => ({
	[RSAA]: {
		api: post(`/part_orders/${orderId}/submit/`),
		types: SUBMIT_PART_ORDER_INVOICE,
	},
})

export const checkInventoryLevels = (orderId) => ({
	[RSAA]: {
		api: post(`/part_orders/${orderId}/check_inventory/`),
		types: CHECK_PART_ORDER_LEVELS,
	},
})

export const checkFullShipments = (orderId) => ({
	[RSAA]: {
		api: post(`/part_orders/${orderId}/check_full_shipment/`),
		types: CHECK_FULL_PART_ORDER,
	},
})

export const resetOrder = (orderId) => ({
	[RSAA]: {
		api: post(`/part_orders/${orderId}/reset_order/`),
		types: RESET_PART_ORDER_LINES,
	},
})

export const checkItemLevels = (params) => ({
	[RSAA]: {
		api: post(`/parts/check_item_levels/`, params),
		types: CHECK_ITEM_LEVELS,
	},
})

const initialState = {
	isFetching: false,
	isRepairingPartOrder: false,
	error: false,
	locations: [],
	orders: [],
	customerOrders: null,
	selectedCustomerOrderId: null,
	order: {},
	detail: false,
	shippingMethodList: [],
	vendorList: [],
}

const partOrder = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_PART_ORDERS.request:
		case FETCH_PART_ORDERS.success:
		case FETCH_PART_ORDERS.failure:
			return {
				...state,
				isLoadingPartOrders: action.type === FETCH_PART_ORDERS.request,
				partOrders:
					action.type === FETCH_PART_ORDERS.success ? action.payload : state.partOrders,
				error: action.error,
			}

		case GET_PART_ORDER.request:
		case GET_PART_ORDER.success:
		case GET_PART_ORDER.failure:
			return {
				...state,
				isLoadingPartOrder: action.type === GET_PART_ORDER.request,
				partOrder:
					action.type === GET_PART_ORDER.success ? action.payload : state.partOrder,
				error: action.error,
			}

		case REPAIR_PART_ORDER.request:
		case REPAIR_PART_ORDER.success:
		case REPAIR_PART_ORDER.failure:
			return {
				...state,
				isRepairingPartOrder: action.type === REPAIR_PART_ORDER.request,
				error: action.error,
			}

		case SET_ORDER:
			return {
				...state,
				order: action.order,
			}

		case PART_ORDER_INITIAL.request:
		case UPDATE_PART_ORDER.request:
		case RECEIVE_LINE_ITEM.request:
		case GET_LOCATION_BINS.request:
		case CREATE_PART_ORDER.request:
		case CREATE_CUSTOMER_PART_ORDER.request:
		case GET_CUSTOMER_PART_ORDERS.request:
		case PART_ORDER_DETAIL.request:
		case CREATE_PO_INITIAL.request:
			return {
				...state,
				isFetching: true,
			}
		case GET_LOCATION_BINS.success:
		case CREATE_PART_ORDER.success:
		case CREATE_CUSTOMER_PART_ORDER.success:
			return {
				...state,
				isFetching: false,
			}
		case PART_ORDER_INITIAL.failure:
		case UPDATE_PART_ORDER.failure:
		case RECEIVE_LINE_ITEM.failure:
		case GET_LOCATION_BINS.failure:
		case CREATE_PART_ORDER.failure:
		case CREATE_CUSTOMER_PART_ORDER.failure:
		case GET_CUSTOMER_PART_ORDERS.failure:
		case PART_ORDER_DETAIL.failure:
		case CREATE_PO_INITIAL.error:
			return {
				...state,
				isFetching: false,
				error: true,
			}

		case PART_ORDER_INITIAL.success:
			return {
				...state,
				orders: action.payload.orders,
				locations: action.payload.locations,
				isFetching: false,
			}

		case UPDATE_PART_ORDER.success:
			return {
				...state,
				order: action.payload,
				isFetching: false,
			}

		case RECEIVE_LINE_ITEM.success:
			return {
				...state,
				order: action.payload.part_order,
				isFetching: false,
			}

		case PART_ORDER_DETAIL.success:
			return {
				...state,
				isFetching: false,
				order: action.payload,
				detail: true,
			}

		case CREATE_PO_INITIAL.success:
			return {
				...state,
				isFetching: false,
				vendorList: action.payload.vendor,
				shippingMethodList: action.payload.shipping,
			}

		case RESET_PART_ORDER:
			return {
				...initialState,
			}

		case UPDATE_DETAIL_VIEW:
			return {
				...state,
				detail: false,
				order: {},
			}

		case GET_CUSTOMER_PART_ORDERS.success:
			return {
				...state,
				isFetching: false,
				error: false,
				customerOrders: action.payload,
			}

		default:
			return state
	}
}
export default partOrder

import { FC, useMemo, useState } from "react"

import { useQueryClient } from "react-query"
import { Cell, Column } from "react-table"

import {
	InventoryLocation,
	InventoryPartType,
	InventoryUrlPaths,
	LocationVendorSummary,
	makeApiErrorMessage,
	useDelayedLocationVendorSummaries,
	useVendor,
} from "@ncs/ncs-api"
import { formatNumber, formatPhone } from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	Box,
	Button,
	Callout,
	DescriptionList,
	Divider,
	Heading,
	Link,
	LoadingSpinner,
	Paragraph,
	ParagraphList,
	ReactTableSortType,
	Table,
	useToast,
	useUrlState,
	VendorEditModal,
} from "@ncs/web-legos"

import { LocationSupplierDetail } from "./components"

export interface LocationReplenishmentTabProps {
	location: InventoryLocation
}

export type LocationReplenishmentTabUrlState = {
	vendor: string | null
}

export const LocationReplenishmentTab: FC<LocationReplenishmentTabProps> = ({ location }) => {
	const { makeErrorToast } = useToast()
	const queryClient = useQueryClient()
	const [vendorIdToEdit, setVendorIdToEdit] = useState<string | null>(null)
	const [summaries, isLoading] = useDelayedLocationVendorSummaries({
		params: {
			partType: InventoryPartType.All,
			location: location.id,
		},
		config: {
			enabled: !!location.isWarehouse,
		},
	})
	const [{ vendor: selectedVendorId }, { setUrlState }] =
		useUrlState<LocationReplenishmentTabUrlState>({ vendor: null })
	const [isRefreshing, setIsRefreshing] = useState(false)

	const handleRefresh = async () => {
		try {
			setIsRefreshing(true)
			await queryClient.invalidateQueries([
				InventoryUrlPaths.Inventory,
				InventoryUrlPaths.Replenishment,
			])
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		} finally {
			setIsRefreshing(false)
		}
	}

	const selectedVendorSummary = useMemo(() => {
		return (summaries ?? []).find(
			(summary) => summary.vendor.id.toString() === selectedVendorId
		)
	}, [summaries, selectedVendorId])
	const [vendorDetails, vendorDetailsLoading] = useVendor(selectedVendorId)

	const sortedSummaries = useMemo(() => {
		return (summaries ?? []).sort((a, b) => {
			return a.total > b.total ? -1 : 1
		})
	}, [summaries])

	const vendorPhone = vendorDetails?.contactInfo?.phone ?? vendorDetails?.phone

	if (!location.isWarehouse) {
		return (
			<Paragraph color="secondary" textAlign="center" py={4}>
				Only warehouse locations can have supplier demand at this time
			</Paragraph>
		)
	}

	if (isLoading || (!!selectedVendorId && !selectedVendorSummary)) {
		return (
			<Box maxWidth={40} m="auto">
				<Paragraph pt={5} mb={2} textAlign="center" secondary>
					Fetching the latest replenishment data for this location. Depending on the
					number of parts and vendors, this may take a minute or two...
				</Paragraph>
				<LoadingSpinner />
			</Box>
		)
	}

	return (
		<>
			{!selectedVendorId && (
				<Table
					columns={columns}
					data={sortedSummaries}
					noDataText={`No supplier demand found for ${
						location ? location.description : "the selected location."
					}`}
					isLoading={isLoading}
					onRowClick={(row) =>
						setUrlState({ vendor: row.original.vendor.id.toString() })
					}
				/>
			)}

			{!!selectedVendorId && (
				<AnimatedEntrance show>
					<Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
						<Button
							icon="long-arrow-left"
							onClick={() => setUrlState({ vendor: null })}
						>
							View different supplier
						</Button>
						<Button icon="sync" onClick={handleRefresh} isLoading={isRefreshing}>
							Refresh data
						</Button>
					</Box>

					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="flex-start"
						flexWrap="wrap"
						mb={4}
					>
						<div>
							<Heading variant="h2" mt={0} mb={0.5} bold>
								{vendorDetails?.name}
							</Heading>

							{selectedVendorSummary && (
								<>
									<DescriptionList
										variant="labeled-paragraph"
										items={[
											[
												"Stock needed",
												formatNumber(selectedVendorSummary.stock),
											],
											[
												"Non-stock needed",
												formatNumber(selectedVendorSummary.nonStock),
											],
										]}
									/>
									<Divider my={-0.5} width={10.5} />
									<DescriptionList
										variant="labeled-paragraph"
										labelWidth={6}
										items={[
											[
												"Total needed",
												formatNumber(selectedVendorSummary.total),
											],
										]}
									/>
								</>
							)}
						</div>

						<Callout p={1} mb={3}>
							<Heading variant="h4" mb={0.5}>
								{vendorDetails?.name}
							</Heading>
							<Box display="flex" columnGap={2}>
								<ParagraphList
									minWidth={10}
									isLoading={vendorDetailsLoading}
									lines={[
										[
											"Contact name: ",
											vendorDetails?.contactInfo?.contactName,
										],
										["Phone: ", vendorPhone ? formatPhone(vendorPhone) : null],
										[
											"Email: ",
											vendorDetails?.contactInfo?.email ?
												<Link
													mailTo
													to={vendorDetails?.contactInfo?.email}
													key="foo" // Keep React from complaining about missing key
												>
													{vendorDetails?.contactInfo?.email}
												</Link>
											:	null,
										],
									]}
								/>
								<ParagraphList
									minWidth={10}
									isLoading={vendorDetailsLoading}
									loadingRowCount={3}
									lines={[
										vendorDetails?.address2,
										vendorDetails?.address1,
										[vendorDetails?.city, ",", vendorDetails?.state],
										vendorDetails?.zipCode,
									]}
								/>
							</Box>
							{vendorDetails?.contactInfo?.website && (
								<div>
									<Link
										external
										newTab
										to={`${vendorDetails?.contactInfo?.website}`}
									>
										{vendorDetails.contactInfo.website}
									</Link>
								</div>
							)}
							<Button
								icon="address-card"
								onClick={() => setVendorIdToEdit(selectedVendorId)}
								containerProps={{ mt: 1.5 }}
							>
								Update supplier contact info
							</Button>
						</Callout>
					</Box>

					<LocationSupplierDetail
						locationId={location.id}
						vendorId={selectedVendorId}
						isRefreshing={isRefreshing}
					/>

					<VendorEditModal
						vendorId={vendorIdToEdit}
						isOpen={!!vendorIdToEdit}
						onClose={() => setVendorIdToEdit(null)}
					/>
				</AnimatedEntrance>
			)}
		</>
	)
}

const columns: Column<LocationVendorSummary>[] = [
	{
		Header: "Supplier",
		accessor: (summary) => summary.vendor.name,
	},
	{
		Header: "Stock needed",
		accessor: ({ stock }) => stock,
		sortType: ReactTableSortType.Number,
		Cell: ({ row: { original } }: Cell<LocationVendorSummary>) => (
			<Paragraph small>{formatNumber(original.stock, { zeroString: "-" })}</Paragraph>
		),
	},
	{
		Header: "Non-stock needed",
		accessor: ({ nonStock }) => nonStock,
		sortType: ReactTableSortType.Number,
		Cell: ({ row: { original } }: Cell<LocationVendorSummary>) => (
			<Paragraph small>{formatNumber(original.nonStock, { zeroString: "-" })}</Paragraph>
		),
	},
	{
		Header: "Total needed",
		accessor: ({ total }) => total,
		sortType: ReactTableSortType.Number,
		Cell: ({ row: { original } }: Cell<LocationVendorSummary>) => (
			<Paragraph small>{formatNumber(original.total, { zeroString: "-" })}</Paragraph>
		),
	},
]

import moment from "moment"
import get from "lodash/get"
import { getValueFromEventTargetValue } from "../util/state"
import { isValidEmail } from "../util/validators"

const NUMBER_TYPES = ["number", "currency", "integer"]

class FormValidator {
	constructor(container, validations) {
		if (typeof container !== "object")
			throw new Error("container object is required in order to manage state")

		if (typeof validations !== "object") throw new Error("validations object is required")

		this.container = container
		this.validations = validations
	}

	getInitialValue = (validations, field) => {
		if (validations[field].hasOwnProperty("default")) {
			let value = validations[field].default
			return typeof value === "function" ? value() : value
		}

		if (validations[field].hasOwnProperty("type")) {
			if (validations[field].type === "bool") {
				return false
			}

			if (validations[field].type === "object") {
				return null
			}
		}

		return ""
	}

	getInitialFormState = (validations = this.validations) => {
		return Object.keys(validations).reduce(
			(obj, field) => ({
				...obj,
				[validations[field].stateName]: this.getInitialValue(validations, field),
				[validations[field].stateName + "IsValid"]: true,
				[validations[field].stateName + "ErrorMessage"]: null,
			}),
			{
				showValidationErrors: false,
				formIsValid: true,
			}
		)
	}

	validateString = (
		{
			stateName,
			type = "string",
			isRequired = false,
			maxLength = 0,
			customValidation,
			dependents,
			maxValue = undefined,
			minValue = undefined,
		},
		newValue = undefined
	) => {
		let value = typeof newValue !== "undefined" ? newValue : this.container.state[stateName]

		if (
			isRequired &&
			(!value || (type === "array" && (!Array.isArray(value) || value.length === 0)))
		) {
			return { [`${stateName}IsValid`]: false, [`${stateName}ErrorMessage`]: "Required." }
		}

		if (maxLength > 0 && value && value.length > maxLength) {
			return {
				[`${stateName}IsValid`]: false,
				[`${stateName}ErrorMessage`]: `Max length is ${maxLength}.`,
			}
		}

		if (type === "email" && value && value.length > 0 && !isValidEmail(value)) {
			return {
				[`${stateName}IsValid`]: false,
				[`${stateName}ErrorMessage`]: "Email is invalid.",
			}
		}

		if (isRequired && !value) {
			return { [`${stateName}IsValid`]: false, [`${stateName}ErrorMessage`]: "Required." }
		}

		if (NUMBER_TYPES.includes(type) && maxValue && value && value > maxValue) {
			return {
				[`${stateName}IsValid`]: false,
				[`${stateName}ErrorMessage`]: `Must be less than ${maxValue}.`,
			}
		}

		if (NUMBER_TYPES.includes(type) && minValue && value && value < minValue) {
			return {
				[`${stateName}IsValid`]: false,
				[`${stateName}ErrorMessage`]: `Must be greater than or equal to ${minValue}.`,
			}
		}

		if (typeof customValidation === "function") {
			let errorMessage = customValidation({ stateName, type, isRequired, maxLength }, value)
			if (errorMessage)
				return {
					[`${stateName}IsValid`]: false,
					[`${stateName}ErrorMessage`]: errorMessage,
				}
		}

		if (Array.isArray(dependents)) {
			dependents.forEach((key) => setTimeout(() => this.validateInputs(key), 1))
		}

		return { [`${stateName}IsValid`]: true, [`${stateName}ErrorMessage`]: null }
	}

	dataIsValid = () => {
		// validate all the fields
		let validationState = Object.keys(this.validations).reduce(
			(obj, field) => ({
				...obj,
				...this.validateString(this.validations[field]),
			}),
			{}
		)

		// if any validation errors
		for (let key in validationState) {
			if (key.endsWith("IsValid") && validationState[key] === false) {
				this.container.setState({ formIsValid: false, ...validationState })
				return false
			}
		}

		// handle validation state changing from false to true
		this.container.setState({ formIsValid: true })

		return true
	}

	validateInputs = (changedKey, newValue) => {
		let state = this.container.state

		// only do per-change validation if errors are being shown
		if (!state.showValidationErrors) return

		let validation = Object.values(this.validations).find((x) => x.stateName === changedKey)
		let validationResult = this.validateString(validation, newValue)

		// contains validation errors
		if (validationResult[changedKey + "IsValid"] === false) {
			this.container.setState({ formIsValid: false, ...validationResult })
		}
		// valid, but was invalid
		else if (!state[changedKey + "IsValid"]) {
			this.container.setState(validationResult)
			// run all validations as this could make the entire form valid
			// do on a timeout so the onChange setState is there
			setTimeout(this.dataIsValid, 10)
		}
	}

	handleInputChanged = (key, type) => (e) => {
		let value = e

		// handle dates from datetime picker
		if (e === null) {
			// keep value as null
		} else if (e instanceof moment) {
			value = type === "date" ? e.format("YYYY-MM-DDT00:00") : e.toISOString()
		} else if (type === "bool" || type === "boolean") {
			value = typeof e === "boolean" ? e : getValueFromEventTargetValue(e)
		} else if (type === "object") {
			// keep value as e
		} else if (typeof e === "object") {
			if (e.hasOwnProperty("target") && e.target.hasOwnProperty("value"))
				value = getValueFromEventTargetValue(e)
			else if (e.hasOwnProperty("value")) value = e.value
		}

		this.container.setState({ [key]: value })
		this.validateInputs(key, value)
	}

	generateFormProps = () => {
		let state = this.container.state

		return Object.keys(this.validations).reduce(
			(obj, field) => {
				let name = this.validations[field].stateName

				return {
					...obj,
					[name]: state[name],
					[name + "IsValid"]: state[name + "IsValid"],
					[name + "ErrorMessage"]: state[name + "ErrorMessage"],
					["on" + name.charAt(0).toUpperCase() + name.slice(1) + "Change"]:
						this.handleInputChanged(name, this.validations[field].type),
				}
			},
			{
				showValidationErrors: state.showValidationErrors,
				formIsValid: state.formIsValid,
			}
		)
	}

	setFormStateFromObject = (obj, additionalState) => {
		let state = this.container.state

		let changedState = { ...additionalState }
		for (let field of Object.keys(this.validations)) {
			let path = this.validations[field].path || field
			let value = get(obj, path)

			if (value !== state[this.validations[field].stateName])
				changedState[this.validations[field].stateName] = value
		}

		this.container.setState(changedState)
	}

	isFieldReadOnly = (field) => {
		switch (typeof this.validations[field].readOnly) {
			case "undefined":
				return false
			case "boolean":
				return this.validations[field].readOnly
			case "function":
				return this.validations[field].readOnly()
			default:
				throw Error("Unsupported readOnly value")
		}
	}

	getFields = () => {
		let state = this.container.state

		let fields = {}
		for (let field of Object.keys(this.validations)) {
			fields[field] = state[this.validations[field].stateName]
		}

		return fields
	}

	getChangedFields = (original) => {
		let state = this.container.state

		let changedFields = {}
		for (let field of Object.keys(this.validations)) {
			let path = this.validations[field].path || field
			let originalValue = get(original, path)
			let currentValue = state[this.validations[field].stateName]

			if (originalValue !== currentValue && !this.isFieldReadOnly(field)) {
				changedFields[field] = currentValue
			}
		}

		return changedFields
	}
}

export default FormValidator

import { FC, useState } from "react"

import dayjs, { Dayjs } from "dayjs"

import { LaborPricingDetails, makeApiErrorMessage, useUpdateLaborPricing } from "@ncs/ncs-api"
import { DateFormat } from "@ncs/ts-utils"
import {
	DateInput,
	ExtendableModalProps,
	Modal,
	Paragraph,
	useIsSaving,
	useToast,
} from "@ncs/web-legos"

export interface EditPriceScheduleModalProps extends ExtendableModalProps {
	pricing: LaborPricingDetails
	priceHistoryItem: LaborPricingDetails["priceHistory"][number]
	isCurrent: boolean
}

export const EditPriceScheduleModal: FC<EditPriceScheduleModalProps> = ({
	priceHistoryItem: { id, startsOn, endsAfter },
	isCurrent,
	...rest
}) => {
	const { makeSuccessToast } = useToast()
	const [startDate, setStartDate] = useState<Dayjs | null>(() => dayjs(startsOn))
	const [endDate, setEndDate] = useState<Dayjs | null>(() =>
		endsAfter ? dayjs(endsAfter) : null
	)
	const { isSaving, setSaving, endSaving } = useIsSaving()
	const [errorText, setErrorText] = useState<string | null>(null)

	const updateRate = useUpdateLaborPricing()

	const handleSave = async () => {
		try {
			if (!startDate) {
				throw new Error("Start date is required")
			}
			if (!endDate) {
				throw new Error("End date is required")
			}
			if (endDate.isBefore(startDate) || endDate.isSame(startDate)) {
				throw new Error("End date must come after start date")
			}

			setSaving()
			await updateRate({
				updates: {
					// Only supply new dates if they're different.
					newStartDate:
						startDate.isSame(startsOn) ? undefined : (
							startDate.format(DateFormat.DateQueryParam)
						),
					newEndDate:
						endDate.isSame(endsAfter) ? undefined : (
							endDate.format(DateFormat.DateQueryParam)
						),
					rateId: id,
				},
			})
			makeSuccessToast("Rate schedule updated")
			rest.onClose()
		} catch (e) {
			endSaving()
			setErrorText(makeApiErrorMessage(e))
		}
	}

	return (
		<Modal
			{...rest}
			title="Edit Price Schedule"
			errorText={errorText}
			rightButtons={{
				buttonText: "Save Changes",
				onClick: handleSave,
				isLoading: isSaving(),
			}}
		>
			<Paragraph mb={1}>
				Edit the dates that this rate should be effective. Cannot change start date after
				it has begun.
			</Paragraph>
			<DateInput
				value={startDate}
				onChange={setStartDate}
				label="Starts on"
				disabled={isCurrent}
				mb={1}
				disablePast
			/>
			<DateInput value={endDate} onChange={setEndDate} label="Ends after" disablePast />
		</Modal>
	)
}

import { Dispatch, ReactElement, SetStateAction } from "react"

import { ContractCoverageTypeSelector } from "../selectors"

export interface ContractCoverageTypeParamState {
	coverageType: string | null
}

export interface ContractCoverageTypeQueryFilterProps<
	QueryParamState extends ContractCoverageTypeParamState,
> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

export const ContractCoverageTypeQueryFilter = <
	QueryParamState extends ContractCoverageTypeParamState,
>({
	queryParamState,
	setQueryParamState,
}: ContractCoverageTypeQueryFilterProps<QueryParamState>): ReactElement => {
	return (
		<ContractCoverageTypeSelector
			value={queryParamState.coverageType}
			fillContainer
			onChange={(newValue) =>
				setQueryParamState((prev) => {
					const newContractTypeParamState: ContractCoverageTypeParamState = {
						coverageType: newValue,
					}

					return {
						...prev,
						...newContractTypeParamState,
					}
				})
			}
		/>
	)
}

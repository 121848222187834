import { FC, useState } from "react"

import { CustomerDetail, LaborRateTypeId } from "@ncs/ncs-api"
import { formatCurrency } from "@ncs/ts-utils"
import {
	Box,
	Button,
	EmptyValueDash,
	GridContainer,
	GridItem,
	Icon,
	LabeledData,
} from "@ncs/web-legos"

import { PricingHistoryModal } from "./components"

export interface ServiceDetailsProps {
	customer: CustomerDetail
}

export const ServiceDetails: FC<ServiceDetailsProps> = ({ customer }) => {
	const [historyModalType, setHistoryModalType] = useState<LaborRateTypeId | null>(null)

	return (
		<>
			<GridContainer alignItems="baseline" rowGap={0}>
				<GridItem sm={4} md={3} lg={2}>
					<LabeledData label="Tech assigned">
						{customer.defaultTech?.name || <EmptyValueDash />}
					</LabeledData>
				</GridItem>
				<GridItem sm={4} md={3} lg={2}>
					<LabeledData label="Tech group assigned">
						{customer.defaultTechAssociation?.description || <EmptyValueDash />}
					</LabeledData>
				</GridItem>
				<GridItem sm={4} md={3} lg={2}>
					<LabeledData label="Chemical tech assigned">
						{customer.defaultChemTech?.name || <EmptyValueDash />}
					</LabeledData>
				</GridItem>
				<GridItem sm={4} md={3} lg={2}>
					<LabeledData label="Chemical tech group assigned">
						{customer.defaultChemTechAssociation?.description || <EmptyValueDash />}
					</LabeledData>
				</GridItem>
				<GridItem sm={4} md={3} lg={2}>
					<LabeledData label="Active?">
						{customer.isFstActive ?
							<>
								<Icon icon="check" /> Active
							</>
						:	"No"}
					</LabeledData>
				</GridItem>
				<GridItem sm={4} md={3} lg={2}>
					<LabeledData label="Preferred customer rate?">
						{customer.useAlternateLabor === false ?
							<>
								<Icon icon="check" /> Preferred customer rate
							</>
						:	"No"}
					</LabeledData>
				</GridItem>
			</GridContainer>

			<GridContainer alignItems="baseline" rowGap={0} mt={1}>
				<GridItem xs={12} sm={6}>
					<Box d="flex" flexDirection="column" gap={0.5} mb={1}>
						<LabeledData label="Labor rate group" mb={0}>
							{customer.currentLaborRate?.group || <EmptyValueDash />}
						</LabeledData>
						<Button
							icon="history"
							onClick={() => setHistoryModalType(LaborRateTypeId.Labor)}
						>
							View labor rate history
						</Button>
					</Box>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<LabeledData label="Labor rate">
						{customer.currentLaborRate?.rate ?
							formatCurrency(customer.currentLaborRate.rate)
						:	<EmptyValueDash />}
					</LabeledData>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<Box d="flex" flexDirection="column" gap={0.5}>
						<LabeledData label="Flat rate group" mb={0}>
							{customer.currentFlatRate?.group || <EmptyValueDash />}
						</LabeledData>
						<Button
							icon="history"
							onClick={() => setHistoryModalType(LaborRateTypeId.FlatRate)}
						>
							View flat rate history
						</Button>
					</Box>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<LabeledData label="Flat rate">
						{customer.currentFlatRate?.rate ?
							formatCurrency(customer.currentFlatRate.rate)
						:	<EmptyValueDash />}
					</LabeledData>
				</GridItem>
			</GridContainer>

			{historyModalType && (
				<PricingHistoryModal
					customer={customer}
					type={historyModalType}
					onClose={() => setHistoryModalType(null)}
				/>
			)}
		</>
	)
}

export function accessToken(state) {
	if (state.access) {
		return state.access.token
	}
}

export function refreshToken(state) {
	if (state.refresh) {
		return state.refresh.token
	}
}

export function isAccessTokenExpired(state) {
	if (state.access && state.access.exp) {
		return 1000 * state.access.exp - new Date().getTime() < 5000
	}
	return true
}

export function isRefreshTokenExpired(state) {
	if (state.refresh && state.refresh.exp) {
		return 1000 * state.refresh.exp - new Date().getTime() < 5000
	}
	return true
}

export function isAuthenticated(state) {
	return !isRefreshTokenExpired(state)
}

export function userId(state) {
	if (!isAuthenticated(state)) return null

	return state.userId
}

export function getName(state) {
	if (!isAuthenticated(state)) return null

	return state.name
}

export function errors(state) {
	return state.errors
}

export function allowedReports(state) {
	return state.allowedReports || []
}

export function meetsAppRestriction(state, restriction) {
	if ((typeof restriction !== "string" && !Array.isArray(restriction)) || !restriction.length)
		return true

	if (!state || !state.apps) return false

	if (typeof restriction === "string") restriction = [restriction]

	return restriction.some((x) => state.apps.includes(x))
}

// ts-import-sorter: disable

import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import cx from "classnames"
import memoizeOne from "memoize-one"
import isEqual from "lodash/isEqual"
import { Redirect, Route, Switch } from "react-router-dom"

import { useAuth } from "@ncs/ncs-api"
import { arrayWrap } from "@ncs/ts-utils"
import { filterRoutesByAppPermissions } from "~/routes"
import dashboardRoutes from "~/routes/dashboard"

// custom components
import { Footer, Header, Sidebar } from "~/components"
import { getBreadcrumbName } from "@ncs/mortar/components/Header/Header"
import { Toaster } from "@ncs/web-legos"

// assets
import image from "@ncs/mortar/assets/img/sidebar-truck-wash.jpg"
import logo from "@ncs/mortar/assets/img/NCSLogo.png"

// styles
import { makeStyles } from "@material-ui/core/styles"
import styles from "@ncs/bricks/assets/jss/material-dashboard-pro-react/layouts/dashboardStyle"
import { PageTitle } from "./PageTitle"

const useStyles = makeStyles(styles)

const switchRoutes = memoizeOne(
	(routes) => (
		<Switch>
			{routes.map((prop, key) => {
				if (prop.redirect) {
					return <Redirect from={prop.path} to={prop.pathTo} key={key} />
				}
				if (prop.collapse) {
					return prop.views.map((prop, key) => {
						return (
							<Route
								path={prop.path}
								component={prop.component}
								key={key}
								exact={prop.exact}
							/>
						)
					})
				}
				return <Route path={prop.path} component={prop.component} key={key} />
			})}
		</Switch>
	),
	isEqual
)

export default function Dashboard(props) {
	const mainPanelRef = useRef()
	const [state, setWholeState] = useState({
		mobileOpen: false,
		miniActive: false,
	})
	const setState = (newState) => setWholeState({ ...state, ...newState })

	function handleDrawerToggle() {
		setState({ mobileOpen: !state.mobileOpen })
	}

	function handleSidebarToggle() {
		setState({ miniActive: !state.miniActive })
	}

	useEffect(() => {
		if (props.history.location.pathname !== props.location.pathname && mainPanelRef.current) {
			mainPanelRef.current.scrollTop = 0
		}
	}, [props.history.location.pathname, props.location.pathname])

	const classes = useStyles()

	const mainPanel =
		classes.mainPanel +
		" " +
		cx({
			[classes.mainPanelSidebarMini]: state.miniActive,
		})

	const newAuth = useAuth()

	const getFilteredRoutes = useCallback(() => {
		return filterRoutesByAppPermissions(dashboardRoutes, (restriction) => {
			const requiresOneOf = arrayWrap(restriction)
			return requiresOneOf.some((app) => (newAuth.user?.apps ?? []).includes(app))
		})
	}, [newAuth.user?.apps])

	const filteredRoutes = getFilteredRoutes()

	const pageTitle = useMemo(() => {
		const pathname = props?.history?.location?.pathname

		if (!!pathname && !!dashboardRoutes && Array.isArray(dashboardRoutes)) {
			return getBreadcrumbName(dashboardRoutes, pathname)
		}

		return null
	}, [props?.history?.location?.pathname])

	return (
		<>
			{!!pageTitle && <PageTitle title={pageTitle} />}

			<div className={classes.wrapper}>
				<Sidebar
					routes={filteredRoutes}
					logoText={"NCS Internal"}
					logo={logo}
					image={image}
					handleDrawerToggle={handleDrawerToggle}
					open={state.mobileOpen}
					color="blue"
					bgColor="black"
					miniActive={state.miniActive}
					auth={props.auth}
					newAuth={newAuth}
					{...props}
				/>
				<div className={mainPanel} ref={mainPanelRef}>
					<Header
						sidebarMinimize={handleSidebarToggle}
						miniActive={state.miniActive}
						routes={filteredRoutes}
						auth={props.auth}
						newAuth={newAuth}
						handleDrawerToggle={handleDrawerToggle}
						{...props}
					/>
					<div className={classes.content}>
						<div className={classes.container}>{switchRoutes(filteredRoutes)}</div>
					</div>
					<Footer fluid />
				</div>
			</div>

			<Toaster />
		</>
	)
}

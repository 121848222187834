export const rowCommentStyles = ({ palette }) => ({
	errorMessage: {
		color: palette.warning.main,
		fontWeight: "bold",
		fontSize: "0.75rem",
		marginBottom: "0.25rem",
	},
	commentContainer: {
		display: "flex",
		alignItems: "center",
	},
	commentSelect: {
		position: "relative",
		"& svg": {
			position: "absolute",
			top: "2px",
			right: "0",
			fontSize: "1.25rem",
			opacity: 0.5,
			pointerEvents: "none",
		},
		"& select": {
			width: "100%",
			fontFamily: "inherit",
			fontSize: "0.85rem",
			color: "inherit",
			fontWeight: "inherit",
			appearance: "none",
			background: "#fafafa",
			borderRadius: "2px",
			border: 0,
			borderBottom: "2px solid #ddd",
			outline: 0,
			padding: "0.2rem 1.5rem 0.19rem 0.35rem",
			transition: "200ms ease-out",
			transitionProperty: "border-color",
			"&:hover": {
				borderColor: "#aaa",
			},
			"&:focus": {
				borderColor: palette.primary.main,
			},
		},
	},
	customComment: {
		width: "100%",
	},
	removeCustomComment: {
		marginLeft: "0.25rem",
		border: 0,
		background: "none",
		cursor: "pointer",
		"& svg": {
			color: "#bbb",
			fontSize: "1rem",
			"&:hover": {
				color: palette.primary.main,
			},
		},
	},
})

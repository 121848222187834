import { FC, memo, useCallback, useMemo, useState } from "react"

import { Cell, Column } from "react-table"

import {
	APPLICATION,
	defaultPricingMatrixItemsQueryParams,
	makeApiErrorMessage,
	PricingMatrix,
	PricingMatrixItem,
	useDeletePricingMatrixItem,
	usePricingMatrixItems,
	useUserCanUse,
} from "@ncs/ncs-api"
import { extractNumber, formatCurrency, formatPercentage } from "@ncs/ts-utils"
import {
	Button,
	ConfirmationModal,
	ConfirmationModalConfig,
	EmptyValueDash,
	Icon,
	SearchQueryFilter,
	Table,
	TableProps,
	useChangeCallback,
	useToast,
	useUrlState,
} from "@ncs/web-legos"

import { EditPricingMatrixItemModal } from "./EditPricingMatrixItemModal"

export interface PricingMatrixPartsTabProps {
	matrix: PricingMatrix | null
}

const PricingMatrixPartsTab: FC<PricingMatrixPartsTabProps> = ({ matrix }) => {
	const isDiscountAdmin = useUserCanUse(APPLICATION.PartDiscountAdmin)
	const deleteItem = useDeletePricingMatrixItem()
	const { makeSuccessToast, makeErrorToast } = useToast()
	const [queryParams, { setUrlState }] = useUrlState({ ...defaultPricingMatrixItemsQueryParams })
	const [showAddModal, setShowAddModal] = useState(false)
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)

	const paramsWithId = {
		...queryParams,
		matrix: matrix?.id ?? null,
	}

	const query = usePricingMatrixItems({
		params: paramsWithId,
		queryConfig: {
			enabled: !!matrix?.id,
		},
	})

	const onDelete = useCallback(
		async (id: string) => {
			try {
				await deleteItem(id)
				makeSuccessToast("Part removed from matrix")
			} catch (e) {
				makeErrorToast(makeApiErrorMessage(e))
			}
		},
		[deleteItem, makeErrorToast, makeSuccessToast]
	)

	const resetParamState = () => {
		setUrlState(defaultPricingMatrixItemsQueryParams)
	}

	useChangeCallback(matrix, () => {
		resetParamState()
	})

	const rowMenu = useMemo((): TableProps<PricingMatrixItem>["rowMenu"] => {
		return isDiscountAdmin ?
				[
					{
						label: "Remove",
						iconName: "trash",
						onClick: (row) =>
							setConfirmationConfig({
								title: "Remove Part",
								onConfirm: () => onDelete(row.original.id),
								message: `Remove ${row.original.partDescription} from ${matrix?.description}?`,
							}),
					},
				]
			:	undefined
	}, [isDiscountAdmin, matrix?.description, onDelete])

	return (
		<>
			{isDiscountAdmin && (
				<Button
					variant="secondary-cta"
					icon="plus-circle"
					onClick={() => setShowAddModal(true)}
				>
					Add Part To Matrix
				</Button>
			)}

			<Table
				query={query}
				columns={columns}
				queryParamState={queryParams}
				setQueryParamState={setUrlState}
				toggledQueryFilters={[SearchQueryFilter]}
				rowMenu={rowMenu}
				noDataText={
					queryParams.search ?
						`No results from "${queryParams.search}`
					:	"No parts in this matrix yet"
				}
			/>

			<EditPricingMatrixItemModal
				isOpen={showAddModal}
				onClose={() => setShowAddModal(false)}
				matrix={matrix}
			/>
			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</>
	)
}

const columns: Column<PricingMatrixItem>[] = [
	{
		Header: "Part #",
		accessor: "part",
		disableSortBy: true,
	},
	{
		Header: "Part",
		accessor: "partDescription",
		disableSortBy: true,
	},
	{
		Header: "Discount %",
		id: "discountRate",
		accessor: "discountRate",
		headingTooltip: "Percentage that the price is reduced",
		Cell: ({
			row: {
				original: { discountRate },
			},
		}: Cell<PricingMatrixItem>) => {
			if (discountRate == null) return <EmptyValueDash />

			const rate = extractNumber(discountRate)

			return rate > 0 ? formatPercentage(rate) : <EmptyValueDash />
		},
	},
	{
		Header: "Markup %",
		id: "markupRate",
		accessor: "discountRate",
		headingTooltip: "Percentage that the price is increased",
		disableSortBy: true,
		Cell: ({
			row: {
				original: { discountRate },
			},
		}: Cell<PricingMatrixItem>) => {
			if (discountRate == null) return <EmptyValueDash />

			const rate = extractNumber(discountRate)

			return rate < 0 ? `+ ${formatPercentage(Math.abs(rate))}` : <EmptyValueDash />
		},
	},
	{
		Header: "Exception Price",
		accessor: "exceptionPrice",
		Cell: ({
			row: {
				original: { exceptionPrice },
			},
		}: Cell<PricingMatrixItem>) =>
			exceptionPrice ? formatCurrency(exceptionPrice) : <EmptyValueDash />,
	},
	{
		Header: "Locked",
		accessor: "locked",
		Cell: ({
			row: {
				original: { locked },
			},
		}: Cell<PricingMatrixItem>) =>
			locked ? <Icon icon="lock" fontSize={0.8} color="gray" /> : <EmptyValueDash />,
	},
]

export default memo(PricingMatrixPartsTab)

import { FC } from "react"

import { CustomerDetail } from "@ncs/ncs-api"
import { Box, HeadingDivider } from "@ncs/web-legos"

import { sectionContentsContainer, sectionHeadingProps } from "../../customer-detail-util"
import { ContractParts, SiteEquipment, WearParts } from "./components"

export interface CustomerPartsEquipmentTabProps {
	customer: CustomerDetail
}

export const CustomerPartsEquipmentTab: FC<CustomerPartsEquipmentTabProps> = ({ customer }) => {
	return (
		<>
			<HeadingDivider {...sectionHeadingProps} mt={0}>
				Site Equipment
			</HeadingDivider>
			<Box {...sectionContentsContainer}>
				<SiteEquipment customer={customer} />
			</Box>

			<HeadingDivider {...sectionHeadingProps}>Wear Parts</HeadingDivider>
			<Box {...sectionContentsContainer}>
				<WearParts customer={customer} />
			</Box>

			<HeadingDivider {...sectionHeadingProps}>CPC Contract Part Inclusion</HeadingDivider>
			<Box {...sectionContentsContainer}>
				<ContractParts customer={customer} />
			</Box>
		</>
	)
}

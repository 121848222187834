const UPDATE_HEADER_TYPE = "header/UPDATE_HEADER_TYPE"

export function updateHeaderIcons(newHeader) {
	return {
		type: UPDATE_HEADER_TYPE,
		newHeader,
	}
}

const initialState = {
	headerIcons: "",
}

const header = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_HEADER_TYPE:
			return {
				headerIcons: action.newHeader,
			}
		default:
			return state
	}
}
export default header

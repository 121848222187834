import { FC, useState } from "react"

import { useFormContext } from "react-hook-form"

import { APPLICATION, useUserCanUse } from "@ncs/ncs-api"
import { Box, Button, Heading, IconButton, Paragraph } from "@ncs/web-legos"

import { AddContractDocumentModal } from "../AddContractDocumentModal"
import { NewContractForm } from "../contract-utils"

export const NewContractDocuments: FC = () => {
	const canEdit = useUserCanUse(APPLICATION.ContractCreator)
	const [showAddModal, setShowAddModal] = useState(false)

	const { watch, setValue } = useFormContext<NewContractForm>()
	const [documents] = watch(["documents"])

	const handleDocumentSave = (description: string, file: File) => {
		setValue(
			"documents",
			[
				...documents,
				{
					description,
					file,
				},
			],
			{
				shouldValidate: true,
			}
		)
	}

	const handleRemoveDocument = (description: string, fileName: string) => {
		setValue(
			"documents",
			documents.filter((d) => d.description !== description || d.file.name !== fileName),
			{
				shouldValidate: true,
			}
		)
	}

	return (
		<>
			{documents?.map((d) => (
				<Box key={`${d.description}-${d.file.name}`} mb={1}>
					<Heading variant="h4">{d.description}</Heading>
					<Box display="flex" alignItems="center" columnGap={0.5}>
						<Paragraph small bold>
							{d.file.name}
						</Paragraph>
						{canEdit && (
							<IconButton
								icon="trash-alt"
								color="primary"
								onClick={() => handleRemoveDocument(d.description, d.file.name)}
							/>
						)}
					</Box>
				</Box>
			))}

			<Button icon="plus" onClick={() => setShowAddModal(true)}>
				Add document
			</Button>

			<AddContractDocumentModal
				isOpen={showAddModal}
				onClose={() => setShowAddModal(false)}
				onSave={handleDocumentSave}
			/>
		</>
	)
}

import React, { Component } from "react"
import { withCallApi } from "~/components"
import { createTicketType } from "@ncs/mortar/redux/services/ticketing"
import FormValidator from "@ncs/bricks/util/formValidator"
import { compose } from "redux"

import TicketTypeAdd from "./TicketTypeAdd"

class TicketTypeAddContainer extends Component {
	constructor(props) {
		super(props)

		this.validations = {
			description: {
				stateName: "description",
				isRequired: true,
				maxLength: 255,
			},
			default_days_completion: {
				stateName: "daysCompletion",
				isRequired: true,
				type: "number",
				maxValue: 60,
			},
		}

		this.formValidator = new FormValidator(this, this.validations)

		this.state = {
			isAdding: false,
			user: null,
			isSaving: false,
			...this.formValidator.getInitialFormState(),
		}
	}

	handleSetInputRef = (input) => {
		this.input = input
	}

	handleTypeSave = () => {
		if (!this.formValidator.dataIsValid()) {
			this.setState({ showValidationErrors: true })
			return
		}
		let type = {
			group_id: this.props.selectedGroup,
			default_assigned_to_id: this.state.user ? this.state.user._id : null,
		}
		for (const [prop, config] of Object.entries(this.validations)) {
			type[prop] = this.state[config.stateName]
		}
		this.props.callApi(createTicketType(type)).then((result) => {
			if (result.error) {
				return this.setState({ error: result.error })
			} else {
				this.setState({
					success: true,
					newTypeMessage: "Type Added.",
				})
				this.setState({
					description: null,
					isAdding: false,
					isSaving: false,
				})
				this.props.onTypeSave()
				this.formValidator.setFormStateFromObject({ daysCompletion: "" })
			}
		})
	}
	handleTypeAddCancel = () => {
		this.setState({
			user: null,
			isAdding: false,
		})
	}

	handleTypeAdd = () => {
		this.setState({
			isAdding: true,
		})
	}

	handleUserChange = (user) => {
		this.setState({ user })
	}

	render() {
		return (
			<TicketTypeAdd
				setInputRef={this.handleSetInputRef}
				isAdding={this.state.isAdding}
				isSaving={this.state.isSaving}
				onSaveType={this.handleTypeSave}
				onCancelTypeAdd={this.handleTypeAddCancel}
				onTypeAdd={this.handleTypeAdd}
				success={this.state.success}
				error={this.state.error}
				onUserChange={this.handleUserChange}
				onTypeSave={this.props.onTypeSave}
				{...this.formValidator.generateFormProps()}
			/>
		)
	}
}

export default compose(withCallApi)(TicketTypeAddContainer)

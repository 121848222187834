import { FC, useState } from "react"

import { DatePickerProps } from "@material-ui/pickers"
import { Dayjs } from "dayjs"

import { IconButton, IconButtonProps } from "../buttons"
import { IconName } from "../typography"
import { DateInput } from "./DateInput"
import { FieldContainerProps } from "./FieldContainer"

export interface DateInputIconProps extends FieldContainerProps {
	value: Dayjs | null
	onChange: (newValue: Dayjs | null) => void | Promise<void>
	icon?: IconName
	iconButtonProps?: IconButtonProps
	datePickerProps?: Omit<DatePickerProps, "onChange" | "value">
}

export const DateInputIcon: FC<DateInputIconProps> = ({
	value,
	onChange,
	icon = "pencil",
	iconButtonProps,
	datePickerProps,
	...rest
}) => {
	const [open, setOpen] = useState(false)
	const [isSaving, setIsSaving] = useState(false)

	const handleChange = async (newValue: Dayjs | null) => {
		// Assume that passed onChange function is handling try/catch.
		setIsSaving(true)
		await onChange(newValue)
		setIsSaving(false)
	}

	return (
		<DateInput
			value={value}
			onChange={handleChange}
			label=""
			fillContainer={false}
			mb={0}
			datePickerProps={{
				autoOk: false,
				open: open,
				onClose: () => setOpen(false),
				TextFieldComponent: () => {
					return (
						<IconButton
							icon={icon}
							color="primary"
							onClick={() => {
								setOpen(true)
							}}
							isLoading={isSaving}
							{...iconButtonProps}
						/>
					)
				},
				...datePickerProps,
			}}
			{...rest}
		/>
	)
}

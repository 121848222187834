import { FC, useState } from "react"

import { Dayjs } from "dayjs"

import { makeApiErrorMessage, PartOrder, useUpdatePartOrder } from "@ncs/ncs-api"
import {
	DateTimeInput,
	ExtendableModalProps,
	Modal,
	Textarea,
	useIsSaving,
	useToast,
} from "@ncs/web-legos"

export interface RemoveHoldModalProps extends ExtendableModalProps {
	partOrder: PartOrder
}

export const RemoveHoldModal: FC<RemoveHoldModalProps> = ({ partOrder, ...rest }) => {
	const { makeSuccessToast } = useToast()
	const [newDate, setNewDate] = useState<Dayjs | null>(null)
	const [comment, setComment] = useState<string | null>(null)
	const { isSaving, setSaving, endSaving } = useIsSaving()
	const [errorText, setErrorText] = useState<string | null>(null)

	const updateOrder = useUpdatePartOrder(partOrder.id)

	const handleSave = async () => {
		if (!newDate) {
			setErrorText("Must provide a new target delivery date")
			return
		}

		try {
			setSaving()
			await updateOrder({
				id: partOrder.id,
				updates: {
					endHold: true,
					targetDeliveryDate: newDate.toISOString(),
					comment,
				},
			})
			makeSuccessToast("Order's hold status removed")
			rest.onClose()
		} catch (e) {
			endSaving()
			setErrorText(makeApiErrorMessage(e))
		}
	}
	return (
		<Modal
			{...rest}
			title="Remove Hold"
			errorText={errorText}
			rightButtons={{
				buttonText: "Remove Hold",
				onClick: handleSave,
				isLoading: isSaving(),
			}}
		>
			<DateTimeInput
				value={newDate}
				onChange={setNewDate}
				muiProps={{ disablePast: true }}
				clearable
				label="New target delivery date (required)"
			/>
			<Textarea
				value={comment}
				onChange={setComment}
				label="Comment (optional)"
				maxLength={320}
			/>
		</Modal>
	)
}

import React from "react"

import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Slide,
	withStyles,
} from "@material-ui/core"
import classNames from "classnames"
import uniqueId from "lodash/uniqueId"
import PropTypes from "prop-types"
import SweetAlert from "react-bootstrap-sweetalert"
import { Portal } from "react-portal"

import modalStyle from "../../assets/jss/material-dashboard-pro-react/modalStyle"
import buttonsStyle from "../../assets/jss/material-dashboard-pro-react/views/buttonsStyle"
import sweetAlertStyle from "../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle"
import { ButtonWithIcon } from "../../components"

import { Check, Delete, Remove } from "@material-ui/icons"

const styles = (theme) => ({
	...buttonsStyle,
	...sweetAlertStyle,
	...modalStyle(theme),
})

const DialogTransition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />
})

class CustomDialog extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			showSuccess: false,
			showCancellation: false,
		}
		this.id = props.id || uniqueId("dialog-")
	}

	showSuccess = () => {
		if (this.props.successTitle) {
			this.setState({ showSuccess: true })
		}
	}

	handleConfirm = (props) => () => {
		// call parent onConfirm for handling and hiding of main alert
		if (typeof props.onConfirm !== "function") return

		if (props.isConfirmationAsync && props.successTitle) {
			props.onConfirm(this.showSuccess.bind(this))
		} else {
			props.onConfirm()
			this.showSuccess()
		}
	}

	handleCancel = (props) => () => {
		// call parent onCancel for handling and hiding of main alert
		if (typeof props.onCancel === "function") props.onCancel()

		// show cancellation if configured
		if (props.cancellationTitle) {
			this.setState({ showCancellation: true })
		}
	}

	handleRemove = (props) => () => {
		// call parent onRemove for handling and hiding of main alert
		if (typeof props.onRemove !== "function") return

		if (props.isRemoveAsync) {
			props.onRemove(this.showSuccess.bind(this))
		} else {
			props.onRemove()
			this.showSuccess()
		}
	}

	handleConfirmSuccess = (props) => () => {
		this.setState({ showSuccess: false })
		if (typeof props.onSuccessConfirm === "function") props.onSuccessConfirm()
	}

	handleConfirmCancel = (props) => () => {
		this.setState({ showCancellation: false })
		if (typeof props.onCancellationConfirm === "function") props.onCancellationConfirm()
	}

	render() {
		const {
			classes,
			alignContentCenter,
			title,
			children,
			successTitle,
			successContent,
			successConfirmText,
			successConformColor,
			cancellationTitle,
			cancellationContent,
			cancellationConfirmText,
			cancellationConformColor,
			show,
			isModal,
			isSaving,
			successProps,
			cancellationProps,
			confirmBtnText,
			confirmBtnIcon,
			confirmBtnColor,
			cancelBtnText,
			cancelBtnIcon,
			cancelBtnColor,
			cancelBtnSimple,
			successTimeout,
			cancellationTimeout,
			confirmBtnDisabled,
			noTransition,
			onConfirm,
			onSuccessConfirm,
			onCancellationConfirm,
			isConfirmationAsync,
			actionButtons,
			removeBtnText,
			removeBtnIcon,
			removeBtnColor,
			removeBtnJustIcon,
			removeBtnDisabled,
			onRemove,
			// these are kept out of ...rest and used elsewhere via props.blah
			...rest
		} = this.props

		let successConfirmCss =
			classes.button +
			" " +
			(successConformColor || confirmBtnColor ?
				classes[successConformColor || confirmBtnColor]
			:	classes.success)

		let cancellationConfirmCss =
			classes.button +
			" " +
			(cancellationConformColor || confirmBtnColor ?
				classes[cancellationConformColor || confirmBtnColor]
			:	classes.success)

		return (
			<Portal>
				<Dialog
					classes={{
						root: classNames(
							{
								[classes.center]: alignContentCenter,
							},
							classes.modalRoot
						),
						paper: classNames(classes.modal, classes.modalHugTop),
					}}
					open={show && !this.state.showCancellation && !this.state.showSuccess}
					TransitionComponent={noTransition ? undefined : DialogTransition}
					aria-labelledby={`${this.id}-title`}
					aria-describedby={`${this.id}-description`}
					onClose={(e, reason) => {
						if (
							!isModal ||
							(reason !== "escapeKeyDown" && reason !== "backdropClick")
						) {
							this.handleCancel(this.props)()
						}
					}}
					{...rest}
				>
					<DialogTitle
						id={`${this.id}-title`}
						disableTypography
						className={classes.modalHeader}
					>
						<h4 className={classes.modalTitle}>{title}</h4>
					</DialogTitle>
					<DialogContent id={`${this.id}-description`} className={classes.modalBody}>
						{children}
					</DialogContent>
					<DialogActions
						className={`${classes.modalFooter}${
							alignContentCenter ? ` ${classes.modalFooterCenter}` : ""
						}`}
					>
						{(typeof this.props.onCancel === "function" ||
							typeof this.props.onCancellationConfirm === "function") && (
							<ButtonWithIcon
								onClick={this.handleCancel(this.props)}
								content={cancelBtnText}
								icon={cancelBtnIcon}
								color={cancelBtnColor}
								disabled={isSaving}
								simple={cancelBtnSimple}
								boxShadowHover={false}
							/>
						)}

						{(typeof this.props.onRemove === "function" ||
							typeof this.props.onRemoveConfirm === "function") && (
							<ButtonWithIcon
								onClick={this.handleRemove(this.props)}
								content={removeBtnJustIcon ? undefined : removeBtnText}
								icon={removeBtnIcon}
								color={removeBtnColor}
								justIcon={removeBtnJustIcon}
								title="Remove"
								disabled={isSaving || removeBtnDisabled}
								boxShadowHover={false}
							/>
						)}

						{actionButtons || (
							<ButtonWithIcon
								onClick={this.handleConfirm(this.props)}
								content={confirmBtnText}
								icon={confirmBtnIcon}
								color={confirmBtnColor}
								loading={isSaving}
								disabled={isSaving || confirmBtnDisabled}
								boxShadowHover={false}
							/>
						)}
					</DialogActions>
				</Dialog>

				{(successTitle || successContent) && (
					<SweetAlert
						success
						show={this.state.showSuccess}
						style={{ display: "block", marginTop: "-100px" }}
						title={successTitle}
						confirmBtnCssClass={successConfirmCss}
						confirmBtnText={successConfirmText}
						onConfirm={this.handleConfirmSuccess(this.props)}
						timeout={successTimeout}
						{...successProps}
					>
						{successContent}
					</SweetAlert>
				)}

				{(cancellationTitle || cancellationContent) && (
					<SweetAlert
						danger
						show={this.state.showCancellation}
						style={{ display: "block", marginTop: "-100px" }}
						title={cancellationTitle}
						confirmBtnCssClass={cancellationConfirmCss}
						confirmBtnText={cancellationConfirmText}
						onConfirm={this.handleConfirmCancel(this.props)}
						timeout={cancellationTimeout}
						{...cancellationProps}
					>
						{cancellationContent}
					</SweetAlert>
				)}
			</Portal>
		)
	}
}

CustomDialog.defaultProps = {
	isModal: true,
	alignContentCenter: true,

	confirmBtnText: "Submit",
	confirmBtnIcon: <Check />,
	confirmBtnColor: "success",
	confirmBtnDisabled: false,

	cancelBtnText: "Cancel",
	cancelBtnIcon: <Remove />,
	cancelBtnColor: "warning",

	removeBtnText: "Remove",
	removeBtnIcon: <Delete />,
	removeBtnColor: "danger",
	removeBtnJustIcon: false,

	successConfirmText: "OK",
	successContent: "",
	successTimeout: 0,

	cancellationConfirmText: "OK",
	cancellationContent: "",
	cancellationTimeout: 0,

	isConfirmationAsync: false,
}

CustomDialog.propTypes = {
	classes: PropTypes.object.isRequired,
	show: PropTypes.bool.isRequired,
	title: PropTypes.string.isRequired,
	onConfirm: PropTypes.func,
	onCancel: PropTypes.func,
	onRemove: PropTypes.func,
	isModal: PropTypes.bool,
	isSaving: PropTypes.bool,
	isConfirmationAsync: PropTypes.bool,
	noTransition: PropTypes.bool,
	alignContentCenter: PropTypes.bool,

	removeBtnIcon: PropTypes.node,
	removeBtnText: PropTypes.string,
	removeBtnColor: PropTypes.oneOf([
		"primary",
		"info",
		"success",
		"warning",
		"danger",
		"rose",
		"white",
	]),
	removeBtnDisabled: PropTypes.bool,
	removeBtnJustIcon: PropTypes.bool,

	confirmBtnIcon: PropTypes.node,
	confirmBtnText: PropTypes.string,
	confirmBtnColor: PropTypes.oneOf([
		"primary",
		"info",
		"success",
		"warning",
		"danger",
		"rose",
		"white",
	]),
	confirmBtnDisabled: PropTypes.bool,

	cancelBtnIcon: PropTypes.node,
	cancelBtnText: PropTypes.string,
	cancelBtnColor: PropTypes.oneOf([
		"primary",
		"info",
		"success",
		"warning",
		"danger",
		"rose",
		"white",
	]),
	cancelBtnSimple: PropTypes.bool,

	successTitle: PropTypes.string,
	successContent: PropTypes.node,
	successConfirmText: PropTypes.string,
	successConformColor: PropTypes.oneOf([
		"primary",
		"info",
		"success",
		"warning",
		"danger",
		"rose",
		"white",
	]),
	onSuccessConfirm: PropTypes.func,
	successTimeout: PropTypes.number,
	successProps: PropTypes.object,

	cancellationTitle: PropTypes.string,
	cancellationContent: PropTypes.node,
	cancellationConfirmText: PropTypes.string,
	cancellationConformColor: PropTypes.oneOf([
		"primary",
		"info",
		"success",
		"warning",
		"danger",
		"rose",
		"white",
	]),
	onCancellationConfirm: PropTypes.func,
	cancellationTimeout: PropTypes.number,
	cancellationProps: PropTypes.object,

	maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", false]),

	/** override all buttons, allows for more than one confirmation button */
	actionButtons: PropTypes.arrayOf(PropTypes.node),
}

export default withStyles(styles)(CustomDialog)

import { FC } from "react"

import { useFormContext, useWatch } from "react-hook-form"

import { useCustomer } from "@ncs/ncs-api"
import { tryToFormatPhone } from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	Box,
	Callout,
	EmptyValueDash,
	getAddressFields,
	GridContainer,
	GridItem,
	Heading,
	Label,
	LabeledData,
	Link,
	Paragraph,
	ParagraphList,
	SkeletonRows,
} from "@ncs/web-legos"

import { CreateDispatchForm } from "../../util"

export const CustomerDetails: FC = () => {
	const { control } = useFormContext<CreateDispatchForm>()
	const [customerId] = useWatch({
		control,
		name: ["customerId"],
	})

	const [details, detailsLoading] = useCustomer(customerId)

	return (
		<Callout variant="info" fillContainer>
			<Box d="flex" justifyContent="space-between" alignItems="center" flexGrow={1} mb={1}>
				<Heading variant="h5">Customer Details</Heading>
				{!!customerId && (
					<Link to={`/customer-management/${customerId}`} newTab icon="external-link">
						View customer
					</Link>
				)}
			</Box>

			{detailsLoading && (
				<Box d="flex" gap={2}>
					<Box flex={1}>
						<SkeletonRows rows={3} />
					</Box>
					<Box flex={1}>
						<SkeletonRows rows={3} />
					</Box>
				</Box>
			)}

			{!customerId && (
				<Paragraph small secondary>
					No customer selected yet
				</Paragraph>
			)}

			<AnimatedEntrance show={!!details}>
				<GridContainer>
					<GridItem xs={6}>
						<Label>Customer Number</Label>
						<Paragraph>{details?.customerNumber}</Paragraph>
					</GridItem>
					<GridItem xs={6}>
						<Label>Name</Label>
						<Paragraph>{details?.name}</Paragraph>
					</GridItem>
					<GridItem xs={6}>
						<Label>Address</Label>
						<ParagraphList
							lines={[...getAddressFields(details, { exclude: "name" })]}
						/>
					</GridItem>
					<GridItem xs={6}>
						<Label>Contact</Label>
						<ParagraphList
							lines={[
								details?.contactName,
								tryToFormatPhone(details?.phone) || undefined,
							]}
						/>
					</GridItem>
					<GridItem xs={6}>
						<LabeledData label="Territory">
							{details?.territory ?
								`(${details.territory.code}) ${details.territory.description}`
							:	<EmptyValueDash />}
						</LabeledData>
					</GridItem>
					<GridItem xs={6}>
						<LabeledData label="Credit status">
							{details?.creditCheckStatus || <EmptyValueDash />}
						</LabeledData>
					</GridItem>
				</GridContainer>
			</AnimatedEntrance>
		</Callout>
	)
}

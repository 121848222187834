import { FC, useCallback, useMemo, useState } from "react"

import { Column } from "react-table"

import { Enterprise, useEnterprises } from "@ncs/ncs-api"
import { displayDate, displayNumber, getTimezoneAbbreviation, yesOrNo } from "@ncs/ts-utils"
import {
	Box,
	Button,
	IsActiveQueryFilter,
	ReactTableSortType,
	SearchQueryFilter,
	Table,
	useUrlState,
} from "@ncs/web-legos"

import { NewEnterpriseModal, SelectedEnterprise } from "./components"

export type EnterprisesTabUrlState = {
	enterpriseId: string | null
}

export const EnterprisesTab: FC = () => {
	const [showCreateModal, setShowCreateModal] = useState(false)
	const [filterState, setFilterState] = useState<{
		isActive: boolean | null
		search: string | null
	}>({
		isActive: true,
		search: null,
	})

	const [enterprises, enterprisesLoading] = useEnterprises()

	const [{ enterpriseId }, { setUrlState }] = useUrlState<EnterprisesTabUrlState>({
		enterpriseId: null,
	})
	const selectedEnterprise = useMemo(() => {
		return (enterprises ?? []).find((e) => e.id === enterpriseId) ?? null
	}, [enterprises, enterpriseId])

	const setSelectedEnterpriseId = useCallback(
		(id: string | null) => {
			setUrlState((prev) => ({
				...prev,
				enterpriseId: id,
			}))
		},
		[setUrlState]
	)

	const filteredData = useMemo(() => {
		const searchChunks = filterState.search?.trim().toUpperCase().split(" ") ?? []

		return (enterprises ?? []).filter((enterprise) => {
			let passesSearch: boolean
			let passesStatus: boolean

			if (!filterState.search) {
				passesSearch = true
			} else {
				const searchable = `${enterprise.name}${enterprise.id}`.toUpperCase()

				passesSearch = searchChunks.every((chunk) => searchable.includes(chunk))
			}

			if (!passesSearch) return false

			if (filterState.isActive === null) {
				passesStatus = true
			} else {
				passesStatus = enterprise.isActive === filterState.isActive
			}

			return passesSearch && passesStatus
		})
	}, [enterprises, filterState.isActive, filterState.search])

	return (
		<>
			{selectedEnterprise ?
				<SelectedEnterprise
					enterprise={selectedEnterprise}
					setShowCreateModal={setShowCreateModal}
				/>
			:	<>
					<Box display="flex" justifyContent="flex-end">
						<Button icon="plus" onClick={() => setShowCreateModal(true)}>
							Create enterprise
						</Button>
					</Box>

					<Table
						data={filteredData}
						columns={columns}
						isLoading={enterprisesLoading}
						queryParamState={filterState}
						setQueryParamState={setFilterState}
						pinnedQueryFilters={[SearchQueryFilter]}
						toggledQueryFilters={[IsActiveQueryFilter]}
						onRowClick={({ original }) => setSelectedEnterpriseId(original.id)}
						defaultSort={[{ id: "Name" }]}
						noDataText={
							filterState.search ? `No enterprises match current filters` : undefined
						}
					/>
				</>
			}

			{showCreateModal && (
				<NewEnterpriseModal
					onClose={() => setShowCreateModal(false)}
					setSelectedEnterpriseId={setSelectedEnterpriseId}
				/>
			)}
		</>
	)
}

const columns: Column<Enterprise>[] = [
	{
		Header: "Name",
		accessor: ({ name }) => name,
	},
	{
		Header: "Organizations count",
		sortType: ReactTableSortType.Number,
		accessor: ({ organizationsCount }) => displayNumber(organizationsCount),
	},
	{
		Header: `Created Date (${getTimezoneAbbreviation()})`,
		hiddenByDefault: true,
		disableSortBy: true,
		accessor: ({ createdOn }) => displayDate(createdOn),
	},
	{
		Header: `Modified Date (${getTimezoneAbbreviation()})`,
		hiddenByDefault: true,
		disableSortBy: true,
		accessor: ({ modifiedOn }) => displayDate(modifiedOn),
	},
	{
		Header: "Is active?",
		accessor: ({ isActive }) => yesOrNo(isActive),
	},
]

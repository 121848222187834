import { FC } from "react"

import { EquipmentVintageLookup, useEquipmentVintagesLookup } from "@ncs/ncs-api"

import { useInitialId } from "../../util/use-initial-id"
import { ExtendableSearchableSelectProps, SearchableSelect } from "../inputs"

export interface EquipmentVintageSelectorProps
	extends ExtendableSearchableSelectProps<EquipmentVintageLookup> {
	initialId?: string | null
	initialModelId?: string | null
}

export const EquipmentVintageSelector: FC<EquipmentVintageSelectorProps> = ({
	value,
	onChange,
	initialId,
	initialModelId,
}) => {
	const [vintages, loading] = useEquipmentVintagesLookup()

	useInitialId({
		initialId,
		onChange,
		currentValue: value,
		findInitialOption: () => {
			return (vintages ?? []).find(
				(v) => v.id === initialId || v.model?.id === initialModelId
			)
		},
	})

	return (
		<SearchableSelect
			options={vintages ?? []}
			value={value ?? null}
			onItemSelect={onChange}
			getOptionLabel={(option) => option.name ?? ""}
			disabled={loading}
			label="Equipment vintage"
			placeholder="Search for a vintage..."
		/>
	)
}

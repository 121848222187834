import { FC } from "react"

import { useHistory, useLocation, useParams } from "react-router-dom"

import { ContractQueryParams, useOrganizationContract } from "@ncs/ncs-api"
import {
	Button,
	Card,
	encodeUrlState,
	Icon,
	Tabs,
	useScrollToTopOnMount,
	useUrlState,
} from "@ncs/web-legos"

import { ContractsListTabUrlState } from "../ContractsListTab"
import { ContractDetailDocumentsTab } from "./ContractDetailDocumentsTab"
import { ContractDetailNotificationsTab } from "./ContractDetailNotificationsTab"
import { ContractDetailSummaryTab } from "./ContractDetailSummaryTab"

export enum ContractDetailTab {
	ContractSummary = "Contract Summary",
	Documents = "Documents",
	Notifications = "Notifications",
}

const ContractDetail: FC = () => {
	useScrollToTopOnMount()
	const history = useHistory()
	const location = useLocation<{ contractQueryParams?: Partial<ContractQueryParams> }>()
	const { id } = useParams<{ id?: string }>()
	const [{ tab }, { updateUrlValue }] = useUrlState({ tab: ContractDetailTab.ContractSummary })
	const [contract] = useOrganizationContract(id)

	if (!id) {
		return null
	}

	return (
		<>
			<Button
				icon="long-arrow-left"
				containerProps={{ mb: 3 }}
				onClick={() =>
					history.push({
						pathname: "/contracts",
						search: encodeUrlState<ContractsListTabUrlState>(
							location.state?.contractQueryParams
						),
					})
				}
			>
				All contracts
			</Button>

			<Card
				heading={`Contract #${id}`}
				headingIcon="file-signature"
				headingDetail={contract?.organization}
			>
				<Tabs<ContractDetailTab>
					currentTab={tab}
					onChange={(newTab) => updateUrlValue("tab", newTab)}
					panels={[
						{
							navLabel: ContractDetailTab.ContractSummary,
							navIcon: <Icon icon="dollar-sign" />,
							component: <ContractDetailSummaryTab contractId={id} />,
						},
						{
							navLabel: ContractDetailTab.Documents,
							navIcon: <Icon icon="folder-open" />,
							component: <ContractDetailDocumentsTab contractId={id} />,
						},
						{
							navLabel: ContractDetailTab.Notifications,
							navIcon: <Icon icon="envelope" />,
							component: <ContractDetailNotificationsTab contractId={id} />,
						},
					]}
				/>
			</Card>
		</>
	)
}

export default ContractDetail

import { FC, useMemo } from "react"

import { Cell, Column } from "react-table"

import {
	LineItemPriceOverride,
	LineItemPriceOverridesQueryParams,
	useLineItemPriceOverrides,
} from "@ncs/ncs-api"
import { formatCurrency, formatDateTime, getTimezoneAbbreviation } from "@ncs/ts-utils"
import {
	Card,
	cssMixins,
	EmptyValueDash,
	encodeUrlState,
	FilterGrid,
	Link,
	Table,
	useUrlState,
} from "@ncs/web-legos"

import { MultiTerritoryQueryFilter, PriceOverridesModal } from "~/components"

import { DispatchDetailTab, DispatchDetailUrlState } from "../Dispatches/DispatchDetail"
import { UnhandledQueryFilter } from "./components"

export type PriceOverridesUrlState = LineItemPriceOverridesQueryParams & {
	overrideId: string | number | null
}

export const PriceOverrides: FC = () => {
	const [urlState, { setUrlState, updateUrlValue }] = useUrlState(defaultUrlState)

	const [overrides, overridesLoading] = useLineItemPriceOverrides({
		params: {
			unhandled: urlState.unhandled,
			territories: urlState.territories,
		},
	})

	const overrideForModal = useMemo(() => {
		return (
			(overrides ?? []).find(
				(override) => override.id === urlState.overrideId?.toString()
			) ?? null
		)
	}, [overrides, urlState.overrideId])

	return (
		<>
			<Card heading="Line Item Price Overrides" headingIcon="user-lock">
				<FilterGrid
					queryParamState={urlState}
					setQueryParamState={setUrlState}
					filters={[UnhandledQueryFilter, MultiTerritoryQueryFilter]}
					showReset={false}
				/>

				<Table
					data={overrides ?? []}
					columns={columns}
					isLoading={overridesLoading}
					filterResetValues={defaultQueryParams}
					storeColumnVisibility
					onRowClick={({ original }) => updateUrlValue("overrideId", original.id)}
				/>
			</Card>

			{!!overrideForModal && (
				<PriceOverridesModal
					overrides={[overrideForModal]}
					closeAfterSubmission
					onClose={() => updateUrlValue("overrideId", null)}
				/>
			)}
		</>
	)
}

const columns: Column<LineItemPriceOverride>[] = [
	{
		Header: `Requested (${getTimezoneAbbreviation()})`,
		accessor: "requestedOn",
		Cell: ({ row: { original } }: Cell<LineItemPriceOverride>) =>
			formatDateTime(original.requestedOn),
	},
	{
		Header: "From",
		Cell: ({ row: { original } }: Cell<LineItemPriceOverride>) => {
			return (
				original.partOrderId ?
					<Link
						newTab
						icon="external-link"
						to={`/part-orders/${original.partOrderId}`}
						css={cssMixins.noWrap}
					>
						Part Order #{original.partOrderOrderId}
					</Link>
				: original.dispatchId ?
					<Link
						newTab
						icon="external-link"
						to={`/service/dispatches/${
							original.dispatchId
						}${encodeUrlState<DispatchDetailUrlState>({
							tab: DispatchDetailTab.Invoice,
						})}`}
						css={cssMixins.noWrap}
					>
						Dispatch #{original.dispatchNumber}
					</Link>
				:	<EmptyValueDash />
			)
		},
	},
	{
		Header: "Customer",
		accessor: ({ customerName, customerNumber }) => `(${customerNumber}) ${customerName}`,
	},
	{
		Header: "Requested by",
		accessor: "requestorName",
	},
	{
		Header: "Reason",
		accessor: ({ reasonDescription, reasonComment }) => {
			const texts: string[] = []
			if (reasonDescription?.trim()) {
				texts.push(reasonDescription.trim())
			}
			if (reasonComment?.trim()) {
				texts.push(reasonComment.trim())
			}
			return texts.join(", ") || "(none)"
		},
	},
	{
		Header: "Line item",
		accessor: ({ lineDescription, lineTypeDescription }) => {
			return lineDescription || lineTypeDescription || "(no description)"
		},
	},
	{
		Header: "Current price",
		accessor: ({ currentRate }) => formatCurrency(currentRate),
	},
	{
		Header: "Requested price",
		accessor: ({ requestedRate }) => formatCurrency(requestedRate),
	},
	{
		Header: `Approved (${getTimezoneAbbreviation()})`,
		hiddenByDefault: true,
		accessor: "approvedOn",
		Cell: ({ row: { original } }: Cell<LineItemPriceOverride>) =>
			original.approvedOn ? formatDateTime(original.approvedOn) : <EmptyValueDash />,
	},
	{
		Header: `Denied (${getTimezoneAbbreviation()})`,
		hiddenByDefault: true,
		accessor: "deniedOn",
		Cell: ({ row: { original } }: Cell<LineItemPriceOverride>) =>
			original.deniedOn ? formatDateTime(original.deniedOn) : <EmptyValueDash />,
	},
	{
		Header: "Handled by",
		hiddenByDefault: true,
		accessor: "approverName",
		Cell: ({ row: { original } }: Cell<LineItemPriceOverride>) => {
			return original.approverName || <EmptyValueDash />
		},
	},
]

const defaultQueryParams: LineItemPriceOverridesQueryParams = {
	unhandled: true,
	territories: null,
}

const defaultUrlState: PriceOverridesUrlState = {
	...defaultQueryParams,
	overrideId: null,
}

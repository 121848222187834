import { FC, useEffect, useMemo, useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"

import {
	Equipment,
	EquipmentVintageLookup,
	makeApiErrorMessage,
	useCreateEquipment,
	useUpdateEquipment,
} from "@ncs/ncs-api"
import { DateFormat } from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	Callout,
	CheckboxFormField,
	DateInputFormField,
	EquipmentVintageSelector,
	ExtendableModalProps,
	HookFormErrorText,
	Modal,
	Paragraph,
	RadioBooleanFormField,
	TextInputFormField,
	useToast,
} from "@ncs/web-legos"

import {
	assembleRequestPatch,
	EquipmentForm,
	equipmentFormDefaultValues,
	EquipmentFormSchema,
	getFormValuesFromEquipment,
} from "../site-equipment-util"

export interface EquipmentModalProps extends ExtendableModalProps {
	customerId: string
	equipment: Equipment | null
}

export const EquipmentModal: FC<EquipmentModalProps> = ({ customerId, equipment, ...rest }) => {
	const { makeSuccessToast } = useToast()
	const [errorText, setErrorText] = useState<string | null>(null)
	const [isSaving, setIsSaving] = useState(false)

	const [selectedVintage, setSelectedVintage] = useState<EquipmentVintageLookup | null>(null)

	const create = useCreateEquipment(customerId)
	const update = useUpdateEquipment(customerId)

	const {
		control,
		reset: formReset,
		handleSubmit,
		formState: { isValid, submitCount, isDirty, dirtyFields },
		setValue,
		watch,
	} = useForm<EquipmentForm>({
		resolver: zodResolver(EquipmentFormSchema),
		defaultValues: {
			...equipmentFormDefaultValues,
			...getFormValuesFromEquipment(equipment),
		},
	})

	const handleVintageChange = (
		vintage: EquipmentVintageLookup | null,
		isSettingInitial?: boolean
	) => {
		// Update local state.
		setSelectedVintage(vintage)
		// Give the form both the vintage ID and model ID.
		const setValueOptions =
			isSettingInitial ? undefined : { shouldValidate: true, shouldDirty: true }

		setValue("eqpVintageId", vintage?.id || "", setValueOptions)
		setValue("modelId", vintage?.model?.id || "", setValueOptions)
	}

	const onSubmit = async (formData: EquipmentForm) => {
		try {
			if (equipment?.contracts.some((c) => c.isActive) && !formData.isActive) {
				throw new Error(
					"This equipment cannot be made inactive because it has one or more active contracts"
				)
			}

			setIsSaving(true)

			if (isEdit) {
				const updates = assembleRequestPatch(formData, dirtyFields)

				if (!!updates.eqpVintageId && updates.keepWorkorderHistory == null) {
					throw new Error("Please choose how to proceed with this equipment's history.")
				}

				await update({
					updates,
					id: equipment.id,
				})
				makeSuccessToast("Equipment updated")
			} else {
				await create(formData)
				makeSuccessToast("Equipment added")
			}
			rest.onClose()
		} catch (e) {
			setErrorText(makeApiErrorMessage(e))
			setIsSaving(false)
		}
	}

	const reset = () => {
		setIsSaving(false)
		setErrorText(null)
		setSelectedVintage(null)
		formReset(
			{
				...equipmentFormDefaultValues,
				...getFormValuesFromEquipment(equipment),
			},
			{
				keepDirty: false,
			}
		)
	}

	const keepWorkOrderHistory = watch("keepWorkOrderHistory")

	useEffect(() => {
		setErrorText(null)
	}, [keepWorkOrderHistory])

	const isChangingVintage = useMemo(() => {
		return !!equipment && !!dirtyFields.eqpVintageId
	}, [dirtyFields.eqpVintageId, equipment])

	const isEdit = !!equipment

	return (
		<Modal
			title={isEdit ? "Edit Equipment" : "Add Equipment"}
			onOpen={reset}
			errorText={errorText}
			{...rest}
			rightButtons={{
				buttonText: isEdit ? "Save Changes" : "Add Equipment",
				onClick: handleSubmit(onSubmit),
				isLoading: isSaving,
				disabled: (submitCount > 0 && !isValid) || (isEdit && !isDirty),
			}}
		>
			<CheckboxFormField control={control} name="isActive" label="Equipment is active" />
			<EquipmentVintageSelector
				value={selectedVintage}
				onChange={handleVintageChange}
				initialId={equipment?.modelId?.toString()}
			/>
			<HookFormErrorText control={control} name="eqpVintageId" mt={-0.5} mb={1} />
			<AnimatedEntrance show={isChangingVintage} direction="down">
				<Callout variant="info" icon="exclamation-triangle">
					<Paragraph mb={0.5} bold>
						You are modifying the equipment vintage. How would you like to handle its
						history?
					</Paragraph>
					<RadioBooleanFormField
						control={control}
						name="keepWorkOrderHistory"
						yesText="Edit the existing
						equipment and keep its history"
						noText="Deactivate the existing equipment and
						create a new customer equipment record"
					/>
				</Callout>
			</AnimatedEntrance>

			<TextInputFormField
				control={control}
				name="serialNumber"
				label="Serial number"
				returnEmptyString
			/>
			<TextInputFormField control={control} name="bay" />
			<CheckboxFormField
				control={control}
				name="requiresWashCounts"
				label="Wash count required"
			/>
			<DateInputFormField
				control={control}
				name="installDate"
				label="Install date"
				dateFormat={DateFormat.DateQueryParam}
				clearable
			/>
			<DateInputFormField
				control={control}
				name="frameWarrantyExpireDate"
				label="Frame warranty expiration date"
				dateFormat={DateFormat.DateQueryParam}
				clearable
			/>
			<DateInputFormField
				control={control}
				name="laborWarrantyExpireDate"
				label="Labor warranty expiration date"
				dateFormat={DateFormat.DateQueryParam}
				clearable
			/>
			<DateInputFormField
				control={control}
				name="partsWarrantyExpireDate"
				label="Parts warranty expiration date"
				dateFormat={DateFormat.DateQueryParam}
				clearable
			/>
		</Modal>
	)
}

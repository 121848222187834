import { Dispatch, ReactElement, SetStateAction } from "react"

import { IncludeExpiredSelector } from "../selectors"

export enum IncludeExpiredParamKey {
	IncludeExpired = "includeExpired",
}

export interface IncludeExpiredParamState {
	[IncludeExpiredParamKey.IncludeExpired]: boolean | null
}

export interface IncludeExpiredQueryFilterProps<QueryParamState extends IncludeExpiredParamState> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

export const IncludeExpiredQueryFilter = <QueryParamState extends IncludeExpiredParamState>({
	queryParamState,
	setQueryParamState,
}: IncludeExpiredQueryFilterProps<QueryParamState>): ReactElement => {
	return (
		<IncludeExpiredSelector
			value={
				queryParamState[IncludeExpiredParamKey.IncludeExpired] == null ? null
				: queryParamState[IncludeExpiredParamKey.IncludeExpired] === true ?
					"true"
				:	"false"
			}
			disableNoSelectionOption={false}
			fillContainer
			onChange={(newValue, newOption) =>
				setQueryParamState((prev) => {
					const newIncludeExpiredParamState: IncludeExpiredParamState = {
						[IncludeExpiredParamKey.IncludeExpired]: newOption?.booleanValue ?? null,
					}

					return {
						...prev,
						...newIncludeExpiredParamState,
					}
				})
			}
			tooltip="Include pricing items that do not have a price currently active?"
		/>
	)
}

import { FC, useMemo } from "react"

import { DeviceRegistrationResults } from "@ncs/ncs-api"
import {
	Box,
	Button,
	ExtendableModalProps,
	Icon,
	IconProps,
	Modal,
	Paragraph,
	useSetUrlState,
} from "@ncs/web-legos"

import { ConnectedDevicesTabName, ConnectedDevicesUrlState } from "../../../ConnectedDevices"

export interface RegistrationResultsModalProps extends ExtendableModalProps {
	results: DeviceRegistrationResults
}

export const RegistrationResultsModal: FC<RegistrationResultsModalProps> = ({
	results,
	...rest
}) => {
	const setUrlState = useSetUrlState<ConnectedDevicesUrlState>()

	const handleDone = () => {
		setUrlState({ tab: ConnectedDevicesTabName.Devices })
	}

	const outcomeIconProps = (outcome: boolean): IconProps => {
		const baseProps: Partial<IconProps> = {
			fixedWidth: true,
		}

		return outcome ?
				{
					...baseProps,
					icon: "check-circle",
					family: "solid",
					color: "success",
				}
			:	{
					...baseProps,
					icon: "times",
					color: "error",
				}
	}

	const formattedResults = useMemo((): Record<string, boolean> => {
		return {
			"Device Created": results.deviceCreated,
			"Device Online": results.deviceOnline,
			"Device Valid": results.deviceValid,
			"Server Connection": results.serverConnection,
		}
	}, [results])

	return (
		<Modal {...rest} title="Registration Results" showCloseButton={false}>
			<Box d="flex" flexDirection="column" gap={0.5}>
				{Object.entries(formattedResults).map(([label, outcome]) => {
					return (
						<Box key={label} d="flex" gap={0.5} alignItems="center">
							<Icon {...outcomeIconProps(outcome)} />
							<Paragraph>{label}</Paragraph>
						</Box>
					)
				})}
				<Box d="flex" gap={0.5} alignItems="center">
					<Icon icon="message" fixedWidth color="gray" />
					<Paragraph>Error Message: {results.error || "(No error message)"}</Paragraph>
				</Box>
			</Box>

			<Button
				variant="secondary-cta"
				fillContainer
				onClick={handleDone}
				containerProps={{ mt: 4 }}
			>
				Done
			</Button>
		</Modal>
	)
}

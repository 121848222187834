import { Dispatch, ReactElement, SetStateAction } from "react"

import { DeviceStatusSelector } from "../selectors"

interface DeviceStatusQueryParams {
	status: string | null
}

export interface DeviceStatusQueryFilterProps<QueryParamState extends DeviceStatusQueryParams> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

export const DeviceStatusQueryFilter = <QueryParamState extends DeviceStatusQueryParams>({
	queryParamState,
	setQueryParamState,
}: DeviceStatusQueryFilterProps<QueryParamState>): ReactElement => {
	const handleChange = (newValue: string | null) => {
		setQueryParamState((prev) => ({
			...prev,
			status: newValue,
		}))
	}

	return (
		<DeviceStatusSelector
			value={queryParamState.status}
			onChange={handleChange}
			disableNoSelectionOption={false}
			noSelectionOptionText="Any status"
			fillContainer
		/>
	)
}

import { FC, useMemo, useState } from "react"

import dayjs from "dayjs"
import { Cell, Column } from "react-table"

import { Customer, Equipment, useEquipment } from "@ncs/ncs-api"
import { formatDate } from "@ncs/ts-utils"
import {
	Box,
	Button,
	EmptyValueDash,
	Icon,
	LoadingSpinner,
	Paragraph,
	Table,
	TableProps,
} from "@ncs/web-legos"

import { EquipmentContractsModal, EquipmentModal } from "./components"

export interface SiteEquipmentProps {
	customer: Customer
}

export const SiteEquipment: FC<SiteEquipmentProps> = ({ customer }) => {
	const [contractsModalEquipment, setContractsModalEquipment] = useState<Equipment | null>(null)
	const [editModalEquipment, setEditModalEquipment] = useState<Equipment | null>(null)
	const [showAddModal, setShowAddModal] = useState(false)

	const [equipment, equipmentLoading] = useEquipment(customer.id)

	const equipmentRowMenu: TableProps<Equipment>["rowMenu"] = useMemo(() => {
		return [
			{
				label: "Edit",
				iconName: "pencil",
				onClick: ({ original }) => setEditModalEquipment(original),
			},
			{
				label: "View contracts",
				iconName: "file-signature",
				onClick: ({ original }) => setContractsModalEquipment(original),
			},
		]
	}, [])

	if (equipmentLoading) {
		return <LoadingSpinner />
	}

	return (
		<>
			<Button icon="plus" onClick={() => setShowAddModal(true)}>
				Add equipment to site
			</Button>

			{equipment?.length ?
				<Table
					columns={siteEquipmentColumns}
					data={(equipment ?? []).sort((a, b) =>
						a?.requiresWashCounts > b?.requiresWashCounts ? -1 : 1
					)}
					isLoading={equipmentLoading}
					disableAllSorting
					noDataText="No equipment found for site"
					rowMenu={equipmentRowMenu}
				/>
			:	<Paragraph small color="secondary" mt={1}>
					No equipment found for site
				</Paragraph>
			}

			{(showAddModal || !!editModalEquipment) && (
				<EquipmentModal
					isOpen
					onClose={() => {
						setShowAddModal(false)
						setEditModalEquipment(null)
					}}
					equipment={editModalEquipment}
					customerId={customer.id}
				/>
			)}
			{!!contractsModalEquipment && (
				<EquipmentContractsModal
					isOpen
					onClose={() => setContractsModalEquipment(null)}
					equipmentId={contractsModalEquipment.id}
					customerId={customer.id}
				/>
			)}
		</>
	)
}

const siteEquipmentColumns: Column<Equipment>[] = [
	{
		Header: "Serial #",
		accessor: "serialNumber",
	},
	{
		Header: "Model",
		accessor: (original) => original.model || <EmptyValueDash />,
	},
	{
		Header: "Bay",
		accessor: (original) => original.bay || <EmptyValueDash />,
	},
	{
		Header: "Install date",
		accessor: ({ installDate }) =>
			installDate ? formatDate(installDate, { formatInUtc: true }) : <EmptyValueDash />,
	},
	{
		Header: "Contracts",
		id: "contracts",
		Cell: ({ row: { original } }: Cell<Equipment>) => {
			return original.contracts.length ?
					<>
						{original.contracts
							.map((c) => (c.isActive ? c.type : `${c.type} (inactive)`))
							.join(", ")}
					</>
				:	<EmptyValueDash />
		},
	},
	{
		Header: "Wash count required?",
		accessor: "requiresWashCounts",
		Cell: ({ row: { original } }: Cell<Equipment>) =>
			original.requiresWashCounts ?
				<Box display="flex" alignItems="center" columnGap={0.5}>
					<Icon icon="check" color="gray" />
					<span>Wash count required</span>
				</Box>
			:	<EmptyValueDash />,
	},
	{
		Header: "Frame warranty expire date",
		hiddenByDefault: true,
		accessor: ({ frameWarrantyExpireDate }) => {
			if (frameWarrantyExpireDate) {
				const date = formatDate(frameWarrantyExpireDate, { formatInUtc: true })
				const expired = dayjs(frameWarrantyExpireDate).isBefore(Date.now(), "d")

				return expired ? `${date} (expired)` : date
			} else {
				return <EmptyValueDash />
			}
		},
	},
	{
		Header: "Labor warranty expire date",
		hiddenByDefault: true,
		accessor: ({ laborWarrantyExpireDate }) => {
			if (laborWarrantyExpireDate) {
				const date = formatDate(laborWarrantyExpireDate, { formatInUtc: true })
				const expired = dayjs(laborWarrantyExpireDate).isBefore(Date.now(), "d")

				return expired ? `${date} (expired)` : date
			} else {
				return <EmptyValueDash />
			}
		},
	},
	{
		Header: "Parts warranty expire date",
		hiddenByDefault: true,
		accessor: ({ partsWarrantyExpireDate }) => {
			if (partsWarrantyExpireDate) {
				const date = formatDate(partsWarrantyExpireDate, { formatInUtc: true })
				const expired = dayjs(partsWarrantyExpireDate).isBefore(Date.now(), "d")

				return expired ? `${date} (expired)` : date
			} else {
				return <EmptyValueDash />
			}
		},
	},
	{
		Header: "Active?",
		accessor: "isActive",
		Cell: ({ row: { original } }: Cell<Equipment>) =>
			original.isActive ?
				<Box display="flex" alignItems="center" columnGap={0.5}>
					<Icon icon="check" color="gray" />
					<span>Active</span>
				</Box>
			:	<EmptyValueDash />,
	},
]

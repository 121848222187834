import { FC, useMemo, useState } from "react"

import { Cell, Column } from "react-table"

import {
	BillOfMaterial,
	makeApiErrorMessage,
	useBillOfMaterials,
	useUpdateBillOfMaterials,
} from "@ncs/ncs-api"
import { displayNumber } from "@ncs/ts-utils"
import {
	Box,
	Button,
	Card,
	ConfirmationModal,
	ConfirmationModalConfig,
	encodeUrlState,
	Icon,
	IsActiveQueryFilter,
	SearchQueryFilter,
	Table,
	TableProps,
	useToast,
} from "@ncs/web-legos"

import { PartManagementUrlState } from "~/views/Inventory/PartManagement/PartManagement"

import { confirmDisableBomMessage, confirmEnableBomMessage } from "./chemical-pour-down-util"
import { MixRecipeModal, NewRecipeModal } from "./components"

export const ChemicalPourDown: FC = () => {
	const { makeSuccessToast, makeErrorToast } = useToast()
	const [modalBomId, setModalBomId] = useState<string | null>(null)
	const [filters, setFilters] = useState<FilterState>(defaultFilters)
	const [showNewBomModal, setShowNewBomModal] = useState(false)
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)

	const [boms, bomsLoading] = useBillOfMaterials()
	const updateBom = useUpdateBillOfMaterials()

	const recipesList = useMemo(() => {
		const searchParts = filters.search?.trim().toLowerCase().split(" ") ?? null

		return (boms ?? []).filter((bom) => {
			let passedSearch: boolean
			let passedStatus: boolean

			if (!searchParts?.length) {
				passedSearch = true
			} else {
				const searchable =
					`${bom.name}${bom.partDescription}${bom.partNumber}`.toLowerCase()

				passedSearch = searchParts.every((s) => searchable.includes(s))
			}

			if (filters.isActive == null) {
				passedStatus = true
			} else {
				passedStatus = bom.isActive === filters.isActive
			}

			return passedSearch && passedStatus
		})
	}, [boms, filters.search, filters.isActive])

	const modalBom = useMemo(() => {
		if (!modalBomId) return null

		return boms?.find((b) => b.id === modalBomId) ?? null
	}, [boms, modalBomId])

	const rowMenu = useMemo((): TableProps<BillOfMaterial>["rowMenu"] => {
		const items: TableProps<BillOfMaterial>["rowMenu"] = [
			{
				label: "Open recipe",
				iconName: "fill-drip",
				onClick: ({ original }) => setModalBomId(original.id),
			},
			{
				label: "View part management page",
				iconName: "external-link",
				disabledAccessor: ({ original }) => !original.partId,
				onClick: ({ original }) =>
					window.open(
						`/inventory/part-management${encodeUrlState<PartManagementUrlState>({
							partId: original.partId,
						})}`
					),
			},
			{
				label: "Enable",
				iconName: "check",
				hiddenAccessor: ({ original }) => original.isActive !== false,
				onClick: ({ original }) => {
					setConfirmationConfig({
						title: "Enable Bill Of Material",
						message: confirmEnableBomMessage,
						onConfirm: async () => {
							try {
								await updateBom({
									id: original.id,
									updates: { isActive: true },
								})
								makeSuccessToast("Status updated")
							} catch (e) {
								makeErrorToast(makeApiErrorMessage(e))
							}
						},
					})
				},
			},
			{
				label: "Disable",
				iconName: "trash-alt",
				hiddenAccessor: ({ original }) => original.isActive !== true,
				onClick: ({ original }) => {
					setConfirmationConfig({
						title: "Disable Bill Of Material",
						message: confirmDisableBomMessage,
						onConfirm: async () => {
							try {
								await updateBom({
									id: original.id,
									updates: { isActive: false },
								})
								makeSuccessToast("Status updated")
							} catch (e) {
								makeErrorToast(makeApiErrorMessage(e))
							}
						},
					})
				},
			},
		]

		return items
	}, [updateBom, makeSuccessToast, makeErrorToast])

	return (
		<Card
			heading="Chemical Pour Down"
			headingIcon="fill-drip"
			renderRight={() => (
				<Button icon="plus-circle" onClick={() => setShowNewBomModal(true)}>
					Create New Bill Of Material
				</Button>
			)}
		>
			<Table
				data={recipesList}
				columns={columns}
				noDataText={
					filters.search || filters.isActive != null ?
						"No recipes match current filter settings"
					:	undefined
				}
				isLoading={bomsLoading}
				onRowClick={({ original }) => setModalBomId(original.id)}
				queryParamState={filters}
				setQueryParamState={setFilters}
				pinnedQueryFilters={[SearchQueryFilter]}
				toggledQueryFilters={[IsActiveQueryFilter]}
				filterResetValues={defaultFilters}
				defaultSort={[{ id: "name" }]}
				rowMenu={rowMenu}
			/>

			{!!modalBom && <MixRecipeModal bom={modalBom} onClose={() => setModalBomId(null)} />}
			{showNewBomModal && <NewRecipeModal onClose={() => setShowNewBomModal(false)} />}
			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</Card>
	)
}

const columns: Column<BillOfMaterial>[] = [
	{
		Header: "Bill of material recipe name",
		id: "name",
		accessor: ({ name }) => name,
	},
	{
		Header: "Resulting chemical",
		accessor: ({ partNumber, partDescription }) =>
			partNumber ? `(${partNumber}) ${partDescription}` : "-",
	},
	{
		Header: "Ingredients count",
		accessor: ({ items }) => displayNumber(items.length),
	},
	{
		Header: "Status",
		hiddenByDefault: true,
		accessor: ({ isActive }) => isActive,
		Cell: ({ row: { original } }: Cell<BillOfMaterial>) => {
			return original.isActive ?
					<Box display="flex" alignItems="center" gap={0.5}>
						<Icon icon="check" color="gray" /> Active
					</Box>
				:	"Not active"
		},
	},
]

interface FilterState {
	search: string | null
	isActive: boolean | null
}

const defaultFilters: FilterState = {
	search: null,
	isActive: true,
}

import React, { useEffect } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import memoizeOne from "memoize-one"
import { BaseSelectFilter, withDynamicTableFilter } from "@ncs/bricks/components"
import { loadLookups, LOOKUPS } from "../../redux/services/lookups"

const getCustomerPortalOptions = memoizeOne((portals) => [
	{ id: "", description: "All Portals" },
	...portals,
])

function CustomerPortalFilter(props) {
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(loadLookups([LOOKUPS.Portals]))
	}, [dispatch])

	const customerPortals = useSelector((x) => x.lookups.portals, shallowEqual)

	return (
		<BaseSelectFilter
			id="customerPortal"
			labelText="Customer Portal"
			options={getCustomerPortalOptions(customerPortals)}
			valueAccessor="id"
			textAccessor="description"
			{...props}
		/>
	)
}

export default withDynamicTableFilter("bill_to_customer__portal_assignment__portal_id")(
	CustomerPortalFilter
)

import React from "react"
import PropTypes from "prop-types"
import {
	createStyles,
	FormControl,
	FormControlLabel,
	FormControlLabelProps,
	FormControlProps,
	FormHelperText,
	makeStyles,
	Radio,
	RadioProps,
} from "@material-ui/core"
import { FiberManualRecord } from "@material-ui/icons"

import customCheckboxStyle from "../../assets/jss/material-dashboard-pro-react/components/customCheckboxStyle"
import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch"

const useStyles = makeStyles({
	...customCheckboxStyle,
	...customCheckboxRadioSwitch,
	...createStyles({
		formControlLabel: {
			userSelect: "none",
		},
		errorMessage: {
			marginTop: "-7px",
		},
	}),
})

export interface CustomRadioProps {
	labelText: React.ReactNode
	value: string | number
	selectedValue?: string | number
	onChange?: RadioProps["onChange"]
	inputProps?: RadioProps
	labelProps?: Omit<FormControlLabelProps, "label" | "control">
	isError?: boolean
	helperText?: string
	formControlProps?: FormControlProps
}

const CustomRadio: React.FC<CustomRadioProps> = ({
	labelText,
	selectedValue,
	value,
	onChange,
	inputProps,
	labelProps,
	isError,
	helperText,
	formControlProps,
}) => {
	const classes = useStyles()

	return (
		<FormControl fullWidth {...formControlProps} error={isError}>
			<FormControlLabel
				control={
					<Radio
						checked={selectedValue === value}
						onChange={onChange}
						tabIndex={-1}
						icon={<FiberManualRecord className={classes.radioUnchecked} />}
						checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
						classes={{
							checked: classes.radio,
							root: classes.radioRoot,
						}}
						{...inputProps}
					/>
				}
				classes={{ label: classes.label, root: classes.labelRoot }}
				label={labelText}
				className={classes.formControlLabel}
				{...labelProps}
			/>
			{helperText && (
				<FormHelperText className={classes.errorMessage}>{helperText}</FormHelperText>
			)}
		</FormControl>
	)
}

CustomRadio.propTypes = {
	labelText: PropTypes.node.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onChange: PropTypes.func,
	inputProps: PropTypes.object,
	labelProps: PropTypes.object,
	isError: PropTypes.bool,
	helperText: PropTypes.string,
	formControlProps: PropTypes.object,
}

export default CustomRadio

import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import callApi from "@ncs/bricks/redux/services/callApi"
import { loadLookups, LOOKUPS } from "../../redux/services/lookups"

// custom components
import { Select } from "@ncs/bricks/components"

class TicketRequestorSelector extends React.PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			requestors: [],
			requestor: null,
		}
	}

	componentDidMount() {
		this.props.loadLookups([LOOKUPS.TicketRequestors], this.filterTicketRequestor)
	}

	// filter requestors whenever the customer changes
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.customer !== this.props.customer) {
			this.filterTicketRequestor()
		}
		if (prevProps.requestor !== this.props.requestor) {
			this.setState({
				requestor: this.state.requestors.filter((x) => x.id === this.props.requestor),
			})
		}
	}

	filterTicketRequestor = () => {
		// if customer is empty
		if (!this.props.customer) {
			this.setState({ requestors: [] })
			return
		}
		let requestors = this.props.ticketRequestors.filter(
			(x) => x.customer_id === Number(this.props.customer)
		)
		this.setState({ requestors })

		// if the currently selected requestor is not in the results, erase the selected requestor
		if (
			this.state.requestor &&
			!requestors.some((t) => t.id.toString() === this.state.requestor)
		) {
			this.props.onChange(null)
		}
	}

	render() {
		const { displayEmpty, ...rest } = this.props
		return (
			<Select
				id="ticket_requestors"
				labelText="Ticket Requestors"
				value={
					this.props.requestor ? this.props.requestor
					: this.state.requestor ?
						this.state.requestor
					:	null
				}
				options={[
					...this.state.requestors,
					{
						id: null,
						name: "other",
						disabled: false,
					},
				]}
				valueAccessor="id"
				textAccessor="name"
				onChange={this.props.onChange}
				labelProps={{
					shrink: displayEmpty ? true : undefined,
					...rest.labelProps,
				}}
				selectProps={{
					displayEmpty: displayEmpty,
					disabled: this.state.requestors.length < 1,
					...rest.selectProps,
				}}
				{...rest}
			/>
		)
	}
}

TicketRequestorSelector.defaultProps = {
	displayEmpty: true,
}

TicketRequestorSelector.propTypes = {
	type: PropTypes.object,
	onBinSelected: PropTypes.func.isRequired,
	displayEmpty: PropTypes.bool,
}

const mapStateToProps = (state) => {
	return {
		ticketRequestors: state.lookups.ticketRequestors,
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			callApi,
			loadLookups,
		},
		dispatch
	)

export default connect(mapStateToProps, mapDispatchToProps)(TicketRequestorSelector)

import { FC, useState } from "react"

import { css } from "@emotion/react"
import saveAs from "file-saver"

import {
	LabelSize,
	makeApiErrorMessage,
	PartOrderLineItem,
	PurchaseOrderLineItemMin,
	useCreateLabel,
} from "@ncs/ncs-api"
import { extractNumber } from "@ncs/ts-utils"
import {
	defaultLabelSize,
	ExtendableModalProps,
	LabelSizeSelector,
	Modal,
	NumericInput,
	Paragraph,
	useToast,
} from "@ncs/web-legos"

import {
	isPartOrderLineItem,
	isPurchaseOrderLineItem,
} from "../receive-parts-modal/receive-parts-modal-util"

export interface PrintLinePartNumbersModalProps extends ExtendableModalProps {
	partOrderOrderNumber?: string | null
	purchaseOrderId?: string | null
	lines: (PurchaseOrderLineItemMin | PartOrderLineItem)[]
}

export const PrintLinePartNumbersModal: FC<PrintLinePartNumbersModalProps> = ({
	partOrderOrderNumber,
	purchaseOrderId,
	lines,
	...rest
}) => {
	const { makeSuccessToast } = useToast()
	const [size, setSize] = useState<LabelSize>(defaultLabelSize)
	const [quantitiesState, setQuantitiesState] = useState(() =>
		makeQuantitiesStateFromLines(lines)
	)
	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const makeLabels = useCreateLabel()

	const handleDownload = async () => {
		try {
			if (Object.values(quantitiesState).every((line) => !line.quantityToPrint)) {
				throw new Error("No lines have a quantity greater than zero")
			}

			setIsSaving(true)
			const { data } = await makeLabels({
				labelSize: size,
				labels: Object.values(quantitiesState).flatMap((line) =>
					line.quantityToPrint ?
						[
							{
								label: line.partNumber,
								quantity: line.quantityToPrint,
							},
						]
					:	[]
				),
			})
			let label = ""
			if (partOrderOrderNumber) label = `part_order_${partOrderOrderNumber}_labels.pdf`
			if (purchaseOrderId) label = `purchase_order_${purchaseOrderId}_labels.pdf`
			saveAs(new Blob([data as Blob]), label)
			makeSuccessToast("Labels generated")
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	return (
		<Modal
			{...rest}
			errorText={errorText}
			title="Part Number QR Code Labels"
			rightButtons={{
				buttonText: "Download QR Codes",
				onClick: handleDownload,
				isLoading: isSaving,
			}}
		>
			<LabelSizeSelector value={size} onChange={setSize} />
			<Paragraph mb={1}>How many copies of each line item part's number?</Paragraph>

			<table css={tableCss}>
				<thead>
					<tr>
						<th>
							<h6>Part Number</h6>
						</th>
						<th>
							<h6>Order Qty</h6>
						</th>
						<th>
							<h6>Print Qty</h6>
						</th>
					</tr>
				</thead>
				<tbody>
					{Object.values(quantitiesState).map((line) => (
						<tr key={line.lineItemId}>
							<td>
								<Paragraph>{line.partNumber}</Paragraph>
							</td>
							<td>
								<Paragraph>{line.orderQuantity}</Paragraph>
							</td>
							<td>
								<NumericInput
									value={line.quantityToPrint}
									onChange={(newValue) =>
										setQuantitiesState((prev) => ({
											...prev,
											[line.lineItemId]: {
												...prev[line.lineItemId],
												quantityToPrint: newValue ?? null,
											},
										}))
									}
									placeholder="Print qty..."
									maxWidth={6}
									fillContainer
									mb={0}
								/>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</Modal>
	)
}

interface QuantitiesState {
	[lineItemId: string]: {
		lineItemId: string
		partNumber: string
		orderQuantity: number
		quantityToPrint: number | null
	}
}

const makeQuantitiesStateFromLines = (
	lines: (PurchaseOrderLineItemMin | PartOrderLineItem)[]
): QuantitiesState => {
	const quantities: QuantitiesState[string][] = lines.flatMap((line) => {
		if (isPartOrderLineItem(line) && line.part) {
			return [
				{
					lineItemId: line.id,
					partNumber: line.part.partNumber,
					orderQuantity: extractNumber(line.quantity),
					quantityToPrint: extractNumber(line.quantity),
				},
			]
		}
		if (isPurchaseOrderLineItem(line) && line.part) {
			return [
				{
					lineItemId: line.id.toString(),
					partNumber: line.part.partNumber,
					orderQuantity: extractNumber(line.quantity),
					quantityToPrint: extractNumber(line.quantity),
				},
			]
		}

		return []
	})

	return Object.fromEntries(quantities.map((q) => [q.lineItemId, q]))
}

const tableCss = css`
	tbody {
		tr {
			vertical-align: middle;
		}
	}
	th {
		text-align: left;
		h6 {
			font-weight: bold;
		}
	}
`

import React, { useCallback } from "react"
import PropTypes from "prop-types"
import {
	FormControl,
	FormControlLabel,
	FormHelperText,
	Checkbox,
	makeStyles,
	FormControlLabelProps,
	CheckboxProps,
	FormControlProps,
} from "@material-ui/core"

import styles from "../../assets/jss/material-dashboard-pro-react/components/customCheckboxStyle"

const useStyles = makeStyles(styles)

export interface BaseCustomCheckboxProps {
	labelText: React.ReactNode
	checked: boolean
	// Omit 'control' and 'label' because including them would overwrite props on FormControlLabel.
	labelProps?: Omit<FormControlLabelProps, "control" | "label">
	inputProps?: CheckboxProps
	isError?: boolean
	helperText?: string
	formControlProps?: FormControlProps
}

export interface CustomCheckboxPropsWithOnClick {
	onClick: (event: React.ChangeEvent<HTMLInputElement>) => void
	onToggle?: undefined
}

export interface CustomCheckboxPropsWithOnToggle {
	onToggle: (checked: boolean) => void
	onClick?: undefined
}

const CustomCheckbox: React.FC<
	BaseCustomCheckboxProps & (CustomCheckboxPropsWithOnClick | CustomCheckboxPropsWithOnToggle)
> = (props) => {
	const { labelText, checked, inputProps, labelProps, isError, helperText, formControlProps } =
		props
	const onClick = "onClick" in props ? props.onClick : undefined
	const onToggle = "onToggle" in props ? props.onToggle : undefined
	const classes = useStyles()

	const handleClick = useCallback(
		(
			onClick: CustomCheckboxPropsWithOnClick["onClick"] | undefined,
			onToggle: CustomCheckboxPropsWithOnToggle["onToggle"] | undefined
		) =>
			(event: React.ChangeEvent<HTMLInputElement>) => {
				if (typeof onClick === "function") onClick(event)

				if (typeof onToggle === "function") onToggle(event.target.checked)
			},
		[]
	)

	return (
		<FormControl fullWidth {...formControlProps} error={isError}>
			<FormControlLabel
				control={
					<Checkbox
						checked={checked}
						onChange={handleClick(onClick, onToggle)}
						tabIndex={-1}
						classes={{
							checked: classes.checked,
						}}
						{...inputProps}
					/>
				}
				classes={{ label: classes.label }}
				label={labelText}
				className={classes.checkboxPadding}
				{...labelProps}
			/>
			{helperText && (
				<FormHelperText className={classes.errorMessage}>{helperText}</FormHelperText>
			)}
		</FormControl>
	)
}

CustomCheckbox.propTypes = {
	labelText: PropTypes.node.isRequired,
	checked: PropTypes.bool.isRequired,
	inputProps: PropTypes.object,
	labelProps: PropTypes.object,
	isError: PropTypes.bool,
	helperText: PropTypes.string,
	formControlProps: PropTypes.object,
}

export default React.memo(CustomCheckbox)

import { unpythonify } from "@ncs/ts-utils"

import {
	ApiDeleteMutation,
	ApiGetQuery,
	ApiGetQueryOptions,
	ApiInfiniteGetQuery,
	ApiInfiniteGetQueryOptions,
	ApiPatchMutation,
	ApiPostMutation,
	useDeleteRequest,
	useGetRequest,
	useInfiniteGetRequest,
	usePatchRequest,
	usePostRequest,
} from "../../request-hooks"
import {
	GenericLineItem,
	GenericLineItemWithBasePrice,
	lineTypesRequireUserPrice,
	useEnumPropertyValidation,
	validateEnum,
} from "../../util"
import { APPLICATION } from "../auth"
import { LineItemType } from "../common"
import { CustomersUrlPaths } from "../customers"
import { DispatchUrlPaths } from "../dispatches"
import { PartOrdersUrlPaths } from "../parts-order"
import { WorkordersUrlPath } from "../workorders"
import {
	AccessorialPrice,
	AccessorialPricesQueryParams,
	AddCustomerToPricingMatrixPost,
	AddLaborPricingCustomersPost,
	CreateAccessorialPricePost,
	CreateContractPartPost,
	CreateFreightExceptionPost,
	CreateFreightMinPost,
	CreateFreightRatePost,
	CreateFuelSurchargePost,
	CreateLaborPricingPost,
	CreatePricingAdjustmentRequestPost,
	CustomerContractParts,
	CustomerContractPartsQueryParams,
	DeleteContractPart,
	FreightException,
	FreightExceptionsQueryParams,
	FreightMin,
	FreightMinsQueryParams,
	FreightRate,
	FreightRatesQueryParams,
	FuelSurcharge,
	FuelSurchargeQueryParams,
	LaborPricingCustomerHistory,
	LaborPricingDetails,
	LaborPricingDetailsQueryParams,
	LaborPricingHistoryQueryParams,
	LaborPricingListItem,
	LaborPricingListQueryParams,
	LineItemPriceOverride,
	LineItemPriceOverridesQueryParams,
	LinePricing,
	LinePricingPost,
	PermanentPriceAction,
	PermanentPriceStatus,
	PricingAdjustmentRequest,
	PricingAdjustmentRequestListItem,
	PricingAdjustmentRequestsQueryParams,
	PricingMatricesQueryParams,
	PricingMatrix,
	PricingMatrixItem,
	PricingMatrixItemPost,
	PricingMatrixItemsQueryParams,
	PricingMatrixPatch,
	PricingMatrixPost,
	PricingUrlPaths,
	RemoveCustomerFromPricingMatrixPatch,
	SubmitOverrideDecisionPost,
	UpdateAccessorialPricePatch,
	UpdateFreightExceptionPatch,
	UpdateFreightMinPatch,
	UpdateFreightRatePatch,
	UpdateLaborPricingCustomersPatch,
	UpdateLaborPricingPatch,
	UpdatePricingAdjustmentRequestPatch,
} from "./types"

export const useLinePricingPost = (): ApiPostMutation<LinePricingPost, LinePricing> => {
	const response = usePostRequest<LinePricingPost, LinePricing>(
		[PricingUrlPaths.Pricing, PricingUrlPaths.LinePricing],
		{
			keyToInvalidate: PricingUrlPaths.Pricing,
			onSuccess: ({ data }) => {
				data.lines.forEach((line) => {
					validateEnum(line.override_permission?.description, APPLICATION)
				})
			},
		}
	)

	return response
}

export const useCalculateLineItemPrices = (): ((pricingProps: {
	lineItems: GenericLineItem[]
	billToId: string
	customerId: string
}) => Promise<GenericLineItemWithBasePrice[]>) => {
	const linePricingPost = useLinePricingPost()

	const calculateLineItemPrices = async ({
		lineItems,
		billToId,
		customerId,
	}: {
		lineItems: GenericLineItem[]
		billToId: string
		customerId: string
	}): Promise<GenericLineItemWithBasePrice[]> => {
		return await Promise.all(
			lineItems.map(async (line): Promise<GenericLineItemWithBasePrice> => {
				if (lineTypesRequireUserPrice.includes(line.lineTypeId)) {
					return {
						...line,
						basePrice: line.basePrice ?? line.finalPrice ?? 0,
					}
				}

				if (line.lineTypeId === LineItemType.Parts && line.part === null) {
					return {
						...line,
						basePrice: line.basePrice ?? line.finalPrice ?? 0,
					}
				}

				const response = await linePricingPost({
					billToId,
					customerId,
					lines: [
						{
							lineTypeId: line.lineTypeId,
							partId: line.part?.id ?? null,
						},
					],
				})
				const pricingData = unpythonify(response.data.lines[0])

				const taxRateDecimal = pricingData.taxRate / 100 // Comes from server like 8.45%, instead of 0.0845

				if (pricingData.contractCovered) {
					return {
						...line,
						taxRate: taxRateDecimal,
						finalPrice: 0, // The customer price
						basePrice: pricingData.unitPrice, // The original price
						subtotal: 0,
					}
				} else {
					return {
						...line,
						taxRate: taxRateDecimal,
						finalPrice: pricingData.netPrice, // The customer price
						basePrice: pricingData.unitPrice, // The original price
						subtotal: line.quantity * pricingData.netPrice,
					}
				}
			})
		)
	}

	return calculateLineItemPrices
}

export const usePricingMatrix = (id: string | null): ApiGetQuery<PricingMatrix> => {
	return useGetRequest([PricingUrlPaths.Pricing, PricingUrlPaths.PriceMatrix, id ?? ""], {
		queryConfig: {
			enabled: !!id,
		},
	})
}

export const usePricingMatrices = (
	options?: ApiInfiniteGetQueryOptions<PricingMatrix, PricingMatricesQueryParams>
): ApiInfiniteGetQuery<PricingMatrix> => {
	return useInfiniteGetRequest([PricingUrlPaths.Pricing, PricingUrlPaths.PriceMatrix], options)
}

export const useCreatePricingMatrix = (): ApiPostMutation<PricingMatrixPost, PricingMatrix> => {
	return usePostRequest([PricingUrlPaths.Pricing, PricingUrlPaths.PriceMatrix], {
		keyToInvalidate: PricingUrlPaths.Pricing,
	})
}

export const useUpdatePricingMatrix = (): ApiPatchMutation<PricingMatrixPatch> => {
	return usePatchRequest([PricingUrlPaths.Pricing, PricingUrlPaths.PriceMatrix], {
		keyToInvalidate: PricingUrlPaths.Pricing,
	})
}

export const defaultPricingMatrixItemsQueryParams: PricingMatrixItemsQueryParams = {
	matrix: null,
	search: null,
}

export const usePricingMatrixItems = (
	options?: ApiInfiniteGetQueryOptions<PricingMatrixItem, PricingMatrixItemsQueryParams>
): ApiInfiniteGetQuery<PricingMatrixItem> => {
	return useInfiniteGetRequest(
		[PricingUrlPaths.Pricing, PricingUrlPaths.PriceMatrixItems],
		options
	)
}

export const useCreatePricingMatrixItem = (): ApiPostMutation<PricingMatrixItemPost> => {
	return usePostRequest([PricingUrlPaths.Pricing, PricingUrlPaths.PriceMatrixItems], {
		keyToInvalidate: PricingUrlPaths.Pricing,
	})
}

export const useDeletePricingMatrixItem = (): ApiDeleteMutation => {
	return useDeleteRequest([PricingUrlPaths.Pricing, PricingUrlPaths.PriceMatrixItems], {
		keyToInvalidate: PricingUrlPaths.Pricing,
	})
}

export const useAddCustomerToPricingMatrix =
	(): ApiPostMutation<AddCustomerToPricingMatrixPost> => {
		return usePostRequest([PricingUrlPaths.Pricing, PricingUrlPaths.PriceMatrix], {
			addRequestDataIdToUrl: true,
			urlPathsAfterId: PricingUrlPaths.AddCustomer,
			keyToInvalidate: [PricingUrlPaths.Pricing, CustomersUrlPaths.Customers],
		})
	}

export const useRemoveCustomerFromPricingMatrix =
	(): ApiPatchMutation<RemoveCustomerFromPricingMatrixPatch> => {
		return usePatchRequest([PricingUrlPaths.Pricing, PricingUrlPaths.PriceMatrix], {
			urlPathsAfterId: PricingUrlPaths.RemoveCustomer,
			keyToInvalidate: [PricingUrlPaths.Pricing, CustomersUrlPaths.Customers],
		})
	}

export const useLaborPricings = (
	options?: ApiInfiniteGetQueryOptions<LaborPricingListItem, LaborPricingListQueryParams>
): ApiInfiniteGetQuery<LaborPricingListItem> => {
	return useInfiniteGetRequest(
		[PricingUrlPaths.Pricing, PricingUrlPaths.LaborPricingList],
		options
	)
}

export const useLaborPricingDetails = (
	id: string | null | undefined,
	options?: ApiGetQueryOptions<LaborPricingDetails, LaborPricingDetailsQueryParams>
): ApiGetQuery<LaborPricingDetails> => {
	return useGetRequest([PricingUrlPaths.Pricing, PricingUrlPaths.LaborPricing, id ?? ""], {
		...options,
		queryConfig: {
			enabled: !!id,
			...options?.queryConfig,
		},
	})
}

// export const useCreateLaborPricing = (): ApiPostMutation<CreateLaborPricingPost, string> => {
// 	return usePostRequest([PricingUrlPaths.Pricing, PricingUrlPaths.LaborPricing], {
// 		keyToInvalidate: PricingUrlPaths.Pricing,
// 	})
// }

// export const useUpdateLaborPricing = (): ApiPatchMutation<UpdateLaborPricingPatch> => {
// 	return usePatchRequest([PricingUrlPaths.Pricing, PricingUrlPaths.LaborPricing], {
// 		keyToInvalidate: PricingUrlPaths.Pricing,
// 	})
// }

export const useCreateLaborPricing = (): ApiPostMutation<
	CreateLaborPricingPost,
	string // Returns the ID of the LaborPricing object (useful if we created a new one)
> => {
	return usePostRequest([PricingUrlPaths.Pricing, PricingUrlPaths.LaborPricing], {
		keyToInvalidate: PricingUrlPaths.Pricing,
	})
}

export const useUpdateLaborPricing = (): ApiPatchMutation<UpdateLaborPricingPatch> => {
	return usePatchRequest([PricingUrlPaths.Pricing, PricingUrlPaths.LaborPricing], {
		keyToInvalidate: PricingUrlPaths.Pricing,
	})
}

export const useDeleteLaborPricingRate = (): ApiDeleteMutation => {
	return useDeleteRequest([PricingUrlPaths.Pricing, PricingUrlPaths.LaborPricing], {
		keyToInvalidate: PricingUrlPaths.Pricing,
	})
}

export const useAddLaborPricingCustomers = (
	laborPricingId: string
): ApiPostMutation<AddLaborPricingCustomersPost> => {
	return usePostRequest(
		[
			PricingUrlPaths.Pricing,
			PricingUrlPaths.LaborPricing,
			laborPricingId,
			PricingUrlPaths.PricingCustomers,
		],
		{
			keyToInvalidate: PricingUrlPaths.Pricing,
		}
	)
}

export const useUpdateLaborPricingCustomers = (
	laborPricingId: string
): ApiPatchMutation<UpdateLaborPricingCustomersPatch> => {
	return usePatchRequest(
		[
			PricingUrlPaths.Pricing,
			PricingUrlPaths.LaborPricing,
			laborPricingId,
			PricingUrlPaths.PricingCustomers,
		],
		{
			keyToInvalidate: PricingUrlPaths.Pricing,
		}
	)
}

export const useDeleteLaborPricingCustomer = (laborPricingId: string): ApiDeleteMutation => {
	return useDeleteRequest(
		[
			PricingUrlPaths.Pricing,
			PricingUrlPaths.LaborPricing,
			laborPricingId,
			PricingUrlPaths.PricingCustomers,
		],
		{
			keyToInvalidate: PricingUrlPaths.Pricing,
		}
	)
}

export const useCustomerLaborPricingHistory = (
	customerId: string | null | undefined,
	options?: ApiGetQueryOptions<LaborPricingCustomerHistory, LaborPricingHistoryQueryParams>
): ApiGetQuery<LaborPricingCustomerHistory> => {
	return useGetRequest(
		[PricingUrlPaths.Pricing, PricingUrlPaths.LaborPricingHistory, customerId ?? ""],
		{
			...options,
			queryConfig: {
				enabled: !!customerId,
				...options?.queryConfig,
			},
		}
	)
}

/**
 * Returns list of accessorial price entries.
 */
export const useAccessorialPrices = (
	options?: ApiGetQueryOptions<AccessorialPrice[], AccessorialPricesQueryParams>
): ApiGetQuery<AccessorialPrice[]> => {
	return useGetRequest([PricingUrlPaths.Pricing, PricingUrlPaths.FreightAccessorial], options)
}

export const useCreateAccessorialPrice = (): ApiPostMutation<CreateAccessorialPricePost> => {
	return usePostRequest([PricingUrlPaths.Pricing, PricingUrlPaths.FreightAccessorial], {
		keyToInvalidate: PricingUrlPaths.Pricing,
	})
}

export const useUpdateAccessorialPrice = (): ApiPatchMutation<UpdateAccessorialPricePatch> => {
	return usePatchRequest([PricingUrlPaths.Pricing, PricingUrlPaths.FreightAccessorial], {
		keyToInvalidate: PricingUrlPaths.Pricing,
	})
}

export const useDeleteAccessorialPrice = (): ApiDeleteMutation => {
	return useDeleteRequest([PricingUrlPaths.Pricing, PricingUrlPaths.FreightAccessorial], {
		keyToInvalidate: PricingUrlPaths.Pricing,
	})
}

export const useFuelSurcharges = (
	options?: ApiGetQueryOptions<FuelSurcharge[], FuelSurchargeQueryParams>
): ApiGetQuery<FuelSurcharge[]> => {
	return useGetRequest([PricingUrlPaths.Pricing, PricingUrlPaths.FuelSurcharge], options)
}

export const useCreateFuelSurcharge = (): ApiPostMutation<CreateFuelSurchargePost> => {
	return usePostRequest([PricingUrlPaths.Pricing, PricingUrlPaths.FuelSurcharge], {
		keyToInvalidate: PricingUrlPaths.Pricing,
	})
}

export const useFreightMins = (
	options?: ApiGetQueryOptions<FreightMin[], FreightMinsQueryParams>
): ApiGetQuery<FreightMin[]> => {
	return useGetRequest([PricingUrlPaths.Pricing, PricingUrlPaths.FreightMin], options)
}

export const useCreateFreightMin = (): ApiPostMutation<CreateFreightMinPost> => {
	return usePostRequest([PricingUrlPaths.Pricing, PricingUrlPaths.FreightMin], {
		keyToInvalidate: PricingUrlPaths.Pricing,
	})
}

export const useUpdateFreightMin = (): ApiPatchMutation<UpdateFreightMinPatch> => {
	return usePatchRequest([PricingUrlPaths.Pricing, PricingUrlPaths.FreightMin], {
		keyToInvalidate: PricingUrlPaths.Pricing,
	})
}

export const useFreightExceptions = (
	options?: ApiGetQueryOptions<FreightException[], FreightExceptionsQueryParams>
): ApiGetQuery<FreightException[]> => {
	return useGetRequest([PricingUrlPaths.Pricing, PricingUrlPaths.FreightException], options)
}

export const useCreateFreightException = (): ApiPostMutation<CreateFreightExceptionPost> => {
	return usePostRequest([PricingUrlPaths.Pricing, PricingUrlPaths.FreightException], {
		keyToInvalidate: PricingUrlPaths.Pricing,
	})
}

export const useUpdateFreightException = (): ApiPatchMutation<UpdateFreightExceptionPatch> => {
	return usePatchRequest([PricingUrlPaths.Pricing, PricingUrlPaths.FreightException], {
		keyToInvalidate: PricingUrlPaths.Pricing,
	})
}

export const useDeleteFreightException = (): ApiDeleteMutation => {
	return useDeleteRequest([PricingUrlPaths.Pricing, PricingUrlPaths.FreightException], {
		keyToInvalidate: PricingUrlPaths.Pricing,
	})
}

export const useFreightRates = (
	options?: ApiGetQueryOptions<FreightRate[], FreightRatesQueryParams>
): ApiGetQuery<FreightRate[]> => {
	return useGetRequest([PricingUrlPaths.Pricing, PricingUrlPaths.FreightRates], options)
}

export const useCreateFreightRate = (): ApiPostMutation<CreateFreightRatePost> => {
	return usePostRequest([PricingUrlPaths.Pricing, PricingUrlPaths.FreightRates], {
		keyToInvalidate: PricingUrlPaths.Pricing,
	})
}

export const useUpdateFreightRate = (): ApiPatchMutation<UpdateFreightRatePatch> => {
	return usePatchRequest([PricingUrlPaths.Pricing, PricingUrlPaths.FreightRates], {
		keyToInvalidate: PricingUrlPaths.Pricing,
	})
}

export const useLineItemPriceOverrides = (
	options?: ApiGetQueryOptions<LineItemPriceOverride[], LineItemPriceOverridesQueryParams>
): ApiGetQuery<LineItemPriceOverride[]> => {
	return useGetRequest([PricingUrlPaths.Pricing, PricingUrlPaths.OverrideException], options)
}

export const useSubmitOverrideDecision = (): ApiPostMutation<SubmitOverrideDecisionPost> => {
	return usePostRequest([PricingUrlPaths.Pricing, PricingUrlPaths.OverrideException], {
		keyToInvalidate: [
			[PricingUrlPaths.Pricing],
			[PartOrdersUrlPaths.Parts, PartOrdersUrlPaths.Order],
			[WorkordersUrlPath.Workorders],
			[DispatchUrlPaths.Dispatches],
		],
		addRequestDataIdToUrl: true,
	})
}

export const usePricingAdjustmentRequests = (
	options?: ApiGetQueryOptions<
		PricingAdjustmentRequestListItem[],
		PricingAdjustmentRequestsQueryParams
	>
): ApiGetQuery<PricingAdjustmentRequestListItem[]> => {
	return useGetRequest(
		[PricingUrlPaths.Pricing, PricingUrlPaths.PermanentPricingUpdateList],
		options
	)
}

export const usePricingAdjustmentRequest = (
	requestId: string | null | undefined
): ApiGetQuery<PricingAdjustmentRequest> => {
	const [data, ...rest] = useGetRequest<PricingAdjustmentRequest>(
		[PricingUrlPaths.Pricing, PricingUrlPaths.PermanentPricingUpdate, requestId ?? ""],
		{
			queryConfig: {
				enabled: !!requestId,
			},
		}
	)

	useEnumPropertyValidation(data?.freight.accessorials, "lineTypeId", LineItemType)
	useEnumPropertyValidation(data, "status", PermanentPriceStatus)
	useEnumPropertyValidation(data?.actions, "action", PermanentPriceAction)

	return [data, ...rest]
}

export const useCreatePricingAdjustmentForm = (): ApiPostMutation<
	CreatePricingAdjustmentRequestPost,
	string
> => {
	return usePostRequest([PricingUrlPaths.Pricing, PricingUrlPaths.PermanentPricingUpdate], {
		keyToInvalidate: PricingUrlPaths.Pricing,
	})
}

export const useUpdatePricingAdjustmentForm =
	(): ApiPatchMutation<UpdatePricingAdjustmentRequestPatch> => {
		return usePatchRequest([PricingUrlPaths.Pricing, PricingUrlPaths.PermanentPricingUpdate], {
			keyToInvalidate: PricingUrlPaths.Pricing,
		})
	}

export const useCustomerContractParts = (
	customerId: string | null | undefined
): ApiGetQuery<CustomerContractParts[]> => {
	return useGetRequest<CustomerContractParts[], CustomerContractPartsQueryParams>(
		[PricingUrlPaths.Pricing, PricingUrlPaths.ContractPartInclusion],
		{
			params: {
				customer: customerId,
				contractType: "CPC", // Make this configurable if need be
			},
			queryConfig: {
				enabled: !!customerId,
			},
		}
	)
}

export const useCreateContractPart = (): ApiPostMutation<CreateContractPartPost> => {
	return usePostRequest([PricingUrlPaths.Pricing, PricingUrlPaths.ContractPartInclusion], {
		keyToInvalidate: PricingUrlPaths.Pricing,
	})
}

export const useDeleteContractPart = (): ApiDeleteMutation<DeleteContractPart> => {
	return useDeleteRequest([PricingUrlPaths.Pricing, PricingUrlPaths.ContractPartInclusion], {
		keyToInvalidate: PricingUrlPaths.Pricing,
	})
}

import { FC, useState } from "react"

import { Column } from "react-table"

import {
	makeApiErrorMessage,
	QuickOrder,
	QuickOrderDetails,
	useQuickOrderDetails,
	useRemoveQuickOrderSite,
} from "@ncs/ncs-api"
import {
	Button,
	ConfirmationModal,
	ConfirmationModalConfig,
	Table,
	useToast,
} from "@ncs/web-legos"

import { AddSiteToQuickOrderModal } from "./AddSiteToQuickOrderModal"

export interface QuickOrdersSitesTabProps {
	order: QuickOrder
}

const columns: Column<QuickOrderDetails["sites"][number]>[] = [
	{
		Header: "Customer #",
		accessor: "customerNumber",
	},
	{
		Header: "Customer Name",
		accessor: "customerName",
	},
]

export const QuickOrdersSitesTab: FC<QuickOrdersSitesTabProps> = ({ order }) => {
	const { makeSuccessToast, makeErrorToast } = useToast()
	const [showAddModal, setShowAddModal] = useState(false)
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)

	const [details, detailsLoading] = useQuickOrderDetails(order.id)
	const removeSite = useRemoveQuickOrderSite()

	const onRemove = async (id: number) => {
		try {
			await removeSite({
				body: {
					quickOrderId: order.id,
					customerId: id,
				},
			})
			makeSuccessToast("Site removed from Quick Order")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		}
	}

	return (
		<>
			<Button
				variant="secondary-cta"
				icon="plus-circle"
				onClick={() => setShowAddModal(true)}
				containerProps={{ mb: 3 }}
			>
				Add site to Quick Order
			</Button>
			<Table
				data={details?.sites ?? []}
				isLoading={detailsLoading}
				columns={columns}
				noDataText="No sites assigned to this Quick Order yet"
				rowMenu={[
					{
						label: "Remove",
						iconName: "trash",
						onClick: ({ original }) =>
							setConfirmationConfig({
								title: "Remove Site",
								message: `Remove ${original.customerNumber} from ${order.description}?`,
								onConfirm: () => onRemove(original.customerId),
							}),
					},
				]}
			/>

			<AddSiteToQuickOrderModal
				isOpen={showAddModal}
				onClose={() => setShowAddModal(false)}
				order={order}
			/>
			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</>
	)
}

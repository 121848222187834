import { FC } from "react"

import { PricingAdjustmentFormStatus, usePricingAdjustmentFormStatuses } from "@ncs/ncs-api"
import { ExtendableSelectProps, Select } from "@ncs/web-legos"

export type PricingAdjustmentFormStatusProps = ExtendableSelectProps<PricingAdjustmentFormStatus>

export const PricingAdjustmentFormStatusSelector: FC<PricingAdjustmentFormStatusProps> = ({
	...rest
}) => {
	const [statuses, isLoading] = usePricingAdjustmentFormStatuses()

	return (
		<Select
			label="Form status"
			{...rest}
			options={(statuses ?? []).sort((a, b) => (a.name > b.name ? 1 : -1))}
			isLoading={isLoading}
			valueAccessor="id"
			textAccessor="name"
		/>
	)
}

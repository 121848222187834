import { FC, useState } from "react"

import { CustomerCredit, makeApiErrorMessage, useExpireCustomerCredit } from "@ncs/ncs-api"
import { formatNumber } from "@ncs/ts-utils"
import {
	ConfirmationModal,
	ConfirmationModalConfig,
	ExtendableModalProps,
	Modal,
	Paragraph,
	useToast,
} from "@ncs/web-legos"

export interface RelatedCustomerModalProps extends ExtendableModalProps {
	customerId: string
	credit: CustomerCredit
}

export const RelatedCustomerModal: FC<RelatedCustomerModalProps> = ({
	customerId,
	credit,
	...rest
}) => {
	const { makeSuccessToast } = useToast()
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)
	const [errorText, setErrorText] = useState<string | null>(null)

	const expireCredit = useExpireCustomerCredit()

	const handleExpireAll = async () => {
		try {
			await expireCredit({
				updates: {
					customerId,
					businessUnitId: credit.businessUnit.id,
					otherUnitId: credit.otherUnit.id,
					isPerSite: true,
				},
			})
			rest.onClose()
			makeSuccessToast(`${creditName} credit expired at related site(s)`)
		} catch (e) {
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const creditName = credit.businessUnit.name || credit.otherUnit.name

	return (
		<>
			<Modal
				errorText={errorText}
				title={`${creditName} Credits At Related Sites`}
				{...rest}
				rightButtons={{
					buttonText: `Expire credits at related sites`,
					onClick: () =>
						setConfirmationConfig({
							title: "Bulk Expire",
							message: `Confirm: Are you sure you want to expire the credits for ${creditName} at ${formatNumber(
								credit.relatedSiteCount
							)} sites? This action cannot be undone.`,
							onConfirm: handleExpireAll,
						}),
				}}
			>
				<Paragraph mb={1}>
					There are <strong>{formatNumber(credit.relatedSiteCount)}</strong> other
					customer(s) that share the same bill-to as this customer and also have{" "}
					{creditName} credits.
				</Paragraph>
				<Paragraph>
					If for some reason you want to expire all of credits in one action, you can do
					that here.
				</Paragraph>
			</Modal>

			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</>
	)
}

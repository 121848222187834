import { FC, useMemo, useState } from "react"

import dayjs from "dayjs"
import { useHistory, useParams } from "react-router-dom"

import {
	LaborPricingDetails,
	LaborPricingDetailsQueryParams,
	LaborRateTypeId,
	useLaborPricingDetails,
	useUpdateLaborPricing,
} from "@ncs/ncs-api"
import { formatCurrency, formatDate, isEnumMember } from "@ncs/ts-utils"
import {
	Box,
	Button,
	Callout,
	Card,
	EditStringModal,
	EditStringModalProps,
	EmptyValueDash,
	encodeUrlState,
	Heading,
	HeadingDivider,
	IconButton,
	Label,
	LoadingSpinner,
	Paragraph,
	useScrollToTopOnMount,
	useUrlState,
} from "@ncs/web-legos"

import { laborRateTypeDescriptions } from "~/components"
import { PageTitle } from "~/layouts/PageTitle"
import { LaborPricingUrlState } from "~/views/Finance/LaborPricing/LaborPricing"

import { LaborPricingCustomers, LaborPricingPriceSchedule } from "./components"

export type LaborPricingDetailsUrlState = LaborPricingDetailsQueryParams

export const LaborPricingDetail: FC = () => {
	useScrollToTopOnMount()
	const history = useHistory<{ laborPricingQueryParams?: LaborPricingUrlState }>()
	const { id } = useParams<{ id: string }>()
	const [queryParams, { setUrlState: setQueryParams }] =
		useUrlState<LaborPricingDetailsUrlState>(defaultParams)

	const [editStringModalConfig, setEditStringModalConfig] =
		useState<EditStringModalProps | null>(null)

	const [pricing, pricingLoading] = useLaborPricingDetails(id, {
		params: queryParams,
	})

	const updateDetails = useUpdateLaborPricing()

	const handleBackToList = () => {
		history.push({
			pathname: "/finance/labor-pricing",
			search: encodeUrlState<LaborPricingUrlState>(
				history.location.state?.laborPricingQueryParams
			),
		})
	}

	const currentRate = useMemo(() => {
		if (!pricing?.priceHistory.length) return null

		// Filter down to just the prices that are in effect now, and sort them so that the newest
		// is first, just in case there are multiple in effect somehow.
		const today = dayjs()
		const inEffect = pricing.priceHistory
			.filter((price) => {
				if (today.isSame(price.startsOn, "day") || today.isAfter(price.startsOn, "day")) {
					if (!price.endsAfter) {
						return true
					}

					return (
						today.isSame(price.endsAfter, "day") ||
						today.isBefore(price.endsAfter, "day")
					)
				}

				return false
			})
			.sort((a, b) => (a.startsOn > b.startsOn ? -1 : 1))

		if (inEffect.length > 1) {
			// Shouldn't happen.
			console.error("Found more than 1 price schedule in effect")
		}

		return inEffect.length ? inEffect[0] : null
	}, [pricing?.priceHistory])

	// The nearest price schedule that's after today.
	const nextRate = useMemo(() => {
		if (!pricing?.priceHistory) return null

		const matches = pricing.priceHistory
			.filter((p) => dayjs(p.startsOn).isAfter(dayjs(), "day"))
			.sort((a, b) => (a.startsOn < b.startsOn ? -1 : 1))

		return matches[0] ?? null
	}, [pricing?.priceHistory])

	const getDescription = (p: LaborPricingDetails) => {
		return p.territory ? `(${p.territory.code}) ${p.territory.description}` : p.description
	}

	const getZone = (p: LaborPricingDetails) => {
		if (p.zone) {
			if (p.zone === 1 && p.territory) {
				return `${p.zone} (Default)`
			}

			return p.zone
		}

		return <EmptyValueDash />
	}

	const getType = (p: LaborPricingDetails) => {
		if (isEnumMember(p.type, LaborRateTypeId)) {
			return laborRateTypeDescriptions[p.type]
		}

		return null
	}

	if (pricingLoading) {
		return (
			<Card>
				<LoadingSpinner />
			</Card>
		)
	}

	if (!pricing) {
		return null
	}

	return (
		<>
			<PageTitle title={getDescription(pricing)} />

			<Button icon="long-arrow-left" containerProps={{ mb: 1 }} onClick={handleBackToList}>
				All labor pricing groups
			</Button>

			<Card heading="Labor Pricing Group Details" headingIcon="tools">
				<Box d="flex" flexDirection="column" gap={2} mb={4}>
					<div>
						<Label>Description</Label>
						<Box d="flex" alignItems="center" gap={0.5}>
							<Heading variant="h2">{getDescription(pricing)}</Heading>
							{!pricing.territory && (
								<IconButton
									icon="pencil"
									color="primary"
									onClick={() =>
										setEditStringModalConfig({
											initialValue: pricing.description,
											label: "Description",
											onSave: async (newValue) => {
												if (newValue) {
													await updateDetails({
														updates: {
															laborPricingId: id,
															description: newValue,
														},
													})
												}
											},
											onClose: () => setEditStringModalConfig(null),
										})
									}
								/>
							)}
						</Box>
					</div>
					<div>
						<Label>Zone</Label>
						<Heading variant="h3">{getZone(pricing)}</Heading>
					</div>
					<div>
						<Label>Rate type</Label>
						<Heading variant="h3">{getType(pricing)}</Heading>
					</div>
					<div>
						<Label>Current rate</Label>
						<Heading variant="h3">
							{currentRate ?
								formatCurrency(currentRate.price)
							:	<Callout variant="warning" noBorder mt={0.5}>
									<Paragraph>No price currently in effect</Paragraph>
									{!!nextRate && (
										<Paragraph small mt={0.5}>
											Next:{" "}
											<strong>
												{formatCurrency(nextRate.price)} starts on{" "}
												{formatDate(nextRate.startsOn, {
													formatInUtc: true,
												})}
											</strong>
										</Paragraph>
									)}
								</Callout>
							}
						</Heading>
					</div>
				</Box>

				<HeadingDivider icon="dollar" headingVariant="h4" bold mb={0.5}>
					Pricing Schedule History
				</HeadingDivider>
				<LaborPricingPriceSchedule pricing={pricing} currentRate={currentRate} />

				<HeadingDivider icon="user" headingVariant="h4" bold mb={0}>
					Customer Assignments
				</HeadingDivider>
				<LaborPricingCustomers
					pricing={pricing}
					queryParams={queryParams}
					setQueryParams={setQueryParams}
				/>
			</Card>

			{!!editStringModalConfig && <EditStringModal {...editStringModalConfig} />}
		</>
	)
}

const defaultParams: LaborPricingDetailsQueryParams = {
	includeInactiveCustomers: false,
}

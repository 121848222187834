import { Dispatch, ReactElement, SetStateAction } from "react"

import { PricingAdjustmentFormStatusSelector } from "../selectors"

export enum PricingAdjustmentFormStatusParamKey {
	Status = "status",
}

export interface PricingAdjustmentFormStatusParamState {
	[PricingAdjustmentFormStatusParamKey.Status]: string | null
}

export interface PricingAdjustmentFormStatusQueryFilterProps<
	QueryParamState extends PricingAdjustmentFormStatusParamState,
> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

export const PricingAdjustmentFormStatusQueryFilter = <
	QueryParamState extends PricingAdjustmentFormStatusParamState,
>({
	queryParamState,
	setQueryParamState,
}: PricingAdjustmentFormStatusQueryFilterProps<QueryParamState>): ReactElement => {
	return (
		<PricingAdjustmentFormStatusSelector
			value={queryParamState[PricingAdjustmentFormStatusParamKey.Status]}
			disableNoSelectionOption={false}
			fillContainer
			onChange={(newValue, newOption) =>
				setQueryParamState((prev) => {
					const newPricingAdjustmentFormStatusParamState: PricingAdjustmentFormStatusParamState =
						{
							[PricingAdjustmentFormStatusParamKey.Status]: newOption?.id ?? null,
						}

					return {
						...prev,
						...newPricingAdjustmentFormStatusParamState,
					}
				})
			}
		/>
	)
}

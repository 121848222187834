import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import get from "lodash/get"
import memoizeOne from "memoize-one"
import { formatDateTime } from "@ncs/bricks/util/formatters"

// core components
import FormHelperText from "@material-ui/core/FormHelperText"

// custom components
import {
	Alert,
	ButtonWithIcon,
	ConditionalContent,
	Datetime,
	FileInput,
	GridContainer,
	GridItem,
	Input,
	InputAsDisplay,
	LoadingWrapper,
	ManyToOneEditor,
	QuickCard,
	Select,
} from "~/components"

// icons
import Videocam from "@material-ui/icons/Videocam"
import Image from "@material-ui/icons/Image"
import AttachFile from "@material-ui/icons/AttachFile"
import CloudDownload from "@material-ui/icons/CloudDownload"
import ChevronLeft from "@material-ui/icons/ChevronLeft"
import Edit from "@material-ui/icons/Edit"
import Remove from "@material-ui/icons/Remove"
import Check from "@material-ui/icons/Check"
import Add from "@material-ui/icons/Add"

// styles
import withStyles from "@material-ui/core/styles/withStyles"
import { dangerColor } from "@ncs/bricks/assets/jss/material-dashboard-pro-react"

const styles = {
	right: {
		textAlign: "right",
	},
	videoContainer: {
		position: "absolute",
		height: "100%",
		width: "100%",
		overflow: "hidden",
		"& video": {
			minWidth: "100%",
			minHeight: "100%",
		},
	},
	error: {
		color: dangerColor[0] + " !important",
	},
}

class DocumentDetail extends React.Component {
	generateCategoryOptions = memoizeOne((cats, parentCategory) => {
		if (!cats || !cats.length) {
			return []
		}

		let sortedCats = [...cats].sort(
			(a, b) => (b.name.toLowerCase() === "ryko") - (a.name.toLowerCase() === "ryko")
		)

		return [
			{
				value: parentCategory ? parentCategory.id.toString() : "-1",
				text: "Select a Category...",
			},
			...sortedCats.map((x) => ({
				value: x.id.toString(),
				text: x.name,
			})),
		]
	})

	render() {
		const {
			classes,
			isLoading,
			isCreating,
			isEditing,
			isAddingCategory,
			item,
			onDownload,
			onBackToList,
			isInfoRefAdmin,
			onStartEditing,
			onCancelEditing,
			showValidationErrors,
			formIsValid,
			fileInputRef,
			categories,
			categoriesIsValid,
			categoriesErrorMessage,
			description,
			onDescriptionChange,
			descriptionIsValid,
			descriptionErrorMessage,
			documentNumber,
			onDocumentNumberChange,
			documentNumberIsValid,
			documentNumberErrorMessage,
			revision,
			onRevisionChange,
			revisionIsValid,
			revisionErrorMessage,
			effectiveDate,
			onEffectiveDateChange,
			effectiveDateIsValid,
			effectiveDateErrorMessage,
			expirationDate,
			onExpirationDateChange,
			expirationDateIsValid,
			expirationDateErrorMessage,
			onSave,
			isSaving,
			displayActionFailure,
			onFailureModalClose,
			infoRefCategories,
			getCategoryLabel,
			onAddCategory,
			onAssignCategory,
			onUnassignCategory,
			onCategoryChange,
			selectedCategory,
			selectedTopLevelCategory,
			selectedSecondLevelCategory,
			selectedThirdLevelCategory,
			selectedFourthLevelCategory,
			isUpdatingFile,
			onStartUpdatingFile,
			onCancelUpdatingFile,
			onFileSelected,
			isFileSelected,
		} = this.props

		const cardIcon =
			(item.document_type_name || "").toLowerCase() === "video" ? <Videocam />
			: (item.document_type_name || "").toLowerCase() === "image" ? <Image />
			: <AttachFile />

		const selectedCategoryIsAlreadyAdded =
			!!selectedCategory && (categories || []).map((x) => x.id).includes(selectedCategory.id)

		return (
			<LoadingWrapper isLoading={isLoading}>
				{() => (
					<React.Fragment>
						<QuickCard
							title={isCreating ? "Create Document" : item.filename}
							icon={cardIcon}
						>
							<GridContainer>
								<GridItem xs={12} md={6}>
									<Input
										labelText="Document Title"
										value={description}
										onChange={onDescriptionChange}
										inputProps={{ disabled: !isEditing }}
										formControlProps={{
											error: showValidationErrors && !descriptionIsValid,
										}}
										helperText={
											showValidationErrors ? descriptionErrorMessage : ""
										}
									/>
								</GridItem>
								<GridItem xs={12} sm={6} md={3}>
									<Input
										labelText="Document Number"
										value={documentNumber}
										onChange={onDocumentNumberChange}
										inputProps={{ disabled: !isEditing }}
										formControlProps={{
											error: showValidationErrors && !documentNumberIsValid,
										}}
										helperText={
											showValidationErrors ? documentNumberErrorMessage : ""
										}
									/>
								</GridItem>
								<GridItem xs={12} sm={6} md={3}>
									<Input
										labelText="Revision"
										value={revision}
										onChange={onRevisionChange}
										inputProps={{ disabled: !isEditing }}
										formControlProps={{
											error: showValidationErrors && !revisionIsValid,
										}}
										helperText={
											showValidationErrors ? revisionErrorMessage : ""
										}
									/>
								</GridItem>
								<GridItem xs={12} sm={6} md={3}>
									<Datetime
										labelText="Effective Date"
										value={moment(effectiveDate)}
										onChange={onEffectiveDateChange}
										isError={showValidationErrors && !effectiveDateIsValid}
										helperText={
											showValidationErrors ? effectiveDateErrorMessage : ""
										}
										dateFormat="MMM DD YYYY"
										timeFormat={false}
									/>
								</GridItem>
								<GridItem xs={12} sm={6} md={3}>
									<Datetime
										labelText="Expiration Date"
										value={moment(expirationDate)}
										onChange={onExpirationDateChange}
										isError={showValidationErrors && !expirationDateIsValid}
										helperText={
											showValidationErrors ? expirationDateErrorMessage : ""
										}
										dateFormat="MMM DD YYYY"
										timeFormat={false}
									/>
								</GridItem>
								<GridItem xs={12} sm={6} md={3} hide={isCreating}>
									<InputAsDisplay
										labelText="Created Date"
										value={formatDateTime(item.created_date)}
									/>
								</GridItem>
								<GridItem xs={12} sm={6} md={3} hide={isCreating}>
									<InputAsDisplay
										labelText="Created By"
										value={get(item, "uploaded_by.name", "")}
									/>
								</GridItem>

								<GridItem xs={12}>
									<ManyToOneEditor
										label="Categories"
										items={categories}
										onDelete={onUnassignCategory}
										textAccessor={getCategoryLabel}
										showValidationErrors={showValidationErrors}
										isValid={categoriesIsValid}
										errorMessage={categoriesErrorMessage}
										isEditing={isEditing}
									/>
								</GridItem>

								<GridItem xs={12} hide={!isEditing || isAddingCategory}>
									<ButtonWithIcon
										icon={<Add />}
										content="Add Category"
										size="sm"
										round
										color="success"
										onClick={onAddCategory}
									/>
								</GridItem>
								<GridItem xs={12} show={isAddingCategory}>
									{() => (
										<GridContainer>
											<GridItem xs={12} sm={6} md={3}>
												<Select
													id="top_category"
													labelText="Top-level Category"
													value={get(
														selectedTopLevelCategory,
														"id",
														"-1"
													).toString()}
													onChange={onCategoryChange}
													options={this.generateCategoryOptions(
														infoRefCategories
													)}
												/>
											</GridItem>
											<GridItem
												xs={12}
												sm={6}
												md={3}
												show={
													get(
														selectedTopLevelCategory,
														"sub_categories.length",
														0
													) > 0
												}
											>
												<Select
													id="second_category"
													labelText="Second-level Category"
													value={get(
														selectedSecondLevelCategory,
														"id",
														get(selectedTopLevelCategory, "id", "-1")
													).toString()}
													onChange={onCategoryChange}
													options={this.generateCategoryOptions(
														get(
															selectedTopLevelCategory,
															"sub_categories"
														),
														selectedTopLevelCategory
													)}
												/>
											</GridItem>
											<GridItem
												xs={12}
												sm={6}
												md={3}
												show={
													get(
														selectedSecondLevelCategory,
														"sub_categories.length",
														0
													) > 0
												}
											>
												<Select
													id="third_category"
													labelText="Third-level Category"
													value={get(
														selectedThirdLevelCategory,
														"id",
														get(
															selectedSecondLevelCategory,
															"id",
															"-1"
														)
													).toString()}
													onChange={onCategoryChange}
													options={this.generateCategoryOptions(
														get(
															selectedSecondLevelCategory,
															"sub_categories"
														),
														selectedSecondLevelCategory
													)}
												/>
											</GridItem>
											<GridItem
												xs={12}
												sm={6}
												md={3}
												show={
													get(
														selectedThirdLevelCategory,
														"sub_categories.length",
														0
													) > 0
												}
											>
												<Select
													id="fourth_category"
													labelText="Fourth-level Category"
													value={get(
														selectedFourthLevelCategory,
														"id",
														get(selectedThirdLevelCategory, "id", "-1")
													).toString()}
													onChange={onCategoryChange}
													options={this.generateCategoryOptions(
														get(
															selectedThirdLevelCategory,
															"sub_categories"
														),
														selectedThirdLevelCategory
													)}
												/>
											</GridItem>

											<GridItem
												xs={12}
												sm={6}
												md={9}
												show={
													get(
														selectedThirdLevelCategory,
														"sub_categories.length",
														0
													) > 0
												}
											>
												{/* button spacer */}
											</GridItem>

											<GridItem xs={12} sm={6} md={3}>
												<ButtonWithIcon
													content={
														selectedCategoryIsAlreadyAdded ?
															"Assigned!"
														:	"Assign Category"
													}
													onClick={onAssignCategory}
													icon={
														selectedCategoryIsAlreadyAdded ?
															<Check />
														:	<Add />
													}
													disabled={
														!selectedTopLevelCategory ||
														selectedCategoryIsAlreadyAdded
													}
													size="sm"
													color="success"
													round
												/>
											</GridItem>
										</GridContainer>
									)}
								</GridItem>

								<GridItem xs={12} md={6} show={isCreating || isEditing}>
									<FileInput
										onFileSelected={onFileSelected}
										onChooseFile={onStartUpdatingFile}
										onFileSelectionCanceled={onCancelUpdatingFile}
										buttonProps={{
											content: isCreating ? "Choose File" : "Update File",
											icon: isCreating ? <AttachFile /> : <Edit />,
											color: isCreating ? "success" : undefined,
										}}
										ref={fileInputRef}
									/>

									<ConditionalContent
										show={showValidationErrors && !isFileSelected}
									>
										<FormHelperText
											id="file-input-text"
											className={classes.error}
										>
											{"Required."}
										</FormHelperText>
									</ConditionalContent>
								</GridItem>

								<GridItem xs={12} hide={isUpdatingFile || isCreating}>
									<ConditionalContent show={!item.id}>
										{"Item does not exist."}
									</ConditionalContent>
									<ConditionalContent
										show={
											"video" ===
											(item.document_type_name || "").toLowerCase()
										}
									>
										{/* eslint-disable-next-line jsx-a11y/media-has-caption */}
										<video
											width="640"
											height="360"
											autoPlay
											controls
											webkitallowfullscreen="true"
											mozallowfullscreen="true"
											allowFullScreen={true}
											poster={item.preview_url || undefined}
										>
											<source src={item.presign_url} type="video/mp4" />
										</video>
									</ConditionalContent>
									<ConditionalContent
										show={
											"image" ===
											(item.document_type_name || "").toLowerCase()
										}
									>
										<img
											src={item.presign_url}
											title={`${item.filename}.${item.extension}`}
											alt={`${item.filename}.${item.extension}`}
										/>
									</ConditionalContent>
									<ConditionalContent
										show={
											"document" ===
											(item.document_type_name || "").toLowerCase()
										}
									>
										<ButtonWithIcon
											content="Download"
											icon={<CloudDownload />}
											onClick={onDownload}
											round
											className="edit"
											color="info"
										/>
									</ConditionalContent>
								</GridItem>

								<GridItem xs={12} />

								<GridItem xs={6}>
									<ButtonWithIcon
										content="Back to List"
										onClick={onBackToList}
										size="sm"
										color="white"
										round
										icon={<ChevronLeft />}
									/>
								</GridItem>
								<GridItem
									xs={6}
									hide={!isInfoRefAdmin || isEditing}
									className={classes.right}
								>
									<ButtonWithIcon
										icon={<Edit />}
										content="Edit Document"
										size="sm"
										round
										color="success"
										onClick={onStartEditing}
									/>
								</GridItem>
								<GridItem xs={6} show={isEditing} className={classes.right}>
									<ConditionalContent hide={isCreating}>
										<ButtonWithIcon
											icon={<Remove />}
											content="Cancel"
											size="sm"
											round
											color="danger"
											onClick={onCancelEditing}
										/>
									</ConditionalContent>
									<ButtonWithIcon
										icon={isCreating ? <Add /> : <Check />}
										content={isCreating ? "Create" : "Save"}
										size="sm"
										round
										color="success"
										onClick={onSave}
										disabled={
											isSaving || (showValidationErrors && !formIsValid)
										}
										loading={isSaving}
									/>
								</GridItem>
							</GridContainer>
						</QuickCard>

						<Alert
							danger
							show={displayActionFailure}
							style={{ display: "block" }}
							title="Failed to save document"
							onConfirm={onFailureModalClose}
							confirmBtnText="Close"
							confirmBtnColor="info"
						>
							{"Please try again. If the issue persists, contact support."}
						</Alert>
					</React.Fragment>
				)}
			</LoadingWrapper>
		)
	}
}

DocumentDetail.propTypes = {
	item: PropTypes.object.isRequired,
	infoRefCategories: PropTypes.array.isRequired,
	isInfoRefAdmin: PropTypes.bool.isRequired,

	isLoading: PropTypes.bool.isRequired,
	isCreating: PropTypes.bool.isRequired,
	isEditing: PropTypes.bool.isRequired,
	isSaving: PropTypes.bool.isRequired,

	onDownload: PropTypes.func.isRequired,
	onBackToList: PropTypes.func.isRequired,

	onStartEditing: PropTypes.func.isRequired,
	onCancelEditing: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,

	getCategoryLabel: PropTypes.func.isRequired,
	onAssignCategory: PropTypes.func.isRequired,
	onUnassignCategory: PropTypes.func.isRequired,
	onAddCategory: PropTypes.func.isRequired,
	isAddingCategory: PropTypes.bool.isRequired,

	onCategoryChange: PropTypes.func.isRequired,

	selectedCategory: PropTypes.object,
	selectedTopLevelCategory: PropTypes.object,
	selectedSecondLevelCategory: PropTypes.object,
	selectedThirdLevelCategory: PropTypes.object,
	selectedFourthLevelCategory: PropTypes.object,

	fileInputRef: PropTypes.object,
	isUpdatingFile: PropTypes.bool.isRequired,
	onStartUpdatingFile: PropTypes.func.isRequired,
	onCancelUpdatingFile: PropTypes.func.isRequired,
	onFileSelected: PropTypes.func.isRequired,

	displayActionFailure: PropTypes.bool.isRequired,
	onFailureModalClose: PropTypes.func.isRequired,
}

export default withStyles(styles)(DocumentDetail)

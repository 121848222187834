import { FC, useEffect, useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { FormProvider, useForm } from "react-hook-form"
import { useQueryClient } from "react-query"
import { useHistory, useLocation, useParams } from "react-router-dom"

import {
	makeApiErrorMessage,
	PointOfSaleUrlPaths,
	PosPartQueryParams,
	useCreatePosPart,
	useOnlinePartRelations,
	usePosPart,
	useUpdatePosPart,
	useUpdatePosPartImage,
} from "@ncs/ncs-api"
import { formatDateTime } from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	Box,
	Button,
	Card,
	Divider,
	encodeUrlState,
	GridContainer,
	GridItem,
	HeadingDivider,
	LoadingSpinner,
	NumericInputFormField,
	useChangeCallback,
	useScrollToTopOnMount,
	useToast,
} from "@ncs/web-legos"

import { PageTitle } from "~/layouts/PageTitle"

import {
	getFormValuesFromPosPart,
	PosPartForm,
	posPartFormDefaultValues,
	PosPartFormSchema,
	posPartFormToPosPartPatch,
	posPartFormToPosPartPost,
} from "./pos-products-utils"
import { PosPartDetails } from "./PosPartDetails"
import { PosPartImage } from "./PosPartImage"
import { PosPartsUrlState } from "./PosParts"
import { PosPartStatus } from "./PosPartStatus"
import { PosPartTaxonomy } from "./PosPartTaxonomy"

const PosPart: FC = () => {
	useScrollToTopOnMount()
	const queryClient = useQueryClient()
	const history = useHistory()
	const location = useLocation<{ params?: PosPartQueryParams }>()
	const { id } = useParams<{ id?: string }>()
	const { makeSuccessToast, makeErrorToast } = useToast()

	const [part, partLoading] = usePosPart(id ?? null, {
		queryConfig: { refetchOnWindowFocus: true },
	})
	const [relations, relationsLoading] = useOnlinePartRelations(part?.id.toString())

	const createPart = useCreatePosPart()
	const updatePartImage = useUpdatePosPartImage()
	const updatePart = useUpdatePosPart()

	const [newImageFile, setNewImageFile] = useState<File | null>(null)
	const [isSaving, setIsSaving] = useState(false)

	const navigateToPartsList = () => {
		history.push({
			pathname: "/pos-products",
			search: encodeUrlState<PosPartsUrlState>(location.state?.params),
		})
	}

	const formMethods = useForm<PosPartForm>({
		resolver: zodResolver(PosPartFormSchema),
		defaultValues: part ? getFormValuesFromPosPart(part) : { ...posPartFormDefaultValues },
	})

	const {
		control,
		watch,
		handleSubmit,
		reset,
		formState: { isValid, submitCount },
		setError,
		setValue,
	} = formMethods

	// When we get the full POS part back from the ID, set the form to its values.
	useChangeCallback(part, (newPart) => {
		if (!newPart) {
			reset({ ...posPartFormDefaultValues })
		} else {
			reset(getFormValuesFromPosPart(newPart))
		}
	})

	const [relationship] = watch(["relationship"])

	const onSubmit = async (formData: PosPartForm) => {
		// For now we're just preventing them from doing this. In the future we can
		// handle it a bit more gracefully
		if (
			formData.relationship !== "parent" &&
			part?.isParent === true &&
			(relationsLoading || (relations?.child ?? []).length > 0)
		) {
			setError("relationship", {
				message:
					"Cannot change a parent product to be a non-parent if it has children attached. Convert those children to standalone products first.",
			})
			setValue("relationship", "parent")
			return
		}

		try {
			setIsSaving(true)

			if (isEditing) {
				// If there's an image update, that'll be a separate call. Tried doing this in
				// parallel with Promise.all but the updates seemed to conflict with each other somehow.
				if (newImageFile) {
					const imageFormData = new FormData()
					imageFormData.append("file", newImageFile)
					await updatePartImage({
						id,
						updates: imageFormData,
					})
				}

				// Note that the image updating call came first. This needs to happen in that order
				// so the server side updates trickling down to children happens properly.
				const updates = posPartFormToPosPartPatch(formData)
				await updatePart({
					id,
					updates,
				})

				makeSuccessToast("Product updated")
			} else {
				// The POST endpoint lets us do this all in one call.
				const newPartData = posPartFormToPosPartPost(formData, newImageFile)
				await createPart(newPartData)
				makeSuccessToast("Product created")
			}
			navigateToPartsList()
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		} finally {
			setIsSaving(false)
		}
	}

	useEffect(() => {
		return () => {
			// The cached version of the details query can give an out-of-date initial ID to
			// the parent part selector, so bust that cache whenever we leave this page.
			void queryClient.removeQueries([
				PointOfSaleUrlPaths.PointOfSale,
				PointOfSaleUrlPaths.PartDetail,
			])
			// This is a kinda slow query, so if you're on a part page, update it, then go back into
			// it you see the stale data for a sec.
			void queryClient.removeQueries([
				PointOfSaleUrlPaths.PointOfSale,
				PointOfSaleUrlPaths.Parts,
				id ?? "",
			])
		}
	}, [queryClient, id])

	const headingDetail =
		part?.lastModified ?
			`Last modified ${formatDateTime(part.lastModified)}${
				part.modifiedBy ? ` by ${part.modifiedBy.name}` : ""
			}`
		:	undefined

	const isEditing = !!id

	if (!!id && partLoading) {
		return (
			<Card>
				<LoadingSpinner text="Loading part information..." />
			</Card>
		)
	}

	return (
		<FormProvider {...formMethods}>
			<PageTitle title={part?.title ?? (partLoading ? undefined : "Create New Product")} />

			<Button
				icon="long-arrow-left"
				containerProps={{ mb: 3 }}
				onClick={navigateToPartsList}
			>
				Back
			</Button>

			<Card
				heading={isEditing ? part?.title : "New Point Of Sale Product"}
				headingIcon="tag"
				headingDetail={headingDetail}
			>
				<GridContainer columnGap={4} mdProps={{ columnGap: 1 }} smProps={{ columnGap: 0 }}>
					<GridItem sm={12} md={6}>
						<HeadingDivider headingVariant="h3" mt={0}>
							Status
						</HeadingDivider>
						<PosPartStatus />

						<HeadingDivider headingVariant="h3" mt={3}>
							Details
						</HeadingDivider>
						<PosPartDetails part={part ?? null} />

						<HeadingDivider headingVariant="h3" mt={3}>
							Taxonomy
						</HeadingDivider>
						<PosPartTaxonomy />

						<AnimatedEntrance show={relationship !== "parent"}>
							<HeadingDivider headingVariant="h3" mt={3}>
								Shipping
							</HeadingDivider>
							<Box maxWidth="50%" xsProps={{ maxWidth: "none" }}>
								<NumericInputFormField
									control={control}
									name="flatShippingRate"
									label="Flat shipping rate $"
									decimalScale={2}
									fixedDecimalScale
								/>
							</Box>
						</AnimatedEntrance>
					</GridItem>

					<GridItem sm={12} md={6}>
						<HeadingDivider headingVariant="h3" mt={0}>
							Product Image
						</HeadingDivider>
						<PosPartImage
							part={part ?? null}
							newImageFile={newImageFile}
							setNewImageFile={setNewImageFile}
						/>
					</GridItem>
				</GridContainer>

				<Divider fullCardBleed />
				<Box
					display="flex"
					columnGap={1}
					my={2}
					xsProps={{ columnGap: 0, justifyContent: "space-between" }}
				>
					<Button
						onClick={handleSubmit(onSubmit)}
						variant="primary-cta"
						isLoading={isSaving}
						disabled={submitCount > 0 && !isValid}
					>
						{isEditing ? "Save Changes" : "Create Part"}
					</Button>
					<Button variant="clear" onClick={navigateToPartsList}>
						Cancel
					</Button>
				</Box>
			</Card>
		</FormProvider>
	)
}

export default PosPart

import { FC, useMemo, useState } from "react"

import { InventoryPart, useInventoryPart, useInventoryParts } from "@ncs/ncs-api"

import { useInitialId } from "../../util"
import { ExtendableSearchableSelectProps, SearchableSelect } from "../inputs"

export interface PartSelectorProps extends ExtendableSearchableSelectProps<InventoryPart> {
	/** Include all parts? By default the backend excludes some parts. */
	includeNonService?: boolean
	/** Crank it up to let viewer sift through more. Comes at the cost of slower responses from server. */
	pageSize?: number
	/** Only show parts that are restricted, or only not restricted. */
	restricted?: boolean
	/** Give a customer ID to only return parts that they're allowed to buy (as far as restricted parts go -
	 * doesn't check anything about Catalogs). */
	restrictionsCustomer?: string | null
	/** Don't do any checking on restricted status and just return them all regardless. */
	skipRestrictedCheck?: boolean
	/** Part that you want to start selected. */
	initialPartId?: string | null
}

export const PartSelector: FC<PartSelectorProps> = ({
	value,
	onChange,
	initialPartId,
	includeNonService,
	restricted,
	restrictionsCustomer,
	skipRestrictedCheck,
	pageSize = 25, // We'll make it a little lower to speed it up, since results are viewed in dropdown.
	...rest
}) => {
	const [searchInput, setSearchInput] = useState<string | null>(null)

	const partsQuery = useInventoryParts({
		params: {
			search: searchInput,
			allParts: includeNonService ? true : undefined,
			service: !includeNonService ? true : undefined,
			restricted,
			restrictionsCustomer,
			skipRestrictedCheck,
		},
		pageSize,
		queryConfig: {
			enabled: !!searchInput,
		},
	})

	// If an initial ID was passed in, we'll go fetch that so we can select it.
	const [initialPart] = useInventoryPart(initialPartId ?? null, {
		queryConfig: {
			enabled: !!initialPartId && value == null,
		},
	})

	useInitialId({
		currentValue: value,
		onChange,
		initialId: initialPartId,
		findInitialOption: () => {
			// If it's in the query, return that.
			const partsQueryResult = partsQuery.data.find((p) => p.id === initialPartId)
			if (partsQueryResult) return partsQueryResult
			// Then check if we got it by calling for just the one part.
			if (initialPart) return initialPart

			return null
		},
	})

	const options = useMemo(() => {
		return value ?
				// `value` may or may not be present in the data, especially at a first.
				// Inject it here, and then filter it out of the data to prevent duplicate.
				[value, ...partsQuery.data.filter((d) => d.id !== value.id)]
			:	partsQuery.data
	}, [partsQuery.data, value])

	return (
		<SearchableSelect
			label="Part"
			placeholder="Search for a part..."
			{...rest}
			isLoading={partsQuery.isLoading || rest.isLoading}
			getOptionLabel={(option) =>
				`(${option.partNumber}) ${option.description} ${option.otherPartNumbers?.join(
					", "
				)}`
			}
			value={value ?? null}
			options={options}
			onInputChange={setSearchInput}
			onItemSelect={onChange}
			useSearchIcon
		/>
	)
}

import { useState } from "react"

import withStyles from "@material-ui/core/styles/withStyles"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import { isAxiosError, makeApiErrorMessage, useAuth } from "@ncs/ncs-api"
import loginPageStyle from "@ncs/bricks/assets/jss/material-dashboard-pro-react/views/loginPageStyle"
import { authErrors } from "@ncs/bricks/redux/selectors"
import { login as reduxAuthLogin } from "@ncs/bricks/redux/services/auth"
import { AnimatedEntrance } from "@ncs/web-legos"

import LoginPage from "./LoginPage"

const LoginPageContainer = ({ classes, submitLogin }) => {
	const { login } = useAuth()
	const [{ email, password }, setCredentials] = useState({
		email: "",
		password: "",
	})
	const [errorMessage, setErrorMessage] = useState("")
	const [isSubmitting, setIsSubmitting] = useState(false)

	const handleChange = (e) => {
		const {
			target: { id, value },
		} = e
		setCredentials((prev) => ({
			...prev,
			[id]: value,
		}))
	}

	const onSubmit = async (event) => {
		event.preventDefault()

		try {
			setIsSubmitting(true)
			setErrorMessage("")

			// For now, also still log in the old way.
			// TODO - just use one login style. Some day!
			submitLogin(email, password)

			// Login with new jwt auth context.
			await login(email, password, "internal")
		} catch (e) {
			console.error(e)
			if (isAxiosError(e)) {
				if (e.response?.status === 401) {
					setErrorMessage("Incorrect email/password combination")
				} else if (e.response?.status === 403) {
					setErrorMessage("Account not set up for use on this site.")
				} else {
					setErrorMessage(makeApiErrorMessage(e))
				}
			} else {
				setErrorMessage("An error occurred, please try again.")
			}
		} finally {
			setIsSubmitting(false)
		}
	}

	return (
		<AnimatedEntrance show>
			<LoginPage
				onInputChange={handleChange}
				classes={classes}
				email={email}
				password={password}
				onSubmit={onSubmit}
				errorMessage={errorMessage}
				isLoading={isSubmitting}
			/>
		</AnimatedEntrance>
	)
}

LoginPageContainer.propTypes = {
	classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
	errors: authErrors(state),
})

const mapDispatchToProps = (dispatch) => ({
	submitLogin: (email, password) => {
		dispatch(reduxAuthLogin(email, password))
	},
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(loginPageStyle)(LoginPageContainer))

import React, { useEffect, useRef } from "react"
import {
	LineChart,
	LineChartData,
	LineChartEventsTypes,
	LineChartOptions,
	LineChartOptionsWithDefaults,
	ResponsiveOptions,
} from "chartist"

export interface ChartistLineGraphProps {
	data: LineChartData
	options?: LineChartOptionsWithDefaults
	responsiveOptions?: ResponsiveOptions<LineChartOptions>
	listeners?: Record<keyof LineChartEventsTypes, (data: LineChartData) => void>
	className?: string
	style?: React.CSSProperties
	children?: React.ReactNode
}

const ChartistLineGraph: React.FC<ChartistLineGraphProps> = (props) => {
	const { data, listeners, options, responsiveOptions } = props
	const chart = useRef<HTMLDivElement | null>()
	const chartist = useRef<LineChart>()
	const [mounted, setMounted] = React.useState(false)

	// create a new line graph instance once we have a ref to the DOM element
	useEffect(() => {
		chartist.current = new LineChart(chart.current ?? null, data, options, responsiveOptions)
		setMounted(true)
	}, [chart.current])

	// attach listeners
	useEffect(() => {
		if (!mounted) {
			return
		}
		const localRef = chartist.current
		if (listeners && localRef) {
			Object.entries(listeners).forEach(([eventName, listener]) => {
				localRef.off(eventName)
				localRef.on(eventName, listener)
			})
		}
	}, [listeners, mounted])

	// handle updates and cleanup
	useEffect(() => {
		if (!mounted) {
			return
		}
		const localRef = chartist.current
		if (!localRef) {
			return
		}

		localRef.update(data, options, false)
		return () => {
			localRef?.detach()
		}
	}, [data, mounted, options])

	const { className, style, children } = props

	return (
		<div
			className={`ct-chart ${className || ""}`}
			ref={(ref) => (chart.current = ref)}
			style={style}
		>
			{children}
		</div>
	)
}

export default ChartistLineGraph

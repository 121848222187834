import { FC } from "react"

import { useHistory, useParams } from "react-router-dom"

import { CustomerMinQueryParams, useCustomer } from "@ncs/ncs-api"
import {
	Box,
	Button,
	Callout,
	Card,
	encodeUrlState,
	Icon,
	LoadingSpinner,
	Paragraph,
	Tabs,
	useScrollToTopOnMount,
	useUrlState,
} from "@ncs/web-legos"

import { PageTitle } from "~/layouts/PageTitle"
import { CustomerManagementUrlState } from "~/views/Customer/CustomerManagement/CustomerManagement"

import {
	CustomerBillingTab,
	CustomerDetailsTab,
	CustomerPartsEquipmentTab,
	CustomerSalesAndServiceTab,
	CustomerSiteSurveysTab,
} from "./components"

export enum CustomerDetailTabName {
	Details = "Details",
	Billing = "Billing",
	PartsEquipment = "Parts & Equipment",
	Sales = "Sales & Service",
	SiteSurveys = "Site Surveys",
}

export type CustomerDetailUrlState = {
	tab: CustomerDetailTabName
}

export const CustomerDetail: FC = () => {
	useScrollToTopOnMount()
	const history = useHistory<{ customerQueryParams: CustomerMinQueryParams }>()
	const { customerId } = useParams<{ customerId: string }>()
	const [{ tab }, { updateUrlValue }] = useUrlState<CustomerDetailUrlState>({
		tab: CustomerDetailTabName.Details,
	})

	const [customer, customerLoading] = useCustomer(customerId)

	if (customerLoading) {
		return <LoadingSpinner />
	}
	if (!customer) {
		return null
	}

	return (
		<>
			<PageTitle
				title={`${
					customer ? `${customer.customerNumber} - ` : ""
				}Customer Details - ${tab}`}
			/>

			<Box display="flex" justifyContent="space-between" alignItems="flex-end">
				<Button
					icon="long-arrow-left"
					containerProps={{ mb: 2 }}
					onClick={() => {
						history.push({
							pathname: "/customer-management",
							search: encodeUrlState<CustomerManagementUrlState>(
								history.location.state?.customerQueryParams
							),
						})
					}}
				>
					All customers
				</Button>
			</Box>

			<Card
				topDetail="Customer Management"
				heading={customer ? `(${customer.customerNumber}) ${customer.name}` : undefined}
				headingIcon="user"
				renderRight={() => {
					return customer ?
							<Box display="flex" columnGap={1}>
								{customer.creditCheckStatus === "NO MORE SERVICE" && (
									<Callout variant="error" icon="ban">
										<Paragraph>Credit Status: No More Service</Paragraph>
									</Callout>
								)}
								{!customer.isFstActive && (
									<Callout variant="warning" icon="user-large-slash">
										<Paragraph>Inactive customer</Paragraph>
									</Callout>
								)}
							</Box>
						:	undefined
				}}
			>
				<Tabs
					currentTab={tab}
					onChange={(newTab) => updateUrlValue("tab", newTab)}
					panels={[
						{
							navLabel: CustomerDetailTabName.Details,
							navIcon: <Icon icon="user-cog" />,
							component: <CustomerDetailsTab customer={customer} />,
						},
						{
							navLabel: CustomerDetailTabName.Billing,
							navIcon: <Icon icon="dollar-sign" />,
							component: <CustomerBillingTab customer={customer} />,
						},
						{
							navLabel: CustomerDetailTabName.PartsEquipment,
							navIcon: <Icon icon="car-wash" />,
							component: <CustomerPartsEquipmentTab customer={customer} />,
						},
						{
							navLabel: CustomerDetailTabName.Sales,
							navIcon: <Icon icon="shopping-cart" />,
							component: <CustomerSalesAndServiceTab customer={customer} />,
						},
						{
							navLabel: CustomerDetailTabName.SiteSurveys,
							navIcon: <Icon icon="clipboard-list" />,
							component: <CustomerSiteSurveysTab customer={customer} />,
						},
					]}
				/>
			</Card>
		</>
	)
}

import React from "react"
import moment from "moment"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators, compose } from "redux"
import get from "lodash/get"
import qs from "query-string"
import callApi from "@ncs/bricks/redux/services/callApi"
import {
	getDocument,
	patchDocument,
	updateDocumentFile,
	listInfoRefCategories,
	createDocument,
} from "@ncs/mortar/redux/services/infoRef"
import { meetsAppRestriction } from "@ncs/bricks/redux/selectors/auth"
import { APPLICATIONS } from "@ncs/mortar/util/constants"
import FormValidator from "@ncs/bricks/util/formValidator"
import DocumentDetail from "./DocumentDetail"

class DocumentDetailContainer extends React.Component {
	validations = {
		id: { stateName: "id" },
		description: { stateName: "description", isRequired: true, maxLength: 250, default: "" },
		document_number: {
			stateName: "documentNumber",
			isRequired: true,
			maxLength: 50,
			default: "",
		},
		revision: { stateName: "revision", isRequired: true, maxLength: 20, default: "" },
		effective_date: { stateName: "effectiveDate", type: "date", default: moment() },
		expiration_date: { stateName: "expirationDate", type: "date" },
		categories: { stateName: "categories", type: "array", isRequired: true, default: [] },
	}

	constructor(props) {
		super(props)
		this.formValidator = new FormValidator(this, this.validations)
		this.fileInputRef = React.createRef()

		this.state = {
			isLoading: true,
			isCreating: false,
			isEditing: false,
			isSaving: false,
			isAddingCategory: false,
			isUpdatingFile: false,
			file: null,
			displayActionFailure: false,
			document: {},
			selectedCategory: null,
			selectedTopLevelCategory: null,
			selectedSecondLevelCategory: null,
			selectedThirdLevelCategory: null,
			selectedFourthLevelCategory: null,
			...this.formValidator.getInitialFormState(),
		}
	}

	componentDidMount() {
		let isCreating = this.props.location.pathname.indexOf("create-document") >= 0
		if (isCreating) {
			// if you don't background this with setTimeout the loading modal stays over the top of everything invisibly
			setTimeout(
				() =>
					this.setState({
						isLoading: false,
						isCreating: true,
						isEditing: true,
						isAddingCategory: true,
						document: {},
					}),
				1
			)

			this.addCategoryFromUrl()
		} else {
			this.setState({ isLoading: true, document: {} })
			this.refreshDocument()
		}

		this.props.listInfoRefCategories()
	}

	addCategoryFromUrl = () => {
		const categoryId = qs.parse(this.props.location.search)["category"]
		if (!categoryId) {
			return
		}

		if (this.props.documentCategories.length === 0) {
			setTimeout(this.addCategoryFromUrl, 50)
			return
		}

		let category = this.props.reverseDocumentCategoriesMap[categoryId]
		this.handleAssignPassedCategory(category)
	}

	refreshDocument = () => {
		this.setState({
			isCreating: false,
			isEditing: false,
			isUpdatingFile: false,
			isLoading: true,
		})
		let id = this.props.match.params.id
		this.props.callApi(getDocument(id)).then((result) => {
			this.formValidator.setFormStateFromObject(result.payload, {
				isLoading: false,
				document: result.error ? {} : result.payload,
			})
		})
	}

	handleDownload = () => {
		window.open(this.state.document.presign_url, "_blank")
	}

	handleBackToList = () => {
		this.props.history.push("/info-ref/documents")
	}

	handleViewDocument = (id) => {
		this.props.history.push(`/info-ref/documents/${id}`)
	}

	getCategoryById = (id) => {
		return this.props.reverseDocumentCategoriesMap[id]
	}

	handleStartEditing = () => {
		this.formValidator.setFormStateFromObject(this.state.document, {
			isEditing: true,
			isCreating: false,
			isUpdatingFile: false,
		})
		this.formValidator.dataIsValid()
	}

	handleCancelEditing = () => {
		this.refreshDocument()
	}

	handleSave = () => {
		if (!this.formValidator.dataIsValid()) {
			this.setState({ showValidationErrors: true })
			return
		}

		this.setState({ isSaving: true })

		const handleResult = (result) => {
			if (this.state.isCreating) {
				this.handleViewDocument(result.payload.id)
			}

			this.setState({
				isSaving: false,
				displayActionFailure: !!result.error,
			})

			if (!result.error) {
				this.refreshDocument()
			}
		}

		const handleError = (error) => {
			console.error(error)
			this.setState({ isSaving: false, displayActionFailure: true })
		}

		const data = this.formValidator.getChangedFields(this.state.document)
		if (this.state.isCreating) {
			data.categories = data.categories.map((x) => x.id)

			this.fileInputRef.current.startUpload(createDocument(data)).then(handleResult)
		} else {
			let savedSomething = false

			if (Object.keys(data).length > 0) {
				savedSomething = true
				this.props
					.callApi(patchDocument(this.state.document.id, data))
					.then(handleResult)
					.catch(handleError)
			}

			if (this.state.isUpdatingFile && this.state.file) {
				savedSomething = true

				this.fileInputRef.current
					.startUpload(updateDocumentFile(this.state.document.id, this.state.file))
					.then(handleResult)
					.catch(handleError)
			}

			if (!savedSomething) {
				// fake a successful response to get rid of editing/saving statuses
				handleResult({})
			}
		}
	}

	handleStartAddingCategory = () => {
		this.setState({ isAddingCategory: true })
	}

	getCategoryLabel = (category) => {
		let cat = this.getCategoryById(category.id)
		if (!cat) {
			return null
		}

		// build category name with parents' names too
		let label = cat.name
		let parent = cat.parent
		while (parent) {
			label = `${parent.name} --> ${label}`
			parent = parent.parent
		}
		return label
	}

	handleCategoryUpdated = (selectedCategory) => {
		const map = this.props.reverseDocumentCategoriesMap

		if (typeof selectedCategory === "string") {
			selectedCategory = map[selectedCategory]
		}

		// retain next-level category if the name is the same (adding a bulletin to many vintages, for example,
		// will keep bulletin selected (third-category) when changing vintages (second-category)
		let currentCategoryName = get(this.state.selectedCategory, "name")
		if (selectedCategory && currentCategoryName) {
			let matchingNextLevelCategory = selectedCategory.sub_categories.find(
				(x) => x.name === currentCategoryName
			)
			if (
				matchingNextLevelCategory &&
				matchingNextLevelCategory.id !== this.state.selectedCategory.id
			) {
				selectedCategory = matchingNextLevelCategory
			}
		}

		let top, second, third, fourth
		top = second = third = fourth = null

		if (selectedCategory) {
			top = map[selectedCategory.id]
			if (top.parent) {
				second = top
				top = second.parent

				if (top.parent) {
					third = second
					second = top
					top = second.parent

					if (top.parent) {
						fourth = third
						third = second
						second = top
						top = second.parent
					}
				}
			}
		}

		this.setState({
			selectedCategory,
			selectedTopLevelCategory: top,
			selectedSecondLevelCategory: second,
			selectedThirdLevelCategory: third,
			selectedFourthLevelCategory: fourth,
		})
	}

	handleAssignCategory = () => {
		this.handleAssignPassedCategory(this.state.selectedCategory)
	}

	handleAssignPassedCategory = (category) => {
		// none selected or selected one is already in list
		if (!category || this.state.categories.some((x) => x.id === category.id)) {
			return
		}

		this.setState({
			categories: [...this.state.categories, { ...category }],
		})
	}

	handleUnassignCategory = (category) => {
		this.setState({
			categories: this.state.categories.filter((x) => x.id !== category.id),
		})
	}

	handleFailureModalClose = () => {
		this.setState({ displayActionFailure: false })
	}

	handleStartUpdatingFile = () => {
		this.setState({ isUpdatingFile: true })
	}

	handleCancelUpdatingFile = () => {
		this.setState({ isUpdatingFile: false, file: null })
	}

	handleFileSelected = (f) => {
		this.setState({ file: !f ? null : f[0] })
	}

	render = () => (
		<DocumentDetail
			isLoading={this.state.isLoading}
			item={this.state.document}
			isCreating={this.state.isCreating}
			isEditing={this.state.isEditing}
			isSaving={this.state.isSaving}
			isAddingCategory={this.state.isAddingCategory}
			infoRefCategories={this.props.documentCategories}
			getCategoryLabel={this.getCategoryLabel}
			onAddCategory={this.handleStartAddingCategory}
			onAssignCategory={this.handleAssignCategory}
			onUnassignCategory={this.handleUnassignCategory}
			onCategoryChange={this.handleCategoryUpdated}
			selectedCategory={this.state.selectedCategory}
			selectedTopLevelCategory={this.state.selectedTopLevelCategory}
			selectedSecondLevelCategory={this.state.selectedSecondLevelCategory}
			selectedThirdLevelCategory={this.state.selectedThirdLevelCategory}
			selectedFourthLevelCategory={this.state.selectedFourthLevelCategory}
			onDownload={this.handleDownload}
			onBackToList={this.handleBackToList}
			isInfoRefAdmin={this.props.isInfoRefAdmin()}
			onStartEditing={this.handleStartEditing}
			onCancelEditing={this.handleCancelEditing}
			isUpdatingFile={this.state.isUpdatingFile}
			onStartUpdatingFile={this.handleStartUpdatingFile}
			onCancelUpdatingFile={this.handleCancelUpdatingFile}
			onFileSelected={this.handleFileSelected}
			isFileSelected={!!this.state.file}
			onSave={this.handleSave}
			displayActionFailure={this.state.displayActionFailure}
			onFailureModalClose={this.handleFailureModalClose}
			fileInputRef={this.fileInputRef}
			{...this.formValidator.generateFormProps()}
		/>
	)
}

const mapStateToProps = ({ infoRef, auth }) => {
	return {
		...infoRef,
		isInfoRefAdmin: () => {
			return meetsAppRestriction(auth, APPLICATIONS.InfoRefAdmin)
		},
	}
}

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ callApi, listInfoRefCategories }, dispatch),
})

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withRouter
)(DocumentDetailContainer)

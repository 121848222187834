import { FC, useState } from "react"

import {
	EnterpriseMinimal,
	makeApiErrorMessage,
	Organization,
	useCreateEnterprise,
	useUpdateOrganization,
} from "@ncs/ncs-api"
import {
	AnimatedEntrance,
	EnterpriseSelector,
	ExtendableModalProps,
	Modal,
	RadioBoolean,
	TextInput,
	useChangeCallback,
	useToast,
} from "@ncs/web-legos"

export interface ReassignEnterpriseModalProps extends ExtendableModalProps {
	organization: Organization
}

export const ReassignEnterpriseModal: FC<ReassignEnterpriseModalProps> = ({
	organization,
	...rest
}) => {
	const { makeSuccessToast } = useToast()
	const [isNew, setIsNew] = useState(false)
	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const [newName, setNewName] = useState<string | null>(null)
	const [selectedEnterprise, setSelectedEnterprise] = useState<EnterpriseMinimal | null>(null)

	const updateOrganization = useUpdateOrganization()
	const createEnterprise = useCreateEnterprise()

	const handleSave = async () => {
		try {
			setIsSaving(true)
			let enterpriseIdUpdate = selectedEnterprise?.id ?? null

			// First create the new enterprise if needed.
			if (isNew) {
				if (!newName) throw new Error("Name is required")

				const { data } = await createEnterprise({ name: newName })
				enterpriseIdUpdate = data
			}

			// Now update the organization. Should either have a selected ID or the new one.
			if (!enterpriseIdUpdate) throw new Error("Select an enterprise")

			await updateOrganization({
				id: organization.id,
				updates: { enterprise: enterpriseIdUpdate },
			})
			makeSuccessToast("Organization updated")
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	useChangeCallback(isNew, (newIsNew) => {
		if (newIsNew === true) {
			setSelectedEnterprise(null)
		} else {
			setNewName(null)
		}
	})

	return (
		<Modal
			{...rest}
			title="Choose Enterprise for Organization"
			errorText={errorText}
			rightButtons={{
				buttonText: "Save",
				isLoading: isSaving,
				onClick: handleSave,
				disabled: !newName && !selectedEnterprise,
			}}
		>
			<RadioBoolean
				htmlName="is-new-enterprise"
				description="Reassign this organization to an existing enterprise or create a new one?"
				value={isNew}
				onChange={(newValue) => {
					setIsNew(newValue)
				}}
				noText="Assign to an existing enterprise"
				yesText="Create a new enterprise"
				noFirst
			/>
			<AnimatedEntrance show={isNew}>
				<TextInput value={newName} onChange={setNewName} label="New enterprise name" />
			</AnimatedEntrance>
			<AnimatedEntrance show={!isNew}>
				<EnterpriseSelector
					value={selectedEnterprise}
					onChange={setSelectedEnterprise}
					label="Existing enterprise"
					placeholder="Search existing enterprises..."
				/>
			</AnimatedEntrance>
		</Modal>
	)
}

import { FC, useMemo } from "react"

import dayjs from "dayjs"
import { useHistory } from "react-router-dom"
import { Cell, Column } from "react-table"

import {
	ContractQueryParams,
	ContractSearchResult,
	defaultContractsQueryParams,
	useContractBusinessUnits,
	useOrganizationContracts,
} from "@ncs/ncs-api"
import { formatDate, Typify } from "@ncs/ts-utils"
import {
	Box,
	ContractCoverageTypeQueryFilter,
	ContractTypeQueryFilter,
	EmptyValueDash,
	FilterGridProps,
	Icon,
	SearchQueryFilter,
	Table,
	useUrlState,
} from "@ncs/web-legos"

const redDaysAway = 30
const yellowDaysAway = 100
const today = dayjs()

const pinnedQueryFilters: FilterGridProps<ContractQueryParams>["filters"] = [
	SearchQueryFilter,
	ContractTypeQueryFilter,
	ContractCoverageTypeQueryFilter,
]

export type ContractsListTabUrlState = Typify<ContractQueryParams>

export const ContractsListTab: FC = () => {
	const history = useHistory()
	const [queryParams, { setUrlState }] = useUrlState<ContractsListTabUrlState>(
		defaultContractsQueryParams
	)
	const [contracts, contractsLoading] = useOrganizationContracts({
		params: queryParams,
	})
	const [businessUnits] = useContractBusinessUnits()

	const c = useMemo(() => {
		if (businessUnits?.length) {
			const columns: Column<ContractSearchResult>[] = [
				{
					Header: "Contract ID",
					accessor: "id",
					hiddenByDefault: true,
				},
				{
					Header: "Organization",
					accessor: (original) => original.organization,
				},
				{
					Header: "Contract types",
					id: "contract-types",
					Cell: ({ row: { original } }: Cell<ContractSearchResult>) => {
						return original.types.length ?
								original.types.map((t) => t.name).join(", ")
							:	<EmptyValueDash />
					},
				},
				{
					Header: "Site coverage",
					id: "coverageType",
					hiddenByDefault: true,
					Cell: ({ row: { original } }: Cell<ContractSearchResult>) =>
						original.coverageType?.description ?? <EmptyValueDash />,
				},
				{
					Header: "Effective date",
					accessor: (original) => formatDate(original.effectiveDate),
				},
				{
					Header: "Expiration date",
					accessor: "expirationDate",
					Cell: ({ row: { original } }: Cell<ContractSearchResult>) => {
						const expirationDate = dayjs(original.expirationDate)
						const daysUntilExpire = expirationDate.diff(today, "days")

						return (
							<Box display="flex" alignItems="center" columnGap={1}>
								<span>{formatDate(original.expirationDate)}</span>
								{daysUntilExpire <= redDaysAway ?
									<Icon icon="history" color="error" />
								: daysUntilExpire <= yellowDaysAway ?
									<Icon icon="history" color="warning" />
								:	null}
							</Box>
						)
					},
				},
			]

			businessUnits.forEach((unit) => {
				columns.push(
					{
						Header: `${unit.name} credits`,
						id: `${unit.name}-credits`,
						hiddenByDefault: true,
						Cell: ({ row: { original } }: Cell<ContractSearchResult>) => {
							return original[`${unit.name}Credits`] ?
									<Icon icon="check" />
								:	<EmptyValueDash />
						},
					},
					{
						Header: `${unit.name} discounts`,
						id: `${unit.name}-discounts`,
						hiddenByDefault: true,
						Cell: ({ row: { original } }: Cell<ContractSearchResult>) => {
							return original[`${unit.name}Discounts`] ?
									<Icon icon="check" />
								:	<EmptyValueDash />
						},
					},
					{
						Header: `${unit.name} rebates`,
						id: `${unit.name}-rebates`,
						hiddenByDefault: true,
						Cell: ({ row: { original } }: Cell<ContractSearchResult>) => {
							return original[`${unit.name}Rebates`] ?
									<Icon icon="check" />
								:	<EmptyValueDash />
						},
					}
				)
			})

			return columns
		}

		return null
	}, [businessUnits])

	return (
		<>
			{!!c && (
				<Table
					columns={c}
					data={contracts ?? []}
					isLoading={contractsLoading}
					queryParamState={queryParams}
					setQueryParamState={setUrlState}
					pinnedQueryFilters={pinnedQueryFilters}
					onRowClick={({ original }) =>
						history.push(`/contracts/${original.id}`, {
							contractQueryParams: queryParams,
						})
					}
					disableAllSorting
				/>
			)}
		</>
	)
}

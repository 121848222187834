import React from "react"
import { Checkbox } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"

const height = "2rem"
const animation = "wave"

const WashCountSkeletonRows = () => {
	const row = (
		<tr>
			<td style={{ width: "3rem" }}>
				<Checkbox disabled />
			</td>
			<td colSpan={7}>
				<Skeleton height={height} animation={animation} />
			</td>
			<td>
				{/* Current Month */}
				<Skeleton height={height} animation={animation} />
			</td>
			<td>
				{/* Ending Count */}
				<Skeleton height={height} animation={animation} />
			</td>
			<td colSpan={3}>
				<Skeleton height={height} animation={animation} />
			</td>
		</tr>
	)

	return (
		<>
			{row}
			{row}
			{row}
			{row}
			{row}
			{row}
			{row}
			{row}
			{row}
			{row}
			{row}
			{row}
		</>
	)
}

export default React.memo(WashCountSkeletonRows)

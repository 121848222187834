import { Dispatch, ReactElement, SetStateAction, useEffect, useState } from "react"

import { OrganizationMinimal } from "@ncs/ncs-api"

import { usePrevious } from "../../util"
import { OrganizationSelector } from "../selectors"

interface OrganizationQueryParams {
	organization: string | null
}

export interface OrganizationQueryFilterProps<QueryParamState extends OrganizationQueryParams> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

export const OrganizationQueryFilter = <QueryParamState extends OrganizationQueryParams>({
	queryParamState,
	setQueryParamState,
}: OrganizationQueryFilterProps<QueryParamState>): ReactElement => {
	const [selectedOrganization, setSelectedOrganization] = useState<OrganizationMinimal | null>(
		null
	)

	const handleChange = (newOrganization: OrganizationMinimal | null) => {
		// Set local state with full Organization object.
		setSelectedOrganization(newOrganization)
		// Set query param state with just the Organization ID.
		setQueryParamState((prev) => ({
			...prev,
			organization: newOrganization?.id ?? null,
		}))
	}

	const prevOrganizationFromQueryParams = usePrevious(queryParamState.organization)
	useEffect(() => {
		// Handles the user clicking Reset button.
		if (
			queryParamState.organization === null &&
			prevOrganizationFromQueryParams !== null &&
			selectedOrganization !== null
		) {
			setSelectedOrganization(null)
		}
	}, [prevOrganizationFromQueryParams, queryParamState.organization, selectedOrganization])

	return (
		<OrganizationSelector
			value={selectedOrganization}
			onChange={handleChange}
			initialId={queryParamState.organization}
			fillContainer
		/>
	)
}

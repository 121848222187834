import React from "react"
import moment from "moment"
// custom components
import { GridContainer, DashboardCard, GridItem, TicketGroupFilter } from "~/components"
// icons
import NotificationsActive from "@material-ui/icons/NotificationsActive"
import NewReleases from "@material-ui/icons/NewReleases"
import Done from "@material-ui/icons/Done"
import Phone from "@material-ui/icons/Phone"
import ThumbUp from "@material-ui/icons/ThumbUp"
import CheckCircle from "@material-ui/icons/CheckCircle"
import Update from "@material-ui/icons/Update"

// styles
import withStyles from "@material-ui/core/styles/withStyles"
import dashboardStyle from "@ncs/bricks/assets/jss/material-dashboard-pro-react/views/dashboardStyle"

const reduxKey = "ticketingDashboardReduxKey"

function TicketingDashboard(props) {
	return (
		<div>
			<GridContainer>
				<GridItem xs={12} md={6} lg={8} />
				<GridItem xs={12} md={6} lg={4}>
					<TicketGroupFilter reduxKey={reduxKey} />
				</GridItem>
			</GridContainer>
			<GridContainer>
				<DashboardCard
					color="primary"
					icon={<NotificationsActive />}
					measureName="Open Tickets"
					measureValue={props.ticketsOpen}
					measureUnit=""
					footerIcon={<Update />}
					footerText="Oldest Open Ticket:"
					footerDate={props.ticketsOpenUpdated}
					onClick={props.onViewOpenTickets}
				/>

				<DashboardCard
					color="success"
					icon={<NewReleases />}
					measureName="New Today"
					measureValue={props.ticketsNewToday}
					measureUnit=""
					footerIcon={<Update />}
					footerText="Newest Ticket:"
					footerDate={props.ticketsNewTodayUpdated}
					onClick={props.onViewNewToday}
				/>

				<DashboardCard
					color="warning"
					icon={<Done />}
					measureName="Past Due"
					measureValue={props.ticketsPastDue}
					measureUnit=""
					footerIcon={<Update />}
					footerText="Oldest Past Due:"
					footerDate={moment.tz(props.ticketsPastDueUpdated, "UTC")}
					onClick={props.onViewPastDue}
				/>

				<DashboardCard
					color="info"
					icon={<Phone />}
					measureName="Tickets Closed Today"
					measureValue={props.ticketsClosedToday}
					measureUnit=""
					footerIcon={<Update />}
					footerText="Newest Closed:"
					footerDate={props.ticketsClosedTodayUpdated}
					onClick={props.onViewClosedToday}
				/>

				<DashboardCard
					color="primary"
					icon={<ThumbUp />}
					measureName="Tickets Assigned To Me"
					measureValue={props.ticketsAssignedToMe}
					measureUnit=""
					footerIcon={<Update />}
					footerText="Newest:"
					footerDate={moment.tz(props.ticketsAssignedToMeUpdated, "UTC")}
					onClick={props.onViewAssignedToMe}
				/>

				<DashboardCard
					color="success"
					icon={<CheckCircle />}
					measureName="My Open Tickets"
					measureValue={props.myTickets}
					measureUnit=""
					footerIcon={<Update />}
					footerText="My Oldest Ticket:"
					footerDate={moment.tz(props.myTicketsUpdated, "UTC")}
					onClick={props.onViewMyOpenTickets}
				/>
			</GridContainer>
		</div>
	)
}

export default withStyles(dashboardStyle)(TicketingDashboard)

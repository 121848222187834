import { get, post, rsaaTypes, RSAA } from "@ncs/bricks/middleware/portalApiMiddleware"

export const GET_WASH_COUNTS = rsaaTypes("customers/territory_wash_counts")
export const WASH_COUNT_INITIAL = rsaaTypes("customers/wash_count_initial")
export const UPDATE_WASH_COUNTS = rsaaTypes("customers/update_wash_count_record")
export const UPDATE_COUNT_DATA = "UPDATE_COUNT_DATA"

export const getWashCountData = (context) => ({
	[RSAA]: {
		api: post("/customers/territory_wash_counts/", context),
		types: GET_WASH_COUNTS,
	},
})

export const washCountInitial = () => ({
	[RSAA]: {
		api: get(`/customers/wash_count_initial/`),
		types: WASH_COUNT_INITIAL,
	},
})

export const updateWashCounts = (context) => ({
	[RSAA]: {
		api: post(`/customers/update_wash_count_record/`, context),
		types: UPDATE_WASH_COUNTS,
	},
})

export const updateCountData = (data) => ({
	type: UPDATE_COUNT_DATA,
	data,
})

const initialState = {
	isLoading: false,
	isSubmitting: false,
	userTerritories: [],
	data: [],
	approver: false,
	error: false,
}

const washCount = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_COUNT_DATA:
			return {
				...state,
				data: action.data,
			}
		case GET_WASH_COUNTS.request:
			return {
				...state,
				isLoading: true,
				error: false,
			}
		case GET_WASH_COUNTS.success:
			return {
				...state,
				isLoading: false,
				data: action.payload,
			}
		case GET_WASH_COUNTS.failure:
			return {
				...state,
				isLoading: false,
				error: true,
			}
		case WASH_COUNT_INITIAL.request:
			return {
				...state,
				isLoading: true,
				error: false,
			}
		case WASH_COUNT_INITIAL.success:
			return {
				...state,
				isLoading: false,
				userTerritories: action.payload.user_territories,
				approver: action.payload.approver,
			}
		case WASH_COUNT_INITIAL.failure:
			return {
				...state,
				isLoading: false,
				error: true,
			}
		case UPDATE_WASH_COUNTS.request:
			return {
				...state,
				isSubmitting: true,
				error: false,
			}
		case UPDATE_WASH_COUNTS.success:
			return {
				...state,
				isSubmitting: false,
				data: action.payload,
			}
		case UPDATE_WASH_COUNTS.failure:
			return {
				...state,
				isSubmitting: false,
				error: true,
			}
		default:
			return state
	}
}
export default washCount

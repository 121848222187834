import React from "react"
import PropTypes from "prop-types"
import Autosuggest from "react-autosuggest"
import match from "autosuggest-highlight/match"
import parse from "autosuggest-highlight/parse"
import classNames from "classnames"
import debounce from "lodash/debounce"
import {
	MenuItem,
	Paper,
	TextField,
	InputAdornment,
	FormHelperText,
	withStyles,
} from "@material-ui/core"
import { Search } from "@material-ui/icons"

import {
	ConditionalContent,
	CircularProgressIndeterminate,
	SearchResultChip,
} from "../../components"

import customInputStyle from "../../assets/jss/material-dashboard-pro-react/components/customInputStyle"
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/components/customSelectStyle"

const styles = (theme) => ({
	...customInputStyle,
	...customSelectStyle,
	root: {
		flexGrow: 1,
	},
	container: {
		position: "relative",
	},
	suggestionsContainerOpen: {
		position: "absolute",
		zIndex: 99999999,
		marginTop: theme.spacing(1),
		left: 0,
		right: 0,
	},
	suggestion: {
		display: "block",
	},
	suggestionsList: {
		margin: 0,
		padding: 0,
		listStyleType: "none",
	},
	divider: {
		height: theme.spacing(2),
	},
	searchBox: {
		marginTop: "6px",
	},
})

class SearchSelector extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			value: "",
			suggestions: [],
			selectedSuggestion: props.defaultValue || null,
			isLoading: false,
			lastRequestTimestamp: null,
		}

		this.lastRequest = null
	}

	handleRenderInputComponent = (inputProps) => {
		const noop = () => {}
		const { classes, inputRef = noop, formControlProps, ref, ...other } = inputProps
		const { adornmentPosition } = this.props
		let position = adornmentPosition === "start" ? "start" : "end"

		return (
			<TextField
				fullWidth
				InputProps={{
					inputRef: (node) => {
						ref(node)
						inputRef(node)
					},
					classes: {
						input: classes.input,
					},
					[position + "Adornment"]: (
						<InputAdornment position={position}>
							{this.state.isLoading === true ?
								<CircularProgressIndeterminate size={24} />
							:	<Search />}
						</InputAdornment>
					),
				}}
				{...formControlProps}
				{...other}
			/>
		)
	}

	handleRenderSuggestion = (suggestion, { query, isHighlighted }) => {
		if (typeof this.props.renderSuggestion === "function")
			return this.props.renderSuggestion(suggestion, { query, isHighlighted })

		const { classes, getSuggestionValue } = this.props
		const suggestionValue = getSuggestionValue(suggestion)

		const matches = match(suggestionValue, query)
		const parts = parse(suggestionValue, matches)

		return (
			<MenuItem
				selected={isHighlighted}
				component="div"
				classes={{
					root: classes.selectMenuItem,
					selected: classes.selectMenuItemSelected,
				}}
			>
				<span>
					{parts.map((part, index) => {
						return part.highlight ?
								<span key={String(index)} style={{ fontWeight: 500 }}>
									{part.text}
								</span>
							:	<strong key={String(index)} style={{ fontWeight: 300 }}>
									{part.text}
								</strong>
					})}
				</span>
			</MenuItem>
		)
	}

	handleChange =
		(name) =>
		(event, { newValue }) => {
			this.setState({ [name]: newValue })
		}

	handleSearch = (value) => () => {
		this.setState({ isLoading: true, lastRequestTimestamp: new Date() }, () => {
			this.props.getSuggestions(value).then((results) => {
				// ignore if result is out-of-order
				if (
					results?.meta?.timestamp &&
					this.state.lastRequestTimestamp > results?.meta?.timestamp
				) {
					return
				}

				this.setState({
					isLoading: false,
					suggestions: results.payload,
				})
			})
		})
	}

	handleSuggestionsFetchRequested = ({ value }) => {
		// Cancel the previous request so we don't bombard the server
		if (this.lastRequest !== null) {
			this.lastRequest.cancel()
		}

		this.lastRequest = debounce(this.handleSearch(value), 350, { maxWait: 1000 })
		this.lastRequest()
	}

	handleSuggestionsClearRequested = () => {
		this.setState({
			suggestions: [],
		})
	}

	handleGetSuggestionValue = (suggestion) => {
		if (typeof this.props.getSuggestionValue === "function")
			return this.props.getSuggestionValue(suggestion)

		return suggestion
	}

	handleSuggestionSelected = (event, { suggestion, ...rest }) => {
		this.setState({ selectedSuggestion: suggestion, value: "" })

		if (typeof this.props.onSuggestionSelected === "function")
			this.props.onSuggestionSelected(event, { suggestion, ...rest })
	}

	handleResetSuggestion = () => {
		this.setState({ selectedSuggestion: null, value: "" })

		if (typeof this.props.onSuggestionSelected !== "function") return

		// mimic Autosuggest event arguments
		this.props.onSuggestionSelected(null, {
			suggestion: null,
			suggestionValue: null,
			suggestionIndex: null,
			sectionIndex: null,
			method: null,
		})
	}

	render() {
		const {
			classes,
			formControlProps,
			label,
			placeholder,
			autoFocus,
			helperText,
			selectedSuggestion,
			suggestionsListMaxHeight,
		} = this.props

		const autosuggestProps = {
			renderInputComponent: this.handleRenderInputComponent,
			suggestions: this.state.suggestions,
			onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
			onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
			getSuggestionValue: this.handleGetSuggestionValue,
			renderSuggestion: this.handleRenderSuggestion,
			onSuggestionSelected: this.handleSuggestionSelected,
		}

		const formControlClasses =
			formControlProps !== undefined && formControlProps.className !== undefined ?
				classNames(classes.selectFormControl, formControlProps.className)
			:	classes.selectFormControl

		// if prop is provided, allow full control
		const selectedItem =
			typeof selectedSuggestion !== "undefined" ? selectedSuggestion : (
				this.state.selectedSuggestion
			)

		return (
			<div className={classes.root}>
				<ConditionalContent show={selectedItem === null}>
					<React.Fragment>
						<Autosuggest
							{...autosuggestProps}
							inputProps={{
								classes: {
									input: classNames(
										{ [classes.input]: true },
										{ [classes.searchBox]: true }
									),
									disabled: classes.disabled,
									underline: classes.underline,
								},
								value: this.state.value,
								placeholder: placeholder,
								onChange: this.handleChange("value"),
								label: label,
								autoFocus: autoFocus,
								InputLabelProps: {
									shrink: true,
									className: classes.selectLabel,
								},
								formControlProps: {
									className: formControlClasses,
									...formControlProps,
								},
							}}
							theme={{
								container: classes.container,
								suggestionsContainerOpen: classes.suggestionsContainerOpen,
								suggestionsList: classes.suggestionsList,
								suggestion: classes.suggestion,
							}}
							renderSuggestionsContainer={(options) => (
								<Paper
									{...options.containerProps}
									square
									style={
										suggestionsListMaxHeight ?
											{
												maxHeight: suggestionsListMaxHeight,
												overflowY: "auto",
											}
										:	undefined
									}
								>
									{options.children}
								</Paper>
							)}
						/>
						{helperText && <FormHelperText>{helperText}</FormHelperText>}
					</React.Fragment>
				</ConditionalContent>
				<ConditionalContent show={selectedItem !== null}>
					{() => (
						<SearchResultChip
							value={[this.handleGetSuggestionValue(selectedItem)]}
							onDelete={this.handleResetSuggestion}
							label={label}
						/>
					)}
				</ConditionalContent>
			</div>
		)
	}
}

SearchSelector.defaultProps = {
	autoFocus: false,
}

SearchSelector.propTypes = {
	classes: PropTypes.object.isRequired,
	formControlProps: PropTypes.object,
	label: PropTypes.string,
	placeholder: PropTypes.string,
	/**
	 * expected to return a promise that will resolve with a payload property of the items being searched
	 * NOTE: IMPORTANT! make sure your query sets includeTimestamp to true so that out-of-order results can be ignored
	 * */
	getSuggestions: PropTypes.func.isRequired,
	getSuggestionValue: PropTypes.func,
	renderSuggestion: PropTypes.func,
	onSuggestionSelected: PropTypes.func.isRequired,
	autoFocus: PropTypes.bool,
	helperText: PropTypes.string,
	/** a pre-selected result, should be in the same format that the suggestions collection contains */
	defaultValue: PropTypes.any,
	/** optional selected value to allow for full control, should be in the same format that the suggestions collection
	 * contains */
	selectedSuggestion: PropTypes.any,
	/** Clamp down on the height of the suggestion box if necessary. */
	suggestionsListMaxHeight: PropTypes.string,
}

export default withStyles(styles)(SearchSelector)

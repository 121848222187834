import { FC, useMemo, useState } from "react"

import { Cell, Column } from "react-table"

import {
	defaultInventoryTransactionsQueryParams,
	InventoryPart,
	InventoryTransaction,
	useExportInventoryTransactions,
	useInventoryTransactions,
} from "@ncs/ncs-api"
import { formatDateTime, getTimezoneAbbreviation } from "@ncs/ts-utils"
import {
	Box,
	EmptyValueDash,
	encodeUrlState,
	Icon,
	Link,
	SearchQueryFilter,
	Table,
} from "@ncs/web-legos"

import { LocationTransactionsUrlState } from "~/views/Inventory/LocationManagement/components"
import { LocationsTab, LocationsUrlState } from "~/views/Inventory/LocationManagement/Locations"
import {
	DispatchDetailTab,
	DispatchDetailUrlState,
} from "~/views/Service/Dispatches/DispatchDetail"

export interface PartHistoryTabProps {
	part: InventoryPart
}

const PartHistoryTab: FC<PartHistoryTabProps> = ({ part }) => {
	const [queryParams, setQueryParams] = useState(defaultInventoryTransactionsQueryParams)
	const combinedParams = useMemo(
		() => ({ ...queryParams, part: part.id }),
		[part.id, queryParams]
	)

	const transactionsQuery = useInventoryTransactions({ params: combinedParams })
	const exportTransactions = useExportInventoryTransactions(combinedParams)

	const columns: Column<InventoryTransaction>[] = useMemo(() => {
		return [
			{
				Header: "Direction",
				accessor: "direction",
				disableSortBy: true,
				Cell: ({ row: { original } }: Cell<InventoryTransaction>) => {
					const { direction } = original
					let icon = <EmptyValueDash />

					if (direction === "transfer") icon = <Icon icon="exchange-alt" color="gray" />
					if (direction === "in") icon = <Icon icon="inbox-in" color="success" />
					if (direction === "out") icon = <Icon icon="inbox-out" color="error" />

					return (
						<Box mt={0.25} ml={0.25}>
							{icon}
						</Box>
					)
				},
			},
			{
				Header: `Date (${getTimezoneAbbreviation()})`,
				id: "transactionDate",
				accessor: (original) => formatDateTime(original.transactionDate),
			},
			{
				Header: "Part #",
				id: "part_PartNumber",
				accessor: "partNumber",
			},
			{
				Header: "Part name",
				accessor: "partDescription",
			},
			{
				Header: "Type",
				id: "transactionType_Description",
				accessor: "type",
			},
			{
				Header: "From location",
				id: "fromBin_Location_LocationCode",
				accessor: (original) => original.fromBin?.locationId,
				Cell: ({
					row: {
						original: { fromBin },
					},
				}: Cell<InventoryTransaction>) => {
					const text = `(${fromBin?.location.locationCode}) ${fromBin?.location.description}`

					return fromBin ?
							<Link
								newTab
								icon="external-link"
								to={`/inventory/locations${encodeUrlState<
									LocationTransactionsUrlState & LocationsUrlState
								>({
									tab: LocationsTab.Transactions,
									urlLocationId: fromBin.locationId.toString(),
									part: part.id,
								})}`}
							>
								{text}
							</Link>
						:	<EmptyValueDash />
				},
			},
			{
				Header: "From bin",
				id: "fromBin_Code",
				accessor: (original) => original.fromBin?.code,
				Cell: ({ row: { original } }: Cell<InventoryTransaction>) =>
					original.fromBin ? original.fromBin.code : <EmptyValueDash />,
			},
			{
				Header: "To location",
				id: "toBin_Location_LocationCode",
				accessor: (original) => original.toBin?.locationId,
				Cell: ({
					row: {
						original: { toBin },
					},
				}: Cell<InventoryTransaction>) => {
					const text = `(${toBin?.location.locationCode}) ${toBin?.location.description}`

					return toBin ?
							<Link
								newTab
								icon="external-link"
								to={`/inventory/locations${encodeUrlState<
									LocationsUrlState & LocationTransactionsUrlState
								>({
									tab: LocationsTab.Transactions,
									urlLocationId: toBin.locationId.toString(),
									part: part.id,
								})}`}
							>
								{text}
							</Link>
						:	<EmptyValueDash />
				},
			},
			{
				Header: "To bin",
				id: "toBin_Code",
				accessor: (original) => original.toBin?.code,
				Cell: ({ row: { original } }: Cell<InventoryTransaction>) =>
					original.toBin ? original.toBin.code : <EmptyValueDash />,
			},
			{
				Header: "Transaction Qty",
				accessor: "quantity",
			},
			{
				Header: "Created by",
				id: "createdBy_FirstName",
				accessor: (original) => original.createdBy.name,
				hiddenByDefault: true,
			},
			{
				Header: "Part Order #",
				accessor: "partOrderNumber",
				hiddenByDefault: true,
				disableSortBy: true,
			},
			{
				Header: "Work Order #",
				accessor: "workOrderNumber",
				hiddenByDefault: true,
				disableSortBy: true,
				Cell: ({ row: { original } }: Cell<InventoryTransaction>) => {
					return original.workOrderNumber && original.dispatchId ?
							<Link
								newTab
								icon="external-link"
								to={`/service/dispatches/${
									original.dispatchId
								}${encodeUrlState<DispatchDetailUrlState>({
									tab: DispatchDetailTab.WorkOrders,
								})}`}
							>
								{original.workOrderNumber}
							</Link>
						:	<EmptyValueDash />
				},
			},
			{
				Header: "Purchase Order #",
				accessor: "purchaseOrderId",
				hiddenByDefault: true,
				disableSortBy: true,
				Cell: ({ row: { original } }: Cell<InventoryTransaction>) => {
					return original.purchaseOrderId ?
							<Link
								newTab
								icon="external-link"
								to={`/purchase-orders/${original.purchaseOrderId}`}
							>
								{original.purchaseOrderId}
							</Link>
						:	<EmptyValueDash />
				},
			},
			{
				Header: "Comment",
				accessor: "comment",
				hiddenByDefault: true,
				disableSortBy: true,
				Cell: ({ row: { original } }: Cell<InventoryTransaction>) =>
					original.comment ? original.comment : <EmptyValueDash />,
			},
		]
	}, [part.id])

	return (
		<Table
			query={transactionsQuery}
			columns={columns}
			queryParamState={queryParams}
			setQueryParamState={setQueryParams}
			pinnedQueryFilters={[SearchQueryFilter]}
			dataExport={exportTransactions}
		/>
	)
}

export default PartHistoryTab

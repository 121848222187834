import { FC } from "react"

import { Cell, Column } from "react-table"

import {
	ErpDetailReportLine,
	ErpDetailReportQueryParams,
	useErpDetailReport,
	useExportErpDetailReport,
} from "@ncs/ncs-api"
import {
	formatCurrency,
	formatDate,
	getStartAndEndDateQueryParams,
	TimeAgo,
	Typify,
} from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	CustomerQueryFilter,
	EnterpriseQueryFilter,
	ErpStatusQueryFilter,
	Link,
	OrganizationQueryFilter,
	Table,
	TerritoryQueryFilter,
	TimeAgoQueryFilter,
	usePaginationUrlState,
	useUrlState,
} from "@ncs/web-legos"

export type ErpDetailReportUrlState = Typify<ErpDetailReportQueryParams>

export const ErpDetailReport: FC = () => {
	const [queryParams, { setUrlState: setQueryParams }] = useUrlState<ErpDetailReportUrlState>(
		initialErpDetailReportUrlState
	)
	const [pagination, setPagination] = usePaginationUrlState()

	const query = useErpDetailReport({
		params: { ...queryParams, ...pagination },
		manualPagination: true,
	})
	const dataExport = useExportErpDetailReport(queryParams)

	return (
		<AnimatedEntrance show>
			<Table
				query={query}
				columns={columns}
				queryParamState={queryParams}
				setQueryParamState={setQueryParams}
				pagination={pagination}
				setPagination={setPagination}
				dataExport={dataExport}
				pinnedQueryFilters={[
					TimeAgoQueryFilter,
					CustomerQueryFilter,
					OrganizationQueryFilter,
					TerritoryQueryFilter,
					EnterpriseQueryFilter,
					ErpStatusQueryFilter,
				]}
				filterResetValues={initialErpDetailReportUrlState}
				storeColumnVisibility="erp-detail-report-columns"
			/>
		</AnimatedEntrance>
	)
}

const columns: Column<ErpDetailReportLine>[] = [
	{
		Header: "ID",
		accessor: "id",
		disableSortBy: true,
		hiddenByDefault: true,
	},
	{
		Header: "Created",
		id: "createdDate",
		accessor: ({ createdDate }) => formatDate(createdDate, { formatInUtc: true }),
	},
	{
		Header: "Customer",
		id: "customer_CustomerNumber",
		accessor: ({ customer }) => `(${customer.customerNumber}) ${customer.name}`,
	},
	{
		Header: "Status",
		accessor: ({ erpStatus }) => erpStatus.description,
		disableSortBy: true,
	},
	{
		Header: "Total",
		id: "total",
		accessor: ({ total }) => formatCurrency(total),
	},
	{
		Header: "Expires",
		id: "expirationDate",
		accessor: ({ expirationDate }) => (expirationDate ? formatDate(expirationDate) : "-"),
		hiddenByDefault: true,
	},
	{
		Header: "Technician",
		id: "techNameEmployee_LastName",
		accessor: ({ techNameEmployee }) => techNameEmployee?.name || "-",
		hiddenByDefault: true,
	},
	{
		Header: "Dispatch",
		accessor: ({ dispatch }) => dispatch?.id,
		disableSortBy: true,
		Cell: ({ row: { original } }: Cell<ErpDetailReportLine>) => {
			return original.dispatch ?
					<Link
						newTab
						to={`/service/dispatches/${original.dispatch.id}`}
						icon="external-link"
					>
						Dispatch #{original.dispatch.dispatchNumber}
					</Link>
				:	"-"
		},
		hiddenByDefault: true,
	},
	{
		Header: "Part order",
		disableSortBy: true,
		accessor: ({ partOrder }) => partOrder?.id,
		Cell: ({ row: { original } }: Cell<ErpDetailReportLine>) => {
			return original.partOrder ?
					<Link newTab to={`/part-orders/${original.partOrder.id}`} icon="external-link">
						Part Order #{original.partOrder.orderId}
					</Link>
				:	"-"
		},
		hiddenByDefault: true,
	},
]

const initialErpDetailReportUrlState: ErpDetailReportUrlState = {
	ordering: null,
	organization: null,
	enterprise: null,
	territory: null,
	customer: null,
	startDate: getStartAndEndDateQueryParams(TimeAgo.DaysAgo30)[0],
	endDate: null,
	erpStatus: null,
}

import {
	patch,
	multipartPost,
	post,
	get,
	rsaaTypes,
	RSAA,
} from "@ncs/bricks/middleware/portalApiMiddleware"

export const LIST_MATERIALS = rsaaTypes("marketing/materials/list")
export const GET_MATERIAL = rsaaTypes("marketing/materials/get")
export const SEARCH_MATERIALS = rsaaTypes("marketing/materials/search")
export const DELETE_MATERIAL = rsaaTypes("marketing/material/delete")
export const GENERATE_MATERIAL_LINK = rsaaTypes("marketing/material/generate_link")
export const UPLOAD_FILE = rsaaTypes("marketing/material/post")

export const LIST_MATERIAL_CATEGORIES = rsaaTypes("marketing/categories/list")
export const GET_CATEGORY = rsaaTypes("marketing/category/get")
export const ADD_CATEGORY = rsaaTypes("marketing/category/post")
export const EDIT_CATEGORY = rsaaTypes("marketing/category/put")
export const DELETE_CATEGORY = rsaaTypes("marketing/category/delete")

export const PLACE_ORDER = rsaaTypes("marketing/orders/post")

export const listMaterials = (querystring_params) => ({
	[RSAA]: {
		api: get("/marketing/documents/", querystring_params),
		types: LIST_MATERIALS,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const getMaterial = (id) => ({
	[RSAA]: {
		api: get(`/marketing/documents/${id}/`),
		types: GET_MATERIAL,
	},
})

const searchMaterialsAPI = (searchText) => ({
	[RSAA]: {
		api: get("/marketing/documents/", {
			search: searchText,
			fields: "id,document_type,filename,extension,created_date,uploaded_by,is_deleted,category",
		}),
		types: SEARCH_MATERIALS,
	},
})

export const searchMaterials = (searchText) => {
	const debounced = (dispatch) => {
		dispatch(searchMaterialsAPI(searchText))
	}

	debounced.meta = {
		debounce: {
			time: 325,
			key: SEARCH_MATERIALS,
		},
	}

	return debounced
}

export const deleteMaterial = (documentId) => ({
	[RSAA]: {
		api: patch(`/marketing/documents/${documentId}/`, { is_deleted: true }),
		types: DELETE_MATERIAL,
	},
})

export const generateMaterialLink = (documentId) => ({
	[RSAA]: {
		api: post(`/marketing/documents/${documentId}/generate_link/`),
		types: GENERATE_MATERIAL_LINK,
	},
})

export const listMaterialCategories = () => ({
	[RSAA]: {
		api: get("/marketing/categories/"),
		types: LIST_MATERIAL_CATEGORIES,
	},
})

export const doFileUpload = (data) => {
	var formData = new FormData()
	formData.append("category_id", data.category_id)
	formData.append("title", data.title)
	formData.append("file[0]", data.file, data.file.name)
	if (data.expirationDate !== null) {
		formData.append("expiration_date", data.expirationDate)
	}
	formData.append("effective_date", data.effectiveDate)
	formData.append("cost", data.cost)
	formData.append("is_orderable", data.isOrderable)
	return {
		[RSAA]: {
			api: multipartPost("/marketing/documents/", formData),
			types: UPLOAD_FILE,
			multipart: true,
		},
	}
}

export const getCategory = (id) => ({
	[RSAA]: {
		api: get(`/marketing/categories/${id}/`),
		types: GET_CATEGORY,
	},
})

export const addCategory = (parent_id, name) => ({
	[RSAA]: {
		api: post("/marketing/categories/", { parent_id, name, is_deleted: false }),
		types: ADD_CATEGORY,
	},
})

export const editCategory = (id, name) => ({
	[RSAA]: {
		api: patch(`/marketing/categories/${id}/`, { name }),
		types: EDIT_CATEGORY,
	},
})

export const deleteCategory = (id) => ({
	[RSAA]: {
		api: patch(`/marketing/categories/${id}/`, { is_deleted: true }),
		types: DELETE_CATEGORY,
	},
})

export const placeOrder = (data) => ({
	[RSAA]: {
		api: post("/marketing/orders/", data),
		types: PLACE_ORDER,
	},
})

const initialState = {
	isLoadingMaterials: false,
	isLoadingMaterialCategories: false,
	isSearchingMaterials: false,
	isLoadingSingleMaterial: false,
	materials: [],
	item: null,
	materialsPages: 1,
	materialsRequestTimestamp: null,
	materialCategories: [],
	isGettingMaterialCategory: false,
	materialCategory: null,
	error: null,
	cart: {},
}

const marketing = (state = initialState, action) => {
	switch (action.type) {
		case LIST_MATERIALS.request:
			return {
				...state,
				isLoadingMaterials: true,
			}
		case LIST_MATERIALS.success:
			// bail if we would be overwriting newer data
			if (state.materialsRequestTimestamp > action.meta.timestamp) return state

			return {
				...state,
				isLoadingMaterials: false,
				materials: action.payload,
				materialsPages: action.meta.pages,
				materialsRequestTimestamp: action.meta.timestamp,
				error: null,
			}
		case LIST_MATERIALS.failure:
			return {
				...state,
				isLoadingMaterials: false,
				error: action.error,
			}

		case GET_MATERIAL.request:
			return {
				...state,
				isLoadingSingleMaterial: true,
			}
		case GET_MATERIAL.success:
			return {
				...state,
				isLoadingSingleMaterial: false,
				item: action.payload,
				error: null,
			}
		case GET_MATERIAL.failure:
			return {
				...state,
				isLoadingSingleMaterial: false,
				error: action.error,
			}

		case SEARCH_MATERIALS.request:
			return {
				...state,
				isSearchingMaterials: true,
			}
		case SEARCH_MATERIALS.success:
			return {
				...state,
				isSearchingMaterials: false,
				materialSearchResults: action.payload,
				error: null,
			}
		case SEARCH_MATERIALS.failure:
			return {
				...state,
				isSearchingMaterials: false,
				error: action.error,
			}

		case GENERATE_MATERIAL_LINK.request:
			return {
				...state,
				isGeneratingLink: true,
			}
		case GENERATE_MATERIAL_LINK.success:
			return {
				...state,
				isGeneratingLink: false,
				materialDocumentLink: action.payload.presign_url,
			}

		case LIST_MATERIAL_CATEGORIES.request:
			return {
				...state,
				isLoadingMaterialCategories: true,
			}
		case LIST_MATERIAL_CATEGORIES.success:
			return {
				...state,
				isLoadingMaterialCategories: false,
				materialCategories: action.payload,
				error: null,
			}
		case LIST_MATERIAL_CATEGORIES.failure:
			return {
				...state,
				isLoadingMaterialCategories: false,
				error: action.error,
			}

		case UPLOAD_FILE.request:
			return {
				...state,
				isLoadingPresignedUploadUrl: true,
			}
		case UPLOAD_FILE.success:
			return {
				...state,
				isLoadingPresignedUploadUrl: false,
			}
		case UPLOAD_FILE.failure:
			return {
				...state,
				isLoadingPresignedUploadUrl: false,
				error: action.error,
			}

		case GET_CATEGORY.request:
			return {
				...state,
				isGettingMaterialCategory: true,
				materialCategory: null,
			}
		case GET_CATEGORY.success:
			return {
				...state,
				isGettingMaterialCategory: false,
				materialCategory: action.payload,
			}
		case GET_CATEGORY.failure:
			return {
				...state,
				isGettingMaterialCategory: false,
				error: action.error,
			}

		case DELETE_MATERIAL.request:
		case ADD_CATEGORY.request:
		case EDIT_CATEGORY.request:
		case DELETE_CATEGORY.request:
			return {
				...state,
				isSaving: true,
			}
		case DELETE_MATERIAL.success:
		case ADD_CATEGORY.success:
		case EDIT_CATEGORY.success:
		case DELETE_CATEGORY.success:
			return {
				...state,
				isSaving: false,
				error: null,
			}
		case DELETE_MATERIAL.failure:
		case ADD_CATEGORY.failure:
		case EDIT_CATEGORY.failure:
		case DELETE_CATEGORY.failure:
			return {
				...state,
				isSaving: false,
				error: action.error,
			}

		case PLACE_ORDER.request:
			return {
				...state,
				isPlacingOrder: true,
			}
		case PLACE_ORDER.success:
			return {
				...state,
				isPlacingOrder: false,
			}
		case PLACE_ORDER.failure:
			return {
				...state,
				isPlacingOrder: false,
				error: action.error,
			}

		default:
			return state
	}
}
export default marketing

import { FC } from "react"

import { Dispatch } from "@ncs/ncs-api"
import {
	formatCurrency,
	formatDateTime,
	getTimezoneAbbreviation,
	tryToFormatPhone,
} from "@ncs/ts-utils"
import {
	Button,
	cssMixins,
	EmptyValueDash,
	ExtendableModalProps,
	GridContainer,
	GridItem,
	Label,
	LabeledData,
	Modal,
	Paragraph,
} from "@ncs/web-legos"

export interface DispatchPreviewModalProps extends ExtendableModalProps {
	dispatch: Dispatch
}

export const DispatchPreviewModal: FC<DispatchPreviewModalProps> = ({ dispatch, ...rest }) => {
	return (
		<Modal
			{...rest}
			title={`Dispatch #${dispatch.dispatchNumber}`}
			titleDetail={`(${dispatch.customer.customerNumber}) ${dispatch.customer.name}`}
		>
			<GridContainer>
				<GridItem xs={12} sm={6}>
					<LabeledData label="Dispatch status">{dispatch.status}</LabeledData>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<LabeledData label="Invoice status">
						{dispatch.invoice?.status || <EmptyValueDash />}
					</LabeledData>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<LabeledData label={`Created (${getTimezoneAbbreviation()})`}>
						{dispatch.createdDate ?
							formatDateTime(dispatch.createdDate)
						:	<EmptyValueDash />}
					</LabeledData>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<LabeledData label="Caller">
						{dispatch.callerName || <EmptyValueDash />}
					</LabeledData>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<LabeledData label="Callback #">
						{tryToFormatPhone(dispatch.callBackNumber) || <EmptyValueDash />}
					</LabeledData>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<LabeledData label="PO / Ticket">
						{dispatch.poTicket || <EmptyValueDash />}
					</LabeledData>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<LabeledData label="Priority">{dispatch.priority}</LabeledData>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<LabeledData label="Call type">{dispatch.callType}</LabeledData>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<LabeledData label="Tech assigned">
						{dispatch.assignedEmployee?.name || <EmptyValueDash />}
					</LabeledData>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<LabeledData label="Not to exceed $">
						{dispatch.billNotToExceed != null ?
							formatCurrency(dispatch.billNotToExceed)
						:	<EmptyValueDash />}
					</LabeledData>
				</GridItem>
				<GridItem xs={12}>
					<Label>Description</Label>
					<Paragraph css={cssMixins.preserveLineBreaks}>
						{dispatch.symptoms || <EmptyValueDash />}
					</Paragraph>
				</GridItem>
			</GridContainer>

			<Button
				icon="external-link"
				onClick={() => window.open(`/service/dispatches/${dispatch.id}`)}
				containerProps={{ mt: 2 }}
			>
				View dispatch
			</Button>
		</Modal>
	)
}

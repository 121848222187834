import { useEffect, useRef, useState } from "react"

import isEqual from "lodash/isEqual"
import { useQueryClient } from "react-query"

import { unpythonify } from "@ncs/ts-utils"

import { apiClient, buildUrl, usePrevious } from "../util"
import {
	ApiDelayedRequest,
	ApiDelayedRequestConfig,
	DelayedRequestResponse,
	isDelayedRequestResponse,
} from "./types"
import { useGetRequest } from "./use-get-request"

const defaultConfig: ApiDelayedRequestConfig = {
	enabled: true,
	staleTime: 1000 * 60 * 5, // 5 minutes
	maximumAttempts: 200,
	retryInterval: 5000, // Every 5 seconds
}

export const useDelayedRequest = <ResponseData, Params>(
	endpoint: string | string[],
	params: Params,
	config?: Partial<ApiDelayedRequestConfig>
): ApiDelayedRequest<ResponseData> => {
	const { staleTime, maximumAttempts, retryInterval, enabled } = {
		...defaultConfig,
		...config,
	}

	const queryClient = useQueryClient()

	const [isLoadingDelayedData, setIsLoadingDelayedData] = useState(false)
	const [recordId, setRecordId] = useState<number | null>(null)
	const delayedDataIsReceived = useRef(false)
	const attemptsRemaining = useRef(maximumAttempts)

	const [data, isLoading, { queryKey }] = useGetRequest<
		ResponseData | DelayedRequestResponse | undefined,
		Params
	>(endpoint, {
		params,
		queryConfig: {
			enabled,
			staleTime,
			onSuccess: (responseData: unknown) => {
				if (isDelayedRequestResponse(responseData)) {
					setRecordId(responseData.delayedResponseId)
					delayedDataIsReceived.current = false
				}
			},
		},
	})

	useEffect(() => {
		let timer: NodeJS.Timeout | null = null

		const fetchData = async (id: number): Promise<void> => {
			setIsLoadingDelayedData(true)
			attemptsRemaining.current = attemptsRemaining.current - 1
			const result = await apiClient.get(buildUrl(`/reports/delayed_response/${id}`))

			if (result.data) {
				// Maybe add a try/catch around this parse? Not sure what I would do with the error though.
				const parsed = JSON.parse(result.data)
				queryClient.setQueryData(queryKey, unpythonify(parsed))

				setIsLoadingDelayedData(false)
				delayedDataIsReceived.current = true
				attemptsRemaining.current = maximumAttempts
				if (timer) clearTimeout(timer)
			} else {
				if (attemptsRemaining.current > 0) {
					timer = setTimeout(() => {
						if (delayedDataIsReceived.current === false) {
							void fetchData(id)
						}
					}, retryInterval)
				}
			}
		}

		if (
			recordId &&
			isLoadingDelayedData === false &&
			delayedDataIsReceived.current === false &&
			attemptsRemaining.current > 0
		) {
			void fetchData(recordId)
		}

		return () => {
			if (timer) clearTimeout(timer)
		}
	}, [recordId, queryClient, queryKey, retryInterval, isLoadingDelayedData, maximumAttempts])

	const prevQueryKey = usePrevious(queryKey)
	useEffect(() => {
		if (isEqual(queryKey, prevQueryKey) === false) {
			setRecordId(null)
			delayedDataIsReceived.current = false
			attemptsRemaining.current = maximumAttempts
		}
	}, [queryKey, prevQueryKey, maximumAttempts])

	return [isDelayedRequestResponse(data) ? undefined : data, isLoading || isLoadingDelayedData]
}

import { FC, useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { Controller, useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"
import { z } from "zod"

import { makeApiErrorMessage, useCreateUserProfile, useRoleGroups } from "@ncs/ncs-api"
import { titleCase } from "@ncs/ts-utils"
import {
	ApplicationGroupSelector,
	Callout,
	CheckboxGroupFormField,
	ExtendableModalProps,
	GridContainer,
	GridItem,
	HeadingDivider,
	Label,
	Modal,
	Paragraph,
	ReportGroupSelector,
	TextInputFormField,
	useToast,
} from "@ncs/web-legos"

export interface NewUserModalProps extends ExtendableModalProps {}

export const NewUserModal: FC<NewUserModalProps> = ({ ...rest }) => {
	const history = useHistory()
	const { makeSuccessToast } = useToast()
	const [errorText, setErrorText] = useState<string | null>(null)
	const [isSaving, setIsSaving] = useState(false)

	const create = useCreateUserProfile()
	const [roleGroups] = useRoleGroups()

	const {
		control,
		handleSubmit,
		formState: { isValid, submitCount },
		reset: formReset,
	} = useForm<UserForm>({
		resolver: zodResolver(UserFormSchema),
		defaultValues: userFormDefaultValues,
	})

	const onCreate = async (formData: UserForm) => {
		try {
			setIsSaving(true)
			const { data } = await create({
				...formData,
				isCustomer: false,
				effectiveDate: new Date().toISOString(),
			})
			makeSuccessToast("New user created")
			history.push(`/user-management/${data._id}`)
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const reset = () => {
		formReset()
		setErrorText(null)
		setIsSaving(false)
	}

	return (
		<Modal
			onOpen={reset}
			rightButtons={{
				buttonText: "Create User",
				onClick: handleSubmit(onCreate),
				isLoading: isSaving,
				disabled: !isValid && submitCount > 0,
			}}
			{...rest}
			title="Create New User"
			errorText={errorText}
			maxWidth="md"
		>
			<HeadingDivider headingVariant="h4" mb={0.5}>
				User Details
			</HeadingDivider>
			<GridContainer rowGap={0} pl={1}>
				<GridItem xs={12} sm={6}>
					<TextInputFormField
						control={control}
						name="firstName"
						label="First name"
						returnEmptyString
					/>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<TextInputFormField
						control={control}
						name="lastName"
						label="Last name"
						returnEmptyString
					/>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<TextInputFormField control={control} name="email" returnEmptyString />
				</GridItem>
				<GridItem xs={12} sm={6}>
					<TextInputFormField
						control={control}
						name="mobile"
						label="Cell phone (optional)"
						placeholder="Cell phone..."
					/>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<TextInputFormField
						control={control}
						name="employeeNumber"
						label="Employee number (optional)"
						placeholder="Employee number..."
					/>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<TextInputFormField
						control={control}
						name="verisaeUsername"
						label="Verisae username (optional)"
						placeholder="Verisae username..."
					/>
				</GridItem>
			</GridContainer>

			<HeadingDivider headingVariant="h4" mb={0.5}>
				Permissions
			</HeadingDivider>
			<GridContainer rowGap={0} pl={1}>
				<GridItem sm={12} md={6}>
					<Controller
						control={control}
						name="applicationAssignmentId"
						render={({
							field: { value, onChange: formOnChange },
							fieldState: { error },
						}) => {
							return (
								<ApplicationGroupSelector
									label="Application group (optional)"
									value={value}
									onChange={(newValue) => formOnChange(newValue)}
									error={error?.message}
									fillContainer
								/>
							)
						}}
					/>
				</GridItem>
				<GridItem sm={12} md={6}>
					<Controller
						control={control}
						name="reportAssignmentId"
						render={({
							field: { value, onChange: formOnChange },
							fieldState: { error },
						}) => {
							return (
								<ReportGroupSelector
									label="Report group (optional)"
									value={value}
									onChange={(newValue) => formOnChange(newValue)}
									error={error?.message}
									fillContainer
								/>
							)
						}}
					/>
				</GridItem>
				<GridItem sm={12} md={6}>
					<Label>User roles</Label>
					<CheckboxGroupFormField
						control={control}
						name="groupIds"
						rows={roleGroups ?? []}
						valueAccessor="id"
						labelAccessor={(row) => titleCase(row.code.split("_").join(" "))}
					/>
				</GridItem>
				<GridItem sm={12} md={6} pt={2}>
					<Callout icon="info-circle" variant="info">
						<Paragraph small>
							Additional permission customization can be set up once new user is
							created
						</Paragraph>
					</Callout>
				</GridItem>
			</GridContainer>
		</Modal>
	)
}

const UserFormSchema = z.object({
	employeeNumber: z.string().nullable(),
	firstName: z.string().min(1, "Required").max(50),
	lastName: z.string().min(1, "Required").max(50),
	email: z.string().min(1, "Required").max(255).email(),
	mobile: z.string().max(20).nullable(),
	verisaeUsername: z.string().max(255).nullable(),
	applicationAssignmentId: z.string().nullable(),
	reportAssignmentId: z.string().nullable(),
	groupIds: z.array(z.string()),
})

export type UserForm = z.infer<typeof UserFormSchema>

const userFormDefaultValues: UserForm = {
	employeeNumber: null,
	firstName: "",
	lastName: "",
	email: "",
	mobile: null,
	verisaeUsername: null,
	applicationAssignmentId: null,
	reportAssignmentId: null,
	groupIds: [],
}

import { Dispatch, FC, SetStateAction, useMemo, useState } from "react"

import { Column } from "react-table"

import { Enterprise, Organization, useOrganizations, useUpdateEnterprise } from "@ncs/ncs-api"
import {
	displayDate,
	displayNumber,
	getTimezoneAbbreviation,
	noFalsy,
	yesOrNo,
} from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	Box,
	Button,
	Callout,
	ConfirmationModal,
	ConfirmationModalConfig,
	EditBooleanModal,
	EditBooleanModalProps,
	EditStringModal,
	EditStringModalProps,
	encodeUrlState,
	Heading,
	HeadingDivider,
	Icon,
	IconButton,
	Label,
	Paragraph,
	ReactTableSortType,
	Table,
	ThrottledTextInput,
	useSetUrlState,
	useToast,
} from "@ncs/web-legos"

import {
	EnterprisesAndOrganizationsTab,
	EnterprisesAndOrganizationsUrlState,
} from "../../../EnterprisesAndOrganizations"
import { OrganizationsTabUrlState } from "../../organizations-tab"
import { ReassignEnterpriseModal } from "../../reassign-enterprise-modal"
import { EnterprisesTabUrlState } from "../EnterprisesTab"
import { AddOrganizationToEnterpriseModal } from "./AddOrganizationToEnterpriseModal"

export interface SelectedEnterpriseProps {
	enterprise: Enterprise
	setShowCreateModal: Dispatch<SetStateAction<boolean>>
}

export const SelectedEnterprise: FC<SelectedEnterpriseProps> = ({
	enterprise,
	setShowCreateModal,
}) => {
	const { makeSuccessToast } = useToast()
	const [editStringModalConfig, setEditStringModalConfig] =
		useState<EditStringModalProps | null>(null)
	const [editBooleanModalConfig, setEditBooleanModalConfig] =
		useState<EditBooleanModalProps | null>(null)
	const [search, setSearch] = useState<string | null>(null)
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)
	const [showAddOrgModal, setShowAddOrgModal] = useState(false)
	const [reassignModalOrg, setReassignModalOrg] = useState<Organization | null>(null)
	const setUrlState = useSetUrlState<
		EnterprisesAndOrganizationsUrlState & OrganizationsTabUrlState & EnterprisesTabUrlState
	>()

	const [organizations, organizationsLoading] = useOrganizations({
		params: { enterprise: enterprise.id },
	})
	const updateEnterprise = useUpdateEnterprise()

	const editName = () => {
		setEditStringModalConfig({
			initialValue: enterprise.name,
			allowEmpty: false,
			title: "Edit Enterprise Name",
			label: "Name",
			onClose: () => setEditStringModalConfig(null),
			onSave: async (newValue) => {
				if (newValue) {
					await updateEnterprise({
						id: enterprise.id,
						updates: {
							name: newValue,
						},
					})
				}
			},
		})
	}

	const editStatus = () => {
		setEditBooleanModalConfig({
			initialSelection: enterprise.isActive,
			description:
				"Set this enterprise's status. An inactive enterprise will be hidden in other lists of enterprises throughout this site.",
			noText: "Inactive",
			yesText: "Active (default)",
			title: "Enterprise Status",
			onClose: () => setEditBooleanModalConfig(null),
			onSave: async (newValue) => {
				await updateEnterprise({
					id: enterprise.id,
					updates: { isActive: newValue },
				})
				makeSuccessToast("Enterprise updated")
			},
		})
	}

	const filteredOrganizations = useMemo(() => {
		const searchChunks = noFalsy(search?.trim().toUpperCase().split(" ") ?? [])

		return (organizations ?? []).filter((org) => {
			if (!search) return true

			const searchable = `${org.name}${org.id}`.toUpperCase()

			return searchChunks.every((chunk) => searchable.includes(chunk))
		})
	}, [organizations, search])

	return (
		<>
			<AnimatedEntrance show>
				<Box display="flex" justifyContent="space-between" mb={3}>
					<Button
						icon="long-arrow-left"
						onClick={() =>
							setUrlState((prev) => ({
								...prev,
								enterpriseId: null,
							}))
						}
					>
						All enterprises
					</Button>
					<Button icon="plus" onClick={() => setShowCreateModal(true)}>
						Create enterprise
					</Button>
				</Box>

				<Callout display="block" variant="info">
					<Label>Enterprise name</Label>
					<Heading variant="h1">
						{enterprise.name}
						<IconButton icon="pencil" color="primary" onClick={editName} />
					</Heading>

					<Box display="flex" mt={1} columnGap={3}>
						<div>
							<Label>Status</Label>
							<Box display="flex" alignItems="center" gap={0.5} mt={-0.35}>
								{enterprise.isActive ?
									<>
										<Icon icon="check" color="gray" />{" "}
										<Paragraph>Active</Paragraph>
									</>
								:	<Paragraph>Not active</Paragraph>}
								<IconButton icon="pencil" color="primary" onClick={editStatus} />
							</Box>
						</div>
						<div>
							<Label>Modified date ({getTimezoneAbbreviation()})</Label>
							<Paragraph>{displayDate(enterprise.modifiedOn)}</Paragraph>
						</div>
						<div>
							<Label>Created date ({getTimezoneAbbreviation()})</Label>
							<Paragraph>{displayDate(enterprise.createdOn)}</Paragraph>
						</div>
					</Box>
				</Callout>

				<HeadingDivider headingVariant="h4" bold mt={4} mb={2}>
					Organizations Belonging To Enterprise ({enterprise.organizationsCount})
				</HeadingDivider>
				<Box
					display="flex"
					justifyContent="space-between"
					gap={1}
					flexWrap="wrap"
					alignItems="flex-end"
					mb={2}
				>
					<Button
						icon="plus-circle"
						disabled={organizationsLoading}
						variant="secondary-cta"
						onClick={() => setShowAddOrgModal(true)}
					>
						Add organization
					</Button>
					<ThrottledTextInput
						value={search}
						onChange={setSearch}
						icon="search"
						placeholder="Search..."
						fillContainer={false}
						mb={0}
					/>
				</Box>

				<Table
					data={filteredOrganizations}
					columns={columns}
					isLoading={organizationsLoading}
					noDataText={
						search ?
							`No organizations match "${search}"`
						:	"No organizations found under this enterprise"
					}
					rowMenu={[
						{
							label: "View in Organizations",
							iconName: "external-link",
							onClick: ({ original }) =>
								window.open(
									`/customers/enterprises-and-organizations${encodeUrlState<
										EnterprisesAndOrganizationsUrlState &
											OrganizationsTabUrlState
									>({
										tab: EnterprisesAndOrganizationsTab.Organizations,
										organizationId: original.id,
									})}`
								),
						},
						{
							label: "Reassign to another enterprise",
							iconName: "shuffle",
							onClick: ({ original }) => setReassignModalOrg(original),
						},
					]}
				/>
			</AnimatedEntrance>

			{showAddOrgModal && (
				<AddOrganizationToEnterpriseModal
					enterprise={enterprise}
					onClose={() => setShowAddOrgModal(false)}
				/>
			)}
			{!!reassignModalOrg && (
				<ReassignEnterpriseModal
					organization={reassignModalOrg}
					onClose={() => setReassignModalOrg(null)}
				/>
			)}
			{!!editStringModalConfig && <EditStringModal {...editStringModalConfig} />}
			{!!editBooleanModalConfig && <EditBooleanModal {...editBooleanModalConfig} />}
			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</>
	)
}

const columns: Column<Organization>[] = [
	{
		Header: "Name",
		accessor: ({ name }) => name,
	},
	{
		Header: "Customer sites count",
		sortType: ReactTableSortType.Number,
		accessor: ({ customersCount }) => displayNumber(customersCount),
	},
	{
		Header: `Created date (${getTimezoneAbbreviation()})`,
		hiddenByDefault: true,
		disableSortBy: true,
		accessor: ({ createdOn }) => displayDate(createdOn),
	},
	{
		Header: `Modified date (${getTimezoneAbbreviation()})`,
		hiddenByDefault: true,
		disableSortBy: true,
		accessor: ({ modifiedOn }) => displayDate(modifiedOn),
	},
	{
		Header: "Is active?",
		accessor: ({ isActive }) => yesOrNo(isActive),
	},
]

import { Dispatch, ReactElement, SetStateAction } from "react"

import { SelectBoolean } from "@ncs/web-legos"

const param = "includeZeroDollar"

export interface ZeroDollarParamState {
	[param]: boolean | null
}

export interface ZeroDollarQueryFilterProps<QueryParamState extends ZeroDollarParamState> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

export const ZeroDollarQueryFilter = <QueryParamState extends ZeroDollarParamState>({
	queryParamState,
	setQueryParamState,
}: ZeroDollarQueryFilterProps<QueryParamState>): ReactElement => {
	return (
		<SelectBoolean
			value={queryParamState[param] === true}
			yesText="Include $0 invoices"
			noText="Exclude $0 invoices"
			showNoSelectionOption={false}
			noFirst
			fillContainer
			label="$0 invoices"
			onChange={(newValue) =>
				setQueryParamState((prev) => {
					const newState: ZeroDollarParamState = {
						[param]: newValue,
					}

					return {
						...prev,
						...newState,
					}
				})
			}
		/>
	)
}

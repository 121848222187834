import { FC } from "react"

import { Card, Tabs, useUrlState } from "@ncs/web-legos"

import { ManufacturersTab, ModelsTab, VintagesTab } from "./components"

export type EquipmentUrlState = {
	tab: EquipmentTab | null
}

export enum EquipmentTab {
	Vintages = "Vintages",
	Models = "Models",
	Manufacturers = "Manufacturers",
}

export const Equipment: FC = () => {
	const [{ tab }, { updateUrlValue }] = useUrlState<EquipmentUrlState>({
		tab: EquipmentTab.Vintages,
	})

	return (
		<Card heading="Customer Equipment Management" headingIcon="car-wash">
			<Tabs
				currentTab={tab}
				onChange={(newTab) => updateUrlValue("tab", newTab)}
				panels={[
					{
						navLabel: EquipmentTab.Vintages,
						component: <VintagesTab />,
					},
					{
						navLabel: EquipmentTab.Models,
						component: <ModelsTab />,
					},
					{
						navLabel: EquipmentTab.Manufacturers,
						component: <ManufacturersTab />,
					},
				]}
			/>
		</Card>
	)
}

import { FC } from "react"

import { FreightClass, FreightClassId, useFreightClasses } from "@ncs/ncs-api"
import { ExtendableSelectProps, Select } from "@ncs/web-legos"

export interface FreightClassSelectorProps
	extends ExtendableSelectProps<FreightClass, FreightClassId | null> {
	classIdsToHide?: FreightClassId[]
}

export const FreightClassSelector: FC<FreightClassSelectorProps> = ({
	classIdsToHide,
	...rest
}) => {
	const [classes, isLoading] = useFreightClasses()

	return (
		<Select
			label="Freight class"
			isLoading={isLoading}
			{...rest}
			onChange={(value, option) => rest.onChange(option?.id ?? null)}
			valueAccessor="id"
			textAccessor="description"
			options={(classes ?? []).filter((c) => !classIdsToHide?.includes(c.id))}
			disableNoSelectionOption={false}
		/>
	)
}

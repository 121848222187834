import { FC, useState } from "react"

import { Contract, makeApiErrorMessage, useUpdateOrganizationContract } from "@ncs/ncs-api"
import {
	ContractCoverageTypeSelector,
	ExtendableModalProps,
	Modal,
	useToast,
} from "@ncs/web-legos"

export interface EditSiteCoverageModalProps extends ExtendableModalProps {
	contract: Contract
}

export const EditSiteCoverageModal: FC<EditSiteCoverageModalProps> = ({ contract, ...rest }) => {
	const { makeSuccessToast } = useToast()
	const [selection, setSelection] = useState<string | null>(
		contract.coverageType?.id.toString() ?? null
	)
	const [errorText, setErrorText] = useState<string | null>(null)
	const [isSaving, setIsSaving] = useState(false)

	const updateContract = useUpdateOrganizationContract()

	const handleSave = async () => {
		try {
			setIsSaving(true)
			await updateContract({
				updates: {
					contract: contract.id,
					coverageTypeId: selection,
				},
			})
			makeSuccessToast("Contract updated")
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	return (
		<Modal
			{...rest}
			title="Site Coverage"
			errorText={errorText}
			rightButtons={{
				buttonText: "Save",
				onClick: handleSave,
				isLoading: isSaving,
			}}
		>
			<ContractCoverageTypeSelector value={selection} onChange={setSelection} />
		</Modal>
	)
}

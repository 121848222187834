import { FC } from "react"

import {
	CustomerAccessorial,
	CustomerDetail,
	LineItemType,
	makeApiErrorMessage,
	useCustomerAccessorials,
	useUpdateCustomerAccessorial,
} from "@ncs/ncs-api"
import { formatCurrency } from "@ncs/ts-utils"
import {
	Box,
	Checkbox,
	GridContainer,
	GridItem,
	LoadingSpinner,
	Paragraph,
	Pipe,
	useIsSaving,
	useToast,
} from "@ncs/web-legos"

export interface AccessorialsProps {
	customer: CustomerDetail
}

export const Accessorials: FC<AccessorialsProps> = ({ customer }) => {
	const { makeSuccessToast, makeErrorToast } = useToast()
	const { isSaving, setSaving, endSaving } = useIsSaving<LineItemType>()

	const [accessorials, accessorialsLoading] = useCustomerAccessorials(customer.id)

	const updateAccessorial = useUpdateCustomerAccessorial()

	const handleChange = async (accessorial: CustomerAccessorial) => {
		try {
			setSaving(accessorial.lineItemTypeId)
			await updateAccessorial({
				customerId: customer.id,
				default: !accessorial.default,
				lineItemTypeId: accessorial.lineItemTypeId,
			})
			makeSuccessToast("Accessorial updated")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		} finally {
			endSaving(accessorial.lineItemTypeId)
		}
	}

	if (accessorialsLoading) {
		return <LoadingSpinner />
	}

	return (
		<>
			<Paragraph mb={0.5}>
				Which accessorial line items should be added to an order by default when the
				customer is purchasing CHM or DTL products?
			</Paragraph>

			<GridContainer columnGap={1} rowGap={0.5} flexWrap="wrap">
				{accessorials?.map((accessorial) => {
					return (
						<GridItem key={accessorial.lineItemTypeId} xs={12} sm={6} md={4}>
							<Checkbox
								value={accessorial.default}
								onChange={() => handleChange(accessorial)}
								label={
									<Box d="flex" flexDirection="column" mt={0.75}>
										<span>{accessorial.lineItemDescription}</span>
										<span>
											{formatCurrency(accessorial.rate)}
											{accessorial.freeFreightExcluded && (
												<>
													<Pipe />
													<span>Free freight excluded</span>
												</>
											)}
										</span>
									</Box>
								}
								isLoading={isSaving(accessorial.lineItemTypeId)}
								mb={0}
							/>
						</GridItem>
					)
				})}
			</GridContainer>
		</>
	)
}

import isEqual from "lodash/isEqual"
import {
	patch,
	multipartPost,
	multipartPatch,
	post,
	get,
	rsaaTypes,
	RSAA,
} from "@ncs/bricks/middleware/portalApiMiddleware"

export const LIST_PARTS = rsaaTypes("point_of_sale/parts/list")
export const GET_PART = rsaaTypes("point_of_sale/parts/get")
export const SEARCH_PARTS = rsaaTypes("point_of_sale/parts/search")
export const CREATE_PART = rsaaTypes("point_of_sale/part/post")
export const PATCH_PART = rsaaTypes("point_of_sale/part/patch")
export const UPDATE_PART_IMAGE = rsaaTypes("point_of_sale/part/patchImage")
export const DELETE_PART = rsaaTypes("point_of_sale/part/delete")
export const ACTIVATE_PART = rsaaTypes("point_of_sale/part/activate")

export const LIST_CATEGORIES = rsaaTypes("point_of_sale/categories/list")
export const GET_CATEGORY = rsaaTypes("point_of_sale/category/get")
export const ADD_CATEGORY = rsaaTypes("point_of_sale/category/post")
export const EDIT_CATEGORY = rsaaTypes("point_of_sale/category/put")
export const DELETE_CATEGORY = rsaaTypes("point_of_sale/category/delete")

export const LIST_SYSTEMS = rsaaTypes("point_of_sale/systems/list")
export const LIST_MANUFACTURERS = rsaaTypes("point_of_sale/manufacturers/list")

export const LIST_CUSTOMER_PARTS = rsaaTypes("point_of_sale/customer-parts/list")
export const GET_CUSTOMER_PART = rsaaTypes("point_of_sale/customer-parts/get")
export const SEARCH_CUSTOMER_PARTS = rsaaTypes("point_of_sale/customer-parts/search")

export const listParts = (querystring_params) => ({
	[RSAA]: {
		api: get("/point_of_sale/parts/", querystring_params),
		types: LIST_PARTS,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const getPart = (id) => ({
	[RSAA]: {
		api: get(`/point_of_sale/parts/${id}/`),
		types: GET_PART,
	},
})

const searchPartsAPI = (searchText) => ({
	[RSAA]: {
		api: get("/point_of_sale/parts/", {
			search: searchText,
		}),
		types: SEARCH_PARTS,
	},
})

export const searchParts = (searchText) => {
	const debounced = (dispatch) => {
		dispatch(searchPartsAPI(searchText))
	}

	debounced.meta = {
		debounce: {
			time: 325,
			key: SEARCH_PARTS,
		},
	}

	return debounced
}

export const createPart = (data, multipart) => {
	// FileInput handles attaching file to the body
	const parsed = {
		...data,
		categories: (data.categories ?? []).map((x) => x._id).join(","),
		vintages: (data.vintages ?? []).map((x) => x._id).join(","),
	}
	if (multipart === true) {
		return {
			[RSAA]: {
				api: multipartPost("/point_of_sale/parts/", parsed),
				types: CREATE_PART,
				multipart: true,
			},
		}
	}
	return {
		[RSAA]: {
			api: post("/point_of_sale/parts/", parsed),
			types: CREATE_PART,
		},
	}
}

export const patchPart = (id, data) => ({
	[RSAA]: {
		api: patch(`/point_of_sale/parts/${id}/`, data),
		types: PATCH_PART,
	},
})

export const updatePartImage = (id) => {
	// FileInput handles attaching file to the body
	return {
		[RSAA]: {
			api: multipartPatch(`/point_of_sale/parts/${id}/`),
			types: UPDATE_PART_IMAGE,
			multipart: true,
		},
	}
}

export const deletePart = (partId) => ({
	[RSAA]: {
		api: patch(`/point_of_sale/parts/${partId}/`, { is_active: false }),
		types: DELETE_PART,
	},
})

export const activatePart = (partId) => ({
	[RSAA]: {
		api: patch(`/point_of_sale/parts/${partId}/`, { is_active: true }),
		types: ACTIVATE_PART,
	},
})

export const listCategories = () => ({
	[RSAA]: {
		api: get("/point_of_sale/categories/"),
		types: LIST_CATEGORIES,
	},
})

export const getCategory = (id) => ({
	[RSAA]: {
		api: get(`/point_of_sale/categories/${id}/`),
		types: GET_CATEGORY,
	},
})

export const addCategory = (name) => ({
	[RSAA]: {
		api: post("/point_of_sale/categories/", { name }),
		types: ADD_CATEGORY,
	},
})

export const patchCategory = (id, data) => ({
	[RSAA]: {
		api: patch(`/point_of_sale/categories/${id}/`, data),
		types: EDIT_CATEGORY,
	},
})

export const deleteCategory = (id) => ({
	[RSAA]: {
		api: patch(`/point_of_sale/categories/${id}/`, { is_active: false }),
		types: DELETE_CATEGORY,
	},
})

export const activateCategory = (id) => ({
	[RSAA]: {
		api: patch(`/point_of_sale/categories/${id}/`, { is_active: true }),
		types: DELETE_CATEGORY,
	},
})

export const listSystems = () => ({
	[RSAA]: {
		api: get("/point_of_sale/systems/"),
		types: LIST_SYSTEMS,
	},
})

export const listManufacturers = () => ({
	[RSAA]: {
		api: get("/point_of_sale/manufacturers/"),
		types: LIST_MANUFACTURERS,
	},
})

export const listCustomerParts = (querystring_params) => ({
	[RSAA]: {
		api: get("/point_of_sale/customer-parts/", querystring_params),
		types: LIST_CUSTOMER_PARTS,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const getCustomerPart = (id) => ({
	[RSAA]: {
		api: get(`/point_of_sale/customer-parts/${id}/`),
		types: GET_CUSTOMER_PART,
	},
})

const searchCustomerPartsAPI = (searchText) => ({
	[RSAA]: {
		api: get("/point_of_sale/customer-parts/", {
			search: searchText,
		}),
		types: SEARCH_CUSTOMER_PARTS,
	},
})

export const searchCustomerParts = (searchText) => {
	const debounced = (dispatch) => {
		dispatch(searchCustomerPartsAPI(searchText))
	}

	debounced.meta = {
		debounce: {
			time: 325,
			key: SEARCH_CUSTOMER_PARTS,
		},
	}

	return debounced
}

const initialState = {
	isLoadingParts: false,
	isLoadingCustomerParts: false,
	isCustomerPartsInitialLoad: true,
	isSaving: false,

	partsPages: 1,
	partsRequestTimestamp: null,
	parts: [],
	isSearchingParts: false,
	partSearchResults: null,
	isLoadingPart: false,
	part: null,

	isLoadingCategories: false,
	partCategories: [],

	isLoadingPartCategory: false,
	partCategory: null,

	isLoadingSystems: false,
	partSystems: [],

	isLoadingManufacturers: false,
	partsManufacturers: [],

	customerPartsPages: 1,
	customerPartsRequestTimestamp: null,
	customerParts: [],
	isSearchingCustomerParts: false,
	customerPartSearchResults: null,
	isLoadingCustomerPart: false,
	customerPart: null,

	error: null,
}

const pointOfSale = (state = initialState, action) => {
	switch (action.type) {
		case LIST_PARTS.request:
			return {
				...state,
				isLoadingParts: true,
			}
		case LIST_PARTS.success:
			// bail if we would be overwriting newer data
			if (state.partsRequestTimestamp > action.meta.timestamp) {
				return state
			}

			return {
				...state,
				isLoadingParts: false,
				parts: action.payload,
				partsPages: action.meta.pages,
				partsRequestTimestamp: action.meta.timestamp,
				error: null,
			}
		case LIST_PARTS.failure:
			return {
				...state,
				isLoadingParts: false,
				error: action.error,
			}

		case GET_PART.request:
		case GET_PART.success:
		case GET_PART.failure:
			return {
				...state,
				isLoadingPart: action.type === GET_PART.request,
				part: action.type === GET_PART.success ? action.payload : state.part,
				error: action.error,
			}

		case SEARCH_PARTS.request:
		case SEARCH_PARTS.success:
		case SEARCH_PARTS.failure:
			return {
				...state,
				isSearchingParts: action.type === SEARCH_PARTS.request,
				partSearchResults:
					action.type === SEARCH_PARTS.success ?
						action.payload
					:	state.partSearchResults,
				error: action.error,
			}

		case LIST_CATEGORIES.request:
		case LIST_CATEGORIES.failure:
			return {
				...state,
				isLoadingCategories: action.type === LIST_CATEGORIES.request,
				error: action.error,
			}

		case LIST_CATEGORIES.success:
			let partCategories = action.payload

			return {
				...state,
				isLoadingCategories: false,
				partCategories,
			}

		case GET_CATEGORY.request:
		case GET_CATEGORY.success:
		case GET_CATEGORY.failure:
			return {
				...state,
				isLoadingPartCategory: action.type === GET_CATEGORY.request,
				partCategory: action.type === GET_CATEGORY.success ? action.payload : null,
				error: action.error,
			}

		case LIST_SYSTEMS.request:
		case LIST_SYSTEMS.failure:
			return {
				...state,
				isLoadingSystems: action.type === LIST_SYSTEMS.request,
				error: action.error,
			}

		case LIST_SYSTEMS.success:
			let partSystems = action.payload

			return {
				...state,
				isLoadingSystems: false,
				partSystems,
			}

		case LIST_MANUFACTURERS.request:
		case LIST_MANUFACTURERS.failure:
			return {
				...state,
				isLoadingManufacturers: action.type === LIST_MANUFACTURERS.request,
				error: action.error,
			}

		case LIST_MANUFACTURERS.success:
			let partManufacturers = action.payload

			return {
				...state,
				isLoadingManufacturers: false,
				partManufacturers,
			}

		case LIST_CUSTOMER_PARTS.request:
			return {
				...state,
				isLoadingCustomerParts: true,
				customerPartsRequestTimestamp: action.meta?.timestamp,
			}
		case LIST_CUSTOMER_PARTS.success:
			// bail if the data is not for the latest request
			if (state.customerPartsRequestTimestamp > action.meta.timestamp) {
				return state
			}

			return {
				...state,
				isLoadingCustomerParts: false,
				isCustomerPartsInitialLoad: false,
				customerParts: action.payload,
				customerPartsPages: action.meta.pages,
				error: null,
			}
		case LIST_CUSTOMER_PARTS.failure:
			return {
				...state,
				isLoadingCustomerParts: false,
				isCustomerPartsInitialLoad: false,
				error: action.error,
			}

		case GET_CUSTOMER_PART.request:
		case GET_CUSTOMER_PART.success:
		case GET_CUSTOMER_PART.failure:
			return {
				...state,
				isLoadingCustomerPart: action.type === GET_CUSTOMER_PART.request,
				customerPart:
					action.type === GET_CUSTOMER_PART.success ?
						action.payload
					:	state.customerPart,
				error: action.error,
			}

		case SEARCH_CUSTOMER_PARTS.request:
		case SEARCH_CUSTOMER_PARTS.success:
		case SEARCH_CUSTOMER_PARTS.failure:
			return {
				...state,
				isSearchingCustomerParts: action.type === SEARCH_CUSTOMER_PARTS.request,
				customerPartSearchResults:
					action.type === SEARCH_CUSTOMER_PARTS.success ?
						action.payload
					:	state.customerPartSearchResults,
				error: action.error,
			}

		case CREATE_PART.request:
		case PATCH_PART.request:
		case UPDATE_PART_IMAGE.request:
		case DELETE_PART.request:
		case ACTIVATE_PART.request:
		case ADD_CATEGORY.request:
		case EDIT_CATEGORY.request:
		case DELETE_CATEGORY.request:
			return {
				...state,
				isSaving: true,
			}
		case CREATE_PART.success:
		case PATCH_PART.success:
		case UPDATE_PART_IMAGE.success:
		case DELETE_PART.success:
		case ACTIVATE_PART.success:
		case ADD_CATEGORY.success:
		case EDIT_CATEGORY.success:
		case DELETE_CATEGORY.success:
			return {
				...state,
				isSaving: false,
				error: null,
			}
		case CREATE_PART.failure:
		case PATCH_PART.failure:
		case UPDATE_PART_IMAGE.failure:
		case DELETE_PART.failure:
		case ACTIVATE_PART.failure:
		case ADD_CATEGORY.failure:
		case EDIT_CATEGORY.failure:
		case DELETE_CATEGORY.failure:
			return {
				...state,
				isSaving: false,
				error: action.error,
			}

		default:
			return state
	}
}
export default pointOfSale

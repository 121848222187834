import { Dispatch, ReactElement, SetStateAction, useEffect, useState } from "react"

import { EquipmentVintageLookup } from "@ncs/ncs-api"

import { usePrevious } from "../../util"
import { EquipmentVintageSelector } from "../selectors"

interface VintageQueryParams {
	vintage: string | null
}

export interface VintageQueryFilterProps<QueryParamState extends VintageQueryParams> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

/**
 * Same thing as `EquipmentVintageQueryFilter` except the key is `vintage` instead of `equipment`.
 */
export const VintageQueryFilter = <QueryParamState extends VintageQueryParams>({
	queryParamState,
	setQueryParamState,
}: VintageQueryFilterProps<QueryParamState>): ReactElement => {
	const [selectedVintage, setSelectedVintage] = useState<EquipmentVintageLookup | null>(null)

	const handleChange = (newVintage: EquipmentVintageLookup | null) => {
		// Set local state with full Vintage object.
		setSelectedVintage(newVintage)
		// Set query param state with just the Vintage ID.
		setQueryParamState((prev) => ({
			...prev,
			vintage: newVintage?.id ?? null,
		}))
	}

	const prevVintageFromQueryParams = usePrevious(queryParamState.vintage)
	useEffect(() => {
		// Handles the user clicking Reset button.
		if (
			queryParamState.vintage === null &&
			prevVintageFromQueryParams !== null &&
			selectedVintage !== null
		) {
			setSelectedVintage(null)
		}
	}, [prevVintageFromQueryParams, queryParamState.vintage, selectedVintage])

	return (
		<EquipmentVintageSelector value={selectedVintage} onChange={handleChange} fillContainer />
	)
}

import React from "react"

import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import withStyles from "@material-ui/core/styles/withStyles"
import PropTypes from "prop-types"

import { APPLICATION, useUserCanUse } from "@ncs/ncs-api"
import dashboardStyle from "@ncs/bricks/assets/jss/material-dashboard-pro-react/views/dashboardStyle"
import { formatNumberAsCurrency } from "@ncs/bricks/util/formatters"
import { Heading } from "@ncs/web-legos"

import { DashboardCard, DashboardLineGraph, GridContainer, GridItem } from "~/components"

import Create from "@material-ui/icons/Create"
import Healing from "@material-ui/icons/Healing"
import LocalCarWash from "@material-ui/icons/LocalCarWash"
import MonetizationOn from "@material-ui/icons/MonetizationOn"
import Refresh from "@material-ui/icons/Refresh"
import Timelapse from "@material-ui/icons/Timelapse"
import Update from "@material-ui/icons/Update"
import Work from "@material-ui/icons/Work"

function ServiceDashboard(props) {
	const canViewService = useUserCanUse([APPLICATION.CreateDispatch, APPLICATION.SearchDispatch])

	if (!canViewService) {
		return (
			<Heading textAlign="center" mt={3}>
				Welcome to the NCS Internal Portal
			</Heading>
		)
	}

	return (
		<div>
			{props.service.length > 0 ?
				<div>
					<GridContainer>
						<GridItem xs={12} s={12} md={6} />
						<GridItem xs={12} s={12} md={6}>
							<form>
								<FormControl fullWidth className={props.classes.selectFormControl}>
									<InputLabel
										htmlFor="territorySelect"
										className={props.classes.selectLabel}
									>
										{"Territory"}
									</InputLabel>
									<Select
										MenuProps={{ className: props.classes.selectMenu }}
										classes={{ select: props.classes.select }}
										value={props.territorySelected}
										onChange={props.onTerritoryUpdate}
										inputProps={{
											name: "territorySelect",
											id: "territorySelect",
										}}
									>
										{props.userTerritories.map((ut, i) => (
											<MenuItem
												key={i}
												classes={{
													root: props.classes.selectMenuItem,
													selected: props.classes.selectMenuItemSelected,
												}}
												value={ut._id}
											>
												{ut.code} - {ut.description}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</form>
						</GridItem>
					</GridContainer>
					<GridContainer>
						<DashboardCard
							color="primary"
							icon={<Work />}
							measureName="PI/PM % Completion"
							measureValue={props.activeDashboard.pi_pm_percent}
							measureUnit="%"
							footerIcon={<Update />}
							footerText="Month-To-Date"
						/>

						<DashboardCard
							color="success"
							icon={<MonetizationOn />}
							measureName="Chemical Total"
							measureValue={formatNumberAsCurrency(props.activeDashboard.chem_total)}
							footerIcon={<Update />}
							footerText="Month-To-Date"
						/>

						<DashboardCard
							color="warning"
							icon={<MonetizationOn />}
							measureName="T & M Total"
							measureValue={formatNumberAsCurrency(props.activeDashboard.tm_total)}
							footerIcon={<Update />}
							footerText="Month-To-Date"
						/>

						<DashboardCard
							color="info"
							icon={<Healing />}
							measureName="Return Calls"
							measureValue={props.activeDashboard.return_call_count}
							measureUnit="Calls"
							footerIcon={<Update />}
							footerText="Month-To-Date"
						/>
					</GridContainer>
					<GridContainer>
						<DashboardLineGraph
							color="info"
							title="Revenue Generating Hours Trend"
							data={props.activeDashboard.daily_rev_hours}
							footerIcon={<Update />}
							footerText="Last 30 Day Activity"
						/>

						<DashboardLineGraph
							color="rose"
							title="Response Time Trend"
							data={props.activeDashboard.daily_response_times}
							footerIcon={<Update />}
							footerText="Last 30 Day Activity"
						/>
					</GridContainer>
					<GridContainer>
						<DashboardCard
							color="primary"
							icon={<Timelapse />}
							measureName="PO Shipped On-Time"
							measureValue={props.activeDashboard.shipped_on_time}
							measureUnit="%"
							footerIcon={<Update />}
							footerText="Month-to-Date"
						/>

						<DashboardCard
							color="success"
							icon={<Refresh />}
							measureName="Inventory 30 Day Turn Rate"
							measureValue={props.activeDashboard.inventory_turns}
							measureUnit="%"
							footerIcon={<Update />}
							footerText="Last 30 days"
						/>

						<DashboardCard
							color="warning"
							icon={<Create />}
							measureName="Invoice Edits"
							measureValue={props.activeDashboard.edited_invoices}
							measureUnit="%"
							footerIcon={<Update />}
							footerText="Month-To-Date"
						/>

						<DashboardCard
							color="info"
							icon={<LocalCarWash />}
							measureName="Wash Count to Gather"
							measureValue={props.activeDashboard.wash_counts_remaining}
							measureUnit="Machines"
							footerIcon={<Update />}
							footerText="Month-To-Date"
						/>
					</GridContainer>
				</div>
			:	<GridContainer>{"No Dashboard Information At This Time"}</GridContainer>}
		</div>
	)
}

ServiceDashboard.propTypes = {
	service: PropTypes.array.isRequired,
}

export default withStyles(dashboardStyle)(ServiceDashboard)

import { FC, Fragment, useCallback, useMemo, useState } from "react"

import { Column, Row } from "react-table"

import {
	Customer,
	CustomerQueryParams,
	defaultCustomerQueryParams,
	makeApiErrorMessage,
	useCustomers,
} from "@ncs/ncs-api"
import { tryToFormatPhone, yesOrNo } from "@ncs/ts-utils"
import {
	Box,
	Disabled,
	Divider,
	EmptyValueDash,
	EnterpriseQueryFilter,
	ExtendableModalProps,
	getAddressFields,
	GridContainer,
	GridItem,
	Modal,
	OrganizationQueryFilter,
	RegionQueryFilter,
	SearchQueryFilter,
	Table,
	TerritoryQueryFilter,
	useIsSaving,
} from "@ncs/web-legos"

export interface AdvancedCustomerSearchModalProps extends ExtendableModalProps {
	onSave: (customer: Customer) => void | Promise<void>
}

export const AdvancedCustomerSearchModal: FC<AdvancedCustomerSearchModalProps> = ({
	onSave,
	onClose,
	...rest
}) => {
	const { isSaving, setSaving, endSaving } = useIsSaving()
	const [errorText, setErrorText] = useState<string | null>(null)

	const [params, setParams] = useState<CustomerQueryParams>({
		...defaultCustomerQueryParams,
	})

	const filtersEmpty = useMemo(() => {
		return Object.entries(params).every(([key, value]) => {
			return value === defaultCustomerQueryParams[key as keyof CustomerQueryParams]
		})
	}, [params])

	const query = useCustomers({
		params,
		queryConfig: {
			enabled: filtersEmpty === false,
		},
	})

	const handleClick = useCallback(
		async (row: Row<Customer>) => {
			try {
				setSaving()
				await onSave(row.original)
				onClose()
			} catch (e) {
				endSaving()
				setErrorText(makeApiErrorMessage(e))
			}
		},
		[onSave, onClose, endSaving, setSaving]
	)

	return (
		<Modal
			title="Advanced Customer Search"
			maxWidth="md"
			fullHeight
			{...rest}
			errorText={errorText}
			onClose={onClose}
			stickyTopContent={
				<Fragment>
					<GridContainer>
						<GridItem xs={12}>
							<SearchQueryFilter
								queryParamState={params}
								setQueryParamState={setParams}
								label="Search all fields"
								autoFocus
							/>
						</GridItem>
					</GridContainer>

					<Divider />

					<GridContainer rowGap={0}>
						<GridItem sm={12} md={6}>
							<TerritoryQueryFilter
								queryParamState={params}
								setQueryParamState={setParams}
							/>
						</GridItem>
						<GridItem sm={12} md={6}>
							<OrganizationQueryFilter
								queryParamState={params}
								setQueryParamState={setParams}
							/>
						</GridItem>
						<GridItem sm={12} md={6}>
							<EnterpriseQueryFilter
								queryParamState={params}
								setQueryParamState={setParams}
							/>
						</GridItem>
						<GridItem sm={12} md={6}>
							<RegionQueryFilter
								queryParamState={params}
								setQueryParamState={setParams}
							/>
						</GridItem>
					</GridContainer>
				</Fragment>
			}
		>
			<Box mt={2}>
				<Disabled disabled={isSaving()}>
					<Table
						query={query}
						columns={columns}
						noDataText={
							filtersEmpty ?
								"Enter search criteria to begin..."
							:	"No customers match the current filter settings"
						}
						onRowClick={handleClick}
					/>
				</Disabled>
			</Box>
		</Modal>
	)
}

const columns: Column<Customer>[] = [
	{
		Header: "Customer #",
		noWrap: true,
		accessor: "customerNumber",
	},
	{
		Header: "Name",
		accessor: "name",
	},
	{
		Header: "Address",
		accessor: (original) => getAddressFields(original, { exclude: "name" }).join(", "),
	},
	{
		Header: "Is FST active?",
		hiddenByDefault: true,
		accessor: ({ isFstActive }) => yesOrNo(isFstActive),
	},
	{
		Header: "Contact name",
		hiddenByDefault: true,
		accessor: ({ contactName }) => contactName || <EmptyValueDash />,
	},
	{
		Header: "Phone",
		hiddenByDefault: true,
		accessor: ({ phone }) => tryToFormatPhone(phone) || <EmptyValueDash />,
	},
	{
		Header: "Site #",
		noWrap: true,
		hiddenByDefault: true,
		accessor: ({ siteNumber }) => siteNumber || <EmptyValueDash />,
	},
]

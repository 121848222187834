import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
import { InputAdornment } from "@material-ui/core"
import { Check, Close, Edit } from "@material-ui/icons"
import { getValueFromEventTargetValue } from "../../util/state"
import { ButtonWithIcon, Input } from "../../components"
import { CustomInputProps } from "./CustomInput"
import { AmbiguousTooltipProp } from "../CustomTooltip/makeTooltipProps"

export interface EditorInputProps {
	value?: InlineEditingInputProps["value"]
	onChange?: CustomInputProps["onChange"]
	startAdornment?: React.ReactElement | null
	endAdornment?: React.ReactElement
}

export interface InlineEditingInputProps {
	/** current value of field */
	value?: string | number

	/** id representation of current value of field. this is used for the editorComponent when it differs from `value`,
	 * e.g. in the case of a Select component.
	 * Defaults to `value`. */
	editorValue?: string | number

	/** value shown if value is empty */
	valueIfEmpty?: string | number

	/** handle the saving of the new value, MUST return a promise */
	onSave: (value: string | number) => Promise<string>

	/** if you refresh data after saving set this to true while it's refreshing so the old value doesn't flash up */
	isRefreshingData?: boolean

	/** Hide the cancel button. Useful for InlineEditor in Dynamic Table where space is tight **/
	hideCancel?: boolean

	/** supply your own editor component if you don't want it to be a Input.
	 * Must accept inputProps and place them on the input for the InputAdornments to work. */
	renderEditorComponent?: (props: EditorInputProps) => React.ReactElement

	/** supply a regex to filter out invalid input. if the regex does not pass, the value won't be updated */
	inputValidationRegex?: RegExp
	labelText?: React.ReactNode
	labelProps?: CustomInputProps["labelProps"]
	inputProps?: CustomInputProps["inputProps"]
	formControlProps?: CustomInputProps["formControlProps"]
	fullWidth?: boolean
	tooltip?: AmbiguousTooltipProp
}

const InlineEditingInput: React.FC<InlineEditingInputProps> = ({
	hideCancel = false,
	labelText,
	fullWidth = true,
	isRefreshingData,
	value: propValue,
	editorValue,
	valueIfEmpty = "",
	onSave,
	inputProps,
	formControlProps,
	labelProps,
	renderEditorComponent,
	inputValidationRegex,
	tooltip,
}) => {
	const inputRef = useRef<HTMLInputElement>()
	const [state, setWholeState] = useState<{
		isEditing: boolean
		isSaving: boolean
		value: string | number
	}>({
		isEditing: false,
		isSaving: false,
		value: "",
	})
	const setState = (newState: Partial<typeof state>) =>
		setWholeState((prevState) => ({ ...prevState, ...newState }))
	const { value, isEditing, isSaving } = state

	function handleStartEditing() {
		setState({
			isEditing: true,
			value: editorValue || propValue || "",
		})
		setTimeout(() => inputRef?.current?.focus(), 100)
	}

	function handleCancelEditing() {
		setState({ isEditing: false })
	}

	function handleSave() {
		setState({ isSaving: true })

		onSave(value).then(() => {
			setState({ isSaving: false, isEditing: false })
		})
	}

	function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
		const newValue = getValueFromEventTargetValue(e)
		if (inputValidationRegex && !inputValidationRegex.test(newValue)) {
			// did not pass validation, do not update
			return
		}

		setState({ value: newValue })
	}

	const editorInputProps: EditorInputProps = {
		value: isRefreshingData || state.isEditing ? value : propValue || valueIfEmpty,
		onChange: handleOnChange,
		startAdornment:
			isEditing && !hideCancel ?
				<InputAdornment position="start">
					<ButtonWithIcon
						justIcon
						round
						size="sm"
						onClick={handleCancelEditing}
						disabled={isSaving}
						icon={<Close />}
					/>
				</InputAdornment>
			:	null,
		endAdornment:
			isEditing ?
				<InputAdornment position="end">
					<ButtonWithIcon
						justIcon
						color="success"
						round
						size="sm"
						onClick={handleSave}
						disabled={isSaving}
						loading={isSaving}
						icon={<Check />}
					/>
				</InputAdornment>
			:	<InputAdornment position="end">
					<ButtonWithIcon
						justIcon
						color={"warning"}
						round
						onClick={handleStartEditing}
						icon={<Edit />}
					/>
				</InputAdornment>,
	}

	const editorComponent =
		typeof renderEditorComponent === "function" ?
			renderEditorComponent(editorInputProps)
		:	null

	return (
		<>
			{!isEditing || !editorComponent ?
				<Input
					labelText={labelText}
					labelProps={labelProps}
					tooltip={isEditing ? undefined : tooltip}
					formControlProps={{
						fullWidth: fullWidth,
						...formControlProps,
					}}
					inputProps={{
						...inputProps,
						inputProps: { ...(inputProps?.inputProps ?? {}), ref: inputRef },
						disabled: !isEditing,
						...editorInputProps,
					}}
				/>
			:	editorComponent}
		</>
	)
}

InlineEditingInput.propTypes = {
	/** current value of field */
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

	/** id representation of current value of field. this is used for the editorComponent when it differs from `value`,
	 * e.g. in the case of a Select component.
	 * Defaults to `value`. */
	editorValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

	/** value shown if value is empty */
	valueIfEmpty: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

	/** handle the saving of the new value, MUST return a promise */
	onSave: PropTypes.func.isRequired,

	/** if you refresh data after saving set this to true while it's refreshing so the old value doesn't flash up */
	isRefreshingData: PropTypes.bool,

	/** Hide the cancel button. Useful for InlineEditor in Dynamic Table where space is tight **/
	hideCancel: PropTypes.bool,

	/** supply your own editor component if you don't want it to be a Input.         *
	 * Must accept inputProps and place them on the input for the InputAdornments to work. */
	renderEditorComponent: PropTypes.func,

	/** supply a regex to filter out invalid input. if the regex does not pass, the value won't be updated */
	inputValidationRegex: PropTypes.instanceOf(RegExp),

	labelText: PropTypes.node,
	labelProps: PropTypes.object,
	inputProps: PropTypes.object,
	formControlProps: PropTypes.object,
	fullWidth: PropTypes.bool,
}

export default React.memo(InlineEditingInput)

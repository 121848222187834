import { APPLICATION, User } from "@ncs/ncs-api"

export enum DashboardName {
	None = "—",
	LineItems = "Line Items Dashboard",
	Service = "Service Dashboard",
}

export const isDashboardName = (str: string): str is DashboardName => {
	return Object.values(DashboardName).includes(String(str) as DashboardName)
}

export const defaultDashboard = DashboardName.Service

export const userCanViewDashboard = (name: DashboardName, user: User): boolean => {
	const hasApps = (apps: APPLICATION[]): boolean => {
		return apps.some((app) => user.apps.includes(app))
	}

	switch (name) {
		case DashboardName.None: {
			return true
		}
		case DashboardName.LineItems: {
			return hasApps([APPLICATION.PartsOrder])
		}
		case DashboardName.Service: {
			return hasApps([APPLICATION.CreateDispatch, APPLICATION.SearchDispatch])
		}
	}
}

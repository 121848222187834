import { FC, useState } from "react"

import { useHistory } from "react-router-dom"

import {
	ConnectedDevice,
	Customer,
	Equipment,
	makeApiErrorMessage,
	useUpdateConnectedMachine,
} from "@ncs/ncs-api"
import {
	Callout,
	CustomerEquipmentSelector,
	CustomerSelector,
	ExtendableModalProps,
	Modal,
	Paragraph,
	TextInput,
	useChangeCallback,
	useToast,
} from "@ncs/web-legos"

export interface EditMachineModalProps extends ExtendableModalProps {
	customerId: string
	machine: ConnectedDevice
}

export const EditMachineModal: FC<EditMachineModalProps> = ({ customerId, machine, ...rest }) => {
	const history = useHistory()
	const { makeSuccessToast } = useToast()
	const [name, setName] = useState<string | null>(machine.name)
	const [customer, setCustomer] = useState<Customer | null>(null)
	const [equipment, setEquipment] = useState<Equipment | null>(null)
	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)
	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
	const [isDeleting, setIsDeleting] = useState(false)

	const updateMachine = useUpdateConnectedMachine()

	const handleSave = async () => {
		try {
			if (!name) throw new Error("Name is required")
			if (!customer) throw new Error("Customer is required")
			if (!equipment) throw new Error("Equipment is required")

			setIsSaving(true)
			await updateMachine({
				updates: {
					deviceId: machine.id,
					isActive: true,
					name,
					customerId: customer.id,
					customerEquipmentId: equipment.id,
				},
			})
			makeSuccessToast("Machine updated")
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const handleDeactivate = async () => {
		try {
			setIsDeleting(true)
			await updateMachine({
				updates: {
					deviceId: machine.id,
					isActive: false,

					// Just pass through the rest of the fields unchanged.
					name: machine.name,
					customerId: customerId,
					customerEquipmentId: machine.equipment?.id ?? null,
				},
			})
			makeSuccessToast("Device deactivated")
			history.replace("/connectivity/connected-devices/")
		} catch (e) {
			setErrorText(makeApiErrorMessage(e))
			setIsDeleting(false)
			setShowDeleteConfirm(false)
		}
	}

	const onOpen = () => {
		setName(machine.name)
		setCustomer(null)
		setEquipment(null)
		setIsSaving(false)
		setErrorText(null)
		setShowDeleteConfirm(false)
		setIsDeleting(false)
	}

	// Whenever customer changes, clear the equipment dropdown.
	useChangeCallback(customer?.id, (newId) => {
		if (newId !== customerId) {
			setEquipment(null)
		}
	})

	return (
		<>
			<Modal
				{...rest}
				onOpen={onOpen}
				title="Edit Connected Machine"
				rightButtons={{
					buttonText: "Save Changes",
					onClick: handleSave,
					isLoading: isSaving,
					disabled: !name || !customer || !equipment,
				}}
				leftButtons={{
					buttonText: "Deactivate device",
					icon: "trash-alt",
					onClick: () => setShowDeleteConfirm(true),
					variant: "text",
				}}
				errorText={errorText}
			>
				<TextInput value={name} onChange={setName} label="Name" />
				<CustomerSelector
					value={customer}
					onChange={setCustomer}
					initialCustomerId={customerId}
				/>
				<CustomerEquipmentSelector
					value={equipment}
					onChange={setEquipment}
					customerId={customer?.id}
					initialId={machine.equipment?.id}
					label="Customer equipment"
					disableOptionsAggregation
				/>
			</Modal>

			<Modal
				isOpen={showDeleteConfirm}
				onClose={() => setShowDeleteConfirm(false)}
				title="Confirm: Deactivate Device?"
				rightButtons={{
					buttonText: "Yes, Deactivate",
					onClick: handleDeactivate,
					isLoading: isDeleting,
				}}
			>
				<Callout variant="error" icon="exclamation-triangle">
					<Paragraph bold>Heads up!</Paragraph>
				</Callout>
				<Paragraph>
					Once deactivated, this device will be hidden entirely in all Connectivity
					screens. The only way to reactivate it will be with manual database
					intervention.
				</Paragraph>
			</Modal>
		</>
	)
}

import React, { Component } from "react"

import {
	TicketGroupFilter,
	TicketTypeFilter,
	GridContainer,
	QuickCard,
	GridItem,
} from "~/components"
import { compose } from "redux"
import { withDynamicTableFilter } from "~/components"

const reduxKey = "typeSubtypeFiltersReduxKey"
const ticketTypeManagementReduxKey = "ticketTypeManagementReduxKey"
const ticketSubtypeManagementReduxKey = "ticketSubtypeManagementReduxKey"

class TypeSubtypeFilters extends Component {
	render = () => {
		return (
			<GridContainer>
				<QuickCard>
					<GridContainer>
						<GridItem xs={12} md={6}>
							<TicketGroupFilter
								reduxKeys={[
									reduxKey,
									ticketTypeManagementReduxKey,
									ticketSubtypeManagementReduxKey,
								]}
							/>
						</GridItem>
						<GridItem xs={12} md={6}>
							<TicketTypeFilter
								reduxKeys={[
									reduxKey,
									ticketTypeManagementReduxKey,
									ticketSubtypeManagementReduxKey,
								]}
							/>
						</GridItem>
					</GridContainer>
				</QuickCard>
			</GridContainer>
		)
	}
}

export default compose(
	withDynamicTableFilter("group", undefined, reduxKey, "setGroup", "selectedGroup"),
	withDynamicTableFilter(
		"ticket_type",
		undefined,
		reduxKey,
		"setTicketType",
		"selectedTicketType"
	)
)(TypeSubtypeFilters)

import { FC, Fragment, useState } from "react"

import {
	APPLICATION,
	LineItemPriceOverride,
	makeApiErrorMessage,
	useAuth,
	useSubmitOverrideDecision,
} from "@ncs/ncs-api"
import { formatCurrency, formatDateTime } from "@ncs/ts-utils"
import {
	Box,
	Button,
	CssGridTable,
	EditStringModal,
	EditStringModalProps,
	EmptyValueDash,
	ExtendableModalProps,
	Icon,
	Modal,
	Paragraph,
	useToast,
} from "@ncs/web-legos"

export interface PriceOverridesModalProps extends ExtendableModalProps {
	overrides: LineItemPriceOverride[]
	closeAfterSubmission?: boolean
}

export const PriceOverridesModal: FC<PriceOverridesModalProps> = ({
	overrides,
	closeAfterSubmission,
	...rest
}) => {
	const { user } = useAuth()
	const { makeSuccessToast } = useToast()
	const [errorText, setErrorText] = useState<string | null>(null)
	const [editStringModalConfig, setEditStringModalConfig] =
		useState<EditStringModalProps | null>(null)

	const submitDecision = useSubmitOverrideDecision()

	const handleSubmit = async (id: string, approved: boolean, comments: string | null) => {
		try {
			await submitDecision({
				id,
				approved,
				comments,
			})
			makeSuccessToast(`Request successfully ${approved ? "approved" : "denied"}`)
			if (closeAfterSubmission) {
				rest.onClose()
			}
		} catch (e) {
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const openTextModal = (id: string, approved: boolean) => {
		setEditStringModalConfig({
			title: "Submit Decision",
			allowEmpty: true,
			label: "Comments (optional)",
			saveButtonLabel: "Submit",
			message: `Confirm: ${
				approved ? "Approve" : "Deny"
			} this override request. Enter any comments below.`,
			maxLength: 255,
			onSave: async (comments) => {
				await handleSubmit(id, approved, comments)
			},
			onClose: () => setEditStringModalConfig(null),
		})
	}

	const getReasonText = (override: LineItemPriceOverride): string => {
		const texts = []
		if (override.reasonDescription) texts.push(override.reasonDescription)
		if (override.reasonComment) texts.push(override.reasonComment)
		return texts.join(", ") || "(none)"
	}

	const userCanApprove = (app: APPLICATION | null) => {
		if (!app) return true
		return !!user?.apps && user.apps.includes(app)
	}

	return (
		<Modal {...rest} title="Price Overrides" errorText={errorText} maxWidth="md">
			<CssGridTable
				headers={[
					"Date",
					"Requested Rate",
					"Description",
					"Reason",
					"Requester",
					"Approval",
				]}
				gridTemplateColumns="1fr 1fr 1fr 1fr 1fr auto"
				gap={1}
				alignItems="flex-start"
				cells={overrides
					.sort((a, b) => (a.requestedOn > b.requestedOn ? -1 : 1))
					.map((override) => {
						return (
							<Fragment key={override.id}>
								<Paragraph small>{formatDateTime(override.requestedOn)}</Paragraph>
								<Paragraph small>
									{formatCurrency(override.requestedRate)}
								</Paragraph>
								<Paragraph small>
									{override.lineDescription ||
										override.lineTypeDescription ||
										"(no description)"}
								</Paragraph>
								<Paragraph small>{getReasonText(override)}</Paragraph>
								<Paragraph small>{override.requestorName}</Paragraph>
								{override.pendingApproval ?
									userCanApprove(override.lineApplication) ?
										<Box d="flex" gap={1}>
											<Button
												icon="thumbs-up"
												onClick={() => openTextModal(override.id, true)}
											>
												Approve
											</Button>
											<Button
												icon="thumbs-down"
												onClick={() => openTextModal(override.id, false)}
											>
												Deny
											</Button>
										</Box>
									:	<Paragraph small maxWidth={20} secondary>
											You do not have the required permission to approve or
											reject this request
										</Paragraph>

								:	<>
										<Box d="flex" gap={0.5}>
											<Box mt={0.1}>
												{!!override.approvedOn && (
													<Icon icon="check" fixedWidth />
												)}
												{!!override.deniedOn && (
													<Icon icon="times" fixedWidth />
												)}
											</Box>
											<div>
												<Paragraph small>
													{!!override.approvedOn && "Approved "}
													{!!override.deniedOn && "Denied "}
													by {override.approverName}{" "}
													{!!override.approvedOn &&
														formatDateTime(override.approvedOn)}
													{!!override.deniedOn &&
														formatDateTime(override.deniedOn)}
												</Paragraph>
												<Paragraph small>
													Comment:{" "}
													{override.approverComments || (
														<EmptyValueDash />
													)}
												</Paragraph>
											</div>
										</Box>
									</>
								}
							</Fragment>
						)
					})}
			/>

			{!!editStringModalConfig && <EditStringModal {...editStringModalConfig} />}
		</Modal>
	)
}

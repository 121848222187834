import { z } from "zod"

import { ManufacturedPart } from "@ncs/ncs-api"

export const ManufacturedPartFormSchema = z.object({
	partId: z.string().min(1, "Required"),
	productTypeId: z.string().min(1, "Required"),
	locationId: z.string().min(1, "Required"),
	batchGroup: z.string().min(1, "Required").max(100),
	packageSize: z.string().min(1, "Required").max(100),
	staticAdu: z.number().nullable(),
	isObsolete: z.boolean(),
})

export type ManufacturedPartForm = z.infer<typeof ManufacturedPartFormSchema>

export const defaultManufacturedPartFormValues: ManufacturedPartForm = {
	partId: "",
	productTypeId: "",
	locationId: "",
	batchGroup: "",
	packageSize: "",
	staticAdu: null,
	isObsolete: false,
}

export const makeDeleteManufacturedPartConfirmMessage = ({
	part,
	location,
}: ManufacturedPart): string => {
	return `Confirm: Delete (${part.partNumber}) ${part.description} at (${location.code}) ${location.description}?`
}

import React from "react"
import { connect } from "react-redux"
import { bindActionCreators, compose } from "redux"
import { withRouter } from "react-router-dom"
import callApi from "@ncs/bricks/redux/services/callApi"
import { getUserAssignments } from "@ncs/mortar/redux/services/ticketing"
import { fetchData } from "@ncs/bricks/redux/services/dynamicTables"
import { APPLICATIONS } from "@ncs/mortar/util/constants"
import { meetsAppRestriction } from "@ncs/bricks/redux/selectors/auth"

import TicketUserManagement from "./TicketingUserManagement"
import { withDynamicTableFilter } from "~/components"

const reduxKey = "ticketingUserManagement"

class TicketingUserManagement extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			selectedTicketType: null,
			selectedTicketGroup: null,
		}
	}

	handleTicketTypeChange = (selectedTicketType) => {
		this.setState({
			selectedTicketType,
		})
	}

	handleTicketGroupChange = (selectedTicketGroup) => {
		this.setState({
			selectedTicketGroup,
		})
	}

	handleAssignmentSaved = () => {
		this.props.fetchData()
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.selectedTicketType !== this.props.selectedTicketType) {
			if (typeof this.props.selectedTicketType === "number") {
				this.props.fetchData(reduxKey)
			}
		}
	}

	handleStartDeleting = (x) => {
		this.setState({ isDeletingUserAssignment: true })
	}

	handleDeleteUserAssignment = () => {
		this.setState({ isDeletingUserAssignment: false })
		this.props.fetchData()
	}
	handleCancelDeleting = () => {
		this.setState({ isDeletingUserAssignment: false })
	}

	render() {
		return (
			<TicketUserManagement
				fetchDataAction={(params) => getUserAssignments(this.props.selectedTicketType)}
				onTicketTypeChange={this.handleTicketTypeChange}
				group={this.props.selectedGroup}
				type={this.props.selectedTicketType}
				onAssignmentSaved={this.handleAssignmentSaved}
				onStartDeleting={this.handleStartDeleting}
				onCancelDeleting={this.handleCancelDeleting}
				onDeleteUserAssignment={this.handleDeleteUserAssignment}
				isDeletingUserAssignment={this.state.isDeletingUserAssignment}
				onTicketGroupChange={this.handleTicketGroupChange}
				reduxKey={reduxKey}
			/>
		)
	}
}
const mapStateToProps = (state) => {
	return {
		isTicketingAdmin: () => {
			return meetsAppRestriction(state.auth, APPLICATIONS.TicketingAdmin)
		},
		...state.lookups,
		dynamicTableState: { ...state.dynamicTables[reduxKey] },
	}
}

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ callApi, fetchData: fetchData(reduxKey) }, dispatch),
})

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withDynamicTableFilter("group", undefined, "filterReduxKey", "setGroup", "selectedGroup"),
	withDynamicTableFilter(
		"ticket_type",
		undefined,
		"filterReduxKey",
		"setTicketType",
		"selectedTicketType"
	),
	withRouter
)(TicketingUserManagement)

// ##############################
// // // Instruction component styles
// #############################

const instructionStyle = {
	instruction: {},
	picture: {},
	image: {
		width: "100%",
		height: "auto",
		borderRadius: "6px",
		display: "block",
		maxWidth: "100%",
	},
}

export default instructionStyle

import { FC, useState } from "react"

import { Dispatch, DispatchStatusId, makeApiErrorMessage, useUpdateDispatch } from "@ncs/ncs-api"
import { isEnumMember } from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	DispatchStatusSelector,
	ExtendableModalProps,
	Modal,
	Textarea,
	useIsSaving,
	useToast,
} from "@ncs/web-legos"

export interface EditStatusModalProps extends ExtendableModalProps {
	dispatch: Dispatch
}

export const EditStatusModal: FC<EditStatusModalProps> = ({ dispatch, ...rest }) => {
	const { makeSuccessToast } = useToast()
	const [status, setStatus] = useState<DispatchStatusId | null>(() => {
		const currentStatus = dispatch.statusId?.toString()
		if (isEnumMember(currentStatus, DispatchStatusId)) {
			return currentStatus
		}

		return null
	})
	const [closingComment, setClosingComment] = useState(dispatch.closedReason || null)
	const [errorText, setErrorText] = useState<string | null>(null)
	const { isSaving, setSaving, endSaving } = useIsSaving()

	const updateDispatch = useUpdateDispatch()

	const handleSave = async () => {
		if (!status) {
			setErrorText("Please choose a status")
			return
		}

		try {
			setSaving()
			await updateDispatch({
				id: dispatch.id,
				updates: {
					statusId: status,
					closedReason: status === DispatchStatusId.Closed ? closingComment : null,
				},
			})
			makeSuccessToast("Priority updated")
			rest.onClose()
		} catch (e) {
			endSaving()
			setErrorText(makeApiErrorMessage(e))
		}
	}

	return (
		<Modal
			{...rest}
			title="Edit Dispatch Status"
			errorText={errorText}
			rightButtons={{
				buttonText: "Save",
				onClick: handleSave,
				isLoading: isSaving(),
			}}
		>
			<DispatchStatusSelector
				value={status}
				onChange={(...[, option]) => setStatus(option?.id ?? null)}
				showNoSelectionOption={false}
				fillContainer
			/>
			<AnimatedEntrance mt={1} show={status === DispatchStatusId.Closed}>
				<Textarea
					value={closingComment}
					onChange={setClosingComment}
					label="Reason for closing (optional)"
				/>
			</AnimatedEntrance>
		</Modal>
	)
}

import { FC } from "react"

import { ExtendableSelectProps, Select } from "../inputs"

export interface PosPartFeaturedSelectorProps
	extends ExtendableSelectProps<(typeof options)[number]> {}

const options = [
	{
		text: "Featured",
		value: "featured",
	},
]

export const PosPartFeaturedSelector: FC<PosPartFeaturedSelectorProps> = ({ ...rest }) => {
	return <Select label="Featured products" fillContainer {...rest} options={options} />
}

import { FC } from "react"

import { PartProductType, usePartProductTypes } from "@ncs/ncs-api"

import { ExtendableSelectProps, Select } from "../inputs"

export interface PartProductTypeSelectorProps extends ExtendableSelectProps<PartProductType> {}

export const PartProductTypeSelector: FC<PartProductTypeSelectorProps> = ({ ...rest }) => {
	const [types, typesLoading] = usePartProductTypes()

	return (
		<Select
			label="Product type"
			disabled={typesLoading}
			{...rest}
			options={types ?? []}
			valueAccessor="id"
			textAccessor="description"
		/>
	)
}

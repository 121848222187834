import { FC, useState } from "react"

import { Dayjs } from "dayjs"
import { Column } from "react-table"

import {
	defaultVacuumStartupsQueryParams,
	useVacuumStartups,
	VacuumStartupHistory,
	VacuumStartupsQueryParams,
} from "@ncs/ncs-api"
import { DateFormat, formatDate } from "@ncs/ts-utils"
import { Table } from "@ncs/web-legos"

export interface StartupStatsTabProps {
	deviceId: string | null
	startDate: Dayjs
	endDate: Dayjs
	isRefreshing?: boolean
}

export const StartupStatsTab: FC<StartupStatsTabProps> = ({
	deviceId,
	startDate,
	endDate,
	isRefreshing,
}) => {
	// Param state that we'll give to Table for ordering.
	const [params, setParams] = useState<VacuumStartupsQueryParams>(
		defaultVacuumStartupsQueryParams
	)

	const combinedParams: VacuumStartupsQueryParams = {
		...params,
		device: deviceId,
		startDate: startDate.utc().format(DateFormat.DateTimeQueryParamNoTz),
		endDate: endDate.utc().format(DateFormat.DateTimeQueryParamNoTz),
	}

	const startupDataQuery = useVacuumStartups(combinedParams)

	return (
		<Table
			query={startupDataQuery}
			columns={columns}
			queryParamState={params}
			setQueryParamState={setParams}
			isUpdating={isRefreshing}
		/>
	)
}

const columns: Column<VacuumStartupHistory>[] = [
	{
		Header: "Date",
		accessor: (original) => formatDate(original.collectedOn),
	},
	{
		Header: "Max output current",
		accessor: "drive1MaxOutputCurrent",
	},
	{
		Header: "Max output power",
		accessor: "drive1MaxOutputPower",
	},
	{
		Header: "Max internal air temp",
		accessor: "drive1MaxInternalAirTemp",
	},
	{
		Header: "Max IGBT U temp",
		accessor: "drive1MaxIgbtUTemp",
	},
	{
		Header: "Max IGBT V temp",
		accessor: "drive1MaxIgbtVTemp",
	},
	{
		Header: "Max IGBT W temp",
		accessor: "drive1MaxIgbtWTemp",
	},
	{
		Header: "Max rectifier temp",
		accessor: "drive1MaxRectifierTemp",
	},
	{
		Header: "Max heatsink fan speed",
		accessor: "drive1MaxHeatsinkFanSpeed",
	},
	{
		Header: "Max motor overload status",
		accessor: "drive1MaxMotorOverloadStatus",
		hiddenByDefault: true,
	},
	{
		Header: "Time powered",
		accessor: "drive1TimePowered",
		hiddenByDefault: true,
	},
	{
		Header: "Time enabled",
		accessor: "drive1TimeEnabled",
		hiddenByDefault: true,
	},
]

import React from "react"
import { TICKETING_FILTERS } from "@ncs/mortar/util/constants"

import { GridContainer, GridItem, QuickCard, DynamicTable, InlineEditingInput } from "~/components"

//local components
import DefaultAssignToEditor from "./DefaultAssignToEditor"

//material ui icons
import ListIcon from "@material-ui/icons/List"

// styles
import { withStyles } from "@material-ui/core/styles"
import TicketTypeAddContainer from "./TicketTypeAddContainer"

const styles = {
	right: {
		textAlign: "right",
	},
}

function TicketTypeManagement(props) {
	const {
		fetchDataAction,
		reduxKey,
		group,
		onTypeSave,
		classes,
		onDescriptionSave,
		onDaysCompletionSave,
		isRefreshing,
		onAssignToSave,
	} = props
	const typeColumns = [
		{
			Header: "Type",
			accessor: (x) => (
				<InlineEditingInput
					labelText=""
					value={x.description}
					onSave={(newDescription) => onDescriptionSave(x.id, newDescription)}
					isRefreshingData={isRefreshing}
					inputProps={{ multiline: false }}
				/>
			),
			id: "description",
			sortable: true,
			toggleable: false,
			headerStyle: { textAlign: "center " },
		},
		{
			Header: "Default Days Completion",
			accessor: (x) => (
				<InlineEditingInput
					labelText=""
					value={x.default_days_completion}
					onSave={(newDays) => onDaysCompletionSave(x.id, newDays)}
					isRefreshingData={isRefreshing}
					inputProps={{ multiline: false, type: "number" }}
				/>
			),
			id: "default_days_completion",
			sortable: true,
			toggleable: false,
			headerStyle: { textAlign: "center " },
		},
		{
			Header: "Default Assigned To",
			accessor: (x) => (x.default_assigned_to ? x.default_assigned_to.name : "None"),
			id: "default_assign_to",
			sortable: true,
			toggleable: false,
			headerStyle: { textAlign: "center " },
		},
		{
			Header: "",
			accessor: (x) => (
				<DefaultAssignToEditor
					user={x.default_assigned_to}
					onSaveAssignTo={onAssignToSave}
					type={x.id}
				/>
			),
			sortable: false,
			id: "editor",
			maxWidth: 85,
		},
	]

	return (
		<GridContainer>
			<GridItem xs={12}>
				<QuickCard title="Type Management" shortenHeader={true} icon={<ListIcon />}>
					<GridContainer>
						<GridItem xs={12} lg={12} className={classes.right} show={false}>
							<TicketTypeAddContainer
								selectedGroup={group}
								onTypeSave={onTypeSave}
							/>
						</GridItem>
						<GridItem xs={12}>
							<DynamicTable // TODO: make props static
								reduxKey={reduxKey}
								fetchDataAction={fetchDataAction}
								filterFields={TICKETING_FILTERS}
								style={{ textAlign: "center" }}
								columns={typeColumns}
							/>
						</GridItem>
					</GridContainer>
				</QuickCard>
			</GridItem>
		</GridContainer>
	)
}

export default withStyles(styles)(TicketTypeManagement)

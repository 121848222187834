import { FC, useState } from "react"

import { useFormContext } from "react-hook-form"

import { makeApiErrorMessage, useUploadBrandCardImage } from "@ncs/ncs-api"
import { unpythonify } from "@ncs/ts-utils"
import { ErrorText, FileInput, FileType, Heading, Paragraph, useToast } from "@ncs/web-legos"

import { BrandCardForm } from "./brand-card-utils"

export const BrandCardImages: FC = () => {
	const { makeErrorToast } = useToast()
	const {
		setValue,
		watch,
		formState: { errors },
	} = useFormContext<BrandCardForm>()
	const [logoImageUrl, bannerImageUrl] = watch(["logoImageUrl", "bannerImageUrl"])
	const [saving, setSaving] = useState<"logoImageUrl" | "bannerImageUrl" | null>(null)

	const uploadImage = useUploadBrandCardImage()

	const onSelect = async (image: File, role: "logoImageUrl" | "bannerImageUrl") => {
		try {
			setSaving(role)
			const data = new FormData()
			data.append("file", image)
			const response = await uploadImage(data)
			const { imageUrl } = unpythonify(response.data)
			setValue(role, imageUrl, { shouldValidate: true })
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		} finally {
			setSaving(null)
		}
	}

	return (
		<>
			<Heading variant="h4" mt={1}>
				Logo
			</Heading>
			<Paragraph small color="secondary" my={0.5}>
				{logoImageUrl || "(None selected yet)"}
			</Paragraph>
			<FileInput
				id="logo-input"
				onChange={(file) => onSelect(file, "logoImageUrl")}
				label="Upload new image"
				isLoading={saving === "logoImageUrl"}
				fileTypes={[FileType.Images]}
			/>
			{!!errors.logoImageUrl && <ErrorText>{errors.logoImageUrl?.message}</ErrorText>}

			<Heading variant="h4" mt={2}>
				Banner
			</Heading>
			<Paragraph small color="secondary" my={0.5}>
				{bannerImageUrl || "(None selected yet)"}
			</Paragraph>
			<FileInput
				id="banner-input"
				onChange={(file) => onSelect(file, "bannerImageUrl")}
				label="Upload new image"
				isLoading={saving === "bannerImageUrl"}
				fileTypes={[FileType.Images]}
			/>
			{!!errors.bannerImageUrl && <ErrorText>{errors.bannerImageUrl?.message}</ErrorText>}
		</>
	)
}

import React from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import callApi from "@ncs/bricks/redux/services/callApi"
import { BaseSelectFilter, withDynamicTableFilter } from "@ncs/bricks/components"
import { TICKET_GROUPS } from "../../util/constants"
import { loadLookups, LOOKUPS } from "../../redux/services/lookups"

// custom components

class TicketGroupFilter extends React.PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			groups: this.props.ticketGroups,
		}
	}

	componentDidMount() {
		this.props.loadLookups([LOOKUPS.TicketGroups], this.filterTicketGroup)
	}
	filterTicketGroup = () => {
		if (!this.props.isServiceUser) {
			let groups = this.props.ticketGroups.filter((x) => x.id !== TICKET_GROUPS.TECH_SUPPORT)
			this.setState({
				groups,
			})
		} else {
			let groups = this.props.ticketGroups
			this.setState({
				groups,
			})
		}
	}

	render() {
		return (
			<BaseSelectFilter
				id="ticketGroups"
				labelText="Department"
				options={[
					{
						id: "",
						description: "All Departments",
					},
					...this.state.groups,
				]}
				valueAccessor="id"
				textAccessor={(x) => x.description}
				onChange={this.props.onChange}
				{...this.props}
			/>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		ticketGroups: state.lookups.ticketGroups,
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			callApi,
			loadLookups,
		},
		dispatch
	)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withDynamicTableFilter("group")(TicketGroupFilter))

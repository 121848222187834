import { FC, memo, useMemo } from "react"

import { BinLocation } from "@ncs/ncs-api"
import { SearchableSelect } from "@ncs/web-legos"

interface ReceivePartsBinSelectorProps {
	bins: BinLocation[]
	value: string | null
	onChange: (newBin: BinLocation | null) => void
}

export const ReceivePartBinSelector: FC<ReceivePartsBinSelectorProps> = memo(
	({ bins, value, onChange }) => {
		const currentBin = useMemo(() => {
			return bins.find((b) => b.id === value) ?? null
		}, [bins, value])

		return (
			<SearchableSelect
				value={currentBin}
				options={bins}
				onItemSelect={onChange}
				getOptionLabel={(bin) => bin.code}
				disabled={!bins.length}
				placeholder="Search bins..."
				noOptionsText="No matching bins founds"
				disableOptionsAggregation
				mb={0}
			/>
		)
	}
)

ReceivePartBinSelector.displayName = "BinSelector"

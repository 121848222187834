import { post, rsaaTypes, RSAA } from "@ncs/bricks/middleware/portalApiMiddleware"

export const GET_PROFIT_LOSS = rsaaTypes("siteProfit/profitLoss/list")
export const GET_PROFIT_SUMMARY = rsaaTypes("siteProfit/profitSummary")
export const GET_ACCOUNT_DETAIL = rsaaTypes("siteProfit/accountDetail")
export const GET_TECHNICIAN_EFFICIENCY = rsaaTypes("siteProfit/technicianEfficiency")

export const getProfitLoss = (querystring_params) => {
	return {
		[RSAA]: {
			api: post("/reports/profitability/", querystring_params),
			types: GET_PROFIT_LOSS,
		},
	}
}

export const getProfitSummary = (querystring_params) => {
	return {
		[RSAA]: {
			api: post("/reports/profit_summary", querystring_params),
			types: GET_PROFIT_SUMMARY,
		},
	}
}

export const getAccountDetail = (querystring_params) => {
	return {
		[RSAA]: {
			api: post("/reports/ar_detail/", querystring_params),
			types: GET_ACCOUNT_DETAIL,
		},
	}
}

export const getTechnicianEfficiency = (querystring_params) => {
	return {
		[RSAA]: {
			api: post("/reports/ar_tech_efficiency/", querystring_params),
			types: GET_TECHNICIAN_EFFICIENCY,
		},
	}
}

const initialState = {
	isLoadingProfitLoss: false,
	profitLosses: null,
}

const siteProfit = (state = initialState, action) => {
	switch (action.type) {
		case GET_PROFIT_LOSS.request:
		case GET_PROFIT_LOSS.success:
		case GET_PROFIT_LOSS.failure:
			return {
				...state,
				isLoadingProfitLoss: action.type === GET_PROFIT_LOSS.request,
				profitLosses: action.payload,
				error: action.error,
			}

		default:
			return state
	}
}

export default siteProfit

import { FC, useState } from "react"

import {
	CustomerDetail,
	makeApiErrorMessage,
	useCreateSiteSurvey,
	useCreateSiteSurveysForChildren,
	useCreateSiteSurveysForSiblings,
	UserMinimal,
} from "@ncs/ncs-api"
import {
	ExtendableModalProps,
	Modal,
	Paragraph,
	RadioGroup,
	UserSelector,
	useToast,
} from "@ncs/web-legos"

export interface RequestSurveyModalProps extends ExtendableModalProps {
	customer: CustomerDetail
}

export const RequestSurveyModal: FC<RequestSurveyModalProps> = ({ customer, ...rest }) => {
	const { makeSuccessToast } = useToast()
	const [tech, setTech] = useState<UserMinimal | null>(null)
	const [customerGroup, setCustomerGroup] = useState<"single" | "children" | "siblings">(
		"single"
	)
	const [errorText, setErrorText] = useState<string | null>(null)
	const [isSaving, setIsSaving] = useState(false)

	const createSingle = useCreateSiteSurvey(customer.id)
	const createForChildren = useCreateSiteSurveysForChildren(customer.id)
	const createForSiblings = useCreateSiteSurveysForSiblings(customer.id)

	const handleSubmit = async () => {
		try {
			setIsSaving(true)

			switch (customerGroup) {
				case "single":
					if (!tech) {
						throw new Error("Select tech to be assigned survey")
					}
					await createSingle({
						assignedToId: tech.id.toString(),
					})
					break
				case "children":
					await createForChildren()
					break
				case "siblings":
					await createForSiblings()
					break
			}
			makeSuccessToast("Request received. Surveys may take a few moments to create.")
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const reset = () => {
		setTech(null)
		setCustomerGroup("single")
		setErrorText(null)
		setIsSaving(false)
	}

	return (
		<Modal
			title="Request Site Survey"
			onOpen={reset}
			{...rest}
			errorText={errorText}
			rightButtons={{
				buttonText: "Create Survey(s)",
				isLoading: isSaving,
				onClick: handleSubmit,
			}}
		>
			<RadioGroup
				htmlName="customer-group"
				value={customerGroup}
				description="Create surveys for:"
				options={[
					{
						label: "This customer only",
						value: "single" as const,
					},
					{
						label: "Customers with same default bill-to",
						value: "siblings" as const,
					},
					{
						label: "Customers that bill to this customer",
						value: "children" as const,
					},
				]}
				labelAccessor="label"
				valueAccessor="value"
				onChange={(value, option) => setCustomerGroup(option.value)}
			/>

			<Paragraph mb={1}>Assign to:</Paragraph>

			{customerGroup === "single" ?
				<UserSelector
					value={tech}
					onChange={setTech}
					initialId={customer.defaultTech?.id}
					label=""
					placeholder="Search for tech..."
				/>
			:	<Paragraph small>Each customer's default tech</Paragraph>}
		</Modal>
	)
}

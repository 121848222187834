import { useMemo } from "react"

import queryString from "query-string"
import { useQueryClient } from "react-query"

import { arrayWrap, unpythonify } from "@ncs/ts-utils"

import { apiClient, buildUrl, preparePortalParams } from "../util"
import { ApiGetQueryOptions } from "./types"

/**
 * Copy pasta most of use-get-request for prefetching purposes.
 */
export const useGetPrefetch = <Payload, Params = Object>(
	endpoint: string | string[],
	options?: ApiGetQueryOptions<Payload, Params>
): void => {
	const queryClient = useQueryClient()

	const endpointArray = useMemo(() => arrayWrap(endpoint), [endpoint])
	const endpointString = useMemo(() => `${endpointArray.join("/")}/`, [endpointArray])

	const preparedParams = useMemo(
		() => preparePortalParams(options?.params, options?.preparePortalParamsOptions),
		[options?.params, options?.preparePortalParamsOptions]
	)

	const queryKey = useMemo(() => {
		const result: (string | object)[] = [...endpointArray]
		if (preparedParams) result.push(preparedParams)

		return result
	}, [endpointArray, preparedParams])

	const endpointWithParams = useMemo(
		() =>
			preparedParams ?
				`${endpointString}?${queryString.stringify(preparedParams, {
					skipEmptyString: true,
					skipNull: true,
					arrayFormat: "comma",
				})}`
			:	endpointString,
		[endpointString, preparedParams]
	)

	void queryClient.prefetchQuery({
		queryKey,
		queryFn: () =>
			apiClient.get(buildUrl(endpointWithParams)).then((response) => {
				if (options?.mapper) {
					return options.mapper(response.data)
				} else {
					return unpythonify(response.data)
				}
			}),
	})
}

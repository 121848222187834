import React, { Component } from "react"
import TicketTypeManagement from "./TicketTypeManagement"
import { listTicketTypes, patchTicketType } from "@ncs/mortar/redux/services/ticketing"
import { bindActionCreators, compose } from "redux"
import { connect } from "react-redux"
import { fetchData } from "@ncs/bricks/redux/services/dynamicTables"
import { withCallApi } from "~/components"

const reduxKey = "ticketTypeManagementReduxKey"

class TicketTypeManagementContainer extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isRefreshing: false,
		}
	}
	listTicketTypes = (query) => listTicketTypes(query)

	handleTypeSave = () => {
		this.refreshData()
	}

	refreshData = () => {
		this.setState({ isRefreshing: true })
		this.props.refreshTypes(reduxKey, this.setState({ isRefreshing: false }))
	}
	handleSaveDescription = (type, description) => {
		let data = {
			description: description,
		}
		return this.props.callApi(patchTicketType(type, data)).then((result) => this.refreshData())
	}

	handleSaveDaysCompletion = (type, days) => {
		let data = {
			default_days_completion: days,
		}
		return this.props.callApi(patchTicketType(type, data)).then((result) => this.refreshData())
	}
	handleSaveDefaultAssigned = (type, assigned) => {
		let data = {
			default_assigned_to_id: assigned._id,
		}
		return this.props.callApi(patchTicketType(type, data)).then((result) => this.refreshData())
	}

	handleAssignToSave = (type, user) => {
		this.refreshData()
	}
	render() {
		return (
			<TicketTypeManagement
				reduxKey={reduxKey}
				fetchDataAction={this.listTicketTypes}
				group={this.props.group}
				type={this.props.type}
				onTypeSave={this.handleTypeSave}
				onDescriptionSave={this.handleSaveDescription}
				onDaysCompletionSave={this.handleSaveDaysCompletion}
				onDefaultAssignedSave={this.handleSaveDefaultAssigned}
				validationLabel={this.state.validationLabel}
				validationError={this.state.validationError}
				onErrorCancel={this.handleErrorCancel}
				onAssignToSave={this.handleAssignToSave}
			/>
		)
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			refreshTypes: fetchData(reduxKey),
		},
		dispatch
	)

export default compose(
	connect(null, mapDispatchToProps),
	withCallApi
)(TicketTypeManagementContainer)

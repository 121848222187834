import { Dispatch, ReactElement, SetStateAction, useEffect, useState } from "react"

import { EquipmentManufacturer } from "@ncs/ncs-api"

import { usePrevious } from "../../util"
import { EquipmentManufacturerSelector } from "../selectors"

interface EquipmentManufacturerQueryParams {
	manufacturer?: string | null
	manufacturerId?: string | null
}

export interface EquipmentManufacturerQueryFilterProps<
	QueryParamState extends EquipmentManufacturerQueryParams,
> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
	paramKey?: keyof EquipmentManufacturerQueryParams
	label?: string
}

export const EquipmentManufacturerQueryFilter = <
	QueryParamState extends EquipmentManufacturerQueryParams,
>({
	queryParamState,
	setQueryParamState,
	paramKey = "manufacturer",
	label,
}: EquipmentManufacturerQueryFilterProps<QueryParamState>): ReactElement => {
	const [selectedManufacturer, setSelectedManufacturer] = useState<EquipmentManufacturer | null>(
		null
	)

	const handleChange = (newManufacturer: EquipmentManufacturer | null) => {
		// Set local state with full EquipmentManufacturer object.
		setSelectedManufacturer(newManufacturer)
		// Set query param state with just the EquipmentManufacturer ID.
		setQueryParamState((prev) => ({
			...prev,
			[paramKey]: newManufacturer?.id.toString() ?? null,
		}))
	}

	const queryParamStateValue = queryParamState[paramKey]

	const prevEquipmentManufacturerFromQueryParams = usePrevious(queryParamStateValue)
	useEffect(() => {
		// Handles the user clicking Reset button.
		if (
			queryParamStateValue === null &&
			prevEquipmentManufacturerFromQueryParams !== null &&
			selectedManufacturer !== null
		) {
			setSelectedManufacturer(null)
		}
	}, [prevEquipmentManufacturerFromQueryParams, queryParamStateValue, selectedManufacturer])

	return (
		<EquipmentManufacturerSelector
			value={selectedManufacturer}
			onChange={handleChange}
			fillContainer
			label={label}
			initialId={queryParamState[paramKey]}
		/>
	)
}

import { Dispatch, ReactElement, SetStateAction, useEffect, useState } from "react"

import { EnterpriseMinimal } from "@ncs/ncs-api"

import { usePrevious } from "../../util"
import { EnterpriseSelector } from "../selectors"

interface EnterpriseQueryFilter {
	enterprise: string | null
}

export interface EnterpriseQueryFilterProps<QueryParamState extends EnterpriseQueryFilter> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

export const EnterpriseQueryFilter = <QueryParamState extends EnterpriseQueryFilter>({
	queryParamState,
	setQueryParamState,
}: EnterpriseQueryFilterProps<QueryParamState>): ReactElement => {
	const [selectedEnterprise, setSelectedEnterprise] = useState<EnterpriseMinimal | null>(null)

	const handleChange = (newEnterprise: EnterpriseMinimal | null) => {
		setSelectedEnterprise(newEnterprise)
		setQueryParamState((prev) => ({
			...prev,
			enterprise: newEnterprise?.id ?? null,
		}))
	}

	// Handles the user clicking Reset button.
	const prevEnterpriseFromQueryParams = usePrevious(queryParamState.enterprise)
	useEffect(() => {
		if (
			queryParamState.enterprise === null &&
			prevEnterpriseFromQueryParams !== null &&
			selectedEnterprise !== null
		) {
			setSelectedEnterprise(null)
		}
	}, [prevEnterpriseFromQueryParams, queryParamState.enterprise, selectedEnterprise])

	return (
		<EnterpriseSelector
			value={selectedEnterprise}
			onChange={handleChange}
			initialId={queryParamState.enterprise}
			fillContainer
		/>
	)
}

import React from "react"

import withStyles from "@material-ui/core/styles/withStyles"
import PropTypes from "prop-types"

import dashboardStyle from "@ncs/bricks/assets/jss/material-dashboard-pro-react/views/dashboardStyle"

import {
	DashboardCard,
	DashboardLineGraph,
	GridContainer,
	GridItem,
	PercentageSummary,
	Select,
} from "~/components"

import CheckCircle from "@material-ui/icons/CheckCircle"
import Done from "@material-ui/icons/Done"
import Edit from "@material-ui/icons/Edit"
import History from "@material-ui/icons/History"
import MenuBook from "@material-ui/icons/MenuBook"
import NewReleases from "@material-ui/icons/NewReleases"
import NotificationsActive from "@material-ui/icons/NotificationsActive"
import ThumbUp from "@material-ui/icons/ThumbUp"
import Update from "@material-ui/icons/Update"

function ServiceRequestsDashboard(props) {
	return (
		<div>
			<GridContainer>
				<GridItem xs={12} md={6} lg={8} />
				<GridItem xs={12} md={6} lg={4}>
					<Select
						id="territoryFilter"
						labelText="Territory"
						value={props.selectedTerritory || "all"}
						onChange={props.onTerritoryChange}
						options={[
							{ _id: "", code: "all", description: "All My Territories" },
							...props.territories,
						].map((territory) => ({
							value: territory.code,
							text:
								territory.code !== "all" ?
									`(${territory.code}) ${territory.description}`
								:	territory.description,
						}))}
					/>
				</GridItem>
			</GridContainer>
			<GridContainer>
				<DashboardCard
					color="primary"
					icon={<NotificationsActive />}
					measureName="Open Dispatches"
					measureValue={props.dispatchesOpen}
					measureUnit=""
					footerIcon={<Update />}
					footerText="Oldest Open Dispatch:"
					footerDate={props.dispatchesOpenUpdated}
					onClick={props.onViewOpenDispatches}
				/>

				<DashboardCard
					color="success"
					icon={<NewReleases />}
					measureName="New Today"
					measureValue={props.dispatchesNewToday}
					measureUnit=""
					footerIcon={<Update />}
					footerText="Newest Dispatch:"
					footerDate={props.dispatchesNewTodayUpdated}
					onClick={props.onViewNewTodayDispatches}
				/>

				<DashboardCard
					color="warning"
					icon={<Done />}
					measureName="Closed Today"
					measureValue={props.dispatchesClosedToday}
					measureUnit=""
					footerIcon={<Update />}
					footerText="Latest Closed:"
					footerDate={props.dispatchesClosedTodayUpdated}
					onClick={props.onViewClosedTodayDispatches}
				/>

				<DashboardCard
					color="info"
					icon={<MenuBook />}
					measureName="Open On Contract"
					measureValue={props.dispatchesOpenOnContract}
					measureUnit=""
					footerIcon={<Update />}
					footerText="Oldest On Contract:"
					footerDate={props.dispatchesOpenOnContractUpdated}
					onClick={props.onViewDispatchesOpenOnContract}
				/>
			</GridContainer>
			<GridContainer>
				<DashboardLineGraph
					color="danger"
					title="Dispatch History"
					data={props.dispatchHistory}
					showLegend={true}
					summaries={[
						<PercentageSummary
							key="new-dispatches"
							data={props.dispatchHistory.series[0].data}
							sizeOfComparison={7}
							betterText={"increase in new dispatches this week over last week."}
							worseText={"decrease in new dispatches this week over last week."}
						/>,

						<PercentageSummary
							key="closed-dispatches"
							data={props.dispatchHistory.series[1].data}
							sizeOfComparison={7}
							betterText={"increase in closed dispatches this week over last week."}
							worseText={"decrease in closed dispatches this week over last week."}
						/>,
					]}
					footerIcon={<Update />}
					footerText="Last Updated:"
					footerDate={props.dispatchHistoryUpdated}
				/>

				<DashboardLineGraph
					color="rose"
					title="Invoice History"
					data={props.invoiceHistory}
					showLegend={true}
					summaries={[
						<PercentageSummary
							key="new-invoices"
							data={props.invoiceHistory.series[0].data}
							sizeOfComparison={7}
							betterText={"increase in new invoices this week over last week."}
							worseText={"decrease in new invoices this week over last week."}
						/>,
						<PercentageSummary
							key="closed-invoices"
							data={props.invoiceHistory.series[1].data}
							sizeOfComparison={7}
							betterText={"increase in closed invoices this week over last week."}
							worseText={"decrease in closed invoices this week over last week."}
						/>,
					]}
					footerIcon={<Update />}
					footerText="Last Updated:"
					footerDate={props.invoiceHistoryUpdated}
				/>
			</GridContainer>
			<GridContainer>
				<DashboardCard
					color="primary"
					icon={<ThumbUp />}
					measureName="Field Approval"
					measureValue={props.invoicesPendingFieldApproval}
					measureUnit="Invoices"
					footerIcon={<Update />}
					footerText="Newest In Status:"
					footerDate={props.invoicesPendingFieldApprovalUpdated}
					onClick={props.onViewFieldApprovalInvoices}
				/>

				<DashboardCard
					color="success"
					icon={<CheckCircle />}
					measureName="Second Approval"
					measureValue={props.invoicesPendingSecondApproval}
					measureUnit="Invoices"
					footerIcon={<Update />}
					footerText="Newest In Status:"
					footerDate={props.invoicesPendingSecondApprovalUpdated}
					onClick={props.onViewSecondApprovalInvoices}
				/>

				<DashboardCard
					color="warning"
					icon={<History />}
					measureName="Rejected"
					measureValue={props.invoicesRejected}
					measureUnit="Invoices"
					footerIcon={<Update />}
					footerText="Newest In Status:"
					footerDate={props.invoicesRejectedUpdated}
					onClick={props.onViewRejectedInvoices}
				/>

				<DashboardCard
					color="info"
					icon={<Edit />}
					measureName="Additional Entry"
					measureValue={props.invoicesAdditionalEntry}
					measureUnit="Invoices"
					footerIcon={<Update />}
					footerText="Newest In Status:"
					footerDate={props.invoicesAdditionalEntryUpdated}
					onClick={props.onViewAdditionalEntryInvoices}
				/>
			</GridContainer>
		</div>
	)
}

ServiceRequestsDashboard.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	lastRefreshed: PropTypes.number,
	dispatchesOpen: PropTypes.number.isRequired,
	dispatchesOpenUpdated: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
	dispatchesNewToday: PropTypes.number.isRequired,
	dispatchesNewTodayUpdated: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
	dispatchesClosedToday: PropTypes.number.isRequired,
	dispatchesClosedTodayUpdated: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.instanceOf(Date),
	]),
	dispatchesOpenOnContract: PropTypes.number.isRequired,
	dispatchesOpenOnContractUpdated: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.instanceOf(Date),
	]),
	invoicesPendingFieldApproval: PropTypes.number.isRequired,
	invoicesPendingFieldApprovalUpdated: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.instanceOf(Date),
	]),
	invoicesPendingSecondApproval: PropTypes.number.isRequired,
	invoicesPendingSecondApprovalUpdated: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.instanceOf(Date),
	]),
	invoicesRejected: PropTypes.number.isRequired,
	invoicesRejectedUpdated: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
	invoicesAdditionalEntry: PropTypes.number.isRequired,
	invoicesAdditionalEntryUpdated: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.instanceOf(Date),
	]),
	dispatchHistory: PropTypes.object.isRequired,
	dispatchHistoryUpdated: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
	invoiceHistory: PropTypes.object.isRequired,
	invoiceHistoryUpdated: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
	onViewOpenDispatches: PropTypes.func.isRequired,
	onViewNewTodayDispatches: PropTypes.func.isRequired,
	onViewClosedTodayDispatches: PropTypes.func.isRequired,
	onViewDispatchesOpenOnContract: PropTypes.func.isRequired,
	onViewFieldApprovalInvoices: PropTypes.func.isRequired,
	onViewSecondApprovalInvoices: PropTypes.func.isRequired,
	onViewRejectedInvoices: PropTypes.func.isRequired,
	onViewAdditionalEntryInvoices: PropTypes.func.isRequired,

	territories: PropTypes.array.isRequired,
	selectedTerritory: PropTypes.string,
	onTerritoryChange: PropTypes.func,
}

export default withStyles(dashboardStyle)(ServiceRequestsDashboard)

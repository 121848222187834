import { FC, Fragment, useState } from "react"

import dayjs, { Dayjs } from "dayjs"

import { EquipmentContract, useEquipmentSingular } from "@ncs/ncs-api"
import { formatDate } from "@ncs/ts-utils"
import {
	Box,
	Checkbox,
	DateInput,
	Divider,
	EmptyValueDash,
	ExtendableModalProps,
	Heading,
	Icon,
	Label,
	LabeledData,
	LoadingSpinner,
	Modal,
	Paragraph,
	useChangeCallback,
} from "@ncs/web-legos"

import { TransferContractsModal } from "./TransferContractsModal"

export interface EquipmentContractModalProps extends ExtendableModalProps {
	equipmentId: string
	customerId: string
}

type LocalValuesState = Record<string, { expiresDate: Dayjs; isActive: boolean }>

const makeLocalValues = (contracts: EquipmentContract[]): LocalValuesState => {
	return Object.fromEntries(
		(contracts ?? []).map((c) => [
			c.id,
			{
				expiresDate: dayjs(c.expirationDate),
				isActive: c.isActive,
			},
		])
	)
}

export const EquipmentContractsModal: FC<EquipmentContractModalProps> = ({
	equipmentId,
	customerId,
	...rest
}) => {
	const [localValues, setLocalValues] = useState<LocalValuesState>({})
	const [idsEditing, setIdsEditing] = useState<string[]>([])
	// const [idsSaving, setIdsSaving] = useState<string[]>([])
	const [errorText, setErrorText] = useState<string | null>(null)
	const [showTransferModal, setShowTransferModal] = useState(false)

	const [equipment, equipmentLoading] = useEquipmentSingular(customerId, equipmentId)
	// const [equipmentList] = useEquipment(customerId)
	// const updateContract = useUpdateEquipmentContract(customerId, equipmentId)

	// const saveEdit = async (contractId: string) => {
	// 	try {
	// 		setIdsSaving((prev) => [...prev, contractId])

	// 		const { expiresDate, isActive } = localValues[contractId]

	// 		await updateContract({
	// 			updates: {
	// 				expirationDate: expiresDate
	// 					? expiresDate.format(DateFormat.DateQueryParam)
	// 					: undefined,
	// 				isActive,
	// 			},
	// 			id: contractId,
	// 		})
	// 	} catch (e) {
	// 		setErrorText(makeApiErrorMessage(e))
	// 	} finally {
	// 		setIdsEditing((prev) => prev.filter((id) => id !== contractId))
	// 		setIdsSaving((prev) => prev.filter((id) => id !== contractId))
	// 	}
	// }

	useChangeCallback(
		equipment?.contracts,
		(updatedContracts) => {
			setLocalValues(makeLocalValues(updatedContracts ?? []))
		},
		{ callOnSetup: true }
	)

	const reset = () => {
		setIdsEditing([])
		// setIdsSaving([])
		setErrorText(null)
	}

	return (
		<Modal
			title="Equipment Contracts"
			onOpen={reset}
			maxWidth="md"
			errorText={errorText}
			// leftButtons={
			// 	!!equipment?.contracts.length && equipmentList && equipmentList.length > 1
			// 		? {
			// 				buttonText: "Transfer contracts to different equipment",
			// 				icon: "exchange-alt",
			// 				variant: "text",
			// 				onClick: () => setShowTransferModal(true),
			// 		  }
			// 		: undefined
			// }
			{...rest}
		>
			{equipmentLoading && <LoadingSpinner />}

			{!!equipment && (
				<Heading mb={2}>
					{equipment.model} {!!equipment.serialNumber && `#${equipment.serialNumber}`}
				</Heading>
			)}

			{!equipment?.contracts.length && !equipmentLoading && (
				<Paragraph small color="secondary">
					No contracts found for this equipment.
				</Paragraph>
			)}

			{equipment?.contracts.map((c, i) => {
				const editing = idsEditing.includes(c.id)

				return (
					<Fragment key={c.id}>
						{i > 0 && <Divider mt={0.5} />}

						<Box display="flex" columnGap={2} alignItems="flex-start" flexWrap="wrap">
							<LabeledData label="Type">{c.type}</LabeledData>
							<LabeledData label="Effective date">
								{c.effectiveDate ?
									formatDate(c.effectiveDate, { formatInUtc: true })
								:	<EmptyValueDash />}
							</LabeledData>
							<Box display="flex" flexDirection="column">
								<Label>Expiration date</Label>
								{editing ?
									<DateInput
										label=""
										value={localValues[c.id]?.expiresDate}
										fillContainer
										width={9}
										onChange={(newDate) => {
											if (newDate) {
												setLocalValues((prev) => ({
													...prev,
													[c.id]: {
														...prev[c.id],
														expiresDate: newDate,
													},
												}))
											}
										}}
									/>
								: c.expirationDate ?
									<Paragraph>
										{formatDate(c.expirationDate, { formatInUtc: true })}
									</Paragraph>
								:	<EmptyValueDash />}
							</Box>
							<Box display="flex" flexDirection="column">
								<Label>Contract active?</Label>
								{editing ?
									<Checkbox
										label="Contract is active"
										value={localValues[c.id]?.isActive}
										width={10}
										mt={-0.5}
										fillContainer
										onChange={(newState) => {
											setLocalValues((prev) => ({
												...prev,
												[c.id]: {
													...prev[c.id],
													isActive: newState,
												},
											}))
										}}
									/>
								: c.isActive ?
									<Box display="flex" columnGap={0.5} alignItems="center">
										<Icon icon="check" />
										Active
									</Box>
								:	<Box display="flex" columnGap={0.5} alignItems="center">
										<Icon icon="ban" />
										Inactive
									</Box>
								}
							</Box>
							{/* {editing ? (
								<AnimatedEntrance display="flex" columnGap={0.5}>
									<IconButton
										icon="check"
										color="success"
										background="primary"
										isLoading={idsSaving.includes(c.id)}
										onClick={() => saveEdit(c.id)}
									/>
									<IconButton
										icon="times"
										onClick={() => {
											setIdsEditing((prev) =>
												prev.filter((pId) => pId !== c.id)
											)
										}}
									/>
								</AnimatedEntrance>
							) : (
								<Button
									onClick={() => setIdsEditing((prev) => [...prev, c.id])}
									icon="pencil"
								>
									Edit
								</Button>
							)} */}
						</Box>
					</Fragment>
				)
			})}

			{!!equipment && (
				<TransferContractsModal
					isOpen={showTransferModal}
					onClose={() => setShowTransferModal(false)}
					currentEquipment={equipment}
					customerId={customerId}
				/>
			)}
		</Modal>
	)
}

import { FC, useMemo, useState } from "react"

import { Column } from "react-table"

import { UserLocation, UserProfile, useUserLocations } from "@ncs/ncs-api"
import { extractNumber } from "@ncs/ts-utils"
import {
	Button,
	ConfirmationModal,
	ConfirmationModalConfig,
	HeadingDivider,
	Table,
} from "@ncs/web-legos"

import { EditTerritoriesModal } from "./EditTerritoriesModal"

export interface UserProfileTerritoriesTabProps {
	user: UserProfile
}

export const UserProfileTerritoriesTab: FC<UserProfileTerritoriesTabProps> = ({ user }) => {
	const [userLocations, useLocationsLoading] = useUserLocations(user.id.toString())
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)
	const [showTerritoriesModal, setShowTerritoriesModal] = useState(false)

	const preparedLocations = useMemo(() => {
		return (userLocations ?? []).sort((a, b) => {
			return extractNumber(a.locationNumber) > extractNumber(b.locationNumber) ? 1 : -1
		})
	}, [userLocations])

	return (
		<>
			<HeadingDivider
				headingVariant="h4"
				bold
				renderRight={() => {
					return (
						<Button icon="pencil" onClick={() => setShowTerritoriesModal(true)}>
							Edit territories
						</Button>
					)
				}}
			>
				User Territories
			</HeadingDivider>

			<Table
				data={preparedLocations}
				columns={columns}
				isLoading={useLocationsLoading}
				noDataText="No territories"
			/>

			{!!userLocations && (
				<EditTerritoriesModal
					isOpen={showTerritoriesModal}
					onClose={() => setShowTerritoriesModal(false)}
					initialUserLocations={userLocations}
					user={user}
				/>
			)}
			{!!confirmationConfig && (
				<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
			)}
		</>
	)
}

const columns: Column<UserLocation>[] = [
	{
		Header: "Code",
		accessor: "locationNumber",
		sortType: "number",
	},
	{
		Header: "Name",
		accessor: "locationName",
	},
]

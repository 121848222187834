import { get, rsaaTypes, RSAA } from "@ncs/bricks/middleware/portalApiMiddleware"
import { unpythonify } from "@ncs/bricks/util/variableCasing"

import {
	parseSystemHierarchy,
	listCategories as listPartsCategories,
	LIST_CATEGORIES as LIST_PARTS_CATEGORIES,
	listSystems as listPartsSystems,
	LIST_SYSTEMS as LIST_PARTS_SYSTEMS,
} from "./catalog"
import { listInfoRefCategories, LIST_INFO_REF_CATEGORIES } from "./infoRef"
import { listMaterialCategories, LIST_MATERIAL_CATEGORIES } from "./marketing"
import {
	listCategories as listPointOfSaleCategories,
	LIST_CATEGORIES as LIST_POINT_OF_SALE_CATEGORIES,
	listManufacturers as listPointOfSaleManufacturers,
	LIST_MANUFACTURERS as LIST_POINT_OF_SALE_MANUFACTURERS,
	listSystems as listPointOfSaleSystems,
	LIST_SYSTEMS as LIST_POINT_OF_SALE_SYSTEMS,
} from "./pointOfSale"

export const LIST_DISPATCH_STATUSES = rsaaTypes("dispatch-statuses/list")
export const LIST_INVOICE_STATUSES = rsaaTypes("invoice-statuses/list")
export const LIST_WORK_ORDER_LINE_ITEM_TYPES = rsaaTypes("work-order-line-item-types/list")
export const LIST_EQUIPMENT_REPAIR_PROPOSAL_STATUSES = rsaaTypes(
	"equipment-repair-proposals-statuses/list"
)
export const LIST_REGIONS = rsaaTypes("regions/list")
export const LIST_TERRITORIES = rsaaTypes("territories/list")
export const LIST_TERRITORIES_FOR_REGION = rsaaTypes("territories/filtered_list")
export const LIST_ENTERPRISES = rsaaTypes("enterprises/list")
export const LIST_ORGANIZATIONS_FOR_REGION = rsaaTypes("organizations/filtered_list")
export const LIST_PORTALS = rsaaTypes("portals/list")
export const LIST_PORTAL_USERS = rsaaTypes("portal-users/list")
export const LIST_CALL_TYPES = rsaaTypes("call-types/list")
export const LIST_CREDIT_MEMO_REASONS = rsaaTypes("credit-memo-reasons/list")
export const LIST_EQUIPMENT_VINTAGES = rsaaTypes("equipment-vintages/list")
export const LIST_PAYMENT_STATUSES = rsaaTypes("payment-stauses/list")
export const LIST_PRODUCTION_STATUSES = rsaaTypes("production-stauses/list")
export const LIST_LOCATIONS = rsaaTypes("inventory/locations/list")
export const LIST_TICKET_TYPES = rsaaTypes("ticketing/types/list")
export const LIST_TICKET_PRIORITIES = rsaaTypes("ticketing/priority/list")
export const LIST_TICKET_SUBTYPES = rsaaTypes("ticketing/subtype/list")
export const LIST_TICKET_STATUSES = rsaaTypes("ticketing/status/get")
export const LIST_TICKET_GROUPS = rsaaTypes("ticketing/groups/list")
export const LIST_TICKET_REQUESTORS = rsaaTypes("ticketing/ticket_requestors/list")
export const LIST_PART_ORDER_STATUSES = rsaaTypes("part-orders/statuses/list")
export const LIST_VENDORS = rsaaTypes("vendors/list")
export const LIST_PICK_LIST_STATUSES = rsaaTypes("pickList/status")
export const LIST_DELIVERY_ORDER_STATUSES = rsaaTypes("delivery-orders/statuses/list")
export const LIST_DELIVERY_SHIPMENT_METHODS = rsaaTypes(
	"delivery-orders/delivery-shipment-methods/list"
)
export const LIST_CUSTOMER_ROUTES = rsaaTypes("customer/routes/list")
export const LIST_PROFIT_MONTHS = rsaaTypes("profit/months")
export const LIST_TECH_ASSIGNMENTS = rsaaTypes("security/tech_assignments")
export const LIST_CHEMICAL_TECH_ASSIGNMENTS = rsaaTypes("security/chemical_tech_assignments")
export const LIST_SHIP_METHOD = rsaaTypes("partOrder/shipMethod")
export const LIST_DISPATCH_PRIORITIES = rsaaTypes("dispatch/priorities/list")
export const LIST_PURCHASE_ORDER_STATUS = rsaaTypes("purchase_order/status/list")
export const LIST_INVENTORY_REQUEST_STATUS = rsaaTypes("inventory_request/status/list")
export const LIST_INVENTORY_TYPES = rsaaTypes("inventory_request/types/list")
export const LIST_PORTAL_FEEDBACK_TYPES = rsaaTypes("portal_feedback/types")
export const LIST_DEFAULT_APPLICATIONS = rsaaTypes("applications/default/list")
export const LIST_ALL_APPLICATIONS = rsaaTypes("applications/all/list")
export const LIST_DEFAULT_REPORTS = rsaaTypes("reports/default/list")
export const LIST_ALL_REPORTS = rsaaTypes("reports/all/list")
export const LIST_REQUEST_STATUS = rsaaTypes("transferRequest/status/list")
export const LIST_HAZMAT_PACKAGE_TYPES = rsaaTypes("inventory/hazmat_package_types/list")
export const LIST_CONTRACT_TYPES = rsaaTypes("contracts/types/list")
export const LIST_PART_GROUPS = rsaaTypes("part_groups/list")
export const LIST_CHEMICAL_MACHINE_TYPES = rsaaTypes("chemical_machine_types/list")
export const NCS_EQUIPMENT_MODELS = rsaaTypes("ncs_equipment/models/list")
export const NCS_EQUIPMENT_VINTAGES = rsaaTypes("ncs_equipment/vintages/list")
export const LIST_WASH_COUNT_COMMENTS = rsaaTypes("wash_count_comments/list")
export const CUSTOMER_SOURCES = rsaaTypes("customers/source/list")
export const LIST_WAREHOUSES = rsaaTypes("warehouses/list")

export const LOOKUPS = Object.freeze({
	DispatchStatuses: 1,
	InvoiceStatuses: 2,
	WorkOrderLineItemTypes: 3,
	EquipmentRepairProposalStatuses: 4,
	Territories: 5,
	Portals: 6,
	PortalUsers: 7,
	CallTypes: 8,
	CreditMemoReasons: 9,
	PartsCategories: 10,
	PartsSystems: 11,
	EquipmentVintages: 12,
	MarketingMaterialCategories: 13,
	PaymentStatuses: 14,
	ProductionStatuses: 15,
	Regions: 17,
	Enterprises: 18,
	Locations: 19,
	InfoRefCategories: 20,
	TicketTypes: 21,
	TicketPriorities: 22,
	TicketSubtypes: 23,
	TicketStatuses: 24,
	TicketGroups: 25,
	PointOfSaleCategories: 26,
	PartOrderStatuses: 27,
	PickListStatus: 28,
	Vendors: 29,
	DeliveryOrderStatuses: 30,
	DeliveryShipmentMethods: 31,
	CustomerRoutes: 32,
	ProfitMonth: 33,
	TicketRequestors: 34,
	TechAssignments: 35,
	ShipMethod: 36,
	DispatchPriorities: 37,
	GoAheadAndTakeNumber38ItIsNotBeingUsed: 38,
	PurchaseOrderStatuses: 39,
	InventoryRequestStatuses: 40,
	InventoryTypes: 41,
	PointOfSaleSystems: 42,
	PointOfSaleManufacturers: 43,
	PortalFeedbackTypes: 44,
	DefaultApplications: 45,
	AllApplications: 46,
	DefaultReports: 47,
	AllReports: 48,
	RequestStatus: 49,
	HazmatPackageTypes: 50,
	ContractTypes: 51,
	PartGroups: 52,
	ChemicalMachineTypes: 53,
	NCSEquipmentModels: 54,
	NCSEquipmentVintages: 55,
	Organizations: 56,
	WashCountComments: 57,
	CustomerSources: 58,
	Warehouses: 59,
	ChemicalTechAssignments: 60,
})

export const listDispatchStatuses = () => ({
	[RSAA]: {
		api: get(`/lookups/dispatch_statuses/`),
		types: LIST_DISPATCH_STATUSES,
	},
})

export const listInvoiceStatuses = () => ({
	[RSAA]: {
		api: get(`/lookups/invoice_statuses/`),
		types: LIST_INVOICE_STATUSES,
	},
})

export const listWorkOrderLineItemTypes = () => ({
	[RSAA]: {
		api: get(`/lookups/work_order_line_item_types/`),
		types: LIST_WORK_ORDER_LINE_ITEM_TYPES,
	},
})

export const listEquipmentRepairProposalStatuses = () => ({
	[RSAA]: {
		api: get(`/lookups/equipment_repair_proposal_statuses/`),
		types: LIST_EQUIPMENT_REPAIR_PROPOSAL_STATUSES,
	},
})

export const listRegions = () => ({
	[RSAA]: {
		api: get(`/regions/`),
		types: LIST_REGIONS,
	},
})

export const listTerritories = () => ({
	[RSAA]: {
		api: get(`/territories/`),
		types: LIST_TERRITORIES,
	},
})

export const listTerritoriesForRegion = (region) => {
	return {
		[RSAA]: {
			api: get(`/territories/`, { region: region || undefined }),
			types: LIST_TERRITORIES_FOR_REGION,
		},
	}
}

export const listLocations = (querystring_params) => ({
	[RSAA]: {
		api: get(`/inventory/locations/`, querystring_params),
		types: LIST_LOCATIONS,
	},
})

export const listEnterprises = () => ({
	[RSAA]: {
		api: get(`/enterprises/`),
		types: LIST_ENTERPRISES,
	},
})

export const listOrganizationsForEnterprise = (enterprise) => {
	return {
		[RSAA]: {
			api: get(`/organizations/`, { enterprise: enterprise || undefined }),
			types: LIST_ORGANIZATIONS_FOR_REGION,
		},
	}
}

export const listPortals = () => ({
	[RSAA]: {
		api: get(`/lookups/portals/`),
		types: LIST_PORTALS,
	},
})

export const listPortalUsers = () => ({
	[RSAA]: {
		api: get(`/lookups/portal_users/`),
		types: LIST_PORTAL_USERS,
	},
})

export const listCallTypes = () => ({
	[RSAA]: {
		api: get(`/lookups/call_types/`),
		types: LIST_CALL_TYPES,
	},
})

export const listCreditMemoReasons = () => ({
	[RSAA]: {
		api: get(`/lookups/credit_memo_reasons/`),
		types: LIST_CREDIT_MEMO_REASONS,
	},
})

export const listEquipmentVintages = () => ({
	[RSAA]: {
		api: get(`/lookups/equipment_vintages/`),
		types: LIST_EQUIPMENT_VINTAGES,
	},
})

export const listPaymentStatuses = () => ({
	[RSAA]: {
		api: get(`/lookups/payment_statuses/`),
		types: LIST_PAYMENT_STATUSES,
	},
})

export const listProductionStatuses = () => ({
	[RSAA]: {
		api: get(`/lookups/production_statuses/`),
		types: LIST_PRODUCTION_STATUSES,
	},
})

export const listTicketPriorities = () => ({
	[RSAA]: {
		api: get(`/lookups/ticket_priorities/`),
		types: LIST_TICKET_PRIORITIES,
	},
})

export const listTicketStatuses = () => ({
	[RSAA]: {
		api: get(`/lookups/ticket_statuses/`),
		types: LIST_TICKET_STATUSES,
	},
})

export const listTicketSubtypes = () => ({
	[RSAA]: {
		api: get(`/lookups/ticket_subtypes/`),
		types: LIST_TICKET_SUBTYPES,
	},
})

export const listTicketTypes = () => ({
	[RSAA]: {
		api: get(`/lookups/ticket_types/`),
		types: LIST_TICKET_TYPES,
	},
})

export const listTicketGroups = () => ({
	[RSAA]: {
		api: get(`/lookups/ticket_groups/`),
		types: LIST_TICKET_GROUPS,
	},
})

export const listTicketRequestors = () => ({
	[RSAA]: {
		api: get(`/lookups/ticket_requestors/`),
		types: LIST_TICKET_REQUESTORS,
	},
})

export const listPartOrderStatuses = () => ({
	[RSAA]: {
		api: get(`/lookups/part_order_statuses/`),
		types: LIST_PART_ORDER_STATUSES,
	},
})

export const listVendors = () => ({
	[RSAA]: {
		api: get(`/lookups/vendors/`),
		types: LIST_VENDORS,
	},
})

export const listPickListStatuses = () => ({
	[RSAA]: {
		api: get(`/lookups/pick_list_statuses/`),
		types: LIST_PICK_LIST_STATUSES,
	},
})

export const listDeliveryOrderStatuses = () => ({
	[RSAA]: {
		api: get(`/lookups/delivery_order_statuses/`),
		types: LIST_DELIVERY_ORDER_STATUSES,
	},
})

export const listDeliveryShipmentMethods = () => ({
	[RSAA]: {
		api: get(`/lookups/delivery_shipment_methods/`),
		types: LIST_DELIVERY_SHIPMENT_METHODS,
	},
})

export const listCustomerRoutes = () => ({
	[RSAA]: {
		api: get(`/lookups/customer_routes/`),
		types: LIST_CUSTOMER_ROUTES,
	},
})

export const listProfitMonths = () => ({
	[RSAA]: {
		api: get(`/lookups/site_profit_months/`),
		types: LIST_PROFIT_MONTHS,
	},
})
export const listTechAssignments = () => ({
	[RSAA]: {
		api: get(`/lookups/tech_assignments/`),
		types: LIST_TECH_ASSIGNMENTS,
	},
})

export const listChemTechAssignments = () => ({
	[RSAA]: {
		api: get(`/lookups/chemical_tech_assignments/`),
		types: LIST_CHEMICAL_TECH_ASSIGNMENTS,
	},
})

export const listShipMethods = () => ({
	[RSAA]: {
		api: get(`/lookups/shipping_methods/`),
		types: LIST_SHIP_METHOD,
	},
})

export const listDispatchPriorities = () => ({
	[RSAA]: {
		api: get(`/lookups/dispatch_priorities/`),
		types: LIST_DISPATCH_PRIORITIES,
	},
})

export const listPurchaseOrderStatus = () => ({
	[RSAA]: {
		api: get(`/lookups/purchase_order_status/`),
		types: LIST_PURCHASE_ORDER_STATUS,
	},
})

export const listInventoryRequestStatus = () => ({
	[RSAA]: {
		api: get(`/lookups/inventory_request_status/`),
		types: LIST_INVENTORY_REQUEST_STATUS,
	},
})

export const listInventoryTypes = () => ({
	[RSAA]: {
		api: get(`/lookups/inventory_type/`),
		types: LIST_INVENTORY_TYPES,
	},
})

export const listPortalFeedbackTypes = () => ({
	[RSAA]: {
		api: get(`/lookups/portal_feedback_types/`),
		types: LIST_PORTAL_FEEDBACK_TYPES,
	},
})

export const listDefaultApplications = () => ({
	[RSAA]: {
		api: get(`/lookups/default_application_assignments/`),
		types: LIST_DEFAULT_APPLICATIONS,
	},
})

export const listAllApplications = () => ({
	[RSAA]: {
		api: get(`/lookups/application_assignments/`),
		types: LIST_ALL_APPLICATIONS,
	},
})

export const listDefaultReports = () => ({
	[RSAA]: {
		api: get(`/lookups/default_report_assignments/`),
		types: LIST_DEFAULT_REPORTS,
	},
})

export const listAllReports = () => ({
	[RSAA]: {
		api: get(`/lookups/report_assignments/`),
		types: LIST_ALL_REPORTS,
	},
})

export const listTransferRequestStatus = () => ({
	[RSAA]: {
		api: get(`/lookups/transfer_request_status/`),
		types: LIST_REQUEST_STATUS,
	},
})

export const listHazmatPackageTypes = () => ({
	[RSAA]: {
		api: get(`/lookups/hazmat_package_types/`),
		types: LIST_HAZMAT_PACKAGE_TYPES,
	},
})

export const listContractTypes = () => ({
	[RSAA]: {
		api: get("/lookups/contract_types/"),
		types: LIST_CONTRACT_TYPES,
	},
})

export const listPartGroups = () => ({
	[RSAA]: {
		api: get("/lookups/part_groups/"),
		types: LIST_PART_GROUPS,
	},
})

export const listChemicalMachineTypes = () => ({
	[RSAA]: {
		api: get("/lookups/chemical_machine_types/"),
		types: LIST_CHEMICAL_MACHINE_TYPES,
	},
})

export const listNCSEquipmentModels = () => ({
	[RSAA]: {
		api: get("/lookups/ncs_equipment_models/"),
		types: NCS_EQUIPMENT_MODELS,
	},
})

export const listNCSEquipmentVintages = () => ({
	[RSAA]: {
		api: get("/lookups/ncs_equipment_vintages/"),
		types: NCS_EQUIPMENT_VINTAGES,
	},
})

export const listWashCountComments = () => ({
	[RSAA]: {
		api: get("/lookups/wash_count_comments/"),
		types: LIST_WASH_COUNT_COMMENTS,
	},
})

export const listCustomerSources = () => ({
	[RSAA]: {
		api: get("/lookups/customer_sources/"),
		types: CUSTOMER_SOURCES,
	},
})

export const listWarehouses = () => ({
	[RSAA]: {
		api: get("/lookups/warehouses/"),
		types: LIST_WAREHOUSES,
	},
})

export const loadLookups = (lookupsToFetch, callback, forceRefresh) => (dispatch, getState) => {
	// this is often the first thing done in a container. on a page refresh that means this can finish before
	//     redux has rehydrated, which causes an error. Delay the call by a millisecond to work around this.
	let innerFunc = () => {
		let lookupList = typeof lookupsToFetch === "number" ? [lookupsToFetch] : lookupsToFetch
		let lookupState = getState().lookups

		let promises = lookupList.map((item) => {
			switch (item) {
				case LOOKUPS.DispatchStatuses:
					if (
						forceRefresh ||
						!lookupState.dispatchStatuses ||
						!lookupState.dispatchStatuses.length
					)
						return dispatch(listDispatchStatuses())
					return null
				case LOOKUPS.InvoiceStatuses:
					if (
						forceRefresh ||
						!lookupState.invoiceStatuses ||
						!lookupState.invoiceStatuses.length
					)
						return dispatch(listInvoiceStatuses())
					return null
				case LOOKUPS.WorkOrderLineItemTypes:
					if (
						forceRefresh ||
						!lookupState.workOrderLineItemTypes ||
						!lookupState.workOrderLineItemTypes.length
					)
						return dispatch(listWorkOrderLineItemTypes())
					return null
				case LOOKUPS.EquipmentRepairProposalStatuses:
					if (
						forceRefresh ||
						!lookupState.erpStatuses ||
						!lookupState.erpStatuses.length
					)
						return dispatch(listEquipmentRepairProposalStatuses())
					return null
				case LOOKUPS.Regions:
					if (forceRefresh || !lookupState.regions || !lookupState.regions.length)
						return dispatch(listRegions())
					return null
				case LOOKUPS.Territories:
					if (
						forceRefresh ||
						!lookupState.territories ||
						!lookupState.territories.length
					)
						return dispatch(listTerritories())
					return null
				case LOOKUPS.Enterprises:
					if (
						forceRefresh ||
						!lookupState.enterprises ||
						!lookupState.enterprises.length
					)
						return dispatch(listEnterprises())
					return null
				case LOOKUPS.Portals:
					if (forceRefresh || !lookupState.portals || !lookupState.portals.length)
						return dispatch(listPortals())
					return null
				case LOOKUPS.PortalUsers:
					if (
						forceRefresh ||
						!lookupState.portalUsers ||
						!lookupState.portalUsers.length
					)
						return dispatch(listPortalUsers())
					return null
				case LOOKUPS.CallTypes:
					if (forceRefresh || !lookupState.callTypes || !lookupState.callTypes.length)
						return dispatch(listCallTypes())
					return null
				case LOOKUPS.CreditMemoReasons:
					if (
						forceRefresh ||
						!lookupState.creditMemoReasons ||
						!lookupState.creditMemoReasons.length
					)
						return dispatch(listCreditMemoReasons())
					return null
				case LOOKUPS.PartsCategories:
					if (
						forceRefresh ||
						!lookupState.partsCategories ||
						!lookupState.partsCategories.length
					)
						return dispatch(listPartsCategories())
					return null
				case LOOKUPS.PartsSystems:
					if (
						forceRefresh ||
						!lookupState.partsSystems ||
						!lookupState.partsSystems.length
					)
						return dispatch(listPartsSystems())
					return null
				case LOOKUPS.EquipmentVintages:
					if (
						forceRefresh ||
						!lookupState.equipmentVintages ||
						!lookupState.equipmentVintages.length
					)
						return dispatch(listEquipmentVintages())
					return null
				case LOOKUPS.MarketingMaterialCategories:
					if (
						forceRefresh ||
						!lookupState.marketingMaterialCategories ||
						!lookupState.marketingMaterialCategories.length
					)
						return dispatch(listMaterialCategories())
					return null
				case LOOKUPS.InfoRefCategories:
					if (
						forceRefresh ||
						!lookupState.infoRefCategories ||
						!lookupState.infoRefCategories.length
					)
						return dispatch(listInfoRefCategories())
					return null
				case LOOKUPS.PaymentStatuses:
					if (
						forceRefresh ||
						!lookupState.paymentStatuses ||
						!lookupState.paymentStatuses.length
					)
						return dispatch(listPaymentStatuses())
					return null
				case LOOKUPS.ProductionStatuses:
					if (
						forceRefresh ||
						!lookupState.productionStatuses ||
						!lookupState.productionStatuses.length
					)
						return dispatch(listProductionStatuses())
					return null
				case LOOKUPS.PointOfSaleCategories:
					if (
						forceRefresh ||
						!lookupState.pointOfSaleCategories ||
						!lookupState.pointOfSaleCategories.length
					)
						return dispatch(listPointOfSaleCategories())
					return null
				case LOOKUPS.PointOfSaleSystems:
					if (
						forceRefresh ||
						!lookupState.pointOfSaleSystems ||
						!lookupState.pointOfSaleSystems.length
					)
						return dispatch(listPointOfSaleSystems())
					return null
				case LOOKUPS.PointOfSaleManufacturers:
					if (
						forceRefresh ||
						!lookupState.pointOfSaleManufacturers ||
						!lookupState.pointOfSaleManufacturers.length
					)
						return dispatch(listPointOfSaleManufacturers())
					return null
				case LOOKUPS.Locations:
					if (forceRefresh || !lookupState.locations || !lookupState.locations.length)
						return dispatch(listLocations())
					return null
				case LOOKUPS.TicketTypes:
					if (
						forceRefresh ||
						!lookupState.ticketTypes ||
						!lookupState.ticketTypes.length
					)
						return dispatch(listTicketTypes())
					return null
				case LOOKUPS.TicketSubtypes:
					if (
						forceRefresh ||
						!lookupState.ticketSubtypes ||
						!lookupState.ticketSubtypes.length
					)
						return dispatch(listTicketSubtypes())
					return null
				case LOOKUPS.TicketStatuses:
					if (
						forceRefresh ||
						!lookupState.ticketStatuses ||
						!lookupState.ticketStatuses.length
					)
						return dispatch(listTicketStatuses())
					return null
				case LOOKUPS.TicketPriorities:
					if (
						forceRefresh ||
						!lookupState.ticketPriorities ||
						!lookupState.ticketPriorities.length
					)
						return dispatch(listTicketPriorities())
					return null
				case LOOKUPS.TicketGroups:
					if (
						forceRefresh ||
						!lookupState.ticketGroups ||
						!lookupState.ticketGroups.length
					)
						return dispatch(listTicketGroups())
					return null
				case LOOKUPS.TicketRequestors:
					if (
						forceRefresh ||
						!lookupState.ticketRequestors ||
						!lookupState.ticketRequestors.length
					)
						return dispatch(listTicketRequestors())
					return null
				case LOOKUPS.PartOrderStatuses:
					if (
						forceRefresh ||
						!lookupState.partOrderStatuses ||
						!lookupState.partOrderStatuses.length
					)
						return dispatch(listPartOrderStatuses())
					return null
				case LOOKUPS.Vendors:
					if (forceRefresh || !lookupState.vendors || !lookupState.vendors.length)
						return dispatch(listVendors())
					return null
				case LOOKUPS.PickListStatus:
					if (
						forceRefresh ||
						!lookupState.pickListStatuses ||
						!lookupState.pickListStatuses.length
					)
						return dispatch(listPickListStatuses())
					return null
				case LOOKUPS.DeliveryOrderStatuses:
					if (
						forceRefresh ||
						!lookupState.deliveryOrderStatuses ||
						!lookupState.deliveryOrderStatuses.length
					)
						return dispatch(listDeliveryOrderStatuses())
					return null
				case LOOKUPS.DeliveryShipmentMethods:
					if (
						forceRefresh ||
						!lookupState.deliveryShipmentMethods ||
						!lookupState.deliveryShipmentMethods.length
					)
						return dispatch(listDeliveryShipmentMethods())
					return null
				case LOOKUPS.CustomerRoutes:
					if (
						forceRefresh ||
						!lookupState.customerRoutes ||
						!lookupState.customerRoutes.length
					)
						return dispatch(listCustomerRoutes())
					return null
				case LOOKUPS.ProfitMonth:
					if (
						forceRefresh ||
						!lookupState.profitMonths ||
						!lookupState.profitMonths.length
					)
						return dispatch(listProfitMonths())
					return null
				case LOOKUPS.TechAssignments:
					if (
						forceRefresh ||
						!lookupState.techAssignments ||
						!lookupState.techAssignments.length
					)
						return dispatch(listTechAssignments())
					return null
				case LOOKUPS.ShipMethod:
					if (
						forceRefresh ||
						!lookupState.shipMethods ||
						!lookupState.shipMethods.length
					)
						return dispatch(listShipMethods())
					return null
				case LOOKUPS.DispatchPriorities:
					if (
						forceRefresh ||
						!lookupState.dispatchPriorities ||
						!lookupState.dispatchPriorities.length
					)
						return dispatch(listDispatchPriorities())
					return null
				case LOOKUPS.PurchaseOrderStatuses:
					if (
						forceRefresh ||
						!lookupState.purchaseOrderStatuses ||
						!lookupState.purchaseOrderStatuses.length
					)
						return dispatch(listPurchaseOrderStatus())
					return null

				case LOOKUPS.InventoryRequestStatuses:
					if (
						forceRefresh ||
						!lookupState.inventoryRequestStatuses ||
						!lookupState.inventoryRequestStatuses.length
					)
						return dispatch(listInventoryRequestStatus())
					return null

				case LOOKUPS.InventoryTypes:
					if (
						forceRefresh ||
						!lookupState.inventoryTypes ||
						!lookupState.inventoryTypes.length
					)
						return dispatch(listInventoryTypes())
					return null

				case LOOKUPS.PortalFeedbackTypes:
					if (
						forceRefresh ||
						!lookupState.portalFeedbackTypes ||
						!lookupState.portalFeedbackTypes.length
					)
						return dispatch(listPortalFeedbackTypes())
					return null

				case LOOKUPS.DefaultApplications:
					if (
						forceRefresh ||
						!lookupState.defaultApplications ||
						!lookupState.defaultApplications.length
					)
						return dispatch(listDefaultApplications())
					return null

				case LOOKUPS.AllApplications:
					if (
						forceRefresh ||
						!lookupState.allApplications ||
						!lookupState.allApplications.length
					)
						return dispatch(listAllApplications())
					return null

				case LOOKUPS.DefaultReports:
					if (
						forceRefresh ||
						!lookupState.defaultReports ||
						!lookupState.defaultReports.length
					)
						return dispatch(listDefaultReports())
					return null

				case LOOKUPS.AllReports:
					if (forceRefresh || !lookupState.allReports || !lookupState.allReports.length)
						return dispatch(listAllReports())
					return null

				case LOOKUPS.RequestStatus:
					if (
						forceRefresh ||
						!lookupState.transferRequestStatuses ||
						!lookupState.transferRequestStatuses.length
					)
						return dispatch(listTransferRequestStatus())
					return null

				case LOOKUPS.HazmatPackageTypes:
					if (
						forceRefresh ||
						!lookupState.hazmatPackageTypes ||
						!lookupState.hazmatPackageTypes.length
					)
						return dispatch(listHazmatPackageTypes())
					return null

				case LOOKUPS.ContractTypes:
					if (
						forceRefresh ||
						!lookupState.contractTypes ||
						!lookupState.contractTypes.length
					)
						return dispatch(listContractTypes())
					return null

				case LOOKUPS.PartGroups:
					if (forceRefresh || !lookupState.partGroups || !lookupState.partGroups.length)
						return dispatch(listPartGroups())
					return null

				case LOOKUPS.ChemicalMachineTypes:
					if (
						forceRefresh ||
						!lookupState.chemicalMachineTypes ||
						!lookupState.chemicalMachineTypes.length
					)
						return dispatch(listChemicalMachineTypes())
					return null

				case LOOKUPS.NCSEquipmentModels:
					if (
						forceRefresh ||
						!lookupState.ncsEquipmentModels ||
						!lookupState.ncsEquipmentModels.length
					)
						return dispatch(listNCSEquipmentModels())
					return null

				case LOOKUPS.NCSEquipmentVintages:
					if (
						forceRefresh ||
						!lookupState.ncsEquipmentVintages ||
						!lookupState.ncsEquipmentVintages.length
					)
						return dispatch(listNCSEquipmentVintages())
					return null
				case LOOKUPS.CustomerSources:
					if (
						forceRefresh ||
						!lookupState.customerSources ||
						!lookupState.customerSources.length
					)
						return dispatch(listCustomerSources())
					return null

				case LOOKUPS.Organizations:
					if (
						forceRefresh ||
						!lookupState.organizations ||
						!lookupState.organizations.length
					)
						return dispatch(listOrganizationsForEnterprise())
					return null

				case LOOKUPS.WashCountComments:
					if (
						forceRefresh ||
						!lookupState.washCountComments ||
						!lookupState.washCountComments.length
					)
						return dispatch(listWashCountComments())
					return null

				case LOOKUPS.Warehouses:
					if (forceRefresh || !lookupState.warehouses || !lookupState.warehouses.length)
						return dispatch(listWarehouses())
					return null

				case LOOKUPS.ChemicalTechAssignments:
					if (
						forceRefresh ||
						!lookupState.chemicalTechAssignments ||
						!lookupState.chemicalTechAssignments.length
					)
						return dispatch(listChemTechAssignments())
					return null

				default:
					throw new Error(
						"Lookup type not supported for redux. Try using local state with callApi."
					)
			}
		})

		if (typeof callback === "function") {
			Promise.all(promises.filter((x) => x !== null)).then(callback)
		}
	}

	setTimeout(innerFunc, 1)
}

const initialState = {
	isLoadingDispatchStatuses: true,
	dispatchStatuses: [],

	isLoadingInvoiceStatuses: true,
	invoiceStatuses: [],

	isLoadingWorkOrderLineItemTypes: true,
	workOrderLineItemTypes: [],

	isLoadingERPStatuses: true,
	erpStatuses: [],

	isLoadingRegions: true,
	regions: [],

	isLoadingTerritories: true,
	territories: [],

	isLoadingLocations: true,
	locations: [],

	isLoadingEnterprises: true,
	enterprises: [],

	isLoadingPortals: true,
	portals: [],

	isLoadingPortalUsers: true,
	portalUsers: [],

	isLoadingCallTypes: true,
	callTypes: [],

	isLoadingCreditMemoReasons: true,
	creditMemoReasons: [],

	isLoadingPartsCategories: true,
	partsCategories: [],

	isLoadingPartsSystems: true,
	partsSystems: [],
	partsManufacturers: [],

	isLoadingEquipmentVintages: true,
	equipmentVintages: [],

	isLoadingMarketingMaterialCategories: true,
	marketingMaterialCategories: [],

	isLoadingInfoRefCategories: true,
	infoRefCategories: [],

	isLoadingPaymentStatuses: true,
	paymentStatuses: [],

	isLoadingProductionStatuses: true,
	productionStatuses: [],

	isLoadingTicketTypes: true,
	ticketTypes: [],

	isLoadingTicketSubtypes: true,
	ticketSubtypes: [],

	isLoadingTicketPriorities: true,
	ticketPriorities: [],

	isLoadingTicketStatuses: true,
	ticketStatuses: [],

	isLoadingTicketGroups: true,
	ticketGroups: [],

	isLoadingTicketRequestors: true,
	ticketRequestors: [],

	isLoadingPartOrderStatuses: true,
	partOrderStatuses: [],

	isLoadingPointOfSaleCategories: true,
	pointOfSaleCategories: [],

	isLoadingPointOfSaleSystems: true,
	pointOfSaleSystems: [],

	isLoadingPointOfSaleManufacturers: true,
	pointOfSaleManufacturers: [],

	isLoadingVendors: true,
	vendors: [],

	isLoadingPickList: true,
	pickListStatuses: [],

	isLoadingDeliveryOrderStatuses: true,
	deliveryOrderStatuses: [],

	isLoadingCustomerRoutes: true,
	customerRoutes: [],

	isLoadingProfitMonths: true,
	profitMonths: [],

	isLoadingTechAssignments: true,
	techAssignments: [],

	isLoadingShipMethods: true,
	shipMethods: [],

	isLoadingDispatchPriorities: true,
	dispatchPriorities: [],

	isLoadingPurcahaseStatuses: true,
	purchaseOrderStatuses: [],

	isLoadingInventoryRequestStatuses: true,
	inventoryRequestStatuses: [],

	isLoadingInventoryTypes: true,
	inventoryTypes: [],

	isLoadingPortalFeedbackTypes: true,
	portalFeedbackTypes: [],

	isLoadingDefaultApplications: true,
	defaultApplications: [],

	isLoadingAllApplications: true,
	allApplications: [],

	isLoadingDefaultReports: true,
	defaultReports: [],

	isLoadingAllReports: true,
	allReports: [],

	isLoadingTransferRequestStatus: true,
	transferRequestStatuses: [],

	isLoadingHazmatPackageTypes: true,
	hazmatPackageTypes: [],

	isLoadingContractTypes: true,
	contractTypes: [],

	isLoadingPartGroups: true,
	partGroups: [],

	isLoadingChemicalMachineTypes: true,
	chemicalMachineTypes: [],

	isLoadingNCSEquipmentVintages: true,
	ncsEquipmentVintages: [],

	isLoadingNCSEquipmentModels: true,
	ncsEquipmentModels: [],

	isLoadingCustomerSources: true,
	customerSources: [],

	isLoadingOrganizations: true,
	organizations: [],

	isLoadingWashCountComments: true,
	washCountComments: [],

	isLoadingWarehouses: true,
	warehouses: [],

	isLoadingChemTechAssignments: true,
	chemTechAssignments: [],
}

const lookups = (state = initialState, action) => {
	switch (action.type) {
		case LIST_DISPATCH_STATUSES.request:
		case LIST_DISPATCH_STATUSES.failure:
			return {
				...state,
				isLoadingDispatchStatuses: action.type === LIST_DISPATCH_STATUSES.request,
			}
		case LIST_DISPATCH_STATUSES.success:
			return {
				...state,
				isLoadingDispatchStatuses: false,
				dispatchStatuses: action.payload,
			}

		case LIST_INVOICE_STATUSES.request:
		case LIST_INVOICE_STATUSES.failure:
			return {
				...state,
				isLoadingInvoiceStatuses: action.type === LIST_INVOICE_STATUSES.request,
			}
		case LIST_INVOICE_STATUSES.success:
			return {
				...state,
				isLoadingInvoiceStatuses: false,
				invoiceStatuses: action.payload,
			}

		case LIST_WORK_ORDER_LINE_ITEM_TYPES.request:
		case LIST_WORK_ORDER_LINE_ITEM_TYPES.failure:
			return {
				...state,
				isLoadingWorkOrderLineItemTypes:
					action.type === LIST_WORK_ORDER_LINE_ITEM_TYPES.request,
			}
		case LIST_WORK_ORDER_LINE_ITEM_TYPES.success:
			return {
				...state,
				isLoadingWorkOrderLineItemTypes: false,
				workOrderLineItemTypes: action.payload,
			}

		case LIST_EQUIPMENT_REPAIR_PROPOSAL_STATUSES.request:
		case LIST_EQUIPMENT_REPAIR_PROPOSAL_STATUSES.failure:
			return {
				...state,
				isLoadingERPStatuses:
					action.type === LIST_EQUIPMENT_REPAIR_PROPOSAL_STATUSES.request,
			}
		case LIST_EQUIPMENT_REPAIR_PROPOSAL_STATUSES.success:
			return {
				...state,
				isLoadingERPStatuses: false,
				erpStatuses: action.payload,
			}

		case LIST_REGIONS.request:
		case LIST_REGIONS.failure:
			return {
				...state,
				isLoadingRegions: action.type === LIST_REGIONS.request,
			}
		case LIST_REGIONS.success:
			return {
				...state,
				isLoadingRegions: false,
				regions: action.payload,
			}

		case LIST_TERRITORIES.request:
		case LIST_TERRITORIES.failure:
			return {
				...state,
				isLoadingTerritories: action.type === LIST_TERRITORIES.request,
			}
		case LIST_TERRITORIES.success:
			return {
				...state,
				isLoadingTerritories: false,
				territories: action.payload,
			}

		case LIST_LOCATIONS.request:
		case LIST_LOCATIONS.failure:
			return {
				...state,
				isLoadingLocations: action.type === LIST_LOCATIONS.request,
			}
		case LIST_LOCATIONS.success:
			return {
				...state,
				isLoadingLocations: false,
				locations: action.payload,
			}

		case LIST_ENTERPRISES.request:
		case LIST_ENTERPRISES.failure:
			return {
				...state,
				isLoadingEnterprises: action.type === LIST_ENTERPRISES.request,
			}
		case LIST_ENTERPRISES.success:
			return {
				...state,
				isLoadingEnterprises: false,
				enterprises: action.payload,
			}

		case LIST_PORTALS.request:
		case LIST_PORTALS.failure:
			return {
				...state,
				isLoadingPortals: action.type === LIST_PORTALS.request,
			}
		case LIST_PORTALS.success:
			return {
				...state,
				isLoadingPortals: false,
				portals: action.payload,
			}

		case LIST_PORTAL_USERS.request:
		case LIST_PORTAL_USERS.failure:
			return {
				...state,
				isLoadingPortalUsers: action.type === LIST_PORTAL_USERS.request,
			}
		case LIST_PORTAL_USERS.success:
			return {
				...state,
				isLoadingPortalUsers: false,
				portalUsers: action.payload,
			}

		case LIST_CALL_TYPES.request:
		case LIST_CALL_TYPES.failure:
			return {
				...state,
				isLoadingCallTypes: action.type === LIST_CALL_TYPES.request,
			}
		case LIST_CALL_TYPES.success:
			return {
				...state,
				isLoadingCallTypes: false,
				callTypes: action.payload,
			}

		case LIST_CREDIT_MEMO_REASONS.request:
		case LIST_CREDIT_MEMO_REASONS.failure:
			return {
				...state,
				isLoadingCreditMemoReasons: action.type === LIST_CREDIT_MEMO_REASONS.request,
			}
		case LIST_CREDIT_MEMO_REASONS.success:
			return {
				...state,
				isLoadingCreditMemoReasons: false,
				creditMemoReasons: action.payload,
			}

		case LIST_PARTS_CATEGORIES.request:
		case LIST_PARTS_CATEGORIES.failure:
			return {
				...state,
				isLoadingPartsCategories: action.type === LIST_PARTS_CATEGORIES.request,
			}
		case LIST_PARTS_CATEGORIES.success:
			return {
				...state,
				isLoadingPartsCategories: false,
				partsCategories: action.payload,
			}

		case LIST_PARTS_SYSTEMS.request:
		case LIST_PARTS_SYSTEMS.failure:
			return {
				...state,
				isLoadingPartsSystems: action.type === LIST_PARTS_SYSTEMS.request,
			}
		case LIST_PARTS_SYSTEMS.success:
			return {
				...state,
				isLoadingPartsSystems: false,
				partsSystems: action.payload,
				partsManufacturers: parseSystemHierarchy(action.payload),
			}

		case LIST_EQUIPMENT_VINTAGES.request:
		case LIST_EQUIPMENT_VINTAGES.failure:
			return {
				...state,
				isLoadingEquipmentVintages: action.type === LIST_EQUIPMENT_VINTAGES.request,
			}
		case LIST_EQUIPMENT_VINTAGES.success:
			return {
				...state,
				isLoadingEquipmentVintages: false,
				equipmentVintages: action.payload,
			}

		case LIST_MATERIAL_CATEGORIES.request:
		case LIST_MATERIAL_CATEGORIES.failure:
			return {
				...state,
				isLoadingMarketingMaterialCategories:
					action.type === LIST_MATERIAL_CATEGORIES.request,
			}
		case LIST_MATERIAL_CATEGORIES.success:
			return {
				...state,
				isLoadingMarketingMaterialCategories: false,
				marketingMaterialCategories: action.payload,
			}

		case LIST_INFO_REF_CATEGORIES.request:
		case LIST_INFO_REF_CATEGORIES.failure:
			return {
				...state,
				isLoadingInfoRefCategories: action.type === LIST_INFO_REF_CATEGORIES.request,
			}
		case LIST_INFO_REF_CATEGORIES.success:
			return {
				...state,
				isLoadingInfoRefCategories: false,
				infoRefCategories: action.payload,
			}

		case LIST_PAYMENT_STATUSES.request:
		case LIST_PAYMENT_STATUSES.failure:
			return {
				...state,
				isLoadingPaymentStatuses: action.type === LIST_PAYMENT_STATUSES.request,
			}
		case LIST_PAYMENT_STATUSES.success:
			return {
				...state,
				isLoadingPaymentStatuses: false,
				paymentStatuses: action.payload,
			}

		case LIST_PRODUCTION_STATUSES.request:
		case LIST_PRODUCTION_STATUSES.failure:
			return {
				...state,
				isLoadingProductionStatuses: action.type === LIST_PRODUCTION_STATUSES.request,
			}
		case LIST_PRODUCTION_STATUSES.success:
			return {
				...state,
				isLoadingProductionStatuses: false,
				productionStatuses: action.payload,
			}

		case LIST_TICKET_TYPES.request:
		case LIST_TICKET_TYPES.failure:
			return {
				...state,
				isLoadingTicketTypes: action.type === LIST_TICKET_TYPES.request,
			}
		case LIST_TICKET_TYPES.success:
			return {
				...state,
				isLoadingTicketTypes: false,
				ticketTypes: action.payload,
			}

		case LIST_TICKET_SUBTYPES.request:
		case LIST_TICKET_SUBTYPES.failure:
			return {
				...state,
				isLoadingTicketSubtypes: action.type === LIST_TICKET_SUBTYPES.request,
			}
		case LIST_TICKET_SUBTYPES.success:
			return {
				...state,
				isLoadingTicketSubtypes: false,
				ticketSubtypes: action.payload,
			}

		case LIST_TICKET_PRIORITIES.request:
		case LIST_TICKET_PRIORITIES.failure:
			return {
				...state,
				isLoadingTicketPriorities: action.type === LIST_TICKET_PRIORITIES.request,
			}
		case LIST_TICKET_PRIORITIES.success:
			return {
				...state,
				isLoadingTicketPriorities: false,
				ticketPriorities: action.payload,
			}

		case LIST_TICKET_STATUSES.request:
		case LIST_TICKET_STATUSES.failure:
			return {
				...state,
				isLoadingTicketStatuses: action.type === LIST_TICKET_STATUSES.request,
			}
		case LIST_TICKET_STATUSES.success:
			return {
				...state,
				isLoadingTicketStatuses: false,
				ticketStatuses: action.payload,
			}

		case LIST_TICKET_GROUPS.request:
		case LIST_TICKET_GROUPS.failure:
			return {
				...state,
				isLoadingTicketGroups: action.type === LIST_TICKET_GROUPS.request,
			}
		case LIST_TICKET_GROUPS.success:
			return {
				...state,
				isLoadingTicketGroups: false,
				ticketGroups: action.payload,
			}

		case LIST_TICKET_REQUESTORS.request:
		case LIST_TICKET_REQUESTORS.failure:
			return {
				...state,
				isLoadingTicketRequestors: action.type === LIST_TICKET_REQUESTORS.request,
			}
		case LIST_TICKET_REQUESTORS.success:
			return {
				...state,
				isLoadingTicketRequestors: false,
				ticketRequestors: action.payload,
			}

		case LIST_PART_ORDER_STATUSES.request:
		case LIST_PART_ORDER_STATUSES.failure:
			return {
				...state,
				isLoadingPartOrderStatuses: action.type === LIST_PART_ORDER_STATUSES.request,
			}
		case LIST_PART_ORDER_STATUSES.success:
			return {
				...state,
				isLoadingPartOrderStatuses: false,
				partOrderStatuses: action.payload,
			}

		case LIST_POINT_OF_SALE_CATEGORIES.request:
		case LIST_POINT_OF_SALE_CATEGORIES.failure:
			return {
				...state,
				isLoadingPointOfSaleCategories:
					action.type === LIST_POINT_OF_SALE_CATEGORIES.request,
			}
		case LIST_POINT_OF_SALE_CATEGORIES.success:
			return {
				...state,
				isLoadingPointOfSaleCategories: false,
				pointOfSaleCategories: action.payload,
			}

		case LIST_POINT_OF_SALE_SYSTEMS.request:
		case LIST_POINT_OF_SALE_SYSTEMS.failure:
			return {
				...state,
				isLoadingPointOfSaleSystems: action.type === LIST_POINT_OF_SALE_SYSTEMS.request,
			}
		case LIST_POINT_OF_SALE_SYSTEMS.success:
			return {
				...state,
				isLoadingPointOfSaleSystems: false,
				pointOfSaleSystems: action.payload,
			}

		case LIST_POINT_OF_SALE_MANUFACTURERS.request:
		case LIST_POINT_OF_SALE_MANUFACTURERS.failure:
			return {
				...state,
				isLoadingPointOfSaleManufacturers:
					action.type === LIST_POINT_OF_SALE_MANUFACTURERS.request,
			}
		case LIST_POINT_OF_SALE_MANUFACTURERS.success:
			return {
				...state,
				isLoadingPointOfSaleManufacturers: false,
				pointOfSaleManufacturers: action.payload,
			}

		case LIST_VENDORS.request:
		case LIST_VENDORS.failure:
			return {
				...state,
				isLoadingVendors: action.type === LIST_VENDORS.request,
			}
		case LIST_VENDORS.success:
			return {
				...state,
				isLoadingVendors: false,
				vendors: action.payload,
			}

		case LIST_PICK_LIST_STATUSES.request:
		case LIST_PICK_LIST_STATUSES.failure:
			return {
				...state,
				isLoadingPickList: action.type === LIST_PICK_LIST_STATUSES.request,
			}
		case LIST_PICK_LIST_STATUSES.success:
			return {
				...state,
				isLoadingPickList: false,
				pickListStatuses: action.payload,
			}

		case LIST_DELIVERY_ORDER_STATUSES.request:
		case LIST_DELIVERY_ORDER_STATUSES.failure:
			return {
				...state,
				isLoadingDeliveryOrderStatuses:
					action.type === LIST_DELIVERY_ORDER_STATUSES.request,
			}
		case LIST_DELIVERY_ORDER_STATUSES.success:
			return {
				...state,
				isLoadingDeliveryOrderStatuses: false,
				deliveryOrderStatuses: action.payload,
			}

		case LIST_DELIVERY_SHIPMENT_METHODS.request:
		case LIST_DELIVERY_SHIPMENT_METHODS.failure:
			return {
				...state,
				isLoadingDeliveryShipmentMethods:
					action.type === LIST_DELIVERY_SHIPMENT_METHODS.request,
			}
		case LIST_DELIVERY_SHIPMENT_METHODS.success:
			return {
				...state,
				isLoadingDeliveryShipmentMethods: false,
				deliveryShipmentMethods: action.payload,
			}

		case LIST_CUSTOMER_ROUTES.request:
		case LIST_CUSTOMER_ROUTES.failure:
			return {
				...state,
				isLoadingCustomerRoutes: action.type === LIST_CUSTOMER_ROUTES.request,
			}
		case LIST_CUSTOMER_ROUTES.success:
			return {
				...state,
				isLoadingCustomerRoutes: false,
				customerRoutes: action.payload,
			}

		case LIST_PROFIT_MONTHS.request:
		case LIST_PROFIT_MONTHS.failure:
			return {
				...state,
				isLoadingProfitMonths: action.type === LIST_PROFIT_MONTHS.request,
			}
		case LIST_PROFIT_MONTHS.success:
			return {
				...state,
				isLoadingProfitMonths: false,
				profitMonths: action.payload,
			}

		case LIST_TECH_ASSIGNMENTS.request:
		case LIST_TECH_ASSIGNMENTS.failure:
			return {
				...state,
				isLoadingTechAssignments: action.type === LIST_TECH_ASSIGNMENTS.request,
			}
		case LIST_TECH_ASSIGNMENTS.success:
			return {
				...state,
				isLoadingTechAssignments: false,
				techAssignments: action.payload,
			}

		case LIST_SHIP_METHOD.request:
		case LIST_SHIP_METHOD.failure:
			return {
				...state,
				isLoadingShipMethods: action.type === LIST_SHIP_METHOD.request,
			}
		case LIST_SHIP_METHOD.success:
			return {
				...state,
				isLoadingShipMethods: false,
				shipMethods: action.payload,
			}

		case LIST_DISPATCH_PRIORITIES.request:
		case LIST_DISPATCH_PRIORITIES.failure:
			return {
				...state,
				isLoadingDispatchPriorities: action.type === LIST_DISPATCH_PRIORITIES.request,
			}
		case LIST_DISPATCH_PRIORITIES.success:
			return {
				...state,
				isLoadingDispatchPriorities: false,
				dispatchPriorities: action.payload,
			}

		case LIST_PURCHASE_ORDER_STATUS.request:
		case LIST_PURCHASE_ORDER_STATUS.failure:
			return {
				...state,
				isLoadingPurcahaseStatuses: action.type === LIST_PURCHASE_ORDER_STATUS.request,
			}
		case LIST_PURCHASE_ORDER_STATUS.success:
			return {
				...state,
				isLoadingPurcahaseStatuses: false,
				purchaseOrderStatuses: action.payload,
			}

		case LIST_INVENTORY_REQUEST_STATUS.request:
		case LIST_INVENTORY_REQUEST_STATUS.failure:
			return {
				...state,
				isLoadingInventoryRequestStatuses:
					action.type === LIST_INVENTORY_REQUEST_STATUS.request,
			}
		case LIST_INVENTORY_REQUEST_STATUS.success:
			return {
				...state,
				isLoadingPurcahaseStatuses: false,
				inventoryRequestStatuses: action.payload,
			}

		case LIST_INVENTORY_TYPES.request:
		case LIST_INVENTORY_TYPES.failure:
			return {
				...state,
				isLoadingInventoryTypes: action.type === LIST_INVENTORY_TYPES.request,
			}
		case LIST_INVENTORY_TYPES.success:
			return {
				...state,
				isLoadingInventoryTypes: false,
				inventoryTypes: action.payload,
			}

		case LIST_PORTAL_FEEDBACK_TYPES.request:
		case LIST_PORTAL_FEEDBACK_TYPES.failure:
			return {
				...state,
				isLoadingPortalFeedbackTypes: action.type === LIST_PORTAL_FEEDBACK_TYPES.request,
			}
		case LIST_PORTAL_FEEDBACK_TYPES.success:
			return {
				...state,
				isLoadingPortalFeedbackTypes: false,
				portalFeedbackTypes: action.payload,
			}

		case LIST_ALL_APPLICATIONS.request:
		case LIST_ALL_APPLICATIONS.failure:
			return {
				...state,
				isLoadingAllApplications: action.type === LIST_ALL_APPLICATIONS.request,
			}
		case LIST_ALL_APPLICATIONS.success:
			return {
				...state,
				isLoadingAllApplications: false,
				allApplications: action.payload,
			}

		case LIST_DEFAULT_APPLICATIONS.request:
		case LIST_DEFAULT_APPLICATIONS.failure:
			return {
				...state,
				isLoadingDefaultApplications: action.type === LIST_DEFAULT_APPLICATIONS.request,
			}
		case LIST_DEFAULT_APPLICATIONS.success:
			return {
				...state,
				isLoadingDefaultApplications: false,
				defaultApplications: action.payload,
			}

		case LIST_ALL_REPORTS.request:
		case LIST_ALL_REPORTS.failure:
			return {
				...state,
				isLoadingAllReports: action.type === LIST_ALL_REPORTS.request,
			}
		case LIST_ALL_REPORTS.success:
			return {
				...state,
				isLoadingAllReports: false,
				allReports: action.payload,
			}

		case LIST_DEFAULT_REPORTS.request:
		case LIST_DEFAULT_REPORTS.failure:
			return {
				...state,
				isLoadingDefaultReports: action.type === LIST_DEFAULT_REPORTS.request,
			}
		case LIST_DEFAULT_REPORTS.success:
			return {
				...state,
				isLoadingDefaultReports: false,
				defaultReports: action.payload,
			}

		case LIST_REQUEST_STATUS.request:
		case LIST_REQUEST_STATUS.failure:
			return {
				...state,
				isLoadingTransferRequestStatus: action.type === LIST_REQUEST_STATUS.request,
			}
		case LIST_REQUEST_STATUS.success:
			return {
				...state,
				isLoadingTransferRequestStatus: false,
				transferRequestStatuses: action.payload,
			}
		case LIST_HAZMAT_PACKAGE_TYPES.request:
		case LIST_HAZMAT_PACKAGE_TYPES.failure:
			return {
				...state,
				isLoadingHazmatPackageTypes: action.type === LIST_HAZMAT_PACKAGE_TYPES.request,
			}
		case LIST_HAZMAT_PACKAGE_TYPES.success:
			return {
				...state,
				isLoadingHazmatPackageTypes: false,
				hazmatPackageTypes: action.payload,
			}

		case LIST_CONTRACT_TYPES.request:
		case LIST_CONTRACT_TYPES.failure:
			return {
				...state,
				isLoadingContractTypes: action.type === LIST_CONTRACT_TYPES.request,
			}
		case LIST_CONTRACT_TYPES.success:
			return {
				...state,
				isLoadingContractTypes: false,
				contractTypes: action.payload,
			}

		case LIST_PART_GROUPS.request:
		case LIST_PART_GROUPS.failure:
			return {
				...state,
				isLoadingPartGroups: action.type === LIST_PART_GROUPS.request,
			}
		case LIST_PART_GROUPS.success:
			return {
				...state,
				isLoadingPartGroups: false,
				partGroups: unpythonify(action.payload),
			}

		case LIST_CHEMICAL_MACHINE_TYPES.request:
		case LIST_CHEMICAL_MACHINE_TYPES.failure:
			return {
				...state,
				isLoadingChemicalMachineTypes: action.type === LIST_CHEMICAL_MACHINE_TYPES.request,
			}
		case LIST_CHEMICAL_MACHINE_TYPES.success:
			return {
				...state,
				isLoadingChemicalMachineTypes: false,
				chemicalMachineTypes: action.payload,
			}

		case NCS_EQUIPMENT_MODELS.request:
		case NCS_EQUIPMENT_MODELS.failure:
			return {
				...state,
				isLoadingNCSEquipmentModels: action.type === NCS_EQUIPMENT_MODELS.request,
			}
		case NCS_EQUIPMENT_MODELS.success:
			return {
				...state,
				isLoadingNCSEquipmentModels: false,
				ncsEquipmentModels: action.payload,
			}

		case NCS_EQUIPMENT_VINTAGES.request:
		case NCS_EQUIPMENT_VINTAGES.failure:
			return {
				...state,
				isLoadingNCSEquipmentVintages: action.type === NCS_EQUIPMENT_VINTAGES.request,
			}
		case NCS_EQUIPMENT_VINTAGES.success:
			return {
				...state,
				isLoadingNCSEquipmentVintages: false,
				ncsEquipmentVintages: action.payload,
			}
		case CUSTOMER_SOURCES.request:
		case CUSTOMER_SOURCES.failure:
			return {
				...state,
				isLoadingCustomerSources: action.type === CUSTOMER_SOURCES.request,
			}
		case CUSTOMER_SOURCES.success:
			return {
				...state,
				isLoadingCustomerSources: false,
				customerSources: action.payload,
			}

		case LIST_ORGANIZATIONS_FOR_REGION.request:
		case LIST_ORGANIZATIONS_FOR_REGION.failure:
			return {
				...state,
				isLoadingOrganizations: action.type === LIST_ORGANIZATIONS_FOR_REGION.request,
			}
		case LIST_ORGANIZATIONS_FOR_REGION.success:
			return {
				...state,
				isLoadingOrganizations: false,
				organizations: action.payload,
			}

		case LIST_WASH_COUNT_COMMENTS.request:
		case LIST_WASH_COUNT_COMMENTS.failure:
			return {
				...state,
				isLoadingWashCountComments: action.type === LIST_WASH_COUNT_COMMENTS.request,
			}
		case LIST_WASH_COUNT_COMMENTS.success:
			return {
				...state,
				isLoadingWashCountComments: false,
				washCountComments: action.payload,
			}

		case LIST_WAREHOUSES.request:
		case LIST_WAREHOUSES.failure:
			return {
				...state,
				isLoadingWarehouses: action.type === LIST_WAREHOUSES.request,
			}
		case LIST_WAREHOUSES.success:
			return {
				...state,
				isLoadingWarehouses: false,
				warehouses: action.payload,
			}

		case LIST_CHEMICAL_TECH_ASSIGNMENTS.request:
		case LIST_CHEMICAL_TECH_ASSIGNMENTS.failure:
			return {
				...state,
				isLoadingChemTechAssignments:
					action.type === LIST_CHEMICAL_TECH_ASSIGNMENTS.request,
			}
		case LIST_CHEMICAL_TECH_ASSIGNMENTS.success:
			return {
				...state,
				isLoadingChemTechAssignments: false,
				chemTechAssignments: action.payload,
			}

		default:
			return state
	}
}
export default lookups

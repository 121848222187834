import { FC, ReactElement, useMemo } from "react"

import { Cell, Column } from "react-table"

import { EquipmentVintage, EquipmentVintageQueryParams, useEquipmentVintages } from "@ncs/ncs-api"
import {
	Box,
	Button,
	EmptyValueDash,
	EquipmentManufacturerQueryFilter,
	EquipmentModelQueryFilter as DefaultEquipmentModelQueryFilter,
	EquipmentModelQueryFilterProps,
	Icon,
	IsActiveQueryFilter,
	SearchQueryFilter,
	Table,
	TableProps,
	useStateReset,
	useUrlState,
} from "@ncs/web-legos"

import {
	EditVintageModal,
	VintageCustomersModal,
	VintageDocumentsModal,
	VintagePartsUsedModal,
	VintageWorkOrdersModal,
} from "./components"

export type VintagesTabParamUrlState = EquipmentVintageQueryParams

export const VintagesTab: FC = () => {
	const [modalState, setModalState, resetModalState] = useStateReset(defaultModalState)
	const [params, { setUrlState: setParamUrlState }] = useUrlState(defaultParamsUrlState)

	const vintagesQuery = useEquipmentVintages({ params })

	const EquipmentModelQueryFilter = useMemo(
		() =>
			(props: EquipmentModelQueryFilterProps<VintagesTabParamUrlState>): ReactElement => {
				return (
					<DefaultEquipmentModelQueryFilter
						{...props}
						label="Model"
						manufacturer={params.manufacturer}
					/>
				)
			},
		[params.manufacturer]
	)

	const rowMenu = useMemo((): TableProps<EquipmentVintage>["rowMenu"] => {
		return [
			{
				label: "Edit",
				iconName: "pencil",
				onClick: ({ original }) =>
					setModalState({
						vintage: original,
						modalType: "edit",
					}),
			},
			{
				label: "Customers",
				iconName: "user",
				onClick: ({ original }) =>
					setModalState({
						vintage: original,
						modalType: "customers",
					}),
			},
			{
				label: "Parts used",
				iconName: "tools",
				onClick: ({ original }) =>
					setModalState({
						vintage: original,
						modalType: "parts",
					}),
			},
			{
				label: "Work orders",
				iconName: "user-hard-hat",
				onClick: ({ original }) =>
					setModalState({
						vintage: original,
						modalType: "work-orders",
					}),
			},
			{
				label: "Documents",
				iconName: "copy",
				onClick: ({ original }) =>
					setModalState({
						vintage: original,
						modalType: "documents",
					}),
			},
		]
	}, [setModalState])

	return (
		<>
			<Box textAlign="right">
				<Button
					icon="plus"
					onClick={() =>
						setModalState({
							vintage: null,
							modalType: "edit",
						})
					}
				>
					Create vintage
				</Button>
			</Box>

			<Table
				query={vintagesQuery}
				columns={columns}
				queryParamState={params}
				setQueryParamState={setParamUrlState}
				pinnedQueryFilters={[
					SearchQueryFilter,
					EquipmentModelQueryFilter,
					EquipmentManufacturerQueryFilter,
					IsActiveQueryFilter,
				]}
				rowMenu={rowMenu}
			/>

			{modalState.modalType === "edit" && (
				<EditVintageModal vintage={modalState.vintage} onClose={resetModalState} />
			)}
			{modalState.modalType === "customers" && (
				<VintageCustomersModal vintage={modalState.vintage} onClose={resetModalState} />
			)}
			{modalState.modalType === "parts" && (
				<VintagePartsUsedModal vintage={modalState.vintage} onClose={resetModalState} />
			)}
			{modalState.modalType === "work-orders" && (
				<VintageWorkOrdersModal vintage={modalState.vintage} onClose={resetModalState} />
			)}
			{modalState.modalType === "documents" && (
				<VintageDocumentsModal vintage={modalState.vintage} onClose={resetModalState} />
			)}
		</>
	)
}

const columns: Column<EquipmentVintage>[] = [
	{
		Header: "Vintage name",
		id: "name",
		accessor: ({ name }) => name || <EmptyValueDash />,
	},
	{
		Header: "Serial # Start",
		disableSortBy: true,
		accessor: ({ beginSerialNumber }) => beginSerialNumber || <EmptyValueDash />,
	},
	{
		Header: "Serial # End",
		disableSortBy: true,
		accessor: ({ endSerialNumber }) => endSerialNumber || <EmptyValueDash />,
	},
	{
		Header: "Model",
		disableSortBy: true,
		accessor: ({ eqpModel }) => eqpModel?.modelName || <EmptyValueDash />,
	},
	{
		Header: "Manufacturer",
		disableSortBy: true,
		accessor: ({ eqpModel }) => eqpModel?.manufacturer?.name || <EmptyValueDash />,
	},
	{
		Header: "Is active?",
		id: "isActive",
		accessor: "isActive",
		Cell: ({ row: { original } }: Cell<EquipmentVintage>) => {
			return original.isActive ?
					<>
						<Icon icon="check" color="gray" /> Active
					</>
				:	<EmptyValueDash />
		},
	},
]

const defaultParamsUrlState: VintagesTabParamUrlState = {
	ordering: null,
	search: null,
	model: null,
	manufacturer: null,
	isActive: null,
}

type ModalState =
	| {
			vintage: EquipmentVintage | null
			modalType: "edit"
	  }
	| {
			vintage: EquipmentVintage
			modalType: "customers" | "parts" | "work-orders" | "documents"
	  }
	| {
			vintage: null
			modalType: null
	  }

const defaultModalState: ModalState = {
	vintage: null,
	modalType: null,
}

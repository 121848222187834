import React, { HTMLAttributes, useMemo } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { makeStyles } from "@material-ui/core"

import styles from "../../assets/jss/material-dashboard-pro-react/components/cardAvatarStyle"

const useStyles = makeStyles(styles)

export interface CardAvatarProps extends HTMLAttributes<HTMLDivElement> {
	className?: string
	profile?: boolean
	plain?: boolean
	testimonial?: boolean
	testimonialFooter?: boolean
}

const CardAvatar: React.FC<CardAvatarProps> = ({
	className,
	plain,
	profile,
	testimonial,
	testimonialFooter,
	children,
	...rest
}) => {
	const classes = useStyles()

	const cardAvatarClasses = useMemo(
		() =>
			classNames(className, {
				[classes.cardAvatar]: true,
				[classes.cardAvatarProfile]: profile,
				[classes.cardAvatarPlain]: plain,
				[classes.cardAvatarTestimonial]: testimonial,
				[classes.cardAvatarTestimonialFooter]: testimonialFooter,
			}),
		[classes, className, plain, profile, testimonial, testimonialFooter]
	)

	return (
		<div className={cardAvatarClasses} {...rest}>
			{children}
		</div>
	)
}

CardAvatar.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	profile: PropTypes.bool,
	plain: PropTypes.bool,
	testimonial: PropTypes.bool,
	testimonialFooter: PropTypes.bool,
}

export default CardAvatar

import { get, rsaaTypes, RSAA } from "@ncs/bricks/middleware/portalApiMiddleware"

// TODO: delete the get line item types call, it's in lookups now
export const GET_LINE_ITEM_TYPES = rsaaTypes("workorders/get_line_item_types")

export const getLineItemTypes = () => ({
	[RSAA]: {
		api: get(`/workorders/workorder_initial/`),
		types: GET_LINE_ITEM_TYPES,
	},
})

const initialState = {
	isLoadingLineItemTypes: false,
	echo: undefined,
	error: undefined,
}

const workOrders = (state = initialState, action) => {
	switch (action.type) {
		case GET_LINE_ITEM_TYPES.request:
			return {
				...state,
				isLoadingLineItemTypes: true,
			}
		case GET_LINE_ITEM_TYPES.success:
			return {
				...state,
				isLoadingLineItemTypes: false,
				lineItemTypes: [...action.payload.types, { text: "PT - Parts", value: 21 }],
				error: action.error,
			}
		case GET_LINE_ITEM_TYPES.failure:
			return {
				...state,
				isLoadingLineItemTypes: false,
				lineItemTypes: undefined,
				error: action.error,
			}
		default:
			return state
	}
}
export default workOrders

import { FC, useEffect, useRef, useState } from "react"

import { useVendors, Vendor } from "@ncs/ncs-api"

import { Button } from "../buttons"
import { ExtendableSearchableSelectProps, SearchableSelect } from "../inputs"
import { VendorEditModal } from "../modals"
import { AnimatedEntrance } from "../transitions"

export interface VendorSelectorProps extends ExtendableSearchableSelectProps<Vendor> {
	value: Vendor | null
	onChange: (newValue: Vendor | null) => void
	initialId?: string | null
	/**
	 * If `true`, show a button that will open the `VendorEditModal`.
	 */
	promptVendorUpdate?: boolean
}

export const VendorSelector: FC<VendorSelectorProps> = ({
	value,
	onChange,
	initialId,
	promptVendorUpdate,
	...rest
}) => {
	const [vendors, isLoading] = useVendors()
	const hasSetInitialId = useRef(false)
	const [vendorToEdit, setVendorToEdit] = useState<Vendor | null>(null)

	useEffect(() => {
		if (!!initialId && !value && !!vendors && !hasSetInitialId.current) {
			const initialVendor = vendors.find((v) => String(v.id) === initialId)

			if (initialVendor) {
				onChange(initialVendor)
				hasSetInitialId.current = true
			}
		}
	}, [initialId, value, vendors, onChange])

	return (
		<>
			<SearchableSelect
				label="Supplier"
				placeholder="Search for a supplier..."
				getOptionLabel={(option) => `(${option.vendorCode}) ${option.name}`}
				isLoading={isLoading}
				{...rest}
				options={vendors ?? []}
				value={value}
				onItemSelect={onChange}
			/>

			<AnimatedEntrance show={!!value && !!promptVendorUpdate} direction="fade">
				<Button
					icon="address-card"
					onClick={() => setVendorToEdit(value)}
					containerProps={{
						display: "flex",
						justifyContent: "flex-end",
						mt: -0.5,
						mb: 1,
					}}
				>
					Update supplier contact info?
				</Button>
			</AnimatedEntrance>

			<VendorEditModal
				isOpen={!!vendorToEdit && !!promptVendorUpdate}
				vendorId={vendorToEdit?.id.toString() ?? null}
				onClose={() => setVendorToEdit(null)}
			/>
		</>
	)
}

import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import callApi from "@ncs/bricks/redux/services/callApi"
import { Select } from "@ncs/bricks/components"
import { loadLookups, LOOKUPS } from "../../redux/services/lookups"

class TerritorySelector extends React.PureComponent {
	componentDidMount() {
		this.props.loadLookups([LOOKUPS.Territories])
	}

	render() {
		const { displayEmpty, ...rest } = this.props

		return (
			<Select
				id="territories"
				labelText="Territory"
				value={this.props.territory || ""}
				onChange={this.props.onChange}
				options={[
					{
						_id: "",
						description: "Select a territory",
						code: null,
						disabled: false,
					},
					...this.props.territories,
				]}
				labelProps={{
					shrink: displayEmpty ? true : undefined,
					...rest.labelProps,
				}}
				valueAccessor="_id"
				textAccessor={(x) =>
					x.code ? `${x.description} (${x.code})` : `${x.description}`
				}
				selectProps={{
					displayEmpty: displayEmpty,
					...rest.selectProps,
				}}
				{...rest}
			/>
		)
	}
}

TerritorySelector.defaultProps = {
	displayEmpty: true,
}

TerritorySelector.propTypes = {
	territory: PropTypes.string,
	onChange: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
	return {
		territories: state.lookups.territories,
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			callApi,
			loadLookups,
		},
		dispatch
	)

export default connect(mapStateToProps, mapDispatchToProps)(TerritorySelector)

// ##############################
// // // LoginPage view styles
// #############################

import { container, cardTitle, grayColor, whiteColor } from "../../material-dashboard-pro-react"
//import logo from "../../../img/FamilyLogo.png"

const loginPageStyle = {
	container,
	cardTitle: {
		...cardTitle,
		color: whiteColor,
	},
	textCenter: {
		textAlign: "center",
	},
	content: {
		paddingTop: "18vh",
		minHeight: "calc(100vh - 80px)",
		position: "relative",
		zIndex: "4",
	},
	justifyContentCenter: {
		justifyContent: "center !important",
		flexDirection: "column",
	},
	customButtonClass: {
		"&,&:focus,&:hover": {
			color: whiteColor,
		},
		marginLeft: "5px",
		marginRight: "5px",
	},
	inputAdornment: {
		marginRight: "18px",
	},
	inputAdornmentIcon: {
		color: grayColor[6],
	},
	cardHidden: {
		opacity: "0",
		transform: "translate3d(0, -60px, 0)",
	},
	cardHeader: {
		marginBottom: "20px",
	},
	socialLine: {
		padding: "0.9375rem 0",
	},
	backgroundImage: {
		//backgroundImage: `url(${logo})`,
		backgroundPosition: "center",
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
	},
}

export default loginPageStyle

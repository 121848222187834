import React, { useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { makeStyles } from "@material-ui/core"
import { ArrowDropDown, Cancel } from "@material-ui/icons"
import { rowCommentStyles } from "./rowCommentStyles"

const useStyles = makeStyles(rowCommentStyles)

// Defined in the backend, this is the ID of the comment that means we'll be
// writing a new last month ending tally upon submission.
export const RESET_COMMENT_ID = "3"

const RowComment = ({ errorMessage, onCommentChange, isSubmitted }) => {
	const classes = useStyles()
	const [localCommentId, setLocalCommentId] = useState(null)
	const [localCustomComment, setLocalCustomComment] = useState(null)
	const predefinedComments = useSelector((store) => store.lookups.washCountComments)

	// Add one more comment option for writing your own custom comment.
	const customCommentId = String(predefinedComments.length + 1)
	const comments = useMemo(
		() =>
			predefinedComments.reduce((acc, comment) => ({ ...acc, [comment.id]: comment }), {
				[customCommentId]: {
					id: customCommentId,
					description: "Write your own comment...",
				},
			}),
		[customCommentId, predefinedComments]
	)

	const handleCommentChange = (e) => {
		const id = e.target.value

		// Update local state.
		setLocalCommentId(id)
		setLocalCustomComment(null)

		// Now update parent state.
		const newComment =
			id === customCommentId ? localCustomComment : comments[id]?.description ?? null
		// Note that if we're using a custom comment the parent state (and the
		// backend) will just want null for the ID.
		const newCommentId = id === customCommentId ? null : id
		setTimeout(() => onCommentChange(newCommentId, newComment), 1)
	}

	const handleCustomCommentChange = (e) => {
		const { value } = e.target

		setLocalCustomComment(value)
		setTimeout(() => onCommentChange(null, value), 1)
	}

	const removeCustomComment = () => {
		setLocalCommentId(null)
		setLocalCustomComment(null)

		setTimeout(() => onCommentChange(null, null), 1)
	}

	return (
		<div>
			{errorMessage && (
				<div className={classes.errorMessage}>
					<span>{errorMessage}</span>
				</div>
			)}

			<div className={classes.commentContainer}>
				{isSubmitted && (
					<span>
						{localCommentId === customCommentId ?
							localCustomComment
						:	comments[localCommentId]?.description ?? ""}
					</span>
				)}

				{!isSubmitted &&
					(localCommentId !== customCommentId ?
						<div className={classes.commentSelect}>
							<ArrowDropDown />
							<select
								value={localCommentId || ""}
								onChange={handleCommentChange}
								disabled={isSubmitted}
							>
								<option value="">--</option>
								{Object.values(comments).map(({ id, description }) => (
									<option key={id} value={id}>
										{description}
									</option>
								))}
							</select>
						</div>
					:	<>
							<input
								type="text"
								className={classes.customComment}
								value={localCustomComment || ""}
								onChange={handleCustomCommentChange}
								placeholder="Your comment..."
								autoFocus
							/>
							<button
								type="button"
								onClick={removeCustomComment}
								className={classes.removeCustomComment}
								title="Remove comment"
							>
								<Cancel />
							</button>
						</>)}
			</div>
		</div>
	)
}

export default React.memo(RowComment)

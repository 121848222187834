import { FC, useState } from "react"

import {
	makeApiErrorMessage,
	useCustomerContactHistory,
	useCustomerUserContactTypes,
	UserProfile,
	useSendCustomerContact,
} from "@ncs/ncs-api"
import { formatDateTime } from "@ncs/ts-utils"
import {
	Box,
	Button,
	ConfirmationModal,
	ConfirmationModalConfig,
	Divider,
	GridContainer,
	GridItem,
	Heading,
	HeadingDivider,
	Icon,
	LoadingSpinner,
	Paragraph,
	SkeletonRows,
	useToast,
} from "@ncs/web-legos"

export interface CustomerUserCommunicationsTabProps {
	user: UserProfile
}

export const CustomerUserCommunicationsTab: FC<CustomerUserCommunicationsTabProps> = ({
	user,
}) => {
	const [contactTypes, contactTypesLoading] = useCustomerUserContactTypes()
	const [contactHistory, contactHistoryLoading] = useCustomerContactHistory({
		params: {
			user: user.id.toString(),
		},
	})
	const sendEmail = useSendCustomerContact()
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)
	const { makeSuccessToast, makeErrorToast } = useToast()

	const handleSend = async (typeId: string) => {
		try {
			await sendEmail({
				user: user.id.toString(),
				requestType: Number(typeId),
			})
			makeSuccessToast("Message sent")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		}
	}

	const confirmSend = (typeId: string) => {
		const description =
			(contactTypes ?? []).find((t) => t.id === typeId)?.description || "message"

		setConfirmationConfig({
			title: "Send Message?",
			message: `Confirm: Send ${description} to ${user.email}?`,
			onConfirm: () => handleSend(typeId),
		})
	}

	const getHistoryMeta = (typeId: string) => {
		if (contactHistoryLoading) {
			return <SkeletonRows rows={1} />
		}

		const records = (contactHistory ?? [])
			.filter((r) => r.typeId === Number(typeId))
			.sort((a, b) => (a.sentOn > b.sentOn ? -1 : 1))

		if (records.length > 0) {
			return (
				<div>
					<Box display="flex" columnGap={0.5} alignItems="center">
						<Icon icon="check" color="gray" />
						<Paragraph small color="secondary">
							Sent {formatDateTime(records[0].sentOn)} by {records[0].requestedBy}
						</Paragraph>
					</Box>
					<Button
						icon="redo"
						onClick={() => confirmSend(typeId)}
						containerProps={{ mt: 0.25, ml: -0.25 }}
					>
						Send again?
					</Button>
				</div>
			)
		}

		return (
			<Paragraph small color="secondary">
				Not sent
			</Paragraph>
		)
	}

	if (contactTypesLoading) {
		return <LoadingSpinner />
	}

	return (
		<>
			<GridContainer>
				<GridItem md={12} lg={6}>
					<HeadingDivider headingVariant="h3" icon="envelope">
						Email
					</HeadingDivider>
					{(contactTypes ?? []).map((type, index) => (
						<Box key={type.id}>
							{index !== 0 && <Divider />}
							<Box
								display="flex"
								justifyContent="space-between"
								alignItems="center"
								columnGap={0.5}
							>
								<div>
									<Heading variant="h3" mb={0.25}>
										{type.description}
									</Heading>
									{getHistoryMeta(type.id)}
								</div>

								{!contactHistoryLoading &&
									(contactHistory ?? []).every(
										(r) => r.typeId !== Number(type.id)
									) && (
										<Button
											variant="secondary-cta"
											icon="paper-plane"
											onClick={() => confirmSend(type.id)}
										>
											Send now
										</Button>
									)}
							</Box>
						</Box>
					))}
				</GridItem>
			</GridContainer>

			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</>
	)
}

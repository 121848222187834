import React from "react"
import { bindActionCreators, compose } from "redux"
import { connect } from "react-redux"
import callApi from "@ncs/bricks/redux/services/callApi"
import { BaseSelectFilter, withDynamicTableFilter } from "@ncs/bricks/components"
import { loadLookups, LOOKUPS } from "../../redux/services/lookups"

class PointOfSaleCategoryFilter extends React.PureComponent {
	componentDidMount() {
		this.props.loadLookups([LOOKUPS.PointOfSaleCategories])
	}

	render() {
		return (
			<BaseSelectFilter
				id="online-part-category"
				labelText="Category"
				options={[
					{ _id: "", is_active: true, name: "All Categories" },
					...this.props.categories,
				]}
				valueAccessor="_id"
				textAccessor={(x) => (x.is_active ? x.name : `${x.name} (Inactive)`)}
				{...this.props}
			/>
		)
	}
}

const mapStateToProps = ({ lookups }) => {
	return {
		isLoading: lookups.isLoadingPointOfSaleCategories,
		categories: lookups.pointOfSaleCategories,
	}
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ callApi, loadLookups }, dispatch)

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withDynamicTableFilter("category")
)(PointOfSaleCategoryFilter)

import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { bindActionCreators } from "redux"
import memoizeOne from "memoize-one"
import { loadLookups, LOOKUPS } from "@ncs/mortar/redux/services/lookups"
import {
	handleCategoryChange,
	handleToggleCategorySidebar,
} from "~/redux/modules/marketingMaterialsModule"
import CategoriesMenu from "../Shared/CategoriesMenu"

class CategoriesMenuContainer extends React.PureComponent {
	constructor(props) {
		super(props)
		this.state = {}
	}

	componentDidMount = () => {
		this.props.loadLookups([LOOKUPS.MarketingMaterialCategories])
	}

	categoryToTreeNode = (category) => {
		return {
			id: category.id,
			value: category.name,
			nodes: category.sub_categories.map(this.categoryToTreeNode),
		}
	}

	mapCategoriesToTree = memoizeOne((categories) => {
		return categories.map(this.categoryToTreeNode)
	})

	handleCategoryChange = (category) => {
		const categoryId = category && category.id > 0 ? category.id : null

		if (categoryId === this.props.selectedCategory) return

		this.props.handleCategoryChange(categoryId)
		this.props.history.push(`/marketing/materials/category/${categoryId || "all"}`)
	}

	render = () => {
		return (
			<CategoriesMenu
				categoryTree={this.mapCategoriesToTree(this.props.categories)}
				selectedCategory={this.props.selectedCategory}
				onCategorySelected={this.handleCategoryChange}
				show={this.props.show}
				onToggleVisibility={this.props.handleToggleCategorySidebar}
			/>
		)
	}
}

const mapStateToProps = ({ lookups, marketingMaterialsModule }) => {
	return {
		categories: lookups.marketingMaterialCategories,
		selectedCategory: marketingMaterialsModule.selectedCategory,
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{ loadLookups, handleCategoryChange, handleToggleCategorySidebar },
		dispatch
	)

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CategoriesMenuContainer))

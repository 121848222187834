import React, { useEffect, useState } from "react"

import { useParams } from "react-router-dom"

import {
	createChemicalPhasePart,
	deleteChemicalPhasePart,
	patchChemicalPhasePart,
} from "@ncs/mortar/redux/services/inventory"
import { titleCase } from "@ncs/bricks/util/formatters"
import { useCallApi, usePrevious } from "@ncs/bricks/util/hooks"

import { Alert, Dialog, InputAsDisplay, NumericInput, PartSelector } from "~/components"

import { Close } from "@material-ui/icons"

const EditPhasePartModal = ({ show, onClose, onCreate, onUpdate, onRemove, phase, phasePart }) => {
	const callApi = useCallApi()
	const { phase_id: machinePhaseId } = useParams()
	const [partId, setPartId] = useState(null)
	const [amount, setAmount] = useState(null)
	const [isConfirmingRemoval, setIsConfirmingRemoval] = useState(false)
	const [isSaving, setIsSaving] = useState(false)

	const prevPhasePart = usePrevious(phasePart)
	// Init state form when modal is opened with a phase part to edit.
	useEffect(() => {
		if (phasePart && !prevPhasePart) {
			setPartId(phasePart.id)
			setAmount(phasePart.defaultUsageRateInML)
		}
	}, [phasePart, prevPhasePart])

	const close = () => {
		onClose()
		setPartId(null)
		setAmount(null)
		setIsConfirmingRemoval(false)
	}

	async function handleConfirm() {
		setIsSaving(true)
		if (phasePart) {
			const { payload } = await callApi(
				patchChemicalPhasePart({
					phasePartId: phasePart.id,
					amount,
				})
			)
			onUpdate(payload)
			setIsSaving(false)
			close()
		} else {
			const { payload } = await callApi(
				createChemicalPhasePart({
					machinePhaseId,
					partId,
					amount,
				})
			)
			onCreate(payload)
			setIsSaving(false)
			close()
		}
	}

	async function handleRemove() {
		setIsSaving(true)
		await callApi(deleteChemicalPhasePart({ phasePartId: phasePart.id }))
		onRemove(phasePart.id)
		setIsSaving(false)
		close()
	}

	function handlePartSelected(value) {
		setPartId(value?._id ?? null)
	}

	return (
		<>
			<Dialog
				show={show}
				title={
					phasePart ?
						`Edit ${titleCase(phasePart.part.description)} in ${phase.description}`
					:	"Add part to phase"
				}
				isSaving={isSaving}
				onConfirm={handleConfirm}
				confirmBtnText={phasePart ? "Update part usage" : "Add part to phase"}
				confirmBtnColor="primary"
				confirmBtnDisabled={!partId || amount === null}
				onCancel={close}
				cancelBtnIcon={<Close />}
				cancelBtnColor="primary"
				cancelBtnSimple
				onRemove={phasePart ? () => setIsConfirmingRemoval(true) : undefined}
				removeBtnJustIcon
				alignContentCenter={false}
				fullWidth
				noTransition
			>
				{phasePart && (
					<InputAsDisplay
						value={`${phasePart.part.partNumber}: ${titleCase(
							phasePart.part.description
						)}`}
						labelText="Part"
					/>
				)}
				{!phasePart && (
					<PartSelector
						onPartSelected={handlePartSelected}
						suggestionsListMaxHeight="30rem"
						chemicalsOnly
						skipRestrictedCheck
					/>
				)}
				<NumericInput
					value={amount}
					onChange={(e) => setAmount(e ?? null)}
					suffix=" ml"
					labelText="Default usage per wash in ml"
				/>
			</Dialog>

			<Alert
				show={isConfirmingRemoval}
				title={`Remove ${titleCase(phasePart?.part?.description)} from ${
					phase?.description
				}?`}
				onConfirm={handleRemove}
				confirmBtnText="Remove"
				confirmBtnColor="danger"
				onCancel={() => setIsConfirmingRemoval(false)}
				cancelBtnText="Keep"
				cancelBtnColor="primary"
			/>
		</>
	)
}

export default EditPhasePartModal

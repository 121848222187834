import { Dispatch, ReactElement, SetStateAction, useEffect, useState } from "react"

import { RegionMinimal } from "@ncs/ncs-api"

import { usePrevious } from "../../util"
import { RegionSelector } from "../selectors"

interface RegionQueryFilter {
	region: string | null
}

export interface RegionQueryFilterProps<QueryParamState extends RegionQueryFilter> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

export const RegionQueryFilter = <QueryParamState extends RegionQueryFilter>({
	queryParamState,
	setQueryParamState,
}: RegionQueryFilterProps<QueryParamState>): ReactElement => {
	const [selectedRegion, setSelectedRegion] = useState<RegionMinimal | null>(null)

	const handleChange = (newRegion: RegionMinimal | null) => {
		setSelectedRegion(newRegion)
		setQueryParamState((prev) => ({
			...prev,
			region: newRegion?.id ?? null,
		}))
	}

	// Handles the user clicking Reset button.
	const prevRegionFromQueryParams = usePrevious(queryParamState.region)
	useEffect(() => {
		if (
			queryParamState.region === null &&
			prevRegionFromQueryParams !== null &&
			selectedRegion !== null
		) {
			setSelectedRegion(null)
		}
	}, [prevRegionFromQueryParams, queryParamState.region, selectedRegion])

	return (
		<RegionSelector
			value={selectedRegion}
			onChange={handleChange}
			initialId={queryParamState.region}
			fillContainer
		/>
	)
}

import get from "lodash/get"
import isEqual from "lodash/isEqual"
import mapValues from "lodash/mapValues"
import omit from "lodash/omit"
import omitBy from "lodash/omitBy"
import orderBy from "lodash/orderBy"
import property from "lodash/property"
import slice from "lodash/slice"
import uniq from "lodash/uniq"
import moment from "moment"

import callApi from "./callApi"

import {
	DATE_RANGE_FILTER_TYPE,
	DEFAULT_REPORT_FILTERS,
	REPORT_FILTERS,
} from "../../util/constants"
import { getDateRangeForFilter, REPORT_PERIODS_ALL } from "../../util/dates"
import { FILTER_OVERRIDE } from "../../util/constants"

const SET_UP_DYNAMIC_REPORT = "dynamicTables/set_up_dynamic_report"
const UPDATE_DYNAMIC_REPORT_STATE = "dynamicTables/update_state"
const SET_SELECTED_COLUMNS = "dynamicTables/set_selected_columns"
const TOGGLE_COLUMN = "dynamicTables/toggle_column"
const ISSUE_FETCH = "dynamicTables/issue_fetch"
const FETCH_SUCCESS = "dynamicTables/fetch_success"
const FETCH_ERROR = "dynamicTables/fetch_error"
const ISSUE_SEARCH = "dynamicTables/issue_search"
const SEARCH_CHANGED = "dynamicTables/search_changed"
const SORTED_CHANGED = "dynamicTables/sorted_changed"
const PAGE_CHANGED = "dynamicTables/page_changed"
const PAGE_SIZE_CHANGED = "dynamicTables/page_size_changed"
const EXPANDED_CHANGED = "dynamicTables/expanded_changed"
const RESIZED_CHANGED = "dynamicTables/resized_changed"
const FILTERED_CHANGED = "dynamicTables/filtered_changed"
const SET_FILTER = "dynamicTables/set_filter"
const UPDATE_FILTERS = "dynamicTables/update_filters"
const RESET_FILTERS = "dynamicTables/reset_filters"
const SET_QUICK_FILTER = "dynamicTables/set_quick_filter"
const HANDLE_QUICK_FILTER = "dynamicTables/handle_quick_filter"

const defaultTablePageSize = 25

export const generateOrdering = (sorted) => {
	if (!sorted.length) return undefined

	return sorted.map((s) => (s.desc ? "-" : "") + s.id.replace(/\./g, "__")).join(",")
}

export const generateOrderingWithPeople =
	(people = []) =>
	(sorted) => {
		// people are actually comprised of two fields, this splits the ordering for name into both fields
		if (!sorted.length) return undefined

		if (!people) return sorted

		for (let person of people) {
			let i = sorted.findIndex((s) => s.id === `${person}.name`)
			sorted =
				i < 0 ? sorted : (
					sorted
						.slice(0, i)
						.concat([
							{ id: `${person}__first_name`, desc: sorted[i].desc },
							{ id: `${person}__last_name`, desc: sorted[i].desc },
						])
						.concat(sorted.slice(i + 1))
				)
		}

		return generateOrdering(sorted)
	}

export const generateFormattedValue = (value, fieldName) => {
	if (
		value instanceof moment ||
		(typeof value === "string" &&
			moment(value, moment.ISO_8601, true).isValid() &&
			// avoid false positives when the number is an int that could be interpreted as a date
			Number(value).toString() !== value)
	) {
		return (
				[
					"start_date",
					"end_date",
					"month",
					"ticket_close_start",
					"ticket_close_end",
					"est_comp_date",
				].includes(fieldName)
			) ?
				moment(value).format("YYYY-MM-DD")
				// TODO: figure out why T & Z cause API to bomb
			:	moment(value).toISOString().replace("T", " ").replace("Z", "")
	}

	// pull IDs out of objects (usually present in the case of ReportFilter Selector values)
	if (typeof value === "object") {
		if (value?.hasOwnProperty("type")) {
			if (value?.hasOwnProperty("filterValue")) {
				if (typeof value.filterValue === "function") {
					return value.filterValue(value.value)
				} else {
					return value.filterValue
				}
			}

			if (value.type === DATE_RANGE_FILTER_TYPE) {
				return !value.value ? undefined : (
						getDateRangeForFilter(value.value)?.format("YYYY-MM-DD")
					)
			}
		}

		let id = get(value, "id", get(value, "_id", undefined))
		if (typeof id !== "undefined") return id
	}

	return value
}

export const generateFilters = (props) => {
	const { filtered, generateFormattedValue } = props

	return Object.assign(
		{},
		...filtered
			// generate value for API
			.map((filter) =>
				filter?.value?.type === FILTER_OVERRIDE ?
					typeof filter.value.filterValue === "function" ?
						filter.value.filterValue(filter.value.value)
					:	filter.value.filterValue
				:	{
						...filter,
						value: generateFormattedValue(filter.value, filter.id),
					}
			)
			// filter out blank values
			.filter(
				(filter) =>
					filter.id !== "report_period" &&
					typeof filter.value !== "undefined" &&
					filter.value !== null &&
					(typeof filter.value !== "string" || filter.value.length > 0)
			)
			// map filter id to property
			.map((filter) => ({
				[filter.id.replace(".", "__")]: filter.value,
			}))
	)
}

/** pull start_date and end_date out of the filtered collection and combine them into one */
const combineReportPeriodText = (filtered) => {
	let startDate = filtered.find((x) => x.id === REPORT_FILTERS.StartDate)
	let endDate = filtered.find((x) => x.id === REPORT_FILTERS.EndDate)

	if (startDate && endDate) {
		return `From ${startDate.text} to ${endDate.text}`
	}

	if (startDate) {
		return `From ${startDate.text} to present`
	}

	if (endDate) {
		return `Through ${endDate.text}`
	}

	return null
}

export const generateFilterText = (props) => {
	const { filtered } = props

	let filteredWithText = filtered.filter(
		(x) =>
			!!x.text && // has text
			x.value && // has value
			(typeof x.value === "object" || // objects don't need the following check
				(!Number.isNaN(Number(x.value)) && Number(x.value) > 0)) // not negative (default value in selects)
	)

	let filters = [
		// format start and end dates as one filter
		combineReportPeriodText(filteredWithText),
		// order by default report filters first
		...DEFAULT_REPORT_FILTERS.filter(
			(name) => ![REPORT_FILTERS.StartDate, REPORT_FILTERS.EndDate].includes(name)
		).map((name) => (filteredWithText.find((x) => x.id === name) || {}).text),
		// everything else in whatever order ¯\_(ツ)_/¯
		...filteredWithText
			.filter((x) => !DEFAULT_REPORT_FILTERS.includes(x.id))
			.map((x) => x.text),
	]

	return filters.filter((x) => !!x).join(" - ")
}

export const generateQuerystringParameters = (props) => {
	const {
		pageSize,
		page,
		sorted,
		filtered,
		search,
		generateOrdering,
		generateFilters,
		additionalQueryParameters,
		...rest
	} = props

	let query = {
		ordering: typeof generateOrdering === "function" ? generateOrdering(sorted) : {},
		page: page + 1,
		pageSize: pageSize ?? defaultTablePageSize,
		search,
		...additionalQueryParameters,
		...(typeof generateFilters === "function" ? generateFilters({ filtered, ...rest }) : {}),
	}

	if (!query.search) delete query.search

	return query
}

/** configure this dynamic report
 * WARNING: if names of properties on the dispatched payload match input properties then DynamicTable will not be able
 * to update the properties after initialization. */
export const setUpDynamicReport = (key) => (reportProps) => {
	const {
		/** required, fetches report data */
		fetchFn,
		/** should this table's state be rehydrated when redux reloads? defaults to false */
		retain,
		/** optional override for customizing the URL based on inputs,
		 * default supports typical dynamic report use cases */
		generateQueryFn,
		/** if using redux, optional object of params to add to the URL
		 * use this instead of overriding generateQueryFn if you only need to add some things on */
		additionalQueryParams,
		/** optional override for customizing filters.
		 * some filters will need to be renamed, etc., which can be done by overriding this method */
		generateFiltersFn,
		/** optional override for customizing export filename. */
		generateExportFilenameFn,
		/** optional override for processing filter values.
		 * some filter values will need pre-processing before including in the URL, which can be done by overriding this method */
		generateFormattedValueFn,
		/** optional override for customizing ordering.
		 * some orderings will need to be split into two fields, etc., which can be done by overriding this method */
		generateOrderingFn,
		/** optional lifecycle method for handling things synchronously before a fetch.
		 * returning an object with {cancel: true} will cancel the fetch */
		preFetchFn,
		/** array of fields that should be reset when Reset Fields is clicked
		 * each item in the array can be a string, which will pull the default value from initialState here,
		 * or an object of format {key: 'field', initialState: 'blah'}  */
		filterFields,
		/* anything else you want to dump in the report's redux store */
		...rest
	} = reportProps

	if (typeof fetchFn !== "function") {
		throw new Error("fetchFn must be a function that fetches the report's data.")
	}

	return (dispatch) => {
		dispatch({
			key,
			type: SET_UP_DYNAMIC_REPORT,
			payload: {
				fetch: fetchFn,
				shouldRetain: typeof retain === "boolean" ? retain : false,
				generateQuery:
					typeof generateQueryFn === "function" ? generateQueryFn : (
						generateQuerystringParameters
					),
				additionalQueryParameters:
					typeof additionalQueryParams === "object" ? additionalQueryParams : {},
				generateFormattedValue:
					typeof generateFormattedValueFn === "function" ? generateFormattedValueFn : (
						generateFormattedValue
					),
				generateFilters:
					typeof generateFiltersFn === "function" ? generateFiltersFn : generateFilters,
				generateOrdering:
					typeof generateOrderingFn === "function" ? generateOrderingFn : (
						generateOrdering
					),
				generateExportFilename:
					typeof generateExportFilenameFn === "function" ? generateExportFilenameFn : (
						generateFilterText
					),
				// this intentionally matches so that dynamicTables manages the collection after initialization
				filterFields: uniq([...(filterFields || []), "search", "page"]),
				...rest,
			},
		})
	}
}

export const updateFetchFn = (key) => (fetchFn) => {
	return (dispatch) => {
		dispatch({
			key,
			type: UPDATE_DYNAMIC_REPORT_STATE,
			payload: { fetch: fetchFn },
		})
	}
}

export const updateState = (key) => (newState) => {
	return (dispatch) => {
		dispatch({
			key,
			type: UPDATE_DYNAMIC_REPORT_STATE,
			payload: newState,
		})
	}
}

export const fetchData = (key) => () => {
	return (dispatch, getState) => {
		const allState = getState().dynamicTables
		const state = allState ? allState[key] : null

		if (!state) {
			throw new Error("Dynamic Table for " + key + " not yet configured.")
		}

		if (typeof state.preFetchFn === "function") {
			let preFetchResponse = state.preFetchFn(state)
			if (preFetchResponse?.cancel === true) {
				return
			}
		}

		if (typeof state.fetch !== "function") {
			console.error("fetchFn is required. Call setUpDynamicReport before calling fetchData.")
			return
		}

		if (typeof state.generateQuery !== "function") {
			console.error(
				"generateQueryFn is required. Call setUpDynamicReport before calling fetchData."
			)
			return
		}

		const query = state.generateQuery(state)

		// ignore duplicate queries within half a second of each other
		if (
			state.fetched &&
			state.fetched.time &&
			new Date() - state.fetched.time < 500 &&
			isEqual(query, state.fetched.query)
		)
			return

		dispatch({ key, type: ISSUE_FETCH, payload: { query, time: new Date() } })
		dispatch(callApi(state.fetch(query))).then((result) =>
			dispatch(handleFetchDataResult(key)(query, result))
		)
	}
}

export const handleFetchDataResult = (key) => (query, result) => (dispatch, getState) => {
	const allState = getState().dynamicTables
	const state = allState ? allState[key] : null

	if (result.error) {
		dispatch({
			key,
			type: FETCH_ERROR,
			payload: { isLoading: false, error: result.payload, data: [] },
		})
		return
	}

	let payload = result.payload
	let pages = result.meta && result.meta.pages
	// if we're on the last real page
	if (!pages && !!payload) {
		pages = query.page
	}

	// raw data may be needed when handling data client side
	let rawData = null

	if (typeof state.dataProperty === "string") {
		payload = property(state.dataProperty)(payload)
	}

	if (state.useClientSideFiltering && state.filtered && state.filtered.length > 0) {
		for (let filter of state.filtered) {
			if (typeof filter.value !== "string") continue

			let words = filter.value.toLowerCase().split(" ")
			let getProp = property(filter.id)
			payload = payload.filter((item) => {
				return words.every((word) => getProp(item).toLowerCase().indexOf(word) >= 0)
			})
		}
	}

	if (state.useClientSideDataProcessing) {
		// handle filtering

		// handle sorting
		if (state.sorted && state.sorted.length > 0) {
			let sorted = state.sorted
			payload = orderBy(
				payload,
				sorted.map((x) => x.id),
				sorted.map((x) => (x.desc ? "desc" : "asc"))
			)
		}

		// maintain raw data before paging for graphs
		rawData = Array.isArray(result.payload) ? [...result.payload] : null

		// handle paging locally
		const pageSize = state.pageSize ?? defaultTablePageSize
		let start = state.page * pageSize
		let end = (state.page + 1) * pageSize
		pages = Math.ceil(payload.length / pageSize)
		payload = [...slice(payload, start, end)]
	}

	dispatch({
		key,
		type: FETCH_SUCCESS,
		payload: { isLoading: false, data: payload, pages, rawData },
	})
}

const fetchDataInternal = (key, dispatch) => dispatch(fetchData(key)())

export const searchData = (key) => () => {
	const debounced = (dispatch) => {
		fetchDataInternal(key, dispatch)
	}

	debounced.meta = {
		debounce: {
			time: 325,
			// key must be unique to the particular search, otherwise it debounces everything together, which
			//      which will eat searches across tabs with shared filters (a la Inventory Management)
			key: `${ISSUE_SEARCH}/${key}`,
		},
	}

	return debounced
}

export const handleSearchChange = (key) => (search) => {
	return (dispatch) => {
		dispatch({ key, type: SEARCH_CHANGED, payload: { search, page: 0 } })
		dispatch(searchData(key)())
	}
}

export const handleSortedChange = (key) => (payload) => {
	return (dispatch) => {
		dispatch({ key, type: SORTED_CHANGED, payload })
		fetchDataInternal(key, dispatch)
	}
}

export const handlePageChange = (key) => (payload) => {
	return (dispatch) => {
		dispatch({ key, type: PAGE_CHANGED, payload })
		fetchDataInternal(key, dispatch)
	}
}

export const handlePageSizeChange = (key) => (pageSize, page) => {
	return (dispatch) => {
		dispatch({ key, type: PAGE_SIZE_CHANGED, payload: { pageSize, page } })
		fetchDataInternal(key, dispatch)
	}
}

export const handleExpandedChange = (key) => (payload) => {
	return (dispatch) => {
		dispatch({ key, type: EXPANDED_CHANGED, payload })
	}
}

export const handleResizedChange = (key) => (payload) => {
	return (dispatch) => {
		dispatch({ key, type: RESIZED_CHANGED, payload })
	}
}

export const handleFilteredChange = (key) => (payload) => {
	return (dispatch) => {
		dispatch({ key, type: FILTERED_CHANGED, payload })
		fetchDataInternal(key, dispatch)
	}
}

export const setSelectedColumns = (key) => (selectedColumns) => ({
	key,
	type: SET_SELECTED_COLUMNS,
	payload: { selectedColumns },
})

export const handleToggleColumn = (key) => (payload) => ({
	key,
	type: TOGGLE_COLUMN,
	payload,
})

export const setFilter =
	(key, field) =>
	(value, text, refreshData = true) =>
	(dispatch) => {
		if (field === "search") {
			dispatch(handleSearchChange(key)(value))
			return
		}

		dispatch({ key, type: SET_FILTER, payload: { field, value, text } })
		if (refreshData) {
			fetchDataInternal(key, dispatch)
		}
	}

export const updateFilters =
	(key) =>
	(filters, refreshData = true) =>
	(dispatch) => {
		dispatch({ key, type: UPDATE_FILTERS, payload: filters })
		if (refreshData) {
			fetchDataInternal(key, dispatch)
		}
	}

export const resetFilters =
	(key) =>
	(refreshData = true) =>
	(dispatch) => {
		dispatch({ key, type: RESET_FILTERS })
		if (refreshData) {
			fetchDataInternal(key, dispatch)
		}
	}

// stores a value that can be read by the table's container to set filters
export const setQuickFilter = (key) => (quickFilter) => (dispatch) => {
	dispatch({ key, type: SET_QUICK_FILTER, payload: quickFilter })
}

// a table's container reads the quick filter value and provides relevant filters, which are
// applied on top of the default filters before purging the quickFilter value so subsequent page
// loads do not overwrite filters too
export const handleQuickFilter =
	(key) => (filters, forcedVisibleColumns) => (dispatch, getState) => {
		if ((forcedVisibleColumns ?? []).length > 0) {
			const allState = getState().dynamicTables
			const state = allState ? allState[key] : null
			const newSelectedColumns = state.availableColumns.filter(
				(x) => forcedVisibleColumns.includes(x) || state.selectedColumns.includes(x)
			)
			dispatch(setSelectedColumns(key)(newSelectedColumns))
		}

		dispatch({ key, type: HANDLE_QUICK_FILTER, payload: filters })
		fetchDataInternal(key, dispatch)
	}

export const getDefaultForFilter = (field, initialState) => {
	if (typeof field === "object") {
		return { id: field.key, value: field.initialState }
	} else {
		return { id: field, value: initialState[field] }
	}
}

export const getDefaultFilters = (filterFields, initialState) => {
	let fields = [
		// do the plain string ones first since they get added dynamically
		...(filterFields ?? []).filter((x) => typeof x !== "object"),
		// then do the object ones since they are explicitly defined and should be last one in to win
		...(filterFields ?? []).filter((x) => typeof x === "object"),
	]

	let result = {
		search: "",
		page: 0,
		filtered: [
			...fields.map((x) => getDefaultForFilter(x, initialState)).filter((x) => !!x.value),
		],
	}

	// if the report period was reset, set the dates too if they weren't also set
	let reportPeriod = result.filtered.find((x) => x.id === "report_period")
	if (
		!!reportPeriod &&
		!result.filtered.some((x) => x.id === "start_date") &&
		!result.filtered.some((x) => x.id === "end_date")
	) {
		let period = REPORT_PERIODS_ALL.find((x) => x.value === reportPeriod.value)
		result.filtered.push({ id: "start_date", value: period.from() })
		result.filtered.push({ id: "end_date", value: period.to() })
	}

	return result
}

const initialState = {
	fetched: {},
	search: "",
	sorted: [],
	page: 0,
	expanded: {},
	resized: [],
	filtered: [],
	isLoading: false,
	quickFilter: null,
}

const getNewStateForKey = (key, state, action) => {
	switch (action.type) {
		case SET_UP_DYNAMIC_REPORT:
			const { defaults, ...rest } = action.payload

			let stateWithoutEmptyDefaults = omitBy(
				state,
				(v, key) =>
					[
						"availableColumns",
						"toggleableColumns",
						"selectedColumns",
						"filtered",
					].includes(key) &&
					(!state[key] || state[key].length === 0)
			)

			return {
				...defaults,
				...stateWithoutEmptyDefaults,
				defaults,
				...rest,
			}
		case UPDATE_DYNAMIC_REPORT_STATE:
			return { ...state, ...action.payload }
		case SET_SELECTED_COLUMNS:
			return { ...state, ...action.payload }
		case TOGGLE_COLUMN:
			let newSelectedColumns =
				state.selectedColumns.includes(action.payload) ?
					state.selectedColumns.filter((x) => x !== action.payload)
				:	[...state.selectedColumns, action.payload]
			return {
				...state,
				selectedColumns: state.availableColumns.filter((x) =>
					newSelectedColumns.includes(x)
				),
			}
		case ISSUE_FETCH:
			return { ...state, fetched: action.payload, isLoading: true }
		case FETCH_ERROR:
		case FETCH_SUCCESS:
			return { ...state, ...action.payload, isLoading: false }
		case SEARCH_CHANGED:
			return { ...state, search: action.payload.search, page: action.payload.page }
		case SORTED_CHANGED:
			return { ...state, sorted: action.payload }
		case PAGE_CHANGED:
			return { ...state, page: action.payload }
		case PAGE_SIZE_CHANGED:
			return { ...state, page: action.payload.page, pageSize: action.payload.pageSize }
		case EXPANDED_CHANGED:
			return { ...state, expanded: action.payload }
		case RESIZED_CHANGED:
			return { ...state, resized: action.payload }
		case FILTERED_CHANGED:
			return { ...state, filtered: action.payload }

		case SET_FILTER:
			let filtered = [
				...(state.filtered || []).filter((x) => x.id !== action.payload.field),
				{
					id: action.payload.field,
					value: action.payload.value,
					text: action.payload.text,
				},
			]

			return {
				...state,
				filterFields: uniq([...(state.filterFields || []), action.payload.field]),
				filtered,
				page: 0,
			}

		case UPDATE_FILTERS:
			let filterKeys = Object.keys(action.payload)

			return {
				...state,
				filterFields: uniq([...(state.filterFields || []), ...filterKeys]),
				filtered: [
					...(state.filtered || []).filter((x) => !filterKeys.includes(x.id)),
					...filterKeys
						.filter((x) => typeof action.payload[x] !== "undefined")
						.map((x) => ({
							id: x,
							...((
								typeof action.payload[x] === "object" &&
								action.payload[x]?.hasOwnProperty("value")
							) ?
								action.payload[x]
							:	{ value: action.payload[x] }),
						})),
				],
				page: 0,
			}

		case RESET_FILTERS:
			return {
				...state,
				...getDefaultFilters(state.filterFields, { ...initialState, ...state.defaults }),
			}

		case SET_QUICK_FILTER:
			return {
				...state,
				quickFilter: action.payload,
			}

		case HANDLE_QUICK_FILTER:
			let filters = getDefaultFilters(state.filterFields, {
				...initialState,
				...state.defaults,
			})
			let newFilterKeys = action.payload.map((x) => x.id)
			let newFilters = action.payload
			filters.filtered = [
				...(filters.filtered || []).filter((x) => !newFilterKeys.includes(x.id)),
				...newFilterKeys.map((x, idx) => ({
					id: x,
					...((
						typeof newFilters[idx] === "object" &&
						newFilters[idx].hasOwnProperty("value")
					) ?
						newFilters[idx]
					:	{ value: newFilters[idx] }),
				})),
			]

			return {
				...state,
				quickFilter: null,
				...filters,
			}

		default:
			return state
	}
}

const dynamicTables = (wholeState, action) => {
	if (action.type === "persist/REHYDRATE") {
		let retained = omitBy(
			(action.payload || {}).dynamicTables || {},
			(value, key) =>
				typeof key !== "string" || typeof value !== "object" || !value.shouldRetain
		)

		return mapValues(retained, (x) =>
			omit(x, [
				"toggleableColumns",
				"defaults",
				"shouldRetain",
				"data",
				"rawData",
				"useClientSideDataProcessing",
			])
		)
	}

	if (!action.key) return wholeState || {}

	const state = wholeState[action.key] || initialState

	return {
		...wholeState,
		[action.key]: getNewStateForKey(action.key, state, action),
	}
}
export default dynamicTables

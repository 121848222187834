import { FC, useState } from "react"

import {
	EquipmentManufacturer,
	EquipmentModel,
	EquipmentVintage,
	makeApiErrorMessage,
	useCreateEquipmentVintage,
	useUpdateEquipmentVintage,
} from "@ncs/ncs-api"
import {
	Box,
	Checkbox,
	EmptyValueDash,
	EquipmentManufacturerSelector,
	EquipmentModelSelector,
	ExtendableModalProps,
	GridContainer,
	GridItem,
	LabeledData,
	Modal,
	TextInput,
	useIsSaving,
	useToast,
} from "@ncs/web-legos"

export interface EditVintageModalProps extends ExtendableModalProps {
	vintage: EquipmentVintage | null
}

export const EditVintageModal: FC<EditVintageModalProps> = ({ vintage, ...rest }) => {
	const { makeSuccessToast } = useToast()
	const { isSaving, setSaving, endSaving } = useIsSaving()
	const [errorText, setErrorText] = useState<string | null>(null)

	const [name, setName] = useState(vintage?.name ?? null)
	const [serialBegins, setSerialBegins] = useState(vintage?.beginSerialNumber ?? null)
	const [serialEnds, setSerialEnds] = useState(vintage?.endSerialNumber ?? null)
	const [isActive, setIsActive] = useState(vintage?.isActive ?? true)
	const [manufacturer, setManufacturer] = useState<EquipmentManufacturer | null>(null)
	const [model, setModel] = useState<EquipmentModel | null>(null)

	const createVintage = useCreateEquipmentVintage()
	const updateVintage = useUpdateEquipmentVintage()

	const handleSave = async () => {
		if (!name) {
			setErrorText("Vintage name is required")
			return
		}

		try {
			setSaving()

			const data = {
				name,
				code: name,
				beginSerialNumber: serialBegins,
				endSerialNumber: serialEnds,
				isActive,
			}

			if (isEdit) {
				await updateVintage({
					id: vintage.id,
					updates: data,
				})
				makeSuccessToast("Vintage updated")
			} else {
				if (!model) {
					throw new Error("Model is required")
				}

				await createVintage({
					...data,
					eqpModelId: model.id,
				})
				makeSuccessToast("Vintage created")
			}
			rest.onClose()
		} catch (e) {
			endSaving()
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const isEdit = !!vintage

	return (
		<Modal
			{...rest}
			title={isEdit ? "Edit Vintage Details" : "New Vintage"}
			errorText={errorText}
			rightButtons={{
				buttonText: isEdit ? "Save Changes" : "Create",
				onClick: handleSave,
				isLoading: isSaving(),
			}}
		>
			{isEdit ?
				<Box d="flex" mb={0.5}>
					<Box flex={1}>
						<LabeledData label="Model">
							{vintage.eqpModel?.modelName || <EmptyValueDash />}
						</LabeledData>
					</Box>
					<Box flex={1}>
						<LabeledData label="Manufacturer">
							{vintage.eqpModel?.manufacturer?.name || <EmptyValueDash />}
						</LabeledData>
					</Box>
				</Box>
			:	<>
					<EquipmentManufacturerSelector
						value={manufacturer}
						onChange={setManufacturer}
					/>
					<EquipmentModelSelector
						value={model}
						onChange={(newModel) => {
							setModel(newModel)
							// Also set the manufacturer to whatever the model's manufacturer is. It's
							// probably also set to it, but this just makes sure they're *nsync.
							if (newModel?.manufacturer) setManufacturer(newModel.manufacturer)
						}}
						manufacturer={manufacturer?.id.toString()}
						disableOptionsAggregation
						maxVisibleOptions={null}
						label="Model"
						placeholder={!manufacturer?.id ? "First select manufacturer" : "Model..."}
					/>
				</>
			}
			<TextInput value={name} onChange={setName} label="Vintage name" />
			<GridContainer>
				<GridItem xs={12} sm={6}>
					<TextInput
						value={serialBegins}
						onChange={setSerialBegins}
						label="Serial # begins"
					/>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<TextInput value={serialEnds} onChange={setSerialEnds} label="Serial # ends" />
				</GridItem>
			</GridContainer>
			<Checkbox value={isActive} onChange={setIsActive} label="Active (default)" />
		</Modal>
	)
}

import { FC } from "react"

import { TerritoryMinimal, useTerritories } from "@ncs/ncs-api"
import { MultiSelect } from "@ncs/web-legos"

export interface TerritoryMultiSelectProps {
	values: TerritoryMinimal[]
	onChange: (newLocations: TerritoryMinimal[]) => void
	maxSelections?: number
}

export const TerritoryMultiSelect: FC<TerritoryMultiSelectProps> = ({
	values,
	onChange,
	maxSelections = 3,
}) => {
	const [territories] = useTerritories()

	return (
		<MultiSelect
			values={values}
			onChange={onChange}
			options={territories ?? []}
			placeholder="Search for a territory..."
			idAccessor="id"
			textAccessor="description"
			longTextAccessor={(option) => `(${option.code}) ${option.description}`}
			label="Territory"
			htmlName="territory-selector"
			aggregateOptions={false}
			maxSelections={maxSelections}
		/>
	)
}

import { FC, ReactElement, useState } from "react"

import { Cell, Column } from "react-table"

import {
	ConnectedDevice,
	TwoDigitMachineCode,
	TwoDigitMachineCodesQueryParams,
	useTwoDigitMachineCodes,
} from "@ncs/ncs-api"
import { formatDateTime, Typify } from "@ncs/ts-utils"
import {
	EmptyValueDash,
	KeyboardStartDateQueryFilter,
	KeyboardStartDateQueryFilterProps,
	MachineTwoDigitCodeStatusQueryFilter,
	NoWrap,
	Paragraph,
	SearchQueryFilter,
	Table,
	usePaginationUrlState,
} from "@ncs/web-legos"

import { c12yAutoRefreshConfig } from "~/util"

import { MachineStateDetailModal } from "./MachineStateDetailModal"

export interface MachineStatesTabProps {
	machine: ConnectedDevice | undefined
}

export type MachineStatesTabUrlState = Typify<TwoDigitMachineCodesQueryParams>

export const MachineStatesTab: FC<MachineStatesTabProps> = ({ machine }) => {
	const [codeForDetailModal, setCodeForDetailModal] = useState<TwoDigitMachineCode | null>(null)

	const [params, setParams] = useState<MachineStatesTabUrlState>(defaultParams)
	const [pagination, setPagination] = usePaginationUrlState()

	const query = useTwoDigitMachineCodes(machine?.id, {
		params: { ...params, ...pagination },
		manualPagination: true,
		queryConfig: c12yAutoRefreshConfig,
	})

	return (
		<>
			<Table
				columns={columns}
				query={query}
				queryParamState={params}
				setQueryParamState={setParams}
				pagination={pagination}
				setPagination={setPagination}
				toggledQueryFilters={[
					StartOnBeforeQueryFilter,
					StartOnAfterQueryFilter,
					SearchQueryFilter,
					MachineTwoDigitCodeStatusQueryFilter,
				]}
				showToggledFiltersByDefault={Object.values(params).some((v) => !!v)}
				onRowClick={({ original }) => setCodeForDetailModal(original)}
				storeColumnVisibility
				noDataText="No data found with the current filter settings"
			/>

			{!!codeForDetailModal && (
				<MachineStateDetailModal
					onClose={() => setCodeForDetailModal(null)}
					code={codeForDetailModal}
				/>
			)}
		</>
	)
}

const StartOnAfterQueryFilter = (
	props: KeyboardStartDateQueryFilterProps<TwoDigitMachineCodesQueryParams>
): ReactElement => {
	return (
		<KeyboardStartDateQueryFilter {...props} label="Start date after" param="startOnAfter" />
	)
}

const StartOnBeforeQueryFilter = (
	props: KeyboardStartDateQueryFilterProps<TwoDigitMachineCodesQueryParams>
): ReactElement => {
	return (
		<KeyboardStartDateQueryFilter {...props} label="Start date before" param="startOnBefore" />
	)
}

const columns: Column<TwoDigitMachineCode>[] = [
	{
		Header: "Code",
		id: "twoDigitCode_MachineCode",
		Cell: ({
			row: {
				original: { twoDigitCode },
			},
		}: Cell<TwoDigitMachineCode>) => {
			return (
				<Paragraph small light mt={0.1}>
					{twoDigitCode?.machineCode || "--"}
				</Paragraph>
			)
		},
	},
	{
		Header: "Start Time",
		id: "eventStartOn",
		accessor: (original) =>
			original.eventStartOn ?
				<NoWrap>{formatDateTime(original.eventStartOn)}</NoWrap>
			:	<EmptyValueDash />,
	},
	{
		Header: "End Time",
		id: "eventEndOn",
		accessor: (original) =>
			original.eventEndOn ?
				<NoWrap>{formatDateTime(original.eventEndOn)}</NoWrap>
			:	<EmptyValueDash />,
	},
	{
		Header: "Code Descriptions",
		id: "description",
		Cell: ({ row: { original } }: Cell<TwoDigitMachineCode>) => (
			<>
				<div>
					<span>Description: {original.twoDigitCode?.description || "-"}</span>
				</div>
				<div>
					<span>Definition: {original.twoDigitCode?.definition || "-"}</span>
				</div>
			</>
		),
		disableSortBy: true,
	},
]

const defaultParams: TwoDigitMachineCodesQueryParams = {
	ordering: null,
	search: null,
	startOnAfter: null,
	startOnBefore: null,
	active: true,
}

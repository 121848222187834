import { get, post, patch, rsaaTypes, RSAA } from "@ncs/bricks/middleware/portalApiMiddleware"

export const GET_MANUFACTURERS = rsaaTypes("equipment/manufacturers")
export const GET_MANUFACTURER = rsaaTypes("equipment/get/manufacturer")
export const GET_MODELS = rsaaTypes("equipment/models")
export const GET_MODEL = rsaaTypes("equipment/get/models")
export const GET_VINTAGE = rsaaTypes("equipment/get/vintage")
export const GET_VINTAGES = rsaaTypes("equipment/vintages")
export const GET_EQUIPMENT_TYPES = rsaaTypes("equipment/types")
export const CLEAR_MANUFACTURER = "equipment/clearManufacturer"
export const CLEAR_MODEL = "equipment/clearModel"
export const CLEAR_VINTAGE = "equipment/clearVintage"
export const CREATE_MANUFACTURER = rsaaTypes("equipment/createManufacturer")
export const CREATE_MODEL = rsaaTypes("equipment/createModel")
export const CREATE_VINTAGE = rsaaTypes("equipment/createVintage")
export const UPDATE_MANUFACTURER = rsaaTypes("equipment/updateManufacturer")
export const UPDATE_MODEL = rsaaTypes("equipment/updateModel")
export const UPDATE_VINTAGE = rsaaTypes("equipment/updateVintage")
export const SELECT_MODEL = "equipment/selectModel"
export const SELECT_MANUFACTURER = "equipment/selectManufacturer"
export const SELECT_VINTAGE = "equipment/selectVintage"
export const ADD_TO_SITESURVEY = rsaaTypes("equipment/addToSiteSurvey")
export const GET_VINTAGE_EQUIPMENT = rsaaTypes("equipment/vintage/equipment")
export const GET_VINTAGE_DISPATCHES = rsaaTypes("equipment/vintage/dispatches")
export const GET_VINTAGE_PARTS = rsaaTypes("equipment/vintage/parts")

export const addToSiteSurvey = (modelId, typeId) => ({
	[RSAA]: {
		api: post(`/equipment/models/${modelId}/add_to_sitesurvey/`, {
			type_id: typeId,
		}),
		types: ADD_TO_SITESURVEY,
	},
})

export const updateManufacturer = (manufacturerId, data) => ({
	[RSAA]: {
		api: patch(`/equipment/manufacturers/${manufacturerId}/`, data),
		types: UPDATE_MANUFACTURER,
	},
})

export const updateModel = (modelId, data) => ({
	[RSAA]: {
		api: patch(`/equipment/models/${modelId}/`, data),
		types: UPDATE_MANUFACTURER,
	},
})

export const updateVintage = (vintageId, data) => ({
	[RSAA]: {
		api: patch(`/equipment/vintages/${vintageId}/`, data),
		types: UPDATE_VINTAGE,
	},
})

export const createManufacturer = (name) => ({
	[RSAA]: {
		api: post("/equipment/manufacturers/", {
			code: name,
			name: name,
			is_active: true,
		}),
		types: CREATE_MANUFACTURER,
	},
})

export const createModel = (data) => ({
	[RSAA]: {
		api: post("/equipment/models/", data),
		types: CREATE_MODEL,
	},
})

export const createVintage = (params) => ({
	[RSAA]: {
		api: post("/equipment/vintages/", params),
		types: CREATE_VINTAGE,
	},
})

export const fetchEquipmentManufacturers = (queryStringParams) => ({
	[RSAA]: {
		api: get(`/equipment/manufacturers/`, queryStringParams),
		types: GET_MANUFACTURERS,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const getManufacturer = (manufacturerId) => ({
	[RSAA]: {
		api: get(`/equipment/manufacturers/${manufacturerId}/`),
		types: GET_MANUFACTURER,
	},
})

export const getModels = (queryStringParams) => {
	return {
		[RSAA]: {
			api: get(`/equipment/models/`, queryStringParams),
			types: GET_MODELS,
			parseLinkHeader: true,
			includeTimestamp: true,
		},
	}
}

export const getModel = (modelId) => {
	return {
		[RSAA]: {
			api: get(`/equipment/models/${modelId}/`),
			types: GET_MODEL,
		},
	}
}

export const getVintages = (queryStringParams) => {
	return {
		[RSAA]: {
			api: get(`/equipment/vintages/`, queryStringParams),
			types: GET_VINTAGES,
			parseLinkHeader: true,
			includeTimestamp: true,
		},
	}
}

export const getVintage = (vintageId) => {
	return {
		[RSAA]: {
			api: get(`/equipment/vintages/${vintageId}/`),
			types: GET_VINTAGE,
		},
	}
}

export const getEquipmentTypes = () => ({
	[RSAA]: {
		api: get("/equipment/types/"),
		types: GET_EQUIPMENT_TYPES,
	},
})

export const getVintageEquipment = (vintageId) => {
	return {
		[RSAA]: {
			api: get(`/equipment/customer_equipment/`, { vintage: vintageId }),
			types: GET_VINTAGE_EQUIPMENT,
		},
	}
}

export const getVintageDispatches = (vintageId) => {
	return {
		[RSAA]: {
			api: get(`/equipment/work_order_machine/`, { vintage: vintageId }),
			types: GET_VINTAGE_DISPATCHES,
		},
	}
}

export const getVintageParts = (vintageId) => {
	return {
		[RSAA]: {
			api: get(`/equipment/parts/`, { vintage: vintageId }),
			types: GET_VINTAGE_PARTS,
		},
	}
}

export const selectManufacturer = (index) => {
	return { type: SELECT_MANUFACTURER, payload: index }
}
export const clearManufacturer = () => {
	return { type: CLEAR_MANUFACTURER }
}
export const selectModel = (index) => {
	return { type: SELECT_MODEL, payload: index }
}
export const clearModel = () => {
	return { type: CLEAR_MODEL }
}
export const selectVintage = (index) => {
	return { type: SELECT_VINTAGE, payload: index }
}
export const clearVintage = () => {
	return { type: CLEAR_VINTAGE }
}

const initialState = {
	manufacturers: [],
	selectedManufacturer: null,

	models: [],
	selectedModel: null,

	vintages: [],
	selectedVintage: null,

	equipmentTypes: [],
	selectedEquipmentType: null,
}

const equipment = (state = initialState, action) => {
	switch (action.type) {
		case GET_MANUFACTURERS.success:
			return { ...state, isLoadingManufacturers: false, manufacturers: action.payload }
		case GET_MODELS.success:
			return { ...state, isLoadingModels: false, models: action.payload }
		case GET_VINTAGES.success:
			return { ...state, vintages: action.payload }
		case GET_EQUIPMENT_TYPES.success:
			return { ...state, equipmentTypes: action.payload }
		case SELECT_MANUFACTURER:
			return {
				...state,
				selectedManufacturer: state.manufacturers.find((r) => r._id === action.payload),
			}
		case CLEAR_MANUFACTURER:
			return { ...state, selectedManufacturer: null }
		case SELECT_MODEL:
			return { ...state, selectedModel: state.models.find((r) => r._id === action.payload) }
		case CLEAR_MODEL:
			return { ...state, selectedModel: null }
		case SELECT_VINTAGE:
			return {
				...state,
				selectedVintage: state.vintages.find((r) => r._id === action.payload),
			}
		case CLEAR_VINTAGE:
			return { ...state, selectVintage: null }
		default:
			return state
	}
}

export default equipment

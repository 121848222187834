import { captureMessage } from "@sentry/react"

import {
	CreditMemoLineItem,
	Dispatch,
	GenericLineItem,
	InvoiceStatusId,
	LineItemPriceOverride,
	LineItemType,
	WorkOrder,
	WorkOrderInvoice,
	WorkOrderInvoiceLineItem,
} from "@ncs/ncs-api"
import {
	DateFormat,
	extractNumber,
	getTimeAgoStartDate,
	isEnumMember,
	TimeAgo,
} from "@ncs/ts-utils"
import { encodeUrlState } from "@ncs/web-legos"

import { ViewDispatchesTabUrlState } from "../views/Service/Dispatches/Dispatches/components"

export const invoiceLineItemToLineItem = (line: WorkOrderInvoiceLineItem): GenericLineItem => {
	if (!isEnumMember(line.typeId.toString(), LineItemType)) {
		captureMessage("Unrecognized line item type ID came from a dispatch invoice", {
			extra: { unrecognizedId: line.typeId },
		})
	}

	let part: GenericLineItem["part"] = null
	if (line.partId && line.partDescription && line.partNumber) {
		part = {
			id: line.partId,
			description: line.partDescription,
			partNumber: line.partNumber,
			partFamily: null, // Don't think we need this for anything dispatch related?
			weight: null,
		}
	}

	// There are some legacy line items that have a total of 0 but the net price doesn't
	// reflect that.
	let finalPrice = extractNumber(line.netPrice)
	if (extractNumber(line.total) === 0) {
		finalPrice = 0
	}

	const tax = extractNumber(line.tax)
	const taxRate = finalPrice !== 0 ? tax / finalPrice : 0

	let overridePermission: GenericLineItem["overridePermission"] | null = null
	if (line.overridePermission && line.overridePermissionId) {
		overridePermission = {
			id: line.overridePermissionId.toString(),
			description: line.overridePermission,
		}
	}

	return {
		id: line.id,
		lineTypeId: line.typeId.toString() as LineItemType,
		part,
		description:
			part ? `(${part.partNumber}) ${part.description}` : line.typeDescription || "",
		quantity: extractNumber(line.quantity),
		finalPrice,
		basePrice: extractNumber(line.unitPrice), // This will be overwritten immediately...
		requestedPrice: null,
		taxRate: taxRate,
		subtotal: extractNumber(line.subTotal),
		priceOverrideReason: null,
		overridePermission,
		reasonComment: null,
		systemGeneratedLine: false,
		originalSystemGeneratedPrice: null,
		overrideApprovalPending: false,
		isBillable: line.billable,
		isUnderWarranty: line.underWarranty,
	}
}

export const allowInvoiceApproval = (invoice: WorkOrderInvoice): boolean => {
	return [
		InvoiceStatusId.Rejected,
		InvoiceStatusId.PendingFieldApproval,
		InvoiceStatusId.PendingSecondApproval,
	].includes(invoice.status)
}

export const allowInvoiceRejection = (invoice: WorkOrderInvoice): boolean => {
	return [InvoiceStatusId.PendingFieldApproval, InvoiceStatusId.PendingSecondApproval].includes(
		invoice.status
	)
}

export const allowInvoiceFinalize = (invoice: WorkOrderInvoice): boolean => {
	return [InvoiceStatusId.InvoiceGenerated, InvoiceStatusId.AdditionalEntry].includes(
		invoice.status
	)
}

export const invoiceWasRejected = (invoice: WorkOrderInvoice): boolean => {
	return [InvoiceStatusId.Rejected].includes(invoice.status)
}

export const allowInvoiceEdit = (invoice: WorkOrderInvoice): boolean => {
	return [
		InvoiceStatusId.ReadyForInvoicing,
		InvoiceStatusId.Rejected,
		InvoiceStatusId.PendingFieldApproval,
		InvoiceStatusId.PendingSecondApproval,
	].includes(invoice.status)
}

export const allowInvoiceCreditMemo = (invoice: WorkOrderInvoice): boolean => {
	return [
		InvoiceStatusId.InvoiceGenerated,
		InvoiceStatusId.AdditionalEntry,
		InvoiceStatusId.Invoiced,
	].includes(invoice.status)
}

export const getDispatchLineDescription = (
	line: WorkOrderInvoiceLineItem | CreditMemoLineItem | WorkOrder["lineItems"][number]
): string => {
	if (line.partDescription && line.partNumber) {
		return `(${line.partNumber}) ${line.partDescription}`
	}

	return line.typeDescription || "(No description)"
}

export const hasWarrantiesOrContracts = (machine: WorkOrder["machines"][number]): boolean => {
	return (
		machine.equipment.contracts.length > 0 ||
		!!machine.equipment.frameWarrantyExpireDate ||
		!!machine.equipment.laborWarrantyExpireDate ||
		!!machine.equipment.partsWarrantyExpireDate
	)
}

export const historyItemIsCreditMemo = (item: WorkOrderInvoice["history"][number]): boolean => {
	return !!item.rejectionReason && item.rejectionReason.includes("Credit Memo")
}

export const getHistoryItemStatus = (item: WorkOrderInvoice["history"][number]): string | null => {
	return historyItemIsCreditMemo(item) ? "Credit Memo" : item.status || null
}

/**
 * Copy pasta from the old UI.
 */
export const getHistoryItemAction = (item: WorkOrderInvoice["history"][number]): string => {
	let action =
		item.status ?
			item.rejectionReason ?
				"Rejected"
			:	"Approved"
		:	"Invoice Note"

	if (historyItemIsCreditMemo(item)) {
		action = "Approved"
		if (item.rejectionReason) {
			if (item.rejectionReason.toLowerCase().indexOf("credit memo created") >= 0) {
				action = "Requested"
			}
			if (item.rejectionReason.toLowerCase().indexOf("credit memo rejected") >= 0) {
				action = "Rejected"
			}
		}
	}

	return action
}

export const invoiceLineItemToOverrideLine = (
	line: WorkOrderInvoiceLineItem,
	dispatch: Dispatch
): LineItemPriceOverride[] => {
	const overrides: LineItemPriceOverride[] = []

	line.approvals.forEach((approval) => {
		overrides.push({
			id: approval.id,
			approvedOn: approval.approvedOn,
			approverComments: approval.approverComments,
			approverName: approval.approver?.name ?? null,
			currentRate: extractNumber(line.netPrice),
			customerName: dispatch.customer.name,
			customerNumber: dispatch.customer.customerNumber,
			deniedOn: approval.deniedOn,
			dispatchId: dispatch.id,
			dispatchNumber: dispatch.dispatchNumber,
			lineApplication: line.overridePermission,
			lineDescription: getDispatchLineDescription(line),
			lineTypeDescription: line.typeDescription,
			partOrderId: null,
			partOrderOrderId: null,
			pendingApproval: approval.pendingApproval,
			reasonComment: approval.reasonComment,
			reasonDescription: approval.reasonDescription,
			requestedOn: approval.requestedOn,
			requestedRate: extractNumber(approval.rate),
			requestorName: approval.requestor.name,
		})
	})

	return overrides
}

/**
 * Check if the total on the invoice makes sense compared to the subtotal and taxes.
 */
export const validateInvoiceTotal = (invoice: WorkOrderInvoice): boolean => {
	const difference =
		extractNumber(invoice.total) -
		extractNumber(invoice.subtotal) -
		extractNumber(invoice.taxTotal)

	return difference < 0.05 && difference > -0.05
}

/**
 * Check that the sum of the line item totals isn't too far off from the invoice's total.
 */
export const validateInvoiceLineItemsTotal = (
	invoice: WorkOrderInvoice,
	invoiceLineItems: WorkOrderInvoiceLineItem[]
): boolean => {
	const lineTotal = invoiceLineItems.reduce((acc, line) => {
		return acc + extractNumber(line.total)
	}, 0)

	const difference = lineTotal - extractNumber(invoice.total)

	return difference < 0.05 && difference > -0.05
}

/**
 * If the invoice's bill-to customer is internal, then the total cannot be above 0.
 */
export const validateBillToTotal = (invoice: WorkOrderInvoice): boolean => {
	if (invoice.billToCustomer?.customerNumber.startsWith("888")) {
		return extractNumber(invoice.total) <= 0
	}

	return true
}

export const dispatchDeepLinks = {
	open: `/service/dispatches${encodeUrlState<ViewDispatchesTabUrlState>({
		statusId: "1",
	})}`,
	newToday: `/service/dispatches${encodeUrlState<ViewDispatchesTabUrlState>({
		callReceivedGte: getTimeAgoStartDate(TimeAgo.Today).format(DateFormat.DateQueryParam),
	})}`,
	closedToday: `/service/dispatches${encodeUrlState<ViewDispatchesTabUrlState>({
		statusId: "2",
		closedDateGte: getTimeAgoStartDate(TimeAgo.Today).format(DateFormat.DateQueryParam),
	})}`,
	openOnContract: `/service/dispatches${encodeUrlState<ViewDispatchesTabUrlState>({
		callTypeId_In: "4,5,6",
	})}`,
	fieldApproval: `/service/dispatches${encodeUrlState<ViewDispatchesTabUrlState>({
		statusId: "2",
		invoice_StatusId: "9",
	})}`,
	secondApproval: `/service/dispatches${encodeUrlState<ViewDispatchesTabUrlState>({
		statusId: "2",
		invoice_StatusId: "10",
	})}`,
	rejected: `/service/dispatches${encodeUrlState<ViewDispatchesTabUrlState>({
		statusId: "2",
		invoice_StatusId: "6",
	})}`,
	additionalEntry: `/service/dispatches${encodeUrlState<ViewDispatchesTabUrlState>({
		statusId: "2",
		invoice_StatusId: "12",
	})}`,
}

import { FC } from "react"

import { LtlCarrierId } from "@ncs/ncs-api"
import {
	AnimatedEntrance,
	Box,
	Button,
	LtlCarrierSelector,
	Paragraph,
	RadioBoolean,
} from "@ncs/web-legos"

import { useShipShipmentContext } from "~/util/ship-shipment"

export const ShipmentCarrier: FC = () => {
	const {
		locationCanFedEx,
		hasPackagePermission,
		selectedCarrierId,
		setSelectedCarrierId,
		newCarrierName,
		setNewCarrierName,
		portalFedEx,
		setPortalFedEx,
		carrierStepComplete,
		setCarrierStepComplete,
	} = useShipShipmentContext()

	return (
		<>
			<Paragraph mb={0.5}>Select LTL carrier (optional)</Paragraph>

			<Box maxWidth={30} smProps={{ maxWidth: "none" }}>
				<LtlCarrierSelector
					value={selectedCarrierId}
					onChange={setSelectedCarrierId}
					newCarrierValue={newCarrierName}
					newCarrierOnChange={setNewCarrierName}
					disableNoSelectionOption={false}
					label={null}
				/>
			</Box>

			<AnimatedEntrance
				show={
					hasPackagePermission &&
					locationCanFedEx &&
					selectedCarrierId === LtlCarrierId.FedEx
				}
			>
				<RadioBoolean
					htmlName="portal-fedex"
					value={portalFedEx}
					onChange={setPortalFedEx}
					yesText="Integrated FedEx"
					noText="FedEx Manager"
					description="Do you want to use the integrated FedEx system or FedEx Manager?"
				/>
			</AnimatedEntrance>

			{!carrierStepComplete && (
				<Button
					icon="check"
					variant="secondary-cta"
					containerProps={{ mt: 1 }}
					onClick={() => setCarrierStepComplete(true)}
					disabled={selectedCarrierId === LtlCarrierId.Other && !newCarrierName}
				>
					Continue
				</Button>
			)}
		</>
	)
}

import { FC, useMemo, useState } from "react"

import { Column } from "react-table"

import {
	BillToCustomers,
	CustomerDetail,
	useBillToCustomers,
	useCustomerBillTos,
} from "@ncs/ncs-api"
import { extractNumber, formatPercentage, tryToFormatPhone } from "@ncs/ts-utils"
import {
	Box,
	cssMixins,
	EmptyValueDash,
	getAddressFields,
	GridContainer,
	GridItem,
	Heading,
	HeadingDivider,
	Icon,
	Label,
	LabeledData,
	Link,
	LoadingSpinner,
	Paragraph,
	ParagraphList,
	Table,
	ThrottledTextInput,
} from "@ncs/web-legos"

export interface CustomerBillingTabProps {
	customer: CustomerDetail
}

export const CustomerBillingTab: FC<CustomerBillingTabProps> = ({ customer }) => {
	const [billerSearch, setBillerSearch] = useState<string | null>(null)

	const [billTos, billTosLoading] = useCustomerBillTos(customer.id)
	const [billers, billersLoading] = useBillToCustomers(customer.id)

	const preparedBillTos = useMemo(() => {
		return (billTos ?? [])
			.filter((b) => b.id !== customer.id)
			.sort((a, b) => (a.isDefault === true && b.isDefault === false ? -1 : 1))
	}, [billTos, customer.id])

	const preparedBillers = useMemo(() => {
		if (!billerSearch) {
			return (billers ?? []).filter((b) => b.id !== customer.id)
		}

		const searchChunks = billerSearch.trim().toUpperCase().split(" ")

		return (billers ?? []).filter((b) => {
			if (b.id === customer.id) return false

			const searchable = `${b.customerNumber}${b.name}`.toUpperCase()

			return searchChunks.every((chunk) => searchable.includes(chunk))
		})
	}, [billerSearch, billers, customer.id])

	const onlyBillToIsSelf = useMemo(() => {
		return billTos?.length === 1 && billTos[0].id === customer.id
	}, [billTos, customer.id])

	const onlyBillerIsSelf = useMemo(() => {
		return billers?.length === 1 && billers[0].id === customer.id
	}, [billers, customer.id])

	const nonSelfBillersCount = useMemo(() => {
		return billers?.filter((b) => b.id !== customer.id).length || 0
	}, [billers, customer.id])

	return (
		<Box d="flex" gap={2} smProps={{ flexDirection: "column" }}>
			<Box flex={1}>
				<HeadingDivider variant="h5">This Customer Bills To</HeadingDivider>
				{billTosLoading && <LoadingSpinner />}
				{onlyBillToIsSelf && (
					<Paragraph small secondary>
						This customer only bills to itself
					</Paragraph>
				)}
				{preparedBillTos.map((b, i) => {
					return (
						<Box
							key={b.id}
							bt={i !== 0 ? 1 : undefined}
							mt={i !== 0 ? 3 : undefined}
							pt={i !== 0 ? 3 : undefined}
						>
							<Box d="flex" gap={1} alignItems="baseline" mb={1}>
								<Heading>
									({b.customerNumber}) {b.name}
								</Heading>
								<Link
									to={`/customer-management/${b.id}`}
									icon="external-link"
									css={cssMixins.noWrap}
									newTab
								>
									View
								</Link>
							</Box>
							<GridContainer pl={1}>
								<GridItem xs={12} sm={6}>
									<LabeledData label="Bill-to type">
										{b.isDefault && <Icon icon="check" />} {b.description}
									</LabeledData>
								</GridItem>
								<GridItem xs={12} sm={6}>
									<LabeledData label="Contact name">
										{b.contactName || <EmptyValueDash />}
									</LabeledData>
								</GridItem>
								<GridItem xs={12} sm={6}>
									<LabeledData label="Email">
										{b.email || <EmptyValueDash />}
									</LabeledData>
								</GridItem>
								<GridItem xs={12} sm={6}>
									<LabeledData label="Phone">
										{tryToFormatPhone(b.phone) || <EmptyValueDash />}
									</LabeledData>
								</GridItem>
								<GridItem xs={12} sm={6}>
									<Label>Address</Label>
									<ParagraphList
										lines={getAddressFields(b, { exclude: "name" })}
									/>
								</GridItem>
								<GridItem xs={12} sm={6}>
									<LabeledData label="Territory">
										{b.territory ?
											`(${b.territory.code}) ${b.territory.description}`
										:	<EmptyValueDash />}
									</LabeledData>
								</GridItem>
								<GridItem xs={12} sm={6}>
									<LabeledData label="Credit status">
										{b.creditCheckStatus || <EmptyValueDash />}
									</LabeledData>
								</GridItem>
								<GridItem xs={12} sm={6}>
									<LabeledData label="AR info">
										{b.arTerm || <EmptyValueDash />}
									</LabeledData>
								</GridItem>
								<GridItem xs={12} sm={6}>
									<LabeledData label="Pricing matrix applied?">
										{b.pricingMatrixId ?
											<>
												<Icon icon="check" /> Pricing matrix applied
											</>
										:	"No"}
									</LabeledData>
								</GridItem>
								<GridItem xs={12} sm={6}>
									<LabeledData label="Part discount %">
										{formatPercentage(
											extractNumber(b.partDiscountPercent)
										) || <EmptyValueDash />}
									</LabeledData>
								</GridItem>
								<GridItem xs={12} sm={6}>
									<LabeledData label="Chemical discount %">
										{formatPercentage(
											extractNumber(b.chemicalDiscountPercent)
										) || <EmptyValueDash />}
									</LabeledData>
								</GridItem>
								<GridItem xs={12} sm={6}>
									<LabeledData label="Detail discount %">
										{formatPercentage(
											extractNumber(b.detailDiscountPercent)
										) || <EmptyValueDash />}
									</LabeledData>
								</GridItem>
							</GridContainer>
						</Box>
					)
				})}
			</Box>
			<Box flex={1}>
				<HeadingDivider variant="h5">
					Sites That Default Bill To This Customer
					{nonSelfBillersCount ? ` (${nonSelfBillersCount})` : ""}
				</HeadingDivider>
				{onlyBillerIsSelf && (
					<Paragraph small secondary>
						The only site that bills to this customer is itself
					</Paragraph>
				)}
				{billersLoading ?
					<LoadingSpinner />
				: nonSelfBillersCount ?
					<>
						<ThrottledTextInput
							value={billerSearch}
							onChange={setBillerSearch}
							fillContainer={false}
							placeholder="Search..."
							icon="search"
							clearable
						/>

						<Table
							data={preparedBillers}
							columns={billersColumns}
							disableAllSorting
							infiniteRowsIncrement={25}
							noDataText={
								billerSearch ? `No sites match "${billerSearch}"` : undefined
							}
							rowMenu={[
								{
									label: "View",
									iconName: "external-link",
									onClick: ({ original }) => {
										window.open(`/customer-management/${original.id}`)
									},
								},
							]}
						/>
					</>
				: onlyBillerIsSelf === false ?
					<Paragraph small secondary>
						No sites bill to this customer
					</Paragraph>
				:	null}
			</Box>
		</Box>
	)
}

const billersColumns: Column<BillToCustomers[number]>[] = [
	{
		Header: "Customer #",
		accessor: "customerNumber",
	},
	{
		Header: "Name",
		accessor: "name",
	},
]

import React from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { BaseSelectFilter, withDynamicTableFilter } from "@ncs/bricks/components"
import { loadLookups, LOOKUPS } from "../../redux/services/lookups"

class EnterpriseFilter extends React.PureComponent {
	componentDidMount() {
		this.props.loadLookups([LOOKUPS.Enterprises])
	}

	render() {
		return (
			<BaseSelectFilter
				id="enterprise"
				labelText="Enterprise"
				options={[{ _id: "", name: "All Enterprises" }, ...this.props.enterprises]}
				valueAccessor="_id"
				textAccessor="name"
				{...this.props}
			/>
		)
	}
}

const mapStateToProps = ({ lookups }) => {
	return {
		isLoadingEnterprises: lookups.isLoadingEnterprises,
		enterprises: lookups.enterprises,
	}
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ loadLookups }, dispatch)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withDynamicTableFilter("enterprise")(EnterpriseFilter))

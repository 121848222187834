import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import moment from "moment"
import { updateFilters } from "../../redux/services/dynamicTables"
import { REPORT_PERIODS_ALL, REPORT_PERIODS_FUTURE, REPORT_PERIODS_PAST } from "../../util/dates"

// custom components
import BaseSelectFilter from "./BaseSelectFilter"
import { formatDate } from "../../util/formatters"

class ReportPeriodFilter extends React.PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			reportPeriod: props.defaultPeriod,
			reportPeriods:
				props.includePastPeriods && props.includeFuturePeriods ? REPORT_PERIODS_ALL
				: props.includeFuturePeriods ? REPORT_PERIODS_FUTURE
				: REPORT_PERIODS_PAST,
		}
	}

	componentDidMount() {
		this.handleChange(this.props.value, true)
	}

	// load ReportPeriods whenever the region changes
	componentDidUpdate(prevProps, prevState, snapshot) {
		// if the reporting period changes from an outside source, update the dates
		if (prevProps.value !== this.props.value && this.props.value !== this.state.reportPeriod) {
			return this.handleChange(this.props.value)
		}

		// report filter is reset, so no dates exist--set them
		if (
			typeof this.props.value !== "undefined" &&
			typeof this.props.startDate === "undefined" &&
			typeof this.props.endDate === "undefined"
		) {
			// do this on a delay because it causes multiple fetches and we want ours to win
			setTimeout(() => this.handleChange(this.props.value), 200)
			return
		}

		// if the user or something else changes the dates, sync up the reporting period
		if (
			prevProps.startDate !== this.props.startDate ||
			prevProps.endDate !== this.props.endDate
		) {
			this.checkForReportPeriodMatch()
		}
	}

	checkForReportPeriodMatch = () => {
		let startDate =
			this.props.startDate ?
				moment(this.props.startDate).startOf("day")
			:	this.props.startDate
		let endDate =
			this.props.endDate ? moment(this.props.endDate).endOf("day") : this.props.endDate

		let match
		if ((startDate === "" && endDate === "") || (startDate === null && endDate === null)) {
			match = this.state.reportPeriods.find((period) => period.value === "all")
		} else if (!startDate || !endDate) {
			match = null
		} else {
			match = this.state.reportPeriods.find(
				(period) => startDate.isSame(period.from()) && endDate.isSame(period.to())
			)
		}

		if (match) {
			// already set appropriately
			if (this.props.value === match.value) {
				return
			}

			// set it, but don't refresh since we're just updating the UI to show the dates match a period preset
			this.handleUpdate(match.value, undefined, undefined, false)
		} else {
			// no matches, set it to custom (but don't refresh data)
			this.handleUpdate("custom", undefined, undefined, false)
		}
	}

	handleChange = (reportPeriod, skipRefreshData) => {
		// if the report period is custom, leave the dates alone
		if (reportPeriod === "custom") {
			return this.handleUpdate(reportPeriod, undefined, undefined, false)
		}

		let period = this.state.reportPeriods.find((x) => x.value === reportPeriod)
		if (!period) return

		let from = period.from()
		let to = period.to()

		this.handleUpdate(reportPeriod, from, to, skipRefreshData !== true)
	}

	handleUpdate = (reportPeriod, startDate, endDate, refreshData) => {
		let updates = { [this.props.reportPeriodFieldName]: reportPeriod }

		if (typeof startDate !== "undefined") {
			updates[this.props.startDateFieldName] = {
				value: startDate,
				text: formatDate(startDate),
			}
		}

		if (typeof endDate !== "undefined") {
			updates[this.props.endDateFieldName] = { value: endDate, text: formatDate(endDate) }
		}

		// set what we think the report period should be so we know when an external thing changes it
		this.setState(
			{ reportPeriod },
			// then update the filters (which will cause an external thing to change the local value)
			() => this.props.updateFilters(updates, refreshData)
		)
	}

	render() {
		return (
			<BaseSelectFilter
				id="reportPeriod"
				labelText="Report Period"
				options={this.state.reportPeriods}
				{...this.props}
				onChange={this.handleChange}
			/>
		)
	}
}

ReportPeriodFilter.defaultProps = {
	reportPeriodFieldName: "report_period",
	startDateFieldName: "start_date",
	endDateFieldName: "end_date",
	defaultPeriod: "since-30-days-ago",
	includePastPeriods: true,
	includeFuturePeriods: false,
}

ReportPeriodFilter.propTypes = {
	/** name of the key under dynamicTables that this report can be found in */
	reduxKey: PropTypes.string.isRequired,

	/** the field this filter is stored as in redux. defaults to report_period */
	reportPeriodFieldName: PropTypes.string,
	/** the start field this filter affects. defaults to start_date */
	startDateFieldName: PropTypes.string,
	/** the end field this filter affects. defaults to end_date */
	endDateFieldName: PropTypes.string,
	/** value of the period to use if none is selected. defaults to since-30-days-ago.
	 * acceptable values can be found in REPORT_PERIODS_ALL */
	defaultPeriod: PropTypes.string,

	/** include ranges from the past (last week, last year, last 90 days, etc.) */
	includePastPeriods: PropTypes.bool,
	/** include ranges in the future (next week, next quarter, etc.) */
	includeFuturePeriods: PropTypes.bool,
}

const mapStateToProps = ({ dynamicTables }, ownState) => {
	const {
		reduxKey,
		reportPeriodFieldName = ReportPeriodFilter.defaultProps.reportPeriodFieldName,
		startDateFieldName = ReportPeriodFilter.defaultProps.startDateFieldName,
		endDateFieldName = ReportPeriodFilter.defaultProps.endDateFieldName,
		defaultPeriod = ReportPeriodFilter.defaultProps.defaultPeriod,
	} = ownState

	let filters = ((dynamicTables || {})[reduxKey] || {}).filtered || []

	return {
		value: (filters.find((x) => x.id === reportPeriodFieldName) || {}).value || defaultPeriod,
		startDate: (filters.find((x) => x.id === startDateFieldName) || {}).value,
		endDate: (filters.find((x) => x.id === endDateFieldName) || {}).value,
	}
}

const mapDispatchToProps = (dispatch, ownState) => {
	const { reduxKey } = ownState

	return bindActionCreators(
		{
			updateFilters: updateFilters(reduxKey),
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportPeriodFilter)

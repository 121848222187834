import React from "react"
import PropTypes from "prop-types"
import { createStyles, makeStyles } from "@material-ui/core"

import {
	ConditionalContent,
	Card,
	CardBody,
	CardHeader,
	CardIcon,
	CardIconTitle,
	CardText,
} from "../../components"
import { MaterialDashboardProColors } from "../../util/types"

import { CardProps } from "./Card"
import { CardHeaderProps } from "./CardHeader"

const styles = createStyles({
	shortHeader: {
		paddingTop: 0,
	},
})

const useStyles = makeStyles(styles)

export interface QuickCardProps extends Omit<CardProps, "title"> {
	/** makes the header text show up in the colored box instead of the icon **/
	textOnlyTitle?: string

	title?: React.ReactNode
	icon?: React.ReactElement
	color?: MaterialDashboardProColors
	headerColor?: Exclude<MaterialDashboardProColors, "gray">
	headerProps?: CardHeaderProps
	show?: boolean
	hide?: boolean
	shortenHeader?: boolean
	children: React.ReactNode | (() => React.ReactNode)
}

const QuickCard: React.FC<QuickCardProps> = ({
	children,
	headerColor = "rose",
	headerProps,
	color,
	hide,
	icon,
	shortenHeader,
	show,
	textOnlyTitle,
	title,
	...rest
}) => {
	const classes = useStyles()

	const content = () => (
		<Card color={color} {...rest}>
			<CardHeader color={headerColor} text {...headerProps}>
				{icon && <CardIcon color={headerColor}>{icon}</CardIcon>}
				{title && <CardIconTitle>{title}</CardIconTitle>}
				{textOnlyTitle && (
					<CardText color="rose">
						<h4>{textOnlyTitle}</h4>
					</CardText>
				)}
			</CardHeader>
			<CardBody className={shortenHeader ? classes.shortHeader : undefined}>
				{typeof children === "function" && children instanceof Function ?
					children()
				:	children}
			</CardBody>
		</Card>
	)

	// conditionally wrap with ConditionalContent
	return typeof show === "undefined" && typeof hide === "undefined" ?
			content()
		:	<ConditionalContent show={show} hide={hide}>
				{content}
			</ConditionalContent>
}

QuickCard.propTypes = {
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,

	/** makes the header text show up in the colored box instead of the icon **/
	textOnlyTitle: PropTypes.string,

	title: PropTypes.node,
	icon: PropTypes.element,
	color: PropTypes.oneOf(["warning", "success", "danger", "info", "primary", "rose", "gray"]),
	headerColor: PropTypes.oneOf(["warning", "success", "danger", "info", "primary", "rose"]),
	show: PropTypes.bool,
	hide: PropTypes.bool,
	shortenHeader: PropTypes.bool,
}

export default React.memo(QuickCard)

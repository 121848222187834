import { ReactElement, useCallback } from "react"

import dayjs from "dayjs"
import { Control, Controller, FieldValues, Path, PathValue } from "react-hook-form"

import { DateFormat, formatDate } from "@ncs/ts-utils"

import { DateInput, DateInputProps } from "../inputs"

export interface DateInputFormFieldProps<TFieldValues extends FieldValues>
	extends Omit<DateInputProps, "value" | "onChange"> {
	name: Path<TFieldValues>
	control: Control<TFieldValues>
	emptyValueFallback?: string | null
	dateFormat?: DateFormat
}

export const DateInputFormField = <TFieldValues extends FieldValues>({
	name,
	control,
	emptyValueFallback = null,
	dateFormat,
	...rest
}: DateInputFormFieldProps<TFieldValues>): ReactElement => {
	const getDateValue = useCallback(
		(rawFormValue: PathValue<TFieldValues, Path<TFieldValues>>) => {
			if (typeof rawFormValue === "string") {
				const date = dayjs(rawFormValue)

				return date.isValid() ? date : null
			}

			return null
		},
		[]
	)

	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { onBlur, value, onChange }, fieldState: { error } }) => {
				return (
					<DateInput
						{...rest}
						onBlur={onBlur}
						value={getDateValue(value)}
						onChange={(newValue) =>
							onChange(
								newValue ?
									dateFormat ? formatDate(newValue.toISOString(), dateFormat)
									:	newValue.toISOString()
								:	emptyValueFallback
							)
						}
						error={error?.message}
					/>
				)
			}}
		/>
	)
}

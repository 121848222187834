import React, { Component } from "react"
import FormValidator from "@ncs/bricks/util/formValidator"
import callApi from "@ncs/bricks/redux/services/callApi"
import { createTechAssignment } from "@ncs/mortar/redux/services/users"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

//local components
import TechAssignmentAdd from "./TechAssignmentAdd"

class TechAssignmentAddContainer extends Component {
	constructor(props) {
		super(props)
		this.validations = {
			description: {
				stateName: "description",
				isRequired: true,
				maxLength: 255,
			},
		}
		this.formValidator = new FormValidator(this, this.validations)

		this.state = { show: false, ...this.formValidator.getInitialFormState() }
	}

	handleCreateAssignment = () => {
		this.setState({ show: true })
	}
	handleCancelCreateAssignment = () => {
		this.setState({ show: false })
	}
	handleAlternateTechSelected = (selectedAlternateTech) => {
		this.setState({ selectedAlternateTech })
	}
	handleDefaultTechSelected = (selectedDefaultTech) => {
		this.setState({ selectedDefaultTech })
	}
	handleTerritorySelected = (selectedTerritory) => {
		this.setState({ selectedTerritory })
	}

	handleSetInputRef = (input) => {
		this.input = input
	}
	handleConfirmTechAssignmentAdd = () => {
		if (!this.formValidator.dataIsValid()) {
			this.setState({ showValidationErrors: true })
			return
		}
		if (!this.state.selectedDefaultTech) {
			return
		}
		if (!this.state.selectedTerritory) {
			return
		}
		if (this.state.selectedAlternateTech) {
			if (this.state.selectedAlternateTech._id === this.state.selectedDefaultTech._id) {
				return
			}
		}
		this.setState({ isSaving: true })
		let techAss = {
			default_tech_id: this.state.selectedDefaultTech._id,
			alternate_id:
				this.state.selectedAlternateTech ? this.state.selectedAlternateTech._id : null,
			territory_id: this.state.selectedTerritory,
		}
		for (const [prop, config] of Object.entries(this.validations)) {
			techAss[prop] = this.state[config.stateName]
		}
		this.props.callApi(createTechAssignment(techAss)).then((result) => {
			this.setState({ isSaving: false, show: false })
		})
	}
	render() {
		return (
			<TechAssignmentAdd
				onStartAssignmentAdd={this.handleCreateAssignment}
				onCancelAssignmentAdd={this.handleCancelCreateAssignment}
				onDefaultTechSelected={this.handleDefaultTechSelected}
				onAlternateTechSelected={this.handleAlternateTechSelected}
				onTerritorySelected={this.handleTerritorySelected}
				onConfirmAssignmentAdd={this.handleConfirmTechAssignmentAdd}
				show={this.state.show}
				selectedTerritory={this.state.selectedTerritory}
				setInputRef={this.handleSetInputRef}
				title="Add Tech Assignment"
				{...this.formValidator.generateFormProps()}
			/>
		)
	}
}
const mapStateToProps = (state) => {
	return {}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			callApi,
		},
		dispatch
	)

export default connect(mapStateToProps, mapDispatchToProps)(TechAssignmentAddContainer)

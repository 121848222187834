import React from "react"
import { withCallApi, withDynamicTableFilter } from "@ncs/bricks/components"
import CustomerSelector from "../Selectors/CustomerSelector"
import { getCustomer } from "../../redux/services/customers"

class CustomerFilter extends React.PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			customers: [],
		}
	}

	filters = ["region", "territory", "enterprise", "organization"]

	// load Customers whenever the higher-level filters change
	componentDidUpdate(prevProps, prevState, snapshot) {
		let filterChanged = this.filters.some(
			(filter) =>
				this.getFilterFromReportState(prevProps, filter) !==
				this.getFilterFromReportState(this.props, filter)
		)

		if (filterChanged) {
			this.clearCustomerIfInvalid()
		}
	}

	getFilterFromReportState = (props, filter) => {
		return (((props.reportState || {}).filtered || []).find((x) => x.id === filter) || {})
			.value
	}

	getQueryParameters = () => {
		return this.filters.reduce(
			(result, filter) => ({
				...result,
				[filter]: this.getFilterFromReportState(this.props, filter) || undefined,
			}),
			{ pageSize: 15 }
		)
	}

	clearCustomerIfInvalid = () => {
		if (!this.props.value) {
			return
		}

		let query = this.getQueryParameters()

		this.props.callApi(getCustomer(this.props.value._id, query)).then((result) => {
			// if the currently selected customer is no longer valid, erase the selected customer
			if (result.error) {
				this.handleCustomerSelected(null)
			}
		})
	}

	handleCustomerSelected = (customer) => {
		this.props.onChange(
			customer,
			!!customer ? `(${customer.customer_number}) ${customer.name}` : ""
		)
	}

	render() {
		return (
			<CustomerSelector
				getQueryParameters={this.getQueryParameters}
				selectedSuggestion={this.props.value || null}
				onCustomerSelected={this.handleCustomerSelected}
				limitToServiceableCustomers={false}
				limitToActiveCustomers={false}
				showKBMManaged={this.props.showKBMManaged}
				showNotKBMManaged={this.props.showNotKBMManaged}
				{...this.props}
			/>
		)
	}
}

export const AliasedCustomerFilter = (filterName, title) =>
	withDynamicTableFilter(filterName, undefined, undefined, undefined, undefined, {
		title,
	})(CustomerFilter)

export default AliasedCustomerFilter("customer", "Customer")

import { FC } from "react"

import { css, useTheme } from "@emotion/react"

import { useAuth } from "@ncs/ncs-api"
import { formatDate } from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	Box,
	Heading,
	useLocalStorageState,
	useScreenSizeMatch,
	useScrollToTopOnMount,
} from "@ncs/web-legos"

import { ncsGear } from "~/assets"
import { DashboardSelector } from "~/components"
import { DashboardName, isDashboardName, userCanViewDashboard } from "~/util"

import { LineItemsDashboard, ServiceDashboard } from "./components"

export const Dashboard: FC = () => {
	useScrollToTopOnMount()
	const screenIsXs = useScreenSizeMatch("xs")
	const { user } = useAuth()
	const theme = useTheme()
	const [selectedDashboard, setSelectedDashboard] = useLocalStorageState<DashboardName | null>(
		null,
		{
			keyOverride: "selected-dashboard",
			validate: (value) => {
				if (!value || !user) return false
				if (isDashboardName(value)) {
					return userCanViewDashboard(value, user)
				}
				return false
			},
		}
	)

	return (
		<div>
			<Box
				display="flex"
				justifyContent="space-between"
				maxWidth={theme.layout?.contentOuterMaxWidth}
				mx="auto"
				flexWrap="wrap-reverse"
				gap={1}
			>
				<DashboardSelector
					value={selectedDashboard}
					onChange={setSelectedDashboard}
					selectCss={dashboardSelectorCss}
					label={null}
					fillContainer={screenIsXs}
				/>

				<div>
					<Heading variant="h3">
						Welcome back, <strong>{user?.name}</strong>
					</Heading>
					<Heading variant="h4" textAlign={screenIsXs ? undefined : "right"}>
						{formatDate(Date.now())}
					</Heading>
				</div>
			</Box>

			<Box maxWidth={theme.layout?.contentOuterMaxWidth} mx="auto" mt={2}>
				{!selectedDashboard ||
					(selectedDashboard === DashboardName.None && (
						<Box textAlign="center" pt={5}>
							<img src={ncsGear} css={ncsGearCss} alt="National Carwash Solutions" />
						</Box>
					))}

				<AnimatedEntrance show={selectedDashboard === DashboardName.Service}>
					<ServiceDashboard />
				</AnimatedEntrance>

				<AnimatedEntrance show={selectedDashboard === DashboardName.LineItems}>
					<LineItemsDashboard />
				</AnimatedEntrance>
			</Box>
		</div>
	)
}

const dashboardSelectorCss = css`
	box-shadow: 0 2px 5px rgb(10 61 83 / 10%);
	border-radius: 4px;
	padding: 0.75rem 1rem;
	padding-right: 2rem;
`
const ncsGearCss = css`
	max-width: 15rem;
	opacity: 0.1;
	filter: saturate(0);
`

import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core"

import styles from "../../assets/jss/material-dashboard-pro-react/components/badgeStyle"
import { MaterialDashboardProColors } from "../../util/types"

const useStyles = makeStyles(styles)

export interface BadgeProps {
	color?: MaterialDashboardProColors
	children: React.ReactNode
}

const Badge: React.FC<BadgeProps> = ({ color, children }) => {
	const classes = useStyles()

	return <span className={`${classes.badge} ${color ? classes[color] : ""}`}>{children}</span>
}

Badge.propTypes = {
	color: PropTypes.oneOf(["primary", "warning", "danger", "success", "info", "rose", "gray"]),
	children: PropTypes.node.isRequired,
}

export default Badge

import { FC, useCallback, useMemo, useState } from "react"

import { css } from "@emotion/react"
import { Cell, Column } from "react-table"

import {
	APPLICATION,
	useUpdateVendorManagement,
	useUserCanUse,
	useVendorManagements,
	VendorManagement as Vendor,
	VendorManagementsQueryParams,
} from "@ncs/ncs-api"
import { formatPhone } from "@ncs/ts-utils"
import {
	Box,
	Button,
	Card,
	EmptyValueDash,
	Icon,
	Table,
	ThrottledTextInput,
	useToast,
} from "@ncs/web-legos"

const VendorManagement: FC = () => {
	const isAdmin = useUserCanUse(APPLICATION.VendorManagement)
	const { makeSuccessToast } = useToast()
	const [params, setParams] = useState<VendorManagementsQueryParams>({
		search: null,
		ordering: null,
	})
	const vendorQuery = useVendorManagements({
		params,
	})
	const updateVendor = useUpdateVendorManagement()
	const [idBeingUpdated, setIdBeingUpdated] = useState<string | null>(null)

	const onVendorToggle = useCallback(
		async (vendor: Vendor) => {
			setIdBeingUpdated(vendor.id)
			await updateVendor({
				id: vendor.id,
				updates: { isActive: !vendor.isActive },
			})
			setIdBeingUpdated(null)
			makeSuccessToast("Vendor updated")
		},
		[updateVendor, makeSuccessToast]
	)

	const columns = useMemo(() => {
		const c: Column<Vendor>[] = [
			{
				Header: "Code",
				accessor: "vendorCode",
			},
			{
				Header: "Name",
				accessor: "name",
			},
			{
				Header: "Phone",
				id: "phone",
				accessor: (original) => {
					const phone = original.contactInfo?.phone || original.phone

					return phone ? formatPhone(phone) : <EmptyValueDash />
				},
				disableSortBy: true,
			},
			{
				Header: "Contact name",
				accessor: (original) => {
					return (
						original.contactInfo?.contactName ||
						original.contactName || <EmptyValueDash />
					)
				},

				disableSortBy: true,
			},
			{
				Header: "Address",
				id: "address",
				disableSortBy: true,
				accessor: (original) => {
					const results = []

					if (original.address1) results.push(original.address1)
					if (original.address2) results.push(original.address2)
					if (original.address3) results.push(original.address3)
					if (original.city) results.push(original.city)
					if (original.state) results.push(original.state)
					if (original.zipCode) results.push(original.zipCode)

					return results.join(", ")
				},
			},
			{
				Header: "KBM Active Code",
				accessor: "kbmActiveCode",
				hiddenByDefault: true,
			},
			{
				Header: "Status",
				accessor: "isActive",
				Cell: ({ row: { original } }: Cell<Vendor>) => (
					<div css={statusCellStyle}>
						{original.isActive ?
							<Icon icon="check-circle" family="solid" color="success" />
						:	<div css={inactiveBlipStyle} />}
						<span>{original.isActive ? "Active" : "Inactive"}</span>
					</div>
				),
			},
		]

		if (isAdmin) {
			c.push({
				Header: "",
				id: "toggle-status-button",
				Cell: ({ row: { original } }: Cell<Vendor>) => (
					<Box pl={1} textAlign="right">
						<Button
							icon={original.isActive ? "times" : "check"}
							isLoading={idBeingUpdated === original.id}
							onClick={() => onVendorToggle(original)}
						>
							{original.isActive ? "Make inactive" : "Make active"}
						</Button>
					</Box>
				),
			})
		}

		return c
	}, [idBeingUpdated, onVendorToggle, isAdmin])

	return (
		<Card heading="Supplier Management">
			<ThrottledTextInput
				value={params.search}
				label="Search suppliers"
				placeholder="Search for a supplier..."
				icon="search"
				maxWidth={20}
				onChange={(newValue) => {
					setParams((prev) => ({
						...prev,
						search: newValue,
					}))
				}}
				clearable
			/>

			<Table
				query={vendorQuery}
				columns={columns}
				queryParamState={params}
				setQueryParamState={setParams}
			/>
		</Card>
	)
}

const statusCellStyle = css`
	display: flex;
	align-items: center;
	span {
		margin-left: 0.5rem;
	}
`
const inactiveBlipStyle = css`
	width: 0.85rem;
	height: 0.85rem;
	background: #eee;
	border-radius: 50%;
	flex: 0 0 auto;
`

export default VendorManagement

import { FC, useMemo, useState } from "react"

import { useThrottle } from "@react-hook/throttle"
import { useHistory } from "react-router-dom"
import { Cell, Column } from "react-table"

import {
	APPLICATION,
	InventoryLocation,
	InventoryPartType,
	OverstockedPart,
	useOpenFulfillmentParts,
	useOverstockedParts,
	useUserCanUse,
} from "@ncs/ncs-api"
import { formatCurrency, formatNumber } from "@ncs/ts-utils"
import {
	Box,
	EmptyValueDash,
	encodeUrlState,
	GridContainer,
	GridItem,
	Icon,
	LetterIcon,
	PartTypeSelector,
	Table,
	TableProps,
	TextInput,
	Tooltip,
} from "@ncs/web-legos"

import { LocationsTab, LocationsUrlState } from "../../Locations"
import { LocationPartsTabUrlState } from "../location-parts-tab"
import { OpenFulfillmentModal } from "./components"

export interface LocationOverstockedTabProps {
	location: InventoryLocation
}

const columns: Column<OverstockedPart>[] = [
	{
		Header: "Part #",
		accessor: "partNumber",
		headingTooltip: (
			<>
				<LetterIcon letter="S" /> = Stock Part
			</>
		),
		headingTooltipIcon: null,
		Cell: ({ row: { original } }: Cell<OverstockedPart>) => {
			return (
				<Box display="flex" alignItems="center" columnGap={0.5}>
					{original.partNumber}
					{original.stockPart && <LetterIcon letter="S" title="Stock Part" />}
				</Box>
			)
		},
	},
	{
		Header: "Part Name",
		id: "partDescription",
		accessor: "partDescription",
	},
	{
		Header: "Unit Cost",
		accessor: "cost",
		sortType: "number",
		Cell: ({ row: { original } }: Cell<OverstockedPart>) => formatCurrency(original.cost),
	},
	{
		Header: "Qty",
		accessor: "quantity",
		sortType: "number",
		Cell: ({ row: { original } }: Cell<OverstockedPart>) => formatNumber(original.quantity),
	},
	{
		Header: "Max Lvl",
		accessor: "maxLevel",
		sortType: "number",
		Cell: ({ row: { original } }: Cell<OverstockedPart>) => formatNumber(original.maxLevel),
	},
	{
		Header: "Qty Over",
		headingTooltip: "Indicates that part is marked for open fulfillment",
		headingTooltipIcon: "share-all",
		sortType: "number",
		accessor: (row) => row.quantityOverStocked ?? 0,
		Cell: ({ row: { original } }: Cell<OverstockedPart>) => {
			return original.quantityOverStocked ?
					<>
						{original.marked ?
							<Tooltip title="Overstocked quantities of this part can be used to fulfill other orders">
								<Box display="flex" alignItems="center" columnGap={0.5}>
									{formatNumber(original.quantityOverStocked)}
									<Icon icon="share-all" />
								</Box>
							</Tooltip>
						:	formatNumber(original.quantityOverStocked)}
					</>
				:	<EmptyValueDash />
		},
	},
	{
		Header: "$ Amount Over",
		id: "amountOverstocked",
		sortType: "number",
		accessor: (original) => original.cost * (original.quantityOverStocked ?? 0),
		Cell: ({
			row: {
				original: { cost, quantityOverStocked },
			},
		}: Cell<OverstockedPart>) =>
			quantityOverStocked != null ?
				formatCurrency(cost * quantityOverStocked)
			:	<EmptyValueDash />,
	},
]

const initialTableState = {
	sortBy: [
		{
			id: "amountOverstocked",
			desc: true,
		},
	],
}

export const LocationOverstockedTab: FC<LocationOverstockedTabProps> = ({ location }) => {
	const history = useHistory()
	const isRdcManager = useUserCanUse(APPLICATION.RDCManager)
	const [searchText, setSearchText] = useState<string | null>(null)
	const [throttledSearchText, setThrottledSearchText] = useThrottle<string | null>(null, 3)
	const [partType, setPartType] = useState<InventoryPartType | null>(InventoryPartType.All)
	const [partForModal, setPartForModal] = useState<OverstockedPart | null>(null)

	const [overstockedParts, isLoading] = useOverstockedParts(location.id, {
		params: { partType },
		queryConfig: {
			refetchOnWindowFocus: true,
		},
	})
	const [openFulfillmentParts] = useOpenFulfillmentParts(location.id)

	const preparedOverstockedParts = useMemo(() => {
		return !throttledSearchText ?
				overstockedParts ?? []
			:	(overstockedParts ?? []).filter((p) =>
					`${p.partDescription} ${p.partNumber}.`
						.toUpperCase()
						.includes(throttledSearchText.toUpperCase())
				)
	}, [overstockedParts, throttledSearchText])

	const rowMenu: TableProps<OverstockedPart>["rowMenu"] = useMemo(
		() => [
			{
				label: "Edit open fulfillment",
				iconName: "share-all",
				onClick: ({ original }) => setPartForModal(original),
				disabledAccessor: ({ original }) => original.stockPart && !!openFulfillmentParts,
			},
			isRdcManager ?
				{
					label: "View this part at location",
					iconName: "external-link",
					onClick: ({ original }) => {
						history.push({
							// Path is actually the same, but changing the trailing slash triggers query
							// param state to read from the URL.
							pathname:
								history.location.pathname.endsWith("/") ?
									"/inventory/locations"
								:	"/inventory/locations/",
							search: encodeUrlState<LocationsUrlState & LocationPartsTabUrlState>({
								urlLocationId: location.id,
								tab: LocationsTab.Parts,
								partsTabPart: original.partId.toString(),
							}),
						})
					},
				}
			:	undefined,
		],
		[history, isRdcManager, location.id, openFulfillmentParts]
	)

	return (
		<>
			<GridContainer>
				<GridItem xs={12} sm={6} md={4}>
					<TextInput
						label="Search"
						icon="search"
						value={searchText}
						onChange={(value) => {
							setSearchText(value)
							setThrottledSearchText(value)
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={6} md={4}>
					<PartTypeSelector value={partType} onChange={setPartType} fillContainer />
				</GridItem>
			</GridContainer>

			<Table
				columns={columns}
				isLoading={isLoading}
				data={preparedOverstockedParts}
				initialState={initialTableState}
				rowMenu={rowMenu}
			/>

			{openFulfillmentParts && (
				<OpenFulfillmentModal
					part={partForModal}
					locationId={location.id}
					openFulfillmentParts={openFulfillmentParts}
					isOpen={!!partForModal}
					onClose={() => setPartForModal(null)}
				/>
			)}
		</>
	)
}

import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators, compose } from "redux"
import { setFilter } from "../../redux/services/dynamicTables"

/** HOC to provide value and onChange props for accessing/setting filters **/
const withDynamicTableFilter = (Component) => {
	class DynamicTableFilter extends React.PureComponent {
		render() {
			const { value, setFilter, ...rest } = this.props
			return <Component {...rest} value={value} onChange={setFilter} />
		}
	}

	return DynamicTableFilter
}

const getReduxKeys = ({ reduxKey, reduxKeys }) => {
	let keys = reduxKey ? [reduxKey] : reduxKeys

	if (!Array.isArray(keys) || keys.length < 1) {
		throw Error(
			"Cannot use withDynamicTableFilter without providing one of reduxKey or reduxKeys"
		)
	}

	return keys
}

const mapStateToProps =
	(fieldName, reduxKeys, valuePropName = "value", extraProps = {}) =>
	({ dynamicTables }, ownState) => {
		// only map the first one, since all should be set the same
		let reduxKey =
			reduxKeys ?
				Array.isArray(reduxKeys) ?
					reduxKeys[0]
				:	reduxKeys
			:	getReduxKeys(ownState)[0]
		let reportState = dynamicTables && reduxKey ? dynamicTables[reduxKey] : undefined

		// special case for search filter
		if (fieldName === "search") {
			return { reportState, value: reportState ? reportState[fieldName] : undefined }
		}

		return {
			reportState,
			[valuePropName]:
				reportState && reportState.filtered ?
					(reportState.filtered.find((x) => x.id === fieldName) || {}).value
				:	undefined,
			...extraProps,
		}
	}

const mapDispatchToProps =
	(fieldName, valueProcessor, reduxKeys, setFilterPropName = "setFilter") =>
	(dispatch, ownState) => {
		let keys = reduxKeys || getReduxKeys(ownState)

		if (typeof keys === "string") keys = [keys]

		let setFilters = (value, text) => (dispatch) =>
			keys.forEach((k) => setFilter(k, fieldName)(value, text)(dispatch))

		return bindActionCreators(
			{
				[setFilterPropName]:
					typeof valueProcessor === "function" ?
						(value, text) => setFilters(valueProcessor(value), text)
					:	setFilters,
			},
			dispatch
		)
	}

withDynamicTableFilter.propTypes = {
	reduxKey: PropTypes.string,
	reduxKeys: PropTypes.arrayOf(PropTypes.string),
}

export default (
	fieldName,
	valueProcessor,
	reduxKeys,
	setFilterPropName,
	valuePropName,
	extraProps
) =>
	compose(
		connect(
			mapStateToProps(fieldName, reduxKeys, valuePropName, extraProps),
			mapDispatchToProps(fieldName, valueProcessor, reduxKeys, setFilterPropName)
		),
		withDynamicTableFilter
	)

import { FC, useState } from "react"

import { useQueryClient } from "react-query"
import { Column } from "react-table"

import {
	Erp,
	ErpLineItem,
	GenericLineItemWithBasePrice,
	lineItemsAreEqual,
	lineTypesRepeatable,
	makeApiErrorMessage,
	useAddErpLineItem,
	useEditErpLineItem,
	useRemoveErpLineItem,
	WorkordersUrlPath,
} from "@ncs/ncs-api"
import { extractNumber, formatCurrency, formatNumber } from "@ncs/ts-utils"
import { Box, Button, Heading, Table, useToast } from "@ncs/web-legos"

import { LineItemEditorModal, SaveLineItemExtras } from "~/components"

import {
	erpLineItemToGenericLineItem,
	lineItemToErpLineItemPatch,
	lineItemToErpLineItemPost,
} from "../quotes-util"

export interface QuoteDetailLineItemsProps {
	erp: Erp
}

export const QuoteDetailLineItems: FC<QuoteDetailLineItemsProps> = ({ erp }) => {
	const [showLineItemModal, setShowLineItemModal] = useState<boolean | ErpLineItem>(false)
	const queryClient = useQueryClient()
	const { makeSuccessToast, makeErrorToast } = useToast()
	const addLineItem = useAddErpLineItem()
	const removeLineItem = useRemoveErpLineItem()
	const updateLineItem = useEditErpLineItem(erp.id)

	const delayedCacheClear = () => {
		// Some of the endpoints take a little while to recalculate, so the update isn't ready when
		// the call returns and cache is invalidated at its normal time. Do it again here
		// after a few seconds.
		setTimeout(() => {
			void queryClient.invalidateQueries(WorkordersUrlPath.Workorders)
		}, 3000)
	}

	const handleRemoveLineItem = async (ids: { erpId: string; lineItemId: string }) => {
		await removeLineItem({
			erpId: ids.erpId,
			erpLineItemId: ids.lineItemId,
		})
		makeSuccessToast("Line item removed")
		delayedCacheClear()
	}

	const handleSaveLine = async (
		newLine: GenericLineItemWithBasePrice,
		{ isEdit }: SaveLineItemExtras
	) => {
		// If any of the current line items are the same type as the new one, then unless
		// it's one of the types that can have repeats, show an error and stop.
		if (
			!isEdit &&
			erp.erpLineItem.some(
				(currentLine) =>
					(currentLine.lineItemTypeId.toString() === newLine.lineTypeId &&
						lineTypesRepeatable.includes(newLine.lineTypeId) === false) ||
					lineItemsAreEqual(erpLineItemToGenericLineItem(currentLine), newLine)
			)
		) {
			makeErrorToast(
				"Line item type is already on this quote. Try editing or removing the existing line."
			)
			return
		}

		try {
			if (isEdit) {
				if (!newLine.id) {
					throw new Error(
						"Trying to save a line item but no ID was found. Should not happen."
					)
				}
				await updateLineItem({
					updates: lineItemToErpLineItemPatch(newLine, erp.id),
					id: newLine.id,
				})
				makeSuccessToast("Line item updated")
				delayedCacheClear()
			} else {
				await addLineItem(lineItemToErpLineItemPost(newLine, erp.id))
				makeSuccessToast("Line item added")
				delayedCacheClear()
			}
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		}
	}

	return (
		<>
			<Heading variant="h2" icon="list">
				Line Items
			</Heading>

			<Box pl={1.5}>
				<Button
					icon="plus-circle"
					variant="secondary-cta"
					containerProps={{ my: 2 }}
					onClick={() => setShowLineItemModal(true)}
				>
					Add line item
				</Button>

				<Table
					data={erp.erpLineItem}
					columns={lineItemColumns}
					rowMenu={[
						{
							label: "Edit",
							iconName: "pencil",
							onClick: (row) => setShowLineItemModal(row.original),
						},
					]}
				/>
			</Box>

			{!!showLineItemModal && (
				<LineItemEditorModal
					shipToId={erp.customer.id}
					lineItemToEdit={
						typeof showLineItemModal !== "boolean" ?
							erpLineItemToGenericLineItem(showLineItemModal)
						:	undefined
					}
					billToId={null}
					onSave={handleSaveLine}
					onRemove={({ id }) => handleRemoveLineItem({ erpId: erp.id, lineItemId: id })}
					onClose={() => setShowLineItemModal(false)}
				/>
			)}
		</>
	)
}

const lineItemColumns: Column<ErpLineItem>[] = [
	{
		Header: "Part #",
		accessor: "partNumber",
	},
	{
		Header: "Description",
		accessor: "description",
	},
	{
		Header: "Qty ordered",
		accessor: ({ quantity }) => formatNumber(extractNumber(quantity)),
	},
	{
		Header: "Unit price",
		accessor: ({ netPrice }) => formatCurrency(netPrice),
	},
	{
		Header: "Line tax",
		accessor: ({ tax }) => formatCurrency(tax),
		hiddenByDefault: true,
	},
	{
		Header: "Line total",
		accessor: ({ total }) => formatCurrency(total),
		hiddenByDefault: true,
	},
]

import { FC, useMemo } from "react"

import {
	LineItemType,
	lineTypesAccessorials,
	useWorkOrderLineItemTypes,
	WorkOrderLineItemType,
	useUserCanUse,
	APPLICATION,
} from "@ncs/ncs-api"
import { ExtendableSelectProps, Select } from "@ncs/web-legos"

export type LineItemTypeSelectorProps = ExtendableSelectProps<
	WorkOrderLineItemType,
	LineItemType
> & {
	/**
	 * By default the call only returns lines that are user selectable.
	 */
	includeAll?: boolean
	/**
	 * @deprecated
	 */
	hideShippingExtras?: boolean
}

export const LineItemTypeSelector: FC<LineItemTypeSelectorProps> = ({
	hideShippingExtras,
	includeAll,
	...rest
}) => {
	const [types, isLoading] = useWorkOrderLineItemTypes({
		params: {
			includeAll: includeAll ? true : undefined,
		},
	})
	const canAddEditFuelSurcharge = useUserCanUse(APPLICATION.FuelSurchargeEditor)

	const options = useMemo(() => {
		return (types ?? [])
			.filter((t) => {
				if (hideShippingExtras) {
					return !lineTypesAccessorials.includes(t.id)
				}
				if (!canAddEditFuelSurcharge) {
					return !(t.id === LineItemType.FuelSurcharge)
				}

				return true
			})
			.sort((a, b) => {
				const aStr = a.description.toLowerCase()
				const bStr = b.description.toLowerCase()

				return aStr > bStr ? 1 : -1
			})
	}, [types, hideShippingExtras, canAddEditFuelSurcharge])

	return (
		<Select
			label="Line item type"
			{...rest}
			onChange={(value, option) => rest.onChange?.(option?.id ?? null, option)}
			options={options}
			isLoading={isLoading}
			valueAccessor="id"
			textAccessor="description"
		/>
	)
}

import { FC, useEffect, useState } from "react"

import dayjs from "dayjs"

import {
	AccessorialPrice,
	LineItemType,
	makeApiErrorMessage,
	useCreateAccessorialPrice,
	useDeleteAccessorialPrice,
	useUpdateAccessorialPrice,
} from "@ncs/ncs-api"
import { isEnumMember } from "@ncs/ts-utils"
import {
	Box,
	Checkbox,
	ConfirmationModal,
	ConfirmationModalConfig,
	DateInput,
	ExtendableModalProps,
	LabeledData,
	Modal,
	NumericInput,
	useIsSaving,
	useToast,
} from "@ncs/web-legos"

import { LineItemTypeSelector } from "~/components"

export interface EditAccessorialModalProps extends ExtendableModalProps {
	toEdit: AccessorialPrice | null
}

export const EditAccessorialModal: FC<EditAccessorialModalProps> = ({ toEdit, ...rest }) => {
	const { makeSuccessToast } = useToast()
	const [lineItemType, setLineItemType] = useState<LineItemType | null>(() => {
		const editType = toEdit?.lineItemTypeId.toString()

		return isEnumMember(editType, LineItemType) ? editType : null
	})
	const [rate, setRate] = useState<number | null>(toEdit?.rate ?? null)
	const [startDate, setStartDate] = useState(toEdit?.startDate ? dayjs(toEdit.startDate) : null)
	const [endDate, setEndDate] = useState(toEdit?.endDate ? dayjs(toEdit.endDate) : null)
	const [freeFreightExcluded, setFreeFreightExcluded] = useState(
		toEdit?.freeFreightExcluded ?? false
	)
	const { isSaving, setSaving, endSaving } = useIsSaving()
	const [errorText, setErrorText] = useState<string | null>(null)
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)

	const updatePrice = useUpdateAccessorialPrice()
	const createPrice = useCreateAccessorialPrice()
	const deletePrice = useDeleteAccessorialPrice()

	const handleSave = async () => {
		try {
			if (rate == null || !lineItemType || !startDate || !endDate) {
				throw new Error("All fields required")
			}
			if (endDate.isBefore(startDate)) {
				throw new Error("End date must come after start date")
			}

			setSaving()

			if (toEdit) {
				await updatePrice({
					id: toEdit.id,
					updates: {
						rate,
						lineItemTypeId: lineItemType,
						startDate: startDate.toISOString(),
						endDate: endDate.toISOString(),
						freeFreightExcluded,
					},
				})
				makeSuccessToast("Charge updated")
			} else {
				await createPrice({
					rate,
					lineItemTypeId: lineItemType,
					startDate: startDate.toISOString(),
					endDate: endDate.toISOString(),
					freeFreightExcluded,
				})
				makeSuccessToast("New charge created")
			}
			rest.onClose()
		} catch (e) {
			endSaving()
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const handleDelete = (id: string | number) => {
		setConfirmationConfig({
			title: "Delete Accessorial Rate?",
			message: "Confirm: Delete this accessorial rate?",
			onConfirm: async () => {
				try {
					await deletePrice(id.toString())
					makeSuccessToast("Rate deleted")
					rest.onClose()
				} catch (e) {
					setErrorText(makeApiErrorMessage(e))
				}
			},
		})
	}

	useEffect(() => {
		setErrorText(null)
	}, [rate, startDate, endDate, lineItemType])

	return (
		<Modal
			{...rest}
			title={toEdit ? "Edit Accessorial Charge" : "New Accessorial Charge"}
			errorText={errorText}
			leftButtons={
				toEdit ?
					{
						buttonText: "Delete",
						icon: "trash-alt",
						variant: "text",
						onClick: () => handleDelete(toEdit.id),
					}
				:	undefined
			}
			rightButtons={{
				buttonText: toEdit ? "Save changes" : "Create",
				onClick: handleSave,
				isLoading: isSaving(),
			}}
		>
			{toEdit ?
				<LabeledData label="Line item type">{toEdit.description}</LabeledData>
			:	<LineItemTypeSelector
					value={lineItemType}
					onChange={setLineItemType}
					fillContainer
					includeAll
				/>
			}
			<NumericInput
				value={rate}
				onChange={(v) => setRate(v ?? null)}
				label="Rate $"
				decimalScale={2}
				fixedDecimalScale
			/>
			<Box d="flex" gap={1}>
				<DateInput
					value={startDate}
					onChange={setStartDate}
					label="Start date"
					disablePast
				/>
				<DateInput value={endDate} onChange={setEndDate} label="End date" disablePast />
			</Box>

			<Checkbox
				label="When an order qualifies for free freight, customer should still be charged for this line item"
				value={freeFreightExcluded}
				onChange={setFreeFreightExcluded}
			/>

			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</Modal>
	)
}

import { FC } from "react"

import { Card, Icon, Tabs, useUrlState } from "@ncs/web-legos"

import { QuotesCreateTab } from "./QuotesCreateTab/QuotesCreateTab"
import { QuotesViewTab } from "./QuotesViewTab/QuotesViewTab"

export type QuotesUrlState = {
	tab: QuotesTab
}

export enum QuotesTab {
	ViewQuotes = "View Quotes",
	CreateQuote = "Create Quote",
}

const Quotes: FC = () => {
	const [{ tab }, { updateUrlValue }] = useUrlState<QuotesUrlState>({
		tab: QuotesTab.ViewQuotes,
	})

	return (
		<Card heading="Quotes" headingIcon="message-dollar">
			<Tabs
				currentTab={tab}
				onChange={(newTab) => updateUrlValue("tab", newTab)}
				panels={[
					{
						navLabel: QuotesTab.ViewQuotes,
						navIcon: <Icon icon="list" />,
						component: <QuotesViewTab />,
					},
					{
						navLabel: QuotesTab.CreateQuote,
						navIcon: <Icon icon="plus" />,
						component: <QuotesCreateTab updateUrlValue={updateUrlValue} />,
					},
				]}
			/>
		</Card>
	)
}

export default Quotes

import { ReactElement, useMemo } from "react"

import {
	Control,
	Controller,
	FieldError,
	FieldValues,
	Path,
	PathValue,
	UnpackNestedValue,
} from "react-hook-form"

import { useInventoryLocations } from "@ncs/ncs-api"

import { LocationSelector, LocationSelectorProps } from "../selectors"

export interface LocationSelectorFormFieldProps<TFieldValues extends FieldValues>
	extends Omit<LocationSelectorProps, "value" | "onChange"> {
	control: Control<TFieldValues>
	name: Path<TFieldValues>
	emptyValueFallback?: string | null
}

export const LocationSelectorFormField = <TFieldValues extends FieldValues>({
	name,
	control,
	...rest
}: LocationSelectorFormFieldProps<TFieldValues>): ReactElement => {
	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { onBlur, value, onChange }, fieldState: { error } }) => {
				return (
					<LocationSelectorFormFieldRender
						{...rest}
						formValue={value}
						formOnChange={onChange}
						formOnBlur={onBlur}
						formError={error}
					/>
				)
			}}
		/>
	)
}

interface LocationSelectorFormFieldRenderProps<TFieldValues extends FieldValues>
	extends Omit<LocationSelectorProps, "value" | "onChange"> {
	formValue: UnpackNestedValue<PathValue<TFieldValues, Path<TFieldValues>>>
	formOnChange: (newValue: string | null) => void
	formOnBlur?: () => void
	formError?: FieldError
	emptyValueFallback?: string | null
}

const LocationSelectorFormFieldRender = <TFieldValues extends FieldValues>({
	formValue,
	formOnChange,
	formOnBlur,
	formError,
	emptyValueFallback = null,
	...rest
}: LocationSelectorFormFieldRenderProps<TFieldValues>): ReactElement => {
	const [locations] = useInventoryLocations()

	const value = useMemo(() => {
		return (locations ?? []).find((l) => l.id === formValue) ?? null
	}, [locations, formValue])

	return (
		<LocationSelector
			{...rest}
			onBlur={formOnBlur}
			value={value}
			initialId={formValue}
			onChange={(newValue) => {
				formOnChange(newValue?.id ?? emptyValueFallback)
			}}
			error={formError?.message}
		/>
	)
}

import React from "react"

// custom components
import { BaseDateFilter, withDynamicTableFilter } from "@ncs/bricks/components"

class StartDateFilter extends React.PureComponent {
	render() {
		return <BaseDateFilter labelText="Start Date" {...this.props} />
	}
}

export default withDynamicTableFilter("start_date")(StartDateFilter)

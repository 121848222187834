import React from "react"

import { withStyles } from "@material-ui/core"
import classNames from "classnames"
import isEqual from "lodash/isEqual"
import orderBy from "lodash/orderBy"
import property from "lodash/property"
import slice from "lodash/slice"
import uniq from "lodash/uniq"
import moment from "moment"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import ReactTable from "react-table-6"
import { bindActionCreators } from "redux"

import { ConditionalContent, DynamicTableActions } from "../../components"
import { callApi } from "../../redux/services/callApi"
import {
	fetchData,
	generateFilters,
	generateFilterText,
	generateOrdering,
	generateQuerystringParameters,
	getDefaultFilters,
	getDefaultForFilter,
	handleExpandedChange,
	handleFilteredChange,
	handlePageChange,
	handlePageSizeChange,
	handleResizedChange,
	handleSearchChange,
	handleSortedChange,
	handleToggleColumn,
	resetFilters,
	setSelectedColumns,
	setUpDynamicReport,
	updateFetchFn,
} from "../../redux/services/dynamicTables"
import { formatDateTimeForFilename } from "../../util/formatters"

const styles = {
	left: {
		textAlign: "left",
	},
	clickableRow: {
		cursor: "pointer",
	},
	tableActionsWrapper: {
		display: "flex",
		justifyContent: "space-between",
	},
	resetFilters: {
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "bottom",
	},
	columnChooser: {
		display: "flex",
		justifyContent: "flex-end",
		alignItems: "bottom",
	},
	stickyHeadings: {
		"& .rt-table": {
			overflow: "unset",
		},
		"& .rt-thead": {
			position: "sticky",
			top: 0,
			background: "rgba(255, 255, 255, 0.9)",
		},
	},
	smallHeadings: {
		"& .rt-table .rt-thead .rt-tr .rt-th": {
			fontSize: "11px",
			textTransform: "uppercase",
			opacity: 0.6,
			fontWeight: "bold",
		},
	},
}

class DynamicTable extends React.PureComponent {
	constructor(props) {
		super(props)
		this.state = this.getInitialState(props)
	}

	getInitialState = (props) => {
		return {
			isLoading: true,

			fetched: {},
			search: "",

			sorted: [],
			page: 0,
			pageSize: props.defaultPageSize || 25,
			expanded: {},
			resized: [],
			filtered: [],

			lastRequestTimestamp: null,

			filterFields: uniq([...(props.filterFields || []), "search", "page"]),

			...this.getDefaultColumnState(),
		}
	}

	componentDidMount = () => {
		if (
			typeof this.props.fetchDataAction === "undefined" &&
			typeof this.props.data === "undefined"
		) {
			throw Error(
				"No data or data fetchers provided. Pass one of either 'fetchDataAction' or 'data' props."
			)
		}

		if (this.props.reduxKey) {
			this.initializeForRedux()
		}

		let cancelInitialFetch = false
		if (typeof this.props.onPostInit === "function") {
			let postInitResponse = this.props.onPostInit(this.props)
			cancelInitialFetch = postInitResponse?.cancel === true
		}

		if (!cancelInitialFetch) {
			this.initialFetch()
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		// update state's data if prop data changes
		if (typeof this.props.data !== "undefined" && this.props.data !== prevProps.data) {
			this.handleSuppliedData()
		}

		// let fetchDataAction update itself
		if (this.props.fetchDataAction !== prevProps.fetchDataAction) {
			this.props.updateFetchFnRedux(this.props.fetchDataAction)
		}
	}

	initialFetch = () => {
		if (this.props.reduxKey) {
			// give a bit of time for all the other start-up stuff to set their filters before requesting data
			setTimeout(this.props.fetchDataRedux, 400)
		} else {
			this.fetchData()
		}
	}

	generateExportFilename = (prefix) => (props) => {
		// if prefix doesn't end in a hyphen
		if (prefix && !/- ?$/.test(prefix)) {
			prefix += " - "
		}

		return `${prefix}Exported ${formatDateTimeForFilename(
			moment().utc()
		)} - ${generateFilterText(props)}`
	}

	initializeForRedux = () => {
		this.props.setUpDynamicReportRedux({
			retain: this.props.retain,
			fetchFn: this.props.fetchDataAction,
			generateQueryFn: this.props.generateQueryFn,
			additionalQueryParams: this.props.additionalQueryParams,
			generateFiltersFn: this.props.generateFiltersFn,
			generateOrderingFn: this.props.generateOrderingFn,
			generateExportFilenameFn: this.generateExportFilename(this.props.exportFilenamePrefix),
			preFetchFn: this.props.onPreFetch,
			dataProperty: this.props.dataProperty,
			useClientSideDataProcessing: this.props.useClientSideDataProcessing,
			filterFields: this.props.filterFields,
			defaults: {
				pageSize: this.props.defaultPageSize || 25,
				...this.getDefaultColumnState(),
				...this.getDefaultFilterValues(),
			},
			...this.props.additionalReduxState,
		})
	}

	getDefaultColumnState = () => {
		return {
			availableColumns: this.props.columns.map((c) => c.Header),
			toggleableColumns: this.props.columns
				.filter((c) => c.toggleable || c.hiddenByDefault)
				.map((c) => c.Header),
			selectedColumns: this.props.columns
				.filter((c) => !c.hiddenByDefault)
				.map((c) => c.Header),
		}
	}

	getDefaultFilterValues = () => {
		const defaults = this.getInitialState(this.props)

		return {
			filtered: defaults.filterFields
				.filter((x) => typeof x === "object")
				.map((field) => getDefaultForFilter(field, defaults)),
		}
	}

	fetchData = (stateChanges) => {
		if (typeof this.props.onPreFetch === "function") {
			let preFetchResponse = this.props.onPreFetch(this.props)
			if (preFetchResponse?.cancel === true) {
				return
			}
		}

		let state = { ...this.state, ...stateChanges }

		if (typeof this.props.data !== "undefined") {
			// setState synchronously, then handle supplied data
			return this.setState(state, this.handleSuppliedData)
		}

		this.setState(state)

		let query = generateQuerystringParameters({
			...state,
			generateFilters: generateFilters,
			generateOrdering: generateOrdering,
		})

		// ignore duplicate queries within half a second of each other
		if (
			state.fetched.time &&
			new Date() - state.fetched.time < 500 &&
			isEqual(query, state.fetched.query)
		) {
			return
		}

		this.setState({ fetched: query, time: new Date(), isLoading: true })

		this.props
			.callApi(this.props.fetchDataAction(query))
			.then((result) => this.handleFetchDataResult(result, query))
	}

	// fake a request result and let handleFetchDataResult do the dirty work
	handleSuppliedData = () => {
		// handle setting page size to content length when useClientSidePaging is false
		let pageSize =
			this.props.useClientSidePaging ?
				this.state.pageSize
			:	(this.props.data || []).length || 25

		let result = {
			payload: this.props.data,
			meta: {
				pages: Math.ceil(this.props.data.length / pageSize),
			},
		}

		let query = {
			page: this.state.page,
			pageSize,
		}

		this.handleFetchDataResult(result, query)
	}

	handleFetchDataResult = (result, query) => {
		let payload = result.payload
		let pages = result.meta && result.meta.pages

		// ignore if result is out-of-order
		if (result?.meta?.timestamp && this.state.lastRequestTimestamp > result?.meta?.timestamp) {
			return
		}

		// if we're on the last real page
		if (!pages && !!payload) {
			pages = query.page
		}

		if (typeof this.props.dataProperty === "string") {
			payload = property(this.props.dataProperty)(payload)
		}

		if (this.props.useClientSideDataProcessing || typeof this.props.data !== "undefined") {
			// handle filtering
			if (this.state.filtered && this.state.filtered.length > 0) {
				for (let filter of this.state.filtered) {
					let words = filter.value.toLowerCase().split(" ")
					let getProp = property(filter.id)
					payload = payload.filter((item) => {
						return words.every(
							(word) => getProp(item).toLowerCase().indexOf(word) >= 0
						)
					})
				}
			}

			// handle sorting
			if (this.state.sorted && this.state.sorted.length > 0) {
				let sorted = this.state.sorted
				payload = orderBy(
					payload,
					sorted.map((x) => x.id),
					sorted.map((x) => (x.desc ? "desc" : "asc"))
				)
			}

			// handle paging locally
			let page = Math.max(query.page, 0)
			let start = page * query.pageSize
			let end = (page + 1) * query.pageSize
			pages = Math.ceil(payload.length / query.pageSize)
			payload = [...slice(payload, start, end)]
		}

		this.setState({
			isLoading: false,
			data: payload,
			pages,
			// store timestamp so we know how to bail on out-of-order results
			lastRequestTimestamp: result?.meta?.timestamp,
		})
	}

	handleSortedChange = (sorted) => {
		if (this.props.reduxKey) {
			this.props.handleSortedChangeRedux(sorted)
		} else {
			this.fetchData({ sorted })
		}
	}

	handlePageChange = (page) => {
		if (this.props.reduxKey) {
			this.props.handlePageChangeRedux(page)
		} else {
			this.fetchData({ page })
		}
	}

	handlePageSizeChange = (pageSize, page) => {
		if (this.props.reduxKey) {
			this.props.handlePageSizeChangeRedux(pageSize, page)
		} else {
			this.fetchData({ pageSize, page })
		}
	}

	handleExpandedChange = (expanded) => {
		if (this.props.reduxKey) {
			this.props.handleExpandedChangeRedux(expanded)
		} else {
			this.setState({ expanded })
		}
	}

	handleResizedChange = (resized) => {
		if (this.props.reduxKey) {
			this.props.handleResizedChangeRedux(resized)
		} else {
			this.setState({ resized })
		}
	}

	handleFilteredChange = (filtered) => {
		if (this.props.reduxKey) {
			this.props.handleFilteredChangeRedux(filtered)
		} else {
			this.fetchData({ filtered })
		}
	}

	handleResetFilters = () => {
		if (this.props.reduxKey) {
			this.props.resetFiltersRedux()
		} else {
			this.fetchData(
				getDefaultFilters(this.state.filterFields, this.getInitialState(this.state))
			)
		}
	}

	handleToggleColumn = (column) => {
		if (this.props.reduxKey) {
			this.props.handleToggleColumnRedux(column)
		} else {
			let newSelectedColumns =
				this.state.selectedColumns.includes(column) ?
					this.state.selectedColumns.filter((x) => x !== column)
				:	[...this.state.selectedColumns, column]

			let newState = {
				selectedColumns: this.state.availableColumns.filter((x) =>
					newSelectedColumns.includes(x)
				),
			}

			this.fetchData(newState)
		}
	}

	render() {
		const {
			classes,
			noDataText,
			columns,
			reduxKey,
			showResetFiltersButton,
			extraButtons,
			exportDataAction,
			exportFilenamePrefix,
			generateQuery,
			additionalQueryParams,
			generateExportFilename,
			showDownload,
			downloadFn,
			hideWhenEmpty,
			fetchDataAction,
			defaultPageSize,
			useClientSidePaging, // eat these so they don't get passed to ...rest
			actionProps,
			tableBodyMaxHeight,
			stickyHeadings,
			smallHeadings,
			...rest
		} = this.props

		let store = reduxKey ? this.props : this.state

		const tableClasses = classNames("-striped", {
			[classes.stickyHeadings]: stickyHeadings,
			[classes.smallHeadings]: smallHeadings,
			"-highlight": typeof this.props.onRowClick === "function",
		})

		return (
			<React.Fragment>
				<DynamicTableActions
					showResetFiltersButton={showResetFiltersButton}
					extraButtons={extraButtons}
					onResetFilters={this.handleResetFilters}
					onToggleColumn={this.handleToggleColumn}
					selectedColumns={store.selectedColumns || []}
					toggleableColumns={store.toggleableColumns || []}
					exportDataAction={exportDataAction}
					generateQueryFn={() => generateQuery(this.props)}
					generateExportFilenameFn={() => generateExportFilename(this.props)}
					showDownload={showDownload}
					downloadFn={downloadFn}
					actionProps={actionProps}
				/>

				<ConditionalContent
					show={
						!store.isLoading &&
						hideWhenEmpty &&
						(!store.data || store.data.length === 0)
					}
				>
					{noDataText}
				</ConditionalContent>

				<ConditionalContent
					hide={hideWhenEmpty && (!store.data || store.data.length === 0)}
				>
					<ReactTable
						manual
						loading={store.isLoading}
						noDataText={
							store.isLoading ? "Loading..."
							: noDataText ?
								noDataText
							:	"No data available."
						}
						columns={columns
							.filter((c) => (store.selectedColumns || []).includes(c.Header))
							.map((x) => ({ headerStyle: styles.left, ...x }))}
						pages={useClientSidePaging ? store.pages : 1}
						defaultPageSize={
							useClientSidePaging ? store.defaultPageSize : (store.data || []).length
						}
						minRows={
							!store.isLoading && (!store.data || store.data.length === 0) ? 2 : 0
						}
						showPaginationTop={useClientSidePaging}
						showPaginationBottom={useClientSidePaging}
						className={tableClasses}
						getTbodyProps={
							tableBodyMaxHeight ?
								() => ({ style: { maxHeight: tableBodyMaxHeight } })
							:	undefined
						}
						getTrProps={
							typeof this.props.onRowClick !== "function" ?
								undefined
							:	(state, rowInfo) => ({
									onClick: () => this.props.onRowClick(rowInfo.original),
									className: classes.clickableRow,
								})
						}
						// fully control the table
						sorted={store.sorted}
						page={useClientSidePaging ? store.page : 1}
						pageSize={useClientSidePaging ? store.pageSize : (store.data || []).length}
						expanded={store.expanded}
						resized={store.resized}
						filtered={store.filtered}
						onSortedChange={this.handleSortedChange}
						onPageChange={this.handlePageChange}
						onPageSizeChange={this.handlePageSizeChange}
						onExpandedChange={this.handleExpandedChange}
						onResizedChange={this.handleResizedChange}
						onFilteredChange={this.handleFilteredChange}
						{...rest}
						data={store.data}
					/>
				</ConditionalContent>
			</React.Fragment>
		)
	}
}

const mapStateToProps = ({ dynamicTables }, ownState) => {
	return {
		...(dynamicTables && ownState.reduxKey ? dynamicTables[ownState.reduxKey] : undefined),
	}
}

const mapDispatchToProps = (dispatch, { reduxKey }) =>
	bindActionCreators(
		{
			callApi,
			setUpDynamicReportRedux: setUpDynamicReport(reduxKey),
			updateFetchFnRedux: updateFetchFn(reduxKey),
			fetchDataRedux: fetchData(reduxKey),
			handleSearchChangeRedux: handleSearchChange(reduxKey),
			handleSortedChangeRedux: handleSortedChange(reduxKey),
			handlePageChangeRedux: handlePageChange(reduxKey),
			handlePageSizeChangeRedux: handlePageSizeChange(reduxKey),
			handleExpandedChangeRedux: handleExpandedChange(reduxKey),
			handleResizedChangeRedux: handleResizedChange(reduxKey),
			handleFilteredChangeRedux: handleFilteredChange(reduxKey),
			setSelectedColumnsRedux: setSelectedColumns(reduxKey),
			handleToggleColumnRedux: handleToggleColumn(reduxKey),
			resetFiltersRedux: resetFilters(reduxKey),
		},
		dispatch
	)

DynamicTable.defaultProps = {
	useClientSideFiltering: false,
	useClientSideDataProcessing: false,
	useClientSidePaging: true,
	hideWhenEmpty: false,
}

DynamicTable.propTypes = {
	classes: PropTypes.object.isRequired,
	/** the RSAA action that gets the data. must accept and pass through query_params for paging/etc to work
	 * do not bind the action with bindActionCreators **/
	fetchDataAction: PropTypes.func,
	/** if the result is not an array this is the path to find the result array **/
	dataProperty: PropTypes.string,
	/** set to true if the fetchDataAction returns all of the data and DynamicTable should handle
	 * paging/sorting locally **/
	useClientSideDataProcessing: PropTypes.bool,
	/** set to true if the fetchDataAction returns all of the data and DynamicTable should handle
	 * filtering locally. **/
	useClientSideFiltering: PropTypes.bool,
	/** use paging for client-side data. true by default, set to false to hide all paging and show all items **/
	useClientSidePaging: PropTypes.bool,
	/** supply unchanging data to the table instead of using the fetchDataAction,
	 * supplying data implies useClientSideDataProcessing = true */
	data: PropTypes.array,
	/** shows up if the table is finished loading and no data is available **/
	noDataText: PropTypes.string,
	/** hides the table entirely if the table is finished loading and no data is available **/
	hideWhenEmpty: PropTypes.bool,
	/** shows the reset filters button. use this to handle resetting filters elsewhere */
	showResetFiltersButton: PropTypes.bool,
	/** more buttons (or any node) to show next to the reset filters button (or where it would be if hidden). */
	extraButtons: PropTypes.node,
	/** definition of all columns to be shown **/
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			/** text that shows up at the top of the table. yes, it's really capitalized...blame ReactTable **/
			Header: PropTypes.string.isRequired,
			/** name of the field in the returned object, dot notation supported--e.g. 'state.description'
			 *  OR a function that receives the item and should return a formatted version
			 *  NOTE: if you want to return a custom node instead of just formatting, use the Cell prop instead **/
			accessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
			/** custom rendering for the cell
			 * function receives these props: https://github.com/tannerlinsley/react-table/tree/v6#renderers
			 * tl;dr, your row data is at the `original` prop
			 * NOTE: if you are only formatting the data into a different value, use the accessor prop instead **/
			Cell: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
			/** required if a func is passed to accessor, names the column when passing it to sorting/filtering/etc. **/
			id: PropTypes.string,
			/** is the column sortable on the server side API? **/
			sortable: PropTypes.bool,
			/** can the user show/hide the column in the UI? **/
			toggleable: PropTypes.bool,
			/** should the column start as hidden in the UI? implies toggleable = true **/
			hiddenByDefault: PropTypes.bool,
			// NOTE: anything else supported by ReactTable's columns can be passed through as well:
			//       https://github.com/tannerlinsley/react-table/tree/v6#columns
		})
	).isRequired,
	/** how many items to show per page by default (user can override via dropdowns) **/
	defaultPageSize: PropTypes.number,
	/** called when anywhere on the row is clicked **/
	onRowClick: PropTypes.func,
	/** array of fields that should be reset when Reset Fields is clicked
	 * each item in the array can be a string, which will pull the default value from initialState here,
	 * or an object of format {key: 'field', initialState: 'blah'}  */
	filterFields: PropTypes.arrayOf(
		PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.shape({
				key: PropTypes.string,
				initialState: PropTypes.any,
			}),
		])
	),
	/** the RSAA action that creates the data export. must accept and pass through query_params for filters/etc to work
	 * do not bind the action with bindActionCreators **/
	exportDataAction: PropTypes.func,

	// the following is only used if the table's state is being stored in redux, a la reports

	/** the key under dynamicTables redux store where report params should be stored
	 * if this is not supplied, all state will be local to the component **/
	reduxKey: PropTypes.string,
	/** if using redux, should this table's state be rehydrated when redux reloads? defaults to false */
	retain: PropTypes.bool,
	/** if using redux, optional override for customizing the URL based on inputs,
	 * default supports typical dynamic report use cases */
	generateQueryFn: PropTypes.func,
	/** if using redux, optional object of params to add to the URL
	 * use this instead of overriding generateQueryFn if you only need to add some things on */
	additionalQueryParams: PropTypes.object,
	/** if using redux, optional override for customizing filters.
	 * some filters will need to be renamed, etc., which can be done by overriding this method */
	generateFiltersFn: PropTypes.func,
	/** if using redux, optional override for customizing ordering.
	 * some orderings will need to be split into two fields, etc., which can be done by overriding this method */
	generateOrderingFn: PropTypes.func,
	/** if using redux, optional override for prefixing export filename.
	 * datetime and active filter values will be appended */
	exportFilenamePrefix: PropTypes.string,

	/** executed after initialization but before any fetching happens
	 * returning an object with {cancel: true} will cancel the initial fetch so that you can dispatch actions and
	 * issue the fetch on your own **/
	onPostInit: PropTypes.func,
	/** executed before every fetch, returning an object with {cancel: true} will cancel the fetch **/
	onPreFetch: PropTypes.func,

	/** extra props containing redux actions specific to a client's endpoints **/
	actionProps: PropTypes.object,

	/** anything else you want to dump in the report's redux store */
	additionalReduxState: PropTypes.object,

	/** Clamp the height of table's tbody. Scrolls after this point. */
	tableBodyMaxHeight: PropTypes.string,

	/* Makes the table headings position: sticky, so they follow as you scroll.  */
	stickyHeadings: PropTypes.bool,

	/* Makes the headings all caps and much smaller. */
	smallHeadings: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DynamicTable))

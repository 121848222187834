import { z } from "zod"

import { CreateContainerChemicalPost } from "@ncs/ncs-api"
import { extractNumber } from "@ncs/ts-utils"
import { NewContainerOptionId } from "@ncs/web-legos"

export const AddChemicalToCustomerFormSchema = z.object({
	name: z.string().min(1, "Required"),
	customerId: z.string().min(1, "Required"),
	partId: z.string().min(1, "Required"),
	lowLevelGallons: z.string().min(1, "Required"),
	pumpSetting: z.string().max(100).nullable(),
	tipType: z.string().max(100).nullable(),
	expectedMlUsage: z.string().min(1, "Required"),
	maxBackStockQuantity: z.string().min(1, "Required"),
	containerId: z.string().nullable(),
	newContainer: z
		.object({
			name: z.string().min(1, "Required").max(100),
			fillableHeight: z.string().min(1, "Required"),
			mlPerMm: z.string().min(1, "Required"),
			gallons: z.number().nullable(),
		})
		.optional(),
	backstockedQuantity: z.number().nullable(),
})

export type AddChemicalToCustomerForm = z.infer<typeof AddChemicalToCustomerFormSchema>

export const addChemicalToCustomerFormDefaultValues: {
	[key in keyof AddChemicalToCustomerForm]: AddChemicalToCustomerForm[key] | undefined
} = {
	name: "",
	customerId: "",
	partId: "",
	lowLevelGallons: undefined,
	pumpSetting: null,
	tipType: null,
	expectedMlUsage: undefined,
	maxBackStockQuantity: "",
	containerId: null,
	newContainer: undefined,
	backstockedQuantity: null,
}

export const newContainerDefaultValues: Partial<AddChemicalToCustomerForm["newContainer"]> = {
	name: "",
	fillableHeight: undefined,
	mlPerMm: undefined,
	gallons: null,
}

export const prepareContainerChemicalPost = (
	form: AddChemicalToCustomerForm
): CreateContainerChemicalPost => {
	const post: CreateContainerChemicalPost = {
		id: form.customerId,
		containerId: form.containerId,
		expectedMlUsage: extractNumber(form.expectedMlUsage),
		isActive: true,
		lowLevelGallons: extractNumber(form.lowLevelGallons),
		maxBackStockQuantity: extractNumber(form.maxBackStockQuantity),
		name: form.name,
		partId: form.partId,
		pumpSetting: form.pumpSetting,
		tipType: form.tipType,

		newContainerName: undefined,
		newContainerFillableHeight: undefined,
		newContainerMlPerMm: undefined,
		newContainerGallons: undefined,
	}

	if (form.containerId === NewContainerOptionId && !!form.newContainer) {
		post.containerId = null
		post.newContainerName = form.newContainer.name
		post.newContainerFillableHeight = extractNumber(form.newContainer.fillableHeight)
		post.newContainerMlPerMm = extractNumber(form.newContainer.mlPerMm)
		post.newContainerGallons = form.newContainer.gallons
	}

	return post
}

import { FC } from "react"

import { CustomerManufacturer, usePartManufacturers } from "@ncs/ncs-api"

import { ExtendableSelectProps, Select } from "../inputs"

export interface PartBrandSelectorProps
	extends ExtendableSelectProps<CustomerManufacturer, CustomerManufacturer> {}

export const PartBrandSelector: FC<PartBrandSelectorProps> = ({ value, onChange, ...rest }) => {
	const [brands, isLoading] = usePartManufacturers()

	return (
		<Select
			disabled={isLoading}
			label="Part brand"
			fillContainer
			{...rest}
			value={value?.id.toString() ?? null}
			onChange={(newValue, newOption) => onChange(newOption ?? null)}
			options={brands ?? []}
			valueAccessor={(option) => option.id.toString()}
			textAccessor="name"
		/>
	)
}

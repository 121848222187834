import { FC, useState } from "react"

import { DatePickerProps } from "@material-ui/pickers"
import { Dayjs } from "dayjs"

import { Button, ButtonProps, IconButton, IconButtonProps } from "../buttons"
import { IconName } from "../typography"
import { DateInput } from "./DateInput"
import { FieldContainerProps } from "./FieldContainer"

export interface DateInputButtonProps extends FieldContainerProps {
	value: Dayjs | null
	onChange: (newValue: Dayjs | null) => void | Promise<void>
	icon?: IconName
	buttonProps?: ButtonProps
	datePickerProps?: Omit<DatePickerProps, "onChange" | "value">
	iconButton?: boolean
	iconButtonProps?: IconButtonProps
}

export const DateInputButton: FC<DateInputButtonProps> = ({
	value,
	onChange,
	icon = "history",
	buttonProps,
	datePickerProps,
	iconButton,
	iconButtonProps,
	...rest
}) => {
	const [open, setOpen] = useState(false)
	const [isSaving, setIsSaving] = useState(false)

	const handleChange = async (newValue: Dayjs | null) => {
		// Assume that passed onChange function is handling try/catch.
		setIsSaving(true)
		await onChange(newValue)
		setIsSaving(false)
	}

	return (
		<DateInput
			value={value}
			onChange={handleChange}
			label=""
			fillContainer={false}
			mb={0}
			datePickerProps={{
				autoOk: false,
				open: open,
				onClose: () => setOpen(false),
				TextFieldComponent: () => {
					if (iconButton) {
						return (
							<IconButton
								{...iconButtonProps}
								onClick={() => {
									setOpen(true)
								}}
								isLoading={isSaving}
							/>
						)
					}
					return (
						<Button
							icon={icon}
							variant="secondary-cta"
							onClick={() => {
								setOpen(true)
							}}
							isLoading={isSaving}
							{...buttonProps}
						/>
					)
				},
				...datePickerProps,
			}}
			{...rest}
		/>
	)
}

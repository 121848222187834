import React from "react"
import PropTypes from "prop-types"
import get from "lodash/get"
import { CollectionSearchSelector, Select, withCallApi } from "@ncs/bricks/components"
import { listBinsWithoutParts } from "../../redux/services/inventory"

class BinSelector extends React.PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			bins: [],
			bin: null,
		}
	}

	componentDidMount() {
		this.loadBins()
	}

	// load bins whenever the location changes
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.location !== this.props.location) {
			this.loadBins()
		}
	}

	loadBins = () => {
		// if location is empty
		if (!this.props.location || !this.props.location._id) {
			this.setState({ bins: [] })
			this.handleBinChanged(null)
			return
		}

		this.props
			.callApi(listBinsWithoutParts(this.props.location._id))
			.then(({ payload: bins }) => {
				this.setState({ bins })

				// if the currently selected bin is not in the results, erase the selected bin
				if (this.props.value && !bins.some((t) => t._id.toString() === this.props.value)) {
					this.handleBinChanged(null)
				}
			})
	}

	handleBinChangedById = (binId) => {
		let bin = this.state.bins.find((x) => x._id.toString() === binId.toString())
		this.handleBinChanged(bin)
	}

	handleBinChanged = (bin) => {
		this.setState({ bin })
		this.props.onBinSelected(bin)
	}

	render() {
		const { displayEmpty, ...rest } = this.props

		return this.state.bins.length <= 100 ?
				<Select
					id="bin"
					labelText="Bin"
					value={get(this.state.bin, "_id", "")}
					options={[
						{
							_id: "",
							description:
								!this.props.location ?
									"No location selected..."
								:	"Select a bin...",
							disabled: true,
						},
						...this.state.bins,
					]}
					valueAccessor="_id"
					textAccessor="code"
					onChange={this.handleBinChangedById}
					labelProps={{
						shrink: displayEmpty ? true : undefined,
						...rest.labelProps,
					}}
					selectProps={{
						displayEmpty: displayEmpty,
						...rest.selectProps,
					}}
					{...rest}
				/>
			:	<CollectionSearchSelector
					labelText="Bin"
					placeholder="Search for a bin"
					collection={this.state.bins}
					selectedSuggestion={this.state.bin}
					valueAccessor={(x) => x.code}
					onSelected={this.handleBinChanged}
				/>
	}
}

BinSelector.defaultProps = {
	displayEmpty: true,
}

BinSelector.propTypes = {
	location: PropTypes.object,
	onBinSelected: PropTypes.func.isRequired,
	displayEmpty: PropTypes.bool,
}

export default withCallApi(BinSelector)

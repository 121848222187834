import { FC, useState } from "react"

import { css, Theme } from "@emotion/react"

import { APPLICATION, useUserCanUse } from "@ncs/ncs-api"
import { formatCurrency, formatPercentage } from "@ncs/ts-utils"
import {
	Box,
	Button,
	Divider,
	EmptyValueDash,
	GridContainer,
	GridItem,
	Heading,
	HeadingProps,
	Paragraph,
} from "@ncs/web-legos"

import {
	ContractIncentiveProgram as IContractIncentiveProgram,
	stringifyContractUnits,
} from "./contract-utils"
import { CreditUsageModal } from "./ContractDetail/CreditUsageModal"

export interface ContractIncentiveProgramProps {
	incentive: IContractIncentiveProgram
	onEdit: () => Promise<void> | void
	onRemove: () => Promise<void> | void
	remainingCredits?: number
}

/**
 * Displays a contract incentive program. Needs to be able to display both an in-progress
 * incentive before the contract's been created, and the edit of a finished one from the
 * contract detail page.
 */
export const ContractIncentiveProgram: FC<ContractIncentiveProgramProps> = ({
	incentive,
	onEdit,
	onRemove,
	remainingCredits,
}) => {
	const canEdit = useUserCanUse(APPLICATION.ContractCreator)
	const [showCreditModal, setShowCreditModal] = useState(false)

	const incentiveHeadingProps: HeadingProps = {
		variant: "h6",
		mb: 0.35,
	}

	return (
		<div css={containerCss}>
			<header>
				<Box display="flex" alignItems="baseline" columnGap={1}>
					<Heading variant="h6" bold>
						Business Units &amp; Categories
					</Heading>
					<Paragraph bold>{stringifyContractUnits(incentive.categories)}</Paragraph>
				</Box>
				{canEdit && (
					<Box display="flex" columnGap={2}>
						<Button icon="pencil" onClick={onEdit}>
							Edit
						</Button>
						<Button icon="trash-alt" onClick={onRemove}>
							Remove
						</Button>
					</Box>
				)}
			</header>

			<GridContainer
				px={1.5}
				py={1}
				columnGap={3}
				smProps={{ columnGap: 1 }}
				xsProps={{ columnGap: 0 }}
			>
				<GridItem sm={12} md={6} lg={4}>
					<Heading {...incentiveHeadingProps}>Discount %</Heading>
					{incentive.discount?.percent != null ?
						<Paragraph>{formatPercentage(incentive.discount.percent)}</Paragraph>
					:	<EmptyValueDash />}
				</GridItem>
				<GridItem sm={12} md={6} lg={4}>
					<Heading {...incentiveHeadingProps}>Annual Rebates</Heading>
					{!incentive.rebate && <EmptyValueDash />}
					{incentive.rebate?.schedule.map((schedule) => (
						<div key={`${schedule.start}-${schedule.end}`} css={rebateLineCss}>
							<div>
								{schedule.end ?
									<Paragraph>
										{formatCurrency(schedule.start, 0)} to{" "}
										{formatCurrency(schedule.end, 0)}
									</Paragraph>
								:	<Paragraph>&gt; {formatCurrency(schedule.start, 0)}</Paragraph>}
							</div>
							<div>
								{schedule.percent ?
									<Paragraph>{formatPercentage(schedule.percent)}</Paragraph>
								:	<EmptyValueDash />}
							</div>
						</div>
					))}
				</GridItem>
				<GridItem sm={12} md={6} lg={4}>
					<Heading {...incentiveHeadingProps}>Credits</Heading>
					{incentive.credit?.amount ?
						<Paragraph>
							{formatCurrency(incentive.credit.amount, 0)}{" "}
							{incentive.credit.isPerSite ? "per site" : "across all sites"}
						</Paragraph>
					:	<EmptyValueDash />}
					{!!incentive.credit?.id && (
						<>
							<Divider my={0.5} />
							<Button
								icon="window-maximize"
								onClick={() => setShowCreditModal(true)}
							>
								Credit usage
							</Button>
							{/* At the moment we're only showing credit remaining if it's NOT per site. */}
							{remainingCredits != null && !incentive.credit.isPerSite && (
								<span
									css={remainingCreditCss}
									className={remainingCredits > 0 ? "above-zero" : undefined}
								>
									{formatCurrency(remainingCredits, 0)} remaining
								</span>
							)}
						</>
					)}
				</GridItem>
			</GridContainer>

			{!!incentive.credit?.id && (
				<CreditUsageModal
					isOpen={showCreditModal}
					onClose={() => setShowCreditModal(false)}
					creditId={incentive.credit.id}
					initialAmount={incentive.credit.amount}
					isPerSite={incentive.credit.isPerSite}
					remainingCreditTotal={remainingCredits}
					units={incentive.categories}
				/>
			)}
		</div>
	)
}

const containerCss = (theme: Theme) => css`
	border: 1px solid #eee;
	border-radius: 10px;
	header {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		border-radius: 10px 10px 0 0;
		background: #eee;
		padding: 1rem 1.5rem;
		${theme.breakpoints.down("sm")} {
			flex-direction: column;
			align-items: flex-start;
			row-gap: 1rem;
		}
	}
`
const rebateLineCss = css`
	display: flex;
	justify-content: space-between;
	padding-bottom: 0.35rem;
	margin-bottom: 0.35rem;
	border-bottom: 1px solid #eee;
	&:last-of-type {
		border: 0;
	}
`
const remainingCreditCss = (theme: Theme) => css`
	color: ${theme.palette.text.secondary};
	font-weight: bold;
	font-size: 0.8rem;
	background: none;
	padding: 0;
	border: 0;
	margin-left: 0.5rem;
	&.above-zero {
		color: ${theme.palette.success.dark};
	}
`

import { Dispatch, ReactElement, SetStateAction } from "react"

import { OrderLineStatusSelector } from "../selectors"

export interface OrderLineStatusParamState {
	orderLineStatus: string | null
}

export interface OrderLineStatusQueryFilterProps<
	QueryParamState extends OrderLineStatusParamState,
> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

export const OrderLineStatusQueryFilter = <QueryParamState extends OrderLineStatusParamState>({
	queryParamState,
	setQueryParamState,
}: OrderLineStatusQueryFilterProps<QueryParamState>): ReactElement => {
	return (
		<OrderLineStatusSelector
			value={queryParamState.orderLineStatus}
			disableNoSelectionOption={false}
			fillContainer
			onChange={(newValue) =>
				setQueryParamState((prev) => {
					const newOrderLineStatusParamState: OrderLineStatusParamState = {
						orderLineStatus: newValue,
					}

					return {
						...prev,
						...newOrderLineStatusParamState,
					}
				})
			}
		/>
	)
}

import React, { useMemo } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import moment, { MomentInput } from "moment"
import Datetime, { DatetimepickerProps } from "react-datetime"
import {
	FormControl,
	FormControlProps,
	InputLabel,
	InputLabelProps,
	FormHelperText,
	makeStyles,
} from "@material-ui/core"
import { Tooltip, makeTooltipProps } from "../../components"
import { AmbiguousTooltipProp } from "../CustomTooltip/makeTooltipProps"

import styles from "../../assets/jss/material-dashboard-pro-react/components/customInputStyle"

const useStyles = makeStyles(styles)

export interface CustomDatetimeProps {
	labelText?: React.ReactNode
	labelProps?: InputLabelProps
	id?: string
	value?: MomentInput
	fullWidth?: boolean
	formControlProps?: FormControlProps
	error?: boolean
	success?: boolean
	helperText?: React.ReactNode
	inputProps?: DatetimepickerProps
	onChange?: DatetimepickerProps["onChange"]
	dateFormat?: DatetimepickerProps["dateFormat"]
	timeFormat?: DatetimepickerProps["timeFormat"]
	tooltip?: AmbiguousTooltipProp
}

const CustomDatetime: React.FC<CustomDatetimeProps> = ({
	formControlProps,
	labelText,
	id,
	value,
	onChange = () => {},
	fullWidth = true,
	labelProps,
	inputProps,
	error,
	success,
	helperText,
	dateFormat = "MMM-DD-YYYY",
	timeFormat = false,
	tooltip,
	...rest
}) => {
	const classes = useStyles()

	const labelClasses = useMemo(
		() =>
			classNames({
				[" " + classes.labelRootError]: error,
				[" " + classes.labelRootSuccess]: success && !error,
			}),
		[classes, error, success]
	)

	const formControlClasses = useMemo(
		() => classNames(formControlProps?.className, classes.formControl),
		[classes, formControlProps]
	)

	const helpTextClasses = useMemo(
		() =>
			classNames({
				[classes.labelRootError]: error,
				[classes.labelRootSuccess]: success && !error,
			}),
		[classes, error, success]
	)

	// Because we need to render Datetime both with or without the Tooltip, we'll
	// assemble the props up here so we don't need to write them out twice below.
	const datetimeComponentProps: DatetimepickerProps = {
		value: moment(value),
		onChange,
		dateFormat,
		timeFormat,
		...inputProps,
		...rest,
	}

	return (
		<FormControl fullWidth={fullWidth} {...formControlProps} className={formControlClasses}>
			{labelText !== undefined ?
				<InputLabel
					shrink={true}
					className={classes.labelRoot + " " + labelClasses}
					htmlFor={id}
					{...labelProps}
				>
					{labelText}
				</InputLabel>
			:	null}

			{
				tooltip ?
					<Tooltip disableFocusListener {...makeTooltipProps(tooltip)}>
						<Datetime {...datetimeComponentProps} />
					</Tooltip>
					// Wrap datetime in a div so it has same structure as if rendered with tooltip.
				:	<div>
						<Datetime {...datetimeComponentProps} />
					</div>

			}

			{helperText && (
				<FormHelperText id={id + "-text"} className={helpTextClasses}>
					{helperText}
				</FormHelperText>
			)}
		</FormControl>
	)
}

CustomDatetime.propTypes = {
	labelText: PropTypes.node,
	labelProps: PropTypes.object,
	id: PropTypes.string,
	value: PropTypes.any,
	onChange: PropTypes.func,
	inputProps: PropTypes.object,
	fullWidth: PropTypes.bool,
	formControlProps: PropTypes.object,
	error: PropTypes.bool,
	success: PropTypes.bool,
	helperText: PropTypes.node,
	dateFormat: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	timeFormat: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	tooltip: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.shape({
			title: PropTypes.node.isRequired,
		}),
	]),
}

export default React.memo(CustomDatetime)

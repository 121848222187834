import React from "react"
import PropTypes from "prop-types"
import get from "lodash/get"
import UserSelector from "../../components/Selectors/UserSelector"
import { withDynamicTableFilter } from "@ncs/bricks/components"

class UserFilter extends React.PureComponent {
	handleUserSelected = (user) => {
		let text = get(user, "name", "")
		this.props.onChange(user, text)
		typeof this.props.onUserSelected === "function" && this.props.onUserSelected(user)
	}

	render = () => (
		<UserSelector
			selectedSuggestion={this.props.value || null}
			onUserSelected={this.handleUserSelected}
			{...this.props}
		/>
	)
}

UserFilter.propTypes = {
	onUserSelected: PropTypes.func,
}

export const AliasedUserFilter = (filterName, labelText) =>
	withDynamicTableFilter(filterName, undefined, undefined, undefined, undefined, { labelText })(
		UserFilter
	)

export default AliasedUserFilter("user", "User")

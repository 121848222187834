import { useRef, useState } from "react"

import queryString from "query-string"

import { arrayWrap, CamelToSnakeCaseNested, unpythonify } from "@ncs/ts-utils"

import { apiClient, preparePortalParams } from "../"

export interface DataExportSummary {
	id: number
	createdDate: string
	customFilters: Record<string, string> | null
	emailOnCompletion: boolean
	errorUploading: boolean
	isReady: boolean
	name: string
	reportId: string | null // Maybe `number | null`?
	reportName: string
	requestedBy: {
		id: string
		name: string
	} | null
}

export interface DataExportLink {
	presignUrl: string
}

export type DataExport = {
	requestReport: () => void
	reportUrl: string | undefined
	requestEmailOnCompletion: () => Promise<void>
	errorOccurred: boolean
}

export const useDataExport = <Params = unknown>(
	/** Don't add `export` to the end, we'll do that automatically. */
	endpoint: string | string[],
	params?: Params
): DataExport => {
	const [reportUrl, setReportUrl] = useState<string | undefined>()
	const [errorOccurred, setErrorOccurred] = useState(false)
	const completionTimer = useRef<NodeJS.Timeout | null>(null)

	// For the most part we want to just pass around the report ID functionally,
	// but it'll be here if you need it on demand (ie, for the patch request).
	const storedReportId = useRef<number | null>(null)

	const reset = (): void => {
		completionTimer.current = null
		storedReportId.current = null
		setReportUrl(undefined)
		setErrorOccurred(false)
	}

	const generateLink = async (reportId: number): Promise<string> => {
		const response = await apiClient.post<CamelToSnakeCaseNested<DataExportLink>>(
			`reports/exports/${reportId}/generate_link/`
		)
		const responseData = unpythonify(response.data)

		return responseData.presignUrl
	}

	const keepCheckingForCompletion = async (iteration = 0, reportId: number): Promise<void> => {
		const response = await apiClient.get<CamelToSnakeCaseNested<DataExportSummary>>(
			`reports/exports/${reportId}/`
		)
		const responseData = unpythonify(response.data)

		if (responseData.errorUploading) {
			setErrorOccurred(true)
		}

		if (responseData.isReady) {
			completionTimer.current = null
			const link = await generateLink(reportId)
			setReportUrl(link)
		} else {
			const intervals = [0, 1, 2, 3, 5, 8, 13, 20]
			const timeout = intervals[Math.min(intervals.length - 1, iteration)] * 1000

			if (!responseData.errorUploading) {
				completionTimer.current = setTimeout(
					() => keepCheckingForCompletion(iteration + 1, reportId),
					timeout
				)
			}
		}
	}

	const requestEmailOnCompletion = async (): Promise<void> => {
		if (storedReportId.current) {
			await apiClient.patch<CamelToSnakeCaseNested<DataExportSummary>>(
				`reports/exports/${storedReportId.current}/`,
				{
					email_on_completion: true,
				}
			)
		}
	}

	const stringifiedParams =
		params ?
			`?${queryString.stringify(preparePortalParams(params), {
				skipEmptyString: true,
				skipNull: true,
				arrayFormat: "comma",
			})}`
		:	""

	const createReport = async (): Promise<void> => {
		const response = await apiClient.post<CamelToSnakeCaseNested<DataExportSummary>>(
			`${arrayWrap(endpoint).join("/")}/export/${stringifiedParams}`
		)

		void keepCheckingForCompletion(0, response.data.id)
		storedReportId.current = response.data.id
	}

	const requestReport = (): void => {
		reset()
		void createReport()
	}

	return { requestReport, reportUrl, requestEmailOnCompletion, errorOccurred }
}

import { Dispatch, ReactElement, SetStateAction } from "react"

import { Customer, useCustomer } from "@ncs/ncs-api"

import { CustomerSelector, CustomerSelectorProps } from "../selectors"

export type CustomerQueryParams = {
	customer?: string | null
	customerId?: string | null
	billToCustomer?: string | null
}

export interface CustomerQueryFilterProps<QueryParamState extends CustomerQueryParams>
	extends Omit<CustomerSelectorProps, "value" | "onChange"> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
	paramKey?: keyof CustomerQueryParams
	label?: string
}

export const CustomerQueryFilter = <QueryParamState extends CustomerQueryParams>({
	queryParamState,
	setQueryParamState,
	paramKey = "customer",
	label,
	...rest
}: CustomerQueryFilterProps<QueryParamState>): ReactElement => {
	const [customer] = useCustomer(queryParamState[paramKey] ?? null)

	const handleChange = (newCustomer: Customer | null) => {
		// Set query param state with just the customer ID.
		setQueryParamState((prev) => ({
			...prev,
			[paramKey]: newCustomer?.id ?? null,
		}))
	}

	return (
		<CustomerSelector
			fillContainer
			label={label}
			{...rest}
			initialCustomerId={queryParamState[paramKey]}
			value={customer ?? null}
			onChange={handleChange}
		/>
	)
}

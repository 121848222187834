import { ReactElement, ReactNode, useCallback, useMemo, useState } from "react"

import {
	AnimatedEntrance,
	Box,
	Button,
	ConfirmationModal,
	ConfirmationModalConfig,
	ErrorText,
	Heading,
	Paragraph,
	useIsSaving,
} from "@ncs/web-legos"

export interface AdjustmentRequestRowProps<Data> {
	/**
	 * Name to show in the UI for this adjustment row.
	 */
	name: string
	/**
	 * Based on the current saved state of the form, is this adjustment row being used or not?
	 */
	isBeingUsedByForm: boolean
	/**
	 * The data that the form says is what the customer(s) are currently set to.
	 */
	currentData: Data
	/**
	 * What the form is going to request that the customers are changed to.
	 */
	proposedData: Data
	/**
	 * How we display the data in the Current and Proposed columns.
	 */
	render: (data: Data, column: "current" | "proposed") => ReactNode
	/**
	 * What we show when user is editing this row's values.
	 */
	editingRender: () => ReactNode
	/**
	 * Synchronous function to call to see if the current state of the edit is valid.
	 * Return a string to show an error message and not save, or null if it's valid.
	 */
	getEditErrors?: () => string | null
	/**
	 * Save the adjustment
	 */
	onSave: () => Promise<void> | void
	/**
	 * Function to fire to tell the parent to calculate the edit state based on the
	 * current state of the form.
	 */
	setEditState: () => void
	/**
	 * Remove the adjustment from the form.
	 */
	onRemove: () => Promise<void> | void
	/**
	 * Can the user do anything with this adjustment row?
	 */
	locked?: boolean
}

export const AdjustmentRequestRow = <Data,>({
	name,
	isBeingUsedByForm,
	currentData,
	proposedData,
	render,
	editingRender,
	getEditErrors,
	onSave,
	setEditState,
	onRemove,
	locked = false,
}: AdjustmentRequestRowProps<Data>): ReactElement => {
	const [isEditing, setIsEditing] = useState(false)
	const { isSaving, setSaving, endSaving } = useIsSaving<"saving" | "removing">()
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)
	const [errorText, setErrorText] = useState<string | null>(null)

	const isOpen = useMemo(() => {
		return isEditing || isBeingUsedByForm
	}, [isEditing, isBeingUsedByForm])

	const handleSave = async () => {
		const errors = getEditErrors ? getEditErrors() : null
		if (errors) {
			setErrorText(errors)
			return
		}
		setErrorText(null)
		setSaving("saving")
		await onSave() // Await the passed in save function.
		endSaving("saving")
		setIsEditing(false)
	}

	const handleRemove = useCallback(() => {
		setConfirmationConfig({
			title: "Remove Adjustment",
			message: (
				<>
					Confirm: Remove adjustment for <strong>{name}</strong> from the form?
				</>
			),
			onConfirm: async () => {
				await onRemove()
				setIsEditing(false)
				setErrorText(null)
			},
		})
	}, [name, onRemove])

	const handleStartEditing = useCallback(() => {
		setEditState()
		setIsEditing(true)
	}, [setEditState])

	const handleCancelEdit = useCallback(() => {
		setIsEditing(false)
		setEditState()
		setErrorText(null)
	}, [setEditState])

	const rightButtons = useMemo((): ReactNode | null => {
		if (locked) return null

		if (isOpen === false && isEditing === false) {
			return (
				<Button icon="plus" onClick={() => setIsEditing(true)}>
					Add adjustment
				</Button>
			)
		}
		if (isOpen === true && isEditing === false) {
			return (
				<Button icon="pencil" onClick={handleStartEditing}>
					Edit
				</Button>
			)
		}
		if (isOpen === true && isEditing === true) {
			return (
				<Box d="flex" gap={1}>
					{isBeingUsedByForm === true && (
						<Button icon="trash-alt" onClick={handleRemove}>
							Remove adjustment
						</Button>
					)}
					<Button icon="times" onClick={handleCancelEdit}>
						Cancel edit
					</Button>
				</Box>
			)
		}

		return null
	}, [
		isOpen,
		isEditing,
		handleCancelEdit,
		handleRemove,
		isBeingUsedByForm,
		handleStartEditing,
		locked,
	])

	return (
		<Box bb={1} pb={2} mb={2}>
			<Box d="flex" justifyContent="space-between" mb={isOpen ? 1 : 0}>
				<Paragraph secondary={isOpen === false} bold={isOpen}>
					{name}
				</Paragraph>
				{rightButtons}
			</Box>
			<AnimatedEntrance show={isOpen}>
				<Box d="flex" smProps={{ flexDirection: "column-reverse" }} gap={2}>
					<Box flex={1}>
						<Heading variant="h6" bold mb={0.5}>
							Current
						</Heading>
						{render(currentData, "current")}
					</Box>
					<Box flex={1}>
						<Heading variant="h6" bold mb={0.5}>
							Proposed Change
						</Heading>
						<AnimatedEntrance show={isEditing}>
							{editingRender()}
							<Box d="flex" gap={1} mt={1}>
								<Button
									variant="primary-cta"
									onClick={handleSave}
									isLoading={isSaving("saving")}
								>
									Save adjustment
								</Button>
							</Box>
							{!!errorText && <ErrorText pt={0.5}>{errorText}</ErrorText>}
						</AnimatedEntrance>
						{isEditing === false && render(proposedData, "proposed")}
					</Box>
				</Box>
			</AnimatedEntrance>

			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</Box>
	)
}

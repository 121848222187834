import React from "react"

import NumberFormField from "./NumberFormField"

class CurrencyFormField extends React.Component {
	render = () => <NumberFormField {...this.props} />
}

CurrencyFormField.defaultProps = {
	thousandSeparator: true,
	decimalScale: 2,
	fixedDecimalScale: true,
	prefix: "$",
	displayType: "input",
}

CurrencyFormField.propTypes = {
	...NumberFormField.propTypes,
}

export default CurrencyFormField

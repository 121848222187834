import { Dispatch, ReactElement, SetStateAction } from "react"

import { BinTypeSelector } from "../selectors"

const key = "binType"

interface BinTypeQueryParam {
	[key]: string | null
}

export interface BinTypeQueryFilterProps<QueryParamState extends BinTypeQueryParam> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

export const BinTypeQueryFilter = <QueryParamState extends BinTypeQueryParam>({
	queryParamState,
	setQueryParamState,
}: BinTypeQueryFilterProps<QueryParamState>): ReactElement => {
	const handleChange = (newValue: string | null) => {
		setQueryParamState((prev) => ({
			...prev,
			[key]: newValue,
		}))
	}

	return (
		<BinTypeSelector
			value={queryParamState[key]}
			onChange={handleChange}
			fillContainer
			disableNoSelectionOption={false}
			noSelectionOptionText="Any type"
		/>
	)
}

import { FC, useEffect, useRef, useState } from "react"

import { PricingMatrix, usePricingMatrices } from "@ncs/ncs-api"

import { ExtendableSearchableSelectProps, SearchableSelect } from "../inputs/SearchableSelect"

export interface PricingMatrixSelectorProps
	extends ExtendableSearchableSelectProps<PricingMatrix> {
	initialId?: string | null
}

export const PricingMatrixSelector: FC<PricingMatrixSelectorProps> = ({
	initialId,
	onChange,
	...rest
}) => {
	const [searchInput, setSearchInput] = useState<string | null>(null)

	const { data, isLoading } = usePricingMatrices({
		params: {
			search: searchInput,
		},
		pageSize: 999, // Just grab 'em all.
	})

	const hasSetInitial = useRef(false)

	useEffect(() => {
		if (hasSetInitial.current === false && !!initialId && !!data && data.length > 0) {
			const initialSelect = data.find((matrix) => matrix.id === initialId)

			if (initialSelect) {
				onChange(initialSelect)
				hasSetInitial.current = true
			}
		}
	}, [data, initialId, onChange])

	return (
		<SearchableSelect
			label="Pricing matrix"
			{...rest}
			options={data ?? []}
			isLoading={isLoading}
			onInputChange={setSearchInput}
			onItemSelect={onChange}
			getOptionLabel={(option) => option.description}
			sortingParam="description"
		/>
	)
}

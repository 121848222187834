import React from "react"
import PropTypes from "prop-types"
import MarketingMaterialsUpload from "./MarketingMaterialsUpload"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { bindActionCreators } from "redux"
import { loadLookups, LOOKUPS } from "@ncs/mortar/redux/services/lookups"
import { doFileUpload } from "@ncs/mortar/redux/services/marketing"
import {
	handleCategoryChange,
	handleDocumentTypeChange,
	resetFilters,
} from "~/redux/modules/marketingMaterialsModule"
import callApi from "@ncs/bricks/redux/services/callApi"
import moment from "moment"

class MarketingMaterialsUploadContainer extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			file: null,
			documentTitle: "",
			isSaving: false,
			showSuccessfulUpload: false,
			showFailedUpload: false,
			effectiveDate: moment().format(),
			expirationDate: null,
			isOrderable: false,
			cost: 0.0,
		}
	}

	componentDidMount = () => {
		this.props.loadLookups([LOOKUPS.MarketingMaterialCategories])
	}

	handleTitleChanged = (title) => {
		this.setState({ documentTitle: title })
	}

	handleFileSelected = (f) => {
		this.setState({ file: f[0] })
	}

	handleCostUpdated = (c) => {
		this.setState({ cost: c })
	}
	handleExpirationDateUpdated = (d) => {
		//let converted = (d instanceof moment) ? d.format("YYYY-MM-DD") : d
		this.setState({ expirationDate: d.format() })
	}
	handleEffectiveDateUpdated = (d) => {
		//let converted = (d instanceof moment) ? d.format("YYYY-MM-DD") : d
		this.setState({ effectiveDate: d.format() })
	}
	handleIsOrderableUpdated = (checked) => {
		this.setState({ isOrderable: checked })
	}

	handleSubmit = () => {
		this.setState({ isSaving: true })
		this.props
			.callApi(
				doFileUpload({
					file: this.state.file,
					category_id: this.props.selectedCategory,
					title: this.state.documentTitle,
					effectiveDate: this.state.effectiveDate,
					expirationDate: this.state.expirationDate,
					cost: this.state.cost,
					isOrderable: this.state.isOrderable,
				})
			)
			.then((result) => {
				this.setState({
					isSaving: false,
					showSuccessfulUpload: !result.error,
					showFailedUpload: !!result.error,
				})
			})
	}

	handleCloseSuccessModal = () => {
		this.setState({ showSuccessfulUpload: false })
	}

	handleCloseFailedUploadModal = () => {
		this.setState({ showFailedUpload: false })
	}

	handleBackToList = () => {
		this.props.history.push("/marketing/materials/")
	}

	render = () => (
		<MarketingMaterialsUpload
			isLoading={this.props.isLoadingMaterialCategories}
			isSaving={this.state.isSaving}
			categories={this.props.materialCategories}
			title={this.state.documentTitle}
			cost={this.state.cost}
			isOrderable={this.state.isOrderable}
			expirationDate={this.state.expirationDate}
			effectiveDate={this.state.effectiveDate}
			onCostUpdated={this.handleCostUpdated}
			onIsOrderableUpdated={this.handleIsOrderableUpdated}
			onEffectiveDateSelected={this.handleEffectiveDateUpdated}
			onExpirationDateSelected={this.handleExpirationDateUpdated}
			selectedCategory={this.props.selectedCategory}
			onCategoryChange={(s) => this.props.handleCategoryChange(Number(s))}
			onFileSelected={this.handleFileSelected}
			onSubmit={this.handleSubmit}
			onTitleChanged={this.handleTitleChanged}
			showSuccessfulUpload={this.state.showSuccessfulUpload}
			onCloseSuccessModal={this.handleCloseSuccessModal}
			onBackToList={this.handleBackToList}
			showFailedUpload={this.state.showFailedUpload}
			onCloseFailedUploadModal={this.handleCloseFailedUploadModal}
		/>
	)
}

MarketingMaterialsUploadContainer.propTypes = {
	callApi: PropTypes.func.isRequired,
	handleCategoryChange: PropTypes.func.isRequired,
	handleDocumentTypeChange: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
	return {
		...state.marketing,
		...state.lookups,
		...state.marketingMaterialsModule,
	}
}

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			loadLookups,
			callApi,
			handleCategoryChange,
			handleDocumentTypeChange,
			resetFilters,
		},
		dispatch
	),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(MarketingMaterialsUploadContainer))

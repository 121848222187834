import { Dispatch, ReactElement, SetStateAction, useMemo } from "react"

import {
	FreightRateType,
	FreightRateTypeMultiSelect,
	freightRateTypeMultiSelectOptions,
} from "./FreightRateTypeMultiSelect"

interface FreightRateTypeQueryParam {
	defaults: boolean | null
	customers: boolean | null
	products: boolean | null
}

export interface FreightRateTypeQueryFilterProps<
	QueryParamState extends FreightRateTypeQueryParam,
> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

export const FreightRateTypeQueryFilter = <QueryParamState extends FreightRateTypeQueryParam>({
	queryParamState,
	setQueryParamState,
}: FreightRateTypeQueryFilterProps<QueryParamState>): ReactElement => {
	const values = useMemo(() => {
		return [...freightRateTypeMultiSelectOptions]
			.filter((option) => !!queryParamState[option.value])
			.map((option) => option.value)
	}, [queryParamState])

	const handleChange = (selections: FreightRateType[]) => {
		if (selections.length === 0) {
			setQueryParamState((prev) => ({
				...prev,
				defaults: null,
				customers: null,
				products: null,
			}))
		} else {
			setQueryParamState((prev) => ({
				...prev,
				customers: selections.includes("customers"),
				products: selections.includes("products"),
				defaults: false,
			}))
		}
	}

	return <FreightRateTypeMultiSelect values={values} onChange={handleChange} />
}

import React from "react"
import PropTypes from "prop-types"
import { MomentInput } from "moment"
import { createStyles, makeStyles } from "@material-ui/core"
import { formatDateTime } from "../../util/formatters"
import { Tooltip, makeTooltipProps } from "../../components"
import { AmbiguousTooltipProp } from "../CustomTooltip/makeTooltipProps"

const styles = createStyles({
	noWrap: {
		whiteSpace: "nowrap",
	},
})
const useStyles = makeStyles(styles)

export interface CustomDatetimeDisplay {
	utcDate?: MomentInput
	format?: string
	invalidDateText?: string
	noWrap?: boolean
	tooltip?: AmbiguousTooltipProp
}

const DatetimeDisplay: React.FC<CustomDatetimeDisplay> = ({
	utcDate,
	format,
	invalidDateText,
	noWrap = false,
	tooltip,
}) => {
	const classes = useStyles()
	const spanClass = noWrap ? classes.noWrap : undefined

	if (tooltip) {
		return (
			<Tooltip
				containerProps={{
					style: {
						// DateTimeDisplay normally renders a span, so we should make an
						// inline level component to match.
						display: "inline",
					},
				}}
				{...makeTooltipProps(tooltip)}
			>
				<span className={spanClass}>
					{formatDateTime(utcDate, format, invalidDateText)}
				</span>
			</Tooltip>
		)
	}

	return <span className={spanClass}>{formatDateTime(utcDate, format, invalidDateText)}</span>
}

DatetimeDisplay.propTypes = {
	utcDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
	format: PropTypes.string,
	invalidDateText: PropTypes.string,
	noWrap: PropTypes.bool,
	tooltip: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.shape({
			title: PropTypes.node.isRequired,
		}),
	]),
}

export default React.memo(DatetimeDisplay)

import React from "react"
import { BaseSelectFilter, withDynamicTableFilter } from "@ncs/bricks/components"

class SiteSurveySubmittedFilter extends React.PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			expired: [
				{ value: "true", text: "True" },
				{ value: "false", text: "False" },
			],
		}
	}

	render() {
		return (
			<BaseSelectFilter
				id="isSubmitted"
				labelText="Is Submitted"
				options={[{ value: "", text: "All Surveys" }, ...this.state.expired]}
				valueAccessor={(x) => (x.value || "").toString()}
				textAccessor="text"
				{...this.props}
			/>
		)
	}
}

export default withDynamicTableFilter("is_submitted")(SiteSurveySubmittedFilter)

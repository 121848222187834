import { FC } from "react"

import { Card, Icon, Tabs, useUrlState } from "@ncs/web-legos"

import { PartOrdersCreateOrderTab, PartOrdersViewOrdersTab } from "./components"

export enum PartOrdersTab {
	View = "View Part Orders",
	Create = "New Part Order",
}

export const PartOrders: FC = () => {
	const [{ tab }, { updateUrlValue }] = useUrlState({
		tab: PartOrdersTab.View,
	})

	return (
		<Card heading="Part Orders" headingIcon="truck">
			<Tabs
				currentTab={tab}
				onChange={(newTab) => updateUrlValue("tab", newTab)}
				panels={[
					{
						navLabel: PartOrdersTab.View,
						component: <PartOrdersViewOrdersTab />,
						navIcon: <Icon icon="list" />,
					},
					{
						navLabel: PartOrdersTab.Create,
						component: <PartOrdersCreateOrderTab />,
						navIcon: <Icon icon="plus" />,
					},
				]}
			/>
		</Card>
	)
}

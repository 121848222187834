import { FC, useCallback, useMemo, useState } from "react"

import { Cell, Column } from "react-table"

import {
	InvoiceSource,
	InvoiceSourceQueryParams,
	makeApiErrorMessage,
	useInvoiceSources,
	usePrintInvoices,
} from "@ncs/ncs-api"
import {
	DateFormat,
	displayDateTime,
	formatCurrency,
	formatNumber,
	getTimeAgoStartDate,
	getTimezoneAbbreviation,
	TimeAgo,
	Typify,
} from "@ncs/ts-utils"
import {
	Box,
	Button,
	Callout,
	CustomerQueryFilter as DefaultCustomerQueryFilter,
	CustomerQueryFilterProps,
	EmptyValueDash,
	Icon,
	IconButton,
	Paragraph,
	SearchQueryFilter,
	Table,
	TimeAgoQueryFilter,
	useIsSaving,
	usePaginationUrlState,
	useToast,
	useUrlState,
} from "@ncs/web-legos"

import { ZeroDollarQueryFilter } from "~/components"

export type PrintInvoicesTabUrlState = Typify<InvoiceSourceQueryParams>

export const PrintInvoicesTab: FC = () => {
	const { makeSuccessToast, makeErrorToast } = useToast()
	const [selections, setSelections] = useState<Record<string, InvoiceSource>>({})
	const [pagination, setPagination] = usePaginationUrlState()
	const [params, { setUrlState: setParams }] = useUrlState(defaultUrlState)
	const { isSaving, setSaving, endSaving } = useIsSaving()

	const invoicesQuery = useInvoiceSources({
		params: {
			...params,
			...pagination,
		},
		manualPagination: true,
	})

	const requestPrint = usePrintInvoices()

	const handlePrint = async () => {
		if (!Object.values(selections).length) {
			makeErrorToast("No invoices selected")
			return
		}

		try {
			setSaving()
			await requestPrint(
				Object.values(selections).map(({ id, invoiceNumber }) => ({
					_id: id,
					invoiceNumber: invoiceNumber.toString(),
				}))
			)
			makeSuccessToast("Print request sent. Check your email in a few minutes.")
			setSelections({})
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		} finally {
			endSaving()
		}
	}

	const addInvoices = useCallback((invoices: InvoiceSource[]) => {
		setSelections((prev) => {
			return {
				...prev,
				...Object.fromEntries(invoices.map((invoice) => [invoice.id, invoice])),
			}
		})
	}, [])

	const removeInvoice = (id: string) => {
		setSelections((prev) => {
			const update = { ...prev }
			delete update[id]
			return update
		})
	}

	const columns = useMemo((): Column<InvoiceSource>[] => {
		return [
			{
				Header: "Invoice #",
				id: "invoiceNumber",
				accessor: "invoiceNumber",
			},
			{
				Header: "Type",
				id: "invoiceType",
				accessor: "invoiceType",
			},
			{
				Header: `Work completed (${tz})`,
				id: "workCompleted",
				hiddenByDefault: true,
				accessor: ({ workCompletedDate }) =>
					displayDateTime(workCompletedDate, "") || <EmptyValueDash />,
			},
			{
				Header: "Customer",
				id: "customer_CustomerNumber",
				accessor: ({ customer }) => `(${customer.customerNumber}) ${customer.name}`,
			},
			{
				Header: "Bill-to",
				id: "billToCustomer_CustomerNumber",
				accessor: ({ billToCustomer }) =>
					billToCustomer ?
						`(${billToCustomer.customerNumber}) ${billToCustomer.name}`
					:	<EmptyValueDash />,
			},
			{
				Header: "Territory",
				id: "customer_Territory_Code",
				hiddenByDefault: true,
				accessor: ({ customer }) =>
					customer.territory ?
						`(${customer.territory.code}) ${customer.territory.description}`
					:	<EmptyValueDash />,
			},
			{
				Header: "Total $",
				id: "total",
				hiddenByDefault: true,
				accessor: ({ total }) => formatCurrency(total),
			},
			{
				Header: "Status",
				id: "statusCode",
				hiddenByDefault: true,
				accessor: ({ statusCode }) => statusCode,
			},
			{
				Header: "PO #",
				id: "dispatch_PoTicket",
				hiddenByDefault: true,
				accessor: ({ dispatch }) => dispatch?.poTicket || <EmptyValueDash />,
			},
			{
				Header: `Invoice date (${tz})`,
				id: "invoiceDate",
				hiddenByDefault: true,
				accessor: ({ invoiceDate }) =>
					displayDateTime(invoiceDate, "") || <EmptyValueDash />,
			},
			{
				Header: "",
				id: "plus-button",
				Cell: ({ row: { original } }: Cell<InvoiceSource>) => {
					return (
						<Box
							d="flex"
							justifyContent="flex-end"
							alignItems="center"
							gap={0.25}
							height={1.5}
						>
							{selections[original.id] ?
								<>
									<Icon color="gray" icon="check" />
									<Paragraph small secondary>
										Selected
									</Paragraph>
									<IconButton
										icon="times"
										onClick={() => removeInvoice(original.id)}
									/>
								</>
							:	<Button icon="plus" onClick={() => addInvoices([original])}>
									Select
								</Button>
							}
						</Box>
					)
				},
			},
		]
	}, [addInvoices, selections])

	const selectionsLength = useMemo(() => Object.values(selections).length, [selections])

	const showMoreStartsOpen = useMemo(() => {
		const keysToCheck: (keyof InvoiceSourceQueryParams)[] = [
			"includeZeroDollar",
			"billToCustomer",
			"search",
		]

		return keysToCheck.some((key) => params[key] !== defaultUrlState[key])
	}, [params])

	return (
		<>
			<Callout>
				{selectionsLength ?
					<Paragraph mb={2}>
						The following <strong>{formatNumber(selectionsLength)}</strong> invoice
						{selectionsLength === 1 ? " is" : "s are"} selected for printing:
					</Paragraph>
				:	<Paragraph>Select invoices below for printing</Paragraph>}

				{Object.values(selections).map((invoice) => (
					<Box key={invoice.id} d="inline-flex" alignItems="center" width={11}>
						<Paragraph>#{invoice.invoiceNumber}</Paragraph>
						<IconButton
							icon="times"
							color="gray"
							onClick={() => removeInvoice(invoice.id)}
						/>
					</Box>
				))}

				{!!selectionsLength && (
					<Box d="flex" gap={2} alignItems="center" mt={2}>
						<Button
							variant="primary-cta"
							icon="print"
							onClick={handlePrint}
							isLoading={isSaving()}
						>
							Email Link To Print
						</Button>
						<Button icon="trash-alt" onClick={() => setSelections({})}>
							Deselect all invoices
						</Button>
					</Box>
				)}
			</Callout>

			<Table
				query={invoicesQuery}
				columns={columns}
				pagination={pagination}
				setPagination={setPagination}
				rowVerticalAlign="middle"
				queryParamState={params}
				setQueryParamState={setParams}
				pinnedQueryFilters={[ShipToCustomerQueryFilter, TimeAgoQueryFilter]}
				toggledQueryFilters={[
					ZeroDollarQueryFilter,
					BillToCustomerQueryFilter,
					SearchQueryFilter,
				]}
				rightButtons={[
					{
						buttonText: "Select whole page",
						onClick: () => addInvoices(invoicesQuery.data),
						icon: "list",
					},
				]}
				showToggledFiltersByDefault={showMoreStartsOpen}
				filterResetValues={defaultUrlState}
			/>
		</>
	)
}

const tz = getTimezoneAbbreviation()

const defaultUrlState: PrintInvoicesTabUrlState = {
	ordering: null,
	search: null,
	startDate: getTimeAgoStartDate(TimeAgo.MonthsAgo6).format(DateFormat.DateQueryParam),
	endDate: null,
	region: null,
	territory: null,
	enterprise: null,
	organization: null,
	customer: null,
	invoiceType: null,
	status: null,
	billToCustomer: null,
	includeZeroDollar: false,
	hasDocuments: null,
}

const ShipToCustomerQueryFilter = (props: CustomerQueryFilterProps<PrintInvoicesTabUrlState>) => {
	return <DefaultCustomerQueryFilter {...props} paramKey="customer" label="Ship-to customer" />
}

const BillToCustomerQueryFilter = (props: CustomerQueryFilterProps<PrintInvoicesTabUrlState>) => {
	return (
		<DefaultCustomerQueryFilter
			{...props}
			paramKey="billToCustomer"
			label="Bill-to customer"
		/>
	)
}

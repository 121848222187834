import { getMiddlewareBaseUrl } from "@ncs/bricks"
import { post, rsaaTypes, RSAA } from "@ncs/bricks/middleware/portalApiMiddleware"

export const CREATE_DOCUMENT_TOKEN = rsaaTypes("documentToken/create")

export const createDocumentToken = (documentId, documentType) => ({
	[RSAA]: {
		api: post(`/workorders/document_tokens/`, {
			document_id: documentId,
			document_type: documentType,
		}),
		types: CREATE_DOCUMENT_TOKEN,
	},
})

export const getDocumentUrlForToken = (token) => {
	return `${getMiddlewareBaseUrl()}/api/document?token=${token}`
}

const initialState = {
	isCreatingDocumentToken: false,
	token: undefined,
	error: undefined,
}

const documents = (state = initialState, action) => {
	switch (action.type) {
		case CREATE_DOCUMENT_TOKEN.request:
			return {
				...state,
				isCreatingDocumentToken: true,
			}
		case CREATE_DOCUMENT_TOKEN.success:
			return {
				...state,
				isCreatingDocumentToken: false,
				token: action.payload.token,
				error: action.error,
			}
		case CREATE_DOCUMENT_TOKEN.failure:
			return {
				...state,
				isCreatingDocumentToken: false,
				token: undefined,
				error: action.error,
			}
		default:
			return state
	}
}
export default documents

import { get, post, patch, rsaaTypes, RSAA } from "@ncs/bricks/middleware/portalApiMiddleware"

export const LIST_CONTRACT_WASH_COUNTS = rsaaTypes("contracts/wash_counts/list")
export const PATCH_CONTRACT_WASH_COUNT = rsaaTypes("contracts/wash_counts/patch")
export const POST_BULK_CONTRACT_WASH_COUNTS = rsaaTypes("contracts/bulk_submit_counts/post")

export const listContractWashCounts = (params) => ({
	[RSAA]: {
		api: get("/contracts/wash_counts/", params),
		types: LIST_CONTRACT_WASH_COUNTS,
		includeTimestamp: true,
	},
})

export const patchContractWashCount = (washCount) => ({
	[RSAA]: {
		api: patch(`/contracts/wash_counts/${washCount.id}/`, washCount),
		types: PATCH_CONTRACT_WASH_COUNT,
	},
})

export const postBulkContractWashCounts = (washCounts) => ({
	[RSAA]: {
		api: post("/contracts/bulk_submit_counts/", washCounts),
		types: POST_BULK_CONTRACT_WASH_COUNTS,
	},
})

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { Select } from "@ncs/bricks/components"
import { loadLookups, LOOKUPS } from "../../redux/services/lookups"

const NCSModelSelector = ({
	displayEmpty,
	labelText,
	labelProps,
	selectProps,
	value,
	onChange,
}) => {
	const dispatch = useDispatch()
	const models = useSelector((store) => store.lookups.ncsEquipmentModels)

	useEffect(() => {
		dispatch(loadLookups([LOOKUPS.NCSEquipmentModels]))
	}, [dispatch])

	const handleChange = (modelId) => {
		onChange(modelId)
	}

	return (
		<Select
			id="models"
			labelText={labelText}
			value={value || ""}
			onChange={handleChange}
			valueAccessor="_id"
			textAccessor="model_name"
			options={[
				{
					_id: "",
					model_name: "Select a model",
				},
				...models,
			]}
			labelProps={{
				shrink: displayEmpty,
				...labelProps,
			}}
			selectProps={{
				displayEmpty,
				...selectProps,
			}}
		/>
	)
}

NCSModelSelector.defaultProps = {
	displayEmpty: true,
	labelText: "Models",
}

NCSModelSelector.propTypes = {
	value: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	displayEmpty: PropTypes.bool,
	labelProps: PropTypes.object,
	selectProps: PropTypes.object,
	labelText: PropTypes.string,
}

export default React.memo(NCSModelSelector)

import React from "react"

import { withStyles } from "@material-ui/core/styles"

import { successColor } from "@ncs/bricks/assets/jss/material-dashboard-pro-react"
import buttonsStyle from "@ncs/bricks/assets/jss/material-dashboard-pro-react/components/buttonStyle"
import { formatDate, formatDateTime } from "@ncs/bricks/util/formatters"

import {
	Button,
	ButtonWithIcon,
	Checkbox,
	ConditionalContent,
	CustomerSelector,
	DetailedCustomerSelector,
	Dialog,
	DynamicTable,
	GridContainer,
	GridItem,
	Input,
	InputAsDisplay,
	LoadingWrapper,
	QuickCard,
	Select,
	SnackbarContent,
	TicketPrioritySelector,
	TicketRequestorSelector,
	TicketSubtypeSelector,
	TicketTypeSelector,
	UserSelector,
} from "~/components"

import TicketCommentAddContainer from "../TicketCommentAddContainer"
import TicketCommentContainer from "../TicketCommentContainer"
import TicketInfoContainer from "../TicketInfoContainer"

import AddIcon from "@material-ui/icons/Add"
import AddAlert from "@material-ui/icons/AddAlert"
import CommentIcon from "@material-ui/icons/Comment"
import HistoryIcon from "@material-ui/icons/History"
import InfoIcon from "@material-ui/icons/Info"
import Remove from "@material-ui/icons/Remove"

const styles = {
	...buttonsStyle,
	right: {
		textAlign: "right",
	},
	notificationCheckIcon: {
		color: `${successColor[0]} !important`,
	},
}

const TechSupportTicket = (props) => {
	const {
		reduxKey,
		fetchDataAction,
		onCustomerSelected,
		selectedTicketType,
		selectedTicketPriority,
		selectedTicketSubtype,
		onTicketPriorityChange,
		onTicketSubtypeChange,
		onTicketTypeChange,
		description,
		descriptionIsValid,
		commentIsValid,
		descriptionErrorMessage,
		comment,
		onCommentChange,
		commentErrorMessage,
		isUrgent,
		isUrgentIsValid,
		onIsUrgentChange,
		isUrgentErrorMessage,
		setInputRef,
		showValidationErrors,
		onDescriptionChange,
		onSaveTicket,
		valid,
		error,
		newTicketMessage,
		success,
		onTicketAssignedToChange,
		selectedAssignedTo,
		classes,
		isAdmin,
		documents,
		onDeleteDocument,
		isSaving,
		selectedCustomer,
		customerEquipment,
		selectedCustomerEquipment,
		onCustomerEquipmentChange,
		onTicketTechChange,
		selectedTicketRequestor,
		onSelectedTicketRequestorChange,
		selectedRequestor,
		onRequestorTypeChange,
		requestorText,
		onRequestorTextChange,
		requestorTextIsValid,
		requestorTextErrorMessage,
		onTicketSelected,
		selectedTicket,
		onCommentSelected,
		selectedComment,
		onCommentClose,
		onAddComment,
		isAddingComment,
		onCancelAddComment,
		onCommentCreated,
		isLoadingTicket,
	} = props
	const columns = [
		{
			Header: "Ticket #",
			accessor: "id",
			id: "id",
			sortable: true,
			toggleable: false,
			headerStyle: { textAlign: "center " },
		},
		{
			Header: "Title",
			accessor: "description",
			id: "description",
			sortable: true,
			toggleable: true,
			headerStyle: { textAlign: "center " },
		},
		{
			Header: "Created Date",
			accessor: (x) => formatDateTime(x.created_date),
			id: "created_date",
			sortable: true,
			toggleable: true,
			headerStyle: { textAlign: "center " },
		},
		{
			Header: "Closed Date",
			accessor: (x) => formatDateTime(x.closed_date),
			id: "closed_date",
			sortable: true,
			toggleable: true,
			hiddenByDefault: true,
			headerStyle: { textAlign: "center " },
		},
		{
			Header: "Estimated Completion Date",
			accessor: (x) => formatDate(x.estimated_completion_date),
			id: "estimated_completion_date",
			sortable: true,
			toggleable: true,
			hiddenByDefault: false,
			headerStyle: { textAlign: "center " },
		},
		{
			Header: "Type",
			accessor: (x) => x.ticket_type.description,
			id: "ticket_type",
			sortable: true,
			toggleable: true,
			headerStyle: { textAlign: "center " },
		},
		{
			Header: "Priority",
			accessor: (x) => (x.priority === null ? "" : x.priority.description),
			id: "priority",
			sortable: true,
			toggleable: true,
			headerStyle: { textAlign: "center " },
		},
		{
			Header: "Group",
			accessor: "ticket_type.group.description",
			id: "ticket_type__group__description",
			sortable: true,
			toggleable: true,
			hiddenByDefault: true,
			headerStyle: { textAlign: "center " },
		},
		{
			Header: "Created By",
			accessor: (x) => x.created_by.name,
			id: "createdBy",
			sortable: true,
			toggleable: true,
			headerStyle: { textAlign: "center " },
		},
		{
			Header: "Closed By",
			accessor: (x) => (x.closed_by ? x.closed_by.name : ""),
			id: "closed_by",
			sortable: true,
			toggleable: true,
			hiddenByDefault: true,
			headerStyle: { textAlign: "center " },
		},
		{
			Header: "Assigned To",
			accessor: (x) => (x.assigned_to ? x.assigned_to.name : ""),
			id: "assignedTo",
			sortable: true,
			toggleable: true,
			headerStyle: { textAlign: "center " },
		},
		{
			Header: "Status",
			accessor: (x) => x.status.description,
			id: "status",
			sortable: true,
			toggleable: true,
			headerStyle: { textAlign: "center " },
		},
		{
			Header: "Urgent",
			accessor: (x) => (x.is_urgent ? "Yes" : "No"),
			id: "is_urgent",
			sortable: true,
			toggleable: true,
			headerStyle: { textAlign: "center " },
		},
		{
			Header: "Tech",
			accessor: (x) => (x.tech_id ? x.tech.name : "None"),
			id: "tech",
			sortable: true,
			toggleable: true,
			headerStyle: { textAlign: "center " },
		},
		{
			Header: "Customer",
			accessor: (x) =>
				x.customer_id ? `${x.customer.name} (${x.customer.customer_number})` : "None",
			id: "customer",
			sortable: true,
			toggleable: true,
			headerStyle: { textAlign: "center " },
		},
		{
			Header: "Customer Equipment",
			accessor: (x) =>
				x.customer_equipment_id ?
					`${x.customer_equipment.model} (${x.customer_equipment.serial_number})`
				:	"None",
			id: "customer_equipment",
			sortable: true,
			toggleable: true,
			headerStyle: { textAlign: "center " },
		},
	]
	return (
		<GridContainer>
			<LoadingWrapper isLoading={isSaving} />
			<LoadingWrapper isLoading={isLoadingTicket} />
			{/* create ticket side */}
			<GridItem xs={5}>
				<GridContainer>
					<GridItem xs={12}>
						<QuickCard title="Customer Info" icon={<InfoIcon />}>
							<GridContainer>
								<GridItem xs={12} sm={6}>
									<InputAsDisplay
										labelText="Customer"
										value={
											selectedCustomer ?
												`${selectedCustomer.name} (${selectedCustomer.customer_number})`
											:	"None"
										}
									/>
								</GridItem>
								<GridItem xs={12} sm={6}>
									<InputAsDisplay
										labelText="Contact"
										value={
											selectedCustomer ?
												`${selectedCustomer.contact_name}`
											:	"None"
										}
									/>
								</GridItem>
								<GridItem xs={12} sm={6}>
									<InputAsDisplay
										labelText="Credit Status"
										value={
											selectedCustomer ?
												`${selectedCustomer.credit_check_status}`
											:	"None"
										}
									/>
								</GridItem>
								<GridItem xs={12} sm={6}>
									<InputAsDisplay
										labelText="Territory"
										value={
											selectedCustomer ?
												`${selectedCustomer.territory.code}`
											:	"None"
										}
									/>
								</GridItem>
								<GridItem xs={12} sm={12}>
									<InputAsDisplay
										labelText="Address"
										value={
											selectedCustomer ?
												`${selectedCustomer.address_2} ${selectedCustomer.city}, ${selectedCustomer.state} ${selectedCustomer.postalcode}`
											:	"None"
										}
									/>
								</GridItem>
							</GridContainer>
						</QuickCard>
					</GridItem>

					<GridItem xs={12}>
						<QuickCard title={"Create Ticket"} icon={<AddIcon />}>
							<GridContainer>
								<GridItem xs={12}>
									<Input
										labelText={"Title"}
										value={description}
										onChange={onDescriptionChange}
										inputProps={{
											inputProps: { ref: setInputRef },
										}}
										formControlProps={{
											error: showValidationErrors && !descriptionIsValid,
										}}
										helperText={
											showValidationErrors ? descriptionErrorMessage : ""
										}
									/>
								</GridItem>
								<GridItem xs={12} sm={10}>
									<CustomerSelector
										onCustomerSelected={onCustomerSelected}
										selectedSuggestion={selectedCustomer}
										limitToActiveCustomers={false}
										limitToServiceableCustomers={true}
										limitToNonServiceCustomer={true}
									/>
								</GridItem>
								<GridItem xs={12} sm={2}>
									<DetailedCustomerSelector
										onCustomerSelected={onCustomerSelected}
										limitToActiveCustomers={false}
										limitToServiceableCustomers={true}
										limitToNonServiceCustomer={true}
									/>
								</GridItem>
								<GridItem xs={12} md={6} lg={6}>
									<TicketTypeSelector
										type={selectedTicketType}
										group={3}
										value={selectedTicketType || ""}
										onChange={onTicketTypeChange}
									/>
								</GridItem>
								<GridItem xs={12} md={6} lg={6}>
									<TicketSubtypeSelector
										type={selectedTicketType}
										onChange={onTicketSubtypeChange}
										subtype={selectedTicketSubtype}
									/>
								</GridItem>
								<GridItem xs={12} md={6} lg={6}>
									<Checkbox
										labelText="Is Urgent"
										checked={isUrgent}
										onClick={onIsUrgentChange}
										isError={!isUrgentIsValid}
										helperText={isUrgentErrorMessage}
									/>
								</GridItem>
								<GridItem
									xs={12}
									md={6}
									show={customerEquipment ? customerEquipment.length > 0 : false}
								>
									<Select
										id="customerEquipment"
										labelText="Customer Equipment"
										value={selectedCustomerEquipment}
										onChange={onCustomerEquipmentChange}
										options={[
											{
												_id: null,
												model: "No Equipment.",
												disabled: false,
												serial_number: "",
											},
											...(customerEquipment || []),
										]}
										labelProps={{ shrink: true }}
										valueAccessor="_id"
										textAccessor={(x) =>
											`${x.model} ${
												x.serial_number ? `(${x.serial_number})` : ""
											} ${x._id !== null ? `(Bay: ${x.bay})` : ""}`
										}
									/>
								</GridItem>
								<GridItem
									sm={12}
									md={6}
									hide={customerEquipment ? customerEquipment.length > 0 : false}
								></GridItem>
								<GridItem xs={12} md={6}>
									<Select
										id="ticketCommentRequestor"
										labelText="Requestor Type"
										value={selectedRequestor}
										onChange={onRequestorTypeChange}
										options={
											selectedCustomer ?
												[
													{
														id: null,
														description: "Select a request type...",
														disabled: false,
													},
													{
														id: 1,
														description: "Technician",
													},
													{
														id: 2,
														description: "Other",
													},
												]
											:	[
													{
														id: null,
														description: "Select a request type...",
														disabled: false,
													},
													{
														id: 1,
														description: "Technician",
													},
												]
										}
										labelProps={{ shrink: true }}
										valueAccessor="id"
										textAccessor={(x) => x.description}
									/>
								</GridItem>
								<GridItem xs={12} md={6} lg={6} show={selectedRequestor === 1}>
									<UserSelector
										labelText="Tech"
										onUserSelected={onTicketTechChange}
									/>
								</GridItem>
								<GridItem xs={12} md={6} show={selectedRequestor === 1}></GridItem>
								<GridItem xs={12} md={6} show={selectedRequestor === 2}>
									<TicketRequestorSelector
										customer={selectedCustomer ? selectedCustomer._id : null}
										onChange={onSelectedTicketRequestorChange}
										requestor={selectedTicketRequestor}
									/>
								</GridItem>
								<GridItem
									xs={12}
									md={6}
									show={
										selectedRequestor === 2 && selectedTicketRequestor === null
									}
								>
									<Input
										labelText={"Requestor"}
										value={requestorText}
										onChange={onRequestorTextChange}
										inputProps={{
											ref: setInputRef,
											autoFocus: true,
										}}
										formControlProps={{
											error: showValidationErrors && !requestorTextIsValid,
										}}
										helperText={
											showValidationErrors ? requestorTextErrorMessage : ""
										}
									/>
								</GridItem>
								<GridItem
									show={
										selectedRequestor === 2 && selectedTicketRequestor !== null
									}
									xs={12}
									sm={6}
								></GridItem>

								<GridItem xs={12} md={6}></GridItem>

								<ConditionalContent show={isAdmin}>
									<GridItem xs={12} md={6}>
										<TicketPrioritySelector
											priority={selectedTicketPriority}
											onChange={onTicketPriorityChange}
										/>
									</GridItem>
									<GridItem xs={12} md={6}>
										<UserSelector
											labelText="Assign To"
											onUserSelected={onTicketAssignedToChange}
											defaultValue={selectedAssignedTo || ""}
										/>
									</GridItem>
								</ConditionalContent>

								<GridItem lg={12}>
									<Input
										labelText={"Comment"}
										value={comment}
										onChange={onCommentChange}
										inputProps={{
											ref: setInputRef,
											multiline: true,
										}}
										formControlProps={{
											error: showValidationErrors && !commentIsValid,
										}}
										helperText={
											showValidationErrors ? commentErrorMessage : ""
										}
									/>
								</GridItem>
								<GridItem xs={6}>
									<GridContainer show={documents?.length > 0}>
										<GridItem xs={12}>
											<h2>Documents</h2>
										</GridItem>
										{documents?.map((document) => (
											<React.Fragment key={document.filename}>
												<GridItem xs={8}>{document.filename}</GridItem>
												<GridItem xs={4}>
													<Button
														justIcon
														round
														color="danger"
														size="sm"
														title="Delete"
														onClick={(e) => {
															e.stopPropagation()
															onDeleteDocument(document)
														}}
													>
														<Remove />
													</Button>
												</GridItem>
											</React.Fragment>
										))}
									</GridContainer>
								</GridItem>
								<GridItem xs={6} className={classes.right}>
									<ButtonWithIcon
										icon={<AddIcon />}
										content="Create Ticket"
										size="sm"
										round
										color="success"
										onClick={onSaveTicket}
										disabled={!valid}
									/>
								</GridItem>
								<ConditionalContent show={error !== null}>
									<GridItem xs={12} />
									<GridItem xs={4}>
										<SnackbarContent
											message={
												"Error Creating Ticket. If problem persists contact IT."
											}
											color={"info"}
											icon={AddAlert}
										/>
									</GridItem>
									<GridItem xs={12} />
								</ConditionalContent>
								<ConditionalContent show={success}>
									<GridItem xs={12}></GridItem>
									<GridItem xs={4}>
										<SnackbarContent
											message={newTicketMessage}
											color={"success"}
											icon={AddAlert}
										/>
									</GridItem>
								</ConditionalContent>
							</GridContainer>
						</QuickCard>
					</GridItem>
				</GridContainer>
			</GridItem>
			{/* customer info/recent ticket side */}
			<GridItem xs={7}>
				<GridContainer>
					<GridItem xs={12}>
						<ConditionalContent show={selectedTicket !== null}>
							<TicketInfoContainer
								key={selectedTicket?._id}
								techSupportView={true}
								ticket={selectedTicket ? selectedTicket : null}
								refreshTicket={onCommentCreated}
							/>
						</ConditionalContent>
					</GridItem>
					<ConditionalContent show={selectedTicket !== null}>
						<GridItem xs={12}>
							<QuickCard title={"comments"} icon={<CommentIcon />}>
								<ButtonWithIcon
									icon={<AddIcon />}
									content="Add Comment"
									size="sm"
									round
									color="success"
									onClick={onAddComment}
								/>
								<DynamicTable // TODO: make props static
									data={selectedTicket ? selectedTicket.comments : []}
									columns={[
										{
											Header: "comment",
											accessor: "id",
											id: "id",
											sortable: true,
											toggleable: false,
											headerStyle: { textAlign: "center " },
										},
										{
											Header: "Description",
											accessor: "description",
											id: "description",
											sortable: true,
											toggleable: true,
											headerStyle: { textAlign: "center " },
										},
										{
											Header: "Created Date",
											accessor: (x) => formatDateTime(x.created_date),
											id: "created_date",
											sortable: true,
											toggleable: true,
											headerStyle: { textAlign: "center " },
										},
									]}
									onRowClick={(comment) => onCommentSelected(comment)}
									showPaginationTop={false}
									showPaginationBottom={false}
								/>
							</QuickCard>
						</GridItem>
					</ConditionalContent>
				</GridContainer>
			</GridItem>
			{/* ticket Info */}
			<GridItem xs={12}>
				<GridContainer>
					<GridItem xs={12}>
						<QuickCard title={"Recent Tickets"} icon={<HistoryIcon />}>
							<GridContainer>
								<GridItem xs={12}>
									<DynamicTable // TODO: make props static
										reduxKey={reduxKey}
										fetchDataAction={fetchDataAction}
										columns={columns}
										onRowClick={(ticket) => onTicketSelected(ticket)}
									/>
								</GridItem>
							</GridContainer>
						</QuickCard>
					</GridItem>
				</GridContainer>
			</GridItem>
			<Dialog
				warning="true"
				show={selectedComment !== null}
				confirmBtnIcon={<Remove />}
				fullWidth={true}
				confirmBtnText="Cancel"
				confirmBtnColor="danger"
				onConfirm={onCommentClose}
				isConfirmationAsync={true}
			>
				{selectedComment !== null && (
					<GridContainer>
						<GridItem xs={12}>
							<TicketCommentContainer
								isService={true}
								comment={selectedComment}
								ticketCustomer={selectedTicket?.customer_id}
								refreshTicket={onCommentCreated}
								hasDocumentationEmail={
									selectedTicket?.customer_id ?
										selectedTicket.customer.documentation_email === null ?
											false
										:	true
									:	false
								}
								documentationEmail={
									selectedTicket?.customer_id ?
										selectedTicket.customer.documentation_email
									:	null
								}
							/>
						</GridItem>
					</GridContainer>
				)}
			</Dialog>
			<Dialog
				warning="true"
				show={isAddingComment}
				confirmBtnIcon={<Remove />}
				fullWidth={true}
				confirmBtnText="Cancel"
				confirmBtnColor="danger"
				onConfirm={onCancelAddComment}
				isConfirmationAsync={true}
			>
				{isAddingComment && (
					<GridContainer>
						<GridItem xs={12}>
							<TicketCommentAddContainer
								onCommentAddCancel={onCancelAddComment}
								ticket={selectedTicket}
								onCommentCreate={onCommentCreated}
								isService={true}
								ticketCustomer={selectedTicket?.customer_id}
							/>
						</GridItem>
					</GridContainer>
				)}
			</Dialog>
		</GridContainer>
	)
}

export default withStyles(styles)(TechSupportTicket)

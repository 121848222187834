import React, { useCallback, useEffect, useState, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { loadLookups, LOOKUPS } from "../../redux/services/lookups"

// custom components
import { Select } from "@ncs/bricks/components"

const HazmatPackageTypeSelector = ({
	value,
	defaultValue,
	onTypeSelected,
	displayEmpty = false,
	labelProps = {},
	selectProps = {},
	...rest
}) => {
	const [localValue, setLocalValue] = useState(defaultValue || "")
	const dispatch = useDispatch()
	const hazmatTypes = useSelector((x) => x.lookups.hazmatPackageTypes)

	// load HazmatPackageTypes once
	useEffect(() => {
		if (!Array.isArray(hazmatTypes) || hazmatTypes.length === 0) {
			dispatch(loadLookups(LOOKUPS.HazmatPackageTypes))
		}
	}, [dispatch])

	const options = useMemo(
		() => [
			{
				id: "",
				description: "Select a Type...",
			},
			...hazmatTypes,
		],
		[hazmatTypes]
	)

	const onChange = useCallback(
		(value) => {
			setLocalValue(value)
			const item = hazmatTypes.find((x) => x.id === value)
			onTypeSelected?.(item)
		},
		[setLocalValue, hazmatTypes, onTypeSelected]
	)
	return (
		<Select
			labelText="Hazmat Type"
			id="hazmatPackageTypes"
			valueAccessor="id"
			textAccessor="description"
			{...rest}
			value={value ?? localValue}
			onChange={onChange}
			options={options}
		/>
	)
}

export default React.memo(HazmatPackageTypeSelector)

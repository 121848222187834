import { FC, useState } from "react"

import {
	APPLICATION,
	InventoryPart,
	makeApiErrorMessage,
	UpdateInventoryPartPatch,
	useUpdateInventoryPart,
	useUpdateInventoryPartImage,
	useUserCanUse,
} from "@ncs/ncs-api"
import { displayNumber, extractNumber, formatCurrency, formatNumber, yesOrNo } from "@ncs/ts-utils"
import {
	Box,
	Button,
	ConfirmationModal,
	ConfirmationModalConfig,
	cssMixins,
	EditNumberModal,
	EditNumberModalProps,
	EditStringModal,
	EditStringModalProps,
	EditBooleanModal,
	EditBooleanModalProps,
	EmptyValueDash,
	encodeUrlState,
	FileInput,
	FileType,
	GridContainer,
	GridItem,
	Heading,
	HeadingDivider,
	Icon,
	IconButton,
	Label,
	LabeledData,
	Link,
	Paragraph,
	ParagraphList,
	PartImage,
	useToast,
} from "@ncs/web-legos"

import { EditFreightGroupModal } from "./EditFreightGroupModal"
import { EditPartHazmatModal } from "./EditPartHazmatModal"
import { EditPartSizeModal } from "./EditPartSizeModal"
import { PartManagementUrlState } from "./PartManagement"

export interface PartDetailsTabProps {
	part: InventoryPart
}

const PartDetailsTab: FC<PartDetailsTabProps> = ({ part }) => {
	const isAdmin = useUserCanUse(APPLICATION.PartManagementAdmin)
	const { makeSuccessToast, makeErrorToast } = useToast()
	const [showEditSize, setShowEditSize] = useState(false)
	const [showEditHazmat, setShowEditHazmat] = useState(false)
	const [showFreightGroupModal, setShowFreightGroupModal] = useState(false)
	const [isSavingImage, setIsSavingImage] = useState(false)
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)
	const [editStringModalConfig, setEditStringModalConfig] =
		useState<EditStringModalProps | null>(null)
	const [editNumberModalConfig, setEditNumberModalConfig] =
		useState<EditNumberModalProps | null>(null)
	const [editBooleanModalConfig, setEditBooleanModalConfig] =
		useState<EditBooleanModalProps | null>(null)

	const updateImage = useUpdateInventoryPartImage()
	const updatePart = useUpdateInventoryPart()

	const onSelectImage = async (file: File) => {
		try {
			setIsSavingImage(true)
			await updateImage({
				partId: part.id,
				file,
			})
			makeSuccessToast("Part updated")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		} finally {
			setIsSavingImage(false)
		}
	}

	const onRemoveImage = async () => {
		await updateImage({
			partId: part.id,
			file: null,
		})
		makeSuccessToast("Part updated")
	}

	const onUpdatePart = async <F extends keyof UpdateInventoryPartPatch>(
		field: F,
		value: UpdateInventoryPartPatch[F]
	) => {
		try {
			await updatePart({
				updates: { [field]: value },
				id: part.id,
			})
			makeSuccessToast("Part updated")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		}
	}

	return (
		<>
			<GridContainer
				columnGap={5}
				mdProps={{ columnGap: 3 }}
				smProps={{ columnGap: 1 }}
				xsProps={{ columnGap: 0 }}
			>
				<GridItem xs={12} md={9}>
					<HeadingDivider headingVariant="h4" bold mt={0}>
						Details
					</HeadingDivider>
					<GridContainer pl={1.5}>
						<GridItem xs={12} sm={6} md={4} lg={3}>
							<LabeledData label="Description" css={cssMixins.breakAnywhereStyle}>
								{part.description}
							</LabeledData>
						</GridItem>
						<GridItem xs={12} sm={6} md={4} lg={3}>
							<LabeledData label="Part number">{part.partNumber}</LabeledData>
						</GridItem>
						<GridItem xs={12} sm={6} md={4} lg={3}>
							<Label>Other part numbers</Label>
							{part.otherPartNumbers.length ?
								<ParagraphList lines={part.otherPartNumbers} />
							:	<div>
									<EmptyValueDash />
								</div>
							}
						</GridItem>
						<GridItem xs={12} sm={6} md={4} lg={3}>
							<LabeledData label="Part family">
								{part.partFamily || <EmptyValueDash />}
							</LabeledData>
						</GridItem>
						<GridItem xs={12} sm={6} md={4} lg={3}>
							<Label>UPC code</Label>
							<Box display="flex" alignItems="center" mt={-0.25}>
								<Paragraph>{part.upcCode || <EmptyValueDash />}</Paragraph>
								{isAdmin && (
									<IconButton
										icon="pencil"
										color="primary"
										onClick={() => {
											setEditStringModalConfig({
												isOpen: true,
												onSave: (newValue) =>
													onUpdatePart("upcCode", newValue),
												initialValue: part.upcCode,
												title: "Edit UPC Code",
												allowEmpty: true,
												emptyValue: null,
												label: "UPC code",
												maxLength: 100,
												onClose: () => setEditStringModalConfig(null),
											})
										}}
									/>
								)}
							</Box>
						</GridItem>
						<GridItem xs={12} sm={6} md={4} lg={3}>
							<Label>UNSPSC number</Label>
							<Box display="flex" alignItems="center" mt={-0.25}>
								<Paragraph>{part.unspscNumber || <EmptyValueDash />}</Paragraph>
								{isAdmin && (
									<IconButton
										icon="pencil"
										color="primary"
										onClick={() => {
											setEditStringModalConfig({
												isOpen: true,
												onSave: (newValue) =>
													onUpdatePart(
														"unspscNumber",
														newValue != null ?
															extractNumber(newValue)
														:	null
													),
												initialValue:
													part.unspscNumber?.toString() ?? null,
												allowEmpty: true,
												title: "Edit UNSPSC Number",
												label: "UNSPSC number",
												onClose: () => setEditStringModalConfig(null),
											})
										}}
									/>
								)}
							</Box>
						</GridItem>
						<GridItem xs={12} sm={6} md={4} lg={3}>
							<LabeledData label="List price">
								{formatCurrency(part.listPrice)}
							</LabeledData>
						</GridItem>
						{isAdmin && (
							<GridItem xs={12} sm={6} md={4} lg={3}>
								<LabeledData label="Standard cost">
									{formatCurrency(part.standardCost)}
								</LabeledData>
							</GridItem>
						)}
						<GridItem xs={12} sm={6} md={4} lg={3}>
							<LabeledData label="Unit of measure">{part.unitOfMeasure}</LabeledData>
						</GridItem>
						<GridItem xs={12} sm={6} md={3}>
							<Label>Qty per pallet</Label>
							<Box display="flex" gap={0.5}>
								<Paragraph>{displayNumber(part.quantityPerPallet)}</Paragraph>
								{isAdmin && (
									<Box mt={-0.4}>
										<IconButton
											icon="pencil"
											color="primary"
											onClick={() =>
												setEditNumberModalConfig({
													title: "Edit Quantity Per Pallet",
													label: "Quantity per pallet",
													initialValue: part.quantityPerPallet,
													onSave: async (newValue) => {
														await onUpdatePart(
															"quantityPerPallet",
															newValue ?? 0
														)
													},
													onClose: () => setEditNumberModalConfig(null),
												})
											}
										/>
									</Box>
								)}
							</Box>
						</GridItem>
						<GridItem xs={12} sm={6} md={3}>
							<Label>Freight group</Label>
							<Box d="flex" gap={0.5}>
								<Paragraph>
									{part.freightGroupDescription || <EmptyValueDash />}
								</Paragraph>
								{isAdmin && (
									<Box mt={-0.4}>
										<IconButton
											icon="pencil"
											color="primary"
											onClick={() => setShowFreightGroupModal(true)}
										/>
									</Box>
								)}
							</Box>
						</GridItem>
						<GridItem xs={12} sm={6} md={3}>
							<Label>Freeze protection required?</Label>
							<Box d="flex" gap={0.5}>
								<Paragraph>{yesOrNo(part.freezeProtection)}</Paragraph>
								{isAdmin && (
									<Box mt={-0.4}>
										<IconButton
											icon="pencil"
											color="primary"
											onClick={() =>
												setEditBooleanModalConfig({
													title: "Edit Freeze Protection",
													description:
														"Does this item require freeze protection measures?",
													onSave: async (newValue) => {
														await onUpdatePart(
															"freezeProtection",
															newValue
														)
													},
													onClose: () => setEditBooleanModalConfig(null),
												})
											}
										/>
									</Box>
								)}
							</Box>
						</GridItem>
					</GridContainer>

					<HeadingDivider headingVariant="h4" bold mt={4}>
						Status
					</HeadingDivider>
					<GridContainer pl={1.5}>
						<GridItem xs={12} sm={6} md={4} lg={3}>
							<LabeledData label="Status">{part.status}</LabeledData>
						</GridItem>
						<GridItem xs={12} sm={6} md={4} lg={3}>
							<LabeledData label="Active? ">
								{part.isActive ?
									<>
										<Icon icon="check" /> Active
									</>
								:	"No"}
							</LabeledData>
						</GridItem>
						<GridItem xs={12} sm={6} md={4} lg={3}>
							<LabeledData label="Service? ">
								{part.isService ?
									<>
										<Icon icon="check" /> Service
									</>
								:	"No"}
							</LabeledData>
						</GridItem>
						<GridItem xs={12} sm={6} md={4} lg={3}>
							<LabeledData label="Replaced by">
								{part.replacedBy ?
									<Link
										to={`/inventory/part-management/${encodeUrlState<PartManagementUrlState>(
											{
												partNumberRedirect: part.replacedBy,
											}
										)}`}
									>
										{part.replacedBy}
									</Link>
								:	<EmptyValueDash />}
							</LabeledData>
						</GridItem>
					</GridContainer>

					<HeadingDivider
						headingVariant="h4"
						bold
						mt={4}
						renderRight={() =>
							isAdmin ?
								<Button icon="pencil" onClick={() => setShowEditSize(true)}>
									Edit size
								</Button>
							:	undefined
						}
					>
						Size
					</HeadingDivider>
					<GridContainer pl={1.5}>
						<GridItem xs={12} sm={6} md={3}>
							<LabeledData label="Height">
								{part.height != null ?
									<>{formatNumber(part.height)}"</>
								:	<EmptyValueDash />}
							</LabeledData>
						</GridItem>
						<GridItem xs={12} sm={6} md={3}>
							<LabeledData label="Length">
								{part.length != null ?
									<>{formatNumber(part.length)}"</>
								:	<EmptyValueDash />}
							</LabeledData>
						</GridItem>
						<GridItem xs={12} sm={6} md={3}>
							<LabeledData label="Width">
								{part.width != null ?
									<>{formatNumber(part.width)}"</>
								:	<EmptyValueDash />}
							</LabeledData>
						</GridItem>
						<GridItem xs={12} sm={6} md={3}>
							<LabeledData label="Weight">
								{part.weight != null ?
									<>{formatNumber(part.weight)} lbs</>
								:	<EmptyValueDash />}
							</LabeledData>
						</GridItem>
						<GridItem xs={12} sm={6} md={3}>
							<LabeledData label="Gallons">
								{part.gallons != null ?
									formatNumber(part.gallons)
								:	<EmptyValueDash />}
							</LabeledData>
						</GridItem>
					</GridContainer>

					<HeadingDivider
						headingVariant="h4"
						bold
						mt={4}
						renderRight={() =>
							isAdmin ?
								<Button icon="pencil" onClick={() => setShowEditHazmat(true)}>
									Edit hazmat
								</Button>
							:	undefined
						}
					>
						Hazmat
					</HeadingDivider>
					<GridContainer pl={1.5}>
						<GridItem xs={12} sm={6} md={3}>
							<LabeledData label="ID number">
								{part.hazmat?.idNumber || <EmptyValueDash />}
							</LabeledData>
						</GridItem>
						<GridItem xs={12} sm={6} md={3}>
							<LabeledData label="Shipping name">
								{part.hazmat?.shippingName || <EmptyValueDash />}
							</LabeledData>
						</GridItem>
						<GridItem xs={12} sm={6} md={3}>
							<LabeledData label="Description">
								{part.hazmat?.description || <EmptyValueDash />}
							</LabeledData>
						</GridItem>
						<GridItem xs={12} sm={6} md={3}>
							<LabeledData label="Hazard class">
								{part.hazmat?.hazardClass || <EmptyValueDash />}
							</LabeledData>
						</GridItem>
						<GridItem xs={12} sm={6} md={3}>
							<LabeledData label="Hazmat type">
								{part.hazmat?.hazmatType || <EmptyValueDash />}
							</LabeledData>
						</GridItem>
						<GridItem xs={12} sm={6} md={3}>
							<LabeledData label="Packing group">
								{part.hazmat?.packingGroup || <EmptyValueDash />}
							</LabeledData>
						</GridItem>
						<GridItem xs={12} sm={6} md={3}>
							<LabeledData label="Additional information">
								{part.hazmat?.additionalInformation || <EmptyValueDash />}
							</LabeledData>
						</GridItem>
					</GridContainer>
				</GridItem>

				<GridItem xs={12} md={3}>
					<Heading variant="h4" bold mb={1}>
						Part Image
					</Heading>
					{part.imageUrl ?
						<PartImage src={part.imageUrl} mb={2} />
					:	<Paragraph small color="secondary" mb={1.5}>
							(none set)
						</Paragraph>
					}
					{!!part.imageUrl && !part.partImageUrl && (
						<Paragraph small color="secondary" mb={2}>
							No image set, showing this part's e-commerce product image instead
						</Paragraph>
					)}
					{isAdmin && (
						<>
							<FileInput
								fileTypes={[FileType.Images]}
								onChange={onSelectImage}
								isLoading={isSavingImage}
								label="Select Image"
								fillContainer
							/>
							{!!part.partImageUrl && (
								<Box textAlign="right" mt={2}>
									<Button
										icon="trash-alt"
										onClick={() =>
											setConfirmationConfig({
												title: "Remove Image",
												message: "Remove the image for this part?",
												onConfirm: onRemoveImage,
											})
										}
									>
										Remove image
									</Button>
								</Box>
							)}
						</>
					)}
				</GridItem>
			</GridContainer>

			{showEditSize && (
				<EditPartSizeModal onClose={() => setShowEditSize(false)} part={part} />
			)}
			{showEditHazmat && (
				<EditPartHazmatModal onClose={() => setShowEditHazmat(false)} part={part} />
			)}
			{showFreightGroupModal && (
				<EditFreightGroupModal
					part={part}
					onClose={() => setShowFreightGroupModal(false)}
				/>
			)}
			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
			{!!editStringModalConfig && <EditStringModal {...editStringModalConfig} />}
			{!!editNumberModalConfig && <EditNumberModal {...editNumberModalConfig} />}
			{!!editBooleanModalConfig && <EditBooleanModal {...editBooleanModalConfig} />}
		</>
	)
}

export default PartDetailsTab

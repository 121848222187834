import { FC } from "react"

import { Cell, Column } from "react-table"

import {
	PmpiSummaryReportLine,
	PmpiSummaryReportQueryParams,
	usePmpiSummaryReport,
} from "@ncs/ncs-api"
import {
	extractNumber,
	formatNumber,
	formatPercentage,
	getStartAndEndDateQueryParams,
	TimeAgo,
	Typify,
} from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	CustomerQueryFilter,
	EnterpriseQueryFilter,
	OrganizationQueryFilter,
	ReactTableSortType,
	RegionQueryFilter,
	Table,
	TerritoryQueryFilter,
	TimeAgoQueryFilter,
	useUrlState,
} from "@ncs/web-legos"

export type PmpiSummaryReportUrlState = Typify<PmpiSummaryReportQueryParams>

export const PmpiSummaryReport: FC = () => {
	const [queryParams, { setUrlState: setQueryParams }] = useUrlState<PmpiSummaryReportUrlState>(
		initialPmpiSummaryReportUrlState
	)
	const [data, dataLoading] = usePmpiSummaryReport({ params: { ...queryParams } })

	return (
		<AnimatedEntrance show>
			<Table
				data={data ?? []}
				columns={columns}
				isLoading={dataLoading}
				queryParamState={queryParams}
				setQueryParamState={setQueryParams}
				pinnedQueryFilters={[
					TimeAgoQueryFilter,
					CustomerQueryFilter,
					TerritoryQueryFilter,
					OrganizationQueryFilter,
				]}
				toggledQueryFilters={[RegionQueryFilter, EnterpriseQueryFilter]}
				filterResetValues={initialPmpiSummaryReportUrlState}
				storeColumnVisibility="pmpi-summary-report-columns"
			/>
		</AnimatedEntrance>
	)
}

const columns: Column<PmpiSummaryReportLine>[] = [
	{
		Header: "Territory",
		sortType: ReactTableSortType.Number,
		accessor: ({ territoryCode }) => extractNumber(territoryCode),
		Cell: ({ row: { original } }: Cell<PmpiSummaryReportLine>) =>
			`(${original.territoryCode}) ${original.territoryDescription}`,
	},
	{
		Header: "Total",
		sortType: ReactTableSortType.Number,
		accessor: ({ total }) => formatNumber(total),
	},
	{
		Header: "Completed",
		sortType: ReactTableSortType.Number,
		accessor: ({ completed }) => formatNumber(completed),
	},
	{
		Header: "Not completed",
		sortType: ReactTableSortType.Number,
		accessor: ({ notCompleted }) => formatNumber(notCompleted),
	},
	{
		Header: "Completion %",
		sortType: ReactTableSortType.Number,
		accessor: ({ completed, total }) =>
			total !== 0 ? formatPercentage(completed / total, 0) : "-",
	},
]

const initialPmpiSummaryReportUrlState: PmpiSummaryReportUrlState = {
	organization: null,
	region: null,
	enterprise: null,
	territory: null,
	customer: null,
	startDate: getStartAndEndDateQueryParams(TimeAgo.DaysAgo30)[0],
	endDate: null,
}

import { FC, useState } from "react"

import {
	makeApiErrorMessage,
	QuickOrder,
	useCreateQuickOrder,
	useUpdateQuickOrder,
} from "@ncs/ncs-api"
import { unpythonify } from "@ncs/ts-utils"
import { ExtendableModalProps, Modal, SetUrlState, TextInput } from "@ncs/web-legos"

import { QuickOrdersTabs, QuickOrdersUrlState } from "./QuickOrders"

export interface EditQuickOrderModalProps extends ExtendableModalProps {
	order: QuickOrder | null
	isNew: boolean
	setUrlState: SetUrlState<QuickOrdersUrlState>
}

export const EditQuickOrderModal: FC<EditQuickOrderModalProps> = ({
	order,
	isNew,
	setUrlState,
	...rest
}) => {
	const [name, setName] = useState<string | null>(null)
	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const create = useCreateQuickOrder()
	const update = useUpdateQuickOrder()

	const onSave = async () => {
		if (!name) return

		try {
			setIsSaving(true)
			if (isNew) {
				const response = await create({
					description: name,
				})
				const { quickOrderId } = unpythonify(response.data)
				setUrlState({ orderId: quickOrderId.toString(), tab: QuickOrdersTabs.Products })
			} else if (order) {
				await update({
					updates: {
						quickOrderId: order.id,
						description: name,
					},
				})
			}
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const reset = () => {
		setName(isNew ? null : order?.description ?? null)
		setIsSaving(false)
		setErrorText(null)
	}

	return (
		<Modal
			title={isNew ? "Create New Quick Order" : "Edit Quick Order"}
			{...rest}
			onOpen={reset}
			rightButtons={{
				buttonText: isNew ? "Create" : "Update",
				onClick: onSave,
				disabled: !name,
				isLoading: isSaving,
			}}
			errorText={errorText}
		>
			<TextInput
				value={name}
				onChange={setName}
				label="Quick Order name"
				maxLength={255}
				autoFocus
			/>
		</Modal>
	)
}

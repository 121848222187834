import React, { Component } from "react"

import withStyles from "@material-ui/core/styles/withStyles"
import { compose } from "redux"

import {
	deleteDocument,
	generateDocumentLink,
	listDocuments,
} from "@ncs/mortar/redux/services/ticketing"
import ExcelIcon from "@ncs/bricks/assets/img/ExcelIcon.svg"
import PDFIcon from "@ncs/bricks/assets/img/PDFIcon.svg"
import WordIcon from "@ncs/bricks/assets/img/WordIcon.svg"

import {
	Button,
	ConditionalContent,
	Dialog,
	GridContainer,
	GridItem,
	InputAsDisplay,
	withCallApi,
} from "~/components"

import TicketDocumentUploadContainer from "./TicketDocumentUploadContainer"

import AttachFile from "@material-ui/icons/AttachFile"
import CloudDownload from "@material-ui/icons/CloudDownload"
import Delete from "@material-ui/icons/Delete"
import Remove from "@material-ui/icons/Remove"
import Videocam from "@material-ui/icons/Videocam"

const styles = {
	right: {
		textAlign: "right",
	},
	center: {
		textAlign: "center",
	},
	previewIcon: {
		width: 75,
		height: 75,
	},
	previewImage: {
		maxWidth: "75px",
		maxHeight: "100px",
		height: "auto",
		width: "auto",
	},
}

class TicketDocuments extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isAdding: false,
			documents: [],
		}
	}

	componentDidMount = () => {
		this.fetchDocuments()
	}

	handleAddDocument = () => {
		this.setState({ isAdding: true })
	}

	handleDocumentUploaded = (file) => {
		this.fetchDocuments()
	}

	fetchDocuments = () => {
		let params = {}
		if (this.props.isComment === true) {
			params = { comment_id: this.props.relatedId }
		} else {
			params = { ticket_id: this.props.relatedId }
		}
		this.props.callApi(listDocuments(params)).then((result) => {
			this.setState({
				documents: result.payload,
			})
		})
	}

	handleGenerateLink = (item) => {
		this.setState({ isGeneratingLink: true })
		this.props.callApi(generateDocumentLink(item.id)).then(({ payload: result }) => {
			this.setState({
				isGeneratingLink: false,
				generatedLink: result.presign_url,
			})
		})
	}

	handleDownload = (url) => {
		window.open(url, "_blank")
	}

	handleDeleteDocument = (deletingDocument) => {
		this.setState({ isConfirmingDelete: true, deletingDocument })
	}

	handleCancelDelete = () => {
		this.setState({
			isSaving: false,
			isConfirmingDelete: false,
			deletingDocument: null,
		})
	}

	handleConfirmDelete = (confirmationCallback) => {
		if (!this.state.deletingDocument || !this.state.deletingDocument.id) {
			return
		}

		this.setState({ isSaving: true })
		this.props.callApi(deleteDocument(this.state.deletingDocument.id)).then(() => {
			this.handleCancelDelete()

			if (typeof confirmationCallback === "function") {
				confirmationCallback()
			}

			// refresh data after deleting
			this.fetchDocuments()
		})
	}
	generatePreview = (item) => {
		let name = (item.document_type_name || "").toLowerCase()
		if (name === "image") {
			return (
				<img
					src={item.presign_url}
					title={`${item.filename}.${item.extension}`}
					alt={`${item.filename}.${item.extension}`}
					className={this.props.classes.previewImage}
				/>
			)
		}

		if (name === "video") {
			return <Videocam className={this.props.classes.previewIcon} />
		}

		let extension = (item.extension || "").toLowerCase()
		switch (extension) {
			case "xls":
			case "xlsx":
				return <img height={75} src={ExcelIcon} alt="Excel Document" />
			case "docx":
			case "doc":
				return <img height={75} src={WordIcon} alt="Word Document" />
			case "pdf":
				return <img height={75} src={PDFIcon} alt="PDF Document" />
			default:
				return <AttachFile className={this.props.classes.previewIcon} />
		}
	}

	render() {
		const { isConfirmingDelete, isSaving, deletingDocument } = this.state
		const { canEdit } = this.props
		return (
			<React.Fragment>
				<ConditionalContent show={this.state.documents.length > 0}>
					<GridContainer>
						<GridItem xs={12}>
							<h3>Documents</h3>
							<GridContainer>
								{this.state.documents.map((document) => (
									<React.Fragment key={document.filename}>
										<GridItem xs={8}>{document.filename}</GridItem>
										<GridItem xs={4}>
											<Button
												justIcon
												round
												className="edit"
												color="info"
												size="sm"
												title="Preview"
												onClick={(e) => {
													e.stopPropagation()
													this.handleDownload(document.presign_url)
												}}
											>
												<CloudDownload />
											</Button>
											<ConditionalContent show={canEdit}>
												<Button
													justIcon
													round
													color="danger"
													size="sm"
													title="Delete"
													onClick={(e) => {
														e.stopPropagation()
														this.handleDeleteDocument(document)
													}}
												>
													<Remove />
												</Button>
											</ConditionalContent>
										</GridItem>
									</React.Fragment>
								))}
							</GridContainer>
						</GridItem>
					</GridContainer>
				</ConditionalContent>
				<ConditionalContent show={canEdit}>
					<TicketDocumentUploadContainer
						onDocumentUploaded={this.handleDocumentUploaded}
						isTicket={this.props.isTicket}
						isComment={this.props.isComment}
						relatedId={this.props.relatedId}
						onSuccess={this.fetchDocuments}
					/>
				</ConditionalContent>

				<Dialog
					warning="true"
					show={isConfirmingDelete}
					title={"Are you sure you want to permanently remove this item?"}
					confirmBtnIcon={<Delete />}
					confirmBtnText="Confirm Deletion"
					confirmBtnColor="danger"
					onConfirm={this.handleConfirmDelete}
					isConfirmationAsync={true}
					cancelBtnText={"Cancel"}
					onCancel={this.handleCancelDelete}
					successTitle="Success"
					successContent="Document was removed."
					successConformColor="success"
					onSuccessConfirm={this.handleCancelDelete}
					isSaving={isSaving}
				>
					{isConfirmingDelete && (
						<GridContainer className={this.props.classes.leftAlignedModal}>
							<GridItem xs={12} sm={4}>
								{this.generatePreview(deletingDocument)}
							</GridItem>
							<GridItem xs={12} sm={8}>
								<InputAsDisplay
									labelText="Filename"
									value={`${deletingDocument.filename}`}
								/>
							</GridItem>
						</GridContainer>
					)}
				</Dialog>
			</React.Fragment>
		)
	}
}

export default compose(withStyles(styles), withCallApi)(TicketDocuments)

import { Dispatch, ReactElement, SetStateAction, useMemo } from "react"

import { PosPartRelationshipSelector } from "../selectors"

export interface PosPartRelationshipQueryParams {
	parent: boolean | null
	child: boolean | null
}

export interface PosPartRelationshipQueryFilterProps<
	QueryParamState extends PosPartRelationshipQueryParams,
> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

export const PosPartRelationshipQueryFilter = <
	QueryParamState extends PosPartRelationshipQueryParams,
>({
	queryParamState,
	setQueryParamState,
}: PosPartRelationshipQueryFilterProps<QueryParamState>): ReactElement => {
	const handleChange = (newValue: string | null) => {
		setQueryParamState((prev) => ({
			...prev,
			parent: newValue === "is_parent" || null,
			child: newValue === "is_child" || null,
		}))
	}

	const value = useMemo(() => {
		return (
			queryParamState.parent ? "is_parent"
			: queryParamState.child ? "is_child"
			: null
		)
	}, [queryParamState.parent, queryParamState.child])

	return (
		<PosPartRelationshipSelector
			value={value}
			onChange={handleChange}
			fillContainer
			disableNoSelectionOption={false}
		/>
	)
}

import React from "react"
import { BaseSelectFilter, withCallApi, withDynamicTableFilter } from "@ncs/bricks/components"
import { listTerritoriesForRegion } from "../../redux/services/lookups"

class TerritoryFilter extends React.PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			territories: [],
		}
	}

	componentDidMount() {
		this.loadTerritories()
	}

	// load Territories whenever the region changes
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (
			this.getRegionFromReportState(prevProps) !== this.getRegionFromReportState(this.props)
		) {
			this.loadTerritories()
		}
	}

	getRegionFromReportState = (props) => {
		return (((props.reportState || {}).filtered || []).find((x) => x.id === "region") || {})
			.value
	}

	loadTerritories = () => {
		let region = this.getRegionFromReportState(this.props)

		this.props.callApi(listTerritoriesForRegion(region)).then(({ payload: territories }) => {
			this.setState({ territories })

			// if the currently selected territory is not in the results, erase the selected territory
			if (
				this.props.value &&
				!territories.some((t) => t._id.toString() === this.props.value)
			) {
				this.props.onChange("")
			}
		})
	}

	render() {
		return (
			<BaseSelectFilter
				id="territory"
				labelText="Territory"
				options={[
					{ _id: "", description: "All Territories" },
					...this.state.territories,
					{ _id: 2, description: "NCS Default", code: "TC" },
				]}
				valueAccessor="_id"
				textAccessor={(x) => (x._id ? `${x.description} (${x.code})` : x.description)}
				{...this.props}
			/>
		)
	}
}

export default withCallApi(withDynamicTableFilter("territory")(TerritoryFilter))

import { ReactElement, useEffect, useState } from "react"

import {
	Control,
	Controller,
	FieldError,
	FieldValues,
	Path,
	PathValue,
	UnpackNestedValue,
} from "react-hook-form"

import { Customer } from "@ncs/ncs-api"

import { usePrevious } from "../../util"
import { CustomerSelector, CustomerSelectorProps } from "../selectors"

export interface CustomerSelectorFormFieldProps<TFieldValues extends FieldValues>
	extends Omit<CustomerSelectorProps, "value" | "onChange"> {
	control: Control<TFieldValues>
	name: Path<TFieldValues>
	emptyValueFallback?: string | null
}

export const CustomerSelectorFormField = <TFieldValues extends FieldValues>({
	name,
	control,
	...rest
}: CustomerSelectorFormFieldProps<TFieldValues>): ReactElement => {
	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { onBlur, value, onChange }, fieldState: { error } }) => {
				return (
					<CustomerSelectorFormFieldRender
						{...rest}
						formValue={value}
						formOnChange={onChange}
						formOnBlur={onBlur}
						formError={error}
					/>
				)
			}}
		/>
	)
}

interface CustomerSelectorFormFieldRenderProps<TFieldValues extends FieldValues>
	extends Omit<CustomerSelectorProps, "value" | "onChange"> {
	formValue: UnpackNestedValue<PathValue<TFieldValues, Path<TFieldValues>>>
	formOnChange: (newValue: string | null) => void
	formOnBlur?: () => void
	formError?: FieldError
	emptyValueFallback?: string | null
}

const CustomerSelectorFormFieldRender = <TFieldValues extends FieldValues>({
	formValue,
	formOnChange,
	formOnBlur,
	formError,
	emptyValueFallback = null,
	...rest
}: CustomerSelectorFormFieldRenderProps<TFieldValues>): ReactElement => {
	const [selectedSite, setSelectedCustomer] = useState<Customer | null>(null)

	// Listen for the reset coming from above.
	// Should reset on anything falsy (including an empty string).
	const prevFormValue = usePrevious(formValue)
	useEffect(() => {
		if (!formValue && !!prevFormValue && !!selectedSite) {
			setSelectedCustomer(null)
		}
	}, [formValue, prevFormValue, selectedSite])

	return (
		<CustomerSelector
			{...rest}
			initialCustomerId={!formValue ? null : formValue}
			onBlur={formOnBlur}
			value={selectedSite}
			onChange={(newValue) => {
				// Change in the form
				formOnChange(newValue?.id ?? emptyValueFallback)
				// Change in local selections tate.
				setSelectedCustomer(newValue)
			}}
			error={formError?.message}
		/>
	)
}

import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { makeStyles } from "@material-ui/core"

import footerStyle from "@ncs/bricks/assets/jss/material-dashboard-pro-react/components/footerStyle"

const useStyles = makeStyles(footerStyle)

function Footer({ fluid, white }) {
	const classes = useStyles()

	var container = cx({
		[classes.container]: !fluid,
		[classes.containerFluid]: fluid,
		[classes.whiteColor]: white,
	})
	var anchor =
		classes.a +
		cx({
			[" " + classes.whiteColor]: white,
		})
	return (
		<footer className={classes.footer}>
			<div className={container}>
				<p className={classes.right}>
					&copy; {1900 + new Date().getYear()}{" "}
					<a href="https://www.ncswash.com" className={anchor}>
						{"National Carwash Solutions"}
					</a>
				</p>
			</div>
		</footer>
	)
}

Footer.propTypes = {
	fluid: PropTypes.bool,
	white: PropTypes.bool,
	rtlActive: PropTypes.bool,
}

export default Footer

import { FC, useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"

import {
	ChemicalCustomerDetail,
	makeApiErrorMessage,
	useInventoryPart,
	useUpdateContainerChemical,
} from "@ncs/ncs-api"
import { displayNumber, formatNumber } from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	Box,
	Button,
	GridContainer,
	GridItem,
	InventoryPartSelectorFormField,
	LabeledData,
	NumericInputFormField,
	PartImage,
	TextInputFormField,
	useToast,
} from "@ncs/web-legos"

import {
	ChemicalSettingsForm,
	ChemicalSettingsFormSchema,
	defaultChemicalSettingsFormValues,
	makeChemicalSettingsFormState,
	makeContainerChemicalUpdatePatch,
} from "../container-detail-modal-util"

export interface ChemicalSettingsTabProps {
	container: ChemicalCustomerDetail["containers"][number]
}

export const ChemicalSettingsTab: FC<ChemicalSettingsTabProps> = ({ container }) => {
	const { makeSuccessToast, makeErrorToast } = useToast()
	const [isEditing, setIsEditing] = useState(false)
	const [isSaving, setIsSaving] = useState(false)

	const updateSettings = useUpdateContainerChemical()

	const { control, reset, handleSubmit, watch } = useForm<ChemicalSettingsForm>({
		resolver: zodResolver(ChemicalSettingsFormSchema),
		defaultValues: { ...defaultChemicalSettingsFormValues },
	})

	const [partId] = watch(["partId"])

	const [partDetails, partDetailsLoading] = useInventoryPart(partId)

	const handleSave = async (form: ChemicalSettingsForm) => {
		try {
			setIsSaving(true)
			const updates = makeContainerChemicalUpdatePatch(form)
			await updateSettings({
				id: container.id,
				updates,
			})
			makeSuccessToast("Settings updated")
			setIsEditing(false)
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		} finally {
			setIsSaving(false)
		}
	}

	return (
		<Box
			display="flex"
			justifyContent="space-between"
			alignItems="flex-start"
			columnGap={1}
			xsProps={{ display: "block" }}
		>
			<Box width="100%">
				<GridContainer mb={2} rowGap={0}>
					{!isEditing ?
						<>
							<GridItem xs={12}>
								<LabeledData label="Name">
									{container.name || "(unnamed)"}
								</LabeledData>
							</GridItem>
							<GridItem xs={12} sm={6}>
								<LabeledData label="Part #">{container.partNumber}</LabeledData>
							</GridItem>
							<GridItem xs={12} sm={6}>
								<LabeledData label="Part">{container.partDescription}</LabeledData>
							</GridItem>
							<GridItem xs={12} sm={6}>
								<LabeledData label="Low level trigger (gallons)">
									{formatNumber(container.lowLevelGallons)}
								</LabeledData>
							</GridItem>
							<GridItem xs={12} sm={6}>
								<LabeledData label="Max back stock quantity">
									{displayNumber(container.maxBackStockQuantity)}
								</LabeledData>
							</GridItem>
							<GridItem xs={12} sm={6}>
								<LabeledData label="Pump setting">
									{container.pumpSetting || "-"}
								</LabeledData>
							</GridItem>
							<GridItem xs={12} sm={6}>
								<LabeledData label="Tip type">
									{container.tipType || "-"}
								</LabeledData>
							</GridItem>

							<GridItem xs={12} sm={6}>
								<LabeledData label="Expected mL usage">
									{displayNumber(container.expectedMlUsage)}
								</LabeledData>
							</GridItem>
						</>
					:	<>
							<GridItem xs={12}>
								<TextInputFormField
									control={control}
									name="name"
									label="Name for this chemical in this container"
									fillContainer
								/>
							</GridItem>
							<GridItem xs={12}>
								<InventoryPartSelectorFormField
									control={control}
									name="partId"
									label="Part"
									fillContainer
									emptyValueFallback=""
								/>
							</GridItem>
							<GridItem xs={12} sm={6}>
								<NumericInputFormField
									control={control}
									name="lowLevelGallons"
									label="Low level trigger (gallons)"
									placeholder="Low level trigger..."
									emptyValueFallback=""
									returnValueAsString
								/>
							</GridItem>
							<GridItem xs={12} sm={6}>
								<NumericInputFormField
									control={control}
									name="maxBackStockQuantity"
									label="Max back stock quantity"
									emptyValueFallback=""
									returnValueAsString
								/>
							</GridItem>
							<GridItem xs={12} sm={6}>
								<TextInputFormField
									control={control}
									name="pumpSetting"
									label="Pump setting"
									emptyValueFallback={null}
								/>
							</GridItem>
							<GridItem xs={12} sm={6}>
								<TextInputFormField
									control={control}
									name="tipType"
									label="Tip type"
									emptyValueFallback={null}
								/>
							</GridItem>
							<GridItem xs={12} sm={6}>
								<NumericInputFormField
									control={control}
									name="expectedMlUsage"
									label="Expected mL usage"
									emptyValueFallback=""
									returnValueAsString
								/>
							</GridItem>
						</>
					}
				</GridContainer>

				{isEditing ?
					<AnimatedEntrance show direction="down">
						<Button
							variant="primary-cta"
							containerProps={{ mr: 2 }}
							isLoading={isSaving}
							onClick={handleSubmit(handleSave)}
						>
							Save changes
						</Button>
						<Button
							onClick={() => {
								setIsEditing(false)
								reset(makeChemicalSettingsFormState(container))
							}}
							icon="times"
							disabled={isSaving}
						>
							Cancel
						</Button>
					</AnimatedEntrance>
				:	<Button
						icon="pencil"
						onClick={() => {
							setIsEditing(true)
							reset(makeChemicalSettingsFormState(container))
						}}
					>
						Edit chemical settings
					</Button>
				}
			</Box>

			<PartImage src={partDetails?.imageUrl} isLoading={partDetailsLoading} maxWidth={15} />
		</Box>
	)
}

import { FC, useEffect, useMemo, useState } from "react"

import { css } from "@emotion/react"

import {
	APPLICATION,
	InventoryPart,
	useInventoryPart,
	useInventoryParts,
	useUserCanUse,
} from "@ncs/ncs-api"
import {
	Box,
	Button,
	Callout,
	Card,
	Icon,
	LoadingSpinner,
	Paragraph,
	PartSelector,
	RecentItems,
	Tabs,
	useChangeCallback,
	useUrlState,
} from "@ncs/web-legos"

import { PageTitle } from "~/layouts/PageTitle"

import PartDetailsTab from "./PartDetailsTab"
import PartHistoryTab from "./PartHistoryTab"
import PartLevelsTab from "./PartLevelsTab"
import { PartRestrictionsTab } from "./PartRestrictionsTab"
import { PrintPartLabelModal } from "./PrintPartLabelModal"
import { ReplacementPartModal } from "./ReplacePartModal"

export enum PartManagementTab {
	PartDetails = "Part Details",
	PartLevels = "Part Levels",
	TransactionHistory = "Transaction History",
	Restrictions = "Restrictions",
}

export type PartManagementUrlState = {
	partId: string | null
	tab: string | null
	partNumberRedirect: string | null
}

const PartManagement: FC = () => {
	const canReplacePart = useUserCanUse(APPLICATION.PartReplacement)
	const [partSelectorPart, setPartSelectorPart] = useState<InventoryPart | null>(null)
	const [{ partId, tab, partNumberRedirect }, { setUrlState }] =
		useUrlState<PartManagementUrlState>({
			partId: null,
			tab: PartManagementTab.PartDetails,
			partNumberRedirect: null,
		})
	// `selectedPart` will stay up-to-date even after calls to backend have changed the part.
	const [selectedPart] = useInventoryPart(partSelectorPart?.id)
	const [showLabelPrintModal, setShowLabelPrintModal] = useState(false)
	const [partToReplace, setPartToReplace] = useState<InventoryPart | null>(null)

	// Handle going from just a part number in the URL to its actual part ID.
	const { data: partNumberRedirectSearchResults, isLoading: partNumberRedirectLoading } =
		useInventoryParts({
			params: {
				search: partNumberRedirect,
				allParts: true,
				skipRestrictedCheck: true,
			},
			queryConfig: {
				enabled: !!partNumberRedirect,
			},
		})

	useEffect(() => {
		if (partNumberRedirect && partNumberRedirectSearchResults.length) {
			const destinationPart = partNumberRedirectSearchResults.find(
				(p) => p.partNumber === partNumberRedirect
			)

			if (destinationPart) {
				setPartSelectorPart(destinationPart)
			}
		}
	}, [partNumberRedirect, partNumberRedirectSearchResults])

	// Keep the URL up to date with what's in the selector.
	useChangeCallback(partSelectorPart?.id, (newPartSelectorPartId) => {
		setUrlState((prev) => ({
			...prev,
			partId: newPartSelectorPartId ?? null,
			// Since we're changing based on what's happening in the selector, we should also
			// clear out the redirect if it was there.
			partNumberRedirect: null,
		}))
	})

	const unsellable = useMemo(() => {
		return (
			selectedPart?.status === "D" ||
			selectedPart?.isActive === false ||
			selectedPart?.isService === false
		)
	}, [selectedPart?.status, selectedPart?.isActive, selectedPart?.isService])

	if (partNumberRedirectLoading) {
		return <LoadingSpinner />
	}

	return (
		<Card
			heading={
				selectedPart ?
					`(${selectedPart.partNumber}) ${selectedPart.description}`
				:	"Part Management"
			}
			headingIcon="wrench"
			renderRight={() =>
				selectedPart ?
					<Box
						display="flex"
						gap={2}
						alignItems="flex-start"
						mdProps={{ flexDirection: "column" }}
					>
						{canReplacePart && (
							<Button
								icon="rotate-exclamation"
								onClick={() => setPartToReplace(selectedPart)}
							>
								Replace this part
							</Button>
						)}
						<Button icon="barcode-read" onClick={() => setShowLabelPrintModal(true)}>
							Create part label
						</Button>
						{unsellable && (
							<Callout icon="ban" variant="warning">
								<Paragraph bold>Part should not be sold because:</Paragraph>
								<ul css={unsellableListCss}>
									{selectedPart?.status === "D" && <li>KBM status is D</li>}
									{selectedPart?.isActive === false && <li>Inactive status</li>}
									{selectedPart?.isService === false && (
										<li>Not a service part</li>
									)}
								</ul>
							</Callout>
						)}
					</Box>
				:	undefined
			}
		>
			{!!selectedPart && (
				<PageTitle
					title={`(${selectedPart.partNumber}) ${selectedPart.description} | Part Management`}
				/>
			)}

			<Box mb={4}>
				<PartSelector
					value={partSelectorPart}
					onChange={setPartSelectorPart}
					maxWidth={40}
					initialPartId={partId}
					label="Select part"
					includeNonService
					skipRestrictedCheck
				/>

				<RecentItems
					item={selectedPart}
					itemLabelAccessor={(item) => item.description}
					onSelect={(item) => setPartSelectorPart(item)}
					compareFn={(a, b) => a.id === b.id}
				/>
			</Box>

			<Tabs
				currentTab={tab ?? PartManagementTab.PartDetails}
				onChange={(newTab) =>
					setUrlState((prev) => ({
						...prev,
						tab: newTab,
					}))
				}
				disabled={!selectedPart}
				panels={[
					{
						navLabel: PartManagementTab.PartDetails,
						navIcon: <Icon icon="wrench" />,
						component: selectedPart ? <PartDetailsTab part={selectedPart} /> : <div />,
					},
					{
						navLabel: PartManagementTab.PartLevels,
						navIcon: <Icon icon="chart-simple" />,
						component: selectedPart ? <PartLevelsTab part={selectedPart} /> : <div />,
					},
					{
						navLabel: PartManagementTab.TransactionHistory,
						navIcon: <Icon icon="history" />,
						component: selectedPart ? <PartHistoryTab part={selectedPart} /> : <div />,
					},
					{
						navLabel: PartManagementTab.Restrictions,
						navIcon: <Icon icon="lock" />,
						component:
							selectedPart ? <PartRestrictionsTab part={selectedPart} /> : <div />,
					},
				]}
			/>

			{!!partId && !selectedPart && <LoadingSpinner />}
			{showLabelPrintModal && !!selectedPart && (
				<PrintPartLabelModal
					part={selectedPart}
					onClose={() => setShowLabelPrintModal(false)}
				/>
			)}

			{!!partToReplace && (
				<ReplacementPartModal
					oldPart={partToReplace}
					onClose={() => setPartToReplace(null)}
				/>
			)}
		</Card>
	)
}

const unsellableListCss = css`
	margin: 0.5rem 0 0 1rem;
	padding: 0;
`

export default PartManagement

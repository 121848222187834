import { FC, useMemo } from "react"

import { Cell, Column } from "react-table"

import {
	defaultInventoryTransactionsQueryParams,
	InventoryLocation,
	InventoryTransaction,
	InventoryTransactionQueryParams,
	useExportInventoryTransactions,
	useInventoryTransactions,
} from "@ncs/ncs-api"
import { formatDateTime, getTimezoneAbbreviation, Typify } from "@ncs/ts-utils"
import {
	Box,
	EmptyValueDash,
	encodeUrlState,
	GridContainer,
	GridItem,
	GridItemProps,
	Icon,
	InventoryPartQueryFilter,
	Link,
	LocationBinQueryFilter,
	SearchQueryFilter,
	Table,
	usePaginationUrlState,
	useUrlState,
} from "@ncs/web-legos"

import {
	DispatchDetailTab,
	DispatchDetailUrlState,
} from "~/views/Service/Dispatches/DispatchDetail"

import { LocationsTab, LocationsUrlState } from "../../Locations"

export interface LocationTransactionsTabProps {
	location: InventoryLocation
}

export type LocationTransactionsUrlState = Typify<InventoryTransactionQueryParams>

export const LocationTransactionsTab: FC<LocationTransactionsTabProps> = ({ location }) => {
	const [queryParams, { setUrlState }] = useUrlState<LocationTransactionsUrlState>({
		...defaultInventoryTransactionsQueryParams,
	})
	const [pagination, setPagination] = usePaginationUrlState()
	const combinedParams = useMemo(
		() => ({
			...queryParams,
			location: location.id,
		}),
		[location.id, queryParams]
	)
	const query = useInventoryTransactions({
		manualPagination: true,
		params: { ...combinedParams, page: pagination.page, pageSize: pagination.pageSize },
	})
	const dataExport = useExportInventoryTransactions(combinedParams)

	const columns: Column<InventoryTransaction>[] = useMemo(() => {
		return [
			{
				Header: "Direction",
				accessor: "direction",
				disableSortBy: true,
				Cell: ({ row: { original } }: Cell<InventoryTransaction>) => {
					const { direction } = original
					let icon = <EmptyValueDash />

					if (direction === "transfer") icon = <Icon icon="exchange-alt" color="gray" />
					if (direction === "in") icon = <Icon icon="inbox-in" color="success" />
					if (direction === "out") icon = <Icon icon="inbox-out" color="error" />

					return (
						<Box mt={0.25} ml={0.25}>
							{icon}
						</Box>
					)
				},
			},
			{
				Header: `Date (${getTimezoneAbbreviation()})`,
				id: "transactionDate",
				accessor: (original) => formatDateTime(original.transactionDate),
			},
			{
				Header: "Part #",
				id: "part_PartNumber",
				accessor: "partNumber",
			},
			{
				Header: "Part name",
				accessor: "partDescription",
			},
			{
				Header: "Type",
				id: "transactionType_Description",
				accessor: "type",
			},
			{
				Header: "From location",
				id: "fromBin_Location_LocationCode",
				accessor: (original) => original.fromBin?.locationId,
				Cell: ({
					row: {
						original: { fromBin },
					},
				}: Cell<InventoryTransaction>) => {
					return (
						fromBin ?
							fromBin.locationId.toString() !== location.id ?
								<Link
									newTab
									icon="external-link"
									to={`/inventory/locations${encodeUrlState<LocationsUrlState>({
										tab: LocationsTab.Transactions,
										urlLocationId: fromBin.locationId.toString(),
									})}`}
								>
									({fromBin.location.locationCode}){" "}
									{fromBin.location.description}
								</Link>
							:	`(${fromBin.location.locationCode}) ${fromBin.location.description}`
						:	<EmptyValueDash />
					)
				},
			},
			{
				Header: "From bin",
				id: "fromBin_Code",
				accessor: (original) => original.fromBin?.code,
				Cell: ({ row: { original } }: Cell<InventoryTransaction>) =>
					original.fromBin ? original.fromBin.code : <EmptyValueDash />,
			},
			{
				Header: "To location",
				id: "toBin_Location_LocationCode",
				accessor: (original) => original.toBin?.locationId,
				Cell: ({
					row: {
						original: { toBin },
					},
				}: Cell<InventoryTransaction>) => {
					return (
						toBin ?
							toBin.locationId.toString() !== location.id ?
								<Link
									newTab
									icon="external-link"
									to={`/inventory/locations${encodeUrlState<LocationsUrlState>({
										tab: LocationsTab.Transactions,
										urlLocationId: toBin.locationId.toString(),
									})}`}
								>
									({toBin.location.locationCode}) {toBin.location.description}
								</Link>
							:	`(${toBin.location.locationCode}) ${toBin.location.description}`
						:	<EmptyValueDash />
					)
				},
			},
			{
				Header: "To bin",
				id: "toBin_Code",
				accessor: (original) => original.toBin?.code,
				Cell: ({ row: { original } }: Cell<InventoryTransaction>) =>
					original.toBin ? original.toBin.code : <EmptyValueDash />,
			},
			{
				Header: "Transaction Qty",
				accessor: "quantity",
			},
			{
				Header: "Created by",
				id: "createdBy_FirstName",
				accessor: (original) => original.createdBy.name,
				hiddenByDefault: true,
			},
			{
				Header: "Part Order #",
				accessor: "partOrderNumber",
				hiddenByDefault: true,
				disableSortBy: true,
			},
			{
				Header: "Work Order #",
				accessor: "workOrderNumber",
				hiddenByDefault: true,
				disableSortBy: true,
				Cell: ({ row: { original } }: Cell<InventoryTransaction>) => {
					return original.workOrderNumber && original.dispatchId ?
							<Link
								newTab
								icon="external-link"
								to={`/service/dispatches/${
									original.dispatchId
								}${encodeUrlState<DispatchDetailUrlState>({
									tab: DispatchDetailTab.WorkOrders,
								})}`}
							>
								{original.workOrderNumber}
							</Link>
						:	<EmptyValueDash />
				},
			},
			{
				Header: "Purchase Order #",
				accessor: "purchaseOrderId",
				hiddenByDefault: true,
				disableSortBy: true,
				Cell: ({ row: { original } }: Cell<InventoryTransaction>) => {
					return original.purchaseOrderId ?
							<Link
								newTab
								icon="external-link"
								to={`/purchase-orders/${original.purchaseOrderId}`}
							>
								{original.purchaseOrderId}
							</Link>
						:	<EmptyValueDash />
				},
			},
			{
				Header: "Comment",
				accessor: "comment",
				hiddenByDefault: true,
				disableSortBy: true,
				Cell: ({ row: { original } }: Cell<InventoryTransaction>) =>
					original.comment ? original.comment : <EmptyValueDash />,
			},
		]
	}, [location.id])

	const queryFilterGridItemProps: GridItemProps = {
		xs: 12,
		sm: 6,
		md: 4,
		lg: 3,
	}
	const queryFilterProps = {
		queryParamState: queryParams,
		setQueryParamState: setUrlState,
	}

	return (
		<>
			<GridContainer>
				<GridItem {...queryFilterGridItemProps}>
					<SearchQueryFilter {...queryFilterProps} />
				</GridItem>
				<GridItem {...queryFilterGridItemProps}>
					<LocationBinQueryFilter {...queryFilterProps} locationId={location.id} />
				</GridItem>
				<GridItem {...queryFilterGridItemProps}>
					<InventoryPartQueryFilter {...queryFilterProps} />
				</GridItem>
			</GridContainer>

			<Table
				query={query}
				columns={columns}
				queryParamState={queryParams}
				setQueryParamState={setUrlState}
				dataExport={dataExport}
				pagination={pagination}
				setPagination={setPagination}
			/>
		</>
	)
}

import { FC, useState } from "react"

import { css } from "@emotion/react"

import { BrandCard, makeApiErrorMessage, useSetBrandCardOrder } from "@ncs/ncs-api"
import { extractNumber, moveListItem } from "@ncs/ts-utils"
import { Box, ExtendableModalProps, Heading, Modal, Paragraph, useToast } from "@ncs/web-legos"

export interface ArrangeModalProps extends ExtendableModalProps {
	cards: BrandCard[] // Only pass in the active cards.
}

export const ArrangeModal: FC<ArrangeModalProps> = ({ cards, ...rest }) => {
	const { makeSuccessToast } = useToast()
	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)
	const [orderState, setOrderState] = useState(cards.map((card) => card.id))

	const saveNewOrder = useSetBrandCardOrder()

	const onChange = (currentIndex: number, selectedIndex: string) => {
		setOrderState((prev) => moveListItem(currentIndex, extractNumber(selectedIndex), prev))
	}

	const onSave = async () => {
		try {
			setIsSaving(true)
			await saveNewOrder({
				cards: orderState,
			})
			makeSuccessToast("Card order updated")
			rest.onClose()
		} catch (e) {
			setErrorText(makeApiErrorMessage(e))
			setIsSaving(false)
		}
	}

	const reset = () => {
		setIsSaving(false)
		setErrorText(null)
		setOrderState(cards.map((card) => card.id))
	}

	return (
		<Modal
			title="Rearrange Brand Cards"
			onOpen={reset}
			{...rest}
			closeButtonText="Cancel"
			rightButtons={{
				buttonText: "Update Order",
				onClick: onSave,
				isLoading: isSaving,
			}}
			errorText={errorText}
		>
			<Paragraph mb={2}>
				Edit the order in which brand cards display for customers.
			</Paragraph>

			<Box display="flex" mb={0.5}>
				<Heading variant="h6" bold width={3.8}>
					Rank
				</Heading>
				<Heading variant="h6" bold>
					Card
				</Heading>
			</Box>

			{orderState.map((id, i) => {
				const card = cards.find((c) => c.id === id)
				if (!card) return null

				return (
					<Box key={card.id} display="flex" alignItems="center" columnGap={1} mb={0.5}>
						<select value={i} onChange={(e) => onChange(i, e.target.value)}>
							{cards.map((c, j) => (
								<option key={`${card.id}${j}`} value={j}>
									{j + 1}
								</option>
							))}
						</select>
						<Box width={6}>
							<img
								src={card.logoImageUrl}
								css={css`
									height: 1rem;
								`}
								alt={`Logo for ${card.name}`}
							/>
						</Box>
						<Paragraph bold ml={1}>
							{card.name}
						</Paragraph>
					</Box>
				)
			})}
		</Modal>
	)
}

import { FC } from "react"

import { titleCase } from "@ncs/ts-utils"
import { ExtendableSelectProps, Select } from "@ncs/web-legos"

export interface OrderLineTypeSelectorProps
	extends ExtendableSelectProps<(typeof options)[number]> {}

export const OrderLineTypeSelector: FC<OrderLineTypeSelectorProps> = ({ ...rest }) => {
	return (
		<Select
			label="Part families"
			{...rest}
			options={options}
			valueAccessor={({ value }) => value}
			textAccessor={({ value }) => titleCase(value)}
		/>
	)
}

const options = [
	{
		value: "chemical",
	},
	{
		value: "detail",
	},
	{
		value: "parts",
	},
]

import { FC, memo, useState } from "react"

import { useFormContext } from "react-hook-form"
import { useParams } from "react-router-dom"

import { PosPart, useOnlinePartRelations, usePosPart } from "@ncs/ncs-api"
import {
	AnimatedEntrance,
	Box,
	ErrorText,
	Link,
	Paragraph,
	PosPartSelector,
	RadioBooleanFormField,
	RadioGroupFormField,
	SkeletonRows,
	useChangeCallback,
} from "@ncs/web-legos"

import { ParentAttributeCallout } from "./ParentAttributeCallout"
import { PosPartForm, updateChildFormWithParent } from "./pos-products-utils"

export interface PosPartStatusProps {}

export const PosPartStatus: FC<PosPartStatusProps> = memo(() => {
	const { id } = useParams<{ id?: string }>()

	const [relations, relationsLoading] = useOnlinePartRelations(id, {
		queryConfig: {
			refetchOnWindowFocus: true,
		},
	})

	const {
		control,
		setValue,
		getValues,
		watch,
		reset,
		formState: { errors },
	} = useFormContext<PosPartForm>()

	const [relationship] = watch(["relationship"])

	// Store the selected parent part.
	const [selectedParentPart, setSelectedParentPart] = useState<PosPart | null>(null)

	// In most cases this will be redundant, but go and fetch again the part data
	// for the parent based on the selected ID. This lets us refetch on window focus.
	const [parentPart] = usePosPart(selectedParentPart?.id.toString() ?? null, {
		queryConfig: { refetchOnWindowFocus: true },
	})

	// As the parent part changes (or is removed), update the form to reflect
	// data from the parent, since the child will inherit some of its values from it.
	useChangeCallback(parentPart, (newParentPart) => {
		if (isChild) {
			reset({ ...updateChildFormWithParent(getValues(), newParentPart ?? null) })
		}
	})

	// What do we need to clear when you change relationship?
	// (Just be careful because the initial loading of the part does trigger the watch.)
	useChangeCallback(relationship, (newValue, prevValue) => {
		// Switching away from child...
		if (newValue !== "child" && prevValue === "child") {
			setSelectedParentPart(null)
		}
		// Switching to parent...
		if (newValue === "parent") {
			setValue("inventoryPartId", null, {
				shouldValidate: true,
			})
			setValue("posPartNumber", null, {
				shouldValidate: true,
			})
			setValue("price", null, {
				shouldValidate: true,
			})
		}
	})

	const isChild = relationship === "child"
	const isParent = relationship === "parent"

	return (
		<>
			<RadioBooleanFormField
				control={control}
				name="isActive"
				yesText="Active (default catalog)"
				noText="Inactive (opt-in only)"
				description={
					<>
						A product that is <strong>active</strong> is in the default customer
						catalog and visible to all customers by default. <strong>Inactive</strong>{" "}
						products are only visible to customers if it's specifically added to their
						catalog.
					</>
				}
			/>

			<RadioGroupFormField
				control={control}
				name="relationship"
				description={
					<>
						Is this product the <strong>parent</strong> or a <strong>child</strong> of
						another point of sale product? The parent/child relationship is used to
						make one customer-facing product page for multiple parts or chemicals. For
						example, the same chemical being sold in Cleanfill, 5 gallon, 55 gallon,
						etc.
					</>
				}
				options={[
					{
						label: "Standalone (default)",
						value: "standalone",
					},
					{
						label: "Parent",
						value: "parent",
					},
					{
						label: "Child",
						value: "child",
					},
				]}
			/>
			{isParent && (
				<AnimatedEntrance show mb={2}>
					<Box>
						<Paragraph>Current children include:</Paragraph>
						{relationsLoading && <SkeletonRows width="25%" />}
						{relations?.child.map((child) => (
							<div key={child.id}>
								<Link to={`/pos-products/product/${child.id}`}>{child.title}</Link>
							</div>
						))}
						{(relations?.child.length === 0 || !id) && (
							<Paragraph small color="secondary" mt={0.5}>
								(none yet)
							</Paragraph>
						)}
					</Box>
				</AnimatedEntrance>
			)}
			{isChild && (
				<AnimatedEntrance show mb={2.25} mt={-0.75}>
					<Box>
						<PosPartSelector
							value={selectedParentPart}
							onChange={setSelectedParentPart}
							params={{
								parent: true,
							}}
							label="Parent product"
							placeholder="Select parent product..."
							isLoading={relationsLoading}
							initialId={
								relations?.parent?.id !== id ? relations?.parent?.id : undefined
							}
						/>
						{!!errors.parentPartId?.message && (
							<ErrorText>{errors.parentPartId.message}</ErrorText>
						)}
					</Box>
				</AnimatedEntrance>
			)}

			<Paragraph>
				Is this a <strong>featured</strong> product? Featured products are listed
				prominently on the portal's Shop page.
			</Paragraph>
			<ParentAttributeCallout />
			<RadioBooleanFormField
				mb={0}
				control={control}
				name="isFeatured"
				noFirst
				noText="No (default)"
				disabledAccessor={() => isChild}
			/>
		</>
	)
})

PosPartStatus.displayName = "PosPartStatus"

import { FC, useState } from "react"

import {
	Contract,
	makeApiErrorMessage,
	useContractTypes,
	useUpdateOrganizationContract,
} from "@ncs/ncs-api"
import { addIfNew } from "@ncs/ts-utils"
import {
	CheckboxGroup,
	ExtendableModalProps,
	Modal,
	Paragraph,
	useChangeCallback,
	useToast,
} from "@ncs/web-legos"

export interface EditContractTypesModalProps extends ExtendableModalProps {
	contract: Contract
}

export const EditContractTypesModal: FC<EditContractTypesModalProps> = ({ contract, ...rest }) => {
	const { makeSuccessToast } = useToast()
	const [includedTypeIds, setIncludedTypeIds] = useState<string[]>([])
	const [errorText, setErrorText] = useState<string | null>(null)
	const [isSaving, setIsSaving] = useState(false)

	const [contractTypes] = useContractTypes()
	const updateContract = useUpdateOrganizationContract()

	const handleSave = async () => {
		try {
			if (!includedTypeIds.length) throw new Error("Choose at least 1 contract type")

			setIsSaving(true)
			await updateContract({
				updates: {
					contract: contract.id,
					types: includedTypeIds,
				},
			})
			makeSuccessToast("Contract types updated")
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	// Get the types currently checked in the contract.
	useChangeCallback(
		contract,
		(newContract) => setIncludedTypeIds(newContract.types.map((t) => t.id.toString())),
		{ callOnSetup: true }
	)

	const reset = () => {
		setIncludedTypeIds(contract.types.map((t) => t.id.toString()))
		setErrorText(null)
		setIsSaving(false)
	}

	return (
		<Modal
			{...rest}
			title="Contract Types"
			errorText={errorText}
			onOpen={reset}
			rightButtons={{
				buttonText: "Save",
				isLoading: isSaving,
				onClick: handleSave,
				disabled: !includedTypeIds.length,
			}}
		>
			<Paragraph mb={1}>Select which contract types apply to this contract.</Paragraph>
			<CheckboxGroup
				groupName="contract-types-modal-options"
				rows={contractTypes ?? []}
				checkedAccessor={(row) => includedTypeIds.includes(row.id)}
				valueAccessor="id"
				labelAccessor="description"
				onChange={(row, newState) => {
					if (newState) {
						setIncludedTypeIds((prev) => addIfNew(row.id, prev))
					} else {
						setIncludedTypeIds((prev) => prev.filter((prevId) => prevId !== row.id))
					}
				}}
			/>
		</Modal>
	)
}

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { Select } from "@ncs/bricks/components"
import { loadLookups, LOOKUPS } from "../../redux/services/lookups"

const NCSVintageSelector = ({
	displayEmpty,
	labelText,
	labelProps,
	selectProps,
	value,
	onChange,
}) => {
	const dispatch = useDispatch()
	const vintages = useSelector((store) => store.lookups.ncsEquipmentVintages)

	useEffect(() => {
		dispatch(loadLookups([LOOKUPS.NCSEquipmentVintages]))
	}, [dispatch])

	const handleChange = (vintageId) => {
		onChange(vintageId)
	}

	return (
		<Select
			id="vintages"
			labelText={labelText}
			value={value || ""}
			onChange={handleChange}
			valueAccessor="_id"
			textAccessor="name"
			options={[
				{
					_id: "",
					name: "Select a vintage",
				},
				...vintages,
			]}
			labelProps={{
				shrink: displayEmpty,
				...labelProps,
			}}
			selectProps={{
				displayEmpty,
				...selectProps,
			}}
		/>
	)
}

NCSVintageSelector.defaultProps = {
	displayEmpty: true,
	labelText: "Vintages",
}

NCSVintageSelector.propTypes = {
	value: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	displayEmpty: PropTypes.bool,
	labelProps: PropTypes.object,
	selectProps: PropTypes.object,
	labelText: PropTypes.string,
}

export default React.memo(NCSVintageSelector)

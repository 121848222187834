import { createContext, useContext } from "react"

import { Dayjs } from "dayjs"

import { BolBillTo, PartOrder, PartOrderShipment, UpdateShipmentPatch } from "@ncs/ncs-api"
import { ReactStateDispatch } from "@ncs/web-legos"

export interface ShipShipmentState {
	partOrder: PartOrder
	canAddFreightLine: boolean
	shipment: PartOrderShipment
	hasPackagePermission: boolean
	locationCanFedEx: boolean
	selectedCarrierId: string | null
	setSelectedCarrierId: ReactStateDispatch<string | null>
	newCarrierName: string | null
	setNewCarrierName: ReactStateDispatch<string | null>
	portalFedEx: boolean
	setPortalFedEx: ReactStateDispatch<boolean>
	usingPortalFedEx: boolean
	usingPackages: boolean
	setUsingPackages: ReactStateDispatch<boolean>
	packages: PackagesState
	setPackages: ReactStateDispatch<PackagesState>
	linePackages: LinePackagesState
	setLinePackages: ReactStateDispatch<LinePackagesState>
	verifyStepComplete: boolean
	setVerifyStepComplete: ReactStateDispatch<boolean>
	carrierStepComplete: boolean
	setCarrierStepComplete: ReactStateDispatch<boolean>
	packItemsStepComplete: boolean
	setPackItemsStepComplete: ReactStateDispatch<boolean>
	carrierSectionReady: boolean
	packItemsSectionReady: boolean
	configureSendSectionReady: boolean
	fedExMethod: FedExUiName
	setFedExMethod: ReactStateDispatch<FedExUiName>
	selectedBol: BolBillTo | null
	setSelectedBol: ReactStateDispatch<BolBillTo | null>
	trackingNumber: string | null
	setTrackingNumber: ReactStateDispatch<string | null>
	freightCharge: number | null
	setFreightCharge: ReactStateDispatch<number | null>
	addFreightToInvoice: boolean
	setAddFreightToInvoice: ReactStateDispatch<boolean>
	proNumber: string | null
	setProNumber: ReactStateDispatch<string | null>
	numberOfPallets: number | null
	setNumberOfPallets: ReactStateDispatch<number | null>
	scheduledShipDate: Dayjs | null
	setScheduledShipDate: ReactStateDispatch<Dayjs | null>
	geodis220ReceivedOn: Dayjs | null
	setGeodis220ReceivedOn: ReactStateDispatch<Dayjs | null>
	resetPackingState: () => void
	makeSaveShipmentPayload: (shouldShip: boolean) => UpdateShipmentPatch
	error: string | null
	setError: ReactStateDispatch<string | null>
}

export interface PackagesState {
	[tempId: string]: {
		tempId: string
		packageId: string | null // Null if it wasn't one already on the line item.
		name: string
		weight: number | null
		typeId: string | null
	}
}

export interface LinePackagesState {
	[tempId: string]: {
		tempId: string
		lineItemId: string
		partOrderLineId: string
		partNumber: string
		partName: string
		originalQuantity: number
		packageQuantity: number | null
		packageTempId: string | null
		isClone: boolean
	}
}

export enum FedExUiName {
	Ground = "FedEx Ground (SG)",
	Overnight = "FedEx Priority Overnight (PO)",
}

export const ShipShipmentContext = createContext<ShipShipmentState | undefined>(undefined)

ShipShipmentContext.displayName = "ShipShipmentContext"

export const useShipShipmentContext = (): ShipShipmentState => {
	const context = useContext(ShipShipmentContext)

	if (context === undefined) {
		throw new Error("useShipShipmentContext must be used within ShipShipmentContextProvider")
	}

	return context
}

import { Dispatch, FC, memo, SetStateAction } from "react"

import { useFormContext, useWatch } from "react-hook-form"

import { CustomerAlternateAddress, CustomerDetail } from "@ncs/ncs-api"
import {
	AnimatedEntrance,
	Box,
	CustomerBillToSelectorFormField,
	CustomerSelectorFormField,
	ErrorText,
	getAddressFields,
	GridContainer,
	GridItem,
	Heading,
	HeadingDivider,
	Label,
	ParagraphList,
	TextInputFormField,
} from "@ncs/web-legos"

import { CreateQuoteOrderForm } from "../quotes-util"

export interface CreateQuoteCustomerProps {
	shipToCustomer: CustomerDetail | undefined
	selectedAlternateAddress: CustomerAlternateAddress | null
	setSelectedAlternateAddress: Dispatch<SetStateAction<CustomerAlternateAddress | null>>
	customersErrorText: string | null
}

export const CreateQuoteCustomer: FC<CreateQuoteCustomerProps> = memo(
	({ shipToCustomer, customersErrorText }) => {
		const { control } = useFormContext<CreateQuoteOrderForm>()

		const [shipToId] = useWatch({
			control,
			name: ["shipToId"],
		})

		return (
			<>
				<HeadingDivider headingVariant="h3">1. Customer</HeadingDivider>

				<Box pl={1.5}>
					<GridContainer>
						<GridItem xs={12} sm={6}>
							<Heading mb={0.6} bold variant="h5" icon="truck">
								Ship To
							</Heading>
							<CustomerSelectorFormField
								control={control}
								name="shipToId"
								label=""
								placeholder="Ship To customer..."
								emptyValueFallback=""
								excludeBillTo
							/>
						</GridItem>

						<GridItem xs={12} sm={6}>
							<Heading mb={0.6} bold variant="h5" icon="dollar-sign">
								Bill To
							</Heading>
							<CustomerBillToSelectorFormField
								control={control}
								name="billToId"
								customerId={shipToId || null}
								noSelectionOptionText="Choose Ship To, then Bill To customer..."
								emptyValueFallback=""
								label=""
							/>
						</GridItem>

						{!!customersErrorText && (
							<GridItem xs={12}>
								<AnimatedEntrance show>
									<ErrorText>{customersErrorText}</ErrorText>
								</AnimatedEntrance>
							</GridItem>
						)}

						{!!shipToId && (
							<GridItem xs={12} sm={6} mb={1}>
								<AnimatedEntrance show>
									<Label>Shipping destination</Label>
									<ParagraphList lines={getAddressFields(shipToCustomer)} />
								</AnimatedEntrance>
							</GridItem>
						)}

						<GridItem xs={12} sm={6}>
							<TextInputFormField
								control={control}
								name="purchaseOrder"
								label="Customer purchase order"
							/>
						</GridItem>
					</GridContainer>
				</Box>
			</>
		)
	}
)

CreateQuoteCustomer.displayName = "CreateQuoteCustomer"

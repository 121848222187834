import {
	get,
	patch,
	post,
	del,
	multipartPost,
	rsaaTypes,
	RSAA,
} from "@ncs/bricks/middleware/portalApiMiddleware"
import { GENERATE_DOCUMENT_LINK } from "./infoRef"

export const LIST_TICKETS = rsaaTypes("ticketing/tickets/list")
export const LIST_SEARCH_TICKETS = rsaaTypes("ticketing/search_tickets/list")
export const GET_RELATED_TICKETS = rsaaTypes("ticketing/related_tickets/list")
export const DELETE_RELATED_TICKET = rsaaTypes("ticketing/related_tickets/delete")
export const CREATE_RELATED_TICKET = rsaaTypes("ticketing/related_tickets/create")
export const LIST_EVENTS = rsaaTypes("ticketing/events/list")
export const CREATE_TICKET = rsaaTypes("ticketing/tickets/create")
export const GET_TICKET = rsaaTypes("ticketing/tickets/get")
export const PATCH_TICKET = rsaaTypes("ticketing/tickets/patch")
export const CREATE_COMMENT = rsaaTypes("ticketing/comments/create")
export const PATCH_COMMENT = rsaaTypes("ticketing/comments/patch")
export const UPLOAD_FILE = rsaaTypes("ticketing/document/post")
export const PATCH_DOCUMENT = rsaaTypes("ticketing/document/patch")
export const LIST_DOCUMENTS = rsaaTypes("ticketing/documents/list")
export const DELETE_DOCUMENT = rsaaTypes("ticketing/documents/delete")
export const GET_TICKET_REPORT = rsaaTypes("ticketing/report/get")
export const LIST_USER_ASSIGNMENTS = rsaaTypes("ticketing/user-assignments/list")
export const CREATE_USER_ASSIGNMENT = rsaaTypes("ticketing/user-assignments/create")
export const GET_USER_ASSIGNMENT = rsaaTypes("ticketing/user-assignments/get")
export const DELETE_USER_ASSIGNMENT = rsaaTypes("ticketing/user-assignments/delete")
export const LIST_TICKET_TYPES = rsaaTypes("ticketing/types/list")
export const PATCH_TICKET_TYPE = rsaaTypes("ticketing/types/patch")
export const CREATE_TICKET_TYPE = rsaaTypes("ticketing/types/create")
export const LIST_TICKET_SUBTYPES = rsaaTypes("ticketing/subtypes/list")
export const PATCH_TICKET_SUBTYPE = rsaaTypes("ticketing/subtypes/patch")
export const CREATE_TICKET_SUBTYPE = rsaaTypes("ticketing/subtypes/create")
export const SEND_TECH_NOTIFICATION = rsaaTypes("ticketing/comment/notify")
export const LIST_TICKET_REQUESTORS = rsaaTypes("ticketing/requestors/list")

export const getTickets = (querystring_params) => ({
	[RSAA]: {
		api: get(`/ticketing/tickets/`, querystring_params),
		types: LIST_TICKETS,
		parseLinkHeader: true,
	},
})

export const getTicketRequestors = (querystring_params) => ({
	[RSAA]: {
		api: get(`/ticketing/requestors/`, querystring_params),
		types: LIST_TICKET_REQUESTORS,
		parseLinkHeader: true,
	},
})

export const getRelatedTickets = (ticketId, querystring_params) => ({
	[RSAA]: {
		api: get(`/ticketing/tickets/${ticketId}/related_tickets/`, querystring_params),
		types: GET_RELATED_TICKETS,
		parseLinkHeader: true,
	},
})

export const deleteRelatedTicket = (ticketId, relatedId) => ({
	[RSAA]: {
		api: del(`/ticketing/tickets/${ticketId}/related_tickets/${relatedId}/`),
		types: DELETE_RELATED_TICKET,
	},
})

export const createRelatedTicket = (ticketId, data) => ({
	[RSAA]: {
		api: post(`/ticketing/tickets/${ticketId}/related_tickets/`, data),
		types: CREATE_RELATED_TICKET,
	},
})

export const searchTickets = (querystring_params) => ({
	[RSAA]: {
		api: get(`/ticketing/search_tickets/`, querystring_params),
		types: LIST_SEARCH_TICKETS,
		parseLinkHeader: true,
	},
})

export const getUserAssignments = (ticketTypeId, querystring_params) => ({
	[RSAA]: {
		api: get(`/ticketing/types/${ticketTypeId}/users/`, querystring_params),
		types: LIST_USER_ASSIGNMENTS,
		parseLinkHeader: true,
	},
})

export const deleteUserAssignment = (ticketTypeId, assignmentId) => ({
	[RSAA]: {
		api: del(`/ticketing/types/${ticketTypeId}/users/${assignmentId}/`),
		types: DELETE_USER_ASSIGNMENT,
	},
})

export const createUserAssignment = (typeId, data) => ({
	[RSAA]: {
		api: post(`/ticketing/types/${typeId}/users/`, data),
		types: CREATE_USER_ASSIGNMENT,
	},
})

export const sendTechNotification = (data) => ({
	[RSAA]: {
		api: post(`/ticketing/send_notification`, data),
		types: SEND_TECH_NOTIFICATION,
	},
})

export const listTicketSubtypes = (querystring_params) => ({
	[RSAA]: {
		api: get(`/ticketing/subtypes/`, querystring_params),
		types: LIST_TICKET_SUBTYPES,
		parseLinkHeader: true,
	},
})
export const patchTicketSubtype = (subtypeId, data) => ({
	[RSAA]: {
		api: patch(`/ticketing/subtypes/${subtypeId}/`, data),
		types: PATCH_TICKET_SUBTYPE,
	},
})
export const createTicketSubtype = (data) => ({
	[RSAA]: {
		api: post(`/ticketing/subtypes/`, data),
		types: CREATE_TICKET_SUBTYPE,
	},
})

export const listTicketTypes = (querystring_params) => ({
	[RSAA]: {
		api: get(`/ticketing/types/`, querystring_params),
		types: LIST_TICKET_TYPES,
		parseLinkHeader: true,
	},
})
export const patchTicketType = (typeId, data) => ({
	[RSAA]: {
		api: patch(`/ticketing/types/${typeId}/`, data),
		types: PATCH_TICKET_TYPE,
	},
})
export const createTicketType = (data) => ({
	[RSAA]: {
		api: post(`/ticketing/types/`, data),
		types: CREATE_TICKET_TYPE,
	},
})

export const getEvents = (ticketId) => ({
	[RSAA]: {
		api: get(`/ticketing/tickets/${ticketId}/events/`),
		types: LIST_EVENTS,
		parseLinkHeader: true,
	},
})

export const getTicketReport = (querystring_params) => ({
	[RSAA]: {
		api: post(`/ticketing/report/`, querystring_params),
		types: GET_TICKET_REPORT,
		parseLinkHeader: true,
	},
})

export const getTicket = (ticketId) => ({
	[RSAA]: {
		api: get(`/ticketing/tickets/${ticketId}/`),
		types: GET_TICKET,
	},
})

export const createTicket = (data) => ({
	[RSAA]: {
		api: post(`/ticketing/tickets/`, data),
		types: CREATE_TICKET,
	},
})

export const patchTicket = (ticket_id, data) => ({
	[RSAA]: {
		api: patch(`/ticketing/tickets/${ticket_id}/`, data),
		types: PATCH_TICKET,
	},
})

export const createComment = (data) => ({
	[RSAA]: {
		api: post(`/ticketing/comments/`, data),
		types: CREATE_TICKET,
	},
})

export const patchComment = (commentId, data) => ({
	[RSAA]: {
		api: patch(`/ticketing/comments/${commentId}/`, data),
		types: PATCH_COMMENT,
	},
})

export const doFileUpload = (data) => {
	var formData = new FormData()
	formData.append("title", data.title)
	formData.append("file[0]", data.file, data.file.name)
	if (data.comment === null) {
		formData.append("comment_id", null)
	} else {
		formData.append("comment_id", data.comment)
	}
	if (data.ticket === null) {
		formData.append("ticket_id", null)
	} else {
		formData.append("ticket_id", data.ticket)
	}
	if (data.expirationDate !== null) {
		formData.append("expiration_date", data.expirationDate)
	}
	formData.append("effective_date", data.effectiveDate)
	return {
		[RSAA]: {
			api: multipartPost("/ticketing/documents/", formData),
			types: UPLOAD_FILE,
			multipart: true,
		},
	}
}

export const patchDocument = (documentId, data) => {
	return {
		[RSAA]: {
			api: patch(`/ticketing/documents/${documentId}/`, data),
			types: PATCH_DOCUMENT,
		},
	}
}

export const listDocuments = (querystring_params) => ({
	[RSAA]: {
		api: get(`/ticketing/documents/`, querystring_params),
		types: LIST_DOCUMENTS,
		parseLinkHeader: true,
	},
})

export const deleteDocument = (documentId) => ({
	[RSAA]: {
		api: patch(`/ticketing/documents/${documentId}/`, {
			is_deleted: true,
		}),
		types: DELETE_DOCUMENT,
	},
})

export const generateDocumentLink = (documentId) => ({
	[RSAA]: {
		api: post(`/ticketing/documents/${documentId}/generate_link/`),
		types: GENERATE_DOCUMENT_LINK,
	},
})

const initialState = {
	isLoadingTickets: false,
	tickets: null,

	isLoadingEvents: false,
	events: [],

	isLoadingUserAssignments: false,
	userAssignments: [],

	isLoadingUserAssignment: false,
	userAssignment: null,

	isLoadingTicket: false,
	ticket: null,

	isLoadingPresignedUploadUrl: false,
	isLoadingDocuments: false,
	documents: [],

	isLoadingTypes: false,
	types: [],

	isLoadingSubtypes: false,
	subtypes: [],

	isLoadingSearchTickets: false,
	searchTickets: null,
}

const ticketing = (state = initialState, action) => {
	switch (action.type) {
		case LIST_TICKETS.request:
			return {
				...state,
				tickets: action.payload,
			}
		case LIST_SEARCH_TICKETS.request:
			return {
				...state,
				searchTickets: action.payload,
			}
		case LIST_EVENTS.failure:
			return {
				...state,
				isLoadingevents: false,
				error: action.error,
			}
		case LIST_EVENTS.request:
			return {
				...state,
				isLoadingEvents: true,
			}
		case LIST_EVENTS.success:
			return {
				...state,
				isLoadingEvents: false,
				events: action.payload,
			}
		case LIST_USER_ASSIGNMENTS.failure:
			return {
				...state,
				isLoadingUserAssignments: false,
				error: action.error,
			}
		case LIST_USER_ASSIGNMENTS.request:
			return {
				...state,
				isLoadingUserAssignments: true,
			}
		case LIST_USER_ASSIGNMENTS.success:
			return {
				...state,
				isLoadingUserAssignments: false,
				userAssignments: action.payload,
			}

		case GET_USER_ASSIGNMENT.failure:
			return {
				...state,
				isLoadingUserAssignment: false,
				error: action.error,
			}
		case GET_USER_ASSIGNMENT.request:
			return {
				...state,
				isLoadingUserAssignment: true,
			}
		case GET_USER_ASSIGNMENT.success:
			return {
				...state,
				isLoadingUserAssignment: false,
				userAssignment: action.payload,
			}
		case CREATE_TICKET.request:
			return {
				...state,
				isSaving: true,
			}
		case CREATE_TICKET.success:
			return {
				...state,
				isSaving: false,
				error: null,
				ticket: action.payload,
			}
		case CREATE_TICKET.failure:
			return {
				...state,
				isSaving: false,
				error: action.error,
			}
		case GET_TICKET.request:
			return {
				...state,
				isLoadingTicket: true,
			}
		case GET_TICKET.succes:
			return {
				...state,
				isLoadingTicket: false,
				ticket: action.payload,
				error: null,
			}
		case GET_TICKET.failure:
			return {
				...state,
				isLoadingTicket: false,
				error: action.error,
			}
		case PATCH_TICKET.request:
			return {
				...state,
				isSaving: true,
			}
		case PATCH_TICKET.success:
			return {
				...state,
				isSaving: false,
			}
		case PATCH_TICKET.failure:
			return {
				...state,
				error: action,
			}
		case CREATE_COMMENT.request:
			return {
				...state,
				isSaving: false,
				error: null,
			}
		case CREATE_COMMENT.succes:
			return {
				...state,
				isSaving: false,
				comment: action.payload,
				error: null,
			}
		case CREATE_COMMENT.failure:
			return {
				...state,
				isSaving: false,
				error: action.error,
			}
		case PATCH_COMMENT.request:
			return {
				...state,
				isSaving: false,
			}
		case PATCH_COMMENT.succes:
			return {
				...state,
				isSaving: false,
			}
		case PATCH_COMMENT.failure:
			return {
				...state,
				error: action,
			}
		case UPLOAD_FILE.request:
			return {
				...state,
				isLoadingPresignedUploadUrl: true,
			}
		case UPLOAD_FILE.success:
			return {
				...state,
				isLoadingPresignedUploadUrl: false,
			}
		case UPLOAD_FILE.failure:
			return {
				...state,
				isLoadingPresignedUploadUrl: false,
				error: action.error,
			}
		case GENERATE_DOCUMENT_LINK.request:
			return {
				...state,
				isGeneratingLink: true,
			}
		case GENERATE_DOCUMENT_LINK.success:
			return {
				...state,
				isGeneratingLink: false,
				ticketDocumentLink: action.payload.presign_url,
			}
		case LIST_DOCUMENTS.request:
			return {
				...state,
				isLoadingDocuments: true,
			}
		case LIST_DOCUMENTS.success:
			// bail if we would be overwriting newer data
			if (state.documentRequestTimestamp > action.meta.timestamp) return state

			return {
				...state,
				isLoadingDocuments: false,
				documents: action.payload,
				documentsPages: action.meta.pages,
				documentRequestTimestamp: action.meta.timestamp,
				error: null,
			}
		case LIST_DOCUMENTS.failure:
			return {
				...state,
				isLoadingDocuments: false,
				error: action.error,
			}
		case SEND_TECH_NOTIFICATION.request:
			return {
				...state,
			}
		case SEND_TECH_NOTIFICATION.success:
			return {
				...state,

				error: null,
			}
		case SEND_TECH_NOTIFICATION.failure:
			return {
				...state,

				error: action.error,
			}
		default:
			return state
	}
}

export default ticketing

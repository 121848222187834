import { FC } from "react"

import { LabelSize } from "@ncs/ncs-api"

import { ExtendableSelectProps, RadioGroup, Select } from "../inputs"

export interface LabelSizeSelectorProps
	extends Omit<ExtendableSelectProps<(typeof sizeOptions)[number]>, "value" | "onChange"> {
	value: LabelSize
	onChange: (newSize: LabelSize) => void
	/**
	 * Display a radio-button list instead of a select menu.
	 */
	radio?: boolean
	/**
	 * Only used if radio.
	 */
	description?: string
}

export const LabelSizeSelector: FC<LabelSizeSelectorProps> = ({
	description = "Choose label size:",
	value,
	onChange,
	radio = false,
	...rest
}) => {
	if (radio) {
		return (
			<RadioGroup
				description={description}
				htmlName="label-size"
				options={sizeOptions}
				value={value}
				onChange={(_newValue, option) => onChange(option.value)}
			/>
		)
	}

	return (
		<Select
			label="Label size"
			{...rest}
			options={sizeOptions}
			value={value || defaultLabelSize}
			onChange={(_newValue, option) => {
				if (option) {
					onChange(option.value)
				}
			}}
			valueAccessor="value"
			textAccessor="label"
			showNoSelectionOption={false}
		/>
	)
}

const sizeOptions: {
	value: LabelSize
	label: string
}[] = [
	{ value: "3x2", label: "3 x 2" }, // Default size
	{ value: "2x1", label: "2 x 1" },
	{ value: "3.5x1.5", label: "3.5 x 1.5" },
	{ value: "4x3", label: "4 x 3" },
	{ value: "6x4", label: "6 x 4" },
]

export const defaultLabelSize: LabelSize = "3x2"

import React from "react"
import { BaseSelectFilter } from "@ncs/bricks/components"
import { useDynamicTableFilter } from "@ncs/bricks/util/hooks"

const filterOptions = [
	{ value: "", text: "All Prices" },
	{ value: "true", text: "True" },
	{ value: "false", text: "False" },
]

export default function AlternatePriceFilter({
	reduxKey,
	filterName = "is_alternate_price",
	labelText = "Is Alternate Price",
	...rest
}) {
	const [value, onChange] = useDynamicTableFilter(filterName, reduxKey)

	return (
		<BaseSelectFilter
			id="isAlternatePrice"
			labelText={labelText}
			options={filterOptions}
			value={value}
			onChange={onChange}
			valueAccessor={(x) => (x.value || "").toString()}
			{...rest}
		/>
	)
}

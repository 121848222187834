import { FC, useCallback, useMemo, useState } from "react"

import { useThrottle } from "@react-hook/throttle"
import { Cell, Column } from "react-table"

import {
	AddRequestsToShipmentPostResponse,
	InventoryPartType,
	makeApiErrorMessage,
	ReplenishmentDetailQueryParams,
	ReplenishmentPart,
	useReplenishmentDetail,
	useSubmitReplenishmentParts,
} from "@ncs/ncs-api"
import { addOrPromote, formatNumber, unpythonify } from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	Box,
	ConfirmationModal,
	ConfirmationModalConfig,
	encodeUrlState,
	GridContainer,
	GridItem,
	HeadingDivider,
	LetterIcon,
	Link,
	Paragraph,
	PartTypeSelector,
	ReactTableSortType,
	Table,
	TableProps,
	TextInput,
	useChangeCallback,
	useScrollToBottom,
	useToast,
} from "@ncs/web-legos"

import { LocationsTab, LocationsUrlState } from "../../../Locations"
import { LocationPartsTabUrlState } from "../../location-parts-tab"

export interface LocationSupplierDetailProps {
	locationId: string
	vendorId: string
	isRefreshing: boolean
}

export const LocationSupplierDetail: FC<LocationSupplierDetailProps> = ({
	locationId,
	vendorId,
	isRefreshing,
}) => {
	const scrollToBottom = useScrollToBottom()
	const { makeSuccessToast, makeErrorToast } = useToast()
	const [searchText, setSearchText] = useState<string | null>(null)
	const [throttledSearchText, setThrottledSearchText] = useThrottle<string | null>(null, 3)
	const [paramState, setParamState] = useState<ReplenishmentDetailQueryParams>({
		location: locationId,
		vendor: vendorId,
		partType: INITIAL_PART_TYPE,
	})
	const [results, setResults] = useState<SubmissionResult[]>([])
	const [isSaving, setIsSaving] = useState<boolean>(false)
	const [confirmationModalConfig, setConfirmationModalConfig] =
		useState<ConfirmationModalConfig | null>(null)

	const [replenishmentDetails, requestsLoading] = useReplenishmentDetail(paramState)
	const submitParts = useSubmitReplenishmentParts({
		invalidateCache: false,
	})

	const handleAddToOrder = useCallback(
		async (partsToAdd: ReplenishmentPart[]) => {
			try {
				setIsSaving(true)

				const res = await submitParts({
					locationId,
					vendorId,
					parts: partsToAdd.map((p) => ({
						partId: p.partId,
						quantity: p.orderAmount,
					})),
				})
				const requestResult = res?.data ? unpythonify(res.data) : null

				makeSuccessToast("Parts added to order")

				if (requestResult) {
					// Add to our results array to display affected orders.
					setResults((prev) =>
						addOrPromote(
							{ ...requestResult, partIds: partsToAdd.map(({ partId }) => partId) },
							prev
						)
					)
					// Scroll to the bottom of the page, in case the list of parts is long.
					scrollToBottom()
				}
			} catch (e) {
				makeErrorToast(makeApiErrorMessage(e))
			} finally {
				setIsSaving(false)
			}
		},
		[submitParts, locationId, vendorId, makeSuccessToast, scrollToBottom, makeErrorToast]
	)

	// If the location or vendor ID changes, we need to refresh our param state.
	useChangeCallback(vendorId, (newId) => {
		setParamState((prev) => ({
			...prev,
			vendor: newId,
		}))
	})
	useChangeCallback(locationId, (newId) => {
		setParamState((prev) => ({
			...prev,
			location: newId,
		}))
	})

	const isAnythingLoading = useMemo(() => {
		return !!isSaving || requestsLoading || isRefreshing
	}, [isSaving, requestsLoading, isRefreshing])

	const tablePagination = useMemo(() => 100, [])

	const bulkActionButtons = useMemo(() => {
		const buttons: TableProps<ReplenishmentPart>["bulkActionButtons"] = [
			{
				buttonText: "Add to DC Order",
				icon: "plus-circle",
				variant: "primary-cta",
				disabled: isAnythingLoading,
				onClick: ({ selectedRows }) => {
					setConfirmationModalConfig({
						title: "Add Parts To DC Order",
						message: `Create or update an order to this vendor from this location for ${selectedRows.length} items?`,
						onConfirm: async () => {
							await handleAddToOrder(selectedRows.map((r) => r.original))
						},
					})
				},
			},
		]

		return buttons
	}, [isAnythingLoading, handleAddToOrder])

	const rowMenu = useMemo(() => {
		const menu: TableProps<ReplenishmentPart>["rowMenu"] = [
			{
				label: "Add to DC order",
				iconName: "plus-circle",
				onClick: ({ original }) => {
					setConfirmationModalConfig({
						title: "Add Part To DC Order",
						message: `Create or update an order to this vendor from this location for (${original.partNumber}) ${original.description}?`,
						onConfirm: async () => {
							await handleAddToOrder([original])
						},
					})
				},
			},
			{
				label: "View part at location",
				iconName: "external-link",
				onClick: ({ original }) => {
					window.open(
						"/inventory/locations" +
							encodeUrlState<LocationsUrlState & LocationPartsTabUrlState>({
								urlLocationId: locationId,
								tab: LocationsTab.Parts,
								partsTabPart: original.partId,
							})
					)
				},
			},
		]

		return menu
	}, [locationId, handleAddToOrder])

	// Filter out requests that the user has already submitted or that don't match the search text.
	const replenishmentParts = useMemo(() => {
		return (replenishmentDetails?.parts ?? []).filter((part) => {
			return (
				results.every((result) => result.partIds.every((id) => part.partId !== id)) &&
				(!throttledSearchText ||
					throttledSearchText
						.toUpperCase()
						.split(" ")
						.every((piece) =>
							`${part.partNumber}${part.description}`.toUpperCase().includes(piece)
						))
			)
		})
	}, [replenishmentDetails?.parts, throttledSearchText, results])

	return (
		<>
			<GridContainer>
				<GridItem xs={12} md={4} lg={3}>
					<TextInput
						fillContainer
						value={searchText}
						onChange={(newValue) => {
							setSearchText(newValue)
							setThrottledSearchText(newValue)
						}}
						icon="search"
						label="Search"
						clearable
					/>
				</GridItem>
				<GridItem xs={12} md={4} lg={3}>
					<PartTypeSelector
						fillContainer
						value={paramState.partType}
						onChange={(newValue) =>
							setParamState((prev) => ({
								...prev,
								partType: newValue,
							}))
						}
					/>
				</GridItem>
			</GridContainer>

			<Table
				data={replenishmentParts}
				columns={columns}
				isUpdating={isSaving || isRefreshing}
				isLoading={isAnythingLoading}
				noDataText="No parts needed from this supplier at this location"
				queryParamState={paramState}
				setQueryParamState={setParamState}
				bulkActionButtons={bulkActionButtons}
				rowMenu={rowMenu}
				infiniteRowsIncrement={tablePagination}
			/>

			<AnimatedEntrance show={results.length > 0} direction="down" mt={4}>
				<HeadingDivider mb={1} variant="h3">
					Created/Updated Orders
				</HeadingDivider>
				{results.map((order) => (
					<Box
						key={order.purchaseOrderId ?? order.partOrderId}
						display="flex"
						alignItems="center"
						columnGap={0.5}
						mb={0.5}
					>
						<Link
							to={
								order.purchaseOrderId ?
									`/purchase-orders/${order.purchaseOrderId}`
								:	`/part-orders/${order.partOrderId}`
							}
							icon="external-link"
							newTab
						>
							{order.purchaseOrderId ?
								`Purchase Order: #${order.purchaseOrderId}`
							:	`Part Order: #${order.partOrderOrderId}`}
						</Link>
						<Paragraph small color="secondary">
							Status: {order.purchaseOrderStatus ?? order.partOrderStatus}. (
							{order.customerNumber}) {order.customer}
						</Paragraph>
					</Box>
				))}
			</AnimatedEntrance>

			<ConfirmationModal
				config={confirmationModalConfig}
				setConfig={setConfirmationModalConfig}
			/>
		</>
	)
}

const columns: Column<ReplenishmentPart>[] = [
	{
		Header: "Part #",
		id: "part-number",
		headingTooltip: (
			<>
				<LetterIcon letter="S" /> = Stock Part
			</>
		),
		headingTooltipIcon: null,
		disableSortBy: true,
		Cell: ({ row: { original } }: Cell<ReplenishmentPart>) => {
			return (
				<Box display="flex" alignItems="center" gap={0.5}>
					{original.partNumber}
					{original.stock && <LetterIcon letter="S" title="Stock Part" />}
				</Box>
			)
		},
	},
	{
		Header: "Part name",
		accessor: ({ description }) => description,
	},
	{
		Header: "On hand",
		accessor: ({ onHand }) => formatNumber(onHand),
	},
	{
		Header: "Incoming orders",
		accessor: ({ incomingOrders }) => incomingOrders,
		sortType: ReactTableSortType.Number,
		Cell: ({ row: { original } }: Cell<ReplenishmentPart>) =>
			formatNumber(original.incomingOrders, {
				zeroString: "-",
			}),
	},
	{
		Header: "Outgoing orders",
		accessor: ({ outgoingOrders }) => outgoingOrders,
		sortType: ReactTableSortType.Number,
		Cell: ({ row: { original } }: Cell<ReplenishmentPart>) =>
			formatNumber(original.outgoingOrders, {
				zeroString: "-",
			}),
	},
	{
		Header: "Picking",
		accessor: ({ picking }) => picking,
		sortType: ReactTableSortType.Number,
		Cell: ({ row: { original } }: Cell<ReplenishmentPart>) =>
			formatNumber(original.picking, {
				zeroString: "-",
			}),
	},
	{
		Header: "Shipping",
		accessor: ({ shipping }) => shipping,
		sortType: ReactTableSortType.Number,
		Cell: ({ row: { original } }: Cell<ReplenishmentPart>) =>
			formatNumber(original.shipping, {
				zeroString: "-",
			}),
	},
	{
		Header: "Reorder point",
		accessor: ({ reorderPoint }) => reorderPoint,
		sortType: ReactTableSortType.Number,
		Cell: ({ row: { original } }: Cell<ReplenishmentPart>) =>
			formatNumber(original.reorderPoint, {
				zeroString: "-",
			}),
	},
	{
		Header: "Econ level",
		accessor: ({ econ }) => econ,
		sortType: ReactTableSortType.Number,
		Cell: ({ row: { original } }: Cell<ReplenishmentPart>) =>
			formatNumber(original.econ, {
				zeroString: "-",
			}),
	},
	{
		Header: "Max level",
		accessor: ({ maxLevel }) => maxLevel,
		sortType: ReactTableSortType.Number,
		Cell: ({ row: { original } }: Cell<ReplenishmentPart>) =>
			formatNumber(original.maxLevel, {
				zeroString: "-",
			}),
	},
	{
		Header: "Amount to order",
		accessor: ({ orderAmount }) => orderAmount,
		sortType: ReactTableSortType.Number,
		Cell: ({ row: { original } }: Cell<ReplenishmentPart>) =>
			formatNumber(original.orderAmount, {
				zeroString: "-",
			}),
	},
]

const INITIAL_PART_TYPE = InventoryPartType.All

interface SubmissionResult extends AddRequestsToShipmentPostResponse {
	partIds: string[]
}

import { FC, useState } from "react"

import { makeApiErrorMessage } from "@ncs/ncs-api"

import { RadioBoolean } from "../inputs"
import { ExtendableModalProps, Modal } from "./Modal"

export interface EditBooleanModalProps extends ExtendableModalProps {
	onSave: (newValue: boolean) => void | Promise<void>
	initialSelection?: boolean
	yesText?: string
	noText?: string
	description?: string
	title?: string
	saveButtonLabel?: string
}

export const EditBooleanModal: FC<EditBooleanModalProps> = ({
	onSave,
	initialSelection = false,
	yesText,
	noText,
	description,
	title,
	saveButtonLabel = "Save",
	...rest
}) => {
	const [value, setValue] = useState(initialSelection)
	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const handleSave = async () => {
		try {
			setIsSaving(true)
			await onSave(value)
			rest.onClose()
		} catch (e) {
			setErrorText(makeApiErrorMessage(e))
			setIsSaving(false)
		}
	}

	const reset = () => {
		setValue(initialSelection)
		setIsSaving(false)
		setErrorText(null)
	}

	return (
		<Modal
			{...rest}
			onOpen={reset}
			errorText={errorText}
			title={title}
			rightButtons={{
				buttonText: saveButtonLabel,
				onClick: handleSave,
				isLoading: isSaving,
			}}
		>
			<RadioBoolean
				value={value}
				yesText={yesText}
				noText={noText}
				htmlName="edit-radio-modal"
				onChange={setValue}
				description={description}
			/>
		</Modal>
	)
}

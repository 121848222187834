import { FC, useMemo, useRef, useState } from "react"

import { Column } from "react-table"

import {
	makeApiErrorMessage,
	ManufacturedPart,
	useDeleteManufacturedPart,
	useManufacturedParts,
} from "@ncs/ncs-api"
import { yesOrNo } from "@ncs/ts-utils"
import {
	Button,
	Card,
	ConfirmationModal,
	ConfirmationModalConfig,
	SearchQueryFilter,
	Table,
	useToast,
	useUrlState,
} from "@ncs/web-legos"

import { ManufacturedPartModal } from "./components"
import { makeDeleteManufacturedPartConfirmMessage } from "./manufactured-parts-util"

export type ManufacturedPartsUrlState = {
	search: string | null
}

export const ManufacturedParts: FC = () => {
	const { makeSuccessToast, makeErrorToast } = useToast()
	const [params, { setUrlState }] = useUrlState<ManufacturedPartsUrlState>({ search: null })
	const filtersStartOpen = useRef(!!params.search)
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)

	const [parts, partsLoading] = useManufacturedParts()
	const deletePart = useDeleteManufacturedPart()

	const [showCreateModal, setShowCreateModal] = useState(false)
	const [modalPartId, setModalPartId] = useState<string | null>(null)
	const modalPart = useMemo(() => {
		return (parts ?? []).find((p) => p.id === modalPartId) ?? null
	}, [parts, modalPartId])

	const handleDelete = (toDelete: ManufacturedPart) => {
		setConfirmationConfig({
			title: "Delete Manufactured Part",
			message: makeDeleteManufacturedPartConfirmMessage(toDelete),
			onConfirm: async () => {
				try {
					await deletePart(toDelete.id)
					makeSuccessToast("Manufactured part deleted")
				} catch (e) {
					makeErrorToast(makeApiErrorMessage(e))
				}
			},
		})
	}

	const filteredParts = useMemo(() => {
		const searchParts = params.search?.trim().toUpperCase().split(" ")

		return (parts ?? []).filter((part) => {
			if (!searchParts?.length) return true

			const searchable = [
				part.packageSize,
				part.batchGroup,
				part.location.code,
				part.location.description,
				part.part.description,
				part.part.partNumber,
				part.productType,
			]
				.join("")
				.toUpperCase()

			return searchParts.every((searchPart) => searchable.includes(searchPart))
		})
	}, [params.search, parts])

	return (
		<Card heading="Manufactured Parts" headingIcon="gears">
			<Button
				variant="secondary-cta"
				icon="plus-circle"
				onClick={() => setShowCreateModal(true)}
			>
				Add Manufactured Part
			</Button>

			<Table
				data={filteredParts}
				columns={columns}
				isLoading={partsLoading}
				defaultSort={defaultSort}
				queryParamState={params}
				setQueryParamState={setUrlState}
				toggledQueryFilters={[SearchQueryFilter]}
				onRowClick={({ original }) => setModalPartId(original.id)}
				showToggledFiltersByDefault={filtersStartOpen.current}
				storeColumnVisibility
				rowMenu={[
					{
						label: "Edit",
						iconName: "pencil",
						onClick: ({ original }) => setModalPartId(original.id),
					},
					{
						label: "Delete",
						iconName: "trash-alt",
						onClick: ({ original }) => handleDelete(original),
					},
				]}
			/>

			{(!!modalPart || showCreateModal) && (
				<ManufacturedPartModal
					part={modalPart}
					onClose={() => {
						setModalPartId(null)
						setShowCreateModal(false)
					}}
				/>
			)}

			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</Card>
	)
}

const columns: Column<ManufacturedPart>[] = [
	{
		Header: "Part",
		accessor: ({ part }) => `(${part.partNumber}) ${part.description}`,
	},
	{
		Header: "Location",
		accessor: ({ location }) => `(${location.code}) ${location.description}`,
	},
	{
		Header: "Type",
		accessor: ({ productType }) => productType.description,
	},
	{
		Header: "Batch group",
		accessor: ({ batchGroup }) => batchGroup,
	},
	{
		Header: "Size",
		accessor: ({ packageSize }) => packageSize,
	},
	{
		Header: "Static ADU",
		hiddenByDefault: true,
		accessor: ({ staticAdu }) => staticAdu ?? "-",
	},
	{
		Header: "Obsolete?",
		hiddenByDefault: true,
		accessor: ({ isObsolete }) => yesOrNo(isObsolete),
	},
]

const defaultSort = [
	{
		id: "Part",
	},
]

import { FC, useState } from "react"

import { Column } from "react-table"

import {
	CustomerContractParts,
	CustomerDetail,
	makeApiErrorMessage,
	useCustomerContractParts,
	useDeleteContractPart,
} from "@ncs/ncs-api"
import { formatDate } from "@ncs/ts-utils"
import {
	Box,
	Button,
	ConfirmationModal,
	ConfirmationModalConfig,
	EmptyValueDash,
	Icon,
	LabeledData,
	LoadingSpinner,
	Paragraph,
	Table,
	useToast,
} from "@ncs/web-legos"

import { AddContractPartModal } from "./components"

export interface ContractPartsProps {
	customer: CustomerDetail
}

export const ContractParts: FC<ContractPartsProps> = ({ customer }) => {
	const { makeSuccessToast, makeErrorToast } = useToast()
	const [contracts, contractsLoading] = useCustomerContractParts(customer.id)
	const [contractForAddModal, setContractForAddModal] = useState<CustomerContractParts | null>(
		null
	)
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)

	const deletePart = useDeleteContractPart()

	const handleRemove = async (part: CustomerContractParts["parts"][number]) => {
		setConfirmationConfig({
			title: "Remove Part",
			message: (
				<>
					Confirm: Remove{" "}
					<strong>
						({part.partNumber}) {part.description}
					</strong>{" "}
					from the part inclusion list?
				</>
			),
			onConfirm: async () => {
				try {
					await deletePart({
						body: {
							inclusion: part.inclusionId,
							contract: undefined,
						},
					})
					makeSuccessToast("Part removed")
				} catch (e) {
					makeErrorToast(makeApiErrorMessage(e))
				}
			},
		})
	}

	const handleRemoveAll = async (contractId: string) => {
		setConfirmationConfig({
			title: "Remove All Parts",
			message: (
				<>
					Confirm: Remove <strong>ALL</strong> parts from this contract's inclusion list?
				</>
			),
			onConfirm: async () => {
				try {
					await deletePart({
						body: {
							inclusion: undefined,
							contract: contractId,
						},
					})
					makeSuccessToast("Part removed")
				} catch (e) {
					makeErrorToast(makeApiErrorMessage(e))
				}
			},
		})
	}

	if (contractsLoading) {
		return <LoadingSpinner />
	}

	if (!contracts) {
		return (
			<Paragraph small secondary>
				Unable to load contract part inclusion.
			</Paragraph>
		)
	}

	if (contracts.length === 0) {
		return (
			<Paragraph small secondary>
				No contracts associated with this customer.
			</Paragraph>
		)
	}

	return (
		<>
			{contracts?.map((contract) => {
				return (
					<Box key={contract.id} bb={1} pb={2} mb={2}>
						<Box
							d="flex"
							justifyContent="space-between"
							alignItems="center"
							flexWrap="wrap"
							gap={1}
							mb={1}
						>
							<Box d="flex" gap={2}>
								<LabeledData mb={0} label="Contract #">
									{contract.number}
								</LabeledData>
								<LabeledData mb={0} label="Equipment Serial #">
									{contract.equipmentSerial || <EmptyValueDash />}
								</LabeledData>
								<LabeledData mb={0} label="Equipment Name">
									{contract.equipmentVintage || contract.equipmentModel || (
										<EmptyValueDash />
									)}
								</LabeledData>
								<LabeledData mb={0} label="Effective date">
									{formatDate(contract.effectiveDate, { formatInUtc: true })}
								</LabeledData>
								<LabeledData mb={0} label="Expiration date">
									{formatDate(contract.expirationDate, { formatInUtc: true })}
								</LabeledData>
								<LabeledData mb={0} label="Active?">
									{contract.isActive ?
										<>
											<Icon icon="check" color="gray" /> Active
										</>
									:	"No"}
								</LabeledData>
							</Box>

							<Box d="flex" gap={1}>
								{contract.parts.length ?
									<>
										<Button
											icon="plus"
											onClick={() => setContractForAddModal(contract)}
										>
											Add part
										</Button>
										<Button
											icon="ban"
											onClick={() => handleRemoveAll(contract.id)}
										>
											Remove all
										</Button>
									</>
								:	<Button
										icon="plus"
										onClick={() => setContractForAddModal(contract)}
									>
										Start part inclusion list
									</Button>
								}
							</Box>
						</Box>

						{contract.parts.length > 0 ?
							<Table
								data={contract.parts}
								columns={columns}
								disableAllSorting
								rowMenu={[
									{
										label: "Remove",
										iconName: "trash-alt",
										onClick: ({ original }) => {
											void handleRemove(original)
										},
									},
								]}
							/>
						:	<Paragraph small secondary>
								All CHM parts included in contract by default.
							</Paragraph>
						}
					</Box>
				)
			})}

			{!!contractForAddModal && (
				<AddContractPartModal
					contract={contractForAddModal}
					onClose={() => setContractForAddModal(null)}
				/>
			)}

			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</>
	)
}

const columns: Column<CustomerContractParts["parts"][number]>[] = [
	{
		Header: "Part",
		accessor: ({ partNumber, description }) => `(${partNumber}) ${description}`,
	},
]

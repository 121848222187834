import { FC, useMemo } from "react"

import { z } from "zod"

import { useSchedulerDashboardData } from "@ncs/ncs-api"
import { titleCase } from "@ncs/ts-utils"
import {
	Box,
	Card,
	CheckboxGroup,
	GridContainer,
	GridItem,
	Icon,
	Label,
	LoadingSpinner,
	Paragraph,
	useLocalStorageState,
	useScreenSizeMatch,
} from "@ncs/web-legos"

import { LineItemsGraph, LineItemsWarehouseCard } from "./components"

export const LineItemsDashboard: FC = () => {
	const screenIsXs = useScreenSizeMatch("xs")
	const [config, setConfig] = useLocalStorageState(defaultConfig, {
		keyOverride: "line-items-dashboard-config",
		validate(state) {
			return LineItemsDashboardConfigSchema.safeParse(state).success
		},
	})

	const [data, dataLoading] = useSchedulerDashboardData()

	const preparedData = useMemo(() => {
		return (data ?? []).sort((a, b) =>
			a.warehouseName.toUpperCase() > b.warehouseName.toUpperCase() ? 1 : -1
		)
	}, [data])

	if (dataLoading) {
		return (
			<Card>
				<LoadingSpinner />
			</Card>
		)
	}

	if (!data) {
		return (
			<Card>
				<Paragraph>Dashboard data could not be loaded at this time</Paragraph>
			</Card>
		)
	}

	return (
		<>
			<Card display="flex" gap={1} alignItems="center" py={0.5} mb={1.5}>
				<Box d="flex" flexDirection={screenIsXs ? "column" : "row"} gap={1}>
					<Box d="flex" alignItems="center" gap={0.5}>
						<Icon icon="cog" fontSize={1.5} color="gray" />
						<Label>Show orders with:</Label>
					</Box>
					<CheckboxGroup
						mb={0}
						rows={Object.entries(config).map(([key, value]) => ({
							label: key,
							checked: value,
						}))}
						valueAccessor="label"
						labelAccessor={(row) => titleCase(row.label)}
						checkedAccessor="checked"
						onChange={({ label }, newState) => {
							setConfig((prev) => ({
								...prev,
								[label]: newState,
							}))
						}}
						horizontal={!screenIsXs}
					/>
				</Box>
			</Card>

			{!screenIsXs && <LineItemsGraph warehousesData={preparedData} config={config} />}

			<GridContainer gap={1.5}>
				{preparedData.map((d) => (
					<GridItem key={d.warehouse} xs={12} md={6} lg={4}>
						<LineItemsWarehouseCard data={d} config={config} />
					</GridItem>
				))}
			</GridContainer>
		</>
	)
}

const LineItemsDashboardConfigSchema = z.object({
	chemical: z.boolean(),
	parts: z.boolean(),
	detail: z.boolean(),
})

export type LineItemsDashboardConfig = z.infer<typeof LineItemsDashboardConfigSchema>

const defaultConfig: LineItemsDashboardConfig = {
	chemical: true,
	parts: true,
	detail: true,
}

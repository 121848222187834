import React from "react"
import PropTypes from "prop-types"
import { Remove } from "@material-ui/icons"
import { createStyles, makeStyles } from "@material-ui/core"
import { GridItem, Button } from "../../components"
import { MaterialUISizeKeys, MaterialUISizeValues } from "../../util/types"
import { GridItemProps } from "../Grid/GridItem"

const styles = createStyles({
	container: {
		position: "relative",
	},
	deleteButtonWrapper: {
		position: "absolute",
		top: "-30px",
		right: "-15px",
		zIndex: 9999,
		marginTop: "10px",
	},
})
const useStyles = makeStyles(styles)

export interface DashboardWidgetWrapperProps extends GridItemProps {
	index: number
	gridWidths: Partial<Record<MaterialUISizeKeys, MaterialUISizeValues>>
	onRemoveWidget?: (index: number) => void
	children: React.ReactNode
}

const DashboardWidgetWrapper: React.FC<DashboardWidgetWrapperProps> = ({
	index,
	gridWidths,
	onRemoveWidget,
	children,
	...rest
}) => {
	const classes = useStyles()

	return (
		<GridItem {...gridWidths} {...rest}>
			<div className={classes.container}>
				<div className={classes.deleteButtonWrapper}>
					<Button
						justIcon
						round
						color="danger"
						size="sm"
						title="Remove"
						onClick={(e) => {
							e.stopPropagation()
							if (onRemoveWidget) {
								onRemoveWidget(index)
							}
						}}
					>
						<Remove />
					</Button>
				</div>
				{children}
			</div>
		</GridItem>
	)
}

DashboardWidgetWrapper.propTypes = {
	index: PropTypes.number.isRequired,
	gridWidths: PropTypes.object.isRequired,
	onRemoveWidget: PropTypes.func,
	children: PropTypes.node.isRequired,
}

export default React.memo(DashboardWidgetWrapper)

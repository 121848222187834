import { FC } from "react"

import { Cell, Column } from "react-table"

import {
	useExportWorkOrderDetailReport,
	useWorkOrderDetailReport,
	WorkOrderDetailReportLine,
	WorkOrderDetailReportQueryParams,
} from "@ncs/ncs-api"
import {
	formatCurrency,
	formatDateTime,
	getStartAndEndDateQueryParams,
	getTimezoneAbbreviation,
	TimeAgo,
	Typify,
} from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	CustomerQueryFilter,
	EnterpriseQueryFilter,
	Link,
	OrganizationQueryFilter,
	Table,
	TerritoryQueryFilter,
	TimeAgoQueryFilter,
	usePaginationUrlState,
	useUrlState,
} from "@ncs/web-legos"

export type WorkOrderDetailReportUrlState = Typify<WorkOrderDetailReportQueryParams>

export const WorkOrderDetailReport: FC = () => {
	const [queryParams, { setUrlState: setQueryParams }] =
		useUrlState<WorkOrderDetailReportUrlState>(initialWorkOrderDetailReportUrlState)
	const [pagination, setPagination] = usePaginationUrlState()

	const query = useWorkOrderDetailReport({
		params: { ...queryParams, ...pagination },
		manualPagination: true,
	})
	const dataExport = useExportWorkOrderDetailReport(queryParams)

	return (
		<AnimatedEntrance show>
			<Table
				query={query}
				columns={columns}
				queryParamState={queryParams}
				setQueryParamState={setQueryParams}
				pagination={pagination}
				setPagination={setPagination}
				dataExport={dataExport}
				pinnedQueryFilters={[
					CustomerQueryFilter,
					OrganizationQueryFilter,
					TerritoryQueryFilter,
					TimeAgoQueryFilter,
				]}
				toggledQueryFilters={[EnterpriseQueryFilter]}
				filterResetValues={initialWorkOrderDetailReportUrlState}
				storeColumnVisibility="work-order-detail-report-columns"
			/>
		</AnimatedEntrance>
	)
}

const columns: Column<WorkOrderDetailReportLine>[] = [
	{
		Header: "Territory",
		id: "customer_Territory_Code",
		accessor: ({ customer: { territory } }) =>
			territory ? `(${territory.code}) ${territory.description}` : "-",
	},
	{
		Header: `Opened (${getTimezoneAbbreviation()})`,
		id: "openDate",
		accessor: ({ openDate }) => formatDateTime(openDate),
	},
	{
		Header: `Closed (${getTimezoneAbbreviation()})`,
		id: "closedDate",
		accessor: ({ closedDate }) => (closedDate ? formatDateTime(closedDate) : "-"),
	},
	{
		Header: "Tech assigned",
		id: "assignedEmployee_LastName",
		accessor: ({ assignedEmployee }) => assignedEmployee.name,
	},
	{
		Header: "W.O. total",
		id: "total",
		hiddenByDefault: true,
		accessor: ({ total }) => formatCurrency(total),
	},
	{
		Header: "Drive time (hrs)",
		id: "driveTimeHours",
		hiddenByDefault: true,
		accessor: ({ driveTimeHours }) => driveTimeHours,
	},
	{
		Header: "Dispatch",
		id: "dispatch_DispatchNumber",
		hiddenByDefault: true,
		accessor: ({ dispatch }) => dispatch?.dispatchNumber,
		Cell: ({ row: { original } }: Cell<WorkOrderDetailReportLine>) =>
			original.dispatch ?
				<Link
					newTab
					icon="external-link"
					to={`/service/dispatches/${original.dispatch.id}`}
				>
					Dispatch #{original.dispatch.dispatchNumber}
				</Link>
			:	"-",
	},
	{
		Header: "Customer #",
		id: "customer_CustomerNumber",
		accessor: ({ customer }) => customer.customerNumber,
	},
	{
		Header: "Customer name",
		id: "customer_Name",
		accessor: ({ customer }) => customer.name,
	},
]

const initialWorkOrderDetailReportUrlState: WorkOrderDetailReportUrlState = {
	ordering: null,
	organization: null,
	enterprise: null,
	territory: null,
	customer: null,
	startDate: getStartAndEndDateQueryParams(TimeAgo.DaysAgo30)[0],
	endDate: null,
}

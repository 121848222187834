import React, { useEffect } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import memoizeOne from "memoize-one"

import { BaseSelectFilter, withDynamicTableFilter } from "@ncs/bricks/components"

import { loadLookups, LOOKUPS } from "../../redux/services/lookups"

// these are used when the lookup hasn't returned yet, anything that can be a default value for the
// filter should be in this list
const defaultStatuses = [
	{ _id: "9", code: "Pending Field Approval" },
	{ _id: "10", code: "Pending Second Approval" },
	{ _id: "6", code: "Rejected" },
	{ _id: "4", code: "Ready For Invoicing" },
	{ _id: "5", code: "Invoice Generated" },
	{ _id: "12", code: "Additional Entry" },
	{ _id: "13", code: "Invoiced" },
	{ _id: "11", code: "Rebilled" },
	{ _id: "14", code: "Staging For Mailing Service" },
	{ _id: "15", code: "Queued In KBM" },
]

const getInvoiceStatusOptions = memoizeOne((statuses) => [
	{ _id: "", code: "All Statuses" },
	...statuses,
])

function InvoiceStatusFilter(props) {
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(loadLookups([LOOKUPS.InvoiceStatuses]))
	}, [dispatch])

	let statuses = useSelector((x) => x.lookups.invoiceStatuses, shallowEqual)
	if (!statuses?.length) {
		statuses = defaultStatuses
	}
	const options = getInvoiceStatusOptions(statuses)

	return (
		<BaseSelectFilter
			id="invoiceStatuses"
			labelText="Invoice Status"
			options={options}
			valueAccessor={(x) => (x._id || options[0]._id).toString()}
			textAccessor="code"
			{...props}
		/>
	)
}

export const AliasedInvoiceStatusFilter = (filterName) =>
	withDynamicTableFilter(filterName)(React.memo(InvoiceStatusFilter))
export default AliasedInvoiceStatusFilter("status")

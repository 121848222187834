import { FC, useState } from "react"

import {
	Customer,
	makeApiErrorMessage,
	PartGroup,
	PartGroupDiscount,
	useAddPartGroupDiscount,
	useUpdatePartGroupDiscount,
} from "@ncs/ncs-api"
import { extractNumber } from "@ncs/ts-utils"
import {
	CustomerSelector,
	ExtendableModalProps,
	LabeledData,
	Modal,
	NumericInput,
	Paragraph,
	RadioBoolean,
	useToast,
} from "@ncs/web-legos"

export interface EditGroupDiscountModalProps extends ExtendableModalProps {
	group: PartGroup
	discountToEdit: PartGroupDiscount | null
}

export const EditGroupDiscountModal: FC<EditGroupDiscountModalProps> = ({
	group,
	discountToEdit,
	...rest
}) => {
	const { makeSuccessToast } = useToast()
	const addDiscount = useAddPartGroupDiscount()
	const updateDiscount = useUpdatePartGroupDiscount()

	const [customer, setCustomer] = useState<Customer | null>(null)
	const [isActive, setIsActive] = useState(true)

	const [discount, setDiscount] = useState<number | null>(null)

	const [isLoading, setIsLoading] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const onCreate = async () => {
		if (!customer) throw new Error("Trying to create discount without customer")
		if (discount == null) throw new Error("Trying to create discount without discount")

		try {
			setIsLoading(true)
			await addDiscount({
				customerId: customer.id,
				groupDiscountPercent: discount,
				groupId: group.id,
				isActive,
			})
			makeSuccessToast("Customer discount added to part group")
			rest.onClose()
		} catch (e) {
			setErrorText(makeApiErrorMessage(e))
			setIsLoading(false)
		}
	}

	const onUpdate = async () => {
		if (discount == null) throw new Error("Trying to update discount without amount")
		if (!discountToEdit) throw new Error("Trying to update discount without discount")

		try {
			setIsLoading(true)
			await updateDiscount({
				updates: {
					groupDiscountPercent: discount,
					isActive,
				},
				id: discountToEdit.id.toString(),
			})
			makeSuccessToast("Customer discount updated")
			rest.onClose()
		} catch (e) {
			setErrorText(makeApiErrorMessage(e))
			setIsLoading(false)
		}
	}

	const reset = () => {
		setCustomer(null)
		setDiscount(
			discountToEdit?.groupDiscountPercent ?
				extractNumber(discountToEdit?.groupDiscountPercent)
			:	null
		)
		setIsActive(discountToEdit?.isActive ?? true)
		setIsLoading(false)
		setErrorText(null)
	}

	const isEditing = !!discountToEdit
	const isValid = discount != null && (!!customer || isEditing)

	return (
		<Modal
			{...rest}
			title={isEditing ? "Edit Customer Discount" : "Add Customer To Part Group"}
			onOpen={reset}
			errorText={errorText}
			rightButtons={{
				buttonText: isEditing ? "Update" : "Add Customer",
				disabled: !isValid,
				onClick: isEditing ? onUpdate : onCreate,
				isLoading,
			}}
		>
			{!isEditing && (
				<Paragraph mb={2}>
					Search for a customer to get a discount on
					<strong> {group.description}</strong>.
				</Paragraph>
			)}
			{isEditing ?
				<LabeledData label="Customer">
					{discountToEdit.customerNumber} - {discountToEdit.customer.name}
				</LabeledData>
			:	<CustomerSelector value={customer} onChange={setCustomer} autoFocus />}
			<NumericInput
				label="Discount %"
				value={discount}
				onChange={(newNumber) => setDiscount(newNumber ?? null)}
				max={100}
				decimalScale={3}
				maxWidth={8}
				allowNegative
				doPercentageMath
			/>
			<RadioBoolean
				mt={0.5}
				htmlName="is-active"
				description="Is discount active for the customer?"
				value={isActive}
				onChange={setIsActive}
			/>
		</Modal>
	)
}

import React, { useEffect, useState } from "react"
import { compose } from "redux"
import { useRouteMatch } from "react-router-dom"
import { Link } from "react-router-dom"
import { validatePasswordResetToken } from "@ncs/bricks/redux/services/auth"

import { CircularProgress } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import { Card, CardBody, CardFooter, GridContainer, GridItem, withCallApi } from "~/components"
import ResetPasswordForm from "./ResetPasswordForm"

import { makeStyles } from "@material-ui/core/styles"
import { container } from "@ncs/bricks/assets/jss/material-dashboard-pro-react"
const pageStyles = {
	container,
	content: {
		paddingTop: "4vh",
		position: "relative",
		zIndex: "4",
	},
}

const useStyles = makeStyles(pageStyles)

const usePasswordResetToken = () => {
	const match = useRouteMatch()

	return match.params.token
}

const TokenInvalid = () => {
	const classes = useStyles()

	return (
		<Card>
			<CardBody>
				<Alert variant="filled" severity="error">
					Unknown, invalid, or expired token.
				</Alert>
			</CardBody>
			<CardFooter className={classes.justifyContentCenter}>
				<Link to="/login/forgot-password">Reset Password</Link>
				<Link to="/login">Login</Link>
			</CardFooter>
		</Card>
	)
}

const ResetPasswordPage = ({ callApi }) => {
	const [token, setToken] = useState(null)
	const [loading, setLoading] = useState(false)
	const [errors, setErrors] = useState({})

	const passwordResetToken = usePasswordResetToken()
	const classes = useStyles()

	useEffect(() => {
		setToken(null)
		setErrors({})
		setLoading(true)

		callApi(validatePasswordResetToken(passwordResetToken))
			.then(({ payload, error }) => {
				if (error === true) {
					throw payload
				}

				if (payload !== undefined) {
					throw new Error("Unexpected payload")
				}

				setToken(passwordResetToken)
			})
			.catch((err) => {
				setErrors(err.response ?? { boomtime: true })
			})
			.finally(() => {
				setLoading(false)
			})
	}, [passwordResetToken, callApi])

	return (
		<div className={classes.content}>
			<div className={classes.container}>
				<GridContainer justify="center" align="center">
					<GridItem xs={12} sm={6} md={4}>
						{loading && <CircularProgress />}
						{errors?.boomtime && <TokenInvalid />}

						{token && <ResetPasswordForm token={token} />}
					</GridItem>
				</GridContainer>
			</div>
		</div>
	)
}

export default compose(withCallApi)(ResetPasswordPage)

import { FC, useState } from "react"

import { PartCategory } from "@ncs/ncs-api"
import { ExtendableModalProps, Modal, Paragraph, PartCategorySelector } from "@ncs/web-legos"

export interface AddCategoryToPartModalProps extends ExtendableModalProps {
	onSave: (categoryId: string) => void
}

export const AddCategoryToPartModal: FC<AddCategoryToPartModalProps> = ({ onSave, ...rest }) => {
	const [category, setCategory] = useState<PartCategory | null>(null)

	const handleSave = () => {
		if (category) {
			onSave(category.id.toString())
			rest.onClose()
		}
	}

	const reset = () => {
		setCategory(null)
	}

	const isValid = !!category

	return (
		<Modal
			{...rest}
			title="Add Category"
			onOpen={reset}
			rightButtons={{
				buttonText: "Add Category",
				onClick: handleSave,
				disabled: !isValid,
			}}
		>
			<Paragraph mb={1}>Select a category to add to this product.</Paragraph>
			<PartCategorySelector label="Category" value={category} onChange={setCategory} />
		</Modal>
	)
}

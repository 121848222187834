export const washCountSubmissionStyles = ({ palette }) => ({
	washCountTable: {
		position: "relative",
		width: "100%",
		background: "white",
		borderCollapse: "collapse",
		lineHeight: "1.15",
		"& .header-row": {
			border: 0,
			fontSize: "10px",
			fontWeight: "bold",
			color: "#999",
			textTransform: "uppercase",
			"& th": {
				position: "sticky",
				top: 0,
				zIndex: 1,
				padding: "1.25rem 0.5rem 0.75rem 0.5rem",
				background: "rgba(255, 255, 255, 0.95)",
				textAlign: "left",
			},
		},
		"& td": {
			padding: "0.35rem 0.5rem",
		},
	},
	row: {
		border: "1px solid rgba(0, 0, 0, 0.03)",
		transition: "background-color 125ms ease-out",
		"&:nth-child(even)": {
			background: "rgba(0, 0, 0, 0.03)",
		},
		"& td": {
			verticalAlign: "middle",
		},
		"& input": {
			borderWidth: "0 0 2px 0",
			borderColor: "#ddd",
			borderStyle: "solid",
			borderRadius: "2px",
			fontFamily: "inherit",
			color: "inherit",
			fontWeight: "inherit",
			fontSize: "inherit",
			transition: "200ms ease-out",
			transitionProperty: "border-color",
			padding: "0.25rem",
			background: "#fafafa",
			"&:hover": {
				borderColor: "#aaa",
			},
			"&:focus": {
				borderColor: palette.primary.main,
			},
		},
	},
	checked: {
		background: "#e5ebf0 !important",
	},
	approvedList: {
		marginBottom: "3rem",
		"& .reporting-link": {
			display: "inline-flex",
			alignItems: "center",
			color: palette.primary.main,
			fontSize: "0.8rem",
			fontWeight: "bold",
			"& svg": {
				fontSize: "inherit",
				marginLeft: "0.5rem",
			},
		},
		"& ul": {
			margin: 0,
			padding: 0,
		},
		"& li": {
			listStyleType: "none",
			margin: 0,
			padding: 0,
			marginBottom: "0.25rem",
		},
	},
	bigMessage: {
		margin: "7rem 0 10rem 0",
		textAlign: "center",
		opacity: 0.4,
		"& svg": {
			opacity: 0.3,
			fontSize: "2.5rem",
		},
	},
})

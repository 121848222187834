import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { withRouter } from "react-router-dom"
import { handleAddToCart, handleRemoveFromCart } from "~/redux/modules/marketingMaterialsModule"
import ViewCart from "./ViewCart"

class ViewCartContainer extends React.Component {
	handleBackToList = () => {
		this.props.history.push("/marketing/materials")
	}

	handleViewCheckout = () => {
		this.props.history.push("/marketing/checkout")
	}

	render() {
		return (
			<ViewCart
				items={Object.values(this.props.cart)}
				onUpdateQuantity={this.props.handleAddToCart}
				onRemoveFromCart={this.props.handleRemoveFromCart}
				onBackToList={this.handleBackToList}
				onViewCheckout={this.handleViewCheckout}
			/>
		)
	}
}

ViewCartContainer.propTypes = {
	cart: PropTypes.object.isRequired,
	handleAddToCart: PropTypes.func.isRequired,
	handleRemoveFromCart: PropTypes.func.isRequired,
}

const mapStateToProps = ({ marketingMaterialsModule }) => {
	return {
		...marketingMaterialsModule,
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators({ handleAddToCart, handleRemoveFromCart }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ViewCartContainer))

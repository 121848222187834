import React, { Component } from "react"

import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { bindActionCreators } from "redux"

import { getTicket } from "@ncs/mortar/redux/services/ticketing"
import callApi from "@ncs/bricks/redux/services/callApi"

import {
	ButtonWithIcon,
	ConditionalContent,
	GridContainer,
	GridItem,
	LoadingWrapper,
} from "~/components"

import TicketCommentAddContainer from "./TicketCommentAddContainer"
import TicketCommentContainer from "./TicketCommentContainer"
import TicketInfoContainer from "./TicketInfoContainer"

import AddIcon from "@material-ui/icons/Add"
import ChevronLeft from "@material-ui/icons/ChevronLeft"

class TicketDetails extends Component {
	constructor(props) {
		super(props)
		this.state = this.getDefaultState()
	}

	getDefaultState = () => ({
		ticket: {},
		isLoadingTicket: false,
		isEditing: false,
		activeTabIndex: 0,
		isAddingComment: false,
		isService: false,
	})

	componentDidMount = () => {
		this.handleChangeTicket()
	}

	//respond to a change in selected ticket
	componentDidUpdate = (prevProps, prevState) => {
		if (this.getIdFromUrlParams() !== this.getIdFromUrlParams(prevProps)) {
			this.handleChangeTicket()
		}
	}
	handleChangeTicket = () => {
		this.fetchTicket()
	}

	handleCommentAddCancel = () => {
		this.setState({ isAddingComment: false })
	}

	fetchTicket = () => {
		this.setState({ isLoadingTicket: true })
		let id = this.getIdFromUrlParams()
		this.props.callApi(getTicket(id)).then(({ payload: ticket }) => {
			this.setState({
				ticket,
				isLoadingTicket: false,
				isService: ticket.ticket_type.group.id === 3 ? true : false,
			})
		})
	}
	handleAddComment = () => {
		this.setState({ isAddingComment: true })
	}
	handleCommentCreate = () => {
		this.fetchTicket()
		this.setState({ isAddingComment: false })
	}
	handleBackToList = () => {
		this.props.history.goBack()
	}

	getIdFromUrlParams = (props = this.props) => props.match.params.id

	render() {
		return (
			<React.Fragment>
				<LoadingWrapper isLoading={this.state.isLoadingTicket} title="Fetching..." />
				<ButtonWithIcon
					content="Back to List"
					onClick={this.handleBackToList}
					size="sm"
					color="white"
					round
					icon={<ChevronLeft />}
				/>
				<ConditionalContent show={Object.keys(this.state.ticket).length > 0}>
					{() => (
						<GridContainer>
							<GridItem xs={12}>
								<TicketInfoContainer
									ticket={this.state.ticket}
									refreshTicket={this.fetchTicket}
								/>
							</GridItem>
							<GridItem xs={12}>
								<ButtonWithIcon
									icon={<AddIcon />}
									content="Add Comment"
									size="sm"
									round
									color="primary"
									onClick={this.handleAddComment}
								/>
							</GridItem>
							<GridItem xs={12}>
								{this.state.ticket.comments.map((comment) => (
									<TicketCommentContainer
										key={comment.id}
										isService={this.state.isService}
										comment={comment}
										refreshTicket={this.fetchTicket}
										ticketCustomer={this.state.ticket.customer_id}
										hasDocumentationEmail={
											this.state.ticket.customer_id ?
												(
													this.state.ticket.customer
														.documentation_email === null
												) ?
													false
												:	true
											:	false
										}
										documentationEmail={
											this.state.ticket.customer_id ?
												this.state.ticket.customer.documentation_email
											:	null
										}
									/>
								))}
							</GridItem>
							<GridItem xs={12} show={this.state.ticket.comments.length > 0}>
								<ButtonWithIcon
									icon={<AddIcon />}
									content="Add Comment"
									size="sm"
									round
									color="primary"
									onClick={this.handleAddComment}
								/>
							</GridItem>
							<GridItem xs={12}>
								<GridContainer>
									<GridItem xs={12} show={this.state.isAddingComment}>
										<TicketCommentAddContainer
											onCommentAddCancel={this.handleCommentAddCancel}
											ticket={this.state.ticket}
											onCommentCreate={this.handleCommentCreate}
											isService={this.state.isService}
											ticketCustomer={this.state.ticket.customer_id}
										/>
									</GridItem>
								</GridContainer>
							</GridItem>
						</GridContainer>
					)}
				</ConditionalContent>
			</React.Fragment>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		selectedTicket: state.ticket,
	}
}

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ callApi, getTicket }, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TicketDetails))

import { ConnectedDevice, ConnectivityDeviceTypeId } from "@ncs/ncs-api"

export const c12yAutoRefreshConfig = {
	staleTime: 1000 * 30,
	refetchInterval: 1000 * 30,
	refetchIntervalInBackground: false,
	refetchOnWindowFocus: true,
}

export const getConnectivityDeviceDetailUrl = (device: ConnectedDevice): string | null => {
	let deviceTypeUrl = ""

	if (device.deviceTypeId.toString() === ConnectivityDeviceTypeId.Machine) {
		deviceTypeUrl = "machines"
	} else if (device.deviceTypeId.toString() === ConnectivityDeviceTypeId.Vacuum) {
		deviceTypeUrl = "vacuums"
	} else if (device.deviceTypeId.toString() === ConnectivityDeviceTypeId.WashCount) {
		deviceTypeUrl = "wash-counters"
	}

	if (deviceTypeUrl) {
		return `/connectivity/connected-devices/${deviceTypeUrl}/${device.id}`
	}

	return null
}

import { FC, useMemo } from "react"

import {
	PriceOverrideReason,
	PriceOverrideReasonTypeId,
	usePriceOverrideReasons,
} from "@ncs/ncs-api"
import { Box, ExtendableSelectProps, ReactStateDispatch, Select, Textarea } from "@ncs/web-legos"

export type PriceOverrideReasonSelectorProps = ExtendableSelectProps<PriceOverrideReason> & {
	otherReasonValue: string | null
	otherReasonOnChange: ReactStateDispatch<string | null>
	typeFilter?: PriceOverrideReasonTypeId
}

export const PriceOverrideReasonSelector: FC<PriceOverrideReasonSelectorProps> = ({
	otherReasonValue,
	otherReasonOnChange,
	typeFilter,
	mb,
	mt,
	...rest
}) => {
	const [reasons, reasonsLoading] = usePriceOverrideReasons()

	const options = useMemo(() => {
		if (typeFilter) {
			return (reasons ?? []).filter((reason) => reason.reasonTypeId === typeFilter)
		}

		return reasons ?? []
	}, [reasons, typeFilter])

	const showTextInput = useMemo(() => {
		return options.find((option) => option.id === rest.value)?.textField
	}, [rest.value, options])

	return (
		<>
			<div>
				<Box
					d={rest.fillContainer ? "flex" : "inline-flex"}
					flexDirection="column"
					mt={mt}
					mb={mb}
				>
					<Select
						label="Price change reason (required)"
						mb={1}
						fillContainer={false}
						{...rest}
						options={options}
						isLoading={reasonsLoading}
						valueAccessor={(option) => option.id.toString()}
						textAccessor="description"
					/>

					{showTextInput && (
						<Textarea
							value={otherReasonValue}
							onChange={otherReasonOnChange}
							label="Other reason"
							fillContainer
							mb={1}
						/>
					)}
				</Box>
			</div>
		</>
	)
}

import { Dispatch, ReactElement, SetStateAction } from "react"

import { PosPartFeaturedSelector } from "../selectors"

export interface PosPartFeaturedQueryParams {
	featured: boolean | null
}

export interface PosPartFeaturedQueryFilterProps<
	QueryParamState extends PosPartFeaturedQueryParams,
> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

export const PosPartFeaturedQueryFilter = <QueryParamState extends PosPartFeaturedQueryParams>({
	queryParamState,
	setQueryParamState,
}: PosPartFeaturedQueryFilterProps<QueryParamState>): ReactElement => {
	const handleChange = (newValue: string | null) => {
		setQueryParamState((prev) => ({
			...prev,
			featured: newValue === "featured" || null,
		}))
	}

	return (
		<PosPartFeaturedSelector
			value={queryParamState.featured ? "featured" : null}
			onChange={handleChange}
			fillContainer
			disableNoSelectionOption={false}
		/>
	)
}

import { Dispatch, ReactElement, SetStateAction, useMemo } from "react"

import { noPortalUserAssignedId, PortalUserSelector } from "../selectors"

interface PortalUserQueryParams {
	billToCustomer_PortalAssignment_AssignedEmployeeId: string | null
	noPortalUser: true | null
}

export interface PortalUserQueryFilterProps<QueryParamState extends PortalUserQueryParams> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

export const PortalUserQueryFilter = <QueryParamState extends PortalUserQueryParams>({
	queryParamState,
	setQueryParamState,
}: PortalUserQueryFilterProps<QueryParamState>): ReactElement => {
	const value = useMemo(() => {
		if (queryParamState.noPortalUser) {
			return noPortalUserAssignedId
		} else {
			return queryParamState.billToCustomer_PortalAssignment_AssignedEmployeeId
		}
	}, [
		queryParamState.noPortalUser,
		queryParamState.billToCustomer_PortalAssignment_AssignedEmployeeId,
	])

	const handleChange = (newValue: string | null) => {
		setQueryParamState((prev) => ({
			...prev,
			billToCustomer_PortalAssignment_AssignedEmployeeId:
				newValue === noPortalUserAssignedId ? null : newValue,
			noPortalUser: newValue === noPortalUserAssignedId ? true : null,
		}))
	}

	return (
		<PortalUserSelector
			value={value}
			onChange={handleChange}
			disableNoSelectionOption={false}
			fillContainer
		/>
	)
}

import { FC, memo, useState } from "react"

import {
	makeApiErrorMessage,
	PartGroup,
	useCreatePartGroup,
	useUpdatePartGroup,
} from "@ncs/ncs-api"
import { unpythonify } from "@ncs/ts-utils"
import { ExtendableModalProps, Modal, RadioBoolean, TextInput, useToast } from "@ncs/web-legos"

export interface EditPartGroupModalProps extends ExtendableModalProps {
	groupToEdit: PartGroup | null
	createCallback: (newId: string) => void
}

export const EditPartGroupModal: FC<EditPartGroupModalProps> = memo(
	({ groupToEdit, createCallback, ...rest }) => {
		const [name, setName] = useState<string | null>(null)
		const [isReusable, setIsReusable] = useState(false)

		const createGroup = useCreatePartGroup()
		const updateGroup = useUpdatePartGroup()

		const { makeSuccessToast } = useToast()
		const [isLoading, setIsLoading] = useState(false)
		const [errorText, setErrorText] = useState<string | null>(null)

		const onCreate = async () => {
			if (!name) throw new Error("Saving when form is invalid")

			try {
				setIsLoading(true)
				const response = await createGroup({
					description: name,
					isReusableGroup: isReusable,
				})
				makeSuccessToast("Part group created")
				const newGroup = unpythonify(response.data)
				createCallback(newGroup.id)
				rest.onClose()
			} catch (e) {
				setErrorText(makeApiErrorMessage(e))
				setIsLoading(false)
			}
		}

		const onUpdate = async () => {
			if (!groupToEdit) throw new Error("Updating without a group")
			if (!name) throw new Error("Saving when form is invalid")

			try {
				setIsLoading(true)
				await updateGroup({
					id: groupToEdit.id,
					updates: {
						description: name,
						isReusableGroup: isReusable,
					},
				})
				makeSuccessToast("Part group updated")
				rest.onClose()
			} catch (e) {
				setErrorText(makeApiErrorMessage(e))
				setIsLoading(false)
			}
		}

		const reset = () => {
			setName(groupToEdit?.description ?? null)
			setIsReusable(groupToEdit?.isReusableGroup ?? true)
			setIsLoading(false)
			setErrorText(null)
		}

		const isEditing = !!groupToEdit
		const isValid = !!name

		return (
			<Modal
				{...rest}
				title={isEditing ? "Edit Part Group" : "Create Part Group"}
				onOpen={reset}
				errorText={errorText}
				rightButtons={{
					buttonText: isEditing ? "Save Changes" : "Create",
					onClick: isEditing ? onUpdate : onCreate,
					disabled: !isValid,
					isLoading,
				}}
			>
				<TextInput
					value={name}
					onChange={setName}
					label="Part group name"
					maxLength={255}
				/>
				<RadioBoolean
					htmlName="reusable"
					description="Can this part group be assigned to customers in the future?"
					value={isReusable}
					onChange={setIsReusable}
					mt={1}
				/>
			</Modal>
		)
	}
)

import { FC, useCallback, useMemo, useRef, useState } from "react"

import { captureMessage } from "@sentry/react"
import dayjs, { Dayjs } from "dayjs"
import { nanoid } from "nanoid"

import {
	APPLICATION,
	BolBillTo,
	LineItemType,
	LtlCarrierId,
	PartOrder,
	PartOrderShipment,
	UpdateShipmentPatch,
	useUserCanUse,
} from "@ncs/ncs-api"
import { extractNumber } from "@ncs/ts-utils"
import { ShippingMethod, useChangeCallback } from "@ncs/web-legos"

import {
	FedExUiName,
	LinePackagesState,
	PackagesState,
	ShipShipmentContext,
	ShipShipmentState,
} from "~/util/ship-shipment"

export interface ShipShipmentContextProviderProps {
	shipment: PartOrderShipment
	partOrder: PartOrder
}

export const ShipShipmentContextProvider: FC<ShipShipmentContextProviderProps> = ({
	shipment,
	partOrder,
	children,
}) => {
	const hasPackagePermission = useUserCanUse(APPLICATION.PackageShipping)
	const locationCanFedEx = !!shipment.fedexIsAuthorized

	// Store the first pass at making packages from shipment so we can use it to
	//initialize both packages state and line packages state.
	const initialPackages = useRef(makePackagesFromShipment(shipment))

	const [selectedCarrierId, setSelectedCarrierId] = useState(shipment.ltlCarrierId ?? null)
	const [newCarrierName, setNewCarrierName] = useState<string | null>(null)

	const [portalFedEx, setPortalFedEx] = useState(locationCanFedEx && hasPackagePermission)
	const usingPortalFedEx = useMemo(() => {
		return (
			hasPackagePermission &&
			!!locationCanFedEx &&
			selectedCarrierId === LtlCarrierId.FedEx &&
			portalFedEx
		)
	}, [portalFedEx, selectedCarrierId, hasPackagePermission, locationCanFedEx])
	const [usingPackages, setUsingPackages] = useState(
		() => hasPackagePermission && shipment.packageMode
	)
	const [packages, setPackages] = useState<PackagesState>(initialPackages.current)
	const [linePackages, setLinePackages] = useState(() =>
		makeLinePackagesState(shipment, initialPackages.current)
	)
	const [fedExMethod, setFedExMethod] = useState<FedExUiName>(() =>
		getFedExUiNameFromPortalMethod(shipment.shipmentMethod?.id || partOrder.shipMethod.id)
	)
	const [scheduledShipDate, setScheduledShipDate] = useState<Dayjs | null>(
		shipment.scheduledShipDate ? dayjs(shipment.scheduledShipDate) : dayjs()
	)

	const [geodis220ReceivedOn, setGeodis220ReceivedOn] = useState<Dayjs | null>(
		shipment.geodis220ReceivedOn ? dayjs(shipment.geodis220ReceivedOn) : null
	)

	const [verifyStepComplete, setVerifyStepComplete] = useState(false)
	const [carrierStepComplete, setCarrierStepComplete] = useState(false)
	const [packItemsStepComplete, setPackItemsStepComplete] = useState(false)
	const carrierSectionReady = verifyStepComplete
	const packItemsSectionReady = carrierStepComplete
	const configureSendSectionReady = packItemsStepComplete

	const [selectedBol, setSelectedBol] = useState(() => getBolBillTo(shipment.bolBillTo))
	const [trackingNumber, setTrackingNumber] = useState(shipment.trackingNumber)
	const [freightCharge, setFreightCharge] = useState(shipment.freight)
	const [addFreightToInvoice, setAddFreightToInvoice] = useState(
		shipment.freightBillable ?? true
	)
	const [proNumber, setProNumber] = useState(shipment.proNumber || null)
	const [numberOfPallets, setNumberOfPallets] = useState(shipment.numberOfPallets || null)
	const [error, setError] = useState<string | null>(null)

	// If an order already has a freight line on it, we hide the UI for adding one.
	const canAddFreightLine = useMemo(() => {
		return partOrder.lineItems.every((line) => line.lineTypeId !== LineItemType.Freight)
	}, [partOrder.lineItems])

	// Watch if user is using portal fedex (aka they have fedex selected and have integrated fedex selected).
	useChangeCallback(
		usingPortalFedEx,
		(newUsingPortalFedEx) => {
			if (newUsingPortalFedEx) {
				setUsingPackages(true)
			}
		},
		{ callOnSetup: true }
	)

	const makeSaveShipmentPayload = useCallback(
		(shouldShip: boolean): UpdateShipmentPatch => {
			if (!scheduledShipDate) {
				throw new Error("Expected ship date is required")
			}
			if (!!trackingNumber && !selectedCarrierId) {
				throw new Error("Carrier is required when tracking info is provided")
			}
			if (selectedCarrierId === LtlCarrierId.Other && !newCarrierName) {
				throw new Error('Carrier name required when "Other" is selected')
			}
			if (!usingPortalFedEx) {
				if (!selectedBol) {
					throw new Error("No BOL bill-to selected")
				}
			}

			if (usingPackages) {
				Object.values(linePackages).forEach((line) => {
					if (!line.packageQuantity) return

					if (line.packageTempId == null) {
						throw new Error(`Item ${line.partNumber} does not have a package selected`)
					}

					if (
						!!line.packageQuantity &&
						Object.values(linePackages).some((otherLine) => {
							return (
								otherLine.tempId !== line.tempId &&
								!!otherLine.packageQuantity &&
								otherLine.partOrderLineId === line.partOrderLineId &&
								otherLine.packageTempId === line.packageTempId
							)
						})
					) {
						throw new Error(
							"The same part should not be assigned to the same package across multiple rows"
						)
					}

					const pkg = packages[line.packageTempId]

					if (!pkg) {
						captureMessage("No package found matching temp ID. This should not happen")
						throw new Error("An invalid package ID is associated with a line item")
					}
					if (pkg.weight == null) {
						throw new Error("All packages being used must have a weight entered")
					}
				})

				// Total up the package quantities of each LRTS across any potential clones.
				const lineItemQuantities: Record<string, number> = {}
				Object.values(linePackages).forEach(({ lineItemId, packageQuantity }) => {
					lineItemQuantities[lineItemId] =
						(lineItemQuantities[lineItemId] ?? 0) + (packageQuantity || 0)
				})
				// Now loop through original shipment lines and compare quantities.
				shipment.lines.forEach((originalLine) => {
					const matchingQuantity = lineItemQuantities[originalLine.id]
					if (originalLine.quantity < matchingQuantity) {
						throw new Error(
							`The total quantity entered for part ${originalLine.partNumber} is greater than the amount in the shipment`
						)
					}
					if (originalLine.quantity > matchingQuantity) {
						throw new Error(
							`Not all quantities of part ${originalLine.partNumber} have been added to packages`
						)
					}
				})
			}

			return {
				sendShipment: shouldShip,
				shipment: shipment.shipmentId,
				bolBillTo: selectedBol,
				carrier: selectedCarrierId,
				newCarrier: newCarrierName,
				trackingNumber,
				freight: canAddFreightLine ? freightCharge : null,
				freightInvoiced: canAddFreightLine ? addFreightToInvoice : null,
				itemsToShip: Object.values(linePackages).flatMap(
					(line): UpdateShipmentPatch["itemsToShip"] => {
						const item: UpdateShipmentPatch["itemsToShip"][number] = {
							id: line.isClone ? null : line.lineItemId,
							partOrderLineId: line.partOrderLineId,
							quantity: line.originalQuantity,
							packageInfo: null,
						}

						if (usingPackages) {
							if (!line.packageQuantity || !line.packageTempId) {
								return []
							}

							item.quantity = line.packageQuantity
							const pkg = packages[line.packageTempId]
							item.packageInfo = {
								// This will be here if using a package that was already assigned to the shipment.
								packageId: pkg.packageId,

								// Field should be present after validation above.
								weight: pkg.weight || 0,

								packageItemId: pkg.typeId,
								newPackageName: pkg.name,
							}
						}

						return [item]
					}
				),
				numberOfPallets,
				proNumber,
				geodis220ReceivedOn: geodis220ReceivedOn ? geodis220ReceivedOn.toISOString() : "",
				packageMode: usingPackages,
				orderShipMethodId:
					usingPortalFedEx ?
						getPortalMethodIdFromFedExUiName(fedExMethod)
					:	shipment.shipmentMethod?.id || partOrder.shipMethod.id,
				shipmentModified: true,
				scheduledShipDate: scheduledShipDate.toISOString(),
			}
		},
		[
			addFreightToInvoice,
			freightCharge,
			linePackages,
			newCarrierName,
			numberOfPallets,
			usingPackages,
			packages,
			proNumber,
			selectedBol,
			selectedCarrierId,
			shipment.lines,
			shipment.shipmentId,
			trackingNumber,
			usingPortalFedEx,
			fedExMethod,
			shipment.shipmentMethod?.id,
			partOrder.shipMethod.id,
			canAddFreightLine,
			scheduledShipDate,
			geodis220ReceivedOn,
		]
	)

	const resetPackingState = (newShipment = shipment) => {
		const newPackages = makePackagesFromShipment(newShipment)
		setPackages(newPackages)
		setLinePackages(makeLinePackagesState(newShipment, newPackages))
	}

	useChangeCallback(shipment, (newShipment) => {
		resetPackingState(newShipment)
	})

	const state: ShipShipmentState = {
		partOrder,
		canAddFreightLine,
		shipment,
		hasPackagePermission,
		locationCanFedEx,
		selectedCarrierId,
		setSelectedCarrierId,
		newCarrierName,
		setNewCarrierName,
		portalFedEx,
		setPortalFedEx,
		usingPortalFedEx,
		usingPackages,
		setUsingPackages,
		packages,
		setPackages,
		linePackages,
		fedExMethod,
		setFedExMethod,
		setLinePackages,
		verifyStepComplete,
		setVerifyStepComplete,
		carrierStepComplete,
		setCarrierStepComplete,
		packItemsStepComplete,
		setPackItemsStepComplete,
		carrierSectionReady,
		packItemsSectionReady,
		configureSendSectionReady,
		selectedBol,
		setSelectedBol,
		trackingNumber,
		setTrackingNumber,
		freightCharge,
		setFreightCharge,
		addFreightToInvoice,
		setAddFreightToInvoice,
		proNumber,
		setProNumber,
		numberOfPallets,
		setNumberOfPallets,
		scheduledShipDate,
		setScheduledShipDate,
		makeSaveShipmentPayload,
		resetPackingState,
		error,
		setError,
		geodis220ReceivedOn,
		setGeodis220ReceivedOn,
	}

	return <ShipShipmentContext.Provider value={state}>{children}</ShipShipmentContext.Provider>
}

/**
 * The shipment lines might have references to packages on some of their lines. Seed our
 * packages state with these boxes.
 */
const makePackagesFromShipment = (shipment: PartOrderShipment): PackagesState => {
	if (shipment.lines.every((l) => !l.package)) {
		return {}
	}

	const state: PackagesState = {}

	shipment.lines.forEach((line) => {
		if (
			!!line.package?.id &&
			Object.values(state).every((pkg) => pkg.packageId !== line.package?.id)
		) {
			const tempId = nanoid()

			state[tempId] = {
				tempId,
				packageId: line.package.id,
				name: line.package.name,
				weight: extractNumber(line.package.weight) || null,
				typeId: line.package.packageItem?.id ?? null,
			}
		}
	})

	return state
}

/**
 * State for tracking which shipment line items are being tied to which packages.
 */
const makeLinePackagesState = (
	shipment: PartOrderShipment,
	packages: PackagesState
): LinePackagesState => {
	const state: LinePackagesState = {}

	shipment.lines.forEach((line) => {
		const tempId = nanoid()

		// If the shipment line has a package, look for it in our package state.
		const linePackage = Object.values(packages).find(
			(pkg) => pkg.packageId === line.package?.id
		)

		state[tempId] = {
			tempId,
			lineItemId: line.id,
			partOrderLineId: line.partOrderLineId,
			partNumber: line.partNumber,
			partName: line.description,
			originalQuantity: line.quantity,
			packageQuantity: line.quantity,
			packageTempId: linePackage?.tempId ?? null,
			isClone: false,
		}
	})

	return state
}

const getFedExUiNameFromPortalMethod = (
	portalMethodId: string | null | undefined
): FedExUiName => {
	switch (portalMethodId) {
		// Next-day maps to overnight.
		case ShippingMethod.NextDay: {
			return FedExUiName.Overnight
		}
		// Everything else maps to ground.
		default: {
			return FedExUiName.Ground
		}
	}
}

const getPortalMethodIdFromFedExUiName = (method: FedExUiName): ShippingMethod => {
	switch (method) {
		// Overnight maps to next day.
		case FedExUiName.Overnight: {
			return ShippingMethod.NextDay
		}
		// Ground maps to ground.
		case FedExUiName.Ground: {
			return ShippingMethod.Ground
		}
	}
}

const getBolBillTo = (text: string | null | undefined): BolBillTo | null => {
	if (!text) {
		return null
	}
	if (Object.values(BolBillTo).includes(text as BolBillTo)) {
		return text as BolBillTo
	}
	console.error(
		`An unexpected string was provided as a BolBillTo enum member: "${text}". Returning null instead.`
	)
	return null
}

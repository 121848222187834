import { FC, useEffect, useState } from "react"

import { makeApiErrorMessage, useUploadCycleCountSheet } from "@ncs/ncs-api"
import {
	Box,
	ExtendableModalProps,
	FileInput,
	FileType,
	IconButton,
	Modal,
	Paragraph,
	useToast,
} from "@ncs/web-legos"

export interface ImportCycleCountModalProps extends ExtendableModalProps {
	locationId: string
}

export const ImportCycleCountModal: FC<ImportCycleCountModalProps> = ({ locationId, ...rest }) => {
	const { makeSuccessToast } = useToast()
	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)
	const [file, setFile] = useState<File | null>(null)

	const submitCount = useUploadCycleCountSheet()

	const handleSubmit = async () => {
		try {
			if (!file) throw new Error("No file selected")

			setIsSaving(true)
			await submitCount({
				locationId,
				countData: file,
			})
			makeSuccessToast(
				"Counts received. Inventory and Physical Inventory screens will include new data in a few minutes when processing completes"
			)
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	useEffect(() => {
		setErrorText(null)
	}, [file])

	const reset = () => {
		setIsSaving(false)
		setErrorText(null)
		setFile(null)
	}

	return (
		<Modal
			{...rest}
			onOpen={reset}
			title="Upload Completed Cycle Count Sheet"
			errorText={errorText}
			rightButtons={{
				buttonText: "Submit File",
				isLoading: isSaving,
				onClick: handleSubmit,
				disabled: !file,
			}}
		>
			<Paragraph mb={2}>
				Select the completed Excel file for the requested part cycle counts.
			</Paragraph>

			{file ?
				<Box display="flex" columnGap={1} alignItems="center">
					<Paragraph bold>{file.name}</Paragraph>
					<IconButton icon="trash-alt" onClick={() => setFile(null)} />
				</Box>
			:	<FileInput onChange={setFile} fileTypes={[FileType.Excel]} />}
		</Modal>
	)
}

import React from "react"
import PropTypes from "prop-types"
import MarketingMaterials from "./MarketingMaterials"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { bindActionCreators } from "redux"
import isEqual from "lodash/isEqual"
import { setStateKeyFromEventTargetValue, setStateKeyFromValue } from "@ncs/bricks/util/state"
import { APPLICATIONS } from "@ncs/mortar/util/constants"
import { meetsAppRestriction } from "@ncs/bricks/redux/selectors/auth"
import { loadLookups, LOOKUPS } from "@ncs/mortar/redux/services/lookups"
import {
	deleteMaterial,
	generateMaterialLink,
	addCategory,
	editCategory,
	deleteCategory,
} from "@ncs/mortar/redux/services/marketing"
import {
	fetchMaterials,
	handleSearchChange,
	handleSortedChange,
	handlePageChange,
	handlePageSizeChange,
	handleExpandedChange,
	handleResizedChange,
	handleFilteredChange,
	handleCreatedDateChange,
	handleCategoryChange,
	handleDocumentTypeChange,
	resetFilters,
	handleToggleCategorySidebar,
	handleToggleColumn,
	setSelectedColumns,
	COLUMNS,
	handleAddToCart,
} from "~/redux/modules/marketingMaterialsModule"
import callApi from "@ncs/bricks/redux/services/callApi"
import Clipboard from "clipboard"

class MarketingMaterialsContainer extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isConfirmingDelete: false,
			deletingItem: null,
			isSaving: false,
			isGeneratingLink: false,
			generatedLink: null,
			isAddingToCart: false,
			addingToCartItem: null,
			addingToCartQuantity: 10,
			showAddedToCart: false,
			isCreatingCategory: false,
			creatingForParentCategory: null,
			isEditingCategory: false,
			editingCategory: null,
			categoryName: "",
			isSavingCategory: false,
			isRemovingCategory: false,
		}
	}

	componentDidMount() {
		this.clipboard = new Clipboard("#clipboardButton", {})

		if (!this.props.materials.length) {
			this.props.fetchMaterials()
		}

		this.props.loadLookups([LOOKUPS.MarketingMaterialCategories])

		this.enableAdminColumns()
	}

	componentWillUnmount() {
		this.clipboard && this.clipboard.destroy()
	}

	enableAdminColumns = () => {
		if (this.props.isMarketingMaterialAdmin()) {
			if (
				!isEqual(this.props.selectedColumns, [
					COLUMNS.Preview,
					COLUMNS.Filename,
					COLUMNS.Actions,
				])
			)
				return

			this.props.setSelectedColumns([
				COLUMNS.Preview,
				COLUMNS.Filename,
				COLUMNS.Category,
				COLUMNS.DateCreated,
				COLUMNS.IsOrderable,
				COLUMNS.Actions,
			])
		}
	}

	handleViewPreview = (item) => {
		this.props.history.push(`/marketing/preview/${item.id}`)
	}

	handleDownload = (item) => {
		window.open(item.presign_url)
	}

	handleGenerateLink = (item) => {
		this.setState({ isGeneratingLink: true })
		this.props.callApi(generateMaterialLink(item.id)).then(({ payload: result }) => {
			this.setState({ isGeneratingLink: false, generatedLink: result.presign_url })
		})
	}

	handleCloseGeneratedLink = () => {
		this.setState({ isGeneratingLink: false, generatedLink: null })
	}

	handleDelete = (deletingId) => {
		this.setState({
			isConfirmingDelete: true,
			deletingItem: this.props.materials.find((x) => x.id === deletingId),
		})
	}

	handleCancelDelete = () => {
		this.setState({ isSaving: false, isConfirmingDelete: false, deletingItem: null })
	}

	handleConfirmDelete = (confirmationCallback) => {
		if (!this.state.deletingItem || !this.state.deletingItem.id) return

		this.setState({ isSaving: true })
		this.props.callApi(deleteMaterial(this.state.deletingItem.id)).then(() => {
			this.handleCancelDelete()

			if (typeof confirmationCallback === "function") {
				confirmationCallback()
			}

			this.props.fetchMaterials()
		})
	}

	handleStartAddingToCart = (addingToCartItem) => {
		this.setState({ isAddingToCart: true, addingToCartItem })
	}

	handleCancelAddingToCart = () => {
		this.setState({ isAddingToCart: false, addingToCartItem: null })
	}

	handleFinishAddingToCart = () => {
		this.props.handleAddToCart(this.state.addingToCartItem, this.state.addingToCartQuantity)
		this.setState({ isAddingToCart: false, showAddedToCart: true })
	}

	handleCloseAddedToCart = () => {
		this.setState({ showAddedToCart: false, addingToCartItem: null, addingToCartQuantity: 10 })
	}

	handleViewCart = () => {
		this.props.history.push("/marketing/cart/")
	}

	getSelectedCategoryTree = (categoryId = this.props.selectedCategory) => {
		for (let top of this.props.materialCategories) {
			if (top.id === categoryId) {
				return { top, mid: null, bottom: null }
			}

			for (let mid of top.sub_categories) {
				if (mid.id === categoryId) {
					return { top, mid, bottom: null }
				}

				for (let bottom of mid.sub_categories) {
					if (bottom.id === categoryId) {
						return { top, mid, bottom }
					}
				}
			}
		}

		return { top: null, mid: null, bottom: null }
	}

	handleCategoryUpdate = (e) => {
		switch (e) {
			case "add-new-tier-1":
				return this.handleCreateCategory(null)
			case "add-new-tier-2":
				return this.handleCreateCategory(this.getSelectedCategoryTree().top)
			case "add-new-tier-3":
				return this.handleCreateCategory(this.getSelectedCategoryTree().mid)
			default:
				return this.props.handleCategoryChange(Number(e))
		}
	}

	handleCreateCategory = (creatingForParentCategory) => {
		return this.setState({
			isCreatingCategory: true,
			creatingForParentCategory,
			categoryName: "",
		})
	}

	handleEditCategory = (editingCategory) => {
		this.setState({
			isEditingCategory: true,
			editingCategory,
			categoryName: editingCategory.name,
		})
	}

	handleCancelEditingCategory = () => {
		this.setState({ isCreatingCategory: false, isEditingCategory: false })
	}

	handleSaveCategory = () => {
		let promise =
			this.state.isCreatingCategory ?
				addCategory(
					(this.state.creatingForParentCategory || { id: null }).id,
					this.state.categoryName
				)
			:	editCategory(this.state.editingCategory.id, this.state.categoryName)

		this.setState({ isSavingCategory: true })
		this.props.callApi(promise).then((result) => {
			this.props.loadLookups([LOOKUPS.MarketingMaterialCategories])

			if (this.state.isCreatingCategory) {
				let categoryId =
					(result.payload || { id: null }).id ||
					this.state.creatingForParentCategory ||
					-1
				this.props.handleCategoryChange(categoryId)
			}

			this.setState({
				isSavingCategory: false,
				isCreatingCategory: false,
				isEditingCategory: false,
				categoryName: "",
			})
		})
	}

	handleStartRemovingCategory = () => {
		this.setState({ isRemovingCategory: true })
	}

	handleRemoveCategory = () => {
		this.setState({ isSavingCategory: true })

		let tree = this.getSelectedCategoryTree()
		let parentId =
			tree.bottom ? tree.mid.id
			: tree.mid ? tree.top.id
			: -1

		this.props.callApi(deleteCategory(this.props.selectedCategory)).then(() => {
			this.props.loadLookups([LOOKUPS.MarketingMaterialCategories])
			this.props.handleCategoryChange(parentId)
			this.setState({
				isSavingCategory: false,
				isRemovingCategory: false,
			})
		})
	}

	render = () => (
		<MarketingMaterials
			isAdmin={this.props.isMarketingMaterialAdmin()}
			isLoading={this.props.isLoadingMaterials}
			isSaving={this.state.isSaving}
			materials={this.props.materials}
			onViewPreview={this.handleViewPreview}
			onDownload={this.handleDownload}
			onStartAddingToCart={this.handleStartAddingToCart}
			isAddingToCart={this.state.isAddingToCart}
			addingToCartItem={this.state.addingToCartItem}
			addingToCartQuantity={this.state.addingToCartQuantity}
			onAddingToCartQuantityChange={setStateKeyFromValue(this, "addingToCartQuantity")}
			onCancelAddingToCart={this.handleCancelAddingToCart}
			onFinishAddingToCart={this.handleFinishAddingToCart}
			showAddedToCart={this.state.showAddedToCart}
			onCloseAddedToCart={this.handleCloseAddedToCart}
			onViewCart={this.handleViewCart}
			cart={this.props.cart}
			onGenerateLink={this.handleGenerateLink}
			onCloseGeneratedLink={this.handleCloseGeneratedLink}
			isGeneratingLink={this.state.isGeneratingLink}
			generatedLink={this.state.generatedLink}
			isConfirmingDelete={this.state.isConfirmingDelete}
			deletingItem={this.state.deletingItem}
			onDelete={this.handleDelete}
			onCancelDelete={this.handleCancelDelete}
			onConfirmDelete={this.handleConfirmDelete}
			showCategorySidebar={this.props.showCategorySidebar}
			onToggleCategorySidebar={this.props.handleToggleCategorySidebar}
			columns={COLUMNS}
			availableColumns={this.props.availableColumns}
			selectedColumns={this.props.selectedColumns}
			toggleColumn={this.props.handleToggleColumn}
			// keep react table data in redux
			search={this.props.search}
			dateRanges={this.props.dateRanges}
			selectedCreatedDateRange={this.props.selectedCreatedDateRange}
			availableDocumentTypeFilters={this.props.availableDocumentTypeFilters}
			selectedDocumentTypeFilter={this.props.selectedDocumentTypeFilter}
			categories={this.props.materialCategories}
			selectedCategory={this.props.selectedCategory}
			sorted={this.props.sorted}
			page={this.props.page}
			pageSize={this.props.pageSize}
			expanded={this.props.expanded}
			resized={this.props.resized}
			filtered={this.props.filtered}
			onSearchChange={this.props.handleSearchChange}
			onCreatedDateRangeChange={this.props.handleCreatedDateChange}
			onCategoryChange={this.handleCategoryUpdate}
			onDocumentTypeChange={this.props.handleDocumentTypeChange}
			onSortedChange={this.props.handleSortedChange}
			onPageChange={this.props.handlePageChange}
			onPageSizeChange={this.props.handlePageSizeChange}
			onExpandedChange={this.props.handleExpandedChange}
			onResizedChange={this.props.handleResizedChange}
			onFilteredChange={this.props.handleFilteredChange}
			onResetFilters={this.props.resetFilters}
			pages={this.props.materialsPages || this.props.page + 1}
			selectedCategoryTree={this.getSelectedCategoryTree(this.props.selectedCategory)}
			onCreateCategory={this.handleCreateCategory}
			isCreatingCategory={this.state.isCreatingCategory}
			onEditCategory={this.handleEditCategory}
			isEditingCategory={this.state.isEditingCategory}
			editingCategory={this.state.editingCategory}
			categoryName={this.state.categoryName}
			onCategoryNameChange={setStateKeyFromEventTargetValue(this, "categoryName")}
			onCancelEditingCategory={this.handleCancelEditingCategory}
			isSavingCategory={this.state.isSavingCategory}
			onSaveCategory={this.handleSaveCategory}
			currentCategory={this.props.materialCategory}
			isRemovingCategory={this.state.isRemovingCategory}
			onStartRemovingCategory={this.handleStartRemovingCategory}
			onRemoveCategory={this.handleRemoveCategory}
		/>
	)
}

MarketingMaterialsContainer.propTypes = {
	callApi: PropTypes.func.isRequired,
	fetchMaterials: PropTypes.func.isRequired,
	loadLookups: PropTypes.func.isRequired,
	handleSearchChange: PropTypes.func.isRequired,
	handleSortedChange: PropTypes.func.isRequired,
	handlePageChange: PropTypes.func.isRequired,
	handlePageSizeChange: PropTypes.func.isRequired,
	handleExpandedChange: PropTypes.func.isRequired,
	handleResizedChange: PropTypes.func.isRequired,
	handleFilteredChange: PropTypes.func.isRequired,
	handleCreatedDateChange: PropTypes.func.isRequired,
	handleCategoryChange: PropTypes.func.isRequired,
	handleDocumentTypeChange: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
	return {
		...state.marketing,
		...state.lookups,
		...state.marketingMaterialsModule,
		isMarketingMaterialAdmin: () => {
			return meetsAppRestriction(state.auth, APPLICATIONS.MarketingMaterialAdmin)
		},
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			callApi,
			loadLookups,
			fetchMaterials,
			handleSortedChange,
			handlePageChange,
			handlePageSizeChange,
			handleExpandedChange,
			handleResizedChange,
			handleFilteredChange,
			handleSearchChange,
			handleCreatedDateChange,
			handleCategoryChange,
			handleDocumentTypeChange,
			resetFilters,
			handleToggleCategorySidebar,
			handleToggleColumn,
			setSelectedColumns,
			handleAddToCart,
		},
		dispatch
	)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(MarketingMaterialsContainer))

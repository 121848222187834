import { Dispatch, ReactElement, SetStateAction, useMemo } from "react"

import { UserGuestSelector, UserGuestSelectorValue } from "../selectors"

export interface UserGuestParamState {
	isGuest: boolean | null
	noSites: boolean | null
}

export interface UserGuestQueryFilterProps<QueryParamState extends UserGuestParamState> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

export const UserGuestQueryFilter = <QueryParamState extends UserGuestParamState>({
	queryParamState,
	setQueryParamState,
}: UserGuestQueryFilterProps<QueryParamState>): ReactElement => {
	const value = useMemo((): UserGuestSelectorValue | null => {
		const isGuest = queryParamState.isGuest
		const noSites = queryParamState.noSites

		if (isGuest === false && noSites === true) {
			return UserGuestSelectorValue.NonGuestsWithNoSites
		}
		if (isGuest === true) {
			return UserGuestSelectorValue.OnlyGuests
		}
		if (isGuest === false) {
			return UserGuestSelectorValue.NonGuests
		}

		return null
	}, [queryParamState.isGuest, queryParamState.noSites])

	return (
		<UserGuestSelector
			value={value}
			fillContainer
			disableNoSelectionOption={false}
			onChange={(newValue, newOption) => {
				setQueryParamState((prev) => {
					const newState: UserGuestParamState = {
						isGuest: null,
						noSites: null,
					}

					if (newOption?.value === UserGuestSelectorValue.OnlyGuests) {
						newState.isGuest = true
					}
					if (newOption?.value === UserGuestSelectorValue.NonGuests) {
						newState.isGuest = false
					}
					if (newOption?.value === UserGuestSelectorValue.NonGuestsWithNoSites) {
						newState.isGuest = false
						newState.noSites = true
					}

					return {
						...prev,
						...newState,
					}
				})
			}}
		/>
	)
}

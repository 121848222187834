import { FC, useState } from "react"

import { css, useTheme } from "@emotion/react"
import dayjs, { Dayjs } from "dayjs"
import { useHistory, useParams } from "react-router-dom"

import {
	ErpStatusId,
	makeApiErrorMessage,
	UpdateErpPatch,
	useEmailErpDoc,
	useErp,
	useRecreateErpDoc,
	useUnexpireErp,
	useUpdateErp,
	useUploadErpAttachment,
} from "@ncs/ncs-api"
import { formatCurrency, formatDate, formatDateTime, getTimezoneAbbreviation } from "@ncs/ts-utils"
import {
	Box,
	Button,
	Callout,
	Card,
	ConfirmationModal,
	ConfirmationModalConfig,
	DateInputButton,
	Divider,
	DownloadDocumentButton,
	EditStringModal,
	EditStringModalProps,
	EmptyValueDash,
	encodeUrlState,
	FileInput,
	getAddressFields,
	GridContainer,
	GridItem,
	HeadingDivider,
	IconButton,
	Label,
	LabeledData,
	Link,
	LoadingSpinner,
	Paragraph,
	ParagraphList,
	useScrollToTopOnMount,
	useToast,
} from "@ncs/web-legos"

import { PageTitle } from "~/layouts/PageTitle"

import { QuotesUrlState } from "../Quotes"
import { erpAttachmentFileTypes } from "../quotes-util"
import { ConvertQuoteModal } from "./ConvertQuoteModal"
import { QuoteDetailLineItems } from "./QuoteDetailLineItems"

const QuoteDetail: FC = () => {
	useScrollToTopOnMount()
	const theme = useTheme()
	const history = useHistory<QuotesUrlState>()
	const { makeSuccessToast, makeErrorToast } = useToast()
	const { quoteId } = useParams<{ quoteId?: string }>()
	const [editStringModalConfig, setEditStringModalConfig] =
		useState<EditStringModalProps | null>(null)
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)
	const [showConvertModal, setShowConvertModal] = useState(false)
	const [isAttaching, setIsAttaching] = useState(false)

	if (!quoteId) {
		throw new Error("On the quote/erp detail page, but there is no quote/erp ID in the URL")
	}

	const [erp, erpLoading] = useErp(quoteId)
	const updateErp = useUpdateErp()
	const emailDoc = useEmailErpDoc()
	const recreateDoc = useRecreateErpDoc()
	const unexpireErp = useUnexpireErp()
	const uploadAttachment = useUploadErpAttachment()

	const handleUpdateQuote = async <Field extends keyof UpdateErpPatch>(
		field: Field,
		value: UpdateErpPatch[Field]
	) => {
		if (!erp) throw new Error("No ERP found")

		await updateErp({
			updates: { [field]: value },
			id: erp.id,
		})
		makeSuccessToast("Quote updated")
	}

	const handleEmailDoc = async (id: string) => {
		await emailDoc({ id })
		makeSuccessToast("Quote document sent")
	}

	const handleRecreateDoc = async (id: string) => {
		await recreateDoc({ id })
		makeSuccessToast("Quote document recreated")
	}

	const handleUnexpire = async (newDate: Dayjs | null) => {
		if (newDate) {
			await unexpireErp({
				id: quoteId,
				newExpirationDate: newDate.toISOString(),
			})
		}
	}

	const handleAddAttachment = async (file: File) => {
		try {
			setIsAttaching(true)
			await uploadAttachment(file, quoteId)
			makeSuccessToast("Attachment added to quote")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		} finally {
			setIsAttaching(false)
		}
	}

	if (erpLoading) {
		return (
			<Card>
				<LoadingSpinner />
			</Card>
		)
	}

	if (!erp) {
		return null
	}

	const title = `Quote for ${erp.customer.name}`
	const isExpired = dayjs(erp.expirationDate).isBefore(dayjs(), "day")

	return (
		<>
			<PageTitle title={title} />

			<Button
				icon="long-arrow-left"
				onClick={() =>
					history.push({
						pathname: "/quotes",
						search: encodeUrlState(history.location.state),
					})
				}
				containerProps={{ mb: 3 }}
			>
				All quotes
			</Button>

			<Card
				heading={title}
				headingDetail={formatDate(erp.createdDate)}
				headingIcon="message-dollar"
			>
				<div
					css={css`
						display: grid;
						grid-template-columns: 1fr 23rem;
						gap: 2rem;
						${theme.breakpoints.down("md")} {
							grid-template-columns: auto auto;
							gap: 1rem;
						}
						${theme.breakpoints.down("xs")} {
							grid-template-columns: auto;
						}
					`}
				>
					<div>
						<GridContainer columnGap={2} smProps={{ columnGap: 1 }}>
							<GridItem md={12} lg={6} mb={1}>
								<HeadingDivider headingVariant="h5" mb={1} mt={0}>
									Summary
								</HeadingDivider>
								<Callout
									noBorder
									variant={
										(
											erp.erpStatus.id === ErpStatusId.VOID ||
											erp.erpStatus.id === ErpStatusId.REJECTED
										) ?
											"warning"
										:	undefined
									}
								>
									<Paragraph>
										<strong>Status: </strong> {erp.erpStatus.description}
									</Paragraph>
									{(!!erp.dispatchId || !!erp.partOrderId) &&
										erp.erpStatus.id === ErpStatusId.APPROVED && (
											<Box
												mt={1}
												display="flex"
												flexDirection="column"
												gap={0.5}
											>
												{!!erp.dispatchId && (
													<Link
														to={`/service/dispatches/${erp.dispatchId}`}
														icon="external-link"
													>
														View dispatch
													</Link>
												)}
												{!!erp.partOrderId && (
													<Link
														to={`/part-orders/${erp.partOrderId}`}
														icon="external-link"
													>
														View part order
													</Link>
												)}
											</Box>
										)}
								</Callout>
								<Paragraph>Subtotal: {formatCurrency(erp.subtotal)}</Paragraph>
								<Paragraph>Tax: {formatCurrency(erp.taxTotal)}</Paragraph>
								<Paragraph>Total: {formatCurrency(erp.total)}</Paragraph>
								<Box display="flex" flexDirection="column" gap={0.5} mt={1.5}>
									<LabeledData label={`Created (${getTimezoneAbbreviation()})`}>
										{formatDateTime(erp.createdDate)} by {erp.createdBy}
									</LabeledData>
									<LabeledData label="Expires">
										{erp.expirationDate ?
											formatDate(erp.expirationDate)
										:	<EmptyValueDash />}
									</LabeledData>
									<LabeledData label="Closed">
										{erp.closedDate ?
											formatDate(erp.closedDate)
										:	<EmptyValueDash />}
									</LabeledData>
								</Box>
							</GridItem>
							<GridItem md={12} lg={6} mb={1}>
								<HeadingDivider headingVariant="h5" mb={1} mt={0}>
									Ship-To Customer
								</HeadingDivider>
								<Paragraph mb={0.15}>
									({erp.customer.customerNumber}) {erp.customer.name}
								</Paragraph>
								<ParagraphList
									lines={getAddressFields(erp.customer, { exclude: ["name"] })}
								/>
								{erp.billToCustomer && (
									<>
										<HeadingDivider headingVariant="h5" mb={1} mt={2}>
											Bill-To Customer
										</HeadingDivider>
										<Paragraph mb={0.15}>
											({erp.billToCustomer.customerNumber}){" "}
											{erp.billToCustomer.name}
										</Paragraph>
										<ParagraphList
											lines={getAddressFields(erp.billToCustomer, {
												exclude: ["name"],
											})}
										/>
									</>
								)}
								<Box mt={2}>
									<Label>Customer purchase order</Label>
									<Box display="flex">
										<Paragraph mt={0.35}>
											{erp.customerPurchaseOrder || <EmptyValueDash />}
										</Paragraph>
										<IconButton
											icon="pencil"
											color="primary"
											onClick={() =>
												setEditStringModalConfig({
													title: "Edit Customer Purchase Order Number",
													allowEmpty: true,
													initialValue: erp.customerPurchaseOrder,
													label: "Customer Purchase Order Number",
													maxLength: 100,
													onClose: () => setEditStringModalConfig(null),
													onSave: (newValue) =>
														handleUpdateQuote(
															"customerPurchaseOrder",
															newValue
														),
												})
											}
										/>
									</Box>
								</Box>
							</GridItem>
						</GridContainer>

						<Box mb={2}>
							<Label>Documents & Attachments</Label>
							<Box display="flex" flexDirection="column" gap={0.25}>
								{!erp.erpDocument.length && !erp.erpAttachments.length && (
									<EmptyValueDash />
								)}
								{erp.erpDocument.map((d) => (
									<div key={d.id}>
										<DownloadDocumentButton
											documentId={d.id}
											documentType={5}
											buttonText={d.name}
											icon="external-link"
										/>
									</div>
								))}
								{erp.erpAttachments.map((a) => (
									<div key={a.id}>
										<DownloadDocumentButton
											documentId={a.id}
											documentType={a.documentType}
											buttonText={a.name}
											icon="external-link"
										/>
									</div>
								))}
							</Box>
						</Box>
						<div>
							<Label>Comments</Label>
							<Box display="flex">
								<Paragraph mt={0.35}>
									{erp.comment || <EmptyValueDash />}
								</Paragraph>
								<IconButton
									icon="pencil"
									color="primary"
									onClick={() =>
										setEditStringModalConfig({
											title: "Edit Quote Comment",
											textarea: true,
											allowEmpty: true,
											initialValue: erp.comment,
											label: "Comment",
											maxLength: 1000,
											onClose: () => setEditStringModalConfig(null),
											onSave: (newValue) =>
												handleUpdateQuote("comment", newValue),
										})
									}
								/>
							</Box>
						</div>
					</div>
					<Box display="flex" flexDirection="column" gap={1}>
						{!isExpired &&
							[ErpStatusId.IN_PROGRESS, ErpStatusId.SUBMITTED].includes(
								erp.erpStatus.id
							) && (
								<Button
									icon="check"
									variant="primary-cta"
									fillContainer
									onClick={() => setShowConvertModal(true)}
								>
									Convert To Dispatch
								</Button>
							)}
						{isExpired && (
							<DateInputButton
								value={dayjs().add(1, "month")}
								onChange={async (newDate) => {
									await handleUnexpire(newDate)
								}}
								buttonProps={{
									buttonText: "Set New Expire Date",
									fillContainer: true,
								}}
								datePickerProps={{
									disablePast: true,
								}}
							/>
						)}
						{!!erp.erpDocument.length && (
							<Button
								icon="envelope"
								variant="secondary-cta"
								fillContainer
								onClick={() =>
									setConfirmationConfig({
										title: "Send Quote Document",
										message:
											"Email the current quote document to the customer and to the technician?",
										onConfirm: () => handleEmailDoc(erp.id),
									})
								}
							>
								Email Quote Doc
							</Button>
						)}
						<Button
							icon="sync"
							variant="secondary-cta"
							fillContainer
							onClick={() =>
								setConfirmationConfig({
									title: "Recreate Quote Document",
									message: "Recreate the document for this quote?",
									onConfirm: () => handleRecreateDoc(erp.id),
								})
							}
						>
							Recreate Quote Doc
						</Button>
						<FileInput
							icon="paperclip-vertical"
							label="Attach file"
							fileTypes={erpAttachmentFileTypes}
							onChange={handleAddAttachment}
							isLoading={isAttaching}
							fillContainer
							mb={0}
						/>
						{erp.erpStatus?.id !== ErpStatusId.VOID && (
							<Button
								icon="ban"
								variant="secondary-cta"
								fillContainer
								onClick={() => {
									setConfirmationConfig({
										title: "Void Quote",
										message: "Void this quote?",
										onConfirm: () =>
											handleUpdateQuote("statusId", ErpStatusId.VOID),
									})
								}}
							>
								Void Quote
							</Button>
						)}
						{erp.erpStatus.id === ErpStatusId.VOID && (
							<Button
								icon="trash-arrow-up"
								variant="secondary-cta"
								fillContainer
								onClick={() => {
									setConfirmationConfig({
										title: "Restore Quote",
										message:
											"Restore this quote? It's status will be changed to In Progress.",
										onConfirm: () =>
											handleUpdateQuote("statusId", ErpStatusId.IN_PROGRESS),
									})
								}}
							>
								Restore Quote
							</Button>
						)}
					</Box>
				</div>

				<Divider mt={3} mb={2} />

				<QuoteDetailLineItems erp={erp} />
			</Card>

			{showConvertModal && (
				<ConvertQuoteModal onClose={() => setShowConvertModal(false)} erp={erp} />
			)}
			{!!editStringModalConfig && <EditStringModal {...editStringModalConfig} />}
			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</>
	)
}

export default QuoteDetail

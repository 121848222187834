import { Dispatch, ReactElement, SetStateAction } from "react"

import { LaborRateTypeId } from "@ncs/ncs-api"
import { isEnumMember } from "@ncs/ts-utils"

import { LaborRateTypeSelector } from "../selectors"

export enum LaborRateTypeParamKey {
	LaborRateTypeKey = "laborRateType",
}

export interface LaborRateTypeParamState {
	[LaborRateTypeParamKey.LaborRateTypeKey]: string | null
}

export interface LaborRateTypeQueryFilterProps<QueryParamState extends LaborRateTypeParamState> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

export const LaborRateTypeQueryFilter = <QueryParamState extends LaborRateTypeParamState>({
	queryParamState,
	setQueryParamState,
}: LaborRateTypeQueryFilterProps<QueryParamState>): ReactElement => {
	return (
		<LaborRateTypeSelector
			value={
				(
					isEnumMember(
						queryParamState[LaborRateTypeParamKey.LaborRateTypeKey],
						LaborRateTypeId
					)
				) ?
					queryParamState[LaborRateTypeParamKey.LaborRateTypeKey]
				:	null
			}
			disableNoSelectionOption={false}
			fillContainer
			onChange={(newValue, newOption) =>
				setQueryParamState((prev) => {
					const newLaborRateTypeParamState: LaborRateTypeParamState = {
						[LaborRateTypeParamKey.LaborRateTypeKey]: newOption?.value ?? null,
					}

					return {
						...prev,
						...newLaborRateTypeParamState,
					}
				})
			}
		/>
	)
}

import { FC, useState } from "react"

import {
	FreightGroupId,
	InventoryPart,
	makeApiErrorMessage,
	useUpdateInventoryPart,
} from "@ncs/ncs-api"
import { isEnumMember } from "@ncs/ts-utils"
import { ExtendableModalProps, Modal, useIsSaving, useToast } from "@ncs/web-legos"

import { FreightGroupSelector } from "~/components"

export interface EditFreightGroupModalProps extends ExtendableModalProps {
	part: InventoryPart
}

export const EditFreightGroupModal: FC<EditFreightGroupModalProps> = ({ part, ...rest }) => {
	const { makeSuccessToast } = useToast()
	const { isSaving, setSaving, endSaving } = useIsSaving()
	const [errorText, setErrorText] = useState<string | null>(null)
	const [groupId, setGroupId] = useState<FreightGroupId | null>(() => {
		const id = part.freightGroupId?.toString()
		if (isEnumMember(id, FreightGroupId)) return id
		return null
	})

	const updatePart = useUpdateInventoryPart()

	const handleSave = async () => {
		try {
			setSaving()
			await updatePart({
				id: part.id,
				updates: {
					freightGroupId: groupId ?? null,
				},
			})
			makeSuccessToast("Part updated")
			rest.onClose()
		} catch (e) {
			setErrorText(makeApiErrorMessage(e))
			endSaving()
		}
	}

	return (
		<Modal
			{...rest}
			title="Edit Freight Group"
			errorText={errorText}
			rightButtons={{
				buttonText: "Update",
				onClick: handleSave,
				isLoading: isSaving(),
				disabled: (part.freightGroupId?.toString() ?? null) === groupId,
			}}
		>
			<FreightGroupSelector
				value={groupId}
				onChange={setGroupId}
				fillContainer
				disableNoSelectionOption={false}
			/>
		</Modal>
	)
}

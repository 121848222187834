import { FC, useMemo, useState } from "react"

import { css, Theme } from "@emotion/react"
import { useHistory, useLocation, useParams } from "react-router-dom"

import {
	createDocumentToken,
	getDocumentUrlForToken,
	makeApiErrorMessage,
	PurchaseOrdersQueryParams,
	useCancelPurchaseOrder,
	useCustomer,
	useFinalizePurchaseOrder,
	useForcePurchaseOrder,
	useMakePurchaseOrderInProgress,
	usePurchaseOrder,
	usePurchaseOrderDocuments,
	useRecreatePurchaseOrderDocuments,
	useReopenPurchaseOrder,
	useUploadPurchaseOrderAttachment,
	useVendor,
} from "@ncs/ncs-api"
import {
	formatCurrency,
	formatDate,
	formatDateTime,
	formatPhone,
	getTimezoneAbbreviation,
} from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	Box,
	Button,
	Callout,
	Card,
	ConfirmationModal,
	ConfirmationModalConfig,
	Divider,
	EmptyValueDash,
	encodeUrlState,
	FileInput,
	GridContainer,
	GridItem,
	GridItemProps,
	HeadingDivider,
	Icon,
	Label,
	LabeledData,
	Link,
	LoadingSpinner,
	Paragraph,
	ParagraphList,
	useScreenSizeMatch,
	useToast,
	VendorEditModal,
} from "@ncs/web-legos"

import { PrintLinePartNumbersModal } from "~/components"
import { PageTitle } from "~/layouts/PageTitle"
import { purchaseOrderAttachmentFileTypes } from "~/util/purchase-orders"

import { PurchaseOrdersViewTabUrlState } from "../PurchaseOrders/components"
import {
	EditPurchaseOrderModal,
	PurchaseOrderDeliveryNoteModal,
	PurchaseOrderDetailLineItems,
	PurchaseOrderOrderInfoModal,
} from "./components"
import { usePurchaseOrderPermissions } from "./purchase-order-detail-util"

export const PurchaseOrderDetail: FC = () => {
	const { makeSuccessToast, makeErrorToast } = useToast()
	const screenIsXs = useScreenSizeMatch("xs")
	const history = useHistory()
	const location = useLocation<{ params?: PurchaseOrdersQueryParams }>()
	const { purchaseOrderId: id } = useParams<{ purchaseOrderId: string }>()
	if (!id) throw new Error("purchase order ID not found in URL")

	const [purchaseOrder, purchaseOrderLoading] = usePurchaseOrder(id)
	const [billToCustomer, billToCustomerLoading] = useCustomer(purchaseOrder?.billToCustomer?.id)
	const [vendor, vendorLoading] = useVendor(purchaseOrder?.vendor?.id ?? null)
	const [documents, documentsLoading] = usePurchaseOrderDocuments(id)
	const finalizeOrder = useFinalizePurchaseOrder(id)
	const markInProgress = useMakePurchaseOrderInProgress(id)
	const forceOrder = useForcePurchaseOrder(id)
	const cancelOrder = useCancelPurchaseOrder(id)
	const recreateDocuments = useRecreatePurchaseOrderDocuments(id)
	const reopenOrder = useReopenPurchaseOrder(id)
	const uploadAttachment = useUploadPurchaseOrderAttachment()

	const [isExpandedView, setIsExpandedView] = useState(false)
	const [documentIdBeingFetch, setDocumentIdBeingFetched] = useState<number | null>(null)
	const [isSaving, setIsSaving] = useState<"recreating-documents" | "adding-attachment" | null>(
		null
	)
	const [showEditModal, setShowEditModal] = useState(false)
	const [showDeliveryNoteModal, setShowDeliveryNoteModal] = useState(false)
	const [vendorIdEdit, setVendorIdToEdit] = useState<string | null>(null)
	const [showOrderInfoModal, setShowOrderInfoModal] = useState(false)
	const [showPrintModal, setShowPrintModal] = useState(false)
	const [confirmationModalConfig, setConfirmationModalConfig] =
		useState<ConfirmationModalConfig | null>(null)

	const handleFinalizeGrimesOrder = async () => {
		try {
			await finalizeOrder()
			makeSuccessToast("Order placed")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		}
	}

	const handleMarkInProgress = async () => {
		try {
			await markInProgress()
			makeSuccessToast("Order marked in-progress")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		}
	}

	const handleForceOrder = async () => {
		try {
			await forceOrder()
			makeSuccessToast("Order has been force shipped")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		}
	}

	const handleViewDocument = async (documentId: number, documentTypeId: number) => {
		try {
			setDocumentIdBeingFetched(documentId)
			const { token } = await createDocumentToken(documentId, documentTypeId)
			const url = getDocumentUrlForToken(token)
			window.open(url, "_blank")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		} finally {
			setDocumentIdBeingFetched(null)
		}
	}

	const handleRecreateDocuments = async () => {
		try {
			setIsSaving("recreating-documents")
			await recreateDocuments()
			makeSuccessToast("Documents created")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		} finally {
			setIsSaving(null)
		}
	}

	const handleCancelOrder = async () => {
		try {
			await cancelOrder()
			makeSuccessToast("Order canceled")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		}
	}

	const handleReopenOrder = async () => {
		try {
			await reopenOrder()
			makeSuccessToast("Order reopened")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		}
	}

	const handleSelectAttachment = async (file: File) => {
		try {
			setIsSaving("adding-attachment")
			await uploadAttachment(file, id)
			makeSuccessToast("Document attached")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		} finally {
			setIsSaving(null)
		}
	}

	const headingDetail = useMemo(() => {
		const details: string[] = []

		if (purchaseOrder) {
			if (purchaseOrder.createdDate) {
				details.push(
					`Created ${formatDateTime(
						purchaseOrder.createdDate
					)} (${getTimezoneAbbreviation()})`
				)
			}
			details.push(`Order # ${purchaseOrder.id}`)
			if (purchaseOrder.kbmOrderNumber) {
				details.push(`KBM Order # ${purchaseOrder.kbmOrderNumber}`)
			}
			if (purchaseOrder.vendorOrderNumber) {
				details.push(`Supplier Order # ${purchaseOrder.vendorOrderNumber}`)
			}
			if (purchaseOrder.purchaseOrderNumber) {
				details.push(`Customer Purchase Order # ${purchaseOrder.purchaseOrderNumber}`)
			}
		}

		return details
	}, [purchaseOrder])

	const gridItemProps: GridItemProps = {
		md: 3,
		sm: 6,
		xs: 12,
	}

	const {
		vendorIsGrimes,
		canMakeInProgress,
		canFinalize,
		canForce,
		canEdit,
		canReceive,
		canReopen,
	} = usePurchaseOrderPermissions(purchaseOrder)

	const vendorPhoneRaw = vendor?.contactInfo?.phone || vendor?.phone
	const vendorPhone = vendorPhoneRaw ? formatPhone(vendorPhoneRaw) : null
	const vendorContactName = vendor?.contactInfo?.contactName || vendor?.contactName
	const vendorEmail = vendor?.contactInfo?.email

	return (
		<>
			<PageTitle
				title={purchaseOrder ? `Purchase Order # ${purchaseOrder.id}` : "Purchase Order"}
			/>

			<Button
				icon="long-arrow-left"
				containerProps={{ mb: 3 }}
				onClick={() =>
					history.push({
						pathname: "/purchase-orders",
						search: encodeUrlState<PurchaseOrdersViewTabUrlState>(
							location.state?.params
						),
					})
				}
				buttonText="All purchase orders"
			/>

			<Card
				heading={purchaseOrder ? `Purchase Order # ${purchaseOrder.id}` : "Purchase Order"}
				headingIcon="warehouse-alt"
				headingDetail={headingDetail}
			>
				{/* Primary action buttons */}
				{(canFinalize || canMakeInProgress) && (
					<Box
						display="flex"
						columnGap={1}
						justifyContent="center"
						mt={4}
						mb={5}
						smProps={{
							flexDirection: "column",
							rowGap: 1,
						}}
					>
						{canMakeInProgress && (
							<div css={primaryButtonContainerStyles}>
								<Button
									buttonText="Mark in-progress"
									variant="primary-cta"
									icon="hourglass-half"
									fillContainer
									onClick={() =>
										setConfirmationModalConfig({
											title: "Mark In-Progress",
											message: "Mark this order as in-progress?",
											onConfirm: handleMarkInProgress,
										})
									}
								/>
							</div>
						)}
						{canFinalize && (
							<div css={primaryButtonContainerStyles}>
								<Button
									buttonText={
										vendorIsGrimes ? "Finalize Order" : (
											"Finalize & Add Order Info"
										)
									}
									variant="primary-cta"
									icon="check"
									fillContainer
									onClick={() => {
										vendorIsGrimes ?
											setConfirmationModalConfig({
												onConfirm: handleFinalizeGrimesOrder,
												title: "Finalize Order",
												message: "Confirm: Finalize this order?",
											})
										:	setShowOrderInfoModal(true)
									}}
								/>
							</div>
						)}
					</Box>
				)}

				<GridContainer>
					<GridItem {...gridItemProps}>
						<HeadingDivider headingVariant="h5" mt={0} mb={0.5}>
							Summary
						</HeadingDivider>
						<Callout
							isLoading={purchaseOrderLoading}
							loadingRowCount={1}
							mb={0.5}
							ml={-0.4}
							px={0.9}
							py={0.6}
							noBorder
						>
							<Paragraph>
								<strong>Order Status: </strong>
								{purchaseOrder?.status?.description}
							</Paragraph>
						</Callout>
						<ParagraphList
							isLoading={purchaseOrderLoading}
							lines={[
								`Subtotal: ${formatCurrency(purchaseOrder?.subTotal ?? 0)}`,
								`Tax: ${formatCurrency(purchaseOrder?.taxTotal ?? 0)}`,
								`Total: ${formatCurrency(purchaseOrder?.total ?? 0)}`,
							]}
						/>
					</GridItem>
					<GridItem {...gridItemProps}>
						<HeadingDivider headingVariant="h5" mt={0} mb={0.5}>
							Supplier
						</HeadingDivider>
						<ParagraphList
							isLoading={vendorLoading || purchaseOrderLoading}
							lines={[
								vendor?.name,
								["Contact name: ", vendorContactName],
								["Phone: ", vendorPhone],
								[
									"Email: ",
									vendorEmail ?
										<Link
											key="email"
											mailTo
											to={vendorEmail}
											css={css`
												word-break: break-all;
											`}
										>
											{vendorEmail}
										</Link>
									:	null,
								],
								vendor?.address2,
								vendor?.address1,
								[vendor?.city, ", ", vendor?.state, ", ", vendor?.zipCode],
							]}
							loadingRowCount={3}
						/>
						{vendor?.contactInfo?.website && (
							<div>
								<Link external newTab to={`${vendor?.contactInfo?.website}`}>
									{vendor.contactInfo.website}
								</Link>
							</div>
						)}
						{!!vendor && (
							<Button
								icon="address-card"
								onClick={() => setVendorIdToEdit(vendor.id)}
								containerProps={{ mt: 0.5 }}
								allowTextWrap
							>
								Update supplier contact info?
							</Button>
						)}
					</GridItem>
					<GridItem {...gridItemProps}>
						<HeadingDivider headingVariant="h5" mt={0} mb={0.5}>
							Customer Ship To
						</HeadingDivider>
						<ParagraphList
							isLoading={purchaseOrderLoading}
							lines={[
								`(${purchaseOrder?.shipToCustomer?.customerNumber}) ${purchaseOrder?.shipToCustomer?.name}`,
								purchaseOrder?.shipToCustomer?.address2,
								`${purchaseOrder?.shipToCustomer?.city}, ${purchaseOrder?.shipToCustomer?.state}`,
								purchaseOrder?.shipToCustomer?.postalcode,
								purchaseOrder?.shipToCustomer?.phone ?
									formatPhone(purchaseOrder.shipToCustomer.phone)
								:	undefined,
							]}
							loadingRowCount={3}
						/>
					</GridItem>
					<GridItem {...gridItemProps}>
						<HeadingDivider headingVariant="h5" mt={0} mb={0.5}>
							Customer Bill To
						</HeadingDivider>
						<ParagraphList
							isLoading={purchaseOrderLoading || billToCustomerLoading}
							lines={[
								`(${billToCustomer?.customerNumber}) ${billToCustomer?.name}`,
								billToCustomer?.address2,
								`${billToCustomer?.city}, ${billToCustomer?.state}`,
								billToCustomer?.postalcode,
								billToCustomer?.phone ?
									formatPhone(billToCustomer.phone)
								:	undefined,
							]}
							loadingRowCount={3}
						/>
					</GridItem>
				</GridContainer>

				{/* Additional details */}
				<AnimatedEntrance show={isExpandedView} direction="down">
					<Divider mt={3} />
					<GridContainer mt={2}>
						<GridItem xs={12} sm={6} md={6} lg={9}>
							<GridContainer>
								<GridItem md={6} lg={4}>
									<LabeledData label="Created by">
										{purchaseOrder?.createdBy?.name}
									</LabeledData>
								</GridItem>
								<GridItem md={6} lg={4}>
									<LabeledData label="Ship method">
										{purchaseOrder?.shipMethod?.description}
									</LabeledData>
								</GridItem>
								<GridItem xs={8}>
									<LabeledData label="Comments">
										{purchaseOrder?.comments || <EmptyValueDash />}
									</LabeledData>
								</GridItem>
							</GridContainer>

							{/* Delivery notes */}
							<HeadingDivider icon="truck" headingVariant="h5" mb={1}>
								Delivery Notes
							</HeadingDivider>
							{(
								purchaseOrder?.deliveryComments &&
								purchaseOrder.deliveryComments.length > 0
							) ?
								purchaseOrder.deliveryComments.map((note) => (
									<Box
										key={note.createdDate}
										display="flex"
										flexDirection="column"
										rowGap={0.25}
										mb={1}
										pl={1.5}
									>
										<Label>Added {formatDate(note.createdDate)}</Label>
										<Box display="flex" alignItems="center" columnGap={0.5}>
											<Icon icon="calendar" />
											<Paragraph>
												Est. delivery date:{" "}
												<strong>
													{formatDate(
														note.deliveryDate,
														undefined,
														undefined,
														true
													)}
												</strong>
											</Paragraph>
										</Box>
										<Box display="flex" alignItems="center" columnGap={0.5}>
											<Icon icon="comment-alt-lines" />
											<Paragraph>
												{note.comment || <EmptyValueDash />}
											</Paragraph>
										</Box>
									</Box>
								))
							:	<Paragraph small color="secondary" pl={1.5}>
									No delivery notes
								</Paragraph>
							}

							{/* Documents */}
							<HeadingDivider icon="copy" mt={2} headingVariant="h5">
								Documents
							</HeadingDivider>
							{documentsLoading && <LoadingSpinner />}

							<Box pl={1.5}>
								{!documentsLoading &&
									!!documents &&
									(documents.length > 0 ?
										documents.map((d) => (
											<div key={d.id}>
												<Button
													buttonText={d.name}
													onClick={() =>
														handleViewDocument(d.id, d.documentType)
													}
													isLoading={documentIdBeingFetch === d.id}
													trailingIcon="external-link"
												/>
											</div>
										))
									:	<Paragraph small color="secondary">
											No documents found
										</Paragraph>)}
							</Box>
						</GridItem>

						{/* Secondary action buttons */}
						<GridItem
							xs={12}
							md={6}
							lg={3}
							display="flex"
							flexDirection="column"
							rowGap={1}
						>
							{screenIsXs && <Divider />}
							{canReopen && (
								<Button
									variant="secondary-cta"
									fillContainer
									icon="envelope-open"
									onClick={() => {
										setConfirmationModalConfig({
											title: "Reopen Order",
											message: "Reopen this purchase order?",
											onConfirm: handleReopenOrder,
										})
									}}
								>
									Reopen Order
								</Button>
							)}
							{canEdit && (
								<>
									<Button
										buttonText="Add delivery note"
										variant="secondary-cta"
										icon="comment-alt-lines"
										onClick={() => setShowDeliveryNoteModal(true)}
										fillContainer
									/>
									<Button
										buttonText="Edit details"
										variant="secondary-cta"
										icon="pencil"
										fillContainer
										onClick={() => setShowEditModal(true)}
									/>
								</>
							)}
							{canForce && (
								<Button
									variant="secondary-cta"
									buttonText="Force Ship"
									icon="exclamation-triangle"
									fillContainer
									onClick={() => {
										setConfirmationModalConfig({
											onConfirm: handleForceOrder,
											title: "Force Order?",
											message:
												"Are you sure you want to force ship this order? This will force the order to act as if KBM has shipped it, potentially causing double receiving later.",
										})
									}}
								/>
							)}
							<Button
								icon="file-signature"
								onClick={handleRecreateDocuments}
								isLoading={isSaving === "recreating-documents"}
								variant="secondary-cta"
								fillContainer
							>
								Create Documents
							</Button>
							<FileInput
								icon="paperclip-vertical"
								label="Attach Document"
								isLoading={isSaving === "adding-attachment"}
								fileTypes={purchaseOrderAttachmentFileTypes}
								onChange={(file) => handleSelectAttachment(file)}
								fillContainer
								mb={0}
							/>
							{!!purchaseOrder?.lineItems.length && (
								<Button
									buttonText="Part number labels"
									variant="secondary-cta"
									icon="print"
									onClick={() => setShowPrintModal(true)}
									fillContainer
								/>
							)}
							{canEdit && (
								<Button
									buttonText="Cancel order"
									variant="secondary-cta"
									icon="trash"
									fillContainer
									onClick={() =>
										setConfirmationModalConfig({
											title: "Cancel Order?",
											message:
												"Are you sure you want to cancel this order? This action cannot be undone.",
											onConfirm: handleCancelOrder,
										})
									}
								/>
							)}
						</GridItem>
					</GridContainer>
				</AnimatedEntrance>

				<Button
					icon={isExpandedView ? "angle-up" : "angle-down"}
					containerProps={{ mt: 2 }}
					onClick={() => setIsExpandedView(!isExpandedView)}
				>
					{isExpandedView ? "Show less" : "Show more details and actions"}
				</Button>

				<Divider my={2} />

				{purchaseOrderLoading && <LoadingSpinner />}
				{purchaseOrder && (
					<PurchaseOrderDetailLineItems
						canEdit={canEdit}
						canReceive={canReceive}
						order={purchaseOrder}
					/>
				)}
			</Card>

			<EditPurchaseOrderModal
				isOpen={showEditModal}
				onClose={() => setShowEditModal(false)}
				order={purchaseOrder ?? null}
			/>
			<PurchaseOrderDeliveryNoteModal
				isOpen={showDeliveryNoteModal}
				onClose={() => setShowDeliveryNoteModal(false)}
				orderId={id}
			/>
			<ConfirmationModal
				config={confirmationModalConfig}
				setConfig={setConfirmationModalConfig}
			/>
			<VendorEditModal
				vendorId={vendorIdEdit}
				isOpen={!!vendorIdEdit}
				onClose={() => setVendorIdToEdit(null)}
			/>
			<PurchaseOrderOrderInfoModal
				order={purchaseOrder ?? null}
				isOpen={showOrderInfoModal}
				onClose={() => setShowOrderInfoModal(false)}
			/>
			{showPrintModal && !!purchaseOrder?.lineItems.length && (
				<PrintLinePartNumbersModal
					lines={purchaseOrder.lineItems}
					purchaseOrderId={purchaseOrder.id.toString()}
					onClose={() => setShowPrintModal(false)}
				/>
			)}
		</>
	)
}

const primaryButtonContainerStyles = (theme: Theme) => css`
	flex-grow: 1;
	max-width: 25rem;
	${theme.breakpoints.down("sm")} {
		max-width: none;
	}
`

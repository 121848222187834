import React, { useMemo } from "react"
import { WASH_COUNT_ENTRY_TYPES } from "@ncs/mortar/util/constants"
import {
	Select,
	CustomerSelector,
	GridContainer,
	GridItem,
	TerritorySelector,
	OrganizationSelector,
	MonthSelector,
} from "~/components"
import { getYearOptions } from "./washCountSubmissionUtils"

const WashCountFilters = ({ filters, onFilterChange }) => {
	const yearOptions = useMemo(() => getYearOptions(), [])

	const onTerritoryChange = (territoryId) => {
		onFilterChange({
			territory: territoryId || undefined,
			customer: undefined,
			organization: undefined,
		})
	}

	const onOrganizationChange = (organizationId) => {
		onFilterChange({
			organization: organizationId || undefined,
			customer: undefined,
			territory: undefined,
		})
	}

	const onCustomerChange = (customer) => {
		onFilterChange({
			customer: customer || undefined,
			territory: undefined,
			organization: undefined,
		})
	}

	const onEntryTypeChange = (entryType) => {
		onFilterChange({
			entryType:
				entryType === WASH_COUNT_ENTRY_TYPES.CUSTOMER_OR_FIELD.value ? "" : entryType,
		})
	}

	const onMonthChange = (month) => {
		onFilterChange({ month })
	}

	const onYearChange = (year) => {
		onFilterChange({ year })
	}

	return (
		<GridContainer style={{ marginBottom: "5rem" }}>
			<GridItem md={4} sm={6} xs={12}>
				<TerritorySelector territory={filters.territory} onChange={onTerritoryChange} />
			</GridItem>
			<GridItem md={4} sm={6} xs={12}>
				<OrganizationSelector
					value={filters.organization}
					onChange={onOrganizationChange}
				/>
			</GridItem>
			<GridItem md={4} sm={6} xs={12}>
				<CustomerSelector
					selectedSuggestion={filters.customer}
					onCustomerSelected={onCustomerChange}
				/>
			</GridItem>
			<GridItem md={4} sm={6} xs={12}>
				<Select
					labelText="Count supplied by"
					value={
						filters.entryType === "" ?
							WASH_COUNT_ENTRY_TYPES.CUSTOMER_OR_FIELD.value
						:	filters.entryType
					}
					onChange={onEntryTypeChange}
					options={Object.values(WASH_COUNT_ENTRY_TYPES).map(
						({ value, description: text }) => ({
							text,
							value,
						})
					)}
				/>
			</GridItem>
			<GridItem md={2} sm={3} xs={6}>
				<MonthSelector value={filters.month} onChange={onMonthChange} />
			</GridItem>
			<GridItem md={2} sm={3} xs={6}>
				<Select
					labelText="Year"
					value={filters.year}
					onChange={onYearChange}
					options={yearOptions}
				/>
			</GridItem>
		</GridContainer>
	)
}

export default React.memo(WashCountFilters)

import { FC, useState } from "react"

import { Customer, makeApiErrorMessage, QuickOrder, useAddQuickOrderSite } from "@ncs/ncs-api"
import { extractNumber } from "@ncs/ts-utils"
import { CustomerSelector, ExtendableModalProps, Modal } from "@ncs/web-legos"

export interface AddSiteToQuickOrderModalProps extends ExtendableModalProps {
	order: QuickOrder
}

export const AddSiteToQuickOrderModal: FC<AddSiteToQuickOrderModalProps> = ({
	order,
	...rest
}) => {
	const [selectedSite, setSelectedSite] = useState<Customer | null>(null)
	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const addSite = useAddQuickOrderSite()

	const onSave = async () => {
		if (!selectedSite) return

		try {
			setIsSaving(true)
			await addSite({
				quickOrderId: order.id ?? null,
				customerId: extractNumber(selectedSite.id),
			})
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const reset = () => {
		setSelectedSite(null)
		setIsSaving(false)
		setErrorText(null)
	}

	return (
		<Modal
			title="Add Site To Quick Order"
			onOpen={reset}
			{...rest}
			errorText={errorText}
			rightButtons={{
				buttonText: "Add Site",
				onClick: onSave,
				disabled: !selectedSite,
				isLoading: isSaving,
			}}
		>
			<CustomerSelector value={selectedSite} onChange={setSelectedSite} autoFocus />
		</Modal>
	)
}

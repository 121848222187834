import React from "react"
import { BaseSelectFilter, withDynamicTableFilter } from "@ncs/bricks/components"

class ERPExpiredFilter extends React.PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			expired: [
				{ value: "true", text: "True" },
				{ value: "false", text: "False" },
			],
		}
	}

	render() {
		return (
			<BaseSelectFilter
				id="isExpired"
				labelText="Is Expired"
				options={[{ value: "", text: "All ERP's" }, ...this.state.expired]}
				valueAccessor={(x) => (x.value || "").toString()}
				textAccessor="text"
				{...this.props}
			/>
		)
	}
}

export default withDynamicTableFilter("is_expired")(ERPExpiredFilter)

import { FC, useState } from "react"

import {
	makeApiErrorMessage,
	ShipmentPackageSize,
	useCreateShipmentPackageSize,
} from "@ncs/ncs-api"
import { unpythonify } from "@ncs/ts-utils"

import { useToast } from "../../contexts"
import { NumericInput, TextInput } from "../inputs"
import { ExtendableModalProps, Modal } from "./Modal"

export interface NewPackageSizeModalProps extends ExtendableModalProps {
	onCreation?: (size: ShipmentPackageSize) => void
}

export const NewPackageSizeModal: FC<NewPackageSizeModalProps> = ({ onCreation, ...rest }) => {
	const { makeSuccessToast } = useToast()
	const [newName, setNewName] = useState<string | null>(null)
	const [newHeight, setNewHeight] = useState<number | null>(null)
	const [newWidth, setNewWidth] = useState<number | null>(null)
	const [newLength, setNewLength] = useState<number | null>(null)
	const [errorText, setErrorText] = useState<string | null>(null)
	const [isSaving, setIsSaving] = useState(false)

	const createSize = useCreateShipmentPackageSize()

	const onSave = async () => {
		try {
			if (!newName || !newHeight || !newWidth || !newLength) {
				throw new Error("All fields are required")
			}
			setIsSaving(true)
			const { data } = await createSize({
				name: newName,
				height: newHeight,
				width: newWidth,
				length: newLength,
			})
			if (typeof onCreation === "function") {
				onCreation(unpythonify(data))
			}
			makeSuccessToast("New size saved")
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	return (
		<Modal
			{...rest}
			title="Create New Package Size"
			errorText={errorText}
			rightButtons={{
				buttonText: "Save",
				isLoading: isSaving,
				onClick: onSave,
			}}
		>
			<TextInput value={newName} onChange={setNewName} label="Size name" maxLength={100} />
			<NumericInput
				value={newHeight}
				onChange={(newValue) => setNewHeight(newValue ?? null)}
				label="Height (in.)"
				decimalScale={2}
			/>
			<NumericInput
				value={newWidth}
				onChange={(newValue) => setNewWidth(newValue ?? null)}
				label="Width (in.)"
				decimalScale={2}
			/>
			<NumericInput
				value={newLength}
				onChange={(newValue) => setNewLength(newValue ?? null)}
				label="Length (in.)"
				decimalScale={2}
			/>
		</Modal>
	)
}

import React from "react"
import PropTypes from "prop-types"
import TimeAgo from "react-timeago"
import { CircularProgressProps, makeStyles } from "@material-ui/core"
import { Info } from "@material-ui/icons"
import {
	Card,
	CardFooter,
	CardHeader,
	CardIcon,
	GridItem,
	CircularProgressIndeterminate,
} from "../../components"
import { formatLongDateTime } from "../../util/formatters"
import { useLongPress } from "../../util/hooks"
import { isMaterialDashboardProColor, MaterialDashboardProColors } from "../../util/types"
import { GridSizeMap } from "./DashboardWidget"

import dashboardStyle from "../../assets/jss/material-dashboard-pro-react/views/dashboardStyle"

const styles = {
	...dashboardStyle,
	clickableCard: {
		cursor: "pointer",
	},
}
const useStyles = makeStyles(styles)

export interface DashboardCardProps {
	isLoading?: boolean
	color: Exclude<MaterialDashboardProColors | CircularProgressProps["color"], "gray" | undefined>
	icon: React.ReactNode
	measureName: React.ReactNode
	measureValue?: React.ReactNode
	measureUnit?: React.ReactNode
	measureValue2?: React.ReactNode
	measureUnit2?: React.ReactNode
	footerIcon?: React.ReactNode
	footerText?: React.ReactNode
	footerDate?: string | Date
	gridSizes?: GridSizeMap
	noWrapper?: boolean
	onClick?: () => void
	onLongPress?: () => void
	smallerValueFont?: boolean
}

const DashboardCard: React.FC<DashboardCardProps> = ({
	isLoading = false,
	color = "info",
	icon = <Info />,
	gridSizes = {
		xs: 12,
		sm: 6,
		md: 6,
		lg: 3,
	},
	measureUnit = "",
	measureValue2 = "",
	measureUnit2 = "",
	noWrapper = false,
	smallerValueFont = false,
	footerDate,
	footerIcon,
	footerText,
	measureName,
	measureValue,
	onClick,
	onLongPress,
}) => {
	const classes = useStyles()
	const longPress = useLongPress(onLongPress, onClick)

	// eslint-disable-next-line jsx-a11y/heading-has-content
	const valueWrapper = smallerValueFont ? <h4 /> : <h3 />

	const inner = (
		<Card
			{...(onLongPress ? longPress : { onClick })}
			className={onClick ? classes.clickableCard : ""}
		>
			<CardHeader color={isMaterialDashboardProColor(color) ? color : undefined} stats icon>
				<CardIcon color={isMaterialDashboardProColor(color) ? color : undefined}>
					{icon}
				</CardIcon>
				<p className={classes.cardCategory}>{measureName}</p>
				{isLoading && (
					<CircularProgressIndeterminate
						size={36}
						color={!isMaterialDashboardProColor(color) ? color : undefined}
					/>
				)}
				{!isLoading && (
					<valueWrapper.type className={classes.cardTitle}>
						{measureValue}
						<small>&nbsp;{measureUnit}</small>
					</valueWrapper.type>
				)}
				{!isLoading && (measureValue2 || measureUnit2) && (
					<valueWrapper.type className={classes.cardTitle}>
						{measureValue2}
						<small>&nbsp;{measureUnit2}</small>
					</valueWrapper.type>
				)}
			</CardHeader>
			{(footerIcon || footerText || footerDate) && (
				<CardFooter stats>
					<div className={classes.stats}>
						{footerIcon}
						{footerText}&nbsp;
						{footerDate && (
							<TimeAgo date={footerDate} title={formatLongDateTime(footerDate)} />
						)}
					</div>
				</CardFooter>
			)}
		</Card>
	)

	return noWrapper ? inner : <GridItem {...gridSizes}>{inner}</GridItem>
}

DashboardCard.propTypes = {
	isLoading: PropTypes.bool,
	color: PropTypes.oneOf([
		"primary" as const,
		"info" as const,
		"success" as const,
		"warning" as const,
		"danger" as const,
		"rose" as const,
		"secondary" as const,
		"inherit" as const,
	]).isRequired,
	icon: PropTypes.node,
	measureName: PropTypes.node.isRequired,
	measureValue: PropTypes.node,
	measureUnit: PropTypes.node,
	measureValue2: PropTypes.node,
	measureUnit2: PropTypes.node,
	footerIcon: PropTypes.node,
	footerText: PropTypes.node,
	footerDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
	gridSizes: PropTypes.object,
	noWrapper: PropTypes.bool,
	onClick: PropTypes.func,
	onLongPress: PropTypes.func,
	smallerValueFont: PropTypes.bool,
}

export default DashboardCard

import React from "react"
import { Hidden, withStyles } from "@material-ui/core"
import { Button, Input } from "@ncs/bricks/components"
import { PowerSettingsNew, ShoppingCart, CheckCircle, History } from "@material-ui/icons"

import headerLinksStyle from "@ncs/bricks/assets/jss/material-dashboard-pro-react/components/headerLinksStyle"

function ExPartsOrderHeader(props) {
	const { classes, onSignOut, onCheckout, onOrderHistory, onViewCart } = props
	return (
		<div>
			<Input
				labelText={"Search"}
				id={"searchText"}
				inputProps={{
					name: "searchText",
					id: "searchText",
				}}
			/>
			<Button
				color="transparent"
				justIcon
				className={classes.buttonLink}
				onClick={onOrderHistory}
			>
				<History />
			</Button>
			<Button
				color="transparent"
				justIcon
				className={classes.buttonLink}
				onClick={onViewCart}
			>
				<ShoppingCart />
			</Button>
			<Button
				color="transparent"
				aria-label="Your Cart"
				justIcon
				className={classes.buttonLink}
				onClick={onCheckout}
			>
				<CheckCircle />
			</Button>
			<Button
				color="transparent"
				aria-label="Sign Out"
				justIcon
				className={classes.buttonLink}
				muiClasses={{
					label: "",
				}}
				onClick={onSignOut}
			>
				<PowerSettingsNew className={classes.headerLinksSvg + " " + classes.links} />
				<Hidden mdUp>
					<span className={classes.linkText}>{"Sign Out"}</span>
				</Hidden>
			</Button>
		</div>
	)
}

export default withStyles(headerLinksStyle)(ExPartsOrderHeader)

import { FC, useMemo, useState } from "react"

import { makeApiErrorMessage, useUpdateOrganizationContract } from "@ncs/ncs-api"
import { titleCase } from "@ncs/ts-utils"
import { ExtendableModalProps, Modal, NumericInput, useToast } from "@ncs/web-legos"

import { EditableContractNumberFields } from "../contract-utils"

export interface EditContractNumberModalProps extends ExtendableModalProps {
	contractId: string
	field: EditableContractNumberFields
	initialValue: number | null
}

export const EditContractNumberModal: FC<EditContractNumberModalProps> = ({
	contractId,
	field,
	initialValue,
	...rest
}) => {
	const { makeSuccessToast } = useToast()
	const [value, setValue] = useState<number | null>(initialValue)
	const [errorText, setErrorText] = useState<string | null>(null)
	const [isSaving, setIsSaving] = useState(false)

	const updateContract = useUpdateOrganizationContract()

	const onSave = async () => {
		try {
			setIsSaving(true)
			await updateContract({
				updates: {
					contract: contractId,
					[field]: value,
				},
			})
			makeSuccessToast("Contract updated")
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const reset = () => {
		setValue(initialValue)
		setErrorText(null)
		setIsSaving(false)
	}

	const label = useMemo(() => {
		switch (field) {
			case "escalatorPercent": {
				return "Escalator %"
			}
			case "escalatorMonths": {
				return "Escalator months"
			}
			case "maxPriceVariancePercent": {
				return "Max price variance %"
			}
			case "maxPriceVarianceMonths": {
				return "Max price variance months"
			}
		}
	}, [field])

	const isPercentage = ["escalatorPercent", "maxPriceVariancePercent"].includes(field)

	return (
		<Modal
			title={titleCase(label)}
			errorText={errorText}
			onOpen={reset}
			rightButtons={{
				buttonText: "Save",
				onClick: onSave,
				isLoading: isSaving,
			}}
			{...rest}
		>
			<NumericInput
				doPercentageMath={isPercentage}
				label={label}
				value={value}
				onChange={(newValue) => setValue(newValue ?? null)}
				decimalScale={isPercentage ? 3 : undefined}
				max={isPercentage ? 100 : undefined}
				min={0}
			/>
		</Modal>
	)
}

import React from "react"
import PropTypes from "prop-types"
import TimeAgo from "react-timeago"
import { Typography, CardHeader, Avatar, makeStyles, createStyles } from "@material-ui/core"
import { Comment as CommentIcon } from "@material-ui/icons"
import { Card, CardBody } from "../../components"
import { CardProps } from "../Card/Card"

import {
	blackColor,
	grayColor,
	hexToRgb,
	successColor,
	whiteColor,
} from "../../assets/jss/material-dashboard-pro-react"

const styles = createStyles({
	commentCard: {
		boxShadow:
			"0 10px 20px -12px rgba(" +
			hexToRgb(blackColor) +
			", 0.42), 0 3px 20px 0px rgba(" +
			hexToRgb(blackColor) +
			", 0.12), 0 8px 10px -5px rgba(" +
			hexToRgb(blackColor) +
			", 0.2)",
		backgroundColor: successColor[0],
	},
	commentHeader: {
		paddingBottom: 0,
	},
	commentAvatarIcon: {
		color: successColor[0],
	},
	commentAvatar: {
		backgroundColor: whiteColor,
	},
	commentAuthor: {
		color: whiteColor,
	},
	commentDate: {
		color: grayColor[9],
	},
	commentText: {
		color: whiteColor,
	},
})

const useStyles = makeStyles(styles)

export interface CommentProps extends CardProps {
	author: string
	text: string
	date?: Date | string
}

const Comment: React.FC<CommentProps> = ({ author, date, text, ...rest }) => {
	const classes = useStyles()

	return (
		<Card className={classes.commentCard} {...rest}>
			<CardHeader
				avatar={
					<Avatar aria-label="Comment" className={classes.commentAvatar}>
						<CommentIcon className={classes.commentAvatarIcon} />
					</Avatar>
				}
				title={author}
				subheader={date ? <TimeAgo date={date} /> : undefined}
				className={classes.commentHeader}
				classes={{
					title: classes.commentAuthor,
					subheader: classes.commentDate,
				}}
			/>
			<CardBody>
				<Typography component="p" className={classes.commentText}>
					{text}
				</Typography>
			</CardBody>
		</Card>
	)
}

Comment.propTypes = {
	author: PropTypes.string.isRequired,
	date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
	text: PropTypes.string.isRequired,
}

export default Comment

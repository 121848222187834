import { FC, useState } from "react"

import { saveAs } from "file-saver"

import { BinManagementBin, LabelSize, makeApiErrorMessage, usePrintBinLabels } from "@ncs/ncs-api"
import { ExtendableModalProps, LabelSizeSelector, Modal, useToast } from "@ncs/web-legos"

export interface PrintSingleBinLabelModalProps extends ExtendableModalProps {
	locationId: string
	bin: BinManagementBin
}

export const PrintSingleBinLabelModal: FC<PrintSingleBinLabelModalProps> = ({
	locationId,
	bin,
	...rest
}) => {
	const { makeSuccessToast, makeErrorToast } = useToast()
	const [size, setSize] = useState<LabelSize>("3x2")
	const [isSaving, setIsSaving] = useState(false)
	const getLabelPdf = usePrintBinLabels()

	const handleCreateLabel = async () => {
		try {
			setIsSaving(true)
			const { data } = await getLabelPdf({
				location: locationId,
				allBins: false,
				bins: [bin.id],
				size,
			})
			saveAs(new Blob([data]), `labels_${size}.pdf`)
			makeSuccessToast("Labels created")
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			makeErrorToast(makeApiErrorMessage(e))
		}
	}

	return (
		<Modal
			{...rest}
			title="Print Bin Label"
			rightButtons={{
				buttonText: "Download label",
				onClick: handleCreateLabel,
				isLoading: isSaving,
			}}
		>
			<LabelSizeSelector value={size} onChange={setSize} radio />
		</Modal>
	)
}

import { Dispatch, ReactElement, SetStateAction, useEffect, useState } from "react"

import { EquipmentVintageLookup } from "@ncs/ncs-api"

import { usePrevious } from "../../util"
import { EquipmentVintageSelector } from "../selectors"

interface EquipmentVintageQueryParams {
	equipment: string | null
}

export interface EquipmentVintageQueryFilterProps<
	QueryParamState extends EquipmentVintageQueryParams,
> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

/**
 * Same thing as `VintageQueryFilter` except the key is `equipment` instead of `vintage`.
 */
export const EquipmentVintageQueryFilter = <QueryParamState extends EquipmentVintageQueryParams>({
	queryParamState,
	setQueryParamState,
}: EquipmentVintageQueryFilterProps<QueryParamState>): ReactElement => {
	const [selectedEquipmentVintage, setSelectedEquipmentVintage] =
		useState<EquipmentVintageLookup | null>(null)

	const handleChange = (newEquipmentVintage: EquipmentVintageLookup | null) => {
		// Set local state with full EquipmentVintage object.
		setSelectedEquipmentVintage(newEquipmentVintage)
		// Set query param state with just the EquipmentVintage ID.
		setQueryParamState((prev) => ({
			...prev,
			equipment: newEquipmentVintage?.id ?? null,
		}))
	}

	const prevEquipmentVintageFromQueryParams = usePrevious(queryParamState.equipment)
	useEffect(() => {
		// Handles the user clicking Reset button.
		if (
			queryParamState.equipment === null &&
			prevEquipmentVintageFromQueryParams !== null &&
			selectedEquipmentVintage !== null
		) {
			setSelectedEquipmentVintage(null)
		}
	}, [prevEquipmentVintageFromQueryParams, queryParamState.equipment, selectedEquipmentVintage])

	return (
		<EquipmentVintageSelector
			value={selectedEquipmentVintage}
			onChange={handleChange}
			fillContainer
		/>
	)
}

import React, { useMemo } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import {
	FormControl,
	FormHelperText,
	InputLabel,
	Select,
	MenuItem,
	makeStyles,
	FormControlProps,
	InputLabelProps,
	SelectProps,
} from "@material-ui/core"
import { getAccessorValue } from "../../util/accessors"

import styles from "../../assets/jss/material-dashboard-pro-react/components/customSelectStyle"

const useStyles = makeStyles(styles)

interface SelectOption {
	[key: string]: unknown
}

export interface CustomSelectProps {
	labelText?: React.ReactNode
	id?: string
	name?: string
	value?: number | string
	onChange?: (value: string) => void
	onChangeWithEvent?: (event: React.ChangeEvent<{ value: unknown }>) => void
	options: SelectOption[]
	valueAccessor?: string | ((option: SelectOption) => string)
	textAccessor?: string | ((option: SelectOption) => string)
	formControlProps?: FormControlProps
	labelProps?: InputLabelProps
	selectProps?: SelectProps
	inputProps?: SelectProps["inputProps"]
	emptyItemsText?: string
	isError?: boolean
	helperText?: string
}

const CustomSelect: React.FC<CustomSelectProps> = ({
	labelText,
	id,
	name,
	options,
	valueAccessor = "value",
	textAccessor = "text",
	emptyItemsText = "No Items",
	value,
	onChange,
	onChangeWithEvent,
	formControlProps,
	labelProps,
	inputProps,
	selectProps,
	helperText,
	isError,
}) => {
	const classes = useStyles()

	const formControlClasses = useMemo(
		() => classNames(classes.selectFormControl, formControlProps?.className),
		[classes, formControlProps]
	)

	// avoid a warning if the options collection isn't yet loaded and the value isn't in there
	const optionIsAvailable = (options ?? []).find(
		(x) => getAccessorValue(x, valueAccessor) === value
	)
	const protectedValue = optionIsAvailable ? value : ""

	return (
		<FormControl
			fullWidth
			className={formControlClasses}
			{...formControlProps}
			error={isError}
		>
			{labelText !== undefined ?
				<InputLabel className={classes.selectLabel} htmlFor={id} {...labelProps}>
					{labelText}
				</InputLabel>
			:	null}
			<Select
				MenuProps={{
					className: classes.selectMenu,
				}}
				classes={{
					select: classes.select,
				}}
				value={protectedValue}
				onChange={(event) => {
					if (typeof onChangeWithEvent === "function") onChangeWithEvent(event)

					if (typeof onChange === "function") onChange(event.target.value as string)
				}}
				inputProps={{
					name: name || id,
					id,
					...inputProps,
				}}
				{...selectProps}
			>
				{options.map((option, i) => (
					<MenuItem
						key={i}
						disabled={!!option.disabled}
						classes={{
							root: classes.selectMenuItem,
							selected: classes.selectMenuItemSelected,
						}}
						value={getAccessorValue(option, valueAccessor)}
					>
						{getAccessorValue(option, textAccessor)}
					</MenuItem>
				))}
				{options.length === 0 && (
					<MenuItem
						disabled={true}
						value=""
						classes={{
							root: classes.selectMenuItem,
							selected: classes.selectMenuItemSelected,
						}}
					>
						{emptyItemsText}
					</MenuItem>
				)}
			</Select>
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
		</FormControl>
	)
}

CustomSelect.propTypes = {
	labelText: PropTypes.node,
	id: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	onChange: PropTypes.func,
	onChangeWithEvent: PropTypes.func,
	options: PropTypes.array.isRequired,
	valueAccessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
	textAccessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
	formControlProps: PropTypes.object,
	labelProps: PropTypes.object,
	inputProps: PropTypes.object,
	selectProps: PropTypes.object,
	emptyItemsText: PropTypes.string,
	isError: PropTypes.bool,
	helperText: PropTypes.string,
}

export default React.memo(CustomSelect)

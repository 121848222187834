import { FC } from "react"

import { ContractCoverageType, useContractCoverageTypes } from "@ncs/ncs-api"

import { ExtendableSelectProps, Select } from "../inputs"

export interface ContractCoverageTypeSelectorProps
	extends ExtendableSelectProps<ContractCoverageType> {}

export const ContractCoverageTypeSelector: FC<ContractCoverageTypeSelectorProps> = ({
	...rest
}) => {
	const [types, isLoading] = useContractCoverageTypes()

	return (
		<Select
			disabled={isLoading}
			label="Site coverage"
			{...rest}
			disableNoSelectionOption={false}
			options={types ?? []}
			valueAccessor="id"
			textAccessor="description"
			fillContainer
		/>
	)
}

import { FC, useState } from "react"

import dayjs from "dayjs"
import { Cell, Column } from "react-table"

import {
	CustomerCredit,
	CustomerDetail,
	makeApiErrorMessage,
	useDeleteSpentCredit,
} from "@ncs/ncs-api"
import { formatCurrency, formatDate, formatNumber } from "@ncs/ts-utils"
import {
	Box,
	Button,
	Callout,
	ConfirmationModal,
	ConfirmationModalConfig,
	EmptyValueDash,
	encodeUrlState,
	GridContainer,
	GridItem,
	Heading,
	IconButton,
	Label,
	Link,
	Paragraph,
	Price,
	Table,
	useToast,
} from "@ncs/web-legos"

import { ViewInvoicesTabUrlState } from "~/views/Service/Invoices/components"

import { ExpireCreditModal } from "./ExpireCreditModal"
import { RecordExpenditureModal } from "./RecordExpenditureModal"
import { RelatedCustomerModal } from "./RelatedCustomerModal"

export interface BusinessCreditProps {
	customer: CustomerDetail
	credit: CustomerCredit
}

export const BusinessCredit: FC<BusinessCreditProps> = ({ customer, credit }) => {
	const { makeSuccessToast, makeErrorToast } = useToast()
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)
	const [addingCreditModal, setAddingCreditModal] = useState<string | null>(null)
	const [showExpireModal, setShowExpireModal] = useState(false)
	const [showRelatedModal, setShowRelatedModal] = useState(false)

	const deleteCredit = useDeleteSpentCredit()

	const handleDeleteExpenditure = async (id: string) => {
		try {
			await deleteCredit({ body: { history: id } })
			makeSuccessToast("Expenditure removed")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		}
	}

	const creditName = credit.businessUnit?.name || credit.otherUnit?.name
	const totalSpent = credit.history.reduce(
		(total: number, item) => (item.amount as number) + total,
		0
	)
	const remaining = credit.amount - totalSpent
	const isExpired = dayjs().isAfter(credit.expiresOn)

	return (
		<>
			<GridContainer opacity={isExpired ? 0.6 : undefined} mb={2}>
				<GridItem xs={12} sm={6} md={4} lg={3} display="flex" flexDirection="column">
					<Label>Business unit</Label>
					<Heading variant="h2">{creditName}</Heading>
				</GridItem>
				<GridItem xs={12} sm={6} md={4} lg={2} display="flex" flexDirection="column">
					<Label>Remaining</Label>
					<Price
						price={remaining}
						color={remaining > 0 ? "success" : undefined}
						small={remaining <= 0}
						round
					/>
				</GridItem>
				<GridItem xs={12} sm={6} md={4} lg={2} display="flex" flexDirection="column">
					<Label>Initial amount</Label>
					<Paragraph>{formatCurrency(credit.amount)}</Paragraph>
				</GridItem>
				<GridItem xs={12} sm={6} md={4} lg={2} display="flex" flexDirection="column">
					<Label>Comments</Label>
					<Paragraph>{credit.comments || <EmptyValueDash />}</Paragraph>
				</GridItem>
				<GridItem xs={12} sm={6} md={4} lg={2} display="flex" flexDirection="column">
					{isExpired ?
						<Callout variant="info" icon="calendar-circle-exclamation" mt={0} mb={0}>
							<Paragraph>
								Expired {formatDate(credit.expiresOn, { formatInUtc: true })}
							</Paragraph>
						</Callout>
					:	<>
							<Label>Expires on</Label>
							<Box display="flex" gap={0.5} alignItems="center">
								<Paragraph>
									{formatDate(credit.expiresOn, { formatInUtc: true })}
								</Paragraph>
								<Box>
									<IconButton
										icon="pencil"
										color="primary"
										onClick={() => setShowExpireModal(true)}
									/>
								</Box>
							</Box>
						</>
					}
				</GridItem>
			</GridContainer>

			<Button icon="plus" onClick={() => setAddingCreditModal(credit.id)}>
				Record {creditName} credit expenditure
			</Button>

			{credit.history.length ?
				<Table
					data={credit.history}
					columns={columns}
					disableAllSorting
					rowMenu={[
						{
							label: "Delete",
							iconName: "trash-alt",
							onClick: ({ original }) =>
								setConfirmationConfig({
									title: "Delete Expenditure",
									message: "Remove this expenditure from this business credit?",
									onConfirm: () => handleDeleteExpenditure(original.id),
								}),
						},
					]}
					defaultSort={[{ id: "transaction-date", desc: true }]}
				/>
			:	<Paragraph small secondary mt={0} mb={1}>
					No expenditures recorded for this credit yet
				</Paragraph>
			}

			{credit.relatedSiteCount > 0 && (
				<Box>
					<Button onClick={() => setShowRelatedModal(true)} icon="info-circle">
						{formatNumber(credit.relatedSiteCount)} other related customer
						{credit.relatedSiteCount !== 1 && "s"} with {creditName} credit
					</Button>
				</Box>
			)}

			{!!addingCreditModal && (
				<RecordExpenditureModal
					isOpen
					onClose={() => setAddingCreditModal(null)}
					customer={customer}
					creditId={addingCreditModal}
				/>
			)}
			{showExpireModal && (
				<ExpireCreditModal
					isOpen
					onClose={() => setShowExpireModal(false)}
					credit={credit}
					customerId={customer.id}
				/>
			)}
			{showRelatedModal && (
				<RelatedCustomerModal
					isOpen
					onClose={() => setShowRelatedModal(false)}
					credit={credit}
					customerId={customer.id}
				/>
			)}
			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</>
	)
}

type CreditExpenditure = CustomerCredit["history"][number]

const columns: Column<CreditExpenditure>[] = [
	{
		Header: "Amount",
		id: "amount",
		accessor: ({ amount }) => formatCurrency(amount),
	},
	{
		Header: "Transaction date",
		id: "transaction-date",
		accessor: ({ createdOn }) => dayjs(createdOn).unix(),
		Cell: ({ row: { original } }: Cell<CreditExpenditure>) => formatDate(original.createdOn),
	},
	{
		Header: "Part order",
		accessor: "partOrderId",
		Cell: ({ row: { original } }: Cell<CreditExpenditure>) => {
			return original.partOrderId && original.orderId ?
					<Link to={`/part-orders/${original.partOrderId}`} newTab icon="external-link">
						Part Order #{original.orderId}
					</Link>
				:	<EmptyValueDash />
		},
	},
	{
		Header: "Invoice",
		accessor: "invoiceId",
		Cell: ({ row: { original } }: Cell<CreditExpenditure>) => {
			return original.invoiceId && original.invoiceNumber ?
					<Link
						to={`/service/dispatches${encodeUrlState<ViewInvoicesTabUrlState>({
							search: original.invoiceNumber,
						})}`}
						icon="external-link"
						newTab
					>
						Invoice #{original.invoiceNumber}
					</Link>
				:	<EmptyValueDash />
		},
	},
	{
		Header: "Description",
		accessor: "description",
		Cell: ({ row: { original } }: Cell<CreditExpenditure>) => {
			return original.description || <EmptyValueDash />
		},
	},
	{
		Header: "Created by",
		accessor: "creator",
	},
]

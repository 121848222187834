import { FormState } from "react-hook-form"
import { z } from "zod"

import { Equipment, UpdateEquipmentPatch } from "@ncs/ncs-api"

export const EquipmentFormSchema = z.object({
	serialNumber: z.string().min(1, "Required").max(255),
	isActive: z.boolean(),
	eqpVintageId: z.string().min(1, "Required"),
	modelId: z.string(),
	installDate: z.string().nullable(),
	bay: z.string().max(50).nullable(),
	requiresWashCounts: z.boolean(),
	frameWarrantyExpireDate: z.string().nullable(),
	laborWarrantyExpireDate: z.string().nullable(),
	partsWarrantyExpireDate: z.string().nullable(),
	keepWorkOrderHistory: z.boolean().optional(),
})

export type EquipmentForm = z.infer<typeof EquipmentFormSchema>

export const equipmentFormDefaultValues: EquipmentForm = {
	serialNumber: "",
	isActive: true,
	eqpVintageId: "",
	modelId: "",
	installDate: null,
	bay: null,
	requiresWashCounts: false,
	frameWarrantyExpireDate: null,
	laborWarrantyExpireDate: null,
	partsWarrantyExpireDate: null,
	keepWorkOrderHistory: undefined,
}

export const getFormValuesFromEquipment = (equipment: Equipment | null): EquipmentForm => {
	return {
		serialNumber: equipment?.serialNumber || equipmentFormDefaultValues.serialNumber,
		isActive: equipment?.isActive ?? equipmentFormDefaultValues.isActive,
		eqpVintageId: equipment?.modelId?.toString() ?? equipmentFormDefaultValues.eqpVintageId,
		modelId: equipment?.modelId?.toString() ?? equipmentFormDefaultValues.modelId,
		installDate: equipment?.installDate || equipmentFormDefaultValues.installDate,
		bay: equipment?.bay || equipmentFormDefaultValues.bay,
		requiresWashCounts:
			equipment?.requiresWashCounts ?? equipmentFormDefaultValues.requiresWashCounts,
		frameWarrantyExpireDate:
			equipment?.frameWarrantyExpireDate ||
			equipmentFormDefaultValues.frameWarrantyExpireDate,
		laborWarrantyExpireDate:
			equipment?.laborWarrantyExpireDate ||
			equipmentFormDefaultValues.laborWarrantyExpireDate,
		partsWarrantyExpireDate:
			equipment?.partsWarrantyExpireDate ||
			equipmentFormDefaultValues.partsWarrantyExpireDate,
	}
}

export const assembleRequestPatch = (
	formData: EquipmentForm,
	dirtyFields: FormState<EquipmentForm>["dirtyFields"]
): UpdateEquipmentPatch => {
	// The form data's going to have everything in it, so we'll just check through
	// the dirty fields object to see what we should include.
	return {
		serialNumber: dirtyFields.serialNumber ? formData.serialNumber : undefined,
		isActive: dirtyFields.isActive ? formData.isActive : undefined,
		eqpVintageId: dirtyFields.eqpVintageId ? formData.eqpVintageId : undefined,
		modelId: dirtyFields.modelId ? formData.modelId : undefined,
		installDate: dirtyFields.installDate ? formData.installDate : undefined,
		bay: dirtyFields.bay ? formData.bay : undefined,
		requiresWashCounts:
			dirtyFields.requiresWashCounts ? formData.requiresWashCounts : undefined,
		frameWarrantyExpireDate:
			dirtyFields.frameWarrantyExpireDate ? formData.frameWarrantyExpireDate : undefined,
		laborWarrantyExpireDate:
			dirtyFields.laborWarrantyExpireDate ? formData.laborWarrantyExpireDate : undefined,
		partsWarrantyExpireDate:
			dirtyFields.partsWarrantyExpireDate ? formData.partsWarrantyExpireDate : undefined,
		// Only include this if vintage is dirty.
		keepWorkorderHistory: dirtyFields.eqpVintageId ? formData.keepWorkOrderHistory : undefined,
	}
}

import { FC, useState } from "react"

import { Contract, makeApiErrorMessage, useUpdateOrganizationContract } from "@ncs/ncs-api"
import { Checkbox, ExtendableModalProps, Modal, useToast } from "@ncs/web-legos"

export interface EditPricingSchedulesModalProps extends ExtendableModalProps {
	contract: Contract
}

export const EditPricingSchedulesModal: FC<EditPricingSchedulesModalProps> = ({
	contract,
	...rest
}) => {
	const { makeSuccessToast } = useToast()
	const [hasChemicalSchedule, setHasChemicalSchedule] = useState(contract.hasChemicalSchedule)
	const [hasSelfServiceChemicalSchedule, setHasSelfServiceChemicalSchedule] = useState(
		contract.hasSelfServiceChemicalSchedule
	)
	const [hasPartsSchedule, setHasPartsSchedule] = useState(contract.hasPartsSchedule)
	const [hasServiceSchedule, setHasServiceSchedule] = useState(contract.hasServiceSchedule)

	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const updateContract = useUpdateOrganizationContract()

	const handleSave = async () => {
		try {
			setIsSaving(true)
			await updateContract({
				updates: {
					contract: contract.id,
					hasChemicalSchedule,
					hasSelfServiceChemicalSchedule,
					hasPartsSchedule,
					hasServiceSchedule,
				},
			})
			makeSuccessToast("Contract updated")
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const reset = () => {
		setHasChemicalSchedule(contract.hasChemicalSchedule)
		setHasSelfServiceChemicalSchedule(contract.hasSelfServiceChemicalSchedule)
		setHasPartsSchedule(contract.hasPartsSchedule)
		setHasServiceSchedule(contract.hasServiceSchedule)
		setIsSaving(false)
		setErrorText(null)
	}

	return (
		<Modal
			title="Pricing Schedules"
			errorText={errorText}
			onOpen={reset}
			rightButtons={{
				buttonText: "Save",
				onClick: handleSave,
				isLoading: isSaving,
			}}
			{...rest}
		>
			<Checkbox
				value={hasChemicalSchedule}
				onChange={setHasChemicalSchedule}
				label="Has chemical pricing schedule"
				mb={0}
			/>
			<Checkbox
				value={hasSelfServiceChemicalSchedule}
				onChange={setHasSelfServiceChemicalSchedule}
				label="Has self-service chemical pricing schedule"
				mb={0}
			/>
			<Checkbox
				value={hasPartsSchedule}
				onChange={setHasPartsSchedule}
				label="Has parts pricing schedule"
				mb={0}
			/>
			<Checkbox
				value={hasServiceSchedule}
				onChange={setHasServiceSchedule}
				label="Has service pricing schedule"
				mb={0}
			/>
		</Modal>
	)
}

import { FC, Fragment, useCallback, useMemo, useState } from "react"

import dayjs, { Dayjs } from "dayjs"

import {
	APPLICATION,
	makeApiErrorMessage,
	UpdateContractPatch,
	useContractIncentives,
	useCreateContractIncentives,
	useDeleteContractIncentives,
	useOrganizationContract,
	useUpdateContractIncentives,
	useUpdateOrganizationContract,
	useUserCanUse,
} from "@ncs/ncs-api"
import {
	extractNumber,
	formatDate,
	formatNumber,
	formatPercentage,
	noNullish,
} from "@ncs/ts-utils"
import {
	Box,
	Button,
	ConfirmationModal,
	ConfirmationModalConfig,
	CssGridTable,
	DateInputIcon,
	Divider,
	EditStringModal,
	EditStringModalProps,
	EmptyValueDash,
	GridContainer,
	GridItem,
	Heading,
	HeadingDivider,
	HeadingDividerProps,
	Icon,
	IconButton,
	Label,
	LoadingSpinner,
	Paragraph,
	useToast,
} from "@ncs/web-legos"

import {
	ContractIncentiveProgram as IContractIncentiveProgram,
	EditableContractNumberFields,
	formIncentiveToIncentivePatch,
	formIncentiveToIncentivePost,
	groupIncentivesByUnits,
} from "../contract-utils"
import { ContractIncentiveProgram } from "../ContractIncentiveProgram"
import { ContractIncentiveProgramModal } from "../ContractIncentiveProgramModal"
import { PriceEscalationsModal } from "../PriceEscalationsModal"
import { EditContractNumberModal } from "./EditContractNumberModal"
import { EditContractTypesModal } from "./EditContractTypesModal"
import { EditPricingSchedulesModal } from "./EditPricingSchedulesModal"
import { EditProductCoverageModal } from "./EditProductCoverageModal"
import { EditSiteCoverageModal } from "./EditSiteCoverageModal"

export interface ContractDetailSummaryTabProps {
	contractId: string
}

export const ContractDetailSummaryTab: FC<ContractDetailSummaryTabProps> = ({ contractId }) => {
	const canEdit = useUserCanUse(APPLICATION.ContractCreator)
	const { makeSuccessToast, makeErrorToast } = useToast()
	const [addingNewIncentive, setAddingNewIncentive] = useState(false)
	const [incentiveToEdit, setIncentiveToEdit] = useState<IContractIncentiveProgram | null>(null)
	const [numberFieldToEdit, setNumberFieldToEdit] =
		useState<EditableContractNumberFields | null>(null)
	const [showEditProductCoverage, setShowEditProductCoverage] = useState(false)
	const [showEditSchedules, setShowEditSchedules] = useState(false)
	const [showEditSiteCoverage, setShowEditSiteCoverage] = useState(false)
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)
	const [showEscalationModal, setShowEscalationModal] = useState(false)
	const [showEditTypesModal, setShowEditTypesModal] = useState(false)
	const [stringToEdit, setStringToEdit] = useState<"organization" | "exception" | null>(null)

	const [contract, contractLoading] = useOrganizationContract(contractId)
	const [incentives, incentivesLoading] = useContractIncentives(contractId)

	const updateContract = useUpdateOrganizationContract()
	const createIncentives = useCreateContractIncentives()
	const updateIncentives = useUpdateContractIncentives()
	const removeIncentives = useDeleteContractIncentives()

	const handleFieldUpdate = useCallback(
		async <F extends keyof UpdateContractPatch>(field: F, value: UpdateContractPatch[F]) => {
			try {
				await updateContract({
					updates: {
						contract: contractId,
						[field]: value,
					},
				})
				makeSuccessToast("Contract updated")
			} catch (e) {
				makeErrorToast(makeApiErrorMessage(e))
			}
		},
		[contractId, makeErrorToast, makeSuccessToast, updateContract]
	)

	const handleSaveDate = async (
		newDate: Dayjs | null,
		key: "effectiveDate" | "expirationDate"
	) => {
		try {
			if (!newDate) throw new Error("No date selected")

			await updateContract({
				updates: {
					contract: contractId,
					[key]: newDate.toISOString(),
				},
				id: contractId,
			})
			makeSuccessToast("Contract updated")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		}
	}

	const handleCreateIncentives = async (incentive: IContractIncentiveProgram) => {
		await createIncentives(formIncentiveToIncentivePost(incentive, contractId))
		makeSuccessToast("Incentive program added")
	}

	const handleUpdateIncentives = async (incentive: IContractIncentiveProgram) => {
		await updateIncentives({ updates: formIncentiveToIncentivePatch(incentive, contractId) })
		makeSuccessToast("Incentive program updated")
	}

	const handleRemoveIncentive = async (program: IContractIncentiveProgram) => {
		await removeIncentives({
			body: {
				contract: contractId,
				rebates: noNullish([program.rebate?.id]),
				credits: noNullish([program.credit?.id]),
				discounts: noNullish([program.discount?.id]),
			},
		})
		makeSuccessToast("Contract incentives removed")
	}

	const coverageList = useMemo(() => {
		const items = []

		if (contract?.partsCovered) items.push("Parts")
		if (contract?.chemicalsCovered) items.push("Chemicals")
		if (contract?.equipmentInstallCovered) items.push("Equipment / Install")
		if (contract?.maintenanceCovered) items.push("Maintenance")

		return items.length ? <Paragraph>{items.join(", ")}</Paragraph> : <EmptyValueDash />
	}, [
		contract?.partsCovered,
		contract?.chemicalsCovered,
		contract?.equipmentInstallCovered,
		contract?.maintenanceCovered,
	])

	const schedulesList = useMemo(() => {
		const items = []

		if (contract?.hasChemicalSchedule) items.push("Has chemical pricing schedule")
		if (contract?.hasSelfServiceChemicalSchedule)
			items.push("Has self-service chemical pricing schedule")
		if (contract?.hasPartsSchedule) items.push("Has parts pricing schedule")
		if (contract?.hasServiceSchedule) items.push("Has has service pricing schedule")

		return items.length ?
				items.map((item) => (
					<Box key={item} display="flex" alignItems="center" mb={0.5} columnGap={0.5}>
						<Icon icon="check" />
						<Paragraph>{item}</Paragraph>
					</Box>
				))
			:	<Paragraph small color="secondary">
					None
				</Paragraph>
	}, [
		contract?.hasChemicalSchedule,
		contract?.hasSelfServiceChemicalSchedule,
		contract?.hasPartsSchedule,
		contract?.hasServiceSchedule,
	])

	const incentivePrograms = useMemo(() => {
		if (incentives) {
			return groupIncentivesByUnits(incentives)
		} else {
			return []
		}
	}, [incentives])

	const editStringModalConfigs: EditStringModalProps | null = useMemo(() => {
		const baseConfig = {
			isOpen: true,
			onClose: () => setStringToEdit(null),
		}

		switch (stringToEdit) {
			case "organization": {
				return {
					...baseConfig,
					label: "Organization",
					title: "Edit Organization",
					initialValue: contract?.organization,
					onSave: async (newValue: string | null) => {
						if (newValue) await handleFieldUpdate("organization", newValue)
					},
					maxLength: 100,
				}
			}
			case "exception": {
				return {
					...baseConfig,
					title: "Edit Special Exception",
					label: "Special exception",
					initialValue: contract?.specialException,
					onSave: async (newValue: string | null) => {
						await handleFieldUpdate("specialException", newValue)
					},
					allowEmpty: true,
					textarea: true,
					maxLength: 5000,
				}
			}
			default: {
				return null
			}
		}
	}, [stringToEdit, handleFieldUpdate, contract?.organization, contract?.specialException])

	const sectionHeadingProps: HeadingDividerProps = {
		headingVariant: "h4",
		mt: 3,
		mb: 1,
		bold: true,
	}

	if (contractLoading) {
		return <LoadingSpinner />
	}

	if (!contract) {
		return null
	}

	return (
		<>
			<GridContainer>
				<GridItem xs={12} sm={6} md={3} display="flex" flexDirection="column">
					<Label>Organization</Label>
					<Box display="flex" alignItems="center" columnGap={0.5} mt={0.25}>
						<Heading>{contract.organization}</Heading>
						{canEdit && (
							<IconButton
								icon="pencil"
								color="primary"
								onClick={() => setStringToEdit("organization")}
							/>
						)}
					</Box>
				</GridItem>
				<GridItem xs={12} sm={6} md={3} display="flex" flexDirection="column">
					<Label>Contract types</Label>
					<Box display="flex" alignItems="center" columnGap={0.5} mt={0.25}>
						<Heading>
							{contract.types.length ?
								contract.types.map((t) => t.description).join(", ")
							:	<EmptyValueDash />}
						</Heading>
						{canEdit && (
							<IconButton
								icon="pencil"
								color="primary"
								onClick={() => setShowEditTypesModal(true)}
							/>
						)}
					</Box>
				</GridItem>
				<GridItem xs={12} sm={6} md={3}>
					<Label>Effective date</Label>
					<Box display="flex" alignItems="center" columnGap={0.5}>
						<Heading>{formatDate(contract.effectiveDate)}</Heading>
						{canEdit && (
							<DateInputIcon
								value={dayjs(contract.effectiveDate)}
								onChange={(newValue) => handleSaveDate(newValue, "effectiveDate")}
							/>
						)}
					</Box>
				</GridItem>
				<GridItem xs={12} sm={6} md={3}>
					<Label>Expiration date</Label>
					<Box display="flex" alignItems="center" columnGap={0.5}>
						<Heading>{formatDate(contract.expirationDate)}</Heading>
						{canEdit && (
							<DateInputIcon
								value={dayjs(contract.expirationDate)}
								onChange={(newValue) => handleSaveDate(newValue, "expirationDate")}
							/>
						)}
					</Box>
				</GridItem>
			</GridContainer>

			<Divider my={2} />

			<GridContainer>
				<GridItem xs={12} sm={6} md={3}>
					<Label>Escalator %</Label>
					<Box display="flex" alignItems="center" columnGap={0.5}>
						{contract.escalatorPercent != null ?
							<Paragraph>{formatPercentage(contract.escalatorPercent)}</Paragraph>
						:	<EmptyValueDash />}
						{canEdit && (
							<IconButton
								icon="pencil"
								color="primary"
								onClick={() => setNumberFieldToEdit("escalatorPercent")}
							/>
						)}
					</Box>
				</GridItem>
				<GridItem xs={12} sm={6} md={3}>
					<Label>Escalator time (months)</Label>
					<Box display="flex" alignItems="center" columnGap={0.5}>
						{contract.escalatorMonths ?
							<Paragraph>{formatNumber(contract.escalatorMonths)} months</Paragraph>
						:	<EmptyValueDash />}
						{canEdit && (
							<IconButton
								icon="pencil"
								color="primary"
								onClick={() => setNumberFieldToEdit("escalatorMonths")}
							/>
						)}
					</Box>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<Label>Price escalations</Label>
					{contract.priceIncreases.length ?
						<>
							<CssGridTable
								gridTemplateColumns="1fr 1fr 1fr"
								columnGap={1}
								rowGap={0.35}
								mt={0.25}
								alignItems="flex-start"
								cells={contract.priceIncreases.map((increase) => (
									<Fragment key={increase.id}>
										<Paragraph>
											Scheduled: {formatDate(increase.scheduledOn)}
										</Paragraph>
										<Paragraph>
											Executed: {formatDate(increase.executedOn)}
										</Paragraph>
										<Paragraph>
											Increased:{" "}
											{formatPercentage(
												extractNumber(increase.percentIncreased)
											)}
										</Paragraph>
									</Fragment>
								))}
							/>
							{canEdit && (
								<Box mt={0.5}>
									<Button
										icon="pencil"
										onClick={() => setShowEscalationModal(true)}
									>
										Edit
									</Button>
								</Box>
							)}
						</>
					:	<Box display="flex" alignItems="center" columnGap={0.5}>
							<EmptyValueDash />
							{canEdit && (
								<IconButton
									color="primary"
									icon="pencil"
									onClick={() => setShowEscalationModal(true)}
								/>
							)}
						</Box>
					}
				</GridItem>
				<GridItem xs={12} sm={6} md={3}>
					<Label>Max price variance %</Label>
					<Box display="flex" alignItems="center" columnGap={0.5}>
						{contract.maxPriceVariancePercent ?
							<Paragraph>
								{formatPercentage(contract.maxPriceVariancePercent)}
							</Paragraph>
						:	<EmptyValueDash />}
						{canEdit && (
							<IconButton
								icon="pencil"
								color="primary"
								onClick={() => setNumberFieldToEdit("maxPriceVariancePercent")}
							/>
						)}
					</Box>
				</GridItem>
				<GridItem xs={12} sm={6} md={3}>
					<Label>Max price variance time (months)</Label>
					<Box display="flex" alignItems="center" columnGap={0.5}>
						{contract.maxPriceVarianceMonths ?
							<Paragraph>
								{formatNumber(contract.maxPriceVarianceMonths)} months
							</Paragraph>
						:	<EmptyValueDash />}
						{canEdit && (
							<IconButton
								icon="pencil"
								color="primary"
								onClick={() => setNumberFieldToEdit("maxPriceVarianceMonths")}
							/>
						)}
					</Box>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<Label>Product coverage</Label>
					<Box display="flex" alignItems="center" columnGap={0.5}>
						{coverageList}
						{canEdit && (
							<IconButton
								icon="pencil"
								color="primary"
								onClick={() => setShowEditProductCoverage(true)}
							/>
						)}
					</Box>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<Label>Site coverage</Label>
					<Box display="flex" alignItems="center" columnGap={0.5}>
						<Paragraph>
							{contract.coverageType?.description ?? <EmptyValueDash />}
						</Paragraph>
						{canEdit && (
							<IconButton
								icon="pencil"
								color="primary"
								onClick={() => setShowEditSiteCoverage(true)}
							/>
						)}
					</Box>
				</GridItem>
			</GridContainer>

			<GridContainer mt={1}>
				<GridItem xs={12} sm={6}>
					<Label>Special exception</Label>
					<Box display="flex" alignItems="center" gap={0.5}>
						<Paragraph>{contract.specialException || <EmptyValueDash />}</Paragraph>
						{canEdit && (
							<IconButton
								icon="pencil"
								color="primary"
								onClick={() => setStringToEdit("exception")}
							/>
						)}
					</Box>
				</GridItem>
			</GridContainer>

			<HeadingDivider {...sectionHeadingProps} icon="file-invoice-dollar">
				Pricing Schedules
			</HeadingDivider>
			<Box pl={1.5}>
				<Box display="flex" alignItems="flex-start" columnGap={0.5}>
					<div>{schedulesList}</div>
					{canEdit && (
						<Box mt={-0.35}>
							<IconButton
								icon="pencil"
								color="primary"
								onClick={() => setShowEditSchedules(true)}
							/>
						</Box>
					)}
				</Box>
			</Box>

			<HeadingDivider {...sectionHeadingProps} icon="hand-holding-dollar">
				Incentive Programs
			</HeadingDivider>
			{incentivesLoading && <LoadingSpinner />}
			<Box display="flex" flexDirection="column" rowGap={2} pl={1.5}>
				{incentivePrograms
					?.filter((program) => program.categories.length > 0)
					.map((program) => (
						<ContractIncentiveProgram
							key={program.categories.map((c) => c.name).join("-")}
							incentive={program}
							remainingCredits={program.remaining}
							onEdit={() => setIncentiveToEdit(program)}
							onRemove={() =>
								setConfirmationConfig({
									title: "Remove Incentive Program",
									message: "Remove this incentive program from this contract?",
									onConfirm: () => handleRemoveIncentive(program),
								})
							}
						/>
					))}
				{canEdit && (
					<Button
						icon="plus"
						containerProps={{ mb: 5 }}
						onClick={() => setAddingNewIncentive(true)}
					>
						Add incentive program
					</Button>
				)}
			</Box>

			{!!editStringModalConfigs && <EditStringModal {...editStringModalConfigs} />}
			{!!numberFieldToEdit && (
				<EditContractNumberModal
					contractId={contractId}
					field={numberFieldToEdit}
					initialValue={contract[numberFieldToEdit]}
					isOpen={!!numberFieldToEdit}
					onClose={() => setNumberFieldToEdit(null)}
				/>
			)}
			{!!contract && (
				<>
					<EditProductCoverageModal
						contract={contract}
						isOpen={showEditProductCoverage}
						onClose={() => setShowEditProductCoverage(false)}
					/>
					<EditPricingSchedulesModal
						contract={contract}
						isOpen={showEditSchedules}
						onClose={() => setShowEditSchedules(false)}
					/>
					<EditContractTypesModal
						isOpen={showEditTypesModal}
						onClose={() => setShowEditTypesModal(false)}
						contract={contract}
					/>
					{showEditSiteCoverage && (
						<EditSiteCoverageModal
							contract={contract}
							onClose={() => setShowEditSiteCoverage(false)}
						/>
					)}
				</>
			)}
			<PriceEscalationsModal
				isOpen={showEscalationModal}
				onClose={() => setShowEscalationModal(false)}
				escalations={contract.priceIncreases.map((i) => ({
					...i,
					percentIncreased: extractNumber(i.percentIncreased),
				}))}
				onSave={(increases) => handleFieldUpdate("priceIncreases", increases)}
			/>
			<ContractIncentiveProgramModal
				isOpen={addingNewIncentive || !!incentiveToEdit}
				onClose={() => {
					setAddingNewIncentive(false)
					setIncentiveToEdit(null)
				}}
				incentive={incentiveToEdit ?? null}
				onSave={(incentive) =>
					addingNewIncentive ?
						handleCreateIncentives(incentive)
					:	handleUpdateIncentives(incentive)
				}
			/>
			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</>
	)
}

import { Dispatch, useState, ReactElement, SetStateAction } from "react"

import { CustomerInternalSelector } from "../selectors"

export interface CustomerInternalQueryParams {
	isInternal: boolean | null
}

export interface CustomerInternalQueryFilterProps<
	QueryParamState extends CustomerInternalQueryParams,
> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

export const CustomerInternalQueryFilter = <QueryParamState extends CustomerInternalQueryParams>({
	queryParamState,
	setQueryParamState,
}: CustomerInternalQueryFilterProps<QueryParamState>): ReactElement => {
	const handleChange = (newValue: string) => {
		setQueryParamState((prev) => ({
			...prev,
			isInternal:
				newValue === "true" ? true
				: newValue === "false" ? false
				: null,
		}))
	}

	return (
		<CustomerInternalSelector
			value={queryParamState.isInternal}
			onChange={handleChange}
			fillContainer
			disableNoSelectionOption={false}
			noSelectionOptionText="Any Customer"
		/>
	)
}

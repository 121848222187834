import { get, patch, post, rsaaTypes, RSAA } from "@ncs/bricks/middleware/portalApiMiddleware"

export const SET_SELECTED_REPORT = "reports/set_selected_report"
export const FETCH_DISPATCH_COUNTS = rsaaTypes("reports/dispatch_counts")
export const FETCH_DISPATCH_HISTORY = rsaaTypes("reports/dispatch_history")
export const FETCH_TICKET_LIFECYCLE = rsaaTypes("reports/ticket_lifecycle")
export const FETCH_TICKET_CLOSED_BY_USER = rsaaTypes("reports/ticket_closed_by_user")
export const FETCH_TICKET_ABUSER = rsaaTypes("reports/ticket_abuser")
export const FETCH_SITE_SURVEY_GROUPED = rsaaTypes("reports/site_survey_grouped")
export const FETCH_SITE_SURVEY = rsaaTypes("reports/site_survey")
export const FETCH_ERP_DETAIL = rsaaTypes("reports/erp_detail")
export const FETCH_ERP_SUMMARY = rsaaTypes("reports/erp_summary")
export const EXPORT_DISPATCH_HISTORY = rsaaTypes("reports/dispatch_history/export")
export const EXPORT_SITE_SURVEY_GROUPED = rsaaTypes("reports/site_survey_grouped/export")
export const EXPORT_SITE_SURVEY = rsaaTypes("reports/site_survey/export")
export const GET_REPORT_EXPORTS = rsaaTypes("reports/exports/get")
export const PATCH_REPORT_EXPORTS = rsaaTypes("reports/exports/patch")
export const GENERATE_LINK = rsaaTypes("reports/exports/generate_link")
export const FETCH_WORKORDER_DETAILS = rsaaTypes("reports/workorder_detail")
export const EXPORT_WORKORDER_DETAILS = rsaaTypes("reports/workorder_detail/export")
export const FETCH_OPEN_DISPATCH_TYPE = rsaaTypes("reports/open_dispatch_type")
export const EXPORT_OPEN_DISPATCH_TYPE = rsaaTypes("reports/open_dispatch_type/export")
export const EXPORT_SERVICE_TREND = rsaaTypes("reports/service_trend/export")
export const FETCH_SERVICE_TREND = rsaaTypes("reports/service_trend")
export const FETCH_WAREHOUSE_ORDER_REPORT = rsaaTypes("reports/warehouse_order_report")
export const FETCH_NEGATIVE_ONHAND = rsaaTypes("reports/negative_onhand")
export const EXPORT_NEGATIVE_ONHAND = rsaaTypes("reports/negative_onhand/export")
export const FETCH_ALLOCATED_PARTS = rsaaTypes("reports/allocated_parts")
export const EXPORT_ALLOCATED_PARTS = rsaaTypes("reports/allocated_parts/export")
export const FETCH_PICK_LIST_DETAIL = rsaaTypes("reports/pick_list_detail")
export const EXPORT_PICK_LIST_DETAIL = rsaaTypes("reports/pick_list_detail/export")
export const FETCH_PART_ORDER_DETAIL = rsaaTypes("reports/part_order_detail")
export const EXPORT_PART_ORDER_DETAIL = rsaaTypes("reports/part_order_detail/export")
export const FETCH_PIPM_DETAIL = rsaaTypes("reports/pipm_detail")
export const EXPORT_PIPM_DETAIL = rsaaTypes("reporst/pipm_detail/export")
export const FETCH_PIPM_SUMMARY = rsaaTypes("reports/pipm_summary")
export const EXPORT_PIPM_SUMMARY = rsaaTypes("reports/pipm_summary/export")
export const FETCH_PRICING_MODIFICATIONS = rsaaTypes("reports/pricing_modification")
export const EXPORT_PRICING_MODIFICATIONS = rsaaTypes("reports/pricing_modification/export")
export const LIST_ALLOWED_REPORTS = rsaaTypes("auth/allowed_reports")
export const FETCH_LABOR_RATE_REPORT = rsaaTypes("reports/labor_rate")
export const EXPORT_LABOR_RATE_REPORT = rsaaTypes("reports/labor_rate/export")
export const FETCH_CHEMICAL_SUMMARY_REPORT = rsaaTypes("reports/chemical_summary")
export const EXPORT_CHEMICAL_SUMMARY_REPORT = rsaaTypes("reports/chemical_summary/export")
export const FETCH_CHEMICAL_DETAIL_REPORT = rsaaTypes("reports/chemical_detail")
export const EXPORT_CHEMICAL_DETAIL_REPORT = rsaaTypes("reports/chemical_detail/export")
export const FETCH_MACHINE_MILESTONE_REPORT = rsaaTypes("reports/machine_milestone")
export const EXPORT_MACHINE_MILESTONE_REPORT = rsaaTypes("reports/machine_milestone/export")
export const FETCH_SALES_REP_INVOICES = rsaaTypes("reports/sales_rep")
export const FETCH_SALES_REP_DETAILS = rsaaTypes("report/sales_rep_details")
export const FETCH_SALES_REP_ASSIGNMENTS = rsaaTypes("report/sales_rep_assignments")
export const FETCH_INVENTORY_CONSOLIDATION_REPORT = rsaaTypes(
	"report/inventory_consolidation_report"
)

export const setSelectedReport = (selectedReport) => (dispatch) => {
	dispatch({ type: SET_SELECTED_REPORT, payload: { selectedReport } })
}

export const fetchSiteSurveyGrouped = (querystringParams) => ({
	[RSAA]: {
		api: get(`/reports/site_survey_grouped/`, querystringParams),
		types: FETCH_SITE_SURVEY_GROUPED,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const fetchERPDetail = (querystringParams) => ({
	[RSAA]: {
		api: get(`/reports/erp_detail/`, querystringParams),
		types: FETCH_ERP_DETAIL,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const fetchERPSummary = (querystringParams) => ({
	[RSAA]: {
		api: get(`/reports/erp_summary/`, querystringParams),
		types: FETCH_ERP_SUMMARY,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const fetchSiteSurvey = (querystringParams) => ({
	[RSAA]: {
		api: get(`/reports/site_survey/`, querystringParams),
		types: FETCH_SITE_SURVEY,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const fetchDispatchCounts = (querystringParams) => ({
	[RSAA]: {
		api: get(`/reports/dispatch_counts/`, querystringParams),
		types: FETCH_DISPATCH_COUNTS,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const fetchDispatchHistory = (querystringParams) => ({
	[RSAA]: {
		api: get(`/reports/dispatch_history/`, querystringParams),
		types: FETCH_DISPATCH_HISTORY,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const fetchWorkOrderDetails = (querystringParams) => ({
	[RSAA]: {
		api: get(`/reports/work_order_detail/`, querystringParams),
		types: FETCH_WORKORDER_DETAILS,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const fetchOpenDispatchType = (querystringParams) => ({
	[RSAA]: {
		api: get(`/reports/open_dispatch_type/`, querystringParams),
		types: FETCH_OPEN_DISPATCH_TYPE,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const fetchServiceTrend = (querystringParams) => ({
	[RSAA]: {
		api: get(`/reports/service_trend/`, querystringParams),
		types: FETCH_SERVICE_TREND,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const fetchWarehouseOrderReport = (querystringParams) => ({
	[RSAA]: {
		api: get(`/reports/warehouse_order_report/`, querystringParams),
		types: FETCH_WAREHOUSE_ORDER_REPORT,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const fetchAllocatedParts = (querystringParams) => ({
	[RSAA]: {
		api: get(`/reports/allocated_parts/`, querystringParams),
		types: FETCH_ALLOCATED_PARTS,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const fetchPickListDetail = (querystringParams) => ({
	[RSAA]: {
		api: get(`/reports/pick_list_detail/`, querystringParams),
		types: FETCH_PICK_LIST_DETAIL,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const fetchPartOrderDetail = (querystringParams) => ({
	[RSAA]: {
		api: get(`/reports/part_order_detail/`, querystringParams),
		types: FETCH_PART_ORDER_DETAIL,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const fetchPIPMDetail = (querystringParams) => ({
	[RSAA]: {
		api: get(`/reports/pmpi_detail/`, querystringParams),
		types: FETCH_PIPM_DETAIL,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const fetchPIPMSummary = (querystringParams) => ({
	[RSAA]: {
		api: get(`/reports/pmpi_summary/`, querystringParams),
		types: FETCH_PIPM_SUMMARY,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const fetchPricingModifications = (querystringParams) => ({
	[RSAA]: {
		api: get(`/reports/pricing_modifications/`, querystringParams),
		types: FETCH_PRICING_MODIFICATIONS,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const fetchTicketLifecycle = (querystringParams) => ({
	[RSAA]: {
		api: get(`/reports/ticket_lifecycle/`, querystringParams),
		types: FETCH_TICKET_LIFECYCLE,
		parseLinkHeader: true,
	},
})

export const fetchTicketClosedByUser = (querystringParams) => ({
	[RSAA]: {
		api: get(`/reports/tickets_closed_by_user/`, querystringParams),
		types: FETCH_TICKET_CLOSED_BY_USER,
		parseLinkHeader: true,
	},
})

export const fetchTicketAbuser = (querystringParams) => ({
	[RSAA]: {
		api: get(`/reports/ticket_abuser/`, querystringParams),
		types: FETCH_TICKET_ABUSER,
		parseLinkHeader: true,
	},
})

export const fetchNegativeOnHand = (querystringParams) => ({
	[RSAA]: {
		api: get(`/reports/negative_on_hand/`, querystringParams),
		types: FETCH_NEGATIVE_ONHAND,
		parseLinkHeader: true,
	},
})

export const fetchLaborRateReport = (querystringParams) => ({
	[RSAA]: {
		api: get(`/reports/labor_pricing/`, querystringParams),
		types: FETCH_LABOR_RATE_REPORT,
		parseLinkHeader: true,
	},
})

export const fetchChemicalRevSummary = (querystringParams) => ({
	[RSAA]: {
		api: get(`/reports/chemical_summary/`, querystringParams),
		types: FETCH_CHEMICAL_SUMMARY_REPORT,
		parseLinkHeader: true,
	},
})

export const fetchChemicalRevDetail = (querystringParams) => ({
	[RSAA]: {
		api: get(`/reports/chemical_detail/`, querystringParams),
		types: FETCH_CHEMICAL_DETAIL_REPORT,
		parseLinkHeader: true,
	},
})

export const fetchMilestoneDetail = (querystringParams) => ({
	[RSAA]: {
		api: get(`/reports/machine_milestone/`, querystringParams),
		types: FETCH_MACHINE_MILESTONE_REPORT,
		parseLinkHeader: true,
	},
})

export const fetchSalesRepSummary = (querystringParams) => ({
	[RSAA]: {
		api: get(`/reports/sales_rep_invoices/`, querystringParams),
		types: FETCH_SALES_REP_INVOICES,
		parseLinkHeader: true,
	},
})

export const fetchSalesRepDetail = (querystringParams) => ({
	[RSAA]: {
		api: get(`/reports/sales_rep_detail/`, querystringParams),
		types: FETCH_SALES_REP_DETAILS,
		parseLinkHeader: true,
	},
})

export const fetchSalesRepAssignment = (querystringParams) => ({
	[RSAA]: {
		api: get(`/reports/customer_sales_rep/`, querystringParams),
		types: FETCH_SALES_REP_ASSIGNMENTS,
		parseLinkHeader: true,
	},
})

export const fetchInventoryConsolidation = (querystringParams) => ({
	[RSAA]: {
		api: get(`/reports/part_consolidation/`, querystringParams),
		types: FETCH_INVENTORY_CONSOLIDATION_REPORT,
		parseLinkHeader: true,
	},
})

export const exportMilestoneDetails = (querystringParams) => ({
	[RSAA]: {
		api: post(`/reports/machine_milestone/export/`, undefined, querystringParams),
		types: EXPORT_MACHINE_MILESTONE_REPORT,
	},
})

export const exportChemicalRevDetail = (querystringParams) => ({
	[RSAA]: {
		api: post(`/reports/chemical_detail/export/`, undefined, querystringParams),
		types: EXPORT_CHEMICAL_DETAIL_REPORT,
	},
})

export const exportChemicalRevSummary = (querystringParams) => ({
	[RSAA]: {
		api: post(`/reports/chemical_summary/export/`, undefined, querystringParams),
		types: EXPORT_CHEMICAL_SUMMARY_REPORT,
	},
})

export const exportLaborRateReport = (querystringParams) => ({
	[RSAA]: {
		api: post(`/reports/labor_pricing/export/`, undefined, querystringParams),
		types: EXPORT_LABOR_RATE_REPORT,
	},
})

export const exportDispatchHistory = (querystringParams) => ({
	[RSAA]: {
		api: post(`/reports/dispatch_history/export/`, undefined, querystringParams),
		types: EXPORT_DISPATCH_HISTORY,
	},
})

export const exportSiteSurveyGrouped = (querystringParams) => ({
	[RSAA]: {
		api: post(`/reports/site_survey_grouped/export/`, undefined, querystringParams),
		types: EXPORT_SITE_SURVEY_GROUPED,
	},
})

export const exportSiteSurvey = (querystringParams) => ({
	[RSAA]: {
		api: post(`/reports/site_survey/export/`, undefined, querystringParams),
		types: EXPORT_SITE_SURVEY,
	},
})

export const exportServiceTrend = (querystringParams) => ({
	[RSAA]: {
		api: post(`/reports/service_trend/export/`, undefined, querystringParams),
		types: EXPORT_SERVICE_TREND,
	},
})

export const exportWorkOrderDetail = (querystringParams) => ({
	[RSAA]: {
		api: post(`/reports/work_order_detail/export/`, undefined, querystringParams),
		types: EXPORT_WORKORDER_DETAILS,
	},
})

export const exportOpenDispatchType = (querystringParams) => ({
	[RSAA]: {
		api: post(`/reports/open_dispatch_type/export/`, undefined, querystringParams),
		types: EXPORT_OPEN_DISPATCH_TYPE,
	},
})

export const exportNegativeOnHand = (querystringParams) => ({
	[RSAA]: {
		api: post(`/reports/negative_on_hand/export/`, undefined, querystringParams),
		types: EXPORT_NEGATIVE_ONHAND,
	},
})

export const exportAllocatedParts = (querystringParams) => ({
	[RSAA]: {
		api: post(`/reports/allocated_parts/export/`, undefined, querystringParams),
		types: EXPORT_ALLOCATED_PARTS,
	},
})

export const exportPickListDetails = (querystringParams) => ({
	[RSAA]: {
		api: post(`/reports/pick_list_detail/export/`, undefined, querystringParams),
		types: EXPORT_PICK_LIST_DETAIL,
	},
})

export const exportPartOrderDetails = (querystringParams) => ({
	[RSAA]: {
		api: post(`/reports/part_order_detail/export/`, undefined, querystringParams),
		types: EXPORT_PART_ORDER_DETAIL,
	},
})

export const exportPIPMDetail = (querystringParams) => ({
	[RSAA]: {
		api: post(`/reports/pmpi_detail/export/`, undefined, querystringParams),
		types: EXPORT_PIPM_DETAIL,
	},
})

export const exportPIPMSummary = (querystringParams) => ({
	[RSAA]: {
		api: post(`/reports/pmpi_summary/export/`, undefined, querystringParams),
		types: EXPORT_PIPM_SUMMARY,
	},
})

export const exportPricingModifications = (querystringParams) => ({
	[RSAA]: {
		api: post(`/reports/pricing_modifications/export/`, undefined, querystringParams),
		types: EXPORT_PRICING_MODIFICATIONS,
	},
})

export const getReportExport = (id) => ({
	[RSAA]: {
		api: get(`/reports/exports/${id}/`),
		types: GET_REPORT_EXPORTS,
	},
})

export const patchReportExport = (id, data) => ({
	[RSAA]: {
		api: patch(`/reports/exports/${id}/`, data),
		types: PATCH_REPORT_EXPORTS,
	},
})

export const generateLink = (id) => ({
	[RSAA]: {
		api: post(`/reports/exports/${id}/generate_link/`),
		types: GENERATE_LINK,
	},
})

export const listAllowedReports = () => ({
	[RSAA]: {
		api: get("/reports/permissions/"),
		types: LIST_ALLOWED_REPORTS,
	},
})

const initialState = {
	isLoadingDispatchCounts: true,
	dispatchCounts: [],

	isLoadingDispatchHistory: true,
	dispatchHistory: [],

	isLoadingDispatchHistoryExport: true,
	dispatchHistoryExport: [],

	isLoadingTicketLifecycle: true,
	ticketLifecycle: [],

	isLoadingTicketClosedByUser: true,
	ticketClosedByUser: [],

	isLoadingTicketAbuser: true,
	ticketAbusers: [],

	isLoadingReportExport: false,
	reportExport: null,

	isGeneratingLink: false,
	reportExportLink: null,

	allowedReports: [],

	errors: {},
}

const reports = (state = initialState, action) => {
	switch (action.type) {
		case SET_SELECTED_REPORT:
			return {
				...state,
				...action.payload,
			}

		case FETCH_DISPATCH_COUNTS.request:
		case FETCH_DISPATCH_COUNTS.failure:
			return {
				...state,
				isLoadingDispatchCounts: action.type === FETCH_DISPATCH_COUNTS.request,
				error: action.error,
			}
		case FETCH_DISPATCH_COUNTS.success:
			return {
				...state,
				isLoadingDispatchCounts: false,
				dispatchCounts: action.payload,
			}

		case FETCH_DISPATCH_HISTORY.request:
		case FETCH_DISPATCH_HISTORY.failure:
			return {
				...state,
				isLoadingDispatchHistory: action.type === FETCH_DISPATCH_HISTORY.request,
				error: action.error,
			}
		case FETCH_DISPATCH_HISTORY.success:
			return {
				...state,
				isLoadingDispatchHistory: false,
				dispatchHistory: action.payload,
			}
		case FETCH_TICKET_LIFECYCLE.request:
		case FETCH_TICKET_LIFECYCLE.failure:
			return {
				...state,
				isLoadingTicketLifecycle: action.type === FETCH_TICKET_LIFECYCLE.request,
				error: action.error,
			}
		case FETCH_TICKET_LIFECYCLE.success:
			return {
				...state,
				isLoadingTicketLifecycle: false,
				ticketLifecycle: action.payload,
			}
		case FETCH_TICKET_CLOSED_BY_USER.request:
		case FETCH_TICKET_CLOSED_BY_USER.failure:
			return {
				...state,
				isLoadingTicketClosedByUser: action.type === FETCH_TICKET_CLOSED_BY_USER.request,
				error: action.error,
			}
		case FETCH_TICKET_CLOSED_BY_USER.success:
			return {
				...state,
				isLoadingTicketClosedByUser: false,
				ticketClosedByUser: action.payload,
			}
		case FETCH_TICKET_ABUSER.request:
		case FETCH_TICKET_ABUSER.failure:
			return {
				...state,
				isLoadingTicketAbuser: action.type === FETCH_TICKET_ABUSER.request,
				error: action.error,
			}
		case FETCH_TICKET_ABUSER.success:
			return {
				...state,
				isLoadingTicketAbuser: false,
				ticketAbusers: action.payload,
			}
		case EXPORT_DISPATCH_HISTORY.request:
		case EXPORT_DISPATCH_HISTORY.failure:
			return {
				...state,
				isLoadingDispatchHistoryExport: action.type === EXPORT_DISPATCH_HISTORY.request,
				error: action.error,
			}
		case EXPORT_DISPATCH_HISTORY.success:
			return {
				...state,
				isLoadingDispatchHistoryExport: false,
				dispatchHistoryExport: action.payload,
			}

		case PATCH_REPORT_EXPORTS.request:
		case PATCH_REPORT_EXPORTS.success:
		case PATCH_REPORT_EXPORTS.failure:
			return {
				...state,
				isSaving: action.type === PATCH_REPORT_EXPORTS.request,
				error: action.error,
			}

		case GET_REPORT_EXPORTS.request:
		case GET_REPORT_EXPORTS.failure:
			return {
				...state,
				isLoadingReportExport: action.type === GET_REPORT_EXPORTS.request,
				error: action.error,
			}
		case GET_REPORT_EXPORTS.success:
			return {
				...state,
				isLoadingReportExport: false,
				reportExport: action.payload,
			}

		case GENERATE_LINK.request:
			return {
				...state,
				isGeneratingLink: true,
			}
		case GENERATE_LINK.success:
			return {
				...state,
				isGeneratingLink: false,
				reportExportLink: action.payload.presign_url,
			}

		case LIST_ALLOWED_REPORTS.success:
			return {
				...state,
				allowedReports: action.payload,
			}
		case LIST_ALLOWED_REPORTS.failure:
			return {
				...state,
				allowedReports: [],
				errors: action.payload.response || { non_field_errors: action.payload.statusText },
			}

		default:
			return state
	}
}
export default reports

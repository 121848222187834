import React from "react"

//custom components
import { GridContainer, QuickCard, DynamicTable } from "~/components"

//material ui components
import ListIcon from "@material-ui/icons/List"
import { formatDateTime } from "@ncs/bricks/util/formatters"

function TicketEvent(props) {
	let { getEvents, reduxKey } = props

	return (
		<GridContainer>
			<QuickCard title="Events" shortenHeader={true} icon={<ListIcon />}>
				<DynamicTable // TODO: make props static
					reduxKey={reduxKey}
					fetchDataAction={getEvents}
					style={{ textAlign: "center " }}
					columns={[
						{
							Header: "Event",
							accessor: "description",
							id: "description",
							sortable: false,
							toggleable: false,
							headerStyle: { textAlign: "center" },
						},
						{
							Header: "Created By",
							accessor: (x) => x.created_by.name,
							id: "created_by.name",
							sortable: false,
							toggleable: false,
							headerStyle: { textAlign: "center" },
						},
						{
							Header: "Date",
							accessor: (x) => formatDateTime(x.created_date),
							id: "created_date",
							sortable: false,
							toggleable: false,
							headerStyle: { textAlign: "center" },
						},
					]}
				/>
			</QuickCard>
		</GridContainer>
	)
}

export default TicketEvent

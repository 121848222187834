import { z } from "zod"

import { ChemicalCustomerDetail, UpdateContainerChemicalPatch } from "@ncs/ncs-api"
import { extractNumber } from "@ncs/ts-utils"

export const ChemicalSettingsFormSchema = z.object({
	containerId: z.string().min(1, "Required"),
	expectedMlUsage: z.string().min(1, "Required"),
	lowLevelGallons: z.string().min(1, "Required"),
	maxBackStockQuantity: z.string().min(1, "Required"),
	name: z.string().min(1, "Required"),
	pumpSetting: z.string().nullable(),
	tipType: z.string().nullable(),
	partId: z.string().min(1, "Required"),
})

export type ChemicalSettingsForm = z.infer<typeof ChemicalSettingsFormSchema>

export const defaultChemicalSettingsFormValues: ChemicalSettingsForm = {
	containerId: "",
	expectedMlUsage: "",
	lowLevelGallons: "",
	maxBackStockQuantity: "",
	name: "",
	pumpSetting: null,
	tipType: null,
	partId: "",
}

export const makeChemicalSettingsFormState = (
	container: ChemicalCustomerDetail["containers"][number]
): ChemicalSettingsForm => {
	return {
		containerId: container.id,
		expectedMlUsage: container.expectedMlUsage?.toString() ?? "",
		lowLevelGallons: container.lowLevelGallons.toString(),
		maxBackStockQuantity: container.maxBackStockQuantity?.toString() ?? "",
		name: container.name ?? "",
		partId: container.partId,
		pumpSetting: container.pumpSetting,
		tipType: container.tipType,
	}
}

export const makeContainerChemicalUpdatePatch = (
	form: ChemicalSettingsForm
): UpdateContainerChemicalPatch => {
	return {
		expectedMlUsage: extractNumber(form.expectedMlUsage),
		lowLevelGallons: extractNumber(form.lowLevelGallons),
		maxBackStockQuantity: extractNumber(form.maxBackStockQuantity),
		name: form.name,
		partId: form.partId,
		pumpSetting: form.pumpSetting,
		tipType: form.tipType,
	}
}

import { FC, useState } from "react"

import { InvoiceSource, useInvoiceSources } from "@ncs/ncs-api"

import { ExtendableSearchableSelectProps, SearchableSelect } from "../inputs"

export interface InvoiceSelectorProps extends ExtendableSearchableSelectProps<InvoiceSource> {}

/**
 * Heads up, this uses the `InvoiceSourceViewSet` on the backend. I guess that's fine?
 */
export const InvoiceSelector: FC<InvoiceSelectorProps> = ({ onChange, ...rest }) => {
	const [searchInput, setSearchInput] = useState<string | null>(null)

	const invoicesQuery = useInvoiceSources({
		params: {
			search: searchInput,
		},
		pageSize: 10,
		queryConfig: {
			enabled: !!searchInput,
		},
	})

	return (
		<SearchableSelect
			isLoading={invoicesQuery.isLoading}
			label="Invoice"
			placeholder="Search for an invoice..."
			{...rest}
			onItemSelect={onChange}
			getOptionLabel={(option) =>
				`#${option.invoiceNumber} - ${option.invoiceType}${
					option.customer.territory ?
						` - (${option.customer.territory.code}) ${option.customer.territory.description}`
					:	""
				}`
			}
			options={invoicesQuery.data}
			onInputChange={setSearchInput}
		/>
	)
}

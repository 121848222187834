import { FC, Fragment, useState } from "react"

import { useFormContext } from "react-hook-form"

import { useContractTypes } from "@ncs/ncs-api"
import { addIfNew, extractNumber, formatDate, formatPercentage } from "@ncs/ts-utils"
import {
	Box,
	Button,
	CheckboxFormField,
	ContractCoverageTypeSelector,
	ContractTypeSelector,
	CssGridTable,
	DateInputFormField,
	GridContainer,
	GridItem,
	HookFormErrorText,
	IconButton,
	Label,
	NumericInputFormField,
	Paragraph,
	TextareaFormField,
	TextInputFormField,
} from "@ncs/web-legos"

import { NewContractForm } from "../contract-utils"
import { PriceEscalationsModal } from "../PriceEscalationsModal"

export const NewContractMain: FC = () => {
	const [addingAnotherType, setAddingAnotherType] = useState(false)
	const [showEscalationsModal, setShowEscalationsModal] = useState(false)
	const [contractTypes] = useContractTypes()

	const { watch, setValue, control } = useFormContext<NewContractForm>()
	const [typeIds, escalations, productCoverage] = watch([
		"typeIds",
		"priceIncreases",
		"coverageType",
	])

	return (
		<>
			<GridContainer mb={0.5}>
				<GridItem md={12} lg={6}>
					<TextInputFormField control={control} name="organization" fillContainer />

					<Box mb={1}>
						<Label>Contract type(s)</Label>
						{typeIds.map((id) => {
							const type = contractTypes?.find((t) => t.id === id)

							if (type) {
								return (
									<Box
										key={id}
										display="flex"
										alignItems="center"
										columnGap={0.5}
										mb={0.5}
									>
										<Paragraph>{type.description}</Paragraph>
										<IconButton
											icon="trash-alt"
											size="sm"
											onClick={() =>
												setValue(
													"typeIds",
													typeIds.filter((typeId) => typeId !== id),
													{
														shouldValidate: true,
													}
												)
											}
										/>
									</Box>
								)
							} else {
								return null
							}
						})}

						{(typeIds.length === 0 || addingAnotherType) && (
							<ContractTypeSelector
								value={null}
								label=""
								onChange={(value) => {
									setAddingAnotherType(false)
									if (value) {
										setValue("typeIds", addIfNew(value, typeIds), {
											shouldValidate: true,
										})
									}
								}}
								disabledAccessor={(option) => typeIds.includes(option.id)}
							/>
						)}
						<HookFormErrorText control={control} name="typeIds" mt={-0.75} mb={1} />

						{!addingAnotherType && typeIds.length > 0 && (
							<Button
								icon="plus"
								onClick={() => setAddingAnotherType(true)}
								containerProps={{ mb: 1 }}
							>
								Add another type
							</Button>
						)}
					</Box>

					<Box display="flex" justifyContent="space-between" columnGap={1}>
						<DateInputFormField
							control={control}
							name="effectiveDate"
							label="Effective date"
						/>
						<DateInputFormField
							control={control}
							name="expirationDate"
							label="Expiration date"
						/>
					</Box>

					<ContractCoverageTypeSelector
						value={productCoverage}
						onChange={(newTypeId) => {
							setValue("coverageType", newTypeId, {
								shouldValidate: true,
							})
						}}
					/>
				</GridItem>
			</GridContainer>

			<Label>Product coverage</Label>
			<CheckboxFormField control={control} name="partsCovered" label="Parts" mb={0} />
			<CheckboxFormField
				control={control}
				name="chemicalsCovered"
				label="Chemicals"
				mb={0}
			/>
			<CheckboxFormField
				control={control}
				name="equipmentInstallCovered"
				label="Equipment / Install"
				mb={0}
			/>
			<CheckboxFormField
				control={control}
				name="maintenanceCovered"
				label="Maintenance"
				mb={0}
			/>

			<GridContainer mt={1}>
				<GridItem md={12} lg={6}>
					<Box display="flex" justifyContent="space-between" columnGap={1}>
						<NumericInputFormField
							control={control}
							name="escalatorPercent"
							label="Escalator %"
							placeholder="Eg, 1.5%"
							min={0}
							max={100}
							decimalScale={3}
							doPercentageMath
						/>
						<NumericInputFormField
							control={control}
							name="escalatorMonths"
							label="Escalator months"
							placeholder="Eg, 12"
						/>
					</Box>
					<Box display="flex" justifyContent="space-between" columnGap={1}>
						<NumericInputFormField
							control={control}
							name="maxPriceVariancePercent"
							label="Max price variance %"
							placeholder="Eg, 1.5%"
							min={0}
							max={100}
							decimalScale={3}
							doPercentageMath
						/>
						<NumericInputFormField
							control={control}
							name="maxPriceVarianceMonths"
							label="Max price variance months"
							placeholder="Eg, 12"
						/>
					</Box>
				</GridItem>
			</GridContainer>

			<GridContainer mb={0.5}>
				<GridItem md={12} lg={6}>
					<TextInputFormField control={control} name="docusignId" label="Docusign ID" />
				</GridItem>
			</GridContainer>

			<GridContainer mb={0.5}>
				<GridItem md={12} lg={6}>
					<TextareaFormField
						control={control}
						name="specialException"
						label="Special exception"
					/>
				</GridItem>
			</GridContainer>

			<GridContainer mb={0.5}>
				<GridItem md={12} lg={6}>
					<Label>Price escalations</Label>
					<CssGridTable
						gridTemplateColumns="1fr 1fr 1fr"
						columnGap={1}
						rowGap={0.35}
						mt={0.25}
						mb={0.5}
						cells={escalations.map((increase, i) => (
							<Fragment key={i}>
								<Paragraph>
									Scheduled: {formatDate(increase.scheduledOn)}
								</Paragraph>
								<Paragraph>Executed: {formatDate(increase.executedOn)}</Paragraph>
								<Paragraph>
									Increased:{" "}
									{formatPercentage(extractNumber(increase.percentIncreased))}
								</Paragraph>
							</Fragment>
						))}
					/>
					{escalations.length ?
						<Button icon="pencil" onClick={() => setShowEscalationsModal(true)}>
							Edit price escalations
						</Button>
					:	<Button icon="plus" onClick={() => setShowEscalationsModal(true)}>
							Add price escalations
						</Button>
					}
				</GridItem>
			</GridContainer>

			<PriceEscalationsModal
				isOpen={showEscalationsModal}
				onClose={() => setShowEscalationsModal(false)}
				escalations={escalations}
				onSave={(increases) => {
					setValue("priceIncreases", increases, {
						shouldValidate: true,
					})
				}}
			/>
		</>
	)
}

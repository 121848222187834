import { FC, useMemo, useState } from "react"

import { UserMinimal, UserMinimalQueryParams, useUser, useUsers } from "@ncs/ncs-api"

import { useInitialId } from "../../util"
import { ExtendableSearchableSelectProps, SearchableSelect } from "../inputs"
import { Paragraph } from "../typography"

export interface UserSelectorProps extends ExtendableSearchableSelectProps<UserMinimal> {
	params?: Partial<UserMinimalQueryParams>
	initialId?: string | null
	customersOnly?: boolean
	employeesOnly?: boolean
	isGuest?: boolean
	isActive?: boolean
}

export const UserSelector: FC<UserSelectorProps> = ({
	params,
	value,
	onChange,
	initialId,
	customersOnly,
	employeesOnly,
	isGuest,
	isActive = true,
	...rest
}) => {
	const [search, setSearch] = useState<string | null>(null)

	const isCustomer =
		customersOnly != null ? customersOnly
		: employeesOnly != null ? !employeesOnly
		: undefined

	const { data, isLoading, isFetching } = useUsers({
		params: {
			isGuest: isGuest ?? null,
			isCustomer,
			search,
			isActive,
		},
	})
	const [initialUser] = useUser(initialId)

	useInitialId({
		initialId,
		onChange,
		currentValue: value,
		findInitialOption: () => initialUser,
	})

	const preparedData = useMemo(() => {
		// Weed out entries where name is an empty string.
		let options = [...data.filter((d) => !!d.name)]

		// `value` passed in from the parent may or may not be present in the search results data,
		// especially at first. Inject it here, and then filter it out of the data to prevent duplicate.
		options =
			value ?
				[
					value,
					...options.filter((d) => {
						// Remove `value` since we just added it already.
						return d.id !== value.id
					}),
				]
			:	options

		return options
	}, [data, value])

	return (
		<SearchableSelect
			isLoading={isLoading || isFetching}
			label="User"
			getOptionLabel={(option) => option.name}
			renderOption={(option) => (
				<div>
					<Paragraph>{option.name}</Paragraph>
					<Paragraph small color="secondary" mt={-0.25}>
						{option.email}
					</Paragraph>
				</div>
			)}
			{...rest}
			value={value}
			options={preparedData}
			onInputChange={setSearch}
			onItemSelect={onChange}
		/>
	)
}

import { FC, useMemo, useState } from "react"

import {
	makeApiErrorMessage,
	Report,
	ReportAssignment,
	useAssignUserReports,
	useAuth,
	useIsUser,
	UserId,
	UserProfile,
	useUserProfile,
} from "@ncs/ncs-api"
import {
	Box,
	Button,
	CheckboxGroup,
	ExtendableModalProps,
	Label,
	Modal,
	ReportGroupSelector,
	useChangeCallback,
	useToast,
} from "@ncs/web-legos"

import { useReportAssignmentMatches } from "./user-management-util"

export interface EditReportsModalProps extends ExtendableModalProps {
	user: UserProfile
	reports: Report[]
}

interface SelectionsState {
	[id: string]: {
		report: Report
		checked: boolean
	}
}

export const EditReportsModal: FC<EditReportsModalProps> = ({
	user,
	reports: allReports,
	...rest
}) => {
	const isAdmin = useIsUser(UserId.Admin)
	const { user: authedUser } = useAuth()
	const [authedUserProfile] = useUserProfile(authedUser?.id)
	const { makeSuccessToast } = useToast()
	const [selectedPresetId, setSelectedPresetId] = useState<string | null>(null)
	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const makeSelectionsState = (reportsToSelect?: Report[]): SelectionsState => {
		return Object.fromEntries(
			allReports.map((report) => {
				const checked =
					(reportsToSelect ?? []).findIndex(
						(selectedReport) => selectedReport.id === report.id
					) !== -1

				return [
					report.id,
					{
						report,
						checked,
					},
				]
			})
		)
	}

	const [selections, setSelections] = useState<SelectionsState>(() => makeSelectionsState())

	const assignReports = useAssignUserReports()

	const onSave = async () => {
		try {
			setIsSaving(true)

			const data = {
				userId: user.id.toString(),
			}
			// If we have a matched preset ID, send that. Otherwise, send the selected applications.
			if (matchedPreset) {
				await assignReports({
					...data,
					reportAssignmentId: matchedPreset.id,
					reports: null,
				})
			} else {
				await assignReports({
					...data,
					reportAssignmentId: null,
					reports: selectedReportIds,
				})
			}
			makeSuccessToast("User permissions updated")
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}
	const selectedReportIds = useMemo(() => {
		return Object.entries(selections)
			.filter(([, value]) => value.checked)
			.map(([id]) => id)
	}, [selections])

	// We need to keep the preset group dropdown in sync with what the user checks as best as possible.
	const matchedPresets = useReportAssignmentMatches(selectedReportIds)

	// From the matched presets, do some logic to pick out just one that makes the most sense.
	const matchedPreset: ReportAssignment | null = useMemo(() => {
		// If nothing matched, return null
		if (!matchedPresets.length) return null

		// If the currently dropdown menu selection is amongst the presets, we'll grab that one.
		const dropdownMatch = matchedPresets.find((preset) => preset.id === selectedPresetId)
		if (dropdownMatch) return dropdownMatch

		// If the user's current group is one of the ones matched, return it.
		const currentUserGroupId = user.reportsGroup?.id.toString() ?? null
		if (currentUserGroupId) {
			const match = matchedPresets.find((preset) => preset.id === currentUserGroupId)
			if (match) return match
		}

		// Otherwise, put any defaults in front of non-defaults, and then take the first in the list.
		return matchedPresets.sort((a, b) => (a.isDefault && !b.isDefault ? -1 : 1))[0]
	}, [matchedPresets, user.reportsGroup?.id, selectedPresetId])

	useChangeCallback(matchedPreset, (newMatch) => setSelectedPresetId(newMatch?.id ?? null))

	const permissionsLookup = useMemo(() => {
		return Object.fromEntries(
			(authedUserProfile?.reportsGroup?.reports ?? []).map(({ report }) => [report.id, true])
		)
	}, [authedUserProfile?.reportsGroup?.reports])

	const handleSelectGroup = (assignment: ReportAssignment | null) => {
		setSelectedPresetId(assignment?.id ?? null)
		setSelections(makeSelectionsState(assignment?.reports))
	}

	const handleClear = () => {
		setSelections(makeSelectionsState())
	}

	const onOpen = () => {
		setErrorText(null)
		setIsSaving(false)

		// Set selections to what the user's current reports are, if any.
		// Note that this triggers state change flows all the way down to setting
		// the dropdown menu, if possible.
		setSelections(
			makeSelectionsState(
				user.reportsGroup?.reports.map((assignedReport) => assignedReport.report)
			)
		)
	}

	return (
		<Modal
			{...rest}
			onOpen={onOpen}
			maxWidth="md"
			title="Edit Report Permissions"
			stickyTopContent={
				<Box display="flex" justifyContent="space-between">
					<ReportGroupSelector
						value={selectedPresetId}
						onChange={(id, option) => handleSelectGroup(option ?? null)}
						label="Report group preset"
					/>
					<Button onClick={handleClear}>Clear selections</Button>
				</Box>
			}
			errorText={errorText}
			rightButtons={{
				buttonText: "Save Changes",
				isLoading: isSaving,
				onClick: onSave,
			}}
		>
			<Box my={1}>
				<Label>Report permissions you are allowed to edit for this user:</Label>
			</Box>
			<CheckboxGroup
				rows={Object.values(selections).sort((a, b) =>
					a.report.name > b.report.name ? 1 : -1
				)}
				valueAccessor={(row) => row.report.id}
				labelAccessor={(row) => row.report.name}
				checkedAccessor={(row) => row.checked}
				hiddenAccessor={
					isAdmin ? undefined : (row) => permissionsLookup[row.report.id] !== true
				}
				onChange={(row, newState) => {
					setSelections((prev) => ({
						...prev,
						[row.report.id]: {
							...prev[row.report.id],
							checked: newState,
						},
					}))
				}}
				columnCounts={{ md: 3, sm: 2, xs: 1 }}
				fillContainer
			/>
		</Modal>
	)
}

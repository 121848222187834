import { RSAA } from "redux-api-middleware"
import { get, post, rsaaTypes } from "../../middleware/portalApiMiddleware"
import jwt from "jsonwebtoken"

export const LOGIN = rsaaTypes("auth/LOGIN")
export const TOKEN = rsaaTypes("auth/TOKEN")
export const LOGOUT = "auth/LOGOUT"
export const USER_CHANGED = "auth/USER_CHANGED"

export const REQUEST_PASSWORD_RESET = rsaaTypes("auth/password/reset")
export const VALIDATE_PASSWORD_RESET_TOKEN = rsaaTypes("auth/password/validate-reset-token")
export const RESET_PASSWORD = rsaaTypes("auth/password/reset")

// TODO: require the url and public key to be passed in so this can be generic
export const login = (email, password) => ({
	[RSAA]: {
		api: post("/auth/token/", { email, password }),
		types: LOGIN,
	},
})

export const loginCustomer = (email, password) => ({
	[RSAA]: {
		api: post("/auth/customer-token/", { email, password }),
		types: LOGIN,
	},
})

export const logout = () => ({
	type: LOGOUT,
})

// TODO: require the url and public key to be passed in so this can be generic
export const refreshAccessToken = (token) => ({
	[RSAA]: {
		api: post("/auth/token/refresh/", { refresh: token }),
		types: TOKEN,
	},
})

// TODO: require the url and public key to be passed in so this can be generic
export const requestPasswordReset = (email) => ({
	[RSAA]: {
		api: post("/security/reset_password_token/", { email }),
		types: REQUEST_PASSWORD_RESET,
	},
})

// TODO: require the url and public key to be passed in so this can be generic
export const validatePasswordResetToken = (token) => ({
	[RSAA]: {
		api: get(`/security/reset_password_token/${token}/`),
		types: VALIDATE_PASSWORD_RESET_TOKEN,
	},
})

// TODO: require the url and public key to be passed in so this can be generic
export const resetPassword = (token, newPassword) => ({
	[RSAA]: {
		api: post(`/security/reset_password_token/${token}/`, {
			password: newPassword,
		}),
		types: RESET_PASSWORD,
	},
})

const initialState = {
	access: undefined,
	refresh: undefined,
	errors: {},
	userId: null,
}

// TODO: remove once actions are generic
const PUBLIC_KEY =
	"-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAk+0I7VMPjIGcBmY1hffUypVMGubBGYQ+tVm69PcyNf60OrfVmOf/wchhIQ9sxpEUALfjsgcBIrv+YoiAPua5Nrb+joUxBOAPDFlz6+R1H45mF8VsW+a53r3CXbb/s8YBtP+JRwa7EuRn4qSKqsmmqt18sY/Im6upEJmuZJGUGs1rpt2MKMAPNTHS0mIUX+kdmDugFHyxGyCDFqDSbNis283/CS/A/a18/Zt5o/ThW5ihEk1JPBoFQ3BGEJwzdlMRS1HMrFTb2f385UQVs6RtSmJJDHqI6dOWf3Bu56KrTHAlxLLJLlUPMT/PCkYxkw1hs/FquygSkRoxHvqIJH2LtQIDAQAB\n-----END PUBLIC KEY-----"

const splitTokenProps = (token) => {
	let decodedToken = jwt.verify(token, PUBLIC_KEY, { ignoreExpiration: true })

	let { exp, jti, token_type, ...rest } = decodedToken
	return {
		tokenProps: { token: token, exp, jti, token_type },
		authProps: rest,
	}
}

const auth = (state = initialState, action) => {
	let access, refresh
	switch (action.type) {
		case LOGIN.success:
			access = splitTokenProps(action.payload.access)
			refresh = splitTokenProps(action.payload.refresh)

			// last logged in user, either via a timed out or overwritten session, or via logout
			const previousUserId = state.userId ?? state.previousUserId
			const userId = access.authProps?.user_id
			const userChanged = !!previousUserId && !!userId && previousUserId !== userId

			if (userChanged) {
				action.asyncDispatch({
					type: USER_CHANGED,
					userId,
					previousUserId,
				})
			}

			return {
				...state,
				access: {
					...access.tokenProps,
				},
				refresh: {
					...refresh.tokenProps,
				},
				errors: {},
				...access.authProps,
				previousUserId: null,
				userId: userId,
			}
		case TOKEN.success:
			access = splitTokenProps(action.payload.access)
			return {
				...state,
				access: {
					...access.tokenProps,
				},
				...access.authProps,
			}
		case LOGIN.failure:
		case TOKEN.failure:
			return {
				...state,
				access: undefined,
				refresh: undefined,
				userId: null,
				errors: action.payload.response || { non_field_errors: action.payload.statusText },
			}
		case LOGOUT:
			return {
				previousUserId: state.userId,
				userId: null,
			}

		default:
			return state
	}
}
export default auth

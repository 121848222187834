import { FC, useMemo } from "react"

import { MultiSelect } from "@ncs/web-legos"

export type FreightRateType = "products" | "customers"

export interface FreightRateTypeMultiSelectProps {
	values: FreightRateType[]
	onChange: (newTypes: FreightRateType[]) => void
}

export const FreightRateTypeMultiSelect: FC<FreightRateTypeMultiSelectProps> = ({
	values: valuesProp,
	onChange,
}) => {
	const values = useMemo(() => {
		return freightRateTypeMultiSelectOptions.filter((o) => valuesProp.includes(o.value))
	}, [valuesProp])

	return (
		<MultiSelect
			options={freightRateTypeMultiSelectOptions}
			values={values}
			idAccessor="value"
			textAccessor="text"
			onChange={(selections) => {
				onChange(selections.map((s) => s.value))
			}}
			label="Freight rate type"
			showSearch={false}
			placeholder="Freight rate type..."
			icon="caret-down"
			iconFamily="solid"
		/>
	)
}

export const freightRateTypeMultiSelectOptions: {
	value: FreightRateType
	text: string
}[] = [
	{
		value: "products",
		text: "Products",
	},
	{
		value: "customers",
		text: "Customers",
	},
]

import React from "react"
import PropTypes from "prop-types"
import get from "lodash/get"
import memoizeOne from "memoize-one"
import { formatDateTime } from "@ncs/bricks/util/formatters"
import { listDocuments } from "@ncs/mortar/redux/services/infoRef"
import { generateOrderingWithPeople } from "@ncs/bricks/redux/services/dynamicTables"

// @material-ui/icons
import ExcelIcon from "@ncs/bricks/assets/img/ExcelIcon.svg"
import WordIcon from "@ncs/bricks/assets/img/WordIcon.svg"
import PDFIcon from "@ncs/bricks/assets/img/PDFIcon.svg"
import VerticalSplit from "@material-ui/icons/VerticalSplit"
import ZoomIn from "@material-ui/icons/ZoomIn"
import CloudDownload from "@material-ui/icons/CloudDownload"
import Videocam from "@material-ui/icons/Videocam"
import AttachFile from "@material-ui/icons/AttachFile"
import Add from "@material-ui/icons/Add"
import Edit from "@material-ui/icons/Edit"
import Remove from "@material-ui/icons/Remove"
import Share from "@material-ui/icons/Share"
import Delete from "@material-ui/icons/Delete"
import AssignmentReturn from "@material-ui/icons/AssignmentReturn"

// custom components
import {
	Alert,
	Button,
	ButtonWithIcon,
	ConditionalContent,
	Dialog,
	DynamicTable,
	GridContainer,
	GridItem,
	Input,
	InputAsDisplay,
	LoadingWrapper,
	QuickCard,
	SearchFilter,
	Select,
} from "~/components"

// styles
import withStyles from "@material-ui/core/styles/withStyles"

const styles = {
	right: {
		textAlign: "right",
	},
	center: {
		textAlign: "center",
	},
	previewIcon: {
		width: 75,
		height: 75,
	},
	previewImage: {
		maxWidth: "75px",
		maxHeight: "100px",
		height: "auto",
		width: "auto",
	},
}

const generateCategoryOptions = memoizeOne((cats, parentCategory) => {
	if (!cats || !cats.length) {
		return []
	}

	let sortedCats = [...cats].sort(
		(a, b) => (b.name.toLowerCase() === "ryko") - (a.name.toLowerCase() === "ryko")
	)

	return [
		{ value: parentCategory ? parentCategory.id.toString() : "-1", text: "All Categories" },
		...sortedCats.map((x) => ({
			value: x.id.toString(),
			text: x.name,
		})),
	]
})

const CategoryManager = (
	{
		isAdmin,
		categories,
		onCategoryChange,
		onStartCreatingCategory,
		onStartDeletingCategory,
		onStartEditingCategory,
		selectedCategory,
		selectedCategoryHasDocuments,
	},
	id,
	labelText,
	availableCategoriesForTier,
	selectedCategoryForTier,
	parentCategory,
	isRootCategoryTier = false
) => (
	<ConditionalContent hide={get(categories, "length", 0) === 0}>
		<ConditionalContent show={get(availableCategoriesForTier, "length", 0) > 0}>
			<Select
				id={id}
				labelText={labelText}
				value={get(
					selectedCategoryForTier,
					"id",
					get(parentCategory, "id", "-1")
				).toString()}
				onChange={onCategoryChange}
				options={generateCategoryOptions(availableCategoriesForTier, parentCategory)}
			/>
		</ConditionalContent>

		<ConditionalContent
			show={
				isAdmin &&
				!!selectedCategoryForTier &&
				selectedCategoryForTier.is_editable &&
				selectedCategoryForTier.id === selectedCategory
			}
		>
			{() => (
				<React.Fragment>
					<br />
					<ButtonWithIcon
						icon={<Edit />}
						content="Edit Name"
						color="warning"
						size="sm"
						round
						onClick={onStartEditingCategory(selectedCategoryForTier)}
					/>
					<ConditionalContent
						show={
							!selectedCategoryHasDocuments &&
							get(selectedCategoryForTier, "sub_categories.length", 0) === 0
						}
					>
						<ButtonWithIcon
							icon={<Remove />}
							content="Delete"
							color="danger"
							size="sm"
							round
							onClick={onStartDeletingCategory(selectedCategoryForTier)}
						/>
					</ConditionalContent>
				</React.Fragment>
			)}
		</ConditionalContent>
		<ConditionalContent
			show={isAdmin && (!!parentCategory || isRootCategoryTier) && !selectedCategoryForTier}
		>
			{() => (
				<React.Fragment>
					<br />
					<ButtonWithIcon
						icon={<Add />}
						content={`Create ${isRootCategoryTier ? "" : "Sub-"}Category`}
						color="success"
						size="sm"
						round
						onClick={onStartCreatingCategory}
					/>
				</React.Fragment>
			)}
		</ConditionalContent>
	</ConditionalContent>
)

const generateOrdering = generateOrderingWithPeople(["created_by"])

class Documents extends React.PureComponent {
	generatePreview = (item) => {
		let name = (item.document_type_name || "").toLowerCase()
		if (name === "image") {
			return (
				<img
					src={item.presign_url}
					title={`${item.filename}.${item.extension}`}
					alt={`${item.filename}.${item.extension}`}
					className={this.props.classes.previewImage}
				/>
			)
		}

		if (name === "video") {
			return <Videocam className={this.props.classes.previewIcon} />
		}

		let extension = (item.extension || "").toLowerCase()
		switch (extension) {
			case "xls":
			case "xlsx":
				return <img height={75} src={ExcelIcon} alt="Excel Document" />
			case "docx":
			case "doc":
				return <img height={75} src={WordIcon} alt="Word Document" />
			case "pdf":
				return <img height={75} src={PDFIcon} alt="PDF Document" />
			default:
				return <AttachFile className={this.props.classes.previewIcon} />
		}
	}

	getColumns = (isAdmin) => [
		{
			Header: "Title",
			accessor: "description",
			id: "description",
			sortable: true,
			toggleable: true,
			width: 500,
		},
		{
			Header: "Filename",
			accessor: (x) => `${x.filename}.${x.extension}`,
			id: "filename",
			sortable: true,
			hiddenByDefault: true,
			width: 500,
		},
		{
			Header: "Document #",
			accessor: "document_number",
			sortable: true,
			toggleable: true,
			width: 150,
		},
		{
			Header: "Revision",
			accessor: "revision",
			sortable: true,
			toggleable: true,
			width: 100,
		},
		{
			Header: "Date Created",
			accessor: (x) => formatDateTime(x.created_date),
			id: "created_date",
			hiddenByDefault: true,
			width: 150,
		},
		{
			Header: "Effective Date",
			accessor: (x) => formatDateTime(x.effective_date),
			id: "effective_date",
			hiddenByDefault: true,
			width: 150,
		},
		{
			Header: "Expiration Date",
			accessor: (x) => formatDateTime(x.expiration_date),
			id: "expiration_date",
			hiddenByDefault: true,
			width: 150,
		},
		{
			Header: "Created By",
			accessor: "created_by.name",
			hiddenByDefault: true,
			width: 150,
		},
		{
			Header: "Preview",
			Cell: ({ original: x }) => this.generatePreview(x),
			accessor: "preview",
			sortable: false,
			toggleable: true,
			width: 80,
		},
		{
			Header: "Actions",
			headerStyle: styles.center,
			Cell: ({ original: item }) => (
				<div className={this.props.classes.center}>
					<Button
						justIcon
						round
						className="edit"
						color="info"
						size="sm"
						title="Preview"
						onClick={(e) => {
							e.stopPropagation()
							if ("document" === item.document_type_name.toLowerCase()) {
								this.props.onDownload(item)
							} else {
								this.props.onViewPreview(item)
							}
						}}
					>
						{["video", "image"].includes(item.document_type_name.toLowerCase()) ?
							<ZoomIn />
						:	<CloudDownload />}
					</Button>
					<Button
						justIcon
						round
						color="warning"
						size="sm"
						title="Share"
						onClick={(e) => {
							e.stopPropagation()
							this.props.onGenerateLink(item)
						}}
					>
						<Share />
					</Button>
					<ConditionalContent show={isAdmin}>
						<Button
							justIcon
							round
							color="danger"
							size="sm"
							title="Delete"
							onClick={(e) => {
								e.stopPropagation()
								this.props.onDelete(item)
							}}
						>
							<Remove />
						</Button>
					</ConditionalContent>
				</div>
			),
			id: "actions",
			sortable: false,
			width: 150,
		},
	]

	render = () => {
		const {
			classes,
			reduxKey,
			isAdmin,
			isSaving,
			onViewPreview,
			onCreateDocument,
			categories,
			isConfirmingDelete,
			deletingItem,
			onCancelDelete,
			onConfirmDelete,
			selectedTopLevelCategory,
			selectedSecondLevelCategory,
			selectedThirdLevelCategory,
			selectedFourthLevelCategory,
			categoryName,
			onCategoryNameChange,
			isCreatingCategory,
			onCancelCreatingCategory,
			onCreateCategory,
			isEditingCategory,
			onCancelEditingCategory,
			onSaveCategory,
			isDeletingCategory,
			onCancelDeletingCategory,
			onDeleteCategory,
			onCloseGeneratedLink,
			isGeneratingLink,
			generatedLink,
		} = this.props

		return (
			<React.Fragment>
				<QuickCard title="Info Ref Documents" icon={<VerticalSplit />}>
					<GridContainer>
						<GridItem xs={12} sm={6} md={3}>
							{CategoryManager(
								this.props,
								"top_category",
								"Top-level Category",
								categories,
								selectedTopLevelCategory,
								null,
								true
							)}
						</GridItem>
						<GridItem xs={12} sm={6} md={3}>
							{CategoryManager(
								this.props,
								"second_category",
								"Second-level Category",
								get(selectedTopLevelCategory, "sub_categories"),
								selectedSecondLevelCategory,
								selectedTopLevelCategory
							)}
						</GridItem>
						<GridItem xs={12} sm={6} md={3}>
							{CategoryManager(
								this.props,
								"third_category",
								"Third-level Category",
								get(selectedSecondLevelCategory, "sub_categories"),
								selectedThirdLevelCategory,
								selectedSecondLevelCategory
							)}
						</GridItem>
						<GridItem xs={12} sm={6} md={3}>
							{CategoryManager(
								this.props,
								"fourth_category",
								"Fourth-level Category",
								get(selectedThirdLevelCategory, "sub_categories"),
								selectedFourthLevelCategory,
								selectedThirdLevelCategory
							)}
						</GridItem>

						<GridItem xs={12}>
							<SearchFilter reduxKey={reduxKey} />
						</GridItem>
						<GridItem xs={12} className={classes.right} show={isAdmin}>
							<ButtonWithIcon
								content="Create Document"
								onClick={onCreateDocument}
								icon={<Add />}
								size="sm"
								color="success"
								round
							/>
						</GridItem>
					</GridContainer>

					<DynamicTable
						reduxKey={reduxKey}
						fetchDataAction={listDocuments}
						noDataText="No documents match your criteria."
						hideWhenEmpty={true}
						showResetFiltersButton={true}
						onRowClick={onViewPreview}
						generateOrderingFn={generateOrdering}
						columns={this.getColumns(isAdmin)}
					/>
				</QuickCard>

				<Dialog
					warning="true"
					show={isConfirmingDelete}
					title={"Are you sure you want to permanently remove this item?"}
					confirmBtnIcon={<Delete />}
					confirmBtnText="Confirm Deletion"
					confirmBtnColor="danger"
					onConfirm={onConfirmDelete}
					isConfirmationAsync={true}
					cancelBtnText={"Cancel"}
					onCancel={onCancelDelete}
					successTitle="Success"
					successContent="Document was removed."
					successConformColor="success"
					onSuccessConfirm={onCancelDelete}
					isSaving={isSaving}
				>
					{isConfirmingDelete && (
						<GridContainer className={classes.leftAlignedModal}>
							<GridItem xs={12} sm={4}>
								{this.generatePreview(deletingItem)}
							</GridItem>
							<GridItem xs={12} sm={8}>
								<InputAsDisplay
									labelText="Filename"
									value={`${deletingItem.filename}.${deletingItem.extension}`}
								/>
							</GridItem>
						</GridContainer>
					)}
				</Dialog>

				<Dialog
					show={isDeletingCategory}
					title="Are you sure you want to delete this category?"
					confirmBtnIcon={<Remove />}
					confirmBtnText="Delete"
					confirmBtnColor="danger"
					onConfirm={onDeleteCategory}
					isConfirmationAsync={true}
					cancelBtnText={"Cancel"}
					onCancel={onCancelDeletingCategory}
					successTitle="Success"
					successContent="Category was successfully deleted."
					onSuccessConfirm={onCancelDeletingCategory}
					isSaving={isSaving}
				>
					{isDeletingCategory && (
						<GridContainer className={classes.leftAlignedModal}>
							<GridItem xs={12}>
								<InputAsDisplay labelText="Category" value={categoryName} />
							</GridItem>
						</GridContainer>
					)}
				</Dialog>

				<Dialog
					show={isEditingCategory}
					title="Edit Category"
					confirmBtnIcon={<Edit />}
					confirmBtnText="Save"
					confirmBtnColor="success"
					onConfirm={onSaveCategory}
					isConfirmationAsync={true}
					confirmBtnDisabled={!categoryName}
					cancelBtnText={"Cancel"}
					onCancel={onCancelEditingCategory}
					successTitle="Success"
					successContent="Category was successfully saved."
					onSuccessConfirm={onCancelEditingCategory}
					isSaving={isSaving}
				>
					{isEditingCategory && (
						<GridContainer className={classes.leftAlignedModal}>
							<GridItem xs={12}>
								<Input
									labelText="Name"
									onChange={onCategoryNameChange}
									value={categoryName}
									inputProps={{ autoFocus: true }}
								/>
							</GridItem>
						</GridContainer>
					)}
				</Dialog>

				<Dialog
					show={isCreatingCategory}
					title="Create Category"
					confirmBtnIcon={<Add />}
					confirmBtnText="Create"
					confirmBtnColor="success"
					onConfirm={onCreateCategory}
					isConfirmationAsync={true}
					confirmBtnDisabled={!categoryName}
					cancelBtnText={"Cancel"}
					onCancel={onCancelCreatingCategory}
					successTitle="Success"
					successContent="Category was successfully created."
					onSuccessConfirm={onCancelCreatingCategory}
					isSaving={isSaving}
				>
					{isCreatingCategory && (
						<GridContainer className={classes.leftAlignedModal}>
							<GridItem xs={12}>
								<Input
									labelText="Name"
									onChange={onCategoryNameChange}
									value={categoryName}
									inputProps={{ autoFocus: true }}
								/>
							</GridItem>
						</GridContainer>
					)}
				</Dialog>

				<LoadingWrapper isLoading={isGeneratingLink} title="Creating Shareable Link" />

				<Alert
					title="Shareable Link"
					confirmBtnText="Close"
					onConfirm={onCloseGeneratedLink}
					show={typeof generatedLink === "string"}
					success
				>
					<Input
						formControlProps={{ fullWidth: true }}
						labelText="Link"
						inputProps={{
							id: "generatedLink",
							autoFocus: true,
							multiline: true,
							value: generatedLink,
						}}
					/>
					<ButtonWithIcon
						id="clipboardButton"
						data-clipboard-target="#generatedLink"
						icon={<AssignmentReturn />}
						content="Copy to Clipboard"
						color="info"
						onClick={onCloseGeneratedLink}
					/>
				</Alert>
			</React.Fragment>
		)
	}
}

Documents.propTypes = {
	classes: PropTypes.object.isRequired,
	reduxKey: PropTypes.string.isRequired,
	isAdmin: PropTypes.bool.isRequired,
	onViewPreview: PropTypes.func.isRequired,
	onDownload: PropTypes.func.isRequired,
	onCreateDocument: PropTypes.func.isRequired,

	isSaving: PropTypes.bool.isRequired,
	isConfirmingDelete: PropTypes.bool.isRequired,
	deletingItem: PropTypes.object,
	onDelete: PropTypes.func.isRequired,
	onCancelDelete: PropTypes.func.isRequired,
	onConfirmDelete: PropTypes.func.isRequired,

	categories: PropTypes.array.isRequired,

	onCategoryChange: PropTypes.func.isRequired,
	selectedCategory: PropTypes.number,
	selectedTopLevelCategory: PropTypes.object,
	selectedSecondLevelCategory: PropTypes.object,
	selectedThirdLevelCategory: PropTypes.object,
	selectedFourthLevelCategory: PropTypes.object,

	isCreatingCategory: PropTypes.bool.isRequired,
	onStartCreatingCategory: PropTypes.func.isRequired,
	onCancelCreatingCategory: PropTypes.func.isRequired,
	onCreateCategory: PropTypes.func.isRequired,

	isEditingCategory: PropTypes.bool.isRequired,
	onStartEditingCategory: PropTypes.func.isRequired,
	onCancelEditingCategory: PropTypes.func.isRequired,
	onSaveCategory: PropTypes.func.isRequired,

	selectedCategoryHasDocuments: PropTypes.bool.isRequired,
	isDeletingCategory: PropTypes.bool.isRequired,
	onStartDeletingCategory: PropTypes.func.isRequired,
	onCancelDeletingCategory: PropTypes.func.isRequired,
	onDeleteCategory: PropTypes.func.isRequired,

	categoryName: PropTypes.string,
	onCategoryNameChange: PropTypes.func.isRequired,

	onGenerateLink: PropTypes.func.isRequired,
	onCloseGeneratedLink: PropTypes.func.isRequired,
	isGeneratingLink: PropTypes.bool.isRequired,
	generatedLink: PropTypes.string,
}

export default withStyles(styles)(Documents)

import React from "react"
import PropTypes from "prop-types"
import Pagination from "./Pagination"

const PaginationContainer = ({ ...props }) => {
	const {
		currentPage,
		totalPages,
		paginationLinkCount,
		onClick,
		alwaysShowFirstPage,
		alwaysShowLastPage,
		...rest
	} = props

	let pagesOnRight = Math.ceil((paginationLinkCount - 1) / 2.0)
	let pagesOnLeft = paginationLinkCount - pagesOnRight - 1
	let minPage = Math.max(1, currentPage - pagesOnLeft)
	let maxPage = Math.min(totalPages, currentPage + pagesOnRight)

	// show more pages to the right if near the first page
	if (currentPage <= pagesOnLeft) {
		maxPage = Math.min(paginationLinkCount, totalPages)
	}
	// show more pages to the left if near the last page
	else if (currentPage > totalPages - pagesOnRight) {
		minPage = Math.max(totalPages - paginationLinkCount + 1, 1)
	}

	let pages = [...Array(maxPage - minPage + 1).keys()].map((i) => ({
		text: (i + minPage).toString(),
		active: currentPage === i + minPage,
		onClick: () => onClick(i + minPage),
	}))

	if (alwaysShowFirstPage && minPage > 1) {
		pages.unshift({ text: "<<", active: false, onClick: () => onClick(1) })
	}

	if (alwaysShowLastPage && maxPage < totalPages) {
		pages.push({ text: ">>", active: false, onClick: () => onClick(totalPages) })
	}

	return <Pagination pages={pages} {...rest} />
}

PaginationContainer.defaultProps = {
	color: "primary",
	currentPage: 1,
	totalPages: 1,
	paginationLinkCount: 7,
	alwaysShowFirstPage: true,
	alwaysShowLastPage: true,
}

PaginationContainer.propTypes = {
	currentPage: PropTypes.number.isRequired,
	totalPages: PropTypes.number.isRequired,
	paginationLinkCount: PropTypes.number,
	onClick: PropTypes.func.isRequired,
	alwaysShowFirstPage: PropTypes.bool,
	alwaysShowLastPage: PropTypes.bool,
	color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
}

export default PaginationContainer

import React from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import callApi from "@ncs/bricks/redux/services/callApi"
import { BaseSelectFilter, withDynamicTableFilter } from "@ncs/bricks/components"
import { loadLookups, LOOKUPS } from "../../redux/services/lookups"

class WarehouseFilter extends React.PureComponent {
	componentDidMount() {
		this.props.loadLookups([LOOKUPS.Warehouses])
	}

	render() {
		return (
			<BaseSelectFilter
				id="warehouses"
				labelText="Warehouse"
				options={[{ _id: "", description: "All Warehouses" }, ...this.props.warehouses]}
				valueAccessor="_id"
				textAccessor={(x) =>
					x._id ? `${x.description} (${x.location_code})` : x.description
				}
				{...this.props}
			/>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		warehouses: state.lookups.warehouses,
	}
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ callApi, loadLookups }, dispatch)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withDynamicTableFilter("warehouse")(WarehouseFilter))

import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import get from "lodash/get"
import CustomerSiteSelector from "../../components/Selectors/CustomerSiteSelector"
import { useDynamicTableFilter } from "@ncs/bricks/util/hooks"

const CustomerSiteFilter = ({
	onCustomerSiteSelected,
	reduxKey,
	filterName = "site",
	...rest
}) => {
	const [value, onChange] = useDynamicTableFilter(filterName, reduxKey)
	const selectedCustomer = useSelector((x) => x.customers?.selectedCustomer)

	useEffect(() => {
		if (selectedCustomer) {
			onChange(selectedCustomer)
		}
	}, [])

	const handleCustomerSiteSelected = (site) => {
		let text = get(site, "name", "")
		onChange(site, text)
		typeof onCustomerSiteSelected === "function" && onCustomerSiteSelected(site)
	}

	return (
		<CustomerSiteSelector
			onCustomerSiteSelected={handleCustomerSiteSelected}
			value={value}
			{...rest}
		/>
	)
}

export default CustomerSiteFilter

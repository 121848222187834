import { FC, Fragment } from "react"

import { CheckOrderItemsResult } from "@ncs/ncs-api"
import { formatCurrency } from "@ncs/ts-utils"
import {
	Box,
	CssGridTable,
	ExtendableModalProps,
	Icon,
	Label,
	Modal,
	Paragraph,
} from "@ncs/web-legos"

export interface OrderSubtotalDetailsModalProps extends ExtendableModalProps {
	checkOrderItemsResult: CheckOrderItemsResult
}

export const OrderSubtotalDetailsModal: FC<OrderSubtotalDetailsModalProps> = ({
	checkOrderItemsResult,
	...rest
}) => {
	if (!checkOrderItemsResult.freightTotals) {
		return null
	}

	const { chemicalMin, chemicalParts } = checkOrderItemsResult.freightTotals

	const chemTotal = chemicalParts?.reduce((acc, part) => acc + part.lineTotal, 0) ?? 0
	const hitChemMin = chemTotal >= (chemicalMin ?? 0)

	return (
		<Modal {...rest} title="Order Subtotal Details">
			{checkOrderItemsResult.freightTotals.exceedsMinOrders ?
				<Paragraph mb={1}>
					This customer has already used all their free freight orders for the month.
				</Paragraph>
			:	<>
					<Label>Customer's required minimum for free shipping</Label>
					<Paragraph mb={1}>
						<strong>{formatCurrency(chemicalMin ?? 0)}</strong>
					</Paragraph>
					<Label>Qualified total so far</Label>
					<Box d="flex" gap={0.5} alignItems="center">
						<Paragraph bold>{formatCurrency(chemTotal)}</Paragraph>
						{hitChemMin ?
							<Icon icon="check-circle" family="solid" color="success" />
						:	<span> ({formatCurrency(chemTotal - (chemicalMin ?? 0), 0)} short)</span>}
					</Box>

					<CssGridTable
						mt={3}
						rowGap={0.5}
						columnGap={1}
						gridTemplateColumns="auto 1fr"
						headers={["Qualified Item (CHM, DTL, 214)", "Line Total"]}
						cells={(chemicalParts ?? []).map((part) => (
							<Fragment key={part.id}>
								<Paragraph small>
									({part.partNumber}) {part.description}
								</Paragraph>
								<Paragraph small>{formatCurrency(part.lineTotal)}</Paragraph>
							</Fragment>
						))}
					/>
				</>
			}
		</Modal>
	)
}

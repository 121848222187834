import { FC, useMemo } from "react"

import { CustomerClass, useCustomerSubclasses } from "@ncs/ncs-api"
import { ExtendableSelectProps, Select } from "@ncs/web-legos"

export interface CustomerSubclassSelectorProps extends ExtendableSelectProps<CustomerClass> {
	customerClassId?: string | null
}

export const CustomerSubclassSelector: FC<CustomerSubclassSelectorProps> = ({
	customerClassId,
	...rest
}) => {
	const [classes, classesLoading] = useCustomerSubclasses()

	const options = useMemo(() => {
		if (!customerClassId) return classes ?? []

		return (classes ?? []).filter((c) => c.customerClass.id === customerClassId)
	}, [customerClassId, classes])

	return (
		<Select
			label="Customer Subclass"
			disabled={classesLoading}
			{...rest}
			options={options}
			valueAccessor="id"
			textAccessor="description"
		/>
	)
}

import { FC } from "react"

import { Lookup, useCreditMemoReasons } from "@ncs/ncs-api"
import { ExtendableSelectProps, Select } from "@ncs/web-legos"

export type CreditMemoReasonSelectorProps = ExtendableSelectProps<Lookup>

export const CreditMemoReasonSelector: FC<CreditMemoReasonSelectorProps> = ({ ...rest }) => {
	const [reasons, reasonsLoading] = useCreditMemoReasons()

	return (
		<Select
			label="Credit memo reason"
			fillContainer
			{...rest}
			options={reasons ?? []}
			isLoading={reasonsLoading}
			valueAccessor="id"
			textAccessor="description"
		/>
	)
}

import { FC, useMemo, useState } from "react"

import { nanoid } from "nanoid"

import { extractNumber, ObjectEntry } from "@ncs/ts-utils"
import {
	ExtendableModalProps,
	Modal,
	NumericInput,
	PackageSizeSelector,
	TextInput,
} from "@ncs/web-legos"

import { LinePackagesState, PackagesState, useShipShipmentContext } from "~/util/ship-shipment"

export interface ShipmentPackageModalProps extends ExtendableModalProps {
	editPackageTempId: string | null
	autoFocus?: "name" | "weight"
}

export const ShipmentPackageModal: FC<ShipmentPackageModalProps> = ({
	editPackageTempId,
	autoFocus,
	...rest
}) => {
	const { packages, setPackages, setLinePackages } = useShipShipmentContext()

	const packageToEdit = useMemo(() => {
		return Object.values(packages).find((pkg) => pkg.tempId === editPackageTempId)
	}, [packages, editPackageTempId])

	const [newPkgName, setNewPkgName] = useState<string | null>(
		packageToEdit?.name || makeNewPackageName(packages)
	)
	const [newPkgType, setNewPkgType] = useState(packageToEdit?.typeId ?? null)
	const [pkgWeight, setPkgWeight] = useState(() => extractNumber(packageToEdit?.weight) || null)

	const handleSave = () => {
		if (newPkgName && newPkgType) {
			const tempId = packageToEdit?.tempId ?? nanoid()

			setPackages((prev) => {
				const newState: PackagesState = {
					...prev,
					[tempId]: {
						tempId,
						packageId: packageToEdit?.packageId ?? null,
						name: newPkgName,
						weight: pkgWeight,
						typeId: newPkgType,
					},
				}

				return newState
			})

			rest.onClose()
		}
	}

	const handleRemove = () => {
		if (packageToEdit) {
			// Set all line items assigned to this package to null.
			setLinePackages((prev) => {
				return Object.fromEntries(
					Object.entries(prev).map(([id, line]): ObjectEntry<LinePackagesState> => {
						return line.packageTempId === packageToEdit.tempId ?
								[
									id,
									{
										...line,
										packageTempId: null,
									},
								]
							:	[id, line]
					})
				)
			})

			// Remove the package.
			setPackages((prev) =>
				Object.fromEntries(
					Object.entries(prev).filter(([, line]) => {
						return line.tempId !== packageToEdit.tempId
					})
				)
			)
		}
		rest.onClose()
	}

	return (
		<Modal
			{...rest}
			title={packageToEdit ? "Edit Package" : "Add Package"}
			leftButtons={
				packageToEdit ?
					{
						buttonText: "Remove package",
						variant: "text",
						icon: "trash-alt",
						onClick: handleRemove,
					}
				:	undefined
			}
			rightButtons={{
				buttonText: packageToEdit ? "Update" : "Add Package",
				disabled: !newPkgName || !newPkgType,
				onClick: handleSave,
			}}
		>
			<TextInput
				value={newPkgName}
				onChange={setNewPkgName}
				label="Name"
				autoFocus={autoFocus === "name"}
			/>
			<PackageSizeSelector value={newPkgType} onChange={setNewPkgType} fillContainer />
			{!!packageToEdit && (
				<NumericInput
					value={pkgWeight}
					onChange={(newValue) => setPkgWeight(newValue ?? null)}
					label="Weight (lbs)"
					maxWidth={8}
					decimalScale={3}
					autoFocus={autoFocus === "weight"}
				/>
			)}
		</Modal>
	)
}

const makeNewPackageName = (packages: PackagesState): string => {
	const prefix = "Package"

	const checkIfTaken = () => {
		return Object.values(packages).some((pkg) => pkg.name === nextName)
	}

	let next = Object.keys(packages).length + 1
	let nextName = `${prefix} ${next}`
	let taken = checkIfTaken()

	while (taken) {
		next += 1
		nextName = `${prefix} ${next}`
		taken = checkIfTaken()
	}

	return nextName
}

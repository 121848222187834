import { ReactElement, useEffect, useState } from "react"

import {
	Control,
	Controller,
	FieldError,
	FieldValues,
	Path,
	PathValue,
	UnpackNestedValue,
} from "react-hook-form"

import { Vendor } from "@ncs/ncs-api"

import { usePrevious } from "../../util"
import { VendorSelector, VendorSelectorProps } from "../selectors"

export interface VendorSelectorFormFieldProps<TFieldValues extends FieldValues>
	extends Omit<VendorSelectorProps, "value" | "onChange"> {
	control: Control<TFieldValues>
	name: Path<TFieldValues>
	emptyValueFallback?: string | null
}

export const VendorSelectorFormField = <TFieldValues extends FieldValues>({
	name,
	control,
	...rest
}: VendorSelectorFormFieldProps<TFieldValues>): ReactElement => {
	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { onBlur, value, onChange }, fieldState: { error } }) => {
				return (
					<VendorSelectorFormFieldRender
						{...rest}
						formValue={value}
						formOnChange={onChange}
						formOnBlur={onBlur}
						formError={error}
						emptyValueFallback=""
					/>
				)
			}}
		/>
	)
}

interface VendorSelectorFormFieldRenderProps<TFieldValues extends FieldValues>
	extends Omit<VendorSelectorProps, "value" | "onChange"> {
	formValue: UnpackNestedValue<PathValue<TFieldValues, Path<TFieldValues>>>
	formOnChange: (newValue: string | null) => void
	formOnBlur?: () => void
	formError?: FieldError
	emptyValueFallback?: string | null
}

const VendorSelectorFormFieldRender = <TFieldValues extends FieldValues>({
	formValue,
	formOnChange,
	formOnBlur,
	formError,
	emptyValueFallback = null,
	...rest
}: VendorSelectorFormFieldRenderProps<TFieldValues>): ReactElement => {
	const [selectedVendor, setSelectedVendor] = useState<Vendor | null>(null)

	// Listen for the reset coming from above.
	// Should reset on anything falsy (including an empty string).
	const prevFormValue = usePrevious(formValue)
	useEffect(() => {
		if (!formValue && !!prevFormValue && !!selectedVendor) {
			setSelectedVendor(null)
		}
	}, [formValue, prevFormValue, selectedVendor])

	return (
		<VendorSelector
			{...rest}
			onBlur={formOnBlur}
			value={selectedVendor}
			onChange={(newValue) => {
				// Change in the form
				formOnChange(newValue?.id ? String(newValue?.id) : emptyValueFallback)
				// Change in local selections tate.
				setSelectedVendor(newValue)
			}}
			error={formError?.message}
		/>
	)
}

import { FC, useEffect, useState } from "react"

import dayjs, { Dayjs } from "dayjs"

import {
	makeApiErrorMessage,
	OrderDeliveryCommentPost,
	useAddOrderDeliveryComment,
} from "@ncs/ncs-api"
import { DateInput, ExtendableModalProps, Modal, Textarea, useToast } from "@ncs/web-legos"

export interface PurchaseOrderDeliveryNoteModalProps extends ExtendableModalProps {
	orderId: string
}

export const PurchaseOrderDeliveryNoteModal: FC<PurchaseOrderDeliveryNoteModalProps> = ({
	orderId,
	onClose,
	...rest
}) => {
	const { makeSuccessToast } = useToast()
	const addComment = useAddOrderDeliveryComment()

	const [date, setDate] = useState<Dayjs | null>(dayjs())
	const [comment, setComment] = useState<string | null>(null)
	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const handleSave = async () => {
		if (!date) {
			setErrorText("An estimated delivery date is required")
		} else if (!comment) {
			setErrorText("A comment is required")
		} else {
			const post: OrderDeliveryCommentPost = {
				orderId,
				comment,
				deliveryDate: date.toISOString(),
			}

			try {
				setIsSaving(true)
				await addComment(post)
				makeSuccessToast("Delivery note added")
				onClose()
			} catch (e) {
				setErrorText(makeApiErrorMessage(e))
				setIsSaving(false)
			}
		}
	}

	const onOpen = () => {
		setComment(null)
		setDate(null)
		setIsSaving(false)
		setErrorText(null)
	}

	useEffect(() => {
		// Clear out the error when these changes.
		setErrorText(null)
	}, [comment, date])

	return (
		<Modal
			{...rest}
			onOpen={onOpen}
			onClose={onClose}
			title="Add Delivery Note"
			rightButtons={{
				buttonText: "Save",
				onClick: handleSave,
				isLoading: isSaving,
			}}
			errorText={errorText}
		>
			<DateInput
				value={date}
				onChange={setDate}
				label="New estimated delivery date"
				disablePast
			/>
			<Textarea value={comment} onChange={setComment} label="Comment" />
		</Modal>
	)
}

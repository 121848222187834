import React from "react"
import TicketingDashboard from "./TicketingDashboard"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { bindActionCreators, compose } from "redux"
import {
	getTicketingDashboard,
	handleGroupChange,
} from "@ncs/mortar/redux/services/ticketingDashboard"
import { LoadingWrapper, withDynamicTableFilter } from "~/components"

const reduxKey = "ticketingDashboardReduxKey"

class TicketingDashboardContainer extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			dataRefreshTimer: null,
		}
	}

	componentDidMount = () => {
		this.refreshData()

		// refresh data on a five minute interval
		let timer = setInterval(this.refreshData, 1000 * 60 * 5)
		this.setState({ timer })
	}

	componentWillUnmount() {
		clearInterval(this.state.timer)
	}

	componentDidUpdate = (prevProps) => {
		if (prevProps.selectedGroup !== this.props.selectedGroup) {
			this.props.handleGroupChange(this.props.selectedGroup)
		}
	}

	refreshData = () => {
		this.props.getData()
	}

	navigate = (url) => {
		this.props.history.push(url)
	}
	humanizeGroupFilter = () => {
		switch (this.props.selectedGroup) {
			case 1:
				return "finance"
			case 2:
				return "it"
			case 3:
				return "tech-support"
			default:
				return "all"
		}
	}

	handleViewOpenTickets = () => {
		this.navigate(
			`/ticketing/management/?quick-filter=open&department=${this.humanizeGroupFilter()}`
		)
	}

	handleViewNewToday = () => {
		this.navigate(
			`/ticketing/management/?quick-filter=new-today&department=${this.humanizeGroupFilter()}`
		)
	}

	handleViewClosedToday = () => {
		this.navigate(
			`/ticketing/management/?quick-filter=closed-today&department=${this.humanizeGroupFilter()}`
		)
	}

	handleViewPastDue = () => {
		this.navigate(
			`/ticketing/management/?quick-filter=past-due&department=${this.humanizeGroupFilter()}`
		)
	}

	handleViewAssignedToMe = () => {
		this.navigate(
			`/ticketing/management/?quick-filter=assigned-to-me&department=${this.humanizeGroupFilter()}`
		)
	}

	handleViewMyOpenTickets = () => {
		this.navigate(
			`/ticketing/management/?quick-filter=my-open-tickets&department=${this.humanizeGroupFilter()}`
		)
	}

	render() {
		return (
			<LoadingWrapper isLoading={this.props.isInitialState} title="Doing the math...">
				<TicketingDashboard
					isLoading={this.props.isLoading}
					lastRefreshed={this.props.lastRefreshed}
					ticketsOpen={this.props.tickets_open}
					ticketsOpenUpdated={this.props.tickets_open_updated}
					ticketsNewToday={this.props.tickets_new_today}
					ticketsNewTodayUpdated={this.props.tickets_new_today_updated}
					ticketsClosedToday={this.props.tickets_closed_today}
					ticketsClosedTodayUpdated={this.props.tickets_closed_today_updated}
					ticketsPastDue={this.props.tickets_past_due}
					ticketsPastDueUpdated={this.props.tickets_past_due_updated}
					ticketsAssignedToMe={this.props.tickets_assigned_to_me}
					ticketsAssignedToMeUpdated={this.props.tickets_assigned_to_me_updated}
					myTickets={this.props.my_tickets}
					myTicketsUpdated={this.props.my_tickets_updated}
					onViewMyOpenTickets={this.handleViewMyOpenTickets}
					onViewAssignedToMe={this.handleViewAssignedToMe}
					onViewOpenTickets={this.handleViewOpenTickets}
					onViewNewToday={this.handleViewNewToday}
					onViewPastDue={this.handleViewPastDue}
					onViewClosedToday={this.handleViewClosedToday}
				/>
			</LoadingWrapper>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		...state.tickets.dashboard,
		territories: state.lookups.territories,
	}
}

const mapDispatchToProps = (dispatch) => ({
	getData: () => {
		dispatch(getTicketingDashboard())
	},
	...bindActionCreators({ handleGroupChange }, dispatch),
})

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withRouter,
	withDynamicTableFilter("group", undefined, reduxKey, "setGroup", "selectedGroup")
)(TicketingDashboardContainer)

import React from "react"

//custom components
import { GridContainer, GridItem, ButtonWithIcon, UserSelector } from "~/components"

// icons
import RemoveIcon from "@material-ui/icons/Remove"
import AddIcon from "@material-ui/icons/Add"

// styles
import { withStyles } from "@material-ui/core/styles"
const styles = {
	right: {
		textAlign: "right",
	},
}

function TicketUserAssignmentAdd(props) {
	const {
		isAdding,
		onSaveAssignment,
		onCancelAssignmentAdd,
		onAssignmentAdd,
		onUserSelected,
		user,
	} = props

	return (
		<GridContainer>
			<GridItem hide={isAdding}>
				<ButtonWithIcon
					icon={<AddIcon />}
					content="Add User"
					size="sm"
					round
					color="success"
					onClick={onAssignmentAdd}
				/>
			</GridItem>

			<GridItem xs={3} show={isAdding}>
				<ButtonWithIcon
					icon={<RemoveIcon />}
					content="Cancel"
					size="sm"
					round
					color="error"
					onClick={onCancelAssignmentAdd}
				/>
			</GridItem>
			<GridItem xs={6} show={isAdding}>
				<UserSelector onUserSelected={onUserSelected} value={user} />
			</GridItem>
			<GridItem xs={3} show={isAdding}>
				<ButtonWithIcon
					icon={<AddIcon />}
					content="Confirm"
					size="sm"
					round
					color="success"
					disabled={!user}
					onClick={onSaveAssignment}
				/>
			</GridItem>
		</GridContainer>
	)
}

export default withStyles(styles)(TicketUserAssignmentAdd)

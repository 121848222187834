import { FC, useState } from "react"

import {
	APPLICATION,
	useAuth,
	useIsUser,
	useReports,
	UserId,
	UserProfile,
	useSecurityApplications,
	useUserCanUse,
} from "@ncs/ncs-api"
import {
	Box,
	Button,
	CssColumns,
	EmptyValueDash,
	HeadingDivider,
	Label,
	LabeledData,
	Paragraph,
} from "@ncs/web-legos"

import { EditApplicationsModal } from "./EditApplicationsModal"
import { EditReportsModal } from "./EditReportsModal"

export interface UserProfilePermissionsTabProps {
	user: UserProfile
}

export const UserProfilePermissionsTab: FC<UserProfilePermissionsTabProps> = ({ user }) => {
	const isUserManagementAdmin = useUserCanUse(APPLICATION.UserManagementAdmin)
	const isAdminAccount = useIsUser(UserId.Admin)
	const auth = useAuth()
	const [showPermissionsModal, setShowPermissionsModal] = useState(false)
	const [showReportsModal, setShowReportsModal] = useState(false)

	const [fullApplicationsList] = useSecurityApplications()
	const [fullReportsList] = useReports()

	const userHasNonDefaultApplications =
		!!user.applicationsGroup && user.applicationsGroup.isDefault === false

	const userHasNonDefaultReports = !!user.reportsGroup && user.reportsGroup.isDefault === false

	const canEditPermissions =
		isAdminAccount || (auth.user?.id !== user.id.toString() && isUserManagementAdmin)

	return (
		<>
			<HeadingDivider
				headingVariant="h4"
				bold
				mb={1}
				renderRight={() =>
					canEditPermissions ?
						<Button icon="pencil" onClick={() => setShowPermissionsModal(true)}>
							Edit applications
						</Button>
					:	undefined
				}
			>
				Application Permissions
			</HeadingDivider>
			<Box pl={1.5}>
				<div>
					<LabeledData label="Application permissions group">
						{userHasNonDefaultApplications ?
							`(Custom group - ${user.applicationsGroup?.description})`
						:	user.applicationsGroup?.description || <EmptyValueDash />}
					</LabeledData>
				</div>

				<div>
					<Label>Permitted applications</Label>
				</div>

				{user.applicationsGroup ?
					<CssColumns
						columnCounts={{ xs: 1, sm: 2, md: 3, lg: 4 }}
						cells={user.applicationsGroup.applications
							.sort((a, b) =>
								a.application.description > b.application.description ? 1 : -1
							)
							.map((a) => (
								<Paragraph key={a.id} mb={0.25}>
									{a.application.description}
								</Paragraph>
							))}
					/>
				:	<div>
						<EmptyValueDash />
					</div>
				}
			</Box>

			<HeadingDivider
				headingVariant="h4"
				bold
				mb={1}
				mt={4}
				renderRight={() =>
					canEditPermissions ?
						<Button icon="pencil" onClick={() => setShowReportsModal(true)}>
							Edit reports
						</Button>
					:	undefined
				}
			>
				Reporting Permissions
			</HeadingDivider>
			<Box pl={1.5}>
				<LabeledData label="Report permissions group">
					{userHasNonDefaultReports ?
						`(Custom group - ${user.reportsGroup?.description})`
					:	user.reportsGroup?.description || <EmptyValueDash />}
				</LabeledData>

				<div>
					<Label>Permitted reports</Label>
				</div>
				{user.reportsGroup ?
					<CssColumns
						columnCounts={{ xs: 1, sm: 2, md: 3, lg: 4 }}
						cells={user.reportsGroup.reports
							.sort((a, b) => (a.report.name > b.report.name ? 1 : -1))
							.map((a) => (
								<Paragraph key={a.id} mb={0.25}>
									{a.report.name}
								</Paragraph>
							))}
					/>
				:	<div>
						<EmptyValueDash />
					</div>
				}
			</Box>

			{!!fullApplicationsList && (
				<EditApplicationsModal
					isOpen={showPermissionsModal}
					onClose={() => setShowPermissionsModal(false)}
					user={user}
					applications={fullApplicationsList}
				/>
			)}
			{!!fullReportsList && (
				<EditReportsModal
					isOpen={showReportsModal}
					onClose={() => setShowReportsModal(false)}
					user={user}
					reports={fullReportsList}
				/>
			)}
		</>
	)
}

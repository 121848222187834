import { FC, useMemo, useState } from "react"

import { Column } from "react-table"

import {
	APPLICATION,
	CustomerMinimal,
	CustomerRestrictedPart,
	InventoryPart,
	makeApiErrorMessage,
	useAddRestrictedPartToCustomers,
	useDeleteRestrictedParts,
	useRestrictedPartCustomers,
	useUpdateInventoryPart,
	useUserCanUse,
} from "@ncs/ncs-api"
import {
	Box,
	Button,
	ConfirmationModal,
	ConfirmationModalConfig,
	EditBooleanModal,
	Heading,
	HeadingDivider,
	Icon,
	IconButton,
	Label,
	LoadingSpinner,
	Paragraph,
	SelectCustomerGroupModal,
	SelectCustomerGroupModalPayload,
	Table,
	TableProps,
	ThrottledTextInput,
	useToast,
} from "@ncs/web-legos"

export interface PartRestrictionsTabProps {
	part: InventoryPart
}

export const PartRestrictionsTab: FC<PartRestrictionsTabProps> = ({ part }) => {
	const { makeSuccessToast, makeErrorToast } = useToast()
	const isAdmin = useUserCanUse(APPLICATION.PartManagementAdmin)
	const [showRestrictedModal, setShowRestrictedModal] = useState(false)
	const [addingCustomer, setAddingCustomer] = useState(false)
	const [removingCustomer, setRemovingCustomer] = useState<CustomerMinimal | null>(null)
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)
	const [search, setSearch] = useState<string | null>(null)

	const updatePart = useUpdateInventoryPart()
	const addCustomers = useAddRestrictedPartToCustomers()
	const removeCustomers = useDeleteRestrictedParts()

	const [allowedCustomersRaw, allowedCustomersLoading] = useRestrictedPartCustomers(part.id)

	const handleSavePart = async (newState: boolean) => {
		await updatePart({
			id: part.id,
			updates: { restricted: newState },
		})
		makeSuccessToast("Part updated")
	}

	const handleAddCustomer = async (payload: SelectCustomerGroupModalPayload) => {
		const customer =
			payload.billToId ?
				{
					billToId: payload.billToId,
				}
			: payload.shipToId ?
				{
					shipToId: payload.shipToId,
				}
			:	null

		if (!customer) throw new Error("Both billToId and shipToId were missing")

		const { data } = await addCustomers({
			...customer,
			partId: part.id,
		})
		makeSuccessToast(`${data} customer(s) added`)
	}

	const handleRemoveCustomer = async (payload: SelectCustomerGroupModalPayload) => {
		const customer =
			payload.billToId ?
				{
					billToId: payload.billToId,
				}
			: payload.shipToId ?
				{
					shipToId: payload.shipToId,
				}
			:	null

		if (!customer) throw new Error("Both billToId and shipToId were missing")

		const { data } = await removeCustomers({
			body: { ...customer, partId: part.id },
		})
		makeSuccessToast(`${data} customer(s) removed`)
	}

	const handleRemoveAllCustomers = async () => {
		try {
			const { data } = await removeCustomers({
				body: { partId: part.id },
			})
			makeSuccessToast(`${data} customer(s) removed`)
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		}
	}

	const rowMenu = useMemo((): TableProps<CustomerRestrictedPart>["rowMenu"] => {
		return [
			{
				label: "Remove permissions",
				iconName: "trash-alt",
				onClick: ({ original }) => setRemovingCustomer(original.customer),
			},
			{
				label: "View Customer Management page",
				iconName: "external-link",
				onClick: ({ original }) =>
					window.open(`/customer-management/${original.customer.id}`),
			},
		]
	}, [])

	const allowedCustomers = useMemo(() => {
		return (allowedCustomersRaw ?? []).filter((c) => {
			if (!search) return true
			const searchable = `${c.customer.customerNumber} ${c.customer.name}`.toLowerCase()
			return searchable.includes(search.trim().toLowerCase())
		})
	}, [allowedCustomersRaw, search])

	return (
		<>
			<Heading variant="h4" bold mt={0} mb={0.5}>
				Restricted Status
			</Heading>

			<Paragraph mb={1}>
				A part that is restricted cannot be sold to a customer unless the customer is set
				up to be allowed to buy it.
			</Paragraph>

			<Label>Part is restricted?</Label>
			<Box display="flex" alignItems="center" columnGap={0.5} mb={1}>
				{part.restricted && <Icon icon="lock" />}
				<Paragraph>{part.restricted ? "Restricted" : "Not restricted"}</Paragraph>
				{isAdmin && (
					<IconButton
						icon="pencil"
						color="primary"
						onClick={() => setShowRestrictedModal(true)}
					/>
				)}
			</Box>

			<HeadingDivider
				headingVariant="h5"
				bold
				mb={0.5}
				mt={2}
				renderRight={() =>
					part.restricted ?
						<Box display="flex" columnGap={1}>
							<Button
								icon="plus"
								containerProps={{ mt: 1, mb: 1 }}
								onClick={() => setAddingCustomer(true)}
								disabled={allowedCustomersLoading}
							>
								Add customer(s)
							</Button>
							<Button
								icon="trash-alt"
								containerProps={{ mt: 1, mb: 1 }}
								disabled={allowedCustomersLoading || !allowedCustomers?.length}
								onClick={() =>
									setConfirmationConfig({
										title: "Remove All Customers",
										message:
											"Are you sure you want to remove purchasing permission for this part for ALL customers?",
										onConfirm: handleRemoveAllCustomers,
									})
								}
							>
								Remove all customers
							</Button>
						</Box>
					:	undefined
				}
			>
				Customers currently allowed to purchase:
			</HeadingDivider>
			{!part.restricted && (
				<Paragraph small color="secondary">
					Anyone can purchase
				</Paragraph>
			)}
			{part.restricted && (
				<>
					{allowedCustomersLoading && <LoadingSpinner />}
					{!allowedCustomersRaw?.length && !allowedCustomersLoading && (
						<Paragraph small color="secondary">
							No customers set up to allow purchase
						</Paragraph>
					)}
					{!!allowedCustomersRaw?.length && (
						<>
							<ThrottledTextInput
								label="Search"
								icon="search"
								value={search}
								onChange={setSearch}
								maxWidth={20}
								clearable
							/>

							<Table
								data={allowedCustomers}
								columns={allowedCustomersColumns}
								rowMenu={rowMenu}
								infiniteRowsIncrement={25}
							/>
						</>
					)}
				</>
			)}

			<EditBooleanModal
				isOpen={showRestrictedModal}
				onClose={() => setShowRestrictedModal(false)}
				onSave={handleSavePart}
				initialSelection={part.restricted}
				title="Edit Restricted Status"
				yesText="Yes, part is restricted"
				noText="No, anyone can purchase it"
				description="Should this part only be available for purchase by customers that have been explicitly allowed to do so on their Customer Management page?"
			/>
			<SelectCustomerGroupModal
				isOpen={addingCustomer}
				onClose={() => setAddingCustomer(false)}
				onSave={(payload) => handleAddCustomer(payload)}
				title="Allow Customers To Purchase"
			/>
			<SelectCustomerGroupModal
				isOpen={!!removingCustomer}
				onClose={() => setRemovingCustomer(null)}
				onSave={(payload) => handleRemoveCustomer(payload)}
				title="Remove Customer Permissions"
				saveButtonText="Remove Permission"
				lockedCustomer={removingCustomer}
			/>
			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</>
	)
}

const allowedCustomersColumns: Column<CustomerRestrictedPart>[] = [
	{
		Header: "Customer number",
		accessor: ({ customer }) => customer.customerNumber,
	},
	{
		Header: "Customer name",
		accessor: ({ customer }) => customer.name,
	},
]

import { Dispatch, ReactElement, SetStateAction, useMemo } from "react"

import { Select } from "../inputs"

interface IsActiveQueryParam {
	isActive: boolean | null
}

export interface IsActiveQueryFilterProps<QueryParamState extends IsActiveQueryParam> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

export const IsActiveQueryFilter = <QueryParamState extends IsActiveQueryParam>({
	queryParamState,
	setQueryParamState,
}: IsActiveQueryFilterProps<QueryParamState>): ReactElement => {
	const value = useMemo(() => {
		if (queryParamState.isActive === true) {
			return "true"
		} else if (queryParamState.isActive === false) {
			return "false"
		}

		return null
	}, [queryParamState.isActive])

	const handleChange = (newValue: string | null) => {
		setQueryParamState((prev) => ({
			...prev,
			isActive: newValue === null ? null : newValue === "true",
		}))
	}

	return (
		<Select
			value={value}
			onChange={handleChange}
			label="Active status"
			options={[
				{
					text: "Active",
					value: "true",
				},
				{
					text: "Inactive",
					value: "false",
				},
			]}
			fillContainer
			disableNoSelectionOption={false}
			noSelectionOptionText="Any status"
		/>
	)
}

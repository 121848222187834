import { FC, ReactNode, useMemo, useState } from "react"

import {
	Customer,
	CustomerMin,
	CustomerMinimal,
	makeApiErrorMessage,
	useCustomerBillTos,
} from "@ncs/ncs-api"

import { Checkbox, RadioGroup } from "../inputs"
import { CustomerSelector } from "../selectors"
import { AnimatedEntrance, LoadingSpinner } from "../transitions"
import { Heading, Paragraph } from "../typography"
import { ExtendableModalProps, Modal } from "./Modal"

export interface SelectCustomerGroupModalPayload {
	shipToId: string | null
	billToId: string | null
}

export interface SelectCustomerGroupModalProps extends ExtendableModalProps {
	onSave: (payload: SelectCustomerGroupModalPayload, includeInactive?: boolean) => Promise<void>
	title?: string
	saveButtonText?: string
	instructions?: ReactNode | string
	/** Pre-fill the selector with this customer ID. */
	initialCustomerId?: string | null
	/** Disable the choosing functionality and just use this customer. */
	lockedCustomer?: Customer | CustomerMin | CustomerMinimal | null
	/**
	 * Inactive customers normally won't be shown in search results, but you can set them to here.
	 */
	includeInactiveInSearch?: boolean
	/**
	 * Optionally show a checkbox asking if they want to include inactive customers. The `onSave`
	 * function will get passed the value of this checkbox as its second argument.
	 */
	showIncludeInactiveCheckbox?: boolean
	/**
	 * Arbitrary content to show above the customer form.
	 */
	contentAbove?: ReactNode
	/**
	 * Arbitrary content to show below the customer form.
	 */
	contentBeneath?: ReactNode
	/**
	 * Should the customer search field auto focus?
	 */
	autoFocus?: boolean
	/**
	 * Should the save button be disabled? It is also disabled when there is no customer selected.
	 */
	disableSaveButton?: boolean | (() => boolean)
}

enum SelectionType {
	Selection = "1",
	SameBillTos = "2",
	BillToSelection = "3",
}

export const SelectCustomerGroupModal: FC<SelectCustomerGroupModalProps> = ({
	onSave,
	saveButtonText = "Save",
	instructions,
	initialCustomerId,
	lockedCustomer,
	includeInactiveInSearch,
	showIncludeInactiveCheckbox,
	contentAbove,
	contentBeneath,
	autoFocus = true,
	disableSaveButton: disableSaveButtonProp,
	...rest
}) => {
	const [selectedSite, setSelectedSite] = useState<Customer | null>(null)
	const [selectionType, setSelectionType] = useState<SelectionType>(SelectionType.Selection)
	const [includeInactiveChecked, setIncludeInactiveChecked] = useState(false)

	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const selectionId = useMemo(() => {
		return selectedSite?.id || lockedCustomer?.id || null
	}, [selectedSite?.id, lockedCustomer?.id])

	const [billTos, billTosLoading] = useCustomerBillTos(selectionId)

	const selectionDefaultBillToId = useMemo(() => {
		return (billTos ?? []).find((b) => b.isDefault)?.id ?? null
	}, [billTos])

	const handleSave = async () => {
		try {
			if (!selectionId) throw new Error("Please select a site")
			setIsSaving(true)

			let shipToId: string | null = null
			let billToId: string | null = null

			switch (selectionType) {
				case SelectionType.Selection: {
					shipToId = selectionId
					break
				}
				case SelectionType.SameBillTos: {
					if (!selectionDefaultBillToId)
						throw new Error("Selection has no default bill-to")
					billToId = selectionDefaultBillToId
					break
				}
				case SelectionType.BillToSelection: {
					billToId = selectionId
					break
				}
			}

			await onSave(
				{
					shipToId,
					billToId,
				},
				showIncludeInactiveCheckbox ? includeInactiveChecked : undefined
			)

			rest.onClose()
		} catch (e) {
			setErrorText(makeApiErrorMessage(e))
			setIsSaving(false)
		}
	}

	const waitingOnBillTos = useMemo(
		() => selectionType !== SelectionType.Selection && billTosLoading,
		[selectionType, billTosLoading]
	)

	const disableSaveButton = useMemo(() => {
		if (!selectionId) return true
		if (waitingOnBillTos) return true

		if (disableSaveButtonProp != null) {
			return disableSaveButtonProp instanceof Function ? disableSaveButtonProp() : (
					disableSaveButtonProp
				)
		}

		return false
	}, [disableSaveButtonProp, selectionId, waitingOnBillTos])

	const reset = () => {
		setSelectedSite(null)
		setSelectionType(SelectionType.Selection)
		setIsSaving(false)
		setErrorText(null)
	}

	return (
		<Modal
			{...rest}
			onOpen={reset}
			rightButtons={{
				buttonText: saveButtonText,
				isLoading: isSaving,
				onClick: handleSave,
				disabled: disableSaveButton,
			}}
			errorText={errorText}
		>
			{!lockedCustomer && (
				<>
					{instructions !== null &&
						(instructions ?
							typeof instructions === "string" ?
								<Paragraph mb={1.5}>{instructions}</Paragraph>
							:	instructions
						:	<Paragraph mb={1.5}>
								Search all customers and make a selection. You can then choose just
								your selection, or your selection and all others that have the same
								bill-to as your selection, or all sites that bill to your
								selection.
							</Paragraph>)}

					{!!contentAbove && contentAbove}

					<CustomerSelector
						autoFocus={autoFocus}
						value={selectedSite}
						onChange={setSelectedSite}
						initialCustomerId={initialCustomerId}
						accountActive={includeInactiveInSearch ? null : undefined}
						service={includeInactiveInSearch ? null : undefined}
					/>
				</>
			)}

			{!!lockedCustomer && (
				<Heading variant="h4" mb={1}>
					({lockedCustomer.customerNumber}) {lockedCustomer.name}
				</Heading>
			)}

			{waitingOnBillTos && (
				<LoadingSpinner my={0} py={0} pb={1} justifyContent="flex-start" />
			)}

			<RadioGroup
				htmlName="selection-type"
				options={options}
				value={selectionType}
				onChange={(newValue, option) => setSelectionType(option.value)}
			/>

			<AnimatedEntrance
				show={
					!!showIncludeInactiveCheckbox &&
					[SelectionType.SameBillTos, SelectionType.BillToSelection].includes(
						selectionType
					)
				}
			>
				<Checkbox
					mt={1}
					value={includeInactiveChecked}
					onChange={setIncludeInactiveChecked}
					label="Include inactive customers?"
				/>
			</AnimatedEntrance>

			{!!contentBeneath && contentBeneath}
		</Modal>
	)
}

const options = [
	{
		label: "Selected site only",
		value: SelectionType.Selection,
	},
	{
		label: "Selection plus all sites with same default bill-to",
		value: SelectionType.SameBillTos,
	},
	{
		label: "All sites that bill to selection",
		value: SelectionType.BillToSelection,
	},
]

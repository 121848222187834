import React, { useCallback, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useAppRestrictions, useCallApi } from "@ncs/bricks/util/hooks"
import { unpythonify } from "@ncs/bricks/util/variableCasing"
import { getChemicalMachineTypes } from "@ncs/mortar/redux/services/inventory"
import { APPLICATIONS } from "@ncs/mortar/util/constants"
import { ButtonWithIcon, GridContainer, GridItem, QuickCard, DynamicTable } from "~/components"
import NewMachineTypeModal from "./NewMachineTypeModal"

import { Add, SettingsInputComponentOutlined } from "@material-ui/icons"

const MachineTypes = () => {
	const isAdmin = useAppRestrictions(APPLICATIONS.ChemMachineTypeAdmin)
	const history = useHistory()
	const callApi = useCallApi()
	const [machineTypes, setMachineTypes] = useState({})
	const [isLoading, setIsLoading] = useState(false)
	const [showNewMachineTypeModal, setShowNewMachineTypeModal] = useState(false)

	const fetchMachineTypes = useCallback(async () => {
		setIsLoading(true)
		const { payload } = await callApi(getChemicalMachineTypes())
		setMachineTypes(unpythonify(payload))
		setIsLoading(false)
	}, [callApi])

	useEffect(() => {
		fetchMachineTypes()
	}, [fetchMachineTypes])

	function handleRowClick(row) {
		history.push(`/machine-types/${row.id}`)
	}

	return (
		<>
			<QuickCard icon={<SettingsInputComponentOutlined />} title="Chemical Machine Types">
				<GridContainer>
					{isAdmin && (
						<GridItem xs={12} justifyContentRight>
							<ButtonWithIcon
								content="Create New Machine Type"
								onClick={() => setShowNewMachineTypeModal(true)}
								icon={<Add />}
								size="sm"
								color="success"
								round
								noMargin
								style={{ marginBottom: "1rem" }}
							/>
						</GridItem>
					)}
					<GridItem xs={12}>
						<DynamicTable
							data={Object.values(machineTypes)}
							useClientSidePaging={false}
							onRowClick={handleRowClick}
							noDataText={
								isLoading ? "Loading machine types..." : "No machine types found"
							}
							columns={[
								{
									Header: "Name",
									accessor: "description",
								},
								{
									id: "isActive",
									Header: "Status",
									accessor: (row) => (row.isActive ? "Active" : "Inactive"),
								},
							]}
						/>
					</GridItem>
				</GridContainer>
			</QuickCard>

			<NewMachineTypeModal
				show={showNewMachineTypeModal}
				onClose={(newMachineType) => {
					setShowNewMachineTypeModal(false)
					if (newMachineType) {
						setMachineTypes((prev) => [unpythonify(newMachineType), ...prev])
					}
				}}
			/>
		</>
	)
}

export default MachineTypes

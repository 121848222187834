import { FC } from "react"

import { Cell, Column } from "react-table"

import {
	EquipmentVintage,
	EquipmentVintageCustomer,
	useEquipmentVintageCustomers,
} from "@ncs/ncs-api"
import { displayDate } from "@ncs/ts-utils"
import { EmptyValueDash, ExtendableModalProps, Heading, Link, Modal, Table } from "@ncs/web-legos"

export interface VintageCustomersModalProps extends ExtendableModalProps {
	vintage: EquipmentVintage
}

export const VintageCustomersModal: FC<VintageCustomersModalProps> = ({ vintage, ...rest }) => {
	const query = useEquipmentVintageCustomers(vintage.id)

	return (
		<Modal {...rest} title="Vintage Customers" maxWidth="md">
			<Heading mt={1} mb={2}>
				Vintage: <strong>{vintage.name}</strong>
			</Heading>

			<Table
				query={query}
				columns={columns}
				noDataText="No customers currently using this vintage on record"
				disableAllSorting
			/>
		</Modal>
	)
}

const columns: Column<EquipmentVintageCustomer>[] = [
	{
		Header: "Customer",
		Cell: ({
			row: {
				original: { customer },
			},
		}: Cell<EquipmentVintageCustomer>) => {
			return (
				<Link to={`/customer-management/${customer.id}`} newTab>
					({customer.customerNumber}) {customer.name}
				</Link>
			)
		},
	},
	{
		Header: "Serial #",
		accessor: "serialNumber",
	},
	{
		Header: "Install date",
		accessor: ({ installDate }) =>
			displayDate(installDate, "", { formatInUtc: true }) || <EmptyValueDash />,
	},
	{
		Header: "Bay",
		accessor: ({ bay }) => bay || <EmptyValueDash />,
	},
]

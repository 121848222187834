import React from "react"
import { BaseDateFilter, withDynamicTableFilter } from "@ncs/bricks/components"

class TicketClosedEndFilter extends React.PureComponent {
	render() {
		return <BaseDateFilter labelText="Closed End Date" {...this.props} />
	}
}

export default withDynamicTableFilter("ticket_close_end")(TicketClosedEndFilter)

import { FC, useMemo } from "react"

import { Column } from "react-table"

import {
	PartOrderDetailReportLine,
	PartOrderDetailReportQueryParams,
	useExportPartOrderDetailReport,
	usePartOrderDetailReport,
} from "@ncs/ncs-api"
import {
	formatDateTime,
	getStartAndEndDateQueryParams,
	getTimezoneAbbreviation,
	isKeyOf,
	TimeAgo,
	Typify,
} from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	EnterpriseQueryFilter,
	Link,
	OrganizationQueryFilter,
	RegionQueryFilter,
	Table,
	TerritoryQueryFilter,
	TimeAgoQueryFilter,
	usePaginationUrlState,
	useUrlState,
} from "@ncs/web-legos"

import { VehicleCareQueryFilter } from "~/components"

export type PartOrderDetailReportUrlState = Typify<PartOrderDetailReportQueryParams>

export const PartOrderDetailReport: FC = () => {
	const [queryParams, { setUrlState: setQueryParams }] =
		useUrlState<PartOrderDetailReportUrlState>(initialPartOrderDetailReportUrlState)
	const [pagination, setPagination] = usePaginationUrlState()

	const query = usePartOrderDetailReport({
		params: { ...queryParams, ...pagination },
		manualPagination: true,
	})
	const dataExport = useExportPartOrderDetailReport(queryParams)

	const showToggledFiltersByDefault = useMemo(() => {
		return Object.entries(toggledQueryFilterKeys).some(([key, isShowMore]) => {
			return isShowMore && isKeyOf(key, queryParams) && !!queryParams[key]
		})
	}, [queryParams])

	return (
		<AnimatedEntrance show>
			<Table
				query={query}
				columns={columns}
				queryParamState={queryParams}
				setQueryParamState={setQueryParams}
				pagination={pagination}
				setPagination={setPagination}
				dataExport={dataExport}
				pinnedQueryFilters={[TimeAgoQueryFilter, TerritoryQueryFilter]}
				toggledQueryFilters={[
					VehicleCareQueryFilter,
					RegionQueryFilter,
					OrganizationQueryFilter,
					EnterpriseQueryFilter,
				]}
				showToggledFiltersByDefault={showToggledFiltersByDefault}
				filterResetValues={initialPartOrderDetailReportUrlState}
				storeColumnVisibility="part-order-detail-report-columns"
			/>
		</AnimatedEntrance>
	)
}

const columns: Column<PartOrderDetailReportLine>[] = [
	{
		Header: "Order #",
		disableSortBy: true,
		accessor: "orderId",
		Cell: ({ row: { original } }) => {
			return (
				<Link newTab icon="external-link" to={`/part-orders/${original.id}`}>
					Part Order #{original.orderId}
				</Link>
			)
		},
	},
	{
		Header: "Status",
		id: "orderStatus_Description",
		accessor: ({ orderStatus }) => orderStatus.description,
	},
	{
		Header: "Ship-to customer",
		disableSortBy: true,
		accessor: ({ shipToCustomer }) =>
			shipToCustomer ? `(${shipToCustomer.customerNumber}) ${shipToCustomer.name}` : "-",
	},
	{
		Header: `Created (${getTimezoneAbbreviation()})`,
		id: "orderDate",
		accessor: ({ orderDate }) => formatDateTime(orderDate),
	},
	{
		Header: "Shipped on-time",
		disableSortBy: true,
		accessor: ({ shippedOnTime }) =>
			shippedOnTime === true ? "Yes"
			: shippedOnTime === false ? "No"
			: shippedOnTime,
	},
	{
		Header: "KBM Order #",
		disableSortBy: true,
		hiddenByDefault: true,
		accessor: ({ kbmOrderNumber }) => kbmOrderNumber || "-",
	},
	{
		Header: `Projected ship time (${getTimezoneAbbreviation()})`,
		hiddenByDefault: true,
		disableSortBy: true,
		accessor: ({ projectedShipTime }) =>
			projectedShipTime ? formatDateTime(projectedShipTime) : "-",
	},
	{
		Header: `Actual ship time (${getTimezoneAbbreviation()})`,
		hiddenByDefault: true,
		disableSortBy: true,
		accessor: ({ actualShipDate }) => (actualShipDate ? formatDateTime(actualShipDate) : "-"),
	},
	{
		Header: "Dispatch #",
		hiddenByDefault: true,
		disableSortBy: true,
		accessor: ({ dispatch }) => dispatch?.dispatchNumber || "-",
	},
	{
		Header: "Created by",
		hiddenByDefault: true,
		disableSortBy: true,
		accessor: ({ createdBy }) => createdBy?.name || "-",
	},
]

const initialPartOrderDetailReportUrlState: PartOrderDetailReportUrlState = {
	ordering: null,
	region: null,
	territory: null,
	startDate: getStartAndEndDateQueryParams(TimeAgo.DaysAgo30)[0],
	endDate: null,
	enterprise: null,
	organization: null,
	vehicleCare: null,
}

const toggledQueryFilterKeys: Record<keyof PartOrderDetailReportQueryParams, boolean> = {
	ordering: false,
	territory: false,
	startDate: false,
	endDate: false,
	enterprise: true,
	organization: true,
	region: true,
	vehicleCare: true,
}

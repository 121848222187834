import React from "react"
import PropTypes from "prop-types"

import {
	Card,
	CardBody,
	CardHeader,
	CardIcon,
	CardIconTitle,
	ConditionalContent,
	TreeView,
} from "~/components"
// icons
import ChevronLeft from "@material-ui/icons/ChevronLeft"
import Collections from "@material-ui/icons/Collections"
// styles
import withStyles from "@material-ui/core/styles/withStyles"
import { cardIconTitle } from "@ncs/bricks/assets/jss/material-dashboard-pro-react"

const styles = {
	clickable: {
		cursor: "pointer",
		paddingLeft: "3px",
	},
	clickableCardIconTitle: {
		...cardIconTitle,
		cursor: "pointer",
	},
}

const CategoriesMenu = (props) => {
	const {
		classes,
		categoryTree,
		onCategorySelected,
		selectedCategory,
		allowToggleVisibility,
		onToggleVisibility,
	} = props

	return (
		<ConditionalContent show={categoryTree && categoryTree.length > 0}>
			<Card>
				<CardHeader color="warning" icon>
					<CardIcon
						color="warning"
						className={allowToggleVisibility ? classes.clickable : undefined}
						onClick={allowToggleVisibility ? onToggleVisibility : undefined}
					>
						{allowToggleVisibility ?
							<ChevronLeft />
						:	<Collections />}
					</CardIcon>
					<CardIconTitle
						className={classes.clickableCardIconTitle}
						onClick={() => onCategorySelected(null)}
					>
						{"All Categories"}
					</CardIconTitle>
				</CardHeader>
				<CardBody>
					<TreeView
						tree={categoryTree}
						onLeafClick={onCategorySelected}
						onParentClick={onCategorySelected}
						selectedValue={selectedCategory}
					/>
				</CardBody>
			</Card>
		</ConditionalContent>
	)
}

CategoriesMenu.defaultProps = {
	allowToggleVisibility: true,
}

CategoriesMenu.propTypes = {
	classes: PropTypes.object.isRequired,
	categoryTree: PropTypes.array.isRequired,
	onCategorySelected: PropTypes.func.isRequired,
	selectedCategory: PropTypes.number,
	allowToggleVisibility: PropTypes.bool,
	onToggleVisibility: PropTypes.func.isRequired,
}

export default withStyles(styles)(CategoriesMenu)

import { FC } from "react"

import { ReportAssignment, useReportAssignments } from "@ncs/ncs-api"

import { ExtendableSelectProps, Select } from "../inputs"

export interface ReportGroupSelectorProps extends ExtendableSelectProps<ReportAssignment> {
	includeNonDefaults?: boolean
}

export const ReportGroupSelector: FC<ReportGroupSelectorProps> = ({
	includeNonDefaults,
	...rest
}) => {
	const [groups, groupsLoading] = useReportAssignments(includeNonDefaults ? undefined : true)

	return (
		<Select
			disabled={groupsLoading}
			label="Report group"
			disableNoSelectionOption={false}
			{...rest}
			options={groups ?? []}
			valueAccessor="id"
			textAccessor="description"
		/>
	)
}

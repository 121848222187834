import React from "react"
import withFilter from "./withDynamicTableFilter"
import { DATE_RANGE_FILTER_TYPE } from "../../util/constants"
import { DATE_RANGES } from "../../util/dates"

// custom components
import BaseSelectFilter from "./BaseSelectFilter"

function PastDateThroughTodayFilter(props) {
	function handleChange(value) {
		props.onChange({ type: DATE_RANGE_FILTER_TYPE, value })
	}

	return (
		<BaseSelectFilter
			id={props.labelText}
			labelText={props.labelText}
			options={DATE_RANGES}
			{...props}
			value={props.value?.value || "all"}
			onChange={handleChange}
		/>
	)
}

export const AliasedPastDateThroughTodayFilter = (filterName, labelText) =>
	withFilter(filterName, undefined, undefined, undefined, undefined, { labelText })(
		PastDateThroughTodayFilter
	)

export default AliasedPastDateThroughTodayFilter("date", "Date")

import { FC, useState } from "react"

import { Dispatch, makeApiErrorMessage, useUpdateDispatch } from "@ncs/ncs-api"
import {
	DispatchCallTypeSelector,
	ExtendableModalProps,
	Modal,
	useIsSaving,
	useToast,
} from "@ncs/web-legos"

export interface EditCallTypeModalProps extends ExtendableModalProps {
	dispatch: Dispatch
}

export const EditCallTypeModal: FC<EditCallTypeModalProps> = ({ dispatch, ...rest }) => {
	const { makeSuccessToast } = useToast()
	const [value, setValue] = useState(dispatch.callTypeId?.toString() ?? null)
	const [errorText, setErrorText] = useState<string | null>(null)
	const { isSaving, setSaving, endSaving } = useIsSaving()

	const updateDispatch = useUpdateDispatch()

	const handleSave = async () => {
		if (!value) {
			setErrorText("Please choose a call type")
			return
		}

		try {
			setSaving()
			await updateDispatch({
				id: dispatch.id,
				updates: {
					callTypeId: value,
				},
			})
			makeSuccessToast("Call type updated")
			rest.onClose()
		} catch (e) {
			endSaving()
			setErrorText(makeApiErrorMessage(e))
		}
	}

	return (
		<Modal
			{...rest}
			title="Edit Call Type"
			errorText={errorText}
			rightButtons={{
				buttonText: "Save",
				isLoading: isSaving(),
				onClick: handleSave,
			}}
		>
			<DispatchCallTypeSelector
				value={value}
				onChange={setValue}
				fillContainer
				showNoSelectionOption={false}
			/>
		</Modal>
	)
}

import { get, post, rsaaTypes, RSAA } from "@ncs/bricks/middleware/portalApiMiddleware"

const FETCH_SHIPPING_REQUESTS = rsaaTypes("shipping_request/list")
const GET_SHIPPING_REQUEST = rsaaTypes("shipping_request/get")
const GET_SHIPPING_REQUEST_STOPS = rsaaTypes("shipping_requests/stops")
const REOPEN_SHIPPING_REQUEST = rsaaTypes("shipping_request/reopen")
const CANCEL_SHIPPING_REQUEST = rsaaTypes("shipping_request/cancel")
const REQUEST_SHIPMENT_SHIPPING_REQUEST = rsaaTypes("shipping_request/request_shipment")
const SHIP_SHIPPING_REQUEST = rsaaTypes("shipping_request/ship")
const SHIPPING_STOP_DETAIL = rsaaTypes("shipping_request/stop/detail")

export const fetchShippingRequests = (querystringParams) => ({
	[RSAA]: {
		api: get(`/inventory/shipping/`, querystringParams),
		types: FETCH_SHIPPING_REQUESTS,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const fetchShippingRequest = (shippingRequestId, stopId) => ({
	[RSAA]: {
		api: get(`/inventory/shipping/${shippingRequestId}/`),
		types: GET_SHIPPING_REQUEST,
	},
})

export const fetchShippingRequestStops = (shippingRequestId, stopId) => ({
	[RSAA]: {
		api: get(`/inventory/shipping/${shippingRequestId}/stops/`),
		types: GET_SHIPPING_REQUEST_STOPS,
	},
})

export const cancelShippingRequest = (shippingRequestId) => ({
	[RSAA]: {
		api: post(`/inventory/shipping/${shippingRequestId}/cancel/`),
		types: CANCEL_SHIPPING_REQUEST,
	},
})

export const reopenShippingRequest = (shippingRequestId) => ({
	[RSAA]: {
		api: post(`/inventory/shipping/${shippingRequestId}/reopen/`),
		types: REOPEN_SHIPPING_REQUEST,
	},
})

export const requestShipmentShippingRequest = (shippingRequestId) => ({
	[RSAA]: {
		api: post(`/inventory/shipping/${shippingRequestId}/request_shipment/`),
		types: REQUEST_SHIPMENT_SHIPPING_REQUEST,
	},
})

export const shipShippingRequest = (shippingRequestId) => ({
	[RSAA]: {
		api: post(`/inventory/shipping/${shippingRequestId}/ship/`),
		types: SHIP_SHIPPING_REQUEST,
	},
})

export const fetchShippingRequestStop = (stopRequestId) => ({
	[RSAA]: {
		api: post(`/inventory/shipping_stop/${stopRequestId}`),
		types: SHIPPING_STOP_DETAIL,
	},
})

const initialState = {
	isLoadingShippingRequests: false,
	shippingRequests: [],
	error: null,
}

const shippingRequest = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_SHIPPING_REQUESTS.request:
		case FETCH_SHIPPING_REQUESTS.success:
		case FETCH_SHIPPING_REQUESTS.failure:
			return {
				...state,
				isLoadingShippingRequests: action.type === FETCH_SHIPPING_REQUESTS.request,
				purchaseOrders:
					action.type === FETCH_SHIPPING_REQUESTS.success ?
						action.payload
					:	state.purchaseOrders,
				error: action.error,
			}

		default:
			return state
	}
}
export default shippingRequest

import React from "react"

import { ExtendableSelectProps, Select } from "../inputs"

export interface DispatchServiceNotificationTypeProps
	extends ExtendableSelectProps<(typeof options)[number], DispatchServiceNotificationTypeEnum> {}

export enum DispatchServiceNotificationTypeEnum {
	Email = "Email",
	Phone = "Phone",
	Fax = "Fax",
	Mail = "Mail",
}

const options: {
	value: DispatchServiceNotificationTypeEnum | null
	text: string
}[] = [
	{
		value: DispatchServiceNotificationTypeEnum.Email,
		text: "Email",
	},
	{
		value: DispatchServiceNotificationTypeEnum.Phone,
		text: "Phone",
	},
	{
		value: DispatchServiceNotificationTypeEnum.Fax,
		text: "Fax",
	},
	{
		value: DispatchServiceNotificationTypeEnum.Mail,
		text: "Mail",
	},
]

export const DispatchServiceNotificationType: React.FC<DispatchServiceNotificationTypeProps> = ({
	onChange,
	label = "Notification Type",
	...rest
}) => {
	return (
		<Select
			disableNoSelectionOption={false}
			label={label}
			options={options}
			onChange={(newValue) =>
				onChange(newValue as DispatchServiceNotificationTypeEnum | null)
			}
			{...rest}
		/>
	)
}

import React from "react"

import { withStyles } from "@material-ui/core/styles"
import get from "lodash/get"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { bindActionCreators } from "redux"

import { loadLookups, LOOKUPS } from "@ncs/mortar/redux/services/lookups"
import {
	addMilestoneGroup,
	fetchMilestoneGroups,
} from "@ncs/mortar/redux/services/machineMilestones"
import { APPLICATIONS } from "@ncs/mortar/util/constants"
import { meetsAppRestriction } from "@ncs/bricks/redux/selectors/auth"
import callApi from "@ncs/bricks/redux/services/callApi"
import { generateOrderingWithPeople } from "@ncs/bricks/redux/services/dynamicTables"

import {
	Button,
	ButtonWithIcon,
	ConditionalContent,
	Dialog,
	DynamicTable,
	GridContainer,
	GridItem,
	Input,
	InputAsDisplay,
	LoadingWrapper,
	NumericInput,
	QuickCard,
	SearchFilter,
	Select,
} from "~/components"

import Add from "@material-ui/icons/Add"
import Info from "@material-ui/icons/Info"
import LocalShipping from "@material-ui/icons/LocalShipping"

const styles = {
	rightAlign: {
		textAlign: "right",
	},
}

const reduxKey = "machineMilestoneGroup"

const columns = [
	{
		Header: "Description",
		accessor: "description",
		id: "description",
		sortable: true,
		toggleable: false,
	},
	{
		Header: "Is Active",
		accessor: (x) => (x.is_active ? "True" : "False"),
		id: "is_active",
		sortable: true,
		toggleable: false,
	},
	{
		Header: "Vintage",
		accessor: (x) => get(x, "x.vintage.name"),
		id: "vintage",
		sortable: true,
		hiddenByDefault: true,
	},
	{
		Header: "Model",
		accessor: (x) => get(x, "x.model.model_name"),
		id: "model",
		sortable: true,
		hiddenByDefault: true,
	},
	{
		Header: "Created By",
		accessor: "created_by.name",
		sortable: true,
		hiddenByDefault: true,
	},
]

class MachineMilestoneGroup extends React.PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			isLoading: false,
			isSaving: false,
			milestoneGroup: null,
			milestones: [],
			newDescription: "",
			newDiscountPercent: 0,
			newIsActive: "true",
			vintageSelected: 0,
			modelSelected: 0,
			creatingMilestoneGroup: false,
			vintageDisabled: false,
			modelDisabled: false,
		}
	}

	viewDetailsButton = (milestone) => (
		<Button
			justIcon
			round
			simple
			className="edit"
			color="info"
			onClick={(e) => {
				e.stopPropagation()
				this.handleMilestoneSelect(milestone)
			}}
		>
			<Info />
		</Button>
	)

	componentDidMount = () => {
		this.props.loadLookups(LOOKUPS.NCSEquipmentModels)
		this.props.loadLookups(LOOKUPS.NCSEquipmentVintages)
	}

	handleDescriptionUpdate = (e) => {
		this.setState({ newDescription: e.target.value })
	}

	handleNewIsActiveUpdate = (newIsActive) => {
		this.setState({ newIsActive })
	}

	handleDiscountPercentUpdate = (newDiscountPercent) => {
		this.setState({ newDiscountPercent })
	}

	handleModelUpdate = (modelSelected) => {
		this.setState({
			modelSelected,
			vintageDisabled: modelSelected !== 0,
			vintageSelected: 0,
		})
	}

	handleVintageUpdate = (vintageSelected) => {
		this.setState({
			vintageSelected,
			modelDisabled: vintageSelected !== 0,
			modelSelected: 0,
		})
	}

	handleMilestoneSelect = (milestone) => {
		this.props.history.push(`/machine-milestone/milestone/${milestone.id}`)
	}

	cancelCreateGroup = () => {
		this.setState({
			creatingMilestoneGroup: false,
			vintageDisabled: false,
			modelDisabled: false,
			newDescription: "",
			newDiscountPercent: 0,
			newIsActive: "true",
			vintageSelected: 0,
			modelSelected: 0,
		})
	}

	handleCreateMilestoneGroup = () => {
		this.setState({
			creatingMilestoneGroup: true,
			vintageDisabled: false,
			modelDisabled: false,
			newDescription: "",
			newDiscountPercent: 0,
			newIsActive: "true",
			vintageSelected: 0,
			modelSelected: 0,
		})
	}

	handleSaveNewGroup = () => {
		let { newDescription, newIsActive, newDiscountPercent, modelSelected, vintageSelected } =
			this.state
		let data = {
			vintage_id: vintageSelected === 0 ? null : vintageSelected,
			model_id: modelSelected === 0 ? null : modelSelected,
			description: newDescription,
			is_active: newIsActive,
			discount_percent: newDiscountPercent ? (newDiscountPercent / 100).toFixed(4) : 0,
		}
		this.setState({ isSaving: false })
		this.props.callApi(addMilestoneGroup(data)).then(({ payload }) => {
			if (payload.error) {
				this.setState({ isSaving: false })
				console.error(payload.error)
			} else {
				this.setState({ isSaving: false })
				this.handleMilestoneSelect(payload)
			}
		})
	}

	render() {
		const {
			isSaving,
			isLoading,
			newDescription,
			newDiscountPercent,
			newIsActive,
			vintageSelected,
			modelSelected,
			creatingMilestoneGroup,
			modelDisabled,
			vintageDisabled,
		} = this.state
		const { classes, models, vintages, isMilestoneAdmin } = this.props
		return (
			<React.Fragment>
				<LoadingWrapper isLoading={isLoading} />

				<QuickCard title={"Machine Milestone Groups"} icon={<LocalShipping />}>
					<GridContainer>
						<GridItem xs={6} sm={6} md={4}>
							<SearchFilter reduxKey={reduxKey} />
						</GridItem>
						<ConditionalContent show={isMilestoneAdmin()}>
							<GridItem xs={12} md={8} className={classes.rightAlign}>
								<ButtonWithIcon
									content="Create Milestone Group"
									onClick={this.handleCreateMilestoneGroup}
									icon={<Add />}
									size="sm"
									color="success"
									round
								/>
							</GridItem>
						</ConditionalContent>
					</GridContainer>

					<DynamicTable // TODO: make props static
						reduxKey={reduxKey}
						fetchDataAction={fetchMilestoneGroups}
						columns={[
							...columns,
							{
								Header: "",
								accessor: this.viewDetailsButton,
								id: "actions",
								sortable: false,
								toggleable: false,
								maxWidth: 50,
							},
						]}
						showResetFiltersButton={true}
						defaultPageSize={25}
						generateOrderingFn={generateOrderingWithPeople(["created_by"])}
						onRowClick={this.handleMilestoneSelect}
					/>
				</QuickCard>
				<Dialog
					show={creatingMilestoneGroup}
					title={"Create Milestone Group"}
					onCancel={this.cancelCreateGroup}
					onConfirm={this.handleSaveNewGroup}
					isSaving={isSaving}
				>
					{vintageDisabled === true ?
						<InputAsDisplay value="None" labelText="Vintage" />
					:	<Select
							value={vintageSelected}
							onChange={this.handleVintageUpdate}
							labelText="Vintage"
							disabled={vintageDisabled}
							valueAccessor="_id"
							textAccessor="name"
							options={[{ _id: 0, name: "None" }, ...vintages]}
						/>
					}
					{modelDisabled === true ?
						<InputAsDisplay value="None" labelText="Model" />
					:	<Select
							value={modelSelected}
							onChange={this.handleModelUpdate}
							labelText="Model"
							disabled={modelDisabled}
							valueAccessor="_id"
							textAccessor="model_name"
							options={[{ _id: 0, model_name: "None" }, ...models]}
						/>
					}
					<Input
						labelText="Description"
						value={newDescription}
						onChange={this.handleDescriptionUpdate}
					/>
					<Select
						value={newIsActive}
						onChange={this.handleNewIsActiveUpdate}
						labelText="Is Active"
						valueAccessor="id"
						textAccessor="description"
						options={[
							{ id: "true", description: "True" },
							{ id: "false", description: "False" },
						]}
					/>
					<NumericInput
						labelText="Discount Percent"
						value={newDiscountPercent}
						decimal_places={2}
						onChange={this.handleDiscountPercentUpdate}
					/>
				</Dialog>
			</React.Fragment>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		vintages: state.lookups.ncsEquipmentVintages,
		models: state.lookups.ncsEquipmentModels,
		isMilestoneAdmin: () => {
			return meetsAppRestriction(state.auth, APPLICATIONS.MachineMilestoneAdmin)
		},
	}
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ callApi, loadLookups }, dispatch)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(withStyles(styles)(MachineMilestoneGroup)))

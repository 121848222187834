import { FC, useState } from "react"

import { css } from "@emotion/react"

import {
	makeApiErrorMessage,
	UpdateUserProfilePatch,
	useBrandCards,
	UserProfile,
	useSetUserBrandCards,
	useUpdateUserProfile,
} from "@ncs/ncs-api"
import { toggleArrayItem } from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	Checkbox,
	ErrorText,
	GridContainer,
	GridItem,
	HeadingDivider,
	LabeledData,
	Paragraph,
	RadioBoolean,
	useChangeCallback,
	useToast,
} from "@ncs/web-legos"

export interface CustomerUserShopTabProps {
	user: UserProfile
}

export const CustomerUserShopTab: FC<CustomerUserShopTabProps> = ({ user }) => {
	const { makeErrorToast, makeSuccessToast } = useToast()
	const [isSavingPoRequired, setIsSavingPoRequired] = useState(false)
	const [isSavingCards, setIsSavingCards] = useState(false)
	const [showAllCards, setShowAllCards] = useState(true)

	const [poRequiredFormValue, setPoRequiredFormValue] = useState(user.poRequired)
	const [cardsToShowFormValue, setCardsToShowFormValue] = useState<string[]>([])
	const [cardsToShowErrorText, setCardsToShowErrorText] = useState<string | null>(null)

	// We need all cards in order to show the list of possibilities.
	const [allCards] = useBrandCards()
	// We also need to know which cards are currently associated with the user.
	const [userCards, userCardsLoading] = useBrandCards({
		params: {
			user: user.id.toString(),
		},
	})

	// Whenever we get an update on the user's cards from the server, set
	// our local state accordingly.
	useChangeCallback(
		userCards,
		(newUserCards) => {
			setCardsToShowFormValue(newUserCards ? newUserCards.map((c) => c.id) : [])
			setShowAllCards(newUserCards?.length === 0)
		},
		{ callOnSetup: true }
	)

	const updateUser = useUpdateUserProfile({ queryParams: { isCustomer: true } })
	const setCards = useSetUserBrandCards()

	const onSaveUser = async <Field extends keyof UpdateUserProfilePatch>(
		field: Field,
		newValue: UpdateUserProfilePatch[Field]
	) => {
		try {
			setIsSavingPoRequired(true)
			await updateUser({
				updates: {
					[field]: newValue,
				},
				id: user.id.toString(),
			})
			makeSuccessToast("User updated")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		} finally {
			setIsSavingPoRequired(false)
		}
	}

	const onSaveCards = async () => {
		try {
			setIsSavingCards(true)
			await setCards({
				user: user.id.toString(),
				cards: cardsToShowFormValue,
			})
			makeSuccessToast("User updated")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		} finally {
			setIsSavingCards(false)
		}
	}

	return (
		<>
			<GridContainer>
				<GridItem md={12} lg={6}>
					<HeadingDivider headingVariant="h3" icon="usd-square" mt={0}>
						Checkout
					</HeadingDivider>
					<LabeledData
						label="Purchase order required at checkout?"
						editingRender={() => (
							<RadioBoolean
								htmlName="po-required"
								value={poRequiredFormValue}
								onChange={setPoRequiredFormValue}
								noFirst
								noText="No (default)"
							/>
						)}
						onSaveEdit={() => onSaveUser("poRequired", poRequiredFormValue)}
						isSavingEdit={isSavingPoRequired}
					>
						{user.poRequired ? "Yes" : "No (default)"}
					</LabeledData>

					<HeadingDivider headingVariant="h3" icon="home">
						Shop Homepage
					</HeadingDivider>
					<LabeledData
						label="Customize visible brand cards"
						onSaveEdit={onSaveCards}
						isLoading={userCardsLoading}
						isSavingEdit={isSavingCards}
						validateEdit={() => {
							const valid = cardsToShowFormValue.length > 0 || showAllCards
							if (!valid)
								setCardsToShowErrorText(
									"Choose at least one card. If you need zero cards displayed, please contact support."
								)
							return valid
						}}
						editingRender={() => (
							<>
								<RadioBoolean
									htmlName="all-cards"
									value={showAllCards}
									onChange={(newValue) => {
										if (newValue === true) {
											setShowAllCards(true)
											setCardsToShowFormValue([])
										} else {
											setShowAllCards(false)
											setCardsToShowFormValue(
												userCards?.map((c) => c.id) ?? []
											)
										}
										setCardsToShowErrorText(null)
									}}
									yesText="Show all cards (default)"
									noText="Choose which brand cards to display"
								/>
								{!showAllCards && (
									<AnimatedEntrance show direction="down" mt={-1} mb={1.5}>
										<ul css={brandListCss}>
											{(allCards ?? []).map((card) => (
												<li key={card.id}>
													<Checkbox
														mb={0}
														fillContainer={false}
														value={cardsToShowFormValue.includes(
															card.id
														)}
														onChange={() => {
															setCardsToShowErrorText(null)
															setCardsToShowFormValue((prev) =>
																toggleArrayItem(card.id, prev)
															)
														}}
														label={
															<span css={brandCheckboxLabel}>
																<Paragraph>{card.name}</Paragraph>
																<img
																	src={card.logoImageUrl}
																	alt={`Logo for ${card.name}`}
																	css={brandLogoCss}
																/>
															</span>
														}
													/>
												</li>
											))}
										</ul>
										{!!cardsToShowErrorText && (
											<ErrorText>{cardsToShowErrorText}</ErrorText>
										)}
									</AnimatedEntrance>
								)}
							</>
						)}
					>
						{userCards && userCards.length === 0 ?
							"Show all cards (default)"
						:	`Only show these brand cards: ${[
								...(userCards ?? []).map((c) => c.name),
							].join(", ")}`
						}
					</LabeledData>
				</GridItem>
			</GridContainer>
		</>
	)
}

const brandListCss = css`
	margin: 0 0 0 1.75rem;
	padding: 0;
	li {
		list-style-type: none;
	}
`
const brandCheckboxLabel = css`
	display: inline-flex;
	align-items: center;
	column-gap: 0.5rem;
	top: 0.1rem;
`
const brandLogoCss = css`
	height: 1rem;
`

import { FC, useState } from "react"

import { makeApiErrorMessage } from "@ncs/ncs-api"

import { Textarea, TextareaProps, TextInput, TextInputProps } from "../inputs"
import { Paragraph } from "../typography"
import { ExtendableModalProps, Modal } from "./Modal"

export interface EditStringModalProps extends ExtendableModalProps {
	onSave: (value: string | null) => void | Promise<void>
	label: string
	initialValue?: string | null
	/**
	 * @default false
	 */
	allowEmpty?: boolean
	/**
	 * @default null
	 */
	emptyValue?: string | null
	/**
	 * @default "Edit"
	 */
	title?: string
	placeholder?: string
	textInputProps?: Omit<TextInputProps, "value" | "onChange">
	/**
	 * @default "Save"
	 */
	saveButtonLabel?: string
	message?: string
	/**
	 * @default false
	 */
	textarea?: boolean
	textareaProps?: Omit<TextareaProps, "value" | "onChange">
	maxLength?: number
}

export const EditStringModal: FC<EditStringModalProps> = ({
	onSave,
	label,
	initialValue,
	allowEmpty = false,
	emptyValue = null,
	title = "Edit",
	placeholder,
	textInputProps,
	saveButtonLabel = "Save",
	message,
	textarea = false,
	textareaProps,
	maxLength,
	...rest
}) => {
	const [value, setValue] = useState<string | null>(initialValue ?? emptyValue ?? null)

	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const handleSave = async () => {
		try {
			if (!value && !allowEmpty) {
				throw new Error("Please enter a value")
			}

			setIsSaving(true)
			await onSave(value?.trim() || null)
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const reset = () => {
		setValue(null)
		setIsSaving(false)
		setErrorText(null)
	}

	return (
		<Modal
			errorText={errorText}
			title={title}
			onOpen={reset}
			{...rest}
			rightButtons={{
				buttonText: saveButtonLabel,
				onClick: handleSave,
				isLoading: isSaving,
				disabled: !value && !allowEmpty,
			}}
		>
			{!!message && <Paragraph mb={1}>{message}</Paragraph>}

			{textarea ?
				<Textarea
					fillContainer
					autoFocus
					{...textareaProps}
					label={label}
					placeholder={placeholder}
					value={value}
					onChange={(newValue) => {
						setValue(newValue || emptyValue)
					}}
					maxLength={maxLength}
				/>
			:	<TextInput
					fillContainer
					autoFocus
					{...textInputProps}
					label={label}
					placeholder={placeholder}
					onReturn={handleSave}
					value={value}
					onChange={(newValue) => {
						setValue(newValue || emptyValue)
					}}
					maxLength={maxLength}
				/>
			}
		</Modal>
	)
}

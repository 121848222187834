import { FC, useEffect, useState } from "react"

import {
	LineItemType,
	makeApiErrorMessage,
	PricingAdjustmentRequest,
	UpdatePricingAdjustmentRequestPatch,
	useAccessorials,
	useUpdatePricingAdjustmentForm,
	WorkOrderLineItemType,
} from "@ncs/ncs-api"
import { extractNumber, formatCurrency } from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	Box,
	EmptyValueDash,
	HeadingDivider,
	IconButton,
	NumericInput,
	Paragraph,
	RadioBoolean,
	useToast,
} from "@ncs/web-legos"

import { AdjustmentRequestRow } from "~/components"
import { priceAdjustmentPermissions } from "~/util"

export interface RequestDetailFreightProps {
	request: PricingAdjustmentRequest
}

export const RequestDetailFreight: FC<RequestDetailFreightProps> = ({ request }) => {
	const { makeSuccessToast, makeErrorToast } = useToast()
	const [accessorials] = useAccessorials()

	const [freeFreightForm, setFreeFreightForm] = useState<FreeFreightForm>(() =>
		makeFreeFreightFormState(request.freight)
	)
	const [minOrderAmountForm, setMinOrderAmountForm] = useState<number | null>(
		request.freight.minOrderAmount
	)
	const [chemicalSurchargeForm, setChemicalSurchargeForm] = useState(
		request.freight.chemicalFuelSurcharge
	)
	const [serviceSurchargeForm, setServiceSurchargeForm] = useState(
		request.freight.serviceFuelSurcharge
	)
	const [accessorialsForm, setAccessorialsForm] = useState<AccessorialFormState>()

	const updateRequest = useUpdatePricingAdjustmentForm()

	useEffect(() => {
		// When we have the results of the accessorials lookup, then we're ready to make the
		// state for the accessorials form.
		if (accessorials) {
			setAccessorialsForm(
				makeAccessorialsFormState(request.freight.accessorials, accessorials)
			)
		}
	}, [accessorials, request.freight.accessorials])

	const handleAccessorialPriceUpdate = (id: string, newPrice: number | undefined) => {
		setAccessorialsForm((prev) => {
			if (!prev) return prev

			return { ...prev, [id]: { ...prev[id], inputFieldPrice: newPrice ?? null } }
		})
	}

	const handleAccessorialStatusUpdate = (id: string, newStatus: boolean) => {
		setAccessorialsForm((prev) => {
			if (!prev) return prev

			return { ...prev, [id]: { ...prev[id], isBeingUsed: newStatus } }
		})
	}

	const handleSave = async (updates: UpdatePricingAdjustmentRequestPatch) => {
		try {
			await updateRequest({
				id: request.id,
				updates,
			})
			makeSuccessToast("Request form updated")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		}
	}

	const { noMoreEdits } = priceAdjustmentPermissions(request)

	const { freight } = request

	return (
		<div>
			<HeadingDivider variant="h5" icon="truck-fast">
				Freight Adjustments
			</HeadingDivider>

			<Box pl={1.5} pt={1}>
				<AdjustmentRequestRow
					name="Free freight deliveries per month"
					locked={noMoreEdits}
					isBeingUsedByForm={[freight.allOrdersFree, freight.ordersPerMonth].some(
						(v) => v != null
					)}
					currentData={{
						allOrdersFree: freight.currentAllOrdersFree,
						ordersPerMonth: freight.currentOrdersPerMonth,
					}}
					proposedData={{
						allOrdersFree: freight.allOrdersFree,
						ordersPerMonth: freight.ordersPerMonth,
					}}
					render={({ allOrdersFree, ordersPerMonth }) => (
						<Paragraph>
							{allOrdersFree ?
								"All orders free"
							: ordersPerMonth !== null ?
								`${ordersPerMonth} order${
									ordersPerMonth !== 1 ? "s" : ""
								} per month`
							:	<EmptyValueDash />}
						</Paragraph>
					)}
					editingRender={() => (
						<Box>
							<RadioBoolean
								htmlName="free-freight-type"
								description="Free freight for set number of orders per month or all orders?"
								value={freeFreightForm.allOrders}
								onChange={(value) =>
									setFreeFreightForm((prev) => ({
										...prev,
										ordersPerMonth: value ? null : prev.ordersPerMonth,
										allOrders: value,
									}))
								}
								yesText="All orders"
								noText="Set orders per month"
								noFirst
							/>
							<AnimatedEntrance show={freeFreightForm.allOrders === false}>
								<NumericInput
									value={freeFreightForm.ordersPerMonth}
									onChange={(value) =>
										setFreeFreightForm((prev) => ({
											...prev,
											ordersPerMonth: value || null,
										}))
									}
									fillContainer={false}
									decimalScale={0}
									placeholder="Orders per month..."
								/>
							</AnimatedEntrance>
						</Box>
					)}
					getEditErrors={() => {
						if (
							freeFreightForm.allOrders === null &&
							freeFreightForm.ordersPerMonth == null
						) {
							return "Please make a selection"
						}
						if (
							freeFreightForm.allOrders === false &&
							freeFreightForm.ordersPerMonth == null
						) {
							return "Enter number of orders per month"
						}

						return null
					}}
					onSave={() =>
						handleSave({
							allOrdersFree: freeFreightForm.allOrders,
							ordersPerMonth: freeFreightForm.ordersPerMonth,
						})
					}
					onRemove={() =>
						handleSave({
							allOrdersFree: null,
							ordersPerMonth: null,
						})
					}
					setEditState={() =>
						setFreeFreightForm(makeFreeFreightFormState(request.freight))
					}
				/>

				<AdjustmentRequestRow<number | null>
					name="Minimum order amount"
					locked={noMoreEdits}
					isBeingUsedByForm={freight.minOrderAmount != null}
					currentData={
						freight.currentMinOrderAmount != null ?
							extractNumber(freight.currentMinOrderAmount)
						:	null
					}
					proposedData={freight.minOrderAmount}
					render={(data) => (
						<Paragraph>
							{data != null ? formatCurrency(data) : <EmptyValueDash />}
						</Paragraph>
					)}
					editingRender={() => (
						<NumericInput
							value={minOrderAmountForm}
							onChange={(v) => setMinOrderAmountForm(v ?? null)}
							decimalScale={2}
							fixedDecimalScale
							fillContainer={false}
							placeholder="Order amount $..."
						/>
					)}
					getEditErrors={() => {
						return !minOrderAmountForm ? "Please enter an amount greater than 0" : null
					}}
					onSave={() => handleSave({ minOrderAmount: minOrderAmountForm })}
					onRemove={() => handleSave({ minOrderAmount: null })}
					setEditState={() => setMinOrderAmountForm(request.freight.minOrderAmount)}
				/>

				<AdjustmentRequestRow<boolean | null>
					name="Chemical fuel surcharge"
					locked={noMoreEdits}
					isBeingUsedByForm={freight.chemicalFuelSurcharge != null}
					currentData={freight.currentChemicalFuelSurcharge}
					proposedData={freight.chemicalFuelSurcharge}
					render={(data) =>
						data == null ?
							<EmptyValueDash />
						:	<Paragraph>
								{data ?
									"Chemical fuel surcharge is billable"
								:	"Chemical fuel surcharge is not billable"}
							</Paragraph>
					}
					editingRender={() => (
						<RadioBoolean
							htmlName="chemical-surcharge"
							value={chemicalSurchargeForm}
							onChange={setChemicalSurchargeForm}
							yesText="Chemical fuel surcharge billable"
							noText="Chemical fuel surcharge not billable"
						/>
					)}
					getEditErrors={() =>
						chemicalSurchargeForm == null ? "Please make a selection" : null
					}
					onSave={() => handleSave({ chemicalFuelSurcharge: chemicalSurchargeForm })}
					onRemove={() => handleSave({ chemicalFuelSurcharge: null })}
					setEditState={() =>
						setChemicalSurchargeForm(request.freight.chemicalFuelSurcharge)
					}
				/>

				<AdjustmentRequestRow<boolean | null>
					name="Service fuel surcharge"
					locked={noMoreEdits}
					isBeingUsedByForm={freight.serviceFuelSurcharge != null}
					currentData={freight.currentServiceFuelSurcharge}
					proposedData={freight.serviceFuelSurcharge}
					render={(data) =>
						data == null ?
							<EmptyValueDash />
						:	<Paragraph>
								{data ?
									"Service fuel surcharge is billable"
								:	"Service fuel surcharge is not billable"}
							</Paragraph>
					}
					editingRender={() => (
						<RadioBoolean
							htmlName="service-surcharge"
							value={serviceSurchargeForm}
							onChange={setServiceSurchargeForm}
							yesText="Service fuel surcharge billable"
							noText="Service fuel surcharge not billable"
						/>
					)}
					onSave={() => handleSave({ serviceFuelSurcharge: serviceSurchargeForm })}
					onRemove={() => handleSave({ serviceFuelSurcharge: null })}
					setEditState={() =>
						setServiceSurchargeForm(request.freight.serviceFuelSurcharge)
					}
				/>

				{!!accessorialsForm && !!accessorials && (
					<AdjustmentRequestRow<
						{
							id: LineItemType
							description: string
							price: number | null
						}[]
					>
						name="Accessorial rate overrides"
						locked={noMoreEdits}
						isBeingUsedByForm={freight.accessorials.length > 0}
						currentData={freight.accessorials.map((a) => ({
							id: a.lineTypeId,
							description: a.description,
							price: a.currentPrice,
						}))}
						proposedData={Object.values(accessorialsForm)
							.filter((a) => a.isBeingUsed)
							.map((a) => ({
								id: a.id,
								description: a.description,
								price: a.proposedPrice,
							}))}
						render={(data) => {
							return (
								<Box>
									{data.map((a) => {
										return (
											<Paragraph key={a.id}>
												{a.description}:{" "}
												{a.price != null ?
													`${formatCurrency(a.price)}`
												:	<EmptyValueDash />}
											</Paragraph>
										)
									})}
								</Box>
							)
						}}
						editingRender={() => (
							<Box d="flex" flexDirection="column" rowGap={0.25}>
								{Object.values(accessorialsForm).map((a) => {
									return (
										<Box
											key={a.id}
											d="flex"
											alignItems="center"
											columnGap={0.5}
										>
											<Paragraph opacity={a.isBeingUsed ? undefined : 0.5}>
												{a.description}
											</Paragraph>
											{a.isBeingUsed ?
												<>
													<NumericInput
														value={a.inputFieldPrice}
														width={5}
														fixedDecimalScale
														autoFocus
														decimalScale={2}
														placeholder="$"
														mb={0}
														onChange={(value) =>
															handleAccessorialPriceUpdate(
																a.id,
																value
															)
														}
													/>
													<IconButton
														icon="times"
														onClick={() =>
															handleAccessorialStatusUpdate(
																a.id,
																false
															)
														}
													/>
												</>
											:	<IconButton
													onClick={() =>
														handleAccessorialStatusUpdate(a.id, true)
													}
												/>
											}
										</Box>
									)
								})}
							</Box>
						)}
						getEditErrors={() => {
							if (
								Object.values(accessorialsForm).some(
									(a) => a.isBeingUsed && a.inputFieldPrice == null
								)
							) {
								return "Please enter a value for each accessorial being edited"
							}

							return null
						}}
						onSave={() =>
							handleSave({
								accessorials: Object.values(accessorialsForm).flatMap((a) => {
									// Null price fields should have already been prevented.
									if (a.inputFieldPrice == null || a.isBeingUsed === false) {
										return []
									}

									return [
										{
											lineTypeId: a.id,
											price: a.inputFieldPrice,
										},
									]
								}),
							})
						}
						onRemove={() => handleSave({ accessorials: [] })}
						setEditState={() =>
							setAccessorialsForm(
								makeAccessorialsFormState(
									request.freight.accessorials,
									accessorials
								)
							)
						}
					/>
				)}
			</Box>
		</div>
	)
}

type FreeFreightForm = {
	allOrders: boolean | null
	ordersPerMonth: number | null
}

const makeFreeFreightFormState = (
	freight: PricingAdjustmentRequest["freight"]
): FreeFreightForm => {
	return {
		allOrders: freight.allOrdersFree,
		ordersPerMonth: freight.ordersPerMonth,
	}
}

type AccessorialFormState = Record<
	string,
	{
		id: LineItemType
		inputFieldPrice: number | null
		proposedPrice: number | null
		currentCustomerPrice: number | null
		description: string
		isBeingUsed: boolean
	}
>

const makeAccessorialsFormState = (
	requestAccessorials: PricingAdjustmentRequest["freight"]["accessorials"],
	lookupResults: WorkOrderLineItemType[]
): AccessorialFormState => {
	return {
		...Object.fromEntries(
			lookupResults.map((line) => [
				line.id,
				{
					id: line.id,
					inputFieldPrice: null,
					proposedPrice: null,
					currentCustomerPrice: null,
					description: line.description,
					isBeingUsed: false,
				},
			])
		),
		...Object.fromEntries(
			requestAccessorials.flatMap((accessorial) => {
				return [
					[
						accessorial.lineTypeId,
						{
							id: accessorial.lineTypeId,
							inputFieldPrice: accessorial.price,
							proposedPrice: accessorial.price,
							currentCustomerPrice: accessorial.currentPrice,
							description: accessorial.description,
							isBeingUsed: true,
						},
					],
				]
			})
		),
	}
}

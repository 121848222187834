import React from "react"

import {
	Collapse,
	Drawer,
	Hidden,
	InputAdornment,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	withStyles,
} from "@material-ui/core"
import parse from "autosuggest-highlight/parse"
import cx from "classnames"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { NavLink, withRouter } from "react-router-dom"
import { bindActionCreators } from "redux"

import avatar from "@ncs/bricks/assets/img/default-avatar.png"
import sidebarStyle from "@ncs/bricks/assets/jss/material-dashboard-pro-react/components/sidebarStyle"
import { Input } from "@ncs/bricks/components"
import { getName } from "@ncs/bricks/redux/selectors/auth"
import { logout } from "@ncs/bricks/redux/services/auth"
import { match } from "@ncs/bricks/util/highlighter"

import { HeaderLinks } from "../../components"

import { PowerSettingsNew, Search } from "@material-ui/icons"

class SidebarWrapper extends React.Component {
	render() {
		const { className, user, headerLinks, links } = this.props
		return (
			<div className={className} ref="sidebarWrapper">
				{user}
				{headerLinks}
				{links}
			</div>
		)
	}
}

class Sidebar extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			openAvatar: false,
			miniActive: true,
			searchText: "",
			...this.getCollapseStates(props.routes),
		}
		this.activeRoute.bind(this)
	}

	mainPanel = React.createRef()

	// this creates the initial state of this component based on the collapse routes
	// that it gets through this.props.routes
	getCollapseStates = (routes) => {
		let initialState = {}
		routes.map((prop) => {
			if (prop.collapse) {
				initialState = {
					[prop.state]: this.getCollapseInitialState(prop.views),
					...this.getCollapseStates(prop.views),
					...initialState,
				}
			}
			return null
		})
		return initialState
	}

	// this verifies if any of the collapses should be default opened on a rerender of this component
	// for example, on the refresh of the page,
	getCollapseInitialState(routes) {
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
				return true
			} else if (this.activeRoute(routes[i])) {
				return true
			}
		}
		return false
	}

	// verifies if routeName is the one active (in browser input)
	activeRoute(route) {
		let currentPath = this.props.location.pathname
		return route.breadcrumbRegex ?
				route.breadcrumbRegex.test(currentPath)
			:	currentPath.indexOf(route.path) > -1
	}

	// Test if a route's route is exactly what the current pathname is.
	activeRouteExact(route) {
		const currentPath = this.props.location.pathname
		return route.path === currentPath
	}

	openCollapse(collapse) {
		let st = {}
		st[collapse] = !this.state[collapse]
		this.setState(st)
	}

	onSignOut = () => {
		// This logs out of the old Redux auth.
		this.props.logout()
		// Also clear the new auth system.
		if (this.props.newAuth) {
			this.props.newAuth.logout()
		}
		this.props.history.push("/")
	}

	handleSearchTextChange = (searchText) => {
		let newState = { searchText }

		if (!!searchText) {
			newState = this.props.routes
				.filter((x) => x.collapse && !x.hidden)
				.reduce(
					(obj, route) => ({
						...obj,
						[route.state]:
							this.viewContainsSearchText(route, searchText) ||
							route.views
								.filter((x) => !x.hidden)
								.some((view) => this.viewContainsSearchText(view, searchText)),
					}),
					newState
				)
		}

		this.setState(newState)
	}

	viewContainsSearchText = (view, searchText) => {
		let words = searchText.toLowerCase().split(" ")
		let name = view.name.toLowerCase()
		return words.every((word) => name.indexOf(word) >= 0)
	}

	handleRenderLinkText = (name) => {
		if (!this.state.searchText) {
			return name
		}

		const matches = match(name, this.state.searchText)
		const parts = parse(name, matches)

		return (
			<span>
				{parts.map((part, index) => {
					return part.highlight ?
							<span key={String(index)} style={{ fontWeight: 500, color: "green" }}>
								{part.text}
							</span>
						:	<strong key={String(index)} style={{ fontWeight: 300 }}>
								{part.text}
							</strong>
				})}
			</span>
		)
	}

	render() {
		const { classes, color, logo, image, logoText, routes, bgColor, name } = this.props

		const itemText =
			classes.itemText +
			" " +
			cx({
				[classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
			})
		const collapseItemText =
			classes.collapseItemText +
			" " +
			cx({
				[classes.collapseItemTextMini]: this.props.miniActive && this.state.miniActive,
			})
		const userWrapperClass =
			classes.user +
			" " +
			cx({
				[classes.whiteAfter]: bgColor === "white",
			})
		const itemIcon = classes.itemIcon
		const caret = classes.caret
		const collapseItemMini = classes.collapseItemMini
		const photo = classes.photo

		let user = (
			<div className={userWrapperClass}>
				<div className={photo}>
					<img src={avatar} className={classes.avatarImg} alt="..." />
				</div>
				<List className={classes.list}>
					<ListItem className={classes.item + " " + classes.userItem}>
						<NavLink
							to={"#"}
							className={classes.itemLink + " " + classes.userCollapseButton}
							onClick={() => this.openCollapse("openAvatar")}
						>
							<ListItemText
								primary={name}
								secondary={
									<b
										className={
											caret +
											" " +
											classes.userCaret +
											" " +
											(this.state.openAvatar ? classes.caretActive : "")
										}
									/>
								}
								disableTypography={true}
								className={itemText + " " + classes.userItemText}
							/>
						</NavLink>
						<Collapse in={this.state.openAvatar} unmountOnExit>
							<List className={classes.list + " " + classes.collapseList}>
								<ListItem className={classes.collapseItem}>
									<NavLink
										to="/profile"
										className={
											classes.itemLink + " " + classes.userCollapseLinks
										}
									>
										<span className={collapseItemMini}>{"MP"}</span>
										<ListItemText
											primary={"My Profile"}
											disableTypography={true}
											className={collapseItemText}
										/>
									</NavLink>
								</ListItem>
								<ListItem className={classes.collapseItem}>
									<NavLink
										to="#"
										onClick={this.onSignOut}
										className={
											classes.itemLink + " " + classes.userCollapseLinks
										}
									>
										<span className={collapseItemMini}>
											<PowerSettingsNew
												className={
													classes.headerLinksSvg + " " + classes.links
												}
											/>
										</span>
										<ListItemText
											primary={"Sign Out"}
											disableTypography={true}
											className={collapseItemText}
										/>
									</NavLink>
								</ListItem>
							</List>
						</Collapse>
					</ListItem>
				</List>
			</div>
		)

		let links = (
			<List className={classes.list}>
				<ListItem className={classes.item}>
					<div className={classes.searchBox}>
						<ListItemText
							primary={
								<Input
									inputProps={{
										placeholder: "Search menu...",
										startAdornment: (
											<InputAdornment position="start">
												<Search />
											</InputAdornment>
										),
									}}
									formControlProps={{ className: classes.searchBoxFormControl }}
									value={this.state.searchText}
									onChange={(e) => this.handleSearchTextChange(e.target.value)}
								/>
							}
							disableTypography={true}
							className={itemText}
						/>
					</div>
				</ListItem>

				{routes.map((prop, key) => {
					if (prop.redirect) {
						return null
					}
					if (prop.hidden) {
						return null
					}
					if (prop.collapse) {
						const navLinkClasses =
							classes.itemLink +
							" " +
							cx({
								[" " + classes.collapseActive]: this.activeRoute(prop),
							})

						return (
							<ListItem key={key} className={classes.item}>
								<NavLink
									to={"#"}
									className={navLinkClasses}
									onClick={(e) => {
										this.openCollapse(prop.state)

										// Don't navigate anywhere since you're just opening/closing a folder.
										e.preventDefault()
									}}
								>
									<ListItemIcon className={itemIcon}>
										<prop.icon />
									</ListItemIcon>
									<ListItemText
										primary={this.handleRenderLinkText(prop.name)}
										secondary={
											<b
												className={
													caret +
													" " +
													(this.state[prop.state] ?
														classes.caretActive
													:	"")
												}
											/>
										}
										disableTypography={true}
										className={itemText}
									/>
								</NavLink>
								<Collapse in={this.state[prop.state]} unmountOnExit>
									<List className={classes.list + " " + classes.collapseList}>
										{prop.views.map((prop, key) => {
											if (prop.redirect || prop.hidden) {
												return null
											}

											const isActiveRoute = this.activeRoute(prop)
											const isActiveRouteExact = this.activeRouteExact(prop)

											const navLinkClasses = classes.collapseItemLink
											const collapseItemMini = classes.collapseItemMini

											return (
												<ListItem
													key={key}
													className={classes.collapseItem}
												>
													<NavLink
														to={prop.path}
														className={navLinkClasses}
														onClick={(e) => {
															// If you're already on the exact page, don't do anything.
															if (isActiveRouteExact) {
																e.preventDefault()
															}
														}}
														style={{
															backgroundColor:
																isActiveRoute ?
																	"rgba(200, 200, 200, 0.2)"
																:	undefined,
														}}
													>
														<span className={collapseItemMini}>
															{prop.mini}
														</span>
														<ListItemText
															primary={this.handleRenderLinkText(
																prop.name
															)}
															disableTypography={true}
															className={collapseItemText}
															style={{
																fontWeight:
																	isActiveRoute ? "normal" : (
																		undefined
																	),
															}}
														/>
													</NavLink>
												</ListItem>
											)
										})}
									</List>
								</Collapse>
							</ListItem>
						)
					}
					const navLinkClasses =
						classes.itemLink +
						" " +
						cx({
							[" " + classes[color]]: this.activeRoute(prop.path),
						})

					return (
						<ListItem key={key} className={classes.item}>
							<NavLink to={prop.path} className={navLinkClasses}>
								<ListItemIcon className={itemIcon}>
									<prop.icon />
								</ListItemIcon>
								<ListItemText
									primary={this.handleRenderLinkText(prop.name)}
									disableTypography={true}
									className={itemText}
								/>
							</NavLink>
						</ListItem>
					)
				})}
			</List>
		)

		const logoNormal =
			classes.logoNormal +
			" " +
			cx({
				[classes.logoNormalSidebarMini]: this.props.miniActive && this.state.miniActive,
			})
		const logoMini = classes.logoMini
		const logoClasses =
			classes.logo +
			" " +
			cx({
				[classes.whiteAfter]: bgColor === "white",
			})
		let brand = (
			<div className={logoClasses}>
				<a href="https://www.ncswash.com" className={logoMini}>
					<img src={logo} alt="logo" className={classes.img} />
				</a>
				<a href="https://www.ncswash.com" className={logoNormal}>
					{logoText}
				</a>
			</div>
		)
		const drawerPaper =
			classes.drawerPaper +
			" " +
			cx({
				[classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive,
			})
		const sidebarWrapper =
			classes.sidebarWrapper +
			" " +
			cx({
				[classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive,
			})
		return (
			<div ref="mainPanel">
				<Hidden mdUp>
					<Drawer
						variant="temporary"
						anchor={"right"}
						open={this.props.open}
						classes={{
							paper: drawerPaper + " " + classes[bgColor + "Background"],
						}}
						onClose={this.props.handleDrawerToggle}
						ModalProps={{
							keepMounted: true, // Better open performance on mobile.
						}}
					>
						{brand}
						<SidebarWrapper
							className={sidebarWrapper}
							user={user}
							headerLinks={<HeaderLinks />}
							links={links}
						/>
						{image !== undefined ?
							<div
								className={classes.background}
								style={{ backgroundImage: "url(" + image + ")" }}
							/>
						:	null}
					</Drawer>
				</Hidden>
				<Hidden smDown>
					<Drawer
						onMouseOver={() => this.setState({ miniActive: false })}
						onMouseOut={() => this.setState({ miniActive: true })}
						anchor={"left"}
						variant="permanent"
						open
						classes={{
							paper: drawerPaper + " " + classes[bgColor + "Background"],
						}}
					>
						{brand}
						<SidebarWrapper className={sidebarWrapper} user={user} links={links} />
						{image !== undefined ?
							<div
								className={classes.background}
								style={{ backgroundImage: "url(" + image + ")" }}
							/>
						:	null}
					</Drawer>
				</Hidden>
			</div>
		)
	}
}

Sidebar.defaultProps = {
	bgColor: "blue",
}

Sidebar.propTypes = {
	classes: PropTypes.object.isRequired,
	bgColor: PropTypes.oneOf(["white", "black", "blue"]),
	color: PropTypes.oneOf(["white", "red", "orange", "green", "blue", "purple", "rose"]),
	logo: PropTypes.string,
	logoText: PropTypes.string,
	image: PropTypes.string,
	routes: PropTypes.arrayOf(PropTypes.object),
}

const mapStateToProps = ({ auth }) => {
	return {
		name: getName(auth),
	}
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ logout }, dispatch)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(withStyles(sidebarStyle)(Sidebar)))

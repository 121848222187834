import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { BaseSelectFilter, withDynamicTableFilter } from "@ncs/bricks/components"
import { loadLookups, LOOKUPS } from "../../redux/services/lookups"

const NCSVintageFilter = (props) => {
	const dispatch = useDispatch()
	const vintages = useSelector((store) => store.lookups.ncsEquipmentVintages)

	useEffect(() => {
		dispatch(loadLookups(LOOKUPS.NCSEquipmentVintages))
	}, [dispatch])

	return (
		<BaseSelectFilter
			id="vintages"
			labelText="Vintages"
			options={[{ _id: "", name: "All vintages" }, ...vintages]}
			valueAccessor="_id"
			textAccessor="name"
			{...props}
		/>
	)
}

export default React.memo(withDynamicTableFilter("vintage")(NCSVintageFilter))

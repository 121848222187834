import { FC } from "react"

import { useVacuumConfig } from "@ncs/ncs-api"
import { LoadingSpinner } from "@ncs/web-legos"

import { ConnectedVacuumConfigForm } from "./ConnectedVacuumConfigForm"

export interface ConfigTabProps {
	deviceId: string | null
}

export const ConfigTab: FC<ConfigTabProps> = ({ deviceId }) => {
	const [config, configLoading] = useVacuumConfig({
		params: {
			device: deviceId,
		},
	})

	if (configLoading) {
		return <LoadingSpinner />
	}

	return <ConnectedVacuumConfigForm config={config ?? null} />
}

import { useCallback, useRef, useState } from "react"

import { ReactStateDispatch } from "./types"

/**
 * Like `useState`, but with an extra function returned that resets state back to what
 * was passed in initially.
 */
export const useStateReset = <State>(
	initialState: State | (() => State)
): [State, ReactStateDispatch<State>, () => void] => {
	const initialStateRef = useRef<State>(
		initialState instanceof Function ? initialState() : initialState
	)
	const [state, setState] = useState<State>(initialStateRef.current)

	const resetState = useCallback(() => {
		setState(initialStateRef.current)
	}, [])

	return [state, setState, resetState]
}

import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import get from "lodash/get"
import isEqual from "lodash/isEqual"
import callApi from "@ncs/bricks/redux/services/callApi"
import { CollectionSearchSelector, Select } from "@ncs/bricks/components"
import { loadLookups, LOOKUPS } from "../../redux/services/lookups"

class LocationSelector extends React.PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			locations: [],
			location: null,
		}
	}

	componentDidMount() {
		this.props.loadLookups([LOOKUPS.Locations], this.filterLocations)
	}

	// filter locations whenever the territory changes
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.territoryId !== this.props.territoryId) {
			this.filterLocations()
		}

		if (!isEqual(this.props.defaultValue, prevProps.defaultValue)) {
			this.handleLocationChangedById(this.props.defaultValue?._id)
		}
	}

	filterLocations = () => {
		if (!this.props.territoryId) {
			this.setState({ locations: this.props.locations })
			return
		}

		let territoryId = Number(this.props.territoryId)

		let locations = this.props.locations.filter((x) => x.territory_id === territoryId)
		this.setState({ locations })

		// if the currently selected location is not in the results, erase the selected location
		if (
			this.state.location &&
			!locations.some((t) => t._id.toString() === this.state.location._id.toString())
		) {
			this.handleLocationChanged(null)
		}
	}

	handleLocationChangedById = (locationId) => {
		let location = this.props.locations.find(
			(x) => x._id.toString() === locationId?.toString()
		)

		this.handleLocationChanged(location)
	}

	handleLocationChanged = (location) => {
		this.setState({ location })
		this.props.onLocationSelected(location)
	}

	render() {
		const { displayEmpty, placeholder, labelText, defaultValue, ...rest } = this.props

		return this.state.locations.length <= 100 ?
				<Select
					id="location"
					labelText={labelText}
					value={get(this.state.location, "_id", "")}
					options={[
						{ _id: "", description: "Select a location...", disabled: true },
						...this.state.locations,
					]}
					valueAccessor="_id"
					textAccessor={(x) =>
						x._id ? `${x.description} (${x.location_code})` : x.description
					}
					onChange={this.handleLocationChangedById}
					labelProps={{
						shrink: displayEmpty ? true : undefined,
						...rest.labelProps,
					}}
					selectProps={{
						displayEmpty: displayEmpty,
						...rest.selectProps,
					}}
					{...rest}
				/>
			:	<CollectionSearchSelector
					labelText={labelText}
					placeholder={placeholder}
					collection={this.state.locations}
					selectedSuggestion={this.state.location || defaultValue}
					valueAccessor={(x) =>
						x.location_code ? `(${x.location_code}) ${x.description}` : x.description
					}
					onSelected={this.handleLocationChanged}
				/>
	}
}

LocationSelector.defaultProps = {
	displayEmpty: true,
	labelText: "Location",
	placeholder: "Search for a location",
}

LocationSelector.propTypes = {
	onLocationSelected: PropTypes.func.isRequired,
	territoryId: PropTypes.number,
	displayEmpty: PropTypes.bool,
}

const mapStateToProps = (state) => {
	return {
		locations: state.lookups.locations,
	}
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ callApi, loadLookups }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(LocationSelector)

import { Dispatch, FC, SetStateAction } from "react"

import { BillOfMaterial, UpdateBillOfMaterialPatch, useUpdateBillOfMaterials } from "@ncs/ncs-api"
import { useToast } from "@ncs/web-legos"

import { MixRecipeModalTab } from "./MixRecipeModal"
import { RecipeForm } from "./RecipeForm"

export interface MixRecipeModalSettingsTabProps {
	bom: BillOfMaterial
	setTab: Dispatch<SetStateAction<MixRecipeModalTab>>
}

export const MixRecipeModalSettingsTab: FC<MixRecipeModalSettingsTabProps> = ({ bom, setTab }) => {
	const { makeSuccessToast } = useToast()
	const updateBom = useUpdateBillOfMaterials()

	const onSave = async (data: UpdateBillOfMaterialPatch) => {
		await updateBom({
			id: bom.id,
			updates: data,
		})
		makeSuccessToast("Recipe updated")
		setTab(MixRecipeModalTab.MixRecipe)
	}

	const onCancel = () => {
		setTab(MixRecipeModalTab.MixRecipe)
	}

	return (
		<>
			<RecipeForm bom={bom} handleUpdate={onSave} handleCancel={onCancel} />
		</>
	)
}

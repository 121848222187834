import React from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { BaseSelectFilter, withDynamicTableFilter } from "@ncs/bricks/components"
import { loadLookups, LOOKUPS } from "../../redux/services/lookups"

class VintageFilter extends React.PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			vintages: [],
		}
	}

	componentDidMount() {
		this.props.loadLookups(LOOKUPS.EquipmentVintages)
	}

	render() {
		return (
			<BaseSelectFilter
				id="vintages"
				labelText="Vintage"
				options={[{ id: "", name: "All Vintages" }, ...this.props.vintages]}
				valueAccessor={(x) => (x._id || "").toString()}
				textAccessor="name"
				{...this.props}
			/>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		vintages: state.lookups.equipmentVintages,
	}
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ loadLookups }, dispatch)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withDynamicTableFilter("vintage")(VintageFilter))

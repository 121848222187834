import { FC, useMemo } from "react"

import { PortalReport, SchedulerDashboardData } from "@ncs/ncs-api"
import { camelToSnake, formatNumber, maxChars, titleCase } from "@ncs/ts-utils"
import { Card, Heading, StackedBarChart } from "@ncs/web-legos"

import { LineItemsDashboardConfig } from "../LineItemsDashboard"

export interface LineItemsGraphProps {
	warehousesData: SchedulerDashboardData
	config: LineItemsDashboardConfig
}

export const LineItemsGraph: FC<LineItemsGraphProps> = ({ warehousesData, config }) => {
	const scrollIntoView = (warehouseName: string) => {
		const element = document.getElementById(warehouseName)
		if (element) {
			element.scrollIntoView({ behavior: "smooth" })
		}
	}

	const report = useMemo(() => makePortalReport(warehousesData), [warehousesData])

	const includedKeys = useMemo(() => {
		return Object.entries(config)
			.filter(([, value]) => value)
			.map(([key]) => titleCase(key))
	}, [config])

	return (
		<Card mb={1.5}>
			<Heading mb={2} variant="h3">
				Unassigned <strong>{includedKeys.join(", ")}</strong> Order Line Items
			</Heading>
			<StackedBarChart
				onBarClick={({ payload }) => {
					scrollIntoView(payload.warehouseName as string)
				}}
				report={report}
				xAxisInterval={0}
				height={325}
				chartMargins={{
					left: 0,
					bottom: 75,
				}}
				yAxisDomain={[0, "auto"]}
				xAxisTick={({ x, y, payload }) => (
					<g transform={`translate(${x},${y + 5})`} width={500} height={500}>
						<text textAnchor="end" transform="rotate(-35)" opacity={0.65}>
							{maxChars(payload.value, 15)}
						</text>
					</g>
				)}
				keysToExclude={Object.entries(config)
					.filter(([, value]) => !value)
					.map(([lineItemType]) => `${lineItemType}LineItems`)}
				withLegend={includedKeys.length > 1}
				tooltipFormatter={(value, name) => {
					return [formatNumber(value), titleCase(camelToSnake(name).split("_")[0])] as [
						string,
						string,
					]
				}}
				gridProps={{
					horizontal: true,
					vertical: false,
				}}
			/>
		</Card>
	)
}

const makePortalReport = (warehousesData: SchedulerDashboardData): PortalReport => {
	return {
		xKey: "warehouseName",
		xLabel: "Warehouse",
		yLabel: "Line items unassigned",
		data: warehousesData.map((data) => ({
			warehouseName: data.warehouseName,
			chemicalLineItems: data.unscheduledOrders.chemical.lines,
			detailLineItems: data.unscheduledOrders.detail.lines,
			partsLineItems: data.unscheduledOrders.parts.lines,
		})),
		dataKeys: {
			chemicalLineItems: "Chemical",
			detailLineItems: "Detail",
			partsLineItems: "Parts",
		},
	}
}

import React, { Component } from "react"
import { withCallApi } from "~/components"
import { withRouter } from "react-router-dom"

import { editOffHours } from "@ncs/mortar/redux/services/users"

//local components
import EditOffHoursEvent from "./EditOffHoursEvent"

class EditOffHoursEventContainer extends Component {
	constructor(props) {
		super(props)

		this.state = {
			selectedTechAss: [],
			startDate: null,
			endDate: null,
			selectedTech: null,
		}
	}
	componentDidMount = () => {
		this.setState({
			selectedTechAss: this.props.event.groups,
			startDate: this.props.event.startDate,
			endDate: this.props.event.endDate,
			selectedTech: {
				_id: this.props.event.techId,
				name: this.props.event.title,
			},
		})
	}
	handleSetInputRef = (input) => {
		this.input = input
	}

	handleEventSave = (active = true) => {
		if (!this.state.selectedTech) {
			return
		}
		if (!this.state.startDate || !this.state.endDate) {
			return
		}
		let e = this.props.event
		let eventUpdate = {
			event: {
				id: e.id,
				techId: this.state.selectedTech._id,
				startTime: this.state.startDate,
				endTime: this.state.endDate,
				active: active ? true : false,
				groups: this.state.selectedTechAss,
			},
		}

		this.props.callApi(editOffHours(eventUpdate)).then((result) => {
			if (result.error) {
				return this.setState({ error: result.error })
			} else {
				this.setState({
					success: true,
					newCommentMessage: "Event Saved.",
				})
				this.props.onEventSave()
			}
		})
	}
	handleDeleteEvent = () => {
		this.handleEventSave(false)
	}
	handleTechAssSelected = (selectedTechAss) => {
		this.setState({
			selectedTechAss,
		})
	}
	handleTechSelected = (selectedTech) => {
		this.setState({ selectedTech })
	}
	handleStartDateChange = (d) => {
		//let converted = (d instanceof moment) ? d.format("YYYY-MM-DD") : d
		this.setState({ startDate: d.format() })
	}
	handleEndDateChange = (d) => {
		this.setState({ endDate: d.format() })
	}
	render() {
		const { isSaving } = this.state
		return (
			<EditOffHoursEvent
				event={this.props.event}
				setInputRef={this.handleSetInputRef}
				isSaving={isSaving}
				onTechSelected={this.handleTechSelected}
				selectedTech={this.state.selectedTech}
				onStartDateChange={this.handleStartDateChange}
				startDate={this.state.startDate}
				onEndDateChange={this.handleEndDateChange}
				endDate={this.state.endDate}
				selectedTechAss={this.state.selectedTechAss}
				onTechAssSelected={this.handleTechAssSelected}
				techAssignments={this.props.techAssignments}
				onEventSave={this.handleEventSave}
				onCancelEvent={this.props.onCancelEvent}
				onDeleteEvent={this.handleDeleteEvent}
			/>
		)
	}
}

export default withRouter(withCallApi(EditOffHoursEventContainer))

import React from "react"
import { createStyles, makeStyles } from "@material-ui/core"

const styles = createStyles({
	clearfix: {
		"&:after,&:before": {
			display: "table",
			content: '" "',
		},
		"&:after": {
			clear: "both",
		},
	},
})

const useStyles = makeStyles(styles)

const Clearfix = () => {
	const classes = useStyles()

	return <div className={classes.clearfix} />
}

export default Clearfix

import { FC, memo, useEffect, useState } from "react"

import { useThrottleCallback } from "@react-hook/throttle"
import { useFormContext } from "react-hook-form"

import { InventoryPart, PosPart, usePosPart } from "@ncs/ncs-api"
import {
	AnimatedEntrance,
	Box,
	Callout,
	encodeUrlState,
	ErrorText,
	LabeledData,
	Link,
	NumericInputFormField,
	PartSelector,
	Textarea,
	TextInputFormField,
	useChangeCallback,
} from "@ncs/web-legos"

import {
	PartManagementTab,
	PartManagementUrlState,
} from "~/views/Inventory/PartManagement/PartManagement"

import { PosPartForm } from "./pos-products-utils"

export interface PosPartDetailsProps {
	part: PosPart | null
}

export const PosPartDetails: FC<PosPartDetailsProps> = memo(({ part }) => {
	// Sometimes the name is computed using the description, so for performance
	// reasons we'll keep a local proxy state here. Avoids using `watch` for
	// typed text input, which is slow AF.
	const [localDescription, setLocalDescription] = useState<string | null>(
		part?.description ?? null
	)

	// Make sure that we stay locally up to date if the part in question changes somehow.
	useChangeCallback(part?.description ?? null, (newPartDescription) => {
		if (localDescription !== newPartDescription) {
			setLocalDescription(newPartDescription)
		}
	})

	const {
		watch,
		setValue,
		getValues,
		control,
		formState: { errors, submitCount },
	} = useFormContext<PosPartForm>()

	const [relationship, parentPartId] = watch(["relationship", "parentPartId"])

	// Fetch the full parent part form the ID that's in the form.
	const [parentPart] = usePosPart(parentPartId, {
		queryConfig: { refetchOnWindowFocus: true },
	})

	// Store the selected inventory part and keep its ID in the form.
	const [selectedInventoryPart, setSelectedInventoryPart] = useState<InventoryPart | null>(null)
	useChangeCallback(selectedInventoryPart, (newPart) => {
		// When you select an inventory part from the inventory part selector,
		// update the form and autofill some values.
		if (getValues("inventoryPartId") !== newPart?.id) {
			setValue("inventoryPartId", newPart?.id ?? null, {
				shouldValidate: true,
			})
			setValue("posPartNumber", newPart?.partNumber ?? null, {
				shouldValidate: true,
			})
			setValue("price", newPart?.listPrice ?? null, {
				shouldValidate: true,
			})
		}
	})

	const throttledFormUpdate = useThrottleCallback(
		() => {
			// Send up our local description.
			setValue("description", localDescription ?? "", {
				shouldValidate: true,
			})
			// And if it's a child, we need to set the name as well based on this.
			if (relationship === "child") {
				if (localDescription && parentPart?.title) {
					setValue("name", `${parentPart.title} [${localDescription}]`, {
						shouldValidate: true,
					})
				} else {
					setValue("name", "", {
						shouldValidate: true,
					})
				}
			}
		},
		2,
		true
	)

	// Throttle-y keep the form values up to date.
	useEffect(throttledFormUpdate, [
		relationship,
		localDescription,
		throttledFormUpdate,
		parentPart?.title,
	])

	return (
		<>
			{relationship === "child" ?
				<LabeledData label="Child name (parent description + variant option label)">
					{parentPart?.title || "(Parent name will go here)"}
					{localDescription ? ` [${localDescription}]` : ""}
				</LabeledData>
			:	<TextInputFormField control={control} name="name" label="Product name" />}
			<Textarea
				value={localDescription}
				onChange={setLocalDescription}
				error={submitCount > 0 ? errors?.description?.message : undefined}
				placeholder={relationship === "child" ? "Eg., 15 Gallon" : "Description..."}
				label={relationship === "child" ? "Variant option label" : "Description"}
			/>
			<AnimatedEntrance show={relationship !== "parent"}>
				<PartSelector
					label="Inventory part"
					value={selectedInventoryPart}
					onChange={setSelectedInventoryPart}
					initialPartId={part?.part?.id}
					skipRestrictedCheck
				/>
				{!!errors.inventoryPartId?.message && submitCount > 0 && (
					<ErrorText mt={-0.75}>{errors.inventoryPartId.message}</ErrorText>
				)}
				{selectedInventoryPart?.restricted && (
					<Callout icon="exclamation-triangle" mt={0.5} mb={3} variant="warning">
						This inventory part is currently set to restricted! Customers will not be
						able to see it unless they are specifically allowed to. Note that this is a
						separate setting than the user's part catalog. Manage this setting in{" "}
						<Link
							to={`/inventory/part-management/${encodeUrlState<PartManagementUrlState>(
								{
									tab: PartManagementTab.Restrictions,
									partId: selectedInventoryPart.id,
								}
							)}`}
							newTab
							icon="external-link"
							underline
						>
							Part Management
						</Link>
						.
					</Callout>
				)}
				<Box maxWidth="50%" xsProps={{ maxWidth: "none" }}>
					<TextInputFormField
						control={control}
						name="posPartNumber"
						label="Point of sale product number"
					/>
					<NumericInputFormField
						control={control}
						name="price"
						label="Price $"
						decimalScale={2}
						fixedDecimalScale
					/>
				</Box>
			</AnimatedEntrance>
		</>
	)
})

PosPartDetails.displayName = "PosPartDetails"

import { FC, useCallback, useMemo, useState } from "react"

import { css } from "@emotion/react"
import { saveAs } from "file-saver"
import { Column } from "react-table"

import {
	BinManagementBin,
	LabelSize,
	makeApiErrorMessage,
	useBinManagementBins,
	useGetAllLocationBins,
	usePrintBinLabels,
} from "@ncs/ncs-api"
import {
	AnimatedEntrance,
	Box,
	Button,
	ButtonProps,
	Chip,
	ExtendableModalProps,
	GridContainer,
	GridItem,
	LabelSizeSelector,
	LoadingSpinner,
	Modal,
	Paragraph,
	Table,
	ThrottledTextInput,
	useScreenSizeMatch,
	useToast,
} from "@ncs/web-legos"

import { binLabelsBatchSize } from "../../../locations-util"

export interface PrintBinLabelsModalProps extends ExtendableModalProps {
	locationId: string
}

export const PrintBinLabelsModal: FC<PrintBinLabelsModalProps> = ({
	locationId,
	onClose,
	...rest
}) => {
	const screenIsSm = useScreenSizeMatch("sm")
	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)
	const { makeSuccessToast } = useToast()

	const [size, setSize] = useState<LabelSize>("3x2")
	const [binStrategy, setBinStrategy] = useState<"all" | "choose" | null>(null)
	const [selectedBins, setSelectedBins] = useState<BinManagementBin[]>([])
	const [searchQuery, setSearchQuery] = useState<string | null>(null)

	// For batch processing of All Bins.
	const [currentBatch, setCurrentBatch] = useState<number | null>(null)
	const [batchesCount, setBatchesCount] = useState<number | null>(null)

	const getLabelPdf = usePrintBinLabels()

	// Separate call here to get all the bins at once.
	const getBins = useGetAllLocationBins()

	const binsQuery = useBinManagementBins({
		params: {
			location: locationId,
			search: searchQuery,
			isActive: true,
		},
	})

	const addBinSelection = (binToAdd: BinManagementBin): void => {
		setSelectedBins((prev) => [...prev.filter((b) => b.id !== binToAdd.id), binToAdd])
	}

	const removeBinSelection = (binToRemove: BinManagementBin): void => {
		setSelectedBins((prev) => prev.filter((b) => b.id !== binToRemove.id))
	}

	const handleSubmit = useCallback(async () => {
		try {
			setIsSaving(true)

			if (binStrategy === "all") {
				// Query for all the bins at the location.
				const { data: allBins } = await getBins(locationId)
				const allBinIds = allBins
					.filter((b) => b.is_active)
					.sort((a, b) => (a.code.toLowerCase() > b.code.toLowerCase() ? 1 : -1))
					.map((b) => b._id.toString())

				// Split all bins into batches.
				const batches: string[][] = []
				let count = Math.min(binLabelsBatchSize, allBinIds.length)

				while (allBinIds.length) {
					batches.push(allBinIds.splice(0, count))
					count = Math.min(binLabelsBatchSize, allBinIds.length)
				}

				setBatchesCount(batches.length)

				for (let i = 1; i <= batches.length; i += 1) {
					setCurrentBatch(i)
					const { data } = await getLabelPdf({
						location: locationId,
						allBins: false,
						bins: batches[i - 1], // zero-based
						size,
					})
					saveAs(new Blob([data]), `labels_${size}_batch_${i}.pdf`)
				}
			} else {
				const { data } = await getLabelPdf({
					location: locationId,
					allBins: false,
					bins: selectedBins.map((b) => b.id),
					size,
				})
				saveAs(new Blob([data]), `labels_${size}.pdf`)
			}
			makeSuccessToast("Labels created")
			onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}, [
		makeSuccessToast,
		onClose,
		binStrategy,
		getLabelPdf,
		locationId,
		selectedBins,
		size,
		getBins,
	])

	const leftButton = useMemo((): ButtonProps | undefined => {
		return binStrategy ?
				{
					buttonText: "Back",
					icon: "long-arrow-left",
					variant: "text",
					onClick: () => setBinStrategy(null),
				}
			:	undefined
	}, [binStrategy])

	const rightButton = useMemo((): ButtonProps | undefined => {
		return (
			binStrategy === "choose" ?
				{
					buttonText: `Download Labels (${selectedBins.length} bins)`,
					disabled: selectedBins.length < 1,
					onClick: handleSubmit,
					isLoading: isSaving,
				}
			: binStrategy === "all" ?
				{
					buttonText: "Download Labels (All bins)",
					onClick: handleSubmit,
					isLoading: isSaving,
				}
			:	undefined
		)
	}, [binStrategy, selectedBins, handleSubmit, isSaving])

	return (
		<Modal
			{...rest}
			onClose={onClose}
			errorText={errorText}
			title="Print Bin Labels"
			titleDetail={binStrategy ? `${size} labels` : ""}
			closeButtonText="cancel"
			maxWidth={binStrategy === "choose" ? "md" : "sm"}
			leftButtons={leftButton}
			rightButtons={rightButton}
		>
			{binStrategy == null && (
				<>
					<LabelSizeSelector value={size} onChange={setSize} radio />

					<Paragraph mt={2} mb={1.5}>
						Do you want to print all bins at this location, or select specific ones?
					</Paragraph>

					<GridContainer gap={1}>
						<GridItem xs={12} sm={6}>
							<Button
								variant="secondary-cta"
								fillContainer
								onClick={() => {
									setSearchQuery(null)
									setBinStrategy("all")
								}}
							>
								All Bins
							</Button>
						</GridItem>
						<GridItem xs={12} sm={6}>
							<Button
								variant="secondary-cta"
								fillContainer
								onClick={() => setBinStrategy("choose")}
							>
								Choose Bins
							</Button>
						</GridItem>
					</GridContainer>
				</>
			)}

			{binStrategy === "all" && (
				<>
					<Paragraph mb={1}>
						Generate a PDF of bin labels for all active bins at this location?
						Depending on the number of bins, the request will be processed in batches
						of {binLabelsBatchSize}.
					</Paragraph>
				</>
			)}

			{binStrategy === "choose" && (
				<>
					<GridContainer>
						<GridItem
							order={screenIsSm ? 2 : undefined}
							sm={12}
							md={6}
							br={1}
							pr={2}
							smProps={{
								pr: 0,
								br: 0,
							}}
						>
							<ThrottledTextInput
								value={searchQuery}
								onChange={setSearchQuery}
								label="Search"
								icon="search"
								clearable
							/>

							<div css={binTableContainerCss}>
								<Table
									query={binsQuery}
									columns={columns}
									disableAllSorting
									infiniteRowsIncrement={100}
									onRowClick={(row) => addBinSelection(row.original)}
								/>
							</div>
						</GridItem>
						<GridItem
							order={screenIsSm ? 1 : undefined}
							sm={12}
							md={6}
							pl={!screenIsSm ? 2 : undefined}
						>
							<Paragraph mb={1}>
								Search for active bins at this location and click to add to your
								label list:
							</Paragraph>

							{!selectedBins.length && (
								<Paragraph small color="secondary">
									(No bins selected yet)
								</Paragraph>
							)}

							<Box display="flex" flexWrap="wrap" gap={0.5}>
								{selectedBins.map((b) => (
									<AnimatedEntrance key={b.id} show>
										<Chip
											label={b.code}
											onDelete={() => removeBinSelection(b)}
										/>
									</AnimatedEntrance>
								))}
							</Box>
						</GridItem>
					</GridContainer>
				</>
			)}
			{isSaving && !!batchesCount && (
				<div css={processingPopupCss}>
					<Paragraph mb={2}>
						Processing batch {currentBatch} of {batchesCount}...
					</Paragraph>

					<LoadingSpinner />
				</div>
			)}
		</Modal>
	)
}

const processingPopupCss = css`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	position: absolute;
	z-index: 99999;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(255, 255, 255, 0.9);
`

const columns: Column<BinManagementBin>[] = [
	{
		Header: "Bin code",
		accessor: "code",
	},
]

const binTableContainerCss = css`
	height: 40rem;
	overflow-y: auto;
	overflow-x: hidden;
`

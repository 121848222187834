import { Dispatch, ReactElement, SetStateAction } from "react"

import { SiteSurveyStatusSelector } from "../selectors"

export interface SiteSurveyStatusParamState {
	isSubmitted: boolean | null
}

export interface SiteSurveyStatusQueryFilterProps<
	QueryParamState extends SiteSurveyStatusParamState,
> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

export const SiteSurveyStatusQueryFilter = <QueryParamState extends SiteSurveyStatusParamState>({
	queryParamState,
	setQueryParamState,
}: SiteSurveyStatusQueryFilterProps<QueryParamState>): ReactElement => {
	return (
		<SiteSurveyStatusSelector
			disableNoSelectionOption={false}
			value={queryParamState.isSubmitted ?? null}
			fillContainer
			onChange={(newValue) =>
				setQueryParamState((prev) => {
					const newSiteSurveyStatusParamState: SiteSurveyStatusParamState = {
						isSubmitted: newValue ?? null,
					}

					return {
						...prev,
						...newSiteSurveyStatusParamState,
					}
				})
			}
		/>
	)
}

import React from "react"
import PropTypes from "prop-types"
import { SearchSelector, withCallApi } from "@ncs/bricks/components"
import { listParts } from "../../redux/services/pointOfSale"

class OnlinePartSelector extends React.Component {
	handleGetSuggestions = (search) => {
		// note: SearchSelector handles debouncing for us
		return this.props.callApi(
			listParts({
				search,
				pageSize: 15,
			})
		)
	}

	handleGetSuggestionValue = (part) => {
		return `${part.online_part_number}: ${part.description}`
	}

	handleSuggestionSelected = (event, { suggestion }) => {
		this.props.onPartSelected(suggestion)
	}

	render = () => (
		<SearchSelector
			label="Online Part"
			placeholder="Search for an online part"
			getSuggestions={this.handleGetSuggestions}
			getSuggestionValue={this.handleGetSuggestionValue}
			onSuggestionSelected={this.handleSuggestionSelected}
			{...this.props}
		/>
	)
}

OnlinePartSelector.propTypes = {
	callApi: PropTypes.func.isRequired,
	onPartSelected: PropTypes.func.isRequired,
}

export default withCallApi(OnlinePartSelector)

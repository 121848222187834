import { FC, useMemo, useState } from "react"

import dayjs from "dayjs"

import {
	Customer,
	FreightGroupId,
	FreightRate,
	makeApiErrorMessage,
	useCreateFreightRate,
	useUpdateFreightRate,
} from "@ncs/ncs-api"
import { isEnumMember } from "@ncs/ts-utils"
import {
	Box,
	CustomerSelector,
	DateInput,
	ExtendableModalProps,
	Modal,
	NumericInput,
	useIsSaving,
	useToast,
} from "@ncs/web-legos"

import { FreightGroupSelector } from "~/components"

export interface EditRateModalProps extends ExtendableModalProps {
	toEdit: FreightRate | null
}

export const EditRateModal: FC<EditRateModalProps> = ({ toEdit, ...rest }) => {
	const { makeSuccessToast } = useToast()
	const [rate, setRate] = useState(toEdit?.rate ?? null)
	const [min, setMin] = useState(toEdit?.baseAmount ?? null)
	const [startDate, setStartDate] = useState(toEdit?.startDate ? dayjs(toEdit.startDate) : null)
	const [endDate, setEndDate] = useState(toEdit?.endDate ? dayjs(toEdit.endDate) : null)
	const [customer, setCustomer] = useState<Customer | null>(null)
	const [freightGroupId, setFreightGroupId] = useState<FreightGroupId | null>(() => {
		const id = toEdit?.freightProductGroupId?.toString()
		return isEnumMember(id, FreightGroupId) ? id : null
	})
	const { isSaving, setSaving, endSaving } = useIsSaving()
	const [errorText, setErrorText] = useState<string | null>(null)

	const createRate = useCreateFreightRate()
	const updateRate = useUpdateFreightRate()

	const alreadyStarted = useMemo(() => {
		return !!toEdit && dayjs(toEdit.startDate).startOf("d").isBefore(dayjs())
	}, [toEdit])

	const handleSave = async () => {
		try {
			if (rate == null) throw new Error("Enter rate")
			if (min == null) throw new Error("Enter order minimum")
			if (!startDate || !endDate) throw new Error("Start and end date are required")
			if (endDate.isBefore(startDate)) throw new Error("End date must be after start date")

			setSaving()

			if (toEdit) {
				await updateRate({
					updates: {
						rateId: toEdit.id,
						rate: rate !== toEdit.rate ? rate : undefined,
						baseAmount: min !== toEdit.baseAmount ? min : undefined,
						startDate:
							startDate.isSame(toEdit.startDate) === false ?
								startDate.toISOString()
							:	undefined,
						endDate:
							endDate.isSame(toEdit.endDate) === false ?
								endDate.toISOString()
							:	undefined,

						// Can't change, but need them to know which rate model to use the ID with.
						customerId: toEdit.customerId,
						freightProductGroupId: toEdit.freightProductGroupId,
					},
				})
				makeSuccessToast("Freight rate updated")
			} else {
				await createRate({
					rate,
					baseAmount: min,
					startDate: startDate.toISOString(),
					endDate: endDate.toISOString(),
					customerId: customer?.id ?? null,
					freightProductGroupId: freightGroupId,
				})
				makeSuccessToast("Freight rate created")
			}
			rest.onClose()
		} catch (e) {
			endSaving()
			setErrorText(makeApiErrorMessage(e))
		}
	}

	return (
		<Modal
			{...rest}
			title={toEdit ? "Edit Freight Rate" : "New Freight Rate"}
			errorText={errorText}
			rightButtons={{
				buttonText: toEdit ? "Save changes" : "Create",
				onClick: handleSave,
				isLoading: isSaving(),
			}}
		>
			<NumericInput
				value={rate}
				onChange={(v) => setRate(v ?? null)}
				decimalScale={2}
				fixedDecimalScale
				label="Freight rate $"
				disabled={alreadyStarted}
			/>
			<NumericInput
				value={min}
				onChange={(v) => setMin(v ?? null)}
				decimalScale={2}
				fixedDecimalScale
				label="Order minimum $ to qualify"
				disabled={alreadyStarted}
			/>
			<CustomerSelector
				value={customer}
				onChange={setCustomer}
				initialCustomerId={toEdit?.customerId}
				label="Limit to a customer (optional)"
				disabled={!!toEdit}
				accountActive={null}
			/>
			<FreightGroupSelector
				value={freightGroupId}
				onChange={setFreightGroupId}
				label="Limit to a product group (optional)"
				disableNoSelectionOption={false}
				fillContainer
				disabled={!!toEdit}
			/>
			<Box d="flex" gap={1}>
				<DateInput
					value={startDate}
					onChange={setStartDate}
					label="Start date"
					disablePast
					disabled={alreadyStarted}
				/>
				<DateInput value={endDate} onChange={setEndDate} label="End date" disablePast />
			</Box>
		</Modal>
	)
}

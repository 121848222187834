import { FC, useCallback, useMemo, useState } from "react"

import { css, Theme } from "@emotion/react"
import { Cell, Column } from "react-table"

import {
	BulkReceivePartOrderPost,
	GenericLineItem,
	LineItemPriceOverride,
	lineItemsAreEqual,
	lineTypesRepeatable,
	makeApiErrorMessage,
	PartOrder,
	PartOrderLineItem,
	PartOrderStatusId,
	useAddPartOrderLineItem,
	useAuth,
	useBulkReceivePartOrder,
	useCreateLinePriceOverrideRequest,
	useDeletePartOrderLineItem,
	usePriceOverrideReasons,
	useUpdatePartOrderLineItem,
} from "@ncs/ncs-api"
import { extractNumber, formatCurrency, formatNumber } from "@ncs/ts-utils"
import {
	Box,
	Button,
	ConfirmationModal,
	ConfirmationModalConfig,
	cssMixins,
	Heading,
	Icon,
	IconButton,
	LetterIcon,
	Link,
	Paragraph,
	Table,
	TableProps,
	Tooltip,
	useScreenSizeMatch,
	useToast,
} from "@ncs/web-legos"

import {
	LineItemEditorModal,
	PriceOverridesModal,
	ReceivePartsModal,
	ReceivePartsModalProps,
	SaveLineItemExtras,
} from "~/components"

import {
	getLineItemRowQuantities,
	getLocationShortage,
	isLineItemDropShipRow,
	isLineItemLocationRow,
	isLineItemParentRow,
	partOrderLineToPriceOverrides,
	UnspecifiedLineItemRow,
	usePartOrderLineItemToLineItem,
	usePartOrderPermissions,
} from "../../part-order-detail-util"
import {
	CancelDropShipModal,
	PartOrderLineTransitDetailsModal,
	RerouteLineItemModal,
} from "./components"

export interface PartOrderDetailLineItemsProps {
	order: PartOrder
}

export const PartOrderDetailLineItems: FC<PartOrderDetailLineItemsProps> = ({ order }) => {
	const { user } = useAuth()
	const screenIsXs = useScreenSizeMatch("xs")
	const { makeSuccessToast, makeErrorToast } = useToast()

	const [showReceiveModal, setShowReceiveModal] = useState(false)
	const [showNewLineItemModal, setShowNewLineItemModal] = useState(false)
	const [transitModalLineItem, setTransitModalLineItem] = useState<PartOrderLineItem | null>(
		null
	)
	const [lineItemToEdit, setLineItemToEdit] = useState<PartOrderLineItem | null>(null)
	const [cancelDropShipLineItem, setCancelDropShipLineItem] = useState<PartOrderLineItem | null>(
		null
	)
	const [rerouteModalLineItem, setRerouteModalLineItem] = useState<PartOrderLineItem | null>(
		null
	)
	const [rerouteModalLocationId, setRerouteModalLocationId] = useState<string | null>(null)
	const [overrideModalLineId, setOverrideModalLineId] = useState<string | null>(null)
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)

	const partOrderLineItemToLineItem = usePartOrderLineItemToLineItem()
	const addLineItem = useAddPartOrderLineItem()
	const updateLineItem = useUpdatePartOrderLineItem()
	const deleteLineItem = useDeletePartOrderLineItem()
	const receiveParts = useBulkReceivePartOrder()
	const [, overrideReasonsLoading] = usePriceOverrideReasons()
	const requestPriceOverride = useCreateLinePriceOverrideRequest()
	const orderStatusAllowedEdit =
		order?.status.id === PartOrderStatusId.Closed ||
		order?.status.id === PartOrderStatusId.PaymentFailed
	const { canReceive, canAddParts, canAddNonParts, canEditLineItems, canApproveInvoice } =
		usePartOrderPermissions(order)
	const onLineItemSave = async (
		line: GenericLineItem,
		{ isEdit, isOverrideRequest }: SaveLineItemExtras
	) => {
		// If the only thing the user did was request a price override, we'll call a different
		// endpoint for that and not the regular line item update.
		if (
			lineItemToEdit &&
			isOverrideRequest &&
			line.requestedPrice != null &&
			line.priceOverrideReason
		) {
			try {
				await requestPriceOverride({
					partOrderLineId: lineItemToEdit.id,
					unitPrice: line.requestedPrice,
					reasonId: line.priceOverrideReason?.id,
					reasonComment: line.reasonComment,
				})
				makeSuccessToast("Price override request submitted")
			} catch (e) {
				makeErrorToast(makeApiErrorMessage(e))
			}
			// Currently we'll stop after handling the override request. If we end up in a situation
			// where we need to first do the override request, and then continue on and do some other
			// save operation on the line item, this will have to be revisited.
			return
		}

		// If any of the current line items are the same type as the new one, then unless
		// it's one of the types that can have repeats, show an error and stop.
		if (
			!isEdit &&
			order.lineItems.some(
				(currentLine) =>
					currentLine.lineTypeId === line.lineTypeId &&
					lineTypesRepeatable.includes(line.lineTypeId) === false
			)
		) {
			makeErrorToast(
				"Line item type is already on order. Try editing or removing the existing line first."
			)
			return
		}

		// If you're trying to add a part that's already on the order, we need to prevent this.
		// It's the same problem that requires us to prevent editing quantities on existing lines.
		if (
			!isEdit &&
			order.lineItems.some((existingLine) => {
				return lineItemsAreEqual(line, partOrderLineItemToLineItem(existingLine))
			})
		) {
			makeErrorToast(
				"Item exists on order already. If you need to change the quantity, try editing the current line or removing it from the order first and then adding again at your desired total quantity."
			)
			return
		}

		try {
			if (!isEdit) {
				await addLineItem({
					description: line.description,
					lineTypeId: line.lineTypeId,
					partId: line.part?.id ?? null,
					partOrderId: order.id,
					quantity: line.quantity,
					reasonId: line.priceOverrideReason?.id ?? null,
					unitPrice: line.finalPrice.toFixed(2),
					unitTax: (line.finalPrice * line.taxRate).toFixed(4),
					unitTaxRate: line.taxRate.toFixed(7),
				})
				makeSuccessToast("Line item added")
			} else {
				await updateLineItem({
					updates: {
						description: line.description,
						partId: line.part?.id ?? null,
						quantity: line.quantity,
						reasonId: line.priceOverrideReason?.id ?? null,
						unitPrice: line.finalPrice.toFixed(2),
						unitTax: (line.finalPrice * line.taxRate).toFixed(4),
						unitTaxRate: line.taxRate.toFixed(7),
					},
					id: line.id,
				})
				makeSuccessToast("Line item updated")
			}
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		}
	}

	const onLineItemRemove = useCallback(
		async (lineId: string) => {
			try {
				await deleteLineItem({ id: lineId })
				makeSuccessToast("Line item removed")
			} catch (e) {
				makeErrorToast(makeApiErrorMessage(e))
			}
		},
		[deleteLineItem, makeSuccessToast, makeErrorToast]
	)

	const recalculateLineItem = useCallback(
		async (lineItem: PartOrderLineItem) => {
			try {
				// You should only be recalculating if there is a location on the line item.
				const locationId = lineItem.locationLines[0]?.locationId ?? null

				await updateLineItem({
					id: lineItem.id,
					updates: {
						recalc: true,
						oldWarehouseId: locationId,
					},
				})
			} catch (e) {
				makeErrorToast(makeApiErrorMessage(e))
			}
		},
		[makeErrorToast, updateLineItem]
	)

	const onRerouteSuccess = async (partOrderLineItem: PartOrderLineItem) => {
		await recalculateLineItem(partOrderLineItem)
		setRerouteModalLineItem(null)
	}

	const onReceiveParts: ReceivePartsModalProps["partReceiveHandler"] = async (
		locationId,
		lines
	) => {
		const submission: BulkReceivePartOrderPost = {
			partOrderId: order.id,
			locationId,
			lines,
		}

		await receiveParts(submission) // Try/catch handled in the modal we pass this into.
	}

	const lineItems = useMemo(() => {
		return order.lineItems.sort((a, b) => {
			if ((!!a.part && !!b.part) || (!a.part && !b.part)) {
				return 0
			}
			return a.part ? -1 : 1
		})
	}, [order.lineItems])

	const tableColumns = useMemo((): Column<UnspecifiedLineItemRow>[] => {
		return [
			{
				Header: "Part #",
				id: "part-number",
				Cell: ({ row: { original } }: Cell<UnspecifiedLineItemRow>) => {
					if (isLineItemParentRow(original)) {
						return (
							<Box d="flex" alignItems="flex-start" gap={0.5}>
								<span css={cssMixins.noWrap}>
									{original.part?.partNumber || null}
								</span>
								{!!original.part?.hazmat && (
									<Tooltip title="Part is hazmat">
										<LetterIcon letter="H" />
									</Tooltip>
								)}
							</Box>
						)
					} else {
						return ""
					}
				},
			},
			{
				Header: "Name",
				id: "name",
				Cell: ({ row: { original } }: Cell<UnspecifiedLineItemRow>) => {
					if (isLineItemParentRow(original)) {
						return original.part?.description || original.description || ""
					} else {
						return (
							<Box display="flex" alignItems="center" gap={0.5}>
								<Icon icon="arrow-turn-down-right" color="gray" />
								{isLineItemLocationRow(original) && (
									<>
										{original.locationName}
										{original.stockItem && (
											<Tooltip title="Part is stock at location">
												<LetterIcon letter="S" />
											</Tooltip>
										)}
									</>
								)}
								{isLineItemDropShipRow(original) && (
									<span css={cssMixins.noWrap}>
										<Link
											to={`/purchase-orders/${original.purchaseOrder}`}
											icon="external-link"
											newTab
										>
											Purchase Order # {original.purchaseOrder}
										</Link>
									</span>
								)}
							</Box>
						)
					}
				},
			},
			{
				Header: "UoM",
				headingTooltip: "Unit of measure",
				id: "unit-of-measure",
				Cell: ({ row: { original } }: Cell<UnspecifiedLineItemRow>) => {
					if (isLineItemParentRow(original)) {
						return (
							<Box d="flex" alignItems="flex-start" gap={0.5}>
								<span css={cssMixins.noWrap}>{original.part?.uom || null}</span>
							</Box>
						)
					} else {
						return ""
					}
				},
			},
			{
				Header: "Qty ordered",
				headingTooltip: "Amount initially ordered",
				id: "qty-ordered",
				Cell: ({ row: { original } }: Cell<UnspecifiedLineItemRow>) => {
					if (isLineItemParentRow(original)) {
						const { quantityOrdered } = getLineItemRowQuantities(original)
						return formatNumber(quantityOrdered)
					}
					if (isLineItemLocationRow(original)) {
						return formatNumber(original.quantityRequested, { zeroString: "-" })
					}
					return formatNumber(original.quantity)
				},
			},
			{
				Header: "Total qty available",
				headingTooltip: "Total amount available at location",
				id: "total-available",
				hiddenByDefault: true,
				Cell: ({ row: { original } }: Cell<UnspecifiedLineItemRow>) => {
					if (isLineItemParentRow(original)) {
						const { quantityCurrentlyAvailable } = getLineItemRowQuantities(original)
						return formatNumber(quantityCurrentlyAvailable, { zeroString: "-" })
					}
					if (isLineItemLocationRow(original)) {
						return formatNumber(original.quantityAvailable, { zeroString: "-" })
					}
					return ""
				},
			},
			{
				Header: "Qty pickable",
				headingTooltip:
					"Amount from what was ordered that is available to be put into a picklist",
				id: "pickable",
				Cell: ({ row: { original } }: Cell<UnspecifiedLineItemRow>) => {
					if (isLineItemParentRow(original)) {
						const { quantityPickable } = getLineItemRowQuantities(original)
						return formatNumber(quantityPickable, { zeroString: "-" })
					}
					if (isLineItemLocationRow(original)) {
						return formatNumber(
							Math.min(original.quantityAvailable, original.quantityRequested),
							{ zeroString: "-" }
						)
					}
					return ""
				},
			},
			{
				Header: "Qty picking",
				headingTooltip: "Amount currently in a pick list",
				id: "picking",
				Cell: ({ row: { original } }: Cell<UnspecifiedLineItemRow>) => {
					if (isLineItemParentRow(original)) {
						const { quantityPicking } = getLineItemRowQuantities(original)
						return formatNumber(quantityPicking, { zeroString: "-" })
					}
					if (isLineItemLocationRow(original)) {
						return formatNumber(original.quantityPicking, { zeroString: "-" })
					}
					return ""
				},
			},
			{
				Header: "Qty shippable",
				headingTooltip: "Amount that is in shipment(s) waiting to be shipped",
				id: "shippable",
				Cell: ({ row: { original } }: Cell<UnspecifiedLineItemRow>) => {
					if (isLineItemParentRow(original)) {
						const { quantityShippable } = getLineItemRowQuantities(original)
						return formatNumber(quantityShippable, { zeroString: "-" })
					}
					if (isLineItemLocationRow(original)) {
						return formatNumber(original.quantityReadyToShip, { zeroString: "-" })
					}
					return ""
				},
			},
			{
				Header: "Qty shipped",
				headingTooltip: "Amount that is in shipments that have been shipped",
				id: "shipped",
				Cell: ({ row: { original } }: Cell<UnspecifiedLineItemRow>) => {
					if (isLineItemParentRow(original)) {
						const { quantityShipped } = getLineItemRowQuantities(original)
						return formatNumber(quantityShipped, { zeroString: "-" })
					}
					return ""
				},
			},
			{
				Header: "Qty drop shipping",
				headingTooltip: "Amount that is being drop shipped from the supplier",
				id: "drop-shipped",
				Cell: ({ row: { original } }: Cell<UnspecifiedLineItemRow>) => {
					if (isLineItemParentRow(original)) {
						const { quantityDropShipped } = getLineItemRowQuantities(original)
						return formatNumber(quantityDropShipped, { zeroString: "-" })
					}
					if (isLineItemDropShipRow(original)) {
						return formatNumber(original.quantity, { zeroString: "-" })
					}
					return ""
				},
			},
			{
				Header: "Qty received",
				headingTooltip: "Amount that has been received into inventory",
				id: "received",
				Cell: ({ row: { original } }: Cell<UnspecifiedLineItemRow>) => {
					if (isLineItemParentRow(original)) {
						const { quantityReceived } = getLineItemRowQuantities(original)
						return formatNumber(quantityReceived, { zeroString: "-" })
					}
					return ""
				},
			},
			{
				Header: "Qty short",
				headingTooltip: "Amount the location cannot cover",
				id: "qty-short",
				Cell: ({ row: { original } }: Cell<UnspecifiedLineItemRow>) => {
					if (isLineItemParentRow(original)) {
						const { quantityShort } = getLineItemRowQuantities(original)

						return quantityShort ?
								<span css={redShortageTextCss}>{formatNumber(quantityShort)}</span>
							:	"-"
					}
					if (isLineItemLocationRow(original)) {
						const shortage = getLocationShortage(original)

						if (shortage != null) {
							return (
								<Button
									trailingIcon="window-maximize"
									onClick={() => {
										const lineItem = lineItems.find(
											(l) => l.id === original.parentLineItemId
										)

										if (lineItem) {
											setRerouteModalLineItem(lineItem)
											setRerouteModalLocationId(original.locationId)
										}
									}}
								>
									{shortage}
								</Button>
							)
						}
					}
					return ""
				},
			},
			{
				Header: "Unit price",
				Cell: ({ row: { original } }: Cell<UnspecifiedLineItemRow>) => {
					if (isLineItemParentRow(original)) {
						return (
							<Box d="flex" gap={0.5}>
								{formatCurrency(original.unitPrice)}
								{!!original.approvalsList?.some((a) => a.pendingApproval) && (
									<Tooltip title="Price override pending approval">
										<Box height={1} mt={-0.45}>
											<IconButton
												icon="user-lock"
												color="primary"
												onClick={() => setOverrideModalLineId(original.id)}
												stopPropagation
											/>
										</Box>
									</Tooltip>
								)}
							</Box>
						)
					}

					return null
				},
			},
			{
				Header: "Unit tax",
				hiddenByDefault: true,
				accessor: (original) =>
					isLineItemParentRow(original) ? formatCurrency(original.unitTax) : "",
			},
		]
	}, [lineItems])

	const rowMenu = useMemo(() => {
		const menuItems: TableProps<UnspecifiedLineItemRow>["rowMenu"] = []

		if (canEditLineItems || canApproveInvoice) {
			menuItems.push(
				{
					label: "Edit",
					iconName: "pencil",
					disabledAccessor: () => overrideReasonsLoading,
					onClick: ({ original }) => {
						if (isLineItemParentRow(original)) {
							setLineItemToEdit(original)
						}
					},
				},
				{
					label: "Reroute / drop ship",
					iconName: "shuffle",
					hiddenAccessor: ({ original }) => {
						return !(
							isLineItemParentRow(original) && original.locationLines.length > 0
						)
					},
					onClick: ({ original }) => {
						if (isLineItemParentRow(original)) {
							setRerouteModalLineItem(original)
						}
					},
				},
				{
					label: "Recalculate",
					iconName: "calculator",
					onClick: ({ original }) => {
						if (isLineItemParentRow(original)) {
							setConfirmationConfig({
								title: "Recalculate line item",
								message:
									"Recalculate line item allocations? This audits the line item's original ordered quantity against the quantities in the various states and add/removes demands as needed.",
								onConfirm: async () => await recalculateLineItem(original),
							})
						}
					},
					hiddenAccessor: ({ original }) => {
						if (isLineItemParentRow(original)) {
							return !original.part
						}
						return true
					},
				}
			)
		}

		menuItems.push(
			{
				label: "Transit details",
				iconName: "shipping-fast",
				hiddenAccessor: ({ original }) => {
					if (isLineItemParentRow(original)) {
						return original.inTransits.length === 0
					}
					return false
				},
				onClick: ({ original }) => {
					if (isLineItemParentRow(original)) {
						setTransitModalLineItem(original)
					}
				},
			},
			{
				label: "View price overrides",
				iconName: "user-lock",
				hiddenAccessor: ({ original }) => {
					if (isLineItemParentRow(original)) {
						return !original.approvalsList?.length
					}
					return true
				},
				onClick: ({ original }) => {
					if (isLineItemParentRow(original)) {
						setOverrideModalLineId(original.id)
					}
				},
			}
		)

		if (canEditLineItems) {
			menuItems.push(
				{
					label: "Cancel drop ship",
					iconName: "times",
					hiddenAccessor: ({ original }) => {
						return (
							isLineItemParentRow(original) &&
							original.quantityDropShipped.length === 0
						)
					},
					onClick: ({ original }) => {
						if (isLineItemParentRow(original)) {
							setCancelDropShipLineItem(original)
						}
					},
				},
				{
					label: "Remove",
					iconName: "trash",
					onClick: ({ original }) => {
						if (isLineItemParentRow(original)) {
							setConfirmationConfig({
								title: "Remove Line Item",
								message: (
									<>
										Remove{" "}
										<strong>
											{original.description || original.part?.description}{" "}
										</strong>
										from this order?
									</>
								),
								onConfirm: async () => {
									await onLineItemRemove(original.id)
								},
							})
						}
					},
					hiddenAccessor: ({ original }) => {
						if (!isLineItemParentRow(original)) return true
						if (original.overridePermission) {
							// If the user does not have permission, they definitely cannot remove the line.
							if (!user?.apps?.includes(original.overridePermission.description))
								return true
						}
						return false
					},
				}
			)
		}

		return menuItems
	}, [
		overrideReasonsLoading,
		onLineItemRemove,
		canEditLineItems,
		canApproveInvoice,
		recalculateLineItem,
		user?.apps,
	])

	const currentWeightTotal = useMemo(() => {
		return order.lineItems.reduce((total, line) => {
			return total + extractNumber(line.part?.weight ?? 0) * extractNumber(line.quantity)
		}, 0)
	}, [order.lineItems])

	const overridesForModal = useMemo((): LineItemPriceOverride[] => {
		if (!overrideModalLineId) return []

		const line = order.lineItems.find((l) => l.id === overrideModalLineId)

		if (!line) return []

		return partOrderLineToPriceOverrides({
			line,
			partOrderId: order.id,
			partOrderOrderId: order.orderId.toString(),
			customerName: order.shipToCustomer.name,
			customerNumber: order.shipToCustomer.customerNumber,
		})
	}, [
		overrideModalLineId,
		order.id,
		order.orderId,
		order.lineItems,
		order.shipToCustomer.name,
		order.shipToCustomer.customerNumber,
	])

	return (
		<>
			<Heading icon="list" variant="h2">
				Line Items
			</Heading>

			<Box ml={1} pl={1.5}>
				<Box
					display="flex"
					alignItems="center"
					gap={1}
					mt={2}
					smProps={{ flexDirection: "column", mb: 1 }}
				>
					{canReceive && (
						<Button
							buttonText="Receive Items"
							variant="primary-cta"
							icon="scanner-gun"
							onClick={() => setShowReceiveModal(true)}
							fillContainer={screenIsXs}
						/>
					)}
					{(canAddParts || canAddNonParts) && (
						<>
							<Button
								buttonText="Add line item"
								variant="secondary-cta"
								icon="plus-circle"
								onClick={() => setShowNewLineItemModal(true)}
								fillContainer={screenIsXs}
								disabled={orderStatusAllowedEdit}
							/>
							{(!canAddParts || orderStatusAllowedEdit) && (
								<Tooltip
									title={
										orderStatusAllowedEdit ? "Order is Closed."
										: !canAddParts ?
											"All parts on the order have been put into pick lists, therefore no new parts can be added. Other line item changes are still allowed."
										:	""
									}
								>
									<Icon icon="lock" color="gray" />
									<Paragraph small secondary display="inline-block" ml={0.5}>
										No new parts
									</Paragraph>
								</Tooltip>
							)}
						</>
					)}
				</Box>

				<Table
					data={lineItems}
					columns={tableColumns}
					getSubRows={(original) =>
						isLineItemParentRow(original) ?
							[
								// Splice in the line item ID so we have access to it from the location row.
								...original.locationLines.map((locationLine) => ({
									...locationLine,
									parentLineItemId: original.id,
								})),
								...original.quantityDropShipped,
							]
						:	[]
					}
					rowMenu={rowMenu}
					expandAllRowsButton
					disableAllSorting
				/>
			</Box>

			{showReceiveModal && (
				<ReceivePartsModal
					partOrderId={order.id}
					titleDetail={`Part Order #${order.orderId}`}
					lineItems={order.lineItems}
					onClose={() => setShowReceiveModal(false)}
					partReceiveHandler={onReceiveParts}
				/>
			)}
			{(showNewLineItemModal || !!lineItemToEdit) && (
				<LineItemEditorModal
					lineItemToEdit={
						lineItemToEdit ? partOrderLineItemToLineItem(lineItemToEdit) : null
					}
					shipToId={order.shipToCustomer?.id ?? null}
					billToId={order.billToCustomer?.id ?? null}
					onSave={onLineItemSave}
					onRemove={(line) => {
						if (line.id) {
							void onLineItemRemove(line.id)
						}
					}}
					onClose={() => {
						setShowNewLineItemModal(false)
						setLineItemToEdit(null)
					}}
					currentWeightTotal={currentWeightTotal}
				/>
			)}
			{!!transitModalLineItem && (
				<PartOrderLineTransitDetailsModal
					lineItem={transitModalLineItem}
					onClose={() => setTransitModalLineItem(null)}
				/>
			)}
			{!!cancelDropShipLineItem && (
				<CancelDropShipModal
					lineItem={cancelDropShipLineItem}
					onClose={() => setCancelDropShipLineItem(null)}
				/>
			)}
			{!!rerouteModalLineItem && (
				<RerouteLineItemModal
					lineItem={rerouteModalLineItem}
					shortageLocationId={rerouteModalLocationId}
					orderIsOnHold={order.status.id === PartOrderStatusId.Hold}
					onClose={() => setRerouteModalLineItem(null)}
					onRerouteSuccess={() => onRerouteSuccess(rerouteModalLineItem)}
				/>
			)}
			{!!overridesForModal.length && (
				<PriceOverridesModal
					overrides={overridesForModal}
					onClose={() => setOverrideModalLineId(null)}
					closeAfterSubmission
				/>
			)}
			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</>
	)
}

const redShortageTextCss = ({ palette }: Theme) => css`
	font-weight: bold;
	color: ${palette.error.main};
`

import React from "react"
import PropTypes from "prop-types"
import NumberFormat, { NumberFormatProps, NumberFormatValues } from "react-number-format"
import { Input } from "../../components"

export interface CustomNumericInputProps extends Omit<NumberFormatProps, "onChange"> {
	/** receives a numeric parameter representing the input amount and the event object */
	onChange?: (newValue?: number) => void
	/** receives an object with formattedValue, value, and floatValue properties, along with the event object.
	 * The floatValue property is what is returned in onChange, which should be used most of the time */
	onValueChange: NumberFormatProps["onValueChange"]
	value?: number | string
	min?: number
	max?: number
}

const CustomNumericInput: React.FC<CustomNumericInputProps> = React.memo(
	({
		onValueChange,
		onChange, // swallow this prop
		value,
		min,
		max,
		thousandSeparator = true,
		displayType = "input",
		isNumericString = false,
		...rest
	}) => {
		function handleValueChange(values: NumberFormatValues) {
			const { floatValue } = values

			// Swallow meaningless updates, otherwise bugs are caused with codependent number fields updating each other.
			if (floatValue === value) {
				return
			}

			// onChange from NumberFormatProps takes the full event, but our implementation
			// will just take the value.
			if (typeof onChange === "function") {
				onChange(floatValue)
			}
		}

		function handleIsAllowed(values: NumberFormatValues) {
			const { floatValue } = values

			// If float value is undefined then user just cleared the input field. Allow this.
			if (floatValue === undefined) {
				return true
			}

			// Otherwise, do our normal checks against the min / max.
			return (
				(typeof min === "undefined" || floatValue >= min) &&
				(typeof max === "undefined" || floatValue <= max)
			)
		}

		return (
			<NumberFormat
				isNumericString={value ? typeof value === "string" : isNumericString}
				thousandSeparator={thousandSeparator}
				displayType={displayType}
				{...rest}
				onValueChange={onValueChange || handleValueChange}
				customInput={Input}
				value={value}
				isAllowed={handleIsAllowed}
			/>
		)
	}
)

CustomNumericInput.propTypes = {
	/** receives a numeric parameter representing the input amount and the event object */
	onChange: PropTypes.func,
	/** receives an object with formattedValue, value, and floatValue properties, along with the event object.
	 * The floatValue property is what is returned in onChange, which should be used most of the time */
	onValueChange: PropTypes.func,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	min: PropTypes.number,
	max: PropTypes.number,
}

export default CustomNumericInput

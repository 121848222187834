import React, { useMemo } from "react"

import dayjs, { Dayjs } from "dayjs"

import { DateFormat } from "@ncs/ts-utils"

import { DateInput } from "../inputs"

interface EndDateParamState {
	endDate: string | null
}

export interface EndDateQueryFilterProps<QueryParamState extends EndDateParamState> {
	queryParamState: QueryParamState
	setQueryParamState: React.Dispatch<React.SetStateAction<QueryParamState>>
	dateFormat?: DateFormat
	label?: string
}

export const EndDateQueryFilter = <QueryParamState extends EndDateParamState>({
	queryParamState,
	setQueryParamState,
	dateFormat = DateFormat.DateQueryParam,
	label = "End date",
}: EndDateQueryFilterProps<QueryParamState>): React.ReactElement => {
	// Take the raw string value that's from query param sate and turn it
	// into a DayJs for the date picker.
	const value = useMemo(
		() => (queryParamState.endDate ? dayjs(queryParamState.endDate) : null),
		[queryParamState.endDate]
	)

	const handleChange = (newDate: Dayjs | null) => {
		setQueryParamState((prev) => ({
			...prev,
			// Convert the DayJs object into a plain string for use in URL to backend.
			endDate: newDate ? dayjs(newDate).endOf("day").format(dateFormat) : null,
		}))
	}

	return <DateInput value={value} label={label} onChange={handleChange} fillContainer />
}

import { FC } from "react"

import { Card, Icon, Tabs, useUrlState } from "@ncs/web-legos"

import { CreateInventoriesTab, ViewInventoriesTab } from "./components"

export type PhysicalInventoryUrlState = {
	tab: PhysicalInventoryTab | null
}

export enum PhysicalInventoryTab {
	ViewInventories = "View Inventories",
	CreateInventory = "Create Inventory Request",
}

export const PhysicalInventory: FC = () => {
	const [{ tab }, { updateUrlValue }] = useUrlState<PhysicalInventoryUrlState>({
		tab: null,
	})

	return (
		<Card heading="Physical Inventory" headingIcon="calculator">
			<Tabs
				currentTab={tab || PhysicalInventoryTab.ViewInventories}
				onChange={(newTab) => updateUrlValue("tab", newTab)}
				panels={[
					{
						navLabel: PhysicalInventoryTab.ViewInventories,
						navIcon: <Icon icon="list" />,
						component: <ViewInventoriesTab />,
					},
					{
						navLabel: PhysicalInventoryTab.CreateInventory,
						navIcon: <Icon icon="plus" />,
						component: <CreateInventoriesTab />,
					},
				]}
			/>
		</Card>
	)
}

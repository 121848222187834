import sortBy from "lodash/sortBy"

import { del, get, patch, post, RSAA, rsaaTypes } from "@ncs/bricks/middleware/portalApiMiddleware"

export const LIST_CUSTOMERS = rsaaTypes("customers/list")
export const LIST_MIN_CUSTOMERS = rsaaTypes("customers/min/list")
export const EXPORT_CUSTOMERS = rsaaTypes("customers/export")
export const EXPORT_MIN_CUSTOMERS = rsaaTypes("customers/min/export")
export const GET_CUSTOMER = rsaaTypes("customer/get")
export const PATCH_CUSTOMER = rsaaTypes("customer/patch")
export const LIST_CUSTOMER_INVOICES = rsaaTypes("customer/invoices/list")
export const LIST_CUSTOMER_EQUIPMENT_REPAIR_PROPOSALS = rsaaTypes(
	"customer/equipment_repair_proposals/list"
)
export const LIST_CUSTOMER_EQUIPMENT = rsaaTypes("customer/equipment/list")
export const GET_CUSTOMER_EQUIPMENT = rsaaTypes("customer/equipment/get")
export const PATCH_CUSTOMER_EQUIPMENT = rsaaTypes("customer/equipment/patch")
export const PATCH_CUSTOMER_EQUIPMENT_CONTRACT = rsaaTypes("customer/equipment/contracts/patch")
export const LIST_CUSTOMER_BILL_TOS = rsaaTypes("customer/bill_tos/list")
export const GET_DEFAULT_CUSTOMER_BILL_TO = rsaaTypes("customer/bill_tos/list/parseDefault")
export const GET_CUSTOMER_PREFERENCES = rsaaTypes("customer/preferences/get")
export const PATCH_CUSTOMER_PREFERENCES = rsaaTypes("customer/preferences/patch")
export const SEARCH_CUSTOMER = rsaaTypes("customers/search")
export const SET_SELECTED_CUSTOMER = "customers/setSelected"
export const CREATE_CUSTOMER = rsaaTypes("customer/create")
export const GET_CUSTOMER_MAP_FIELDS = rsaaTypes("customers/map_page/get")
export const GET_CUSTOMER_MAP = rsaaTypes("customers/map/get")
export const SWAP_KBM_MANAGED = rsaaTypes("customers/swap_kbm_managed")
export const GET_CHEM_TYPES = rsaaTypes("customers/chem/types")
export const SUBMIT_CHEM_FORM = rsaaTypes("customers/chem/submitForm")
export const LIST_CUSTOMER_PARTS = rsaaTypes("customers/parts")
export const LIST_CUSTOMER_SITE_SURVEYS = rsaaTypes("customer/site_surveys/list")
export const CREATE_CUSTOMER_SITE_SURVEY = rsaaTypes("customer/site_surveys/post")
export const CREATE_SITE_SURVEYS_FOR_CHILDREN = rsaaTypes(
	"customer/site_surveys/create_for_children/post"
)
export const CREATE_SITE_SURVEYS_FOR_SIBLINGS = rsaaTypes(
	"customer/site_surveys/create_for_siblings/post"
)
export const GET_SITE_SURVEY = rsaaTypes("customer/get_site_survey/get")
export const GET_CUSTOMER_ROUTES = rsaaTypes("customer/customer_routes/list")
export const GET_ROUTE_DETAILS = rsaaTypes("customer/customer_routes/details")
export const CREATE_CUSTOMER_ROUTE = rsaaTypes("customer/customer_routes/create")
export const UPDATE_CUSTOMER_ROUTE = rsaaTypes("customer/customer_routes/update")
export const REMOVE_ROUTE_STOP = rsaaTypes("customer/customer_routes/stop/remove")
export const GET_ROUTE_SCHEDULE = rsaaTypes("customer/customer_routes/schedule/list")
export const UPDATE_ROUTE_SCHEDULE = rsaaTypes("customer/customer_routes/schedule/update")
export const CREATE_ROUTE_SCHEDULE = rsaaTypes("customer/customer_routes/schedule/create")
export const REMOVE_ROUTE_SCHEDULE = rsaaTypes("customer/customer_routes/schedule/remove")
export const LIST_CUSTOMER_CONTACT_INFO = rsaaTypes("customers/site_contact_info/list")
export const GET_CUSTOMER_CONTACT_INFO = rsaaTypes("customers/site_contact_info/get")
export const UPDATE_CUSTOMER_CONTACT_INFO = rsaaTypes("customers/site_contact_info/patch")
export const LIST_CUSTOMER_SITE = rsaaTypes("customer/customer_sites/list")
export const ONLINE_CUSTOMER_CART = rsaaTypes("customer/online_customer_cart")
export const REMOVE_LINE_CUSTOMER_CART = rsaaTypes("customer/online_customer_cart/line/remove")
export const ADD_LINE_CUSTOMER_CART = rsaaTypes("customer/online_customer_cart/line/add")
export const CUSTOMER_CART_SITES = rsaaTypes("customer/customer_cart_sites")
export const LIST_CUSTOMER_MILESTONE = rsaaTypes("customer/milestone/list")
export const REJECT_CUSTOMER_MILESTONE = rsaaTypes("customer/milestone/reject")
export const APPROVE_CUSTOMER_MILESTONE = rsaaTypes("customer/milestone/approve")

export const getChemTypes = () => ({
	[RSAA]: {
		api: get(`/customers/initial_chemical_projection/`),
		types: GET_CHEM_TYPES,
	},
})

export const submitChemForecastForm = (form) => ({
	[RSAA]: {
		api: post(`/customers/chemical_projection/`, form),
		types: SUBMIT_CHEM_FORM,
	},
})

export const getCustomerMapFields = () => ({
	[RSAA]: {
		api: get(`/customers/map_page/`),
		types: GET_CUSTOMER_MAP_FIELDS,
	},
})

export const getCustomerMap = (querystring_params) => ({
	[RSAA]: {
		api: get(`/customers/map/`, querystring_params),
		types: GET_CUSTOMER_MAP,
	},
})

export const listCustomerContactInfo = (querystringParams) => ({
	[RSAA]: {
		api: get("/customers/site_contact_info/", querystringParams),
		types: LIST_CUSTOMER_CONTACT_INFO,
		parseLinkHeader: true,
	},
})

export const getCustomerContactInfo = (customerId) => ({
	[RSAA]: {
		api: get(`/customers/site_contact_info/`, { customer: customerId }),
		types: GET_CUSTOMER_CONTACT_INFO,
	},
})

export const updateCustomerContactInfo = (contactId, params) => ({
	[RSAA]: {
		api: patch(`/customers/site_contact_info/${contactId}/`, params),
		types: UPDATE_CUSTOMER_CONTACT_INFO,
	},
})

export const listCustomerSites = (querystringParams) => ({
	[RSAA]: {
		api: get(`/customers/customer_sites/`, querystringParams),
		types: LIST_CUSTOMER_SITE,
		parseLinkHeader: true,
	},
})

export const listCustomers = (querystringParams) => ({
	[RSAA]: {
		api: get(`/customers/`, querystringParams),
		types: LIST_CUSTOMERS,
		parseLinkHeader: true,
	},
})

export const listMinCustomers = (querystringParams) => ({
	[RSAA]: {
		api: get(`/customers/minimal/`, querystringParams),
		types: LIST_MIN_CUSTOMERS,
		parseLinkHeader: true,
	},
})

export const exportMinCustomers = (querystringParams) => ({
	[RSAA]: {
		api: post(`/customers/minimal/export/`, undefined, querystringParams),
		types: EXPORT_MIN_CUSTOMERS,
	},
})

export const exportCustomers = (querystringParams) => ({
	[RSAA]: {
		api: post(`/customers/export/`, undefined, querystringParams),
		types: EXPORT_CUSTOMERS,
	},
})

export const getCustomer = (customerId, querystringParams) => ({
	[RSAA]: {
		api: get(`/customers/${customerId}/`, querystringParams),
		types: GET_CUSTOMER,
	},
})

export const patchCustomer = (customerId, data) => ({
	[RSAA]: {
		api: patch(`/customers/${customerId}/`, data),
		types: PATCH_CUSTOMER,
	},
})

export const getCustomerInvoices = (customerId, pageSize = 10) => ({
	[RSAA]: {
		api: get(`/customers/${customerId}/invoices/?pageSize=${pageSize}`),
		types: LIST_CUSTOMER_INVOICES,
	},
})

export const getCustomerEquipmentRepairProposals = (customerId, pageSize = 10) => ({
	[RSAA]: {
		api: get(`/customers/${customerId}/equipment_repair_proposals/?pageSize=${pageSize}`),
		types: LIST_CUSTOMER_EQUIPMENT_REPAIR_PROPOSALS,
	},
})

export const getCustomerPreferences = (customerId) => ({
	[RSAA]: {
		api: get(`/customers/${customerId}/preferences/`),
		types: GET_CUSTOMER_PREFERENCES,
	},
})

export const patchCustomerPreferences = (customerId, data) => ({
	[RSAA]: {
		api: patch(`/customers/${customerId}/preferences/`, data),
		types: PATCH_CUSTOMER_PREFERENCES,
	},
})

export const listCustomerEquipment = (customerId) => ({
	[RSAA]: {
		api: get(`/customers/${customerId}/equipment/`),
		types: LIST_CUSTOMER_EQUIPMENT,
	},
})

export const getCustomerEquipment = (customerId, equipmentId) => ({
	[RSAA]: {
		api: get(`/customers/${customerId}/equipment/${equipmentId}/`),
		types: GET_CUSTOMER_EQUIPMENT,
	},
})

export const patchCustomerEquipment = (customerId, equipmentId, data) => ({
	[RSAA]: {
		api: patch(`/customers/${customerId}/equipment/${equipmentId}/`, data),
		types: PATCH_CUSTOMER_EQUIPMENT,
	},
})

export const createCustomerEquipment = (customerId, data) => ({
	[RSAA]: {
		api: post(`/customers/${customerId}/equipment/`, data),
		types: PATCH_CUSTOMER_EQUIPMENT,
	},
})

export const patchCustomerEquipmentContract = (customerId, equipmentId, contractId, data) => ({
	[RSAA]: {
		api: patch(
			`/customers/${customerId}/equipment/${equipmentId}/contracts/${contractId}/`,
			data
		),
		types: PATCH_CUSTOMER_EQUIPMENT_CONTRACT,
	},
})

export const listCustomerBillTos = (customerId) => ({
	[RSAA]: {
		api: get(`/customers/${customerId}/bill_tos/`),
		types: LIST_CUSTOMER_PARTS,
	},
})

export const listCustomerParts = (customerId) => ({
	[RSAA]: {
		api: get(`/parts/customer/${customerId}/`),
		types: LIST_CUSTOMER_BILL_TOS,
	},
})

export const getDefaultCustomerBillTo = (customerId) => ({
	[RSAA]: {
		api: get(`/customers/${customerId}/bill_tos/`),
		types: GET_DEFAULT_CUSTOMER_BILL_TO,
	},
})

export const listCustomerSiteSurveys = (customerId) => ({
	[RSAA]: {
		api: get(`/customers/${customerId}/site_surveys/`),
		types: LIST_CUSTOMER_SITE_SURVEYS,
	},
})

export const getCustomerSiteSurvey = (customerId, surveyResponseId) => ({
	[RSAA]: {
		api: get(`/customers/${customerId}/site_surveys/${surveyResponseId}/`),
		types: GET_SITE_SURVEY,
	},
})

export const createSiteSurvey = (customerId, potentialCustomerId, data) => {
	if (!!customerId) {
		return {
			[RSAA]: {
				api: post(`/customers/${customerId}/site_surveys/`, data),
				types: CREATE_CUSTOMER_SITE_SURVEY,
			},
		}
	}

	return {
		[RSAA]: {
			api: post(`/customers/potential_customers/${potentialCustomerId}/site_surveys/`, data),
			types: CREATE_POTENTIAL_CUSTOMER_SITE_SURVEY,
		},
	}
}

export const createSiteSurveysForChildren = (customerId) => ({
	[RSAA]: {
		api: post(`/customers/${customerId}/site_surveys/create_for_children/`),
		types: CREATE_SITE_SURVEYS_FOR_CHILDREN,
	},
})

export const createSiteSurveysForSiblings = (customerId) => ({
	[RSAA]: {
		api: post(`/customers/${customerId}/site_surveys/create_for_siblings/`),
		types: CREATE_SITE_SURVEYS_FOR_SIBLINGS,
	},
})

export const setSelectedCustomer = (selectedCustomer) => ({
	type: SET_SELECTED_CUSTOMER,
	payload: selectedCustomer,
})

export const handleCustomerSelected = (customer) => ({
	type: SET_SELECTED_CUSTOMER,
	payload: customer ? customer._id : null,
})

export const createCustomerAPI = (customer) => ({
	[RSAA]: {
		api: post("/customers/create_customer/", customer),
		types: CREATE_CUSTOMER,
	},
})

const searchCustomerAPI = (searchText) => ({
	[RSAA]: {
		api: get("/customers/", {
			search: searchText,
			fields: "name,customer_number,_id,address_2,city,state",
		}),
		types: SEARCH_CUSTOMER,
	},
})

export const swapKBMManaged = (customerId, data) => ({
	[RSAA]: {
		api: post(`/customers/${customerId}/swap_kbm_managed/`, data),
		types: SWAP_KBM_MANAGED,
	},
})

export const getCustomerRoutes = (querystringParams) => ({
	[RSAA]: {
		api: get("/routes/", querystringParams),
		types: GET_CUSTOMER_ROUTES,
	},
})

export const getRouteDetails = (routeId) => ({
	[RSAA]: {
		api: get(`/routes/${routeId}/`),
		types: GET_ROUTE_DETAILS,
	},
})

export const createRoute = (params) => ({
	[RSAA]: {
		api: post(`/routes/`, params),
		types: CREATE_CUSTOMER_ROUTE,
	},
})

export const updateCustomerRoute = (routeId, params) => ({
	[RSAA]: {
		api: patch(`/routes/${routeId}/`, params),
		types: UPDATE_CUSTOMER_ROUTE,
	},
})

export const getRouteSchedule = (params) => ({
	[RSAA]: {
		api: get(`/dispatch/route_schedule/`, params),
		types: GET_ROUTE_SCHEDULE,
	},
})

export const createRouteSchedule = (params) => ({
	[RSAA]: {
		api: post(`/dispatch/route_schedule/`, params),
		types: CREATE_ROUTE_SCHEDULE,
	},
})

export const editRouteSchedule = (scheduleId, params) => ({
	[RSAA]: {
		api: patch(`/dispatch/route_schedule/${scheduleId}/`, params),
		types: UPDATE_ROUTE_SCHEDULE,
	},
})

export const removeRouteSchedule = (scheduleId) => ({
	[RSAA]: {
		api: del(`/dispatch/route_schedule/${scheduleId}/`),
		types: REMOVE_ROUTE_SCHEDULE,
	},
})

export const getCustomerPrefillCart = (customerId) => ({
	[RSAA]: {
		api: get(`/customers/online_chemicals/`, { customer: customerId }),
		types: ONLINE_CUSTOMER_CART,
	},
})

export const removePrefillCartLine = (lineId) => ({
	[RSAA]: {
		api: del(`/customers/online_chemicals/${lineId}/`),
		types: REMOVE_LINE_CUSTOMER_CART,
	},
})

export const addPrefillCartLine = (partId, customerId) => ({
	[RSAA]: {
		api: post(`/customers/online_chemicals/`, {
			customer_id: customerId,
			online_part_id: partId,
		}),
		types: ADD_LINE_CUSTOMER_CART,
	},
})

export const getUserCustomerCartSites = (scheduleId) => ({
	[RSAA]: {
		api: get(`/customers/cart_sites/`),
		types: CUSTOMER_CART_SITES,
	},
})

export const getCustomerMilestones = (customerId) => ({
	[RSAA]: {
		api: get(`/workorders/customer_milestone/`, { customer: customerId }),
		types: LIST_CUSTOMER_MILESTONE,
	},
})

export const rejectCustomerMilestone = (milestoneId) => ({
	[RSAA]: {
		api: post(`/workorders/customer_milestone/${milestoneId}/reject_milestone/`),
		types: REJECT_CUSTOMER_MILESTONE,
	},
})

export const approveCustomerMilestone = (milestoneId, params) => ({
	[RSAA]: {
		api: post(`/workorders/customer_milestone/${milestoneId}/approve_milestone/`, params),
		types: APPROVE_CUSTOMER_MILESTONE,
	},
})

export const searchCustomer = (searchText) => {
	const debounced = (dispatch) => {
		dispatch(searchCustomerAPI(searchText))
	}

	debounced.meta = {
		debounce: {
			time: 325,
			key: SEARCH_CUSTOMER,
		},
	}

	return debounced
}

const initialState = {
	isLoadingCustomer: false,
	isLoadingCustomerInvoices: false,
	isLoadingCustomerERPs: false,
	isLoadingCustomerBillTos: false,
	isLoadingCustomerSiteSurveys: false,
	isLoadingDefaultCustomerBillTo: false,
	isLoadingCustomerSites: false,
	isSwappingKBMManaged: false,
	customer: null,
	customerInvoices: null,
	customerERPs: null,
	customerEquipment: [],
	customerBillTos: [],
	customerSiteSurveys: [],
	defaultCustomerBillTo: [],
	customerSearchReturn: [],
	customerSites: [],
	error: null,
	selectedCustomer: null,
}

const customers = (state = initialState, action) => {
	switch (action.type) {
		case GET_CUSTOMER.request:
			return {
				...state,
				isLoadingCustomer: true,
			}
		case GET_CUSTOMER.success:
			return {
				...state,
				isLoadingCustomer: false,
				customer: action.payload,
				error: null,
			}
		case GET_CUSTOMER.failure:
			return {
				...state,
				isLoadingCustomer: false,
				error: action.error,
			}
		case CREATE_CUSTOMER.request:
			return {
				...state,
				isSaving: true,
			}
		case CREATE_CUSTOMER.success:
			return {
				...state,
				isSaving: false,
				customer: action.payload,
				error: null,
			}
		case CREATE_CUSTOMER.failure:
			return {
				...state,
				isSaving: false,
				error: action.error,
			}
		case LIST_CUSTOMER_INVOICES.request:
			return {
				...state,
				isLoadingCustomerInvoices: true,
			}
		case LIST_CUSTOMER_INVOICES.success:
			return {
				...state,
				isLoadingCustomerInvoices: false,
				customerInvoices: action.payload,
				error: null,
			}
		case LIST_CUSTOMER_INVOICES.failure:
			return {
				...state,
				isLoadingCustomerInvoices: false,
				error: action.error,
			}

		case LIST_CUSTOMER_EQUIPMENT_REPAIR_PROPOSALS.request:
			return {
				...state,
				isLoadingCustomerERPs: true,
			}
		case LIST_CUSTOMER_EQUIPMENT_REPAIR_PROPOSALS.success:
			return {
				...state,
				isLoadingCustomerERPs: false,
				customerERPs: action.payload,
				error: null,
			}
		case LIST_CUSTOMER_EQUIPMENT_REPAIR_PROPOSALS.failure:
			return {
				...state,
				isLoadingCustomerERPs: false,
				error: action.error,
			}

		case GET_CUSTOMER_PREFERENCES.request:
			return {
				...state,
				isLoadingCustomerPreferences: true,
			}
		case GET_CUSTOMER_PREFERENCES.success:
			return {
				...state,
				isLoadingCustomerPreferences: false,
				customerPreferences: action.payload,
				error: null,
			}
		case GET_CUSTOMER_PREFERENCES.failure:
			return {
				...state,
				isLoadingCustomerPreferences: false,
				error: action.error,
			}

		case LIST_CUSTOMER_EQUIPMENT.request:
			return {
				...state,
				isLoadingCustomerEquipment: true,
			}
		case LIST_CUSTOMER_EQUIPMENT.success:
			return {
				...state,
				isLoadingCustomerEquipment: false,
				customerEquipment: action.payload,
				error: null,
			}
		case LIST_CUSTOMER_EQUIPMENT.failure:
			return {
				...state,
				isLoadingCustomerEquipment: false,
				error: action.error,
			}

		case LIST_CUSTOMER_SITE_SURVEYS.request:
		case LIST_CUSTOMER_SITE_SURVEYS.success:
		case LIST_CUSTOMER_SITE_SURVEYS.failure:
			return {
				...state,
				isLoadingCustomerSiteSurveys: action.type === LIST_CUSTOMER_SITE_SURVEYS.request,
				customerSiteSurveys:
					action.type === LIST_CUSTOMER_SITE_SURVEYS.success ?
						action.payload
					:	undefined,
				error: action.error || null,
			}

		case LIST_CUSTOMER_SITE.request:
		case LIST_CUSTOMER_SITE.success:
		case LIST_CUSTOMER_SITE.failure:
			return {
				...state,
				isLoadingCustomerSites: action.type === LIST_CUSTOMER_SITE.request,
				customerSites:
					action.type === LIST_CUSTOMER_SITE.success ?
						sortBy(action.payload, ["name", "customer_number", "_id"])
					:	state.customerSites,
				error: action.error || null,
			}

		case LIST_CUSTOMER_BILL_TOS.request:
			return {
				...state,
				isLoadingCustomerBillTos: true,
			}
		case LIST_CUSTOMER_BILL_TOS.success:
			return {
				...state,
				isLoadingCustomerBillTos: false,
				customerBillTos: action.payload,
				error: null,
			}
		case LIST_CUSTOMER_BILL_TOS.failure:
			return {
				...state,
				isLoadingCustomerBillTos: false,
				error: action.error,
			}

		case GET_DEFAULT_CUSTOMER_BILL_TO.request:
			return {
				...state,
				isLoadingDefaultCustomerBillTo: true,
			}
		case GET_DEFAULT_CUSTOMER_BILL_TO.success:
			return {
				...state,
				isLoadingDefaultCustomerBillTo: false,
				defaultCustomerBillTo: action.payload.find((x) => x.is_default) || null,
				error: null,
			}
		case GET_DEFAULT_CUSTOMER_BILL_TO.failure:
			return {
				...state,
				isLoadingDefaultCustomerBillTo: false,
				error: action.error,
			}

		case SET_SELECTED_CUSTOMER:
			return {
				...state,
				selectedCustomer: action.payload,
			}

		case SEARCH_CUSTOMER.request:
			return {
				...state,
				isSearchingCustomer: true,
			}
		case SEARCH_CUSTOMER.success:
			return {
				...state,
				isSearchingCustomer: false,
				customerSearchReturn: action.payload,
				error: null,
			}
		case SEARCH_CUSTOMER.failure:
			return {
				...state,
				isSearchingCustomer: false,
				error: action.error,
			}
		case SWAP_KBM_MANAGED.request:
			return {
				...state,
				isSwappingKBMManaged: true,
			}
		case SWAP_KBM_MANAGED.failure:
			return {
				...state,
				isSwappingKBMManaged: false,
				error: action.error,
			}
		case SWAP_KBM_MANAGED.success:
			return {
				...state,
				isSwappingKBMManaged: false,
				error: null,
			}
		case PATCH_CUSTOMER.request:
		case PATCH_CUSTOMER_PREFERENCES.request:
		case PATCH_CUSTOMER_EQUIPMENT.request:
		case PATCH_CUSTOMER_EQUIPMENT_CONTRACT.request:
		case CREATE_CUSTOMER_SITE_SURVEY.request:
		case CREATE_SITE_SURVEYS_FOR_CHILDREN.request:
		case CREATE_SITE_SURVEYS_FOR_SIBLINGS.request:
			return {
				...state,
				isSaving: true,
			}

		case PATCH_CUSTOMER.success:
		case PATCH_CUSTOMER_PREFERENCES.success:
		case PATCH_CUSTOMER_EQUIPMENT.success:
		case PATCH_CUSTOMER_EQUIPMENT_CONTRACT.success:
		case CREATE_CUSTOMER_SITE_SURVEY.success:
		case CREATE_SITE_SURVEYS_FOR_CHILDREN.success:
		case CREATE_SITE_SURVEYS_FOR_SIBLINGS.success:
			return {
				...state,
				isSaving: false,
			}

		case PATCH_CUSTOMER.failure:
		case PATCH_CUSTOMER_PREFERENCES.failure:
		case PATCH_CUSTOMER_EQUIPMENT.failure:
		case PATCH_CUSTOMER_EQUIPMENT_CONTRACT.failure:
		case CREATE_CUSTOMER_SITE_SURVEY.failure:
		case CREATE_SITE_SURVEYS_FOR_CHILDREN.failure:
		case CREATE_SITE_SURVEYS_FOR_SIBLINGS.failure:
			return {
				...state,
				error: action,
			}

		default:
			return state
	}
}
export default customers

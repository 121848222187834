import React from "react"
import PropTypes from "prop-types"

import { Input, InputAsDisplay } from "../../../components"

class TextFormField extends React.PureComponent {
	render = () => {
		const {
			errorMessage,
			isEditing,
			isReadOnly,
			isValid,
			labelText,
			onChange,
			showValidationErrors,
			value,
		} = this.props

		return (
			<React.Fragment>
				{(isReadOnly || !isEditing) && (
					<InputAsDisplay labelText={labelText} value={value} />
				)}

				{!isReadOnly && !!isEditing && (
					<Input
						labelText={labelText}
						value={value}
						onChange={onChange}
						formControlProps={{
							error: showValidationErrors && !isValid,
						}}
						helperText={showValidationErrors ? errorMessage : ""}
					/>
				)}
			</React.Fragment>
		)
	}
}

TextFormField.propTypes = {
	/** true if in editing mode, false if in read-only mode. defaults to false */
	isEditing: PropTypes.bool,

	/** name of the field */
	labelText: PropTypes.string.isRequired,

	/** current value of input */
	value: PropTypes.string,

	/** when true the editor will never be shown, defaults to false */
	isReadOnly: PropTypes.bool,

	/** function called when the user changes the value, should update the bit that gets passed in as `value` */
	onChange: PropTypes.func,

	/** should the error message be displayed if invalid? usually this is only set to true after a save is attempted */
	showValidationErrors: PropTypes.bool,

	/** is the current value valid? */
	isValid: PropTypes.bool,

	/** error message for why the current value is not valid, only shown if showValidationErrors is true */
	errorMessage: PropTypes.string,
}

export default TextFormField

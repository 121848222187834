import React, { useEffect } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import memoizeOne from "memoize-one"
import { BaseSelectFilter, withDynamicTableFilter } from "@ncs/bricks/components"
import { loadLookups, LOOKUPS } from "../../redux/services/lookups"

// these are used when the lookup hasn't returned yet, anything that can be a default value for the
// filter should be in this list
const defaultStatuses = [
	{ _id: "1", description: "Open" },
	{ _id: "3", description: "Completed" },
	{ _id: "2", description: "Closed" },
]

const getDispatchStatusOptions = memoizeOne((statuses) => {
	return [
		{ _id: "", description: "All Statuses" },
		...(statuses?.length ? statuses : defaultStatuses),
	]
})

function DispatchStatusFilter(props) {
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(loadLookups([LOOKUPS.DispatchStatuses]))
	}, [dispatch])

	const statuses = useSelector((x) => x.lookups.dispatchStatuses, shallowEqual)

	return (
		<BaseSelectFilter
			id="dispatchStatus"
			labelText="Status"
			options={getDispatchStatusOptions(statuses)}
			valueAccessor="_id"
			textAccessor="description"
			{...props}
		/>
	)
}

export const AliasedDispatchStatusFilter = (filterName) =>
	withDynamicTableFilter(filterName)(DispatchStatusFilter)

export default AliasedDispatchStatusFilter("status")

import { FC, useEffect, useState } from "react"

import { nanoid } from "nanoid"

import {
	BinLocation,
	BinPart,
	InventoryLocation,
	InventoryPart,
	InventoryTransactionType,
	makeApiErrorMessage,
} from "@ncs/ncs-api"
import { formatNumber } from "@ncs/ts-utils"
import {
	Disabled,
	ExtendableModalProps,
	LocationBinSelector,
	Modal,
	NumericInput,
	Paragraph,
	PartSelector,
} from "@ncs/web-legos"

import { PartLocationBinSelector } from "~/components"

import { InventoryTransactionLine } from "../inventory-transaction-util"

export interface AddTransactionPartModalProps extends ExtendableModalProps {
	transactionType: InventoryTransactionType
	fromLocation: InventoryLocation | null
	toLocation: InventoryLocation | null
	currentTransactionLines: InventoryTransactionLine[]
	onSave: (part: InventoryTransactionLine) => void
}

export const AddTransactionPartModal: FC<AddTransactionPartModalProps> = ({
	transactionType,
	fromLocation,
	toLocation,
	currentTransactionLines,
	onSave,
	...rest
}) => {
	const defaultQuantity = !!fromLocation && !toLocation ? -1 : 1

	const [part, setPart] = useState<InventoryPart | null>(null)
	const [errorText, setErrorText] = useState<string | null>(null)

	const [fromBin, setFromBin] = useState<BinPart | null>(null)
	const [toBin, setToBin] = useState<BinLocation | null>(null)
	const [quantity, setQuantity] = useState<number | null>(defaultQuantity)

	const handleSubmit = async () => {
		try {
			if (!part) {
				throw new Error("Select a part")
			}
			if (!quantity) {
				throw new Error("Enter a quantity for the transaction")
			}
			if (fromLocation) {
				if (!fromBin) {
					throw new Error("Select bin the part is coming from")
				}
				if (quantity > fromBin.quantity) {
					throw new Error("Transfer quantity is higher than bin quantity")
				}
				if (!toLocation) {
					if (
						currentTransactionLines.some((line) => {
							return line.partId === part.id && line.sourceBinId === fromBin.binId
						})
					) {
						throw new Error(
							"A transaction line already exists for this part coming from the same bin"
						)
					}
				}
			}
			if (toLocation) {
				if (!toBin) {
					throw new Error("Select the bin the part is going to")
				}
				if (!fromBin) {
					if (
						currentTransactionLines.some((line) => {
							return line.partId === part.id && line.destinationBinId === toBin.id
						})
					) {
						throw new Error(
							"A transaction line already exists for this part going into the same bin"
						)
					}
				}
			}
			if (fromLocation && toLocation) {
				if (
					currentTransactionLines.some((line) => {
						return (
							line.partId === part.id &&
							line.sourceBinId === fromBin?.binId &&
							line.destinationBinId === toBin?.id
						)
					})
				) {
					throw new Error(
						"A transaction line already exists for this part with the same source and destination bins."
					)
				}
			}
			onSave({
				id: nanoid(),
				partId: part.id,
				partNumber: part.partNumber,
				partDescription: part.description,
				quantity,
				destinationBinId: toBin?.id,
				destinationBinCode: toBin?.code,
				sourceBinId: fromBin?.binId,
				sourceBinCode: fromBin?.binCode,
			})
			rest.onClose()
		} catch (e) {
			setErrorText(makeApiErrorMessage(e))
		}
	}

	useEffect(() => {
		setErrorText(null)
	}, [part, fromBin, toBin, quantity])

	return (
		<Modal
			{...rest}
			title={`Select Item for ${transactionType.text}`}
			errorText={errorText}
			rightButtons={{
				buttonText: "Add Item To Transaction",
				onClick: handleSubmit,
				disabled: !!errorText,
			}}
		>
			<PartSelector
				value={part}
				onChange={setPart}
				includeNonService
				skipRestrictedCheck
				label="Transaction part"
				autoFocus
			/>

			<Disabled disabled={!part}>
				{!!fromLocation && (
					<>
						<PartLocationBinSelector
							partId={part?.id ?? null}
							locationId={fromLocation.id}
							value={fromBin}
							onChange={setFromBin}
							label={`From ${fromLocation.description} bin`}
							showNoStockError
							mb={0}
						/>
						{fromBin && (
							<Paragraph small secondary textAlign="right">
								Bin quantity: <strong>{formatNumber(fromBin.quantity)}</strong>
							</Paragraph>
						)}
					</>
				)}

				{!!toLocation && (
					<LocationBinSelector
						locationId={toLocation.id}
						value={toBin}
						onChange={setToBin}
						label={`To ${toLocation.description} bin`}
						excludeInactive
						mt={1}
					/>
				)}

				<NumericInput
					value={quantity}
					onChange={(newValue) => setQuantity(newValue ?? null)}
					label="Transaction quantity"
					decimalScale={0}
					allowNegative={defaultQuantity === -1}
					mt={1}
				/>
			</Disabled>
		</Modal>
	)
}

import { FC, useState } from "react"

import { css } from "@emotion/react"
import { useHistory } from "react-router-dom"
import { Column } from "react-table"

import { BrandCard, useBrandCards } from "@ncs/ncs-api"
import { formatDateTime } from "@ncs/ts-utils"
import {
	Box,
	Button,
	Card,
	EmptyValueDash,
	Icon,
	Paragraph,
	Table,
	useScrollToTopOnMount,
} from "@ncs/web-legos"

import { ArrangeModal } from "./ArrangeModal"

const columns: Column<BrandCard>[] = [
	{
		Header: "Logo",
		accessor: "logoImageUrl",
		Cell: ({ row: { original } }) => {
			return (
				<img src={original.logoImageUrl} alt={`Logo for ${original.name}`} css={logoCss} />
			)
		},
	},
	{
		Header: "Name",
		accessor: "name",
	},
	{
		Header: "Tagline",
		accessor: "taglineText",
	},
	{
		Header: "Button Text",
		accessor: "buttonLabel",
	},
	{
		Header: "Last modified",
		accessor: "modifiedOn",
		Cell: ({
			row: {
				original: { modifiedOn },
			},
		}) => (modifiedOn ? formatDateTime(modifiedOn) : <EmptyValueDash />),
	},
	{
		Header: "Visible?",
		accessor: "isActive",
		Cell: ({ row: { original } }) =>
			original.isActive ? <Icon icon="check" color="gray" /> : <EmptyValueDash />,
	},
]

const BrandCards: FC = () => {
	useScrollToTopOnMount()
	const history = useHistory()
	const [showArrangeModal, setShowArrangeModal] = useState(false)

	const [cards, cardsLoading] = useBrandCards()

	return (
		<Card heading="Shop Homepage Brand Cards" headingIcon="pennant">
			<Paragraph mb={2} maxWidth={40}>
				Brand Cards display prominently on the Shop homepage to showcase NCS brands. When
				clicked, they can link the customer over to the Search page with a filter preset to
				the brand, or to any other page you wish.
			</Paragraph>

			<Box display="flex" justifyContent="space-between" alignItems="flex-end" mb={2}>
				<Button
					icon="plus-circle"
					variant="secondary-cta"
					onClick={() => history.push("/pos-brand-cards/card")}
				>
					Create new brand card
				</Button>

				<Button
					icon="sort"
					onClick={() => setShowArrangeModal(true)}
					disabled={!cards || cards.length < 2}
				>
					Rearrange card order
				</Button>
			</Box>

			<Table
				data={cards ?? []}
				noDataText="No cards created yet"
				columns={columns}
				isLoading={cardsLoading}
				rowVerticalAlign="middle"
				onRowClick={({ original }) => history.push(`/pos-brand-cards/card/${original.id}`)}
				disableAllSorting
			/>

			{!!cards && cards.length > 1 && (
				<ArrangeModal
					isOpen={showArrangeModal}
					onClose={() => setShowArrangeModal(false)}
					cards={cards.filter((card) => card.isActive)}
				/>
			)}
		</Card>
	)
}

const logoCss = css`
	height: 1.5rem;
`

export default BrandCards

import { FC, memo, useCallback, useMemo, useState } from "react"

import { Column } from "react-table"

import {
	APPLICATION,
	defaultPartGroupMembersQueryParams,
	makeApiErrorMessage,
	PartGroup,
	PartGroupMember,
	usePartGroupMembers,
	useRemovePartGroupMember,
	useUserCanUse,
} from "@ncs/ncs-api"
import {
	Button,
	ConfirmationModal,
	ConfirmationModalConfig,
	SearchQueryFilter,
	Table,
	TableProps,
	useToast,
} from "@ncs/web-legos"

import { NewPartGroupMemberModal } from "./NewPartGroupMemberModal"

export interface PartGroupsPartsTabProps {
	group: PartGroup
}

const columns: Column<PartGroupMember>[] = [
	{
		Header: "Part #",
		accessor: ({ part }) => part.partNumber,
		disableSortBy: true,
	},
	{
		Header: "Part Name",
		accessor: ({ part }) => part.description,
		disableSortBy: true,
	},
]

export const PartGroupsPartsTab: FC<PartGroupsPartsTabProps> = memo(({ group }) => {
	const isDiscountAdmin = useUserCanUse(APPLICATION.PartDiscountAdmin)
	const [queryParams, setQueryParams] = useState(defaultPartGroupMembersQueryParams)
	const membersQuery = usePartGroupMembers({
		params: {
			...queryParams,
			group: group.id,
		},
	})
	const removePart = useRemovePartGroupMember()
	const { makeSuccessToast, makeErrorToast } = useToast()
	const [showAddPartModal, setShowAddPartModal] = useState(false)
	const [confirmConfig, setConfirmConfig] = useState<ConfirmationModalConfig | null>(null)

	const onRemove = useCallback(
		async (memberId: string) => {
			try {
				await removePart(memberId)
				makeSuccessToast("Part removed from group")
			} catch (e) {
				makeErrorToast(makeApiErrorMessage(e))
			}
		},
		[makeErrorToast, makeSuccessToast, removePart]
	)

	const rowMenu = useMemo((): TableProps<PartGroupMember>["rowMenu"] => {
		return isDiscountAdmin ?
				[
					{
						label: "Remove from group",
						iconName: "trash",
						onClick: ({ original }) =>
							setConfirmConfig({
								title: "Remove From Group",
								message: (
									<>
										Remove <strong>{original.part.description}</strong> from
										the group <strong>{original.group.description}</strong>?
									</>
								),
								onConfirm: () => onRemove(original.id),
							}),
					},
				]
			:	undefined
	}, [isDiscountAdmin, onRemove])

	return (
		<>
			{isDiscountAdmin && (
				<Button
					buttonText="Add Part To Group"
					variant="secondary-cta"
					icon="plus-circle"
					onClick={() => setShowAddPartModal(true)}
				/>
			)}

			<Table
				query={membersQuery}
				columns={columns}
				noDataText="No parts in this group yet"
				queryParamState={queryParams}
				setQueryParamState={setQueryParams}
				toggledQueryFilters={[SearchQueryFilter]}
				rowMenu={rowMenu}
			/>

			<NewPartGroupMemberModal
				isOpen={showAddPartModal}
				onClose={() => setShowAddPartModal(false)}
				group={group}
			/>
			<ConfirmationModal config={confirmConfig} setConfig={setConfirmConfig} />
		</>
	)
})

import { FC, useMemo } from "react"

import { useReportPermissions } from "@ncs/ncs-api"
import {
	AnimatedEntrance,
	Box,
	Button,
	Card,
	HeadingDivider,
	LoadingSpinner,
	Select,
	useUrlState,
} from "@ncs/web-legos"

import { PageTitle } from "~/layouts/PageTitle"

import { reportConfigs } from "./reports-util"

const Reports: FC = () => {
	const [reportPermissions, reportPermissionsLoading] = useReportPermissions()
	const allowedReportNames = useMemo(() => {
		return (reportPermissions ?? []).map((r) => r.name)
	}, [reportPermissions])

	const [{ selectedReportName }, { setUrlState }] = useUrlState<{
		selectedReportName: string | null
	}>({
		selectedReportName: null,
	})

	const handleReportChange = (name: string | null) => {
		setUrlState({ selectedReportName: name })
	}

	const reportsOptions = useMemo(() => {
		return reportConfigs
			.filter(
				(r) => r.permissionName == null || allowedReportNames.includes(r.permissionName)
			)
			.sort((a, b) => (a.uiName.toLowerCase() > b.uiName.toLowerCase() ? 1 : -1))
			.map((r) => ({
				value: r.uiName,
				text: r.uiName,
			}))
	}, [allowedReportNames])

	const SelectedReport = useMemo(() => {
		// Match the UI name chosen from the Select within the report config.
		const selection = reportConfigs.find((r) => r.uiName === selectedReportName)

		if (!selection) {
			return null
		}

		// Double-check you have permission to view this report.
		if (
			selection.permissionName != null &&
			allowedReportNames.includes(selection.permissionName) === false
		) {
			return null
		}

		return selection.component
	}, [selectedReportName, allowedReportNames])

	if (reportPermissionsLoading) {
		return <LoadingSpinner />
	}

	return (
		<>
			{!!SelectedReport && (
				<>
					<PageTitle title={`${selectedReportName} Report`} />

					<AnimatedEntrance show direction="fade">
						<Button
							icon="long-arrow-left"
							onClick={() => handleReportChange(null)}
							containerProps={{ mb: 2 }}
						>
							Back
						</Button>
					</AnimatedEntrance>
				</>
			)}

			<Card heading="Reports" headingIcon="magnifying-glass-chart">
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					gap={1}
					mb={2}
					xsProps={{ display: "block" }}
				>
					{!!selectedReportName && (
						<HeadingDivider headingVariant="h2" flexGrow={1}>
							{selectedReportName} Report
						</HeadingDivider>
					)}
					<Select
						value={selectedReportName}
						onChange={(value, option) => handleReportChange(option?.text ?? null)}
						options={reportsOptions}
						label={selectedReportName ? "View different report" : "Select report"}
						disableNoSelectionOption={false}
						fillContainer={!selectedReportName}
						maxWidth={!selectedReportName ? "50%" : undefined}
						smProps={{ maxWidth: "none" }}
					/>
				</Box>

				{!!SelectedReport && <SelectedReport />}
			</Card>
		</>
	)
}

export default Reports

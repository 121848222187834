import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import moment from "moment"

import { Slide } from "@material-ui/core"
import { Timer, NewReleases } from "@material-ui/icons"

// custom components
import { Dialog } from "../../components"
import { isAuthenticated } from "../../redux/selectors"
import { forceReload, requestExtraTimeBeforeReload } from "../../redux/services/clientVersion"

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />
})

class ClientUpdater extends React.PureComponent {
	componentDidMount() {
		if (this.props.forcedReloadCountdown) {
			// if we're just mounting this, they can't be in the middle of anything--force the reload
			this.props.forceReload()
		}

		if (this.clientVersionTimer) {
			clearInterval(this.clientVersionTimer)
		}

		this.clientVersionTimer = setInterval(this.checkForNewClientVersion, 5000)
	}

	componentWillUnmount() {
		if (this.clientVersionTimer) {
			clearInterval(this.clientVersionTimer)
		}
	}

	checkForNewClientVersion = () => {
		if (!this.props.forcedReloadCountdown) {
			return
		}

		// if unauthenticated we can't be in the middle of anything, so force reload
		if (!this.props.isAuthenticated) {
			this.props.forceReload()
		}

		// if modal decision has not been made and > 15 minutes have passed, or if decision made and 5 extra minutes
		let timeout = this.props.usingExtraTimeBeforeReload ? 5 : 15
		if (moment().diff(this.props.forcedReloadCountdown, "minutes") >= timeout) {
			this.props.forceReload()
		}
	}

	onRequestExtraTime = () => {
		this.props.requestExtraTimeBeforeReload()
	}

	render = () => (
		<Dialog
			title="Site Update Available"
			show={!!this.props.forcedReloadCountdown && !this.props.usingExtraTimeBeforeReload}
			cancelBtnText="I Need More Time"
			cancelBtnColor="warning"
			cancelBtnIcon={<Timer />}
			onCancel={this.onRequestExtraTime}
			confirmBtnText="Update Now"
			confirmBtnColor="success"
			confirmBtnIcon={<NewReleases />}
			onConfirm={this.props.forceReload}
			TransitionComponent={Transition}
		>
			<p>Your browser needs to refresh the website to receive the latest update.</p>
			<p>
				If you need to finish what you&apos;re doing clicking &quot;I Need More Time&quot;
				will give you up to five more minutes before updating. However, if you do not
				update now you may encounter bugs due to server changes.
			</p>
		</Dialog>
	)
}

const mapStateToProps = (state) => ({
	isAuthenticated: isAuthenticated(state),
	...state.clientVersion,
})

const mapDispatchToProps = (dispatch) =>
	bindActionCreators({ forceReload, requestExtraTimeBeforeReload }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ClientUpdater)

import { Dispatch, ReactElement, SetStateAction } from "react"

import { OrderLineTypeSelector } from "../selectors"

export interface OrderLineTypeParamState {
	lineType: string | null
}

export interface OrderLineTypeQueryFilterProps<QueryParamState extends OrderLineTypeParamState> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

export const OrderLineTypeQueryFilter = <QueryParamState extends OrderLineTypeParamState>({
	queryParamState,
	setQueryParamState,
}: OrderLineTypeQueryFilterProps<QueryParamState>): ReactElement => {
	return (
		<OrderLineTypeSelector
			value={queryParamState.lineType}
			disableNoSelectionOption={false}
			fillContainer
			onChange={(newValue) =>
				setQueryParamState((prev) => {
					const newOrderLineTypeParamState: OrderLineTypeParamState = {
						lineType: newValue,
					}

					return {
						...prev,
						...newOrderLineTypeParamState,
					}
				})
			}
		/>
	)
}

import React, { PureComponent } from "react"

import { listOffHours } from "@ncs/mortar/redux/services/users"

import { withCallApi } from "~/components"

import OffHours from "./OffHours"

class OffHoursContainer extends PureComponent {
	state = {
		events: [],
		selectedEvent: null,
		creating: false,
		createEvent: {},
		selectedTerritory: null,
		isLoading: false,
	}

	getEvents = () => {
		this.setState({ isLoading: true })
		if (this.props.territory !== null) {
			this.props
				.callApi(
					listOffHours({
						territory: this.state.selectedTerritory,
					})
				)
				.then((result) => {
					this.setState({
						events: result.payload.events,
						techAssignments: result.payload.groups,
						isLoading: false,
					})
				})
		} else {
			this.setState({ events: [], isLoading: false })
		}
	}

	handleEditSlot = (event) => {
		this.setState({ creating: false })
		if (this.state.selectedEvent) {
			this.setState({ selectedEvent: null }, () => {
				this.setState({ selectedEvent: event })
			})
		} else {
			this.setState({ selectedEvent: event })
		}
	}
	handleSelectSlot = (event) => {
		this.setState({ selectedEvent: null })
		if (this.state.creating) {
			this.setState({ creating: false }, () => {
				this.setState({
					createEvent: {
						start: event.start,
						end: event.end,
					},
					creating: true,
				})
			})
		} else {
			this.setState({
				createEvent: {
					start: event.start,
					end: event.end,
				},
				creating: true,
			})
		}
	}
	handleEventSave = () => {
		this.setState({ selectedEvent: null, creating: false })
		this.getEvents()
	}
	handleCancelEvent = () => {
		this.setState({
			creating: false,
			selectedEvent: null,
		})
	}
	handleTerritoryChange = (selectedTerritory) => {
		this.setState(
			{ selectedTerritory, creating: false, selectedEvent: null, events: [] },
			() => this.getEvents()
		)
	}
	render() {
		return (
			<React.Fragment>
				<OffHours
					events={this.state.events}
					onEditSlot={this.handleEditSlot}
					onSlotSelect={this.handleSelectSlot}
					selectedEvent={this.state.selectedEvent}
					techAssignments={this.state.techAssignments}
					onEventSave={this.handleEventSave}
					creating={this.state.creating}
					createEvent={this.state.createEvent}
					onCancelEvent={this.handleCancelEvent}
					onTerritoryChange={this.handleTerritoryChange}
					selectedTerritory={this.state.selectedTerritory}
					isLoading={this.state.isLoading}
				/>
			</React.Fragment>
		)
	}
}

export default withCallApi(OffHoursContainer)

import { FC, useMemo, useState } from "react"

import {
	InventoryLocation,
	LocationId,
	makeApiErrorMessage,
	useUpdatePartOrder,
} from "@ncs/ncs-api"
import { ExtendableModalProps, LocationSelector, Modal, Paragraph, useToast } from "@ncs/web-legos"

export interface ChangeDcRoutingModalProps extends ExtendableModalProps {
	partOrderId: string
	locationIds: string[]
}

export const ChangeDcRoutingModal: FC<ChangeDcRoutingModalProps> = ({
	partOrderId,
	locationIds,
	...rest
}) => {
	const { makeSuccessToast } = useToast()
	const [fromDc, setFromDc] = useState<InventoryLocation | null>(null)
	const [toDc, setToDc] = useState<InventoryLocation | null>(null)
	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const updateOrder = useUpdatePartOrder(partOrderId)

	const aLocationIsDallas = useMemo(() => {
		return locationIds.some((id) => id === LocationId.PartsWarehouse)
	}, [locationIds])

	const handleSave = async () => {
		try {
			if (!fromDc?.id || !toDc?.id) {
				throw new Error("Must choose both a From and To DC")
			}
			if (fromDc.id === toDc.id) {
				throw new Error("Cannot use the same DC for From and To")
			}

			setIsSaving(true)
			await updateOrder({
				updates: {
					oldWarehouseId: fromDc.id,
					newWarehouseId: toDc.id,
				},
				id: partOrderId,
			})
			makeSuccessToast("Order updated")
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	return (
		<Modal
			{...rest}
			title="Change DC Routing"
			rightButtons={{
				buttonText: "Reallocate",
				isLoading: isSaving,
				onClick: handleSave,
			}}
			errorText={errorText}
		>
			<Paragraph mb={1}>Reroute all line items not in a pick list from:</Paragraph>

			<LocationSelector
				value={fromDc}
				onChange={setFromDc}
				filter={(l) => locationIds.includes(l.id)}
				label="From DC:"
			/>

			<LocationSelector
				value={toDc}
				onChange={setToDc}
				warehousesOnly
				filter={(l) => !fromDc || l.id !== fromDc.id}
				label="To DC:"
				addFakeGrimes={aLocationIsDallas}
			/>
		</Modal>
	)
}

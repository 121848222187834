import React from "react"
import moment from "moment"

import AddCommentIcon from "@material-ui/icons/AddComment"

//custom components
import {
	GridContainer,
	QuickCard,
	GridItem,
	ButtonWithIcon,
	UserSelector,
	Datetime,
	MultiSelect,
} from "~/components"

// icons
import RemoveIcon from "@material-ui/icons/Remove"
import AddIcon from "@material-ui/icons/Add"
import DeleteIcon from "@material-ui/icons/Delete"

// styles
import { withStyles } from "@material-ui/core/styles"
const styles = {
	right: {
		textAlign: "right",
	},
}

function EditOffHoursEvent(props) {
	let {
		onTechSelected,
		onStartDateChange,
		onEndDateChange,
		onTechAssSelected,
		selectedTechAss,
		techAssignments,
		event,
		startDate,
		endDate,
		onEventSave,
		onCancelEvent,
		onDeleteEvent,
	} = props
	return (
		<GridContainer>
			<GridItem xs={12}>
				<QuickCard title={"Edit Event"} icon={<AddCommentIcon />}>
					<GridContainer>
						<GridItem xs={12} md={4}>
							<UserSelector
								onUserSelected={onTechSelected}
								defaultValue={{
									_id: event ? event.techId : null,
									name: event ? event.title : "",
								}}
							/>
						</GridItem>
						<GridItem xs={12} md={4}>
							<Datetime
								labelText="Start"
								timeFormat={false}
								dateFormat="MM-DD-YYYY"
								onChange={(e) => onStartDateChange(moment(e))}
								value={
									startDate ? moment(startDate)
									: event ?
										moment(event.startDate)
									:	null
								}
								inputProps={{ disabled: true }}
							/>
						</GridItem>
						<GridItem xs={12} md={4}>
							<Datetime
								labelText="End"
								timeFormat={false}
								dateFormat="MM-DD-YYYY"
								onChange={(e) => onEndDateChange(moment(e))}
								value={
									endDate ? moment(endDate)
									: event ?
										moment(event.endDate)
									:	null
								}
								inputProps={{ disabled: true }}
							/>
						</GridItem>
						<GridItem xs={12} md={4}>
							<MultiSelect
								options={techAssignments}
								valueAccessor="_id"
								textAccessor="description"
								value={selectedTechAss}
								onChange={onTechAssSelected}
								labelText={"Groups"}
							/>
						</GridItem>
						<GridItem xs={12}></GridItem>
						<GridItem>
							<ButtonWithIcon
								icon={<RemoveIcon />}
								content="Cancel"
								size="sm"
								round
								color="warning"
								onClick={onCancelEvent}
							/>
						</GridItem>
						<GridItem>
							<ButtonWithIcon
								icon={<DeleteIcon />}
								content="Remove"
								size="sm"
								round
								color="danger"
								onClick={onDeleteEvent}
							/>
						</GridItem>
						<GridItem>
							<ButtonWithIcon
								icon={<AddIcon />}
								content="Confirm"
								size="sm"
								round
								color="success"
								onClick={onEventSave}
							/>
						</GridItem>
					</GridContainer>
				</QuickCard>
			</GridItem>
		</GridContainer>
	)
}

export default withStyles(styles)(EditOffHoursEvent)

import { FC, useEffect, useMemo, useRef, useState } from "react"

import {
	ConnectivityDeviceTypeId,
	Customer,
	DeviceRegistrationResults,
	Equipment,
	makeApiErrorMessage,
	useRegisterDevice,
} from "@ncs/ncs-api"
import { unpythonify } from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	Box,
	Button,
	ConnectivityDeviceTypeSelector,
	cssMixins,
	CustomerEquipmentSelector,
	CustomerSelector,
	Divider,
	ErrorText,
	Icon,
	Paragraph,
	RadioBoolean,
	TextInput,
	Tooltip,
	useChangeCallback,
	useIsSaving,
} from "@ncs/web-legos"

import { RegistrationResultsModal } from "./components"

export const RegisterNewDeviceTab: FC = () => {
	const [customer, setCustomer] = useState<Customer | null>(null)
	const [equipment, setEquipment] = useState<Equipment | null>(null)
	const [deviceType, setDeviceType] = useState<string | null>(null)
	const [deviceInfo, setDeviceInfo] = useState<string | null>(null)
	const [enteringUrl, setEnteringUrl] = useState(false)
	const { isSaving, setSaving, endSaving } = useIsSaving()
	const [errorText, setErrorText] = useState<string | null>(null)
	const [results, setResults] = useState<DeviceRegistrationResults | null>(null)

	const washCounterInputRef = useRef<HTMLInputElement>(null)

	const registerDevice = useRegisterDevice()

	const assembledAccuPumpUrl = useMemo(() => {
		return enteringUrl ? deviceInfo : `${accuPumpUrl}${deviceInfo || ""}`
	}, [enteringUrl, deviceInfo])

	const handleSubmit = async () => {
		try {
			if (!equipment) {
				throw new Error("Select customer equipment to tie connectivity device to")
			}
			if (!deviceType) {
				throw new Error("Select connectivity device type")
			}
			if (!deviceInfo) {
				throw new Error("Enter the new device's identifier information")
			}
			if (
				deviceType === ConnectivityDeviceTypeId.WashCount &&
				(!assembledAccuPumpUrl || assembledAccuPumpUrl.startsWith(accuPumpUrl) === false)
			) {
				throw new Error(
					"Invalid device code. Verify that you are inputting the proper information based on your selection"
				)
			}

			const finalDeviceInfo =
				deviceType === ConnectivityDeviceTypeId.WashCount ?
					assembledAccuPumpUrl
				:	deviceInfo

			if (!finalDeviceInfo) {
				// Shouldn't ever get here.
				throw new Error(
					"Could not properly assemble device identifier information. Please contact support"
				)
			}

			setSaving()

			const { data } = await registerDevice({
				deviceType,
				customerEquipment: equipment.id,
				deviceInfo: finalDeviceInfo,
			})
			setResults(unpythonify(data))
		} catch (e) {
			setErrorText(makeApiErrorMessage(e))
		} finally {
			endSaving()
		}
	}

	// Whenever customer changes, clear out the equipment selection.
	useChangeCallback(customer?.id, () => setEquipment(null))

	useEffect(() => {
		setErrorText(null)
	}, [customer, equipment, deviceType, deviceInfo, enteringUrl])

	return (
		<>
			<Box maxWidth={40} smProps={{ maxWidth: "none" }}>
				<Box mb={3}>
					<Paragraph mb={0.5}>
						Select the customer equipment to tie this new device to.
					</Paragraph>
					<CustomerSelector value={customer} onChange={setCustomer} label={null} />
					<CustomerEquipmentSelector
						customerId={customer?.id}
						value={equipment}
						onChange={setEquipment}
						disabled={!customer}
						label="Customer equipment"
					/>
				</Box>

				<Box mb={3}>
					<Paragraph mb={0.5}>Select the type of connectivity device.</Paragraph>
					<ConnectivityDeviceTypeSelector
						value={deviceType}
						onChange={(newValue) => {
							setDeviceType(newValue)
							washCounterInputRef.current?.focus()
						}}
						fillContainer
						label={null}
					/>
				</Box>

				<AnimatedEntrance
					show={deviceType === ConnectivityDeviceTypeId.Machine}
					direction="down"
				>
					<Paragraph mb={0.5}>Enter the new device's serial number.</Paragraph>
				</AnimatedEntrance>

				<AnimatedEntrance
					show={deviceType === ConnectivityDeviceTypeId.WashCount}
					direction="down"
				>
					<Box d="flex" gap={0.5}>
						<Paragraph>
							Enter the code string that comes after <strong>qrcode=</strong> at the
							end of the Accu-Pump URL printed on the device, or enter the entire
							full URL.
						</Paragraph>
						<Tooltip
							title={
								<>
									When registering a new Accu-Pump wash counter device, you need
									to submit either the full URL that's printed on the device, or
									just the final code string in it.
									<br />
									<br />
									To enter just the final code, type in the numbers or letters
									that come after <strong>qrcode=</strong>. For example, if you
									see{" "}
									<span css={cssMixins.breakAnywhereStyle}>
										<strong>{assembledAccuPumpUrl}ABC123456</strong>
									</span>{" "}
									printed on it, you would enter here <strong>ABC123456</strong>.
									<br />
									<br />
									Or, you can choose to enter the entire URL. This is probably
									most useful in cases where you have a scanning gun that can
									read a QR code and enter it into your computer or mobile
									device. The QR code contains the full URL.
								</>
							}
						>
							<Box top="2px">
								<Icon icon="info-circle" color="gray" />
							</Box>
						</Tooltip>
					</Box>
					<RadioBoolean
						value={enteringUrl}
						onChange={(newValue) => {
							setEnteringUrl(newValue)
							washCounterInputRef.current?.focus()
						}}
						htmlName="entering-url"
						noText="I will type in the code from the end of the URL"
						yesText="I will type in or scan the entire URL"
						noFirst
					/>
				</AnimatedEntrance>

				{deviceType === ConnectivityDeviceTypeId.Vacuum && (
					<Paragraph secondary>
						Registering new vacuum devices is not supported through this site at this
						time.
					</Paragraph>
				)}

				<AnimatedEntrance
					show={!!deviceType && deviceType !== ConnectivityDeviceTypeId.Vacuum}
					direction="down"
				>
					{deviceType === ConnectivityDeviceTypeId.WashCount && (
						<Paragraph small secondary mb={0.5}>
							Accu-Guard URL to be submitted: {assembledAccuPumpUrl}
						</Paragraph>
					)}
					<TextInput
						autoFocus
						ref={washCounterInputRef}
						value={deviceInfo}
						onChange={setDeviceInfo}
						placeholder="Device identifier info..."
						disabled={!deviceType}
						clearable
					/>
				</AnimatedEntrance>

				<Divider my={3} />

				{!!errorText && (
					<ErrorText mt={-2} mb={1}>
						{errorText}
					</ErrorText>
				)}

				<Button
					fillContainer
					variant="primary-cta"
					onClick={handleSubmit}
					isLoading={isSaving()}
					disabled={!!results || deviceType === ConnectivityDeviceTypeId.Vacuum}
				>
					Register Device
				</Button>
			</Box>

			{!!results && (
				<RegistrationResultsModal results={results} onClose={() => setResults(null)} />
			)}
		</>
	)
}

const accuPumpUrl = "https://www.accu-pump.com/qrdevice?qrcode="

import { FC, useMemo, useState } from "react"

import {
	Customer,
	makeApiErrorMessage,
	useCustomerBillTos,
	UserProfile,
	useUpdateCustomerUserLocations,
} from "@ncs/ncs-api"
import {
	AnimatedEntrance,
	CustomerSelector,
	ExtendableModalProps,
	LoadingSpinner,
	Modal,
	Paragraph,
	RadioGroup,
	useToast,
} from "@ncs/web-legos"

export interface AddSiteModalProps extends ExtendableModalProps {
	user: UserProfile
}

enum SelectionType {
	Selection = "1",
	SameBillTos = "2",
	BillToSelection = "3",
}

const options = [
	{
		label: "Selected site only",
		value: SelectionType.Selection,
	},
	{
		label: "Selection plus all sites with same default bill-to",
		value: SelectionType.SameBillTos,
	},
	{
		label: "All sites that bill to selection",
		value: SelectionType.BillToSelection,
	},
]

export const AddSiteModal: FC<AddSiteModalProps> = ({ user, ...rest }) => {
	const { makeSuccessToast } = useToast()
	const [selectionType, setSelectionType] = useState<SelectionType>(SelectionType.Selection)
	const [selectedSite, setSelectedSite] = useState<Customer | null>(null)
	const [billTos, billTosLoading] = useCustomerBillTos(selectedSite?.id)
	const selectionDefaultBillToId = useMemo(() => {
		return (billTos ?? []).find((b) => b.isDefault)?.id
	}, [billTos])
	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)
	const updateLocations = useUpdateCustomerUserLocations(user.id.toString())

	const onSave = async () => {
		if (!selectedSite) return

		try {
			setIsSaving(true)
			await updateLocations({
				addCustomerIds:
					selectionType === SelectionType.Selection ? [selectedSite.id] : null,
				addBillToIds:
					selectionType === SelectionType.BillToSelection ? [selectedSite.id]
					: selectionType === SelectionType.SameBillTos && selectionDefaultBillToId ?
						[selectionDefaultBillToId]
					:	null,
				removeCustomerIds: null,
			})
			makeSuccessToast("Site(s) added to user")
			rest.onClose()
		} catch (e) {
			setErrorText(makeApiErrorMessage(e))
			setIsSaving(false)
		}
	}

	const reset = () => {
		setSelectionType(SelectionType.Selection)
		setSelectedSite(null)
		setIsSaving(false)
		setErrorText(null)
	}

	return (
		<Modal
			{...rest}
			title="Add Site To User"
			rightButtons={{
				buttonText: "Add Site(s)",
				isLoading: isSaving,
				onClick: onSave,
				disabled:
					!selectedSite || (selectionType !== SelectionType.Selection && billTosLoading),
			}}
			onOpen={reset}
			errorText={errorText}
		>
			<Paragraph mb={1}>
				Search all customers and make a selection. You can then add just your selection, or
				your selection and all others that have the same bill-to as your selection, or all
				sites that bill to your selection.
			</Paragraph>

			<CustomerSelector value={selectedSite} onChange={setSelectedSite} autoFocus />

			{selectionType !== SelectionType.Selection && billTosLoading && (
				<LoadingSpinner my={0} py={0} pb={1} justifyContent="flex-start" />
			)}

			<AnimatedEntrance show={!!selectedSite}>
				<RadioGroup
					htmlName="selection-type"
					options={options}
					value={selectionType}
					onChange={(newValue, option) => setSelectionType(option.value)}
				/>
			</AnimatedEntrance>
		</Modal>
	)
}

import React from "react"
import PropTypes from "prop-types"

import { GridContainer } from "../../components"

import FormValidatorField from "./FormValidatorField"

const defaultWidths = { xs: 12, sm: 6, md: 4, lg: 3 }

class FormValidatorFieldList extends React.Component {
	render = () => {
		const { fields, formValidator, isEditing, ...rest } = this.props

		return (
			<GridContainer>
				{fields.map((config) => {
					if (typeof config === "string") {
						return (
							<FormValidatorField
								key={config}
								field={config}
								formValidator={this.props.formValidator}
								{...defaultWidths}
								{...rest}
							/>
						)
					}

					const { field, stateName, ...configProps } = config

					return (
						<FormValidatorField
							key={stateName}
							field={field ?? stateName}
							formValidator={this.props.formValidator}
							{...defaultWidths}
							{...configProps}
							{...rest}
						/>
					)
				})}
			</GridContainer>
		)
	}
}

FormValidatorFieldList.propTypes = {
	/** formValidator instance controlling this form field */
	formValidator: PropTypes.object.isRequired,

	/** true if in editing mode, false if in read-only mode. defaults to false */
	isEditing: PropTypes.bool,

	fields: PropTypes.arrayOf(
		PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.shape({
				stateName: PropTypes.string.isRequired,
				labelText: PropTypes.string,
				type: PropTypes.oneOf([
					"string",
					"object",
					"bool",
					"array",
					"email",
					"number",
					"currency",
					"integer",
					"date",
				]),
				isRequired: PropTypes.bool,
				maxLength: PropTypes.number,
				minValue: PropTypes.number,
				maxValue: PropTypes.number,
				dependents: PropTypes.arrayOf(PropTypes.string),
				customValidation: PropTypes.func,
				default: PropTypes.any,
				readOnly: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
				editorProps: PropTypes.object,
			}),
		])
	),
}

export default FormValidatorFieldList

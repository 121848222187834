import { useMemo } from "react"
import { useSelector } from "react-redux"
import { useQuery } from "react-query"
import omit from "lodash/omit"
import { get, put, rsaaTypes, RSAA } from "@ncs/bricks/middleware/portalApiMiddleware"
import { durationToWords } from "@ncs/bricks/util/dates"
import { useCallApi, useDebounce } from "@ncs/bricks/util/hooks"
import { unpythonify } from "@ncs/bricks/util/variableCasing"
import { generateQuerystringParameters } from "@ncs/bricks/redux/services/dynamicTables"

export const SERVICE_DASHBOARD = rsaaTypes("dashboard/service_dashboard")
export const UPDATE_DASHBOARD_TERRITORY = "dashboard/UPDATE_DASHBOARD_TERRITORY"
export const GET_OPEN_DISPATCHES_DASHBOARD = rsaaTypes(
	"reports/customer_dashboard/open_dispatches"
)
export const GET_OPEN_ORDERS_DASHBOARD = rsaaTypes("reports/customer_dashboard/open_orders")
export const GET_ARRIVAL_TIME_DASHBOARD = rsaaTypes("reports/customer_dashboard/arrival_time")
export const GET_RESPONSE_TIME_DASHBOARD = rsaaTypes("reports/customer_dashboard/response_time")
export const GET_INVOICED_DOLLARS_PER_MONTH_DASHBOARD = rsaaTypes(
	"reports/customer_dashboard/invoiced_dollars_per_month"
)
export const GET_INVOICED_DOLLARS_MONTH_TO_DATE_DASHBOARD = rsaaTypes(
	"reports/customer_dashboard/invoiced_dollars_month_to_date"
)
export const GET_OUTSTANDING_BALANCE_DASHBOARD = rsaaTypes(
	"reports/customer_dashboard/outstanding_balance_per_month"
)
export const GET_DISPATCHES_BY_MONTH_DASHBOARD = rsaaTypes(
	"reports/customer_dashboard/dispatches_by_month"
)
export const GET_ORDERS_BY_MONTH_DASHBOARD = rsaaTypes(
	"reports/customer_dashboard/orders_by_month"
)
export const GET_DASHBOARD_CONFIG = rsaaTypes("dashboards/get")
export const UPDATE_DASHBOARD_CONFIG = rsaaTypes("dashboards/put")

export const getServiceDashboard = () => ({
	[RSAA]: {
		api: get("/reports/user_mtd_report/"),
		types: SERVICE_DASHBOARD,
	},
})

export function updateTerritorySelected(terr, dashboard) {
	return {
		type: UPDATE_DASHBOARD_TERRITORY,
		terr,
		dashboard,
	}
}

const getCustomerDashboardQuerystringParams = (getState, customerId, querystringParams = {}) => {
	let site = customerId ?? getState().customers?.selectedCustomer
	if (site && Number(site) > 0) {
		if (!querystringParams) {
			return { site }
		}
		querystringParams.site = site
	}
	return querystringParams
}

const getCustomerDashboardApi =
	(report, rsaaType, customerId, querystringParams) => (dispatch, getState) => {
		let url = `/reports/customer_dashboard/${report}/`

		dispatch({
			[RSAA]: {
				api: get(
					url,
					getCustomerDashboardQuerystringParams(getState, customerId, querystringParams)
				),
				types: rsaaType,
			},
		})
	}

const getQuerystringParams = (dynamicTableState) => {
	const querystringParams =
		!dynamicTableState ? {} : generateQuerystringParameters(dynamicTableState)

	return omit(querystringParams, [
		"call_received_gte",
		"closed_date_gte",
		"start_date",
		"end_date",
		"page",
		"pageSize",
	])
}

const useCustomerDashboardApi = (report, rsaaType, filtersReduxKey) => {
	const customerId = useSelector((x) => x.customers.selectedCustomer)
	const dynamicTableState = useSelector((x) =>
		!filtersReduxKey ? null : x.dynamicTables[filtersReduxKey]
	)
	const querystringParams = useMemo(
		() => (!filtersReduxKey ? null : getQuerystringParams(dynamicTableState)),
		[filtersReduxKey, dynamicTableState]
	)
	const debouncedParams = useDebounce(querystringParams, 600, { maxWait: 10000 })

	const callApi = useCallApi()
	return useQuery([report, customerId, debouncedParams], () =>
		callApi(getCustomerDashboardApi(report, rsaaType, customerId, debouncedParams))
	)
}

export function useOpenDispatches(filtersReduxKey) {
	const { data, ...rest } = useCustomerDashboardApi(
		"dispatches/open_dispatches",
		GET_OPEN_DISPATCHES_DASHBOARD,
		filtersReduxKey
	)

	const { openDispatches, oldestOpenDispatchDate } =
		!!data?.payload ? unpythonify(data?.payload) : {}

	return {
		...rest,
		openDispatches,
		oldestOpenDispatchDate,
	}
}

export function useOpenOrders(filtersReduxKey) {
	const { data, ...rest } = useCustomerDashboardApi(
		"orders/open_orders",
		GET_OPEN_ORDERS_DASHBOARD,
		filtersReduxKey
	)
	const { openOrders, oldestOpenOrderDate } = !!data?.payload ? unpythonify(data?.payload) : {}

	return {
		...rest,
		openOrders,
		oldestOpenOrderDate,
	}
}

function humanizeDurations(isLoading, average, longest) {
	if (isLoading || typeof average === "undefined" || typeof longest === "undefined") {
		return {
			humanizedAverageDuration: "",
			humanizedLongestDuration: "",
		}
	}

	return {
		humanizedAverageDuration: durationToWords(average),
		humanizedLongestDuration: durationToWords(longest),
	}
}

export function useArrivalTime(filtersReduxKey) {
	const { isLoading, data, ...rest } = useCustomerDashboardApi(
		"dispatches/arrival_time",
		GET_ARRIVAL_TIME_DASHBOARD,
		filtersReduxKey
	)

	const { averageArrivalTimeInMinutes, longestArrivalTimeInMinutes } =
		!!data?.payload ? unpythonify(data?.payload) : {}

	return {
		isLoading,
		averageArrivalTimeInMinutes,
		longestArrivalTimeInMinutes,
		...humanizeDurations(isLoading, averageArrivalTimeInMinutes, longestArrivalTimeInMinutes),
		...rest,
	}
}

export function useResponseTime(filtersReduxKey) {
	const { isLoading, data, ...rest } = useCustomerDashboardApi(
		"dispatches/response_time",
		GET_RESPONSE_TIME_DASHBOARD,
		filtersReduxKey
	)

	const { averageResponseTimeInMinutes, longestResponseTimeInMinutes } =
		!!data?.payload ? unpythonify(data?.payload) : {}

	return {
		isLoading,
		averageResponseTimeInMinutes,
		longestResponseTimeInMinutes,
		...humanizeDurations(
			isLoading,
			averageResponseTimeInMinutes,
			longestResponseTimeInMinutes
		),
		...rest,
	}
}

export function useInvoicedDollarsPerMonth(filtersReduxKey) {
	const { data, ...rest } = useCustomerDashboardApi(
		"invoices/invoiced_dollars_per_month",
		GET_INVOICED_DOLLARS_PER_MONTH_DASHBOARD,
		filtersReduxKey
	)

	const monthlyTotalDollars = Array.isArray(data?.payload) ? unpythonify(data?.payload) : []

	return {
		...rest,
		monthlyTotalDollars,
	}
}

export function useInvoicedDollarsMonthToDate(filtersReduxKey) {
	const { data: { error, payload: mtdDollars } = { payload: null }, ...rest } =
		useCustomerDashboardApi(
			"invoices/invoiced_dollars_month_to_date",
			GET_INVOICED_DOLLARS_MONTH_TO_DATE_DASHBOARD,
			filtersReduxKey
		)

	return {
		...rest,
		mtdDollars: !error && /^\d+(\.\d+)?$/.test(mtdDollars) ? mtdDollars : undefined,
	}
}

export function useOutstandingBalance(filtersReduxKey) {
	const { data: { error, payload: outstandingBalance } = { payload: null }, ...rest } =
		useCustomerDashboardApi(
			"invoices/outstanding_balance",
			GET_OUTSTANDING_BALANCE_DASHBOARD,
			filtersReduxKey
		)

	return {
		...rest,
		outstandingBalance:
			!error && /^\d+(\.\d+)?$/.test(outstandingBalance) ? outstandingBalance : undefined,
	}
}

export function useDispatchesByMonth(filtersReduxKey) {
	const { data, ...rest } = useCustomerDashboardApi(
		"dispatches/dispatches_by_month",
		GET_DISPATCHES_BY_MONTH_DASHBOARD,
		filtersReduxKey
	)

	const dispatches = Array.isArray(data?.payload) ? unpythonify(data?.payload) : []

	return {
		...rest,
		dispatches,
	}
}

export function useOrdersByMonth(filtersReduxKey) {
	const { data, ...rest } = useCustomerDashboardApi(
		"orders/orders_by_month",
		GET_ORDERS_BY_MONTH_DASHBOARD,
		filtersReduxKey
	)

	const orders = Array.isArray(data?.payload) ? unpythonify(data?.payload) : []

	return {
		...rest,
		orders,
	}
}

export const getDashboardConfig = (key) => ({
	[RSAA]: {
		api: get(`/dashboards/${key}/`),
		types: GET_DASHBOARD_CONFIG,
		meta: { key },
	},
})

export const updateDashboardConfig = (key, config) => ({
	[RSAA]: {
		api: put(`/dashboards/${key}/`, { config }),
		types: UPDATE_DASHBOARD_CONFIG,
		meta: { key },
	},
})

const initialState = {
	isInitialState: true,
	service: [],
	finance: {},
	support: {},
	userTerritories: [],
	territorySelected: "",
	activeDashboard: {},
	error: false,
}

const dashboards = (state = initialState, action) => {
	switch (action.type) {
		case SERVICE_DASHBOARD.request:
			return {
				...state,
			}
		case SERVICE_DASHBOARD.success:
			if (action.payload.metrics.length > 0) {
				return {
					...state,
					service: action.payload.metrics,
					userTerritories: action.payload.territories,
					activeDashboard: action.payload.metrics[0],
					territorySelected: action.payload.metrics[0].territory_id,
					lastRefreshed: Date.now(),
					isInitialState: false,
				}
			} else {
				return {
					...state,
					service: [],
					userTerritories: action.payload.territories,
					lastRefreshed: Date.now(),
					isInitialState: false,
				}
			}
		case SERVICE_DASHBOARD.failure:
			return {
				...state,
				error: true,
			}
		case UPDATE_DASHBOARD_TERRITORY:
			return {
				...state,
				activeDashboard: action.dashboard,
				territorySelected: action.terr,
			}
		default:
			return state
	}
}
export default dashboards

import moment from "moment-timezone"
import { default as pluralize } from "./pluralization"

export const localTimezoneAbbreviation = () => moment.tz(moment.tz.guess()).zoneAbbr()

export const getDateRangeForFilter = (dateRange) => {
	let today = moment().startOf("day")
	switch (dateRange.toLowerCase()) {
		case "5-years":
			return today.subtract(5, "years")
		case "2-years":
			return today.subtract(2, "years")
		case "1-year":
			return today.subtract(1, "years")
		case "ytd":
			return today.startOf("year")
		case "3-months":
			return today.subtract(3, "months")
		case "6-months":
			return today.subtract(6, "months")
		case "last-month":
			return today.subtract(1, "months").startOf("month")
		case "this-month":
			return today.startOf("month")
		case "30-days":
			return today.subtract(30, "days")
		case "2-weeks":
			return today.subtract(14, "days")
		case "1-week":
			return today.subtract(7, "days")
		case "yesterday":
			return today.subtract(1, "days")
		case "today":
			return today
		default:
			return null
	}
}

export const DATE_RANGES = [
	{ value: "all", text: "All Time" },
	{ value: "today", text: "Today" },
	{ value: "yesterday", text: "Since Yesterday" },
	{ value: "1-week", text: "Within 1 Week" },
	{ value: "2-weeks", text: "Within 2 Weeks" },
	{ value: "30-days", text: "Within 30 days" },
	{ value: "this-month", text: "This Month" },
	{ value: "last-month", text: "Since Last Month" },
	{ value: "3-months", text: "Within 3 Months" },
	{ value: "6-months", text: "Within 6 Months" },
	{ value: "ytd", text: "Year-to-Date" },
	{ value: "1-year", text: "Within 1 Year" },
	{ value: "2-years", text: "Within 2 Years" },
	{ value: "5-years", text: "Within 5 Years" },
]

const reportPeriods = [
	{ value: "all", text: "All Dates", from: () => "", to: () => "" },
	{ value: "custom", text: "Custom", from: () => undefined, to: () => undefined },
	{
		value: "today",
		text: "Today",
		from: (date = moment()) => date.startOf("day"),
		to: (date = moment()) => date.endOf("day"),
	},
	{
		value: "yesterday",
		text: "Yesterday",
		past: true,
		from: (date = moment()) => date.subtract(1, "day").startOf("day"),
		to: (date = moment()) => date.subtract(1, "day").endOf("day"),
	},
	{
		value: "recent",
		text: "Recent",
		past: true,
		from: (date = moment()) => date.subtract(5, "days").startOf("day"),
		to: (date = moment()) => date.endOf("day"),
	},
	{
		value: "this-Week",
		text: "This Week",
		from: (date = moment()) => date.startOf("week"),
		to: (date = moment()) => date.endOf("week"),
	},
	{
		value: "this-week-to-date",
		text: "This Week-to-date",
		future: true,
		from: (date = moment()) => date.startOf("week"),
		to: (date = moment()) => date.endOf("day"),
	},
	{
		value: "this-month",
		text: "This Month",
		from: (date = moment()) => date.startOf("month"),
		to: (date = moment()) => date.endOf("month"),
	},
	{
		value: "this-month-to-date",
		text: "This Month-to-date",
		future: true,
		from: (date = moment()) => date.startOf("month"),
		to: (date = moment()) => date.endOf("day"),
	},
	{
		value: "this-quarter",
		text: "This Quarter",
		from: (date = moment()) => date.startOf("quarter"),
		to: (date = moment()) => date.endOf("quarter"),
	},
	{
		value: "this-quarter-to-date",
		text: "This Quarter-to-date",
		future: true,
		from: (date = moment()) => date.startOf("quarter"),
		to: (date = moment()) => date.endOf("day"),
	},
	{
		value: "this-year",
		text: "This Year",
		from: (date = moment()) => date.startOf("year"),
		to: (date = moment()) => date.endOf("year"),
	},
	{
		value: "this-year-to-date",
		text: "This Year-to-date",
		future: true,
		from: (date = moment()) => date.startOf("year"),
		to: (date = moment()) => date.endOf("day"),
	},
	{
		value: "this-year-to-last-month",
		text: "This Year-to-last-month",
		past: true,
		from: (date = moment()) => date.startOf("year"),
		to: (date = moment()) => date.subtract(1, "month").endOf("month"),
	},
	{
		value: "last-week",
		text: "Last Week",
		past: true,
		from: (date = moment()) => date.subtract(1, "week").startOf("week"),
		to: (date = moment()) => date.subtract(1, "week").endOf("week"),
	},
	{
		value: "last-week-to-date",
		text: "Last Week-to-date",
		past: true,
		from: (date = moment()) => date.subtract(1, "week").startOf("week"),
		to: (date = moment()) => date.subtract(1, "week").endOf("day"),
	},
	{
		value: "last-month",
		text: "Last Month",
		past: true,
		from: (date = moment()) => date.subtract(1, "month").startOf("month"),
		to: (date = moment()) => date.subtract(1, "month").endOf("month"),
	},
	{
		value: "last-month-to-date",
		text: "Last Month-to-date",
		past: true,
		from: (date = moment()) => date.subtract(1, "month").startOf("month"),
		to: (date = moment()) => date.subtract(1, "month").endOf("day"),
	},
	{
		value: "last-quarter",
		text: "Last Quarter",
		past: true,
		from: (date = moment()) => date.subtract(1, "quarter").startOf("quarter"),
		to: (date = moment()) => date.subtract(1, "quarter").endOf("quarter"),
	},
	{
		value: "last-quarter-to-date",
		text: "Last Quarter-to-date",
		past: true,
		from: (date = moment()) => date.subtract(1, "quarter").startOf("quarter"),
		to: (date = moment()) => date.subtract(1, "quarter").endOf("day"),
	},
	{
		value: "last-year",
		text: "Last Year",
		past: true,
		from: (date = moment()) => date.subtract(1, "year").startOf("year"),
		to: (date = moment()) => date.subtract(1, "year").endOf("year"),
	},
	{
		value: "last-year-to-date",
		text: "Last Year-to-date",
		past: true,
		from: (date = moment()) => date.subtract(1, "year").startOf("year"),
		to: (date = moment()) => date.subtract(1, "year").endOf("day"),
	},
	{
		value: "since-30-days-ago",
		text: "Since 30 Days Ago",
		past: true,
		from: (date = moment()) => date.subtract(30, "days").startOf("day"),
		to: (date = moment()) => date.endOf("day"),
	},
	{
		value: "since-60-days-ago",
		text: "Since 60 Days Ago",
		past: true,
		from: (date = moment()) => date.subtract(60, "days").startOf("day"),
		to: (date = moment()) => date.endOf("day"),
	},
	{
		value: "since-90-days-ago",
		text: "Since 90 Days Ago",
		past: true,
		from: (date = moment()) => date.subtract(90, "days").startOf("day"),
		to: (date = moment()) => date.endOf("day"),
	},
	{
		value: "since-6-months-ago",
		text: "Since 6 Months Ago",
		past: true,
		from: (date = moment()) => date.subtract(6, "month").startOf("day"),
		to: (date = moment()) => date.endOf("day"),
	},
	{
		value: "since-1-year-ago",
		text: "Since 1 Year Ago",
		past: true,
		from: (date = moment()) => date.subtract(1, "year").startOf("day"),
		to: (date = moment()) => date.endOf("day"),
	},
	{
		value: "next-week",
		text: "Next Week",
		future: true,
		from: (date = moment()) => date.add(1, "week").startOf("week"),
		to: (date = moment()) => date.add(1, "week").endOf("week"),
	},
	{
		value: "next-4-weeks",
		text: "Next 4 Weeks",
		future: true,
		from: (date = moment()) => date.add(1, "week").startOf("week"),
		to: (date = moment()) => date.add(4, "week").endOf("week"),
	},
	{
		value: "next-month",
		text: "Next Month",
		future: true,
		from: (date = moment()) => date.add(1, "month").startOf("month"),
		to: (date = moment()) => date.add(1, "month").endOf("month"),
	},
	{
		value: "next-quarter",
		text: "Next Quarter",
		future: true,
		from: (date = moment()) => date.add(1, "quarter").startOf("quarter"),
		to: (date = moment()) => date.add(1, "quarter").endOf("quarter"),
	},
	{
		value: "next-year",
		text: "Next Year",
		future: true,
		from: (date = moment()) => date.add(1, "year").startOf("year"),
		to: (date = moment()) => date.add(1, "year").endOf("year"),
	},
]

const getReportPeriods = (includePast, includeFuture) => {
	return reportPeriods.filter(
		(x) =>
			(includeFuture && includePast) ||
			(includePast && !x.future) ||
			(includeFuture && !x.past)
	)
}

export const REPORT_PERIODS_PAST = getReportPeriods(true, false)
export const REPORT_PERIODS_FUTURE = getReportPeriods(false, true)
export const REPORT_PERIODS_ALL = getReportPeriods(true, true)

export const durationToWords = (minutes) => {
	if (typeof minutes === "undefined" || minutes === null) {
		return "N/A"
	}

	let duration = moment.duration(minutes, "minutes")
	let pieces = []

	let days = duration.asDays()
	if (days >= 1) {
		pieces.push(`${Math.floor(days)} ${pluralize("day", days)}`)
	}

	let hours = duration.hours()
	if (days >= 1 || hours > 0) {
		pieces.push(`${hours} ${pluralize("hour", hours)}`)
	}

	let mins = duration.minutes()
	pieces.push(`${mins} ${pluralize("minute", mins)}`)

	return pieces.join(", ")
}

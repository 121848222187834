import { FC, useState } from "react"

import {
	APPLICATION,
	ContractDocument,
	useContractDocuments,
	useCreateContractDocument,
	useUserCanUse,
} from "@ncs/ncs-api"
import {
	Box,
	Button,
	Divider,
	DownloadDocumentButton,
	Heading,
	LoadingSpinner,
	Paragraph,
	useToast,
} from "@ncs/web-legos"

import { AddContractDocumentModal } from "../AddContractDocumentModal"

export interface ContractDetailDocumentsTabProps {
	contractId: string
}

export const ContractDetailDocumentsTab: FC<ContractDetailDocumentsTabProps> = ({
	contractId,
}) => {
	const canEdit = useUserCanUse(APPLICATION.ContractCreator)
	const { makeSuccessToast } = useToast()
	const [showAddModal, setShowAddModal] = useState(false)
	const [documentToEdit, setDocumentToEdit] = useState<ContractDocument | null>(null)

	const [documents, documentsLoading] = useContractDocuments(contractId)

	const createDocument = useCreateContractDocument()

	const handleCreateDocument = async (description: string, file: File) => {
		await createDocument({
			contract: contractId,
			description,
			file,
		})
		makeSuccessToast("Document added to contract")
	}

	return (
		<>
			{documentsLoading && <LoadingSpinner />}

			{!documentsLoading && !documents?.length && (
				<Paragraph small color="secondary">
					No documents attached to this contract yet
				</Paragraph>
			)}

			{documents?.map((d) => (
				<Box key={d.id} mb={2} display="flex" alignItems="baseline" columnGap={0.5}>
					<Heading variant="h4">{d.description}</Heading>
					<DownloadDocumentButton documentId={d.id} documentType={33} />
				</Box>
			))}

			{canEdit && (
				<>
					<Divider />
					<Button icon="plus" onClick={() => setShowAddModal(true)}>
						Add document
					</Button>
				</>
			)}

			<AddContractDocumentModal
				isOpen={showAddModal || !!documentToEdit}
				onClose={() => {
					setShowAddModal(false)
					setDocumentToEdit(null)
				}}
				onSave={handleCreateDocument}
			/>
		</>
	)
}

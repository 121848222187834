import { FC, memo, useState } from "react"

import { InventoryPart, makeApiErrorMessage, PartGroup, useAddPartGroupMember } from "@ncs/ncs-api"
import { ExtendableModalProps, Modal, Paragraph, PartSelector, useToast } from "@ncs/web-legos"

export interface NewPartGroupMemberModalProps extends ExtendableModalProps {
	group: PartGroup
}

export const NewPartGroupMemberModal: FC<NewPartGroupMemberModalProps> = memo(
	({ group, ...rest }) => {
		const addPart = useAddPartGroupMember()
		const { makeSuccessToast } = useToast()

		const [part, setPart] = useState<InventoryPart | null>(null)
		const [isLoading, setIsLoading] = useState(false)
		const [errorText, setErrorText] = useState<string | null>(null)

		const onSave = async () => {
			if (!part) throw new Error("No part selected")

			try {
				setIsLoading(true)
				await addPart({
					partId: part.id,
					groupId: group.id,
				})
				makeSuccessToast("Part added to group")
				rest.onClose()
			} catch (e) {
				setErrorText(makeApiErrorMessage(e))
				setIsLoading(false)
			}
		}

		const reset = () => {
			setPart(null)
			setIsLoading(false)
			setErrorText(null)
		}

		const isValid = !!part

		return (
			<Modal
				{...rest}
				title="Add Part To Group"
				onOpen={reset}
				errorText={errorText}
				rightButtons={{
					buttonText: "Add Part",
					disabled: !isValid,
					isLoading,
					onClick: onSave,
				}}
			>
				<Paragraph mb={1}>
					Search for the part you'd like to add to <strong>{group?.description}</strong>.
				</Paragraph>
				<PartSelector value={part} onChange={setPart} autoFocus skipRestrictedCheck />
			</Modal>
		)
	}
)

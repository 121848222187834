import { get, rsaaTypes, RSAA } from "@ncs/bricks/middleware/portalApiMiddleware"

export const TICKETING_DASHBOARD = rsaaTypes("tickets/dashboard")
const GROUP_CHANGED = "ticketing/group_changed"

export const getTicketingDashboard = () => {
	return (dispatch, getState) => {
		// let selectedGroup = null
		let selectedGroup = getState().tickets.dashboard.selectedGroup
		let query = selectedGroup ? { group: selectedGroup } : null
		dispatch({
			[RSAA]: {
				api: get(`/ticketing/dashboard/`, query),
				types: TICKETING_DASHBOARD,
			},
		})
	}
}

export const handleGroupChange = (selectedGroup) => {
	return (dispatch) => {
		//eat fake "all" group that UI creates
		selectedGroup = selectedGroup === "all" ? null : selectedGroup
		dispatch({ type: GROUP_CHANGED, payload: selectedGroup })
		dispatch(getTicketingDashboard())
	}
}

const initialState = {
	dashboard: {
		isLoading: true,
		isInitialState: true,
		lastRefreshed: null,
		tickets_open: null,
		tickets_open_updated: null,
		tickets_closed: null,
		selectedGroup: null,
	},
}

const tickets = (state = initialState, action) => {
	switch (action.type) {
		case TICKETING_DASHBOARD.request:
			return {
				...state,
				dashboard: {
					...state.dashboard,
					isLoading: true,
				},
			}
		case TICKETING_DASHBOARD.failure:
			return {
				...state,
				dashboard: {
					...state.dashboard,
					isLoading: false,
					isInitialState: false,
					error: action.payload,
				},
			}
		case TICKETING_DASHBOARD.success:
			return {
				...state,
				dashboard: {
					...state.dashboard,
					...action.payload,
					// dispatch_history: dispatchHistory,
					// invoice_history: invoiceHistory,
					isLoading: false,
					isInitialState: false,
					lastRefreshed: Date.now(),
				},
			}
		case GROUP_CHANGED:
			return {
				...state,
				dashboard: {
					...state.dashboard,
					selectedGroup: action.payload,
					isInitialState: state.dashboard.selectedGroup !== action.payload,
				},
			}
		default:
			return state
	}
}
export default tickets

import { FC, useMemo } from "react"

import { InventoryLocation, useInventoryLocations } from "@ncs/ncs-api"

import { MultiSelect } from "../inputs"

export interface WarehouseMultiSelectProps {
	values: InventoryLocation[]
	onChange: (newLocations: InventoryLocation[]) => void
	maxSelections?: number
}

export const WarehouseMultiSelect: FC<WarehouseMultiSelectProps> = ({
	values,
	onChange,
	maxSelections = 3,
}) => {
	const [locations] = useInventoryLocations()

	const options = useMemo(() => {
		return (locations ?? []).filter((l) => l.isWarehouse)
	}, [locations])

	return (
		<MultiSelect
			values={values}
			onChange={onChange}
			options={options}
			placeholder="Search for a warehouse..."
			idAccessor="id"
			textAccessor="description"
			longTextAccessor={(option) => `(${option.locationCode}) ${option.description}`}
			label="Warehouse"
			htmlName="warehouse-selector"
			aggregateOptions={false}
			maxSelections={maxSelections}
		/>
	)
}

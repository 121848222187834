import { FC, useState } from "react"

import { ContractBusinessUnit, useContractBusinessUnits } from "@ncs/ncs-api"
import { ExtendableModalProps, Modal, SearchableSelect } from "@ncs/web-legos"

export interface AddBusinessUnitModalProps extends ExtendableModalProps {
	onSave: (unit: ContractBusinessUnit) => void
}

export const AddBusinessUnitModal: FC<AddBusinessUnitModalProps> = ({ onSave, ...rest }) => {
	const [businessUnitToAdd, setBusinessUnitAdd] = useState<ContractBusinessUnit | null>(null)

	const [units, unitsLoading] = useContractBusinessUnits()

	const handleSave = () => {
		if (businessUnitToAdd) {
			onSave(businessUnitToAdd)
		}
		rest.onClose()
	}

	return (
		<Modal
			{...rest}
			onOpen={() => setBusinessUnitAdd(null)}
			title="Add Business Unit"
			rightButtons={{
				buttonText: "Add",
				disabled: !businessUnitToAdd,
				onClick: handleSave,
			}}
		>
			<SearchableSelect
				value={businessUnitToAdd}
				options={units ?? []}
				getOptionLabel={(option) => option.name}
				onItemSelect={setBusinessUnitAdd}
				label="Business unit"
				isLoading={unitsLoading}
				fillContainer
			/>
		</Modal>
	)
}

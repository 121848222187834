import { FC } from "react"

import { Cell, Column } from "react-table"

import {
	SalesRepInvoicesReportLine,
	SalesRepInvoicesReportQueryParams,
	useSalesRepInvoicesReport,
} from "@ncs/ncs-api"
import { formatCurrency, getStartAndEndDateQueryParams, TimeAgo, Typify } from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	ReactTableSortType,
	Table,
	TimeAgoQueryFilter,
	UserQueryFilter,
	useUrlState,
} from "@ncs/web-legos"

export type SalesRepInvoicesReportUrlState = Typify<SalesRepInvoicesReportQueryParams>

export const SalesRepInvoicesReport: FC = () => {
	const [params, { setUrlState: setParams }] = useUrlState<SalesRepInvoicesReportUrlState>(
		initialSalesRepInvoicesReportUrlState
	)

	const [data, isLoading] = useSalesRepInvoicesReport({ params })

	return (
		<AnimatedEntrance show>
			<Table
				data={data ?? []}
				isLoading={isLoading}
				columns={columns}
				queryParamState={params}
				setQueryParamState={setParams}
				pinnedQueryFilters={[UserQueryFilter, TimeAgoQueryFilter]}
				noDataText={
					!params.user ? "This report requires setting the user filter" : undefined
				}
				storeColumnVisibility="sales-rep-invoices-report-columns"
			/>
		</AnimatedEntrance>
	)
}

const columns: Column<SalesRepInvoicesReportLine>[] = [
	{
		Header: "Customer #",
		disableSortBy: true,
		accessor: "customerNumber",
	},
	{
		Header: "Customer Name",
		accessor: "customerName",
	},
	{
		Header: "Rep",
		accessor: "salesRep",
	},
	{
		Header: "Total",
		accessor: "totalInvoiced",
		sortType: ReactTableSortType.Number,
		Cell: ({ row: { original } }: Cell<SalesRepInvoicesReportLine>) =>
			formatCurrency(original.totalInvoiced),
	},
	{
		Header: "Work Order",
		accessor: "workOrderRevenue",
		sortType: ReactTableSortType.Number,
		hiddenByDefault: true,
		Cell: ({ row: { original } }: Cell<SalesRepInvoicesReportLine>) =>
			original.workOrderRevenue ? formatCurrency(original.workOrderRevenue) : "-",
	},
	{
		Header: "Part Order",
		accessor: "partOrderRevenue",
		hiddenByDefault: true,
		sortType: ReactTableSortType.Number,
		Cell: ({ row: { original } }: Cell<SalesRepInvoicesReportLine>) =>
			original.partOrderRevenue ? formatCurrency(original.partOrderRevenue) : "-",
	},
	{
		Header: "Delivery Order",
		accessor: "deliveryOrderRevenue",
		hiddenByDefault: true,
		sortType: ReactTableSortType.Number,
		Cell: ({ row: { original } }: Cell<SalesRepInvoicesReportLine>) =>
			original.deliveryOrderRevenue ? formatCurrency(original.deliveryOrderRevenue) : "-",
	},
]

const initialSalesRepInvoicesReportUrlState: SalesRepInvoicesReportUrlState = {
	user: null,
	startDate: getStartAndEndDateQueryParams(TimeAgo.DaysAgo30)[0],
	endDate: null,
}

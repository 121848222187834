import { FC, ReactElement } from "react"

import { Column } from "react-table"

import {
	CustomerPortalUsageSummary,
	CustomerPortalUsageSummaryReportQueryParams,
	useGetCustomerPortalUsageSummaryReport,
} from "@ncs/ncs-api"
import {
	displayNumber,
	formatCurrency,
	getStartAndEndDateQueryParams,
	TimeAgo,
} from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	Table,
	TimeAgoQueryFilter,
	TimeAgoQueryFilterProps,
	useUrlState,
} from "@ncs/web-legos"

export type CustomerPortalUsageSummaryReportUrlState = CustomerPortalUsageSummaryReportQueryParams

export const CustomerPortalUsageSummaryReport: FC = () => {
	const [queryParams, { setUrlState: setQueryParams }] =
		useUrlState<CustomerPortalUsageSummaryReportQueryParams>(
			initialCustomerPortalUsageSummaryReportUrlState
		)

	const [report, reportLoading] = useGetCustomerPortalUsageSummaryReport({
		params: queryParams,
		queryConfig: {
			enabled: !!queryParams.startDate,
		},
	})

	return (
		<AnimatedEntrance show>
			<Table
				data={report ? [report] : []}
				columns={columns}
				queryParamState={queryParams}
				setQueryParamState={setQueryParams}
				pinnedQueryFilters={[ReportTimeAgoQueryFilter]}
				isLoading={reportLoading}
				storeColumnVisibility
				disableAllSorting
			/>
		</AnimatedEntrance>
	)
}

const columns: Column<CustomerPortalUsageSummary>[] = [
	{
		Header: "Total new accounts created",
		accessor: ({ totalNewAccounts }) => displayNumber(totalNewAccounts),
	},
	{
		Header: "New guest accounts",
		hiddenByDefault: true,
		accessor: ({ newGuestAccounts }) => displayNumber(newGuestAccounts),
	},
	{
		Header: "New existing customer accounts",
		hiddenByDefault: true,
		accessor: ({ totalNewAccounts, newGuestAccounts }) =>
			displayNumber(totalNewAccounts - newGuestAccounts),
	},
	{
		Header: "Orders placed",
		accessor: ({ ordersPlaced }) => displayNumber(ordersPlaced),
	},
	{
		Header: "Mean order value",
		accessor: ({ meanOrderValue }) => formatCurrency(meanOrderValue),
	},
	{
		Header: "Median order value",
		hiddenByDefault: true,
		accessor: ({ medianOrderValue }) => formatCurrency(medianOrderValue),
	},
	{
		Header: "Total order sales",
		accessor: ({ totalOrderValue }) => formatCurrency(totalOrderValue),
	},
]

const initialCustomerPortalUsageSummaryReportUrlState: CustomerPortalUsageSummaryReportUrlState = {
	startDate: getStartAndEndDateQueryParams(TimeAgo.YearToDate)[0],
	endDate: null,
}

const ReportTimeAgoQueryFilter = (
	props: TimeAgoQueryFilterProps<CustomerPortalUsageSummaryReportUrlState>
): ReactElement => {
	return <TimeAgoQueryFilter {...props} disableNoSelectionOption showNoSelectionOption={false} />
}

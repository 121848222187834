import { FC, useMemo, useState } from "react"

import { css } from "@emotion/react"
import { Cell, Column } from "react-table"

import { Dispatch, useDispatchWorkorders, WorkOrder, WorkOrderInvoiceLineItem } from "@ncs/ncs-api"
import {
	displayDateTime,
	extractNumber,
	formatCurrency,
	formatNumber,
	getTimezoneAbbreviation,
} from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	Box,
	Button,
	cssMixins,
	EmptyValueDash,
	GridContainer,
	GridItem,
	HeadingDivider,
	Icon,
	LabeledData,
	LoadingSpinner,
	Paragraph,
	Table,
} from "@ncs/web-legos"

import { getDispatchLineDescription, hasWarrantiesOrContracts } from "~/util"

import { MachineDetailsModal } from "./components"

export interface WorkOrdersTabProps {
	dispatch: Dispatch
}

export const WorkOrdersTab: FC<WorkOrdersTabProps> = ({ dispatch }) => {
	const [modalMachine, setModalMachine] = useState<WorkOrder["machines"][number] | null>(null)
	const [expandedOrders, setExpandedOrders] = useState<Record<string, boolean>>({})

	const [workOrders, workOrdersLoading] = useDispatchWorkorders(dispatch.id)

	const handleDetailsToggle = (workOrderId: string, newState: boolean): void => {
		setExpandedOrders((prev) => ({
			...prev,
			[workOrderId]: newState,
		}))
	}

	const machineColumns = useMemo((): Column<WorkOrder["machines"][number]>[] => {
		return [
			{
				Header: "Name",
				accessor: ({ model }) => model || <EmptyValueDash />,
			},
			{
				Header: "Serial #",
				accessor: ({ serialNumber }) => serialNumber || <EmptyValueDash />,
			},
			{
				Header: "Wash count",
				accessor: ({ washCounter }) => formatNumber(washCounter),
			},
			{
				Header: "Warranties / Contracts",
				Cell: ({ row: { original } }: Cell<WorkOrder["machines"][number]>) => {
					return hasWarrantiesOrContracts(original) ?
							<Button
								onClick={() => setModalMachine(original)}
								trailingIcon="browser"
							>
								View
							</Button>
						:	"None"
				},
			},
		]
	}, [])

	if (workOrdersLoading) {
		return <LoadingSpinner />
	}
	if (!workOrders?.length) {
		return (
			<Paragraph secondary textAlign="center" my={5}>
				There are no work orders for this dispatch yet
			</Paragraph>
		)
	}

	return (
		<>
			<Box d="flex" flexDirection="column" rowGap={3}>
				{(workOrders ?? [])
					.sort((a, b) => (a.openDate > b.openDate ? -1 : 1))
					.map((w) => {
						const isExpanded = !!expandedOrders[w.id]

						return (
							<Box key={w.id}>
								<HeadingDivider variant="h5" mt={0}>
									Work Order #{w.workorderNumber}
								</HeadingDivider>

								<GridContainer>
									<GridItem xs={12} sm={6} md={4} lg={3}>
										<LabeledData label="Technician">
											{w.technician?.name || <EmptyValueDash />}
										</LabeledData>
									</GridItem>
									<GridItem xs={12} sm={6} md={4} lg={3}>
										<LabeledData
											label={`Opened (${getTimezoneAbbreviation()})`}
										>
											{displayDateTime(w.openDate)}
										</LabeledData>
									</GridItem>
									<GridItem xs={12} sm={6} md={4} lg={3}>
										<LabeledData
											label={`Closed (${getTimezoneAbbreviation()})`}
										>
											{displayDateTime(w.closedDate, "") || (
												<EmptyValueDash />
											)}
										</LabeledData>
									</GridItem>
								</GridContainer>

								<Box mb={1} maxWidth="50%" smProps={{ maxWidth: "none " }}>
									<LabeledData label="Description">
										<span css={cssMixins.preserveLineBreaks}>
											{w.comments || <EmptyValueDash />}
										</span>
									</LabeledData>
								</Box>

								<AnimatedEntrance
									show={isExpanded}
									bl={1}
									pl={1}
									mt={1}
									d="flex"
									flexDirection="column"
									rowGap={1}
									direction="down"
								>
									<div>
										<Paragraph bold>Line Items</Paragraph>
										{w.lineItems.length ?
											<Table
												data={w.lineItems}
												columns={lineItemColumns}
												disableAllSorting
											/>
										:	<Paragraph small secondary mt={0.5}>
												No line items are on this work order
											</Paragraph>
										}
									</div>

									<div>
										<Paragraph bold>Machines</Paragraph>
										{w.machines.length ?
											<Table
												data={w.machines}
												columns={machineColumns}
												disableAllSorting
											/>
										:	<Paragraph small secondary mt={0.5}>
												No machines are attached to this work order
											</Paragraph>
										}
									</div>
								</AnimatedEntrance>

								{isExpanded ?
									<Box mt={1}>
										<Button
											icon="angle-up"
											onClick={() => handleDetailsToggle(w.id, false)}
										>
											Hide
										</Button>
									</Box>
								:	<Box mt={-1}>
										<Button
											icon="angle-right"
											onClick={() => handleDetailsToggle(w.id, true)}
										>
											Show line items and machines
										</Button>
									</Box>
								}
							</Box>
						)
					})}
			</Box>

			{!!modalMachine && (
				<MachineDetailsModal
					machine={modalMachine}
					onClose={() => setModalMachine(null)}
				/>
			)}
		</>
	)
}

const lineItemColumns: Column<WorkOrder["lineItems"][number]>[] = [
	{
		Header: "Description",
		accessor: (original) => getDispatchLineDescription(original),
	},
	{
		Header: "Under warranty?",
		accessor: "underWarranty",
		Cell: ({ row: { original } }: Cell<WorkOrder["lineItems"][number]>) => {
			return original.underWarranty ?
					<Box d="flex" alignItems="center" gap={0.5}>
						<Icon icon="check" color="gray" />
						<span>Under warranty</span>
					</Box>
				:	<EmptyValueDash />
		},
	},
	{
		Header: "Billable?",
		accessor: "billable",
		Cell: ({ row: { original } }: Cell<WorkOrder["lineItems"][number]>) => {
			return original.billable ?
					<Box d="flex" alignItems="center" gap={0.5}>
						<Icon icon="check" color="gray" />
						<span>Billable</span>
					</Box>
				:	<EmptyValueDash />
		},
	},
	{
		Header: "Qty",
		accessor: ({ quantity }) => formatNumber(extractNumber(quantity)),
	},
	{
		Header: "Price",
		accessor: "netPrice",
		Cell: ({ row: { original } }: Cell<WorkOrderInvoiceLineItem>) => {
			const originalPrice = extractNumber(original.unitPrice)
			let finalPrice = extractNumber(original.netPrice)

			if (extractNumber(original.total) === 0) {
				finalPrice = 0
			}

			if (finalPrice < originalPrice) {
				return (
					<Box d="flex" gap={0.5}>
						<span
							css={css`
								text-decoration: line-through;
							`}
						>
							{formatCurrency(originalPrice)}
						</span>
						<span>{formatCurrency(finalPrice)}</span>
					</Box>
				)
			}

			return formatCurrency(finalPrice)
		},
	},
	{
		Header: "Subtotal",
		accessor: ({ subTotal }) => formatCurrency(subTotal),
	},
	{
		Header: "Est tax",
		accessor: ({ tax }) => formatCurrency(tax),
	},
	{
		Header: "Total",
		accessor: ({ total }) => formatCurrency(total),
	},
]

export interface InventoryTransactionLine {
	id: string
	partId: string
	partNumber: string
	partDescription: string
	quantity: number
	destinationBinId?: string
	destinationBinCode?: string
	sourceBinId?: string
	sourceBinCode?: string
}

/**
 * There are some inventory transaction types that were made at some point only for Tera.
 */
export const teraTransactionTypes = [11, 12, 13]

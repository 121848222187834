import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import SwipeableViews from "react-swipeable-views"
import { makeStyles, Tab, Tabs } from "@material-ui/core"

import styles from "../../assets/jss/material-dashboard-pro-react/components/navPillsStyle"
import { GridContainer, GridItem } from "../../components"

const useStyles = makeStyles(styles)

export default function NavPills(props) {
	const [active, setActive] = React.useState(props.active)
	const handleChange = (event, active) => {
		props.onTabChange(active)
		setActive(active)
	}

	const classes = useStyles()
	const { tabs, direction, color, horizontal, alignCenter } = props
	const flexContainerClasses = classNames({
		[classes.flexContainer]: true,
		[classes.horizontalDisplay]: horizontal !== undefined,
	})

	const visibleTabs = tabs.filter((x) => typeof x.hidden !== "boolean" || !x.hidden)

	const tabButtons = (
		<Tabs
			classes={{
				root: classes.root,
				fixed: classes.fixed,
				flexContainer: flexContainerClasses,
				indicator: classes.displayNone,
			}}
			value={active}
			onChange={handleChange}
			centered={alignCenter}
		>
			{visibleTabs.map((prop, key) => {
				var icon = {}
				if (prop.tabIcon !== undefined) {
					icon["icon"] = <prop.tabIcon className={classes.tabIcon} />
				}
				const pillsClasses = classNames({
					[classes.pills]: true,
					[classes.horizontalPills]: horizontal !== undefined,
					[classes.pillsWithIcons]: prop.tabIcon !== undefined,
				})
				return (
					<Tab
						label={prop.tabButton}
						key={key}
						{...icon}
						classes={{
							root: pillsClasses,
							selected: classes[color],
						}}
					/>
				)
			})}
		</Tabs>
	)
	const tabContent = (
		<div className={classes.contentWrapper}>
			<SwipeableViews
				axis={direction === "rtl" ? "x-reverse" : "x"}
				index={active}
				style={{ overflowY: "hidden" }}
			>
				{visibleTabs.map((prop, key) => {
					return (
						<div className={classes.tabContent} key={key}>
							{prop.tabContent}
						</div>
					)
				})}
			</SwipeableViews>
		</div>
	)
	return horizontal !== undefined ?
			<GridContainer>
				<GridItem {...horizontal.tabsGrid}>{tabButtons}</GridItem>
				<GridItem {...horizontal.contentGrid}>{tabContent}</GridItem>
			</GridContainer>
		:	<div>
				{tabButtons}
				{tabContent}
			</div>
}

NavPills.defaultProps = {
	active: 0,
	color: "primary",
	onTabChange: () => {},
}

NavPills.propTypes = {
	// index of the default active pill
	active: PropTypes.number,
	tabs: PropTypes.arrayOf(
		PropTypes.shape({
			tabButton: PropTypes.string,
			tabIcon: PropTypes.object,
			tabContent: PropTypes.node,
		})
	).isRequired,
	color: PropTypes.oneOf(["primary", "warning", "danger", "success", "info", "rose"]),
	direction: PropTypes.string,
	horizontal: PropTypes.shape({
		tabsGrid: PropTypes.object,
		contentGrid: PropTypes.object,
	}),
	alignCenter: PropTypes.bool,
	onTabChange: PropTypes.func,
}

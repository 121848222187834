import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import get from "lodash/get"
import callApi from "@ncs/bricks/redux/services/callApi"
import { Select } from "@ncs/bricks/components"
import { loadLookups, LOOKUPS } from "../../redux/services/lookups"

class TicketTypeSelector extends React.PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			types: [],
			type: null,
		}
	}

	componentDidMount() {
		this.props.loadLookups([LOOKUPS.TicketTypes], this.filterTicketType)
	}

	// filter type whenever the group changes
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.group !== this.props.group) {
			this.filterTicketType()
		}
		if (prevProps.type !== this.props.type) {
			this.setState({
				type: this.state.types.filter((x) => x.id === this.props.type),
			})
		}
	}

	filterTicketType = () => {
		// if type is empty

		if (!this.props.group) {
			this.setState({ types: [] })
			return
		}

		let types = this.props.types.filter((x) => x.group_id === Number(this.props.group))

		this.setState({ types })

		// if the currently selected type is not in the results, erase the selected type
		if (this.state.type && !types.some((t) => t.id.toString() === this.state.type)) {
			this.props.onChange(null)
		}
	}

	render() {
		const { displayEmpty, ...rest } = this.props
		return (
			<Select
				id="ticketTypes"
				labelText="Type"
				value={
					this.props.type ||
					get(this.state.type ? this.state.type[0] : this.state.type, { id: "" })
				}
				onChange={this.props.onChange}
				options={[
					{
						id: "",
						description:
							!this.props.group ? "No types for this department"
							: this.state.types.length > 0 ? "Select a type..."
							: "No types for this department",
						disabled: true,
					},
					...this.state.types,
				]}
				valueAccessor="id"
				textAccessor={(x) => x.description}
				labelProps={{
					shrink: displayEmpty ? true : undefined,
					...rest.labelProps,
				}}
				selectProps={{
					displayEmpty: displayEmpty,
					disabled: this.state.types.length < 1,
					...rest.selectProps,
				}}
				{...rest}
			/>
		)
	}
}

TicketTypeSelector.defaultProps = {
	displayEmpty: true,
}

TicketTypeSelector.propTypes = {
	type: PropTypes.object,
	displayEmpty: PropTypes.bool,
}

const mapStateToProps = (state) => {
	return {
		types: state.lookups.ticketTypes,
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			callApi,
			loadLookups,
		},
		dispatch
	)

export default connect(mapStateToProps, mapDispatchToProps)(TicketTypeSelector)

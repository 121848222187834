import React from "react"

import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { bindActionCreators } from "redux"

import { logout } from "@ncs/bricks/redux/services/auth"

import { HEADER_TYPES } from "../../util/constants"

import DefaultHeader from "./DefaultHeader"
import ExPartsOrderHeader from "./ExPartsOrderHeader"
import PartOrderHeader from "./PartOrderHeader"

class HeaderLinks extends React.Component {
	renderIcons(type) {
		switch (type) {
			case HEADER_TYPES.PartsOrder:
				return <PartOrderHeader onCreatePO={this.onCreatePO} />
			case HEADER_TYPES.ExternalPartsOrder:
				return (
					<ExPartsOrderHeader
						onSignOut={this.onSignOut}
						onCheckout={this.onCheckout}
						onOrderHistory={this.onOrderHistory}
						onViewCart={this.onViewCart}
					/>
				)
			case HEADER_TYPES.None:
				return null
			default:
				return <DefaultHeader onSignIn={this.onSignIn} onSignOut={this.onSignOut} />
		}
	}

	onCheckout = () => {
		this.props.history.push("/order/checkout")
	}

	onOrderHistory = () => {
		this.props.history.push("/order/history")
	}

	onViewCart = () => {
		this.props.history.push("/order/cart")
	}

	onCreatePO = () => {
		this.props.history.push("/order/create")
	}

	onSignIn = () => {
		this.props.history.push("/login")
	}

	onSignOut = () => {
		// Logout of Redux auth
		this.props.logout()
		// Logout of new auth
		if (this.props.newAuth) {
			this.props.newAuth.logout()
		}
		this.props.history.push("/")
	}

	render() {
		return <div>{this.renderIcons(this.props.headerIcons)}</div>
	}
}

const mapStateToProps = (state) => {
	return {
		...state.header,
	}
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ logout }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HeaderLinks))

import React, { useState } from "react"
import { compose } from "redux"
import { Link } from "react-router-dom"
import { Alert, AlertTitle } from "@material-ui/lab"
import { resetPassword } from "@ncs/bricks/redux/services/auth"
import loginPageStyle from "@ncs/bricks/assets/jss/material-dashboard-pro-react/views/loginPageStyle"

// core components
import InputAdornment from "@material-ui/core/InputAdornment"
import { Typography } from "@material-ui/core"

// custom components
import { Input, Button, Card, CardBody, CardFooter, withCallApi } from "~/components"

// icons
import LockOutline from "@material-ui/icons/Lock"

// styles
import makeStyles from "@material-ui/core/styles/makeStyles"
const useStyles = makeStyles(loginPageStyle)

const ResetPasswordForm = ({ token, callApi }) => {
	const [password, setPassword] = useState("")
	const [errors, setErrors] = useState({})
	const [loading, setLoading] = useState(false)
	const [success, setSuccess] = useState(false)

	const classes = useStyles()

	const handleSubmit = async (event) => {
		event.preventDefault()

		setLoading(true)
		setErrors({})

		callApi(resetPassword(token, password))
			.then(({ error, payload }) => {
				if (error === true) {
					throw payload
				}

				if (payload !== undefined) {
					throw new Error("Unexpected payload")
				}

				setSuccess(true)
			})
			.catch((err) => {
				setErrors(err.response ?? { boomtime: true })
			})
			.finally(() => {
				setLoading(false)
			})
	}

	if (success) {
		return (
			<Card>
				<CardBody>
					<Alert variant="filled" severity="success">
						Password was successfully updated!
					</Alert>
				</CardBody>
				<CardFooter className={classes.justifyContentCenter}>
					<Link to="/login">Back to login</Link>
				</CardFooter>
			</Card>
		)
	}

	return (
		<form onSubmit={handleSubmit}>
			{errors?.boomtime === true && (
				<Alert variant="filled" severity="error">
					<AlertTitle>An error occurred when processing your request</AlertTitle>
					Please try again later
				</Alert>
			)}
			<Typography>Enter your new password</Typography>
			<Card>
				<CardBody>
					<Input
						labelText="Password"
						id="password"
						value={password}
						formControlProps={{
							fullWidth: true,
						}}
						error={errors?.password?.length > 0}
						helperText={errors?.password?.join(" ")}
						inputProps={{
							type: "password",
							onChange: (e) => {
								setPassword(e.target.value)
							},
							endAdornment: (
								<InputAdornment position="end">
									<LockOutline className={classes.inputAdornmentIcon} />
								</InputAdornment>
							),
						}}
					/>
				</CardBody>
				<CardFooter className={classes.justifyContentCenter}>
					<Button
						color="rose"
						simple
						size="lg"
						block
						type="submit"
						onClick={handleSubmit}
						disabled={loading || password.length === 0}
					>
						Reset password
					</Button>
					<Link to="/login">Back to login</Link>
				</CardFooter>
			</Card>
		</form>
	)
}

export default compose(withCallApi)(ResetPasswordForm)

import { FC } from "react"

import dayjs from "dayjs"
import { Column } from "react-table"

import {
	ErpSummaryReportLine,
	ErpSummaryReportQueryParams,
	useErpSummaryReport,
	useExportErpSummaryReport,
} from "@ncs/ncs-api"
import {
	formatNumber,
	formatPercentage,
	getStartAndEndDateQueryParams,
	TimeAgo,
	Typify,
} from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	CustomerQueryFilter,
	EnterpriseQueryFilter,
	OrganizationQueryFilter,
	Table,
	TerritoryQueryFilter,
	TimeAgoQueryFilter,
	usePaginationUrlState,
	useUrlState,
} from "@ncs/web-legos"

export type ErpSummaryReportUrlState = Typify<ErpSummaryReportQueryParams>

export const ErpSummaryReport: FC = () => {
	const [queryParams, { setUrlState: setQueryParams }] = useUrlState<ErpSummaryReportUrlState>(
		initialErpSummaryReportUrlState
	)
	const [pagination, setPagination] = usePaginationUrlState()
	const query = useErpSummaryReport({
		params: { ...queryParams, ...pagination },
		manualPagination: true,
	})
	const dataExport = useExportErpSummaryReport(queryParams)

	return (
		<AnimatedEntrance show>
			<Table
				query={query}
				columns={columns}
				queryParamState={queryParams}
				setQueryParamState={setQueryParams}
				pagination={pagination}
				setPagination={setPagination}
				dataExport={dataExport}
				pinnedQueryFilters={[
					TimeAgoQueryFilter,
					CustomerQueryFilter,
					OrganizationQueryFilter,
					TerritoryQueryFilter,
					EnterpriseQueryFilter,
				]}
				filterResetValues={initialErpSummaryReportUrlState}
				storeColumnVisibility="erp-summary-report-columns"
			/>
		</AnimatedEntrance>
	)
}

const columns: Column<ErpSummaryReportLine>[] = [
	{
		Header: "Month",
		id: "month",
		accessor: ({ month, year }) => dayjs(`${month}-1-${year}`).format("MMMM"),
	},
	{
		Header: "Year",
		id: "year",
		accessor: ({ month, year }) => dayjs(`${month}-1-${year}`).format("YYYY"),
	},
	{
		Header: "Total",
		id: "total",
		accessor: ({ total }) => formatNumber(total),
	},
	{
		Header: "In Progress",
		disableSortBy: true,
		accessor: ({ inProgress }) => formatNumber(inProgress),
	},
	{
		Header: "Submitted",
		disableSortBy: true,
		accessor: ({ submitted }) => formatNumber(submitted),
	},
	{
		Header: "Approved",
		disableSortBy: true,
		accessor: ({ approved }) => formatNumber(approved),
	},
	{
		Header: "Rejected",
		disableSortBy: true,
		hiddenByDefault: true,
		accessor: ({ rejected }) => formatNumber(rejected),
	},
	{
		Header: "Void",
		disableSortBy: true,
		hiddenByDefault: true,
		accessor: ({ void: voidCount }) => formatNumber(voidCount), // TS gets confused by `void`.
	},
	{
		Header: "Completed",
		disableSortBy: true,
		hiddenByDefault: true,
		accessor: ({ completed }) => formatNumber(completed),
	},
	{
		Header: "Conversion %",
		disableSortBy: true,
		hiddenByDefault: true,
		accessor: ({ completed, total }) => formatPercentage(total !== 0 ? completed / total : 1),
	},
]

const initialErpSummaryReportUrlState: ErpSummaryReportUrlState = {
	ordering: null,
	organization: null,
	enterprise: null,
	territory: null,
	customer: null,
	startDate: getStartAndEndDateQueryParams(TimeAgo.DaysAgo30)[0],
	endDate: null,
}

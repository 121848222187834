import React from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import callApi from "@ncs/bricks/redux/services/callApi"
import { withRouter } from "react-router-dom"
import { fetchData } from "@ncs/bricks/redux/services/dynamicTables"
import { getCustomerRoutes } from "@ncs/mortar/redux/services/customers"
import { REPORT_FILTERS } from "@ncs/mortar/util/constants"
import { formatDate } from "@ncs/bricks/util/formatters"

// custom components
import {
	Button,
	ButtonWithIcon,
	DynamicTable,
	GridContainer,
	GridItem,
	QuickCard,
	ReportFilters,
} from "~/components"

// icons
import Info from "@material-ui/icons/Info"
import LocalShipping from "@material-ui/icons/LocalShipping"
import Add from "@material-ui/icons/Add"

import withStyles from "@material-ui/core/styles/withStyles"
const styles = {
	right: {
		textAlign: "right",
	},
}

// constants
const reduxKey = "customerRoutes"

class CustomerRoutes extends React.Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	resetState = () => {
		this.setState({})
	}

	handleRouteSelected = (route) => {
		this.props.history.push(`/customer/routes/${route.id}`)
	}

	handleCreateRoute = () => {
		this.props.history.push(`/customer/create-route`)
	}

	viewDetailsButton = (part) => (
		<Button
			justIcon
			round
			simple
			className="edit"
			color="info"
			onClick={(e) => {
				e.stopPropagation()
				this.handleRouteSelected(part)
			}}
		>
			<Info />
		</Button>
	)

	columns = [
		{
			Header: "Route Name",
			accessor: "name",
			sortable: true,
			toggleable: false,
		},
		{
			Header: "Assigned Employee",
			accessor: "assigned_employee.name",
			sortable: true,
			toggleable: true,
		},
		{
			Header: "Active",
			accessor: (x) => (x ? "True" : "False"),
			id: "is_active",
			sortable: true,
			toggleable: true,
		},
		{
			Header: "Created Date",
			accessor: (x) => (x.created ? formatDate(x.created) : ""),
			id: "created",
			sortable: true,
			toggleable: true,
		},
		{
			Header: "Last Modified",
			accessor: (x) => (x.last_modified ? formatDate(x.last_modified) : ""),
			id: "last_modified",
			sortable: true,
			toggleable: true,
		},
		{
			Header: "",
			Cell: this.viewDetailsButton,
			accessor: "actions",
			sortable: false,
			toggleable: false,
			maxWidth: 50,
		},
	]

	render() {
		const { classes } = this.props

		return (
			<QuickCard title="Customer Routes" icon={<LocalShipping />}>
				<ReportFilters
					config={{
						reduxKey,
						filters: [
							REPORT_FILTERS.Search,
							REPORT_FILTERS.Territory,
							REPORT_FILTERS.Customer,
						],
					}}
				/>

				<GridContainer>
					<GridItem xs={12} className={classes.right}>
						<ButtonWithIcon
							content="New Route"
							onClick={this.handleCreateRoute}
							icon={<Add />}
							size="sm"
							color="success"
							round
						/>
					</GridItem>
				</GridContainer>
				<DynamicTable
					reduxKey={reduxKey}
					fetchDataAction={getCustomerRoutes}
					columns={this.columns}
					showResetFiltersButton={true}
					defaultPageSize={10}
					onRowClick={this.handleRouteSelected}
				/>
			</QuickCard>
		)
	}
}

const mapStateToProps = (state) => {
	return {}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			callApi,
			refreshPartCrossRef: fetchData("customerRoutes"),
		},
		dispatch
	)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(withStyles(styles)(CustomerRoutes)))

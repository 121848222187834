import { FC, useState } from "react"

import {
	makeApiErrorMessage,
	Customer,
	getCustomerContacts,
	useDeleteCustomerContact,
	CustomerContact,
} from "@ncs/ncs-api"
import {
	Box,
	Button,
	ConfirmationModal,
	ConfirmationModalConfig,
	IconButton,
	LoadingSpinner,
	Paragraph,
	Pipe,
	useToast,
} from "@ncs/web-legos"

import { AddServiceNotificationsModal } from "./components"

export interface ServiceNotificationsProps {
	customer: Customer
}

export const ServiceNotifications: FC<ServiceNotificationsProps> = ({ customer }) => {
	const { makeSuccessToast, makeErrorToast } = useToast()
	const [contacts, contactsLoading] = getCustomerContacts(customer.id)
	const [showCreateNotificationModal, setShowCreateNotificationModal] = useState<boolean>(false)
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)
	const [editContact, setEditContact] = useState<CustomerContact | null>(null)

	const deleteCustomerContact = useDeleteCustomerContact(customer.id)

	const handleDeleteCustomerContact = async (contactId: number) => {
		try {
			const contactIdString = contactId.toString()
			await deleteCustomerContact(contactIdString)
			makeSuccessToast("Sales assignment removed")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		}
	}

	return (
		<>
			{contactsLoading && <LoadingSpinner />}

			{!!contacts && (
				<Box mb={1}>
					{contacts.map((c) => {
						return (
							<Box key={c.id} mb={0.5} display="flex" alignItems="center">
								<Box display="flex" alignItems="center">
									<Paragraph small color="primary">
										{c.destination}
									</Paragraph>

									<Pipe />

									<Paragraph small color="primary">
										{c.communicationChannel}
									</Paragraph>

									<Pipe />

									<Paragraph small color="primary">
										{c.communicationReason}
									</Paragraph>

									<Pipe />

									<Box ml={0.5}>
										<IconButton
											icon="trash-alt"
											size="sm"
											color="primary"
											onClick={() => {
												setConfirmationConfig({
													title: "Remove Sales Assignment",
													message: `Remove ${c.destination} from this customer?`,
													onConfirm: () =>
														handleDeleteCustomerContact(c.id),
												})
											}}
										/>
									</Box>

									<Box ml={0.5}>
										<IconButton
											icon="pencil"
											size="sm"
											color="primary"
											onClick={() => {
												setEditContact(c)
												setShowCreateNotificationModal(true)
											}}
										/>
									</Box>
								</Box>
							</Box>
						)
					})}
				</Box>
			)}
			<Button
				icon="plus"
				containerProps={{ ml: -0.3 }}
				onClick={() => setShowCreateNotificationModal(true)}
			>
				Add service notification
			</Button>

			{showCreateNotificationModal && (
				<AddServiceNotificationsModal
					onClose={() => {
						setShowCreateNotificationModal(false)
						setEditContact(null)
					}}
					customerId={customer.id}
					customerContact={editContact}
				/>
			)}
			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</>
	)
}

import { FC, ReactElement, useMemo } from "react"

import { useHistory } from "react-router-dom"
import { Cell, Column } from "react-table"

import { Dispatch, DispatchQueryParams, useDispatches } from "@ncs/ncs-api"
import {
	formatCurrency,
	formatDateTime,
	getStartAndEndDateQueryParams,
	getTimezoneAbbreviation,
	TimeAgo,
	Typify,
	yesOrNo,
} from "@ncs/ts-utils"
import {
	Box,
	DispatchCallContactedQueryFilter,
	DispatchCallTypeQueryFilter as DefaultDispatchCallTypeQueryFilter,
	DispatchCallTypeQueryFilterProps,
	DispatchStatusQueryFilter,
	InvoiceStatusQueryFilter,
	InvoiceStatusQueryFilterProps,
	PortalQueryFilter,
	PortalUserQueryFilter,
	SearchQueryFilter,
	Table,
	TerritoryQueryFilter,
	TimeAgoQueryFilter,
	TimeAgoQueryFilterProps,
	TooltipIcon,
	usePaginationUrlState,
	useUrlState,
} from "@ncs/web-legos"

export type ViewDispatchesTabUrlState = Typify<DispatchQueryParams>

export const ViewDispatchesTab: FC = () => {
	const history = useHistory()

	const [filterParams, { setUrlState: setFilterParams }] =
		useUrlState<ViewDispatchesTabUrlState>({ ...initialQueryParams })
	const [pagination, setPagination] = usePaginationUrlState()
	const combinedParams = {
		...filterParams,
		...pagination,
	}

	const query = useDispatches({
		params: combinedParams,
		manualPagination: true,
	})

	const showToggledFiltersByDefault = useMemo(() => {
		const keys: (keyof DispatchQueryParams)[] = [
			"callReceivedGte",
			"closedDateGte",
			"billToCustomer_PortalAssignment_PortalId",
			"billToCustomer_PortalAssignment_AssignedEmployeeId",
			"territory",
			"noPortalUser",
			"callTypeId_In",
		]

		return keys.some((k) => filterParams[k] !== initialQueryParams[k])
	}, [filterParams])

	return (
		<>
			<Table
				query={query}
				columns={columns}
				queryParamState={filterParams}
				setQueryParamState={setFilterParams}
				pagination={pagination}
				setPagination={setPagination}
				pinnedQueryFilters={[
					SearchQueryFilter,
					DispatchStatusQueryFilter,
					DispatchInvoiceStatusQueryFilter,
				]}
				toggledQueryFilters={[
					TerritoryQueryFilter,
					DispatchCallContactedQueryFilter,
					DispatchCallTypeQueryFilter,
					ClosedTimeAgoQueryFilter,
					CallReceivedTimeAgoQueryFilter,
					PortalUserQueryFilter,
					PortalQueryFilter,
				]}
				filterResetValues={initialQueryParams}
				showToggledFiltersByDefault={showToggledFiltersByDefault}
				storeColumnVisibility
				onRowClick={({ original }) => {
					history.push({
						pathname: `/service/dispatches/${original.id}`,
						state: { dispatchParams: combinedParams },
					})
				}}
			/>
		</>
	)
}

const CallReceivedTimeAgoQueryFilter = (
	props: TimeAgoQueryFilterProps<DispatchQueryParams>
): ReactElement => {
	return <TimeAgoQueryFilter {...props} paramKey="callReceivedGte" label="Call received date" />
}

const ClosedTimeAgoQueryFilter = (
	props: TimeAgoQueryFilterProps<DispatchQueryParams>
): ReactElement => {
	return <TimeAgoQueryFilter {...props} paramKey="closedDateGte" label="Closed date" />
}

const DispatchInvoiceStatusQueryFilter = (
	props: InvoiceStatusQueryFilterProps<DispatchQueryParams>
): ReactElement => {
	return <InvoiceStatusQueryFilter {...props} param="invoice_StatusId" />
}

const initialQueryParams: DispatchQueryParams = {
	ordering: null,
	territory: null,
	statusId: null,
	search: null,
	callReceivedGte: getStartAndEndDateQueryParams(TimeAgo.MonthsAgo3)[0],
	closedDateGte: null,
	endDate: null,
	invoice_StatusId: null,
	billToCustomer_PortalAssignment_PortalId: null,
	billToCustomer_PortalAssignment_AssignedEmployeeId: null,
	callTypeId_In: null,
	customer: null,
	noPortalUser: null,
	nuisance: false,
	dispatchContactExists: null,
}

const columns: Column<Dispatch>[] = [
	{
		Header: "Dispatch #",
		accessor: "dispatchNumber",
		noWrap: true,
	},
	{
		Header: "Customer",
		id: "customer_CustomerNumber",
		noWrap: true,
		accessor: ({ customer }) => `(${customer.customerNumber}) ${customer.name}`,
	},
	{
		Header: "Territory",
		id: "customer_Territory_Code",
		hiddenByDefault: true,
		accessor: ({ customer }) =>
			customer.territory ?
				`(${customer.territory.code}) ${customer.territory.description}`
			:	"-",
	},
	{
		Header: "Status",
		id: "status_Description",
		accessor: "status",
	},
	{
		Header: `Date created (${getTimezoneAbbreviation()})`,
		id: "createdDate",
		accessor: ({ createdDate }) => (createdDate ? formatDateTime(createdDate) : "-"),
	},
	{
		Header: `Date closed (${getTimezoneAbbreviation()})`,
		id: "closedDate",
		accessor: ({ closedDate }) => (closedDate ? formatDateTime(closedDate) : "-"),
	},
	{
		Header: "Bill-to customer",
		id: "billToCustomer_CustomerNumber",
		hiddenByDefault: true,
		accessor: ({ billToCustomer }) =>
			billToCustomer ? `(${billToCustomer.customerNumber}) ${billToCustomer.name}` : "-",
	},
	{
		Header: "Tech assigned",
		id: "assignedEmployee_FirstName,assignedEmployee_LastName",
		hiddenByDefault: true,
		accessor: ({ assignedEmployee }) => assignedEmployee?.name || "-",
	},
	{
		Header: "Invoice status",
		hiddenByDefault: true,
		disableSortBy: true,
		accessor: ({ invoice }) => invoice?.status || "-",
	},
	{
		Header: "Invoice #",
		hiddenByDefault: true,
		disableSortBy: true,
		accessor: ({ invoice }) => invoice?.invoiceNumber || "-",
	},
	{
		Header: "Invoice total",
		hiddenByDefault: true,
		disableSortBy: true,
		accessor: ({ invoice }) => (invoice ? formatCurrency(invoice.total) : "-"),
	},
	{
		Header: "PO #",
		id: "poTicket",
		hiddenByDefault: true,
		accessor: ({ poTicket }) => poTicket || "-",
	},
	{
		Header: "Call type",
		id: "callType_Description",
		hiddenByDefault: true,
		accessor: ({ callType }) => callType || "-",
	},
	{
		Header: "Caller",
		id: "callerName",
		hiddenByDefault: true,
		accessor: ({ callerName }) => callerName || "-",
	},
	{
		Header: `Call received (${getTimezoneAbbreviation()})`,
		id: "callReceived",
		hiddenByDefault: true,
		accessor: ({ callReceivedDate }) => formatDateTime(callReceivedDate),
		Cell: ({ row: { original } }: Cell<Dispatch>) => {
			return (
				<Box display="flex" alignItems="center" columnGap={0.5}>
					{formatDateTime(original.callReceivedDate)}
					{original.dispatchContactExists ?
						<TooltipIcon icon="phone" tooltipProps={{ iconFamily: "solid" }}>
							Contacted
						</TooltipIcon>
					:	null}
				</Box>
			)
		},
	},
	{
		Header: "ERP",
		hiddenByDefault: true,
		accessor: ({ erp }) => erp || "-",
	},
	{
		Header: "Portal",
		id: "billToCustomer_Portal_Description",
		hiddenByDefault: true,
		accessor: ({ billToCustomer }) => billToCustomer?.portal?.description || "-",
	},
	{
		Header: "Portal user",
		id: "billToCustomer_PortalUser_Name",
		hiddenByDefault: true,
		accessor: ({ billToCustomer }) => billToCustomer?.portalUser?.name || "-",
	},
	{
		Header: "Nuisance?",
		id: "nuisance",
		hiddenByDefault: true,
		disableSortBy: true,
		accessor: ({ nuisance }) => yesOrNo(nuisance),
	},
]

const DispatchCallTypeQueryFilter = (
	props: DispatchCallTypeQueryFilterProps<ViewDispatchesTabUrlState>
) => {
	return <DefaultDispatchCallTypeQueryFilter {...props} paramKey="callTypeId_In" />
}

import { FC, useState } from "react"

import {
	BinManagementBin,
	BinManagementBinPatch,
	BinManagementBinPost,
	BinTypeId,
	InventoryLocation,
	makeApiErrorMessage,
	useCreateBin,
	useUpdateBin,
} from "@ncs/ncs-api"
import { Checkbox, ExtendableModalProps, Modal, TextInput, TooltipIcon } from "@ncs/web-legos"

import { BinTypeSelector } from "~/components"

export interface EditBinModalProps extends ExtendableModalProps {
	bin: BinManagementBin | null
	location: InventoryLocation
}

export const EditBinModal: FC<EditBinModalProps> = ({ bin, location, ...rest }) => {
	const [code, setCode] = useState<string | null>(null)
	const [isActive, setIsActive] = useState(true)
	const [binType, setBinType] = useState<string | null>(null)
	const [errorText, setErrorText] = useState<string | null>(null)
	const [isSaving, setIsSaving] = useState(false)

	const createBin = useCreateBin()
	const updateBin = useUpdateBin()

	const handleCreateBin = async () => {
		if (!code) {
			setErrorText("Bin code is required")
			return
		}
		if (!binType) {
			setErrorText("Bin type is required")
			return
		}

		try {
			setIsSaving(true)
			const postData: BinManagementBinPost = {
				code,
				isActive,
				binTypeId: binType,
				locationId: location.id,
			}
			await createBin(postData)
			rest.onClose()
		} catch (e) {
			setErrorText(makeApiErrorMessage(e))
		} finally {
			setIsSaving(false)
		}
	}

	const handleUpdateBin = async () => {
		if (!code) {
			setErrorText("Bin code is required")
			return
		}
		if (!bin) {
			setErrorText("No bin in state to edit")
			return
		}
		if (!binType) {
			setErrorText("Bin type is required")
			return
		}

		try {
			setIsSaving(true)
			const patchData: BinManagementBinPatch = {
				code,
				isActive,
				binTypeId: binType,
				locationId: location.id,
			}
			await updateBin({
				updates: patchData,
				id: bin.id,
			})
			rest.onClose()
		} catch (e) {
			setErrorText(makeApiErrorMessage(e))
		} finally {
			setIsSaving(false)
		}
	}

	const setupEditModal = () => {
		setCode(bin?.code ?? null)
		setIsActive(bin?.isActive ?? true)
		setBinType(bin?.binTypeId.toString() ?? BinTypeId.Default)
		setErrorText(null)
		setIsSaving(false)
	}

	const isNew = !bin

	return (
		<Modal
			title={isNew ? "Create Bin" : "Edit Bin"}
			onOpen={setupEditModal}
			errorText={errorText}
			{...rest}
			rightButtons={[
				{
					buttonText: "Save",
					disabled: !code || !binType,
					isLoading: isSaving,
					onClick: isNew ? handleCreateBin : handleUpdateBin,
				},
			]}
		>
			<TextInput
				value={code}
				onChange={setCode}
				label="Bin code"
				maxWidth={18}
				maxLength={100}
			/>
			<BinTypeSelector value={binType} onChange={setBinType} maxWidth={18} fillContainer />
			<Checkbox
				label={
					<span>
						Bin is active
						<TooltipIcon>
							Bins that are not active are hidden and cannot have parts in them.
						</TooltipIcon>
					</span>
				}
				value={isActive}
				onChange={setIsActive}
			/>
		</Modal>
	)
}

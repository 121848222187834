import { FC } from "react"

import { SalesmanRole, useSalesmanRoles } from "@ncs/ncs-api"

import { ExtendableSelectProps, Select } from "../inputs"

export interface SalesRoleSelectorProps extends ExtendableSelectProps<SalesmanRole> {}

export const SalesRoleSelector: FC<SalesRoleSelectorProps> = ({ ...rest }) => {
	const [roles, isLoading] = useSalesmanRoles()

	return (
		<Select
			disabled={isLoading}
			label="Sales role"
			{...rest}
			options={roles ?? []}
			valueAccessor="id"
			textAccessor="description"
		/>
	)
}

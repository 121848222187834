import { FC } from "react"

import { ContractType, useContractTypes } from "@ncs/ncs-api"

import { ExtendableSelectProps, Select } from "../inputs"

export interface ContractTypeSelectorProps extends ExtendableSelectProps<ContractType> {}

export const ContractTypeSelector: FC<ContractTypeSelectorProps> = ({ ...rest }) => {
	const [types, isLoading] = useContractTypes()

	return (
		<Select
			disabled={isLoading}
			label="Contract type"
			{...rest}
			options={types ?? []}
			valueAccessor="id"
			textAccessor="description"
			fillContainer
		/>
	)
}

import { FC, useState } from "react"

import { css } from "@emotion/react"
import { Dayjs } from "dayjs"
import { Column } from "react-table"

import {
	defaultVacuumEventsQueryParams,
	useExportVacuumEvents,
	useVacuumEvents,
	VacuumEventHistory,
	VacuumEventsQueryParams,
} from "@ncs/ncs-api"
import { DateFormat, formatDateTime } from "@ncs/ts-utils"
import {
	EmptyValueDash,
	SearchQueryFilter,
	Table,
	VacuumEventTypeQueryFilter,
} from "@ncs/web-legos"

import { EventDetailModal } from "./EventDetailModal"

export interface EventsTabProps {
	deviceId: string | null
	startDate: Dayjs
	endDate: Dayjs
	isRefreshing?: boolean
}

const columns: Column<VacuumEventHistory>[] = [
	{
		Header: "Start",
		accessor: (original) =>
			original.startedOn ?
				formatDateTime(original.startedOn, DateFormat.DateTimeSeconds)
			:	<EmptyValueDash />,
	},
	{
		Header: "End",
		accessor: (original) =>
			original.endedOn ?
				formatDateTime(original.endedOn, DateFormat.DateTimeSeconds)
			:	<EmptyValueDash />,
	},
	{
		Header: "Type",
		accessor: "eventType",
	},
	{
		Header: "Message",
		accessor: "message",
		disableSortBy: true,
	},
	{
		Header: "code",
		accessor: (original) => original.eventCode ?? <EmptyValueDash />,
	},
]

export const EventsTab: FC<EventsTabProps> = ({ deviceId, startDate, endDate, isRefreshing }) => {
	const [eventForDetailModal, setEventForDetailModal] = useState<VacuumEventHistory | null>(null)

	// Just given to Table for ordering.
	const [paramState, setParamState] = useState<VacuumEventsQueryParams>(
		defaultVacuumEventsQueryParams
	)

	const combinedParams: VacuumEventsQueryParams = {
		...paramState,
		device: deviceId,
		startDate: startDate.utc().format(DateFormat.DateTimeQueryParamNoTz),
		endDate: endDate.utc().format(DateFormat.DateTimeQueryParamNoTz),
	}

	const vacuumEventsQuery = useVacuumEvents(combinedParams)
	const exportVacuumEvents = useExportVacuumEvents(combinedParams)

	return (
		<>
			<Table
				query={vacuumEventsQuery}
				columns={columns}
				css={tableStyle}
				onRowClick={(row) => setEventForDetailModal(row.original)}
				queryParamState={paramState}
				setQueryParamState={setParamState}
				toggledQueryFilters={[SearchQueryFilter, VacuumEventTypeQueryFilter]}
				dataExport={exportVacuumEvents}
				isUpdating={isRefreshing}
			/>

			<EventDetailModal
				isOpen={!!eventForDetailModal}
				onClose={() => setEventForDetailModal(null)}
				event={eventForDetailModal}
			/>
		</>
	)
}

const tableStyle = css`
	tr,
	td {
		vertical-align: top;
	}
`

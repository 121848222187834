import { FC, useMemo, useState } from "react"

import dayjs from "dayjs"
import { Cell, Column } from "react-table"

import {
	APPLICATION,
	FreightException,
	FreightExceptionsQueryParams,
	useFreightExceptions,
	useUserCanUse,
} from "@ncs/ncs-api"
import { dateRangeIsActive, displayDate, getTimezoneAbbreviation } from "@ncs/ts-utils"
import {
	Box,
	Button,
	CustomerQueryFilter,
	EmptyValueDash,
	Icon,
	ReactTableSortType,
	Table,
	Tooltip,
	useUrlState,
} from "@ncs/web-legos"

import { ActiveOnlyQueryFilter } from "~/components"

import { EditExceptionModal } from "./components"

export type CustomerExceptionsUrlState = FreightExceptionsQueryParams

export const CustomerExceptionsTab: FC = () => {
	const canEdit = useUserCanUse(APPLICATION.CustomerFreightExceptions)
	const [showNew, setShowNew] = useState(false)
	const [toEdit, setToEdit] = useState<FreightException | null>(null)
	const [params, { setUrlState }] = useUrlState(defaultUrlState)

	const [exceptions, exceptionsLoading] = useFreightExceptions({ params })

	const sortedExceptions = useMemo(() => {
		return (exceptions ?? []).sort((a, b) => {
			if (a.customerNumber === b.customerNumber) {
				return a.startDate > b.startDate ? -1 : 1
			}

			return a.customerName < b.customerNumber ? -1 : 1
		})
	}, [exceptions])

	const filtersStartOpen = Object.values(params).some((p) => p)

	return (
		<>
			{canEdit && (
				<Box d="flex" justifyContent="flex-end" mb={4}>
					<Button
						variant="secondary-cta"
						icon="plus-circle"
						onClick={() => setShowNew(true)}
					>
						Create Customer Exception
					</Button>
				</Box>
			)}

			<Table
				data={sortedExceptions}
				columns={columns}
				isLoading={exceptionsLoading}
				onRowClick={canEdit ? ({ original }) => setToEdit(original) : undefined}
				queryParamState={params}
				setQueryParamState={setUrlState}
				toggledQueryFilters={[ActiveOnlyQueryFilter, CustomerQueryFilter]}
				showToggledFiltersByDefault={filtersStartOpen}
			/>

			{(showNew || !!toEdit) && (
				<EditExceptionModal
					toEdit={toEdit}
					onClose={() => {
						setShowNew(false)
						setToEdit(null)
					}}
				/>
			)}
		</>
	)
}

const columns: Column<FreightException>[] = [
	{
		Header: "Customer",
		accessor: ({ customerNumber, customerName }) => `(${customerNumber}) ${customerName}`,
	},
	{
		Header: "Free freight orders per month",
		accessor: ({ allOrders, orderCountPerMonth }) =>
			allOrders ? "All orders" : orderCountPerMonth,
		sortType: ReactTableSortType.Number,
	},
	{
		Header: `Start (${getTimezoneAbbreviation()})`,
		accessor: ({ startDate }) => new Date(startDate),
		Cell: ({ row: { original } }: Cell<FreightException>) => displayDate(original.startDate),
		sortType: ReactTableSortType.Datetime,
	},
	{
		Header: `End (${getTimezoneAbbreviation()})`,
		accessor: ({ endDate }) => new Date(endDate),
		Cell: ({ row: { original } }: Cell<FreightException>) => displayDate(original.endDate),
		sortType: ReactTableSortType.Datetime,
	},
	{
		Header: "Status",
		Cell: ({ row: { original } }: Cell<FreightException>) => {
			return (
				dateRangeIsActive(original.startDate, original.endDate) ?
					<>
						<Icon icon="check" /> Active
					</>
				: dayjs(original.endDate).isBefore(original.startDate) ?
					<Tooltip title="Date range can never be active because end date comes before start date">
						<Icon icon="exclamation-triangle" />
					</Tooltip>
				:	<EmptyValueDash />
			)
		},
	},
	{
		Header: `Created (${getTimezoneAbbreviation()})`,
		accessor: ({ createdOn }) => new Date(createdOn),
		Cell: ({ row: { original } }: Cell<FreightException>) => displayDate(original.createdOn),
		sortType: ReactTableSortType.Datetime,
		hiddenByDefault: true,
	},
	{
		Header: "Created by",
		accessor: "userCreated",
		hiddenByDefault: true,
	},
	{
		Header: `Last modified (${getTimezoneAbbreviation()})`,
		accessor: ({ modifiedOn }) => new Date(modifiedOn),
		Cell: ({ row: { original } }: Cell<FreightException>) => displayDate(original.modifiedOn),
		sortType: ReactTableSortType.Datetime,
		hiddenByDefault: true,
	},
	{
		Header: "Last modified by",
		accessor: "userLastModified",
		hiddenByDefault: true,
	},
]

const defaultUrlState: CustomerExceptionsUrlState = {
	active: null,
	customer: null,
}

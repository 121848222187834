import React, { useCallback, useMemo, useState } from "react"

import { makeStyles } from "@material-ui/core"
import classNames from "classnames"
import moment from "moment"

import { WASH_COUNT_SUBMISSION_STATUSES } from "@ncs/mortar/util/constants"
import {
	extractInteger,
	formatNumber,
	formatNumberAsCurrency,
	titleCase,
} from "@ncs/bricks/util/formatters"

import { Tooltip } from "~/components"

import RowComment, { RESET_COMMENT_ID } from "./RowComment"
import { rowContentsStyles } from "./rowContentsStyles"
import { washCountReady } from "./washCountSubmissionUtils"

import { CheckCircleOutline, Input, NoEncryption, Warning } from "@material-ui/icons"

export const useStyles = makeStyles(rowContentsStyles)

const RowContents = ({ washCount, index, updateParentWashCountState }) => {
	const classes = useStyles()
	const [localCurrentCount, setLocalCurrentCount] = useState(washCount.currentCount)
	const [localNewEnding, setLocalNewEnding] = useState(washCount.newEnding)

	const ready = useMemo(() => washCountReady(washCount), [washCount])
	const submitted = washCount.status.id === WASH_COUNT_SUBMISSION_STATUSES.SUBMITTED.id

	const onMonthBlur = () => {
		const newEnding = localCurrentCount + washCount.lastMonthEnding

		// Unless the count reset comment option is chosen, we'll automatically
		// do the math to update the total count based on the new month count.
		if (washCount.commentId !== RESET_COMMENT_ID) setLocalNewEnding(newEnding)

		// Also update the parent. setTimeout lets the local setState happen
		// asap without waiting for this slower setState.
		setTimeout(
			() =>
				updateParentWashCountState(
					{
						...washCount,
						newEnding:
							washCount.commentId !== RESET_COMMENT_ID ?
								newEnding
							:	washCount.newEnding,
						currentCount: localCurrentCount,
					},
					index
				),
			1
		)
	}

	const onTotalBlur = () => {
		// Calc the new current count based on new local ending.
		const newCurrentCount = localNewEnding - washCount.lastMonthEnding

		// If you're new total puts the current count below zero, then reset it
		// cause ya done screwed up.
		if (newCurrentCount < 0) {
			setLocalNewEnding(washCount.originalNewEnding)

			return
		}

		// Unless the count reset comment option is chosen, we'll automatically
		// do the math to update the current count based on the new total count.
		if (washCount.commentId !== RESET_COMMENT_ID) setLocalCurrentCount(newCurrentCount)

		setTimeout(
			() =>
				updateParentWashCountState(
					{
						...washCount,
						currentCount:
							washCount.commentId !== RESET_COMMENT_ID ?
								newCurrentCount
							:	washCount.currentCount,
						newEnding: localNewEnding,
					},
					index
				),
			1
		)
	}

	const onCommentChange = useCallback(
		(commentId, comment) => {
			updateParentWashCountState(
				{
					...washCount,
					commentId,
					comment,
				},
				index
			)
		},
		[index, washCount, updateParentWashCountState]
	)

	const dispatchDate = useMemo(
		() => moment(washCount.dispatch?.workOrderDate).format("MMM D"),
		[washCount.dispatch]
	)

	const status = useMemo(() => {
		if (submitted) {
			return (
				<span className={classNames(classes.status, classes.statusSubmitted)}>
					<Input />
					Submitted
				</span>
			)
		}

		if (!ready) {
			return (
				<span className={classNames(classes.status, classes.statusInvalid)}>
					<Warning />
					Requires comment
				</span>
			)
		}

		return (
			<span className={classNames(classes.status, classes.statusValid)}>
				<CheckCircleOutline />
				Ready
			</span>
		)
	}, [classes, submitted, ready])

	const unlockedIndicator = useMemo(
		() => (
			<Tooltip
				enterDelay={150}
				title={
					<>
						When <strong>Resetting counts</strong> is the comment, these fields can be
						edited without automatically changing each other.
					</>
				}
			>
				<NoEncryption />
			</Tooltip>
		),
		[]
	)

	return (
		<>
			<td>
				<span>
					{washCount?.package?.portalContractSite?.customer?.customerNumber ?? ""}
				</span>
				<br />
				<span>
					{titleCase(washCount?.package?.portalContractSite?.customer?.name ?? "")}
				</span>
			</td>
			<td>
				<div className={classes.serialNumber}>
					{washCount.package?.portalContractSite?.customerEquipment?.serialNumber}
				</div>
			</td>
			<td>{washCount.package?.packageDescription}</td>
			<td>{formatNumber(washCount.lastMonthStarting)}</td>
			<td>{formatNumber(washCount.lastMonthEnding)}</td>
			<td>{formatNumber(washCount.lastMonthEnding - washCount.lastMonthStarting)}</td>
			<td className={classes.dispatchLink}>
				{washCount.dispatch?.Id ?
					<a
						href={`/service/dispatches/${washCount.dispatch?.Id}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						{dispatchDate}
					</a>
				:	<>--</>}
			</td>
			<td>
				{!submitted ?
					<div className={classes.flexCell}>
						<input
							type="text"
							value={formatNumber(localCurrentCount)}
							onChange={(e) => setLocalCurrentCount(extractInteger(e.target.value))}
							onBlur={onMonthBlur}
							style={{ maxWidth: "4rem" }}
						/>
						{washCount.commentId === RESET_COMMENT_ID && unlockedIndicator}
					</div>
				:	<span className={classes.submittedCount}>{formatNumber(localCurrentCount)}</span>}
			</td>
			<td>
				{!submitted ?
					<div className={classes.flexCell}>
						<input
							type="text"
							value={formatNumber(localNewEnding)}
							onChange={(e) => setLocalNewEnding(extractInteger(e.target.value))}
							onBlur={onTotalBlur}
							style={{ maxWidth: "5rem" }}
						/>
						{washCount.commentId === RESET_COMMENT_ID && unlockedIndicator}
					</div>
				:	<span className={classes.submittedCount}>{formatNumber(localNewEnding)}</span>}
			</td>
			<td>{formatNumberAsCurrency(washCount.currentCount * washCount.package.price)}</td>
			<td>
				<RowComment
					errorMessage={washCount.errorMessage}
					onCommentChange={onCommentChange}
					isSubmitted={submitted}
				/>
			</td>
			<td>{status}</td>
		</>
	)
}

export default React.memo(RowContents)

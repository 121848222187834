import { Dispatch, FC, memo, SetStateAction, useEffect, useMemo, useState } from "react"

import { useFormContext, useWatch } from "react-hook-form"
import { Cell, Column } from "react-table"

import {
	GenericLineItem,
	GenericLineItemWithBasePrice,
	lineItemsAreEqual,
	lineTypesRepeatable,
	useCalculateLineItemPrices,
} from "@ncs/ncs-api"
import { extractNumber, formatCurrency, formatNumber } from "@ncs/ts-utils"
import {
	Box,
	Button,
	ErrorText,
	GridContainer,
	GridItem,
	HeadingDivider,
	Label,
	Price,
	Table,
	useChangeCallback,
	useScreenSizeMatch,
	useToast,
} from "@ncs/web-legos"

import { LineItemEditorModal, SaveLineItemExtras } from "~/components"

import { CreateQuoteOrderForm } from "../quotes-util"

export interface CreateQuoteLineItemsProps {
	lineItemsToSubmit: GenericLineItemWithBasePrice[]
	setLineItemsToSubmit: Dispatch<SetStateAction<GenericLineItemWithBasePrice[]>>
	lineItemErrorText: string | null
	setLineItemErrorText: Dispatch<SetStateAction<string | null>>
	orderSubtotal: number
	orderTaxes: number
	orderTotal: number
}

export const CreateQuoteLineItems: FC<CreateQuoteLineItemsProps> = memo(
	({
		lineItemsToSubmit,
		setLineItemsToSubmit,
		lineItemErrorText,
		setLineItemErrorText,
		orderSubtotal,
		orderTaxes,
		orderTotal,
	}) => {
		const screenIsXs = useScreenSizeMatch("xs")
		const { makeSuccessToast, makeErrorToast } = useToast()
		const [showNewLineItemModal, setShowNewLineItemModal] = useState(false)
		const [lineItemToEdit, setLineItemToEdit] = useState<GenericLineItem | null>(null)

		const recalculateLineItems = useCalculateLineItemPrices()

		const { control } = useFormContext<CreateQuoteOrderForm>()
		const [shipToId, billToId] = useWatch({
			name: ["shipToId", "billToId"],
			control,
		})

		const onLineItemSave = (
			newLine: GenericLineItemWithBasePrice,
			{ isEdit }: SaveLineItemExtras
		) => {
			// If any of the current line items are the same type as the new one, then unless
			// it's one of the types that can have repeats, show an error and stop.
			if (
				!isEdit &&
				lineItemsToSubmit.some(
					(currentLine) =>
						currentLine.lineTypeId === newLine.lineTypeId &&
						lineTypesRepeatable.includes(newLine.lineTypeId) === false
				)
			) {
				makeErrorToast(
					"Line item type is already on order. Try editing or removing the existing line first."
				)
				return
			}

			const existingEntryIndex = lineItemsToSubmit.findIndex((line) =>
				lineItemsAreEqual(line, newLine)
			)

			if (isEdit) {
				// We're editing an existing line, so blow away the old one.
				setLineItemsToSubmit((prev) =>
					prev.map((lineItem, i) => (i === existingEntryIndex ? newLine : lineItem))
				)
				makeSuccessToast("Line item updated")
			} else {
				if (existingEntryIndex !== -1) {
					// We weren't editing, but the part already existed so merge their quantities.
					setLineItemsToSubmit((prev) =>
						prev.map((lineItem, i) =>
							i === existingEntryIndex ?
								{
									...newLine,
									quantity: lineItem.quantity + newLine.quantity,
									lineTotal:
										(lineItem.quantity + newLine.quantity) *
										newLine.finalPrice,
								}
							:	lineItem
						)
					)
					makeSuccessToast("Line item updated")
				} else {
					setLineItemsToSubmit((prev) => [...prev, newLine]) // Brand new, just add it.
					makeSuccessToast("Line item added")
				}
			}
		}

		const onLineItemDelete = (lineToDelete: GenericLineItem) => {
			setLineItemToEdit(null)
			setLineItemsToSubmit((prev) =>
				prev.filter((line) => !lineItemsAreEqual(line, lineToDelete))
			)
		}

		const onLineItemEdit = (lineItem: GenericLineItem) => {
			setLineItemToEdit(lineItem)
		}

		// Clear out line items error text.
		useEffect(() => {
			if (!!lineItemErrorText && lineItemsToSubmit.length > 0) {
				setLineItemErrorText(null)
			}
		}, [lineItemErrorText, lineItemsToSubmit, setLineItemErrorText])

		// Recalculate the line items prices if the bill to changes.
		useChangeCallback(billToId, async (newBillToId) => {
			if (newBillToId) {
				const updateLineItems = await recalculateLineItems({
					billToId: newBillToId,
					customerId: shipToId,
					lineItems: lineItemsToSubmit,
				})
				setLineItemsToSubmit(updateLineItems)
			}
		})

		const currentWeightTotal = useMemo(() => {
			return lineItemsToSubmit.reduce((total: number, line) => {
				return total + extractNumber(line.part?.weight ?? 0) * line.quantity
			}, 0)
		}, [lineItemsToSubmit])

		return (
			<>
				<HeadingDivider headingVariant="h3" mt={3}>
					2. Line Items
				</HeadingDivider>

				<Box pl={1.5}>
					<GridContainer px={0.5} mt={2} mb={1}>
						<GridItem xs={12} sm={6}>
							<Button
								variant="secondary-cta"
								icon="plus-circle"
								onClick={() => setShowNewLineItemModal(true)}
								disabled={!shipToId || !billToId}
								fillContainer={screenIsXs}
							>
								Add line item
							</Button>
						</GridItem>
						<GridItem
							xs={12}
							sm={6}
							display="flex"
							columnGap={2}
							justifyContent="flex-end"
							xsProps={{ justifyContent: "flex-start" }}
						>
							<div>
								<Label>Subtotal</Label>
								<Price price={orderSubtotal} small />
							</div>
							<div>
								<Label>Taxes</Label>
								<Price price={orderTaxes} small />
							</div>
							<div>
								<Label>Total</Label>
								<Price price={orderTotal} small />
							</div>
						</GridItem>
					</GridContainer>

					{lineItemsToSubmit.length > 0 && (
						<Table
							data={lineItemsToSubmit.sort((a, b) => {
								if (!!a.part && !b.part) {
									return -1
								}
								if (!a.part && !!b.part) {
									return 1
								}
								return a.description > b.description ? 1 : -1
							})}
							columns={columns}
							rowMenu={[
								{
									label: "Edit",
									iconName: "pencil",
									onClick: ({ original }) => onLineItemEdit(original),
									disabledAccessor: () => !billToId || !shipToId,
								},
								{
									label: "Remove",
									iconName: "trash",
									onClick: ({ original }) => onLineItemDelete(original),
								},
							]}
							disableAllSorting
						/>
					)}

					{!!lineItemErrorText && <ErrorText my={1}>{lineItemErrorText}</ErrorText>}
				</Box>

				{(showNewLineItemModal || !!lineItemToEdit) && (
					<LineItemEditorModal
						lineItemToEdit={lineItemToEdit}
						onClose={() => {
							setShowNewLineItemModal(false)
							setLineItemToEdit(null)
						}}
						shipToId={shipToId || null}
						billToId={billToId || null}
						onSave={onLineItemSave}
						onRemove={onLineItemDelete}
						currentWeightTotal={currentWeightTotal}
					/>
				)}
			</>
		)
	}
)

const columns: Column<GenericLineItem>[] = [
	{
		Header: "Description",
		accessor: "description",
	},
	{
		// Note that this is not the `unit price` that comes from pricing endpoint.
		// Calling it "Unit Price" just makes the most sense to the user.
		Header: "Unit Price",
		accessor: (original) =>
			original.finalPrice != null ? formatCurrency(original.finalPrice) : "-",
	},
	{
		Header: "Qty",
		accessor: "quantity",
		Cell: ({ row: { original } }: Cell<GenericLineItem>) => {
			if (!original.part) {
				return formatNumber(original.quantity)
			} else {
				return formatNumber(original.quantity)
			}
		},
	},
	{
		Header: "Line Subtotal",
		accessor: (original) => formatCurrency(original.subtotal),
	},
]

CreateQuoteLineItems.displayName = "CreateQuoteLineItems"

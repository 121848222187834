import React from "react"

import { ExtendableSelectProps, Select } from "../inputs"

type ContactedStatus = { contacted: string; description: string }
export type DispatchCallContactedSelector = ExtendableSelectProps<ContactedStatus>

export const DispatchCallContactedSelector: React.FC<DispatchCallContactedSelector> = ({
	value,
	onChange,
	...rest
}) => {
	const contactedStatus: Array<ContactedStatus> = [
		{
			contacted: "true",
			description: "Contacted",
		},
		{
			contacted: "false",
			description: "Not Contacted",
		},
	]

	return (
		<Select
			label="Customer Contacted"
			{...rest}
			value={value}
			onChange={onChange}
			options={contactedStatus}
			disabled={false}
			valueAccessor="contacted"
			textAccessor="description"
		/>
	)
}

import {
	faAbacus,
	faAlignSlash,
	faAngleDown,
	faAngleUp,
	faArrowTurnDownRight,
	faBalanceScaleLeft,
	faBan,
	faBarcodeRead,
	faBook,
	faBoxCheck,
	faBoxes,
	faBoxFull,
	faBoxOpen,
	faBrakeWarning,
	faBrowser,
	faCalculator,
	faCalendarCircleExclamation,
	faCalendarCircleUser,
	faCalendarExclamation,
	faCartShoppingFast,
	faChartNetwork,
	faChartSimple,
	faCheckCircle,
	faClipboardCheck,
	faClipboardList,
	faClock,
	faCodeCommit,
	faCog,
	faCommentAltLines,
	faCommentDollar,
	faCopy,
	faDollarSign,
	faDollyFlatbedAlt,
	faEmptySet,
	faEnvelope,
	faEnvelopeOpen,
	faEnvelopeOpenDollar,
	faExchangeAlt,
	faExclamationCircle,
	faExclamationTriangle,
	faFileSignature,
	faFileSpreadsheet,
	faFillDrip,
	faFilter,
	faFloppyDiskPen,
	faFolderOpen,
	faFolders,
	faGears,
	faGift,
	faHandHoldingDollar,
	faHistory,
	faHome,
	faHourglassHalf,
	faHouseCircleExclamation,
	faInboxIn,
	faInboxOut,
	faInfoCircle,
	faLink,
	faList,
	faLocationCheck,
	faLock,
	faMagnifyingGlassChart,
	faMapMarkerAlt,
	faMessage,
	faMessageDollar,
	faMinusSquare,
	faMoneyBillWave,
	faPalletAlt,
	faPalletBox,
	faPaperclipVertical,
	faPaperPlane,
	faParachuteBox,
	faPennant,
	faPercent,
	faPersonDigging,
	faPersonDolly,
	faPlusCircle,
	faPrescriptionBottle,
	faPrint,
	faQuestionCircle,
	faRaindrops,
	faRandom,
	faRedo,
	faRotateExclamation,
	faScannerGun,
	faSearchLocation,
	faSearchPlus,
	faSensorAlert,
	faShareAll,
	faShippingFast,
	faShoppingBasket,
	faShoppingCart,
	faSitemap,
	faSort,
	faSplit,
	faSync,
	faTable,
	faTachometer,
	faTag,
	faTags,
	faThumbsDown,
	faThumbsUp,
	faTools,
	faTrash,
	faTrashAlt,
	faTrashArrowUp,
	faTruck,
	faTruckContainer,
	faTruckFast,
	faUndo,
	faUsdSquare,
	faUserCog,
	faUserHardHat,
	faUserLargeSlash,
	faUserLock,
	faUsers,
	faWarehouse,
	faWarehouseAlt,
	faWifi,
	faWindowMaximize,
	faWrench,
} from "@fortawesome/pro-regular-svg-icons"
import {
	faCaretDown as faCaretDownSolid,
	faCaretUp as faCaretUpSolid,
	faCheckCircle as faCheckCircleSolid,
	faExclamationTriangle as faExclamationTriangleSolid,
	faGarageCar as faGarageCarSolid,
	faShieldHalved as faShieldHalvedSolid,
	faStar as faStarSolid,
} from "@fortawesome/pro-solid-svg-icons"

export const faIcons = [
	faAbacus,
	faAlignSlash,
	faAngleDown,
	faAngleUp,
	faArrowTurnDownRight,
	faBalanceScaleLeft,
	faBan,
	faBarcodeRead,
	faBook,
	faBoxCheck,
	faBoxes,
	faBoxFull,
	faBoxOpen,
	faBrakeWarning,
	faBrowser,
	faCalculator,
	faCalendarCircleExclamation,
	faCalendarCircleUser,
	faCalendarExclamation,
	faCaretDownSolid,
	faCaretUpSolid,
	faCartShoppingFast,
	faChartNetwork,
	faChartSimple,
	faCheckCircle,
	faCheckCircleSolid,
	faClipboardCheck,
	faClipboardList,
	faClock,
	faCodeCommit,
	faCog,
	faCommentAltLines,
	faCommentDollar,
	faCopy,
	faDollarSign,
	faDollyFlatbedAlt,
	faEmptySet,
	faEnvelope,
	faEnvelopeOpen,
	faEnvelopeOpenDollar,
	faEnvelopeOpenDollar,
	faExchangeAlt,
	faExclamationCircle,
	faExclamationTriangle,
	faExclamationTriangleSolid,
	faFileSignature,
	faFileSpreadsheet,
	faFillDrip,
	faFilter,
	faFloppyDiskPen,
	faFolderOpen,
	faFolders,
	faGarageCarSolid,
	faGears,
	faGift,
	faHandHoldingDollar,
	faHistory,
	faHome,
	faHourglassHalf,
	faHouseCircleExclamation,
	faInboxIn,
	faInboxOut,
	faInfoCircle,
	faLink,
	faList,
	faLocationCheck,
	faLock,
	faMagnifyingGlassChart,
	faMapMarkerAlt,
	faMessage,
	faMessageDollar,
	faMinusSquare,
	faMoneyBillWave,
	faPalletAlt,
	faPalletBox,
	faPaperclipVertical,
	faPaperPlane,
	faParachuteBox,
	faPennant,
	faPercent,
	faPersonDigging,
	faPersonDolly,
	faPlusCircle,
	faPrescriptionBottle,
	faPrint,
	faQuestionCircle,
	faRaindrops,
	faRandom,
	faRedo,
	faRotateExclamation,
	faScannerGun,
	faSearchLocation,
	faSearchPlus,
	faSensorAlert,
	faShareAll,
	faShieldHalvedSolid,
	faShippingFast,
	faShoppingBasket,
	faShoppingCart,
	faSitemap,
	faSort,
	faSplit,
	faStarSolid,
	faSync,
	faTable,
	faTachometer,
	faTag,
	faTags,
	faThumbsDown,
	faThumbsUp,
	faTools,
	faTrash,
	faTrashAlt,
	faTrashArrowUp,
	faTruck,
	faTruckContainer,
	faTruckFast,
	faUndo,
	faUsdSquare,
	faUserCog,
	faUserHardHat,
	faUserLargeSlash,
	faUserLock,
	faUsers,
	faWarehouse,
	faWarehouseAlt,
	faWifi,
	faWindowMaximize,
	faWrench,
]

import { FC, useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"

import {
	CreateSpentCreditPost,
	CustomerDetail,
	InvoiceSource,
	makeApiErrorMessage,
	PartOrderList,
	useCreateSpentCredit,
} from "@ncs/ncs-api"
import {
	AnimatedEntrance,
	DateInputFormField,
	ExtendableModalProps,
	GridContainer,
	GridItem,
	InvoiceSelector,
	Modal,
	NumericInputFormField,
	PartOrderSelector,
	RadioGroup,
	TextareaFormField,
	useChangeCallback,
	useToast,
} from "@ncs/web-legos"

export interface RecordExpenditureModalProps extends ExtendableModalProps {
	customer: CustomerDetail
	creditId: string
}

export const RecordExpenditureModal: FC<RecordExpenditureModalProps> = ({
	customer,
	creditId,
	...rest
}) => {
	const { makeSuccessToast } = useToast()
	const [assignmentType, setAssignmentType] = useState<"part-order" | "invoice">("part-order")
	const [selectedPartOrder, setSelectedPartOrder] = useState<PartOrderList[number] | null>(null)
	const [selectedInvoice, setSelectedInvoice] = useState<InvoiceSource | null>(null)
	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const createExpenditure = useCreateSpentCredit()

	const {
		control,
		handleSubmit: formHandleSubmit,
		reset,
		getValues,
	} = useForm<CreditExpenditureForm>({
		resolver: zodResolver(CreditExpenditureFormSchema),
		defaultValues: { ...defaultCreditExpenditureFormValues },
	})

	const handleSubmit = async (formData: CreditExpenditureForm) => {
		try {
			setIsSaving(true)
			if (formData.amount == null) throw new Error("Amount is required")
			if (!formData.partOrderId && !formData.invoiceId) {
				throw new Error("Please select either a part order or an invoice")
			}

			const postData: CreateSpentCreditPost = {
				credit: creditId,
				transactionDate: formData.transactionDate,
				description: formData.comments,
				amount: formData.amount,
				partOrderId: formData.partOrderId,
				invoiceId: formData.invoiceId,
			}
			await createExpenditure(postData)
			makeSuccessToast("Expenditure recorded")
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	/* Keep the form up to date with the selector selections. */
	useChangeCallback(selectedPartOrder?.id, (newPartOrderId) => {
		reset({
			...getValues(),
			partOrderId: newPartOrderId ?? null,
			invoiceId: null,
		})
	})
	useChangeCallback(selectedInvoice?.id, (newInvoiceId) => {
		reset({
			...getValues(),
			invoiceId: newInvoiceId ?? null,
			partOrderId: null,
		})
	})

	return (
		<Modal
			{...rest}
			title="Record Credit Expenditure"
			errorText={errorText}
			rightButtons={{
				buttonText: "Save",
				onClick: formHandleSubmit(handleSubmit),
				isLoading: isSaving,
			}}
		>
			<GridContainer mb={1}>
				<GridItem xs={12} sm={6}>
					<NumericInputFormField
						control={control}
						name="amount"
						label="Amount $"
						decimalScale={2}
						fixedDecimalScale
					/>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<DateInputFormField
						control={control}
						name="transactionDate"
						label="Transaction date"
					/>
				</GridItem>
			</GridContainer>

			<RadioGroup
				htmlName="assignment-type"
				description="Associate this transaction with:"
				value={assignmentType}
				onChange={(newValue, newOption) => setAssignmentType(newOption.value)}
				options={[
					{
						value: "part-order" as const,
						label: "Part order",
					},
					{
						value: "invoice" as const,
						label: "Invoice",
					},
				]}
			/>
			<AnimatedEntrance show={assignmentType === "part-order"}>
				<PartOrderSelector value={selectedPartOrder} onChange={setSelectedPartOrder} />
			</AnimatedEntrance>
			<AnimatedEntrance show={assignmentType === "invoice"}>
				<InvoiceSelector value={selectedInvoice} onChange={setSelectedInvoice} />
			</AnimatedEntrance>

			<TextareaFormField control={control} name="comments" />
		</Modal>
	)
}

const CreditExpenditureFormSchema = z
	.object({
		amount: z.number().min(1).nullable(),
		transactionDate: z.string().min(1, "Required"),
		partOrderId: z.string().nullable(),
		invoiceId: z.string().nullable(),
		comments: z.string().max(1000).nullable(),
	})
	.refine((values) => typeof values.amount === "number", {
		message: "Required",
		path: ["amount"],
	})

export type CreditExpenditureForm = z.infer<typeof CreditExpenditureFormSchema>

const defaultCreditExpenditureFormValues: Partial<CreditExpenditureForm> = {
	amount: undefined,
	transactionDate: "",
	partOrderId: null,
	invoiceId: null,
	comments: null,
}

import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core"

import { formatNumberAsCurrency } from "../../util/formatters"

class FancyCurrency extends React.PureComponent {
	render() {
		const { amount, classes, ...rest } = this.props

		let formattedPrice = formatNumberAsCurrency(amount)
		let symbol = formattedPrice.substring(0, 1)
		let price = formattedPrice.substring(1)
		let whole = price.split(".")[0]
		let fraction = price.split(".")[1]

		// split into superscripted $, normal dollars, no decimal, superscripted cents
		return (
			<span {...rest}>
				<span className={classes.priceSymbol}>{symbol}</span>
				<span className={classes.priceWhole}>{whole}</span>
				<span className={classes.priceFraction}>{fraction}</span>
			</span>
		)
	}
}

const styles = {
	priceSymbol: {
		position: "relative",
		top: "-0.5rem",
		fontSize: "0.70rem",
	},
	priceWhole: {
		fontSize: "1.25rem",
	},
	priceFraction: {
		position: "relative",
		top: "-0.5rem",
		fontSize: "0.70rem",
	},
}

FancyCurrency.propTypes = {
	amount: PropTypes.number.isRequired,
}

export default withStyles(styles)(FancyCurrency)

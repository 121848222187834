import React from "react"
import PropTypes from "prop-types"
import SweetAlert from "react-bootstrap-sweetalert"
import { Portal } from "react-portal"
import { withStyles } from "@material-ui/core"

import sweetAlertStyle from "../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle"
import buttonsStyle from "../../assets/jss/material-dashboard-pro-react/views/buttonsStyle"

const styles = {
	...buttonsStyle,
	...sweetAlertStyle,
}

class CustomAlert extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			showSuccess: false,
			showCancellation: false,
		}
	}

	handleConfirm = (props) => () => {
		// call parent onConfirm for handling and hiding of main alert
		if (typeof props.onConfirm === "function") props.onConfirm()

		// show success if configured
		if (props.successTitle) {
			this.setState({ showSuccess: true })
		}
	}

	handleCancel = (props) => () => {
		// call parent onCancel for handling and hiding of main alert
		if (typeof props.onCancel === "function") props.onCancel()

		// show cancellation if configured
		if (props.cancellationTitle) {
			this.setState({ showCancellation: true })
		}
	}

	handleConfirmSuccess = (props) => () => {
		this.setState({ showSuccess: false })
		if (typeof props.onSuccessConfirm === "function") props.onSuccessConfirm()
	}

	handleConfirmCancel = (props) => () => {
		this.setState({ showCancellation: false })
		if (typeof props.onCancellationConfirm === "function") props.onCancellationConfirm()
	}

	render() {
		const {
			classes,
			children,
			style,
			autoWidth,
			confirmBtnCssClass,
			cancelBtnCssClass,
			confirmBtnColor,
			cancelBtnColor,
			successTitle,
			successContent,
			successConfirmText,
			cancellationTitle,
			cancellationContent,
			cancellationConfirmText,
			show,
			isModal,
			successProps,
			cancellationProps,
			onConfirm,
			onCancel,
			...rest
		} = this.props

		let customStyle = {
			...style,
		}
		if (autoWidth) {
			customStyle.width = "auto"
		}

		let confirmCss = confirmBtnCssClass || classes.button + " " + classes[confirmBtnColor]
		let cancelCss = cancelBtnCssClass || classes.button + " " + classes[cancelBtnColor]
		let successBtnCss = classes.button + " " + classes.success

		return (
			<Portal>
				<SweetAlert
					showCancel={
						this.props.showCancel !== false &&
						(typeof this.props.onCancel === "function" ||
							typeof this.props.onCancellationConfirm === "function")
					}
					style={customStyle}
					confirmBtnCssClass={confirmCss}
					cancelBtnCssClass={cancelCss}
					{...rest}
					onConfirm={this.handleConfirm(this.props)}
					onCancel={this.handleCancel(this.props)}
					show={show && !this.state.showCancellation && !this.state.showSuccess}
					closeOnClickOutside={!isModal}
					closeOnEsc={!isModal}
				>
					{children}
				</SweetAlert>

				{(successTitle || successContent) && (
					<SweetAlert
						success
						show={this.state.showSuccess}
						style={{ display: "block", marginTop: "-100px" }}
						title={successTitle}
						confirmBtnCssClass={successBtnCss}
						confirmBtnText={successConfirmText}
						onConfirm={this.handleConfirmSuccess(this.props)}
						{...successProps}
					>
						{successContent}
					</SweetAlert>
				)}

				{(cancellationTitle || cancellationContent) && (
					<SweetAlert
						danger
						show={this.state.showCancellation}
						style={{ display: "block", marginTop: "-100px" }}
						title={cancellationTitle}
						confirmBtnCssClass={successBtnCss}
						confirmBtnText={cancellationConfirmText}
						onConfirm={this.handleConfirmCancel(this.props)}
						{...cancellationProps}
					>
						{cancellationContent}
					</SweetAlert>
				)}
			</Portal>
		)
	}
}

CustomAlert.defaultProps = {
	isModal: true,
	confirmBtnText: "Submit",
	cancelBtnText: "Cancel",
	confirmBtnColor: "success",
	cancelBtnColor: "warning",
	successConfirmText: "OK",
	successContent: "",
	cancellationConfirmText: "OK",
	cancellationContent: "",
}

CustomAlert.propTypes = {
	classes: PropTypes.object.isRequired,
	show: PropTypes.bool.isRequired,
	title: PropTypes.string.isRequired,
	onConfirm: PropTypes.func.isRequired,
	onCancel: PropTypes.func,
	isModal: PropTypes.bool,

	confirmBtnText: PropTypes.string,
	cancelBtnText: PropTypes.string,
	confirmBtnColor: PropTypes.oneOf([
		"primary",
		"info",
		"success",
		"warning",
		"danger",
		"danger",
		"rose",
		"white",
	]),
	cancelBtnColor: PropTypes.oneOf([
		"primary",
		"info",
		"success",
		"warning",
		"danger",
		"danger",
		"rose",
		"white",
	]),
	autoWidth: PropTypes.bool,

	successTitle: PropTypes.string,
	successContent: PropTypes.node,
	successConfirmText: PropTypes.string,
	onSuccessConfirm: PropTypes.func,
	successProps: PropTypes.object,

	cancellationTitle: PropTypes.string,
	cancellationContent: PropTypes.node,
	cancellationConfirmText: PropTypes.string,
	onCancellationConfirm: PropTypes.func,
	cancellationProps: PropTypes.object,
}

export default withStyles(styles)(CustomAlert)

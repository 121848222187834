import { FC, useState } from "react"

import { useHistory, useParams } from "react-router-dom"

import {
	makeApiErrorMessage,
	usePricingAdjustmentRequest,
	useUpdatePricingAdjustmentForm,
} from "@ncs/ncs-api"
import {
	Box,
	Button,
	Card,
	ConfirmationModal,
	ConfirmationModalConfig,
	EditStringModal,
	EditStringModalProps,
	HeadingDivider,
	LoadingSpinner,
	Paragraph,
	ThrottledTextarea,
	useScrollToTop,
	useToast,
} from "@ncs/web-legos"

import { priceAdjustmentPermissions } from "~/util"

import { RequestDetailCustomers, RequestDetailFreight, RequestDetailHeader } from "./components"

export const PricingAdjustmentRequestDetail: FC = () => {
	const { makeSuccessToast, makeErrorToast } = useToast()
	const history = useHistory()
	const scrollToTop = useScrollToTop()
	const { id } = useParams<{ id: string }>()
	const [submissionComment, setSubmissionComment] = useState<string | null>(null)
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)
	const [editStringModalConfig, setEditStringModalConfig] =
		useState<EditStringModalProps | null>(null)

	const [request, requestLoading] = usePricingAdjustmentRequest(id)
	const updateForm = useUpdatePricingAdjustmentForm()

	const handleSubmit = () => {
		setConfirmationConfig({
			title: "Submit for Approval",
			message: "Confirm: Submit this pricing adjustment request form for approval?",
			onConfirm: async () => {
				try {
					await updateForm({
						id,
						updates: { action: "submit", actionComments: submissionComment },
					})
					makeSuccessToast("Form submitted")
					scrollToTop()
					setSubmissionComment(null)
				} catch (e) {
					makeErrorToast(makeApiErrorMessage(e))
				}
			},
		})
	}

	const handleApprove = () => {
		setConfirmationConfig({
			title: "Approve Request",
			message: "Confirm: Apply the pricing adjustments in this form?",
			onConfirm: async () => {
				try {
					await updateForm({
						id,
						updates: { action: "approve", actionComments: submissionComment },
					})
					makeSuccessToast("Form approved")
					// TODO: What happens now? Do price changes happen right away?
					// SHould we go back to the list page?
					scrollToTop()
					setSubmissionComment(null)
				} catch (e) {
					makeErrorToast(makeApiErrorMessage(e))
				}
			},
		})
	}

	const handleReject = () => {
		setConfirmationConfig({
			title: "Reject Request",
			message: "Confirm: Reject the pricing adjustments in this form?",
			onConfirm: async () => {
				try {
					await updateForm({
						id,
						updates: { action: "deny", actionComments: submissionComment },
					})
					makeSuccessToast("Form rejected")
					// TODO: What happens now? Do we lock this form from any future edits?
					// Should we go back to the list page?
					scrollToTop()
					setSubmissionComment(null)
				} catch (e) {
					makeErrorToast(makeApiErrorMessage(e))
				}
			},
		})
	}

	const handleRequestMoreInfo = () => {
		setConfirmationConfig({
			title: "Request More Info",
			message:
				"Confirm: Mark this request as needing more information before deciding to approve or reject? Please elaborate in the comments box.",
			onConfirm: async () => {
				try {
					await updateForm({
						id,
						updates: { action: "more-info", actionComments: submissionComment },
					})
					makeSuccessToast("Form marked as needing more info")
					// TODO: What happens now? Do we lock this form from any future edits?
					// Should we go back to the list page?
					scrollToTop()
					setSubmissionComment(null)
				} catch (e) {
					makeErrorToast(makeApiErrorMessage(e))
				}
			},
		})
	}

	const handleCancel = () => {
		setEditStringModalConfig({
			title: "Cancel Request",
			message:
				"Confirm: Cancel and close out this pricing adjustment request? No further actions can be taken on it once it is canceled.",
			label: "Comments (optional)",
			textarea: true,
			allowEmpty: true,
			maxLength: 2000,
			saveButtonLabel: "Cancel Request",
			onSave: async (value) => {
				try {
					await updateForm({
						id,
						updates: { action: "cancel", actionComments: value },
					})
					makeSuccessToast("Form canceled")
					// TODO: What happens now? Do we lock this form from any future edits?
					// Should we go back to the list page?
					scrollToTop()
					setSubmissionComment(null)
				} catch (e) {
					makeErrorToast(makeApiErrorMessage(e))
				}
			},
			onClose: () => setEditStringModalConfig(null),
		})
	}

	if (requestLoading) {
		return (
			<Card>
				<LoadingSpinner />
			</Card>
		)
	}

	if (!request) {
		return <Card>Could not load adjustment request form</Card>
	}

	const { canSubmit, canApprove, canResubmit, isRejected, isApproved, isCanceled } =
		priceAdjustmentPermissions(request)

	return (
		<>
			<Button
				icon="long-arrow-left"
				onClick={() => history.push("/service/price-adjustment-requests")}
				containerProps={{ mb: 3 }}
			>
				All request forms
			</Button>

			<Card heading="Pricing Adjustment Request Form" headingIcon="dollar-sign">
				<Box d="flex" flexDirection="column" rowGap={3}>
					<RequestDetailHeader request={request} />
					<RequestDetailCustomers request={request} />
					<RequestDetailFreight request={request} />

					<div>
						<HeadingDivider icon="check-circle" variant="h5">
							Submission
						</HeadingDivider>
						<Box pl={1.5}>
							{canSubmit && (
								<>
									<Paragraph>
										When you've added all the price adjustments that are
										needed, click below to submit your request for approval.
									</Paragraph>
									<Box my={4} maxWidth={50} m="auto">
										<Button
											variant="primary-cta"
											fillContainer
											onClick={handleSubmit}
										>
											Submit for approval
										</Button>
									</Box>
								</>
							)}

							{canApprove && (
								<>
									<Paragraph mb={1}>
										Click below when you've made your decision about this
										adjustment request, providing a comment if needed.
									</Paragraph>
									<ThrottledTextarea
										value={submissionComment}
										onChange={setSubmissionComment}
										label="Comments (optional)"
										maxLength={2000}
										rows={4}
										maxWidth="50%"
										mdProps={{ maxWidth: "none " }}
									/>
									<Box d="flex" maxWidth={60} m="auto" gap={2} my={4}>
										<Button
											fillContainer
											variant="primary-cta"
											icon="thumbs-up"
											onClick={handleApprove}
										>
											Approve
										</Button>
										<Button
											fillContainer
											variant="secondary-cta"
											icon="thumbs-down"
											onClick={handleReject}
										>
											Reject
										</Button>
										<Button
											fillContainer
											variant="secondary-cta"
											icon="info-circle"
											onClick={handleRequestMoreInfo}
										>
											Request more info
										</Button>
									</Box>
								</>
							)}

							{canResubmit && (
								<>
									<Paragraph mb={1}>
										When the needed information has been added to this
										request's Notes or in the comments below, please resubmit
										for approval.
									</Paragraph>
									<ThrottledTextarea
										value={submissionComment}
										onChange={setSubmissionComment}
										label="Comments (optional)"
										maxLength={2000}
										rows={4}
										maxWidth="50%"
										mdProps={{ maxWidth: "none " }}
									/>
									<Box mt={3} mx="auto" maxWidth={25}>
										<Button
											fillContainer
											variant="primary-cta"
											icon="check"
											onClick={handleSubmit}
										>
											Resubmit
										</Button>
									</Box>
								</>
							)}

							{isApproved && (
								<Paragraph>
									This pricing adjustment request has been approved and applied
									to its customers.
								</Paragraph>
							)}

							{isRejected && (
								<Paragraph>
									This pricing adjustment request has been rejected.
								</Paragraph>
							)}

							{isCanceled && (
								<Paragraph>
									This pricing adjustment request has been canceled.
								</Paragraph>
							)}
						</Box>
					</div>

					{canSubmit && (
						<div>
							<Button icon="trash-alt" onClick={handleCancel}>
								Cancel request
							</Button>
						</div>
					)}
				</Box>
			</Card>

			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
			{!!editStringModalConfig && <EditStringModal {...editStringModalConfig} />}
		</>
	)
}

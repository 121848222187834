import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import callApi from "@ncs/bricks/redux/services/callApi"
import { loadLookups, LOOKUPS } from "../../redux/services/lookups"

// custom components
import { Select } from "@ncs/bricks/components"

class ChemicalTechAssignmentSelector extends React.PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			chemTechAssignments: [],
			techAssignment: null,
		}
	}

	componentDidMount() {
		this.props.loadLookups([LOOKUPS.ChemicalTechAssignments], this.filterTechAssignments)
	}
	filterTechAssignments = () => {
		let chemTechAssignments = this.props.chemTechAssignments
		if (this.props.territory) {
			chemTechAssignments = chemTechAssignments.filter(
				(x) => x.territory_id === parseInt(this.props.territory)
			)
		}
		this.setState({ chemTechAssignments })
	}

	render() {
		const { displayEmpty, ...rest } = this.props
		return (
			<Select
				id="chemicalTechAssignments"
				labelText="Chemical Tech Assignments"
				value={this.props.techAssignment}
				onChange={this.props.onChange}
				options={[
					{
						_id: null,
						description: "Select a chemical tech assignment...",
						disabled: true,
					},
					...this.state.chemTechAssignments,
				]}
				valueAccessor="_id"
				textAccessor={(x) => x.description}
				{...rest}
			/>
		)
	}
}

ChemicalTechAssignmentSelector.defaultProps = {
	displayEmpty: true,
}

ChemicalTechAssignmentSelector.propTypes = {
	displayEmpty: PropTypes.bool,
}

const mapStateToProps = (state) => {
	return {
		chemTechAssignments: state.lookups.chemTechAssignments,
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			callApi,
			loadLookups,
		},
		dispatch
	)

export default connect(mapStateToProps, mapDispatchToProps)(ChemicalTechAssignmentSelector)

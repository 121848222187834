import { Dispatch, ReactElement, SetStateAction, useMemo } from "react"

import { Select } from "@ncs/web-legos"

interface ActiveOnlyQueryParam {
	active: true | null
}

export interface ActiveOnlyQueryFilterProps<QueryParamState extends ActiveOnlyQueryParam> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

/**
 * Can only send true to activate the filter, or nothing.
 */
export const ActiveOnlyQueryFilter = <QueryParamState extends ActiveOnlyQueryParam>({
	queryParamState,
	setQueryParamState,
}: ActiveOnlyQueryFilterProps<QueryParamState>): ReactElement => {
	const value = useMemo(() => {
		if (queryParamState.active === true) {
			return "true"
		}

		return null
	}, [queryParamState.active])

	const handleChange = (newValue: string | null) => {
		setQueryParamState((prev) => ({
			...prev,
			active: newValue === "true" ? true : null,
		}))
	}

	return (
		<Select
			value={value}
			onChange={handleChange}
			label="Active status"
			options={[
				{
					text: "Active only",
					value: "true",
				},
			]}
			fillContainer
			disableNoSelectionOption={false}
		/>
	)
}

import React, { HTMLAttributes, useMemo } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { makeStyles } from "@material-ui/core"

import styles from "../../assets/jss/material-dashboard-pro-react/components/cardHeaderStyle"
import { MaterialDashboardProColors } from "../../util/types"

const useStyles = makeStyles(styles)

export interface CardHeaderProps extends HTMLAttributes<HTMLDivElement> {
	className?: string
	color?: Exclude<MaterialDashboardProColors, "gray">
	plain?: boolean
	image?: boolean
	contact?: boolean
	signup?: boolean
	stats?: boolean
	icon?: boolean
	text?: boolean

	/** Optionally provide content that sits in the top right. */
	rightAlignedContent?: React.ReactNode
}

const CardHeader: React.FC<CardHeaderProps> = ({
	className,
	color,
	plain,
	image,
	contact,
	signup,
	stats,
	icon,
	text,
	rightAlignedContent,
	children,
	...rest
}) => {
	const classes = useStyles()

	const cardHeaderClasses = useMemo(
		() =>
			classNames(className, classes.cardHeader, {
				[classes[(color + "CardHeader") as keyof typeof classes]]: color,
				[classes.cardHeaderPlain]: plain,
				[classes.cardHeaderImage]: image,
				[classes.cardHeaderContact]: contact,
				[classes.cardHeaderSignup]: signup,
				[classes.cardHeaderStats]: stats,
				[classes.cardHeaderIcon]: icon,
				[classes.cardHeaderText]: text,
			}),
		[classes, className, color, contact, image, icon, plain, signup, stats, text]
	)

	return (
		<div className={cardHeaderClasses} {...rest}>
			{children}
			{!!rightAlignedContent && (
				<div className={classes.rightAlignedContent}>{rightAlignedContent}</div>
			)}
		</div>
	)
}

CardHeader.propTypes = {
	className: PropTypes.string,
	color: PropTypes.oneOf(["warning", "success", "danger", "info", "primary", "rose"]),
	plain: PropTypes.bool,
	image: PropTypes.bool,
	contact: PropTypes.bool,
	signup: PropTypes.bool,
	stats: PropTypes.bool,
	icon: PropTypes.bool,
	text: PropTypes.bool,
	children: PropTypes.node,
}

export default CardHeader

import { FC } from "react"

import { InventoryRequestStatus, useInventoryRequestStatuses } from "@ncs/ncs-api"
import { ExtendableSelectProps, Select } from "@ncs/web-legos"

export type InventoryRequestStatusSelectorProps = ExtendableSelectProps<InventoryRequestStatus>

export const InventoryRequestStatusSelector: FC<InventoryRequestStatusSelectorProps> = ({
	...rest
}) => {
	const [statuses, isLoading] = useInventoryRequestStatuses()

	return (
		<Select
			label="Status"
			{...rest}
			options={statuses ?? []}
			isLoading={isLoading}
			valueAccessor={(option) => option.id.toString()}
			textAccessor="description"
		/>
	)
}

import { FC, useState } from "react"

import { Column } from "react-table"

import {
	makeApiErrorMessage,
	ProductCatalog,
	ProductCatalogDetails,
	useProductCatalogDetails,
	useRemoveUserFromCatalog,
} from "@ncs/ncs-api"
import { extractNumber } from "@ncs/ts-utils"
import {
	Button,
	ConfirmationModal,
	ConfirmationModalConfig,
	Table,
	useToast,
} from "@ncs/web-legos"

import { AddUserToCatalogModal } from "./AddUserToCatalogModal"

export interface CatalogUsersTabProps {
	catalog: ProductCatalog
}

const columns: Column<ProductCatalogDetails["users"][number]>[] = [
	{
		Header: "Account username",
		accessor: "userName",
	},
]

export const CatalogUsersTab: FC<CatalogUsersTabProps> = ({ catalog }) => {
	const { makeSuccessToast, makeErrorToast } = useToast()
	const [showAddModal, setShowAddModal] = useState(false)
	const [details, detailsLoading] = useProductCatalogDetails(catalog.id.toString())
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)

	const removeUser = useRemoveUserFromCatalog()

	const onRemove = async (id: number) => {
		try {
			await removeUser({
				body: {
					catalogId: catalog.id,
					userId: extractNumber(id),
				},
			})
			makeSuccessToast("User removed from catalog")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		}
	}

	return (
		<>
			<Button
				variant="secondary-cta"
				icon="plus-circle"
				onClick={() => setShowAddModal(true)}
				containerProps={{ mb: 3 }}
			>
				Add user to catalog
			</Button>

			<Table
				data={details?.users ?? []}
				isLoading={detailsLoading}
				columns={columns}
				noDataText="No users assigned to catalog yet"
				rowMenu={[
					{
						label: "Remove",
						iconName: "trash",
						onClick: ({ original }) =>
							setConfirmationConfig({
								title: "Remove User",
								message: `Remove ${original.userName} from ${catalog.description}?`,
								onConfirm: () => onRemove(original.userId),
							}),
					},
				]}
			/>

			<AddUserToCatalogModal
				isOpen={showAddModal}
				onClose={() => setShowAddModal(false)}
				catalog={catalog}
			/>
			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</>
	)
}

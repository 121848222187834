import { FC, useEffect, useRef } from "react"

import { QuickOrder, useQuickOrders } from "@ncs/ncs-api"

import { ExtendableSearchableSelectProps, SearchableSelect } from "../inputs"

export interface QuickOrderSelectorProps extends ExtendableSearchableSelectProps<QuickOrder> {
	initialId?: string | null
}

export const QuickOrderSelector: FC<QuickOrderSelectorProps> = ({
	onChange,
	initialId,
	...rest
}) => {
	const [quickOrders, isLoading] = useQuickOrders()
	const hasSetInitial = useRef(false)

	useEffect(() => {
		if (hasSetInitial.current === false && !rest.value && !!initialId) {
			const initialOption = (quickOrders ?? []).find((o) => o.id.toString() === initialId)

			if (initialOption) {
				hasSetInitial.current = true
				onChange(initialOption)
			}
		}
	}, [quickOrders, initialId, onChange, rest.value])

	return (
		<SearchableSelect
			label="Quick order"
			{...rest}
			options={quickOrders ?? []}
			onItemSelect={onChange}
			getOptionLabel={(option) => option.description}
			isLoading={isLoading}
		/>
	)
}

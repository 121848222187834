import { FC, useState } from "react"

import {
	makeApiErrorMessage,
	ProductCatalog,
	useCreateProductCatalog,
	useUpdateProductCatalog,
} from "@ncs/ncs-api"
import { unpythonify } from "@ncs/ts-utils"
import { ExtendableModalProps, Modal, SetUrlState, TextInput } from "@ncs/web-legos"

import { CatalogsUrlState, CatalogTabs } from "./Catalogs"

export interface EditCatalogModalProps extends ExtendableModalProps {
	catalog: ProductCatalog | null
	isNew: boolean
	setUrlState: SetUrlState<CatalogsUrlState>
}

export const EditCatalogModal: FC<EditCatalogModalProps> = ({
	catalog,
	isNew,
	setUrlState,
	...rest
}) => {
	const [name, setName] = useState<string | null>(null)
	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const create = useCreateProductCatalog()
	const update = useUpdateProductCatalog()

	const onSave = async () => {
		if (!name) return

		try {
			setIsSaving(true)
			if (isNew) {
				const response = await create({
					description: name,
				})
				const { catalogId } = unpythonify(response.data)
				setUrlState({ catalogId, tab: CatalogTabs.CatalogProducts })
			} else if (catalog) {
				await update({
					updates: {
						catalogId: catalog.id,
						description: name,
					},
				})
			}
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const reset = () => {
		setName(isNew ? null : catalog?.description ?? null)
		setIsSaving(false)
		setErrorText(null)
	}

	return (
		<Modal
			title={isNew ? "Create New Catalog" : "Edit Catalog"}
			{...rest}
			onOpen={reset}
			rightButtons={{
				buttonText: isNew ? "Create" : "Update",
				onClick: onSave,
				disabled: !name,
				isLoading: isSaving,
			}}
			errorText={errorText}
		>
			<TextInput
				value={name}
				onChange={setName}
				label="Catalog name"
				maxLength={255}
				autoFocus
			/>
		</Modal>
	)
}

import { FC, Fragment, useMemo, useState } from "react"

import dayjs from "dayjs"

import { LaborPricingDetails, useDeleteLaborPricingRate } from "@ncs/ncs-api"
import { displayDate, formatCurrency, formatDate, formatDateTime } from "@ncs/ts-utils"
import {
	Box,
	Button,
	ConfirmationModal,
	ConfirmationModalConfig,
	ContextMenu,
	CssGridTable,
	EmptyValueDash,
	Icon,
	Paragraph,
	Tooltip,
	useToast,
} from "@ncs/web-legos"

import { EditPriceScheduleModal } from "./EditPriceScheduleModal"
import { NewPriceChangeModal } from "./NewPriceChangeModal"

export interface LaborPricingPriceScheduleProps {
	pricing: LaborPricingDetails
	currentRate: PriceHistoryItem | null
}

type PriceHistoryItem = LaborPricingDetails["priceHistory"][number]

export const LaborPricingPriceSchedule: FC<LaborPricingPriceScheduleProps> = ({
	pricing,
	currentRate,
}) => {
	const { makeSuccessToast } = useToast()
	const [showNewPriceModal, setShowNewPriceModal] = useState(false)
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)
	const [priceToEdit, setPriceToEdit] = useState<PriceHistoryItem | null>(null)

	const deleteRate = useDeleteLaborPricingRate()

	const handleDeleteRate = (rateId: string) => {
		setConfirmationConfig({
			title: "Delete Future Rate Change",
			message: "Confirm: Delete this rate change?",
			onConfirm: async () => {
				await deleteRate(rateId)
				makeSuccessToast("Rate deleted")
			},
		})
	}

	const [priceLastModifiedBy, priceLastModifiedDate] = useMemo(() => {
		if (!pricing?.priceHistory.length) return [null, null]

		const mostRecent = pricing.priceHistory.sort((a, b) =>
			dayjs(a.createdDate).unix() > dayjs(b.createdDate).unix() ? -1 : 1
		)[0]

		return [mostRecent.createdBy, mostRecent.createdDate]
	}, [pricing?.priceHistory])

	return (
		<>
			<Box mb={3}>
				{!!priceLastModifiedDate && !!priceLastModifiedBy && (
					<Paragraph small secondary>
						Last price edit {formatDateTime(priceLastModifiedDate)} by{" "}
						{priceLastModifiedBy}
					</Paragraph>
				)}
				<Button
					icon="plus"
					variant="secondary-cta"
					onClick={() => setShowNewPriceModal(true)}
					containerProps={{ mt: 1.5 }}
				>
					Add price change
				</Button>
			</Box>

			{pricing.priceHistory.length ?
				<CssGridTable
					inline
					mb={2}
					rowGap={0.35}
					columnGap={2}
					gridTemplateColumns="auto auto auto"
					headers={["Rate", "Start", "End"]}
					cells={pricing.priceHistory
						.sort((a, b) => {
							return dayjs(a.startsOn).unix() > dayjs(b.startsOn).unix() ? -1 : 1
						})
						.map((rate) => {
							const isCurrentRate = rate.id === currentRate?.id
							const isFutureRate = dayjs(rate.startsOn).isAfter(dayjs(), "day")

							return (
								<Fragment key={rate.id}>
									<Paragraph secondary={!isCurrentRate}>
										{formatCurrency(rate.price)}
									</Paragraph>
									<Box d="flex" alignItems="center" gap={0.5} height={1}>
										{dayjs(rate.endsAfter).isBefore(rate.startsOn) && (
											<Tooltip title="Date range is invalid because end date comes before start date.">
												<Icon
													icon="exclamation-triangle"
													color="error"
													family="solid"
												/>
											</Tooltip>
										)}
										<Paragraph secondary={!isCurrentRate}>
											{formatDate(rate.startsOn, { formatInUtc: true })}
										</Paragraph>
									</Box>
									<Box d="flex" alignItems="center" gap={0.5} height={1}>
										{dayjs(rate.endsAfter).isBefore(rate.startsOn) && (
											<Tooltip title="Date range is invalid because end date comes before start date.">
												<Icon
													icon="exclamation-triangle"
													color="error"
													family="solid"
												/>
											</Tooltip>
										)}
										<Paragraph secondary={!isCurrentRate}>
											{!rate.endsAfter && !isCurrentRate && (
												<EmptyValueDash />
											)}
											{displayDate(rate.endsAfter, "", {
												formatInUtc: true,
											})}
											{isCurrentRate && <strong> (current)</strong>}
										</Paragraph>
										{(isFutureRate || isCurrentRate) && (
											<ContextMenu
												icon="pencil"
												menuItems={[
													{
														label: "Edit dates",
														iconName: "pencil",
														onClick: () => setPriceToEdit(rate),
													},
													{
														label: "Delete rate",
														iconName: "trash-alt",
														onClick: () => handleDeleteRate(rate.id),
													},
												]}
											/>
										)}
									</Box>
								</Fragment>
							)
						})}
				/>
			:	<Paragraph small secondary mt={-1} mb={3}>
					No prices added yet
				</Paragraph>
			}

			{showNewPriceModal && (
				<NewPriceChangeModal
					laborPricing={pricing}
					onClose={() => setShowNewPriceModal(false)}
				/>
			)}
			{!!priceToEdit && (
				<EditPriceScheduleModal
					pricing={pricing}
					priceHistoryItem={priceToEdit}
					isCurrent={priceToEdit.id === currentRate?.id}
					onClose={() => setPriceToEdit(null)}
				/>
			)}
			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</>
	)
}

import { FC, useMemo } from "react"

import { PartInBin, usePartBins } from "@ncs/ncs-api"
import { ErrorText, ExtendableSearchableSelectProps, SearchableSelect } from "@ncs/web-legos"

export interface PartBinSelectorProps extends ExtendableSearchableSelectProps<PartInBin> {
	partId: string | null | undefined
	hideEmptyBins?: boolean
	showNoBinsMessage?: boolean
}

export const PartBinSelector: FC<PartBinSelectorProps> = ({
	partId,
	hideEmptyBins,
	showNoBinsMessage,
	...rest
}) => {
	const [bins, binsLoading] = usePartBins(partId)

	const options = useMemo(() => {
		return (bins ?? []).filter((bin) => {
			return !hideEmptyBins || bin.qty > 0
		})
	}, [bins, hideEmptyBins])

	return (
		<>
			<SearchableSelect
				label="Bin"
				disableOptionsAggregation
				getOptionLabel={(option) => option.text}
				useSearchIcon
				{...rest}
				isLoading={binsLoading}
				options={options}
				onItemSelect={(option) => rest.onChange(option)}
				uniqueIdParam={(option) => option.binId}
			/>
			{showNoBinsMessage && !binsLoading && !options.length && !!partId && (
				<ErrorText>No available stock found for this part</ErrorText>
			)}
		</>
	)
}

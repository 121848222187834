import { FC, useState } from "react"

import { makeApiErrorMessage, useCreateSalesAssignment, UserMinimal } from "@ncs/ncs-api"
import {
	ExtendableModalProps,
	Modal,
	SalesRoleSelector,
	SalesTypeSelector,
	UserSelector,
	useToast,
} from "@ncs/web-legos"

export interface AddSalesAssignmentModalProps extends ExtendableModalProps {
	customerId: string
}

export const AddSalesAssignmentModal: FC<AddSalesAssignmentModalProps> = ({
	customerId,
	...rest
}) => {
	const { makeSuccessToast } = useToast()
	const [user, setUser] = useState<UserMinimal | null>(null)
	const [roleId, setRoleId] = useState<string | null>(null)
	const [typeId, setTypeId] = useState<string | null>(null)
	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const createAssignment = useCreateSalesAssignment()

	const handleSave = async () => {
		try {
			if (!user) throw new Error("No user selected")
			if (!roleId) throw new Error("No role selected")
			if (!typeId) throw new Error("No type selected")

			setIsSaving(true)
			await createAssignment({
				user: user.id.toString(),
				customer: customerId,
				salesRole: roleId,
				salesType: typeId,
			})
			makeSuccessToast("Sales assignment added to customer")
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const reset = () => {
		setUser(null)
		setRoleId(null)
		setTypeId(null)
		setIsSaving(false)
		setErrorText(null)
	}

	return (
		<Modal
			{...rest}
			onOpen={reset}
			title="Add Sales Assignment"
			errorText={errorText}
			rightButtons={{
				buttonText: "Assign To Customer",
				onClick: handleSave,
				isLoading: isSaving,
				disabled: !roleId || !typeId || !user,
			}}
		>
			<UserSelector
				value={user}
				onChange={setUser}
				fillContainer
				label="Person"
				employeesOnly
			/>
			<SalesTypeSelector value={typeId} onChange={setTypeId} fillContainer />
			<SalesRoleSelector value={roleId} onChange={setRoleId} fillContainer />
		</Modal>
	)
}

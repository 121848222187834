import React from "react"
import PropTypes from "prop-types"

import TextFormField from "./TextFormField"

class CustomEditorFormField extends React.PureComponent {
	render = () => {
		const { isEditing, editorComponent, readOnlyComponent } = this.props

		let Component =
			isEditing ? editorComponent ?? TextFormField : readOnlyComponent ?? TextFormField
		return <Component {...this.props} />
	}
}

CustomEditorFormField.propTypes = {
	/** true if in editing mode, false if in read-only mode. defaults to false */
	isEditing: PropTypes.bool,

	/** component to use in editor mode, uses TextFormField if undefined */
	editorComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),

	/** component to use in read-only mode, uses TextFormField if undefined */
	readOnlyComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
}

export default CustomEditorFormField

import { z } from "zod"

import {
	AddErpLineItemPost,
	CreateErpPost,
	ErpLineItem,
	GenericLineItem,
	GenericLineItemWithBasePrice,
	GenericLineItemWithId,
	idIsLineItemTypeId,
} from "@ncs/ncs-api"
import { extractNumber } from "@ncs/ts-utils"
import { FileType } from "@ncs/web-legos"

export const CreateQuoteOrderFormSchema = z.object({
	shipToId: z.string().min(1, "Required"),
	billToId: z.string().min(1, "Required"),
	purchaseOrder: z.string().nullable(),
	comment: z.string().nullable(),
})

export type CreateQuoteOrderForm = z.infer<typeof CreateQuoteOrderFormSchema>

export const defaultCreateQuoteOrderFormValues: Partial<CreateQuoteOrderForm> = {
	shipToId: "",
	billToId: "",
	purchaseOrder: null,
	comment: null,
}

export const quoteFormToErpPost = async (
	form: CreateQuoteOrderForm,
	lineItems: GenericLineItemWithBasePrice[]
): Promise<CreateErpPost> => {
	const erpLineItems: CreateErpPost["erpLineItem"] = lineItems.map(
		(lineItem): CreateErpPost["erpLineItem"][number] => {
			const subtotal = lineItem.finalPrice * lineItem.quantity
			const tax = lineItem.taxRate * lineItem.finalPrice * lineItem.quantity
			const total = subtotal + tax

			return {
				lineItemTypeId: extractNumber(lineItem.lineTypeId),
				partId: lineItem.part?.id ?? null,
				description: lineItem.description,
				netPrice: lineItem.finalPrice.toFixed(2),
				unitPrice: lineItem.basePrice.toFixed(2),
				subTotal: subtotal.toFixed(2),
				tax: tax.toFixed(2),
				total: total.toFixed(2),
				quantity: lineItem.quantity.toString(), // This should probably be a number...?
				reasonId: lineItem.priceOverrideReason?.id.toString() ?? null,
			}
		}
	)

	const result: CreateErpPost = {
		comment: form.comment,
		customerId: form.shipToId,
		customerPurchaseOrder: form.purchaseOrder,
		billToCustomerId: form.billToId,
		erpLineItem: erpLineItems,
		erpStatusId: 1, // Hardcode to 1 in this case.
	}

	return result
}

export const lineItemToErpLineItemPost = (
	lineItem: GenericLineItemWithBasePrice,
	erpId: string
): AddErpLineItemPost => {
	const tax = lineItem.taxRate * lineItem.basePrice * lineItem.quantity

	return {
		id: erpId,
		description: lineItem.description,
		lineItemTypeId: extractNumber(lineItem.lineTypeId),
		netPrice: lineItem.finalPrice.toFixed(2),
		partId: lineItem.part?.id ?? null,
		quantity: lineItem.quantity,
		subTotal: lineItem.subtotal.toFixed(2),
		tax: tax.toFixed(2),
		total: (tax + lineItem.quantity * lineItem.finalPrice).toFixed(2),
		unitPrice: lineItem.basePrice.toFixed(2),
		reasonId: lineItem.priceOverrideReason?.id.toString() ?? null,
	}
}

export const lineItemToErpLineItemPatch = lineItemToErpLineItemPost

export const erpLineItemToGenericLineItem = (lineItem: ErpLineItem): GenericLineItemWithId => {
	const lineTypeId = lineItem.lineItemTypeId.toString()
	if (!idIsLineItemTypeId(lineTypeId)) {
		throw new Error(`Unrecognized line item type ID: ${lineTypeId}`)
	}

	let part: GenericLineItem["part"] = null
	if (lineItem.partId && lineItem.partNumber && lineItem.partDescription) {
		part = {
			id: lineItem.partId,
			partNumber: lineItem.partNumber,
			partFamily: lineItem.partFamily,
			description: lineItem.description,
			weight: lineItem.partWeight,
		}
	}

	const quantity = extractNumber(lineItem.quantity)
	const finalPrice = extractNumber(lineItem.netPrice)
	const subtotal = extractNumber(lineItem.subTotal)
	const tax = extractNumber(lineItem.tax)

	return {
		id: lineItem.id,
		lineTypeId: lineTypeId,
		part,
		description: lineItem.description,
		quantity,
		finalPrice,
		basePrice: null,
		taxRate: tax !== 0 ? tax / subtotal : 0,
		subtotal,
		priceOverrideReason: lineItem.reason,
		reasonComment: null,
		overridePermission: null,
		requestedPrice: null,
		systemGeneratedLine: false,
		originalSystemGeneratedPrice: null,
	}
}

export const erpAttachmentFileTypes = [
	FileType.Excel,
	FileType.Images,
	FileType.Pdf,
	FileType.Text,
	FileType.Word,
]

import { FC, useEffect, useMemo, useState } from "react"

import uniqBy from "lodash/uniqBy"

import { EqpModel, EquipmentVintageLookup, useEquipmentVintagesLookup } from "@ncs/ncs-api"
import { AnimatedEntrance, ExtendableModalProps, Modal, Paragraph, Select } from "@ncs/web-legos"

export interface AddVintageToPartModalProps extends ExtendableModalProps {
	onSave: (vintageId: string) => void
}

export const AddVintageToPartModal: FC<AddVintageToPartModalProps> = ({ onSave, ...rest }) => {
	const [vintages] = useEquipmentVintagesLookup()

	const manufacturers = useMemo(() => {
		return uniqBy(
			(vintages ?? []).flatMap((vintage) =>
				vintage.model?.manufacturer ? [vintage.model.manufacturer] : []
			),
			"id"
		).sort((a, b) => (a.name > b.name ? 1 : -1))
	}, [vintages])

	const [selectedManufacturer, setSelectedManufacturer] = useState<
		EqpModel["manufacturer"] | null
	>(null)

	const filteredModels = useMemo(() => {
		return uniqBy(
			(vintages ?? [])
				.flatMap((vintage) => (vintage.model ? [vintage.model] : []))
				.filter(
					(model) =>
						model.manufacturer && model.manufacturer.id === selectedManufacturer?.id
				),
			"id"
		).sort((a, b) => (a.modelName > b.modelName ? 1 : -1))
	}, [selectedManufacturer?.id, vintages])

	const [selectedModel, setSelectedModel] = useState<EqpModel | null>(null)

	const filteredVintages = useMemo(() => {
		return (vintages ?? [])
			.flatMap((vintage) => (vintage.model?.id === selectedModel?.id ? [vintage] : []))
			.sort((a, b) => ((a?.name ?? "") > (b?.name ?? "") ? 1 : -1))
	}, [selectedModel?.id, vintages])

	const [selectedVintage, setSelectedVintage] = useState<EquipmentVintageLookup | null>(null)

	// De-select things when selections change.
	useEffect(() => {
		if (selectedManufacturer) {
			if (
				selectedModel != null &&
				selectedModel?.manufacturer?.id !== selectedManufacturer.id
			) {
				setSelectedModel(null)
			}
			if (
				selectedVintage != null &&
				selectedVintage?.model?.manufacturer?.id !== selectedManufacturer.id
			) {
				setSelectedVintage(null)
			}
		}
	}, [selectedManufacturer, selectedModel, selectedVintage])
	useEffect(() => {
		if (selectedModel) {
			if (selectedVintage != null && selectedVintage.model?.id !== selectedModel.id) {
				setSelectedVintage(null)
			}
		}
	}, [selectedModel, selectedVintage])

	// Auto-select things when we can.
	useEffect(() => {
		if (selectedManufacturer?.id) {
			if (!selectedModel?.id && filteredModels.length === 1) {
				setSelectedModel(filteredModels[0])
			}
		}
	}, [filteredModels, selectedManufacturer?.id, selectedModel?.id])
	useEffect(() => {
		if (selectedModel?.id) {
			if (!selectedVintage?.id && filteredVintages.length === 1) {
				setSelectedVintage(filteredVintages[0])
			}
		}
	}, [filteredVintages, selectedModel?.id, selectedVintage?.id])

	const reset = () => {
		setSelectedManufacturer(null)
		setSelectedModel(null)
		setSelectedVintage(null)
	}

	const handleSave = () => {
		if (selectedVintage?.id) {
			onSave(selectedVintage.id)
			rest.onClose()
		}
	}

	return (
		<Modal
			title="Add Equipment Type"
			{...rest}
			onOpen={reset}
			rightButtons={{
				buttonText: "Add Equipment Type",
				onClick: handleSave,
				disabled: !selectedVintage || !selectedModel || !selectedManufacturer,
			}}
		>
			<Paragraph mb={1}>
				Search for a manufacturer, model, and vintage to add to this product.
			</Paragraph>
			<Select
				label="Manufacturer"
				value={selectedManufacturer?.id ?? null}
				onChange={(value, option) => setSelectedManufacturer(option ?? null)}
				options={manufacturers}
				valueAccessor="id"
				textAccessor="name"
				fillContainer
			/>
			<AnimatedEntrance show={filteredModels.length > 0}>
				<Select
					label={`Models for ${selectedManufacturer?.name}`}
					value={selectedModel?.id ?? null}
					onChange={(value, option) => setSelectedModel(option ?? null)}
					options={filteredModels}
					valueAccessor="id"
					textAccessor="modelName"
					fillContainer
				/>
			</AnimatedEntrance>
			<AnimatedEntrance show={filteredVintages.length > 0}>
				<Select
					label={`Vintages for ${selectedModel?.modelName}`}
					value={selectedVintage?.id ?? null}
					onChange={(value, option) => setSelectedVintage(option ?? null)}
					options={filteredVintages}
					valueAccessor="id"
					textAccessor="name"
					fillContainer
				/>
			</AnimatedEntrance>
		</Modal>
	)
}

import { FC, useEffect, useState } from "react"

import { makeApiErrorMessage, UserMinimal } from "@ncs/ncs-api"
import {
	ExtendableModalProps,
	Modal,
	useIsSaving,
	UserSelector,
	UserSelectorProps,
} from "@ncs/web-legos"

export interface UserSelectorModalProps extends ExtendableModalProps {
	onSave: (user: UserMinimal | null) => void | Promise<void>
	allowNull?: boolean
	initialId?: UserSelectorProps["initialId"]
	employeesOnly?: UserSelectorProps["employeesOnly"]
	selectorProps?: Partial<UserSelectorProps>
}

export const UserSelectorModal: FC<UserSelectorModalProps> = ({
	onSave,
	allowNull = false,
	initialId,
	employeesOnly,
	selectorProps,
	...rest
}) => {
	const [user, setUser] = useState<UserMinimal | null>(null)
	const [errorText, setErrorText] = useState<string | null>(null)
	const { isSaving, setSaving, endSaving } = useIsSaving()

	const handleSave = async () => {
		if (allowNull === false && !user) {
			setErrorText("Please select a user")
			return
		}

		try {
			setSaving()
			await onSave(user)
			rest.onClose()
		} catch (e) {
			endSaving()
			setErrorText(makeApiErrorMessage(e))
		}
	}

	useEffect(() => {
		setErrorText(null)
	}, [user?.id])

	return (
		<Modal
			title="Select User"
			{...rest}
			errorText={errorText}
			rightButtons={{
				buttonText: "Save",
				onClick: handleSave,
				isLoading: isSaving(),
			}}
		>
			<UserSelector
				employeesOnly={employeesOnly}
				initialId={initialId}
				{...selectorProps}
				value={user}
				onChange={setUser}
			/>
		</Modal>
	)
}

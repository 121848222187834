import { FC } from "react"

import { css } from "@emotion/react"

import { SchedulerDashboardData } from "@ncs/ncs-api"
import { titleCase } from "@ncs/ts-utils"
import {
	Box,
	Callout,
	Card,
	encodeUrlState,
	FormattedNumber,
	GridContainer,
	GridItem,
	Heading,
	Label,
	Link,
} from "@ncs/web-legos"

import { PartOrdersViewOrdersTabUrlState } from "~/views/PartOrders/PartOrders/components"

import { LineItemsDashboardConfig } from "../LineItemsDashboard"

export interface LineItemsWarehouseCardProps {
	data: SchedulerDashboardData[number]
	config: LineItemsDashboardConfig
}

export const LineItemsWarehouseCard: FC<LineItemsWarehouseCardProps> = ({ data, config }) => {
	return (
		<Card id={data.warehouseName}>
			<Heading opacity={0.75} mb={2}>
				({data.warehouseCode}) {data.warehouseName}
			</Heading>
			{Object.entries(data.unscheduledOrders).map(([orderType, { orders, lines }]) => {
				if (!config[orderType as keyof LineItemsDashboardConfig]) return null

				return (
					<Box key={orderType} mb={2}>
						<Box d="flex" mb={0.5} position="relative">
							<Link
								to={`/part-orders${encodeUrlState<PartOrdersViewOrdersTabUrlState>(
									{
										dashboardActionableItems: true,
										lineType: orderType,
										orderLineStatus: "unassigned",
										warehouses: [data.warehouse],
									}
								)}`}
								underline
								fontSize={1.1}
								css={headingLinkCss}
							>
								{titleCase(orderType)}
							</Link>
							<div css={headingLinkLineCss} />
						</Box>
						<Box display="flex">
							<Box flex={1}>
								<Label>
									<strong>{titleCase(orderType)}</strong> line items
								</Label>
								<Heading variant="h1-banner">
									<FormattedNumber value={lines} />
								</Heading>
							</Box>
							<Box flex={1}>
								<Label>
									Orders with <strong>{orderType}</strong>
								</Label>
								<Heading variant="h1-banner">
									<FormattedNumber value={orders} />
								</Heading>
							</Box>
						</Box>
					</Box>
				)
			})}

			<Callout variant="info" fillContainer noBorder mt={2}>
				<GridContainer>
					<GridItem sm={12} md={6}>
						<Heading variant="h5" icon="person-dolly" mb={0.5} opacity={1}>
							Current Pick Lists
						</Heading>
						<Label>Line items assigned</Label>
						<Heading variant="h3">
							<FormattedNumber value={data.currentPicking.lines} />
						</Heading>
					</GridItem>
					<GridItem sm={12} md={6}>
						<Heading variant="h5" icon="history" mb={0.5} opacity={1}>
							DC Average, Last 30 Days
						</Heading>
						<Label>Lines picked per day</Label>
						<Heading variant="h3">
							<FormattedNumber value={data.historicalTrends.averageLines} />
						</Heading>
					</GridItem>
				</GridContainer>
			</Callout>

			<Box textAlign="right">
				<Link
					icon="long-arrow-right"
					to={`/part-orders${encodeUrlState<PartOrdersViewOrdersTabUrlState>({
						warehouses: [data.warehouse],
					})}`}
					underline
				>
					View all {data.warehouseName} orders
				</Link>
			</Box>
		</Card>
	)
}

const headingLinkCss = css`
	background: white;
	padding-right: 0.5rem;
	z-index: 1;
`
const headingLinkLineCss = css`
	position: absolute;
	border-top: 1px solid #eee;
	top: 10px;
	left: 0;
	right: 0;
	z-index: 0;
`

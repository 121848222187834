import { FC, useState } from "react"

import { css } from "@emotion/react"
import capitalize from "lodash/capitalize"

import {
	InventoryPart,
	makeApiErrorMessage,
	ReplacementSuccess,
	useReplacePart,
} from "@ncs/ncs-api"
import { camelToSnake, displayNumber, unpythonify } from "@ncs/ts-utils"
import {
	ConfirmationModal,
	ConfirmationModalConfig,
	DescriptionList,
	ExtendableModalProps,
	Heading,
	Label,
	Modal,
	Paragraph,
	PartSelector,
} from "@ncs/web-legos"

export interface ReplacePartModalProps extends ExtendableModalProps {
	oldPart: InventoryPart
}

export const ReplacementPartModal: FC<ReplacePartModalProps> = ({ oldPart, ...rest }) => {
	const [newPart, setNewPart] = useState<InventoryPart | null>(null)
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)
	const [successData, setSuccessData] = useState<ReplacementSuccess | null>(null)
	const [errorText, setErrorText] = useState<string | null>(null)

	const replacePart = useReplacePart()

	return (
		<>
			<Modal
				{...rest}
				title="Replace Part"
				errorText={errorText}
				rightButtons={{
					buttonText: "Replace Part",
					disabled: !newPart?.id,
					onClick: () =>
						setConfirmationConfig({
							title: "Replace This Part",
							message: (
								<>
									<span css={confirmMessageSpanCss}>
										Please take a moment to confirm: Are you sure you want to
										replace
									</span>
									<span css={confirmMessageSpanCss}>
										<strong>
											({oldPart.partNumber}) {oldPart.description}
										</strong>
									</span>
									<span css={confirmMessageSpanCss}>with</span>
									<span css={confirmMessageSpanCss}>
										<strong>
											({newPart?.partNumber}) {newPart?.description}
										</strong>
										?
									</span>

									<span css={confirmMessageSpanCss}>
										There is no way to undo this operation!
									</span>
								</>
							),
							onConfirm: async () => {
								if (newPart) {
									try {
										const { data } = await replacePart({
											oldPart: oldPart.id,
											newPart: newPart.id,
										})
										setSuccessData(unpythonify(data))
									} catch (e) {
										setErrorText(makeApiErrorMessage(e))
									}
								}
							},
						}),
				}}
			>
				<Label>Old part</Label>
				<Heading mb={2}>
					<strong>
						({oldPart.partNumber}) {oldPart.description}
					</strong>
				</Heading>

				<Paragraph mb={2}>
					This will replace all references to this part in open orders, operations, and
					pricing with your selected new part.
				</Paragraph>

				<PartSelector
					value={newPart}
					onChange={setNewPart}
					label="New part"
					skipRestrictedCheck
					includeNonService
				/>
			</Modal>

			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />

			{!!successData && (
				<Modal
					onClose={() => {
						// We can just close the parent modal to close it all.
						rest.onClose()
					}}
					closeButtonText="CLOSE"
					rightButtons={{
						buttonText: "DONE",
						icon: "check",
						onClick: () => rest.onClose(),
					}}
					title="Success!"
				>
					The following changes occurred:
					<DescriptionList
						items={Object.entries(successData).map(([type, count]) => [
							capitalize(camelToSnake(type).split("_").join(" ")),
							displayNumber(count),
						])}
					/>
				</Modal>
			)}
		</>
	)
}

const confirmMessageSpanCss = css`
	display: block;
	margin-bottom: 0.5rem;
`

import React from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import callApi from "@ncs/bricks/redux/services/callApi"
import { BaseSelectFilter, withDynamicTableFilter } from "@ncs/bricks/components"
import { loadLookups, LOOKUPS } from "../../redux/services/lookups"

class PurchaseOrderStatusFilter extends React.PureComponent {
	constructor(props) {
		super(props)
		this.state = {}
	}

	componentDidMount() {
		this.props.loadLookups([LOOKUPS.PurchaseOrderStatuses])
	}

	render() {
		return (
			<BaseSelectFilter
				id="purchaseOrderStatuses"
				labelText="Status"
				options={[{ id: "", description: "All Statuses" }, ...this.props.statuses]}
				valueAccessor={(x) => (x.id || "").toString()}
				textAccessor="description"
				{...this.props}
			/>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		statuses: state.lookups.purchaseOrderStatuses,
	}
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ callApi, loadLookups }, dispatch)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withDynamicTableFilter("purchase_order_status")(PurchaseOrderStatusFilter))

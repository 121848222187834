import { FC, Fragment, useMemo, useState } from "react"

import { Column } from "react-table"

import {
	APPLICATION,
	ContractRecipient,
	SentContractNotification,
	useContractRecipients,
	useCreateContractRecipients,
	useDeleteContractRecipient,
	useSentContractNotifications,
	useUserCanUse,
} from "@ncs/ncs-api"
import { formatDateTime } from "@ncs/ts-utils"
import {
	Box,
	Button,
	ConfirmationModal,
	ConfirmationModalConfig,
	GridContainer,
	GridItem,
	Heading,
	HeadingDivider,
	IconButton,
	LoadingSpinner,
	Paragraph,
	Table,
} from "@ncs/web-legos"

import { AddExternalRecipientModal } from "../AddExternalRecipientModal"
import { AddInternalRecipientModal } from "../AddInternalRecipientModal"
import { ContractFormRecipient } from "../contract-utils"

export interface ContractDetailNotificationsTabProps {
	contractId: string
}

const columns: Column<SentContractNotification>[] = [
	{
		Header: "Notification",
		accessor: (original) => original.messageDescription,
	},
	{
		Header: "Recipients",
		accessor: (original) => original.recipients.map((r) => `${r.name}: ${r.email}`).join(", "),
	},
	{
		Header: "Date",
		accessor: (original) => formatDateTime(original.dateSent),
	},
]

export const ContractDetailNotificationsTab: FC<ContractDetailNotificationsTabProps> = ({
	contractId,
}) => {
	const canEdit = useUserCanUse(APPLICATION.ContractCreator)
	const [showAddInternal, setShowAddInternal] = useState(false)
	const [showAddExternal, setShowAddExternal] = useState(false)
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)

	const [recipients, recipientsLoading] = useContractRecipients(contractId)
	const [sentMessages, sentMessagesLoading] = useSentContractNotifications(contractId)
	const createRecipient = useCreateContractRecipients()
	const deleteRecipient = useDeleteContractRecipient()

	const handleCreateRecipient = async (user: ContractFormRecipient) => {
		// The modals will do the error catching.
		await createRecipient({
			contract: contractId,
			recipients: [
				{
					name: user.name,
					email: user.email,
					description: user.description,
					user: user.userId,
				},
			],
		})
	}

	const handleDeleteRecipient = async (recipientId: string) => {
		await deleteRecipient({
			body: {
				contract: contractId,
				recipient: recipientId,
			},
		})
	}

	const getRecipientRow = (r: ContractRecipient) => {
		return (
			<Box display="flex" alignItems="center" columnGap={0.5} mb={0.5}>
				<Paragraph>{getRecipientString(r)}</Paragraph>
				<IconButton
					icon="trash-alt"
					color="gray"
					size="sm"
					onClick={() =>
						setConfirmationConfig({
							title: "Delete Recipient",
							message: (
								<>
									Delete <strong>{r.name ?? r.user?.name}</strong> from this
									contract? This will remove them from all future notifications
									and hide them when displaying sent messages.
								</>
							),
							onConfirm: () => void handleDeleteRecipient(r.id),
						})
					}
				/>
			</Box>
		)
	}

	const getRecipientString = (r: ContractRecipient): string => {
		return `${r.name ?? r.user?.name}${r.description ? `, ${r.description}` : ""}: ${
			r.email ?? r.user?.email
		}`
	}

	const internals = useMemo(() => {
		return (recipients ?? []).filter((r) => !!r.user)
	}, [recipients])

	const externals = useMemo(() => {
		return (recipients ?? []).filter((r) => !r.user)
	}, [recipients])

	return (
		<>
			<HeadingDivider headingVariant="h4" icon="paper-plane" bold>
				Sent Messages
			</HeadingDivider>
			{sentMessagesLoading && <LoadingSpinner />}
			<Box pl={1.5} mb={4}>
				{!sentMessagesLoading &&
					(!sentMessages?.length ?
						<Paragraph small color="secondary">
							No messages sent yet
						</Paragraph>
					:	<Table
							data={sentMessages}
							columns={columns}
							disableAllSorting
							infiniteRowsIncrement={5}
						/>)}
			</Box>

			{canEdit && (
				<>
					<HeadingDivider headingVariant="h4" icon="cog" bold>
						Configure Message Recipients
					</HeadingDivider>
					{recipientsLoading && <LoadingSpinner />}

					<Box pl={1.5}>
						<GridContainer>
							<GridItem xs={12} md={6}>
								<Heading variant="h6" mb={0.5}>
									Internal Recipients
								</Heading>
								{internals.length === 0 && (
									<Paragraph small color="secondary" mb={0.65}>
										No internal recipients yet
									</Paragraph>
								)}
								{internals.map((internal) => (
									<Paragraph key={internal.id}>{internal.name}</Paragraph>
								))}
								{internals.map((internal) => (
									<Fragment key={internal.id}>
										{getRecipientRow(internal)}
									</Fragment>
								))}

								<Button
									icon="plus"
									onClick={() => setShowAddInternal(true)}
									containerProps={{ mt: 0.5, ml: -0.3 }}
								>
									Add internal recipient
								</Button>
							</GridItem>

							<GridItem xs={12} md={6}>
								<Heading variant="h6" mb={0.5}>
									External Recipients
								</Heading>
								{externals.length === 0 && (
									<Paragraph small color="secondary">
										No external recipients yet
									</Paragraph>
								)}
								{externals.map((external) => (
									<Fragment key={external.id}>
										{getRecipientRow(external)}
									</Fragment>
								))}

								<Button
									icon="plus"
									onClick={() => setShowAddExternal(true)}
									containerProps={{ mt: 0.5, ml: -0.3 }}
								>
									Add external recipient
								</Button>
							</GridItem>
						</GridContainer>
					</Box>
				</>
			)}

			{!!showAddExternal && (
				<AddExternalRecipientModal
					isOpen
					onClose={() => setShowAddExternal(false)}
					onSave={(user) => handleCreateRecipient(user)}
				/>
			)}
			{!!showAddInternal && (
				<AddInternalRecipientModal
					isOpen
					onClose={() => setShowAddInternal(false)}
					onSave={(user) => handleCreateRecipient(user)}
				/>
			)}
			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</>
	)
}

import React, { Component } from "react"
import FormValidator from "@ncs/bricks/util/formValidator"
import { withCallApi } from "~/components"
import { withRouter } from "react-router-dom"

import { createComment } from "@ncs/mortar/redux/services/ticketing"

import TicketCommentAdd from "./TicketCommentAdd"

class TicketAddContainer extends Component {
	constructor(props) {
		super(props)

		this.validations = {
			description: {
				stateName: "description",
				isRequired: true,
				maxLength: 5000,
			},
			ticket_requestor_name: {
				stateName: "requestorText",
				isRequired: false,
				maxLength: 255,
				minLength: 1,
			},
			send_notification: {
				stateName: "sendNotification",
				type: "bool",
			},
		}

		this.formValidator = new FormValidator(this, this.validations)

		this.state = {
			isEditing: false,
			isSaving: false,
			...this.formValidator.getInitialFormState(),
			isService: props.ticket.ticket_type.group_id === 3 ? true : false,
			selectedRequestor: null,
			requestorText: "",
			selectedTech: null,
			selectedTicketRequestor: null,
		}
	}

	handleSetInputRef = (input) => {
		this.input = input
	}

	handleCommentSave = () => {
		if (!this.formValidator.dataIsValid()) {
			this.setState({ showValidationErrors: true })
			return
		}
		let comment = {
			ticket_id: this.props.ticket.id,
			tech_id: this.state.selectedTech !== null ? this.state.selectedTech._id : null,
			ticket_requestor_id: this.state.selectedTicketRequestor,
			customer_id: this.props.ticket.customer ? this.props.ticket.customer_id : null,
		}

		for (const [prop, config] of Object.entries(this.validations)) {
			comment[prop] = this.state[config.stateName]
		}

		this.props.callApi(createComment(comment)).then((result) => {
			if (result.error) {
				return this.setState({ error: result.error })
			} else {
				this.setState({
					success: true,
					newCommentMessage: "Comment Created.",
				})
				this.props.onCommentCreate()
			}
		})
	}
	handleCommentAddCancel = () => {
		this.props.onCommentAddCancel()
	}
	handleRequestorTypeChange = (selectedRequestor) => {
		if (selectedRequestor === 1) {
			this.setState({ selectedRequestor, ticket_requestor_name: null, requestorText: "" })
		} else {
			this.setState({ selectedRequestor, selectedTech: null })
		}
	}
	handleRequestorTextChange = (requestorText) => {
		this.setState({ requestorText })
	}
	handleTechSelected = (selectedTech) => {
		this.setState({ selectedTech })
	}
	handleSelectedTicketRequestorChange = (selectedTicketRequestor) => {
		this.setState({ selectedTicketRequestor, requestorText: "" })
	}
	render() {
		const { isSaving, success, error } = this.state
		return (
			<TicketCommentAdd
				setInputRef={this.handleSetInputRef}
				isSaving={isSaving}
				onSaveComment={this.handleCommentSave}
				onCancelCommentAdd={this.handleCommentAddCancel}
				success={success}
				error={error}
				isService={this.props.isService}
				selectedRequestor={this.state.selectedRequestor}
				onRequestorTypeChange={this.handleRequestorTypeChange}
				onRequestorTextChange={this.handleRequestorTextChange}
				onTechSelected={this.handleTechSelected}
				ticketCustomer={this.props.ticketCustomer}
				onSelectedTicketRequestorChange={this.handleSelectedTicketRequestorChange}
				selectedTicketRequestor={this.state.selectedTicketRequestor}
				{...this.formValidator.generateFormProps()}
			/>
		)
	}
}

export default withRouter(withCallApi(TicketAddContainer))

import { FC, useCallback, useMemo } from "react"

import { ExtendableSelectProps, Select } from "./Select"

export interface SelectBooleanProps
	extends Omit<
		ExtendableSelectProps<{
			text: string
			value: FakeStringBoolean
		}>,
		"value" | "onChange" | "options"
	> {
	value: boolean | null
	onChange: (newState: boolean | null) => void
	/** The text for the 'yes' option. */
	yesText?: string
	/** The text for the 'no' option.  */
	noText?: string
	/** If true, options will be No then Yes instead of Yes then No. */
	noFirst?: boolean
}

type FakeStringBoolean = "true" | "false"

export const SelectBoolean: FC<SelectBooleanProps> = ({
	value,
	onChange,
	yesText = "Yes",
	noText = "No",
	noFirst,
	...rest
}) => {
	const localValue = useMemo(() => {
		return (
			value == null ? null
			: value === true ? "true"
			: "false"
		)
	}, [value])

	const options = useMemo(() => {
		const result = [
			{
				text: yesText,
				value: "true" as const,
			},
			{
				text: noText,
				value: "false" as const,
			},
		]

		if (noFirst) {
			result.reverse()
		}

		return result
	}, [noText, yesText, noFirst])

	const handleChange = useCallback(
		(newValue: FakeStringBoolean | null) => {
			// Call the onChange param with a real boolean.
			onChange(newValue === null ? null : newValue === "true")
		},
		[onChange]
	)

	return (
		<Select
			{...rest}
			value={localValue}
			options={options}
			onChange={(newValue, newOption) => handleChange(newOption?.value ?? null)}
		/>
	)
}

import { Dispatch, ReactElement, SetStateAction } from "react"

import { InvoiceStatusSelector } from "../selectors"

interface InvoiceStatusQueryParams {
	status?: string | null
	invoice_StatusId?: string | null
}

export interface InvoiceStatusQueryFilterProps<QueryParamState extends InvoiceStatusQueryParams> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
	param?: keyof InvoiceStatusQueryParams
}

export const InvoiceStatusQueryFilter = <QueryParamState extends InvoiceStatusQueryParams>({
	queryParamState,
	setQueryParamState,
	param = "status",
}: InvoiceStatusQueryFilterProps<QueryParamState>): ReactElement => {
	const handleChange = (newValue: string | null) => {
		setQueryParamState((prev) => ({
			...prev,
			[param]: newValue,
		}))
	}

	return (
		<InvoiceStatusSelector
			value={queryParamState[param] ?? null}
			onChange={handleChange}
			disableNoSelectionOption={false}
			noSelectionOptionText="Any status"
			fillContainer
		/>
	)
}

import { FC, useState } from "react"

import { makeApiErrorMessage, PosPart, QuickOrder, useAddQuickOrderPart } from "@ncs/ncs-api"
import { ExtendableModalProps, Modal, Paragraph, PosPartSelector } from "@ncs/web-legos"

export interface AddPartToQuickOrderModalProps extends ExtendableModalProps {
	order: QuickOrder
}

export const AddPartToQuickOrderModal: FC<AddPartToQuickOrderModalProps> = ({
	order,
	...rest
}) => {
	const [selection, setSelection] = useState<PosPart | null>(null)
	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const addPart = useAddQuickOrderPart()

	const onSave = async () => {
		if (!selection) return

		try {
			setIsSaving(true)
			await addPart({
				onlinePartId: selection.id,
				quickOrderId: order.id,
			})
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const reset = () => {
		setSelection(null)
		setIsSaving(false)
		setErrorText(null)
	}

	return (
		<Modal
			title="Add Product To Quick Order"
			onOpen={reset}
			{...rest}
			errorText={errorText}
			rightButtons={{
				buttonText: "Add Product",
				onClick: onSave,
				disabled: !selection,
				isLoading: isSaving,
			}}
		>
			<Paragraph mb={1}>
				Select a product to add to the {order.description} Quick Order.
			</Paragraph>
			<PosPartSelector
				value={selection}
				onChange={setSelection}
				hideParents
				hideRelationshipLabels
				autoFocus
			/>
		</Modal>
	)
}

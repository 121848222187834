import React from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import callApi from "@ncs/bricks/redux/services/callApi"
import { BaseSelectFilter, withDynamicTableFilter } from "@ncs/bricks/components"
import { loadLookups, LOOKUPS } from "../../redux/services/lookups"

class VendorFilter extends React.PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			locations: [],
		}
	}

	componentDidMount() {
		this.props.loadLookups([LOOKUPS.Vendors])
	}

	render() {
		return (
			<BaseSelectFilter
				id="vendors"
				labelText="Vendor"
				options={[{ id: "", name: "All Vendors" }, ...this.props.vendors]}
				valueAccessor={(x) => (x._id || "").toString()}
				textAccessor="name"
				{...this.props}
			/>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		vendors: state.lookups.vendors,
	}
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ callApi, loadLookups }, dispatch)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withDynamicTableFilter("vendor")(VendorFilter))

import React from "react"
import moment from "moment"

// icons
import Edit from "@material-ui/icons/Edit"
import Check from "@material-ui/icons/Check"
import Remove from "@material-ui/icons/Remove"
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber"
import CloseIcon from "@material-ui/icons/Close"
import RepeatIcon from "@material-ui/icons/Repeat"
import { Link } from "react-router-dom"
//core components
// import FormControl from "@material-ui/core/FormControl"
// import FormControlLabel from "@material-ui/core/FormControlLabel"
// import InputLabel from "@material-ui/core/InputLabel"

import {
	ButtonWithIcon,
	ConditionalContent,
	GridContainer,
	GridItem,
	Input,
	QuickCard,
	UserSelector,
	Checkbox,
	Datetime,
	TicketSubtypeSelector,
	TicketStatusSelector,
	TicketPrioritySelector,
	TicketTypeSelector,
	TicketGroupSelector,
	InputAsDisplay,
	TicketSelector,
	Dialog,
	SnackbarContent,
	Button,
	CustomerSelector,
	Select,
	TicketRequestorSelector,
	// onClickCustomer,
} from "~/components"

// styles
import { withStyles } from "@material-ui/core/styles"
import { formatDate } from "@ncs/bricks/util/formatters"

//local components
import TicketDocuments from "./TicketDocuments"
import CommentNotificationContainer from "./CommentNotificationContainer"

const styles = {
	right: {
		textAlign: "right",
	},
}

function TicketInfo(props) {
	let {
		ticket,
		isEditing,
		isSaving,
		description,
		onDescriptionChange,
		showValidationErrors,
		descriptionIsValid,
		descriptionErrorMessage,
		isNuisance,
		onIsNuisanceChange,
		isNuisanceIsValid,
		isNuisanceErrorMessage,
		isUrgent,
		onIsUrgentChange,
		isUrgentIsValid,
		isUrgentErrorMessage,
		setInputRef,
		formIsValid,
		onCancelEditingTicketInfo,
		onSaveTicketInfo,
		onStartEditingTicketInfo,
		classes,
		onTicketStatusChange,
		selectedStatus,
		onTicketPriorityChange,
		selectedPriority,
		onTicketTypeChange,
		selectedType,
		onTicketSubtypeChange,
		selectedSubtype,
		onTicketAssignedToChange,
		onEstimatedCompletionDateUpdated,
		estimatedCompletionDate,
		isAdmin,
		comment,
		onCommentChange,
		commentIsValid,
		commentErrorMessage,
		selectedGroup,
		onTicketGroupChange,
		isValid,
		canEdit,
		isAssigned,
		onCloseTicket,
		onAddDuplicate,
		onRemoveDuplicate,
		isAddingDuplicate,
		onConfirmDuplicate,
		onCancelAddDuplicate,
		onRelatedTicketSelected,
		isDuplicate,
		relatedTicket,
		onViewRelatedTicket,
		selectedTicket,
		selectedCustomer,
		onCustomerSelected,
		customerEquipment,
		selectedCustomerEquipment,
		onCustomerEquipmentChange,
		onTicketTechChange,
		isServiceUser,
		selectedRequestor,
		requestorText,
		requestorTextErrorMessage,
		requestorTextIsValid,
		onRequestorTypeChange,
		onSelectedTicketRequestorChange,
		selectedTicketRequestor,
		onRequestorTextChange,
	} = props
	let viewRelatedButtonContent = `View Ticket ${relatedTicket}`
	return (
		<GridContainer>
			<GridItem xs={12}>
				<QuickCard title={`Ticket #${ticket.id}`} icon={<ConfirmationNumberIcon />}>
					<GridContainer justify={"center"} show={!isEditing}>
						<GridItem xs={12} md={4}></GridItem>
						<GridItem xs={12} md={4} show={isDuplicate}>
							<SnackbarContent
								message={
									<React.Fragment>
										This ticket has been marked as a duplicate. Please view
										Ticket {relatedTicket} for more information.
									</React.Fragment>
								}
								color={"info"}
								icon={RepeatIcon}
							></SnackbarContent>
						</GridItem>
						<GridItem xs={12} md={4}></GridItem>
						<GridItem show={isDuplicate}>
							<Button
								content={viewRelatedButtonContent}
								size="sm"
								round
								color="warning"
								onClick={onViewRelatedTicket}
							/>
						</GridItem>
						<GridItem />
					</GridContainer>
					<GridItem xs={12} className={classes.right}>
						<ConditionalContent
							hide={isDuplicate || !isAdmin || isEditing || isSaving}
						>
							<ButtonWithIcon
								icon={<RepeatIcon />}
								content="Mark as Duplicate"
								size="sm"
								round
								color="warning"
								onClick={onAddDuplicate}
							/>
						</ConditionalContent>
						<ConditionalContent
							hide={!isDuplicate || !isAdmin || isEditing || isSaving}
						>
							<ButtonWithIcon
								icon={<RepeatIcon />}
								content="Remove as Duplicate"
								size="sm"
								round
								color="warning"
								onClick={onRemoveDuplicate}
							/>
						</ConditionalContent>
						<Dialog
							warning="true"
							show={isAddingDuplicate}
							title={"Choose a ticket "}
							// confirmBtnIcon={<Delete />}
							confirmBtnText="Confirm Duplicate"
							confirmBtnColor="danger"
							onConfirm={onConfirmDuplicate}
							isConfirmationAsync={true}
							cancelBtnText={"Cancel"}
							onCancel={onCancelAddDuplicate}
							successTitle="Success"
							successContent="Duplicate Added"
							successConformColor="success"
							onSuccessConfirm={onConfirmDuplicate}
							isSaving={isSaving}
						>
							{isAddingDuplicate && (
								<GridContainer>
									<GridItem xs={12} md={12}>
										<TicketSelector
											onTicketSelected={onRelatedTicketSelected}
										/>
									</GridItem>
								</GridContainer>
							)}
							<GridContainer>
								<GridItem xs={12}>
									<InputAsDisplay
										labelText="Description"
										value={selectedTicket ? selectedTicket.description : ""}
									/>
								</GridItem>
							</GridContainer>
						</Dialog>
						<ConditionalContent
							hide={!canEdit || isEditing || isSaving || ticket.status_id === 4}
						>
							<ButtonWithIcon
								icon={<CloseIcon />}
								content="Close Ticket"
								size="sm"
								round
								color="danger"
								onClick={onCloseTicket}
							/>
						</ConditionalContent>
					</GridItem>
					<GridContainer>
						<GridItem>
							<GridContainer>
								<ConditionalContent hide={isEditing}>
									<GridItem xs={12} md={12}>
										<Input
											labelText={"Title"}
											value={description}
											onChange={onDescriptionChange}
											inputProps={{
												inputProps: { ref: setInputRef },
												disabled: !isEditing,
												autoFocus: isEditing,
											}}
											formControlProps={{
												error: showValidationErrors && !descriptionIsValid,
											}}
											helperText={
												showValidationErrors ? descriptionErrorMessage : ""
											}
										/>
									</GridItem>
								</ConditionalContent>

								<ConditionalContent show={isEditing}>
									<GridItem xs={12} md={12}>
										<Input
											labelText={"Title"}
											value={description}
											onChange={onDescriptionChange}
											inputProps={{
												inputProps: { ref: setInputRef },
												disabled: !isEditing,
												autoFocus: isEditing,
											}}
											formControlProps={{
												error: showValidationErrors && !descriptionIsValid,
											}}
											helperText={
												showValidationErrors ? descriptionErrorMessage : ""
											}
										/>
									</GridItem>
								</ConditionalContent>
								<ConditionalContent hide={isEditing}>
									<GridItem xs={12} md={4}>
										<Input
											labelText={"Department"}
											value={ticket.ticket_type.group.description}
											inputProps={{
												inputProps: { ref: setInputRef },
												disabled: !isEditing,
												autoFocus: isEditing,
											}}
										/>
									</GridItem>
								</ConditionalContent>
								<ConditionalContent show={isEditing}>
									<GridItem xs={12} md={4}>
										<TicketGroupSelector
											group={selectedGroup}
											onChange={onTicketGroupChange}
											isServiceUser={isServiceUser}
										/>
									</GridItem>
								</ConditionalContent>
								<GridItem xs={12} md={4}>
									<InputAsDisplay
										labelText={"Created Date"}
										value={formatDate(ticket.created_date)}
										inputProps={{
											inputProps: { ref: setInputRef },
											disabled: !isAdmin,
											autoFocus: isEditing,
										}}
									/>
								</GridItem>
								<ConditionalContent hide={isEditing}>
									<GridItem xs={12} md={4}>
										{
											ticket.customer === null ?
												<Input
													labelText={"Customer"}
													value={
														ticket.customer === null ?
															"None"
														:	ticket.customer.name
													}
													inputProps={{
														inputProps: { ref: setInputRef },
														disabled: !isEditing,
														autoFocus: isEditing,
													}}
												/>
											:	<Link to={`/customer/details/${ticket.customer._id}`}>
													<Input
														labelText={"Customer"}
														value={
															ticket.customer === null ?
																"None"
															:	ticket.customer.name
														}
														inputProps={{
															inputProps: { ref: setInputRef },
															disabled: !isEditing,
															autoFocus: isEditing,
														}}
													/>
												</Link>


											//       <Button

											//   content={ticket.customer.name}
											//   size="sm"
											//   round
											//   color="success"
											//   onClick={onClickCustomer}
											// />

											//       <FormControl fullWidth className={props.classes.selectFormControl}>
											// 				<InputLabel
											// 					htmlFor="territorySelect"
											// 					// className={classes.selectLabel}
											// 				>
											// 					{"Customer"}
											// 				</InputLabel>
											// 				<Link
											// inputProps={{
											//   inputProps: { ref: setInputRef,
											//     name: "territorySelect",
											//     id: "territorySelect",
											//    }}}
											// to={`/customer/details/${ticket.customer._id}`}>
											//                 {ticket.customer.name}
											//             </Link>
											// 			</FormControl>

											//             <FormControl fullWidth>
											// 	<FormControlLabel
											//     label={"Customer"}
											//     htmlFor="territorySelect"
											// 		classes={{ label: classes.label }}

											// 		className={classes.checkboxPadding}
											// 	/>
											//     <Link
											//       inputProps={{
											//         inputProps: { ref: setInputRef,
											//           name: "territorySelect",
											//           id: "territorySelect",
											//          }}}
											//       to={`/customer/details/${ticket.customer._id}`}>
											//                       {ticket.customer.name}
											//                   </Link>

											// </FormControl>
										}
									</GridItem>
								</ConditionalContent>

								<ConditionalContent show={isEditing && (isAdmin || isAssigned)}>
									<GridItem xs={12} md={4}>
										<CustomerSelector
											labelText="Customer"
											onCustomerSelected={onCustomerSelected}
											defaultValue={ticket.customer}
										/>
									</GridItem>
								</ConditionalContent>
								<ConditionalContent hide={isEditing}>
									<GridItem xs={12} md={4}>
										<Input
											labelText={"Type"}
											value={ticket.ticket_type.description}
											inputProps={{
												inputProps: { ref: setInputRef },

												disabled: !isEditing,
												autoFocus: isEditing,
											}}
										/>
									</GridItem>
								</ConditionalContent>
								<ConditionalContent show={isEditing}>
									<GridItem xs={12} md={4}>
										<TicketTypeSelector
											type={selectedType || ticket.ticket_type.id}
											onChange={onTicketTypeChange}
											group={selectedGroup}
										/>
									</GridItem>
								</ConditionalContent>
								<GridItem xs={12} md={4}>
									<InputAsDisplay
										value={ticket.created_by.name}
										labelText={"Created By"}
									/>
								</GridItem>
								<GridItem
									xs={12}
									md={4}
									show={selectedCustomer === null}
								></GridItem>
								<ConditionalContent show={selectedCustomer !== null}>
									<GridItem xs={12} md={4}>
										<InputAsDisplay
											labelText={"Customer Address"}
											value={
												selectedCustomer ?
													`${selectedCustomer.address_2} - ${selectedCustomer.city}, ${selectedCustomer.state}`
												:	""
											}
											inputProps={{
												inputProps: { ref: setInputRef },
												disabled: !isAdmin,
												autoFocus: isEditing,
											}}
										/>
									</GridItem>
								</ConditionalContent>
								<ConditionalContent hide={isEditing}>
									<GridItem xs={12} md={4}>
										<Input
											labelText={"Subtype"}
											value={
												ticket.subtype ?
													ticket.subtype.description
												:	"None"
											}
											inputProps={{
												inputProps: { ref: setInputRef },
												disabled: !isEditing,
												autoFocus: isEditing,
											}}
										/>
									</GridItem>
								</ConditionalContent>
								<ConditionalContent show={isEditing}>
									<GridItem xs={12} md={4}>
										<TicketSubtypeSelector
											type={selectedType}
											onChange={onTicketSubtypeChange}
											subtype={selectedSubtype}
										/>
									</GridItem>
								</ConditionalContent>
								<ConditionalContent hide={isEditing}>
									<GridItem xs={12} md={4}>
										<Input
											labelText={"Est Completion Date"}
											value={
												ticket.estimated_completion_date !== null ?
													formatDate(ticket.estimated_completion_date)
												:	"None"
											}
											inputProps={{
												inputProps: { ref: setInputRef },
												disabled: !isEditing,
												autoFocus: isEditing,
											}}
										/>
									</GridItem>
								</ConditionalContent>
								<ConditionalContent show={isEditing && isAdmin}>
									<GridItem xs={12} md={4}>
										<Datetime
											labelText="Est Completion Date"
											timeFormat={false}
											dateFormat="MM-DD-YYYY"
											onChange={(e) =>
												onEstimatedCompletionDateUpdated(moment(e))
											}
											value={moment(
												estimatedCompletionDate ||
													ticket.estimated_completion_date
											)}
											inputProps={{ disabled: true }}
										/>
									</GridItem>
								</ConditionalContent>
								<ConditionalContent show={isEditing && !isAdmin}>
									<GridItem xs={12} md={4}>
										<Input
											labelText={"Estimated Completion Date"}
											value={
												ticket.estimated_completion_date !== null ?
													formatDate(ticket.estimated_completion_date)
												:	"None"
											}
											inputProps={{
												inputProps: { ref: setInputRef },
												disabled: !isAdmin,
												autoFocus: isEditing,
											}}
										/>
									</GridItem>
								</ConditionalContent>
								<GridItem
									xs={12}
									md={4}
									lg={4}
									show={selectedCustomer === null}
								></GridItem>
								<ConditionalContent show={selectedCustomer !== null}>
									<GridItem xs={12} md={4} lg={4} show={!isEditing}>
										<InputAsDisplay
											labelText={"Customer Equipment"}
											value={
												(
													ticket.customer_id &&
													ticket.customer_equipment_id !== null
												) ?
													`${ticket.customer_equipment.model} ${
														ticket.customer_equipment.serial_number ?
															`(${ticket.customer_equipment.serial_number})`
														:	""
													} ${`(Bay: ${ticket.customer_equipment.bay})`}`
												:	"No Equipment Selected."
											}
											inputProps={{
												inputProps: { ref: setInputRef },
												disabled: !isAdmin,
												autoFocus: isEditing,
											}}
										/>
									</GridItem>
									<GridItem xs={12} md={4} lg={4} show={isEditing}>
										<Select
											id="customerEquipment"
											labelText="Customer Equipment"
											value={selectedCustomerEquipment}
											onChange={onCustomerEquipmentChange}
											options={[
												{
													_id: null,
													model: "No Equipment.",
													disabled: false,
													serial_number: "",
												},
												...customerEquipment,
											]}
											labelProps={{ shrink: true }}
											valueAccessor="_id"
											textAccessor={(x) =>
												`${x.model} ${
													x.serial_number ? `(${x.serial_number})` : ""
												} ${x._id !== null ? `(Bay: ${x.bay})` : ""}`
											}
										/>
									</GridItem>
								</ConditionalContent>

								<ConditionalContent hide={isEditing}>
									<GridItem xs={12} md={4}>
										<Input
											labelText={"Assigned To"}
											value={
												ticket.assigned_to === null ?
													"Not Assigned"
												:	ticket.assigned_to.name
											}
											inputProps={{
												inputProps: { ref: setInputRef },
												disabled: !isEditing,
												autoFocus: isEditing,
											}}
										/>
									</GridItem>
								</ConditionalContent>
								<ConditionalContent show={isEditing}>
									<ConditionalContent show={isAdmin || isAssigned}>
										<GridItem xs={12} md={4}>
											<UserSelector
												labelText="Assigned To"
												onUserSelected={onTicketAssignedToChange}
												defaultValue={ticket.assigned_to}
											/>
										</GridItem>
									</ConditionalContent>
									<ConditionalContent show={!isAdmin}>
										<GridItem xs={12} md={4}>
											<Input
												labelText={"Assigned To"}
												value={
													ticket.assigned_to === null ?
														"Not Assigned"
													:	ticket.assigned_to.name
												}
												inputProps={{
													inputProps: { ref: setInputRef },
													disabled: true,
													autoFocus: isEditing,
												}}
											/>
										</GridItem>
									</ConditionalContent>
								</ConditionalContent>
								<ConditionalContent hide={isEditing}>
									<GridItem xs={12} md={4}>
										<Input
											labelText={"Status"}
											value={ticket.status.description}
											inputProps={{
												inputProps: { ref: setInputRef },
												disabled: !isEditing,
												autoFocus: isEditing,
											}}
										/>
									</GridItem>
								</ConditionalContent>
								<ConditionalContent show={isEditing}>
									<GridItem xs={12} md={4}>
										<TicketStatusSelector
											status={selectedStatus || ticket.status.id}
											onChange={onTicketStatusChange}
										/>
									</GridItem>
								</ConditionalContent>
								<ConditionalContent hide={isEditing}>
									<GridItem xs={12} md={4}>
										<Input
											labelText={"Priority"}
											value={
												ticket.priority === null ?
													"No Priority Assigned"
												:	ticket.priority.description
											}
											inputProps={{
												inputProps: { ref: setInputRef },
												disabled: !isEditing,
												autoFocus: isEditing,
											}}
										/>
									</GridItem>
								</ConditionalContent>
								<ConditionalContent show={isEditing}>
									<GridItem xs={12} md={4}>
										<TicketPrioritySelector
											priority={
												selectedPriority === null ?
													ticket.priority !== null ?
														ticket.priority.id
													:	""
												:	selectedPriority
											}
											onChange={onTicketPriorityChange}
										/>
									</GridItem>
								</ConditionalContent>
								<ConditionalContent hide={isEditing}>
									<GridItem xs={12} md={4} show={selectedGroup === 3}>
										<Input
											labelText={"Requestor Type"}
											value={
												ticket.tech === null ?
													ticket.ticket_requestor ?
														"Other"
													:	"None"
												:	"Tech"
											}
											inputProps={{
												inputProps: { ref: setInputRef },
												disabled: !isEditing,
												autoFocus: isEditing,
											}}
										/>
									</GridItem>
								</ConditionalContent>
								<GridItem xs={12} md={4} show={selectedGroup === 3 && isEditing}>
									<Select
										id="ticketCommentRequestor"
										labelText="Requestor Type"
										value={selectedRequestor}
										onChange={onRequestorTypeChange}
										options={
											selectedCustomer ?
												[
													{
														id: null,
														description: "Select a request type...",
														disabled: false,
													},
													{
														id: 1,
														description: "Technician",
													},
													{
														id: 2,
														description: "Other",
													},
												]
											:	[
													{
														id: null,
														description: "Select a request type...",
														disabled: false,
													},
													{
														id: 1,
														description: "Technician",
													},
												]
										}
										labelProps={{ shrink: true }}
										valueAccessor="id"
										textAccessor={(x) => x.description}
									/>
								</GridItem>
								<GridItem
									xs={12}
									md={4}
									lg={4}
									show={selectedRequestor === 1 && isEditing}
								>
									<UserSelector
										labelText="Tech"
										onUserSelected={onTicketTechChange}
										defaultValue={ticket.tech}
									/>
								</GridItem>
								<GridItem
									xs={12}
									md={4}
									show={selectedRequestor === 1 && isEditing}
								></GridItem>
								<GridItem
									xs={12}
									md={4}
									show={
										selectedRequestor === 2 && selectedGroup === 3 && isEditing
									}
								>
									<TicketRequestorSelector
										customer={selectedCustomer ? selectedCustomer._id : null}
										onChange={onSelectedTicketRequestorChange}
										requestor={selectedTicketRequestor}
									/>
								</GridItem>
								<GridItem
									xs={12}
									md={4}
									show={
										selectedRequestor === 2 &&
										selectedTicketRequestor === null &&
										isEditing
									}
								>
									<Input
										labelText={"Requestor"}
										value={requestorText}
										onChange={onRequestorTextChange}
										inputProps={{
											ref: setInputRef,
											autoFocus: true,
										}}
										formControlProps={{
											error: showValidationErrors && !requestorTextIsValid,
										}}
										helperText={
											showValidationErrors ? requestorTextErrorMessage : ""
										}
									/>
								</GridItem>

								<ConditionalContent hide={isEditing}>
									<GridItem xs={12} md={4} show={selectedRequestor === 1}>
										<Input
											labelText={"Technician"}
											value={
												ticket.tech === null ? "No Tech" : ticket.tech.name
											}
											inputProps={{
												inputProps: { ref: setInputRef },
												disabled: !isEditing,
												autoFocus: isEditing,
											}}
										/>
									</GridItem>
								</ConditionalContent>
								<GridItem
									xs={12}
									md={4}
									show={selectedRequestor === 2 && !isEditing}
								>
									<Input
										labelText={"Ticket Requestor"}
										value={
											ticket.ticket_requestor ?
												ticket.ticket_requestor.name
											:	"None"
										}
										inputProps={{
											inputProps: { ref: setInputRef },
											disabled: !isEditing,
											autoFocus: isEditing,
										}}
									/>
								</GridItem>
								<GridItem
									show={
										selectedRequestor === 2 && selectedTicketRequestor !== null
									}
									xs={12}
									sm={4}
								></GridItem>
								<GridItem
									xs={12}
									md={4}
									show={selectedRequestor === 1 && !isEditing}
								></GridItem>
								<ConditionalContent hide={isEditing}>
									<GridItem xs={12} sm={3}>
										<Checkbox
											labelText="Is Urgent"
											checked={isUrgent}
											onClick={onIsUrgentChange}
											isError={!isUrgentIsValid}
											helperText={isUrgentErrorMessage}
											inputProps={{ disabled: true }}
										/>
									</GridItem>
								</ConditionalContent>
								<ConditionalContent show={isEditing}>
									<GridItem xs={12} sm={3}>
										<Checkbox
											labelText="Is Urgent"
											checked={isUrgent}
											onClick={onIsUrgentChange}
											isError={!isUrgentIsValid}
											helperText={isUrgentErrorMessage}
										/>
									</GridItem>
								</ConditionalContent>
								<ConditionalContent show={isAdmin}>
									<ConditionalContent hide={isEditing}>
										<GridItem xs={12} sm={3}>
											<Checkbox
												labelText="Is Nuisance"
												checked={isNuisance}
												onClick={onIsNuisanceChange}
												isError={!isNuisanceIsValid}
												helperText={isNuisanceErrorMessage}
												inputProps={{ disabled: true }}
											/>
										</GridItem>
									</ConditionalContent>
									<ConditionalContent show={isEditing}>
										<GridItem xs={12} sm={3}>
											<Checkbox
												labelText="Is Nuisance"
												checked={isNuisance}
												onClick={onIsNuisanceChange}
												isError={!isNuisanceIsValid}
												helperText={isNuisanceErrorMessage}
											/>
										</GridItem>
									</ConditionalContent>
								</ConditionalContent>
								<ConditionalContent hide={isEditing}>
									<GridItem xs={12}>
										<Input
											labelText={"Initial Comment"}
											value={comment}
											onChange={onCommentChange}
											inputProps={{
												inputProps: { ref: setInputRef },
												disabled: !isEditing,
												autoFocus: isEditing,
												multiline: true,
											}}
											formControlProps={{
												error: showValidationErrors && !commentIsValid,
											}}
											helperText={
												showValidationErrors ? commentErrorMessage : ""
											}
										/>
									</GridItem>
								</ConditionalContent>

								<ConditionalContent show={isEditing}>
									<GridItem xs={12}>
										<Input
											labelText={"Initial Comment"}
											value={comment}
											onChange={onCommentChange}
											inputProps={{
												inputProps: { ref: setInputRef },
												autoFocus: isEditing,
												multiline: true,
											}}
											formControlProps={{
												error: showValidationErrors && !commentIsValid,
											}}
											helperText={
												showValidationErrors ? commentErrorMessage : ""
											}
										/>
									</GridItem>
								</ConditionalContent>
							</GridContainer>
						</GridItem>

						<GridItem xs={12} />
						<GridItem xs={12} show={selectedGroup === 3 && !isEditing}>
							<CommentNotificationContainer
								ticketId={ticket.id}
								hasDocumentationEmail={
									ticket.customer_id ?
										ticket.customer.documentation_email === null ?
											false
										:	true
									:	false
								}
								documentationEmail={
									ticket.customer_id ? ticket.customer.documentation_email : null
								}
							/>
						</GridItem>
						<GridItem xs={12} className={classes.right}>
							<ConditionalContent hide={!canEdit || isEditing || isSaving}>
								<ButtonWithIcon
									icon={<Edit />}
									content="Edit"
									size="sm"
									round
									color="success"
									onClick={onStartEditingTicketInfo}
								/>
							</ConditionalContent>
							<ConditionalContent>
								<ButtonWithIcon
									icon={<Edit />}
									content="Mark as duplicate"
									size="sm"
									round
									color="warning"
									onClick={onStartEditingTicketInfo}
								/>
							</ConditionalContent>
							<ConditionalContent show={isEditing || isSaving}>
								<ButtonWithIcon
									icon={<Remove />}
									content="Cancel"
									size="sm"
									round
									onClick={onCancelEditingTicketInfo}
									disabled={isSaving}
								/>
								<ButtonWithIcon
									icon={<Check />}
									content="Save"
									size="sm"
									round
									color="success"
									onClick={onSaveTicketInfo}
									disabled={
										isSaving ||
										(showValidationErrors && !formIsValid) ||
										!isValid
									}
									loading={isSaving}
								/>
							</ConditionalContent>
						</GridItem>
					</GridContainer>
					<GridContainer>
						<GridItem xs={12} md={6} show={!isEditing}>
							<TicketDocuments
								relatedId={ticket.id}
								canEdit={canEdit}
								isTicket={true}
							/>
						</GridItem>
					</GridContainer>
				</QuickCard>
			</GridItem>
		</GridContainer>
	)
}

export default withStyles(styles)(TicketInfo)

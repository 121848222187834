import { FC, memo, useState } from "react"

import {
	makeApiErrorMessage,
	PricingMatrix,
	useCreatePricingMatrix,
	useUpdatePricingMatrix,
} from "@ncs/ncs-api"
import { unpythonify } from "@ncs/ts-utils"
import { ExtendableModalProps, Modal, TextInput, useToast } from "@ncs/web-legos"

export interface EditPricingMatrixModalProps extends ExtendableModalProps {
	matrix: PricingMatrix | null
	createCallback: (newId: string) => void
}

export const EditPricingMatrixModal: FC<EditPricingMatrixModalProps> = memo(
	({ matrix, createCallback, ...rest }) => {
		const createMatrix = useCreatePricingMatrix()
		const updateMatrix = useUpdatePricingMatrix()
		const { makeSuccessToast } = useToast()

		const [name, setName] = useState<string | null>(null)
		const [errorText, setErrorText] = useState<string | null>(null)
		const [isLoading, setIsLoading] = useState(false)

		const onCreate = async () => {
			if (name) {
				try {
					setIsLoading(true)
					const res = await createMatrix({ description: name })
					const newMatrix = unpythonify<PricingMatrix>(res.data)
					makeSuccessToast("Pricing matrix created")
					createCallback(newMatrix.id)
					rest.onClose()
				} catch (e) {
					setErrorText(makeApiErrorMessage(e))
					setIsLoading(false)
				}
			}
		}

		const onUpdate = async () => {
			if (name && matrix?.id) {
				try {
					setIsLoading(true)
					await updateMatrix({
						updates: {
							description: name,
						},
						id: matrix.id,
					})
					makeSuccessToast("Pricing matrix updated")
					rest.onClose()
				} catch (e) {
					setErrorText(makeApiErrorMessage(e))
					setIsLoading(false)
				}
			}
		}

		const onOpen = () => {
			if (matrix) {
				setName(matrix.description)
			} else {
				setName(null)
			}
			setErrorText(null)
			setIsLoading(false)
		}

		const isEditing = !!matrix

		return (
			<Modal
				title={isEditing ? "Edit Pricing Matrix" : "Create Pricing Matrix"}
				{...rest}
				errorText={errorText}
				onOpen={onOpen}
				rightButtons={{
					buttonText: isEditing ? "Save Changes" : "Create",
					onClick: isEditing ? onUpdate : onCreate,
					disabled: !name,
					isLoading,
				}}
			>
				<TextInput
					label="Matrix name"
					maxLength={255}
					value={name}
					onChange={setName}
					autoFocus
				/>
			</Modal>
		)
	}
)

import React, { HTMLAttributes } from "react"
import PropTypes from "prop-types"
import { createStyles, makeStyles, Tooltip, TooltipProps } from "@material-ui/core"
import { InfoOutlined } from "@material-ui/icons"

const useStyles = makeStyles((theme) =>
	createStyles({
		arrow: {
			color: "white",
		},
		tooltip: {
			background: "white",
			boxShadow: theme.shadows[15],
			padding: "0.6rem 1rem 0.85rem 1rem",
			fontSize: "14px",
			color: theme.palette.text.primary,
			"& .row": {
				display: "flex",
				"& .icon": {
					paddingRight: "0.75rem",
					marginBottom: "-0.25rem",
					opacity: 0.35,
					lineHeight: 0,
				},
				"& .text": {
					paddingTop: "0.1rem",
					opacity: 0.65,
					fontWeight: "normal",
				},
			},
		},
	})
)

export interface CustomTooltipProps extends Omit<TooltipProps, "title"> {
	containerProps?: HTMLAttributes<HTMLDivElement>
	title: string | React.ReactNode
	icon?: React.ReactNode
	noIcon?: boolean
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({
	placement = "top-start",
	enterDelay = 500,
	containerProps,
	title,
	icon = <InfoOutlined />,
	noIcon,
	children,
	...rest
}) => {
	const classes = useStyles()

	return (
		<Tooltip
			classes={classes}
			title={
				<div className="row">
					{!noIcon && <span className="icon">{icon}</span>}
					<span className="text">{title}</span>
				</div>
			}
			placement={placement}
			enterDelay={enterDelay}
			{...rest}
		>
			<div {...containerProps}>{children}</div>
		</Tooltip>
	)
}

CustomTooltip.propTypes = {
	containerProps: PropTypes.object,
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
	icon: PropTypes.node,
	noIcon: PropTypes.bool,
	children: PropTypes.element.isRequired,
}

export default CustomTooltip

import React from "react"
import { withCallApi } from "~/components"
import { deleteUserAssignment } from "@ncs/mortar/redux/services/ticketing"

import { GridContainer, GridItem, ButtonWithIcon, InputAsDisplay, Dialog } from "~/components"

import Delete from "@material-ui/icons/Delete"

class TicketingUserEditor extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isDeleting: false,
		}
	}

	deleteUserAssignment = () => {
		return this.props
			.callApi(deleteUserAssignment(this.props.type, this.props.assignment))
			.then(() => {
				this.props.onDeleteUserAssignment()
				this.setState({ isDeleting: false })
			})
	}

	handleStartDeleting = () => {
		this.setState({ isDeleting: true })
	}

	handleCancelDeleting = () => {
		this.setState({ isDeleting: false })
	}

	render = () => {
		const { isSaving, user } = this.props
		return (
			<React.Fragment>
				<ButtonWithIcon
					icon={<Delete />}
					round
					color="danger"
					justIcon
					onClick={this.handleStartDeleting}
				/>
				<Dialog
					warning="true"
					show={this.state.isDeleting}
					title={"Are you sure you want to remove this User?"}
					confirmBtnIcon={<Delete />}
					confirmBtnText="Confirm Removal"
					confirmBtnColor="danger"
					onConfirm={this.deleteUserAssignment}
					isConfirmationAsync={true}
					cancelBtnText={"Cancel"}
					onCancel={this.handleCancelDeleting}
					successTitle="Success"
					successContent="User was removed."
					onSuccessConfirm={this.handleCancelDeleting}
					isSaving={isSaving}
				>
					<GridContainer>
						<GridItem xs={12}>
							<InputAsDisplay labelText="User" value={user} />
						</GridItem>
					</GridContainer>
				</Dialog>
			</React.Fragment>
		)
	}
}

export default withCallApi(TicketingUserEditor)

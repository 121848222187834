import React from "react"

import withStyles from "@material-ui/core/styles/withStyles"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { bindActionCreators } from "redux"

import { listCustomers } from "@ncs/mortar/redux/services/customers"
import {
	getTechAssignmentDetail,
	patchTechAssignmentDetail,
} from "@ncs/mortar/redux/services/users"
import callApi from "@ncs/bricks/redux/services/callApi"
import FormValidator from "@ncs/bricks/util/formValidator"

import TechAssignmentDetail from "./TechAssignmentDetail"

const styles = {
	right: {
		textAlign: "right",
	},
}

class TechAssignmentDetailContainer extends React.Component {
	constructor(props) {
		super(props)

		this.validations = {
			description: {
				stateName: "description",
				isRequired: true,
				maxLength: 255,
			},
		}

		this.formValidator = new FormValidator(this, this.validations)

		this.state = {
			techAssignment: null,
			customers: [],
			isLoading: false,
			startSwapDefault: false,
			isEditing: false,
			selectedDefaultTech: {},
			selectedAlternateTech: {},
		}
	}
	handleSetInputRef = (input) => {
		this.input = input
	}

	getTechAssignmentFromUrlParams = (props = this.props) => props.match.params.tech_ass_id

	componentDidMount = () => {
		this.fetchTechAssignment()
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		let newOrderNumber = this.getTechAssignmentFromUrlParams()
		if (this.getTechAssignmentFromUrlParams(prevProps) !== newOrderNumber) {
			this.refreshDetails()
		}
	}

	refreshDetails = () => {
		// reset state then call fetch details
		this.fetchTechAssignment()
	}

	fetchTechAssignment = () => {
		this.setState({ isLoading: true })

		let techAssId = this.getTechAssignmentFromUrlParams()

		this.props
			.callApi(getTechAssignmentDetail(techAssId))
			.then(({ payload: techAssignment }) => {
				this.setState({
					techAssignment: techAssignment,
					description: techAssignment.description,
					selectedDefaultTech: techAssignment.default_tech,
					selectedAlternateTech: techAssignment.alternate,
				})
			})
		this.props
			.callApi(listCustomers({ default_tech: techAssId, service: true }))
			.then(({ payload: customers }) => {
				this.setState({
					customers: customers,
					isLoading: false,
				})
			})
	}

	updateCustomer = (customer) => {
		// console.log(customer)
	}

	handleBackToList = () => {
		this.props.history.push("/technician-assignment/")
	}

	handleStartSwapDefault = () => {
		this.setState({ startSwapDefault: true })
	}

	handleCancelSwapDefault = () => {
		this.setState({ startSwapDefault: false })
	}

	handleConfirmSwapDefault = () => {
		this.props
			.callApi(
				patchTechAssignmentDetail(this.state.techAssignment._id, {
					default_not_available: !this.state.techAssignment.default_not_available,
				})
			)
			.then((result) => {
				this.setState({ startSwapDefault: false })
				return this.refreshDetails()
			})
	}
	handleStartEditing = () => {
		this.setState({ isEditing: true })
	}
	handleCancelEditing = () => {
		this.setState({
			isEditing: false,
			selectedAlternateTech: this.state.techAssignment.alternate,
			selectedDefaultTech: this.state.techAssignment.default_tech,
			description: this.state.techAssignment.description,
		})
	}
	handleDefaultTechChange = (selectedDefaultTech) => {
		this.setState({ selectedDefaultTech })
	}
	handleAlternateTechChange = (selectedAlternateTech) => {
		this.setState({ selectedAlternateTech })
	}
	handleConfirmEdit = () => {
		if (!this.formValidator.dataIsValid()) {
			this.setState({ showValidationErrors: true })
			return
		}
		if (!this.state.selectedDefaultTech) {
			return
		}
		if (this.state.selectedAlternateTech) {
			if (this.state.selectedAlternateTech._id === this.state.selectedDefaultTech._id) {
				return
			}
		}

		let techAssignment = {
			alternate_id:
				this.state.selectedAlternateTech ? this.state.selectedAlternateTech._id : null,
			default_tech_id: this.state.selectedDefaultTech._id,
			default_not_available:
				this.state.techAssignment.default_not_available ?
					!this.state.selectedAlternateTech ?
						false
					:	true
				:	this.state.techAssignment.default_not_available,
		}
		for (const [prop, config] of Object.entries(this.validations)) {
			techAssignment[prop] = this.state[config.stateName]
		}
		this.props
			.callApi(patchTechAssignmentDetail(this.state.techAssignment._id, techAssignment))
			.then((result) => {
				this.setState({ isEditing: false })
				return this.refreshDetails()
			})
		this.setState({ isEditing: false })
	}
	handleDeleteTechAssignment = () => {
		if (!this.formValidator.dataIsValid()) {
			this.setState({ showValidationErrors: true })
			return
		}
		if (!this.state.selectedDefaultTech) {
			return
		}
		if (this.state.selectedAlternateTech) {
			if (this.state.selectedAlternateTech._id === this.state.selectedDefaultTech._id) {
				return
			}
		}
		let techAssignment = {
			alternate_id:
				this.state.selectedAlternateTech ? this.state.selectedAlternateTech._id : null,
			default_tech_id: this.state.selectedDefaultTech._id,
			default_not_available:
				this.state.techAssignment.default_not_available ?
					!this.state.selectedAlternateTech ?
						false
					:	true
				:	this.state.techAssignment.default_not_available,
			is_active: false,
		}
		for (const [prop, config] of Object.entries(this.validations)) {
			techAssignment[prop] = this.state[config.stateName]
		}
		let newAssignment = this.state.techAssignment
		newAssignment.is_active = false
		this.props
			.callApi(patchTechAssignmentDetail(this.state.techAssignment._id, techAssignment))
			.then((result) => {
				this.setState({ isEditing: false })
				return this.handleBackToList()
			})
		this.setState({ isEditing: false })
	}

	render() {
		const { isLoading, customers, techAssignment, isEditing, startSwapDefault } = this.state
		return (
			<TechAssignmentDetail
				techAssignment={techAssignment}
				setInputRef={this.handleSetInputRef}
				onBackToList={this.handleBackToList}
				onDefaultTechChange={this.handleDefaultTechChange}
				onAlternateTechChange={this.handleAlternateTechChange}
				onStartEditing={this.handleStartEditing}
				onCancelEditing={this.handleCancelEditing}
				onStartSwapDefault={this.handleStartSwapDefault}
				onCancelSwapDefault={this.handleCancelSwapDefault}
				startSwapDefault={startSwapDefault}
				onConfirmSwapDefault={this.handleConfirmSwapDefault}
				isLoading={isLoading}
				customers={customers}
				isEditing={isEditing}
				onConfirmEdit={this.handleConfirmEdit}
				onSave={this.refreshDetails}
				onDeleteAssignment={this.handleDeleteTechAssignment}
				{...this.formValidator.generateFormProps()}
			/>
		)
	}
}

const mapStateToProps = (state) => {
	return {}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			callApi,
		},
		dispatch
	)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(withStyles(styles)(TechAssignmentDetailContainer)))

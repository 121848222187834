import { FC } from "react"

import { useFormContext } from "react-hook-form"
import { useParams } from "react-router-dom"

import { AnimatedEntrance, BoxProps, Callout, Link, Paragraph } from "@ncs/web-legos"

import { PosPartForm } from "./pos-products-utils"

export interface ParentAttributeCalloutProps extends BoxProps {}

export const ParentAttributeCallout: FC<ParentAttributeCalloutProps> = ({ ...rest }) => {
	const { id } = useParams<{ id?: string }>()
	const { watch } = useFormContext<PosPartForm>()
	const [relationship, parentPartId] = watch(["relationship", "parentPartId"])

	if (relationship !== "child") {
		return null
	}

	return (
		<AnimatedEntrance show>
			<Callout display="block" variant="info" icon="sitemap" my={0.5} {...rest}>
				<Paragraph>
					Inherited from parent.
					{!!id && !!parentPartId && (
						<>
							{" "}
							Go to{" "}
							<Link to={`/pos-products/product/${parentPartId}`}>
								parent part
							</Link>{" "}
							to edit.
						</>
					)}
				</Paragraph>
			</Callout>
		</AnimatedEntrance>
	)
}

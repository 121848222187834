import { FC, useState } from "react"

import { BillOfMaterial, makeApiErrorMessage, useUpdateBillOfMaterials } from "@ncs/ncs-api"
import {
	ConfirmationModal,
	ConfirmationModalConfig,
	ExtendableModalProps,
	Modal,
	Tabs,
	useToast,
} from "@ncs/web-legos"

import { confirmDisableBomMessage, confirmEnableBomMessage } from "../chemical-pour-down-util"
import { MixRecipeModalMixRecipeTab } from "./MixRecipeModalMixRecipeTab"
import { MixRecipeModalSettingsTab } from "./MixRecipeModalSettingsTab"

export interface MixRecipeModalProps extends ExtendableModalProps {
	bom: BillOfMaterial
}

export enum MixRecipeModalTab {
	MixRecipe = "Mix Recipe",
	Edit = "Edit",
}

export const MixRecipeModal: FC<MixRecipeModalProps> = ({ bom, ...rest }) => {
	const { makeSuccessToast } = useToast()
	const [tab, setTab] = useState<MixRecipeModalTab>(MixRecipeModalTab.MixRecipe)
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)
	const [errorText, setErrorText] = useState<string | null>(null)

	const updateBom = useUpdateBillOfMaterials()

	const changeStatus = async (newStatus: boolean) => {
		try {
			await updateBom({
				id: bom.id,
				updates: {
					isActive: newStatus,
				},
			})
			makeSuccessToast("Bill of Material status updated")
			if (!newStatus) {
				rest.onClose()
			}
		} catch (e) {
			setErrorText(makeApiErrorMessage(e))
		}
	}

	return (
		<Modal
			{...rest}
			title="Mix Chemical Recipe"
			errorText={errorText}
			leftButtons={
				bom.isActive ?
					{
						buttonText: "Disable",
						variant: "text",
						icon: "trash-alt",
						onClick: () => {
							setConfirmationConfig({
								title: "Disable Bill Of Material",
								message: confirmDisableBomMessage,
								onConfirm: async () => {
									await changeStatus(false)
								},
							})
						},
					}
				:	{
						buttonText: "Re-enable",
						variant: "text",
						icon: "check",
						onClick: () => {
							setConfirmationConfig({
								title: "Re-enable Bill Of Material",
								message: confirmEnableBomMessage,
								onConfirm: async () => {
									await changeStatus(true)
								},
							})
						},
					}
			}
		>
			<Tabs<MixRecipeModalTab>
				currentTab={tab}
				onChange={setTab}
				panels={[
					{
						navLabel: MixRecipeModalTab.MixRecipe,
						component: (
							<MixRecipeModalMixRecipeTab closeModal={rest.onClose} bom={bom} />
						),
					},
					{
						navLabel: MixRecipeModalTab.Edit,
						component: <MixRecipeModalSettingsTab bom={bom} setTab={setTab} />,
					},
				]}
			/>

			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</Modal>
	)
}

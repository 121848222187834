import React from "react"
import { arrayOf, shape, number, string, func, oneOfType, object } from "prop-types"
import classNames from "classnames"
import property from "lodash/property"
import memoizeOne from "memoize-one"
import {
	withStyles,
	ListItem,
	Accordion,
	AccordionDetails,
	AccordionSummary,
} from "@material-ui/core"
import { KeyboardArrowDown } from "@material-ui/icons"

import { infoColor, grayColor, whiteColor } from "../../assets/jss/material-dashboard-pro-react"

const pickClassName = property("className")
/** Prop-type for a recursive data structure */
const tree = {
	// The node value.
	value: string.isRequired,
	// Optional node ID. Useful for when the node value is not unique.
	id: oneOfType([string, number]),
}

Object.assign(tree, {
	nodes: arrayOf(oneOfType([shape(tree), string])),
})

const selected = {
	paddingLeft: "3px",
	color: whiteColor,
	backgroundColor: infoColor[0] + " !important",
	fontWeight: "bold",
	userSelect: "none",
	cursor: "pointer",
}

const styles = {
	panel: {
		width: "100%",
		paddingRight: 0,
		paddingLeft: 0,
		"&:before": {
			height: 0,
		},
	},
	panelSummary: {
		padding: 0,
		margin: 0,
	},
	panelDetails: {
		padding: 0,
		display: "block",
	},
	panelExpanded: {
		margin: 0,
		"&:before": {
			opacity: 0,
		},
		border: "1px solid " + grayColor[0],
	},
	childPanel: {
		"&:before": {
			opacity: 0,
		},
	},
	text: {
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "noWrap",
		maxWidth: "75vw",
	},
	expandIcon: {},
	selectedLeaf: {
		...selected,
	},
	selectedParent: {
		...selected,
		paddingLeft: "0",
	},
	selectedParentWrapper: {
		border: "1px solid " + infoColor[0],
	},
	clickable: {
		cursor: "pointer",
	},
}

/**
 * Render a tree view.
 */
class TreeView extends React.PureComponent {
	static propTypes = {
		/** The data to render as a tree view */
		tree: arrayOf(shape(tree)).isRequired,
		/** Callback function fired when a tree leaf is clicked. */
		onLeafClick: func,
		/** Callback function fired when a tree parent is clicked. */
		onParentClick: func,
		/** A search term to refine the tree */
		searchTerm: string,
		/** Properties applied to the AccordionSummary element. */
		accordionSummaryProps: oneOfType([object, func]),
		/** Properties applied to the AccordionDetails element. */
		accordionDetailsProps: oneOfType([object, func]),
		/** Properties applied to the ListItem element. */
		listItemProps: oneOfType([object, func]),
		/** id of selected node. changes formatting of selected node if provided. */
		selectedValue: oneOfType([number, string]),
	}

	static defaultProps = {
		searchTerm: null,
		onLeafClick: null,
		onParentClick: null,
		accordionSummaryProps: null,
		accordionDetailsProps: null,
		listItemProps: null,
	}

	createFilteredTree = memoizeOne((tree, searchTerm) => (searchTerm ? this.filter(tree) : tree))

	handleLeafClick = (leaf) => {
		if (this.props.onLeafClick) {
			this.props.onLeafClick(leaf)
		}
	}

	handleParentClick = (parent) => {
		if (this.props.onParentClick) {
			this.props.onParentClick(parent)
		}
	}

	generateAdditionalProps = (propsOrPropsFunc, item) => {
		return typeof propsOrPropsFunc === "function" ? propsOrPropsFunc(item) : propsOrPropsFunc
	}

	renderNode = (node, parent, depth = 0) => {
		const {
			theme: {
				spacing: { unit: themeUnit },
			},
			classes,
			searchTerm,
			onLeafClick, // eat
			onParentClick, // eat
			accordionSummaryProps,
			accordionDetailsProps,
			listItemProps,
			selectedValue,
			...props
		} = this.props
		const value = this.getNodeValue(node)
		const id = this.getNodeId(node)
		const isLeaf = this.isLeaf(node)
		const unit = themeUnit * 1.5
		const textIndent = unit * depth + unit

		if (isLeaf && searchTerm && !value.includes(searchTerm)) {
			return null
		}

		if (isLeaf) {
			return (
				<ListItem
					disableGutters
					style={{ textIndent }}
					key={typeof id !== "undefined" ? id : value}
					id={value}
					value={value}
					onClick={() => this.handleLeafClick({ value, parent, id })}
					button
					{...this.generateAdditionalProps(listItemProps, { value, parent, id, depth })}
					className={classNames({
						[classes.selectedLeaf]: selectedValue === id,
						[classes.clickable]: typeof onLeafClick === "function",
					})}
				>
					<div className={classes.text}>{value}</div>
				</ListItem>
			)
		}

		const accordionClasses = {
			expanded: classes.panelExpanded,
			...(parent ? { root: classes.childPanel } : null),
		}

		return (
			<Accordion
				classes={accordionClasses}
				style={{ textIndent }}
				key={typeof node.id !== "undefined" ? node.id : node.value}
				elevation={0}
				onChange={(event, expanded) =>
					this.handleParentClick({ ...node, event, expanded })
				}
				{...props}
				className={classNames(classes.panel, pickClassName(props), {
					[classes.selectedParentWrapper]: selectedValue === id,
					[classes.clickable]: typeof onParentClick === "function",
				})}
			>
				<AccordionSummary
					classes={{
						expandIcon: classes.expandIcon,
						root: classes.panelSummary,
					}}
					{...this.generateAdditionalProps(accordionSummaryProps, {
						...node,
						depth,
					})}
					className={classNames(pickClassName(accordionSummaryProps), {
						[classes.selectedParent]: selectedValue === id,
					})}
					expandIcon={<KeyboardArrowDown />}
				>
					<div className={classes.text}>{node.value}</div>
				</AccordionSummary>
				<AccordionDetails
					{...this.generateAdditionalProps(accordionDetailsProps, {
						...node,
						depth,
					})}
					classes={{ root: classes.panelDetails }}
					className={classNames(pickClassName(accordionDetailsProps))}
				>
					{node.nodes.map((l) => this.renderNode(l, node, depth + 1))}
				</AccordionDetails>
			</Accordion>
		)
	}

	isLeaf(node) {
		return typeof node === "string" || !node.nodes || !node.nodes.length
	}

	getNodeValue(node) {
		return typeof node === "string" ? node : node.value
	}

	getNodeId(node) {
		if (typeof node === "object") {
			return node.id
		}
	}

	filter(tree) {
		const { searchTerm } = this.props

		return tree.filter((node) => {
			const value = this.getNodeValue(node)
			const isLeaf = this.isLeaf(node)

			if (value.includes(searchTerm)) {
				return true
			}

			if (isLeaf) {
				return false
			}

			const subtree = this.filter(node.nodes)

			return Boolean(subtree.length)
		})
	}

	render() {
		const tree = this.createFilteredTree(this.props.tree, this.props.searchTerm)

		return tree.map((node) => this.renderNode(node, null))
	}
}

export default withStyles(styles, { withTheme: true })(TreeView)

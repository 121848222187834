import { Dispatch, ReactElement, SetStateAction } from "react"

import { ErpStatusSelector } from "../selectors"

interface ErpStatusQueryParams {
	erpStatus: string | null
}

export interface ErpStatusQueryFilterProps<QueryParamState extends ErpStatusQueryParams> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

export const ErpStatusQueryFilter = <QueryParamState extends ErpStatusQueryParams>({
	queryParamState,
	setQueryParamState,
}: ErpStatusQueryFilterProps<QueryParamState>): ReactElement => {
	const handleChange = (erpStatus: string | null) => {
		setQueryParamState((prev) => {
			const newState: ErpStatusQueryParams = { erpStatus }

			return {
				...prev,
				...newState,
			}
		})
	}

	return (
		<ErpStatusSelector
			disableNoSelectionOption={false}
			noSelectionOptionText="Any status"
			value={queryParamState.erpStatus}
			onChange={handleChange}
			fillContainer
		/>
	)
}

import { FC, useState } from "react"

import {
	makeApiErrorMessage,
	TerritoryMinimal,
	UserLocation,
	UserProfile,
	useTerritories,
	useUpdateUserLocations,
} from "@ncs/ncs-api"
import { extractNumber } from "@ncs/ts-utils"
import {
	Box,
	Button,
	Callout,
	CheckboxGroup,
	ExtendableModalProps,
	HeadingDivider,
	LoadingSpinner,
	Modal,
	Paragraph,
	useChangeCallback,
	useToast,
} from "@ncs/web-legos"

export interface EditTerritoriesModalProps extends ExtendableModalProps {
	initialUserLocations: UserLocation[]
	user: UserProfile
}

interface SelectionsState {
	[id: string]: {
		checked: boolean
		territory: TerritoryMinimal
	}
}

export const EditTerritoriesModal: FC<EditTerritoriesModalProps> = ({
	initialUserLocations,
	user,
	...rest
}) => {
	const { makeSuccessToast } = useToast()
	const [selections, setSelections] = useState<SelectionsState>({})
	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const [allLocations, allLocationsLoading] = useTerritories()
	const update = useUpdateUserLocations(user.id.toString())

	const handleSave = async () => {
		try {
			setIsSaving(true)
			await update({
				customers: null,
				locations: Object.values(selections).flatMap(({ checked, territory }) => {
					return checked ?
							[
								{
									_id: territory.id,
									description: territory.description,
									code: territory.code,
								},
							]
						:	[]
				}),
				billToCustomers: false,
			})
			makeSuccessToast("User territories updated")
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const selectAll = (domestic: boolean) => {
		setSelections((prev) => {
			return Object.fromEntries(
				Object.entries(prev).map(([id, state]) => {
					return state.territory.isDomestic === domestic ?
							[id, { ...state, checked: true }]
						:	[id, state]
				})
			)
		})
	}

	const selectNone = (domestic: boolean) => {
		setSelections((prev) => {
			return Object.fromEntries(
				Object.entries(prev).map(([id, state]) => {
					return state.territory.isDomestic === domestic ?
							[id, { ...state, checked: false }]
						:	[id, state]
				})
			)
		})
	}

	const reset = () => {
		setIsSaving(false)
		setErrorText(null)
		if (allLocations) {
			setSelections(makeStateFromLocations(initialUserLocations, allLocations))
		}
	}

	// Instantiate local state once the territories lookup has finished.
	useChangeCallback(
		allLocations,
		(locations) => {
			if (locations) {
				setSelections(makeStateFromLocations(initialUserLocations, locations))
			}
		},
		{ callOnSetup: true }
	)

	return (
		<Modal
			{...rest}
			title="Edit User Territories"
			onOpen={reset}
			maxWidth="md"
			errorText={errorText}
			rightButtons={{
				buttonText: "Save Changes",
				isLoading: isSaving,
				onClick: handleSave,
			}}
		>
			{allLocationsLoading && <LoadingSpinner />}
			{allLocations && (
				<>
					<HeadingDivider
						headingVariant="h3"
						mb={1}
						renderRight={() => {
							return (
								<Box display="flex" columnGap={0.75} mt={-0.25}>
									<Button onClick={() => selectAll(true)}>Select all</Button>
									<Button onClick={() => selectNone(true)}>Select none</Button>
								</Box>
							)
						}}
					>
						Domestic Territories
					</HeadingDivider>
					<Box pl={1.5}>
						<CheckboxGroup
							id="domestic"
							rows={allLocations
								.filter((l) => l.isDomestic)
								.sort((a, b) =>
									extractNumber(a.code) > extractNumber(b.code) ? 1 : -1
								)}
							valueAccessor="id"
							labelAccessor={(row) => `(${row.code}) ${row.description}`}
							checkedAccessor={(row) => !!selections[row.id]?.checked}
							onChange={(row, newState) =>
								setSelections((prev) => ({
									...prev,
									[row.id]: {
										...prev[row.id],
										checked: newState,
									},
								}))
							}
							columnCounts={{ md: 2, sm: 1 }}
							fillContainer
						/>
					</Box>

					<HeadingDivider
						headingVariant="h3"
						mb={1}
						renderRight={() => {
							return (
								<Box display="flex" columnGap={0.75} mt={-0.25}>
									<Button onClick={() => selectAll(false)}>Select all</Button>
									<Button onClick={() => selectNone(false)}>Select none</Button>
								</Box>
							)
						}}
					>
						Non-Domestic Territories
					</HeadingDivider>
					<Box pl={1.5}>
						<CheckboxGroup
							id="non-domestic"
							rows={allLocations
								.filter((l) => !l.isDomestic)
								.sort((a, b) =>
									extractNumber(a.code) > extractNumber(b.code) ? 1 : -1
								)}
							valueAccessor="id"
							labelAccessor={(row) => `(${row.code}) ${row.description}`}
							checkedAccessor={(row) => !!selections[row.id]?.checked}
							onChange={(row, newState) =>
								setSelections((prev) => ({
									...prev,
									[row.id]: {
										...prev[row.id],
										checked: newState,
									},
								}))
							}
							columnCounts={{ md: 2, sm: 1 }}
							fillContainer
						/>
					</Box>
				</>
			)}

			<Callout icon="info-circle" variant="info" mt={2}>
				<Paragraph small>
					Note: Territories listed here are limited to the territories that you have
					permission to edit.
				</Paragraph>
			</Callout>
		</Modal>
	)
}

const makeStateFromLocations = (
	userLocations: UserLocation[],
	allLocations: TerritoryMinimal[]
): SelectionsState => {
	return Object.fromEntries(
		allLocations.map((l) => [
			l.id,
			{
				checked: userLocations.findIndex((uL) => uL.locationId.toString() === l.id) !== -1,
				territory: l,
			},
		])
	)
}

import { BoxProps, HeadingDividerProps } from "@ncs/web-legos"

export const sectionHeadingProps: HeadingDividerProps = {
	headingVariant: "h5",
	bold: true,
	mt: 4,
	mb: 1,
}

export const sectionContentsContainer: BoxProps = {
	pl: 1.75,
}

import { FC } from "react"

import { APPLICATION, useUserCanUse } from "@ncs/ncs-api"
import { Card, Icon, Tabs, useUrlState } from "@ncs/web-legos"

import { PageTitle } from "~/layouts/PageTitle"

import { ContractsListTab } from "./ContractsListTab"
import { ContractsNewContractTab } from "./NewContract/ContractsNewContractTab"

export enum ContractsTab {
	ViewContracts = "View Contracts",
	NewContract = "New Contract",
}

export type ContractsUrlState = {
	tab: ContractsTab
}

const Contracts: FC = () => {
	const [{ tab }, { updateUrlValue }] = useUrlState<ContractsUrlState>({
		tab: ContractsTab.ViewContracts,
	})
	const canEdit = useUserCanUse(APPLICATION.ContractCreator)

	return (
		<>
			<PageTitle title="Contracts" />
			<Card headingIcon="file-signature" heading="Contracts">
				<Tabs
					currentTab={tab}
					onChange={(newTab) => updateUrlValue("tab", newTab)}
					panels={[
						{
							navLabel: ContractsTab.ViewContracts,
							navIcon: <Icon icon="list" />,
							component: <ContractsListTab />,
						},
						{
							navLabel: ContractsTab.NewContract,
							navIcon: <Icon icon="plus-circle" />,
							component: <ContractsNewContractTab />,
							disabled: !canEdit,
						},
					]}
				/>
			</Card>
		</>
	)
}

export default Contracts

import { get, post, rsaaTypes, RSAA } from "@ncs/bricks/middleware/portalApiMiddleware"

const SUBMIT_CONTRACT_FORM = rsaaTypes("contracts/create")
const LIST_CONTRACTS = rsaaTypes("contracts/contract_site/list")
const FETCH_CONTRACT = rsaaTypes("contracts/contract_site/fetch")

export const submitContractForm = (formData) => ({
	[RSAA]: {
		api: post("/contracts/create/", formData),
		types: SUBMIT_CONTRACT_FORM,
	},
})

export const listContracts = (queryStringParams) => ({
	[RSAA]: {
		api: get("/contracts/contract_site/", queryStringParams),
		types: LIST_CONTRACTS,
		parseLinkHeader: true,
	},
})

export const fetchContract = (contractId) => ({
	[RSAA]: {
		api: get(`/contracts/contract_site/${contractId}/`),
		types: FETCH_CONTRACT,
	},
})

export default (state) => state

import { FC, Fragment, useMemo, useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { nanoid } from "nanoid"
import { useForm } from "react-hook-form"
import { z } from "zod"

import {
	ChemicalCustomerDetail,
	CreateChemicalPackagePost,
	makeApiErrorMessage,
	useCreateChemicalPackage,
	useUpdateChemicalPackage,
} from "@ncs/ncs-api"
import { extractNumber } from "@ncs/ts-utils"
import {
	Button,
	Callout,
	ConfirmationModal,
	ConfirmationModalConfig,
	CssGridTable,
	CustomerChemicalContainerSelector,
	ExtendableModalProps,
	GridContainer,
	GridItem,
	HeadingDivider,
	IconButton,
	Modal,
	NumericInput,
	NumericInputFormField,
	Paragraph,
	TextInputFormField,
	useToast,
} from "@ncs/web-legos"

import { ChemicalPackage } from "../packages-tab-util"

export interface EditChemicalPackageModalProps extends ExtendableModalProps {
	packageId: string | null
	packages: ChemicalCustomerDetail["packages"]
	customerId: string
}

export const EditChemicalPackageModal: FC<EditChemicalPackageModalProps> = ({
	packageId,
	packages,
	customerId,
	...rest
}) => {
	const { makeSuccessToast, makeErrorToast } = useToast()
	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)

	const createPackage = useCreateChemicalPackage()
	const updatePackage = useUpdateChemicalPackage()

	const selectedPackage = useMemo(() => {
		return packages.find((p) => p.id === packageId)
	}, [packageId, packages])

	const { control, handleSubmit } = useForm<ChemicalPackageForm>({
		resolver: zodResolver(ChemicalPackageFormSchema),
		defaultValues: initializePackageForm(selectedPackage),
	})

	const [chemicals, setChemicals] = useState<ChemicalsState>(() =>
		initializeChemicalsState(selectedPackage)
	)

	const handleSave = async (form: ChemicalPackageForm) => {
		try {
			setIsSaving(true)

			if (isEdit) {
				await updatePackage({
					id: selectedPackage.id,
					updates: assemblePatchData(form, chemicals, customerId),
				})
			} else {
				await createPackage(assemblePostData(form, chemicals, customerId))
			}
			makeSuccessToast(isEdit ? "Package updated" : "Package created")
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const handleUpdateStatus = async (newStatus: boolean) => {
		if (isEdit) {
			try {
				await updatePackage({
					id: selectedPackage.id,
					updates: { isActive: newStatus },
				})
				makeSuccessToast("Status updated")
				rest.onClose()
			} catch (e) {
				makeErrorToast(makeApiErrorMessage(e))
			}
		}
	}

	const isEdit = !!selectedPackage

	return (
		<>
			<Modal
				{...rest}
				title={isEdit ? "Package Details" : "New Package"}
				titleDetail={isEdit ? selectedPackage.name : undefined}
				maxWidth="md"
				errorText={errorText}
				leftButtons={
					selectedPackage ?
						[
							{
								buttonText:
									selectedPackage.isActive ? "Make inactive" : "Reactivate",
								variant: "text",
								icon: selectedPackage.isActive ? "trash-alt" : "check",
								onClick: () =>
									setConfirmationConfig({
										title: "Update Status",
										message:
											selectedPackage.isActive ?
												"Set this package to inactive?"
											:	"Reactivate this package?",
										onConfirm: async () => {
											await handleUpdateStatus(!selectedPackage.isActive)
										},
									}),
							},
						]
					:	undefined
				}
				rightButtons={{
					buttonText: isEdit ? "Save Changes" : "Create",
					isLoading: isSaving,
					onClick: handleSubmit(handleSave),
				}}
			>
				{isEdit && selectedPackage.isActive === false && (
					<Callout mb={2} variant="info">
						<span>This package is currently set to inactive.</span>
						<Button
							onClick={() =>
								setConfirmationConfig({
									title: "Update Status",
									message:
										selectedPackage.isActive ?
											"Set this package to inactive?"
										:	"Reactivate this package?",
									onConfirm: async () => {
										await handleUpdateStatus(!selectedPackage.isActive)
									},
								})
							}
						>
							Reactivate?
						</Button>
					</Callout>
				)}

				<HeadingDivider bold headingVariant="h4" mt={0} mb={1}>
					Package
				</HeadingDivider>
				<GridContainer rowGap={0}>
					<GridItem xs={12} sm={6}>
						<TextInputFormField control={control} name="name" emptyValueFallback="" />
					</GridItem>
					<GridItem xs={12} sm={6}>
						<NumericInputFormField
							control={control}
							name="number"
							label="PLC package number"
							emptyValueFallback=""
							returnValueAsString
						/>
					</GridItem>
					<GridItem xs={12} sm={6}>
						<NumericInputFormField
							control={control}
							name="price"
							label="Price $"
							emptyValueFallback={null}
							decimalScale={2}
							fixedDecimalScale
						/>
					</GridItem>
				</GridContainer>

				<HeadingDivider bold headingVariant="h4" mt={3} mb={1}>
					Chemicals
				</HeadingDivider>
				<CssGridTable
					gridTemplateColumns="auto auto auto 1fr"
					columnGap={1}
					rowGap={0.5}
					ml={-0.35}
					headers={
						Object.keys(chemicals).length > 0 ?
							["", "Passes", "Chemical Container", "Part"]
						:	undefined
					}
					cells={Object.values(chemicals).map(({ rowId, passes, chemical }) => {
						return (
							<Fragment key={rowId}>
								<IconButton
									icon="times"
									color="gray"
									onClick={() => {
										setChemicals((prev) => {
											const newState = { ...prev }
											delete newState[rowId]
											return newState
										})
									}}
								/>
								<NumericInput
									value={passes ?? null}
									onChange={(newValue) => {
										setChemicals((prev) => ({
											...prev,
											[rowId]: {
												...prev[rowId],
												passes: newValue ?? null,
											},
										}))
									}}
									width={3}
									placeholder="#"
									decimalScale={2}
									mb={0}
								/>
								<CustomerChemicalContainerSelector
									value={chemical?.containerId ?? null}
									onChange={(value, option) =>
										setChemicals((prev) => ({
											...prev,
											[rowId]: {
												...prev[rowId],
												chemical:
													option ?
														{
															...prev[rowId],
															containerId: option.id,
															containerName: option.containerName,
															partNumber: option.partNumber,
															partDescription:
																option.partDescription,
														}
													:	null,
											},
										}))
									}
									label={null}
									customerId={customerId}
									fillContainer
									mb={0}
								/>
								<Paragraph small secondary>
									{!!chemical && (
										<>
											({chemical.partNumber}) {chemical.partDescription}
										</>
									)}
								</Paragraph>
							</Fragment>
						)
					})}
				/>
				<Button
					icon="plus-circle"
					containerProps={{ mt: 1.5 }}
					onClick={() => {
						setChemicals((prev) => {
							const newId = nanoid()

							return {
								...prev,
								[newId]: {
									rowId: newId,
									passes: null,
									chemical: null,
								},
							}
						})
					}}
				>
					Add chemical
				</Button>
			</Modal>

			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</>
	)
}

type ChemicalsState = {
	[rowId: string]: {
		rowId: string
		passes: number | null
		chemical: {
			containerId: string
			containerName: string | null
			partNumber: string
			partDescription: string
		} | null
	}
}

const initializeChemicalsState = (
	chemicalPackage: ChemicalPackage | undefined
): ChemicalsState => {
	if (!chemicalPackage) {
		const id = nanoid()

		return {
			[id]: {
				rowId: id,
				passes: null,
				chemical: null,
			},
		}
	}

	return Object.fromEntries(
		chemicalPackage.chemicals.map((chemical) => {
			const id = nanoid()

			return [
				id,
				{
					rowId: id,
					passes: chemical.passes,
					chemical: {
						containerId: chemical.containerId,
						containerName: chemical.containerName,
						partNumber: chemical.partNumber,
						partDescription: chemical.partDescription,
					},
				},
			]
		})
	)
}

const ChemicalPackageFormSchema = z.object({
	name: z.string().min(1, "Required"),
	number: z.string().min(1, "Required"),
	price: z.number().nullable(),
})

type ChemicalPackageForm = z.infer<typeof ChemicalPackageFormSchema>

const defaultChemicalPackageFormValues: ChemicalPackageForm = {
	name: "",
	number: "",
	price: null,
}

const initializePackageForm = (
	chemicalPackage: ChemicalPackage | undefined
): ChemicalPackageForm => {
	if (!chemicalPackage) return defaultChemicalPackageFormValues

	return {
		name: chemicalPackage.name,
		number: chemicalPackage.number.toString(),
		price: chemicalPackage.price ?? null,
	}
}

const assemblePostData = (
	form: ChemicalPackageForm,
	chemicalsState: ChemicalsState,
	customerId: string
): CreateChemicalPackagePost => {
	const chemicals: CreateChemicalPackagePost["chemicals"] = []

	Object.values(chemicalsState).forEach(({ chemical, passes }) => {
		if (chemical == null && passes == null) {
			return
		}
		if (chemical == null) {
			throw new Error("Must choose a chemical for all rows that have a passes count")
		}
		if (passes == null) {
			throw new Error("Must enter a passes count for all rows with a chemical")
		}

		chemicals.push({
			containerId: chemical.containerId,
			passes,
		})
	})

	if (chemicals.length === 0) {
		throw new Error("At least one chemical must be included")
	}

	return {
		name: form.name,
		customerId,
		isActive: true,
		number: extractNumber(form.number),
		price: form.price,
		chemicals,
	}
}

export const assemblePatchData = assemblePostData

import React from "react"
import PropTypes from "prop-types"

import { SearchSelector, withCallApi } from "@ncs/bricks/components"

import { listContracts } from "../../redux/services/contracts"

const ContractSelector = ({ label, placeholder, onContractSelected, callApi, ...rest }) => {
	async function handleGetSuggestions(search) {
		return callApi(
			listContracts({
				search,
				pageSize: 15,
			})
		)
	}

	function handleGetSuggestionValue(contract) {
		return `${contract.customer.name}, ${contract.bay ? `Bay ${contract.bay}, ` : ""}${
			contract.portal_contract.contract_type.kbm_code
		}`
	}

	function handleSuggestionSelected(event, { suggestion }) {
		onContractSelected(suggestion)
	}

	return (
		<SearchSelector
			label={label}
			placeholder={placeholder}
			getSuggestions={handleGetSuggestions}
			getSuggestionValue={handleGetSuggestionValue}
			onSuggestionSelected={handleSuggestionSelected}
			{...rest}
		/>
	)
}

ContractSelector.defaultProps = {
	label: "Contract",
	placeholder: "Search contracts...",
}

ContractSelector.propTypes = {
	callApi: PropTypes.func.isRequired,
	onContractSelected: PropTypes.func.isRequired,
}

export default withCallApi(ContractSelector)

import { FC } from "react"

import { useHistory, useParams } from "react-router-dom"

import { useDispatch } from "@ncs/ncs-api"
import { formatDateTime } from "@ncs/ts-utils"
import {
	Box,
	Button,
	Card,
	encodeUrlState,
	Icon,
	LoadingSpinner,
	PaginationUrlState,
	Tabs,
	useScrollToTopOnMount,
	useUrlState,
} from "@ncs/web-legos"

import { PageTitle } from "~/layouts"
import { DispatchesUrlState } from "~/views/Service/Dispatches/Dispatches"

import { HeaderDetails, HistoryTab, InvoiceTab, SummaryTab, WorkOrdersTab } from "./components"

export enum DispatchDetailTab {
	Summary = "Summary",
	Invoice = "Invoice",
	WorkOrders = "Work Orders",
	History = "History",
}

export type DispatchDetailUrlState = {
	tab: DispatchDetailTab | null
}

export const DispatchDetail: FC = () => {
	useScrollToTopOnMount()
	const history = useHistory<{ dispatchParams: DispatchesUrlState & PaginationUrlState }>()
	const { id: dispatchId } = useParams<{ id: string }>()
	const [{ tab }, { updateUrlValue }] = useUrlState(defaultUrlState)

	const [dispatch, dispatchLoading] = useDispatch(dispatchId)

	const handleBack = () => {
		history.push({
			pathname: "/service/dispatches",
			search: encodeUrlState<DispatchesUrlState & PaginationUrlState>({
				...(history.location.state?.dispatchParams ?? {}),
			}),
		})
	}

	if (dispatchLoading) {
		return (
			<Card>
				<LoadingSpinner />
			</Card>
		)
	}

	if (!dispatch) {
		return <Card>Could not load dispatch</Card>
	}

	return (
		<>
			<PageTitle title={`Dispatch #${dispatch.dispatchNumber}`} />
			<Button icon="long-arrow-left" onClick={handleBack} containerProps={{ mb: 3 }}>
				All dispatches
			</Button>

			<Card
				heading={`Dispatch #${dispatch.dispatchNumber}`}
				headingIcon="user-hard-hat"
				headingDetail={[
					dispatch.createdDate ?
						`Created ${formatDateTime(dispatch.createdDate)}`
					:	"No creation date recorded",
					`Created by ${dispatch.createdBy?.name || "unknown"}`,
				]}
			>
				<HeaderDetails dispatch={dispatch} />

				<Box mt={4}>
					<Tabs
						currentTab={tab || DispatchDetailTab.Summary}
						onChange={(newTab) => updateUrlValue("tab", newTab)}
						panels={[
							{
								navLabel: DispatchDetailTab.Summary,
								navIcon: <Icon icon="list" />,
								component: <SummaryTab dispatch={dispatch} />,
							},
							{
								navLabel: DispatchDetailTab.WorkOrders,
								navIcon: <Icon icon="tools" />,
								component: <WorkOrdersTab dispatch={dispatch} />,
							},
							{
								navLabel: DispatchDetailTab.Invoice,
								navIcon: <Icon icon="envelope-open-dollar" />,
								component: <InvoiceTab dispatch={dispatch} />,
							},
							{
								navLabel: DispatchDetailTab.History,
								navIcon: <Icon icon="history" />,
								component: <HistoryTab dispatch={dispatch} />,
							},
						]}
					/>
				</Box>
			</Card>
		</>
	)
}

const defaultUrlState: DispatchDetailUrlState = {
	tab: DispatchDetailTab.Summary,
}

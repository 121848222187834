import { get, post, patch, del, rsaaTypes, RSAA } from "@ncs/bricks/middleware/portalApiMiddleware"

const FETCH_MILESTONE_GROUPS = rsaaTypes("milestone_groups/list")
const FETCH_MILESTONE_GROUP = rsaaTypes("milestone_groups/fetch")
const ADD_MILESTONE_GROUP = rsaaTypes("milestone_groups/add")
const UPDATE_MILESTONE_GROUP = rsaaTypes("milestone_group/update")
const FETCH_MILESTONES = rsaaTypes("milestone/list")
const FETCH_MILESTONE = rsaaTypes("milestone/fetch")
const ADD_MILESTONE = rsaaTypes("milestone/add")
const UPDATE_MILESTONE = rsaaTypes("milestone/update")
const UPDATE_MILESTONE_LINE = rsaaTypes("milestone/line/update")
const ADD_MILESTONE_LINE = rsaaTypes("milestone/line/add")
const REMOVE_MILESTONE_LINE = rsaaTypes("milestone/line/remove")
const PATCH_CUSTOMER_MILESTONE = rsaaTypes("customer_milestone/update")

export const fetchMilestoneGroups = (querystringParams) => ({
	[RSAA]: {
		api: get(`/workorders/machine_milestone_group/`, querystringParams),
		types: FETCH_MILESTONE_GROUPS,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const fetchMilestoneGroup = (groupId) => ({
	[RSAA]: {
		api: get(`/workorders/machine_milestone_group/${groupId}/`),
		types: FETCH_MILESTONE_GROUP,
	},
})

export const updateMilestoneGroup = (groupId, newData) => ({
	[RSAA]: {
		api: patch(`/workorders/machine_milestone_group/${groupId}/`, newData),
		types: UPDATE_MILESTONE_GROUP,
	},
})

export const addMilestoneGroup = (params) => ({
	[RSAA]: {
		api: post("/workorders/machine_milestone_group/", params),
		types: ADD_MILESTONE_GROUP,
	},
})

export const fetchMilestones = (groupId) => ({
	[RSAA]: {
		api: get(`/workorders/machine_milestone/`, { machine_group: groupId }),
		types: FETCH_MILESTONES,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const fetchMilestone = (milestoneId) => ({
	[RSAA]: {
		api: get(`/workorders/machine_milestone/${milestoneId}/`),
		types: FETCH_MILESTONE,
	},
})

export const updateMilestone = (milestoneId, params) => ({
	[RSAA]: {
		api: patch(`/workorders/machine_milestone/${milestoneId}/`, params),
		types: UPDATE_MILESTONE,
	},
})

export const addMilestone = (params) => ({
	[RSAA]: {
		api: post(`/workorders/machine_milestone/`, params),
		types: ADD_MILESTONE,
	},
})

export const addMilestoneLine = (line) => ({
	[RSAA]: {
		api: post(`/workorders/milestone_items/`, line),
		types: ADD_MILESTONE_LINE,
	},
})

export const removeMilestoneLine = (lineId) => ({
	[RSAA]: {
		api: del(`/workorders/milestone_items/${lineId}/`),
		types: REMOVE_MILESTONE_LINE,
	},
})

export const updateMilestoneLine = (orderId, params) => ({
	[RSAA]: {
		api: patch(`/workorders/milestone_items/${lineId}/`, params),
		types: UPDATE_MILESTONE_LINE,
	},
})

export const updateCustomerMilestone = (milestoneId, params) => ({
	[RSAA]: {
		api: patch(`/workorders/customer_milestone/`, params),
		types: PATCH_CUSTOMER_MILESTONE,
	},
})

const initialState = {
	error: null,
}

const machineMilestone = (state = initialState, action) => {
	switch (action.type) {
		default:
			return state
	}
}
export default machineMilestone

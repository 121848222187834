import React, { useState } from "react"
import { useCallApi } from "@ncs/bricks/util/hooks"
import { createChemicalMachineType } from "@ncs/mortar/redux/services/inventory"
import { Dialog, Input, Select } from "~/components"

import { Close } from "@material-ui/icons"

const NewMachineTypeModal = ({ show, onClose }) => {
	const callApi = useCallApi()
	const [description, setDescription] = useState("")
	const [isActive, setIsActive] = useState(true)
	const [isLoading, setIsLoading] = useState(false)

	const close = (newMachineType) => {
		setDescription("")
		setIsActive(true)
		setIsLoading(false)
		onClose(newMachineType)
	}

	async function handleConfirm() {
		setIsLoading(true)
		const { payload } = await callApi(
			createChemicalMachineType({
				description,
				isActive,
			})
		)
		close(payload)
	}

	return (
		<Dialog
			show={show}
			onCancel={close}
			onConfirm={handleConfirm}
			title="Create new machine type"
			confirmBtnText="Create Machine Type"
			confirmBtnColor="primary"
			confirmBtnDisabled={!description}
			isSaving={isLoading}
			cancelBtnIcon={<Close />}
			cancelBtnColor="primary"
			alignContentCenter={false}
			cancelBtnSimple
			noTransition
			fullWidth
		>
			<Input
				value={description}
				onChange={(e) => setDescription(e.target.value)}
				labelText="Machine type name"
			/>
			<Select
				value={String(isActive)}
				onChange={(value) => setIsActive(value === "true")}
				labelText="Is machine type active?"
				options={[
					{
						value: "true",
						text: "Active",
					},
					{
						value: "false",
						text: "Inactive",
					},
				]}
			/>
		</Dialog>
	)
}

export default NewMachineTypeModal

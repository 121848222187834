import { FC, useState } from "react"

import dayjs from "dayjs"
import { Cell, Column } from "react-table"

import {
	APPLICATION,
	FuelSurcharge,
	FuelSurchargeQueryParams,
	useFuelSurcharges,
	useUserCanUse,
} from "@ncs/ncs-api"
import {
	dateRangeIsActive,
	displayDate,
	formatCurrency,
	getTimezoneAbbreviation,
} from "@ncs/ts-utils"
import {
	Box,
	Button,
	EmptyValueDash,
	Icon,
	ReactTableSortType,
	Table,
	Tooltip,
	useUrlState,
} from "@ncs/web-legos"

import { ActiveOnlyQueryFilter } from "~/components"

import { NewFuelSurchargeModal } from "./components"

export type FuelSurchargesTabUrlState = FuelSurchargeQueryParams

export const FuelSurchargesTab: FC = () => {
	const canEdit = useUserCanUse(APPLICATION.FreightRatesAdmin)
	const [showCreate, setShowCreate] = useState(false)

	const [params, { setUrlState: setParams }] =
		useUrlState<FuelSurchargesTabUrlState>(defaultUrlState)

	const [charges, chargesLoading] = useFuelSurcharges({ params })

	return (
		<>
			{canEdit && (
				<Box textAlign="right" mb={4}>
					<Button
						variant="secondary-cta"
						icon="plus-circle"
						onClick={() => setShowCreate(true)}
					>
						Create Fuel Price
					</Button>
				</Box>
			)}

			<Table
				data={charges ?? []}
				columns={columns}
				isLoading={chargesLoading}
				toggledQueryFilters={[ActiveOnlyQueryFilter]}
				queryParamState={params}
				setQueryParamState={setParams}
			/>

			{showCreate && <NewFuelSurchargeModal onClose={() => setShowCreate(false)} />}
		</>
	)
}

const columns: Column<FuelSurcharge>[] = [
	{
		Header: "Gasoline rate",
		accessor: "gasolineRate",
		Cell: ({ row: { original } }: Cell<FuelSurcharge>) =>
			formatCurrency(original.gasolineRate),
		sortType: ReactTableSortType.Number,
	},
	{
		Header: "Diesel rate",
		accessor: "dieselRate",
		Cell: ({ row: { original } }: Cell<FuelSurcharge>) => formatCurrency(original.dieselRate),
		sortType: ReactTableSortType.Number,
	},
	{
		Header: `Start date (${getTimezoneAbbreviation()})`,
		accessor: ({ startDate }) => new Date(startDate),
		Cell: ({ row: { original } }: Cell<FuelSurcharge>) => displayDate(original.startDate),
		sortType: ReactTableSortType.Datetime,
	},
	{
		Header: `End date (${getTimezoneAbbreviation()})`,
		accessor: ({ endDate }) => new Date(endDate),
		Cell: ({ row: { original } }: Cell<FuelSurcharge>) => displayDate(original.endDate),
		sortType: ReactTableSortType.Datetime,
	},
	{
		Header: "Status",
		Cell: ({ row: { original } }: Cell<FuelSurcharge>) => {
			return (
				dateRangeIsActive(original.startDate, original.endDate) ?
					<>
						<Icon icon="check" /> Active
					</>
				: dayjs(original.endDate).isBefore(original.startDate) ?
					<Tooltip title="Date range can never be active because end date comes before start date">
						<Icon icon="exclamation-triangle" />
					</Tooltip>
				:	<EmptyValueDash />
			)
		},
	},
	{
		Header: "Created by",
		accessor: "userCreated",
		hiddenByDefault: true,
	},
	{
		Header: `Created (${getTimezoneAbbreviation()})`,
		sortType: ReactTableSortType.Datetime,
		hiddenByDefault: true,
		accessor: ({ createdOn }) => new Date(createdOn),
		Cell: ({ row: { original } }: Cell<FuelSurcharge>) => displayDate(original.createdOn),
	},
]

const defaultUrlState: FuelSurchargesTabUrlState = {
	active: null,
}

import { FC } from "react"

import { Card, Icon, Tabs, useUrlState } from "@ncs/web-legos"

import { PrintInvoicesTab, ViewInvoicesTab } from "./components"

export enum InvoicesTab {
	ViewInvoices = "View Invoices",
	PrintInvoices = "Print Invoices",
}

export type InvoicesUrlState = {
	tab: InvoicesTab | null
}

export const Invoices: FC = () => {
	const [{ tab }, { updateUrlValue }] = useUrlState(defaultUrlState)

	return (
		<Card heading="Invoices" headingIcon="envelope-open-dollar">
			<Tabs
				currentTab={tab}
				onChange={(newTab) => updateUrlValue("tab", newTab)}
				panels={[
					{
						navLabel: InvoicesTab.ViewInvoices,
						navIcon: <Icon icon="list" />,
						component: <ViewInvoicesTab />,
					},
					{
						navLabel: InvoicesTab.PrintInvoices,
						navIcon: <Icon icon="print" />,
						component: <PrintInvoicesTab />,
					},
				]}
			/>
		</Card>
	)
}

const defaultUrlState: InvoicesUrlState = {
	tab: InvoicesTab.ViewInvoices,
}

import React from "react"
import PropTypes from "prop-types"

import { GridContainer, GridItem, ReportPeriodFilter, SearchFilter } from "@ncs/bricks/components"

import {
	DEFAULT_REPORT_FILTERS,
	INVOICE_REPORT_FILTERS,
	REPORT_FILTERS,
	TICKETING_REPORT_FILTERS,
} from "../../util/constants"

// custom components
import { default as AlternatePriceFilter } from "./AlternatePriceFilter"
import { default as CustomerFilter } from "./CustomerFilter"
import { default as CustomerPortalFilter } from "./CustomerPortalFilter"
import { default as CustomerPortalUserFilter } from "./CustomerPortalUserFilter"
import { default as CustomerRoutesFilter } from "./CustomerRoutesFilter"
import { default as CustomerSiteFilter } from "./CustomerSiteFilter"
import { default as DeliveryOrderStatusFilter } from "./DeliveryOrderStatusFilter"
import { default as DispatchCallTypeFilter } from "./DispatchCallTypeFilter"
import { default as DispatchStatusFilter } from "./DispatchStatusFilter"
import { default as EndDateFilter } from "./EndDateFilter"
import { default as EnterpriseFilter } from "./EnterpriseFilter"
import { default as ERPExpiredFilter } from "./ERPExpiredFilter"
import { default as ERPStatusFilter } from "./ERPStatusFilter"
import { default as InventoryRequestStatusFilter } from "./InventoryRequestStatusFilter"
import { default as InventoryTypeFilter } from "./InventoryTypeFilter"
import { default as InvoiceStatusFilter } from "./InvoiceStatusFilter"
import { default as InvoiceTypeFilter } from "./InvoiceTypeFilter"
import { default as IsActiveFilter } from "./IsActiveFilter"
import { default as IsFSTActiveFilter } from "./IsFSTActiveFilter"
import { default as LocationFilter } from "./LocationFilter"
import { default as NCSModelFilter } from "./NCSModelFilter"
import { default as NCSVintageFilter } from "./NCSVintageFilter"
import { default as OrganizationFilter } from "./OrganizationFilter"
import { default as PartFilter } from "./PartFilter"
import { default as PartOrderStatusFilter } from "./PartOrderStatusFilter"
import { default as PickListStatusFilter } from "./PickListStatusFilter"
import { default as ProfitEndMonth } from "./ProfitEndMonth"
import { default as ProfitStartMonth } from "./ProfitStartMonth"
import { default as PurchaseOrderStatusFilter } from "./PurchaseOrderStatusFilter"
import { default as RegionFilter } from "./RegionFilter"
import { default as SiteSurveySubmittedFilter } from "./SiteSurveySubmittedFilter"
import { default as StartDateFilter } from "./StartDateFilter"
import { default as TerritoryFilter } from "./TerritoryFilter"
import { default as TicketGroupFilter } from "./TicketGroupFilter"
import { default as TicketSubtypeFilter } from "./TicketSubtypeFilter"
import { default as TicketTypeFilter } from "./TicketTypeFilter"
import { default as TransferRequestStatusFilter } from "./TransferRequestStatus"
import { default as VendorFilter } from "./VendorFilter"
import { default as VintageFilter } from "./VintageFilter"
import { default as UserFilter } from "./UserFilter"
import { default as WarehouseFilter } from "./WarehouseFilter"

const genericFilters = {
	[REPORT_FILTERS.Customer]: CustomerFilter,
	[REPORT_FILTERS.EndDate]: EndDateFilter,
	[REPORT_FILTERS.Enterprise]: EnterpriseFilter,
	[REPORT_FILTERS.Organization]: OrganizationFilter,
	[REPORT_FILTERS.Part]: PartFilter,
	[REPORT_FILTERS.Region]: RegionFilter,
	[REPORT_FILTERS.ReportPeriod]: ReportPeriodFilter,
	[REPORT_FILTERS.Search]: SearchFilter,
	[REPORT_FILTERS.StartDate]: StartDateFilter,
	[REPORT_FILTERS.Territory]: TerritoryFilter,
	[REPORT_FILTERS.PartOrderStatus]: PartOrderStatusFilter,
	[REPORT_FILTERS.DeliveryOrderStatus]: DeliveryOrderStatusFilter,
	[REPORT_FILTERS.Vendors]: VendorFilter,
	[REPORT_FILTERS.PickListStatus]: PickListStatusFilter,
	[REPORT_FILTERS.CustomerRoutes]: CustomerRoutesFilter,
	[REPORT_FILTERS.ERPStatus]: ERPStatusFilter,
	[REPORT_FILTERS.IsActive]: IsActiveFilter,
	[REPORT_FILTERS.IsFSTActive]: IsFSTActiveFilter,
	[REPORT_FILTERS.IsAlternatePrice]: AlternatePriceFilter,
	[TICKETING_REPORT_FILTERS.TicketGroup]: TicketGroupFilter,
	[TICKETING_REPORT_FILTERS.TicketType]: TicketTypeFilter,
	[TICKETING_REPORT_FILTERS.TicketSubType]: TicketSubtypeFilter,
	[INVOICE_REPORT_FILTERS.InvoiceType]: InvoiceTypeFilter,
	[REPORT_FILTERS.StartMonth]: ProfitStartMonth,
	[REPORT_FILTERS.EndMonth]: ProfitEndMonth,
	[REPORT_FILTERS.Vintages]: VintageFilter,
	[REPORT_FILTERS.PurchaseOrderStatus]: PurchaseOrderStatusFilter,
	[REPORT_FILTERS.DispatchStatus]: DispatchStatusFilter,
	[REPORT_FILTERS.DispatchCallType]: DispatchCallTypeFilter,
	[REPORT_FILTERS.InventoryRequestStatus]: InventoryRequestStatusFilter,
	[REPORT_FILTERS.InventoryType]: InventoryTypeFilter,
	[REPORT_FILTERS.ERPExpiredFilter]: ERPExpiredFilter,
	[REPORT_FILTERS.SiteSurveySubmitted]: SiteSurveySubmittedFilter,
	[REPORT_FILTERS.InvoiceStatus]: InvoiceStatusFilter,
	[REPORT_FILTERS.Location]: LocationFilter,
	[REPORT_FILTERS.TransferRequestStatus]: TransferRequestStatusFilter,
	[REPORT_FILTERS.CustomerPortal]: CustomerPortalFilter,
	[REPORT_FILTERS.CustomerPortalUser]: CustomerPortalUserFilter,
	[REPORT_FILTERS.CustomerSite]: CustomerSiteFilter,
	[REPORT_FILTERS.NCSModels]: NCSModelFilter,
	[REPORT_FILTERS.NCSVintages]: NCSVintageFilter,
	[REPORT_FILTERS.User]: UserFilter,
	[REPORT_FILTERS.Warehouse]: WarehouseFilter,
}

class ReportFilters extends React.PureComponent {
	render() {
		const { config, containerProps } = this.props

		if (!config) {
			console.error("ReportFilter requires a report config to be passed to it.")
			return null
		}

		const reportFilters = config.filters || DEFAULT_REPORT_FILTERS
		return (
			<GridContainer {...containerProps}>
				{reportFilters.map((filter, i) => {
					let wrapperProps = { xs: 12, sm: 6, md: 4 }

					if (typeof filter === "string") {
						filter = genericFilters[filter]
					}

					let FilterComponent = filter

					// allow overriding of wrapper, particularly for setting size
					if (typeof filter === "object" && filter.hasOwnProperty("component")) {
						if (filter.wrapperProps) {
							wrapperProps = filter.wrapperProps
						}
						FilterComponent = filter.component
					}

					return (
						<GridItem {...wrapperProps} key={i}>
							{config.reduxKey ?
								<FilterComponent reduxKey={config.reduxKey} />
							:	<FilterComponent reduxKeys={config.reduxKeys} />}
						</GridItem>
					)
				})}
			</GridContainer>
		)
	}
}

ReportFilters.defaultProps = {
	containerProps: {},
}

ReportFilters.propTypes = {
	config: PropTypes.shape({
		filters: PropTypes.array,
		reduxKey: PropTypes.string,
		reduxKeys: PropTypes.array,
	}),
	// Passed to the GridContainer.
	containerProps: PropTypes.object,
}

export default ReportFilters

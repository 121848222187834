import { FC, useMemo, useState } from "react"

import { useFormContext } from "react-hook-form"

import { addOrReplace } from "@ncs/ts-utils"
import {
	Box,
	Button,
	GridContainer,
	GridItem,
	Heading,
	HookFormErrorText,
	IconButton,
	Paragraph,
} from "@ncs/web-legos"

import { AddExternalRecipientModal } from "../AddExternalRecipientModal"
import { AddInternalRecipientModal } from "../AddInternalRecipientModal"
import { ContractFormRecipient, NewContractForm } from "../contract-utils"

export const NewContractRecipients: FC = () => {
	const [addingNewInternal, setAddingNewInternal] = useState(false)
	const [addingNewExternal, setAddingNewExternal] = useState(false)

	const { control, watch, setValue } = useFormContext<NewContractForm>()
	const [recipients] = watch(["recipients"])

	const saveNewRecipient = (recipient: ContractFormRecipient) => {
		setValue(
			"recipients",
			addOrReplace(recipient, recipients, (r) => r.email === recipient.email),
			{
				shouldValidate: true,
			}
		)
	}

	const removeRecipient = ({ email, userId }: { email?: string; userId?: string }) => {
		if (!email && !userId) return

		setValue(
			"recipients",
			recipients.filter((r) => {
				if (email) {
					return r.email !== email
				}
				if (userId) {
					return r.userId !== userId
				}

				return true
			}),
			{
				shouldValidate: true,
			}
		)
	}

	const internal = useMemo(() => {
		return recipients.filter((r) => !!r.userId)
	}, [recipients])

	const external = useMemo(() => {
		return recipients.filter((r) => !r.userId)
	}, [recipients])

	return (
		<>
			<GridContainer>
				<GridItem md={12} lg={6} display="flex" justifyContent="space-between" gap={1}>
					<Box width="100%">
						<Heading variant="h6" mb={0.5}>
							Internal Recipients
						</Heading>
						{internal.map((r) => (
							<Box key={r.email} mb={0.5} display="flex" gap={0.5}>
								<IconButton
									icon="trash-alt"
									onClick={() =>
										removeRecipient({
											userId: r.userId,
										})
									}
									size="sm"
								/>
								<Paragraph mt={0.15}>
									{r.name}
									{r.description ? `, ${r.description}` : ""}: {r.email}
								</Paragraph>
							</Box>
						))}
						<Button icon="plus" onClick={() => setAddingNewInternal(true)}>
							Add person
						</Button>
					</Box>
					<Box width="100%">
						<Heading variant="h6" mb={0.5}>
							External Recipients
						</Heading>
						{external.map((r) => (
							<Box key={r.email} mb={0.5} display="flex" columnGap={0.5}>
								<IconButton
									icon="trash-alt"
									onClick={() =>
										removeRecipient({
											email: r.email,
										})
									}
									size="sm"
								/>
								<Paragraph mt={0.15}>
									{r.name}
									{r.description ? `, ${r.description}` : ""}: {r.email}
								</Paragraph>
							</Box>
						))}
						<Button icon="plus" onClick={() => setAddingNewExternal(true)}>
							Add person
						</Button>
					</Box>
				</GridItem>
			</GridContainer>

			<HookFormErrorText control={control} name="recipients" />

			<AddInternalRecipientModal
				isOpen={addingNewInternal}
				onClose={() => setAddingNewInternal(false)}
				onSave={saveNewRecipient}
			/>
			<AddExternalRecipientModal
				isOpen={addingNewExternal}
				onClose={() => setAddingNewExternal(false)}
				onSave={saveNewRecipient}
			/>
		</>
	)
}

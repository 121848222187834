import { FC, useEffect, useState } from "react"

import { useThrottle } from "@react-hook/throttle"

import { useChangeCallback, usePrevious } from "../../util"
import { NumericInput, NumericInputProps } from "./NumericInput"

export interface ThrottledNumericInputProps extends Omit<NumericInputProps, "value" | "onChange"> {
	value: number | null
	onChange: (newValue: number | null) => void
	/**
	 * Throttle changes per second
	 * @default 3
	 */
	fps?: number
	/**
	 * Leading throttle change
	 * @default true
	 */
	leading?: boolean
}

/**
 * Like regular `NumericInput`, except we use a local state proxy and the `onChange` prop is throttled.
 */
export const ThrottledNumericInput: FC<ThrottledNumericInputProps> = ({
	value: valueProp,
	onChange: onChangeToThrottle,
	fps = 3,
	leading = true,
	...rest
}) => {
	const [localValue, setLocalValue] = useState<number | null>(valueProp)
	const [throttledValue, setThrottledValue] = useThrottle(valueProp, fps, leading)

	// Whenever local value changes, fire the throttled value updater.
	useEffect(() => {
		setThrottledValue(localValue)
	}, [localValue, setThrottledValue])

	// Whenever throttled value changes, fire the passed in onChange.
	const prevThrottledValue = usePrevious(throttledValue)
	useEffect(() => {
		if (throttledValue !== prevThrottledValue) {
			onChangeToThrottle(throttledValue)
		}
	}, [onChangeToThrottle, throttledValue, prevThrottledValue])

	// If parent passes in something different than what we have, change to that.
	useChangeCallback(valueProp, (newValueProp) => {
		if (localValue !== newValueProp && throttledValue !== newValueProp) {
			setLocalValue(newValueProp)
		}
	})

	return (
		<NumericInput
			{...rest}
			value={localValue}
			onChange={(newValue) => setLocalValue(newValue ?? null)}
		/>
	)
}

import { FC, useCallback, useMemo, useState } from "react"

import { Column, Row } from "react-table"

import {
	CycleCountPart,
	InventoryLocation,
	makeApiErrorMessage,
	useCycleCountParts,
	useRequestCycleCountSheet,
} from "@ncs/ncs-api"
import { formatDate } from "@ncs/ts-utils"
import {
	ButtonProps,
	SearchQueryFilter,
	Select,
	Table,
	TableProps,
	useToast,
	useUrlState,
} from "@ncs/web-legos"

import { ImportCycleCountModal } from "./components"

export interface LocationCycleCountsTabProps {
	location: InventoryLocation
}

type FilterState = {
	search: string | null
	completed: "No" | "Yes" | null
}

export const LocationCycleCountsTab: FC<LocationCycleCountsTabProps> = ({ location }) => {
	const { makeSuccessToast, makeErrorToast } = useToast()
	const [filterState, { setUrlState }] = useUrlState<FilterState>({
		search: null,
		completed: "No",
	})
	const [isRequesting, setIsRequesting] = useState(false)
	const [showUploadModal, setShowUploadModal] = useState(false)

	const [parts] = useCycleCountParts(location.id)
	const requestSheet = useRequestCycleCountSheet()

	const handleRequest = useCallback(
		async (selectedRows: Row<CycleCountPart>[], allUncompletedParts: boolean) => {
			try {
				if (!selectedRows.length && !allUncompletedParts)
					throw new Error("No parts selected for requesting")

				setIsRequesting(true)
				await requestSheet({
					locationId: location.id,
					allUncompletedParts,
					ids:
						allUncompletedParts ? null : (
							selectedRows.map(({ original }) => original.id)
						),
				})
				makeSuccessToast("Request received. Check your email in a few minutes.")
			} catch (e) {
				makeErrorToast(makeApiErrorMessage(e))
			} finally {
				setIsRequesting(false)
			}
		},
		[location.id, makeSuccessToast, makeErrorToast, requestSheet]
	)

	const preparedParts = useMemo(() => {
		const searchSegments = (filterState.search ?? "")
			.split(" ")
			.map((s) => s.trim().toLowerCase())

		return (parts ?? []).filter((p) => {
			const partSearch = `${p.partNumber} ${p.partDescription}`
				.toLowerCase()
				.replace("  ", " ")

			return (
				(filterState.completed == null || filterState.completed === p.completed) &&
				(!searchSegments.length || searchSegments.every((s) => partSearch.includes(s)))
			)
		})
	}, [parts, filterState.search, filterState.completed])

	const bulkActionButtons = useMemo((): TableProps<CycleCountPart>["bulkActionButtons"] => {
		return [
			{
				buttonText: "Request count sheet for selections",
				icon: "download",
				isLoading: isRequesting,
				onClick: async ({ toggleAllRowsSelected, selectedRows, isAllRowsSelected }) => {
					await handleRequest(selectedRows, isAllRowsSelected)
					toggleAllRowsSelected(false)
				},
			},
		]
	}, [handleRequest, isRequesting])

	const rightButtons = useMemo((): ButtonProps[] => {
		return [
			{
				buttonText: "Upload completed count sheet",
				icon: "upload",
				onClick: () => setShowUploadModal(true),
			},
		]
	}, [])

	return (
		<>
			<Table
				data={preparedParts}
				columns={columns}
				bulkActionButtons={bulkActionButtons}
				rightButtons={rightButtons}
				queryParamState={filterState}
				setQueryParamState={setUrlState}
				showToggledFiltersByDefault={Object.values(filterState).some((f) => !!f)}
				pinnedQueryFilters={[
					SearchQueryFilter,
					() => {
						return (
							<Select
								label="Part count completed?"
								value={filterState.completed}
								fillContainer
								disableNoSelectionOption={false}
								onChange={(value, option) =>
									setUrlState((prev) => ({
										...prev,
										completed: option?.value ?? null,
									}))
								}
								options={[
									{
										value: "No" as const,
										text: "No",
									},
									{
										value: "Yes" as const,
										text: "Yes",
									},
								]}
							/>
						)
					},
				]}
			/>

			<ImportCycleCountModal
				isOpen={showUploadModal}
				onClose={() => setShowUploadModal(false)}
				locationId={location.id}
			/>
		</>
	)
}

const columns: Column<CycleCountPart>[] = [
	{
		Header: "Part #",
		accessor: "partNumber",
	},
	{
		Header: "Description",
		accessor: "partDescription",
	},
	{
		Header: "Due date",
		accessor: (original) => formatDate(original.dueDate),
	},
	{
		Header: "Completed?",
		accessor: "completed",
		hiddenByDefault: true,
	},
]

import { FC, useEffect, useMemo, useRef } from "react"

import { useAuth } from "@ncs/ncs-api"
import { ExtendableSelectProps, Select } from "@ncs/web-legos"

import { DashboardName, defaultDashboard, userCanViewDashboard } from "~/util"

export type DashboardSelectorProps = ExtendableSelectProps<DashboardOption, DashboardName>

export const DashboardSelector: FC<DashboardSelectorProps> = ({ value, onChange, ...rest }) => {
	const { user } = useAuth()
	const shouldSetDefault = useRef(true)

	const options = useMemo((): DashboardOption[] => {
		if (!user) return []

		return Object.values(DashboardName)
			.filter((name) => userCanViewDashboard(name, user))
			.map((name) => ({ value: name }))
	}, [user])

	useEffect(() => {
		// Once value has been truthy, don't set an auto-set an initial option/
		if (!!value && shouldSetDefault.current === true) {
			shouldSetDefault.current = false
		}
	})

	// If there wasn't a previous dashboard found in local storage, set to the default.
	useEffect(() => {
		if (
			!!user &&
			!value &&
			shouldSetDefault.current === true &&
			userCanViewDashboard(defaultDashboard, user)
		) {
			onChange(defaultDashboard)
		}
	}, [onChange, value, user])

	return (
		<Select
			label="Dashboard"
			{...rest}
			value={value}
			options={options}
			onChange={(_value, option) => onChange(option?.value ?? null)}
			textAccessor="value"
			valueAccessor="value"
			showNoSelectionOption={false}
			sortOptions={false}
		/>
	)
}

interface DashboardOption {
	value: DashboardName
}

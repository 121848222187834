import { FC } from "react"

import { Column } from "react-table"

import {
	NegativeOnHandReportLine,
	NegativeOnHandReportQueryParams,
	useExportNegativeOnHandReport,
	useNegativeOnHandReport,
} from "@ncs/ncs-api"
import { formatNumber, Typify } from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	LocationQueryFilter,
	RegionQueryFilter,
	Table,
	TerritoryQueryFilter,
	usePaginationUrlState,
	useUrlState,
} from "@ncs/web-legos"

export type NegativeOnHandReportUrlState = Typify<NegativeOnHandReportQueryParams>

export const NegativeOnHandReport: FC = () => {
	const [queryParams, { setUrlState: setQueryParams }] =
		useUrlState<NegativeOnHandReportUrlState>(initialNegativeOnHandReportUrlState)
	const [pagination, setPagination] = usePaginationUrlState()

	const query = useNegativeOnHandReport({
		params: { ...queryParams, ...pagination },
		manualPagination: true,
	})
	const dataExport = useExportNegativeOnHandReport(queryParams)

	return (
		<AnimatedEntrance show>
			<Table
				query={query}
				columns={columns}
				queryParamState={queryParams}
				setQueryParamState={setQueryParams}
				pagination={pagination}
				setPagination={setPagination}
				dataExport={dataExport}
				pinnedQueryFilters={[LocationQueryFilter, TerritoryQueryFilter]}
				toggledQueryFilters={[RegionQueryFilter]}
				storeColumnVisibility="negative-on-hand-report-columns"
			/>
		</AnimatedEntrance>
	)
}

const columns: Column<NegativeOnHandReportLine>[] = [
	{
		Header: "Part #",
		disableSortBy: true,
		accessor: ({ inventory }) => inventory.part.partNumber,
	},
	{
		Header: "Description",
		disableSortBy: true,
		accessor: ({ inventory }) => inventory.part.description,
	},
	{
		Header: "Quantity",
		id: "qty",
		accessor: ({ qty }) => formatNumber(qty),
	},
	{
		Header: "Location",
		id: "bin_Location_LocationCode",
		accessor: ({ bin }) => `(${bin.location.locationCode}) ${bin.location.description}`,
	},
	{
		Header: "Bin",
		disableSortBy: true,
		hiddenByDefault: true,
		accessor: ({ bin }) => bin.code,
	},
]

const initialNegativeOnHandReportUrlState: NegativeOnHandReportUrlState = {
	ordering: null,
	territory: null,
	region: null,
	location: null,
}

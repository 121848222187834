import dayjs from "dayjs"
import { DeepPartial } from "react-hook-form"
import { z } from "zod"

import { GenericLineItem, PurchaseOrderPost, PurchaseOrderPostLineItem } from "@ncs/ncs-api"

export const CreatePurchaseOrderFormSchema = z.object({
	shipToCustomerId: z.string().min(1, "Required"),
	billToCustomerId: z.string().min(1, "Required"),
	vendorId: z.string().min(1, "Required"),
	shipMethodId: z.string(),
	createAndOrder: z.boolean(),
	comments: z.string().optional().nullable(),
	purchaseOrderNumber: z.string().max(100).optional().nullable(),
})

export type CreatePurchaseOrderForm = z.infer<typeof CreatePurchaseOrderFormSchema>

export const defaultPurchaseOrderFormValues: DeepPartial<CreatePurchaseOrderForm> = {
	shipToCustomerId: "",
	billToCustomerId: "",
	vendorId: "", // Must be set on the VendorSelectorFormField if you want a default.
	createAndOrder: false,
}

export const assemblePurchaseOrderPost = (
	formData: CreatePurchaseOrderForm,
	lineItems: GenericLineItem[],
	pricing: {
		orderSubtotal: number
		orderTaxes: number
		orderTotal: number
	}
): PurchaseOrderPost => {
	const purchaseOrderPost: PurchaseOrderPost = {
		orderDate: dayjs().toISOString(),
		comments: formData.comments ?? null,
		shipToCustomerId: formData.shipToCustomerId,
		billToCustomerId: formData.billToCustomerId,
		purchaseOrderNumber: formData.purchaseOrderNumber ?? null,
		shipMethodId: formData.shipMethodId,
		vendorId: formData.vendorId,
		lineItems: lineItems.map((line) => lineItemToPurchaseOrderPostLineItem(line)),
		subTotal: pricing.orderSubtotal.toFixed(2),
		taxTotal: pricing.orderTaxes.toFixed(2),
		total: pricing.orderTotal.toFixed(2),
		createAndOrder: formData.createAndOrder,
	}

	return purchaseOrderPost
}

/** Map `GenericLineItem` to `PurchaseOrderPostLineItem`. */
export const lineItemToPurchaseOrderPostLineItem = (
	line: GenericLineItem
): PurchaseOrderPostLineItem => {
	const unitTax = line.finalPrice * line.taxRate

	const result: PurchaseOrderPostLineItem = {
		lineTypeId: line.lineTypeId,
		partId: line.part?.id ?? null,
		description: line.description,
		netPrice: line.finalPrice.toFixed(4),
		unitPrice: line.finalPrice.toFixed(4),
		unitTax: unitTax.toFixed(7),
		taxRateUsed: line.taxRate.toFixed(7),
		lineTotal: ((line.finalPrice + unitTax) * line.quantity).toFixed(7),
		quantity: line.quantity,
	}

	return result
}

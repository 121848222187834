import { FC, useEffect } from "react"

import { Prompt, PromptProps } from "react-router-dom"

import { useClientVersionContext } from "@ncs/ncs-api"

/**
 * Uses React Router DOM to provide a confirmation message when user navigates away
 * within the app. Uses the beforeunload browser event if they try to navigate outside
 * of the app.
 */
export const ConfirmExitIntent: FC<PromptProps> = ({
	when = true,
	message = "Are you sure you want to leave?",
}) => {
	const [{ clientStaleSince }] = useClientVersionContext()

	useEffect(() => {
		const eventListener = (event: BeforeUnloadEvent): string | void => {
			// Don't prevent refresh if client's been marked stale.
			if (when && !clientStaleSince) {
				// Prompts the generic "Are you sure?" window.
				const returnValue = event.preventDefault()

				// Handle legacy `event.returnValue` property
				// https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
				if (typeof returnValue === "string") {
					return (event.returnValue = returnValue)
				}

				// Chrome doesn't support `event.preventDefault()` on `BeforeUnloadEvent`,
				// instead it requires `event.returnValue` to be set
				// https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload#browser_compatibility
				if (event.defaultPrevented) {
					return (event.returnValue = "")
				}
			}
		}

		window.addEventListener("beforeunload", eventListener)

		return () => {
			window.removeEventListener("beforeunload", eventListener)
		}
	}, [when, clientStaleSince])

	return <Prompt when={when} message={message} />
}

import React, { Component } from "react"

import TicketDocumentUpload from "./TicketDocumentUpload"
import moment from "moment"
import { doFileUpload } from "@ncs/mortar/redux/services/ticketing"
import { withCallApi } from "~/components"

//custom components
import { GridContainer, GridItem } from "~/components"

class TicketDocumentUploadContainer extends Component {
	constructor(props) {
		super(props)
		this.fileInputRef = React.createRef()

		this.state = {
			file: null,
			documentTitle: "",
			isSaving: false,
			showSuccessfulUpload: false,
			showFailedUpload: false,
			effectiveDate: moment().format(),
			expirationDate: null,
			isAdding: false,
		}
	}
	handleFileSelected = (f) => {
		this.setState({ file: f[0] })
	}

	handleSubmit = () => {
		this.setState({ isSaving: true })
		if (!this.state.file) {
			this.setState({ isSaving: false })
			return
		}
		if (this.state.isSaving === false) {
			this.doUpload()
		}
		this.setState({ isSaving: true })
		// this.setState({ isSaving: true }, () => this.doUpload())
		return
	}

	doUpload = () => {
		let ticket = null
		let comment = null
		if (this.props.isComment) {
			comment = this.props.relatedId
		}
		if (this.props.isTicket) {
			ticket = this.props.relatedId
		}
		let file = {
			file: this.state.file,
			title: this.state.file.name,
			effectiveDate: this.state.effectiveDate,
			expirationDate: this.state.expirationDate,
			comment: comment,
			ticket: ticket,
		}
		this.props.callApi(doFileUpload(file)).then((result) => {
			this.setState({
				isSaving: false,
				showSuccessfulUpload: !result.error,
				showFailedUpload: !!result.error,
			})
			this.props.onDocumentUploaded(result.payload)
		})
		this.setState({ isSaving: false })
	}

	handleCloseSuccessModal = () => {
		this.setState({ showSuccessfulUpload: false, isAdding: false })
		this.props.onSuccess()
	}

	handleCloseFailedUploadModal = () => {
		this.setState({ showFailedUpload: false, isAdding: false })
	}
	handleAddDocument = () => {
		this.setState({ isAdding: true })
	}

	handleCancel = () => {
		this.setState({ isAdding: false, file: null })
	}

	render() {
		return (
			<GridContainer>
				<GridItem xs={12}>
					<TicketDocumentUpload
						isSaving={this.state.isSaving}
						title={this.state.documentTitle}
						cost={this.state.cost}
						isOrderable={this.state.isOrderable}
						expirationDate={this.state.expirationDate}
						effectiveDate={this.state.effectiveDate}
						onEffectiveDateSelected={this.handleEffectiveDateUpdated}
						onExpirationDateSelected={this.handleExpirationDateUpdated}
						onFileSelected={this.handleFileSelected}
						onSubmit={this.handleSubmit}
						onTitleChanged={this.handleTitleChanged}
						showSuccessfulUpload={this.state.showSuccessfulUpload}
						onCloseSuccessModal={this.handleCloseSuccessModal}
						onBackToList={this.handleBackToList}
						showFailedUpload={this.state.showFailedUpload}
						onCloseFailedUploadModal={this.handleCloseFailedUploadModal}
						isAdding={this.state.isAdding}
						fileInputRef={this.fileInputRef}
						onStartUploadFile={this.handleAddDocument}
						onCancelUploadFile={this.handleCancel}
					/>
				</GridItem>
			</GridContainer>
		)
	}
}

TicketDocumentUploadContainer.defaultProps = {
	isTicket: false,
	isComment: false,
	onSuccess: () => {},
}

export default withCallApi(TicketDocumentUploadContainer)

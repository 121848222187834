import { FC, useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { Controller, useForm } from "react-hook-form"

import {
	makeApiErrorMessage,
	ManufacturedPart,
	useCreateManufacturedPart,
	useDeleteManufacturedPart,
	useUpdateManufacturedPart,
} from "@ncs/ncs-api"
import {
	CheckboxFormField,
	ConfirmationModal,
	ConfirmationModalConfig,
	Divider,
	ExtendableModalProps,
	InventoryPartSelectorFormField,
	LocationSelectorFormField,
	Modal,
	NumericInputFormField,
	PartProductTypeSelector,
	TextInputFormField,
	useChangeCallback,
	useToast,
} from "@ncs/web-legos"

import {
	defaultManufacturedPartFormValues,
	makeDeleteManufacturedPartConfirmMessage,
	ManufacturedPartForm,
	ManufacturedPartFormSchema,
} from "../manufactured-parts-util"

export interface ManufacturedPartModalProps extends ExtendableModalProps {
	part: ManufacturedPart | null
}

export const ManufacturedPartModal: FC<ManufacturedPartModalProps> = ({ part, ...rest }) => {
	const { makeSuccessToast, makeErrorToast } = useToast()
	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)

	const createPart = useCreateManufacturedPart()
	const updatePart = useUpdateManufacturedPart()
	const deletePart = useDeleteManufacturedPart()

	const { control, reset, handleSubmit } = useForm<ManufacturedPartForm>({
		resolver: zodResolver(ManufacturedPartFormSchema),
		defaultValues: defaultManufacturedPartFormValues,
	})

	const handleSave = async (form: ManufacturedPartForm) => {
		try {
			setIsSaving(true)
			if (isEdit) {
				await updatePart({
					id: part.id,
					updates: {
						batchGroup: form.batchGroup,
						obs: form.isObsolete,
						packageSize: form.packageSize,
						productType: form.productTypeId,
						staticAdu: form.staticAdu,
					},
				})
				makeSuccessToast("Manufactured part updated")
			} else {
				await createPart({
					batchGroup: form.batchGroup,
					obs: form.isObsolete,
					location: form.locationId,
					packageSize: form.packageSize,
					part: form.partId,
					productType: form.productTypeId,
					staticAdu: form.staticAdu,
				})
				makeSuccessToast("Manufactured part created")
			}
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const handleDelete = (toDelete: ManufacturedPart) => {
		setConfirmationConfig({
			title: "Delete Manufactured Part",
			message: makeDeleteManufacturedPartConfirmMessage(toDelete),
			onConfirm: async () => {
				try {
					await deletePart(toDelete.id)
					makeSuccessToast("Manufactured part deleted")
					rest.onClose()
				} catch (e) {
					makeErrorToast(makeApiErrorMessage(e))
				}
			},
		})
	}

	useChangeCallback(
		part,
		(newPart) => {
			if (newPart) {
				reset({
					partId: newPart.part.id,
					productTypeId: newPart.productType.id,
					locationId: newPart.location.id,
					batchGroup: newPart.batchGroup,
					packageSize: newPart.packageSize,
					staticAdu: newPart.staticAdu,
					isObsolete: newPart.isObsolete,
				})
			}
		},
		{ callOnSetup: true }
	)

	const isEdit = !!part

	return (
		<Modal
			{...rest}
			title={isEdit ? "Edit Manufactured Part" : "Add Manufactured Part"}
			errorText={errorText}
			leftButtons={
				isEdit ?
					{
						variant: "text",
						icon: "trash-alt",
						buttonText: "Delete",
						onClick: () => handleDelete(part),
					}
				:	undefined
			}
			rightButtons={{
				buttonText: isEdit ? "Save changes" : "Create",
				onClick: handleSubmit(handleSave),
				isLoading: isSaving,
			}}
		>
			<LocationSelectorFormField
				control={control}
				name="locationId"
				disabled={isEdit}
				emptyValueFallback=""
			/>
			<InventoryPartSelectorFormField
				control={control}
				name="partId"
				disabled={isEdit}
				emptyValueFallback=""
			/>
			<Controller
				control={control}
				name="productTypeId"
				render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => {
					return (
						<PartProductTypeSelector
							value={value}
							onChange={onChange}
							onBlur={onBlur}
							error={error?.message}
							fillContainer
						/>
					)
				}}
			/>
			<TextInputFormField
				control={control}
				name="batchGroup"
				label="Batch group"
				returnEmptyString
			/>
			<TextInputFormField
				control={control}
				name="packageSize"
				label="Package size"
				returnEmptyString
			/>
			<NumericInputFormField
				control={control}
				name="staticAdu"
				label="Static ADU"
				emptyValueFallback={null}
			/>
			<Divider mb={0.75} />
			<CheckboxFormField control={control} name="isObsolete" label="Mark part as obsolete" />

			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</Modal>
	)
}

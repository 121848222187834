import { FC, useState } from "react"

import dayjs from "dayjs"

import {
	Customer,
	FreightException,
	makeApiErrorMessage,
	useCreateFreightException,
	useDeleteFreightException,
	useUpdateFreightException,
} from "@ncs/ncs-api"
import {
	Box,
	ConfirmationModal,
	ConfirmationModalConfig,
	CustomerSelector,
	DateInput,
	ExtendableModalProps,
	Label,
	Modal,
	NumericInput,
	Paragraph,
	RadioBoolean,
	useIsSaving,
	useToast,
} from "@ncs/web-legos"

export interface EditExceptionModalProps extends ExtendableModalProps {
	toEdit: FreightException | null
}

export const EditExceptionModal: FC<EditExceptionModalProps> = ({ toEdit, ...rest }) => {
	const { makeSuccessToast } = useToast()
	const [customer, setCustomer] = useState<Customer | null>(null)
	const [startDate, setStartDate] = useState(() => (toEdit ? dayjs(toEdit.startDate) : null))
	const [endDate, setEndDate] = useState(() => (toEdit ? dayjs(toEdit.endDate) : null))
	const [orderCountPerMonth, setOrderCountPerMonth] = useState(
		toEdit?.orderCountPerMonth ?? null
	)
	const [allOrders, setAllOrders] = useState(toEdit?.allOrders ?? false)
	const { isSaving, setSaving, endSaving } = useIsSaving()
	const [errorText, setErrorText] = useState<string | null>(null)
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)

	const createException = useCreateFreightException()
	const updateException = useUpdateFreightException()
	const deleteException = useDeleteFreightException()

	const handleSave = async () => {
		try {
			if (!startDate || !endDate || (!allOrders && orderCountPerMonth == null)) {
				throw new Error("All fields are required")
			}
			if (endDate.isBefore(startDate)) {
				throw new Error("End date must come before start date")
			}

			setSaving()

			const data = {
				startDate: startDate.toISOString(),
				endDate: endDate.toISOString(),
				orderCountPerMonth: allOrders ? null : orderCountPerMonth,
				allOrders,
			}

			if (toEdit) {
				await updateException({
					id: toEdit.id,
					updates: data,
				})
				makeSuccessToast("Customer exception updated")
			} else {
				if (!customer) {
					throw new Error("Select a customer")
				}
				await createException({
					...data,
					customerId: customer.id,
				})
				makeSuccessToast("Customer exception created")
			}
			rest.onClose()
		} catch (e) {
			endSaving()
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const handleDelete = (id: number) => {
		setConfirmationConfig({
			title: "Delete Customer Exception",
			message: "Confirm: Delete this customer exception?",
			onConfirm: async () => {
				try {
					await deleteException(id.toString())
					rest.onClose()
				} catch (e) {
					setErrorText(makeApiErrorMessage(e))
				}
			},
		})
	}

	return (
		<Modal
			{...rest}
			title={toEdit ? "Edit Customer Exception" : "New Customer Exception"}
			errorText={errorText}
			rightButtons={{
				buttonText: toEdit ? "Save changes" : "Create",
				isLoading: isSaving(),
				onClick: handleSave,
			}}
			leftButtons={
				toEdit ?
					{
						buttonText: "Delete",
						icon: "trash-alt",
						variant: "text",
						onClick: () => handleDelete(toEdit.id),
					}
				:	undefined
			}
		>
			<Box mb={1.5}>
				{toEdit ?
					<>
						<Label>Customer</Label>
						<Paragraph>
							({toEdit.customerNumber}) {toEdit.customerName}
						</Paragraph>
					</>
				:	<CustomerSelector
						value={customer}
						onChange={setCustomer}
						accountActive={null}
						mb={0}
					/>
				}
			</Box>

			<RadioBoolean
				htmlName="all-orders"
				description="Free freight for set number of orders per month or for all orders?"
				value={allOrders}
				onChange={setAllOrders}
				noFirst
				yesText="Free freight for all orders"
				noText="Free freight on set number of orders"
			/>
			{!allOrders && (
				<NumericInput
					value={orderCountPerMonth}
					onChange={(n) => setOrderCountPerMonth(n ?? null)}
					label="Free freight orders per month"
					decimalScale={0}
				/>
			)}
			<Box d="flex" gap={1}>
				<DateInput
					value={startDate}
					onChange={setStartDate}
					label="Start date"
					disabled={!!(toEdit && dayjs().isAfter(toEdit.startDate))}
					disablePast
				/>
				<DateInput value={endDate} onChange={setEndDate} label="End date" disablePast />
			</Box>

			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</Modal>
	)
}

import { Dispatch, ReactElement, SetStateAction } from "react"

import { PosPartActiveSelector } from "../selectors"

export interface PosPartActiveQueryParams {
	active: boolean | null
}

export interface PosPartActiveQueryFilterProps<QueryParamState extends PosPartActiveQueryParams> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

export const PosPartActiveQueryFilter = <QueryParamState extends PosPartActiveQueryParams>({
	queryParamState,
	setQueryParamState,
}: PosPartActiveQueryFilterProps<QueryParamState>): ReactElement => {
	const handleChange = (newValue: string | null) => {
		setQueryParamState((prev) => ({
			...prev,
			active:
				newValue === "active" ? true
				: newValue === "inactive" ? false
				: null,
		}))
	}

	return (
		<PosPartActiveSelector
			value={
				queryParamState.active ? "active"
				: queryParamState.active === false ?
					"inactive"
				:	null
			}
			onChange={handleChange}
			fillContainer
			disableNoSelectionOption={false}
		/>
	)
}

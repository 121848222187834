import { FC, useMemo, useState } from "react"

import {
	makeApiErrorMessage,
	OpenFulfillmentPart,
	OverstockedPart,
	useAddOpenFulfillmentPart,
	useDeleteOpenFulfillmentPart,
	useInventoryLocation,
} from "@ncs/ncs-api"
import { ExtendableModalProps, Modal, Paragraph, RadioGroup, useToast } from "@ncs/web-legos"

export interface OpenFulfillmentModalProps extends ExtendableModalProps {
	part: OverstockedPart | null
	locationId: string
	openFulfillmentParts: OpenFulfillmentPart[]
}

export const OpenFulfillmentModal: FC<OpenFulfillmentModalProps> = ({
	part,
	locationId,
	openFulfillmentParts,
	onClose,
	...rest
}) => {
	const { makeSuccessToast } = useToast()
	const addOpenPart = useAddOpenFulfillmentPart()
	const removeOpenPart = useDeleteOpenFulfillmentPart()
	const [location] = useInventoryLocation(locationId)

	const [markedLocal, setMarkedLocal] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)
	const [isSaving, setIsSaving] = useState(false)

	// If the part is marked, there should be a corresponding Open Fulfillment Part.
	// We need the ID from it in order to delete the open part and make it not marked anymore.
	const openPartToRemove = useMemo(
		() => openFulfillmentParts.find((p) => p.part?.id === part?.partId.toString()),
		[openFulfillmentParts, part?.partId]
	)

	const markedOriginally = !!part?.marked

	const handleSave = async () => {
		if (part) {
			if (markedLocal && !markedOriginally) {
				try {
					setIsSaving(true)
					await addOpenPart({ partId: part.partId, locationId })
					onClose()
					makeSuccessToast("Part added to open fulfillment")
				} catch (e) {
					setErrorText(makeApiErrorMessage(e))
					setIsSaving(false)
				}
			} else if (markedOriginally && !markedLocal) {
				if (!openPartToRemove) {
					setErrorText(
						"No existing open fulfillment found to remove. Please contact support"
					)
				} else {
					try {
						setIsSaving(true)
						await removeOpenPart(openPartToRemove.id)
						onClose()
						makeSuccessToast("Part removed from open fulfillment")
					} catch (e) {
						setErrorText(makeApiErrorMessage(e))
						setIsSaving(false)
					}
				}
			} else {
				// If nothing's changing, just close the modal.
				onClose()
			}
		}
	}

	const onOpen = () => {
		setMarkedLocal(markedOriginally)
		setErrorText(null)
		setIsSaving(false)
	}

	return (
		<Modal
			{...rest}
			onOpen={onOpen}
			onClose={onClose}
			title="Open Fulfillment"
			rightButtons={[
				{
					buttonText: "Save",
					onClick: handleSave,
					isLoading: isSaving,
				},
			]}
			errorText={errorText}
		>
			<Paragraph>
				Should overstocked quantities of <strong>{part?.partDescription}</strong> at{" "}
				<strong>{location?.description}</strong> be available to fulfill other orders?
			</Paragraph>
			<RadioGroup
				value={markedLocal ? "yes" : "no"}
				onChange={(newValue) => setMarkedLocal(newValue === "yes")}
				htmlName="open-fulfillment"
				options={[
					{
						value: "no",
						label: "No",
					},
					{
						value: "yes",
						label: "Yes",
					},
				]}
			/>
		</Modal>
	)
}

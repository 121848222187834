import React from "react"

import withStyles from "@material-ui/core/styles/withStyles"
import get from "lodash/get"
import moment from "moment"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { bindActionCreators } from "redux"

import { getRouteDetails, updateCustomerRoute } from "@ncs/mortar/redux/services/customers"
import callApi from "@ncs/bricks/redux/services/callApi"
import { formatDate } from "@ncs/bricks/util/formatters"

import {
	Button,
	ButtonWithIcon,
	Checkbox,
	ConditionalContent,
	CustomerSelector,
	DynamicTable,
	GridContainer,
	GridItem,
	Input,
	InputAsDisplay,
	LoadingWrapper,
	QuickCard,
	UserSelector,
} from "~/components"

import Add from "@material-ui/icons/Add"
import Cancel from "@material-ui/icons/Cancel"
import ChevronLeft from "@material-ui/icons/ChevronLeft"
import Clear from "@material-ui/icons/Clear"
import Remove from "@material-ui/icons/DeleteForever"
import Edit from "@material-ui/icons/Edit"
import LocalShipping from "@material-ui/icons/LocalShipping"
import Save from "@material-ui/icons/Save"

const styles = {
	right: {
		textAlign: "right",
	},
}

class RouteDetail extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			route: null,
			isEditing: false,
			isSaving: false,
			addCustomer: false,
			newRouteName: "",
			assignedEmployee: null,
			isActive: true,
			newStops: [],
		}
	}

	resetState = () => {
		this.setState({
			route: null,
			isEditing: false,
			isSaving: false,
			addCustomer: false,
			newRouteName: "",
			assignedEmployee: null,
			isActive: true,
			newStops: [],
		})
	}

	getRouteNumberFromUrlParams = (props = this.props) => props.match.params.route_id

	componentDidMount = () => {
		this.fetchRouteDetails()
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		let newOrderNumber = this.getRouteNumberFromUrlParams()
		if (this.getRouteNumberFromUrlParams(prevProps) !== newOrderNumber) {
			this.refreshDetails()
		}
	}

	refreshDetails = () => {
		// reset state then call fetch details
		this.resetState()
		this.fetchRouteDetails()
	}

	fetchRouteDetails = () => {
		this.setState({ isLoading: true })

		let routeId = this.getRouteNumberFromUrlParams()

		this.props.callApi(getRouteDetails(routeId)).then(({ payload: route }) => {
			this.setState({
				route: route,
				isLoading: false,
			})
		})
	}

	updateIsActive = () => {
		this.setState({ isActive: !this.state.isActive })
	}

	updateRouteName = (e) => {
		this.setState({ newRouteName: e.target.value })
	}

	addCustomer = () => {
		this.setState({
			addCustomer: true,
		})
	}

	cancelAddCustomer = () => {
		this.setState({ addCustomer: false })
	}

	handleCustomerSelect = (customer) => {
		let add = this.state.newStops.filter(
			(stop) => stop.customer._id === customer._id.toString()
		)
		if (add.length === 0) {
			this.setState({
				newStops: [{ id: -1, customer: customer }, ...this.state.newStops],
				addCustomer: false,
			})
		} else {
			this.setState({
				addCustomer: false,
			})
		}
	}

	editRoute = () => {
		this.setState({
			isEditing: true,
			newRouteName: this.state.route.name,
			isActive: this.state.route.is_active,
			assignedEmployee: this.state.route.assigned_employee?._id,
			newStops: this.state.route?.route_location ?? [],
		})
	}

	updateUserSelected = (assignedEmployee) => {
		if (assignedEmployee !== null) {
			this.setState({ assignedEmployee: assignedEmployee._id })
		} else {
			this.setState({ assignedEmployee: null })
		}
	}

	cancelEditingCustomer = () => {
		this.setState({ isEditing: false })
	}

	handleUpdateRoute = () => {
		this.setState({ isSaving: true })
		let params = {
			assigned_employee_id: this.state.assignedEmployee,
			name: this.state.newRouteName,
			is_active: this.state.isActive,
			route_location: this.state.newStops,
		}
		this.props.callApi(updateCustomerRoute(this.state.route.id, params)).then((result) => {
			if (result.error) {
				this.setState({ isSaving: false })
				console.error(result.error)
				return
			}
			this.refreshDetails()
		})
	}

	handleRemoveRoute = (route) => {
		this.setState({
			newStops: this.state.newStops.filter(
				(stop) => stop.customer._id !== route.customer._id.toString()
			),
		})
	}

	handleBackToList = () => {
		this.props.history.push("/customer/routes")
	}

	editColumns = [
		{
			Header: "Customer Number",
			accessor: "customer.customer_number",
			sortable: true,
			toggleable: false,
		},
		{
			Header: "Name",
			accessor: "customer.name",
			sortable: true,
			toggleable: true,
		},
		{
			Header: "",
			Cell: (x) => (
				<Button
					justIcon
					round
					simple
					className="edit"
					color="danger"
					onClick={(e) => {
						e.stopPropagation()
						this.handleRemoveRoute(x.original)
					}}
				>
					<Remove />
				</Button>
			),
			accessor: "actions",
			sortable: false,
			toggleable: false,
			maxWidth: 50,
		},
	]

	columns = [
		{
			Header: "Customer Number",
			accessor: "customer.customer_number",
			sortable: true,
			toggleable: false,
		},
		{
			Header: "Name",
			accessor: "customer.name",
			sortable: true,
			toggleable: true,
		},
	]

	render() {
		const {
			isLoading,
			route,
			newRouteName,
			isActive,
			newStops,
			isEditing,
			isSaving,
			addCustomer,
		} = this.state

		return (
			<React.Fragment>
				<LoadingWrapper isLoading={isLoading} />
				<GridItem xs={12}>
					<ButtonWithIcon
						content="Back to List"
						onClick={this.handleBackToList}
						color="white"
						size="sm"
						round
						icon={<ChevronLeft />}
					/>
				</GridItem>
				<QuickCard title="Route Details" icon={<LocalShipping />}>
					<GridContainer>
						<GridItem xs={12} sm={6}>
							{isEditing ?
								<Input
									labelText={"Route"}
									value={newRouteName}
									onChange={this.updateRouteName}
								/>
							:	<InputAsDisplay labelText="Route" value={get(route, "name", "")} />}
						</GridItem>
						<GridItem xs={12} sm={6}>
							{isEditing ?
								<UserSelector
									defaultValue={this.state.route.assigned_employee}
									onUserSelected={this.updateUserSelected}
									labelText={"Assigned Employee"}
								/>
							:	<InputAsDisplay
									labelText="Assigned Employee"
									value={get(route, "assigned_employee.name", "")}
								/>
							}
						</GridItem>
						<GridItem xs={12} sm={4}>
							<InputAsDisplay
								labelText="Date Created"
								value={route ? formatDate(moment(route.created)) : ""}
							/>
						</GridItem>
						<GridItem xs={12} sm={4}>
							<InputAsDisplay
								labelText="Date Last Modified"
								value={route ? formatDate(moment(route.last_modified)) : ""}
							/>
						</GridItem>
						<GridItem xs={12} sm={4}>
							{isEditing ?
								<Checkbox
									labelText="Is Active"
									checked={isActive}
									onClick={this.updateIsActive}
								/>
							:	<InputAsDisplay
									labelText="Is Active"
									value={
										route ?
											route.is_active ?
												"True"
											:	"False"
										:	""
									}
								/>
							}
						</GridItem>
						<ConditionalContent show={addCustomer}>
							<GridItem xs={12}>
								<CustomerSelector
									labelText="Customer"
									onCustomerSelected={this.handleCustomerSelect}
								/>
							</GridItem>
						</ConditionalContent>

						{isEditing ?
							<GridItem xs={12} align="right">
								<ButtonWithIcon
									content="Cancel Edit"
									onClick={this.cancelEditingCustomer}
									color="warning"
									size="sm"
									round
									disabled={isSaving}
									icon={<Cancel />}
								/>
								{addCustomer ?
									<ButtonWithIcon
										content="Cancel Add Customer"
										onClick={this.cancelAddCustomer}
										color="info"
										size="sm"
										round
										disabled={isSaving}
										icon={<Clear />}
									/>
								:	<ButtonWithIcon
										content="Add Customer"
										onClick={this.addCustomer}
										color="primary"
										size="sm"
										round
										disabled={isSaving}
										icon={<Add />}
									/>
								}
								<ButtonWithIcon
									content="Update Route"
									onClick={this.handleUpdateRoute}
									color="success"
									size="sm"
									round
									disabled={isSaving}
									loading={isSaving}
									icon={<Save />}
								/>
							</GridItem>
						:	<GridItem xs={12} align="right">
								<ButtonWithIcon
									content="Edit Route"
									onClick={this.editRoute}
									color="primary"
									size="sm"
									round
									disabled={isSaving}
									icon={<Edit />}
								/>
							</GridItem>
						}
					</GridContainer>
					<ConditionalContent show={isEditing}>
						<DynamicTable
							loading={isLoading}
							data={newStops}
							columns={this.editColumns}
							useClientSideDataProcessing={true}
							useClientSidePaging={false}
							showPaginationTop={false}
							showPaginationBottom={false}
						/>
					</ConditionalContent>
					<ConditionalContent hide={isEditing}>
						<DynamicTable
							loading={isLoading}
							data={route?.route_location ?? []}
							columns={this.columns}
							useClientSideDataProcessing={true}
							useClientSidePaging={false}
							showPaginationTop={false}
							showPaginationBottom={false}
						/>
					</ConditionalContent>
				</QuickCard>
			</React.Fragment>
		)
	}
}

const mapStateToProps = (state) => {
	return {}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			callApi,
		},
		dispatch
	)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(withStyles(styles)(RouteDetail)))

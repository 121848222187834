import { FC, MouseEvent, useState } from "react"

import { css, Theme } from "@emotion/react"
import { Popover } from "@material-ui/core"
import { transparentize } from "polished"

import { IconButton } from "../buttons"
import { Box } from "../layout"
import { Icon, IconFamily, IconName } from "../typography"

export interface ContextMenuProps {
	icon?: IconName
	iconFamily?: IconFamily
	menuItems: ({
		label: string
		onClick: () => void
		iconName?: IconName
		iconFamily?: IconFamily
		disabled?: boolean
		hidden?: boolean
	} | null)[]
}

export const ContextMenu: FC<ContextMenuProps> = ({
	icon = "ellipsis-v",
	iconFamily = "regular",
	menuItems,
}) => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

	return (
		<Box>
			<IconButton
				icon={icon}
				family={iconFamily}
				onClick={(e: MouseEvent<HTMLButtonElement>) => {
					e.stopPropagation()
					setAnchorEl(e.currentTarget)
				}}
				color="primary"
			/>

			<Popover
				open={!!anchorEl}
				anchorEl={anchorEl}
				onClose={() => {
					setAnchorEl(null)
				}}
				anchorOrigin={{
					horizontal: "right",
					vertical: "top",
				}}
				transformOrigin={{
					horizontal: "right",
					vertical: "top",
				}}
			>
				<ul css={menuCss}>
					{menuItems.map((menuItem) => {
						if (!menuItem || menuItem.hidden) {
							return null
						}

						return (
							<li key={menuItem.label}>
								<button
									type="button"
									onClick={(e) => {
										e.stopPropagation()
										menuItem.onClick()

										// Close the menu
										setAnchorEl(null)
									}}
									disabled={menuItem.disabled}
								>
									{!!menuItem.iconName && (
										<Box display="inline-block" pr={0.5}>
											<Icon
												icon={menuItem.iconName}
												family={menuItem.iconFamily}
												fixedWidth
											/>
										</Box>
									)}
									{menuItem.label}
								</button>
							</li>
						)
					})}
				</ul>
			</Popover>
		</Box>
	)
}

const menuCss = (theme: Theme) => css`
	padding: 0.5rem 0;
	margin: 0;
	li {
		list-style: none;
		border-bottom: 1px solid #eee;
		&:last-of-type {
			border: none;
		}
		button {
			width: 100%;
			min-width: 10rem;
			text-align: left;
			padding: 0.75rem 1rem;
			background: none;
			border: none;
			&:hover:not(:disabled) {
				background: ${transparentize(0.9, theme.palette.primary.main)};
			}
			&:disabled {
				cursor: not-allowed;
			}
		}
	}
`

import { FC } from "react"

import { css } from "@emotion/react"
import { zodResolver } from "@hookform/resolvers/zod"
import { FormProvider, useForm } from "react-hook-form"

import { Box } from "@ncs/web-legos"

import { CreateDispatchForm, CustomerDetails, RecentDispatches } from "./components"
import {
	CreateDispatchForm as CreateDispatchFormType,
	CreateDispatchFormSchema,
	defaultCreateDispatchFormValues,
} from "./util"

export const CreateDispatchTab: FC = () => {
	const form = useForm<CreateDispatchFormType>({
		resolver: zodResolver(CreateDispatchFormSchema),
		defaultValues: defaultCreateDispatchFormValues,
	})

	return (
		<FormProvider {...form}>
			<Box d="flex" gap={2} mdProps={{ flexDirection: "column" }}>
				<Box flex={1}>
					<div css={formContainerCss}>
						<CreateDispatchForm />
					</div>
				</Box>
				<Box d="flex" flexDirection="column" flex={1} mt={0.75} rowGap={1}>
					<CustomerDetails />
					<RecentDispatches />
				</Box>
			</Box>
		</FormProvider>
	)
}

const formContainerCss = css`
	position: sticky;
	top: 1rem;
`

import { FC, ReactElement, useMemo } from "react"

import { Column } from "react-table"

import {
	createDocumentToken,
	getDocumentUrlForToken,
	InvoiceSource,
	InvoiceSourceQueryParams,
	useExportInvoices,
	useInvoiceSources,
} from "@ncs/ncs-api"
import {
	DateFormat,
	displayDateTime,
	formatCurrency,
	getTimeAgoStartDate,
	getTimezoneAbbreviation,
	TimeAgo,
	Typify,
} from "@ncs/ts-utils"
import {
	CustomerQueryFilter,
	EmptyValueDash,
	encodeUrlState,
	EnterpriseQueryFilter,
	InvoiceStatusQueryFilter,
	InvoiceTypeQueryFilter as DefaultInvoiceTypeQueryFilter,
	InvoiceTypeQueryFilterProps,
	OrganizationQueryFilter,
	RegionQueryFilter,
	SearchQueryFilter,
	Table,
	TerritoryQueryFilter,
	TimeAgoQueryFilter,
	useUrlState,
} from "@ncs/web-legos"

import { DispatchDetailTab } from "~/views/Service/Dispatches/DispatchDetail"

export type ViewInvoicesTabUrlState = Typify<InvoiceSourceQueryParams>

export const ViewInvoicesTab: FC = () => {
	const [params, { setUrlState }] = useUrlState(defaultUrlState)

	const query = useInvoiceSources({ params })

	const handleRowClick = async (invoice: InvoiceSource) => {
		if (invoice.invoiceType === "Dispatch") {
			window.open(
				`/service/dispatches/${invoice.dispatch?.id}${encodeUrlState({
					tab: DispatchDetailTab.Invoice,
				})}`
			)
		} else if (invoice.invoiceType === "Delivery Order") {
			// This might not work anymore?
			window.open(`/delivery-order/${invoice.deliveryOrderId}`)
		} else if (invoice.invoiceType === "Parts Order") {
			window.open(`/part-orders/${invoice.partOrderId}`)
		} else if (invoice.invoiceType === "KBM Invoice" && invoice.document) {
			const { token } = await createDocumentToken(
				invoice.document.documentId,
				invoice.document.documentType
			)
			window.open(getDocumentUrlForToken(token))
		}
	}

	const showMoreStartsOpen = useMemo(() => {
		const keysToCheck: (keyof InvoiceSourceQueryParams)[] = [
			"region",
			"territory",
			"enterprise",
			"organization",
			"customer",
			"invoiceType",
			"status",
		]

		return keysToCheck.some((key) => params[key] !== defaultUrlState[key])
	}, [params])

	const exportInvoices = useExportInvoices(params)

	return (
		<>
			<Table
				query={query}
				columns={columns}
				dataExport={exportInvoices}
				queryParamState={params}
				setQueryParamState={setUrlState}
				pinnedQueryFilters={[SearchQueryFilter, TimeAgoQueryFilter]}
				toggledQueryFilters={[
					RegionQueryFilter,
					TerritoryQueryFilter,
					EnterpriseQueryFilter,
					OrganizationQueryFilter,
					CustomerQueryFilter,
					InvoiceTypeQueryFilter,
					InvoiceStatusQueryFilter,
				]}
				onRowClick={({ original }) => {
					void handleRowClick(original)
				}}
				filterResetValues={defaultUrlState}
				showToggledFiltersByDefault={showMoreStartsOpen}
			/>
		</>
	)
}

const columns: Column<InvoiceSource>[] = [
	{
		Header: "Invoice #",
		id: "invoiceNumber",
		accessor: "invoiceNumber",
		disableSortBy: true,
	},
	{
		Header: "Type",
		id: "invoiceType",
		accessor: "invoiceType",
		disableSortBy: true,
	},
	{
		Header: `Work completed (${getTimezoneAbbreviation()})`,
		id: "workCompletedDate",
		accessor: ({ workCompletedDate }) =>
			displayDateTime(workCompletedDate, "") || <EmptyValueDash />,
	},
	{
		Header: "Customer",
		id: "customer_CustomerNumber",
		accessor: ({ customer }) => `(${customer.customerNumber}) ${customer.name}`,
	},
	{
		Header: "Bill-to",
		id: "billToCustomer_CustomerNumber",
		hiddenByDefault: true,
		accessor: ({ billToCustomer }) =>
			billToCustomer ?
				`(${billToCustomer.customerNumber}) ${billToCustomer.name}`
			:	<EmptyValueDash />,
	},
	{
		Header: "Territory",
		id: "customer_Territory_Code",
		accessor: ({ customer }) =>
			customer.territory ?
				`(${customer.territory.code}) ${customer.territory.description}`
			:	<EmptyValueDash />,
	},
	{
		Header: "Total $",
		id: "total",
		accessor: ({ total }) => formatCurrency(total),
	},
	{
		Header: "Status",
		id: "statusCode",
		accessor: ({ statusCode }) => statusCode,
		disableSortBy: true,
	},
	{
		Header: "PO #",
		id: "dispatch_PoTicket",
		hiddenByDefault: true,
		accessor: ({ dispatch }) => dispatch?.poTicket || <EmptyValueDash />,
	},
	{
		Header: `Invoice date (${getTimezoneAbbreviation()})`,
		id: "invoiceDate",
		hiddenByDefault: true,
		accessor: ({ invoiceDate }) => displayDateTime(invoiceDate, "") || <EmptyValueDash />,
	},
]

const defaultUrlState: ViewInvoicesTabUrlState = {
	ordering: null,
	search: null,
	startDate: getTimeAgoStartDate(TimeAgo.MonthsAgo6).format(DateFormat.DateQueryParam),
	endDate: null,
	region: null,
	territory: null,
	enterprise: null,
	organization: null,
	customer: null,
	invoiceType: null,
	status: null,
	billToCustomer: null,
	includeZeroDollar: null,
	hasDocuments: null,
}

const InvoiceTypeQueryFilter = (
	props: InvoiceTypeQueryFilterProps<ViewInvoicesTabUrlState>
): ReactElement => {
	return <DefaultInvoiceTypeQueryFilter {...props} paramKey="invoiceType" />
}

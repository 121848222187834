import React, { HTMLAttributes, useMemo } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { makeStyles } from "@material-ui/core"

import { MaterialDashboardProColors } from "../../util/types"
import styles from "../../assets/jss/material-dashboard-pro-react/components/cardStyle"

const useStyles = makeStyles(styles)

export interface CardProps extends HTMLAttributes<HTMLDivElement> {
	className?: string
	plain?: boolean
	profile?: boolean
	blog?: boolean
	raised?: boolean
	background?: boolean
	pricing?: boolean
	testimonial?: boolean
	color?: MaterialDashboardProColors
	product?: boolean
	chart?: boolean
	login?: boolean
}

const Card: React.FC<CardProps> = ({
	className,
	children,
	plain,
	profile,
	blog,
	raised,
	background,
	pricing,
	color,
	product,
	testimonial,
	chart,
	login,
	...rest
}) => {
	const classes = useStyles()

	const cardClasses = useMemo(
		() =>
			classNames(className, {
				[classes.card]: true,
				[classes.cardPlain]: plain,
				[classes.cardProfile]: profile || testimonial,
				[classes.cardBlog]: blog,
				[classes.cardRaised]: raised,
				[classes.cardBackground]: background,
				[classes.cardPricingColor]:
					(pricing && color !== undefined) || (pricing && background !== undefined),
				[classes[color as MaterialDashboardProColors]]: color,
				[classes.cardPricing]: pricing,
				[classes.cardProduct]: product,
				[classes.cardChart]: chart,
				[classes.cardLogin]: login,
			}),
		[
			background,
			blog,
			chart,
			className,
			classes,
			color,
			login,
			plain,
			pricing,
			product,
			profile,
			raised,
			testimonial,
		]
	)

	return (
		<div className={cardClasses} {...rest}>
			{children}
		</div>
	)
}

Card.propTypes = {
	className: PropTypes.string,
	plain: PropTypes.bool,
	profile: PropTypes.bool,
	blog: PropTypes.bool,
	raised: PropTypes.bool,
	background: PropTypes.bool,
	pricing: PropTypes.bool,
	testimonial: PropTypes.bool,
	color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger", "rose", "gray"]),
	product: PropTypes.bool,
	chart: PropTypes.bool,
	login: PropTypes.bool,
	children: PropTypes.node,
}

export default Card

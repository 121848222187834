import { FC, useMemo, useState } from "react"

import dayjs from "dayjs"

import {
	APPLICATION,
	Customer,
	FreightClassId,
	FreightMin,
	makeApiErrorMessage,
	useCreateFreightMin,
	useUpdateFreightMin,
	useUserCanUse,
} from "@ncs/ncs-api"
import { isEnumMember } from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	Box,
	CustomerSelector,
	DateInput,
	ExtendableModalProps,
	Label,
	Modal,
	NumericInput,
	Paragraph,
	RadioGroup,
	useIsSaving,
	useToast,
} from "@ncs/web-legos"

import { FreightClassSelector } from "~/components"

export interface EditFreightMinModalProps extends ExtendableModalProps {
	toEdit: FreightMin | null
}

export const EditFreightMinModal: FC<EditFreightMinModalProps> = ({ toEdit, ...rest }) => {
	const canEditAny = useUserCanUse(APPLICATION.FreightRatesAdmin)
	const canEditCustomer = useUserCanUse(APPLICATION.CustomerFreightExceptions)
	const { makeSuccessToast } = useToast()
	const [amount, setAmount] = useState(toEdit?.amount ?? null)
	const [startDate, setStartDate] = useState(toEdit?.startDate ? dayjs(toEdit.startDate) : null)
	const [endDate, setEndDate] = useState(toEdit?.endDate ? dayjs(toEdit.endDate) : null)
	const [minFor, setMinFor] = useState<"customer" | "class" | null>(
		toEdit?.customerId ? "customer"
		: toEdit?.freightClassId ? "class"
		: null
	)
	const [customer, setCustomer] = useState<Customer | null>(null)
	const [freightClass, setFreightClass] = useState<FreightClassId | null>(() => {
		const freightId = toEdit?.freightClassId?.toString()
		if (isEnumMember(freightId, FreightClassId)) {
			return freightId
		}

		return null
	})
	const { isSaving, setSaving, endSaving } = useIsSaving()
	const [errorText, setErrorText] = useState<string | null>(null)

	const create = useCreateFreightMin()
	const update = useUpdateFreightMin()

	const handleSave = async () => {
		try {
			if (amount == null || !startDate || !endDate || !minFor) {
				throw new Error("All fields are required")
			}
			if (endDate.isBefore(startDate)) {
				throw new Error("End date must come after start date")
			}

			setSaving()

			if (toEdit) {
				if (minFor === "customer" && toEdit.customerId) {
					await update({
						updates: {
							amount: amount !== toEdit.amount ? amount : undefined,
							startDate:
								startDate.isSame(toEdit.startDate) ? undefined : (
									startDate.toISOString()
								),
							endDate:
								endDate.isSame(toEdit.endDate) ? undefined : endDate.toISOString(),
							rateId: toEdit.id,
							customerId: toEdit.customerId.toString(),
						},
					})
				} else if (minFor === "class" && freightClass) {
					await update({
						updates: {
							amount: amount !== toEdit.amount ? amount : undefined,
							startDate:
								startDate.isSame(toEdit.startDate) ? undefined : (
									startDate.toISOString()
								),
							endDate:
								endDate.isSame(toEdit.endDate) ? undefined : endDate.toISOString(),
							rateId: toEdit.id,
							freightClassId: freightClass,
						},
					})
				}
				makeSuccessToast("Freight min updated")
			} else {
				if (minFor === "customer") {
					if (!customer) {
						throw new Error("Select a customer")
					}
					await create({
						amount,
						startDate: startDate.toISOString(),
						endDate: endDate.toISOString(),
						customerId: customer.id,
					})
				} else if (minFor === "class") {
					if (!freightClass) {
						throw new Error("Select a freight class")
					}
					await create({
						amount,
						startDate: startDate.toISOString(),
						endDate: endDate.toISOString(),
						freightClassId: freightClass,
					})
				}
				makeSuccessToast("Freight min created")
			}
			rest.onClose()
		} catch (e) {
			endSaving()
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const canOnlyEditCustomers = useMemo(
		() => !canEditAny && canEditCustomer,
		[canEditAny, canEditCustomer]
	)
	const disallowEditing = useMemo(() => {
		return canOnlyEditCustomers && !!toEdit?.freightClassId
	}, [canOnlyEditCustomers, toEdit?.freightClassId])

	return (
		<Modal
			{...rest}
			title={toEdit ? "Edit Freight Minimum" : "New Freight Minimum"}
			rightButtons={
				disallowEditing ? undefined : (
					{
						buttonText: toEdit ? "Save changes" : "Create",
						onClick: handleSave,
						isLoading: isSaving(),
					}
				)
			}
			errorText={errorText}
		>
			<NumericInput
				value={amount}
				onChange={(v) => setAmount(v ?? null)}
				label="Amount $"
				decimalScale={2}
				fixedDecimalScale
				disabled={disallowEditing}
			/>

			<Box d="flex" gap={1} smProps={{ flexDirection: "column" }} mb={1}>
				<DateInput
					value={startDate}
					onChange={setStartDate}
					label="Effective date"
					disablePast
					disabled={disallowEditing}
				/>
				<DateInput
					value={endDate}
					onChange={setEndDate}
					label="End date"
					disablePast
					disabled={disallowEditing}
				/>
			</Box>

			{toEdit ?
				<>
					<Label>For</Label>
					<Paragraph>
						{!!toEdit.customerId &&
							`(${toEdit.customerNumber}) ${toEdit.customerName}`}
						{!!toEdit.freightClassName && `${toEdit.freightClassName} freight class`}
					</Paragraph>
				</>
			:	<>
					<RadioGroup
						description="Is this minimum for a customer or a freight class?"
						htmlName="min-for"
						value={minFor}
						onChange={(value, option) => setMinFor(option.value)}
						disabledAccessor="disabled"
						options={[
							{
								value: "customer" as const,
								label: "Customer",
								disabled: false,
							},
							{
								value: "class" as const,
								label:
									canOnlyEditCustomers ?
										"Freight class (missing required permission)"
									:	"Freight class",
								disabled: canOnlyEditCustomers,
							},
						]}
					/>

					<AnimatedEntrance show={minFor === "customer"}>
						<CustomerSelector
							value={customer}
							onChange={setCustomer}
							accountActive={null}
						/>
					</AnimatedEntrance>
					<AnimatedEntrance show={minFor === "class"}>
						<FreightClassSelector
							value={freightClass}
							onChange={setFreightClass}
							fillContainer
						/>
					</AnimatedEntrance>
				</>
			}
		</Modal>
	)
}

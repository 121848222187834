import { FC } from "react"

import sortBy from "lodash/sortBy"
import { useHistory } from "react-router-dom"

import {
	ConnectedDevice,
	ConnectedDevicesAtSite,
	getGenericDeviceStatusSortingPriority,
} from "@ncs/ncs-api"
import { titleCase } from "@ncs/ts-utils"
import {
	Box,
	ExtendableModalProps,
	Heading,
	Modal,
	ModalActionRows,
	Paragraph,
	Pipe,
	StatusBlip,
} from "@ncs/web-legos"

import { getConnectivityDeviceDetailUrl } from "~/util"

import { DevicesTabUrlState } from "../devices-tab"

export interface ChooseDeviceModalProps extends ExtendableModalProps {
	devicesAtSite: ConnectedDevicesAtSite
	devicesUrlState: DevicesTabUrlState
}

export const ChooseDeviceModal: FC<ChooseDeviceModalProps> = ({
	devicesAtSite: { customer, devices },
	devicesUrlState,
	...rest
}) => {
	const history = useHistory()

	const handleDeviceClick = (device: ConnectedDevice) => {
		const url = getConnectivityDeviceDetailUrl(device)

		if (url) {
			history.push({
				pathname: url,
				state: { devicesUrlState },
			})
		}
	}

	return (
		<Modal
			{...rest}
			title="Select Device"
			titleDetail={`(${customer.number}) ${customer.name}`}
		>
			<ModalActionRows
				rows={sortBy(devices, (row) => getGenericDeviceStatusSortingPriority(row.state))}
				renderRow={(row) => (
					<Box key={row.id} d="flex" flexDirection="column" gap={0.25}>
						<Heading>{row.name}</Heading>
						<Paragraph secondary>
							{row.equipment && (
								<>
									{row.equipment.vintage || "(No vintage information)"}
									{!!row.equipment.serialNumber && (
										<>
											<Pipe />
											Serial # {row.equipment.serialNumber}
										</>
									)}
								</>
							)}
						</Paragraph>
						<Box d="flex" gap={0.5} alignItems="center">
							<StatusBlip status={row.state} />
							<Paragraph small secondary>
								Status: {titleCase(row.state)}
							</Paragraph>
						</Box>
					</Box>
				)}
				rowKeyAccessor={(row) => row.id}
				onRowClick={handleDeviceClick}
			/>
		</Modal>
	)
}

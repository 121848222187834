import { FC } from "react"

import { Column } from "react-table"

import { EquipmentVintage } from "@ncs/ncs-api"
import { EmptyValueDash, ExtendableModalProps, Heading, Modal, Table } from "@ncs/web-legos"

export interface VintageDocumentsModalProps extends ExtendableModalProps {
	vintage: EquipmentVintage
}

export const VintageDocumentsModal: FC<VintageDocumentsModalProps> = ({ vintage, ...rest }) => {
	return (
		<Modal {...rest} title="Vintage Documents" maxWidth="md">
			<Heading mt={1} mb={2}>
				Vintage: <strong>{vintage.name}</strong>
			</Heading>

			<Table
				data={vintage.documents}
				columns={columns}
				disableAllSorting
				noDataText="No documents found"
			/>
		</Modal>
	)
}

const columns: Column<EquipmentVintage["documents"][number]>[] = [
	{
		Header: "Document #",
		accessor: ({ documentNumber }) => documentNumber || <EmptyValueDash />,
	},
	{
		Header: "File name",
		accessor: ({ filename }) => filename || <EmptyValueDash />,
	},
	{
		Header: "Description",
		accessor: ({ description }) => description || <EmptyValueDash />,
	},
]

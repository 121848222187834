import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"

import { listCustomerSites, handleCustomerSelected } from "../../redux/services/customers"

import { Select } from "@ncs/bricks/components"

function CustomerSiteSelector({ onCustomerSiteSelected, onChange, value, ...rest }) {
	const dispatch = useDispatch()
	const isLoading = useSelector((x) => x.customers.isLoadingCustomerSites)
	const customerSites = useSelector((x) => x.customers.customerSites)
	const selectedCustomer = useSelector((x) => x.customers.selectedCustomer)

	const siteOptions =
		isLoading ?
			[{ _id: "-1", name: "Loading Sites...", disabled: true }]
		:	[{ _id: "-1", name: "All Sites", customer_number: "" }, ...customerSites]

	useEffect(() => {
		dispatch(listCustomerSites())
	}, [dispatch])

	const handleUpdateSelectedCustomer = (selectedCustomer) => {
		dispatch(handleCustomerSelected({ _id: selectedCustomer }))

		if (typeof onChange === "function") {
			onChange(selectedCustomer)
		}

		if (typeof onCustomerSiteSelected === "function") {
			let customer = (customerSites ?? []).find((x) => x._id === selectedCustomer)
			onCustomerSiteSelected(customer)
		}
	}

	return (
		<Select
			id="sites"
			labelText="Site"
			onChange={handleUpdateSelectedCustomer}
			value={selectedCustomer || "-1"}
			options={siteOptions}
			valueAccessor={(item) => item._id}
			textAccessor={(item) =>
				item.customer_number ? `${item.name} (${item.customer_number})` : item.name
			}
			{...rest}
		/>
	)
}

CustomerSiteSelector.propTypes = {
	/** callback that receives the selected customer, or null if all sites is selected **/
	onCustomerSiteSelected: PropTypes.func,
}

export default CustomerSiteSelector

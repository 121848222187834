import { FC } from "react"

import { Cell, Column } from "react-table"

import {
	SiteSurveyReportLine,
	SiteSurveyReportQueryParams,
	useExportSiteSurveyReport,
	useSiteSurveyReport,
} from "@ncs/ncs-api"
import {
	formatDateTime,
	getStartAndEndDateQueryParams,
	getTimezoneAbbreviation,
	TimeAgo,
	Typify,
} from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	CustomerQueryFilter,
	EnterpriseQueryFilter,
	Icon,
	Link,
	OrganizationQueryFilter,
	SiteSurveyStatusQueryFilter,
	Table,
	TerritoryQueryFilter,
	TimeAgoQueryFilter,
	usePaginationUrlState,
	useUrlState,
} from "@ncs/web-legos"

export type SiteSurveyReportUrlState = Typify<SiteSurveyReportQueryParams>

export const SiteSurveyReport: FC = () => {
	const [queryParams, { setUrlState: setQueryParams }] = useUrlState<SiteSurveyReportUrlState>(
		initialSiteSurveyReportUrlState
	)
	const [pagination, setPagination] = usePaginationUrlState()

	const query = useSiteSurveyReport({
		params: { ...queryParams, ...pagination },
		manualPagination: true,
	})
	const dataExport = useExportSiteSurveyReport(queryParams)

	return (
		<AnimatedEntrance show>
			<Table
				query={query}
				columns={columns}
				queryParamState={queryParams}
				setQueryParamState={setQueryParams}
				pagination={pagination}
				setPagination={setPagination}
				dataExport={dataExport}
				pinnedQueryFilters={[
					TimeAgoQueryFilter,
					CustomerQueryFilter,
					TerritoryQueryFilter,
					SiteSurveyStatusQueryFilter,
				]}
				toggledQueryFilters={[OrganizationQueryFilter, EnterpriseQueryFilter]}
				filterResetValues={initialSiteSurveyReportUrlState}
				storeColumnVisibility="site-survey-report-columns"
			/>
		</AnimatedEntrance>
	)
}

const columns: Column<SiteSurveyReportLine>[] = [
	{
		Header: "Customer",
		id: "customer_CustomerNumber",
		accessor: ({ customer }) => customer?.customerNumber,
		Cell: ({ row: { original } }: Cell<SiteSurveyReportLine>) => {
			return original.customer ?
					<Link
						to={`/customer-management/${original.customer.id}`}
						newTab
						icon="external-link"
					>
						({original.customer.customerNumber}) {original.customer.name}
					</Link>
				:	"-"
		},
	},
	{
		Header: "Name",
		accessor: ({ customer }) => customer?.name || "-",
	},
	{
		Header: `Created (${getTimezoneAbbreviation()})`,
		accessor: ({ createdDate }) => formatDateTime(createdDate),
	},
	{
		Header: `Assigned (${getTimezoneAbbreviation()})`,
		hiddenByDefault: true,
		accessor: ({ assignedDate }) => (assignedDate ? formatDateTime(assignedDate) : "-"),
	},
	{
		Header: "Requested by",
		hiddenByDefault: true,
		accessor: ({ requestedBy }) => requestedBy.name,
	},
	{
		Header: "Assigned to",
		hiddenByDefault: true,
		accessor: ({ assignedTo }) => assignedTo.name,
	},
	{
		Header: "Submitted",
		accessor: "isSubmitted",
		Cell: ({ row: { original } }) =>
			original.isSubmitted ?
				<>
					<Icon icon="check" color="gray" /> Submitted
				</>
			:	"No",
	},
]

const initialSiteSurveyReportUrlState: SiteSurveyReportUrlState = {
	ordering: null,
	organization: null,
	enterprise: null,
	territory: null,
	customer: null,
	startDate: getStartAndEndDateQueryParams(TimeAgo.DaysAgo30)[0],
	endDate: null,
	isSubmitted: null,
}

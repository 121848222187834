import { isRSAA } from "redux-api-middleware"

const isApiRequest = (action) => action?.hasOwnProperty("types") === true || isRSAA(action)

const asyncDispatchMiddleware = (store) => (next) => (action) => {
	// don't touch api requests
	if (isApiRequest(action)) {
		return next(action)
	}

	let syncActivityFinished = false
	let actionQueue = []

	function flushQueue() {
		actionQueue.forEach((a) => store.dispatch(a))
		actionQueue = []
	}

	function asyncDispatch(asyncAction) {
		actionQueue = actionQueue.concat([asyncAction])

		if (syncActivityFinished) {
			flushQueue()
		}
	}

	let actionWithAsyncDispatch = Object.assign({}, action, { asyncDispatch })
	let result = next(actionWithAsyncDispatch)
	syncActivityFinished = true
	flushQueue()
	return result
}

export default asyncDispatchMiddleware

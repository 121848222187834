import { ReactElement, useMemo } from "react"

import { TerritoryMinimal, useTerritories } from "@ncs/ncs-api"
import { ReactStateDispatch } from "@ncs/web-legos"

import { TerritoryMultiSelect } from "../multi-selects"

interface MultiTerritoryParamState {
	territories: string[] | null
}

export interface MultiTerritoryQueryFilterProps<QueryParamState extends MultiTerritoryParamState> {
	queryParamState: QueryParamState
	setQueryParamState: ReactStateDispatch<QueryParamState>
}

export const MultiTerritoryQueryFilter = <QueryParamState extends MultiTerritoryParamState>({
	queryParamState,
	setQueryParamState,
}: MultiTerritoryQueryFilterProps<QueryParamState>): ReactElement => {
	const [territories] = useTerritories()

	const handleChange = (newList: TerritoryMinimal[]) => {
		setQueryParamState((prev) => {
			const newState: QueryParamState = {
				...prev,
				territories: newList.length ? newList.map((l) => l.id) : null,
			}

			return newState
		})
	}

	const values = useMemo(() => {
		const result: TerritoryMinimal[] = []

		queryParamState.territories?.forEach((territoryId) => {
			const match = territories?.find((t) => t.id === territoryId)

			if (match) {
				result.push(match)
			}
		})

		return result
	}, [queryParamState.territories, territories])

	return <TerritoryMultiSelect values={values} onChange={handleChange} />
}

import { FC, useMemo, useState } from "react"

import { css } from "@emotion/react"

import {
	createDocumentToken,
	getDocumentUrlForToken,
	makeApiErrorMessage,
	PartOrderLineItem,
	PartOrderPickList,
	PickListStatus,
	useGetPickListDocumentById,
	usePartOrderPickLists,
	useUserCanUse,
	APPLICATION,
} from "@ncs/ncs-api"
import { formatNumber } from "@ncs/ts-utils"
import {
	Box,
	Button,
	Divider,
	Heading,
	Icon,
	LoadingSpinner,
	Paragraph,
	Pipe,
	Tooltip,
	useScreenSizeMatch,
	useToast,
} from "@ncs/web-legos"

import {
	FulfillmentSources,
	getLocationPickables,
	getLocationShortage,
	inactivePickListStatuses,
	PickListByLocation,
	pickListStatusOrder,
} from "../../part-order-detail-util"
import { GeneratePickListModal, PartOrderPickListModal } from "./components"

export interface PartOrderDetailPickListsProps {
	partOrderId: string
	partOrderOrderId: number | null
	partOrderLoading: boolean
	partOrderOnHold: boolean
	isShipComplete: boolean
	fulfillmentLocations: FulfillmentSources["locations"]
	orderLineItems: PartOrderLineItem[]
	paymentNotComplete: boolean
}

export const PartOrderDetailPickLists: FC<PartOrderDetailPickListsProps> = ({
	partOrderId,
	partOrderOrderId,
	partOrderLoading,
	partOrderOnHold,
	isShipComplete,
	fulfillmentLocations,
	orderLineItems,
	paymentNotComplete,
}) => {
	const screenIsXs = useScreenSizeMatch("xs")
	const { makeErrorToast } = useToast()
	const [showAll, setShowAll] = useState(false)
	const [loadingDocIds, setLoadingDocIds] = useState<string[]>([])

	const [pickListsRaw, pickListsLoading] = usePartOrderPickLists(partOrderId)
	const getPickListDocument = useGetPickListDocumentById()
	const canDownloadPickListPdf = useUserCanUse(APPLICATION.PickListDownloader)

	const [modalPickListId, setModalPickListId] = useState<string | null>(null)
	const pickListForModal = useMemo(() => {
		return (pickListsRaw ?? []).find((list) => modalPickListId === list.id)
	}, [pickListsRaw, modalPickListId])
	const [generateModalLocation, setGenerateModalLocation] = useState<
		PartOrderPickList["location"] | null
	>(null)

	const handleDownload = async (pickListId: string) => {
		try {
			setLoadingDocIds((prev) => [...prev, pickListId])
			const document = await getPickListDocument(pickListId)
			if (document) {
				const { token } = await createDocumentToken(document.id, document.documentType)
				window.open(getDocumentUrlForToken(token), "_blank")
			} else {
				throw Error("There was an error downloading this document")
			}
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		} finally {
			setLoadingDocIds((prev) => prev.filter((id) => id !== pickListId))
		}
	}

	const pickListsByLocation = useMemo(() => {
		const result: PickListByLocation = {}
		const pickLists = pickListsRaw ?? []

		if (partOrderLoading) {
			// Fulfillment locations will be empty until the part order is returned, which comes in
			// after the pick lists result is already in.
			return result
		}

		// First we'll go through the locations found on the line items and calculate if any of
		// them could have pick lists generated for them.
		Object.values(fulfillmentLocations).forEach((location) => {
			result[location.locationId] = {
				location: {
					id: location.locationId,
					locationCode: location.locationNumber,
					description: location.locationName,
				},
				pickLists: [],
				pickableLineLocations: getLocationPickables(location.locationId, orderLineItems),
			}
		})

		// Now go through the pick lists and add/update the locations found there.
		pickLists.forEach((pickList) => {
			result[pickList.location.id] = {
				pickableLineLocations: result[pickList.location.id]?.pickableLineLocations ?? [],
				location: result[pickList.location.id]?.location ?? {
					id: pickList.id,
					locationCode: pickList.location.locationCode,
					description: pickList.location.description,
				},
				pickLists: [...(result[pickList.location.id]?.pickLists ?? []), pickList],
			}
		})

		Object.keys(result).forEach((locationId) => {
			result[locationId].pickLists = result[locationId].pickLists.sort((a, b) => {
				if (a.status.description !== b.status.description) {
					return (
							pickListStatusOrder[a.status.description] <
								pickListStatusOrder[b.status.description]
						) ?
							-1
						:	1
				}
				return a.createdDate > b.createdDate ? -1 : 1
			})
		})

		return result
	}, [pickListsRaw, fulfillmentLocations, orderLineItems, partOrderLoading])

	const pickListsToHide = useMemo(() => {
		return Object.values(pickListsByLocation).reduce<string[]>((idList, location) => {
			return [
				...idList,
				...location.pickLists.flatMap((pickList) =>
					inactivePickListStatuses.includes(pickList.status.description) ?
						[pickList.id]
					:	[]
				),
			]
		}, [])
	}, [pickListsByLocation])

	const showPickListSection = useMemo(() => {
		return Object.values(pickListsByLocation).some((location) => {
			return !!(location.pickLists.length || location.pickableLineLocations.length)
		})
	}, [pickListsByLocation])

	const showShipCompleteWarning = useMemo(() => {
		const hasMultipleDcs = Object.keys(fulfillmentLocations).length > 1
		const hasAnyShortage = orderLineItems.some((line) =>
			line.locationLines.some((location) => getLocationShortage(location) !== null)
		)

		return isShipComplete && (hasMultipleDcs || hasAnyShortage)
	}, [fulfillmentLocations, isShipComplete, orderLineItems])

	if (!partOrderLoading && pickListsLoading) {
		return <LoadingSpinner />
	}

	if (!showPickListSection) {
		return null
	}

	return (
		<>
			<Divider my={2} />
			<Heading icon="person-dolly" variant="h2" mb={1.5}>
				Pick Lists
			</Heading>
			<Box ml={1} pl={1.5} pb={0.25}>
				{Object.values(pickListsByLocation)
					.sort((a, b) => (a.location.description > b.location.description ? 1 : -1))
					.map(({ location, pickLists, pickableLineLocations }) => {
						const hasPickableLineLocations = !!pickableLineLocations.length
						const hasActivePickLists = pickLists.some(
							(pickList) =>
								!inactivePickListStatuses.includes(pickList.status.description)
						)

						if (!hasPickableLineLocations && !hasActivePickLists && !showAll) {
							return null
						}

						return (
							<Box
								key={location.id}
								mb={1.5}
								css={css`
									border-top: 1px solid #eee;
									padding-top: 1rem;
									&:first-of-type {
										border-top-width: 0;
										padding-top: 0;
									}
								`}
							>
								<Box display="flex" alignItems="baseline" gap={1}>
									<Heading variant="h4" mb={1}>
										({location.locationCode}) {location.description}
									</Heading>

									{hasActivePickLists &&
										hasPickableLineLocations &&
										!partOrderLoading && (
											<Tooltip title="There are more line items at this location ready to be put on a pick list, but you cannot create a new list while another is in progress. Complete or cancel the current one before generating another.">
												<Box display="flex" alignItems="center" gap={0.5}>
													<Icon icon="info-circle" />
													<Paragraph small>
														More line items pickable
													</Paragraph>
												</Box>
											</Tooltip>
										)}
								</Box>

								{!hasActivePickLists &&
									hasPickableLineLocations &&
									!partOrderLoading && (
										<Box
											display="flex"
											alignItems="center"
											gap={1}
											mb={0.75}
											xsProps={{
												flexDirection: "column",
												alignItems: "flex-start",
											}}
										>
											<Button
												variant="primary-cta"
												onClick={() => setGenerateModalLocation(location)}
												fillContainer={screenIsXs}
												disabled={partOrderOnHold || paymentNotComplete}
											>
												Generate pick list
											</Button>
											<Paragraph small secondary>
												{partOrderOnHold ?
													"Disabled while order is in hold status"
												:	<>
														{pickableLineLocations.length} line item
														{pickableLineLocations.length !== 1 &&
															"s"}{" "}
														pickable
													</>
												}
											</Paragraph>
										</Box>
									)}

								<Box>
									{pickLists
										.filter(
											(pickList) =>
												showAll ||
												!inactivePickListStatuses.includes(
													pickList.status.description
												)
										)
										.map((pickList) => {
											return (
												<Box
													key={pickList.id}
													display="flex"
													gap={1}
													mb={0.75}
													alignItems="center"
													xsProps={{
														flexDirection: "column",
														alignItems: "flex-start",
													}}
												>
													<Button
														fillContainer={screenIsXs}
														variant={
															(
																inactivePickListStatuses.includes(
																	pickList.status.description
																)
															) ?
																"secondary-cta"
															:	"primary-cta"
														}
														onClick={() =>
															setModalPickListId(pickList.id)
														}
													>
														View
													</Button>
													<Paragraph secondary small>
														Status:{" "}
														<strong>
															{pickList.status.description}
														</strong>
														<Pipe />
														Pick List # {pickList.id}
														{pickList.status.description !==
															PickListStatus.Removed && (
															<>
																<Pipe />
																{formatNumber(
																	pickList.items.length
																)}{" "}
																line items
																<Pipe />
																List weight:{" "}
																{formatNumber(
																	Math.round(
																		pickList.items.reduce(
																			(total, item) => {
																				return (
																					(total as number) +
																					item.weight *
																						item.quantity
																				)
																			},
																			0
																		)
																	)
																)}{" "}
																lbs
															</>
														)}
													</Paragraph>
													{pickList.status.description !==
														PickListStatus.Removed &&
														canDownloadPickListPdf && (
															<Button
																icon="file-pdf"
																onClick={() =>
																	handleDownload(pickList.id)
																}
																isLoading={loadingDocIds.includes(
																	pickList.id
																)}
															>
																Download
															</Button>
														)}
												</Box>
											)
										})}
								</Box>
							</Box>
						)
					})}
			</Box>

			{!showAll && pickListsToHide.length > 0 && (
				<Box
					display="flex"
					justifyContent="center"
					mt={
						(
							pickListsToHide.length === pickListsRaw?.length &&
							!screenIsXs &&
							Object.values(pickListsByLocation).every(
								(l) => !l.pickableLineLocations.length
							)
						) ?
							-2.75
						:	undefined
					}
				>
					<Button icon="angle-down" onClick={() => setShowAll(true)}>
						Show all pick lists ({pickListsRaw?.length})
					</Button>
				</Box>
			)}

			{!!pickListForModal && !!partOrderOrderId && (
				<PartOrderPickListModal
					partOrderId={partOrderId}
					partOrderOrderId={partOrderOrderId}
					pickList={pickListForModal}
					onClose={() => setModalPickListId(null)}
				/>
			)}
			{!!generateModalLocation && (
				<GeneratePickListModal
					partOrderId={partOrderId}
					showShipCompleteWarning={showShipCompleteWarning}
					location={generateModalLocation}
					lineItems={orderLineItems}
					onClose={() => setGenerateModalLocation(null)}
				/>
			)}
		</>
	)
}

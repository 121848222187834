import { FC, useEffect, useState } from "react"

import { Dayjs } from "dayjs"

import { makeApiErrorMessage, useCreateFuelSurcharge } from "@ncs/ncs-api"
import {
	Box,
	DateInput,
	ExtendableModalProps,
	Modal,
	NumericInput,
	useIsSaving,
	useToast,
} from "@ncs/web-legos"

export type NewFuelSurchargeModalProps = ExtendableModalProps

export const NewFuelSurchargeModal: FC<NewFuelSurchargeModalProps> = ({ ...rest }) => {
	const { makeSuccessToast } = useToast()
	const { isSaving, setSaving, endSaving } = useIsSaving()
	const [errorText, setErrorText] = useState<string | null>(null)

	const [dieselRate, setDieselRate] = useState<number | null>(null)
	const [gasolineRate, setGasolineRate] = useState<number | null>(null)
	const [endDate, setEndDate] = useState<Dayjs | null>(null)
	const [startDate, setStartDate] = useState<Dayjs | null>(null)

	const createCharge = useCreateFuelSurcharge()

	const handleSave = async () => {
		try {
			setSaving()
			if (!dieselRate || !gasolineRate || !startDate || !endDate) {
				throw new Error("All fields are required")
			}
			if (endDate.isBefore(startDate)) {
				throw new Error("End date must come after the start date")
			}

			await createCharge({
				dieselRate,
				gasolineRate,
				startDate: startDate.toISOString(),
				endDate: endDate.toISOString(),
			})
			rest.onClose()
			makeSuccessToast("Surcharge price created")
		} catch (e) {
			endSaving()
			setErrorText(makeApiErrorMessage(e))
		}
	}

	useEffect(() => {
		setErrorText(null)
	}, [dieselRate, gasolineRate, endDate, startDate])

	return (
		<Modal
			{...rest}
			title="New Fuel Surcharge Price"
			errorText={errorText}
			rightButtons={{
				buttonText: "Create",
				onClick: handleSave,
				isLoading: isSaving(),
			}}
		>
			<NumericInput
				value={gasolineRate}
				onChange={(v) => setGasolineRate(v ?? null)}
				label="Gasoline rate $"
				fixedDecimalScale
				decimalScale={2}
			/>
			<NumericInput
				value={dieselRate}
				onChange={(v) => setDieselRate(v ?? null)}
				label="Diesel rate $"
				fixedDecimalScale
				decimalScale={2}
			/>
			<Box d="flex" gap={1} smProps={{ flexDirection: "column" }}>
				<DateInput
					value={startDate}
					onChange={setStartDate}
					label="Effective date"
					disablePast
				/>
				<DateInput value={endDate} onChange={setEndDate} label="End date" disablePast />
			</Box>
		</Modal>
	)
}

import { FC, useMemo, useState } from "react"

import { Column } from "react-table"

import {
	makeApiErrorMessage,
	UpdateUserProfilePatch,
	UserLocation,
	UserProfile,
	useUpdateCustomerUserLocations,
	useUpdateUserProfile,
	useUserLocations,
} from "@ncs/ncs-api"
import {
	ApplicationGroupSelector,
	Box,
	Button,
	ConfirmationModal,
	ConfirmationModalConfig,
	Heading,
	HeadingDivider,
	LabeledData,
	LoadingSpinner,
	Paragraph,
	RadioBoolean,
	Table,
	TextInput,
	useToast,
} from "@ncs/web-legos"

import { AddSiteModal } from "./AddSiteModal"

export interface CustomerUserPermissionsTabProps {
	user: UserProfile
}

const columns: Column<UserLocation>[] = [
	{
		Header: "Site #",
		accessor: "locationNumber",
	},
	{
		Header: "Name",
		accessor: "locationName",
	},
	{
		Header: "Address",
		accessor: (original) =>
			`${original.customerAddress}, ${original.customerCity}, ${original.customerState} ${original.customerPostalCode}`,
	},
]

export const CustomerUserPermissionsTab: FC<CustomerUserPermissionsTabProps> = ({ user }) => {
	const [siteSearchQuery, setSiteSearchQuery] = useState<string | null>(null)
	const [groupIdFormValue, setGroupIdFormValue] = useState<string | null>(
		user.applicationsGroup?.id.toString() ?? null
	)
	const [isGuestFormValue, setIsGuestFormValue] = useState(user.isGuest)
	const [isSuperFormValue, setIsSuperFormValue] = useState(user.isCustomerSuperuser)
	const [isSaving, setIsSaving] = useState<keyof UpdateUserProfilePatch | null>(null)
	const { makeErrorToast, makeSuccessToast } = useToast()
	const [showAddModal, setShowAddModal] = useState(false)
	const [confirmationModalConfig, setConfirmationModalConfig] =
		useState<ConfirmationModalConfig | null>(null)

	const [locations, locationsLoading] = useUserLocations(user.id.toString())
	const updateUser = useUpdateUserProfile({ queryParams: { isCustomer: true } })
	const updateLocations = useUpdateCustomerUserLocations(user.id.toString())

	const onSave = async <Field extends keyof UpdateUserProfilePatch>(
		field: Field,
		newValue: UpdateUserProfilePatch[Field]
	) => {
		try {
			setIsSaving(field)
			await updateUser({
				id: user.id.toString(),
				updates: { [field]: newValue },
			})
			makeSuccessToast("User updated")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		} finally {
			setIsSaving(null)
		}
	}

	const removeLocation = async (idToRemove: string) => {
		try {
			await updateLocations({
				addCustomerIds: null,
				addBillToIds: null,
				removeCustomerIds: [idToRemove],
			})
			makeSuccessToast("Site removed from user")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		}
	}

	const removeAllLocations = async () => {
		try {
			await updateLocations({
				addCustomerIds: null,
				addBillToIds: null,
				removeCustomerIds: (locations ?? []).map((l) => l.locationId.toString()),
			})
			makeSuccessToast("All sites removed from user")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		}
	}

	const preparedLocations = useMemo(() => {
		return (locations ?? [])
			.filter((site) =>
				Object.values(site)
					.join("")
					.toLowerCase()
					.includes(siteSearchQuery?.toLowerCase() ?? "")
			)
			.sort((a, b) => (a.locationName > b.locationName ? 1 : -1))
	}, [locations, siteSearchQuery])

	const hasSites = (locations ?? []).length > 0
	const isGuest = user.isGuest

	return (
		<>
			<LabeledData
				display="block"
				label="Application group"
				editingRender={() => (
					<div>
						<ApplicationGroupSelector
							value={groupIdFormValue}
							onChange={setGroupIdFormValue}
							label={null}
						/>
					</div>
				)}
				onSaveEdit={() => onSave("applicationAssignmentId", groupIdFormValue)}
				isSavingEdit={isSaving === "applicationAssignmentId"}
			>
				{(user.applicationsGroup?.description || user.applicationsGroup?.id) ?? "None"}
			</LabeledData>

			<LabeledData
				display="block"
				label="Is this a guest user? Guest users cannot have any sites registered with NCS."
				editingRender={() => (
					<RadioBoolean
						htmlName="is-guest"
						value={isGuestFormValue}
						onChange={setIsGuestFormValue}
						noFirst
						noText="No (user is NCS customer)"
						yesText="Yes (user is guest)"
						disabledAccessor={() => (locations ?? []).length > 0}
					/>
				)}
				onSaveEdit={() => onSave("isGuest", isGuestFormValue)}
				isSavingEdit={isSaving === "isGuest"}
			>
				{user.isGuest ? "Yes (user is guest)" : "No (user is NCS customer)"}
			</LabeledData>

			{!user.isGuest && (
				<LabeledData
					display="block"
					label="Is this customer a superuser?"
					editingRender={() => (
						<RadioBoolean
							htmlName="is-superuser"
							description="Customer superusers can create and edit users that have permissions to all their sites. Only set this to Yes if you're sure."
							value={isSuperFormValue}
							onChange={setIsSuperFormValue}
							noFirst
							noText="No (default)"
							yesText="Yes"
						/>
					)}
					onSaveEdit={() => onSave("isCustomerSuperuser", isSuperFormValue)}
					isSavingEdit={isSaving === "isCustomerSuperuser"}
				>
					{user.isCustomerSuperuser ? "Yes" : "No (default)"}
				</LabeledData>
			)}

			{isGuest ?
				<Paragraph color="secondary">
					Guest users do not have sites associated with their account
				</Paragraph>
			:	<>
					<HeadingDivider
						icon="garage-car"
						iconFamily="solid"
						headingVariant="h3"
						mb={2}
					>
						Sites
					</HeadingDivider>

					{locationsLoading && <LoadingSpinner text="Loading sites..." />}

					{!locationsLoading && (
						<Button
							variant="secondary-cta"
							icon="plus-circle"
							onClick={() => setShowAddModal(true)}
							disabled={locationsLoading}
						>
							Add site to user
						</Button>
					)}

					{hasSites && (
						<>
							<Box mt={2}>
								<TextInput
									value={siteSearchQuery}
									onChange={setSiteSearchQuery}
									label="Search"
									fillContainer={false}
									icon="search"
								/>
							</Box>

							<Table
								columns={columns}
								data={preparedLocations}
								noDataText={`No sites match "${siteSearchQuery}"`}
								disableAllSorting
								rowMenu={[
									{
										label: "Remove from user",
										iconName: "trash",
										onClick: ({ original }) => {
											setConfirmationModalConfig({
												title: "Remove Site?",
												message: `Are you sure you want to remove site ${original.locationNumber} from this user?`,
												onConfirm: async () =>
													await removeLocation(
														original.locationId.toString()
													),
											})
										},
									},
									{
										label: "Manage customer site",
										iconName: "external-link",
										onClick: ({ original }) => {
											window.open(
												`/customer-management/${original.locationId}`
											)
										},
									},
								]}
							/>

							<Button
								icon="trash"
								containerProps={{ mt: 2 }}
								onClick={() => {
									setConfirmationModalConfig({
										title: "Remove all sites?",
										message:
											"Are you sure you want to remove ALL sites from this user?",
										onConfirm: async () => await removeAllLocations(),
									})
								}}
							>
								Remove all sites
							</Button>
						</>
					)}

					{!locationsLoading && !hasSites && (
						<Heading opacity={0.5} icon="exclamation-circle" mt={3} mb={1}>
							No sites added to user yet
						</Heading>
					)}
				</>
			}

			<AddSiteModal
				isOpen={showAddModal}
				onClose={() => setShowAddModal(false)}
				user={user}
			/>
			<ConfirmationModal
				config={confirmationModalConfig}
				setConfig={setConfirmationModalConfig}
			/>
		</>
	)
}

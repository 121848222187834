import { FC, memo, useCallback, useMemo, useState } from "react"

import { Cell, Column } from "react-table"

import {
	APPLICATION,
	Customer,
	defaultCustomerQueryParams,
	makeApiErrorMessage,
	PricingMatrix,
	useCustomers,
	useRemoveCustomerFromPricingMatrix,
	useUserCanUse,
} from "@ncs/ncs-api"
import {
	Button,
	ConfirmationModal,
	ConfirmationModalConfig,
	EmptyValueDash,
	getAddressFields,
	Icon,
	SearchQueryFilter,
	Table,
	TableProps,
	useToast,
} from "@ncs/web-legos"

import { EditPricingMatrixCustomerModal } from "./EditPricingMatrixCustomerModal"

export interface PricingMatrixCustomersTabProps {
	matrix: PricingMatrix | null
}

const PricingMatrixCustomersTab: FC<PricingMatrixCustomersTabProps> = ({ matrix }) => {
	const isDiscountAdmin = useUserCanUse(APPLICATION.PartDiscountAdmin)
	const removeCustomer = useRemoveCustomerFromPricingMatrix()
	const { makeErrorToast, makeSuccessToast } = useToast()

	const [queryParams, setQueryParams] = useState({
		...defaultCustomerQueryParams,
		// Also turn off the default customers query params.
		service: null,
		accountActive: null,
		showKbmManaged: null,
	})

	const paramsWithId = {
		...queryParams,
		pricingMatrixId: matrix?.id ?? null,
	}

	const customersQuery = useCustomers({
		params: paramsWithId,
		queryConfig: {
			enabled: !!matrix,
		},
	})

	const [showModal, setShowModal] = useState(false)
	const [confirmModalConfig, setConfirmModalConfig] = useState<ConfirmationModalConfig | null>(
		null
	)

	const onRemove = useCallback(
		async (customerId: string) => {
			try {
				await removeCustomer({
					updates: {
						customerId,
					},
					id: matrix?.id,
				})
				makeSuccessToast("Customer removed from pricing matrix")
			} catch (e) {
				makeErrorToast(makeApiErrorMessage(e))
			}
		},
		[removeCustomer, makeSuccessToast, makeErrorToast, matrix?.id]
	)

	const rowMenu = useMemo((): TableProps<Customer>["rowMenu"] => {
		return isDiscountAdmin ?
				[
					{
						label: "View customer",
						iconName: "external-link",
						onClick: ({ original }) => {
							window.open(`/customer-management/${original.id}`)
						},
					},
					{
						label: "Remove",
						iconName: "trash",
						onClick: ({ original }) =>
							setConfirmModalConfig({
								title: "Remove Customer",
								message: `Remove ${original.name} from pricing matrix ${matrix?.description}?`,
								onConfirm: () => onRemove(original.id),
							}),
					},
				]
			:	undefined
	}, [isDiscountAdmin, onRemove, matrix?.description])

	return (
		<>
			{isDiscountAdmin && (
				<Button
					variant="secondary-cta"
					icon="plus-circle"
					containerProps={{ mb: 2 }}
					onClick={() => setShowModal(true)}
				>
					Add customer to matrix
				</Button>
			)}

			<Table
				query={customersQuery}
				columns={columns}
				queryParamState={queryParams}
				setQueryParamState={setQueryParams}
				noDataText="No customers in this matrix yet"
				toggledQueryFilters={[SearchQueryFilter]}
				rowMenu={rowMenu}
			/>

			{showModal && !!matrix && (
				<EditPricingMatrixCustomerModal
					matrix={matrix}
					onClose={() => setShowModal(false)}
				/>
			)}
			<ConfirmationModal config={confirmModalConfig} setConfig={setConfirmModalConfig} />
		</>
	)
}

const columns: Column<Customer>[] = [
	{
		Header: "Customer #",
		accessor: "customerNumber",
		disableSortBy: true,
	},
	{
		Header: "Customer Name",
		accessor: "name",
		disableSortBy: true,
	},
	{
		Header: "Site Address",
		id: "address",
		Cell: (cell: Cell<Customer>) => {
			return getAddressFields(cell.row.original, { exclude: ["name", "zip"] }).join(", ")
		},
		disableSortBy: true,
	},
	{
		Header: "Is Active Customer?",
		accessor: "isActive",
		disableSortBy: true,
		Cell: (cell: Cell<Customer>) => {
			return cell.row.original.isFstActive ?
					<Icon icon="check" fontSize={0.8} color="gray" />
				:	<EmptyValueDash />
		},
	},
]

export default memo(PricingMatrixCustomersTab)

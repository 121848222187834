import React from "react"

//custom components
import { GridContainer, GridItem, ButtonWithIcon, Input, UserSelector } from "~/components"

// icons
import RemoveIcon from "@material-ui/icons/Remove"
import AddIcon from "@material-ui/icons/Add"

function TicketTypeAdd(props) {
	const {
		isAdding,
		onCancelTypeAdd,
		onSaveType,
		description,
		onDescriptionChange,
		descriptionIsValid,
		descriptionErrorMessage,
		onDaysCompletionChange,
		daysCompletion,
		daysCompletionIsValid,
		daysCompletionErrorMessage,
		setInputRef,
		showValidationErrors,
		onTypeAdd,
		onUserChange,
		user,
	} = props
	return (
		<GridContainer>
			<GridItem hide={isAdding}>
				<ButtonWithIcon
					icon={<AddIcon />}
					content="Add Type"
					size="sm"
					round
					color="success"
					onClick={onTypeAdd}
				/>
			</GridItem>

			<GridItem xs={2} show={isAdding}>
				<ButtonWithIcon
					icon={<RemoveIcon />}
					content="Cancel"
					size="sm"
					round
					color="error"
					onClick={onCancelTypeAdd}
				/>
			</GridItem>
			<GridItem xs={3} show={isAdding}>
				<Input
					labelText={"Description"}
					value={description}
					onChange={onDescriptionChange}
					inputProps={{
						inputProps: { ref: setInputRef },
					}}
					formControlProps={{
						error: showValidationErrors && !descriptionIsValid,
					}}
					helperText={showValidationErrors ? descriptionErrorMessage : ""}
				/>
			</GridItem>
			<GridItem xs={2} show={isAdding}>
				<Input
					type="number"
					labelText={"Def. Days Completion"}
					value={daysCompletion}
					onChange={onDaysCompletionChange}
					inputProps={{
						inputProps: { ref: setInputRef, type: "number" },
					}}
					formControlProps={{
						error: showValidationErrors && !daysCompletionIsValid,
					}}
					helperText={showValidationErrors ? daysCompletionErrorMessage : ""}
				/>
			</GridItem>
			<GridItem xs={3} show={isAdding}>
				<UserSelector value={user} onUserSelected={onUserChange} />
			</GridItem>
			<GridItem xs={2} show={isAdding}>
				<ButtonWithIcon
					icon={<AddIcon />}
					content="Confirm"
					size="sm"
					round
					color="success"
					disabled={!description || !daysCompletion}
					onClick={onSaveType}
				/>
			</GridItem>
		</GridContainer>
	)
}

export default TicketTypeAdd

import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import classNames from "classnames"
import { mdiCalculator, mdiTextSearch } from "@mdi/js"
import Icon from "@mdi/react"
import { makeStyles } from "@material-ui/core"
import { Info, Launch } from "@material-ui/icons"
import { WASH_COUNT_ENTRY_TYPES, WASH_COUNT_SUBMISSION_STATUSES } from "@ncs/mortar/util/constants"
import { loadLookups, LOOKUPS } from "@ncs/mortar/redux/services/lookups"
import {
	postBulkContractWashCounts,
	listContractWashCounts,
} from "@ncs/mortar/redux/services/contractWashCountSubmission"
import { useCallApi, useLocalStorageSyncedState } from "@ncs/bricks/util/hooks"
import { unpythonify } from "@ncs/bricks/util/variableCasing"
import { QuickCard, HeadingWithLine, Tooltip } from "~/components"
import WashCountFilters from "./WashCountFilters"
import SkeletonRows from "./SkeletonRows"
import RowContents from "./RowContents"
import RowCheckbox from "./RowCheckbox"
import SubmissionControls from "./SubmissionControls"
import {
	prepareIncomingWashCounts,
	prepareOutgoingWashCounts,
	washCountReady,
	validateCounts,
	guessWashCountPeriod,
	prepareFilters,
} from "./washCountSubmissionUtils"
import { washCountSubmissionStyles } from "./washCountSubmissionStyles"

const useStyles = makeStyles(washCountSubmissionStyles)

const defaultSyncingFilterState = {
	customer: undefined,
	territory: undefined,
	organization: undefined,
	entryType: WASH_COUNT_ENTRY_TYPES.CUSTOMER_OR_FIELD.value,
}

const [defaultMonth, defaultYear] = guessWashCountPeriod()
const defaultNotSyncingFilterState = {
	month: defaultMonth,
	year: defaultYear,
}

const WashCountSubmission = () => {
	const classes = useStyles()
	const callApi = useCallApi()
	const dispatch = useDispatch()
	const [isLoading, setIsLoading] = useState(false)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [washCounts, setWashCounts] = useState([])
	const [finishedWashCounts, setFinishedWashCounts] = useState([])
	const [lastCheckIndex, setLastCheckIndex] = useState(null)

	const [syncingFilters, setSyncingFilters] = useLocalStorageSyncedState(
		defaultSyncingFilterState
	)
	const [notSyncingFilters, setNotSyncingFilters] = useState(defaultNotSyncingFilterState)

	const filters = useMemo(
		() => ({ ...syncingFilters, ...notSyncingFilters }),
		[syncingFilters, notSyncingFilters]
	)

	const requiredFiltersAreSet = useMemo(
		() => filters.organization || filters.territory || filters.customer,
		[filters]
	)

	useEffect(() => {
		dispatch(loadLookups(LOOKUPS.WashCountComments))
	}, [dispatch])

	// An array of booleans that indicate if the wash count row of the same
	// index should be checked or not.
	const [checkedArray, setCheckedArray] = useState([])

	const fetchWashCounts = useCallback(
		async (filters) => {
			setIsLoading(true)
			const { payload } = await callApi(listContractWashCounts(prepareFilters(filters)))
			setIsLoading(false)

			const awaiting = []
			const finished = []
			unpythonify(payload).forEach((count) =>
				count.invoice === null ? awaiting.push(count) : finished.push(count)
			)

			const preparedCounts = prepareIncomingWashCounts(awaiting)

			setWashCounts(preparedCounts)
			setFinishedWashCounts(finished)
			setCheckedArray(Array(payload.length).fill(false))
		},
		[callApi]
	)

	useEffect(() => {
		// Only fetch wash counts if a customer, org, or territory is selected.
		if (requiredFiltersAreSet) {
			fetchWashCounts(filters)
		} else {
			// If you changed the filters to not include one of those, then things should reset.
			setWashCounts([])
			setFinishedWashCounts([])
			setCheckedArray([])
		}
	}, [fetchWashCounts, filters, requiredFiltersAreSet])

	const onFilterChange = useCallback(
		(updatesToMerge) => {
			// Split the updated filters back into syncing and non-syncing.
			const updatedSyncingFilters = {}
			const updatedNotSyncingFilters = {}

			Object.entries({ ...filters, ...updatesToMerge }).forEach(([key, value]) => {
				if (Object.keys(defaultSyncingFilterState).includes(key)) {
					updatedSyncingFilters[key] = value
				} else {
					updatedNotSyncingFilters[key] = value
				}
			})

			setSyncingFilters(updatedSyncingFilters)
			setNotSyncingFilters(updatedNotSyncingFilters)
		},
		[filters, setSyncingFilters]
	)

	const onCheckboxClick = useCallback(
		(indexClicked, wasShiftClicked, newCheckedState) => {
			if (wasShiftClicked && lastCheckIndex !== null) {
				// Was the last box the user checked ahead or behind?
				const lowerBound = indexClicked < lastCheckIndex ? indexClicked : lastCheckIndex
				const upperBound = indexClicked > lastCheckIndex ? indexClicked : lastCheckIndex

				// Change everything between what was clicked now and the previously clicked box
				// to the destination state of what was clicked.
				setCheckedArray((prev) =>
					prev.map((c, i) =>
						i >= lowerBound && i <= upperBound && washCountReady(washCounts[i]) ?
							newCheckedState
						:	c
					)
				)
			} else {
				// If shift key is not being held, then we simply just flip the one that was clicked.
				setCheckedArray((prev) => {
					const result = [...prev]
					result.splice(indexClicked, 1, newCheckedState)

					return result
				})

				// Store this as the last touched checkbox for use when user shift-clicks next.
				setLastCheckIndex(indexClicked)
			}
		},
		[washCounts, lastCheckIndex]
	)

	const updateWashCount = useCallback(
		(updatedWashCount, indexToChange) => {
			// Update the error message field.
			updatedWashCount.errorMessage = validateCounts(updatedWashCount)

			setWashCounts((prev) =>
				prev.map((count, i) => (i === indexToChange ? updatedWashCount : count))
			)

			// If it's no longer ready, make sure it's not still checked.
			if (!washCountReady(updatedWashCount) && checkedArray[indexToChange]) {
				setCheckedArray((prev) => prev.map((c, i) => (i === indexToChange ? false : c)))
			}
		},
		[checkedArray]
	)

	const bulkCheckChange = useCallback(
		(newState) => {
			if (newState) {
				// If you're clicking to check all, we only check all that are ready.
				setCheckedArray(washCounts.map((washCount) => washCountReady(washCount)))
			} else {
				setCheckedArray(Array(washCounts.length).fill(false))
			}

			// Clear this out after doing a bulk change.
			setLastCheckIndex(null)
		},
		[washCounts]
	)

	const onSubmit = useCallback(async () => {
		setIsSubmitting(true)

		const preparedCounts = prepareOutgoingWashCounts(
			washCounts.filter((washCount, index) => checkedArray[index])
		)

		// Submit the checked wash counts.
		await callApi(postBulkContractWashCounts(preparedCounts))

		setIsSubmitting(false)

		// If you were just submitted, let's change your status to submitted.
		setWashCounts((prev) =>
			prev.map((washCount, index) => {
				return checkedArray[index] ?
						{
							...washCount,
							status: WASH_COUNT_SUBMISSION_STATUSES.SUBMITTED,
						}
					:	washCount
			})
		)

		// Finally, uncheck everything that was checked.
		setCheckedArray(Array(washCounts.length).fill(false))
	}, [checkedArray, washCounts, callApi])

	const readyTally = useMemo(
		() => washCounts.filter((washCounts) => washCountReady(washCounts)).length,
		[washCounts]
	)

	const checkedTally = useMemo(
		() => checkedArray.filter((state) => state).length,
		[checkedArray]
	)

	return (
		<QuickCard
			icon={<Icon path={mdiCalculator} size="24px" />}
			title="Submit Wash Counts"
			headerColor="primary"
		>
			<WashCountFilters
				disabled={isLoading}
				filters={filters}
				onFilterChange={onFilterChange}
			/>

			<HeadingWithLine
				heading={`Invoices Awaiting Approval ${
					!isLoading && requiredFiltersAreSet ? `(${washCounts.length})` : ""
				}`}
			/>

			{!requiredFiltersAreSet && !isLoading && (
				<div className={classes.bigMessage}>
					<Icon path={mdiTextSearch} size="48px" />
					<h4>
						Please select a territory, organization, or customer to view wash counts.
					</h4>
				</div>
			)}

			{requiredFiltersAreSet && !isLoading && washCounts.length === 0 && (
				<div className={classes.bigMessage}>
					<Info />
					<h4>No invoices awaiting approval found.</h4>
				</div>
			)}

			{requiredFiltersAreSet && (washCounts.length > 0 || isLoading) && (
				<>
					<SubmissionControls
						bulkCheckChange={bulkCheckChange}
						checkedTally={checkedTally}
						readyTally={readyTally}
						washCounts={washCounts}
						isSubmitting={isSubmitting}
						isLoading={isLoading}
						onSubmit={onSubmit}
					/>
					<table className={classes.washCountTable}>
						<thead>
							<tr className="header-row">
								<th style={{ textAlign: "center" }}>
									{checkedTally > 0 ? `(${checkedTally})` : ""}
								</th>
								<th>Customer</th>
								<th>Serial #</th>
								<th>Package</th>
								<th>Prev Month Start</th>
								<th>Prev Month End</th>
								<th>Prev Month Count</th>
								<th>Last Dispatch</th>
								<th>
									<Tooltip
										enterDelay={100}
										title="Washes occurring in this billing cycle. Starts pre-filled with the count from the most recent dispatch."
									>
										<>Current Month</>
									</Tooltip>
								</th>
								<th>
									<Tooltip enterDelay={100} title="New grand total of washes.">
										<>Ending Count</>
									</Tooltip>
								</th>
								<th>
									<Tooltip
										enterDelay={100}
										title="Pre-tax cost based on contract package price."
									>
										<>Price</>
									</Tooltip>
								</th>
								<th>Comment</th>
								<th>Status</th>
							</tr>
						</thead>
						<tbody>
							{isLoading && <SkeletonRows />}
							{!isLoading &&
								washCounts.map((washCount, index) => (
									<tr
										key={washCount.id}
										className={classNames(classes.row, {
											[classes.checked]: !!checkedArray[index],
										})}
									>
										<RowCheckbox
											index={index}
											disabled={!washCountReady(washCount)}
											checked={!!checkedArray[index]}
											onCheckboxClick={onCheckboxClick}
											submitted={
												washCount.status.id ===
												WASH_COUNT_SUBMISSION_STATUSES.SUBMITTED.id
											}
										/>
										<RowContents
											index={index}
											washCount={washCount}
											updateParentWashCountState={updateWashCount}
										/>
									</tr>
								))}
						</tbody>
					</table>
					<SubmissionControls
						bulkCheckChange={bulkCheckChange}
						checkedTally={checkedTally}
						readyTally={readyTally}
						washCounts={washCounts}
						isSubmitting={isSubmitting}
						isLoading={isLoading}
						onSubmit={onSubmit}
					/>
				</>
			)}

			<div className={classes.approvedList}>
				<HeadingWithLine
					heading={`Finished Invoices ${
						!isLoading && requiredFiltersAreSet ? `(${finishedWashCounts.length})` : ""
					}`}
					style={{ marginTop: "4rem" }}
					rightButton={
						<a
							href="/reports"
							className="reporting-link"
							target="_blank"
							rel="noreferrer"
						>
							Create Report <Launch />
						</a>
					}
				/>

				{finishedWashCounts.length > 0 && (
					<ul>
						{finishedWashCounts.map(({ id, invoice, package: pkg }) => (
							<li key={id}>
								{pkg?.portalContractSite?.customer?.customerNumber} -{" "}
								{pkg?.portalContractSite?.customer?.name}. Invoice #
								{invoice.invoiceNumber}.
							</li>
						))}
					</ul>
				)}
			</div>
		</QuickCard>
	)
}

export default WashCountSubmission

import isEqual from "lodash/isEqual"
import omit from "lodash/omit"

import { getCategory, listMaterials } from "@ncs/mortar/redux/services/marketing"
import { DATE_RANGES, getDateRangeForFilter } from "@ncs/bricks/util/dates"

const SET_SELECTED_COLUMNS = "marketingMaterialsModule/set_selected_columns"
const TOGGLE_COLUMN = "marketingMaterialsModule/toggle_column"
const FETCH_MATERIALS = "marketingMaterialsModule/fetch_materials"
const SEARCH_CHANGED = "marketingMaterialsModule/search_changed"
const SORTED_CHANGED = "marketingMaterialsModule/sorted_changed"
const PAGE_CHANGED = "marketingMaterialsModule/page_changed"
const PAGE_SIZE_CHANGED = "marketingMaterialsModule/page_size_changed"
const EXPANDED_CHANGED = "marketingMaterialsModule/expanded_changed"
const RESIZED_CHANGED = "marketingMaterialsModule/resized_changed"
const FILTERED_CHANGED = "marketingMaterialsModule/filtered_changed"

const CREATED_DATE_CHANGED = "marketingMaterialsModule/created_date_changed"
const CATEGORY_CHANGED = "marketingMaterialsModule/category_changed"
const DOCUMENT_TYPE_CHANGED = "marketingMaterialsModule/document_type_changed"

const RESET_FILTERS = "marketingMaterialsModule/reset_filters"
const TOGGLE_CATEGORY_SIDEBAR = "marketingMaterialsModule/toggle_category_sidebar"

// cart stuff
const ADD_TO_CART = "marketing/add_to_cart"
const REMOVE_FROM_CART = "marketing/remove_from_cart"
const EMPTY_CART = "marketing/empty_cart"

export const COLUMNS = {
	Preview: "Preview",
	Filename: "Filename",
	Category: "Category",
	ExpirationDate: "Expiration Date",
	EffectiveDate: "Effective Date",
	Cost: "Cost",
	IsOrderable: "Is Orderable",
	DateCreated: "Date Created",
	CreatedBy: "Created By",
	Actions: "Actions",
}

const generateOrdering = (sorted) => {
	if (!sorted.length) return undefined

	// assigned_employee__name is actually comprised of two fields
	let i = sorted.findIndex((s) => s.id === "uploaded_by.name")
	let parsed =
		i < 0 ? sorted : (
			sorted
				.slice(0, i)
				.concat([
					{ id: "uploaded_by__first_name", desc: sorted[i].desc },
					{ id: "uploaded_by__last_name", desc: sorted[i].desc },
				])
				.concat(sorted.slice(i + 1))
		)

	return parsed.map((s) => (s.desc ? "-" : "") + s.id.replace(/\./g, "__")).join(",")
}

const generateFilters = (props) => {
	const { filtered, selectedCreatedDateRange, selectedCategory, selectedDocumentTypeFilter } =
		props

	let filters = Object.assign(
		{},
		...filtered.map((filter) => ({ [filter.id.replace(".", "__")]: filter.value }))
	)

	const createdDateStart = getDateRangeForFilter(selectedCreatedDateRange)
	if (createdDateStart) filters["created_date_gte"] = createdDateStart.format("YYYY-MM-DD")

	filters["category"] = Number(selectedCategory > 0) ? selectedCategory : undefined
	filters["document_type"] =
		Number(selectedDocumentTypeFilter) >= 0 ? selectedDocumentTypeFilter : undefined

	return filters
}

const generateQuerystringParameters = ({ pageSize, page, sorted, filtered, search, ...rest }) => {
	let query = {
		ordering: generateOrdering(sorted),
		page: page + 1,
		pageSize: pageSize,
		search,
		...generateFilters({ filtered, ...rest }),
	}

	if (!query.search) delete query.search

	return query
}

export const fetchMaterials = () => {
	return (dispatch, getState) => {
		const state = getState().marketingMaterialsModule

		let query = generateQuerystringParameters(state)
		// ignore duplicate queries within half a second of each other
		if (
			state.fetched.time &&
			new Date() - state.fetched.time < 500 &&
			isEqual(query, state.fetched.query)
		)
			return

		dispatch({ type: FETCH_MATERIALS, payload: { query, time: new Date() } })
		dispatch(listMaterials(query))
	}
}

export const searchMaterials = () => {
	const debounced = (dispatch) => {
		dispatch(fetchMaterials())
	}

	debounced.meta = {
		debounce: {
			time: 325,
			key: "SEARCH_MATERIALS",
		},
	}

	return debounced
}

export const handleSearchChange = (search) => {
	return (dispatch) => {
		dispatch({ type: SEARCH_CHANGED, payload: { search, page: 0 } })
		dispatch(searchMaterials())
	}
}

export const handleSortedChange = (payload) => {
	return (dispatch) => {
		dispatch({ type: SORTED_CHANGED, payload })
		dispatch(fetchMaterials())
	}
}

export const handlePageChange = (payload) => {
	return (dispatch) => {
		dispatch({ type: PAGE_CHANGED, payload })
		dispatch(fetchMaterials())
	}
}

export const handlePageSizeChange = (pageSize, page) => {
	return (dispatch) => {
		dispatch({ type: PAGE_SIZE_CHANGED, payload: { pageSize, page } })
		dispatch(fetchMaterials())
	}
}

export const handleExpandedChange = (payload) => {
	return (dispatch) => {
		dispatch({ type: EXPANDED_CHANGED, payload })
	}
}

export const handleResizedChange = (payload) => {
	return (dispatch) => {
		dispatch({ type: RESIZED_CHANGED, payload })
	}
}

export const handleFilteredChange = (payload) => {
	return (dispatch) => {
		dispatch({ type: FILTERED_CHANGED, payload })
		dispatch(fetchMaterials())
	}
}

export const setSelectedColumns = (selectedColumns) => ({
	type: SET_SELECTED_COLUMNS,
	payload: { selectedColumns },
})

export const handleToggleColumn = (payload) => ({
	type: TOGGLE_COLUMN,
	payload,
})

export const handleCreatedDateChange = (selectedCreatedDateRange) => {
	return (dispatch) => {
		dispatch({ type: CREATED_DATE_CHANGED, payload: { selectedCreatedDateRange, page: 0 } })
		dispatch(fetchMaterials())
	}
}

export const handleCategoryChange = (selectedCategory) => {
	return (dispatch) => {
		dispatch({ type: CATEGORY_CHANGED, payload: { selectedCategory, page: 0 } })
		dispatch(fetchMaterials())
		if (selectedCategory > 0) {
			dispatch(getCategory(selectedCategory))
		}
	}
}

export const handleDocumentTypeChange = (selectedDocumentTypeFilter) => (dispatch) => {
	dispatch({ type: DOCUMENT_TYPE_CHANGED, payload: { selectedDocumentTypeFilter, page: 0 } })
	dispatch(fetchMaterials())
}

export const resetFilters = () => (dispatch) => {
	dispatch({ type: RESET_FILTERS })
	dispatch(fetchMaterials())
}

export const handleToggleCategorySidebar = () => (dispatch) => {
	dispatch({ type: TOGGLE_CATEGORY_SIDEBAR })
}

const getDefaultFilters = () => {
	const filterFields = [
		"search",
		"page",
		"selectedCreatedDateRange",
		"selectedCategory",
		"selectedDocumentTypeFilter",
	]
	return filterFields.reduce((result, key) => {
		result[key] = initialState[key]
		return result
	}, {})
}

export const handleAddToCart = (item, quantity) => (dispatch) => {
	dispatch({ type: ADD_TO_CART, payload: { item, quantity } })
}

export const handleRemoveFromCart = (item, quantity) => (dispatch) => {
	dispatch({ type: REMOVE_FROM_CART, payload: { item, quantity } })
}

export const handleEmptyCart = () => (dispatch) => {
	dispatch({ type: EMPTY_CART })
}

const initialState = {
	showCategorySidebar: false,

	fetched: {},
	search: "",
	sorted: [],
	page: 0,
	pageSize: 25,
	expanded: {},
	resized: [],
	filtered: [],

	selectedColumns: [COLUMNS.Preview, COLUMNS.Filename, COLUMNS.Actions],

	availableColumns: [
		COLUMNS.Preview,
		COLUMNS.Filename,
		COLUMNS.Category,
		COLUMNS.DateCreated,
		COLUMNS.CreatedBy,
		COLUMNS.EffectiveDate,
		COLUMNS.ExpirationDate,
		COLUMNS.Cost,
		COLUMNS.IsOrderable,
		COLUMNS.Actions,
	],

	dateRanges: DATE_RANGES,
	selectedCreatedDateRange: "all",

	selectedCategory: -1,

	availableDocumentTypeFilters: [
		{ value: "-1", text: "All" },
		{ value: "0", text: "Documents" },
		{ value: "1", text: "Images" },
		{ value: "2", text: "Videos" },
	],
	selectedDocumentTypeFilter: "-1",

	cart: {},
}

const marketingMaterialsModule = (state = initialState, action) => {
	switch (action.type) {
		case "persist/REHYDRATE":
			return {
				...state,
				...(action.payload || {}).marketingMaterialsModule,
			}
		case SET_SELECTED_COLUMNS:
			return { ...state, ...action.payload }
		case TOGGLE_COLUMN: {
			let newSelectedColumns =
				state.selectedColumns.includes(action.payload) ?
					state.selectedColumns.filter((x) => x !== action.payload)
				:	[...state.selectedColumns, action.payload]
			return {
				...state,
				selectedColumns: state.availableColumns.filter((x) =>
					newSelectedColumns.includes(x)
				),
			}
		}
		case FETCH_MATERIALS:
			return { ...state, fetched: action.payload }
		case SEARCH_CHANGED:
			return { ...state, search: action.payload.search, page: action.payload.page }
		case SORTED_CHANGED:
			return { ...state, sorted: action.payload }
		case PAGE_CHANGED:
			return { ...state, page: action.payload }
		case PAGE_SIZE_CHANGED:
			return { ...state, page: action.payload.page, pageSize: action.payload.pageSize }
		case EXPANDED_CHANGED:
			return { ...state, expanded: action.payload }
		case RESIZED_CHANGED:
			return { ...state, resized: action.payload }
		case FILTERED_CHANGED:
			return { ...state, filtered: action.payload }
		case CREATED_DATE_CHANGED:
			if (
				!state.dateRanges
					.map((x) => x.value)
					.includes(action.payload.selectedCreatedDateRange)
			)
				return state

			return { ...state, ...action.payload }
		case DOCUMENT_TYPE_CHANGED:
			if (
				!state.availableDocumentTypeFilters
					.map((x) => x.value)
					.includes(action.payload.selectedDocumentTypeFilter)
			)
				return state

			return { ...state, ...action.payload }
		case CATEGORY_CHANGED:
			return {
				...state,
				...action.payload,
				selectedCategory: Number(action.payload.selectedCategory),
			}
		case RESET_FILTERS:
			return {
				...state,
				...getDefaultFilters(),
			}
		case TOGGLE_CATEGORY_SIDEBAR:
			return {
				...state,
				showCategorySidebar: !state.showCategorySidebar,
			}

		case ADD_TO_CART:
			return {
				...state,
				cart: {
					...state.cart,
					[action.payload.item.id]: {
						item: action.payload.item,
						quantity: Number(action.payload.quantity),
					},
				},
			}
		case REMOVE_FROM_CART:
			return {
				...state,
				cart: omit(state.cart, [action.payload.item.id]),
			}
		case EMPTY_CART:
			return {
				...state,
				cart: {},
			}

		default:
			return state
	}
}
export default marketingMaterialsModule

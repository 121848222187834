import { FC } from "react"

import { useFormContext } from "react-hook-form"

import { CheckboxFormField } from "@ncs/web-legos"

import { NewContractForm } from "../contract-utils"

export const NewContractSchedules: FC = () => {
	const { control } = useFormContext<NewContractForm>()

	return (
		<>
			<CheckboxFormField
				control={control}
				name="hasChemicalSchedule"
				label="Has chemical pricing schedule"
				mb={0}
			/>
			<CheckboxFormField
				control={control}
				name="hasSelfServiceChemicalSchedule"
				label="Has self-service chemical pricing schedule"
				mb={0}
			/>
			<CheckboxFormField
				control={control}
				name="hasPartsSchedule"
				label="Has parts pricing schedule"
				mb={0}
			/>
			<CheckboxFormField
				control={control}
				name="hasServiceSchedule"
				label="Has service pricing schedule"
				mb={0}
			/>
		</>
	)
}

import { FC, useCallback, useState } from "react"

import { Cell, Column } from "react-table"

import {
	Dispatch,
	InvoiceHistoryItem,
	makeApiErrorMessage,
	useCreateInvoiceNote,
	useCustomerInvoiceHistory,
	useWorkOrderInvoice,
	WorkOrderInvoice,
} from "@ncs/ncs-api"
import {
	displayDateTime,
	formatCurrency,
	formatDateTime,
	getTimezoneAbbreviation,
	maxChars,
} from "@ncs/ts-utils"
import {
	Box,
	Button,
	EditStringModal,
	EditStringModalProps,
	EmptyValueDash,
	encodeUrlState,
	HeadingDivider,
	HeadingDividerProps,
	Icon,
	LoadingSpinner,
	Paragraph,
	Table,
	useToast,
} from "@ncs/web-legos"

import { getHistoryItemAction, getHistoryItemStatus } from "~/util"

import { DispatchDetailTab, DispatchDetailUrlState } from "../../DispatchDetail"

export interface HistoryTabProps {
	dispatch: Dispatch
}

export const HistoryTab: FC<HistoryTabProps> = ({ dispatch }) => {
	const { makeSuccessToast, makeErrorToast } = useToast()
	const [invoice, invoiceLoading] = useWorkOrderInvoice(dispatch.invoice?.id)
	const customerInvoicesQuery = useCustomerInvoiceHistory(dispatch.customer.id, {
		pageSize: 10,
	})
	const addNote = useCreateInvoiceNote()
	const [editStringModalConfig, setEditStringModalConfig] =
		useState<EditStringModalProps | null>(null)

	const handleAddNote = async () => {
		setEditStringModalConfig({
			title: "New Invoice Note",
			message: "Add a new note to this dispatch's invoice.",
			label: "Comments",
			textarea: true,
			allowEmpty: false,
			onSave: async (value) => {
				if (value && invoice) {
					try {
						await addNote({
							id: invoice.id,
							rejectionReason: value,
						})
						makeSuccessToast("Invoice note created")
					} catch (e) {
						makeErrorToast(makeApiErrorMessage(e))
					}
				}
			},
			onClose: () => setEditStringModalConfig(null),
		})
	}

	const goToInvoice = useCallback((dispatchId: string) => {
		window.open(
			`/service/dispatches/${dispatchId}${encodeUrlState<DispatchDetailUrlState>({
				tab: DispatchDetailTab.Invoice,
			})}`
		)
	}, [])

	if (invoiceLoading) {
		return <LoadingSpinner />
	}

	const headingProps: HeadingDividerProps = {
		variant: "h5",
		mt: 0,
		mb: 0,
	}

	return (
		<>
			<Box d="flex" flexDirection="column" rowGap={3}>
				<div>
					<HeadingDivider
						{...headingProps}
						renderRight={() => (
							<Button icon="plus" disabled={!invoice} onClick={handleAddNote}>
								Add an invoice note
							</Button>
						)}
					>
						Invoice Status Changes & Notes
					</HeadingDivider>
					{invoice?.history.length ?
						<Table
							data={invoice.history}
							columns={invoiceHistoryColumns}
							disableAllSorting
						/>
					:	<Paragraph small secondary mt={1}>
							No invoice history yet
						</Paragraph>
					}
				</div>

				<div>
					<HeadingDivider {...headingProps}>Invoice Line Item Actions</HeadingDivider>
					{invoice?.actions.length ?
						<Table
							data={invoice.actions}
							columns={invoiceActionsColumns}
							disableAllSorting
						/>
					:	<Paragraph small secondary mt={1}>
							No invoice actions yet
						</Paragraph>
					}
				</div>

				<div>
					<HeadingDivider {...headingProps}>Other Invoices for Customer</HeadingDivider>
					{customerInvoicesQuery.isLoading ?
						<LoadingSpinner />
					: customerInvoicesQuery.data.length ?
						<Table
							query={customerInvoicesQuery}
							columns={customerInvoicesColumns}
							disableAllSorting
							onRowClick={({ original }) => {
								if (original.dispatch) goToInvoice(original.dispatch.id)
							}}
						/>
					:	<Paragraph small secondary>
							No customer invoice history yet
						</Paragraph>
					}
				</div>
			</Box>

			{!!editStringModalConfig && <EditStringModal {...editStringModalConfig} />}
		</>
	)
}

const invoiceHistoryColumns: Column<WorkOrderInvoice["history"][number]>[] = [
	{
		Header: "Date",
		accessor: ({ createdDate }) => formatDateTime(createdDate),
	},
	{
		Header: "User",
		accessor: "createdBy",
	},
	{
		Header: "Status",
		accessor: (original) => getHistoryItemStatus(original) || <EmptyValueDash />,
	},
	{
		Header: "Action",
		accessor: (original) => getHistoryItemAction(original),
	},
	{
		Header: "Reason",
		accessor: ({ rejectionReason }) => rejectionReason || <EmptyValueDash />,
	},
]

const invoiceActionsColumns: Column<WorkOrderInvoice["actions"][number]>[] = [
	{
		Header: "Date",
		accessor: ({ createdDate }) => formatDateTime(createdDate),
	},
	{
		Header: "Changed by",
		accessor: "createdBy",
	},
	{
		Header: "Action",
		accessor: "actionType",
	},
	{
		Header: "Line item",
		accessor: ({ part, description }) => part || description || <EmptyValueDash />,
	},
	{
		Header: "Unit $",
		accessor: ({ unitPrice }) => formatCurrency(unitPrice),
	},
	{
		Header: "Net $",
		accessor: ({ netPrice }) => formatCurrency(netPrice),
	},
	{
		Header: "Subtotal",
		accessor: ({ subTotal }) => formatCurrency(subTotal),
	},
	{
		Header: "Tax",
		accessor: ({ tax }) => formatCurrency(tax),
	},
	{
		Header: "Total",
		accessor: ({ total }) => formatCurrency(total),
	},
	{
		Header: "Under warranty?",
		accessor: "underWarranty",
		Cell: ({ row: { original } }: Cell<WorkOrderInvoice["actions"][number]>) => {
			return original.underWarranty ?
					<Box d="flex" alignItems="center" gap={0.5}>
						<Icon icon="check" color="gray" />
						<span>Under warranty</span>
					</Box>
				:	<EmptyValueDash />
		},
	},
	{
		Header: "Billable?",
		accessor: "billable",
		Cell: ({ row: { original } }: Cell<WorkOrderInvoice["actions"][number]>) => {
			return original.billable ?
					<Box d="flex" alignItems="center" gap={0.5}>
						<Icon icon="check" color="gray" />
						<span>Billable</span>
					</Box>
				:	<EmptyValueDash />
		},
	},
]

const customerInvoicesColumns: Column<InvoiceHistoryItem>[] = [
	{
		Header: "Invoice #",
		accessor: "invoiceNumber",
	},
	{
		Header: "Invoice status",
		accessor: "statusCode",
	},
	{
		Header: `Opened (${getTimezoneAbbreviation()})`,
		accessor: ({ arrivedDate }) => displayDateTime(arrivedDate, "") || <EmptyValueDash />,
	},
	{
		Header: `Closed (${getTimezoneAbbreviation()})`,
		accessor: ({ workCompletedDate }) =>
			displayDateTime(workCompletedDate, "") || <EmptyValueDash />,
	},
	{
		Header: "Total",
		accessor: ({ total }) => formatCurrency(total),
	},
	{
		Header: "Dispatch description",
		accessor: ({ dispatch }) =>
			dispatch?.symptoms ? maxChars(dispatch.symptoms, 100) : <EmptyValueDash />,
	},
]

import { FC, useEffect, useMemo, useState } from "react"

import { ContractUnitCategory, makeApiErrorMessage } from "@ncs/ncs-api"
import { addOrPromote } from "@ncs/ts-utils"
import {
	Box,
	Button,
	Chip,
	ExtendableModalProps,
	IconButton,
	Modal,
	NumericInput,
	Paragraph,
	RadioGroup,
} from "@ncs/web-legos"

import { AddBusinessUnitModal } from "./AddBusinessUnitModal"
import { AddCustomCategoryModal } from "./AddCustomCategoryModal"
import { businessUnitToUnitCategory, ContractIncentiveProgram } from "./contract-utils"

export interface ContractIncentiveProgramModalProps extends ExtendableModalProps {
	incentive: ContractIncentiveProgram | null
	onSave: (incentive: ContractIncentiveProgram) => void | Promise<void>
}

interface InProgressRebate {
	start: number | null
	end: number | null
	percent: number | null
}

export const ContractIncentiveProgramModal: FC<ContractIncentiveProgramModalProps> = ({
	incentive,
	onSave,
	...rest
}) => {
	const [categories, setCategories] = useState<ContractUnitCategory[]>([])
	const [discountPercent, setDiscountPercent] = useState<number | null>(null)
	const [rebateSchedule, setRebateSchedule] = useState<InProgressRebate[]>([])
	const [pendingRebate, setPendingRebate] = useState<InProgressRebate | null>(null)
	const [creditAmount, setCreditAmount] = useState<number | null>(null)
	const [creditIsPerSite, setCreditIsPerSite] = useState(false)

	const [addingBusinessUnit, setAddingBusinessUnit] = useState(false)
	const [addingCustomCategory, setAddingCustomCategory] = useState(false)
	const [newCustomCategories, setNewCustomCategories] = useState<string[]>([])

	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const removeCategory = (idToRemove: string) => {
		setCategories((prev) => prev.filter(({ id }) => id !== idToRemove))
	}

	const removeNewCustomCategory = (name: string) => {
		setNewCustomCategories((prev) => prev.filter((c) => c !== name))
	}

	const updateRebate = (
		field: keyof InProgressRebate,
		newValue: number | null | undefined,
		indexToUpdate: number
	) => {
		setRebateSchedule((prev) =>
			prev.map((r, i) => {
				return i !== indexToUpdate ? r : (
						{
							...r,
							[field]: newValue ?? null,
						}
					)
			})
		)
	}

	const removeRebate = (indexToRemove: number) => {
		setRebateSchedule((prev) => prev.filter((r, i) => i !== indexToRemove))
	}

	const updatePendingRebate = (
		field: keyof InProgressRebate,
		newValue: number | null | undefined
	) => {
		setPendingRebate((prev) => ({
			start: prev?.start ?? null,
			end: prev?.end ?? null,
			percent: prev?.percent ?? null,
			[field]: newValue ?? null,
		}))
	}

	const savePendingRebate = () => {
		if (pendingRebate) {
			setRebateSchedule((prev) => [
				...prev,
				{
					start: pendingRebate.start ?? 0,
					end: pendingRebate.end, // This one can stay blank, to indicate anything greater than.
					percent: pendingRebate.percent ?? 0,
				},
			])
		}
		setPendingRebate(null)
	}

	const handleSave = async () => {
		try {
			// At least one category must be selected.
			if (!categories.length && !newCustomCategories.length) {
				throw new Error("At least one business unit or category type must be selected.")
			}
			// This shouldn't happen, but you can't have new Custom categories with anything
			// other than discounts.
			if (hasCustomCategory && (rebateSchedule.length || creditAmount)) {
				throw new Error("'Custom' category type only usable with discount incentive.")
			}
			// I guess you do need to include at least one of the three incentives?
			if (!rebateSchedule.length && !creditAmount && !discountPercent) {
				throw new Error(
					"Must provide at least one incentive to add a program to the contract."
				)
			}

			setIsSaving(true)

			const newProgram: ContractIncentiveProgram = {
				categories: [
					...categories,
					...newCustomCategories.map((name) => ({ name, type: "other" as const })),
				],
				rebate:
					rebateSchedule.length ?
						{
							id: incentive?.rebate?.id,
							schedule: rebateSchedule.flatMap((r) => [
								{
									start: r.start ?? 0,
									end: r.end,
									percent: r.percent ?? 0,
								},
							]),
						}
					:	null,
				credit:
					creditAmount ?
						{
							id: incentive?.credit?.id,
							amount: creditAmount,
							isPerSite: creditIsPerSite,
						}
					:	null,
				discount:
					discountPercent ?
						{
							id: incentive?.discount?.id,
							percent: discountPercent,
						}
					:	null,
			}

			await onSave(newProgram)
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const reset = () => {
		// Separate the categories that have IDs vs new "others" that don't.
		setCategories(
			(incentive?.categories ?? []).filter(
				(c) => !!(c as ContractUnitCategory).id
			) as ContractUnitCategory[]
		)
		setNewCustomCategories(
			(incentive?.categories ?? [])
				.filter((c) => !(c as ContractUnitCategory).id)
				.map((c) => c.name) as string[]
		)
		setDiscountPercent(incentive?.discount?.percent ?? null)
		setRebateSchedule(incentive?.rebate?.schedule ?? [])
		setPendingRebate(null)
		setCreditAmount(incentive?.credit?.amount ?? null)
		setCreditIsPerSite(incentive?.credit?.isPerSite ?? false)
		setAddingBusinessUnit(false)
		setAddingCustomCategory(false)
		setIsSaving(false)
		setErrorText(null)
	}

	useEffect(() => {
		setErrorText(null)
	}, [categories, newCustomCategories, discountPercent, rebateSchedule, creditAmount])

	const hasCustomCategory = useMemo(() => {
		return categories.some((c) => c.type === "other") || !!newCustomCategories.length
	}, [categories, newCustomCategories])

	const isEdit = !!incentive

	return (
		<Modal
			title="Incentive Program"
			onOpen={reset}
			{...rest}
			rightButtons={{
				buttonText: isEdit ? "Save Changes" : "Add To Contract",
				onClick: handleSave,
				isLoading: isSaving,
			}}
			errorText={errorText}
		>
			<Paragraph mb={1}>
				Which business units and/or other categories are covered by these incentive?
			</Paragraph>
			<Paragraph mb={1} small color="secondary">
				Note that if you add a non-business unit category, you are limited to providing
				only a discount.
			</Paragraph>
			<Box display="flex" columnGap={0.5} rowGap={0.5} flexWrap="wrap" mb={2}>
				{categories.map((c) => {
					return (
						<Chip
							key={c.id}
							label={c.type === "other" ? `${c.name} (other)` : c.name}
							onDelete={() => removeCategory(c.id)}
						/>
					)
				})}
				{newCustomCategories.map((c) => {
					return (
						<Chip
							key={c}
							label={`${c} (other)`}
							onDelete={() => removeNewCustomCategory(c)}
						/>
					)
				})}
				<Button icon="plus" onClick={() => setAddingBusinessUnit(true)}>
					Add business unit
				</Button>
				<Button
					icon="plus"
					onClick={() => setAddingCustomCategory(true)}
					disabled={!!rebateSchedule.length || !!creditAmount}
				>
					Add other category (Discounts only)
				</Button>
			</Box>

			<Paragraph mb={0.25}>Discount %</Paragraph>
			<NumericInput
				doPercentageMath
				value={discountPercent}
				onChange={(value) => setDiscountPercent(value ?? null)}
				maxWidth={8}
				placeholder="Eg, 1.5%"
				mb={2}
				min={0}
				max={100}
				decimalScale={3}
			/>

			<Paragraph mb={0.5}>Annual Rebates</Paragraph>
			{rebateSchedule.map((r, i) => (
				<Box key={i} display="flex" alignItems="center" columnGap={1}>
					<NumericInput
						value={r.start}
						onChange={(value) => updateRebate("start", value, i)}
						label="From"
					/>
					<NumericInput
						value={r.end}
						onChange={(value) => updateRebate("end", value, i)}
						label="To"
					/>
					<NumericInput
						doPercentageMath
						value={r.percent}
						onChange={(value) => updateRebate("percent", value, i)}
						label="Rebate amount %"
						placeholder="Eg, 1.5%"
						min={0}
						max={100}
						decimalScale={3}
					/>
					<Box width={19}>
						<IconButton icon="trash-alt" onClick={() => removeRebate(i)} size="sm" />
					</Box>
				</Box>
			))}
			{pendingRebate ?
				<Box display="flex" alignItems="center" columnGap={1}>
					<NumericInput
						value={pendingRebate.start}
						onChange={(value) => updatePendingRebate("start", value)}
						label="From $"
					/>
					<NumericInput
						value={pendingRebate.end}
						onChange={(value) => updatePendingRebate("end", value)}
						label="To $"
					/>
					<NumericInput
						doPercentageMath
						value={pendingRebate.percent}
						onChange={(value) => updatePendingRebate("percent", value)}
						label="Rebate amount %"
						placeholder="Eg, 1.5%"
						min={0}
						max={100}
						decimalScale={3}
					/>
					<Box display="flex" justifyContent="space-between" columnGap={1} width={19}>
						<IconButton
							icon="check"
							color="success"
							background="primary"
							onClick={savePendingRebate}
						/>
						<IconButton icon="times" onClick={() => setPendingRebate(null)} />
					</Box>
				</Box>
			:	<Button
					icon="plus"
					onClick={() =>
						setPendingRebate({
							end: null,
							start: null,
							percent: null,
						})
					}
					disabled={hasCustomCategory}
				>
					Add rebate row
				</Button>
			}

			<Paragraph mt={2} mb={0.25}>
				Credits $
			</Paragraph>
			<Box display="flex" alignItems="center" columnGap={1} mb={1}>
				<NumericInput
					value={creditAmount}
					onChange={(value) => setCreditAmount(value ?? null)}
					maxWidth={8}
					placeholder="Credits $..."
					disabled={hasCustomCategory && !creditAmount}
				/>
				<RadioGroup
					htmlName="credits-are-per-site"
					value={creditIsPerSite ? "true" : "false"}
					disabledAccessor={() => hasCustomCategory && !creditAmount}
					options={[
						{
							value: "false",
							label: "Total for all sites",
						},
						{
							value: "true",
							label: "Per site",
						},
					]}
					onChange={(value) => setCreditIsPerSite(value === "true")}
					horizontal
				/>
			</Box>

			<AddBusinessUnitModal
				isOpen={addingBusinessUnit}
				onClose={() => setAddingBusinessUnit(false)}
				onSave={(newUnit) => {
					setCategories((prev) =>
						addOrPromote(businessUnitToUnitCategory(newUnit), prev)
					)
				}}
			/>
			<AddCustomCategoryModal
				isOpen={addingCustomCategory}
				onClose={() => setAddingCustomCategory(false)}
				onSave={(newName) => {
					// Add the new other category.
					setNewCustomCategories((prev) => addOrPromote(newName, prev))
					// If you had anything in the incentives that aren't allowed to go w/
					// Custom category, empty them out.
					setRebateSchedule([])
					setPendingRebate(null)
					setCreditAmount(null)
				}}
			/>
		</Modal>
	)
}

import { FC, Fragment, useEffect, useState } from "react"

import {
	InventoryLocation,
	InventoryPart,
	InventoryRequestTypeId,
	makeApiErrorMessage,
	useCreateInventoryRequest,
	UserMinimal,
} from "@ncs/ncs-api"
import { addIfNew } from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	Box,
	Button,
	Callout,
	CssGridTable,
	ErrorText,
	HeadingDivider,
	IconButton,
	LocationSelector,
	Paragraph,
	RadioGroup,
	Select,
	TextInput,
	useIsSaving,
	UserSelector,
	useSetUrlState,
} from "@ncs/web-legos"

import { InventoryRequestTypeSelector } from "~/components"

import { PhysicalInventoryTab, PhysicalInventoryUrlState } from "../../PhysicalInventory"
import { AddPartModal, InventoryRequestCreatedModal } from "./components"

export const CreateInventoriesTab: FC = () => {
	const { isSaving, setSaving, endSaving } = useIsSaving()
	const [errorText, setErrorText] = useState<string | null>(null)
	const [showSuccessModal, setShowSuccessModal] = useState(false)
	const setUrlState = useSetUrlState<PhysicalInventoryUrlState>()

	const [requestType, setRequestType] = useState<InventoryRequestTypeId>(
		InventoryRequestTypeId.Cycle
	)
	const [location, setLocation] = useState<InventoryLocation | null>(null)
	const [description, setDescription] = useState<string | null>(null)
	const [assignedTo, setAssignedTo] = useState<UserMinimal | null>(null)
	const [binStartsWith, setBinStartsWith] = useState<string | null>(null)
	const [partStartsWith, setPartStartsWith] = useState<string | null>(null)
	const [partList, setPartList] = useState<InventoryPart[]>([])
	const [partStrategy, setPartStrategy] = useState<"specify" | "search" | null>(null)
	const [typeFilter, setTypeFilter] = useState<"detail" | "chemical" | null>(null)
	const [showPartModal, setShowPartModal] = useState(false)

	const createRequest = useCreateInventoryRequest()

	const handleCreate = async () => {
		try {
			setSaving()

			if (!location) {
				throw new Error("Location is required")
			}

			if (requestType === InventoryRequestTypeId.Physical) {
				await createRequest({
					requestType,
					location: location.id,
					description:
						description ||
						`Physical Inventory at (${location.locationCode}) ${location.description}`,
				})
			} else {
				if (!partStrategy) {
					throw new Error("Select how you will identify which parts to count")
				}
				if (partStrategy === "specify") {
					if (!partList.length) {
						throw new Error("Choose at least one part")
					}
				}

				await createRequest({
					requestType,
					location: location.id,
					description:
						description ||
						`Cycle Count at (${location.locationCode}) ${location.description}`,
					parts: partStrategy === "specify" ? partList.map((p) => p.id) : null,
					binStartsWith: partStrategy === "search" ? binStartsWith : null,
					partNumberStartsWith: partStrategy === "search" ? partStartsWith : null,
					detailProductsOnly: partStrategy === "search" && typeFilter === "detail",
					chemicalProductsOnly: partStrategy === "search" && typeFilter === "chemical",
					assignedTo: assignedTo?.id ?? null,
				})
			}
			endSaving()
			setShowSuccessModal(true)
		} catch (e) {
			endSaving()
			setErrorText(makeApiErrorMessage(e))
		}
	}

	useEffect(() => {
		setErrorText(null)
	}, [requestType, location, description, partList, partStrategy])

	return (
		<>
			<Box maxWidth="50%" mdProps={{ maxWidth: "none" }}>
				<HeadingDivider headingVariant="h4" bold>
					Request
				</HeadingDivider>

				<Box d="flex" flexDirection="column" rowGap={2} mb={4}>
					<InventoryRequestTypeSelector
						label="Inventory count type"
						value={requestType}
						onChange={(newType) => {
							if (newType) {
								setRequestType(newType)
							}
						}}
						showNoSelectionOption={false}
						fillContainer
						mb={0}
					/>
					<LocationSelector value={location} onChange={setLocation} mb={0} />
					<TextInput
						value={description}
						onChange={setDescription}
						label="Description (optional)"
						mb={0}
						maxLength={250}
					/>
					<UserSelector
						value={assignedTo}
						onChange={setAssignedTo}
						employeesOnly
						label="Assigned to"
						placeholder="Who should perform the count?"
						tooltip="Defaults to currently logged in user (that's you) if no selection is made"
						mb={0}
					/>
				</Box>

				<HeadingDivider headingVariant="h4" bold>
					Parts
				</HeadingDivider>

				<AnimatedEntrance show={requestType === InventoryRequestTypeId.Physical}>
					<Callout variant="warning" icon="info-circle">
						<Paragraph>
							Creating physical inventories through this screen is not yet
							implemented. Check back soon.
						</Paragraph>
					</Callout>
				</AnimatedEntrance>

				<AnimatedEntrance show={requestType === InventoryRequestTypeId.Cycle}>
					<RadioGroup
						htmlName="part-strategy"
						value={partStrategy}
						onChange={(value, option) => setPartStrategy(option.value)}
						options={[
							{
								value: "specify" as const,
								label: "Specify parts individually",
							},
							{
								value: "search" as const,
								label: "Use search criteria to select parts",
							},
						]}
						mb={2}
					/>

					<AnimatedEntrance show={partStrategy === "specify"} mt={1}>
						<Button icon="plus" onClick={() => setShowPartModal(true)}>
							Add part
						</Button>
						{!!partList.length && (
							<CssGridTable
								mt={1}
								gridTemplateColumns="1rem auto 1fr"
								columnGap={1}
								headers={["", "Part #", "Name"]}
								cells={partList.map((p) => {
									return (
										<Fragment key={p.id}>
											<Box>
												<IconButton
													icon="times"
													color="gray"
													onClick={() => {
														setPartList((prev) =>
															prev.filter(
																(oldPart) => oldPart.id !== p.id
															)
														)
													}}
												/>
											</Box>
											<Paragraph>{p.partNumber}</Paragraph>
											<Paragraph>{p.description}</Paragraph>
										</Fragment>
									)
								})}
							/>
						)}
					</AnimatedEntrance>

					<AnimatedEntrance
						show={partStrategy === "search"}
						d="flex"
						flexDirection="column"
						rowGap={2}
					>
						<div>
							<Paragraph mb={0.35}>Filter by type</Paragraph>
							<Select
								value={typeFilter}
								onChange={(value, option) => setTypeFilter(option?.value ?? null)}
								options={[
									{
										value: "detail" as const,
										text: "Detail products only",
									},
									{
										value: "chemical" as const,
										text: "Chemical products only",
									},
								]}
								fillContainer
								disableNoSelectionOption={false}
								noSelectionOptionText={"Any"}
								mb={0}
							/>
						</div>
						<div>
							<Paragraph mb={0.35}>Part number must start with</Paragraph>
							<TextInput
								value={partStartsWith}
								onChange={setPartStartsWith}
								placeholder="Part number starts with (optional)..."
								mb={0}
								maxLength={255}
							/>
						</div>
						<div>
							<Paragraph mb={0.35}>Bin code must start with</Paragraph>
							<TextInput
								value={binStartsWith}
								onChange={setBinStartsWith}
								placeholder="Bin code starts with (optional)..."
								mb={0}
								maxLength={255}
							/>
						</div>
					</AnimatedEntrance>
				</AnimatedEntrance>
			</Box>

			<Box maxWidth={40} mx="auto" mt={7} mb={3}>
				{!!errorText && (
					<ErrorText textAlign="center" mb={2}>
						{errorText}
					</ErrorText>
				)}

				<Button
					variant="primary-cta"
					fillContainer
					isLoading={isSaving()}
					onClick={handleCreate}
					disabled={requestType === InventoryRequestTypeId.Physical}
				>
					Create Request
				</Button>
			</Box>

			{showPartModal && (
				<AddPartModal
					onSave={(newPart) =>
						setPartList((prev) => {
							return addIfNew(newPart, prev, {
								compareFn: (oldPart) => oldPart.id === newPart.id,
							})
						})
					}
					onClose={() => setShowPartModal(false)}
				/>
			)}
			{showSuccessModal && (
				<InventoryRequestCreatedModal
					onClose={() => setUrlState({ tab: PhysicalInventoryTab.ViewInventories })}
				/>
			)}
		</>
	)
}

import React, { useMemo, useState } from "react"
import PropTypes from "prop-types"
import { CircularProgress, Dialog, DialogTitle, makeStyles } from "@material-ui/core"
import { Button, Alert } from "../../components"
import { MaterialUISizeKeys } from "../../util/types"
import WizardContextProvider, { useWizardContext } from "./wizardContext"
import { wizardStyles } from "./wizardStyles"

import { ArrowBack, ArrowForward, Check } from "@material-ui/icons"

const useStyles = makeStyles(wizardStyles)

export type WizardCombinedState<WizardCustomState = Record<string, unknown>> = {
	currentStep: number
} & WizardCustomState

export interface WizardPane {
	title: string
	allowAdvance?: ((wizardState: WizardCombinedState) => boolean) | null
	component: React.ReactNode
}

export interface WizardContentProps {
	className?: string
	open: boolean
	onClose(): void
	title: string
	submissionLoading?: boolean
	submissionLoadingText?: string
	confirmBeforeExiting?: boolean
	panes: WizardPane[]
	paneHeight?: string
	onFinish(wizardState: WizardCombinedState): void
	finishButtonText?: string
	fullWidth?: boolean
	maxWidth?: MaterialUISizeKeys
}

const WizardContent: React.FC<WizardContentProps> = ({
	className,
	open,
	onClose,
	title,
	panes,
	submissionLoading = false,
	submissionLoadingText = "Submitting...",
	confirmBeforeExiting,
	paneHeight = "450px",
	onFinish,
	finishButtonText = "Finish",
	fullWidth = true,
	maxWidth = "md",
}) => {
	const classes = useStyles({ panes, paneHeight })
	const [showExitConfirmation, setShowExitConfirmation] = useState(false)

	const [wizardState, setWizardState] = useWizardContext()
	const { currentStep } = wizardState

	function handleClose() {
		// Prevent user from closing out of the modal after clicking submit.
		if (!submissionLoading) {
			if (confirmBeforeExiting) {
				setShowExitConfirmation(true)
			} else {
				onClose()
			}
		}
	}

	function handleStepChange(newStepNum: number) {
		setWizardState((prev) => ({
			...prev,
			currentStep: newStepNum,
		}))
	}

	function handleFinish() {
		onFinish(wizardState)
	}

	function getPaneClasses(index: number) {
		const paneClasses = [classes.pane]

		if (index < currentStep) paneClasses.push(classes.hiddenLeft)
		if (index > currentStep) paneClasses.push(classes.hiddenRight)

		return paneClasses.join(" ")
	}

	function getStepClasses(pane: WizardPane, index: number) {
		const stepClasses = [classes.step]

		if (currentStep < index) stepClasses.push(classes.stepIncomplete)
		if (index === currentStep) stepClasses.push(classes.stepCurrent)
		if (currentStep > index) stepClasses.push(classes.stepComplete)

		return stepClasses.join(" ")
	}

	const allowPrev = currentStep > 0

	const allowAdvance = useMemo(() => {
		const pane = panes[currentStep]

		if (typeof pane.allowAdvance === "function") {
			return pane.allowAdvance(wizardState)
		}

		return true
	}, [panes, wizardState, currentStep])

	return (
		<>
			<Dialog
				open={open}
				onClose={handleClose}
				fullWidth={fullWidth}
				maxWidth={maxWidth}
				className={className ? className : ""}
			>
				<div className={classes.container}>
					<DialogTitle>{title}</DialogTitle>
					<div className={classes.stepsContainer}>
						{panes.map((p, i) => (
							<div key={p.title} className={getStepClasses(p, i)}>
								<button
									type="button"
									onClick={() => handleStepChange(i)}
									disabled={i >= currentStep}
								>
									<div className="blip">
										{currentStep > i && (
											<span className="step-num check">&#10004;</span>
										)}
										{currentStep <= i && (
											<span className="step-num">{i + 1}</span>
										)}
									</div>
									<span className="title">{p.title}</span>
								</button>
								{i !== panes.length - 1 && <div className="connector" />}
							</div>
						))}
					</div>

					<div className={classes.panesContainer}>
						{!submissionLoading &&
							panes.map((p, i) => (
								<div key={p.title} className={getPaneClasses(i)}>
									{p.component}
								</div>
							))}
						{submissionLoading && (
							<div className={classes.loadingContainer}>
								<CircularProgress />
								<p>{submissionLoadingText}</p>
							</div>
						)}
					</div>

					<div
						className={`${classes.footer} ${
							submissionLoading ? classes.disabled : ""
						}`}
					>
						{allowPrev && (
							<Button
								onClick={() => handleStepChange(currentStep - 1)}
								className={classes.leftButton}
								startIcon={<ArrowBack />}
								color="transparent"
							>
								Prev
							</Button>
						)}
						{currentStep === panes.length - 1 ?
							<Button
								onClick={handleFinish}
								className={classes.rightButton}
								startIcon={<Check />}
								disabled={!allowAdvance}
								color="success"
							>
								{finishButtonText}
							</Button>
						:	<Button
								onClick={() => handleStepChange(currentStep + 1)}
								className={classes.rightButton}
								endIcon={<ArrowForward />}
								disabled={!allowAdvance}
								color={allowAdvance ? "primary" : undefined}
							>
								Next
							</Button>
						}
					</div>
				</div>
			</Dialog>

			<Alert
				show={showExitConfirmation}
				title="Discard changes and close?"
				onConfirm={onClose}
				onCancel={() => setShowExitConfirmation(false)}
				confirmBtnText="Discard"
				confirmBtnColor="danger"
				cancelBtnText="Stay"
				cancelBtnColor="primary"
			/>
		</>
	)
}

WizardContent.propTypes = {
	className: PropTypes.string,
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	submissionLoading: PropTypes.bool,
	submissionLoadingText: PropTypes.string,
	confirmBeforeExiting: PropTypes.bool,
	panes: PropTypes.arrayOf(
		PropTypes.exact({
			title: PropTypes.string.isRequired,
			allowAdvance: PropTypes.func,
			component: PropTypes.element.isRequired,
		}).isRequired
	).isRequired,
	paneHeight: PropTypes.string,
	onFinish: PropTypes.func.isRequired,
	finishButtonText: PropTypes.string,
	fullWidth: PropTypes.bool,
	maxWidth: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]),
}

export interface WizardProps extends WizardContentProps {
	extraContextState: Record<string, unknown>
}

const Wizard: React.FC<WizardProps> = ({ extraContextState, ...rest }) => {
	return (
		<WizardContextProvider extraContextState={extraContextState}>
			<WizardContent {...rest} />
		</WizardContextProvider>
	)
}

export default Wizard

import { FC } from "react"

import { Column } from "react-table"

import {
	PricingModificationsReportLine,
	PricingModificationsReportQueryParams,
	useExportPricingModificationsReport,
	usePricingModificationsReport,
} from "@ncs/ncs-api"
import {
	formatCurrency,
	getStartAndEndDateQueryParams,
	getTimezoneAbbreviation,
	TimeAgo,
	Typify,
} from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	CustomerQueryFilter,
	Table,
	TimeAgoQueryFilter,
	usePaginationUrlState,
	useUrlState,
} from "@ncs/web-legos"

export type PricingModificationsReportUrlState = Typify<PricingModificationsReportQueryParams>

export const PricingModificationsReport: FC = () => {
	const [queryParams, { setUrlState: setQueryParams }] =
		useUrlState<PricingModificationsReportUrlState>(initialPricingModificationsReportUrlState)
	const [pagination, setPagination] = usePaginationUrlState()

	const query = usePricingModificationsReport({
		params: { ...queryParams, ...pagination },
		manualPagination: true,
	})
	const dataExport = useExportPricingModificationsReport(queryParams)

	return (
		<AnimatedEntrance show>
			<Table
				query={query}
				columns={columns}
				queryParamState={queryParams}
				setQueryParamState={setQueryParams}
				pagination={pagination}
				setPagination={setPagination}
				dataExport={dataExport}
				pinnedQueryFilters={[TimeAgoQueryFilter, CustomerQueryFilter]}
				storeColumnVisibility="pricing-modifications-report-columns"
			/>
		</AnimatedEntrance>
	)
}

const columns: Column<PricingModificationsReportLine>[] = [
	{
		Header: "Line type",
		disableSortBy: true,
		accessor: ({ lineItemType }) => lineItemType.description,
	},
	{
		Header: "Part",
		disableSortBy: true,
		accessor: ({ part }) => (part ? `${part.partNumber}: ${part.description}` : "-"),
	},
	{
		Header: "Price charged",
		disableSortBy: true,
		accessor: ({ actualPrice }) => formatCurrency(actualPrice),
	},
	{
		Header: "System price",
		disableSortBy: true,
		accessor: ({ calculatedPrice }) => formatCurrency(calculatedPrice),
	},
	{
		Header: "Created by",
		disableSortBy: true,
		accessor: ({ user }) => user.name,
	},
	{
		Header: "Part order",
		disableSortBy: true,
		hiddenByDefault: true,
		accessor: ({ partOrderLineItem }) => partOrderLineItem?.partOrder.orderId || "-",
	},
	{
		Header: "Dispatch",
		disableSortBy: true,
		hiddenByDefault: true,
		accessor: ({ workOrderLineItem }) =>
			workOrderLineItem?.workorder.dispatch?.dispatchNumber || "-",
	},
	{
		Header: "Delivery order",
		disableSortBy: true,
		hiddenByDefault: true,
		accessor: ({ deliveryOrderLineItem }) => deliveryOrderLineItem?.deliveryOrderId || "-",
	},
	{
		Header: `Date created (${getTimezoneAbbreviation()})`,
		id: "createdDate",
		hiddenByDefault: true,
		accessor: ({ deliveryOrderLineItem }) => deliveryOrderLineItem?.deliveryOrderId || "-",
	},
]

const initialPricingModificationsReportUrlState: PricingModificationsReportUrlState = {
	ordering: null,
	customer: null,
	startDate: getStartAndEndDateQueryParams(TimeAgo.DaysAgo30)[0],
	endDate: null,
}

import { FC } from "react"

import { CustomerSystem, usePartSystems } from "@ncs/ncs-api"

import { ExtendableSelectProps, Select } from "../inputs"

export interface PartSystemSelectorProps
	extends ExtendableSelectProps<CustomerSystem, CustomerSystem> {}

export const PartSystemSelector: FC<PartSystemSelectorProps> = ({ value, onChange, ...rest }) => {
	const [systems, isLoading] = usePartSystems()

	return (
		<Select
			disabled={isLoading}
			label="Part system"
			fillContainer
			{...rest}
			value={value?.id.toString() ?? null}
			onChange={(newValue, newOption) => onChange(newOption ?? null)}
			options={systems ?? []}
			valueAccessor={(option) => option.id.toString()}
			textAccessor="modelName"
		/>
	)
}

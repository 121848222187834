import { FC } from "react"

import { PartCategory, usePartCategories } from "@ncs/ncs-api"

import { ExtendableSelectProps, Select } from "../inputs"

export interface PartCategorySelectorProps
	extends ExtendableSelectProps<PartCategory, PartCategory> {}

export const PartCategorySelector: FC<PartCategorySelectorProps> = ({
	value,
	onChange,
	...rest
}) => {
	const [categories, isLoading] = usePartCategories()

	return (
		<Select
			disabled={isLoading}
			label="Part category"
			fillContainer
			{...rest}
			value={value?.id.toString() ?? null}
			onChange={(newValue, newOption) => onChange(newOption ?? null)}
			options={categories ?? []}
			valueAccessor={(option) => option.id.toString()}
			textAccessor="name"
		/>
	)
}

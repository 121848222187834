import React from "react"

import PropTypes from "prop-types"

import { withCallApi, withDynamicTableFilter } from "@ncs/bricks/components"

import PartSelector from "../../components/Selectors/PartSelector"

class PartFilter extends React.PureComponent {
	handlePartSelected = (part) => {
		let text = !!part ? `(${part.part_number}) ${part.description}` : ""
		this.props.onChange(part, text)
		typeof this.props.onPartSelected === "function" && this.props.onPartSelected(part)
	}

	render = () => (
		<PartSelector
			selectedSuggestion={this.props.value || null}
			onPartSelected={this.handlePartSelected}
			{...this.props}
		/>
	)
}

PartFilter.defaultProps = {
	service: true,
	skipRestrictedCheck: true,
}

PartFilter.propTypes = {
	onPartSelected: PropTypes.func,
	service: PropTypes.bool,
	includeAllParts: PropTypes.bool,
	skipRestrictedCheck: PropTypes.bool,
}

export default withCallApi(withDynamicTableFilter("part")(PartFilter))

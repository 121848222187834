import React from "react"
import PropTypes from "prop-types"
import { Input } from "../../components"

import { CustomInputProps } from "./CustomInput"

export interface CustomInputAsDisplayProps {
	labelText: string
	value?: string | number
	id?: string
	fullControlProps?: CustomInputProps["formControlProps"]
	inputProps?: CustomInputProps["inputProps"]
}

const CustomInputAsDisplay: React.FC<CustomInputAsDisplayProps> = ({
	labelText,
	value,
	id,
	fullControlProps,
	inputProps,
	...rest
}) => {
	const inputId = id || labelText.replace(/ /g, "")

	return (
		<Input
			labelText={labelText}
			id={inputId}
			formControlProps={{
				fullWidth: true,
				...fullControlProps,
			}}
			inputProps={{
				...inputProps,
				value: value ?? "",
				disabled: true,
			}}
			{...rest}
		/>
	)
}

CustomInputAsDisplay.propTypes = {
	labelText: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	id: PropTypes.string,
	fullControlProps: PropTypes.object,
	inputProps: PropTypes.object,
}

export default React.memo(CustomInputAsDisplay)

import { FC, useState } from "react"

import dayjs from "dayjs"
import { Cell, Column } from "react-table"

import {
	APPLICATION,
	FreightMin,
	FreightMinsQueryParams,
	useFreightMins,
	useUserCanUse,
} from "@ncs/ncs-api"
import {
	dateRangeIsActive,
	displayDate,
	formatCurrency,
	getTimezoneAbbreviation,
} from "@ncs/ts-utils"
import {
	Box,
	Button,
	CustomerQueryFilter,
	EmptyValueDash,
	Icon,
	ReactTableSortType,
	Table,
	Tooltip,
	useUrlState,
} from "@ncs/web-legos"

import { ActiveOnlyQueryFilter } from "~/components"

import { DefaultsCustomersQueryFilter, EditFreightMinModal } from "./components"

export type MinimumsTabUrlState = FreightMinsQueryParams

export const MinimumsTab: FC = () => {
	const canEdit = useUserCanUse([
		APPLICATION.FreightRatesAdmin,
		APPLICATION.CustomerFreightExceptions,
	])
	const [showCreate, setShowCreate] = useState(false)
	const [toEdit, setToEdit] = useState<FreightMin | null>(null)

	const [params, { setUrlState }] = useUrlState(defaultUrlState)

	const [mins, minsLoading] = useFreightMins({ params })

	const showFiltersByDefault = Object.values(params).some((p) => p)

	return (
		<>
			{canEdit && (
				<Box d="flex" justifyContent="flex-end" mb={4}>
					<Button
						icon="plus-circle"
						onClick={() => setShowCreate(true)}
						variant="secondary-cta"
					>
						Create Freight Minimum
					</Button>
				</Box>
			)}

			<Table
				data={mins ?? []}
				columns={columns}
				isLoading={minsLoading}
				onRowClick={canEdit ? ({ original }) => setToEdit(original) : undefined}
				queryParamState={params}
				setQueryParamState={setUrlState}
				toggledQueryFilters={[
					ActiveOnlyQueryFilter,
					DefaultsCustomersQueryFilter,
					CustomerQueryFilter,
				]}
				showToggledFiltersByDefault={showFiltersByDefault}
			/>

			{(showCreate || !!toEdit) && (
				<EditFreightMinModal
					toEdit={toEdit}
					onClose={() => {
						setToEdit(null)
						setShowCreate(false)
					}}
				/>
			)}
		</>
	)
}

const columns: Column<FreightMin>[] = [
	{
		Header: "Amount $",
		accessor: "amount",
		Cell: ({ row: { original } }: Cell<FreightMin>) => formatCurrency(original.amount),
		sortType: ReactTableSortType.Number,
	},
	{
		Header: "Customer",
		accessor: ({ customerName, customerNumber }) =>
			customerName ? `(${customerNumber}) ${customerName}` : "-",
	},
	{
		Header: "Freight class",
		accessor: ({ freightClassName }) => freightClassName || "-",
	},
	{
		Header: `Start (${getTimezoneAbbreviation()})`,
		accessor: ({ startDate }) => new Date(startDate),
		Cell: ({ row: { original } }: Cell<FreightMin>) => displayDate(original.startDate),
		sortType: ReactTableSortType.Datetime,
	},
	{
		Header: `End (${getTimezoneAbbreviation()})`,
		accessor: ({ endDate }) => new Date(endDate),
		Cell: ({ row: { original } }: Cell<FreightMin>) => displayDate(original.endDate),
		sortType: ReactTableSortType.Datetime,
	},
	{
		Header: "Status",
		Cell: ({ row: { original } }: Cell<FreightMin>) => {
			return (
				dateRangeIsActive(original.startDate, original.endDate) ?
					<>
						<Icon icon="check" /> Active
					</>
				: dayjs(original.endDate).isBefore(original.startDate) ?
					<Tooltip title="Date range can never be active because end date comes before start date">
						<Icon icon="exclamation-triangle" />
					</Tooltip>
				:	<EmptyValueDash />
			)
		},
	},
	{
		Header: `Created (${getTimezoneAbbreviation()})`,
		accessor: ({ createdOn }) => new Date(createdOn),
		Cell: ({ row: { original } }: Cell<FreightMin>) => displayDate(original.createdOn),
		sortType: ReactTableSortType.Datetime,
		hiddenByDefault: true,
	},
	{
		Header: "Created by",
		accessor: "userCreated",
		hiddenByDefault: true,
	},
	{
		Header: `Last modified (${getTimezoneAbbreviation()})`,
		accessor: ({ modifiedOn }) => new Date(modifiedOn),
		Cell: ({ row: { original } }: Cell<FreightMin>) => displayDate(original.modifiedOn),
		sortType: ReactTableSortType.Datetime,
		hiddenByDefault: true,
	},
	{
		Header: "Last modified by",
		accessor: "userLastModified",
		hiddenByDefault: true,
	},
]

const defaultUrlState: MinimumsTabUrlState = {
	active: null,
	defaultsOnly: null,
	customersOnly: null,
	customer: null,
}

import { FC } from "react"

import { ExtendableSelectProps, Select } from "../inputs"

export interface SiteSurveyStatusSelectorProps
	extends ExtendableSelectProps<(typeof options)[number], boolean> {}

const options = [
	{ value: true, text: "Submitted" },
	{ value: false, text: "Not submitted" },
]

export const SiteSurveyStatusSelector: FC<SiteSurveyStatusSelectorProps> = ({
	value,
	onChange,
	...rest
}) => {
	return (
		<Select
			label="Submission status"
			{...rest}
			value={typeof value === "boolean" ? String(value) : null}
			valueAccessor={(option) => String(option.value)}
			onChange={(newValue, newOption) => onChange(newOption?.value ?? null)}
			options={options}
		/>
	)
}

import { FC, Fragment } from "react"

import { WorkOrder } from "@ncs/ncs-api"
import { displayDate } from "@ncs/ts-utils"
import {
	Box,
	CssGridTable,
	EmptyValueDash,
	ExtendableModalProps,
	HeadingDivider,
	Icon,
	Modal,
	Paragraph,
} from "@ncs/web-legos"

export interface MachineDetailsModalProps extends ExtendableModalProps {
	machine: WorkOrder["machines"][number]
}

export const MachineDetailsModal: FC<MachineDetailsModalProps> = ({ machine, ...rest }) => {
	const { equipment } = machine

	return (
		<Modal {...rest} title="Machine Contracts and Warranties">
			<HeadingDivider variant="h5">Contracts</HeadingDivider>
			{equipment.contracts.length ?
				<CssGridTable
					gridTemplateColumns="1fr 1fr 1fr 1fr"
					alignItems="flex-start"
					headers={["Type", "Eff Date", "Exp Date", "Is Active?"]}
					cells={equipment.contracts.map((c) => {
						return (
							<Fragment key={c.id}>
								<Paragraph>{c.type}</Paragraph>
								<Paragraph>
									{displayDate(c.effectiveDate, "", { formatInUtc: true })}
								</Paragraph>
								<Paragraph>
									{displayDate(c.expirationDate, "", { formatInUtc: true })}
								</Paragraph>
								{c.isActive ?
									<Box d="flex" gap={0.5} alignItems="center">
										<Icon icon="check" />
										<Paragraph>Active</Paragraph>
									</Box>
								:	<EmptyValueDash />}
							</Fragment>
						)
					})}
				/>
			:	<Paragraph small secondary mt={-1}>
					No contracts found
				</Paragraph>
			}

			<HeadingDivider variant="h5">Warranties</HeadingDivider>
			<CssGridTable
				columnGap={2}
				rowGap={0.5}
				gridTemplateColumns="auto 1fr"
				headers={["Type", "Exp Date"]}
				cells={[
					<Paragraph key={0}>Frame</Paragraph>,
					<Paragraph key={1}>
						{displayDate(equipment.frameWarrantyExpireDate, "", {
							formatInUtc: true,
						}) || <EmptyValueDash />}
					</Paragraph>,
					<Paragraph key={2}>Labor</Paragraph>,
					<Paragraph key={3}>
						{displayDate(equipment.laborWarrantyExpireDate, "", {
							formatInUtc: true,
						}) || <EmptyValueDash />}
					</Paragraph>,
					<Paragraph key={4}>Parts</Paragraph>,
					<Paragraph key={5}>
						{displayDate(equipment.partsWarrantyExpireDate, "", {
							formatInUtc: true,
						}) || <EmptyValueDash />}
					</Paragraph>,
				]}
			/>
		</Modal>
	)
}

import { FC, useEffect, useMemo, useRef, useState } from "react"

import { PosPart, PosPartQueryParams, usePosPart, usePosParts } from "@ncs/ncs-api"

import { ExtendableSearchableSelectProps, SearchableSelect } from "../inputs"
import { Paragraph } from "../typography"

export interface PosPartSelectorProps extends ExtendableSearchableSelectProps<PosPart> {
	params?: Partial<PosPartQueryParams>
	initialId?: string | null
	hideParents?: boolean
	hideChildren?: boolean
	hideRelationshipLabels?: boolean
}

export const PosPartSelector: FC<PosPartSelectorProps> = ({
	params,
	value,
	onChange,
	initialId,
	hideParents,
	hideChildren,
	hideRelationshipLabels,
	...rest
}) => {
	const [search, setSearch] = useState<string | null>(null)
	const { data, isLoading } = usePosParts({
		params: {
			parent: hideParents === true ? false : undefined,
			child: hideChildren === true ? false : undefined,
			...params,
			search,
		},
		pageSize: 15,
	})

	// The initial ID may or may not be in the Parts call, so we'll try and get it specifically here.
	const [initialPart] = usePosPart(initialId ?? null)
	const hasSetInitial = useRef(false)

	useEffect(() => {
		if (hasSetInitial.current === false && !!initialId && initialPart && !value) {
			hasSetInitial.current = true
			onChange(initialPart)
		}
	}, [initialPart, initialId, onChange, value])

	const options = useMemo(() => {
		return value ?
				// `value` may or may not be present in the data, especially at first.
				// Inject it here, and then filter it out of the data to prevent duplicate.
				[value, ...data.filter((d) => d.id !== value.id)]
			:	data
	}, [data, value])

	const getOptionLabel = (option: PosPart) => {
		if (option.isParent) {
			return `${option.title} (Parent)`
		}

		if (option.isChild) {
			;`${option.onlinePartNumber} - ${option.title} (Child)`
		}

		return `${option.onlinePartNumber} - ${option.title}`
	}

	return (
		<SearchableSelect
			isLoading={isLoading}
			label="Product"
			getOptionLabel={getOptionLabel}
			renderOption={(option) => {
				if (option.isParent) {
					return (
						<div>
							<Paragraph>{option.title}</Paragraph>
							{!hideRelationshipLabels && (
								<Paragraph small color="secondary">
									Parent
								</Paragraph>
							)}
						</div>
					)
				}
				if (option.isChild) {
					return (
						<div>
							<Paragraph>
								{option.onlinePartNumber} - {option.title}
							</Paragraph>
							{!hideRelationshipLabels && (
								<Paragraph small color="secondary">
									Child
								</Paragraph>
							)}
						</div>
					)
				}
				return `${option.onlinePartNumber} - ${option.title}`
			}}
			{...rest}
			value={value}
			options={options}
			onInputChange={setSearch}
			onItemSelect={onChange}
		/>
	)
}

import React, { Component } from "react"

import TicketInfo from "./TicketInfo"
import FormValidator from "@ncs/bricks/util/formValidator"
import { withCallApi } from "~/components"
import {
	patchTicket,
	createRelatedTicket,
	deleteRelatedTicket,
	getRelatedTickets,
} from "@ncs/mortar/redux/services/ticketing"
import { listCustomerEquipment } from "@ncs/mortar/redux/services/customers"
import { loadLookups } from "@ncs/mortar/redux/services/lookups"
import callApi from "@ncs/bricks/redux/services/callApi"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { APPLICATIONS } from "@ncs/mortar/util/constants"
import { meetsAppRestriction, userId } from "@ncs/bricks/redux/selectors/auth"
import { withRouter } from "react-router-dom"
import cloneDeep from "lodash/cloneDeep"

class TicketInfoContainer extends Component {
	constructor(props) {
		super(props)

		this.validations = {
			description: {
				stateName: "description",
				isRequired: true,
				maxLength: 1000,
			},
			is_nuisance: {
				stateName: "isNuisance",
				type: "bool",
			},
			is_urgent: {
				stateName: "isUrgent",
				type: "bool",
			},
			initial_comment: {
				stateName: "comment",
				isRequried: true,
				maxLength: 5000,
			},
			ticket_requestor_name: {
				stateName: "requestorText",
				isRequired: false,
				maxLength: 255,
				minLength: 1,
			},
		}

		this.formValidator = new FormValidator(this, this.validations)

		this.state = {
			isEditing: false,
			isSaving: false,
			selectedStatus: this.props.ticket.status.id,
			selectedType: this.props.ticket.ticket_type.id,
			selectedSubtype: this.props.ticket.subtype ? this.props.ticket.subtype.id : null,
			selectedPriority: this.props.ticket.priority ? this.props.ticket.priority.id : null,
			selectedAssignedTo:
				this.props.ticket.assigned_to ? this.props.ticket.assigned_to : null,
			selectedTicketTech: this.props.ticket.tech ? this.props.ticket.tech : null,
			selectedGroup: this.props.ticket.ticket_type.group.id,
			estimatedCompletionDate: null,
			selectedCustomer: this.props.ticket.customer,
			valid: true,
			canEdit: false,
			isAssigned: false,
			isCreator: false,
			isAddingDuplicate: false,
			isDuplicate: false,
			selectedTicket: {},
			relatedTicket: {},
			selectedCustomerEquipment: this.props.ticket.customer_equipment_id,
			customerEquipment: [],
			isService: this.props.ticket.ticket_type.group.id === 3 ? true : false,
			selectedRequestor:
				this.props.ticket.tech_id ? 1
				: this.props.ticket.ticket_requestor_id ? 2
				: null,
			...this.formValidator.getInitialFormState(),
		}
	}

	handleSetInputRef = (input) => {
		this.input = input
	}

	componentDidMount = () => {
		this.loadStateFromProps()
		this.canEdit()
		this.getRelatedTicket()
		if (this.props.ticket.customer_id) {
			this.props
				.callApi(listCustomerEquipment(this.props.ticket.customer_id))
				.then((result) => this.setState({ customerEquipment: result.payload }))
		}
	}

	componentDidUpdate = (prevProps) => {
		if (this.props.ticket.id !== prevProps.ticket.id) {
			if (this.props.techSupportView) {
				this.loadStateFromProps()
			} else {
				this.props.refreshTicket()
			}

			this.getRelatedTicket()
		}
	}

	getRelatedTicket = () => {
		this.props.callApi(getRelatedTickets(this.props.ticket.id)).then((result) => {
			if (result.payload.length > 0) {
				this.setState({
					relatedTicket: result.payload[0],
					isLoadingTicket: false,
					isDuplicate: true,
				})
			} else {
				this.setState({ isDuplicate: false })
			}
		})
	}

	loadStateFromProps = () => {
		this.formValidator.setFormStateFromObject(this.props.ticket)
	}

	handleEstimatedCompleteUpdated = (d) => {
		//let converted = (d instanceof moment) ? d.format("YYYY-MM-DD") : d
		this.setState({ estimatedCompletionDate: d.format() })
	}

	handleStartEditing = () => {
		this.setState({ isEditing: true })
		setTimeout(() => {
			this.input && typeof this.input.focus === "function" && this.input.focus()
		}, 50)
		this.formValidator.dataIsValid()
	}

	handleCancelEditing = () => {
		this.setState({
			isEditing: false,
			showValidationErrors: false,
			selectedStatus: null,
			selectedPriority: null,
			selectedSubtype: this.props.ticket.subtype ? this.props.ticket.subtype.id : null,
			selectedType: this.props.ticket.ticket_type.id,
			estimatedCompletionDate: null,
		})
		this.loadStateFromProps()
	}

	handleSave = () => {
		if (!this.formValidator.dataIsValid()) {
			this.setState({ showValidationErrors: true })
			return
		}

		this.setState({ isSaving: true, isEditing: false })
		let ticket = {
			ticket_requestor_id:
				this.state.selectedTicketRequestor ? this.state.selectedTicketRequestor : null,
		}
		for (const [prop, config] of Object.entries(this.validations)) {
			ticket[prop] = this.state[config.stateName]
		}
		ticket.ticket_type_id =
			this.state.selectedType ? this.state.selectedType : this.props.ticket.ticket_type.id
		ticket.status_id =
			this.state.selectedStatus ? this.state.selectedStatus : this.props.ticket.status.id
		ticket.subtype_id =
			this.state.selectedSubtype ? this.state.selectedSubtype : this.state.selectedSubtype
		ticket.priority_id =
			this.state.selectedPriority ? this.state.selectedPriority
			: this.props.ticket.priority ? this.props.ticket.priority.id
			: null
		ticket.assigned_to_id =
			this.state.selectedAssignedTo ? this.state.selectedAssignedTo._id : null
		ticket.tech_id = this.state.selectedTicketTech ? this.state.selectedTicketTech._id : null
		ticket.estimated_completion_date =
			this.state.estimatedCompletionDate ?
				this.state.estimatedCompletionDate
			:	this.props.ticket.estimated_completion_date
		ticket.customer_equipment_id =
			this.state.selectedCustomerEquipment ? this.state.selectedCustomerEquipment : null
		ticket.customer_id = this.state.selectedCustomer ? this.state.selectedCustomer._id : null
		let api = patchTicket(this.props.ticket.id, ticket)
		this.props.callApi(api).then((result) => {
			this.props.refreshTicket()
			this.setState({ isSaving: false })
		})
	}

	handleCloseTicket = () => {
		this.setState({ isSaving: true, isEditing: false })
		let ticket = cloneDeep(this.props.ticket)
		ticket.status_id = 4
		ticket.status = null
		this.props.callApi(patchTicket(this.props.ticket.id, ticket)).then((result) => {
			this.props.refreshTicket()
			this.setState({ isSaving: false })
		})
	}

	validateSelects = () => {
		const { selectedType } = this.state
		let valid = true
		if (!selectedType) {
			valid = false
		}
		this.setState({ valid: valid })
	}

	handleStatusChange = (status) => {
		this.setState({ selectedStatus: status }, () => this.validateSelects())
	}

	handleTypeChange = (type) => {
		this.setState({ selectedType: type, selectedSubtype: null }, () => this.validateSelects())
	}

	handleSubtypeChange = (subtype) => {
		this.setState({ selectedSubtype: subtype }, () => this.validateSelects())
	}

	handlePriorityChange = (priority) => {
		this.setState({ selectedPriority: priority })
	}
	handleTicketAssignedToChange = (selectedAssignedTo) => {
		this.setState({ selectedAssignedTo })
	}
	handleTicketTechChange = (selectedTicketTech) => {
		this.setState({ selectedTicketTech })
	}
	handleTicketCustomerChange = (selectedCustomer) => {
		this.setState({ selectedCustomer }, () => {
			if (selectedCustomer !== null) {
				this.props.callApi(listCustomerEquipment(selectedCustomer._id)).then((result) =>
					this.setState({
						customerEquipment: result.payload,
						selectedCustomerEquipment: null,
					})
				)
			} else {
				this.setState({ customerEquipment: [], selectedCustomerEquipment: null })
			}
		})
	}

	handleGroupChange = (selectedGroup) => {
		this.setState(
			{
				selectedGroup: selectedGroup,
				selectedType: null,
				selectedSubtype: null,
			},
			() => this.validateSelects()
		)
	}
	handleAddingDuplicate = () => {
		this.handleCancelEditing()
		this.setState({ isAddingDuplicate: true })
	}
	handleRemoveDuplicate = () => {
		this.props
			.callApi(deleteRelatedTicket(this.props.ticket.id, this.state.relatedTicket.id))
			.then(this.setState({ isDuplicate: false }))
	}

	handleCancleAddingDuplicate = () => {
		this.setState({ isAddingDuplicate: false, selectedTicket: {} })
	}
	handleConfirmDuplicate = () => {
		this.setState({ isEditing: false })
		let data = {
			child_ticket_id: this.props.ticket.id,
			parent_ticket_id: this.state.selectedTicket.id,
		}
		this.props.callApi(createRelatedTicket(this.props.ticket.id, data)).then((result) => {
			this.props.refreshTicket()
			this.getRelatedTicket()
		})
		this.setState({ isAddingDuplicate: false, isDuplicate: true })
	}
	handleRelatedTicketSelected = (selectedTicket) => {
		this.setState({ selectedTicket })
	}
	handleViewRelatedTicket = () => {
		this.props.history.push(`${this.state.relatedTicket.parent_ticket_id}`)
		this.props.refreshTicket()
		this.getRelatedTicket()
	}

	canEdit = () => {
		let admin = this.props.isTicketingAdmin()
		let user = this.props.userId()
		if (this.state.isService === true) {
			return this.setState({ canEdit: true })
		}

		if (admin) {
			return this.setState({ canEdit: true })
		}
		if (user === parseInt(this.props.ticket.created_by._id)) {
			return this.setState({ canEdit: true, isCreator: true })
		}
		if (parseInt(this.props.ticket.assigned_to_id) === user) {
			return this.setState({ canEdit: true, isAssigned: true })
		}
	}

	handleCustomerEquipmentChange = (selectedCustomerEquipment) => {
		this.setState({ selectedCustomerEquipment })
	}
	handleClickCustomer = () => {
		this.props.history.push(`/customer/details/${this.props.ticket.customer._id}`)
	}
	handleRequestorTextChange = (requestorText) => {
		this.setState({ requestorText })
	}
	handleRequestorTypeChange = (selectedRequestor) => {
		if (selectedRequestor === 1) {
			this.setState({ selectedRequestor, ticket_requestor_name: null, requestorText: "" })
		} else {
			this.setState({ selectedRequestor, selectedTicketTech: null })
		}
	}
	handleSelectedTicketRequestorChange = (selectedTicketRequestor) => {
		this.setState({ selectedTicketRequestor, requestorText: "" })
	}

	render() {
		const isAdmin = this.props.isTicketingAdmin()
		const isServiceUser = this.props.isServiceUser()
		return (
			<TicketInfo
				ticket={this.props.ticket}
				isEditing={this.state.isEditing}
				isSaving={this.state.isSaving}
				onStartEditingTicketInfo={this.handleStartEditing}
				onSaveTicketInfo={this.handleSave}
				onCancelEditingTicketInfo={this.handleCancelEditing}
				setInputRef={this.handleSetInputRef}
				statuses={this.props.ticketStatuses}
				types={this.props.ticketTypes}
				subtypes={this.props.ticketSubtypes}
				priorities={this.props.ticketPriorities}
				onTicketStatusChange={this.handleStatusChange}
				onTicketTypeChange={this.handleTypeChange}
				onTicketSubtypeChange={this.handleSubtypeChange}
				onTicketPriorityChange={this.handlePriorityChange}
				selectedStatus={this.state.selectedStatus}
				selectedType={this.state.selectedType}
				selectedSubtype={this.state.selectedSubtype}
				selectedPriority={this.state.selectedPriority}
				selectedAssignedTo={this.state.selectedAssignedTo}
				selectedTicketTech={this.state.selectedTicketTech}
				onTicketAssignedToChange={this.handleTicketAssignedToChange}
				onTicketTechChange={this.handleTicketTechChange}
				onEstimatedCompletionDateUpdated={this.handleEstimatedCompleteUpdated}
				estimatedCompletionDate={this.state.estimatedCompletionDate}
				isAdmin={isAdmin}
				isServiceUser={isServiceUser}
				onTicketGroupChange={this.handleGroupChange}
				selectedGroup={this.state.selectedGroup}
				isValid={this.state.valid}
				userId={this.props.userId()}
				canEdit={this.state.canEdit}
				isCreator={this.state.isCreator}
				isAssigned={this.state.isAssigned}
				onCloseTicket={this.handleCloseTicket}
				onAddDuplicate={this.handleAddingDuplicate}
				onConfirmDuplicate={this.handleConfirmDuplicate}
				isAddingDuplicate={this.state.isAddingDuplicate}
				onCancelAddDuplicate={this.handleCancleAddingDuplicate}
				isDuplicate={this.state.isDuplicate}
				onRemoveDuplicate={this.handleRemoveDuplicate}
				selectedTicket={this.state.selectedTicket}
				onRelatedTicketSelected={this.handleRelatedTicketSelected}
				relatedTicket={this.state.relatedTicket.parent_ticket_id}
				onViewRelatedTicket={this.handleViewRelatedTicket}
				selectedCustomer={this.state.selectedCustomer}
				onCustomerSelected={this.handleTicketCustomerChange}
				customerEquipment={this.state.customerEquipment}
				selectedCustomerEquipment={this.state.selectedCustomerEquipment}
				onCustomerEquipmentChange={this.handleCustomerEquipmentChange}
				onClickCustomer={this.handleClickCustomer}
				selectedRequestor={this.state.selectedRequestor}
				onRequestorTypeChange={this.handleRequestorTypeChange}
				onRequestorTextChange={this.handleRequestorTextChange}
				onSelectedTicketRequestorChange={this.handleSelectedTicketRequestorChange}
				// requestorText={this.state.requestorText}
				selectedTicketRequestor={this.state.selectedTicketRequestor}
				{...this.formValidator.generateFormProps()}
			/>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		isTicketingAdmin: () => {
			return meetsAppRestriction(state.auth, APPLICATIONS.TicketingAdmin)
		},
		isServiceUser: () => {
			return meetsAppRestriction(state.auth, APPLICATIONS.TicketingTechSupportUser)
		},
		userId: () => {
			return userId(state.auth)
		},
		...state.lookups,
	}
}

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ callApi, loadLookups }, dispatch),
})
TicketInfoContainer.defaultProps = {
	techSupportView: false,
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withCallApi(withRouter(TicketInfoContainer)))

import React, { useMemo } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Tabs, Tab, makeStyles } from "@material-ui/core"
import { Card, CardBody, CardHeader } from "../../components"
import { CardHeaderProps } from "../Card/CardHeader"

import styles from "../../assets/jss/material-dashboard-pro-react/components/customTabsStyle"

const useStyles = makeStyles(styles)

export interface CustomTabsProps {
	/** The default opened tab - index starts at 0. */
	value?: number

	/**
	 * Function for changing the value. Note: If you pass this function,
	 * the default function that changes the tabs will no longer work,
	 * so you need to create the changing functionality as well.
	 */
	changeValue?: () => void

	headerColor?: CardHeaderProps["color"]
	title?: string
	tabs: {
		tabName: string
		tabIcon?: string | React.ReactElement | null
		tabContent: React.ReactNode
	}[]
	rtlActive?: boolean
	plainTabs?: boolean
}

const CustomTabs: React.FC<CustomTabsProps> = ({
	value: propsValue = 0,
	changeValue,
	headerColor,
	plainTabs,
	tabs,
	title,
	rtlActive,
}) => {
	const classes = useStyles()
	const [value, setValue] = React.useState(propsValue)

	const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setValue(value)
	}

	const cardTitle = useMemo(
		() =>
			classNames({
				[classes.cardTitle]: true,
				[classes.cardTitleRTL]: rtlActive,
			}),
		[classes, rtlActive]
	)

	return (
		<Card plain={plainTabs}>
			<CardHeader color={headerColor} plain={plainTabs}>
				{title !== undefined ?
					<div className={cardTitle}>{title}</div>
				:	null}
				<Tabs
					value={value}
					onChange={changeValue ? changeValue : handleChange}
					classes={{
						root: classes.tabsRoot,
						indicator: classes.displayNone,
					}}
					variant="scrollable"
					scrollButtons="auto"
				>
					{tabs.map((tab, key) => (
						<Tab
							classes={{
								root: classes.tabRootButton,
								selected: classes.tabSelected,
								wrapper: classes.tabWrapper,
							}}
							key={key}
							label={tab.tabName}
							icon={tab.tabIcon ?? undefined}
						/>
					))}
				</Tabs>
			</CardHeader>
			<CardBody>
				{tabs.map((prop, key) => {
					if (key === value) {
						return <div key={key}>{prop.tabContent}</div>
					}
					return null
				})}
			</CardBody>
		</Card>
	)
}

CustomTabs.propTypes = {
	// the default opened tab - index starts at 0
	value: PropTypes.number,
	// function for changing the value
	// note, if you pass this function,
	// the default function that changes the tabs will no longer work,
	// so you need to create the changing functionality as well
	changeValue: PropTypes.func,
	headerColor: PropTypes.oneOf(["warning", "success", "danger", "info", "primary", "rose"]),
	title: PropTypes.string,
	tabs: PropTypes.arrayOf(
		PropTypes.shape({
			tabName: PropTypes.string.isRequired,
			tabIcon: PropTypes.oneOfType([
				PropTypes.string.isRequired,
				PropTypes.element.isRequired,
			]),
			tabContent: PropTypes.node.isRequired,
		}).isRequired
	).isRequired,
	rtlActive: PropTypes.bool,
	plainTabs: PropTypes.bool,
}

export default CustomTabs

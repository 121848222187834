import { FC } from "react"

import { IconButton, IconButtonProps } from "../buttons"
import { Box, BoxProps } from "../layout"
import { Label, LabelProps } from "./Label"
import { Paragraph, ParagraphProps } from "./Paragraph"

export interface LabeledDataWithButtonProps extends BoxProps {
	label?: string
	onClick?: () => void
	iconButtonContainerProps?: BoxProps
	iconButtonProps?: IconButtonProps
	paragraphProps?: ParagraphProps
	labelProps?: LabelProps
}

/**
 * Looks like `LabeledData`, except it also renders an IconButton after the text.
 */
export const LabeledDataWithButton: FC<LabeledDataWithButtonProps> = ({
	label,
	onClick,
	iconButtonContainerProps,
	iconButtonProps,
	paragraphProps,
	labelProps,
	children,
	...rest
}) => {
	return (
		<Box {...rest}>
			{!!label && <Label {...labelProps}>{label}</Label>}
			<Box d="flex" mt={0.1}>
				<Paragraph {...paragraphProps}>{children}</Paragraph>
				{!!onClick && (
					<Box mt={-0.45} {...iconButtonContainerProps}>
						<IconButton onClick={onClick} {...iconButtonProps} />
					</Box>
				)}
			</Box>
		</Box>
	)
}

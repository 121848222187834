import {
	APPLICATION,
	GenericLineItem,
	LineItemPriceOverride,
	PartOrder,
	PartOrderLineItem,
	PartOrderPickList,
	PartOrderStatusId,
	PickListStatus,
	useAuth,
	usePriceOverrideReasons,
	useUserCanUse,
} from "@ncs/ncs-api"
import { extractNumber } from "@ncs/ts-utils"

import { openPartOrderStatuses, paymentIncompleteStatuses } from "~/util/part-orders"

export type PartOrderLineItemToLineItem = (lineItem: PartOrderLineItem) => GenericLineItem

/** Map `PartOrderLineItem` to `GenericLineItem`. */
export const usePartOrderLineItemToLineItem = (): PartOrderLineItemToLineItem => {
	const [priceOverrideReasons, overrideReasonsLoading] = usePriceOverrideReasons()

	const transform: PartOrderLineItemToLineItem = (lineItem) => {
		if (overrideReasonsLoading) {
			console.error(
				"usePartOrderLineItemToLineItem was called before the price override reasons lookup finished."
			)
		}
		if (!lineItem.lineTypeId) {
			throw new Error("Line item did not have a line type ID!")
		}

		const quantity = extractNumber(lineItem.quantity)
		const finalPrice = extractNumber(lineItem.unitPrice)
		const unitTax = extractNumber(lineItem.unitTax)
		const taxRate = finalPrice !== 0 ? unitTax / finalPrice : 0
		const overrideApprovalPending = !!lineItem.approvalsList?.some(
			(approval) => approval.pendingApproval
		)

		const genericLineItem: GenericLineItem = {
			id: lineItem.id,
			lineTypeId: lineItem.lineTypeId,
			part:
				lineItem.part ?
					{
						id: lineItem.part.id,
						partNumber: lineItem.part.partNumber,
						description: lineItem.part.description,
						partFamily: lineItem.part.partFamily,
						weight:
							lineItem.part.weight != null ?
								extractNumber(lineItem.part.weight)
							:	null,
					}
				:	null,
			description: lineItem.description,
			quantity,
			finalPrice,
			basePrice: lineItem.approvals ? finalPrice : null,
			taxRate,
			subtotal: quantity * finalPrice,
			overridePermission: lineItem.overridePermission,
			overrideApprovalPending,
			priceOverrideReason:
				overrideApprovalPending ?
					priceOverrideReasons?.find((r) => r.id === lineItem.reasonId?.toString()) ??
					null
				:	null,
			reasonComment:
				overrideApprovalPending ? lineItem.approvals?.reasonComment ?? null : null,
			requestedPrice: overrideApprovalPending ? lineItem.approvals?.rate ?? null : null,

			// We don't know this and don't care on the part order detail.
			systemGeneratedLine: false,
			originalSystemGeneratedPrice: null,
		}

		return genericLineItem
	}

	return transform
}

export const usePartOrderPermissions = (
	partOrder: PartOrder | null | undefined
): {
	canEdit: boolean
	canCancel: boolean
	canApproveInvoice: boolean
	canApproveOrder: boolean
	canReceive: boolean
	needsPoForApproval: boolean
	canEditLineItems: boolean
	canAddParts: boolean
	canAddNonParts: boolean
	canEditHold: boolean
	isOpen: boolean
	paymentNotComplete: boolean
} => {
	const { user } = useAuth()
	const isOrderHoldManager = useUserCanUse(APPLICATION.PartOrderHoldStatus)

	const canApproveInvoice = !!partOrder?.invoice.some(
		(invoice) => invoice.statusCode === PendingFieldApprovalInvoiceStatusCode
	)

	const isOnHold = partOrder?.status.id === PartOrderStatusId.Hold

	const isOpen = !!partOrder && openPartOrderStatuses.includes(partOrder.status.id)

	const canEdit = canApproveInvoice || isOnHold || isOpen

	const canCancel = canEdit

	const needsPoForApproval = !!partOrder?.poRequired && !partOrder.purchaseOrderNumber

	const canApproveOrder =
		!!partOrder &&
		[PartOrderStatusId.InProgress, PartOrderStatusId.Submitted].includes(partOrder.status.id)

	const canReceive = partOrder?.isReceivable ?? false

	// You can edit the existing line items up until point of invoicing.
	// There are extra rules around changing quantities, but for now the front end ain't
	// dealing with that.
	const canEditLineItems = partOrder?.status.id !== PartOrderStatusId.Invoiced

	// As long as there are any pending allocations, you can still add more parts.
	const canAddParts =
		!!partOrder &&
		partOrder.lineItems.some((line) =>
			line.locationLines.some((location) => location.quantityRequested)
		)

	const canAddNonParts = partOrder?.status.id !== PartOrderStatusId.Invoiced

	const canEditHold =
		isOrderHoldManager || !!partOrder?.holders?.some((h) => h.userId === user?.id)

	const paymentNotComplete = paymentIncompleteStatuses.includes(partOrder?.status.id)

	return {
		canEdit,
		canCancel,
		canApproveOrder,
		canApproveInvoice,
		canReceive,
		needsPoForApproval,
		canEditLineItems,
		canAddParts,
		canAddNonParts,
		canEditHold,
		isOpen,
		paymentNotComplete,
	}
}

export type PickListByLocation = {
	[locationId: string]: {
		location: PartOrderPickList["location"]
		pickLists: PartOrderPickList[]
		pickableLineLocations: PartOrderLineItem["locationLines"]
	}
}

export const pickListStatusOrder = {
	[PickListStatus.Open]: 1,
	[PickListStatus.ReadyToPick]: 2,
	[PickListStatus.InProgress]: 3,
	[PickListStatus.Picked]: 4,
	[PickListStatus.Removed]: 5,
}

export const PendingFieldApprovalInvoiceStatusCode = "Pending Field Approval"

export const inactivePickListStatuses = [PickListStatus.Picked, PickListStatus.Removed]

export type LineItemLocationRow = PartOrderLineItem["locationLines"][number] & {
	parentLineItemId: string
}
export type LineItemDropShipRow = PartOrderLineItem["quantityDropShipped"][number]
export type UnspecifiedLineItemRow = PartOrderLineItem | LineItemLocationRow | LineItemDropShipRow

export const isLineItemParentRow = (row: UnspecifiedLineItemRow): row is PartOrderLineItem => {
	return !!(row as PartOrderLineItem).locationLines
}

export const isLineItemLocationRow = (row: UnspecifiedLineItemRow): row is LineItemLocationRow => {
	return !!(row as LineItemLocationRow).locationId
}

export const isLineItemDropShipRow = (row: UnspecifiedLineItemRow): row is LineItemDropShipRow => {
	return !!(row as LineItemDropShipRow).purchaseOrder
}

export const getLocationShortage = (
	location: PartOrderLineItem["locationLines"][number]
): number | null => {
	const difference = location.quantityAvailable - location.quantityRequested

	return difference < 0 ? difference : null
}

export interface FulfillmentSources {
	/**
	 * Combined list of locations that have been found on pick lists and on line items.
	 */
	locations: {
		[locationId: string]: {
			locationId: string
			locationName: string
			locationNumber: string
		}
	}
	orders: {
		[purchaseOrderId: string]: PartOrderLineItem["quantityDropShipped"][number]
	}
}

export const getFulfillmentSources = (lineItems: PartOrderLineItem[]): FulfillmentSources => {
	const locations: FulfillmentSources["locations"] = {}
	const orders: FulfillmentSources["orders"] = {}

	lineItems.forEach((lineItem) => {
		lineItem.locationLines.forEach((location) => {
			locations[location.locationId] = {
				locationId: location.locationId,
				locationName: location.locationName,
				locationNumber: location.locationNumber,
			}
		})

		lineItem.quantityDropShipped.forEach((shipment) => {
			orders[shipment.purchaseOrder.toString()] = shipment
		})
	})

	return {
		locations,
		orders,
	}
}

/**
 * Take a location and the list of line items from the order and return the location lines that
 * have quantities available for picking.
 */
export const getLocationPickables = (
	locationId: string,
	lineItems: PartOrderLineItem[]
): PartOrderLineItem["locationLines"] => {
	const results: PartOrderLineItem["locationLines"] = []

	lineItems.forEach((lineItem) => {
		// Look for each line item being sourced from the passed in location ID.
		const locationLine = lineItem.locationLines.find(
			(l) => l.locationId.toString() === locationId
		)

		if (locationLine) {
			// If the location line needs something and the location has some available, add to our list
			// of pickable location lines.
			if (locationLine.quantityRequested > 0 && locationLine.quantityAvailable > 0) {
				results.push(locationLine)
			}
		}
	})

	return results
}

/**
 * Calculate quantities for the LINE ITEM ROW.
 */
export const getLineItemRowQuantities = (
	lineItem: PartOrderLineItem
): {
	quantityOrdered: number
	quantityNeeded: number
	quantityCurrentlyRequested: number
	quantityCurrentlyAvailable: number
	quantityPickable: number
	quantityPicking: number
	quantityShippable: number
	quantityShipped: number
	quantityDropShipped: number
	quantityReceived: number
	quantityShort: number
} => {
	// How much was originally asked for for the entire line?
	const quantityOrdered = extractNumber(lineItem.quantity)

	// All the currently requested amounts from each location.
	const quantityCurrentlyRequested = lineItem.locationLines.reduce((total, location) => {
		return total + location.quantityRequested
	}, 0)

	// The total amount available across all locations.
	const quantityCurrentlyAvailable = lineItem.locationLines.reduce((total, location) => {
		return total + location.quantityAvailable
	}, 0)

	// The amount that we could pick for this whole line, given how much is needed and how much is available.
	const quantityPickable = Math.min(quantityCurrentlyRequested, quantityCurrentlyAvailable)

	// Total amount being picked across all locations.
	const quantityPicking = lineItem.locationLines.reduce((total, location) => {
		return total + location.quantityPicking
	}, 0)

	// Total amount ready to ship across all locations.
	const quantityShippable = lineItem.locationLines.reduce((total, location) => {
		return total + location.quantityReadyToShip
	}, 0)

	// The amount the line has already received.
	const quantityReceived = lineItem.quantityReceived

	// The amount that's already been shipped.
	const quantityShipped = lineItem.quantityShipped

	// The amount sent through drop shipments.
	const quantityDropShipped = lineItem.quantityDropShipped.reduce((total, dropShip) => {
		return total + dropShip.quantity
	}, 0)

	// Take the original ordered amount and subtract the other totals.
	// Could this just be the sum of "requested quantity" from each of the locations?
	const quantityNeeded = Math.min(
		quantityOrdered -
			quantityPicking -
			quantityShippable -
			quantityShipped -
			quantityDropShipped,
		0
	)

	// Add up all the shortages for each line.
	const quantityShort = lineItem.locationLines.reduce((total, location) => {
		const shortage = location.quantityAvailable - location.quantityRequested

		if (shortage < 0) {
			return total + shortage
		}
		return total
	}, 0)

	return {
		quantityOrdered,
		quantityNeeded,
		quantityCurrentlyRequested,
		quantityCurrentlyAvailable,
		quantityPickable,
		quantityPicking,
		quantityShippable,
		quantityShipped,
		quantityDropShipped,
		quantityReceived,
		quantityShort,
	}
}

export const partOrderLineToPriceOverrides = ({
	line,
	partOrderId,
	partOrderOrderId,
	customerName,
	customerNumber,
}: {
	line: PartOrderLineItem
	partOrderId: string
	partOrderOrderId: string
	customerName: string
	customerNumber: string
}): LineItemPriceOverride[] => {
	return (
		line.approvalsList?.flatMap((approval): LineItemPriceOverride[] => {
			return [
				{
					id: approval.id,
					approvedOn: approval.approvedOn,
					approverComments: approval.approverComments,
					approverName: approval.approverName,
					customerName,
					customerNumber,
					currentRate: approval.rate,
					deniedOn: approval.deniedOn,
					lineDescription: line.description,
					partOrderId: partOrderId,
					partOrderOrderId: partOrderOrderId,
					requestedRate: approval.rate,
					reasonComment: approval.reasonComment,
					reasonDescription: approval.reasonDescription,
					requestorName: approval.requestedBy,
					requestedOn: approval.requestedOn,
					lineApplication: line.overridePermission?.description ?? null,
					dispatchId: null,
					dispatchNumber: null,
					lineTypeDescription: null, // Add a lookup for this?
					pendingApproval: approval.pendingApproval,
				},
			]
		}) ?? []
	)
}

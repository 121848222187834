import React, { useMemo } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import {
	FormControl,
	InputLabel,
	FormHelperText,
	Input,
	makeStyles,
	createStyles,
	InputLabelProps,
	InputProps as MaterialUIInputProps,
	FormControlProps,
} from "@material-ui/core"
import { Tooltip, makeTooltipProps } from "../../components"
import { AmbiguousTooltipProp } from "../CustomTooltip/makeTooltipProps"

import styles from "../../assets/jss/material-dashboard-pro-react/components/customInputStyle"

interface CustomInputStyleProps {
	fullWidth: boolean
}

const useStyles = makeStyles({
	...styles,
	// For consistency's sake, this will hold the input, whether it's wrapped
	// in Tooltip or not.
	...createStyles({
		inputContainer: {
			"&> .MuiInputBase-root": {
				// Because we're putting Mui component into another div, we need to do
				// this to respect the full width prop.
				width: (props: CustomInputStyleProps) => (props.fullWidth ? "100%" : "auto"),
			},
		},
	}),
})

export interface CustomInputProps {
	labelText?: React.ReactNode
	labelProps?: InputLabelProps & { center?: boolean }
	id?: string
	value?: MaterialUIInputProps["value"]
	onChange?: MaterialUIInputProps["onChange"]
	inputProps?: MaterialUIInputProps & { center?: boolean }
	fullWidth?: boolean
	formControlProps?: FormControlProps
	inputRootCustomClasses?: string
	error?: boolean
	success?: boolean
	white?: boolean
	helperText?: React.ReactNode
	multiline?: boolean
	tooltip?: AmbiguousTooltipProp
	inputRef?: MaterialUIInputProps["inputRef"]
	name?: MaterialUIInputProps["name"]
	required?: MaterialUIInputProps["required"]
}

const CustomInput: React.FC<CustomInputProps> = ({
	formControlProps,
	labelText,
	id,
	value,
	onChange = () => {},
	fullWidth = true,
	labelProps,
	inputProps,
	error,
	white,
	inputRootCustomClasses,
	success,
	helperText,
	multiline = false,
	inputRef,
	name,
	required,
	tooltip,
}) => {
	const classes = useStyles({ fullWidth })

	const { center: centerLabel = false, ...remainingLabelProps } = labelProps ?? {}
	const { center: centerInput = false, ...remainingInputProps } = inputProps ?? {}

	const labelClasses = useMemo(
		() =>
			classNames({
				[" " + classes.labelRootError]: error,
				[" " + classes.labelRootSuccess]: success && !error,
				[classes.centerLabel]: centerLabel,
			}),
		[classes, centerLabel, error, success]
	)

	const underlineClasses = useMemo(
		() =>
			classNames({
				[classes.underlineError]: error,
				[classes.underlineSuccess]: success && !error,
				[classes.underline]: true,
				[classes.whiteUnderline]: white,
			}),
		[classes, error, success, white]
	)

	const marginTop = useMemo(() => classNames(inputRootCustomClasses), [inputRootCustomClasses])

	const inputClasses = useMemo(
		() =>
			classNames({
				[classes.input]: true,
				[classes.whiteInput]: white,
				[classes.centerText]: centerInput,
			}),
		[centerInput, classes, white]
	)

	const formControlClasses = useMemo(
		() => classNames(formControlProps?.className, classes.formControl),
		[classes, formControlProps]
	)

	const helpTextClasses = useMemo(
		() =>
			classNames({
				[classes.labelRootError]: error,
				[classes.labelRootSuccess]: success && !error,
			}),
		[classes, error, success]
	)

	// Because we need to render Input both with or without the Tooltip, we'll
	// assemble the props up here so we don't need to write them out twice below.
	const inputComponentProps = {
		classes: {
			input: inputClasses,
			root: marginTop,
			disabled: classes.disabled,
			underline: underlineClasses,
		},
		id,
		name,
		multiline,
		onChange,
		value,
		inputRef,
		...remainingInputProps,
	}

	return (
		<FormControl fullWidth={fullWidth} {...formControlProps} className={formControlClasses}>
			{labelText !== undefined ?
				<InputLabel
					className={classes.labelRoot + " " + labelClasses}
					htmlFor={id}
					{...remainingLabelProps}
				>
					{labelText + (required ? " *" : "")}
				</InputLabel>
			:	null}

			{tooltip ?
				<Tooltip
					disableFocusListener
					containerProps={{
						className: classes.inputContainer,
					}}
					{...makeTooltipProps(tooltip)}
				>
					<Input {...inputComponentProps} />
				</Tooltip>
			:	<div className={classes.inputContainer}>
					<Input {...inputComponentProps} />
				</div>
			}

			{helperText && (
				<FormHelperText id={id + "-text"} className={helpTextClasses}>
					{helperText}
				</FormHelperText>
			)}
		</FormControl>
	)
}

CustomInput.propTypes = {
	labelText: PropTypes.node,
	labelProps: PropTypes.object,
	id: PropTypes.string,
	value: PropTypes.any,
	onChange: PropTypes.func,
	inputProps: PropTypes.object,
	fullWidth: PropTypes.bool,
	formControlProps: PropTypes.object,
	inputRootCustomClasses: PropTypes.string,
	error: PropTypes.bool,
	success: PropTypes.bool,
	white: PropTypes.bool,
	helperText: PropTypes.node,
	multiline: PropTypes.bool,
	tooltip: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.shape({
			title: PropTypes.node.isRequired,
		}),
	]),
}

export default React.memo(CustomInput)

import { get, post, patch, rsaaTypes, RSAA } from "@ncs/bricks/middleware/portalApiMiddleware"

export const GET_CREDIT_MEMO_INVOICE = rsaaTypes("credit-memo-invoice/get")
export const LIST_CREDIT_MEMO_INVOICES = rsaaTypes("credit-memo-invoices/list")
export const PATCH_CREDIT_MEMO_INVOICE = rsaaTypes("credit-memo-invoice/patch")
export const APPROVE_CREDIT_MEMO_INVOICE = rsaaTypes("credit-memo-invoice/approve/post")
export const REJECT_CREDIT_MEMO_INVOICE = rsaaTypes("credit-memo-invoice/reject/post")

export const listCreditMemoInvoices = (invoiceId, querystring_params) => ({
	[RSAA]: {
		api: get(`/workorders/invoices/${invoiceId}/credit_memos/`, querystring_params),
		types: LIST_CREDIT_MEMO_INVOICES,
	},
})

export const getCreditMemoInvoice = (invoiceId, creditMemoInvoiceId) => ({
	[RSAA]: {
		api: get(`/workorders/invoices/${invoiceId}/credit_memos/${creditMemoInvoiceId}/`),
		types: GET_CREDIT_MEMO_INVOICE,
	},
})

export const patchCreditMemoInvoice = (invoiceId, creditMemoInvoiceId, data) => ({
	[RSAA]: {
		api: patch(`/workorders/invoices/${invoiceId}/credit_memos/${creditMemoInvoiceId}/`, data),
		types: PATCH_CREDIT_MEMO_INVOICE,
	},
})

export const approveCreditMemoInvoice = (invoiceId, creditMemoInvoiceId) => ({
	[RSAA]: {
		api: post(
			`/workorders/invoices/${invoiceId}/credit_memos/${creditMemoInvoiceId}/approve/`,
			null
		),
		types: APPROVE_CREDIT_MEMO_INVOICE,
	},
})

export const rejectCreditMemoInvoice = (invoiceId, creditMemoInvoiceId, data) => ({
	[RSAA]: {
		api: post(
			`/workorders/invoices/${invoiceId}/credit_memos/${creditMemoInvoiceId}/reject/`,
			data
		),
		types: REJECT_CREDIT_MEMO_INVOICE,
	},
})

const initialState = {
	isLoading: true,
	isLoadingCreditMemoInvoice: true,
	isLoadingCreditMemoInvoices: true,
	creditMemoInvoice: {},
	creditMemoInvoices: [],
}

const creditMemos = (state = initialState, action) => {
	switch (action.type) {
		case LIST_CREDIT_MEMO_INVOICES.request:
			return {
				...state,
				isLoadingCreditMemoInvoices: true,
			}
		case LIST_CREDIT_MEMO_INVOICES.success:
			return {
				...state,
				isLoadingCreditMemoInvoices: false,
				creditMemoInvoices: action.payload,
			}

		case GET_CREDIT_MEMO_INVOICE.request:
			return {
				...state,
				isLoadingCreditMemoInvoice: true,
			}
		case GET_CREDIT_MEMO_INVOICE.success:
			return {
				...state,
				isLoadingCreditMemoInvoice: false,
				creditMemoInvoice: action.payload,
			}

		case PATCH_CREDIT_MEMO_INVOICE.request:
		case APPROVE_CREDIT_MEMO_INVOICE.request:
		case REJECT_CREDIT_MEMO_INVOICE.request:
			return {
				...state,
				isSaving: true,
			}

		case PATCH_CREDIT_MEMO_INVOICE.success:
		case APPROVE_CREDIT_MEMO_INVOICE.success:
		case REJECT_CREDIT_MEMO_INVOICE.success:
			return {
				...state,
				isSaving: false,
			}

		default:
			return state
	}
}
export default creditMemos

import React from "react"
import PropTypes from "prop-types"
import { DashboardWidget } from "../../components"
import { WidgetConfig, widgetConfigPropType } from "./DashboardWidget"
import { WidgetMap } from "./ConfigurableDashboard"

export interface DashboardWidgetListProps {
	widgets: WidgetConfig[]
	widgetMap: WidgetMap
	isEditing: boolean
	onRemoveWidget: (index: number) => void
	onStartEditing: () => void
	onUpdateWidgetConfig?: (index: number) => (config: WidgetConfig) => void
}

const DashboardWidgetList: React.FC<DashboardWidgetListProps> = ({
	isEditing,
	onRemoveWidget,
	onStartEditing,
	onUpdateWidgetConfig,
	widgetMap,
	widgets,
}) => {
	return (
		<>
			{widgets.map((widgetConfig, index) => (
				<DashboardWidget
					key={index}
					widgetConfig={widgetConfig}
					widgetMap={widgetMap}
					index={index}
					isEditing={isEditing}
					onStartEditing={onStartEditing}
					onRemoveWidget={onRemoveWidget}
					onUpdateWidgetConfig={
						onUpdateWidgetConfig ? onUpdateWidgetConfig(index) : undefined
					}
				/>
			))}
		</>
	)
}

DashboardWidgetList.propTypes = {
	// @ts-expect-error. Permit TS to describe widgetConfigPropType more specifically than PropTypes can.
	widgets: PropTypes.arrayOf(widgetConfigPropType.isRequired).isRequired,
	widgetMap: PropTypes.object.isRequired,
	isEditing: PropTypes.bool.isRequired,
	onRemoveWidget: PropTypes.func.isRequired,
	onStartEditing: PropTypes.func.isRequired,
	onUpdateWidgetConfig: PropTypes.func,
}

export default React.memo(DashboardWidgetList)

import { MouseEvent, ReactElement, useMemo, useState } from "react"

import { css } from "@emotion/react"
import { IconName } from "@fortawesome/fontawesome-common-types"
import { Popover } from "@material-ui/core"

import { accessString, ObjectEntry, StringAccessor } from "@ncs/ts-utils"

import { cssMixins, useChangeCallback } from "../../util"
import { Chip } from "../chips"
import { Box } from "../layout"
import { Icon, IconFamily, Paragraph } from "../typography"
import { CheckboxGroup } from "./CheckboxGroup"
import { FieldContainer, FieldContainerProps } from "./FieldContainer"
import { ThrottledTextInput } from "./ThrottledTextInput"

export interface MultiSelectProps<Item extends {}> extends FieldContainerProps {
	values: Item[]
	onChange: (newItems: Item[]) => void
	options: Item[]
	idAccessor: StringAccessor<Item>
	textAccessor: StringAccessor<Item>
	/**
	 * Optionally provide a text accessor that will be used when list is open and there's
	 * more room for a longer label.
	 */
	longTextAccessor?: StringAccessor<Item>
	placeholder?: string
	htmlName?: string
	/**
	 * Cap the number of options to be displayed at once.
	 */
	optionsLimit?: number
	/**
	 * Number of chips to try and show when the dropdown is closed.
	 * @default 2
	 */
	maxClosedChips?: number
	/**
	 * Max number of selections user can do at once.
	 */
	maxSelections?: number
	/**
	 * Heads up: Test this option thoroughly. It's in here, but hasn't really be used yet.
	 */
	aggregateOptions?: boolean
	/**
	 * Should there be a text search for the options at the top?
	 * @default true
	 */
	showSearch?: boolean
	/**
	 * Icon to show on the filter (when closed)
	 * @default 'search'
	 */
	icon?: IconName
	/**
	 * Icon family to show on the filter (when closed)
	 * @default 'solid'
	 */
	iconFamily?: IconFamily
}

export const MultiSelect = <Item extends {}>({
	values,
	onChange,
	options: optionsProps,
	idAccessor,
	textAccessor,
	longTextAccessor,
	placeholder = "Search...",
	htmlName,
	aggregateOptions: shouldAggregateOptions,
	optionsLimit,
	maxClosedChips = 2,
	maxSelections,
	showSearch = true,
	icon = "search",
	iconFamily = "regular",
	...rest
}: MultiSelectProps<Item>): ReactElement => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
	const [query, setQuery] = useState<string | null>(null)

	const [aggregateOptions, setAggregateOptions] = useState<Record<string, Item>>(() => {
		return Object.fromEntries(
			optionsProps.map((option): ObjectEntry<Record<string, Item>> => {
				return [accessString(option, idAccessor), option]
			})
		)
	})

	useChangeCallback(
		optionsProps,
		(newOptions) => {
			setAggregateOptions((prev) => {
				const optionsToAdd: Item[] = []

				newOptions.forEach((newOption) => {
					const newOptionId = accessString(newOption, idAccessor)
					if (!prev[newOptionId]) {
						optionsToAdd.push(newOption)
					}
				})

				return {
					...prev,
					...Object.fromEntries(
						optionsToAdd.map((newOption) => {
							return [accessString(newOption, idAccessor), newOption]
						})
					),
				}
			})
		},
		{ disabled: !shouldAggregateOptions }
	)

	const handleChange = (changedItem: Item, newState: boolean) => {
		if (newState) {
			setQuery(null)
			onChange([...values, changedItem])
		} else {
			onChange(
				values.filter(
					(prevItem) =>
						accessString(prevItem, idAccessor) !==
						accessString(changedItem, idAccessor)
				)
			)
		}
	}

	const handleRemove = (item: Item) => {
		onChange(
			values.filter(
				(prevItem) => accessString(prevItem, idAccessor) !== accessString(item, idAccessor)
			)
		)
	}

	const popoverWidth = useMemo(() => {
		// Get the width of the containing button element.
		return anchorEl?.clientWidth
	}, [anchorEl])

	const preparedOptions = useMemo(() => {
		const queryChunks = query?.trim().toUpperCase().split(" ")
		const baseOptions = shouldAggregateOptions ? Object.values(aggregateOptions) : optionsProps
		const options = baseOptions
			.filter((option) => {
				if (!queryChunks?.length) return true

				const optionLabel = accessString(
					option,
					longTextAccessor ?? textAccessor
				).toUpperCase()

				return queryChunks.every((chunk) => optionLabel.includes(chunk))
			})
			.sort((a, b) => {
				const aLabel = accessString(a, textAccessor).toUpperCase()
				const bLabel = accessString(b, textAccessor).toUpperCase()

				return aLabel > bLabel ? 1 : -1
			})

		return optionsLimit ? options.slice(0, optionsLimit) : options
	}, [
		query,
		optionsProps,
		textAccessor,
		shouldAggregateOptions,
		aggregateOptions,
		longTextAccessor,
		optionsLimit,
	])

	const closedValuesToShow = useMemo(() => {
		const result: Item[] = []
		const count = Math.min(values.length, maxClosedChips)

		for (let i = 0; i < count; i += 1) {
			result.push(values[i])
		}

		return result
	}, [values, maxClosedChips])

	const selectionCapReached = maxSelections ? values.length >= maxSelections : false

	return (
		<FieldContainer {...rest}>
			<button
				className="multi-select" // Give us a hook to apply the same basic global styles as select, input, etc
				css={buttonContainerCss}
				onClick={(e: MouseEvent<HTMLButtonElement>) => {
					e.stopPropagation()
					e.preventDefault()
					setAnchorEl(e.currentTarget)
				}}
			>
				<Box d="flex" alignItems="center" width="100%" columnGap={0.25}>
					{closedValuesToShow.map((v) => {
						return (
							<Chip
								key={accessString(v, idAccessor)}
								label={accessString(v, textAccessor)}
								variant="square"
								css={closedChipCss}
								small
							/>
						)
					})}
					{values.length > closedValuesToShow.length && (
						<Paragraph small px={0.3} css={cssMixins.noWrap}>
							+{values.length - closedValuesToShow.length} more
						</Paragraph>
					)}
					{!values.length && (
						<Paragraph pl="4px" opacity={0.5}>
							{placeholder}
						</Paragraph>
					)}
					<Box ml="auto" px={0.25}>
						<Icon icon={icon} family={iconFamily} opacity={0.5} />
					</Box>
				</Box>
			</button>

			{!!popoverWidth && (
				<Popover
					open={!!anchorEl}
					anchorEl={anchorEl}
					onClose={() => setAnchorEl(null)}
					PaperProps={{ style: { width: popoverWidth } }}
				>
					<Box d="flex" flexDirection="column" p={0.5} gap={0.25}>
						{values.map((v) => {
							const id = accessString(v, idAccessor)
							const label = accessString(v, longTextAccessor ?? textAccessor)

							return (
								<div key={id}>
									<Chip
										label={label}
										variant="square"
										onDelete={() => handleRemove(v)}
										fillContainer
										small
									/>
								</div>
							)
						})}
					</Box>

					{selectionCapReached ?
						<Paragraph small secondary p={1}>
							Maximum number of options selected
						</Paragraph>
					:	<>
							{showSearch && (
								<ThrottledTextInput
									value={query}
									onChange={setQuery}
									icon="search"
									autoFocus
									css={inputCss}
									placeholder={placeholder}
									fillContainer
									clearable
								/>
							)}
						</>
					}

					{!!preparedOptions.length && !selectionCapReached && (
						<Box
							pl={0.5}
							maxHeight={30}
							css={css`
								overflow-y: auto;
							`}
						>
							<CheckboxGroup
								rows={preparedOptions}
								groupName={htmlName}
								checkedAccessor={(option) =>
									values.some((v) => {
										return (
											accessString(v, idAccessor) ===
											accessString(option, idAccessor)
										)
									})
								}
								labelAccessor={longTextAccessor ?? textAccessor}
								valueAccessor={idAccessor}
								onChange={(changedRow, newState) =>
									handleChange(changedRow, newState)
								}
							/>
						</Box>
					)}

					{!!query && preparedOptions.length === 0 && (
						<Paragraph small secondary p={1}>
							Nothing found with "{query}"
						</Paragraph>
					)}
				</Popover>
			)}
		</FieldContainer>
	)
}

const buttonContainerCss = css`
	display: flex;
	justify-content: space-between;
	align-items: center;
	text-align: left;
	background: white;
	padding: 0.42rem 0.3rem;
	height: 34px;
	> span {
		opacity: 0.35;
		font-size: 1rem;
	}
	> svg {
		opacity: 0.4;
	}
`
const closedChipCss = css`
	overflow-x: hidden;
	height: 23px;
`
const inputCss = css`
	border-top: none !important;
	border-right: none !important;
	border-left: none !important;
	border-bottom: 1px solid #eee !important;
	outline: none !important;
`

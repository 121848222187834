import { FC } from "react"

import { useHistory, useLocation, useParams } from "react-router-dom"

import { UserProfileQueryParams, useUserProfile } from "@ncs/ncs-api"
import { formatDate, formatDateTime } from "@ncs/ts-utils"
import {
	Box,
	Button,
	Callout,
	Card,
	encodeUrlState,
	Icon,
	LabeledData,
	LoadingSpinner,
	Paragraph,
	Tabs,
	useScrollToTopOnMount,
	useUrlState,
} from "@ncs/web-legos"

import { CustomerUserCommunicationsTab } from "./CustomerUserCommunicationsTab"
import { CustomerUserPermissionsTab } from "./CustomerUserPermissionsTab"
import { CustomerUsersUrlState } from "./CustomerUsers"
import { CustomerUserShopTab } from "./CustomerUserShopTab"

export enum CustomerUserTab {
	Permissions = "Permissions",
	Communications = "Communications",
	ConfigureShop = "Configure Shop",
}

const CustomerUser: FC = () => {
	useScrollToTopOnMount()
	const history = useHistory()
	const { id } = useParams<{ id?: string }>()
	const location = useLocation<{ params?: UserProfileQueryParams }>()
	const [user, userLoading] = useUserProfile(id, {
		// Supplying "isCustomer" as a param lets non-admins view the profile, as long as
		// the user is indeed a customer user.
		params: {
			isCustomer: true,
		},
	})
	const [{ tab }, { updateUrlValue }] = useUrlState({
		tab: CustomerUserTab.Permissions,
	})

	const navigateToUserList = () => {
		history.push({
			pathname: "/pos-users",
			search: encodeUrlState<CustomerUsersUrlState>(location.state?.params),
		})
	}

	if (userLoading) {
		return (
			<Card>
				<LoadingSpinner />
			</Card>
		)
	}
	if (!user) {
		return <Card>User not found</Card>
	}

	return (
		<>
			<Button icon="long-arrow-left" onClick={navigateToUserList} containerProps={{ mb: 3 }}>
				Back to user list
			</Button>

			<Card
				heading={user.email}
				headingIcon="address-card"
				headingDetail={
					user.lastLogin ?
						`Last login ${formatDateTime(user.lastLogin)}`
					:	"Last login: none"
				}
				renderRight={() =>
					!user.isGuest &&
					!user.siteCount && (
						<Callout variant="warning" icon="exclamation-triangle">
							<Paragraph small>
								User is a customer of NCS but does not have any sites setup yet.
							</Paragraph>
						</Callout>
					)
				}
			>
				<Box display="flex" columnGap={5} flexWrap="wrap">
					<LabeledData label="First name">{user.firstName}</LabeledData>
					<LabeledData label="Last name">{user.lastName}</LabeledData>
					<LabeledData label="Email">{user.email}</LabeledData>
					<LabeledData label="Sign-up date">
						{formatDate(user.effectiveDate)}
					</LabeledData>
				</Box>

				<Tabs
					currentTab={tab}
					onChange={(newTab) => updateUrlValue("tab", newTab)}
					panels={[
						{
							navLabel: CustomerUserTab.Permissions,
							navIcon: <Icon icon="shield-halved" family="solid" />,
							component: <CustomerUserPermissionsTab user={user} />,
						},
						{
							navLabel: CustomerUserTab.Communications,
							navIcon: <Icon icon="comment-alt-lines" />,
							component: <CustomerUserCommunicationsTab user={user} />,
						},
						{
							navLabel: CustomerUserTab.ConfigureShop,
							navIcon: <Icon icon="shopping-cart" />,
							component: <CustomerUserShopTab user={user} />,
						},
					]}
				/>
			</Card>
		</>
	)
}

export default CustomerUser

import { FC } from "react"

import { Column } from "react-table"

import {
	useExportWarehouseOrderReport,
	useWarehouseOrderReport,
	WarehouseOrderReportLine,
	WarehouseOrderReportQueryParams,
} from "@ncs/ncs-api"
import { formatNumber, formatPercentage, Typify } from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	Table,
	TerritoryQueryFilter,
	TimeAgoQueryFilter,
	usePaginationUrlState,
	useUrlState,
} from "@ncs/web-legos"

export type WarehouseOrderReportUrlState = Typify<WarehouseOrderReportQueryParams>

export const WarehouseOrderReport: FC = () => {
	const [queryParams, { setUrlState: setQueryParams }] =
		useUrlState<WarehouseOrderReportUrlState>(initialWarehouseOrderReportUrlState)
	const [pagination, setPagination] = usePaginationUrlState()

	const query = useWarehouseOrderReport({
		params: { ...queryParams, ...pagination },
		manualPagination: true,
	})
	const dataExport = useExportWarehouseOrderReport(queryParams)

	return (
		<AnimatedEntrance show>
			<Table
				query={query}
				columns={columns}
				queryParamState={queryParams}
				setQueryParamState={setQueryParams}
				pagination={pagination}
				setPagination={setPagination}
				dataExport={dataExport}
				pinnedQueryFilters={[TerritoryQueryFilter, TimeAgoQueryFilter]}
				storeColumnVisibility="warehouse-order-report-columns"
			/>
		</AnimatedEntrance>
	)
}

const columns: Column<WarehouseOrderReportLine>[] = [
	{
		Header: "Warehouse",
		id: "warehouse_LocationCode",
		accessor: (line) => `(${line.warehouse_LocationCode}) ${line.warehouse_Description}`,
	},
	{
		Header: "Lines placed",
		disableSortBy: true,
		hiddenByDefault: true,
		accessor: ({ totalLinesPlaced }) => formatNumber(totalLinesPlaced),
	},
	{
		Header: "Lines in-stock",
		disableSortBy: true,
		hiddenByDefault: true,
		accessor: (
			{ totalLinesPlaced, totalLinesInstock } // cspell:disable-line
		) =>
			totalLinesPlaced ?
				formatNumber(totalLinesInstock) // cspell:disable-line
			:	"-",
	},
	{
		Header: "In-stock %",
		disableSortBy: true,
		// cspell:disable-next-line
		accessor: ({ totalLinesPlaced, totalLinesInstock }) => {
			return totalLinesPlaced ?
					formatPercentage(totalLinesInstock / totalLinesPlaced, 0) // cspell:disable-line
				:	"-"
		},
	},
	{
		Header: "Lines picked",
		disableSortBy: true,
		hiddenByDefault: true,
		accessor: ({ totalLinesPicked }) => formatNumber(totalLinesPicked),
	},
	{
		Header: "Picked on-time",
		disableSortBy: true,
		hiddenByDefault: true,
		accessor: (
			{ totalLinesPicked, totalLinesPickedOntime } // cspell:disable-line
		) =>
			totalLinesPicked ?
				formatNumber(totalLinesPickedOntime) // cspell:disable-line
			:	"-",
	},
	{
		Header: "Picked on-time %",
		disableSortBy: true,
		// cspell:disable-next-line
		accessor: ({ totalLinesPicked, totalLinesPickedOntime }) => {
			return totalLinesPicked ?
					formatPercentage(totalLinesPickedOntime / totalLinesPicked, 0) // cspell:disable-line
				:	"-"
		},
	},
	{
		Header: "Lines shipped",
		disableSortBy: true,
		hiddenByDefault: true,
		accessor: ({ totalLinesShipped }) => formatNumber(totalLinesShipped),
	},
	{
		Header: "Shipped on-time",
		disableSortBy: true,
		hiddenByDefault: true,
		// cspell:disable-next-line
		accessor: ({ totalLinesShipped, totalLinesShippedOntime }) =>
			totalLinesShipped ?
				formatNumber(totalLinesShippedOntime) // cspell:disable-line
			:	"-",
	},
	{
		Header: "Shipped on-time %",
		disableSortBy: true,
		// cspell:disable-next-line
		accessor: ({ totalLinesShipped, totalLinesShippedOntime }) => {
			return totalLinesShipped ?
					formatPercentage(totalLinesShippedOntime / totalLinesShipped, 0) // cspell:disable-line
				:	"-"
		},
	},
	{
		Header: "Lines delivered",
		disableSortBy: true,
		hiddenByDefault: true,
		accessor: ({ totalLinesDelivered }) => formatNumber(totalLinesDelivered),
	},
	{
		Header: "Delivered on-time",
		disableSortBy: true,
		hiddenByDefault: true,
		// cspell:disable-next-line
		accessor: ({ totalLinesDelivered, totalLinesDeliveredOntime }) =>
			totalLinesDeliveredOntime ? formatNumber(totalLinesDelivered) : "-", // cspell:disable-line
	},
	{
		Header: "Delivered on-time %",
		disableSortBy: true,
		// cspell:disable-next-line
		accessor: ({ totalLinesDelivered, totalLinesDeliveredOntime }) => {
			return totalLinesDelivered ?
					formatPercentage(totalLinesDeliveredOntime / totalLinesDelivered, 0) // cspell:disable-line
				:	"-"
		},
	},
	{
		Header: "Avg pick time (hrs)",
		disableSortBy: true,
		accessor: "avgPickTime",
		hiddenByDefault: true,
	},
	{
		Header: "Avg ship time (hrs)",
		disableSortBy: true,
		accessor: "avgShipTime",
		hiddenByDefault: true,
	},
	{
		Header: "Avg delivery time (hrs)",
		disableSortBy: true,
		accessor: "avgDeliveryTime",
		hiddenByDefault: true,
	},
]

const initialWarehouseOrderReportUrlState: WarehouseOrderReportUrlState = {
	ordering: null,
	territory: null,
	startDate: null,
	endDate: null,
}

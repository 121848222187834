import { FC } from "react"

import { ExtendableSelectProps, Select } from "../inputs"

export interface UserGuestSelectorProps extends ExtendableSelectProps<(typeof options)[number]> {}

export const UserGuestSelector: FC<UserGuestSelectorProps> = ({ ...rest }) => {
	return <Select {...rest} options={options} label="User guest status" />
}

export enum UserGuestSelectorValue {
	OnlyGuests = "1",
	NonGuests = "2",
	NonGuestsWithNoSites = "3",
}

const options = [
	{
		value: UserGuestSelectorValue.OnlyGuests,
		text: "Guests",
	},
	{
		value: UserGuestSelectorValue.NonGuests,
		text: "Non-guests",
	},
	{
		value: UserGuestSelectorValue.NonGuestsWithNoSites,
		text: "Non-guests with no sites",
	},
]

import { FC, useMemo, useState } from "react"

import { Cell, Column } from "react-table"

import { ChemicalCustomerDetail } from "@ncs/ncs-api"
import { formatCurrency, formatNumber, yesOrNo } from "@ncs/ts-utils"
import { Box, Button, ReactTableSortType, Table } from "@ncs/web-legos"

import { EditChemicalPackageModal } from "./components"
import { ChemicalPackage } from "./packages-tab-util"

export interface PackagesTabProps {
	chemicalCustomerDetail: ChemicalCustomerDetail
}

export const PackagesTab: FC<PackagesTabProps> = ({ chemicalCustomerDetail }) => {
	const [detailModalPackageId, setDetailModalPackageId] = useState<string | null>(null)
	const [showCreateModal, setShowCreateModal] = useState(false)

	const data = useMemo(() => {
		return chemicalCustomerDetail.packages.sort((a, b) => {
			if (a.isActive && !b.isActive) return -1
			if (!a.isActive && b.isActive) return 1
			return a.number < b.number ? -1 : 1
		})
	}, [chemicalCustomerDetail.packages])

	return (
		<>
			<Button
				buttonText="Create Chemical Package"
				variant="secondary-cta"
				icon="plus-circle"
				onClick={() => setShowCreateModal(true)}
			/>

			<Table
				data={data}
				columns={columns}
				onRowClick={({ original }) => setDetailModalPackageId(original.id)}
				noDataText="No packages set up for customer yet"
				rowDisabledAccessor={({ isActive }) => !isActive}
			/>

			{(!!detailModalPackageId || showCreateModal) && (
				<EditChemicalPackageModal
					packageId={detailModalPackageId}
					packages={chemicalCustomerDetail.packages}
					customerId={chemicalCustomerDetail.customerId}
					onClose={() => {
						setDetailModalPackageId(null)
						setShowCreateModal(false)
					}}
				/>
			)}
		</>
	)
}

const columns: Column<ChemicalPackage>[] = [
	{
		Header: "Name",
		accessor: ({ name }) => name,
	},
	{
		Header: "PLC Package #",
		id: "packageNumber",
		sortType: ReactTableSortType.Number,
		accessor: ({ number }) => number,
	},
	{
		Header: "Chemicals",
		id: "chemicals",
		disableSortBy: true,
		Cell: ({ row: { original } }: Cell<ChemicalPackage>) => {
			return (
				<Box display="flex" flexDirection="column" rowGap={0.5}>
					{original.chemicals.map((chemical) => (
						<span key={chemical.id}>
							{formatNumber(chemical.passes)} pass{chemical.passes !== 1 ? "es" : ""}
							: ({chemical.partNumber}) {chemical.partDescription}
						</span>
					))}
				</Box>
			)
		},
	},
	{
		Header: "Price",
		accessor: ({ price }) => price,
		sortType: ReactTableSortType.Number,
		Cell: ({ row: { original } }: Cell<ChemicalPackage>) => {
			return original.price != null ? formatCurrency(original.price) : "-"
		},
	},
	{
		Header: "Is active?",
		hiddenByDefault: true,
		accessor: ({ isActive }) => yesOrNo(isActive),
	},
]

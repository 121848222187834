import React from "react"

//custom components
import {
	GridContainer,
	GridItem,
	ButtonWithIcon,
	Input,
	Select,
	UserSelector,
	Dialog,
} from "~/components"

// icons
import Remove from "@material-ui/icons/Remove"
import SendIcon from "@material-ui/icons/Send"

function CommentNotification(props) {
	let {
		show,
		onConfirmSendNotification,
		onCancelSendTechNotification,
		isSaving,
		selectedNotificationType,
		onNotificationTypeChange,
		hasDocumentationEmail,
		onNotificationTechSelected,
		notificationEmail,
		onNotificationEmailChange,
		showValidationErrors,
		notificationEmailErrorMessage,
		notificationEmailIsValid,
		message,
		messageIsValid,
		messageErrorMessage,
		onMessageChange,
		setInputRef,
		onSendTechNotification,
		notificationSent,
		notificationSentText,
		onConfirmNotificationSent,
	} = props

	return (
		<GridContainer>
			<GridItem xs={12} md={4}>
				<ButtonWithIcon
					icon={<SendIcon />}
					color={"primary"}
					size="sm"
					content={"Send Notification"}
					round
					onClick={onSendTechNotification}
					disabled={isSaving}
				/>
			</GridItem>

			<Dialog
				warning="true"
				show={show}
				title="Send Notification"
				confirmBtnIcon={<SendIcon />}
				confirmBtnText="Send Notification"
				onConfirm={onConfirmSendNotification}
				isConfirmationAsync={true}
				cancelBtnIcon={<Remove />}
				cancelBtnText={"Cancel"}
				cancelBtnColor={"danger"}
				onCancel={onCancelSendTechNotification}
				isSaving={isSaving}
			>
				{show && (
					<GridContainer>
						<GridItem xs={12} md={6}>
							<Select
								id="ticketCommentRequestor"
								labelText="Requestor Type"
								value={selectedNotificationType || ""}
								onChange={onNotificationTypeChange}
								options={
									hasDocumentationEmail === false ?
										[
											{
												id: null,
												description: "Select a request type...",
												disabled: false,
											},
											{
												id: 1,
												description: "Technician",
											},
										]
									:	[
											{
												id: null,
												description: "Select a request type...",
												disabled: false,
											},
											{
												id: 1,
												description: "Technician",
											},
											{
												id: 2,
												description: "Customer",
											},
										]
								}
								labelProps={{ shrink: true }}
								valueAccessor="id"
								textAccessor={(x) => x.description}
							/>
						</GridItem>
						<GridItem xs={12} md={6} show={selectedNotificationType === 1}>
							<UserSelector onUserSelected={onNotificationTechSelected} />
						</GridItem>
						<GridItem xs={12} md={6} show={selectedNotificationType === 2}>
							<Input
								labelText={"Email"}
								value={notificationEmail}
								onChange={onNotificationEmailChange}
								inputProps={{
									inputProps: { ref: setInputRef },
								}}
								formControlProps={{
									error: showValidationErrors && !notificationEmailIsValid,
								}}
								helperText={
									showValidationErrors ? notificationEmailErrorMessage : ""
								}
							/>
						</GridItem>
						<GridItem xs={12}>
							<Input
								labelText={"Message"}
								value={message}
								onChange={onMessageChange}
								inputProps={{
									inputProps: { ref: setInputRef },
									multiline: true,
								}}
								formControlProps={{
									error: showValidationErrors && !messageIsValid,
								}}
								helperText={showValidationErrors ? messageErrorMessage : ""}
							/>
						</GridItem>
					</GridContainer>
				)}
			</Dialog>
			<Dialog
				warning="true"
				show={notificationSent}
				title={notificationSentText}
				confirmBtnIcon={<SendIcon />}
				confirmBtnText="OK"
				confirmBtnColor="success"
				onConfirm={onConfirmNotificationSent}
				isConfirmationAsync={true}
				onSuccessConfirm={onConfirmNotificationSent}
			/>
		</GridContainer>
	)
}
export default CommentNotification

import { FC } from "react"

import { Cell, Column } from "react-table"

import {
	PmpiDetailReportLine,
	PmpiDetailReportQueryParams,
	useExportPmpiDetailReport,
	usePmpiDetailReport,
} from "@ncs/ncs-api"
import {
	formatDateTime,
	getStartAndEndDateQueryParams,
	getTimezoneAbbreviation,
	TimeAgo,
	Typify,
} from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	CustomerQueryFilter,
	EnterpriseQueryFilter,
	Icon,
	Link,
	OrganizationQueryFilter,
	RegionQueryFilter,
	Table,
	TerritoryQueryFilter,
	TimeAgoQueryFilter,
	usePaginationUrlState,
	useUrlState,
} from "@ncs/web-legos"

export type PmpiDetailReportUrlState = Typify<PmpiDetailReportQueryParams>

export const PmpiDetailReport: FC = () => {
	const [queryParams, { setUrlState: setQueryParams }] = useUrlState<PmpiDetailReportUrlState>(
		initialPmpiDetailReportUrlState
	)
	const [pagination, setPagination] = usePaginationUrlState({
		defaultPageSize: 25,
	})

	const query = usePmpiDetailReport({
		params: { ...queryParams, ...pagination },
		manualPagination: true,
	})
	const dataExport = useExportPmpiDetailReport(queryParams)

	return (
		<AnimatedEntrance show>
			<Table
				query={query}
				columns={columns}
				queryParamState={queryParams}
				setQueryParamState={setQueryParams}
				pinnedQueryFilters={[
					TimeAgoQueryFilter,
					CustomerQueryFilter,
					OrganizationQueryFilter,
					TerritoryQueryFilter,
				]}
				toggledQueryFilters={[RegionQueryFilter, EnterpriseQueryFilter]}
				filterResetValues={initialPmpiDetailReportUrlState}
				pagination={pagination}
				setPagination={setPagination}
				dataExport={dataExport}
				storeColumnVisibility="pmpi-detail-report-columns"
			/>
		</AnimatedEntrance>
	)
}

const columns: Column<PmpiDetailReportLine>[] = [
	{
		Header: "Contract type",
		disableSortBy: true,
		accessor: ({ contract }) => contract.type,
	},
	{
		Header: "Frequency",
		disableSortBy: true,
		accessor: "frequency",
	},
	{
		Header: "Customer #",
		id: "contract_Customer_CustomerNumber",
		accessor: ({ contract }) => contract.customer.customerNumber,
	},
	{
		Header: "Name",
		id: "contract_Customer_Name",
		accessor: ({ contract }) => contract.customer.name,
	},
	{
		Header: "Completed?",
		disableSortBy: true,
		accessor: "completed",
		Cell: ({ row: { original } }: Cell<PmpiDetailReportLine>) =>
			original.completed ?
				<>
					<Icon icon="check" color="gray" /> Yes
				</>
			:	"No",
	},
	{
		Header: "Equipment serial #",
		disableSortBy: true,
		hiddenByDefault: true,
		accessor: ({ contract }) => contract.customerEquipment?.serialNumber || "-",
	},
	{
		Header: "Equipment model",
		disableSortBy: true,
		hiddenByDefault: true,
		accessor: ({ contract }) => contract.customerEquipment?.model || "-",
	},
	{
		Header: `Period start (${getTimezoneAbbreviation()})`,
		id: "periodStartDate",
		hiddenByDefault: true,
		accessor: ({ periodStartDate }) => formatDateTime(periodStartDate),
	},
	{
		Header: `Period end (${getTimezoneAbbreviation()})`,
		id: "periodEndDate",
		hiddenByDefault: true,
		accessor: ({ periodEndDate }) => formatDateTime(periodEndDate),
	},
	{
		Header: "Dispatch #",
		disableSortBy: true,
		hiddenByDefault: true,
		accessor: ({ dispatch }) => dispatch?.dispatchNumber,
		Cell: ({ row: { original } }: Cell<PmpiDetailReportLine>) =>
			original.dispatch ?
				<Link
					newTab
					icon="external-link"
					to={`/service/dispatches/${original.dispatch.id}`}
				>
					{original.dispatch.dispatchNumber}
				</Link>
			:	"-",
	},
]

const initialPmpiDetailReportUrlState: PmpiDetailReportUrlState = {
	ordering: null,
	organization: null,
	region: null,
	enterprise: null,
	territory: null,
	customer: null,
	startDate: getStartAndEndDateQueryParams(TimeAgo.DaysAgo30)[0],
	endDate: null,
	notCompleted: null,
}

import React, { useEffect } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import memoizeOne from "memoize-one"
import { BaseSelectFilter, withDynamicTableFilter } from "@ncs/bricks/components"
import { loadLookups, LOOKUPS } from "../../redux/services/lookups"
import { FILTER_OVERRIDE } from "../../util/constants"

const getCustomerPortalUserOptions = memoizeOne((users) => [
	{ _id: "-1", name: "All Users" },
	{ _id: "no_portal_user", name: "No User Assigned" },
	...users,
])

function getFilterValue(value) {
	if (value === "no_portal_user") {
		return { id: "no_portal_user", value: true }
	}

	if (Number(value) > 0) {
		return { id: "bill_to_customer__portal_assignment__assigned_employee_id", value }
	}

	return { id: "" }
}

function CustomerPortalUserFilter(props) {
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(loadLookups([LOOKUPS.PortalUsers]))
	}, [dispatch])

	const customerPortalUsers = useSelector((x) => x.lookups.portalUsers, shallowEqual)

	function handleChange(value) {
		props.onChange({ type: FILTER_OVERRIDE, value, filterValue: getFilterValue(value) })
	}

	const options = getCustomerPortalUserOptions(customerPortalUsers)

	return (
		<BaseSelectFilter
			id="customerPortalUser"
			labelText="Customer Portal User"
			options={options}
			valueAccessor="_id"
			textAccessor="name"
			{...props}
			value={props.value?.value || options[0]._id}
			onChange={handleChange}
		/>
	)
}

export default withDynamicTableFilter("customer_portal_user")(CustomerPortalUserFilter)

import { FC, useEffect, useState } from "react"

import { Dayjs } from "dayjs"
import { useHistory } from "react-router-dom"

import {
	LaborRateTypeId,
	makeApiErrorMessage,
	TerritoryMinimal,
	useCreateLaborPricing,
} from "@ncs/ncs-api"
import { DateFormat, extractNumber } from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	Box,
	DateInput,
	ExtendableModalProps,
	Modal,
	NumericInput,
	Paragraph,
	RadioBoolean,
	Select,
	TerritorySelector,
	TextInput,
	useChangeCallback,
	useIsSaving,
	useToast,
} from "@ncs/web-legos"

export interface NewLaborPricingModalProps extends ExtendableModalProps {}

export const NewLaborPricingModal: FC<NewLaborPricingModalProps> = ({ ...rest }) => {
	const history = useHistory()
	const { makeSuccessToast } = useToast()
	const [isFlat, setIsFlat] = useState<boolean | null>(null)
	const [isTerritory, setIsTerritory] = useState(false)
	const [selectedTerritory, setSelectedTerritory] = useState<TerritoryMinimal | null>(null)
	const [selectedZone, setSelectedZone] = useState<string | null>(null)
	const [description, setDescription] = useState<string | null>(null)
	const [price, setPrice] = useState<number | null>(null)
	const [effectiveDate, setEffectiveDate] = useState<Dayjs | null>(null)
	const [endDate, setEndDate] = useState<Dayjs | null>(null)
	const [errorText, setErrorText] = useState<string | null>(null)
	const { isSaving, setSaving, endSaving } = useIsSaving()

	const createPricing = useCreateLaborPricing()

	const handleSave = async () => {
		try {
			if (isFlat == null) {
				throw new Error("Choose labor type")
			}
			if (isTerritory) {
				if (!selectedTerritory) {
					throw new Error("Choose territory")
				}
				if (!selectedZone) {
					throw new Error("Select zone")
				}
			} else {
				if (!description) {
					throw new Error("Name of the new pricing group is required")
				}
			}
			if (price == null) {
				throw new Error("Rate is required")
			}
			if (!effectiveDate) {
				throw new Error("Start date is required")
			}
			if (!endDate) {
				throw new Error("End date is required")
			}
			if (endDate.isSame(effectiveDate, "day") || endDate.isBefore(effectiveDate)) {
				throw new Error("End date must be later than start date")
			}

			setSaving()

			const { data } = await createPricing({
				typeId: isFlat ? LaborRateTypeId.FlatRate : LaborRateTypeId.Labor,
				description: isTerritory ? null : description,
				territory: isTerritory ? selectedTerritory?.id ?? null : null,
				zone: selectedZone != null ? extractNumber(selectedZone) : null,
				price,
				startsOn: effectiveDate.format(DateFormat.DateQueryParam),
				endsAfter: endDate?.format(DateFormat.DateQueryParam),
			})
			makeSuccessToast("New pricing group created")
			history.push(`/finance/labor-pricing/${data}`)
		} catch (e) {
			endSaving()
			setErrorText(makeApiErrorMessage(e))
		}
	}

	// When you toggle between territory/customer, clear the state of the other.
	useChangeCallback(isTerritory, (newIsTerritory) => {
		if (newIsTerritory) {
			setDescription(null)
		} else {
			setSelectedTerritory(null)
		}
	})

	useEffect(() => {
		setErrorText(null)
	}, [
		isFlat,
		isTerritory,
		selectedTerritory,
		selectedZone,
		description,
		price,
		effectiveDate,
		endDate,
	])

	return (
		<Modal
			{...rest}
			title="New Labor Pricing Group"
			rightButtons={{
				buttonText: "Create",
				onClick: handleSave,
				isLoading: isSaving(),
			}}
			errorText={errorText}
		>
			<RadioBoolean
				htmlName="is-flat"
				description="Is this rate labor or flat?"
				value={isFlat}
				onChange={setIsFlat}
				noText="Labor"
				yesText="Flat"
				noFirst
			/>
			<RadioBoolean
				htmlName="is-customers"
				description="Will this price be tied to a specific territory?"
				value={isTerritory}
				onChange={setIsTerritory}
				noFirst
				noText="No (default)"
			/>

			<AnimatedEntrance show={isTerritory === true} mb={1}>
				<TerritorySelector
					value={selectedTerritory}
					onChange={setSelectedTerritory}
					mb={0}
				/>
			</AnimatedEntrance>

			{isTerritory === false && (
				<TextInput
					value={description}
					onChange={setDescription}
					label="Name for new pricing item"
					mb={1}
				/>
			)}

			<Select
				value={selectedZone}
				onChange={setSelectedZone}
				options={zoneOptions}
				label={isTerritory ? "Zone (required)" : "Zone (optional)"}
				disableNoSelectionOption={isTerritory}
				fillContainer
				maxWidth="50%"
				smProps={{ maxWidth: "none" }}
			/>

			<Box maxWidth="50%" smProps={{ maxWidth: "none" }}>
				<Paragraph mb={0.5}>Rate $</Paragraph>
				<NumericInput
					value={price}
					onChange={(newValue) => setPrice(newValue ?? null)}
					decimalScale={2}
					fixedDecimalScale
					label={null}
					placeholder="Eg, 150"
					fillContainer
				/>
			</Box>

			<Box d="flex" gap={1}>
				<Box flex={1}>
					<Paragraph mb={0.5}>Rate takes effect on</Paragraph>
					<DateInput
						value={effectiveDate}
						onChange={setEffectiveDate}
						label={null}
						fillContainer
						disablePast
					/>
				</Box>
				<Box flex={1}>
					<Paragraph mb={0.5}>Rate ends after</Paragraph>
					<DateInput
						value={endDate}
						onChange={setEndDate}
						label={null}
						fillContainer
						disablePast
					/>
				</Box>
			</Box>
		</Modal>
	)
}

const zoneOptions = Array.from(Array(30), (e, i) => ({
	value: (i + 1).toString(),
	text: `${(i + 1).toString()}${i === 0 ? ` (Default)` : ""}`,
}))

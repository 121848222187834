import { FC, useEffect, useMemo, useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { Controller, useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"
import { Column } from "react-table"

import {
	GenericLineItem,
	lineItemsAreEqual,
	makeApiErrorMessage,
	useCalculateLineItemPrices,
	useCreatePurchaseOrder,
	useCustomer,
	useUploadPurchaseOrderAttachment,
} from "@ncs/ncs-api"
import { extractNumber, formatCurrency, unpythonify } from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	Box,
	Button,
	ConfirmExitIntent,
	CustomerBillToSelectorFormField,
	CustomerSelectorFormField,
	Divider,
	ErrorText,
	FileInput,
	GridContainer,
	GridItem,
	Heading,
	HeadingDivider,
	IconButton,
	Label,
	OrderShipMethodSelector,
	Paragraph,
	Price,
	Table,
	TextareaFormField,
	TextInputFormField,
	useChangeCallback,
	useScrollToTop,
	useToast,
	VendorSelectorFormField,
} from "@ncs/web-legos"

import { LineItemEditorModal, SaveLineItemExtras } from "~/components"
import { purchaseOrderAttachmentFileTypes } from "~/util/purchase-orders"

import {
	assemblePurchaseOrderPost,
	CreatePurchaseOrderForm,
	CreatePurchaseOrderFormSchema,
	defaultPurchaseOrderFormValues,
} from "../purchase-orders-util"

export const PurchaseOrdersCreateTab: FC = () => {
	const { makeSuccessToast } = useToast()
	const history = useHistory()
	const scrollToTop = useScrollToTop()
	const recalculateLineItems = useCalculateLineItemPrices()
	const createPurchaseOrder = useCreatePurchaseOrder()
	const uploadAttachment = useUploadPurchaseOrderAttachment()

	const [isSubmitting, setIsSubmitting] = useState(false)
	const [submissionErrorText, setSubmissionErrorText] = useState<string | null>(null)
	const [lineItemsToSubmit, setLineItemsToSubmit] = useState<GenericLineItem[]>([])
	const [attachments, setAttachments] = useState<File[]>([])
	const [lineItemToEdit, setLineItemToEdit] = useState<GenericLineItem | null>(null)
	const [showNewLineItemModal, setShowNewLineItemModal] = useState(false)
	const [lineItemErrorText, setLineItemErrorText] = useState<string | null>(null)
	const [customersErrorText, setCustomersErrorText] = useState<string | null>(null)

	const {
		control,
		handleSubmit,
		watch,
		formState: { isValid: formIsValid, submitCount },
	} = useForm<CreatePurchaseOrderForm>({
		mode: "onChange",
		resolver: zodResolver(CreatePurchaseOrderFormSchema),
		defaultValues: defaultPurchaseOrderFormValues,
	})

	const [shipToCustomerId, billToCustomerId] = watch([
		"shipToCustomerId",
		"billToCustomerId",
		"shipMethodId",
	])

	const [shipToCustomer] = useCustomer(shipToCustomerId)
	const [billToCustomer] = useCustomer(billToCustomerId)

	const onSubmit = async (formData: CreatePurchaseOrderForm) => {
		if (lineItemsToSubmit.length < 1) {
			setLineItemErrorText("At least 1 line item is required")
			return
		}
		if (!shipToCustomer?.servicable || !billToCustomer?.servicable) {
			scrollToTop()
			return
		}

		const purchaseOrderPost = assemblePurchaseOrderPost(formData, lineItemsToSubmit, {
			orderSubtotal,
			orderTaxes,
			orderTotal,
		})

		try {
			setIsSubmitting(true)
			const response = await createPurchaseOrder(purchaseOrderPost)
			const newOrder = unpythonify(response.data)

			// Now that new order is created, call to attach any attachments.
			await Promise.all([
				...attachments.map((attachment) =>
					uploadAttachment(attachment, newOrder.id.toString())
				),
			])

			makeSuccessToast(`Order #${newOrder.id} created`)
			history.push(`/purchase-orders/${newOrder.id}`)
		} catch (e) {
			setSubmissionErrorText(makeApiErrorMessage(e))
			setIsSubmitting(false)
		}
	}

	const onLineItemSave = (newLine: GenericLineItem, { isEdit }: SaveLineItemExtras) => {
		const existingEntryIndex = lineItemsToSubmit.findIndex((line) =>
			lineItemsAreEqual(line, newLine)
		)

		if (isEdit) {
			// We're editing an existing line, so blow away the old one.
			setLineItemsToSubmit((prev) =>
				prev.map((lineItem, i) => (i === existingEntryIndex ? newLine : lineItem))
			)
			makeSuccessToast("Line item updated")
		} else {
			if (existingEntryIndex !== -1) {
				// We weren't editing, but the part already existed so merge their quantities.
				setLineItemsToSubmit((prev) =>
					prev.map((lineItem, i) =>
						i === existingEntryIndex ?
							{
								...newLine,
								quantity: lineItem.quantity + newLine.quantity,
								lineTotal:
									(lineItem.quantity + newLine.quantity) * newLine.finalPrice,
							}
						:	lineItem
					)
				)
				makeSuccessToast("Line item updated")
			} else {
				setLineItemsToSubmit((prev) => [...prev, newLine]) // Brand new, just add it.
				makeSuccessToast("Line item added")
			}
		}
	}

	const onLineItemDelete = (lineToDelete: GenericLineItem) => {
		setLineItemToEdit(null)
		setLineItemsToSubmit((prev) =>
			prev.filter((line) => !lineItemsAreEqual(line, lineToDelete))
		)
	}

	const onLineItemEdit = (lineItem: GenericLineItem) => {
		setLineItemToEdit(lineItem)
	}

	const handleAttachmentSelect = (file: File) => {
		setAttachments((prev) => [...prev, file])
	}

	const handleRemoveAttachment = (indexToRemove: number) => {
		setAttachments((prev) => prev.filter((attachment, i) => i !== indexToRemove))
	}

	// Recalculate the line items prices if the bill to changes.
	useChangeCallback(billToCustomerId, async (newBillToId) => {
		if (newBillToId && shipToCustomerId) {
			const updateLineItems = await recalculateLineItems({
				billToId: newBillToId,
				customerId: shipToCustomerId,
				lineItems: lineItemsToSubmit,
			})
			setLineItemsToSubmit(updateLineItems)
		}
	})

	// Show an error if you choose a ship to or bill to that's not serviceable.
	useEffect(() => {
		if (!!shipToCustomer && shipToCustomer.servicable === false) {
			setCustomersErrorText(
				"Your Ship To selection is invalid because it is not serviceable."
			)
		} else if (!!billToCustomer && billToCustomer.servicable === false) {
			setCustomersErrorText(
				"Your Bill To selection is invalid because it is not serviceable."
			)
		} else {
			setCustomersErrorText(null)
		}
	}, [billToCustomer, shipToCustomer])

	// Clear out line items error text.
	useEffect(() => {
		if (!!lineItemErrorText && lineItemsToSubmit.length > 0) {
			setLineItemErrorText(null)
		}
	}, [lineItemErrorText, lineItemsToSubmit])

	const currentWeightTotal = useMemo(() => {
		return lineItemsToSubmit.reduce((total, line) => {
			return total + extractNumber(line.part?.weight ?? 0) * line.quantity
		}, 0)
	}, [lineItemsToSubmit])

	const { orderSubtotal, orderTaxes, orderTotal } = useMemo(() => {
		const lineItemsSubtotal = lineItemsToSubmit.reduce(
			(prev, lineItem) => prev + lineItem.finalPrice * lineItem.quantity,
			0
		)
		const lineItemsTaxes = lineItemsToSubmit.reduce(
			(prev, lineItem) => prev + lineItem.finalPrice * lineItem.quantity * lineItem.taxRate,
			0
		)

		return {
			orderSubtotal: lineItemsSubtotal,
			orderTaxes: lineItemsTaxes,
			orderTotal: lineItemsSubtotal + lineItemsTaxes,
		}
	}, [lineItemsToSubmit])

	const canSubmit =
		formIsValid &&
		lineItemsToSubmit.length > 0 &&
		shipToCustomer?.servicable &&
		billToCustomer?.servicable

	return (
		<>
			<HeadingDivider headingVariant="h3">1. Customer</HeadingDivider>

			<GridContainer rowGap={0}>
				<GridItem xs={12} sm={6}>
					<Heading mb={0.6} bold variant="h5" icon="truck">
						Ship To
					</Heading>
					<CustomerSelectorFormField
						control={control}
						name="shipToCustomerId"
						label=""
						placeholder="Ship To customer..."
						emptyValueFallback=""
						excludeBillTo
						limitToServiceCustomers
					/>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<Heading mb={0.6} bold variant="h5" icon="dollar-sign">
						Bill To
					</Heading>
					<CustomerBillToSelectorFormField
						control={control}
						name="billToCustomerId"
						customerId={shipToCustomerId || null}
						showNoSelectionOption={!shipToCustomerId}
						noSelectionOptionText="Choose Ship To, then Bill To customer..."
						label=""
						addBillToInternal
					/>
				</GridItem>
				{!!customersErrorText && (
					<GridItem xs={12}>
						<AnimatedEntrance show>
							<ErrorText>{customersErrorText}</ErrorText>
						</AnimatedEntrance>
					</GridItem>
				)}

				<GridItem xs={12} sm={6}>
					<VendorSelectorFormField
						control={control}
						name="vendorId"
						initialId="1"
						promptVendorUpdate
					/>
					<TextInputFormField
						control={control}
						name="purchaseOrderNumber"
						label="Customer purchase order"
						emptyValueFallback=""
					/>
				</GridItem>
			</GridContainer>

			<HeadingDivider headingVariant="h3" mt={3}>
				2. Line Items
			</HeadingDivider>

			<GridContainer pr={0.5} mt={2} mb={1}>
				<GridItem xs={12} sm={6}>
					<Button
						variant="secondary-cta"
						icon="plus-circle"
						onClick={() => setShowNewLineItemModal(true)}
						disabled={!shipToCustomerId || !billToCustomerId}
					>
						Add line item
					</Button>
				</GridItem>
				<GridItem xs={12} sm={6} display="flex" columnGap={2} justifyContent="flex-end">
					<div>
						<Label>Subtotal</Label>
						<Price price={orderSubtotal} small />
					</div>
					<div>
						<Label>Taxes</Label>
						<Price price={orderTaxes} small />
					</div>
					<div>
						<Label>Total</Label>
						<Price price={orderTotal} small />
					</div>
				</GridItem>
			</GridContainer>

			{lineItemsToSubmit.length > 0 && (
				<Table
					data={lineItemsToSubmit}
					columns={columns}
					rowMenu={[
						{
							label: "Edit",
							iconName: "pencil",
							onClick: ({ original }) => onLineItemEdit(original),
							disabledAccessor: () => !shipToCustomerId || !billToCustomerId,
						},
						{
							label: "Remove",
							iconName: "trash",
							onClick: ({ original }) => onLineItemDelete(original),
						},
					]}
					disableAllSorting
				/>
			)}

			{!!lineItemErrorText && <ErrorText my={1}>{lineItemErrorText}</ErrorText>}

			<HeadingDivider headingVariant="h3" mt={3}>
				3. Shipping
			</HeadingDivider>

			<Controller
				control={control}
				name="shipMethodId"
				render={({ field: { onBlur, value, onChange }, fieldState: { error } }) => (
					<OrderShipMethodSelector
						value={value ?? null}
						onChange={onChange}
						onBlur={onBlur}
						error={error?.message}
					/>
				)}
			/>

			<GridContainer>
				<GridItem xs={12} sm={6}>
					<TextareaFormField
						control={control}
						name="comments"
						label="Additional comments"
					/>
				</GridItem>
			</GridContainer>

			<Divider mt={3} mb={1} />

			{attachments.length > 0 && (
				<Box>
					<Heading bold variant="h5" mt={1} mb={0.35}>
						Attachments
					</Heading>
					{attachments.map(({ name, size }, i) => (
						<Box
							key={`${name}-${size}-${i}`}
							display="flex"
							alignItems="center"
							gap={1}
						>
							<Paragraph small>{name}</Paragraph>
							<IconButton
								icon="trash-alt"
								onClick={() => handleRemoveAttachment(i)}
								color="primary"
								size="sm"
								title="Remove attachment"
							/>
						</Box>
					))}
				</Box>
			)}

			<Box display="flex" justifyContent="flex-start" alignItems="center" gap={2} my={1}>
				<FileInput
					onChange={handleAttachmentSelect}
					fileTypes={purchaseOrderAttachmentFileTypes}
					buttonVariant="text"
					icon="paperclip-vertical"
					label="Attach file to order"
					width="auto"
				/>
			</Box>

			<GridContainer>
				<GridItem
					display="flex"
					justifyContent="space-between"
					columnGap={2}
					sm={12}
					md={6}
					lg={4}
				>
					<div>
						<Label>Subtotal</Label>
						<Price price={orderSubtotal} />
					</div>
					<div>
						<Label>Taxes</Label>
						<Price price={orderTaxes} />
					</div>
					<div>
						<Label>Total</Label>
						<Price price={orderTotal} />
					</div>
				</GridItem>
			</GridContainer>

			<GridContainer mt={2} mb={5}>
				<GridItem sm={12} md={6} lg={4}>
					<Button
						variant="primary-cta"
						onClick={handleSubmit(onSubmit)}
						isLoading={isSubmitting}
						disabled={submitCount > 0 && !canSubmit}
						fillContainer
					>
						CREATE ORDER
					</Button>
				</GridItem>
			</GridContainer>

			{!!submissionErrorText && (
				<ErrorText mt={-4} mb={4}>
					{submissionErrorText}
				</ErrorText>
			)}

			{(showNewLineItemModal || !!lineItemToEdit) && (
				<LineItemEditorModal
					lineItemToEdit={lineItemToEdit}
					onClose={() => {
						setShowNewLineItemModal(false)
						setLineItemToEdit(null)
					}}
					shipToId={shipToCustomerId || null}
					billToId={billToCustomerId || null}
					onSave={onLineItemSave}
					onRemove={onLineItemDelete}
					isPurchaseOrder
					currentWeightTotal={currentWeightTotal}
					skipRestrictedCheck
				/>
			)}

			<ConfirmExitIntent
				when={lineItemsToSubmit.length > 0 && !isSubmitting}
				message="Leave without completing order?"
			/>
		</>
	)
}

const columns: Column<GenericLineItem>[] = [
	{
		Header: "Description",
		accessor: "description",
	},
	{
		Header: "Unit Price",
		accessor: (original) => formatCurrency(original.finalPrice),
	},
	{
		Header: "Qty",
		accessor: "quantity",
	},
	{
		Header: "Line Subtotal",
		accessor: (original) => formatCurrency(original.subtotal),
	},
]

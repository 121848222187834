import { Dispatch, ReactElement, SetStateAction } from "react"

import { InventoryRequestStatusSelector } from "../selectors"

export enum InventoryRequestStatusParamKey {
	InventoryRequestStatusKey = "status",
}

export interface InventoryRequestStatusParamState {
	[InventoryRequestStatusParamKey.InventoryRequestStatusKey]: string | null
}

export interface InventoryRequestStatusQueryFilterProps<
	QueryParamState extends InventoryRequestStatusParamState,
> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

export const InventoryRequestStatusQueryFilter = <
	QueryParamState extends InventoryRequestStatusParamState,
>({
	queryParamState,
	setQueryParamState,
}: InventoryRequestStatusQueryFilterProps<QueryParamState>): ReactElement => {
	return (
		<InventoryRequestStatusSelector
			value={queryParamState[InventoryRequestStatusParamKey.InventoryRequestStatusKey]}
			disableNoSelectionOption={false}
			fillContainer
			onChange={(newValue, newOption) =>
				setQueryParamState((prev) => {
					const newInventoryRequestStatusParamState: InventoryRequestStatusParamState = {
						[InventoryRequestStatusParamKey.InventoryRequestStatusKey]:
							newOption?.id.toString() ?? null,
					}

					return {
						...prev,
						...newInventoryRequestStatusParamState,
					}
				})
			}
		/>
	)
}

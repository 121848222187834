import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"

import { SearchSelector } from "@ncs/bricks/components"
import pluralize from "@ncs/bricks/util/pluralization"

import { loadLookups, LOOKUPS } from "../../redux/services/lookups"

const PartGroupSelector = ({ label, placeholder, onSelect, ...rest }) => {
	const dispatch = useDispatch()
	const partGroups = useSelector((store) => store.lookups.partGroups) ?? []

	useEffect(() => {
		// Just in case you haven't done this yet, we'll call the lookup now.
		dispatch(loadLookups(LOOKUPS.PartGroups))
	}, [dispatch])

	async function handleGetSuggestions(search) {
		const suggestions = partGroups.filter(
			(group) =>
				group.description.toUpperCase().includes(search.toUpperCase()) ||
				group.id.includes(search)
		)

		return {
			payload: suggestions,
		}
	}

	function handleGetSuggestionValue(partGroup) {
		return `${partGroup.description} (${partGroup.partCount.toLocaleString()} ${pluralize(
			"product",
			partGroup.partCount
		)})`
	}

	function handleSuggestionSelected(event, { suggestion }) {
		onSelect(suggestion)
	}

	return (
		<SearchSelector
			label={label}
			placeholder={placeholder}
			getSuggestions={handleGetSuggestions}
			getSuggestionValue={handleGetSuggestionValue}
			onSuggestionSelected={handleSuggestionSelected}
			{...rest}
		/>
	)
}

PartGroupSelector.defaultProps = {
	label: "Part Group",
	placeholder: "Search for a part group...",
}

PartGroupSelector.propTypes = {
	onSelect: PropTypes.func.isRequired,
}

export default PartGroupSelector

import { ReactElement, useMemo, useState } from "react"

import {
	Control,
	Controller,
	FieldError,
	FieldValues,
	Path,
	PathValue,
	UnpackNestedValue,
} from "react-hook-form"

import { InventoryPart, useInventoryPart } from "@ncs/ncs-api"

import { useChangeCallback } from "../../util"
import { PartSelector, PartSelectorProps } from "../selectors"

export interface InventoryPartSelectorFormFieldProps<TFieldValues extends FieldValues>
	extends Omit<PartSelectorProps, "value" | "onChange"> {
	control: Control<TFieldValues>
	name: Path<TFieldValues>
	emptyValueFallback?: string | null
}

export const InventoryPartSelectorFormField = <TFieldValues extends FieldValues>({
	name,
	control,
	...rest
}: InventoryPartSelectorFormFieldProps<TFieldValues>): ReactElement => {
	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { onBlur, value, onChange }, fieldState: { error } }) => {
				return (
					<InventoryPartSelectorFormFieldRender
						{...rest}
						formValue={value}
						formOnChange={onChange}
						formOnBlur={onBlur}
						formError={error}
					/>
				)
			}}
		/>
	)
}

interface InventoryPartSelectorFormFieldRenderProps<TFieldValues extends FieldValues>
	extends Omit<PartSelectorProps, "value" | "onChange"> {
	formValue: UnpackNestedValue<PathValue<TFieldValues, Path<TFieldValues>>>
	formOnChange: (newValue: string | null) => void
	formOnBlur?: () => void
	formError?: FieldError
	emptyValueFallback?: string | null
}

const InventoryPartSelectorFormFieldRender = <TFieldValues extends FieldValues>({
	formValue,
	formOnChange,
	formOnBlur,
	formError,
	emptyValueFallback = null,
	...rest
}: InventoryPartSelectorFormFieldRenderProps<TFieldValues>): ReactElement => {
	const [localPart, setLocalPart] = useState<InventoryPart | null>(null)

	const [formPart, formPartLoading] = useInventoryPart(formValue)

	// When local state changes, update the form.
	useChangeCallback(
		localPart?.id,
		(newLocalPartId) => {
			formOnChange(newLocalPartId ?? emptyValueFallback)
		},
		{ callOnSetup: true }
	)

	// When the part listening directly to the form ID changes, sync up our
	// local state it's wrong. Handles form state being set outside of this
	// component.
	useChangeCallback(
		formPart,
		(newPart) => {
			if ((newPart?.id ?? null) !== (localPart?.id ?? null)) {
				setLocalPart(newPart ?? null)
			}
		},
		{ callOnSetup: true }
	)

	// If the form value goes null, we need a way to set our local state to null
	// too.
	useChangeCallback(
		formValue,
		(newFormValue) => {
			if (!newFormValue && localPart != null) {
				setLocalPart(null)
			}
		},
		{ callOnSetup: true }
	)

	const value = useMemo(() => {
		if (!formValue) return null

		return formPartLoading ? localPart : formPart ?? null
	}, [formValue, formPartLoading, localPart, formPart])

	return (
		<PartSelector
			{...rest}
			onBlur={formOnBlur}
			value={value}
			isLoading={formPartLoading}
			onChange={setLocalPart}
			error={formError?.message}
		/>
	)
}

import { FC } from "react"

import { CreateBillOfMaterialPost, useCreateBillOfMaterials } from "@ncs/ncs-api"
import { ExtendableModalProps, Modal, useToast } from "@ncs/web-legos"

import { RecipeForm } from "./RecipeForm"

export type NewRecipeModalProps = ExtendableModalProps

export const NewRecipeModal: FC<NewRecipeModalProps> = ({ ...rest }) => {
	const { makeSuccessToast } = useToast()
	const createBom = useCreateBillOfMaterials()

	const onSave = async (data: CreateBillOfMaterialPost) => {
		await createBom(data)
		makeSuccessToast("Bill Of Material created")
		rest.onClose()
	}

	return (
		<Modal {...rest} title="Create New Bill Of Material">
			<RecipeForm bom={null} handleCreate={onSave} />
		</Modal>
	)
}

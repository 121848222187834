import { FC } from "react"

import { Column } from "react-table"

import {
	InventoryConsolidationReportLine,
	InventoryConsolidationReportQueryParams,
	useExportInventoryConsolidationReport,
	useInventoryConsolidationReport,
} from "@ncs/ncs-api"
import { formatNumber, Typify } from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	LocationQueryFilter,
	ReactTableSortType,
	Table,
	TerritoryQueryFilter,
	useUrlState,
} from "@ncs/web-legos"

export type InventoryConsolidationReportUrlState = Typify<InventoryConsolidationReportQueryParams>

export const InventoryConsolidationReport: FC = () => {
	const [params, { setUrlState: setParams }] = useUrlState<InventoryConsolidationReportUrlState>(
		initialInventoryConsolidationReportUrlState
	)

	const [data, isLoading] = useInventoryConsolidationReport({ params })
	const dataExport = useExportInventoryConsolidationReport(params)

	const noFilters = !params.location && !params.territory

	return (
		<AnimatedEntrance show>
			<Table
				data={data ?? []}
				isLoading={isLoading}
				columns={columns}
				queryParamState={params}
				setQueryParamState={setParams}
				pinnedQueryFilters={[LocationQueryFilter, TerritoryQueryFilter]}
				dataExport={dataExport}
				noDataText={
					noFilters ? "This report requires setting at least one filter" : undefined
				}
				storeColumnVisibility="inventory-consolidation-report-columns"
			/>
		</AnimatedEntrance>
	)
}

const columns: Column<InventoryConsolidationReportLine>[] = [
	{
		Header: "Location",
		accessor: ({ location, locationDescription }) => `(${location}) ${locationDescription}`,
	},
	{
		Header: "Part",
		accessor: ({ part, partDescription }) => `(${part}) ${partDescription}`,
	},
	{
		Header: "Bin 1",
		id: "bin1Quantity",
		sortType: ReactTableSortType.Number,
		accessor: ({ bin1, bin1Quantity }) => `${bin1}: ${formatNumber(bin1Quantity)}`,
	},
	{
		Header: "Bin 2",
		id: "bin2Quantity",
		sortType: ReactTableSortType.Number,
		accessor: ({ bin2, bin2Quantity }) => `${bin2}: ${formatNumber(bin2Quantity)}`,
	},
	{
		Header: "Bin 3",
		id: "bin3Quantity",
		sortType: ReactTableSortType.Number,
		hiddenByDefault: true,
		accessor: ({ bin3, bin3Quantity }) =>
			bin3 && bin3Quantity != null ? `${bin3}: ${formatNumber(bin3Quantity)}` : "-",
	},
	{
		Header: "Bin 4",
		id: "bin4Quantity",
		sortType: ReactTableSortType.Number,
		hiddenByDefault: true,
		accessor: ({ bin4, bin4Quantity }) =>
			bin4 && bin4Quantity != null ? `${bin4}: ${formatNumber(bin4Quantity)}` : "-",
	},
	{
		Header: "Bin 5",
		id: "bin5Quantity",
		sortType: ReactTableSortType.Number,
		hiddenByDefault: true,
		accessor: ({ bin5, bin5Quantity }) =>
			bin5 && bin5Quantity != null ? `${bin5}: ${formatNumber(bin5Quantity)}` : "-",
	},
]

const initialInventoryConsolidationReportUrlState: InventoryConsolidationReportUrlState = {
	location: null,
	territory: null,
}

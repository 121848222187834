import { FC, useMemo, useState } from "react"

import { useHistory, useParams } from "react-router-dom"

import {
	DeviceStatusCategory,
	useConnectedMachineDetails,
	useConnectedMachinesAtSites,
} from "@ncs/ncs-api"
import { formatDateTime, titleCase } from "@ncs/ts-utils"
import {
	Box,
	Button,
	Callout,
	Card,
	encodeUrlState,
	Icon,
	LoadingSpinner,
	Paragraph,
	SkeletonRows,
	StatusBlip,
	Tabs,
	useScreenSizeMatch,
	useScrollToTopOnMount,
	useUrlState,
} from "@ncs/web-legos"

import { c12yAutoRefreshConfig } from "~/util"

import { DevicesTabUrlState } from "../ConnectedDevices/components"
import {
	EditMachineModal,
	MachineEventsTab,
	MachineStatesTab,
	MachineWashCountsTab,
	MachineWorkOrdersTab,
} from "./components"

export enum MachineDetailTab {
	FourDigitEvents = "4-Digit Events",
	TwoDigitStates = "2-Digit States",
	WashCounts = "Wash Counts",
	WorkOrders = "Work Orders",
}

export const MachineDetail: FC = () => {
	useScrollToTopOnMount()
	const screenIsSm = useScreenSizeMatch("sm")
	const history = useHistory<{ devicesUrlState?: DevicesTabUrlState }>()
	const { id } = useParams<{ id: string }>()
	const [{ tab }, { updateUrlValue }] = useUrlState({
		tab: MachineDetailTab.FourDigitEvents,
	})
	const [showEditModal, setShowEditModal] = useState(false)

	const [siteWithMachines, siteWithMachinesLoading] = useConnectedMachinesAtSites({
		params: { device: id },
		queryConfig: c12yAutoRefreshConfig,
	})
	const [customer, machine] = useMemo(() => {
		// Because we pass in the specific device as a param, there should only be one item
		// in the array, and the devices list should have the device in question in it.
		const site = siteWithMachines?.find((s) => s.devices.some((d) => d.id === id))

		return [site?.customer, site?.devices.find((d) => d.id === id)]
	}, [siteWithMachines, id])

	const [c12yDetails, c12yDetailsLoading] = useConnectedMachineDetails(id, {
		queryConfig: c12yAutoRefreshConfig,
	})

	return (
		<>
			<Button
				icon="long-arrow-left"
				onClick={() =>
					history.push({
						pathname: "/connectivity/connected-devices",
						search: encodeUrlState<DevicesTabUrlState>(
							history.location.state?.devicesUrlState
						),
					})
				}
				containerProps={{ mb: 3 }}
			>
				Back
			</Button>

			<Card
				heading={
					machine && customer ?
						`${machine.name} at ${customer.name} (${customer.number})`
					:	undefined
				}
				headingDetail={
					machine ?
						[
							machine.equipment?.vintage,
							machine.equipment ?
								`Serial # ${machine.equipment.serialNumber || "(none)"}`
							:	null,
							`Device ID ${machine.deviceId}`,
						]
					:	undefined
				}
				headingIcon="router"
				renderRight={() =>
					siteWithMachinesLoading ? undefined : (
						<Button icon="pencil" onClick={() => setShowEditModal(true)}>
							Edit device
						</Button>
					)
				}
			>
				<Box mb={3}>
					{siteWithMachinesLoading ?
						<LoadingSpinner />
					:	<Callout maxWidth={45}>
							<Box display="flex" alignItems="center" columnGap={0.62} mb={0.25}>
								<StatusBlip status={machine?.state} size={1} />
								<Paragraph>
									Status: <strong>{titleCase(machine?.state) || "--"}</strong>
								</Paragraph>
							</Box>
							<Box display="flex" alignItems="center" columnGap={0.5} mb={0.25}>
								<Icon
									icon={
										machine?.state !== DeviceStatusCategory.Offline ?
											"wifi"
										:	"wifi-slash"
									}
								/>
								<Paragraph>
									Last communication:{" "}
									{!!c12yDetails?.lastUpdatedOn && (
										<strong>
											{formatDateTime(c12yDetails.lastUpdatedOn)}
										</strong>
									)}
								</Paragraph>
								{c12yDetailsLoading && <SkeletonRows rows={1} width={11} />}
							</Box>
							<Box display="flex" alignItems="center" columnGap={0.5} mb={0.25}>
								<Icon icon="code-commit" />
								<Paragraph>
									IFSF states:{" "}
									{c12yDetails?.activeStates.length ? "" : <strong>--</strong>}
								</Paragraph>
							</Box>

							{c12yDetailsLoading && (
								<SkeletonRows
									rows={2}
									width={screenIsSm ? "100%" : 35}
									containerProps={{ ml: 2 }}
								/>
							)}

							{!!c12yDetails?.activeStates.length &&
								c12yDetails.activeStates
									.sort((a, b) => (a.startOn > b.startOn ? -1 : 1))
									.map((state) => (
										<Box
											key={`${state.machineCode}-${state.startOn}`}
											display="flex"
											gap={0.5}
											ml={2.5}
											mb={0.5}
										>
											<Box>&bull;</Box>
											<Paragraph code>
												<strong>
													{state.machineCode} -{" "}
													{formatDateTime(state.startOn)}:{" "}
												</strong>
												{state.description || "--"}
											</Paragraph>
										</Box>
									))}
						</Callout>
					}
				</Box>

				<Tabs
					currentTab={tab}
					onChange={(newTab) => updateUrlValue("tab", newTab)}
					disabled={siteWithMachinesLoading}
					panels={[
						{
							navLabel: MachineDetailTab.FourDigitEvents,
							navIcon: <Icon icon="bell-on" />,
							component: <MachineEventsTab machine={machine} />,
						},
						{
							navLabel: MachineDetailTab.TwoDigitStates,
							navIcon: <Icon icon="sensor-alert" />,
							component: <MachineStatesTab machine={machine} />,
						},
						{
							navLabel: MachineDetailTab.WashCounts,
							navIcon: <Icon icon="chart-bar" />,
							component: <MachineWashCountsTab machine={machine} />,
						},
						{
							navLabel: MachineDetailTab.WorkOrders,
							navIcon: <Icon icon="tools" />,
							component: <MachineWorkOrdersTab machine={machine} />,
						},
					]}
				/>
			</Card>

			{!!machine && !!customer && showEditModal && (
				<EditMachineModal
					onClose={() => setShowEditModal(false)}
					machine={machine}
					customerId={customer.id}
				/>
			)}
		</>
	)
}

import React from "react"
import { TICKETING_FILTERS } from "@ncs/mortar/util/constants"

import { GridContainer, GridItem, QuickCard, DynamicTable, InlineEditingInput } from "~/components"

//local componenets
import TicketSubtypeAddContainer from "./TicketSubtypeAddContainer"

//material ui icons
import ListIcon from "@material-ui/icons/List"

// styles
import { withStyles } from "@material-ui/core/styles"

const styles = {
	right: {
		textAlign: "right",
	},
}

function TicketSubtypeManagement(props) {
	const {
		fetchDataAction,
		reduxKey,
		selectedTicketType,
		onSubtypeSave,
		isRefreshing,
		onSaveEdit,
		classes,
	} = props
	const subtypeColumns = [
		{
			Header: "Type",
			accessor: (x) => (
				<InlineEditingInput
					labelText="Description"
					value={x.description}
					onSave={(newDescription) => onSaveEdit(x.id, newDescription)}
					isRefreshingData={isRefreshing}
					inputProps={{ multiline: false }}
				/>
			),
			id: "edit",
			sortable: true,
			toggleable: false,
			headerStyle: { textAlign: "center " },
		},
	]

	return (
		<GridContainer>
			<GridItem xs={12} show={typeof selectedTicketType === "number"}>
				<QuickCard title="Subtype Management" shortenHeader={true} icon={<ListIcon />}>
					<GridContainer>
						<GridItem xs={12} lg={12} className={classes.right} show={false}>
							<TicketSubtypeAddContainer onSubtypeSave={onSubtypeSave} />
						</GridItem>
						<GridItem xs={12}>
							<DynamicTable // TODO: make props static
								reduxKey={reduxKey}
								fetchDataAction={fetchDataAction}
								filterFields={TICKETING_FILTERS}
								style={{ textAlign: "center" }}
								columns={subtypeColumns}
							/>
						</GridItem>
					</GridContainer>
				</QuickCard>
			</GridItem>
		</GridContainer>
	)
}

export default withStyles(styles)(TicketSubtypeManagement)

import React from "react"

import AttachFile from "@material-ui/icons/AttachFile"
import Remove from "@material-ui/icons/Remove"
import Videocam from "@material-ui/icons/Videocam"
import PropTypes from "prop-types"
import ReactTable from "react-table-6"

import { Button, Select } from "~/components"

const CartTable = (props) => {
	const { items, canUpdateQuantity, onUpdateQuantity, onRemoveFromCart } = props

	const tableData = items.map(({ item, quantity }) => ({
		id: item.id,
		preview:
			(item.document_type_name || "").toLowerCase() === "image" ?
				<img
					src={item.presign_url}
					title={`${item.filename}.${item.extension}`}
					alt={`${item.filename}.${item.extension}`}
					height="75"
				/>
			: (item.document_type_name || "").toLowerCase() === "video" ? <Videocam />
			: <AttachFile />,
		filename: item.filename,
		quantity:
			canUpdateQuantity ?
				<Select
					id="addingToCartQuantity"
					value={quantity}
					onChange={(qty) => onUpdateQuantity(item, qty)}
					options={[
						{ value: 1, text: "1" },
						{ value: 10, text: "10" },
						{ value: 25, text: "25" },
						{ value: 50, text: "50" },
					]}
				/>
			:	quantity,
		actions: (
			<Button
				justIcon
				round
				color="danger"
				size="sm"
				title="Delete"
				onClick={(e) => {
					e.stopPropagation()
					onRemoveFromCart(item)
				}}
			>
				<Remove />
			</Button>
		),
	}))

	let columns = [
		{
			Header: "Preview",
			accessor: "preview",
			sortable: false,
			filterable: false,
			headerStyle: { textAlign: "center " },
		},
		{
			Header: "Filename",
			accessor: "filename",
			sortable: true,
			filterable: false,
			headerStyle: { textAlign: "center " },
		},
		{
			Header: "Quantity",
			accessor: "quantity",
			sortable: true,
			filterable: false,
			headerStyle: { textAlign: "center " },
		},
	]

	if (canUpdateQuantity) {
		columns.push({
			Header: "Remove Item",
			accessor: "actions",
			sortable: false,
			filterable: false,
			headerStyle: { textAlign: "center " },
		})
	}

	return (
		<ReactTable
			data={tableData}
			columns={columns}
			defaultPageSize={items.length}
			minRows={0}
			showPaginationTop={false}
			showPaginationBottom={false}
			className="-striped -highlight"
			style={{ textAlign: "center " }}
		/>
	)
}

CartTable.defaultProps = {
	canUpdateQuantity: false,
	onUpdateQuantity: () => {},
	onRemoveFromCart: () => {},
}

CartTable.propTypes = {
	items: PropTypes.array.isRequired,
	canUpdateQuantity: PropTypes.bool,
	onUpdateQuantity: PropTypes.func,
	onRemoveFromCart: PropTypes.func.isRequired,
}

export default CartTable

import { z } from "zod"

import { CreateDispatchPost } from "@ncs/ncs-api"
import { extractNumber, validatePhone } from "@ncs/ts-utils"

export const CreateDispatchFormSchema = z.object({
	customerId: z.string().min(1, "Required"),
	caller: z.string().max(100).min(1, "Required"),
	altCallBackNumber: z
		.string()
		.max(100)
		.nullable()
		.refine(
			(value) => {
				return !value || validatePhone(value)
			},
			{
				message: "This doesn't appear to be a valid phone number",
			}
		),
	assignedEmployeeId: z.string().nullable(),
	bay: z.string().nullable(),
	billNotToExceed: z.number().nullable(),
	callBackNumber: z
		.string()
		.max(100)
		.nullable()
		.refine(
			(value) => {
				return !value || validatePhone(value)
			},
			{
				message: "This doesn't appear to be a valid phone number",
			}
		),
	callType: z.string().min(1, "Required"),
	isReturnCall: z.boolean(),
	isOutOfChemicals: z.boolean(),
	poTicket: z.string().nullable(),
	priority: z.string().min(1, "Required"),
	symptoms: z.string().min(1, "Required").max(1000),
})

export type CreateDispatchForm = z.infer<typeof CreateDispatchFormSchema>

export const defaultCreateDispatchFormValues: CreateDispatchForm = {
	customerId: "",
	caller: "",
	altCallBackNumber: null,
	assignedEmployeeId: null,
	bay: null,
	billNotToExceed: null,
	callBackNumber: null,
	callType: "",
	isReturnCall: false,
	isOutOfChemicals: false,
	poTicket: null,
	priority: "",
	symptoms: "",
}

export const assembleDispatchPost = (form: CreateDispatchForm): CreateDispatchPost => {
	return {
		dispatch: {
			altCallBackNumber: form.altCallBackNumber,
			assignedEmployee: form.assignedEmployeeId,
			bay: form.bay,
			billNotToExceed: form.billNotToExceed,
			callBackNumber: form.callBackNumber,
			callerName: form.caller,
			callType: extractNumber(form.callType),
			customer: form.customerId,
			isReturnCall: form.isReturnCall,
			outOfChemicals: form.isOutOfChemicals,
			poTicket: form.poTicket,
			priority: extractNumber(form.priority),
			symptoms: form.symptoms,
		},
	}
}

import { FC, useState } from "react"

import {
	Enterprise,
	makeApiErrorMessage,
	OrganizationMinimal,
	useCreateOrganization,
	useUpdateOrganization,
} from "@ncs/ncs-api"
import {
	AnimatedEntrance,
	ExtendableModalProps,
	Modal,
	OrganizationSelector,
	RadioBoolean,
	TextInput,
	useChangeCallback,
	useToast,
} from "@ncs/web-legos"

export interface AddOrganizationToEnterpriseModalProps extends ExtendableModalProps {
	enterprise: Enterprise
}

export const AddOrganizationToEnterpriseModal: FC<AddOrganizationToEnterpriseModalProps> = ({
	enterprise,
	...rest
}) => {
	const { makeSuccessToast } = useToast()
	const [isNew, setIsNew] = useState(true)
	const [newName, setNewName] = useState<string | null>(null)
	const [selectedOrganization, setSelectedOrganization] = useState<OrganizationMinimal | null>(
		null
	)
	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const updateOrganization = useUpdateOrganization()
	const createOrganization = useCreateOrganization()

	const handleSave = async () => {
		try {
			setIsSaving(true)

			if (isNew) {
				if (!newName) {
					throw new Error("Name is required")
				}
				await createOrganization({
					name: newName,
					enterprise: enterprise.id,
				})
				makeSuccessToast(`${newName} created and added to enterprise`)
			} else {
				if (!selectedOrganization) {
					throw new Error("Choose an organization")
				}
				await updateOrganization({
					id: selectedOrganization.id,
					updates: { enterprise: enterprise.id },
				})
				makeSuccessToast("Organization updated")
			}
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	useChangeCallback(isNew, (newIsNew) => {
		if (newIsNew === true) {
			setSelectedOrganization(null)
		} else {
			setNewName(null)
		}
	})

	return (
		<Modal
			{...rest}
			title="Add Organization To Enterprise"
			errorText={errorText}
			rightButtons={{
				buttonText: "Save",
				isLoading: isSaving,
				onClick: handleSave,
				disabled: !newName && !selectedOrganization,
			}}
		>
			<RadioBoolean
				htmlName="is-new-organization"
				description="Create a new organization or move an existing one to this enterprise."
				value={isNew}
				onChange={(newValue) => {
					setIsNew(newValue)
				}}
				yesText="Create a new organization"
				noText="Move an existing organization"
			/>
			<AnimatedEntrance show={isNew}>
				<TextInput value={newName} onChange={setNewName} label="New organization name" />
			</AnimatedEntrance>
			<AnimatedEntrance show={!isNew}>
				<OrganizationSelector
					value={selectedOrganization}
					onChange={setSelectedOrganization}
					label="Existing organization"
					placeholder="Search existing organizations..."
				/>
			</AnimatedEntrance>
		</Modal>
	)
}

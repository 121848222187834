import isEqual from "lodash/isEqual"

import { listUserProfiles } from "@ncs/mortar/redux/services/users"

const SET_SELECTED_COLUMNS = "userAdminModule/set_selected_columns"
const TOGGLE_COLUMN = "userAdminModule/toggle_column"
const FETCH_USER_PROFILES = "userAdminModule/fetch_user_profiles"
const SEARCH_USER_PROFILES = "userAdminModule/search_user_profiles"
const SEARCH_CHANGED = "userAdminModule/search_changed"
const SORTED_CHANGED = "userAdminModule/sorted_changed"
const PAGE_CHANGED = "userAdminModule/page_changed"
const PAGE_SIZE_CHANGED = "userAdminModule/page_size_changed"
const EXPANDED_CHANGED = "userAdminModule/expanded_changed"
const RESIZED_CHANGED = "userAdminModule/resized_changed"
const FILTERED_CHANGED = "userAdminModule/filtered_changed"
const RESET_FILTERS = "userAdminModule/reset_filters"

export const COLUMNS = {
	FirstName: "First Name",
	LastName: "Last Name",
	EmployeeNumber: "Employee Number",
	Email: "Email",
	Mobile: "Cell Phone #",
	TextEmail: "Text Email",
	EffectiveDate: "Effective Date",
	EndDate: "End Date",
	LastLogin: "Last Login",
	VerisaeUsername: "Verisae Username",
	Actions: "Actions",
}

const generateOrdering = (sorted) => {
	if (!sorted.length) return undefined

	return sorted.map((s) => (s.desc ? "-" : "") + s.id.replace(/\./g, "__")).join(",")
}

const generateFilters = (props) => {
	const { filtered } = props

	return Object.assign(
		{},
		...filtered.map((filter) => ({ [filter.id.replace(".", "__")]: filter.value }))
	)
}

const generateQuerystringParameters = ({ pageSize, page, sorted, filtered, search, ...rest }) => {
	let query = {
		ordering: generateOrdering(sorted),
		page: page + 1,
		pageSize: pageSize,
		search,
		...generateFilters({ filtered, ...rest }),
	}

	if (!query.search) delete query.search

	return query
}

export const fetchUserProfiles = () => {
	return (dispatch, getState) => {
		const state = getState().userAdminModule

		let query = generateQuerystringParameters(state)
		// ignore duplicate queries within half a second of each other
		if (
			state.fetched.time &&
			new Date() - state.fetched.time < 500 &&
			isEqual(query, state.fetched.query)
		)
			return

		dispatch({ type: FETCH_USER_PROFILES, payload: { query, time: new Date() } })
		dispatch(listUserProfiles(query))
	}
}

export const searchUserProfiles = () => {
	const debounced = (dispatch) => {
		dispatch(fetchUserProfiles())
	}

	debounced.meta = {
		debounce: {
			time: 325,
			key: SEARCH_USER_PROFILES,
		},
	}

	return debounced
}

export const handleSearchChange = (search) => {
	return (dispatch) => {
		dispatch({ type: SEARCH_CHANGED, payload: { search, page: 0 } })
		dispatch(searchUserProfiles())
	}
}

export const handleSortedChange = (payload) => {
	return (dispatch) => {
		dispatch({ type: SORTED_CHANGED, payload })
		dispatch(fetchUserProfiles())
	}
}

export const handlePageChange = (payload) => {
	return (dispatch) => {
		dispatch({ type: PAGE_CHANGED, payload })
		dispatch(fetchUserProfiles())
	}
}

export const handlePageSizeChange = (pageSize, page) => {
	return (dispatch) => {
		dispatch({ type: PAGE_SIZE_CHANGED, payload: { pageSize, page } })
		dispatch(fetchUserProfiles())
	}
}

export const handleExpandedChange = (payload) => {
	return (dispatch) => {
		dispatch({ type: EXPANDED_CHANGED, payload })
	}
}

export const handleResizedChange = (payload) => {
	return (dispatch) => {
		dispatch({ type: RESIZED_CHANGED, payload })
	}
}

export const handleFilteredChange = (payload) => {
	return (dispatch) => {
		dispatch({ type: FILTERED_CHANGED, payload })
		dispatch(fetchUserProfiles())
	}
}

export const setSelectedColumns = (selectedColumns) => ({
	type: SET_SELECTED_COLUMNS,
	payload: { selectedColumns },
})

export const handleToggleColumn = (payload) => ({
	type: TOGGLE_COLUMN,
	payload,
})

export const resetFilters = () => (dispatch) => {
	dispatch({ type: RESET_FILTERS })
	dispatch(fetchUserProfiles())
}

const getDefaultFilters = () => {
	const filterFields = ["search", "page"]
	return filterFields.reduce((result, key) => {
		result[key] = initialState[key]
		return result
	}, {})
}

const initialState = {
	fetched: {},
	search: "",
	sorted: [],
	page: 0,
	pageSize: 10,
	expanded: {},
	resized: [],
	filtered: [],

	selectedColumns: [
		COLUMNS.FirstName,
		COLUMNS.LastName,
		COLUMNS.EmployeeNumber,
		COLUMNS.Email,
		COLUMNS.Actions,
	],

	availableColumns: [
		COLUMNS.FirstName,
		COLUMNS.LastName,
		COLUMNS.EmployeeNumber,
		COLUMNS.Email,
		COLUMNS.Mobile,
		COLUMNS.TextEmail,
		COLUMNS.EffectiveDate,
		COLUMNS.EndDate,
		COLUMNS.LastLogin,
		COLUMNS.VerisaeUsername,
		COLUMNS.Actions,
	],
}

const userAdminModule = (state = initialState, action) => {
	switch (action.type) {
		case "persist/REHYDRATE":
			return {
				...state,
				...(action.payload || {}).userAdminModule,
			}
		case SET_SELECTED_COLUMNS:
			return { ...state, ...action.payload }
		case TOGGLE_COLUMN: {
			let newSelectedColumns =
				state.selectedColumns.includes(action.payload) ?
					state.selectedColumns.filter((x) => x !== action.payload)
				:	[...state.selectedColumns, action.payload]
			return {
				...state,
				selectedColumns: state.availableColumns.filter((x) =>
					newSelectedColumns.includes(x)
				),
			}
		}
		case FETCH_USER_PROFILES:
			return { ...state, fetched: action.payload }
		case SEARCH_CHANGED:
			return { ...state, search: action.payload.search, page: action.payload.page }
		case SORTED_CHANGED:
			return { ...state, sorted: action.payload }
		case PAGE_CHANGED:
			return { ...state, page: action.payload }
		case PAGE_SIZE_CHANGED:
			return { ...state, page: action.payload.page, pageSize: action.payload.pageSize }
		case EXPANDED_CHANGED:
			return { ...state, expanded: action.payload }
		case RESIZED_CHANGED:
			return { ...state, resized: action.payload }
		case FILTERED_CHANGED:
			return { ...state, filtered: action.payload }

		case RESET_FILTERS:
			return {
				...state,
				...getDefaultFilters(),
			}

		default:
			return state
	}
}
export default userAdminModule

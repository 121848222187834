import React from "react"
import { InputLabelProps } from "@material-ui/core"
import { Select } from "../../components"
import { MONTHS } from "../../util/constants"
import { CustomSelectProps } from "../CustomInput/CustomSelect"

export interface MonthSelectorProps {
	value: number
	onChange(newMonth: string): void
	displayEmpty?: boolean
	labelProps?: InputLabelProps
	selectProps?: Omit<CustomSelectProps, "onChange">
}

const MonthSelector: React.FC<MonthSelectorProps> = ({
	value,
	onChange,
	displayEmpty = true,
	labelProps,
	selectProps,
}) => {
	const handleChange = (newMonth: string) => {
		onChange(newMonth)
	}

	return (
		<Select
			labelText="Month"
			value={value || ""}
			onChange={handleChange}
			labelProps={{
				shrink: displayEmpty,
				...labelProps,
			}}
			selectProps={{
				displayEmpty,
				...selectProps,
			}}
			options={MONTHS.map((m, i) => ({
				value: i || "",
				text: m || "Select month",
				disabled: !m,
			}))}
		/>
	)
}

export default React.memo(MonthSelector)

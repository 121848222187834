import { ContractCoverageType } from "../lookups"

export enum ContractUrlPath {
	ContactHistory = "contact_history",
	Contracts = "contracts",
	CreditHistory = "credit_history",
	Credits = "credits",
	Customer = "customer",
	Document = "document",
	Entity = "entity",
	Incentives = "incentives",
	List = "list",
	Recipients = "recipients",
}

export interface ContractBusinessUnit {
	id: string
	name: string
}

export interface ContractType<Id extends string | number = string> {
	id: Id
	name: string
	description: string
}

export type ContractSearchResult = {
	id: number
	chemicalsCovered: boolean
	createdOn: string
	creator: string
	docusignId: string | null
	editor: string
	effectiveDate: string
	equipmentInstallCovered: boolean
	escalatorPercent: number | null
	escalatorMonths: number | null
	expirationDate: string
	hasChemicalSchedule: boolean
	hasPartsSchedule: boolean
	hasSelfServiceChemicalSchedule: boolean
	hasServiceSchedule: boolean
	maintenanceCovered: boolean
	maxPriceVarianceMonths: number | null
	maxPriceVariancePercent: number | null
	modifiedOn: string
	organization: string
	partsCovered: boolean
	types: ContractType<number>[]
	coverageType: ContractCoverageType<number> | null
} & {
	// The business units will be here too, with credits, discounts, etc. They'll
	// be accessed by looping through the results of the business units lookup.
	[key: string]: boolean
}

export interface Contract {
	id: string
	chemicalsCovered: boolean
	createdOn: string
	creator: string
	docusignId: string | null
	editor: string
	effectiveDate: string
	equipmentInstallCovered: boolean
	escalatorPercent: number | null
	escalatorMonths: number | null
	expirationDate: string
	hasChemicalSchedule: boolean
	hasPartsSchedule: boolean
	hasSelfServiceChemicalSchedule: boolean
	hasServiceSchedule: boolean
	maintenanceCovered: boolean
	maxPriceVarianceMonths: number | null
	maxPriceVariancePercent: number | null
	modifiedOn: string
	organization: string
	partsCovered: boolean
	types: ContractType<number>[]
	priceIncreases: ContractPriceIncreases[]
	coverageType: ContractCoverageType<number> | null
	specialException: string | null
}

export interface ContractQueryParams {
	contract: string | null
	search: string | null
	type: string | null // contract type ID
	coverageType: string | null // coverage type ID
}

export interface ContractPriceIncreases {
	id: number
	contractId: number
	executedOn: string
	percentIncreased: string
	scheduledOn: string
}

export interface ContractUnitCategory {
	id: string
	name: string
	type: "business_unit" | "other"
}

/**
 * For use when POSTing and user wants to create a new "other" category.
 */
export interface NewOtherContractUnitCategory {
	name: string
	type: "other"
}

export interface CreateContractPost {
	organization: string
	docusignId: string | null
	effectiveDate: string
	expirationDate: string
	partsCovered: boolean
	chemicalsCovered: boolean
	equipmentInstallCovered: boolean
	maintenanceCovered: boolean
	hasChemicalSchedule: boolean
	hasSelfServiceChemicalSchedule: boolean
	hasPartsSchedule: boolean
	hasServiceSchedule: boolean
	escalatorPercent: number | null
	escalatorMonths: number | null
	maxPriceVariancePercent: number | null
	maxPriceVarianceMonths: number | null
	rebates: {
		schedule: {
			start: number
			end: number | null
			percent: number
		}[]
		categories: (ContractUnitCategory | NewOtherContractUnitCategory)[]
	}[]
	credits: {
		amount: number
		isPerSite: boolean
		categories: (ContractUnitCategory | NewOtherContractUnitCategory)[]
	}[]
	discounts: {
		percent: number
		categories: (ContractUnitCategory | NewOtherContractUnitCategory)[]
	}[]
	documents: {
		description: string
		file: File
	}[]
	recipients: {
		description: string | null
		name?: string
		email?: string
		user?: string
	}[]
	types: string[]
	priceIncreases: {
		executedOn: string
		scheduledOn: string
		percentIncreased: number
	}[]
	coverageTypeId: string | null
	specialException: string | null
}

// After creating a contract, some of their properties will be updated through different endpoints.
export type UpdateContractPatch = Omit<
	Partial<CreateContractPost>,
	"rebates" | "credits" | "discounts" | "documents" | "recipients"
> & {
	contract: string
}

/** Groups together a contract's credits, discounts, and rebates into one object / endpoint. */
export interface ContractIncentives {
	rebates: {
		id: string
		schedule: {
			start: number
			end: number | null
			percent: number
		}[]
		categories: ContractUnitCategory[]
	}[]
	credits: {
		id: string
		amount: number
		isPerSite: boolean
		remaining: number | null
		categories: ContractUnitCategory[]
	}[]
	discounts: {
		id: string
		percent: number
		categories: ContractUnitCategory[]
	}[]
}

export interface ContractIncentivesQueryParams {
	contract: string
}

export type ContractRebate = ContractIncentives["rebates"][number]
export type ContractCredit = ContractIncentives["credits"][number]
export type ContractDiscount = ContractIncentives["discounts"][number]

export interface CreateContractIncentivesPost {
	contract: string
	rebates: CreateContractPost["rebates"]
	credits: CreateContractPost["credits"]
	discounts: CreateContractPost["discounts"]
}

export interface UpdateContractIncentivesPatch {
	contract: string
	rebates?: (Omit<ContractRebate, "categories"> & {
		categories: (ContractUnitCategory | NewOtherContractUnitCategory)[]
	})[]
	credits?: (Omit<ContractCredit, "remaining" | "categories"> & {
		categories: (ContractUnitCategory | NewOtherContractUnitCategory)[]
	})[]
	discounts?: (Omit<ContractDiscount, "categories"> & {
		categories: (ContractUnitCategory | NewOtherContractUnitCategory)[]
	})[]
}

/**
 * Provide the IDs of the incentives you want to remove from the contract.
 */
export interface DeleteContractIncentives {
	contract: string
	rebates: string[]
	credits: string[]
	discounts: string[]
}

export interface ContractDocument {
	id: number
	contractEntityId: number
	description: string
	bucket: string
	key: string
}

export interface CreateContractDocumentPost {
	contract: string
	description: string
	file: File
}

export interface ContractRecipient {
	id: string
	name: string | null
	email: string | null
	description: string | null
	user: {
		pk: string
		name: string
		email: string
	} | null
}

export interface ContractRecipientsQueryParams {
	contract: string
}

export interface CreateContractRecipientsPost {
	contract: string
	recipients: {
		description: string | null
		// Should either have name and email, or a user ID.
		name?: string
		email?: string
		user?: string
	}[]
}

export interface DeleteContractRecipient {
	contract: string
	recipient: string
}

// History of notifications sent out.
export interface SentContractNotification {
	id: string
	messageDescription: string
	dateSent: string
	recipients: {
		name: string
		email: string
	}[]
}

export interface SentContractNotificationQueryParams {
	contract: string
}

export interface SpentContractCredit {
	id: string
	amount: number
	creditId: number
	createdOn: string // The transaction date
	creator: string
	description: string | null
	partOrderId: string | null
	orderId: number | null // The part order's order ID
	invoiceId: string | null
	invoiceNumber: string | null
}

export interface SpentContractCreditQueryParams {
	credit: string
}

export interface CreateSpentCreditPost {
	credit: string
	transactionDate: string
	amount: number
	description: string | null
	partOrderId?: string | null
	invoiceId?: string | null
}

export interface DeleteSpentCredit {
	history: string
}

export interface CustomerCredit {
	id: string
	createdOn: string
	createdDate: string
	amount: number
	expiresOn: string // Heads up, no timezone!!
	comments: string | null
	businessUnit: {
		id: string | null
		name: string | null
	}
	otherUnit: {
		id: string | null
		name: string | null
	}
	history: SpentContractCredit[]
	relatedSiteCount: number
}

export interface CreateCustomerCreditPost {
	customerId: string
	amount: number
	expiresOn: string
	comments: string | null
	isPerSite: boolean
	businessUnit: string | null // ID of existing unit.
	otherUnitName: string | null // Name of new unit to make.
}

export type ExpireCustomerCreditPatch = {
	customerId: string | null
	businessUnitId: string | null
	otherUnitId: string | null
	isPerSite: boolean | null
}

import { FC, useCallback, useMemo, useState } from "react"

import dayjs, { Dayjs } from "dayjs"
import { Cell, Column } from "react-table"

import {
	LaborPricingDetails,
	LaborPricingDetailsQueryParams,
	LaborRateTypeId,
	makeApiErrorMessage,
	useAddLaborPricingCustomers,
	useDeleteLaborPricingCustomer,
} from "@ncs/ncs-api"
import { DateFormat, displayDate, isEnumMember } from "@ncs/ts-utils"
import {
	Box,
	Button,
	Callout,
	ConfirmationModal,
	ConfirmationModalConfig,
	DateInput,
	encodeUrlState,
	getAddressFields,
	Paragraph,
	SelectBoolean,
	SelectCustomerGroupModal,
	SelectCustomerGroupModalPayload,
	SetUrlState,
	Table,
	TableProps,
	ThrottledTextInput,
	useToast,
} from "@ncs/web-legos"

import { laborRateTypeDescriptions } from "~/components"
import {
	CustomerDetailTabName,
	CustomerDetailUrlState,
} from "~/views/Customer/CustomerManagement/CustomerDetail/CustomerDetail"

import { EditLaborMembershipsModal } from "./EditLaborMembershipsModal"

export interface LaborPricingCustomersProps {
	pricing: LaborPricingDetails
	queryParams: LaborPricingDetailsQueryParams
	setQueryParams: SetUrlState<LaborPricingDetailsQueryParams>
}

type PricingCustomer = LaborPricingDetails["customers"][number]

export const LaborPricingCustomers: FC<LaborPricingCustomersProps> = ({
	pricing,
	queryParams,
	setQueryParams,
}) => {
	const { makeSuccessToast, makeErrorToast } = useToast()
	const [showAddCustomer, setShowAddCustomer] = useState(false)
	const [newCustomersStartDate, setNewCustomersStartDate] = useState<Dayjs | null>(() =>
		dayjs().startOf("day").add(1, "day")
	)
	const [newCustomersEndDate, setNewCustomersEndDate] = useState<Dayjs | null>(null)
	const [membershipsToEdit, setMembershipsToEdit] = useState<string[]>([])
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)
	const [search, setSearch] = useState<string | null>(null)

	const addCustomers = useAddLaborPricingCustomers(pricing.id)
	const deleteCustomer = useDeleteLaborPricingCustomer(pricing.id)

	const handleAddCustomers = async (data: SelectCustomerGroupModalPayload) => {
		if (!newCustomersStartDate) {
			throw new Error("Please choose when this assignment should begin")
		}
		if (
			!!newCustomersEndDate &&
			(dayjs(newCustomersEndDate).isBefore(newCustomersStartDate) ||
				dayjs(newCustomersEndDate).isSame(newCustomersStartDate, "day"))
		) {
			throw new Error("End date must be after start date")
		}

		await addCustomers({
			...data,
			membershipStartsOn: newCustomersStartDate.format(DateFormat.DateQueryParam),
			membershipEndsAfter:
				newCustomersEndDate ? newCustomersEndDate.format(DateFormat.DateQueryParam) : null,
		})
		makeSuccessToast("Customer list updated")
	}

	const handleDelete = useCallback(
		async (membershipId: string) => {
			setConfirmationConfig({
				title: "Delete Membership",
				message: "Confirm: Delete this membership?",
				onConfirm: async () => {
					try {
						await deleteCustomer(membershipId)
						makeSuccessToast("Membership deleted")
					} catch (e) {
						makeErrorToast(makeApiErrorMessage(e))
					}
				},
			})
		},
		[deleteCustomer, makeErrorToast, makeSuccessToast]
	)

	const customers = useMemo(() => {
		const chunks = search?.trim().toUpperCase().split(" ")

		return pricing.customers.filter((c) => {
			if (!search) return true

			const searchable = Object.values(c).join("").toUpperCase()

			return chunks?.every((chunk) => searchable.includes(chunk))
		})
	}, [pricing.customers, search])

	const bulkActionButtons = useMemo((): TableProps<PricingCustomer>["bulkActionButtons"] => {
		return [
			{
				buttonText: "Bulk update end date",
				icon: "pencil",
				onClick: ({ selectedRows }) => {
					setMembershipsToEdit(selectedRows.map(({ original }) => original.membershipId))
				},
			},
		]
	}, [])

	const rowMenu = useMemo((): TableProps<PricingCustomer>["rowMenu"] => {
		return [
			{
				label: "Change end date",
				iconName: "pencil",
				hiddenAccessor: ({ original }) => {
					// Can only edit an end date if it's in the future.
					return !dayjs(original.membershipEndsAfter).isAfter(dayjs())
				},
				onClick: ({ original }) => {
					setMembershipsToEdit([original.membershipId])
				},
			},
			{
				label: "Delete",
				iconName: "trash-alt",
				hiddenAccessor: ({ original }) => {
					// Can't delete one that's already started.
					return !dayjs(original.membershipStartsOn).isAfter(dayjs())
				},
				onClick: ({ original }) => handleDelete(original.membershipId),
			},
			{
				label: "View customer management",
				iconName: "external-link",
				onClick: ({ original }) => {
					window.open(
						`/customer-management/${
							original.customerId
						}${encodeUrlState<CustomerDetailUrlState>({
							tab: CustomerDetailTabName.Sales,
						})}`
					)
				},
			},
		]
	}, [handleDelete])

	const laborType = useMemo(() => {
		if (isEnumMember(pricing.type, LaborRateTypeId)) {
			return laborRateTypeDescriptions[pricing.type].toLowerCase()
		}

		return null
	}, [pricing.type])

	if (pricing.territory && pricing.zone === 1) {
		return (
			<Callout variant="info" icon="check" mt={2}>
				<Paragraph>
					This is the default for all customers in territory ({pricing.territory.code}){" "}
					{pricing.territory.description} that do not have another {laborType} labor
					price active and assigned to them.
				</Paragraph>
			</Callout>
		)
	}

	return (
		<>
			<Box d="flex" justifyContent="space-between" alignItems="flex-end">
				<Button
					icon="plus"
					variant="secondary-cta"
					onClick={() => setShowAddCustomer(true)}
					containerProps={{ mt: 2, mb: 2 }}
				>
					Add customer
				</Button>
				<Box d="flex" gap={1}>
					<ThrottledTextInput
						value={search}
						onChange={setSearch}
						label="Search"
						icon="search"
						clearable
						fillContainer={false}
						leading={false}
					/>
					<SelectBoolean
						value={queryParams.includeInactiveCustomers}
						onChange={(newValue) =>
							setQueryParams((prev) => ({
								...prev,
								includeInactiveCustomers: newValue,
							}))
						}
						label="Show inactive memberships?"
						showNoSelectionOption={false}
						noFirst
					/>
				</Box>
			</Box>

			<Table
				data={customers}
				columns={columns}
				noDataText="No customer assignments found with current filters"
				bulkActionButtons={bulkActionButtons}
				rowMenu={rowMenu}
			/>

			{showAddCustomer && (
				<SelectCustomerGroupModal
					onSave={handleAddCustomers}
					onClose={() => {
						setShowAddCustomer(false)
						setNewCustomersStartDate(dayjs().startOf("day").add(1, "day"))
						setNewCustomersEndDate(null)
					}}
					saveButtonText="Add Customers"
					contentBeneath={
						<Box d="flex" columnGap={1} mt={1}>
							<Paragraph mb={1}></Paragraph>
							<DateInput
								value={newCustomersStartDate}
								onChange={setNewCustomersStartDate}
								label="Membership starts on"
								disablePast
							/>
							<DateInput
								value={newCustomersEndDate}
								onChange={setNewCustomersEndDate}
								label="Membership ends after (optional)"
								disablePast
								clearable
							/>
						</Box>
					}
				/>
			)}
			{!!membershipsToEdit.length && (
				<EditLaborMembershipsModal
					pricing={pricing}
					memberships={membershipsToEdit}
					onClose={() => setMembershipsToEdit([])}
				/>
			)}
			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</>
	)
}

const columns: Column<PricingCustomer>[] = [
	{
		Header: "Customer #",
		noWrap: true,
		disableSortBy: true,
		accessor: ({ customerNumber }) => customerNumber,
	},
	{
		Header: "Name",
		accessor: ({ name }) => name,
	},
	{
		Header: "Address",
		disableSortBy: true,
		accessor: (original) => getAddressFields(original, { exclude: "name" }).join(", "),
	},
	{
		Header: "Membership start date",
		accessor: (original) => original.membershipStartsOn,
		Cell: ({ row: { original } }: Cell<PricingCustomer>) =>
			displayDate(original.membershipStartsOn, "-", { formatInUtc: true }),
	},
	{
		Header: "Membership end date",
		accessor: (original) => original.membershipEndsAfter,
		Cell: ({ row: { original } }: Cell<PricingCustomer>) =>
			displayDate(original.membershipEndsAfter, "-", { formatInUtc: true }),
	},
]

import { FC, useState } from "react"

import {
	InventoryPart,
	makeApiErrorMessage,
	useCreatePartCrossReference,
	useDeletePartCrossReference,
	usePartCrossReference,
	useUpdatePartCrossReference,
} from "@ncs/ncs-api"
import {
	ConfirmationModal,
	ConfirmationModalConfig,
	Divider,
	ExtendableModalProps,
	GridContainer,
	GridItem,
	LabeledData,
	Modal,
	PartSelector,
	TextInput,
	useChangeCallback,
	useIsSaving,
	useToast,
} from "@ncs/web-legos"

export interface EditCrossReferenceProps extends ExtendableModalProps {
	crossReferenceId: string | null
}

export const EditCrossReference: FC<EditCrossReferenceProps> = ({ crossReferenceId, ...rest }) => {
	const { makeSuccessToast } = useToast()
	const { isSaving, setSaving, endSaving } = useIsSaving()
	const [errorText, setErrorText] = useState<string | null>(null)
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)

	const [part, setPart] = useState<InventoryPart | null>(null)
	const [alternateNumber, setAlternateNumber] = useState<string | null>(null)
	const [alternateDescription, setAlternateDescription] = useState<string | null>(null)

	const [reference, referenceLoading] = usePartCrossReference(crossReferenceId)

	// Set state when reference to edit loads.
	useChangeCallback(
		reference,
		(loadedReference) => {
			setAlternateNumber(loadedReference?.partNumber ?? null)
			setAlternateDescription(loadedReference?.description ?? null)
		},
		{ callOnSetup: true }
	)

	const updateReference = useUpdatePartCrossReference()
	const createReference = useCreatePartCrossReference()
	const deleteReference = useDeletePartCrossReference()

	const handleSave = async () => {
		if (!alternateDescription || !alternateNumber) {
			setErrorText("Alternate number and alternate description are required")
			return
		}

		try {
			setSaving()

			if (isEdit && reference) {
				await updateReference({
					id: crossReferenceId,
					updates: {
						partId: reference.part.id,
						description: alternateDescription,
						partNumber: alternateNumber,
					},
				})
				makeSuccessToast("Cross reference updated")
			} else {
				if (!part) {
					setErrorText("Please choose NCS part to reference")
					return
				}

				await createReference({
					partId: part.id,
					description: alternateDescription,
					partNumber: alternateNumber,
				})
				makeSuccessToast("Cross reference created")
			}
			rest.onClose()
		} catch (e) {
			endSaving()
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const handleDelete = () => {
		if (isEdit) {
			setConfirmationConfig({
				title: "Delete Cross Reference",
				message: "Confirm: Delete this part cross reference?",
				onConfirm: async () => {
					await deleteReference(crossReferenceId)
					makeSuccessToast("Reference deleted")
					rest.onClose()
				},
			})
		}
	}

	const isEdit = !!crossReferenceId

	return (
		<Modal
			{...rest}
			title={`${isEdit ? "Edit" : "New"} Part Cross Reference`}
			rightButtons={{
				buttonText: isEdit ? "Save changes" : "Create",
				onClick: handleSave,
				disabled: referenceLoading || isSaving(),
				isLoading: isSaving(),
			}}
			leftButtons={
				isEdit ?
					{
						buttonText: "Delete",
						icon: "trash-alt",
						variant: "text",
						onClick: handleDelete,
					}
				:	undefined
			}
			errorText={errorText}
		>
			{isEdit ?
				<LabeledData label="NCS part" isLoading={referenceLoading}>
					({reference?.part.partNumber}) {reference?.part.description}
				</LabeledData>
			:	<PartSelector value={part} onChange={setPart} label="NCS part" mb={0} />}
			<Divider mt={1.5} mb={1} />
			<GridContainer>
				<GridItem xs={12} sm={6}>
					<TextInput
						value={alternateNumber}
						onChange={setAlternateNumber}
						label="Alternate part #"
					/>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<TextInput
						value={alternateDescription}
						onChange={setAlternateDescription}
						label="Alternate description"
					/>
				</GridItem>
			</GridContainer>

			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</Modal>
	)
}

import { FC } from "react"

import { ExtendableSelectProps, Select } from "../inputs"

export interface PosPartActiveSelectorProps
	extends ExtendableSelectProps<(typeof options)[number]> {}

const options = [
	{
		text: "Active (Default catalog)",
		value: "active",
	},
	{
		text: "Inactive (opt-in only)",
		value: "inactive",
	},
]

export const PosPartActiveSelector: FC<PosPartActiveSelectorProps> = ({ ...rest }) => {
	return <Select label="Active products" fillContainer {...rest} options={options} />
}

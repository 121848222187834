import React from "react"
import PropTypes from "prop-types"
import {
	CircularProgress,
	CircularProgressProps,
	createStyles,
	makeStyles,
} from "@material-ui/core"

interface StyleProps {
	size: number
}

const useStyles = makeStyles(
	createStyles({
		marginKiller: {
			"& svg": {
				margin: "0 !important",
				height: ({ size }: StyleProps) => `${size}px !important`,
				width: ({ size }: StyleProps) => `${size}px !important`,
			},
		},
	})
)

interface Props extends CircularProgressProps {
	size?: number
	className?: string
}

const CircularProgressIndeterminate: React.FC<Props> = ({ size = 100, className, ...rest }) => {
	const classes = useStyles({ size })

	return (
		<div className={`${classes.marginKiller} ${className ? className : ""}`}>
			<CircularProgress size={size} color="primary" {...rest} />
		</div>
	)
}

CircularProgressIndeterminate.propTypes = {
	size: PropTypes.number,
	className: PropTypes.string,
}

export default CircularProgressIndeterminate

import { Dispatch, FC, memo, SetStateAction, useMemo } from "react"

import { css } from "@emotion/react"

import { extractNumber, formatNumber, noNullish } from "@ncs/ts-utils"
import { Box, EmptyValueDash, Icon, Paragraph } from "@ncs/web-legos"

import {
	binQtyRowHeight,
	LineFormState,
	LinePrintingState,
	ReceivingLine,
} from "./receive-parts-modal-util"
import { ReceivePartQuantityWarning } from "./ReceivePartQuantityWarning"
import { ReceivePartsBinList } from "./ReceivePartsBinList"
import { ReceivePartsQtyList } from "./ReceivePartsQtyList"

export interface ReceivePartsTableTrProps {
	receivingLine: ReceivingLine
	lineState: LineFormState[string]
	setLineFormState: Dispatch<SetStateAction<LineFormState>>
	destinationId: string
	locationId: string | null
	showDemands: boolean
	showPrintColumn: boolean
	linePrintingState?: LinePrintingState
	setLinePrintingState?: Dispatch<SetStateAction<LinePrintingState>>
	isSummaryRow?: boolean
	lastDestinationRow?: boolean
}

export const ReceivePartsTableTr: FC<ReceivePartsTableTrProps> = memo(
	({
		receivingLine,
		lineState,
		setLineFormState,
		destinationId,
		locationId,
		showDemands,
		showPrintColumn,
		linePrintingState,
		setLinePrintingState,
		isSummaryRow = false,
		lastDestinationRow = false,
	}) => {
		const rowClassName = useMemo(() => {
			return noNullish([
				isSummaryRow ? "line-item-summary-row" : "destination-row",
				lastDestinationRow ? "last-destination-row" : null,
			]).join(" ")
		}, [isSummaryRow, lastDestinationRow])

		const showQuantityWarning = useMemo(() => {
			const inputTotal = Object.values(lineState.destinations).reduce(
				(lineTotal, destination) => {
					return (
						lineTotal +
						Object.values(destination.binQuantities).reduce(
							(binQtyTotal, { quantity }) => binQtyTotal + quantity,
							0
						)
					)
				},
				0
			)

			return inputTotal > receivingLine.orderedQuantity
		}, [lineState.destinations, receivingLine.orderedQuantity])

		const destination = lineState.destinations[destinationId]

		if (!destination) {
			console.error("Bad destination ID")

			return null
		}

		return (
			<tr className={rowClassName}>
				{showPrintColumn && !isSummaryRow && <td className="print-quantity" />}
				{showPrintColumn &&
					isSummaryRow &&
					!!linePrintingState &&
					!!setLinePrintingState && (
						<td className="print-quantity">
							<Box height={binQtyRowHeight}>
								<input
									value={
										linePrintingState[receivingLine.lineItemId]?.quantity ?? ""
									}
									onChange={(e) => {
										setLinePrintingState((prev) => ({
											...prev,
											[receivingLine.lineItemId]: {
												...prev[receivingLine.lineItemId],
												quantity:
													e.target.value === "" ?
														null
													:	extractNumber(e.target.value),
											},
										}))
									}}
									placeholder="Qty..."
								/>
							</Box>
						</td>
					)}
				<td className="line-item">
					{isSummaryRow ?
						<Paragraph pr={1} breakAnywhere>
							{receivingLine.part.partNumber}: {receivingLine.part.description}
						</Paragraph>
					:	<Box display="flex" alignItems="center" columnGap={1} pl={1.5} pt={0.5}>
							<Icon icon="level-up" color="light-gray" css={destinationIconCss} />
							{destination.partOrder ?
								`Order #${destination.partOrder.orderId}: ${destination.partOrder.shipToCustomer.name}`
							:	"Stock"}
						</Box>
					}
				</td>
				<td className="ordered-qty">
					{isSummaryRow ?
						<Paragraph>{formatNumber(receivingLine.orderedQuantity)}</Paragraph>
					: destination.quantityNeeded ?
						<Box pt={0.5}>{formatNumber(destination.quantityNeeded)}</Box>
					:	<Box pt={0.5}>
							<EmptyValueDash />
						</Box>
					}
				</td>
				<td className="already-received">
					{isSummaryRow ?
						<Paragraph>{formatNumber(receivingLine.alreadyReceived)}</Paragraph>
					:	<Box pt={0.5}>
							<EmptyValueDash />
						</Box>
					}
				</td>
				<td className="receive-quantity">
					<Box display="flex" gap={0.5}>
						{((isSummaryRow && !showDemands) || !isSummaryRow) && (
							<ReceivePartsQtyList
								lineItemId={receivingLine.lineItemId}
								destinationId={destinationId}
								binQuantities={destination.binQuantities}
								setLineFormState={setLineFormState}
								showWarning={showQuantityWarning}
							/>
						)}
						{isSummaryRow && (
							<Box mt={!showDemands ? 0.5 : undefined}>
								<ReceivePartQuantityWarning showWarning={showQuantityWarning} />
							</Box>
						)}
					</Box>
				</td>
				<td className="bin">
					{((isSummaryRow && !showDemands) || !isSummaryRow) && (
						<ReceivePartsBinList
							lineItemId={receivingLine.lineItemId}
							destinationId={destinationId}
							binQuantities={destination.binQuantities}
							setLineFormState={setLineFormState}
							locationId={locationId}
						/>
					)}
				</td>
			</tr>
		)
	}
)

ReceivePartsTableTr.displayName = "ReceivePartsTableTr"

const destinationIconCss = css`
	transform: rotate(90deg);
`

import { FC, useMemo } from "react"

import { Container, useContainers } from "@ncs/ncs-api"

import { ExtendableSelectProps, Select } from "../inputs"

export interface ContainerSelectorProps extends ExtendableSelectProps<Container> {
	addNewOption?: boolean
}

export const ContainerSelector: FC<ContainerSelectorProps> = ({
	addNewOption = false,
	...rest
}) => {
	const [containers, loading] = useContainers()

	const options = useMemo(() => {
		const result = [...(containers ?? [])].sort((a, b) => (a.name > b.name ? 1 : -1))

		if (addNewOption) {
			result.push({
				id: NewContainerOptionId,
				name: "Create new container type",
				fillableHeight: "",
				mlPerMm: "",
				gallons: 0,
			})
		}

		return result
	}, [containers, addNewOption])

	return (
		<Select
			label="Container"
			disabled={loading}
			{...rest}
			options={options}
			valueAccessor="id"
			textAccessor="name"
		/>
	)
}

export const NewContainerOptionId = "new"

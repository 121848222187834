import React from "react"
import PropTypes from "prop-types"
import { SearchSelector, withCallApi } from "@ncs/bricks/components"
import { searchUsers } from "../../redux/services/users"

class UserSelector extends React.Component {
	handleGetSuggestions = (search) => {
		// note: SearchSelector handles debouncing for us
		return this.props.callApi(
			searchUsers({
				search,
				fields: "_id,name",
				pageSize: 15,
			})
		)
	}

	handleGetSuggestionValue = (user) => {
		return user.name
	}

	handleSuggestionSelected = (event, { suggestion }) => {
		this.props.onUserSelected(suggestion)
	}

	render = () => {
		const { labelText, classes, ...rest } = this.props

		return (
			<SearchSelector
				label={labelText}
				getSuggestions={this.props.onSearch || this.handleGetSuggestions}
				getSuggestionValue={this.props.onGetValue || this.handleGetSuggestionValue}
				onSuggestionSelected={this.handleSuggestionSelected}
				{...rest}
			/>
		)
	}
}

UserSelector.defaultProps = {
	labelText: "User",
	placeholder: "Search for a user",
}

UserSelector.propTypes = {
	callApi: PropTypes.func.isRequired,
	onUserSelected: PropTypes.func.isRequired,
	labelText: PropTypes.string,
	placeholder: PropTypes.string,
	onSearch: PropTypes.func,
	onGetValue: PropTypes.func,

	/** preselected user */
	defaultValue: PropTypes.shape({
		_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		name: PropTypes.string,
	}),
}

export default withCallApi(UserSelector)

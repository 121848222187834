import { get, rsaaTypes, RSAA } from "@ncs/bricks/middleware/portalApiMiddleware"
import moment from "moment"

export const SERVICE_REQUESTS_DASHBOARD = rsaaTypes("service_requests/dashboard")
const TERRITORY_CHANGED = "service_requests/territory_changed"

export const getServiceRequestsDashboard = () => {
	return (dispatch, getState) => {
		let selectedTerritory = getState().serviceRequests.dashboard.selectedTerritory
		let query = selectedTerritory ? { territory: selectedTerritory } : null

		dispatch({
			[RSAA]: {
				api: get("/service_requests/dashboard/", query),
				types: SERVICE_REQUESTS_DASHBOARD,
			},
		})
	}
}

export const handleTerritoryChange = (selectedTerritory) => {
	return (dispatch) => {
		// eat fake "all" territory that UI creates
		selectedTerritory = selectedTerritory === "all" ? null : selectedTerritory

		dispatch({ type: TERRITORY_CHANGED, payload: selectedTerritory })
		dispatch(getServiceRequestsDashboard())
	}
}

const initialState = {
	dashboard: {
		isLoading: true,
		isInitialState: true,
		lastRefreshed: null,
		dispatches_open: null,
		dispatches_open_updated: null,
		dispatches_new_today: null,
		dispatches_new_today_updated: null,
		dispatches_closed_today: null,
		dispatches_closed_today_updated: null,
		dispatches_open_on_contract: null,
		dispatches_open_on_contract_updated: null,
		invoices_pending_field_approval: null,
		invoices_pending_field_approval_updated: null,
		invoices_pending_second_approval: null,
		invoices_pending_second_approval_updated: null,
		invoices_rejected: null,
		invoices_rejected_updated: null,
		invoices_additional_entry: null,
		invoices_additional_entry_updated: null,
		dispatch_history: { labels: [], series: [[]] },
		dispatch_history_updated: null,
		invoice_history: { labels: [], series: [[]] },
		invoice_history_updated: null,
		selectedTerritory: null,
	},
}

const serviceRequests = (state = initialState, action) => {
	switch (action.type) {
		case SERVICE_REQUESTS_DASHBOARD.request:
			return {
				...state,
				dashboard: {
					...state.dashboard,
					isLoading: true,
				},
			}
		case SERVICE_REQUESTS_DASHBOARD.failure:
			return {
				...state,
				dashboard: {
					...state.dashboard,
					isLoading: false,
					isInitialState: false,
					error: action.payload,
				},
			}
		case SERVICE_REQUESTS_DASHBOARD.success:
			const sortedDispatchHistory = Object.keys(action.payload.dispatch_history)
				.sort()
				.map((x) => {
					return { ...action.payload.dispatch_history[x], date: moment(x) }
				})
			let dispatchHistory = {
				labels: sortedDispatchHistory.map((x) => x.date.format("D")),
				series: [
					{
						name: "New",
						data: sortedDispatchHistory.map((x) => x.created),
						className: "dispatch-history-new-today",
					},
					{
						name: "Closed",
						data: sortedDispatchHistory.map((x) => x.closed),
						className: "dispatch-history-closed-today",
					},
				],
			}

			const sortedInvoiceHistory = Object.keys(action.payload.invoice_history)
				.sort()
				.map((x) => {
					return { ...action.payload.invoice_history[x], date: moment(x) }
				})
			let invoiceHistory = {
				labels: sortedInvoiceHistory.map((x) => x.date.format("D")),
				series: [
					{
						name: "Field Approval",
						data: sortedInvoiceHistory.map((x) => x["Pending Field Approval"]),
						className: "invoice-history-field-approval",
					},
					{
						name: "Second Approval",
						data: sortedInvoiceHistory.map((x) => x["Pending Second Approval"]),
						className: "invoice-history-second-approval",
					},
					{
						name: "Rejected",
						data: sortedInvoiceHistory.map((x) => x["Rejected"]),
						className: "invoice-history-rejected",
					},
					{
						name: "Additional Entry",
						data: sortedInvoiceHistory.map((x) => x["Additional Entry"]),
						className: "invoice-history-additional-entry",
					},
				],
			}

			return {
				...state,
				dashboard: {
					...state.dashboard,
					...action.payload,
					dispatch_history: dispatchHistory,
					invoice_history: invoiceHistory,
					isLoading: false,
					isInitialState: false,
					lastRefreshed: Date.now(),
				},
			}
		case TERRITORY_CHANGED:
			return {
				...state,
				dashboard: {
					...state.dashboard,
					selectedTerritory: action.payload,
					isInitialState: state.dashboard.selectedTerritory !== action.payload,
				},
			}
		default:
			return state
	}
}
export default serviceRequests

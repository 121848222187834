import React, { Component } from "react"
import FormValidator from "@ncs/bricks/util/formValidator"
import callApi from "@ncs/bricks/redux/services/callApi"
import { patchCustomer } from "@ncs/mortar/redux/services/customers"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

//local components
import TechAssignmentChange from "./TechAssignmentChange"

class ChemicalTechAssignmentChangeContainer extends Component {
	constructor(props) {
		super(props)
		this.validations = {
			description: {
				stateName: "description",
				isRequired: true,
				maxLength: 255,
			},
		}
		this.formValidator = new FormValidator(this, this.validations)

		this.state = { show: false, ...this.formValidator.getInitialFormState() }
	}

	handleCreateAssignment = () => {
		this.setState({ show: true })
	}
	handleCancelCreateAssignment = () => {
		this.setState({ show: false })
	}
	handleTechAssSelected = (selectedTechAssignment) => {
		this.setState({ selectedTechAssignment })
	}

	handleSetInputRef = (input) => {
		this.input = input
	}
	handleConfirmTechAssignmentAdd = () => {
		if (!this.state.selectedTechAssignment) {
			return
		}

		this.setState({ isSaving: true })
		this.props
			.callApi(
				patchCustomer(this.props.customer, {
					default_chem_tech_association_id: this.state.selectedTechAssignment,
				})
			)
			.then((result) => {
				this.setState({ isSaving: false, show: false })
				return this.props.onSave()
			})
	}
	render() {
		return (
			<TechAssignmentChange
				onTechAssSelected={this.handleTechAssSelected}
				onStartAssignmentAdd={this.handleCreateAssignment}
				onCancelAssignmentAdd={this.handleCancelCreateAssignment}
				onConfirmAssignmentAdd={this.handleConfirmTechAssignmentAdd}
				show={this.state.show}
				setInputRef={this.handleSetInputRef}
				territory={this.props.territory}
				selectedTechAssignment={this.state.selectedTechAssignment}
				chemical={true}
				{...this.formValidator.generateFormProps()}
			/>
		)
	}
}
const mapStateToProps = (state) => {
	return {}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			callApi,
		},
		dispatch
	)

export default connect(mapStateToProps, mapDispatchToProps)(ChemicalTechAssignmentChangeContainer)

import { FC, useState } from "react"

import { useFormContext, useWatch } from "react-hook-form"
import { Column } from "react-table"

import { Dispatch, useCustomer, useDispatches } from "@ncs/ncs-api"
import {
	DateFormat,
	formatDate,
	getTimeAgoStartDate,
	getTimezoneAbbreviation,
	TimeAgo,
} from "@ncs/ts-utils"
import {
	Box,
	Callout,
	EmptyValueDash,
	Heading,
	Icon,
	infoCalloutBackgroundColor,
	Paragraph,
	Table,
	TimeAgoSelector,
} from "@ncs/web-legos"

import { CreateDispatchForm } from "../../util"
import { DispatchPreviewModal } from "./components"

export const RecentDispatches: FC = () => {
	const { control } = useFormContext<CreateDispatchForm>()
	const [customerId] = useWatch({
		control,
		name: ["customerId"],
	})
	const [timeAgo, setTimeAgo] = useState<TimeAgo | null>(TimeAgo.MonthsAgo3)
	const [modalDispatch, setModalDispatch] = useState<Dispatch | null>(null)

	const [customerDetails] = useCustomer(customerId)
	const query = useDispatches({
		pageSize: 20,
		params: {
			callReceivedGte: getTimeAgoStartDate(timeAgo)?.format(DateFormat.DateQueryParam),
			customer: customerId,
		},
	})

	return (
		<Callout variant="info" fillContainer>
			<Box d="flex" justifyContent="space-between" mb={1}>
				<Heading variant="h5">Recent Dispatches</Heading>
				{!!customerDetails && (
					<Box d="flex" gap={0.5}>
						<Paragraph small secondary mt={-0.15}>
							for ({customerDetails.customerNumber}) {customerDetails.name}
						</Paragraph>
						<Icon icon="user" />
					</Box>
				)}
			</Box>
			<TimeAgoSelector value={timeAgo} onChange={setTimeAgo} label={null} />
			<Table
				query={query}
				columns={columns}
				headerBackgroundColor={infoCalloutBackgroundColor}
				disableAllSorting
				onRowClick={({ original }) => setModalDispatch(original)}
				noDataText={
					customerId ?
						"No recent dispatches found for this customer"
					:	"No dispatches found"
				}
			/>

			{!!modalDispatch && (
				<DispatchPreviewModal
					dispatch={modalDispatch}
					onClose={() => setModalDispatch(null)}
				/>
			)}
		</Callout>
	)
}

const columns: Column<Dispatch>[] = [
	{
		Header: `Created (${getTimezoneAbbreviation()})`,
		accessor: ({ createdDate }) =>
			createdDate ? formatDate(createdDate) : <EmptyValueDash />,
	},
	{
		Header: "Status",
		accessor: ({ status }) => status || <EmptyValueDash />,
	},
	{
		Header: "Customer",
		accessor: ({ customer }) => `(${customer.customerNumber}) ${customer.name}`,
	},
]

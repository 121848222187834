import { FC, memo, useEffect } from "react"

import dayjs from "dayjs"
import { Controller, useFormContext, useWatch } from "react-hook-form"

import { CustomerDetail, useAuth } from "@ncs/ncs-api"
import { addBusinessDays } from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	Box,
	chemicalShipmentOptions,
	CustomerSelectorFormField,
	DateInputFormField,
	GridContainer,
	GridItem,
	Heading,
	HeadingDivider,
	OrderShipMethodSelector,
	Paragraph,
	retailSaleShipMethodId,
	ShippingMethod,
	UserSelectorFormField,
} from "@ncs/web-legos"

import { CreatePartOrderForm } from "../../../part-orders-util"

export interface CreatePartOrderShippingProps {
	shipToCustomer: CustomerDetail | undefined
	orderHasChm: boolean
}

export const CreatePartOrderShipping: FC<CreatePartOrderShippingProps> = memo(
	({ shipToCustomer, orderHasChm }) => {
		const { user } = useAuth()

		const { control, setValue } = useFormContext<CreatePartOrderForm>()

		const [shipmentMethodId, isHold, targetDeliveryDate] = useWatch({
			name: ["shipmentMethodId", "isHold", "targetDeliveryDate"],
			control,
		})

		useEffect(() => {
			if (
				targetDeliveryDate &&
				dayjs().add(2, "weeks").endOf("day").isBefore(targetDeliveryDate)
			) {
				setValue("isHold", true, { shouldValidate: true })
			} else {
				setValue("isHold", false, { shouldValidate: true })
			}
		}, [targetDeliveryDate, setValue])

		// Reset shipment method if you had it set to something non-chemable, then add a CHM part.
		useEffect(() => {
			if (
				orderHasChm &&
				shipmentMethodId !== "" &&
				chemicalShipmentOptions.includes(shipmentMethodId as ShippingMethod) === false
			) {
				setValue("shipmentMethodId", "")
			}
		}, [orderHasChm, setValue, shipmentMethodId])

		return (
			<>
				<HeadingDivider headingVariant="h3" mt={3}>
					3. Shipping
				</HeadingDivider>

				<Box pl={1.5}>
					<GridContainer mb={1} gap={1}>
						<GridItem xs={12} sm={6}>
							<DateInputFormField
								control={control}
								name="targetDeliveryDate"
								label="Target shipping date (minimum 3 business days)"
								shouldDisableDate={(date) => {
									return addBusinessDays(2).isAfter(date)
								}}
							/>
						</GridItem>
						{isHold && (
							<>
								<GridItem xs={12} sm={6} pt={1}>
									<Paragraph>
										Because target date is more than 2 weeks out, this order
										will start in the <strong>ON HOLD</strong> status. Choose
										the person that can remove the hold on the order (defaults
										to you).
									</Paragraph>
								</GridItem>
								<GridItem xs={12} sm={6}>
									<AnimatedEntrance show>
										<UserSelectorFormField
											control={control}
											name="holder"
											initialId={user?.id}
											employeesOnly
											fillContainer
											label="Who can remove the hold on the order?"
										/>
									</AnimatedEntrance>
								</GridItem>
							</>
						)}
					</GridContainer>
				</Box>

				<Box pl={1.5}>
					<GridContainer mb={2}>
						<GridItem xs={12} sm={6}>
							<Controller
								control={control}
								name="shipmentMethodId"
								render={({
									field: { onBlur, value, onChange },
									fieldState: { error },
								}) => (
									<OrderShipMethodSelector
										value={value}
										onChange={onChange}
										onBlur={onBlur}
										error={error?.message}
										restrictToChem={orderHasChm}
										includeRetailSale
										fillContainer
									/>
								)}
							/>
						</GridItem>
						<GridItem xs={12} sm={6}>
							<AnimatedEntrance
								show={shipmentMethodId === retailSaleShipMethodId}
								mt={-0.55}
							>
								<Heading mb={0.6} bold variant="h5" icon="dollar-sign">
									Alternate Bill To
								</Heading>
								<CustomerSelectorFormField
									fillContainer
									control={control}
									name="billToId"
									label=""
								/>
							</AnimatedEntrance>
						</GridItem>
					</GridContainer>
				</Box>
			</>
		)
	}
)

CreatePartOrderShipping.displayName = "CreatePartOrderShipping"

import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Hidden, withStyles } from "@material-ui/core"
import { PowerSettingsNew } from "@material-ui/icons"
import { Button } from "@ncs/bricks/components"
import { isAuthenticated } from "@ncs/bricks/redux/selectors"

import headerLinksStyle from "@ncs/bricks/assets/jss/material-dashboard-pro-react/components/headerLinksStyle"

function DefaultHeader(props) {
	const { classes, isAuthenticated, onSignIn, onSignOut } = props
	return (
		<div>
			<Button
				color="transparent"
				aria-label="Sign Out"
				justIcon
				className={classes.buttonLink}
				muiClasses={{
					label: "",
				}}
				onClick={() => (isAuthenticated() ? onSignOut() : onSignIn())}
			>
				<PowerSettingsNew className={classes.headerLinksSvg + " " + classes.links} />
				<Hidden mdUp>
					<span className={classes.linkText}>{"Sign Out"}</span>
				</Hidden>
			</Button>
		</div>
	)
}

DefaultHeader.propTypes = {
	isAuthenticated: PropTypes.func.isRequired,
	onSignIn: PropTypes.func.isRequired,
	onSignOut: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
	isAuthenticated: () => isAuthenticated(state),
})

export default connect(mapStateToProps, null)(withStyles(headerLinksStyle)(DefaultHeader))

import { FC, useMemo, useState } from "react"

import {
	InventoryLocation,
	LineItemStockInfo,
	makeApiErrorMessage,
	PartOrderLineItem,
	useInventoryPart,
	useLineItemStockInfo,
	useUpdatePartOrderLineItem,
} from "@ncs/ncs-api"
import { extractNumber, formatNumber } from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	Box,
	Button,
	ConfirmationModal,
	ConfirmationModalConfig,
	CssColumns,
	ExtendableModalProps,
	Heading,
	HeadingDivider,
	HeadingDividerProps,
	Label,
	LoadingSpinner,
	LocationSelector,
	Modal,
	Paragraph,
	PartImage,
	RadioBoolean,
	ThrottledNumericInput,
	useToast,
} from "@ncs/web-legos"

import { getLocationShortage } from "../../../part-order-detail-util"
import { PickNewDcModal } from "./PickNewDcModal"

export interface RerouteLineItemModalProps extends ExtendableModalProps {
	lineItem: PartOrderLineItem
	shortageLocationId?: string | null
	orderIsOnHold: boolean
	onRerouteSuccess: () => void
}

export const RerouteLineItemModal: FC<RerouteLineItemModalProps> = ({
	lineItem,
	shortageLocationId,
	orderIsOnHold,
	onRerouteSuccess,
	...rest
}) => {
	const { makeSuccessToast } = useToast()

	// We can assume the location line if you pass in an ID for the location with the
	// shortage, if there the line item only has one line location.
	const assumedLineLocation = useMemo(() => {
		// If you passed in the specific location you want, find that.
		if (shortageLocationId) {
			return lineItem.locationLines.find((l) => l.locationId === shortageLocationId) ?? null
		}

		// Otherwise, check if there's just one, and if there is (there probably is), use it.
		return lineItem.locationLines.length === 1 ? lineItem.locationLines[0] : null
	}, [shortageLocationId, lineItem.locationLines])

	const [isFullQty, setIsFullQty] = useState(true)
	const [qtyToChange, setQtyToChange] = useState<number | null>(extractNumber(lineItem.quantity))
	const [selectedLocation, setSelectedLocation] = useState<InventoryLocation | null>(null)

	const selectedLineLocation = useMemo(() => {
		return lineItem.locationLines.find((l) => l.locationId === selectedLocation?.id) ?? null
	}, [lineItem.locationLines, selectedLocation?.id])

	const [errorText, setErrorText] = useState<string | null>(null)
	const [showNewDcModal, setShowNewDcModal] = useState(false)
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)

	const [partDetails, partDetailsLoading] = useInventoryPart(lineItem.part?.id)
	const [stockInfo, stockInfoLoading] = useLineItemStockInfo(
		lineItem.id,
		{
			quantity: qtyToChange || 0,
			location: selectedLocation?.id ?? null,
		},
		{
			enabled: !!qtyToChange,
		}
	)
	const updateLineItem = useUpdatePartOrderLineItem()

	const handleDropShip = async () => {
		try {
			await updateLineItem({
				updates: {
					dropShip: true,
					oldWarehouseId: selectedLocation?.id,
					quantityMoving: extractNumber(qtyToChange),
				},
				id: lineItem.id,
			})
			makeSuccessToast("Line item drop shipped")
			rest.onClose()
		} catch (e) {
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const lineItemLocations = useMemo(() => {
		return lineItem.locationLines.map((l) => l.locationId)
	}, [lineItem.locationLines])

	const inStockDcs = useMemo(() => {
		return (stockInfo?.locations ?? []).filter((l) => {
			const availableField: keyof LineItemStockInfo["locations"][number] =
				isFullQty ? "fullAvailable" : "shortageAvailable"

			return l[availableField] && l.locationId !== selectedLocation?.id
		})
	}, [stockInfo?.locations, selectedLocation?.id, isFullQty])

	if (!lineItem.part) {
		return null
	}

	return (
		<Modal {...rest} title="Reroute Line Item" errorText={errorText}>
			<Box display="flex" justifyContent="space-between" gap={1} mb={2}>
				<Box display="flex" flexDirection="column" gap={2}>
					<div>
						<Label>Part</Label>
						<Heading>
							({lineItem.part.partNumber}) {lineItem.part.description}
						</Heading>
					</div>
					<div>
						<Label>Total line item quantity ordered</Label>
						<Heading>{formatNumber(extractNumber(lineItem.quantity))}</Heading>
					</div>
				</Box>

				<PartImage
					maxWidth={12}
					width="100%"
					src={partDetails?.imageUrl}
					alt={lineItem.part.description}
					isLoading={partDetailsLoading}
				/>
			</Box>

			<HeadingDivider {...headingDividerProps}>Allocation To Reroute</HeadingDivider>
			<LocationSelector
				value={selectedLocation}
				onChange={setSelectedLocation}
				label="From"
				placeholder="Choose allocation to reroute"
				filter={(l) => lineItemLocations.includes(l.id)}
				initialId={assumedLineLocation?.locationId}
			/>

			<Box display="flex" gap={2} mt={2}>
				<Box flex={1}>
					<Label>Quantity requested from DC</Label>
					<Heading>
						{selectedLineLocation ?
							formatNumber(selectedLineLocation.quantityRequested)
						:	"-"}
					</Heading>
				</Box>
				<Box flex={1}>
					<Label>Quantity available at DC</Label>
					<Heading>
						{selectedLineLocation ?
							formatNumber(selectedLineLocation.quantityAvailable)
						:	"-"}
					</Heading>
				</Box>
			</Box>
			<Box display="flex" gap={2} mt={2}>
				<Box flex={1}>
					<Label>Quantity short at DC</Label>
					<Heading>
						{selectedLineLocation ?
							formatNumber(getLocationShortage(selectedLineLocation) || 0)
						:	"-"}
					</Heading>
				</Box>
				<Box flex={1}>
					<Label>Quantity on order for DC</Label>
					<Heading>
						{stockInfo?.quantityOnOrder != null ?
							formatNumber(stockInfo.quantityOnOrder)
						:	"-"}
					</Heading>
				</Box>
			</Box>

			<HeadingDivider {...headingDividerProps}>Quantity To Change</HeadingDivider>
			<Box display="flex">
				<RadioBoolean
					fillContainer={false}
					htmlName="full-qty"
					value={isFullQty}
					onChange={(newState) => {
						setIsFullQty(newState)
						if (newState === true) {
							setQtyToChange(extractNumber(lineItem.quantity))
						}
					}}
					yesText={`Full line quantity (${formatNumber(
						extractNumber(lineItem.quantity)
					)})`}
					noText="Partial quantity"
					mb={0}
				/>
				<AnimatedEntrance show={!isFullQty} ml={4}>
					<ThrottledNumericInput
						maxWidth={5}
						value={qtyToChange}
						onChange={(v) => setQtyToChange(v ?? null)}
						label="Qty to change"
						max={extractNumber(lineItem.quantity)}
						fps={2}
						leading={false}
					/>
				</AnimatedEntrance>
			</Box>

			<HeadingDivider {...headingDividerProps}>Reroute To Another DC</HeadingDivider>
			{!!qtyToChange &&
				(stockInfoLoading ?
					<LoadingSpinner />
				:	<>
						{inStockDcs.length ?
							<>
								<Paragraph small secondary mb={0.5}>
									DCs with <strong>{formatNumber(qtyToChange)}</strong> in stock:
								</Paragraph>
								<CssColumns
									cells={inStockDcs.map(
										(dc) => `(${dc.locationNumber}) ${dc.locationName}`
									)}
									columnCounts={{ xs: 1, sm: 2 }}
								/>
							</>
						:	<Paragraph small secondary>
								No other DCs have this quantity in stock
							</Paragraph>
						}
					</>)}
			<Button
				icon="shuffle"
				disabled={
					stockInfoLoading || !inStockDcs.length || !qtyToChange || !selectedLocation?.id
				}
				variant="secondary-cta"
				containerProps={{ mt: 1 }}
				fillContainer
				onClick={() => setShowNewDcModal(true)}
			>
				Choose New DC {qtyToChange ? `(${qtyToChange})` : ""}
			</Button>

			<HeadingDivider {...headingDividerProps}>Drop Ship</HeadingDivider>
			{orderIsOnHold && (
				<Paragraph small secondary>
					Cannot place a drop ship while order is in hold status
				</Paragraph>
			)}
			<Button
				icon="parachute-box"
				variant="secondary-cta"
				fillContainer
				disabled={!selectedLocation?.id || !qtyToChange || orderIsOnHold}
				containerProps={{ mt: 0.75 }}
				onClick={() => {
					setConfirmationConfig({
						title: "Drop Ship",
						message: "Confirm: Drop ship this line item?",
						onConfirm: handleDropShip,
					})
				}}
			>
				Drop Ship {qtyToChange ? `(${qtyToChange})` : ""}
			</Button>

			{showNewDcModal && !!selectedLocation?.id && (
				<PickNewDcModal
					lineItemId={lineItem.id}
					locationId={selectedLocation.id}
					quantity={qtyToChange || 0}
					options={inStockDcs}
					onRerouteSuccess={onRerouteSuccess}
					onClose={() => setShowNewDcModal(false)}
				/>
			)}
			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</Modal>
	)
}

const headingDividerProps: HeadingDividerProps = {
	mt: 2,
	mb: 0.5,
	headingVariant: "h4",
	bold: true,
}

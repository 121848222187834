import React from "react"

import withStyles from "@material-ui/core/styles/withStyles"
import moment from "moment"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { bindActionCreators } from "redux"

import {
	createRouteSchedule,
	editRouteSchedule,
	getCustomerRoutes,
	getRouteSchedule,
	removeRouteSchedule,
} from "@ncs/mortar/redux/services/customers"
import callApi from "@ncs/bricks/redux/services/callApi"
import { BigCalendar } from "@ncs/web-legos"

import {
	ButtonWithIcon,
	ConditionalContent,
	Datetime,
	GridContainer,
	GridItem,
	LoadingWrapper,
	QuickCard,
	Select,
	TerritorySelector,
} from "~/components"

import Cancel from "@material-ui/icons/Cancel"
import Remove from "@material-ui/icons/DeleteForever"
import Save from "@material-ui/icons/Save"
import Schedule from "@material-ui/icons/Schedule"

const styles = {}

class RouteSchedule extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isLoading: false,
			isSaving: false,
			selectedTerritory: null,
			events: [],
			eventsToShow: [],
			routes: [],
			routeSelected: -1,
			startDate: null,
			endDate: null,
			eventSelected: null,
		}
	}

	handleGetEvents = (territory) => {
		this.props.callApi(getRouteSchedule({ territory: territory })).then((result) => {
			if (result.error) {
				this.setState({ isLoading: false })
				console.error(result.error)
				return
			}
			this.setState({
				events: result.payload,
				eventsToShow: result.payload,
				routeSelected: -1,
			})
		})
	}

	handleStartDateChange = (startDate) => {
		this.setState({ startDate })
	}

	handleEndDateChange = (endDate) => {
		this.setState({ endDate })
	}

	handleSelectEvent = (event) => {
		this.setState({
			eventSelected: event,
			startDate: event.start_date,
			endDate: event.end_date,
			routeSelected: event.route_id,
		})
	}

	handleSelectSlot = (event) => {
		this.setState({
			eventSelected: event,
			startDate: event.start,
			endDate: event.end,
		})
	}

	cancelDateSelect = () => {
		this.setState({
			eventSelected: null,
			startDate: null,
			endDate: null,
		})
	}

	handleRemoveEvent = () => {
		this.setState({ isSaving: true })
		this.props.callApi(removeRouteSchedule(this.state.eventSelected.id)).then((result) => {
			if (result.error) {
				this.setState({ isLoading: false })
				console.error(result.error)
				return
			}
			this.setState({ isSaving: false })
			this.cancelDateSelect()
			this.handleGetEvents(this.state.selectedTerritory)
		})
	}

	handleUpdateRouteSelect = (routeSelected) => {
		this.setState({
			routeSelected,
			eventsToShow:
				routeSelected === -1 ?
					this.state.events
				:	this.state.events.filter((e) => e.route_id.toString() === routeSelected),
		})
	}

	handleSaveEvent = () => {
		this.setState({ isSaving: true })
		let params = {
			start_date: this.state.startDate,
			end_date: this.state.endDate,
			route_id: this.state.routeSelected,
		}
		if (this.state.eventSelected.id) {
			this.props
				.callApi(editRouteSchedule(this.state.eventSelected.id, params))
				.then((result) => {
					if (result.error) {
						this.setState({ isLoading: false })
						console.error(result.error)
						return
					}
					this.setState({ isSaving: false })
					this.cancelDateSelect()
					this.handleGetEvents(this.state.selectedTerritory)
				})
		} else {
			this.props.callApi(createRouteSchedule(params)).then((result) => {
				if (result.error) {
					this.setState({ isLoading: false })
					return
				}
				this.setState({ isSaving: false })
				this.cancelDateSelect()
				this.handleGetEvents(this.state.selectedTerritory)
			})
		}
	}

	handleTerritoryChange = (selectedTerritory) => {
		this.setState({ selectedTerritory, isLoading: true })
		this.handleGetEvents(selectedTerritory)
		this.props.callApi(getCustomerRoutes({ territory: selectedTerritory })).then((result) => {
			if (result.error) {
				this.setState({ isLoading: false })
				console.error(result.error)
				return
			}
			this.setState({
				routes: result.payload,
				routeSelected: -1,
				isLoading: false,
			})
		})
	}

	render() {
		const {
			isLoading,
			selectedTerritory,
			eventsToShow,
			routes,
			routeSelected,
			eventSelected,
			startDate,
			endDate,
			isSaving,
		} = this.state

		let saveDisabled = this.state.routeSelected === -1

		return (
			<React.Fragment>
				<LoadingWrapper isLoading={isLoading} />

				<QuickCard title={"Route Schedule"} icon={<Schedule />}>
					<GridContainer>
						<GridItem xs={12}>
							<TerritorySelector
								onChange={this.handleTerritoryChange}
								territory={selectedTerritory}
							/>
						</GridItem>
						<ConditionalContent show={selectedTerritory !== null}>
							<GridItem xs={12}>
								<Select
									id="routes"
									labelText="Route"
									value={routeSelected}
									onChange={this.handleUpdateRouteSelect}
									options={[{ id: -1, name: "All Routes" }, ...routes]}
									valueAccessor="id"
									textAccessor={"name"}
								/>
							</GridItem>
							<ConditionalContent show={eventSelected !== null}>
								<GridItem xs={12} md={6}>
									<Datetime
										labelText="Start"
										timeFormat={false}
										dateFormat="MM-DD-YYYY"
										onChange={(e) => this.handleStartDateChange(moment(e))}
										value={startDate}
										inputProps={{ disabled: true }}
									/>
								</GridItem>
								<GridItem xs={12} md={6}>
									<Datetime
										labelText="End"
										timeFormat={false}
										dateFormat="MM-DD-YYYY"
										onChange={(e) => this.handleEndDateChange(moment(e))}
										value={endDate}
										inputProps={{ disabled: true }}
									/>
								</GridItem>
								<GridItem xs={12} align={"right"}>
									<ButtonWithIcon
										content="Cancel Edit"
										onClick={this.cancelDateSelect}
										color="warning"
										size="sm"
										round
										disabled={isSaving}
										icon={<Cancel />}
									/>
									<ButtonWithIcon
										content="Remove Route Event"
										onClick={this.handleRemoveEvent}
										color="danger"
										size="sm"
										round
										disabled={isSaving || saveDisabled}
										icon={<Remove />}
									/>

									<ButtonWithIcon
										content="Save Route Event"
										onClick={this.handleSaveEvent}
										color="success"
										size="sm"
										round
										disabled={isSaving || saveDisabled}
										icon={<Save />}
									/>
								</GridItem>
							</ConditionalContent>
						</ConditionalContent>

						<GridItem xs={12}>
							<BigCalendar
								popup
								selectable
								views={["month"]}
								startAccessor="start_date"
								endAccessor="end_date"
								events={eventsToShow}
								onSelectEvent={(event) => this.handleSelectEvent(event)}
								onSelectSlot={(event) => this.handleSelectSlot(event)}
							/>
						</GridItem>
					</GridContainer>
				</QuickCard>
			</React.Fragment>
		)
	}
}

const mapStateToProps = (state) => {
	return {}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			callApi,
		},
		dispatch
	)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(withStyles(styles)(RouteSchedule)))

import { FC, useEffect, useRef } from "react"

import { OrganizationMinimal, useActiveOrganizations } from "@ncs/ncs-api"

import { ExtendableSearchableSelectProps, SearchableSelect } from "../inputs"

export interface OrganizationSelectorProps
	extends ExtendableSearchableSelectProps<OrganizationMinimal> {
	initialId?: string | null
}

export const OrganizationSelector: FC<OrganizationSelectorProps> = ({
	value,
	onChange,
	initialId,
	...rest
}) => {
	const [organizations, loading] = useActiveOrganizations()
	const hasSetInitial = useRef(false)

	useEffect(() => {
		if (!!initialId && !hasSetInitial.current && !!organizations) {
			const option = organizations.find((t) => t.id === initialId)

			if (option) {
				onChange(option)
				hasSetInitial.current = true
			}
		}
	}, [initialId, organizations, onChange])

	return (
		<SearchableSelect
			getOptionLabel={(option) => option.name}
			isLoading={loading}
			label="Organization"
			placeholder="Search for an organization..."
			{...rest}
			options={organizations ?? []}
			value={value ?? null}
			onItemSelect={onChange}
			useSearchIcon
		/>
	)
}

import { z } from "zod"

import {
	LocationPartSummary,
	LocationPartSummaryPatch,
	LocationPartSummaryPost,
} from "@ncs/ncs-api"

export const LocationPartFormSchema = z
	.object({
		partId: z.string().min(1, "Part is required"),
		vendorId: z.string().min(1, "Supplier is required"),
		isStock: z.boolean(),
		minLocationLevel: z.number().nullable(),
		maxLocationLevel: z.number().nullable(),
		econOrderAmount: z.number().nullable(),
		minOrderQuantity: z.number().nullable(),
		leadTime: z.number().nullable(),
		isLocal: z.boolean(),
		vendorPartNumber: z.string().nullable(),
		vendorPartName: z.string().nullable(),
		vendorPartCost: z.number().nullable(),
	})
	.refine((values) => values.isStock || values.isLocal, {
		message: "Must choose either stock or local",
		path: ["isStock"],
	})
	.refine((values) => values.isStock || values.isLocal, {
		message: "Must choose either stock or local",
		path: ["isLocal"],
	})
	.refine((values) => !values.isStock || values.minLocationLevel != null, {
		message: "Required for stock part",
		path: ["minLocationLevel"],
	})
	.refine((values) => !values.isStock || values.maxLocationLevel != null, {
		message: "Required for stock part",
		path: ["maxLocationLevel"],
	})
	.refine((values) => !values.isStock || values.econOrderAmount != null, {
		message: "Required for stock part",
		path: ["econOrderAmount"],
	})
	.refine((values) => !values.isStock || values.minOrderQuantity != null, {
		message: "Required for stock part",
		path: ["minOrderQuantity"],
	})
	.refine((values) => !values.isStock || values.leadTime != null, {
		message: "Required for stock part",
		path: ["leadTime"],
	})
	.refine((values) => !values.isLocal || values.vendorPartNumber != null, {
		message: "Required for locally sourced part",
		path: ["vendorPartNumber"],
	})
	.refine((values) => !values.isLocal || values.vendorPartName != null, {
		message: "Required for locally sourced part",
		path: ["vendorPartName"],
	})
	.refine((values) => !values.isLocal || values.vendorPartCost != null, {
		message: "Required for locally sourced part",
		path: ["vendorPartCost"],
	})
	.refine((values) => !values.isLocal || values.vendorId !== "1", {
		message: "Locally sourced parts cannot have Grimes as their supplier",
		path: ["vendorId"],
	})
export type LocationPartForm = z.infer<typeof LocationPartFormSchema>

export const defaultLocationPartFormValues: LocationPartForm = {
	partId: "",
	vendorId: "",
	isStock: true,
	minLocationLevel: null,
	maxLocationLevel: null,
	econOrderAmount: null,
	minOrderQuantity: null,
	leadTime: null,
	isLocal: false,
	vendorPartNumber: null,
	vendorPartName: null,
	vendorPartCost: null,
}

export const getPartSummaryFormValues = (
	p: LocationPartSummary | null | undefined
): Partial<LocationPartForm> => {
	if (!p) return {}

	return {
		partId: p.partId,
		vendorId: p.vendor?.id ?? "",
		isStock: p.isStock,
		minLocationLevel: p.minLocationLevel,
		maxLocationLevel: p.maxLocationLevel,
		econOrderAmount: p.econOrderAmount,
		minOrderQuantity: p.minOrderQuantity,
		leadTime: p.leadTime,
		isLocal: p.isLocallySourced,
		vendorPartNumber: p.localVendorPartNumber,
		vendorPartName: p.localVendorPartName,
		vendorPartCost: p.localVendorPartCost,
	}
}

export const makeLocationPartPost = (
	rawFormData: LocationPartForm,
	locationId: string
): LocationPartSummaryPost => {
	const validatedData = LocationPartFormSchema.parse(rawFormData)

	if (!validatedData.partId) throw new Error("No part ID found")

	const postData: LocationPartSummaryPost = {
		partId: validatedData.partId,
		maxLevel: validatedData.maxLocationLevel,
		reorderPoint: validatedData.minLocationLevel,
		econOrderAmount: validatedData.econOrderAmount,
		minOrderQuantity: validatedData.minOrderQuantity,
		leadTimeDays: validatedData.leadTime,
		vendorId: validatedData.vendorId,
		vendorPartNumber: validatedData.vendorPartNumber,
		vendorPartDescription: validatedData.vendorPartName,
		vendorPartCost: validatedData.vendorPartCost,
		stockPart: validatedData.isStock,
		localPart: validatedData.isLocal,
		locationId,
	}

	return postData
}

export const makeLocationPartPatch = (
	rawFormData: LocationPartForm,
	locationId: string
): LocationPartSummaryPatch => {
	const validatedData = LocationPartFormSchema.parse(rawFormData)

	if (!validatedData.partId) throw new Error("No part ID found")

	const patchData: LocationPartSummaryPatch = {
		partId: validatedData.partId,
		maxLevel: validatedData.maxLocationLevel,
		reorderPoint: validatedData.minLocationLevel,
		econOrderAmount: validatedData.econOrderAmount,
		minOrderQuantity: validatedData.minOrderQuantity,
		leadTimeDays: validatedData.leadTime,
		vendorId: validatedData.vendorId,
		vendorPartNumber: validatedData.vendorPartNumber,
		vendorPartDescription: validatedData.vendorPartName,
		vendorPartCost: validatedData.vendorPartCost,
		stockPart: validatedData.isStock,
		localPart: validatedData.isLocal,
		locationId,
	}

	return patchData
}

export const makeLocationPartDeletePatch = (
	partSummary: LocationPartSummary,
	locationId: string
): LocationPartSummaryPatch => {
	const patchData: LocationPartSummaryPatch = {
		partId: partSummary.partId,
		maxLevel: partSummary.maxLocationLevel,
		reorderPoint: partSummary.minLocationLevel,
		econOrderAmount: partSummary.econOrderAmount,
		minOrderQuantity: partSummary.minOrderQuantity,
		leadTimeDays: partSummary.leadTime,
		vendorId: partSummary.vendor?.id ?? "1",
		vendorPartNumber: partSummary.localVendorPartNumber,
		vendorPartDescription: partSummary.localVendorPartName,
		vendorPartCost: partSummary.localVendorPartCost,
		stockPart: false,
		localPart: false,
		locationId,
	}

	return patchData
}

export const isSummaryRow = (
	row: LocationPartSummary["bins"][number] | LocationPartSummary
): row is LocationPartSummary => {
	// If you're getting the bin of a summary row, it won't have a bins property.
	return !!(row as LocationPartSummary).bins
}

export const binLabelsBatchSize = 250

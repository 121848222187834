import React, { HTMLAttributes, useMemo } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { makeStyles } from "@material-ui/core"

import styles from "../../assets/jss/material-dashboard-pro-react/components/cardFooterStyle"

const useStyles = makeStyles(styles)

export interface CardFooterProps extends HTMLAttributes<HTMLDivElement> {
	className?: string
	plain?: boolean
	profile?: boolean
	pricing?: boolean
	testimonial?: boolean
	stats?: boolean
	chart?: boolean
	product?: boolean
}

const CardFooter: React.FC<CardFooterProps> = ({
	className,
	plain,
	profile,
	pricing,
	testimonial,
	stats,
	chart,
	product,
	children,
	...rest
}) => {
	const classes = useStyles()

	const cardFooterClasses = useMemo(
		() =>
			classNames(className, {
				[classes.cardFooter]: true,
				[classes.cardFooterPlain]: plain,
				[classes.cardFooterProfile]: profile || testimonial,
				[classes.cardFooterPricing]: pricing,
				[classes.cardFooterTestimonial]: testimonial,
				[classes.cardFooterStats]: stats,
				[classes.cardFooterChart]: chart || product,
			}),
		[chart, classes, className, plain, profile, pricing, product, stats, testimonial]
	)

	return (
		<div className={cardFooterClasses} {...rest}>
			{children}
		</div>
	)
}

CardFooter.propTypes = {
	className: PropTypes.string,
	plain: PropTypes.bool,
	profile: PropTypes.bool,
	pricing: PropTypes.bool,
	testimonial: PropTypes.bool,
	stats: PropTypes.bool,
	chart: PropTypes.bool,
	product: PropTypes.bool,
	children: PropTypes.node,
}

export default CardFooter

import React, { forwardRef } from "react"
import PropTypes from "prop-types"
import { createStyles, makeStyles, Theme } from "@material-ui/core"
import { Button, CircularProgressIndeterminate } from "../../components"
import { ButtonProps } from "./Button"

import buttonStyle from "../../assets/jss/material-dashboard-pro-react/components/buttonStyle"

interface StyleProps {
	noMargin: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
	...buttonStyle,
	...createStyles({
		wrapper: {
			position: "relative",
			display: "inline-block",
			margin: (props: StyleProps) => (props.noMargin ? 0 : theme.spacing(1)),
			"& button": {
				margin: (props) => (props.noMargin ? 0 : "auto"),
			},
		},
		buttonProgress: {
			position: "absolute",
			top: "50%",
			left: "50%",
			marginTop: -12,
			marginLeft: -12,
		},
	}),
}))

export interface ButtonWithIconProps extends ButtonProps {
	icon: React.ReactElement
	content?: string
	children?: React.ReactNode
	disabled?: boolean
	loading?: boolean
	className?: string
	wrapperClassName?: string
	noMargin?: boolean
}

const ButtonWithIcon = forwardRef<HTMLButtonElement, ButtonWithIconProps>(
	(
		{
			icon,
			content,
			disabled,
			loading,
			wrapperClassName,
			noMargin = false,
			children,
			...rest
		},
		ref
	) => {
		const classes = useStyles({ noMargin })

		return (
			<div className={classes.wrapper + (wrapperClassName ? " " + wrapperClassName : "")}>
				<Button ref={ref} disabled={disabled || loading} {...rest}>
					{icon} {content || children}
				</Button>
				{loading && (
					<CircularProgressIndeterminate size={24} className={classes.buttonProgress} />
				)}
			</div>
		)
	}
)

ButtonWithIcon.propTypes = {
	icon: PropTypes.element.isRequired,
	content: PropTypes.string,
	children: PropTypes.node,
	disabled: PropTypes.bool,
	loading: PropTypes.bool,
	className: PropTypes.string,
	wrapperClassName: PropTypes.string,
	noMargin: PropTypes.bool,
}

export default ButtonWithIcon

import { Dispatch, FC, memo, SetStateAction } from "react"

import produce from "immer"
import { nanoid } from "nanoid"

import { BinLocationMinimal, useLocationBins } from "@ncs/ncs-api"
import { Box, IconButton } from "@ncs/web-legos"

import { binQtyRowHeight, LineFormState } from "./receive-parts-modal-util"
import { ReceivePartBinSelector } from "./ReceivePartsBinSelector"

export interface ReceivePartsBinListProps {
	lineItemId: string
	destinationId: string
	binQuantities: LineFormState[string]["destinations"][string]["binQuantities"]
	setLineFormState: Dispatch<SetStateAction<LineFormState>>
	locationId: string | null
}

export const ReceivePartsBinList: FC<ReceivePartsBinListProps> = memo(
	({ lineItemId, destinationId, binQuantities, setLineFormState, locationId }) => {
		const [bins] = useLocationBins(locationId ?? null)

		const updateBin = (binQuantityId: string, newBin: BinLocationMinimal | null) => {
			setLineFormState((prev) => {
				return produce(prev, (draft) => {
					const binQuantity =
						draft[lineItemId].destinations[destinationId].binQuantities[binQuantityId]

					if (newBin !== undefined) {
						binQuantity.binId = newBin?.id ?? null
					}
				})
			})
		}

		const addBinRow = () => {
			setLineFormState((prev) => {
				const newBinQuantityId = nanoid()

				return produce(prev, (draft) => {
					draft[lineItemId].destinations[destinationId].binQuantities[newBinQuantityId] =
						{
							binQuantityId: newBinQuantityId,
							binId: null,
							quantity: 0,
						}
				})
			})
		}

		const removeBinRow = (binQuantityId: string) => {
			setLineFormState((prev) => {
				return produce(prev, (draft) => {
					delete draft[lineItemId].destinations[destinationId].binQuantities[
						binQuantityId
					]
				})
			})
		}

		return (
			<>
				{Object.values(binQuantities).map(({ binQuantityId, binId }, i) => {
					return (
						<Box key={binQuantityId} display="flex" gap={1} height={binQtyRowHeight}>
							<ReceivePartBinSelector
								bins={bins ?? []}
								value={binId}
								onChange={(newBin) => {
									updateBin(binQuantityId, newBin)
								}}
							/>
							{i === 0 && (
								<IconButton
									icon="plus-circle"
									onClick={() => addBinRow()}
									color="primary"
									title="Add bin row"
								/>
							)}
							{i > 0 && (
								<IconButton
									icon="times"
									onClick={() => removeBinRow(binQuantityId)}
									color="gray"
									title="Remove extra bin row"
								/>
							)}
						</Box>
					)
				})}
			</>
		)
	}
)

ReceivePartsBinList.displayName = "ReceivePartsBinList"

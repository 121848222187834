import { FC, useMemo, useState } from "react"

import { Equipment, makeApiErrorMessage, useEquipment, useUpdateEquipment } from "@ncs/ncs-api"
import { ExtendableModalProps, Modal, Paragraph, Select, useToast } from "@ncs/web-legos"

export interface TransferContractsModalProps extends ExtendableModalProps {
	currentEquipment: Equipment
	customerId: string
}

export const TransferContractsModal: FC<TransferContractsModalProps> = ({
	currentEquipment,
	customerId,
	...rest
}) => {
	const { makeSuccessToast } = useToast()
	const [selectedEquipmentId, setSelectedEquipmentId] = useState<string | null>(null)
	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const [allEquipment] = useEquipment(customerId)
	const updateEquipment = useUpdateEquipment(customerId)

	const handleSave = async () => {
		try {
			if (!selectedEquipmentId) throw new Error("No equipment selected")

			setIsSaving(true)
			await updateEquipment({
				updates: {
					moveContractsTo: selectedEquipmentId,
				},
				id: currentEquipment.id,
			})
			makeSuccessToast("Contracts transferred")
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const equipmentList = useMemo(() => {
		return (allEquipment ?? []).filter((e) => e.id !== currentEquipment.id)
	}, [allEquipment, currentEquipment])

	return (
		<Modal
			title="Transfer Contracts"
			{...rest}
			errorText={errorText}
			closeButtonText="Cancel"
			rightButtons={{
				buttonText: "Move contracts",
				onClick: handleSave,
				disabled: !selectedEquipmentId,
				isLoading: isSaving,
			}}
		>
			<Paragraph mb={1}>
				Move all contracts currently associated with {currentEquipment?.model}{" "}
				{!!currentEquipment.serialNumber && `#${currentEquipment.serialNumber}`} to
				different equipment at this site.
			</Paragraph>

			<Select
				options={equipmentList}
				value={selectedEquipmentId}
				onChange={setSelectedEquipmentId}
				valueAccessor="id"
				textAccessor={(option) =>
					`${option.model}${option.serialNumber ? ` #${option.serialNumber}` : ""}${
						!option.isActive ? ` (inactive)` : ""
					}`
				}
				disabledAccessor={(option) => !option.isActive}
				label="Move to"
			/>
		</Modal>
	)
}

import React, { useMemo } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import {
	Grid,
	GridProps as MaterialUIGridProps,
	makeStyles,
	createStyles,
} from "@material-ui/core"
import { materialUISizeValues, MaterialUISizeValues } from "../../util/types"

const useStyles = makeStyles(
	createStyles({
		grid: {
			padding: "0 15px !important",
		},
		alignItemsCenter: {
			display: "inline-flex",
			alignItems: "center",
		},
		justifyContentLeft: {
			display: "inline-flex",
			justifyContent: "flex-start",
		},
		justifyContentCenter: {
			display: "inline-flex",
			justifyContent: "center",
		},
		justifyContentRight: {
			display: "inline-flex",
			justifyContent: "flex-end",
		},
		justifyContentSpaceBetween: {
			display: "inline-flex",
			justifyContent: "space-between",
		},
	})
)

export interface GridItemProps extends MaterialUIGridProps {
	xs?: MaterialUISizeValues
	sm?: MaterialUISizeValues
	md?: MaterialUISizeValues
	lg?: MaterialUISizeValues
	xl?: MaterialUISizeValues
	className?: string
	show?: boolean
	hide?: boolean
	alignItemsCenter?: boolean
	justifyContentLeft?: boolean
	justifyContentCenter?: boolean
	justifyContentRight?: boolean
	justifyContentSpaceBetween?: boolean
}

const GridItem = React.forwardRef<HTMLDivElement, GridItemProps>(
	(
		{
			className,
			direction,
			show,
			hide,
			alignItemsCenter,
			justifyContentLeft,
			justifyContentCenter,
			justifyContentRight,
			justifyContentSpaceBetween,
			children,
			...rest
		},
		ref
	) => {
		const classes = useStyles()

		const gridClasses = useMemo(
			() =>
				classNames(classes.grid, className, {
					[classes.alignItemsCenter]: alignItemsCenter,
					[classes.justifyContentLeft]: justifyContentLeft,
					[classes.justifyContentCenter]: justifyContentCenter,
					[classes.justifyContentRight]: justifyContentRight,
					[classes.justifyContentSpaceBetween]: justifyContentSpaceBetween,
				}),
			[
				alignItemsCenter,
				classes,
				className,
				justifyContentCenter,
				justifyContentLeft,
				justifyContentRight,
				justifyContentSpaceBetween,
			]
		)

		if (typeof show === typeof hide && typeof show !== "undefined") {
			throw new Error(
				"The `show` and `hide` props are mutually exclusive. Please provide just one, " +
					"whichever makes the most sense for your conditional statement"
			)
		}

		const shouldShow =
			typeof show === "boolean" ? show
			: typeof hide === "boolean" ? !hide
			: true

		if (!shouldShow) {
			return null
		}

		return (
			<Grid
				item
				ref={ref}
				{...rest}
				// fixes errors when GridItem is inside of a Slide component
				direction={
					!direction || ["up", "down", "left", "right"].includes(direction ?? "") ?
						undefined
					:	direction
				}
				className={gridClasses}
			>
				{!!shouldShow &&
					(typeof children === "function" && children instanceof Function ?
						children()
					:	children)}
			</Grid>
		)
	}
)

GridItem.propTypes = {
	xs: PropTypes.oneOf(materialUISizeValues),
	sm: PropTypes.oneOf(materialUISizeValues),
	md: PropTypes.oneOf(materialUISizeValues),
	lg: PropTypes.oneOf(materialUISizeValues),
	xl: PropTypes.oneOf(materialUISizeValues),
	className: PropTypes.string,
	show: PropTypes.bool,
	hide: PropTypes.bool,
	alignItemsCenter: PropTypes.bool,
	justifyContentLeft: PropTypes.bool,
	justifyContentCenter: PropTypes.bool,
	justifyContentRight: PropTypes.bool,
	justifyContentSpaceBetween: PropTypes.bool,
}

export default GridItem

import { FC } from "react"

import { Cell, Column } from "react-table"

import {
	PickListDetailReportLine,
	PickListDetailReportQueryParams,
	useExportPickListDetailReport,
	usePickListDetailReport,
} from "@ncs/ncs-api"
import {
	formatDateTime,
	getStartAndEndDateQueryParams,
	getTimezoneAbbreviation,
	TimeAgo,
	Typify,
} from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	Link,
	RegionQueryFilter,
	Table,
	TerritoryQueryFilter,
	TimeAgoQueryFilter,
	usePaginationUrlState,
	useUrlState,
} from "@ncs/web-legos"

export type PickListDetailReportUrlState = Typify<PickListDetailReportQueryParams>

export const PickListDetailReport: FC = () => {
	const [queryParams, { setUrlState: setQueryParams }] =
		useUrlState<PickListDetailReportUrlState>(initialPickListDetailReportUrlState)
	const [pagination, setPagination] = usePaginationUrlState()

	const query = usePickListDetailReport({
		params: { ...queryParams, ...pagination },
		manualPagination: true,
	})
	const dataExport = useExportPickListDetailReport(queryParams)

	return (
		<AnimatedEntrance show>
			<Table
				query={query}
				columns={columns}
				queryParamState={queryParams}
				setQueryParamState={setQueryParams}
				pagination={pagination}
				setPagination={setPagination}
				dataExport={dataExport}
				pinnedQueryFilters={[TimeAgoQueryFilter, TerritoryQueryFilter]}
				toggledQueryFilters={[RegionQueryFilter]}
				filterResetValues={initialPickListDetailReportUrlState}
				storeColumnVisibility="pick-list-detail-report-columns"
			/>
		</AnimatedEntrance>
	)
}

const columns: Column<PickListDetailReportLine>[] = [
	{
		Header: "Pick List #",
		disableSortBy: true,
		accessor: "id",
	},
	{
		Header: "Status",
		id: "status_Description",
		accessor: ({ status }) => status.description,
	},
	{
		Header: "Part Order",
		disableSortBy: true,
		accessor: ({ partOrder }) => partOrder?.orderId,
		Cell: ({
			row: {
				original: { partOrder },
			},
		}: Cell<PickListDetailReportLine>) => {
			return partOrder ?
					<Link to={`/part-orders/${partOrder.id}`} newTab icon="external-link">
						Part Order #{partOrder.orderId}
					</Link>
				:	"-"
		},
	},
	{
		Header: "Picked on-time",
		disableSortBy: true,
		accessor: ({ pickedOnTime }) =>
			pickedOnTime === true ? "Yes"
			: pickedOnTime === false ? "No"
			: pickedOnTime,
	},
	{
		Header: "Delivery order ID",
		disableSortBy: true,
		hiddenByDefault: true,
		accessor: ({ deliveryOrderId }) => deliveryOrderId || "-",
	},
	{
		Header: `Created (${getTimezoneAbbreviation()})`,
		id: "createdDate",
		accessor: ({ createdDate }) => formatDateTime(createdDate),
	},
	{
		Header: `Projected pick time (${getTimezoneAbbreviation()})`,
		disableSortBy: true,
		hiddenByDefault: true,
		accessor: ({ projectedPickTime }) =>
			projectedPickTime ? formatDateTime(projectedPickTime) : "-",
	},
	{
		Header: `Actual pick time (${getTimezoneAbbreviation()})`,
		disableSortBy: true,
		hiddenByDefault: true,
		accessor: ({ actualPickTime }) => (actualPickTime ? formatDateTime(actualPickTime) : "-"),
	},
]

const initialPickListDetailReportUrlState: PickListDetailReportUrlState = {
	ordering: null,
	region: null,
	territory: null,
	location: null,
	startDate: getStartAndEndDateQueryParams(TimeAgo.DaysAgo30)[0],
	endDate: null,
}

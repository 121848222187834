import React from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { BaseSelectFilter, withDynamicTableFilter } from "@ncs/bricks/components"
import { loadLookups, LOOKUPS } from "../../redux/services/lookups"

class RegionFilter extends React.PureComponent {
	componentDidMount() {
		this.props.loadLookups([LOOKUPS.Regions])
	}

	render() {
		return (
			<BaseSelectFilter
				id="region"
				labelText="Region"
				options={[{ _id: "", description: "All Regions" }, ...this.props.regions]}
				valueAccessor="_id"
				textAccessor={(x) => (x._id ? `${x.description} (${x.code})` : x.description)}
				{...this.props}
			/>
		)
	}
}

const mapStateToProps = ({ lookups }) => {
	return {
		isLoadingRegions: lookups.isLoadingRegions,
		regions: lookups.regions,
	}
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ loadLookups }, dispatch)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withDynamicTableFilter("region")(RegionFilter))

import React from "react"
import PropTypes from "prop-types"
import { Popover, FormControlLabel, Checkbox, withStyles } from "@material-ui/core"
import { Add } from "@material-ui/icons"

import { ButtonWithIcon } from "../../components"

const styles = {
	columnChooser: {
		margin: "15px",
		userSelect: "none",
	},
	clickable: {
		cursor: "pointer",
	},
}

class ColumnChooser extends React.PureComponent {
	constructor(props) {
		super(props)
		this.state = { isChoosingColumns: null }
	}

	handleChooseColumns = (event) => {
		this.setState({ isChoosingColumns: event.currentTarget })
	}

	handleDoneChoosingColumns = () => {
		this.setState({ isChoosingColumns: null })
	}

	render() {
		const { classes, toggleableColumns, selectedColumns, toggleColumn } = this.props

		if (!Array.isArray(toggleableColumns) || toggleableColumns.length === 0) {
			return null
		}

		return (
			<React.Fragment>
				<ButtonWithIcon
					round
					simple
					onClick={this.handleChooseColumns}
					color="success"
					className="edit"
					icon={<Add />}
					content={"Choose Columns"}
				/>

				<Popover
					open={Boolean(this.state.isChoosingColumns)}
					anchorEl={this.state.isChoosingColumns}
					onClose={this.handleDoneChoosingColumns}
					anchorOrigin={{ vertical: "center", horizontal: "left" }}
					transformOrigin={{ vertical: "center", horizontal: "right" }}
				>
					<div className={classes.columnChooser}>
						{toggleableColumns.map((col) => (
							<div key={col}>
								<FormControlLabel
									control={
										<Checkbox
											checked={selectedColumns.includes(col)}
											tabIndex={-1}
											onClick={() => toggleColumn(col)}
										/>
									}
									label={col}
									className={classes.clickable}
								/>
							</div>
						))}
					</div>
				</Popover>
			</React.Fragment>
		)
	}
}

ColumnChooser.propTypes = {
	toggleableColumns: PropTypes.array.isRequired,
	selectedColumns: PropTypes.array.isRequired,
	toggleColumn: PropTypes.func.isRequired,
}

export default withStyles(styles)(ColumnChooser)

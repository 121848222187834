import { FC, useEffect, useState } from "react"

import { saveAs } from "file-saver"

import { LabelSize, makeApiErrorMessage, useCreateLabel } from "@ncs/ncs-api"
import {
	Box,
	Button,
	Card,
	ErrorText,
	LabelSizeSelector,
	NumericInput,
	Paragraph,
	TextInput,
	useScreenSizeMatch,
	useToast,
} from "@ncs/web-legos"

const QrCodeMaker: FC = () => {
	const { makeSuccessToast } = useToast()
	const screenIsMd = useScreenSizeMatch("md")

	const [contents, setContents] = useState<string | null>(null)
	const [size, setSize] = useState<LabelSize>("3x2")
	const [quantity, setQuantity] = useState<number | null>(1)
	const [errorText, setErrorText] = useState<string | null>(null)
	const [isSaving, setIsSaving] = useState(false)

	const makeLabel = useCreateLabel()

	const requestLabel = async () => {
		try {
			if (!contents) {
				throw new Error("Text for QR code is required")
			}
			if (!quantity) {
				throw new Error("Must enter a quantity of 1 or more")
			}

			setIsSaving(true)
			const { data } = await makeLabel({
				labelSize: size,
				labels: [{ label: contents, quantity }],
			})
			saveAs(new Blob([data as Blob]), `label_${contents}.pdf`)
			makeSuccessToast("Label created")
		} catch (e) {
			setErrorText(makeApiErrorMessage(e))
		} finally {
			setIsSaving(false)
		}
	}

	const reset = () => {
		setContents(null)
		setSize("3x2")
		setQuantity(1)
		setErrorText(null)
	}

	useEffect(() => {
		setErrorText(null)
	}, [contents, quantity])

	return (
		<Card heading="Create QR Code" headingIcon="barcode-read">
			<Box mb={3} maxWidth={!screenIsMd ? 40 : undefined}>
				<Paragraph mb={0.5}>Enter the text to be encoded into the QR code:</Paragraph>
				<TextInput
					value={contents}
					onChange={setContents}
					placeholder="QR code contents (required)"
					maxLength={30}
				/>
				<Paragraph mt={1} mb={0.5}>
					Number of copies:
				</Paragraph>
				<NumericInput
					value={quantity}
					onChange={(value) => setQuantity(value ?? null)}
					placeholder="# of copies..."
					min={1}
					max={250}
				/>
				<LabelSizeSelector value={size} onChange={setSize} radio />
			</Box>

			{!!errorText && <ErrorText my={1}>{errorText}</ErrorText>}

			<Button
				variant="primary-cta"
				fillContainer={screenIsMd}
				onClick={requestLabel}
				isLoading={isSaving}
			>
				Generate QR Code
			</Button>
			<Box mt={2}>
				<Button icon="undo" onClick={reset}>
					Reset
				</Button>
			</Box>
		</Card>
	)
}

export default QrCodeMaker

import { FC, useMemo, useState } from "react"

import { css } from "@emotion/react"

import { PartOrder, PartOrderShipment, usePartOrderShipments } from "@ncs/ncs-api"
import { formatDate } from "@ncs/ts-utils"
import { Box, Button, Divider, Heading, Paragraph, Pipe, useScreenSizeMatch } from "@ncs/web-legos"

import { ShipmentDetailModal, ShipShipmentModal } from "./components"

export interface PartOrderDetailShipmentsProps {
	partOrderId: string
	partOrder: PartOrder | null
	paymentNotComplete: boolean
}

export interface ShipmentsByLocation {
	[locationId: string]: {
		location: {
			id: string
			code: string
			name: string
		}
		shipments: PartOrderShipment[]
	}
}

export const PartOrderDetailShipments: FC<PartOrderDetailShipmentsProps> = ({
	partOrderId,
	partOrder,
	paymentNotComplete,
}) => {
	const screenIsXs = useScreenSizeMatch("xs")
	const [shipmentsRaw, shipmentsLoading] = usePartOrderShipments({
		params: {
			partOrder: partOrderId,
			allShipments: true,
		},
	})

	const [showAll, setShowAll] = useState(false)
	const [shipmentForDetail, setShipmentForDetail] = useState<PartOrderShipment | null>(null)

	const [shipmentIdForShipping, setShipmentIdForShipping] = useState<string | null>(null)
	const shipmentForShipping = useMemo(() => {
		return shipmentsRaw?.find((s) => s.shipmentId.toString() === shipmentIdForShipping) ?? null
	}, [shipmentsRaw, shipmentIdForShipping])

	const shipmentsByLocation = useMemo(() => {
		const result: ShipmentsByLocation = {}
		const shipments = shipmentsRaw ?? []

		shipments.forEach((s) => {
			result[s.locationId] = {
				location: {
					id: s.locationId,
					code: s.locationCode,
					name: s.locationDescription,
				},
				shipments: [...(result[s.locationId]?.shipments ?? []), s],
			}
		})

		Object.values(result).forEach((l) => {
			l.shipments.sort((a, b) => (a.createdOn > b.createdOn ? -1 : 1))
		})

		return result
	}, [shipmentsRaw])

	const alreadyShippedCount = (shipmentsRaw ?? []).reduce(
		(total, shipment) => total + (shipment.shipped ? 1 : 0),
		0
	)

	if (!shipmentsRaw?.length || shipmentsLoading) {
		return null
	}

	return (
		<>
			<Heading icon="box-check" variant="h2">
				Shipments
			</Heading>

			<Box mt={1} ml={1} pl={1.5} pb={0.25}>
				{Object.values(shipmentsByLocation)
					.sort((a, b) => (a.location.name > b.location.name ? 1 : -1))
					.map(({ location, shipments }) => {
						const pending: PartOrderShipment[] = []
						const shipped: PartOrderShipment[] = []

						shipments.forEach((s) => {
							if (!s.shipped) {
								pending.push(s)
							} else {
								shipped.push(s)
							}
						})

						if (!pending.length && !showAll) {
							return null
						}

						return (
							<Box
								key={location.id}
								mb={1.5}
								css={css`
									border-top: 1px solid #eee;
									padding-top: 1rem;
									&:first-of-type {
										border-top-width: 0;
										padding-top: 0;
									}
								`}
							>
								<Heading variant="h4" mb={1}>
									({location.code}) {location.name}
								</Heading>

								{pending.map((s) => {
									return (
										<Box
											key={s.shipmentId}
											display="flex"
											flexWrap="wrap"
											gap={1}
											alignItems="center"
											mb={0.75}
											xsProps={{
												flexDirection: "column",
												alignItems: "flex-start",
											}}
										>
											<Button
												variant="primary-cta"
												onClick={() =>
													setShipmentIdForShipping(
														s.shipmentId.toString()
													)
												}
												fillContainer={screenIsXs}
												disabled={paymentNotComplete}
											>
												Configure &amp; Send
											</Button>
											<Paragraph small color="secondary">
												Status: <strong>Pending Shipment</strong>
												{!!s.pickedToBin && (
													<>
														<Pipe />
														Bin: {s.pickedToBin}
													</>
												)}
												<Pipe />
												Shipment # {s.shipmentId}
												<Pipe />
												{s.lines.length} line item
												{s.lines.length !== 1 && "s"}
											</Paragraph>
										</Box>
									)
								})}

								{showAll &&
									shipped.map((s) => {
										return (
											<Box
												key={s.shipmentId}
												display="flex"
												flexWrap="wrap"
												gap={1}
												alignItems="center"
												mb={0.5}
												xsProps={{
													flexDirection: "column",
													alignItems: "flex-start",
												}}
											>
												<Button
													variant="secondary-cta"
													onClick={() => setShipmentForDetail(s)}
													fillContainer={screenIsXs}
												>
													Carrier Tracking
												</Button>
												<Paragraph small color="secondary">
													Status: <strong>Shipped</strong>
													{s.inTransit?.shipDate ?
														<>
															<Pipe />
															Sent{" "}
															{formatDate(s.inTransit.shipDate, {
																formatInUtc: true,
															})}
														</>
													:	""}
													<Pipe />
													Shipment # {s.shipmentId}
													<Pipe />
													{s.lines.length} line item
													{s.lines.length !== 1 && "s"}
												</Paragraph>
											</Box>
										)
									})}
							</Box>
						)
					})}
			</Box>

			{!showAll && alreadyShippedCount > 0 && (
				<Box
					display="flex"
					justifyContent="center"
					mt={shipmentsRaw.every((s) => s.shipped) && !screenIsXs ? -2 : undefined}
				>
					<Button icon="angle-down" onClick={() => setShowAll(true)}>
						Show all shipments ({shipmentsRaw.length})
					</Button>
				</Box>
			)}

			<Divider my={2} />

			{!!shipmentForDetail && (
				<ShipmentDetailModal
					onClose={() => setShipmentForDetail(null)}
					shipment={shipmentForDetail}
					partOrderId={partOrderId}
				/>
			)}
			{!!shipmentForShipping && !!partOrder && (
				<ShipShipmentModal
					partOrder={partOrder}
					shipment={shipmentForShipping}
					onClose={() => setShipmentIdForShipping(null)}
				/>
			)}
		</>
	)
}

import { Dispatch, ReactElement, SetStateAction, useMemo } from "react"

import { Select } from "@ncs/web-legos"

interface UnhandledQueryParam {
	unhandled: true | null
}

export interface UnhandledQueryFilterProps<QueryParamState extends UnhandledQueryParam> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

/**
 * Can only send true to activate the filter, or nothing.
 */
export const UnhandledQueryFilter = <QueryParamState extends UnhandledQueryParam>({
	queryParamState,
	setQueryParamState,
}: UnhandledQueryFilterProps<QueryParamState>): ReactElement => {
	const value = useMemo(() => {
		if (queryParamState.unhandled === true) {
			return "true"
		}

		return null
	}, [queryParamState.unhandled])

	const handleChange = (newValue: string | null) => {
		setQueryParamState((prev) => ({
			...prev,
			unhandled: newValue === "true" ? true : null,
		}))
	}

	return (
		<Select
			value={value}
			onChange={handleChange}
			label="Request status"
			options={[
				{
					text: "Show unhandled only",
					value: "true",
				},
			]}
			fillContainer
			disableNoSelectionOption={false}
		/>
	)
}

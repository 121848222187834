import React from "react"
import PropTypes from "prop-types"
import { SearchSelector, withCallApi } from "@ncs/bricks/components"
import { listCustomers } from "../../redux/services/customers"

class CustomerSelector extends React.Component {
	handleGetSuggestions = (search) => {
		// note: SearchSelector handles debouncing for us
		return this.props.callApi(
			listCustomers({
				search,
				fields: "_id,name,customer_number,address_2,city,state,is_fst_active",
				pageSize: 15,
				service: this.props.limitToServiceableCustomers ? true : undefined,
				account_active: this.props.limitToActiveCustomers ? true : undefined,
				is_service_customer:
					this.props.limitToNonServiceCustomer ? false
					: this.props.limitToServiceCustomer ? true
					: undefined,
				is_warehouse: this.props.limitToWarehouses ? true : undefined,
				show_kbm_managed:
					this.props.showKBMManaged === true ? true
					: this.props.showKBMManaged === false ? false
					: undefined,
				show_not_kbm_managed:
					this.props.showNotKBMManaged === true ? true
					: this.props.showKBMManaged === false ? false
					: undefined,
				is_internal: false,
				exclude_territory: this.props.excludeBillTo === true ? 1 : undefined,
				...this.props.getQueryParameters(),
			})
		)
	}

	handleGetSuggestionValue = (customer) => {
		return `(${customer.customer_number}) ${customer.name}${
			customer.is_fst_active ? "" : " (Inactive)"
		}`
	}

	handleSuggestionSelected = (event, { suggestion }) => {
		this.props.onCustomerSelected(suggestion)
	}

	render = () => (
		<SearchSelector
			label={this.props.title || "Customer"}
			placeholder="Search for a customer"
			getSuggestions={this.handleGetSuggestions}
			getSuggestionValue={this.handleGetSuggestionValue}
			onSuggestionSelected={this.handleSuggestionSelected}
			{...this.props}
		/>
	)
}

CustomerSelector.defaultProps = {
	limitToServiceableCustomers: true,
	limitToActiveCustomers: true,
	limitToNonServiceCustomer: false,
	limitToServiceCustomer: false,
	showKBMManaged: true,
	showNotKBMManaged: false,
	limitToWarehouses: false,
	excludeBillTo: false,
	isInternal: false,
	getQueryParameters: () => ({}),
}

CustomerSelector.propTypes = {
	callApi: PropTypes.func.isRequired,
	onCustomerSelected: PropTypes.func.isRequired,
	title: PropTypes.string,
	limitToServiceableCustomers: PropTypes.bool,
	limitToActiveCustomers: PropTypes.bool,
	showKBMManaged: PropTypes.bool,
	showKBMNotManaged: PropTypes.bool,
	getQueryParameters: PropTypes.func,
	isInternal: PropTypes.bool,
}

export default withCallApi(CustomerSelector)

import { FC, useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { Column } from "react-table"
import { z } from "zod"

import {
	APPLICATION,
	ContractUnitCategory,
	makeApiErrorMessage,
	NewOtherContractUnitCategory,
	SpentContractCredit,
	useCreateSpentCredit,
	useDeleteSpentCredit,
	useSpentContractCredits,
	useUserCanUse,
} from "@ncs/ncs-api"
import { formatCurrency, formatDate } from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	Box,
	Button,
	ConfirmationModal,
	ConfirmationModalConfig,
	DateInputFormField,
	Divider,
	ExtendableModalProps,
	Heading,
	IconButton,
	Label,
	Modal,
	NumericInputFormField,
	Table,
	TextInputFormField,
	useToast,
} from "@ncs/web-legos"

import { stringifyContractUnits } from "../contract-utils"

export interface CreditUsageModalProps extends ExtendableModalProps {
	creditId: string
	initialAmount: number
	isPerSite: boolean
	remainingCreditTotal: number | null | undefined
	units: (ContractUnitCategory | NewOtherContractUnitCategory)[]
}

const columns: Column<SpentContractCredit>[] = [
	{
		Header: "Transaction date",
		accessor: ({ createdOn }) => formatDate(createdOn),
	},
	{
		Header: "Logged by",
		accessor: "creator",
	},
	{
		Header: "Description",
		accessor: "description",
	},
	{
		Header: "Amount",
		accessor: (original) => formatCurrency(original.amount),
	},
]

const SpentCreditFormSchema = z.object({
	description: z.string().min(1, "Required").max(1000),
	amount: z.number().min(0),
	date: z.string().min(1, "Required"),
})

type SpentCreditForm = z.infer<typeof SpentCreditFormSchema>

const spentCreditFormDefaults: SpentCreditForm = {
	description: "",
	amount: 0,
	date: "",
}

export const CreditUsageModal: FC<CreditUsageModalProps> = ({
	creditId,
	initialAmount,
	isPerSite,
	remainingCreditTotal,
	units,
	...rest
}) => {
	const canEdit = useUserCanUse(APPLICATION.ContractCreator)
	const { makeSuccessToast, makeErrorToast } = useToast()
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)
	const [showForm, setShowForm] = useState(false)
	const [isCreating, setIsCreating] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const {
		control,
		handleSubmit,
		reset: formReset,
		formState: { submitCount, isValid },
	} = useForm<SpentCreditForm>({
		resolver: zodResolver(SpentCreditFormSchema),
		defaultValues: spentCreditFormDefaults,
	})

	const [spentCredits, spentCreditsLoading] = useSpentContractCredits(creditId)

	const createSpentCredit = useCreateSpentCredit()
	const deleteSpentCredit = useDeleteSpentCredit()

	const handleCreate = async (formData: SpentCreditForm) => {
		try {
			setIsCreating(true)
			await createSpentCredit({
				credit: creditId,
				transactionDate: formData.date,
				description: formData.description,
				amount: formData.amount,
			})
			makeSuccessToast("Transaction added")
			reset()
		} catch (e) {
			setErrorText(makeApiErrorMessage(e))
		} finally {
			setIsCreating(false)
		}
	}

	const handleDelete = async (id: string) => {
		try {
			await deleteSpentCredit({ body: { history: id } })
			makeSuccessToast("Transaction deleted")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		}
	}

	const reset = () => {
		formReset(undefined, {
			keepIsSubmitted: false,
		})
		setConfirmationConfig(null)
		setShowForm(false)
		setIsCreating(false)
		setErrorText(null)
	}

	return (
		<Modal
			title="Credit Usage"
			titleDetail={`for ${stringifyContractUnits(units)}`}
			errorText={errorText}
			onOpen={reset}
			maxWidth="md"
			{...rest}
		>
			<Box mb={2} display="flex" columnGap={3}>
				<div>
					<Label>Initial credit amount</Label>
					<Heading>
						{formatCurrency(initialAmount)}{" "}
						{isPerSite ? "(per site)" : "(across all sites)"}
					</Heading>
				</div>
				{!isPerSite && remainingCreditTotal != null && (
					<div>
						<Label>Remaining amount</Label>
						<Heading>{formatCurrency(remainingCreditTotal)}</Heading>
					</div>
				)}
			</Box>

			<Table
				data={spentCredits ?? []}
				isLoading={spentCreditsLoading}
				columns={columns}
				rowMenu={
					canEdit ?
						[
							{
								label: "Delete",
								iconName: "trash-alt",
								onClick: (row) =>
									setConfirmationConfig({
										title: "Delete Transaction",
										message: "Delete this transaction?",
										onConfirm: () => handleDelete(row.original.id),
									}),
							},
						]
					:	undefined
				}
				noDataText="No transactions recorded for this contract credit"
				disableAllSorting
			/>

			<Divider />

			{canEdit &&
				(showForm ?
					<AnimatedEntrance show direction="down">
						<Box display="flex" columnGap={1}>
							<NumericInputFormField
								control={control}
								name="amount"
								label="Amount $"
								min={0}
								decimalScale={2}
								fixedDecimalScale
								emptyValueFallback={0}
							/>
							<DateInputFormField
								control={control}
								name="date"
								label="Transaction date"
							/>
						</Box>
						<TextInputFormField
							control={control}
							name="description"
							placeholder="Reference number, site name, etc..."
							returnEmptyString
							autoFocus
						/>
						<AnimatedEntrance
							show
							display="flex"
							alignItems="center"
							columnGap={0.5}
							mt={0.5}
							direction="down"
							preventOverflow
						>
							<IconButton
								icon="check"
								color="success"
								background="primary"
								onClick={handleSubmit(handleCreate)}
								isLoading={isCreating}
								disabled={submitCount > 0 && !isValid}
							/>
							<IconButton icon="times" onClick={reset} />
						</AnimatedEntrance>
					</AnimatedEntrance>
				:	<Button icon="plus" onClick={() => setShowForm(true)}>
						Record a transaction
					</Button>)}

			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</Modal>
	)
}

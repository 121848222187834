import { Dispatch, ReactElement, SetStateAction, useEffect, useState } from "react"

import { InventoryLocation } from "@ncs/ncs-api"

import { usePrevious } from "../../util"
import { LocationSelector } from "../selectors"

interface LocationQueryParams {
	location: string | null
}

export interface LocationQueryFilterProps<QueryParamState extends LocationQueryParams> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

export const LocationQueryFilter = <QueryParamState extends LocationQueryParams>({
	queryParamState,
	setQueryParamState,
}: LocationQueryFilterProps<QueryParamState>): ReactElement => {
	const [selectedLocation, setSelectedLocation] = useState<InventoryLocation | null>(null)

	const handleChange = (newLocation: InventoryLocation | null) => {
		// Set local state with full Location object.
		setSelectedLocation(newLocation)
		// Set query param state with just the Location ID.
		setQueryParamState((prev) => ({
			...prev,
			location: newLocation?.id ?? null,
		}))
	}

	const prevParamStateLocation = usePrevious(queryParamState.location)
	useEffect(() => {
		// Handles the user clicking Reset button.
		if (!queryParamState.location && !!prevParamStateLocation && !!selectedLocation) {
			setSelectedLocation(null)
		}
	}, [prevParamStateLocation, queryParamState.location, selectedLocation])

	return (
		<LocationSelector
			value={selectedLocation}
			onChange={handleChange}
			initialId={queryParamState.location}
			fillContainer
		/>
	)
}

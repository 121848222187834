import {
  defaultFont,
  container,
  containerFluid,
  primaryColor,
  whiteColor,
  footerBorderColor,
} from "../../material-dashboard-pro-react"

const footerStyle = {
  block: {},
  left: {
    float: "left!important",
    display: "block"
  },
  right: {
    margin: "0",
    fontSize: "14px",
    float: "right!important",
    padding: "15px"
  },
  footer: {
    bottom: "0",
		borderTop: `1px solid ${footerBorderColor}`,
    padding: "15px 0",
    ...defaultFont,
    zIndex: 4
  },
	customerFooter: {
		bottom: "0",
		borderTop: `1px solid ${footerBorderColor}`,
		padding: "30px 0",
		backgroundColor: primaryColor[0],
		...defaultFont,
		zIndex: 4,
		flexShrink: 0,
	},
  container: {
    zIndex: 3,
    ...container,
    position: "relative"
  },
	customerContainer: {
		zIndex: 3,
		...container,
		position: "relative",
		marginTop: 30,
	},
  containerFluid: {
    zIndex: 3,
    ...containerFluid,
    position: "relative"
  },
  a: {
    color: primaryColor[0],
    textDecoration: "none",
    backgroundColor: "transparent"
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0",
    width: "auto"
  },
  whiteColor: {
    "&,&:hover,&:focus": {
      color: whiteColor
    }
	},
	menuLink: {
		color: whiteColor,
		fontWeight: 500,
	},
	menuItem: {
		marginBottom: 15,
		marginLeft: 50,
	},
	copyRights: {
		marginTop: 10,
		color: whiteColor,
		marginLeft: 50,
	},
	logo: {
		width: 200,
		marginRight: 25,
		marginBottom: 25,
	},
	address: {
		marginBottom: 10,
		color: whiteColor,
		marginRight: 50,
	},
	siteInfo: {
		alignContent: "space-between",
	},
	siteInfoContainer: {
		marginRight: 50,
		marginTop: 20,
	},
}
export default footerStyle

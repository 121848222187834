import React from "react"
import { withRouter } from "react-router-dom"
import { withCallApi } from "~/components"
import { getMaterial } from "@ncs/mortar/redux/services/marketing"
import MarketingMaterialDetail from "./MarketingMaterialDetail"

class MarketingMaterialDetailContainer extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isLoading: true,
			material: {},
		}
	}

	componentDidMount() {
		let id = this.props.match.params.id

		this.setState({ isLoading: true, material: {} })
		this.props.callApi(getMaterial(id)).then((result) => {
			this.setState({ isLoading: false, material: result.error ? {} : result.payload })
		})
	}

	handleDownload = () => {
		window.open(this.state.material.presign_url)
	}

	handleBackToList = () => {
		this.props.history.push("/marketing/materials/")
	}

	render = () => (
		<MarketingMaterialDetail
			isLoading={this.state.isLoading}
			item={this.state.material}
			onDownload={this.handleDownload}
			onBackToList={this.handleBackToList}
		/>
	)
}

export default withCallApi(withRouter(MarketingMaterialDetailContainer))

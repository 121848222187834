import { FC, useEffect, useMemo, useState } from "react"

import { Cell, Column } from "react-table"

import {
	CreateCreditMemoPost,
	Dispatch,
	makeApiErrorMessage,
	useCreateCreditMemo,
	useRebillInvoice,
	WorkOrderInvoice,
	WorkOrderInvoiceLineItem,
} from "@ncs/ncs-api"
import { extractNumber, formatCurrency, formatNumber, ObjectEntry, ValueOf } from "@ncs/ts-utils"
import {
	Box,
	Button,
	ExtendableModalProps,
	HeadingDivider,
	IconButton,
	Modal,
	Table,
	Textarea,
	ThrottledNumericInput,
	useIsSaving,
	useToast,
} from "@ncs/web-legos"

import { CreditMemoReasonSelector } from "~/components"
import { getDispatchLineDescription } from "~/util"

export interface CreateCreditMemoModalProps extends ExtendableModalProps {
	dispatch: Dispatch
	invoice: WorkOrderInvoice
	lineItems: WorkOrderInvoiceLineItem[]
}

interface LinesState {
	[id: string]: {
		id: string
		description: string
		quantity: number
		originalNetPrice: number
		modifiedNetPrice: number | null
	}
}

export const CreateCreditMemoModal: FC<CreateCreditMemoModalProps> = ({
	dispatch,
	invoice,
	lineItems,
	...rest
}) => {
	const { makeSuccessToast } = useToast()
	const [reason, setReason] = useState<string | null>(null)
	const [comments, setComments] = useState<string | null>(null)
	const [errorText, setErrorText] = useState<string | null>(null)
	const { isSaving, setSaving, endSaving } = useIsSaving()

	const createMemo = useCreateCreditMemo(invoice.id)
	const rebillInvoice = useRebillInvoice(invoice.id)

	const [lines, setLines] = useState<LinesState>(() => {
		return Object.fromEntries(
			lineItems.map((line): ObjectEntry<LinesState> => {
				return [
					line.id,
					{
						id: line.id,
						description: getDispatchLineDescription(line),
						quantity: extractNumber(line.quantity),
						originalNetPrice: extractNumber(line.netPrice),
						modifiedNetPrice: extractNumber(line.netPrice),
					},
				]
			})
		)
	})

	const handleLinePriceChange = (id: string, newPrice: number | null | undefined) => {
		setLines((prev) => ({
			...prev,
			[id]: {
				...prev[id],
				modifiedNetPrice: newPrice ?? null,
			},
		}))
	}

	const handleBulkLinePriceSet = (price?: number) => {
		setLines((prev) => {
			return Object.fromEntries(
				Object.entries(prev).map(([id, line]) => {
					return [
						id,
						{
							...line,
							modifiedNetPrice: price ?? line.originalNetPrice,
						},
					]
				})
			)
		})
	}

	const handleSave = async () => {
		if (!reason) {
			setErrorText("Reason for credit memo is required")
			return
		}
		if (!comments) {
			setErrorText("A comment is required")
			return
		}
		if (Object.values(lines).some((line) => line.modifiedNetPrice == null)) {
			setErrorText("All lines must have a price entered")
			return
		}

		try {
			setSaving()

			const isRebill = Object.values(lines).every((line) => line.modifiedNetPrice === 0)
			const items = Object.values(lines).flatMap(
				(line): CreateCreditMemoPost["items"][number][] => {
					if (
						line.modifiedNetPrice == null ||
						line.originalNetPrice === line.modifiedNetPrice
					) {
						return []
					}

					return [
						{
							id: line.id,
							netPrice: line.modifiedNetPrice,
						},
					]
				}
			)

			if (isRebill) {
				await rebillInvoice({
					comments,
					reason,
					items,
				})
				makeSuccessToast("Entire invoice rebilled")
			} else {
				await createMemo({
					comments,
					reason,
					items,
				})
				makeSuccessToast("Credit memo applied to invoice")
			}
			rest.onClose()
		} catch (e) {
			endSaving()
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const columns = useMemo((): Column<ValueOf<LinesState>>[] => {
		return [
			{
				Header: "Description",
				accessor: ({ description }) => description,
			},
			{
				Header: "Quantity",
				accessor: ({ quantity }) => formatNumber(quantity),
			},
			{
				Header: "Current price $",
				accessor: ({ originalNetPrice }) => formatCurrency(originalNetPrice),
			},
			{
				Header: "New price $",
				accessor: "modifiedNetPrice",
				Cell: ({ row: { original } }: Cell<ValueOf<LinesState>>) => {
					return (
						<ThrottledNumericInput
							value={original.modifiedNetPrice}
							onChange={(value) => handleLinePriceChange(original.id, value ?? null)}
							leading={false}
							placeholder="New price..."
							decimalScale={2}
							fixedDecimalScale
							mb={0}
						/>
					)
				},
			},
			{
				Header: "",
				id: "buttons",
				Cell: ({ row: { original } }: Cell<ValueOf<LinesState>>) => {
					return (
						<IconButton
							icon="times"
							onClick={() => handleLinePriceChange(original.id, 0)}
						/>
					)
				},
			},
		]
	}, [])

	useEffect(() => {
		setErrorText(null)
	}, [reason, comments, lines])

	return (
		<Modal
			{...rest}
			title="Credit Memo"
			maxWidth="md"
			errorText={errorText}
			rightButtons={{
				buttonText: "Create credit memo",
				isLoading: isSaving(),
				onClick: handleSave,
			}}
			closeButtonText="Cancel"
		>
			<Box maxWidth="50%" smProps={{ maxWidth: "none" }} mb={2}>
				<CreditMemoReasonSelector value={reason} onChange={setReason} />
				<Textarea
					value={comments}
					onChange={setComments}
					label="Credit memo comments"
					placeholder="Comments..."
					maxLength={10000}
				/>
			</Box>

			<HeadingDivider
				variant="h5"
				renderRight={() => (
					<Box d="flex" gap={1}>
						<Button icon="empty-set" onClick={() => handleBulkLinePriceSet(0)}>
							Credit all items
						</Button>
						<Button icon="undo" onClick={() => handleBulkLinePriceSet()}>
							Reset
						</Button>
					</Box>
				)}
			>
				Billable Line Items
			</HeadingDivider>
			<Table
				data={Object.values(lines)}
				columns={columns}
				rowVerticalAlign="middle"
				disableAllSorting
			/>
		</Modal>
	)
}

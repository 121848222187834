import { FC, useState } from "react"

import {
	EquipmentManufacturer,
	makeApiErrorMessage,
	UpdateEquipmentManufacturerPatch,
	useCreateEquipmentManufacturer,
	useUpdateEquipmentManufacturer,
} from "@ncs/ncs-api"
import {
	Checkbox,
	ExtendableModalProps,
	Modal,
	TextInput,
	useIsSaving,
	useToast,
} from "@ncs/web-legos"

export interface EditManufacturerModalProps extends ExtendableModalProps {
	manufacturer: EquipmentManufacturer | null
}

export const EditManufacturerModal: FC<EditManufacturerModalProps> = ({
	manufacturer,
	...rest
}) => {
	const { makeSuccessToast } = useToast()
	const { isSaving, setSaving, endSaving } = useIsSaving()
	const [errorText, setErrorText] = useState<string | null>(null)

	const [name, setName] = useState(manufacturer?.name ?? null)
	const [isActive, setIsActive] = useState(manufacturer?.isActive ?? true)

	const create = useCreateEquipmentManufacturer()
	const update = useUpdateEquipmentManufacturer()

	const handleSave = async () => {
		if (!name) {
			setErrorText("Name is required")
			return
		}

		try {
			setSaving()

			const data: UpdateEquipmentManufacturerPatch = {
				name,
				code: name,
				isActive,
			}

			if (isEdit) {
				await update({
					id: manufacturer.id,
					updates: data,
				})
				makeSuccessToast("Manufacturer updated")
			} else {
				await create(data)
				makeSuccessToast("Manufacturer created")
			}
			rest.onClose()
		} catch (e) {
			endSaving()
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const isEdit = !!manufacturer

	return (
		<Modal
			{...rest}
			title={isEdit ? "Edit Manufacturer" : "New Manufacturer"}
			errorText={errorText}
			rightButtons={{
				buttonText: isEdit ? "Save Changes" : "Create",
				isLoading: isSaving(),
				onClick: handleSave,
			}}
		>
			<TextInput value={name} onChange={setName} label="Manufacturer name" />
			<Checkbox value={isActive} onChange={setIsActive} label="Active (default)" />
		</Modal>
	)
}

import React from "react"
import moment from "moment"
import { InputAdornment } from "@material-ui/core"
import { ChevronLeft, ChevronRight } from "@material-ui/icons"

import { Input, ButtonWithIcon } from "../../components"

import withFilter from "./withDynamicTableFilter"

class MonthFilter extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			value: moment().startOf("month").format("MMM YYYY"),
		}
	}

	handleIncrease = () => {
		let newDate = moment(this.state.value).startOf("month").add(1, "months")
		this.setState({ value: newDate.format("MMM YYYY") }, () =>
			this.props.onChange(
				newDate.format("YYYY-MM-DD"),
				`month=${newDate.format("YYYY-MM-DD")}`
			)
		)
	}

	handleDecrease = () => {
		let newDate = moment(this.state.value).startOf("month").add(-1, "months")
		this.setState({ value: newDate.format("MMM YYYY") }, () =>
			this.props.onChange(
				newDate.format("YYYY-MM-DD"),
				`month=${newDate.format("YYYY-MM-DD")}`
			)
		)
	}

	render() {
		let editorProps = {
			startAdornment: (
				<InputAdornment position="start">
					<ButtonWithIcon
						justIcon
						round
						size="sm"
						onClick={this.handleDecrease}
						icon={<ChevronLeft />}
					/>
				</InputAdornment>
			),
			endAdornment: (
				<InputAdornment position="end">
					<ButtonWithIcon
						justIcon
						round
						size="sm"
						onClick={this.handleIncrease}
						icon={<ChevronRight />}
					/>
				</InputAdornment>
			),
			disabled: true,
		}
		return <Input value={this.state.value} inputProps={editorProps} />
	}
}

export default withFilter("month")(MonthFilter)

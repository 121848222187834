import React from "react"
import { withCallApi } from "~/components"
import { patchTicketType } from "@ncs/mortar/redux/services/ticketing"

import { GridContainer, GridItem, ButtonWithIcon, UserSelector, Dialog } from "~/components"

import Edit from "@material-ui/icons/Edit"
class DefaultAssignToEditor extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isEditing: false,
			selectedUser: null,
		}
	}

	editDefaultAssignedTo = () => {
		let data = {
			default_assigned_to_id: this.state.selectedUser ? this.state.selectedUser._id : null,
		}
		return this.props.callApi(patchTicketType(this.props.type, data)).then(() => {
			this.props.onSaveAssignTo()
			this.setState({ isEditing: false })
		})
	}

	handleStartDeleting = () => {
		this.setState({ isEditing: true })
	}

	handleCancelEdit = () => {
		this.setState({ isEditing: false })
	}

	handleUserSelected = (selectedUser) => {
		this.setState({ selectedUser })
	}

	render = () => {
		const { isSaving, user } = this.props
		return (
			<React.Fragment>
				<ButtonWithIcon
					icon={<Edit color="action" />}
					round
					color="warning"
					justIcon
					onClick={this.handleStartDeleting}
				/>
				<Dialog
					warning="true"
					show={this.state.isEditing}
					title={"Edit Default Assigned To"}
					confirmBtnIcon={<Edit />}
					confirmBtnText="Confirm"
					confirmBtnColor="success"
					onConfirm={this.editDefaultAssignedTo}
					isConfirmationAsync={true}
					cancelBtnText={"Cancel"}
					onCancel={this.handleCancelEdit}
					successTitle="Success"
					successContent="Default Assigned To Edited."
					onSuccessConfirm={this.handleCancelEdit}
					isSaving={isSaving}
				>
					<GridContainer>
						<GridItem xs={12}>
							<UserSelector
								labelText="Default Assigned To"
								defaultValue={user}
								onUserSelected={this.handleUserSelected}
							/>
						</GridItem>
					</GridContainer>
				</Dialog>
			</React.Fragment>
		)
	}
}

export default withCallApi(DefaultAssignToEditor)

import { FC } from "react"

import { BinLocation, useLocationBins } from "@ncs/ncs-api"

import { useInitialId } from "../../util"
import { ExtendableSearchableSelectProps, SearchableSelect } from "../inputs"

export interface LocationBinSelectorProps extends ExtendableSearchableSelectProps<BinLocation> {
	locationId: string | null
	initialId?: string | null
	excludeInactive?: boolean
}

export const LocationBinSelector: FC<LocationBinSelectorProps> = ({
	initialId,
	locationId,
	value,
	onChange,
	excludeInactive = false,
	...rest
}) => {
	const [bins, isLoading] = useLocationBins(locationId, {
		params: {
			isActive: excludeInactive ? true : undefined,
		},
	})

	useInitialId({
		initialId,
		currentValue: value,
		onChange,
		findInitialOption: () => {
			return (bins ?? []).find((bin) => bin.id === initialId)
		},
	})

	return (
		<SearchableSelect
			label="Location bin"
			placeholder={locationId ? "Search for a bin..." : "Choose location"}
			disabled={!locationId}
			{...rest}
			isLoading={isLoading}
			options={bins ?? []}
			value={value}
			onItemSelect={onChange}
			getOptionLabel={(option) => option.code}
			disableOptionsAggregation
			useSearchIcon
		/>
	)
}

import { FC, useMemo } from "react"

import { ApplicationAssignment, useApplicationAssignments } from "@ncs/ncs-api"

import { ExtendableSelectProps, Select } from "../inputs"

export interface ApplicationGroupSelectorProps
	extends ExtendableSelectProps<ApplicationAssignment> {
	includeNonDefaults?: boolean
}

export const ApplicationGroupSelector: FC<ApplicationGroupSelectorProps> = ({
	includeNonDefaults,
	...rest
}) => {
	const [apps, appsLoading] = useApplicationAssignments(includeNonDefaults ? undefined : true)

	const options = useMemo(() => {
		return (apps ?? []).sort((a, b) => (a.description > b.description ? 1 : -1))
	}, [apps])

	return (
		<Select
			label="Application group"
			disabled={appsLoading}
			disableNoSelectionOption={false}
			{...rest}
			options={options}
			valueAccessor="id"
			textAccessor="description"
		/>
	)
}

import React from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import callApi from "@ncs/bricks/redux/services/callApi"
import { BaseSelectFilter, withDynamicTableFilter } from "@ncs/bricks/components"
import { loadLookups, LOOKUPS } from "../../redux/services/lookups"

// custom components

class TicketSubtypeFilter extends React.PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			subTypes: [],
		}
	}

	componentDidMount() {
		this.props.loadLookups([LOOKUPS.TicketSubtypes], this.filterTicketSubtype)
	}

	// filter subytpes whenever the type changes
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (
			this.getTicketTypeFromReportState(prevProps) !==
			this.getTicketTypeFromReportState(this.props)
		) {
			this.filterTicketSubtype()
		}
	}

	getTicketTypeFromReportState = (props) => {
		return (
			((props.reportState || {}).filtered || []).find((x) => x.id === "ticket_type") || {}
		).value
	}

	filterTicketSubtype = () => {
		let type = this.getTicketTypeFromReportState(this.props)
		let subTypes = this.props.subTypes.filter((x) => x.type_id === Number(type))
		this.setState({
			subTypes,
		})

		// if the currently selected subtype is not in the results, erase the selected subtype
		if (this.props.value && !subTypes.some((t) => t.id.toString() === this.props.value)) {
			this.props.onChange("")
		}
	}

	render() {
		return (
			<BaseSelectFilter
				id="ticketSubtype"
				labelText="Subtype"
				options={[
					{
						id: "",
						description: "All Subtypes",
					},
					...this.state.subTypes,
				]}
				valueAccessor="id"
				textAccessor={(x) => x.description}
				{...this.props}
			/>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		subTypes: state.lookups.ticketSubtypes,
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			callApi,
			loadLookups,
		},
		dispatch
	)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withDynamicTableFilter("subtype")(TicketSubtypeFilter))

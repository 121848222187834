import { FC, useState } from "react"

import {
	InventoryLocation,
	makeApiErrorMessage,
	PartOrderLineItem,
	StockStatus,
	useCancelDropShip,
	useLineItemStockInfo,
} from "@ncs/ncs-api"
import { extractNumber } from "@ncs/ts-utils"
import {
	Box,
	ExtendableModalProps,
	Icon,
	LocationSelector,
	Modal,
	Paragraph,
	StatusBlip,
	useToast,
} from "@ncs/web-legos"

export interface CancelDropShipModalProps extends ExtendableModalProps {
	lineItem: PartOrderLineItem
}

export const CancelDropShipModal: FC<CancelDropShipModalProps> = ({ lineItem, ...rest }) => {
	const { makeSuccessToast } = useToast()
	const [newDc, setNewDc] = useState<InventoryLocation | null>(null)
	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const [stockInfo] = useLineItemStockInfo(lineItem.id, {
		quantity: extractNumber(lineItem.quantity), // Just passing the full quantity here
		location: null,
	})
	const cancelDropShip = useCancelDropShip(lineItem.id)

	const handleSave = async () => {
		try {
			if (!newDc) {
				throw new Error("Must choose a DC to route line item to")
			}

			setIsSaving(true)
			await cancelDropShip({
				location: newDc.id,
			})
			makeSuccessToast("Drop ship canceled")
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	return (
		<Modal
			{...rest}
			title="Cancel Drop Ship"
			errorText={errorText}
			rightButtons={{
				buttonText: "Save",
				onClick: handleSave,
				isLoading: isSaving,
			}}
		>
			<Paragraph mb={1}>
				After canceling the drop ship, where should we try to route this line item to?
			</Paragraph>
			<LocationSelector
				label={null}
				value={newDc}
				onChange={setNewDc}
				warehousesOnly
				renderOption={(option) => {
					const locationInfo = stockInfo?.locations.find(
						(l) => l.locationId === option.id
					)

					return (
						<div>
							<Paragraph>
								({option.locationCode}) {option.description}
							</Paragraph>
							{locationInfo && (
								<>
									{locationInfo.fullAvailable ?
										<Box display="flex" alignItems="center" gap={0.5}>
											<StatusBlip status={StockStatus.All} />
											<Paragraph small secondary>
												Quantity in stock
											</Paragraph>
										</Box>
									:	<Box display="flex" alignItems="center" gap={0.5}>
											<Icon icon="times" color="gray" />
											<Paragraph small secondary>
												Not in stock
											</Paragraph>
										</Box>
									}
								</>
							)}
						</div>
					)
				}}
			/>
		</Modal>
	)
}

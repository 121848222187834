import React, { useState } from "react"
import withStyles from "@material-ui/core/styles/withStyles"
import { connect } from "react-redux"
import { compose } from "redux"
import { Link } from "react-router-dom"
import { Alert, AlertTitle } from "@material-ui/lab"
import InputAdornment from "@material-ui/core/InputAdornment"
import { Typography } from "@material-ui/core"

import loginPageStyle from "@ncs/bricks/assets/jss/material-dashboard-pro-react/views/loginPageStyle"
import { authErrors } from "@ncs/bricks/redux/selectors"
import { login, requestPasswordReset } from "@ncs/bricks/redux/services/auth"
import { Input, Button, Card, CardBody, CardFooter, withCallApi } from "~/components"

// icons
import Email from "@material-ui/icons/Email"

const ForgotPasswordForm = ({ classes, callApi }) => {
	const [email, setEmail] = useState("")
	const [errors, setErrors] = useState({})
	const [loading, setLoading] = useState(false)
	const [success, setSuccess] = useState(false)

	const handleSubmit = async (event) => {
		event.preventDefault()

		setLoading(true)
		setErrors({})

		callApi(requestPasswordReset(email))
			.then(({ error, payload }) => {
				if (error === true) {
					throw payload
				}

				if (payload !== undefined) {
					throw new Error("Unexpected payload", payload)
				}

				setSuccess(true)
			})
			.catch((payload) => {
				setErrors(payload.response ?? { boomtime: true })
			})
			.finally(() => {
				setLoading(false)
			})
	}

	if (success) {
		return (
			<Card>
				<CardBody>
					<Alert variant="filled" severity="success">
						Reset password email was sent
					</Alert>
					<Typography>
						Please check your email in a couple of minutes for more detail.
					</Typography>
				</CardBody>
				<CardFooter className={classes.justifyContentCenter}>
					<Link to="/login">Back to login</Link>
				</CardFooter>
			</Card>
		)
	}

	return (
		<form onSubmit={handleSubmit}>
			{errors?.boomtime === true && (
				<Alert variant="filled" severity="error">
					<AlertTitle>An error occurred when processing your request</AlertTitle>
					Please try again later
				</Alert>
			)}
			<p>
				Enter the email address that you used to register. We'll send you an email with a
				link to reset your password.
			</p>
			<Card>
				<CardBody>
					<Input
						labelText="Email..."
						id="email"
						value={email}
						formControlProps={{ fullWidth: true }}
						error={errors?.email?.length > 0}
						disabled={loading}
						helperText={errors?.email?.join(" ")}
						inputProps={{
							onChange: (e) => {
								setEmail(e.target.value)
							},
							endAdornment: (
								<InputAdornment position="end">
									<Email className={classes.inputAdornmentIcon} />
								</InputAdornment>
							),
						}}
					/>
				</CardBody>
				<CardFooter className={classes.justifyContentCenter}>
					<Button
						color="rose"
						simple
						size="lg"
						block
						type="submit"
						onClick={handleSubmit}
						loading={loading}
						disabled={loading || email.length === 0}
					>
						Send reset password link
					</Button>
					<Link to="/login">Back to login</Link>
				</CardFooter>
			</Card>
		</form>
	)
}

const mapStateToProps = (state) => ({
	errors: authErrors(state),
})

const mapDispatchToProps = (dispatch) => ({
	submitLogin: (email, password) => {
		dispatch(login(email, password))
	},
})

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(loginPageStyle),
	withCallApi
)(ForgotPasswordForm)

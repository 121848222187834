import React, { useMemo } from "react"
import classNames from "classnames"
import {
	makeStyles,
	Button as MaterialUIButton,
	ButtonProps as MaterialUIButtonProps,
} from "@material-ui/core"
import { MaterialDashboardProColors } from "../../util/types"

import styles from "../../assets/jss/material-dashboard-pro-react/components/buttonStyle"

const useStyles = makeStyles(styles)

export interface ButtonProps extends Omit<MaterialUIButtonProps, "color" | "size"> {
	color?:
		| Exclude<MaterialDashboardProColors, "gray">
		| "white"
		| "twitter"
		| "facebook"
		| "google"
		| "linkedin"
		| "pinterest"
		| "youtube"
		| "tumblr"
		| "github"
		| "behance"
		| "dribbble"
		| "reddit"
		| "transparent"
	size?: "sm" | "lg"
	simple?: boolean
	round?: boolean
	fullWidth?: boolean
	disabled?: boolean
	block?: boolean
	link?: boolean
	justIcon?: boolean
	className?: string
	muiClasses?: MaterialUIButtonProps["classes"]
	boxShadowHover?: boolean
	content?: string
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	(
		{
			color,
			round,
			fullWidth,
			disabled,
			simple,
			size,
			block,
			link,
			justIcon,
			className,
			muiClasses,
			boxShadowHover = true,
			content,
			children,
			...rest
		},
		ref
	) => {
		const classes = useStyles()

		const btnClasses = useMemo(
			() =>
				classNames(className, {
					[classes.button]: true,
					[classes[size as Exclude<ButtonProps["size"], undefined>]]: size,
					[classes[color as Exclude<ButtonProps["color"], undefined>]]: color,
					[classes.round]: round,
					[classes.fullWidth]: fullWidth,
					[classes.disabled]: disabled,
					[classes.simple]: simple,
					[classes.boxShadowHover]: boxShadowHover && !simple,
					[classes.block]: block,
					[classes.link]: link,
					[classes.justIcon]: justIcon,
				}),
			[
				block,
				classes,
				className,
				color,
				disabled,
				fullWidth,
				justIcon,
				link,
				round,
				simple,
				size,
				boxShadowHover,
			]
		)

		return (
			<MaterialUIButton {...rest} ref={ref} classes={muiClasses} className={btnClasses}>
				{content}
				{children}
			</MaterialUIButton>
		)
	}
)

export default Button

import React from "react"

import get from "lodash/get"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { bindActionCreators } from "redux"

import { loadLookups, LOOKUPS } from "@ncs/mortar/redux/services/lookups"
import {
	createUserSalesAssignment,
	getUserLocations,
	getUserProfile,
	getUserSalesInfo,
	patchUserLocations,
	patchUserProfile,
	updateUserSalesAssignment,
} from "@ncs/mortar/redux/services/users"
import { APPLICATIONS } from "@ncs/mortar/util/constants"
import { meetsAppRestriction } from "@ncs/bricks/redux/selectors/auth"
import { requestPasswordReset } from "@ncs/bricks/redux/services/auth"
import callApi from "@ncs/bricks/redux/services/callApi"
import FormValidator from "@ncs/bricks/util/formValidator"

import UserDetails from "./UserDetails"

class UserDetailsContainer extends React.Component {
	constructor(props) {
		super(props)

		this.validations = {
			employee_number: { stateName: "employeeNumber", readOnly: this.props.isNotUserAdmin },
			first_name: { stateName: "firstName", isRequired: true, maxLength: 50 },
			last_name: { stateName: "lastName", isRequired: true, maxLength: 50 },
			email: {
				stateName: "email",
				type: "email",
				isRequired: true,
				maxLength: 255,
				readOnly: this.props.isNotUserAdmin,
			},
			mobile: { stateName: "mobile", maxLength: 20 },
			last_login: { stateName: "lastLogin", type: "datetime", readOnly: true },

			verisae_username: { stateName: "verisaeUsername", maxLength: 255 },
			effective_date: {
				stateName: "effectiveDate",
				type: "date",
				readOnly: this.props.isNotUserAdmin,
			},
			end_date: { stateName: "endDate", type: "date", readOnly: this.props.isNotUserAdmin },

			// TODO: allowed applications and report applications, read-only collections
		}

		this.formValidator = new FormValidator(this, this.validations)

		this.state = {
			user: {},
			activeTabIndex: 0,
			isLoading: true,
			isLoadingLocations: false,
			isLoadingSales: false,
			isEditing: false,
			isSaving: false,
			displayActionSuccess: false,
			displayActionFailure: false,
			applicationSelected: 0,
			reportSelected: 0,
			disablePasswordReset: false,
			isResetting: false,
			locations: [],
			showAddLocation: false,
			newUserTerritories: [],
			billToUsed: false,
			customerSelected: null,
			newUserCustomers: [],
			availableTerritories: [],
			selectedDefaultTerritory: null,
			salesAssignment: null,
			salesCustomers: [],
			salesTerritories: [],
			salesCustomerSelected: null,
			addSalesCustomer: false,
			showRemoveCustomer: false,
			removeCustomerSelected: null,
			...this.formValidator.getInitialFormState(),
		}
	}

	componentDidMount = () => {
		this.fetchUserProfile(this.getUserId(this.props))
		this.props.loadLookups(LOOKUPS.DefaultApplications)
		this.props.loadLookups(LOOKUPS.DefaultReports)
		this.props.loadLookups(LOOKUPS.Territories)
		this.fetchUserLocations(this.getUserId(this.props))
		this.fetchSalesLocations(this.getUserId(this.props))
	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		const nextId = this.getUserId(nextProps)
		if (this.getUserId(this.props) === nextId) return

		this.resetState()
		this.fetchUserProfile(nextId)
	}

	getUserId = (props) => {
		// for My Profile view
		if (props.userId) return props.userId

		return props.match.params.id
	}

	resetState = () => {
		this.setState(this.getDefaultState())
	}

	fetchSalesLocations = (userId) => {
		this.setState({ isLoadingSales: true })
		this.props
			.callApi(getUserSalesInfo(userId))
			.then(({ payload: assignments }) => {
				if (assignments) {
					this.setState({
						salesCustomers: assignments[0].customers,
						salesTerritories: assignments[0].territories,
						salesAssignment: assignments[0],
					})
				}
			})
			.catch((error) => {
				this.setState({ isLoadingSales: false })
				this.errorFetchingUserProfile(error)
			})
	}

	fetchUserLocations = (userId) => {
		this.setState({ isLoadingLocations: true })
		this.props
			.callApi(getUserLocations(userId))
			.then(({ payload: locations }) => {
				this.setState({ locations, isLoadingLocations: false })
			})
			.catch((error) => {
				this.setState({ isLoadingLocations: false })
				this.errorFetchingUserProfile(error)
			})
	}

	fetchUserProfile = (userId) => {
		this.setState({ isLoading: true })
		this.props
			.callApi(getUserProfile(userId))
			.then(({ payload: user }) => {
				this.loadStateFromUser(user, { isLoading: false, user })
			})
			.catch((error) => {
				this.errorFetchingUserProfile(error)
			})
	}

	errorFetchingUserProfile = (error) => {
		console.error(error)
	}

	loadStateFromUser = (user, additionalState) => {
		let userProfile = typeof user !== "undefined" ? user : this.state.user
		this.setState({ selectedDefaultTerritory: userProfile.default_territory?._id })
		this.formValidator.setFormStateFromObject(userProfile, additionalState)
	}

	handleCancelSalesCustomer = () => {
		this.setState({
			addSalesCustomer: !this.state.addSalesCustomer,
			salesCustomerSelected: null,
		})
	}

	handleShowAddCustomer = () => {
		this.setState({ addSalesCustomer: true, salesCustomerSelected: null })
	}

	handleAddSalesCustomer = () => {
		if (!this.state.salesCustomerSelected) {
			return
		}
		this.setState({ isSaving: true })
		let params = {
			user: this.getUserId(this.props),
			sales_type: 1, // Eventually this will be selectable, but just need chem for now
		}
		if (this.state.salesAssignment) {
			// means this is an update
			let currentCustomerIds = this.state.salesCustomers.map((x) => x.id)
			params.customers = [this.state.salesCustomerSelected._id, ...currentCustomerIds]
			params.territories = []
			this.props
				.callApi(updateUserSalesAssignment(this.state.salesAssignment.id, params))
				.then((response) => {
					this.setState({ isSaving: false })
					this.handleCancelSalesCustomer()
					this.fetchSalesLocations(this.getUserId(this.props))
				})
				.catch((error) => {
					this.setState({ isSaving: false })
					this.errorFetchingUserProfile(error)
				})
		} else {
			// means this is a create
			params.customers = [this.state.salesCustomerSelected._id]
			params.territories = []
			this.props
				.callApi(createUserSalesAssignment(params))
				.then((response) => {
					this.setState({ isSaving: false })
					this.handleCancelSalesCustomer()
					this.fetchSalesLocations(this.getUserId(this.props))
				})
				.catch((error) => {
					this.setState({ isSaving: false })
					this.errorFetchingUserProfile(error)
				})
		}
	}

	updateShowRemoveCustomer = () => {
		this.setState({
			showRemoveCustomer: !this.state.showRemoveCustomer,
			removeCustomerSelected: null,
		})
	}

	handleShowRemoveCustomer = (customerSelected) => {
		this.setState({ showRemoveCustomer: true, removeCustomerSelected: customerSelected })
	}

	handleRemoveSalesCustomer = () => {
		if (!this.state.removeCustomerSelected) {
			return
		}
		let currentCustomerIds = this.state.salesCustomers.map((x) => x.id)
		let newCustomerIds = currentCustomerIds.filter(
			(x) => this.state.removeCustomerSelected.id === x.id
		)
		this.setState({ isSaving: true })
		let params = {
			user: this.getUserId(this.props),
			sales_type: 1,
			customers: newCustomerIds,
			territories: [],
		}
		this.props
			.callApi(updateUserSalesAssignment(this.state.salesAssignment.id, params))
			.then((response) => {
				this.setState({ isSaving: false })
				this.updateShowRemoveCustomer()
				this.fetchSalesLocations(this.getUserId(this.props))
			})
			.catch((error) => {
				this.setState({ isSaving: false })
				this.errorFetchingUserProfile(error)
			})
	}

	handleReloadCustomers = () => {
		this.fetchSalesLocations(this.getUserId(this.props))
	}

	handleSaleCustomerSelected = (salesCustomerSelected) => {
		this.setState({ salesCustomerSelected })
	}

	handleApplicationGroupUpdate = (applicationSelected) => {
		this.setState({ applicationSelected })
	}

	handleReportGroupUpdate = (reportSelected) => {
		this.setState({ reportSelected })
	}

	handleStartEditing = () => {
		this.setState({
			isEditing: true,
			applicationSelected: get(this.state.user, "applications_group._id", 0),
			reportSelected: get(this.state.user, "reports_group._id", 0),
		})
		setTimeout(() => {
			this.input && typeof this.input.focus === "function" && this.input.focus()
		}, 50)
		this.formValidator.dataIsValid()
	}

	handleCancelEditing = () => {
		this.loadStateFromUser(undefined, { isEditing: false, showValidationErrors: false })
	}

	handleSave = () => {
		if (!this.formValidator.dataIsValid()) {
			this.setState({ showValidationErrors: true })
			return
		}

		this.setState({ isSaving: true, isEditing: false })

		const user = this.formValidator.getChangedFields(this.state.user)

		user.application_assignment_id =
			this.state.applicationSelected === 0 ? null : this.state.applicationSelected
		user.report_assignment_id =
			this.state.reportSelected === 0 ? null : this.state.reportSelected
		user.default_territory_id =
			this.state.selectedDefaultTerritory ? this.state.selectedDefaultTerritory : null
		this.props
			.callApi(patchUserProfile(this.state.user._id, user))
			.then((result) => {
				this.setState({
					isSaving: false,
					displayActionSuccess: !result.error,
					displayActionFailure: !!result.error,
				})
				this.fetchUserProfile(this.getUserId(this.props))
			})
			.catch((error) => {
				console.error(error)
				this.setState({ isSaving: false, displayActionFailure: true })
			})
	}

	resetPassword = () => {
		this.setState({ isResetting: true, disablePasswordReset: true })
		this.props
			.callApi(requestPasswordReset(this.state.user.email))
			.then((result) => {
				this.setState({
					isResetting: false,
				})
			})
			.catch((error) => {
				console.error(error)
				this.setState({ isResetting: false, disablePasswordReset: true })
			})
	}

	handleBackToList = () => {
		this.props.history.push("/user-management")
	}

	handleSuccessModalClose = () => {
		this.setState({ displayActionSuccess: false })
	}

	handleFailureModalClose = () => {
		this.setState({ displayActionFailure: false })
	}

	handleShowAddLocation = () => {
		let currentTerritories = []
		let availableTerritories = []
		if (this.state.user.is_customer === false) {
			this.state.locations.map((loc) => {
				return currentTerritories.push({
					_id: loc.location_id,
					description: loc.location_name,
					code: loc.location_number,
				})
			})
			this.props.territories.map((t) => {
				if (currentTerritories.filter((x) => x._id === parseInt(t._id)).length === 0) {
					return availableTerritories.push(t)
				}
				return null
			})

			availableTerritories = availableTerritories.filter(
				(x) => !currentTerritories.includes(x)
			)

			this.setState({
				showAddLocation: true,
				newUserTerritories: currentTerritories,
				availableTerritories: availableTerritories,
			})
		} else {
			this.setState({ showAddLocation: true, newUserCustomers: [...this.state.locations] })
		}
	}

	handleCancelAddLocation = () => {
		this.setState({
			showAddLocation: false,
			newUserTerritories: [],
			customerSelected: null,
			newUserCustomers: [],
		})
	}

	handleReloadUser = () => {
		this.fetchUserLocations(this.getUserId(this.props))
	}

	handleAddLocation = () => {
		this.setState({ isSavingLocations: true })
		if (this.state.user.is_customer === false) {
			let params = {
				customers: null,
				locations: this.state.newUserTerritories,
				bill_to_customers: false,
			}
			this.props
				.callApi(patchUserLocations(this.state.user._id, params))
				.then((result) => {
					this.setState({
						isSavingLocations: false,
					})
					this.handleCancelAddLocation()
					this.fetchUserLocations(this.getUserId(this.props))
				})
				.catch((error) => {
					console.error(error)
					this.setState({ isSavingLocations: false })
				})
		} else {
			let params = {
				customers:
					this.state.customerSelected ?
						[
							{
								location_id: this.state.customerSelected._id,
								location_name: this.state.customerSelected.name,
								location_number: this.state.customerSelected.customer_number,
							},
							...this.state.newUserCustomers,
						]
					:	this.state.newUserCustomers,
				locations: null,
				bill_to_customers: this.state.billToUsed,
			}
			this.props
				.callApi(patchUserLocations(this.state.user._id, params))
				.then((result) => {
					this.setState({
						isSavingLocations: false,
					})
					this.handleCancelAddLocation()
					this.fetchUserLocations(this.getUserId(this.props))
				})
				.catch((error) => {
					console.error(error)
					this.setState({ isSavingLocations: false })
				})
		}
	}

	handleAddTerritory = (territory) => {
		// none selected or selected one is already in list
		if (!territory || this.state.newUserTerritories.some((x) => x._id === territory._id)) {
			return
		}
		this.setState({
			newUserTerritories: [territory, ...this.state.newUserTerritories],
			availableTerritories: this.state.availableTerritories.filter(
				(x) => x._id !== territory._id
			),
		})
	}

	handleRemoveTerritory = (territory) => {
		this.setState({
			newUserTerritories: this.state.newUserTerritories.filter(
				(x) => x._id !== territory._id
			),
			availableTerritories: [territory, ...this.state.availableTerritories],
		})
	}

	handleRemoveCustomer = (customer) => {
		this.setState({
			newUserCustomers: this.state.newUserCustomers.filter(
				(x) => x.location_id !== customer.location_id
			),
		})
	}

	handleBillToUpdate = () => {
		this.setState({ billToUsed: !this.state.billToUsed })
	}

	handleCustomerSelected = (customer) => {
		if (customer) {
			this.setState({ customerSelected: customer })
		} else {
			this.setState({ customerSelected: null })
		}
	}
	handleAddAll = () => {
		this.setState({ availableTerritories: [], newUserTerritories: this.props.territories })
	}
	handleRemoveAll = () => {
		this.setState({ availableTerritories: this.props.territories, newUserTerritories: [] })
	}
	handleAddDomestic = () => {
		this.setState({
			availableTerritories: this.props.territories.filter((x) => x.is_domestic === false),
			newUserTerritories: this.props.territories.filter((x) => x.is_domestic === true),
		})
	}
	handleAddInternational = () => {
		this.setState({
			availableTerritories: this.props.territories.filter((x) => x.is_domestic === true),
			newUserTerritories: this.props.territories.filter((x) => x.is_domestic === false),
		})
	}
	handleDefaultTerritoryChange = (selectedDefaultTerritory) => {
		this.setState({ selectedDefaultTerritory })
	}

	handleTabChange = (activeTabIndex) => {
		this.setState({ activeTabIndex })
	}
	render() {
		return (
			<UserDetails
				user={this.state.user}
				isLoading={this.state.isLoading}
				isEditing={this.state.isEditing}
				isSaving={this.state.isSaving}
				onStartEditingUser={this.handleStartEditing}
				onSaveUser={this.handleSave}
				onCancelEditingUser={this.handleCancelEditing}
				onBackToList={this.handleBackToList}
				isUserAdmin={this.props.isUserAdmin()}
				isUserCustomer={!!this.state.user?.is_customer}
				displayActionSuccess={this.state.displayActionSuccess}
				onSuccessModalClose={this.handleSuccessModalClose}
				displayActionFailure={this.state.displayActionFailure}
				onFailureModalClose={this.handleFailureModalClose}
				isMyProfileView={!!this.props.userId}
				applications={this.props.applications}
				applicationSelected={this.state.applicationSelected}
				handleApplicationGroupUpdate={this.handleApplicationGroupUpdate}
				reports={this.props.reports}
				reportSelected={this.state.reportSelected}
				handleReportGroupUpdate={this.handleReportGroupUpdate}
				resetPassword={this.resetPassword}
				disablePasswordReset={this.state.disablePasswordReset}
				isResetting={this.state.isResetting}
				locations={this.state.locations}
				territories={this.props.territories}
				availableTerritories={this.state.availableTerritories}
				isLoadingLocations={this.state.isLoadingLocations}
				showAddLocation={this.state.showAddLocation}
				handleCancelAddLocation={this.handleCancelAddLocation}
				handleReloadUser={this.handleReloadUser}
				handleAddLocation={this.handleAddLocation}
				handleShowAddLocation={this.handleShowAddLocation}
				newUserTerritories={this.state.newUserTerritories}
				onRemoveTerritory={this.handleRemoveTerritory}
				onAddTerritory={this.handleAddTerritory}
				billToUsed={this.state.billToUsed}
				handleBillToUpdate={this.handleBillToUpdate}
				customerSelected={this.state.customerSelected}
				handleCustomerSelected={this.handleCustomerSelected}
				newUserCustomers={this.state.newUserCustomers}
				handleRemoveCustomer={this.handleRemoveCustomer}
				onAddAll={this.handleAddAll}
				onRemoveAll={this.handleRemoveAll}
				onAddDomestic={this.handleAddDomestic}
				onAddInternational={this.handleAddInternational}
				selectedDefaultTerritory={this.state.selectedDefaultTerritory}
				onDefaultTerritoryChange={this.handleDefaultTerritoryChange}
				activeTabIndex={this.state.activeTabIndex}
				handleTabChange={this.handleTabChage}
				salesTerritories={this.state.salesTerritories}
				salesCustomers={this.state.salesCustomers}
				addSalesCustomer={this.state.addSalesCustomer}
				handleCancelSalesCustomer={this.handleCancelSalesCustomer}
				handleAddSalesCustomer={this.handleAddSalesCustomer}
				handleReloadCustomers={this.handleReloadCustomers}
				handleSaleCustomerSelected={this.handleSaleCustomerSelected}
				handleShowAddCustomer={this.handleShowAddCustomer}
				handleRemoveSalesCustomer={this.handleRemoveSalesCustomer}
				showRemoveCustomer={this.state.showRemoveCustomer}
				updateShowRemoveCustomer={this.updateShowRemoveCustomer}
				handleShowRemoveCustomer={this.handleShowRemoveCustomer}
				{...this.formValidator.generateFormProps()}
			/>
		)
	}
}

UserDetailsContainer.propTypes = {
	userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	user: PropTypes.object,
}

const mapStateToProps = (state) => {
	return {
		isNotUserAdmin: () => {
			return !meetsAppRestriction(state.auth, APPLICATIONS.UserManagementAdmin)
		},
		isUserAdmin: () => {
			return meetsAppRestriction(state.auth, APPLICATIONS.UserManagementAdmin)
		},
		applications: state.lookups.defaultApplications,
		reports: state.lookups.defaultReports,
		territories: state.lookups.territories,
	}
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ callApi, loadLookups }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserDetailsContainer))

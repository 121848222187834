import { FC, useState } from "react"

import { APPLICATION, PricingMatrix, usePricingMatrix, useUserCanUse } from "@ncs/ncs-api"
import {
	AnimatedEntrance,
	Box,
	Button,
	Callout,
	Card,
	Divider,
	Heading,
	Icon,
	Label,
	LoadingSpinner,
	PricingMatrixSelector,
	RecentItems,
	Tabs,
	useChangeCallback,
	useUrlState,
} from "@ncs/web-legos"

import { EditPricingMatrixModal } from "./EditPricingMatrixModal"
import PricingMatrixCustomersTab from "./PricingMatrixCustomersTab"
import PricingMatrixPartsTab from "./PricingMatrixPartsTab"

enum PricingMatrixTab {
	Parts = "Matrix Parts",
	Customers = "Customers",
}

export type PricingMatricesUrlState = {
	tab: PricingMatrixTab
	urlMatrixId: string | null
}

const PricingMatrices: FC = () => {
	const isDiscountAdmin = useUserCanUse(APPLICATION.PartDiscountAdmin)
	const [{ tab, urlMatrixId }, { updateUrlValue }] = useUrlState<PricingMatricesUrlState>({
		tab: PricingMatrixTab.Parts,
		urlMatrixId: null,
	})
	const [selectedMatrixId, setSelectedMatrixId] = useState<string | null>(null)

	// Get the full matrix from our ID. Note that normally we'd just store the full matrix
	// in state, but because it can be patched here on this screen we need to get more of
	// a live feed to the current value.
	const [selectedMatrix, selectedMatrixLoading] = usePricingMatrix(selectedMatrixId)

	// Also keep the matrix ID in the URL so user can refresh or back into this page.
	useChangeCallback(selectedMatrixId, () => {
		updateUrlValue("urlMatrixId", selectedMatrixId)
	})

	const [matrixToEdit, setMatrixToEdit] = useState<PricingMatrix | null>(null)
	const [addingNewMatrix, setAddingNewMatrix] = useState(false)

	return (
		<>
			<Card
				heading="Pricing Matrices"
				headingIcon="usd-square"
				renderRight={() =>
					isDiscountAdmin ?
						<Button icon="plus" onClick={() => setAddingNewMatrix(true)}>
							Create New Pricing Matrix
						</Button>
					:	undefined
				}
			>
				<PricingMatrixSelector
					value={selectedMatrix ?? null}
					onChange={(newMatrix) => setSelectedMatrixId(newMatrix?.id ?? null)}
					maxWidth={35}
					label="Select pricing matrix"
					placeholder="Search for pricing matrix..."
					initialId={urlMatrixId}
				/>
				<Box mb={3}>
					<RecentItems
						item={selectedMatrix}
						itemLabelAccessor="description"
						onSelect={(matrix) => setSelectedMatrixId(matrix.id)}
						compareFn={(a, b) => a.id === b.id}
					/>
				</Box>

				<Divider fullCardBleed my={3} />

				{selectedMatrixLoading && <LoadingSpinner />}

				{!!selectedMatrix && (
					<AnimatedEntrance show>
						<Callout mb={2} variant="info" pr={4} smProps={{ pr: undefined }}>
							<Label>Matrix name</Label>
							<Heading variant="h2" mb={1.5}>
								{selectedMatrix.description}
							</Heading>

							{isDiscountAdmin && (
								<Button
									buttonText="Edit"
									icon="pencil"
									onClick={() => setMatrixToEdit(selectedMatrix)}
								/>
							)}
						</Callout>

						<Tabs
							currentTab={tab}
							onChange={(newTab) => updateUrlValue("tab", newTab)}
							disabled={!selectedMatrix}
							panels={[
								{
									navLabel: PricingMatrixTab.Parts,
									navIcon: <Icon icon="tools" />,
									component: <PricingMatrixPartsTab matrix={selectedMatrix} />,
								},
								{
									navLabel: PricingMatrixTab.Customers,
									navIcon: <Icon icon="users" />,
									component: (
										<PricingMatrixCustomersTab matrix={selectedMatrix} />
									),
								},
							]}
						/>
					</AnimatedEntrance>
				)}

				{!selectedMatrix && !selectedMatrixLoading && (
					<Heading textAlign="center" icon="search" pt={4} pb={5} opacity={0.25}>
						Select a matrix above to begin
					</Heading>
				)}
			</Card>

			<EditPricingMatrixModal
				isOpen={addingNewMatrix || !!matrixToEdit}
				onClose={() => {
					setAddingNewMatrix(false)
					setMatrixToEdit(null)
				}}
				matrix={matrixToEdit}
				createCallback={setSelectedMatrixId}
			/>
		</>
	)
}

export default PricingMatrices

import { ReactElement } from "react"

import { Control, Controller, FieldValues, Path } from "react-hook-form"

import { RadioGroup, RadioGroupProps } from "../inputs"

export interface RadioGroupFormFieldProps<TFieldValues extends FieldValues, Options>
	extends Omit<RadioGroupProps<Options>, "value" | "onChange" | "htmlName"> {
	name: Path<TFieldValues>
	control: Control<TFieldValues>
}

export const RadioGroupFormField = <TFieldValues extends FieldValues, Options>({
	name,
	control,
	...rest
}: RadioGroupFormFieldProps<TFieldValues, Options>): ReactElement => {
	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { onBlur, value, onChange }, fieldState: { error } }) => (
				<RadioGroup
					{...rest}
					onBlur={onBlur}
					htmlName={name}
					value={value ? String(value) : null}
					onChange={onChange}
					error={error?.message}
				/>
			)}
		/>
	)
}

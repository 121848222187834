import { FC, useMemo, useState } from "react"

import { css } from "@emotion/react"
import { Cell, Column } from "react-table"

import {
	Dispatch,
	GenericLineItem,
	GenericLineItemWithBasePrice,
	GenericLineItemWithId,
	LineItemPriceOverride,
	makeApiErrorMessage,
	useCreateWorkOrderInvoiceLineItem,
	useDeleteWorkOrderLineInvoiceLineItem,
	useSetInvoiceLineItemsNonBillable,
	useUpdateWorkOrderInvoiceLineItem,
	useWorkOrderInvoiceLineItems,
	WorkOrderInvoice,
	WorkOrderInvoiceLineItem,
} from "@ncs/ncs-api"
import { extractNumber, formatCurrency, formatNumber, maxChars } from "@ncs/ts-utils"
import {
	Box,
	Button,
	ConfirmationModal,
	ConfirmationModalConfig,
	EmptyValueDash,
	HeadingDivider,
	Icon,
	IconButton,
	Label,
	Price,
	Table,
	ThrottledTextInput,
	Tooltip,
	useToast,
} from "@ncs/web-legos"

import { LineItemEditorModal, PriceOverridesModal, SaveLineItemExtras } from "~/components"
import {
	allowInvoiceCreditMemo,
	allowInvoiceEdit,
	getDispatchLineDescription,
	invoiceLineItemToLineItem,
	invoiceLineItemToOverrideLine,
} from "~/util"

import { CreateCreditMemoModal, WorkOrderSummaryModal } from "./components"

export interface InvoiceLineItemsProps {
	dispatch: Dispatch
	invoice: WorkOrderInvoice
}

export const InvoiceLineItems: FC<InvoiceLineItemsProps> = ({ dispatch, invoice }) => {
	const { makeSuccessToast, makeErrorToast } = useToast()
	const [showAddLine, setShowAddLine] = useState(false)
	const [lineIdForModal, setLineIdForModal] = useState<string | null>(null)
	const [search, setSearch] = useState<string | null>(null)
	const [workOrderModalLine, setWorkOrderModalLine] = useState<WorkOrderInvoiceLineItem | null>(
		null
	)
	const [showCreditMemo, setShowCreditMemo] = useState(false)
	const [overridesModalLineId, setOverridesModalLineId] = useState<string | null>(null)
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)

	const [lineItems, lineItemsLoading] = useWorkOrderInvoiceLineItems(invoice.id)
	const createLine = useCreateWorkOrderInvoiceLineItem(invoice.id)
	const updateLine = useUpdateWorkOrderInvoiceLineItem(invoice.id)
	const deleteLine = useDeleteWorkOrderLineInvoiceLineItem(invoice.id)
	const setAllNonBillable = useSetInvoiceLineItemsNonBillable(invoice.id)

	const overridesForModal = useMemo((): LineItemPriceOverride[] => {
		const line = lineItems?.find((l) => l.id === overridesModalLineId)

		if (line) {
			return invoiceLineItemToOverrideLine(line, dispatch)
		}

		return []
	}, [overridesModalLineId, dispatch, lineItems])

	const handleSave = async (
		line: GenericLineItemWithBasePrice,
		{ isEdit, binId, basePriceWasNull }: SaveLineItemExtras
	): Promise<void> => {
		try {
			if (isEdit && line.id) {
				await updateLine({
					id: line.id,
					updates: {
						netPrice: line.finalPrice,
						billable: line.isBillable ?? false,
						quantity: line.quantity,
						reason: line.description,
						underWarranty: line.isUnderWarranty ?? false,
						overrideReasonId:
							line.priceOverrideReason?.id ?
								extractNumber(line.priceOverrideReason.id)
							:	null,
						overrideComment: line.reasonComment,
						requestedOverrideAmount: line.requestedPrice,
					},
				})
			} else {
				await createLine({
					netPrice: line.finalPrice,
					// If the modal didn't ever get a real base price, then submit the unit price
					// as the same as the final price.
					unitPrice: basePriceWasNull ? line.finalPrice : line.basePrice,
					binId: extractNumber(binId),
					partId: line.part?.id ? extractNumber(line.part.id) : null,
					quantity: line.quantity,
					billable: line.isBillable ?? false,
					underWarranty: line.isUnderWarranty ?? false,
					reason: line.description,
					typeId: extractNumber(line.lineTypeId),
				})
				makeSuccessToast("Line item added")
			}
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		}
	}

	const handleSetAllNonBillable = async () => {
		setConfirmationConfig({
			title: "Set All Lines To Non-Billable",
			message:
				"This will set all line items on the invoice to be non-billable. This means that all their prices will become $0. Depending on the number of line items, this may take a minute or two.",
			confirmButtonText: "Make all line items non-billable",
			onConfirm: async () => {
				try {
					await setAllNonBillable()
					makeSuccessToast("Line items updated")
				} catch (e) {
					makeErrorToast(makeApiErrorMessage(e))
				}
			},
		})
	}

	const handleDelete = async (lineToDelete: GenericLineItemWithId) => {
		try {
			await deleteLine(lineToDelete.id)
			makeSuccessToast("Line item deleted")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		}
	}

	const preparedLines = useMemo(() => {
		return (lineItems ?? []).filter((line) => {
			if (!search) return true
			const searchChunks = search.trim().toLowerCase().split(" ")
			const searchable = Object.values(line).join("").toLowerCase()
			return searchChunks.every((chunk) => searchable.includes(chunk))
		})
	}, [lineItems, search])

	const billableLineItems = useMemo(() => {
		return (lineItems ?? []).filter((line) => line.billable)
	}, [lineItems])

	const lineItemForModal = useMemo((): GenericLineItem | null => {
		if (!lineIdForModal) return null

		const line = lineItems?.find((l) => l.id === lineIdForModal)

		return line ? invoiceLineItemToLineItem(line) : null
	}, [lineItems, lineIdForModal])

	const columns = useMemo(
		(): Column<WorkOrderInvoiceLineItem>[] => [
			{
				Header: "Description",
				accessor: (original) => getDispatchLineDescription(original),
			},
			{
				Header: "Under warranty?",
				accessor: "underWarranty",
				Cell: ({ row: { original } }: Cell<WorkOrderInvoiceLineItem>) => {
					return original.underWarranty ?
							<Box d="flex" alignItems="center" gap={0.5}>
								<Icon icon="check" color="gray" />
								<span>Under warranty</span>
							</Box>
						:	<EmptyValueDash />
				},
			},
			{
				Header: "Billable?",
				accessor: "billable",
				Cell: ({ row: { original } }: Cell<WorkOrderInvoiceLineItem>) => {
					return original.billable ?
							<Box d="flex" alignItems="center" gap={0.5}>
								<Icon icon="check" color="gray" />
								<span>Billable</span>
							</Box>
						:	<EmptyValueDash />
				},
			},
			{
				Header: "Qty",
				accessor: ({ quantity }) => formatNumber(extractNumber(quantity)),
			},
			{
				Header: "Price",
				accessor: "netPrice",
				Cell: ({ row: { original } }: Cell<WorkOrderInvoiceLineItem>) => {
					const originalPrice = extractNumber(original.unitPrice)
					let finalPrice = extractNumber(original.netPrice)

					if (extractNumber(original.total) === 0) {
						finalPrice = 0
					}

					return (
						<Box d="flex" gap={0.5}>
							{finalPrice < originalPrice && (
								<span
									css={css`
										text-decoration: line-through;
									`}
								>
									{formatCurrency(originalPrice)}
								</span>
							)}

							<span>{formatCurrency(finalPrice)}</span>

							{original.approvals.some((a) => a.pendingApproval) && (
								<Tooltip title="Price override pending approval">
									<Box height={1} mt={-0.45}>
										<IconButton
											icon="user-lock"
											color="primary"
											stopPropagation
											onClick={() => setOverridesModalLineId(original.id)}
										/>
									</Box>
								</Tooltip>
							)}
						</Box>
					)
				},
			},
			{
				Header: "Subtotal",
				accessor: ({ subTotal }) => formatCurrency(subTotal),
			},
			{
				Header: "Est tax",
				accessor: ({ tax }) => formatCurrency(tax),
			},
			{
				Header: "Total",
				accessor: ({ total }) => formatCurrency(total),
			},
			{
				Header: "Work order",
				Cell: ({ row: { original } }: Cell<WorkOrderInvoiceLineItem>) => {
					return (
						<>
							<Button
								onClick={() => setWorkOrderModalLine(original)}
								stopPropagation
							>
								Work Order #{original.workorder.workorderNumber}
							</Button>{" "}
							<span>{maxChars(original.workorder.comment || "", 50)}</span>
						</>
					)
				},
			},
		],
		[]
	)

	const canEditInvoice = useMemo((): boolean => {
		return allowInvoiceEdit(invoice)
	}, [invoice])

	return (
		<>
			<div>
				<HeadingDivider
					headingVariant="h5"
					mt={0}
					renderRight={() => {
						return (
							<Box d="flex" gap={1}>
								{canEditInvoice && (
									<>
										<Button icon="plus" onClick={() => setShowAddLine(true)}>
											Add line item
										</Button>
										<Button icon="ban" onClick={handleSetAllNonBillable}>
											Make all non-billable
										</Button>
									</>
								)}
								{allowInvoiceCreditMemo(invoice) && (
									<Button
										icon="plus"
										onClick={() => setShowCreditMemo(true)}
										disabled={!billableLineItems.length}
									>
										Add credit memo
									</Button>
								)}
							</Box>
						)
					}}
				>
					Line Items
				</HeadingDivider>

				<Box d="flex" columnGap={2} rowGap={1} mt={2} mb={1} flexWrap="wrap">
					<div>
						<Label>Parts total</Label>
						<Price price={invoice.totalParts} />
					</div>
					<div>
						<Label>Labor total</Label>
						<Price price={invoice.laborTotal} />
					</div>
					<div>
						<Label>Other total</Label>
						<Price price={invoice.otherTotal} />
					</div>
					<Box bl={1} pl={2}>
						<Label>Subtotal</Label>
						<Price price={invoice.subtotal} />
					</Box>
					<div>
						<Label>Tax total</Label>
						<Price price={invoice.taxTotal} />
					</div>
					<Box bl={1} pl={2}>
						<Label>Total</Label>
						<Price price={invoice.total} />
					</Box>
				</Box>

				<Box textAlign="right">
					<ThrottledTextInput
						value={search}
						onChange={setSearch}
						label={null}
						placeholder="Search"
						icon="search"
						clearable
						fillContainer={false}
						leading={false}
					/>
				</Box>

				<Table
					data={preparedLines}
					columns={columns}
					isLoading={lineItemsLoading}
					disableAllSorting
					noDataText={search ? `No lines match "${search}"` : "No line items on invoice"}
					infiniteRowsIncrement={25}
					rowMenu={[
						{
							label: "Edit",
							iconName: "pencil",
							onClick: ({ original }) => setLineIdForModal(original.id),
							disabledAccessor: () => canEditInvoice === false,
						},
						{
							label: "View price overrides",
							iconName: "user-lock",
							hiddenAccessor: ({ original }) => !original.approvals.length,
							onClick: ({ original }) => setOverridesModalLineId(original.id),
						},
					]}
				/>
			</div>

			{(!!lineItemForModal || showAddLine) && (
				<LineItemEditorModal
					isDispatch
					lineItemToEdit={lineItemForModal}
					shipToId={dispatch.customer.id}
					billToId={dispatch.billToCustomer?.id ?? null}
					onSave={handleSave}
					onRemove={handleDelete}
					onClose={() => {
						setLineIdForModal(null)
						setShowAddLine(false)
					}}
				/>
			)}
			{showCreditMemo && (
				<CreateCreditMemoModal
					dispatch={dispatch}
					invoice={invoice}
					lineItems={billableLineItems}
					onClose={() => setShowCreditMemo(false)}
				/>
			)}
			{!!workOrderModalLine && (
				<WorkOrderSummaryModal
					details={workOrderModalLine.workorder}
					onClose={() => setWorkOrderModalLine(null)}
				/>
			)}
			{overridesForModal.length > 0 && (
				<PriceOverridesModal
					overrides={overridesForModal}
					onClose={() => setOverridesModalLineId(null)}
					closeAfterSubmission
				/>
			)}
			{!!confirmationConfig && (
				<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
			)}
		</>
	)
}

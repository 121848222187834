import React from "react"

//custom components
import { GridContainer, GridItem, ButtonWithIcon, Input } from "~/components"

// icons
import RemoveIcon from "@material-ui/icons/Remove"
import AddIcon from "@material-ui/icons/Add"

function TicketSubtypeAdd(props) {
	const {
		isAdding,
		onSubtypeAdd,
		onCancelSubtypeAdd,
		onSaveSubtype,
		description,
		onDescriptionChange,
		descriptionIsValid,
		descriptionErrorMessage,
		setInputRef,
		showValidationErrors,
	} = props

	return (
		<GridContainer>
			<GridItem hide={isAdding}>
				<ButtonWithIcon
					icon={<AddIcon />}
					content="Add Subtype"
					size="sm"
					round
					color="success"
					onClick={onSubtypeAdd}
				/>
			</GridItem>

			<GridItem xs={3} show={isAdding}>
				<ButtonWithIcon
					icon={<RemoveIcon />}
					content="Cancel"
					size="sm"
					round
					color="error"
					onClick={onCancelSubtypeAdd}
				/>
			</GridItem>
			<GridItem xs={6} show={isAdding}>
				<Input
					labelText={"Description"}
					value={description}
					onChange={onDescriptionChange}
					inputProps={{
						inputProps: { ref: setInputRef },
					}}
					formControlProps={{
						error: showValidationErrors && !descriptionIsValid,
					}}
					helperText={showValidationErrors ? descriptionErrorMessage : ""}
				/>
			</GridItem>
			<GridItem xs={3} show={isAdding}>
				<ButtonWithIcon
					icon={<AddIcon />}
					content="Confirm"
					size="sm"
					round
					color="success"
					disabled={!description}
					onClick={onSaveSubtype}
				/>
			</GridItem>
		</GridContainer>
	)
}

export default TicketSubtypeAdd

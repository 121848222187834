import { FC, useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { Controller, useForm } from "react-hook-form"

import { makeApiErrorMessage, useCreateContainerChemical } from "@ncs/ncs-api"
import {
	AnimatedEntrance,
	Box,
	ContainerSelector,
	CustomerSelectorFormField,
	Divider,
	ExtendableModalProps,
	HeadingDivider,
	HeadingDividerProps,
	InventoryPartSelectorFormField,
	Modal,
	NewContainerOptionId,
	NumericInputFormField,
	Paragraph,
	TextInputFormField,
	useToast,
} from "@ncs/web-legos"

import {
	AddChemicalToCustomerForm,
	addChemicalToCustomerFormDefaultValues,
	AddChemicalToCustomerFormSchema,
	newContainerDefaultValues,
	prepareContainerChemicalPost,
} from "./add-chemical-to-customer-modal-utils"

export interface AddChemicalToCustomerModalProps extends ExtendableModalProps {
	customer?: {
		id: string
		number: string
		name: string
	}
}

export const AddChemicalToCustomerModal: FC<AddChemicalToCustomerModalProps> = ({
	customer,
	...rest
}) => {
	const { makeSuccessToast } = useToast()
	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const createContainerChemical = useCreateContainerChemical()

	const {
		control,
		reset,
		getValues,
		watch,
		handleSubmit,
		formState: { errors, submitCount },
	} = useForm<AddChemicalToCustomerForm>({
		resolver: zodResolver(AddChemicalToCustomerFormSchema),
		defaultValues: {
			...addChemicalToCustomerFormDefaultValues,
			customerId: customer?.id,
		},
	})

	const handleCreate = async (form: AddChemicalToCustomerForm) => {
		try {
			setIsSaving(true)
			const data = prepareContainerChemicalPost(form)
			await createContainerChemical(data)
			makeSuccessToast("Chemical container created")
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const [newContainer] = watch(["newContainer"])

	const headingProps: HeadingDividerProps = {
		bold: true,
		headingVariant: "h4",
		mt: 2.5,
		mb: 0.75,
	}

	return (
		<Modal
			{...rest}
			title="Add Chemical To Customer"
			errorText={errorText}
			rightButtons={{
				buttonText: "Add Chemical",
				onClick: handleSubmit(handleCreate),
				isLoading: isSaving,
				disabled: Object.keys(errors).length > 0 && submitCount > 0,
			}}
		>
			<HeadingDivider {...headingProps} mt={0}>
				Customer
			</HeadingDivider>
			{customer ?
				<Paragraph>
					({customer.number}) {customer.name}
				</Paragraph>
			:	<CustomerSelectorFormField
					control={control}
					name="customerId"
					emptyValueFallback=""
				/>
			}

			<HeadingDivider {...headingProps}>Chemical</HeadingDivider>
			<InventoryPartSelectorFormField
				control={control}
				name="partId"
				label="Part"
				emptyValueFallback=""
			/>
			<Box maxWidth="50%" xsProps={{ maxWidth: "none" }}>
				<NumericInputFormField
					control={control}
					name="expectedMlUsage"
					label="Expected mL usage"
					emptyValueFallback=""
					returnValueAsString
				/>
				<NumericInputFormField
					control={control}
					name="lowLevelGallons"
					label="Low level trigger (gallons)"
					emptyValueFallback=""
					returnValueAsString
				/>
				<NumericInputFormField
					control={control}
					name="maxBackStockQuantity"
					label="Max back stock quantity"
					emptyValueFallback=""
					returnValueAsString
				/>
				<TextInputFormField control={control} name="pumpSetting" label="Pump setting" />
				<TextInputFormField control={control} name="tipType" label="Tip type" />
			</Box>

			<HeadingDivider {...headingProps}>Container</HeadingDivider>
			<Controller
				control={control}
				name="containerId"
				render={({ field: { onBlur, value }, fieldState: { error } }) => {
					return (
						<ContainerSelector
							{...rest}
							onBlur={onBlur}
							value={value || null}
							onChange={(newValue) => {
								// If user selected the "neW" option, set the container ID in the
								// form to null and set newContainer in the form to its defaults.
								if (newValue === NewContainerOptionId) {
									reset({
										...getValues(),
										containerId: newValue,
										newContainer: { ...newContainerDefaultValues },
									})
								} else {
									reset({
										...getValues(),
										containerId: newValue,
										newContainer: undefined,
									})
								}
							}}
							error={error?.message}
							addNewOption
							fillContainer
						/>
					)
				}}
			/>

			<AnimatedEntrance show={!!newContainer}>
				<TextInputFormField
					control={control}
					name="newContainer.name"
					label="Name for this new container type"
					emptyValueFallback=""
				/>
				<NumericInputFormField
					control={control}
					name="newContainer.fillableHeight"
					label="Fillable height (gallons)"
					decimalScale={2}
					emptyValueFallback=""
					returnValueAsString
				/>
				<NumericInputFormField
					control={control}
					name="newContainer.gallons"
					label="Total gallons"
					decimalScale={0}
					emptyValueFallback={null}
				/>
				<NumericInputFormField
					control={control}
					name="newContainer.mlPerMm"
					label="mL per mm"
					decimalScale={2}
					emptyValueFallback=""
					returnValueAsString
				/>
			</AnimatedEntrance>

			<Divider />
			<TextInputFormField
				control={control}
				name="name"
				label="Enter a name for this chemical in this container"
				placeholder="Name..."
				emptyValueFallback=""
			/>
		</Modal>
	)
}

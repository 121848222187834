import { FC, useEffect, useState } from "react"

import {
	CustomerContractParts,
	InventoryPart,
	makeApiErrorMessage,
	useCreateContractPart,
} from "@ncs/ncs-api"
import {
	ExtendableModalProps,
	Modal,
	Paragraph,
	PartSelector,
	useIsSaving,
	useToast,
} from "@ncs/web-legos"

export interface AddContractPartModalProps extends ExtendableModalProps {
	contract: CustomerContractParts
}

export const AddContractPartModal: FC<AddContractPartModalProps> = ({ contract, ...rest }) => {
	const { makeSuccessToast } = useToast()
	const [selectedPart, setSelectedPart] = useState<InventoryPart | null>(null)
	const { isSaving, setSaving, endSaving } = useIsSaving()
	const [errorText, setErrorText] = useState<string | null>(null)

	const createInclusion = useCreateContractPart()

	const handleSave = async (keepOpen = false) => {
		if (!selectedPart) {
			setErrorText("Please select a part")
			return
		}

		try {
			setSaving()
			await createInclusion({
				contract: contract.id,
				part: selectedPart.id,
			})
			makeSuccessToast("Part added to contract")
			if (keepOpen === true) {
				endSaving()
				setSelectedPart(null)
			} else {
				rest.onClose()
			}
		} catch (e) {
			endSaving()
			setErrorText(makeApiErrorMessage(e))
		}
	}

	useEffect(() => {
		setErrorText(null)
	}, [selectedPart?.id])

	return (
		<Modal
			{...rest}
			title="Add Part To Contract"
			errorText={errorText}
			rightButtons={{
				buttonText: "Add Part",
				onClick: () => handleSave(),
				isLoading: isSaving(),
				disabled: !selectedPart,
				nestedButtons: [
					{
						buttonText: "Save + Add Another",
						isLoading: isSaving(),
						onClick: async () => {
							await handleSave(true)
						},
					},
				],
			}}
		>
			<Paragraph mb={1}>Choose part to be included in this contract:</Paragraph>

			<PartSelector
				value={selectedPart}
				onChange={setSelectedPart}
				autoFocus
				skipRestrictedCheck
			/>
		</Modal>
	)
}

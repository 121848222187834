import React from "react"
import CommentIcon from "@material-ui/icons/Comment"

//custom components
import {
	GridContainer,
	QuickCard,
	GridItem,
	ConditionalContent,
	ButtonWithIcon,
	Input,
	Select,
	UserSelector,
	TicketRequestorSelector,
} from "~/components"

//local components
import TicketDocuments from "./TicketDocuments"
import CommentNotificationContainer from "./CommentNotificationContainer"
// icons
import Edit from "@material-ui/icons/Edit"
import Check from "@material-ui/icons/Check"
import Remove from "@material-ui/icons/Remove"

// styles
import { withStyles } from "@material-ui/core/styles"
import { formatDateTime } from "@ncs/bricks/util/formatters"

const styles = {
	right: {
		textAlign: "right",
	},
}

function TicketComment(props) {
	let {
		ticketComment,
		userId,
		onStartEditing,
		onSave,
		onCancelEditing,
		isEditing,
		isSaving,
		formIsValid,
		showValidationErrors,
		isTicketingAdmin,
		description,
		descriptionIsValid,
		descriptionErrorMessage,
		onDescriptionChange,
		setInputRef,
		selectedRequestor,
		onRequestorTypeChange,
		onTechSelected,
		requestorText,
		onRequestorTextChange,
		requestorTextIsValid,
		requestorTextErrorMessage,
		selectedTech,
		isService,
		ticketCustomer,
		onSelectedTicketRequestorChange,
		selectedTicketRequestor,
		startNotification,
		hasDocumentationEmail,
		documentationEmail,
	} = props
	let canEdit = ticketComment.created_by_id === userId || isTicketingAdmin || isService
	return (
		<GridContainer>
			<GridItem xs={12}>
				<QuickCard
					title={`${ticketComment.created_by.name} - ${formatDateTime(
						ticketComment.created_date
					)}`}
					icon={<CommentIcon />}
				>
					<GridContainer>
						<ConditionalContent hide={isEditing || !isService}>
							<GridItem xs={12} md={4}>
								<Input
									labelText={"Requestor Type"}
									value={
										ticketComment.tech_id ? "Technician"
										: ticketComment.ticket_requestor_id ?
											"Other"
										:	"None"
									}
									onChange={onDescriptionChange}
									inputProps={{
										inputProps: { ref: setInputRef },
										disabled: !isEditing,
										autoFocus: isEditing,
										multiline: true,
									}}
									formControlProps={{
										error: showValidationErrors && !descriptionIsValid,
									}}
									helperText={
										showValidationErrors ? descriptionErrorMessage : ""
									}
								/>
							</GridItem>
						</ConditionalContent>
						<ConditionalContent show={isEditing && isService}>
							<GridItem xs={12} md={4}>
								<Select
									id="ticketCommentRequestor"
									labelText="Requestor Type"
									value={selectedRequestor}
									onChange={onRequestorTypeChange}
									options={[
										{
											id: null,
											description: "Select a request type...",
											disabled: false,
										},
										{
											id: 1,
											description: "Technician",
										},
										{
											id: 2,
											description: "Other",
										},
									]}
									labelProps={{ shrink: true }}
									valueAccessor="id"
									textAccessor={(x) => x.description}
								/>
							</GridItem>
						</ConditionalContent>
						<ConditionalContent show={isEditing && isService}>
							<GridItem xs={12} md={4} show={selectedRequestor === 1}>
								<UserSelector
									defaultValue={selectedTech}
									onUserSelected={onTechSelected}
									label={"Tech"}
								/>
							</GridItem>
						</ConditionalContent>

						<ConditionalContent hide={isEditing || !isService}>
							<GridItem xs={12} md={4}>
								<Input
									labelText={"Requestor"}
									value={
										selectedRequestor === 1 ?
											ticketComment.tech_id ?
												ticketComment.tech.name
											:	"No Tech."
										: selectedRequestor === 2 ?
											ticketComment.ticket_requestor_id ?
												ticketComment.ticket_requestor.name
											:	"No Requestor."
										:	"No Tech Or Requestor."
									}
									onChange={onDescriptionChange}
									inputProps={{
										inputProps: { ref: setInputRef },
										disabled: !isEditing,
										autoFocus: isEditing,
									}}
								/>
							</GridItem>
						</ConditionalContent>
						<ConditionalContent show={isEditing && isService}>
							<GridItem xs={12} md={4} show={selectedRequestor === 2}>
								<TicketRequestorSelector
									customer={ticketCustomer}
									onChange={onSelectedTicketRequestorChange}
									requestor={selectedTicketRequestor}
								/>
							</GridItem>
							<GridItem
								xs={12}
								md={4}
								show={selectedRequestor === 2 && selectedTicketRequestor === null}
							>
								<Input
									labelText={"Requestor"}
									value={requestorText}
									onChange={onRequestorTextChange}
									inputProps={{
										ref: setInputRef,
										autoFocus: true,
									}}
									formControlProps={{
										error: showValidationErrors && !requestorTextIsValid,
									}}
									helperText={
										showValidationErrors ? requestorTextErrorMessage : ""
									}
								/>
							</GridItem>
						</ConditionalContent>
						<ConditionalContent hide={isEditing || !isService}>
							<GridItem
								xs={12}
								md={4}
								show={selectedRequestor === 1 || selectedRequestor === 2}
							>
								<CommentNotificationContainer
									show={startNotification}
									documentationEmail={documentationEmail}
									hasDocumentationEmail={hasDocumentationEmail}
									commentId={ticketComment.id}
									selectedRequestor={selectedRequestor}
								/>
							</GridItem>
						</ConditionalContent>
						<ConditionalContent hide={isEditing}>
							<GridItem xs={10}>
								<Input
									labelText={"Comment"}
									value={description}
									onChange={onDescriptionChange}
									inputProps={{
										inputProps: { ref: setInputRef },
										disabled: !isEditing,
										autoFocus: isEditing,
										multiline: true,
									}}
									formControlProps={{
										error: showValidationErrors && !descriptionIsValid,
									}}
									helperText={
										showValidationErrors ? descriptionErrorMessage : ""
									}
								/>
							</GridItem>
						</ConditionalContent>
						<ConditionalContent show={isEditing}>
							<GridItem xs={1} hide={isEditing || isSaving}>
								<div></div>
							</GridItem>
							<GridItem xs={10}>
								<Input
									labelText={"Comment"}
									value={description}
									onChange={onDescriptionChange}
									inputProps={{
										inputProps: { ref: setInputRef },
										disabled: !isEditing,
										autoFocus: isEditing,
										multiline: true,
									}}
									formControlProps={{
										error: showValidationErrors && !descriptionIsValid,
									}}
									helperText={
										showValidationErrors ? descriptionErrorMessage : ""
									}
								/>
							</GridItem>
						</ConditionalContent>
						<ConditionalContent hide={isEditing || isSaving || !canEdit}>
							<GridItem xs={2}>
								<ButtonWithIcon
									icon={<Edit />}
									justIcon
									size="sm"
									round
									color="success"
									onClick={onStartEditing}
								/>
							</GridItem>
						</ConditionalContent>
						<ConditionalContent show={isEditing || isSaving}>
							<GridItem xs={1}>
								<ButtonWithIcon
									icon={<Remove />}
									justIcon
									size="sm"
									round
									onClick={onCancelEditing}
									disabled={isSaving}
								/>
							</GridItem>
							<GridItem xs={1}>
								<ButtonWithIcon
									icon={<Check />}
									justIcon
									size="sm"
									round
									color="success"
									onClick={onSave}
									disabled={isSaving || (showValidationErrors && !formIsValid)}
									loading={isSaving}
								/>
							</GridItem>
						</ConditionalContent>
					</GridContainer>
					<GridContainer>
						<GridItem xs={6}>
							<TicketDocuments
								relatedId={ticketComment.id}
								key={ticketComment.id}
								canEdit={canEdit}
								isComment={true}
							/>
						</GridItem>
					</GridContainer>
				</QuickCard>
			</GridItem>
		</GridContainer>
	)
}

export default withStyles(styles)(TicketComment)

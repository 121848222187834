import React from "react"
import PropTypes from "prop-types"
import { formatDateTime, formatNumberAsCurrency } from "@ncs/bricks/util/formatters"

// custom components
import {
	ButtonWithIcon,
	ConditionalContent,
	GridContainer,
	GridItem,
	InputAsDisplay,
	Checkbox,
	LoadingWrapper,
	QuickCard,
} from "~/components"

// icons
import Videocam from "@material-ui/icons/Videocam"
import Image from "@material-ui/icons/Image"
import AttachFile from "@material-ui/icons/AttachFile"
import CloudDownload from "@material-ui/icons/CloudDownload"
import ChevronLeft from "@material-ui/icons/ChevronLeft"

// styles
import withStyles from "@material-ui/core/styles/withStyles"

const styles = {
	videoContainer: {
		position: "absolute",
		height: "100%",
		width: "100%",
		overflow: "hidden",
		"& video": {
			minWidth: "100%",
			minHeight: "100%",
		},
	},
}

const MarketingMaterialDetail = (props) => {
	const { isLoading, item, onDownload, onBackToList } = props
	const cardIcon =
		(item.document_type_name || "").toLowerCase() === "video" ? <Videocam />
		: (item.document_type_name || "").toLowerCase() === "image" ? <Image />
		: <AttachFile />

	return (
		<LoadingWrapper isLoading={isLoading}>
			{() => (
				<QuickCard title={item.filename} icon={cardIcon}>
					<GridContainer>
						<GridItem xs={12} md={4}>
							<InputAsDisplay labelText="Category" value={item.category.name} />
						</GridItem>
						<GridItem xs={12} sm={6} md={4}>
							<InputAsDisplay
								labelText="Created Date"
								value={formatDateTime(item.created_date)}
							/>
						</GridItem>
						<GridItem xs={12} sm={6} md={4}>
							<InputAsDisplay
								labelText="Effective Date"
								value={formatDateTime(item.effective_date)}
							/>
						</GridItem>
						<GridItem xs={12} sm={6} md={4}>
							<InputAsDisplay
								labelText="Expiration Date"
								value={formatDateTime(item.expiration_date)}
							/>
						</GridItem>
						<GridItem xs={12} sm={6} md={4}>
							<InputAsDisplay
								labelText="Cost"
								value={formatNumberAsCurrency(item.cost)}
							/>
						</GridItem>
						<GridItem xs={12} sm={6} md={4}>
							<Checkbox
								labelText="Is Orderable"
								value={item.is_orderable}
								inputProps={{
									disabled: true,
								}}
							/>
						</GridItem>
						<GridItem xs={12} sm={6} md={4}>
							<InputAsDisplay labelText="Created By" value={item.uploaded_by.name} />
						</GridItem>
						<GridItem xs={12}>
							<ConditionalContent show={!item.id}>
								Item does not exist.
							</ConditionalContent>
							<ConditionalContent
								show={"video" === (item.document_type_name || "").toLowerCase()}
							>
								{/* eslint-disable-next-line jsx-a11y/media-has-caption */}
								<video
									width="640"
									height="360"
									autoPlay
									controls
									webkitallowfullscreen="true" // spell-checker: disable-line
									mozallowfullscreen="true" // spell-checker: disable-line
									allowFullScreen={true}
									poster={item.preview_url || undefined}
								>
									<source src={item.presign_url} type="video/mp4" />
								</video>
							</ConditionalContent>
							<ConditionalContent
								show={"image" === (item.document_type_name || "").toLowerCase()}
							>
								<img
									src={item.presign_url}
									title={`${item.filename}.${item.extension}`}
									alt={`${item.filename}.${item.extension}`}
								/>
							</ConditionalContent>
							<ConditionalContent
								show={"document" === (item.document_type_name || "").toLowerCase()}
							>
								<ButtonWithIcon
									content="Download"
									icon={<CloudDownload />}
									onClick={onDownload}
									round
									className="edit"
									color="info"
								/>
							</ConditionalContent>
						</GridItem>
						<GridItem xs={12}>
							<ButtonWithIcon
								content="Back to List"
								onClick={onBackToList}
								size="sm"
								color="white"
								round
								icon={<ChevronLeft />}
							/>
						</GridItem>
					</GridContainer>
				</QuickCard>
			)}
		</LoadingWrapper>
	)
}

MarketingMaterialDetail.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	item: PropTypes.object.isRequired,
	onDownload: PropTypes.func.isRequired,
	onBackToList: PropTypes.func.isRequired,
}

export default withStyles(styles)(MarketingMaterialDetail)

import { FC, useEffect, useState } from "react"

import { captureException } from "@sentry/react"

import dayjs from "dayjs"

import {
	APPLICATION,
	makeApiErrorMessage,
	useCallFedEx,
	useCompleteShipment,
	useUpdateShipment,
	useUserCanUse,
} from "@ncs/ncs-api"
import {
	BolBillToSelector,
	Box,
	Button,
	Checkbox,
	DateInput,
	ErrorText,
	RadioGroup,
	ThrottledNumericInput,
	ThrottledTextInput,
	useToast,
} from "@ncs/web-legos"

import { FedExUiName, useShipShipmentContext } from "~/util/ship-shipment"

export interface ShipmentConfigureSendProps {
	closeModal: () => void
}

export const ShipmentConfigureSend: FC<ShipmentConfigureSendProps> = ({ closeModal }) => {
	const {
		shipment,
		partOrder,
		canAddFreightLine,
		fedExMethod,
		setFedExMethod,
		usingPortalFedEx,
		selectedBol,
		setSelectedBol,
		trackingNumber,
		setTrackingNumber,
		freightCharge,
		setFreightCharge,
		addFreightToInvoice,
		setAddFreightToInvoice,
		numberOfPallets,
		setNumberOfPallets,
		proNumber,
		setProNumber,
		makeSaveShipmentPayload,
		scheduledShipDate,
		setScheduledShipDate,
		geodis220ReceivedOn,
		setGeodis220ReceivedOn,
	} = useShipShipmentContext()

	const { makeSuccessToast } = useToast()
	const [hasClickedShip, setHasClickedShip] = useState(false)
	const [isSaving, setIsSaving] = useState<"shipping" | "saving" | null>(null)
	const [error, setError] = useState<string | null>(null)

	const callFedEx = useCallFedEx()
	const saveShipment = useUpdateShipment()
	const completeShipment = useCompleteShipment()
	const canOverrideGeodis = useUserCanUse(APPLICATION.GeodisOverride)

	const handleShipItems = async (shouldShip: boolean) => {
		setHasClickedShip(true)

		try {
			const data = makeSaveShipmentPayload(shouldShip)
			setIsSaving(shouldShip ? "shipping" : "saving")

			// First save the shipment config.
			await saveShipment({
				id: shipment.shipmentId,
				updates: data,
			})

			// Then, if user actually clicked to ship, first do fedex if need be and then ship.
			if (shouldShip) {
				if (usingPortalFedEx) {
					try {
						await callFedEx({
							shipmentId: shipment.shipmentId,
							partOrderId: partOrder.id,
						})
					} catch (e) {
						captureException(e)
						// Throw if there's an error so we don't call to complete.
						throw new Error(
							`There was an error with the integrated FedEx system. To continue, switch to FedEx Manager or select a different LTL Carrier and then try again. Error: ${makeApiErrorMessage(
								e
							)}`
						)
					}
				}

				// If you get this far, then fedex succeeded.
				await completeShipment({ id: shipment.shipmentId })
				makeSuccessToast("Items shipped")
			} else {
				makeSuccessToast("Shipment configuration saved")
			}
			closeModal()
		} catch (e) {
			setIsSaving(null)
			setError(makeApiErrorMessage(e))
		}
	}

	// Keep the error text up to date as user uses form.
	useEffect(() => {
		try {
			makeSaveShipmentPayload(false)
			setError(null)
		} catch (e) {
			if (e instanceof Error) {
				setError(e.message)
			}
		}
	}, [makeSaveShipmentPayload])

	return (
		<>
			{usingPortalFedEx && (
				<RadioGroup
					htmlName="fed-ex-method"
					description="Verify order shipment method selection:"
					value={fedExMethod}
					onChange={(newValue, option) => setFedExMethod(option.value)}
					options={[
						{
							value: FedExUiName.Ground,
							label: FedExUiName.Ground,
						},
						{
							value: FedExUiName.Overnight,
							label: FedExUiName.Overnight,
						},
					]}
				/>
			)}

			<Box maxWidth="50%" smProps={{ maxWidth: "none" }}>
				<BolBillToSelector
					value={selectedBol}
					onChange={(value, option) => setSelectedBol(option?.value ?? null)}
				/>
				<DateInput
					value={scheduledShipDate}
					onChange={setScheduledShipDate}
					label="Expected ship date"
					tooltip="If this package won't be getting picked up today, record here when pickup is expected."
					disablePast
				/>
				<ThrottledNumericInput
					value={numberOfPallets}
					onChange={(value) => setNumberOfPallets(value ?? null)}
					decimalScale={0}
					label="Number of pallets"
				/>
				<ThrottledTextInput
					value={proNumber}
					onChange={setProNumber}
					label="PRO number"
					maxLength={255}
				/>

				{!usingPortalFedEx && (
					<>
						<ThrottledTextInput
							value={trackingNumber}
							onChange={setTrackingNumber}
							label="Tracking number (optional)"
							maxLength={100}
						/>
						{canAddFreightLine && (
							<ThrottledNumericInput
								value={freightCharge}
								onChange={(newValue) => setFreightCharge(newValue ?? null)}
								decimalScale={2}
								fixedDecimalScale
								label="$ Freight charge (optional)"
								mb={0.25}
							/>
						)}
					</>
				)}

				{canAddFreightLine && (
					<Checkbox
						value={addFreightToInvoice}
						onChange={setAddFreightToInvoice}
						label="Add freight line item when shipment is sent?"
						mb={0.5}
					/>
				)}
			</Box>

			<Box maxWidth={45} m="auto" mt={5} mb={2} textAlign="center">
				{!!error && hasClickedShip && (
					<ErrorText mb={1} textAlign="center">
						{error}
					</ErrorText>
				)}
				<Button
					fillContainer
					variant="primary-cta"
					onClick={() => handleShipItems(true)}
					containerProps={{ mb: 2 }}
					isLoading={isSaving === "shipping"}
					disabled={!!isSaving || (!!shipment.geodis940SentOn && !geodis220ReceivedOn)}
				>
					Ship items
				</Button>
				{canOverrideGeodis && (
					<Button
						fillContainer
						variant="secondary-cta"
						containerProps={{ mb: 2 }}
						onClick={() => setGeodis220ReceivedOn(dayjs())}
						isLoading={isSaving === "shipping"}
						disabled={
							!!isSaving ||
							(!!shipment.geodis940SentOn && !!geodis220ReceivedOn) ||
							!shipment.geodis940SentOn
						}
					>
						Override Geodis
					</Button>
				)}
				<Button
					icon="floppy-disk-pen"
					onClick={() => handleShipItems(false)}
					isLoading={isSaving === "saving"}
					disabled={!!isSaving}
				>
					Generate docs and save configuration but don't ship yet
				</Button>
			</Box>
		</>
	)
}

import { get, patch, rsaaTypes, post, RSAA } from "@ncs/bricks/middleware/portalApiMiddleware"

export const SEARCH_USERS = rsaaTypes("users/list")

export const LIST_USER_PROFILES = rsaaTypes("user_profiles/list")
export const GET_USER_PROFILE = rsaaTypes("user_profile/get")
export const PATCH_USER_PROFILE = rsaaTypes("user_profile/patch")
export const GET_TECH_ASSIGNMENTS = rsaaTypes("user/tech_assignments/list")
export const GET_ASSIGNMENT_DETAILS = rsaaTypes("user/tech_assignment/details")
export const PATCH_ASSIGNMENT_DETAILS = rsaaTypes("user/tech_assignment/patch")
export const CREATE_TECH_ASSIGNMENT = rsaaTypes("user/tech_assignment/create")
export const GET_CHEMICAL_TECH_ASSIGNMENTS = rsaaTypes("user/chemical_tech_assignments/list")
export const GET_CHEMICAL_ASSIGNMENT_DETAILS = rsaaTypes("user/chemical_tech_assignment/details")
export const PATCH_CHEMICAL_ASSIGNMENT_DETAILS = rsaaTypes("user/chemical_tech_assignment/patch")
export const CREATE_CHEMICAL_TECH_ASSIGNMENT = rsaaTypes("user/chemical_tech_assignment/create")
export const LIST_OFF_HOURS = rsaaTypes("user/off_hours/list")
export const EDIT_OFF_HOURS = rsaaTypes("user/off_hours/patch")
export const SEND_RESET_PASSWORD = rsaaTypes("user/send_password_reset")
export const GET_USER_LOCATIONS = rsaaTypes("user/locations/list")
export const PATCH_USER_LOCATIONS = rsaaTypes("user/locations/update")
export const ADD_NEW_USER = rsaaTypes("user/add")
export const GET_USER_SCHEDULE = rsaaTypes("user/schedule/list")
export const SAVE_USER_SCHEDULE = rsaaTypes("user/schedule/save")
export const FETCH_USER_SALES_ASSIGNMENTS = rsaaTypes("user/sales/list")
export const CREATE_USER_SALES_ASSIGNMENTS = rsaaTypes("user/sales/create")
export const UPDATE_USER_SALES_ASSIGNMENTS = rsaaTypes("user/sales/update")

export const searchUsers = (querystring_params) => ({
	[RSAA]: {
		api: get(`/security/users/`, querystring_params),
		types: SEARCH_USERS,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const listUserProfiles = (querystring_params) => ({
	[RSAA]: {
		api: get(`/security/user_profiles/`, querystring_params),
		types: LIST_USER_PROFILES,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const getUserProfile = (userId) => ({
	[RSAA]: {
		api: get(`/security/user_profiles/${userId}/`),
		types: GET_USER_PROFILE,
	},
})

export const patchUserProfile = (userId, data) => ({
	[RSAA]: {
		api: patch(`/security/user_profiles/${userId}/`, data),
		types: PATCH_USER_PROFILE,
	},
})

export const getTechnicianAssignment = (querystringParams) => ({
	[RSAA]: {
		api: get(`/security/tech_assignments/`, querystringParams),
		types: GET_TECH_ASSIGNMENTS,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const getChemicalTechnicianAssignment = (querystringParams) => ({
	[RSAA]: {
		api: get(`/security/chemical_tech_assignments/`, querystringParams),
		types: GET_CHEMICAL_TECH_ASSIGNMENTS,
		parseLinkHeader: true,
		includeTimestamp: true,
	},
})

export const getTechAssignmentDetail = (techAssignmentId) => ({
	[RSAA]: {
		api: get(`/security/tech_assignments/${techAssignmentId}/`),
		types: GET_ASSIGNMENT_DETAILS,
	},
})

export const getChemicalTechAssignmentDetail = (chemicalTechAssignmentId) => ({
	[RSAA]: {
		api: get(`/security/chemical_tech_assignments/${chemicalTechAssignmentId}/`),
		types: GET_ASSIGNMENT_DETAILS,
	},
})

export const patchTechAssignmentDetail = (techAssignmentId, data) => ({
	[RSAA]: {
		api: patch(`/security/tech_assignments/${techAssignmentId}/`, data),
		types: PATCH_ASSIGNMENT_DETAILS,
	},
})

export const patchChemicalTechAssignmentDetail = (chemTechAssignmentId, data) => ({
	[RSAA]: {
		api: patch(`/security/chemical_tech_assignments/${chemTechAssignmentId}/`, data),
		types: PATCH_CHEMICAL_ASSIGNMENT_DETAILS,
	},
})

export const createTechAssignment = (data) => ({
	[RSAA]: {
		api: post(`/security/tech_assignments/`, data),
		types: CREATE_TECH_ASSIGNMENT,
	},
})

export const createChemicalTechAssignment = (data) => ({
	[RSAA]: {
		api: post(`/security/chemical_tech_assignments/`, data),
		types: CREATE_CHEMICAL_TECH_ASSIGNMENT,
	},
})

export const listOffHours = (data) => ({
	[RSAA]: {
		api: post(`/dispatch/get_off_hours/`, data),
		types: LIST_OFF_HOURS,
	},
})
export const editOffHours = (data) => ({
	[RSAA]: {
		api: post(`/dispatch/update_off_hours_tech/`, data),
		types: EDIT_OFF_HOURS,
	},
})

export const sendResetPassword = (userId) => ({
	[RSAA]: {
		api: post(`/security/user_profiles/${userId}/passwordReset/`),
		types: SEND_RESET_PASSWORD,
	},
})

export const getUserLocations = (userId) => ({
	[RSAA]: {
		api: get(`/security/user_locations/${userId}/`),
		types: GET_USER_LOCATIONS,
	},
})

export const patchUserLocations = (userId, params) => ({
	[RSAA]: {
		api: post(`/security/locations_user_update/${userId}/`, params),
		types: PATCH_USER_LOCATIONS,
	},
})

export const createNewUser = (newUserInfo) => ({
	[RSAA]: {
		api: post(`/security/user_profiles/`, newUserInfo),
		types: ADD_NEW_USER,
	},
})

export const getUserSchedule = (params) => ({
	[RSAA]: {
		api: get(`/security/get_schedule/`, params),
		types: GET_USER_SCHEDULE,
	},
})

export const saveTechHours = (hours) => ({
	[RSAA]: {
		api: post(`/security/update_schedule/`, hours),
		types: SAVE_USER_SCHEDULE,
	},
})

export const getUserSalesInfo = (userId) => ({
	[RSAA]: {
		api: get(`/security/sales_assignments/`, { user: userId }),
		types: FETCH_USER_SALES_ASSIGNMENTS,
	},
})

export const updateUserSalesAssignment = (assignmentId, params) => ({
	[RSAA]: {
		api: patch(`/security/sales_assignments/${assignmentId}/`, params),
		types: UPDATE_USER_SALES_ASSIGNMENTS,
	},
})

export const createUserSalesAssignment = (params) => ({
	[RSAA]: {
		api: post(`/security/sales_assignments/`, params),
		types: CREATE_USER_SALES_ASSIGNMENTS,
	},
})

const initialState = {
	isLoadingUsers: true,
	users: [],

	isLoadingUserProfiles: true,
	userProfiles: [],
	userProfilesPages: 1,
	userProfilesRequestTimestamp: null,

	isLoadingUserProfile: true,
	userProfile: null,

	isLoadingOffHoursEvents: true,
	offHoursEvents: [],

	isSaving: false,
	error: null,
}

const lookups = (state = initialState, action) => {
	switch (action.type) {
		case SEARCH_USERS.request:
			return {
				...state,
				isLoadingUsers: true,
			}
		case SEARCH_USERS.success:
			return {
				...state,
				isLoadingUsers: false,
				users: action.payload,
			}
		case SEARCH_USERS.failure:
			return {
				...state,
				isLoadingUsers: false,
				error: action.error,
			}

		case LIST_USER_PROFILES.request:
			return {
				...state,
				isLoadingUserProfiles: true,
			}
		case LIST_USER_PROFILES.success:
			// bail if we would be overwriting newer data
			if (state.userProfilesRequestTimestamp > action.meta.timestamp) return state

			return {
				...state,
				isLoadingUserProfiles: false,
				userProfiles: action.payload,
				userProfilesPages: action.meta.pages,
				userProfilesRequestTimestamp: action.meta.timestamp,
				error: null,
			}
		case LIST_USER_PROFILES.failure:
			return {
				...state,
				isLoadingUserProfiles: false,
				error: action.error,
			}

		case GET_USER_PROFILE.request:
			return {
				...state,
				isLoadingUserProfile: true,
			}
		case GET_USER_PROFILE.success:
			return {
				...state,
				isLoadingUserProfile: false,
				userProfile: action.payload,
			}
		case GET_USER_PROFILE.failure:
			return {
				...state,
				isLoadingUserProfile: false,
				error: action.error,
			}

		case PATCH_USER_PROFILE.request:
			return {
				...state,
				isSaving: true,
			}
		case PATCH_USER_PROFILE.success:
			return {
				...state,
				isSaving: false,
				userProfile: action.payload,
			}
		case PATCH_USER_PROFILE.failure:
			return {
				...state,
				isSaving: false,
				error: action.error,
			}

		case LIST_OFF_HOURS.request:
			return {
				...state,
				isLoadingOffHoursEvents: true,
			}
		case LIST_OFF_HOURS.success:
			return {
				...state,
				isLoadingOffHoursEvents: false,
				offHoursEvents: action.payload,
			}
		case LIST_OFF_HOURS.failure:
			return {
				...state,
				isLoadingOffHoursEvents: false,
				error: action.error,
			}
		case EDIT_OFF_HOURS.request:
			return {
				...state,
				isSaving: true,
			}
		case EDIT_OFF_HOURS.success:
			return {
				...state,
				isSaving: false,
			}
		case EDIT_OFF_HOURS.failure:
			return {
				...state,
				isSaving: false,
				error: action.error,
			}

		default:
			return state
	}
}
export default lookups

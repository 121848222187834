import { Dispatch, ReactElement, SetStateAction } from "react"

import { IncludeUnusedSelector } from "../selectors"

export enum IncludeUnusedParamKey {
	IncludeUnused = "includeUnused",
}

export interface IncludeUnusedParamState {
	[IncludeUnusedParamKey.IncludeUnused]: boolean | null
}

export interface IncludeUnusedQueryFilterProps<QueryParamState extends IncludeUnusedParamState> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

export const IncludeUnusedQueryFilter = <QueryParamState extends IncludeUnusedParamState>({
	queryParamState,
	setQueryParamState,
}: IncludeUnusedQueryFilterProps<QueryParamState>): ReactElement => {
	return (
		<IncludeUnusedSelector
			value={
				queryParamState[IncludeUnusedParamKey.IncludeUnused] == null ? null
				: queryParamState[IncludeUnusedParamKey.IncludeUnused] === true ?
					"true"
				:	"false"
			}
			disableNoSelectionOption={false}
			fillContainer
			onChange={(newValue, newOption) =>
				setQueryParamState((prev) => {
					const newIncludeUnusedParamState: IncludeUnusedParamState = {
						[IncludeUnusedParamKey.IncludeUnused]: newOption?.booleanValue ?? null,
					}

					return {
						...prev,
						...newIncludeUnusedParamState,
					}
				})
			}
			tooltip="Include pricing items that do not have any customer memberships currently active?"
		/>
	)
}

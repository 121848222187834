import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import callApi from "@ncs/bricks/redux/services/callApi"
import { Select } from "@ncs/bricks/components"
import { loadLookups, LOOKUPS } from "../../redux/services/lookups"

class TicketStatusSelector extends React.PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			statuses: [],
			status: null,
		}
	}

	componentDidMount() {
		this.props.loadLookups([LOOKUPS.TicketStatuses])
	}

	render() {
		const { displayEmpty, ...rest } = this.props
		return (
			<Select
				id="ticketStatuses"
				labelText="Status"
				value={this.props.status || ""}
				onChange={this.props.onChange}
				options={[
					{
						id: "",
						description: "Select a status...",
						disabled: true,
					},
					...this.props.types,
				]}
				valueAccessor="id"
				textAccessor={(x) => x.description}
				{...rest}
			/>
		)
	}
}

TicketStatusSelector.defaultProps = {
	displayEmpty: true,
}

TicketStatusSelector.propTypes = {
	type: PropTypes.object,
	displayEmpty: PropTypes.bool,
}

const mapStateToProps = (state) => {
	return {
		types: state.lookups.ticketStatuses,
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			callApi,
			loadLookups,
		},
		dispatch
	)

export default connect(mapStateToProps, mapDispatchToProps)(TicketStatusSelector)

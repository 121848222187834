import { FC, useCallback, useMemo, useState } from "react"

import dayjs from "dayjs"
import { Cell, Column } from "react-table"

import {
	APPLICATION,
	FreightRate,
	FreightRatesQueryParams,
	useFreightRates,
	useUserCanUse,
} from "@ncs/ncs-api"
import {
	dateRangeIsActive,
	displayDate,
	formatCurrency,
	getTimezoneAbbreviation,
} from "@ncs/ts-utils"
import {
	Box,
	Button,
	CustomerQueryFilter as DefaultCustomerQueryFilter,
	CustomerQueryFilterProps,
	EmptyValueDash,
	Icon,
	ReactTableSortType,
	Table,
	Tooltip,
	useUrlState,
} from "@ncs/web-legos"

import { ActiveOnlyQueryFilter } from "~/components"

import { EditRateModal, FreightRateTypeQueryFilter } from "./components"

export type FreightRatesTabUrlState = FreightRatesQueryParams

export const FreightRatesTab: FC = () => {
	const canEdit = useUserCanUse(APPLICATION.FreightRatesAdmin)
	const [showCreate, setShowCreate] = useState(false)
	const [toEdit, setToEdit] = useState<FreightRate | null>(null)
	const [params, { setUrlState }] = useUrlState(defaultUrlState)

	const [rates, ratesLoading] = useFreightRates({ params })

	const getCurrentMax = useCallback(
		(rate: FreightRate): number | null => {
			// Look through the rates list for all the ones that are active, and if
			// we find any that match default / customer / product group, then find
			// the next highest beyond the one passed in.
			const sameKinds = (rates ?? [])
				.filter((r) => {
					if (dateRangeIsActive(r.startDate, r.endDate) === false) {
						return false
					}

					return (
						r.customerId === rate.customerId &&
						r.freightProductGroupId === rate.freightProductGroupId
					)
				})
				.sort((a, b) => (a.baseAmount > b.baseAmount ? 1 : -1))

			const matchingIndex = sameKinds.findIndex((r) => r.id === rate.id)

			if (matchingIndex + 1 < sameKinds.length) {
				return sameKinds[matchingIndex + 1].baseAmount - 0.01
			}

			return null
		},
		[rates]
	)

	const columns: Column<FreightRate>[] = useMemo(
		() => [
			{
				Header: "Type",
				accessor: (original) => {
					const types = []
					if (original.customerId) types.push("Customer")
					if (original.freightProductGroupId) types.push("Products")
					if (!types.length) types.push("Default")

					return types.join(" / ")
				},
			},
			{
				Header: "Order min $",
				accessor: "baseAmount",
				Cell: ({ row: { original } }: Cell<FreightRate>) =>
					formatCurrency(original.baseAmount),
				sortType: ReactTableSortType.Number,
			},
			{
				Header: "Current order max $",
				disableSortBy: true,
				accessor: (original) => {
					if (dateRangeIsActive(original.startDate, original.endDate)) {
						const max = getCurrentMax(original)

						if (max) return formatCurrency(max)
					}
					return <EmptyValueDash />
				},
			},
			{
				Header: "Rate $",
				accessor: "rate",
				Cell: ({ row: { original } }: Cell<FreightRate>) => formatCurrency(original.rate),
				sortType: ReactTableSortType.Number,
			},
			{
				Header: "Customer",
				accessor: ({ customerName, customerNumber }) =>
					customerName ? `(${customerNumber}) ${customerName}` : <EmptyValueDash />,
			},
			{
				Header: "Product group",
				accessor: ({ freightProductGroupName }) =>
					freightProductGroupName || <EmptyValueDash />,
			},
			{
				Header: "Start date",
				accessor: ({ startDate }) => new Date(startDate),
				Cell: ({ row: { original } }: Cell<FreightRate>) =>
					displayDate(original.startDate),
				sortType: ReactTableSortType.Datetime,
			},
			{
				Header: "End date",
				accessor: ({ endDate }) => new Date(endDate),
				Cell: ({ row: { original } }: Cell<FreightRate>) => displayDate(original.endDate),
				sortType: ReactTableSortType.Datetime,
			},
			{
				Header: "Status",
				Cell: ({ row: { original } }: Cell<FreightRate>) => {
					return (
						dateRangeIsActive(original.startDate, original.endDate) ?
							<>
								<Icon icon="check" /> Active
							</>
						: dayjs(original.endDate).isBefore(original.startDate) ?
							<Tooltip title="Date range can never be active because end date comes before start date">
								<Icon icon="exclamation-triangle" />
							</Tooltip>
						:	<EmptyValueDash />
					)
				},
			},
			{
				Header: `Created (${getTimezoneAbbreviation()})`,
				accessor: ({ createdOn }) => new Date(createdOn),
				Cell: ({ row: { original } }: Cell<FreightRate>) =>
					displayDate(original.createdOn),
				sortType: ReactTableSortType.Datetime,
				hiddenByDefault: true,
			},
			{
				Header: "Created by",
				accessor: "userCreated",
				hiddenByDefault: true,
			},
			{
				Header: `Last modified (${getTimezoneAbbreviation()})`,
				accessor: ({ modifiedOn }) => new Date(modifiedOn),
				Cell: ({ row: { original } }: Cell<FreightRate>) =>
					displayDate(original.modifiedOn),
				sortType: ReactTableSortType.Datetime,
				hiddenByDefault: true,
			},
			{
				Header: "Last modified by",
				accessor: "userLastModified",
				hiddenByDefault: true,
			},
		],
		[getCurrentMax]
	)

	const filtersStartOpen = useMemo(() => {
		return !!(
			params.active !== true ||
			params.defaults != null ||
			params.customers != null ||
			params.products != null ||
			params.customerId
		)
	}, [params.active, params.defaults, params.customers, params.products, params.customerId])

	return (
		<>
			{canEdit && (
				<Box d="flex" justifyContent="flex-end" mb={4}>
					<Button
						icon="plus-circle"
						variant="secondary-cta"
						onClick={() => setShowCreate(true)}
					>
						Create New Rate
					</Button>
				</Box>
			)}

			<Table
				data={rates ?? []}
				columns={columns}
				isLoading={ratesLoading}
				onRowClick={canEdit ? ({ original }) => setToEdit(original) : undefined}
				queryParamState={params}
				setQueryParamState={setUrlState}
				toggledQueryFilters={[
					CustomerQueryFilter,
					ActiveOnlyQueryFilter,
					FreightRateTypeQueryFilter,
				]}
				filterResetValues={defaultUrlState}
				showToggledFiltersByDefault={filtersStartOpen}
			/>

			{(showCreate || !!toEdit) && (
				<EditRateModal
					toEdit={toEdit}
					onClose={() => {
						setShowCreate(false)
						setToEdit(null)
					}}
				/>
			)}
		</>
	)
}

const CustomerQueryFilter = (props: CustomerQueryFilterProps<FreightRatesTabUrlState>) => {
	return <DefaultCustomerQueryFilter {...props} paramKey="customerId" />
}

const defaultUrlState: FreightRatesTabUrlState = {
	active: true,
	defaults: null,
	customers: null,
	products: null,
	customerId: null,
}

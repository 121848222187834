import { FC, useState } from "react"

import { Dayjs } from "dayjs"
import { Column } from "react-table"

import {
	defaultVacuumMessagesQueryParams,
	useExportVacuumMessages,
	useVacuumMessages,
	VacuumMessageHistory,
	VacuumMessagesQueryParams,
} from "@ncs/ncs-api"
import { DateFormat, formatDate } from "@ncs/ts-utils"
import { EmptyValueDash, SearchQueryFilter, Table } from "@ncs/web-legos"

export interface TelemetryTabProps {
	deviceId: string | null
	startDate: Dayjs
	endDate: Dayjs
	isRefreshing?: boolean
}

export const TelemetryTab: FC<TelemetryTabProps> = ({
	deviceId,
	startDate,
	endDate,
	isRefreshing,
}) => {
	// Will just be given to Table for ordering.
	const [paramState, setParamState] = useState<VacuumMessagesQueryParams>(
		defaultVacuumMessagesQueryParams
	)

	const combinedParams: VacuumMessagesQueryParams = {
		// Table params.
		...paramState,
		// The rest of the params.
		device: deviceId,
		startDate: startDate.utc().format(DateFormat.DateTimeQueryParamNoTz),
		endDate: endDate.utc().format(DateFormat.DateTimeQueryParamNoTz),
	}

	const messagesQuery = useVacuumMessages(combinedParams)
	const exportVacuumMessages = useExportVacuumMessages(combinedParams)

	return (
		<Table
			query={messagesQuery}
			columns={columns}
			isUpdating={isRefreshing}
			queryParamState={paramState}
			setQueryParamState={setParamState}
			toggledQueryFilters={[SearchQueryFilter]}
			dataExport={exportVacuumMessages}
		/>
	)
}

const columns: Column<VacuumMessageHistory>[] = [
	{
		Header: "Timestamp",
		id: "collectedOn",
		accessor: (original) => formatDate(original.collectedOn, DateFormat.DateTimeSeconds),
	},
	{
		Header: "Message",
		accessor: (original) => original.message ?? <EmptyValueDash />,
		disableSortBy: true,
	},
	{
		Header: "Status",
		id: "status",
		accessor: (original) => original.status ?? <EmptyValueDash />,
		hiddenByDefault: true,
	},
	{
		Header: "Ref Frequency 1",
		id: "refFrequency1",
		accessor: (original) => original.refFrequency1 ?? <EmptyValueDash />,
		hiddenByDefault: true,
	},
	{
		Header: "Ref Frequency 2",
		id: "refFrequency2",
		accessor: (original) => original.refFrequency2 ?? <EmptyValueDash />,
		hiddenByDefault: true,
	},
	{
		Header: "Output Frequency 1",
		id: "outputFrequency1",
		accessor: (original) => original.outputFrequency1 ?? <EmptyValueDash />,
		hiddenByDefault: true,
	},
	{
		Header: "Output Frequency 2",
		id: "outputFrequency2",
		accessor: (original) => original.outputFrequency2 ?? <EmptyValueDash />,
		hiddenByDefault: true,
	},
	{
		Header: "Output Power 1",
		id: "outputPower1",
		accessor: (original) => original.outputPower1 ?? <EmptyValueDash />,
	},
	{
		Header: "Output Power 2",
		id: "outputPower2",
		accessor: (original) => original.outputPower2 ?? <EmptyValueDash />,
		hiddenByDefault: true,
	},
	{
		Header: "Output Current 1",
		id: "outputCurrent1",
		accessor: (original) => original.outputCurrent1 ?? <EmptyValueDash />,
	},
	{
		Header: "Output Current 2",
		id: "outputCurrent2",
		accessor: (original) => original.outputCurrent2 ?? <EmptyValueDash />,
		hiddenByDefault: true,
	},
	{
		Header: "System Pressure",
		id: "p1",
		accessor: (original) => original.p1 ?? <EmptyValueDash />,
	},
	{
		Header: "Filter Pressure",
		id: "p2",
		accessor: (original) => original.p2 ?? <EmptyValueDash />,
	},
	{
		Header: "Vib 1",
		id: "vib1",
		accessor: (original) => original.vib1 ?? <EmptyValueDash />,
	},
	{
		Header: "Vib 2",
		id: "vib2",
		accessor: (original) => original.vib2 ?? <EmptyValueDash />,
		hiddenByDefault: true,
	},
]

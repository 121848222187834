import React from "react"
import PropTypes from "prop-types"
import { Switch, withStyles } from "@material-ui/core"

import switchStyles from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch"

const CustomSwitch = (props) => {
	const { classes, leftValue, rightValue, ...rest } = props

	return (
		<div>
			{leftValue}
			<Switch
				{...rest}
				classes={{
					switchBase: classes.switchBase,
				}}
			/>
			{rightValue}
		</div>
	)
}

CustomSwitch.propTypes = {
	leftValue: PropTypes.node,
	rightValue: PropTypes.node,
	checked: PropTypes.bool.isRequired,
	onChange: PropTypes.func,
}

export default withStyles(switchStyles)(CustomSwitch)

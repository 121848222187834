import { FC, useState } from "react"

import { makeApiErrorMessage, useReassignPickList, UserMinimal } from "@ncs/ncs-api"
import { ExtendableModalProps, Modal, Paragraph, UserSelector, useToast } from "@ncs/web-legos"

export interface PickListReassignModalProps extends ExtendableModalProps {
	pickListId: string
}

export const PickListReassignModal: FC<PickListReassignModalProps> = ({ pickListId, ...rest }) => {
	const { makeSuccessToast } = useToast()
	const [user, setUser] = useState<UserMinimal | null>(null)
	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const reassignList = useReassignPickList()

	const onSave = async () => {
		try {
			if (!user) {
				throw new Error("No user selected")
			}

			setIsSaving(true)
			await reassignList({
				pickListId,
				userId: user.id,
			})
			makeSuccessToast("Pick list updated")
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	return (
		<Modal
			{...rest}
			title="Reassign Pick List"
			errorText={errorText}
			rightButtons={{
				buttonText: "Update",
				onClick: onSave,
				isLoading: isSaving,
			}}
		>
			<Paragraph mb={1}>Choose a new assignment for this pick list:</Paragraph>
			<UserSelector
				value={user}
				onChange={setUser}
				label={null}
				placeholder="Search..."
				employeesOnly
				autoFocus
			/>
		</Modal>
	)
}

import { FC } from "react"

import dayjs from "dayjs"
import { Column } from "react-table"

import {
	ChemicalRevSummaryReportLine,
	ChemicalRevSummaryReportQueryParams,
	useChemicalRevSummaryReport,
	useExportChemicalRevSummaryReport,
} from "@ncs/ncs-api"
import {
	DateFormat,
	extractNumber,
	formatCurrency,
	getStartAndEndDateQueryParams,
	TimeAgo,
	Typify,
} from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	CustomerQueryFilter,
	EnterpriseQueryFilter,
	OrganizationQueryFilter,
	RegionQueryFilter,
	Table,
	TerritoryQueryFilter,
	TimeAgoQueryFilter,
	usePaginationUrlState,
	useUrlState,
} from "@ncs/web-legos"

export type ChemicalRevSummaryReportUrlState = Typify<ChemicalRevSummaryReportQueryParams>

export const ChemicalRevSummaryReport: FC = () => {
	const [queryParams, { setUrlState: setQueryParams }] =
		useUrlState<ChemicalRevSummaryReportUrlState>(initialChemicalRevSummaryReportUrlState)
	const [pagination, setPagination] = usePaginationUrlState({
		defaultPageSize: 25,
	})

	const query = useChemicalRevSummaryReport({
		params: { ...queryParams, ...pagination },
		manualPagination: true,
	})
	const dataExport = useExportChemicalRevSummaryReport(queryParams)

	return (
		<AnimatedEntrance show>
			<Table
				query={query}
				columns={columns}
				queryParamState={queryParams}
				setQueryParamState={setQueryParams}
				pinnedQueryFilters={[
					TimeAgoQueryFilter,
					CustomerQueryFilter,
					OrganizationQueryFilter,
					TerritoryQueryFilter,
				]}
				toggledQueryFilters={[RegionQueryFilter, EnterpriseQueryFilter]}
				filterResetValues={initialChemicalRevSummaryReportUrlState}
				pagination={pagination}
				setPagination={setPagination}
				dataExport={dataExport}
				disableAllSorting
				storeColumnVisibility="chemical-rev-summary-report-columns"
			/>
		</AnimatedEntrance>
	)
}

const columns: Column<ChemicalRevSummaryReportLine>[] = [
	{
		Header: "Month",
		accessor: ({ month, year }) => dayjs(`${month}-01-${year}`).format(DateFormat.MonthYear),
	},
	{
		Header: "Territory",
		accessor: ({ territoryCode, territoryDescription }) =>
			`(${territoryCode}) ${territoryDescription}`,
		hiddenByDefault: true,
	},
	{
		Header: "Customer",
		accessor: (row) => `(${row.customerNumber}) ${row.customerName}`,
	},
	{
		Header: "Revenue total",
		accessor: ({ deliveryOrderRevenue, partOrderRevenue, workOrderRevenue }) => {
			return formatCurrency(
				extractNumber(deliveryOrderRevenue) +
					extractNumber(partOrderRevenue) +
					extractNumber(workOrderRevenue)
			)
		},
	},
	{
		Header: "Delivery order revenue",
		accessor: ({ deliveryOrderRevenue }) => {
			return formatCurrency(extractNumber(deliveryOrderRevenue))
		},
		hiddenByDefault: true,
	},
	{
		Header: "Part order revenue",
		accessor: ({ partOrderRevenue }) => {
			return formatCurrency(extractNumber(partOrderRevenue))
		},
		hiddenByDefault: true,
	},
	{
		Header: "Work order revenue",
		accessor: ({ workOrderRevenue }) => {
			return formatCurrency(extractNumber(workOrderRevenue))
		},
		hiddenByDefault: true,
	},
	{
		Header: "CoGs total",
		accessor: ({ deliveryOrderCogs, partOrderCogs, workOrderCogs }) => {
			return formatCurrency(
				extractNumber(deliveryOrderCogs) +
					extractNumber(partOrderCogs) +
					extractNumber(workOrderCogs)
			)
		},
	},
	{
		Header: "Delivery order CoGs",
		accessor: ({ deliveryOrderCogs }) => {
			return formatCurrency(extractNumber(deliveryOrderCogs))
		},
		hiddenByDefault: true,
	},
	{
		Header: "Part order CoGs",
		accessor: ({ partOrderCogs }) => {
			return formatCurrency(extractNumber(partOrderCogs))
		},
		hiddenByDefault: true,
	},
	{
		Header: "Work order CoGs",
		accessor: ({ workOrderCogs }) => {
			return formatCurrency(extractNumber(workOrderCogs))
		},
		hiddenByDefault: true,
	},
]

const initialChemicalRevSummaryReportUrlState: ChemicalRevSummaryReportUrlState = {
	ordering: null,
	organization: null,
	region: null,
	enterprise: null,
	territory: null,
	customer: null,
	startDate: getStartAndEndDateQueryParams(TimeAgo.DaysAgo30)[0],
	endDate: null,
}

import { FC, useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { FormProvider, useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"

import {
	makeApiErrorMessage,
	useCreateContractDocument,
	useCreateContractIncentives,
	useCreateContractRecipients,
	useCreateOrganizationContract,
} from "@ncs/ncs-api"
import {
	Box,
	Button,
	Divider,
	ErrorText,
	HeadingDivider,
	HeadingDividerProps,
} from "@ncs/web-legos"

import {
	contractFormToContractPost,
	NewContractForm,
	newContractFormDefaultValues,
	NewContractFormSchema,
} from "../contract-utils"
import { NewContractDocuments } from "./NewContractDocuments"
import { NewContractIncentives } from "./NewContractIncentives"
import { NewContractMain } from "./NewContractMain"
import { NewContractRecipients } from "./NewContractRecipients"
import { NewContractSchedules } from "./NewContractSchedules"

const headingProps: HeadingDividerProps = {
	headingVariant: "h4",
	bold: true,
	mt: 3,
}

export const ContractsNewContractTab: FC = () => {
	const history = useHistory()
	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const createContract = useCreateOrganizationContract()
	const createIncentives = useCreateContractIncentives()
	const createRecipients = useCreateContractRecipients()
	const createDocument = useCreateContractDocument()

	const form = useForm<NewContractForm>({
		resolver: zodResolver(NewContractFormSchema),
		defaultValues: newContractFormDefaultValues,
	})

	const { handleSubmit, formState, trigger } = form
	const { submitCount, isValid } = formState

	const submit = async (formData: NewContractForm) => {
		try {
			setIsSaving(true)
			await trigger()
			NewContractFormSchema.parse(formData)

			// We'll need to create the incentives, recipients, and documents after the contract has
			// initially been created.
			const { rebates, credits, discounts, recipients, documents, ...restOfForm } =
				contractFormToContractPost(formData)

			// Contract POST returns the new ID.
			const { data: newId } = await createContract(restOfForm)

			// Now create the relations.
			await Promise.all([
				createIncentives({
					contract: newId.toString(),
					rebates,
					credits,
					discounts,
				}),
				createRecipients({
					contract: newId.toString(),
					recipients,
				}),
				documents.map(({ description, file }) =>
					createDocument({
						contract: newId.toString(),
						description,
						file,
					})
				),
			])

			// Now go...
			history.push(`/contracts/${newId}`)
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	return (
		<FormProvider {...form}>
			<HeadingDivider {...headingProps} mt={0} icon="calendar">
				Contract Details
			</HeadingDivider>
			<Box pl={1.5} xsProps={{ pl: 0 }}>
				<NewContractMain />
			</Box>

			<HeadingDivider {...headingProps} icon="file-invoice-dollar">
				Pricing Schedules
			</HeadingDivider>
			<Box pl={1.5} xsProps={{ pl: 0 }}>
				<NewContractSchedules />
			</Box>

			<HeadingDivider {...headingProps} icon="hand-holding-dollar">
				Incentive Programs
			</HeadingDivider>
			<Box pl={1.5} xsProps={{ pl: 0 }}>
				<NewContractIncentives />
			</Box>

			<HeadingDivider {...headingProps} icon="folder-open">
				Documents
			</HeadingDivider>
			<Box pl={1.5} xsProps={{ pl: 0 }}>
				<NewContractDocuments />
			</Box>

			<HeadingDivider {...headingProps} icon="envelope">
				Email Notification Recipients
			</HeadingDivider>
			<Box pl={1.5} xsProps={{ pl: 0 }}>
				<NewContractRecipients />
			</Box>

			<Divider mt={5} />
			<Box textAlign="center" pt={2} pb={1}>
				{/* Errors from the submit call, which happens after we've made it through
				hook form's validation. */}
				{!!errorText && <ErrorText mb={1}>{errorText}</ErrorText>}

				{/* We don't always jump to top of page on hook form error, so show an extra
				notice down here. */}
				{submitCount > 0 && !isValid && (
					<ErrorText mb={1}>
						Please check the form for errors before continuing
					</ErrorText>
				)}

				<Button
					fillContainer
					variant="primary-cta"
					onClick={handleSubmit(submit)}
					containerProps={{ maxWidth: 50 }}
					disabled={submitCount > 0 && !isValid}
					isLoading={isSaving}
				>
					Create Contract
				</Button>
			</Box>
		</FormProvider>
	)
}

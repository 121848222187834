import { FC, memo, useMemo, useState } from "react"

import {
	InventoryPart,
	makeApiErrorMessage,
	PricingMatrix,
	useCreatePricingMatrixItem,
} from "@ncs/ncs-api"
import { extractNumber } from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	CheckboxGroup,
	ExtendableModalProps,
	Modal,
	NumericInput,
	Paragraph,
	PartSelector,
	RadioGroup,
	useToast,
} from "@ncs/web-legos"

export interface EditPricingMatrixModalProps extends ExtendableModalProps {
	matrix: PricingMatrix | null
}

type AdjustmentOption = "discount" | "markup" | "price"

const adjustmentOptions: {
	value: AdjustmentOption
	label: string
}[] = [
	{
		value: "discount",
		label: "Decreased with a discount percentage",
	},
	{
		value: "markup",
		label: "Increased with a markup percentage",
	},
	{
		value: "price",
		label: "Using a fixed exception price",
	},
]

export const EditPricingMatrixItemModal: FC<EditPricingMatrixModalProps> = memo(
	({ matrix, ...rest }) => {
		const createPart = useCreatePricingMatrixItem()
		const { makeSuccessToast } = useToast()

		const [part, setPart] = useState<InventoryPart | null>(null)
		const [discount, setDiscount] = useState<number | null>(null)
		const [markup, setMarkup] = useState<number | null>(null)
		const [price, setPrice] = useState<number | null>(null)
		const [locked, setLocked] = useState(false)

		// Do they want to set a discount, markup, or fixed price?
		const [type, setType] = useState<AdjustmentOption>("discount")

		const [isLoading, setIsLoading] = useState(false)
		const [errorText, setErrorText] = useState<string | null>(null)

		const onSubmit = async () => {
			if (isValid && part && matrix) {
				try {
					setIsLoading(true)
					await createPart({
						partId: part.id,
						pricingMatrixId: matrix.id,
						discountRate:
							normalizedDiscountRate == null ? normalizedDiscountRate : (
								extractNumber(
									normalizedDiscountRate.toFixed(percentDecimalScale + 2) // Add 2 because we divided by 100
								)
							),
						exceptionPrice: type === "price" ? price : null,
						locked: type !== "price" ? locked : false,
					})
					makeSuccessToast("Part added to matrix")
					rest.onClose()
				} catch (e) {
					setErrorText(makeApiErrorMessage(e))
					setIsLoading(false)
				}
			}
		}

		const reset = () => {
			setPart(null)
			setType("discount")
			setDiscount(null)
			setMarkup(null)
			setPrice(null)
			setLocked(false)
			setIsLoading(false)
			setErrorText(null)
		}

		/** Looks at both discount and markup to decide what the final value to send to the backend is. */
		const normalizedDiscountRate = useMemo(() => {
			switch (type) {
				case "price":
					return null
				case "discount":
					return discount != null ? discount / 100 : null
				case "markup":
					return markup != null ? 0 - markup / 100 : null
			}
		}, [type, discount, markup])

		const isValid = (normalizedDiscountRate != null || price != null) && !!part && !!matrix

		return (
			<Modal
				title="Add Part To Matrix"
				{...rest}
				onOpen={reset}
				rightButtons={{
					buttonText: "Add Part",
					disabled: !isValid,
					isLoading,
					onClick: onSubmit,
				}}
				errorText={errorText}
			>
				<PartSelector
					value={part}
					onChange={setPart}
					autoFocus
					label="Select part"
					skipRestrictedCheck
				/>

				<RadioGroup
					description="How do you want to adjust the price for this part?"
					htmlName="price-adjust"
					options={adjustmentOptions}
					value={type}
					onChange={(value, option) => {
						setType(option.value)
						setDiscount(null)
						setMarkup(null)
						setPrice(null)
						if (option.value === "price") {
							setLocked(false)
						}
					}}
					mt={1}
				/>

				<AnimatedEntrance show={type === "discount"}>
					<NumericInput
						value={discount}
						onChange={(newValue) => setDiscount(newValue ?? null)}
						label="Discount percentage %"
						min={1}
						max={100}
						allowNegative={false}
						decimalScale={percentDecimalScale}
						maxWidth={12}
					/>
				</AnimatedEntrance>

				<AnimatedEntrance show={type === "markup"}>
					<NumericInput
						value={markup}
						onChange={(newValue) => setMarkup(newValue ?? null)}
						label="Markup percentage %"
						min={1}
						max={100}
						allowNegative={false}
						decimalScale={percentDecimalScale}
						maxWidth={12}
					/>
				</AnimatedEntrance>

				<AnimatedEntrance show={type === "discount" || type === "markup"} mt={2}>
					<Paragraph mb={0.5}>
						If the <strong>locked</strong> option is selected, the customer's price
						will not change if the part's standard list price goes up or down in the
						future.
					</Paragraph>
					<CheckboxGroup
						rows={[
							{
								value: true,
								label: "Lock price?",
							},
						]}
						valueAccessor={(row) => String(row.value)}
						checkedAccessor={() => locked}
						onChange={(row, newState) => {
							setLocked(newState)
						}}
					/>
				</AnimatedEntrance>

				<AnimatedEntrance show={type === "price"}>
					<NumericInput
						value={price}
						onChange={(newValue) => setPrice(newValue ?? null)}
						label="Exception price $"
						decimalScale={2}
						maxWidth={12}
						min={0}
						allowNegative={false}
					/>
				</AnimatedEntrance>
			</Modal>
		)
	}
)

const percentDecimalScale = 3

EditPricingMatrixItemModal.displayName = "EditPricingMatrixItemModal"

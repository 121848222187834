import { FC, useState } from "react"

import { Cell, Column } from "react-table"

import { CustomerDetail, SurveyResponse, useSiteSurveyResponses } from "@ncs/ncs-api"
import { formatDateTime, getTimezoneAbbreviation } from "@ncs/ts-utils"
import { Box, Button, DownloadDocumentButton, EmptyValueDash, Icon, Table } from "@ncs/web-legos"

import { RequestSurveyModal } from "./components"

export interface CustomerSiteSurveysTabProps {
	customer: CustomerDetail
}

const columns: Column<SurveyResponse>[] = [
	{
		Header: "Customer",
		id: "customer",
		hiddenByDefault: true,
		Cell: ({
			row: {
				original: { customer },
			},
		}: Cell<SurveyResponse>) =>
			customer ? `(${customer?.customerNumber}) ${customer?.name}` : <EmptyValueDash />,
	},
	{
		Header: `Date created (${getTimezoneAbbreviation()})`,
		id: "createdDate",
		Cell: ({ row: { original } }: Cell<SurveyResponse>) =>
			original.createdDate ? formatDateTime(original.createdDate) : <EmptyValueDash />,
	},
	{
		Header: "Requested by",
		accessor: ({ requestedBy }) => requestedBy.name,
		hiddenByDefault: true,
	},
	{
		Header: "Assigned to",
		accessor: ({ assignedTo }) => assignedTo.name,
	},
	{
		Header: `Date assigned (${getTimezoneAbbreviation()})`,
		id: "assignedDate",
		Cell: ({ row: { original } }: Cell<SurveyResponse>) =>
			original.assignedDate ? formatDateTime(original.assignedDate) : <EmptyValueDash />,
	},
	{
		Header: "Is submitted?",
		id: "isSubmitted",
		Cell: ({ row: { original } }: Cell<SurveyResponse>) =>
			original.isSubmitted ?
				<Box display="flex" alignItems="center" columnGap={0.5}>
					<Icon icon="check" color="gray" />
					Submitted
				</Box>
			:	<EmptyValueDash />,
	},
	{
		Header: "Full document",
		id: "fullDocument",
		Cell: ({ row: { original } }: Cell<SurveyResponse>) => {
			return original.surveyDocumentFull.length ?
					<Box display="flex" flexDirection="column" rowGap={0.5}>
						{original.surveyDocumentFull.map((d) => (
							<DownloadDocumentButton
								key={d.id}
								documentId={d.id}
								documentType={16}
								buttonText={`Full Doc #${d.id}`}
							/>
						))}
					</Box>
				:	<EmptyValueDash />
		},
	},
	{
		Header: "360 document",
		id: "360Document",
		Cell: ({ row: { original } }: Cell<SurveyResponse>) => {
			return original.surveyDocument360.length ?
					<Box display="flex" flexDirection="column" rowGap={0.5}>
						{original.surveyDocument360.map((d) => (
							<DownloadDocumentButton
								key={d.id}
								documentId={d.id}
								documentType={16}
								buttonText={`360 Doc #${d.id}`}
							/>
						))}
					</Box>
				:	<EmptyValueDash />
		},
	},
]

export const CustomerSiteSurveysTab: FC<CustomerSiteSurveysTabProps> = ({ customer }) => {
	const [showRequestModal, setShowRequestModal] = useState(false)

	const [surveys, surveysLoading] = useSiteSurveyResponses(customer.id)

	return (
		<>
			<Button icon="plus" onClick={() => setShowRequestModal(true)}>
				Request Site Survey
			</Button>

			<Table
				data={surveys ?? []}
				columns={columns}
				isLoading={surveysLoading}
				noDataText="No surveys found for this site"
				disableAllSorting
			/>

			<RequestSurveyModal
				isOpen={showRequestModal}
				onClose={() => setShowRequestModal(false)}
				customer={customer}
			/>
		</>
	)
}

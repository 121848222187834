import { FC, useState } from "react"

import { Dayjs } from "dayjs"

import { LaborPricingDetails, makeApiErrorMessage, useCreateLaborPricing } from "@ncs/ncs-api"
import { DateFormat } from "@ncs/ts-utils"
import {
	Box,
	DateInput,
	ExtendableModalProps,
	Modal,
	NumericInput,
	Paragraph,
	useIsSaving,
	useToast,
} from "@ncs/web-legos"

export interface NewPriceChangeModalProps extends ExtendableModalProps {
	laborPricing: LaborPricingDetails
}

export const NewPriceChangeModal: FC<NewPriceChangeModalProps> = ({ laborPricing, ...rest }) => {
	const { makeSuccessToast } = useToast()
	const [newRate, setNewRate] = useState<number | null>(null)
	const [startDate, setStartDate] = useState<Dayjs | null>(null)
	const [endDate, setEndDate] = useState<Dayjs | null>(null)
	const [errorText, setErrorText] = useState<string | null>(null)
	const { isSaving, setSaving, endSaving } = useIsSaving()

	const createRate = useCreateLaborPricing()

	const handleSave = async () => {
		try {
			if (newRate == null) {
				throw new Error("Enter a valid new rate")
			}
			if (!startDate) {
				throw new Error("Enter a date for the new rate to start on")
			}
			if (!endDate) {
				throw new Error("End date is required")
			}
			if (endDate.isBefore(startDate)) {
				throw new Error("End date must come after start date")
			}

			setSaving()
			await createRate({
				laborPricingId: laborPricing.id,
				price: newRate,
				startsOn: startDate.format(DateFormat.DateQueryParam),
				endsAfter: endDate.format(DateFormat.DateQueryParam),
			})
			makeSuccessToast("New price change scheduled")
			rest.onClose()
		} catch (e) {
			endSaving()
			setErrorText(makeApiErrorMessage(e))
		}
	}

	return (
		<Modal
			{...rest}
			title="New Price Change"
			errorText={errorText}
			rightButtons={{
				buttonText: "Save",
				onClick: handleSave,
				isLoading: isSaving(),
			}}
		>
			<Paragraph mb={2}>
				Define a new rate and when that rate should start. The rate currently in effect
				will be set to end the day before the new one starts.
			</Paragraph>

			<Box maxWidth="50%" smProps={{ maxWidth: "none" }}>
				<NumericInput
					value={newRate}
					onChange={(newValue) => setNewRate(newValue ?? null)}
					label="New rate"
					placeholder="Eg, 150"
					decimalScale={2}
					fixedDecimalScale
				/>
				<DateInput
					value={startDate}
					onChange={setStartDate}
					label="Effective on"
					disablePast
				/>
				<DateInput value={endDate} onChange={setEndDate} label="Ends after" disablePast />
			</Box>
		</Modal>
	)
}

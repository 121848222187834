import { createStyles } from "@material-ui/core"
import { primaryColor, successColor } from "../../assets/jss/material-dashboard-pro-react"
import { WizardPane } from "./Wizard"

export interface WizardStyleProps {
	panes: WizardPane[]
	paneHeight: string
}

export const wizardStyles = createStyles({
	container: {
		position: "relative",
		"& .MuiRadio-colorSecondary": {
			color: primaryColor[0],
		},
	},

	stepsContainer: {
		display: "grid",
		gridTemplateColumns: (props: WizardStyleProps) => props.panes.map(() => "1fr").join(" "),
		maxWidth: "52rem",
		margin: "2rem auto 3rem auto",
		justifyContent: "space-between",
		"& button": {
			position: "relative",
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			background: "none",
			border: "none",
			cursor: "pointer",
			fontFamily: "inherit",
			zIndex: 1,
			"&:disabled": {
				cursor: "auto",
			},
		},
	},

	step: {
		position: "relative",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		"& .title": {
			fontSize: "0.75rem",
			textTransform: "uppercase",
		},
		"& .connector": {
			position: "absolute",
			top: "8px",
			left: "50%",
			width: "100%",
			height: "2px",
			borderTop: "2px dashed #ddd",
			transition: "border 300ms ease-out",
		},
		"& .blip": {
			position: "relative",
			height: "1.25rem",
			width: "1.25rem",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			borderRadius: "100%",
			marginBottom: "1rem",
			boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.25)",
			transform: "scale(1)",
			transition: "background 300ms ease-out, transform 300ms ease-out",
			"& span": {
				position: "relative",
				top: "-1px",
				color: "white",
				fontSize: "0.75rem",
				transition: "color 300ms ease-out",
				fontWeight: "bold",
				lineHeight: "1em",
			},
			"& .check": {
				top: 0,
			},
		},
	},

	stepIncomplete: {
		"& .blip": {
			background: "white",
			"& span": {
				color: "#bbb",
			},
		},
	},

	stepCurrent: {
		"& .title": {
			color: primaryColor[0],
			fontWeight: "bold",
		},
		"& .blip": {
			background: primaryColor[0],
			transform: "scale(1.5)",
		},
	},

	stepComplete: {
		"& .connector": {
			borderTop: "2px solid #c7dbe8",
		},
		"& .blip": {
			background: successColor[0],
		},
		"& .icon": {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			position: "absolute",
			top: "0",
			height: "1rem",
			width: "1rem",
			left: "50%",
			transform: "translateX(-50%)",
		},
	},

	panesContainer: {
		position: "relative",
		overflowX: "hidden",
		overflowY: "visible",
		height: (props) => props.paneHeight,
	},

	pane: {
		position: "absolute",
		top: "0",
		right: "2rem",
		left: "2rem",
		height: "100%",
		transform: "translateX(0)",
		transition: "transform 400ms cubic-bezier(0.33, 1, 0.68, 1)",
	},
	hiddenLeft: {
		transform: "translateX(calc(-100% - 4rem))",
		visibility: "hidden",
	},
	hiddenRight: {
		transform: "translateX(calc(100% + 4rem))",
		visibility: "hidden",
	},

	footer: {
		display: "flex",
		padding: "2rem",
		"& .MuiButton-contained": {
			color: "white",
			backgroundColor: primaryColor[0],
		},
	},
	leftButton: {
		marginRight: "auto",
	},
	rightButton: {
		marginLeft: "auto",
	},

	loadingContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		height: "100%",
		"& p": {
			marginTop: "2em",
		},
	},

	disabled: {
		opacity: 0.5,
		pointerEvents: "none",
	},
})

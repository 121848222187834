import { FC } from "react"

import dayjs from "dayjs"
import { useHistory } from "react-router-dom"
import { Column } from "react-table"

import {
	initialPurchaseOrdersQueryParams,
	PurchaseOrder,
	PurchaseOrdersQueryParams,
	usePurchaseOrders,
} from "@ncs/ncs-api"
import {
	formatDateTime,
	getStartAndEndDateQueryParams,
	getTimeAgoFromStartDate,
	getTimezoneAbbreviation,
	Typify,
} from "@ncs/ts-utils"
import {
	CustomerQueryFilter,
	EmptyValueDash,
	PurchaseOrderStatusQueryFilter,
	SearchQueryFilter,
	Table,
	TerritoryQueryFilter,
	TimeAgoQueryFilter,
	usePaginationUrlState,
	useUrlState,
	VendorQueryFilter,
	WarehouseQueryFilter,
} from "@ncs/web-legos"

export type PurchaseOrdersViewTabUrlState = Typify<PurchaseOrdersQueryParams>

export const PurchaseOrdersViewTab: FC = () => {
	const history = useHistory()

	const [filterParams, { setUrlState }] = useUrlState<PurchaseOrdersViewTabUrlState>({
		...initialPurchaseOrdersQueryParams,
		startDate,
	})
	const [pagination, setPagination] = usePaginationUrlState()

	const params = {
		...filterParams,
		page: pagination.page,
		pageSize: pagination.pageSize,
	}

	const purchaseOrders = usePurchaseOrders({
		manualPagination: true,
		params,
	})

	const showMoreFiltersByDefault = Object.entries(filterParams)
		.filter(([key]) => ["vendor", "warehouse"].includes(key))
		.some(([, value]) => !!value)

	return (
		<>
			<Table
				query={purchaseOrders}
				columns={columns}
				queryParamState={filterParams}
				setQueryParamState={setUrlState}
				pinnedQueryFilters={[
					SearchQueryFilter,
					CustomerQueryFilter,
					TerritoryQueryFilter,
					PurchaseOrderStatusQueryFilter,
					TimeAgoQueryFilter,
				]}
				pagination={pagination}
				setPagination={setPagination}
				toggledQueryFilters={[WarehouseQueryFilter, VendorQueryFilter]}
				showToggledFiltersByDefault={showMoreFiltersByDefault}
				onRowClick={({ original }) =>
					history.push(`/purchase-orders/${original.id}`, { params })
				}
				noDataText="No orders found with the current filter settings"
				storeColumnVisibility
			/>
		</>
	)
}

const columns: Column<PurchaseOrder>[] = [
	{
		Header: "Order #",
		accessor: "id",
		disableToggle: true,
	},
	{
		Header: "KBM Order #",
		accessor: "kbmOrderNumber",
		hiddenByDefault: true,
	},
	{
		Header: `Created Date (${getTimezoneAbbreviation()})`,
		id: "createdDate",
		accessor: ({ createdDate }) =>
			createdDate ? formatDateTime(createdDate) : <EmptyValueDash />,
	},

	{
		Header: "Created By",
		hiddenByDefault: true,
		accessor: ({ createdBy }) => createdBy?.name ?? <EmptyValueDash />,
	},
	{
		Header: `Closed Date (${getTimezoneAbbreviation()})`,
		accessor: ({ closedDate }) =>
			closedDate ? formatDateTime(closedDate) : <EmptyValueDash />,
		hiddenByDefault: true,
	},
	{
		Header: "Customer #",
		accessor: ({ shipToCustomer }) => shipToCustomer?.customerNumber,
	},
	{
		Header: "Customer",
		accessor: ({ shipToCustomer }) => shipToCustomer?.name,
	},
	{
		Header: "Vendor",
		accessor: ({ vendor }) => vendor?.name ?? <EmptyValueDash />,
	},
	{
		Header: "Customer PO #",
		hiddenByDefault: true,
		accessor: ({ purchaseOrderNumber }) => purchaseOrderNumber ?? <EmptyValueDash />,
	},
	{
		Header: "Address",
		hiddenByDefault: true,
		accessor: ({ shipToCustomer }) => shipToCustomer?.address2 ?? <EmptyValueDash />,
	},
	{
		Header: "City",
		hiddenByDefault: true,
		accessor: ({ shipToCustomer }) => shipToCustomer?.city ?? <EmptyValueDash />,
	},
	{
		Header: "State",
		hiddenByDefault: true,
		accessor: ({ shipToCustomer }) => shipToCustomer?.state ?? <EmptyValueDash />,
	},
	{
		Header: "Postal Code",
		hiddenByDefault: true,
		accessor: ({ shipToCustomer }) => shipToCustomer?.postalcode ?? <EmptyValueDash />,
	},
	{
		Header: "Bill-To Customer #",
		hiddenByDefault: true,
		accessor: ({ billToCustomer }) => billToCustomer?.customerNumber,
	},
	{
		Header: "Bill-To Customer",
		hiddenByDefault: true,
		accessor: ({ billToCustomer }) => billToCustomer?.name,
	},
	{
		Header: "Territory",
		hiddenByDefault: true,
		accessor: ({ shipToCustomer }) =>
			shipToCustomer?.territory?.description ?? <EmptyValueDash />,
	},
	{
		Header: "Order status",
		accessor: ({ status }) => status.description ?? <EmptyValueDash />,
	},
]

const [startDate] = getStartAndEndDateQueryParams(
	getTimeAgoFromStartDate(dayjs().subtract(6, "months").toISOString())
)

import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { withRouter } from "react-router-dom"
import callApi from "@ncs/bricks/redux/services/callApi"
import { fetchData } from "@ncs/bricks/redux/services/dynamicTables"

import { reduxKey as ticketEventReduxKey } from "./TicketEventContainer"

// local components
import TicketingOverview from "./TicketingOverview"

class TicketingOverviewContainer extends React.Component {
	getCodeFromUrlParams = (props = this.props) => props.match.params.location_code

	constructor(props) {
		super(props)
		this.state = {
			activeTabIndex: 0,
		}
	}

	handleTabChange = (activeTabIndex) => {
		if (activeTabIndex === 1) {
			this.props.fetchData()
		}
		this.setState({ activeTabIndex })
	}
	refreshLocation = (location) => {
		this.setLocation(this.state.selectedLocation._id, true)
	}

	render() {
		return (
			<TicketingOverview
				activeTabIndex={this.state.activeTabIndex}
				onTabChange={this.handleTabChange}
			/>
		)
	}
}

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ callApi, fetchData: fetchData(ticketEventReduxKey) }, dispatch),
})

export default connect(null, mapDispatchToProps)(withRouter(TicketingOverviewContainer))

import { FC, useState } from "react"

import { DateTimePicker, DateTimePickerProps } from "@material-ui/pickers"
import { Dayjs } from "dayjs"

import { DateFormat } from "@ncs/ts-utils"

import { FieldContainer, FieldContainerProps } from "./FieldContainer"
import { TextInput } from "./TextInput"

export interface DateTimeInputProps extends FieldContainerProps {
	value: Dayjs | null
	onChange: (newDateTime: Dayjs | null) => void
	muiProps?: Omit<DateTimePickerProps, "onChange" | "value">
	clearable?: boolean
	placeholder?: string
}

export const DateTimeInput: FC<DateTimeInputProps> = ({
	value,
	onChange,
	muiProps,
	clearable,
	placeholder = "Choose date...",
	...rest
}) => {
	const [open, setOpen] = useState(false)

	return (
		<FieldContainer label="Date and time" {...rest}>
			<DateTimePicker
				value={value}
				onChange={onChange}
				format={DateFormat.DateTime}
				open={open}
				onClose={() => setOpen(false)}
				emptyLabel={placeholder}
				clearable={clearable}
				TextFieldComponent={() => {
					return (
						<TextInput
							value={value?.format(DateFormat.DateTime)}
							onClick={() => setOpen(true)}
							placeholder={placeholder}
							icon="calendar"
						/>
					)
				}}
				{...muiProps}
			/>
		</FieldContainer>
	)
}

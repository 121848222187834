import { FC, useState } from "react"

import { makeApiErrorMessage, PartOrder, PartOrderShipment, useDeleteShipment } from "@ncs/ncs-api"
import {
	AnimatedEntrance,
	AnimatedEntranceProps,
	Box,
	ConfirmationModal,
	ConfirmationModalConfig,
	Disabled,
	ExtendableModalProps,
	HeadingDivider,
	HeadingDividerProps,
	Modal,
	useToast,
} from "@ncs/web-legos"

import { useShipShipmentContext } from "~/util/ship-shipment"

import {
	ShipmentCarrier,
	ShipmentConfigureSend,
	ShipmentPackItems,
	ShipmentVerifyContents,
	ShipShipmentContextProvider,
} from "./components"

export interface ShipShipmentModalProps extends ExtendableModalProps {
	partOrder: PartOrder
	shipment: PartOrderShipment
}

export const ShipShipmentModal: FC<ShipShipmentModalProps> = ({
	partOrder,
	shipment,
	...rest
}) => {
	return (
		<ShipShipmentContextProvider shipment={shipment} partOrder={partOrder}>
			<ShipShipmentModalInner {...rest} />
		</ShipShipmentContextProvider>
	)
}

const ShipShipmentModalInner: FC<ExtendableModalProps> = ({ ...rest }) => {
	const { makeSuccessToast, makeErrorToast } = useToast()
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)

	const {
		shipment,
		verifyStepComplete,
		carrierSectionReady,
		carrierStepComplete,
		packItemsSectionReady,
		packItemsStepComplete,
		configureSendSectionReady,
	} = useShipShipmentContext()

	const deleteShipment = useDeleteShipment()

	const handleDelete = () => {
		setConfirmationConfig({
			title: "Cancel Shipment",
			message:
				"Confirm: Cancel this shipment? This will compare the original line item quantities ordered with the quantities that have shipped so far, deleting shipment lines and recreating order demands as necessary.",
			onConfirm: async () => {
				try {
					await deleteShipment({ id: shipment.shipmentId })
					makeSuccessToast("Shipment canceled")
					rest.onClose()
				} catch (e) {
					makeErrorToast(makeApiErrorMessage(e))
				}
			},
		})
	}

	return (
		<Modal
			{...rest}
			title="Ship Items"
			maxWidth="md"
			titleDetail={`Shipment # ${shipment.shipmentId}`}
			confirmClose={verifyStepComplete}
			confirmCloseMessage="Close this shipping modal?"
			leftButtons={{
				buttonText: "Cancel shipment",
				icon: "trash-alt",
				variant: "text",
				onClick: handleDelete,
			}}
		>
			<HeadingDivider
				{...sectionHeadingProps}
				mt={1}
				icon={verifyStepComplete ? "check" : undefined}
			>
				1. Verify Shipment Contents
			</HeadingDivider>
			<Box {...sectionBoxProps}>
				<ShipmentVerifyContents />
			</Box>

			<Disabled disabled={!carrierSectionReady}>
				<HeadingDivider
					{...sectionHeadingProps}
					icon={carrierStepComplete ? "check" : undefined}
				>
					2. LTL Carrier
				</HeadingDivider>
				<AnimatedEntrance {...sectionBoxProps} show={carrierSectionReady}>
					<ShipmentCarrier />
				</AnimatedEntrance>
			</Disabled>

			<Disabled disabled={!packItemsSectionReady}>
				<HeadingDivider
					{...sectionHeadingProps}
					icon={packItemsStepComplete ? "check" : undefined}
				>
					3. Pack Items
				</HeadingDivider>
				<AnimatedEntrance {...sectionBoxProps} show={packItemsSectionReady}>
					<ShipmentPackItems />
				</AnimatedEntrance>
			</Disabled>

			<Disabled disabled={!configureSendSectionReady}>
				<HeadingDivider {...sectionHeadingProps}>4. Configure & Send</HeadingDivider>
				<AnimatedEntrance {...sectionBoxProps} show={configureSendSectionReady}>
					<ShipmentConfigureSend closeModal={rest.onClose} />
				</AnimatedEntrance>
			</Disabled>

			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</Modal>
	)
}

const sectionHeadingProps: HeadingDividerProps = {
	headingVariant: "h4",
	bold: true,
	mt: 3,
	mb: 1.5,
}

const sectionBoxProps: Partial<AnimatedEntranceProps> = {
	direction: "down",
	pl: 1.5,
}

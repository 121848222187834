import { FC, useState } from "react"

import {
	EquipmentManufacturer,
	EquipmentModel,
	makeApiErrorMessage,
	UpdateEquipmentModelPatch,
	useCreateEquipmentModel,
	useUpdateEquipmentModel,
} from "@ncs/ncs-api"
import {
	Checkbox,
	EmptyValueDash,
	EquipmentManufacturerSelector,
	ExtendableModalProps,
	LabeledData,
	Modal,
	TextInput,
	useIsSaving,
	useToast,
} from "@ncs/web-legos"

export interface EditModelModalProps extends ExtendableModalProps {
	model: EquipmentModel | null
}

export const EditModelModal: FC<EditModelModalProps> = ({ model, ...rest }) => {
	const { makeSuccessToast } = useToast()
	const { isSaving, setSaving, endSaving } = useIsSaving()
	const [errorText, setErrorText] = useState<string | null>(null)

	const [name, setName] = useState(model?.modelName ?? null)
	const [isActive, setIsActive] = useState(model?.isActive ?? true)
	const [isPrimary, setIsPrimary] = useState(model?.isPrimary ?? false)
	const [manufacturer, setManufacturer] = useState<EquipmentManufacturer | null>(null)

	const createModel = useCreateEquipmentModel()
	const updateModel = useUpdateEquipmentModel()

	const handleSave = async () => {
		if (!name) {
			setErrorText("Name is required")
			return
		}

		const data: UpdateEquipmentModelPatch = {
			modelName: name,
			isActive,
			isPrimary,
		}

		try {
			setSaving()
			if (isEdit) {
				await updateModel({
					id: model.id,
					updates: data,
				})
				makeSuccessToast("Model updated")
			} else {
				if (!manufacturer) {
					throw new Error("Please select a manufacturer")
				}

				await createModel({
					...data,
					manufacturerId: manufacturer.id,
					eqpFamilyId: 16, // This was hardcoded in here in the old UI,
				})
				makeSuccessToast("Model created")
			}
			rest.onClose()
		} catch (e) {
			endSaving()
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const isEdit = !!model

	return (
		<Modal
			{...rest}
			title={isEdit ? "Edit Model" : "New Model"}
			errorText={errorText}
			rightButtons={{
				buttonText: isEdit ? "Update Model" : "Create",
				isLoading: isSaving(),
				onClick: handleSave,
			}}
		>
			{isEdit ?
				<LabeledData label="Manufacturer">
					{model.manufacturer?.name || <EmptyValueDash />}
				</LabeledData>
			:	<EquipmentManufacturerSelector value={manufacturer} onChange={setManufacturer} />}
			<TextInput
				value={name}
				onChange={setName}
				label="Model name"
				maxLength={255}
				fillContainer
			/>
			<Checkbox value={isActive} onChange={setIsActive} label="Active (default)" mb={0} />
			<Checkbox value={isPrimary} onChange={setIsPrimary} label="Primary" />
		</Modal>
	)
}

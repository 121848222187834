const valueOrDefaultFn = (value, defaultValue) => () => {
	return value || typeof defaultValue === "undefined" ? value : defaultValue
}

const setStateKeyByValueFunction = (self, key, valueFn) => {
	self.setState({ [key]: valueFn() })
}

export const setStateKeyFromValue = (self, key, defaultValue) => (value) => {
	setStateKeyByValueFunction(self, key, valueOrDefaultFn(value, defaultValue))
}

export const setStateKeyFromEventValue = (self, key, defaultValue) => (e) => {
	setStateKeyByValueFunction(self, key, valueOrDefaultFn(e.value, defaultValue))
}

export const getValueFromEventTargetValue = (e) => {
	return e.target.type === "checkbox" ? e.target.checked : e.target.value
}

export const setStateKeyFromEventTargetValue = (self, key, defaultValue) => (e) => {
	if (!key) key = e.target.name || e.target.id
	let value = getValueFromEventTargetValue(e)
	setStateKeyByValueFunction(self, key, valueOrDefaultFn(value, defaultValue))
}

export const toggleStateKey = (self, key) => () => {
	setStateKeyByValueFunction(self, key, () => !self.state[key])
}

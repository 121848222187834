import React from "react"
import PropTypes from "prop-types"

import BooleanFormField from "./Fields/BooleanFormField"
import CurrencyFormField from "./Fields/CurrencyFormField"
import CustomEditorFormField from "./Fields/CustomEditorFormField"
import IntegerFormField from "./Fields/IntegerFormField"
import NumberFormField from "./Fields/NumberFormField"
import TextFormField from "./Fields/TextFormField"

const fieldMap = {
	string: TextFormField,
	number: NumberFormField,
	currency: CurrencyFormField,
	integer: IntegerFormField,
	bool: BooleanFormField,
	boolean: BooleanFormField,
	custom: CustomEditorFormField,
}

class FormField extends React.PureComponent {
	render = () => {
		const { type, ...rest } = this.props
		const Component = fieldMap[type] ?? fieldMap["string"]
		return <Component {...rest} />
	}
}

FormField.propTypes = {
	/** type of input */
	type: PropTypes.string.isRequired,

	/** true if in editing mode, false if in read-only mode. defaults to false */
	isEditing: PropTypes.bool,

	/** name of the field */
	labelText: PropTypes.string.isRequired,

	/** current value of input */
	value: PropTypes.any,

	/** when true the editor will never be shown, defaults to false */
	isReadOnly: PropTypes.bool,

	/** function called when the user changes the value, should update the bit that gets passed in as `value` */
	onChange: PropTypes.func,

	/** should the error message be displayed if invalid? usually this is only set to true after a save is attempted */
	showValidationErrors: PropTypes.bool,

	/** is the current value valid? */
	isValid: PropTypes.bool,

	/** error message for why the current value is not valid, only shown if showValidationErrors is true */
	errorMessage: PropTypes.string,
}

export default FormField

import { FC, useEffect, useState } from "react"

import { makeApiErrorMessage } from "@ncs/ncs-api"
import {
	Box,
	Divider,
	ExtendableModalProps,
	FileInput,
	FileType,
	IconButton,
	LabeledData,
	Modal,
	TextInput,
} from "@ncs/web-legos"

export interface AddContractDocumentModalProps extends ExtendableModalProps {
	onSave: (description: string, file: File) => Promise<void> | void
}

export const AddContractDocumentModal: FC<AddContractDocumentModalProps> = ({
	onSave,
	...rest
}) => {
	const [description, setDescription] = useState<string | null>(null)
	const [file, setFile] = useState<File | null>(null)
	const [errorText, setErrorText] = useState<string | null>(null)
	const [isSaving, setIsSaving] = useState(false)

	const handleSave = async () => {
		try {
			if (!description) throw new Error("Description is required")
			if (!file) throw new Error("Please select a file")

			setIsSaving(true)
			await onSave(description, file)
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	useEffect(() => {
		setErrorText(null)
	}, [description, file])

	const reset = () => {
		setDescription(null)
		setFile(null)
		setErrorText(null)
		setIsSaving(false)
	}

	return (
		<Modal
			title="Add Document"
			{...rest}
			onOpen={reset}
			errorText={errorText}
			rightButtons={{
				buttonText: "Add Document",
				onClick: handleSave,
				isLoading: isSaving,
			}}
		>
			<TextInput value={description} onChange={setDescription} label="Description" />

			<Divider />

			{file ?
				<Box display="flex" alignItems="center" columnGap={0.5} mb={2}>
					<LabeledData label="File">{file.name}</LabeledData>
					<IconButton icon="trash-alt" onClick={() => setFile(null)} />
				</Box>
			:	<FileInput
					fileTypes={[
						FileType.Pdf,
						FileType.Word,
						FileType.Text,
						FileType.Excel,
						FileType.Images,
					]}
					onChange={setFile}
					maxSize={50}
				/>
			}
		</Modal>
	)
}

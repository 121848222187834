import { ReactElement } from "react"

import { useTheme } from "@emotion/react"
import {
	Bar,
	BarChart as RechartBarChart,
	CartesianGrid,
	Legend,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts"

import { PortalReport } from "@ncs/ncs-api"
import { formatNumber, pythonify } from "@ncs/ts-utils"

import { ExtendableBarChartProps } from "./charts-util"
import { ResponsiveChartContainer } from "./ResponsiveChartContainer"

export interface StackedBarChartProps extends ExtendableBarChartProps {
	report: PortalReport
	keysToExclude?: string[]
}

export const StackedBarChart = ({
	report,
	keysToExclude = [],
	chartMargins,
	colorArray,
	gridProps,
	height,
	legendPosition = "right",
	onBarClick,
	tooltipFormatter,
	withLegend = true,
	withTooltip = true,
	xAxisDomain,
	xAxisInterval,
	xAxisLabel,
	xAxisTick,
	xAxisTickFormatter,
	xAxisType,
	yAxisDomain = ["dataMin", "dataMax"],
	yAxisInterval,
	yAxisLabel,
	yAxisTickFormatter,
	yAxisType,
}: StackedBarChartProps): ReactElement => {
	const theme = useTheme()

	return (
		<ResponsiveChartContainer height={height}>
			<RechartBarChart data={report.data} margin={chartMargins}>
				<XAxis
					dataKey={report.xKey}
					interval={xAxisInterval}
					label={xAxisLabel}
					tick={xAxisTick}
					domain={xAxisDomain}
					tickFormatter={xAxisTickFormatter}
					type={xAxisType}
				/>
				<YAxis
					domain={yAxisDomain}
					tickFormatter={yAxisTickFormatter ?? ((value) => formatNumber(value))}
					interval={yAxisInterval}
					label={yAxisLabel}
					type={yAxisType}
				/>

				{withTooltip && <Tooltip formatter={tooltipFormatter} />}

				<CartesianGrid stroke={theme.palette.grey[200]} {...gridProps} />

				{withLegend && (
					<Legend
						align={legendPosition === "right" ? "right" : "center"}
						layout={legendPosition === "right" ? "vertical" : "horizontal"}
						wrapperStyle={
							legendPosition === "right" ?
								{
									paddingLeft: "2rem",
									paddingBottom: "1.5rem",
								}
							:	{
									marginTop: "1rem",
								}
						}
						formatter={(value) => {
							return report.dataKeys[pythonify(value)]
						}}
					/>
				)}

				{Object.keys(report.dataKeys)
					.filter((key) => !keysToExclude.includes(key))
					.map((key, i) => (
						<Bar
							onClick={onBarClick}
							key={key}
							dataKey={key}
							stackId="a" // Can be anything.
							fill={
								colorArray ?
									colorArray[i] ??
									// If we run out of colors, start at the beginning of theme colors array.
									theme.palette.chartColors[i - colorArray.length]
								:	theme.palette.chartColors[i]
							}
						/>
					))}
			</RechartBarChart>
		</ResponsiveChartContainer>
	)
}

import { Dispatch, ReactElement, SetStateAction } from "react"

import { VacuumEventType } from "@ncs/ncs-api"

import { VacuumEventTypeSelector } from "../selectors"

export interface VacuumEventTypeQueryParams {
	eventType: string | null
}

export interface VacuumEventTypeQueryFilterProps<
	QueryParamState extends VacuumEventTypeQueryParams,
> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

export const VacuumEventTypeQueryFilter = <QueryParamState extends VacuumEventTypeQueryParams>({
	queryParamState,
	setQueryParamState,
}: VacuumEventTypeQueryFilterProps<QueryParamState>): ReactElement => {
	return (
		<VacuumEventTypeSelector
			fillContainer
			value={queryParamState.eventType as VacuumEventType | null}
			onChange={(newValue) => {
				setQueryParamState((prev) => ({
					...prev,
					eventType: newValue,
				}))
			}}
		/>
	)
}

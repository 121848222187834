import React from "react"
import { connect } from "react-redux"
import { bindActionCreators, compose } from "redux"
import { callApi } from "../../redux/services/callApi"

/** HOC to provide callApi prop. a shortcut for bindActionCreators with only callApi. If you're binding other stuff,
 * just bind callApi manually. **/
const withCallApi = (Component) => {
	class CallApiHoc extends React.PureComponent {
		render() {
			return <Component {...this.props} />
		}
	}

	return CallApiHoc
}

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ callApi }, dispatch)
}

export default compose(connect(null, mapDispatchToProps), withCallApi)

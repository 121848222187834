import { FC, useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"
import { z } from "zod"

import { makeApiErrorMessage, useCreateUserProfile } from "@ncs/ncs-api"
import { ExtendableModalProps, Modal, TextInputFormField, useToast } from "@ncs/web-legos"

export const CreateCustomerUserModal: FC<ExtendableModalProps> = ({ ...rest }) => {
	const history = useHistory()
	const { makeSuccessToast } = useToast()
	const [errorText, setErrorText] = useState<string | null>(null)
	const [isSaving, setIsSaving] = useState(false)

	const create = useCreateUserProfile()

	const {
		control,
		handleSubmit,
		formState: { isValid, submitCount },
		reset: formReset,
	} = useForm<CustomerUserForm>({
		resolver: zodResolver(CustomerUserFormSchema),
		defaultValues: userFormDefaultValues,
	})

	const onCreate = async (formData: CustomerUserForm) => {
		try {
			setIsSaving(true)
			const { data } = await create({
				...formData,
				isCustomer: true,
				effectiveDate: new Date().toISOString(),
				employeeNumber: null,
				mobile: null,
				verisaeUsername: null,
				reportAssignmentId: null,
				applicationAssignmentId: null,
			})
			makeSuccessToast("New customer user created")
			history.push(`/pos-users/${data._id}`)
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const reset = () => {
		formReset()
		setErrorText(null)
		setIsSaving(false)
	}

	return (
		<Modal
			{...rest}
			onOpen={reset}
			errorText={errorText}
			title="Create New Customer User"
			rightButtons={{
				buttonText: "Create",
				disabled: !isValid && submitCount > 0,
				isLoading: isSaving,
				onClick: handleSubmit(onCreate),
			}}
		>
			<TextInputFormField
				control={control}
				name="firstName"
				label="First name"
				returnEmptyString
			/>
			<TextInputFormField
				control={control}
				name="lastName"
				label="Last name"
				returnEmptyString
			/>
			<TextInputFormField control={control} name="email" label="E-mail" returnEmptyString />
		</Modal>
	)
}

const CustomerUserFormSchema = z.object({
	firstName: z.string().min(1, "Required").max(50),
	lastName: z.string().min(1, "Required").max(50),
	email: z.string().min(1, "Required").max(255).email(),
})

export type CustomerUserForm = z.infer<typeof CustomerUserFormSchema>

const userFormDefaultValues: CustomerUserForm = {
	firstName: "",
	lastName: "",
	email: "",
}

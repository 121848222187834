import { FC, useState } from "react"

import {
	CustomerDetail,
	makeApiErrorMessage,
	useDeleteSalesAssignment,
	useSalesAssignments,
} from "@ncs/ncs-api"
import {
	Box,
	Button,
	ConfirmationModal,
	ConfirmationModalConfig,
	IconButton,
	Paragraph,
	Pipe,
	useToast,
} from "@ncs/web-legos"

import { AddSalesAssignmentModal } from "./components"

export interface SalesAssignmentProps {
	customer: CustomerDetail
}

export const SalesAssignments: FC<SalesAssignmentProps> = ({ customer }) => {
	const { makeSuccessToast, makeErrorToast } = useToast()
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)
	const [showAssignmentModal, setShowAssignmentModal] = useState(false)

	const [assignments] = useSalesAssignments({ params: { customer: customer.id } })
	const deleteSalesAssignment = useDeleteSalesAssignment()

	const handleDeleteSalesAssignment = async (id: string) => {
		try {
			await deleteSalesAssignment(id)
			makeSuccessToast("Sales assignment removed")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		}
	}

	return (
		<>
			{!!assignments?.length && (
				<Box mb={1}>
					{assignments?.map((assignment) => (
						<Box key={assignment.id} mb={0.5} display="flex" alignItems="center">
							<Paragraph mr={1}>{assignment.user.name}</Paragraph>
							<Box display="flex" alignItems="center">
								<Paragraph small color="secondary">
									{assignment.salesType.description}
								</Paragraph>
								{assignment.salesRole && (
									<>
										<Pipe />
										<Paragraph small color="secondary">
											{assignment.salesRole.name}
										</Paragraph>
									</>
								)}
								<Box ml={0.5}>
									<IconButton
										icon="trash-alt"
										size="sm"
										color="primary"
										onClick={() =>
											setConfirmationConfig({
												title: "Remove Sales Assignment",
												message: `Remove ${assignment.user.name} from this customer?`,
												onConfirm: () =>
													handleDeleteSalesAssignment(assignment.id),
											})
										}
									/>
								</Box>
							</Box>
						</Box>
					))}
				</Box>
			)}

			<Button
				icon="plus"
				containerProps={{ ml: -0.3 }}
				onClick={() => setShowAssignmentModal(true)}
			>
				Add sales assignment
			</Button>

			{showAssignmentModal && (
				<AddSalesAssignmentModal
					onClose={() => setShowAssignmentModal(false)}
					customerId={customer.id}
				/>
			)}
			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</>
	)
}

import React from "react"

import withStyles from "@material-ui/core/styles/withStyles"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { bindActionCreators } from "redux"

import { createRoute } from "@ncs/mortar/redux/services/customers"
import callApi from "@ncs/bricks/redux/services/callApi"

import {
	Button,
	ButtonWithIcon,
	Checkbox,
	ConditionalContent,
	CustomerSelector,
	DynamicTable,
	GridContainer,
	GridItem,
	Input,
	LoadingWrapper,
	QuickCard,
	TerritorySelector,
	UserSelector,
} from "~/components"

import { Add, ChevronLeft, Clear, LocalShipping, Remove, Save } from "@material-ui/icons"

const styles = {
	right: {
		textAlign: "right",
	},
}

class CreateRoute extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isSaving: false,
			addCustomer: false,
			newRouteName: "",
			assignedEmployee: null,
			isActive: true,
			newStops: [],
			territoryId: null,
		}
	}

	updateIsActive = () => {
		this.setState({ isActive: !this.state.isActive })
	}

	updateRouteName = (e) => {
		this.setState({ newRouteName: e.target.value })
	}

	addCustomer = () => {
		this.setState({
			addCustomer: true,
		})
	}

	cancelAddCustomer = () => {
		this.setState({ addCustomer: false })
	}

	handleCustomerSelect = (customer) => {
		let add = this.state.newStops.filter(
			(stop) => stop.customer._id === customer._id.toString()
		)
		if (add.length === 0) {
			this.setState({
				newStops: [{ id: -1, customer: customer }, ...this.state.newStops],
				addCustomer: false,
			})
		} else {
			this.setState({
				addCustomer: false,
			})
		}
	}

	updateUserSelected = (assignedEmployee) => {
		if (assignedEmployee !== null) {
			this.setState({ assignedEmployee: assignedEmployee._id })
		} else {
			this.setState({ assignedEmployee: null })
		}
	}

	handleCreateRoute = () => {
		this.setState({ isSaving: true })
		let params = {
			assigned_employee_id: this.state.assignedEmployee,
			name: this.state.newRouteName,
			is_active: this.state.isActive,
			route_location: this.state.newStops,
			territory_id: this.state.territoryId,
		}
		this.props.callApi(createRoute(params)).then((result) => {
			if (result.error) {
				this.setState({ isSaving: false })
				console.error(result.error)
				return
			}
			this.setState({
				isSaving: false,
			})
			this.props.history.push(`/customer/routes/${result.payload.id}`)
		})
	}

	handleTerritoryUpdate = (territoryId) => {
		this.setState({ territoryId })
	}

	handleRemoveRoute = (route) => {
		this.setState({
			newStops: this.state.newStops.filter(
				(stop) => stop.customer._id !== route.customer._id.toString()
			),
		})
	}

	handleBackToList = () => {
		this.props.history.push("/customer/routes")
	}

	editColumns = [
		{
			Header: "Customer Number",
			accessor: "customer.customer_number",
			sortable: true,
			toggleable: false,
		},
		{
			Header: "Name",
			accessor: "customer.name",
			sortable: true,
			toggleable: true,
		},
		{
			Header: "",
			Cell: (x) => (
				<Button
					justIcon
					round
					simple
					className="edit"
					color="danger"
					onClick={(e) => {
						e.stopPropagation()
						this.handleRemoveRoute(x.original)
					}}
				>
					<Remove />
				</Button>
			),
			accessor: "actions",
			sortable: false,
			toggleable: false,
			maxWidth: 50,
		},
	]

	render() {
		const {
			isLoading,
			newRouteName,
			isActive,
			newStops,
			isSaving,
			addCustomer,
			territoryId,
			assignedEmployee,
		} = this.state

		let disabled = territoryId === null || newRouteName === "" || assignedEmployee === null

		return (
			<React.Fragment>
				<LoadingWrapper isLoading={isLoading} />
				<GridItem xs={12}>
					<ButtonWithIcon
						content="Back to List"
						onClick={this.handleBackToList}
						color="white"
						size="sm"
						round
						icon={<ChevronLeft />}
					/>
				</GridItem>
				<QuickCard title="Create Route" icon={<LocalShipping />}>
					<GridContainer>
						<GridItem xs={12} sm={8}>
							<Input
								labelText={"Route"}
								value={newRouteName}
								onChange={this.updateRouteName}
							/>
						</GridItem>

						<GridItem xs={12} sm={4} align="center">
							<Checkbox
								labelText="Is Active"
								checked={isActive}
								onClick={this.updateIsActive}
							/>
						</GridItem>
						<GridItem xs={12} sm={6}>
							<UserSelector
								onUserSelected={this.updateUserSelected}
								labelText={"Assigned Employee"}
							/>
						</GridItem>
						<GridItem xs={12} sm={6}>
							<TerritorySelector
								onChange={this.handleTerritoryUpdate}
								territory={territoryId}
							/>
						</GridItem>
						<ConditionalContent show={addCustomer}>
							<GridItem xs={12}>
								<CustomerSelector
									labelText="Customer"
									onCustomerSelected={this.handleCustomerSelect}
								/>
							</GridItem>
						</ConditionalContent>

						<GridItem xs={12} align="right">
							{addCustomer ?
								<ButtonWithIcon
									content="Cancel Add Customer"
									onClick={this.cancelAddCustomer}
									color="info"
									size="sm"
									round
									disabled={isSaving}
									icon={<Clear />}
								/>
							:	<ButtonWithIcon
									content="Add Customer"
									onClick={this.addCustomer}
									color="primary"
									size="sm"
									round
									disabled={isSaving}
									icon={<Add />}
								/>
							}
							<ButtonWithIcon
								content="Create Route"
								onClick={this.handleCreateRoute}
								color="success"
								size="sm"
								round
								disabled={isSaving || disabled}
								loading={isSaving}
								icon={<Save />}
							/>
						</GridItem>
					</GridContainer>
					<DynamicTable
						loading={isLoading}
						data={newStops}
						columns={this.editColumns}
						useClientSideDataProcessing={true}
						showPaginationTop={false}
						showPaginationBottom={false}
					/>
				</QuickCard>
			</React.Fragment>
		)
	}
}

const mapStateToProps = (state) => {
	return {}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			callApi,
		},
		dispatch
	)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(withStyles(styles)(CreateRoute)))

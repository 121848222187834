import { FC, useEffect, useMemo, useState } from "react"

import { Cell, Column } from "react-table"

import {
	InventoryLocation,
	InventoryTransactionType,
	makeApiErrorMessage,
	useCreateInventoryTransaction,
	useInventoryTransactionInitialData,
	useIsUser,
	UserId,
} from "@ncs/ncs-api"
import { formatNumber } from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	Box,
	Button,
	Card,
	ConfirmationModal,
	ConfirmationModalConfig,
	Disabled,
	Divider,
	ErrorText,
	GridContainer,
	GridItem,
	HeadingDivider,
	IconButton,
	LoadingSpinner,
	LocationSelector,
	Select,
	Table,
	useChangeCallback,
	useToast,
} from "@ncs/web-legos"

import { AddTransactionPartModal } from "./components"
import { InventoryTransactionLine, teraTransactionTypes } from "./inventory-transaction-util"

export const InventoryTransaction: FC = () => {
	const isTera = useIsUser(UserId.TeraStevens)
	const { makeSuccessToast, makeErrorToast } = useToast()
	const [selectedTransactionType, setSelectedTransactionType] =
		useState<InventoryTransactionType | null>(null)
	const [showAddPartModal, setShowAddPartModal] = useState(false)
	const [selectedSource, setSelectedSource] = useState<InventoryLocation | null>(null)
	const [selectedDestination, setSelectedDestination] = useState<InventoryLocation | null>(null)
	const [transactionLines, setTransactionLines] = useState<InventoryTransactionLine[]>([])
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)
	const [errorText, setErrorText] = useState<string | null>(null)

	const [initialData, initialDataLoading] = useInventoryTransactionInitialData()
	const createTransaction = useCreateInventoryTransaction()

	const handleAddPart = (line: InventoryTransactionLine): void => {
		setTransactionLines((prev) => {
			return [...prev, line]
		})
	}

	const handleRemovePart = (lineId: string): void => {
		setTransactionLines((prev) => [...prev.filter(({ id }) => id !== lineId)])
	}

	const handleSubmit = async (): Promise<void> => {
		try {
			if (!selectedTransactionType) throw new Error("No transaction type found")

			await createTransaction({
				transactionTypeId: selectedTransactionType.value,
				sourceLocationId: selectedSource?.id ?? null,
				destLocationId: selectedDestination?.id ?? null,
				parts: transactionLines.map((p) => ({
					partId: p.partId,
					quantity: Math.abs(p.quantity), // UI shows stock reduction as negative.
					destBinId: p.destinationBinId,
					sourceBinId: p.sourceBinId,
				})),
			})
			makeSuccessToast("Transaction completed")
			reset()
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		}
	}

	const getErrors = (): string | null => {
		if (!selectedTransactionType) {
			return "Select a transaction type"
		}
		if (selectedTransactionType.requiredLocations.source && !selectedSource) {
			return "Source location not selected"
		}
		if (selectedTransactionType.requiredLocations.dest && !selectedDestination) {
			return "Destination location not selected"
		}
		if (!transactionLines.length) {
			return "No parts selected yet"
		}

		return null
	}

	const reset = (): void => {
		setSelectedDestination(null)
		setSelectedSource(null)
		setTransactionLines([])
	}

	useEffect(() => {
		setErrorText(null)
	}, [selectedTransactionType, transactionLines, selectedSource, selectedDestination])

	// When the things changes, reset state.
	useChangeCallback(selectedTransactionType, reset)
	useChangeCallback(selectedSource, () => {
		setTransactionLines([])
	})
	useChangeCallback(selectedDestination, () => {
		setTransactionLines([])
	})

	const hasTo = useMemo(
		() => selectedTransactionType?.requiredLocations.dest === true,
		[selectedTransactionType?.requiredLocations.dest]
	)
	const hasFrom = useMemo(
		() => selectedTransactionType?.requiredLocations.source === true,
		[selectedTransactionType?.requiredLocations.source]
	)

	const partsColumns = useMemo(() => {
		const columns: Column<InventoryTransactionLine>[] = [
			{
				Header: "Part #",
				accessor: "partNumber",
			},
			{
				Header: "Part name",
				accessor: "partDescription",
			},
		]

		if (hasFrom) {
			columns.push({
				Header: "From Bin",
				accessor: "sourceBinCode",
			})
		}
		if (hasTo) {
			columns.push({
				Header: "To Bin",
				accessor: "destinationBinCode",
			})
		}

		columns.push(
			{
				Header: "Quantity",
				accessor: ({ quantity }) => formatNumber(quantity),
			},
			{
				Header: "",
				id: "remove-button",
				Cell: ({ row: { original } }: Cell<InventoryTransactionLine>) => (
					<IconButton
						icon="trash-alt"
						onClick={() => handleRemovePart(original.id)}
						title="Remove line"
						size="sm"
					/>
				),
			}
		)

		return columns
	}, [hasTo, hasFrom])

	if (initialDataLoading) {
		return (
			<Card>
				<LoadingSpinner />
			</Card>
		)
	}

	if (!initialData) return null

	const locationSectionReady = selectedTransactionType != null
	const partsSectionReady = !(
		!selectedTransactionType ||
		(selectedTransactionType.requiredLocations.dest && !selectedDestination) ||
		(selectedTransactionType.requiredLocations.source && !selectedSource)
	)

	return (
		<Card heading="Create Inventory Transaction" headingIcon="exchange-alt">
			<HeadingDivider headingVariant="h5">Transaction Type</HeadingDivider>
			<GridContainer pl={1.5}>
				<GridItem md={12} lg={6}>
					<Select
						value={selectedTransactionType?.value.toString() ?? null}
						options={initialData.transactionTypes.filter(
							(t) => isTera || !teraTransactionTypes.includes(t.value)
						)}
						valueAccessor={(option) => option.value.toString()}
						onChange={(id, selection) => setSelectedTransactionType(selection ?? null)}
						label="Transaction type"
						fillContainer
					/>
				</GridItem>
			</GridContainer>

			<Disabled disabled={!locationSectionReady}>
				<HeadingDivider headingVariant="h5" mt={3}>
					Location(s)
				</HeadingDivider>
			</Disabled>
			<GridContainer pl={1.5}>
				{!!selectedTransactionType?.requiredLocations.source && (
					<GridItem md={12} lg={6}>
						<AnimatedEntrance show>
							<LocationSelector
								value={selectedSource}
								onChange={setSelectedSource}
								label="Source location"
							/>
						</AnimatedEntrance>
					</GridItem>
				)}
				{!!selectedTransactionType?.requiredLocations.dest && (
					<GridItem md={12} lg={6}>
						<AnimatedEntrance show>
							<LocationSelector
								value={selectedDestination}
								onChange={setSelectedDestination}
								label="Destination location"
							/>
						</AnimatedEntrance>
					</GridItem>
				)}
			</GridContainer>

			<Disabled disabled={!partsSectionReady}>
				<HeadingDivider headingVariant="h5" mt={3}>
					Transaction Items
				</HeadingDivider>
			</Disabled>
			<Box pl={1.5}>
				<AnimatedEntrance show={partsSectionReady}>
					<Button
						icon="plus-circle"
						variant="secondary-cta"
						onClick={() => setShowAddPartModal(true)}
						containerProps={{ mb: 2 }}
					>
						Add item to transaction
					</Button>
				</AnimatedEntrance>

				{!!transactionLines.length && (
					<Table
						data={transactionLines}
						columns={partsColumns}
						disableAllSorting
						rowVerticalAlign="middle"
					/>
				)}
			</Box>

			<Divider mt={5} />

			{!!errorText && <ErrorText textAlign="center">{errorText}</ErrorText>}

			<Box display="flex" justifyContent="center" maxWidth={50} m="auto" py={1}>
				<Button
					variant="primary-cta"
					fillContainer
					disabled={!!errorText}
					onClick={() => {
						const error = getErrors()

						if (error) {
							setErrorText(error)
						} else {
							setConfirmationConfig({
								title: "Submit Transaction",
								message: "Confirm: Execute this inventory transaction?",
								onConfirm: async () => {
									await handleSubmit()
								},
								confirmButtonText: "Yes, Transfer Items",
							})
						}
					}}
				>
					Create Transaction
				</Button>
			</Box>

			{!!selectedTransactionType && showAddPartModal && (
				<AddTransactionPartModal
					transactionType={selectedTransactionType}
					fromLocation={selectedSource}
					toLocation={selectedDestination}
					currentTransactionLines={transactionLines}
					onSave={handleAddPart}
					onClose={() => setShowAddPartModal(false)}
				/>
			)}
			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</Card>
	)
}

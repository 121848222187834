import React, { Component } from "react"
import { withCallApi } from "~/components"
import { withRouter } from "react-router-dom"

import { editOffHours } from "@ncs/mortar/redux/services/users"

//local components
import CreateOffHoursEvent from "./CreateOffHoursEvent"

class CreateOffHoursEventContainer extends Component {
	constructor(props) {
		super(props)

		this.state = {
			startDate: null,
			endDate: null,
			selectedTechAss: [],
		}
	}

	handleSetInputRef = (input) => {
		this.input = input
	}
	componentDidMount = () => {
		this.setState({
			startDate: this.props.event.start,
			endDate: this.props.event.end,
		})
	}

	handleEventSave = () => {
		if (!this.state.selectedTech) {
			return
		}
		if (!this.state.startDate || !this.state.endDate) {
			return
		}
		let event = {
			event: {
				territoryId: this.props.territory,
				techId: this.state.selectedTech._id,
				startTime: this.state.startDate,
				endTime: this.state.endDate,
				active: true,
				groups: this.state.selectedTechAss,
			},
		}

		this.props.callApi(editOffHours(event)).then((result) => {
			if (result.error) {
				return this.setState({ error: result.error })
			} else {
				this.setState({
					success: true,
					newCommentMessage: "Event Saved.",
				})
				this.props.onEventSave()
			}
		})
	}
	handleTechAssSelected = (selectedTechAss) => {
		this.setState({
			selectedTechAss,
		})
	}
	handleTechSelected = (selectedTech) => {
		this.setState({ selectedTech })
	}
	handleStartDateChange = (d) => {
		this.setState({ startDate: d.format() })
	}
	handleEndDateChange = (d) => {
		this.setState({ endDate: d.format() })
	}
	render() {
		const { isSaving } = this.state
		return (
			<CreateOffHoursEvent
				setInputRef={this.handleSetInputRef}
				isSaving={isSaving}
				onTechSelected={this.handleTechSelected}
				onStartDateChange={this.handleStartDateChange}
				startDate={this.state.startDate}
				onEndDateChange={this.handleEndDateChange}
				endDate={this.state.endDate}
				selectedTechAss={this.state.selectedTechAss}
				onTechAssSelected={this.handleTechAssSelected}
				techAssignments={this.props.techAssignments}
				onEventSave={this.handleEventSave}
				onCancelEvent={this.props.onCancelEvent}
			/>
		)
	}
}

export default withRouter(withCallApi(CreateOffHoursEventContainer))

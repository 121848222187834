import React, { useEffect } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import memoizeOne from "memoize-one"
import { BaseSelectFilter, withDynamicTableFilter } from "@ncs/bricks/components"
import { loadLookups, LOOKUPS } from "../../redux/services/lookups"
import { CALL_TYPES, FILTER_OVERRIDE } from "../../util/constants"

const getCallTypeOptions = memoizeOne((callTypes) => [
	{ _id: "all", description: "All Types" },
	{ _id: "on_contract", description: "Contract Types (MI/PI/PM)" },
	...(callTypes?.length ? callTypes : Object.values(CALL_TYPES)),
])

function getFilterValue(value) {
	if (value === "on_contract") {
		return {
			id: "call_type_id__in",
			value: Object.entries(CALL_TYPES)
				.filter(([key, callType]) => callType.isContract)
				.map(([key, callType]) => callType._id.toString())
				.join(","),
		}
	}

	if (Number(value) > 0) {
		return { id: "call_type_id", value }
	}

	return { id: "" }
}

export const ON_CONTRACT_FILTER_VALUE = {
	type: FILTER_OVERRIDE,
	value: "on_contract",
	filterValue: getFilterValue("on_contract"),
}

function DispatchCallTypeFilter(props) {
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(loadLookups([LOOKUPS.CallTypes]))
	}, [dispatch])

	const callTypes = useSelector((x) => x.lookups.callTypes, shallowEqual)

	function handleChange(value) {
		props.onChange({ type: FILTER_OVERRIDE, value, filterValue: getFilterValue(value) })
	}

	const options = getCallTypeOptions(callTypes)

	return (
		<BaseSelectFilter
			id="callTypes"
			labelText="Call Type"
			options={options}
			valueAccessor="_id"
			textAccessor="description"
			{...props}
			value={props.value?.value || options[0]._id}
			onChange={handleChange}
		/>
	)
}

export const AliasedDispatchCallTypeFilter = (filterName) =>
	withDynamicTableFilter(filterName)(React.memo(DispatchCallTypeFilter))

export default withDynamicTableFilter("call_type_id")(React.memo(DispatchCallTypeFilter))

import { FC, useCallback, useMemo, useState } from "react"

import { Cell, Column } from "react-table"

import {
	BinLocation,
	InventoryLocation,
	InventoryPart,
	LocationPartSummary,
	makeApiErrorMessage,
	useEmailLocationInventory,
	useEmailLocationStock,
	useLocationPartSummaries,
	useUpdateLocationPart,
} from "@ncs/ncs-api"
import { formatCurrency, formatNumber } from "@ncs/ts-utils"
import {
	Box,
	Button,
	ButtonProps,
	ConfirmationModal,
	ConfirmationModalConfig,
	encodeUrlState,
	GridContainer,
	GridItem,
	GridItemProps,
	LetterIcon,
	LocationBinSelector,
	LocationPartTypeSelector,
	PartImage,
	PartSelector,
	Table,
	TableProps,
	usePaginationUrlState,
	useToast,
	useUrlState,
} from "@ncs/web-legos"

import { PartManagementUrlState } from "~/views/Inventory/PartManagement/PartManagement"

import { isSummaryRow, makeLocationPartDeletePatch } from "../../locations-util"
import { AllocationsModal, LocationPartModal, LocationPartOrdersModal } from "./components"
import { BulkImportPartsModal } from "~/components"

export interface LocationPartsTabProps {
	location: InventoryLocation
}

export type LocationPartsTabUrlState = {
	partsTabPart: string | null
	type: string | null
	partsTabBin: string | null
}

type SummaryRowOrBinRow = LocationPartSummary["bins"][number] | LocationPartSummary

export const LocationPartsTab: FC<LocationPartsTabProps> = ({ location }) => {
	const { makeSuccessToast, makeErrorToast } = useToast()
	const [selectedPart, setSelectedPart] = useState<InventoryPart | null>(null)
	const [partForEdit, setPartForEdit] = useState<LocationPartSummary | null>(null)
	const [showAddModal, setShowAddModal] = useState(false)
	const [allocationsModalPart, setAllocationsModalPart] = useState<string | null>(null)
	const [showImportModal, setShowImportModal] = useState(false)
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)
	const [isExporting, setIsExporting] = useState<"stockItems" | "inventory" | null>(null)
	const [partIdForOrdersModal, setPartIdForOrdersModal] = useState<string | null>(null)
	const [selectedBin, setSelectedBin] = useState<BinLocation | null>(null)

	const [{ partsTabPart, partsTabBin, ...restOfUrlState }, { updateUrlValue }] =
		useUrlState<LocationPartsTabUrlState>({
			partsTabPart: null,
			type: null,
			partsTabBin: null,
		})

	const [pagination, setPagination] = usePaginationUrlState()

	const summariesQuery = useLocationPartSummaries({
		params: {
			...restOfUrlState,
			part: partsTabPart,
			location: location.id,
			bin: partsTabBin,
			page: pagination.page,
			pageSize: pagination.pageSize,
		},
		manualPagination: true,
	})

	const updatePart = useUpdateLocationPart()
	const emailStock = useEmailLocationStock()
	const emailInventory = useEmailLocationInventory()

	const deletePart = useCallback(
		async (partSummary: LocationPartSummary) => {
			try {
				await updatePart({
					updates: makeLocationPartDeletePatch(partSummary, location.id),
				})
				makeSuccessToast("Part relationship removed from location")
				setPartForEdit(null)
			} catch (e) {
				makeErrorToast(makeApiErrorMessage(e))
			}
		},
		[makeSuccessToast, makeErrorToast, updatePart, location.id]
	)

	const handleEmailStock = useCallback(
		async (locationId: string) => {
			try {
				setIsExporting("stockItems")
				await emailStock(locationId)
				makeSuccessToast(
					"Stock report is being created. Check your email in a few minutes."
				)
			} catch (e) {
				makeErrorToast(makeApiErrorMessage(e))
			} finally {
				setIsExporting(null)
			}
		},
		[emailStock, makeErrorToast, makeSuccessToast]
	)

	const handleEmailInventory = useCallback(
		async (locationId: string) => {
			try {
				setIsExporting("inventory")
				await emailInventory(locationId)
				makeSuccessToast(
					"Inventory report is being created. Check your email in a few minutes."
				)
			} catch (e) {
				makeErrorToast(makeApiErrorMessage(e))
			} finally {
				setIsExporting(null)
			}
		},
		[emailInventory, makeErrorToast, makeSuccessToast]
	)

	const rowMenu: TableProps<SummaryRowOrBinRow>["rowMenu"] = useMemo(() => {
		return [
			{
				label: "Edit at location",
				iconName: "pencil",
				onClick: (row) => {
					if (isSummaryRow(row.original)) {
						setPartForEdit(row.original)
					}
				},
			},
			{
				label: "View orders for part",
				iconName: "exchange-alt",
				onClick: (row) => {
					if (isSummaryRow(row.original)) {
						setPartIdForOrdersModal(row.original.partId)
					}
				},
			},
			{
				label: "Part management details",
				iconName: "external-link",
				onClick: (row) => {
					if (isSummaryRow(row.original)) {
						window.open(
							`/inventory/part-management/${encodeUrlState<PartManagementUrlState>({
								partId: row.original.partId,
							})}`
						)
					}
				},
			},
			{
				label: "Remove",
				iconName: "trash",
				disabledAccessor: ({ original }) => {
					if (isSummaryRow(original)) {
						return !original.isStock && !original.isLocallySourced
					}
					return false
				},
				onClick: ({ original }) => {
					if (isSummaryRow(original)) {
						setConfirmationConfig({
							title: "Remove Part",
							message: "Remove this part's relationship with this location?",
							onConfirm: () => deletePart(original),
						})
					}
				},
			},
		]
	}, [deletePart])

	const rightTableButtons: ButtonProps[] = useMemo(() => {
		return [
			{
				buttonText: "Export stock items",
				icon: "external-link",
				onClick: () => handleEmailStock(location.id),
				isLoading: isExporting === "stockItems",
			},
			{
				buttonText: "Export inventory",
				icon: "external-link",
				onClick: () => handleEmailInventory(location.id),
				isLoading: isExporting === "inventory",
			},
		]
	}, [handleEmailStock, handleEmailInventory, isExporting, location.id])

	const getSubRows = useCallback((row: SummaryRowOrBinRow) => {
		return isSummaryRow(row) ? row.bins.filter((b) => b.binQuantity) : []
	}, [])

	return (
		<>
			<Box display="flex" justifyContent="flex-end" columnGap={1} alignItems="center" mb={2}>
				<Button icon="plus-circle" onClick={() => setShowAddModal(true)}>
					Add part to location
				</Button>
				<Button icon="file-spreadsheet" onClick={() => setShowImportModal(true)}>
					Bulk update/add parts
				</Button>
			</Box>

			<GridContainer>
				<GridItem {...filterGridItemProps}>
					<PartSelector
						value={selectedPart}
						onChange={(newPart) => {
							setSelectedPart(newPart)
							updateUrlValue("partsTabPart", newPart?.id ?? null)
							setPagination((prev) => ({ ...prev, page: 1 }))
						}}
						initialPartId={partsTabPart}
						pageSize={100}
						skipRestrictedCheck
						includeNonService
					/>
				</GridItem>
				<GridItem {...filterGridItemProps}>
					<LocationPartTypeSelector
						value={restOfUrlState.type}
						onChange={(newType) => {
							updateUrlValue("type", newType)
							setPagination((prev) => ({ ...prev, page: 1 }))
						}}
					/>
				</GridItem>
				<GridItem {...filterGridItemProps}>
					<LocationBinSelector
						locationId={location.id}
						value={selectedBin}
						onChange={(newBin) => {
							setSelectedBin(newBin)
							updateUrlValue("partsTabBin", newBin?.id ?? null)
							setPagination((prev) => ({ ...prev, page: 1 }))
						}}
						initialId={partsTabBin}
						label="Bin"
					/>
				</GridItem>
			</GridContainer>

			<Table
				query={summariesQuery}
				columns={columns}
				getSubRows={getSubRows}
				rowMenu={rowMenu}
				rowVerticalAlign="middle"
				disableAllSorting
				autoResetExpanded={false}
				rightButtons={rightTableButtons}
				pagination={pagination}
				setPagination={setPagination}
			/>

			{(showAddModal || !!partForEdit) && (
				<LocationPartModal
					isOpen
					onClose={() => {
						setShowAddModal(false)
						setPartForEdit(null)
					}}
					partSummary={partForEdit}
					location={location}
				/>
			)}
			{!!allocationsModalPart && (
				<AllocationsModal
					isOpen
					onClose={() => setAllocationsModalPart(null)}
					partId={allocationsModalPart}
					locationId={location.id}
				/>
			)}
			<BulkImportPartsModal
				isOpen={showImportModal}
				title="Import Parts At Location"
				onClose={() => setShowImportModal(false)}
				formDataId={location.id}
			/>
			{!!partIdForOrdersModal && (
				<LocationPartOrdersModal
					isOpen
					partId={partIdForOrdersModal}
					location={location}
					onClose={() => setPartIdForOrdersModal(null)}
				/>
			)}
			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</>
	)
}

const columns: Column<SummaryRowOrBinRow>[] = [
	{
		Header: "Part number",
		id: "part-number",
		headingTooltip: (
			<>
				<LetterIcon letter="S" /> = Stock Part
				<br />
				<LetterIcon letter="L" /> = Locally Sourced Part
			</>
		),
		headingTooltipIcon: null,
		Cell: ({ row: { original } }: Cell<SummaryRowOrBinRow>) =>
			isSummaryRow(original) ?
				<Box display="flex" alignItems="center" columnGap={0.5}>
					{original.partNumber}
					{original.isStock && <LetterIcon letter="S" title="Stock Part" />}
					{original.isLocallySourced && <LetterIcon letter="L" title="Local Part" />}
				</Box>
			:	"",
	},
	{
		Header: "Name",
		accessor: (original) =>
			isSummaryRow(original) ? original.partName : `Bin: ${original.binCode}`,
		Cell: ({ row: { original } }: Cell<SummaryRowOrBinRow>) => {
			if (isSummaryRow(original)) {
				return (
					<Box display="flex" alignItems="center" columnGap={1}>
						<PartImage
							src={original.partImgUrl}
							alt={original.partName}
							width={2}
							minWidth={2}
						/>
						<span>{original.partName}</span>
					</Box>
				)
			} else {
				return <Box pl={3.1}>Bin: {original.binCode}</Box>
			}
		},
	},
	{
		Header: "Supplier",
		id: "vendor",
		hiddenByDefault: true,
		Cell: ({ row: { original } }: Cell<SummaryRowOrBinRow>) => {
			return isSummaryRow(original) ? original.vendor?.name || "-" : ""
		},
	},
	{
		Header: "On-hand qty",
		id: "on-hand-qty",
		Cell: ({ row: { original } }: Cell<SummaryRowOrBinRow>) => {
			const qty = isSummaryRow(original) ? original.quantityOnHand : original.binQuantity

			return formatNumber(qty || 0, { zeroString: "-" })
		},
	},
	{
		Header: "Picking",
		accessor: (original) => {
			return formatNumber(isSummaryRow(original) ? original.picking : original.binPicking, {
				zeroString: "-",
			})
		},
	},
	{
		Header: "Shipping",
		accessor: (original) => {
			return formatNumber(
				isSummaryRow(original) ? original.shipping : original.binShipping,
				{ zeroString: "-" }
			)
		},
	},
	{
		Header: "Available qty",
		id: "available-qty",
		Cell: ({ row: { original } }: Cell<SummaryRowOrBinRow>) => {
			return isSummaryRow(original) ?
					formatNumber(original.quantityAvailable || 0, { zeroString: "-" })
				:	""
		},
	},
	{
		Header: "Total demands",
		id: "demands",
		Cell: ({ row: { original } }: Cell<SummaryRowOrBinRow>) => {
			return isSummaryRow(original) ?
					formatNumber(original.softAllocations || 0, { zeroString: "-" })
				:	""
		},
	},
	{
		Header: "Total Cost",
		id: "cost",
		hiddenByDefault: true,
		Cell: ({ row: { original } }: Cell<SummaryRowOrBinRow>) =>
			isSummaryRow(original) ?
				original.totalCost ?
					formatCurrency(original.totalCost)
				:	"-"
			: original.binCost ? formatCurrency(original.binCost)
			: "-",
	},
	{
		Header: "Local supplier part #",
		id: "local-vendor-part-number",
		hiddenByDefault: true,
		Cell: ({ row: { original } }: Cell<SummaryRowOrBinRow>) => {
			return isSummaryRow(original) ? original.localVendorPartNumber || "-" : ""
		},
	},
	{
		Header: "Local supplier part name",
		id: "local-vendor-part-name",
		hiddenByDefault: true,
		Cell: ({ row: { original } }: Cell<SummaryRowOrBinRow>) => {
			return isSummaryRow(original) ? original.localVendorPartName || "-" : ""
		},
	},
	{
		Header: "Local supplier cost",
		id: "local-vendor-cost",
		hiddenByDefault: true,
		Cell: ({ row: { original } }: Cell<SummaryRowOrBinRow>) => {
			return (
				isSummaryRow(original) ?
					original.localVendorPartCost ?
						formatCurrency(original.localVendorPartCost)
					:	"-"
				:	""
			)
		},
	},
	{
		Header: "Min location level",
		id: "min-location-level",
		hiddenByDefault: true,
		Cell: ({ row: { original } }: Cell<SummaryRowOrBinRow>) => {
			return (
				isSummaryRow(original) ?
					original.minLocationLevel ?
						formatNumber(original.minLocationLevel)
					:	"-"
				:	""
			)
		},
	},
	{
		Header: "Max location level",
		id: "max-location-level",
		hiddenByDefault: true,
		Cell: ({ row: { original } }: Cell<SummaryRowOrBinRow>) => {
			return (
				isSummaryRow(original) ?
					original.maxLocationLevel ?
						formatNumber(original.maxLocationLevel)
					:	"-"
				:	""
			)
		},
	},
	{
		Header: "Econ order amount",
		id: "econ-order-amount",
		hiddenByDefault: true,
		Cell: ({ row: { original } }: Cell<SummaryRowOrBinRow>) => {
			return (
				isSummaryRow(original) ?
					original.econOrderAmount ?
						formatNumber(original.econOrderAmount)
					:	"-"
				:	""
			)
		},
	},
	{
		Header: "Min order quantity",
		id: "min-order-quantity",
		hiddenByDefault: true,
		Cell: ({ row: { original } }: Cell<SummaryRowOrBinRow>) => {
			return (
				isSummaryRow(original) ?
					original.minOrderQuantity ?
						formatNumber(original.minOrderQuantity)
					:	"-"
				:	""
			)
		},
	},
	{
		Header: "Lead time (days)",
		id: "lead-time",
		hiddenByDefault: true,
		Cell: ({ row: { original } }: Cell<SummaryRowOrBinRow>) => {
			return (
				isSummaryRow(original) ?
					original.leadTime ?
						formatNumber(original.leadTime)
					:	"-"
				:	""
			)
		},
	},
]

const filterGridItemProps: GridItemProps = {
	xs: 12,
	sm: 6,
	md: 4,
	lg: 3,
}

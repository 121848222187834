import React from "react"

import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { bindActionCreators } from "redux"

import { listTerritories } from "@ncs/mortar/redux/services/lookups"
import {
	getServiceRequestsDashboard,
	handleTerritoryChange,
} from "@ncs/mortar/redux/services/serviceRequests"
import { setQuickFilter } from "@ncs/bricks/redux/services/dynamicTables"

import { LoadingWrapper } from "~/components"
import { dispatchDeepLinks, dispatchesReduxKey as reduxKey } from "~/util"

import ServiceRequestsDashboard from "./ServiceRequestsDashboard"

class ServiceRequestsDashboardContainer extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			dataRefreshTimer: null,
		}
	}

	componentDidMount = () => {
		this.refreshData()

		// refresh data on a five minute interval
		let timer = setInterval(this.refreshData, 1000 * 60 * 5)
		this.setState({ timer })

		if (!this.props.territories || !this.props.territories.length) this.props.listTerritories()
	}

	componentWillUnmount() {
		clearInterval(this.state.timer)
	}

	refreshData = () => {
		this.props.getData()
	}

	viewDispatches = () => {
		this.props.history.push("/service/dispatches")
	}

	handleViewOpenDispatches = () => {
		this.props.history.push(dispatchDeepLinks.open)
	}

	handleViewNewTodayDispatches = () => {
		this.props.history.push(dispatchDeepLinks.newToday)
	}

	handleViewClosedTodayDispatches = () => {
		this.props.history.push(dispatchDeepLinks.closedToday)
	}

	handleViewDispatchesOpenOnContract = () => {
		this.props.history.push(dispatchDeepLinks.openOnContract)
	}

	handleViewFieldApprovalInvoices = () => {
		this.props.history.push(dispatchDeepLinks.fieldApproval)
	}

	handleViewSecondApprovalInvoices = () => {
		this.props.history.push(dispatchDeepLinks.secondApproval)
	}

	handleViewRejectedInvoices = () => {
		this.props.history.push(dispatchDeepLinks.rejected)
	}

	handleViewAdditionalEntryInvoices = () => {
		this.props.history.push(dispatchDeepLinks.additionalEntry)
	}

	render() {
		return (
			<LoadingWrapper isLoading={this.props.isInitialState} title="Doing the math...">
				<ServiceRequestsDashboard
					isLoading={this.props.isLoading}
					lastRefreshed={this.props.lastRefreshed}
					dispatchesOpen={this.props.dispatches_open}
					dispatchesOpenUpdated={this.props.dispatches_open_updated}
					dispatchesNewToday={this.props.dispatches_new_today}
					dispatchesNewTodayUpdated={this.props.dispatches_new_today_updated}
					dispatchesClosedToday={this.props.dispatches_closed_today}
					dispatchesClosedTodayUpdated={this.props.dispatches_closed_today_updated}
					dispatchesOpenOnContract={this.props.dispatches_open_on_contract}
					dispatchesOpenOnContractUpdated={
						this.props.dispatches_open_on_contract_updated
					}
					invoicesPendingFieldApproval={this.props.invoices_pending_field_approval}
					invoicesPendingFieldApprovalUpdated={
						this.props.invoices_pending_field_approval_updated
					}
					invoicesPendingSecondApproval={this.props.invoices_pending_second_approval}
					invoicesPendingSecondApprovalUpdated={
						this.props.invoices_pending_second_approval_updated
					}
					invoicesRejected={this.props.invoices_rejected}
					invoicesRejectedUpdated={this.props.invoices_rejected_updated}
					invoicesAdditionalEntry={this.props.invoices_additional_entry}
					invoicesAdditionalEntryUpdated={this.props.invoices_additional_entry_updated}
					dispatchHistory={this.props.dispatch_history}
					dispatchHistoryUpdated={this.props.dispatch_history_updated}
					invoiceHistory={this.props.invoice_history}
					invoiceHistoryUpdated={this.props.invoice_history_updated}
					onViewOpenDispatches={this.handleViewOpenDispatches}
					onViewNewTodayDispatches={this.handleViewNewTodayDispatches}
					onViewClosedTodayDispatches={this.handleViewClosedTodayDispatches}
					onViewDispatchesOpenOnContract={this.handleViewDispatchesOpenOnContract}
					onViewFieldApprovalInvoices={this.handleViewFieldApprovalInvoices}
					onViewSecondApprovalInvoices={this.handleViewSecondApprovalInvoices}
					onViewRejectedInvoices={this.handleViewRejectedInvoices}
					onViewAdditionalEntryInvoices={this.handleViewAdditionalEntryInvoices}
					territories={this.props.territories}
					selectedTerritory={this.props.selectedTerritory}
					onTerritoryChange={this.props.handleTerritoryChange}
				/>
			</LoadingWrapper>
		)
	}
}

ServiceRequestsDashboardContainer.propTypes = {
	getData: PropTypes.func.isRequired,
	listTerritories: PropTypes.func.isRequired,
	handleTerritoryChange: PropTypes.func.isRequired,
	setQuickFilter: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
	return {
		...state.serviceRequests.dashboard,
		territories: state.lookups.territories,
	}
}

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			getData: getServiceRequestsDashboard,
			listTerritories,
			handleTerritoryChange,
			setQuickFilter: setQuickFilter(reduxKey),
		},
		dispatch
	),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(ServiceRequestsDashboardContainer))

import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { InputAdornment } from "@material-ui/core"
import { titleCase } from "@ncs/bricks/util/formatters"
import { unpythonify } from "@ncs/bricks/util/variableCasing"
import { useAppRestrictions, useCallApi } from "@ncs/bricks/util/hooks"
import {
	getChemicalMachineTypes,
	getChemicalMachinePhases,
	getChemicalPhaseParts,
	patchChemicalMachinePhase,
} from "@ncs/mortar/redux/services/inventory"
import { APPLICATIONS } from "@ncs/mortar/util/constants"
import {
	AnimatedEntrance,
	ButtonWithIcon,
	GridContainer,
	GridItem,
	Input,
	InputAsDisplay,
	QuickCard,
	Select,
	DynamicTable,
} from "~/components"
import EditPhasePartModal from "./EditPhasePartModal"

import {
	Add,
	Check,
	ChevronLeft,
	Close,
	Edit,
	Search,
	SettingsInputComponentOutlined,
} from "@material-ui/icons"

const MachinePhaseDetail = () => {
	const isAdmin = useAppRestrictions(APPLICATIONS.ChemMachineTypeAdmin)
	const history = useHistory()
	const callApi = useCallApi()
	const { machine_type_id: machineTypeId, phase_id: phaseId } = useParams()
	const [machineType, setMachineType] = useState(null)
	const [machinePhase, setMachinePhase] = useState(null)
	const [phaseParts, setPhaseParts] = useState([])
	const [filterQuery, setFilterQuery] = useState("")
	const [isLoadingMachinePhases, setIsLoadingMachinePhases] = useState(false)
	const [isLoadingPhaseParts, setIsLoadingPhaseParts] = useState(false)
	const [isEditingPhase, setIsEditingPhase] = useState(false)
	const [tempDescription, setTempDescription] = useState("")
	const [tempIsActive, setTempIsActive] = useState(null)
	const [phasePartIdToEdit, setPhasePartIdToEdit] = useState(null)
	const [showPhasePartModal, setShowPhasePartModal] = useState(false)

	const fetchMachineTypes = useCallback(async () => {
		const { payload } = await callApi(getChemicalMachineTypes())
		setMachineType(unpythonify(payload.find((p) => p.id === machineTypeId)))
	}, [callApi, machineTypeId])

	const fetchMachinePhases = useCallback(async () => {
		setIsLoadingMachinePhases(true)
		const { payload } = await callApi(getChemicalMachinePhases({ machineTypeId }))
		setMachinePhase(unpythonify(payload.find((p) => p.id === phaseId)))
		setIsLoadingMachinePhases(false)
	}, [callApi, machineTypeId, phaseId])

	const fetchPhaseParts = useCallback(async () => {
		setIsLoadingPhaseParts(true)
		const { payload } = await callApi(getChemicalPhaseParts({ machinePhaseId: phaseId }))
		setIsLoadingPhaseParts(false)
		setPhaseParts(unpythonify(payload))
	}, [callApi, phaseId])

	useEffect(() => {
		fetchMachineTypes()
	}, [fetchMachineTypes])

	useEffect(() => {
		fetchMachinePhases()
	}, [fetchMachinePhases])

	useEffect(() => {
		fetchPhaseParts()
	}, [fetchPhaseParts])

	function reset() {
		setIsEditingPhase(false)
		setTempDescription("")
		setTempIsActive(null)
	}

	function startEditing() {
		setIsEditingPhase(true)
		setTempDescription(machinePhase.description)
		setTempIsActive(machinePhase.isActive)
	}

	async function handleSave() {
		setIsLoadingMachinePhases(true)
		const { payload } = await callApi(
			patchChemicalMachinePhase({
				machinePhaseId: phaseId,
				description: tempDescription,
				isActive: tempIsActive,
			})
		)
		setMachinePhase(unpythonify(payload))
		setIsLoadingMachinePhases(false)
		reset()
	}

	function handleRowClick(row) {
		setPhasePartIdToEdit(row.id)
		setShowPhasePartModal(true)
	}

	function handleBack() {
		history.push(`/machine-types/${machineTypeId}`)
	}

	const filteredPhaseParts = useMemo(() => {
		return phaseParts.filter(
			({ part }) =>
				part.description.toLowerCase().includes(filterQuery.toLowerCase()) ||
				part.partNumber.includes(filterQuery)
		)
	}, [phaseParts, filterQuery])

	if (!machineType || !machinePhase) {
		return <div />
	}

	return (
		<>
			<ButtonWithIcon
				content={`Back to ${machineType.description} machine type`}
				icon={<ChevronLeft />}
				onClick={handleBack}
				color="white"
				size="sm"
				round
			/>

			<QuickCard
				icon={<SettingsInputComponentOutlined />}
				title={`${machinePhase.description} Phase in ${machineType.description}`}
			>
				<GridContainer>
					<GridItem lg={4} md={6} xs={12}>
						{isEditingPhase ?
							<Input
								labelText="Machine phase name"
								value={tempDescription}
								onChange={(e) => setTempDescription(e.target.value)}
								inputProps={{
									autoFocus: true,
								}}
							/>
						:	<InputAsDisplay
								labelText="Machine phase name"
								value={machinePhase.description}
							/>
						}
					</GridItem>
					<GridItem lg={4} md={6} xs={12}>
						{isEditingPhase ?
							<Select
								labelText="Machine phase status"
								value={String(tempIsActive)}
								onChange={(value) => setTempIsActive(value === "true")}
								options={[
									{
										value: "true",
										text: "Active",
									},
									{
										value: "false",
										text: "Inactive",
									},
								]}
							/>
						:	<InputAsDisplay
								value={machinePhase.isActive ? "Active" : "Inactive"}
								labelText="Machine phase status"
							/>
						}
					</GridItem>
					{isAdmin && (
						<GridItem lg={4} xs={12} justifyContentRight alignItemsCenter>
							{!isEditingPhase && (
								<ButtonWithIcon
									content="Edit Name and Status"
									onClick={startEditing}
									icon={<Edit />}
									size="sm"
									color="primary"
									disabled={isLoadingMachinePhases}
									noMargin
									round
								/>
							)}
							<AnimatedEntrance show={isEditingPhase}>
								<ButtonWithIcon
									content="Cancel"
									onClick={reset}
									icon={<Close />}
									size="sm"
									color="primary"
									round
									simple
								/>
								<ButtonWithIcon
									content="Save"
									onClick={handleSave}
									icon={<Check />}
									size="sm"
									color="primary"
									loading={isLoadingMachinePhases}
									disabled={!tempDescription}
									round
								/>
							</AnimatedEntrance>
						</GridItem>
					)}
				</GridContainer>

				<GridContainer style={{ marginTop: "3rem" }}>
					<GridItem xs={12}>
						<h4>
							Parts and Usage for {machinePhase.description} in{" "}
							{machineType.description}
						</h4>
					</GridItem>
					<GridItem xs={12} justifyContentSpaceBetween alignItemsCenter>
						<Input
							value={filterQuery}
							onChange={(e) => setFilterQuery(e.target.value)}
							fullWidth={false}
							formControlProps={{
								style: {
									padding: "0.75rem 0",
									margin: 0,
								},
							}}
							inputProps={{
								placeholder: "Search all fields...",
								startAdornment: (
									<InputAdornment position="start">
										<Search />
									</InputAdornment>
								),
							}}
						/>
						{isAdmin && (
							<ButtonWithIcon
								content="Add Part To Phase"
								onClick={() => setShowPhasePartModal(true)}
								icon={<Add />}
								size="sm"
								color="success"
								disabled={isEditingPhase}
								noMargin
								round
							/>
						)}
					</GridItem>
					<GridItem xs={12}>
						<DynamicTable
							data={filteredPhaseParts}
							onRowClick={isAdmin ? handleRowClick : undefined}
							noDataText={
								isLoadingPhaseParts ? "Loading parts..." : (
									"No parts found for this phase"
								)
							}
							columns={[
								{
									id: "part.description",
									Header: "Name",
									accessor: (item) => titleCase(item.part.description),
								},
								{
									Header: "Part number",
									accessor: "part.partNumber",
								},
								{
									id: "defaultUsageRateInML",
									Header: "Default ml per wash",
									accessor: (item) => `${item.defaultUsageRateInML} ml`,
								},
							]}
						/>
					</GridItem>
				</GridContainer>
			</QuickCard>

			<EditPhasePartModal
				show={showPhasePartModal}
				phase={machinePhase}
				phasePart={phaseParts.find((p) => p.id === phasePartIdToEdit)}
				onClose={() => {
					setShowPhasePartModal(false)
					setPhasePartIdToEdit(null)
				}}
				onCreate={(newPhasePart) => {
					setPhaseParts((prev) => [unpythonify(newPhasePart), ...prev])
				}}
				onUpdate={(phasePart) => {
					setPhaseParts((prev) =>
						prev.map((p) => (p.id === phasePart.id ? unpythonify(phasePart) : p))
					)
				}}
				onRemove={(phasePartId) => {
					setPhaseParts((prev) => [...prev.filter((p) => p.id !== phasePartId)])
				}}
			/>
		</>
	)
}

export default MachinePhaseDetail

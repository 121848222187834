export const snakeToCamel = (str) =>
	str.replace(/([-_][a-z])/gi, (group) => group.toUpperCase().replace("-", "").replace("_", ""))

export const unpythonify = (item) =>
	Array.isArray(item) ? item.map(unpythonify)
	: item && typeof item === "object" ?
		Object.fromEntries(
			Object.entries(item).map(([key, value]) => [snakeToCamel(key), unpythonify(value)])
		)
	:	item

export const camelToSnake = (str) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

export const pythonify = (item) => {
	if (!item) {
		return item
	}
	if (Array.isArray(item)) {
		return item.map(pythonify)
	}
	if (typeof item === "object") {
		return Object.fromEntries(
			Object.entries(item).map(([key, value]) => [camelToSnake(key), pythonify(value)])
		)
	}

	return item
}

import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { BaseSelectFilter, withDynamicTableFilter } from "@ncs/bricks/components"
import { loadLookups, LOOKUPS } from "../../redux/services/lookups"

const NCSModelFilter = (props) => {
	const dispatch = useDispatch()
	const models = useSelector((store) => store.lookups.ncsEquipmentModels)

	useEffect(() => {
		dispatch(loadLookups(LOOKUPS.NCSEquipmentModels))
	}, [dispatch])

	return (
		<BaseSelectFilter
			id="models"
			labelText="Model"
			options={[{ _id: "", model_name: "All models" }, ...models]}
			valueAccessor="_id"
			textAccessor="model_name"
			{...props}
		/>
	)
}

export default React.memo(withDynamicTableFilter("model")(NCSModelFilter))

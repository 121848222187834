import React from "react"
import moment from "moment"

import { Datetime } from "../../components"
import { formatDate } from "../../util/formatters"

class BaseDateFilter extends React.PureComponent {
	handleChange = (value) => {
		this.props.onChange(moment(value).startOf("day"), formatDate(value))
	}

	render() {
		return (
			<Datetime
				value={this.props.value}
				dateFormat="MMM-DD-YYYY"
				{...this.props}
				onChange={this.handleChange}
			/>
		)
	}
}

export default BaseDateFilter

import React, { useState } from "react"
import PropTypes from "prop-types"
import get from "lodash/get"
import { Add, Check } from "@material-ui/icons"
import { Chip, ChipProps } from "@material-ui/core"
import { getAccessorValue } from "../../util/accessors"
import pluralization from "../../util/pluralization"
import {
	SearchResultChip,
	ButtonWithIcon,
	GridContainer,
	GridItem,
	Select,
} from "../../components"
import { SearchResultChipProps } from "./SearchResultChip"

export interface ManyToOneEditorProps extends Omit<SearchResultChipProps, "onDelete"> {
	label: string
	items: { [key: string]: unknown }[]
	valueAccessor?: string | ((item: unknown) => string)
	textAccessor: string | ((item: unknown) => string)
	isEditing?: boolean
	showValidationErrors?: boolean
	isValid?: boolean
	errorMessage?: string
	onDelete?: (chipValue: { [key: string]: unknown }) => void
	chipProps?: ChipProps
	availableItems?: { [key: string]: unknown }[]
	addItemButton?: React.ReactNode
	addItemLabel?: string
	addingItemDropdownLabel?: string
	alreadyAddedItemLabel?: string
	onAdd?: (item: unknown) => void
	addingVerb?: string
}

const ManyToOneEditor: React.FC<ManyToOneEditorProps> = ({
	label,
	items = [],
	valueAccessor = "value",
	textAccessor = "text",
	isEditing = false,
	showValidationErrors = false,
	isValid = true,
	errorMessage,
	onDelete,
	chipProps,
	availableItems = [],
	addItemButton,
	addItemLabel,
	addingItemDropdownLabel,
	alreadyAddedItemLabel,
	onAdd,
	addingVerb = "Add",
	...rest
}) => {
	const [state, setState] = useState<{
		isAddingItem: boolean
		selectedItem: string | null
	}>({
		isAddingItem: false,
		selectedItem: null,
	})

	function handleStartAddingItem() {
		setState({
			isAddingItem: true,
			selectedItem: null,
		})
	}

	function handleItemSelected(selectedItem: string) {
		setState({ ...state, selectedItem })
	}

	function handleAddItem() {
		const item = availableItems.find(
			(x) => getAccessorValue(x, valueAccessor) === state.selectedItem
		)
		if (item && onAdd) {
			onAdd(item)
		}
	}

	const { isAddingItem, selectedItem } = state

	const selectedItemIsAlreadyAdded =
		!!selectedItem && items.some((x) => getAccessorValue(x, valueAccessor) === selectedItem)

	return (
		<GridContainer>
			<GridItem xs={12}>
				<SearchResultChip
					label={label}
					value={items}
					error={showValidationErrors && !isValid}
					helperText={showValidationErrors ? errorMessage : ""}
					chipRenderer={(
						{ value: chipValue, isDisabled, handleClick, className },
						index
					) => {
						const text = getAccessorValue(chipValue, textAccessor)

						return (
							<Chip
								key={index}
								label={text}
								className={className}
								style={{
									pointerEvents: isDisabled ? "none" : undefined,
								}}
								onClick={handleClick}
								onDelete={
									isEditing && onDelete ? () => onDelete(chipValue) : undefined
								}
								color="primary"
								variant="outlined"
								{...chipProps}
							/>
						)
					}}
					{...rest}
				/>
			</GridItem>
			<GridItem
				xs={12}
				hide={!isEditing || isAddingItem || get(availableItems, "length", 0) < 1}
			>
				{addItemButton || (
					<ButtonWithIcon
						icon={<Add />}
						content={addItemLabel ?? `${addingVerb} ${pluralization.singular(label)}`}
						size="sm"
						round
						color="success"
						onClick={handleStartAddingItem}
					/>
				)}
			</GridItem>
			<GridItem xs={12} show={isAddingItem}>
				{() => (
					<GridContainer>
						<GridItem xs={12} sm={6}>
							<Select
								labelText={
									addingItemDropdownLabel ??
									`${addingVerb} ${pluralization.singular(label)}`
								}
								value={selectedItem ?? ""}
								onChange={handleItemSelected}
								options={availableItems}
								valueAccessor={valueAccessor}
								textAccessor={textAccessor}
							/>
						</GridItem>

						<GridItem xs={12} sm={6}>
							<ButtonWithIcon
								content={
									selectedItemIsAlreadyAdded ?
										alreadyAddedItemLabel ?? `${addingVerb}ed!`
									:	addItemLabel ??
										`${addingVerb} ${pluralization.singular(label)}`
								}
								onClick={handleAddItem}
								icon={selectedItemIsAlreadyAdded ? <Check /> : <Add />}
								disabled={!selectedItem || selectedItemIsAlreadyAdded}
								size="sm"
								color="success"
								round
							/>
						</GridItem>
					</GridContainer>
				)}
			</GridItem>
		</GridContainer>
	)
}

ManyToOneEditor.propTypes = {
	/** The content of the floating label. **/
	label: PropTypes.string.isRequired,
	/** array of objects to display **/
	items: PropTypes.array.isRequired,
	/** accessor to get the value out of the object, path as string (default 'value') or function that receives item **/
	valueAccessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
	/** accessor to get the text out of the object, path as string (default 'text') or function that receives item **/
	textAccessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
	/** bool that enables deleting items **/
	isEditing: PropTypes.bool,
	/** controls showing the error message when isValid is false **/
	showValidationErrors: PropTypes.bool,
	/** is the current data valid (usually used when this many-to-one field is required to have > 0 items) **/
	isValid: PropTypes.bool,
	/** error message to show when showValidationErrors is true and isValid is false **/
	errorMessage: PropTypes.string,
	/** receives item when delete button is pressed **/
	onDelete: PropTypes.func,
	/** Props to pass through to the `Chip` children. **/
	chipProps: PropTypes.object,

	/** optional list of items that can be added. if this is empty, the adding UI won't show **/
	availableItems: PropTypes.array,
	/** optional override to the default button used to add an item **/
	addItemButton: PropTypes.node,
	/** text of the button to begin adding an item, defaults to `${addingVerb} ${label('s singular form)}` **/
	addItemLabel: PropTypes.string,
	/** label for the dropdown when adding an item, defaults to `${addingVerb} ${label('s singular form)}`  **/
	addingItemDropdownLabel: PropTypes.string,
	/** label for the button when the selected item is already added, defaults to `${addingVerb}ed!`  **/
	alreadyAddedItemLabel: PropTypes.string,
	/** receives item when add button is pressed **/
	onAdd: PropTypes.func,
	/** allows keeping the default labels while changing the verb used when adding, e.g. "Assign" instead of "Add" **/
	addingVerb: PropTypes.string,
}

export default React.memo(ManyToOneEditor)

import { FC, useState } from "react"

import dayjs, { Dayjs } from "dayjs"
import { isValidPhoneNumber } from "react-phone-number-input"

import {
	makeApiErrorMessage,
	PartOrder,
	PartOrderStatusId,
	UserMinimal,
	useUpdatePartOrder,
} from "@ncs/ncs-api"
import { isEnumMember } from "@ncs/ts-utils"
import {
	Box,
	Checkbox,
	DateInput,
	ExtendableModalProps,
	Modal,
	OrderShipMethodSelector,
	Paragraph,
	PhoneInput,
	ShippingMethod,
	Textarea,
	TextInput,
	UserSelector,
	useToast,
} from "@ncs/web-legos"

import { usePartOrderPermissions } from "../../part-order-detail-util"

export interface EditPartOrderModalProps extends ExtendableModalProps {
	order: PartOrder
}

export const EditPartOrderModal: FC<EditPartOrderModalProps> = ({ order, ...rest }) => {
	const { makeSuccessToast } = useToast()
	const [shipMethodId, setShipMethodId] = useState<string | null>(order.shipMethod.id ?? null)
	const [isMachineDown, setIsMachineDown] = useState(order.isMachineDown)
	const [isShipComplete, setIsShipComplete] = useState(order.shipComplete)
	const [customerPO, setCustomerPO] = useState<string | null>(order.purchaseOrderNumber)
	const [invoiceComment, setInvoiceComment] = useState<string | null>(order.comment)
	const [internalComment, setInternalComment] = useState<string | null>(order.internalComment)
	const [phone, setPhone] = useState<string | null>(order.contactPhone)
	const [targetDeliveryDate, setTargetDeliveryDate] = useState<Dayjs | null>(() =>
		order.targetDeliveryDate ? dayjs(order.targetDeliveryDate) : null
	)
	const [holder, setHolder] = useState<UserMinimal | null>(null)
	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const updateOrder = useUpdatePartOrder(order.id)
	const { canEditHold } = usePartOrderPermissions(order)

	const orderIsOnHold = order.status.id === PartOrderStatusId.Hold

	const onSave = async () => {
		try {
			if (!shipMethodId) {
				throw new Error("No ship method selected")
			}
			if (!phone || !isValidPhoneNumber(phone)) {
				throw new Error("A valid phone number is required")
			}
			if (orderIsOnHold && !targetDeliveryDate) {
				throw new Error("Target delivery date is required for orders on hold")
			}

			setIsSaving(true)
			await updateOrder({
				updates: {
					isMachineDown,
					comment: invoiceComment || null,
					internalComment: internalComment || null,
					purchaseOrderNumber: customerPO || null,
					shipComplete: isShipComplete,
					shipMethodId,
					contactPhone: phone,

					// If order is on hold, you can edit the date. If order is not on hold, but a date
					// is on the order (because it USED to be on hold), then allow editing the date.
					// But if it's not on hold, and there isn't a date on the order already, then
					// don't send it.
					targetDeliveryDate:
						canEditHold ?
							orderIsOnHold || order.targetDeliveryDate ?
								targetDeliveryDate?.toISOString() ?? null
							:	undefined
						:	undefined,
					holders:
						orderIsOnHold && canEditHold ?
							holder ? [holder.id]
							:	null
						:	undefined,
				},
				id: order.id,
			})
			makeSuccessToast("Order updated")
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	return (
		<Modal
			{...rest}
			title="Edit Part Order Details"
			rightButtons={{
				buttonText: "Save Order",
				isLoading: isSaving,
				onClick: onSave,
			}}
			closeButtonText="Cancel"
			errorText={errorText}
		>
			<PhoneInput value={phone} onChange={setPhone} label="Delivery contact phone" />
			<TextInput
				label="Customer purchase order #"
				value={customerPO}
				onChange={setCustomerPO}
				maxLength={30}
			/>
			<Textarea
				label="Invoice comments (visible to customer)"
				value={invoiceComment}
				onChange={setInvoiceComment}
				maxLength={320}
			/>
			<Textarea
				label="Internal comments (not visible to customer)"
				value={internalComment}
				onChange={setInternalComment}
				maxLength={500}
			/>
			<Box d="flex" gap={1} alignItems="center">
				<OrderShipMethodSelector
					value={shipMethodId}
					onChange={setShipMethodId}
					showNoSelectionOption
					disableNoSelectionOption={false}
				/>
				{!isEnumMember(shipMethodId, ShippingMethod) &&
					shipMethodId === order.shipMethod.id && (
						<Paragraph small secondary>
							Alternate ship method selected:
							<br />
							{order.shipMethod.description}
						</Paragraph>
					)}
			</Box>
			<Checkbox label="Machine down" value={isMachineDown} onChange={setIsMachineDown} />
			<Checkbox label="Ship complete" value={isShipComplete} onChange={setIsShipComplete} />
			{canEditHold && (
				<>
					{/* Let user edit the date if it's already there, but if it's not then the
				place to add it is in the Place On Hold modal, not here */}
					{(orderIsOnHold || !!order.targetDeliveryDate) && (
						<DateInput
							label="Target delivery date"
							value={targetDeliveryDate}
							onChange={setTargetDeliveryDate}
							clearable
						/>
					)}
					{orderIsOnHold && (
						<UserSelector
							value={holder}
							onChange={setHolder}
							initialId={order.holders ? order.holders[0]?.userId : undefined}
							label="Who can remove this order's hold status?"
						/>
					)}
				</>
			)}
		</Modal>
	)
}

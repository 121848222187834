import { Dispatch, ReactElement, SetStateAction, useMemo } from "react"

import { usePartCategories } from "@ncs/ncs-api"

import { PartCategorySelector } from "../selectors"

export interface PartCategoryParamState {
	category: string | null
}

export interface PartCategoryQueryFilterProps<QueryParamState extends PartCategoryParamState> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

export const PartCategoryQueryFilter = <QueryParamState extends PartCategoryParamState>({
	queryParamState,
	setQueryParamState,
}: PartCategoryQueryFilterProps<QueryParamState>): ReactElement => {
	const [categories] = usePartCategories()

	const value = useMemo(() => {
		const category = (categories ?? []).find(
			(c) => c.id.toString() === queryParamState.category
		)

		return category ?? null
	}, [categories, queryParamState.category])

	return (
		<PartCategorySelector
			label="Product category"
			value={value}
			fillContainer
			disableNoSelectionOption={false}
			onChange={(newValue) =>
				setQueryParamState((prev) => ({
					...prev,
					category: newValue?.id.toString() ?? null,
				}))
			}
		/>
	)
}

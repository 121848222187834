import React, { useState } from "react"
import PropTypes from "prop-types"
import { WizardCombinedState } from "./Wizard"

export type WizardContext<
	WizardCustomState extends Record<string, unknown> = Record<string, unknown>,
> = [
	WizardCombinedState<WizardCustomState>,
	React.Dispatch<React.SetStateAction<WizardCombinedState<WizardCustomState>>>,
]

const WizardStateContext = React.createContext<WizardContext | undefined>(undefined)

const WizardContextProvider = ({
	extraContextState,
	children,
}: {
	extraContextState: Record<string, unknown>
	children: React.ReactNode
}) => {
	// Combine the passed in extra state with the Wizard's built-in state.
	const wizardState = useState({
		...extraContextState,
		currentStep: 0,
	})

	return (
		<WizardStateContext.Provider value={wizardState}>{children}</WizardStateContext.Provider>
	)
}

WizardContextProvider.propTypes = {
	extraContextState: PropTypes.object.isRequired,
}

export default WizardContextProvider

export const useWizardContext = <
	WizardCustomState extends Record<string, unknown> = Record<string, unknown>,
>(): WizardContext<WizardCustomState> => {
	const context = React.useContext(WizardStateContext)

	if (context === undefined) {
		throw new Error("useWizardContext must be used within the Wizard component")
	}

	return context as WizardContext<WizardCustomState>
}

import React from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import callApi from "@ncs/bricks/redux/services/callApi"
import { withRouter } from "react-router-dom"
import { fetchData } from "@ncs/bricks/redux/services/dynamicTables"
import {
	getTechnicianAssignment,
	getChemicalTechnicianAssignment,
} from "@ncs/mortar/redux/services/users"
import { listCustomers } from "@ncs/mortar/redux/services/customers"
import { REPORT_FILTERS } from "@ncs/mortar/util/constants"

// custom components
import {
	DynamicTable,
	GridContainer,
	GridItem,
	NavPills,
	QuickCard,
	ReportFilters,
} from "~/components"

// icons
import Person from "@material-ui/icons/Person"

// local components
import TechAssignmentAddContainer from "./TechAssignmentAddContainer"
import ChemicalTechAssignmentAddContainer from "./ChemicalTechAssignmentAddContainer"
import TechAssignmentChangeContainer from "./TechAssignmentChangeContainer"

import withStyles from "@material-ui/core/styles/withStyles"
const styles = {
	right: {
		textAlign: "right",
	},
}

// constants
const reduxKey = "technicianAssignments"
const custReduxKey = "customersTechAssignments"
const chemTechReduxKey = "chemicalTechAssignments"

const techColumns = [
	{
		Header: "Description",
		accessor: "description",
		sortable: true,
		toggleable: false,
	},
	{
		Header: "Default Tech",
		accessor: "default_tech.name",
		sortable: true,
		toggleable: true,
	},
	{
		Header: "Alternate Tech",
		accessor: "alternate.name",
		sortable: true,
		toggleable: true,
	},
	{
		Header: "Alternate Active",
		accessor: (x) => (x.default_not_available ? "True" : "False"),
		id: "default_not_available",
		sortable: true,
		toggleable: true,
	},
	{
		Header: "Territory",
		accessor: (x) => `(${x.territory.code}) ${x.territory.description}`,
		id: "territory",
		sortable: true,
		toggleable: true,
		hiddenByDefault: true,
	},
]
const custColumns = [
	{
		Header: "Customer Name",
		accessor: "name",
		sortable: true,
		toggleable: false,
	},
	{
		Header: "Customer Number",
		accessor: "customer_number",
		sortable: true,
		toggleable: false,
	},
	{
		Header: "Address 2",
		accessor: "address_2",
		sortable: true,
		hiddenByDefault: true,
	},
	{
		Header: "City",
		accessor: "city",
		sortable: true,
		toggleable: true,
		hiddenByDefault: false,
	},
	{
		Header: "State",
		accessor: "state",
		sortable: true,
		toggleable: true,
		hiddenByDefault: false,
	},
	{
		Header: "Postal Code",
		accessor: "postalcode",
		sortable: true,
		hiddenByDefault: true,
	},
]

class TechAssignmentGroups extends React.Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	resetState = () => {
		this.setState({})
	}

	handleAssignmentSelected = (techAssignment) => {
		this.props.history.push(`/technician-assignment/${techAssignment._id}`)
	}

	handleChemicalTechSelected = (chemTechAssignment) => {
		this.props.history.push(`/chemical-technician-assignment/${chemTechAssignment._id}`)
	}

	refreshCustomers = () => {
		this.props.fetchCustomers(custReduxKey)
	}

	listCustomers = (query) =>
		listCustomers({
			no_default_tech: true,
			service: true,
			is_fst_active: true,
			...query,
		})
	getTechnicianAssignment = (query) =>
		getTechnicianAssignment({
			active: true,
			...query,
		})
	getChemicalTechnicianAssignment = (query) =>
		getChemicalTechnicianAssignment({
			active: true,
			...query,
		})
	render() {
		const { classes } = this.props

		return (
			<QuickCard title="Tech Assignment" icon={<Person />}>
				<ReportFilters
					config={{
						reduxKeys: [reduxKey, custReduxKey, chemTechReduxKey],
						filters: [
							REPORT_FILTERS.Search,
							REPORT_FILTERS.Territory,
							REPORT_FILTERS.Customer,
						],
					}}
				/>

				<NavPills
					color="warning"
					alignCenter
					active={this.state.activeTabIndex}
					onTabChange={this.handleTabChange}
					tabs={[
						{
							tabButton: "Tech Assignments",
							tabIcon: Person,
							tabContent: (
								<QuickCard title="Tech Assignments" icon={<Person />}>
									<GridContainer>
										<GridItem xs={12} className={classes.right}>
											<TechAssignmentAddContainer />
										</GridItem>
									</GridContainer>
									<DynamicTable
										reduxKey={reduxKey}
										fetchDataAction={this.getTechnicianAssignment}
										columns={techColumns}
										showResetFiltersButton={true}
										defaultPageSize={10}
										onRowClick={this.handleAssignmentSelected}
									/>
								</QuickCard>
							),
						},
						{
							tabButton: "Unassigned Customers",
							tabIcon: Person,
							tabContent: (
								<QuickCard title="Unassigned Customers" icon={<Person />}>
									<DynamicTable // TODO: make props static
										reduxKey={custReduxKey}
										fetchDataAction={this.listCustomers}
										columns={[
											...custColumns,
											{
												Header: "",
												accessor: (x) => (
													<TechAssignmentChangeContainer
														territory={x.territory._id}
														customer={x._id}
														onSave={this.refreshCustomers}
													/>
												),
												id: "actions",
												sortable: false,
												toggleable: false,
												maxWidth: 85,
											},
										]}
										showResetFiltersButton={true}
										defaultPageSize={10}
									/>
								</QuickCard>
							),
						},
						{
							tabButton: "Chemical Tech Assignments",
							tabIcon: Person,
							tabContent: (
								<QuickCard title="Chemical Tech Assignments" icon={<Person />}>
									<GridContainer>
										<GridItem xs={12} className={classes.right}>
											<ChemicalTechAssignmentAddContainer />
										</GridItem>
									</GridContainer>
									<DynamicTable
										reduxKey={chemTechReduxKey}
										fetchDataAction={this.getChemicalTechnicianAssignment}
										columns={techColumns}
										showResetFiltersButton={true}
										defaultPageSize={10}
										onRowClick={this.handleChemicalTechSelected}
									/>
								</QuickCard>
							),
						},
					]}
				/>
			</QuickCard>
		)
	}
}

const mapStateToProps = (state) => {
	return {}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			callApi,
			fetchData: fetchData(reduxKey),
			fetchCustomers: fetchData(custReduxKey),
		},
		dispatch
	)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(withStyles(styles)(TechAssignmentGroups)))

import {
	APPLICATION,
	GenericLineItem,
	LineItemType,
	PurchaseOrder,
	PurchaseOrderLineItemMin,
	PurchaseOrderLineItemPatch,
	PurchaseOrderLineItemPost,
	PurchaseOrderStatusId,
	useUserCanUse,
} from "@ncs/ncs-api"
import { extractNumber } from "@ncs/ts-utils"

/** Map `GenericLineItem` to `PurchaseOrderLineItemPatch`. */
export const lineItemToPurchaseOrderLineItemPatch = (
	line: GenericLineItem
): PurchaseOrderLineItemPatch => {
	const unitTax = line.finalPrice * line.taxRate

	if (!line.part) {
		throw new Error("Your purchase order line item has no part!")
	}

	const result: PurchaseOrderLineItemPatch = {
		partId: line.part.id,
		quantity: line.quantity,
		unitPrice: line.finalPrice.toFixed(4),
		unitTax: unitTax.toFixed(7),
		taxRateUsed: line.taxRate.toFixed(7),
		lineTotal: ((line.finalPrice + unitTax) * line.quantity).toFixed(7),
	}

	return result
}

/** Map `GenericLineItem` to `PurchaseOrderLineItemPost`. */
export const lineItemToPurchaseOrderLineItemPost = (
	line: GenericLineItem,
	purchaseOrderId: number
): PurchaseOrderLineItemPost => {
	const linePost = lineItemToPurchaseOrderLineItemPatch(line)

	return {
		purchaseOrderId: String(purchaseOrderId),
		...linePost,
	}
}

/** Map `PurchaseOrderLineItemMin` to `GenericLineItem`. */
export const purchaseOrderLineItemToLineItem = (
	lineItem: PurchaseOrderLineItemMin
): GenericLineItem => {
	const quantity = extractNumber(lineItem.quantity)
	const finalPrice = extractNumber(lineItem.unitPrice)
	const unitTax = extractNumber(lineItem.unitTax)
	const taxRate = finalPrice !== 0 ? unitTax / finalPrice : 0

	const genericLineItem: GenericLineItem = {
		id: String(lineItem.id),
		lineTypeId: LineItemType.Parts,
		part:
			lineItem.part ?
				{
					id: lineItem.part.id,
					partNumber: lineItem.part.partNumber,
					partFamily: lineItem.part.partFamily,
					description: lineItem.part.description,
					weight:
						lineItem.part.weight != null ? extractNumber(lineItem.part.weight) : null,
				}
			:	null,
		description: lineItem.part?.description ?? "",
		quantity,
		finalPrice,
		basePrice: null,
		taxRate,
		subtotal: quantity * finalPrice,
		priceOverrideReason: null,
		reasonComment: null,
		overridePermission: null,
		requestedPrice: null,
		systemGeneratedLine: false,
		originalSystemGeneratedPrice: null,
	}

	return genericLineItem
}

export const usePurchaseOrderPermissions = (
	purchaseOrder: PurchaseOrder | null | undefined
): {
	vendorIsGrimes: boolean
	canMakeInProgress: boolean
	canFinalize: boolean
	canForce: boolean
	canEdit: boolean
	canReceive: boolean
	canReopen: boolean
} => {
	const isRdcManager = useUserCanUse(APPLICATION.RDCManager)

	// Make it easier to check status ID arrays.
	const orderStatusId = purchaseOrder?.status?.id ?? (0 as PurchaseOrderStatusId)

	const vendorIsGrimes = purchaseOrder?.vendor?.id === "1"

	const canMakeInProgress =
		!vendorIsGrimes && [PurchaseOrderStatusId.Open].includes(orderStatusId)

	const canFinalize = [PurchaseOrderStatusId.Open, PurchaseOrderStatusId.InProgress].includes(
		orderStatusId
	)

	const canForce = [PurchaseOrderStatusId.Ordered].includes(orderStatusId)

	const canEdit = [
		PurchaseOrderStatusId.Open,
		PurchaseOrderStatusId.InProgress,
		PurchaseOrderStatusId.Ordered,
	].includes(orderStatusId)

	const canReceive =
		!canEdit &&
		![PurchaseOrderStatusId.Closed, PurchaseOrderStatusId.Canceled].includes(orderStatusId)

	const canReopen = isRdcManager && orderStatusId === PurchaseOrderStatusId.Closed

	return {
		vendorIsGrimes,
		canMakeInProgress,
		canFinalize,
		canForce,
		canEdit,
		canReceive,
		canReopen,
	}
}

import React, { Component } from "react"

import withStyles from "@material-ui/core/styles/withStyles"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { bindActionCreators, compose } from "redux"

import { listCustomerEquipment } from "@ncs/mortar/redux/services/customers"
import { loadLookups, LOOKUPS } from "@ncs/mortar/redux/services/lookups"
import { createTicket, deleteDocument, patchDocument } from "@ncs/mortar/redux/services/ticketing"
import { APPLICATIONS, TICKET_GROUPS } from "@ncs/mortar/util/constants"
import ExcelIcon from "@ncs/bricks/assets/img/ExcelIcon.svg"
import PDFIcon from "@ncs/bricks/assets/img/PDFIcon.svg"
import WordIcon from "@ncs/bricks/assets/img/WordIcon.svg"
import { meetsAppRestriction } from "@ncs/bricks/redux/selectors/auth"
import callApi from "@ncs/bricks/redux/services/callApi"
import FormValidator from "@ncs/bricks/util/formValidator"

import { ButtonWithIcon, GridContainer, GridItem, QuickCard, withCallApi } from "~/components"

import CreateTicket from "./CreateTicket"

import AddIcon from "@material-ui/icons/Add"
import AttachFile from "@material-ui/icons/AttachFile"
import ChevronLeft from "@material-ui/icons/ChevronLeft"
import Videocam from "@material-ui/icons/Videocam"

const styles = {
	right: {
		textAlign: "right",
	},
	center: {
		textAlign: "center",
	},
	previewIcon: {
		width: 75,
		height: 75,
	},
	previewImage: {
		maxWidth: "75px",
		maxHeight: "100px",
		height: "auto",
		width: "auto",
	},
}

class CreateTicketContainer extends Component {
	constructor(props) {
		super(props)

		this.validations = {
			description: {
				stateName: "description",
				isRequired: true,
				maxLength: 1000,
			},
			initial_comment: {
				stateName: "comment",
				isRequired: true,
				maxLength: 5000,
			},
			is_urgent: {
				stateName: "isUrgent",
				type: "bool",
			},
			ticket_requestor_name: {
				stateName: "requestorText",
				isRequired: false,
				maxLength: 255,
				minLength: 1,
			},
		}

		this.formValidator = new FormValidator(this, this.validations)

		this.state = {
			selectedTicketType: null,
			selectedTicketSubtype: null,
			selectedTicketPriority: null,
			selectedTicketGroup:
				this.props.isServiceUser() ?
					this.props.isTicketingAdmin() ?
						null
					:	TICKET_GROUPS.TECH_SUPPORT
				:	null,
			selectedAssignedTo: null,
			selectedTicketTech: null,
			isSaving: false,
			selectedTicketTypeIsValid: null,
			selectedTicketTypeErrorMessage: null,
			valid: false,
			success: null,
			newTicketMessage: null,
			error: null,
			documents: [],
			isConfirmingDelete: false,
			deletingDocument: null,
			selectedCustomer: null,
			customerEquipment: [],
			selectedCustomerEquipment: null,
			selectedTicketRequestor: null,
			...this.formValidator.getInitialFormState(),
		}
	}

	resetState = () => {
		this.setState({
			...this.state,
			selectedTicketType: null,
			selectedTicketSubtype: null,
			selectedTicketPriority: null,
			selectedAssignedTo: null,
			selectedTicketTech: null,
			isSaving: false,
			selectedTicketTypeIsValid: null,
			selectedTicketTypeErrorMessage: null,
			valid: false,
			...this.formValidator.getInitialFormState(),
		})
	}

	componentDidMount = () => {
		this.props.loadLookups([
			LOOKUPS.TicketTypes,
			LOOKUPS.TicketSubtypes,
			LOOKUPS.TicketPriorities,
		])
	}

	validateSelects = () => {
		const { selectedTicketType } = this.state
		let valid = true
		if (!selectedTicketType) {
			valid = false
		}
		this.setState({ valid: valid })
	}

	handleTicketTypeChange = (selectedTicketType) => {
		this.setState({ selectedTicketType }, () => this.validateSelects())
	}

	handleTicketPriorityChange = (selectedTicketPriority) => {
		this.setState({ selectedTicketPriority }, () => this.validateSelects())
		this.validateSelects()
	}

	handleTicketSubtypeChange = (selectedTicketSubtype) => {
		this.setState({ selectedTicketSubtype }, () => this.validateSelects())
	}
	handleTicketGroupChange = (selectedTicketGroup) => {
		this.setState({ selectedTicketGroup }, () => this.validateSelects())
	}

	handleTicketSave = () => {
		if (this.validateSelects() || !this.formValidator.dataIsValid()) {
			this.setState({ showValidationErrors: true })
			return
		}

		this.setState({ isSaving: true })
		let ticket = {
			priority_id: this.state.selectedTicketPriority,
			ticket_type_id: this.state.selectedTicketType,
			subtype_id: this.state.selectedTicketSubtype,
			status_id: 1,
			assigned_to_id:
				this.state.selectedAssignedTo === null ? null : this.state.selectedAssignedTo._id,
			customer_id: this.state.selectedCustomer ? this.state.selectedCustomer._id : null,
			customer_equipment_id: this.state.selectedCustomerEquipment,
			tech_id:
				this.state.selectedTicketTech === null ? null : this.state.selectedTicketTech._id,
		}
		for (const [prop, config] of Object.entries(this.validations)) {
			ticket[prop] = this.state[config.stateName]
		}
		this.props.callApi(createTicket(ticket)).then((result) => {
			if (result.error) {
				return this.setState({
					error: true,
					newTicketMessage: "Create failed. Try again, if problem persists contact IT.",
				})
			} else {
				this.setState({
					success: true,
					newTicketMessage: "Ticket Created.",
				})
				this.state.documents.map((document) =>
					this.props.callApi(
						patchDocument(document.id, { ticket_id: result.payload.id })
					)
				)
				return this.props.history.push(`/ticketing/detail/${result.payload.id}`)
			}
		})
	}
	handleTicketAssignedToChange = (selectedAssignedTo) => {
		this.setState({ selectedAssignedTo })
	}

	handleSetInputRef = (input) => {
		this.input = input
	}

	handleBackToList = () => {
		this.props.history.goBack()
	}

	handleDocumentUploaded = (file) => {
		this.setState({ documents: [file, ...this.state.documents] })
	}

	handleDeleteDocument = (deletingDocument) => {
		this.setState({ isConfirmingDelete: true, deletingDocument })
	}

	handleCancelDelete = () => {
		this.setState({
			isSaving: false,
			isConfirmingDelete: false,
			deletingDocument: null,
		})
	}

	handleConfirmDelete = (confirmationCallback) => {
		if (!this.state.deletingDocument || !this.state.deletingDocument.id) {
			return
		}

		this.setState({ isSaving: true })
		this.props.callApi(deleteDocument(this.state.deletingDocument.id)).then(() => {
			if (typeof confirmationCallback === "function") {
				confirmationCallback()
			}
			// refresh data after deleting
			let filtered = this.state.documents.filter(
				(x) => x.id === this.state.deletingDocument.id
			)
			this.state.documents.pop(filtered)
			this.handleCancelDelete()
		})
	}
	generatePreview = (item) => {
		let name = (item.document_type_name || "").toLowerCase()
		if (name === "image") {
			return (
				<img
					src={item.presign_url}
					title={`${item.filename}.${item.extension}`}
					alt={`${item.filename}.${item.extension}`}
					className={this.props.classes.previewImage}
				/>
			)
		}

		if (name === "video") {
			return <Videocam className={this.props.classes.previewIcon} />
		}

		let extension = (item.extension || "").toLowerCase()
		switch (extension) {
			case "xls":
			case "xlsx":
				return <img height={75} src={ExcelIcon} alt="Excel Document" />
			case "docx":
			case "doc":
				return <img height={75} src={WordIcon} alt="Word Document" />
			case "pdf":
				return <img height={75} src={PDFIcon} alt="PDF Document" />
			default:
				return <AttachFile className={this.props.classes.previewIcon} />
		}
	}
	handleCustomerSelected = (selectedCustomer) => {
		this.setState({ selectedCustomer }, () => {
			if (selectedCustomer !== null) {
				this.props
					.callApi(listCustomerEquipment(selectedCustomer._id))
					.then((result) => this.setState({ customerEquipment: result.payload }))
			} else {
				this.setState({ customerEquipment: [] })
			}
		})
	}
	handleCustomerEquipmentChange = (selectedCustomerEquipment) => {
		this.setState({ selectedCustomerEquipment })
	}
	handleTicketTechSelected = (selectedTicketTech) => {
		this.setState({ selectedTicketTech })
	}
	handleRequestorTextChange = (requestorText) => {
		this.setState({ requestorText })
	}
	handleRequestorTypeChange = (selectedRequestor) => {
		if (selectedRequestor === 1) {
			this.setState({ selectedRequestor, ticket_requestor_name: null, requestorText: "" })
		} else {
			this.setState({ selectedRequestor, selectedTech: null })
		}
	}
	handleSelectedTicketRequestorChange = (selectedTicketRequestor) => {
		this.setState({ selectedTicketRequestor, requestorText: "" })
	}

	render() {
		const { ticketTypes, ticketSubtypes, ticketPriorities } = this.props
		const {
			selectedTicketPriority,
			selectedTicketSubtype,
			selectedTicketType,
			selectedAssignedTo,
			isSaving,
			selectedTicketTypeIsValid,
			selectedTicketTypeErrorMessage,
			valid,
			success,
			newTicketMessage,
			error,
		} = this.state
		const isAdmin = this.props.isTicketingAdmin()
		const isServiceUser = this.props.isServiceUser()
		return (
			<React.Fragment>
				<ButtonWithIcon
					content="Back to List"
					onClick={this.handleBackToList}
					size="sm"
					color="white"
					round
					icon={<ChevronLeft />}
				/>
				<QuickCard title="Create Ticket" icon={<AddIcon />}>
					<GridContainer>
						<GridItem lg={12}>
							<CreateTicket
								setInputRef={this.handleSetInputRef}
								ticketTypes={ticketTypes}
								selectedTicketTypeIsValid={selectedTicketTypeIsValid}
								selectedTicketTypeErrorMessage={selectedTicketTypeErrorMessage}
								ticketSubtypes={ticketSubtypes}
								ticketPriorities={ticketPriorities}
								selectedTicketGroup={this.state.selectedTicketGroup}
								onTicketGroupChange={this.handleTicketGroupChange}
								onTicketTypeChange={this.handleTicketTypeChange}
								onTicketSubtypeChange={this.handleTicketSubtypeChange}
								onTicketPriorityChange={this.handleTicketPriorityChange}
								selectedTicketType={selectedTicketType}
								selectedTicketSubtype={selectedTicketSubtype}
								selectedTicketPriority={selectedTicketPriority}
								isSaving={isSaving}
								onSaveTicket={this.handleTicketSave}
								valid={valid}
								success={success}
								error={error}
								isAdmin={isAdmin}
								isServiceUser={isServiceUser}
								newTicketMessage={newTicketMessage}
								onTicketAssignedToChange={this.handleTicketAssignedToChange}
								selectedAssignedTo={selectedAssignedTo}
								onDocumentUploaded={this.handleDocumentUploaded}
								documents={this.state.documents}
								onDeleteDocument={this.handleDeleteDocument}
								onCancelDelete={this.handleCancelDelete}
								isConfirmingDelete={this.state.isConfirmingDelete}
								generatePreview={this.generatePreview}
								deletingDocument={this.state.deletingDocument}
								onConfirmDelete={this.handleConfirmDelete}
								onCustomerSelected={this.handleCustomerSelected}
								selectedCustomer={this.state.selectedCustomer}
								customerEquipment={this.state.customerEquipment}
								selectedCustomerEquipment={this.state.selectedCustomerEquipment}
								onCustomerEquipmentChange={this.handleCustomerEquipmentChange}
								onTicketTechChange={this.handleTicketTechSelected}
								selectedRequestor={this.state.selectedRequestor}
								onRequestorTypeChange={this.handleRequestorTypeChange}
								onRequestorTextChange={this.handleRequestorTextChange}
								onSelectedTicketRequestorChange={
									this.handleSelectedTicketRequestorChange
								}
								requestorText={this.state.requestorText}
								selectedTicketRequestor={this.state.selectedTicketRequestor}
								{...this.formValidator.generateFormProps()}
							/>
						</GridItem>
					</GridContainer>
				</QuickCard>
			</React.Fragment>
		)
	}
}
const mapStateToProps = (state) => {
	return {
		isTicketingAdmin: () => {
			return meetsAppRestriction(state.auth, APPLICATIONS.TicketingAdmin)
		},
		isServiceUser: () => {
			return meetsAppRestriction(state.auth, APPLICATIONS.TicketingTechSupportUser)
		},
		...state.lookups,
	}
}

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ callApi, loadLookups }, dispatch),
})

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
	withCallApi,
	withRouter
)(CreateTicketContainer)

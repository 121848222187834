import React, { Component } from "react"

import withStyles from "@material-ui/core/styles/withStyles"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { bindActionCreators, compose } from "redux"

import { listCustomerEquipment } from "@ncs/mortar/redux/services/customers"
import { loadLookups } from "@ncs/mortar/redux/services/lookups"
import {
	createTicket,
	deleteDocument,
	getTicket,
	getTickets,
	patchDocument,
} from "@ncs/mortar/redux/services/ticketing"
import { APPLICATIONS } from "@ncs/mortar/util/constants"
import ExcelIcon from "@ncs/bricks/assets/img/ExcelIcon.svg"
import PDFIcon from "@ncs/bricks/assets/img/PDFIcon.svg"
import WordIcon from "@ncs/bricks/assets/img/WordIcon.svg"
import { meetsAppRestriction } from "@ncs/bricks/redux/selectors/auth"
import callApi from "@ncs/bricks/redux/services/callApi"
import FormValidator from "@ncs/bricks/util/formValidator"

import { withCallApi } from "~/components"

import TechSupportTicket from "./TechSupportTicket"

import AttachFile from "@material-ui/icons/AttachFile"
import Videocam from "@material-ui/icons/Videocam"

const styles = {
	right: {
		textAlign: "right",
	},
	center: {
		textAlign: "center",
	},
	previewIcon: {
		width: 75,
		height: 75,
	},
	previewImage: {
		maxWidth: "75px",
		maxHeight: "100px",
		height: "auto",
		width: "auto",
	},
}

const reduxKey = "techSupportTicketReduxKey"

class TechSupportTicketContainer extends Component {
	constructor(props) {
		super(props)

		this.validations = {
			description: {
				stateName: "description",
				isRequired: true,
				maxLength: 1000,
			},
			initial_comment: {
				stateName: "comment",
				isRequired: true,
				maxLength: 5000,
			},
			is_urgent: {
				stateName: "isUrgent",
				type: "bool",
			},
			ticket_requestor_name: {
				stateName: "requestorText",
				isRequired: false,
				maxLength: 255,
				minLength: 1,
			},
		}

		this.formValidator = new FormValidator(this, this.validations)

		this.state = {
			selectedCustomer: null,
			error: null,
			selectedTicketType: null,
			selectedTicketSubtype: null,
			selectedTicketPriority: null,
			selectedAssignedTo: null,
			selectedTicketTech: null,
			isSaving: false,
			selectedTicketTypeIsValid: null,
			selectedTicketTypeErrorMessage: null,
			valid: false,
			success: null,
			newTicketMessage: null,
			documents: [],
			isConfirmingDelete: false,
			deletingDocument: null,
			customerEquipment: [],
			selectedCustomerEquipment: null,
			selectedTicketRequestor: null,
			selectedTicket: null,
			selectedComment: null,
			isAddingComment: false,
			...this.formValidator.getInitialFormState(),
		}
	}
	resetFormState = (param) => {
		this.setState({
			selectedTicketType: null,
			selectedTicketSubtype: null,
			selectedTicketPriority: null,
			selectedAssignedTo: null,
			selectedTicketTech: null,
			description: null,
			comment: null,
			isUrgent: false,
			isNuisance: false,
			selectedCustomerEquipment: null,
			selectedTicketRequestor: null,
		})
	}
	fetchTicket = (ticketId = null) => {
		this.setState({ isLoadingTicket: true })
		let id = ticketId
		if (!ticketId) {
			id = this.state.selectedTicket.id
		}
		this.props.callApi(getTicket(id)).then(({ payload: ticket }) => {
			this.setState({
				selectedTicket: ticket,
				isLoadingTicket: false,
			})
		})
	}
	getTickets = (params) =>
		getTickets({
			...params,
			customer: this.state.selectedCustomer,
			service: true,
		})
	validateSelects = () => {
		const { selectedTicketType } = this.state
		let valid = true
		if (!selectedTicketType) {
			valid = false
		}
		this.setState({ valid: valid })
	}
	handleTicketTypeChange = (selectedTicketType) => {
		this.setState({ selectedTicketType }, () => this.validateSelects())
	}
	handleTicketPriorityChange = (selectedTicketPriority) => {
		this.setState({ selectedTicketPriority }, () => this.validateSelects())
		this.validateSelects()
	}
	handleTicketSubtypeChange = (selectedTicketSubtype) => {
		this.setState({ selectedTicketSubtype }, () => this.validateSelects())
	}
	handleTicketSave = () => {
		if (this.validateSelects() || !this.formValidator.dataIsValid()) {
			this.setState({ showValidationErrors: true })
			return
		}

		this.setState({ isSaving: true })
		let ticket = {
			priority_id: this.state.selectedTicketPriority,
			ticket_type_id: this.state.selectedTicketType,
			subtype_id: this.state.selectedTicketSubtype,
			status_id: 1,
			assigned_to_id:
				this.state.selectedAssignedTo === null ? null : this.state.selectedAssignedTo._id,
			customer_id: this.state.selectedCustomer ? this.state.selectedCustomer._id : null,
			customer_equipment_id: this.state.selectedCustomerEquipment,
			tech_id:
				this.state.selectedTicketTech === null ? null : this.state.selectedTicketTech._id,
		}
		for (const [prop, config] of Object.entries(this.validations)) {
			ticket[prop] = this.state[config.stateName]
		}
		this.props.callApi(createTicket(ticket)).then((result) => {
			if (result.error) {
				return this.setState({
					error: true,
					newTicketMessage: "Create failed. Try again, if problem persists contact IT.",
				})
			} else {
				this.setState({
					isSaving: false,
				})
				this.state.documents.map((document) =>
					this.props.callApi(
						patchDocument(document.id, { ticket_id: result.payload.id })
					)
				)
				this.resetFormState()
				return this.fetchTicket(result.payload.id)
			}
		})
	}
	handleTicketAssignedToChange = (selectedAssignedTo) => {
		this.setState({ selectedAssignedTo })
	}
	handleSetInputRef = (input) => {
		this.input = input
	}
	handleDocumentUploaded = (file) => {
		this.setState({ documents: [file, ...this.state.documents] })
	}

	handleDeleteDocument = (deletingDocument) => {
		this.setState({ isConfirmingDelete: true, deletingDocument })
	}

	handleCancelDelete = () => {
		this.setState({
			isSaving: false,
			isConfirmingDelete: false,
			deletingDocument: null,
		})
	}
	handleConfirmDelete = (confirmationCallback) => {
		if (!this.state.deletingDocument || !this.state.deletingDocument.id) {
			return
		}

		this.setState({ isSaving: true })
		this.props.callApi(deleteDocument(this.state.deletingDocument.id)).then(() => {
			if (typeof confirmationCallback === "function") {
				confirmationCallback()
			}
			// refresh data after deleting
			let filtered = this.state.documents.filter(
				(x) => x.id === this.state.deletingDocument.id
			)
			this.state.documents.pop(filtered)
			this.handleCancelDelete()
		})
	}
	generatePreview = (item) => {
		let name = (item.document_type_name || "").toLowerCase()
		if (name === "image") {
			return (
				<img
					src={item.presign_url}
					title={`${item.filename}.${item.extension}`}
					alt={`${item.filename}.${item.extension}`}
					className={this.props.classes.previewImage}
				/>
			)
		}

		if (name === "video") {
			return <Videocam className={this.props.classes.previewIcon} />
		}

		let extension = (item.extension || "").toLowerCase()
		switch (extension) {
			case "xls":
			case "xlsx":
				return <img height={75} src={ExcelIcon} alt="Excel Document" />
			case "docx":
			case "doc":
				return <img height={75} src={WordIcon} alt="Word Document" />
			case "pdf":
				return <img height={75} src={PDFIcon} alt="PDF Document" />
			default:
				return <AttachFile className={this.props.classes.previewIcon} />
		}
	}
	handleCustomerSelected = (selectedCustomer) => {
		this.setState({ selectedCustomer }, () => {
			if (selectedCustomer !== null) {
				this.props
					.callApi(listCustomerEquipment(selectedCustomer._id))
					.then((result) => this.setState({ customerEquipment: result.payload }))
			} else {
				this.setState({ customerEquipment: [] })
			}
		})
	}
	handleCustomerEquipmentChange = (selectedCustomerEquipment) => {
		this.setState({ selectedCustomerEquipment })
	}
	handleTicketTechSelected = (selectedTicketTech) => {
		this.setState({ selectedTicketTech })
	}
	handleRequestorTextChange = (requestorText) => {
		this.setState({ requestorText })
	}
	handleRequestorTypeChange = (selectedRequestor) => {
		if (selectedRequestor === 1) {
			this.setState({ selectedRequestor, ticket_requestor_name: null, requestorText: "" })
		} else {
			this.setState({ selectedRequestor, selectedTech: null })
		}
	}
	handleSelectedTicketRequestorChange = (selectedTicketRequestor) => {
		this.setState({ selectedTicketRequestor, requestorText: "" })
	}
	handleTicketSelected = (selectedTicket) => {
		this.setState({ selectedTicket })
	}
	handleCommentSelected = (selectedComment) => {
		this.setState({ selectedComment })
	}
	handleCloseComment = () => {
		this.setState({ selectedComment: null })
	}
	handleAddComment = () => {
		this.setState({ isAddingComment: true })
	}
	handleCancelAddComment = () => {
		this.setState({ isAddingComment: false })
	}
	handleCommentCreated = () => {
		this.fetchTicket()
		this.setState({ isAddingComment: false })
	}
	render() {
		const { ticketTypes, ticketSubtypes, ticketPriorities } = this.props

		const {
			selectedTicketPriority,
			selectedTicketSubtype,
			selectedTicketType,
			selectedAssignedTo,
			isSaving,
			selectedTicketTypeIsValid,
			selectedTicketTypeErrorMessage,
			valid,
			success,
			newTicketMessage,
			error,
		} = this.state
		const isAdmin = this.props.isTicketingAdmin()

		return (
			<TechSupportTicket
				reduxKey={reduxKey}
				fetchDataAction={this.getTickets}
				setInputRef={this.handleSetInputRef}
				ticketTypes={ticketTypes}
				selectedTicketTypeIsValid={selectedTicketTypeIsValid}
				selectedTicketTypeErrorMessage={selectedTicketTypeErrorMessage}
				ticketSubtypes={ticketSubtypes}
				ticketPriorities={ticketPriorities}
				selectedTicketGroup={this.state.selectedTicketGroup}
				onTicketGroupChange={this.handleTicketGroupChange}
				onTicketTypeChange={this.handleTicketTypeChange}
				onTicketSubtypeChange={this.handleTicketSubtypeChange}
				onTicketPriorityChange={this.handleTicketPriorityChange}
				selectedTicketType={selectedTicketType}
				selectedTicketSubtype={selectedTicketSubtype}
				selectedTicketPriority={selectedTicketPriority}
				isSaving={isSaving}
				onSaveTicket={this.handleTicketSave}
				valid={valid}
				success={success}
				error={error}
				isAdmin={isAdmin}
				newTicketMessage={newTicketMessage}
				onTicketAssignedToChange={this.handleTicketAssignedToChange}
				selectedAssignedTo={selectedAssignedTo}
				onDocumentUploaded={this.handleDocumentUploaded}
				documents={this.state.documents}
				onSuccess={this.handleDocumentSuccess}
				onDeleteDocument={this.handleDeleteDocument}
				onCancelDelete={this.handleCancelDelete}
				isConfirmingDelete={this.state.isConfirmingDelete}
				generatePreview={this.generatePreview}
				deletingDocument={this.state.deletingDocument}
				onConfirmDelete={this.handleConfirmDelete}
				onCustomerSelected={this.handleCustomerSelected}
				selectedCustomer={this.state.selectedCustomer}
				customerEquipment={this.state.customerEquipment}
				selectedCustomerEquipment={this.state.selectedCustomerEquipment}
				onCustomerEquipmentChange={this.handleCustomerEquipmentChange}
				onTicketTechChange={this.handleTicketTechSelected}
				selectedRequestor={this.state.selectedRequestor}
				onRequestorTypeChange={this.handleRequestorTypeChange}
				onRequestorTextChange={this.handleRequestorTextChange}
				onSelectedTicketRequestorChange={this.handleSelectedTicketRequestorChange}
				requestorText={this.state.requestorText}
				selectedTicketRequestor={this.state.selectedTicketRequestor}
				onTicketSelected={this.handleTicketSelected}
				selectedTicket={this.state.selectedTicket}
				selectedComment={this.state.selectedComment}
				onCommentSelected={this.handleCommentSelected}
				onCommentClose={this.handleCloseComment}
				onAddComment={this.handleAddComment}
				onCancelAddComment={this.handleCancelAddComment}
				isAddingComment={this.state.isAddingComment}
				onCommentCreated={this.handleCommentCreated}
				isLoadingTicket={this.state.isLoadingTicket}
				{...this.formValidator.generateFormProps()}
			/>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		isTicketingAdmin: () => {
			return meetsAppRestriction(state.auth, APPLICATIONS.TicketingAdmin)
		},
		isServiceUser: () => {
			return meetsAppRestriction(state.auth, APPLICATIONS.TicketingTechSupportUser)
		},
		...state.lookups,
	}
}

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ callApi, loadLookups }, dispatch),
})

export default compose(
	withStyles(styles),
	connect(mapStateToProps, mapDispatchToProps),
	withCallApi,
	withRouter
)(TechSupportTicketContainer)

import React from "react"
import { BaseSelectFilter, withCallApi, withDynamicTableFilter } from "@ncs/bricks/components"
import { listOrganizationsForEnterprise } from "../../redux/services/lookups"

class OrganizationFilter extends React.PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			organizations: [],
		}
	}

	componentDidMount() {
		this.loadOrganizations()
	}

	// load Organizations whenever the enterprise changes
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (
			this.getEnterpriseFromReportState(prevProps) !==
			this.getEnterpriseFromReportState(this.props)
		) {
			this.loadOrganizations()
		}
	}

	getEnterpriseFromReportState = (props) => {
		return (
			((props.reportState || {}).filtered || []).find((x) => x.id === "enterprise") || {}
		).value
	}

	loadOrganizations = () => {
		let enterprise = this.getEnterpriseFromReportState(this.props)

		this.props
			.callApi(listOrganizationsForEnterprise(enterprise))
			.then(({ payload: organizations }) => {
				this.setState({ organizations })

				// if the currently selected organization is not in the results, erase the selected organization
				if (
					this.props.value &&
					!organizations.some((t) => t._id.toString() === this.props.value)
				) {
					this.props.onChange("")
				}
			})
	}

	render() {
		return (
			<BaseSelectFilter
				id="organization"
				labelText="Organization"
				options={[{ _id: "", name: "All Organizations" }, ...this.state.organizations]}
				valueAccessor="_id"
				textAccessor="name"
				{...this.props}
			/>
		)
	}
}

export default withCallApi(withDynamicTableFilter("organization")(OrganizationFilter))

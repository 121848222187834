import { FC } from "react"

import { Cell, Column } from "react-table"

import {
	MilestoneDetailReportLine,
	MilestoneDetailReportQueryParams,
	useExportMilestoneDetailReport,
	useMilestoneDetailReport,
} from "@ncs/ncs-api"
import {
	formatCurrency,
	formatDateTime,
	getStartAndEndDateQueryParams,
	getTimezoneAbbreviation,
	TimeAgo,
	Typify,
} from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	CustomerQueryFilter,
	Link,
	RegionQueryFilter,
	Table,
	TerritoryQueryFilter,
	TimeAgoQueryFilter,
	usePaginationUrlState,
	useUrlState,
} from "@ncs/web-legos"

export type MilestoneDetailReportUrlState = Typify<MilestoneDetailReportQueryParams>

export const MilestoneDetailReport: FC = () => {
	const [queryParams, { setUrlState: setQueryParams }] =
		useUrlState<MilestoneDetailReportUrlState>(initialMilestoneDetailReportUrlState)
	const [pagination, setPagination] = usePaginationUrlState()

	const query = useMilestoneDetailReport({
		params: { ...queryParams, ...pagination },
		manualPagination: true,
	})
	const dataExport = useExportMilestoneDetailReport(queryParams)

	return (
		<AnimatedEntrance show>
			<Table
				query={query}
				columns={columns}
				queryParamState={queryParams}
				setQueryParamState={setQueryParams}
				pagination={pagination}
				setPagination={setPagination}
				dataExport={dataExport}
				pinnedQueryFilters={[
					TimeAgoQueryFilter,
					CustomerQueryFilter,
					TerritoryQueryFilter,
				]}
				toggledQueryFilters={[RegionQueryFilter]}
				filterResetValues={initialMilestoneDetailReportUrlState}
				storeColumnVisibility="milestone-detail-report-columns"
			/>
		</AnimatedEntrance>
	)
}

const columns: Column<MilestoneDetailReportLine>[] = [
	{
		Header: "Customer",
		id: "customer_CustomerNumber",
		accessor: ({ customer }) => customer.customerNumber,
		Cell: ({
			row: {
				original: { customer },
			},
		}: Cell<MilestoneDetailReportLine>) => {
			return (
				<Link to={`/customer-management/${customer.id}`} icon="external-link" newTab>
					({customer.customerNumber}) {customer.name}
				</Link>
			)
		},
	},
	{
		Header: "Customer Equipment",
		disableSortBy: true,
		accessor: ({ customerEquipment: { serialNumber, model } }) => `(${serialNumber}) ${model}`,
	},
	{
		Header: `Identified on (${getTimezoneAbbreviation()})`,
		id: "identifiedOn",
		accessor: ({ identifiedOn }) => formatDateTime(identifiedOn),
	},
	{
		Header: "Status",
		disableSortBy: true,
		accessor: ({ status }) => status.description,
	},
	{
		Header: "Quote (ERP)",
		disableSortBy: true,
		hiddenByDefault: true,
		Cell: ({ row: { original } }: Cell<MilestoneDetailReportLine>) => {
			return original.milestoneErp ?
					<Link to={`/quotes/${original.milestoneErp.id}`} newTab icon="external-link">
						View quote
					</Link>
				:	"-"
		},
	},
	{
		Header: "Dispatch",
		id: "dispatch",
		disableSortBy: true,
		hiddenByDefault: true,
		Cell: ({ row: { original } }: Cell<MilestoneDetailReportLine>) => {
			return original.milestoneErp?.dispatch ?
					<Link
						newTab
						to={`/service/dispatches/${original.milestoneErp.dispatch.id}`}
						icon="external-link"
					>
						View dispatch
					</Link>
				:	"-"
		},
	},
	{
		Header: "Dispatch tech",
		hiddenByDefault: true,
		disableSortBy: true,
		accessor: ({ milestoneErp }) => milestoneErp?.dispatch?.assignedEmployee?.name || "-",
	},
	{
		Header: "Total",
		disableSortBy: true,
		hiddenByDefault: true,
		accessor: ({ milestoneErp }) => (milestoneErp ? formatCurrency(milestoneErp.total) : "-"),
	},
	{
		Header: "Converted",
		disableSortBy: true,
		hiddenByDefault: true,
		accessor: ({ converted }) => (converted ? "Yes" : "No"),
	},
	{
		Header: `Approved on (${getTimezoneAbbreviation()})`,
		disableSortBy: true,
		hiddenByDefault: true,
		accessor: ({ approvedOn }) => (approvedOn ? formatDateTime(approvedOn) : "-"),
	},
	{
		Header: "Approved by",
		disableSortBy: true,
		hiddenByDefault: true,
		accessor: ({ approvedBy }) => approvedBy?.name || "-",
	},
	{
		Header: `Rejected on (${getTimezoneAbbreviation()})`,
		disableSortBy: true,
		hiddenByDefault: true,
		accessor: ({ rejectedOn }) => (rejectedOn ? formatDateTime(rejectedOn) : "-"),
	},
	{
		Header: "Rejected by",
		disableSortBy: true,
		hiddenByDefault: true,
		accessor: ({ rejectedBy }) => rejectedBy?.name || "-",
	},
]

const initialMilestoneDetailReportUrlState: MilestoneDetailReportUrlState = {
	ordering: null,
	startDate: getStartAndEndDateQueryParams(TimeAgo.DaysAgo30)[0],
	endDate: null,
	territory: null,
	region: null,
	customer: null,
}

import React from "react"
import PropTypes from "prop-types"
import { CloudDownload, Launch, MailOutline, Schedule } from "@material-ui/icons"
import { withStyles } from "@material-ui/core"

import {
	ButtonWithIcon,
	Dialog,
	GridContainer,
	GridItem,
	Input,
	withCallApi,
} from "../../components"
import { setStateKeyFromEventTargetValue } from "../../util/state"

const styles = {
	columnChooser: {
		margin: "15px",
		userSelect: "none",
	},
	clickable: {
		cursor: "pointer",
	},
}

class ReportExporter extends React.PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			showExportModal: false,
			reportExport: null,
			isSaving: false,
			filename: null,
			emailOnCompletion: false,
		}
		this.patchTimer = null
		this.completionTimer = null
	}

	componentWillUnmount() {
		if (this.patchTimer) {
			clearTimeout(this.patchTimer)
			this.patchTimer = null
		}

		if (this.completionTimer) {
			clearTimeout(this.completionTimer)
			this.completionTimer = null
		}
	}

	handleExportReport = () => {
		let filename =
			typeof this.props.generateExportFilenameFn === "function" ?
				this.props.generateExportFilenameFn()
			:	null

		let query = { ...this.props.generateQueryFn(), name: filename || undefined }

		this.props
			.callApi(this.props.exportDataAction(query))
			.then(({ payload: reportExport }) => {
				this.setState({ reportExport })
				this.checkForReportCompletion()
			})

		this.setState({
			showExportModal: true,
			filename,
			defaultFilename: filename,
			emailOnCompletion: false,
		})
	}

	checkForReportCompletion = (iteration = 0) => {
		if (iteration > 0 && this.state.reportExport && this.state.reportExport.id) {
			this.props
				.callApi(this.props.getReportExport(this.state.reportExport.id))
				.then(({ payload: reportExport }) => {
					this.setState({ reportExport })

					// stop checking if the report is ready
					if (reportExport.is_ready) {
						clearTimeout(this.completionTimer)
						this.completionTimer = null
					}
				})
		}

		const intervals = [0, 1, 2, 3, 5, 8, 13, 20]
		let timeout = intervals[Math.min(intervals.length - 1, ++iteration)] * 1000
		this.completionTimer = setTimeout(() => this.checkForReportCompletion(iteration), timeout)
	}

	handleEmailMe = (confirmationCallback) => {
		let setStateCallback = () => {
			this.patchReportExport(() => {
				this.setState({ isSaving: false, showExportModal: false })
				typeof confirmationCallback === "function" && confirmationCallback()
			})
		}

		this.setState({ isSaving: true, emailOnCompletion: true }, setStateCallback)
	}

	handleWaitForDownload = () => {
		this.setState({ showExportModal: false })
		this.patchReportExport()
	}

	patchReportExport = (callback) => {
		// if the nothing has changed from the default, don't send the PATCH
		if (this.state.defaultFilename === this.state.filename && !this.state.emailOnCompletion) {
			typeof callback === "function" && callback()
		}

		// do we have an object to patch yet?
		if (!this.state.reportExport || !this.state.reportExport.id) {
			// delay the patch
			this.patchTimer = setTimeout(() => this.patchReportExport(callback), 200)
			return
		}

		// do the patch
		let data = { name: this.state.filename, email_on_completion: this.state.emailOnCompletion }
		this.props
			.callApi(this.props.patchReportExport(this.state.reportExport.id, data))
			.then(({ payload: reportExport }) => {
				this.setState({ reportExport })
				this.checkForReportCompletion()
				typeof callback === "function" && callback()
			})
	}

	handleDownloadFile = () => {
		this.setState({ isSaving: true })
		this.patchReportExport(() => {
			this.props
				.callApi(this.props.generateLink(this.state.reportExport.id))
				.then(({ payload: result }) => {
					window.open(result.presign_url)
					this.setState({ isSaving: false, showExportModal: false, reportExport: null })
				})
		})
	}

	handleCancelDownload = () => {
		this.setState({ reportExport: null })
	}

	render() {
		const { classes, exportDataAction } = this.props
		const { showExportModal, filename, isSaving, reportExport, emailOnCompletion } = this.state

		if (typeof exportDataAction !== "function") {
			return null
		}

		let reportIsReady = reportExport && reportExport.is_ready
		let isWaitingForDownload =
			!showExportModal &&
			!emailOnCompletion &&
			reportExport !== null &&
			typeof reportExport === "object" &&
			!reportExport.is_ready

		return (
			<React.Fragment>
				<ButtonWithIcon
					round
					simple
					onClick={this.handleExportReport}
					color="success"
					className="edit"
					icon={<Launch />}
					content="Export"
					loading={isWaitingForDownload}
					disabled={isWaitingForDownload}
				/>

				<Dialog
					warning="true"
					show={showExportModal}
					title="Exporting Report"
					confirmBtnIcon={<MailOutline />}
					confirmBtnText="Email Me"
					onConfirm={this.handleEmailMe}
					isConfirmationAsync={true}
					successTitle="Emailing Report"
					successContent={
						reportIsReady ?
							"Your generated report is on its way to your inbox."
						:	"You'll receive an email when the report has been generated."
					}
					cancelBtnIcon={reportIsReady ? <CloudDownload /> : <Schedule />}
					cancelBtnText={reportIsReady ? "Download Report" : "Wait for Download"}
					cancelBtnColor={reportIsReady ? "success" : "info"}
					onCancel={reportIsReady ? this.handleDownloadFile : this.handleWaitForDownload}
					isSaving={isSaving}
				>
					{showExportModal && (
						<GridContainer className={classes.leftAlignedModal}>
							<GridItem xs={12}>
								<Input
									labelText="Report Filename"
									inputProps={{
										autoFocus: true,
										multiline: true,
										onChange: setStateKeyFromEventTargetValue(
											this,
											"filename"
										),
										value: filename || "",
									}}
								/>
							</GridItem>
						</GridContainer>
					)}
				</Dialog>

				<Dialog
					title="Your report is ready for download."
					show={
						// only if ready after waiting for download
						!showExportModal && !!(reportExport || {}).is_ready && !emailOnCompletion
					}
					confirmBtnIcon={<CloudDownload />}
					confirmBtnText="Download Report"
					onConfirm={this.handleDownloadFile}
					onCancel={this.handleCancelDownload}
					isSaving={isSaving}
				/>
			</React.Fragment>
		)
	}
}

ReportExporter.propTypes = {
	/** function that initiates report export and returns a report export object */
	exportDataAction: PropTypes.func,

	/** function that returns a filename for the report as exported */
	generateExportFilenameFn: PropTypes.func,

	/** function that generates a download link for the report **/
	generateLink: PropTypes.func.isRequired,

	/** function that returns status of export job **/
	getReportExport: PropTypes.func.isRequired,

	/** updates an export job with new information (whether to email when completed, name of export, etc.) **/
	patchReportExport: PropTypes.func.isRequired,
}

export default withCallApi(withStyles(styles)(ReportExporter))

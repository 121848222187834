import { Dispatch, ReactElement, SetStateAction } from "react"

import { DispatchCallContactedSelector } from "../selectors"

interface DispatchCallContactedQueryParams {
	dispatchContactExists: string | null
}

export interface DispatchCallContactedQueryFilterProps<
	QueryParamState extends DispatchCallContactedQueryParams,
> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

export const DispatchCallContactedQueryFilter = <
	QueryParamState extends DispatchCallContactedQueryParams,
>({
	queryParamState,
	setQueryParamState,
}: DispatchCallContactedQueryFilterProps<QueryParamState>): ReactElement => {
	const handleChange = (newValue: string | null) => {
		setQueryParamState((prev) => ({
			...prev,
			dispatchContactExists: newValue,
		}))
	}

	return (
		<DispatchCallContactedSelector
			value={queryParamState.dispatchContactExists}
			onChange={handleChange}
			disableNoSelectionOption={false}
			noSelectionOptionText="Any contact status"
			fillContainer
		/>
	)
}

import React from "react"
import PropTypes from "prop-types"

import { default as NumericInput } from "../../CustomInput/CustomNumericInput"

import TextFormField from "./TextFormField"

class NumberFormField extends React.PureComponent {
	render = () => {
		const {
			errorMessage,
			isEditing,
			isReadOnly,
			isValid,
			labelText,
			onChange,
			showValidationErrors,
			value,
			...rest
		} = this.props

		return (
			<React.Fragment>
				<NumericInput
					labelText={labelText}
					value={value}
					onChange={onChange}
					inputProps={{ disabled: isReadOnly || !isEditing }}
					formControlProps={{
						error: showValidationErrors && !isValid,
					}}
					helperText={showValidationErrors ? errorMessage : ""}
					{...rest}
				/>
			</React.Fragment>
		)
	}
}

NumberFormField.defaultProps = {
	thousandSeparator: true,
	decimalScale: 2,
	allowNegative: false,
	fixedDecimalScale: false,
	displayType: "input",
}

NumberFormField.propTypes = {
	...NumericInput.propTypes,
	...TextFormField.propsTypes,

	/** current value of input */
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default NumberFormField

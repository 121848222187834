import { FC, useState } from "react"

import dayjs, { Dayjs } from "dayjs"

import {
	makeApiErrorMessage,
	PartOrder,
	PartOrderList,
	PickListStatus,
	usePartOrderPickLists,
	usePartOrderShipments,
	UserMinimal,
	useUpdatePartOrder,
} from "@ncs/ncs-api"
import {
	DateInput,
	ExtendableModalProps,
	Modal,
	Paragraph,
	useIsSaving,
	UserSelector,
	useToast,
} from "@ncs/web-legos"

import { usePartOrderPermissions } from "../../part-order-detail-util"

export interface PlaceHoldModalProps extends ExtendableModalProps {
	order: PartOrder
	orderListResult: PartOrderList[number]
}

export const PlaceHoldModal: FC<PlaceHoldModalProps> = ({ order, orderListResult, ...rest }) => {
	const { makeSuccessToast } = useToast()
	const [deliveryDate, setDeliveryDate] = useState<Dayjs | null>(
		order.targetDeliveryDate ? dayjs(order.targetDeliveryDate) : null
	)
	const [holder, setHolder] = useState<UserMinimal | null>(null)
	const { isSaving, setSaving, endSaving } = useIsSaving()
	const [errorText, setErrorText] = useState<string | null>(null)

	const [pickLists, pickListsLoading] = usePartOrderPickLists(order.id)
	const [shipments, shipmentsLoading] = usePartOrderShipments({
		params: {
			partOrder: order.id,
			allShipments: true,
		},
	})
	const { canEditHold, isOpen: orderInOpenStatus } = usePartOrderPermissions(order)
	const updateOrder = useUpdatePartOrder(order.id)

	const handleUpdate = async () => {
		try {
			if (!deliveryDate) {
				throw new Error("Target delivery date is required to put an order on hold")
			}

			setSaving()
			await updateOrder({
				id: order.id,
				updates: {
					putOnHold: true,
					targetDeliveryDate: deliveryDate.toISOString(),
					holders: holder ? [holder.id] : null,
				},
			})
			makeSuccessToast("Order status set to ON HOLD")
			rest.onClose()
		} catch (e) {
			endSaving()
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const hasPickLists = (pickLists ?? []).some(
		(l) => l.status.description !== PickListStatus.Removed
	)
	const hasShipments = !!shipments?.length
	const hasDropShipments = order.lineItems.some((line) => line.quantityDropShipped.length > 0)
	const canPutOnHold =
		orderInOpenStatus &&
		!pickListsLoading &&
		!hasPickLists &&
		!shipmentsLoading &&
		!hasShipments &&
		!hasDropShipments &&
		canEditHold

	return (
		<Modal
			{...rest}
			title="Place Order On Hold"
			rightButtons={{
				buttonText: "Place On Hold",
				disabled: !canPutOnHold,
				isLoading: isSaving(),
				onClick: handleUpdate,
			}}
			errorText={errorText}
		>
			{hasPickLists && (
				<Paragraph small secondary>
					Cannot put on hold an order that has pick lists.
				</Paragraph>
			)}
			{hasShipments && (
				<Paragraph small secondary>
					Cannot put on hold an order that has shipments.
				</Paragraph>
			)}
			{hasDropShipments && (
				<Paragraph small secondary>
					Cannot put on hold an order that has drop shipments.
				</Paragraph>
			)}
			{!canEditHold && (
				<Paragraph small secondary>
					You do not have the required permissions to put this order on hold.
				</Paragraph>
			)}
			{canPutOnHold && (
				<>
					<Paragraph mb={1}>
						Put this order into hold status for a future delivery? Until the hold is
						removed, the order cannot be progressed once put in this state. A person of
						your choice, as well as part order hold status admins, can remove the hold
						later.
					</Paragraph>
					<DateInput
						label="Target delivery date"
						value={deliveryDate}
						onChange={setDeliveryDate}
						clearable
					/>
					<UserSelector
						value={holder}
						onChange={setHolder}
						label="Who can remove this order's hold status?"
						initialId={order.holders ? order.holders[0]?.userId : undefined}
					/>
				</>
			)}
		</Modal>
	)
}

import { Dispatch, ReactElement, SetStateAction } from "react"

import { ContractTypeSelector } from "../selectors"

export interface ContractTypeParamState {
	type: string | null
}

export interface ContractTypeQueryFilterProps<QueryParamState extends ContractTypeParamState> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

export const ContractTypeQueryFilter = <QueryParamState extends ContractTypeParamState>({
	queryParamState,
	setQueryParamState,
}: ContractTypeQueryFilterProps<QueryParamState>): ReactElement => {
	return (
		<ContractTypeSelector
			value={queryParamState.type}
			disableNoSelectionOption={false}
			noSelectionOptionText="Any"
			fillContainer
			onChange={(newValue) =>
				setQueryParamState((prev) => {
					const newContractTypeParamState: ContractTypeParamState = {
						type: newValue,
					}

					return {
						...prev,
						...newContractTypeParamState,
					}
				})
			}
		/>
	)
}

import { css, SerializedStyles, Theme } from "@emotion/react"

export const globalStyles = (theme: Theme): SerializedStyles => css`
	html {
		scroll-behavior: smooth;
	}

	/* Typography */
	body {
		color: #373737;
		line-height: 1.4;
	}
	h1 {
		font-size: ${theme.typography.h1.fontSize};
		font-weight: ${theme.typography.h1.fontWeight};
		line-height: ${theme.typography.h1.lineHeight};
		letter-spacing: ${theme.typography.h1.letterSpacing};
	}
	h2 {
		font-size: ${theme.typography.h2.fontSize};
		font-weight: ${theme.typography.h2.fontWeight};
		line-height: ${theme.typography.h2.lineHeight};
		letter-spacing: ${theme.typography.h2.letterSpacing};
	}
	h3 {
		font-size: ${theme.typography.h3.fontSize};
		font-weight: ${theme.typography.h3.fontWeight};
		line-height: ${theme.typography.h3.lineHeight};
		letter-spacing: ${theme.typography.h3.letterSpacing};
	}
	h4 {
		font-size: ${theme.typography.h4.fontSize};
		font-weight: ${theme.typography.h4.fontWeight};
		line-height: ${theme.typography.h4.lineHeight};
		letter-spacing: ${theme.typography.h4.letterSpacing};
	}
	h5 {
		font-size: ${theme.typography.h5.fontSize};
		font-weight: ${theme.typography.h5.fontWeight};
		line-height: ${theme.typography.h5.lineHeight};
		letter-spacing: ${theme.typography.h5.letterSpacing};
		opacity: 0.85;
	}
	h6 {
		font-size: ${theme.typography.h6.fontSize};
		font-weight: ${theme.typography.h6.fontWeight};
		letter-spacing: ${theme.typography.h6.letterSpacing};
		text-transform: ${theme.typography.h6.textTransform};
		color: ${theme.palette.text.secondary};
	}
	strong {
		font-weight: bold; // Some browsers default strong to "bolder".
	}

	/* Forms */

	// Note that these do select the hidden HTML elements inside MUI's fancy elements.

	input,
	select,
	textarea,
	button {
		font-family: inherit;
		font-size: 1rem;
	}
	input,
	select,
	textarea,
	.multi-select {
		color: inherit;
		width: inherit;
		border: 1px solid ${theme.palette.grey[300]};
		transition-duration: ${theme.transitions.duration.shorter}ms;
		transition-timing-function: ${theme.transitions.easing.easeOut};
		transition-property: border-color;
		&:focus:not(:disabled),
		&:hover:not(:disabled) {
			outline: 0;
			border-color: ${theme.palette.primary.main};
		}
	}
	input,
	textarea {
		padding: 0.42rem 0.5rem;
		&::placeholder {
			opacity: 0.5;
		}
	}
	select {
		padding: 0.42rem 0.45rem 0.4rem 0.45rem;
		background: white;
		&:disabled {
			background: rgba(239, 239, 239, 0.3);
		}
	}
	textarea {
		resize: vertical;
	}
	button {
		cursor: pointer;
	}

	/* Tables */
	table {
		border-collapse: collapse;
		width: 100%;
	}

	/* Images */
	img {
		max-width: 100%;
		height: auto;
	}

	/* Animations */
	@keyframes move-to-position {
		to {
			opacity: 1;
			transform: unset;
		}
	}

	/* Override FontAwesome's default spin speed to be a bit more interesting. */
	.fa-spin {
		animation: fa-spin 0.75s infinite cubic-bezier(0.31, -0.1, 0, 1.01);
	}
`

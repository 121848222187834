import { FC, useMemo } from "react"

import dayjs from "dayjs"
import { Cell, Column } from "react-table"

import {
	DispatchCountsReportLine,
	DispatchCountsReportQueryParams,
	PortalReport,
	useDispatchCountsReport,
} from "@ncs/ncs-api"
import {
	DateFormat,
	formatNumber,
	getStartAndEndDateQueryParams,
	TimeAgo,
	Typify,
} from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	CustomerQueryFilter,
	EnterpriseQueryFilter,
	OrganizationQueryFilter,
	RegionQueryFilter,
	Table,
	TerritoryQueryFilter,
	TimeAgoQueryFilter,
	useUrlState,
} from "@ncs/web-legos"

export type DispatchCountsReportUrlState = Typify<DispatchCountsReportQueryParams>

export const DispatchCountsReport: FC = () => {
	const [queryParams, { setUrlState: setQueryParams }] =
		useUrlState<DispatchCountsReportUrlState>(initialDispatchCountsReportUrlState)

	const [data, dataLoading] = useDispatchCountsReport({ params: { ...queryParams } })

	const chartReport = useMemo(() => {
		if (data) {
			return makeBarChartReport(data)
		}

		return undefined
	}, [data])

	return (
		<AnimatedEntrance show>
			<Table
				data={data ?? []}
				columns={columns}
				isLoading={dataLoading}
				queryParamState={queryParams}
				setQueryParamState={setQueryParams}
				pinnedQueryFilters={[
					TimeAgoQueryFilter,
					CustomerQueryFilter,
					TerritoryQueryFilter,
					OrganizationQueryFilter,
				]}
				toggledQueryFilters={[RegionQueryFilter, EnterpriseQueryFilter]}
				filterResetValues={initialDispatchCountsReportUrlState}
				storeColumnVisibility="dispatch-counts-report-columns"
				defaultSort={[{ id: "Date", desc: true }]}
				barChartReport={chartReport}
			/>
		</AnimatedEntrance>
	)
}

const columns: Column<DispatchCountsReportLine>[] = [
	{
		Header: "Date",
		accessor: ({ month, year }) => dayjs(`${month}-01-${year}`).toISOString(),
		Cell: ({ row: { original } }: Cell<DispatchCountsReportLine>) => {
			return dayjs(`${original.month}-01-${original.year}`).format(DateFormat.MonthYear)
		},
	},
	{
		Header: "Opened",
		accessor: ({ open }) => formatNumber(open),
	},
	{
		Header: "Closed",
		accessor: ({ closed }) => formatNumber(closed),
	},
	{
		Header: "Completed",
		accessor: ({ completed }) => formatNumber(completed),
	},
	{
		Header: "Total",
		accessor: ({ total }) => formatNumber(total),
	},
]

const initialDispatchCountsReportUrlState: DispatchCountsReportUrlState = {
	organization: null,
	region: null,
	enterprise: null,
	territory: null,
	customer: null,
	startDate: getStartAndEndDateQueryParams(TimeAgo.MonthsAgo6)[0],
	endDate: null,
}

const makeBarChartReport = (data: DispatchCountsReportLine[]): PortalReport => {
	return {
		data: data
			.sort((a, b) => {
				if (a.year === b.year) {
					return a.month > b.month ? 1 : -1
				}
				return a.year > b.year ? 1 : -1
			})
			.map((d) => ({
				closed: d.closed,
				open: d.open,
				completed: d.completed,
				date: dayjs(`${d.month}-01-${d.year}`).format("MMM YYYY"),
			})),
		dataKeys: {
			closed: "Closed",
			open: "Open",
			completed: "Completed",
		},
		xKey: "date",
		xLabel: "Date",
		yLabel: "Count",
	}
}

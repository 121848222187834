import React from "react"

//custom components
import {
	Alert,
	ButtonWithIcon,
	GridContainer,
	GridItem,
	FileInput,
	ConditionalContent,
} from "~/components"

// @material-ui/icons
import CloudUpload from "@material-ui/icons/CloudUpload"
import AttachFile from "@material-ui/icons/AttachFile"
import AddIcon from "@material-ui/icons/Add"
import RemoveIcon from "@material-ui/icons/Remove"

// styles
import withStyles from "@material-ui/core/styles/withStyles"

const styles = {
	clickableRow: {
		cursor: "pointer",
	},
	columnChooser: {
		margin: "15px",
	},
	inputAdornmentIcon: {
		color: "#555",
	},
	right: {
		textAlign: "right",
	},
	bottom: {
		marginTop: "19px",
	},
}

function TicketDocumentUpload(props) {
	const {
		isSaving,
		onFileSelected,
		onSubmit,
		showSuccessfulUpload,
		onCloseSuccessModal,
		onBackToList,
		showFailedUpload,
		onCloseFailedUploadModal,
		onCancelUploadFile,
		onStartUploadFile,
		fileInputRef,
		isAdding,
	} = props
	return (
		<React.Fragment>
			<GridContainer>
				<GridItem xs={12} md={12} lg={12}>
					<GridContainer>
						<GridItem xs={12} md={6} show={isAdding}>
							<FileInput
								onFileSelected={onFileSelected}
								onChooseFile={onStartUploadFile}
								onFileSelectionCanceled={onCancelUploadFile}
								buttonProps={{
									content: "Choose File",
									icon: <AttachFile />,
								}}
								ref={fileInputRef}
							/>
						</GridItem>
						<GridItem xs={12} md={6} show={isAdding}>
							<ButtonWithIcon
								content="Upload"
								onClick={onSubmit}
								icon={<CloudUpload />}
								loading={isSaving}
								disabled={isSaving}
								color="success"
								round
							/>
						</GridItem>
					</GridContainer>
				</GridItem>
				<ConditionalContent hide={isAdding}>
					<GridItem xs={12}>
						<ButtonWithIcon
							icon={<AddIcon />}
							content="Add Document"
							size="sm"
							round
							color="success"
							onClick={onStartUploadFile}
						/>
					</GridItem>
				</ConditionalContent>
				<ConditionalContent show={isAdding}>
					<GridItem xs={12}>
						<ButtonWithIcon
							icon={<RemoveIcon />}
							content="Cancel"
							size="sm"
							round
							color="danger"
							onClick={onCancelUploadFile}
						/>
					</GridItem>
				</ConditionalContent>
			</GridContainer>

			<Alert
				success
				show={showSuccessfulUpload}
				title="Document successfully uploaded!"
				onCancel={onBackToList}
				cancelBtnText="Back to List"
				onConfirm={onCloseSuccessModal}
				confirmBtnText="Close"
			/>

			<Alert
				danger
				show={showFailedUpload}
				title="Document failed to upload"
				onConfirm={onCloseFailedUploadModal}
				confirmBtnText="Try Again"
			>
				{"Please try your upload again."}
			</Alert>
		</React.Fragment>
	)
}

export default withStyles(styles)(TicketDocumentUpload)

import { FC, useState } from "react"

import { makeApiErrorMessage, useCreateEnterprise } from "@ncs/ncs-api"
import { ExtendableModalProps, Modal, TextInput, useToast } from "@ncs/web-legos"

export interface NewEnterpriseModalProps extends ExtendableModalProps {
	setSelectedEnterpriseId: (id: string | null) => void
}

export const NewEnterpriseModal: FC<NewEnterpriseModalProps> = ({
	setSelectedEnterpriseId,
	...rest
}) => {
	const { makeSuccessToast } = useToast()
	const [name, setName] = useState<string | null>(null)
	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const createEnterprise = useCreateEnterprise()

	const handleSave = async () => {
		try {
			if (!name) {
				throw new Error("Name is required")
			}

			setIsSaving(true)
			const { data } = await createEnterprise({ name })
			makeSuccessToast("New enterprise created")
			setSelectedEnterpriseId(data)
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	return (
		<Modal
			{...rest}
			title="Create New Enterprise"
			errorText={errorText}
			rightButtons={{
				buttonText: "Create Enterprise",
				isLoading: isSaving,
				disabled: !name,
				onClick: handleSave,
			}}
		>
			<TextInput value={name} onChange={setName} label="Name" autoFocus />
		</Modal>
	)
}

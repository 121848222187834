import React from "react"
import PropTypes from "prop-types"

// custom components
import {
	Alert,
	ButtonWithIcon,
	Card,
	CardBody,
	CardHeader,
	CardText,
	GridContainer,
	GridItem,
	Input,
	QuickCard,
} from "~/components"
import CartTable from "./CartTable"

// icons
import LocalShipping from "@material-ui/icons/LocalShipping"
import Check from "@material-ui/icons/Check"
import ArrowBack from "@material-ui/icons/ArrowBack"

// styles
import withStyles from "@material-ui/core/styles/withStyles"
import extendedTablesStyle from "@ncs/bricks/assets/jss/material-dashboard-pro-react/views/extendedTablesStyle"
import { dangerColor, grayColor } from "@ncs/bricks/assets/jss/material-dashboard-pro-react"

const styles = {
	...extendedTablesStyle,
	priceLabel: {
		textAlign: "right",
		marginRight: "0",
		paddingRight: "2px !important",
		fontSize: "1rem",
	},
	price: {
		color: grayColor[0],
		textDecoration: "none",
		fontWeight: "bold",
		textAlign: "left",
		marginLeft: "0",
		paddingLeft: "2px !important",
		fontSize: "1rem",
	},
	totalPrice: {
		color: dangerColor[0],
	},
	center: {
		textAlign: "center",
	},
}

const Checkout = (props) => {
	const {
		classes,
		items,
		onPlaceOrder,
		isPlacingOrder,
		showSuccessModal,
		onCloseSuccessModal,
		showFailureModal,
		onCloseFailureModal,
		name,
		nameIsValid,
		nameErrorMessage,
		onNameChange,
		address1,
		address1IsValid,
		address1ErrorMessage,
		onAddress1Change,
		address2,
		address2IsValid,
		address2ErrorMessage,
		onAddress2Change,
		city,
		cityIsValid,
		cityErrorMessage,
		onCityChange,
		addressState,
		addressStateIsValid,
		addressStateErrorMessage,
		onAddressStateChange,
		postalCode,
		postalCodeIsValid,
		postalCodeErrorMessage,
		onPostalCodeChange,
		showValidationErrors,
		formIsValid,
		onViewCart,
	} = props

	return (
		<React.Fragment>
			<GridContainer>
				<GridItem xs={12}>
					<QuickCard title={"Shipping Info"} icon={<LocalShipping />}>
						<GridContainer>
							<GridItem xs={12} md={6}>
								<GridContainer>
									<GridItem xs={12}>
										<Input
											labelText={"Name"}
											value={name}
											onChange={onNameChange}
											inputProps={{ autoFocus: true }}
											formControlProps={{
												error: showValidationErrors && !nameIsValid,
											}}
											helperText={
												showValidationErrors ? nameErrorMessage : ""
											}
										/>
									</GridItem>

									<GridItem xs={12}>
										<Input
											labelText={"Address 1"}
											value={address1}
											onChange={onAddress1Change}
											formControlProps={{
												error: showValidationErrors && !address1IsValid,
											}}
											helperText={
												showValidationErrors ? address1ErrorMessage : ""
											}
										/>
									</GridItem>

									<GridItem xs={12}>
										<Input
											labelText={"Address 2"}
											value={address2}
											onChange={onAddress2Change}
											formControlProps={{
												error: showValidationErrors && !address2IsValid,
											}}
											helperText={
												showValidationErrors ? address2ErrorMessage : ""
											}
										/>
									</GridItem>
								</GridContainer>
							</GridItem>

							<GridItem xs={12} md={6}>
								<GridContainer>
									<GridItem xs={12}>
										<Input
											labelText={"City"}
											value={city}
											onChange={onCityChange}
											formControlProps={{
												error: showValidationErrors && !cityIsValid,
											}}
											helperText={
												showValidationErrors ? cityErrorMessage : ""
											}
										/>
									</GridItem>

									<GridItem xs={12} lg={6}>
										<Input
											labelText={"State"}
											value={addressState}
											onChange={onAddressStateChange}
											formControlProps={{
												error:
													showValidationErrors && !addressStateIsValid,
											}}
											helperText={
												showValidationErrors ? addressStateErrorMessage : (
													""
												)
											}
										/>
									</GridItem>
									<GridItem xs={12} lg={6}>
										<Input
											labelText={"Zip"}
											value={postalCode}
											onChange={onPostalCodeChange}
											formControlProps={{
												error: showValidationErrors && !postalCodeIsValid,
											}}
											helperText={
												showValidationErrors ? postalCodeErrorMessage : ""
											}
										/>
									</GridItem>
								</GridContainer>
							</GridItem>
							<GridItem xs={12}>
								<ButtonWithIcon
									content="Back to Cart"
									icon={<ArrowBack />}
									round
									onClick={onViewCart}
								/>
								<ButtonWithIcon
									content="Place Order"
									icon={<Check />}
									color={"success"}
									round
									onClick={onPlaceOrder}
									loading={isPlacingOrder}
									disabled={
										isPlacingOrder || (showValidationErrors && !formIsValid)
									}
								/>
							</GridItem>
						</GridContainer>
					</QuickCard>
				</GridItem>

				<GridItem xs={12}>
					<Card>
						<CardHeader color="rose" text>
							<CardText color="rose">
								<h4 className={classes.cardTitle}>{"Order Summary"}</h4>
							</CardText>
						</CardHeader>
						{items.length > 0 ?
							<CardBody>
								<CartTable items={items} canUpdateQuantity={false} />
							</CardBody>
						:	<CardBody>
								<GridContainer>{"Cart is Empty"}</GridContainer>
							</CardBody>
						}
					</Card>
				</GridItem>
			</GridContainer>

			<Alert
				success
				show={showSuccessModal}
				title={"Success!"}
				onConfirm={onCloseSuccessModal}
				confirmBtnText="Awesome!"
			>
				{"Your order has been placed."}
			</Alert>

			<Alert
				danger
				show={showFailureModal}
				title={"Order failed."}
				onConfirm={onCloseFailureModal}
				confirmBtnText="Try Again"
			>
				{"Your order could not be placed."}
			</Alert>
		</React.Fragment>
	)
}

Checkout.propTypes = {
	classes: PropTypes.object.isRequired,
	items: PropTypes.array.isRequired,
	onPlaceOrder: PropTypes.func.isRequired,
	isPlacingOrder: PropTypes.bool.isRequired,
	showSuccessModal: PropTypes.bool.isRequired,
	showFailureModal: PropTypes.bool.isRequired,
	onCloseSuccessModal: PropTypes.func.isRequired,
	onCloseFailureModal: PropTypes.func.isRequired,
	onViewCart: PropTypes.func.isRequired,

	// form fields
	showValidationErrors: PropTypes.bool.isRequired,
	formIsValid: PropTypes.bool.isRequired,
	name: PropTypes.string,
	nameIsValid: PropTypes.bool,
	nameErrorMessage: PropTypes.string,
	onNameChange: PropTypes.func.isRequired,
	address1: PropTypes.string,
	address1IsValid: PropTypes.bool,
	address1ErrorMessage: PropTypes.string,
	onAddress1Change: PropTypes.func.isRequired,
	address2: PropTypes.string,
	address2IsValid: PropTypes.bool,
	address2ErrorMessage: PropTypes.string,
	onAddress2Change: PropTypes.func.isRequired,
	city: PropTypes.string,
	cityIsValid: PropTypes.bool,
	cityErrorMessage: PropTypes.string,
	onCityChange: PropTypes.func.isRequired,
	addressState: PropTypes.string,
	addressStateIsValid: PropTypes.bool,
	addressStateErrorMessage: PropTypes.string,
	onAddressStateChange: PropTypes.func.isRequired,
	postalCode: PropTypes.string,
	postalCodeIsValid: PropTypes.bool,
	postalCodeErrorMessage: PropTypes.string,
	onPostalCodeChange: PropTypes.func.isRequired,
}

export default withStyles(styles)(Checkout)

import { Dispatch, ReactElement, SetStateAction } from "react"

import { InventoryRequestTypeId } from "@ncs/ncs-api"

import { InventoryRequestTypeSelector } from "../selectors"

export enum InventoryRequestTypeParamKey {
	InventoryRequestTypeKey = "inventoryType",
}

export interface InventoryRequestTypeParamState {
	[InventoryRequestTypeParamKey.InventoryRequestTypeKey]: InventoryRequestTypeId | null
}

export interface InventoryRequestTypeQueryFilterProps<
	QueryParamState extends InventoryRequestTypeParamState,
> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

export const InventoryRequestTypeQueryFilter = <
	QueryParamState extends InventoryRequestTypeParamState,
>({
	queryParamState,
	setQueryParamState,
}: InventoryRequestTypeQueryFilterProps<QueryParamState>): ReactElement => {
	return (
		<InventoryRequestTypeSelector
			value={queryParamState[InventoryRequestTypeParamKey.InventoryRequestTypeKey]}
			disableNoSelectionOption={false}
			fillContainer
			onChange={(newValue) =>
				setQueryParamState((prev) => {
					const newInventoryRequestTypeParamState: InventoryRequestTypeParamState = {
						[InventoryRequestTypeParamKey.InventoryRequestTypeKey]: newValue,
					}

					return {
						...prev,
						...newInventoryRequestTypeParamState,
					}
				})
			}
		/>
	)
}

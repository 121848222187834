import { FC, Fragment, useState } from "react"

import { css } from "@emotion/react"
import { nanoid } from "nanoid"

import { useShipmentPackageSizes } from "@ncs/ncs-api"
import { displayNumber, formatNumber, ObjectEntry } from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	Box,
	Button,
	CssGridTable,
	cssMixins,
	Divider,
	GridContainer,
	GridItem,
	Heading,
	Icon,
	IconButton,
	NumericInput,
	Paragraph,
	RadioBoolean,
	Select,
	Tooltip,
} from "@ncs/web-legos"

import { LinePackagesState, useShipShipmentContext } from "~/util/ship-shipment"

import { ShipmentPackageModal } from "./components"

export const ShipmentPackItems: FC = () => {
	const {
		hasPackagePermission,
		usingPackages,
		setUsingPackages,
		packItemsStepComplete,
		setPackItemsStepComplete,
		packages,
		linePackages,
		setLinePackages,
		usingPortalFedEx,
		resetPackingState,
	} = useShipShipmentContext()

	const [showNewPkgModal, setShowNewPkgModal] = useState(false)
	const [editModalPkg, setEditModalPkg] = useState<{
		id: string
		autoFocus?: "name" | "weight"
	} | null>(null)

	const [sizes] = useShipmentPackageSizes()

	const updateLineQuantity = (tempId: string, newValue: number | null) => {
		setLinePackages((prev) => ({
			...prev,
			[tempId]: {
				...prev[tempId],
				packageQuantity: newValue,
			},
		}))
	}

	const updateLinePackage = (tempId: string, newValue: string | null) => {
		setLinePackages((prev) => ({
			...prev,
			[tempId]: {
				...prev[tempId],
				packageTempId: newValue,
			},
		}))
	}

	const splitLine = (tempIdToClone: string) => {
		setLinePackages((prev) => {
			return Object.fromEntries(
				Object.entries(prev).flatMap(([id, line]): ObjectEntry<LinePackagesState>[] => {
					if (id === tempIdToClone) {
						const newId = nanoid()

						return [
							[id, line],
							[
								newId,
								{
									...line,
									tempId: newId,
									packageQuantity: null,
									packageTempId: null,
									isClone: true,
								},
							],
						]
					}
					return [[id, line]]
				})
			)
		})
	}

	const removeClone = (tempIdToRemove: string) => {
		setLinePackages((prev) => {
			return Object.fromEntries(
				Object.entries(prev).filter(([tempId]) => tempId !== tempIdToRemove)
			)
		})
	}

	return (
		<>
			{hasPackagePermission ?
				<>
					<Box d="flex" gap={0.5} alignItems="center">
						<Paragraph>Will you be recording packing container information?</Paragraph>
						<Tooltip
							title={
								<>
									<Paragraph mb={1}>
										Unless you are shipping with FedEx and intend to use the
										integrated FedEx system (as opposed to the FedEx Manager),
										you do not need to record packing information.
									</Paragraph>
									<Paragraph>
										If you'd like, you can still record the information and it
										will be viewable later in the Carrier Tracking modal.
									</Paragraph>
								</>
							}
						>
							<Icon icon="info-circle" color="gray" />
						</Tooltip>
					</Box>
					<RadioBoolean
						htmlName="package-mode"
						value={usingPackages}
						onChange={setUsingPackages}
						yesText={`Yes ${
							usingPortalFedEx ? " (required if using integrated FedEx)" : ""
						}`}
						disabledAccessor={() => usingPortalFedEx}
						noFirst
						noText="No (default)"
						mb={2}
					/>
				</>
			:	<Paragraph secondary mb={1}>
					No additional packing information needed.
				</Paragraph>
			}

			<AnimatedEntrance show={usingPackages}>
				<Box d="flex" justifyContent="flex-end" mb={0.5}>
					<Button onClick={resetPackingState} icon="undo">
						Reset
					</Button>
				</Box>

				<CssGridTable
					gridTemplateColumns="auto 1fr auto auto auto auto"
					headers={["Part #", "Name", "Total Qty", "Package Qty", "Package", "Split"]}
					columnGap={1}
					rowGap={0.5}
					cells={Object.values(linePackages).map((line) => {
						const {
							packageQuantity,
							originalQuantity,
							packageTempId,
							partName,
							partNumber,
							tempId,
						} = line
						const selectedPkg = packageTempId ? packages[packageTempId] ?? null : null

						return (
							<Fragment key={tempId}>
								<span>{partNumber}</span>
								<span>{partName}</span>
								<span>{!line.isClone && formatNumber(originalQuantity)}</span>
								<NumericInput
									value={packageQuantity}
									onChange={(newValue) =>
										updateLineQuantity(tempId, newValue ?? null)
									}
									max={originalQuantity}
									maxWidth={4}
									placeholder="Qty..."
									mb={0}
								/>
								<Select
									options={Object.values(packages)}
									value={selectedPkg?.tempId ?? null}
									onChange={(pkgTempId) => updateLinePackage(tempId, pkgTempId)}
									valueAccessor="tempId"
									textAccessor="name"
									disableNoSelectionOption={false}
									mb={0}
								/>
								{line.isClone ?
									<IconButton
										icon="times"
										onClick={() => removeClone(line.tempId)}
										title="Remove split line item"
										color="light-gray"
									/>
								:	<IconButton
										icon="split"
										onClick={() => splitLine(line.tempId)}
										title="Split line item across multiple packages"
										color="gray"
									/>
								}
							</Fragment>
						)
					})}
				/>

				<GridContainer my={3} alignItems="flex-start">
					{Object.values(packages).map((pkg) => {
						const type = sizes?.find((size) => size.id === pkg.typeId)
						const lines = Object.values(linePackages).filter(
							(line) => line.packageTempId === pkg.tempId && !!line.packageQuantity
						)

						return (
							<GridItem key={pkg.tempId} xs={12} sm={6} md={4} css={packageCardCss}>
								<Box d="flex" justifyContent="space-between" gap={0.5}>
									<div>
										<Heading mb={0.5}>{pkg.name}</Heading>
										<Paragraph small secondary mb={1}>
											{type?.name}
										</Paragraph>
									</div>
									<Box mt={-0.3} mr={-0.3}>
										<IconButton
											icon="pencil"
											onClick={() =>
												setEditModalPkg({
													id: pkg.tempId,
												})
											}
											color="primary"
										/>
									</Box>
								</Box>

								{lines.length ?
									<>
										<CssGridTable
											gridTemplateColumns="auto auto 1fr"
											headers={["Qty", "Part #", "Name"]}
											columnGap={0.5}
											alignItems="flex-start"
											cells={lines
												.filter((line) => !!line.packageQuantity)
												.map((line) => (
													<Fragment key={line.tempId}>
														<span>
															{displayNumber(line.packageQuantity)}
														</span>
														<span css={cssMixins.breakAnywhereStyle}>
															{line.partNumber}
														</span>
														<span css={cssMixins.breakWord}>
															{line.partName}
														</span>
													</Fragment>
												))}
										/>

										<Divider my={1} />

										<Box d="flex" flexDirection="column" gap={0.5}>
											{pkg.weight == null ?
												<Button
													icon="pallet-box"
													fixedWidthIcon
													onClick={() =>
														setEditModalPkg({
															id: pkg.tempId,
															autoFocus: "weight",
														})
													}
												>
													Enter weight
												</Button>
											:	<Paragraph bold secondary pl={0.35}>
													{displayNumber(pkg.weight)} lbs
												</Paragraph>
											}
										</Box>
									</>
								:	<Paragraph small secondary>
										(empty)
									</Paragraph>
								}
							</GridItem>
						)
					})}
					<GridItem xs={12} sm={6} md={4} css={addPackageCard}>
						<Button icon="plus" onClick={() => setShowNewPkgModal(true)}>
							Add package
						</Button>
					</GridItem>
				</GridContainer>
			</AnimatedEntrance>

			{!packItemsStepComplete && (
				<Button
					icon="check"
					variant="secondary-cta"
					onClick={() => setPackItemsStepComplete(true)}
				>
					{usingPackages ? "Done Packing" : "Continue"}
				</Button>
			)}

			{(showNewPkgModal || !!editModalPkg) && (
				<ShipmentPackageModal
					editPackageTempId={editModalPkg?.id ?? null}
					onClose={() => {
						setShowNewPkgModal(false)
						setEditModalPkg(null)
					}}
					autoFocus={showNewPkgModal ? "name" : editModalPkg?.autoFocus}
				/>
			)}
		</>
	)
}

const packageCardCss = css`
	border: 1px solid #ddd;
	border-radius: 5px;
	padding: 1rem;
`
const addPackageCard = css`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	min-height: 5rem;
	border: 1px dashed #ccc;
	border-radius: 5px;
`

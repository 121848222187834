import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core"
import { GridItem } from "../../components"

const useStyles = makeStyles({
	"@keyframes moveToPosition": {
		to: {
			opacity: 1,
			transform: "none",
		},
	},
	animatedEntrance: {
		animationName: "$moveToPosition",
		animationFillMode: "forwards",
		animationDuration: (props) => `${props.duration}ms`,
		animationTimingFunction: (props) => props.easing,
		animationDelay: (props) => `${props.delay}ms`,
		opacity: (props) => (props.fade ? "0" : "1"),
		transform: (props) => props.transform,
	},
})

const AnimatedEntrance = ({
	direction,
	duration,
	easing,
	delay,
	fade,
	asGridItem,
	show,
	children,
	...rest
}) => {
	const classes = useStyles({
		transform: getTransformFromDirection(direction),
		duration,
		easing,
		delay,
		fade,
	})

	function getTransformFromDirection(direction) {
		switch (direction) {
			case "up":
				return "translateY(0.65em)"
			case "down":
				return "translateY(-0.65em)"
			case "left":
				return "translateX(100%)"
			case "right":
				return "translateX(-100%)"
			default:
				return "translateY(0.65em)"
		}
	}

	if (asGridItem && show) {
		return (
			<GridItem className={classes.animatedEntrance} {...rest}>
				{children}
			</GridItem>
		)
	}

	if (show) {
		return (
			<div className={classes.animatedEntrance} {...rest}>
				{children}
			</div>
		)
	}

	return <div />
}

AnimatedEntrance.defaultProps = {
	direction: "up",
	duration: 500,
	easing: "cubic-bezier(0.33, 1, 0.68, 1)",
	delay: 0,
	fade: true,
	show: true,
	asGridItem: false,
}

AnimatedEntrance.propTypes = {
	direction: PropTypes.oneOf(["up", "down", "left", "right"]),
	duration: PropTypes.number,
	easing: PropTypes.string,
	delay: PropTypes.number,
	fade: PropTypes.bool,
	show: PropTypes.bool,
	children: PropTypes.node.isRequired,
	asGridItem: PropTypes.bool,
}

export default AnimatedEntrance

import { FC, useCallback, useMemo } from "react"

import dayjs from "dayjs"
import { useQueryClient } from "react-query"
import { useHistory } from "react-router-dom"
import { Cell, Column } from "react-table"

import {
	PartOrderList,
	PartOrderListQueryParams,
	PartOrdersUrlPaths,
	usePartOrderList,
} from "@ncs/ncs-api"
import {
	formatCurrency,
	formatDateTime,
	getStartAndEndDateQueryParams,
	getTimeAgoFromStartDate,
	getTimezoneAbbreviation,
	isEnumMember,
	Typify,
} from "@ncs/ts-utils"
import {
	Box,
	cssMixins,
	CustomerInternalQueryFilter,
	CustomerQueryFilter,
	EmptyValueDash,
	EnterpriseQueryFilter,
	Icon,
	IncludeFutureOrdersQueryFilter,
	LetterIcon,
	MultiWarehouseQueryFilter,
	OrderShipMethodQueryFilter,
	OrganizationQueryFilter,
	PartOrderStatusQueryFilter,
	SearchQueryFilter,
	ShippingMethod,
	shippingMethods,
	StatusBlip,
	Table,
	TerritoryQueryFilter,
	TimeAgoQueryFilter,
	TimeAgoQueryFilterProps,
	Tooltip,
	usePaginationUrlState,
	UserQueryFilter,
	UserQueryFilterProps,
	useScrollToTopOnMount,
	useUrlState,
} from "@ncs/web-legos"

import { OrderLineStatusQueryFilter, OrderLineTypeQueryFilter } from "~/components"
import { getLineLocationPickListCreationStatus } from "~/util"

export type PartOrdersViewOrdersTabUrlState = Typify<PartOrderListQueryParams>

export const PartOrdersViewOrdersTab: FC = () => {
	useScrollToTopOnMount()
	const history = useHistory()
	const queryClient = useQueryClient()

	const [filterParams, { setUrlState: setFilterParams }] =
		useUrlState<PartOrdersViewOrdersTabUrlState>({ ...initialQueryParams })
	const [pagination, setPagination] = usePaginationUrlState()
	const params = useMemo(
		() => ({
			...filterParams,
			page: pagination.page,
			pageSize: pagination.pageSize,
		}),
		[filterParams, pagination.page, pagination.pageSize]
	)

	const partOrderSearchQuery = usePartOrderList({
		params,
		manualPagination: true,
		queryConfig: {
			staleTime: 1000 * 60,
			refetchInterval: 1000 * 60,
			refetchIntervalInBackground: false,
			refetchOnWindowFocus: true,
		},
	})

	const showFiltersByDefault = useMemo(() => {
		const keys: (keyof typeof filterParams)[] = [
			"organization",
			"enterprise",
			"partOrderStatus",
			"futureOrders",
			"picker",
			"lineType",
			"orderLineStatus",
			"shipMethod",
		]
		return keys.some((key) => filterParams[key] != null)
	}, [filterParams])

	const onRowClick = useCallback(
		({ original }) => {
			// Mark the query as stale, so that if user backs up to the list view we will
			// refetch. Necessary because of the kinda funky way we ended up doing pagination
			// with RQ's infinite queries.
			void queryClient.invalidateQueries(PartOrdersUrlPaths.Parts)

			history.push(`/part-orders/${original.id}`, {
				partOrderSearchQueryParams: params,
			})
		},
		[history, params, queryClient]
	)

	return (
		<>
			<Table
				columns={columns}
				query={partOrderSearchQuery}
				queryParamState={filterParams}
				setQueryParamState={setFilterParams}
				pinnedQueryFilters={[
					SearchQueryFilter,
					CustomerQueryFilter,
					TerritoryQueryFilter,
					MultiWarehouseQueryFilter,
					PartOrderTimeAgoQueryFilter,
				]}
				toggledQueryFilters={[
					CustomerInternalQueryFilter,
					EnterpriseQueryFilter,
					OrganizationQueryFilter,
					ListPickerQueryFilter,
					PartOrderStatusQueryFilter,
					IncludeFutureOrdersQueryFilter,
					OrderLineStatusQueryFilter,
					OrderLineTypeQueryFilter,
					OrderShipMethodQueryFilter,
				]}
				pagination={pagination}
				setPagination={setPagination}
				disableAllSorting
				showToggledFiltersByDefault={showFiltersByDefault}
				onRowClick={onRowClick}
				noDataText="No orders found with the current filter settings"
				storeColumnVisibility
				filterResetValues={initialQueryParams}
			/>
		</>
	)
}

const columns: Column<PartOrderList[number]>[] = [
	{
		Header: "Order #",
		accessor: "orderId",
		disableToggle: true,
		Cell: ({ row: { original } }: Cell<PartOrderList[number]>) => {
			return (
				<Box d="flex" gap={0.5}>
					<span>{original.orderId}</span>
					{!!original.hazmat && (
						<Tooltip title="Order contains hazmat part(s)">
							<LetterIcon letter="H" />
						</Tooltip>
					)}
				</Box>
			)
		},
	},
	{
		Header: "KBM #",
		id: "kbmOrderNumber",
		hiddenByDefault: true,
		accessor: (original) => original.kbmOrderNumber ?? <EmptyValueDash />,
	},
	{
		Header: `Order date (${getTimezoneAbbreviation()})`,
		id: "orderDate",
		accessor: ({ orderDate }) => (orderDate ? formatDateTime(orderDate) : <EmptyValueDash />),
	},
	{
		Header: "Customer #",
		accessor: ({ customerNumber }) => customerNumber,
	},
	{
		Header: "Customer name",
		accessor: ({ customerName }) => customerName,
	},
	{
		Header: "DC actions needed",
		id: "dcs",
		Cell: ({ row: { original } }: Cell<PartOrderList[number]>) => {
			if (original.locations.length === 0) {
				return <EmptyValueDash />
			}

			return (
				<>
					{original.locations.map((l) => {
						const pickListCreationStatus = getLineLocationPickListCreationStatus(l)

						return (
							<Box
								key={l.locationId}
								display="flex"
								alignItems="flex-start"
								gap={0.5}
							>
								{pickListCreationStatus && (
									<Tooltip title={pickListCreationStatus[1]}>
										<StatusBlip status={pickListCreationStatus[0]} />
									</Tooltip>
								)}
								{l.picking && (
									<Tooltip title="Waiting on pick list completion">
										<Icon icon="person-dolly" />
									</Tooltip>
								)}
								{l.shipping && (
									<Tooltip title="Waiting on shipment of items">
										<Icon icon="box-check" />
									</Tooltip>
								)}
								({l.locationNumber}) {l.locationName}
							</Box>
						)
					})}
				</>
			)
		},
	},
	{
		Header: "Pick list assignments",
		id: "pickers",
		hiddenByDefault: true,
		Cell: ({ row: { original } }: Cell<PartOrderList[number]>) => {
			if (original.locations.length === 0) {
				return <EmptyValueDash />
			}

			return (
				<>
					{original.locations.map((l) => {
						return (
							<div key={l.locationId}>
								{l.picker ?
									<span>
										{l.picker.firstName} {l.picker.lastName} ({l.locationName})
									</span>
								:	<EmptyValueDash />}
							</div>
						)
					})}
				</>
			)
		},
	},
	{
		Header: "Picked to bin",
		id: "pickedToBin",
		hiddenByDefault: true,
		Cell: ({ row: { original } }: Cell<PartOrderList[number]>) => {
			if (original.locations.length === 0) {
				return <EmptyValueDash />
			}

			return (
				<>
					{original.locations.map((l) => {
						return (
							<div key={l.locationId}>
								{l.pickedToBin ?
									<span>
										{l.pickedToBin} ({l.locationName})
									</span>
								:	<EmptyValueDash />}
							</div>
						)
					})}
				</>
			)
		},
	},
	{
		Header: "Ship method",
		hiddenByDefault: true,
		accessor: ({ shipMethodId, shipMethodDescription }) => {
			// The common ones are mapped to more user-friendly descriptions that are used
			// in the ship method selector dropdown.
			if (isEnumMember(shipMethodId, ShippingMethod)) {
				return shippingMethods[shipMethodId]
			}
			// Fallback to whatever the system description is.
			return shipMethodDescription
		},
	},
	{
		Header: "Order status",
		accessor: ({ status }) => <span css={cssMixins.noWrap}>{status ?? "-"}</span>,
	},
	{
		Header: "Line Count",
		hiddenByDefault: true,
		accessor: ({ lineItemCount }) => (
			<span css={cssMixins.noWrap}>{lineItemCount ?? "-"}</span>
		),
	},
	{
		Header: "Order Subtotal",
		hiddenByDefault: true,
		accessor: ({ subTotal }) => (
			<span css={cssMixins.noWrap}>{subTotal != null ? formatCurrency(subTotal) : "-"}</span>
		),
	},
]

const [startDate] = getStartAndEndDateQueryParams(
	getTimeAgoFromStartDate(dayjs().subtract(6, "months").toISOString())
)

const initialQueryParams: PartOrderListQueryParams = {
	search: null,
	ordering: null,
	organization: null,
	enterprise: null,
	territory: null,
	customer: null,
	startDate,
	endDate: null,
	partOrderStatus: null,
	warehouse: null,
	warehouses: [],
	futureOrders: null,
	picker: null,
	lineType: null,
	orderLineStatus: null,
	shipMethod: null,
	subTotal: null,
	isInternal: null,
	dashboardActionableItems: null,
}

const PartOrderTimeAgoQueryFilter = (props: TimeAgoQueryFilterProps<PartOrderListQueryParams>) => {
	return <TimeAgoQueryFilter {...props} disableNoSelectionOption showNoSelectionOption={false} />
}

const ListPickerQueryFilter = (props: UserQueryFilterProps<PartOrderListQueryParams>) => {
	return <UserQueryFilter {...props} label="Lists picked by" paramKey="picker" employeesOnly />
}

import { FC } from "react"

import { ExtendableSelectProps, Select } from "../inputs"

const options = [
	{
		value: "stock",
		label: "Stock parts",
	},
	{
		value: "local",
		label: "Locally sourced parts",
	},
]

export const LocationPartTypeSelector: FC<ExtendableSelectProps<(typeof options)[number]>> = ({
	...rest
}) => {
	return (
		<Select
			{...rest}
			label="Part type"
			options={options}
			fillContainer
			valueAccessor="value"
			textAccessor="label"
			noSelectionOptionText="Any"
			disableNoSelectionOption={false}
		/>
	)
}

import { useCallback, useLayoutEffect, useState } from "react"

/** Returns a function that will scroll to the top of the page when called **/
export const useScrollToBottom = (): (() => void) => {
	const [triggered, setTriggered] = useState<Date>()

	// scroll to the top when triggered changes, except the first time
	useLayoutEffect(() => {
		let id: null | ReturnType<typeof setTimeout> = null
		if (typeof triggered !== "undefined") {
			const totalHeight = document.documentElement.getBoundingClientRect().height
			id = setTimeout(() => {
				window.scrollTo({ left: 0, top: totalHeight, behavior: "smooth" })
			}, 1)
		}
		return () => {
			if (id) {
				clearTimeout(id)
			}
		}
	}, [triggered])

	const trigger = useCallback(() => {
		setTriggered(new Date())
	}, [])

	return trigger
}

import { FC, Fragment, memo, useCallback, useEffect, useMemo, useState } from "react"

import { css, Theme } from "@emotion/react"
import produce from "immer"
import cloneDeep from "lodash/cloneDeep"
import { nanoid } from "nanoid"

import {
	InventoryLocation,
	LabelSize,
	makeApiErrorMessage,
	PartOrderLineItem,
	PartOrderLineMin,
	PurchaseOrderLineItemMin,
	useCreateLabel,
	useDefaultOrderBins,
	useLocationBins,
} from "@ncs/ncs-api"
import { extractNumber } from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	blobSaver,
	Box,
	Button,
	Checkbox,
	ConfirmationModal,
	ConfirmationModalConfig,
	defaultLabelSize,
	ErrorText,
	ExtendableModalProps,
	LabelSizeSelector,
	LoadingSpinner,
	LocationSelector,
	Modal,
	Paragraph,
	useChangeCallback,
	useToast,
} from "@ncs/web-legos"

import {
	isPartOrderLineItem,
	isPurchaseOrderLineItem,
	LineFormState,
	LinePrintingState,
	makeLineFormState,
	makeLinePrintingState,
	ReceivingLine,
} from "./receive-parts-modal-util"
import { ReceivePartsTableTr } from "./ReceivePartsTableTr"

export interface ReceivePartsModalProps extends ExtendableModalProps {
	lineItems: (PartOrderLineItem | PurchaseOrderLineItemMin)[]
	partOrderId?: string
	purchaseOrderId?: string
	/**
	 * Function to call to actually receive the parts. Should not try/catch, should not close modal!
	 */
	partReceiveHandler: (
		locationId: string,
		lines: {
			lineId: string
			binQuantities: {
				binId: string
				quantity: number
				partOrderId: string | null
			}[]
		}[]
	) => void | Promise<void>
}

export const ReceivePartsModal: FC<ReceivePartsModalProps> = memo(
	({
		lineItems: partOrPurchaseLines,
		partOrderId,
		purchaseOrderId,
		onClose,
		partReceiveHandler,
		...rest
	}) => {
		const { makeSuccessToast } = useToast()
		const [location, setLocation] = useState<InventoryLocation | null>(null)
		const [showFinishedLines, setShowFinishedLines] = useState(false)
		const [showDemands, setShowDemands] = useState(false)
		const [bins, locationBinsLoading] = useLocationBins(location?.id ?? null)
		const [defaultBins, defaultBinsLoading] = useDefaultOrderBins({
			params: {
				location: location?.id,
				partOrder: partOrderId,
				purchaseOrder: purchaseOrderId,
			},
		})
		const makeLabels = useCreateLabel()

		const [isReceiving, setIsReceiving] = useState(false)
		const [errorText, setErrorText] = useState<string | null>(null)
		const [confirmationConfig, setConfirmationConfig] =
			useState<ConfirmationModalConfig | null>(null)

		const [shouldPrint, setShouldPrint] = useState(false)
		const [labelSize, setLabelSize] = useState<LabelSize>(defaultLabelSize)
		const [linePrintingState, setLinePrintingState] = useState<LinePrintingState>({})

		/**
		 * Take the partOrPurchaseLines items, filter out the ones that don't have parts, and
		 * then put them all in the same shape. Reminder that this is not where we'll store
		 * the state of the user typing into the receiving bin / quantities fields.
		 * There's an actual useState for that. So this stays fixed while that changes.
		 */
		const receivingLines: ReceivingLine[] = useMemo(() => {
			const lines: ReceivingLine[] = []

			// Digest our line items one at a time and build the receiving line as we go.
			partOrPurchaseLines.forEach((line): void => {
				// If the line has no part, we don't care about it in this modal.
				if (!line.part) return

				// We should only consider the ones that have been shipped, preventing us from receiving
				// when they are not part of a shipment
				//Has any of it been shipped?
				const isReceivablePartOrder =
					isPartOrderLineItem(line) ? extractNumber(line.quantityShipped) : null

				const isReceivablePurchaseOrder =
					isPurchaseOrderLineItem(line) ? extractNumber(line.quantityReceivable) : null

				let hasDropShippedQuantity = false

				// Check if it's a PartOrder and handle drop-shipped quantity
				if (isPartOrderLineItem(line)) {
					hasDropShippedQuantity =
						line.quantityDropShipped && line.quantityDropShipped.length > 0
				}

				// If it's a PartOrder and quantityShipped is 0,
				// or it's a PurchaseOrder and quantityReceivable is 0, skip.
				if (
					((isPartOrderLineItem(line) && isReceivablePartOrder === 0) ||
						(isPurchaseOrderLineItem(line) && isReceivablePurchaseOrder === 0)) &&
					!hasDropShippedQuantity
				) {
					return
				}
				// How much of this line item was ordered originally?
				const orderedQuantity = extractNumber(line.quantity)

				// Does the backend say that some have already been received towards this line?
				const alreadyReceived = extractNumber(
					isPartOrderLineItem(line) ? line.quantityReceived : line.receivedQuantity
				)

				// How many can we disperse to our bin destinations?
				const receivableQuantity = extractNumber(
					isPartOrderLineItem(line) ? line.quantityShipped : line.quantityReceivable
				)
				// Now we'll build an array of possible destinations for this line item.
				// These will be listed beneath each line item and go to a specific bin with a specific quantity.
				const destinations: ReceivingLine["destinations"] = []

				// As we make our destinations, we'll look at the quantity they need and suggest
				// that they get this number, keeping track of the line's total receivable quantity
				// as we go.
				let receivableQuantityRemaining = receivableQuantity

				// For a purchase order, we need to build destinations for the individual part order
				// line items that go into it.
				if (isPurchaseOrderLineItem(line)) {
					// Purchase order line items can have an array of part order lines in them.
					const purchaseOrderPartLines = line.partPurchaseLine ?? []

					// Purchase order line items can also have an array of demands on them. We'll
					// look up each part order line in here.
					const purchaseOrderDemands = line.demand ?? []

					// Make a destination for each of the part order lines in the purchase order.
					purchaseOrderPartLines.forEach((partOrderLine: PartOrderLineMin) => {
						// Try to find a matching part order demand.
						const matchingPartOrderDemand = purchaseOrderDemands.find(
							(demand) =>
								demand.partOrder.orderId ===
								partOrderLine.partOrder.orderId.toString()
						)

						// Look at the matching part order demand to get the quantity this order needs.
						const quantityNeeded = extractNumber(
							matchingPartOrderDemand?.partOrder.quantity ?? 0
						)

						// Ideally we can suggest the full amount it wants.
						const suggestedReceiveQuantity =
							quantityNeeded <= receivableQuantityRemaining ? quantityNeeded : (
								receivableQuantityRemaining
							)

						// Update the remaining receivable tally.
						receivableQuantityRemaining =
							receivableQuantityRemaining - suggestedReceiveQuantity

						// Add the destination to our array for this receiving line item.
						destinations.push({
							quantityNeeded,
							suggestedReceiveQuantity,
							partOrder: partOrderLine.partOrder,
						})
					})
				}

				// Now add a destination row for stock. For purchase orders, this will be in addition to
				// the rows of part orders we just made. For a part order, this will just be all of it.
				// It won't have a specific quantity needed.  We'll just suggest that it
				// gets whatever receivable quantity remains.
				destinations.push({
					quantityNeeded: null,
					partOrder: null,
					suggestedReceiveQuantity: receivableQuantityRemaining,
				})

				lines.push({
					lineItemId: line.id.toString(),
					part:
						isPartOrderLineItem(line) ?
							{
								id: line.part.id,
								partNumber: line.part.partNumber,
								description: line.part.description,
								weight: line.part.weight.toString(),
								partFamily: line.part.partFamily,
							}
						:	line.part,
					orderedQuantity,
					alreadyReceived,
					receivableQuantity,
					destinations,
				})
			})

			return lines.sort((a) => {
				return a.alreadyReceived < a.orderedQuantity ? -1 : 1
			})
		}, [partOrPurchaseLines])

		const finishedLinesCount = useMemo(() => {
			return receivingLines.reduce((total, line) => {
				let newTotal = total

				if (line.alreadyReceived >= line.orderedQuantity) {
					newTotal += 1
				}

				return newTotal
			}, 0)
		}, [receivingLines])

		// This is where we track user input of receiving quantities.
		const [lineFormState, setLineFormState] = useState<LineFormState>(() =>
			makeLineFormState(receivingLines)
		)

		// Whenever the line items change, we should reset our state again.
		// Should only happen when user navigates to a new order.
		useChangeCallback(receivingLines, (newLines) => {
			setLineFormState(makeLineFormState(newLines))
		})

		// Look at the default bins that we've received from backend and set the bins according to that.
		// TODO: Can we make this shorter with immer?
		const setDefaultBins = useCallback(() => {
			const updatedLineState: LineFormState = cloneDeep(lineFormState)

			receivingLines.forEach((line) => {
				// Look for a part default bin result.
				const partDefaultBin = (defaultBins ?? []).find(
					(partBin) => partBin.partId.toString() === line.part.id
				)

				// If we found one, then modify the bin quantity entries for the demands on this line item.
				if (partDefaultBin) {
					updatedLineState[line.lineItemId] = {
						...updatedLineState[line.lineItemId],
						destinations: Object.fromEntries(
							Object.values(updatedLineState[line.lineItemId].destinations).map(
								({ binQuantities, partOrder, ...restOfDestination }) => {
									return [
										restOfDestination.destinationId,
										{
											...restOfDestination,
											partOrder,
											binQuantities: Object.fromEntries(
												Object.values(binQuantities).map(
													({ binQuantityId, quantity }) => {
														const newBinId =
															purchaseOrderId ?
																partOrder ?
																	partDefaultBin.orderBinId?.toString() ??
																	null
																:	partDefaultBin.stockBinId?.toString() ??
																	null
															:	partDefaultBin.binId?.toString() ??
																null

														return [
															binQuantityId,
															{
																binQuantityId,
																binId: newBinId,
																quantity,
															},
														]
													}
												)
											),
										},
									]
								}
							)
						),
					}
				}
			})

			setLineFormState(updatedLineState)
		}, [defaultBins, lineFormState, purchaseOrderId, receivingLines])

		const handleChangeShowDemand = (newState: boolean) => {
			setShowDemands(newState)

			// If you're switching away from showing demands, we should clear out the bin/qty for
			// those order rows.
			if (!newState) {
				setLineFormState((prev) => {
					return produce(prev, (draft) => {
						Object.keys(draft).forEach((lineId) => {
							Object.keys(draft[lineId].destinations).forEach((destinationId) => {
								if (draft[lineId].destinations[destinationId].partOrder) {
									const newBinId = nanoid()
									draft[lineId].destinations[destinationId].binQuantities = {
										[newBinId]: {
											binQuantityId: newBinId,
											binId: null,
											quantity: 0,
										},
									}
								}
							})
						})
					})
				})
			}
		}

		const onSubmit = () => {
			if (!location) {
				setErrorText("Select your receiving location")
			} else if (
				Object.values(lineFormState).every((line) =>
					Object.values(line.destinations).every(({ binQuantities }) =>
						Object.values(binQuantities).every(
							(binQuantity) => binQuantity.quantity === 0
						)
					)
				)
			) {
				setErrorText(
					"Submission requires at least one part with a receive quantity greater than zero"
				)
			} else if (
				Object.values(lineFormState).some((line) =>
					Object.values(line.destinations).some((destination) =>
						Object.values(destination.binQuantities).some(
							({ binId, quantity }) => quantity > 0 && binId == null
						)
					)
				)
			) {
				setErrorText(
					"All parts with a receive quantity greater than 0 must have a bin assignment"
				)
			} else if (
				receivingLines.some((line) => {
					const lineTotalToReceive = Object.values(
						lineFormState[line.lineItemId].destinations
					).reduce((lineTotal, destination) => {
						return (
							lineTotal +
							Object.values(destination.binQuantities).reduce(
								(binTotal, { quantity }) => binTotal + quantity,
								0
							)
						)
					}, 0)

					return lineTotalToReceive > line.orderedQuantity
				})
			) {
				setConfirmationConfig({
					message:
						"One or more line item parts have total receiving quantities that are higher than the amount ordered. Please check your work before continuing.",
					onConfirm: receiveParts,
					confirmButtonText: "Yes, I'm sure",
				})
			} else {
				void receiveParts()
			}
		}

		const receiveParts = async () => {
			try {
				if (!location) throw new Error("Trying to receive when a location is not selected")

				const lines = receivingLines
					.map((line) => {
						const binQuantities = Object.values(
							lineFormState[line.lineItemId].destinations
						).flatMap((destination) => {
							return [
								...Object.values(destination.binQuantities).flatMap(
									({ binId, quantity }) => {
										// Only add a bin quantity if we have a quantity above zero.
										// Also there shouldn't be any without bin IDs.
										return !!binId && quantity > 0 ?
												[
													{
														quantity,
														binId,
														partOrderId:
															destination.partOrder?.id ?? null,
													},
												]
											:	[]
									}
								),
							]
						})
						return {
							lineId: line.lineItemId,
							binQuantities,
						}
					})
					// Don't include lines that ended up having no bin quantities.
					.filter((line) => line.binQuantities.length > 0)

				setIsReceiving(true)
				await partReceiveHandler(location.id, lines)

				if (shouldPrint) {
					const { data } = await makeLabels({
						labelSize,
						labels: Object.values(linePrintingState).flatMap((line) => {
							return line.quantity ?
									[
										{
											label: line.partNumber,
											quantity: line.quantity,
										},
									]
								:	[]
						}),
					})
					blobSaver(data as Blob, `received_items_${labelSize}.pdf`)
				}

				makeSuccessToast(`Line items received${shouldPrint ? ", labels created" : ""}`)
				onClose()
			} catch (e) {
				setErrorText(makeApiErrorMessage(e))
				setIsReceiving(false)
			}
		}

		// When location changes, clear out bin selections, but not quantity.
		useChangeCallback(location, () => {
			setLineFormState((prev) => {
				return produce(prev, (draft) => {
					Object.keys(draft).forEach((lineId) => {
						Object.keys(draft[lineId].destinations).forEach((destinationId) => {
							Object.keys(
								draft[lineId].destinations[destinationId].binQuantities
							).forEach((binQtyId) => {
								draft[lineId].destinations[destinationId].binQuantities[
									binQtyId
								].binId = null
							})
						})
					})
				})
			})
		})

		// We need to wait for both bins and default bins, so whenever either changes, check
		// if we have both, and if we do, set the defaults.
		useChangeCallback(defaultBins, (newDefaultBins) => {
			if (!!newDefaultBins && !!bins) {
				setDefaultBins()
			}
		})
		useChangeCallback(bins, (newBins) => {
			if (!!newBins && !!defaultBins) {
				setDefaultBins()
			}
		})

		// Clear out the error message text whenever any of these update.
		useEffect(() => {
			setErrorText(null)
		}, [lineFormState, location, bins])

		return (
			<Modal
				title="Receive Items"
				{...rest}
				onClose={onClose}
				maxWidth="md"
				rightButtons={{
					buttonText: "Receive Items",
					onClick: onSubmit,
					variant: "primary-cta",
					isLoading: isReceiving,
				}}
				errorText={errorText}
				aboveFooterContent={
					<Box textAlign="right" mt={-1}>
						<Checkbox
							d="inline"
							value={shouldPrint}
							onChange={(newValue) => {
								setShouldPrint(newValue)
								if (newValue) {
									setLinePrintingState(
										makeLinePrintingState(receivingLines, lineFormState)
									)
								}
							}}
							label="Also download part number QR code labels for these items?"
							mb={0}
						/>
						<AnimatedEntrance show={shouldPrint}>
							<LabelSizeSelector
								value={labelSize}
								onChange={setLabelSize}
								label="Label size"
							/>
						</AnimatedEntrance>
					</Box>
				}
			>
				<Box display="flex" alignItems="center" gap={1}>
					<LocationSelector
						value={location}
						onChange={setLocation}
						maxWidth={28}
						label="Receiving location"
					/>
					{(locationBinsLoading || defaultBinsLoading) && (
						<LoadingSpinner fontSize="1rem" />
					)}
				</Box>
				{!!location && !!bins && bins.length === 0 && (
					<ErrorText mb={1}>Location has no bins</ErrorText>
				)}

				{/* Receiving to specific demands feature only makes sense if purchase order. */}
				{!!purchaseOrderId && (
					<Checkbox
						value={showDemands}
						onChange={handleChangeShowDemand}
						label="Receive to individual line item demands"
						mb={0}
					/>
				)}

				<table css={receiveTableStyle}>
					<thead>
						<tr>
							{shouldPrint && <th className="print-quantity">Print qty</th>}
							<th className="line-item">Line Item</th>
							<th className="ordered-quantity">
								Ordered
								<br />
								Qty
							</th>
							<th className="already-received">
								Already
								<br />
								Received
							</th>
							<th className="receive-quantity">
								Receive
								<br />
								Qty
							</th>
							<th className="bin">Bin</th>
						</tr>
					</thead>
					<tbody>
						{receivingLines
							.filter(
								(line) =>
									showFinishedLines ||
									line.alreadyReceived < line.orderedQuantity
							)
							.map((line) => {
								const lineState = lineFormState[line.lineItemId]

								if (!lineState) {
									console.error("Bad lineItemId")
									return null
								}

								// First we'll show the stock destination. This one exists whether or not we're
								// receiving to specific demands. Where it displays depends on whether we're
								// showing demands though.
								const stockDestination = Object.values(
									lineState.destinations
								).find((d) => !d.partOrder)

								if (!stockDestination) {
									console.error("No stock destination on line")
									return null
								}

								return (
									<Fragment key={line.lineItemId}>
										<ReceivePartsTableTr
											receivingLine={line}
											lineState={lineState}
											setLineFormState={setLineFormState}
											destinationId={stockDestination.destinationId}
											locationId={location?.id ?? null}
											showDemands={showDemands}
											showPrintColumn={shouldPrint}
											linePrintingState={linePrintingState}
											setLinePrintingState={setLinePrintingState}
											isSummaryRow
										/>

										{/* Now loop through the possible destinations to receive into. */}
										{showDemands &&
											Object.values(lineState.destinations).map(
												(destination, i) => {
													return (
														<ReceivePartsTableTr
															key={destination.destinationId}
															receivingLine={line}
															lineState={lineState}
															setLineFormState={setLineFormState}
															destinationId={
																destination.destinationId
															}
															locationId={location?.id ?? null}
															showDemands={showDemands}
															showPrintColumn={shouldPrint}
															lastDestinationRow={
																i ===
																Object.keys(lineState.destinations)
																	.length -
																	1
															}
														/>
													)
												}
											)}
									</Fragment>
								)
							})}
					</tbody>
				</table>

				{finishedLinesCount === receivingLines.length && !showFinishedLines && (
					<Paragraph color="secondary" small mb={1}>
						No line items to receive
					</Paragraph>
				)}

				{!!finishedLinesCount &&
					(showFinishedLines ?
						<Button
							icon="angle-up"
							onClick={() => setShowFinishedLines(false)}
							containerProps={{ mt: 1 }}
						>
							Hide finished line items
						</Button>
					:	<Button
							icon="angle-down"
							onClick={() => setShowFinishedLines(true)}
							containerProps={{ mt: 1 }}
						>
							Show {finishedLinesCount} finished line item
							{finishedLinesCount !== 1 && "s"}
						</Button>)}

				<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
			</Modal>
		)
	}
)

const receiveTableStyle = (theme: Theme) => css`
	width: 100%;
	td,
	th {
		padding-bottom: 0.5rem;
		min-width: 4rem; // Because part name is break anywhere, they could get really tiny otherwise.
	}
	th {
		text-align: left;
		font-size: 0.75rem;
		color: ${theme.palette.text.secondary};
		text-transform: uppercase;
		line-height: 1rem;
		vertical-align: bottom;
		padding-right: 1rem;
	}
	.receive-quantity {
		padding-right: 0.5rem;
		input {
			width: 4rem;
			&.warning {
				background: #fff8d0;
			}
		}
	}
	.print-quantity {
		padding-right: 0.5rem;
		input {
			width: 4rem;
		}
	}
	.bin {
		width: 17rem;
	}
	.line-item-summary-row {
		td {
			padding-top: 0.75rem;
			padding-bottom: 0.5rem;
			border-top: 1px solid #eee;
			vertical-align: top;
		}
	}
	.destination-row,
	.last-destination-row {
		td {
			vertical-align: top;
		}
	}
	.last-destination-row {
		td {
			padding-bottom: 1.75rem;
		}
	}
`

ReceivePartsModal.displayName = "ReceivePartsModal"

import { FC, useState } from "react"

import { makeApiErrorMessage, UserMinimal } from "@ncs/ncs-api"
import { ExtendableModalProps, Modal, Paragraph, TextInput, UserSelector } from "@ncs/web-legos"

import { ContractFormRecipient } from "./contract-utils"

export interface AddInternalRecipientModalProps extends ExtendableModalProps {
	onSave: (user: ContractFormRecipient) => Promise<void> | void
}

export const AddInternalRecipientModal: FC<AddInternalRecipientModalProps> = ({
	onSave,
	...rest
}) => {
	const [user, setUser] = useState<UserMinimal | null>(null)
	const [description, setDescription] = useState<string | null>(null)

	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const handleSave = async () => {
		try {
			if (!user) throw new Error("Please select a user")

			setIsSaving(true)
			// Even though we ultimately only send user ID for internal recipients, we still
			// need to pass out the name and email so they can be displayed if we're on the
			// Create Contract form.
			await onSave({
				description,
				email: user.email,
				name: user.name,
				userId: user.id.toString(),
			})
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const reset = () => {
		setUser(null)
		setDescription(null)
		setIsSaving(false)
		setErrorText(null)
	}

	return (
		<Modal
			{...rest}
			onOpen={reset}
			errorText={errorText}
			title="Add Internal Recipient"
			rightButtons={{
				buttonText: "Add Person",
				onClick: handleSave,
				isLoading: isSaving,
			}}
		>
			<Paragraph mb={1}>
				Search for any NCS user account that should get email alerts for this contract.
			</Paragraph>

			<UserSelector value={user} onChange={setUser} employeesOnly />

			<TextInput
				value={description}
				onChange={setDescription}
				label="Description / Role (optional)"
			/>
		</Modal>
	)
}

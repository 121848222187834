import { FC, useState } from "react"

import {
	CustomerMilestone,
	CustomerMilestoneStatusId,
	makeApiErrorMessage,
	useApproveMilestone,
	useRejectMilestone,
} from "@ncs/ncs-api"
import {
	extractNumber,
	formatCurrency,
	formatDate,
	formatNumber,
	formatPercentage,
} from "@ncs/ts-utils"
import {
	Box,
	Button,
	Divider,
	EmptyValueDash,
	ExtendableModalProps,
	GridContainer,
	GridItem,
	Icon,
	Label,
	LabeledData,
	Link,
	Modal,
	Paragraph,
	useToast,
} from "@ncs/web-legos"

export interface MilestoneModalProps extends ExtendableModalProps {
	milestone: CustomerMilestone | null
}

export const MilestoneModal: FC<MilestoneModalProps> = ({ milestone, ...rest }) => {
	const { makeSuccessToast } = useToast()
	const [errorText, setErrorText] = useState<string | null>(null)
	const [isSaving, setIsSaving] = useState<"convert" | "reject" | null>(null)

	const reject = useRejectMilestone()
	const approve = useApproveMilestone()

	const handleReject = async () => {
		try {
			if (!milestone) throw new Error("Milestone is null, please contact support.")

			setIsSaving("reject")
			await reject({ id: milestone.id })
			makeSuccessToast("Milestone rejected")
			rest.onClose()
		} catch (e) {
			setErrorText(makeApiErrorMessage(e))
			setIsSaving(null)
		}
	}

	const handleConvert = async () => {
		try {
			if (!milestone) throw new Error("Milestone is null, please contact support.")

			setIsSaving("convert")
			await approve({
				id: milestone.id,
				averageWashPrice: extractNumber(milestone.averageWashPrice),
				dailyAverageWashcount: milestone.dailyAverageWashcount,
				appliedDiscountPercent: extractNumber(milestone.appliedDiscountPercent),
			})
			makeSuccessToast("Milestone converted to ERP")
			rest.onClose()
		} catch (e) {
			setErrorText(makeApiErrorMessage(e))
			setIsSaving(null)
		}
	}

	const reset = () => {
		setErrorText(null)
		setIsSaving(null)
	}

	if (!milestone) {
		return null
	}

	return (
		<Modal
			title={milestone.milestone.description}
			onOpen={reset}
			{...rest}
			errorText={errorText}
		>
			<Box display="flex" justifyContent="flex-end" mb={2}>
				<Link
					to={`/machine-milestone/milestone/${milestone.milestone.id}`}
					icon="external-link"
					underline
					newTab
				>
					View milestone details
				</Link>
			</Box>

			<GridContainer rowGap={0}>
				<GridItem xs={12} sm={6}>
					<LabeledData label="Wash counts">
						{formatNumber(milestone.milestone.washCount)}
					</LabeledData>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<LabeledData label="Age of machine (months)">
						{milestone.milestone.ageInMonths ?
							formatNumber(milestone.milestone.ageInMonths)
						:	<EmptyValueDash />}
					</LabeledData>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<Label>Milestone active?</Label>
					{milestone.milestone.isActive ?
						<Box display="flex" alignItems="center" columnGap={0.5}>
							<Icon icon="check" />
							<Paragraph>Active</Paragraph>
						</Box>
					:	<EmptyValueDash />}
				</GridItem>
				<GridItem xs={12} sm={6}>
					<LabeledData label="Discount percent">
						{formatPercentage(extractNumber(milestone.milestone.discountPercent))}
						{/* If this becomes editable, the max discount is the max, depending on auth. */}
					</LabeledData>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<LabeledData label="Daily average wash count">
						{milestone.dailyAverageWashcount != null ?
							formatNumber(milestone.dailyAverageWashcount)
						:	<EmptyValueDash />}
					</LabeledData>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<LabeledData label="Average wash price">
						{formatCurrency(extractNumber(milestone.averageWashPrice))}
					</LabeledData>
				</GridItem>
			</GridContainer>

			<Divider mt={2} mb={1} />

			<Paragraph small color="secondary" mb={1}>
				Status: {milestone.status.description}{" "}
				{milestone.status.id === CustomerMilestoneStatusId.Created && (
					<>on {formatDate(milestone.identifiedOn)}.</>
				)}
				{milestone.status.id === CustomerMilestoneStatusId.Converted &&
					!!milestone.approvedOn && <>to quote on {formatDate(milestone.approvedOn)}.</>}
				{milestone.status.id === CustomerMilestoneStatusId.Rejected &&
					!!milestone.rejectedOn && <>on {formatDate(milestone.rejectedOn)}.</>}
			</Paragraph>

			{milestone.status.id === CustomerMilestoneStatusId.Converted && (
				<Link
					to={`/quotes/${milestone.milestoneErp?.id}`}
					icon="external-link"
					underline
					newTab
				>
					View created quote
				</Link>
			)}

			{milestone.status.id === CustomerMilestoneStatusId.Created && (
				<GridContainer mt={2}>
					<GridItem xs={12} sm={6}>
						<Button
							variant="secondary-cta"
							isLoading={isSaving === "reject"}
							disabled={isSaving != null}
							onClick={handleReject}
							fillContainer
						>
							Reject
						</Button>
					</GridItem>
					<GridItem xs={12} sm={6}>
						<Button
							variant="primary-cta"
							isLoading={isSaving === "convert"}
							disabled={isSaving != null}
							onClick={handleConvert}
							fillContainer
						>
							Convert to Quote
						</Button>
					</GridItem>
				</GridContainer>
			)}
		</Modal>
	)
}

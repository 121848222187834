import { FC, Fragment } from "react"

import { PartOrderLineItem } from "@ncs/ncs-api"
import { formatDate, formatNumber } from "@ncs/ts-utils"
import {
	Box,
	Divider,
	EmptyValueDash,
	ExtendableModalProps,
	GridContainer,
	GridItem,
	LabeledData,
	Link,
	Modal,
} from "@ncs/web-legos"

export interface PartOrderLineTransitDetailsModalProps extends ExtendableModalProps {
	lineItem: PartOrderLineItem
}

export const PartOrderLineTransitDetailsModal: FC<PartOrderLineTransitDetailsModalProps> = ({
	lineItem,
	...rest
}) => {
	return (
		<Modal
			{...rest}
			title={`Transit Details: ${lineItem.part?.description || lineItem.description}`}
		>
			{lineItem.inTransits.map((t, i) => {
				return (
					<Fragment key={Object.values(t).join("")}>
						{i !== 0 && <Divider />}

						<Box mb={1}>
							<GridContainer>
								<GridItem sm={6} md={3}>
									<LabeledData label="Quantity">
										{formatNumber(t.quantityInTransit)}
									</LabeledData>
								</GridItem>
								<GridItem sm={6} md={3}>
									<LabeledData label="LTL carrier">
										{t.ltlCarrier || <EmptyValueDash />}
									</LabeledData>
								</GridItem>
								<GridItem sm={6} md={3}>
									<LabeledData label="Date">
										{t.shipDate ?
											formatDate(t.shipDate, { formatInUtc: true })
										:	<EmptyValueDash />}
									</LabeledData>
								</GridItem>
								<GridItem sm={6} md={3}>
									<LabeledData label="Tracking #">
										{t.trackingNumber || <EmptyValueDash />}
									</LabeledData>
								</GridItem>
							</GridContainer>

							{t.trackingLink && (
								<Link to={t.trackingLink} newTab icon="external-link" external>
									{t.trackingLink}
								</Link>
							)}
						</Box>
					</Fragment>
				)
			})}
		</Modal>
	)
}

import React from "react"

import withStyles from "@material-ui/core/styles/withStyles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import moment from "moment"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { bindActionCreators } from "redux"

import { getUserSchedule, saveTechHours } from "@ncs/mortar/redux/services/users"
import callApi from "@ncs/bricks/redux/services/callApi"

import {
	Alert,
	Button,
	ButtonWithIcon,
	ConditionalContent,
	GridContainer,
	GridItem,
	InputAsDisplay,
	LoadingWrapper,
	NumericInput,
	QuickCard,
	TerritorySelector,
} from "~/components"

import Save from "@material-ui/icons/Save"
import Schedule from "@material-ui/icons/Schedule"

const styles = {
	actualHours: {
		fontAlign: "center",
	},
}

class UserSchedule extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isLoading: false,
			isSaving: false,
			selectedTerritory: null,
			isEditing: false,
			dateSelected: moment(),
			dates: [],
			techs: [],
			nextDate: moment(),
			editingConfirm: false,
		}
	}

	handleSaveHours = () => {
		this.setState({ isSaving: true })
		this.props.callApi(saveTechHours({ technicians: this.state.techs })).then((result) => {
			if (result.error) {
				this.setState({ isSaving: false })
				console.error(result.error)
				return
			}
			this.setState({
				isSaving: false,
				selectedTerritory: null,
				isEditing: false,
				dateSelected: moment(),
				dates: [],
				techs: [],
			})
		})
	}

	handleHoursChange = (tech, td) => (change) => {
		let techs = this.state.techs.map((t) => {
			if (t.tech_id !== tech.tech_id) {
				return { ...t }
			}
			return {
				...t,
				dates: t.dates.map((d) => {
					if (d.date !== td.date) {
						return { ...d }
					} else {
						return {
							...d,
							hours: change.floatValue,
						}
					}
				}),
			}
		})
		this.setState({ techs, isEditing: true })
	}

	getTechInfo = (territorySelected, date) => {
		let params = {
			date: date.format(),
			territory: territorySelected,
		}
		this.setState({ isLoading: true })
		this.props.callApi(getUserSchedule(params)).then((result) => {
			if (result.error) {
				this.setState({ isLoading: false })
				console.error(result.error)
				return
			}
			this.setState({
				isLoading: false,
				dates: result.payload.dates,
				techs: result.payload.technicians,
				isEditing: false,
				nextDate: false,
			})
		})
	}

	handleConfirmDateChange = () => {
		this.setState({
			dateSelected: this.state.nextDate,
			editingConfirm: false,
			isEditing: false,
		})
		this.getTechInfo(this.state.selectedTerritory, this.state.nextDate)
	}

	handleCancelUpdateDate = () => {
		this.setState({ editingConfirm: false })
	}

	handleThisWeek = () => {
		if (this.state.isEditing === true) {
			this.setState({ nextDate: moment(), editingConfirm: true })
		} else {
			this.setState({ dateSelected: moment() })
			this.getTechInfo(this.state.selectedTerritory, moment())
		}
	}

	handleNextWeek = () => {
		let newTime = this.state.dateSelected.add(7, "days")
		if (this.state.isEditing === true) {
			this.setState({ nextDate: newTime, editingConfirm: true })
		} else {
			this.setState({ dateSelected: newTime })
			this.getTechInfo(this.state.selectedTerritory, newTime)
		}
	}

	handlePreviousWeek = () => {
		let newTime = this.state.dateSelected.subtract(7, "days")
		if (this.state.isEditing === true) {
			this.setState({ nextDate: newTime, editingConfirm: true })
		} else {
			this.setState({ dateSelected: newTime })
			this.getTechInfo(this.state.selectedTerritory, newTime)
		}
	}

	handleTerritoryChange = (selectedTerritory) => {
		this.setState({ selectedTerritory })

		this.getTechInfo(selectedTerritory, this.state.dateSelected)
	}

	render() {
		const { isLoading, selectedTerritory, dates, techs, isEditing, isSaving, editingConfirm } =
			this.state

		return (
			<React.Fragment>
				<LoadingWrapper isLoading={isLoading} />

				<QuickCard title={"Tech Schedule"} icon={<Schedule />}>
					<GridContainer>
						<GridItem xs={12}>
							<TerritorySelector
								onChange={this.handleTerritoryChange}
								territory={selectedTerritory}
							/>
						</GridItem>
						<ConditionalContent show={techs.length > 0}>
							<ConditionalContent show={dates.length > 0}>
								<GridItem xs={12} sm={8} align="left">
									<Button
										content="Previous Week"
										onClick={this.handlePreviousWeek}
										color="primary"
										size="sm"
										round
									/>
									<Button
										content="Current Week"
										onClick={this.handleThisWeek}
										color="primary"
										size="sm"
										round
									/>
									<Button
										content="Next Week"
										onClick={this.handleNextWeek}
										color="primary"
										size="sm"
										round
									/>
								</GridItem>
								<ConditionalContent show={isEditing}>
									<GridItem xs={12} sm={4} align="right">
										<ButtonWithIcon
											content="Save"
											onClick={this.handleSaveHours}
											color="success"
											size="sm"
											round
											disabled={isSaving}
											loading={isSaving}
											icon={<Save />}
										/>
									</GridItem>
								</ConditionalContent>
							</ConditionalContent>
							<GridItem xs={12}>
								<Table>
									<TableHead>
										<TableCell>{""}</TableCell>
										{dates.map((x, i) => (
											<TableCell key={`date_${x}`} id={`date_${x}`}>
												{x}
											</TableCell>
										))}
										<TableCell>{"Total"}</TableCell>
									</TableHead>
									<TableBody>
										{techs.map((tech, i) => (
											<TableRow
												key={`tech_${tech.name}`}
												id={`tech_${tech.name}`}
											>
												<TableCell>{tech.name}</TableCell>
												{tech.dates.map((td, i) => (
													<TableCell
														key={`hours_${i}_${tech.name}`}
														id={`hours_${i}_${tech.name}`}
													>
														<GridContainer justify="space-between">
															<GridItem>
																<NumericInput
																	onValueChange={this.handleHoursChange(
																		tech,
																		td
																	)}
																	value={td.hours}
																/>
															</GridItem>
															<GridItem>
																{`Actual: ${
																	td.actual_hours ?
																		td.actual_hours
																	:	0
																}`}
															</GridItem>
														</GridContainer>
													</TableCell>
												))}
												<TableCell>
													<GridContainer>
														<GridItem>
															<InputAsDisplay
																labelText={""}
																id={`ttl_${tech.name}`}
																value={`${
																	Math.round(
																		tech.dates.reduce(
																			(a, b) =>
																				a + (b.hours || 0),
																			0
																		) * 10
																	) / 10
																}`}
															/>
														</GridItem>
														<GridItem>
															{`Actual: ${
																Math.round(
																	tech.dates.reduce(
																		(a, b) =>
																			a +
																			(b.actual_hours || 0),
																		0
																	) * 10
																) / 10
															}`}
														</GridItem>
													</GridContainer>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</GridItem>
						</ConditionalContent>
					</GridContainer>
				</QuickCard>
				<Alert
					warning
					show={editingConfirm}
					style={{ display: "block" }}
					title="Unsaved Changes Found."
					onCancel={this.handleCancelUpdateDate}
					cancelBtnText="Cancel"
					cancelBtnColor="warning"
					onConfirm={this.handleConfirmDateChange}
					confirmBtnText="Continue"
				>
					{"Currently editing. Continue will lose changes. Do you wish to continue"}
				</Alert>
			</React.Fragment>
		)
	}
}

const mapStateToProps = (state) => {
	return {}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			callApi,
		},
		dispatch
	)
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(withStyles(styles)(UserSchedule)))

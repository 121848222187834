import { FC, useState } from "react"

import { createDocumentToken, getDocumentUrlForToken, makeApiErrorMessage } from "@ncs/ncs-api"

import { useToast } from "../../contexts/toast"
import { Button, ButtonProps } from "./Button"

export interface DownloadDocumentButtonProps {
	documentId: string | number
	documentType: number
	buttonVariant?: ButtonProps["variant"]
	buttonText?: ButtonProps["buttonText"]
	icon?: ButtonProps["icon"]
	disabled?: ButtonProps["disabled"]
	containerProps?: ButtonProps["containerProps"]
}

export const DownloadDocumentButton: FC<DownloadDocumentButtonProps> = ({
	documentId,
	documentType,
	buttonVariant,
	buttonText = "Download",
	icon = "download",
	disabled,
	containerProps,
}) => {
	const { makeErrorToast } = useToast()
	const [isLoading, setIsLoading] = useState(false)

	const handleClick = async () => {
		try {
			setIsLoading(true)
			const { token } = await createDocumentToken(documentId, documentType)
			const url = getDocumentUrlForToken(token)
			window.open(url)
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		} finally {
			setIsLoading(false)
		}
	}

	return (
		<Button
			variant={buttonVariant}
			buttonText={buttonText}
			icon={icon}
			disabled={disabled}
			containerProps={containerProps}
			isLoading={isLoading}
			onClick={handleClick}
		/>
	)
}

import { FC, useState } from "react"

import { Column } from "react-table"

import {
	Dispatch,
	DispatchCallLog,
	makeApiErrorMessage,
	UpdateDispatchPatch,
	useCustomer,
	useDispatchCallLogs,
	useUpdateDispatch,
	useUpdateWorkOrderInvoice,
} from "@ncs/ncs-api"
import {
	displayDateTime,
	extractNumber,
	formatCurrency,
	formatDateTime,
	formatPhone,
	getTimezoneAbbreviation,
	yesOrNo,
} from "@ncs/ts-utils"
import {
	Box,
	Button,
	cssMixins,
	EditNumberModal,
	EditNumberModalProps,
	EditPhoneModal,
	EditPhoneModalProps,
	EditStringModal,
	EditStringModalProps,
	EditBooleanModal,
	EditBooleanModalProps,
	EmptyValueDash,
	getAddressFields,
	GridContainer,
	GridItem,
	HeadingDivider,
	HeadingDividerProps,
	IconButton,
	LabeledData,
	LabeledDataWithButton,
	Paragraph,
	ParagraphList,
	Table,
	useToast,
} from "@ncs/web-legos"

import { BillToSelectorModal } from "~/components"

export interface SummaryTabProps {
	dispatch: Dispatch
}

export const SummaryTab: FC<SummaryTabProps> = ({ dispatch }) => {
	const { makeSuccessToast, makeErrorToast } = useToast()
	const [showChangeBillToModal, setShowChangeBillToModal] = useState(false)
	const [editStringModalConfig, setEditStringModalConfig] =
		useState<EditStringModalProps | null>(null)
	const [editNumberModalConfig, setEditNumberModalConfig] =
		useState<EditNumberModalProps | null>(null)
	const [editPhoneModalConfig, setEditPhoneModalConfig] = useState<EditPhoneModalProps | null>(
		null
	)
	const [editBooleanModalConfig, setEditBooleanModalConfig] =
		useState<EditBooleanModalProps | null>(null)

	const [shipToDetails, shipToDetailsLoading] = useCustomer(dispatch.customer.id)
	const [billToDetails, billToDetailsLoading] = useCustomer(dispatch.billToCustomer?.id)
	const [callLogs, callLogsLoading] = useDispatchCallLogs(dispatch.id)
	const updateDispatch = useUpdateDispatch()
	const updateInvoice = useUpdateWorkOrderInvoice()

	const handleSave = async <K extends keyof UpdateDispatchPatch>(
		key: K,
		value: UpdateDispatchPatch[K]
	) => {
		try {
			await updateDispatch({
				id: dispatch.id,
				updates: {
					[key]: value,
				},
			})
			// If we updated the bill to, then we also need to update the invoice to have the same.
			if (key === "billToCustomerId" && dispatch.invoice?.id) {
				await updateInvoice({
					id: dispatch.invoice.id,
					updates: { [key]: value },
				})
			}
			makeSuccessToast("Dispatch updated")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		}
	}

	const editFieldInModal = <K extends ModalEditableKeys>(key: K) => {
		const baseProps = {
			onClose: () => {
				setEditPhoneModalConfig(null)
				setEditStringModalConfig(null)
				setEditNumberModalConfig(null)
				setEditBooleanModalConfig(null)
			},
		}

		switch (key) {
			case "symptoms": {
				setEditStringModalConfig({
					...baseProps,
					title: "Edit Description",
					initialValue: dispatch.symptoms,
					allowEmpty: true,
					maxLength: 1000,
					textarea: true,
					textareaProps: {
						rows: 15,
					},
					label: "Symptoms",
					onSave: async (value) => {
						await handleSave("symptoms", value)
					},
				})
				break
			}
			case "callBackNumber": {
				setEditPhoneModalConfig({
					...baseProps,
					title: "Edit Callback Number",
					initialValue: dispatch.callBackNumber ?? undefined,
					allowEmpty: true,
					onSave: async (value) => {
						await handleSave("callBackNumber", value)
					},
				})
				break
			}
			case "altCallBackNumber": {
				setEditPhoneModalConfig({
					...baseProps,
					title: "Edit Alternate Callback Number",
					initialValue: dispatch.altCallBackNumber ?? undefined,
					allowEmpty: true,
					onSave: async (value) => {
						await handleSave("altCallBackNumber", value)
					},
				})
				break
			}
			case "poTicket": {
				setEditStringModalConfig({
					...baseProps,
					title: "Edit Purchase Order / Ticket Number",
					initialValue: dispatch.poTicket ?? undefined,
					label: "PO / Ticket #",
					allowEmpty: true,
					maxLength: 250,
					onSave: async (value) => {
						await handleSave("poTicket", value)
					},
				})
				break
			}
			case "billNotToExceed": {
				setEditNumberModalConfig({
					...baseProps,
					title: "Edit Bill Not To Exceed",
					initialValue:
						dispatch.billNotToExceed != null ?
							extractNumber(dispatch.billNotToExceed)
						:	null,
					label: "Not to exceed $",
					numericInputProps: {
						fixedDecimalScale: true,
						decimalScale: 2,
					},
					onSave: async (value) => {
						await handleSave("billNotToExceed", value)
					},
				})
				break
			}
			case "nuisance": {
				setEditBooleanModalConfig({
					...baseProps,
					title: "Edit Nuisance",
					onSave: async (value) => {
						await handleSave("nuisance", value)
					},
				})
			}
		}
	}

	const headingProps: HeadingDividerProps = {
		headingVariant: "h5",
		mb: 1,
		mt: 0,
	}

	return (
		<>
			<Box d="flex" flexDirection="column" rowGap={3}>
				<div>
					<HeadingDivider {...headingProps}>Description</HeadingDivider>
					<Paragraph
						maxWidth="50%"
						smProps={{ maxWidth: "none" }}
						css={cssMixins.preserveLineBreaks}
					>
						{dispatch.symptoms || <EmptyValueDash />}
						<IconButton onClick={() => editFieldInModal("symptoms")} />
					</Paragraph>
				</div>
				<div>
					<HeadingDivider {...headingProps}>Call Details</HeadingDivider>
					<GridContainer>
						<GridItem xs={12} sm={6} md={3}>
							<LabeledData label="Call received">
								{displayDateTime(dispatch.callReceivedDate, null) || (
									<EmptyValueDash />
								)}
							</LabeledData>
						</GridItem>
						<GridItem xs={12} sm={6} md={3}>
							<LabeledData label="Caller name">
								{dispatch.callerName || <EmptyValueDash />}
							</LabeledData>
						</GridItem>
						<GridItem xs={12} sm={6} md={3}>
							<LabeledDataWithButton
								label="Callback #"
								onClick={() => editFieldInModal("callBackNumber")}
							>
								{dispatch.callBackNumber ?
									formatPhone(dispatch.callBackNumber)
								:	<EmptyValueDash />}
							</LabeledDataWithButton>
						</GridItem>
						<GridItem xs={12} sm={6} md={3}>
							<LabeledDataWithButton
								label="Alt callback #"
								onClick={() => editFieldInModal("altCallBackNumber")}
							>
								{dispatch.altCallBackNumber ?
									formatPhone(dispatch.altCallBackNumber)
								:	<EmptyValueDash />}
							</LabeledDataWithButton>
						</GridItem>
						<GridItem xs={12} sm={6} md={3}>
							<LabeledDataWithButton
								label="PO / Ticket #"
								onClick={() => editFieldInModal("poTicket")}
							>
								{dispatch.poTicket || <EmptyValueDash />}
							</LabeledDataWithButton>
						</GridItem>
						<GridItem xs={12} sm={6} md={3}>
							<LabeledDataWithButton
								label="Not to exceed"
								onClick={() => editFieldInModal("billNotToExceed")}
							>
								{dispatch.billNotToExceed != null ?
									formatCurrency(dispatch.billNotToExceed)
								:	<EmptyValueDash />}
							</LabeledDataWithButton>
						</GridItem>
						<GridItem xs={12} sm={6} md={3}>
							<LabeledDataWithButton
								label="Nuisance?"
								onClick={() => editFieldInModal("nuisance")}
							>
								{yesOrNo(dispatch.nuisance)}
							</LabeledDataWithButton>
						</GridItem>
					</GridContainer>
				</div>
				<GridContainer>
					<GridItem sm={12} md={6}>
						<HeadingDivider {...headingProps}>Ship-To Customer</HeadingDivider>
						<Box d="flex" flexDirection="column" rowGap={1}>
							<div>
								<ParagraphList
									lines={[
										`(${dispatch.customer.customerNumber}) ${dispatch.customer.name}`,
										...getAddressFields(shipToDetails, { exclude: "name" }),
									]}
									isLoading={shipToDetailsLoading}
								/>
								{!!dispatch.customer.territory && (
									<Paragraph small>
										Terr: ({dispatch.customer.territory.code}){" "}
										{dispatch.customer.territory.description}
									</Paragraph>
								)}
							</div>
							<LabeledData label="AR status" mb={0}>
								{shipToDetails?.creditCheckStatus || <EmptyValueDash />}
							</LabeledData>
							<LabeledData label="Contract types" mb={0}>
								{shipToDetails?.contracts.map((c) => c.type).join(", ") || (
									<EmptyValueDash />
								)}
							</LabeledData>
						</Box>
					</GridItem>

					<GridItem sm={12} md={6}>
						<HeadingDivider
							{...headingProps}
							renderRight={() => (
								<Button
									icon="pencil"
									onClick={() => setShowChangeBillToModal(true)}
									disabled={!shipToDetails?.billableTo?.length}
								>
									Change bill-to
								</Button>
							)}
						>
							Bill-To Customer
						</HeadingDivider>
						<Box d="flex" flexDirection="column" rowGap={1}>
							<div>
								<ParagraphList
									lines={[
										`(${billToDetails?.customerNumber}) ${billToDetails?.name}`,
										...getAddressFields(billToDetails, { exclude: "name" }),
									]}
									isLoading={billToDetailsLoading}
								/>
								{!!billToDetails?.territory && (
									<Paragraph small>
										Terr: ({billToDetails.territory.code}){" "}
										{billToDetails.territory.description}
									</Paragraph>
								)}
							</div>
							<LabeledData label="AR status" mb={0}>
								{billToDetails?.creditCheckStatus || <EmptyValueDash />}
							</LabeledData>
							<LabeledData label="Contract types" mb={0}>
								{billToDetails?.contracts.map((c) => c.type).join(", ") || (
									<EmptyValueDash />
								)}
							</LabeledData>
						</Box>
					</GridItem>
				</GridContainer>

				<div>
					<HeadingDivider {...headingProps}>Customer Call Logs</HeadingDivider>
					{!!callLogs?.length && (
						<Table data={callLogs ?? []} columns={callLogsColumns} />
					)}
					{!callLogs?.length && !callLogsLoading && (
						<Paragraph small secondary>
							No calls recorded
						</Paragraph>
					)}
				</div>
			</Box>

			{showChangeBillToModal && (
				<BillToSelectorModal
					customerId={dispatch.customer.id}
					currentId={dispatch.billToCustomer?.id}
					onSave={async (billToId) => {
						if (billToId) await handleSave("billToCustomerId", billToId)
					}}
					onClose={() => setShowChangeBillToModal(false)}
				/>
			)}
			{!!editStringModalConfig && <EditStringModal {...editStringModalConfig} />}
			{!!editPhoneModalConfig && <EditPhoneModal {...editPhoneModalConfig} />}
			{!!editNumberModalConfig && <EditNumberModal {...editNumberModalConfig} />}
			{!!editBooleanModalConfig && <EditBooleanModal {...editBooleanModalConfig} />}
		</>
	)
}

const callLogsColumns: Column<DispatchCallLog>[] = [
	{
		Header: `Contacted (${getTimezoneAbbreviation()})`,
		accessor: ({ contactTimestamp }) => formatDateTime(contactTimestamp),
	},
	{
		Header: "Employee",
		accessor: ({ contactedBy }) => contactedBy.name,
	},
	{
		Header: "Method",
		accessor: "contactMethod",
	},
	{
		Header: "ETA",
		accessor: ({ eta }) => displayDateTime(eta),
	},
	{
		Header: "Comments",
		accessor: ({ comments }) => comments || <EmptyValueDash />,
	},
	{
		Header: "Resolved?",
		accessor: ({ isResolved }) => yesOrNo(isResolved),
	},
]

type ModalEditableKeys = keyof Pick<
	UpdateDispatchPatch,
	| "symptoms"
	| "callBackNumber"
	| "altCallBackNumber"
	| "poTicket"
	| "billNotToExceed"
	| "nuisance"
>

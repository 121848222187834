import { FC } from "react"

import { Column } from "react-table"

import {
	LaborRatesReportLine,
	LaborRatesReportQueryParams,
	useExportLaborRatesReport,
	useLaborRatesReport,
} from "@ncs/ncs-api"
import { formatCurrency, Typify } from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	CustomerQueryFilter,
	EnterpriseQueryFilter,
	OrganizationQueryFilter,
	RegionQueryFilter,
	Table,
	TerritoryQueryFilter,
	usePaginationUrlState,
	useUrlState,
} from "@ncs/web-legos"

export type LaborRatesReportUrlState = Typify<LaborRatesReportQueryParams>

export const LaborRatesReport: FC = () => {
	const [queryParams, { setUrlState: setQueryParams }] = useUrlState<LaborRatesReportUrlState>(
		initialLaborRatesReportUrlState
	)
	const [pagination, setPagination] = usePaginationUrlState()

	const query = useLaborRatesReport({
		params: { ...queryParams, ...pagination },
		manualPagination: true,
	})
	const dataExport = useExportLaborRatesReport(queryParams)

	return (
		<AnimatedEntrance show>
			<Table
				query={query}
				columns={columns}
				queryParamState={queryParams}
				setQueryParamState={setQueryParams}
				pagination={pagination}
				setPagination={setPagination}
				dataExport={dataExport}
				pinnedQueryFilters={[
					CustomerQueryFilter,
					TerritoryQueryFilter,
					OrganizationQueryFilter,
					EnterpriseQueryFilter,
				]}
				toggledQueryFilters={[RegionQueryFilter]}
				storeColumnVisibility="labor-rates-report-columns"
			/>
		</AnimatedEntrance>
	)
}

const columns: Column<LaborRatesReportLine>[] = [
	{
		Header: "Customer #",
		id: "customerNumber",
		accessor: "customerNumber",
	},
	{
		Header: "Customer",
		disableSortBy: true,
		accessor: "name",
	},
	{
		Header: "Territory",
		disableSortBy: true,
		accessor: ({ territory }) =>
			territory ? `(${territory.code}) ${territory.description}` : "-",
		hiddenByDefault: true,
	},
	{
		Header: "Labor rate",
		disableSortBy: true,
		accessor: ({ labor }) => formatCurrency(labor.rate, 0),
	},
	{
		Header: "Labor group",
		disableSortBy: true,
		hiddenByDefault: true,
		accessor: ({ labor }) => labor.group,
	},
	{
		Header: "Flat rate",
		disableSortBy: true,
		accessor: ({ flat }) => formatCurrency(flat.rate, 0),
	},
	{
		Header: "Flat group",
		disableSortBy: true,
		hiddenByDefault: true,
		accessor: ({ flat }) => flat.group,
	},
]

const initialLaborRatesReportUrlState: LaborRatesReportUrlState = {
	ordering: null,
	organization: null,
	enterprise: null,
	territory: null,
	region: null,
	customer: null,
}

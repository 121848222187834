import { Dispatch, ReactElement, SetStateAction, useEffect, useState } from "react"

import { Vendor } from "@ncs/ncs-api"

import { usePrevious } from "../../util"
import { VendorSelector } from "../selectors"

interface VendorQueryParams {
	vendor: number | null
}

export interface VendorQueryFilterProps<QueryParamState extends VendorQueryParams> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

export const VendorQueryFilter = <QueryParamState extends VendorQueryParams>({
	queryParamState,
	setQueryParamState,
}: VendorQueryFilterProps<QueryParamState>): ReactElement => {
	const [selectedVendor, setSelectedVendor] = useState<Vendor | null>(null)

	const handleChange = (newVendor: Vendor | null) => {
		setSelectedVendor(newVendor)
		setQueryParamState((prev) => {
			const newVendorParam: VendorQueryParams = {
				vendor: newVendor?.id ?? null,
			}

			return {
				...prev,
				...newVendorParam,
			}
		})
	}

	const prevVendorFromQueryParams = usePrevious(queryParamState.vendor)
	useEffect(() => {
		// Handles the user clicking Reset button.
		if (
			queryParamState.vendor === null &&
			prevVendorFromQueryParams !== null &&
			selectedVendor !== null
		) {
			setSelectedVendor(null)
		}
	}, [prevVendorFromQueryParams, queryParamState.vendor, selectedVendor])

	return (
		<VendorSelector
			value={selectedVendor}
			onChange={handleChange}
			initialId={queryParamState.vendor != null ? String(queryParamState.vendor) : undefined}
			fillContainer
		/>
	)
}

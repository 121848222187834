import { FC, useMemo, useState } from "react"

import dayjs from "dayjs"

import {
	Dispatch,
	InvoiceStatusId,
	makeApiErrorMessage,
	UpdateWorkOrderInvoicePatch,
	useApproveInvoice,
	useFinalizeInvoice,
	useRejectInvoice,
	useUpdateWorkOrderInvoice,
	useWorkOrderInvoice,
	useWorkOrderInvoiceLineItems,
} from "@ncs/ncs-api"
import { displayDateTime } from "@ncs/ts-utils"
import {
	Box,
	Button,
	ConfirmationModal,
	ConfirmationModalConfig,
	cssMixins,
	DateTimeInputButton,
	EditStringModal,
	EditStringModalProps,
	EmptyValueDash,
	ErrorText,
	GridContainer,
	GridItem,
	GridItemProps,
	HeadingDivider,
	IconButton,
	Label,
	LabeledData,
	LoadingSpinner,
	Paragraph,
	useToast,
} from "@ncs/web-legos"

import {
	allowInvoiceApproval,
	allowInvoiceFinalize,
	allowInvoiceRejection,
	invoiceWasRejected,
	validateBillToTotal,
	validateInvoiceLineItemsTotal,
	validateInvoiceTotal,
} from "~/util"

import { CreditMemos, InvoiceLineItems } from "./components"

export interface InvoiceTabProps {
	dispatch: Dispatch
}

export const InvoiceTab: FC<InvoiceTabProps> = ({ dispatch }) => {
	const { makeSuccessToast, makeErrorToast } = useToast()
	const [editStringModalConfig, setEditStringModalConfig] =
		useState<EditStringModalProps | null>(null)
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)

	const [invoice, invoiceLoading] = useWorkOrderInvoice(dispatch.invoice?.id)
	const [lineItems] = useWorkOrderInvoiceLineItems(dispatch.invoice?.id)
	const updateInvoice = useUpdateWorkOrderInvoice()
	const approveInvoice = useApproveInvoice()
	const rejectInvoice = useRejectInvoice()
	const finalizeInvoice = useFinalizeInvoice()

	const handleSave = async <K extends keyof UpdateWorkOrderInvoicePatch>(
		key: K,
		value: UpdateWorkOrderInvoicePatch[K]
	) => {
		if (invoice) {
			try {
				await updateInvoice({
					id: invoice.id,
					updates: {
						[key]: value,
					},
				})
				makeSuccessToast("Invoice updated")
			} catch (e) {
				makeErrorToast(makeApiErrorMessage(e))
			}
		}
	}

	const handleApprovalDecision = (invoiceId: string, isApprove: boolean) => {
		if (isApprove) {
			setConfirmationConfig({
				title: "Approve Invoice",
				message: "Confirm: Approve this invoice?",
				onConfirm: async () => {
					try {
						await approveInvoice({ id: invoiceId })
						makeSuccessToast("Invoice approved")
					} catch (e) {
						makeErrorToast(makeApiErrorMessage(e))
					}
				},
			})
		} else {
			setEditStringModalConfig({
				title: "Reject Invoice",
				message: "Confirm: Reject this invoice? Enter any comments below.",
				allowEmpty: true,
				label: "Comments (optional)",
				onSave: async (comment) => {
					try {
						await rejectInvoice({ id: invoiceId, comment })
						makeSuccessToast("Invoice rejected")
					} catch (e) {
						makeErrorToast(makeApiErrorMessage(e))
					}
				},
				onClose: () => setEditStringModalConfig(null),
			})
		}
	}

	const handleFinalize = (invoiceId: string) => {
		setConfirmationConfig({
			title: "Finalize Invoice",
			message: "Confirm: Finalize this invoice?",
			onConfirm: async () => {
				try {
					await finalizeInvoice({ id: invoiceId })
					makeSuccessToast("Invoice finalized")
				} catch (e) {
					makeErrorToast(makeApiErrorMessage(e))
				}
			},
		})
	}

	const canApprove = useMemo(() => {
		if (!invoice) return false
		return allowInvoiceApproval(invoice)
	}, [invoice])

	const canReject = useMemo(() => {
		if (!invoice) return false
		return allowInvoiceRejection(invoice)
	}, [invoice])

	const canFinalize = useMemo(() => {
		if (!invoice) return false
		return allowInvoiceFinalize(invoice)
	}, [invoice])

	const wasRejected = useMemo(() => {
		if (!invoice) return false
		return invoiceWasRejected(invoice)
	}, [invoice])

	const invoiceValidityError = useMemo((): string | null => {
		if (!invoice || !lineItems) return null

		if (validateBillToTotal(invoice) === false) {
			return "Invoice billed internally with dollar amount"
		}
		if (validateInvoiceTotal(invoice) === false) {
			return "Invoice's total does not equal its subtotal plus its tax"
		}
		if (validateInvoiceLineItemsTotal(invoice, lineItems) === false) {
			return "Invoice's total does not equal the sum of its line items' totals"
		}
		if (
			invoice.arrivedDate &&
			invoice.workCompletedDate &&
			dayjs(invoice.arrivedDate).isAfter(invoice.workCompletedDate)
		) {
			return "Invoice's closed date is earlier than the opened date"
		}

		return null
	}, [invoice, lineItems])

	const closedDateIsFuture = useMemo(() => {
		if (!invoice?.workCompletedDate) return false

		return dayjs().isBefore(invoice.workCompletedDate)
	}, [invoice?.workCompletedDate])

	const disableApprovalButton = useMemo(() => {
		return closedDateIsFuture || !!invoiceValidityError || canApprove === false
	}, [closedDateIsFuture, invoiceValidityError, canApprove])

	const gridItemProps: GridItemProps = {
		xs: 12,
		sm: 6,
		md: 3,
	}

	if (!dispatch.invoice?.id) {
		return (
			<Paragraph secondary textAlign="center" my={5}>
				There is no invoice for this dispatch yet
			</Paragraph>
		)
	}
	if (invoiceLoading) {
		return <LoadingSpinner />
	}
	if (!invoice) {
		return <Paragraph>Could not load invoice details</Paragraph>
	}

	return (
		<Box d="flex" flexDirection="column" rowGap={3}>
			<div>
				<HeadingDivider
					headingVariant="h5"
					mt={0}
					renderRight={
						wasRejected && canApprove ?
							() => {
								return (
									<Button
										icon="undo"
										disabled={disableApprovalButton}
										onClick={() => handleApprovalDecision(invoice.id, true)}
									>
										Approve to undo rejection?
									</Button>
								)
							}
						:	undefined
					}
				>
					Invoice #{invoice.invoiceNumber}
				</HeadingDivider>

				{(canApprove || canReject || canFinalize) && !wasRejected && (
					<Box d="flex" justifyContent="center" my={2} gap={1}>
						{canApprove && (
							<Box d="flex" maxWidth={20} flexGrow={1}>
								<Button
									variant="primary-cta"
									icon="thumbs-up"
									fillContainer
									disabled={disableApprovalButton}
									onClick={() => handleApprovalDecision(invoice.id, true)}
								>
									{invoice.status === InvoiceStatusId.PendingFieldApproval &&
										"Add field approval"}
									{invoice.status === InvoiceStatusId.PendingSecondApproval &&
										"Add second approval"}
								</Button>
							</Box>
						)}
						{canReject && (
							<Box d="flex" maxWidth={20} flexGrow={1}>
								<Button
									variant="secondary-cta"
									icon="thumbs-down"
									fillContainer
									onClick={() => handleApprovalDecision(invoice.id, false)}
								>
									Reject
								</Button>
							</Box>
						)}
						{canFinalize && (
							<Box d="flex" maxWidth={20} flexGrow={1}>
								<Button
									variant="primary-cta"
									icon="check"
									fillContainer
									onClick={() => handleFinalize(invoice.id)}
								>
									Send To Invoiced
								</Button>
							</Box>
						)}
					</Box>
				)}

				{!!invoiceValidityError && (
					<ErrorText my={1} textAlign="center">
						{invoiceValidityError}
					</ErrorText>
				)}

				<GridContainer>
					<GridItem {...gridItemProps}>
						<LabeledData label="Bill-to customer">
							{invoice.billToCustomer ?
								`(${invoice.billToCustomer.customerNumber}) ${invoice.billToCustomer.name}`
							:	<EmptyValueDash />}
						</LabeledData>
					</GridItem>
					<GridItem {...gridItemProps}>
						<LabeledData label="Status">
							{invoice.statusCode || <EmptyValueDash />}
						</LabeledData>
					</GridItem>
					<GridItem {...gridItemProps}>
						<LabeledData label="PO #">
							{dispatch.poTicket || <EmptyValueDash />}
						</LabeledData>
					</GridItem>
				</GridContainer>
				<GridContainer>
					<GridItem {...gridItemProps}>
						<Label>Open Date</Label>
						<Box d="flex" gap={0.5} alignItems="center" mt={-0.25}>
							<Paragraph>
								{displayDateTime(invoice.arrivedDate, "") || <EmptyValueDash />}
							</Paragraph>
							<DateTimeInputButton
								iconButton
								value={invoice.arrivedDate ? dayjs(invoice.arrivedDate) : null}
								onChange={(newDate) =>
									handleSave(
										"arrivedDate",
										newDate?.local().toISOString() ?? null
									)
								}
							/>
						</Box>
					</GridItem>
					<GridItem {...gridItemProps}>
						<Label
							tooltip={
								closedDateIsFuture && canApprove ?
									"Approval of the invoice will be disabled until the closed date has passed"
								:	undefined
							}
						>
							Closed date
						</Label>
						<Box d="flex" gap={0.5} alignItems="center" mt={-0.25}>
							<Paragraph>
								{displayDateTime(invoice.workCompletedDate, "") || (
									<EmptyValueDash />
								)}
							</Paragraph>
							<DateTimeInputButton
								iconButton
								value={
									invoice.workCompletedDate ?
										dayjs(invoice.workCompletedDate)
									:	null
								}
								onChange={(newDate) =>
									handleSave(
										"workCompletedDate",
										newDate?.local().toISOString() ?? null
									)
								}
							/>
						</Box>
					</GridItem>
				</GridContainer>
			</div>

			<InvoiceLineItems dispatch={dispatch} invoice={invoice} />

			<CreditMemos invoiceId={dispatch.invoice.id} />

			<div>
				<HeadingDivider variant="h5">Invoice Comments</HeadingDivider>
				<Box d="flex">
					<Paragraph
						maxWidth="50%"
						smProps={{ maxWidth: "none" }}
						css={cssMixins.preserveLineBreaks}
					>
						{invoice.comments || <EmptyValueDash />}
					</Paragraph>
					<Box mt={-0.5}>
						<IconButton
							onClick={() =>
								setEditStringModalConfig({
									title: "Invoice Comments",
									message: "Edit invoice comments",
									initialValue: invoice.comments,
									textarea: true,
									maxLength: 10000,
									allowEmpty: true,
									label: "Comments",
									textareaProps: { rows: 10 },
									onSave: async (value) => {
										await handleSave("comments", value)
									},
									onClose: () => setEditStringModalConfig(null),
								})
							}
						/>
					</Box>
				</Box>
			</div>

			{!!editStringModalConfig && <EditStringModal {...editStringModalConfig} />}
			{!!confirmationConfig && (
				<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
			)}
		</Box>
	)
}

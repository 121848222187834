import React, { useMemo } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import {
	FormControl,
	FormHelperText,
	InputLabel,
	Select,
	MenuItem,
	Chip,
	makeStyles,
	SelectProps,
	InputLabelProps,
	FormControlProps,
} from "@material-ui/core"
import { getAccessorValue } from "../../util/accessors"

import styles from "../../assets/jss/material-dashboard-pro-react/components/customSelectStyle"

const useStyles = makeStyles(styles)

interface SelectOption {
	[key: string]: unknown
}

export interface CustomMultiSelectProps {
	labelText?: React.ReactNode
	id: string
	name?: string
	value?: (string | number)[]
	onChange?: (value: (string | number)[]) => void
	options: SelectOption[]
	valueAccessor?: string | number | ((option: SelectOption) => string | number)
	textAccessor?: string | number | ((option: SelectOption) => string | number)
	formControlProps?: FormControlProps
	labelProps?: InputLabelProps
	inputProps?: React.HTMLAttributes<HTMLInputElement>
	selectProps?: SelectProps
	emptyItemsText?: string
	isError?: boolean
	helperText?: string
}

const CustomMultiSelect: React.FC<CustomMultiSelectProps> = ({
	labelText,
	id,
	name,
	options,
	valueAccessor = "value",
	textAccessor = "text",
	value = [],
	formControlProps,
	labelProps,
	inputProps,
	emptyItemsText = "No Items",
	selectProps,
	helperText,
	isError,
	onChange,
}) => {
	const classes = useStyles()

	const formControlClasses = useMemo(
		() => classNames(classes.selectFormControl, formControlProps?.className),
		[classes, formControlProps]
	)

	const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		if (typeof onChange === "function") onChange(event.target.value as (string | number)[])
	}

	const handleDelete = (valueToDelete: string | number) => {
		const newSelected = value.filter(
			(x) => parseInt(String(x)) !== parseInt(String(valueToDelete))
		)
		if (typeof onChange === "function") onChange(newSelected)
	}

	const getChipLabel = (value: string | number) => {
		// Select's renderValue function gives us just the actual value, so
		// we need to first find that value's option and then access its text.
		const option = options.find((x) => {
			const optionValue = getAccessorValue(x, valueAccessor)

			return parseInt(String(optionValue)) === parseInt(String(value))
		})

		if (option) {
			return getAccessorValue(option, textAccessor)
		}

		return ""
	}

	return (
		<FormControl
			fullWidth
			className={formControlClasses}
			{...formControlProps}
			error={isError}
		>
			{labelText !== undefined ?
				<InputLabel className={classes.selectLabel} htmlFor={id} {...labelProps}>
					{labelText}
				</InputLabel>
			:	null}
			<Select
				multiple
				MenuProps={{
					className: classes.selectMenu,
				}}
				classes={{
					select: classes.select,
				}}
				value={value}
				onChange={handleChange}
				inputProps={{
					name: name || id,
					id,
					...inputProps,
				}}
				renderValue={(value) => (
					<div className={classes.chips}>
						{(value as (number | string)[]).map((value) => (
							<Chip
								key={value}
								className={`${classes.chip} ${classes.multiSelectChip}`}
								onDelete={() => handleDelete(value)}
								label={getChipLabel(value)}
							/>
						))}
					</div>
				)}
				{...selectProps}
			>
				{options.map((option, i) => (
					<MenuItem
						key={i}
						disabled={!!option.disabled}
						classes={{
							root: classes.selectMenuItem,
							selected: classes.selectMenuItemSelected,
						}}
						value={getAccessorValue(option, valueAccessor)}
					>
						{getAccessorValue(option, textAccessor)}
					</MenuItem>
				))}
				{options.length === 0 && (
					<MenuItem
						disabled={true}
						value=""
						classes={{
							root: classes.selectMenuItem,
							selected: classes.selectMenuItemSelected,
						}}
					>
						{emptyItemsText}
					</MenuItem>
				)}
			</Select>
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
		</FormControl>
	)
}

CustomMultiSelect.propTypes = {
	labelText: PropTypes.node,
	id: PropTypes.string.isRequired,
	name: PropTypes.string,
	value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired),
	onChange: PropTypes.func,
	options: PropTypes.array.isRequired,
	valueAccessor: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.func]),
	textAccessor: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.func]),
	formControlProps: PropTypes.object,
	labelProps: PropTypes.object,
	inputProps: PropTypes.object,
	selectProps: PropTypes.object,
	emptyItemsText: PropTypes.string,
	isError: PropTypes.bool,
	helperText: PropTypes.string,
}

export default React.memo(CustomMultiSelect)

import modalStyle from "../modalStyle"

export default function loadingWrapperStyle(theme) {
	return {
		marginRight: {
			marginRight: "5px",
		},
		modalSectionTitle: {
			marginTop: "30px",
		},
		...modalStyle(theme),
		modalBody: {
			...modalStyle(theme).modalBody,
			minWidth: "300px",
			textAlign: "center",
		},
	}
}

import { PropsWithChildren, ReactNode } from "react"

import { css } from "@emotion/react"

import { Box } from "../layout"
import { Icon, IconFamily, IconName, IconProps } from "../typography"

export interface ModalActionRowsProps<RowType> {
	rows: RowType[]
	renderRow: (row: RowType) => ReactNode
	rowKeyAccessor: (row: RowType) => string | number
	onRowClick: (row: RowType) => void
	icon?: IconName
	iconFamily?: IconFamily
	iconColor?: IconProps["color"]
}

/**
 * A full-bleed clickable button row inside a modal.
 */
export const ModalActionRows = <RowType,>({
	rows,
	renderRow,
	rowKeyAccessor,
	onRowClick,
	icon = "long-arrow-right",
	iconFamily = "regular",
	iconColor = "primary",
}: PropsWithChildren<ModalActionRowsProps<RowType>>) => {
	return (
		<div>
			{rows.map((r) => (
				<Box key={rowKeyAccessor(r)} css={rowCss} onClick={() => onRowClick(r)}>
					{icon && <Icon icon={icon} color={iconColor} family={iconFamily} />}
					{renderRow(r)}
				</Box>
			))}
		</div>
	)
}

const rowCss = css`
	display: flex;
	align-items: center;
	column-gap: 1rem;
	margin: 0 -24px;
	padding: 1rem 24px;
	background: white;
	border-bottom: 1px solid #eee;
	transition: background-color 200ms ease-out;
	&:hover,
	&:focus {
		background-color: #fafafa;
	}
	&:first-of-type {
		border-top: 1px solid #eee;
	}
`

import { FC, useState } from "react"

import { InventoryPart, makeApiErrorMessage, useUpdateInventoryPart } from "@ncs/ncs-api"
import { ExtendableModalProps, Modal, NumericInput, useToast } from "@ncs/web-legos"

export interface EditPartSizeModalProps extends ExtendableModalProps {
	part: InventoryPart
}

export const EditPartSizeModal: FC<EditPartSizeModalProps> = ({ part, ...rest }) => {
	const { makeSuccessToast } = useToast()
	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const [height, setHeight] = useState(part.height)
	const [length, setLength] = useState(part.length)
	const [width, setWidth] = useState(part.width)
	const [weight, setWeight] = useState(part.weight)
	const [gallons, setGallons] = useState(part.gallons)

	const updatePart = useUpdateInventoryPart()

	const handleUpdate = async () => {
		try {
			setIsSaving(true)
			await updatePart({
				updates: {
					height,
					length,
					width,
					weight,
					gallons,
				},
				id: part.id,
			})
			makeSuccessToast("Part updated")
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	return (
		<Modal
			{...rest}
			title="Edit Part Size"
			errorText={errorText}
			rightButtons={{
				buttonText: "Save Changes",
				isLoading: isSaving,
				onClick: handleUpdate,
			}}
		>
			<NumericInput
				value={height}
				onChange={(value) => setHeight(value ?? 0)}
				label="Height (inches)"
				decimalScale={3}
				max={9999999999.999}
			/>
			<NumericInput
				value={length}
				onChange={(value) => setLength(value ?? 0)}
				label="Length (inches)"
				decimalScale={3}
				max={9999999999.999}
			/>
			<NumericInput
				value={width}
				onChange={(value) => setWidth(value ?? 0)}
				label="Width (inches)"
				decimalScale={3}
				max={9999999999.999}
			/>
			<NumericInput
				value={weight}
				onChange={(value) => setWeight(value ?? 0)}
				label="Weight (lbs)"
				decimalScale={3}
				max={9999999999.999}
			/>
			<NumericInput
				value={gallons}
				onChange={(value) => setGallons(value ?? null)}
				label="Gallons"
				decimalScale={2}
				max={9999.99}
			/>
		</Modal>
	)
}

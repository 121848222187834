import { FC, useMemo, useState } from "react"

import { Column } from "react-table"

import { ChemicalCustomerDetail } from "@ncs/ncs-api"
import { displayNumber, yesOrNo } from "@ncs/ts-utils"
import { Button, Table } from "@ncs/web-legos"

import { AddChemicalToCustomerModal } from "~/components"

import { ContainerDetailModal } from "./components"

export interface ChemicalContainersTabProps {
	chemicalCustomerDetail: ChemicalCustomerDetail
}

export const ChemicalContainersTab: FC<ChemicalContainersTabProps> = ({
	chemicalCustomerDetail,
}) => {
	const [modalContainerId, setModalContainerId] = useState<string | null>(null)
	const [showAddModal, setShowAddModal] = useState(false)

	const data = useMemo(() => {
		return chemicalCustomerDetail.containers.sort((a, b) => {
			if (a.isActive && !b.isActive) return -1
			if (!a.isActive && b.isActive) return 1
			return (a?.name ?? "") > (b?.name ?? "") ? 1 : -1
		})
	}, [chemicalCustomerDetail.containers])

	return (
		<>
			<Button
				buttonText="Create chemical container"
				icon="plus-circle"
				variant="secondary-cta"
				onClick={() => setShowAddModal(true)}
			/>

			<Table
				data={data}
				columns={columns}
				onRowClick={({ original }) => setModalContainerId(original.id)}
				rowDisabledAccessor={({ isActive }) => !isActive}
			/>

			{!!modalContainerId && (
				<ContainerDetailModal
					containerChemicalId={modalContainerId}
					customerContainers={chemicalCustomerDetail.containers}
					onClose={() => setModalContainerId(null)}
				/>
			)}
			{showAddModal && (
				<AddChemicalToCustomerModal
					onClose={() => setShowAddModal(false)}
					customer={{
						id: chemicalCustomerDetail.customerId,
						number: chemicalCustomerDetail.customerNumber,
						name: chemicalCustomerDetail.customerName,
					}}
				/>
			)}
		</>
	)
}

const columns: Column<ChemicalCustomerDetail["containers"][number]>[] = [
	{
		Header: "Name",
		accessor: ({ name }) => name,
	},
	{
		Header: "Chemical Part",
		accessor: ({ partNumber, partDescription }) => `(${partNumber}) ${partDescription}`,
	},
	{
		Header: "Container",
		accessor: ({ containerName }) => containerName,
	},
	{
		Header: "Expected mL usage",
		accessor: ({ expectedMlUsage }) => displayNumber(expectedMlUsage),
	},
	{
		Header: "Low level (gal.)",
		accessor: ({ lowLevelGallons }) => displayNumber(lowLevelGallons),
	},
	{
		Header: "Max back stock qty",
		accessor: ({ maxBackStockQuantity }) => displayNumber(maxBackStockQuantity),
	},
	{
		Header: "Is active?",
		hiddenByDefault: true,
		accessor: ({ isActive }) => yesOrNo(isActive),
	},
]

import React from "react"

import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Redirect, Route } from "react-router-dom"

import { isAuthenticated } from "../../redux/selectors"

const ProtectedRoute = ({
	component: Component,
	isAuthenticated: isAuthenticatedProp,
	isAuthenticatedRedux,
	...rest
}) => {
	// First check for authentication coming in as a prop from the parent.
	// Fallback to auth coming from redux.
	const isAuthenticated = isAuthenticatedProp ?? isAuthenticatedRedux

	return (
		<Route
			{...rest}
			render={(props) =>
				isAuthenticated ?
					<Component {...props} />
				:	<Redirect
						to={{
							pathname: "/login",
							state: { from: props.location },
						}}
					/>
			}
		/>
	)
}

ProtectedRoute.propTypes = {
	/** Optionally pass in an isAuthenticated to take precedence over the isAuthenticated from Redux. */
	isAuthenticated: PropTypes.bool,
	location: PropTypes.object.isRequired, // comes from Route, needs to be passed on to prevent blocking react-router
}

const mapStateToProps = (state) => ({
	isAuthenticatedRedux: isAuthenticated(state),
})

export default connect(mapStateToProps, null)(ProtectedRoute)

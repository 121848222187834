import { FC, useMemo, useState } from "react"

import dayjs from "dayjs"
import { useQueryClient } from "react-query"
import { useHistory, useParams } from "react-router-dom"

import {
	ConnectivityUrlPaths,
	ReportUrlPaths,
	useSiteVacuums,
	useVacuumsAtSite,
} from "@ncs/ncs-api"
import { displayDateTime } from "@ncs/ts-utils"
import {
	Box,
	Button,
	Card,
	DateTimeInput,
	EmptyValueDash,
	encodeUrlState,
	ErrorText,
	GridContainer,
	GridItem,
	Icon,
	LabeledData,
	LoadingSpinner,
	Paragraph,
	StatusBlip,
	Tabs,
	useUrlState,
} from "@ncs/web-legos"

import { DevicesTabUrlState } from "../ConnectedDevices/components"
import { ChartsTab, ConfigTab, EventsTab, StartupStatsTab, TelemetryTab } from "./components"

enum ConnectedVacuumTab {
	Telemetry = "Telemetry",
	Events = "Events",
	StartupStats = "Startup Stats",
	Config = "Config",
	Charts = "Charts",
}

export type ConnectedVacuumsUrlState = {
	tab: ConnectedVacuumTab
}

export const VacuumDetail: FC = () => {
	const history = useHistory<{ devicesUrlState?: DevicesTabUrlState }>()
	const { id } = useParams<{ id: string }>()
	const [{ tab }, { updateUrlValue }] = useUrlState<ConnectedVacuumsUrlState>({
		tab: ConnectedVacuumTab.Telemetry,
	})
	const [startDate, setStartDate] = useState(dayjs().subtract(1, "day"))
	const [endDate, setEndDate] = useState(dayjs())
	const [isRefreshing, setIsRefreshing] = useState(false)
	const queryClient = useQueryClient()

	// We need to use this endpoint to get at the customer for this device.
	const vacuumsAtSiteQuery = useVacuumsAtSite({ params: { device: id } })
	const { customer, device } = useMemo(() => {
		const match = vacuumsAtSiteQuery.data.find((site) => site.devices.find((d) => d.id === id))

		return {
			customer: match?.customer ?? null,
			device: match?.devices.find((d) => d.id === id) ?? null,
		}
	}, [vacuumsAtSiteQuery.data, id])

	// Now we can call for all vacuums at this site.
	const [siteVacuums, loadingSiteVacuums] = useSiteVacuums(customer?.id)
	// And finally get the specific vacuum that the URL referred to.
	const vacuum = useMemo(() => {
		return (siteVacuums ?? []).find((v) => v.id === id) ?? null
	}, [siteVacuums, id])

	const onDataRefresh = async () => {
		setIsRefreshing(true)
		await queryClient.invalidateQueries([
			ConnectivityUrlPaths.Connectivity,
			ConnectivityUrlPaths.Vacuum,
		])
		await queryClient.invalidateQueries([ReportUrlPaths.Reports, ReportUrlPaths.Connectivity])
		setIsRefreshing(false)
	}

	const isLoading = vacuumsAtSiteQuery.isLoading || loadingSiteVacuums

	if (isLoading) {
		return (
			<Card>
				<LoadingSpinner text="Loading vacuum details..." />
			</Card>
		)
	}

	if (!vacuum) {
		return (
			<Card>
				<ErrorText>Unable to load vacuum details</ErrorText>
			</Card>
		)
	}

	return (
		<>
			<Button
				icon="long-arrow-left"
				onClick={() =>
					history.push({
						pathname: "/connectivity/connected-devices",
						search: encodeUrlState<DevicesTabUrlState>(
							history.location.state?.devicesUrlState
						),
					})
				}
				containerProps={{ mb: 3 }}
			>
				Back
			</Button>

			<Card
				headingIcon="router"
				heading={
					customer ?
						`${vacuum.deviceInfo} at ${customer.name} (${customer.number})`
					:	undefined
				}
				renderRight={() => (
					<Button icon="sync" onClick={onDataRefresh} isLoading={isRefreshing}>
						Refresh data
					</Button>
				)}
			>
				<Box d="flex" gap={2} mt={-1}>
					<Box d="flex" alignItems="center" gap={0.5}>
						<StatusBlip status={vacuum.status} />
						<Paragraph>Device status: {vacuum.status ?? "Unknown"}</Paragraph>
					</Box>
					<Box d="flex" gap={0.5} alignItems="center">
						<Icon icon="satellite-dish" />
						<Paragraph>
							Last communication: {displayDateTime(device?.lastCommunication)}
						</Paragraph>
					</Box>
				</Box>

				<GridContainer my={2}>
					<GridItem sm={6} md={3}>
						<LabeledData
							label="Vacuum software version"
							isLoading={loadingSiteVacuums}
						>
							{vacuum.vacSwVersion ?? <EmptyValueDash />}
						</LabeledData>
					</GridItem>
					<GridItem sm={6} md={3}>
						<LabeledData label="Drive software version" isLoading={loadingSiteVacuums}>
							{vacuum.driveSwVersion ?? <EmptyValueDash />}
						</LabeledData>
					</GridItem>
					<GridItem sm={6} md={3}>
						<LabeledData label="Drive accessories" isLoading={loadingSiteVacuums}>
							{vacuum.accessories ?? <EmptyValueDash />}
						</LabeledData>
					</GridItem>
					<GridItem sm={6} md={3}>
						<LabeledData label="Drive frame" isLoading={loadingSiteVacuums}>
							{vacuum.frame ?? <EmptyValueDash />}
						</LabeledData>
					</GridItem>
				</GridContainer>

				<GridContainer rowGap={0}>
					<GridItem xs={12} sm={6} md={4}>
						<DateTimeInput
							label="Start time after"
							value={startDate}
							onChange={(selection) => setStartDate(selection ?? dayjs())}
						/>
					</GridItem>
					<GridItem xs={12} sm={6} md={4}>
						<DateTimeInput
							label="Start time before"
							value={endDate}
							onChange={(selection) => setEndDate(selection ?? dayjs())}
						/>
					</GridItem>
				</GridContainer>

				<Tabs
					currentTab={tab}
					onChange={(newTab) => updateUrlValue("tab", newTab)}
					panels={[
						{
							component: (
								<TelemetryTab
									deviceId={vacuum.id ?? null}
									startDate={startDate}
									endDate={endDate}
									isRefreshing={isRefreshing}
								/>
							),
							navLabel: ConnectedVacuumTab.Telemetry,
							navIcon: <Icon icon="chart-network" />,
						},
						{
							component: (
								<EventsTab
									deviceId={vacuum.id ?? null}
									startDate={startDate}
									endDate={endDate}
									isRefreshing={isRefreshing}
								/>
							),
							navLabel: ConnectedVacuumTab.Events,
							navIcon: <Icon icon="calendar-exclamation" />,
						},
						{
							component: (
								<StartupStatsTab
									deviceId={vacuum.id ?? null}
									startDate={startDate}
									endDate={endDate}
									isRefreshing={isRefreshing}
								/>
							),
							navLabel: ConnectedVacuumTab.StartupStats,
							navIcon: <Icon icon="tachometer" />,
						},
						{
							component: (
								<ChartsTab
									deviceId={vacuum.id ?? null}
									startDate={startDate}
									endDate={endDate}
									isRefreshing={isRefreshing}
								/>
							),
							navLabel: ConnectedVacuumTab.Charts,
							navIcon: <Icon icon="chart-bar" />,
						},
						{
							component: <ConfigTab deviceId={vacuum.id ?? null} />,
							navLabel: ConnectedVacuumTab.Config,
							navIcon: <Icon icon="cog" />,
						},
					]}
				/>
			</Card>
		</>
	)
}

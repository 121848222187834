import { FC } from "react"

import { CustomerType, useCustomerTypes } from "@ncs/ncs-api"
import { ExtendableSelectProps, Select } from "@ncs/web-legos"

export interface CustomerTypesSelectorProps extends ExtendableSelectProps<CustomerType> {}

export const CustomerTypeSelector: FC<CustomerTypesSelectorProps> = ({ ...rest }) => {
	const [types, typesLoading] = useCustomerTypes()

	return (
		<Select
			label="Customer type"
			disableNoSelectionOption={false}
			disabled={typesLoading}
			{...rest}
			options={types ?? []}
			valueAccessor="id"
			textAccessor="description"
		/>
	)
}

import { FC, ReactElement } from "react"

import { Column } from "react-table"

import {
	SalesRepAssignmentsReportLine,
	SalesRepAssignmentsReportQueryParams,
	useSalesRepAssignmentsReport,
} from "@ncs/ncs-api"
import { Typify } from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	CustomerQueryFilter,
	EnterpriseQueryFilter,
	OrganizationQueryFilter,
	Table,
	UserQueryFilter,
	UserQueryFilterProps,
	useUrlState,
} from "@ncs/web-legos"

export type SalesRepAssignmentsReportUrlState = Typify<SalesRepAssignmentsReportQueryParams>

export const SalesRepAssignmentsReport: FC = () => {
	const [params, { setUrlState: setParams }] = useUrlState<SalesRepAssignmentsReportUrlState>(
		initialSalesRepAssignmentsReportUrlState
	)

	const [data, isLoading] = useSalesRepAssignmentsReport({ params })

	const noFilters =
		!params.customer && !params.enterprise && !params.organization && !params.user

	return (
		<AnimatedEntrance show>
			<Table
				data={data ?? []}
				isLoading={isLoading}
				columns={columns}
				queryParamState={params}
				setQueryParamState={setParams}
				pinnedQueryFilters={[
					SalesRepUserQueryFilter,
					CustomerQueryFilter,
					OrganizationQueryFilter,
					EnterpriseQueryFilter,
				]}
				noDataText={
					noFilters ? "This report requires setting at least one filter" : undefined
				}
				storeColumnVisibility="sales-rep-assignments-report-columns"
			/>
		</AnimatedEntrance>
	)
}

const columns: Column<SalesRepAssignmentsReportLine>[] = [
	{
		Header: "Customer #",
		disableSortBy: true,
		accessor: "customerNumber",
	},
	{
		Header: "Customer name",
		accessor: "customerName",
	},
	{
		Header: "Rep name",
		accessor: "user",
	},
	{
		Header: "Role",
		accessor: "role",
	},
]

const initialSalesRepAssignmentsReportUrlState: SalesRepAssignmentsReportUrlState = {
	user: null,
	customer: null,
	organization: null,
	enterprise: null,
}

const SalesRepUserQueryFilter = (
	props: UserQueryFilterProps<SalesRepAssignmentsReportUrlState>
): ReactElement => {
	return <UserQueryFilter {...props} employeesOnly />
}

import React from "react"
import PropTypes from "prop-types"

// If the content in children can cause undefined errors, make sure you wrap the children in a function, like so:
// <ConditionalContent show={notAnObject}>
//   {() => <Blah prop={notAnObject.undefinedProp} /> }
// </ConditionalContent>
class ConditionalContent extends React.PureComponent {
	render() {
		const { show, hide, children } = this.props

		if (typeof show === typeof hide && typeof show !== "undefined") {
			throw new Error(
				"The `show` and `hide` props are mutually exclusive. Please provide just one, " +
					"whichever makes the most sense for your conditional statement"
			)
		}

		let shouldShow =
			typeof show === "boolean" ? show
			: typeof hide === "boolean" ? !hide
			: false

		return (
			<React.Fragment>
				{shouldShow && (typeof children === "function" ? children() : children)}
			</React.Fragment>
		)
	}
}

ConditionalContent.propTypes = {
	show: PropTypes.bool,
	hide: PropTypes.bool,
}

export default ConditionalContent
